import React, {FC, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { RootState } from '../../store';
import { setUserActivityState } from '../../store/actions/authActions';
import { getStores } from '../../store/actions/storeActions';
import { setCarrierPrices } from '../../store/actions/storeActions';
import { setUsersAdmin, setUserDirectory, setRightDrawer } from '../../store/actions/genericActions';
import { setActiveApis, getShipmentData } from '../../store/actions/apiActions';
import { roleTypeArray } from '../../features/referenceData/userLists';
import { getMonthYear } from '../../features/functions/handleDates';
import { Store, UserDirectoryFav } from '../../types/storeTypes';
import { ApiConnection, ShipmentInterface } from '../../types/apiTypes';
import { CarrierPrice } from '../../types/storeTypes';
import { User, UserActivityState } from '../../types/authTypes';
import { firestore, auth } from '../../firebase/config';
import { collection, onSnapshot, query, where, orderBy, Timestamp, limit} from 'firebase/firestore';
import {formatCurrencyMxn } from '../../features/functions/genericFunctions';

import AvatarCustomed from '../UI/AvatarCustomed';
import EHIsotipo from '../UI/assets/EHIsotipo';

import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

const Header: FC = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const userInStore: User | null = useSelector((state: RootState) => state.auth.user)
    const { rightDrawer } = useSelector((state: RootState) => state.generic);
    const userBalance: string = userInStore && userInStore.balance ? formatCurrencyMxn(userInStore.balance) : '$0 MXN'
    
    useEffect(()=> {
        let now = new Date();
        now.setMonth(now.getMonth()-1);
        now.setHours(0,0,0,0);

        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const currentMonth: Date = new Date(year+'-'+month+ '-'+now.getDate().toString().padStart(2, '0'))

        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if(user && user.uid) {
                const userRef = collection(firestore, 'users')
                const userQuery = query((userRef), 
                where('uid','==',user.uid))
                onSnapshot(userQuery, (querySnapshot) => {
                    const userDb: Array<User> = querySnapshot.docs.map((doc) => {
                        const userDoc: User = {...doc.data() as User, uid: doc.id}
                        return userDoc
                    })
                    const userData = userDb[0]
                    const userActivityToSave: UserActivityState = {
                        active:userData.active ? userData.active : false,
                        balance:userData.balance,
                        abono:userData.abono,
                        about:userData.about,
                        phoneNumber:userData.phoneNumber,
                        birthday:userData.birthday
                    }
                    const setStore: boolean = true
                    dispatch<any>(setUserActivityState(userActivityToSave, setStore));
                })

                if(userInStore&&userInStore.active) { // add emailVerified to condition once Jair confirms valid emails will be used '&& user.emailVerified'
                    const franchisesQuery: Array<any> = 
                        userInStore?.role === roleTypeArray[1] ? ["_id", '!=', 'all']
                        :
                        userInStore?.role === roleTypeArray[2] || userInStore?.role === roleTypeArray[3] ? ["members", 'array-contains', userInStore?.uid]
                        : ["_id", '==', 'all'];
                    const franchisesRef = collection(firestore, 'franchises')
                    const franchiseQuery = query((franchisesRef), 
                        where(franchisesQuery[0], franchisesQuery[1], franchisesQuery[2]));
                    onSnapshot(franchiseQuery, (querySnapshot) => {
                        const franchises: Array<Store> = querySnapshot.docs.map((doc) => {
                            const franchiseDoc: Store = {...doc.data() as Store, _id: doc.id}
                            return franchiseDoc
                        })
                        dispatch<any>(getStores(franchises))
        
                        const userDirectoryRef = collection(firestore, 'userDirectory')
                        const userDirectoryQuery = query((userDirectoryRef), where('createdBy','==', userInStore.uid))
                        onSnapshot(userDirectoryQuery, (querySnapshot) => {
                            const userDirectory: Array<UserDirectoryFav> = querySnapshot.docs.map((doc) => {
                                const userDirDoc: UserDirectoryFav = {...doc.data() as UserDirectoryFav, _id: doc.id}
                                return userDirDoc
                            })
                            dispatch<any>(setUserDirectory(userDirectory))
                        })
        
                        const apiCredentialsRef = collection(firestore, 'apiCredentials')
                        const apiCredentialsQuery = query((apiCredentialsRef), where("released", '==', true));
                        onSnapshot(apiCredentialsQuery, (querySnapshot => {
                            const apiCredentials: Array<ApiConnection> = querySnapshot.docs.map((doc) => {
                                const apiCredential: ApiConnection = {...doc.data() as ApiConnection, _id: doc.id}
                                return apiCredential
                            })
                            dispatch<any>(setActiveApis(apiCredentials))
                        })) 
                        
                        if(franchises&&franchises.length>0) {
                            //get data for admin
                            if(userInStore&&userInStore.role === roleTypeArray[1]){
                                const usersRef = collection(firestore, 'users')
                                const usersQuery = query((usersRef), 
                                        where("email", '!=', ''));
                                    onSnapshot(usersQuery, (querySnapshot) => {
                                    const users: Array<User> = querySnapshot.docs.map((doc) => {
                                        const userDoc: User = {...doc.data() as User, uid: doc.id}
                                        return userDoc
                                    })
                                    dispatch<any>(setUsersAdmin(users))
                                })

                                const carrierPricesRef = collection(firestore, 'carrierPrices')
                                const carrierPricesQuery = query((carrierPricesRef),
                                    where("_id", "!=",' '));
                                onSnapshot(carrierPricesQuery, (querySnapshot => {
                                    const carrierPrices: Array<CarrierPrice> = querySnapshot.docs.map((doc) => {
                                        const carrierPrice: CarrierPrice = {...doc.data() as CarrierPrice, _id: doc.id}
                                        return carrierPrice
                                    })
                                    // console.log(carrierPrices)
                                    dispatch<any>(setCarrierPrices(carrierPrices))
                                }))
                                dispatch<any>(getShipmentData(0, userInStore, null, null));
                            }
        
                            //Get data for supervisors
                            if(userInStore&&userInStore.role === roleTypeArray[2]){ 
                                const usersRef = collection(firestore, 'users') //get users for supervisor based on franchises.members
                                let memberIdsInFranchises: Array<string> = []
                                for(const franchise of franchises){
                                    const franchiseMembers = franchise.members
                                    if(franchiseMembers){
                                        for(const memberId of franchiseMembers){
                                            memberIdsInFranchises.push(memberId)
                                        }
                                    }
                                }
                                const memberIdsArray: Array<string> = memberIdsInFranchises.filter((value, index, self) => self.indexOf(value) === index);
                                const usersQuery = query(
                                    (usersRef), 
                                    where("uid", 'in', memberIdsArray),
                                    where("role", '==', roleTypeArray[3]), //bring members only
                                );
                                onSnapshot(usersQuery, (querySnapshot) => {
                                    const users: Array<User> = querySnapshot.docs.map((doc) => {
                                        const userDoc: User = {...doc.data() as User, uid: doc.id}
                                        return userDoc
                                    })
                                    dispatch<any>(setUsersAdmin(users))
            
                                    const carrierPricesRef = collection(firestore, 'carrierPrices')
                                    const carrierPricesQuery = query((carrierPricesRef),
                                        where("customerId", "in", memberIdsArray));
                                    onSnapshot(carrierPricesQuery, (querySnapshot => {
                                        const carrierPrices: Array<CarrierPrice> = querySnapshot.docs.map((doc) => {
                                            const carrierPrice: CarrierPrice = {...doc.data() as CarrierPrice, _id: doc.id}
                                            return carrierPrice
                                        })
                                        dispatch<any>(setCarrierPrices(carrierPrices))
                                    }))
                                    
                                })
                                dispatch<any>(getShipmentData(0, userInStore, null, franchises)); 
                            }
        
                            //Get data for members
                            if(userInStore&&userInStore.role === roleTypeArray[3]){
                                
                                dispatch<any>(getShipmentData(0, userInStore, null,null));

                                const carrierPricesRef = collection(firestore, 'carrierPrices')
                                const carrierPricesQuery = query((carrierPricesRef),
                                    where("_id", "!=",' '));
                                onSnapshot(carrierPricesQuery, (querySnapshot => {
                                    const carrierPrices: Array<CarrierPrice> = querySnapshot.docs.map((doc) => {
                                        const carrierPrice: CarrierPrice = {...doc.data() as CarrierPrice, _id: doc.id}
                                        return carrierPrice
                                    })
                                    // console.log(carrierPrices)
                                    dispatch<any>(setCarrierPrices(carrierPrices))
                                }))
        
                            }
                        }  
                    })
                }
            }
          });
                  
        return () => {
            unsubscribe();
        }


    }, [
        dispatch,
        userInStore,
        location
    ])

    const rightDrawerHandler = () => {
        const statusFlag: boolean = rightDrawer;
        dispatch<any>(setRightDrawer(!statusFlag))
    }

    const navigateBalanceHandler = () =>{
        navigate('/balance') 
    }


    return (
        <AppBar 
            elevation={0} position="fixed" color="inherit" 
            sx={{ 
                borderBottom:.5,
                borderColor: 'grey.300',
                display: 'block',
                zIndex: (theme) => theme.zIndex.drawer + 1 }
            }
            >
            <Toolbar>
                <Box className="d-flex-row-center-start" sx={{flexGrow: 1}}>
                    <ButtonBase disableRipple onClick={()=>navigate('/dashboard')}>
                        <EHIsotipo width={35} height={35} />
                    </ButtonBase>
                    <Typography sx={{ml:2, fontWeight:600, display:{xs:'none', md:'block'}}} variant="h6">
                        ENVIOSHAPPY
                    </Typography>
                </Box>
                {userInStore ? 
                    <Box className="d-flex-row-end-centered">
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type='string'
                                disabled
                                size='small'
                                value={userBalance}
                                endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip title="Agregar saldo">
                                        <IconButton
                                        aria-label="add-balance"
                                        onClick={navigateBalanceHandler}
                                        edge="end"
                                        >
                                            {<LocalAtmIcon />}
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                        <IconButton 
                            onClick={rightDrawerHandler} 
                            size="small" sx={{ml:.75}}>
                            <AvatarCustomed 
                                userName={userInStore.fullName} 
                                size={30} 
                                userPhoto={userInStore.userAvatar}  
                                fontWeight={600}
                                fontSize={16}
                            />
                        </IconButton>
                    </Box>
                    :null
                }
            </Toolbar>
        </AppBar>
    )
}

export default Header