import React, {FC, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../store';
import { setEditStore } from '../../../store/actions/storeActions';
import { setShowForm } from '../../../store/actions/genericActions';
import { User } from '../../../types/authTypes';
import { Store } from '../../../types/storeTypes';
import { roleTypeArray } from '../../../features/referenceData/userLists';

import MemoizedNewStoreForm from '../../UI/forms/NewStoreForm';

import StoreCard from './StoreCard';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

const StoresSummary: FC = () => {

    const dispatch = useDispatch();

    const user: User | null = useSelector((state: RootState) => state.auth.user)
    const { loading, showForm } = useSelector((state: RootState) => state.generic);
    const { stores } = useSelector((state: RootState) => state.stores);
    const [searchStoreName, setSearchStoreName] = useState<string>();
    const [filteredStores, setFilteredStores] = useState<Array<Store> |null>(stores ? stores : null)

    useEffect(()=> {
        if(searchStoreName) {
            setFilteredStores(stores && stores.filter((store:Store) => {
                return store.storeName.toLowerCase().includes(searchStoreName)
            }))
        }
        if(!searchStoreName || searchStoreName === ''){
            setFilteredStores(stores)
        }

    },[
        searchStoreName,
        stores
    ])

    const searchByName = (stringToSearch:string) => {
        const stringToSearchToLowerCase: string = stringToSearch.toLowerCase()
        setSearchStoreName(stringToSearchToLowerCase);
    }

    const toggleFormHandler = () => {
        const currentState = showForm
        if(showForm){
            dispatch<any>(setEditStore(undefined))
        }
        dispatch<any>(setShowForm(!currentState))
    }

    return (
        <Container maxWidth='xl' sx={{bgcolor:'#F4F7F9', minHeight:'100vh'}}>
          <Toolbar sx={{mb:3}}/>
            { user ? 
                <Grid container spacing={2} sx={{width:'100%', px:{xs:.5,md:4}, pb:4}}>
                    <Grid item xs={12} md={8}>
                        <Box sx={{width:'100%'}}>
                            <Typography 
                                component="h6" 
                                variant="h5" 
                                sx={{fontWeight:700}}>
                                Tiendas
                            </Typography>
                            <Box sx={{
                                width:'100%',
                                display:'flex',
                                flexDirection:{xs:'column', md:'row'}
                                }}>
                                {user.role === roleTypeArray[1] ?
                                    <LoadingButton
                                        loading={loading}
                                        color='success'
                                        // size='small'
                                        sx={{my:1.5}}
                                        variant={showForm ? 'text':"outlined"}
                                        onClick={toggleFormHandler}
                                        startIcon={
                                            !showForm ?
                                            <AddBoxIcon />
                                            :
                                            <ArrowBackIosIcon />
                                        }
                                    >   
                                        
                                        {showForm ? 'Regresar' : 'Registrar tienda'}
                                    </LoadingButton>
                                    :null
                                }
                                <TextField
                                    required
                                    color="secondary"
                                    size="small"
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                        ),
                                    }}
                                    sx={{my:1.5, mx:1, bgcolor:'white'}}
                                    id="outlined-required"
                                    onChange={(e:any) => searchByName(e.currentTarget.value)}
                                    placeholder='Buscar'
                                    type="text"
                                />
                            </Box>
                        </Box>
                    </Grid>
                    {!showForm && filteredStores && filteredStores?.length>0 ? 
                        <StoreCard userAdmin={user.role === roleTypeArray[1] ? true: false} stores={filteredStores} />
                        :
                        null
                    }
                    {!showForm && stores?.length === 0 ? 
                        <Grid item xs={12}>
                            <Box sx={{width:'100%'}}>
                                <Typography variant="body1">Aún no tienes tienda asignada</Typography>
                            </Box>
                        </Grid>
                        :null
                    }
                    {showForm && user && user.role === roleTypeArray[1] ?
                        <Grid item xs={12} md={6}>
                            <MemoizedNewStoreForm user={user}/>
                        </Grid>
                        :null
                    }
                </Grid>
                :null
            }
        </Container>
    )
}

export default StoresSummary