import React, { FC, useState, useEffect  }from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PackageBasicData } from '../../../types/storeTypes';
import { RootState } from '../../../store';
import { setPackageToShip } from '../../../store/actions/genericActions';

import { packageTypes, shipmentServiceTypes, unitKeyArray } from '../../../features/referenceData/appLists';
import { validateLengthInput, validateEmail, validatePositiveNumber } from '../../../features/functions/validatorField';

import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

const PackageForShipmentForm: FC = () => {

    const dispatch = useDispatch();
    const {packageToShip} = useSelector((state: RootState) => state.generic)

    const [skipDimensionCheck, setSkipDimnesionCheck] = useState(false)

    const [peso, setPeso] = useState<number|null>(
        packageToShip&&packageToShip.peso?packageToShip.peso
        :
        null
    )
    const [envio, setEnvio] = useState<string>(
        packageToShip&&packageToShip.envio?packageToShip.envio
        :
        packageTypes[0]
        )
    const [contenido, setContenido] = useState<string>(
        packageToShip&&packageToShip.contenido?packageToShip.contenido
        :
        ''
    )
    const [qty, setQuantity] = useState<number|null>(
        packageToShip&&packageToShip.qty?packageToShip.qty
        :null
    )
    const [tipoServicio, setTipoServicio] = useState<string>( //this may go away with 3rd step
        packageToShip&&packageToShip.tipoServicio?packageToShip.tipoServicio
        :
        shipmentServiceTypes[0]
    )
    const [largo, setLargo] = useState<number|null>(
        packageToShip&&packageToShip.largo?packageToShip.largo
        :null
    )
    const [ancho, setAncho] = useState<number|null>(
        packageToShip&&packageToShip.ancho?packageToShip.ancho
        :null
    )
    const [alto, setAlto] = useState<number|null>(
        packageToShip&&packageToShip.alto?packageToShip.alto
        :null
    )
    const [correo, setCorreo] = useState<string>(
        packageToShip&&packageToShip.correo?packageToShip.correo
        :''
    )
    const [pesoVol, setPesoVol] = useState<number|null>(
        packageToShip&&packageToShip.pesoVol?packageToShip.pesoVol
        :null
    )
    const [comments, setComments] = useState<string>(
        packageToShip&&packageToShip.comments?packageToShip.comments
        :''
    )
    const [unitKey, setUnitKey] = useState<string>(
        packageToShip&&packageToShip.unitKey?packageToShip.unitKey
        :''
    )
    const [goodsTransported, setGoodsTransported] = useState<string>(
        packageToShip&&packageToShip.goodsTransported?packageToShip.goodsTransported
        :''
    )//valueGoods
    const [valueGoods, setValueGoods] = useState<number|null>(
        packageToShip&&packageToShip.valueGoods?packageToShip.valueGoods
        :null
    )

    const [formValid, setFormValid] = useState<boolean>(false);
    const [invalidPeso, setInvalidPeso] = useState<boolean>(false);
    const [invalidEnvio, setInvalidEnvio] = useState<boolean>(false);
    const [invalidContenido, setInvalidContenido] = useState<boolean>(false);
    const [invalidQty, setInvalidQty] = useState<boolean>(false);
    const [invalidLargo, setInvalidLargo] = useState<boolean>(false);
    const [invalidAncho, setInvalidAncho] = useState<boolean>(false);
    const [invalidAlto, setInvalidAlto] = useState<boolean>(false);
    const [invalidCorreo, setInvalidCorreo] = useState<boolean>(false);
    const [invalidComments, setInvalidComments] = useState<boolean>(false);
    
    useEffect(() => {
        if(!invalidAlto&&!invalidLargo&&!invalidAncho&&largo&&ancho&&alto){
            const pesoVolCalc= Math.round(((largo*ancho*alto)/5000)*100)/100
            setPesoVol(pesoVolCalc)
        }
        if((
                peso!==null&&
                envio!== null&&
                contenido!== null&&
                qty!== null&&
                largo!== null&&
                ancho!== null&&
                alto!== null&&
                pesoVol!== null
            ) 
            && 
            (
                !invalidPeso&&
                !invalidEnvio&&
                !invalidContenido&&
                !invalidQty&&
                !invalidLargo&&
                !invalidAncho&&
                !invalidAlto&&
                !invalidCorreo&&
                !invalidComments
            )
            ){
                setFormValid(true)
                let packageData: PackageBasicData = {
                    peso:peso?peso:0,
                    envio:envio?envio:'',
                    contenido:contenido?contenido:'',
                    qty:qty?qty:0,
                    // tipoServicio: tipoServicio.slice(-3),
                    largo:largo?largo:0,
                    ancho:ancho?ancho:0,
                    alto:alto?alto:0,
                    correo:correo?correo:'',
                    pesoVol:pesoVol?pesoVol:0,
                    comments:comments?comments:'',
                    pesoFinal : -1,
                    unitKey: unitKey?unitKey:'',
                    valueGoods:valueGoods?valueGoods:0,
                    goodsTransported: goodsTransported?goodsTransported:''
                }
                const setStore:boolean = true
                dispatch<any>(setPackageToShip(setStore, packageData))
            }else{
                setFormValid(false)
                const setStore:boolean = false
                dispatch<any>(setPackageToShip(setStore))
        }
  
    }, [
        dispatch,
        peso,
        envio,
        contenido,
        qty,
        tipoServicio,
        largo,
        ancho,
        alto,
        correo,
        pesoVol,
        comments,
        unitKey,
        goodsTransported,
        valueGoods,
        invalidPeso,
        invalidEnvio,
        invalidContenido,
        invalidQty,
        invalidLargo,
        invalidAncho,
        invalidAlto,
        invalidCorreo,
        invalidComments,
        formValid
    ])

    const pesoHandler = (e:any) => {
        setPeso(parseFloat(e))
        setInvalidPeso(validatePositiveNumber(e))
    }
    const envioHandler = (e:SelectChangeEvent) => {
        const value=e.target.value as string
        setEnvio(value)
        if(value == packageTypes[1]){ //si sobre 
            setSkipDimnesionCheck(true);
            pesoHandler(1);
            altoHandler(1);
            anchoHandler(1);
            largoHandler(1);
        }
        if(value == packageTypes[0]){ //si caja 
            setSkipDimnesionCheck(false);
            pesoHandler(undefined);
            altoHandler(undefined);
            anchoHandler(undefined);
            largoHandler(undefined);
        }
        setInvalidEnvio(validateLengthInput(50,value))
    }
    
    const contenidoHandler = (e:any) => {
        setContenido(e)
        setInvalidContenido(validateLengthInput(50,e))
    }
    
    const serviceTypeHandler = (e:SelectChangeEvent) => {
        const value=e.target.value as string
        setTipoServicio(value)
    }

    const quantityHandler = (e:any) => {
        setQuantity(parseFloat(e))
        setInvalidQty(validatePositiveNumber(e))
    }
    const largoHandler = (e:any) => {
        setLargo(parseFloat(e))
        setInvalidLargo(validatePositiveNumber(e))
    }
    const anchoHandler = (e:any) => {
        setAncho(parseFloat(e))
        setInvalidAncho(validatePositiveNumber(e))
    }
    const altoHandler = (e:any) => {
        setAlto(parseFloat(e))
        setInvalidAlto(validatePositiveNumber(e))
    }
    const correoHandler = (e:any) => {
        console.log("correo", e,e =="",validateEmail(e));
        setCorreo(e)
        setInvalidCorreo(e ==""?false:validateEmail(e))
    }
    const commentsHandler = (e:any) => {
        setComments(e)
        setInvalidComments(validateLengthInput(25,e))
    }
    const unitKeyHandler = (e:any) => {
        console.log("unitKeyHandler: ",e);
        setUnitKey(e)
        //setInvalidComments(validateLengthInput(25,e))
    }
    const goodsTransportedHandler = (e:any) => {
        console.log("goodsTransportedHandler: ",e)
        setGoodsTransported(e)
        //setInvalidComments(validateLengthInput(25,e))
    }
    const valueGoodsHandler = (e:any) => {
        setValueGoods(parseFloat(e))
        setInvalidPeso(validatePositiveNumber(e))
        console.log("goodsTransportedHandler: ",e)
        //setInvalidComments(validateLengthInput(25,e))
    }

    return (
        <Card elevation={0}>
            <CardContent>
                <Box 
                    component="form"
                    sx={{
                        width:'100%',
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={2}>
                            <FormControl sx={{my:1}} color="secondary" size='small' fullWidth>
                                <InputLabel id="package-type-label">Tipo de Paquete</InputLabel>
                                <Select
                                    labelId="package-type-label"
                                    id="package-type"
                                    value={envio}
                                    label="Tipo de Paquete"
                                    onChange={envioHandler}
                                >
                                    { packageTypes.map((type: string, index:number) => {
                                            return(
                                                <MenuItem key={index}  sx={{boxShadow:0}} value={type}>{type}</MenuItem>
                                            )
                                        }
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                required
                                color="secondary"
                                size='small'
                                value={qty?qty:''}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => quantityHandler(e.currentTarget.value)}
                                label="No. Guías"
                                type="number"
                                error={invalidQty}
                                helperText={invalidQty ?'Ingresa un número mayor a 0':null}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                required
                                color="secondary"
                                size='small'
                                value={contenido}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => contenidoHandler(e.currentTarget.value)}
                                label="Contenido"
                                placeholder='Ropa y Accesorios'
                                type="text"
                                error={invalidContenido}
                                helperText={
                                    invalidContenido && contenido?
                                        `Intenta un texto más corto${contenido.length}/40`
                                    :null
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                required
                                color="secondary"
                                variant='standard'
                                //size='small'
                                value={peso?peso:''}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => pesoHandler(e.currentTarget.value)}
                                label="Peso (KG)"
                                type="number"
                                error={invalidPeso}
                                disabled={skipDimensionCheck}
                                helperText={ invalidPeso ? 'Ingresa un número mayor a 0':null}
                            />
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <FormControl sx={{my:1}} color="secondary" size='small' fullWidth>
                                <InputLabel id="package-type-label">Tipo de Servicio</InputLabel>
                                <Select
                                    labelId="package-type-label"
                                    id="package-type"
                                    value={tipoServicio}
                                    label="Tipo de Servicio"
                                    onChange={serviceTypeHandler}
                                >
                                    { shipmentServiceTypes.map((type: string, index:number) => {
                                            return(
                                                <MenuItem key={index}  sx={{boxShadow:0}} value={type}>{type}</MenuItem>
                                            )
                                        }
                                    )}
                                </Select>
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={12} md={2}>
                            <TextField
                                required
                                color="secondary"
                                variant='standard'
                                value={largo?largo:''}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => largoHandler(e.currentTarget.value)}
                                label="Largo (cm)"
                                type="number"
                                error={invalidLargo}
                                disabled={skipDimensionCheck}
                                helperText={invalidLargo ? 'Ingresa un número mayor a 0':null}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                required
                                color="secondary"
                                variant='standard'
                                value={ancho?ancho:''}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => anchoHandler(e.currentTarget.value)}
                                label="Ancho (cm)"
                                type="number"
                                error={invalidAncho}
                                disabled={skipDimensionCheck}
                                helperText={invalidAncho ? 'Ingresa un número mayor a 0':null}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                required
                                color="secondary"
                                variant='standard'
                                value={alto?alto:''}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => altoHandler(e.currentTarget.value)}
                                label="Alto (cm)"
                                type="number"
                                error={invalidAlto}
                                disabled={skipDimensionCheck}
                                helperText={invalidAlto? 'Ingresa un número mayor a 0':null}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant="caption">
                                Peso Volumétrico (kg)
                            </Typography>
                            <Typography variant="h6" sx={{fontWeight:600}}>
                               {pesoVol?pesoVol:0} kg
                            </Typography>
                        </Grid>
                        {/*<Grid item xs={12} md={4}>
                            <TextField
                                color="secondary"
                                variant='standard'
                                value={correo}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => correoHandler(e.currentTarget.value)}
                                label="Correo"
                                type="email"
                                error={invalidCorreo}
                                helperText={
                                    invalidCorreo && correo?
                                        'Ingresa un correo válido'
                                    :null
                                }
                            />
                            </Grid>*/}
                        <Grid item xs={12} md={6}>
                            <TextField
                                color="secondary"
                                variant='standard'
                                value={comments}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => commentsHandler(e.currentTarget.value)}
                                label="Referencia"
                                type="text"
                                error={invalidComments}
                                helperText={
                                    invalidComments && comments?
                                        `Intenta un texto más corto${comments.length}/25`
                                    :null
                                }
                            />
                        </Grid>
                        
                    </Grid>
                    <CardContent>
                    <Typography variant="subtitle1" sx={{fontWeight:600}}>
                          Información Carta Porte
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={2}>
                            <FormControl sx={{my:1}} color="secondary" size='small' fullWidth>
                                <InputLabel id="package-type-label">Clave unidad</InputLabel>
                                <Select
                                    labelId="package-type-label"
                                    id="package-type"
                                    value={unitKey?unitKey:''}
                                    label="Clave unidad"
                                    onChange={(e:any) => unitKeyHandler(e.target.value)}
                                >
                                    { unitKeyArray.map((unitKeyArr) => {
                                            return(
                                                <MenuItem 
                                                key={unitKeyArr.unitKey}  sx={{boxShadow:0}} value={unitKeyArr.unitKey}>{unitKeyArr.label}</MenuItem>
                                            )
                                        }
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                required
                                color="secondary"
                                size='small'
                                value={valueGoods?valueGoods:''}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => valueGoodsHandler(e.currentTarget.value)}
                                label="Valor de Mercancia (moneda MX)"
                                type="number"
                                error={invalidAlto}
                                helperText={invalidAlto? 'Ingresa un número mayor a 0':null}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                color="secondary"
                                size='small'
                                value={goodsTransported?goodsTransported:''}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => goodsTransportedHandler(e.currentTarget.value)}
                                label="Claves de Productos y Servicios"
                                type="text"
                                error={invalidComments}
                                helperText={
                                    invalidComments && goodsTransported?
                                        `Intenta un texto más corto${goodsTransported.length}/25`
                                    :null
                                }
                            />
                        </Grid>
                    </Grid>
                    </CardContent>
                </Box>
            </CardContent>
        </Card>
    )
}

const MemoizedPackageForShipment = React.memo(PackageForShipmentForm)

export default MemoizedPackageForShipment