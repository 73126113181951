import { carrierServiceType } from './apiTypes';
import { UserBasicData } from './authTypes';

export const SET_STORES = 'SET_STORES';
export const SET_COMISSIONS = 'SET_COMISSIONS';
export const SET_CARRIER_PRICES = 'SET_CARRIER_PRICES';
export const SET_CARRIER_PRICE_TO_EDIT = 'SET_CARRIER_PRICE_TO_EDIT,';
export const SET_EDIT_STORE = 'SET_EDIT_STORE';

export interface StoreState {
    stores: Array<Store> | null
    comissions: Array<ComissionSaved> | null
    carrierPrices: Array<CarrierPrice> | null
    carrierPriceToEdit: CarrierPrice | null
    editStore: Store | null
}

export interface Store {
    _id: string,
    storeName: string,
    storeAddress: string,
    storeNeighborhood: string,
    storeCity: string,
    storePhone: string,
    storeSupervisor?: UserBasicData
    createdAt?: any,
    createdBy?: string,
    lastModifiedAt?: any,
    lastModifiedBy?: string,
    avatarStorageRef?:string,
    storeAvatar?:string,
    members?: Array<string>
}

export interface CarrierPrice  {
    [x: string]: any;
    _id: string,
    apiProvider: string,
    carrier: string,
    name: string,
    active: boolean,
    days: number,
    kgStart: number,
    kgEnd: number,
    amount: number,
    cost: number, 
    costExtendedZone: number, 
    code?: string,
    traceable?: boolean,
    pickup?: boolean,
    useQuotedRates?: boolean,
    serviceType?: carrierServiceType,
    priceExtraKg?: number,
    zone?: string,
    priceExtendedZone?: number,
    customerPct?: number,
    exRate?: number,
    createdAt?: any,
    createdBy?: string,
    lastModifiedAt?: any,
    lastModifiedBy?: string,
    priceScope?: string,
    customerId?: string,
    percentage:number,
    IsPercentagedPrice:boolean,
    priceForCustomer?: {
        uid: string;
        fullName: string;
        email: string;
        userAvatar?: string | null;
    }
    kilajeArr: Array<kilajeData>,
    dynamicPrice: boolean,
}

export interface kilajeData{
    kgStart:number|null,
    kgEnd:number|null,
    amount:number|null,
    cost:number|null,
    priceExtendedZone:number|null,
    costExtendedZone:number|null,
    percentage:number|null
}

export interface UserDirectoryFav {
    _id: string,
    createdBy: string,
    createdAt?: any,
    lastModifiedAt?: any,
    lastModifiedBy?: string,
    alias?: string,
    fullName?: string,
    phone?: string,
    zipCode?: string,
    address?: string,
    address2?:string,
    email?: string,
    neighborhood?: string,
    city?: string,
    state?: string,
    country?: string,
    company?: string,
    reference?: string,
    rfc?:string
}

export interface ComissionSaved {
    _id:string,
    storeId:string,
    supervisorId:string,
    submittedBy:string,
    submittedAt?: any, 
    createDate?: Date,
    monthYear?: string, // maybe we filter by this if dates cannot work?
    comissions?: Array<ComissionItem>,
    notes?: string

}

export interface ComissionType {
    _id:string,
    type: string,
    name: string,
    subtypes?: [{amount?:number, name?:string}],
    amount?: number,
    percentage?: number
}

export interface ComissionItem {
    type?: number,
    name?: string,
    amount?: number,
    qty?: number,
    cost?: number,
    percentage?:number,
    todayDate?: string,
    subtype?: string,
    totalAmount?: number,
}

export interface PackageBasicData {
    peso: number,
    envio: string,
    contenido: string,
    qty: number,
    largo: number,
    ancho: number,
    alto: number,
    tipoServicio?: string,
    correo?: string,
    pesoVol?: number,
    pesoFinal:number,
    comments?: string,
    unitKey?:string,
    goodsTransported?:string,
    valueGoods?:number
}

export interface OptionsForShipment {
    traceable:boolean,
    pickup:boolean,
    useQuotedRates:boolean,
    serviceType: string
}

export interface PackagesShipped {
    type?: number,
    name?: string,
    amount?: number,
    qty?: number,
    date?: string,
    todayDate?: string,
    subtype?: string,
}

export interface carrierExtendedZone{
    carrier : string,
    isExtendedZone: boolean
}

interface SetStoriesAction {
    type: typeof SET_STORES;
    payload: Array<Store>;
}

interface SetComissionsAction {
    type: typeof SET_COMISSIONS;
    payload: Array<ComissionSaved>;
}

interface SetCarrierPrices {
    type: typeof SET_CARRIER_PRICES;
    payload: Array<CarrierPrice>;
}

interface SetCarrierPriceToEdit {
    type: typeof SET_CARRIER_PRICE_TO_EDIT;
    payload: CarrierPrice | null;
}

interface SetEditStoreAction {
    type: typeof SET_EDIT_STORE;
    payload: Store | null;
} 

export type StoreAction = SetStoriesAction | SetComissionsAction | SetCarrierPrices | SetCarrierPriceToEdit | SetEditStoreAction
