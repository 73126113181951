export const comissionsSales: string[] = [
    'Accesorios',
    'Equipos',
    'Reparaciones',
    'Planes',
    'Portabilidades',
    'Chips'
]

export const deductionsSales: string [] = [
    'Retardos',
    'Faltantes',
    'Préstamos'
]

export const activeInactive: string [] = [
    'Activo',
    'Suspendido'
]

export const carrierServiceTypes: string [] =[
    'Nacional',
    'Zonas',
    'Internacional'
]

export const shipmentStatus: string [] =[
    'Iniciado',
    'Información de paquete generada',
    'No enviado',
    'En progreso',
    'Enviado',
]

export const publicFinancialMetrics: string [] = [
    'Pagado',
    'Venta del periodo',
]

export const adminFinancialMetrics: string [] = [
    'Pagado',
    'Venta del periodo',
    'Costo del periodo',
    'Utilidad plataforma',
    'Utilidad real'
]
