export const dialogTypes: string[] = [
    'createShipment', //0
    'createQuote', //1
    'createFavorite', //2 
    'trackShipment',//3
    'addBalance', //4
    'shipmentFailed', //5
    'addUserPrice', //6
    'editUserPrice', //7
    'bulkReport' //8
]

export const apiProviders: string[] = [
    'Estafeta', //0
    'Fedex',//1
    'Redpack', //2
    'DHL', //3
    'Fedex Jaro', //4
    'EnviosHappy' //5
]

export const directionStepsForms: string[] = [
    'backwards',
    'forwards'
]

export const packageTypes: string[] = [
    'Caja',
    'Sobre' // sobre no req largo ancho ni algo
]

export const adjustmentBalanceTypes: string[] = [
    'Agregar',
    'Quitar'
]

export const balanceType: string [] = [
    'Saldo',
    'Depósito'
]

export const directoryRole: string[] = [
    'Origen',
    'Destino'
]

export const shipmentServiceTypes: string [] = [
    'Standard - STD',
    'Express - EXP'
]

export const priceScopeTypes: string [] = [
    'Global',
    'Cliente'
]

export const environment: string [] = [
    'FirebaseSandboxOneMartian', //0  Onemartian firebase account
    'FirebaseDevEnviosHappy', //1 Envios happy firebase account
    'FirebaseProdEnviosHappy' //2 Envios happy firebase account
]

export const defaultAssets: any = {
    defaultImageForStores: 'https://images.pexels.com/photos/6169033/pexels-photo-6169033.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
}

export const mexStates: Array<{label:string, satId: string, fedex : string}> = [
{label:"Aguascalientes", satId:"AGU", fedex : "AG"},
{label:"Baja California", satId:"BCN", fedex : "BC"},
{label:"Baja California Sur", satId:"BCS", fedex : "BS"},
{label:"Campeche", satId:"CAM", fedex : "CM"},
{label:"Chiapas", satId:"CHP", fedex : "CS"},
{label:"Chihuahua", satId:"CHH", fedex : "CH"},
{label:"Coahuila", satId:"COA", fedex : "CO"},
{label:"Colima", satId:"COL", fedex : "CL"},
{label:"Ciudad de México", satId:"DIF", fedex : "DF"},
{label:"Durango", satId:"DUR", fedex : "DG"},
{label:"Guanajuato", satId:"GUA", fedex : "GT"},
{label:"Guerrero", satId:"GRO", fedex : "GR"},
{label:"Hidalgo", satId:"HID", fedex : "HG"},
{label:"Jalisco", satId:"JAL", fedex : "JA"},
{label:"México", satId:"MEX", fedex : "EM"},
{label:"Michoacán", satId:"MIC", fedex : "MI"},
{label:"Morelos", satId:"MOR", fedex : "MO"},
{label:"Nayarit", satId:"NAY", fedex : "NA"},
{label:"Nuevo León", satId:"NLE", fedex : "NL"},
{label:"Oaxaca", satId:"OAX", fedex : "OA"},
{label:"Puebla", satId:"PUE", fedex : "PU"},
{label:"Querétaro", satId:"QUE", fedex : "QE"},
{label:"Quintana Roo", satId:"ROO", fedex : "QR"},
{label:"San Luis Potosí", satId:"SLP", fedex : "SL"},
{label:"Sinaloa", satId:"SIN", fedex : "SI"},
{label:"Sonora", satId:"SON", fedex : "SO"},
{label:"Tabasco", satId:"TAB", fedex : "TB"},
{label:"Tamaulipas", satId:"TAM", fedex : "TM"},
{label:"Tlaxcala", satId:"TLA", fedex : "TL"},
{label:"Veracruz", satId:"VER", fedex : "VE"},
{label:"Yucatán", satId:"YUC", fedex : "YU"},
{label:"Zacatecas", satId:"ZAC", fedex : "ZA"},
];

export const stateToID = (estado: string|undefined, type: string) : string =>{
    if(estado)
        for(let i = 0; i < mexStates.length; i ++){
            if(estado == mexStates[i].label){
                if(type == "satid")
                    return mexStates[i].satId;
                if(type == "fedex")
                    return mexStates[i].fedex;
            }
        }
    return "";
}

export const pricesTypes: string [] = [
    'Porcentaje',
    'Fijo'
]

export const availableCountries: string[] = ["MEX"];

export const unitKeyArray: Array<{unitKey:string, label: string}> = [
    {unitKey:"H87",label:"Pieza"},
    {unitKey:"EA",label:"Elemento"},
    {unitKey:"E48",label:"Unidad de Servicio"},
    {unitKey:"ACT",label:"Actividad"},
    {unitKey:"KGM",label:"Kilogramo"},
    {unitKey:"E51",label:"Trabajo"},
    {unitKey:"A9",label:"Tarifa"},
    {unitKey:"MTR",label:"Metro"},
    {unitKey:"AB",label:"Paquete a granel"},
    {unitKey:"BB",label:"Caja base"},
    {unitKey:"KT",label:"Kit"},
    {unitKey:"SET",label:"Conjunto"},
    {unitKey:"LTR",label:"Litro"},
    {unitKey:"XBX",label:"Caja"},
    {unitKey:"MON",label:"Mes"},
    {unitKey:"HUR",label:"Hora"},
    {unitKey:"MTK",label:"Metro cuadrado"},
    {unitKey:"11",label:"Equipos"},
    {unitKey:"MGM",label:"Miligramo"},
    {unitKey:"XPK",label:"Paquete"},
    {unitKey:"XKI",label:"Kit (Conjunto de piezas)"},
    {unitKey:"AS",label:"Variedad"},
    {unitKey:"GRM",label:"Gramo"},
    {unitKey:"PR",label:"Par"},
    {unitKey:"DPC",label:"Docenas de piezas"},
    {unitKey:"xun",label:"Unidad"},
    {unitKey:"DAY",label:"Día"},
    {unitKey:"XLT",label:"Lote"},
    {unitKey:"10",label:"Grupos"},
    {unitKey:"MLT",label:"Mililitro"},
    {unitKey:"E54",label:"Viaje"}
]


/*export const goodsTransportedArray: Array<{prodId:string, label: string}> = [
    {prodId:"01010101",label:"No existe en el catálogo"},
    {prodId:"10101500",label:"Animales vivos de granja"},
    {prodId:"10101501",label:"Gatos vivos"},
    {prodId:"10101502",label:"Perros"},
    {prodId:"10101504",label:"Visón"},
    {prodId:"10101505",label:"Ratas"},
    {prodId:"10101506",label:"Caballos"},
    {prodId:"10101507",label:"Ovejas"},
    {prodId:"10101508",label:"Cabras"},
    {prodId:"10101509",label:"Asnos"},
    {prodId:"10101510",label:"Ratones"},
    {prodId:"10101511",label:"Cerdos"},
    {prodId:"10101512",label:"Conejos"},
    {prodId:"10101513",label:"Cobayas o conejillos de indias"},
    {prodId:"10101514",label:"Primates"},
    {prodId:"10101515",label:"Armadillos"},
    {prodId:"10101516",label:"Ganado vacuno"},
    {prodId:"10101517",label:"Camellos"},
    {prodId:"10101600",label:"Pájaros y aves de corral vivos"},
    {prodId:"10101601",label:"Pollos vivos"},
    {prodId:"10101602",label:"Patos vivos"},
    {prodId:"10101603",label:"Pavos vivos"},
    {prodId:"10101604",label:"Gansos vivos"},
    {prodId:"10101605",label:"Faisanes vivos"},
    {prodId:"10101700",label:"Peces vivos"},
    {prodId:"10101701",label:"Salmones vivos"},
    {prodId:"10101702",label:"Trucha viva"},
    {prodId:"10101703",label:"Tilapia viva"},
    {prodId:"10101704",label:"Carpa viva"},
    {prodId:"10101705",label:"Anguilas vivas"},
    {prodId:"10101800",label:"Mariscos e invertebrados acuáticos vivos"},
    {prodId:"10101801",label:"Camarón vivo"},
    {prodId:"10101802",label:"Almejas vivas"},
    {prodId:"10101803",label:"Mejillones vivos"},
    {prodId:"10101804",label:"Ostras vivas"},
    {prodId:"10101805",label:"Cangrejos vivos"},
    {prodId:"10101806",label:"Abulones vivos"},
    {prodId:"10101807",label:"Pulpo vivo"},
    {prodId:"10101808",label:"Calamar vivo"},
    {prodId:"10101809",label:"Sanguijuelas"},
    {prodId:"10101900",label:"Insectos vivos"},
    {prodId:"10101901",label:"Mariposas"},
    {prodId:"10101902",label:"Escarabajos"},
    {prodId:"10101903",label:"Abejas"},
    {prodId:"10101904",label:"Gusanos de seda"},
    {prodId:"10111300",label:"Accesorios, equipo y tratamientos para los animales domésticos"},
    {prodId:"10111301",label:"Juguetes para mascotas"},
    {prodId:"10111302",label:"Productos para el aseo y cuidado de mascotas"},
    {prodId:"10111303",label:"Equipo para el manejo de desperdicios de las mascotas"},
    {prodId:"10111304",label:"Tazones o equipo para alimentación de mascotas"},
    {prodId:"10111305",label:"Tratamientos medicados para mascotas"},
    {prodId:"10111306",label:"Kits para el entrenamiento de mascotas domésticas"},
    {prodId:"10111307",label:"Cobijas para mascotas"},
    {prodId:"10121500",label:"Pienso para ganado"},
    {prodId:"10121501",label:"Salvado de trigo puro"},
    {prodId:"10121502",label:"Avena para forraje"},
    {prodId:"10121503",label:"Maíz para forraje"},
    {prodId:"10121504",label:"Sorgo para forraje"},
    {prodId:"10121505",label:"Heno"},
    {prodId:"10121506",label:"Tortas oleaginosas"},
    {prodId:"10121507",label:"Forraje compuesto"},
    {prodId:"10121600",label:"Alimento para pájaros y aves de corral"},
    {prodId:"10121601",label:"Alimento vivo para aves"},
    {prodId:"10121602",label:"Alpiste"},
    {prodId:"10121603",label:"Botanas o comida recreacional para aves"},
    {prodId:"10121604",label:"Alimento avícola"},
    {prodId:"10121700",label:"Alimento para peces"},
    {prodId:"10121701",label:"Salmuera fresca o congelada"},
    {prodId:"10121702",label:"Alimento granulado para peces"},
    {prodId:"10121703",label:"Alimento en hojuelas para peces"},
    {prodId:"10121800",label:"Alimento para perros y gatos"},
    {prodId:"10121801",label:"Comida seca para perros"},
    {prodId:"10121802",label:"Comida húmeda para perros"},
    {prodId:"10121803",label:"Leche para perros o gatos"},
    {prodId:"10121804",label:"Comida seca para gatos"},
    {prodId:"10121805",label:"Comida húmeda para gatos"},
    {prodId:"10121806",label:"Botanas o comida recreacional para gatos o perros"},
    {prodId:"10121900",label:"Alimento para roedores"},
    {prodId:"10121901",label:"Comida granulada para roedores"},
    {prodId:"10122100",label:"Comida para animales variados"},
    {prodId:"10122101",label:"Comida para cerdos"},
    {prodId:"10122102",label:"Comida para visones"},
    {prodId:"10122103",label:"Comida para monos"},
    {prodId:"10122104",label:"Comida para conejos"},
    {prodId:"10131500",label:"Cobertizos para animales"},
    {prodId:"10131506",label:"Establos para ganado"},
    {prodId:"10131507",label:"Casas para mascotas domesticadas"},
    {prodId:"10131508",label:"Camas para mascotas"},
    {prodId:"10131600",label:"Recipientes para animales"},
    {prodId:"10131601",label:"Jaulas o sus accesorios"},
    {prodId:"10131602",label:"Perreras"},
    {prodId:"10131603",label:"Equipaje para el transporte de animales"},
    {prodId:"10131604",label:"Correas para perros"},
    {prodId:"10141500",label:"Talabartería"},
    {prodId:"10141501",label:"Sillas de montar"},
    {prodId:"10141502",label:"Fustas y látigos"},
    {prodId:"10141503",label:"Herraduras para caballo"},
    {prodId:"10141504",label:"Herraduras para mula"},
    {prodId:"10141505",label:"Sillín o pelero"},
    {prodId:"10141600",label:"Arneses"},
    {prodId:"10141601",label:"Bridas"},
    {prodId:"10141602",label:"Yugos"},
    {prodId:"10141603",label:"Bocados para caballos"},
    {prodId:"10141604",label:"Riendas"},
    {prodId:"10141605",label:"Estribos"},
    {prodId:"10141606",label:"Correas o traíllas"},
    {prodId:"10141607",label:"Arneses de cuello para animales"},
    {prodId:"10141608",label:"Arneses o sus accesorios"},
    {prodId:"10141609",label:"Sujetadores"},
    {prodId:"10141610",label:"Bozales"},
    {prodId:"10141611",label:"Soportes para correas"},
    {prodId:"10151500",label:"Semillas y plántulas vegetales"},
    {prodId:"10151501",label:"Semillas o plántulas de fríjol"},
    {prodId:"10151502",label:"Semillas o plántulas de zanahoria"},
    {prodId:"10151503",label:"Semillas o plántulas de apio"},
    {prodId:"10151504",label:"Semillas o plántulas de chiles"},
    {prodId:"10151505",label:"Semillas o plántulas de calabacín"},
    {prodId:"10151506",label:"Semillas o plántulas de alverja"},
    {prodId:"10151507",label:"Semillas o plántulas de pepino cohombro"},
    {prodId:"10151508",label:"Semillas o plántulas de berenjena"},
    {prodId:"10151509",label:"Semillas o plántulas de endivias"},
    {prodId:"10151510",label:"Semillas o plántulas de ajo"},
    {prodId:"10151511",label:"Semillas o plántulas de puerro"},
    {prodId:"10151512",label:"Semillas o plántulas de lechuga"},
    {prodId:"10151513",label:"Semillas o plántulas de maíz"},
    {prodId:"10151514",label:"Semillas o plántulas de melón"},
    {prodId:"10151515",label:"Semillas o plántulas cebolla"},
    {prodId:"10151516",label:"Semillas o plántulas de soya"},
    {prodId:"10151517",label:"Semillas o plántulas de espinaca"},
    {prodId:"10151518",label:"Semillas o plántulas de tomate"},
    {prodId:"10151519",label:"Semillas o plántulas de nabo"},
    {prodId:"10151520",label:"Semillas o plántulas de acelga"},
    {prodId:"10151521",label:"Semillas o plántulas de pimiento morrón"},
    {prodId:"10151522",label:"Semillas o plántulas de remolacha"},
    {prodId:"10151523",label:"Semillas o plántulas de coliflor"},
    {prodId:"10151524",label:"Semillas o plántulas de perejil"},
    {prodId:"10151525",label:"Semillas o plántulas de brócoli"},
    {prodId:"10151526",label:"Semillas o plántulas de repollo"},
    {prodId:"10151527",label:"Semillas o plántulas de papa"},
    {prodId:"10151528",label:"Semillas o plántulas de batata"},
    {prodId:"10151529",label:"Semillas o plántulas de calabaza"},
    {prodId:"10151530",label:"Semillas o plántulas de rábano"},
    {prodId:"10151531",label:"Semillas o plántulas de repollitos de bruselas"},
    {prodId:"10151532",label:"Semillas o plántulas de ahuyama"},
    {prodId:"10151533",label:"Semillas o plántulas de okra"},
    {prodId:"10151534",label:"Semillas o plántulas de melón cantalupo"},
    {prodId:"10151535",label:"Semillas o plántulas de maní"},
    {prodId:"10151536",label:"Semillas o plántulas de caigua"},
    {prodId:"10151537",label:"Semillas o plántulas de espárrago"},
    {prodId:"10151538",label:"Semillas o plántulas de garbanzo"},
    {prodId:"10151539",label:"Semillas o plántulas de haba"},
    {prodId:"10151600",label:"Semillas de cereales"},
    {prodId:"10151601",label:"Semillas de trigo"},
    {prodId:"10151602",label:"Semillas de canola"},
    {prodId:"10151603",label:"Semillas de cebada"},
    {prodId:"10151604",label:"Semillas de mijo"},
    {prodId:"10151605",label:"Semillas de avena"},
    {prodId:"10151606",label:"Semillas de ajonjolí"},
    {prodId:"10151607",label:"Semillas de linaza"},
    {prodId:"10151608",label:"Semillas de aceite de ricino"},
    {prodId:"10151609",label:"Semillas de maíz"},
    {prodId:"10151610",label:"Semillas de centeno"},
    {prodId:"10151611",label:"Semillas de sorgo"},
    {prodId:"10151612",label:"Semillas o plántulas de kiwicha"},
    {prodId:"10151613",label:"Semillas o plántulas de quínoa"},
    {prodId:"10151700",label:"Semillas y plántulas de hierba y forraje"},
    {prodId:"10151701",label:"Semillas o plántulas de arroz"},
    {prodId:"10151702",label:"Semillas o plántulas de trébol"},
    {prodId:"10151703",label:"Semillas o plántulas de alfalfa"},
    {prodId:"10151704",label:"Semillas o plántulas de pasto"},
    {prodId:"10151705",label:"Semillas o plántulas de veza (gachas / guija)"},
    {prodId:"10151706",label:"Semillas o plántulas de guar"},
    {prodId:"10151800",label:"Semillas y plántulas de especias"},
    {prodId:"10151801",label:"Semillas o plántulas de pimienta"},
    {prodId:"10151802",label:"Semillas o plántulas de vainilla"},
    {prodId:"10151803",label:"Semillas o plántulas de canela"},
    {prodId:"10151804",label:"Semillas o plántulas de clavo de olor"},
    {prodId:"10151805",label:"Semillas o plántulas de cilantro"},
    {prodId:"10151806",label:"Semillas o plántulas de jengibre"},
    {prodId:"10151807",label:"Semillas o plántulas de azafrán"},
    {prodId:"10151808",label:"Semillas o plántulas de tomillo"},
    {prodId:"10151809",label:"Semillas o plántulas de curry"},
    {prodId:"10151810",label:"Semillas o plántulas de mostaza"},
    {prodId:"10151811",label:"Semillas o plántulas de ginseng"},
    {prodId:"10151812",label:"Semillas o plántulas de champiñones"},
    {prodId:"10151813",label:"Semillas o plántulas de sacha inchi"},
    {prodId:"10151814",label:"Semillas o plántulas de achiote"},
    {prodId:"10151815",label:"Semillas o plántulas de kudzu"},
    {prodId:"10151816",label:"Semillas o plántulas de albahaca"},
    {prodId:"10151817",label:"Semillas o plántulas de anís"},
    {prodId:"10151900",label:"Semillas, bulbos, plántulas y esquejes de flores"},
    {prodId:"10151901",label:"Semillas, bulbos, plántulas o esquejes de tulipán"},
    {prodId:"10151902",label:"Semillas, plántulas o esquejes de rosa"},
    {prodId:"10151903",label:"Semillas, bulbos, plántulas o esquejes de narciso"},
    {prodId:"10151904",label:"Semillas de girasol"},
    {prodId:"10151906",label:"Bulbos de lirio"},
    {prodId:"10151907",label:"Semillas o plántulas de veza"},
    {prodId:"10152000",label:"Semillas y esquejes de árboles y arbustos"},
    {prodId:"10152001",label:"Semillas o esquejes de árboles frutales"},
    {prodId:"10152002",label:"Semillas o esquejes de coníferas"},
    {prodId:"10152003",label:"Semillas o esquejes de árboles de frutos secos"},
    {prodId:"10152004",label:"Plántulas de latifoliados"},
    {prodId:"10152005",label:"Plántulas de coníferas"},
    {prodId:"10152006",label:"Semillas o yemas de pino"},
    {prodId:"10152007",label:"Semillas o yemas de algarrobo"},
    {prodId:"10152100",label:"Residuos que no sean de piensos"},
    {prodId:"10152101",label:"Extracción de los residuos de semilla de babool"},
    {prodId:"10152102",label:"Residuos de semilla de colza"},
    {prodId:"10152103",label:"Residuo de semillas de linaza"},
    {prodId:"10152104",label:"Torta de neem"},
    {prodId:"10152200",label:"Semillas de cultivos fibrosos y semilleros"},
    {prodId:"10152201",label:"Semillas o plántulas de algodón"},
    {prodId:"10152202",label:"Semillas o plántulas de lino"},
    {prodId:"10152300",label:"Plántulas y semillas de leguminosas"},
    {prodId:"10161500",label:"Árboles y arbustos"},
    {prodId:"10161501",label:"Aceitunos"},
    {prodId:"10161502",label:"Cafetos"},
    {prodId:"10161503",label:"Cacaoteros"},
    {prodId:"10161504",label:"Manzanos"},
    {prodId:"10161505",label:"Peros"},
    {prodId:"10161506",label:"Naranjos"},
    {prodId:"10161507",label:"Rododendros"},
    {prodId:"10161508",label:"Plantas de te"},
    {prodId:"10161509",label:"Coníferas"},
    {prodId:"10161510",label:"Abetos rojos (píceas)"},
    {prodId:"10161511",label:"Pinos"},
    {prodId:"10161512",label:"Abetos"},
    {prodId:"10161513",label:"Palmeras"},
    {prodId:"10161514",label:"Casuarina"},
    {prodId:"10161515",label:"Ciprés"},
    {prodId:"10161516",label:"Eucalipto"},
    {prodId:"10161517",label:"Árbol de quinua"},
    {prodId:"10161518",label:"Magnolio"},
    {prodId:"10161519",label:"Mioporo"},
    {prodId:"10161520",label:"Acalifa"},
    {prodId:"10161521",label:"Tecomaria capensis o madreselva del cabo"},
    {prodId:"10161522",label:"Arbusto croton bolaina"},
    {prodId:"10161523",label:"Abutillón"},
    {prodId:"10161524",label:"Ficus"},
    {prodId:"10161525",label:"Lúcumo"},
    {prodId:"10161526",label:"Aguacatal"},
    {prodId:"10161527",label:"Guanábano"},
    {prodId:"10161528",label:"Carambolo"},
    {prodId:"10161529",label:"Ciruelo"},
    {prodId:"10161530",label:"Árbol de quince"},
    {prodId:"10161600",label:"Plantas florales"},
    {prodId:"10161602",label:"Poinsettias"},
    {prodId:"10161604",label:"Azaleas"},
    {prodId:"10161605",label:"Cactos"},
    {prodId:"10161606",label:"Ageratum púrpura"},
    {prodId:"10161800",label:"Plantas sin flor"},
    {prodId:"10161801",label:"Helechos"},
    {prodId:"10161802",label:"Hiedras"},
    {prodId:"10161803",label:"Filodendros"},
    {prodId:"10161804",label:"Líquenes"},
    {prodId:"10161805",label:"Vides"},
    {prodId:"10161900",label:"Productos florales secos"},
    {prodId:"10161901",label:"Vainas secas"},
    {prodId:"10161902",label:"Follaje seco"},
    {prodId:"10161903",label:"Helechos secos"},
    {prodId:"10161905",label:"Ramas y tallos secos"},
    {prodId:"10161906",label:"Penachos de gramíneas secos"},
    {prodId:"10161907",label:"Flores secas prensadas"},
    {prodId:"10161908",label:"Pétalos secos"},
    {prodId:"10171500",label:"Abonos orgánicos y nutrientes para plantas"},
    {prodId:"10171501",label:"Estiércol o guano"},
    {prodId:"10171502",label:"Hormonas para plantas"},
    {prodId:"10171503",label:"Harina de pescado"},
    {prodId:"10171504",label:"Abono"},
    {prodId:"10171505",label:"Nutriente foliar"},
    {prodId:"10171506",label:"Humus"},
    {prodId:"10171600",label:"Abonos químicos y nutrientes para plantas"},
    {prodId:"10171601",label:"Fertilizante nitrogenado"},
    {prodId:"10171602",label:"Fertilizante de potasio"},
    {prodId:"10171603",label:"Fertilizante de fósforo"},
    {prodId:"10171604",label:"Fertilizante de sulfuro"},
    {prodId:"10171605",label:"Mezclas de nitrógeno - fósforo - potasio - npk"},
    {prodId:"10171606",label:"Fertilizarte de sílice puro"},
    {prodId:"10171607",label:"Fertilizante con magnesio"},
    {prodId:"10171608",label:"Fertilizante micro elemento"},
    {prodId:"10171609",label:"Fertilizante fosfato de sílice"},
    {prodId:"10171610",label:"Fertilizante potasio de sílice"},
    {prodId:"10171611",label:"Fertilizante de calcio"},
    {prodId:"10171700",label:"Herbicidas"},
    {prodId:"10171701",label:"Matamalezas"},
    {prodId:"10171702",label:"Fungicidas"},
    {prodId:"10171800",label:"Acondicionadores de suelos"},
    {prodId:"10171801",label:"Acondicionador orgánico de suelos"},
    {prodId:"10171802",label:"Acondicionador inorgánico de suelos"},
    {prodId:"10191500",label:"Pesticidas o repelentes de plagas"},
    {prodId:"10191506",label:"Mata - roedores"},
    {prodId:"10191507",label:"Repelentes de aves"},
    {prodId:"10191508",label:"Protectores contra termitas"},
    {prodId:"10191509",label:"Insecticidas"},
    {prodId:"10191510",label:"Abamectina"},
    {prodId:"10191511",label:"Fipronil"},
    {prodId:"10191700",label:"Dispositivos para control de plagas"},
    {prodId:"10191701",label:"Trampas para control animal"},
    {prodId:"10191703",label:"Trampas para el control de insectos voladores"},
    {prodId:"10191704",label:"Matamoscas"},
    {prodId:"10191705",label:"Lazos"},
    {prodId:"10191706",label:"Cepos"},
    {prodId:"10191707",label:"Repelente ultrasónico de pestes"},
    {prodId:"10202100",label:"Rosales vivos anaranjados"},
    {prodId:"10202101",label:"Rosal vivo alhambra"},
    {prodId:"10202102",label:"Rosal vivo aloha"},
    {prodId:"10202103",label:"Rosal vivo amber"},
    {prodId:"10202104",label:"Rosal vivo apache"},
    {prodId:"10202105",label:"Rosal vivo arabia"},
    {prodId:"10202106",label:"Rosal vivo bengala"},
    {prodId:"10202107",label:"Rosal vivo bibi"},
    {prodId:"10202108",label:"Rosal vivo caramba"},
    {prodId:"10202109",label:"Rosal vivo caramella"},
    {prodId:"10202110",label:"Rosal vivo carla"},
    {prodId:"10202111",label:"Rosal vivo cartagena"},
    {prodId:"10202112",label:"Rosal vivo chanson"},
    {prodId:"10202113",label:"Rosal vivo charmer"},
    {prodId:"10202114",label:"Rosal vivo cherry brandy"},
    {prodId:"10202115",label:"Rosal vivo chilis"},
    {prodId:"10202116",label:"Rosal vivo cinnamon"},
    {prodId:"10202117",label:"Rosal vivo colandro"},
    {prodId:"10202118",label:"Rosal vivo coral sea"},
    {prodId:"10202119",label:"Rosal vivo corvette o red corvette"},
    {prodId:"10202120",label:"Rosal vivo dark milva"},
    {prodId:"10202121",label:"Rosal vivo donna"},
    {prodId:"10202122",label:"Rosal vivo dreamer"},
    {prodId:"10202123",label:"Rosal vivo el dorado"},
    {prodId:"10202124",label:"Rosal vivo el toro"},
    {prodId:"10202125",label:"Rosal vivo elena"},
    {prodId:"10202126",label:"Rosal vivo ensueño"},
    {prodId:"10202127",label:"Rosal vivo euforia"},
    {prodId:"10202128",label:"Rosal vivo exótica"},
    {prodId:"10202129",label:"Rosal vivo fancy amazon"},
    {prodId:"10202130",label:"Rosal vivo fiction"},
    {prodId:"10202131",label:"Rosal vivo finess"},
    {prodId:"10202132",label:"Rosal vivo flameco"},
    {prodId:"10202133",label:"Rosal vivo free spirit"},
    {prodId:"10202134",label:"Rosal vivo gelato"},
    {prodId:"10202135",label:"Rosal vivo gypsy curiosa"},
    {prodId:"10202136",label:"Rosal vivo high and magic"},
    {prodId:"10202137",label:"Rosal vivo high and orange magic"},
    {prodId:"10202138",label:"Rosal vivo iguana o alegra"},
    {prodId:"10202139",label:"Rosal vivo impulse"},
    {prodId:"10202140",label:"Rosal vivo indian femma"},
    {prodId:"10202141",label:"Rosal vivo indian sunset"},
    {prodId:"10202142",label:"Rosal vivo karusso"},
    {prodId:"10202143",label:"Rosal vivo kerio"},
    {prodId:"10202144",label:"Rosal vivo kiki"},
    {prodId:"10202145",label:"Rosal vivo latin circus"},
    {prodId:"10202146",label:"Rosal vivo leonisa"},
    {prodId:"10202147",label:"Rosal vivo lipstick"},
    {prodId:"10202148",label:"Rosal vivo lobita"},
    {prodId:"10202149",label:"Rosal vivo luca"},
    {prodId:"10202150",label:"Rosal vivo manitou"},
    {prodId:"10202151",label:"Rosal vivo mariana"},
    {prodId:"10202152",label:"Rosal vivo marjan o pk sensation"},
    {prodId:"10202153",label:"Rosal vivo milonga"},
    {prodId:"10202154",label:"Rosal vivo milva"},
    {prodId:"10202155",label:"Rosal vivo miracle"},
    {prodId:"10202156",label:"Rosal vivo mirage"},
    {prodId:"10202157",label:"Rosal vivo monte carlo"},
    {prodId:"10202158",label:"Rosal vivo movie star"},
    {prodId:"10202159",label:"Rosal vivo nikita"},
    {prodId:"10202160",label:"Rosal vivo orange flame"},
    {prodId:"10202161",label:"Rosal vivo orange france"},
    {prodId:"10202162",label:"Rosal vivo orange intuition"},
    {prodId:"10202163",label:"Rosal vivo orange unique"},
    {prodId:"10202164",label:"Rosal vivo orangine u orangina"},
    {prodId:"10202165",label:"Rosal vivo papaya"},
    {prodId:"10202166",label:"Rosal vivo pareo"},
    {prodId:"10202167",label:"Rosal vivo peach sherbet"},
    {prodId:"10202168",label:"Rosal vivo queensday"},
    {prodId:"10202169",label:"Rosal vivo rosselle"},
    {prodId:"10202170",label:"Rosal vivo royal circus"},
    {prodId:"10202171",label:"Rosal vivo sari"},
    {prodId:"10202172",label:"Rosal vivo sensual"},
    {prodId:"10202173",label:"Rosal vivo soap"},
    {prodId:"10202174",label:"Rosal vivo sombrero"},
    {prodId:"10202175",label:"Rosal vivo spicy"},
    {prodId:"10202176",label:"Rosal vivo star 2000"},
    {prodId:"10202177",label:"Rosal vivo summer versilia"},
    {prodId:"10202178",label:"Rosal vivo trixx"},
    {prodId:"10202179",label:"Rosal vivo tropical amazon"},
    {prodId:"10202180",label:"Rosal vivo utopia"},
    {prodId:"10202181",label:"Rosal vivo valentine"},
    {prodId:"10202182",label:"Rosal vivo verano"},
    {prodId:"10202183",label:"Rosal vivo versilia"},
    {prodId:"10202184",label:"Rosal vivo voodoo"},
    {prodId:"10202185",label:"Rosal vivo wow"},
    {prodId:"10202186",label:"Rosal vivo yabadabadoo"},
    {prodId:"10202200",label:"Rosales vivos durazno"},
    {prodId:"10202201",label:"Rosal vivo alejandra"},
    {prodId:"10202202",label:"Rosal vivo azafran"},
    {prodId:"10202203",label:"Rosal vivo big fun"},
    {prodId:"10202204",label:"Rosal vivo cabaret"},
    {prodId:"10202205",label:"Rosal vivo capuccino"},
    {prodId:"10202206",label:"Rosal vivo carpe diem"},
    {prodId:"10202207",label:"Rosal vivo cosima"},
    {prodId:"10202208",label:"Rosal vivo cumbia"},
    {prodId:"10202209",label:"Rosal vivo dream"},
    {prodId:"10202210",label:"Rosal vivo epoca"},
    {prodId:"10202211",label:"Rosal vivo fado"},
    {prodId:"10202212",label:"Rosal vivo femma"},
    {prodId:"10202213",label:"Rosal vivo guajira"},
    {prodId:"10202214",label:"Rosal vivo high and arena"},
    {prodId:"10202215",label:"Rosal vivo high and dandy"},
    {prodId:"10202216",label:"Rosal vivo high and lucky"},
    {prodId:"10202217",label:"Rosal vivo high and peach"},
    {prodId:"10202218",label:"Rosal vivo imagination"},
    {prodId:"10202219",label:"Rosal vivo isis"},
    {prodId:"10202220",label:"Rosal vivo joy o light versilia"},
    {prodId:"10202221",label:"Rosal vivo juliet ausjameson"},
    {prodId:"10202222",label:"Rosal vivo la parisienne"},
    {prodId:"10202223",label:"Rosal vivo la perla"},
    {prodId:"10202224",label:"Rosal vivo lovita sunblaze"},
    {prodId:"10202225",label:"Rosal vivo malilena o marilena"},
    {prodId:"10202226",label:"Rosal vivo monyna"},
    {prodId:"10202227",label:"Rosal vivo nectarine"},
    {prodId:"10202228",label:"Rosal vivo oriental curiosa"},
    {prodId:"10202229",label:"Rosal vivo osiana"},
    {prodId:"10202230",label:"Rosal vivo peach avalanche"},
    {prodId:"10202231",label:"Rosal vivo peach deja vu"},
    {prodId:"10202232",label:"Rosal vivo picanto"},
    {prodId:"10202233",label:"Rosal vivo prima donna"},
    {prodId:"10202234",label:"Rosal vivo sheril"},
    {prodId:"10202235",label:"Rosal vivo sirocco"},
    {prodId:"10202236",label:"Rosal vivo tamara"},
    {prodId:"10202237",label:"Rosal vivo taxo"},
    {prodId:"10202238",label:"Rosal vivo trust"},
    {prodId:"10202239",label:"Rosal vivo valencia"},
    {prodId:"10202240",label:"Rosal vivo vinci"},
    {prodId:"10202241",label:"Rosal vivo wanda"},
    {prodId:"10202300",label:"Rosales vivos rosados"},
    {prodId:"10202301",label:"Rosal vivo aerobic"},
    {prodId:"10202302",label:"Rosal vivo after party"},
    {prodId:"10202303",label:"Rosal vivo amsterdam"},
    {prodId:"10202304",label:"Rosal vivo aqua rose"},
    {prodId:"10202305",label:"Rosal vivo attache"},
    {prodId:"10202306",label:"Rosal vivo attitude"},
    {prodId:"10202307",label:"Rosal vivo ballet"},
    {prodId:"10202308",label:"Rosal vivo belami"},
    {prodId:"10202309",label:"Rosal vivo bella voo o belle vue"},
    {prodId:"10202310",label:"Rosal vivo bling bling"},
    {prodId:"10202311",label:"Rosal vivo blushing akito"},
    {prodId:"10202312",label:"Rosal vivo brooke"},
    {prodId:"10202313",label:"Rosal vivo bugatti"},
    {prodId:"10202314",label:"Rosal vivo cadillac"},
    {prodId:"10202315",label:"Rosal vivo carnaval"},
    {prodId:"10202316",label:"Rosal vivo cereza"},
    {prodId:"10202317",label:"Rosal vivo charming unique"},
    {prodId:"10202318",label:"Rosal vivo cherry o"},
    {prodId:"10202319",label:"Rosal vivo ciciolina"},
    {prodId:"10202320",label:"Rosal vivo classic cezanne"},
    {prodId:"10202321",label:"Rosal vivo clasic duett"},
    {prodId:"10202322",label:"Rosal vivo cosmiq"},
    {prodId:"10202323",label:"Rosal vivo dark engagement"},
    {prodId:"10202324",label:"Rosal vivo daytona"},
    {prodId:"10202325",label:"Rosal vivo dekora"},
    {prodId:"10202326",label:"Rosal vivo dolores"},
    {prodId:"10202327",label:"Rosal vivo eliza"},
    {prodId:"10202328",label:"Rosal vivo flash baccara"},
    {prodId:"10202329",label:"Rosal vivo full house"},
    {prodId:"10202330",label:"Rosal vivo funky"},
    {prodId:"10202331",label:"Rosal vivo giliane"},
    {prodId:"10202332",label:"Rosal vivo gran europe"},
    {prodId:"10202333",label:"Rosal vivo habari"},
    {prodId:"10202334",label:"Rosal vivo hanseat"},
    {prodId:"10202335",label:"Rosal vivo high and amazing"},
    {prodId:"10202336",label:"Rosal vivo high and bonita"},
    {prodId:"10202337",label:"Rosal vivo high and booming"},
    {prodId:"10202338",label:"Rosal vivo high and fantasy"},
    {prodId:"10202339",label:"Rosal vivo high and rich"},
    {prodId:"10202340",label:"Rosal vivo hot lady"},
    {prodId:"10202341",label:"Rosal vivo hot princess"},
    {prodId:"10202342",label:"Rosal vivo inspiration"},
    {prodId:"10202343",label:"Rosal vivo jeimy"},
    {prodId:"10202344",label:"Rosal vivo kachita"},
    {prodId:"10202345",label:"Rosal vivo karen"},
    {prodId:"10202346",label:"Rosal vivo kenji"},
    {prodId:"10202347",label:"Rosal vivo kiko"},
    {prodId:"10202348",label:"Rosal vivo laser"},
    {prodId:"10202349",label:"Rosal vivo latin duett"},
    {prodId:"10202350",label:"Rosal vivo latin fever"},
    {prodId:"10202351",label:"Rosal vivo lifestyle"},
    {prodId:"10202352",label:"Rosal vivo light orlando"},
    {prodId:"10202353",label:"Rosal vivo lovely dreams"},
    {prodId:"10202354",label:"Rosal vivo loyalty"},
    {prodId:"10202355",label:"Rosal vivo malibu"},
    {prodId:"10202356",label:"Rosal vivo mata-hari"},
    {prodId:"10202357",label:"Rosal vivo memphis"},
    {prodId:"10202358",label:"Rosal vivo mi amor"},
    {prodId:"10202359",label:"Rosal vivo miami"},
    {prodId:"10202360",label:"Rosal vivo michelle"},
    {prodId:"10202361",label:"Rosal vivo mikaela"},
    {prodId:"10202362",label:"Rosal vivo orchestra"},
    {prodId:"10202363",label:"Rosal vivo orlando"},
    {prodId:"10202364",label:"Rosal vivo osadia"},
    {prodId:"10202365",label:"Rosal vivo paeonia freelander"},
    {prodId:"10202366",label:"Rosal vivo paula"},
    {prodId:"10202367",label:"Rosal vivo pavarotti"},
    {prodId:"10202368",label:"Rosal vivo pink intuition"},
    {prodId:"10202369",label:"Rosal vivo poison"},
    {prodId:"10202370",label:"Rosal vivo princess"},
    {prodId:"10202371",label:"Rosal vivo queen mary"},
    {prodId:"10202372",label:"Rosal vivo raphaela"},
    {prodId:"10202373",label:"Rosal vivo raspberry ice"},
    {prodId:"10202374",label:"Rosal vivo ravel"},
    {prodId:"10202375",label:"Rosal vivo riviera"},
    {prodId:"10202376",label:"Rosal vivo sade"},
    {prodId:"10202377",label:"Rosal vivo sashimi"},
    {prodId:"10202378",label:"Rosal vivo shanya"},
    {prodId:"10202379",label:"Rosal vivo shocking versilia"},
    {prodId:"10202380",label:"Rosal vivo solitaire"},
    {prodId:"10202381",label:"Rosal vivo something different"},
    {prodId:"10202382",label:"Rosal vivo splendid renate"},
    {prodId:"10202383",label:"Rosal vivo star"},
    {prodId:"10202384",label:"Rosal vivo sweet candia"},
    {prodId:"10202385",label:"Rosal vivo sweet moments"},
    {prodId:"10202386",label:"Rosal vivo sweet unique"},
    {prodId:"10202387",label:"Rosal vivo taboo"},
    {prodId:"10202388",label:"Rosal vivo timona"},
    {prodId:"10202389",label:"Rosal vivo topaz"},
    {prodId:"10202390",label:"Rosal vivo vogue"},
    {prodId:"10202391",label:"Rosal vivo voila"},
    {prodId:"10202392",label:"Rosal vivo wild one"},
    {prodId:"10202393",label:"Rosal vivo yves piaget"},
    {prodId:"10211700",label:"Astromelia viva"},
    {prodId:"10211701",label:"Astromelia viva agropoli"},
    {prodId:"10211702",label:"Astromelia viva bourgogne"},
    {prodId:"10211703",label:"Astromelia viva cairo"},
    {prodId:"10211704",label:"Astromelia viva charmes"},
    {prodId:"10211705",label:"Astromelia viva cherry bay"},
    {prodId:"10211706",label:"Astromelia viva cherry white"},
    {prodId:"10211707",label:"Astromelia viva dame blanche"},
    {prodId:"10211708",label:"Astromelia viva diamond"},
    {prodId:"10211709",label:"Astromelia viva gran canaria"},
    {prodId:"10211710",label:"Astromelia viva harlekijn"},
    {prodId:"10211711",label:"Astromelia viva indian summer"},
    {prodId:"10211712",label:"Astromelia viva jamaica"},
    {prodId:"10211713",label:"Astromelia viva macondo"},
    {prodId:"10211714",label:"Astromelia viva mistique"},
    {prodId:"10211715",label:"Astromelia viva my fair"},
    {prodId:"10211716",label:"Astromelia viva new cairo"},
    {prodId:"10211717",label:"Astromelia viva nice"},
    {prodId:"10211718",label:"Astromelia viva orange bowl"},
    {prodId:"10211719",label:"Astromelia viva orange queens"},
    {prodId:"10211720",label:"Astromelia viva orange sun"},
    {prodId:"10211721",label:"Astromelia viva paris"},
    {prodId:"10211722",label:"Astromelia viva picasso"},
    {prodId:"10211723",label:"Astromelia viva pink panther"},
    {prodId:"10211724",label:"Astromelia viva prima donna"},
    {prodId:"10211725",label:"Astromelia viva red silhouette"},
    {prodId:"10211726",label:"Astromelia viva sacha"},
    {prodId:"10211727",label:"Astromelia viva salmon"},
    {prodId:"10211728",label:"Astromelia viva santiago"},
    {prodId:"10211729",label:"Astromelia viva senna"},
    {prodId:"10211730",label:"Astromelia viva snowball"},
    {prodId:"10211731",label:"Astromelia viva sublime"},
    {prodId:"10211732",label:"Astromelia viva tropicana"},
    {prodId:"10211733",label:"Astromelia viva virginia"},
    {prodId:"10211734",label:"Astromelia viva white"},
    {prodId:"10211800",label:"Amaranto vivo"},
    {prodId:"10211801",label:"Amaranto vivo verde colgante"},
    {prodId:"10211802",label:"Amaranto vivo rojo colgante"},
    {prodId:"10211803",label:"Amaranto vivo bonce erguido"},
    {prodId:"10211804",label:"Amaranto vivo verde erguido"},
    {prodId:"10211805",label:"Amaranto vivo rojo erguido"},
    {prodId:"10213200",label:"Clavel (dianthus) vivo"},
    {prodId:"10213201",label:"Clavel (dianthus) vivo chocolate"},
    {prodId:"10213202",label:"Clavel (dianthus) vivo fucsia"},
    {prodId:"10213203",label:"Clavel (dianthus) vivo bola verde"},
    {prodId:"10213204",label:"Clavel (dianthus) vivo rosado fuerte"},
    {prodId:"10213205",label:"Clavel (dianthus) vivo lavanda"},
    {prodId:"10213206",label:"Clavel (dianthus) vivo frambuesa"},
    {prodId:"10213207",label:"Clavel (dianthus) vivo rojo"},
    {prodId:"10213208",label:"Clavel (dianthus) vivo rosado"},
    {prodId:"10213700",label:"Ajedrezada viva"},
    {prodId:"10213701",label:"Ajedrezada viva acmopelata"},
    {prodId:"10213702",label:"Ajedrezada viva assyriaca"},
    {prodId:"10213703",label:"Ajedrezada viva assyriaca uva vulpis"},
    {prodId:"10213704",label:"Ajedrezada viva elysee"},
    {prodId:"10213705",label:"Ajedrezada viva imperialis anaranjada"},
    {prodId:"10213706",label:"Ajedrezada viva imperialis amarilla"},
    {prodId:"10213707",label:"Ajedrezada viva meleagris"},
    {prodId:"10213708",label:"Ajedrezada viva michailowski"},
    {prodId:"10213709",label:"Ajedrezada viva uva vulpis"},
    {prodId:"10214000",label:"Jengibre vivo"},
    {prodId:"10214001",label:"Jengibre vivo  indonesio"},
    {prodId:"10214002",label:"Jengibre vivo jungle king rosado"},
    {prodId:"10214003",label:"Jengibre vivo jungle king rojo"},
    {prodId:"10214004",label:"Jengibre vivo rosado"},
    {prodId:"10214005",label:"Jengibre vivo rojo"},
    {prodId:"10214006",label:"Jengibre vivo torch"},
    {prodId:"10215400",label:"Azucena viva"},
    {prodId:"10215401",label:"Azucena viva alteza longiflorum e híbrido asiática"},
    {prodId:"10215402",label:"Azucena viva black out"},
    {prodId:"10215403",label:"Azucena viva rosado oscuro"},
    {prodId:"10215404",label:"Azucena viva leéctrica asiática"},
    {prodId:"10215405",label:"Azucena viva festival asiática"},
    {prodId:"10215406",label:"Azucena viva ginebra asiática"},
    {prodId:"10215407",label:"Azucena viva rosado claro asiática"},
    {prodId:"10215408",label:"Azucena viva colombina asiática"},
    {prodId:"10215409",label:"Azucena viva miss américa púrpura asiática"},
    {prodId:"10215410",label:"Azucena viva monte negro asiática"},
    {prodId:"10215411",label:"Azucena viva anaranjada asiática"},
    {prodId:"10215412",label:"Azucena viva durazno cannes asiática"},
    {prodId:"10215413",label:"Azucena viva rosada asiática"},
    {prodId:"10215414",label:"Azucena viva sancerre asiátia"},
    {prodId:"10215415",label:"Azucena viva white dream asiática"},
    {prodId:"10215416",label:"Azucena viva amarilla asiática"},
    {prodId:"10215417",label:"Azucena viva diamante brillante longiflorum e híbrido asiática"},
    {prodId:"10215418",label:"Azucena viva brindisi longiflorum e híbrido asiática"},
    {prodId:"10215419",label:"Azucena viva carmine longiflorum e híbrido asiática"},
    {prodId:"10215420",label:"Azucena viva cinnabar longiflorum e híbrido asiática"},
    {prodId:"10215421",label:"Azucena viva club longiflorum e híbrido asiática"},
    {prodId:"10215422",label:"Azucena viva discovery longiflorum e híbrido asiática"},
    {prodId:"10215423",label:"Azucena viva de pascua"},
    {prodId:"10215424",label:"Azucena viva isis longiflorum e híbrido asiática"},
    {prodId:"10215425",label:"Azucena viva la hybrid justice longiflorum e híbrido asiática"},
    {prodId:"10215426",label:"Azucena viva lace longiflorum e híbrido asiática"},
    {prodId:"10215427",label:"Azucena viva lirio de los valles"},
    {prodId:"10215428",label:"Azucena viva love longiflorum e híbrido asiática"},
    {prodId:"10215429",label:"Azucena viva menorca longiflorum e híbrido asiática"},
    {prodId:"10215430",label:"Azucena viva oriental acapulco"},
    {prodId:"10215431",label:"Azucena viva oriental albion"},
    {prodId:"10215432",label:"Azucena viva oriental argentina"},
    {prodId:"10215433",label:"Azucena viva oriental auratum"},
    {prodId:"10215434",label:"Azucena viva oriental barbaresco"},
    {prodId:"10215435",label:"Azucena viva oriental bernini"},
    {prodId:"10215436",label:"Azucena viva oriental beseno"},
    {prodId:"10215437",label:"Azucena viva oriental broadway"},
    {prodId:"10215438",label:"Azucena viva oriental canada"},
    {prodId:"10215439",label:"Azucena viva oriental casablanca"},
    {prodId:"10215440",label:"Azucena viva oriental chili"},
    {prodId:"10215441",label:"Azucena viva oriental chrystal"},
    {prodId:"10215442",label:"Azucena viva oriental cobra"},
    {prodId:"10215443",label:"Azucena viva oriental conca d' or"},
    {prodId:"10215444",label:"Azucena viva oriental cote d' ivor"},
    {prodId:"10215445",label:"Azucena viva oriental dizzy"},
    {prodId:"10215446",label:"Azucena viva oriental fireball"},
    {prodId:"10215447",label:"Azucena viva oriental gluhwein"},
    {prodId:"10215448",label:"Azucena viva oriental goldband"},
    {prodId:"10215449",label:"Azucena viva oriental halifax"},
    {prodId:"10215450",label:"Azucena viva oriental kathryn"},
    {prodId:"10215451",label:"Azucena viva oriental kyoto"},
    {prodId:"10215452",label:"Azucena viva oriental la mancha"},
    {prodId:"10215453",label:"Azucena viva oriental medusa"},
    {prodId:"10215454",label:"Azucena viva oriental montezuma"},
    {prodId:"10215455",label:"Azucena viva oriental muscadet"},
    {prodId:"10215456",label:"Azucena viva oriental nippon"},
    {prodId:"10215457",label:"Azucena viva oriental opus one"},
    {prodId:"10215458",label:"Azucena viva oriental pompeii"},
    {prodId:"10215459",label:"Azucena viva oriental rialto"},
    {prodId:"10215460",label:"Azucena viva oriental robina"},
    {prodId:"10215461",label:"Azucena viva oriental rousilon"},
    {prodId:"10215462",label:"Azucena viva oriental siberia"},
    {prodId:"10215463",label:"Azucena viva oriental sorbonne"},
    {prodId:"10215464",label:"Azucena viva oriental starfighter"},
    {prodId:"10215465",label:"Azucena viva oriental stargazer"},
    {prodId:"10215466",label:"Azucena viva oriental sumatra"},
    {prodId:"10215467",label:"Azucena viva oriental time out"},
    {prodId:"10215468",label:"Azucena viva oriental tom pouche"},
    {prodId:"10215469",label:"Azucena viva oriental tropical"},
    {prodId:"10215470",label:"Azucena viva oriental white cup"},
    {prodId:"10215471",label:"Azucena viva oriental white merostar"},
    {prodId:"10215472",label:"Azucena viva oriental white montana"},
    {prodId:"10215473",label:"Azucena viva oriental white stargazer"},
    {prodId:"10215474",label:"Azucena viva oriental yellow band"},
    {prodId:"10215475",label:"Azucena viva oriental yellow dream"},
    {prodId:"10215476",label:"Azucena viva oriental yellow queen"},
    {prodId:"10215477",label:"Azucena viva oriental yellow star"},
    {prodId:"10215478",label:"Azucena viva oriental yeloween"},
    {prodId:"10215479",label:"Azucena viva ot red dutch"},
    {prodId:"10215480",label:"Azucena viva sonata nimph"},
    {prodId:"10215481",label:"Azucena viva sonata shocking"},
    {prodId:"10215482",label:"Azucena viva sonata triumphateer"},
    {prodId:"10215483",label:"Azucena viva sunset longiflorum e híbrido asiática"},
    {prodId:"10215484",label:"Azucena viva de agua"},
    {prodId:"10215600",label:"Lisianthus vivo"},
    {prodId:"10215601",label:"Lisianthus vivo crema"},
    {prodId:"10215602",label:"Lisianthus vivo rosado fuerte"},
    {prodId:"10215603",label:"Lisianthus vivo verde"},
    {prodId:"10215604",label:"Lisianthus vivo lavanda"},
    {prodId:"10215605",label:"Lisianthus vivo rosado claro"},
    {prodId:"10215606",label:"Lisianthus vivo mini blanco"},
    {prodId:"10215607",label:"Lisianthus vivo durazno"},
    {prodId:"10215608",label:"Lisianthus vivo rosado con borde blanco"},
    {prodId:"10215609",label:"Lisianthus vivo púrpura"},
    {prodId:"10215610",label:"Lisianthus vivo púrpura con borde blanco"},
    {prodId:"10215611",label:"Lisianthus vivo blanco con borde rosado"},
    {prodId:"10215612",label:"Lisianthus vivo blanco"},
    {prodId:"10215613",label:"Lisianthus vivo blanco con borde púrpura"},
    {prodId:"10216100",label:"Estrella de Belén viva"},
    {prodId:"10216101",label:"Estrella de belén viva arábica"},
    {prodId:"10216102",label:"Estrella de belén viva dubium anaranjada"},
    {prodId:"10216103",label:"Estrella de belén viva umbellada"},
    {prodId:"10216104",label:"Estrella de belén viva dubium blanca"},
    {prodId:"10216105",label:"Estrella de belén viva dubium amarilla"},
    {prodId:"10216500",label:"Escabiosa viva"},
    {prodId:"10216501",label:"Escabiosa viva anual"},
    {prodId:"10216502",label:"Escabiosa viva negra"},
    {prodId:"10216503",label:"Escabiosa viva caucásica azul"},
    {prodId:"10216504",label:"Escabiosa viva caucásica rosada"},
    {prodId:"10216505",label:"Escabiosa viva caucásica vainas"},
    {prodId:"10216506",label:"Escabiosa viva caucásica blanca"},
    {prodId:"10216507",label:"Escabiosa viva fresa"},
    {prodId:"10216800",label:"Romero del pantano viva"},
    {prodId:"10216801",label:"Romero del pantano viva azul"},
    {prodId:"10216802",label:"Romero del pantano viva lavanda"},
    {prodId:"10216803",label:"Romero del pantano viva durazno"},
    {prodId:"10216804",label:"Romero del pantano viva rosada"},
    {prodId:"10216805",label:"Romero del pantano viva púrpura"},
    {prodId:"10216806",label:"Romero del pantano viva espuma de mar"},
    {prodId:"10216807",label:"Romero del pantano viva blanca"},
    {prodId:"10216808",label:"Romero del pantano viva amarilla"},
    {prodId:"10216900",label:"Matiola viva"},
    {prodId:"10216901",label:"Matiola viva apricot"},
    {prodId:"10216902",label:"Matiola viva crema"},
    {prodId:"10216903",label:"Matiola viva fucsia"},
    {prodId:"10216904",label:"Matiola viva lavanda"},
    {prodId:"10216905",label:"Matiola viva lavanda claro"},
    {prodId:"10216906",label:"Matiola viva rosada"},
    {prodId:"10216907",label:"Matiola viva púrpura"},
    {prodId:"10216908",label:"Matiola viva rojo rubí"},
    {prodId:"10216909",label:"Matiola viva enamorada rosda"},
    {prodId:"10216910",label:"Matiola viva blanca"},
    {prodId:"10217400",label:"Flor de cera viva"},
    {prodId:"10217401",label:"Flor de cera viva alba"},
    {prodId:"10217402",label:"Flor de cera viva bicolor"},
    {prodId:"10217403",label:"Flor de cera viva chichilla"},
    {prodId:"10217404",label:"Flor de cera viva reina danzante"},
    {prodId:"10217405",label:"Flor de cera viva dinamarca"},
    {prodId:"10217406",label:"Flor de cera viva denmar"},
    {prodId:"10217407",label:"Flor de cera viva híbrida pastel flor gema"},
    {prodId:"10217408",label:"Flor de cera viva híbrida rosada flor gem"},
    {prodId:"10217409",label:"Flor de cera viva híbrida blanca rubia"},
    {prodId:"10217410",label:"Flor de cera viva híbrida eric john"},
    {prodId:"10217411",label:"Flor de cera viva híbrida dama pintada"},
    {prodId:"10217412",label:"Flor de cera viva híbrida revelación"},
    {prodId:"10217413",label:"Flor de cera viva híbrida bola de nieve"},
    {prodId:"10217414",label:"Flor de cera viva juriens brook"},
    {prodId:"10217415",label:"Flor de cera viva lady stephanie rosada"},
    {prodId:"10217416",label:"Flor de cera viva madonna"},
    {prodId:"10217417",label:"Flor de cera viva mini blanca"},
    {prodId:"10217418",label:"Flor de cera viva anaranjada"},
    {prodId:"10217419",label:"Flor de cera viva perla"},
    {prodId:"10217420",label:"Flor de cera viva pixie moon"},
    {prodId:"10217421",label:"Flor de cera viva orgullo púrpura"},
    {prodId:"10217422",label:"Flor de cera viva roja"},
    {prodId:"10217423",label:"Flor de cera viva wanaroo"},
    {prodId:"10217424",label:"Flor de cera viva amarilla"},
    {prodId:"10221500",label:"Agapanto vivo"},
    {prodId:"10221501",label:"Agapanto vivo azul"},
    {prodId:"10221502",label:"Agapanto vivo blanco"},
    {prodId:"10222700",label:"Catalea viva"},
    {prodId:"10222701",label:"Calatea viva cigarro"},
    {prodId:"10222702",label:"Calatea hielo verde"},
    {prodId:"10222703",label:"Calatea serpiente cascabel"},
    {prodId:"10223800",label:"Manzanilla viva"},
    {prodId:"10223801",label:"Manzanilla viva sencilla vegmo"},
    {prodId:"10223802",label:"Manzanilla viva doble blanca"},
    {prodId:"10223803",label:"Manzanilla viva bola de nieve"},
    {prodId:"10223804",label:"Manzanilla viva blanca"},
    {prodId:"10224900",label:"Maraca viva"},
    {prodId:"10224901",label:"Maraca viva marrón"},
    {prodId:"10224902",label:"Maraca viva shampoo ginger"},
    {prodId:"10226100",label:"Sello de salomón (polygonato) viva"},
    {prodId:"10226101",label:"Sello de salomón (polygonato) falso viva"},
    {prodId:"10226102",label:"Sello de salomón (polygonato) variegado viva"},
    {prodId:"10241500",label:"Clavel vivo de floración sencilla"},
    {prodId:"10241501",label:"Clavel vivo de floración sencilla burgundy bicolor"},
    {prodId:"10241502",label:"Clavel vivo de floración sencilla burgundy"},
    {prodId:"10241503",label:"Clavel vivo de floración sencilla cinderella"},
    {prodId:"10241504",label:"Clavel vivo de floración sencilla crema bicolor"},
    {prodId:"10241505",label:"Clavel vivo de floración sencilla crema"},
    {prodId:"10241506",label:"Clavel vivo de floración sencilla verde o prado"},
    {prodId:"10241507",label:"Clavel vivo de floración sencilla rosado fuerte"},
    {prodId:"10241508",label:"Clavel vivo de floración sencilla verde claro"},
    {prodId:"10241509",label:"Clavel vivo de floración sencilla rosado claro"},
    {prodId:"10241510",label:"Clavel vivo de floración sencilla anaranjado bicolor"},
    {prodId:"10241511",label:"Clavel vivo de floración sencilla anaranjado"},
    {prodId:"10241512",label:"Clavel vivo de floración sencilla durazno"},
    {prodId:"10241513",label:"Clavel vivo de floración sencilla menta bicolor"},
    {prodId:"10241514",label:"Clavel vivo de floración sencilla rosado bicolor"},
    {prodId:"10241515",label:"Clavel vivo de floración sencilla rosado"},
    {prodId:"10241516",label:"Clavel vivo de floración sencilla púrpura bicolor"},
    {prodId:"10241517",label:"Clavel vivo de floración sencilla rojo bicolor"},
    {prodId:"10241518",label:"Clavel vivo de floración sencilla rojo"},
    {prodId:"10241519",label:"Clavel vivo de floración sencilla blanco"},
    {prodId:"10241520",label:"Clavel vivo de floración sencilla amarillo"},
    {prodId:"10252000",label:"Orquídea phalaenopsis viva"},
    {prodId:"10252001",label:"Orquídea phalaenopsis viva amabilis"},
    {prodId:"10252002",label:"Orquídea phalaenopsis viva amboinensis"},
    {prodId:"10252003",label:"Orquídea phalaenopsis viva afrodita"},
    {prodId:"10252004",label:"Orquídea phalaenopsis viva appendiculata"},
    {prodId:"10252005",label:"Orquídea phalaenopsis viva bastianii"},
    {prodId:"10252006",label:"Orquídea phalaenopsis viva bellina"},
    {prodId:"10252007",label:"Orquídea phalaenopsis viva borneensis"},
    {prodId:"10252008",label:"Orquídea phalaenopsis viva braceana"},
    {prodId:"10252009",label:"Orquídea phalaenopsis viva buyssoniana"},
    {prodId:"10252010",label:"Orquídea phalaenopsis viva celebensis"},
    {prodId:"10252011",label:"Orquídea phalaenopsis viva chibae"},
    {prodId:"10252012",label:"Orquídea phalaenopsis viva cochlearis"},
    {prodId:"10252013",label:"Orquídea phalaenopsis viva corningiana"},
    {prodId:"10252014",label:"Orquídea phalaenopsis viva cornu-cervi"},
    {prodId:"10252015",label:"Orquídea phalaenopsis viva deliciosa"},
    {prodId:"10252016",label:"Orquídea phalaenopsis viva doweryënsis"},
    {prodId:"10252017",label:"Orquídea phalaenopsis viva equestris"},
    {prodId:"10252018",label:"Orquídea phalaenopsis viva fasciata"},
    {prodId:"10252019",label:"Orquídea phalaenopsis viva fimbriata"},
    {prodId:"10252020",label:"Orquídea phalaenopsis viva floresensis"},
    {prodId:"10252021",label:"Orquídea phalaenopsis viva fuscata"},
    {prodId:"10252022",label:"Orquídea phalaenopsis viva gibbosa"},
    {prodId:"10252023",label:"Orquídea phalaenopsis viva hainanensis"},
    {prodId:"10252024",label:"Orquídea phalaenopsis viva hieroglyphica"},
    {prodId:"10252025",label:"Orquídea phalaenopsis viva honghenensis"},
    {prodId:"10252026",label:"Orquídea phalaenopsis viva inscriptiosinensis"},
    {prodId:"10252027",label:"Orquídea phalaenopsis viva javanica"},
    {prodId:"10252028",label:"Orquídea phalaenopsis viva kunstleri"},
    {prodId:"10252029",label:"Orquídea phalaenopsis viva lamelligera"},
    {prodId:"10252030",label:"Orquídea phalaenopsis viva lindenii"},
    {prodId:"10252031",label:"Orquídea phalaenopsis viva lobbii"},
    {prodId:"10252032",label:"Orquídea phalaenopsis viva lowii"},
    {prodId:"10252033",label:"Orquídea phalaenopsis viva lueddemanniana"},
    {prodId:"10252034",label:"Orquídea phalaenopsis viva mambo"},
    {prodId:"10252035",label:"Orquídea phalaenopsis viva luteola"},
    {prodId:"10252036",label:"Orquídea phalaenopsis viva maculata"},
    {prodId:"10252037",label:"Orquídea phalaenopsis viva malipoensis"},
    {prodId:"10252038",label:"Orquídea phalaenopsis viva mannii"},
    {prodId:"10252039",label:"Orquídea phalaenopsis viva mariae"},
    {prodId:"10252040",label:"Orquídea phalaenopsis viva micholitzii"},
    {prodId:"10252041",label:"Orquídea phalaenopsis viva modesta"},
    {prodId:"10252042",label:"Orquídea phalaenopsis viva mysorensis"},
    {prodId:"10252043",label:"Orquídea phalaenopsis viva pallens"},
    {prodId:"10252044",label:"Orquídea phalaenopsis viva pantherina"},
    {prodId:"10252045",label:"Orquídea phalaenopsis viva parishii"},
    {prodId:"10252046",label:"Orquídea phalaenopsis viva petelotii"},
    {prodId:"10252047",label:"Orquídea phalaenopsis viva philippinensis"},
    {prodId:"10252048",label:"Orquídea phalaenopsis viva pulcherrima"},
    {prodId:"10252049",label:"Orquídea phalaenopsis viva pulchra"},
    {prodId:"10252050",label:"Orquídea phalaenopsis viva regnieriana"},
    {prodId:"10252051",label:"Orquídea phalaenopsis viva reichenbachiana"},
    {prodId:"10252052",label:"Orquídea phalaenopsis viva nivacolor"},
    {prodId:"10252053",label:"Orquídea phalaenopsis viva sanderiana"},
    {prodId:"10252054",label:"Orquídea phalaenopsis viva schilleriana"},
    {prodId:"10252055",label:"Orquídea phalaenopsis viva speciosa"},
    {prodId:"10252056",label:"Orquídea phalaenopsis viva stobartiana"},
    {prodId:"10252057",label:"Orquídea phalaenopsis viva stuartiana"},
    {prodId:"10252058",label:"Orquídea phalaenopsis viva sumatrana"},
    {prodId:"10252059",label:"Orquídea phalaenopsis viva taenialis"},
    {prodId:"10252060",label:"Orquídea phalaenopsis viva tetraspis"},
    {prodId:"10252061",label:"Orquídea phalaenopsis viva venosa"},
    {prodId:"10252062",label:"Orquídea phalaenopsis viva violácea"},
    {prodId:"10252063",label:"Orquídea phalaenopsis viva viridis"},
    {prodId:"10252064",label:"Orquídea phalaenopsis viva wilsonii"},
    {prodId:"10252065",label:"Orquídea phalaenopsis viva zebrina"},
    {prodId:"10252067",label:"Orquídea phalaenopsis viva labio lavanda"},
    {prodId:"10302400",label:"Rosas cortadas frescas rojos o burgundy"},
    {prodId:"10302401",label:"Rosal cortado fresco african dawn"},
    {prodId:"10302402",label:"Rosal cortado fresco amada"},
    {prodId:"10302403",label:"Rosal cortado fresco black baccara"},
    {prodId:"10302404",label:"Rosal cortado fresco black beauty"},
    {prodId:"10302405",label:"Rosal cortado fresco black finess o black magic"},
    {prodId:"10302406",label:"Rosal cortado fresco black magic"},
    {prodId:"10302407",label:"Rosal cortado fresco bohemian o pasarela"},
    {prodId:"10302408",label:"Rosal cortado fresco breathless"},
    {prodId:"10302409",label:"Rosal cortado fresco caballero"},
    {prodId:"10302410",label:"Rosal cortado fresco carrera"},
    {prodId:"10302411",label:"Rosal cortado fresco charlene"},
    {prodId:"10302412",label:"Rosal cortado fresco charlotte"},
    {prodId:"10302413",label:"Rosal cortado fresco cherry lady"},
    {prodId:"10302414",label:"Rosal cortado fresco cherry love"},
    {prodId:"10302415",label:"Rosal cortado fresco classy rose"},
    {prodId:"10302416",label:"Rosal cortado fresco colorado velvet"},
    {prodId:"10302417",label:"Rosal cortado fresco corazón"},
    {prodId:"10302418",label:"Rosal cortado fresco corrida"},
    {prodId:"10302419",label:"Rosal cortado fresco dynamite"},
    {prodId:"10302420",label:"Rosal cortado fresco eurored"},
    {prodId:"10302421",label:"Rosal cortado fresco fashion"},
    {prodId:"10302422",label:"Rosal cortado fresco fire and ice"},
    {prodId:"10302423",label:"Rosal cortado fresco first red"},
    {prodId:"10302424",label:"Rosal cortado fresco forever young"},
    {prodId:"10302425",label:"Rosal cortado fresco freedom"},
    {prodId:"10302426",label:"Rosal cortado fresco freestyle"},
    {prodId:"10302427",label:"Rosal cortado fresco friendship"},
    {prodId:"10302428",label:"Rosal cortado fresco gospel"},
    {prodId:"10302429",label:"Rosal cortado fresco graffity"},
    {prodId:"10302430",label:"Rosal cortado fresco grand gala"},
    {prodId:"10302431",label:"Rosal cortado fresco grand prix"},
    {prodId:"10302432",label:"Rosal cortado fresco grand classe"},
    {prodId:"10302433",label:"Rosal cortado fresco hearts"},
    {prodId:"10302434",label:"Rosal cortado fresco heat"},
    {prodId:"10302435",label:"Rosal cortado fresco hocus pocus"},
    {prodId:"10302436",label:"Rosal cortado fresco lady in red"},
    {prodId:"10302437",label:"Rosal cortado fresco latin lady"},
    {prodId:"10302438",label:"Rosal cortado fresco legend"},
    {prodId:"10302439",label:"Rosal cortado fresco lulu"},
    {prodId:"10302440",label:"Rosal cortado fresco luna rossa"},
    {prodId:"10302441",label:"Rosal cortado fresco luxor"},
    {prodId:"10302442",label:"Rosal cortado fresco madame delbard o carola"},
    {prodId:"10302443",label:"Rosal cortado fresco miss paris"},
    {prodId:"10302444",label:"Rosal cortado fresco nicole"},
    {prodId:"10302445",label:"Rosal cortado fresco night fever"},
    {prodId:"10302446",label:"Rosal cortado fresco obsession"},
    {prodId:"10302447",label:"Rosal cortado fresco opium"},
    {prodId:"10302448",label:"Rosal cortado fresco paz"},
    {prodId:"10302449",label:"Rosal cortado fresco preference"},
    {prodId:"10302450",label:"Rosal cortado fresco red berlin"},
    {prodId:"10302451",label:"Rosal cortado fresco red bull"},
    {prodId:"10302452",label:"Rosal cortado fresco red calypso"},
    {prodId:"10302453",label:"Rosal cortado fresco red diamond"},
    {prodId:"10302454",label:"Rosal cortado fresco red fantasy"},
    {prodId:"10302455",label:"Rosal cortado fresco red france"},
    {prodId:"10302456",label:"Rosal cortado fresco red intuition"},
    {prodId:"10302457",label:"Rosal cortado fresco red jewel"},
    {prodId:"10302458",label:"Rosal cortado fresco red magic"},
    {prodId:"10302459",label:"Rosal cortado fresco red one"},
    {prodId:"10302460",label:"Rosal cortado fresco red paris"},
    {prodId:"10302461",label:"Rosal cortado fresco red princess"},
    {prodId:"10302462",label:"Rosal cortado fresco red sensation o colorad"},
    {prodId:"10302463",label:"Rosal cortado fresco red unique"},
    {prodId:"10302464",label:"Rosal cortado fresco rockefeller"},
    {prodId:"10302465",label:"Rosal cortado fresco romeo"},
    {prodId:"10302466",label:"Rosal cortado fresco rouge baiser"},
    {prodId:"10302467",label:"Rosal cortado fresco roulette"},
    {prodId:"10302468",label:"Rosal cortado fresco royal massai"},
    {prodId:"10302469",label:"Rosal cortado fresco royal red"},
    {prodId:"10302470",label:"Rosal cortado fresco samurai"},
    {prodId:"10302471",label:"Rosal cortado fresco sexy red"},
    {prodId:"10302472",label:"Rosal cortado fresco starfire"},
    {prodId:"10302473",label:"Rosal cortado fresco tango"},
    {prodId:"10302474",label:"Rosal cortado fresco tiger tail"},
    {prodId:"10302475",label:"Rosal cortado fresco tinto"},
    {prodId:"10302476",label:"Rosal cortado fresco top secret"},
    {prodId:"10302477",label:"Rosal cortado fresco vital"},
    {prodId:"10302478",label:"Rosal cortado fresco wisdom"},
    {prodId:"10302479",label:"Rosal cortado fresco xantia"},
    {prodId:"10302480",label:"Rosal cortado fresco xcite"},
    {prodId:"10302700",label:"Rosas cortadas frescas amarillos"},
    {prodId:"10302701",label:"Rosal cortado fresco aalsmeer gold"},
    {prodId:"10302702",label:"Rosal cortado fresco alina"},
    {prodId:"10302703",label:"Rosal cortado fresco ambiance"},
    {prodId:"10302704",label:"Rosal cortado fresco aquarel"},
    {prodId:"10302705",label:"Rosal cortado fresco autumn"},
    {prodId:"10302706",label:"Rosal cortado fresco brasil"},
    {prodId:"10302707",label:"Rosal cortado fresco candle light"},
    {prodId:"10302708",label:"Rosal cortado fresco cantata o cantate"},
    {prodId:"10302709",label:"Rosal cortado fresco capriccio"},
    {prodId:"10302710",label:"Rosal cortado fresco caribbean"},
    {prodId:"10302711",label:"Rosal cortado fresco circus"},
    {prodId:"10302712",label:"Rosal cortado fresco citran"},
    {prodId:"10302713",label:"Rosal cortado fresco concorde"},
    {prodId:"10302714",label:"Rosal cortado fresco conga"},
    {prodId:"10302715",label:"Rosal cortado fresco deja vu"},
    {prodId:"10302716",label:"Rosal cortado fresco desire"},
    {prodId:"10302717",label:"Rosal cortado fresco donia sol"},
    {prodId:"10302718",label:"Rosal cortado fresco dueto"},
    {prodId:"10302719",label:"Rosal cortado fresco erin"},
    {prodId:"10302720",label:"Rosal cortado fresco exotic curiosa"},
    {prodId:"10302721",label:"Rosal cortado fresco feria"},
    {prodId:"10302722",label:"Rosal cortado fresco fire bird"},
    {prodId:"10302723",label:"Rosal cortado fresco florida"},
    {prodId:"10302724",label:"Rosal cortado fresco friendly"},
    {prodId:"10302725",label:"Rosal cortado fresco gallinda"},
    {prodId:"10302726",label:"Rosal cortado fresco geisha"},
    {prodId:"10302727",label:"Rosal cortado fresco gelbe"},
    {prodId:"10302728",label:"Rosal cortado fresco gelosia o yellow flame"},
    {prodId:"10302729",label:"Rosal cortado fresco gold rush"},
    {prodId:"10302730",label:"Rosal cortado fresco gold star"},
    {prodId:"10302731",label:"Rosal cortado fresco gold strike"},
    {prodId:"10302732",label:"Rosal cortado fresco golda"},
    {prodId:"10302733",label:"Rosal cortado fresco golden fashion"},
    {prodId:"10302734",label:"Rosal cortado fresco golden gate"},
    {prodId:"10302735",label:"Rosal cortado fresco gran dorado"},
    {prodId:"10302736",label:"Rosal cortado fresco helio"},
    {prodId:"10302737",label:"Rosal cortado fresco high and exotic"},
    {prodId:"10302738",label:"Rosal cortado fresco high and yellow"},
    {prodId:"10302739",label:"Rosal cortado fresco high and yellow magic"},
    {prodId:"10302740",label:"Rosal cortado fresco high society"},
    {prodId:"10302741",label:"Rosal cortado fresco hummer"},
    {prodId:"10302742",label:"Rosal cortado fresco idole o elle"},
    {prodId:"10302743",label:"Rosal cortado fresco inti"},
    {prodId:"10302744",label:"Rosal cortado fresco jet set"},
    {prodId:"10302745",label:"Rosal cortado fresco judy"},
    {prodId:"10302746",label:"Rosal cortado fresco jupiter"},
    {prodId:"10302747",label:"Rosal cortado fresco konfetti"},
    {prodId:"10302748",label:"Rosal cortado fresco kyara o kira"},
    {prodId:"10302749",label:"Rosal cortado fresco latin beauty"},
    {prodId:"10302750",label:"Rosal cortado fresco latin spirit"},
    {prodId:"10302751",label:"Rosal cortado fresco latina"},
    {prodId:"10302752",label:"Rosal cortado fresco lina"},
    {prodId:"10302753",label:"Rosal cortado fresco lindsey"},
    {prodId:"10302754",label:"Rosal cortado fresco male"},
    {prodId:"10302755",label:"Rosal cortado fresco marie clare"},
    {prodId:"10302756",label:"Rosal cortado fresco marisa"},
    {prodId:"10302757",label:"Rosal cortado fresco matchball"},
    {prodId:"10302758",label:"Rosal cortado fresco melon"},
    {prodId:"10302759",label:"Rosal cortado fresco mohana"},
    {prodId:"10302760",label:"Rosal cortado fresco okie dokie"},
    {prodId:"10302761",label:"Rosal cortado fresco pailine"},
    {prodId:"10302762",label:"Rosal cortado fresco parrot"},
    {prodId:"10302763",label:"Rosal cortado fresco rio d oro"},
    {prodId:"10302764",label:"Rosal cortado fresco salami"},
    {prodId:"10302765",label:"Rosal cortado fresco santa fe"},
    {prodId:"10302766",label:"Rosal cortado fresco skyline"},
    {prodId:"10302767",label:"Rosal cortado fresco sonrisa"},
    {prodId:"10302768",label:"Rosal cortado fresco star ambiance"},
    {prodId:"10302769",label:"Rosal cortado fresco starbust"},
    {prodId:"10302770",label:"Rosal cortado fresco sun king"},
    {prodId:"10302771",label:"Rosal cortado fresco sunmaster"},
    {prodId:"10302772",label:"Rosal cortado fresco sunny milva"},
    {prodId:"10302773",label:"Rosal cortado fresco sushi"},
    {prodId:"10302774",label:"Rosal cortado fresco tabasco"},
    {prodId:"10302775",label:"Rosal cortado fresco tara"},
    {prodId:"10302776",label:"Rosal cortado fresco tresor 2000"},
    {prodId:"10302777",label:"Rosal cortado fresco ooty"},
    {prodId:"10302778",label:"Rosal cortado fresco yellow coral"},
    {prodId:"10302779",label:"Rosal cortado fresco yellow finess"},
    {prodId:"10302780",label:"Rosal cortado fresco yellow submarine"},
    {prodId:"10302781",label:"Rosal cortado fresco yellow sunset"},
    {prodId:"10302782",label:"Rosal cortado fresco yelllow timeless"},
    {prodId:"10311800",label:"Amaranto cortado fresco"},
    {prodId:"10311801",label:"Amaranto cortado fresco verde colgante"},
    {prodId:"10311802",label:"Amaranto cortado fresco rojo colgante"},
    {prodId:"10311803",label:"Amaranto cortado fresco bronce erguido"},
    {prodId:"10311804",label:"Amaranto cortado fresco verde erguido"},
    {prodId:"10311805",label:"Amaranto cortado fresco rojo erguido"},
    {prodId:"10312100",label:"Asclepia cortada fresca"},
    {prodId:"10312101",label:"Asclepia cortada fresca lavanda"},
    {prodId:"10312102",label:"Asclepia cortada fresca moby dick"},
    {prodId:"10312103",label:"Asclepia cortada fresca tuberosa"},
    {prodId:"10312104",label:"Asclepia cortada fresca blanca"},
    {prodId:"10312200",label:"Aster cortada fresca"},
    {prodId:"10312201",label:"Aster cortada fresca beauty"},
    {prodId:"10312202",label:"Aster cortada fresca japonés azul"},
    {prodId:"10312203",label:"Aster cortada fresca japonés verde"},
    {prodId:"10312204",label:"Aster cortada fresca japonés rosado fuerte"},
    {prodId:"10312205",label:"Aster cortada fresca japonés lavanda"},
    {prodId:"10312206",label:"Aster cortada fresca japonés rosado claro"},
    {prodId:"10312207",label:"Aster cortada fresca japonés durazno"},
    {prodId:"10312208",label:"Aster cortada fresca japonés rosado"},
    {prodId:"10312209",label:"Aster cortada fresca japonés púrpura"},
    {prodId:"10312210",label:"Aster cortada fresca japonés rojo"},
    {prodId:"10312211",label:"Aster cortada fresca japonés spider"},
    {prodId:"10312212",label:"Aster cortada fresca japonés  blanco"},
    {prodId:"10312213",label:"Aster cortada fresca novi belgii rosado fuerte"},
    {prodId:"10312214",label:"Aster cortada fresca novi belgii lavanda"},
    {prodId:"10312215",label:"Aster cortada fresca novi belgii rosado"},
    {prodId:"10312216",label:"Aster cortada fresca novi belgii púrpura"},
    {prodId:"10312217",label:"Aster cortada fresca novi belgii blanco"},
    {prodId:"10312218",label:"Aster cortada fresca solidago"},
    {prodId:"10312219",label:"Aster cortada fresca spider"},
    {prodId:"10313200",label:"Clavel (dianthus) cortado fresco"},
    {prodId:"10313201",label:"Clavel (dianthus) cortado fresco chocolate"},
    {prodId:"10313202",label:"Clavel (dianthus) cortado fresco fucsia"},
    {prodId:"10313203",label:"Clavel (dianthus) cortado fresco bola verde"},
    {prodId:"10313204",label:"Clavel (dianthus) cortado fresco rosado fuerte"},
    {prodId:"10313205",label:"Clavel (dianthus) cortado fresco lavanda"},
    {prodId:"10313206",label:"Clavel (dianthus) cortado fresco frambuesa"},
    {prodId:"10313207",label:"Clavel (dianthus) cortado fresco rojo"},
    {prodId:"10313208",label:"Clavel (dianthus) cortado fresco rosado"},
    {prodId:"10313900",label:"Margarita cortada fresca"},
    {prodId:"10313901",label:"Margarita cortada fresca crema de centro negro"},
    {prodId:"10313902",label:"Margarita cortada fresca crema"},
    {prodId:"10313903",label:"Margarita cortada fresca dorada"},
    {prodId:"10313904",label:"Margarita cortada fresca rosado fuerte"},
    {prodId:"10313905",label:"Margarita cortada fresca rosado claro"},
    {prodId:"10313906",label:"Margarita cortada fresca magenta"},
    {prodId:"10313907",label:"Margarita cortada fresca mini coral"},
    {prodId:"10313908",label:"Margarita cortada fresca mini fucsia"},
    {prodId:"10313909",label:"Margarita cortada fresca mini rosado fuerte"},
    {prodId:"10313910",label:"Margarita cortada fresca mini anaranjado claro de centro negro"},
    {prodId:"10313911",label:"Margarita cortada fresca mini anaranjado de centro negro"},
    {prodId:"10313912",label:"Margarita cortada fresca mini anaranjado"},
    {prodId:"10313913",label:"Margarita cortada fresca mini rojo de centro negro"},
    {prodId:"10313914",label:"Margarita cortada fresca mini blanco"},
    {prodId:"10313915",label:"Margarita cortada fresca mini amarillo claro de centro negro"},
    {prodId:"10313916",label:"Margarita cortada fresca anaranjado de centro negro"},
    {prodId:"10313917",label:"Margarita cortada fresca anaranjada"},
    {prodId:"10313918",label:"Margarita cortada fresca durazno de centro negro"},
    {prodId:"10313919",label:"Margarita cortada fresca durazno"},
    {prodId:"10313920",label:"Margarita cortada fresca rosada de centro negro"},
    {prodId:"10313921",label:"Margarita cortada fresca rosada"},
    {prodId:"10313922",label:"Margarita cortada fresca roja de centro negro"},
    {prodId:"10313923",label:"Margarita cortada fresca roja"},
    {prodId:"10313924",label:"Margarita cortada fresca spider durazno"},
    {prodId:"10313925",label:"Margarita cortada fresca spider roja"},
    {prodId:"10313926",label:"Margarita cortada fresca terracota"},
    {prodId:"10313927",label:"Margarita cortada fresca blanca de centro negro"},
    {prodId:"10313928",label:"Margarita cortada fresca blanca"},
    {prodId:"10313929",label:"Margarita cortada fresca amarilla"},
    {prodId:"10314400",label:"Gypsophilia cortada fresca"},
    {prodId:"10314401",label:"Gypsophilia cortada fresca bambino"},
    {prodId:"10314402",label:"Gypsophilia cortada fresca million stars"},
    {prodId:"10314403",label:"Gypsophilia cortada fresca mirabella"},
    {prodId:"10314404",label:"Gypsophilia cortada fresca new love"},
    {prodId:"10314405",label:"Gypsophilia cortada fresca orión"},
    {prodId:"10314406",label:"Gypsophilia cortada fresca perfecta"},
    {prodId:"10315100",label:"Delfinio cortado fresco"},
    {prodId:"10315101",label:"Delfinio cortada fresca azul nube"},
    {prodId:"10315102",label:"Delfinio cortada fresca rosado fuerte"},
    {prodId:"10315103",label:"Delfinio cortada fresca lavanda"},
    {prodId:"10315104",label:"Delfinio cortada fresca rosado claro"},
    {prodId:"10315105",label:"Delfinio cortada fresca púrpura"},
    {prodId:"10315106",label:"Delfinio cortada fresca blanca"},
    {prodId:"10316000",label:"Pimientos ornamentales cortados frescos"},
    {prodId:"10316001",label:"Pimiento chile cortado fresco ornamental"},
    {prodId:"10316002",label:"Pimiento mezclado cortado fresco ornamental"},
    {prodId:"10316003",label:"Pimiento anaranjado cortado fresco ornamental"},
    {prodId:"10316004",label:"Pimiento rojo cortado fresco ornamental"},
    {prodId:"10316005",label:"Pimiento amarillo cortado fresco ornamental"},
    {prodId:"10316500",label:"Escabiosa cortada fresca"},
    {prodId:"10316501",label:"Escabiosa cortada fresca anual"},
    {prodId:"10316502",label:"Escabiosa cortada fresca negra"},
    {prodId:"10316503",label:"Escabiosa cortada fresca caucásica azul"},
    {prodId:"10316504",label:"Escabiosa cortada fresca caucásica rosada"},
    {prodId:"10316505",label:"Escabiosa cortada fresca caucásica vainas"},
    {prodId:"10316506",label:"Escabiosa cortada fresca caucásica blanca"},
    {prodId:"10316507",label:"Escabiosa cortada fresca fresa"},
    {prodId:"10316700",label:"Boca de dragón cortada fresca"},
    {prodId:"10316701",label:"Boca de dragón cortada fresca bicolor"},
    {prodId:"10316702",label:"Boca de dragón cortada fresca burgundy"},
    {prodId:"10316703",label:"Boca de dragón cortada fresca rosado fuerte"},
    {prodId:"10316704",label:"Boca de dragón cortada fresca lavanda"},
    {prodId:"10316705",label:"Boca de dragón cortada fresca anaranjado claro"},
    {prodId:"10316706",label:"Boca de dragón cortada fresca rosado claro"},
    {prodId:"10316707",label:"Boca de dragón cortada fresca anaranjado"},
    {prodId:"10316708",label:"Boca de dragón cortada fresca blanco"},
    {prodId:"10316709",label:"Boca de dragón cortada fresca amarillo"},
    {prodId:"10316800",label:"Romero del pantano cortada fresca"},
    {prodId:"10316801",label:"Romero del pantano cortada fresca azul"},
    {prodId:"10316802",label:"Romero del pantano cortada fresca lavanda"},
    {prodId:"10316803",label:"Romero del pantano cortada fresca durazno"},
    {prodId:"10316804",label:"Romero del pantano cortada fresca rosada"},
    {prodId:"10316805",label:"Romero del pantano cortada fresca púrpura"},
    {prodId:"10316806",label:"Romero del pantano cortada fresca espuma de mar"},
    {prodId:"10316807",label:"Romero del pantano cortada fresca blanca"},
    {prodId:"10316808",label:"Romero del pantano cortada fresca amarilla"},
    {prodId:"10316900",label:"Matiola cortada fresca"},
    {prodId:"10316901",label:"Matiola cortada fresca apricot"},
    {prodId:"10316902",label:"Matiola cortada fresca crema"},
    {prodId:"10316903",label:"Matiola cortada fresca fucsia"},
    {prodId:"10316904",label:"Matiola cortada fresca lavanda"},
    {prodId:"10316905",label:"Matiola cortada fresca lavanda claro"},
    {prodId:"10316906",label:"Matiola cortada fresca rosada"},
    {prodId:"10316907",label:"Matiola cortada fresca púrpura"},
    {prodId:"10316908",label:"Matiola cortada fresca rojo rubí"},
    {prodId:"10316909",label:"Matiola cortada fresca enamorada rosda"},
    {prodId:"10316910",label:"Matiola cortada fresca blanca"},
    {prodId:"10317000",label:"Girasol cortada fresca"},
    {prodId:"10317001",label:"Girasol cortada fresca tinte festivo"},
    {prodId:"10317002",label:"Girasol cortada fresca mahogany"},
    {prodId:"10317003",label:"Girasol cortada fresca rayo de sol"},
    {prodId:"10317004",label:"Girasol cortada fresca brillo del sol"},
    {prodId:"10317005",label:"Girasol cortada fresca salpicada de sol"},
    {prodId:"10317006",label:"Girasol cortada fresca oso de peluche"},
    {prodId:"10317100",label:"Guisante de olor cortado fresco"},
    {prodId:"10317101",label:"Guisante de olor cortado fresco verde teñido"},
    {prodId:"10317102",label:"Guisante de olor cortado fresco rosado fuerte"},
    {prodId:"10317103",label:"Guisante de olor cortado fresco lavanda"},
    {prodId:"10317104",label:"Guisante de olor cortado fresco rosado claro"},
    {prodId:"10317105",label:"Guisante de olor cortado fresco anaranjado"},
    {prodId:"10317106",label:"Guisante de olor cortado fresco durazno teñido"},
    {prodId:"10317107",label:"Guisante de olor cortado fresco púrpura"},
    {prodId:"10317108",label:"Guisante de olor cortado fresco blanco"},
    {prodId:"10317200",label:"Cardo cortado fresco"},
    {prodId:"10317201",label:"Cardo cortado fresco alpinum"},
    {prodId:"10317202",label:"Cardo cortado fresco echinops"},
    {prodId:"10317203",label:"Cardo cortado fresco eryngium árabe"},
    {prodId:"10317204",label:"Cardo cortado fresco eryngium azul"},
    {prodId:"10317205",label:"Cardo cortado fresco eryngium orión"},
    {prodId:"10317206",label:"Cardo cortado fresco eryngium frambuesa"},
    {prodId:"10317207",label:"Cardo cortado fresco eryngium supernova"},
    {prodId:"10317208",label:"Cardo cortado fresco eryngium campanita"},
    {prodId:"10317400",label:"Flor de cera cortada fresca"},
    {prodId:"10317401",label:"Flor de cera cortada fresca alba"},
    {prodId:"10317402",label:"Flor de cera cortada fresca bicolor"},
    {prodId:"10317403",label:"Flor de cera cortada fresca chichilla"},
    {prodId:"10317404",label:"Flor de cera cortada fresca reina danzante"},
    {prodId:"10317405",label:"Flor de cera cortada fresca dinamarca"},
    {prodId:"10317406",label:"Flor de cera cortada fresca denmar"},
    {prodId:"10317407",label:"Flor de cera cortada fresca híbrida pastel flor gema"},
    {prodId:"10317408",label:"Flor de cera cortada fresca híbrida rosada flor gema"},
    {prodId:"10317409",label:"Flor de cera cortada fresca híbrida blanca rubia"},
    {prodId:"10317410",label:"Flor de cera cortada fresca híbrida eric john"},
    {prodId:"10317411",label:"Flor de cera cortada fresca híbrida dama pintada"},
    {prodId:"10317412",label:"Flor de cera cortada fresca híbrida revelación"},
    {prodId:"10317413",label:"Flor de cera cortada fresca híbrida bola de nieve"},
    {prodId:"10317414",label:"Flor de cera cortada fresca juriens brook"},
    {prodId:"10317415",label:"Flor de cera cortada fresca lady stephanie rosada"},
    {prodId:"10317416",label:"Flor de cera cortada fresca madonna"},
    {prodId:"10317417",label:"Flor de cera cortada fresca mini blanca"},
    {prodId:"10317418",label:"Flor de cera cortada fresca anaranjada"},
    {prodId:"10317419",label:"Flor de cera cortada fresca perla"},
    {prodId:"10317420",label:"Flor de cera cortada fresca pixie moon"},
    {prodId:"10317421",label:"Flor de cera cortada fresca orgullo púrpura"},
    {prodId:"10317422",label:"Flor de cera cortada fresca roja"},
    {prodId:"10317423",label:"Flor de cera cortada fresca wanaroo"},
    {prodId:"10317424",label:"Flor de cera cortada fresca amarilla"},
    {prodId:"10321800",label:"angélica cortada fresca"},
    {prodId:"10321801",label:"Angélica cortada fresca gigas"},
    {prodId:"10321802",label:"Angélica cortada fresca sylvestris"},
    {prodId:"10322700",label:"Catalea cortada fresca"},
    {prodId:"10322701",label:"Calatea cortada fresca cigarro"},
    {prodId:"10322702",label:"Calatea cortada fresca hielo verde"},
    {prodId:"10322703",label:"Calatea cortada fresca serpiente cascabel"},
    {prodId:"10326000",label:"Especies individuales o variedades de flores cortadas frescas"},
    {prodId:"10326001",label:"Agrsotema cortada fresca"},
    {prodId:"10326002",label:"Kniphophia o assegai poker cortada fresca"},
    {prodId:"10326003",label:"Bellis perennis cortada fresca"},
    {prodId:"10326004",label:"Campana de irlanda o molucella cortada fresca"},
    {prodId:"10326005",label:"Ave del paraíso cortada fresca"},
    {prodId:"10326006",label:"Novia sonrojada cortada fresca"},
    {prodId:"10326007",label:"Buddleia o arbusto mariposa cortada fresca"},
    {prodId:"10326008",label:"Bupleurum griffithii cortada fresca"},
    {prodId:"10326009",label:"California ginesta cortada fresca"},
    {prodId:"10326010",label:"Callicarpa púrpura cortada fresca"},
    {prodId:"10326011",label:"Campanilla blanca cortada fresca"},
    {prodId:"10326012",label:"Penacho dulce cortada fresca"},
    {prodId:"10326013",label:"Cariopteris cortada fresca"},
    {prodId:"10326014",label:"Centaurea o marco polo cortada fresca"},
    {prodId:"10326015",label:"Linterna china cortada fresca"},
    {prodId:"10326016",label:"Clematis recta purpurea cortada fresca"},
    {prodId:"10326017",label:"Cleome spinosa cortada fresca"},
    {prodId:"10326018",label:"Coreopsis cortada fresca"},
    {prodId:"10326019",label:"Farolitos de la virgen azul cortada fresca"},
    {prodId:"10326020",label:"Cosmos chocolate cortada fresca"},
    {prodId:"10326021",label:"Cotinus coggygria cortada fresca"},
    {prodId:"10326022",label:"Craspedia o billy bolas cortada fresca"},
    {prodId:"10326023",label:"Deutzia alta cortada fresca"},
    {prodId:"10326024",label:"Diosma cortada fresca"},
    {prodId:"10326025",label:"Echeveria suculenta chupahuevos cortada fresca"},
    {prodId:"10326026",label:"Echinacea purpurea cortada fresca"},
    {prodId:"10326027",label:"Edelweiss cortada fresca"},
    {prodId:"10326028",label:"Erythronium pagoda cortada fresca"},
    {prodId:"10326029",label:"Flor de eucalipto cortada fresca"},
    {prodId:"10326030",label:"Eucaris o lirio del amazonas cortado fresco"},
    {prodId:"10326031",label:"Eucomis o lirio de piña cortado fresco"},
    {prodId:"10326032",label:"Eupatorium maculatum cortado fresco"},
    {prodId:"10326033",label:"Filipendula cortada fresca"},
    {prodId:"10326034",label:"Digitalis cortado fresco"},
    {prodId:"10326035",label:"Gilia globo cortada fresca"},
    {prodId:"10326036",label:"Globularia de ojo azul cortada fresca"},
    {prodId:"10326037",label:"Washington hawthorne cortada fresca"},
    {prodId:"10326038",label:"Helenio cortada fresca"},
    {prodId:"10326039",label:"Helianto cortada fresca"},
    {prodId:"10326040",label:"Herperis matronalis cortada fresca"},
    {prodId:"10326041",label:"Houttuynia cordata chameleon cortada fresca"},
    {prodId:"10326043",label:"Maiz indio cortado fresco"},
    {prodId:"10326044",label:"Aro cortado fresco"},
    {prodId:"10326045",label:"Arbol del cielo japonés cortado fresco"},
    {prodId:"10326046",label:"Enredadera de jazmin florecida cortada fresca"},
    {prodId:"10326047",label:"Jatropha curcas o pólvora cortada fresca"},
    {prodId:"10326048",label:"Knautia cortada fresca"},
    {prodId:"10326049",label:"Kochia sedifolia cortada fresca"},
    {prodId:"10326050",label:"Lachenalia romaud cortada fresca"},
    {prodId:"10326051",label:"Flor de oereja de oveja cortada fresca"},
    {prodId:"10326052",label:"Lavanda cortada fresca"},
    {prodId:"10326053",label:"Leucocoryne speciosa cortada fresca"},
    {prodId:"10326054",label:"Lythrum cortada fresca"},
    {prodId:"10326055",label:"Malva zebrina cortada fresca"},
    {prodId:"10326056",label:"Margarita blanca cortada fresca"},
    {prodId:"10326057",label:"Montbretia amarilla cortada fresca"},
    {prodId:"10326058",label:"Nebelia cortada fresca"},
    {prodId:"10326059",label:"Nicotiana cortada fresca"},
    {prodId:"10326060",label:"Nigella damascena o amor en la niebla cortada fresca"},
    {prodId:"10326061",label:"Vainas nigella cortada fresca"},
    {prodId:"10326062",label:"Orquídea de monja cortada fresca"},
    {prodId:"10326063",label:"Orquídea verde paphiopedilum"},
    {prodId:"10326064",label:"Paranomus cortada fresca"},
    {prodId:"10326065",label:"Penstemon husker rojo cortado fresco"},
    {prodId:"10326066",label:"Manzana peruana cortada fresca"},
    {prodId:"10326067",label:"Phlomis sarnia cortada fresca"},
    {prodId:"10326068",label:"Didiscus o flor de encaje rosada cortada fresca"},
    {prodId:"10326069",label:"Platycodon o flor globo cortada fresca"},
    {prodId:"10326070",label:"Retzia capensis cortada fresca"},
    {prodId:"10326071",label:"Ricino común cortado fresco"},
    {prodId:"10326072",label:"Nieve en el monte cortada fresca"},
    {prodId:"10326073",label:"Solidago teñida cortada fresca"},
    {prodId:"10326074",label:"Scilla blanca cortada fresca"},
    {prodId:"10326075",label:"Stachys byzantina cortada fresca"},
    {prodId:"10326076",label:"Flor de paja cortada fresca"},
    {prodId:"10326077",label:"Oscularia suculenta cortada fresca"},
    {prodId:"10326078",label:"Flor de tillasandia cortada fresca"},
    {prodId:"10326079",label:"Triteleia cortada fresca"},
    {prodId:"10326080",label:"Tritoma naranja o chuzo caliente cortada fresca roja"},
    {prodId:"10326081",label:"Veronicastrum virginiana cortada fresca"},
    {prodId:"10326082",label:"Bromelia vriesea splendens cortada fresca"},
    {prodId:"10326084",label:"Hipericim o hierba de san juan cortada fresca"},
    {prodId:"10326085",label:"Spirea cortada fresca"},
    {prodId:"10326086",label:"Yerba de san bonifacio cortada fresca"},
    {prodId:"10341500",label:"Bouquet cortado fresco de rosa"},
    {prodId:"10341501",label:"Bouquet cortado fresco de rosa azul o lavanda o púrpura"},
    {prodId:"10341600",label:"Bouquet cortado fresco de clavel"},
    {prodId:"10341601",label:"Bouquet cortado fresco de clavel burgundi bi color"},
    {prodId:"10342100",label:"Bouquet cortado fresco de flores tropicales"},
    {prodId:"10342101",label:"Bouquet cortado fresco de orquidea dendrobium"},
    {prodId:"10342200",label:"Bouquet cortado fresco floral mixto"},
    {prodId:"10342201",label:"Bouquet cortado fresco de rosa clavel peonía"},
    {prodId:"10411700",label:"Astromelia cortada seca"},
    {prodId:"10411701",label:"Astromelia cortada seca agropoli"},
    {prodId:"10411702",label:"Astromelia cortada seca bourgogne"},
    {prodId:"10411703",label:"Astromelia cortada seca cairo"},
    {prodId:"10411704",label:"Astromelia cortada seca charmes"},
    {prodId:"10411705",label:"Astromelia cortada seca cherry bay"},
    {prodId:"10411706",label:"Astromelia cortada seca cherry white"},
    {prodId:"10411707",label:"Astromelia cortada seca dame blanche"},
    {prodId:"10411708",label:"Astromelia cortada seca diamond"},
    {prodId:"10411709",label:"Astromelia cortada seca gran canaria"},
    {prodId:"10411710",label:"Astromelia cortada seca harlekijn"},
    {prodId:"10411711",label:"Astromelia cortada seca indian summer"},
    {prodId:"10411712",label:"Astromelia cortada seca jamaica"},
    {prodId:"10411713",label:"Astromelia cortada seca macondo"},
    {prodId:"10411714",label:"Astromelia cortada seca mistique"},
    {prodId:"10411715",label:"Astromelia cortada seca my fair"},
    {prodId:"10411716",label:"Astromelia cortada seca new cairo"},
    {prodId:"10411717",label:"Astromelia cortada seca nice"},
    {prodId:"10411718",label:"Astromelia cortada seca orange bowl"},
    {prodId:"10411719",label:"Astromelia cortada seca orange queens"},
    {prodId:"10411720",label:"Astromelia cortada seca orange sun"},
    {prodId:"10411721",label:"Astromelia cortada seca paris"},
    {prodId:"10411722",label:"Astromelia cortada seca picasso"},
    {prodId:"10411723",label:"Astromelia cortada seca pink panther"},
    {prodId:"10411724",label:"Astromelia cortada seca prima donna"},
    {prodId:"10411725",label:"Astromelia cortada seca red silhouette"},
    {prodId:"10411726",label:"Astromelia cortada seca sacha"},
    {prodId:"10411727",label:"Astromelia cortada seca salmón"},
    {prodId:"10411728",label:"Astromelia cortada seca santiago"},
    {prodId:"10411729",label:"Astromelia cortada seca senna"},
    {prodId:"10411730",label:"Astromelia cortada seca snowball"},
    {prodId:"10411731",label:"Astromelia cortada seca sublime"},
    {prodId:"10411732",label:"Astromelia cortada seca tropicana"},
    {prodId:"10411733",label:"Astromelia cortada seca virginia"},
    {prodId:"10411734",label:"Astromelia cortada seca blanca"},
    {prodId:"10411800",label:"Amaranto cortado seco"},
    {prodId:"10411801",label:"Amaranto cortado seco verde colgante"},
    {prodId:"10411802",label:"Amaranto cortado seco rojo colgante"},
    {prodId:"10411803",label:"Amaranto cortado seco bronce erguido"},
    {prodId:"10411804",label:"Amaranto cortado seco verde erguido"},
    {prodId:"10411805",label:"Amaranto cortado seco rojo erguido"},
    {prodId:"10414000",label:"Jengibre cortado seco"},
    {prodId:"10414001",label:"Jengibre cortado seco  indonesio"},
    {prodId:"10414002",label:"Jengibre cortado seco jungle king rosado"},
    {prodId:"10414003",label:"Jengibre cortado seco jungle king rojo"},
    {prodId:"10414004",label:"Jengibre cortado seco rosado"},
    {prodId:"10414005",label:"Jengibre cortado seco rojo"},
    {prodId:"10414006",label:"Jengibre cortado seco torch"},
    {prodId:"10426042",label:"Jacinto con bulbo cortada seca"},
    {prodId:"10326042",label:"Jacinto con bulbo cortada fresca"},
    {prodId:"10416000",label:"Pimientos ornamentales cortado secos"},
    {prodId:"10416001",label:"Pimiento chile cortado seco ornamental"},
    {prodId:"10416002",label:"Pimiento mezclado cortado seco ornamental"},
    {prodId:"10416003",label:"Pimiento anaranjado cortado seco ornamental"},
    {prodId:"10416004",label:"Pimiento rojo cortado seco ornamental"},
    {prodId:"10416005",label:"Pimiento amarillo cortado seco ornamental"},
    {prodId:"10417000",label:"Girasol cortada seca"},
    {prodId:"10417001",label:"Girasol cortada seca tinte festivo"},
    {prodId:"10417002",label:"Girasol cortada seca mahogany"},
    {prodId:"10417003",label:"Girasol cortada seca rayo de sol"},
    {prodId:"10417004",label:"Girasol cortada seca brillo del sol"},
    {prodId:"10417005",label:"Girasol cortada seca salpicada de sol"},
    {prodId:"10417006",label:"Girasol cortada seca oso de peluche"},
    {prodId:"10425100",label:"Mimosa cortada seca"},
    {prodId:"10425101",label:"Mimosa cortada seca azul o púrpura"},
    {prodId:"10425102",label:"Mimosa dedo cortada seca"},
    {prodId:"10425103",label:"Mimosa cortada seca floribunda o italia"},
    {prodId:"10425104",label:"Mimosa cortada seca mirandola"},
    {prodId:"10426000",label:"Especies individuales o variedades de flores cortadas secas"},
    {prodId:"10426001",label:"Agrsotema cortada seca"},
    {prodId:"10426002",label:"Kniphophia o assegai poker cortada seca"},
    {prodId:"10426003",label:"Bellis perennis cortada seca"},
    {prodId:"10426004",label:"Campana de irlanda o molucella cortada seca"},
    {prodId:"10426005",label:"Ave del paraíso cortada seca"},
    {prodId:"10426006",label:"Novia sonrojada cortada seca"},
    {prodId:"10426007",label:"Buddleia o arbusto mariposa cortada seca"},
    {prodId:"10426008",label:"Bupleurum griffithii cortada seca"},
    {prodId:"10426009",label:"California ginesta cortada seca"},
    {prodId:"10426010",label:"Callicarpa púrpura cortada seca"},
    {prodId:"10426011",label:"Campanilla blanca cortada seca"},
    {prodId:"10426012",label:"Penacho dulce cortada seca"},
    {prodId:"10426013",label:"Cariopteris cortada seca"},
    {prodId:"10426014",label:"Centaurea o marco polo cortada seca"},
    {prodId:"10426015",label:"Linterna china cortada seca"},
    {prodId:"10426016",label:"Clematis recta purpurea cortada seca"},
    {prodId:"10426017",label:"Cleome spinosa cortada seca"},
    {prodId:"10426018",label:"Coreopsis cortada seca"},
    {prodId:"10426019",label:"Farolitos de la virgen azul cortada seca"},
    {prodId:"10426020",label:"Cosmos chocolate cortada seca"},
    {prodId:"10426021",label:"Cotinus coggygria cortada seca"},
    {prodId:"10426022",label:"Craspedia o billy bolas cortada seca"},
    {prodId:"10426023",label:"Deutzia alta cortada seca"},
    {prodId:"10426024",label:"Diosma cortada seca"},
    {prodId:"10426025",label:"Echeveria suculenta chupahuevos cortada seca"},
    {prodId:"10426026",label:"Echinacea purpurea cortada seca"},
    {prodId:"10426027",label:"Edelweiss cortada seca"},
    {prodId:"10426028",label:"Erythronium pagoda cortada seca"},
    {prodId:"10426029",label:"Flor de eucalipto cortada seca"},
    {prodId:"10426030",label:"Eucaris o lirio del amazonas cortado seco"},
    {prodId:"10426031",label:"Eucomis o lirio de piña cortado seco"},
    {prodId:"10426032",label:"Eupatorium maculatum cortado seco"},
    {prodId:"10426033",label:"Filipendula cortada seca"},
    {prodId:"10426034",label:"Digitalis cortado seco"},
    {prodId:"10426035",label:"Gilia globo cortada seca"},
    {prodId:"10426036",label:"Globularia de ojo azul cortada seca"},
    {prodId:"10426037",label:"Washington hawthorne cortada seca"},
    {prodId:"10426038",label:"Helenio cortada seca"},
    {prodId:"10426039",label:"Helianto cortada seca"},
    {prodId:"10426040",label:"Herperis matronalis cortada seca"},
    {prodId:"10426041",label:"Houttuynia cordata chameleon cortada seca"},
    {prodId:"10151905",label:"Bulbos o tallos de jacinto"},
    {prodId:"10426043",label:"Maiz indio cortado seco"},
    {prodId:"10426044",label:"Jack in the pulpit cortado seco"},
    {prodId:"10426045",label:"Arbol del cielo japonés cortado seco"},
    {prodId:"10426046",label:"Enredadera de jazmin florecida cortada seca"},
    {prodId:"10426047",label:"Jatropha curcas o pólvora cortada seca"},
    {prodId:"10426048",label:"Knautia cortada seca"},
    {prodId:"10426049",label:"Kochia sedifolia cortada seca"},
    {prodId:"10426050",label:"Lachenalia romaud cortada seca"},
    {prodId:"10426051",label:"Flor de oreja de oveja cortada seca"},
    {prodId:"10426052",label:"Lavanda cortada seca"},
    {prodId:"10426053",label:"Leucocoryne speciosa cortada seca"},
    {prodId:"10426054",label:"Lythrum cortada seca"},
    {prodId:"10426055",label:"Malva zebrina cortada seca"},
    {prodId:"10426056",label:"Margarita blanca cortada seca"},
    {prodId:"10426057",label:"Montbretia amarilla cortada seca"},
    {prodId:"10426058",label:"Nebelia cortada seca"},
    {prodId:"10426059",label:"Nicotiana cortada seca"},
    {prodId:"10426060",label:"Nigella damascena o amor en la niebla cortada seca"},
    {prodId:"10426061",label:"Nigella cortada seca"},
    {prodId:"10426062",label:"Orquídea de monja cortada seca"},
    {prodId:"10426063",label:"Orquídea verde paphiopedilum cortada seca"},
    {prodId:"10426064",label:"Paranomus cortada seca"},
    {prodId:"10426065",label:"Penstemon husker rojo cortado seco"},
    {prodId:"10426066",label:"Manzana peruana cortada seca"},
    {prodId:"10426067",label:"Phlomis sarnia cortada seca"},
    {prodId:"10426068",label:"Didiscus o flor de encaje rosada cortada seca"},
    {prodId:"10426069",label:"Platycodon o flor globo cortada seca"},
    {prodId:"10426070",label:"Retzia capensis cortada seca"},
    {prodId:"10426071",label:"Ricino común cortado seco"},
    {prodId:"10426072",label:"Nieve en el monte cortada seca"},
    {prodId:"10426073",label:"Solidago teñida cortada seca"},
    {prodId:"10426074",label:"Scilla blanca cortada seca"},
    {prodId:"10426075",label:"Stachys byzantina cortada seca"},
    {prodId:"10426076",label:"Flor de paja cortada seca"},
    {prodId:"10426077",label:"Oscularia suculenta cortada seca"},
    {prodId:"10426078",label:"Flor de tillasandia cortada seca"},
    {prodId:"10426079",label:"Triteleia cortada seca"},
    {prodId:"10426080",label:"Tritoma naranja o chuzo caliente cortada seca roja"},
    {prodId:"10426081",label:"Veronicastrum virginiana cortada seca"},
    {prodId:"10426082",label:"Bromelia vriesea splendens cortada seca"},
    {prodId:"10426084",label:"Hipericim o hierba de san juan cortada seca"},
    {prodId:"10426085",label:"Spirea cortada seca"},
    {prodId:"10426086",label:"Yerba de san bonifacio cortada seca"},
    {prodId:"10426100",label:"Sello de salomón (polygonato) cortada seca"},
    {prodId:"10426101",label:"Sello de salomón (polygonato) falso cortada seca"},
    {prodId:"10426102",label:"Sello de salomón (polygonato) variegado cortada seca"},
    {prodId:"10501600",label:"Chili verde cortado fresco"},
    {prodId:"10501601",label:"Chili verde cortado fresco avellana"},
    {prodId:"10501602",label:"Chili verde cortado fresco romerillo"},
    {prodId:"10501603",label:"Chili verde cortado fresco pacarilla"},
    {prodId:"10501604",label:"Chili verde cortado fresco musgo"},
    {prodId:"10501800",label:"Hojas de eucalipto cortadas prescas"},
    {prodId:"10501801",label:"Eucalipto cortado fresco bebé azul"},
    {prodId:"10501802",label:"Eucalipto cortado fresco alto bonsai"},
    {prodId:"10501803",label:"Eucalipto cortado fresco pluma"},
    {prodId:"10501804",label:"Eucalipto cortado fresco gunnii"},
    {prodId:"10501805",label:"Eucalipto cortado fresco parvifolia"},
    {prodId:"10501806",label:"Eucalipto cortado fresco preservado"},
    {prodId:"10501807",label:"Eucalipto cortado fresco sin semilla"},
    {prodId:"10501808",label:"Eucalipto cortado fresco sin semilla y sin hojas"},
    {prodId:"10501809",label:"Eucalipto cortado fresco sin semillas llorón"},
    {prodId:"10501810",label:"Eucalipto cortado fresco dólar de plata"},
    {prodId:"10501811",label:"Eucalipto cortado fresco alto espiral"},
    {prodId:"10501812",label:"Eucalipto cortado fresco azul verdadero"},
    {prodId:"10501813",label:"Eucalipto cortado fresco sauce sin semillas"},
    {prodId:"10502000",label:"Hojas o follajes cortados frescos"},
    {prodId:"10502001",label:"Follaje fresco cortado de melaluca australiana"},
    {prodId:"10502002",label:"Follaje fresco cortado de lepto azul"},
    {prodId:"10502003",label:"Follaje fresco cortado de corylus avellana"},
    {prodId:"10502004",label:"Follaje fresco cortado de hosta verde"},
    {prodId:"10502005",label:"Follaje fresco cortado de hosta variegada"},
    {prodId:"10502006",label:"Follaje fresco cortado de kunzia"},
    {prodId:"10502007",label:"Follaje fresco cortado de jade"},
    {prodId:"10502008",label:"Follaje fresco cortado de magnolia"},
    {prodId:"10502009",label:"Follaje fresco cortado de meyeril o foxtail"},
    {prodId:"10502010",label:"Follaje fresco cortado de olivo"},
    {prodId:"10502011",label:"Follaje fresco cortado de pistacho"},
    {prodId:"10502012",label:"Follaje fresco cortado de ciruelo"},
    {prodId:"10502013",label:"Follaje fresco cortado de robellini"},
    {prodId:"10502014",label:"Follaje fresco cortado de viburnum"},
    {prodId:"10502015",label:"Follaje fresco cortado de xanadu"},
    {prodId:"10502016",label:"Follaje fresco cortado de sanderiana"},
    {prodId:"10502017",label:"Follaje fresco cortado de gardenia"},
    {prodId:"10502100",label:"Pastos costados frescos"},
    {prodId:"10502101",label:"Pasto fresco cortado lirio florida"},
    {prodId:"10502102",label:"Pasto fresco cortado variegado"},
    {prodId:"10502103",label:"Pasto fresco cortado oso"},
    {prodId:"10502104",label:"Pasto fresco cortado bambú"},
    {prodId:"10502105",label:"Pasto fresco cortado caña"},
    {prodId:"10502106",label:"Pasto fresco cortado descorchador"},
    {prodId:"10502107",label:"Pasto fresco cortado pluma"},
    {prodId:"10502108",label:"Pasto fresco cortado flexi"},
    {prodId:"10502109",label:"Pasto fresco cortado fuente"},
    {prodId:"10502110",label:"Pasto fresco cortado carrizo"},
    {prodId:"10502111",label:"Pasto fresco cortado millet"},
    {prodId:"10502112",label:"Pasto fresco cortado millet negro"},
    {prodId:"10502113",label:"Pasto fresco cortado milo"},
    {prodId:"10502114",label:"Pasto fresco cortado avena variegada"},
    {prodId:"10502115",label:"Pasto fresco cortado phalaris canariensis"},
    {prodId:"10502116",label:"Pasto fresco cortado ciruela púrpiura"},
    {prodId:"10502117",label:"Pasto fresco cortado cascabel"},
    {prodId:"10502118",label:"Pasto fresco cortado cinta"},
    {prodId:"10502119",label:"Pasto fresco cortado avena marina"},
    {prodId:"10502120",label:"Pasto fresco cortado steelgrass"},
    {prodId:"10502121",label:"Pasto fresco cortado cebra variegado"},
    {prodId:"10502122",label:"Pasto fresco cortado panicum o jungla"},
    {prodId:"10502123",label:"Pasto fresco cortado pampas"},
    {prodId:"10502124",label:"Pasto fresco cortado pimienta"},
    {prodId:"10502125",label:"Pasto fresco cortado cebra"},
    {prodId:"10502500",label:"Mirto cortado fresco"},
    {prodId:"10502501",label:"Mirto fresco cortado enano"},
    {prodId:"10502502",label:"Mirto fresco cortado variegado"},
    {prodId:"10502900",label:"Hojas frescas cortadas de especies sencillas"},
    {prodId:"10502901",label:"Acanto o cordone fresca cortada"},
    {prodId:"10502902",label:"Pluma de acacia púrpura fresca cortada"},
    {prodId:"10502903",label:"Adina rubella fresca cortada"},
    {prodId:"10502904",label:"Agonis flexuosa fresca cortada"},
    {prodId:"10502905",label:"Azara dentata fresca cortada"},
    {prodId:"10502906",label:"Arbusto barker fresca cortada"},
    {prodId:"10502907",label:"Corona de laurel fresca cortada"},
    {prodId:"10502908",label:"Llama azul fresca cortada"},
    {prodId:"10502909",label:"Buxus africana fresca cortada"},
    {prodId:"10502910",label:"Laurel fresca cortada"},
    {prodId:"10502911",label:"Hoja de camelia fresca cortada"},
    {prodId:"10502912",label:"Cedro dorado de nueva zelandia fresca cortada"},
    {prodId:"10502913",label:"Ceratopetalum de nueva zelandia fresca cortada"},
    {prodId:"10502914",label:"Choisya ternata fresca cortada"},
    {prodId:"10502915",label:"Cocculus fresca cortada"},
    {prodId:"10502916",label:"Croto fresca cortada"},
    {prodId:"10502917",label:"Datura fresca cortada"},
    {prodId:"10502918",label:"Dusty miller fresca cortada"},
    {prodId:"10502919",label:"Elegia capensis fresca cortada"},
    {prodId:"10502920",label:"Hoja de euonymous fresca cortada"},
    {prodId:"10502921",label:"Hoja de lino fresca cortada"},
    {prodId:"10502922",label:"Hoja de galax fresca cortada"},
    {prodId:"10502923",label:"Hoja de oro fresca cortada"},
    {prodId:"10502924",label:"Hoja de gravillea fresca cortada"},
    {prodId:"10502925",label:"Hoja de pasto cola de caballo o culebra fresca cortada"},
    {prodId:"10502926",label:"Acacia hoja de navaja fresca cortada"},
    {prodId:"10502927",label:"Hoja de laurel fresca cortada"},
    {prodId:"10502928",label:"Hoja de cuero fresca cortada"},
    {prodId:"10502929",label:"Hoja de ligularia fresca cortada"},
    {prodId:"10502930",label:"Hoja de ligularia  argentea fresca cortada"},
    {prodId:"10502931",label:"Lophomyrtus fresca cortada"},
    {prodId:"10502932",label:"Lycopodium  o pino princresa fresca cortada"},
    {prodId:"10502933",label:"Mahonia aquifolium fresca cortada"},
    {prodId:"10502934",label:"Hoja de nagi fresca cortada"},
    {prodId:"10502935",label:"Palma de abanico palmetto fresca cortada"},
    {prodId:"10502936",label:"Papyrus umbrella florida fresca cortada"},
    {prodId:"10502937",label:"Pennycress fresca cortada"},
    {prodId:"10502938",label:"Photinia robin rojo fresca cortada"},
    {prodId:"10502939",label:"Diablo de nueve cortezas o physocarpus fresca cortada"},
    {prodId:"10502940",label:"Pimelea nivea fresca cortada"},
    {prodId:"10502941",label:"Hoja de pistacho fresca cortada"},
    {prodId:"10502942",label:"Plumosa fresca cortada"},
    {prodId:"10502943",label:"Podocarpus fresca cortada"},
    {prodId:"10502944",label:"Pokeweed fresca cortada"},
    {prodId:"10502945",label:"Photinia  de punta roja fresca cortada"},
    {prodId:"10502946",label:"Rhamnus o buckthorn variegada fresca cortada"},
    {prodId:"10502947",label:"Rododendro en flor fresca cortada"},
    {prodId:"10502948",label:"Hoja de rosio fresca cortada"},
    {prodId:"10502949",label:"Ruscus florida largo fresca cortada"},
    {prodId:"10502950",label:"Ruscus italiana fresca cortada"},
    {prodId:"10502951",label:"Salal u hoja de limón fresca cortada"},
    {prodId:"10502952",label:"Ruscifolia sarcococca fresca cortada"},
    {prodId:"10502953",label:"Cheflera fresca cortada"},
    {prodId:"10502954",label:"Avena de mar uniola latifolia fresca cortada"},
    {prodId:"10502955",label:"Tomillo plateado fresca cortada"},
    {prodId:"10502956",label:"Skimmia fresca cortada"},
    {prodId:"10502957",label:"Springeri fresca cortada"},
    {prodId:"10502958",label:"Tulepods fresca cortada"},
    {prodId:"10502959",label:"Weigelia fresca cortada"},
    {prodId:"11101500",label:"Minerales"},
    {prodId:"11101501",label:"Mica"},
    {prodId:"11101502",label:"Lija o esmeril"},
    {prodId:"11101503",label:"Cuarzo"},
    {prodId:"11101504",label:"Pirita"},
    {prodId:"11101505",label:"Azufre o sulfuro"},
    {prodId:"11101506",label:"Tiza"},
    {prodId:"11101507",label:"Grafito"},
    {prodId:"11101508",label:"Dolomita"},
    {prodId:"11101509",label:"Magnesita"},
    {prodId:"11101510",label:"Asbesto (amianto)"},
    {prodId:"11101511",label:"Calcio"},
    {prodId:"11101512",label:"Borato"},
    {prodId:"11101513",label:"Criolita"},
    {prodId:"11101514",label:"Feldespato"},
    {prodId:"11101515",label:"Leucita"},
    {prodId:"11101516",label:"Nefelita"},
    {prodId:"11101517",label:"Esteatita"},
    {prodId:"11101518",label:"Talco"},
    {prodId:"11101519",label:"Diamante en bruto"},
    {prodId:"11101520",label:"Granates"},
    {prodId:"11101521",label:"Carburo de silicio"},
    {prodId:"11101522",label:"Carbón activado"},
    {prodId:"11101523",label:"Mulita"},
    {prodId:"11101524",label:"Fluorita"},
    {prodId:"11101525",label:"Kieserita"},
    {prodId:"11101526",label:"Carnalita"},
    {prodId:"11101527",label:"Sílice de diatomeas"},
    {prodId:"11101528",label:"Óxido de aluminio activado"},
    {prodId:"11101529",label:"Zeolita"},
    {prodId:"11101530",label:"Calcita"},
    {prodId:"11101531",label:"Serpentina"},
    {prodId:"11101532",label:"Peridoto"},
    {prodId:"11101533",label:"Pirofilita"},
    {prodId:"11101534",label:"Diamante pulido"},
    {prodId:"11101600",label:"Minerales metálicos"},
    {prodId:"11101601",label:"Mineral de hierro"},
    {prodId:"11101602",label:"Mineral de titanio"},
    {prodId:"11101603",label:"Mineral de uranio"},
    {prodId:"11101604",label:"Mineral de cobre"},
    {prodId:"11101605",label:"Mineral de aluminio"},
    {prodId:"11101606",label:"Mineral de níquel"},
    {prodId:"11101607",label:"Mineral de plata"},
    {prodId:"11101608",label:"Mineral de plomo"},
    {prodId:"11101609",label:"Mineral de zinc"},
    {prodId:"11101610",label:"Mineral de estaño"},
    {prodId:"11101611",label:"Mineral de manganeso"},
    {prodId:"11101612",label:"Mineral de cromo"},
    {prodId:"11101613",label:"Mineral de tungsteno o wolframio"},
    {prodId:"11101614",label:"Mineral de molibdeno"},
    {prodId:"11101615",label:"Mineral de cobalto"},
    {prodId:"11101616",label:"Mineral de oro"},
    {prodId:"11101617",label:"Mineral de tántalo"},
    {prodId:"11101618",label:"Mineral de platino"},
    {prodId:"11101619",label:"Mineral de vermiculita"},
    {prodId:"11101620",label:"Mineral de torio"},
    {prodId:"11101621",label:"Mineral de cianita"},
    {prodId:"11101622",label:"Mineral de antimonio"},
    {prodId:"11101623",label:"Mineral de zirconio"},
    {prodId:"11101624",label:"Mineral de magnetita"},
    {prodId:"11101625",label:"Mineral de bauxita"},
    {prodId:"11101700",label:"Metales de Base"},
    {prodId:"11101701",label:"Escoria o ceniza"},
    {prodId:"11101702",label:"Grafito natural"},
    {prodId:"11101704",label:"Acero"},
    {prodId:"11101705",label:"Aluminio"},
    {prodId:"11101706",label:"Níquel"},
    {prodId:"11101708",label:"Bismuto"},
    {prodId:"11101709",label:"Antimonio"},
    {prodId:"11101710",label:"Cadmio"},
    {prodId:"11101711",label:"Aleación no ferrosa"},
    {prodId:"11101712",label:"Aleación ferrosa"},
    {prodId:"11101713",label:"Hierro"},
    {prodId:"11101714",label:"Plomo"},
    {prodId:"11101716",label:"Estaño"},
    {prodId:"11101717",label:"Indio"},
    {prodId:"11101718",label:"Paladio"},
    {prodId:"11101719",label:"Zinc"},
    {prodId:"11101800",label:"Metales preciosos"},
    {prodId:"11101801",label:"Oro"},
    {prodId:"11101802",label:"Plata"},
    {prodId:"11101803",label:"Platino"},
    {prodId:"11101900",label:"Productos minerales intermedios, procesados y refinados"},
    {prodId:"11101901",label:"Concentrado de mineral de hierro"},
    {prodId:"11101902",label:"Concentrado de níquel"},
    {prodId:"11101903",label:"Briqueta o compacto de níquel"},
    {prodId:"11101904",label:"Mate de níquel"},
    {prodId:"11101905",label:"Aluminio liquido"},
    {prodId:"11101906",label:"Concentrado de cobre"},
    {prodId:"11101907",label:"Cátodo de cobre"},
    {prodId:"11101908",label:"Sínter o perdigones de manganeso"},
    {prodId:"11111500",label:"Barro y Tierra"},
    {prodId:"11111501",label:"Tierra"},
    {prodId:"11111502",label:"Material de relleno"},
    {prodId:"11111503",label:"Capa vegetal"},
    {prodId:"11111504",label:"Tierra de cristal"},
    {prodId:"11111505",label:"Suelo artificial"},
    {prodId:"11111600",label:"Piedra"},
    {prodId:"11111601",label:"Yeso"},
    {prodId:"11111602",label:"Travertino"},
    {prodId:"11111603",label:"Alabastro"},
    {prodId:"11111604",label:"Granito"},
    {prodId:"11111605",label:"Mármol"},
    {prodId:"11111606",label:"Pizarra"},
    {prodId:"11111607",label:"Arenisca"},
    {prodId:"11111608",label:"Caliza"},
    {prodId:"11111609",label:"Basalto"},
    {prodId:"11111610",label:"Piedra pómez"},
    {prodId:"11111611",label:"Gravilla"},
    {prodId:"11111612",label:"Caliza en polvo"},
    {prodId:"11111613",label:"Wollastonita"},
    {prodId:"11111700",label:"Arena"},
    {prodId:"11111701",label:"Arena de sílice"},
    {prodId:"11111800",label:"Arcillas"},
    {prodId:"11111801",label:"Terracota"},
    {prodId:"11111802",label:"Arcilla refractaria"},
    {prodId:"11111803",label:"Caolín u otras arcillas caolínicas"},
    {prodId:"11111804",label:"Bentonita"},
    {prodId:"11111805",label:"Andalucita"},
    {prodId:"11111806",label:"Mullita"},
    {prodId:"11111807",label:"Chamota"},
    {prodId:"11111808",label:"Arcilla común"},
    {prodId:"11111809",label:"Arcilla de bola"},
    {prodId:"11111810",label:"Tierra de fuller"},
    {prodId:"11111811",label:"Haydita"},
    {prodId:"11111812",label:"Vermiculita"},
    {prodId:"11121500",label:"Savia"},
    {prodId:"11121502",label:"Resina"},
    {prodId:"11121503",label:"Laca"},
    {prodId:"11121600",label:"Madera"},
    {prodId:"11121603",label:"Troncos"},
    {prodId:"11121604",label:"Madera blanda"},
    {prodId:"11121605",label:"Ratán"},
    {prodId:"11121606",label:"Corcho"},
    {prodId:"11121607",label:"Pulpa de madera"},
    {prodId:"11121608",label:"Bambú"},
    {prodId:"11121610",label:"Maderas duras"},
    {prodId:"11121612",label:"Médula de madera"},
    {prodId:"11121614",label:"Madera de teca"},
    {prodId:"11121615",label:"Madera de álamo"},
    {prodId:"11121616",label:"Madera de pino"},
    {prodId:"11121617",label:"Madera de roble"},
    {prodId:"11121618",label:"Madera de arce"},
    {prodId:"11121619",label:"Madera de cerezo"},
    {prodId:"11121620",label:"Madera de tornillo"},
    {prodId:"11121700",label:"Productos secundarios de la explotación forestal"},
    {prodId:"11121701",label:"Aserrín"},
    {prodId:"11121702",label:"Astillas de madera"},
    {prodId:"11121703",label:"Musgo de turba"},
    {prodId:"11121705",label:"Corteza"},
    {prodId:"11121706",label:"Composta de madera"},
    {prodId:"11121707",label:"Viruta de madera"},
    {prodId:"11121708",label:"Harina de madera"},
    {prodId:"11121709",label:"Bálsamos"},
    {prodId:"11121710",label:"Brea de madera"},
    {prodId:"11121800",label:"Fibras de plantas"},
    {prodId:"11121801",label:"Cáñamo"},
    {prodId:"11121802",label:"Algodón"},
    {prodId:"11121803",label:"Lino"},
    {prodId:"11121804",label:"Yute"},
    {prodId:"11121805",label:"Sisal"},
    {prodId:"11121806",label:"Fibra de coco"},
    {prodId:"11121807",label:"Fibras de abacá"},
    {prodId:"11121808",label:"Ramio"},
    {prodId:"11121809",label:"Paja"},
    {prodId:"11121810",label:"Mijo común o ixtle o piasava"},
    {prodId:"11121900",label:"Productos de perfumería"},
    {prodId:"11121901",label:"Raíz de regaliz"},
    {prodId:"11122000",label:"Productos de madera diseñados"},
    {prodId:"11122001",label:"Triplex"},
    {prodId:"11122002",label:"Tablex"},
    {prodId:"11122003",label:"Corrugado"},
    {prodId:"11122004",label:"Enchapes de madera"},
    {prodId:"11122005",label:"Madera laminada engomada"},
    {prodId:"11122006",label:"Madera tratada"},
    {prodId:"11131500",label:"Cuero, pieles o materiales textiles de animales"},
    {prodId:"11131501",label:"Plumas"},
    {prodId:"11131502",label:"Pieles"},
    {prodId:"11131503",label:"Pelo animal"},
    {prodId:"11131504",label:"Cueros"},
    {prodId:"11131505",label:"Mohair sin procesar"},
    {prodId:"11131506",label:"Lana sin procesar"},
    {prodId:"11131507",label:"Gamuza"},
    {prodId:"11131508",label:"Cuero de grano completo"},
    {prodId:"11131600",label:"Otros productos de animales"},
    {prodId:"11131602",label:"Semen"},
    {prodId:"11131603",label:"Excreciones"},
    {prodId:"11131604",label:"Huevas de pescado"},
    {prodId:"11131605",label:"Huesos"},
    {prodId:"11131607",label:"Embriones"},
    {prodId:"11131608",label:"Artículos de concha"},
    {prodId:"11141600",label:"Desechos no metálicos y chatarra"},
    {prodId:"11141601",label:"Desecho o desperdicio textil"},
    {prodId:"11141602",label:"Desecho o desperdicio plástico"},
    {prodId:"11141603",label:"Desechos de aceite"},
    {prodId:"11141604",label:"Desechos de papel"},
    {prodId:"11141605",label:"Desecho o desperdicios de vidrio"},
    {prodId:"11141606",label:"Desecho o desperdicios de madera"},
    {prodId:"11141607",label:"Desecho o desperdicios de caucho"},
    {prodId:"11141608",label:"Desecho o desperdicios peligrosos"},
    {prodId:"11141609",label:"Composición de cuero"},
    {prodId:"11141610",label:"Desecho o desperdicios de cuero"},
    {prodId:"11141700",label:"Desperdicios o restos de alimentos y tabaco"},
    {prodId:"11141701",label:"Desecho o desperdicios de alimentos"},
    {prodId:"11141702",label:"Desecho o desperdicios de tabaco"},
    {prodId:"11151500",label:"Fibras"},
    {prodId:"11151501",label:"Fibras de acetato"},
    {prodId:"11151502",label:"Fibras de nylon"},
    {prodId:"11151503",label:"Fibras de poliéster"},
    {prodId:"11151504",label:"Fibras de acrílico"},
    {prodId:"11151505",label:"Fibras de viscosas"},
    {prodId:"11151506",label:"Fibras de rayón"},
    {prodId:"11151507",label:"Fibras de algodón"},
    {prodId:"11151508",label:"Fibras de lana"},
    {prodId:"11151509",label:"Fibras de seda"},
    {prodId:"11151510",label:"Fibras vegetales"},
    {prodId:"11151511",label:"Fibras de polipropileno"},
    {prodId:"11151512",label:"Fibras de vidrio"},
    {prodId:"11151513",label:"Fibras cerámicas"},
    {prodId:"11151514",label:"Fibras de polímero de aramida"},
    {prodId:"11151515",label:"Fibras de asbesto"},
    {prodId:"11151516",label:"Fibras de poliuretano o spándex"},
    {prodId:"11151517",label:"Fibras de alcohol polivinilo"},
    {prodId:"11151518",label:"Fibras de polietileno"},
    {prodId:"11151519",label:"Fibras de lana de roca o lana mineral"},
    {prodId:"11151600",label:"Hebras"},
    {prodId:"11151601",label:"Hebra de algodón"},
    {prodId:"11151602",label:"Hebra de seda"},
    {prodId:"11151603",label:"Hebra de poliéster"},
    {prodId:"11151604",label:"Hebra de poliamida"},
    {prodId:"11151605",label:"Hebra de bismaleimida"},
    {prodId:"11151606",label:"Hebra de fibra de vidrio"},
    {prodId:"11151607",label:"Hebra de grafito"},
    {prodId:"11151608",label:"Hebra de nylon"},
    {prodId:"11151609",label:"Hebra de resina impregnada"},
    {prodId:"11151610",label:"Hebra de caucho o látex"},
    {prodId:"11151611",label:"Hebra de spandex"},
    {prodId:"11151612",label:"Hebra de asbesto"},
    {prodId:"11151700",label:"Hilos"},
    {prodId:"11151701",label:"Hilado de lana"},
    {prodId:"11151702",label:"Hilado de algodón"},
    {prodId:"11151703",label:"Hilado de poliéster"},
    {prodId:"11151704",label:"Hilado de acrílico"},
    {prodId:"11151705",label:"Hilado de seda"},
    {prodId:"11151706",label:"Hilado de ramie"},
    {prodId:"11151708",label:"Hilado de pelo animal"},
    {prodId:"11151709",label:"Hilado de sintético"},
    {prodId:"11151710",label:"Hilado de yute"},
    {prodId:"11151711",label:"Hilado de fibra de coco"},
    {prodId:"11151712",label:"Hilado de papel"},
    {prodId:"11151713",label:"Hilado de cáñamo"},
    {prodId:"11151714",label:"Hilado de vidrio"},
    {prodId:"11151715",label:"Hilado de lino"},
    {prodId:"11151716",label:"Hilado mezclado"},
    {prodId:"11161500",label:"Tejidos de seda"},
    {prodId:"11161501",label:"Textiles de seda de tejido simple"},
    {prodId:"11161502",label:"Textiles de seda de tejido  jacquard"},
    {prodId:"11161503",label:"Textiles de lana"},
    {prodId:"11161504",label:"Textiles de tejido de pana"},
    {prodId:"11161600",label:"Tejidos de lana"},
    {prodId:"11161601",label:"Tejidos de lana en tafetán"},
    {prodId:"11161602",label:"Tejidos de lana urdidos a la jacquard"},
    {prodId:"11161603",label:"Tejidos de lana de punto"},
    {prodId:"11161604",label:"Tejidos de lana de ligamento de sarga"},
    {prodId:"11161700",label:"Tejidos de algodón"},
    {prodId:"11161701",label:"Textiles de algodón de tejido simple"},
    {prodId:"11161702",label:"Textiles de algodón de tejido de pana"},
    {prodId:"11161703",label:"Tela de algodón oxford"},
    {prodId:"11161704",label:"Textiles de algodón tejido"},
    {prodId:"11161705",label:"Textiles de terciopelo de algodón"},
    {prodId:"11161800",label:"Tejidos sintéticos"},
    {prodId:"11161801",label:"Textil sintético de tejido sintético"},
    {prodId:"11161802",label:"Textil sintético de tejido jacquard"},
    {prodId:"11161803",label:"Textil sintético de tejido dobby"},
    {prodId:"11161804",label:"Textil sintético tejido"},
    {prodId:"11161805",label:"Textil sintético de terciopelo"},
    {prodId:"11161806",label:"Textil sintético de tejido de pana"},
    {prodId:"11161807",label:"Textil sintético de felpa"},
    {prodId:"11162000",label:"Tejidos de materiales vegetales no de algodón"},
    {prodId:"11162001",label:"Textil de fibra vegetal tejida distinta de algodón simple"},
    {prodId:"11162002",label:"Textil de fibra vegetal tejida distinta de algodón"},
    {prodId:"11162003",label:"Tela de arpillera o cáñamo o yute"},
    {prodId:"11162100",label:"Tejidos o telas especiales"},
    {prodId:"11162101",label:"Tela o textil de queso"},
    {prodId:"11162102",label:"Tela o textil de bismaleimida"},
    {prodId:"11162104",label:"Tela o textil de grafito"},
    {prodId:"11162105",label:"Tela o textil de vidrio"},
    {prodId:"11162107",label:"Tela de resina impregnada"},
    {prodId:"11162108",label:"Tela malla de alambre"},
    {prodId:"11162109",label:"Encaje"},
    {prodId:"11162110",label:"Malla red"},
    {prodId:"11162111",label:"Malla"},
    {prodId:"11162112",label:"Telas revestidas"},
    {prodId:"11162113",label:"Telas para tapicería"},
    {prodId:"11162114",label:"Telas o cintas de sujeción por contacto"},
    {prodId:"11162115",label:"Trenza elástica"},
    {prodId:"11162116",label:"Tela de fique o estopa"},
    {prodId:"11162117",label:"Tela de caucho"},
    {prodId:"11162118",label:"Tela de hilado de papel"},
    {prodId:"11162119",label:"Tela de calcado"},
    {prodId:"11162120",label:"Tela de cernido"},
    {prodId:"11162121",label:"Artículos de pasamanería y ornamentales"},
    {prodId:"11162122",label:"Tela de sujeción"},
    {prodId:"11162123",label:"Tela de cinta"},
    {prodId:"11162124",label:"Tela de fieltro"},
    {prodId:"11162125",label:"Tela de correas"},
    {prodId:"11162126",label:"Tela acolchada"},
    {prodId:"11162127",label:"Tela de camuflaje"},
    {prodId:"11162128",label:"Tela de paracaídas"},
    {prodId:"11162129",label:"Tela marquisette"},
    {prodId:"11162130",label:"Tela dosel"},
    {prodId:"11162131",label:"Tela para ribeteado"},
    {prodId:"11162132",label:"Tela de damasco"},
    {prodId:"11162133",label:"Tela de satín"},
    {prodId:"11162200",label:"Telas no tejidas"},
    {prodId:"11162201",label:"Telas no tejidas ligadas térmicamente"},
    {prodId:"11162202",label:"Telas no tejidas hidroentrelazadas"},
    {prodId:"11162300",label:"Cueros"},
    {prodId:"11162301",label:"Cuero de gamuza"},
    {prodId:"11162302",label:"Cuero de cabra"},
    {prodId:"11162303",label:"Cuero de oveja"},
    {prodId:"11162304",label:"Cuero acharolado"},
    {prodId:"11162305",label:"Cuero de vaca"},
    {prodId:"11162306",label:"Cuero de cerdo"},
    {prodId:"11162307",label:"Cuero sintético o de imitación"},
    {prodId:"11162308",label:"Cuero de búfalo"},
    {prodId:"11162309",label:"Cuero de reptil"},
    {prodId:"11162310",label:"Cuero de caballo"},
    {prodId:"11162311",label:"Cuero de becerro"},
    {prodId:"11162400",label:"Guata"},
    {prodId:"11162401",label:"Guata de algodón"},
    {prodId:"11162402",label:"Guata sintética"},
    {prodId:"11171500",label:"Aceros básicos"},
    {prodId:"11171501",label:"Acero e24-2 o a37-2"},
    {prodId:"11171600",label:"Aleaciones de acero inoxidable"},
    {prodId:"11171601",label:"Aleación de acero inoxidable 304"},
    {prodId:"11171602",label:"Aleación de acero inoxidable 304i"},
    {prodId:"11171603",label:"Aleación de acero inoxidable 316"},
    {prodId:"11171604",label:"Aleación de ferroníquel"},
    {prodId:"11172000",label:"Aleaciones de aluminio"},
    {prodId:"11172001",label:"Aleación de aluminio 7178"},
    {prodId:"11172002",label:"Aluminio refundido"},
    {prodId:"11172003",label:"Aleación de hierro y aluminio"},
    {prodId:"11172200",label:"Aleaciones con base de magnesio"},
    {prodId:"11172201",label:"Aleación de aluminio y manganeso"},
    {prodId:"11181500",label:"Óxidos metálicos no férreos"},
    {prodId:"11181501",label:"Óxido de molibdeno"},
    {prodId:"11181502",label:"Óxido de titanio"},
    {prodId:"11181503",label:"Óxido de indio"},
    {prodId:"11181504",label:"Óxido de estaño"},
    {prodId:"11181505",label:"Arcilla de sellado"},
    {prodId:"11181506",label:"Óxido de vanadio"},
    {prodId:"11181507",label:"Óxido de níquel"},
    {prodId:"11181508",label:"Óxido de manganeso"},
    {prodId:"11191500",label:"Cuerpos sólidos de metal"},
    {prodId:"11191501",label:"Sólidos de níquel"},
    {prodId:"11191502",label:"Sólidos de acero básico"},
    {prodId:"11191503",label:"Sólidos de aleación ferrosa"},
    {prodId:"11191504",label:"Sólidos de aleación no ferrosa"},
    {prodId:"11191505",label:"Sólidos de súper aleación"},
    {prodId:"11191600",label:"Chatarra"},
    {prodId:"11191601",label:"Chatarra de níquel"},
    {prodId:"11191602",label:"Chatarra de acero básico"},
    {prodId:"11191603",label:"Chatarra de aleación ferrosa"},
    {prodId:"11191604",label:"Chatarra de aleación no ferrosa"},
    {prodId:"11191605",label:"Chatarra de súper aleación"},
    {prodId:"11191606",label:"Destrozos automovilísticos para chatarra o desecho"},
    {prodId:"11191607",label:"Chatarra de cobre"},
    {prodId:"11191608",label:"Chatarra de plomo"},
    {prodId:"11191609",label:"Chatarra de zinc"},
    {prodId:"11191610",label:"Chatarra de aluminio"},
    {prodId:"11191611",label:"Chatarra de estaño"},
    {prodId:"11191800",label:"Subproductos del metal"},
    {prodId:"11191801",label:"Escoria de manganeso"},
    {prodId:"11191802",label:"Sulfuro de cobre"},
    {prodId:"11191803",label:"Sulfuro de níquel"},
    {prodId:"12131500",label:"Explosivos"},
    {prodId:"12131501",label:"Dinamita"},
    {prodId:"12131502",label:"Cartuchos explosivos"},
    {prodId:"12131503",label:"Explosivos propelentes"},
    {prodId:"12131504",label:"Cargas explosivas"},
    {prodId:"12131505",label:"Explosivos plásticos"},
    {prodId:"12131506",label:"Explosivos aluminizados"},
    {prodId:"12131507",label:"Explosivos de nitrato de amonio"},
    {prodId:"12131508",label:"Explosivos de polvo de nitroglicerina"},
    {prodId:"12131509",label:"Nitrato de amonio y fuel oil - anfo"},
    {prodId:"12131600",label:"Pirotecnia"},
    {prodId:"12131601",label:"Fuegos artificiales"},
    {prodId:"12131602",label:"Señales de niebla"},
    {prodId:"12131603",label:"Cohetes de lluvia"},
    {prodId:"12131604",label:"Antorchas"},
    {prodId:"12131605",label:"Materiales pirotécnicos para el teatro o la televisión"},
    {prodId:"12131700",label:"Encendedores"},
    {prodId:"12131701",label:"Estopines"},
    {prodId:"12131702",label:"Detonadores"},
    {prodId:"12131703",label:"Mechas explosivas"},
    {prodId:"12131704",label:"Iniciadores explosivos"},
    {prodId:"12131705",label:"Fulminantes explosivos"},
    {prodId:"12131706",label:"Fósforos"},
    {prodId:"12131707",label:"Encendedores"},
    {prodId:"12131708",label:"Caja detonadora"},
    {prodId:"12131709",label:"Apisonadores de explosivos"},
    {prodId:"12131800",label:"Propulsores"},
    {prodId:"12131801",label:"Propelente en polvo"},
    {prodId:"12131802",label:"Propelente sólido"},
    {prodId:"12131803",label:"Propelente de pistola"},
    {prodId:"12131804",label:"Propelente de alta energía"},
    {prodId:"12131805",label:"Propelente en gel"},
    {prodId:"12131806",label:"Propelente híbridos"},
    {prodId:"12141500",label:"Metales de tierra"},
    {prodId:"12141501",label:"Berilio be"},
    {prodId:"12141502",label:"Magnesio mg"},
    {prodId:"12141503",label:"Calcio ca"},
    {prodId:"12141504",label:"Estroncio sr"},
    {prodId:"12141505",label:"Bario ba"},
    {prodId:"12141506",label:"Radio ra"},
    {prodId:"12141600",label:"Metales de tierra rara"},
    {prodId:"12141601",label:"Cerio ce"},
    {prodId:"12141602",label:"Disprosio dy"},
    {prodId:"12141603",label:"Erbio er"},
    {prodId:"12141604",label:"Europio eu"},
    {prodId:"12141605",label:"Gadolinio gd"},
    {prodId:"12141606",label:"Holmio ho"},
    {prodId:"12141607",label:"Lantano la"},
    {prodId:"12141608",label:"Lutecio lu"},
    {prodId:"12141609",label:"Neodimio nd"},
    {prodId:"12141610",label:"Praseodimio pr"},
    {prodId:"12141611",label:"Promecio pm"},
    {prodId:"12141612",label:"Samario sm"},
    {prodId:"12141613",label:"Escandio sc"},
    {prodId:"12141614",label:"Terbio tb"},
    {prodId:"12141615",label:"Tulio tm"},
    {prodId:"12141616",label:"Iterbio yb"},
    {prodId:"12141617",label:"Itrio y"},
    {prodId:"12141700",label:"Metales de transición"},
    {prodId:"12141701",label:"Actinio ac"},
    {prodId:"12141702",label:"Aluminio al"},
    {prodId:"12141703",label:"Americio am"},
    {prodId:"12141704",label:"Antimonio sb"},
    {prodId:"12141705",label:"Berkelio bk"},
    {prodId:"12141706",label:"Bismuto bi"},
    {prodId:"12141707",label:"Cadmio ca"},
    {prodId:"12141708",label:"Californio cf"},
    {prodId:"12141709",label:"Cromo cr"},
    {prodId:"12141710",label:"Cobalto co"},
    {prodId:"12141711",label:"Cobre cu"},
    {prodId:"12141712",label:"Curio cm"},
    {prodId:"12141713",label:"Einstenio es"},
    {prodId:"12141714",label:"Fermio fm"},
    {prodId:"12141715",label:"Galio ga"},
    {prodId:"12141716",label:"Germanio ge"},
    {prodId:"12141717",label:"Oro au"},
    {prodId:"12141718",label:"Hafnio hf"},
    {prodId:"12141719",label:"Indio in"},
    {prodId:"12141720",label:"Iridio ir"},
    {prodId:"12141721",label:"Hierro fe"},
    {prodId:"12141722",label:"Lawrencio lr"},
    {prodId:"12141723",label:"Plomo pb"},
    {prodId:"12141724",label:"Manganeso mn"},
    {prodId:"12141725",label:"Mendelevio md"},
    {prodId:"12141726",label:"Mercurio hg"},
    {prodId:"12141727",label:"Molibdeno mo"},
    {prodId:"12141728",label:"Neptunio np"},
    {prodId:"12141729",label:"Níquel ni"},
    {prodId:"12141730",label:"Niobio nb"},
    {prodId:"12141731",label:"Nobelio no"},
    {prodId:"12141732",label:"Osmio os"},
    {prodId:"12141733",label:"Paladio pd"},
    {prodId:"12141734",label:"Platino pyt"},
    {prodId:"12141735",label:"Plutonio pu"},
    {prodId:"12141736",label:"Protactinio pa"},
    {prodId:"12141737",label:"Renio re"},
    {prodId:"12141738",label:"Rodio rh"},
    {prodId:"12141739",label:"Rutenio ru"},
    {prodId:"12141740",label:"Plata ag"},
    {prodId:"12141741",label:"Tántalo ta"},
    {prodId:"12141742",label:"Tecnecio te"},
    {prodId:"12141743",label:"Talio tl"},
    {prodId:"12141744",label:"Torio th"},
    {prodId:"12141745",label:"Estaño sn"},
    {prodId:"12141746",label:"Titanio ti"},
    {prodId:"12141747",label:"Tungsteno w"},
    {prodId:"12141748",label:"Uranio u"},
    {prodId:"12141749",label:"Vanadio v"},
    {prodId:"12141750",label:"Zinc zn"},
    {prodId:"12141751",label:"Zirconio zr"},
    {prodId:"12141752",label:"Bohrio bh"},
    {prodId:"12141753",label:"Dubnio db"},
    {prodId:"12141754",label:"Hassio hs"},
    {prodId:"12141755",label:"Rutherfordio rf"},
    {prodId:"12141756",label:"Seaborgio sg"},
    {prodId:"12141757",label:"Ununnilium uum"},
    {prodId:"12141758",label:"Unununium uuu"},
    {prodId:"12141759",label:"Ununbium uub"},
    {prodId:"12141760",label:"Polonio po"},
    {prodId:"12141800",label:"Metales álcalis"},
    {prodId:"12141801",label:"Cesio cs"},
    {prodId:"12141802",label:"Francio fm"},
    {prodId:"12141803",label:"Litio li"},
    {prodId:"12141804",label:"Potasio k"},
    {prodId:"12141805",label:"Rubidio rb"},
    {prodId:"12141806",label:"Sodio na"},
    {prodId:"12141900",label:"No-metales y gases elementales y puros"},
    {prodId:"12141901",label:"Cloro cl"},
    {prodId:"12141902",label:"Hidrógeno h"},
    {prodId:"12141903",label:"Nitrógeno n"},
    {prodId:"12141904",label:"Oxígeno o"},
    {prodId:"12141905",label:"Flúor f"},
    {prodId:"12141906",label:"Arsénico as"},
    {prodId:"12141907",label:"Boro b"},
    {prodId:"12141908",label:"Carbono c"},
    {prodId:"12141909",label:"Fósforo p"},
    {prodId:"12141910",label:"Selenio se"},
    {prodId:"12141911",label:"Silicona si"},
    {prodId:"12141912",label:"Azufre s"},
    {prodId:"12141913",label:"Telurio te"},
    {prodId:"12141914",label:"Astato at"},
    {prodId:"12141915",label:"Bromo br"},
    {prodId:"12141916",label:"Yodo i"},
    {prodId:"12142000",label:"Gases nobles"},
    {prodId:"12142001",label:"Gas xenón xe"},
    {prodId:"12142002",label:"Gas radón rn"},
    {prodId:"12142003",label:"Gas criptón kr"},
    {prodId:"12142004",label:"Gas argón ar"},
    {prodId:"12142005",label:"Gas helio he"},
    {prodId:"12142006",label:"Gas neón ne"},
    {prodId:"12142100",label:"Gases naturales y gases mixtos"},
    {prodId:"12142101",label:"Gases compuestos de hidrógeno"},
    {prodId:"12142102",label:"Gases compuestos clorados"},
    {prodId:"12142103",label:"Amoniaco"},
    {prodId:"12142104",label:"Gas dióxido de carbono"},
    {prodId:"12142105",label:"Aire industrial"},
    {prodId:"12142106",label:"Mezclas de gas inerte"},
    {prodId:"12142107",label:"Sulfato de hidrógeno"},
    {prodId:"12142108",label:"Monóxido de carbono"},
    {prodId:"12142200",label:"Isótopos"},
    {prodId:"12142201",label:"Disolvente deuterado"},
    {prodId:"12142202",label:"Agua pesada"},
    {prodId:"12142203",label:"Fuentes alfa"},
    {prodId:"12142204",label:"Fuentes beta"},
    {prodId:"12142205",label:"Fuentes de cobalto"},
    {prodId:"12142206",label:"Fuentes gamma"},
    {prodId:"12142207",label:"Fuentes radio - isótopo"},
    {prodId:"12142208",label:"Fuentes de calibración"},
    {prodId:"12161500",label:"Indicadores y reactivos"},
    {prodId:"12161501",label:"Etiquetas de afinidad"},
    {prodId:"12161502",label:"Agentes de entrecruzamiento"},
    {prodId:"12161503",label:"Kits de reactivos"},
    {prodId:"12161504",label:"Reactivos sulfhidrilo"},
    {prodId:"12161505",label:"Agentes de intercalación"},
    {prodId:"12161506",label:"Agentes de desvío"},
    {prodId:"12161507",label:"Reactivo cupferrón"},
    {prodId:"12161600",label:"Catalizadores"},
    {prodId:"12161601",label:"Catalizadores ácidos"},
    {prodId:"12161602",label:"Catalizadores de combustión"},
    {prodId:"12161603",label:"Catalizadores a la medida"},
    {prodId:"12161604",label:"Catalizadores de ruptura"},
    {prodId:"12161605",label:"Catalizadores fe tratamiento"},
    {prodId:"12161606",label:"Catalizadores reformadores"},
    {prodId:"12161700",label:"Amortiguadores"},
    {prodId:"12161701",label:"Mezclas de anfólito"},
    {prodId:"12161702",label:"Soluciones reguladoras de bicarbonato"},
    {prodId:"12161703",label:"Otras soluciones reguladoras"},
    {prodId:"12161704",label:"Soluciones reguladoras ácidas"},
    {prodId:"12161705",label:"Soluciones reguladoras básicas"},
    {prodId:"12161706",label:"Soluciones reguladoras neutrales"},
    {prodId:"12161800",label:"Coloides"},
    {prodId:"12161801",label:"Geles"},
    {prodId:"12161802",label:"Suspensiones"},
    {prodId:"12161803",label:"Aerosoles"},
    {prodId:"12161804",label:"Emulsiones"},
    {prodId:"12161805",label:"Agentes gelificantes naturales"},
    {prodId:"12161806",label:"Agentes gelificantes sintéticos"},
    {prodId:"12161807",label:"Estabilizadores de gel"},
    {prodId:"12161808",label:"Agentes de suspensión"},
    {prodId:"12161809",label:"Gel de silicona"},
    {prodId:"12161900",label:"Surfactantes"},
    {prodId:"12161901",label:"Agentes anti - espuma"},
    {prodId:"12161902",label:"Surfactantes detergentes"},
    {prodId:"12161903",label:"Agentes de espuma"},
    {prodId:"12161904",label:"Agentes de dispersión"},
    {prodId:"12161905",label:"Enjuagues"},
    {prodId:"12161906",label:"Agentes de humectación"},
    {prodId:"12161907",label:"Aditivos de inundación de agua"},
    {prodId:"12161908",label:"Alkil sulfatos"},
    {prodId:"12161909",label:"Betaínas"},
    {prodId:"12161910",label:"Sulfatos de éter"},
    {prodId:"12161911",label:"Cuaternarios"},
    {prodId:"12161912",label:"Sultaína"},
    {prodId:"12161913",label:"Coadyuvante de rociado"},
    {prodId:"12162000",label:"Plastificantes"},
    {prodId:"12162002",label:"Poliméricos"},
    {prodId:"12162003",label:"Aceites agrícolas"},
    {prodId:"12162004",label:"Sulfonamidas"},
    {prodId:"12162005",label:"Glutaratos"},
    {prodId:"12162006",label:"Plastificante ester aromático"},
    {prodId:"12162100",label:"Retardadores de llama"},
    {prodId:"12162101",label:"Retardantes brominados"},
    {prodId:"12162200",label:"Anti-oxidantes"},
    {prodId:"12162201",label:"Ácido ascórbico"},
    {prodId:"12162202",label:"Beta caroteno"},
    {prodId:"12162203",label:"Butilhidroxianisol"},
    {prodId:"12162204",label:"Butilado hidroxitolueno"},
    {prodId:"12162205",label:"Citrato de calcio"},
    {prodId:"12162206",label:"Cantaxantina"},
    {prodId:"12162207",label:"Melatonina"},
    {prodId:"12162208",label:"Ácido nordihidroguaiarético"},
    {prodId:"12162209",label:"Galato de propilo"},
    {prodId:"12162210",label:"Silibinina"},
    {prodId:"12162211",label:"Dióxido de azufre"},
    {prodId:"12162212",label:"Ubicquinona o coenzima q10"},
    {prodId:"12162300",label:"Agentes de curación"},
    {prodId:"12162301",label:"Agentes de curado transmitidos por el agua"},
    {prodId:"12162302",label:"Aceleradores de cemento"},
    {prodId:"12162303",label:"Retardantes de cemento"},
    {prodId:"12162304",label:"Acelerador adhesivo"},
    {prodId:"12162305",label:"Aditivo de concreto"},
    {prodId:"12162700",label:"Aditivos de pérdida de fluido"},
    {prodId:"12162701",label:"Aditivos de pérdida de fluido de polímeros modificados"},
    {prodId:"12162702",label:"Aditivos de pérdida de fluido de polímeros naturales"},
    {prodId:"12162800",label:"Reductores de fricción"},
    {prodId:"12162801",label:"Reductores de fricción aniónico"},
    {prodId:"12162802",label:"Reductores de fricción catódica"},
    {prodId:"12162900",label:"Agentes de control de parafina asfaltenos"},
    {prodId:"12162901",label:"Agentes de control de asfaltenos de parafina tipo solvente"},
    {prodId:"12162902",label:"Agentes de control de asfaltenos de parafina modificados cristal"},
    {prodId:"12162903",label:"Agentes de control de asfaltenos de parafina tipo dispersante"},
    {prodId:"12163100",label:"Anti lodo (sludger)"},
    {prodId:"12163101",label:"Aditivos anti - lodos"},
    {prodId:"12163102",label:"Desfloculante"},
    {prodId:"12163300",label:"Agentes de expansión"},
    {prodId:"12163301",label:"Agentes de expansión de cemento"},
    {prodId:"12163400",label:"Extensores"},
    {prodId:"12163401",label:"Extensores de cemento"},
    {prodId:"12163500",label:"Sellador de pozo petrolífero"},
    {prodId:"12163501",label:"Sellantes de cemento"},
    {prodId:"12163600",label:"Inhibidores de corrosión"},
    {prodId:"12163601",label:"Inhibidores de corrosión en la producción de petróleo"},
    {prodId:"12163602",label:"Inhibidores de corrosión en la producción de gas"},
    {prodId:"12163800",label:"Limpiadores químicos"},
    {prodId:"12163801",label:"Eliminadores de sulfuro de hidrógeno"},
    {prodId:"12163802",label:"Eliminadores de oxígeno"},
    {prodId:"12163900",label:"Reguladores de sarro"},
    {prodId:"12163901",label:"Inhibidores de incrustaciones"},
    {prodId:"12163902",label:"Removedores o convertidores de incrustaciones"},
    {prodId:"12164000",label:"Bactericidas"},
    {prodId:"12164001",label:"Microbicidas registrados"},
    {prodId:"12164100",label:"In situ"},
    {prodId:"12164101",label:"Aditivos in situ"},
    {prodId:"12164102",label:"Aditivos ácidos"},
    {prodId:"12164200",label:"Retardadores"},
    {prodId:"12164201",label:"Inhibidores anti - corrosión"},
    {prodId:"12164300",label:"Controladores de hierro"},
    {prodId:"12164301",label:"Aditivos de control de hierro"},
    {prodId:"12164302",label:"Aditivos secuestradores de hierro"},
    {prodId:"12164303",label:"Inhibidor de la corrosión de hierro"},
    {prodId:"12164400",label:"No emulsificadoras"},
    {prodId:"12164401",label:"Aditivos no emulsificantes"},
    {prodId:"12164500",label:"Aditivos de alimentos y fármacos"},
    {prodId:"12164501",label:"Preservativos de alimentos"},
    {prodId:"12164502",label:"sabores o extractos"},
    {prodId:"12164503",label:"Aditivos de fragancia"},
    {prodId:"12164504",label:"Endulzantes"},
    {prodId:"12164505",label:"Excipiente"},
    {prodId:"12164508",label:"Desintegrante"},
    {prodId:"12164509",label:"Agente antiapelmazante"},
    {prodId:"12164600",label:"Modificadores de papel, plástico y tela"},
    {prodId:"12164601",label:"Agente de dimensionamiento"},
    {prodId:"12164602",label:"Agente de espesamiento"},
    {prodId:"12164603",label:"Abrillantador óptico"},
    {prodId:"12164604",label:"Agente antiestática"},
    {prodId:"12164605",label:"Micro - floculante"},
    {prodId:"12164606",label:"Estabilizador de luz y ultravioleta"},
    {prodId:"12164800",label:"Agentes reductores de agua"},
    {prodId:"12164801",label:"Agente reductor de agua en cemento"},
    {prodId:"12164802",label:"Agente detenedor o hinchador de agua"},
    {prodId:"12164803",label:"Repelente estructural de agua"},
    {prodId:"12164900",label:"Agentes de impermeabilización"},
    {prodId:"12164901",label:"Recubrimiento impermeable de uretano"},
    {prodId:"12164902",label:"Recubrimiento impermeable de asfalto"},
    {prodId:"12164903",label:"Recubrimiento impermeable epoxi"},
    {prodId:"12164904",label:"Recubrimiento impermeable inorgánico"},
    {prodId:"12164905",label:"Agente de impermeabilización"},
    {prodId:"12165000",label:"Agentes anti encogimiento"},
    {prodId:"12165001",label:"Concreto anti - contracción del cemento"},
    {prodId:"12165100",label:"Químicos para mejorar la recuperación de petróleo"},
    {prodId:"12165101",label:"Gel de polímero"},
    {prodId:"12165102",label:"Surfactante dispersante"},
    {prodId:"12165103",label:"Surfactante polímero álcali asp"},
    {prodId:"12165104",label:"Polímero surfactante sp"},
    {prodId:"12171500",label:"Tintes"},
    {prodId:"12171501",label:"Colorantes fluorescentes"},
    {prodId:"12171502",label:"Colorantes ftaleínicos"},
    {prodId:"12171503",label:"Colorantes rosanilina"},
    {prodId:"12171504",label:"Colorantes fdc seguros para alimentos o cosméticos"},
    {prodId:"12171505",label:"Lacado"},
    {prodId:"12171506",label:"Colorantes naturales"},
    {prodId:"12171507",label:"Colorantes de azufre"},
    {prodId:"12171508",label:"Colorantes de cuba o tina"},
    {prodId:"12171509",label:"Colorantes reactivos"},
    {prodId:"12171510",label:"Colorantes solventes"},
    {prodId:"12171511",label:"Colorantes ácidos"},
    {prodId:"12171600",label:"Pigmentos"},
    {prodId:"12171602",label:"Óxidos metálicos inorgánicos"},
    {prodId:"12171603",label:"Negro carbono"},
    {prodId:"12171604",label:"Dióxido de titanio"},
    {prodId:"12171605",label:"Pigmentos orgánicos"},
    {prodId:"12171606",label:"Zeaxantina"},
    {prodId:"12171607",label:"Verde parís"},
    {prodId:"12171608",label:"Caput mortuum (púrpura de cardenal)"},
    {prodId:"12171609",label:"Verde cadmio"},
    {prodId:"12171610",label:"Anaranjado cadmio"},
    {prodId:"12171611",label:"Amarillo cadmio"},
    {prodId:"12171612",label:"Rojo cadmio"},
    {prodId:"12171613",label:"Rojo ocre"},
    {prodId:"12171614",label:"Amarillo ocre"},
    {prodId:"12171615",label:"Sanguina"},
    {prodId:"12171616",label:"Azul de prusia"},
    {prodId:"12171617",label:"Rojo veneciano"},
    {prodId:"12171618",label:"Verde cromo"},
    {prodId:"12171619",label:"Auerolina (amarillo de cobalto)"},
    {prodId:"12171620",label:"Amarillo cromo"},
    {prodId:"12171621",label:"Pigmento fluorescente"},
    {prodId:"12171700",label:"Compuestos de colores y dispersiones"},
    {prodId:"12171701",label:"Concentrado de color de polímero"},
    {prodId:"12171702",label:"Dispersiones de pigmento"},
    {prodId:"12171703",label:"Tintas"},
    {prodId:"12181500",label:"Ceras"},
    {prodId:"12181501",label:"Ceras sintéticas"},
    {prodId:"12181502",label:"Ceras naturales"},
    {prodId:"12181503",label:"Parafinas"},
    {prodId:"12181504",label:"Petrolatos"},
    {prodId:"12181600",label:"Aceites"},
    {prodId:"12181601",label:"Aceites sintéticos"},
    {prodId:"12181602",label:"Aceites naturales"},
    {prodId:"12191500",label:"Solventes hidrocarbonatos"},
    {prodId:"12191501",label:"Disolventes aromáticos"},
    {prodId:"12191502",label:"Disolventes alifáticos"},
    {prodId:"12191503",label:"Fenoles o sus sustitutos y derivados"},
    {prodId:"12191504",label:"Alcanos cíclicos"},
    {prodId:"12191600",label:"Solventes oxigenados"},
    {prodId:"12191601",label:"Solventes de alcohol"},
    {prodId:"12191602",label:"Solventes activos"},
    {prodId:"12352000",label:"Compuestos aromáticos y alifáticos"},
    {prodId:"12352001",label:"Alcanos"},
    {prodId:"12352002",label:"Alquenos"},
    {prodId:"12352003",label:"Alquinos"},
    {prodId:"12352005",label:"Compuestos aromáticos o heterocíclicos"},
    {prodId:"12352100",label:"Derivados orgánicos y compuestos sustituidos"},
    {prodId:"12352101",label:"Compuestos halogenados orgánicos"},
    {prodId:"12352102",label:"Compuestos orgánicos nitro o nitroso"},
    {prodId:"12352103",label:"Compuestos órgano - metálicos"},
    {prodId:"12352104",label:"Alcoholes o sus sustitutos"},
    {prodId:"12352105",label:"Alcoholes tio (mercaptanos)"},
    {prodId:"12352106",label:"Ácidos orgánicos o sus sustitutos"},
    {prodId:"12352107",label:"Sales orgánicas o sus sustitutos"},
    {prodId:"12352108",label:"Esteres o sus sustitutos"},
    {prodId:"12352111",label:"Amidas o imidas"},
    {prodId:"12352112",label:"Esteres o sus sustitutos"},
    {prodId:"12352113",label:"Tioéteres"},
    {prodId:"12352114",label:"Aldehídos o sus sustitutos"},
    {prodId:"12352115",label:"Cetonas o quinonas o sus sustitutos"},
    {prodId:"12352116",label:"Amines o imines o sus sustitutos"},
    {prodId:"12352117",label:"Cianuros o isocianuros"},
    {prodId:"12352118",label:"Cianatos o isocianatos o tiocianatos o isotiocianatos"},
    {prodId:"12352119",label:"Óxidos orgánicos"},
    {prodId:"12352120",label:"Peróxidos orgánicos"},
    {prodId:"12352121",label:"Hidróxidos orgánicos"},
    {prodId:"12352123",label:"Ureidos o purinas o sus derivados"},
    {prodId:"12352124",label:"Azo compuestos o sus sustitutos"},
    {prodId:"12352126",label:"Oximas"},
    {prodId:"12352127",label:"Hidracinas o hidracidas o sus sustitutos"},
    {prodId:"12352128",label:"Fosfinas"},
    {prodId:"12352129",label:"Amidinas o imidinas"},
    {prodId:"12352130",label:"Intermedios de acrilato o metacrilato"},
    {prodId:"12352131",label:"Pirrolidona"},
    {prodId:"12352132",label:"Triclosán"},
    {prodId:"12352133",label:"Condicionador de congelación"},
    {prodId:"12352135",label:"Glicol propileno"},
    {prodId:"12352136",label:"Glicol trietileno"},
    {prodId:"12352137",label:"Glicol etileno"},
    {prodId:"12352200",label:"Productos bioquímicos"},
    {prodId:"12352201",label:"Carbohidratos o sus derivados"},
    {prodId:"12352202",label:"Proteínas"},
    {prodId:"12352203",label:"Anticuerpos"},
    {prodId:"12352204",label:"Enzimas"},
    {prodId:"12352205",label:"Nutrientes"},
    {prodId:"12352206",label:"Tejidos"},
    {prodId:"12352207",label:"Cultivos y fluidos"},
    {prodId:"12352208",label:"Ácido nucleico"},
    {prodId:"12352209",label:"Aminoácidos o sus derivados"},
    {prodId:"12352210",label:"Alcaloides"},
    {prodId:"12352211",label:"Grasas o lípidos"},
    {prodId:"12352212",label:"Terpenoides"},
    {prodId:"12352300",label:"Compuestos inorgánicos"},
    {prodId:"12352301",label:"Ácidos inorgánicos"},
    {prodId:"12352302",label:"Sales metálicas inorgánicas"},
    {prodId:"12352303",label:"Óxidos inorgánicos"},
    {prodId:"12352304",label:"Peróxidos inorgánicos"},
    {prodId:"12352305",label:"Hidróxidos inorgánicos"},
    {prodId:"12352306",label:"Hídridos inorgánicos"},
    {prodId:"12352307",label:"Halidos ácidos o sus sustitutos"},
    {prodId:"12352308",label:"Silicatos"},
    {prodId:"12352309",label:"Sílice"},
    {prodId:"12352310",label:"Siliconas"},
    {prodId:"12352311",label:"Alúmina y otros compuestos de aluminio"},
    {prodId:"12352312",label:"Permanganato de potasio"},
    {prodId:"12352313",label:"Ácido trifluoroacético tfa"},
    {prodId:"12352314",label:"Sulfato de amonio"},
    {prodId:"12352315",label:"Caucho de silicona líquida"},
    {prodId:"12352316",label:"Hidróxido de sodio"},
    {prodId:"12352317",label:"Fluoruro de aluminio"},
    {prodId:"12352318",label:"Fluoruro de calcio"},
    {prodId:"12352319",label:"Hidróxido de calcio"},
    {prodId:"12352320",label:"Hidróxido de potasio"},
    {prodId:"12352321",label:"Glicol acetileno"},
    {prodId:"12352400",label:"Mezclas"},
    {prodId:"12352401",label:"Mezclas químicas orgánicas"},
    {prodId:"12352402",label:"Mezclas químicas inorgánicas"},
    {prodId:"12352500",label:"Fijadores"},
    {prodId:"12352501",label:"Formaldehidos"},
    {prodId:"12352502",label:"Glutarales"},
    {prodId:"12352503",label:"Taninos"},
    {prodId:"12352600",label:"Compuestos fluorados"},
    {prodId:"13101500",label:"Caucho natural"},
    {prodId:"13101501",label:"Caucho látex"},
    {prodId:"13101502",label:"Caucho crepe"},
    {prodId:"13101503",label:"Caucho hoja ahumada"},
    {prodId:"13101504",label:"Caucho espuma natural"},
    {prodId:"13101505",label:"Caucho bloque o borona"},
    {prodId:"13101600",label:"Caucho procesado y sintético"},
    {prodId:"13101601",label:"Caucho vulcanizado"},
    {prodId:"13101602",label:"Caucho clorado"},
    {prodId:"13101603",label:"Caucho clorhidrato"},
    {prodId:"13101604",label:"Caucho ciclizado"},
    {prodId:"13101605",label:"Caucho isomerizado"},
    {prodId:"13101606",label:"Caucho termoplástico"},
    {prodId:"13101607",label:"Compuesto de caucho"},
    {prodId:"13101608",label:"Caucho reclamado"},
    {prodId:"13101700",label:"Elastómeros"},
    {prodId:"13101701",label:"Butadieno acilonitrilo nbr"},
    {prodId:"13101702",label:"Nitrilo altamente saturado nhbr"},
    {prodId:"13101703",label:"Fluorocarbono fcm"},
    {prodId:"13101704",label:"Propileno etileno epe"},
    {prodId:"13101705",label:"Butadieno estireno"},
    {prodId:"13101706",label:"Clorpreno cr"},
    {prodId:"13101707",label:"Isopreno isobutileno iir / xiir"},
    {prodId:"13101708",label:"Silicona vmq y pmq y pvmq"},
    {prodId:"13101709",label:"Fluorosilicona fvmq"},
    {prodId:"13101710",label:"Poliacrilato acm"},
    {prodId:"13101711",label:"Etileno acrílico aem"},
    {prodId:"13101712",label:"Polietileno clorosulfonatado csm"},
    {prodId:"13101713",label:"Cloropolietileno cm"},
    {prodId:"13101714",label:"Epicloridrina"},
    {prodId:"13101715",label:"Polisopreno natural nr"},
    {prodId:"13101716",label:"Polisopreno sintético ir"},
    {prodId:"13101717",label:"Poliéster uretano au"},
    {prodId:"13101718",label:"Poliéster uretano eu"},
    {prodId:"13101719",label:"Polibutadieno br"},
    {prodId:"13101720",label:"Poli éter bloque amida peba"},
    {prodId:"13101721",label:"Estireno bloque copolímero tes"},
    {prodId:"13101722",label:"Copoliester"},
    {prodId:"13101723",label:"Termoplástico"},
    {prodId:"13101724",label:"Poliolefínico"},
    {prodId:"13101725",label:"Etileno propileno dieno epdm"},
    {prodId:"13101900",label:"Plásticos termoformados"},
    {prodId:"13101902",label:"Fenólico pf"},
    {prodId:"13101903",label:"Poliéster no saturado up"},
    {prodId:"13101904",label:"Urea uf"},
    {prodId:"13101905",label:"Melamina mf"},
    {prodId:"13101906",label:"Poliuretano termoestable pur"},
    {prodId:"13102000",label:"Plásticos termoplásticos"},
    {prodId:"13102001",label:"Acrilonitrilo butadieno estireno abs"},
    {prodId:"13102002",label:"Aleaciones de acrilonitrilo butadieno estireno abs"},
    {prodId:"13102003",label:"Polímero acetal"},
    {prodId:"13102005",label:"Acrilonitrilo estireno acrílico asa"},
    {prodId:"13102006",label:"Aleaciones de acrilonitrilo estireno acrílico asa"},
    {prodId:"13102008",label:"Fluoropolímeros ptfe"},
    {prodId:"13102010",label:"Polímero cristal líquido lcp"},
    {prodId:"13102011",label:"Nylons poliamida pa"},
    {prodId:"13102012",label:"(no hay sugerencias) pbt"},
    {prodId:"13102013",label:"Policarbonato pc"},
    {prodId:"13102014",label:"Polietereterquetona peek"},
    {prodId:"13102016",label:"Polietersulfona pes"},
    {prodId:"13102017",label:"Polietileno de baja densidad hdpe"},
    {prodId:"13102018",label:"Polietileno de alta densidad ldpe"},
    {prodId:"13102019",label:"Polietileno de media densidad mdpe"},
    {prodId:"13102020",label:"Polietileno tereftalato pet"},
    {prodId:"13102022",label:"Polipropileno pp"},
    {prodId:"13102024",label:"Óxido de polifenileno ppo"},
    {prodId:"13102025",label:"Sulfuro de polifenileno pps"},
    {prodId:"13102026",label:"Poliestireno (icopor) ps"},
    {prodId:"13102027",label:"Poliestireno de alto impacto hips"},
    {prodId:"13102028",label:"Polisulfona psu"},
    {prodId:"13102029",label:"Poliuretano termoplástico rígido rptu"},
    {prodId:"13102030",label:"Cloruro de polivinilo pvc"},
    {prodId:"13102031",label:"Éter de polifenilene ppe"},
    {prodId:"13102032",label:"Poliolefina termoplástica tpo"},
    {prodId:"13111000",label:"Resinas"},
    {prodId:"13111001",label:"Epoxi"},
    {prodId:"13111002",label:"Resina fenólica"},
    {prodId:"13111003",label:"Resina polester no saturada"},
    {prodId:"13111004",label:"Resina estireno butadieno acrilonitrilo"},
    {prodId:"13111005",label:"Resina acrílica estireno acrilonitrilo"},
    {prodId:"13111006",label:"Resina aleación acrílica estireno acrilonitrilo"},
    {prodId:"13111007",label:"Resina fliuoropolímero"},
    {prodId:"13111008",label:"Resina acetato vinilo etileno"},
    {prodId:"13111009",label:"Resina polímero cristal líquido"},
    {prodId:"13111010",label:"Nylon"},
    {prodId:"13111011",label:"Tetralftalato polibutileno"},
    {prodId:"13111012",label:"Resina de policarbonato"},
    {prodId:"13111013",label:"Resina polietereterquetona"},
    {prodId:"13111014",label:"Resina polieterimida"},
    {prodId:"13111015",label:"Resina polietersulfona"},
    {prodId:"13111016",label:"Polietileno"},
    {prodId:"13111017",label:"Resina terftalato polietileno"},
    {prodId:"13111018",label:"Resina polimida"},
    {prodId:"13111019",label:"Resina polipropileno"},
    {prodId:"13111020",label:"Resina poliftalamida"},
    {prodId:"13111021",label:"Óxido de polietileno"},
    {prodId:"13111022",label:"Resina sulfuro de polifenilene"},
    {prodId:"13111023",label:"Resina de poliestireno"},
    {prodId:"13111024",label:"Resina de polisulfona"},
    {prodId:"13111025",label:"Resina de cloruro de polivinilo"},
    {prodId:"13111026",label:"Resina de acrilonitrilo de estireno"},
    {prodId:"13111027",label:"Formaldehído de urea"},
    {prodId:"13111028",label:"Alquido"},
    {prodId:"13111029",label:"Formaldehído de melamina"},
    {prodId:"13111030",label:"Poliacetal"},
    {prodId:"13111031",label:"Poliamida"},
    {prodId:"13111032",label:"Grupo alilo"},
    {prodId:"13111033",label:"Ácido acrílico de etileno"},
    {prodId:"13111034",label:"Compuesto cloruro de polivinilo"},
    {prodId:"13111035",label:"Solución de vinilo"},
    {prodId:"13111036",label:"Fenoxi"},
    {prodId:"13111037",label:"Resina compuesta"},
    {prodId:"13111038",label:"Pirolidina polivinilo"},
    {prodId:"13111039",label:"Tereftalato de polietileno o glicol modificado"},
    {prodId:"13111040",label:"Fijador de hidrocarburo"},
    {prodId:"13111041",label:"Mezclas de policarbonato"},
    {prodId:"13111042",label:"Alcohol polivinilo"},
    {prodId:"13111043",label:"Butiral polivinilo"},
    {prodId:"13111044",label:"Compuesto de moldeo de poliéster"},
    {prodId:"13111045",label:"Acetato de polivinilo"},
    {prodId:"13111046",label:"Éter de polivinilo"},
    {prodId:"13111047",label:"Polivinilo formal"},
    {prodId:"13111048",label:"Acrílico de estireno"},
    {prodId:"13111049",label:"Polímeros de propileno de etileno"},
    {prodId:"13111050",label:"Óxido de polipropileno"},
    {prodId:"13111051",label:"Éter de polipropileno"},
    {prodId:"13111052",label:"Sulfona de polipropileno"},
    {prodId:"13111053",label:"Polimetilacrilato"},
    {prodId:"13111054",label:"Anhídrido maléico de estireno"},
    {prodId:"13111055",label:"Poliestireno sindiotáctico"},
    {prodId:"13111056",label:"Cloruro de polivinilo clorado"},
    {prodId:"13111057",label:"Poliéster termoplástico"},
    {prodId:"13111058",label:"Resinas de indeno"},
    {prodId:"13111059",label:"Resinas plásticas"},
    {prodId:"13111060",label:"Resinas de petróleo"},
    {prodId:"13111061",label:"Resinas de poliuretano"},
    {prodId:"13111062",label:"Resinas de polieter"},
    {prodId:"13111063",label:"Resinas recicladas"},
    {prodId:"13111064",label:"Resinas acrílicas"},
    {prodId:"13111065",label:"Resinas celulosas"},
    {prodId:"13111066",label:"Resinas de polipropileno"},
    {prodId:"13111067",label:"Alcohol etileno vinilo"},
    {prodId:"13111068",label:"Polietileno lineal de baja densidad"},
    {prodId:"13111069",label:"Resina de poliacrilonitrilo"},
    {prodId:"13111070",label:"Resina de poliamida - imida"},
    {prodId:"13111071",label:"Resina de poliarileterquetona"},
    {prodId:"13111072",label:"Resina de polibenzimidasola"},
    {prodId:"13111073",label:"Resina de polimetilpenteno"},
    {prodId:"13111074",label:"Fluoruro de polivinildeno"},
    {prodId:"13111075",label:"Aleación estireno uradieno acrilonitrilo policarbonato pc abs"},
    {prodId:"13111076",label:"Polietileno entrecruzado pex"},
    {prodId:"13111077",label:"Poliamida 6 - 12"},
    {prodId:"13111078",label:"Poliamida 6 - 6"},
    {prodId:"13111079",label:"Poliamida 4 - 6"},
    {prodId:"13111080",label:"Nylon poliamida de alta temperatura htn"},
    {prodId:"13111081",label:"Poliamida 12"},
    {prodId:"13111082",label:"Poliamida 6"},
    {prodId:"13111100",label:"Colofonia"},
    {prodId:"13111101",label:"Rosina de madera"},
    {prodId:"13111102",label:"Rosina de goma"},
    {prodId:"13111103",label:"Rosina aceite de pino"},
    {prodId:"13111200",label:"Películas"},
    {prodId:"13111201",label:"Películas de polietileno"},
    {prodId:"13111202",label:"Películas de poliuretano"},
    {prodId:"13111203",label:"Películas de acetato"},
    {prodId:"13111204",label:"Películas de acrílico"},
    {prodId:"13111205",label:"Películas coextruídas"},
    {prodId:"13111206",label:"Películas de fluoropolímero"},
    {prodId:"13111207",label:"Películas metalizadas"},
    {prodId:"13111208",label:"Películas de nylon"},
    {prodId:"13111209",label:"Películas de policarbonato"},
    {prodId:"13111210",label:"Películas de poliéster"},
    {prodId:"13111211",label:"Películas de polipropileno"},
    {prodId:"13111212",label:"Polipropileno orientado axialmente"},
    {prodId:"13111213",label:"Películas de poliamida"},
    {prodId:"13111214",label:"Películas de poliestireno"},
    {prodId:"13111215",label:"Películas de cloruro de polivinilo flexible"},
    {prodId:"13111216",label:"Películas de cloruro de polivinilo rígido"},
    {prodId:"13111217",label:"Películas de alcohol vinilo etileno"},
    {prodId:"13111218",label:"Películas de cloruro de polivinilo"},
    {prodId:"13111219",label:"Películas de alcohol polivinilo"},
    {prodId:"13111220",label:"Películas de recubiertas de silicona"},
    {prodId:"13111300",label:"Espumas"},
    {prodId:"13111301",label:"Espumas de poliolefina"},
    {prodId:"13111302",label:"Espumas de poli éter"},
    {prodId:"13111303",label:"Espumas de silicona"},
    {prodId:"13111304",label:"Espumas de terpolímero propileno etileno"},
    {prodId:"13111305",label:"Espumas de neopreno"},
    {prodId:"13111306",label:"Espumas de cloruro de polivinilo"},
    {prodId:"13111307",label:"Espumas de caucho"},
    {prodId:"13111308",label:"Espumas de poliestireno"},
    {prodId:"14101500",label:"Materias primas"},
    {prodId:"14101501",label:"Pulpa de papel"},
    {prodId:"14111500",label:"Papel de imprenta y papel de escribir"},
    {prodId:"14111501",label:"Papel cebolla"},
    {prodId:"14111502",label:"Papel vitela"},
    {prodId:"14111503",label:"Papel pergamino"},
    {prodId:"14111504",label:"Papel en formas continuas"},
    {prodId:"14111505",label:"Papel mimeógrafo"},
    {prodId:"14111506",label:"Papel para impresión de computadores"},
    {prodId:"14111507",label:"Papel para impresora o fotocopiadora"},
    {prodId:"14111508",label:"Papel para fax"},
    {prodId:"14111509",label:"Papel membreteado"},
    {prodId:"14111510",label:"Papel para plotter"},
    {prodId:"14111511",label:"Papel de escritura"},
    {prodId:"14111512",label:"Papel para gráficos"},
    {prodId:"14111513",label:"Papel de libro"},
    {prodId:"14111514",label:"Blocs o cuadernos de papel"},
    {prodId:"14111515",label:"Papel para sumadora o máquina registradora"},
    {prodId:"14111516",label:"Repuestos de papel para cuaderno"},
    {prodId:"14111518",label:"Tarjetas de índice"},
    {prodId:"14111519",label:"Papeles cartulina"},
    {prodId:"14111520",label:"Papel secante"},
    {prodId:"14111523",label:"Papel calcante"},
    {prodId:"14111524",label:"Papel folio"},
    {prodId:"14111525",label:"Papel multipropósito"},
    {prodId:"14111526",label:"Papel libretas o libros de mensajes telefónicos"},
    {prodId:"14111527",label:"Papel autocopiante"},
    {prodId:"14111528",label:"Papel magnético"},
    {prodId:"14111529",label:"Rollos de télex"},
    {prodId:"14111530",label:"Papel de notas autoadhesivas"},
    {prodId:"14111531",label:"Papel libros o cuadernos para bitácoras"},
    {prodId:"14111532",label:"Papel kits de papeles surtidos"},
    {prodId:"14111533",label:"Papel cuadernillos o formularios de exámenes"},
    {prodId:"14111534",label:"Papeles para notación musical o manuscritos"},
    {prodId:"14111535",label:"Papeles para telégrafo"},
    {prodId:"14111536",label:"Tarjetas de préstamo de bibliotecas"},
    {prodId:"14111537",label:"Etiquetas de papel"},
    {prodId:"14111538",label:"Papel digital"},
    {prodId:"14111539",label:"Papel de monitoreo o calcado o registro médico"},
    {prodId:"14111540",label:"Papel de estampilla"},
    {prodId:"14111541",label:"Papel lector de marcas ópticas"},
    {prodId:"14111542",label:"Papel coreano para papelería"},
    {prodId:"14111543",label:"Piedra de tinta"},
    {prodId:"14111600",label:"Papel fantasía"},
    {prodId:"14111601",label:"Papel o bolsas o cajas de regalo"},
    {prodId:"14111604",label:"Tarjetas de presentación"},
    {prodId:"14111605",label:"Tarjetas postales, de saludo o de notas"},
    {prodId:"14111606",label:"Papel para artes o artesanías"},
    {prodId:"14111607",label:"Tableros para afiches"},
    {prodId:"14111608",label:"Certificados de regalo"},
    {prodId:"14111609",label:"Papel de cubierta"},
    {prodId:"14111610",label:"Papel de construcción"},
    {prodId:"14111611",label:"Tarjetas de invitación o de anuncio"},
    {prodId:"14111613",label:"Papel de pancartas"},
    {prodId:"14111614",label:"Papel o tejidos de álbum"},
    {prodId:"14111615",label:"Papeles de afiche"},
    {prodId:"14111616",label:"Papeles para forrar"},
    {prodId:"14111617",label:"Papel leathack (verjurado)"},
    {prodId:"14111618",label:"Papel kent"},
    {prodId:"14111700",label:"Productos de papel para uso personal"},
    {prodId:"14111701",label:"Pañuelos faciales"},
    {prodId:"14111702",label:"Cubiertos de asientos de sanitario"},
    {prodId:"14111703",label:"Toallas de papel"},
    {prodId:"14111704",label:"Papel higiénico"},
    {prodId:"14111705",label:"Servilletas de papel"},
    {prodId:"14111706",label:"Manteles de papel"},
    {prodId:"14111800",label:"Papeles de uso comercial"},
    {prodId:"14111801",label:"Boletas o rollos de boletería"},
    {prodId:"14111802",label:"Recibos o libros de recibos"},
    {prodId:"14111803",label:"Comprobantes"},
    {prodId:"14111804",label:"Facturas o libros de facturas"},
    {prodId:"14111805",label:"Cheques o chequeras"},
    {prodId:"14111806",label:"Formularios o cuestionarios de negocios"},
    {prodId:"14111807",label:"Libros comerciales para múltiples usos"},
    {prodId:"14111808",label:"Formatos contables o libros de contabilidad"},
    {prodId:"14111809",label:"Formas o libros de conocimientos de embarque"},
    {prodId:"14111810",label:"Formatos o libros de personal"},
    {prodId:"14111811",label:"Formatos o libros de ventas"},
    {prodId:"14111812",label:"Formatos o libros de inventarios"},
    {prodId:"14111813",label:"Formatos o libros de correspondencia"},
    {prodId:"14111814",label:"Formatos o libros de impuestos"},
    {prodId:"14111815",label:"Tarjetas de identificación"},
    {prodId:"14111816",label:"Tarjetas de huellas digitales de solicitante"},
    {prodId:"14111817",label:"Formato de verificación de depósito"},
    {prodId:"14111818",label:"Papel térmico"},
    {prodId:"14111819",label:"Formularios p libros de reservas"},
    {prodId:"14111820",label:"Formas o cupones de juegos de azar"},
    {prodId:"14111821",label:"Formatos o libros de órdenes"},
    {prodId:"14111822",label:"Formatos o libros de entregas"},
    {prodId:"14111823",label:"Formatos o libros de control"},
    {prodId:"14111824",label:"Libreta de prescripciones farmacéuticas"},
    {prodId:"14111825",label:"Menú"},
    {prodId:"14111826",label:"Certificados de nacimiento"},
    {prodId:"14111827",label:"Certificados de defunción"},
    {prodId:"14111828",label:"Papelería comercial membreteada"},
    {prodId:"14121500",label:"Cartón y papel para embalaje"},
    {prodId:"14121501",label:"Cartón blanqueado"},
    {prodId:"14121502",label:"Cartón no blanqueado"},
    {prodId:"14121503",label:"Cartón"},
    {prodId:"14121504",label:"Papel de empaque"},
    {prodId:"14121505",label:"Material de fibra"},
    {prodId:"14121506",label:"Fibra corrugada"},
    {prodId:"14121507",label:"Papel inhibidor de la corrosión volátil o vci"},
    {prodId:"14121600",label:"Papel de seda"},
    {prodId:"14121601",label:"Papel crepé sin blanquear"},
    {prodId:"14121602",label:"Papel crepé semi blanqueado"},
    {prodId:"14121603",label:"Pañuelos de papel resistentes a la humedad"},
    {prodId:"14121604",label:"Pañuelos de papel libres de ácido"},
    {prodId:"14121605",label:"Pañuelos de papel kraft"},
    {prodId:"14121700",label:"Papeles laminados"},
    {prodId:"14121701",label:"Papeles adheridos con película"},
    {prodId:"14121702",label:"Papeles cilindro o papel pesado multi - capas"},
    {prodId:"14121703",label:"Hojas de papel aluminio laminado"},
    {prodId:"14121800",label:"Papeles recubiertos"},
    {prodId:"14121801",label:"Papeles recubiertos de arcilla"},
    {prodId:"14121802",label:"Papeles recubiertos de polietileno"},
    {prodId:"14121803",label:"Papeles recubiertos de poliéster"},
    {prodId:"14121804",label:"Papeles recubiertos de silicona"},
    {prodId:"14121805",label:"Papeles recubiertos tratados con látex"},
    {prodId:"14121806",label:"Papel de parafinado"},
    {prodId:"14121807",label:"Papel mantequilla"},
    {prodId:"14121808",label:"Papel para congelador"},
    {prodId:"14121809",label:"Papel de enmascarar"},
    {prodId:"14121810",label:"Papeles carbón"},
    {prodId:"14121811",label:"Papeles de copiado sensibilizados"},
    {prodId:"14121812",label:"Papel de fotografía"},
    {prodId:"14121900",label:"Papel prensa y de offset"},
    {prodId:"14121901",label:"Papel periódico estándar"},
    {prodId:"14121902",label:"Papel periódico de colores"},
    {prodId:"14121903",label:"Papel periódico de alto brillo"},
    {prodId:"14121904",label:"Papel offset"},
    {prodId:"14121905",label:"Papeles tímpano"},
    {prodId:"14122100",label:"Papeles de base sin recubrir"},
    {prodId:"14122101",label:"Papel kraft súper calandrado"},
    {prodId:"14122102",label:"Papel kraft terminado o satinado en máquina"},
    {prodId:"14122103",label:"Papel no recubierto no tratado"},
    {prodId:"14122104",label:"Papel crepé no tratado"},
    {prodId:"14122105",label:"Papel crepé tratado con látex"},
    {prodId:"14122106",label:"Papel no recubierto tratado con látex"},
    {prodId:"14122107",label:"Papel base corrugado"},
    {prodId:"14122200",label:"Papeles especializados de uso industrial"},
    {prodId:"14122201",label:"Papel para germinación de semillas"},
    {prodId:"14122202",label:"Papel para bolsas de te"},
    {prodId:"15101500",label:"Petróleo y Destilados"},
    {prodId:"15101502",label:"Kerosene"},
    {prodId:"15101503",label:"Nafta"},
    {prodId:"15101504",label:"Combustible de aviación"},
    {prodId:"15101505",label:"Combustible diesel"},
    {prodId:"15101507",label:"Benceno"},
    {prodId:"15101508",label:"Petróleo crudo"},
    {prodId:"15101509",label:"Combustible marítimo"},
    {prodId:"15101510",label:"Condensado"},
    {prodId:"15101511",label:"Etanol"},
    {prodId:"15101512",label:"Coque de petróleo o petcoke"},
    {prodId:"15101513",label:"Combustible diesel para uso fuera de carretera"},
    {prodId:"15101514",label:"Gasolina regular menor a 91 octanos"},
    {prodId:"15101515",label:"Gasolina premium mayor o igual a 91 octanos"},
    {prodId:"15101600",label:"Combustibles sólidos o gelificados"},
    {prodId:"15101601",label:"Carbón sub - bituminosos o débil"},
    {prodId:"15101602",label:"Lignito"},
    {prodId:"15101603",label:"Turba"},
    {prodId:"15101604",label:"Coque"},
    {prodId:"15101605",label:"Carbón de leña o vegetal"},
    {prodId:"15101606",label:"Combustibles de alcohol gelatinoso"},
    {prodId:"15101607",label:"Hexaminas"},
    {prodId:"15101608",label:"Trioxanos"},
    {prodId:"15101609",label:"Briqueta"},
    {prodId:"15101610",label:"Carbón de cascara de coco"},
    {prodId:"15101611",label:"Antracita o carbón duro"},
    {prodId:"15101612",label:"Carbón metalúrgico"},
    {prodId:"15101613",label:"Carbón sin procesar"},
    {prodId:"15101614",label:"Carbón energético"},
    {prodId:"15101700",label:"Aceites combustibles"},
    {prodId:"15101701",label:"Fuel oil de calefacción # 2"},
    {prodId:"15101702",label:"Fuel oils pesados residuales # 4 ó # 6"},
    {prodId:"15101800",label:"Combustibles o biocombustibles líquidos basados en plantas"},
    {prodId:"15101801",label:"Biodiesel"},
    {prodId:"15111500",label:"Combustibles gaseosos"},
    {prodId:"15111501",label:"Propano"},
    {prodId:"15111502",label:"Metano"},
    {prodId:"15111503",label:"Propileno"},
    {prodId:"15111504",label:"Etileno"},
    {prodId:"15111505",label:"Butano"},
    {prodId:"15111506",label:"Acetileno"},
    {prodId:"15111507",label:"Gas de agua o gas productor"},
    {prodId:"15111508",label:"Gas de carbón"},
    {prodId:"15111509",label:"Gas metilacetileno propadieno mapp"},
    {prodId:"15111510",label:"Gas licuado de petróleo"},
    {prodId:"15111511",label:"Gas natural licuado gnl"},
    {prodId:"15111512",label:"Gas natural"},
    {prodId:"15111700",label:"Aditivos para carburante"},
    {prodId:"15111701",label:"Espesantes de combustible"},
    {prodId:"15111702",label:"Inhibidores de hielo para sistemas de combustibles"},
    {prodId:"15121500",label:"Preparados lubricantes"},
    {prodId:"15121501",label:"Aceite motor"},
    {prodId:"15121502",label:"Aceite de corte"},
    {prodId:"15121503",label:"Aceite de engranajes"},
    {prodId:"15121504",label:"Aceite hidráulico"},
    {prodId:"15121505",label:"Aceite de transformador o aislador"},
    {prodId:"15121508",label:"Aceite de transmisión"},
    {prodId:"15121509",label:"Aceite de frenos"},
    {prodId:"15121510",label:"Anti - excoriación"},
    {prodId:"15121511",label:"Pastas de ensamble"},
    {prodId:"15121512",label:"Anti adhesivos"},
    {prodId:"15121513",label:"Lubricantes de grafito"},
    {prodId:"15121514",label:"Lubricantes espray"},
    {prodId:"15121515",label:"Compuestos anti - adherentes o anti - manchas"},
    {prodId:"15121516",label:"Eliminador de fugas"},
    {prodId:"15121517",label:"Jabones lubricantes"},
    {prodId:"15121518",label:"Fluidos de amortiguación"},
    {prodId:"15121519",label:"Aceites de lubricación de relojes"},
    {prodId:"15121520",label:"Lubricantes de propósito general"},
    {prodId:"15121521",label:"Aceites para lubricación de bombas"},
    {prodId:"15121522",label:"Aceites para lubricación de armas"},
    {prodId:"15121523",label:"Fluidos para preparación de lentes"},
    {prodId:"15121524",label:"Aceites de templado"},
    {prodId:"15121525",label:"Aceite atemperante"},
    {prodId:"15121526",label:"Lubricantes para equipo de procesamiento de alimentos"},
    {prodId:"15121527",label:"Aceite de turbina"},
    {prodId:"15121528",label:"Fluido hidráulico resistente al fuego"},
    {prodId:"15121529",label:"Aceite para máquinas de refrigeración"},
    {prodId:"15121530",label:"Aceite o fluido de transferencia de calor"},
    {prodId:"15121800",label:"Anticorrosivos"},
    {prodId:"15121801",label:"Repelente de humedad"},
    {prodId:"15121802",label:"Lubricante anti - corrosión"},
    {prodId:"15121803",label:"Removedor de óxido"},
    {prodId:"15121804",label:"Preparación contra óxido"},
    {prodId:"15121805",label:"Pastas anti - soldado"},
    {prodId:"15121806",label:"Aceites penetrantes"},
    {prodId:"15121807",label:"Anticongelante"},
    {prodId:"15121900",label:"Grasas"},
    {prodId:"15121901",label:"Grasa de silicona"},
    {prodId:"15121902",label:"Grasa"},
    {prodId:"15121903",label:"Grasa fluoropolímero"},
    {prodId:"15121904",label:"Grasa de lana"},
    {prodId:"15121905",label:"Grasa térmica"},
    {prodId:"15131500",label:"Combustible nuclear"},
    {prodId:"15131502",label:"Uranio empobrecido"},
    {prodId:"15131503",label:"Uranio enriquecido"},
    {prodId:"15131504",label:"Iridio"},
    {prodId:"15131505",label:"Plutonio enriquecido"},
    {prodId:"15131506",label:"Plutonio empobrecido"},
    {prodId:"20101500",label:"Equipo de corte"},
    {prodId:"20101501",label:"Equipo de minería continua"},
    {prodId:"20101502",label:"Cizallas de pared alta"},
    {prodId:"20101503",label:"Cortadores de carbón"},
    {prodId:"20101504",label:"Cortadores de roca"},
    {prodId:"20101505",label:"Cadena de corte para minería"},
    {prodId:"20101506",label:"Barra de corte"},
    {prodId:"20101600",label:"Cribas y equipos de alimentación"},
    {prodId:"20101601",label:"Pantallas"},
    {prodId:"20101602",label:"Alimentadores"},
    {prodId:"20101603",label:"Pantalla de hoyo de desagüe"},
    {prodId:"20101617",label:"Motorreductor"},
    {prodId:"20101618",label:"Canaleta alimentadora"},
    {prodId:"20101619",label:"Banda alimentadora para pesar"},
    {prodId:"20101620",label:"Vibro - alimentador electromagnético"},
    {prodId:"20101621",label:"Vibro - alimentador electromecánico"},
    {prodId:"20101700",label:"Trituradoras, quebrantadoras y amoladores"},
    {prodId:"20101701",label:"Trituradores de roca"},
    {prodId:"20101702",label:"Trituradores de rollo"},
    {prodId:"20101703",label:"Trituradores de cono"},
    {prodId:"20101704",label:"Trituradores giratorios"},
    {prodId:"20101705",label:"Trituradores de impacto"},
    {prodId:"20101706",label:"Trituradores de mandíbula"},
    {prodId:"20101707",label:"Plantas de trituración"},
    {prodId:"20101708",label:"Molino de varilla"},
    {prodId:"20101709",label:"Molino de bola"},
    {prodId:"20101710",label:"Maquinaria de pulverización"},
    {prodId:"20101711",label:"Rompedores de roca"},
    {prodId:"20101712",label:"Moledores de roca"},
    {prodId:"20101713",label:"Moledoras de ciclón o vórtex"},
    {prodId:"20101714",label:"Placas de mandíbula"},
    {prodId:"20101715",label:"Broca trituradora"},
    {prodId:"20101716",label:"Martillo triturador"},
    {prodId:"20101800",label:"Sistemas mecanizados de soporte en tierra"},
    {prodId:"20101801",label:"Apernador de cable"},
    {prodId:"20101802",label:"Apernador de tijera"},
    {prodId:"20101803",label:"Apernador de boom"},
    {prodId:"20101804",label:"Equipo para aplicar concreto lanzado o shotcrete"},
    {prodId:"20101805",label:"Repuestos o accesorios de sistema mecanizado de soporte en tierra"},
    {prodId:"20101810",label:"Conmutadores"},
    {prodId:"20101900",label:"Sistemas secundarios de ruptura de roca"},
    {prodId:"20101901",label:"Sistema blockholer o taladro y cargue"},
    {prodId:"20101902",label:"Sistemas de impacto repetitivo"},
    {prodId:"20101903",label:"Repuestos o accesorios de sistema secundario de ruptura de roca"},
    {prodId:"20102000",label:"Sistemas de exploración y desarrollo"},
    {prodId:"20102001",label:"Taladros de barreno profundo en el hoyo ith o abajo del hoyo dth"},
    {prodId:"20102002",label:"Taladros de barreo profundo de martillo superior"},
    {prodId:"20102003",label:"Jumbos neumáticos para perforación de pozos"},
    {prodId:"20102004",label:"Jumbos hidráulicos para perforación de pozos"},
    {prodId:"20102005",label:"Jumbos neumáticos de desarrollo horizontal"},
    {prodId:"20102006",label:"Jumbos hidráulicos de desarrollo horizontal"},
    {prodId:"20102007",label:"Taladros de núcleo"},
    {prodId:"20102008",label:"Repuestos o accesorios de sistema de exploración o desarrollo"},
    {prodId:"20102100",label:"Barrenas de rocas"},
    {prodId:"20102101",label:"Taladros de roca neumáticos"},
    {prodId:"20102102",label:"Taladros de roca hidráulicos"},
    {prodId:"20102103",label:"Taladros de roca manuales"},
    {prodId:"20102104",label:"Repuestos y accesorios de taladros de roca"},
    {prodId:"20102105",label:"Broca o barrena de acero"},
    {prodId:"20102106",label:"Broca o barrena de vacío"},
    {prodId:"20102300",label:"Vehículos de servicio de minería subterránea"},
    {prodId:"20102301",label:"Transporte de personal"},
    {prodId:"20102302",label:"Vehículos grúa"},
    {prodId:"20102303",label:"Cama bajas para el transporte de material"},
    {prodId:"20102304",label:"Transportadores de carga a granel"},
    {prodId:"20102305",label:"Vehículos utilitarios"},
    {prodId:"20102306",label:"Vehículos de plataforma elevable o elevadores de tijera"},
    {prodId:"20102307",label:"Repuestos o accesorios de vehículo de servicio minero subterráneo"},
    {prodId:"20111500",label:"Equipo de exploración y perforación"},
    {prodId:"20111504",label:"Equipos para perforar pozos de agua"},
    {prodId:"20111505",label:"Equipo para la exploración de uranio"},
    {prodId:"20111600",label:"Maquinaria de perforación y explotación"},
    {prodId:"20111601",label:"Maquinaria de sondeo o de perforación"},
    {prodId:"20111602",label:"Maquinaria de ensamble en fondo de pozo"},
    {prodId:"20111603",label:"Martillos perforadores"},
    {prodId:"20111604",label:"Taladro sobre orugas"},
    {prodId:"20111606",label:"Vibradores neumáticos"},
    {prodId:"20111607",label:"Maquinaria para hacer túneles"},
    {prodId:"20111608",label:"Martillos de poder"},
    {prodId:"20111609",label:"Taladro de platina"},
    {prodId:"20111610",label:"Maquinaria de inspección de alcantarillas"},
    {prodId:"20111611",label:"Taladros giratorios"},
    {prodId:"20111612",label:"Taladros de perforación de pozos"},
    {prodId:"20111613",label:"Taladros de barreno profundo"},
    {prodId:"20111614",label:"Brocas industriales"},
    {prodId:"20111615",label:"Perforadora de avance"},
    {prodId:"20111616",label:"Torre de perforación"},
    {prodId:"20111617",label:"Carros de perforación"},
    {prodId:"20111618",label:"Caña de pecar de fondo de pozo"},
    {prodId:"20111619",label:"Cono de perforación de pozos"},
    {prodId:"20111620",label:"Agarre de martillo"},
    {prodId:"20111621",label:"Oscilador de revestimiento"},
    {prodId:"20111622",label:"Taladro de circulación reversa"},
    {prodId:"20111623",label:"Almeja de diafragma de muro"},
    {prodId:"20111700",label:"Accesorios de perforación y explotación"},
    {prodId:"20111701",label:"Instrumentos audiovisuales para inspección de pozos"},
    {prodId:"20111702",label:"Tapones o anclajes de tubos"},
    {prodId:"20111703",label:"Recubrimientos de perforación"},
    {prodId:"20111704",label:"Pantallas de perforación"},
    {prodId:"20111705",label:"Puntos de pozo"},
    {prodId:"20111706",label:"Cuñas de perforación"},
    {prodId:"20111707",label:"Adaptadores de herramientas de perforación"},
    {prodId:"20111708",label:"Columnas de perforación"},
    {prodId:"20111709",label:"Herramientas o kits de accesorios para perforar pozos"},
    {prodId:"20111710",label:"Kit de reparación de tapones y tuberías de perforación"},
    {prodId:"20111711",label:"Partes y accesorios de tuberías de perforación"},
    {prodId:"20111712",label:"Tubo de lavado"},
    {prodId:"20111713",label:"Mástil de perforación"},
    {prodId:"20111714",label:"Conector de tubería flexible"},
    {prodId:"20111715",label:"Recubrimiento de tajo del sitio del pozo"},
    {prodId:"20121000",label:"Equipo acidificante"},
    {prodId:"20121001",label:"Unidades de mezcla acidificante"},
    {prodId:"20121002",label:"Sensores de densidad acidificante"},
    {prodId:"20121003",label:"Unidades de bombeo acidificante"},
    {prodId:"20121004",label:"Unidades acidificantes"},
    {prodId:"20121005",label:"Tubería de aire acidificante"},
    {prodId:"20121006",label:"Inyectores de bola acidificantes"},
    {prodId:"20121007",label:"Equipo de ácido líquido a granel"},
    {prodId:"20121008",label:"Cajas de gota acidificante"},
    {prodId:"20121009",label:"Medidores de acidificación"},
    {prodId:"20121010",label:"Cajas de unión de acidificación"},
    {prodId:"20121011",label:"Sensores de presión de acidificación"},
    {prodId:"20121012",label:"Tubería de proceso acidificante"},
    {prodId:"20121013",label:"Uniones estrechas de acidificación"},
    {prodId:"20121014",label:"Pivotes de acidificación"},
    {prodId:"20121015",label:"Hierros de tratamiento de acidificación"},
    {prodId:"20121016",label:"Protectores de árboles de acidificación"},
    {prodId:"20121100",label:"Equipo para cementar"},
    {prodId:"20121101",label:"Unidades de mezclado"},
    {prodId:"20121102",label:"Tapones de puente"},
    {prodId:"20121103",label:"Equipo de cemento líquido a granel"},
    {prodId:"20121104",label:"Equipo de material de cemento a granel"},
    {prodId:"20121105",label:"Sensores de densidad del cemento"},
    {prodId:"20121106",label:"Unidades de flotación de cemento a granel"},
    {prodId:"20121107",label:"Herramientas de flotación de cemento"},
    {prodId:"20121108",label:"Tapones limpiadores de equipo de flotación de cemento"},
    {prodId:"20121109",label:"Unidades de bombeo de cemento"},
    {prodId:"20121110",label:"Retenedores de cemento"},
    {prodId:"20121111",label:"Centralizadores"},
    {prodId:"20121112",label:"Acoples de pestillo expreso"},
    {prodId:"20121113",label:"Collares de flotación"},
    {prodId:"20121114",label:"Zapatas de flotación"},
    {prodId:"20121115",label:"Herramientas para cementación de campos petroleros"},
    {prodId:"20121116",label:"Tapones recuperables de cementación"},
    {prodId:"20121118",label:"Cabezales de cemento bajo el mar"},
    {prodId:"20121119",label:"Cabezales de cemento en superficie"},
    {prodId:"20121120",label:"Centralizador de arco"},
    {prodId:"20121121",label:"Centralizador de cuchilla"},
    {prodId:"20121122",label:"Centralizador sub de arco"},
    {prodId:"20121123",label:"Kit de herramientas de cementación"},
    {prodId:"20121124",label:"Kit de equipo de flotación de cemento"},
    {prodId:"20121125",label:"Kit de retención de cemento"},
    {prodId:"20121126",label:"Partes y accesorios del centralizador"},
    {prodId:"20121127",label:"Estabilizador"},
    {prodId:"20121128",label:"Herramienta de torque y arrastre de reducción"},
    {prodId:"20121129",label:"Partes y accesorios de la herramienta de torque y arrastre de reducción"},
    {prodId:"20121130",label:"Partes y accesorios del tapón de puente"},
    {prodId:"20121300",label:"Equipo de control de arena"},
    {prodId:"20121301",label:"Tapones obturadores"},
    {prodId:"20121302",label:"Flotadores"},
    {prodId:"20121303",label:"Sistemas frac pack"},
    {prodId:"20121304",label:"Sistemas paquete de gravilla"},
    {prodId:"20121305",label:"Zapatas guía"},
    {prodId:"20121306",label:"Boquillas de acometida"},
    {prodId:"20121307",label:"Acoples de conformación"},
    {prodId:"20121308",label:"Rebosamientos de tuberías de producción"},
    {prodId:"20121309",label:"Obturadores de control de arena"},
    {prodId:"20121310",label:"Equipo de granel líquido para control de arena"},
    {prodId:"20121311",label:"Equipo que usa unidades de soporte para control de arena a granel"},
    {prodId:"20121312",label:"Sensores de densidad de control de arena"},
    {prodId:"20121313",label:"Unidades múltiples de control de arena"},
    {prodId:"20121314",label:"Equipo de transporte de arena usando unidades de soporte"},
    {prodId:"20121315",label:"Unidades control de bombeo de arena"},
    {prodId:"20121316",label:"Pantallas de control de arena"},
    {prodId:"20121317",label:"Unidades de mezclado de lechada control de arena"},
    {prodId:"20121318",label:"Detectores de arena"},
    {prodId:"20121319",label:"Localizadores de ensamble de sello"},
    {prodId:"20121320",label:"Uniones de cizalla"},
    {prodId:"20121321",label:"Herramientas de levantamiento de manga"},
    {prodId:"20121322",label:"Mangas deslizantes"},
    {prodId:"20121323",label:"Cuerdas de velocidad"},
    {prodId:"20121324",label:"Anillo de control de arena"},
    {prodId:"20121325",label:"Patrón de tubo muescado"},
    {prodId:"20121326",label:"Partes y accesorios de pantalla de control de arena"},
    {prodId:"20121400",label:"Herramientas y equipo de terminación"},
    {prodId:"20121401",label:"Subs de atrapado de bola"},
    {prodId:"20121402",label:"Uniones de voladura"},
    {prodId:"20121403",label:"Boquillas de voladura"},
    {prodId:"20121404",label:"Tapón calibrador de cierre"},
    {prodId:"20121405",label:"Dispositivos de circulación de producción"},
    {prodId:"20121406",label:"Equipo de prueba de culminación"},
    {prodId:"20121407",label:"Protectores de línea de control"},
    {prodId:"20121408",label:"Herramientas de deflexión"},
    {prodId:"20121409",label:"Uniones de expansión de culminación"},
    {prodId:"20121410",label:"Acoples de flujo"},
    {prodId:"20121411",label:"Equipo de elevación de gas"},
    {prodId:"20121412",label:"Herramientas de estabilización de colgador"},
    {prodId:"20121413",label:"Bombas hidráulicas de culminación"},
    {prodId:"20121414",label:"Herramientas hidráulicas de curado"},
    {prodId:"20121415",label:"Sistemas de inyección"},
    {prodId:"20121416",label:"Boquillas de estabilización"},
    {prodId:"20121417",label:"Colgadores de línea"},
    {prodId:"20121418",label:"Herramientas de halar tapones"},
    {prodId:"20121419",label:"Herramientas de correr tapones"},
    {prodId:"20121420",label:"Tapones de producción"},
    {prodId:"20121421",label:"Equipos de bombeo a través de la línea de flujo"},
    {prodId:"20121422",label:"Uniones de seguridad de culminación"},
    {prodId:"20121423",label:"Ensambles de sello de culminación"},
    {prodId:"20121424",label:"Sello hoyo u hoyo pulido"},
    {prodId:"20121425",label:"Mandriles de bolsillos laterales"},
    {prodId:"20121427",label:"Válvulas de seguridad sub - superficie"},
    {prodId:"20121428",label:"Uniones de desplazamiento"},
    {prodId:"20121429",label:"Anclajes de tubería"},
    {prodId:"20121430",label:"Ensamblajes de flujo paralelo"},
    {prodId:"20121431",label:"Tapón inflable"},
    {prodId:"20121432",label:"Partes y accesorios de válvula de control de pozo"},
    {prodId:"20121433",label:"Kit de reparación de culminación de hoyo revestido"},
    {prodId:"20121434",label:"Kit de reparación de manga deslizante"},
    {prodId:"20121435",label:"Kit adaptador de fraguado"},
    {prodId:"20121436",label:"Partes y accesorios para el sistema de muelle de pistón"},
    {prodId:"20121437",label:"Partes y accesorios para las válvulas de seguridad de sub - superficie"},
    {prodId:"20121438",label:"Partes y accesorios para válvula de muelle de  gas"},
    {prodId:"20121439",label:"Mandril tapón de producción"},
    {prodId:"20121440",label:"Sub tapón de revestimiento del anillo"},
    {prodId:"20121441",label:"Herramienta de fraguado del revestimiento"},
    {prodId:"20121442",label:"Bloque de arrastre"},
    {prodId:"20121443",label:"Kit de herramientas de fraguado de tapón de revestimiento"},
    {prodId:"20121444",label:"Kit de reparación del sistema de revestimiento"},
    {prodId:"20121445",label:"Accesorios y partes"},
    {prodId:"20121446",label:"Tapón superior revestimiento"},
    {prodId:"20121447",label:"Collar fraguado del revestimiento"},
    {prodId:"20121448",label:"Junk bonnet"},
    {prodId:"20121449",label:"Equipo de muelle de pistón"},
    {prodId:"20121450",label:"Mandril muelle de gas"},
    {prodId:"20121451",label:"Válvula muelle de gas"},
    {prodId:"20121500",label:"Herramientas convencionales de perforación"},
    {prodId:"20121501",label:"Preventor de reventones"},
    {prodId:"20121502",label:"Controles del preventor de reventones"},
    {prodId:"20121503",label:"Raspadores de revestimiento"},
    {prodId:"20121504",label:"Collar de perforación"},
    {prodId:"20121505",label:"Equipo de núcleos"},
    {prodId:"20121506",label:"Protectores de rosca de tubería de perforación"},
    {prodId:"20121507",label:"Uniones de herramientas de tubería de perforación"},
    {prodId:"20121508",label:"Tubo de perforación"},
    {prodId:"20121509",label:"Anillos de calibre"},
    {prodId:"20121510",label:"Abridores de hoyos"},
    {prodId:"20121511",label:"Escariadores de hoyos"},
    {prodId:"20121513",label:"Amortiguadores de fondo de pozo"},
    {prodId:"20121514",label:"Subs de perforación"},
    {prodId:"20121515",label:"Estabilizadores de fondo de pozo"},
    {prodId:"20121516",label:"Propulsores"},
    {prodId:"20121517",label:"Partes y accesorios de escariadores de hoyos de perforación"},
    {prodId:"20121518",label:"Cabeza de control giratorio"},
    {prodId:"20121519",label:"Partes y accesorios de la cabeza de control giratorio"},
    {prodId:"20121520",label:"Partes y accesorios del preventor de reventones"},
    {prodId:"20121521",label:"Partes y accesorios de los amortiguadores de fondo de pozo"},
    {prodId:"20121522",label:"Partes y accesorios de los raspadores de revestimiento"},
    {prodId:"20121523",label:"Tarro de perforación"},
    {prodId:"20121524",label:"Partes y accesorios del tarro de perforación"},
    {prodId:"20121600",label:"Brocas de barrena"},
    {prodId:"20121601",label:"Brocas de cortadora fija"},
    {prodId:"20121602",label:"Brocas de diamante natural"},
    {prodId:"20121603",label:"Brocas de boquilla"},
    {prodId:"20121604",label:"Brocas pdc"},
    {prodId:"20121605",label:"Brocas cónicas de rodillo de botón de inserción"},
    {prodId:"20121606",label:"Brocas de rodillo de diente de acero"},
    {prodId:"20121607",label:"Brocas de núcleo"},
    {prodId:"20121608",label:"Brocas de bloque"},
    {prodId:"20121609",label:"Brocas de minería continuas"},
    {prodId:"20121610",label:"Brocas de alimentador"},
    {prodId:"20121611",label:"Broca de tamaño grande"},
    {prodId:"20121612",label:"Broca de techo"},
    {prodId:"20121613",label:"Accesorios de broca"},
    {prodId:"20121700",label:"Herramientas de pesca"},
    {prodId:"20121701",label:"Bumper subs"},
    {prodId:"20121702",label:"Parches de revestimiento"},
    {prodId:"20121703",label:"Impulsores de tarro"},
    {prodId:"20121704",label:"Subs de chatarra"},
    {prodId:"20121705",label:"Molinos o zapatas de quemado"},
    {prodId:"20121706",label:"Rebosamientos"},
    {prodId:"20121707",label:"Lanzas de pesca en campo petrolero"},
    {prodId:"20121708",label:"Herramientas de pesca no especificada"},
    {prodId:"20121709",label:"Tubo y extensión de lavado de pesca"},
    {prodId:"20121710",label:"Extensión de rebosamiento"},
    {prodId:"20121711",label:"Sub de pesca"},
    {prodId:"20121712",label:"Partes y accesorios de parche de revestimiento"},
    {prodId:"20121713",label:"Buje de operación de tubo de lavado"},
    {prodId:"20121714",label:"Bloque de impresión de pesca"},
    {prodId:"20121715",label:"Kit de pesca de sistema de cable de acero"},
    {prodId:"20121716",label:"Partes y accesorios de la canasta de chatarra"},
    {prodId:"20121717",label:"Pescante de agarre"},
    {prodId:"20121718",label:"Control de pescante"},
    {prodId:"20121719",label:"Tapón de pescante"},
    {prodId:"20121720",label:"Partes y accesorios de pescante"},
    {prodId:"20121721",label:"Imán de pesca"},
    {prodId:"20121722",label:"Grifo de ajuste"},
    {prodId:"20121723",label:"Agarre de lanza de pescar"},
    {prodId:"20121724",label:"Partes y accesorios de lanza de pescar"},
    {prodId:"20121725",label:"Cortador de revestimiento"},
    {prodId:"20121726",label:"Grifo de caja"},
    {prodId:"20121727",label:"Canasta de desperdicios"},
    {prodId:"20121728",label:"Tarro de pesca"},
    {prodId:"20121800",label:"Equipo de perforación direccional"},
    {prodId:"20121801",label:"Herramientas de geo - dirección"},
    {prodId:"20121802",label:"Motores de lodo"},
    {prodId:"20121803",label:"Herramientas dirigibles giratorias"},
    {prodId:"20121804",label:"Sistemas de control de superficie de perforación direccional"},
    {prodId:"20121805",label:"Herramientas de perforación direccional de hoyo derecho"},
    {prodId:"20121806",label:"Registro mientras herramientas perforan lwd"},
    {prodId:"20121807",label:"Partes y accesorios de registro mientras herramientas perforan lwd"},
    {prodId:"20121808",label:"Estabilizador de perforación direccional"},
    {prodId:"20121809",label:"Partes y accesorios de herramientas dirigibles giratorias"},
    {prodId:"20121810",label:"Sub de taladro direccional"},
    {prodId:"20121811",label:"Hélice de perforación direccional"},
    {prodId:"20121812",label:"Collar de perforación direccional"},
    {prodId:"20121813",label:"Partes y accesorios de motores de lodo"},
    {prodId:"20121900",label:"Equipo de medir y registro del pozo"},
    {prodId:"20121901",label:"Herramientas acústicas"},
    {prodId:"20121902",label:"Instrumentos de control de perforación o lodo"},
    {prodId:"20121903",label:"Herramientas de medición de desempeño de perforación"},
    {prodId:"20121904",label:"Equipo de medición de flujo"},
    {prodId:"20121905",label:"Herramientas de resonancia magnética nuclear"},
    {prodId:"20121906",label:"Herramientas nucleares"},
    {prodId:"20121907",label:"Equipos de registro de producción"},
    {prodId:"20121908",label:"Herramientas de resistividad"},
    {prodId:"20121909",label:"Sistemas de levantamiento"},
    {prodId:"20121910",label:"Sistemas de telemetría"},
    {prodId:"20121911",label:"Herramientas ultrasónicas"},
    {prodId:"20121912",label:"Equipo de presión de registro de fondo de pozo"},
    {prodId:"20121913",label:"Equipo de prueba de registro de fondo de pozo"},
    {prodId:"20121914",label:"Unidades de registro de pozo"},
    {prodId:"20121915",label:"Registro de densidad de granel"},
    {prodId:"20121916",label:"Cable de fondo de pozo de sensor óptico"},
    {prodId:"20121917",label:"Mandril y accesorios de sensor óptico"},
    {prodId:"20121918",label:"Cable de superficie de sensor óptico"},
    {prodId:"20121919",label:"Herramientas y accesorios de inspección de revestimiento"},
    {prodId:"20121920",label:"Herramientas y accesorios de indicador de punto libre"},
    {prodId:"20121921",label:"Medidor de radiación"},
    {prodId:"20121922",label:"Partes y accesorios de herramienta de rayos gamma"},
    {prodId:"20121923",label:"Herramientas y partes de imaginería de pozo"},
    {prodId:"20122000",label:"Equipo de prueba y Accesorios"},
    {prodId:"20122001",label:"Barra de desplazamiento"},
    {prodId:"20122002",label:"Mangas de desplazamiento"},
    {prodId:"20122003",label:"Conejo de desplazamiento"},
    {prodId:"20122004",label:"Accesorios de prueba"},
    {prodId:"20122005",label:"Boquillas de prueba"},
    {prodId:"20122006",label:"Tapones de prueba"},
    {prodId:"20122100",label:"Equipo de Perforación"},
    {prodId:"20122101",label:"Pistolas de cápsulas"},
    {prodId:"20122102",label:"Pistolas de revestimiento"},
    {prodId:"20122103",label:"Cabezas de despliegue"},
    {prodId:"20122104",label:"Explosivos de perforación"},
    {prodId:"20122105",label:"Cabezas de disparo"},
    {prodId:"20122106",label:"Adaptadores de pistola"},
    {prodId:"20122107",label:"Pistola de disparo de alta densidad"},
    {prodId:"20122108",label:"Tapones bull de perforación"},
    {prodId:"20122109",label:"Herramientas de poner tapones"},
    {prodId:"20122110",label:"Equipo de posicionamiento de perforación"},
    {prodId:"20122111",label:"Pistolas festoneadas"},
    {prodId:"20122112",label:"Subs en tándem"},
    {prodId:"20122114",label:"Pistola de perforación de tubos completa"},
    {prodId:"20122115",label:"Subs ventilación bajo balance"},
    {prodId:"20122213",label:"Separadores de pruebas de pozo"},
    {prodId:"20122300",label:"Equipo de Slickline - Cable de Recuperación"},
    {prodId:"20122301",label:"Cabezas de adaptador de cable de recuperación"},
    {prodId:"20122302",label:"Retardo de envío del cable de recuperación"},
    {prodId:"20122303",label:"Guías de campana de cable de recuperación"},
    {prodId:"20122304",label:"Cajas ciegas de cable de recuperación"},
    {prodId:"20122305",label:"Equipo de presión de fondo de hoyo de cable de recuperación"},
    {prodId:"20122306",label:"Herramientas de calibración de cable de recuperación"},
    {prodId:"20122307",label:"Volcado de cemento de rescate de equipos cable de recuperación"},
    {prodId:"20122308",label:"Cortadores químicos de cable de recuperación"},
    {prodId:"20122309",label:"Centralizadores de cadena de herramientas de almeja de cable de recuperación"},
    {prodId:"20122310",label:"Centralizadores de cable de acero de almeja de cable de recuperación"},
    {prodId:"20122311",label:"Localizadores de collar de cable de recuperación"},
    {prodId:"20122312",label:"Colectores de cable de recuperación"},
    {prodId:"20122313",label:"Herramientas de colisión de cable de recuperación"},
    {prodId:"20122314",label:"Cruces de cable de recuperación"},
    {prodId:"20122315",label:"Equipo de medición de profundidad de cable de recuperación"},
    {prodId:"20122316",label:"Frasco dewar de cable de recuperación"},
    {prodId:"20122317",label:"Herramientas del dipmeter de cable de recuperación"},
    {prodId:"20122318",label:"Herramientas direccionales de cable de recuperación"},
    {prodId:"20122319",label:"Herramientas de vaya con el diablo de cable de recuperación"},
    {prodId:"20122320",label:"Abridores de hoyos de cable de recuperación"},
    {prodId:"20122321",label:"Cortadores jet de cable de recuperación"},
    {prodId:"20122322",label:"Disparos de desperdicios de cable de recuperación"},
    {prodId:"20122323",label:"Herramientas de kickover de cable de recuperación"},
    {prodId:"20122324",label:"Uniones de nudillos de cable de recuperación"},
    {prodId:"20122325",label:"Bloques de impresión de cable de recuperación"},
    {prodId:"20122326",label:"Mandriles de ubicación de cable de recuperación"},
    {prodId:"20122327",label:"Mandriles de bloqueo de cable de recuperación"},
    {prodId:"20122328",label:"Lubricadores de cable de recuperación"},
    {prodId:"20122329",label:"Achicadores mecánicos de cable de recuperación"},
    {prodId:"20122330",label:"Plugback mecánico de cable de recuperación"},
    {prodId:"20122331",label:"Otras herramientas de cable de recuperación"},
    {prodId:"20122332",label:"Raspadores de parafina de cable de recuperación"},
    {prodId:"20122333",label:"Enchufe de línea de cable de recuperación"},
    {prodId:"20122334",label:"Tenazas para halar o correr el cable de recuperación"},
    {prodId:"20122335",label:"Herramientas para cortar el cable de recuperación"},
    {prodId:"20122336",label:"Roldanas o bloques de piso de la cable de recuperación"},
    {prodId:"20122338",label:"Accesorios de herramientas para halar el cable de recuperación"},
    {prodId:"20122339",label:"Herramientas para halar el cable de recuperación"},
    {prodId:"20122340",label:"Herramientas para correr el cable de recuperación"},
    {prodId:"20122341",label:"Unidades de cable de recuperación"},
    {prodId:"20122342",label:"Alambre del cable de recuperación"},
    {prodId:"20122343",label:"Herramientas sónicas del cable de recuperación"},
    {prodId:"20122344",label:"Barras espaciadoras del cable de recuperación"},
    {prodId:"20122345",label:"Terminales de prensado del cable de recuperación"},
    {prodId:"20122346",label:"Dispositivos de tensión del cable de recuperación"},
    {prodId:"20122347",label:"Tapones de tubos de cable de recuperación"},
    {prodId:"20122348",label:"Herramientas ultrasónicas cable de recuperación"},
    {prodId:"20122349",label:"Agarres del cable de recuperación"},
    {prodId:"20122350",label:"Tarros del cable de recuperación"},
    {prodId:"20122351",label:"Raspadores del cable de recuperación"},
    {prodId:"20122352",label:"Lanza del cable de recuperación"},
    {prodId:"20122353",label:"Tallos del cable de recuperación"},
    {prodId:"20122354",label:"Válvulas del cable de recuperación"},
    {prodId:"20122356",label:"Preventores del cable de recuperación"},
    {prodId:"20122357",label:"Aceleradores de tarro del cable de recuperación"},
    {prodId:"20122358",label:"Dardo de prueba"},
    {prodId:"20122359",label:"Accesorios y partes de herramientas de corrida del cable de recuperación"},
    {prodId:"20122360",label:"Punzón de la línea de cable"},
    {prodId:"20122361",label:"Válvula de pie"},
    {prodId:"20122362",label:"Guía de campana de la línea de cable"},
    {prodId:"20122363",label:"Hallador de cable"},
    {prodId:"20122364",label:"Cadena de herramientas de línea de cable"},
    {prodId:"20122365",label:"Partes y accesorios del centralizador de cable de recuperación"},
    {prodId:"20122366",label:"Descentralizador magnético"},
    {prodId:"20122367",label:"Herramienta de alado de la línea de cable"},
    {prodId:"20122368",label:"Mandril de la línea de cable"},
    {prodId:"20122369",label:"Herramienta de establecer línea de cable"},
    {prodId:"20122370",label:"Cruce de línea de cable"},
    {prodId:"20122371",label:"Centralizador de cable de recuperación"},
    {prodId:"20122372",label:"Acople de pivote de línea de cable"},
    {prodId:"20122373",label:"Partes y accesorios de herramienta de kickover del cable de recuperación"},
    {prodId:"20122400",label:"Equipo de sistemas de producción"},
    {prodId:"20122401",label:"Dispositivo golpeador de cable"},
    {prodId:"20122402",label:"Evaporadores de producción de campo de petróleo"},
    {prodId:"20122403",label:"Probadores hipot"},
    {prodId:"20122404",label:"Maquinas de vueltas de campo de petróleo"},
    {prodId:"20122405",label:"Elevadores finales de motor"},
    {prodId:"20122406",label:"Probadores dieléctricos de aceite"},
    {prodId:"20122407",label:"Unidades de llenado al vacío de aceite"},
    {prodId:"20122408",label:"Enderezadores de eje de producción de campo petrolero"},
    {prodId:"20122409",label:"Bobina de producción de campo de petróleo"},
    {prodId:"20122410",label:"Analizadores de vibración"},
    {prodId:"20122500",label:"Equipo para tubería flexible"},
    {prodId:"20122501",label:"Herramientas de arenadora"},
    {prodId:"20122502",label:"Unidades de camión grúa de tubería flexible"},
    {prodId:"20122503",label:"Unidades de tubería flexible"},
    {prodId:"20122504",label:"Paquetes de manguera de tubería flexible"},
    {prodId:"20122505",label:"Sistemas inflables de tubería flexible"},
    {prodId:"20122506",label:"Cabezales de inyector de tubería flexible"},
    {prodId:"20122507",label:"Equipo de elevación de tubería flexible"},
    {prodId:"20122508",label:"Casas de operadores"},
    {prodId:"20122509",label:"Fuentes de poder de tubería flexible"},
    {prodId:"20122510",label:"Rollos de tubería flexible"},
    {prodId:"20122511",label:"Carretes de embobinar tubería flexible"},
    {prodId:"20122512",label:"Guías de tubería"},
    {prodId:"20122513",label:"Enganches de boca de pozo"},
    {prodId:"20122514",label:"Estructuras de soporte de boca de pozo"},
    {prodId:"20122515",label:"Tubería flexible de campo petrolero"},
    {prodId:"20122516",label:"Cadena de herramientas de tubería flexible"},
    {prodId:"20122518",label:"Centralizador de tubería flexible"},
    {prodId:"20122700",label:"Mercancía tubular para campo petrolero"},
    {prodId:"20122701",label:"Revestimiento para campo petrolero"},
    {prodId:"20122702",label:"Acoples para campo petrolero"},
    {prodId:"20122703",label:"Tubería corta para campo petrolero"},
    {prodId:"20122704",label:"Tubería para campo petrolero"},
    {prodId:"20122705",label:"Recubrimiento de tubería para campo petrolero"},
    {prodId:"20122706",label:"Revestimiento de conductor"},
    {prodId:"20122707",label:"Equipo para operar el revestimiento para conductor"},
    {prodId:"20122708",label:"Cruces de la tubería de perforación"},
    {prodId:"20122709",label:"Protectores de rosca para campo petrolero"},
    {prodId:"20122710",label:"Dispositivo para parar el revestimiento"},
    {prodId:"20122800",label:"Equipo y plataformas de reacondicionamiento y perforación"},
    {prodId:"20122801",label:"Agitadores de lodos"},
    {prodId:"20122802",label:"Depósitos de lodos"},
    {prodId:"20122803",label:"Equipo de aire para perforaciones"},
    {prodId:"20122804",label:"Taladros en barcazas"},
    {prodId:"20122806",label:"Desviadores de fluido"},
    {prodId:"20122807",label:"Aparejos de maniobras"},
    {prodId:"20122808",label:"Equipo para lecho de perforación"},
    {prodId:"20122809",label:"Pivotes de perforación"},
    {prodId:"20122810",label:"Barco - taladro de perforación"},
    {prodId:"20122811",label:"Elevadores de taladro de perforación"},
    {prodId:"20122812",label:"Estribos del taladro de perforación"},
    {prodId:"20122813",label:"Unidades de reacondicionamiento hidráulico"},
    {prodId:"20122814",label:"Sistemas de gatos para mover el taladro de perforación"},
    {prodId:"20122815",label:"Taladro de perforación marina movido con gatos"},
    {prodId:"20122816",label:"Bujes kelly"},
    {prodId:"20122817",label:"Válvulas kelly"},
    {prodId:"20122818",label:"Escobillas kelly"},
    {prodId:"20122819",label:"Kellys"},
    {prodId:"20122820",label:"Taladro de perforación en tierra"},
    {prodId:"20122821",label:"Equipo de limpieza de lodo"},
    {prodId:"20122822",label:"Múltiples para lodo"},
    {prodId:"20122823",label:"Mezcladoras de lodo"},
    {prodId:"20122824",label:"Equipo para manejo de tubería"},
    {prodId:"20122825",label:"Plataforma de taladro de perforación"},
    {prodId:"20122826",label:"Pivote de energía o transmisión superior"},
    {prodId:"20122827",label:"Patines para taladro"},
    {prodId:"20122828",label:"Elevadores del taladro de perforación"},
    {prodId:"20122829",label:"Mesas giratorias de taladro de perforación"},
    {prodId:"20122830",label:"Plataformas auto - elevadoras para el reacondicionamiento"},
    {prodId:"20122831",label:"Taladros de perforación semi - sumergibles"},
    {prodId:"20122832",label:"Deslizadores del lecho de perforación"},
    {prodId:"20122833",label:"Tenazas de reposición"},
    {prodId:"20122834",label:"Llave doble automática"},
    {prodId:"20122835",label:"Equipo viajero"},
    {prodId:"20122836",label:"Barcos de reacondicionamiento"},
    {prodId:"20122837",label:"Taladros de reacondicionamiento"},
    {prodId:"20122838",label:"Separadores de lodo (lutita)"},
    {prodId:"20122839",label:"Desgasificadores de lodo"},
    {prodId:"20122840",label:"Bloques de corona"},
    {prodId:"20122841",label:"Bloques móviles"},
    {prodId:"20122842",label:"Desarenadores de lodo"},
    {prodId:"20122843",label:"Separadores de granos finos de lodo"},
    {prodId:"20122844",label:"Tenazas de energía"},
    {prodId:"20122845",label:"Partes y accesorios para el manejo de tubería"},
    {prodId:"20122846",label:"Trampolín"},
    {prodId:"20122847",label:"Sub y enchufe de elevador"},
    {prodId:"20122848",label:"Dispositivo de arreglo horizontal o unidad de trepidación"},
    {prodId:"20122849",label:"Araña de revestimiento de pozo"},
    {prodId:"20122851",label:"Unidad de potencia hidráulica"},
    {prodId:"20122900",label:"Equipo de registrar datos de superficie"},
    {prodId:"20122901",label:"Conductos de registrar datos de superficie"},
    {prodId:"20122902",label:"Sensores de registrar datos de superficie"},
    {prodId:"20122903",label:"Unidades de registrar datos de superficie"},
    {prodId:"20123200",label:"Equipo de expansión en profundidad"},
    {prodId:"20123201",label:"Herramienta ranurada de ajuste expandible"},
    {prodId:"20123202",label:"Kit de reparación expandible"},
    {prodId:"20123203",label:"Revestimiento expandible"},
    {prodId:"20131000",label:"Lodo de perforar y materiales"},
    {prodId:"20131001",label:"Agentes de control de filtración"},
    {prodId:"20131002",label:"Espaciadores de fluido"},
    {prodId:"20131003",label:"Agentes de circulación perdida"},
    {prodId:"20131004",label:"Lodos con base de petróleo"},
    {prodId:"20131005",label:"Aumentadores de la rata de penetración"},
    {prodId:"20131006",label:"Fluidos centradores"},
    {prodId:"20131007",label:"Lodos con base sintética"},
    {prodId:"20131008",label:"Agentes para adelgazar el lodo"},
    {prodId:"20131009",label:"Lodos con base en agua"},
    {prodId:"20141000",label:"Equipo de cabeza de pozo"},
    {prodId:"20141001",label:"Actuadores de boca de pozo"},
    {prodId:"20141002",label:"Bombas de balancín de boca de pozo"},
    {prodId:"20141003",label:"Líneas de flujo de boca de pozo"},
    {prodId:"20141004",label:"Válvulas de compuerta de boca de pozo"},
    {prodId:"20141005",label:"Reguladores de producción de boca de pozo"},
    {prodId:"20141006",label:"Accesorios de boca de pozo o flujo debajo de la superficie de boca de pozo"},
    {prodId:"20141007",label:"Accesorios de boca de pozo o flujo de superficie de boca de pozo"},
    {prodId:"20141008",label:"Válvulas de seguridad de la superficie de boca de pozo"},
    {prodId:"20141011",label:"Adaptador de cabeza de tubería"},
    {prodId:"20141014",label:"Carrete del cabezal del revestimiento"},
    {prodId:"20141015",label:"Conexiones en t o en cruz de la boca de pozo"},
    {prodId:"20141016",label:"Base de llegada de la boca de pozo"},
    {prodId:"20141017",label:"Cuerpo de transporte boca de pozo"},
    {prodId:"20141018",label:"Colgador de boca de pozo"},
    {prodId:"20141300",label:"Anclas y bombas de chorro de barrenas hacia abajo"},
    {prodId:"20141301",label:"Bombas de chorro de fondo de pozo"},
    {prodId:"20141302",label:"Partes y accesorios de bombas de chorro de fondo de pozo"},
    {prodId:"20141303",label:"Tubería de perforación"},
    {prodId:"20141500",label:"Bombas de barrenas hacia abajo"},
    {prodId:"20141501",label:"Bombas eléctricas de fondo de pozo"},
    {prodId:"20141502",label:"Bomba de fondo de pozo de cavidad progresiva"},
    {prodId:"20141700",label:"Plataformas de almacenaje y producción a poca distancia de la costa"},
    {prodId:"20141701",label:"Plataformas fijas de producción costa afuera"},
    {prodId:"20141702",label:"Plataformas flotantes de producción costa afuera"},
    {prodId:"20141703",label:"Plataformas flotantes de almacenamiento costa afuera"},
    {prodId:"20141704",label:"Plataformas flotantes de brazo de tensión de producción costa afuera"},
    {prodId:"20141705",label:"Plataformas flotantes de brazo de tensión de almacenamiento costa afuera"},
    {prodId:"20142200",label:"Calentadores de línea"},
    {prodId:"20142201",label:"Calentadores de línea eléctrica"},
    {prodId:"20142700",label:"Unidades de bombear"},
    {prodId:"20142701",label:"Gatos de la varilla de bombeo"},
    {prodId:"20142702",label:"Bombas de barra"},
    {prodId:"20142703",label:"Bombas de barra mecánica"},
    {prodId:"20142704",label:"Ensamble de marco de bombeo y extensión"},
    {prodId:"20142705",label:"Ensamble de biela"},
    {prodId:"20142706",label:"Ensamble de ecualizador pitman"},
    {prodId:"20142707",label:"Ensamble de cabezote"},
    {prodId:"20142708",label:"Ensamble de puesto samson"},
    {prodId:"20142709",label:"Ensamble de viga de caminar"},
    {prodId:"20142710",label:"Ensamble de barra de colgador"},
    {prodId:"20142800",label:"Separadores de producción"},
    {prodId:"20142801",label:"Separadores de agua petróleo"},
    {prodId:"20142900",label:"Tanques y recipientes almacenadores"},
    {prodId:"20142901",label:"Tanques almacenadores de petróleo"},
    {prodId:"20142902",label:"Tanque de fibra de vidrio"},
    {prodId:"20142903",label:"Tanque de acero"},
    {prodId:"20142904",label:"Tanque plástico"},
    {prodId:"20142905",label:"Tanque receptor de aire"},
    {prodId:"20143000",label:"Vástagos de succión"},
    {prodId:"20143001",label:"Vástagos de succión de aleación de acero"},
    {prodId:"20143002",label:"Varillas cortas"},
    {prodId:"20143003",label:"Vástago continuo de succión"},
    {prodId:"20143004",label:"Pin de terminación roscada de vástago continuo de succión"},
    {prodId:"20143005",label:"Acople deslizante de vástago continuo de succión"},
    {prodId:"21101500",label:"Maquinaria agrícola para preparación del suelo"},
    {prodId:"21101501",label:"Arados"},
    {prodId:"21101502",label:"Pulverizadores"},
    {prodId:"21101503",label:"Cultivadoras"},
    {prodId:"21101504",label:"Desmalezadoras"},
    {prodId:"21101505",label:"Máquinas desyerbadoras"},
    {prodId:"21101506",label:"Máquinas aplanadoras o niveladoras"},
    {prodId:"21101507",label:"Rodillos agrícolas"},
    {prodId:"21101508",label:"Rodillos para prados o terrenos deportivos"},
    {prodId:"21101509",label:"Máquina para drenaje de zanjas"},
    {prodId:"21101511",label:"Chorros de riego"},
    {prodId:"21101512",label:"Gastos generales de riego"},
    {prodId:"21101513",label:"Discos"},
    {prodId:"21101514",label:"Arados de subsuelo"},
    {prodId:"21101516",label:"Sembradoras"},
    {prodId:"21101517",label:"Motocultivador giratorio o de potencia"},
    {prodId:"21101518",label:"Esterilizador de suelo"},
    {prodId:"21101519",label:"Inyector de suelo"},
    {prodId:"21101520",label:"Lanzallamas"},
    {prodId:"21101521",label:"Mezclador de fertilizante"},
    {prodId:"21101522",label:"Arado de bordes o arado"},
    {prodId:"21101523",label:"Sistema automatizado de manejo de maquinaria agrícola"},
    {prodId:"21101600",label:"Maquinaria agrícola para siembra y plantado"},
    {prodId:"21101601",label:"Plantadoras"},
    {prodId:"21101602",label:"Transplantadoras"},
    {prodId:"21101603",label:"Sembradoras de grano"},
    {prodId:"21101604",label:"Sembradoras de semillas"},
    {prodId:"21101605",label:"Equipo para tratamiento de semillas"},
    {prodId:"21101606",label:"Excavadoras de agujeros"},
    {prodId:"21101607",label:"Remolque de sembradora"},
    {prodId:"21101608",label:"Gabinete o cámara para crecimiento de plantas"},
    {prodId:"21101609",label:"Cama de semillas (semillero)"},
    {prodId:"21101610",label:"Máquina de cubrimiento del suelo"},
    {prodId:"21101611",label:"Controlador de tasa de flujo de fertilizante"},
    {prodId:"21101612",label:"Germinador de semillas"},
    {prodId:"21101700",label:"Maquinaria agrícola para cosechar"},
    {prodId:"21101701",label:"Cortadoras de pasto"},
    {prodId:"21101702",label:"Máquina segadora de heno"},
    {prodId:"21101703",label:"Cosechadoras"},
    {prodId:"21101704",label:"Cosechadoras “trilladoras” o mixtas"},
    {prodId:"21101705",label:"Trilladoras"},
    {prodId:"21101706",label:"Separadores de cultivos"},
    {prodId:"21101707",label:"Piezas de cosechadora o accesorios"},
    {prodId:"21101708",label:"Piezas de segadora o accesorios"},
    {prodId:"21101709",label:"Embaladora"},
    {prodId:"21101710",label:"Secadora de cosecha"},
    {prodId:"21101711",label:"Desgranadora"},
    {prodId:"21101712",label:"Máquina automática cortadora y empaquetadora de flores"},
    {prodId:"21101800",label:"Aparatos dispersores o aspersores para agricultura"},
    {prodId:"21101801",label:"Rociadores"},
    {prodId:"21101802",label:"Guarda polvos"},
    {prodId:"21101803",label:"Aspersores de agua"},
    {prodId:"21101804",label:"Dispersores o distribuidores de fertilizante"},
    {prodId:"21101805",label:"Generadores de niebla o neblina"},
    {prodId:"21101806",label:"Compostadores"},
    {prodId:"21101807",label:"Equipo y suministros para polinización"},
    {prodId:"21101808",label:"Equipo para protección contra las heladas"},
    {prodId:"21101809",label:"Sistema para la fumigación del grano"},
    {prodId:"21101900",label:"Equipo para aves de corral y ganado"},
    {prodId:"21101901",label:"Ordeñadoras"},
    {prodId:"21101902",label:"Equipo para cría de ganado"},
    {prodId:"21101903",label:"Incubadoras o polleras para aves de corral"},
    {prodId:"21101904",label:"Mezcladoras de forraje"},
    {prodId:"21101905",label:"Equipo para identificación de ganado"},
    {prodId:"21101906",label:"Equipo de inspección o recolección de huevos"},
    {prodId:"21101907",label:"Máquinas para abrevar animales"},
    {prodId:"21101908",label:"Tanques refrigeradores de leche"},
    {prodId:"21101909",label:"Equipo para el esquilar o peluquear de animales"},
    {prodId:"21101910",label:"Peine de lana"},
    {prodId:"21101911",label:"Compostador para gallinaza"},
    {prodId:"21101912",label:"Espacio para inmovilizar ganado"},
    {prodId:"21101913",label:"Cortador de picos"},
    {prodId:"21101914",label:"Cerca eléctrica para ganado"},
    {prodId:"21101915",label:"Medidor de grasa en la espalda de los cerdos"},
    {prodId:"21102000",label:"Maquinaria agrícola para limpieza, selección o clasificación"},
    {prodId:"21102001",label:"Máquinas limpiadoras de semillas, grano o legumbres secas"},
    {prodId:"21102002",label:"Máquinas seleccionadoras de semillas, grano o legumbres secas"},
    {prodId:"21102003",label:"Clasificadoras de semillas, grano o legumbres secas"},
    {prodId:"21102004",label:"Equipo para limpieza y descascarillado de arroz"},
    {prodId:"21102005",label:"Molino para trituración"},
    {prodId:"21102006",label:"Molinos de martillo"},
    {prodId:"21102007",label:"Clasificadora de frutas"},
    {prodId:"21102008",label:"Equipo para limpiar o descascarar cebada"},
    {prodId:"21102100",label:"Maquinaria y equipo para transformación agrícola"},
    {prodId:"21102101",label:"Máquinas agrícolas de briqueta"},
    {prodId:"21102102",label:"Expulsor de aceite de semilla"},
    {prodId:"21102200",label:"Maquinaria y equipo para silvicultura"},
    {prodId:"21102201",label:"Descortezadoras"},
    {prodId:"21102202",label:"Equipo de explotación forestal"},
    {prodId:"21102203",label:"Equipo de reforestación"},
    {prodId:"21102204",label:"Sierras para silvicultura"},
    {prodId:"21102205",label:"Deslizadores forestales"},
    {prodId:"21102206",label:"Barrenos para aumento forestal"},
    {prodId:"21102207",label:"Hipsómetro para silvicultura"},
    {prodId:"21102300",label:"Equipo para invernadero"},
    {prodId:"21102301",label:"Equipo de riego para invernadero"},
    {prodId:"21102302",label:"Materas para invernadero"},
    {prodId:"21102303",label:"Equipo de ventilación para invernadero"},
    {prodId:"21102304",label:"Equipo aislante para invernadero"},
    {prodId:"21102305",label:"Material para sombras para agricultura"},
    {prodId:"21102306",label:"Inyector de fertilizante hidropónico"},
    {prodId:"21102400",label:"Equipo para insectos"},
    {prodId:"21102401",label:"Equipo para apicultura"},
    {prodId:"21102402",label:"Equipo para gusanos de seda"},
    {prodId:"21102403",label:"Equipo para la cría de mariposas"},
    {prodId:"21102404",label:"Equipo para la cría de escarabajos"},
    {prodId:"21111500",label:"Equipo de pesca comercial"},
    {prodId:"21111501",label:"Anzuelos para pesca comercial"},
    {prodId:"21111502",label:"Carretes para pesca comercial"},
    {prodId:"21111503",label:"Aparejos para pesca comercial"},
    {prodId:"21111504",label:"Redes para pesca comercial"},
    {prodId:"21111506",label:"Flotadores para pesca comercial"},
    {prodId:"21111507",label:"Plomos o pesos comerciales"},
    {prodId:"21111508",label:"Transportadores de redes de pesca"},
    {prodId:"21111600",label:"Equipo para acuicultura"},
    {prodId:"21111601",label:"Equipo para piscicultura marina"},
    {prodId:"21111602",label:"Suministros para piscicultura"},
    {prodId:"22101500",label:"Maquinaria para trabajo de desmonte"},
    {prodId:"22101501",label:"Cargadores frontales"},
    {prodId:"22101502",label:"Niveladoras"},
    {prodId:"22101504",label:"Piloteadoras"},
    {prodId:"22101505",label:"Aplanadoras"},
    {prodId:"22101507",label:"Bateadoras"},
    {prodId:"22101508",label:"Máquinas para abrir zanjas"},
    {prodId:"22101509",label:"Retroexcavadoras"},
    {prodId:"22101511",label:"Compactadores"},
    {prodId:"22101513",label:"Dragalíneas"},
    {prodId:"22101514",label:"Dragas"},
    {prodId:"22101516",label:"Excavadoras de fosos"},
    {prodId:"22101518",label:"Raspadores elevadores"},
    {prodId:"22101519",label:"Máquina giratoria con cazoleta de rastrillos abiertas"},
    {prodId:"22101520",label:"Máquina giratoria con rastrillos elevadores"},
    {prodId:"22101521",label:"Rastrilladora arrastrada"},
    {prodId:"22101522",label:"Buldóceres de orugas"},
    {prodId:"22101523",label:"Buldóceres de ruedas"},
    {prodId:"22101524",label:"Excavadoras móviles"},
    {prodId:"22101525",label:"Excavadoras de ruedas"},
    {prodId:"22101526",label:"Excavadoras de orugas"},
    {prodId:"22101527",label:"Transportes integrados de carga"},
    {prodId:"22101528",label:"Cargadores de ruedas"},
    {prodId:"22101529",label:"Cargadores sobre patines con dirección"},
    {prodId:"22101530",label:"Raspadores abiertos"},
    {prodId:"22101531",label:"Cañones para quitar nieve"},
    {prodId:"22101532",label:"Cargadores de orugas"},
    {prodId:"22101533",label:"Arrancadoras de troncos"},
    {prodId:"22101534",label:"Excavadoras de campaña"},
    {prodId:"22101535",label:"Extractor de pilotes"},
    {prodId:"22101536",label:"Lavador de llantas"},
    {prodId:"22101537",label:"Cortadora de pilotes de concreto"},
    {prodId:"22101538",label:"Quitanieves"},
    {prodId:"22101600",label:"Equipo de pavimentación"},
    {prodId:"22101602",label:"Equipo de apisonamiento"},
    {prodId:"22101603",label:"Ensanchadores de carreteras"},
    {prodId:"22101604",label:"Placas vibradoras"},
    {prodId:"22101605",label:"Acabadoras de asfalto"},
    {prodId:"22101606",label:"Esparcidoras de gravilla"},
    {prodId:"22101607",label:"Pavimentadoras"},
    {prodId:"22101608",label:"Aplanadoras en frío"},
    {prodId:"22101609",label:"Mezcladoras de material de pavimentación"},
    {prodId:"22101610",label:"Distribuidoras de agregados"},
    {prodId:"22101611",label:"Distribuidoras de material bituminoso"},
    {prodId:"22101612",label:"Escarificadoras de carreteras"},
    {prodId:"22101613",label:"Aplanadoras calentadoras de la superficie de la carretera"},
    {prodId:"22101614",label:"Revestimientos de pavimento de concreto"},
    {prodId:"22101615",label:"Trituradoras de pavimento"},
    {prodId:"22101616",label:"Máquinas para hacer andenes"},
    {prodId:"22101617",label:"Máquinas de relleno"},
    {prodId:"22101618",label:"Equipos de preparación de superficies de rodamiento o mecanismos para su colocación"},
    {prodId:"22101619",label:"Máquinas pulidoras"},
    {prodId:"22101620",label:"Máquinas de limpieza o acabado de juntas"},
    {prodId:"22101621",label:"Distribuidora de asfalto"},
    {prodId:"22101622",label:"Recicladora de asfalto"},
    {prodId:"22101623",label:"Máquina pavimentadora y de terminados"},
    {prodId:"22101624",label:"Máquina para marcar calles"},
    {prodId:"22101625",label:"Máquina pulidora de concreto"},
    {prodId:"22101626",label:"Planta mezcladora de asfalto"},
    {prodId:"22101627",label:"Fusor de asfalto"},
    {prodId:"22101628",label:"Lavadora de agregados"},
    {prodId:"22101629",label:"Secadora de agregados"},
    {prodId:"22101630",label:"Removedor de marcas de calles"},
    {prodId:"22101631",label:"Guía de piloteadora"},
    {prodId:"22101700",label:"Componentes de equipo pesado"},
    {prodId:"22101701",label:"Palas excavadoras"},
    {prodId:"22101702",label:"Palas mecánicas para el de movimiento de tierra o sus piezas o accesorios"},
    {prodId:"22101703",label:"Cuchillas o dientes u otros filos cortantes"},
    {prodId:"22101704",label:"Escarificadores"},
    {prodId:"22101705",label:"Orugas: eslabones o zapatas o sus piezas"},
    {prodId:"22101706",label:"Cucharas de pala"},
    {prodId:"22101707",label:"Cables de retención"},
    {prodId:"22101708",label:"Extractores"},
    {prodId:"22101709",label:"Cucharones bivalvos"},
    {prodId:"22101710",label:"Remolques quitanieves"},
    {prodId:"22101711",label:"Herramientas de trituración de pavimento o accesorios"},
    {prodId:"22101712",label:"Herramientas de piloteadoras o sus piezas o accesorios"},
    {prodId:"22101713",label:"Brazo de retroexcavadora o secciones del brazo"},
    {prodId:"22101714",label:"Kits de reparación o piezas de apisonadora"},
    {prodId:"22101715",label:"Plantas o alimentadoras de dosificación"},
    {prodId:"22101716",label:"Kits de conversión de maquinaria de construcción"},
    {prodId:"22101717",label:"Vertederas de movimiento de tierra"},
    {prodId:"22101718",label:"Sistemas de control de aplanadora"},
    {prodId:"22101719",label:"Portaherramientas de corredera transversal de aplanadora"},
    {prodId:"22101720",label:"Zapatas perfiladoras de zanjadora"},
    {prodId:"22101721",label:"Brocas y accesorios de la hincadora de estacas"},
    {prodId:"22101722",label:"Cuchara dentada"},
    {prodId:"22101723",label:"Cortador lateral de cuchara"},
    {prodId:"22101724",label:"Cincel cortador hidráulico"},
    {prodId:"22101800",label:"Grúas de elevación"},
    {prodId:"22101801",label:"Manlift o elevador de personal"},
    {prodId:"22101802",label:"Elevador de plataforma"},
    {prodId:"22101803",label:"Elevador de boom articulado"},
    {prodId:"22101804",label:"Elevador de boom telescópico"},
    {prodId:"22101900",label:"Maquinaria y accesorios de construcción de edificios"},
    {prodId:"22101901",label:"Mezcladoras o plantas de concreto"},
    {prodId:"22101902",label:"Mezcladoras de argamasa, pañete o mortero"},
    {prodId:"22101903",label:"Mezcladoras de grada giratoria"},
    {prodId:"22101904",label:"Máquinas de curado"},
    {prodId:"22101905",label:"Distribuidoras de concreto"},
    {prodId:"22101906",label:"Equipo de apuntalamiento"},
    {prodId:"22101907",label:"Puntales de zanja"},
    {prodId:"22101908",label:"Drenaje de cartón o drenaje de pabilo"},
    {prodId:"22102000",label:"Equipo y maquinaria de demolición de edificios"},
    {prodId:"22102001",label:"Kits de equipo de demolición"},
    {prodId:"22102002",label:"Bola para demolición"},
    {prodId:"23101500",label:"Maquinaria para trabajar madera, piedra, cerámica y similares"},
    {prodId:"23101501",label:"Caladoras"},
    {prodId:"23101502",label:"Taladros"},
    {prodId:"23101504",label:"Máquinas dobladoras"},
    {prodId:"23101506",label:"Esmeriladoras"},
    {prodId:"23101507",label:"Troqueladoras"},
    {prodId:"23101508",label:"Cortadoras"},
    {prodId:"23101509",label:"Lijadoras"},
    {prodId:"23101510",label:"Pulidoras"},
    {prodId:"23101511",label:"Máquinas para tornear"},
    {prodId:"23101512",label:"Sierras mecánicas"},
    {prodId:"23101513",label:"Fresadoras"},
    {prodId:"23101514",label:"Cepilladoras"},
    {prodId:"23101515",label:"Máquinas para grabar"},
    {prodId:"23101516",label:"Máquina de martillar con la peña de cuentas de vidrio"},
    {prodId:"23101517",label:"Máquinas de sanblasteado"},
    {prodId:"23101518",label:"Máquina de picar con chorro de perdigones"},
    {prodId:"23101519",label:"Robots"},
    {prodId:"23101520",label:"Máquinas Ram de electro descarga"},
    {prodId:"23101521",label:"Máquina de descarga de electrodo de cátodo de alambre"},
    {prodId:"23101522",label:"Rompevirutas"},
    {prodId:"23101525",label:"Lijadora de huso oscilante"},
    {prodId:"23101528",label:"Lijadora de tambor"},
    {prodId:"23101529",label:"Rodillo esparcidor de goma"},
    {prodId:"23101530",label:"Máquina cola de milano"},
    {prodId:"23101531",label:"Máquina combinada de carpintería"},
    {prodId:"23101532",label:"Máquina espigadora"},
    {prodId:"23101533",label:"Prensa fría"},
    {prodId:"23101534",label:"Aplicador de banda de orillos"},
    {prodId:"23101535",label:"Máquina para unir espesores distintos"},
    {prodId:"23101536",label:"Máquina de clavijas"},
    {prodId:"23101537",label:"Lijadora de banda y disco"},
    {prodId:"23101538",label:"Desbastadora para carpintería"},
    {prodId:"23111500",label:"Maquinaria y equipo para destilado y transformación de petróleo"},
    {prodId:"23111501",label:"Equipo de hidroprocesamiento de destilado"},
    {prodId:"23111502",label:"Máquina para destilación de crudo"},
    {prodId:"23111503",label:"Equipo de ruptura catalítica"},
    {prodId:"23111504",label:"Equipo de hidro - ruptura"},
    {prodId:"23111505",label:"Maquinaria de isomerización"},
    {prodId:"23111506",label:"Máquina coquizadora"},
    {prodId:"23111507",label:"Maquinaria para recuperación de gas"},
    {prodId:"23111600",label:"Maquinaria de hidrotratamiento"},
    {prodId:"23111601",label:"Hidrotratador de nafta"},
    {prodId:"23111602",label:"Hidrotratador de destilado"},
    {prodId:"23111603",label:"Hidrotratador de alimentación catalítica"},
    {prodId:"23111604",label:"Hidrotratador de lubricantes"},
    {prodId:"23111605",label:"Hidrotratador de gasolina"},
    {prodId:"23111606",label:"Hidrotratador de aceite residual"},
    {prodId:"23121500",label:"Maquinaria y accesorios para transformados textiles"},
    {prodId:"23121501",label:"Maquinaria para bordados"},
    {prodId:"23121502",label:"Maquinaria para afelpado"},
    {prodId:"23121503",label:"Devanadoras o encarretadoras"},
    {prodId:"23121504",label:"Máquinas de Torción"},
    {prodId:"23121505",label:"Máquina para pegar puntadas"},
    {prodId:"23121506",label:"Tejedoras"},
    {prodId:"23121507",label:"Urdidoras"},
    {prodId:"23121508",label:"Máquinas de acabados"},
    {prodId:"23121509",label:"Husos"},
    {prodId:"23121510",label:"Máquinas para hacer encaje"},
    {prodId:"23121511",label:"Máquinas para lavar textiles"},
    {prodId:"23121512",label:"Máquinas cardadoras"},
    {prodId:"23121513",label:"Enrolladoras de ensamble de hilaza"},
    {prodId:"23121514",label:"Cortadora de hilaza"},
    {prodId:"23121515",label:"Máquina levantadora"},
    {prodId:"23121516",label:"Máquina quemadora"},
    {prodId:"23121517",label:"Máquina enrolladora fina"},
    {prodId:"23121518",label:"Máquina para desenrollar hebras"},
    {prodId:"23121519",label:"Sistema de hilado de laboratorio"},
    {prodId:"23121520",label:"Máquina combadora"},
    {prodId:"23121600",label:"Maquinaria, equipo y accesorios para trabajo textil"},
    {prodId:"23121601",label:"Máquinas para forrar botones"},
    {prodId:"23121602",label:"Máquinas para coser botones"},
    {prodId:"23121603",label:"Máquinas de hacer ojales"},
    {prodId:"23121604",label:"Máquinas cortadoras de tela"},
    {prodId:"23121605",label:"Máquinas para rellenar cojines"},
    {prodId:"23121606",label:"Máquinas plegadoras o rebobinadoras"},
    {prodId:"23121607",label:"Máquinas blanqueadoras"},
    {prodId:"23121608",label:"Máquinas para doblar telas o paños"},
    {prodId:"23121609",label:"Máquinas bobinadoras o desenrolladoras"},
    {prodId:"23121610",label:"Máquinas para teñir"},
    {prodId:"23121611",label:"Máquinas cortadoras o festoneadoras"},
    {prodId:"23121612",label:"Agujas para máquina de cose"},
    {prodId:"23121613",label:"Máquinas para procesar seda"},
    {prodId:"23121614",label:"Máquinas de coser"},
    {prodId:"23121615",label:"Mesas para cortar telas"},
    {prodId:"23121616",label:"Probadoras de tinturas"},
    {prodId:"23131500",label:"Equipo y suministros de trituración, lijado y pulido"},
    {prodId:"23131501",label:"Compuestos abrasivos"},
    {prodId:"23131502",label:"Ruedas de fieltro"},
    {prodId:"23131503",label:"Ruedas de esmerilado"},
    {prodId:"23131504",label:"Compuestos para brillado"},
    {prodId:"23131505",label:"Cabezas de pulir"},
    {prodId:"23131506",label:"Ruedas para pulir"},
    {prodId:"23131507",label:"Tela para lijar"},
    {prodId:"23131508",label:"Tambores para lijar"},
    {prodId:"23131509",label:"Cilindros o brilladoras"},
    {prodId:"23131510",label:"Suministros o medios de cilindro"},
    {prodId:"23131511",label:"Pivotes de agua"},
    {prodId:"23131512",label:"Bandejas de agua"},
    {prodId:"23131513",label:"Bloques para lijar"},
    {prodId:"23131514",label:"Piedras montadas"},
    {prodId:"23131515",label:"Rectificadora de piedra de moler"},
    {prodId:"23131600",label:"Equipo y accesorios de facetaje"},
    {prodId:"23131601",label:"Accesorios para hacer facetas o pulir caras"},
    {prodId:"23131602",label:"Máquinas para hacer facetas o pulir caras"},
    {prodId:"23131603",label:"Pulidoras para hacer facetas o pulir caras"},
    {prodId:"23131604",label:"Sierras para hacer facetas o pulir caras"},
    {prodId:"23131700",label:"Equipo del cabbing"},
    {prodId:"23131701",label:"Accesorios para hacer cabuchones"},
    {prodId:"23131702",label:"Cinturones para hacer cabuchones"},
    {prodId:"23131703",label:"Discos para hacer cabuchones"},
    {prodId:"23131704",label:"Máquinas para hacer cabuchones"},
    {prodId:"23141600",label:"Maquinaria y accesorios para el preparado del cuero"},
    {prodId:"23141601",label:"Máquinas para descarnar cuero"},
    {prodId:"23141602",label:"Máquinas para curtir cuero"},
    {prodId:"23141603",label:"Máquinas para teñir cuero"},
    {prodId:"23141604",label:"Máquinas para desengrasar cuero"},
    {prodId:"23141605",label:"Prensadoras de cuero"},
    {prodId:"23141606",label:"Máquina escurridora"},
    {prodId:"23141607",label:"Impermeable"},
    {prodId:"23141608",label:"Máquina para quitar el pelo"},
    {prodId:"23141700",label:"Maquinaria y accesorios para trabajar y reparar cuero"},
    {prodId:"23141701",label:"Máquinas cortadoras de cuero"},
    {prodId:"23141702",label:"Máquinas ribeteadoras de cuero"},
    {prodId:"23141703",label:"Máquinas clavadoras de cuero"},
    {prodId:"23141704",label:"Equipo para talabartería"},
    {prodId:"23151500",label:"Maquinaria, equipo y suministros para la elaboración de caucho y plástico"},
    {prodId:"23151501",label:"Máquinas de moldeo por soplado"},
    {prodId:"23151502",label:"Máquinas revestidoras"},
    {prodId:"23151503",label:"Extrusoras"},
    {prodId:"23151504",label:"Máquinas de moldeo por inyección"},
    {prodId:"23151506",label:"Prensas de caucho o plástico"},
    {prodId:"23151507",label:"Máquinas termo modeladoras"},
    {prodId:"23151508",label:"Máquinas para moldear al vacío"},
    {prodId:"23151509",label:"Vulcanizadoras"},
    {prodId:"23151510",label:"Maquinaria para cortar plástico"},
    {prodId:"23151511",label:"Maquinaria para triturar plástico"},
    {prodId:"23151512",label:"Molinos de caucho o plástico"},
    {prodId:"23151513",label:"Moldes de extrusión de caucho o plástico"},
    {prodId:"23151514",label:"Moldes de inyección para plástico"},
    {prodId:"23151515",label:"Moldes de termoformar"},
    {prodId:"23151516",label:"Pasadores del eyector"},
    {prodId:"23151517",label:"Cilindro imd para decoración en molde"},
    {prodId:"23151518",label:"Equipo de moldeo por inmersión"},
    {prodId:"23151519",label:"Equipo de moldeado y procesamiento de uretano"},
    {prodId:"23151520",label:"Equipo de moldeado giratorio"},
    {prodId:"23151600",label:"Maquinaria, equipo y suministros para la industria del cemento, cerámica y vidrio"},
    {prodId:"23151601",label:"Sopladores o secadores"},
    {prodId:"23151602",label:"Trituradoras"},
    {prodId:"23151603",label:"Máquinas de soldadura por fusión o de estirado de vidrio"},
    {prodId:"23151604",label:"Esmeriladoras p pulidoras"},
    {prodId:"23151606",label:"Máquinas moldeadoras de cemento o cerámica o vidrio o similar"},
    {prodId:"23151607",label:"Prensas"},
    {prodId:"23151608",label:"Tamices"},
    {prodId:"23151609",label:"Instrumento para soplar vidrio"},
    {prodId:"23151610",label:"Extractor micropipeta"},
    {prodId:"23151700",label:"Maquinaria, equipo y suministros de la industria óptica"},
    {prodId:"23151701",label:"Máquinas para pulir lentes"},
    {prodId:"23151702",label:"Equipo de medición de lentes"},
    {prodId:"23151703",label:"Equipo de esmerilado de lentes"},
    {prodId:"23151704",label:"Equipos de ensayo de lentes"},
    {prodId:"23151705",label:"Equipo para recubrir el vacío óptico"},
    {prodId:"23151800",label:"Maquinaria, equipo y suministros de la industria farmacéutica"},
    {prodId:"23151801",label:"Equipo para llenado de ampolletas"},
    {prodId:"23151802",label:"Colocadores de tapas, insertadores de algodón o aplicadores de sellos de seguridad"},
    {prodId:"23151803",label:"Máquinas encapsuladoras"},
    {prodId:"23151804",label:"Reactores, fermentadores o digestores"},
    {prodId:"23151805",label:"Máquinas dosificadoras de barrena para llenado o cerrado hermético"},
    {prodId:"23151806",label:"Filtros o ultrafiltros farmacéuticos"},
    {prodId:"23151807",label:"Secadores por congelación o liofilizadores"},
    {prodId:"23151808",label:"Granuladores"},
    {prodId:"23151809",label:"Máquinas farmacéuticas de tamizado"},
    {prodId:"23151810",label:"Máquinas de procesado o llenado estéril o aséptico"},
    {prodId:"23151811",label:"Máquinas para hacer ensayos de tabletas o cápsulas"},
    {prodId:"23151812",label:"Contadores de tabletas"},
    {prodId:"23151813",label:"Máquinas de hacer tabletas"},
    {prodId:"23151814",label:"Equipo de producción de vacunas"},
    {prodId:"23151816",label:"Columnas de cromatografía"},
    {prodId:"23151817",label:"Medios de cromatografía"},
    {prodId:"23151818",label:"Dispositivos de prueba de esterilidad"},
    {prodId:"23151819",label:"Comprobadores de integridad de los filtros"},
    {prodId:"23151821",label:"Adaptador de cartucho filtro"},
    {prodId:"23151822",label:"Adaptadores o conectores o accesorios para soportes de filtros farmacéuticos"},
    {prodId:"23151823",label:"Radiofármaco de diagnóstico"},
    {prodId:"23151824",label:"Equipos o accesorios para hacer compuestos farmacológicos"},
    {prodId:"23151825",label:"Unidad de tanque para filtrar y mezclar"},
    {prodId:"23151900",label:"Maquinaria, equipo y suministros para la elaboración y el procesamiento de papel"},
    {prodId:"23151901",label:"Cortadores"},
    {prodId:"23151902",label:"Rajadores"},
    {prodId:"23151903",label:"Máquinas de lavado o para sacar el agua"},
    {prodId:"23151904",label:"Rebobinadoras"},
    {prodId:"23151905",label:"Máquinas para hacer pulpa de madera"},
    {prodId:"23151906",label:"Calandrias para hacer papel o cartón"},
    {prodId:"23151907",label:"Cedazo o tambor de pantalla"},
    {prodId:"23151908",label:"Refinador mecánico de pulpa"},
    {prodId:"23151909",label:"Máquina para hacer pulpa"},
    {prodId:"23151910",label:"Flotador"},
    {prodId:"23151911",label:"Máquina de papel"},
    {prodId:"23152100",label:"Maquinaria y equipo de separación"},
    {prodId:"23152101",label:"Pantallas o piezas o equipo vibratorios de separación"},
    {prodId:"23152102",label:"Pantallas o piezas o equipo estacionario de separación"},
    {prodId:"23152103",label:"Pantallas o piezas o equipo de clasificación neumática"},
    {prodId:"23152104",label:"Equipo o piezas y pantallas de separación centrífuga"},
    {prodId:"23152105",label:"Vibrador electromecánico"},
    {prodId:"23152106",label:"Pantalla vibratoria de separación de perfil uretano"},
    {prodId:"23152107",label:"Pantalla vibratoria de separación de alambre de perfil"},
    {prodId:"23152108",label:"Pantalla vibratoria de separación de la estampadora"},
    {prodId:"23152109",label:"Pantalla vibratoria de separación de la estampadora recubierta de uretano"},
    {prodId:"23152110",label:"Pantalla vibratoria de separación de alambre de uretano o caucho"},
    {prodId:"23152111",label:"Pantalla vibratoria de separación de alambre tejido"},
    {prodId:"23152112",label:"Pantalla vibratoria de separación de alambre tejido recubierto de uretano"},
    {prodId:"23152113",label:"Tamiz vibratorio"},
    {prodId:"23152200",label:"Mesa y estantes para fabricación"},
    {prodId:"23152201",label:"Mesas rotatorias"},
    {prodId:"23152202",label:"Bancos de apilado"},
    {prodId:"23152203",label:"Bancos de pruebas de componentes o motores"},
    {prodId:"23152204",label:"Resguardo de la máquina"},
    {prodId:"23152205",label:"Mesas de sierra de banda"},
    {prodId:"23152206",label:"Protección de barrera"},
    {prodId:"23152900",label:"Maquinaria empacadora"},
    {prodId:"23152901",label:"Maquinaria de envolver"},
    {prodId:"23152902",label:"Maquinaria de formar, rellenar o sellar"},
    {prodId:"23152903",label:"Empacadora al vacío"},
    {prodId:"23152904",label:"Tolvas para empacar"},
    {prodId:"23152905",label:"Máquinas para el formado de cartón"},
    {prodId:"23152906",label:"Maquinas para encintar"},
    {prodId:"23152907",label:"Suministros para maquinaria de empaque"},
    {prodId:"23152908",label:"Máquina para lavar botellas"},
    {prodId:"23152909",label:"Máquina para organizar materias primas"},
    {prodId:"23152910",label:"Máquina para hacer bolsas automáticas"},
    {prodId:"23153000",label:"Dispositivos y sistemas de guía, posicionamiento y sujeción"},
    {prodId:"23153001",label:"Plantilla de medición"},
    {prodId:"23153002",label:"Plantilla de guía"},
    {prodId:"23153003",label:"Plantilla maestro"},
    {prodId:"23153004",label:"Plantilla de aguja"},
    {prodId:"23153005",label:"Plantilla de eje"},
    {prodId:"23153006",label:"Plantilla de chequeo"},
    {prodId:"23153007",label:"Plantilla de instalación"},
    {prodId:"23153008",label:"Plantilla de cámara"},
    {prodId:"23153009",label:"Plantilla de captación"},
    {prodId:"23153010",label:"Plantilla de eliminación"},
    {prodId:"23153011",label:"Plantilla de boquilla"},
    {prodId:"23153012",label:"Plantilla de deslizamiento"},
    {prodId:"23153013",label:"Plantilla de centrado"},
    {prodId:"23153014",label:"Plantilla de inspección"},
    {prodId:"23153015",label:"Plantilla de alimentador"},
    {prodId:"23153016",label:"Plantilla de embrague"},
    {prodId:"23153017",label:"Plantilla de alineación"},
    {prodId:"23153018",label:"Plantilla de posicionamiento"},
    {prodId:"23153019",label:"Placa elevadora"},
    {prodId:"23153020",label:"Guía de cinta"},
    {prodId:"23153021",label:"Mordaza de alimentación de cinta"},
    {prodId:"23153022",label:"Alimentador mecánico"},
    {prodId:"23153023",label:"Arnés del alimentador"},
    {prodId:"23153024",label:"Mordaza de alimentación"},
    {prodId:"23153025",label:"Agarrador mecánico"},
    {prodId:"23153026",label:"Mordaza de soporte"},
    {prodId:"23153027",label:"Conjunto de mordaza"},
    {prodId:"23153028",label:"Mordaza estacionaria"},
    {prodId:"23153029",label:"Bloque de plantilla"},
    {prodId:"23153030",label:"Guías de desplazamiento lineal"},
    {prodId:"23153031",label:"Plantillas de medición"},
    {prodId:"23153032",label:"Plantilla de anillo"},
    {prodId:"23153033",label:"Placa de garganta"},
    {prodId:"23153034",label:"Rieles de máquina"},
    {prodId:"23153035",label:"Placa"},
    {prodId:"23153036",label:"Placas de cerrojo"},
    {prodId:"23153037",label:"Conjunto de rodillos de alimentación"},
    {prodId:"23153038",label:"Caballete"},
    {prodId:"23153039",label:"Carro de riel de maquinado"},
    {prodId:"23153100",label:"Componentes y accesorios de maquinaria industrial"},
    {prodId:"23153101",label:"Tope de paso"},
    {prodId:"23153103",label:"Almohadilla de topa"},
    {prodId:"23153129",label:"Limpiador de la vía de máquina"},
    {prodId:"23153130",label:"Soportes de máquina o aisladores de vibración"},
    {prodId:"23153131",label:"Placas o barras o cintas de desgaste"},
    {prodId:"23153132",label:"Deflectores de polvo"},
    {prodId:"23153133",label:"Diente de rueda de cadena"},
    {prodId:"23153134",label:"Rodillos de avance o de arrastre"},
    {prodId:"23153135",label:"Cubiertas de rodillos de avance"},
    {prodId:"23153136",label:"Conductores de aserrín"},
    {prodId:"23153137",label:"Cubiertas guardapolvos de máquina"},
    {prodId:"23153138",label:"Cabezales de corte o desbastado"},
    {prodId:"23153139",label:"Camas guía"},
    {prodId:"23153140",label:"Brazos articulados"},
    {prodId:"23153141",label:"Alambre de máquina de electro descarga edm"},
    {prodId:"23153142",label:"Fuelles de acordeón"},
    {prodId:"23153143",label:"Boquillas de rociado"},
    {prodId:"23153144",label:"Bloque de rociado"},
    {prodId:"23153145",label:"Pistola de rociado"},
    {prodId:"23153200",label:"Robótica"},
    {prodId:"23153201",label:"Robots de pintura"},
    {prodId:"23153202",label:"Robots para levantar o poner"},
    {prodId:"23153203",label:"Robots de sellado adhesivo"},
    {prodId:"23153204",label:"Robots de soldadura"},
    {prodId:"23153205",label:"Robot para atender maquinaria"},
    {prodId:"23153206",label:"Robot para remover material"},
    {prodId:"23153207",label:"Robot de limpieza"},
    {prodId:"23153400",label:"Ensambladoras"},
    {prodId:"23153401",label:"Sistemas de aplicación de pegante o adhesivo"},
    {prodId:"23153402",label:"Accesorios de ensamblaje"},
    {prodId:"23153403",label:"Ensamblaje especializado"},
    {prodId:"23153404",label:"Sistemas de ensamblaje para vehículos de chasis (vo)"},
    {prodId:"23153405",label:"Ensamblaje de componentes ilimitados"},
    {prodId:"23153406",label:"Líneas completas de tren motor"},
    {prodId:"23153407",label:"Dispositivo de montaje de superficies"},
    {prodId:"23153408",label:"Prueba de llenado"},
    {prodId:"23153409",label:"Multihusillo fijo de corredera roscada"},
    {prodId:"23153410",label:"Patines de carrocería"},
    {prodId:"23153411",label:"Inflado de montaje de llantas"},
    {prodId:"23153412",label:"Cizalla de guillotina"},
    {prodId:"23153413",label:"Aplicación de recepción (pu) de inserción de vidrio"},
    {prodId:"23153414",label:"Brazos articulados de movimiento giratorio"},
    {prodId:"23153415",label:"Acoplamiento automático del chasis"},
    {prodId:"23153416",label:"Componentes flexibles"},
    {prodId:"23153417",label:"Maquinas diversas para ensamble"},
    {prodId:"23153418",label:"Accesorios o herramientas par ensamble a la medida"},
    {prodId:"23153419",label:"Máquina de estación sencilla a la medida"},
    {prodId:"23153420",label:"Máquina para el ensamble de cables y alambres"},
    {prodId:"23153421",label:"Máquina torcedora o hebradora"},
    {prodId:"23153500",label:"Sistemas de pintura"},
    {prodId:"23153501",label:"Sistemas de aplicación de pintura"},
    {prodId:"23153502",label:"Caseta para reparación de pintura"},
    {prodId:"23153503",label:"Hornos de sistemas de pintura"},
    {prodId:"23153504",label:"Ingeniería o disposición de la planta de pintura"},
    {prodId:"23153505",label:"Sistema de pintura llave en mano"},
    {prodId:"23153506",label:"Sistema de pintura de fosfato o “e coat”"},
    {prodId:"23153507",label:"Taller de pintura variado"},
    {prodId:"23153508",label:"Sistemas diversos de pintura"},
    {prodId:"23153600",label:"Máquinas para el marcado de partes"},
    {prodId:"23153601",label:"Máquinas para el arcado al ácido"},
    {prodId:"23153602",label:"Maquinas para el marcado láser"},
    {prodId:"23153603",label:"Máquinas para el marcado pinstamp"},
    {prodId:"23153604",label:"Herramienta para el marcado láser"},
    {prodId:"23153605",label:"Máquina para el marcado con puntos"},
    {prodId:"23153606",label:"Máquina para el marcado por electro - grabado"},
    {prodId:"23153607",label:"Máquina para el marcado por chorro de tinta"},
    {prodId:"23153608",label:"Máquina para el marcado de tubos electrónicos"},
    {prodId:"23153700",label:"Equipo de aseguramiento o torque de precisión"},
    {prodId:"23153701",label:"Torquímetro de impacto"},
    {prodId:"23153702",label:"Torquímetro"},
    {prodId:"23161500",label:"Máquinas y equipo de fundición"},
    {prodId:"23161501",label:"Fuelles de fundición"},
    {prodId:"23161502",label:"Quemadores de fundición"},
    {prodId:"23161503",label:"Horno de secado del núcleo"},
    {prodId:"23161506",label:"Crisoles de fundición"},
    {prodId:"23161507",label:"Convertidores para fundición"},
    {prodId:"23161510",label:"Máquinas fundidoras"},
    {prodId:"23161514",label:"Prensas de dimensionamiento o grabado en relieve"},
    {prodId:"23161516",label:"Máquina de troqueles de fundición"},
    {prodId:"23161517",label:"Maquina moldeadora fundidora"},
    {prodId:"23161600",label:"Suministros de fundición"},
    {prodId:"23161601",label:"Fuelles de fundición"},
    {prodId:"23161602",label:"Arcillas de fundición"},
    {prodId:"23161603",label:"Frascos de fundición"},
    {prodId:"23161605",label:"Cucharas de fundición"},
    {prodId:"23161606",label:"Troqueles de fundición"},
    {prodId:"23161607",label:"Arena de fundición"},
    {prodId:"23161608",label:"Palas de fundición"},
    {prodId:"23161700",label:"Troqueles y herramientas de fundición"},
    {prodId:"23161701",label:"Caja para la formación de núcleos de arena"},
    {prodId:"23161702",label:"Patrón de formación de troqueles de arena"},
    {prodId:"23181500",label:"Maquinaria para la elaboración de alimentos"},
    {prodId:"23181501",label:"Maquinaria de llenado"},
    {prodId:"23181502",label:"Maquinaria de molido"},
    {prodId:"23181504",label:"Maquinaria de tamizado"},
    {prodId:"23181505",label:"Maquinaria para deshidratación"},
    {prodId:"23181506",label:"Maquinaria de lavado"},
    {prodId:"23181507",label:"Maquinaria de triturado"},
    {prodId:"23181508",label:"Máquinas blanqueadoras"},
    {prodId:"23181509",label:"Maquinaria de clasificación"},
    {prodId:"23181510",label:"Atadoras de carne"},
    {prodId:"23181511",label:"Conformadora"},
    {prodId:"23181512",label:"Enfriadora"},
    {prodId:"23181513",label:"Preespolvoreadora"},
    {prodId:"23181514",label:"Máquina para elaboración de queso"},
    {prodId:"23181515",label:"Máquina para elaborar tofu (queso de soya)"},
    {prodId:"23181516",label:"Máquina para elaborar fideos"},
    {prodId:"23181517",label:"Máquina para elaborar frijol de soya fermentado"},
    {prodId:"23181600",label:"Maquinaria para corte de alimentos"},
    {prodId:"23181601",label:"Maquinaria para cortar en cubos"},
    {prodId:"23181602",label:"Máquina tajadora"},
    {prodId:"23181603",label:"Máquina picadora"},
    {prodId:"23181604",label:"Máquina cortadora"},
    {prodId:"23181605",label:"Máquina ralladora"},
    {prodId:"23181606",label:"Máquina peladora"},
    {prodId:"23181700",label:"Maquinaria para cocinar o ahumar alimentos"},
    {prodId:"23181701",label:"Máquina para ahumar"},
    {prodId:"23181702",label:"Maquinaria para asar"},
    {prodId:"23181703",label:"Maquinaria para cocinar"},
    {prodId:"23181704",label:"Máquina para cocinar al vapor"},
    {prodId:"23181705",label:"Máquina para esterilizar alimentos"},
    {prodId:"23181800",label:"Maquinaria industrial para elaboración de bebidas"},
    {prodId:"23181801",label:"Equipo y suministros para elaboración de café"},
    {prodId:"23181802",label:"Maquinaria para elaboración de zumos"},
    {prodId:"23181803",label:"Máquinas para hacer hielo"},
    {prodId:"23181804",label:"Máquinas para hacer helados"},
    {prodId:"23181805",label:"Partes y accesorios de máquinas para hacer hielo"},
    {prodId:"23181806",label:"Esterilizador de bebidas"},
    {prodId:"23191000",label:"Mezcladores por tandas"},
    {prodId:"23191001",label:"Mezcladora cambiadora"},
    {prodId:"23191002",label:"Mezcladora de cuchilla helicoidal"},
    {prodId:"23191003",label:"Mezcladora con doble brazo amasador"},
    {prodId:"23191004",label:"Mezcladoras intensivas"},
    {prodId:"23191005",label:"Mezcladoras de rodillo"},
    {prodId:"23191006",label:"Mezcladora de arena"},
    {prodId:"23191100",label:"Mezcladores continuos"},
    {prodId:"23191101",label:"Mezcladora de tornillo sencillo"},
    {prodId:"23191102",label:"Mezcladora de tornillo doble"},
    {prodId:"23191200",label:"Piezas de mezcladora y accesorios"},
    {prodId:"23191201",label:"Rastrillos de mezcladora"},
    {prodId:"23191202",label:"Cuchillas de mezcladora"},
    {prodId:"23201000",label:"Sistemas de contacto líquido gas"},
    {prodId:"23201001",label:"Columnas de placa"},
    {prodId:"23201002",label:"Columnas cargadas"},
    {prodId:"23201003",label:"Contacto de líquido disperso"},
    {prodId:"23201004",label:"Columna de pared mojada"},
    {prodId:"23201005",label:"Columnas de burbuja"},
    {prodId:"23201006",label:"Columna de destilación"},
    {prodId:"23201007",label:"Columna cargada de destilación"},
    {prodId:"23201008",label:"Bandeja de destilación"},
    {prodId:"23201100",label:"Absorción e intercambio de iones"},
    {prodId:"23201101",label:"Recipiente absorbente"},
    {prodId:"23201102",label:"Recipiente absorbente a presión ambiental"},
    {prodId:"23201200",label:"Equipo de secado industrial"},
    {prodId:"23201201",label:"Secadores atomizadores"},
    {prodId:"23201202",label:"Secadores de aire"},
    {prodId:"23201203",label:"Secadores de lecho fluido"},
    {prodId:"23201204",label:"Equipo para el secado de comida"},
    {prodId:"23211000",label:"Maquinaria de ensamblaje electrónico y equipo de soporte"},
    {prodId:"23211001",label:"Colocadores de chips"},
    {prodId:"23211002",label:"Maquinaria suministradora de pegamento"},
    {prodId:"23211003",label:"Maquina de inserción de terminales"},
    {prodId:"23211100",label:"Maquinaria de procesamiento y fabricación electrónica"},
    {prodId:"23211101",label:"Sistemas de procesamiento de semiconductores"},
    {prodId:"23211102",label:"Máquina para hacer tarjetas de circuitos impresos"},
    {prodId:"23211103",label:"Pegador de alambre al wafer"},
    {prodId:"23211104",label:"Monitor de inspección de chip semiconductor"},
    {prodId:"23211105",label:"Dispositivo para sellar porosidad o impregnación de vacío"},
    {prodId:"23211106",label:"Implantador de iones"},
    {prodId:"23231000",label:"Descortezadoras y accesorios"},
    {prodId:"23231001",label:"Portaherramientas"},
    {prodId:"23231002",label:"Herramienta de carburo"},
    {prodId:"23231100",label:"Sierras de cinta y accesorios"},
    {prodId:"23231101",label:"Rueda de sierra de cinta"},
    {prodId:"23231102",label:"Guía de sierra"},
    {prodId:"23231200",label:"Sierras circulares y accesorios"},
    {prodId:"23231201",label:"Espaciador de sierra"},
    {prodId:"23231202",label:"Eje de sierra"},
    {prodId:"23231300",label:"Canteadoras"},
    {prodId:"23231301",label:"Rodillo alimentador"},
    {prodId:"23231302",label:"Rodillo de sujeción"},
    {prodId:"23231400",label:"Sierras de recorte y accesorios"},
    {prodId:"23231401",label:"Guía de alineación de la madera"},
    {prodId:"23231402",label:"Sierra cero"},
    {prodId:"23231500",label:"Clasificadoras de maderos y accesorios"},
    {prodId:"23231501",label:"Barras portasierra"},
    {prodId:"23231502",label:"Eslinga de contenedor"},
    {prodId:"23231600",label:"Apiladoras de maderos y accesorios"},
    {prodId:"23231601",label:"Soporte de reborde"},
    {prodId:"23231602",label:"Rodillo de terminación"},
    {prodId:"23231700",label:"Secaderos de madera y equipo y accesorios"},
    {prodId:"23231701",label:"Deflector"},
    {prodId:"23231900",label:"Cepilladoras y accesorios"},
    {prodId:"23231901",label:"Lecho base"},
    {prodId:"23231902",label:"Placa trasera"},
    {prodId:"23231903",label:"Guía refrigerada por agua"},
    {prodId:"23232000",label:"Terminadoras de madera y accesorios"},
    {prodId:"23232001",label:"Porta cuchillas"},
    {prodId:"23232100",label:"Sierras retestadoras y accesorios"},
    {prodId:"23232101",label:"Soporte guía ajustable"},
    {prodId:"23232200",label:"Empalmadoras por entalladura múltiple y accesorios"},
    {prodId:"23232201",label:"Boquilla de pegamento"},
    {prodId:"23241400",label:"Máquinas rectificadoras de metales"},
    {prodId:"23241401",label:"Rectificador de banco"},
    {prodId:"23241402",label:"Máquina de rectificar superficies"},
    {prodId:"23241403",label:"Maquina de rectificación interna"},
    {prodId:"23241404",label:"Maquina de rectificación externa"},
    {prodId:"23241405",label:"Maquina de rectificación sin centro"},
    {prodId:"23241406",label:"Maquina ultrasónica"},
    {prodId:"23241407",label:"Máquina de rectificación cilíndrica"},
    {prodId:"23241408",label:"Máquina de rectificación de cuchillo"},
    {prodId:"23241409",label:"Máquina de rectificación de perfil óptico"},
    {prodId:"23241410",label:"Máquina de rectificación de tornillo sinfín"},
    {prodId:"23241411",label:"Máquina pulidora"},
    {prodId:"23241500",label:"Máquinas cortadoras de metales"},
    {prodId:"23241501",label:"Equipo de maquinado abrasivo a chorro"},
    {prodId:"23241502",label:"Máquina electromecánica ecm"},
    {prodId:"23241503",label:"Máquina de cortado con llama"},
    {prodId:"23241504",label:"Herramienta de corte de engranajes"},
    {prodId:"23241505",label:"Máquina láser para cortar"},
    {prodId:"23241506",label:"Máquina de corte plasma"},
    {prodId:"23241507",label:"Máquina de aserrado y corte"},
    {prodId:"23241508",label:"Máquina de corte con chorro de agua"},
    {prodId:"23241509",label:"Máquina de sierra de banda para metal"},
    {prodId:"23241510",label:"Máquina cizalladora de metal"},
    {prodId:"23241511",label:"Máquina para hacer roscas"},
    {prodId:"23241600",label:"Herramientas cortadoras de metales"},
    {prodId:"23241601",label:"Herramienta perforador"},
    {prodId:"23241602",label:"Punzón"},
    {prodId:"23241603",label:"Máquina para avellanado"},
    {prodId:"23241605",label:"Carburo de perforación"},
    {prodId:"23241606",label:"Taladros de acero de alta velocidad"},
    {prodId:"23241608",label:"Cortador de quemador"},
    {prodId:"23241609",label:"Cuchillos y biseles"},
    {prodId:"23241610",label:"Cortador de tubos"},
    {prodId:"23241611",label:"Escariador"},
    {prodId:"23241612",label:"Cortador de formas"},
    {prodId:"23241613",label:"Cortador de cepillado"},
    {prodId:"23241614",label:"Fresadora sólida"},
    {prodId:"23241615",label:"Grifos"},
    {prodId:"23241616",label:"Cortador de cable o alambre"},
    {prodId:"23241617",label:"Escariador de hoyos para pines"},
    {prodId:"23241618",label:"Escariador alineador de hoyos"},
    {prodId:"23241619",label:"Escariador de tubos"},
    {prodId:"23241620",label:"Escariador de concha"},
    {prodId:"23241621",label:"Cuchilla de sierra de varilla"},
    {prodId:"23241622",label:"Cortador de ángulo sencillo"},
    {prodId:"23241623",label:"Fresadora lateral"},
    {prodId:"23241624",label:"Fresadora cilíndrica plana"},
    {prodId:"23241625",label:"Fresadora de concha para extremos"},
    {prodId:"23241626",label:"Cortador convexo"},
    {prodId:"23241627",label:"Cortador cóncavo"},
    {prodId:"23241628",label:"Cortador redondeador de esquinas"},
    {prodId:"23241629",label:"Sierra para hacer ranuras en metal"},
    {prodId:"23241630",label:"Cortador de ranuras en t"},
    {prodId:"23241631",label:"Cortador de asiento woodruff"},
    {prodId:"23241632",label:"Cortador de cola de milano"},
    {prodId:"23241633",label:"Cortador conformador de engranajes"},
    {prodId:"23241634",label:"Taladro largo"},
    {prodId:"23241635",label:"Taladro de precisión"},
    {prodId:"23241636",label:"Taladros de herrero"},
    {prodId:"23241637",label:"Taladro de hoyo profundo"},
    {prodId:"23241638",label:"Cortador de hoyos con punta de carburo"},
    {prodId:"23241639",label:"Taladro de huso"},
    {prodId:"23241640",label:"Taladro multi - pasos"},
    {prodId:"23241641",label:"Taladro multi paso hex"},
    {prodId:"23241642",label:"Roscadora de velocidad"},
    {prodId:"23241643",label:"Escariador largo"},
    {prodId:"23241644",label:"Láser de dióxido de carbono"},
    {prodId:"23241645",label:"Escariador limador de precisión de agujeros"},
    {prodId:"23241646",label:"Escariador ajustable"},
    {prodId:"23241647",label:"Escariador cónico de agujero de precisión"},
    {prodId:"23241800",label:"Máquinas taladradoras de metales"},
    {prodId:"23241801",label:"Taladro radial o prensa de taladro"},
    {prodId:"23241802",label:"Taladro de segmentos"},
    {prodId:"23241803",label:"Taladro de cañón"},
    {prodId:"23241804",label:"Taladro controlado numéricamente"},
    {prodId:"23241805",label:"Máquina de taladro de mesa rotativa"},
    {prodId:"23241806",label:"Máquina de taladro de múltiples cabezas de huso"},
    {prodId:"23241807",label:"Máquina de taladro de brazo radial"},
    {prodId:"23241808",label:"Máquina de taladro de hoyo profundo"},
    {prodId:"23241809",label:"Máquina de taladro de banco"},
    {prodId:"23241900",label:"Máquinas perforadoras de metales"},
    {prodId:"23241901",label:"Máquina perforadora original"},
    {prodId:"23241902",label:"Máquina perforadora con plantilla"},
    {prodId:"23241903",label:"Máquinas para dar formas al metal"},
    {prodId:"23241904",label:"Máquinas para dar formas internas al metal"},
    {prodId:"23241905",label:"Máquinas para dar formas al metal en la superficie"},
    {prodId:"23241906",label:"Máquina perforadora vertical"},
    {prodId:"23242100",label:"Accesorios para máquinas de cortar metales"},
    {prodId:"23242101",label:"Cortadora de barras o varillas"},
    {prodId:"23242102",label:"Soporte de electrodos"},
    {prodId:"23242103",label:"Relieve de forma"},
    {prodId:"23242104",label:"Herramientas o brocas de formado"},
    {prodId:"23242105",label:"Inserto indexable"},
    {prodId:"23242106",label:"Cuerpos o soportes de herramientas indexables"},
    {prodId:"23242107",label:"Inserto de carburo"},
    {prodId:"23242108",label:"Inserto de cerámica"},
    {prodId:"23242109",label:"Inserto de diamante"},
    {prodId:"23242110",label:"Inserto de acero"},
    {prodId:"23242111",label:"Torreta de torno"},
    {prodId:"23242112",label:"Cuchilla de sierra de banda para cortar metal"},
    {prodId:"23242113",label:"Cuchilla de sierra circular para cortar metal"},
    {prodId:"23242114",label:"Soportes firmes"},
    {prodId:"23242115",label:"Tailstock"},
    {prodId:"23242116",label:"Anexo de máquina de enroscado"},
    {prodId:"23242117",label:"Anexo de máquina de hacer roscas"},
    {prodId:"23242118",label:"Herramienta de moleteado"},
    {prodId:"23242119",label:"Broca de torno"},
    {prodId:"23242120",label:"Base de tabla de máquina"},
    {prodId:"23242300",label:"Tornos y centros de torneado"},
    {prodId:"23242301",label:"Máquina  automática de barra"},
    {prodId:"23242302",label:"Torno o máquina sujetadora automática"},
    {prodId:"23242303",label:"Centro giratorio horizontal"},
    {prodId:"23242304",label:"Torno para duplicar contornos o trazadora"},
    {prodId:"23242305",label:"Torno de torreta"},
    {prodId:"23242306",label:"Centro giratorio vertical"},
    {prodId:"23242307",label:"Tornillo de avance"},
    {prodId:"23242308",label:"Máquina centradora"},
    {prodId:"23242400",label:"Centros de maquinado"},
    {prodId:"23242401",label:"Centro maquinado horizontal"},
    {prodId:"23242402",label:"Centro maquinado universal o multi - tarea"},
    {prodId:"23242403",label:"Centro maquinado vertical"},
    {prodId:"23242500",label:"Máquinas para el fresado de metales"},
    {prodId:"23242501",label:"Máquina fresadora de lecho"},
    {prodId:"23242502",label:"Máquina fresadora de puente"},
    {prodId:"23242503",label:"Máquina fresadora de columna y rodilla"},
    {prodId:"23242504",label:"Máquina fresadora de pórtico"},
    {prodId:"23242505",label:"Máquina fresadora de perfilado y duplicación"},
    {prodId:"23242506",label:"Máquina fresadora de columna desplazada"},
    {prodId:"23242507",label:"Máquina fresadora universal"},
    {prodId:"23242508",label:"Máquina fresadora de copia"},
    {prodId:"23242509",label:"Máquina fresadora de torreta"},
    {prodId:"23242510",label:"Máquina fresadora de estilo planeador"},
    {prodId:"23242511",label:"Máquina fresadora de extremo"},
    {prodId:"23242600",label:"Maquinas para el cortado de metales especializados o diversos"},
    {prodId:"23242601",label:"Máquina biseladora"},
    {prodId:"23242602",label:"Máquina de bruñido"},
    {prodId:"23242603",label:"Máquina para hacer chaflanes"},
    {prodId:"23242604",label:"Máquina para lapeado"},
    {prodId:"23242605",label:"Máquina pulidora de metal"},
    {prodId:"23242606",label:"Máquina de procesamiento en capas delgadas"},
    {prodId:"23242607",label:"Máquina de súper  - terminado"},
    {prodId:"23242608",label:"Preseteador de herramientas"},
    {prodId:"23242609",label:"Máquina para perfeccionado"},
    {prodId:"23242610",label:"Maquina de dimensionamiento de hoyos de diamante"},
    {prodId:"23242611",label:"Máquina para hacer bordes"},
    {prodId:"23242612",label:"Máquina para grabado de metales"},
    {prodId:"23242613",label:"Máquina para hacer tornillos hembra"},
    {prodId:"23242614",label:"Máquina para hacer llavería"},
    {prodId:"23242615",label:"Máquina para hacer surcos en metal"},
    {prodId:"23251500",label:"Maquinaria para tubulares doblados"},
    {prodId:"23251501",label:"Freno de prensa hidráulica"},
    {prodId:"23251502",label:"Freno de prensa manual"},
    {prodId:"23251503",label:"Máquina para el doblado de metal"},
    {prodId:"23251504",label:"Doblador de tangentes"},
    {prodId:"23251505",label:"Máquina dobladora de tubos"},
    {prodId:"23251506",label:"Terminador de puntas de tubos"},
    {prodId:"23251507",label:"Doblador de alas"},
    {prodId:"23251508",label:"Máquina dobladora de barras de acero"},
    {prodId:"23251509",label:"Máquina para reducir diámetro de alambres"},
    {prodId:"23251600",label:"Máquinas para enrollado de metales"},
    {prodId:"23251601",label:"Máquina enrolladora de hebras"},
    {prodId:"23251602",label:"Máquina para el formado de láminas metálicas"},
    {prodId:"23251603",label:"Prensa de rodillos"},
    {prodId:"23251700",label:"Máquinas de forjado"},
    {prodId:"23251701",label:"Prensa de forja de cizalla"},
    {prodId:"23251702",label:"Prensa de forja de impresión y troquel cerrado"},
    {prodId:"23251703",label:"Prensa de forja de troquel abierto"},
    {prodId:"23251704",label:"Máquinas de forjado radial"},
    {prodId:"23251705",label:"Máquina de forjado rollo"},
    {prodId:"23251706",label:"Rebabas giratorias"},
    {prodId:"23251707",label:"Prensa de recortado"},
    {prodId:"23251708",label:"Máquina de forjado de alta velocidad"},
    {prodId:"23251709",label:"Máquina de forjado de martillo de aire"},
    {prodId:"23251710",label:"Máquina de forjado de martillo de resorte"},
    {prodId:"23251711",label:"Máquina de forjado de martillo a vapor"},
    {prodId:"23251712",label:"Máquina de forjado de martillo de caída"},
    {prodId:"23251713",label:"Máquina de forjado de terminaciones prensadas"},
    {prodId:"23251714",label:"Forja de compresión o prensa de manivela"},
    {prodId:"23251800",label:"Troqueles y herramientas para el formado de metales"},
    {prodId:"23251801",label:"Troquel de doblado"},
    {prodId:"23251802",label:"Troquel de corte"},
    {prodId:"23251803",label:"Troquel de fundición a presión"},
    {prodId:"23251804",label:"Troquel de terminado de bordes"},
    {prodId:"23251805",label:"Troquel de forja"},
    {prodId:"23251806",label:"Troquel de extrusión de metal"},
    {prodId:"23251807",label:"Sello de metal"},
    {prodId:"23251808",label:"Mandril para el doblado de tubos"},
    {prodId:"23251809",label:"Troquel giratorio"},
    {prodId:"23251810",label:"Troquel de estampado"},
    {prodId:"23251811",label:"Troquel de regla de acero"},
    {prodId:"23251812",label:"Troquel de roscado"},
    {prodId:"23251813",label:"Troquel de cableado"},
    {prodId:"23251814",label:"Troquel de dibujo"},
    {prodId:"23261500",label:"Máquinas para prototipos rápidos"},
    {prodId:"23261501",label:"Máquina de modelado  por deposición con fusible fdm"},
    {prodId:"23261502",label:"Máquina de método de chorro de tinta"},
    {prodId:"23261503",label:"Máquina para la manufactura de objeto laminado"},
    {prodId:"23261504",label:"Máquina láser para la formación de polvo"},
    {prodId:"23261505",label:"Máquina láser selectiva de sinterización"},
    {prodId:"23261506",label:"Máquina de estero - litografía"},
    {prodId:"23261507",label:"Máquina impresora tridimensional"},
    {prodId:"23271400",label:"Máquinas soldadoras"},
    {prodId:"23271401",label:"Máquina para soldadura arco sumergida"},
    {prodId:"23271402",label:"Máquina de soldadura ultrasónica"},
    {prodId:"23271403",label:"Máquina de soldadura de proyección"},
    {prodId:"23271404",label:"Máquina de soldadura arco plasma"},
    {prodId:"23271405",label:"Máquina de soldadura láser"},
    {prodId:"23271406",label:"Máquina de soldadura aislada"},
    {prodId:"23271407",label:"Máquina de soldadura de gas inerte de tungsteno"},
    {prodId:"23271408",label:"Máquina de soldadura de gas inerte de metal"},
    {prodId:"23271409",label:"Máquina de soldadura de barra o soldadura arco metálico protegida"},
    {prodId:"23271410",label:"Rectificador de soldadura"},
    {prodId:"23271411",label:"Soldadura plástica"},
    {prodId:"23271412",label:"Soldadura termita"},
    {prodId:"23271413",label:"Máquina de soldadura de rayo electrón ebw"},
    {prodId:"23271414",label:"Máquina de soldadura electro escoria esw"},
    {prodId:"23271415",label:"Máquina de soldadura de contacto o presión fría"},
    {prodId:"23271416",label:"Máquina de soldadura de fricción fw"},
    {prodId:"23271417",label:"Máquina de soldadura con estaño"},
    {prodId:"23271418",label:"Máquina de soldadura por junta eléctrica"},
    {prodId:"23271419",label:"Soldadura con recalado"},
    {prodId:"23271420",label:"Soldadura de cordón"},
    {prodId:"23271421",label:"Soldadura de cuchilla de sierra de banda"},
    {prodId:"23271422",label:"Soldadura de montantes o soldadura arco de montantes"},
    {prodId:"23271423",label:"Soldadura de arco de corriente alterna ac"},
    {prodId:"23271424",label:"Soldadura de arco de corriente directa dc"},
    {prodId:"23271500",label:"Maquinaria de soldadura fuerte"},
    {prodId:"23271501",label:"Máquina de soldadura fuerte para hornos"},
    {prodId:"23271502",label:"Máquina de soldadura fuerte por inducción"},
    {prodId:"23271600",label:"Maquinaria de soldadura débil"},
    {prodId:"23271601",label:"Máquina de soldadura débil para hornos"},
    {prodId:"23271602",label:"Máquina de soldadura por inducción"},
    {prodId:"23271603",label:"Hierro de soldadura débil"},
    {prodId:"23271604",label:"Máquina de soldadura de onda"},
    {prodId:"23271605",label:"Horno de reflujo"},
    {prodId:"23271606",label:"Máquina de soldadura débil selectiva"},
    {prodId:"23271607",label:"Máquina automática de soldadura débil"},
    {prodId:"23271700",label:"Accesorios para soldar, soldadura fuerte y soldadura débil"},
    {prodId:"23271701",label:"Soplete"},
    {prodId:"23271702",label:"Soplete grande"},
    {prodId:"23271703",label:"Anillo de soldadura"},
    {prodId:"23271704",label:"Pistola para des - soldar"},
    {prodId:"23271705",label:"Bomba para des - soldar"},
    {prodId:"23271706",label:"Punta para des - soldar"},
    {prodId:"23271707",label:"Aparato para soldar o soldar fuerte o cortar  gas"},
    {prodId:"23271708",label:"Barra indicadora de temperatura"},
    {prodId:"23271709",label:"Generador de soldadura"},
    {prodId:"23271710",label:"Lima limpiadora de soldadura o soldadura fuerte"},
    {prodId:"23271711",label:"Punta de soldadura o corte"},
    {prodId:"23271712",label:"Kit de soldadura  o soldadura débil"},
    {prodId:"23271713",label:"Manipulador y posicionador de soldadura"},
    {prodId:"23271714",label:"Pantalla de soldadura"},
    {prodId:"23271715",label:"Cuchilla perfiladora de punta de soldadura"},
    {prodId:"23271716",label:"Accesorios de perfilación de punta de soldadura"},
    {prodId:"23271717",label:"Supresor de retorno"},
    {prodId:"23271718",label:"Protector para soldadura"},
    {prodId:"23271800",label:"Suministros para soldar, soldadura fuerte y soldadura débil"},
    {prodId:"23271801",label:"Espray anti - salpicaduras"},
    {prodId:"23271802",label:"Fundente de soldadura fuerte"},
    {prodId:"23271803",label:"Desoldador de trenza"},
    {prodId:"23271804",label:"Varilla de soldadura fuerte o soldadura de gas"},
    {prodId:"23271805",label:"Polvo de hierro"},
    {prodId:"23271806",label:"Cautín"},
    {prodId:"23271807",label:"Fluido de soldadura"},
    {prodId:"23271808",label:"Fundente de soldadura débil"},
    {prodId:"23271809",label:"Pinta de soldadura"},
    {prodId:"23271810",label:"Electrodo de soldadura"},
    {prodId:"23271811",label:"Fundente de soldadura"},
    {prodId:"23271812",label:"Barra de soldadura"},
    {prodId:"23271813",label:"Alambre de soldadura"},
    {prodId:"23271814",label:"Electrodo de corte"},
    {prodId:"23271815",label:"Barra de cautín"},
    {prodId:"23271816",label:"Pasta de cautín"},
    {prodId:"23271817",label:"Polvo de cautín"},
    {prodId:"23271818",label:"Alambre de cautín"},
    {prodId:"23271819",label:"Fundente de unión de metal"},
    {prodId:"23271820",label:"Estabilizador de humedad de electrodo de soldadura"},
    {prodId:"23271821",label:"Soplete de soldadura"},
    {prodId:"23281500",label:"Máquinas para recubrir o platear"},
    {prodId:"23281501",label:"Máquina anodizdora"},
    {prodId:"23281502",label:"Máquina de baño electrolítico"},
    {prodId:"23281503",label:"Máquina de rociado térmico"},
    {prodId:"23281504",label:"Máquina de plateado de barril"},
    {prodId:"23281600",label:"Máquinas para tratamiento de calor"},
    {prodId:"23281601",label:"Máquina de inducción de calentamiento"},
    {prodId:"23281602",label:"Máquina de enfriamiento rápido"},
    {prodId:"23281603",label:"Horno para endurecimiento durante tratamiento de calor"},
    {prodId:"23281700",label:"Máquinas para tratamiento mecánico de superficie"},
    {prodId:"23281701",label:"Máquina de revestimiento"},
    {prodId:"23281702",label:"Máquina de perdigonado"},
    {prodId:"23281703",label:"Máquina de arena (sand blast)"},
    {prodId:"23281704",label:"Máquina de electro descarga para endurecimiento de superficies"},
    {prodId:"23281800",label:"Accesorios y herramientas para el tratamiento de metal"},
    {prodId:"23281801",label:"Parrilla de galvanoplastia"},
    {prodId:"23281802",label:"Parrilla de deposición de calor físico"},
    {prodId:"23281900",label:"Maquinaria y suministros para la limpieza del metal"},
    {prodId:"23281901",label:"Limpiador electroquímico de metal"},
    {prodId:"23281902",label:"Limpiador químico de metal"},
    {prodId:"23281903",label:"Limpiador de metal mecánico o ultrasónico"},
    {prodId:"23281904",label:"Equipo de lavado y limpiado acuoso"},
    {prodId:"23281905",label:"Equipo de desengrase térmico"},
    {prodId:"23291500",label:"Herramientas de perforación industrial"},
    {prodId:"23291501",label:"Taladro soldado"},
    {prodId:"23291502",label:"Taladro de parte superior o frontal intercambiable"},
    {prodId:"23291503",label:"Taladro de inserto indexable"},
    {prodId:"23291504",label:"Molino de parte superior o frontal intercambiable"},
    {prodId:"23291700",label:"Herramientas de escariado industrial"},
    {prodId:"23291701",label:"Cuchilla de escariador"},
    {prodId:"23291702",label:"Escariador soldado"},
    {prodId:"23291703",label:"Escariador sólido"},
    {prodId:"23291704",label:"Escariador indexable"},
    {prodId:"23291800",label:"Herramientas de roscado industrial"},
    {prodId:"23291801",label:"Soporte de roscado axial"},
    {prodId:"23291802",label:"Soporte de roscado externo"},
    {prodId:"23291803",label:"Soporte de roscado interno"},
    {prodId:"23291804",label:"Inserto de roscado"},
    {prodId:"23291900",label:"Herramientas de barrenado industrial"},
    {prodId:"23291901",label:"Cabeza de perforación"},
    {prodId:"23291902",label:"Herramienta de perforación burda"},
    {prodId:"23301500",label:"Maquinaria, equipos y accesorios para el trabajo de alambre"},
    {prodId:"23301501",label:"Equipo para cortado de cable y alambre y ensamble de terminales"},
    {prodId:"24101500",label:"Camiones industriales"},
    {prodId:"24101501",label:"Carretas"},
    {prodId:"24101502",label:"Vehículos de transporte a grane"},
    {prodId:"24101503",label:"Vehículo dolly"},
    {prodId:"24101504",label:"Carretones de mano o accesorios"},
    {prodId:"24101505",label:"Camiones de pallets"},
    {prodId:"24101506",label:"Carretas de empujar"},
    {prodId:"24101507",label:"Carretillas"},
    {prodId:"24101508",label:"Plataformas rodantes"},
    {prodId:"24101509",label:"Vagones"},
    {prodId:"24101510",label:"Contenedor de basura plástico"},
    {prodId:"24101511",label:"Trolleys de estante"},
    {prodId:"24101512",label:"Bugies eléctricos"},
    {prodId:"24101513",label:"Remolcador"},
    {prodId:"24101514",label:"Camión plataforma"},
    {prodId:"24101515",label:"Camión pórtico"},
    {prodId:"24101516",label:"Plataforma auto propulsada"},
    {prodId:"24101517",label:"Tractor de remolque eléctrico"},
    {prodId:"24101600",label:"Equipo de izaje y accesorios"},
    {prodId:"24101601",label:"Ascensores"},
    {prodId:"24101602",label:"Montacargas"},
    {prodId:"24101603",label:"Elevadores forklift"},
    {prodId:"24101604",label:"Elevadores"},
    {prodId:"24101605",label:"Equipo de carga"},
    {prodId:"24101606",label:"Apiladoras"},
    {prodId:"24101608",label:"Malacates o güinches"},
    {prodId:"24101609",label:"Inclinadores"},
    {prodId:"24101610",label:"Manipuladores"},
    {prodId:"24101611",label:"Eslingas"},
    {prodId:"24101612",label:"Gatos"},
    {prodId:"24101613",label:"Bloques o poleas"},
    {prodId:"24101614",label:"Sacos de aire para cargar"},
    {prodId:"24101615",label:"Rampas de carga"},
    {prodId:"24101616",label:"Dispositivo debajo de gancho"},
    {prodId:"24101617",label:"Elevador de tijera"},
    {prodId:"24101618",label:"Instalador de tuberías"},
    {prodId:"24101619",label:"Puentes grúas"},
    {prodId:"24101620",label:"Camión grúas"},
    {prodId:"24101621",label:"Grúas todo terreno"},
    {prodId:"24101622",label:"Grúas para terrenos difíciles"},
    {prodId:"24101623",label:"Torre grúas"},
    {prodId:"24101624",label:"Grúas hidráulicas sobre camión"},
    {prodId:"24101625",label:"Camión grúas convencionales"},
    {prodId:"24101626",label:"Escaleras mecánicas o cintas rodantes"},
    {prodId:"24101627",label:"Troleys de viga"},
    {prodId:"24101628",label:"Horquillas ajustables"},
    {prodId:"24101629",label:"Accesorios o suministros de elevador forklift o transportador vertical"},
    {prodId:"24101630",label:"Grúas de taller"},
    {prodId:"24101631",label:"Tazas de succión"},
    {prodId:"24101632",label:"Izajes laterales"},
    {prodId:"24101633",label:"Tambores de elevación"},
    {prodId:"24101634",label:"Sacos de cadena"},
    {prodId:"24101635",label:"Gatos de tornillo"},
    {prodId:"24101636",label:"Bolsas de contrapeso y contrapesos"},
    {prodId:"24101637",label:"Eslinga de material de nylon"},
    {prodId:"24101638",label:"Eslinga de cuerda"},
    {prodId:"24101639",label:"Molinete"},
    {prodId:"24101640",label:"Unidad de engranaje transversal"},
    {prodId:"24101641",label:"Grillete"},
    {prodId:"24101642",label:"Grúa flotante"},
    {prodId:"24101643",label:"Trolly de lavado y secado"},
    {prodId:"24101644",label:"Ascensor de cocina"},
    {prodId:"24101645",label:"Grúa sobre orugas"},
    {prodId:"24101646",label:"Grúa ferroviaria"},
    {prodId:"24101647",label:"Almeja para izaje de material"},
    {prodId:"24101648",label:"Almeja para izaje de placas"},
    {prodId:"24101649",label:"Grúa güinche"},
    {prodId:"24101650",label:"Guy derrick"},
    {prodId:"24101651",label:"Cabrestante"},
    {prodId:"24101652",label:"Grúa de mástil"},
    {prodId:"24101653",label:"Puentegrúa"},
    {prodId:"24101654",label:"Grúa gantry o de pórtico"},
    {prodId:"24101655",label:"Sistema de parqueadero vertical"},
    {prodId:"24101656",label:"Grúa halada por tractor"},
    {prodId:"24101657",label:"Grúa montada sobre tractor"},
    {prodId:"24101658",label:"Imán de izaje"},
    {prodId:"24101659",label:"Gato o dolly móvil vertical"},
    {prodId:"24101660",label:"Elevador de baterías"},
    {prodId:"24101661",label:"Elevador de vehículo"},
    {prodId:"24101662",label:"Cubierta de güinche"},
    {prodId:"24101700",label:"Transportadores y accesorios"},
    {prodId:"24101701",label:"Rodillo transportador"},
    {prodId:"24101702",label:"Transbordadores a bolas"},
    {prodId:"24101703",label:"Acumuladoras de rocas"},
    {prodId:"24101704",label:"Alimentadores para banda transportadora"},
    {prodId:"24101705",label:"Tornillo de banda transportadora"},
    {prodId:"24101706",label:"Troles o vagonetas y accesorios"},
    {prodId:"24101707",label:"Banda transportadora sobre rieles"},
    {prodId:"24101708",label:"Banda transportadora extensible"},
    {prodId:"24101709",label:"Transportador de rodillos"},
    {prodId:"24101710",label:"Paradas de paquetes"},
    {prodId:"24101711",label:"Tornamesas"},
    {prodId:"24101712",label:"Bandas transportadoras"},
    {prodId:"24101713",label:"Aparato de cangilones"},
    {prodId:"24101714",label:"Bandas transportadoras aéreas"},
    {prodId:"24101715",label:"Sistema de bandas transportadoras"},
    {prodId:"24101716",label:"Poleas de banda transportadora"},
    {prodId:"24101717",label:"Rodillos de banda transportadora"},
    {prodId:"24101718",label:"Sujeciones de la banda transportadora"},
    {prodId:"24101719",label:"Cepillos de la banda transportadora"},
    {prodId:"24101721",label:"Cubierta de trolley"},
    {prodId:"24101722",label:"Banda transportadora de cadena"},
    {prodId:"24101723",label:"Rodillos o tambores motorizados"},
    {prodId:"24101724",label:"Bastidores de banda transportadora"},
    {prodId:"24101725",label:"Articulaciones o eslabones de banda transportadora"},
    {prodId:"24101726",label:"Revestimiento de la banda transportadora"},
    {prodId:"24101727",label:"Banda transportadora vibratoria"},
    {prodId:"24101728",label:"Soportes de la banda transportadora"},
    {prodId:"24101729",label:"Balde de elevador de cangilones"},
    {prodId:"24101730",label:"Banda transportadora de tornillo"},
    {prodId:"24101731",label:"Faldón de la banda transportadora"},
    {prodId:"24101732",label:"Soporte del rodillo de la banda transportadora"},
    {prodId:"24101733",label:"Soporte del rodillo de retorno de la banda transportadora"},
    {prodId:"24101734",label:"Raspador en v"},
    {prodId:"24101735",label:"Deslizador de aire"},
    {prodId:"24101736",label:"Sistema de transporte de fase densa"},
    {prodId:"24101737",label:"Sistema de transporte de fase liviana"},
    {prodId:"24101738",label:"Compuerta de barra"},
    {prodId:"24101739",label:"Unidad receptora de granel"},
    {prodId:"24101740",label:"Banda transportadora de ángulo alto"},
    {prodId:"24101741",label:"Raspador interno de banda"},
    {prodId:"24101742",label:"Raspador externo de banda"},
    {prodId:"24101743",label:"Socavón final de banda transportadora"},
    {prodId:"24101744",label:"Socavón de inicio de banda transportadora"},
    {prodId:"24101745",label:"Banda transportadora sobre ruedas"},
    {prodId:"24101746",label:"Banda transportadora portátil"},
    {prodId:"24101747",label:"Soporte de banda transportadora"},
    {prodId:"24101748",label:"Banda transportadora tripper o disparadora"},
    {prodId:"24101749",label:"Sistema de transporte por bandas"},
    {prodId:"24101750",label:"Banda transportadora aérea"},
    {prodId:"24101800",label:"Equipo para muelles"},
    {prodId:"24101801",label:"Niveladores para muelles"},
    {prodId:"24101802",label:"Retenedoras para muelles"},
    {prodId:"24101803",label:"Rampas para muelles"},
    {prodId:"24101804",label:"Puertas de cintas"},
    {prodId:"24101805",label:"Parachoques para muelles"},
    {prodId:"24101806",label:"Escaleras para muelles"},
    {prodId:"24101807",label:"Planchas para muelles"},
    {prodId:"24101808",label:"Cuñas para ruedas"},
    {prodId:"24101809",label:"Carriles de muelle o accesorios"},
    {prodId:"24101900",label:"Equipo para manejo de canecas"},
    {prodId:"24101901",label:"Abridores de canecas"},
    {prodId:"24101902",label:"Agarradores para canecas"},
    {prodId:"24101903",label:"Elevadores de canecas"},
    {prodId:"24101904",label:"Soportes para canecas"},
    {prodId:"24101905",label:"Cubierta de derrames"},
    {prodId:"24101906",label:"Recuperadores de canecas"},
    {prodId:"24101907",label:"Soportes de contención de derrames"},
    {prodId:"24101908",label:"Caballete para canecas"},
    {prodId:"24102000",label:"Disposición en estantes y almacenamiento"},
    {prodId:"24102001",label:"Sistemas de estanterías para equipo electrónico montado sobre estantes"},
    {prodId:"24102002",label:"Manipuladores para recipientes"},
    {prodId:"24102004",label:"Estanterías para almacenaje"},
    {prodId:"24102005",label:"Sistemas automatizados de almacenaje o recuperación"},
    {prodId:"24102006",label:"Bancos de trabajo"},
    {prodId:"24102007",label:"Carretes de almacenamiento de estantes"},
    {prodId:"24102008",label:"Soportes de carretes portátiles"},
    {prodId:"24102009",label:"Rieles montados sobre pared"},
    {prodId:"24102010",label:"Estantería de vidrio"},
    {prodId:"24102011",label:"Accesorio de biblioteca"},
    {prodId:"24102100",label:"Equipo y suministros para almacenaje"},
    {prodId:"24102101",label:"Equipo para manejo de carga"},
    {prodId:"24102102",label:"Carrusel de bodegaje"},
    {prodId:"24102103",label:"Encajadoras de bodegaje"},
    {prodId:"24102104",label:"Despaletizadoras"},
    {prodId:"24102105",label:"Paletizadoras"},
    {prodId:"24102106",label:"Equipo de retractilado industrial"},
    {prodId:"24102107",label:"Maquinaria para encartonar"},
    {prodId:"24102108",label:"Compactadores de empaque"},
    {prodId:"24102109",label:"Ganchos para sacos"},
    {prodId:"24102200",label:"Herramientas para envasado"},
    {prodId:"24102201",label:"Dispensadores de película elástica"},
    {prodId:"24102202",label:"Dispensadores de cinta para sellar cajas"},
    {prodId:"24102203",label:"Herramientas o equipos para sellar bolsas"},
    {prodId:"24102204",label:"Dispensador de zunchos"},
    {prodId:"24102208",label:"Infladores de aire"},
    {prodId:"24102209",label:"Máquina para cortar moldes"},
    {prodId:"24102210",label:"Prensa de embalar"},
    {prodId:"24102300",label:"Vehículos guiados automáticos AGV"},
    {prodId:"24102301",label:"Vehículo automático guiado por códigos de barras"},
    {prodId:"24102302",label:"Vehículo automático guiado a través del cableado"},
    {prodId:"24111500",label:"Bolsas"},
    {prodId:"24111501",label:"Bolsas de lona"},
    {prodId:"24111502",label:"Bolsas de papel"},
    {prodId:"24111503",label:"Bolsas plásticas"},
    {prodId:"24111505",label:"Contendedores de granel intermedios flexibles"},
    {prodId:"24111506",label:"Redes o bolsas de lavandería"},
    {prodId:"24111507",label:"Bolsas de herramientas"},
    {prodId:"24111508",label:"Bolsas de carpa"},
    {prodId:"24111509",label:"Bolsas de agua"},
    {prodId:"24111510",label:"Bolsas y paquetes de cuerda"},
    {prodId:"24111511",label:"Bolsas de malla de fibra"},
    {prodId:"24111512",label:"Bolsas de paja"},
    {prodId:"24111513",label:"Bolsas de algodón"},
    {prodId:"24111514",label:"Bolsas con cremallera"},
    {prodId:"24111800",label:"Tanques y cilindros y sus accesorios"},
    {prodId:"24111801",label:"Reservorios"},
    {prodId:"24111802",label:"Tanques o cilindros de aire o gas"},
    {prodId:"24111803",label:"Tanques de almacenamiento"},
    {prodId:"24111804",label:"Tanques de calibración"},
    {prodId:"24111805",label:"Tanques de productos químicos"},
    {prodId:"24111806",label:"Tanques de inmersión"},
    {prodId:"24111807",label:"Tanques de expansión"},
    {prodId:"24111808",label:"Tanques de almacenaje de combustible"},
    {prodId:"24111809",label:"Tanques de procesamiento"},
    {prodId:"24111810",label:"Tanques de almacenamiento de agua"},
    {prodId:"24111811",label:"Bombonas"},
    {prodId:"24111812",label:"Pileta de contención"},
    {prodId:"24111813",label:"Tanques de lavado"},
    {prodId:"24111814",label:"Tanques de agua caliente"},
    {prodId:"24111815",label:"Tanques de condensado de vapor"},
    {prodId:"24111816",label:"Tanque o cilindro de gas licuado"},
    {prodId:"24111817",label:"Silo de cemento"},
    {prodId:"24111818",label:"Sello de tanque"},
    {prodId:"24112000",label:"Arcones y cestas"},
    {prodId:"24112003",label:"Tolvas no metálicas"},
    {prodId:"24112004",label:"Tolvas metálicas"},
    {prodId:"24112005",label:"Canastas metálicas"},
    {prodId:"24112006",label:"Canastas no metálicas"},
    {prodId:"24112007",label:"Estante para el manejo de material"},
    {prodId:"24112100",label:"Toneles, cubas y bidones"},
    {prodId:"24112101",label:"Toneles"},
    {prodId:"24112102",label:"Barriles"},
    {prodId:"24112108",label:"Tambores metálicos"},
    {prodId:"24112109",label:"Tambores no metálicos"},
    {prodId:"24112110",label:"Contenedores intermedios a granel"},
    {prodId:"24112111",label:"Estabilizador"},
    {prodId:"24112112",label:"Tapa de tambor"},
    {prodId:"24112200",label:"Latas y cubos"},
    {prodId:"24112204",label:"Cubos metálicos"},
    {prodId:"24112205",label:"Cubos no metálicos"},
    {prodId:"24112206",label:"Bidones metálicos para líquido inflamable"},
    {prodId:"24112207",label:"Bidones no metálicos para líquido inflamable"},
    {prodId:"24112208",label:"Conjunto pulverizador"},
    {prodId:"24112209",label:"Contenedor de paredes rectas"},
    {prodId:"24112400",label:"Cofres, armarios y baúles de almacenaje"},
    {prodId:"24112401",label:"Cofres, cajas o armarios para herramientas"},
    {prodId:"24112402",label:"Armarios para materiales peligrosos"},
    {prodId:"24112403",label:"Cinturón para herramientas"},
    {prodId:"24112404",label:"Caja"},
    {prodId:"24112406",label:"Compartimentos de caja o estantería"},
    {prodId:"24112407",label:"Buzones"},
    {prodId:"24112408",label:"Cajas antiestática"},
    {prodId:"24112409",label:"Tapas para cajas"},
    {prodId:"24112411",label:"Estuches para transporte"},
    {prodId:"24112412",label:"Estuches para transporte de equipo"},
    {prodId:"24112413",label:"Cajas o estuches para guardar banderas"},
    {prodId:"24112414",label:"Caja de almacenamiento refrigerado"},
    {prodId:"24112415",label:"Urnas de votos"},
    {prodId:"24112500",label:"Cartón ondulado y otros materiales para distribución"},
    {prodId:"24112501",label:"Cartones acanalados ranurados"},
    {prodId:"24112502",label:"Cajas de transporte cortadas con troquel de una sola pieza"},
    {prodId:"24112503",label:"Cartones acanalados de transporte cortados con troquel con tapas separadas"},
    {prodId:"24112504",label:"Cajas moldeadas"},
    {prodId:"24112505",label:"Formas de cartón corrugado"},
    {prodId:"24112600",label:"Contenedores para líquidos"},
    {prodId:"24112601",label:"Jarras"},
    {prodId:"24112602",label:"Frascos"},
    {prodId:"24112700",label:"Estibas"},
    {prodId:"24112701",label:"Pallets de madera"},
    {prodId:"24112702",label:"Pallets plásticos"},
    {prodId:"24112800",label:"Contenedores de carga"},
    {prodId:"24112801",label:"Contenedor de carga seca"},
    {prodId:"24112802",label:"Contenedor de carga open top"},
    {prodId:"24112803",label:"Contenedor de carga de plataforma"},
    {prodId:"24112804",label:"Contenedor de carga flatrack"},
    {prodId:"24112805",label:"Contenedor de carga de temperatura controlada"},
    {prodId:"24112900",label:"Embalajes"},
    {prodId:"24112901",label:"Embalaje de madera"},
    {prodId:"24112902",label:"Embalaje de plástico"},
    {prodId:"24113000",label:"Lámina termo - retráctil"},
    {prodId:"24113001",label:"Lámina termo - retráctil corrogada"},
    {prodId:"24113002",label:"Lámina termo - retráctil de cartón o cartulina sólida"},
    {prodId:"24113003",label:"Lámina termo - retráctil plástica"},
    {prodId:"24121500",label:"Cajas, bolsas y sacos para empaquetado"},
    {prodId:"24121502",label:"Sacos o bolsas para empacar"},
    {prodId:"24121503",label:"Cajas para empacar"},
    {prodId:"24121504",label:"Empaques cardados"},
    {prodId:"24121506",label:"Cajas conductivas"},
    {prodId:"24121507",label:"Cajas instaladas rígidas"},
    {prodId:"24121508",label:"Cartones de huevos"},
    {prodId:"24121509",label:"Bandejas para empacar"},
    {prodId:"24121510",label:"Empaques para bolsas de te"},
    {prodId:"24121511",label:"Cartón de empacar"},
    {prodId:"24121512",label:"Ladrillos de hielo o “cold packs”"},
    {prodId:"24121513",label:"Estuche para empacar"},
    {prodId:"24121800",label:"Latas para envasado"},
    {prodId:"24121801",label:"Latas de aerosol"},
    {prodId:"24121802",label:"Latas de pintura o barniz"},
    {prodId:"24121803",label:"Latas de bebida"},
    {prodId:"24121804",label:"Latas de comida"},
    {prodId:"24121805",label:"Latas de acero"},
    {prodId:"24121806",label:"Latas de aluminio"},
    {prodId:"24121807",label:"Recipientes de plástico"},
    {prodId:"24121808",label:"Recipientes de fibra de cartón"},
    {prodId:"24122000",label:"Botellas"},
    {prodId:"24122001",label:"Botellas para apretar"},
    {prodId:"24122002",label:"Botellas de plástico"},
    {prodId:"24122003",label:"Botellas de cristal"},
    {prodId:"24122004",label:"Tapones o tapas"},
    {prodId:"24122005",label:"Botellas de aplicador"},
    {prodId:"24122006",label:"Aplicador"},
    {prodId:"24131500",label:"Refrigeradores industriales"},
    {prodId:"24131501",label:"Refrigerador y congelador combinado"},
    {prodId:"24131502",label:"Refrigeradores de nitrógeno líquido"},
    {prodId:"24131503",label:"Cuartos fríos"},
    {prodId:"24131504",label:"Contenedores refrigerados"},
    {prodId:"24131505",label:"Buques refrigerados"},
    {prodId:"24131506",label:"Tanques refrigerados"},
    {prodId:"24131507",label:"Congelador de golpe"},
    {prodId:"24131508",label:"Gabinete refrigerado"},
    {prodId:"24131509",label:"Refrigerador bajo el mostrador"},
    {prodId:"24131510",label:"Refrigerador de mostrador"},
    {prodId:"24131511",label:"Gabinete de refrigeración para pastelería"},
    {prodId:"24131512",label:"Estación de preparación con gabinete refrigerado"},
    {prodId:"24131513",label:"Refrigerante"},
    {prodId:"24131514",label:"Unidad de almacenamiento de pre - enfriamiento y frío"},
    {prodId:"24131600",label:"Congeladores industriales"},
    {prodId:"24131601",label:"Congeladores horizontales"},
    {prodId:"24131602",label:"Congeladores verticales"},
    {prodId:"24131603",label:"Congeladores a bajas temperaturas"},
    {prodId:"24131604",label:"Equipo de secado por congelación"},
    {prodId:"24131605",label:"Cuartos fríos"},
    {prodId:"24131606",label:"Congeladores de placa"},
    {prodId:"24131607",label:"Congeladores de golpe de frío"},
    {prodId:"24131608",label:"Gabinete de congelación para pastelería"},
    {prodId:"24131609",label:"Congelador bajo el mostrador"},
    {prodId:"24131610",label:"Congelador de mostrador"},
    {prodId:"24131900",label:"Máquinas de hacer el hielo"},
    {prodId:"24131901",label:"Máquinas para hacer cubos de hielo"},
    {prodId:"24131902",label:"Máquinas para hacer bloques de hielo"},
    {prodId:"24131903",label:"Máquinas para hacer hojuelas de hielo"},
    {prodId:"24141500",label:"Suministros para seguridad y protección"},
    {prodId:"24141501",label:"Película elástica para envoltura"},
    {prodId:"24141502",label:"Láminas elásticas de embalaje"},
    {prodId:"24141504",label:"Sellos de seguridad a prueba de manipulación"},
    {prodId:"24141506",label:"Encerados"},
    {prodId:"24141507",label:"Película anti-estática para empacar"},
    {prodId:"24141508",label:"Soporte angular de cartón"},
    {prodId:"24141510",label:"Protectores de cables"},
    {prodId:"24141511",label:"Amarres de carga"},
    {prodId:"24141512",label:"Absorbentes de empaquetado"},
    {prodId:"24141513",label:"Empaques blíster"},
    {prodId:"24141514",label:"Películas para empacar"},
    {prodId:"24141515",label:"Red de protección"},
    {prodId:"24141516",label:"Desecante"},
    {prodId:"24141517",label:"Polifilm"},
    {prodId:"24141518",label:"Láminas separadoras"},
    {prodId:"24141519",label:"Bandas o correas de acero para empacar"},
    {prodId:"24141520",label:"Bandas o correas no metálicas para empacar"},
    {prodId:"24141600",label:"Suministros de acolchados"},
    {prodId:"24141601",label:"Empaque de burbujas"},
    {prodId:"24141602",label:"Materiales de termoformado"},
    {prodId:"24141603",label:"Amortiguación"},
    {prodId:"24141604",label:"Relleno"},
    {prodId:"24141605",label:"Materiales de empaque rellenos de aire"},
    {prodId:"24141606",label:"Bolitas de poliestireno"},
    {prodId:"24141607",label:"Separadores de cartón"},
    {prodId:"24141608",label:"Protecciones externas"},
    {prodId:"24141700",label:"Tubos y núcleos de embalaje y etiquetas y accesorios"},
    {prodId:"24141701",label:"Tubos o núcleos de papel"},
    {prodId:"24141702",label:"Tapones de extremidad tubo o núcleo"},
    {prodId:"24141703",label:"Piezas de papel"},
    {prodId:"24141704",label:"Instrucciones o insertos impresos"},
    {prodId:"24141705",label:"Tubos plegables"},
    {prodId:"24141706",label:"Carrete"},
    {prodId:"24141707",label:"Carretel"},
    {prodId:"24141708",label:"Asas de paquetes"},
    {prodId:"24141709",label:"Tubos o tapones de cápsulas"},
    {prodId:"24141710",label:"Etiqueta de alambre"},
    {prodId:"25101500",label:"Vehículos de pasajeros"},
    {prodId:"25101501",label:"Minibuses"},
    {prodId:"25101502",label:"Autobuses"},
    {prodId:"25101503",label:"Carros"},
    {prodId:"25101504",label:"Station wagons"},
    {prodId:"25101505",label:"Minivans o vans"},
    {prodId:"25101506",label:"Limosinas"},
    {prodId:"25101507",label:"Camiones ligeros o vehículos utilitarios deportivos"},
    {prodId:"25101508",label:"Carro deportivo"},
    {prodId:"25101509",label:"Carro eléctrico"},
    {prodId:"25101600",label:"Vehículos de transporte de productos y materiales"},
    {prodId:"25101601",label:"Volquetas"},
    {prodId:"25101602",label:"Remolques"},
    {prodId:"25101604",label:"Camiones de reparto"},
    {prodId:"25101609",label:"Camiones de manipulación de sedimento y aguas residuales"},
    {prodId:"25101610",label:"Carrotanques"},
    {prodId:"25101611",label:"Camiones de carga"},
    {prodId:"25101612",label:"Camiones concreteros"},
    {prodId:"25101613",label:"Camiones para bombear concreto"},
    {prodId:"25101700",label:"Vehículos de protección y salvamento"},
    {prodId:"25101701",label:"Camiones de bomberos y de rescate"},
    {prodId:"25101702",label:"Carros policiales"},
    {prodId:"25101703",label:"Ambulancias"},
    {prodId:"25101800",label:"Bicicletas de motor"},
    {prodId:"25101801",label:"Motocicletas"},
    {prodId:"25101802",label:"Scooters"},
    {prodId:"25101803",label:"Velomotores"},
    {prodId:"25101804",label:"Sidecar de motocicleta"},
    {prodId:"25101900",label:"Vehículos especializados o de recreo"},
    {prodId:"25101901",label:"Tractores agrícolas"},
    {prodId:"25101902",label:"Casas rodantes"},
    {prodId:"25101903",label:"Trineos motorizados o motos de nieve"},
    {prodId:"25101904",label:"Carritos de golf"},
    {prodId:"25101905",label:"Vehículos todoterreno de ruedas o de tracción"},
    {prodId:"25101906",label:"Karts"},
    {prodId:"25101907",label:"Remolques de camping o caravanas"},
    {prodId:"25101908",label:"Cuatrimoto"},
    {prodId:"25101909",label:"Vehículos anfibios"},
    {prodId:"25101910",label:"Camiones aspersores de agua"},
    {prodId:"25101911",label:"Camión con plataforma aérea de trabajo"},
    {prodId:"25101912",label:"Camión escalera"},
    {prodId:"25101913",label:"Camión refrigerador"},
    {prodId:"25101914",label:"Carro de la basura o vehículo de recolección de desperdicios"},
    {prodId:"25101915",label:"Camión petrolero"},
    {prodId:"25101916",label:"Camión tolva"},
    {prodId:"25101917",label:"Camabaja"},
    {prodId:"25101918",label:"Vehículo eléctrico de vecindario"},
    {prodId:"25101919",label:"Barredora de calles"},
    {prodId:"25101920",label:"Carro de seguridad"},
    {prodId:"25101921",label:"Carros para transporte de líquidos exceptuando petróleo"},
    {prodId:"25101922",label:"Camión de reparación de calles"},
    {prodId:"25101923",label:"Tractor de orugas"},
    {prodId:"25101924",label:"Vehículo limpiador de barandas"},
    {prodId:"25101925",label:"Camión de signos"},
    {prodId:"25101926",label:"Camión quitanieves"},
    {prodId:"25101927",label:"Camión esparcidor de sal"},
    {prodId:"25101928",label:"Camión de medios móvil"},
    {prodId:"25101929",label:"Van oficina"},
    {prodId:"25101930",label:"Camión con equipo de iluminación"},
    {prodId:"25101931",label:"Van para radiodifusión externa"},
    {prodId:"25101932",label:"Camión para controlar multitudes"},
    {prodId:"25101933",label:"Camión grúa"},
    {prodId:"25101934",label:"Coche fúnebre"},
    {prodId:"25101935",label:"Camión rociador de líquidos"},
    {prodId:"25101936",label:"Camión de vacío"},
    {prodId:"25101937",label:"Bus para tratamiento y exámenes médicos"},
    {prodId:"25101938",label:"Sangre - móvil"},
    {prodId:"25102000",label:"Vehículos de guerra"},
    {prodId:"25102001",label:"Tanques"},
    {prodId:"25102002",label:"Carros de combate acorazados"},
    {prodId:"25102003",label:"Artillería motorizada"},
    {prodId:"25102100",label:"Camiones tractores"},
    {prodId:"25102101",label:"Tractores delanteros de cabina baja"},
    {prodId:"25102102",label:"Tractomulas de nariz alargada con cama"},
    {prodId:"25102103",label:"Tractomulas de nariz alargada sin cama"},
    {prodId:"25102104",label:"Tractomulas con cabina encima del motor con cama"},
    {prodId:"25102105",label:"Tractomulas con cabina encima del motor sin cama"},
    {prodId:"25102106",label:"Cabezote"},
    {prodId:"25111500",label:"Embarcaciones marítimas comerciales"},
    {prodId:"25111501",label:"Barcos pesqueros"},
    {prodId:"25111502",label:"Botes de pesca"},
    {prodId:"25111503",label:"Buques de carga o de contenedores"},
    {prodId:"25111504",label:"Embarcación para dragados"},
    {prodId:"25111505",label:"Buques cisterna"},
    {prodId:"25111506",label:"Remolcadores"},
    {prodId:"25111507",label:"Barcazas"},
    {prodId:"25111508",label:"Transbordadores de pasajeros o vehículos"},
    {prodId:"25111509",label:"Buques de cruceros"},
    {prodId:"25111510",label:"Naves de salvamento"},
    {prodId:"25111511",label:"Barco de tripulación de gas o petróleo"},
    {prodId:"25111512",label:"Barco de trabajo de gas o petróleo"},
    {prodId:"25111513",label:"Barco para sísmica"},
    {prodId:"25111514",label:"Rompehielos"},
    {prodId:"25111515",label:"Carguero de gas líquido"},
    {prodId:"25111516",label:"Bote de caucho inflable"},
    {prodId:"25111517",label:"Batiscafo (hovercraft)"},
    {prodId:"25111518",label:"Buque hospital"},
    {prodId:"25111519",label:"Bote de carreras"},
    {prodId:"25111520",label:"Boya"},
    {prodId:"25111521",label:"Ancla marina"},
    {prodId:"25111522",label:"Carguero tanquero químico"},
    {prodId:"25111523",label:"Buque fábrica"},
    {prodId:"25111524",label:"Buque granelero"},
    {prodId:"25111525",label:"Buque roll on - roll off"},
    {prodId:"25111526",label:"Buque taladro"},
    {prodId:"25111527",label:"Bote de remos"},
    {prodId:"25111528",label:"Bote de cuarentena"},
    {prodId:"25111529",label:"Buque de meteorología"},
    {prodId:"25111530",label:"Bote de piloto"},
    {prodId:"25111531",label:"Barco para levantamientos hidrográficos"},
    {prodId:"25111532",label:"Nave para recolección de escombros"},
    {prodId:"25111533",label:"Buque de entrenamiento"},
    {prodId:"25111534",label:"Buque de investigación"},
    {prodId:"25111535",label:"Buque para el tendido de cable"},
    {prodId:"25111600",label:"Embarcaciones de seguridad y salvamento"},
    {prodId:"25111601",label:"Lanchas o balsas salvavidas"},
    {prodId:"25111602",label:"Nave para apagar incendios"},
    {prodId:"25111603",label:"Buques o botes de rescate"},
    {prodId:"25111700",label:"Embarcaciones militares"},
    {prodId:"25111701",label:"Submarinos"},
    {prodId:"25111702",label:"Portaviones"},
    {prodId:"25111703",label:"Barcos de munición"},
    {prodId:"25111704",label:"Barcos de asalto anfibio"},
    {prodId:"25111705",label:"Muelles anfibios de transporte"},
    {prodId:"25111706",label:"Buques anfibios de comando"},
    {prodId:"25111707",label:"Buques de comando"},
    {prodId:"25111708",label:"Buques de guerra"},
    {prodId:"25111709",label:"Destructores"},
    {prodId:"25111710",label:"Buques de desembarco"},
    {prodId:"25111711",label:"Buques de apoyo de combate rápido"},
    {prodId:"25111712",label:"Fragatas"},
    {prodId:"25111713",label:"Buques petroleros de escuadra"},
    {prodId:"25111714",label:"Embarcación de desembarque de uso general"},
    {prodId:"25111715",label:"Embarcación mecanizada o de uso general"},
    {prodId:"25111716",label:"Buques buscaminas"},
    {prodId:"25111717",label:"Buques dragaminas"},
    {prodId:"25111718",label:"Embarcaciones de patrulla costera"},
    {prodId:"25111719",label:"Buques nodriza para submarinos"},
    {prodId:"25111720",label:"Botes para desembarco de tanques"},
    {prodId:"25111721",label:"Embarcaciones de desembarque aerodeslizadas"},
    {prodId:"25111722",label:"Barco guardacostas"},
    {prodId:"25111723",label:"Bote de patrulla pesquera"},
    {prodId:"25111724",label:"Nave de entrenamiento de pesca"},
    {prodId:"25111725",label:"Nave de investigación de pesca"},
    {prodId:"25111726",label:"Nave para el control de la contaminación marina"},
    {prodId:"25111800",label:"Embarcaciones de recreo"},
    {prodId:"25111801",label:"Embarcaciones a vela de recreo"},
    {prodId:"25111802",label:"Lanchas de recreo a motor"},
    {prodId:"25111803",label:"Lanchas de recreo a remo"},
    {prodId:"25111804",label:"Canoas o kayaks"},
    {prodId:"25111805",label:"Artefactos a motor de uso personal"},
    {prodId:"25111806",label:"Balsas"},
    {prodId:"25111807",label:"Lanchas pequeñas"},
    {prodId:"25111808",label:"Yates"},
    {prodId:"25111900",label:"Sistemas y premontajes de embarcaciones marítimas"},
    {prodId:"25111901",label:"Sistemas de comunicaciones de embarcaciones marítimas"},
    {prodId:"25111902",label:"Hélices marítimas"},
    {prodId:"25111903",label:"Velas"},
    {prodId:"25111904",label:"Remos"},
    {prodId:"25111905",label:"Sistemas balísticos de la marina"},
    {prodId:"25111906",label:"Cuñas de ancla"},
    {prodId:"25111907",label:"Cables de ancla"},
    {prodId:"25111908",label:"Recuperadores de ancla"},
    {prodId:"25111909",label:"Rodillos de ancla"},
    {prodId:"25111910",label:"Rampas de embarcaciones"},
    {prodId:"25111911",label:"Bicheros"},
    {prodId:"25111912",label:"Recogedores de botavaras"},
    {prodId:"25111913",label:"Escotillas de cubierta"},
    {prodId:"25111914",label:"Argollas de amarre"},
    {prodId:"25111915",label:"Escaleras de muelle"},
    {prodId:"25111916",label:"Señales de teñido de emergencia"},
    {prodId:"25111917",label:"Alabantes"},
    {prodId:"25111918",label:"Sistemas de recogido de velas"},
    {prodId:"25111919",label:"Protectores de la quilla"},
    {prodId:"25111920",label:"Defensas"},
    {prodId:"25111921",label:"Cuellos de ganso marinos"},
    {prodId:"25111922",label:"Cabos de amarre"},
    {prodId:"25111923",label:"Bloqueos de remos"},
    {prodId:"25111924",label:"Reflectores de radar"},
    {prodId:"25111925",label:"Timones"},
    {prodId:"25111926",label:"Refuerzos de velas"},
    {prodId:"25111927",label:"Botavaras"},
    {prodId:"25111928",label:"Fundas de velas"},
    {prodId:"25111929",label:"Sacas"},
    {prodId:"25111930",label:"Palos de espinaquer"},
    {prodId:"25111931",label:"Plataformas de popa"},
    {prodId:"25111932",label:"Cortavientos"},
    {prodId:"25111933",label:"Relojes de mareas"},
    {prodId:"25111934",label:"Cañas de timones"},
    {prodId:"25111935",label:"Berlingas"},
    {prodId:"25111936",label:"Propulsor marino"},
    {prodId:"25111937",label:"Protector de mástil"},
    {prodId:"25111938",label:"Generador marino de agua fresca"},
    {prodId:"25111939",label:"Engranaje de dirección marino"},
    {prodId:"25111940",label:"Simulador de navegación marina"},
    {prodId:"25121500",label:"Locomotoras y vagonetas eléctricas"},
    {prodId:"25121501",label:"Locomotoras diesel de carga"},
    {prodId:"25121502",label:"Locomotoras eléctricas de carga"},
    {prodId:"25121503",label:"Locomotoras diesel de pasajeros"},
    {prodId:"25121504",label:"Locomotoras eléctricas de pasajeros"},
    {prodId:"25121600",label:"Vagones de ferrocarril y tranvía"},
    {prodId:"25121601",label:"Vagones de carga"},
    {prodId:"25121602",label:"Vagones cisterna"},
    {prodId:"25121603",label:"Vagones de pasajeros"},
    {prodId:"25121604",label:"Vagones tolva o góndolas"},
    {prodId:"25121605",label:"Tranvías"},
    {prodId:"25121700",label:"Equipo y sistemas de apoyo ferroviario"},
    {prodId:"25121701",label:"Sistemas de cambiavías"},
    {prodId:"25121702",label:"Traviesas"},
    {prodId:"25121703",label:"Vías férreas"},
    {prodId:"25121704",label:"Eclisas"},
    {prodId:"25121705",label:"Enganches de rieles"},
    {prodId:"25121706",label:"Aparatos de tracción"},
    {prodId:"25121707",label:"Conjuntos de bogie"},
    {prodId:"25121708",label:"Riel férreo pesado"},
    {prodId:"25121709",label:"Riel férreo pesado de segundo uso"},
    {prodId:"25121710",label:"Riel férreo liviano"},
    {prodId:"25121711",label:"Riel férreo liviano de segundo uso"},
    {prodId:"25121712",label:"Amarre férreo de acero"},
    {prodId:"25121713",label:"Contacto eléctrico de rieles"},
    {prodId:"25121714",label:"Conmutador férreo de giro de rieles pesados"},
    {prodId:"25121715",label:"Conmutador férreo de giro de rieles livianos"},
    {prodId:"25121716",label:"Amarre férreo de madera"},
    {prodId:"25121717",label:"Unión eléctrica de rieles"},
    {prodId:"25131600",label:"Aeronave de alas giratorias civil y comercial"},
    {prodId:"25131601",label:"Helicópteros de pasajeros"},
    {prodId:"25131602",label:"Helicópteros de carga"},
    {prodId:"25131603",label:"Helicópteros agrícolas"},
    {prodId:"25131604",label:"Helicópteros médicos o de rescate"},
    {prodId:"25131605",label:"Aeronave de entrenamiento de ala rotativa o helicóptero de entrenamiento"},
    {prodId:"25132000",label:"Avión de recreo"},
    {prodId:"25132001",label:"Aerodeslizadores"},
    {prodId:"25132002",label:"Globos aerostáticos"},
    {prodId:"25132003",label:"Planeadores"},
    {prodId:"25132004",label:"Parapente"},
    {prodId:"25132005",label:"Aviones ultralivianos"},
    {prodId:"25151700",label:"Satélites"},
    {prodId:"25151701",label:"Satélites de comunicación"},
    {prodId:"25151702",label:"Satélites meteorológicos"},
    {prodId:"25151703",label:"Satélites militares"},
    {prodId:"25151704",label:"Satélites científicos o de investigación"},
    {prodId:"25151705",label:"Satélites de navegación"},
    {prodId:"25151706",label:"Satélites geoestacionarios"},
    {prodId:"25151707",label:"Satélites de órbita cercana a la tierra"},
    {prodId:"25151708",label:"Satélites de órbita sincronizada con el sol"},
    {prodId:"25151709",label:"Satélites geo - sincronizados"},
    {prodId:"25161500",label:"Vehículos a pedal"},
    {prodId:"25161501",label:"Bicicletas de turismo"},
    {prodId:"25161502",label:"Monociclos"},
    {prodId:"25161503",label:"Triciclos"},
    {prodId:"25161504",label:"Bicicletas tándem"},
    {prodId:"25161505",label:"Bicicletas de montaña"},
    {prodId:"25161506",label:"Bicicletas de carreras"},
    {prodId:"25161507",label:"Bicicletas"},
    {prodId:"25161508",label:"Bicicletas reclinadas"},
    {prodId:"25161509",label:"Bicicletas para niños"},
    {prodId:"25161510",label:"Bicicletas de cuatro ruedas o cuadriciclos"},
    {prodId:"25171500",label:"Limpiaparabrisas"},
    {prodId:"25171502",label:"limpiaparabrisas para automóviles"},
    {prodId:"25171503",label:"Limpiaparabrisas para locomotoras"},
    {prodId:"25171504",label:"Limpiaparabrisas marítimos"},
    {prodId:"25171505",label:"Limpiaparabrisas de camión"},
    {prodId:"25171506",label:"Bomba de lava parabrisas"},
    {prodId:"25171507",label:"Cuchillas limpiadoras"},
    {prodId:"25171508",label:"Motor del limpiaparabrisas"},
    {prodId:"25171600",label:"Sistemas de descarchado y antiniebla"},
    {prodId:"25171602",label:"Sistemas de desescarchado y antiniebla para automóviles"},
    {prodId:"25171603",label:"Sistemas de desescarchado y antiniebla para trenes"},
    {prodId:"25171700",label:"Sistemas de frenado y componentes"},
    {prodId:"25171702",label:"Sistemas de frenado para automóviles"},
    {prodId:"25171703",label:"Sistemas de frenado para trenes"},
    {prodId:"25171704",label:"Chutes de arrastre"},
    {prodId:"25171705",label:"Rotores"},
    {prodId:"25171706",label:"Calibrador de frenaje"},
    {prodId:"25171707",label:"Freno de tambor"},
    {prodId:"25171708",label:"Freno de disco"},
    {prodId:"25171709",label:"Freno enfriado con líquido"},
    {prodId:"25171710",label:"Cilindros principales"},
    {prodId:"25171711",label:"Cilindros esclavos"},
    {prodId:"25171712",label:"Zapatas de freno de tambor"},
    {prodId:"25171713",label:"Almohadillas de discos de freno"},
    {prodId:"25171714",label:"Tambor de freno"},
    {prodId:"25171715",label:"Rotores de frenos de disco"},
    {prodId:"25171716",label:"Líneas de freno"},
    {prodId:"25171717",label:"Pistones de freno"},
    {prodId:"25171718",label:"Kits de reparación de frenos"},
    {prodId:"25171719",label:"Refuerzos de frenado"},
    {prodId:"25171720",label:"Freno de circuito dual"},
    {prodId:"25171721",label:"Freno mecánico o de parqueo"},
    {prodId:"25171722",label:"Tornillo de rosca de purga del freno"},
    {prodId:"25171723",label:"Sistema de frenos antibloqueo - ABS"},
    {prodId:"25171724",label:"Bobina del sistema de frenos antibloqueo - ABS"},
    {prodId:"25171725",label:"Freno de banda"},
    {prodId:"25171900",label:"Ruedas y acabados"},
    {prodId:"25171901",label:"Rines o ruedas para automóviles"},
    {prodId:"25171902",label:"Ruedas para trenes"},
    {prodId:"25171903",label:"Rines o ruedas para camiones"},
    {prodId:"25171905",label:"Válvulas de neumáticos"},
    {prodId:"25171906",label:"Cubiertas de llantas de automóviles"},
    {prodId:"25172000",label:"Componentes de sistema de suspensión"},
    {prodId:"25172001",label:"Sistemas de suspensión para automóviles"},
    {prodId:"25172002",label:"Sistemas de suspensión para camiones"},
    {prodId:"25172003",label:"Amortiguadores para camiones"},
    {prodId:"25172004",label:"Amortiguadores para automóviles"},
    {prodId:"25172005",label:"Sistemas de suspensión para trenes"},
    {prodId:"25172007",label:"Puntales"},
    {prodId:"25172009",label:"Buje de automóvil"},
    {prodId:"25172010",label:"Barra anti -  ladeo"},
    {prodId:"25172011",label:"Amortiguadores de choque"},
    {prodId:"25172012",label:"Placa deslizante"},
    {prodId:"25172013",label:"Brazo de control de automóvil"},
    {prodId:"25172014",label:"Sistema activo de suspensión"},
    {prodId:"25172100",label:"Componentes y sistemas de seguridad de vehículo"},
    {prodId:"25172101",label:"Airbags"},
    {prodId:"25172104",label:"Cinturones de seguridad"},
    {prodId:"25172105",label:"Sistemas para evitar choques"},
    {prodId:"25172106",label:"Sistemas sensores de impacto"},
    {prodId:"25172108",label:"Apoyacabezas"},
    {prodId:"25172109",label:"Latas de propulsor de airbags"},
    {prodId:"25172110",label:"Pito de vehículo"},
    {prodId:"25172111",label:"Sistema remoto de bloqueo"},
    {prodId:"25172112",label:"Sistemas de control de la estabilidad del vehículo"},
    {prodId:"25172113",label:"Sistemas de control de la tracción del vehículo"},
    {prodId:"25172114",label:"Bloqueadores de ruedas"},
    {prodId:"25172115",label:"Sistema de bloqueo central"},
    {prodId:"25172116",label:"Cadenas de llantas para nieve"},
    {prodId:"25172117",label:"Ensamble de cubierta de airbag"},
    {prodId:"25172118",label:"Ensamble de tela de airbag"},
    {prodId:"25172119",label:"Tela de airbag"},
    {prodId:"25172120",label:"Inflador de airbag"},
    {prodId:"25172121",label:"Inflador de airbag del conductor"},
    {prodId:"25172122",label:"Inflador de airbag del pasajero"},
    {prodId:"25172123",label:"Inflador de airbag de rodilla"},
    {prodId:"25172124",label:"Inflador de airbag lateral"},
    {prodId:"25172125",label:"Inflador de airbag de cortina"},
    {prodId:"25172126",label:"Tejido de cinturones de seguridad"},
    {prodId:"25172127",label:"Sensor de peso de asientos"},
    {prodId:"25172128",label:"Sensor de tensión del cinturón de seguridad bts"},
    {prodId:"25172129",label:"Sistema de información del conductor"},
    {prodId:"25172130",label:"Control de acceso del vehículo sin llave o fob"},
    {prodId:"25172131",label:"Inmovilizador"},
    {prodId:"25172200",label:"Puertas para vehículos"},
    {prodId:"25172201",label:"Puertas de automotores desmontables"},
    {prodId:"25172203",label:"Puertas de automotores"},
    {prodId:"25172204",label:"Puertas de persiana para camiones"},
    {prodId:"25172205",label:"Compuertas inferiores o puertas elevadoras"},
    {prodId:"25172300",label:"Ventanas y parabrisas para vehículos"},
    {prodId:"25172301",label:"Parabrisas para automotores"},
    {prodId:"25172303",label:"Ventanas para automotores"},
    {prodId:"25172304",label:"Cremallera de ventana o ensamble regulatorio"},
    {prodId:"25172305",label:"Parabrisas para vehículo de dos ruedas"},
    {prodId:"25172400",label:"Depósitos y sistemas de combustible"},
    {prodId:"25172404",label:"Sistemas de almacenaje de combustible híbrido"},
    {prodId:"25172405",label:"Sistemas de inyección de combustible"},
    {prodId:"25172406",label:"Tanques de combustible"},
    {prodId:"25172407",label:"Elementos respiraderos"},
    {prodId:"25172408",label:"Tapas de aceite o combustible"},
    {prodId:"25172409",label:"Módulo de combustible"},
    {prodId:"25172410",label:"Inyector o anillo de combustible"},
    {prodId:"25172411",label:"Flotador de tanque de combustible"},
    {prodId:"25172500",label:"Neumáticos y cámaras de neumáticos"},
    {prodId:"25172502",label:"Neumático para llantas de automóviles"},
    {prodId:"25172503",label:"Llantas para camiones pesados"},
    {prodId:"25172504",label:"Llantas para automóviles o camionetas"},
    {prodId:"25172505",label:"Llantas de bicicleta"},
    {prodId:"25172506",label:"Neumáticos de bicicleta"},
    {prodId:"25172507",label:"Cordón de llanta"},
    {prodId:"25172508",label:"Labrado de llanta"},
    {prodId:"25172509",label:"Neumáticos para llantas de camiones pesados"},
    {prodId:"25172510",label:"Neumático de espuma"},
    {prodId:"25172511",label:"Kit de reparación de llantas"},
    {prodId:"25172512",label:"Llanta de motocicleta"},
    {prodId:"25172600",label:"Acabados y revestimientos exterior para vehículos"},
    {prodId:"25172601",label:"Acabados para automotores"},
    {prodId:"25172602",label:"Guardabarros"},
    {prodId:"25172603",label:"Parachoques para automotores"},
    {prodId:"25172604",label:"Espejos retrovisores"},
    {prodId:"25172605",label:"Parrillas de vehículos"},
    {prodId:"25172606",label:"Capós de vehículos"},
    {prodId:"25172607",label:"Paneles laterales de vehículos"},
    {prodId:"25172608",label:"Tableros"},
    {prodId:"25172609",label:"Faldón anti - fango"},
    {prodId:"25172610",label:"Placa de matrícula"},
    {prodId:"25172611",label:"Guarda - bómper"},
    {prodId:"25172700",label:"Sistemas de control medioambiental"},
    {prodId:"25172702",label:"Sistemas de control medioambiental espacial"},
    {prodId:"25172703",label:"Sistemas de control medioambiental marítimo"},
    {prodId:"25172704",label:"Sistema control temperatura vehículo"},
    {prodId:"25172705",label:"Cerca para aceite"},
    {prodId:"25172706",label:"Protector de limo"},
    {prodId:"25172707",label:"Separador de aceite"},
    {prodId:"25172708",label:"Máquina para el control de algas"},
    {prodId:"25172709",label:"Aire acondicionado vehicular"},
    {prodId:"25172710",label:"Calentador vehicular"},
    {prodId:"25172711",label:"Purificador de aire para vehículos"},
    {prodId:"25172800",label:"Sistemas y componentes hidráulicos"},
    {prodId:"25172802",label:"Sistemas hidráulicos para automotores"},
    {prodId:"25172803",label:"Sistemas hidráulicos marítimos"},
    {prodId:"25172900",label:"Iluminación exterior para vehículos"},
    {prodId:"25172901",label:"Iluminación exterior para automóviles"},
    {prodId:"25172903",label:"Iluminación exterior para vagones de tren"},
    {prodId:"25172904",label:"Iluminación exterior para buques o barcos"},
    {prodId:"25172905",label:"Sistema para lavar o limpiar la farola delantera"},
    {prodId:"25172906",label:"Reflectores"},
    {prodId:"25172907",label:"Luz frontal del vehículo"},
    {prodId:"25172908",label:"Luz trasera del vehículo"},
    {prodId:"25173000",label:"Iluminación interior para vehículos"},
    {prodId:"25173001",label:"Iluminación interior para automóviles"},
    {prodId:"25173003",label:"Iluminación interior para vagones de tren"},
    {prodId:"25173004",label:"Iluminación interior para buques o barcos"},
    {prodId:"25173005",label:"Ensamblajes de iluminación de las placas"},
    {prodId:"25173006",label:"Lente no recubierto para iluminación interior del vehículo"},
    {prodId:"25173007",label:"Lente recubierto para iluminación interior del vehículo"},
    {prodId:"25173008",label:"Tubo de luz de vehículo"},
    {prodId:"25173100",label:"Sistemas y componentes de localización y navegación"},
    {prodId:"25173107",label:"Sistemas de posicionamiento global de vehículos"},
    {prodId:"25173108",label:"Sistemas de navegación vehicular"},
    {prodId:"25173300",label:"Sistemas de control principal"},
    {prodId:"25173303",label:"Sistemas de computador"},
    {prodId:"25173304",label:"Sistemas electrónicos de ignición"},
    {prodId:"25173700",label:"Controles de emisión y de escape"},
    {prodId:"25173701",label:"Convertidores catalíticos"},
    {prodId:"25173702",label:"Silenciadores de exhosto o resonadores"},
    {prodId:"25173703",label:"Colector de escape"},
    {prodId:"25173704",label:"Adaptadores de silenciadores"},
    {prodId:"25173705",label:"Amortiguadores de chispas"},
    {prodId:"25173706",label:"Alfombra catalítica"},
    {prodId:"25173707",label:"Sustrato catalítico"},
    {prodId:"25173708",label:"Recubrimiento y solución para desechos catalíticos"},
    {prodId:"25173800",label:"Sistemas de tren de transmisión"},
    {prodId:"25173801",label:"Ejes de manejo"},
    {prodId:"25173802",label:"Ejes no de manejo"},
    {prodId:"25173803",label:"Carcasas de eje"},
    {prodId:"25173804",label:"Husillos de giro de eje"},
    {prodId:"25173805",label:"Diferenciales"},
    {prodId:"25173806",label:"Juntas de velocidad constante"},
    {prodId:"25173807",label:"Ejes de cardán"},
    {prodId:"25173808",label:"Equipo de reparar ejes"},
    {prodId:"25173809",label:"Buje de eje"},
    {prodId:"25173810",label:"Juntas de cardán"},
    {prodId:"25173811",label:"Ejes de tracción"},
    {prodId:"25173812",label:"Transmisiones manuales"},
    {prodId:"25173813",label:"Transmisiones automáticas"},
    {prodId:"25173815",label:"Cables de embrague"},
    {prodId:"25173816",label:"Componentes hidráulicos de embrague"},
    {prodId:"25173817",label:"Cadenas de los engranajes conductores"},
    {prodId:"25173818",label:"Eje propulsor"},
    {prodId:"25173819",label:"Cigüeñal de la hélice marina"},
    {prodId:"25173820",label:"Transmisión continuamente variable"},
    {prodId:"25173821",label:"Ensamblaje de unidad de transferencia"},
    {prodId:"25173900",label:"Componentes eléctricos"},
    {prodId:"25173901",label:"Ignición"},
    {prodId:"25173902",label:"Arranque del motor"},
    {prodId:"25173903",label:"Componente de corte y protección eléctrica al interior del vehículo"},
    {prodId:"25174000",label:"Sistema de refrigerar de motor"},
    {prodId:"25174001",label:"Ventilador"},
    {prodId:"25174002",label:"Radiadores de motor"},
    {prodId:"25174003",label:"Tapas de radiador"},
    {prodId:"25174004",label:"Refrigerante de motor"},
    {prodId:"25174005",label:"Indicadores de aguja del tablero"},
    {prodId:"25174006",label:"Ensamble de manguera de agua refrigerante"},
    {prodId:"25174100",label:"Sistemas de tejado"},
    {prodId:"25174101",label:"Salida de emergencia de vehículos"},
    {prodId:"25174102",label:"Techos permanentes convertibles"},
    {prodId:"25174103",label:"Techos duros desmontables"},
    {prodId:"25174104",label:"Techos blandos desmontables"},
    {prodId:"25174105",label:"Sistemas de rejillas de techo"},
    {prodId:"25174106",label:"Sunroofs o techos corredizos"},
    {prodId:"25174107",label:"Deflectores de viento"},
    {prodId:"25174200",label:"Sistema de dirección"},
    {prodId:"25174201",label:"Sacos de dirección"},
    {prodId:"25174202",label:"Suspensión de dirección"},
    {prodId:"25174203",label:"Junta de bola"},
    {prodId:"25174204",label:"Sistema de dirección hidráulica"},
    {prodId:"25174205",label:"Bielas"},
    {prodId:"25174206",label:"Contramanivela"},
    {prodId:"25174207",label:"Varillas de dirección"},
    {prodId:"25174208",label:"Eje de bloqueo"},
    {prodId:"25174209",label:"Piñones"},
    {prodId:"25174210",label:"Cable de afinamiento de dirección hidráulica"},
    {prodId:"25174211",label:"Timones o volantes"},
    {prodId:"25174212",label:"Ensambles de columna de dirección"},
    {prodId:"25174213",label:"Ensambles de cilindro de dirección"},
    {prodId:"25174214",label:"Timones o volantes de madera"},
    {prodId:"25174215",label:"Timones o volantes de cuero"},
    {prodId:"25174216",label:"Brazo articulado de dirección"},
    {prodId:"25174217",label:"Engranaje de dirección de vehículo"},
    {prodId:"25174400",label:"Sistemas del interior de vehículos"},
    {prodId:"25174401",label:"Biseles"},
    {prodId:"25174402",label:"Consolas"},
    {prodId:"25174403",label:"Paneles de puerta"},
    {prodId:"25174404",label:"Tapizado del techo"},
    {prodId:"25174405",label:"Grupos de instrumentos"},
    {prodId:"25174406",label:"Paneles de instrumentos"},
    {prodId:"25174407",label:"Pedales"},
    {prodId:"25174408",label:"Puntos para energía o para encendedores"},
    {prodId:"25174409",label:"Visores de sol"},
    {prodId:"25174410",label:"Componentes y sistemas de sonido del vehículo"},
    {prodId:"25174411",label:"Acolchado de caucho de la llave"},
    {prodId:"25174412",label:"Aplique decorativo del vehículo"},
    {prodId:"25174413",label:"Escudo o emblema interior del vehículo"},
    {prodId:"25174414",label:"Tacógrafo"},
    {prodId:"25174415",label:"Indicador de la presión de aceite del vehículo"},
    {prodId:"25174416",label:"Encendedor de cigarrillos del vehículo"},
    {prodId:"25174417",label:"Interruptor de combinación del vehículo"},
    {prodId:"25174418",label:"Tapetes del vehículo"},
    {prodId:"25174419",label:"Sistema de audio video del vehículo"},
    {prodId:"25174420",label:"Cenicero del vehículo"},
    {prodId:"25174421",label:"Cortina del vehículo"},
    {prodId:"25174600",label:"Sistemas de asientos del vehículo"},
    {prodId:"25174601",label:"Fundas de asientos"},
    {prodId:"25174602",label:"Cojinería de asientos"},
    {prodId:"25174603",label:"Marcos de asientos"},
    {prodId:"25174604",label:"Silla de timonel de bote"},
    {prodId:"25174605",label:"Silla de motocicleta"},
    {prodId:"25174700",label:"Componentes de ciclo no motorizado o accesorios"},
    {prodId:"25174701",label:"Pedales de bicicleta"},
    {prodId:"25174702",label:"Rayos de bicicleta"},
    {prodId:"25174703",label:"Caja trasera de motocicleta"},
    {prodId:"25174704",label:"Canasta para vehículo de dos ruedas"},
    {prodId:"25174705",label:"Caja para llevar el correo en vehículo de dos ruedas"},
    {prodId:"25174800",label:"Componentes y sistemas de vehículos especializados"},
    {prodId:"25174801",label:"Estabilizador de camión de succión o gato de piso"},
    {prodId:"25174802",label:"Boom para vehículos de rescate o de remolque"},
    {prodId:"25174803",label:"Gancho de remolcar"},
    {prodId:"25174804",label:"Mástil lateral para vehículo"},
    {prodId:"25174805",label:"Aspersor de arena para vehículo"},
    {prodId:"25174806",label:"Pijama para vehículo a la medida"},
    {prodId:"25174807",label:"Bisagra de vehículo"},
    {prodId:"25174808",label:"Caja de almacenamiento de accesorio para vehículos"},
    {prodId:"25174809",label:"Escalera de vehículo"},
    {prodId:"25174810",label:"Gato de vehículo"},
    {prodId:"25174900",label:"Aisladores y amortiguadores de vibración de vehículos"},
    {prodId:"25174901",label:"Aislador y amortiguador moldeado para la vibración del vehículo"},
    {prodId:"25174902",label:"Aislador y amortiguador extruido para la vibración del vehículo"},
    {prodId:"25174903",label:"Aislador y amortiguador fundido para la vibración del vehículo"},
    {prodId:"25175000",label:"Sistemas de cargado eléctrico de vehículos"},
    {prodId:"25175001",label:"Cargador portátil para vehículo eléctrico"},
    {prodId:"25175002",label:"Estación de carga de vehículo eléctrico"},
    {prodId:"25175003",label:"Kit para cargar vehículo eléctrico"},
    {prodId:"25181600",label:"Chasis automotriz"},
    {prodId:"25181601",label:"Chasis para automóviles"},
    {prodId:"25181602",label:"Chasis para camiones"},
    {prodId:"25181603",label:"Bastidores de motos"},
    {prodId:"25181604",label:"Carrocería de camión de carga"},
    {prodId:"25181605",label:"Carrocería de volqueta"},
    {prodId:"25181606",label:"Carrocería de tráiler"},
    {prodId:"25181607",label:"Cabina de tractor"},
    {prodId:"25181608",label:"Bastidor de vehículo"},
    {prodId:"25181609",label:"Tráiler granelero"},
    {prodId:"25181610",label:"Tráiler para carga aérea"},
    {prodId:"25181611",label:"Tráiler dolly"},
    {prodId:"25181612",label:"Tráiler agrícola"},
    {prodId:"25181700",label:"Remolques para productos y materiales"},
    {prodId:"25181701",label:"Tráiler para contenedores"},
    {prodId:"25181702",label:"Tráiler cama alta"},
    {prodId:"25181703",label:"Remolques para ganado"},
    {prodId:"25181704",label:"Remolques carrotanque sin temperatura controlada"},
    {prodId:"25181705",label:"Remolques carrotanque con temperatura controlada"},
    {prodId:"25181706",label:"Remolques contenedor con temperatura controlada"},
    {prodId:"25181707",label:"Remolques para transporte de automóviles (niñeras)"},
    {prodId:"25181708",label:"Enganches de remolque"},
    {prodId:"25181709",label:"Pala cargadora"},
    {prodId:"25181710",label:"Placas de matrícula de tráiler"},
    {prodId:"25181711",label:"Remolque de motonieve"},
    {prodId:"25181712",label:"Remolque de motocicleta"},
    {prodId:"25181713",label:"Remolque de botes"},
    {prodId:"25181714",label:"Semi - remolque"},
    {prodId:"25181715",label:"Tráiler volqueta"},
    {prodId:"25181716",label:"Chasís tráiler"},
    {prodId:"25181717",label:"Tráiler cama baja"},
    {prodId:"25181718",label:"Tráiler con carrete de cable"},
    {prodId:"25181719",label:"Tráiler refrigerado"},
    {prodId:"25191500",label:"Sistemas y equipo de apoyo para transporte aéreo"},
    {prodId:"25191501",label:"Sistemas de entrenamiento de apoyo terrestre"},
    {prodId:"25191502",label:"Sistemas de ensayo o mantenimiento de apoyo terrestre"},
    {prodId:"25191503",label:"Sistemas integrados de información de mantenimiento"},
    {prodId:"25191504",label:"Simuladores de vuelo para aviones"},
    {prodId:"25191505",label:"Medios de carga y descarga para aviones"},
    {prodId:"25191506",label:"Equipo para recargar combustible de aviones"},
    {prodId:"25191507",label:"Equipo para deshielo de aviones"},
    {prodId:"25191508",label:"Pasarela telescópica para aviones"},
    {prodId:"25191509",label:"Tractores remolcadores para aviones"},
    {prodId:"25191510",label:"Unidades de potencia para pista aérea"},
    {prodId:"25191511",label:"Equipo de lavabos para aeronaves"},
    {prodId:"25191512",label:"Escalerillas transportables o rodantes"},
    {prodId:"25191513",label:"Kit de mantenimiento de vehículo de soporte en tierra"},
    {prodId:"25191514",label:"Barra de remolque de avión"},
    {prodId:"25191515",label:"Sistema de aproximación controlado por tierra"},
    {prodId:"25191516",label:"Unidad neumática para arranque de aeronaves"},
    {prodId:"25191517",label:"Camión calefactor y de aire acondicionado para aeronaves"},
    {prodId:"25191518",label:"Equipo sensor de pruebas aeronáuticas"},
    {prodId:"25191519",label:"Adaptador de remolcado de aviones"},
    {prodId:"25191520",label:"Cámara de prueba ambiental"},
    {prodId:"25191521",label:"Sistema retráctil de soporte de cable gancho"},
    {prodId:"25191522",label:"Set de mantenimiento de motor de aeronave"},
    {prodId:"25191523",label:"Tractor para remolcar contenedores de equipaje aéreo"},
    {prodId:"25191524",label:"Banda transportadora para cargar equipaje en el avión"},
    {prodId:"25191525",label:"Camión para cargar combustible al avión"},
    {prodId:"25191700",label:"Equipo para el mantenimiento de vehículo"},
    {prodId:"25191701",label:"Equipos de balanceo de llantas"},
    {prodId:"25191702",label:"Equipos de alineación de llantas"},
    {prodId:"25191703",label:"Máquinas para cambiar llantas"},
    {prodId:"25191704",label:"Pedestales de vehículo o motor"},
    {prodId:"25191705",label:"Balanza de pistón y varilla de motor"},
    {prodId:"25191706",label:"Gato para levantar material rodante férreo"},
    {prodId:"25191707",label:"Mesa ferroviaria para levantar motor y componente"},
    {prodId:"25191708",label:"Balanceo de carburador"},
    {prodId:"25191709",label:"Ajustador de potencia"},
    {prodId:"25191710",label:"Lapeadora de válvulas"},
    {prodId:"25191711",label:"Pulidora de válvulas"},
    {prodId:"25191712",label:"Cortador de asientos de válvulas"},
    {prodId:"25191713",label:"Dispositivo para reciclar anticongelante"},
    {prodId:"25191714",label:"Torno para frenos de tambor"},
    {prodId:"25191715",label:"Pulidora de zapatas de freno"},
    {prodId:"25191716",label:"Alicate para resortes de freno"},
    {prodId:"25191717",label:"Máquina para enderezar carrocerías de vehículos"},
    {prodId:"25191718",label:"Carrito para reparar carros por debajo"},
    {prodId:"25191719",label:"Llave para tuercas de llantas"},
    {prodId:"25191720",label:"Llave de bujías"},
    {prodId:"25191721",label:"Limpiador de bujías"},
    {prodId:"25191722",label:"Juego de herramientas de motor"},
    {prodId:"25191723",label:"Calentador de pistones"},
    {prodId:"25191724",label:"Kit para reconstruir transmisión automática"},
    {prodId:"25191725",label:"Máquina perforadora para automóviles"},
    {prodId:"25191726",label:"Máquina perfeccionadora para automóviles"},
    {prodId:"25191727",label:"Alineador de varillas de conexión"},
    {prodId:"25191728",label:"Balanceador de cigüeñal"},
    {prodId:"25191729",label:"Pulidora de cigüeñal"},
    {prodId:"25191730",label:"Luz de toma de tiempos"},
    {prodId:"25191731",label:"Limpiador de convertidor de torque"},
    {prodId:"25191732",label:"Perno roscado para pistón"},
    {prodId:"25191733",label:"Pulidora de pistones"},
    {prodId:"25191734",label:"Compresor de resorte de válvula"},
    {prodId:"25191735",label:"Compresor de anillo de pistón"},
    {prodId:"25191736",label:"Simulador de conducción de vehículo"},
    {prodId:"25191737",label:"Inflador automático de llantas"},
    {prodId:"25191738",label:"Abridor de llantas"},
    {prodId:"25191739",label:"Tester de simulación de engranajes de dirección"},
    {prodId:"25191740",label:"Máquina para lavar vehículos"},
    {prodId:"25191741",label:"Máquina para lavar trenes"},
    {prodId:"25191742",label:"Máquina para lavar partes de vehículos"},
    {prodId:"25191743",label:"Máquina para lavar componentes de ferrocarril"},
    {prodId:"25191800",label:"Equipo de medición y prueba de vehículos"},
    {prodId:"25191801",label:"Probador de inducidos"},
    {prodId:"25191802",label:"Tacómetro de ángulo de contacto"},
    {prodId:"25191803",label:"Tester de humo diesel"},
    {prodId:"25191804",label:"Probador de radiador"},
    {prodId:"25191805",label:"Probador de distribuidor de motor"},
    {prodId:"25191806",label:"Hidrómetro de batería"},
    {prodId:"25191807",label:"Detector de fugas de aceite de rodamiento"},
    {prodId:"25191808",label:"Probador de freno"},
    {prodId:"25191809",label:"Probador de ángulo de deslizamiento"},
    {prodId:"25191810",label:"Dinamómetro de chasis"},
    {prodId:"25191811",label:"Probador del velocímetro"},
    {prodId:"25191812",label:"Probador de bloqueo"},
    {prodId:"25191813",label:"Tester de brecha de bujías"},
    {prodId:"25191814",label:"Tester de bujías"},
    {prodId:"25191815",label:"Tester del inducido"},
    {prodId:"25191816",label:"Tester del resorte tipo compresión"},
    {prodId:"25191817",label:"Medidor de presión de la compresión"},
    {prodId:"25191818",label:"Tester del elemento limpiador de aire"},
    {prodId:"25191819",label:"Tester de simulación de los frenos anti bloqueo abs"},
    {prodId:"25191820",label:"Multímetro de motor"},
    {prodId:"25191821",label:"Tester de simulador de motor"},
    {prodId:"25191822",label:"Tester de inyector de motor"},
    {prodId:"25191823",label:"Lecho de prueba de motor"},
    {prodId:"25191824",label:"Probador de puesta a punto del motor"},
    {prodId:"25191825",label:"Medidor de consumo de combustible"},
    {prodId:"25191826",label:"Tester de fugas de aceite"},
    {prodId:"25191827",label:"Tester de simulación de suspensión de control electrónico ecs"},
    {prodId:"25191828",label:"Tester de transmisión automática"},
    {prodId:"25191829",label:"Sistema de inspección de control electrónico"},
    {prodId:"25191830",label:"Tester de luz frontal"},
    {prodId:"25191831",label:"Tester del regulador de ventanas del vehículo"},
    {prodId:"25191832",label:"Medidor de vacío para mantenimiento de vehículo"},
    {prodId:"25191833",label:"Medidor de avance del camber"},
    {prodId:"25191834",label:"Tester de resistencia de bobina de condensación o ohm"},
    {prodId:"25191835",label:"Válvula de presión de ruedas"},
    {prodId:"25191836",label:"Medidor de convergencia de las ruedas delanteras"},
    {prodId:"25191837",label:"Medidor de radio de giro"},
    {prodId:"25191838",label:"Sistema de inspección de vehículos automotores"},
    {prodId:"25201500",label:"Fuselaje y componentes de avión"},
    {prodId:"25201501",label:"Spoilers de avión"},
    {prodId:"25201502",label:"Aletas de avión"},
    {prodId:"25201503",label:"Estabilizadores horizontales de avión"},
    {prodId:"25201504",label:"Aletas canard de avión"},
    {prodId:"25201505",label:"Listones de avión"},
    {prodId:"25201506",label:"Flaps o transmisión de flaps de avión"},
    {prodId:"25201507",label:"Timones de avión"},
    {prodId:"25201508",label:"Elevadores de avión"},
    {prodId:"25201509",label:"Alerones de avión"},
    {prodId:"25201510",label:"Propulsores de avión"},
    {prodId:"25201511",label:"Alas de avión"},
    {prodId:"25201512",label:"Fuselajes de avión"},
    {prodId:"25201513",label:"Cúpulas protectoras de la antena de avión"},
    {prodId:"25201514",label:"Rotores de avión"},
    {prodId:"25201515",label:"Ventilador de elevación de avión"},
    {prodId:"25201516",label:"Escudetes de avión"},
    {prodId:"25201517",label:"Muebles de avión"},
    {prodId:"25201518",label:"Dobladores de avión"},
    {prodId:"25201519",label:"Nervaduras de avión"},
    {prodId:"25201520",label:"Largueros de avión"},
    {prodId:"25201521",label:"Aleta compensadora para aspa de hélice"},
    {prodId:"25201522",label:"Vaina universal de helicóptero"},
    {prodId:"25201600",label:"Sistemas de situación y navegación aeroespacial y componentes"},
    {prodId:"25201601",label:"Sistemas de control digital del altitud del avión"},
    {prodId:"25201602",label:"Faros de navegación de avión"},
    {prodId:"25201603",label:"Sistemas de seguimiento de aire a tierra"},
    {prodId:"25201604",label:"Sistemas de guía aeronáutica"},
    {prodId:"25201605",label:"Controles de volante de avión"},
    {prodId:"25201606",label:"Sistemas de control de la altitud del nave espacial"},
    {prodId:"25201700",label:"Sistemas relacionados con las comunicaciones de vuelo"},
    {prodId:"25201701",label:"Sistemas de comunicación del avión"},
    {prodId:"25201702",label:"Registradores de datos de vuelo"},
    {prodId:"25201703",label:"Contramedidas de avión"},
    {prodId:"25201704",label:"Sistemas de codificación o de decodificación"},
    {prodId:"25201705",label:"Sistemas de telemetría de aeronaves"},
    {prodId:"25201706",label:"Electrónica de interfaz del avión"},
    {prodId:"25201707",label:"Giroscopio del avión"},
    {prodId:"25201708",label:"Cámaras de avión"},
    {prodId:"25201709",label:"Sondas o sensores de avión"},
    {prodId:"25201710",label:"Guías de onda del avión"},
    {prodId:"25202100",label:"Instrumentación de vuelo"},
    {prodId:"25202101",label:"Indicadores de la cabina aeroespacial"},
    {prodId:"25202102",label:"Medidores de la cabina aeroespacial"},
    {prodId:"25202103",label:"Paneles de despliegue aeroespacial de la cabina"},
    {prodId:"25202104",label:"Paneles de interruptores aeroespaciales de la cabina"},
    {prodId:"25202105",label:"Presentación visual de cabeza levantada (hud)"},
    {prodId:"25202200",label:"Sistemas del aterrizaje y de frenos del avión"},
    {prodId:"25202201",label:"Sistemas de frenar del avión"},
    {prodId:"25202202",label:"Rampas de arrastre de avión"},
    {prodId:"25202203",label:"Ruedas de avión"},
    {prodId:"25202204",label:"Montajes del tren de aterrizaje"},
    {prodId:"25202205",label:"Llantas de avión"},
    {prodId:"25202206",label:"Controles anti - deslizamiento de avión"},
    {prodId:"25202207",label:"Contrafuerte del tren de aterrizaje"},
    {prodId:"25202400",label:"Sistemas y depósitos de combustible del avión"},
    {prodId:"25202401",label:"Tanques interiores de combustible del avión"},
    {prodId:"25202402",label:"Depósitos desechables de combustible del avión"},
    {prodId:"25202403",label:"Tanques de propelente del avión"},
    {prodId:"25202404",label:"Sistemas híbridos de almacenaje de combustible de avión"},
    {prodId:"25202405",label:"Sistemas de manejo de combustible del avión"},
    {prodId:"25202406",label:"Post - impulsores"},
    {prodId:"25202500",label:"Equipo de avión"},
    {prodId:"25202501",label:"Sistemas hidráulicos de avión"},
    {prodId:"25202502",label:"Iluminación exterior de avión"},
    {prodId:"25202503",label:"Iluminación interior de avión"},
    {prodId:"25202504",label:"Limpiaparabrisas de avión"},
    {prodId:"25202505",label:"Sistemas de descongelación o sistemas de desempañar a bordo de avión"},
    {prodId:"25202506",label:"Puertas de avión"},
    {prodId:"25202507",label:"Ventanas de avión"},
    {prodId:"25202508",label:"Parabrisas de avión"},
    {prodId:"25202509",label:"Montajes de choque del avión"},
    {prodId:"25202510",label:"Conjuntos de anillo colector del avión"},
    {prodId:"25202600",label:"Sistemas y componentes del control ambiental del avión"},
    {prodId:"25202601",label:"Controladores ambientales del avión"},
    {prodId:"25202602",label:"Reguladores ambientales del avión"},
    {prodId:"25202603",label:"Turbinas para enfriar el avión"},
    {prodId:"25202604",label:"Ventiladores para enfriar el avión"},
    {prodId:"25202605",label:"Intercambiadores de calor del avión"},
    {prodId:"25202606",label:"Separadores del agua del avión"},
    {prodId:"25202607",label:"Equipo del oxígeno del avión"},
    {prodId:"25202700",label:"Acumuladores del avión"},
    {prodId:"25202701",label:"Acumuladores hidráulicos del avión"},
    {prodId:"25202702",label:"Acumuladores neumáticos del avión"},
    {prodId:"26101100",label:"Motores eléctricos de corriente alterna AC"},
    {prodId:"26101101",label:"Motor ac automotriz"},
    {prodId:"26101102",label:"Motor ac de freno"},
    {prodId:"26101103",label:"Motor ac de trabajo de granja"},
    {prodId:"26101105",label:"Motor ac de calefacción y enfriamiento"},
    {prodId:"26101106",label:"Motor ac inversor"},
    {prodId:"26101107",label:"Motor ac de bomba"},
    {prodId:"26101108",label:"Motor ac de compresor"},
    {prodId:"26101109",label:"Motor ac sincrónico"},
    {prodId:"26101110",label:"Motor ac multi - velocidad"},
    {prodId:"26101111",label:"Motor ac de de lavadora a presión"},
    {prodId:"26101112",label:"Motor ac de propósito general"},
    {prodId:"26101113",label:"Motor ac sumergible"},
    {prodId:"26101114",label:"Motor ac de inducción"},
    {prodId:"26101115",label:"Motor ac multi - fase"},
    {prodId:"26101116",label:"Motor ac de fase sencilla"},
    {prodId:"26101117",label:"Motor ac de eje motriz"},
    {prodId:"26101200",label:"Motores eléctricos de corriente directa DC"},
    {prodId:"26101201",label:"Motor dc en derivación"},
    {prodId:"26101202",label:"Motor dc de pasos"},
    {prodId:"26101203",label:"Motor dc sin núcleo"},
    {prodId:"26101204",label:"Motor dc con excitación en derivación"},
    {prodId:"26101205",label:"Servo motor dc"},
    {prodId:"26101206",label:"Motor dc de ángulo de torque limitado"},
    {prodId:"26101207",label:"Motor dc linear"},
    {prodId:"26101208",label:"Motor dc de imán permanente"},
    {prodId:"26101209",label:"Motor dc sin escobillas"},
    {prodId:"26101210",label:"Motor dc de excitación compuesta"},
    {prodId:"26101211",label:"Motor ultrasónico o de vibración"},
    {prodId:"26101212",label:"Motor de huso"},
    {prodId:"26101300",label:"Motores no eléctricos"},
    {prodId:"26101301",label:"Motor de aire"},
    {prodId:"26101302",label:"Alternador"},
    {prodId:"26101303",label:"Dinamotor"},
    {prodId:"26101304",label:"Motor hidráulico"},
    {prodId:"26101306",label:"Motor de cohete líquido"},
    {prodId:"26101309",label:"Motor de cohete sólido"},
    {prodId:"26101310",label:"Motor de torque"},
    {prodId:"26101311",label:"Motor universal"},
    {prodId:"26101312",label:"Motor de pistón axial"},
    {prodId:"26101313",label:"Motor de pistón radial"},
    {prodId:"26101400",label:"Componentes de motores o generadores"},
    {prodId:"26101401",label:"Inducido"},
    {prodId:"26101402",label:"Adaptador de base de motor"},
    {prodId:"26101403",label:"Freno de motor"},
    {prodId:"26101404",label:"Escobilla de motor"},
    {prodId:"26101405",label:"Bobina de motor"},
    {prodId:"26101406",label:"Montura o base de motor"},
    {prodId:"26101408",label:"Poste de motor"},
    {prodId:"26101409",label:"Rotor de motor"},
    {prodId:"26101410",label:"Estator de motor"},
    {prodId:"26101411",label:"Laminación de motor"},
    {prodId:"26101412",label:"Kit de reparación de motor"},
    {prodId:"26101413",label:"Carcasa de motor"},
    {prodId:"26101414",label:"Transportador de escobillas de motor"},
    {prodId:"26101415",label:"Conmutador de motor"},
    {prodId:"26101500",label:"Motores"},
    {prodId:"26101501",label:"Motores hidráulicos"},
    {prodId:"26101502",label:"Motores neumáticos"},
    {prodId:"26101503",label:"Motores a gas"},
    {prodId:"26101504",label:"Motores diesel"},
    {prodId:"26101505",label:"Motores de vapor"},
    {prodId:"26101506",label:"Motores de turbina"},
    {prodId:"26101507",label:"Motores de turbina con hélice"},
    {prodId:"26101508",label:"Motores térmicos"},
    {prodId:"26101509",label:"Motores hidroeléctricos"},
    {prodId:"26101510",label:"Máquinas rotativas"},
    {prodId:"26101511",label:"Motores de turbina hidráulica"},
    {prodId:"26101512",label:"Motores turbohélice"},
    {prodId:"26101513",label:"Kit de reparación de motores"},
    {prodId:"26101514",label:"Motor de jet"},
    {prodId:"26101515",label:"Motor fuera de borda a gasolina"},
    {prodId:"26101700",label:"Accesorios y componentes de motor"},
    {prodId:"26101701",label:"Quemadores para aeronaves"},
    {prodId:"26101702",label:"Compresores de motor de avión"},
    {prodId:"26101703",label:"Difusores de motor de avión"},
    {prodId:"26101704",label:"Monturas de motor"},
    {prodId:"26101705",label:"Gatos de tornillo de los ejes motores del avión"},
    {prodId:"26101706",label:"Unidades de transmisión del avión"},
    {prodId:"26101707",label:"Ejes compensadores"},
    {prodId:"26101708",label:"Seguidores de la leva"},
    {prodId:"26101709",label:"Elevadores de árbol de levas"},
    {prodId:"26101710",label:"Carburadores"},
    {prodId:"26101711",label:"Bielas"},
    {prodId:"26101712",label:"Válvulas de ventilación del cárter"},
    {prodId:"26101713",label:"Culata de cilindro"},
    {prodId:"26101715",label:"Tapas de motor"},
    {prodId:"26101716",label:"Forjas del motor"},
    {prodId:"26101717",label:"Calentadores de motor"},
    {prodId:"26101718",label:"Sistemas de ignición de motor"},
    {prodId:"26101719",label:"Súper cargadores"},
    {prodId:"26101720",label:"Turbocargador"},
    {prodId:"26101721",label:"Poleas del motor"},
    {prodId:"26101723",label:"Lata de vapor de combustible"},
    {prodId:"26101724",label:"Tapón brillante"},
    {prodId:"26101725",label:"Medidores de varilla de aceite"},
    {prodId:"26101726",label:"Coladores de aceite"},
    {prodId:"26101727",label:"Anillo de pistón"},
    {prodId:"26101728",label:"Tubos de varilla de empuje"},
    {prodId:"26101729",label:"Bolas de brazo oscilante"},
    {prodId:"26101730",label:"Ejes de brazo oscilante"},
    {prodId:"26101731",label:"Brazos oscilantes"},
    {prodId:"26101732",label:"Bujía de encendido"},
    {prodId:"26101733",label:"Chorro de carburador"},
    {prodId:"26101734",label:"Diafragmas de carburador"},
    {prodId:"26101735",label:"Batea de aceite"},
    {prodId:"26101736",label:"Pistones"},
    {prodId:"26101737",label:"Cadena de distribución"},
    {prodId:"26101738",label:"Múltiples de entrada"},
    {prodId:"26101740",label:"Inyectores de combustible"},
    {prodId:"26101741",label:"Manguitos de motor"},
    {prodId:"26101742",label:"Distribuidores de inyección de combustible"},
    {prodId:"26101743",label:"Válvulas de motor"},
    {prodId:"26101747",label:"Varas de empuje"},
    {prodId:"26101748",label:"Volante de inercia del motor"},
    {prodId:"26101749",label:"Cigüeñal"},
    {prodId:"26101750",label:"Válvula de estrangulación"},
    {prodId:"26101751",label:"Controles electrónicos de motor"},
    {prodId:"26101754",label:"Asiento de válvula del motor"},
    {prodId:"26101755",label:"Guía de válvula"},
    {prodId:"26101756",label:"Adaptadores del carburador"},
    {prodId:"26101757",label:"Accesorios de las bujías"},
    {prodId:"26101758",label:"Ajustadores del balancín"},
    {prodId:"26101759",label:"Adaptadores del motor de arranque"},
    {prodId:"26101760",label:"Vástagos del estárter"},
    {prodId:"26101761",label:"Tapones del árbol de levas"},
    {prodId:"26101762",label:"Varillaje de los componentes del motor"},
    {prodId:"26101763",label:"Tapones del anticongelante"},
    {prodId:"26101764",label:"Revestimiento de los cilindros"},
    {prodId:"26101765",label:"Amortiguadores de vibración"},
    {prodId:"26101766",label:"Reguladores"},
    {prodId:"26101767",label:"Estator de turbina"},
    {prodId:"26101768",label:"Eje de turbina"},
    {prodId:"26101769",label:"Bobina de ignición"},
    {prodId:"26101770",label:"Control electrónico de motor para motores a gasolina"},
    {prodId:"26101771",label:"Control electrónico de motor para motores diesel"},
    {prodId:"26101772",label:"Limpiador de inyector"},
    {prodId:"26101773",label:"Válvula de medición proporcional diesel"},
    {prodId:"26101774",label:"Válvula solenoide ara purgar lata"},
    {prodId:"26101775",label:"Válvula de alta presión diesel"},
    {prodId:"26101776",label:"Bobina del inmovilizador"},
    {prodId:"26101777",label:"Bobina del inyector de combustible gasolina"},
    {prodId:"26101778",label:"Bobina del inyector de combustible diesel"},
    {prodId:"26101779",label:"Arnés pasacables para cableado"},
    {prodId:"26101780",label:"Inyector de aceite o válvula de medición"},
    {prodId:"26101781",label:"Pasador de pistón"},
    {prodId:"26101782",label:"Distribuidor de ignición"},
    {prodId:"26101783",label:"Unión de husillos hidráulicos"},
    {prodId:"26101784",label:"Araña del diferencial"},
    {prodId:"26101785",label:"Elemento colador de aceite"},
    {prodId:"26101786",label:"Alza válvulas"},
    {prodId:"26101787",label:"Válvula y bastidor de admisión"},
    {prodId:"26101900",label:"Componentes de motor de combustión interna"},
    {prodId:"26101903",label:"Árbol de distribución"},
    {prodId:"26101904",label:"Boquilla de inyección de combustible"},
    {prodId:"26101905",label:"Bloque de cilindros"},
    {prodId:"26111500",label:"Transmisión de energía cinética"},
    {prodId:"26111503",label:"Dispositivos de velocidad regulables"},
    {prodId:"26111504",label:"Correas de la transmisión"},
    {prodId:"26111505",label:"Cadenas de la transmisión"},
    {prodId:"26111506",label:"Aparatos de movimiento rectilíneo"},
    {prodId:"26111508",label:"Despegues de energía"},
    {prodId:"26111509",label:"Culatas de transmisión"},
    {prodId:"26111510",label:"Árboles de transmisión"},
    {prodId:"26111512",label:"Ejes"},
    {prodId:"26111513",label:"Cadenas transmisoras de potencia"},
    {prodId:"26111514",label:"Uniones de charnela"},
    {prodId:"26111515",label:"Servo controlador"},
    {prodId:"26111516",label:"Transmisión escalonada o transmisión stepper o graduador escalonado"},
    {prodId:"26111517",label:"Portaengranajes"},
    {prodId:"26111518",label:"Puntal tensor"},
    {prodId:"26111519",label:"Convertidores de torque"},
    {prodId:"26111520",label:"Muñones"},
    {prodId:"26111521",label:"Cabeza del impulsor"},
    {prodId:"26111522",label:"Conjunto del impulsor"},
    {prodId:"26111523",label:"Topes de retención"},
    {prodId:"26111524",label:"Unidades de engranajes"},
    {prodId:"26111525",label:"Transmisiones de tambor"},
    {prodId:"26111527",label:"Sistemas de control de movimiento integrados"},
    {prodId:"26111528",label:"Transmisiones hidrostáticas"},
    {prodId:"26111529",label:"Transmisiones hidrocinéticas"},
    {prodId:"26111530",label:"Leva de transmisión"},
    {prodId:"26111531",label:"Manguitos de la transmisión"},
    {prodId:"26111532",label:"Soportes o conjuntos del eje"},
    {prodId:"26111533",label:"Tensores de cadena"},
    {prodId:"26111534",label:"Cubos de la transmisión"},
    {prodId:"26111535",label:"Tornillos esféricos o conjuntos de tornillos esféricos"},
    {prodId:"26111536",label:"Reductor de velocidad montado sobre eje"},
    {prodId:"26111537",label:"Reductor de velocidad con tornillo"},
    {prodId:"26111538",label:"Reductor de velocidad de hélice"},
    {prodId:"26111539",label:"Reductor de velocidad de hélice y tornillo"},
    {prodId:"26111540",label:"Reductor de velocidad planetario"},
    {prodId:"26111541",label:"Reductor de velocidad cicloidal"},
    {prodId:"26111542",label:"Reductor aumentador de velocidad de bisel"},
    {prodId:"26111543",label:"Reductor de velocidad de tracción"},
    {prodId:"26111544",label:"Controlador de velocidad"},
    {prodId:"26111545",label:"Adaptador de freno de tambor"},
    {prodId:"26111546",label:"Disco de turbina"},
    {prodId:"26111547",label:"Cubo de apriete"},
    {prodId:"26111548",label:"Palanca de cambios"},
    {prodId:"26111549",label:"Escalón del eje"},
    {prodId:"26111600",label:"Generadores de potencia"},
    {prodId:"26111601",label:"Generadores diesel"},
    {prodId:"26111602",label:"Generadores hidroeléctricos"},
    {prodId:"26111603",label:"Generadores eólicos"},
    {prodId:"26111604",label:"Generadores a gas"},
    {prodId:"26111605",label:"Generadores térmicos"},
    {prodId:"26111606",label:"Generadores hidráulicos"},
    {prodId:"26111607",label:"Generadores solares"},
    {prodId:"26111608",label:"Generadores de vapor"},
    {prodId:"26111609",label:"Generador de turbina de gas"},
    {prodId:"26111610",label:"Generador selsyn"},
    {prodId:"26111611",label:"Generador auxiliar"},
    {prodId:"26111612",label:"Generador de impulso"},
    {prodId:"26111613",label:"Generador de marea"},
    {prodId:"26111700",label:"Baterías, pilas y accesorios"},
    {prodId:"26111701",label:"Baterías recargables"},
    {prodId:"26111702",label:"Pilas alcalinas"},
    {prodId:"26111703",label:"Baterías para vehículos"},
    {prodId:"26111704",label:"Cargadores de baterías"},
    {prodId:"26111705",label:"Pilas secas"},
    {prodId:"26111706",label:"Pilas electrónicas"},
    {prodId:"26111707",label:"Baterías de plomo-ácido"},
    {prodId:"26111708",label:"Baterías de ferroníquel"},
    {prodId:"26111709",label:"Baterías de níquel-cadmio"},
    {prodId:"26111710",label:"Bloques de pilas específicas para productos"},
    {prodId:"26111711",label:"Baterías de litio"},
    {prodId:"26111712",label:"Baterías de níquel- hidrógeno"},
    {prodId:"26111713",label:"Baterías térmicas"},
    {prodId:"26111714",label:"Zinc aire"},
    {prodId:"26111715",label:"Batería de carbono zinc"},
    {prodId:"26111716",label:"Batería de oxido de mercurio"},
    {prodId:"26111717",label:"Baterías del manganeso"},
    {prodId:"26111718",label:"Baterías de óxido de plata"},
    {prodId:"26111719",label:"Probadores de baterías"},
    {prodId:"26111720",label:"Soportes de batería"},
    {prodId:"26111721",label:"Baterías de níquel-hidruro metálico"},
    {prodId:"26111722",label:"Adaptador de batería o accesorios"},
    {prodId:"26111723",label:"Puertas, tapas o estantes de baterías"},
    {prodId:"26111724",label:"Kits de herramientas para baterías"},
    {prodId:"26111725",label:"Baterías de níquel-cloruro de sodio"},
    {prodId:"26111726",label:"Unidad de balasto de batería fluorescente"},
    {prodId:"26111727",label:"Descargador de baterías"},
    {prodId:"26111728",label:"Celda estándar"},
    {prodId:"26111729",label:"Unidad de cargador de batería de auto"},
    {prodId:"26111800",label:"Componentes de la transmisión"},
    {prodId:"26111801",label:"Correas en v"},
    {prodId:"26111802",label:"Correas de distribución de engranaje"},
    {prodId:"26111803",label:"Correas redondas"},
    {prodId:"26111804",label:"Correas planas"},
    {prodId:"26111805",label:"Tensores de correa"},
    {prodId:"26111806",label:"Poleas de transmisión"},
    {prodId:"26111807",label:"Polea de distribución"},
    {prodId:"26111808",label:"Trantorque"},
    {prodId:"26111809",label:"Defensas de correa"},
    {prodId:"26111810",label:"Bridas de la polea de distribución"},
    {prodId:"26111811",label:"Banda sincrónica"},
    {prodId:"26111812",label:"Polea sincrónica"},
    {prodId:"26111813",label:"Polea de velocidad variable"},
    {prodId:"26111814",label:"Banda hexagonal"},
    {prodId:"26111815",label:"Polea de cono"},
    {prodId:"26111816",label:"Polea plana"},
    {prodId:"26111900",label:"Embragues"},
    {prodId:"26111901",label:"Embragues de placa"},
    {prodId:"26111902",label:"Embragues de diafragma"},
    {prodId:"26111903",label:"Embrague centrífugo"},
    {prodId:"26111904",label:"Embrague semi centrífugo"},
    {prodId:"26111905",label:"Embrague de rueda libre"},
    {prodId:"26111907",label:"Acoplamiento de fluido"},
    {prodId:"26111908",label:"Embragues de leva"},
    {prodId:"26111909",label:"Embragues eléctricos"},
    {prodId:"26111910",label:"Embragues hidráulicos"},
    {prodId:"26111911",label:"Embrague neumático"},
    {prodId:"26111912",label:"Embrague mecánico"},
    {prodId:"26111913",label:"Unidad de detención de embrague"},
    {prodId:"26111914",label:"Embrague de mandíbula"},
    {prodId:"26111915",label:"Embrague automático"},
    {prodId:"26111916",label:"Embrague semi automático"},
    {prodId:"26111917",label:"Embrague electromagnético"},
    {prodId:"26112000",label:"Piezas y accesorios de embragues"},
    {prodId:"26112001",label:"Placa de presión"},
    {prodId:"26112002",label:"Placa impulsada"},
    {prodId:"26112003",label:"Placas de embrague"},
    {prodId:"26112004",label:"Kits de reparación del embrague"},
    {prodId:"26112100",label:"Sistemas de frenado industriales"},
    {prodId:"26112101",label:"Sistemas de frenos neumáticos o de aire"},
    {prodId:"26112102",label:"Sistemas de frenos hidráulicos"},
    {prodId:"26112103",label:"Sistemas de frenos mecánicos"},
    {prodId:"26112104",label:"Conjuntos de embrague de frenado"},
    {prodId:"26112105",label:"Sistemas de frenado eléctrico"},
    {prodId:"26121500",label:"Alambre eléctrico"},
    {prodId:"26121501",label:"Alambre calentador"},
    {prodId:"26121505",label:"Alambre para artefactos"},
    {prodId:"26121507",label:"Alambre para radio o televisión"},
    {prodId:"26121508",label:"Alambre para automoción o aviación"},
    {prodId:"26121509",label:"Alambre para imanes"},
    {prodId:"26121510",label:"Alambre de trole"},
    {prodId:"26121514",label:"Alambre subterráneo"},
    {prodId:"26121515",label:"Alambre de silicio-amianto (sa)"},
    {prodId:"26121517",label:"Hilo de cobre"},
    {prodId:"26121519",label:"Alambre de aluminio revestido de cobre"},
    {prodId:"26121520",label:"Alambre de cobre-acero"},
    {prodId:"26121521",label:"Alambre de bronce"},
    {prodId:"26121522",label:"Alambre pelado"},
    {prodId:"26121523",label:"Alambre forrado pero no aislado"},
    {prodId:"26121524",label:"Alambre aislado o forrado"},
    {prodId:"26121532",label:"Alambre para interconexiones"},
    {prodId:"26121533",label:"Alambre de kaptan"},
    {prodId:"26121534",label:"Alambre de poliamida"},
    {prodId:"26121536",label:"Cordón de extensión"},
    {prodId:"26121538",label:"Conjunto de cable"},
    {prodId:"26121539",label:"Cables para cableado"},
    {prodId:"26121540",label:"Cable galvanizado"},
    {prodId:"26121541",label:"Conductores de bus"},
    {prodId:"26121542",label:"Cable de instalación"},
    {prodId:"26121543",label:"Cable resistente al calor"},
    {prodId:"26121544",label:"Cable de bajada"},
    {prodId:"26121545",label:"Cable portátil eléctrico"},
    {prodId:"26121546",label:"Cable de cobre recubierto de estaño"},
    {prodId:"26121547",label:"Cable de bronce"},
    {prodId:"26121548",label:"Cable rectangular"},
    {prodId:"26121600",label:"Cables eléctricos y accesorios"},
    {prodId:"26121601",label:"Cable de calentamiento"},
    {prodId:"26121602",label:"Cable submarino"},
    {prodId:"26121603",label:"Cable de mando"},
    {prodId:"26121604",label:"Cable para señales"},
    {prodId:"26121606",label:"Cable coaxial"},
    {prodId:"26121607",label:"Cable de fibra óptica"},
    {prodId:"26121608",label:"Cable aéreo"},
    {prodId:"26121609",label:"Cable de redes"},
    {prodId:"26121610",label:"Cable de bronce"},
    {prodId:"26121611",label:"Cable desnudo"},
    {prodId:"26121612",label:"Cable forrado pero no aislado"},
    {prodId:"26121613",label:"Cable aislado o forrado"},
    {prodId:"26121614",label:"Cable de construcción"},
    {prodId:"26121615",label:"Cable para ser enterrado de forma directa"},
    {prodId:"26121616",label:"Cable de telecomunicaciones"},
    {prodId:"26121617",label:"Cable triaxial"},
    {prodId:"26121618",label:"Cable de poliqueno reticulado"},
    {prodId:"26121619",label:"Cable de floropolímero"},
    {prodId:"26121620",label:"Cable para interconexiones"},
    {prodId:"26121621",label:"Cable de kaptan"},
    {prodId:"26121622",label:"Cable de poliamida"},
    {prodId:"26121623",label:"Cable de radiofrecuencia (rf)"},
    {prodId:"26121624",label:"Cable plano o de cinta"},
    {prodId:"26121628",label:"Cables blindados"},
    {prodId:"26121629",label:"Cable de alimentación"},
    {prodId:"26121630",label:"Accesorios de cable"},
    {prodId:"26121631",label:"Cable coaxial exterior de planta"},
    {prodId:"26121632",label:"Cable de comunicaciones exterior de planta"},
    {prodId:"26121633",label:"Cable de telecomunicaciones exterior de planta"},
    {prodId:"26121634",label:"Cable de cobre"},
    {prodId:"26121635",label:"Rollos de cable"},
    {prodId:"26121636",label:"Cables de alimentación"},
    {prodId:"26121637",label:"Cable de fibra óptica de exterior"},
    {prodId:"26121638",label:"Materiales de engaste"},
    {prodId:"26121639",label:"Multi - cables combinados o a la medida"},
    {prodId:"26121640",label:"Cables resistentes al calor"},
    {prodId:"26121641",label:"Cables de instalación"},
    {prodId:"26121642",label:"Cable de instrumentación"},
    {prodId:"26121643",label:"Cable eléctrico reforzado de acero desnudo"},
    {prodId:"26121644",label:"Cable eléctrico de aluminio desnudo"},
    {prodId:"26121645",label:"Cable eléctrico de cobre desnudo"},
    {prodId:"26121646",label:"Cable de seguimiento y máquina de minería"},
    {prodId:"26121647",label:"Ensamble de cable de seguimiento y máquina de minería"},
    {prodId:"26121648",label:"Kit de terminación de cable de alto voltaje"},
    {prodId:"26121649",label:"Kit de unión de cable de alto voltaje"},
    {prodId:"26121650",label:"Conjunto de cable de fibra óptica"},
    {prodId:"26121651",label:"Cable so resistente al aceite"},
    {prodId:"26121652",label:"Conjunto de cordón eléctrico"},
    {prodId:"26121653",label:"Cable toll"},
    {prodId:"26121654",label:"Cable de frecuencia de carrier"},
    {prodId:"26121655",label:"Cable de fibra óptica simplex y dúplex"},
    {prodId:"26121656",label:"Cable de fibra óptica submarina"},
    {prodId:"26121657",label:"Cable de fibra óptica retardante de fuego"},
    {prodId:"26121658",label:"Cable de transmisión de aluminio conductor resistente al movimiento"},
    {prodId:"26121659",label:"Cable de bajada de servicio cuádruple de aluminio conductor"},
    {prodId:"26121660",label:"Cable de bajada de servicio dúplex de aluminio conductor"},
    {prodId:"26121661",label:"Cable de aluminio conductor subterráneo de entrada de servicio"},
    {prodId:"26121662",label:"Cable de aluminio conductor de entrada de servicio"},
    {prodId:"26121663",label:"Cable sencillo de aluminio conductor de servicio"},
    {prodId:"26121664",label:"Alambre de aluminio conductor recubierto de polietileno"},
    {prodId:"26121665",label:"Cable de aluminio de bajada de servicio triple"},
    {prodId:"26121666",label:"Conjunto de cable de comunicación"},
    {prodId:"26121700",label:"Cableado preformado"},
    {prodId:"26121701",label:"Cableado preformado de panel"},
    {prodId:"26121702",label:"Cableado preformado troncal"},
    {prodId:"26121703",label:"Cableado preformado de comunicación"},
    {prodId:"26121704",label:"Arnés de alambrado especial"},
    {prodId:"26121706",label:"Conjunto coaxial"},
    {prodId:"26121707",label:"Conjunto de cable plano flexible"},
    {prodId:"26121708",label:"Conjunto de cable de batería"},
    {prodId:"26121709",label:"Conjunto de cordón embobinado"},
    {prodId:"26121710",label:"Arnés de cableado sensor oxígeno"},
    {prodId:"26121711",label:"Varilla de pistón"},
    {prodId:"26121800",label:"Cable automotriz"},
    {prodId:"26121802",label:"Cable automotriz de núcleo sencillo de 60 voltios clase a"},
    {prodId:"26121803",label:"Cable automotriz de núcleo sencillo de 60 voltios clase b"},
    {prodId:"26121804",label:"Cable automotriz de núcleo sencillo de 60 voltios clase c"},
    {prodId:"26121805",label:"Cable automotriz de núcleo sencillo de 60 voltios clase d"},
    {prodId:"26121806",label:"Cable automotriz de núcleo sencillo de 60 voltios clase e"},
    {prodId:"26121807",label:"Cable automotriz de núcleo sencillo de 60 voltios clase f"},
    {prodId:"26121808",label:"Cable automotriz de núcleo sencillo de 60 voltios clase g"},
    {prodId:"26121809",label:"Cable automotriz de núcleo sencillo de 60 voltios clase h"},
    {prodId:"26121810",label:"Cable automotriz de núcleo sencillo de 600 voltios clase a"},
    {prodId:"26121811",label:"Cable automotriz de núcleo sencillo de 600 voltios clase b"},
    {prodId:"26121812",label:"Cable automotriz de núcleo sencillo de 600 voltios clase c"},
    {prodId:"26121813",label:"Cable automotriz de núcleo sencillo de 600 voltios clase d"},
    {prodId:"26121814",label:"Cable automotriz de núcleo sencillo de 600 voltios clase e"},
    {prodId:"26121815",label:"Cable automotriz de núcleo sencillo de 600 voltios clase f"},
    {prodId:"26121816",label:"Cable automotriz de núcleo sencillo de 600 voltios clase g"},
    {prodId:"26121817",label:"Cable automotriz de núcleo sencillo de 600 voltios clase h"},
    {prodId:"26121818",label:"Cable automotriz de núcleo múltiple de 60 voltios clase a"},
    {prodId:"26121819",label:"Cable automotriz de núcleo múltiple de 60 voltios clase b"},
    {prodId:"26121820",label:"Cable automotriz de núcleo múltiple de 60 voltios clase c"},
    {prodId:"26121821",label:"Cable automotriz de núcleo múltiple de 60 voltios clase d"},
    {prodId:"26121822",label:"Cable automotriz de núcleo múltiple de 60 voltios clase e"},
    {prodId:"26121823",label:"Cable automotriz de núcleo múltiple de 60 voltios clase f"},
    {prodId:"26121824",label:"Cable automotriz de núcleo múltiple de 60 voltios clase g"},
    {prodId:"26121825",label:"Cable automotriz de núcleo múltiple de 60 voltios clase h"},
    {prodId:"26121826",label:"Cable automotriz de núcleo múltiple de 600 voltios clase a"},
    {prodId:"26121827",label:"Cable automotriz de núcleo múltiple de 600 voltios clase b"},
    {prodId:"26121828",label:"Cable automotriz de núcleo múltiple de 600 voltios clase c"},
    {prodId:"26121829",label:"Cable automotriz de núcleo múltiple de 600 voltios clase d"},
    {prodId:"26121830",label:"Cable automotriz de núcleo múltiple de 600 voltios clase e"},
    {prodId:"26121831",label:"Cable automotriz de núcleo múltiple de 600 voltios clase f"},
    {prodId:"26121832",label:"Cable automotriz de núcleo múltiple de 600 voltios clase g"},
    {prodId:"26121833",label:"Cable automotriz de núcleo múltiple de 600 voltios clase h"},
    {prodId:"26121834",label:"Cable automotriz trenzado de 60 voltios clase a"},
    {prodId:"26121835",label:"Cable automotriz trenzado de 60 voltios clase b"},
    {prodId:"26121836",label:"Cable automotriz trenzado de 60 voltios clase c"},
    {prodId:"26121837",label:"Cable automotriz trenzado de 60 voltios clase d"},
    {prodId:"26121838",label:"Cable automotriz trenzado de 60 voltios clase e"},
    {prodId:"26121839",label:"Cable automotriz trenzado de 60 voltios clase f"},
    {prodId:"26121840",label:"Cable automotriz trenzado de 60 voltios clase g"},
    {prodId:"26121841",label:"Cable automotriz trenzado de 60 voltios clase h"},
    {prodId:"26121842",label:"Cable automotriz trenzado de 600 voltios clase a"},
    {prodId:"26121843",label:"Cable automotriz trenzado de 600 voltios clase b"},
    {prodId:"26121844",label:"Cable automotriz trenzado de 600 voltios clase c"},
    {prodId:"26121845",label:"Cable automotriz trenzado de 600 voltios clase d"},
    {prodId:"26121846",label:"Cable automotriz trenzado de 600 voltios clase e"},
    {prodId:"26121847",label:"Cable automotriz trenzado de 600 voltios clase f"},
    {prodId:"26121848",label:"Cable automotriz trenzado de 600 voltios clase g"},
    {prodId:"26121849",label:"Cable automotriz trenzado de 600 voltios clase h"},
    {prodId:"26121850",label:"Cable automotriz de núcleo múltiple seleccionado de 60 voltios"},
    {prodId:"26121851",label:"Cable automotriz de núcleo múltiple seleccionado de 600 voltios"},
    {prodId:"26121852",label:"Cable automotriz de núcleo de ignición"},
    {prodId:"26131500",label:"Centrales eléctricas"},
    {prodId:"26131501",label:"Centrales eléctricas de diesel"},
    {prodId:"26131502",label:"Centrales eléctricas geotérmicas"},
    {prodId:"26131503",label:"Centrales hidroeléctricas"},
    {prodId:"26131504",label:"Centrales de gas"},
    {prodId:"26131505",label:"Centrales de energía  marina"},
    {prodId:"26131506",label:"Centrales de energía a petróleo"},
    {prodId:"26131507",label:"Centrales de energía solar"},
    {prodId:"26131508",label:"Centrales termoeléctricas"},
    {prodId:"26131509",label:"Central de energía eólica"},
    {prodId:"26131510",label:"Central térmica"},
    {prodId:"26131600",label:"Equipo de cribado o estructuras de tubo de escape"},
    {prodId:"26131601",label:"Pantallas de agua móviles"},
    {prodId:"26131602",label:"Travesaños corredizos"},
    {prodId:"26131603",label:"Rejillas de agua"},
    {prodId:"26131604",label:"Filtros fijos"},
    {prodId:"26131605",label:"Estructuras de toma"},
    {prodId:"26131606",label:"Chimenea de acero"},
    {prodId:"26131607",label:"Chimenea de concreto"},
    {prodId:"26131608",label:"Chimeneas de ventilación o antorchas"},
    {prodId:"26131609",label:"Chimeneas de admisión"},
    {prodId:"26131610",label:"Chimeneas de derivación"},
    {prodId:"26131611",label:"Secciones de silenciador"},
    {prodId:"26131612",label:"Conductos de salida de escape"},
    {prodId:"26131613",label:"Juntas de dilatación de conductos de escape"},
    {prodId:"26131614",label:"Amortiguadores de cierre de la chimenea"},
    {prodId:"26131615",label:"Amortiguadores de desviación de escape"},
    {prodId:"26131616",label:"Amortiguadores de aislamiento de escape"},
    {prodId:"26131617",label:"Amortiguador de veleta"},
    {prodId:"26131618",label:"Amortiguador de cabezal"},
    {prodId:"26131700",label:"Equipo de detección o vigilancia de producción de energía"},
    {prodId:"26131701",label:"Detectores de gases inflamables o peligrosos para generadores"},
    {prodId:"26131702",label:"Detectores de llama de sistemas de combustión de turbina de gas"},
    {prodId:"26131800",label:"Equipo de control de producción de energía"},
    {prodId:"26131801",label:"Paneles de control eléctrico para generadores"},
    {prodId:"26131802",label:"Paneles de control de compresores"},
    {prodId:"26131803",label:"Paneles de protección o control de generadores"},
    {prodId:"26131804",label:"Paneles de control de turbina de gas"},
    {prodId:"26131807",label:"Paneles de control de turbinas de vapor"},
    {prodId:"26131808",label:"Conmutadores de control de carga de subestación"},
    {prodId:"26131811",label:"Reactores de limitación de intensidad"},
    {prodId:"26131812",label:"Conmutadores con aislamiento de gas"},
    {prodId:"26131813",label:"Conmutadores de desconexión de estaciones de maniobra"},
    {prodId:"26131814",label:"Disipador de sobretensiones de estaciones de maniobra"},
    {prodId:"26141700",label:"Equipo para dosimetría"},
    {prodId:"26141701",label:"Dosímetros de cámara de ionización"},
    {prodId:"26141702",label:"Dosímetros"},
    {prodId:"26141703",label:"Sistemas de dosimetría de patrón secundario"},
    {prodId:"26141704",label:"Dosímetros fantasma"},
    {prodId:"26142300",label:"Equipos protectores contra la radiación"},
    {prodId:"26142302",label:"Blindajes de plomo"},
    {prodId:"26142303",label:"Distintivo de película"},
    {prodId:"26142304",label:"Equipo radiográfico"},
    {prodId:"26142306",label:"Recipientes blindados contra la radiación"},
    {prodId:"26142307",label:"Cámaras de plomo para protección contra la radiación"},
    {prodId:"26142308",label:"Ladrillos de plomo para protección contra la radiación"},
    {prodId:"26142310",label:"Cajas selladas con guantes para protección contra la radiación"},
    {prodId:"26142311",label:"Ventanas para blindaje contra la radiación"},
    {prodId:"26142312",label:"Plomo para blindaje contra la radiación"},
    {prodId:"26142400",label:"Equipo para residuos radiactivos"},
    {prodId:"26142401",label:"Compactadores o incineradores para el tratamiento de residuos radiactivos"},
    {prodId:"26142402",label:"Absorbentes de radiaciones nucleares"},
    {prodId:"26142403",label:"Evaporadores, concentradores o secadores de energía atómica"},
    {prodId:"26142404",label:"Sistemas de inter - bloqueo de las puertas"},
    {prodId:"26142405",label:"Sistemas de dosificación de residuos radiactivos"},
    {prodId:"26142406",label:"Sistemas de solidificación de residuos radiactivos"},
    {prodId:"26142407",label:"Sistemas de eliminación de residuos radiactivos"},
    {prodId:"26142408",label:"Instalaciones para el tratamiento de residuos radiactivos"},
    {prodId:"27111500",label:"Herramientas de corte y engarzado y punzones"},
    {prodId:"27111501",label:"Hojas de cuchillo"},
    {prodId:"27111502",label:"Navajas de afeitar"},
    {prodId:"27111503",label:"Cuchillos de diversas aplicaciones"},
    {prodId:"27111504",label:"Navajas de bolsillo"},
    {prodId:"27111505",label:"Sets para manicure"},
    {prodId:"27111506",label:"Cizallas"},
    {prodId:"27111507",label:"Cortadores de metal"},
    {prodId:"27111508",label:"Sierras"},
    {prodId:"27111509",label:"Barrenas"},
    {prodId:"27111510",label:"Herramientas para desforrar"},
    {prodId:"27111511",label:"Cortadores de alambres"},
    {prodId:"27111512",label:"Cortadores de pernos"},
    {prodId:"27111513",label:"Cortadores de manguera"},
    {prodId:"27111514",label:"Cortadores de vidrio"},
    {prodId:"27111515",label:"Taladro de mano"},
    {prodId:"27111516",label:"Alicates de perforación"},
    {prodId:"27111517",label:"Dispensadores o juegos de hojas de cuchilla"},
    {prodId:"27111518",label:"Herramienta para engastar y doblar alambre"},
    {prodId:"27111519",label:"Tijeras para estaño"},
    {prodId:"27111520",label:"Rompetuercas"},
    {prodId:"27111521",label:"Recortadoras de chapa de uña vibratoria"},
    {prodId:"27111522",label:"Herramienta para aboquillar o ensanchar"},
    {prodId:"27111523",label:"Raspador de vidrio"},
    {prodId:"27111524",label:"Cortadora de ribetes"},
    {prodId:"27111525",label:"Tenazas"},
    {prodId:"27111526",label:"Herramienta para quitar rebabas"},
    {prodId:"27111527",label:"Cortador de tacos"},
    {prodId:"27111529",label:"Tijeras aisladas"},
    {prodId:"27111530",label:"Tijeras de pescador"},
    {prodId:"27111531",label:"Tijeras para el pelo"},
    {prodId:"27111532",label:"Tijeras de joyería"},
    {prodId:"27111533",label:"Tijeras de carnicero"},
    {prodId:"27111534",label:"Compás de corte"},
    {prodId:"27111535",label:"Tijeras de alambre"},
    {prodId:"27111536",label:"Cortador de círculos"},
    {prodId:"27111537",label:"Broca para madera"},
    {prodId:"27111538",label:"Broca forstner"},
    {prodId:"27111539",label:"Broca para madera plana"},
    {prodId:"27111540",label:"Broca para baldosa"},
    {prodId:"27111541",label:"Punta guía de bola"},
    {prodId:"27111542",label:"Broca de punto de espuela"},
    {prodId:"27111543",label:"Broca de mampostería"},
    {prodId:"27111544",label:"Sierra de hender"},
    {prodId:"27111545",label:"Sierra de hender biselada"},
    {prodId:"27111546",label:"Sierra de panel"},
    {prodId:"27111547",label:"Sierra de pisos"},
    {prodId:"27111548",label:"Sierra tenon"},
    {prodId:"27111549",label:"Sierra para herraduras"},
    {prodId:"27111550",label:"Sierra de moño"},
    {prodId:"27111551",label:"Sierra de troncos"},
    {prodId:"27111552",label:"Segueta"},
    {prodId:"27111553",label:"Sierra de dos hombres"},
    {prodId:"27111554",label:"Afilador de cuchillas"},
    {prodId:"27111555",label:"Punzón central"},
    {prodId:"27111556",label:"Punzón hueco"},
    {prodId:"27111557",label:"Punzón cónico"},
    {prodId:"27111558",label:"Pinzón de pasador paralelo"},
    {prodId:"27111559",label:"Sierra para metales"},
    {prodId:"27111560",label:"Mini alicates"},
    {prodId:"27111561",label:"Caja de ingletear"},
    {prodId:"27111562",label:"Cortadora de cables"},
    {prodId:"27111600",label:"Herramientas de perfilar"},
    {prodId:"27111601",label:"Mazas de hierro"},
    {prodId:"27111602",label:"Martillos"},
    {prodId:"27111603",label:"Yunques"},
    {prodId:"27111604",label:"Hachas de mano"},
    {prodId:"27111605",label:"Picas"},
    {prodId:"27111607",label:"Herramientas de recalcar"},
    {prodId:"27111608",label:"Enderezadores manuales de cables"},
    {prodId:"27111609",label:"Enderezadores eléctricos de cables"},
    {prodId:"27111610",label:"Componentes de martillo y mazo"},
    {prodId:"27111611",label:"Martillo para picar piedra"},
    {prodId:"27111612",label:"Martillo o mazo que no saca chispas"},
    {prodId:"27111613",label:"Hachuela que no sacan chispas"},
    {prodId:"27111614",label:"Pica que no saca chispas"},
    {prodId:"27111615",label:"Almádena o martillo macho"},
    {prodId:"27111616",label:"Martillo de bola"},
    {prodId:"27111617",label:"Martillo de caucho"},
    {prodId:"27111618",label:"Martillo de garra"},
    {prodId:"27111619",label:"Martillo de bola de cruz y derecho"},
    {prodId:"27111620",label:"Martillo de bola de pasador"},
    {prodId:"27111621",label:"Martillo mazo"},
    {prodId:"27111622",label:"Martillo de carpintería"},
    {prodId:"27111623",label:"Tapador de botellas manual"},
    {prodId:"27111700",label:"Llaves inglesas y guías"},
    {prodId:"27111701",label:"Destornilladores"},
    {prodId:"27111702",label:"Llaves para tuercas"},
    {prodId:"27111703",label:"Juegos de enchufes"},
    {prodId:"27111704",label:"Enchufes"},
    {prodId:"27111705",label:"Llaves de tuercas de boca cerrada"},
    {prodId:"27111706",label:"Llave de tuercas de boca abierta"},
    {prodId:"27111707",label:"Llaves ajustables"},
    {prodId:"27111708",label:"Llaves para tubos"},
    {prodId:"27111709",label:"Extractor de tornillos"},
    {prodId:"27111710",label:"Llaves allen"},
    {prodId:"27111711",label:"Trinquetes"},
    {prodId:"27111712",label:"Extractores"},
    {prodId:"27111713",label:"Llaves de combinación"},
    {prodId:"27111714",label:"Llaves de especialidad"},
    {prodId:"27111715",label:"Llaves de torsión"},
    {prodId:"27111716",label:"Llave torx"},
    {prodId:"27111717",label:"Extractores de tubería"},
    {prodId:"27111718",label:"Extractores de grifo"},
    {prodId:"27111720",label:"Llave manual en t para grifos"},
    {prodId:"27111721",label:"Manivelas"},
    {prodId:"27111722",label:"Troqueleras"},
    {prodId:"27111723",label:"Llaves de tubo"},
    {prodId:"27111724",label:"Llaves de gancho"},
    {prodId:"27111725",label:"Llaves tubulares de desplazamiento"},
    {prodId:"27111726",label:"Llaves de tuercas"},
    {prodId:"27111727",label:"Llaves macho con mango en t"},
    {prodId:"27111728",label:"Juego de destornilladores"},
    {prodId:"27111729",label:"Juego de llaves"},
    {prodId:"27111730",label:"Llave giratoria de dos extremos"},
    {prodId:"27111731",label:"Destornillador de precisión"},
    {prodId:"27111732",label:"Destornillador que no saca chispas"},
    {prodId:"27111733",label:"Destornillador de mango flexible"},
    {prodId:"27111734",label:"Destornillador de múltiples puntas"},
    {prodId:"27111735",label:"Destornillador de trinquete"},
    {prodId:"27111736",label:"Destornillador aislado"},
    {prodId:"27111737",label:"Destornillador manual automático de empujar"},
    {prodId:"27111738",label:"Destornillador tester de voltaje"},
    {prodId:"27111739",label:"Destornillador de torque"},
    {prodId:"27111740",label:"Destornillador telescópico"},
    {prodId:"27111741",label:"Destornillador de mango intercambiable"},
    {prodId:"27111742",label:"Destornillador de impacto"},
    {prodId:"27111743",label:"Llave de anillos"},
    {prodId:"27111744",label:"Llave de compensación de anillos"},
    {prodId:"27111745",label:"Llave de anillo dividido"},
    {prodId:"27111746",label:"Llave bulldog"},
    {prodId:"27111747",label:"Llave de caja"},
    {prodId:"27111748",label:"Llave de huella"},
    {prodId:"27111749",label:"Llave de tubo o stillson"},
    {prodId:"27111750",label:"Pinza de presión"},
    {prodId:"27111751",label:"Llave de grifos"},
    {prodId:"27111752",label:"Llave de obstrucción"},
    {prodId:"27111753",label:"Llave de trinquete"},
    {prodId:"27111754",label:"Llave en s"},
    {prodId:"27111755",label:"Llave en y"},
    {prodId:"27111756",label:"Llave de pata de cuervo"},
    {prodId:"27111757",label:"Llave de golpe especial o slugging"},
    {prodId:"27111758",label:"Llave dinamométrica de disparo automático"},
    {prodId:"27111759",label:"Llave de cuchilla"},
    {prodId:"27111760",label:"Llave de tuercas de llantas"},
    {prodId:"27111761",label:"Llave de bomba de agua"},
    {prodId:"27111762",label:"Llave de trinquete de cabeza flexible"},
    {prodId:"27111763",label:"Llave allen"},
    {prodId:"27111764",label:"Guías de broca"},
    {prodId:"27111765",label:"Llave para destapar tambores"},
    {prodId:"27111766",label:"Llave de rin cruzado"},
    {prodId:"27111767",label:"Destornillador de compensación o en “l”"},
    {prodId:"27111800",label:"Herramientas de medida y bocetaje"},
    {prodId:"27111801",label:"Cintas métricas"},
    {prodId:"27111802",label:"Escalas"},
    {prodId:"27111803",label:"Escuadras"},
    {prodId:"27111804",label:"Plomadas"},
    {prodId:"27111806",label:"Calibrador de clavos"},
    {prodId:"27111807",label:"Bordes rectos"},
    {prodId:"27111809",label:"Biseles"},
    {prodId:"27111810",label:"Buscadores de pernos"},
    {prodId:"27111811",label:"Regla doblable"},
    {prodId:"27111812",label:"Regla larga"},
    {prodId:"27111813",label:"Línea de plomada"},
    {prodId:"27111814",label:"Espejo de inspección"},
    {prodId:"27111815",label:"Escuadra de combinación"},
    {prodId:"27111816",label:"Juego de bola indicadora"},
    {prodId:"27111817",label:"Regla de encogimiento"},
    {prodId:"27111818",label:"Soporte del indicador del"},
    {prodId:"27111819",label:"Espécimen de comparación de rugosidad"},
    {prodId:"27111820",label:"Escuadra maestra"},
    {prodId:"27111821",label:"Escuadra cilíndrica"},
    {prodId:"27111822",label:"Instrumento de medición de ángulos"},
    {prodId:"27111823",label:"Calibrador de centro"},
    {prodId:"27111824",label:"Calibrador de ángulo"},
    {prodId:"27111825",label:"Placa de seno"},
    {prodId:"27111826",label:"Placa de superficie de precisión"},
    {prodId:"27111827",label:"Placa de ángulo ajustable"},
    {prodId:"27111828",label:"Placa de ángulo fijo"},
    {prodId:"27111829",label:"Paralelo óptico"},
    {prodId:"27111900",label:"Herramientas gruesas y de acabado"},
    {prodId:"27111901",label:"Cortafríos"},
    {prodId:"27111903",label:"Cepillos de carpintero"},
    {prodId:"27111904",label:"Raspa"},
    {prodId:"27111905",label:"Esmeriladoras"},
    {prodId:"27111906",label:"Cinceles de madera"},
    {prodId:"27111907",label:"Cepillos de alambre"},
    {prodId:"27111908",label:"Piedras o herramientas o equipos de afilar"},
    {prodId:"27111909",label:"Espátulas"},
    {prodId:"27111910",label:"Buriles"},
    {prodId:"27111911",label:"Formones"},
    {prodId:"27111912",label:"Perforador de madera"},
    {prodId:"27111913",label:"Arreglador de bordes"},
    {prodId:"27111914",label:"Cincel de punta de bola"},
    {prodId:"27111915",label:"Cincel de cantera"},
    {prodId:"27111916",label:"Removedor de techos"},
    {prodId:"27111917",label:"Aplicador de goma"},
    {prodId:"27111918",label:"Lija manual"},
    {prodId:"27111919",label:"Lima bastarda"},
    {prodId:"27111920",label:"Lima de segundo corte"},
    {prodId:"27111921",label:"Lima de corte liso"},
    {prodId:"27111922",label:"Lima de corte en cruz"},
    {prodId:"27111923",label:"Lima de sierra de cadena"},
    {prodId:"27111924",label:"Lima de corte raspa"},
    {prodId:"27111925",label:"Lima de aguja"},
    {prodId:"27111926",label:"Lima plana de aluminio"},
    {prodId:"27111927",label:"Lima de aluminio semicircular"},
    {prodId:"27111928",label:"Lima rallo de queso"},
    {prodId:"27111929",label:"Lima de mano plana"},
    {prodId:"27111930",label:"Lima redonda"},
    {prodId:"27111931",label:"Lima semicircular"},
    {prodId:"27111932",label:"Lima de escuadra"},
    {prodId:"27111933",label:"Lima triangular de tres escuadras"},
    {prodId:"27111934",label:"Lima plana cónica"},
    {prodId:"27111935",label:"Lima de navaja"},
    {prodId:"27111936",label:"Lima de guarda"},
    {prodId:"27111937",label:"Lima laminada"},
    {prodId:"27111938",label:"Lima de torno de ángulo largo"},
    {prodId:"27111939",label:"Lima semicircular para tubería"},
    {prodId:"27111940",label:"Lima de sierra maderera"},
    {prodId:"27111941",label:"Lima de sierra cónica"},
    {prodId:"27111942",label:"Lima de sierra de extremo doble"},
    {prodId:"27111943",label:"Lima de granjero"},
    {prodId:"27111944",label:"Lima de precisión"},
    {prodId:"27111945",label:"Lima curva para grabar troqueles"},
    {prodId:"27111946",label:"Lima curva de platería"},
    {prodId:"27111948",label:"Raspador semicircular de madera"},
    {prodId:"27111949",label:"Raspador semicircular de gabinete"},
    {prodId:"27111950",label:"Raspador circular de madera"},
    {prodId:"27111951",label:"Raspador de zapatas de cuatro en mano"},
    {prodId:"27111952",label:"Raspador de caballos"},
    {prodId:"27111953",label:"Raspador de peletero"},
    {prodId:"27111954",label:"Escariador cónico de vástago de puente"},
    {prodId:"27111955",label:"Escariador redondo de vástago de puente"},
    {prodId:"27111956",label:"Bruñidor"},
    {prodId:"27111957",label:"Limpiador de boquillas"},
    {prodId:"27111958",label:"Lapeador de mano"},
    {prodId:"27112000",label:"Herramientas manuales de jardinería, agricultura y forestación"},
    {prodId:"27112001",label:"Machetes"},
    {prodId:"27112002",label:"Azadones"},
    {prodId:"27112003",label:"Rastrillos"},
    {prodId:"27112004",label:"Palas"},
    {prodId:"27112005",label:"Hachas"},
    {prodId:"27112006",label:"Guadañas"},
    {prodId:"27112007",label:"Tijeras de podar"},
    {prodId:"27112008",label:"Azadas"},
    {prodId:"27112009",label:"Raspadores"},
    {prodId:"27112010",label:"Horquilla de jardín"},
    {prodId:"27112011",label:"Mangos de herramientas"},
    {prodId:"27112012",label:"Criba jardinera"},
    {prodId:"27112013",label:"Excavadora de hoyos para postes"},
    {prodId:"27112014",label:"Cortadora de pasto"},
    {prodId:"27112015",label:"Escarificador de prados"},
    {prodId:"27112016",label:"Tijeras para setos"},
    {prodId:"27112017",label:"Barras de cavar o barretones"},
    {prodId:"27112018",label:"Plantador de bulbos"},
    {prodId:"27112019",label:"Sierra podadora"},
    {prodId:"27112020",label:"Hoz"},
    {prodId:"27112021",label:"Aireador de césped"},
    {prodId:"27112022",label:"Cizalla para bordes"},
    {prodId:"27112023",label:"Cultivador a mano"},
    {prodId:"27112024",label:"Juego de herramientas para jardín en miniatura"},
    {prodId:"27112025",label:"Azada de mango corto"},
    {prodId:"27112026",label:"Cuña de mango"},
    {prodId:"27112027",label:"Escoba de jardín"},
    {prodId:"27112028",label:"Descortezadora de árboles"},
    {prodId:"27112029",label:"Regadera de jardín"},
    {prodId:"27112030",label:"Gancho podador"},
    {prodId:"27112031",label:"Cedazo de jardín"},
    {prodId:"27112032",label:"Azuela"},
    {prodId:"27112033",label:"Juego de herramientas de jardín"},
    {prodId:"27112034",label:"Cortador de raíces"},
    {prodId:"27112035",label:"Cortador de setos"},
    {prodId:"27112036",label:"Trituradora de jardín"},
    {prodId:"27112037",label:"Cortador de brochas"},
    {prodId:"27112038",label:"Sierra de cadena de jardín"},
    {prodId:"27112039",label:"Podadora de 2 manos"},
    {prodId:"27112040",label:"Sonda de suelo"},
    {prodId:"27112041",label:"Cuchillo de injertos"},
    {prodId:"27112042",label:"Medidor de ancho de troncos"},
    {prodId:"27112043",label:"Herramienta para diagnóstico de árboles o resistógrafo"},
    {prodId:"27112044",label:"Juego de inyecciones para troncos de árboles"},
    {prodId:"27112045",label:"Cortador de césped"},
    {prodId:"27112046",label:"Recogedor de pasto cortado"},
    {prodId:"27112047",label:"Cortador de ramas altas"},
    {prodId:"27112100",label:"Herramientas de sujeción y fijación"},
    {prodId:"27112101",label:"Dobladores de tubos"},
    {prodId:"27112102",label:"Tornillos de banco"},
    {prodId:"27112103",label:"Pinza de mano"},
    {prodId:"27112104",label:"Tenazas"},
    {prodId:"27112105",label:"Pinzas"},
    {prodId:"27112106",label:"Alicates de guardalínea"},
    {prodId:"27112107",label:"Alicates boquianchos ajustables"},
    {prodId:"27112108",label:"Alicates de punta de aguja"},
    {prodId:"27112109",label:"Herramientas magnéticas"},
    {prodId:"27112110",label:"Pinzas de anillo de retención"},
    {prodId:"27112111",label:"Alicates de lagarto"},
    {prodId:"27112112",label:"Tenazas de ranura y lengüeta"},
    {prodId:"27112113",label:"Alicates de articulación movible o de ranura"},
    {prodId:"27112114",label:"Pinzas de corte diagonal"},
    {prodId:"27112115",label:"Pinzas de cerrado"},
    {prodId:"27112116",label:"Pinzas de cerca"},
    {prodId:"27112117",label:"Tenazas de corte final"},
    {prodId:"27112119",label:"Cambiador de bombilla de luz"},
    {prodId:"27112120",label:"Grapas c"},
    {prodId:"27112121",label:"Grapa de ángulo"},
    {prodId:"27112122",label:"Alicates de hoja metálica"},
    {prodId:"27112123",label:"Prensa de mesa"},
    {prodId:"27112124",label:"Tensionadores"},
    {prodId:"27112125",label:"Alicates de punta redonda"},
    {prodId:"27112126",label:"Alicates planos"},
    {prodId:"27112127",label:"Llaves de cinta"},
    {prodId:"27112128",label:"Alicates de punta curvada"},
    {prodId:"27112129",label:"Abrazaderas de mango redondo"},
    {prodId:"27112130",label:"Abrazaderas de tres garras"},
    {prodId:"27112131",label:"Abrazadera para la apertura de mandíbula"},
    {prodId:"27112132",label:"Abrazaderas de fijación"},
    {prodId:"27112133",label:"Abrazaderas con mango en t"},
    {prodId:"27112134",label:"Alicates de punta larga"},
    {prodId:"27112135",label:"Alicates aislados"},
    {prodId:"27112136",label:"Alicates que no sacan chispa"},
    {prodId:"27112137",label:"Juego de alicates"},
    {prodId:"27112138",label:"Enderezador manual de tubos"},
    {prodId:"27112139",label:"Alicates de combinación"},
    {prodId:"27112140",label:"Alicates circlip"},
    {prodId:"27112141",label:"Alicates de vidrio"},
    {prodId:"27112142",label:"Alicates muli - herramienta"},
    {prodId:"27112143",label:"Alicates para cortar baldosas"},
    {prodId:"27112144",label:"Alicates de nariz doblada"},
    {prodId:"27112145",label:"Alicates de articulación movible"},
    {prodId:"27112146",label:"Tornillo de base pivote"},
    {prodId:"27112147",label:"Alicates de engaste"},
    {prodId:"27112148",label:"Alicates electrónicos"},
    {prodId:"27112149",label:"Alicates electrostáticos"},
    {prodId:"27112150",label:"Alicates de joyería"},
    {prodId:"27112151",label:"Alicates pelacables"},
    {prodId:"27112152",label:"Almeja triclover"},
    {prodId:"27112153",label:"Engrapadora de banda"},
    {prodId:"27112154",label:"Tornillo fresador"},
    {prodId:"27112155",label:"Expansor de anillo de pistón"},
    {prodId:"27112156",label:"Tornillo de mano"},
    {prodId:"27112157",label:"Tornillo de taladro"},
    {prodId:"27112158",label:"Tornillo de pata"},
    {prodId:"27112159",label:"Tornillo de cadena"},
    {prodId:"27112160",label:"Torcedor de cables"},
    {prodId:"27112161",label:"Herramienta para empacar cables"},
    {prodId:"27112162",label:"Unión para reglas"},
    {prodId:"27112200",label:"Herramientas de albañilería y concreto"},
    {prodId:"27112201",label:"Palustres"},
    {prodId:"27112202",label:"Palustres o llanas de madera"},
    {prodId:"27112203",label:"Bordeadoras"},
    {prodId:"27112205",label:"Vibradores de concreto"},
    {prodId:"27112206",label:"Tabla de mortero"},
    {prodId:"27112207",label:"Ariete de mano"},
    {prodId:"27112208",label:"Pica"},
    {prodId:"27112209",label:"Pala de mampostería"},
    {prodId:"27112210",label:"Unidor de ladrillos"},
    {prodId:"27112211",label:"Llenador de uniones"},
    {prodId:"27112212",label:"Herramienta de frente de brocal"},
    {prodId:"27112213",label:"Chute de concreto"},
    {prodId:"27112215",label:"Alisador de bordes"},
    {prodId:"27112216",label:"Marcador de baldosas"},
    {prodId:"27112217",label:"Partidor de baldosas"},
    {prodId:"27112218",label:"Sierra eléctrica para baldosas"},
    {prodId:"27112219",label:"Pellizcador de baldosas"},
    {prodId:"27112220",label:"Sierra de mano para baldosas"},
    {prodId:"27112221",label:"Broca para baldosas"},
    {prodId:"27112222",label:"Cortador de baldosas"},
    {prodId:"27112223",label:"Lima para baldosas"},
    {prodId:"27112224",label:"Copiador de perfiles para corte"},
    {prodId:"27112225",label:"Esparcidor muescado"},
    {prodId:"27112226",label:"Palustre para baldosas"},
    {prodId:"27112227",label:"Esparcidor de lechada"},
    {prodId:"27112228",label:"Removedor de lechada"},
    {prodId:"27112229",label:"Cincel para remover baldosas"},
    {prodId:"27112230",label:"Llenador de juntas"},
    {prodId:"27112231",label:"Herramienta para escalones"},
    {prodId:"27112232",label:"Ranuradora"},
    {prodId:"27112300",label:"Herramientas de marcar"},
    {prodId:"27112301",label:"Hierro de marcar"},
    {prodId:"27112302",label:"Punzones o alwznas"},
    {prodId:"27112303",label:"Punzón de trazar"},
    {prodId:"27112304",label:"Líneas de marcar con tiza"},
    {prodId:"27112305",label:"Marcadores o soportes de metal"},
    {prodId:"27112306",label:"Sellos de metal"},
    {prodId:"27112307",label:"Tizas para trazar líneas"},
    {prodId:"27112308",label:"Carrito marcador"},
    {prodId:"27112309",label:"Lápiz marcador"},
    {prodId:"27112310",label:"Soporte de tiza"},
    {prodId:"27112311",label:"Tiza para marcar"},
    {prodId:"27112312",label:"Medidor superficial"},
    {prodId:"27112313",label:"Marcador de dimensiones"},
    {prodId:"27112400",label:"Herramientas de inserción y ajuste"},
    {prodId:"27112401",label:"Pistolas de grapas"},
    {prodId:"27112402",label:"Ribeteadoras"},
    {prodId:"27112403",label:"Colocadores de abrazaderas"},
    {prodId:"27112404",label:"Herramientas para poner anclajes"},
    {prodId:"27112405",label:"Calentadores de pernos"},
    {prodId:"27112406",label:"Pistolas de etiquetado"},
    {prodId:"27112407",label:"Desprendedor de etiqueta de seguridad"},
    {prodId:"27112408",label:"Removedor industrial de ganchos"},
    {prodId:"27112409",label:"Pistola remachadora explosiva"},
    {prodId:"27112410",label:"Pistola para amarrar cables"},
    {prodId:"27112500",label:"Herramientas para apalancar y curvar"},
    {prodId:"27112501",label:"Herramientas curvatubos"},
    {prodId:"27112502",label:"Palancas"},
    {prodId:"27112503",label:"Dobladoras de tubos"},
    {prodId:"27112504",label:"Cuñas"},
    {prodId:"27112505",label:"Ganchos para empaquetaduras"},
    {prodId:"27112506",label:"Manchas de impresión"},
    {prodId:"27112507",label:"Varilla patecabra"},
    {prodId:"27112508",label:"Doblador de cables"},
    {prodId:"27112600",label:"Herramientas para precintar"},
    {prodId:"27112601",label:"Espátulas para enmasillar"},
    {prodId:"27112602",label:"Herramientas de calafateado"},
    {prodId:"27112603",label:"Herramienta de inserción"},
    {prodId:"27112604",label:"Recipiente para plastilina"},
    {prodId:"27112700",label:"Herramientas mecánicas"},
    {prodId:"27112701",label:"Fuelles eléctricos"},
    {prodId:"27112702",label:"Pulidoras eléctricas"},
    {prodId:"27112703",label:"Taladradoras eléctricas"},
    {prodId:"27112704",label:"Amoladora eléctricas"},
    {prodId:"27112705",label:"Martillos de demolición"},
    {prodId:"27112706",label:"Cepillos eléctricos"},
    {prodId:"27112707",label:"Cuchillas eléctricas"},
    {prodId:"27112708",label:"Lijadoras eléctricas"},
    {prodId:"27112709",label:"Sierras eléctricas"},
    {prodId:"27112710",label:"Pistolas de tornillos eléctricas"},
    {prodId:"27112711",label:"Pistolas de grapas eléctricas"},
    {prodId:"27112712",label:"Ribeteadoras eléctricas"},
    {prodId:"27112713",label:"Aprietatuercas neumático de percusión"},
    {prodId:"27112714",label:"Pistolas de calafateo eléctricas"},
    {prodId:"27112715",label:"Martillos cinceladores eléctricos"},
    {prodId:"27112716",label:"Pistolas de clavos eléctricas"},
    {prodId:"27112717",label:"Pistolas de calor"},
    {prodId:"27112718",label:"Grabadores"},
    {prodId:"27112719",label:"Pistolas para engomar"},
    {prodId:"27112720",label:"Herramientas de par de torsión"},
    {prodId:"27112721",label:"Juntador de capas de material"},
    {prodId:"27112722",label:"Biseladora"},
    {prodId:"27112723",label:"Cizallas eléctricas"},
    {prodId:"27112724",label:"Pellizcadoras eléctricas"},
    {prodId:"27112725",label:"Ribeteadora eléctrica"},
    {prodId:"27112726",label:"Cortador de varillas eléctrico"},
    {prodId:"27112727",label:"Pistola de mortero"},
    {prodId:"27112728",label:"Soplete eléctrico"},
    {prodId:"27112729",label:"Conector de tubos"},
    {prodId:"27112730",label:"Pistola de grasa eléctrica"},
    {prodId:"27112731",label:"Tijeras eléctricas"},
    {prodId:"27112732",label:"Doblador de tubos eléctrico"},
    {prodId:"27112733",label:"Cortador de ángulos"},
    {prodId:"27112734",label:"Sierra de vaivén"},
    {prodId:"27112735",label:"Sierra de espiral"},
    {prodId:"27112736",label:"Cortador de muros"},
    {prodId:"27112737",label:"Lija orbital"},
    {prodId:"27112738",label:"Lija de detalle"},
    {prodId:"27112739",label:"Lija de banda"},
    {prodId:"27112740",label:"Lija de banca"},
    {prodId:"27112741",label:"Lima lija o lija dedo"},
    {prodId:"27112742",label:"Lija de disco"},
    {prodId:"27112743",label:"Sierra de brazo elástico"},
    {prodId:"27112744",label:"Sierra neumática"},
    {prodId:"27112745",label:"Sierra de concreto"},
    {prodId:"27112746",label:"Sierra de cadena"},
    {prodId:"27112747",label:"Sierra recíproca"},
    {prodId:"27112748",label:"Sierra ingleteadora"},
    {prodId:"27112749",label:"Pulidora de ángulo"},
    {prodId:"27112750",label:"Herramienta compresora a batería"},
    {prodId:"27112800",label:"Conexiones de herramientas y accesorios"},
    {prodId:"27112802",label:"Hojas de sierra"},
    {prodId:"27112803",label:"Fresadoras con mango"},
    {prodId:"27112804",label:"Troqueles o punzones de estampar"},
    {prodId:"27112805",label:"Troqueles de roscado"},
    {prodId:"27112806",label:"Machos de roscado"},
    {prodId:"27112807",label:"Cuñas"},
    {prodId:"27112808",label:"Anillos metálicos"},
    {prodId:"27112809",label:"Portaherramientas"},
    {prodId:"27112810",label:"Equipos para reparar roscas"},
    {prodId:"27112811",label:"Árboles"},
    {prodId:"27112812",label:"Avellanadores"},
    {prodId:"27112813",label:"Vara de extensión"},
    {prodId:"27112814",label:"Brocas de destornillador"},
    {prodId:"27112815",label:"Brocas para poner tuercas"},
    {prodId:"27112818",label:"Sombreretes o revestimientos de prensas de tornillo"},
    {prodId:"27112819",label:"Cuchillas de corte para encuadernación"},
    {prodId:"27112820",label:"Troqueles de herramienta engarzadora de lengüetas"},
    {prodId:"27112821",label:"Brocas de buriladora"},
    {prodId:"27112822",label:"Adaptadores de cubo"},
    {prodId:"27112823",label:"Cadenas de corte"},
    {prodId:"27112824",label:"Portabrocas"},
    {prodId:"27112825",label:"Juegos de plantillas de herramienta"},
    {prodId:"27112826",label:"Caladora"},
    {prodId:"27112827",label:"Dispositivos para imantar y viceversa"},
    {prodId:"27112828",label:"Enchufes de impacto"},
    {prodId:"27112829",label:"Anexos de llave de impacto y sus accesorios"},
    {prodId:"27112830",label:"Accesorios y suministros de destornillador"},
    {prodId:"27112831",label:"Accesorios y suministros de llave"},
    {prodId:"27112832",label:"Anexos t accesorios de enchufe"},
    {prodId:"27112833",label:"Estuche de brocas"},
    {prodId:"27112834",label:"Estuche de roscador manual de tubos"},
    {prodId:"27112835",label:"Escariador de máquina"},
    {prodId:"27112836",label:"Juego de brocas de destornillador"},
    {prodId:"27112837",label:"Escariador manual"},
    {prodId:"27112838",label:"Disco de corte"},
    {prodId:"27112839",label:"Broca de cincel"},
    {prodId:"27112840",label:"Broca guía"},
    {prodId:"27112841",label:"Broca manual para metal"},
    {prodId:"27112842",label:"Broca manual para madera"},
    {prodId:"27112843",label:"Broca manual para piedra"},
    {prodId:"27112844",label:"Placa de lapeado"},
    {prodId:"27112845",label:"Juego de brocas"},
    {prodId:"27112846",label:"Adaptador de pistola de calor"},
    {prodId:"27112847",label:"Estuche de troqueles"},
    {prodId:"27112900",label:"Herramientas dispensadoras"},
    {prodId:"27112901",label:"Pistolas de grasa"},
    {prodId:"27112902",label:"Chimeneas industriales"},
    {prodId:"27112903",label:"Rociador manual"},
    {prodId:"27112904",label:"Pistola de resina"},
    {prodId:"27112905",label:"Lata de aceite"},
    {prodId:"27112906",label:"Pistolas de calafateado"},
    {prodId:"27112907",label:"Separadores de difusión"},
    {prodId:"27112908",label:"Pistola de aceite"},
    {prodId:"27112909",label:"Trapero para techos"},
    {prodId:"27112910",label:"Almacenamiento portátil para desperdicio de aceite"},
    {prodId:"27112911",label:"Copa de aceite"},
    {prodId:"27112912",label:"Cambiador de aceite"},
    {prodId:"27112913",label:"Lubricador de aceite"},
    {prodId:"27112914",label:"Lubricador de grasa"},
    {prodId:"27112915",label:"Dispensador de gas natural"},
    {prodId:"27112916",label:"Dispensador de combustible líquido o bomba de gasolina"},
    {prodId:"27113000",label:"Cepillos"},
    {prodId:"27113001",label:"Cepillos de aruñar"},
    {prodId:"27113002",label:"Cepillos de tubo"},
    {prodId:"27113003",label:"Cepillos de aplicar"},
    {prodId:"27113004",label:"Cepillos de extensor"},
    {prodId:"27113005",label:"Cepillo de techado"},
    {prodId:"27113100",label:"Herramientas de arrastre"},
    {prodId:"27113101",label:"Cinta guía"},
    {prodId:"27113102",label:"Tirador de fusible"},
    {prodId:"27113103",label:"Buril"},
    {prodId:"27113104",label:"Extractores de estacas"},
    {prodId:"27113105",label:"Extensor de resortes"},
    {prodId:"27113200",label:"Juegos de herramientas"},
    {prodId:"27113201",label:"Conjuntos generales de herramientas"},
    {prodId:"27113202",label:"Kit de herramientas para ajustar rodamiento"},
    {prodId:"27113203",label:"Kit de herramienta para computadores"},
    {prodId:"27113204",label:"Kits de electricista"},
    {prodId:"27113300",label:"Herramientas manuales de precisión"},
    {prodId:"27113301",label:"Resortes de retorno a presión"},
    {prodId:"27121500",label:"Prensas hidráulicas"},
    {prodId:"27121501",label:"Suspensiones a presión retornables"},
    {prodId:"27121502",label:"Bastidores de prensa hidráulica"},
    {prodId:"27121503",label:"Columnas de prensa hidráulica"},
    {prodId:"27121504",label:"Prensa hidráulica industrial"},
    {prodId:"27121600",label:"Pistones y cilindros hidráulicos"},
    {prodId:"27121601",label:"Pistones de cilindro"},
    {prodId:"27121602",label:"Cilindros hidráulicos"},
    {prodId:"27121603",label:"Vástagos de pistón de cilindro hidráulico"},
    {prodId:"27121604",label:"Kits de reparación de cilindro hidráulico o sus componentes"},
    {prodId:"27121605",label:"Cuerpos de cilindro hidráulico"},
    {prodId:"27121606",label:"Soportes de montaje para cilindros hidráulicos"},
    {prodId:"27121700",label:"Accesorios de tubería y manga hidráulica"},
    {prodId:"27121701",label:"Conectores hidráulicos rápidos"},
    {prodId:"27121702",label:"Injertos o injertos dobles hidráulicos"},
    {prodId:"27121703",label:"Férulas"},
    {prodId:"27121704",label:"Uniones hidráulicas"},
    {prodId:"27121705",label:"Codos hidráulicos o de compresión"},
    {prodId:"27121706",label:"Tuercas de férula"},
    {prodId:"27121707",label:"Conectores de pliegue"},
    {prodId:"27121800",label:"Herramientas hidráulicas"},
    {prodId:"27121801",label:"Levantadores de cubierta de manhole"},
    {prodId:"27121802",label:"Acumuladores hidráulicos"},
    {prodId:"27121803",label:"Almejas hidráulicas"},
    {prodId:"27121804",label:"Cizallas hidráulicas"},
    {prodId:"27121805",label:"Extractor hidráulico"},
    {prodId:"27121806",label:"Doblador de tubos hidráulico"},
    {prodId:"27121807",label:"Halador hidráulico"},
    {prodId:"27121808",label:"Cortador de cadenas hidráulico"},
    {prodId:"27121809",label:"Herramienta hidráulica para romper tuercas"},
    {prodId:"27121810",label:"Herramienta engastadora hidráulica manual"},
    {prodId:"27121811",label:"Accesorio de herramienta engastadora hidráulica"},
    {prodId:"27121812",label:"Remoto hidráulico"},
    {prodId:"27131500",label:"Herramientas neumáticas"},
    {prodId:"27131501",label:"Llaves de impacto neumático"},
    {prodId:"27131502",label:"Pistola de aire comprimido"},
    {prodId:"27131504",label:"Martillo neumático"},
    {prodId:"27131505",label:"Taladro neumático"},
    {prodId:"27131506",label:"Clavadora de clavos neumática"},
    {prodId:"27131507",label:"Máquinas de lijado neumáticas"},
    {prodId:"27131508",label:"Motores neumáticos"},
    {prodId:"27131509",label:"Acumuladores neumáticos"},
    {prodId:"27131510",label:"Depósitos de acumulador"},
    {prodId:"27131511",label:"Rectificadoras neumáticas"},
    {prodId:"27131512",label:"Destornillador neumático"},
    {prodId:"27131513",label:"Lima neumática"},
    {prodId:"27131514",label:"Alicates neumáticos"},
    {prodId:"27131515",label:"Llave neumática de no - impacto"},
    {prodId:"27131516",label:"Lápiz grabador neumático"},
    {prodId:"27131517",label:"Grapadora neumática industrial"},
    {prodId:"27131518",label:"Herramienta neumática para remover vidrios de autos"},
    {prodId:"27131519",label:"Punzón neumático o de aire"},
    {prodId:"27131520",label:"Cortador neumático de varillas"},
    {prodId:"27131521",label:"Ribeteador neumático"},
    {prodId:"27131522",label:"Lija neumática miniatura"},
    {prodId:"27131523",label:"Martillo neumático"},
    {prodId:"27131524",label:"Avellanadora neumática"},
    {prodId:"27131525",label:"Cizallas neumáticas"},
    {prodId:"27131526",label:"Prensa neumática"},
    {prodId:"27131527",label:"Juego de herramientas neumáticas"},
    {prodId:"27131528",label:"Sacador de puntillas neumático"},
    {prodId:"27131600",label:"Accesorios de aire y conectores"},
    {prodId:"27131601",label:"Múltiples de aire"},
    {prodId:"27131603",label:"Reguladores de aire"},
    {prodId:"27131604",label:"Lubricadores neumáticos"},
    {prodId:"27131605",label:"Cortina de aire"},
    {prodId:"27131608",label:"Conos o almohadillas de vacío"},
    {prodId:"27131609",label:"Silenciadores neumáticos"},
    {prodId:"27131610",label:"Kits de reparación del engrasador o el regulador"},
    {prodId:"27131613",label:"Acoplamiento neumático"},
    {prodId:"27131614",label:"Adaptadores neumáticos"},
    {prodId:"27131615",label:"Generador neumático de vacío"},
    {prodId:"27131700",label:"Cilindros neumáticos y componentes"},
    {prodId:"27131701",label:"Cilindros neumáticos"},
    {prodId:"27131702",label:"Accesorios de vástago de cilindro neumático"},
    {prodId:"27131703",label:"Pistones de cilindro neumático"},
    {prodId:"27131704",label:"Vástagos de pistón de cilindro neumático"},
    {prodId:"27131705",label:"Cuerpo de cilindro neumático"},
    {prodId:"27131706",label:"Capacetes de cilindro neumático"},
    {prodId:"27131707",label:"Kits de reparación de cilindro neumático o sus componentes"},
    {prodId:"27131708",label:"Soportes de montaje para cilindros neumáticos"},
    {prodId:"27131709",label:"Anillos amortiguadores de cilindro neumático"},
    {prodId:"27141000",label:"Herramientas de carrocería"},
    {prodId:"27141001",label:"Herramientas de recorte o moldeo"},
    {prodId:"27141100",label:"Herramientas de suspensión"},
    {prodId:"27141101",label:"Extractor del volante"},
    {prodId:"30101500",label:"Ángulos"},
    {prodId:"30101501",label:"Ángulos de aleación ferrosa"},
    {prodId:"30101502",label:"Ángulos de aleación no ferrosa"},
    {prodId:"30101503",label:"Ángulos de hierro"},
    {prodId:"30101504",label:"Ángulos de acero"},
    {prodId:"30101505",label:"Ángulos de acero inoxidable"},
    {prodId:"30101506",label:"Ángulos de aluminio"},
    {prodId:"30101507",label:"Ángulos de magnesio"},
    {prodId:"30101508",label:"Ángulos de titanio"},
    {prodId:"30101509",label:"Ángulos de cobre"},
    {prodId:"30101510",label:"Ángulos de latón"},
    {prodId:"30101511",label:"Ángulos de bronce"},
    {prodId:"30101512",label:"Ángulos de zinc"},
    {prodId:"30101513",label:"Ángulos de estaño"},
    {prodId:"30101514",label:"Ángulos de plomo"},
    {prodId:"30101515",label:"Ángulos de plástico"},
    {prodId:"30101516",label:"Ángulos de caucho"},
    {prodId:"30101517",label:"Ángulos de metales preciosos"},
    {prodId:"30101700",label:"Vigas"},
    {prodId:"30101701",label:"Vigas de aleación ferrosa"},
    {prodId:"30101702",label:"Vigas de aleación no ferrosa"},
    {prodId:"30101703",label:"Vigas de hierro"},
    {prodId:"30101704",label:"Vigas de acero"},
    {prodId:"30101705",label:"Vigas de acero inoxidable"},
    {prodId:"30101706",label:"Vigas de aluminio"},
    {prodId:"30101707",label:"Vigas de magnesio"},
    {prodId:"30101708",label:"Vigas de titanio"},
    {prodId:"30101709",label:"Vigas de cobre"},
    {prodId:"30101710",label:"Vigas de latón"},
    {prodId:"30101711",label:"Vigas de bronce"},
    {prodId:"30101712",label:"Vigas de zinc"},
    {prodId:"30101713",label:"Vigas de estaño"},
    {prodId:"30101714",label:"Vigas de plomo"},
    {prodId:"30101715",label:"Vigas de plástico"},
    {prodId:"30101716",label:"Vigas de caucho"},
    {prodId:"30101717",label:"Vigas de concreto"},
    {prodId:"30101718",label:"Vigas de metales preciosos"},
    {prodId:"30101719",label:"Vigas de haydita"},
    {prodId:"30101720",label:"Vigas de triplex"},
    {prodId:"30101800",label:"Conductos"},
    {prodId:"30101801",label:"Conductos de aleación ferrosa"},
    {prodId:"30101802",label:"Conductos de aleación no ferrosa"},
    {prodId:"30101803",label:"Conductos de hierro"},
    {prodId:"30101804",label:"Conductos de acero"},
    {prodId:"30101805",label:"Conductos de acero inoxidable"},
    {prodId:"30101806",label:"Conductos de aluminio"},
    {prodId:"30101807",label:"Conductos de magnesio"},
    {prodId:"30101808",label:"Conductos de titanio"},
    {prodId:"30101809",label:"Conductos de cobre"},
    {prodId:"30101810",label:"Conductos de latón"},
    {prodId:"30101811",label:"Conductos de bronce"},
    {prodId:"30101812",label:"Conductos de zinc"},
    {prodId:"30101813",label:"Conductos de estaño"},
    {prodId:"30101814",label:"Conductos de plomo"},
    {prodId:"30101815",label:"Conductos de plástico"},
    {prodId:"30101816",label:"Conductos de caucho"},
    {prodId:"30101817",label:"Conductos de metales preciosos"},
    {prodId:"30101818",label:"Conductos de fibra de vidrio"},
    {prodId:"30102000",label:"Hoja"},
    {prodId:"30102001",label:"Lámina de aleación ferrosa"},
    {prodId:"30102002",label:"Lámina de aleación no ferrosa"},
    {prodId:"30102003",label:"Lámina de hierro"},
    {prodId:"30102004",label:"Lámina de acero"},
    {prodId:"30102005",label:"Lámina de acero inoxidable"},
    {prodId:"30102006",label:"Lámina de aluminio"},
    {prodId:"30102007",label:"Lámina de magnesio"},
    {prodId:"30102008",label:"Lámina de titanio"},
    {prodId:"30102009",label:"Lámina de cobre"},
    {prodId:"30102010",label:"Lámina de latón"},
    {prodId:"30102011",label:"Lámina de bronce"},
    {prodId:"30102012",label:"Lámina de zinc"},
    {prodId:"30102013",label:"Lámina de estaño"},
    {prodId:"30102014",label:"Lámina de plomo"},
    {prodId:"30102015",label:"Lámina de plástico"},
    {prodId:"30102016",label:"Lámina de tántalo"},
    {prodId:"30102200",label:"Plancha"},
    {prodId:"30102201",label:"Placa de aleación ferrosa"},
    {prodId:"30102202",label:"Placa de aleación no ferrosa"},
    {prodId:"30102203",label:"Placa de hierro"},
    {prodId:"30102204",label:"Placa de acero"},
    {prodId:"30102205",label:"Placa de acero inoxidable"},
    {prodId:"30102206",label:"Placa de aluminio"},
    {prodId:"30102207",label:"Placa de magnesio"},
    {prodId:"30102208",label:"Placa de titanio"},
    {prodId:"30102209",label:"Placa de cobre"},
    {prodId:"30102210",label:"Placa de latón"},
    {prodId:"30102211",label:"Placa de bronce"},
    {prodId:"30102212",label:"Placa de zinc"},
    {prodId:"30102213",label:"Placa de estaño"},
    {prodId:"30102214",label:"Placa de plomo"},
    {prodId:"30102215",label:"Placa de plástico"},
    {prodId:"30102216",label:"Placa de caucho"},
    {prodId:"30102217",label:"Placa de concreto"},
    {prodId:"30102218",label:"Placa de metales preciosos"},
    {prodId:"30102220",label:"Placa de níquel"},
    {prodId:"30102221",label:"Placa de fibra"},
    {prodId:"30102222",label:"Placa de viruta"},
    {prodId:"30102223",label:"Placa de enchape de madera"},
    {prodId:"30102224",label:"Placa de tablero de papel o madera (coreboard)"},
    {prodId:"30102225",label:"Placa de corcho"},
    {prodId:"30102226",label:"Placa de butilo"},
    {prodId:"30102227",label:"Placa de tántalo"},
    {prodId:"30102300",label:"Perfiles"},
    {prodId:"30102301",label:"Perfiles de aleación ferrosa"},
    {prodId:"30102302",label:"Perfiles de aleación no ferrosa"},
    {prodId:"30102303",label:"Perfiles de hierro"},
    {prodId:"30102304",label:"Perfiles de acero"},
    {prodId:"30102305",label:"Perfiles de acero inoxidable"},
    {prodId:"30102306",label:"Perfiles de aluminio"},
    {prodId:"30102307",label:"Perfiles de magnesio"},
    {prodId:"30102308",label:"Perfiles de titanio"},
    {prodId:"30102309",label:"Perfiles de cobre"},
    {prodId:"30102310",label:"Perfiles de latón"},
    {prodId:"30102311",label:"Perfiles de bronce"},
    {prodId:"30102312",label:"Perfiles de zinc"},
    {prodId:"30102313",label:"Perfiles de estaño"},
    {prodId:"30102314",label:"Perfiles de plomo"},
    {prodId:"30102315",label:"Perfiles de plástico"},
    {prodId:"30102316",label:"Perfiles de caucho"},
    {prodId:"30102400",label:"Varillas"},
    {prodId:"30102401",label:"Varillas de aleación ferrosa"},
    {prodId:"30102402",label:"Varillas de aleación no ferrosa"},
    {prodId:"30102403",label:"Varillas de hierro"},
    {prodId:"30102404",label:"Varillas de acero"},
    {prodId:"30102405",label:"Varillas de acero inoxidable"},
    {prodId:"30102406",label:"Varillas de aluminio"},
    {prodId:"30102407",label:"Varillas de magnesio"},
    {prodId:"30102408",label:"Varillas de titanio"},
    {prodId:"30102409",label:"Varillas de cobre"},
    {prodId:"30102410",label:"Varillas de latón"},
    {prodId:"30102411",label:"Varillas de bronce"},
    {prodId:"30102412",label:"Varillas de zinc"},
    {prodId:"30102413",label:"Varillas de estaño"},
    {prodId:"30102414",label:"Varillas de plomo"},
    {prodId:"30102415",label:"Varillas de plástico"},
    {prodId:"30102416",label:"Varillas de caucho"},
    {prodId:"30102417",label:"Varillas de níquel"},
    {prodId:"30102800",label:"Pilotaje"},
    {prodId:"30102801",label:"Pilares de aluminio"},
    {prodId:"30102802",label:"Pilares de concreto"},
    {prodId:"30102803",label:"Pilares de acero"},
    {prodId:"30102804",label:"Pilares de madera"},
    {prodId:"30102900",label:"Pilares"},
    {prodId:"30102901",label:"Postes de cemento o concreto"},
    {prodId:"30102903",label:"Postes de metal"},
    {prodId:"30102904",label:"Postes de madera"},
    {prodId:"30102905",label:"Postes de plástico"},
    {prodId:"30102906",label:"Postes de fibra de vidrio"},
    {prodId:"30102907",label:"Postes de madera tratada"},
    {prodId:"30103100",label:"Rieles"},
    {prodId:"30103101",label:"Rieles de acero"},
    {prodId:"30103102",label:"Rieles de aluminio"},
    {prodId:"30103103",label:"Rieles de metal"},
    {prodId:"30103104",label:"Rieles de madera"},
    {prodId:"30103200",label:"Enrejado"},
    {prodId:"30103201",label:"Rejilla de acero"},
    {prodId:"30103202",label:"Rejilla de acero inoxidable"},
    {prodId:"30103203",label:"Rejilla de aluminio"},
    {prodId:"30103204",label:"Rejilla de fibra de vidrio"},
    {prodId:"30103205",label:"Rejilla de hierro"},
    {prodId:"30103206",label:"Rejilla de plástico"},
    {prodId:"30103207",label:"Rejilla laminada"},
    {prodId:"30103208",label:"Rejilla de madera"},
    {prodId:"30103500",label:"Alma de panal"},
    {prodId:"30103501",label:"Núcleo de panal de aluminio"},
    {prodId:"30103502",label:"Núcleo de panal de magnesio"},
    {prodId:"30103503",label:"Núcleo de panal de espuma"},
    {prodId:"30103504",label:"Núcleo de panal de plástico"},
    {prodId:"30103505",label:"Núcleo de panal de madera"},
    {prodId:"30103506",label:"Núcleo de panal de metal ferroso"},
    {prodId:"30103507",label:"Núcleo de panal de bronce"},
    {prodId:"30103508",label:"Núcleo de panal de cobre"},
    {prodId:"30103509",label:"Núcleo de panal de acero"},
    {prodId:"30103510",label:"Núcleo de panal de plomo"},
    {prodId:"30103511",label:"Núcleo de panal de zinc"},
    {prodId:"30103512",label:"Núcleo de panal de titanio"},
    {prodId:"30103513",label:"Núcleo de panal de latón"},
    {prodId:"30103514",label:"Núcleo de panal de metal no ferroso"},
    {prodId:"30103515",label:"Núcleo de panal de metales preciosos"},
    {prodId:"30103600",label:"Productos estructurales"},
    {prodId:"30103601",label:"Vigas de madera"},
    {prodId:"30103602",label:"Vigas compuestas de madera"},
    {prodId:"30103603",label:"Madera para marcos"},
    {prodId:"30103604",label:"Revestimiento o láminas de madera"},
    {prodId:"30103605",label:"Tablones de madera"},
    {prodId:"30103606",label:"Armazones de madera"},
    {prodId:"30103607",label:"Viguetas de madera"},
    {prodId:"30103608",label:"Postes de madera o postes telefónicos"},
    {prodId:"30103609",label:"Tabla de tabique de ventilación"},
    {prodId:"30103610",label:"Bloque de soporte en minas"},
    {prodId:"30103611",label:"Esteras de troncos"},
    {prodId:"30103612",label:"Vínculos de ferrocarril"},
    {prodId:"30103613",label:"Elemento prefabricado de hyadita"},
    {prodId:"30103614",label:"Vigueta de placa de acero"},
    {prodId:"30103615",label:"Viga de placa"},
    {prodId:"30103616",label:"Marco compuesto"},
    {prodId:"30103617",label:"Marco de madera"},
    {prodId:"30103618",label:"Marco de acero"},
    {prodId:"30103619",label:"Elemento de concreto prefabricado"},
    {prodId:"30103620",label:"Bloque de soporte en minas tratado"},
    {prodId:"30103621",label:"Vínculo de ferrocarril no tratado"},
    {prodId:"30103622",label:"Troncos cuadrados"},
    {prodId:"30103623",label:"Barra o malla de refuerzo"},
    {prodId:"30103700",label:"Trenza"},
    {prodId:"30103701",label:"Trenza de acero inoxidable"},
    {prodId:"30103800",label:"Fibras y filamentos mecánicos"},
    {prodId:"30103801",label:"Fibra de acero"},
    {prodId:"30103802",label:"Fibra de estaño"},
    {prodId:"30103803",label:"Fibra de aluminio"},
    {prodId:"30103804",label:"Fibra de aleación"},
    {prodId:"30103900",label:"Ejes"},
    {prodId:"30103901",label:"Socavón con cabeza"},
    {prodId:"30111500",label:"Concreto y morteros"},
    {prodId:"30111501",label:"Concreto aireado"},
    {prodId:"30111502",label:"Concreto conductor"},
    {prodId:"30111503",label:"Concreto aislante"},
    {prodId:"30111504",label:"Morteros"},
    {prodId:"30111505",label:"Concreto premezclado"},
    {prodId:"30111506",label:"Lechada"},
    {prodId:"30111507",label:"Concreto curado en suelo"},
    {prodId:"30111508",label:"Concreto permeable al agua"},
    {prodId:"30111509",label:"Concreto basado en asfalto"},
    {prodId:"30111600",label:"Cemento y cal"},
    {prodId:"30111601",label:"Cemento"},
    {prodId:"30111602",label:"Cal clorada"},
    {prodId:"30111603",label:"Cal hidráulica"},
    {prodId:"30111604",label:"Cal apagada"},
    {prodId:"30111605",label:"Cal magra"},
    {prodId:"30111607",label:"Cal viva"},
    {prodId:"30111700",label:"Yesos"},
    {prodId:"30111701",label:"Enlucido de yeso"},
    {prodId:"30111800",label:"Agregados"},
    {prodId:"30111801",label:"Agregado natural"},
    {prodId:"30111802",label:"Agregado artificial"},
    {prodId:"30111900",label:"Elementos para reforzar concreto"},
    {prodId:"30111901",label:"Fibra para reforzar concreto"},
    {prodId:"30111902",label:"Junta de expansión de construcción"},
    {prodId:"30111903",label:"Lámina de malla de alambre"},
    {prodId:"30121500",label:"Derivados bituminosos"},
    {prodId:"30121501",label:"Alquitrán de carbón hulla"},
    {prodId:"30121503",label:"Creosota"},
    {prodId:"30121504",label:"Bitumen"},
    {prodId:"30121600",label:"Asfaltos"},
    {prodId:"30121601",label:"Asfalto"},
    {prodId:"30121602",label:"Brea"},
    {prodId:"30121603",label:"Gilsonita"},
    {prodId:"30121604",label:"Residuos de alquitrán o petróleo"},
    {prodId:"30121605",label:"Marcos de pozo con tapas del registro"},
    {prodId:"30121700",label:"Materiales de construcción de caminos y carrileras"},
    {prodId:"30121701",label:"Geomalla"},
    {prodId:"30121702",label:"Geotextil"},
    {prodId:"30121703",label:"Riel de puente"},
    {prodId:"30121704",label:"Losa de concreto"},
    {prodId:"30121705",label:"Losa de pavimento"},
    {prodId:"30121706",label:"Borde de concreto"},
    {prodId:"30121707",label:"Tablero de protección contra el ruido"},
    {prodId:"30121708",label:"Baldosa de ladrillo"},
    {prodId:"30121709",label:"Roca triturada sin grado"},
    {prodId:"30121710",label:"Piedra de pavimento"},
    {prodId:"30121711",label:"Borde de piedra natural"},
    {prodId:"30121712",label:"Cubierta de poste"},
    {prodId:"30121713",label:"Tapa de manhole"},
    {prodId:"30121714",label:"Caja de manhole"},
    {prodId:"30121715",label:"Junta de expansión de puente"},
    {prodId:"30121716",label:"Montura de asiento de puente"},
    {prodId:"30121717",label:"Baranda"},
    {prodId:"30121718",label:"Cerca de seguridad y red cara la caída de rocas"},
    {prodId:"30121719",label:"Separador de seguridad de calle"},
    {prodId:"30121800",label:"Materiales de arquitectura paisajística"},
    {prodId:"30121801",label:"Rejilla para árbol"},
    {prodId:"30121802",label:"Varilla de protección de árbol"},
    {prodId:"30121803",label:"Prado artificial"},
    {prodId:"30131500",label:"Bloques"},
    {prodId:"30131502",label:"Bloques de concreto"},
    {prodId:"30131503",label:"Bloques de piedra"},
    {prodId:"30131504",label:"Bloques de cerámica"},
    {prodId:"30131505",label:"Bloques de haydita"},
    {prodId:"30131506",label:"Losa de roca natural"},
    {prodId:"30131507",label:"Bloque de concreto liviano"},
    {prodId:"30131508",label:"Bloque de vidrio"},
    {prodId:"30131509",label:"Bloque a prueba de sonido"},
    {prodId:"30131510",label:"Bloque de concreto para revestimiento"},
    {prodId:"30131511",label:"Unidad de concreto de escollera"},
    {prodId:"30131512",label:"Bloque de concreto aireado de peso liviano esterilizado en autoclave"},
    {prodId:"30131513",label:"Bloque de caucho"},
    {prodId:"30131514",label:"Bloque de culvert construido de concreto reforzado"},
    {prodId:"30131515",label:"Bloque de madera"},
    {prodId:"30131516",label:"Bloque braille"},
    {prodId:"30131517",label:"Bloque de adobe"},
    {prodId:"30131600",label:"Ladrillos"},
    {prodId:"30131602",label:"Ladrillos de cerámica"},
    {prodId:"30131603",label:"Ladrillos de concreto"},
    {prodId:"30131604",label:"Ladrillos de piedra"},
    {prodId:"30131605",label:"Ladrillo sílico - calcáreo"},
    {prodId:"30131606",label:"Ladrillo de loess"},
    {prodId:"30131607",label:"Ladrillo de arcilla"},
    {prodId:"30131608",label:"Ladrillo vitrificado con sal"},
    {prodId:"30131609",label:"Ladrillo manufacturado"},
    {prodId:"30131610",label:"Ladrillo antifuego"},
    {prodId:"30131700",label:"Azulejos y baldosas"},
    {prodId:"30131702",label:"Losas o baldosas de piedra"},
    {prodId:"30131703",label:"Losas o baldosas de concreto"},
    {prodId:"30131704",label:"Losas o baldosas de cerámica"},
    {prodId:"30131705",label:"Lápidas"},
    {prodId:"30131706",label:"Losas o baldosas de metal"},
    {prodId:"30141500",label:"Aislamiento térmico"},
    {prodId:"30141501",label:"Burletes"},
    {prodId:"30141503",label:"Aislamiento de espuma"},
    {prodId:"30141505",label:"Revestimiento de aislamiento térmico"},
    {prodId:"30141508",label:"Aislamiento de fibra"},
    {prodId:"30141510",label:"Barrido de puerta"},
    {prodId:"30141511",label:"Película de ventana"},
    {prodId:"30141512",label:"Kits de aislamiento térmico"},
    {prodId:"30141513",label:"Ladrillos aislantes térmicos"},
    {prodId:"30141514",label:"Aislación de poliestireno expandido eps"},
    {prodId:"30141515",label:"Aislación de poliestireno extruido xps"},
    {prodId:"30141516",label:"Aislación de lana mineral"},
    {prodId:"30141600",label:"Aislamiento especial"},
    {prodId:"30141601",label:"Aislación acústica"},
    {prodId:"30141603",label:"Escudos térmicas"},
    {prodId:"30141604",label:"Aislación por recubrimiento aerosol"},
    {prodId:"30141605",label:"Material a prueba de incendios"},
    {prodId:"30141700",label:"Capas de sellamiento de aislamiento"},
    {prodId:"30141701",label:"Capa sellante de tabla de asfalto"},
    {prodId:"30141702",label:"Capa sellante plástica"},
    {prodId:"30141703",label:"Capa sellante ce caucho"},
    {prodId:"30151500",label:"Material para tejados y techos"},
    {prodId:"30151501",label:"Techado prearmado"},
    {prodId:"30151502",label:"Valles de techo"},
    {prodId:"30151503",label:"Textiles de techado"},
    {prodId:"30151505",label:"Membranas para techos"},
    {prodId:"30151507",label:"Huelgos"},
    {prodId:"30151508",label:"Tejas de asfalto"},
    {prodId:"30151509",label:"Armazones"},
    {prodId:"30151510",label:"Tejados de pizarra"},
    {prodId:"30151511",label:"Tejas de concreto"},
    {prodId:"30151512",label:"Tejas de ladrillo"},
    {prodId:"30151513",label:"Tejas de cerámica"},
    {prodId:"30151514",label:"Tejas metálicas"},
    {prodId:"30151515",label:"Tejas de madera"},
    {prodId:"30151700",label:"Canalones de tejado y accesorios"},
    {prodId:"30151701",label:"Boquillas de bajantes"},
    {prodId:"30151702",label:"Dinteles para goteo"},
    {prodId:"30151703",label:"Canaletas"},
    {prodId:"30151704",label:"Bloques contra salpicaduras"},
    {prodId:"30151800",label:"Materiales para revestimiento de paredes y exterior"},
    {prodId:"30151801",label:"Postigos"},
    {prodId:"30151802",label:"Recubrimiento"},
    {prodId:"30151803",label:"Empalmes de revestimiento de paredes"},
    {prodId:"30151805",label:"Estucado"},
    {prodId:"30151806",label:"Muros de bloque de vidrio"},
    {prodId:"30151807",label:"Materiales para terminados exteriores"},
    {prodId:"30151808",label:"Molde de ladrillo"},
    {prodId:"30151900",label:"Materiales y productos para acabados"},
    {prodId:"30151901",label:"Toldos"},
    {prodId:"30151902",label:"Marquesinas"},
    {prodId:"30152000",label:"Cercado"},
    {prodId:"30152001",label:"Cercado de metal"},
    {prodId:"30152002",label:"Cercado de madera"},
    {prodId:"30152003",label:"Vallado de fibrocemento"},
    {prodId:"30161500",label:"Materiales para acabados de paredes"},
    {prodId:"30161501",label:"Fibra prensada"},
    {prodId:"30161502",label:"Papel de colgadura"},
    {prodId:"30161503",label:"Drywall"},
    {prodId:"30161504",label:"Protectores de esquinas"},
    {prodId:"30161505",label:"Paneles o empanelado"},
    {prodId:"30161508",label:"Rodillo de papel de colgadura"},
    {prodId:"30161509",label:"Tabla de yeso"},
    {prodId:"30161510",label:"Cobertura de pared plástica"},
    {prodId:"30161511",label:"Tela de pared"},
    {prodId:"30161600",label:"Materiales para techos"},
    {prodId:"30161601",label:"Baldosines acústicos para techos"},
    {prodId:"30161602",label:"Paneles para techos"},
    {prodId:"30161603",label:"Encofrados"},
    {prodId:"30161604",label:"Sistemas de cielo raso"},
    {prodId:"30161700",label:"Suelos"},
    {prodId:"30161701",label:"Alfombrado"},
    {prodId:"30161702",label:"Pisos de madera"},
    {prodId:"30161703",label:"Linóleo"},
    {prodId:"30161705",label:"Pisos de caucho"},
    {prodId:"30161706",label:"Pisos de baldosa o piedra"},
    {prodId:"30161707",label:"Pisos de vinilo"},
    {prodId:"30161708",label:"Alfombras de nudos"},
    {prodId:"30161709",label:"Alfombras de penachos"},
    {prodId:"30161710",label:"Pisos laminados"},
    {prodId:"30161711",label:"Alfombras para exteriores"},
    {prodId:"30161712",label:"Perfiles laminados para suelos"},
    {prodId:"30161713",label:"Almohadilla para alfombras"},
    {prodId:"30161714",label:"Suelo de corcho"},
    {prodId:"30161715",label:"Enrejados de madera"},
    {prodId:"30161717",label:"Pisos para accesos"},
    {prodId:"30161718",label:"Piso de acero antideslizante"},
    {prodId:"30161719",label:"Fieltro para alfombras"},
    {prodId:"30161720",label:"Panel de piso"},
    {prodId:"30161721",label:"Umbral de piso"},
    {prodId:"30161800",label:"Ebanistería"},
    {prodId:"30161801",label:"Armarios"},
    {prodId:"30161803",label:"Armarios domésticos"},
    {prodId:"30161804",label:"Armarios de escuela"},
    {prodId:"30161806",label:"Closet"},
    {prodId:"30161807",label:"Cajón"},
    {prodId:"30161808",label:"Ensamble para deslizamiento del cajón"},
    {prodId:"30161809",label:"Almohadillas contra cierre brusco"},
    {prodId:"30161810",label:"Gabinete tri - view"},
    {prodId:"30161900",label:"Moldeado y carpintería mecánica"},
    {prodId:"30161901",label:"Puertas de rejilla"},
    {prodId:"30161902",label:"Columnas"},
    {prodId:"30161903",label:"Paneles de madera"},
    {prodId:"30161904",label:"Cornisas"},
    {prodId:"30161905",label:"Cercos de puertas"},
    {prodId:"30161906",label:"Moldes"},
    {prodId:"30161908",label:"Piezas de escaleras"},
    {prodId:"30162000",label:"Molduras y carpintería"},
    {prodId:"30162001",label:"Laminado de alta presión"},
    {prodId:"30162002",label:"Laminado especial"},
    {prodId:"30162003",label:"Laminado de metal"},
    {prodId:"30162004",label:"Laminado de reborde"},
    {prodId:"30162005",label:"Lámina térmica rígida o rtf"},
    {prodId:"30162100",label:"Laminados interiores"},
    {prodId:"30162101",label:"Escaleras de acero"},
    {prodId:"30162102",label:"Escaleras de madera"},
    {prodId:"30162103",label:"Escaleras de concreto"},
    {prodId:"30162104",label:"Arista del escalón"},
    {prodId:"30162200",label:"Mostradores"},
    {prodId:"30162201",label:"Mesón laminado"},
    {prodId:"30162202",label:"Mesón de mármol cultivado"},
    {prodId:"30162203",label:"Mesón de superficie sólida"},
    {prodId:"30162204",label:"Mesón de granito"},
    {prodId:"30162300",label:"Accesorios para gabinetes"},
    {prodId:"30162301",label:"Piezas o ensamble de “lazy susan”"},
    {prodId:"30162302",label:"Engaste de rejilla o cajón para condimentos"},
    {prodId:"30162303",label:"Piezas o ensamble de caneca de la basura de sacar"},
    {prodId:"30162304",label:"Piezas o ensamble de despensa"},
    {prodId:"30162305",label:"Piezas o ensamble de bandeja de inclinar"},
    {prodId:"30162306",label:"Piezas o ensamble de montura de puerta o toalla deslizable"},
    {prodId:"30162307",label:"Piezas o ensamble de ancheta de sacar o voltear"},
    {prodId:"30162308",label:"Tapa o ensamble de caja para el pan"},
    {prodId:"30162309",label:"Estante para copas"},
    {prodId:"30162310",label:"Bandera rodante"},
    {prodId:"30162311",label:"Recipiente de almacenamiento de deslizar o su ensamble"},
    {prodId:"30162400",label:"Muros divisorios"},
    {prodId:"30162401",label:"Pared plegable"},
    {prodId:"30162402",label:"Pantalla o biombo o cubículo"},
    {prodId:"30162403",label:"Sistema de pared interior"},
    {prodId:"30162404",label:"Pared de cuarto frío"},
    {prodId:"30171500",label:"Puertas"},
    {prodId:"30171501",label:"Puertas de cristal"},
    {prodId:"30171502",label:"Puertas de pantalla"},
    {prodId:"30171503",label:"Puertas rodantes"},
    {prodId:"30171504",label:"Puertas de madera"},
    {prodId:"30171505",label:"Puertas de metal"},
    {prodId:"30171506",label:"Contrapuertas"},
    {prodId:"30171507",label:"Marcos de puertas o quicios"},
    {prodId:"30171508",label:"Puertas corrediza empotrable"},
    {prodId:"30171509",label:"Puertas giratorias"},
    {prodId:"30171510",label:"Puertas automáticas"},
    {prodId:"30171511",label:"Puerta de vaivén"},
    {prodId:"30171512",label:"Abridores de puertas"},
    {prodId:"30171513",label:"Rodapiés"},
    {prodId:"30171514",label:"Cerradores de puertas"},
    {prodId:"30171515",label:"Puerta de inspección"},
    {prodId:"30171516",label:"Pantalla para puertas"},
    {prodId:"30171517",label:"Puertas a prueba de sonido"},
    {prodId:"30171518",label:"Puertas imperfectas"},
    {prodId:"30171519",label:"Puertas anti - explosión"},
    {prodId:"30171520",label:"Loop de puerta y ventana"},
    {prodId:"30171521",label:"Puerta hermética contra agua"},
    {prodId:"30171522",label:"Puerta hermética sin circulación de aire"},
    {prodId:"30171523",label:"Puerta de escape de incendio"},
    {prodId:"30171524",label:"Puerta de protección contra rayos"},
    {prodId:"30171525",label:"Puerta de presión"},
    {prodId:"30171526",label:"Puerta plegable"},
    {prodId:"30171600",label:"Ventanas"},
    {prodId:"30171604",label:"Ventanas de guillotina"},
    {prodId:"30171605",label:"Ventanas de apertura horizontal"},
    {prodId:"30171606",label:"Ventanas giratorias"},
    {prodId:"30171607",label:"Ventanas con hoja de desplazamiento horizontal"},
    {prodId:"30171608",label:"Ventanas basculantes o de montante"},
    {prodId:"30171609",label:"Ventanas fijas"},
    {prodId:"30171610",label:"Miradores"},
    {prodId:"30171611",label:"Ventana de miradores"},
    {prodId:"30171612",label:"Ventanas salientes"},
    {prodId:"30171613",label:"Paredes de vidrio"},
    {prodId:"30171614",label:"Pantallas de ventana"},
    {prodId:"30171615",label:"Ventanas francesas o puerta - ventanas"},
    {prodId:"30171700",label:"Productos de cristal"},
    {prodId:"30171701",label:"Adoquines"},
    {prodId:"30171703",label:"Vidrio biselado"},
    {prodId:"30171704",label:"Vidrio con plomo"},
    {prodId:"30171705",label:"Vidrio laminado"},
    {prodId:"30171706",label:"Vidrio templado"},
    {prodId:"30171707",label:"Vidrio de seguridad"},
    {prodId:"30171708",label:"Vidrio flotado"},
    {prodId:"30171709",label:"Vidrio con alambre incrustado"},
    {prodId:"30171710",label:"Vidrio de aislación"},
    {prodId:"30171711",label:"Vidrio corrugado"},
    {prodId:"30171712",label:"Vitrales"},
    {prodId:"30171800",label:"Claraboyas"},
    {prodId:"30171801",label:"Claraboyas fijas"},
    {prodId:"30171802",label:"Claraboyas con ventilación"},
    {prodId:"30171803",label:"Claraboyas de tubo"},
    {prodId:"30171804",label:"Tapa de humo"},
    {prodId:"30171900",label:"Marcos de ventanas"},
    {prodId:"30171901",label:"Marcos para ventanas de guillotina"},
    {prodId:"30171902",label:"Marcos para ventanas de apertura horizontal"},
    {prodId:"30171903",label:"Marcos para ventanas giratorias"},
    {prodId:"30171904",label:"Marcos para ventanas con hojas de desplazamiento horizontal"},
    {prodId:"30171905",label:"Marcos para ventanas basculantes o de montante"},
    {prodId:"30171906",label:"Marcos para ventanas fijas"},
    {prodId:"30171907",label:"Alfeizar o antepecho"},
    {prodId:"30171908",label:"Arco de ventana"},
    {prodId:"30172000",label:"Puertas"},
    {prodId:"30172001",label:"Puerta de barra sencilla"},
    {prodId:"30172002",label:"Puerta de doble barra"},
    {prodId:"30172100",label:"Puertas para garaje y operadores"},
    {prodId:"30172101",label:"Puerta de garaje pan"},
    {prodId:"30172102",label:"Puerta de garaje en sándwich aislada"},
    {prodId:"30172103",label:"Puerta de garaje de madera"},
    {prodId:"30172104",label:"Puerta de garaje de carroza"},
    {prodId:"30172105",label:"Puerta de garaje de pantalla"},
    {prodId:"30172106",label:"Puerta de garaje de de pvc"},
    {prodId:"30172107",label:"Puerta de garaje de eslabones de cadena"},
    {prodId:"30172108",label:"Puerta de garaje rodante"},
    {prodId:"30172109",label:"Componente de puerta de garaje"},
    {prodId:"30172110",label:"Accesorio de puerta de garaje"},
    {prodId:"30172111",label:"Operador completo de puerta de garaje"},
    {prodId:"30172112",label:"Componente de operador de puerta de garaje"},
    {prodId:"30172113",label:"Accesorio de operador de puerta de garaje"},
    {prodId:"30181500",label:"Porcelana sanitaria"},
    {prodId:"30181501",label:"Tinas o bañeras"},
    {prodId:"30181502",label:"Bidés"},
    {prodId:"30181503",label:"Duchas"},
    {prodId:"30181504",label:"Lavamanos"},
    {prodId:"30181505",label:"Inodoros o excusados"},
    {prodId:"30181506",label:"Orinales"},
    {prodId:"30181507",label:"Cerramientos para bañeras o duchas"},
    {prodId:"30181508",label:"Divisiones de baños"},
    {prodId:"30181511",label:"Inodoros o excusados"},
    {prodId:"30181514",label:"Tapa de tanque del inodoro"},
    {prodId:"30181515",label:"Tanque del inodoro"},
    {prodId:"30181516",label:"Sauna"},
    {prodId:"30181517",label:"Jacuzzi"},
    {prodId:"30181600",label:"Instalaciones residenciales no sanitarias"},
    {prodId:"30181601",label:"Jabonera"},
    {prodId:"30181602",label:"Toallero"},
    {prodId:"30181603",label:"Bizcocho o asiento del inodoro"},
    {prodId:"30181604",label:"Tapa del bizcocho"},
    {prodId:"30181605",label:"Desagüe"},
    {prodId:"30181606",label:"Ganchos de baño"},
    {prodId:"30181607",label:"Cortina de baño o su ensamble"},
    {prodId:"30181608",label:"Varilla de ducha"},
    {prodId:"30181609",label:"Soporte para frascos de ducha"},
    {prodId:"30181610",label:"Soporte de  papel higiénico"},
    {prodId:"30181611",label:"Soporte de cepillos de dientes o vaso"},
    {prodId:"30181612",label:"Gancho para la máquina afeitadora"},
    {prodId:"30181613",label:"Faldón de la tina o del jacuzzi"},
    {prodId:"30181614",label:"Dispensador de jabón"},
    {prodId:"30181700",label:"Grifos"},
    {prodId:"30181701",label:"Llave de registro"},
    {prodId:"30181800",label:"Cabezas, chorros y partes y accesorios de grifos y duchas"},
    {prodId:"30181801",label:"Pomo de ducha"},
    {prodId:"30181802",label:"Aireador de la ducha"},
    {prodId:"30181803",label:"Ducha teléfono"},
    {prodId:"30181804",label:"Grifo"},
    {prodId:"30181805",label:"Ducha combinada fija y teléfono"},
    {prodId:"30181806",label:"Chorros"},
    {prodId:"30181807",label:"Boquilla"},
    {prodId:"30181808",label:"Válvulas ocultas"},
    {prodId:"30181809",label:"Kit de reparación de duchas teléfono"},
    {prodId:"30181810",label:"Ajuste de grifo"},
    {prodId:"30181811",label:"Kit de reparación de grifo"},
    {prodId:"30181812",label:"Rociador de cuerpo"},
    {prodId:"30191500",label:"Escaleras y andamios"},
    {prodId:"30191501",label:"Escaleras"},
    {prodId:"30191502",label:"Andamios"},
    {prodId:"30191505",label:"Taburete escalonado"},
    {prodId:"30191506",label:"Escalón de plataforma"},
    {prodId:"30191507",label:"Escalera de combinación"},
    {prodId:"30191508",label:"Escalera de techo"},
    {prodId:"30191509",label:"Escalera de escape de incendios"},
    {prodId:"30191510",label:"Escalera de extinguidor de incendios"},
    {prodId:"30191600",label:"Escaleras y accesorios de andamiaje"},
    {prodId:"30191601",label:"Barandas"},
    {prodId:"30191602",label:"Estabilizadores de andamiaje"},
    {prodId:"30191603",label:"Piso del andamiaje"},
    {prodId:"30191604",label:"Varillas cruzadas del andamio"},
    {prodId:"30191605",label:"Marco del andamio"},
    {prodId:"30191606",label:"Escalera del andamio"},
    {prodId:"30191607",label:"Contrapeso del andamio"},
    {prodId:"30191608",label:"Ménsula del andamio"},
    {prodId:"30191609",label:"Plataforma del andamio"},
    {prodId:"30191610",label:"Pescante del andamio"},
    {prodId:"30191611",label:"Dispositivo de fijación del andamio"},
    {prodId:"30191612",label:"Cuerda de alambre del andamio"},
    {prodId:"30191613",label:"Plataforma de la escalera"},
    {prodId:"30191614",label:"Bandeja de la escalera"},
    {prodId:"30191615",label:"Descanso de la escalera"},
    {prodId:"30191616",label:"Red de seguridad de construcción"},
    {prodId:"30191617",label:"Cerramiento de seguridad de la construcción"},
    {prodId:"30191700",label:"Cobertizos y remolques de construcción"},
    {prodId:"30191701",label:"Caseta de construcción"},
    {prodId:"30191702",label:"Tráiler de construcción"},
    {prodId:"30191800",label:"Equipos y materiales para construcciones temporales y apoyo al mantenimiento"},
    {prodId:"30191801",label:"Formaleta estructural"},
    {prodId:"30191802",label:"Accesorio de formaleta estructural"},
    {prodId:"30191803",label:"Espaciador estructural"},
    {prodId:"30241500",label:"Componentes de consolidación de estructuras portátiles"},
    {prodId:"30241501",label:"Conector de marcos de tubos"},
    {prodId:"30241502",label:"Placa de anclaje"},
    {prodId:"30241503",label:"Anillo de soporte"},
    {prodId:"30241504",label:"Placa base"},
    {prodId:"30241505",label:"Cúpula"},
    {prodId:"30241506",label:"Campana funicular"},
    {prodId:"30241507",label:"Aguilón"},
    {prodId:"30241508",label:"Poste grúa y accesorios"},
    {prodId:"30241509",label:"Sección de mástil y poste rey"},
    {prodId:"30241510",label:"Poste lateral"},
    {prodId:"30241511",label:"Estaca o clavija"},
    {prodId:"30241512",label:"Travesaño"},
    {prodId:"30241513",label:"Marco en a de acero"},
    {prodId:"30241514",label:"Sujetador estructural"},
    {prodId:"30241515",label:"Herramienta de alineación estructural"},
    {prodId:"30241600",label:"Componentes estructurales de tribunas, gradas y escalera"},
    {prodId:"30241601",label:"Tarima y accesorios de la tribuna"},
    {prodId:"30241602",label:"Escalón"},
    {prodId:"30241603",label:"Durmiente"},
    {prodId:"30241604",label:"Elevador de escalones"},
    {prodId:"30241700",label:"Componentes soporte y cobertura de tiendas, carpas y estructuras de membrana"},
    {prodId:"30241701",label:"Viga o riel o tubería de marco"},
    {prodId:"30241702",label:"Sección de lienzo de estructura portátil"},
    {prodId:"30251500",label:"Estructuras de soporte de techos bajo tierra"},
    {prodId:"30251501",label:"Perno y armazón de combinación de techo"},
    {prodId:"30251502",label:"Placa de techo"},
    {prodId:"30251503",label:"Cápsula o cartucho de resina adhesiva"},
    {prodId:"30251504",label:"Celda de expansión"},
    {prodId:"30251505",label:"Estera de nervadura"},
    {prodId:"30261500",label:"Barras de latón"},
    {prodId:"30261501",label:"Barra de latón enrollado en caliente c 360"},
    {prodId:"30261502",label:"Barra de latón forjado en caliente c 360"},
    {prodId:"30261503",label:"Barra de latón enrollado en caliente c 464"},
    {prodId:"30261504",label:"Barra de latón forjado en caliente c 464"},
    {prodId:"30261505",label:"Barra de latón estirado en frío c 464"},
    {prodId:"30261600",label:"Láminas de latón"},
    {prodId:"30261601",label:"Lámina de latón enrollado en caliente c 360"},
    {prodId:"30261602",label:"Lámina de latón forjado en caliente c 464"},
    {prodId:"30261603",label:"Lámina de latón enrollado en frío c 464"},
    {prodId:"30261700",label:"Lingotes, bandas, palanquillas y alambres de latón"},
    {prodId:"30261701",label:"Alambre de latón"},
    {prodId:"30261702",label:"Banda de latón"},
    {prodId:"30261703",label:"Palanquilla de latón"},
    {prodId:"30261704",label:"Lingote de latón"},
    {prodId:"30262200",label:"Barras de cobre"},
    {prodId:"30262201",label:"Barra de cobre estirada en frío"},
    {prodId:"30262202",label:"Barra de cobre enrollada en caliente"},
    {prodId:"30262400",label:"Lingotes, bandas, palanquillas y alambres de cobre"},
    {prodId:"30262401",label:"Alambre de cobre"},
    {prodId:"30262402",label:"Banda de cobre enrollada en caliente"},
    {prodId:"30262403",label:"Banda de cobre enrollada en frío"},
    {prodId:"30262404",label:"Palanquilla de cobre"},
    {prodId:"30262405",label:"Lingote de cobre"},
    {prodId:"30262500",label:"Barras de bronce"},
    {prodId:"30262501",label:"Barra de bronce fósforo enrollada en caliente"},
    {prodId:"30262502",label:"Barra de bronce fósforo enrollada en frío"},
    {prodId:"30262503",label:"Barra de bronce aluminio enrollada en caliente"},
    {prodId:"30262504",label:"Barra de bronce aluminio estirada en frío"},
    {prodId:"30262505",label:"Barra de bronce silicona enrollada en caliente"},
    {prodId:"30262506",label:"Barra de bronce silicona estirada en frío"},
    {prodId:"30262800",label:"Barras y láminas de zinc"},
    {prodId:"30262801",label:"Barra de zinc enrollada en caliente"},
    {prodId:"30262802",label:"Lámina de zinc enrollada en caliente"},
    {prodId:"30262900",label:"Lingotes, bandas, palanquillas y alambres de zinc"},
    {prodId:"30262901",label:"Alambre de zinc"},
    {prodId:"30262902",label:"Banda de zinc"},
    {prodId:"30262903",label:"Palanquilla de zinc"},
    {prodId:"30262904",label:"Lingote de zinc"},
    {prodId:"30263000",label:"Barras de estaño"},
    {prodId:"30263001",label:"Barra de estaño estirada en frío"},
    {prodId:"30263002",label:"Barra de estaño enrollada en caliente"},
    {prodId:"30263200",label:"Lingotes, bandas, palanquillas y alambres de estaño"},
    {prodId:"30263201",label:"Alambre de estaño"},
    {prodId:"30263202",label:"Banda de estaño"},
    {prodId:"30263203",label:"Lingote de estaño"},
    {prodId:"30263500",label:"Lingotes, bandas, palanquillas y alambres de plomo"},
    {prodId:"30263501",label:"Alambre de plomo"},
    {prodId:"30263502",label:"Banda de plomo"},
    {prodId:"30263503",label:"Palanquilla de plomo"},
    {prodId:"30263504",label:"Lingote de plomo"},
    {prodId:"30263600",label:"Barras de acero al carbón"},
    {prodId:"30263601",label:"Barra de acero al carbón serie sae 1000 estirada en frío"},
    {prodId:"30263602",label:"Barra de acero al carbón serie sae 1100 estirada en frío"},
    {prodId:"30263603",label:"Barra de acero al carbón serie sae 1200 estirada en frío"},
    {prodId:"30263604",label:"Barra de acero al carbón serie sae 1500 estirada en frío"},
    {prodId:"30263605",label:"Barra de acero al carbón serie sae 1000 enrollada en caliente"},
    {prodId:"30263606",label:"Barra de acero al carbón serie sae 1100 enrollada en caliente"},
    {prodId:"30263607",label:"Barra de acero al carbón serie sae 1200 enrollada en caliente"},
    {prodId:"30263608",label:"Barra de acero al carbón serie sae 1500 enrollada en caliente"},
    {prodId:"30263700",label:"Barras de aleación de acero"},
    {prodId:"30263701",label:"Barra de aleación de acero  serie sae 4000 estirada en frío"},
    {prodId:"30263702",label:"Barra de aleación de acero  serie sae 5000 estirada en frío"},
    {prodId:"30263703",label:"Barra de aleación de acero  serie sae 6000 estirada en frío"},
    {prodId:"30263704",label:"Barra de aleación de acero  serie sae 8000 estirada en frío"},
    {prodId:"30263705",label:"Barra de aleación de acero  serie sae 9000 estirada en frío"},
    {prodId:"30263706",label:"Barra de aleación de acero  serie sae 4000 enrollada en caliente"},
    {prodId:"30263707",label:"Barra de aleación de acero  serie sae 5000 enrollada en caliente"},
    {prodId:"30263708",label:"Barra de aleación de acero  serie sae 6000 enrollada en caliente"},
    {prodId:"30263709",label:"Barra de aleación de acero  serie sae 8000 enrollada en caliente"},
    {prodId:"30263710",label:"Barra de aleación de acero  serie sae 9000 enrollada en caliente"},
    {prodId:"30263800",label:"Barras de acero de herramienta"},
    {prodId:"30263801",label:"Barra de acero de herramienta estirada en frío"},
    {prodId:"30263802",label:"Barra de acero de herramienta enrollada en caliente"},
    {prodId:"30263900",label:"Barras de acero especial"},
    {prodId:"30263901",label:"Barra de acero de alta resistencia baja aleación hsla enrollada en caliente"},
    {prodId:"30264000",label:"Láminas de acero al carbón"},
    {prodId:"30264001",label:"Lámina de acero al carbón serie sae 1000 enrollada en caliente"},
    {prodId:"30264002",label:"Lámina de acero al carbón serie sae 1100 enrollada en caliente"},
    {prodId:"30264003",label:"Lámina de acero al carbón serie sae 1200 enrollada en caliente"},
    {prodId:"30264004",label:"Lámina de acero al carbón serie sae 1500 enrollada en caliente"},
    {prodId:"30264005",label:"Lámina de acero al carbón electro galvanizada serie sae 1000 enrollada en caliente"},
    {prodId:"30264006",label:"Lámina de acero al carbón electro galvanizada serie sae 1100 enrollada en caliente"},
    {prodId:"30264007",label:"Lámina de acero al carbón electro galvanizada serie sae 1200 enrollada en caliente"},
    {prodId:"30264008",label:"Lámina de acero al carbón electro galvanizada serie sae 1500 enrollada en caliente"},
    {prodId:"30264009",label:"Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1000 enrollada en caliente"},
    {prodId:"30264010",label:"Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1100 enrollada en caliente"},
    {prodId:"30264011",label:"Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1200 enrollada en caliente"},
    {prodId:"30264012",label:"Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1500 enrollada en caliente"},
    {prodId:"30264013",label:"Lámina de acero al carbón serie sae 1000 enrollada en frío"},
    {prodId:"30264014",label:"Lámina de acero al carbón serie sae 1100 enrollada en frío"},
    {prodId:"30264015",label:"Lámina de acero al carbón serie sae 1200 enrollada en frío"},
    {prodId:"30264016",label:"Lámina de acero al carbón serie sae 1500 enrollada en frío"},
    {prodId:"30264017",label:"Lámina de acero al carbón electro galvanizada serie sae 1000 enrollada en frío"},
    {prodId:"30264018",label:"Lámina de acero al carbón electro galvanizada serie sae 1100 enrollada en frío"},
    {prodId:"30264019",label:"Lámina de acero al carbón electro galvanizada serie sae 1200 enrollada en frío"},
    {prodId:"30264020",label:"Lámina de acero al carbón electro galvanizada serie sae 1500 enrollada en frío"},
    {prodId:"30264021",label:"Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1000 enrollada en frío"},
    {prodId:"30264022",label:"Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1100 enrollada en frío"},
    {prodId:"30264023",label:"Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1200 enrollada en frío"},
    {prodId:"30264024",label:"Lámina de acero al carbón inmersión en caliente galvanizada serie sae 1500 enrollada en frío"},
    {prodId:"30264200",label:"Láminas de acero de herramienta"},
    {prodId:"30264201",label:"Lámina de acero de alta resistencia baja aleación hsla enrollada en caliente"},
    {prodId:"30264202",label:"Lámina de acero de alta resistencia baja aleación hsla enrollada en frío"},
    {prodId:"30264203",label:"Lámina de acero aluminizado"},
    {prodId:"30264204",label:"Lámina de acero corrugada"},
    {prodId:"30264300",label:"Láminas de acero especial"},
    {prodId:"30264301",label:"Alambre de acero perforado"},
    {prodId:"30264302",label:"Alambre de acero embebido"},
    {prodId:"30264303",label:"Alambre de acero aluminizado"},
    {prodId:"30264304",label:"Alambre de acero inmersión en caliente galvanizado"},
    {prodId:"30264305",label:"Alambre de acero electro galvanizado"},
    {prodId:"30264400",label:"Lingotes, bandas, palanquillas y alambres de al carbón"},
    {prodId:"30264401",label:"Alambre de acero al carbón serie sae 1100 enrollada en caliente"},
    {prodId:"30264402",label:"Alambre de acero al carbón serie sae 1200 enrollada en caliente"},
    {prodId:"30264403",label:"Alambre de acero al carbón serie sae 1500 enrollada en caliente"},
    {prodId:"30264404",label:"Banda de acero al carbón serie sae 1100 enrollada en frío"},
    {prodId:"30264405",label:"Banda de acero al carbón serie sae 1200 enrollada en frío"},
    {prodId:"30264406",label:"Banda de acero al carbón serie sae 1500 enrollada en frío"},
    {prodId:"30264407",label:"Banda de acero al carbón serie sae 1100 enrollada en caliente"},
    {prodId:"30264408",label:"Banda de acero al carbón serie sae 1200 enrollada en caliente"},
    {prodId:"30264409",label:"Banda de acero al carbón serie sae 1500 enrollada en caliente"},
    {prodId:"30264410",label:"Palanquilla de acero al carbón enrollado en caliente"},
    {prodId:"30264411",label:"Palanquilla de acero al carbón enrollado en frío"},
    {prodId:"30264412",label:"Lingote de acero al carbón enrollado en caliente"},
    {prodId:"30264413",label:"Lingote de acero al carbón enrollado en frío"},
    {prodId:"30264500",label:"Barras de acero inoxidable"},
    {prodId:"30264501",label:"Barra de acero inoxidable serie sae 200 estirada en frío"},
    {prodId:"30264502",label:"Barra de acero inoxidable serie sae 300 estirada en frío"},
    {prodId:"30264503",label:"Barra de acero inoxidable serie sae 400 estirada en frío"},
    {prodId:"30264504",label:"Barra de acero inoxidable serie sae 200 enrollada en caliente"},
    {prodId:"30264505",label:"Barra de acero inoxidable serie sae 300 enrollada en caliente"},
    {prodId:"30264506",label:"Barra de acero inoxidable serie sae 400 enrollada en caliente"},
    {prodId:"30264600",label:"Láminas de acero inoxidable"},
    {prodId:"30264601",label:"Lámina de acero inoxidable serie sae 200 enrollada en caliente"},
    {prodId:"30264602",label:"Lámina de acero inoxidable serie sae 300 enrollada en caliente"},
    {prodId:"30264603",label:"Lámina de acero inoxidable serie sae 400 enrollada en caliente"},
    {prodId:"30264604",label:"Lámina de acero inoxidable serie sae 200 enrollada en frío"},
    {prodId:"30264605",label:"Lámina de acero inoxidable serie sae 300 enrollada en frío"},
    {prodId:"30264606",label:"Lámina de acero inoxidable serie sae 400 enrollada en frío"},
    {prodId:"30264700",label:"Alambre de acero inoxidable"},
    {prodId:"30264701",label:"Alambre de acero inoxidable serie sae 300 enrollada en caliente"},
    {prodId:"30264702",label:"Alambre de acero inoxidable serie sae 400 enrollada en caliente"},
    {prodId:"30264703",label:"Alambre de acero inoxidable serie sae 200 enrollada en frío"},
    {prodId:"30264704",label:"Alambre de acero inoxidable serie sae 300 enrollada en frío"},
    {prodId:"30264705",label:"Alambre de acero inoxidable serie sae 400 enrollada en frío"},
    {prodId:"30264800",label:"Bandas de acero inoxidable"},
    {prodId:"30264801",label:"Banda de acero inoxidable serie sae 200 enrollada en caliente"},
    {prodId:"30264802",label:"Banda de acero inoxidable serie sae 300 enrollada en caliente"},
    {prodId:"30264803",label:"Banda de acero inoxidable serie sae 400 enrollada en caliente"},
    {prodId:"30264804",label:"Banda de acero inoxidable serie sae 200 enrollada en frío"},
    {prodId:"30264805",label:"Banda de acero inoxidable serie sae 300 enrollada en frío"},
    {prodId:"30264806",label:"Banda de acero inoxidable serie sae 400 enrollada en frío"},
    {prodId:"30264900",label:"Barras de aluminio"},
    {prodId:"30264901",label:"Barra de aluminio serie sae 1000 estirada en frío"},
    {prodId:"30264902",label:"Barra de aluminio serie sae 1000 enrollada en caliente"},
    {prodId:"30264903",label:"Barra de aluminio serie sae 2000 estirada en frío"},
    {prodId:"30264904",label:"Barra de aluminio serie sae 2000 enrollada en caliente"},
    {prodId:"30264905",label:"Barra de aluminio serie sae 3000 estirada en frío"},
    {prodId:"30264906",label:"Barra de aluminio serie sae 3000 enrollada en caliente"},
    {prodId:"30264907",label:"Barra de aluminio serie sae 4000 estirada en frío"},
    {prodId:"30264908",label:"Barra de aluminio serie sae 4000 enrollada en caliente"},
    {prodId:"30264909",label:"Barra de aluminio serie sae 5000 estirada en frío"},
    {prodId:"30264910",label:"Barra de aluminio serie sae 5000 enrollada en caliente"},
    {prodId:"30264911",label:"Barra de aluminio serie sae 6000 estirada en frío"},
    {prodId:"30264912",label:"Barra de aluminio serie sae 6000 enrollada en caliente"},
    {prodId:"30265000",label:"Láminas de aluminio"},
    {prodId:"30265001",label:"Lámina de aluminio serie sae 1000 enrollada en frío"},
    {prodId:"30265002",label:"Lámina de aluminio serie sae 1000 enrollada en caliente"},
    {prodId:"30265003",label:"Lámina de aluminio serie sae 2000 enrollada en frío"},
    {prodId:"30265004",label:"Lámina de aluminio serie sae 2000 enrollada en caliente"},
    {prodId:"30265005",label:"Lámina de aluminio serie sae 3000 enrollada en frío"},
    {prodId:"30265006",label:"Lámina de aluminio serie sae 3000 enrollada en caliente"},
    {prodId:"30265007",label:"Lámina de aluminio serie sae 4000 enrollada en frío"},
    {prodId:"30265008",label:"Lámina de aluminio serie sae 4000 enrollada en caliente"},
    {prodId:"30265009",label:"Lámina de aluminio serie sae 5000 enrollada en frío"},
    {prodId:"30265010",label:"Lámina de aluminio serie sae 5000 enrollada en caliente"},
    {prodId:"30265011",label:"Lámina de aluminio serie sae 6000 enrollada en frío"},
    {prodId:"30265012",label:"Lámina de aluminio serie sae 6000 enrollada en caliente"},
    {prodId:"30265100",label:"Alambre de aluminio"},
    {prodId:"30265101",label:"Alambre de aluminio serie sae 1000 enrollada en caliente"},
    {prodId:"30265102",label:"Alambre de aluminio serie sae 2000 enrollada en caliente"},
    {prodId:"30265103",label:"Alambre de aluminio serie sae 3000 enrollada en caliente"},
    {prodId:"30265104",label:"Alambre de aluminio serie sae 4000 enrollada en caliente"},
    {prodId:"30265105",label:"Alambre de aluminio serie sae 5000 enrollada en caliente"},
    {prodId:"30265106",label:"Alambre de aluminio serie sae 6000 enrollada en caliente"},
    {prodId:"30265200",label:"Bandas y lingotes de aluminio"},
    {prodId:"30265201",label:"Banda de aluminio"},
    {prodId:"30265202",label:"Lingote de aluminio"},
    {prodId:"30265300",label:"Barras de hierro"},
    {prodId:"30265301",label:"Barra fundida de hierro dúctil"},
    {prodId:"30265302",label:"Barra fundida de hierro gris"},
    {prodId:"30265303",label:"Barra fundida de hierro blanco"},
    {prodId:"30265304",label:"Barra fundida de hierro maleable"},
    {prodId:"30265400",label:"Láminas de hierro"},
    {prodId:"30265401",label:"Lámina de hierro dúctil enrollada en caliente"},
    {prodId:"30265403",label:"Lámina de hierro blanco enrollada en caliente"},
    {prodId:"30265404",label:"Lámina de hierro maleable enrollada en caliente"},
    {prodId:"30265602",label:"Lámina de aleación ferrosa"},
    {prodId:"30265700",label:"Lingotes, bandas, palanquillas y alambres de aleaciones ferrosas"},
    {prodId:"30265701",label:"Alambre de aleación ferrosa"},
    {prodId:"30265702",label:"Banda de aleación ferrosa"},
    {prodId:"30265703",label:"Palanquilla de aleación ferrosa"},
    {prodId:"30265704",label:"Lingote de extrusión de aleación ferrosa"},
    {prodId:"30265800",label:"Barras y láminas plásticas"},
    {prodId:"30265801",label:"Barra de plástico"},
    {prodId:"30265802",label:"Lámina de plástico"},
    {prodId:"30265803",label:"Lámina corrugada de plástico"},
    {prodId:"30265900",label:"Alambre y bandas de plástico"},
    {prodId:"30265901",label:"Alambre de plástico"},
    {prodId:"30265902",label:"Banda de plástico"},
    {prodId:"30266200",label:"Lingotes, bandas, palanquillas y alambres de metales preciosos y metales especiales"},
    {prodId:"30266201",label:"Alambre de grafito"},
    {prodId:"30266202",label:"Alambre de aleación no ferrosa"},
    {prodId:"30266203",label:"Alambre de metal precioso"},
    {prodId:"30266204",label:"Banda de aleación no ferrosa"},
    {prodId:"30266205",label:"Palanquilla de aleación no ferrosa"},
    {prodId:"30266206",label:"Palanquilla de metal precioso"},
    {prodId:"30266207",label:"Lingote de metal precioso"},
    {prodId:"30266208",label:"Lingote de antimonio"},
    {prodId:"30266209",label:"Lingote de cadmio"},
    {prodId:"30266210",label:"Lingote de circonio"},
    {prodId:"30266211",label:"Lingote de cobalto"},
    {prodId:"30266212",label:"Lingote de molibdeno"},
    {prodId:"30266213",label:"lingote de arsénico"},
    {prodId:"30266214",label:"Lingote de bismuto"},
    {prodId:"30266215",label:"Lingote de indio"},
    {prodId:"30266216",label:"Lingote de extrusión de aleación no ferrosa"},
    {prodId:"30266300",label:"Materiales estructurales de madera"},
    {prodId:"30266301",label:"Barra de madera"},
    {prodId:"30266400",label:"Barras y láminas de materiales especiales"},
    {prodId:"30266401",label:"Barra de caucho"},
    {prodId:"30266402",label:"Lámina de caucho"},
    {prodId:"30266403",label:"Lámina de caucho espuma"},
    {prodId:"30266404",label:"Lámina de caucho y corcho"},
    {prodId:"30266405",label:"Lámina de fibra comprimida"},
    {prodId:"30266406",label:"Lámina de fibra comprimida con metal insertado"},
    {prodId:"30266407",label:"Lámina de fibra y caucho"},
    {prodId:"30266408",label:"Lámina de asbesto"},
    {prodId:"30266410",label:"Barra de tántalo"},
    {prodId:"30266500",label:"Lingotes, bandas, palanquillas y alambres de  materiales especiales"},
    {prodId:"30266501",label:"Banda de caucho"},
    {prodId:"30266502",label:"Alambre de fibra comprimida"},
    {prodId:"30266503",label:"Alambre de fibra y caucho"},
    {prodId:"30266504",label:"Banda de tántalo"},
    {prodId:"30266700",label:"Trasmisiones"},
    {prodId:"30266701",label:"Transmisión de acero"},
    {prodId:"30266702",label:"Transmisión de acero inoxidable"},
    {prodId:"31101600",label:"Moldeos en arena"},
    {prodId:"31101601",label:"Fundición en arena de aleación no ferrosa"},
    {prodId:"31101602",label:"Fundición en arena de aleación ferrosa"},
    {prodId:"31101603",label:"Fundición en arena de acero"},
    {prodId:"31101604",label:"Fundición en arena de acero inoxidable"},
    {prodId:"31101606",label:"Fundición en arena de aluminio"},
    {prodId:"31101607",label:"Fundición en arena de magnesio"},
    {prodId:"31101608",label:"Fundición en arena de titanio"},
    {prodId:"31101609",label:"Fundición en arena de berilio"},
    {prodId:"31101610",label:"Fundición en arena de cobre"},
    {prodId:"31101611",label:"Fundición en arena de latón"},
    {prodId:"31101612",label:"Fundición en arena de bronce"},
    {prodId:"31101613",label:"Fundición en arena de zinc"},
    {prodId:"31101614",label:"Fundición en arena de estaño"},
    {prodId:"31101615",label:"Fundición en arena de plomo"},
    {prodId:"31101616",label:"Fundición en arena de metal precioso"},
    {prodId:"31101617",label:"Fundición en arena de hierro gris"},
    {prodId:"31101700",label:"Piezas fundidas en molde permanente"},
    {prodId:"31101701",label:"Objetos de aleación no ferrosa fundidos en molde fijo"},
    {prodId:"31101702",label:"Objetos de aleación ferrosa fundidos en molde fijo"},
    {prodId:"31101703",label:"Objetos de acero fundidos en molde fijo"},
    {prodId:"31101704",label:"Objetos de acero inoxidable fundidos en molde fijo"},
    {prodId:"31101705",label:"Objetos de hierro fundidos en molde fijo"},
    {prodId:"31101706",label:"Objetos de aluminio fundidos en molde fijo"},
    {prodId:"31101707",label:"Objetos de magnesio fundidos en molde fijo"},
    {prodId:"31101708",label:"Objetos de titanio fundidos en molde fijo"},
    {prodId:"31101709",label:"Objetos de berilio fundidos en molde fijo"},
    {prodId:"31101710",label:"Objetos de cobre fundidos en molde fijo"},
    {prodId:"31101711",label:"Objetos de latón fundidos en molde fijo"},
    {prodId:"31101712",label:"Objetos de bronce fundidos en molde fijo"},
    {prodId:"31101713",label:"Objetos de zinc fundidos en molde fijo"},
    {prodId:"31101714",label:"Objetos de estaño fundidos en molde fijo"},
    {prodId:"31101715",label:"Objetos de plomo fundidos en molde fijo"},
    {prodId:"31101716",label:"Objetos de metal precioso fundidos en molde fijo"},
    {prodId:"31101800",label:"Piezas fundidas por moldeo en cáscara"},
    {prodId:"31101801",label:"Objetos de aleación no ferrosa fundidos por moldeo en cáscara"},
    {prodId:"31101802",label:"Objetos de aleación ferrosa fundidos por moldeo en cáscara"},
    {prodId:"31101803",label:"Objetos de acero fundidos por moldeo en cáscara"},
    {prodId:"31101804",label:"Objetos de acero inoxidable fundidos por moldeo en cáscara"},
    {prodId:"31101806",label:"Objetos de aluminio fundidos por moldeo en cáscara"},
    {prodId:"31101807",label:"Objetos de magnesio fundidos por moldeo en cáscara"},
    {prodId:"31101808",label:"Objetos de titanio fundidos por moldeo en cáscara"},
    {prodId:"31101809",label:"Objetos de berilio fundidos por moldeo en cáscara"},
    {prodId:"31101810",label:"Objetos de cobre fundidos por moldeo en cáscara"},
    {prodId:"31101811",label:"Objetos de latón fundidos por moldeo en cáscara"},
    {prodId:"31101812",label:"Objetos de bronce fundidos por moldeo en cáscara"},
    {prodId:"31101813",label:"Objetos de zinc fundidos por moldeo en cáscara"},
    {prodId:"31101814",label:"Objetos de estaño fundidos por moldeo en cáscara"},
    {prodId:"31101815",label:"Objetos de plomo fundidos por moldeo en cáscara"},
    {prodId:"31101816",label:"Objetos de metal precioso fundidos por moldeo en cáscara"},
    {prodId:"31101817",label:"Objetos de hierro dúctil fundidos por moldeo en cáscara"},
    {prodId:"31101900",label:"Piezas fundidas a la cera perdida"},
    {prodId:"31101901",label:"Objetos de aleación no ferrosa fundidos a la cera perdida"},
    {prodId:"31101902",label:"Objetos de aleación ferrosa fundidos a la cera perdida"},
    {prodId:"31101903",label:"Objetos de acero fundidos a la cera perdida"},
    {prodId:"31101904",label:"Objetos de acero inoxidable fundidos a la cera perdida"},
    {prodId:"31101905",label:"Objetos de hierro fundidos a la cera perdida"},
    {prodId:"31101906",label:"Objetos de aluminio fundidos a la cera perdida"},
    {prodId:"31101907",label:"Objetos de magnesio fundidos a la cera perdida"},
    {prodId:"31101908",label:"Objetos de zinc fundidos a la cera perdida"},
    {prodId:"31101909",label:"Objetos de estaño fundidos a la cera perdida"},
    {prodId:"31101910",label:"Objetos de plomo fundidos a la cera perdida"},
    {prodId:"31101911",label:"Objetos de metal precioso fundidos a la cera perdida"},
    {prodId:"31101912",label:"Objetos de titanio fundidos a la cera perdida"},
    {prodId:"31101914",label:"Objetos de aleación de acero fundidos a la cera perdida"},
    {prodId:"31102000",label:"Piezas de fundición centrífuga"},
    {prodId:"31102001",label:"Objetos de fundición centrífuga de aleación no ferrosa"},
    {prodId:"31102002",label:"Objetos de fundición centrífuga de aleación ferrosa"},
    {prodId:"31102003",label:"Objetos de fundición centrífuga de acero"},
    {prodId:"31102004",label:"Objetos de fundición centrífuga de acero inoxidable"},
    {prodId:"31102005",label:"Objetos de fundición centrífuga de hierro"},
    {prodId:"31102006",label:"Objetos de fundición centrífuga de aluminio"},
    {prodId:"31102007",label:"Objetos de fundición centrífuga de magnesio"},
    {prodId:"31102008",label:"Objetos de fundición centrífuga de titanio"},
    {prodId:"31102009",label:"Objetos de fundición centrífuga de berilio"},
    {prodId:"31102010",label:"Objetos de fundición centrífuga de cobre"},
    {prodId:"31102011",label:"Objetos de fundición centrífuga de latón"},
    {prodId:"31102012",label:"Objetos de fundición centrífuga de bronce"},
    {prodId:"31102013",label:"Objetos de fundición centrífuga de zinc"},
    {prodId:"31102014",label:"Objetos de fundición centrífuga de estaño"},
    {prodId:"31102015",label:"Objetos de fundición centrífuga de plomo"},
    {prodId:"31102016",label:"Objetos de fundición centrífuga de metal precioso"},
    {prodId:"31102200",label:"Piezas fundidas en molde de grafito"},
    {prodId:"31102201",label:"Objetos de aleación no ferrosa fundidos en molde de grafito"},
    {prodId:"31102202",label:"Objetos de aleación ferrosa fundidos en molde de grafito"},
    {prodId:"31102203",label:"Objetos de acero fundidos en molde de grafito"},
    {prodId:"31102204",label:"Objetos de acero inoxidable fundidos en molde de grafito"},
    {prodId:"31102205",label:"Objetos de hierro fundidos en molde de grafito"},
    {prodId:"31102206",label:"Objetos de aluminio fundidos en molde de grafito"},
    {prodId:"31102207",label:"Objetos de magnesio fundidos en molde de grafito"},
    {prodId:"31102208",label:"Objetos de titanio fundidos en molde de grafito"},
    {prodId:"31102209",label:"Objetos de berilio fundidos en molde de grafito"},
    {prodId:"31102210",label:"Objetos de cobre fundidos en molde de grafito"},
    {prodId:"31102211",label:"Objetos de latón fundidos en molde de grafito"},
    {prodId:"31102212",label:"Objetos de bronce fundidos en molde de grafito"},
    {prodId:"31102213",label:"Objetos de zinc fundidos en molde de grafito"},
    {prodId:"31102214",label:"Objetos de estaño fundidos en molde de grafito"},
    {prodId:"31102215",label:"Objetos de plomo fundidos en molde de grafito"},
    {prodId:"31102216",label:"Objetos de metal precioso fundidos en molde de grafito"},
    {prodId:"31102300",label:"Piezas fundidas en molde de yeso"},
    {prodId:"31102301",label:"Objetos de aleación no ferrosa fundidos en molde de yeso"},
    {prodId:"31102302",label:"Objetos de aleación ferrosa fundidos en molde de yeso"},
    {prodId:"31102303",label:"Objetos de acero fundidos en molde de yeso"},
    {prodId:"31102304",label:"Objetos de acero inoxidable fundidos en molde de yeso"},
    {prodId:"31102305",label:"Objetos de hierro fundidos en molde de yeso"},
    {prodId:"31102306",label:"Objetos de aluminio fundidos en molde de yeso"},
    {prodId:"31102307",label:"Objetos de magnesio fundidos en molde de yeso"},
    {prodId:"31102308",label:"Objetos de titanio fundidos en molde de yeso"},
    {prodId:"31102309",label:"Objetos de berilio fundidos en molde de yeso"},
    {prodId:"31102310",label:"Objetos de cobre fundidos en molde de yeso"},
    {prodId:"31102311",label:"Objetos de latón fundidos en molde de yeso"},
    {prodId:"31102312",label:"Objetos de bronce fundidos en molde de yeso"},
    {prodId:"31102313",label:"Objetos de zinc fundidos en molde de yeso"},
    {prodId:"31102314",label:"Objetos de estaño fundidos en molde de yeso"},
    {prodId:"31102315",label:"Objetos de plomo fundidos en molde de yeso"},
    {prodId:"31102316",label:"Objetos de metal precioso fundidos en molde de yeso"},
    {prodId:"31102400",label:"Fundiciones en V"},
    {prodId:"31102401",label:"Objetos de aleación no ferrosa fundidos por proceso en v"},
    {prodId:"31102402",label:"Objetos de aleación ferrosa fundidos por proceso en v"},
    {prodId:"31102403",label:"Objetos de acero fundidos por proceso en v"},
    {prodId:"31102404",label:"Objetos de acero inoxidable fundidos por proceso en v"},
    {prodId:"31102405",label:"Objetos de hierro fundidos por proceso en v"},
    {prodId:"31102406",label:"Objetos de aluminio fundidos por proceso en v"},
    {prodId:"31102407",label:"Objetos de magnesio fundidos por proceso en v"},
    {prodId:"31102408",label:"Objetos de titanio fundidos por proceso en v"},
    {prodId:"31102409",label:"Objetos de berilio fundidos por proceso en v"},
    {prodId:"31102410",label:"Objetos de cobre fundidos por proceso en v"},
    {prodId:"31102411",label:"Objetos de latón fundidos por proceso en v"},
    {prodId:"31102412",label:"Objetos de bronce fundidos por proceso en v"},
    {prodId:"31102413",label:"Objetos de zinc fundidos por proceso en v"},
    {prodId:"31102414",label:"Objetos de estaño fundidos por proceso en v"},
    {prodId:"31102415",label:"Objetos de plomo fundidos por proceso en v"},
    {prodId:"31102416",label:"Objetos de metal precioso fundidos por proceso en v"},
    {prodId:"31102600",label:"Fundiciones con troquel y ensambles de fundiciones  de alta presión"},
    {prodId:"31102601",label:"Objetos de aluminio de alta presión fundidos en molde fijo"},
    {prodId:"31102602",label:"Objetos de aluminio de alta presión moldeados con troquel"},
    {prodId:"31102603",label:"Objetos de zinc de alta presión moldeados con troquel"},
    {prodId:"31102900",label:"Moldeos con troquel y ensambles de fundiciones permanentes a gravedad"},
    {prodId:"31102901",label:"Objetos de aluminio moldeados por gravedad permanente"},
    {prodId:"31111500",label:"Extrusiones de perfiles"},
    {prodId:"31111501",label:"Extrusiones de perfiles de aluminio"},
    {prodId:"31111502",label:"Extrusiones de perfiles de berilio"},
    {prodId:"31111503",label:"Extrusiones de perfiles de latón"},
    {prodId:"31111504",label:"Extrusiones de perfiles de bronce"},
    {prodId:"31111505",label:"Extrusiones de perfiles de cobre"},
    {prodId:"31111506",label:"Extrusiones de perfiles de aleación ferrosa"},
    {prodId:"31111507",label:"Extrusiones de perfiles de plomo"},
    {prodId:"31111508",label:"Extrusiones de perfiles de magnesio"},
    {prodId:"31111509",label:"Extrusiones de perfiles de aleación no ferrosa"},
    {prodId:"31111510",label:"Extrusiones de perfiles de plástico"},
    {prodId:"31111511",label:"Extrusiones de perfiles de metal precioso"},
    {prodId:"31111512",label:"Extrusiones de perfiles de caucho"},
    {prodId:"31111513",label:"Extrusiones de perfiles de acero inoxidable"},
    {prodId:"31111514",label:"Extrusiones de perfiles de acero"},
    {prodId:"31111515",label:"Extrusiones de perfiles de estaño"},
    {prodId:"31111516",label:"Extrusiones de perfiles de titanio"},
    {prodId:"31111517",label:"Extrusiones de perfiles de zinc"},
    {prodId:"31111600",label:"Extrusiones por percusión"},
    {prodId:"31111601",label:"Extrusiones por impacto de aluminio"},
    {prodId:"31111602",label:"Extrusiones por impacto de berilio"},
    {prodId:"31111603",label:"Extrusiones por impacto de latón"},
    {prodId:"31111604",label:"Extrusiones por impacto de bronce"},
    {prodId:"31111605",label:"Extrusiones por impacto de cobre"},
    {prodId:"31111606",label:"Extrusiones por impacto de aleación ferrosa"},
    {prodId:"31111607",label:"Extrusiones por impacto de plomo"},
    {prodId:"31111608",label:"Extrusiones por impacto de magnesio"},
    {prodId:"31111609",label:"Extrusiones por impacto de aleación no ferrosa"},
    {prodId:"31111610",label:"Extrusiones por impacto de plástico"},
    {prodId:"31111611",label:"Extrusiones por impacto de metal precioso"},
    {prodId:"31111612",label:"Extrusiones por impacto de caucho"},
    {prodId:"31111613",label:"Extrusiones por impacto de acero inoxidable"},
    {prodId:"31111614",label:"Extrusiones por impacto de acero"},
    {prodId:"31111615",label:"Extrusiones por impacto de estaño"},
    {prodId:"31111616",label:"Extrusiones por impacto de titanio"},
    {prodId:"31111617",label:"Extrusiones por impacto de zinc"},
    {prodId:"31121000",label:"Piezas fundidas mecanizadas de proceso v"},
    {prodId:"31121001",label:"Objetos fundidos maquinados por proceso v de aleaciones no ferrosas"},
    {prodId:"31121002",label:"Objetos fundidos maquinados por proceso v de aleaciones ferrosas"},
    {prodId:"31121003",label:"Objetos fundidos maquinados por proceso v de acero"},
    {prodId:"31121004",label:"Objetos fundidos maquinados por proceso v de acero inoxidable"},
    {prodId:"31121005",label:"Objetos fundidos maquinados por proceso v de hierro"},
    {prodId:"31121006",label:"Objetos fundidos maquinados por proceso v de aluminio"},
    {prodId:"31121007",label:"Objetos fundidos maquinados por proceso v de magnesio"},
    {prodId:"31121008",label:"Objetos fundidos maquinados por proceso v de titanio"},
    {prodId:"31121009",label:"Objetos fundidos maquinados por proceso v de berilio"},
    {prodId:"31121010",label:"Objetos fundidos maquinados por proceso v de cobre"},
    {prodId:"31121011",label:"Objetos fundidos maquinados por proceso v de latón"},
    {prodId:"31121012",label:"Objetos fundidos maquinados por proceso v de bronce"},
    {prodId:"31121013",label:"Objetos fundidos maquinados por proceso v de zinc"},
    {prodId:"31121014",label:"Objetos fundidos maquinados por proceso v de estaño"},
    {prodId:"31121015",label:"Objetos fundidos maquinados por proceso v de plomo"},
    {prodId:"31121016",label:"Objetos fundidos maquinados por proceso v de metal precioso"},
    {prodId:"31121017",label:"Objetos fundidos maquinados por proceso v de compuestos"},
    {prodId:"31121018",label:"Objetos fundidos maquinados por proceso v de aleación de níquel"},
    {prodId:"31121019",label:"Objetos fundidos maquinados por proceso v no metálico"},
    {prodId:"31121100",label:"Fundiciones a troquel mecanizadas"},
    {prodId:"31121101",label:"Objetos fundidos maquinados con troquel de aleaciones no ferrosas"},
    {prodId:"31121102",label:"Objetos fundidos maquinados con troquel de aleaciones ferrosas"},
    {prodId:"31121103",label:"Objetos fundidos maquinados con troquel de acero"},
    {prodId:"31121104",label:"Objetos fundidos maquinados con troquel de acero inoxidable"},
    {prodId:"31121105",label:"Objetos fundidos maquinados con troquel de hierro"},
    {prodId:"31121106",label:"Objetos fundidos maquinados con troquel de aluminio"},
    {prodId:"31121107",label:"Objetos fundidos maquinados con troquel de magnesio"},
    {prodId:"31121108",label:"Objetos fundidos maquinados con troquel de titanio"},
    {prodId:"31121109",label:"Objetos fundidos maquinados con troquel de berilio"},
    {prodId:"31121110",label:"Objetos fundidos maquinados con troquel de cobre"},
    {prodId:"31121111",label:"Objetos fundidos maquinados con troquel de latón"},
    {prodId:"31121112",label:"Objetos fundidos maquinados con troquel de bronce"},
    {prodId:"31121113",label:"Objetos fundidos maquinados con troquel de zinc"},
    {prodId:"31121114",label:"Objetos fundidos maquinados con troquel de estaño"},
    {prodId:"31121115",label:"Objetos fundidos maquinados con troquel de plomo"},
    {prodId:"31121116",label:"Objetos fundidos maquinados con troquel de metal precioso"},
    {prodId:"31121117",label:"Objetos fundidos maquinados con troquel de compuestos"},
    {prodId:"31121118",label:"Objetos fundidos maquinados con troquel de aleación de níquel"},
    {prodId:"31121119",label:"Objetos fundidos maquinados con troquel no metálico"},
    {prodId:"31121120",label:"Objetos de aluminio de alta presión fundidos maquinados con troquel"},
    {prodId:"31121121",label:"Ensamble de objetos de aluminio de alta presión fundidos maquinados con troquel"},
    {prodId:"31121122",label:"Objetos de magnesio de alta presión fundidos maquinados con troquel"},
    {prodId:"31121123",label:"Ensamble de objetos de magnesio de alta presión fundidos maquinados con troquel"},
    {prodId:"31121124",label:"Objetos de zinc de alta presión fundidos maquinados con troquel"},
    {prodId:"31121125",label:"Ensamble de objetos de zinc de alta presión fundidos maquinados con troquel"},
    {prodId:"31121200",label:"Fundiciones en arena mecanizadas"},
    {prodId:"31121201",label:"Objetos maquinados de aleación no ferrosa fundidos en arena"},
    {prodId:"31121202",label:"Objetos maquinados de aleación ferrosa fundidos en arena"},
    {prodId:"31121203",label:"Objetos maquinados de acero fundidos en arena"},
    {prodId:"31121204",label:"Objetos maquinados de acero inoxidable fundidos en arena"},
    {prodId:"31121205",label:"Objetos maquinados de hierro fundidos en arena"},
    {prodId:"31121206",label:"Objetos maquinados de aluminio fundidos en arena"},
    {prodId:"31121207",label:"Objetos maquinados de magnesio fundidos en arena"},
    {prodId:"31121208",label:"Objetos maquinados de titanio fundidos en arena"},
    {prodId:"31121209",label:"Objetos maquinados de berilio fundidos en arena"},
    {prodId:"31121210",label:"Objetos maquinados de cobre fundidos en arena"},
    {prodId:"31121211",label:"Objetos maquinados de latón fundidos en arena"},
    {prodId:"31121212",label:"Objetos maquinados de bronce fundidos en arena"},
    {prodId:"31121213",label:"Objetos maquinados de cinc fundidos en arena"},
    {prodId:"31121214",label:"Objetos maquinados de estaño fundidos en arena"},
    {prodId:"31121215",label:"Objetos maquinados de plomo fundidos en arena"},
    {prodId:"31121216",label:"Objetos maquinados de metal precioso fundidos en arena"},
    {prodId:"31121217",label:"Objetos maquinados de compuestos fundidos en arena"},
    {prodId:"31121218",label:"Objetos maquinados de aleación de níquel fundidos en arena"},
    {prodId:"31121219",label:"Objetos maquinados no metálicos fundidos en arena"},
    {prodId:"31121220",label:"Objetos maquinados de aluminio fundidos en arena"},
    {prodId:"31121221",label:"Ensamble de objetos maquinados de aluminio fundidos en arena"},
    {prodId:"31121222",label:"Objetos maquinados de hierro dúctil fundidos en arena"},
    {prodId:"31121223",label:"Ensamble de objetos maquinados de hierro dúctil fundidos en arena"},
    {prodId:"31121224",label:"Objetos maquinados de hierro gris fundidos en arena"},
    {prodId:"31121225",label:"Ensamble de objetos maquinados de hierro gris fundidos en arena"},
    {prodId:"31121226",label:"Objetos maquinados de hierro maleable fundidos en arena"},
    {prodId:"31121227",label:"Ensamble de objetos maquinados de hierro maleable fundidos en arena"},
    {prodId:"31121300",label:"Fundiciones de molde permanente mecanizadas"},
    {prodId:"31121301",label:"Objetos maquinados en molde permanente de aleación no ferrosa fundidos"},
    {prodId:"31121302",label:"Objetos maquinados en molde permanente de aleación ferrosa fundidos"},
    {prodId:"31121303",label:"Objetos maquinados en molde permanente de acero fundidos"},
    {prodId:"31121304",label:"Objetos maquinados en molde permanente de acero inoxidable fundidos"},
    {prodId:"31121305",label:"Objetos maquinados en molde permanente de hierro fundidos"},
    {prodId:"31121306",label:"Objetos maquinados en molde permanente de aluminio fundidos"},
    {prodId:"31121307",label:"Objetos maquinados en molde permanente de magnesio fundidos"},
    {prodId:"31121308",label:"Objetos maquinados en molde permanente de titanio fundidos"},
    {prodId:"31121309",label:"Objetos maquinados en molde permanente de berilio fundidos"},
    {prodId:"31121310",label:"Objetos maquinados en molde permanente de cobre fundidos"},
    {prodId:"31121311",label:"Objetos maquinados en molde permanente de latón fundidos"},
    {prodId:"31121312",label:"Objetos maquinados en molde permanente de bronce fundidos"},
    {prodId:"31121313",label:"Objetos maquinados en molde permanente de cinc fundidos"},
    {prodId:"31121314",label:"Objetos maquinados en molde permanente de estaño fundidos"},
    {prodId:"31121315",label:"Objetos maquinados en molde permanente de plomo fundidos"},
    {prodId:"31121316",label:"Objetos maquinados en molde permanente de metal precioso fundidos"},
    {prodId:"31121317",label:"Objetos maquinados en molde permanente de compuestos fundidos"},
    {prodId:"31121318",label:"Objetos maquinados en molde permanente de aleación de níquel fundidos"},
    {prodId:"31121319",label:"Objetos maquinados en molde permanente no metálicos fundidos"},
    {prodId:"31121320",label:"Objetos maquinados en molde permanente de aluminio de baja presión fundidos"},
    {prodId:"31121321",label:"Ensamble de objetos maquinados en molde permanente de aluminio de baja presión fundidos"},
    {prodId:"31121700",label:"Piezas fundidas centrífugas mecanizadas"},
    {prodId:"31121701",label:"Objetos maquinados centrifugados de aleación no ferrosa fundidos"},
    {prodId:"31121702",label:"Objetos maquinados centrifugados de aleación ferrosa fundidos"},
    {prodId:"31121703",label:"Objetos maquinados centrifugados de acero fundidos"},
    {prodId:"31121704",label:"Objetos maquinados centrifugados de acero inoxidable fundidos"},
    {prodId:"31121705",label:"Objetos maquinados centrifugados de hierro fundidos"},
    {prodId:"31121706",label:"Objetos maquinados centrifugados de aluminio fundidos"},
    {prodId:"31121707",label:"Objetos maquinados centrifugados de magnesio fundidos"},
    {prodId:"31121708",label:"Objetos maquinados centrifugados de titanio fundidos"},
    {prodId:"31121709",label:"Objetos maquinados centrifugados de berilio fundidos"},
    {prodId:"31121710",label:"Objetos maquinados centrifugados de cobre fundidos"},
    {prodId:"31121711",label:"Objetos maquinados centrifugados de latón fundidos"},
    {prodId:"31121712",label:"Objetos maquinados centrifugados de bronce fundidos"},
    {prodId:"31121713",label:"Objetos maquinados centrifugados de cinc fundidos"},
    {prodId:"31121714",label:"Objetos maquinados centrifugados de estaño fundidos"},
    {prodId:"31121715",label:"Objetos maquinados centrifugados de plomo fundidos"},
    {prodId:"31121716",label:"Objetos maquinados centrifugados de metal precioso fundidos"},
    {prodId:"31121717",label:"Objetos maquinados centrifugados de compuestos fundidos"},
    {prodId:"31121718",label:"Objetos maquinados centrifugados de aleación de níquel fundidos"},
    {prodId:"31121719",label:"Objetos maquinados centrifugados no metálicos fundidos"},
    {prodId:"31132000",label:"Componentes de metal en polvo"},
    {prodId:"31132001",label:"Componentes de metal ferroso pulverizado"},
    {prodId:"31132002",label:"Partes de metal no ferroso pulverizado"},
    {prodId:"31132100",label:"Forjas de acero"},
    {prodId:"31132101",label:"Forja de acero maquinado forjado en frío"},
    {prodId:"31132102",label:"Forja de acero tratado con calor forjado en frío"},
    {prodId:"31132103",label:"Forja de acero maquinado y tratado con calor forjado en frío"},
    {prodId:"31132104",label:"Forja de acero tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31132105",label:"Forja de acero maquinado forjado en tibio"},
    {prodId:"31132106",label:"Forja de acero tratado con calor forjado en tibio"},
    {prodId:"31132107",label:"Forja de acero maquinado y tratado con calor forjado en tibio"},
    {prodId:"31132108",label:"Forja de acero tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31132109",label:"Forja de acero maquinado forjado en caliente"},
    {prodId:"31132110",label:"Forja de acero tratado con calor forjado en caliente"},
    {prodId:"31132111",label:"Forja de acero maquinado y tratado con calor forjado en caliente"},
    {prodId:"31132112",label:"Forja de acero tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31132300",label:"Forjas de aluminio"},
    {prodId:"31132301",label:"Forja de aluminio maquinado forjado en frío"},
    {prodId:"31132302",label:"Forja de aluminio tratado con calor forjado en frío"},
    {prodId:"31132303",label:"Forja de aluminio maquinado y tratado con calor forjado en frío"},
    {prodId:"31132304",label:"Forja de aluminio tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31132305",label:"Forja de aluminio maquinado forjado en tibio"},
    {prodId:"31132306",label:"Forja de aluminio tratado con calor forjado en tibio"},
    {prodId:"31132307",label:"Forja de aluminio maquinado y tratado con calor forjado en tibio"},
    {prodId:"31132308",label:"Forja de aluminio tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31132700",label:"Forjas de hierro"},
    {prodId:"31132701",label:"Forja de hierro maquinado forjado en frío"},
    {prodId:"31132702",label:"Forja de hierro tratado con calor forjado en frío"},
    {prodId:"31132703",label:"Forja de hierro maquinado y tratado con calor forjado en frío"},
    {prodId:"31132704",label:"Forja de hierro tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31132705",label:"Forja de hierro maquinado forjado en tibio"},
    {prodId:"31132706",label:"Forja de hierro tratado con calor forjado en tibio"},
    {prodId:"31132707",label:"Forja de hierro maquinado y tratado con calor forjado en tibio"},
    {prodId:"31132708",label:"Forja de hierro tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31132709",label:"Forja de hierro maquinado forjado en caliente"},
    {prodId:"31132710",label:"Forja de hierro tratado con calor forjado en caliente"},
    {prodId:"31132711",label:"Forja de hierro maquinado y tratado con calor forjado en caliente"},
    {prodId:"31132712",label:"Forja de hierro tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31132900",label:"Forjas de titanio"},
    {prodId:"31132901",label:"Forja de titanio maquinado forjado en frío"},
    {prodId:"31132902",label:"Forja de titanio tratado con calor forjado en frío"},
    {prodId:"31132903",label:"Forja de titanio maquinado y tratado con calor forjado en frío"},
    {prodId:"31132904",label:"Forja de titanio tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31132905",label:"Forja de titanio maquinado forjado en tibio"},
    {prodId:"31132906",label:"Forja de titanio tratado con calor forjado en tibio"},
    {prodId:"31132907",label:"Forja de titanio maquinado y tratado con calor forjado en tibio"},
    {prodId:"31132908",label:"Forja de titanio tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31132909",label:"Forja de titanio maquinado forjado en caliente"},
    {prodId:"31132910",label:"Forja de titanio tratado con calor forjado en caliente"},
    {prodId:"31132911",label:"Forja de titanio maquinado y tratado con calor forjado en caliente"},
    {prodId:"31132912",label:"Forja de titanio tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31133100",label:"Forjas de cobre"},
    {prodId:"31133101",label:"Forja de cobre maquinado forjado en frío"},
    {prodId:"31133102",label:"Forja de cobre tratado con calor forjado en frío"},
    {prodId:"31133103",label:"Forja de cobre maquinado y tratado con calor forjado en frío"},
    {prodId:"31133104",label:"Forja de cobre tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31133105",label:"Forja de cobre maquinado forjado en tibio"},
    {prodId:"31133106",label:"Forja de cobre tratado con calor forjado en tibio"},
    {prodId:"31133107",label:"Forja de cobre maquinado y tratado con calor forjado en tibio"},
    {prodId:"31133108",label:"Forja de cobre tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31133109",label:"Forja de cobre maquinado forjado en caliente"},
    {prodId:"31133110",label:"Forja de cobre tratado con calor forjado en caliente"},
    {prodId:"31133111",label:"Forja de cobre maquinado y tratado con calor forjado en caliente"},
    {prodId:"31133112",label:"Forja de cobre tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31133200",label:"Forjas de zinc"},
    {prodId:"31133201",label:"Forja de zinc maquinado forjado en frío"},
    {prodId:"31133202",label:"Forja de zinc tratado con calor forjado en frío"},
    {prodId:"31133203",label:"Forja de zinc maquinado y tratado con calor forjado en frío"},
    {prodId:"31133204",label:"Forja de zinc tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31133205",label:"Forja de zinc maquinado forjado en tibio"},
    {prodId:"31133206",label:"Forja de zinc tratado con calor forjado en tibio"},
    {prodId:"31133207",label:"Forja de zinc maquinado y tratado con calor forjado en tibio"},
    {prodId:"31133208",label:"Forja de zinc tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31133209",label:"Forja de zinc maquinado forjado en caliente"},
    {prodId:"31133210",label:"Forja de zinc tratado con calor forjado en caliente"},
    {prodId:"31133211",label:"Forja de zinc maquinado y tratado con calor forjado en caliente"},
    {prodId:"31133212",label:"Forja de zinc tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31133500",label:"Forjas de plomo"},
    {prodId:"31133501",label:"Forja de plomo maquinado forjado en frío"},
    {prodId:"31133502",label:"Forja de plomo tratado con calor forjado en frío"},
    {prodId:"31133503",label:"Forja de plomo maquinado y tratado con calor forjado en frío"},
    {prodId:"31133504",label:"Forja de plomo tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31133505",label:"Forja de plomo maquinado forjado en tibio"},
    {prodId:"31133506",label:"Forja de plomo tratado con calor forjado en tibio"},
    {prodId:"31133507",label:"Forja de plomo maquinado y tratado con calor forjado en tibio"},
    {prodId:"31133508",label:"Forja de plomo tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31133509",label:"Forja de plomo maquinado forjado en caliente"},
    {prodId:"31133510",label:"Forja de plomo tratado con calor forjado en caliente"},
    {prodId:"31133511",label:"Forja de plomo maquinado y tratado con calor forjado en caliente"},
    {prodId:"31133512",label:"Forja de plomo tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31133700",label:"Metales en polvos y aleaciones de metal"},
    {prodId:"31133701",label:"Fricción material de metal en polvo y aleación de metal"},
    {prodId:"31133702",label:"Contacto eléctrico de metal en polvo y aleación de metal"},
    {prodId:"31133703",label:"Carburo cementado de metal en polvo y aleación de metal"},
    {prodId:"31133704",label:"Matriz metal diamante de metal en polvo y aleación de metal"},
    {prodId:"31133705",label:"Recubrimiento conformal de silicona"},
    {prodId:"31133706",label:"Recubrimiento conformal de acrílico"},
    {prodId:"31133707",label:"Recubrimiento conformal de poliuretano"},
    {prodId:"31133708",label:"Recubrimiento conformal de epóxido"},
    {prodId:"31133709",label:"Recubrimiento conformal de parilene"},
    {prodId:"31133710",label:"Sellante de silicona"},
    {prodId:"31133711",label:"Sellante de poliuretano"},
    {prodId:"31141500",label:"Molduras por inyección"},
    {prodId:"31141501",label:"Molduras por inyección de plástico"},
    {prodId:"31141502",label:"Molduras por inyección de caucho"},
    {prodId:"31141503",label:"Molduras por inyección de vidrio"},
    {prodId:"31141600",label:"Molduras al vacío"},
    {prodId:"31141601",label:"Molduras al vacío de plástico"},
    {prodId:"31141602",label:"Molduras al vacío de caucho"},
    {prodId:"31141603",label:"Molduras al vacío de vidrio"},
    {prodId:"31141700",label:"Moldeados por inyección de aire"},
    {prodId:"31141701",label:"Moldeados plásticos por inyección de aire"},
    {prodId:"31141702",label:"Moldeados de caucho por inyección de aire"},
    {prodId:"31141800",label:"Moldeos por inyección y reacción (RIM)"},
    {prodId:"31141801",label:"Moldeados de inyección de reacción de plástico"},
    {prodId:"31141802",label:"Moldeados de inyección de reacción de caucho"},
    {prodId:"31141900",label:"Moldeados de inyección con inserción"},
    {prodId:"31141901",label:"Moldeado plástico con inserción previa a la inyección"},
    {prodId:"31142000",label:"Ensambles de moldeados termoplásticos"},
    {prodId:"31142001",label:"Ensamble de moldeado termoplástico por compresión"},
    {prodId:"31142002",label:"Ensamble de moldeado termoplástico por inmersión"},
    {prodId:"31142003",label:"Ensamble de moldeado termoplástico por aire"},
    {prodId:"31142004",label:"Ensamble de moldeado termoplástico por inyección"},
    {prodId:"31142005",label:"Ensamble de moldeado termoplástico por inyección asistida por gas"},
    {prodId:"31142006",label:"Ensamble de moldeado termoplástico por inyección de alta precisión"},
    {prodId:"31142007",label:"Ensamble de moldeado termoplástico por transferencia"},
    {prodId:"31142800",label:"Moldeados por inmersión"},
    {prodId:"31142801",label:"Moldeado por inmersión de plástico o plastisol"},
    {prodId:"31142802",label:"Moldeado por inmersión de látex"},
    {prodId:"31142803",label:"Moldeado por inmersión de neopreno"},
    {prodId:"31142804",label:"Moldeado por inmersión de uretano"},
    {prodId:"31142900",label:"Moldeados decorados en el molde por inyección"},
    {prodId:"31142901",label:"Aplique en molde para moldeado por inyección"},
    {prodId:"31142902",label:"Tinta en molde para traspaso por moldeo por inyección"},
    {prodId:"31142903",label:"Molde para moldeado por inyección de color"},
    {prodId:"31142904",label:"Moldeado por inyección de disparos múltiples"},
    {prodId:"31143100",label:"Ensambles de moldeado termoplástico terminados"},
    {prodId:"31143101",label:"Ensamble de moldeado termoplástico terminado por inyección"},
    {prodId:"31143102",label:"Ensamble de moldeado termoplástico terminado por compresión"},
    {prodId:"31143103",label:"Ensamble de moldeado termoplástico terminado por inyección asistida por gas"},
    {prodId:"31151500",label:"Cuerdas"},
    {prodId:"31151501",label:"Cuerda de algodón"},
    {prodId:"31151502",label:"Cuerda de poliéster"},
    {prodId:"31151503",label:"Cuerda de polipropileno"},
    {prodId:"31151504",label:"Cuerda de nylon"},
    {prodId:"31151505",label:"Cable de acero"},
    {prodId:"31151506",label:"Cuerda de cáñamo"},
    {prodId:"31151507",label:"Cuerda o pita"},
    {prodId:"31151508",label:"Cabuya"},
    {prodId:"31151509",label:"Cuerda de caucho"},
    {prodId:"31151510",label:"Cuerda de rayón"},
    {prodId:"31151511",label:"Cuerda de lino"},
    {prodId:"31151512",label:"Cuerda de polietileno"},
    {prodId:"31151513",label:"Cuerda de fibra de coco"},
    {prodId:"31151514",label:"Cuerda de yute"},
    {prodId:"31151515",label:"Cuerda de esparto"},
    {prodId:"31151516",label:"Cuerda de rafia"},
    {prodId:"31151517",label:"Cuerda de aloe"},
    {prodId:"31151518",label:"Cuerda impregnada"},
    {prodId:"31151519",label:"Cuerda de látex"},
    {prodId:"31151520",label:"Cuerda de vinylon"},
    {prodId:"31151521",label:"Cuerda de asbesto"},
    {prodId:"31151600",label:"Cadenas"},
    {prodId:"31151601",label:"Cadenas de seguridad"},
    {prodId:"31151603",label:"Cadenas de rodillos"},
    {prodId:"31151604",label:"Cadenas resistentes de bobina"},
    {prodId:"31151605",label:"Cadenas de banda"},
    {prodId:"31151606",label:"Cadena jack"},
    {prodId:"31151607",label:"Cadenas corrientes"},
    {prodId:"31151608",label:"Cadenas de bola"},
    {prodId:"31151609",label:"Eslabones de cadena"},
    {prodId:"31151610",label:"Cadena de pasador hueco"},
    {prodId:"31151611",label:"Cadena silenciosa"},
    {prodId:"31151612",label:"Cadena de bloque"},
    {prodId:"31151613",label:"Cadena de ingeniería"},
    {prodId:"31151614",label:"Cadena transportadora"},
    {prodId:"31151615",label:"Cadena de rodillo o sobre placa"},
    {prodId:"31151616",label:"Cadena tipo muela"},
    {prodId:"31151617",label:"Cadena draga línea"},
    {prodId:"31151618",label:"Cadena de muro alto"},
    {prodId:"31151619",label:"Cadena de escalón de escalera eléctrica"},
    {prodId:"31151700",label:"Cable mecánico"},
    {prodId:"31151702",label:"Cables de control no eléctrico"},
    {prodId:"31151703",label:"Cables elevadores"},
    {prodId:"31151704",label:"Cable-vías"},
    {prodId:"31151705",label:"Cable de acero no eléctrico"},
    {prodId:"31151706",label:"Cable de cobre no eléctrico"},
    {prodId:"31151707",label:"Cable de aluminio no eléctrico"},
    {prodId:"31151708",label:"Cable guy"},
    {prodId:"31151709",label:"Cable de hierro no eléctrico"},
    {prodId:"31151710",label:"Cable sintético"},
    {prodId:"31151800",label:"Alambre mecánico"},
    {prodId:"31151803",label:"Cuerda de acero para piano"},
    {prodId:"31151804",label:"Alambre de grapa"},
    {prodId:"31151805",label:"Alambre de multi - filamentos"},
    {prodId:"31151806",label:"Alambre laminado"},
    {prodId:"31151900",label:"Correas"},
    {prodId:"31151901",label:"Correas de metal"},
    {prodId:"31151902",label:"Correas de cuero"},
    {prodId:"31151903",label:"Correas de fibra"},
    {prodId:"31151904",label:"Correas plásticas"},
    {prodId:"31151905",label:"Correas de caucho"},
    {prodId:"31151906",label:"Correas para amarrar los raches"},
    {prodId:"31152000",label:"Cable de seguridad"},
    {prodId:"31152001",label:"Alambre de navaja"},
    {prodId:"31152002",label:"Alambre de púas"},
    {prodId:"31152100",label:"Cordones"},
    {prodId:"31152101",label:"Cordón de algodón"},
    {prodId:"31152102",label:"Cordón de nylon"},
    {prodId:"31152103",label:"Cordón metálico"},
    {prodId:"31152104",label:"Cordón de silicona"},
    {prodId:"31152105",label:"Cordón de látex"},
    {prodId:"31152106",label:"Cordón de poliéster"},
    {prodId:"31152107",label:"Cordón de neopreno"},
    {prodId:"31152108",label:"Cordón de polipropileno"},
    {prodId:"31152109",label:"Cordón de caucho"},
    {prodId:"31152110",label:"Cordón de asbesto"},
    {prodId:"31152111",label:"Cordón de fibra de vidrio"},
    {prodId:"31152112",label:"Cordón de fibra cerámica"},
    {prodId:"31152200",label:"Alambres no eléctricos de hierro y acero"},
    {prodId:"31152201",label:"Cable de acero bajo en carbono"},
    {prodId:"31152202",label:"Cable de acero semiduro"},
    {prodId:"31152203",label:"Cable de acero inoxidable"},
    {prodId:"31152204",label:"Cable de acero trenzado"},
    {prodId:"31152205",label:"Cable de acero templado con aceite"},
    {prodId:"31152206",label:"Cable de acero recubierto"},
    {prodId:"31152207",label:"Cable de acero de concreto pretensado"},
    {prodId:"31152208",label:"Cable de acero de concreto pretensado trenzado"},
    {prodId:"31152209",label:"Alambre de hierro"},
    {prodId:"31152300",label:"Alambres no eléctricos de metales no ferrosos"},
    {prodId:"31152301",label:"Alambre de plomo o aleación de plomo"},
    {prodId:"31152302",label:"Alambre de níquel o aleación de níquel"},
    {prodId:"31152303",label:"Alambre de aleación nicromo"},
    {prodId:"31152304",label:"Alambre de cobre o aleación de cobre"},
    {prodId:"31152305",label:"Alambre de aluminio o aleación de aluminio"},
    {prodId:"31152306",label:"Alambre de estaño o aleación de estaño"},
    {prodId:"31152307",label:"Alambre de titanio o aleación de titanio"},
    {prodId:"31161500",label:"Tornillos"},
    {prodId:"31161501",label:"Tornillos de perno"},
    {prodId:"31161502",label:"Tornillos de anclaje"},
    {prodId:"31161503",label:"Clavo-tornillo"},
    {prodId:"31161504",label:"Tornillos de máquina"},
    {prodId:"31161505",label:"Tornillos de presión"},
    {prodId:"31161506",label:"Tornillos para lámina metálica"},
    {prodId:"31161507",label:"Tornillos roscadores"},
    {prodId:"31161508",label:"Tornillos de rosca para madera"},
    {prodId:"31161509",label:"Tornillos para drywall"},
    {prodId:"31161510",label:"Tornillo cautivo"},
    {prodId:"31161511",label:"Tornillos de apriete"},
    {prodId:"31161512",label:"Tornillos de rosca para laminados"},
    {prodId:"31161513",label:"Tornillos de cabeza perdida"},
    {prodId:"31161514",label:"Tornillos de soldadura"},
    {prodId:"31161516",label:"Tornillo de orejas"},
    {prodId:"31161517",label:"Tornillo de hombros"},
    {prodId:"31161518",label:"Tornillo de enchufe"},
    {prodId:"31161519",label:"Tornillo de balde elevador"},
    {prodId:"31161520",label:"Tornillo de cabeza hexagonal"},
    {prodId:"31161521",label:"Tornillo de carruaje"},
    {prodId:"31161522",label:"Tornillo de ojo"},
    {prodId:"31161523",label:"Tronillo de ala"},
    {prodId:"31161524",label:"Tornillo decorativo"},
    {prodId:"31161525",label:"Tornillo para plástico"},
    {prodId:"31161526",label:"Tornillo de concreto"},
    {prodId:"31161527",label:"Tornillo en t o cabeza de martillo"},
    {prodId:"31161528",label:"Tornillo auto - entrante"},
    {prodId:"31161529",label:"Tornillo de gato"},
    {prodId:"31161530",label:"Tornillo grub"},
    {prodId:"31161531",label:"Tornillo especializado"},
    {prodId:"31161532",label:"Tornillo allen"},
    {prodId:"31161533",label:"Tornillo con arandela ensamblada"},
    {prodId:"31161534",label:"Tornillo de rosca trapezoidal"},
    {prodId:"31161600",label:"Pernos"},
    {prodId:"31161601",label:"Pernos de anclaje"},
    {prodId:"31161602",label:"Pernos ciegos"},
    {prodId:"31161603",label:"Pernos de carruaje"},
    {prodId:"31161604",label:"Pernos de horquilla"},
    {prodId:"31161605",label:"Pernos de cilindro"},
    {prodId:"31161606",label:"Cerrojos de puerta"},
    {prodId:"31161607",label:"Pernos de expansión"},
    {prodId:"31161608",label:"Tirafondos"},
    {prodId:"31161609",label:"Tornillo de fiador"},
    {prodId:"31161610",label:"Pernos de ojo"},
    {prodId:"31161611",label:"Pernos de sujeción"},
    {prodId:"31161612",label:"Pernos de espiga o de reborde"},
    {prodId:"31161613",label:"Pernos de tensión"},
    {prodId:"31161614",label:"Pernos estructurales"},
    {prodId:"31161616",label:"Pernos en u"},
    {prodId:"31161617",label:"Pernos de ala"},
    {prodId:"31161618",label:"Varilla roscada"},
    {prodId:"31161619",label:"Pernos prisioneros"},
    {prodId:"31161620",label:"Pernos de cabeza hexagonal"},
    {prodId:"31161621",label:"Pernos elevadores"},
    {prodId:"31161622",label:"Perno de cizalla"},
    {prodId:"31161623",label:"Perno de cable"},
    {prodId:"31161624",label:"Perno de resina"},
    {prodId:"31161625",label:"Perno de carrilera"},
    {prodId:"31161626",label:"Perno sems de cabeza plana"},
    {prodId:"31161627",label:"Ensamble de pernos"},
    {prodId:"31161628",label:"Perno de cabeza cuadrada"},
    {prodId:"31161629",label:"Perno de cabeza redonda"},
    {prodId:"31161630",label:"Perno en blanco"},
    {prodId:"31161631",label:"Perno de hombro"},
    {prodId:"31161632",label:"Perno de roca"},
    {prodId:"31161633",label:"Perno de estufa"},
    {prodId:"31161634",label:"Perno de sobre - cuello"},
    {prodId:"31161635",label:"Perno con arandela ensamblada"},
    {prodId:"31161636",label:"Perno de soldadura"},
    {prodId:"31161637",label:"Perno de cabeza de enchufe"},
    {prodId:"31161638",label:"Perno t"},
    {prodId:"31161639",label:"Perno de gancho"},
    {prodId:"31161640",label:"Perno garfio"},
    {prodId:"31161700",label:"Tuercas"},
    {prodId:"31161701",label:"Tuercas de anclaje"},
    {prodId:"31161702",label:"Tuercas de rodamiento"},
    {prodId:"31161703",label:"Tuercas ciegas"},
    {prodId:"31161704",label:"Tuercas de cañón"},
    {prodId:"31161705",label:"Tuerca tapa"},
    {prodId:"31161706",label:"Tuercas cautivas"},
    {prodId:"31161707",label:"Tuercas almenadas"},
    {prodId:"31161708",label:"Tuercas de canal"},
    {prodId:"31161709",label:"Tuercas sujetadoras"},
    {prodId:"31161710",label:"Tuercas de expansión"},
    {prodId:"31161711",label:"Tuercas de ojo"},
    {prodId:"31161712",label:"Tuercas de brida"},
    {prodId:"31161713",label:"Tuercas de manguera"},
    {prodId:"31161714",label:"Tuercas de inserción"},
    {prodId:"31161716",label:"Contratuercas"},
    {prodId:"31161717",label:"Tuercas de aletas"},
    {prodId:"31161718",label:"Tuercas de fiador"},
    {prodId:"31161719",label:"Tuercas giratorias"},
    {prodId:"31161720",label:"Tuercas limitadoras"},
    {prodId:"31161721",label:"Tuercas de resorte"},
    {prodId:"31161722",label:"Tuercas de unión"},
    {prodId:"31161723",label:"Tuercas de placa con rosca"},
    {prodId:"31161724",label:"Tuercas de prensa"},
    {prodId:"31161725",label:"Tuercas abrazaderas"},
    {prodId:"31161726",label:"Tuercas domo"},
    {prodId:"31161727",label:"Tuercas hexagonales"},
    {prodId:"31161728",label:"Tuercas de acople"},
    {prodId:"31161729",label:"Tuercas estriadas"},
    {prodId:"31161730",label:"Tuercas cuadradas"},
    {prodId:"31161731",label:"Tuercas soldables"},
    {prodId:"31161732",label:"Tuerca medialuna"},
    {prodId:"31161733",label:"Tuerca en t o ranura de tuerca en t"},
    {prodId:"31161734",label:"Tuerca redonda con ranura"},
    {prodId:"31161735",label:"Tuerca de jaula"},
    {prodId:"31161736",label:"Tapa de tuerca decorativa"},
    {prodId:"31161737",label:"Tuerca de cizalla"},
    {prodId:"31161738",label:"Tuerca keps o k"},
    {prodId:"31161739",label:"Tuerca de soldadura"},
    {prodId:"31161740",label:"Tuerca perno"},
    {prodId:"31161741",label:"Tuerca de llave interna"},
    {prodId:"31161800",label:"Arandelas"},
    {prodId:"31161801",label:"Arandelas de seguridad"},
    {prodId:"31161802",label:"Arandelas achaflanadas"},
    {prodId:"31161803",label:"Arandelas de fijación"},
    {prodId:"31161804",label:"Arandelas curvas"},
    {prodId:"31161805",label:"Arandelas aislantes eléctricas"},
    {prodId:"31161806",label:"Arandelas de acabado"},
    {prodId:"31161807",label:"Arandelas planas"},
    {prodId:"31161808",label:"Arandelas abiertas"},
    {prodId:"31161809",label:"Arandelas reductoras"},
    {prodId:"31161810",label:"Arandelas de separación"},
    {prodId:"31161811",label:"Arandelas de resorte"},
    {prodId:"31161812",label:"Arandelas cuadradas"},
    {prodId:"31161813",label:"Arandelas giratorias"},
    {prodId:"31161814",label:"Arandelas de empuje"},
    {prodId:"31161815",label:"Arandelas de tope"},
    {prodId:"31161816",label:"Espaciadores y separadores"},
    {prodId:"31161817",label:"Arandelas cónicas"},
    {prodId:"31161818",label:"Arandelas de sellado"},
    {prodId:"31161819",label:"Juegos de arandelas"},
    {prodId:"31161820",label:"Arandelas en domo o esféricas"},
    {prodId:"31161821",label:"Arandela roscada"},
    {prodId:"31161822",label:"Arandelas con dientes de seguridad"},
    {prodId:"31161823",label:"Arandela distribuidora"},
    {prodId:"31161824",label:"Arandela para perno de techo"},
    {prodId:"31161825",label:"Arandela belleville"},
    {prodId:"31161826",label:"Arandela dentada"},
    {prodId:"31161827",label:"Arandela recortada"},
    {prodId:"31161828",label:"Arandela con ranura"},
    {prodId:"31161829",label:"Arandela retenedora"},
    {prodId:"31161830",label:"Arandelas de ola"},
    {prodId:"31161831",label:"Arandela reductora de vibración"},
    {prodId:"31161832",label:"Arandelas avellanadas"},
    {prodId:"31161833",label:"Arandela empaque"},
    {prodId:"31161834",label:"Arandelas de copa"},
    {prodId:"31161835",label:"Arandelas de pestaña"},
    {prodId:"31161836",label:"Arandelas de placa de anclaje"},
    {prodId:"31161837",label:"Arandelas de división"},
    {prodId:"31161838",label:"Arandelas lenguadas"},
    {prodId:"31161900",label:"Muelles"},
    {prodId:"31161901",label:"Muelles helicoidales"},
    {prodId:"31161902",label:"Muelles"},
    {prodId:"31161903",label:"Muelles espirales"},
    {prodId:"31161904",label:"Resortes de compresión"},
    {prodId:"31161905",label:"Resortes para estampas"},
    {prodId:"31161906",label:"Muelles de disco"},
    {prodId:"31161907",label:"Muelles de extensión"},
    {prodId:"31161908",label:"Muelles de torsión"},
    {prodId:"31161909",label:"Muelle en ola"},
    {prodId:"31161910",label:"Resorte de alambre"},
    {prodId:"31161911",label:"Ensamble de resorte"},
    {prodId:"31161912",label:"Resorte de válvula inyectora"},
    {prodId:"31162000",label:"Clavos"},
    {prodId:"31162001",label:"Chinches"},
    {prodId:"31162002",label:"Clavos de sombrerete"},
    {prodId:"31162003",label:"Clavos de acabado"},
    {prodId:"31162004",label:"Clavos de mampostería"},
    {prodId:"31162005",label:"Clavos para tejados"},
    {prodId:"31162006",label:"Clavos de alambre"},
    {prodId:"31162007",label:"Clavos de tapicería"},
    {prodId:"31162008",label:"Pasadores de arrastre"},
    {prodId:"31162009",label:"Clavo de fibra cemento"},
    {prodId:"31162010",label:"Clavo de herradura"},
    {prodId:"31162011",label:"Puntilla decorativa"},
    {prodId:"31162012",label:"Clavo de levantamientos topográficos"},
    {prodId:"31162013",label:"Clave de botes"},
    {prodId:"31162014",label:"Clavo ferroviario"},
    {prodId:"31162100",label:"Anclas"},
    {prodId:"31162101",label:"Anclajes de concreto"},
    {prodId:"31162102",label:"Anclajes de cuña"},
    {prodId:"31162103",label:"Anclajes de pared"},
    {prodId:"31162104",label:"Anclajes de tornillo"},
    {prodId:"31162105",label:"Anclajes de resina"},
    {prodId:"31162106",label:"Anclaje de tubería"},
    {prodId:"31162107",label:"Anclajes de expansión de clavo"},
    {prodId:"31162108",label:"Anclajes de amarre"},
    {prodId:"31162109",label:"Anclaje de cimentación"},
    {prodId:"31162200",label:"Remaches"},
    {prodId:"31162201",label:"Remaches ciegos"},
    {prodId:"31162202",label:"Remaches de corona"},
    {prodId:"31162203",label:"Remaches de cabeza plana"},
    {prodId:"31162204",label:"Remaches completos"},
    {prodId:"31162205",label:"Remaches de trinquetes"},
    {prodId:"31162206",label:"Remaches de estañador"},
    {prodId:"31162207",label:"Remaches de compresión"},
    {prodId:"31162208",label:"Remaches de tonelero"},
    {prodId:"31162209",label:"Remaches de botón"},
    {prodId:"31162210",label:"Remaches articulados"},
    {prodId:"31162211",label:"Remaches sólidos"},
    {prodId:"31162212",label:"Remache con mandril"},
    {prodId:"31162213",label:"Remache aerodinámico"},
    {prodId:"31162214",label:"Remache semi - tubular"},
    {prodId:"31162215",label:"Remache tubular"},
    {prodId:"31162216",label:"Remache de hombro"},
    {prodId:"31162300",label:"Ferretería de montaje"},
    {prodId:"31162301",label:"Perfiles de montaje"},
    {prodId:"31162303",label:"Barras de montaje"},
    {prodId:"31162304",label:"Regletas de montaje"},
    {prodId:"31162305",label:"Abrazaderas de montaje"},
    {prodId:"31162306",label:"Soportes colgantes de montaje"},
    {prodId:"31162307",label:"Placas de montaje"},
    {prodId:"31162308",label:"Paneles de montaje"},
    {prodId:"31162309",label:"Estantes de montaje"},
    {prodId:"31162310",label:"Correas de montaje"},
    {prodId:"31162311",label:"Bujes de pared"},
    {prodId:"31162312",label:"Pasadores de montaje"},
    {prodId:"31162313",label:"Kits de montaje"},
    {prodId:"31162314",label:"Espaciador de baldosas"},
    {prodId:"31162400",label:"Fijadores varios"},
    {prodId:"31162401",label:"Aros interiores"},
    {prodId:"31162402",label:"Cerraduras"},
    {prodId:"31162403",label:"Goznes o bisagras"},
    {prodId:"31162404",label:"Grapas"},
    {prodId:"31162405",label:"Tensores"},
    {prodId:"31162406",label:"Cierres para zunchado"},
    {prodId:"31162407",label:"Pestillo"},
    {prodId:"31162409",label:"Pasador de horquilla"},
    {prodId:"31162410",label:"Pasadores estriados"},
    {prodId:"31162411",label:"Anillos elásticos"},
    {prodId:"31162412",label:"Horquilla"},
    {prodId:"31162413",label:"Cierre de presión"},
    {prodId:"31162414",label:"Abrazadera"},
    {prodId:"31162415",label:"Uñeta"},
    {prodId:"31162416",label:"Pasadores de conexión o acoplamiento"},
    {prodId:"31162417",label:"Pasadores de alineación"},
    {prodId:"31162418",label:"Ataduras de torsión"},
    {prodId:"31162419",label:"Anillos y eslabones"},
    {prodId:"31162420",label:"Bisagra de ingeniería"},
    {prodId:"31162421",label:"Trampa"},
    {prodId:"31162500",label:"Soportes y puntales"},
    {prodId:"31162501",label:"Soportes para estanterías"},
    {prodId:"31162502",label:"Soportes en escuadra"},
    {prodId:"31162503",label:"Puntales"},
    {prodId:"31162504",label:"Soportes para accesorios eléctricos"},
    {prodId:"31162505",label:"Soportes de montaje magnético"},
    {prodId:"31162506",label:"Soporte de pared"},
    {prodId:"31162507",label:"Soportes de piñón"},
    {prodId:"31162508",label:"Soporte de eje de ojo"},
    {prodId:"31162600",label:"Ganchos"},
    {prodId:"31162601",label:"Ganchos giratorios"},
    {prodId:"31162602",label:"Ganchos de resorte"},
    {prodId:"31162603",label:"Ganchos en s"},
    {prodId:"31162604",label:"Ganchos de seguridad"},
    {prodId:"31162605",label:"Ganchos de suspensión"},
    {prodId:"31162606",label:"Ganchos en j"},
    {prodId:"31162607",label:"Ganchos de alambre para riostras"},
    {prodId:"31162608",label:"Ganchos de grúa"},
    {prodId:"31162609",label:"Ganchos de atornillar"},
    {prodId:"31162610",label:"Ganchos de tablero"},
    {prodId:"31162611",label:"Gancho de deslizamiento"},
    {prodId:"31162612",label:"Gancho cuadrado"},
    {prodId:"31162613",label:"Gancho en forma de 8"},
    {prodId:"31162614",label:"Gancho fijo"},
    {prodId:"31162700",label:"Roldanas pivotantes y Ruedas"},
    {prodId:"31162701",label:"Ruedas para muebles"},
    {prodId:"31162702",label:"Ruedas"},
    {prodId:"31162703",label:"Deslizadoras"},
    {prodId:"31162704",label:"Puntas de rodillo"},
    {prodId:"31162800",label:"Ferretería en general"},
    {prodId:"31162801",label:"Chapas o pomos"},
    {prodId:"31162802",label:"Insertos"},
    {prodId:"31162803",label:"Grilletes"},
    {prodId:"31162804",label:"Topes de puerta"},
    {prodId:"31162805",label:"Cable dedal"},
    {prodId:"31162806",label:"Cubiertas de tornillos"},
    {prodId:"31162807",label:"Palancas"},
    {prodId:"31162808",label:"Barras de pánico"},
    {prodId:"31162809",label:"Pasadores posicionadores"},
    {prodId:"31162810",label:"Empalmes o placas de unión"},
    {prodId:"31162811",label:"Collar del eje"},
    {prodId:"31162812",label:"Mangas de prensado"},
    {prodId:"31162813",label:"Ganchos para cerda de ropa"},
    {prodId:"31162814",label:"Kit de sujetadores varios"},
    {prodId:"31162815",label:"Calce"},
    {prodId:"31162816",label:"Hilera"},
    {prodId:"31162817",label:"Soporte de cuerda de ropa"},
    {prodId:"31162818",label:"Ánodo anti corrosión"},
    {prodId:"31162819",label:"Palanca de control"},
    {prodId:"31162900",label:"Mordazas"},
    {prodId:"31162901",label:"Abrazaderas de espiga"},
    {prodId:"31162902",label:"Abrazaderas de resorte"},
    {prodId:"31162903",label:"Abrazaderas de tornillo"},
    {prodId:"31162904",label:"Abrazadera de cable metálico"},
    {prodId:"31162905",label:"Abrazadera para viga doble t"},
    {prodId:"31162906",label:"Abrazaderas de manguera o tubo"},
    {prodId:"31162907",label:"Abrazadera de extensión"},
    {prodId:"31162908",label:"Abrazadera en miniatura"},
    {prodId:"31162909",label:"Abrazadera isofónica"},
    {prodId:"31162910",label:"Abrazadera de taladro"},
    {prodId:"31162911",label:"Abrazadera de tornillo manual"},
    {prodId:"31162912",label:"Abrazadera de banda"},
    {prodId:"31162913",label:"Abrazadera de banco"},
    {prodId:"31162914",label:"Abrazadera de red"},
    {prodId:"31162915",label:"Abrazadera de ingletes"},
    {prodId:"31162916",label:"Abrazadera de acción rápida"},
    {prodId:"31162917",label:"Abrazadera de agarre rápido"},
    {prodId:"31162918",label:"Abrazadera de potencia"},
    {prodId:"31162919",label:"Abrazadera de velocidad"},
    {prodId:"31162920",label:"Abrazadera de marco de fotos"},
    {prodId:"31163000",label:"Embragues"},
    {prodId:"31163001",label:"Acoples elastoméricos"},
    {prodId:"31163002",label:"Acoples por engranaje"},
    {prodId:"31163003",label:"Acoples metálicos"},
    {prodId:"31163004",label:"Acoples en miniatura"},
    {prodId:"31163005",label:"Manguitos de acoplamiento"},
    {prodId:"31163006",label:"Acoples de rejilla"},
    {prodId:"31163007",label:"Acoples de cadena"},
    {prodId:"31163008",label:"Acoples de disco"},
    {prodId:"31163009",label:"Acoples rígidos"},
    {prodId:"31163010",label:"Acoples de araña"},
    {prodId:"31163011",label:"Acoples de mordaza"},
    {prodId:"31163012",label:"Acoples medios"},
    {prodId:"31163013",label:"Acoples de ejes"},
    {prodId:"31163014",label:"Acoples giratorio"},
    {prodId:"31163015",label:"Acoples oldham"},
    {prodId:"31163016",label:"Acoples de varilla"},
    {prodId:"31163017",label:"Varilla de acople"},
    {prodId:"31163018",label:"Acoples de fuelle"},
    {prodId:"31163019",label:"Acoples magnéticos"},
    {prodId:"31163020",label:"Acoples de brida"},
    {prodId:"31163021",label:"Acople universal flexible"},
    {prodId:"31163022",label:"Acoples de abrazadera"},
    {prodId:"31163023",label:"Acoples de mariposa"},
    {prodId:"31163024",label:"Acoples de brida tipo flexible"},
    {prodId:"31163025",label:"Acoples de mariposa partida"},
    {prodId:"31163026",label:"Acoples de media vuelta"},
    {prodId:"31163027",label:"Acoples de cadena giratoria"},
    {prodId:"31163028",label:"Acoples de rejilla de tipo flexible"},
    {prodId:"31163029",label:"Acoples de clip de fricción"},
    {prodId:"31163030",label:"Acoples duraflex"},
    {prodId:"31163031",label:"Acoples de vendedores"},
    {prodId:"31163032",label:"Adapatador eléctrico de acoples"},
    {prodId:"31163100",label:"Conectores"},
    {prodId:"31163101",label:"Desconectores rápidos"},
    {prodId:"31163102",label:"Férula"},
    {prodId:"31163103",label:"Conector de remolque"},
    {prodId:"31163200",label:"Herraje de retención"},
    {prodId:"31163201",label:"Pasadores de resorte"},
    {prodId:"31163202",label:"Anillos de retención"},
    {prodId:"31163203",label:"Pasador de espiga"},
    {prodId:"31163204",label:"Pasador de horquilla"},
    {prodId:"31163205",label:"Pasadores de cono"},
    {prodId:"31163207",label:"Horquillas de eje o de disco"},
    {prodId:"31163208",label:"Bloquecillo"},
    {prodId:"31163209",label:"Soportes o retenes del rodamiento"},
    {prodId:"31163210",label:"Collares de retención"},
    {prodId:"31163211",label:"Grapas de retención"},
    {prodId:"31163212",label:"Pasadores roscados"},
    {prodId:"31163213",label:"Pasadores de pivote"},
    {prodId:"31163214",label:"Pasadores de deslizamiento"},
    {prodId:"31163215",label:"Pasadores estriados"},
    {prodId:"31163216",label:"Colgador de cable"},
    {prodId:"31163217",label:"Bujes de bloqueo de tubo de desconexión rápida"},
    {prodId:"31163218",label:"Manga para adaptación de rodamiento"},
    {prodId:"31163219",label:"Pasador cilíndrico"},
    {prodId:"31163220",label:"Juego de pines diversos"},
    {prodId:"31163221",label:"Llave de pasador"},
    {prodId:"31163222",label:"Llave cuadrada"},
    {prodId:"31163223",label:"Llave rectangular"},
    {prodId:"31163224",label:"Llave cónica"},
    {prodId:"31163225",label:"Llave de broche de cuña"},
    {prodId:"31163226",label:"Anillo de seguridad"},
    {prodId:"31163227",label:"Anillo en eje (flinger)"},
    {prodId:"31163228",label:"Anillo partido"},
    {prodId:"31163229",label:"Clip retenedor metálico"},
    {prodId:"31163230",label:"Clip retenedor plástico"},
    {prodId:"31163231",label:"Collar de retención de tubos"},
    {prodId:"31163300",label:"Espaciadores"},
    {prodId:"31163301",label:"Montante de dos extremos"},
    {prodId:"31163302",label:"Montante para soldar"},
    {prodId:"31163303",label:"Montante de bola"},
    {prodId:"31163304",label:"Montante complejo"},
    {prodId:"31163400",label:"Redes de metal y estructuras de cribado"},
    {prodId:"31163401",label:"Gavión"},
    {prodId:"31171500",label:"Rodamientos"},
    {prodId:"31171501",label:"Rodamientos embridados"},
    {prodId:"31171502",label:"Rodamientos radiales"},
    {prodId:"31171503",label:"Rodamientos de rueda"},
    {prodId:"31171504",label:"Rodamientos de balineras"},
    {prodId:"31171505",label:"Rodamientos de rodillos"},
    {prodId:"31171506",label:"Rodamientos lineales"},
    {prodId:"31171507",label:"Rodamientos de empuje"},
    {prodId:"31171508",label:"Rodamientos de cabeza de biela"},
    {prodId:"31171509",label:"Rodamientos de manguito interior"},
    {prodId:"31171510",label:"Rodamientos esféricos"},
    {prodId:"31171511",label:"Soportes de cojinetes"},
    {prodId:"31171512",label:"Rodamiento de agujas"},
    {prodId:"31171513",label:"Rodamientos colgaderos"},
    {prodId:"31171515",label:"Rodamientos simples"},
    {prodId:"31171516",label:"Rodamientos cónicos"},
    {prodId:"31171518",label:"Jaula de rodamiento"},
    {prodId:"31171519",label:"Carcasas o pedestales de rodamientos"},
    {prodId:"31171520",label:"Muñones de rodamientos"},
    {prodId:"31171522",label:"Rodamientos magnéticos"},
    {prodId:"31171523",label:"Rodamientos neumáticos"},
    {prodId:"31171524",label:"Tapas de rodamientos"},
    {prodId:"31171525",label:"Revestimientos de rodamiento"},
    {prodId:"31171526",label:"Almohadillas de rodamiento"},
    {prodId:"31171527",label:"Conos de rodamiento"},
    {prodId:"31171528",label:"Rodamiento partido"},
    {prodId:"31171529",label:"Sombreretes de rodamiento"},
    {prodId:"31171530",label:"Rodamiento de seguimiento de levas"},
    {prodId:"31171531",label:"Rodamiento de contacto angular"},
    {prodId:"31171532",label:"Rodamiento auto - alineante"},
    {prodId:"31171533",label:"Rodamiento y marco ajustables"},
    {prodId:"31171534",label:"Rodamiento de base cónica"},
    {prodId:"31171535",label:"Rodamiento de cartucho"},
    {prodId:"31171536",label:"Rodamiento de insertar"},
    {prodId:"31171537",label:"Rodamiento de combinación"},
    {prodId:"31171538",label:"Rodamiento de cuatro puntos"},
    {prodId:"31171539",label:"Balinera"},
    {prodId:"31171540",label:"Aguja de rodamiento"},
    {prodId:"31171541",label:"Rodillo de rodamiento"},
    {prodId:"31171542",label:"Rodamiento tensionador"},
    {prodId:"31171543",label:"Rodamiento de flexión"},
    {prodId:"31171544",label:"Bloque de rodamiento plummer"},
    {prodId:"31171545",label:"Cubierta de rodamiento"},
    {prodId:"31171546",label:"Rodamiento reversible"},
    {prodId:"31171547",label:"Rodamiento cilíndrico"},
    {prodId:"31171548",label:"Manguito de retiro de rodamiento"},
    {prodId:"31171549",label:"Rodamiento de joyería"},
    {prodId:"31171550",label:"Rodamiento combinado de rodillo y balinera"},
    {prodId:"31171551",label:"Rodamiento sin aceite"},
    {prodId:"31171552",label:"Rodamiento de balinera de empuje"},
    {prodId:"31171553",label:"Rodamiento de rodillo de empuje"},
    {prodId:"31171554",label:"Rodamiento guía"},
    {prodId:"31171555",label:"Ensamble de adaptador de rodamiento de rodillos"},
    {prodId:"31171556",label:"Rodamiento inserto de alto desempeño"},
    {prodId:"31171557",label:"Buje de chumacera"},
    {prodId:"31171558",label:"Rodillo de movimiento lineal y giratorio"},
    {prodId:"31171559",label:"Placa de bloqueo para rodamiento de rodillo"},
    {prodId:"31171560",label:"Anillo de presión de bloqueo para rodamiento de rodillo"},
    {prodId:"31171561",label:"Manguito adaptador para rodamiento de rodillo"},
    {prodId:"31171562",label:"Rodamiento inserto con bloqueo de tornillo"},
    {prodId:"31171563",label:"Rodamiento de insertar con collar de bloqueo excéntrico"},
    {prodId:"31171564",label:"Rodamiento de insertar con anillo interior estándar"},
    {prodId:"31171600",label:"Cojinetes"},
    {prodId:"31171603",label:"Bujes de taladro"},
    {prodId:"31171604",label:"Bujes pilotos"},
    {prodId:"31171605",label:"Bujes de eje"},
    {prodId:"31171606",label:"Bujes de brida"},
    {prodId:"31171607",label:"Buje de desgaste"},
    {prodId:"31171608",label:"Buje en blanco"},
    {prodId:"31171609",label:"Manga de buje"},
    {prodId:"31171610",label:"Buje forjado en frío"},
    {prodId:"31171700",label:"Engranajes"},
    {prodId:"31171704",label:"Engranajes de fricción"},
    {prodId:"31171706",label:"Engranajes cónicos"},
    {prodId:"31171707",label:"Engranajes rectos"},
    {prodId:"31171708",label:"Engranajes biselados"},
    {prodId:"31171709",label:"Engranajes cremallera"},
    {prodId:"31171710",label:"Engranajes piñón"},
    {prodId:"31171711",label:"Engranajes de anillo"},
    {prodId:"31171712",label:"Engranajes de tornillo sin fin"},
    {prodId:"31171713",label:"Engranajes laterales"},
    {prodId:"31171714",label:"Engranajes helicoidales"},
    {prodId:"31171715",label:"Engranajes cilíndricos"},
    {prodId:"31171716",label:"Engranajes en espina de pescado"},
    {prodId:"31171800",label:"Ruedas industriales"},
    {prodId:"31171801",label:"Ruedas dentadas"},
    {prodId:"31171802",label:"Ruedas motrices"},
    {prodId:"31171803",label:"Rueda voladora"},
    {prodId:"31171804",label:"Poleas"},
    {prodId:"31171805",label:"Cepillos de rueda"},
    {prodId:"31171806",label:"Ruedas de rodillos"},
    {prodId:"31171900",label:"Ruedas dentadas"},
    {prodId:"31171901",label:"Ruedas dentadas para cadena de rodillos"},
    {prodId:"31171902",label:"Ruedas dentadas para cadena de ingeniería"},
    {prodId:"31171903",label:"Ruedas dentadas para cadena de transporte"},
    {prodId:"31171904",label:"Ruedas dentadas para cadena silenciosa"},
    {prodId:"31171905",label:"Rueda dentada o polea de cadena"},
    {prodId:"31181700",label:"Embalajes y empaquetaduras"},
    {prodId:"31181701",label:"Empaques"},
    {prodId:"31181702",label:"Prensaestopas"},
    {prodId:"31181703",label:"Deflectores de aceite"},
    {prodId:"31181704",label:"Caja de relleno"},
    {prodId:"31181705",label:"Anillo de sello de soporte"},
    {prodId:"31181800",label:"Cubiertas automotrices"},
    {prodId:"31181801",label:"Manija de palanca de cambios de transmisión automotriz"},
    {prodId:"31181802",label:"Cubierta de bujía"},
    {prodId:"31191500",label:"Abrasivos y medios de abrasivo"},
    {prodId:"31191501",label:"Papeles abrasivos"},
    {prodId:"31191502",label:"Pulidor"},
    {prodId:"31191504",label:"Telas abrasivas"},
    {prodId:"31191505",label:"Almohadillas abrasivas"},
    {prodId:"31191506",label:"Discos abrasivos"},
    {prodId:"31191507",label:"Cintas abrasivas"},
    {prodId:"31191508",label:"Polvo de diamante"},
    {prodId:"31191509",label:"Pulidores abrasivos"},
    {prodId:"31191510",label:"Piedras abrasivas"},
    {prodId:"31191511",label:"Virutas de acero"},
    {prodId:"31191512",label:"Chorro de balines o perdigones"},
    {prodId:"31191513",label:"Cuentas de vidrio"},
    {prodId:"31191514",label:"Medio amortiguador"},
    {prodId:"31191515",label:"Malla abrasiva"},
    {prodId:"31191516",label:"Rollos de cartucho abrasivo"},
    {prodId:"31191517",label:"Planchas de esmeril"},
    {prodId:"31191518",label:"Carburo de tungsteno"},
    {prodId:"31191519",label:"Tambores abrasivos"},
    {prodId:"31191520",label:"Abrasivo estrella"},
    {prodId:"31191521",label:"Cepillo abrasivo"},
    {prodId:"31191600",label:"Ruedas abrasivas"},
    {prodId:"31191601",label:"Ruedas abrasivas cúbicas de nitrato borozon"},
    {prodId:"31191602",label:"Ruedas abrasivas de diamante"},
    {prodId:"31201500",label:"Cinta adhesiva"},
    {prodId:"31201501",label:"Cinta de ductos"},
    {prodId:"31201502",label:"Cinta aislante eléctrica"},
    {prodId:"31201503",label:"Cinta de enmascarar"},
    {prodId:"31201504",label:"Cinta para alfombras"},
    {prodId:"31201505",label:"Cinta doble faz"},
    {prodId:"31201506",label:"Cinta de bísmalemida"},
    {prodId:"31201507",label:"Cinta de fibra de vidrio"},
    {prodId:"31201508",label:"Cinta de grafito"},
    {prodId:"31201509",label:"Cinta de nylon"},
    {prodId:"31201510",label:"Cinta impregnada de resina"},
    {prodId:"31201511",label:"Cinta de malla metálica"},
    {prodId:"31201512",label:"Cinta transparente"},
    {prodId:"31201513",label:"Cintas antideslizantes de seguridad"},
    {prodId:"31201514",label:"Cinta de sellado de hilo de poli tetrafluoretileno (ptfe)"},
    {prodId:"31201515",label:"Cintas de papel"},
    {prodId:"31201516",label:"Cinta reflectiva"},
    {prodId:"31201517",label:"Cinta para empaquetar"},
    {prodId:"31201518",label:"Cinta conductora de electricidad"},
    {prodId:"31201519",label:"Cinta para reparar tubería o manguera"},
    {prodId:"31201520",label:"Cinta para marcar los pasillos"},
    {prodId:"31201521",label:"Cinta metálica"},
    {prodId:"31201522",label:"Cinta de transferencia adhesiva"},
    {prodId:"31201523",label:"Cinta de tela"},
    {prodId:"31201524",label:"Cinta para codificación de color"},
    {prodId:"31201525",label:"Cinta de vinilo"},
    {prodId:"31201526",label:"Cinta magnética"},
    {prodId:"31201527",label:"Cintas de espuma"},
    {prodId:"31201528",label:"Cinta de montaje"},
    {prodId:"31201529",label:"Cinta de perfilar"},
    {prodId:"31201530",label:"Cinta de papel de aluminio"},
    {prodId:"31201531",label:"Cinta de polietileno"},
    {prodId:"31201532",label:"Cinta de cloruro de polivinilo pvc"},
    {prodId:"31201533",label:"Cinta de hoja de plomo"},
    {prodId:"31201534",label:"Cinta de caucho"},
    {prodId:"31201535",label:"Cinta para empalmes de cables"},
    {prodId:"31201536",label:"Cinta de lanilla de polietileno"},
    {prodId:"31201537",label:"Cinta de arrancar"},
    {prodId:"31201600",label:"Otros adhesivos y selladores"},
    {prodId:"31201601",label:"Adhesivos químicos"},
    {prodId:"31201602",label:"Pastas"},
    {prodId:"31201603",label:"Gomas"},
    {prodId:"31201604",label:"Cementos de caucho"},
    {prodId:"31201605",label:"Masillas"},
    {prodId:"31201606",label:"Calafateos"},
    {prodId:"31201607",label:"Aglomerante epoxy"},
    {prodId:"31201608",label:"Adhesivos de espuma"},
    {prodId:"31201609",label:"Adhesivos de termo impregnación"},
    {prodId:"31201610",label:"Pegamentos"},
    {prodId:"31201611",label:"Adhesivos de lámina"},
    {prodId:"31201612",label:"Selladores de rosca"},
    {prodId:"31201613",label:"Adhesivo reusable"},
    {prodId:"31201614",label:"Lacre"},
    {prodId:"31201615",label:"Activadores del adhesivo"},
    {prodId:"31201616",label:"Adhesivos líquidos"},
    {prodId:"31201617",label:"Cementos disolventes"},
    {prodId:"31201618",label:"Compuesto de unión de drywall"},
    {prodId:"31201619",label:"Adhesivo instantáneo"},
    {prodId:"31201620",label:"Adhesivo reactivo"},
    {prodId:"31201621",label:"Adhesivo en aerosol"},
    {prodId:"31201622",label:"Adhesivo multi - propósito"},
    {prodId:"31201623",label:"Adhesivo de contacto"},
    {prodId:"31201624",label:"Adhesivo de montura"},
    {prodId:"31201625",label:"Base de masilla"},
    {prodId:"31201626",label:"Adhesivo sensible a la presión psa"},
    {prodId:"31201627",label:"Adhesivo anaeróbico"},
    {prodId:"31201628",label:"Sellante de impregnación"},
    {prodId:"31201629",label:"Encapsulación de silicona"},
    {prodId:"31201630",label:"Sellante de espuma"},
    {prodId:"31201631",label:"Compuesto sellante"},
    {prodId:"31201632",label:"Adhesivo de silicona"},
    {prodId:"31201633",label:"Adhesivo de fluorocarbono"},
    {prodId:"31201634",label:"Adhesivo acrílico anaeróbico"},
    {prodId:"31201635",label:"Adhesivo de poliuretano"},
    {prodId:"31201636",label:"Adhesivo de cianoacrilato"},
    {prodId:"31201637",label:"Material de comunicación tipo espuma para detener el agua"},
    {prodId:"31211500",label:"Pinturas y tapa poros"},
    {prodId:"31211501",label:"Pinturas de esmalte"},
    {prodId:"31211502",label:"Pinturas de agua"},
    {prodId:"31211503",label:"Pinturas basadas en pigmentos"},
    {prodId:"31211504",label:"Pinturas de revestimiento"},
    {prodId:"31211505",label:"Pinturas de aceite"},
    {prodId:"31211506",label:"Pinturas de látex"},
    {prodId:"31211507",label:"Pinturas en aerosol"},
    {prodId:"31211508",label:"Pinturas acrílicas"},
    {prodId:"31211509",label:"Bases para esmalte"},
    {prodId:"31211510",label:"Bases de poliuretano"},
    {prodId:"31211511",label:"Bases de uretano"},
    {prodId:"31211512",label:"Bases de látex"},
    {prodId:"31211513",label:"Pintura de marcado invertido"},
    {prodId:"31211514",label:"Compuesto de galvanización en frío"},
    {prodId:"31211515",label:"Pintura basada en disolvente"},
    {prodId:"31211516",label:"Pintura de terminado al horno"},
    {prodId:"31211517",label:"Pintura de terminado patrón"},
    {prodId:"31211518",label:"Pintura antióxido"},
    {prodId:"31211519",label:"Pintura de aluminio"},
    {prodId:"31211520",label:"Pintura anti - incrustante"},
    {prodId:"31211521",label:"Pintura premezclada"},
    {prodId:"31211522",label:"Pintura de trabajo pesado"},
    {prodId:"31211600",label:"Aditivos para pinturas"},
    {prodId:"31211601",label:"Lechada de cal con cola para blanquear paredes"},
    {prodId:"31211602",label:"Lechada de cal con cola para blanquear paredes"},
    {prodId:"31211603",label:"Secantes de pintura"},
    {prodId:"31211604",label:"Diluyentes para pinturas"},
    {prodId:"31211605",label:"Agentes antideslizantes"},
    {prodId:"31211606",label:"Agentes niveladores"},
    {prodId:"31211607",label:"Inhibidor ultravioleta"},
    {prodId:"31211700",label:"Acabados en general"},
    {prodId:"31211701",label:"Vitrificados"},
    {prodId:"31211702",label:"Lustres"},
    {prodId:"31211703",label:"Lacas"},
    {prodId:"31211704",label:"Sellantes"},
    {prodId:"31211705",label:"Barniz de laca"},
    {prodId:"31211706",label:"Tinturas"},
    {prodId:"31211707",label:"Barnices"},
    {prodId:"31211708",label:"Recubrimiento de polvo"},
    {prodId:"31211709",label:"Tinturas de terminación"},
    {prodId:"31211800",label:"Disolventes y diluyentes para pinturas"},
    {prodId:"31211801",label:"Removedores de pintura o barniz"},
    {prodId:"31211802",label:"Compuestos para arrancar pintura o barniz"},
    {prodId:"31211803",label:"Diluyentes para pinturas y barnices"},
    {prodId:"31211900",label:"Aplicadores de pintura y accesorios para pintar"},
    {prodId:"31211901",label:"Paños para herramientas"},
    {prodId:"31211902",label:"Herramientas para bordes"},
    {prodId:"31211903",label:"Equipo para protección"},
    {prodId:"31211904",label:"Brochas"},
    {prodId:"31211905",label:"Mezcladores de pintura"},
    {prodId:"31211906",label:"Rodillos de pintar"},
    {prodId:"31211908",label:"Pistolas de pintar"},
    {prodId:"31211909",label:"Bandejas de pintura"},
    {prodId:"31211910",label:"Guantes para pintar"},
    {prodId:"31211912",label:"Varillas telescópicas"},
    {prodId:"31211913",label:"Boquillas de pintura"},
    {prodId:"31211914",label:"Cepillos de aire"},
    {prodId:"31211915",label:"Coladores de pintura"},
    {prodId:"31211916",label:"Forradores de bandeja de pintura"},
    {prodId:"31211917",label:"Cubiertas para rodillos de pintura"},
    {prodId:"31211918",label:"Accesorio o máscara de pintura"},
    {prodId:"31221600",label:"Productos para curtidos"},
    {prodId:"31221601",label:"Extractos inorgánicos para curtiembre"},
    {prodId:"31221602",label:"Extractos orgánicos de origen animal para curtiembre"},
    {prodId:"31221603",label:"Extractos orgánicos de origen vegetal para curtiembre"},
    {prodId:"31231100",label:"Material en barra labrada"},
    {prodId:"31231101",label:"Aluminio en barra labrada"},
    {prodId:"31231102",label:"Berilio en barra labrada"},
    {prodId:"31231103",label:"Latón en barra labrada"},
    {prodId:"31231104",label:"Bronce en barra labrada"},
    {prodId:"31231105",label:"Cobre en barra labrada"},
    {prodId:"31231106",label:"Hierro en barra labrada"},
    {prodId:"31231107",label:"Plomo en barra labrada"},
    {prodId:"31231108",label:"Magnesio en barra labrada"},
    {prodId:"31231109",label:"Metal precioso en barra labrada"},
    {prodId:"31231110",label:"Acero inoxidable en barra labrada"},
    {prodId:"31231111",label:"Estaño en barra labrada"},
    {prodId:"31231112",label:"Titanio en barra labrada"},
    {prodId:"31231113",label:"Cinc en barra labrada"},
    {prodId:"31231114",label:"Aleación no ferrosa en barra labrada"},
    {prodId:"31231115",label:"Aleación ferrosa en barra labrada"},
    {prodId:"31231116",label:"Acero en barra labrada"},
    {prodId:"31231117",label:"Compuestos en barra labrada"},
    {prodId:"31231118",label:"Aleación de níquel en barra labrada"},
    {prodId:"31231119",label:"Material no metálico en barra labrada"},
    {prodId:"31231120",label:"Acero plata en barra labrada"},
    {prodId:"31231200",label:"Material en placas labrado"},
    {prodId:"31231201",label:"Aluminio en placa labrada"},
    {prodId:"31231202",label:"Berilio en placa labrada"},
    {prodId:"31231203",label:"Latón en placa labrada"},
    {prodId:"31231204",label:"Bronce en placa labrada"},
    {prodId:"31231205",label:"Cobre en placa labrada"},
    {prodId:"31231206",label:"Hierro en placa labrada"},
    {prodId:"31231207",label:"Plomo en placa labrada"},
    {prodId:"31231208",label:"Magnesio en placa labrada"},
    {prodId:"31231209",label:"Metal precioso en placa labrada"},
    {prodId:"31231210",label:"Acero inoxidable en placa labrada"},
    {prodId:"31231211",label:"Estaño en placa labrada"},
    {prodId:"31231212",label:"Titanio en placa labrada"},
    {prodId:"31231213",label:"Cinc en placa labrada"},
    {prodId:"31231214",label:"Aleación no ferrosa en placa labrada"},
    {prodId:"31231215",label:"Aleación ferrosa en placa labrada"},
    {prodId:"31231216",label:"Acero en placa labrada"},
    {prodId:"31231217",label:"Compuestos en placa labrada"},
    {prodId:"31231218",label:"Aleación de níquel en placa labrada"},
    {prodId:"31231219",label:"Material no metálico en placa labrada"},
    {prodId:"31231400",label:"Suplementos"},
    {prodId:"31231401",label:"Platina de latón"},
    {prodId:"31231402",label:"Platina de acero"},
    {prodId:"31231403",label:"Platina de acero inoxidable"},
    {prodId:"31231404",label:"Platina de aluminio"},
    {prodId:"31231405",label:"Platina de cobre"},
    {prodId:"31241500",label:"Lentes y prismas"},
    {prodId:"31241501",label:"Lentes"},
    {prodId:"31241502",label:"Prismas"},
    {prodId:"31241600",label:"Cristales ópticos"},
    {prodId:"31241601",label:"Cristales de filtro"},
    {prodId:"31241602",label:"Discos de cristal"},
    {prodId:"31241603",label:"Vidrio moldeado"},
    {prodId:"31241604",label:"Cristales de prismas"},
    {prodId:"31241605",label:"Cristales de silicio"},
    {prodId:"31241606",label:"Cristales de germanio"},
    {prodId:"31241607",label:"Barras redondas"},
    {prodId:"31241608",label:"Barras cuadradas"},
    {prodId:"31241609",label:"Cristales indicadores de muestra"},
    {prodId:"31241610",label:"Blancos de material óptico infrarrojo"},
    {prodId:"31241700",label:"Espejos"},
    {prodId:"31241701",label:"Espejos torneados con herramienta de diamante"},
    {prodId:"31241702",label:"Espejos metálicos"},
    {prodId:"31241703",label:"Espejos parabólicos"},
    {prodId:"31241704",label:"Espejos sin revestimiento"},
    {prodId:"31241705",label:"Espejos para láser"},
    {prodId:"31241706",label:"Espejos planos"},
    {prodId:"31241800",label:"Filtros ópticos"},
    {prodId:"31241801",label:"Filtros ópticos de banda ancha"},
    {prodId:"31241802",label:"Filtros de gradiente"},
    {prodId:"31241803",label:"Filtros infrarrojos"},
    {prodId:"31241804",label:"Filtros de láser"},
    {prodId:"31241805",label:"Filtros de banda estrecha"},
    {prodId:"31241806",label:"Filtros de película de plástico"},
    {prodId:"31241807",label:"Filtros visuales"},
    {prodId:"31241808",label:"Filtros dicróicos"},
    {prodId:"31241809",label:"Filtros de lyot"},
    {prodId:"31241810",label:"Filtros de gelatina"},
    {prodId:"31241811",label:"Filtros de color"},
    {prodId:"31241812",label:"Filtros polarizantes"},
    {prodId:"31241813",label:"Filtros de reflexión"},
    {prodId:"31241814",label:"Filtros de succión"},
    {prodId:"31241815",label:"Filtros de interferencia"},
    {prodId:"31242100",label:"Accesorios de dispositivos ópticos"},
    {prodId:"31242101",label:"Monturas ópticas"},
    {prodId:"31242103",label:"Aberturas o ranuras ópticas"},
    {prodId:"31242104",label:"Soportes o carriles ópticos"},
    {prodId:"31242105",label:"Identificadores de fibra óptica"},
    {prodId:"31242106",label:"Recubrimientos ópticos"},
    {prodId:"31242107",label:"Tablero de pruebas ópticas"},
    {prodId:"31242200",label:"Componentes ópticos variados"},
    {prodId:"31242201",label:"Divisores del haz óptico"},
    {prodId:"31242202",label:"Polarizadores"},
    {prodId:"31242203",label:"Despolarizadores"},
    {prodId:"31242204",label:"Difusores ópticos"},
    {prodId:"31242205",label:"Retardadores ópticos"},
    {prodId:"31242206",label:"Vidrios ópticamente planos"},
    {prodId:"31242207",label:"Montajes ópticos experimentales"},
    {prodId:"31242208",label:"Picadores ópticos"},
    {prodId:"31251500",label:"Activadores"},
    {prodId:"31251501",label:"Activadores eléctricos"},
    {prodId:"31251502",label:"Activadores electrónicos"},
    {prodId:"31251503",label:"Activadores hidráulicos"},
    {prodId:"31251504",label:"Activadores neumáticos"},
    {prodId:"31251505",label:"Servoválvula"},
    {prodId:"31251506",label:"Activadores por engranajes"},
    {prodId:"31251507",label:"Activadores giratorios"},
    {prodId:"31251508",label:"Activadores fotoeléctricos"},
    {prodId:"31251509",label:"Activadores electromagnéticos"},
    {prodId:"31251510",label:"Solenoides"},
    {prodId:"31251511",label:"Activadores lineales"},
    {prodId:"31251512",label:"Activadores automotrices hvac"},
    {prodId:"31251513",label:"Activadores  de compuerta"},
    {prodId:"31251514",label:"Activador electromecánico de aeronaves"},
    {prodId:"31251600",label:"Componentes de robot"},
    {prodId:"31251601",label:"Efectores finales robóticos"},
    {prodId:"31261500",label:"Cubiertas y cajas"},
    {prodId:"31261501",label:"Cubiertas y carcasas de plástico"},
    {prodId:"31261502",label:"Cubiertas y carcasas de metal"},
    {prodId:"31261503",label:"Cubiertas y carcasas de acero"},
    {prodId:"31261504",label:"Carcasas de cajas de cambios"},
    {prodId:"31261505",label:"Carcasas de embrague"},
    {prodId:"31261600",label:"Cascos y envolturas"},
    {prodId:"31261601",label:"Envoltorios o recubrimientos de plástico"},
    {prodId:"31261602",label:"Envoltorios o recubrimientos de metal"},
    {prodId:"31261603",label:"Envoltorios o recubrimientos de acero"},
    {prodId:"31261700",label:"Cerramientos o alojamientos insonorizantes"},
    {prodId:"31261701",label:"Carcasa  insonorizante de máquina"},
    {prodId:"31261702",label:"Cerramiento insonorizante de conjunto de generador montado"},
    {prodId:"31261703",label:"Carcasa insonorizante de bomba"},
    {prodId:"31261704",label:"Carcasa insonorizante de admisión de aire"},
    {prodId:"31271600",label:"Piezas hechas con torno de roscar"},
    {prodId:"31271601",label:"Piezas hechas en torno de roscar de metal"},
    {prodId:"31271602",label:"Piezas hechas en torno de roscar no metales"},
    {prodId:"31281500",label:"Componentes estampados"},
    {prodId:"31281502",label:"Componentes de aluminio estampados"},
    {prodId:"31281503",label:"Componentes de aleaciones ferrosas estampados"},
    {prodId:"31281504",label:"Componentes de hierro estampados"},
    {prodId:"31281505",label:"Componentes de aleaciones no ferrosas estampados"},
    {prodId:"31281506",label:"Componentes de acero inoxidable estampados"},
    {prodId:"31281507",label:"Componentes de acero al carbono estampados"},
    {prodId:"31281508",label:"Componentes de magnesio estampados"},
    {prodId:"31281509",label:"Componentes de zinc estampados"},
    {prodId:"31281510",label:"Componentes de estaño estampados"},
    {prodId:"31281511",label:"Componentes de titanio estampados"},
    {prodId:"31281512",label:"Componentes de berilio estampados"},
    {prodId:"31281513",label:"Componentes de metal precioso estampados"},
    {prodId:"31281514",label:"Componentes de cobre estampados"},
    {prodId:"31281515",label:"Componentes de plomo estampados"},
    {prodId:"31281516",label:"Componentes de latón estampados"},
    {prodId:"31281517",label:"Componentes de bronce estampados"},
    {prodId:"31281518",label:"Componentes compuestos estampados"},
    {prodId:"31281519",label:"Componentes de aleación de níquel estampados"},
    {prodId:"31281520",label:"Componentes no metálicos estampados"},
    {prodId:"31281521",label:"Estampados recubiertos"},
    {prodId:"31281522",label:"Componente de berilio cobre estampado"},
    {prodId:"31281523",label:"Componente estampado de cobre latón plateado"},
    {prodId:"31281524",label:"Componente estampado de aluminio sae serie 1000"},
    {prodId:"31281525",label:"Componente estampado de aluminio sae serie 3000"},
    {prodId:"31281526",label:"Componente estampado de aluminio sae serie 5000"},
    {prodId:"31281527",label:"Componente estampado de aluminio sae serie 6000"},
    {prodId:"31281528",label:"Componente estampado de inconel"},
    {prodId:"31281529",label:"Componente estampado de acero al carbón sae serie 1000 enrollado en frío recubierto de estaño"},
    {prodId:"31281530",label:"Componente estampado de acero al carbón sae serie 1000 enrollado en caliente"},
    {prodId:"31281531",label:"Componente estampado de aleación de aleación de acero enrollada en caliente"},
    {prodId:"31281532",label:"Componente estampado de acero al carbón sae serie 1000 enrollado en frío"},
    {prodId:"31281533",label:"Componente estampado de acero inoxidable sae serie 200 enrollado en frío"},
    {prodId:"31281534",label:"Componente estampado de acero inoxidable sae serie 300 enrollado en frío"},
    {prodId:"31281535",label:"Componente estampado de acero inoxidable sae serie 400 enrollado en frío"},
    {prodId:"31281536",label:"Componente estampado de aleación de aleación de acero enrollada en frío"},
    {prodId:"31281537",label:"Componente estampado de acero al carbón sae serie 1000 enrollado en frío galvanizado en caliente por inmersión"},
    {prodId:"31281538",label:"Componente estampado de acero al carbón sae serie 1000 enrollado en frío electro  galvanizado"},
    {prodId:"31281700",label:"Componentes soldados"},
    {prodId:"31281701",label:"Componentes de metal soldado"},
    {prodId:"31281800",label:"Componentes perforados"},
    {prodId:"31281801",label:"Componentes de aluminio perforados"},
    {prodId:"31281802",label:"Componentes de aleación ferrosa perforados"},
    {prodId:"31281803",label:"Componentes de hierro perforados"},
    {prodId:"31281804",label:"Componentes de aleaciones no ferrosas perforados"},
    {prodId:"31281805",label:"Componentes de acero inoxidable perforados"},
    {prodId:"31281806",label:"Componentes de acero al carbono perforados"},
    {prodId:"31281807",label:"Componentes compuestos perforados"},
    {prodId:"31281808",label:"Componentes de aleación de níquel perforados"},
    {prodId:"31281809",label:"Componentes no metálicos perforados"},
    {prodId:"31281810",label:"Componentes de titanio perforados"},
    {prodId:"31281811",label:"Componentes de berilio perforados"},
    {prodId:"31281812",label:"Componentes de metal precioso perforados"},
    {prodId:"31281813",label:"Componentes de cobre perforados"},
    {prodId:"31281814",label:"Componentes de plomo perforados"},
    {prodId:"31281815",label:"Componentes de latón perforados"},
    {prodId:"31281816",label:"Componentes de bronce perforados"},
    {prodId:"31281817",label:"Componentes de magnesio perforados"},
    {prodId:"31281818",label:"Componentes de zinc perforados"},
    {prodId:"31281819",label:"Componentes de estaño perforados"},
    {prodId:"31282000",label:"Componentes hidro formados"},
    {prodId:"31282001",label:"Componentes de aluminio hidroformados"},
    {prodId:"31282002",label:"Componentes de berilio hidroformados"},
    {prodId:"31282003",label:"Componentes de latón hidroformados"},
    {prodId:"31282004",label:"Componentes de bronce hidroformados"},
    {prodId:"31282005",label:"Componentes compuestos hidroformados"},
    {prodId:"31282006",label:"Componentes de cobre hidroformados"},
    {prodId:"31282007",label:"Componentes de aleación ferrosa hidroformados"},
    {prodId:"31282008",label:"Componentes de hierro hidroformados"},
    {prodId:"31282009",label:"Componentes de plomo hidroformados"},
    {prodId:"31282010",label:"Componentes de magnesio hidroformados"},
    {prodId:"31282011",label:"Componentes de aleación de níquel hidroformados"},
    {prodId:"31282012",label:"Componentes de aleación no ferrosa hidroformados"},
    {prodId:"31282013",label:"Componentes no metálicos hidroformados"},
    {prodId:"31282014",label:"Componentes de metal precioso hidroformados"},
    {prodId:"31282015",label:"Componentes de acero inoxidable hidroformados"},
    {prodId:"31282016",label:"Componentes de acero hidroformados"},
    {prodId:"31282017",label:"Componentes de estaño hidroformados"},
    {prodId:"31282018",label:"Componentes de titanio hidroformados"},
    {prodId:"31282019",label:"Componentes de zinc hidroformados"},
    {prodId:"31282100",label:"Componentes formados con rotación"},
    {prodId:"31282101",label:"Componentes de aluminio formados en torno"},
    {prodId:"31282102",label:"Componentes de berilio formados en torno"},
    {prodId:"31282103",label:"Componentes de latón formados en torno"},
    {prodId:"31282104",label:"Componentes de bronce formados en torno"},
    {prodId:"31282105",label:"Componentes compuestos formados en torno"},
    {prodId:"31282106",label:"Componentes de cobre formados en torno"},
    {prodId:"31282107",label:"Componentes de aleación ferrosa formados en torno"},
    {prodId:"31282108",label:"Componentes de hierro formados en torno"},
    {prodId:"31282109",label:"Componentes de plomo formados en torno"},
    {prodId:"31282110",label:"Componentes de magnesio formados en torno"},
    {prodId:"31282111",label:"Componentes de aleación de níquel formados en torno"},
    {prodId:"31282112",label:"Componentes de aleación no ferrosa formados en torno"},
    {prodId:"31282113",label:"Componentes no metálicos formados en torno"},
    {prodId:"31282114",label:"Componentes de metal precioso formados en torno"},
    {prodId:"31282115",label:"Componentes de acero inoxidable formados en torno"},
    {prodId:"31282116",label:"Componentes de acero formados en torno"},
    {prodId:"31282117",label:"Componentes de estaño formados en torno"},
    {prodId:"31282118",label:"Componentes de titanio formados en torno"},
    {prodId:"31282119",label:"Componentes de zinc formados en torno"},
    {prodId:"31282200",label:"Componentes formados con rodillo"},
    {prodId:"31282201",label:"Componentes de aluminio formados enrollados"},
    {prodId:"31282202",label:"Componentes de berilio formados enrollados"},
    {prodId:"31282203",label:"Componentes de latón formados enrollados"},
    {prodId:"31282204",label:"Componentes de bronce formados enrollados"},
    {prodId:"31282205",label:"Componentes compuestos formados enrollados"},
    {prodId:"31282206",label:"Componentes de cobre formados enrollados"},
    {prodId:"31282207",label:"Componentes de aleación ferrosa formados enrollados"},
    {prodId:"31282208",label:"Componentes de hierro formados enrollados"},
    {prodId:"31282209",label:"Componentes de plomo formados enrollados"},
    {prodId:"31282210",label:"Componentes de magnesio formados enrollados"},
    {prodId:"31282211",label:"Componentes de aleación de níquel formados enrollados"},
    {prodId:"31282212",label:"Componentes de aleación no ferrosa formados enrollados"},
    {prodId:"31282213",label:"Componentes no metálicos formados enrollados"},
    {prodId:"31282214",label:"Componentes de metal precioso formados enrollados"},
    {prodId:"31282215",label:"Componentes de acero inoxidable formados enrollados"},
    {prodId:"31282216",label:"Componentes de acero formados enrollados"},
    {prodId:"31282217",label:"Componentes de estaño formados enrollados"},
    {prodId:"31282218",label:"Componentes de titanio formados enrollados"},
    {prodId:"31282219",label:"Componentes de zinc formados enrollados"},
    {prodId:"31282300",label:"Componentes formados por estiramiento por presión"},
    {prodId:"31282301",label:"Componentes de aluminio formados por estiramiento por presión"},
    {prodId:"31282302",label:"Componentes de berilio formados por estiramiento por presión"},
    {prodId:"31282303",label:"Componentes de latón formados por estiramiento por presión"},
    {prodId:"31282304",label:"Componentes de bronce formados por estiramiento por presión"},
    {prodId:"31282305",label:"Componentes compuestos formados por estiramiento por presión"},
    {prodId:"31282306",label:"Componentes de cobre formados por estiramiento por presión"},
    {prodId:"31282307",label:"Componentes de aleación ferrosa formados por estiramiento por presión"},
    {prodId:"31282308",label:"Componentes de hierro formados por estiramiento por presión"},
    {prodId:"31282309",label:"Componentes de plomo formados por estiramiento por presión"},
    {prodId:"31282310",label:"Componentes de magnesio formados por estiramiento por presión"},
    {prodId:"31282311",label:"Componentes de aleación de níquel formados por estiramiento por presión"},
    {prodId:"31282312",label:"Componentes de aleación no ferrosa formados por estiramiento por presión"},
    {prodId:"31282313",label:"Componentes no metálicos formados por estiramiento por presión"},
    {prodId:"31282314",label:"Componentes de metal precioso formados por estiramiento por presión"},
    {prodId:"31282315",label:"Componentes de acero inoxidable formados por estiramiento por presión"},
    {prodId:"31282316",label:"Componentes de acero formados por estiramiento por presión"},
    {prodId:"31282317",label:"Componentes de estaño formados por estiramiento por presión"},
    {prodId:"31282318",label:"Componentes de titanio formados por estiramiento por presión"},
    {prodId:"31282319",label:"Componentes de zinc formados por estiramiento por presión"},
    {prodId:"31291200",label:"Estiramientos por presión de impacto labrados"},
    {prodId:"31291201",label:"Componentes de aluminio maquinados por extrusión de impacto"},
    {prodId:"31291202",label:"Componentes de berilio maquinados por extrusión de impacto"},
    {prodId:"31291203",label:"Componentes de latón maquinados por extrusión de impacto"},
    {prodId:"31291204",label:"Componentes de bronce maquinados por extrusión de impacto"},
    {prodId:"31291205",label:"Componentes de cobre maquinados por extrusión de impacto"},
    {prodId:"31291206",label:"Componentes de aleación ferrosa maquinados por extrusión de impacto"},
    {prodId:"31291207",label:"Componentes de plomo maquinados por extrusión de impacto"},
    {prodId:"31291208",label:"Componentes de magnesio maquinados por extrusión de impacto"},
    {prodId:"31291209",label:"Componentes de aleación no ferrosa maquinados por extrusión de impacto"},
    {prodId:"31291210",label:"Componentes plásticos maquinados por extrusión de impacto"},
    {prodId:"31291211",label:"Componentes de metal precioso maquinados por extrusión de impacto"},
    {prodId:"31291212",label:"Componentes de caucho maquinados por extrusión de impacto"},
    {prodId:"31291213",label:"Componentes de acero inoxidable maquinados por extrusión de impacto"},
    {prodId:"31291214",label:"Componentes de acero maquinados por extrusión de impacto"},
    {prodId:"31291215",label:"Componentes de estaño maquinados por extrusión de impacto"},
    {prodId:"31291216",label:"Componentes de titanio maquinados por extrusión de impacto"},
    {prodId:"31291217",label:"Componentes de zinc maquinados por extrusión de impacto"},
    {prodId:"31291218",label:"Componentes compuestos maquinados por extrusión de impacto"},
    {prodId:"31291219",label:"Componentes de aleación de níquel maquinados por extrusión de impacto"},
    {prodId:"31291220",label:"Componentes no metálicos maquinados por extrusión de impacto"},
    {prodId:"31291400",label:"Estiramientos por presión en caliente labrados"},
    {prodId:"31291401",label:"Componentes de aluminio maquinados por extrusión en caliente"},
    {prodId:"31291402",label:"Componentes de berilio maquinados por extrusión en caliente"},
    {prodId:"31291403",label:"Componentes de latón maquinados por extrusión en caliente"},
    {prodId:"31291404",label:"Componentes de bronce maquinados por extrusión en caliente"},
    {prodId:"31291405",label:"Componentes de cobre maquinados por extrusión en caliente"},
    {prodId:"31291406",label:"Componentes de aleación ferrosa maquinados por extrusión en caliente"},
    {prodId:"31291407",label:"Componentes de plomo maquinados por extrusión en caliente"},
    {prodId:"31291408",label:"Componentes de magnesio maquinados por extrusión en caliente"},
    {prodId:"31291409",label:"Componentes de aleación no ferrosa maquinados por extrusión en caliente"},
    {prodId:"31291410",label:"Componentes plásticos maquinados por extrusión en caliente"},
    {prodId:"31291411",label:"Componentes de metal precioso maquinados por extrusión en caliente"},
    {prodId:"31291412",label:"Componentes de caucho maquinados por extrusión en caliente"},
    {prodId:"31291413",label:"Componentes de acero inoxidable maquinados por extrusión en caliente"},
    {prodId:"31291414",label:"Componentes de acero maquinados por extrusión en caliente"},
    {prodId:"31291415",label:"Componentes de estaño maquinados por extrusión en caliente"},
    {prodId:"31291416",label:"Componentes de titanio maquinados por extrusión en caliente"},
    {prodId:"31291417",label:"Componentes de zinc maquinados por extrusión en caliente"},
    {prodId:"31291418",label:"Componentes compuestos maquinados por extrusión en caliente"},
    {prodId:"31291419",label:"Componentes de aleación de níquel maquinados por extrusión en caliente"},
    {prodId:"31291420",label:"Componentes no metálicos maquinados por extrusión en caliente"},
    {prodId:"31321100",label:"Conjuntos ligados de material en barras"},
    {prodId:"31321101",label:"Ensambles de barras pegadas de aluminio"},
    {prodId:"31321102",label:"Ensambles de barras pegadas de acero al carbono"},
    {prodId:"31321103",label:"Ensambles de barras pegadas de aleación hast x"},
    {prodId:"31321104",label:"Ensambles de barras pegadas de inconel"},
    {prodId:"31321105",label:"Ensambles de barras pegadas de acero de aleación baja"},
    {prodId:"31321106",label:"Ensambles de barras pegadas no metálica"},
    {prodId:"31321109",label:"Ensambles de barras pegadas de acero inoxidable"},
    {prodId:"31321110",label:"Ensambles de barras pegadas de titanio"},
    {prodId:"31321111",label:"Ensambles de barras pegadas de aleación wasp"},
    {prodId:"31321112",label:"Ensambles de barras pegadas de cobre"},
    {prodId:"31321113",label:"Ensambles de barras pegadas de latón"},
    {prodId:"31321400",label:"Conjuntos soldados o soldados en fuerte de material en barras"},
    {prodId:"31321401",label:"Ensambles de barras soldadas con soldadura fuerte o débil de aluminio"},
    {prodId:"31321402",label:"Ensambles de barras soldadas con soldadura fuerte o débil de acero al carbono"},
    {prodId:"31321403",label:"Ensambles de barras soldadas con soldadura fuerte o débil de aleación hast x"},
    {prodId:"31321404",label:"Ensambles de barras soldadas con soldadura fuerte o débil de inconel"},
    {prodId:"31321405",label:"Ensambles de barras soldadas con soldadura fuerte o débil de acero de aleación baja"},
    {prodId:"31321406",label:"Ensambles de barras soldadas con soldadura fuerte o débil no metálica"},
    {prodId:"31321409",label:"Ensambles de barras soldadas con soldadura fuerte o débil de acero inoxidable"},
    {prodId:"31321410",label:"Ensambles de barras soldadas con soldadura fuerte o débil de titanio"},
    {prodId:"31321411",label:"Ensambles de barras soldadas con soldadura fuerte o débil de aleación wasp"},
    {prodId:"31321412",label:"Ensambles de barras soldadas con soldadura fuerte o débil de cobre"},
    {prodId:"31321413",label:"Ensambles de barras soldadas con soldadura fuerte o débil de latón"},
    {prodId:"31331100",label:"Conjuntos estructurales con acabado ligado"},
    {prodId:"31331101",label:"Ensambles estructurales pegados de aluminio"},
    {prodId:"31331102",label:"Ensambles estructurales pegados de acero al carbono"},
    {prodId:"31331103",label:"Ensambles estructurales pegados de aleación hast x"},
    {prodId:"31331104",label:"Ensambles estructurales pegados de inconel"},
    {prodId:"31331105",label:"Ensambles estructurales pegados de acero de aleación baja"},
    {prodId:"31331106",label:"Ensambles estructurales pegados no metálica"},
    {prodId:"31331109",label:"Ensambles estructurales pegados de acero inoxidable"},
    {prodId:"31331110",label:"Ensambles estructurales pegados de titanio"},
    {prodId:"31331111",label:"Ensambles estructurales pegados de aleación wasp"},
    {prodId:"31331112",label:"Ensambles estructurales pegados de cobre"},
    {prodId:"31331113",label:"Ensambles estructurales pegados de latón"},
    {prodId:"31331200",label:"Conjuntos estructurales empernados"},
    {prodId:"31331201",label:"Ensambles estructurales atornillados de aluminio"},
    {prodId:"31331202",label:"Ensambles estructurales atornillados de acero al carbono"},
    {prodId:"31331203",label:"Ensambles estructurales atornillados de aleación hast x"},
    {prodId:"31331204",label:"Ensambles estructurales atornillados de inconel"},
    {prodId:"31331205",label:"Ensambles estructurales atornillados de acero de aleación baja"},
    {prodId:"31331206",label:"Ensambles estructurales atornillados no metálica"},
    {prodId:"31331209",label:"Ensambles estructurales atornillados de acero inoxidable"},
    {prodId:"31331210",label:"Ensambles estructurales atornillados de titanio"},
    {prodId:"31331211",label:"Ensambles estructurales atornillados de aleación wasp"},
    {prodId:"31331212",label:"Ensambles estructurales atornillados de cobre"},
    {prodId:"31331213",label:"Ensambles estructurales atornillados de latón"},
    {prodId:"31331300",label:"Conjuntos estructurales soldado sónico"},
    {prodId:"31331301",label:"Ensambles estructurales con soldadura sónica de aluminio"},
    {prodId:"31331302",label:"Ensambles estructurales con soldadura sónica de acero al carbono"},
    {prodId:"31331303",label:"Ensambles estructurales con soldadura sónica de aleación hast x"},
    {prodId:"31331304",label:"Ensambles estructurales con soldadura sónica de inconel"},
    {prodId:"31331305",label:"Ensambles estructurales con soldadura sónica de acero de aleación baja"},
    {prodId:"31331306",label:"Ensambles estructurales con soldadura sónica no metálica"},
    {prodId:"31331309",label:"Ensambles estructurales con soldadura sónica de acero inoxidable"},
    {prodId:"31331310",label:"Ensambles estructurales con soldadura sónica de titanio"},
    {prodId:"31331311",label:"Ensambles estructurales con soldadura sónica de aleación wasp"},
    {prodId:"31331312",label:"Ensambles estructurales con soldadura sónica de cobre"},
    {prodId:"31331313",label:"Ensambles estructurales con soldadura sónica de latón"},
    {prodId:"31331400",label:"Conjuntos estructurales soldados con ultra violeta UV"},
    {prodId:"31331401",label:"Ensambles estructurales con soldadura ultra violeta de aluminio"},
    {prodId:"31331402",label:"Ensambles estructurales con soldadura ultra violeta de acero al carbono"},
    {prodId:"31331403",label:"Ensambles estructurales con soldadura ultra violeta de aleación hast x"},
    {prodId:"31331404",label:"Ensambles estructurales con soldadura ultra violeta de inconel"},
    {prodId:"31331405",label:"Ensambles estructurales con soldadura ultra violeta de acero de aleación baja"},
    {prodId:"31331406",label:"Ensambles estructurales con soldadura ultra violeta no metálica"},
    {prodId:"31331409",label:"Ensambles estructurales con soldadura ultra violeta de acero inoxidable"},
    {prodId:"31331410",label:"Ensambles estructurales con soldadura ultra violeta de titanio"},
    {prodId:"31331411",label:"Ensambles estructurales con soldadura ultra violeta de aleación wasp"},
    {prodId:"31331412",label:"Ensambles estructurales con soldadura ultra violeta de cobre"},
    {prodId:"31331413",label:"Ensambles estructurales con soldadura ultra violeta de latón"},
    {prodId:"31331600",label:"Conjuntos estructurales soldado o soldado con latón"},
    {prodId:"31331601",label:"Ensambles estructurales con soldadura de fuerte o débil de aluminio"},
    {prodId:"31331602",label:"Ensambles estructurales con soldadura de fuerte o débil de acero al carbono"},
    {prodId:"31331603",label:"Ensambles estructurales con soldadura de fuerte o débil de aleación hast x"},
    {prodId:"31331604",label:"Ensambles estructurales con soldadura de fuerte o débil de inconel"},
    {prodId:"31331605",label:"Ensambles estructurales con soldadura de fuerte o débil de acero de aleación baja"},
    {prodId:"31331606",label:"Ensambles estructurales con soldadura de fuerte o débil no metálica"},
    {prodId:"31331609",label:"Ensambles estructurales con soldadura de fuerte o débil de acero inoxidable"},
    {prodId:"31331610",label:"Ensambles estructurales con soldadura de fuerte o débil de titanio"},
    {prodId:"31331611",label:"Ensambles estructurales con soldadura de fuerte o débil de aleación wasp"},
    {prodId:"31331612",label:"Ensambles estructurales con soldadura de fuerte o débil de cobre"},
    {prodId:"31331613",label:"Ensambles estructurales con soldadura de fuerte o débil de latón"},
    {prodId:"31331700",label:"Conjuntos estructurales remachados"},
    {prodId:"31331701",label:"Ensambles estructurales remachados de aluminio"},
    {prodId:"31331702",label:"Ensambles estructurales remachados de acero al carbono"},
    {prodId:"31331703",label:"Ensambles estructurales remachados de aleación hast x"},
    {prodId:"31331704",label:"Ensambles estructurales remachados de inconel"},
    {prodId:"31331705",label:"Ensambles estructurales remachados de acero de aleación baja"},
    {prodId:"31331706",label:"Ensambles estructurales remachados no metálica"},
    {prodId:"31331709",label:"Ensambles estructurales remachados de acero inoxidable"},
    {prodId:"31331710",label:"Ensambles estructurales remachados de titanio"},
    {prodId:"31331711",label:"Ensambles estructurales remachados de aleación wasp"},
    {prodId:"31331712",label:"Ensambles estructurales remachados de cobre"},
    {prodId:"31331713",label:"Ensambles estructurales remachados de latón"},
    {prodId:"31341100",label:"Conjuntos de placa soldado o soldado con latón"},
    {prodId:"31341101",label:"Ensambles de láminas soldadas con soldadura fuerte o débil de aluminio"},
    {prodId:"31341102",label:"Ensambles de láminas soldadas con soldadura fuerte o débil de acero al carbono"},
    {prodId:"31341103",label:"Ensambles de láminas soldadas con soldadura fuerte o débil de aleación hast x"},
    {prodId:"31341104",label:"Ensambles de láminas soldadas con soldadura fuerte o débil de inconel"},
    {prodId:"31341105",label:"Ensambles de láminas soldadas con soldadura fuerte o débil de acero de aleación baja"},
    {prodId:"31341106",label:"Ensambles de láminas soldadas con soldadura fuerte o débil no metálica"},
    {prodId:"31341109",label:"Ensambles de láminas soldadas con soldadura fuerte o débil de acero inoxidable"},
    {prodId:"31341110",label:"Ensambles de láminas soldadas con soldadura fuerte o débil de titanio"},
    {prodId:"31341111",label:"Ensambles de láminas soldadas con soldadura fuerte o débil de aleación wasp"},
    {prodId:"31341112",label:"Ensambles de láminas soldadas con soldadura fuerte o débil de cobre"},
    {prodId:"31341113",label:"Ensambles de láminas soldadas con soldadura fuerte o débil de latón"},
    {prodId:"31351200",label:"Conjuntos de tubería soldada o soldada con latón"},
    {prodId:"31351201",label:"Ensambles de tubos soldados con soldadura fuerte o débil de aluminio"},
    {prodId:"31351202",label:"Ensambles de tubos soldados con soldadura fuerte o débil de acero al carbono"},
    {prodId:"31351203",label:"Ensambles de tubos soldados con soldadura fuerte o débil de aleación hast x"},
    {prodId:"31351204",label:"Ensambles de tubos soldados con soldadura fuerte o débil de inconel"},
    {prodId:"31351205",label:"Ensambles de tubos soldados con soldadura fuerte o débil de acero de aleación baja"},
    {prodId:"31351206",label:"Ensambles de tubos soldados con soldadura fuerte o débil no metálica"},
    {prodId:"31351209",label:"Ensambles de tubos soldados con soldadura fuerte o débil de acero inoxidable"},
    {prodId:"31351210",label:"Ensambles de tubos soldados con soldadura fuerte o débil de titanio"},
    {prodId:"31351211",label:"Ensambles de tubos soldados con soldadura fuerte o débil de aleación wasp"},
    {prodId:"31351212",label:"Ensambles de tubos soldados con soldadura fuerte o débil de cobre"},
    {prodId:"31351213",label:"Ensambles de tubos soldados con soldadura fuerte o débil de latón"},
    {prodId:"31351400",label:"Conjuntos de tubería ligada"},
    {prodId:"31351401",label:"Ensambles de tubos pegados de aluminio"},
    {prodId:"31351402",label:"Ensambles de tubos pegados de acero al carbono"},
    {prodId:"31351403",label:"Ensambles de tubos pegados de aleación hast x"},
    {prodId:"31351404",label:"Ensambles de tubos pegados de inconel"},
    {prodId:"31351405",label:"Ensambles de tubos pegados de acero de aleación baja"},
    {prodId:"31351406",label:"Ensambles de tubos pegados no metálica"},
    {prodId:"31351409",label:"Ensambles de tubos pegados de acero inoxidable"},
    {prodId:"31351410",label:"Ensambles de tubos pegados de titanio"},
    {prodId:"31351411",label:"Ensambles de tubos pegados de aleación wasp"},
    {prodId:"31351412",label:"Ensambles de tubos pegados de cobre"},
    {prodId:"31351413",label:"Ensambles de tubos pegados de latón"},
    {prodId:"31351500",label:"Conjuntos de tubería empernados"},
    {prodId:"31351501",label:"Ensambles de tubos atornillados de aluminio"},
    {prodId:"31351502",label:"Ensambles de tubos atornillados de acero al carbono"},
    {prodId:"31351503",label:"Ensambles de tubos atornillados de aleación hast x"},
    {prodId:"31351504",label:"Ensambles de tubos atornillados de inconel"},
    {prodId:"31351505",label:"Ensambles de tubos atornillados de acero de aleación baja"},
    {prodId:"31351506",label:"Ensambles de tubos atornillados no metálica"},
    {prodId:"31351509",label:"Ensambles de tubos atornillados de acero inoxidable"},
    {prodId:"31351510",label:"Ensambles de tubos atornillados de titanio"},
    {prodId:"31351511",label:"Ensambles de tubos atornillados de aleación wasp"},
    {prodId:"31351512",label:"Ensambles de tubos atornillados de cobre"},
    {prodId:"31351513",label:"Ensambles de tubos atornillados de latón"},
    {prodId:"31361100",label:"Conjuntos de placa ligados"},
    {prodId:"31361101",label:"Ensambles de placas soldadas de aluminio"},
    {prodId:"31361102",label:"Ensambles de placas soldadas de acero al carbono"},
    {prodId:"31361103",label:"Ensambles de placas soldadas de aleación hast x"},
    {prodId:"31361104",label:"Ensambles de placas soldadas de inconel"},
    {prodId:"31361105",label:"Ensambles de placas soldadas de acero de aleación baja"},
    {prodId:"31361106",label:"Ensambles de placas soldadas no metálica"},
    {prodId:"31361109",label:"Ensambles de placas soldadas de acero inoxidable"},
    {prodId:"31361110",label:"Ensambles de placas soldadas de titanio"},
    {prodId:"31361111",label:"Ensambles de placas soldadas de aleación wasp"},
    {prodId:"31361112",label:"Ensambles de placas soldadas de cobre"},
    {prodId:"31361113",label:"Ensambles de placas soldadas de latón"},
    {prodId:"31361200",label:"Conjuntos de placa empernados"},
    {prodId:"31361201",label:"Ensambles de placas atornilladas de aluminio"},
    {prodId:"31361202",label:"Ensambles de placas atornilladas de acero al carbono"},
    {prodId:"31361203",label:"Ensambles de placas atornilladas de aleación hast x"},
    {prodId:"31361204",label:"Ensambles de placas atornilladas de inconel"},
    {prodId:"31361205",label:"Ensambles de placas atornilladas de acero de aleación baja"},
    {prodId:"31361206",label:"Ensambles de placas atornilladas no metálica"},
    {prodId:"31361209",label:"Ensambles de placas atornilladas de acero inoxidable"},
    {prodId:"31361210",label:"Ensambles de placas atornilladas de titanio"},
    {prodId:"31361211",label:"Ensambles de placas atornilladas de aleación wasp"},
    {prodId:"31361212",label:"Ensambles de placas atornilladas de cobre"},
    {prodId:"31361213",label:"Ensambles de placas atornilladas de latón"},
    {prodId:"31371000",label:"Productos de fibra de cerámica"},
    {prodId:"31371001",label:"Paneles aislantes"},
    {prodId:"31371002",label:"Lana aislante"},
    {prodId:"31371003",label:"Sábanas refractarias"},
    {prodId:"31371100",label:"Ladrillos refractarios"},
    {prodId:"31371101",label:"Ladrillo de mulita"},
    {prodId:"31371102",label:"Ladrillos de silimanita"},
    {prodId:"31371103",label:"Ladrillos resistentes al ácido"},
    {prodId:"31371104",label:"Ladrillos de sílice"},
    {prodId:"31371105",label:"Ladrillos de alta alúmina"},
    {prodId:"31371106",label:"Ladrillos de silicato de calcio"},
    {prodId:"31371107",label:"Ladrillos con formas"},
    {prodId:"31371108",label:"Ladrillos de arcilla de fuego"},
    {prodId:"31371200",label:"Compuestos para moldear"},
    {prodId:"31371201",label:"Moldeables densos"},
    {prodId:"31371202",label:"Moldeables de aislación"},
    {prodId:"31371203",label:"Moldeables bajos en cemento"},
    {prodId:"31371204",label:"Moldeables resistentes a ácidos o a álcalis"},
    {prodId:"31371205",label:"Moldeables resistentes a abrasión"},
    {prodId:"31371206",label:"Moldeables sic"},
    {prodId:"31371207",label:"Moldeables de auto flujo"},
    {prodId:"31371208",label:"Moldeable tabular alúmina"},
    {prodId:"31371209",label:"Moldeable resistente a la erosión"},
    {prodId:"31371210",label:"Moldeable de alta alúmina"},
    {prodId:"31371211",label:"Moldeable de arcilla de fuego"},
    {prodId:"31371212",label:"Moldeable de servicio especial"},
    {prodId:"31371300",label:"Refractarios formados"},
    {prodId:"31371301",label:"Bloques porosos"},
    {prodId:"31371302",label:"Boquillas de zircón"},
    {prodId:"31381100",label:"Imanes fundidos y ensambles magnéticos"},
    {prodId:"31381101",label:"Imán isotrópico fundido maquinado de ferrita"},
    {prodId:"31381102",label:"Imán isotrópico fundido maquinado de bario ferrita"},
    {prodId:"31381103",label:"Imán isotrópico fundido maquinado de estroncio ferrita"},
    {prodId:"31381104",label:"Imán isotrópico fundido maquinado de neodimio"},
    {prodId:"31381105",label:"Imán isotrópico fundido maquinado de samario cobalto"},
    {prodId:"31381106",label:"Imán isotrópico fundido maquinado de aluminio níquel cobalto ferroso"},
    {prodId:"31381107",label:"Imán anisotrópico fundido maquinado de ferrita"},
    {prodId:"31381108",label:"Imán anisotrópico fundido maquinado de bario ferrita"},
    {prodId:"31381109",label:"Imán anisotrópico fundido maquinado de estroncio ferrita"},
    {prodId:"31381110",label:"Imán anisotrópico fundido maquinado de samario cobalto"},
    {prodId:"31381111",label:"Imán anisotrópico fundido maquinado de aluminio níquel cobalto ferroso"},
    {prodId:"31381112",label:"Imán isotrópico fundido, maquinado y recubierto de ferrita"},
    {prodId:"31381113",label:"Imán isotrópico fundido, maquinado y recubierto de bario ferrita"},
    {prodId:"31381114",label:"Imán isotrópico fundido, maquinado y recubierto de estroncio ferrita"},
    {prodId:"31381115",label:"Imán isotrópico fundido, maquinado y recubierto de neodimio"},
    {prodId:"31381116",label:"Imán isotrópico fundido, maquinado y recubierto de samario cobalto"},
    {prodId:"31381117",label:"Imán isotrópico fundido, maquinado y recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381118",label:"Imán anisotrópico fundido, maquinado y recubierto  de ferrita"},
    {prodId:"31381119",label:"Imán anisotrópico fundido, maquinado y recubierto  de bario ferrita"},
    {prodId:"31381120",label:"Imán anisotrópico fundido, maquinado y recubierto de estroncio ferrita"},
    {prodId:"31381121",label:"Imán anisotrópico fundido, maquinado y recubierto de samario cobalto"},
    {prodId:"31381122",label:"Imán anisotrópico fundido, maquinado y recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381123",label:"Imán isotrópico fundido recubierto de ferrita"},
    {prodId:"31381124",label:"Imán isotrópico fundido recubierto de bario ferrita"},
    {prodId:"31381125",label:"Imán isotrópico fundido recubierto de estroncio ferrita"},
    {prodId:"31381126",label:"Imán isotrópico fundido recubierto de neodimio"},
    {prodId:"31381127",label:"Imán isotrópico fundido recubierto de samario cobalto"},
    {prodId:"31381128",label:"Imán isotrópico fundido recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381129",label:"Imán anisotrópico fundido recubierto de ferrita"},
    {prodId:"31381130",label:"Imán anisotrópico fundido recubierto de bario ferrita"},
    {prodId:"31381131",label:"Imán anisotrópico fundido recubierto de estroncio ferrita"},
    {prodId:"31381132",label:"Imán anisotrópico fundido recubierto de samario cobalto"},
    {prodId:"31381133",label:"Imán anisotrópico fundido recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381134",label:"Herramienta isotrópica fundida maquinada de ferrita"},
    {prodId:"31381135",label:"Herramienta isotrópica fundida maquinada de bario ferrita"},
    {prodId:"31381136",label:"Herramienta isotrópica fundida maquinada de estroncio ferrita"},
    {prodId:"31381137",label:"Herramienta isotrópica fundida maquinada de neodimio"},
    {prodId:"31381138",label:"Herramienta isotrópica fundida maquinada de samario cobalto"},
    {prodId:"31381139",label:"Herramienta isotrópica fundida maquinada de aluminio níquel cobalto ferroso"},
    {prodId:"31381140",label:"Herramienta anisotrópica fundida maquinada de ferrita"},
    {prodId:"31381141",label:"Herramienta anisotrópica fundida maquinada de bario ferrita"},
    {prodId:"31381142",label:"Herramienta anisotrópica fundida maquinada de estroncio ferrita"},
    {prodId:"31381143",label:"Herramienta anisotrópica fundida maquinada de samario cobalto"},
    {prodId:"31381144",label:"Herramienta anisotrópica fundida maquinada de aluminio níquel cobalto ferroso"},
    {prodId:"31381145",label:"Ensamble de imán isotrópico fundido de ferrita"},
    {prodId:"31381146",label:"Ensamble de imán isotrópico fundido de bario ferrita"},
    {prodId:"31381147",label:"Ensamble de imán isotrópico fundido de estroncio ferrita"},
    {prodId:"31381148",label:"Ensamble de imán isotrópico fundido de neodimio"},
    {prodId:"31381149",label:"Ensamble de imán isotrópico fundido de samario cobalto"},
    {prodId:"31381150",label:"Ensamble de imán isotrópico fundido de aluminio níquel cobalto ferroso"},
    {prodId:"31381151",label:"Ensamble de imán anisotrópico fundido de ferrita"},
    {prodId:"31381152",label:"Ensamble de imán anisotrópico fundido de bario ferrita"},
    {prodId:"31381153",label:"Ensamble de imán anisotrópico fundido de estroncio ferrita"},
    {prodId:"31381154",label:"Ensamble de imán anisotrópico fundido de samario cobalto"},
    {prodId:"31381155",label:"Ensamble de imán anisotrópico fundido de aluminio níquel cobalto ferroso"},
    {prodId:"31381200",label:"Imanes sinterizados y ensambles magnéticos"},
    {prodId:"31381201",label:"Imán isotrópico sinterizado maquinado de ferrita"},
    {prodId:"31381202",label:"Imán isotrópico sinterizado maquinado de bario ferrita"},
    {prodId:"31381203",label:"Imán isotrópico sinterizado maquinado de estroncio ferrita"},
    {prodId:"31381204",label:"Imán isotrópico sinterizado maquinado de neodimio"},
    {prodId:"31381205",label:"Imán isotrópico sinterizado maquinado de samario cobalto"},
    {prodId:"31381206",label:"Imán isotrópico sinterizado maquinado de aluminio níquel cobalto ferroso"},
    {prodId:"31381207",label:"Imán anisotrópico sinterizado maquinado de ferrita"},
    {prodId:"31381208",label:"Imán anisotrópico sinterizado maquinado de bario ferrita"},
    {prodId:"31381209",label:"Imán anisotrópico sinterizado maquinado de estroncio ferrita"},
    {prodId:"31381210",label:"Imán anisotrópico sinterizado maquinado de samario cobalto"},
    {prodId:"31381211",label:"Imán anisotrópico sinterizado maquinado de aluminio níquel cobalto ferroso"},
    {prodId:"31381212",label:"Imán isotrópico sinterizado, maquinado y recubierto de ferrita"},
    {prodId:"31381213",label:"Imán isotrópico sinterizado, maquinado y recubierto de bario ferrita"},
    {prodId:"31381214",label:"Imán isotrópico sinterizado, maquinado y recubierto de estroncio ferrita"},
    {prodId:"31381215",label:"Imán isotrópico sinterizado, maquinado y recubierto de neodimio"},
    {prodId:"31381216",label:"Imán isotrópico sinterizado, maquinado y recubierto de samario cobalto"},
    {prodId:"31381217",label:"Imán isotrópico sinterizado, maquinado y recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381218",label:"Imán anisotrópico sinterizado, maquinado y recubierto  de ferrita"},
    {prodId:"31381219",label:"Imán anisotrópico sinterizado, maquinado y recubierto  de bario ferrita"},
    {prodId:"31381220",label:"Imán anisotrópico sinterizado, maquinado y recubierto de estroncio ferrita"},
    {prodId:"31381221",label:"Imán anisotrópico sinterizado, maquinado y recubierto de samario cobalto"},
    {prodId:"31381222",label:"Imán anisotrópico sinterizado, maquinado y recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381223",label:"Imán isotrópico sinterizado recubierto de ferrita"},
    {prodId:"31381224",label:"Imán isotrópico sinterizado recubierto de bario ferrita"},
    {prodId:"31381225",label:"Imán isotrópico sinterizado recubierto de estroncio ferrita"},
    {prodId:"31381226",label:"Imán isotrópico sinterizado recubierto de neodimio"},
    {prodId:"31381227",label:"Imán isotrópico sinterizado recubierto de samario cobalto"},
    {prodId:"31381228",label:"Imán isotrópico sinterizado recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381229",label:"Imán anisotrópico sinterizado recubierto de ferrita"},
    {prodId:"31381230",label:"Imán anisotrópico sinterizado recubierto de bario ferrita"},
    {prodId:"31381231",label:"Imán anisotrópico sinterizado recubierto de estroncio ferrita"},
    {prodId:"31381232",label:"Imán anisotrópico sinterizado recubierto de samario cobalto"},
    {prodId:"31381233",label:"Imán anisotrópico sinterizado recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381234",label:"Herramienta isotrópica sinterizada maquinada de ferrita"},
    {prodId:"31381235",label:"Herramienta isotrópica sinterizada maquinada de bario ferrita"},
    {prodId:"31381236",label:"Herramienta isotrópica sinterizada maquinada de estroncio ferrita"},
    {prodId:"31381237",label:"Herramienta isotrópica sinterizada maquinada de neodimio"},
    {prodId:"31381238",label:"Herramienta isotrópica sinterizada maquinada de samario cobalto"},
    {prodId:"31381239",label:"Herramienta isotrópica sinterizada maquinada de aluminio níquel cobalto ferroso"},
    {prodId:"31381240",label:"Herramienta anisotrópica sinterizada maquinada de ferrita"},
    {prodId:"31381241",label:"Herramienta anisotrópica sinterizada maquinada de bario ferrita"},
    {prodId:"31381242",label:"Herramienta anisotrópica sinterizada maquinada de estroncio ferrita"},
    {prodId:"31381243",label:"Herramienta anisotrópica sinterizada maquinada de samario cobalto"},
    {prodId:"31381244",label:"Herramienta anisotrópica sinterizada maquinada de aluminio níquel cobalto ferroso"},
    {prodId:"31381245",label:"Ensamble de imán isotrópico sinterizado de ferrita"},
    {prodId:"31381246",label:"Ensamble de imán isotrópico sinterizado de bario ferrita"},
    {prodId:"31381247",label:"Ensamble de imán isotrópico sinterizado de estroncio ferrita"},
    {prodId:"31381248",label:"Ensamble de imán isotrópico sinterizado de neodimio"},
    {prodId:"31381249",label:"Ensamble de imán isotrópico sinterizado de samario cobalto"},
    {prodId:"31381250",label:"Ensamble de imán isotrópico sinterizado de aluminio níquel cobalto ferroso"},
    {prodId:"31381251",label:"Ensamble de imán anisotrópico sinterizado de ferrita"},
    {prodId:"31381252",label:"Ensamble de imán anisotrópico sinterizado de bario ferrita"},
    {prodId:"31381253",label:"Ensamble de imán anisotrópico sinterizado de estroncio ferrita"},
    {prodId:"31381254",label:"Ensamble de imán anisotrópico sinterizado de samario cobalto"},
    {prodId:"31381255",label:"Ensamble de imán anisotrópico sinterizado de aluminio níquel cobalto ferroso"},
    {prodId:"31381400",label:"Imanes pegados con plástico y ensambles magnéticos"},
    {prodId:"31381401",label:"Imán isotrópico pegado con plástico maquinado de ferrita"},
    {prodId:"31381402",label:"Imán isotrópico pegado con plástico maquinado de bario ferrita"},
    {prodId:"31381403",label:"Imán isotrópico pegado con plástico maquinado de estroncio ferrita"},
    {prodId:"31381404",label:"Imán isotrópico pegado con plástico maquinado de neodimio"},
    {prodId:"31381405",label:"Imán isotrópico pegado con plástico maquinado de samario cobalto"},
    {prodId:"31381406",label:"Imán isotrópico pegado con plástico maquinado de aluminio níquel cobalto ferroso"},
    {prodId:"31381407",label:"Imán anisotrópico pegado con plástico maquinado de ferrita"},
    {prodId:"31381408",label:"Imán anisotrópico pegado con plástico maquinado de bario ferrita"},
    {prodId:"31381409",label:"Imán anisotrópico pegado con plástico maquinado de estroncio ferrita"},
    {prodId:"31381410",label:"Imán anisotrópico pegado con plástico maquinado de samario cobalto"},
    {prodId:"31381411",label:"Imán anisotrópico pegado con plástico maquinado de aluminio níquel cobalto ferroso"},
    {prodId:"31381412",label:"Imán isotrópico pegado con plástico, maquinado y recubierto de ferrita"},
    {prodId:"31381413",label:"Imán isotrópico pegado con plástico, maquinado y recubierto de bario ferrita"},
    {prodId:"31381414",label:"Imán isotrópico pegado con plástico, maquinado y recubierto de estroncio ferrita"},
    {prodId:"31381415",label:"Imán isotrópico pegado con plástico, maquinado y recubierto de neodimio"},
    {prodId:"31381416",label:"Imán isotrópico pegado con plástico, maquinado y recubierto de samario cobalto"},
    {prodId:"31381417",label:"Imán isotrópico pegado con plástico, maquinado y recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381418",label:"Imán anisotrópico pegado con plástico, maquinado y recubierto  de ferrita"},
    {prodId:"31381419",label:"Imán anisotrópico pegado con plástico, maquinado y recubierto  de bario ferrita"},
    {prodId:"31381420",label:"Imán anisotrópico pegado con plástico, maquinado y recubierto de estroncio ferrita"},
    {prodId:"31381421",label:"Imán anisotrópico pegado con plástico, maquinado y recubierto de samario cobalto"},
    {prodId:"31381422",label:"Imán anisotrópico pegado con plástico, maquinado y recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381423",label:"Imán isotrópico pegado con plástico recubierto de ferrita"},
    {prodId:"31381424",label:"Imán isotrópico pegado con plástico recubierto de bario ferrita"},
    {prodId:"31381425",label:"Imán isotrópico pegado con plástico recubierto de estroncio ferrita"},
    {prodId:"31381426",label:"Imán isotrópico pegado con plástico recubierto de neodimio"},
    {prodId:"31381427",label:"Imán isotrópico pegado con plástico recubierto de samario cobalto"},
    {prodId:"31381428",label:"Imán isotrópico pegado con plástico recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381429",label:"Imán anisotrópico pegado con plástico recubierto de ferrita"},
    {prodId:"31381430",label:"Imán anisotrópico pegado con plástico recubierto de bario ferrita"},
    {prodId:"31381431",label:"Imán anisotrópico pegado con plástico recubierto de estroncio ferrita"},
    {prodId:"31381432",label:"Imán anisotrópico pegado con plástico recubierto de samario cobalto"},
    {prodId:"31381433",label:"Imán anisotrópico pegado con plástico recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381434",label:"Herramienta isotrópica pegada con plástico maquinada de ferrita"},
    {prodId:"31381435",label:"Herramienta isotrópica pegada con plástico maquinada de bario ferrita"},
    {prodId:"31381436",label:"Herramienta isotrópica pegada con plástico maquinada de estroncio ferrita"},
    {prodId:"31381437",label:"Herramienta isotrópica pegada con plástico maquinada de neodimio"},
    {prodId:"31381438",label:"Herramienta isotrópica pegada con plástico maquinada de samario cobalto"},
    {prodId:"31381439",label:"Herramienta isotrópica pegada con plástico maquinada de aluminio níquel cobalto ferroso"},
    {prodId:"31381440",label:"Herramienta anisotrópica pegada con plástico maquinada de ferrita"},
    {prodId:"31381441",label:"Herramienta anisotrópica pegada con plástico maquinada de bario ferrita"},
    {prodId:"31381442",label:"Herramienta anisotrópica pegada con plástico maquinada de estroncio ferrita"},
    {prodId:"31381443",label:"Herramienta anisotrópica pegada con plástico maquinada de samario cobalto"},
    {prodId:"31381444",label:"Herramienta anisotrópica pegada con plástico maquinada de aluminio níquel cobalto ferroso"},
    {prodId:"31381445",label:"Ensamble de imán isotrópico pegado con plástico de ferrita"},
    {prodId:"31381446",label:"Ensamble de imán isotrópico pegado con plástico de bario ferrita"},
    {prodId:"31381447",label:"Ensamble de imán isotrópico pegado con plástico de estroncio ferrita"},
    {prodId:"31381448",label:"Ensamble de imán isotrópico pegado con plástico de neodimio"},
    {prodId:"31381449",label:"Ensamble de imán isotrópico pegado con plástico de samario cobalto"},
    {prodId:"31381450",label:"Ensamble de imán isotrópico pegado con plástico de aluminio níquel cobalto ferroso"},
    {prodId:"31381451",label:"Ensamble de imán anisotrópico pegado con plástico de ferrita"},
    {prodId:"31381452",label:"Ensamble de imán anisotrópico pegado con plástico de bario ferrita"},
    {prodId:"31381453",label:"Ensamble de imán anisotrópico pegado con plástico de estroncio ferrita"},
    {prodId:"31381454",label:"Ensamble de imán anisotrópico pegado con plástico de samario cobalto"},
    {prodId:"31381455",label:"Ensamble de imán anisotrópico pegado con plástico de aluminio níquel cobalto ferroso"},
    {prodId:"31381500",label:"Imanes moldeados por inyección y ensambles magnéticos"},
    {prodId:"31381501",label:"Imán isotrópico pegado con plástico moldeado por inyección maquinado de ferrita"},
    {prodId:"31381502",label:"Imán isotrópico pegado con plástico moldeado por inyección maquinado de bario ferrita"},
    {prodId:"31381503",label:"Imán isotrópico pegado con plástico moldeado por inyección maquinado de estroncio ferrita"},
    {prodId:"31381504",label:"Imán isotrópico pegado con plástico moldeado por inyección maquinado de neodimio"},
    {prodId:"31381505",label:"Imán isotrópico pegado con plástico moldeado por inyección maquinado de samario cobalto"},
    {prodId:"31381506",label:"Imán isotrópico pegado con plástico moldeado por inyección maquinado de aluminio níquel cobalto ferroso"},
    {prodId:"31381507",label:"Imán anisotrópico pegado con plástico moldeado por inyección maquinado de ferrita"},
    {prodId:"31381508",label:"Imán anisotrópico pegado con plástico moldeado por inyección maquinado de bario ferrita"},
    {prodId:"31381509",label:"Imán anisotrópico pegado con plástico moldeado por inyección maquinado de estroncio ferrita"},
    {prodId:"31381510",label:"Imán anisotrópico pegado con plástico moldeado por inyección maquinado de samario cobalto"},
    {prodId:"31381511",label:"Imán anisotrópico pegado con plástico moldeado por inyección maquinado de aluminio níquel cobalto ferroso"},
    {prodId:"31381512",label:"Imán isotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de ferrita"},
    {prodId:"31381513",label:"Imán isotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de bario ferrita"},
    {prodId:"31381514",label:"Imán isotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de estroncio ferrita"},
    {prodId:"31381515",label:"Imán isotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de neodimio"},
    {prodId:"31381516",label:"Imán isotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de samario cobalto"},
    {prodId:"31381517",label:"Imán isotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381518",label:"Imán anisotrópico pegado con plástico moldeado por inyección, maquinado y recubierto  de ferrita"},
    {prodId:"31381519",label:"Imán anisotrópico pegado con plástico moldeado por inyección, maquinado y recubierto  de bario ferrita"},
    {prodId:"31381520",label:"Imán anisotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de estroncio ferrita"},
    {prodId:"31381521",label:"Imán anisotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de samario cobalto"},
    {prodId:"31381522",label:"Imán anisotrópico pegado con plástico moldeado por inyección, maquinado y recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381523",label:"Imán isotrópico pegado con plástico moldeado por inyección recubierto de ferrita"},
    {prodId:"31381524",label:"Imán isotrópico pegado con plástico moldeado por inyección recubierto de bario ferrita"},
    {prodId:"31381525",label:"Imán isotrópico pegado con plástico moldeado por inyección recubierto de estroncio ferrita"},
    {prodId:"31381526",label:"Imán isotrópico pegado con plástico moldeado por inyección recubierto de neodimio"},
    {prodId:"31381527",label:"Imán isotrópico pegado con plástico moldeado por inyección recubierto de samario cobalto"},
    {prodId:"31381528",label:"Imán isotrópico pegado con plástico moldeado por inyección recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381529",label:"Imán anisotrópico pegado con plástico moldeado por inyección recubierto de ferrita"},
    {prodId:"31381530",label:"Imán anisotrópico pegado con plástico moldeado por inyección recubierto de bario ferrita"},
    {prodId:"31381531",label:"Imán anisotrópico pegado con plástico moldeado por inyección recubierto de estroncio ferrita"},
    {prodId:"31381532",label:"Imán anisotrópico pegado con plástico moldeado por inyección recubierto de samario cobalto"},
    {prodId:"31381533",label:"Imán anisotrópico pegado con plástico moldeado por inyección recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381534",label:"Herramienta isotrópica pegada con plástico moldeada por inyección maquinada de ferrita"},
    {prodId:"31381535",label:"Herramienta isotrópica pegada con plástico moldeada por inyección maquinada de bario ferrita"},
    {prodId:"31381536",label:"Herramienta isotrópica pegada con plástico moldeada por inyección maquinada de estroncio ferrita"},
    {prodId:"31381537",label:"Herramienta isotrópica pegada con plástico moldeada por inyección maquinada de neodimio"},
    {prodId:"31381538",label:"Herramienta isotrópica pegada con plástico moldeada por inyección maquinada de samario cobalto"},
    {prodId:"31381539",label:"Herramienta isotrópica pegada con plástico moldeada por inyección maquinada de aluminio níquel cobalto ferroso"},
    {prodId:"31381540",label:"Herramienta anisotrópica pegada con plástico moldeada por inyección maquinada de ferrita"},
    {prodId:"31381541",label:"Herramienta anisotrópica pegada con plástico moldeada por inyección maquinada de bario ferrita"},
    {prodId:"31381542",label:"Herramienta anisotrópica pegada con plástico moldeada por inyección maquinada de estroncio ferrita"},
    {prodId:"31381543",label:"Herramienta anisotrópica pegada con plástico moldeada por inyección maquinada de samario cobalto"},
    {prodId:"31381544",label:"Herramienta anisotrópica pegada con plástico moldeada por inyección maquinada de aluminio níquel cobalto ferroso"},
    {prodId:"31381545",label:"Ensamble de imán isotrópico pegado con plástico moldeado por inyección de ferrita"},
    {prodId:"31381546",label:"Ensamble de imán isotrópico pegado con plástico moldeado por inyección de bario ferrita"},
    {prodId:"31381547",label:"Ensamble de imán isotrópico pegado con plástico moldeado por inyección de estroncio ferrita"},
    {prodId:"31381548",label:"Ensamble de imán isotrópico pegado con plástico moldeado por inyección de neodimio"},
    {prodId:"31381549",label:"Ensamble de imán isotrópico pegado con plástico moldeado por inyección de samario cobalto"},
    {prodId:"31381550",label:"Ensamble de imán isotrópico pegado con plástico moldeado por inyección de aluminio níquel cobalto ferroso"},
    {prodId:"31381551",label:"Ensamble de imán anisotrópico pegado con plástico moldeado por inyección de ferrita"},
    {prodId:"31381552",label:"Ensamble de imán anisotrópico pegado con plástico moldeado por inyección de bario ferrita"},
    {prodId:"31381553",label:"Ensamble de imán anisotrópico pegado con plástico moldeado por inyección de estroncio ferrita"},
    {prodId:"31381554",label:"Ensamble de imán anisotrópico pegado con plástico moldeado por inyección de samario cobalto"},
    {prodId:"31381555",label:"Ensamble de imán anisotrópico pegado con plástico moldeado por inyección de aluminio níquel cobalto ferroso"},
    {prodId:"31391500",label:"Maquinados de precisión estándar"},
    {prodId:"31391501",label:"Maquinado de precisión estándar de plástico"},
    {prodId:"31391502",label:"Maquinado de precisión estándar de cerámica"},
    {prodId:"31391503",label:"Maquinado de precisión estándar de acero"},
    {prodId:"31391504",label:"Maquinado de precisión estándar de acero inoxidable"},
    {prodId:"31391505",label:"Maquinado de precisión estándar de aluminio"},
    {prodId:"31391506",label:"Maquinado de precisión estándar de latón"},
    {prodId:"31391600",label:"Maquinados de precisión media"},
    {prodId:"31391601",label:"Maquinado de precisión media de plástico"},
    {prodId:"31391602",label:"Maquinado de precisión media de cerámica"},
    {prodId:"31391603",label:"Maquinado de precisión media de acero"},
    {prodId:"31391604",label:"Maquinado de precisión media de acero inoxidable"},
    {prodId:"31391605",label:"Maquinado de precisión media de aluminio"},
    {prodId:"31391606",label:"Maquinado de precisión media de latón"},
    {prodId:"31391700",label:"Maquinados de precisión alta"},
    {prodId:"31391701",label:"Maquinado de alta precisión de plástico"},
    {prodId:"31391702",label:"Maquinado de alta precisión de cerámica"},
    {prodId:"31391703",label:"Maquinado de alta precisión de acero"},
    {prodId:"31391704",label:"Maquinado de alta precisión de acero inoxidable"},
    {prodId:"31391705",label:"Maquinado de alta precisión de aluminio"},
    {prodId:"31391706",label:"Maquinado de alta precisión de latón"},
    {prodId:"31401500",label:"Empaques Moldeados"},
    {prodId:"31401501",label:"Empaque moldeado de caucho"},
    {prodId:"31401502",label:"Empaque moldeado de plástico"},
    {prodId:"31401503",label:"Empaque moldeado o - ring"},
    {prodId:"31401504",label:"Empaque moldeado de interferencia electromagnética"},
    {prodId:"31401505",label:"Empaque moldeado de silicona"},
    {prodId:"31401506",label:"Empaque moldeado inflable"},
    {prodId:"31401507",label:"Kit de empaque moldeado"},
    {prodId:"31401600",label:"Empaques troquelados"},
    {prodId:"31401601",label:"Empaque troquelado de caucho"},
    {prodId:"31401602",label:"Empaque troquelado de plástico"},
    {prodId:"31401603",label:"Empaque troquelado de textil"},
    {prodId:"31401604",label:"Empaque troquelado de corcho"},
    {prodId:"31401605",label:"Empaque troquelado de interferencia electromagnética o emi"},
    {prodId:"31401606",label:"Empaque troquelado de silicona"},
    {prodId:"31401607",label:"Empaque troquelado de fibra comprimida"},
    {prodId:"31401608",label:"Empaque troquelado de bi - material"},
    {prodId:"31401609",label:"Empaque troquelado de polietifluroetileno"},
    {prodId:"31401610",label:"Kit de empaque troquelado"},
    {prodId:"31401700",label:"Empaques estampados"},
    {prodId:"31401701",label:"Empaque de caucho estampado recubierto de acero"},
    {prodId:"31401702",label:"Empaque estampado de plástico"},
    {prodId:"31401703",label:"Empaque estampado de caucho"},
    {prodId:"31401704",label:"Empaque estampado de metal"},
    {prodId:"31401705",label:"Empaque estampado de textil"},
    {prodId:"31401706",label:"Empaque estampado de corcho"},
    {prodId:"31401707",label:"Empaque estampado atornillado"},
    {prodId:"31401708",label:"Kit de empaque estampado"},
    {prodId:"31401800",label:"Empaques cortados con chorro de agua"},
    {prodId:"31401801",label:"Empaque de interferencia electromagnética cortado con chorro de agua"},
    {prodId:"31401802",label:"Empaque  de caucho cortado con chorro de agua"},
    {prodId:"31401803",label:"Empaque  de plástico cortado con chorro de agua"},
    {prodId:"31401804",label:"Empaque de textil cortado con chorro de agua"},
    {prodId:"31401805",label:"Empaque de corcho cortado con chorro de agua"},
    {prodId:"31401806",label:"Empaque de silicona cortado con chorro de agua"},
    {prodId:"31401807",label:"Kit de empaque cortado con chorro de agua"},
    {prodId:"31401900",label:"Empaques líquidos"},
    {prodId:"31401901",label:"Empaque líquido de poli acrilato"},
    {prodId:"31401902",label:"Empaque líquido de silicona"},
    {prodId:"31401903",label:"Empaque líquido de solvente"},
    {prodId:"31401904",label:"Empaque líquido anaeróbico"},
    {prodId:"31411500",label:"Sellos torneados"},
    {prodId:"31411501",label:"Sello torneado de caucho"},
    {prodId:"31411502",label:"Sello torneado de politetrafluroetileno"},
    {prodId:"31411503",label:"Sello torneado de neopreno"},
    {prodId:"31411504",label:"Sello torneado de poliacrilato"},
    {prodId:"31411505",label:"Sello torneado metálico"},
    {prodId:"31411506",label:"Kit de sello torneado"},
    {prodId:"31411600",label:"Sellos troquelados"},
    {prodId:"31411601",label:"Sello troquelado de caucho"},
    {prodId:"31411602",label:"Sello troquelado de espuma"},
    {prodId:"31411603",label:"Sello troquelado de plástico"},
    {prodId:"31411604",label:"Kit de sello troquelado"},
    {prodId:"31411605",label:"Sello troquelado de silicona"},
    {prodId:"31411700",label:"Sellos mecánicos"},
    {prodId:"31411701",label:"Sello mecánico de empuje"},
    {prodId:"31411702",label:"Sello mecánico de fuelle"},
    {prodId:"31411703",label:"Kit de sello mecánico"},
    {prodId:"31411704",label:"Sello  v - ring"},
    {prodId:"31411705",label:"Sello de labio"},
    {prodId:"31411800",label:"Sellos de diafragma"},
    {prodId:"31411801",label:"Sello de diafragma soldado"},
    {prodId:"31411802",label:"Sello de diafragma de abrazadera"},
    {prodId:"31411803",label:"Sello de diafragma de cápsula"},
    {prodId:"31411804",label:"Sello de diafragma de caucho"},
    {prodId:"31411805",label:"Sello de diafragma de fibra reforzada"},
    {prodId:"31411900",label:"Sellos moldeados"},
    {prodId:"31411901",label:"Sello moldeado de caucho"},
    {prodId:"31411902",label:"Sello moldeado de plástico"},
    {prodId:"31411903",label:"Sello moldeado de precisión"},
    {prodId:"31411904",label:"Sello moldeado de caucho sobre metal o plástico"},
    {prodId:"31411905",label:"Sello moldeado de silicona"},
    {prodId:"31421500",label:"Sellos sinterizados"},
    {prodId:"31421501",label:"Filtro sinterizado estándar de hierro cobre"},
    {prodId:"31421502",label:"Filtro sinterizado estándar compuesto de hierro magnético suave"},
    {prodId:"31421503",label:"Filtro sinterizado de alta temperatura compuesto de hierro magnético suave"},
    {prodId:"31421504",label:"Filtro sinterizado estándar de acero inoxidable"},
    {prodId:"31421505",label:"Filtro sinterizado de alta temperatura de acero inoxidable"},
    {prodId:"31421506",label:"Filtro sinterizado compacto estándar de acero inoxidable"},
    {prodId:"31421507",label:"Filtro sinterizado estándar de níquel cobalto"},
    {prodId:"31421508",label:"Filtro sinterizado de alta temperatura de níquel cobalto"},
    {prodId:"31421509",label:"Filtro sinterizado compacto estándar de níquel cobalto"},
    {prodId:"31421510",label:"Filtro sinterizado estándar de aluminio"},
    {prodId:"31421511",label:"Filtro sinterizado alta temperatura de níquel cobalto"},
    {prodId:"31421512",label:"Filtro sinterizado estándar de base de cobre"},
    {prodId:"31421513",label:"Filtro sinterizado estándar de aleación de titanio"},
    {prodId:"31421514",label:"Filtro sinterizado de alta temperatura de aleación de titanio"},
    {prodId:"31421515",label:"Filtro sinterizado estándar de berilio"},
    {prodId:"31421516",label:"Filtro sinterizado de alta temperatura de berilio"},
    {prodId:"31421517",label:"Filtro sinterizado estándar de metal refractario"},
    {prodId:"31421518",label:"Filtro sinterizado de alta temperatura de metal refractario"},
    {prodId:"31421519",label:"Filtro sinterizado estándar de cerámica"},
    {prodId:"31421520",label:"Filtro sinterizado de alta temperatura de cerámica"},
    {prodId:"31421521",label:"Filtro sinterizado estándar de polvo de hierro puro"},
    {prodId:"31421522",label:"Filtro sinterizado de alta temperatura de polvo de hierro puro"},
    {prodId:"32101500",label:"Conjuntos de circuitos y componentes de radiofrecuencia (RF)"},
    {prodId:"32101502",label:"Montajes de circuitos impresos (pca)"},
    {prodId:"32101503",label:"Conjuntos de circuitos mixtos"},
    {prodId:"32101504",label:"Ensamblajes de circuitos montados en superficie"},
    {prodId:"32101505",label:"Ensamblajes de circuitos electro plateados"},
    {prodId:"32101506",label:"Tarjetas de circuito de doble cara"},
    {prodId:"32101507",label:"Tarjetas de tablero de conectores de circuitos"},
    {prodId:"32101508",label:"Tarjetas de circuito multi capa"},
    {prodId:"32101509",label:"Tarjetas de circuito de una cara"},
    {prodId:"32101510",label:"Tablero de cables impreso"},
    {prodId:"32101512",label:"Demoduladores"},
    {prodId:"32101513",label:"Conjuntos de circuitos de aplicaciones específicas"},
    {prodId:"32101514",label:"Amplificadores"},
    {prodId:"32101515",label:"Atenuadores"},
    {prodId:"32101516",label:"Circuladores"},
    {prodId:"32101517",label:"Acopladores"},
    {prodId:"32101518",label:"Líneas de retardo"},
    {prodId:"32101519",label:"Detectores"},
    {prodId:"32101520",label:"Cargas simuladas"},
    {prodId:"32101521",label:"Filtros de radiofrecuencia (rf)"},
    {prodId:"32101522",label:"Aisladores"},
    {prodId:"32101523",label:"Mezcladores"},
    {prodId:"32101524",label:"Variadores de fase"},
    {prodId:"32101525",label:"Multiplexores"},
    {prodId:"32101526",label:"Amplificadores de tubos de onda"},
    {prodId:"32101527",label:"Terminaciones"},
    {prodId:"32101528",label:"Moduladores"},
    {prodId:"32101529",label:"Divisores o splitters"},
    {prodId:"32101530",label:"Combinado de radio frecuencia rf"},
    {prodId:"32101531",label:"Pad atenuador de red"},
    {prodId:"32101532",label:"Sustrato cerámico"},
    {prodId:"32101533",label:"Transformador de salid"},
    {prodId:"32101534",label:"Trampa de ondas"},
    {prodId:"32101535",label:"Circuito de fase modulada"},
    {prodId:"32101536",label:"Compensador de desbalance de fase y factor de potencia"},
    {prodId:"32101537",label:"Repetidor de sincro"},
    {prodId:"32101538",label:"Estabilizador de impedancia"},
    {prodId:"32101539",label:"Selector de frecuencia"},
    {prodId:"32101540",label:"Resolvedor"},
    {prodId:"32101541",label:"Transmisor de sincro"},
    {prodId:"32101542",label:"Receptor de sincro"},
    {prodId:"32101543",label:"Controlador e indicador de calibre"},
    {prodId:"32101544",label:"Oscilador de frecuencia"},
    {prodId:"32101545",label:"Bobina de antena"},
    {prodId:"32101546",label:"Multiplicador de frecuencia"},
    {prodId:"32101547",label:"Limitador"},
    {prodId:"32101548",label:"Discriminador"},
    {prodId:"32101600",label:"Circuitos integrados"},
    {prodId:"32101601",label:"Memoria de acceso aleatorio (ram)"},
    {prodId:"32101602",label:"Memoria ram dinámica (dram)"},
    {prodId:"32101603",label:"Memoria ram estática (sram)"},
    {prodId:"32101604",label:"Memoria rom programable (prom)"},
    {prodId:"32101605",label:"Memoria rom programable borrable (eprom)"},
    {prodId:"32101606",label:"Memoria rom programable borrable eléctricamente (eeprom)"},
    {prodId:"32101607",label:"Circuitos integrados monolíticos de memoria (mmic)"},
    {prodId:"32101608",label:"Memoria de sólo lectura (rom)"},
    {prodId:"32101609",label:"Circuitos integrados de aplicaciones específicas (asic)"},
    {prodId:"32101611",label:"Lógica de matriz programable (pal)"},
    {prodId:"32101612",label:"Lógica de matriz de puertas (gal)"},
    {prodId:"32101613",label:"Lógica transistor-transistor (ttl)"},
    {prodId:"32101614",label:"Lógica de emisor acoplado (ecl)"},
    {prodId:"32101615",label:"Tecnología mos bipolar (bimos)"},
    {prodId:"32101616",label:"Tecnología cmos bipolar (bicmos)"},
    {prodId:"32101617",label:"Tarjetas inteligentes"},
    {prodId:"32101618",label:"Circuitos integrados ordenados hacia arriba"},
    {prodId:"32101619",label:"Circuitos integrados lineales"},
    {prodId:"32101620",label:"Circuitos integrados digitales"},
    {prodId:"32101621",label:"Memoria dram síncrona (sdram)"},
    {prodId:"32101622",label:"Memoria flash"},
    {prodId:"32101623",label:"Memoria rdram (rambus)"},
    {prodId:"32101624",label:"Memoria sgram"},
    {prodId:"32101625",label:"Circuitos integrados de accionamiento o control por motor"},
    {prodId:"32101626",label:"Microprocesadores"},
    {prodId:"32101627",label:"Osciladores de reloj"},
    {prodId:"32101628",label:"Microcontroladores"},
    {prodId:"32101629",label:"Amplificadores operacionales"},
    {prodId:"32101630",label:"Circuitos integrados de regulador de tensión"},
    {prodId:"32101631",label:"Circuitos integrados de comparador de tensión"},
    {prodId:"32101632",label:"Circuitos integrados de temporizador"},
    {prodId:"32101633",label:"Puertas lógicas"},
    {prodId:"32101634",label:"Circuitos basculantes"},
    {prodId:"32101635",label:"Registros de desplazamiento"},
    {prodId:"32101636",label:"Procesador de señal digital (dsp)"},
    {prodId:"32101637",label:"Procesadores de red"},
    {prodId:"32101638",label:"Circuito integrado de aplicación específica"},
    {prodId:"32101639",label:"Microcontrolador de 8 bits"},
    {prodId:"32101640",label:"Microcontrolador de 16 bits"},
    {prodId:"32101641",label:"Procesador de señal digital de extremo bajo dsp"},
    {prodId:"32101642",label:"Microcontrolador de rango medio de 32 bits"},
    {prodId:"32101643",label:"Circuito integrado lógico programable"},
    {prodId:"32101644",label:"Procesador de señal digital de extremo alto dsp"},
    {prodId:"32101645",label:"Microcontrolador de rango alto de 32 bits"},
    {prodId:"32101646",label:"Memoria flash paralelo (nor)"},
    {prodId:"32101647",label:"Memoria flash serial (nor)"},
    {prodId:"32101648",label:"Memoria flash (nand)"},
    {prodId:"32101649",label:"Circuito integrado estándar análogo o lineal"},
    {prodId:"32101650",label:"Circuito integrado de amplificador de audio análogo"},
    {prodId:"32101651",label:"Circuito integrado de amplificador de audio digital"},
    {prodId:"32101652",label:"Circuito integrado lógico estándar"},
    {prodId:"32101653",label:"Circuito integrado microondas"},
    {prodId:"32101654",label:"Circuito integrado bus transceptor"},
    {prodId:"32101655",label:"Circuito integrado de servicio de radio audio digital satelital"},
    {prodId:"32101656",label:"Circuito integrado de sistema de posicionamiento geográfico gps"},
    {prodId:"32101657",label:"Circuito integrado de sintonizador"},
    {prodId:"32101658",label:"Circuito integrado de acelerador de gráficos"},
    {prodId:"32101659",label:"Circuito integrado de exhibición de drivers"},
    {prodId:"32101660",label:"Circuito integrado de sensor de tasa angular"},
    {prodId:"32101661",label:"Circuito integrado acelerómetro"},
    {prodId:"32101662",label:"Circuito integrado efecto hall"},
    {prodId:"32101663",label:"Circuito integrado sensor temperatura infrarroja ir"},
    {prodId:"32101664",label:"Circuito integrado ultrasónico"},
    {prodId:"32101665",label:"Circuito integrado de sensor de imagen"},
    {prodId:"32101666",label:"Circuito integrado de procesador de videos o media"},
    {prodId:"32101667",label:"Circuito integrado de procesador de visión"},
    {prodId:"32101668",label:"Circuito integrado condecs"},
    {prodId:"32101669",label:"Circuito integrado de protocolo de comunicación"},
    {prodId:"32101670",label:"Circuito integrado de transponedor"},
    {prodId:"32101671",label:"Circuito integrado de sensor de presión"},
    {prodId:"32101672",label:"Bastidor de conductores"},
    {prodId:"32111500",label:"Diodos"},
    {prodId:"32111501",label:"Diodos de microondas"},
    {prodId:"32111502",label:"Diodos zener"},
    {prodId:"32111503",label:"Diodos emisores de luz (led)"},
    {prodId:"32111504",label:"Diodos schottky"},
    {prodId:"32111505",label:"Diodos con efecto túnel"},
    {prodId:"32111506",label:"Diodos fotosensibles"},
    {prodId:"32111507",label:"Diodos de capacitancia variable"},
    {prodId:"32111508",label:"Diodos solares"},
    {prodId:"32111509",label:"Diodos de energía"},
    {prodId:"32111510",label:"Diodos de radiofrecuencia (rf)"},
    {prodId:"32111511",label:"Diodos de señal pequeña"},
    {prodId:"32111512",label:"Diodo láser"},
    {prodId:"32111513",label:"Diodo varactor"},
    {prodId:"32111514",label:"Diodo pin"},
    {prodId:"32111515",label:"Diodo óptico"},
    {prodId:"32111600",label:"Transistores"},
    {prodId:"32111601",label:"Transistores fotosensibles"},
    {prodId:"32111602",label:"Transistor de efecto de campo (fet)"},
    {prodId:"32111603",label:"Transistores mos de efecto de campo (mosfet)"},
    {prodId:"32111604",label:"Chips de transistor"},
    {prodId:"32111607",label:"Transistores bipolares de radiofrecuencia (rf) o darlington"},
    {prodId:"32111608",label:"Transistores uniempalme"},
    {prodId:"32111609",label:"Transistores bipolares de puerta aislada (igbt)"},
    {prodId:"32111610",label:"Transistores de efecto de campo de unión (jfet)"},
    {prodId:"32111611",label:"Transistores bipolares de unión (bjt)"},
    {prodId:"32111612",label:"Transistor de efecto de campo de potencia"},
    {prodId:"32111613",label:"Transistor bipolar o de radio frecuencia bipolar"},
    {prodId:"32111614",label:"Transistor de efecto de campo inteligente"},
    {prodId:"32111615",label:"Transistor de efecto de campo de señal pequeña"},
    {prodId:"32111616",label:"Transistor de efecto de campo de radio frecuencia"},
    {prodId:"32111700",label:"Aparatos semiconductores"},
    {prodId:"32111701",label:"Células fotovoltaicas"},
    {prodId:"32111702",label:"Tiristores"},
    {prodId:"32111703",label:"Diacs"},
    {prodId:"32111704",label:"Triacs"},
    {prodId:"32111705",label:"Aisladores acoplados ópticos"},
    {prodId:"32111706",label:"Osciladores a cristal"},
    {prodId:"32111707",label:"Suspector de semiconductor"},
    {prodId:"32111708",label:"Red de adaptación de impedancia"},
    {prodId:"32111709",label:"Red de compensación de temperatura"},
    {prodId:"32121500",label:"Capacitores"},
    {prodId:"32121501",label:"Capacitores fijos"},
    {prodId:"32121502",label:"Capacitores o varactores variables"},
    {prodId:"32121503",label:"Capacitores ajustables pre - ajustados"},
    {prodId:"32121504",label:"Redes de capacitores"},
    {prodId:"32121505",label:"Capacitor fijo de aluminio electrolítico"},
    {prodId:"32121506",label:"Capacitor cerámico fijo"},
    {prodId:"32121507",label:"Capacitor de película fijo"},
    {prodId:"32121508",label:"Capacitor fijo de tántalo"},
    {prodId:"32121509",label:"Capacitor de aire"},
    {prodId:"32121510",label:"Capacitor de gas"},
    {prodId:"32121511",label:"Capacitor de aceite"},
    {prodId:"32121512",label:"Capacitor de mica"},
    {prodId:"32121513",label:"Capacitor de vacío"},
    {prodId:"32121514",label:"Capacitor de papel"},
    {prodId:"32121515",label:"Capacitor de papel metalizado"},
    {prodId:"32121600",label:"Resistores"},
    {prodId:"32121602",label:"Resistores fusibles"},
    {prodId:"32121603",label:"Resistores o varistores variables"},
    {prodId:"32121607",label:"Redes de resistores"},
    {prodId:"32121609",label:"Resistores fijos"},
    {prodId:"32121610",label:"Termistor"},
    {prodId:"32121611",label:"Placa restrictora o restrictor de aire"},
    {prodId:"32121612",label:"Resistor montado en superficie"},
    {prodId:"32121613",label:"Resistor embobinado"},
    {prodId:"32121614",label:"Resistor de óxido de película de metal"},
    {prodId:"32121615",label:"Resistor de película de carbono"},
    {prodId:"32121616",label:"Resistor recortador"},
    {prodId:"32121617",label:"Resistor de potencia"},
    {prodId:"32121618",label:"Resistor de película de metal"},
    {prodId:"32121700",label:"Componentes discretos"},
    {prodId:"32121701",label:"Rectificadores"},
    {prodId:"32121702",label:"Inductores"},
    {prodId:"32121703",label:"Ferritas"},
    {prodId:"32121704",label:"Convertidores estáticos"},
    {prodId:"32121705",label:"Inversores"},
    {prodId:"32121706",label:"Redes r/c de resistores o capacitores"},
    {prodId:"32121707",label:"Rectificador controlado de silicona"},
    {prodId:"32121708",label:"Rectificador de puente"},
    {prodId:"32121709",label:"Inductor de embobinado"},
    {prodId:"32121710",label:"Inductor multi capa"},
    {prodId:"32121711",label:"Inductor de estrangulador"},
    {prodId:"32121712",label:"Bobina de alta frecuencia"},
    {prodId:"32131000",label:"Accesorios, materias primas y piezas de componentes electrónicos"},
    {prodId:"32131001",label:"Piletas térmicas"},
    {prodId:"32131002",label:"Matrices de semiconductor"},
    {prodId:"32131003",label:"Pastillas de semiconductor"},
    {prodId:"32131005",label:"Paquetes de circuitos integrados"},
    {prodId:"32131006",label:"Soportes o zócalos de circuito integrado"},
    {prodId:"32131007",label:"Soportes de componentes discretos"},
    {prodId:"32131008",label:"Compuestos disipadores de calor"},
    {prodId:"32131009",label:"Aisladores para disipadores de calor"},
    {prodId:"32131010",label:"Tarjetas sencillas de circuitos impresos"},
    {prodId:"32131011",label:"Cubiertas de circuitos integrados"},
    {prodId:"32131012",label:"Objetivos de erosión superficial"},
    {prodId:"32131013",label:"Máscara de foto"},
    {prodId:"32131014",label:"Tablero de circuito impreso flexible, desnudo"},
    {prodId:"32131015",label:"Tablero de circuito impreso por un solo lado, desnudo"},
    {prodId:"32131016",label:"Tablero de circuito impreso por un ambos lados, desnudo"},
    {prodId:"32131017",label:"Tablero de circuito impreso multicapa, desnudo"},
    {prodId:"32131018",label:"Tablero de circuito impreso con capa pesada de bronce, desnudo"},
    {prodId:"32131019",label:"Tablero de circuito impreso electroplateado con oro, desnudo"},
    {prodId:"32131020",label:"Tablero de circuito impreso ensamblado flexible"},
    {prodId:"32131021",label:"Sustrato cerámico combustión combinada a baja temperatura"},
    {prodId:"32131022",label:"Cerámica para sustratos de tableros de circuitos impresos pcb"},
    {prodId:"32131023",label:"Soporte de dispositivos eléctricos o electrónicos"},
    {prodId:"32141000",label:"Tubos electrónicos"},
    {prodId:"32141001",label:"Tubos de rayos catódicos"},
    {prodId:"32141002",label:"Klistrones"},
    {prodId:"32141003",label:"Magnetrones"},
    {prodId:"32141004",label:"Tubos de ondas progresivas"},
    {prodId:"32141005",label:"Tubos de disco sellado"},
    {prodId:"32141006",label:"Resnatrones"},
    {prodId:"32141007",label:"Tiratrones"},
    {prodId:"32141008",label:"Ignitrones"},
    {prodId:"32141009",label:"Tubos fotoeléctricos"},
    {prodId:"32141010",label:"Tubos fotomultiplicadores"},
    {prodId:"32141011",label:"Tubos receptores de televisión o cámara"},
    {prodId:"32141012",label:"Tubo de diodo"},
    {prodId:"32141013",label:"Tubo de tríodo"},
    {prodId:"32141014",label:"Tubos de tetrodo"},
    {prodId:"32141015",label:"Tubos de pentodo"},
    {prodId:"32141016",label:"Tubos múltiples"},
    {prodId:"32141017",label:"Tubo contador"},
    {prodId:"32141018",label:"Tubo de rayo de salida"},
    {prodId:"32141019",label:"Tubo electrón de microondas"},
    {prodId:"32141020",label:"Tubo convertidor de frecuencia"},
    {prodId:"32141021",label:"Tubo rectificador"},
    {prodId:"32141022",label:"Tubo regulador electrónico de voltaje"},
    {prodId:"32141100",label:"Piezas y accesorios de tubos electrónicos"},
    {prodId:"32141101",label:"Cátodos o emisores"},
    {prodId:"32141102",label:"Elementos de ánodo"},
    {prodId:"32141103",label:"Elementos de rejilla"},
    {prodId:"32141104",label:"Elementos deflectores"},
    {prodId:"32141105",label:"Obturadores o fundas de tubo"},
    {prodId:"32141106",label:"Bases de tubo"},
    {prodId:"32141107",label:"Zócalos de tubo"},
    {prodId:"32141108",label:"Clavijas de electrodo"},
    {prodId:"32141109",label:"Portaelectrodos"},
    {prodId:"32141110",label:"Pieza de polo magnético"},
    {prodId:"32151500",label:"Dispositivos de control de indicación y de señalización"},
    {prodId:"32151501",label:"Módulo de sonido de control"},
    {prodId:"32151502",label:"Módulo de luz"},
    {prodId:"32151503",label:"Apilamiento de luz"},
    {prodId:"32151504",label:"Ecualizador de circuito electrónico"},
    {prodId:"32151600",label:"Subsistemas programables de controlador lógico"},
    {prodId:"32151601",label:"Subsistema i/o chasis controlador lógico programable"},
    {prodId:"32151602",label:"Subsistema i/o chasis controlador lógico programable distribuido en gabinete"},
    {prodId:"32151603",label:"Subsistema i/o chasis controlador lógico programable distribuido en la máquina"},
    {prodId:"32151700",label:"Controladores lógicos programables"},
    {prodId:"32151701",label:"Dispositivo de unión control de red"},
    {prodId:"32151702",label:"Interfaz pc red control"},
    {prodId:"32151703",label:"Accesorios de controlador lógico programable"},
    {prodId:"32151704",label:"Chasis de controlador lógico programable"},
    {prodId:"32151705",label:"Módulo de controlador lógico programable"},
    {prodId:"32151706",label:"Suministro de energía de controlador lógico programable"},
    {prodId:"32151707",label:"Dispositivo de programación del controlador lógico programable"},
    {prodId:"32151800",label:"Dispositivos de control de seguridad"},
    {prodId:"32151801",label:"Interruptor de carga iec"},
    {prodId:"32151802",label:"Módulo de control de seguridad"},
    {prodId:"32151803",label:"Sistema de aislación de seguridad"},
    {prodId:"32151804",label:"Cortina y scanner de luz de seguridad"},
    {prodId:"32151805",label:"Estera (mat) y borde de seguridad"},
    {prodId:"32151900",label:"Dispositivos de automatización de control de la conectividad"},
    {prodId:"32151901",label:"Conector de paso de mamparo"},
    {prodId:"32151902",label:"Conjunto de cables del sistema de control"},
    {prodId:"32151903",label:"Caja de distribución del sistema de control"},
    {prodId:"32151904",label:"Cable de conexión del sistema de control"},
    {prodId:"32151905",label:"Receptáculo del sistema de control"},
    {prodId:"32151906",label:"Splitter del sistema de control"},
    {prodId:"32151907",label:"Cable en y del sistema de control"},
    {prodId:"32151908",label:"Cableado del sistema de control"},
    {prodId:"32151909",label:"Conector desmontable de campo"},
    {prodId:"32151910",label:"Sistema de control del panel de paso"},
    {prodId:"39101600",label:"Lámparas y bombillas"},
    {prodId:"39101601",label:"Lámparas halógenas"},
    {prodId:"39101602",label:"Lámparas médicas"},
    {prodId:"39101603",label:"Lámparas solares"},
    {prodId:"39101605",label:"Lámparas fluorescentes"},
    {prodId:"39101608",label:"Luz asómbrica o scialytica de operación"},
    {prodId:"39101609",label:"Lámparas de escenario o estudio"},
    {prodId:"39101612",label:"Lámparas incandescentes"},
    {prodId:"39101613",label:"Lámparas infrarrojas"},
    {prodId:"39101614",label:"Lámparas de haluro- metálico"},
    {prodId:"39101615",label:"Lámparas de vapor de mercurio"},
    {prodId:"39101616",label:"Lámparas de rayos ultravioleta (uv)"},
    {prodId:"39101617",label:"Lámparas de sodio de alta presión hid"},
    {prodId:"39101618",label:"Lámparas de neón"},
    {prodId:"39101619",label:"Lámparas compactas fluorescentes cfl"},
    {prodId:"39101620",label:"Lámparas de inducción"},
    {prodId:"39101621",label:"Lámparas de sodio de baja presión hid"},
    {prodId:"39101622",label:"Lámparas en miniatura"},
    {prodId:"39101623",label:"Lámparas de rayo sellado"},
    {prodId:"39101624",label:"Luz negra"},
    {prodId:"39101625",label:"Lámparas de xenón"},
    {prodId:"39101626",label:"Lámparas de criptón"},
    {prodId:"39101627",label:"Lámparas de arco"},
    {prodId:"39101800",label:"Componentes y accesorios de Lámpara"},
    {prodId:"39101801",label:"Filamento de lámpara"},
    {prodId:"39101802",label:"Vidrio de lámpara"},
    {prodId:"39101803",label:"Base de lámpara"},
    {prodId:"39101804",label:"Ignitor de lámpara de sodio de alta presión"},
    {prodId:"39101805",label:"Capacitor seco de haluro de metal"},
    {prodId:"39101806",label:"Capacitor seco de lámpara de sodio de alta presión"},
    {prodId:"39101900",label:"Balastos de lámparas y transformadores de lámparas"},
    {prodId:"39101901",label:"Balasto fluorescente"},
    {prodId:"39101902",label:"Balasto de alta intensidad de descarga hid"},
    {prodId:"39101903",label:"Sistema de inducción de iluminación"},
    {prodId:"39101904",label:"Transformador de iluminación de bajo voltaje"},
    {prodId:"39101905",label:"Balasto de neón"},
    {prodId:"39101906",label:"Transformador de lámpara reductora"},
    {prodId:"39101907",label:"Balasto de alta intensidad de descarga  electrónica ehid"},
    {prodId:"39111500",label:"Iluminación de interiores y artefactos"},
    {prodId:"39111501",label:"Artefactos fluorescentes"},
    {prodId:"39111503",label:"Dispositivos de pared"},
    {prodId:"39111504",label:"Sistemas de iluminación de escenario o estudio"},
    {prodId:"39111505",label:"Iluminación empotrada"},
    {prodId:"39111506",label:"Arañas de luces"},
    {prodId:"39111507",label:"Artefactos de escritorio"},
    {prodId:"39111508",label:"Alumbrado de pista"},
    {prodId:"39111509",label:"Lámparas de pie"},
    {prodId:"39111510",label:"Lámparas de mesa"},
    {prodId:"39111512",label:"Luces de banco de laboratorio"},
    {prodId:"39111515",label:"Artefactos para lámpara proyectada hacia abajo"},
    {prodId:"39111520",label:"Artefactos de alumbrado halógeno"},
    {prodId:"39111521",label:"Plafones"},
    {prodId:"39111522",label:"Iluminación colgante"},
    {prodId:"39111524",label:"Accesorio de alta intensidad de descarga hid"},
    {prodId:"39111525",label:"Accesorio incandescente"},
    {prodId:"39111527",label:"Accesorio de iluminación solar"},
    {prodId:"39111528",label:"Accesorio para debajo de gabinete"},
    {prodId:"39111529",label:"Gobo de iluminación"},
    {prodId:"39111530",label:"Accesorio para lámpara proyectada hacia abajo"},
    {prodId:"39111531",label:"Orientación para iluminación comercial"},
    {prodId:"39111532",label:"Accesorio fluorescente para grandes áreas"},
    {prodId:"39111533",label:"Banda de iluminación"},
    {prodId:"39111534",label:"Luminaria fluorescente con lente"},
    {prodId:"39111535",label:"Accesorio parabólico"},
    {prodId:"39111536",label:"Iluminador de tocador"},
    {prodId:"39111537",label:"Dispositivo para iluminaciones deportivas"},
    {prodId:"39111538",label:"Dispositivo para iluminaciones de garaje o marquesina"},
    {prodId:"39111539",label:"Caja de iluminación"},
    {prodId:"39111540",label:"Sistema de iluminación de auto - elevación"},
    {prodId:"39111541",label:"Accesorio de dispositivo de iluminación interior"},
    {prodId:"39111600",label:"Iluminación exterior y artefactos"},
    {prodId:"39111603",label:"Alumbrado de la vía pública"},
    {prodId:"39111605",label:"Iluminación paisajística"},
    {prodId:"39111606",label:"Alumbrado submarino"},
    {prodId:"39111608",label:"Alumbrado de zonas residenciales"},
    {prodId:"39111609",label:"Linternas de queroseno, propano o butano"},
    {prodId:"39111610",label:"Linternas"},
    {prodId:"39111611",label:"Reflectores"},
    {prodId:"39111612",label:"Iluminación de áreas"},
    {prodId:"39111613",label:"Iluminación de seguridad"},
    {prodId:"39111614",label:"Dispositivo de iluminación de buzones"},
    {prodId:"39111615",label:"Luces exteriores para iluminación puntual instaladas en el suelo"},
    {prodId:"39111616",label:"Accesorios de iluminación exterior"},
    {prodId:"39111700",label:"Alumbrado de emergencia"},
    {prodId:"39111703",label:"Luces de tormenta"},
    {prodId:"39111705",label:"Barras fluorescentes o de iluminación"},
    {prodId:"39111706",label:"Luces de emergencia o estroboscópicas (licuadoras)"},
    {prodId:"39111707",label:"Unidad de salida de combo de luz"},
    {prodId:"39111708",label:"Señal iluminada de salida de emergencia"},
    {prodId:"39111709",label:"Unidad de luz de emergencia"},
    {prodId:"39111710",label:"Accesorios de iluminación de emergencia"},
    {prodId:"39111711",label:"Kit de conversión de salida de luz"},
    {prodId:"39111712",label:"Galería de montaje de luz de salida"},
    {prodId:"39111713",label:"Dispositivo de iluminación remota"},
    {prodId:"39111714",label:"Bengala de señalización"},
    {prodId:"39111800",label:"Accesorios de iluminación"},
    {prodId:"39111801",label:"Balastos de lámparas"},
    {prodId:"39111802",label:"Cubiertas de lámpara"},
    {prodId:"39111803",label:"Enchufes de lámparas"},
    {prodId:"39111806",label:"Cajas de iluminación"},
    {prodId:"39111808",label:"Parrillas"},
    {prodId:"39111809",label:"Filtros acondicionadores de luz"},
    {prodId:"39111810",label:"Interruptor de lámpara"},
    {prodId:"39111811",label:"Líneas férreas electrificadas"},
    {prodId:"39111812",label:"Pantallas de lámparas"},
    {prodId:"39111813",label:"Brazos de lámparas"},
    {prodId:"39111814",label:"Kit de brida de iluminación de marco de cielorraso"},
    {prodId:"39111815",label:"Protector de lámpara y de dispositivo de lámpara"},
    {prodId:"39111816",label:"Lente de lámpara"},
    {prodId:"39111817",label:"Accesorio para montaje de lámpara"},
    {prodId:"39111818",label:"Reflector de lámpara"},
    {prodId:"39111819",label:"Accesorio de seguridad de lámpara"},
    {prodId:"39111820",label:"Kit de ensamble de cableado de lámpara"},
    {prodId:"39111821",label:"Kit de retro - adaptación de iluminación"},
    {prodId:"39111822",label:"Soporte de pared para iluminación"},
    {prodId:"39111823",label:"Tapa para poste de luz"},
    {prodId:"39111824",label:"Lente difusor de iluminación"},
    {prodId:"39111825",label:"Lámpara con bisagras"},
    {prodId:"39111826",label:"Juego de cables de iluminación"},
    {prodId:"39111827",label:"Reflector o redireccionador de iluminación"},
    {prodId:"39111828",label:"Dispositivo de puerta de iluminación"},
    {prodId:"39111829",label:"Cubierta del canal de iluminación"},
    {prodId:"39111830",label:"Brazo de soporte de iluminación"},
    {prodId:"39111900",label:"Señalización e iluminación de emplazamientos peligrosos"},
    {prodId:"39111903",label:"Iluminación de minería subterránea"},
    {prodId:"39111904",label:"Dispositivo de ubicación marina"},
    {prodId:"39111905",label:"Dispositivo de iluminación de sala blanca"},
    {prodId:"39111906",label:"Dispositivo de luz de servicio en condiciones difíciles"},
    {prodId:"39111907",label:"Dispositivo de luz hermética de vapor"},
    {prodId:"39111908",label:"Dispositivo de iluminación protector contra vandalismo"},
    {prodId:"39111909",label:"Accesorio de dispositivo de iluminación de ambiente especial"},
    {prodId:"39112000",label:"Proyectores móviles"},
    {prodId:"39112001",label:"Torre de luz"},
    {prodId:"39112002",label:"Carro de iluminación"},
    {prodId:"39112003",label:"Stand de iluminación"},
    {prodId:"39112004",label:"Luz para puertos de cargue"},
    {prodId:"39112005",label:"Luz fluorescente portátil"},
    {prodId:"39112006",label:"Lámpara de mano portátil"},
    {prodId:"39112007",label:"Lámpara de mano portátil de descarga de alta intensidad hid"},
    {prodId:"39112008",label:"Luz incandescente portátil"},
    {prodId:"39112009",label:"Luz de mecánicos"},
    {prodId:"39112010",label:"Luz de cuerda"},
    {prodId:"39112011",label:"Árbol o hilo de luz"},
    {prodId:"39112012",label:"Luz manual o extensión"},
    {prodId:"39112013",label:"Accesorio de iluminación portátil y temporal"},
    {prodId:"39112100",label:"Iluminación óptica"},
    {prodId:"39112101",label:"Iluminación de fibra óptica"},
    {prodId:"39112102",label:"Iluminación óptica de diodo emisor de luz led"},
    {prodId:"39112200",label:"Dispositivos de efectos especiales"},
    {prodId:"39112201",label:"Aparato de efecto atmosférico"},
    {prodId:"39112202",label:"Máquina de burbujas"},
    {prodId:"39112300",label:"Iluminación y accesorios de escenarios y estudios"},
    {prodId:"39112301",label:"Cambiador de color"},
    {prodId:"39112302",label:"Filtros de color"},
    {prodId:"39112303",label:"Soportes y marcos de filtros"},
    {prodId:"39112304",label:"Soportes gobo"},
    {prodId:"39112305",label:"Accesorios de rotadores gobo y efecto de movimiento de luz"},
    {prodId:"39112306",label:"Diafragma iris"},
    {prodId:"39112307",label:"Barras de iluminación"},
    {prodId:"39112308",label:"Sillas iluminadas"},
    {prodId:"39112309",label:"Bola de disco o bola de espejos"},
    {prodId:"39112400",label:"Dispositivos para manejo de luces y control de escenarios y estudios"},
    {prodId:"39112401",label:"Dimers y accesorios"},
    {prodId:"39112402",label:"Consolas y accesorios para el control de iluminación"},
    {prodId:"39112403",label:"Suministro de energía y unidades de control de iluminación"},
    {prodId:"39112500",label:"Luminarias de escenarios y estudios"},
    {prodId:"39112501",label:"Luz de foco"},
    {prodId:"39112502",label:"Luz de seguir"},
    {prodId:"39112503",label:"Luces móviles"},
    {prodId:"39112504",label:"Spots de latas par y pasadores"},
    {prodId:"39112505",label:"Luminarias tipo perfil"},
    {prodId:"39112506",label:"Bancos spot"},
    {prodId:"39112507",label:"Luces de franjas"},
    {prodId:"39112508",label:"Luminarias ultravioleta y de luz negra"},
    {prodId:"39112600",label:"Dispositivos de iluminación no - eléctrica"},
    {prodId:"39112601",label:"Lámpara de alcohol"},
    {prodId:"39112602",label:"Candelero"},
    {prodId:"39112603",label:"Lámpara de kerosene o de propano o de gas natural o de butano"},
    {prodId:"39112604",label:"Velas de cera"},
    {prodId:"39121000",label:"Equipamiento para distribución y conversión de alimentación"},
    {prodId:"39121001",label:"Transformadores de distribución de potencia"},
    {prodId:"39121002",label:"Transformadores de suministro de potencia"},
    {prodId:"39121003",label:"Transformadores de instrumentos"},
    {prodId:"39121004",label:"Unidades de suministro de energía"},
    {prodId:"39121006",label:"Adaptadores o inversores de potencia"},
    {prodId:"39121007",label:"Conversores de frecuencia"},
    {prodId:"39121008",label:"Conversores de señales"},
    {prodId:"39121009",label:"Reguladores eléctricos o de potencia"},
    {prodId:"39121010",label:"Bobinas magnéticas"},
    {prodId:"39121011",label:"Fuentes ininterrumpibles de potencia"},
    {prodId:"39121012",label:"Estrangulador"},
    {prodId:"39121013",label:"Convertidores rotativos eléctricos"},
    {prodId:"39121014",label:"Bancos de capacitores"},
    {prodId:"39121015",label:"Reactores"},
    {prodId:"39121016",label:"Anillos colectores"},
    {prodId:"39121017",label:"Unidades de distribución de alimentación (pdus)"},
    {prodId:"39121018",label:"Barreras de seguridad intrínseca"},
    {prodId:"39121019",label:"Dispositivos de acoplamiento por inducción"},
    {prodId:"39121020",label:"Acondicionadores de señal"},
    {prodId:"39121021",label:"Unidades servo"},
    {prodId:"39121022",label:"Transformadores electrónicos"},
    {prodId:"39121023",label:"Compensadores var estática"},
    {prodId:"39121024",label:"Condensadores síncronos"},
    {prodId:"39121025",label:"Transformadores buck - boost"},
    {prodId:"39121026",label:"Transformadores de control de potencia"},
    {prodId:"39121027",label:"Transformador encapsulado"},
    {prodId:"39121028",label:"Transformador de mitigación armónica"},
    {prodId:"39121029",label:"Transformador de aislación"},
    {prodId:"39121030",label:"Transformador montado en pad"},
    {prodId:"39121031",label:"Strip de salida del suministro eléctrico"},
    {prodId:"39121032",label:"Transformador de corriente"},
    {prodId:"39121033",label:"Transformador potencial"},
    {prodId:"39121034",label:"Transformador de corriente de fase cero"},
    {prodId:"39121035",label:"Compensador de arranque de motor"},
    {prodId:"39121036",label:"Transformador de radio frecuencia rf"},
    {prodId:"39121037",label:"Transformador de limite"},
    {prodId:"39121038",label:"Yugo deflector"},
    {prodId:"39121039",label:"Suministro de potencia de celda de combustible"},
    {prodId:"39121040",label:"Ahorrador de energía eléctrica"},
    {prodId:"39121041",label:"Transformador de voltaje constante"},
    {prodId:"39121042",label:"Transformador de filamento"},
    {prodId:"39121043",label:"Transformador rotativo"},
    {prodId:"39121044",label:"Transformador de audio frecuencia"},
    {prodId:"39121045",label:"Transformador de modulación"},
    {prodId:"39121046",label:"Transformador para igualar impedancia"},
    {prodId:"39121047",label:"Transformador de frecuencia intermedia"},
    {prodId:"39121100",label:"Centros de control y distribución y accesorios"},
    {prodId:"39121101",label:"Centros de carga"},
    {prodId:"39121102",label:"Tomas o centros de medidores"},
    {prodId:"39121103",label:"Paneles"},
    {prodId:"39121104",label:"Centros de control de motor"},
    {prodId:"39121105",label:"Sistemas de conmutadores"},
    {prodId:"39121106",label:"Sistemas de control o vigilancia de potencia"},
    {prodId:"39121107",label:"Sistemas de control de iluminación"},
    {prodId:"39121108",label:"Accesorios del panel de control o distribución"},
    {prodId:"39121109",label:"Transformadores de transmisión"},
    {prodId:"39121110",label:"Tablero de interruptor de circuito"},
    {prodId:"39121111",label:"Tablero de fusibles"},
    {prodId:"39121112",label:"Tablero de bajo voltaje de corriente alterna y directa ac dc"},
    {prodId:"39121113",label:"Centro de control de motor de bajo voltaje"},
    {prodId:"39121114",label:"Centro de control de motor de medio voltaje"},
    {prodId:"39121115",label:"Tablero de controles de medio voltaje"},
    {prodId:"39121116",label:"Tablero de bajo voltaje"},
    {prodId:"39121117",label:"Bus bar"},
    {prodId:"39121300",label:"Cuadros, registros y menaje para electricidad"},
    {prodId:"39121301",label:"Cerramientos del panel de control o distribución"},
    {prodId:"39121302",label:"Placas o cubiertas de cerramiento"},
    {prodId:"39121303",label:"Cajas eléctricas"},
    {prodId:"39121304",label:"Cubiertas de cajas eléctricas"},
    {prodId:"39121305",label:"Cajas a prueba de intemperie"},
    {prodId:"39121306",label:"Cajas de conmutadores"},
    {prodId:"39121307",label:"Cajas de suelo"},
    {prodId:"39121308",label:"Cajas de toma de corriente"},
    {prodId:"39121309",label:"Cajas eléctricas especiales"},
    {prodId:"39121310",label:"Cajas de uso general"},
    {prodId:"39121311",label:"Accesorios eléctricos"},
    {prodId:"39121312",label:"Bujes eléctricos"},
    {prodId:"39121313",label:"Bridas de techo"},
    {prodId:"39121314",label:"Cerramiento a prueba de explosión"},
    {prodId:"39121315",label:"Caja de dispositivos"},
    {prodId:"39121316",label:"Caja de cielorraso"},
    {prodId:"39121317",label:"Platillo de cielorraso"},
    {prodId:"39121318",label:"Cerramiento del interruptor de circuito"},
    {prodId:"39121319",label:"Cerramiento de transformador de corriente"},
    {prodId:"39121320",label:"Extensión de caja eléctrica"},
    {prodId:"39121321",label:"Ferretería y accesorios de caja eléctrica"},
    {prodId:"39121322",label:"Partición de caja eléctrica"},
    {prodId:"39121323",label:"Cerramiento de consola eléctrica"},
    {prodId:"39121324",label:"Cerramiento eléctrico de propósito general"},
    {prodId:"39121325",label:"Cerramiento de unidad de interfaz de operador eléctrico"},
    {prodId:"39121326",label:"Cerramiento de botón eléctrico"},
    {prodId:"39121327",label:"Cerramiento de terminal eléctrico"},
    {prodId:"39121328",label:"Caja de perforación calificada de fuego"},
    {prodId:"39121329",label:"Gabinete de fusibles"},
    {prodId:"39121330",label:"Caja de distribución eléctrica"},
    {prodId:"39121331",label:"Caja de ubicación peligrosa"},
    {prodId:"39121332",label:"Caja de unión eléctrica"},
    {prodId:"39121333",label:"Caja de mampostería"},
    {prodId:"39121334",label:"Cerramiento eléctrico modular"},
    {prodId:"39121335",label:"Gabinete de terminaciones telefónicas"},
    {prodId:"39121336",label:"Cerramiento eléctrico subterráneo"},
    {prodId:"39121337",label:"Compuesto de sellamiento eléctrico"},
    {prodId:"39121400",label:"Lengüetas de conexión, conectadores y terminales"},
    {prodId:"39121402",label:"Enchufes eléctricos"},
    {prodId:"39121403",label:"Enchufe de bloqueo"},
    {prodId:"39121404",label:"Manguitos eléctricos"},
    {prodId:"39121405",label:"Terminales de cable o alambre"},
    {prodId:"39121406",label:"Receptáculos eléctricos"},
    {prodId:"39121407",label:"Strips de conexiones"},
    {prodId:"39121408",label:"Conexiones mecánicas"},
    {prodId:"39121409",label:"Conectores de cables eléctricos"},
    {prodId:"39121410",label:"Bloques de terminales"},
    {prodId:"39121412",label:"Conectores de soporte posterior"},
    {prodId:"39121413",label:"Conectores circulares"},
    {prodId:"39121414",label:"Conectores coaxiales"},
    {prodId:"39121415",label:"Conectores planos"},
    {prodId:"39121416",label:"Tapas de conectores eléctricos"},
    {prodId:"39121419",label:"Conexiones flexibles"},
    {prodId:"39121420",label:"Conectores herméticos"},
    {prodId:"39121421",label:"Ensambles de conectores"},
    {prodId:"39121423",label:"Conectores de resorte"},
    {prodId:"39121424",label:"Tapas del bloque de terminales"},
    {prodId:"39121425",label:"Separador de tablero de terminales"},
    {prodId:"39121426",label:"Puente de conexión"},
    {prodId:"39121427",label:"Bobinadoras de cable"},
    {prodId:"39121428",label:"Bobinadoras eléctricas"},
    {prodId:"39121431",label:"Conectores estancos de cables"},
    {prodId:"39121432",label:"Terminales eléctricos"},
    {prodId:"39121433",label:"Conectores de radiofrecuencia (rf)"},
    {prodId:"39121434",label:"Conectores de tubos metálicos eléctricos (emt)"},
    {prodId:"39121435",label:"Hilos o cables de conexión"},
    {prodId:"39121436",label:"Electrodos"},
    {prodId:"39121437",label:"Patines de toma de corriente"},
    {prodId:"39121438",label:"Conector automático de hilos o cables"},
    {prodId:"39121440",label:"Cable de extensión eléctrica"},
    {prodId:"39121441",label:"Cable de puente eléctrico"},
    {prodId:"39121442",label:"Ensamble de puerto eléctrico"},
    {prodId:"39121443",label:"Conector de bus subterráneo eléctrico"},
    {prodId:"39121444",label:"Enchufe y módulo rj"},
    {prodId:"39121445",label:"Torsión en conector de cable"},
    {prodId:"39121446",label:"Equipo conector de terminal de cable"},
    {prodId:"39121447",label:"Desconexión de terminal de cable"},
    {prodId:"39121448",label:"Conector de cable o alambre de compresión"},
    {prodId:"39121449",label:"Empalme de cable o alambre de compresión"},
    {prodId:"39121450",label:"Cabeza de martillo"},
    {prodId:"39121451",label:"Conectores tap off"},
    {prodId:"39121452",label:"Unión de cable"},
    {prodId:"39121453",label:"Unión de cangrejo"},
    {prodId:"39121454",label:"Terminal de batería"},
    {prodId:"39121455",label:"Conector de molde de inserción eléctrica"},
    {prodId:"39121456",label:"Conector de subestación"},
    {prodId:"39121457",label:"Conector exotérmico"},
    {prodId:"39121458",label:"Reductor de compresión de cable o alambre"},
    {prodId:"39121459",label:"Placa de circuito impreso pcb con prensa conector de cabecera"},
    {prodId:"39121460",label:"Conector con filtro de cabecera"},
    {prodId:"39121461",label:"Sello de conector y cable"},
    {prodId:"39121462",label:"Conector de telecomunicaciones"},
    {prodId:"39121463",label:"Conector para enchufe"},
    {prodId:"39121464",label:"Codo de tubo metálico eléctrico emt"},
    {prodId:"39121465",label:"Trenza flexible"},
    {prodId:"39121466",label:"Terminal y tapa de conexión mecánica"},
    {prodId:"39121467",label:"Enchufe y tapa de conexión mecánica"},
    {prodId:"39121500",label:"Conmutadores, controles y relés y accesorios"},
    {prodId:"39121521",label:"Controles de motor de arranque"},
    {prodId:"39121522",label:"Contactos eléctricos"},
    {prodId:"39121523",label:"Temporizadores"},
    {prodId:"39121524",label:"Fotocontroles"},
    {prodId:"39121527",label:"Codificadores"},
    {prodId:"39121528",label:"Sensores fotoeléctricos"},
    {prodId:"39121529",label:"Contactores"},
    {prodId:"39121534",label:"Luces indicadoras o indicadores luminosos"},
    {prodId:"39121544",label:"Piezas de luces indicadoras o accesorios"},
    {prodId:"39121545",label:"Paradas de emergencia"},
    {prodId:"39121551",label:"Piezas de reflector"},
    {prodId:"39121552",label:"Módulos de control eléctrico"},
    {prodId:"39121555",label:"Control de contador"},
    {prodId:"39121561",label:"Estación colgante de control"},
    {prodId:"39121565",label:"Estación de control de montaje de superficie"},
    {prodId:"39121568",label:"Reactor de derivación"},
    {prodId:"39121569",label:"Reacción en serie"},
    {prodId:"39121570",label:"Re - cerrador"},
    {prodId:"39121600",label:"Dispositivos y accesorios para la protección de circuitos"},
    {prodId:"39121601",label:"Breakers de circuito"},
    {prodId:"39121602",label:"Breakers de circuito magnético"},
    {prodId:"39121603",label:"Micro disyuntores"},
    {prodId:"39121604",label:"Fusibles de retardo"},
    {prodId:"39121605",label:"Fusibles de tapón"},
    {prodId:"39121606",label:"Fusibles de cartucho"},
    {prodId:"39121607",label:"Fusibles de cuerpo de vidrio"},
    {prodId:"39121609",label:"Microfusibles"},
    {prodId:"39121610",label:"Supresor de ondas"},
    {prodId:"39121611",label:"Fusibles de cerámicas"},
    {prodId:"39121612",label:"Fusibles de cuchilla"},
    {prodId:"39121613",label:"Conjuntos o dispositivos de polo a tierra"},
    {prodId:"39121614",label:"Disyuntores de pérdida a tierra"},
    {prodId:"39121615",label:"Breakers de circuito de aire"},
    {prodId:"39121616",label:"Breakers de circuito de caja moldeada"},
    {prodId:"39121617",label:"Piezas de fusible o accesorios"},
    {prodId:"39121618",label:"Alambre para fusible"},
    {prodId:"39121619",label:"Fusibles tipo botella"},
    {prodId:"39121620",label:"Materiales transientes de protección"},
    {prodId:"39121621",label:"Accesorios y aparatos de protección contra rayos"},
    {prodId:"39121622",label:"Fusible automotriz"},
    {prodId:"39121623",label:"Fusible electrónico"},
    {prodId:"39121624",label:"Soporte de fusible"},
    {prodId:"39121625",label:"Fusible de alta velocidad"},
    {prodId:"39121626",label:"Fusible de medio voltaje"},
    {prodId:"39121627",label:"Fusible de potencia"},
    {prodId:"39121628",label:"Fusible semiconductor"},
    {prodId:"39121629",label:"Bloque de fusibles"},
    {prodId:"39121630",label:"Clip de fusible"},
    {prodId:"39121631",label:"Interruptor de circuito de alto voltaje lleno de aceite"},
    {prodId:"39121632",label:"Protector de redes"},
    {prodId:"39121633",label:"Interruptor de circuito"},
    {prodId:"39121634",label:"Protector de sobretensiones"},
    {prodId:"39121635",label:"Regulador de voltaje"},
    {prodId:"39121636",label:"Limitador de corriente"},
    {prodId:"39121637",label:"Supresor de arco"},
    {prodId:"39121638",label:"Corte de fusible"},
    {prodId:"39121639",label:"Interruptor de vacío"},
    {prodId:"39121640",label:"Breaker de circuito eléctrico"},
    {prodId:"39121641",label:"Fusible de gancho"},
    {prodId:"39121642",label:"Fusible de tornillo"},
    {prodId:"39121643",label:"Panel protector de sobrecorriente"},
    {prodId:"39121644",label:"Breaker de circuito de alta velocidad"},
    {prodId:"39121645",label:"Breaker de circuito de aceite"},
    {prodId:"39121646",label:"Breaker de circuito de gas"},
    {prodId:"39121647",label:"Descargador electrostático"},
    {prodId:"39121648",label:"Aditivo de tierra"},
    {prodId:"39121700",label:"Ferretería eléctrica y suministros"},
    {prodId:"39121701",label:"Soportes eléctricos"},
    {prodId:"39121702",label:"Clips para cables"},
    {prodId:"39121703",label:"Enlaces de cables"},
    {prodId:"39121704",label:"Placas de pared"},
    {prodId:"39121705",label:"Grapas para cables"},
    {prodId:"39121706",label:"Bujes de transformadores"},
    {prodId:"39121707",label:"Clavos de tabla de arneses"},
    {prodId:"39121708",label:"Riel din"},
    {prodId:"39121709",label:"Atadura de mango"},
    {prodId:"39121710",label:"Receptáculo multiplicador eléctrico"},
    {prodId:"39121717",label:"Montaje de fijador de cable"},
    {prodId:"39121718",label:"Kits de empalme de cables"},
    {prodId:"39121719",label:"Protectores"},
    {prodId:"39121720",label:"Asas de transformadores"},
    {prodId:"39121721",label:"Aislantes eléctricos"},
    {prodId:"39121722",label:"Dispositivo para sacar alambres y cables"},
    {prodId:"39121723",label:"Tubo encogible al calor"},
    {prodId:"39121724",label:"Artículos para montaje de conector"},
    {prodId:"39121725",label:"Mango de cable eléctrico"},
    {prodId:"39121726",label:"Atador de cables eléctricos"},
    {prodId:"39121727",label:"Varilla aislante eléctrica"},
    {prodId:"39121728",label:"Tubo de aislamiento"},
    {prodId:"39121729",label:"Juego de cables eléctricos"},
    {prodId:"39121730",label:"Roseta eléctrica"},
    {prodId:"39121731",label:"Equipo para atar cables"},
    {prodId:"39121800",label:"Instalaciones de Edificios Inteligentes - IBI"},
    {prodId:"39121801",label:"Unidades o dispositivos de control"},
    {prodId:"39121802",label:"Unidades o dispositivos inactivos"},
    {prodId:"39121803",label:"Sistema de control de construcción ambiental"},
    {prodId:"39121900",label:"Dispositivos y accesorios de seguridad eléctrica"},
    {prodId:"39121901",label:"Bloqueo de breaker de circuito"},
    {prodId:"39121902",label:"Cerramiento de bloqueo"},
    {prodId:"39121903",label:"Bloqueo de pasador y candado"},
    {prodId:"39121904",label:"Kit y estación de bloqueo"},
    {prodId:"39121905",label:"Dispositivo de bloqueo múltiple"},
    {prodId:"39121906",label:"Bloqueo de enchufe y cable"},
    {prodId:"39121907",label:"Bloqueo de receptáculo"},
    {prodId:"39121908",label:"Bloqueo de interruptor"},
    {prodId:"39121909",label:"Bloqueo de válvula"},
    {prodId:"39121910",label:"Marcador de voltaje"},
    {prodId:"39122000",label:"Unidades eléctricas de velocidades variables"},
    {prodId:"39122001",label:"Unidad ac invertida"},
    {prodId:"39122002",label:"Unidad dc de control de motor"},
    {prodId:"39122003",label:"Unidad de servo control"},
    {prodId:"39122100",label:"Equipo de transmisión y distribución eléctrica"},
    {prodId:"39122101",label:"Potheads de cable para subestaciones"},
    {prodId:"39122102",label:"Cambiador de tomas"},
    {prodId:"39122103",label:"Poste de utilidad"},
    {prodId:"39122104",label:"Cable galvanizado de refuerzo"},
    {prodId:"39122105",label:"Espaciador eléctrico"},
    {prodId:"39122106",label:"Abrazadera para cruceta"},
    {prodId:"39122107",label:"Bloque de madera de cableado"},
    {prodId:"39122108",label:"Varilla de anclaje para cable galvanizado de refuerzo"},
    {prodId:"39122109",label:"Cruceta de acero tipo cuadrado"},
    {prodId:"39122110",label:"Arandela de conexión para línea eléctrica aérea"},
    {prodId:"39122111",label:"Dispositivo para dividir la línea eléctrica del carro"},
    {prodId:"39122112",label:"Ojo del juego de aislamiento"},
    {prodId:"39122113",label:"Cruceta del poste de utilidad"},
    {prodId:"39122114",label:"Toma de vía de bus eléctrico o toma de bus"},
    {prodId:"39122115",label:"Varilla de armadura eléctrica"},
    {prodId:"39122116",label:"Ajuste de vínculo de línea aérea"},
    {prodId:"39122117",label:"Tensión yugo de polo de suspensión"},
    {prodId:"39122118",label:"Conector de bloque de cable eléctrico"},
    {prodId:"39122119",label:"Torre de acero de transmisión eléctrica"},
    {prodId:"39122120",label:"Banda de polo de utilidad"},
    {prodId:"39122200",label:"Interruptores eléctricos y accesorios"},
    {prodId:"39122201",label:"Interruptor de código"},
    {prodId:"39122202",label:"Interruptor de cuchilla"},
    {prodId:"39122203",label:"Interruptor sensible"},
    {prodId:"39122204",label:"Interruptor de vueltas"},
    {prodId:"39122205",label:"Interruptores de seguridad"},
    {prodId:"39122206",label:"Reductores o dimers"},
    {prodId:"39122207",label:"Interruptores de tambor"},
    {prodId:"39122208",label:"Interruptores de tiempos"},
    {prodId:"39122209",label:"Interruptores de resorte"},
    {prodId:"39122210",label:"Interruptores automáticos por caída de presión"},
    {prodId:"39122211",label:"Interruptores de volquete"},
    {prodId:"39122212",label:"Conmutadores de botón deslizante"},
    {prodId:"39122213",label:"Interruptores de límite"},
    {prodId:"39122214",label:"Interruptores de controlador"},
    {prodId:"39122215",label:"Interruptores variables"},
    {prodId:"39122216",label:"Interruptores de botón"},
    {prodId:"39122217",label:"Interruptores giratorios"},
    {prodId:"39122218",label:"Interruptor no fusible"},
    {prodId:"39122219",label:"Interruptor nivel o flotador"},
    {prodId:"39122220",label:"Interruptor de radio frecuencia rf"},
    {prodId:"39122221",label:"Parte o accesorio de interruptor"},
    {prodId:"39122222",label:"Interruptor de pie"},
    {prodId:"39122223",label:"Interruptor de flujo"},
    {prodId:"39122224",label:"Interruptor de cerradura"},
    {prodId:"39122225",label:"Interruptor de mercurio"},
    {prodId:"39122226",label:"Interruptor rocker"},
    {prodId:"39122227",label:"Interruptor o control joystick"},
    {prodId:"39122228",label:"Interruptor de vacío"},
    {prodId:"39122229",label:"Interruptor de temperatura"},
    {prodId:"39122230",label:"Interruptor de proximidad"},
    {prodId:"39122231",label:"Interruptor dispositivo de combinación"},
    {prodId:"39122232",label:"Interruptor de combinación"},
    {prodId:"39122233",label:"Interruptor de desconexión"},
    {prodId:"39122234",label:"Interruptor de bloqueo"},
    {prodId:"39122235",label:"Interruptor de sistema de cableado modular"},
    {prodId:"39122236",label:"Interruptor de sensor de ocupación o movimiento"},
    {prodId:"39122237",label:"Interruptor de montura de panel"},
    {prodId:"39122238",label:"Interruptor de fotocelda"},
    {prodId:"39122239",label:"Interruptor de halar"},
    {prodId:"39122240",label:"Interruptor de velocidad"},
    {prodId:"39122241",label:"Interruptor de lengüeta"},
    {prodId:"39122242",label:"Interruptor de suelo"},
    {prodId:"39122243",label:"Micro - interruptor"},
    {prodId:"39122244",label:"Interruptor de corte"},
    {prodId:"39122245",label:"Interruptor magnético"},
    {prodId:"39122246",label:"Interruptor de potenciómetro eléctrico"},
    {prodId:"39122247",label:"Interruptor de potenciómetro automotriz hvac"},
    {prodId:"39122248",label:"Interruptor de distribuidor de señal de potencia"},
    {prodId:"39122249",label:"Interruptor de control de timón"},
    {prodId:"39122250",label:"Interruptor selector de cambio de transmisión automática"},
    {prodId:"39122251",label:"Interruptor detent"},
    {prodId:"39122300",label:"Relés eléctricos y accesorios"},
    {prodId:"39122301",label:"Relé buchholtz"},
    {prodId:"39122302",label:"Relé de distancia"},
    {prodId:"39122303",label:"Relé multicontacto"},
    {prodId:"39122304",label:"Relé de lengüeta"},
    {prodId:"39122305",label:"Relé de medidor"},
    {prodId:"39122306",label:"Relé de tierra direccional"},
    {prodId:"39122307",label:"Relé auxiliar"},
    {prodId:"39122308",label:"Relé de índice diferencial"},
    {prodId:"39122309",label:"Relé subterráneo"},
    {prodId:"39122310",label:"Relé selector de tierra"},
    {prodId:"39122311",label:"Relé de presión"},
    {prodId:"39122312",label:"Relé de polaridad"},
    {prodId:"39122313",label:"Relé trip free"},
    {prodId:"39122314",label:"Relé de bloqueo"},
    {prodId:"39122315",label:"Relé electromagnético"},
    {prodId:"39122316",label:"Relé de re - cerrado"},
    {prodId:"39122317",label:"Relé de aseguramiento"},
    {prodId:"39122318",label:"Relé de frecuencia"},
    {prodId:"39122319",label:"Relé de corriente directa"},
    {prodId:"39122320",label:"Relé horizontal"},
    {prodId:"39122321",label:"Relé no polarizado"},
    {prodId:"39122322",label:"Relé de voltaje de corriente directa"},
    {prodId:"39122323",label:"Relé digital multi - función"},
    {prodId:"39122324",label:"Relés de potencia"},
    {prodId:"39122325",label:"Relés universales"},
    {prodId:"39122326",label:"Relés de clavija bipolar"},
    {prodId:"39122327",label:"Relés de voltaje alterno"},
    {prodId:"39122328",label:"Relés de mercurio"},
    {prodId:"39122329",label:"Relés de acción diferida"},
    {prodId:"39122330",label:"Relés de sobrecarga"},
    {prodId:"39122331",label:"Relés de control"},
    {prodId:"39122332",label:"Relés de interrupción de fase"},
    {prodId:"39122333",label:"Relés de estado sólido"},
    {prodId:"39122334",label:"Módulo de relés múltiples o de placa de relés"},
    {prodId:"39122335",label:"Base o toma de relé"},
    {prodId:"39122336",label:"Relé intermitente de luz direccional"},
    {prodId:"39122337",label:"Relé de tablero de circuito impreso"},
    {prodId:"39131500",label:"Marcadores de alambre y dispositivos para marcar alambre"},
    {prodId:"39131501",label:"Marcador de cable de pinza"},
    {prodId:"39131502",label:"Marcador de cable de encogimiento por calor"},
    {prodId:"39131503",label:"Marcador de cable de deslizar"},
    {prodId:"39131504",label:"Marcador de cable de identificación"},
    {prodId:"39131505",label:"Herramienta e impresora para rotular cable"},
    {prodId:"39131506",label:"Libro de marcador de cable"},
    {prodId:"39131507",label:"Tarjeta de arcador de cable"},
    {prodId:"39131508",label:"Rollo y dispensador de arcador de cable"},
    {prodId:"39131509",label:"Marcador de cable de escribir"},
    {prodId:"39131600",label:"Dispositivos de protección de alambre"},
    {prodId:"39131601",label:"Tubo corrugado para cableado interno"},
    {prodId:"39131602",label:"Manga trenzada expandible"},
    {prodId:"39131603",label:"Perfil pasacable"},
    {prodId:"39131604",label:"Empaque de espiral"},
    {prodId:"39131605",label:"Piso de cables track or guard"},
    {prodId:"39131606",label:"Placa de clavos para protección de cable"},
    {prodId:"39131607",label:"Cordón para amarrar cables"},
    {prodId:"39131608",label:"Sistema de sellamiento de ductos"},
    {prodId:"39131700",label:"Conductos eléctricos, electroductos y cables aéreos"},
    {prodId:"39131701",label:"Electroducto"},
    {prodId:"39131702",label:"Dispositivos y accesorios de electroducto"},
    {prodId:"39131703",label:"Escalerilla de cables"},
    {prodId:"39131704",label:"Bandeja de cables"},
    {prodId:"39131705",label:"Dispositivos y accesorios de bandeja de cable"},
    {prodId:"39131706",label:"Conducto eléctrico"},
    {prodId:"39131707",label:"Acoples de conducto eléctrico"},
    {prodId:"39131708",label:"Cuerpo de ajuste de conducto eléctrico"},
    {prodId:"39131709",label:"Cableado eléctrico o conductos de cables"},
    {prodId:"39131710",label:"Cables aéreos"},
    {prodId:"39131711",label:"Dispositivos y accesorios de cableado eléctrico"},
    {prodId:"39131712",label:"Ducto de cableado"},
    {prodId:"39131713",label:"Dispositivos y accesorios de ducto de cableado"},
    {prodId:"39131714",label:"Canaletas para cables"},
    {prodId:"39131715",label:"Conducto subterráneo de manguera"},
    {prodId:"39131716",label:"Manipulador de cable"},
    {prodId:"39131717",label:"Codo de conducto eléctrico"},
    {prodId:"39131718",label:"Boquilla de conducto eléctrico"},
    {prodId:"39131719",label:"Tubería metálica eléctrica emt"},
    {prodId:"39131720",label:"Tubería metálica eléctrica emt roscada"},
    {prodId:"40101500",label:"Ventilación"},
    {prodId:"40101501",label:"Colectores de aire"},
    {prodId:"40101502",label:"Extractores de aire"},
    {prodId:"40101503",label:"Rejilla de ventilación"},
    {prodId:"40101504",label:"Compuertas de ventilación"},
    {prodId:"40101505",label:"Difusores de aire"},
    {prodId:"40101506",label:"Tubos de ventilación"},
    {prodId:"40101507",label:"Tubería de ventilación en espiral"},
    {prodId:"40101508",label:"Cortina de ventilación"},
    {prodId:"40101509",label:"Clavos para sistemas de ventilación"},
    {prodId:"40101510",label:"Tubería de ventilación"},
    {prodId:"40101511",label:"Colgador para tubos de ventilación"},
    {prodId:"40101512",label:"Dispositivos para tubos de ventilación"},
    {prodId:"40101600",label:"Circulación del aire y piezas y accesorios"},
    {prodId:"40101601",label:"Secadores"},
    {prodId:"40101602",label:"Circuladores de aire"},
    {prodId:"40101603",label:"Impulsores"},
    {prodId:"40101604",label:"Ventiladores"},
    {prodId:"40101605",label:"Protectores o sus accesorios para ventiladores"},
    {prodId:"40101606",label:"Dispositivos de seguridad para ventiladores"},
    {prodId:"40101607",label:"Válvula de control de volumen de aire"},
    {prodId:"40101608",label:"Unidad de filtrado para ventiladores"},
    {prodId:"40101609",label:"Ventiladores de techo"},
    {prodId:"40101610",label:"Kit de ventiladores de techo"},
    {prodId:"40101700",label:"Enfriamiento"},
    {prodId:"40101701",label:"Aires acondicionados"},
    {prodId:"40101702",label:"Intercambiadores de enfriado"},
    {prodId:"40101703",label:"Enfriadores de evaporación"},
    {prodId:"40101704",label:"Unidades de condensación"},
    {prodId:"40101705",label:"Ensamblajes de tubos capilares"},
    {prodId:"40101706",label:"Obturadores de aire acondicionado"},
    {prodId:"40101707",label:"Accesorios para torres de enfriamiento"},
    {prodId:"40101708",label:"Unidad de ventilador de bobina"},
    {prodId:"40101709",label:"Unidad de manejo de aire"},
    {prodId:"40101710",label:"Enfriador de reciprocidad"},
    {prodId:"40101711",label:"Enfriador de líquido centrífugo"},
    {prodId:"40101712",label:"Enfriador de tornillo"},
    {prodId:"40101713",label:"Enfriador de absorción"},
    {prodId:"40101714",label:"Enfriador calentador de absorción"},
    {prodId:"40101715",label:"Cámara de temperatura y humedad constantes"},
    {prodId:"40101716",label:"Torre enfriadora"},
    {prodId:"40101717",label:"Ahorrador de energía para aire acondicionado"},
    {prodId:"40101718",label:"Bobina enfriadora"},
    {prodId:"40101719",label:"Receptor de líquido refrigerante"},
    {prodId:"40101720",label:"Unidad de enfriamiento"},
    {prodId:"40101721",label:"Condensador de agua enfriada"},
    {prodId:"40101722",label:"Condensador de vapor"},
    {prodId:"40101800",label:"Equipo de calefacción y piezas y accesorios"},
    {prodId:"40101801",label:"Radiadores"},
    {prodId:"40101802",label:"Intercambiadores de calor"},
    {prodId:"40101805",label:"Calefacción"},
    {prodId:"40101806",label:"Bombas de calor"},
    {prodId:"40101807",label:"Unidades de calefacción solar"},
    {prodId:"40101808",label:"Estufas de calefacción"},
    {prodId:"40101809",label:"Calentadores de circulación"},
    {prodId:"40101810",label:"Calentadores de conductos de bobina"},
    {prodId:"40101811",label:"Calentadores de convección"},
    {prodId:"40101812",label:"Intercambiadores divididos"},
    {prodId:"40101813",label:"Intercambiadores de doble división"},
    {prodId:"40101814",label:"Calentadores de aletas tubulares"},
    {prodId:"40101815",label:"Calentadores de inmersión"},
    {prodId:"40101816",label:"Intercambiadores de teteras"},
    {prodId:"40101817",label:"Intercambiadores de una vía"},
    {prodId:"40101818",label:"Calentadores de procesamiento de aire"},
    {prodId:"40101819",label:"Calentadores de espacio"},
    {prodId:"40101820",label:"Intercambiadores divididos"},
    {prodId:"40101821",label:"Calentadores de banda"},
    {prodId:"40101822",label:"Calentadores tubulares"},
    {prodId:"40101823",label:"Intercambiadores de dos vías"},
    {prodId:"40101824",label:"Calentador de cuarzo"},
    {prodId:"40101825",label:"Calentadores de agua para uso doméstico"},
    {prodId:"40101826",label:"Calentadores de agua comerciales"},
    {prodId:"40101827",label:"Calentadores de fibra cerámica"},
    {prodId:"40101828",label:"Calentadores de cartucho"},
    {prodId:"40101829",label:"Calentadores de tiro"},
    {prodId:"40101830",label:"Elementos de calefacción"},
    {prodId:"40101831",label:"Calentadores de inducción"},
    {prodId:"40101832",label:"Puertas para equipos de calefacción"},
    {prodId:"40101833",label:"Encendedor para calderas o calentadores"},
    {prodId:"40101834",label:"Quemadores (fogones)"},
    {prodId:"40101835",label:"Plato intercambiador de calor"},
    {prodId:"40101836",label:"Intercambiador de calor de concha y de tubo"},
    {prodId:"40101837",label:"Chimenea"},
    {prodId:"40101838",label:"Economizador"},
    {prodId:"40101839",label:"Placas difusoras"},
    {prodId:"40101840",label:"Calentador de película"},
    {prodId:"40101841",label:"Pre calentador de aire"},
    {prodId:"40101842",label:"Dispositivo para derretir hielo"},
    {prodId:"40101843",label:"Horno eléctrico"},
    {prodId:"40101844",label:"Horno de alta frecuencia de inducción al vacío"},
    {prodId:"40101845",label:"Horno de reverberación"},
    {prodId:"40101846",label:"Horno de baño de sal"},
    {prodId:"40101847",label:"Horno eléctrico de carbonización y nitrificación"},
    {prodId:"40101848",label:"Sistema de calefacción de aire forzado"},
    {prodId:"40101849",label:"Cabezal de distribución de agua caliente"},
    {prodId:"40101850",label:"Horno crematorio"},
    {prodId:"40101900",label:"Control de humedad"},
    {prodId:"40101901",label:"Vaporizadores"},
    {prodId:"40101902",label:"Deshumidificadores"},
    {prodId:"40101903",label:"Humidificadores"},
    {prodId:"40102000",label:"Calderas"},
    {prodId:"40102001",label:"Quemadores de tubo radiante"},
    {prodId:"40102002",label:"Quemadores de tubo de agua"},
    {prodId:"40102003",label:"Quemadores eléctricos"},
    {prodId:"40102004",label:"Quemadores operados con gas natural"},
    {prodId:"40102005",label:"Quemadores operados con gas propano"},
    {prodId:"40102006",label:"Quemadores operados con calor sobrante"},
    {prodId:"40102007",label:"Mini quemadores de aceite"},
    {prodId:"40102100",label:"Chimeneas y accesorios"},
    {prodId:"40102101",label:"Chimeneas operadas con leña"},
    {prodId:"40102102",label:"Chimenea a gas de ventilador b"},
    {prodId:"40102103",label:"Chimenea a gas de ventilador directo"},
    {prodId:"40102104",label:"Chimenea a gas de ventilador libre"},
    {prodId:"40102105",label:"Chimenea eléctrica"},
    {prodId:"40102106",label:"Fachadas de chimeneas"},
    {prodId:"40102107",label:"Repisas para chimeneas"},
    {prodId:"40141600",label:"Válvulas"},
    {prodId:"40141602",label:"Válvulas de aguja"},
    {prodId:"40141603",label:"Válvulas neumáticas"},
    {prodId:"40141604",label:"Válvulas de seguridad"},
    {prodId:"40141605",label:"Válvulas solenoides"},
    {prodId:"40141606",label:"Válvulas de relevo"},
    {prodId:"40141607",label:"Válvulas de bola"},
    {prodId:"40141608",label:"Válvulas hidráulicas"},
    {prodId:"40141609",label:"Válvulas de control"},
    {prodId:"40141610",label:"Válvulas de flotación"},
    {prodId:"40141611",label:"Válvulas de globo"},
    {prodId:"40141612",label:"Válvulas de expansión"},
    {prodId:"40141613",label:"Válvulas de compuerta"},
    {prodId:"40141615",label:"Válvulas de lengüeta"},
    {prodId:"40141616",label:"Partes o accesorios para válvulas"},
    {prodId:"40141617",label:"Válvulas esféricas de ángulo"},
    {prodId:"40141618",label:"Válvulas de seguridad de bola"},
    {prodId:"40141619",label:"Válvulas de mariposa con diseño de casquillo"},
    {prodId:"40141620",label:"Válvulas de mariposa con diseño de disco"},
    {prodId:"40141621",label:"Válvulas de diafragma"},
    {prodId:"40141622",label:"Válvulas de seguridad en línea"},
    {prodId:"40141623",label:"Válvulas tipo compuerta"},
    {prodId:"40141624",label:"Válvulas con pistones lubricados"},
    {prodId:"40141625",label:"Válvulas purgadoras de sedimentos (barro o lodo)"},
    {prodId:"40141626",label:"Válvulas con pistón no lubricado"},
    {prodId:"40141627",label:"Válvulas de orificio"},
    {prodId:"40141628",label:"Válvulas piloto"},
    {prodId:"40141629",label:"Válvulas de pinzamiento"},
    {prodId:"40141630",label:"Válvulas de seguridad de pistón"},
    {prodId:"40141631",label:"Válvulas de bombeo"},
    {prodId:"40141632",label:"Válvulas de monitoreo"},
    {prodId:"40141633",label:"Válvulas deslizantes"},
    {prodId:"40141634",label:"Válvulas de control de rotación"},
    {prodId:"40141635",label:"Válvulas de turbina"},
    {prodId:"40141636",label:"Kits de válvulas"},
    {prodId:"40141637",label:"Válvulas de control de disco"},
    {prodId:"40141638",label:"Válvulas alternadoras"},
    {prodId:"40141639",label:"Válvulas de llenado del inodoro"},
    {prodId:"40141640",label:"Válvulas de inodoro"},
    {prodId:"40141641",label:"Válvulas solenoides"},
    {prodId:"40141642",label:"Válvulas de asiento (o auxiliares)"},
    {prodId:"40141643",label:"Válvulas de contención"},
    {prodId:"40141644",label:"Válvulas de esclusa rotativas"},
    {prodId:"40141645",label:"Vástagos de válvulas"},
    {prodId:"40141646",label:"Soportes (asientos) de válvulas"},
    {prodId:"40141647",label:"Válvulas de segmento"},
    {prodId:"40141648",label:"Válvulas magnéticas"},
    {prodId:"40141649",label:"Ensamblaje de bobina de válvula de control direccional"},
    {prodId:"40141650",label:"Válvula de gas comprimido"},
    {prodId:"40141651",label:"Válvula de aire"},
    {prodId:"40141652",label:"Válvula reductora de presión"},
    {prodId:"40141653",label:"Válvula de compuerta"},
    {prodId:"40141654",label:"Válvula de regulación"},
    {prodId:"40141655",label:"Válvula de vacío"},
    {prodId:"40141656",label:"Válvula de brida tipo mariposa"},
    {prodId:"40141657",label:"Válvula de control de rociadores"},
    {prodId:"40141658",label:"Válvula de bloqueo y sangrado"},
    {prodId:"40141659",label:"Válvula horizontal de elevación"},
    {prodId:"40141700",label:"Material de ferretería y accesorios"},
    {prodId:"40141719",label:"Adaptadores para plomería"},
    {prodId:"40141720",label:"Conectores para plomería"},
    {prodId:"40141725",label:"Ganchos (soportes) para plomería"},
    {prodId:"40141726",label:"Hidrantes"},
    {prodId:"40141727",label:"Plomería de ventilación"},
    {prodId:"40141731",label:"Boquillas"},
    {prodId:"40141732",label:"Centradores (arañas) para plomería"},
    {prodId:"40141734",label:"Conectores para mangueras"},
    {prodId:"40141735",label:"Embudos"},
    {prodId:"40141736",label:"Graseras (de lubricación)"},
    {prodId:"40141737",label:"Diafragmas"},
    {prodId:"40141738",label:"Tapones de drenaje (de aceite)"},
    {prodId:"40141739",label:"Tapas de desagüe"},
    {prodId:"40141740",label:"Llave de la gasolina"},
    {prodId:"40141741",label:"Orificios de ajuste"},
    {prodId:"40141742",label:"Atomizadores"},
    {prodId:"40141743",label:"Puntas o capas de boquillas"},
    {prodId:"40141744",label:"Cuencos de drenaje"},
    {prodId:"40141745",label:"Fusibles de alta temperatura"},
    {prodId:"40141746",label:"Mirillas (indicadores de nivel)"},
    {prodId:"40141747",label:"Trampa de grasas"},
    {prodId:"40141749",label:"Interruptor de vacío"},
    {prodId:"40141750",label:"Brazo (de tubería)"},
    {prodId:"40141751",label:"Codos (de tubería)"},
    {prodId:"40141755",label:"Resorte para evitar movimiento de tubería"},
    {prodId:"40141756",label:"Caja de accesorios para conectar tubos"},
    {prodId:"40141757",label:"Escudetes"},
    {prodId:"40141758",label:"Canal de drenaje"},
    {prodId:"40141759",label:"Desagües de piso"},
    {prodId:"40141760",label:"Hidrante anti congelamiento"},
    {prodId:"40141761",label:"Múltiple (colector) de tubería"},
    {prodId:"40141762",label:"Dispositivo protector del medidor de agua"},
    {prodId:"40141763",label:"Supresor de golpes de ariete"},
    {prodId:"40141764",label:"Ensamblaje de mangueras"},
    {prodId:"40141765",label:"Separador de drenaje"},
    {prodId:"40141766",label:"Regulador de cierre con asiento para trabajos de agua"},
    {prodId:"40141767",label:"Zapatas para tubería"},
    {prodId:"40141768",label:"Adaptador desmontable"},
    {prodId:"40141769",label:"Caja o cámara de válvulas"},
    {prodId:"40141770",label:"Máquina de fusión de tubos"},
    {prodId:"40141900",label:"Conductos"},
    {prodId:"40141901",label:"Conductos flexibles"},
    {prodId:"40141902",label:"Conductos rígidos"},
    {prodId:"40141903",label:"Conductos o red de conductos de manganeso"},
    {prodId:"40141904",label:"Conductos o red de conductos de aleación ferrosa"},
    {prodId:"40141905",label:"Conductos o red de conductos de titanio"},
    {prodId:"40141906",label:"Conductos o red de conductos de latón"},
    {prodId:"40141907",label:"Conductos o red de conductos de latón"},
    {prodId:"40141908",label:"Conductos o red de conductos de plomo"},
    {prodId:"40141909",label:"Conductos o red de conductos de bronce"},
    {prodId:"40141910",label:"Conductos o red de conductos de zinc"},
    {prodId:"40141911",label:"Conductos o red de conductos de acero"},
    {prodId:"40141912",label:"Conductos o red de conductos de hierro"},
    {prodId:"40141913",label:"Conductos o red de conductos de cemento"},
    {prodId:"40141914",label:"Conductos o red de conductos de plástico"},
    {prodId:"40141915",label:"Conductos o red de conductos de caucho"},
    {prodId:"40141916",label:"Conductos o red de conductos de vidrio"},
    {prodId:"40141917",label:"Conductos o red de conductos de piedra"},
    {prodId:"40141918",label:"Conductos o red de conductos de aleación no ferrosa"},
    {prodId:"40141919",label:"Conductos o red de conductos de aluminio"},
    {prodId:"40141920",label:"Conductos o red de conductos de acero inoxidable"},
    {prodId:"40141921",label:"Conductos o red de conductos de metales preciosos"},
    {prodId:"40141922",label:"Conductos o red de conductos de cobre"},
    {prodId:"40141923",label:"Conductos o red de conductos de látex"},
    {prodId:"40141924",label:"Conductos o red de conductos de nylon"},
    {prodId:"40142000",label:"Mangueras"},
    {prodId:"40142001",label:"Mangueras de ácido"},
    {prodId:"40142002",label:"Mangueras de aire"},
    {prodId:"40142003",label:"Mangueras de perforación"},
    {prodId:"40142004",label:"Mangueras marítimas"},
    {prodId:"40142005",label:"Mangueras para manipular material"},
    {prodId:"40142006",label:"Mangueras de aceite"},
    {prodId:"40142007",label:"Mangueras especiales"},
    {prodId:"40142008",label:"Mangueras de agua"},
    {prodId:"40142009",label:"Mangueras multipropósito de aire, agua y gas"},
    {prodId:"40142010",label:"Mangueras recubiertas de fluoropolímero"},
    {prodId:"40142011",label:"Manguera química"},
    {prodId:"40142012",label:"Manguera para alimentos y bebidas"},
    {prodId:"40142013",label:"Mangueras para minería"},
    {prodId:"40142014",label:"Mangueras de petróleo"},
    {prodId:"40142015",label:"Mangueras para limpieza al vapor"},
    {prodId:"40142016",label:"Mangueras al vacío"},
    {prodId:"40142017",label:"Mangueras de soldadura"},
    {prodId:"40142018",label:"Mangueras para rociar"},
    {prodId:"40142019",label:"Mangueras de conductos"},
    {prodId:"40142020",label:"Mangueras hidráulicas"},
    {prodId:"40142021",label:"Mangueras de polvo de piedra"},
    {prodId:"40142022",label:"Rollos de mangueras"},
    {prodId:"40142200",label:"Reguladores de gas y fluido"},
    {prodId:"40142201",label:"Reguladores de gas"},
    {prodId:"40142202",label:"Reguladores de fluido"},
    {prodId:"40142203",label:"Kits de reparación de reguladores de fluido"},
    {prodId:"40142204",label:"Regulador de oxígeno para soldadura"},
    {prodId:"40142205",label:"Regulador de acetileno para soldadura"},
    {prodId:"40142206",label:"Compuerta de regulación de flujo"},
    {prodId:"40142207",label:"Regulador del nivel del agua"},
    {prodId:"40142208",label:"Mezclador de gas"},
    {prodId:"40142500",label:"Interceptores y coladores"},
    {prodId:"40142501",label:"Filtros (coladores) de líquido"},
    {prodId:"40142502",label:"Trampas de líquido"},
    {prodId:"40142503",label:"Trampas de vapor"},
    {prodId:"40142504",label:"Filtros (coladores) de vapor"},
    {prodId:"40142505",label:"Filtros (coladores) en y"},
    {prodId:"40142506",label:"Filtros (coladores) en t"},
    {prodId:"40142507",label:"Filtros (coladores) cónicos"},
    {prodId:"40142508",label:"Filtros (coladores) de canasta"},
    {prodId:"40142509",label:"Trampa de aceite"},
    {prodId:"40142510",label:"Trampa de vapor termostática"},
    {prodId:"40142511",label:"Trampa de vapor termodinámica"},
    {prodId:"40142512",label:"Respiradero"},
    {prodId:"40142513",label:"Trampa p"},
    {prodId:"40142514",label:"Trampa j"},
    {prodId:"40142515",label:"Trampa s"},
    {prodId:"40151500",label:"Bombas"},
    {prodId:"40151501",label:"Bombas de aire"},
    {prodId:"40151502",label:"Bombas de vacío"},
    {prodId:"40151503",label:"Bombas centrífugas"},
    {prodId:"40151504",label:"Bombas de circulación"},
    {prodId:"40151505",label:"Bombas dosificadoras"},
    {prodId:"40151506",label:"Bombas de mano"},
    {prodId:"40151507",label:"Bombas de irrigación"},
    {prodId:"40151508",label:"Bombas de barro"},
    {prodId:"40151509",label:"Bombas recíprocas"},
    {prodId:"40151510",label:"Bombas de agua"},
    {prodId:"40151511",label:"Bombas para pozos"},
    {prodId:"40151512",label:"Bombas para sumideros"},
    {prodId:"40151513",label:"Bombas sumergibles"},
    {prodId:"40151514",label:"Bombas de vapor"},
    {prodId:"40151515",label:"Bombas solenoides"},
    {prodId:"40151516",label:"Bombas de corte"},
    {prodId:"40151517",label:"Bombas de alcantarillado"},
    {prodId:"40151518",label:"Bombas no selladas"},
    {prodId:"40151519",label:"Bombas sanitarias"},
    {prodId:"40151520",label:"Bombas de muestreo"},
    {prodId:"40151521",label:"Bombas giratorias"},
    {prodId:"40151522",label:"Bombas de osmosis inversa"},
    {prodId:"40151523",label:"Bombas de desplazamiento positivo"},
    {prodId:"40151524",label:"Bombas de aceite"},
    {prodId:"40151525",label:"Bombas de lodo"},
    {prodId:"40151526",label:"Bombas de turbina"},
    {prodId:"40151527",label:"Bombas de émbolo"},
    {prodId:"40151528",label:"Bombas oscilantes"},
    {prodId:"40151529",label:"Bombas de tambor"},
    {prodId:"40151530",label:"Bombas de dragado"},
    {prodId:"40151531",label:"Bombas para remover agua"},
    {prodId:"40151532",label:"Bombas de combustible"},
    {prodId:"40151533",label:"Bombas hidráulicas"},
    {prodId:"40151534",label:"Bombas criogénicas"},
    {prodId:"40151546",label:"Bombas de partición axial"},
    {prodId:"40151547",label:"Bombas de pozo profundo"},
    {prodId:"40151548",label:"Bombas de diafragma"},
    {prodId:"40151549",label:"Bombas de doble diafragma"},
    {prodId:"40151550",label:"Bombas dúplex"},
    {prodId:"40151551",label:"Bombas de engranaje"},
    {prodId:"40151552",label:"Bombas de metraje o inyección o proporcionales"},
    {prodId:"40151553",label:"Bombas de cavidad progresiva"},
    {prodId:"40151554",label:"Bombas de pistón"},
    {prodId:"40151555",label:"Bombas de resalte rotatorias"},
    {prodId:"40151556",label:"Bombas de leva rotatorias"},
    {prodId:"40151557",label:"Bombas de pistón rotatorias"},
    {prodId:"40151558",label:"Bombas de tuerca"},
    {prodId:"40151559",label:"Bombas simplex"},
    {prodId:"40151560",label:"Bombas de paleta deslizante"},
    {prodId:"40151561",label:"Bombas triplex"},
    {prodId:"40151562",label:"Bombas de tornillo"},
    {prodId:"40151563",label:"Sets de bombas contra incendio"},
    {prodId:"40151564",label:"Bombas químicas"},
    {prodId:"40151565",label:"Bombas de macerar"},
    {prodId:"40151566",label:"Bomba elevadora de presión"},
    {prodId:"40151567",label:"Bomba de alta temperatura"},
    {prodId:"40151568",label:"Bomba de pulpa"},
    {prodId:"40151569",label:"Bomba monoflex"},
    {prodId:"40151570",label:"Bomba de flujo mixto"},
    {prodId:"40151571",label:"Bomba longitudinal"},
    {prodId:"40151572",label:"Bomba de elevación de aire"},
    {prodId:"40151573",label:"Bomba de magneto"},
    {prodId:"40151574",label:"Bomba eyectora"},
    {prodId:"40151575",label:"Bomba enfriadora sin cepillo"},
    {prodId:"40151576",label:"Bomba eléctrica"},
    {prodId:"40151577",label:"Bomba de lechada"},
    {prodId:"40151578",label:"Bomba de aire para buceo"},
    {prodId:"40151579",label:"Bomba de gravedad"},
    {prodId:"40151580",label:"Bomba lubricante"},
    {prodId:"40151600",label:"Compresores"},
    {prodId:"40151601",label:"Compresores de aire"},
    {prodId:"40151602",label:"Compresores de flujo axiales"},
    {prodId:"40151603",label:"Compresores de diafragma"},
    {prodId:"40151604",label:"Compresores de gas"},
    {prodId:"40151605",label:"Compresores de motor"},
    {prodId:"40151606",label:"Compresores recíprocos"},
    {prodId:"40151607",label:"Compresores refrigerantes"},
    {prodId:"40151608",label:"Compresores rotativos"},
    {prodId:"40151609",label:"Compresores de tuerca"},
    {prodId:"40151611",label:"Compresores de barril"},
    {prodId:"40151612",label:"Compresores centrífugos"},
    {prodId:"40151613",label:"Compresores de combinación"},
    {prodId:"40151614",label:"Compresores semi radiales"},
    {prodId:"40151615",label:"Turbo compresores"},
    {prodId:"40151616",label:"Kits de compresores"},
    {prodId:"40151700",label:"Piezas y accesorios de bomba"},
    {prodId:"40151701",label:"Carcasas para bombas"},
    {prodId:"40151712",label:"Empaques para bombas"},
    {prodId:"40151713",label:"Revestimientos para bombas"},
    {prodId:"40151714",label:"Barriles para bombas"},
    {prodId:"40151715",label:"Poleas para bombas"},
    {prodId:"40151716",label:"Cabezas para bombas"},
    {prodId:"40151717",label:"Discos para bombas"},
    {prodId:"40151718",label:"Partes de repuesto para bombas de lodo"},
    {prodId:"40151719",label:"Partes de repuesto para bombas de alcantarillado"},
    {prodId:"40151720",label:"Partes de repuesto para bombas sumergibles"},
    {prodId:"40151721",label:"Partes de repuesto para bombas de agua"},
    {prodId:"40151722",label:"Partes de repuesto para bombas de pozo"},
    {prodId:"40151723",label:"Partes de repuesto para bombas de sumidero"},
    {prodId:"40151724",label:"Partes de repuesto para bombas dosificadoras"},
    {prodId:"40151725",label:"Partes de repuesto para bombas centrífugas"},
    {prodId:"40151726",label:"Partes de repuesto para bombas de circulación"},
    {prodId:"40151727",label:"Partes de repuesto para bombas rotatorias"},
    {prodId:"40151728",label:"Kits de reparación de bombas"},
    {prodId:"40151729",label:"Estator de bombas"},
    {prodId:"40151730",label:"Impulsores de bombas"},
    {prodId:"40151731",label:"Rotores de bombas"},
    {prodId:"40151732",label:"Ejes de bombas"},
    {prodId:"40151733",label:"Placas de asiento de bombas"},
    {prodId:"40151734",label:"Placas de base de bombas"},
    {prodId:"40151735",label:"Ensamblaje de columnas de bombas"},
    {prodId:"40151736",label:"Tubos de columna de bombas"},
    {prodId:"40151737",label:"Ensamblaje de caja de bombas"},
    {prodId:"40151738",label:"Campana de succión de bombas"},
    {prodId:"40151739",label:"Partes para bombas recíprocas"},
    {prodId:"40151800",label:"Partes o accesorios de compresores"},
    {prodId:"40151801",label:"Partes para compresores rotatorios"},
    {prodId:"40151802",label:"Partes para compresores de aire"},
    {prodId:"40151803",label:"Partes para compresores recíprocos"},
    {prodId:"40151804",label:"Partes para compresores centrífugos"},
    {prodId:"40161500",label:"Filtros"},
    {prodId:"40161501",label:"Filtros al vacío"},
    {prodId:"40161502",label:"Filtros de agua"},
    {prodId:"40161503",label:"Recolectores de polvo"},
    {prodId:"40161504",label:"Filtros de aceite"},
    {prodId:"40161505",label:"Filtros de aire"},
    {prodId:"40161506",label:"Maquinaria de filtrado"},
    {prodId:"40161507",label:"Membranas de filtrado"},
    {prodId:"40161508",label:"Filtros de bolsa"},
    {prodId:"40161509",label:"Filtros de absorción"},
    {prodId:"40161511",label:"Filtros coalescentes"},
    {prodId:"40161512",label:"Filtros electrónicos"},
    {prodId:"40161513",label:"Filtros de combustible"},
    {prodId:"40161514",label:"Filtros para tuberías de gas"},
    {prodId:"40161515",label:"Filtros hidráulicos"},
    {prodId:"40161516",label:"Filtros en línea"},
    {prodId:"40161517",label:"Filtros de luz"},
    {prodId:"40161518",label:"Filtros de microfibra"},
    {prodId:"40161519",label:"Filtros de panel"},
    {prodId:"40161520",label:"Filtros de aletas radiales"},
    {prodId:"40161521",label:"Bases para filtros"},
    {prodId:"40161522",label:"Aletas para filtros"},
    {prodId:"40161524",label:"Filtros de pintura"},
    {prodId:"40161525",label:"Contenedores para filtros"},
    {prodId:"40161526",label:"Retenedores o accesorios para filtros"},
    {prodId:"40161527",label:"Kits de reparación de filtros"},
    {prodId:"40161528",label:"Filtros de arena"},
    {prodId:"40161529",label:"Filtros de tubo"},
    {prodId:"40161530",label:"Filtros de cabina de aire"},
    {prodId:"40161531",label:"Ensamblaje de filtros"},
    {prodId:"40161532",label:"Malla para filtros"},
    {prodId:"40161533",label:"Filtros para desechos sólidos"},
    {prodId:"40161600",label:"Purificación"},
    {prodId:"40161601",label:"Neutralizador (depurador) de aire"},
    {prodId:"40161602",label:"Limpiadores de aire"},
    {prodId:"40161603",label:"Máquinas limpiadoras de tubos y tuberías"},
    {prodId:"40161604",label:"Regenerador de aceite"},
    {prodId:"40161605",label:"Torre desodorizante"},
    {prodId:"40161606",label:"Generador de ozono para limpiar el aire"},
    {prodId:"40161607",label:"Sistema para desulfurar el gas de combustión"},
    {prodId:"40161608",label:"Esterilizador de aire"},
    {prodId:"40161700",label:"Separadores"},
    {prodId:"40161701",label:"Centrífugas"},
    {prodId:"40161702",label:"Lavador húmedo"},
    {prodId:"40161703",label:"Eliminadores de niebla"},
    {prodId:"40161704",label:"Hidrociclones"},
    {prodId:"40161705",label:"Tamiz separador de partículas líquidas"},
    {prodId:"40161800",label:"Medios de filtrado"},
    {prodId:"40161801",label:"Medios de textiles metálicos"},
    {prodId:"40161802",label:"Fieltros prensados"},
    {prodId:"40161803",label:"Papeles filtrantes"},
    {prodId:"40161804",label:"Ayudas filtrantes"},
    {prodId:"40161805",label:"Paño filtrante"},
    {prodId:"40161806",label:"Malla filtrante"},
    {prodId:"40161807",label:"Elemento (dispositivo poroso) filtrante"},
    {prodId:"40161808",label:"Medio biológico filtrante"},
    {prodId:"40161809",label:"Cartucho filtrante"},
    {prodId:"40171500",label:"Tubos y tuberías comerciales"},
    {prodId:"40171501",label:"Tubo de acero al carbono soldado para uso comercial"},
    {prodId:"40171502",label:"Tubo de acero al carbono sin uniones para uso comercial"},
    {prodId:"40171503",label:"Tubo de hierro dúctil para uso comercial"},
    {prodId:"40171504",label:"Tubo de aleación de alto níquel para uso comercial"},
    {prodId:"40171505",label:"Tubo de acero de alto rendimiento para uso comercial"},
    {prodId:"40171506",label:"Tubo de aleación ferrosa para uso comercial"},
    {prodId:"40171507",label:"Tubo de aluminio para uso comercial"},
    {prodId:"40171508",label:"Tubo de latón para uso comercial"},
    {prodId:"40171509",label:"Tubo de bronce para uso comercial"},
    {prodId:"40171510",label:"Tubo de concreto para uso comercial"},
    {prodId:"40171511",label:"Tubo de cobre para uso comercial"},
    {prodId:"40171512",label:"Tubo de hierro forjado para uso comercial"},
    {prodId:"40171513",label:"Concentrador de tubo de hierro forjado ensamblado para uso comercial"},
    {prodId:"40171514",label:"Tubo de plomo para uso comercial"},
    {prodId:"40171515",label:"Tubo de magnesio para uso comercial"},
    {prodId:"40171516",label:"Tubo no ferroso para uso comercial"},
    {prodId:"40171517",label:"Tubo pvc para uso comercial"},
    {prodId:"40171518",label:"Tubo cpvc para uso comercial"},
    {prodId:"40171519",label:"Tubo abs para uso comercial"},
    {prodId:"40171520",label:"Tubo hdpe para uso comercial"},
    {prodId:"40171521",label:"Tubo de acero inoxidable soldado para uso comercial"},
    {prodId:"40171522",label:"Tubo de acero inoxidable sin uniones para uso comercial"},
    {prodId:"40171523",label:"Tubo de latón para uso comercial"},
    {prodId:"40171524",label:"Tubo de titanio para uso comercial"},
    {prodId:"40171525",label:"Tubo de zinc para uso comercial"},
    {prodId:"40171526",label:"Tubo de vidrio para uso comercial"},
    {prodId:"40171527",label:"Tubo galvanizado corrugado soldado para uso comercial"},
    {prodId:"40171600",label:"Tubos y tuberías industriales"},
    {prodId:"40171601",label:"Tubo de acero al carbono soldado para uso industrial"},
    {prodId:"40171602",label:"Tubo de acero al carbono sin uniones para uso industrial"},
    {prodId:"40171603",label:"Tubo de hierro dúctil para uso industrial"},
    {prodId:"40171604",label:"Tubo de aleación de alto níquel para uso industrial"},
    {prodId:"40171605",label:"Tubo de acero de alto rendimiento para uso industrial"},
    {prodId:"40171606",label:"Tubo de aleación ferrosa para uso industrial"},
    {prodId:"40171607",label:"Tubo de aluminio para uso industrial"},
    {prodId:"40171608",label:"Tubo de latón para uso industrial"},
    {prodId:"40171609",label:"Tubo de bronce para uso industrial"},
    {prodId:"40171610",label:"Tubo de concreto para uso industrial"},
    {prodId:"40171611",label:"Tubo de cobre para uso industrial"},
    {prodId:"40171612",label:"Tubo de hierro forjado para uso industrial"},
    {prodId:"40171613",label:"Concentrador de tubo de hierro forjado ensamblado para uso industrial"},
    {prodId:"40171614",label:"Tubo de plomo para uso industrial"},
    {prodId:"40171615",label:"Tubo de magnesio para uso industrial"},
    {prodId:"40171616",label:"Tubo no ferroso para uso industrial"},
    {prodId:"40171617",label:"Tubo pvc para uso industrial"},
    {prodId:"40171618",label:"Tubo cpvc para uso industrial"},
    {prodId:"40171619",label:"Tubo abs para uso industrial"},
    {prodId:"40171620",label:"Tubo hdpe para uso industrial"},
    {prodId:"40171621",label:"Tubo de acero inoxidable soldado para uso industrial"},
    {prodId:"40171622",label:"Tubo de acero inoxidable sin uniones para uso industrial"},
    {prodId:"40171623",label:"Tubo de latón para uso industrial"},
    {prodId:"40171624",label:"Tubo de titanio para uso industrial"},
    {prodId:"40171625",label:"Tubo de zinc para uso industrial"},
    {prodId:"40171626",label:"Tubo de vidrio para uso industrial"},
    {prodId:"40171700",label:"Adaptadores de tubos"},
    {prodId:"40171701",label:"Adaptador de tubo de latón"},
    {prodId:"40171702",label:"Adaptador de tubo de acero al carbono"},
    {prodId:"40171703",label:"Adaptador de tubo de hierro forjado"},
    {prodId:"40171704",label:"Adaptador de tubo de hierro dúctil"},
    {prodId:"40171705",label:"Adaptador de tubo de acero forjado"},
    {prodId:"40171706",label:"Adaptador de tubo de hierro maleable"},
    {prodId:"40171707",label:"Adaptador de tubo de acero inoxidable"},
    {prodId:"40171708",label:"Adaptador de tubo plástico pvc"},
    {prodId:"40171709",label:"Adaptador de tubo plástico cpvc"},
    {prodId:"40171710",label:"Adaptador de tubo plástico abs"},
    {prodId:"40171711",label:"Adaptador de tubo plástico hdpe"},
    {prodId:"40171900",label:"Bridas de respaldo para tubos"},
    {prodId:"40171901",label:"Brida de respaldo para tubos de acero al carbono"},
    {prodId:"40171902",label:"Brida de respaldo para tubos de acero forjado"},
    {prodId:"40171903",label:"Brida de respaldo para tubos de acero inoxidable"},
    {prodId:"40171904",label:"Brida de respaldo para tubos de hierro dúctil"},
    {prodId:"40171905",label:"Brida de respaldo para tubos de cobre"},
    {prodId:"40172000",label:"Deflectores para tubos"},
    {prodId:"40172001",label:"Deflectores para tubos de latón"},
    {prodId:"40172002",label:"Deflectores para tubos de hierro dúctil"},
    {prodId:"40172003",label:"Deflectores para tubos de acero forjado"},
    {prodId:"40172004",label:"Deflectores para tubos de acero inoxidable"},
    {prodId:"40172005",label:"Deflectores para tubos de plástico pvc"},
    {prodId:"40172006",label:"Deflectores para tubos de plástico cpvc"},
    {prodId:"40172007",label:"Deflectores para tubos de plástico abs"},
    {prodId:"40172008",label:"Deflectores para tubos de plástico hdpe"},
    {prodId:"40172100",label:"Codos para tubos"},
    {prodId:"40172101",label:"Codos para tubos de hierro forjado"},
    {prodId:"40172102",label:"Codos para tubos de hierro maleable"},
    {prodId:"40172103",label:"Codos para tubos de cobre"},
    {prodId:"40172200",label:"Bridas ciegas para tubos"},
    {prodId:"40172201",label:"Bridas ciegas para tubos de acero al carbono"},
    {prodId:"40172202",label:"Bridas ciegas para tubos de hierro forjado"},
    {prodId:"40172203",label:"Bridas ciegas para tubos de hierro dúctil"},
    {prodId:"40172204",label:"Bridas ciegas para tubos de acero forjado"},
    {prodId:"40172205",label:"Bridas ciegas para tubos de acero inoxidable"},
    {prodId:"40172206",label:"Bridas ciegas para tubos de cobre"},
    {prodId:"40172300",label:"Casquillos para tubos"},
    {prodId:"40172301",label:"Casquillos para tubos de latón"},
    {prodId:"40172302",label:"Casquillos para tubos de acero al carbono"},
    {prodId:"40172303",label:"Casquillos para tubos de hierro forjado"},
    {prodId:"40172304",label:"Casquillos para tubos de hierro dúctil"},
    {prodId:"40172305",label:"Casquillos para tubos de acero forjado"},
    {prodId:"40172306",label:"Casquillos para tubos de hierro maleable"},
    {prodId:"40172307",label:"Casquillos para tubos de acero inoxidable"},
    {prodId:"40172308",label:"Casquillos para tubos de plástico pvc"},
    {prodId:"40172309",label:"Casquillos para tubos de plástico cpvc"},
    {prodId:"40172310",label:"Casquillos para tubos de plástico abs"},
    {prodId:"40172311",label:"Casquillos para tubos de plástico hdpe"},
    {prodId:"40172312",label:"Casquillos para tubos de cobre"},
    {prodId:"40172313",label:"Casquillos para tubos de caucho"},
    {prodId:"40172400",label:"Tapas para tubos"},
    {prodId:"40172401",label:"Tapa de tubo de latón"},
    {prodId:"40172402",label:"Tapa de tubo de acero al carbono"},
    {prodId:"40172403",label:"Tapa de tubo de hierro forjado"},
    {prodId:"40172404",label:"Tapa de tubo de hierro dúctil"},
    {prodId:"40172405",label:"Tapa de tubo de acero forjado"},
    {prodId:"40172406",label:"Tapa de tubo de hierro maleable"},
    {prodId:"40172407",label:"Tapa de tubo de acero inoxidable"},
    {prodId:"40172408",label:"Tapa de tubo de plástico pvc"},
    {prodId:"40172409",label:"Tapa de tubo de plástico cpvc"},
    {prodId:"40172410",label:"Tapa de tubo de plástico abs"},
    {prodId:"40172411",label:"Tapa de tubo de plástico hdpe"},
    {prodId:"40172412",label:"Tapa de tubo de cobre"},
    {prodId:"40172413",label:"Tapa de tubo de caucho"},
    {prodId:"40172500",label:"Conectores de tubos"},
    {prodId:"40172501",label:"Conector de tubo de latón"},
    {prodId:"40172502",label:"Conector de tubo de acero al carbono"},
    {prodId:"40172503",label:"Conector de tubo de hierro forjado"},
    {prodId:"40172504",label:"Conector de tubo de hierro dúctil"},
    {prodId:"40172505",label:"Conector de tubo de acero forjado"},
    {prodId:"40172506",label:"Conector de tubo de hierro maleable"},
    {prodId:"40172507",label:"Conector de tubo de acero inoxidable"},
    {prodId:"40172508",label:"Conector de tubo de plástico pvc"},
    {prodId:"40172509",label:"Conector de tubo de plástico cpvc"},
    {prodId:"40172510",label:"Conector de tubo de plástico abs"},
    {prodId:"40172511",label:"Conector de tubo de plástico hdpe"},
    {prodId:"40172512",label:"Conector de tubo de plástico vidrio reforzado termoestable"},
    {prodId:"40172513",label:"Conector de tubo flexible"},
    {prodId:"40172514",label:"Conector de tubo pre aislado"},
    {prodId:"40172515",label:"Conector de tubo de poli butileno"},
    {prodId:"40172516",label:"Conector de tubo de polietileno"},
    {prodId:"40172517",label:"Conector de tubo de polipropileno"},
    {prodId:"40172518",label:"Conector de tubo de acero revestido"},
    {prodId:"40172519",label:"Conector de tubo de aleación de aluminio y de aluminio"},
    {prodId:"40172520",label:"Conector de tubo de clorhidrato de polivinilo rígido"},
    {prodId:"40172521",label:"Conector de tubo de cobre y de aleación de cobre"},
    {prodId:"40172522",label:"Uniones de tubo flexible"},
    {prodId:"40172600",label:"Acoples de tubos"},
    {prodId:"40172601",label:"Acoples de tubos de latón"},
    {prodId:"40172602",label:"Acoples de tubos de acero al carbono"},
    {prodId:"40172603",label:"Acoples de tubos de hierro forjado"},
    {prodId:"40172604",label:"Acoples de tubos de hierro dúctil"},
    {prodId:"40172605",label:"Acoples de tubos de acero forjado"},
    {prodId:"40172606",label:"Acoples de tubos de hierro maleable"},
    {prodId:"40172607",label:"Acoples de tubos de acero inoxidable"},
    {prodId:"40172608",label:"Acoples de tubos de plástico pvc"},
    {prodId:"40172609",label:"Acoples de tubos de plástico cpvc"},
    {prodId:"40172610",label:"Acoples de tubos abs"},
    {prodId:"40172611",label:"Acoples de tubos de plástico hdpe"},
    {prodId:"40172612",label:"Acoples de tubos de cobre"},
    {prodId:"40172700",label:"Cruces de tubos"},
    {prodId:"40172701",label:"Cruces de tubos de latón"},
    {prodId:"40172702",label:"Cruces de tubos de acero al carbono"},
    {prodId:"40172703",label:"Cruces de tubos de hierro forjado"},
    {prodId:"40172704",label:"Cruces de tubos de hierro dúctil"},
    {prodId:"40172705",label:"Cruces de tubos de acero forjado"},
    {prodId:"40172706",label:"Cruces de tubos de hierro maleable"},
    {prodId:"40172707",label:"Cruces de tubos de acero inoxidable"},
    {prodId:"40172708",label:"Cruces de tubos de plástico pvc"},
    {prodId:"40172709",label:"Cruces de tubos de plástico cpvc"},
    {prodId:"40172710",label:"Cruces de tubos de plástico abs"},
    {prodId:"40172711",label:"Cruces de tubos de plástico hdpe"},
    {prodId:"40172712",label:"Cruces de tubos de cobre"},
    {prodId:"40172800",label:"Codos de tubos"},
    {prodId:"40172801",label:"Codos para tubos de latón"},
    {prodId:"40172802",label:"Codos para tubos de acero al carbono"},
    {prodId:"40172803",label:"Codos para tubos de hierro forjado"},
    {prodId:"40172804",label:"Codos para tubos de hierro dúctil"},
    {prodId:"40172805",label:"Codos para tubos de acero forjado"},
    {prodId:"40172806",label:"Codos para tubos de hierro maleable"},
    {prodId:"40172807",label:"Codos para tubos de acero inoxidable"},
    {prodId:"40172808",label:"Codos para tubos de plástico pvc"},
    {prodId:"40172809",label:"Codos para tubos de plástico cpvc"},
    {prodId:"40172810",label:"Codos para tubos de plástico abs"},
    {prodId:"40172811",label:"Codos para tubos de plástico hdpe"},
    {prodId:"40172812",label:"Codos para tubos de cobre"},
    {prodId:"40172900",label:"Uniones de extensión de tubos"},
    {prodId:"40172901",label:"Uniones de extensión para tubos de acero al carbono"},
    {prodId:"40172902",label:"Uniones de extensión para tubos de hierro forjado"},
    {prodId:"40172903",label:"Uniones de extensión para tubos de hierro dúctil"},
    {prodId:"40172904",label:"Uniones de extensión para tubos de acero forjado"},
    {prodId:"40172905",label:"Uniones de extensión para tubos de acero inoxidable"},
    {prodId:"40172906",label:"Uniones de extensión para tubos de plástico pvc"},
    {prodId:"40172907",label:"Uniones de extensión para tubos de plástico cpvc"},
    {prodId:"40172908",label:"Uniones de extensión para tubos de plástico abs"},
    {prodId:"40172909",label:"Uniones de extensión para tubos de plástico hdpe"},
    {prodId:"40172910",label:"Uniones de extensión para tubos de caucho"},
    {prodId:"40172911",label:"Uniones de extensión para tubos de cobre"},
    {prodId:"40173000",label:"Semiacoplamientos de tubería"},
    {prodId:"40173001",label:"Semiacoplamientos para tubos de latón"},
    {prodId:"40173002",label:"Semiacoplamientos para tubos de acero al carbono"},
    {prodId:"40173003",label:"Semiacoplamientos para tubos de acero forjado"},
    {prodId:"40173004",label:"Semiacoplamientos para tubos de hierro maleable"},
    {prodId:"40173005",label:"Semiacoplamientos para tubos de acero inoxidable"},
    {prodId:"40173006",label:"Semiacoplamientos para tubos de plástico pvc"},
    {prodId:"40173007",label:"Semiacoplamientos para tubos de plástico cpvc"},
    {prodId:"40173008",label:"Semiacoplamientos para tubos de plástico abs"},
    {prodId:"40173009",label:"Semiacoplamientos para tubos de plástico hdpe"},
    {prodId:"40173100",label:"Brida de junta de empalme para tubos"},
    {prodId:"40173101",label:"Brida de junta de empalme para tubos de acero al carbono"},
    {prodId:"40173102",label:"Brida de junta de empalme para tubos de acero forjado"},
    {prodId:"40173103",label:"Brida de junta de empalme para tubos de acero inoxidable"},
    {prodId:"40173104",label:"Brida de junta de empalme para tubos de cobre"},
    {prodId:"40173200",label:"Bridas cuello largas para soldar"},
    {prodId:"40173201",label:"Bridas cuello largas para soldar para tubos de acero al carbono"},
    {prodId:"40173202",label:"Bridas cuello largas para soldar para tubos de acero forjado"},
    {prodId:"40173203",label:"Bridas cuello largas para soldar para tubos de acero inoxidable"},
    {prodId:"40173300",label:"Boquillas de tubos"},
    {prodId:"40173301",label:"Boquillas para tubos de latón"},
    {prodId:"40173302",label:"Boquillas para tubos de acero al carbono"},
    {prodId:"40173303",label:"Boquillas para tubos de acero inoxidable"},
    {prodId:"40173304",label:"Boquillas para tubos de plástico pvc"},
    {prodId:"40173305",label:"Boquillas para tubos de plástico cpvc"},
    {prodId:"40173306",label:"Boquillas para tubos de plástico abs"},
    {prodId:"40173307",label:"Boquillas para tubos de plástico hdpe"},
    {prodId:"40173400",label:"Bridas de plato"},
    {prodId:"40173401",label:"Bridas de plato para tubos de latón"},
    {prodId:"40173402",label:"Bridas de plato para tubos de acero al carbono"},
    {prodId:"40173403",label:"Bridas de plato para tubos de hierro forjado"},
    {prodId:"40173404",label:"Bridas de plato para tubos de hierro dúctil"},
    {prodId:"40173405",label:"Bridas de plato para tubos de hierro maleable"},
    {prodId:"40173406",label:"Bridas de plato para tubos de acero inoxidable"},
    {prodId:"40173407",label:"Bridas de plato para tubos de plástico abs"},
    {prodId:"40173408",label:"Bridas de plato para tubos de plástico pvc"},
    {prodId:"40173409",label:"Bridas de plato para tubos de cobre"},
    {prodId:"40173500",label:"Tapones para tubos"},
    {prodId:"40173501",label:"Tapones para tubos de latón"},
    {prodId:"40173502",label:"Tapones para tubos de acero al carbono"},
    {prodId:"40173503",label:"Tapones para tubos de hierro forjado"},
    {prodId:"40173504",label:"Tapones para tubos de hierro dúctil"},
    {prodId:"40173505",label:"Tapones para tubos de acero forjado"},
    {prodId:"40173506",label:"Tapones para tubos de hierro maleable"},
    {prodId:"40173507",label:"Tapones para tubos de acero inoxidable"},
    {prodId:"40173508",label:"Tapones para tubos de plástico pvc"},
    {prodId:"40173509",label:"Tapones para tubos de plástico cpvc"},
    {prodId:"40173510",label:"Tapones para tubos de plástico abs"},
    {prodId:"40173511",label:"Tapones para tubos de plástico hdpe"},
    {prodId:"40173512",label:"Tapones para tubos de cobre"},
    {prodId:"40173513",label:"Tapones para tubos de caucho"},
    {prodId:"40173600",label:"Acoplamientos reductores para tubos"},
    {prodId:"40173601",label:"Acoplamiento reductor para tubos de latón"},
    {prodId:"40173602",label:"Acoplamiento reductor para tubos de acero al carbono"},
    {prodId:"40173603",label:"Acoplamiento reductor para tubos de hierro forjado"},
    {prodId:"40173604",label:"Acoplamiento reductor para tubos de hierro dúctil"},
    {prodId:"40173605",label:"Acoplamiento reductor para tubos de acero forjado"},
    {prodId:"40173606",label:"Acoplamiento reductor para tubos de hierro maleable"},
    {prodId:"40173607",label:"Acoplamiento reductor para tubos de acero inoxidable"},
    {prodId:"40173608",label:"Acoplamiento reductor para tubos de plástico pvc"},
    {prodId:"40173609",label:"Acoplamiento reductor para tubos de plástico cpvc"},
    {prodId:"40173610",label:"Acoplamiento reductor para tubos de plástico abs"},
    {prodId:"40173611",label:"Acoplamiento reductor para tubos de plástico hdpe"},
    {prodId:"40173612",label:"Acoplamiento reductor para tubos de caucho"},
    {prodId:"40173613",label:"Acoplamiento reductor para tubos de cobre"},
    {prodId:"40173800",label:"Abrazaderas de reparación para tubos"},
    {prodId:"40173801",label:"Abrazadera de reparación para tubos de hierro forjado"},
    {prodId:"40173802",label:"Abrazadera de reparación para tubos de hierro dúctil"},
    {prodId:"40173803",label:"Abrazadera de reparación para tubos de acero forjado"},
    {prodId:"40173804",label:"Abrazadera de reparación para tubos de acero inoxidable"},
    {prodId:"40173805",label:"Abrazadera de reparación para tubos de plástico pvc"},
    {prodId:"40173806",label:"Abrazadera de reparación para tubos de plástico cpvc"},
    {prodId:"40173807",label:"Abrazadera de reparación para tubos de plástico abs"},
    {prodId:"40173808",label:"Abrazadera de reparación para tubos de plástico hdpe"},
    {prodId:"40173900",label:"Disco de ruptura para tubos"},
    {prodId:"40173901",label:"Disco de ruptura para tubos de acero al carbono"},
    {prodId:"40173902",label:"Disco de ruptura para tubos de hierro forjado"},
    {prodId:"40173903",label:"Disco de ruptura para tubos de hierro dúctil"},
    {prodId:"40173904",label:"Disco de ruptura para tubos de acero forjado"},
    {prodId:"40173905",label:"Disco de ruptura para tubos de hierro maleable"},
    {prodId:"40173906",label:"Disco de ruptura para tubos de acero inoxidable"},
    {prodId:"40173907",label:"Disco de ruptura para tubos de plástico pvc"},
    {prodId:"40173908",label:"Disco de ruptura para tubos de plástico cpvc"},
    {prodId:"40173909",label:"Disco de ruptura para tubos de plástico abs"},
    {prodId:"40173910",label:"Disco de ruptura para tubos de plástico hdpe"},
    {prodId:"40174100",label:"Brida encajada por soldadura para tubos"},
    {prodId:"40174101",label:"Brida encajada por soldadura para tubos de acero al carbono"},
    {prodId:"40174102",label:"Brida encajada por soldadura para tubos de acero forjado"},
    {prodId:"40174103",label:"Brida encajada por soldadura para tubos de acero inoxidable"},
    {prodId:"40174104",label:"Brida encajada por soldadura para tubos de plástico pvc"},
    {prodId:"40174105",label:"Brida encajada por soldadura para tubos de plástico cpvc"},
    {prodId:"40174106",label:"Brida encajada por soldadura para tubos de plástico abs"},
    {prodId:"40174107",label:"Brida encajada por soldadura para tubos de plástico hdpe"},
    {prodId:"40174108",label:"Brida encajada por soldadura para tubos de cobre"},
    {prodId:"40174300",label:"Espaciadores para tubos"},
    {prodId:"40174301",label:"Espaciador para tubos de latón"},
    {prodId:"40174302",label:"Espaciador para tubos de acero al carbono"},
    {prodId:"40174303",label:"Espaciador para tubos de hierro forjado"},
    {prodId:"40174304",label:"Espaciador para tubos de hierro dúctil"},
    {prodId:"40174305",label:"Espaciador para tubos de acero forjado"},
    {prodId:"40174306",label:"Espaciador para tubos de acero inoxidable"},
    {prodId:"40174307",label:"Espaciador para tubos de plástico pvc"},
    {prodId:"40174308",label:"Espaciador para tubos de plástico cpvc"},
    {prodId:"40174309",label:"Espaciador para tubos de plástico abs"},
    {prodId:"40174310",label:"Espaciador para tubos de plástico hdpe"},
    {prodId:"40174400",label:"Bridas ciegas para tubos"},
    {prodId:"40174401",label:"Bridas ciegas para tubos de acero al carbono"},
    {prodId:"40174402",label:"Bridas ciegas para tubos de acero forjado"},
    {prodId:"40174403",label:"Bridas ciegas para tubos de acero forjado"},
    {prodId:"40174500",label:"Pivotes o uniones rotativas para tubos"},
    {prodId:"40174501",label:"Pivotes o uniones rotativas para tubos de latón"},
    {prodId:"40174502",label:"Pivotes o uniones rotativas para tubos de plástico pvc"},
    {prodId:"40174503",label:"Pivotes o uniones rotativas para tubos de plástico cpvc"},
    {prodId:"40174504",label:"Pivotes o uniones rotativas para tubos de plástico abs"},
    {prodId:"40174505",label:"Pivotes o uniones rotativas para tubos de plástico hdpe"},
    {prodId:"40174506",label:"Pivotes o uniones rotativas para tubos de cobre"},
    {prodId:"40174600",label:"T para tubos"},
    {prodId:"40174601",label:"T para tubos de latón"},
    {prodId:"40174602",label:"T para tubos de acero al carbono"},
    {prodId:"40174603",label:"T para tubos de hierro forjado"},
    {prodId:"40174604",label:"T para tubos de hierro dúctil"},
    {prodId:"40174605",label:"T para tubos de acero forjado"},
    {prodId:"40174606",label:"T para tubos de hierro maleable"},
    {prodId:"40174607",label:"T para tubos de acero inoxidable"},
    {prodId:"40174608",label:"T para tubos de plástico pvc"},
    {prodId:"40174609",label:"T para tubos de plástico cpvc"},
    {prodId:"40174610",label:"T para tubos de plástico abs"},
    {prodId:"40174611",label:"T para tubos de plástico hdpe"},
    {prodId:"40174612",label:"T para tubos de cobre"},
    {prodId:"40174700",label:"Brida roscada para tubos"},
    {prodId:"40174701",label:"Brida roscada para tubos de latón"},
    {prodId:"40174702",label:"Brida roscada para tubos de acero al carbono"},
    {prodId:"40174703",label:"Brida roscada para tubos de hierro forjado"},
    {prodId:"40174704",label:"Brida roscada para tubos de hierro dúctil"},
    {prodId:"40174705",label:"Brida roscada para tubos de acero forjado"},
    {prodId:"40174706",label:"Brida roscada para tubos de hierro maleable"},
    {prodId:"40174707",label:"Brida roscada para tubos de acero inoxidable"},
    {prodId:"40174708",label:"Brida roscada para tubos de plástico pvc"},
    {prodId:"40174709",label:"Brida roscada para tubos de plástico cpvc"},
    {prodId:"40174710",label:"Brida roscada para tubos de plástico abs"},
    {prodId:"40174711",label:"Brida roscada para tubos de plástico hdpe"},
    {prodId:"40174800",label:"Bridas de inodoro para tubos"},
    {prodId:"40174801",label:"Bridas de inodoro para tubos de acero al carbono"},
    {prodId:"40174802",label:"Bridas de inodoro para tubos de hierro forjado"},
    {prodId:"40174803",label:"Bridas de inodoro para tubos de plástico pvc"},
    {prodId:"40174804",label:"Bridas de inodoro para tubos de plástico cpvc"},
    {prodId:"40174805",label:"Bridas de inodoro para tubos de plástico abs"},
    {prodId:"40174806",label:"Bridas de inodoro para tubos de plástico hdpe"},
    {prodId:"40174807",label:"Bridas de inodoro para tubos de latón"},
    {prodId:"40174808",label:"Bridas de inodoro para tubos de cobre"},
    {prodId:"40174900",label:"Uniones para tubos"},
    {prodId:"40174901",label:"Uniones para tubos de latón"},
    {prodId:"40174902",label:"Uniones para tubos de acero al carbono"},
    {prodId:"40174903",label:"Uniones para tubos de hierro forjado"},
    {prodId:"40174904",label:"Uniones para tubos de hierro dúctil"},
    {prodId:"40174905",label:"Uniones para tubos de acero forjado"},
    {prodId:"40174906",label:"Uniones para tubos de hierro maleable"},
    {prodId:"40174907",label:"Uniones para tubos de acero inoxidable"},
    {prodId:"40174908",label:"Uniones para tubos de plástico pvc"},
    {prodId:"40174909",label:"Uniones para tubos de plástico cpvc"},
    {prodId:"40174910",label:"Uniones para tubos de plástico abs"},
    {prodId:"40174911",label:"Uniones para tubos de plástico hdpe"},
    {prodId:"40174912",label:"Uniones para tubos de cobre"},
    {prodId:"40175000",label:"Bridas cuello para tubos"},
    {prodId:"40175001",label:"Bridas cuello para tubos de acero al carbono"},
    {prodId:"40175002",label:"Bridas cuello para tubos de acero forjado"},
    {prodId:"40175003",label:"Bridas cuello para tubos de acero inoxidable"},
    {prodId:"40175200",label:"Y de conexión para tubos"},
    {prodId:"40175201",label:"Y de conexión para tubos de latón"},
    {prodId:"40175202",label:"Y de conexión para tubos de acero al carbono"},
    {prodId:"40175203",label:"Y de conexión para tubos de hierro forjado"},
    {prodId:"40175204",label:"Y de conexión para tubos de hierro dúctil"},
    {prodId:"40175205",label:"Y de conexión para tubos de acero forjado"},
    {prodId:"40175206",label:"Y de conexión para tubos de hierro maleable"},
    {prodId:"40175207",label:"Y de conexión para tubos de acero inoxidable"},
    {prodId:"40175208",label:"Y de conexión para tubos de plástico pvc"},
    {prodId:"40175209",label:"Y de conexión para tubos de plástico cpvc"},
    {prodId:"40175210",label:"Y de conexión para tubos de plástico abs"},
    {prodId:"40175211",label:"Y de conexión para tubos de plástico hdpe"},
    {prodId:"40175212",label:"Y de conexión para tubos de cobre"},
    {prodId:"40175300",label:"Accesorios y bridas especializados para tubos"},
    {prodId:"40175301",label:"Bridas de amoniaco"},
    {prodId:"40175302",label:"Bridas de orificio"},
    {prodId:"40175303",label:"Salidas para ramas de tubería"},
    {prodId:"40175304",label:"Cajas de conexión de tubos"},
    {prodId:"40175305",label:"Insertos de tubos"},
    {prodId:"40175306",label:"Laterales de tubos"},
    {prodId:"40175307",label:"Sillas de tubos"},
    {prodId:"40175308",label:"Remates de secciones de tuberías"},
    {prodId:"40175309",label:"Bridas de paleta para tubos"},
    {prodId:"40181700",label:"Tubos de aluminio soldados"},
    {prodId:"40181701",label:"Tubo doblado de aluminio soldado"},
    {prodId:"40181702",label:"Tubo perforado de aluminio soldado"},
    {prodId:"40181703",label:"Tubo de remate final de aluminio soldado"},
    {prodId:"40181704",label:"Tubo multipuerto de aluminio soldado"},
    {prodId:"40181705",label:"Tubo biselado de aluminio soldado"},
    {prodId:"40181706",label:"Ensamblaje de tubos de aluminio soldado"},
    {prodId:"40181800",label:"Tubos de acero soldados"},
    {prodId:"40181801",label:"Tubo doblado de acero soldado"},
    {prodId:"40181802",label:"Tubo perforado de acero soldado"},
    {prodId:"40181803",label:"Tubo de remate final de acero soldado"},
    {prodId:"40181804",label:"Tubo multipuerto de acero soldado"},
    {prodId:"40181805",label:"Tubo biselado de acero soldado"},
    {prodId:"40181806",label:"Ensamblaje de tubos de acero soldado"},
    {prodId:"40181900",label:"Tubos de acero inoxidable soldados"},
    {prodId:"40181901",label:"Tubo doblado de acero inoxidable soldado"},
    {prodId:"40181902",label:"Tubo perforado de acero inoxidable soldado"},
    {prodId:"40181903",label:"Tubo de remate final de acero inoxidable soldado"},
    {prodId:"40181904",label:"Tubo multipuerto de acero inoxidable soldado"},
    {prodId:"40181905",label:"Tubo biselado de acero inoxidable soldado"},
    {prodId:"40181906",label:"Ensamblaje de tubos de acero inoxidable soldado"},
    {prodId:"40182000",label:"Tubos de cobre sin uniones"},
    {prodId:"40182001",label:"Tubo doblado de cobre sin uniones"},
    {prodId:"40182002",label:"Tubo perforado de cobre sin uniones"},
    {prodId:"40182003",label:"Tubo de remate final de cobre sin uniones"},
    {prodId:"40182004",label:"Tubo multipuerto de cobre sin uniones"},
    {prodId:"40182005",label:"Tubo biselado de cobre sin uniones"},
    {prodId:"40182006",label:"Ensamblaje de tubos de cobre sin uniones"},
    {prodId:"40182300",label:"Tubos de acero sin uniones"},
    {prodId:"40182301",label:"Tubo doblado de acero sin uniones"},
    {prodId:"40182302",label:"Tubo perforado de acero sin uniones"},
    {prodId:"40182303",label:"Tubo de remate final de acero sin uniones"},
    {prodId:"40182304",label:"Tubo multipuerto de acero sin uniones"},
    {prodId:"40182305",label:"Tubo biselado de acero sin uniones"},
    {prodId:"40182306",label:"Ensamblaje de tubos de acero sin uniones"},
    {prodId:"40182400",label:"Tubos de acero inoxidable sin uniones"},
    {prodId:"40182401",label:"Tubo doblado de acero inoxidable sin uniones"},
    {prodId:"40182402",label:"Tubo perforado de acero inoxidable sin uniones"},
    {prodId:"40182403",label:"Tubo de remate final de acero inoxidable sin uniones"},
    {prodId:"40182404",label:"Tubo multipuerto de acero inoxidable sin uniones"},
    {prodId:"40182405",label:"Tubo biselado de acero inoxidable sin uniones"},
    {prodId:"40182406",label:"Ensamblaje de tubos de acero inoxidable sin uniones"},
    {prodId:"40182700",label:"Tubos de aluminio extruidos"},
    {prodId:"40182701",label:"Tubo doblado de aluminio extruido"},
    {prodId:"40182702",label:"Tubo perforado de aluminio extruido"},
    {prodId:"40182703",label:"Tubo de remate final de aluminio extruido"},
    {prodId:"40182704",label:"Tubo multipuerto de aluminio extruido"},
    {prodId:"40182705",label:"Tubo biselado de aluminio extruido"},
    {prodId:"40182706",label:"Ensamblaje de tubos de aluminio extruido"},
    {prodId:"40182707",label:"Tubería cubierta de aluminio extruido"},
    {prodId:"40182900",label:"Tubos de acero inoxidable extruidos"},
    {prodId:"40182901",label:"Tubo doblado de acero inoxidable extruido"},
    {prodId:"40182902",label:"Tubo perforado de acero inoxidable extruido"},
    {prodId:"40182903",label:"Tubo de remate final de acero inoxidable extruido"},
    {prodId:"40182904",label:"Tubo multipuerto de acero inoxidable extruido"},
    {prodId:"40182905",label:"Tubo biselado de acero inoxidable extruido"},
    {prodId:"40182906",label:"Ensamblaje de tubos de acero inoxidable extruido"},
    {prodId:"40183000",label:"Tuberías de caucho y plástico"},
    {prodId:"40183001",label:"Tubería de caucho"},
    {prodId:"40183002",label:"Tubería de plástico pvc"},
    {prodId:"40183003",label:"Tubería de plástico cpvc"},
    {prodId:"40183004",label:"Tubería de plástico hdpe"},
    {prodId:"40183005",label:"Tubería de caucho de baja presión"},
    {prodId:"40183006",label:"Tubería termo contraíble"},
    {prodId:"40183007",label:"Tubería de espuma de caucho"},
    {prodId:"40183008",label:"Tubería de poliuretano pur"},
    {prodId:"40183009",label:"Tubería de manga trenzada"},
    {prodId:"40183010",label:"Tubería de alta presión con ensamblaje"},
    {prodId:"40183100",label:"Accesorios de tubos"},
    {prodId:"40183101",label:"Codo de tubo"},
    {prodId:"40183102",label:"“t” de tubo"},
    {prodId:"40183103",label:"Unión de tubo"},
    {prodId:"40183104",label:"Tapón de tubo"},
    {prodId:"40183105",label:"Boquilla de tubo"},
    {prodId:"40183106",label:"Pistón de tubo"},
    {prodId:"40183107",label:"Acople de tubo"},
    {prodId:"40183108",label:"Casquillo de tubo"},
    {prodId:"40183109",label:"Adaptador de tubo"},
    {prodId:"40183110",label:"Conector de tubo"},
    {prodId:"40183111",label:"Cruz de tubo"},
    {prodId:"40183112",label:"Reductor de tubo"},
    {prodId:"41101500",label:"Equipo y suministros para la mezcla, la dispersión y la homogeneización en laboratorio"},
    {prodId:"41101502",label:"Bolsas “stomachers”"},
    {prodId:"41101503",label:"Rociadores de laboratorio"},
    {prodId:"41101504",label:"Homogeneizadores"},
    {prodId:"41101505",label:"Celdas de presión francesas"},
    {prodId:"41101515",label:"Latas medidoras de líquido"},
    {prodId:"41101516",label:"Homogeneizadores dobles"},
    {prodId:"41101518",label:"Mezcladores o emulsificadores de laboratorio"},
    {prodId:"41101700",label:"Equipo de perforación, amoladura, corte, trituración y prensado para laboratorio"},
    {prodId:"41101701",label:"Molinos de laboratorio"},
    {prodId:"41101702",label:"Pilones y morteros"},
    {prodId:"41101703",label:"Moledoras de tejidos"},
    {prodId:"41101705",label:"Trituradoras o pulverizadoras de laboratorio"},
    {prodId:"41101706",label:"Desintegradores de laboratorio"},
    {prodId:"41101707",label:"Prensas de laboratorio"},
    {prodId:"41101708",label:"Esmeriladora o pulidora de laboratorio"},
    {prodId:"41101709",label:"Mezcladora de asfalto y concreto de laboratorio"},
    {prodId:"41101800",label:"Equipo de física de electrones y de estado sólido para laboratorio"},
    {prodId:"41101801",label:"Pistolas de electrones"},
    {prodId:"41101802",label:"Generadores de rayos x"},
    {prodId:"41101803",label:"Coulómetros"},
    {prodId:"41101804",label:"Electroscopios"},
    {prodId:"41101805",label:"Flujómetros"},
    {prodId:"41101806",label:"Magnetómetros"},
    {prodId:"41101807",label:"Aparatos de difracción de electrones"},
    {prodId:"41101808",label:"Aparatos de difracción de neutrones"},
    {prodId:"41101809",label:"Aparatos de difracción óptica"},
    {prodId:"41101810",label:"Difractómetros"},
    {prodId:"41101811",label:"Micro analizador de sondeo de electrones por rayos x"},
    {prodId:"41101812",label:"Acelerador de partículas"},
    {prodId:"41101813",label:"Controlador de equipos de rayos x de laboratorio"},
    {prodId:"41102400",label:"Equipo de calefacción y secadores para laboratorio"},
    {prodId:"41102401",label:"Quemadores de gas"},
    {prodId:"41102402",label:"Quemadores de alcohol"},
    {prodId:"41102403",label:"Incineradores de laboratorio"},
    {prodId:"41102404",label:"Calentadores de laboratorio"},
    {prodId:"41102405",label:"Revestimientos o cintas calentadoras"},
    {prodId:"41102406",label:"Hornillas eléctricas de laboratorio"},
    {prodId:"41102407",label:"Gabinetes calentadores"},
    {prodId:"41102410",label:"Secadoras infrarrojas"},
    {prodId:"41102412",label:"Secadores de aire caliente"},
    {prodId:"41102421",label:"Cámaras de reciclaje de temperatura o recicladores térmicos"},
    {prodId:"41102422",label:"Baños secos o bloques calentadores"},
    {prodId:"41102423",label:"Hornillas eléctricas agitadoras"},
    {prodId:"41102424",label:"Calentadores de deslizamiento"},
    {prodId:"41102425",label:"Secadores de deslizamiento"},
    {prodId:"41102426",label:"Equipos o accesorios para calentar o secar"},
    {prodId:"41102427",label:"Torre secadora"},
    {prodId:"41102428",label:"Papel altamente absorbente"},
    {prodId:"41102429",label:"Sellador por calor de unidades de tubos de muestras de sangre"},
    {prodId:"41102500",label:"Equipo entomológico para laboratorio y accesorios"},
    {prodId:"41102501",label:"Contenedores de insectos para laboratorio"},
    {prodId:"41102502",label:"Instalaciones de crianza para entomología"},
    {prodId:"41102503",label:"Textiles o redes para entomología"},
    {prodId:"41102504",label:"Equipo de entomología para clavar especímenes"},
    {prodId:"41102505",label:"Materiales de ensamblaje para entomología"},
    {prodId:"41102506",label:"Bandejas de entomología"},
    {prodId:"41102507",label:"Equipo para recolectar especímenes de entomología"},
    {prodId:"41102508",label:"Aspiradoras para entomología"},
    {prodId:"41102509",label:"Cucharones para entomología"},
    {prodId:"41102510",label:"Mono copas para entomología"},
    {prodId:"41102511",label:"Trampas pegajosas para entomología"},
    {prodId:"41102512",label:"Kits de pruebas para insectos"},
    {prodId:"41102513",label:"Unidades de despliegue para entomología"},
    {prodId:"41102600",label:"Equipo y accesorios para laboratorio animal"},
    {prodId:"41102601",label:"Jaulas para animales pequeños para laboratorio"},
    {prodId:"41102602",label:"Equipo de acuarios"},
    {prodId:"41102603",label:"Suministros para identificación de animales"},
    {prodId:"41102604",label:"Dispositivos para atrapar animales"},
    {prodId:"41102605",label:"Sistemas de aireación de peces"},
    {prodId:"41102606",label:"Restricciones o arneses para animales para laboratorio"},
    {prodId:"41102607",label:"Agujas para alimentar animales"},
    {prodId:"41102608",label:"Equipos para pruebas de animales"},
    {prodId:"41102609",label:"Pistola anestésica"},
    {prodId:"41102900",label:"Equipo de histología"},
    {prodId:"41102901",label:"Estaciones de incrustación de tejidos"},
    {prodId:"41102902",label:"Moldes de incrustación"},
    {prodId:"41102903",label:"Cápsulas de incrustación"},
    {prodId:"41102904",label:"Compuestos de incrustación"},
    {prodId:"41102905",label:"Aparatos de teñido histológico"},
    {prodId:"41102909",label:"Procesadores de tejidos"},
    {prodId:"41102910",label:"Aparatos de cultivo de tejidos"},
    {prodId:"41102911",label:"Cuchillos o sujeta cuchillos o cuchillas histológicos"},
    {prodId:"41102912",label:"Cuchillos marcadores de vidrio histológicos"},
    {prodId:"41102913",label:"Afiladores o correas o compuestos histológicos"},
    {prodId:"41102914",label:"Desintegradores ultrasónicos"},
    {prodId:"41102915",label:"Estaciones de muestreo y disección histológica"},
    {prodId:"41102916",label:"Micrótomos"},
    {prodId:"41102917",label:"Cuchillas para micrótomos"},
    {prodId:"41102918",label:"Forros deslizantes para laboratorio"},
    {prodId:"41102919",label:"Recicladores de solventes"},
    {prodId:"41102920",label:"Casetes para tejidos para histología"},
    {prodId:"41102921",label:"Parafina para histología"},
    {prodId:"41102922",label:"Equipo de forros deslizantes automatizado"},
    {prodId:"41102923",label:"Contador de celdas somático"},
    {prodId:"41102924",label:"Etiquetador de casetes de tejidos automatizado"},
    {prodId:"41102925",label:"Arcador permanente resistente a la formalina y al solvente para histología"},
    {prodId:"41102926",label:"Tolva de casetes de tejidos para histología"},
    {prodId:"41102927",label:"Implemento para procesar tejidos para histología"},
    {prodId:"41103000",label:"Equipo de enfriamiento para laboratorio"},
    {prodId:"41103001",label:"Sondas planas enfriadoras refrigeradas"},
    {prodId:"41103003",label:"Criostatos"},
    {prodId:"41103004",label:"Hornos de circulación por ventilador"},
    {prodId:"41103005",label:"Gabinetes o congeladores ultra fríos o ultra bajos independientes"},
    {prodId:"41103006",label:"Congeladores criogénicos o de nitrógeno líquido"},
    {prodId:"41103007",label:"Unidades de enfriamiento o circuladores de agua fría"},
    {prodId:"41103008",label:"Módulos enfriadores refrigerados"},
    {prodId:"41103010",label:"Refrigeradores para bancos de sangre"},
    {prodId:"41103011",label:"Refrigeradores para propósitos generales o neveras congeladores"},
    {prodId:"41103012",label:"Refrigeradores o neveras congeladores para almacenar material inflamable"},
    {prodId:"41103013",label:"Refrigeradores o neveras congeladores a prueba de explosiones"},
    {prodId:"41103014",label:"Refrigeradores de cromatografía"},
    {prodId:"41103015",label:"Congeladores para bancos de sangre"},
    {prodId:"41103017",label:"Congeladores para almacenar material inflamable"},
    {prodId:"41103019",label:"Congeladores para almacenar plasma"},
    {prodId:"41103020",label:"Congeladores de cofre ultra fríos o ultra bajos"},
    {prodId:"41103021",label:"Congeladores planos para laboratorio"},
    {prodId:"41103022",label:"Transporte o almacenamiento frío"},
    {prodId:"41103023",label:"Enfriadores para laboratorio"},
    {prodId:"41103024",label:"Trampas frías"},
    {prodId:"41103025",label:"Accesorios para equipos enfriadores de laboratorio"},
    {prodId:"41103026",label:"Cubeta de hielo o contenedor enfriador para mesa de trabajo"},
    {prodId:"41103027",label:"Cabina de almacenamiento de unidades de sangre"},
    {prodId:"41103200",label:"Equipo de lavado de laboratorio"},
    {prodId:"41103201",label:"Lavadoras de ingeniería química"},
    {prodId:"41103202",label:"Máquinas lavadoras para laboratorio"},
    {prodId:"41103203",label:"Lavadoras de pipetas"},
    {prodId:"41103205",label:"Estantes o accesorios para máquinas lavadoras"},
    {prodId:"41103206",label:"Detergentes de lavado para laboratorios"},
    {prodId:"41103207",label:"Lavadoras de micro placas"},
    {prodId:"41103208",label:"Lavadoras de celdas de bancos de sangre"},
    {prodId:"41103209",label:"Botellas de lavado de laboratorios"},
    {prodId:"41103210",label:"Esterilizadores uv ultravioleta para laboratorios"},
    {prodId:"41103211",label:"Equipos para el tratamiento de aguas residuales de laboratorio"},
    {prodId:"41103212",label:"Cepillo limpiador de cristalería de laboratorio"},
    {prodId:"41103300",label:"Equipo de mecánica de fluidos"},
    {prodId:"41103301",label:"Contadores centellantes líquidos"},
    {prodId:"41103302",label:"Hidrómetros ácidos de batería"},
    {prodId:"41103303",label:"Densitómetros"},
    {prodId:"41103305",label:"Equipos de alto vacío"},
    {prodId:"41103306",label:"Equipos de vacío neumático"},
    {prodId:"41103307",label:"Equipos de vacío o vapor de mercurio"},
    {prodId:"41103308",label:"Aparatos de combustión de alto vacío"},
    {prodId:"41103309",label:"Equipos de análisis de inyección de flujo"},
    {prodId:"41103310",label:"Instrumentos para medir la concentración de gas o vapor"},
    {prodId:"41103311",label:"Manómetros"},
    {prodId:"41103312",label:"Viscosímetros"},
    {prodId:"41103313",label:"Indicadores de profundidad"},
    {prodId:"41103314",label:"Aparatos de estimación de estructura microscópica"},
    {prodId:"41103315",label:"Aparatos de estimación de fuerza de una solución"},
    {prodId:"41103316",label:"Picnómetros"},
    {prodId:"41103317",label:"Instrumentos para medir la tensión de la superficie"},
    {prodId:"41103318",label:"Densitómetro nuclear"},
    {prodId:"41103319",label:"Instrumento para medir la concentración"},
    {prodId:"41103320",label:"Instrumento para medir la densidad"},
    {prodId:"41103321",label:"Aparatos para medir la estabilidad de cuerpos flotantes"},
    {prodId:"41103322",label:"Evaluador de corriente de aire"},
    {prodId:"41103323",label:"Flujómetro acústico de canal abierto"},
    {prodId:"41103324",label:"Generador de ondas para laboratorio"},
    {prodId:"41103325",label:"Aparato de visualización de flujo de fluido"},
    {prodId:"41103326",label:"Túnel de viento"},
    {prodId:"41103327",label:"Medidor de corriente"},
    {prodId:"41103400",label:"Equipo de acondicionamiento ambiental para laboratorio"},
    {prodId:"41103401",label:"Pantallas de control de contaminación"},
    {prodId:"41103403",label:"Equipos de control de aire microbiológico"},
    {prodId:"41103406",label:"Cajas de guantes de aislamiento"},
    {prodId:"41103407",label:"Cámara anaeróbica"},
    {prodId:"41103408",label:"Alcance refrigerado para cámaras ambientales o de cultivo"},
    {prodId:"41103409",label:"Alcance caliente para cámaras ambientales o de cultivo"},
    {prodId:"41103410",label:"Alcance refrigerado y caliente para cámaras ambientales y de cultivo"},
    {prodId:"41103411",label:"Camino refrigerado para cámaras ambientales o de cultivo"},
    {prodId:"41103412",label:"Camino caliente para cámaras ambientales o de cultivo"},
    {prodId:"41103413",label:"Camino refrigerado y caliente para cámaras ambientales o de cultivo"},
    {prodId:"41103414",label:"Accesorios para equipos acondicionadores de ambiente para laboratorios"},
    {prodId:"41103415",label:"Bancos limpios"},
    {prodId:"41103416",label:"Cámara de ciclos de temperatura"},
    {prodId:"41103417",label:"Monitor de calidad del aire interior"},
    {prodId:"41103418",label:"Cámara ambiental de temperatura y humedad a la que se puede entrar"},
    {prodId:"41103419",label:"Cámara de polución ambiental"},
    {prodId:"41103420",label:"Cámara de ozono ambiental"},
    {prodId:"41103421",label:"Cámara de explosión ambiental"},
    {prodId:"41103500",label:"Recintos cerrados de laboratorio y accesorios"},
    {prodId:"41103501",label:"Ebullómetro"},
    {prodId:"41103502",label:"Caperuzas o cajones para gases"},
    {prodId:"41103504",label:"Gabinetes o estaciones para flujo laminar"},
    {prodId:"41103506",label:"Cerramientos pcr"},
    {prodId:"41103507",label:"Cerramientos hepa filtrados"},
    {prodId:"41103508",label:"Cerramientos carbono filtrados"},
    {prodId:"41103509",label:"Estropajos para laboratorio"},
    {prodId:"41103510",label:"Secadores para laboratorio"},
    {prodId:"41103511",label:"Accesorios de cerramiento para laboratorio"},
    {prodId:"41103512",label:"Eliminadores de estática"},
    {prodId:"41103513",label:"Cerramientos para cultivo de tejidos"},
    {prodId:"41103514",label:"Generador de vapor para laboratorio"},
    {prodId:"41103515",label:"Generador de gas para laboratorio"},
    {prodId:"41103516",label:"Capucha de ventilación"},
    {prodId:"41103700",label:"Cubetas de laboratorio"},
    {prodId:"41103701",label:"Baños de circulación"},
    {prodId:"41103702",label:"Baños termostáticos"},
    {prodId:"41103703",label:"Baños múltiples"},
    {prodId:"41103704",label:"Baños biológicos"},
    {prodId:"41103705",label:"Baños para órganos"},
    {prodId:"41103706",label:"Baños de agua"},
    {prodId:"41103707",label:"Baños de aceite"},
    {prodId:"41103708",label:"Baños de arena"},
    {prodId:"41103709",label:"Baños refrigerados"},
    {prodId:"41103710",label:"Baños de agua de agitación orbital"},
    {prodId:"41103711",label:"Baños de agua de agitación recíproca"},
    {prodId:"41103712",label:"Circuladores de inmersión"},
    {prodId:"41103713",label:"Baños de viscosidad"},
    {prodId:"41103714",label:"Baños de flotación de tejidos"},
    {prodId:"41103715",label:"Accesorios o suministros para baños de laboratorio"},
    {prodId:"41103716",label:"Baños para descongelar plasma de bancos de sangre"},
    {prodId:"41103717",label:"Baños para cultivos de tejidos"},
    {prodId:"41103800",label:"Equipo y suministros para la mezcla y la agitación por sacudida o varillas en laboratorio"},
    {prodId:"41103801",label:"Mezcladores de laboratorio"},
    {prodId:"41103802",label:"Mezcladores de rodillo"},
    {prodId:"41103803",label:"Mesas para revolver"},
    {prodId:"41103804",label:"Equipo multi banco o de floculación"},
    {prodId:"41103805",label:"Vibradores para laboratorio"},
    {prodId:"41103806",label:"Agitador magnético"},
    {prodId:"41103807",label:"Mezcladores de toque para laboratorio"},
    {prodId:"41103808",label:"Mezcladores de plaqueta"},
    {prodId:"41103809",label:"Mezcladores químicos o de hematología"},
    {prodId:"41103810",label:"Agitadores de techo"},
    {prodId:"41103811",label:"Sacudidores orbitales"},
    {prodId:"41103812",label:"Sacudidores recíprocos"},
    {prodId:"41103813",label:"Sacudidores de rotación"},
    {prodId:"41103814",label:"Mezcladores de vértice"},
    {prodId:"41103815",label:"Rotadores de tubos"},
    {prodId:"41103816",label:"Accesorios o aditamentos para mezcladores o sacudidores"},
    {prodId:"41103817",label:"Reactor de laboratorio"},
    {prodId:"41103900",label:"Centrifugadoras de laboratorio y accesorios"},
    {prodId:"41103901",label:"Micro centrífugas"},
    {prodId:"41103902",label:"Micro centrífugas refrigeradas"},
    {prodId:"41103903",label:"Centrífugas de mesa"},
    {prodId:"41103904",label:"Centrífugas de mesa refrigeradas"},
    {prodId:"41103905",label:"Centrífugas de piso"},
    {prodId:"41103906",label:"Centrífugas de piso refrigeradas"},
    {prodId:"41103907",label:"Ultra centrífugas"},
    {prodId:"41103908",label:"Centrífugas de vacío"},
    {prodId:"41103909",label:"Rotores de centrífugas"},
    {prodId:"41103910",label:"Baldes centrífugos"},
    {prodId:"41103911",label:"Adaptadores para centrífugas"},
    {prodId:"41103912",label:"Cepillos para centrífugas"},
    {prodId:"41103913",label:"Accesorios para centrífugas de laboratorio"},
    {prodId:"41103914",label:"Citocentrífuga"},
    {prodId:"41104000",label:"Equipo de toma de muestras"},
    {prodId:"41104001",label:"Cambiadores de muestras"},
    {prodId:"41104002",label:"Oxidante de muestras"},
    {prodId:"41104003",label:"Línea de preparación de muestras"},
    {prodId:"41104004",label:"Bombas de preparación de muestras"},
    {prodId:"41104005",label:"Achicadores para laboratorio"},
    {prodId:"41104006",label:"Coliwasas (muestreadores de desechos líquidos de compostaje)"},
    {prodId:"41104007",label:"Muestreadores de agua"},
    {prodId:"41104008",label:"Muestreadores o colectores de aire"},
    {prodId:"41104009",label:"Bombas muestreadoras de aire"},
    {prodId:"41104010",label:"Kits de reactivos para usar con muestreadores de aire"},
    {prodId:"41104011",label:"Filtros u otras partes de repuesto para muestreadores"},
    {prodId:"41104012",label:"Recogedores o frascos para polvo"},
    {prodId:"41104013",label:"Muestreadores de dióxido de sulfuro o de humo"},
    {prodId:"41104014",label:"Aplicadores de muestras"},
    {prodId:"41104015",label:"Equipo de análisis de muestras de plantas"},
    {prodId:"41104016",label:"Muestreadores de polución de aire"},
    {prodId:"41104017",label:"Contenedores para muestras"},
    {prodId:"41104018",label:"Preparaciones para extracción de fase sólida"},
    {prodId:"41104019",label:"Colectores de muestras"},
    {prodId:"41104020",label:"Bandeja de flujo de elemento calcino"},
    {prodId:"41104021",label:"Colector de fracciones"},
    {prodId:"41104022",label:"Formador de muestras"},
    {prodId:"41104100",label:"Contenedores de recogida y transporte de especímenes, y suministros"},
    {prodId:"41104101",label:"Sobres o empaques para especímenes o láminas de muestras"},
    {prodId:"41104102",label:"Lancetas"},
    {prodId:"41104103",label:"Calentadores de pivote"},
    {prodId:"41104104",label:"Torniquetes"},
    {prodId:"41104105",label:"Bolsas para recolectar o transportar especímenes"},
    {prodId:"41104106",label:"Bandejas o accesorios de flebotomía"},
    {prodId:"41104107",label:"Tubos de recolección o contenedores de sangre al vacío"},
    {prodId:"41104108",label:"Tubos de recolección o contenedores de sangre no al vacío"},
    {prodId:"41104109",label:"Bolsas de recolección de unidades de sangre"},
    {prodId:"41104110",label:"Botellas de cultivo de sangre"},
    {prodId:"41104111",label:"Kits o contenedores de recolección de citologías"},
    {prodId:"41104112",label:"Contenedores de recolección de orina"},
    {prodId:"41104114",label:"Contenedores de recolección frepp sepp"},
    {prodId:"41104115",label:"Contenedores de recolección de filtro de suero"},
    {prodId:"41104116",label:"Contenedores de recolección o transporte de frotis"},
    {prodId:"41104117",label:"Porta especímenes"},
    {prodId:"41104118",label:"Contenedor de recolección de especímenes"},
    {prodId:"41104119",label:"Contenedores de recolección de tejido óseo"},
    {prodId:"41104120",label:"Tubos de tasa de sedimentación"},
    {prodId:"41104121",label:"Contenedores con medio químico para recolección de heces"},
    {prodId:"41104122",label:"Contenedores sin medio químico para recolección de heces"},
    {prodId:"41104123",label:"Aparatos o contenedores para recolección de esputos"},
    {prodId:"41104124",label:"Bandejas de biopsia de médula de hueso para laboratorio"},
    {prodId:"41104125",label:"Contenedor de preservación de especímenes para histología o patología"},
    {prodId:"41104126",label:"Contenedor de especímenes de histología o patología"},
    {prodId:"41104127",label:"Alimentador para laboratorio"},
    {prodId:"41104128",label:"Recolector de especímenes de cultivo sin frotis"},
    {prodId:"41104129",label:"Tarjeta de recolección de monitoreo de desórdenes metabólicos neonatales"},
    {prodId:"41104200",label:"Equipo y suministros de purificación de agua para laboratorio"},
    {prodId:"41104201",label:"Reactivos de purificación del agua"},
    {prodId:"41104202",label:"Equipos de deionización o desmineralización"},
    {prodId:"41104203",label:"Equipos de intercambio de base"},
    {prodId:"41104204",label:"Equipos de ósmosis inversa"},
    {prodId:"41104205",label:"Unidades ultra violeta de purificación de agua"},
    {prodId:"41104206",label:"Sistemas de agua ultra pura"},
    {prodId:"41104207",label:"Sistemas de análisis de agua"},
    {prodId:"41104208",label:"Deshidratadores"},
    {prodId:"41104209",label:"Desoxidantes"},
    {prodId:"41104210",label:"Disolventes"},
    {prodId:"41104211",label:"Suavizantes"},
    {prodId:"41104212",label:"Cartuchos de filtración de agua"},
    {prodId:"41104213",label:"Agua destilada o deionizada"},
    {prodId:"41104300",label:"Equipo de fermentación"},
    {prodId:"41104301",label:"Unidades de fermentación estándar"},
    {prodId:"41104302",label:"Aparatos de cultivo continuo"},
    {prodId:"41104303",label:"Jarros o accesorios anaeróbicos"},
    {prodId:"41104304",label:"Sistemas digestivos"},
    {prodId:"41104305",label:"Condensadores (espesantes)"},
    {prodId:"41104306",label:"Equipos para cultivos in vitro"},
    {prodId:"41104307",label:"Equipos para fermentación microbiológica"},
    {prodId:"41104308",label:"Sistemas o suministros para cultivos ambientales anaeróbicos"},
    {prodId:"41104400",label:"Equipo de incubación de laboratorio"},
    {prodId:"41104401",label:"Incubadoras para uso general de convección de gravedad"},
    {prodId:"41104402",label:"Incubadoras de uso general de aire forzado o convección mecánica"},
    {prodId:"41104403",label:"Incubadoras para cultivo de tejidos"},
    {prodId:"41104404",label:"Incubadoras de demanda de oxígeno biológico bod enfriadas"},
    {prodId:"41104405",label:"Incubadoras de agitación"},
    {prodId:"41104406",label:"Incubadoras planas"},
    {prodId:"41104407",label:"Incubadoras de cámara única de dióxido de carbono recubierta de agua"},
    {prodId:"41104408",label:"Incubadoras de cámara dual de dióxido de carbono recubierta de agua"},
    {prodId:"41104409",label:"Incubadoras de cámara única de dióxido de carbono recubierta de agua con control de humedad"},
    {prodId:"41104410",label:"Incubadoras de cámara dual de dióxido de carbono recubierta de agua con control de humedad"},
    {prodId:"41104411",label:"Incubadoras de cámara única de dióxido de carbono de pared seca"},
    {prodId:"41104412",label:"Incubadoras de cámara dual de dióxido de carbono de pared seca"},
    {prodId:"41104413",label:"Incubadoras de cámara única de dióxido de carbono de pared seca con control de humedad"},
    {prodId:"41104414",label:"Incubadoras de cámara dual de dióxido de carbono de pared seca con control de humedad"},
    {prodId:"41104415",label:"Incubadoras de cámara única de tres gases recubierta de agua"},
    {prodId:"41104416",label:"Incubadoras de cámara dual de tres gases recubierta de agua"},
    {prodId:"41104417",label:"Incubadoras de cámara única de tres gases recubierta de agua con control de humedad"},
    {prodId:"41104418",label:"Incubadoras de cámara dual de tres gases recubierta de agua con control de humedad"},
    {prodId:"41104419",label:"Incubadoras de cámara única de tres gases de pared seca"},
    {prodId:"41104420",label:"Incubadoras de cámara dual de tres gases de pared seca"},
    {prodId:"41104421",label:"Incubadoras de cámara única de tres gases de pared seca con control de humedad"},
    {prodId:"41104422",label:"Incubadoras de cámara dual de tres gases de pared seca con control de humedad"},
    {prodId:"41104423",label:"Incubadoras refrigeradas"},
    {prodId:"41104424",label:"Accesorios para incubadoras"},
    {prodId:"41104425",label:"Incubadora de dióxido de carbono"},
    {prodId:"41104426",label:"Aparatos para preparación de medios"},
    {prodId:"41104427",label:"Incubadora de plaqueta"},
    {prodId:"41104500",label:"Estufas de laboratorio y accesorios"},
    {prodId:"41104501",label:"Hornos de convección mecánica para laboratorio"},
    {prodId:"41104502",label:"Hornos de convección de gravedad"},
    {prodId:"41104503",label:"Hornos de envejecimiento"},
    {prodId:"41104504",label:"Hornos de limpieza de espacio"},
    {prodId:"41104505",label:"Recipientes de cuarzo para horno para laboratorio"},
    {prodId:"41104506",label:"Hornos de seguridad para laboratorios"},
    {prodId:"41104507",label:"Hornos microondas para laboratorio"},
    {prodId:"41104508",label:"Secadoras de inducción"},
    {prodId:"41104509",label:"Hornos al vacío"},
    {prodId:"41104510",label:"Hornos o gabinetes secadores"},
    {prodId:"41104511",label:"Hornos o incubadoras de hibridación"},
    {prodId:"41104512",label:"Accesorios para hornos de laboratorio"},
    {prodId:"41104513",label:"Horno para derretir magnesio"},
    {prodId:"41104600",label:"Hornos de laboratorio y accesorios"},
    {prodId:"41104601",label:"Hornos de caja para laboratorio"},
    {prodId:"41104602",label:"Hornos de caja programables"},
    {prodId:"41104603",label:"Hornos de tubo"},
    {prodId:"41104604",label:"Hornos de tubo programables"},
    {prodId:"41104605",label:"Hornos de crisol"},
    {prodId:"41104606",label:"Hornos de crisol programables"},
    {prodId:"41104607",label:"Consola de control de calderas"},
    {prodId:"41104608",label:"Consola de control de calderas programables"},
    {prodId:"41104609",label:"Hornos de seguridad para laboratorio"},
    {prodId:"41104610",label:"Crisoles para hornos de laboratorio"},
    {prodId:"41104611",label:"Aislamiento de repuesto para calderas de laboratorio"},
    {prodId:"41104612",label:"Accesorios para calderas de laboratorio"},
    {prodId:"41104613",label:"Prensa caliente para laboratorio"},
    {prodId:"41104800",label:"Equipo y suministros de laboratorio para el vertido, la destilación, la evaporación y la extracción"},
    {prodId:"41104801",label:"Unidades de redomas o retortas"},
    {prodId:"41104802",label:"Unidades de bi destilación"},
    {prodId:"41104803",label:"Evaporadores para laboratorio"},
    {prodId:"41104804",label:"Evaporadores de vacío o rotatorios"},
    {prodId:"41104805",label:"Evaporadores de purga de nitrógeno"},
    {prodId:"41104806",label:"Equipo de extracción para laboratorios"},
    {prodId:"41104807",label:"Extractores de grasa"},
    {prodId:"41104808",label:"Extractores de fibra cruda"},
    {prodId:"41104809",label:"Unidad de análisis de sedimentación"},
    {prodId:"41104810",label:"Aparatos de fraccionamiento"},
    {prodId:"41104811",label:"Fraccionadores de densidad gradiente"},
    {prodId:"41104812",label:"Pipetas o columnas o accesorios de destilación"},
    {prodId:"41104813",label:"Componentes de reflujo"},
    {prodId:"41104814",label:"Condensadores intercambiadores de calor para laboratorio"},
    {prodId:"41104815",label:"Aparatos “kjeldahl” para la determinación de nitrógeno"},
    {prodId:"41104816",label:"Concentradores centrífugos o de vacío"},
    {prodId:"41104817",label:"Casquillos de extracción"},
    {prodId:"41104818",label:"Empaques estructurados (para uso en columnas de destilación y reactivos químicos)"},
    {prodId:"41104819",label:"Cosechadora de células"},
    {prodId:"41104820",label:"Aparatos de destilación fraccional para laboratorios"},
    {prodId:"41104821",label:"Concentrador de muestras estáticas"},
    {prodId:"41104900",label:"Equipo y suministros de filtrado para laboratorio"},
    {prodId:"41104901",label:"Filtros de línea para laboratorio"},
    {prodId:"41104902",label:"Equipos de filtración de gel"},
    {prodId:"41104903",label:"Equipos de ultra filtración"},
    {prodId:"41104904",label:"Filtros de células aglomeradas"},
    {prodId:"41104905",label:"Equipos de filtración de canal angosto"},
    {prodId:"41104906",label:"Equipos de filtración de ósmosis inversa"},
    {prodId:"41104907",label:"Equipos de filtración molecular"},
    {prodId:"41104908",label:"Elementos de filtración de cartucho para laboratorios"},
    {prodId:"41104909",label:"Sujeta filtros o ciclones para laboratorios"},
    {prodId:"41104910",label:"Filtros multi hoja o de prensa para laboratorios"},
    {prodId:"41104911",label:"Sistemas de filtración de aire para laboratorios"},
    {prodId:"41104912",label:"Filtro de prensas de fluido"},
    {prodId:"41104913",label:"Filtros de bio separación"},
    {prodId:"41104914",label:"Tazas o botellas de filtración"},
    {prodId:"41104915",label:"Filtros de cápsulas"},
    {prodId:"41104916",label:"Filtros centrífugos"},
    {prodId:"41104917",label:"Filtros ambientales para laboratorio"},
    {prodId:"41104918",label:"Filtros de vidrio para laboratorio"},
    {prodId:"41104919",label:"Filtros hepa para laboratorio"},
    {prodId:"41104920",label:"Filtros de hibridación"},
    {prodId:"41104921",label:"Filtros de membrana para laboratorio"},
    {prodId:"41104922",label:"Filtros de jeringa"},
    {prodId:"41104923",label:"Filtros de plato multipocillo"},
    {prodId:"41104924",label:"Filtros de microbiología"},
    {prodId:"41104925",label:"Hardware de filtración o accesorios para laboratorio"},
    {prodId:"41104926",label:"Filtro de cama de sílice"},
    {prodId:"41104927",label:"Pantallas de soporte para filtros"},
    {prodId:"41104928",label:"Receptor de botellas para laboratorio"},
    {prodId:"41104929",label:"Papeles filtrantes para laboratorio"},
    {prodId:"41104930",label:"Cernidor molecular"},
    {prodId:"41105000",label:"Equipo y suministros de tamizado y cribado para laboratorio"},
    {prodId:"41105001",label:"Separadores para laboratorio"},
    {prodId:"41105002",label:"Equipo de tamizaje para laboratorio"},
    {prodId:"41105003",label:"Cernidores de prueba"},
    {prodId:"41105100",label:"Bombas y conductos de laboratorio"},
    {prodId:"41105101",label:"Bombas de vacío para laboratorio"},
    {prodId:"41105102",label:"Bombas peristálticas"},
    {prodId:"41105103",label:"Bombas centrífugas para laboratorio"},
    {prodId:"41105104",label:"Bombas de jeringa"},
    {prodId:"41105105",label:"Bombas de medición"},
    {prodId:"41105106",label:"Bombas de cromatografía"},
    {prodId:"41105107",label:"Bombas de tambor para laboratorio"},
    {prodId:"41105108",label:"Tubos de uso general para laboratorio"},
    {prodId:"41105109",label:"Bombas de aleta rotativa"},
    {prodId:"41105200",label:"Equipo de teñido de portaobjetos de laboratorio y accesorios"},
    {prodId:"41105201",label:"Equipo para teñir muestras de histología o citología"},
    {prodId:"41105202",label:"Equipo para teñir muestras de hematología"},
    {prodId:"41105203",label:"Equipo para teñir muestras de microbiología"},
    {prodId:"41105204",label:"Accesorios para equipos para teñir muestras de laboratorio"},
    {prodId:"41105205",label:"Equipo para preparar micro muestras"},
    {prodId:"41105206",label:"Equipo inmuno-histo-químico automático para teñir"},
    {prodId:"41105207",label:"Accesorios para equipo inmuno-histo-químico automático para teñir"},
    {prodId:"41105208",label:"Estante y bandeja para teñir muestras para laboratorio"},
    {prodId:"41105209",label:"Set para teñir muestras manualmente multi departamental"},
    {prodId:"41105300",label:"Sistemas de electroforesis y transferencia para laboratorio y suministros"},
    {prodId:"41105301",label:"Cajas de gel"},
    {prodId:"41105302",label:"Secadores de gel"},
    {prodId:"41105303",label:"Suministros para el sistema de energía de electroforesis"},
    {prodId:"41105304",label:"Transiluminadores"},
    {prodId:"41105305",label:"Accesorios para el sistema de electroforesis"},
    {prodId:"41105307",label:"Instrumental para electroforesis capilar"},
    {prodId:"41105308",label:"Capilares o cartuchos"},
    {prodId:"41105309",label:"Kits o reactivos para electroforesis capilar"},
    {prodId:"41105310",label:"Accesorios para bloquear o transferir"},
    {prodId:"41105311",label:"Aparatos para bloquear o transferir"},
    {prodId:"41105312",label:"Peinillas o platos o espaciadores o bandejas"},
    {prodId:"41105313",label:"Casetes de detección o accesorios relacionados"},
    {prodId:"41105314",label:"Sistemas de documentación de gel"},
    {prodId:"41105315",label:"Accesorios de documentación de gel"},
    {prodId:"41105316",label:"Entrecruzadores ultravioleta"},
    {prodId:"41105317",label:"Reactivos para preparar geles de agarosa"},
    {prodId:"41105318",label:"Geles de agarosa prefabricados"},
    {prodId:"41105319",label:"Reactivos para preparar gel poliacrilamida"},
    {prodId:"41105320",label:"Geles de poliacrilamida pre fabricados"},
    {prodId:"41105321",label:"Tintura para geles ácido nucleicos"},
    {prodId:"41105322",label:"Tintura para geles poliacrilamidas"},
    {prodId:"41105323",label:"Zonas o soluciones prefabricadas para electroforesis"},
    {prodId:"41105324",label:"Sondas para ácido desoxirribonucleico dna o ácido ribonucleico rna"},
    {prodId:"41105325",label:"Placas micropocillo para hibridación de ácido desoxirribonucleico dna o ácido ribonucleico rna"},
    {prodId:"41105326",label:"Agentes reactivos o zonas para hibridación"},
    {prodId:"41105327",label:"Nucleótidos u oligómeros conjugados"},
    {prodId:"41105328",label:"Northern blot o southern blot o western blot prefabricadas"},
    {prodId:"41105329",label:"Agentes bloqueadores"},
    {prodId:"41105330",label:"Proteínas de control o lisados celulares o lisados de tejidos"},
    {prodId:"41105331",label:"Reactivos o kits o sustratos de detección quimio fluorescente de proteínas"},
    {prodId:"41105332",label:"Reactivos o kits o sustratos de detección quimio luminiscente de proteínas"},
    {prodId:"41105333",label:"Reactivos o kits o sustratos de detección cromogénica de proteínas"},
    {prodId:"41105334",label:"Marcadores de cuantificación de ácido desoxirribonucleico dna"},
    {prodId:"41105335",label:"Marcadores de tamaño o estándares de ácido desoxirribonucleico dna"},
    {prodId:"41105336",label:"Marcadores de foco isoeléctrico ief"},
    {prodId:"41105337",label:"Marcadores de electroforesis de proteína"},
    {prodId:"41105338",label:"Marcadores o estándares de ácido ribonucleico rna"},
    {prodId:"41105339",label:"Membranas de transferencia por adsorción"},
    {prodId:"41105340",label:"Sistema de electroforesis"},
    {prodId:"41105341",label:"Película de auto radiografía"},
    {prodId:"41105500",label:"Kits y componentes de extracción, purificación y cuantificación de ácidos nucleicos"},
    {prodId:"41105501",label:"Kits de extracción de gel o limpiadores de ácido desoxirribonucleico dna"},
    {prodId:"41105502",label:"Kits para extrae ácido desoxirribonucleico dna de alimentos"},
    {prodId:"41105503",label:"Sistemas de electro elución"},
    {prodId:"41105504",label:"Kits de purificación de ácido desoxirribonucleico dna genómico"},
    {prodId:"41105505",label:"Sistemas de selección de alto rendimiento hts en purificación de ácido nucleico"},
    {prodId:"41105506",label:"Kits para purificación de ácido ribonucleico mensajero mrna"},
    {prodId:"41105507",label:"Glóbulos magnéticos para aislar ácido nucleico"},
    {prodId:"41105508",label:"Co-precipitantes de ácidos nucleicos"},
    {prodId:"41105509",label:"Kits de cuantificación de ácidos nucleicos"},
    {prodId:"41105510",label:"Kits de purificación de fagos de ácido desoxirribonucleico dna"},
    {prodId:"41105511",label:"Kits para extracción de plásmidos de ácido desoxirribonucleico dna de la levadura"},
    {prodId:"41105512",label:"Kit de purificación de plásmidos o cósmidos o cromosomas bacterianos artificiales bac"},
    {prodId:"41105513",label:"Kits de purificación de ácido nucleico etiquetado"},
    {prodId:"41105514",label:"Reactivos para extracción o precipitación o re suspensión de ácido nucleico"},
    {prodId:"41105515",label:"Materiales de estabilización o limpieza de ácido ribonucleico"},
    {prodId:"41105516",label:"Kits de extracción de gel de ácido ribonucleico rna"},
    {prodId:"41105517",label:"Kits para extracción de ácido nucleico de células o tejidos de plantas"},
    {prodId:"41105518",label:"Kits de purificación de ácido ribonucleico rna total"},
    {prodId:"41105519",label:"Kits de purificación de ácido desoxirribonucleico dna viral"},
    {prodId:"41105520",label:"Kits de purificación de ácido ribonucleico viral"},
    {prodId:"41105521",label:"Sistema de detección de ácido desoxirribonucleico dna"},
    {prodId:"41105900",label:"Bibliotecas y material relacionado"},
    {prodId:"41105901",label:"Tejidos animales o fluidos corporales"},
    {prodId:"41105902",label:"Muestrarios de ácido desoxirribonucleico dna complementario"},
    {prodId:"41105903",label:"Kits de síntesis de ácido desoxirribonucleico dna complementario"},
    {prodId:"41105904",label:"Muestrarios genómicos"},
    {prodId:"41105905",label:"Kits de construcción de muestrarios"},
    {prodId:"41105906",label:"Muestrarios de despliegue de proteínas o péptidos"},
    {prodId:"41105907",label:"Dos muestrarios o sistemas de dos híbridos"},
    {prodId:"41105908",label:"Kits de empaques virales"},
    {prodId:"41105909",label:"Sintetizador de péptidos"},
    {prodId:"41106100",label:"Kits de análisis de ácido desoxirribonucleico (ADN)"},
    {prodId:"41106101",label:"Kits citogenéticos"},
    {prodId:"41106102",label:"Kits de kits de exhibición o sustracción diferenciales"},
    {prodId:"41106103",label:"Kits de tipificación de ácido desoxirribonucleico dna"},
    {prodId:"41106104",label:"Pruebas de protección nucleasa"},
    {prodId:"41106200",label:"Medios, kits y equipo de propagación y transformación de microorganismos"},
    {prodId:"41106201",label:"Antimicóticos"},
    {prodId:"41106202",label:"Celdas competentes de bacterias"},
    {prodId:"41106203",label:"Kits de transformación de bacterias"},
    {prodId:"41106204",label:"Medio agar embotellado o en bandas para bacterias"},
    {prodId:"41106205",label:"Mezclas de suplemento brent para levadura"},
    {prodId:"41106206",label:"Mezclas de suplemento completo para levadura"},
    {prodId:"41106207",label:"Medio discoide dictiostelium"},
    {prodId:"41106208",label:"Cubetas de electroporación"},
    {prodId:"41106209",label:"Mezclas de suplemento hollenberg para levaduras"},
    {prodId:"41106210",label:"Medios o suplementos para schizosaccharomyces pombe (levadura de fisión)"},
    {prodId:"41106211",label:"Ingredientes o aditivos de medio para schizosaccharomyces pombe"},
    {prodId:"41106212",label:"Ingredientes o aditivos de medio para bacterias"},
    {prodId:"41106213",label:"Medio seco premezclado"},
    {prodId:"41106214",label:"Reactivos para preparar bacterias competentes"},
    {prodId:"41106215",label:"Reactivos para preparar levadura competente"},
    {prodId:"41106216",label:"Medios ricos para levadura"},
    {prodId:"41106217",label:"Platos especiales para bacterias"},
    {prodId:"41106218",label:"Medio seco premezclado especializado"},
    {prodId:"41106219",label:"Mezclas suplementarias completas sintéticas para levadura"},
    {prodId:"41106220",label:"Medio sintético para levadura"},
    {prodId:"41106221",label:"Células competentes para levadura"},
    {prodId:"41106222",label:"Kits de transformación de levadura"},
    {prodId:"41106223",label:"Bases de nitrógeno para levadura ynb o variantes de bases de nitrógeno para levaduras ynb"},
    {prodId:"41106300",label:"Productos de reacción en cadena de la polimerasa (PCR) y reacción en cadena de transcriptasa inversa de la polimerasa (RT PCR)"},
    {prodId:"41106301",label:"Trifosfatos deoxinucleotidos dntps"},
    {prodId:"41106302",label:"Kits de reacción en cadena polimerasa específica para genes"},
    {prodId:"41106303",label:"Kits de purificación de reacción en cadena de polimerasa"},
    {prodId:"41106304",label:"Kits para cuantificación de ácido ribonucleico mensajero mrna mediante reacción en cadena de polimerasa pcr"},
    {prodId:"41106305",label:"Nucleótidos"},
    {prodId:"41106306",label:"Tapones para reacción en cadena de polimerasa pcr"},
    {prodId:"41106307",label:"Productos para optimizar la reacción en cadena de polimerasa pcr"},
    {prodId:"41106308",label:"Base para reacción en cadena de polimerasa pcr o reacción en cadena de polimerasa transcripta inversa rt pcr"},
    {prodId:"41106309",label:"Ácido desoxirribonucleico complementario cdna pre-preparado"},
    {prodId:"41106310",label:"Ácido desoxirribonucleico adn genómico purificado"},
    {prodId:"41106311",label:"Ácidos ribonucleicos rna purificados"},
    {prodId:"41106312",label:"Productos de tecnología de rápida amplificación o terminaciones de ácido desoxirribonucleico complementario race"},
    {prodId:"41106313",label:"Kits de reacción en cadena de polimerasa transcriptasa inversa rt pcr"},
    {prodId:"41106314",label:"Kits o polimerasas de ácido desoxirribonucleico dna termoestable"},
    {prodId:"41106400",label:"Iniciadores, enlaces y adaptadores"},
    {prodId:"41106401",label:"Adaptadores o enlazadores"},
    {prodId:"41106402",label:"Bases misceláneas"},
    {prodId:"41106403",label:"Bases de secuenciación"},
    {prodId:"41106500",label:"Productos de expresión proteínica"},
    {prodId:"41106501",label:"Kits de expresión bacteriana"},
    {prodId:"41106502",label:"Reactivos de transfección eucariótica"},
    {prodId:"41106503",label:"Inductores o reguladores"},
    {prodId:"41106504",label:"Células de insectos"},
    {prodId:"41106505",label:"Kits de expresión de insectos"},
    {prodId:"41106506",label:"Medio para insectos"},
    {prodId:"41106507",label:"Reactivos o suplementos para medio para insectos"},
    {prodId:"41106508",label:"Kits de expresión celular mamífera"},
    {prodId:"41106509",label:"Células mamíferas"},
    {prodId:"41106510",label:"Kits para extracción de proteína de células o tejidos de mamíferos"},
    {prodId:"41106511",label:"Kits para extracción de proteína de bacterias"},
    {prodId:"41106512",label:"Kits para extracción de proteína de levadura"},
    {prodId:"41106513",label:"Reportero de ensayo genético"},
    {prodId:"41106514",label:"Líneas celulares mamíferas estables"},
    {prodId:"41106515",label:"Kits de expresión de levadura"},
    {prodId:"41106516",label:"Consumibles de expresión de enzimas"},
    {prodId:"41106700",label:"Equipos y accesorios de laboratorio botánico"},
    {prodId:"41106701",label:"Medidor de área de hojas"},
    {prodId:"41106702",label:"Aparato de medición de fotosíntesis"},
    {prodId:"41106703",label:"Instrumento de medición de crecimiento de las plantas o auxanómetro"},
    {prodId:"41106704",label:"Instrumento de medición de la clorofila"},
    {prodId:"41106705",label:"Fitotrón"},
    {prodId:"41106706",label:"Minirizotrón"},
    {prodId:"41106707",label:"Dispositivo de inspección de la nutrición de la vegetación"},
    {prodId:"41106708",label:"Set de dispositivos para recolección de plantas"},
    {prodId:"41111500",label:"Instrumentos de medición del peso"},
    {prodId:"41111501",label:"Balanzas de carga superior electrónicos"},
    {prodId:"41111502",label:"Balanzas de laboratorio"},
    {prodId:"41111503",label:"Balanzas mecánicas"},
    {prodId:"41111504",label:"Balanzas de resorte tensor"},
    {prodId:"41111505",label:"Pesas de calibración o sets de pesas"},
    {prodId:"41111506",label:"Básculas para pesar animales"},
    {prodId:"41111507",label:"Básculas de mesa"},
    {prodId:"41111508",label:"Básculas para medir el peso corporal"},
    {prodId:"41111509",label:"Básculas de piso o de plataforma"},
    {prodId:"41111510",label:"Básculas postales"},
    {prodId:"41111511",label:"Básculas de camión o riel"},
    {prodId:"41111512",label:"Balanzas de triple haz"},
    {prodId:"41111513",label:"Balanzas de humedad"},
    {prodId:"41111515",label:"Contenedores o tazones o barcos o papeles para pesar"},
    {prodId:"41111516",label:"Accesorios para instrumentos de medición de peso"},
    {prodId:"41111517",label:"Balanzas analíticas"},
    {prodId:"41111518",label:"Básculas de carga axial"},
    {prodId:"41111519",label:"Básculas de grúa"},
    {prodId:"41111520",label:"Báscula de peso de correa transportadora"},
    {prodId:"41111521",label:"Termogravímeto"},
    {prodId:"41111522",label:"Básculas de tolva"},
    {prodId:"41111523",label:"Prototipo de peso"},
    {prodId:"41111524",label:"Báscula indicadora de precio"},
    {prodId:"41111525",label:"Báscula empacadora automática"},
    {prodId:"41111526",label:"Báscula de selección automática"},
    {prodId:"41111600",label:"Instrumentos de medida de longitud, espesor o distancia"},
    {prodId:"41111601",label:"Micrómetros"},
    {prodId:"41111602",label:"Podómetros"},
    {prodId:"41111603",label:"Telémetros o buscadores de rango"},
    {prodId:"41111604",label:"Reglas"},
    {prodId:"41111605",label:"Medidoras de tensión"},
    {prodId:"41111606",label:"Telurómetros"},
    {prodId:"41111607",label:"Medidores o contadores de roscas"},
    {prodId:"41111613",label:"Metros de distancia"},
    {prodId:"41111614",label:"Medidores de altura"},
    {prodId:"41111615",label:"Sistemas láser de medición"},
    {prodId:"41111616",label:"Ruedas medidoras para distancias"},
    {prodId:"41111617",label:"Medidor de espesores"},
    {prodId:"41111618",label:"Set de bloques de patrón longitudinal"},
    {prodId:"41111619",label:"Calibrador go nogo"},
    {prodId:"41111620",label:"Cuña de etalón"},
    {prodId:"41111621",label:"Calibradores"},
    {prodId:"41111622",label:"Calibradores micrómetros"},
    {prodId:"41111623",label:"Dispositivos de medición de grosor"},
    {prodId:"41111624",label:"Medidor de orificios o alexómetro"},
    {prodId:"41111625",label:"Curvímetro"},
    {prodId:"41111626",label:"Bloque de medición"},
    {prodId:"41111627",label:"Bloque en v"},
    {prodId:"41111628",label:"Medidor de alambre"},
    {prodId:"41111629",label:"Sensor de posición de cable o alambre de extensión linear"},
    {prodId:"41111630",label:"Indicador de dial o medidor de dial"},
    {prodId:"41111631",label:"Medidor de radio"},
    {prodId:"41111632",label:"Medidor de cilindros"},
    {prodId:"41111633",label:"Micrómetro de aire"},
    {prodId:"41111634",label:"Micrómetro eléctrico"},
    {prodId:"41111635",label:"Comparador de bloques de patrón longitudinal"},
    {prodId:"41111636",label:"Equipo de medición de diámetro de cilindro"},
    {prodId:"41111637",label:"Taza probadora cónica"},
    {prodId:"41111638",label:"Probador de circunferencias de cigarrillos"},
    {prodId:"41111639",label:"Medidor de formas y profundidad de la cabeza de los tornillos"},
    {prodId:"41111640",label:"Medidor de soldadura"},
    {prodId:"41111641",label:"Medidor de paso de rosca"},
    {prodId:"41111642",label:"Medidor de espacio entre uniones de rieles"},
    {prodId:"41111643",label:"Calibrador de alambre"},
    {prodId:"41111644",label:"Medidor de pernos"},
    {prodId:"41111645",label:"Barra sinusoidal"},
    {prodId:"41111646",label:"Medidor telescópico"},
    {prodId:"41111647",label:"Medidor de orificios"},
    {prodId:"41111648",label:"Medidor cónico"},
    {prodId:"41111649",label:"Medidor de profundidad de llantas"},
    {prodId:"41111650",label:"Medidor de perforación"},
    {prodId:"41111651",label:"Catetómetro"},
    {prodId:"41111700",label:"Instrumentos y accesorios de visión y observación"},
    {prodId:"41111701",label:"Microscopios iónicos"},
    {prodId:"41111702",label:"Microscopios monoculares"},
    {prodId:"41111703",label:"Microscopios de disección de luz o de estéreo"},
    {prodId:"41111704",label:"Iluminadores para microscopios"},
    {prodId:"41111705",label:"Objetivos para microscopios"},
    {prodId:"41111706",label:"Elementos de sujeción de fotos para microscopios"},
    {prodId:"41111707",label:"Proyectores de perfil"},
    {prodId:"41111708",label:"Elementos de sujeción de video para microscopios"},
    {prodId:"41111709",label:"Microscopios compuestos de luz binocular"},
    {prodId:"41111710",label:"Combinación de microscopios de luz y electrones"},
    {prodId:"41111711",label:"Microscopios de electrones"},
    {prodId:"41111712",label:"Microscopios invertidos"},
    {prodId:"41111713",label:"Magnificadores"},
    {prodId:"41111714",label:"Lupas"},
    {prodId:"41111715",label:"Telescopios"},
    {prodId:"41111716",label:"Equipos de inspección boroscópica"},
    {prodId:"41111717",label:"Binoculares"},
    {prodId:"41111718",label:"Microscopios metalúrgicos"},
    {prodId:"41111719",label:"Microscopios de campo oscuro"},
    {prodId:"41111720",label:"Microscopios de escáner de electrones"},
    {prodId:"41111721",label:"Microscopios de transmisión de electrones"},
    {prodId:"41111722",label:"Microscopios fluorescentes"},
    {prodId:"41111723",label:"Microscopios de escáner de luz, disco giratorio o escáner de láser"},
    {prodId:"41111724",label:"Microscopios de escáner de sonda"},
    {prodId:"41111725",label:"Microscopios de polarización"},
    {prodId:"41111726",label:"Microscopios acústicos"},
    {prodId:"41111727",label:"Microscopios de proyección"},
    {prodId:"41111728",label:"Microscopios de campo ancho"},
    {prodId:"41111729",label:"Microscopios oculares"},
    {prodId:"41111730",label:"Condensadores de microscopios"},
    {prodId:"41111731",label:"Colectores de microscopios"},
    {prodId:"41111733",label:"Tubos de microscopios"},
    {prodId:"41111734",label:"Microscopios de fases"},
    {prodId:"41111735",label:"Microscopios de fases automáticos"},
    {prodId:"41111736",label:"Cubiertas para microscopios"},
    {prodId:"41111737",label:"Videoscopios"},
    {prodId:"41111738",label:"Fibroscopios"},
    {prodId:"41111739",label:"Bombillos de repuesto para microscopios de laboratorio"},
    {prodId:"41111740",label:"Sistema de inspección óptica automática"},
    {prodId:"41111741",label:"Equipo de microscopio de contraste de interferencia diferencial"},
    {prodId:"41111742",label:"Periscopio"},
    {prodId:"41111743",label:"Autocolimador"},
    {prodId:"41111744",label:"Micrómetro microscópico"},
    {prodId:"41111745",label:"Micromanipulador"},
    {prodId:"41111746",label:"Palanca óptica"},
    {prodId:"41111747",label:"Fibroscopio industrial"},
    {prodId:"41111748",label:"Microscopio de imagen multimedia"},
    {prodId:"41111749",label:"Microscopio de contraste de fase"},
    {prodId:"41111800",label:"Equipo de examen no destructivo"},
    {prodId:"41111801",label:"Equipo de examen de corriente parásita"},
    {prodId:"41111802",label:"Equipo de examen de penetración líquida"},
    {prodId:"41111803",label:"Equipo de examen de partículas magnéticas"},
    {prodId:"41111804",label:"Equipo de examen ultrasónico"},
    {prodId:"41111805",label:"Equipo de examen de radiografía co 60"},
    {prodId:"41111806",label:"Equipo de examen de radiografía cs 137"},
    {prodId:"41111807",label:"Equipo de examen de radiografía ir 192"},
    {prodId:"41111808",label:"Equipo de examen de radiografía de rayos x"},
    {prodId:"41111809",label:"Equipo de prueba de goteo"},
    {prodId:"41111810",label:"Estación de equipos de pruebas de calor"},
    {prodId:"41111811",label:"Estación de equipos de pruebas de frío"},
    {prodId:"41111812",label:"Equipo de examen de radiografía de rayos gamma"},
    {prodId:"41111813",label:"Iluminador para ver radiografías para uso industrial"},
    {prodId:"41111814",label:"Equipo de examen de radiografía de neutrones"},
    {prodId:"41111815",label:"Detector de metales de refuerzo"},
    {prodId:"41111816",label:"Instrumento de medición de desplazamiento"},
    {prodId:"41111817",label:"Sistema de medición del sabor del arroz"},
    {prodId:"41111818",label:"Reactivo de examen no destructivo"},
    {prodId:"41111819",label:"Probador de cable de acero"},
    {prodId:"41111820",label:"Detector de fluorescencia"},
    {prodId:"41111900",label:"Instrumentos indicadores y de registro"},
    {prodId:"41111901",label:"Contadores"},
    {prodId:"41111902",label:"Contadores electrónicos"},
    {prodId:"41111903",label:"Detectores de metales"},
    {prodId:"41111904",label:"Columnas electrónicas"},
    {prodId:"41111905",label:"Sondas de medición electrónicas"},
    {prodId:"41111906",label:"Grabadoras de tablas"},
    {prodId:"41111907",label:"Grabadoras de lectura digital"},
    {prodId:"41111908",label:"Grabadoras gráficas"},
    {prodId:"41111909",label:"Grabadoras de cintas magnéticas"},
    {prodId:"41111910",label:"Grabadoras multifunción"},
    {prodId:"41111911",label:"Grabadoras oscilo gráficas"},
    {prodId:"41111912",label:"Grabadoras sicológicas"},
    {prodId:"41111913",label:"Grabadoras de punta de trazado"},
    {prodId:"41111914",label:"Servo grabadoras"},
    {prodId:"41111915",label:"Sensores bi metálicos"},
    {prodId:"41111916",label:"Sensores de no contacto"},
    {prodId:"41111917",label:"Probadores digitales"},
    {prodId:"41111918",label:"Instruments giroscópicos"},
    {prodId:"41111919",label:"Aparatos de detección para objetos no metálicos"},
    {prodId:"41111920",label:"Máquinas de medición de coordenadas cmm"},
    {prodId:"41111921",label:"Sensores de velocidad"},
    {prodId:"41111922",label:"Sensores de fallas de lámparas"},
    {prodId:"41111923",label:"Sensor de pistones pre-arranque"},
    {prodId:"41111924",label:"Sensores de oxígeno"},
    {prodId:"41111926",label:"Sensores de proximidad"},
    {prodId:"41111927",label:"Sensores de presión"},
    {prodId:"41111928",label:"Sensores de corriente"},
    {prodId:"41111929",label:"Detectores de radiación"},
    {prodId:"41111930",label:"Sensores de corriente eléctrica"},
    {prodId:"41111931",label:"Sensores de flujo"},
    {prodId:"41111932",label:"Detectores de escape de líquidos"},
    {prodId:"41111933",label:"Sensores de carga eléctrica"},
    {prodId:"41111934",label:"Sensores de fuerza o de torsión"},
    {prodId:"41111935",label:"Sensores de inclinación"},
    {prodId:"41111936",label:"Sensores de imagen de semiconductor de óxido de metal complementario cmos"},
    {prodId:"41111937",label:"Sensores giratorios de posición"},
    {prodId:"41111938",label:"Sensores o transmisores de nivel"},
    {prodId:"41111939",label:"Sensores acústicos"},
    {prodId:"41111940",label:"Sensores de color"},
    {prodId:"41111941",label:"Sensores olfativos"},
    {prodId:"41111942",label:"Sensores de opacidad o polvo o visibilidad"},
    {prodId:"41111943",label:"Sensores de resistencia o conductividad eléctrica"},
    {prodId:"41111944",label:"Sensores de admisión eléctricos"},
    {prodId:"41111945",label:"Sensores de posición linear"},
    {prodId:"41111946",label:"Sensores de inductancia eléctrica"},
    {prodId:"41111947",label:"Esferos de registro de tablas"},
    {prodId:"41111948",label:"Contadores de células diferenciales hematológicas manuales o electrónicos"},
    {prodId:"41111949",label:"Cronómetro"},
    {prodId:"41111950",label:"Indicador de nivel"},
    {prodId:"41111951",label:"Indicador de humedad"},
    {prodId:"41111952",label:"Sensor de sulfuro de hidrógeno"},
    {prodId:"41111953",label:"Sensor de armado de inflador de restricción (bolsa de aire) suplementario"},
    {prodId:"41111954",label:"Sensor líquido"},
    {prodId:"41111955",label:"Sensor solar"},
    {prodId:"41111956",label:"Sensor de temperatura infrarrojo"},
    {prodId:"41111957",label:"Sensor de presión de aceite"},
    {prodId:"41111958",label:"Sensor de presión ambiental múltiple"},
    {prodId:"41111959",label:"Sensor de presión de combustible"},
    {prodId:"41111960",label:"Sensor ultrasónico"},
    {prodId:"41111961",label:"Sensor de ocupación"},
    {prodId:"41111962",label:"Sensor de desgaste de frenos"},
    {prodId:"41111963",label:"Sensor de nivel de combustible"},
    {prodId:"41111964",label:"Sensor de tensión del cinturón de seguridad"},
    {prodId:"41111965",label:"Sensor de agua"},
    {prodId:"41111966",label:"Sensor de humedad"},
    {prodId:"41111967",label:"Sensor de cabeceo largo lateral"},
    {prodId:"41111968",label:"Sensor de tasa angular"},
    {prodId:"41111969",label:"Sensor de control de voltaje"},
    {prodId:"41111970",label:"Sensor de temperatura"},
    {prodId:"41111971",label:"Contador binario"},
    {prodId:"41111972",label:"Sensor de inflador de restricción (bolsa de aire) suplementario sir o de ensamblaje de bobina de la bolsa de aire"},
    {prodId:"41111973",label:"Contador de partículas"},
    {prodId:"41111974",label:"Probador sensor de calor"},
    {prodId:"41111975",label:"Monitor de campo magnético"},
    {prodId:"41111976",label:"Sistema de chequeo métrico de agua"},
    {prodId:"41111977",label:"Contador de iones"},
    {prodId:"41111978",label:"Lector de tubo microhematocrito manual"},
    {prodId:"41112100",label:"Transductores"},
    {prodId:"41112101",label:"Cristales piezoeléctricos"},
    {prodId:"41112103",label:"Sensores de fibra"},
    {prodId:"41112104",label:"Transductores de audio"},
    {prodId:"41112105",label:"Transmisores de temperatura"},
    {prodId:"41112106",label:"Transmisores de humedad"},
    {prodId:"41112107",label:"Transductores electro neumáticos"},
    {prodId:"41112108",label:"Celdas de carga"},
    {prodId:"41112109",label:"Transductor de corriente"},
    {prodId:"41112110",label:"Transductor de presión"},
    {prodId:"41112111",label:"Transductor de desplazamiento"},
    {prodId:"41112112",label:"Transductor de energía eléctrica"},
    {prodId:"41112113",label:"Transductor de energía mecánica"},
    {prodId:"41112114",label:"Transmisor termohigrómetro"},
    {prodId:"41112200",label:"Instrumentos de medida de temperatura y calor"},
    {prodId:"41112201",label:"Calorímetros"},
    {prodId:"41112202",label:"Equipos de rastreo de calor"},
    {prodId:"41112203",label:"Grabadoras de punto de fusión"},
    {prodId:"41112204",label:"Pirómetros"},
    {prodId:"41112205",label:"Reguladores de temperatura"},
    {prodId:"41112206",label:"Termo cúpulas"},
    {prodId:"41112207",label:"Termógrafos"},
    {prodId:"41112209",label:"Termostatos"},
    {prodId:"41112210",label:"Termómetros de lectura remota"},
    {prodId:"41112211",label:"Termómetros de resistencia"},
    {prodId:"41112212",label:"Termómetros de superficie"},
    {prodId:"41112213",label:"Termómetros de mano"},
    {prodId:"41112214",label:"Controles de temperatura criogénicos"},
    {prodId:"41112215",label:"Controladores de temperatura humidificadores"},
    {prodId:"41112216",label:"Termopozos"},
    {prodId:"41112217",label:"Termo cabezas"},
    {prodId:"41112219",label:"Sondas termopares"},
    {prodId:"41112220",label:"Termómetros de refrigerador o congelador de laboratorio"},
    {prodId:"41112221",label:"Termómetros de incubadora de laboratorio"},
    {prodId:"41112222",label:"Indicador de temperatura"},
    {prodId:"41112223",label:"Indicador compuesto"},
    {prodId:"41112224",label:"Termómetro infrarrojo"},
    {prodId:"41112225",label:"Detector de temperatura de resistencia"},
    {prodId:"41112226",label:"Sensor termopar de baja temperatura"},
    {prodId:"41112227",label:"Sensor termopar de alta temperatura"},
    {prodId:"41112228",label:"Analizador de eficiencia de combustión"},
    {prodId:"41112229",label:"Medidor de calor"},
    {prodId:"41112230",label:"Calibrador pirómetro"},
    {prodId:"41112231",label:"Probador de nube de aceite y de punto de vertido"},
    {prodId:"41112232",label:"Instrumento medidor de punto de congelación"},
    {prodId:"41112233",label:"Instrumento medidor de punto de derretimiento"},
    {prodId:"41112234",label:"Flujómetro de calor"},
    {prodId:"41112235",label:"Instrumento para medir calor específico"},
    {prodId:"41112236",label:"Probador de punto de ablandamiento del asfalto"},
    {prodId:"41112237",label:"Termómetro de punto de rocío"},
    {prodId:"41112238",label:"Probador de punto de caída de temperatura"},
    {prodId:"41112239",label:"Termómetro de vidrio"},
    {prodId:"41112240",label:"Banda de verificación de temperatura de la unidad de sangre"},
    {prodId:"41112300",label:"Instrumentos de medición de la humedad"},
    {prodId:"41112301",label:"Higrómetros"},
    {prodId:"41112302",label:"Sicrómetros"},
    {prodId:"41112303",label:"Probadores de humedad de temperatura"},
    {prodId:"41112304",label:"Medidores de rocío"},
    {prodId:"41112305",label:"Controlador de humedad"},
    {prodId:"41112306",label:"Calibrador de humedad"},
    {prodId:"41112307",label:"Probador de retención de agua por el cemento"},
    {prodId:"41112400",label:"Instrumentos de medida y control de la presión"},
    {prodId:"41112401",label:"Indicadores de profundidad"},
    {prodId:"41112402",label:"Manostatos"},
    {prodId:"41112403",label:"Indicadores de presión"},
    {prodId:"41112404",label:"Reguladores de presión"},
    {prodId:"41112405",label:"Grabadoras de presión o de vacío"},
    {prodId:"41112406",label:"Indicadores de vacío"},
    {prodId:"41112407",label:"Instrumentos o controles de nivel de líquido"},
    {prodId:"41112408",label:"Intensificadores de presión"},
    {prodId:"41112409",label:"Escáneres de presión"},
    {prodId:"41112410",label:"Transmisores de presión"},
    {prodId:"41112411",label:"Controladores de presión"},
    {prodId:"41112412",label:"Indicador de presión"},
    {prodId:"41112413",label:"Indicador de presión diferencial"},
    {prodId:"41112414",label:"Calibrador de presión"},
    {prodId:"41112415",label:"Aparato de warburg"},
    {prodId:"41112416",label:"Probador de presión de destrucción o rompimiento"},
    {prodId:"41112417",label:"Instrumento medidor de la presión de vapor"},
    {prodId:"41112418",label:"Probador de presión capilar"},
    {prodId:"41112419",label:"Medidor de resistencia de inhalación de los filtros de cigarrillos"},
    {prodId:"41112420",label:"Indicador de caída de presión"},
    {prodId:"41112421",label:"Probador de dureza de las frutas"},
    {prodId:"41112422",label:"Probador de presión hidráulica"},
    {prodId:"41112423",label:"Altímetro de presión"},
    {prodId:"41112500",label:"Instrumentos de medición y observación del caudal de fluidos"},
    {prodId:"41112501",label:"Flujómetros"},
    {prodId:"41112502",label:"Reómetros"},
    {prodId:"41112503",label:"Rotámetros"},
    {prodId:"41112504",label:"Medidores de agua"},
    {prodId:"41112505",label:"Repuestos para medidores de agua"},
    {prodId:"41112506",label:"Venturis"},
    {prodId:"41112508",label:"Indicadores de gas"},
    {prodId:"41112509",label:"Monitores de temperatura y velocidad del aire"},
    {prodId:"41112510",label:"Indicadores visuales de flujo"},
    {prodId:"41112511",label:"Ventanas para visualizar el flujo"},
    {prodId:"41112512",label:"Computadores o totalizadores de flujo"},
    {prodId:"41112513",label:"Plato de orificio"},
    {prodId:"41112514",label:"Indicadores de aceite"},
    {prodId:"41112516",label:"Transmisores de flujo"},
    {prodId:"41112517",label:"Flujómetros ópticos y accesorios"},
    {prodId:"41112518",label:"Equipo para probar la eficiencia de las bombas"},
    {prodId:"41112519",label:"Indicador de piloto"},
    {prodId:"41112520",label:"Medidor de flujo de bombas contra incendios"},
    {prodId:"41112521",label:"Columna de calibración"},
    {prodId:"41112800",label:"Equipo e instrumentos relacionados con el transporte"},
    {prodId:"41112801",label:"Velocímetros"},
    {prodId:"41112802",label:"Tacómetros"},
    {prodId:"41112803",label:"Discos para tacómetros"},
    {prodId:"41112804",label:"Equipo de medición de la abrasión de los rieles"},
    {prodId:"41112805",label:"Instrumento de medición del hundimiento de los rieles"},
    {prodId:"41112806",label:"Instrumento de medición de la separación entre las uniones de los rieles"},
    {prodId:"41112807",label:"Instrumento de medición de la fuerza de unión de los travesaños de los rieles"},
    {prodId:"41112808",label:"Probador de los vagones eléctricos"},
    {prodId:"41112809",label:"Probador de taxímetros"},
    {prodId:"41112900",label:"Equipo e instrumentos de navegación"},
    {prodId:"41112901",label:"Compases para encontrar la dirección"},
    {prodId:"41112902",label:"Instrumentos de navegación por radio"},
    {prodId:"41112903",label:"Sextantes"},
    {prodId:"41112904",label:"Dispositivos de control complejo"},
    {prodId:"41112905",label:"Faro infrarrojo"},
    {prodId:"41112906",label:"Faro de radio"},
    {prodId:"41112907",label:"Boya de radio"},
    {prodId:"41113000",label:"Instrumentos de suministros evaluación química"},
    {prodId:"41113001",label:"Controladores de analizador digital"},
    {prodId:"41113002",label:"Analizadores de bioluminiscencia o quimioluminiscencia"},
    {prodId:"41113003",label:"Analizadores de electro gravimetría"},
    {prodId:"41113004",label:"Analizadores de ionización de la llama"},
    {prodId:"41113005",label:"Analizadores de iones"},
    {prodId:"41113006",label:"Analizadores de radiometría"},
    {prodId:"41113007",label:"Analizadores de acceso aleatorio"},
    {prodId:"41113008",label:"Analizadores de cintigrafía"},
    {prodId:"41113009",label:"Analizadores térmicos diferenciales"},
    {prodId:"41113010",label:"Analizadores térmicos de gravimetría"},
    {prodId:"41113023",label:"Equipo de partición de geles"},
    {prodId:"41113024",label:"Hidrómetros"},
    {prodId:"41113025",label:"Monocromadores"},
    {prodId:"41113026",label:"Nefelómetros"},
    {prodId:"41113027",label:"Osmómetros"},
    {prodId:"41113029",label:"Polarógrafos"},
    {prodId:"41113030",label:"Escáner radiocromatográfico"},
    {prodId:"41113031",label:"Sacarímetros"},
    {prodId:"41113033",label:"Volúmetros"},
    {prodId:"41113034",label:"Tiras o papeles para prueba de ph"},
    {prodId:"41113035",label:"Tiras o papeles para pruebas químicas"},
    {prodId:"41113036",label:"Micro placas"},
    {prodId:"41113037",label:"Lectores de micro placas"},
    {prodId:"41113038",label:"Alcoholímetro"},
    {prodId:"41113039",label:"Accesorios para osmómetros"},
    {prodId:"41113040",label:"Contador de colonias (de microorganismos)"},
    {prodId:"41113041",label:"Analizador elemental"},
    {prodId:"41113042",label:"Analizador de leche"},
    {prodId:"41113043",label:"Sistema de determinación de la fibra de la dieta"},
    {prodId:"41113044",label:"Hidrómetro de alcohol"},
    {prodId:"41113045",label:"Probador de película de aceite"},
    {prodId:"41113046",label:"Probador de adhesión"},
    {prodId:"41113047",label:"Probador de características de la espuma del aceite"},
    {prodId:"41113048",label:"Probador de resistencia del color"},
    {prodId:"41113049",label:"Analizador de combustión"},
    {prodId:"41113050",label:"Detector de arsénico"},
    {prodId:"41113051",label:"Probador de reducción de oxidación"},
    {prodId:"41113052",label:"Probador de tiza o pigmento que suelta la pintura seca"},
    {prodId:"41113053",label:"Probador de prevención de óxido"},
    {prodId:"41113054",label:"Probador de demulsibilidad"},
    {prodId:"41113055",label:"Probador de goma en el combustible"},
    {prodId:"41113056",label:"Cable de experimentación de llama"},
    {prodId:"41113057",label:"Densitómetro de lodo"},
    {prodId:"41113100",label:"Analizadores y monitores de gas"},
    {prodId:"41113101",label:"Analizadores de emisión exhaustiva de automóviles"},
    {prodId:"41113102",label:"Analizadores de combustión catalítica"},
    {prodId:"41113103",label:"Analizadores de absorción de gas químico"},
    {prodId:"41113104",label:"Explosímetros"},
    {prodId:"41113105",label:"Analizadores o detectores de hidrocarburos"},
    {prodId:"41113106",label:"Analizadores de absorción de infrarrojos o ultravioleta"},
    {prodId:"41113107",label:"Analizadores de gas nitrógeno"},
    {prodId:"41113108",label:"Analizadores de óxido de nitrógeno"},
    {prodId:"41113109",label:"Equipo orsat"},
    {prodId:"41113110",label:"Analizadores de gas de oxígeno"},
    {prodId:"41113111",label:"Analizadores de ozono"},
    {prodId:"41113112",label:"Analizadores de susceptibilidad paramagnética"},
    {prodId:"41113113",label:"Detectores o analizadores de dióxido de sulfuro"},
    {prodId:"41113114",label:"Analizadores de conductividad térmica"},
    {prodId:"41113115",label:"Detectores de radón"},
    {prodId:"41113116",label:"Tubos detectores de gas"},
    {prodId:"41113117",label:"Monitores simples de gas"},
    {prodId:"41113118",label:"Monitores múltiples de gas"},
    {prodId:"41113119",label:"Analizadores de dióxido de carbono disuelto"},
    {prodId:"41113120",label:"Analizador de monóxido de carbono"},
    {prodId:"41113121",label:"Sistema analizador de vapor de gas de vehículos"},
    {prodId:"41113122",label:"Olfatómetro"},
    {prodId:"41113123",label:"Probador de humo"},
    {prodId:"41113124",label:"Respirómetro"},
    {prodId:"41113300",label:"Analizadores de líquidos, sólidos y elementos"},
    {prodId:"41113301",label:"Analizadores ácidos o de base"},
    {prodId:"41113302",label:"Albuminómetros"},
    {prodId:"41113304",label:"Analizadores de bauxita"},
    {prodId:"41113305",label:"Analizadores de calcio"},
    {prodId:"41113306",label:"Analizadores de cloruro"},
    {prodId:"41113308",label:"Analizadores de electrolitos"},
    {prodId:"41113309",label:"Analizadores de enzimas"},
    {prodId:"41113310",label:"Analizadores de ácidos grasos"},
    {prodId:"41113311",label:"Lámpara detectora de haluro"},
    {prodId:"41113312",label:"Analizadores de lactato"},
    {prodId:"41113313",label:"Instrumentos de prueba de aceite mineral"},
    {prodId:"41113314",label:"Analizadores monitores de contenido de aceite"},
    {prodId:"41113315",label:"Analizadores de carbono orgánico"},
    {prodId:"41113316",label:"Equipo de prueba de petróleo"},
    {prodId:"41113318",label:"Analizadores de uranio"},
    {prodId:"41113319",label:"Analizadores de agua"},
    {prodId:"41113320",label:"Kit de prueba de aceite lubricante"},
    {prodId:"41113321",label:"Probador de pintura"},
    {prodId:"41113322",label:"Analizador de nitrógeno o nitrato o nitrito"},
    {prodId:"41113323",label:"Analizadores de azúcar"},
    {prodId:"41113324",label:"Probador de fuerza de ocultamiento de la pintura"},
    {prodId:"41113325",label:"Probador de posibilidad de lavar la pintura"},
    {prodId:"41113326",label:"Instrumento medidor del grano de la pintura"},
    {prodId:"41113327",label:"Aplicador de película de pintura"},
    {prodId:"41113328",label:"Analizador de mercurio"},
    {prodId:"41113329",label:"Probador de sólidos suspendidos ss"},
    {prodId:"41113330",label:"Instrumento para medir el espesor del lodo"},
    {prodId:"41113331",label:"Probador de congelación y descongelación"},
    {prodId:"41113332",label:"Analizador de raciones líquidas"},
    {prodId:"41113333",label:"Analizador de potencial zeta"},
    {prodId:"41113334",label:"Equipo de prueba de pintura de revestimiento"},
    {prodId:"41113335",label:"Analizador de componentes múltiples de compuestos disueltos"},
    {prodId:"41113336",label:"Probador de número de emulsiones de vapor"},
    {prodId:"41113337",label:"Probador de tamaño del grano o de dispersión"},
    {prodId:"41113338",label:"Medidor de demanda de oxígeno bioquímico bod"},
    {prodId:"41113339",label:"Medidor de demanda de oxígeno químico cod"},
    {prodId:"41113400",label:"Instrumentos de evaluación nuclear"},
    {prodId:"41113401",label:"Contadores alfa"},
    {prodId:"41113402",label:"Contadores alfa beta"},
    {prodId:"41113403",label:"Contadores beta"},
    {prodId:"41113404",label:"Contadores beta gamma"},
    {prodId:"41113405",label:"Contadores gama"},
    {prodId:"41113406",label:"Medidores kvp"},
    {prodId:"41113407",label:"Micro analizadores de rayos x"},
    {prodId:"41113600",label:"Equipo de medición y comprobación eléctrica"},
    {prodId:"41113601",label:"Amperímetros"},
    {prodId:"41113602",label:"Medidores  de fase"},
    {prodId:"41113603",label:"Puentes de laboratorio"},
    {prodId:"41113604",label:"Medidores de capacitancia"},
    {prodId:"41113605",label:"Derivatógrafos de análisis térmico"},
    {prodId:"41113606",label:"Indicadores de monitoreo de congelamiento"},
    {prodId:"41113607",label:"Monitores de estrés de calor"},
    {prodId:"41113608",label:"Contadores de coincidencia o no coincidencia"},
    {prodId:"41113611",label:"Medidores de diafonía"},
    {prodId:"41113612",label:"Probadores de resistencia de la tierra"},
    {prodId:"41113613",label:"Grabadoras de valor eléctrico"},
    {prodId:"41113614",label:"Medidores de campo electromagnético"},
    {prodId:"41113615",label:"Electrómetros"},
    {prodId:"41113616",label:"Cargas electrónicas"},
    {prodId:"41113617",label:"Equipos de medición de la fuerza del campo"},
    {prodId:"41113618",label:"Instrumentos de medición de ganancia"},
    {prodId:"41113619",label:"Galvanómetros"},
    {prodId:"41113620",label:"Cable de detección de alto voltaje"},
    {prodId:"41113621",label:"Medidores de impedancia"},
    {prodId:"41113622",label:"Bobinas o cajas de inductancias calibradas"},
    {prodId:"41113623",label:"Medidores de resistencia al aislamiento"},
    {prodId:"41113624",label:"Medidores de aislamiento"},
    {prodId:"41113625",label:"Cámaras de ionización"},
    {prodId:"41113626",label:"Ionómetros"},
    {prodId:"41113627",label:"Medidores de circuito de línea a tierra"},
    {prodId:"41113628",label:"Megohmetros"},
    {prodId:"41113629",label:"Medidores de filtración de microondas"},
    {prodId:"41113630",label:"Multímetros"},
    {prodId:"41113631",label:"Ohmetros"},
    {prodId:"41113632",label:"Oscilógrafos"},
    {prodId:"41113633",label:"Potenciómetros"},
    {prodId:"41113634",label:"Medidores q"},
    {prodId:"41113635",label:"Equipo de medición de resistencia calibrada"},
    {prodId:"41113636",label:"Generadores de nivel"},
    {prodId:"41113637",label:"Medidores de voltaje o de corriente"},
    {prodId:"41113638",label:"Osciloscopios"},
    {prodId:"41113639",label:"Acelerómetros"},
    {prodId:"41113640",label:"Medidores de vatios"},
    {prodId:"41113641",label:"Probadores de circuitos gfi"},
    {prodId:"41113642",label:"Probador de circuitos"},
    {prodId:"41113644",label:"Trazador de circuitos"},
    {prodId:"41113645",label:"Dispositivos de filtración de tierra"},
    {prodId:"41113646",label:"Calibrador o simulador de temperatura"},
    {prodId:"41113647",label:"Calibrador o simulador de frecuencia"},
    {prodId:"41113649",label:"Sofómetro"},
    {prodId:"41113650",label:"Probador funcional"},
    {prodId:"41113651",label:"Tablero de medición de resistencia"},
    {prodId:"41113652",label:"Tablero de ensamblaje de cables"},
    {prodId:"41113653",label:"Probador de circuito en serie"},
    {prodId:"41113654",label:"Medidor de circuito de corriente"},
    {prodId:"41113655",label:"Set de prueba de generador"},
    {prodId:"41113656",label:"Probador de servo sistema"},
    {prodId:"41113657",label:"Probador de disyuntor (interruptor)"},
    {prodId:"41113658",label:"Probador de durabilidad del interruptor"},
    {prodId:"41113659",label:"Medidor de energía reactiva (var)"},
    {prodId:"41113660",label:"Probador de oscilador"},
    {prodId:"41113661",label:"Probador de regulador de voltaje"},
    {prodId:"41113662",label:"Resonador de cavidades"},
    {prodId:"41113663",label:"Probador transformador"},
    {prodId:"41113664",label:"Indicador de secuencia de fase"},
    {prodId:"41113665",label:"Probador de resistencia de contacto"},
    {prodId:"41113666",label:"Probador de pararrayos"},
    {prodId:"41113667",label:"Equipo de prueba del medidor de vatios hora"},
    {prodId:"41113668",label:"Probador de capacidad"},
    {prodId:"41113669",label:"Detector de línea de voltaje"},
    {prodId:"41113670",label:"Equipo de prueba de resistencia"},
    {prodId:"41113671",label:"Divisor de corriente"},
    {prodId:"41113672",label:"Probador de relé"},
    {prodId:"41113673",label:"Medidor de panel y tablero de control"},
    {prodId:"41113674",label:"Probador de máquina pulidora"},
    {prodId:"41113675",label:"Equipo de prueba de control de frecuencia"},
    {prodId:"41113676",label:"Probador de bajo voltaje"},
    {prodId:"41113677",label:"Probador de resistencia de voltaje"},
    {prodId:"41113681",label:"Probador combi (voltaje, corriente, frecuencia y otras)"},
    {prodId:"41113682",label:"Probador de corriente eléctrica"},
    {prodId:"41113683",label:"Probador de lámparas"},
    {prodId:"41113684",label:"Calibrador medidor de voltaje y corriente"},
    {prodId:"41113686",label:"Medidor de pulso"},
    {prodId:"41113687",label:"Máquina para probar freno"},
    {prodId:"41113688",label:"Equipo para probar convertidores"},
    {prodId:"41113689",label:"Equipo para probar controladores"},
    {prodId:"41113700",label:"Instrumentos de medición y comprobación de comunicación electrónica"},
    {prodId:"41113701",label:"Probador de tubos de rayos catódicos"},
    {prodId:"41113702",label:"Comparadores"},
    {prodId:"41113703",label:"Acoplamiento direccional"},
    {prodId:"41113704",label:"Probadores de circuitos integrados"},
    {prodId:"41113705",label:"Probadores de estado lógico"},
    {prodId:"41113706",label:"Probadores de semiconductores"},
    {prodId:"41113707",label:"Probadores de circuitos de transistores"},
    {prodId:"41113708",label:"Medidores de energía"},
    {prodId:"41113709",label:"Medidores de modulación"},
    {prodId:"41113710",label:"Medidor de nivel"},
    {prodId:"41113711",label:"Analizadores de red"},
    {prodId:"41113712",label:"Probadores de cintas"},
    {prodId:"41113713",label:"Probadores de velocidad de la cinta"},
    {prodId:"41113714",label:"Diferenciador"},
    {prodId:"41113715",label:"Probadores de redes digitales de servicios integrados isdn"},
    {prodId:"41113716",label:"Localizadores de fallas de fibra óptica"},
    {prodId:"41113717",label:"Fuentes de prueba de fibra óptica"},
    {prodId:"41113718",label:"Analizadores de protocolo"},
    {prodId:"41113719",label:"Probador de pérdida óptica"},
    {prodId:"41113720",label:"Equipo de prueba de intensidad del tráfico"},
    {prodId:"41113721",label:"Instrumento de medición de señal de video"},
    {prodId:"41113722",label:"Probador de interferencia electromagnética (emi)"},
    {prodId:"41113723",label:"Probador de equipo de radio"},
    {prodId:"41113724",label:"Set de prueba de teléfonos"},
    {prodId:"41113725",label:"Medidor de energía óptica"},
    {prodId:"41113726",label:"Probador de resistencia al calor"},
    {prodId:"41113727",label:"Medidor de distorsión"},
    {prodId:"41113728",label:"Cámara ambiental de escudo electromagnético"},
    {prodId:"41113729",label:"Medidor de salida del amplificador"},
    {prodId:"41113730",label:"Probador de televisiones"},
    {prodId:"41113731",label:"Probador de cristales"},
    {prodId:"41113732",label:"Probador de amplificadores"},
    {prodId:"41113733",label:"Probador de antenas"},
    {prodId:"41113734",label:"Medidor de desviación de frecuencia"},
    {prodId:"41113735",label:"Probador de equipo de microondas"},
    {prodId:"41113736",label:"Refractómetro electrónico y de comunicación"},
    {prodId:"41113737",label:"Medidor de ruido"},
    {prodId:"41113738",label:"Probador de sobrecarga de la línea de comunicación"},
    {prodId:"41113739",label:"Probador de susceptibilidad electromagnética"},
    {prodId:"41113740",label:"Probador de cable de video, voz y datos"},
    {prodId:"41113800",label:"Instrumentos geofísicos, geotécnicos e hidrológicos"},
    {prodId:"41113801",label:"Compases geológicos"},
    {prodId:"41113802",label:"Aparatos de prospección geológica"},
    {prodId:"41113803",label:"Instrumentos geofísicos electromagnéticos"},
    {prodId:"41113804",label:"Instrumentos geofísicos de gravedad"},
    {prodId:"41113805",label:"Instrumentos geofísicos de polarización inducida ip"},
    {prodId:"41113806",label:"Instrumentos geofísicos magnetómetros"},
    {prodId:"41113807",label:"Instrumentos geofísicos de resistividad"},
    {prodId:"41113808",label:"Gravímetros"},
    {prodId:"41113809",label:"Radar de penetración del suelo"},
    {prodId:"41113810",label:"Equipo de prueba hidrotérmica"},
    {prodId:"41113811",label:"Probador de resistencia sólida"},
    {prodId:"41113812",label:"Probador de fricción del suelo"},
    {prodId:"41113813",label:"Agregado estándar"},
    {prodId:"41113814",label:"Probador de límite plástico del suelo"},
    {prodId:"41113815",label:"Probador del límite de encogimiento del suelo"},
    {prodId:"41113816",label:"Probador de consolidación"},
    {prodId:"41113817",label:"Probador del límite líquido del suelo"},
    {prodId:"41113818",label:"Probador de resistencia de la placa"},
    {prodId:"41113819",label:"Analizador de suelos"},
    {prodId:"41113820",label:"Probador de gravedad específica agregada"},
    {prodId:"41113821",label:"Probador de penetración del suelo"},
    {prodId:"41113822",label:"Probador de tasa de resistencia california"},
    {prodId:"41113823",label:"Probador de expansión de espécimen"},
    {prodId:"41113824",label:"Probador de permeabilidad de cabeza de suelo"},
    {prodId:"41113825",label:"Analizador de agregado del suelo"},
    {prodId:"41113826",label:"Analizador de textura del suelo"},
    {prodId:"41113827",label:"Aparato de compresión no confinada del suelo"},
    {prodId:"41113828",label:"Aparato de cono de densidad de la arena"},
    {prodId:"41113829",label:"Equipo de recolección de suelo bajo el agua"},
    {prodId:"41113830",label:"Instrumento para medir el peso de la unidad de agregado"},
    {prodId:"41113831",label:"Instrumento para medir el agua superficial en la superficie del agregado"},
    {prodId:"41113900",label:"Equipo para medición de suelos"},
    {prodId:"41113901",label:"Instrumentos de medición de perforación"},
    {prodId:"41113902",label:"Probadores de disolución o desintegración"},
    {prodId:"41113903",label:"Aparatos medidores del tamaño de partículas"},
    {prodId:"41113904",label:"Penetrómetros"},
    {prodId:"41113905",label:"Aparatos para probar permeabilidad"},
    {prodId:"41113906",label:"Aparatos para estimar permeabilidad o porosidad"},
    {prodId:"41113907",label:"Porosímetros"},
    {prodId:"41113908",label:"Aparatos para probar arena"},
    {prodId:"41113909",label:"Aparatos muestreadores del núcleo del suelo"},
    {prodId:"41113910",label:"Kits de probadores del suelo"},
    {prodId:"41114200",label:"Instrumentos de agrimensión"},
    {prodId:"41114201",label:"Cintas medidoras"},
    {prodId:"41114202",label:"Varillas medidoras"},
    {prodId:"41114203",label:"Tablas medidoras"},
    {prodId:"41114204",label:"Teodolitos"},
    {prodId:"41114205",label:"Estacas de localización"},
    {prodId:"41114206",label:"Centro de localización"},
    {prodId:"41114207",label:"Estación total"},
    {prodId:"41114208",label:"Instrumento de longimetría"},
    {prodId:"41114209",label:"Taquímetro o taqueómetro"},
    {prodId:"41114210",label:"Nivel óptico"},
    {prodId:"41114211",label:"Altímetro"},
    {prodId:"41114212",label:"Estereoscopio de espejo"},
    {prodId:"41114213",label:"Comparador de coordinadas"},
    {prodId:"41114214",label:"Plantilla de encuestas"},
    {prodId:"41114215",label:"Dendrómetro"},
    {prodId:"41114216",label:"Alidada"},
    {prodId:"41114217",label:"Línea de plomo"},
    {prodId:"41114218",label:"Planímetros"},
    {prodId:"41114219",label:"Brazo de la plomada"},
    {prodId:"41114220",label:"Tránsito"},
    {prodId:"41114221",label:"Escuadra óptica"},
    {prodId:"41114400",label:"Instrumentos meteorológicos"},
    {prodId:"41114401",label:"Anemómetros"},
    {prodId:"41114402",label:"Barómetros"},
    {prodId:"41114403",label:"Grabadoras de precipitación o evaporación"},
    {prodId:"41114404",label:"Aparatos de radiosonda"},
    {prodId:"41114405",label:"Grabadoras de lluvia"},
    {prodId:"41114406",label:"Aparatos para observar la precipitación o evaporación en la superficie"},
    {prodId:"41114407",label:"Aparatos para observar la radiación solar en la superficie"},
    {prodId:"41114408",label:"Aparatos para observar la temperatura o humedad en la superficie"},
    {prodId:"41114409",label:"Aparatos para observar el viento en la superficie"},
    {prodId:"41114410",label:"Estaciones meteorológicas"},
    {prodId:"41114411",label:"Accesorios para instrumentos de meteorología"},
    {prodId:"41114412",label:"Sistemas sonoros de radio acústica"},
    {prodId:"41114413",label:"Ceilómetro"},
    {prodId:"41114414",label:"Equipo receptor de satélite meteorológico"},
    {prodId:"41114415",label:"Sistema de análisis de rayos"},
    {prodId:"41114416",label:"Grabadora o escáner de tabla meteorológica"},
    {prodId:"41114417",label:"Robot boya meteorológica"},
    {prodId:"41114418",label:"Sistema para recibir y analizar información del satélite meteorológico"},
    {prodId:"41114419",label:"Sistema de alerta del nivel bajo del corte de viento"},
    {prodId:"41114420",label:"Calibrador de barómetros"},
    {prodId:"41114421",label:"Calibrador de higrómetros"},
    {prodId:"41114422",label:"Calibrador de medidor de lluvia"},
    {prodId:"41114423",label:"Calibrador de sistema automático de clima"},
    {prodId:"41114424",label:"Calibrador de anemómetros"},
    {prodId:"41114425",label:"Calibrador de actinómetros"},
    {prodId:"41114426",label:"Interruptor barométrico"},
    {prodId:"41114427",label:"Veletas"},
    {prodId:"41114500",label:"Instrumentos mecánicos"},
    {prodId:"41114501",label:"Dinamómetros"},
    {prodId:"41114502",label:"Elastómetros"},
    {prodId:"41114503",label:"Extensómetros"},
    {prodId:"41114504",label:"Instrumentos de medición de densidad"},
    {prodId:"41114505",label:"Instrumentos de prueba de redondez"},
    {prodId:"41114506",label:"Esferómetros"},
    {prodId:"41114507",label:"Maquinas de prueba de resortes"},
    {prodId:"41114508",label:"Probadores de superficie"},
    {prodId:"41114509",label:"Tensiómetros"},
    {prodId:"41114510",label:"Limitador de torsión"},
    {prodId:"41114511",label:"Probador de suavidad"},
    {prodId:"41114512",label:"Probador de balance rotatorio de alta velocidad"},
    {prodId:"41114513",label:"Instrumento de medición de excentricidad"},
    {prodId:"41114514",label:"Instrumento de medición de vibración de torsión"},
    {prodId:"41114515",label:"Probador de fuerza centrífuga"},
    {prodId:"41114516",label:"Instrumento de medición de fuerza de corte"},
    {prodId:"41114517",label:"Probador de hinchazón hidráulica"},
    {prodId:"41114518",label:"Probador de soporte de cremallera"},
    {prodId:"41114519",label:"Sistema de remolque y carrito"},
    {prodId:"41114520",label:"Probador de caída"},
    {prodId:"41114521",label:"Probador de compresor de aire"},
    {prodId:"41114522",label:"Probador de engranajes"},
    {prodId:"41114523",label:"Barra de prueba"},
    {prodId:"41114524",label:"Probador hidráulico"},
    {prodId:"41114525",label:"Probador de filtros de aceite"},
    {prodId:"41114526",label:"Probador de limpiador de aire"},
    {prodId:"41114527",label:"Probador de correa"},
    {prodId:"41114528",label:"Instrumento de medición del mecanismo del movimiento de planeación"},
    {prodId:"41114529",label:"Probador de llantas en movimiento"},
    {prodId:"41114530",label:"Probador del estrés del pegante"},
    {prodId:"41114600",label:"Instrumentos de verificación de metales, metalurgia y materiales estructurales"},
    {prodId:"41114601",label:"Probadores de abrasión"},
    {prodId:"41114602",label:"Probadores de compresión"},
    {prodId:"41114603",label:"Instrumentos para probar concreto o cemento"},
    {prodId:"41114604",label:"Probadores de corrosión"},
    {prodId:"41114605",label:"Detectores de grietas o corrosión"},
    {prodId:"41114606",label:"Probadores de fuga"},
    {prodId:"41114607",label:"Máquinas para probar ductilidad"},
    {prodId:"41114608",label:"Probadores de fatiga"},
    {prodId:"41114609",label:"Aparatos probadores de forja"},
    {prodId:"41114610",label:"Aparatos probadores de fundición"},
    {prodId:"41114611",label:"Probadores de dureza"},
    {prodId:"41114612",label:"Probadores de impacto"},
    {prodId:"41114613",label:"Marco de carga"},
    {prodId:"41114614",label:"Instrumentos para probar metales"},
    {prodId:"41114615",label:"Instrumentos probadores foto elásticos"},
    {prodId:"41114616",label:"Indicadores de resistencia al estrés"},
    {prodId:"41114617",label:"Probadores de relajación"},
    {prodId:"41114618",label:"Instrumentos de medición de rugosidad"},
    {prodId:"41114619",label:"Probadores de fuerza de corte"},
    {prodId:"41114620",label:"Aparatos para probar choque"},
    {prodId:"41114621",label:"Probadores de tensión"},
    {prodId:"41114622",label:"Probadores de torsión"},
    {prodId:"41114623",label:"Máquinas para pruebas de flexión o transversales"},
    {prodId:"41114624",label:"Probadores de vibración"},
    {prodId:"41114625",label:"Probadores de desgaste"},
    {prodId:"41114626",label:"Aparatos para probar soldaduras"},
    {prodId:"41114627",label:"Aro de calibración"},
    {prodId:"41114628",label:"Probador de plasticidad"},
    {prodId:"41114629",label:"Probador de distorsión por calor"},
    {prodId:"41114630",label:"Probador de choque térmico"},
    {prodId:"41114631",label:"Probador de estrés"},
    {prodId:"41114632",label:"Probador de fuerza tensora"},
    {prodId:"41114633",label:"Probador de expansión térmica"},
    {prodId:"41114634",label:"Probador erichsen"},
    {prodId:"41114635",label:"Probador avalador de joyas"},
    {prodId:"41114636",label:"Instrumento de medición de condición moldeadora de polímeros"},
    {prodId:"41114637",label:"Aparatos de prueba de haces de luz"},
    {prodId:"41114638",label:"Probador de carga"},
    {prodId:"41114639",label:"Probador de flujo de cemento"},
    {prodId:"41114640",label:"Equipo de medición de plano de carretera"},
    {prodId:"41114641",label:"Equipo de curar cemento para laboratorio"},
    {prodId:"41114642",label:"Detector de perforación"},
    {prodId:"41114643",label:"Probador de estabilidad del cemento"},
    {prodId:"41114644",label:"Instrumento para medir aire en el concreto"},
    {prodId:"41114645",label:"Probador de longitud de mortero"},
    {prodId:"41114646",label:"Probador de aguja vicat"},
    {prodId:"41114647",label:"Probador de asentamiento"},
    {prodId:"41114648",label:"Probador de vibración del cemento o concreto"},
    {prodId:"41114649",label:"Probador de permeabilidad del mortero"},
    {prodId:"41114650",label:"Molde cilíndrico de concreto"},
    {prodId:"41114700",label:"Instrumentos para comprobación de papel, madera y tejidos"},
    {prodId:"41114701",label:"Instrumentos de prueba de cartón"},
    {prodId:"41114702",label:"Probadores de resistencia de textiles"},
    {prodId:"41114703",label:"Instrumentos para probar cuero"},
    {prodId:"41114704",label:"Instrumentos para probar papel"},
    {prodId:"41114705",label:"Instrumentos para probar textiles"},
    {prodId:"41114706",label:"Instrumentos para probar madera"},
    {prodId:"41114707",label:"Probador de fuerza de textiles o papel"},
    {prodId:"41114708",label:"Probador de permeabilidad de vapor de agua"},
    {prodId:"41114709",label:"Instrumento para probar yam (textil sintético)"},
    {prodId:"41114710",label:"Probador de transmisión térmica"},
    {prodId:"41114711",label:"Probador de caída de textiles"},
    {prodId:"41114712",label:"Probador de repelencia de agua para textiles"},
    {prodId:"41114713",label:"Probador de recuperación de arrugas para textiles"},
    {prodId:"41114714",label:"Probador de maduración de textiles"},
    {prodId:"41114715",label:"Probador de permeabilidad al aire, papel o textiles"},
    {prodId:"41114716",label:"Probador de encogimiento de textiles"},
    {prodId:"41114717",label:"Probador de cocción de textiles"},
    {prodId:"41114718",label:"Probador de grado de formación de motas para textiles"},
    {prodId:"41114719",label:"Máquina probadora de hojas de papel"},
    {prodId:"41114720",label:"Probador de absorción de agua de papel y paño"},
    {prodId:"41114721",label:"Probador de longitud de fibra"},
    {prodId:"41114722",label:"Probador de resistencia al rompimiento"},
    {prodId:"41114723",label:"Probador de fuerza de estallido"},
    {prodId:"41115100",label:"Instrumentos para comprobación de carbón y mineral"},
    {prodId:"41115101",label:"Instrumentos para probar carbón"},
    {prodId:"41115102",label:"Goniómetro"},
    {prodId:"41115200",label:"Sistemas y componentes de radar y sonar"},
    {prodId:"41115201",label:"Sistemas de vigilancia basados en radar"},
    {prodId:"41115202",label:"Polarrotores (elementos centrales de la antena)"},
    {prodId:"41115203",label:"Radar meteorológico"},
    {prodId:"41115300",label:"Equipo de generación y medición de luz y ondas"},
    {prodId:"41115301",label:"Medidores de absorción de luz"},
    {prodId:"41115302",label:"Cámaras anecóicas"},
    {prodId:"41115303",label:"Analizadores de frecuencia"},
    {prodId:"41115304",label:"Contadores o temporizadores o divisores de frecuencia"},
    {prodId:"41115305",label:"Medidores de frecuencia eléctrica"},
    {prodId:"41115306",label:"Interferómetros"},
    {prodId:"41115307",label:"Láseres"},
    {prodId:"41115308",label:"Medidores de luz"},
    {prodId:"41115309",label:"Luxómetros"},
    {prodId:"41115310",label:"Set de calibración óptica"},
    {prodId:"41115311",label:"Fotómetros"},
    {prodId:"41115312",label:"Polarímetros o refractómetros de mesa"},
    {prodId:"41115313",label:"Polarímetros o refractómetros manuales"},
    {prodId:"41115314",label:"Polarímetros"},
    {prodId:"41115315",label:"Polariscopios"},
    {prodId:"41115316",label:"Reflectómetros"},
    {prodId:"41115317",label:"Estroboscopios"},
    {prodId:"41115318",label:"Colorímetros"},
    {prodId:"41115319",label:"Lectores de tubo o disco"},
    {prodId:"41115320",label:"Generadores de señales"},
    {prodId:"41115321",label:"Imágenes infrarrojas"},
    {prodId:"41115322",label:"Analizadores de rayos láser"},
    {prodId:"41115323",label:"Generador de funciones"},
    {prodId:"41115324",label:"Sensor ultravioleta"},
    {prodId:"41115325",label:"Generador de imágenes térmico"},
    {prodId:"41115326",label:"Colimador óptico"},
    {prodId:"41115327",label:"Equipo de medición foto eléctrica"},
    {prodId:"41115328",label:"Sintetizador de forma de onda"},
    {prodId:"41115329",label:"Equipo de medición de propagación de radioonda"},
    {prodId:"41115330",label:"Medidor de opacidad"},
    {prodId:"41115331",label:"Instrumento medidor de brillo"},
    {prodId:"41115332",label:"Medidor de largo de onda óptica"},
    {prodId:"41115333",label:"Instrumento medidor de fotones"},
    {prodId:"41115334",label:"Probador de blancura"},
    {prodId:"41115335",label:"Vectorscopio"},
    {prodId:"41115336",label:"Lámpara de rayos ultravioleta para laboratorio"},
    {prodId:"41115337",label:"Instrumento de medición de ondas"},
    {prodId:"41115338",label:"Medidor de iluminancia"},
    {prodId:"41115339",label:"Elipsómetro"},
    {prodId:"41115400",label:"Equipo espectroscópico"},
    {prodId:"41115401",label:"Espectrofluorímetros o fluorímetros"},
    {prodId:"41115402",label:"Espectrógrafos"},
    {prodId:"41115403",label:"Espectrómetros"},
    {prodId:"41115404",label:"Espectrómetros de masa"},
    {prodId:"41115405",label:"Espectrómetros de protón"},
    {prodId:"41115406",label:"Espectrofotómetros"},
    {prodId:"41115407",label:"Espectrómetros de absorción atómica aa"},
    {prodId:"41115408",label:"Espectrómetros infrarrojos"},
    {prodId:"41115409",label:"Espectrómetros de resonancia magnética nuclear nmr"},
    {prodId:"41115411",label:"Espectrómetros de plasma acoplado inductivamente icp"},
    {prodId:"41115412",label:"Espectro bolómetro"},
    {prodId:"41115413",label:"Citómetro de flujo"},
    {prodId:"41115414",label:"Sistema de espectroscopia de rayo radio"},
    {prodId:"41115415",label:"Sistema de espectroscopio de electrones para análisis químico"},
    {prodId:"41115416",label:"Espectrómetro de resonancia de giro de electrones"},
    {prodId:"41115500",label:"Equipo de generación y medición de sonido"},
    {prodId:"41115501",label:"Sonares"},
    {prodId:"41115502",label:"Sonómetros"},
    {prodId:"41115503",label:"Aparatos para medir el sonido o medidores de decibeles"},
    {prodId:"41115507",label:"Analizador de voz"},
    {prodId:"41115508",label:"Detector de sonido"},
    {prodId:"41115509",label:"Detector de cables y tubos subterráneos"},
    {prodId:"41115510",label:"Detector de fuga de tubos de agua"},
    {prodId:"41115511",label:"Probador de audífonos"},
    {prodId:"41115512",label:"Emisor de onda acústica"},
    {prodId:"41115513",label:"Generador acústico"},
    {prodId:"41115514",label:"Sonoboya"},
    {prodId:"41115515",label:"Hidrófono"},
    {prodId:"41115600",label:"Instrumentos y accesorios de medición electroquímica"},
    {prodId:"41115601",label:"Equipo de análisis volumétrico karl fischer"},
    {prodId:"41115602",label:"Equipo de análisis volumétrico"},
    {prodId:"41115603",label:"Medidores de ph"},
    {prodId:"41115604",label:"Electrodos de ph"},
    {prodId:"41115606",label:"Medidores de electrodos selectivos de iones ise"},
    {prodId:"41115607",label:"Tiras de prueba de iones selectivos"},
    {prodId:"41115608",label:"Electrodos selectivos de iones"},
    {prodId:"41115609",label:"Medidores de conductividad"},
    {prodId:"41115610",label:"Celdas de conductividad"},
    {prodId:"41115611",label:"Medidores de oxígeno disuelto"},
    {prodId:"41115612",label:"Sondas de oxigeno disuelto"},
    {prodId:"41115613",label:"Medidor de salinidad"},
    {prodId:"41115614",label:"Transmisores de ph"},
    {prodId:"41115615",label:"Controlador de ph"},
    {prodId:"41115700",label:"Instrumentos y accesorios de medición cromatográfica"},
    {prodId:"41115701",label:"Detectores cromatográficos"},
    {prodId:"41115702",label:"Escáneres cromatográficos"},
    {prodId:"41115703",label:"Gases cromatográficos"},
    {prodId:"41115704",label:"Iones cromatográficos"},
    {prodId:"41115705",label:"Cromatógrafos líquidos"},
    {prodId:"41115706",label:"Cromatógrafos de capa delgada"},
    {prodId:"41115707",label:"Cromatógrafos para cromatografía de líquido de alta presión"},
    {prodId:"41115708",label:"Cromatógrafos de capa delgada de alta presión tlc"},
    {prodId:"41115709",label:"Columnas para cromatografía líquida de alta presión hplc"},
    {prodId:"41115710",label:"Columnas para cromatografía de gas"},
    {prodId:"41115711",label:"Columnas para cromatografía líquida lc"},
    {prodId:"41115712",label:"Columnas para extracción de fase sólida spe"},
    {prodId:"41115713",label:"Tanques para cromatografía de capa delgada"},
    {prodId:"41115714",label:"Auto muestreadores"},
    {prodId:"41115715",label:"Inyectores"},
    {prodId:"41115716",label:"Accesorios para cromatografía líquida"},
    {prodId:"41115717",label:"Accesorios para cromatografía de gas"},
    {prodId:"41115718",label:"Inyector de paredes de membrana"},
    {prodId:"41115719",label:"Revestimientos para cromatografía de gas"},
    {prodId:"41115720",label:"Tubos para cromatografía"},
    {prodId:"41115721",label:"Sistema de cromatografía líquida de proteínas rápidas fplc"},
    {prodId:"41115722",label:"Columna para cromatografía líquida de proteínas rápidas fplc"},
    {prodId:"41115723",label:"Cromatógrafo de fluido súper crítico"},
    {prodId:"41115800",label:"Analizadores, accesorios y suministros clínicos y diagnósticos"},
    {prodId:"41115801",label:"Analizadores de amino ácidos"},
    {prodId:"41115802",label:"Accesorios o suministros para analizadores de amino ácidos"},
    {prodId:"41115803",label:"Analizadores de bancos de sangre"},
    {prodId:"41115804",label:"Accesorios o suministros para analizadores de bancos de sangre"},
    {prodId:"41115805",label:"Analizadores de gas en la sangre"},
    {prodId:"41115806",label:"Accesorios o suministros para analizadores de gas en la sangre"},
    {prodId:"41115807",label:"Analizadores químicos"},
    {prodId:"41115808",label:"Accesorios o suministros para analizadores químico"},
    {prodId:"41115809",label:"Analizadores de coagulación"},
    {prodId:"41115810",label:"Accesorios o suministros para analizadores de coagulación"},
    {prodId:"41115811",label:"Analizadores de secuencia desoxirribonucleica"},
    {prodId:"41115812",label:"Accesorios o suministros para analizadores de secuencia desoxirribonucleica"},
    {prodId:"41115813",label:"Analizadores de toxicología"},
    {prodId:"41115814",label:"Accesorios o suministros para analizadores de toxicología"},
    {prodId:"41115815",label:"Analizadores de hematología"},
    {prodId:"41115816",label:"Accesorios o suministros para analizadores de hematología"},
    {prodId:"41115817",label:"Analizadores de histología"},
    {prodId:"41115818",label:"Accesorios o suministros para analizadores de histología"},
    {prodId:"41115819",label:"Analizadores de inmunología"},
    {prodId:"41115820",label:"Accesorios o suministros para analizadores de inmunología"},
    {prodId:"41115821",label:"Analizadores de microbiología"},
    {prodId:"41115822",label:"Accesorios o suministros para analizadores de microbiología"},
    {prodId:"41115823",label:"Analizadores de proteína"},
    {prodId:"41115824",label:"Accesorios o suministros para analizadores de proteína"},
    {prodId:"41115825",label:"Analizadores radio isotópicos"},
    {prodId:"41115826",label:"Accesorios o suministros para analizadores radio isotópicos"},
    {prodId:"41115827",label:"Analizadores de orina"},
    {prodId:"41115828",label:"Accesorios o suministros para analizadores de orina"},
    {prodId:"41115829",label:"Analizadores de productos cárnicos o lácteos"},
    {prodId:"41115830",label:"Analizadores de glucosa"},
    {prodId:"41115831",label:"Estante o revistero para la manipulación de micro placas"},
    {prodId:"41115832",label:"Equipo para detección de antibióticos"},
    {prodId:"41115833",label:"Analizador de piel"},
    {prodId:"41115834",label:"Accesorios o suministros para citómetro de flujo"},
    {prodId:"41115835",label:"Contador automático de androginia y fertilidad"},
    {prodId:"41115836",label:"Accesorios o suministros para contador automático de androginia y fertilidad"},
    {prodId:"41115837",label:"Contador y medidor de tamaño de partículas"},
    {prodId:"41115838",label:"Accesorios o suministros para contador y medidor de tamaño de partículas"},
    {prodId:"41115839",label:"Analizador de unidad procesadora de aféresis de banco de sangre y donantes"},
    {prodId:"41115840",label:"Accesorios o suministros para analizador de unidad procesadora de aféresis de banco de sangre y donantes"},
    {prodId:"41116000",label:"Reactivos de analizadores clínicos y diagnósticos"},
    {prodId:"41116001",label:"Reactivos analizadores de amino ácidos"},
    {prodId:"41116002",label:"Reactivos analizadores de bancos de sangre"},
    {prodId:"41116003",label:"Reactivos analizadores de gas en la sangre"},
    {prodId:"41116004",label:"Reactivos analizadores de química"},
    {prodId:"41116005",label:"Reactivos analizadores de coagulación"},
    {prodId:"41116006",label:"Reactivos analizadores de secuencia de ácido desoxirribonucleico dna"},
    {prodId:"41116007",label:"Reactivos analizadores de toxicología"},
    {prodId:"41116008",label:"Reactivos analizadores de hematología"},
    {prodId:"41116009",label:"Reactivos analizadores de histología"},
    {prodId:"41116010",label:"Reactivos analizadores de inmunología"},
    {prodId:"41116011",label:"Reactivos analizadores de microbiología"},
    {prodId:"41116012",label:"Reactivos analizadores de proteínas"},
    {prodId:"41116013",label:"Reactivos analizadores radio isotópicos"},
    {prodId:"41116014",label:"Reactivos analizadores de análisis de orina"},
    {prodId:"41116015",label:"Reactivos o anticuerpos analizadores de citometría de flujo"},
    {prodId:"41116016",label:"Reactivo analizador de electrolitos"},
    {prodId:"41116017",label:"Reactivo contador automático de androginia y fertilidad"},
    {prodId:"41116018",label:"Reactivo analizador contador y medidor de tamaño de partículas"},
    {prodId:"41116100",label:"Kits de ensayos manuales, controles de calidad, calibradores y normativas"},
    {prodId:"41116101",label:"Kits o suministros de prueba de bancos de sangre"},
    {prodId:"41116102",label:"Reactivos o soluciones de bancos de sangre"},
    {prodId:"41116103",label:"Controles de calidad o calibradores o estándares de bancos de sangre"},
    {prodId:"41116104",label:"Kits o suministros para pruebas químicas"},
    {prodId:"41116105",label:"Reactivos o soluciones químicas"},
    {prodId:"41116106",label:"Tiras de prueba o papel de prueba químico"},
    {prodId:"41116107",label:"Controles de calidad o calibradores o estándares químicos"},
    {prodId:"41116108",label:"Kits o suministros para pruebas de coagulación"},
    {prodId:"41116109",label:"Reactivos o soluciones de coagulación"},
    {prodId:"41116110",label:"Controles de calidad o calibradores o estándares de coagulación"},
    {prodId:"41116111",label:"Kits o suministros para pruebas de citología"},
    {prodId:"41116112",label:"Controles de calidad o calibradores o estándares de citología"},
    {prodId:"41116113",label:"Reactivos o soluciones o tinturas para citología"},
    {prodId:"41116116",label:"Kits o suministros para pruebas ambientales"},
    {prodId:"41116117",label:"Reactivos o soluciones o tinturas ambientales"},
    {prodId:"41116118",label:"Kits o suministros para pruebas de alimentos"},
    {prodId:"41116119",label:"Reactivos o soluciones o tinturas para kits de pruebas de alimentos"},
    {prodId:"41116120",label:"Kits o suministros para pruebas de hematología"},
    {prodId:"41116121",label:"Reactivos o soluciones o tinturas para hematología"},
    {prodId:"41116122",label:"Controles de calidad o calibradores o estándares para hematología"},
    {prodId:"41116123",label:"Kits o suministros para pruebas de histología"},
    {prodId:"41116124",label:"Reactivos o soluciones o tinturas para histología"},
    {prodId:"41116125",label:"Controles de calidad o calibradores o estándares para histología"},
    {prodId:"41116126",label:"Kits o suministros para pruebas de inmunología o serología"},
    {prodId:"41116127",label:"Reactivos o soluciones o tinturas para inmunología o serología"},
    {prodId:"41116128",label:"Controles de calidad o calibradores o estándares para inmunología o serología"},
    {prodId:"41116129",label:"Kits o suministros para pruebas de microbiología o bacteriología"},
    {prodId:"41116130",label:"Reactivos o soluciones o tinturas para microbiología o bacteriología"},
    {prodId:"41116131",label:"Discos o paneles de identificación o sensibilidad para microbiología o bacteriología"},
    {prodId:"41116132",label:"Controles de calidad o calibradores o estándares para microbiología o bacteriología"},
    {prodId:"41116133",label:"Kits o suministros para pruebas de biología molecular"},
    {prodId:"41116134",label:"Reactivos o soluciones o tinturas para biología molecular"},
    {prodId:"41116135",label:"Controles de calidad o calibradores o estándares para biología molecular"},
    {prodId:"41116136",label:"Kits o suministros para análisis de orina"},
    {prodId:"41116137",label:"Reactivos o soluciones o tinturas para análisis de orina"},
    {prodId:"41116138",label:"Tiras para análisis de orina"},
    {prodId:"41116139",label:"Controles de calidad o calibradores o estándares de análisis de orina"},
    {prodId:"41116141",label:"Reactivos o soluciones o tinturas para parasitología o micología"},
    {prodId:"41116142",label:"Medio para parasitología o micología"},
    {prodId:"41116143",label:"Controles de calidad o calibradores o estándares de parasitología o micología"},
    {prodId:"41116144",label:"Kits o suministros para pruebas de virología"},
    {prodId:"41116145",label:"Controles de calidad o calibradores o estándares para virología"},
    {prodId:"41116146",label:"Kits o suministros para pruebas de toxicología"},
    {prodId:"41116147",label:"Controles de calidad o calibradores o estándares para toxicología"},
    {prodId:"41116148",label:"Kits o suministros para pruebas de citometría de flujo"},
    {prodId:"41116150",label:"Kits o suministros para pruebas de androgénesis y fertilidad"},
    {prodId:"41116152",label:"Controles de calidad o calibradores o estándares para citometría de flujo"},
    {prodId:"41116153",label:"Controles de calidad o calibradores o estándares para inmunohistoquímica"},
    {prodId:"41116154",label:"Controles de calidad o calibradores o estándares para contar y determinar el tamaño de las partículas"},
    {prodId:"41116155",label:"Medio de crecimiento de cultura de células y biología molecular"},
    {prodId:"41116156",label:"Reactivos o soluciones o tinturas de virología"},
    {prodId:"41116157",label:"Medio de cultura de tejidos y células de virología"},
    {prodId:"41116200",label:"Suministros y equipo para lugares de tratamiento de pacientes"},
    {prodId:"41116201",label:"Monitores o medidores de glucosa"},
    {prodId:"41116202",label:"Monitores o medidores de colesterol"},
    {prodId:"41116203",label:"Accesorios para monitores o medidores"},
    {prodId:"41116205",label:"Kits de pruebas rápidas"},
    {prodId:"41116206",label:"Bebidas de diagnóstico para pruebas de laboratorio"},
    {prodId:"41116207",label:"Monitores o medidores de hemoglobina o hematocritos"},
    {prodId:"41116208",label:"Monitores o medidores de parámetros múltiples de química sanguínea"},
    {prodId:"41116209",label:"Monitores o medidores de múltiples parámetros de coagulación de la sangre"},
    {prodId:"41116210",label:"Monitores o medidores analizadores de aliento de desorden metabólico"},
    {prodId:"41116211",label:"Analizador de aliento de etanol"},
    {prodId:"41116212",label:"Controles de calidad o calibradores o estándares para monitores o medidores"},
    {prodId:"41116400",label:"Instrumentos de medición de la aceleración y la vibración"},
    {prodId:"41116401",label:"Martillos de impacto"},
    {prodId:"41116500",label:"Piezas y accesorios de instrumentos"},
    {prodId:"41116501",label:"Diales o kits de diales de medición"},
    {prodId:"41116502",label:"Clip eléctrico"},
    {prodId:"41121500",label:"Equipo y suministros de pipetas y manipulación de líquidos"},
    {prodId:"41121501",label:"Sistemas de manipulación líquida automática o robótica"},
    {prodId:"41121502",label:"Diluidores de laboratorio"},
    {prodId:"41121503",label:"Pipeta de desplazamiento de aire multicanal manuales"},
    {prodId:"41121504",label:"Pipeta de desplazamiento de aire de un solo canal manuales"},
    {prodId:"41121505",label:"Pipeta de desplazamiento positivo de un solo canal manuales"},
    {prodId:"41121506",label:"Pipeta repetidoras de un solo canal manuales"},
    {prodId:"41121507",label:"Pipeta de un solo canal electrónicas"},
    {prodId:"41121508",label:"Pipeta multicanales electrónicas"},
    {prodId:"41121509",label:"Pipetas pasteur o de transferencia"},
    {prodId:"41121510",label:"Pipetas volumétricas"},
    {prodId:"41121511",label:"Pipetas serológicas"},
    {prodId:"41121513",label:"Pipetas de caída"},
    {prodId:"41121514",label:"Bombas de pipetas"},
    {prodId:"41121515",label:"Bombillos de pipetas"},
    {prodId:"41121516",label:"Dispensadores de tapas de botella"},
    {prodId:"41121517",label:"Insertos o accesorios para pipeteras"},
    {prodId:"41121600",label:"Puntas de pipeta"},
    {prodId:"41121601",label:"Filtro de boquilla de pipeta"},
    {prodId:"41121602",label:"Puntas de pipeta de barrera de aerosol"},
    {prodId:"41121603",label:"Puntas de pipeta de baja retención"},
    {prodId:"41121604",label:"Puntas de pipeta de referencia"},
    {prodId:"41121605",label:"Puntas de pipeta ultra micro"},
    {prodId:"41121606",label:"Puntas de pipeta de carga de gel"},
    {prodId:"41121607",label:"Puntas de pipeta universales"},
    {prodId:"41121608",label:"Puntas de pipeta robóticas"},
    {prodId:"41121609",label:"Puntas de pipeta de volumen variable"},
    {prodId:"41121700",label:"Tubos de ensayo"},
    {prodId:"41121701",label:"Tubos de ensayo general o multipropósito"},
    {prodId:"41121702",label:"Tubos micro centrífugos"},
    {prodId:"41121703",label:"Tubos centrífugos"},
    {prodId:"41121704",label:"Tubos criogénicos"},
    {prodId:"41121705",label:"Tubos de resonancia magnética nuclear nmr"},
    {prodId:"41121706",label:"Tubos de cultivo"},
    {prodId:"41121707",label:"Tubos de pruebas de separador"},
    {prodId:"41121708",label:"Tubos de pruebas de anti coagulación"},
    {prodId:"41121709",label:"Tubos capilares o hematocritos"},
    {prodId:"41121710",label:"Cierres o tapas para tubos de ensayo"},
    {prodId:"41121711",label:"Tubos o accesorios para pruebas de análisis de orina"},
    {prodId:"41121712",label:"Tubos viscosímetros"},
    {prodId:"41121713",label:"Analizador de tamaño de arena"},
    {prodId:"41121800",label:"Artículos de vidrio o plástico y suministros generales de laboratorio"},
    {prodId:"41121801",label:"Vasos de observación para laboratorio"},
    {prodId:"41121802",label:"Varillas para revolver para laboratorio"},
    {prodId:"41121803",label:"Vasos de precipitados para laboratorio"},
    {prodId:"41121804",label:"Redomas para laboratorio"},
    {prodId:"41121805",label:"Cilindros graduados para laboratorio"},
    {prodId:"41121806",label:"Frascos para laboratorio"},
    {prodId:"41121807",label:"Ampollas para laboratorio"},
    {prodId:"41121808",label:"Buretas para laboratorio"},
    {prodId:"41121809",label:"Embudos para laboratorio"},
    {prodId:"41121810",label:"Platos o frascos para tintura para laboratorio"},
    {prodId:"41121811",label:"Kits de micro química para laboratorio"},
    {prodId:"41121812",label:"Platos para laboratorio"},
    {prodId:"41121813",label:"Cubetas"},
    {prodId:"41121814",label:"Tapas o forros o forros deslizantes para laboratorio"},
    {prodId:"41121815",label:"Adaptadores o conectores o instalaciones para laboratorio"},
    {prodId:"41121816",label:"Tubo de vidrio para laboratorio"},
    {prodId:"41121818",label:"Botella de almacenamiento para laboratorio"},
    {prodId:"41121819",label:"Botella de gotero y tapa de gotero"},
    {prodId:"41122000",label:"Jeringuillas de laboratorio o de muestreo"},
    {prodId:"41122001",label:"Jeringas de cromatografía"},
    {prodId:"41122002",label:"Agujas para jeringas de cromatografía"},
    {prodId:"41122003",label:"Adaptadores o accesorios para jeringa"},
    {prodId:"41122004",label:"Jeringas para muestras"},
    {prodId:"41122100",label:"Suministros de cultivo de tejido y cribado de alta definición"},
    {prodId:"41122101",label:"Platos o placas petri"},
    {prodId:"41122102",label:"Platos multi pocillo"},
    {prodId:"41122103",label:"Espátulas de células"},
    {prodId:"41122104",label:"Frascos de cultivo de tejidos"},
    {prodId:"41122105",label:"Botellas de cultivo rotatorias"},
    {prodId:"41122106",label:"Dispositivos de inoculación"},
    {prodId:"41122107",label:"Platos o placas o insertos recubiertos para cultivo de tejidos"},
    {prodId:"41122108",label:"Presillos o agujas para inoculación microbiológica"},
    {prodId:"41122109",label:"Almohadilla de petri"},
    {prodId:"41122110",label:"Dispensador de almohadillas de petri"},
    {prodId:"41122200",label:"Crisoles"},
    {prodId:"41122201",label:"Crisoles de vidrio"},
    {prodId:"41122202",label:"Crisoles cerámicos"},
    {prodId:"41122203",label:"Crisoles de metal"},
    {prodId:"41122400",label:"Instrumentos de laboratorio"},
    {prodId:"41122401",label:"Barras giratorias, barras para revolver o gotas para revolver magnéticas"},
    {prodId:"41122402",label:"Recuperadores magnéticos de barras giratorias o recuperadores de barras giratorias"},
    {prodId:"41122403",label:"Espátulas para laboratorio"},
    {prodId:"41122404",label:"Tenazas para laboratorio"},
    {prodId:"41122405",label:"Fórceps para laboratorio"},
    {prodId:"41122406",label:"Cuchillos para laboratorio"},
    {prodId:"41122407",label:"Escalpelos para laboratorio"},
    {prodId:"41122409",label:"Herramientas para laboratorio"},
    {prodId:"41122410",label:"Película sellante para laboratorio"},
    {prodId:"41122411",label:"Cronómetros o relojes para laboratorio"},
    {prodId:"41122412",label:"Sellantes de tubos para laboratorio"},
    {prodId:"41122413",label:"Pinzas para laboratorio"},
    {prodId:"41122414",label:"Cuchara para laboratorio"},
    {prodId:"41122415",label:"Cristales de bioseparación centrífuga"},
    {prodId:"41122500",label:"Corchos, tapones y accesorios de laboratorio"},
    {prodId:"41122501",label:"Corchos para laboratorio"},
    {prodId:"41122502",label:"Tapones para laboratorio"},
    {prodId:"41122503",label:"Porta corchos para laboratorio"},
    {prodId:"41122600",label:"Portaobjetos de laboratorio y suministros"},
    {prodId:"41122601",label:"Portaobjetos para microscopios"},
    {prodId:"41122602",label:"Portaobjetos de microscopio"},
    {prodId:"41122603",label:"Papel de lentes para microscopio"},
    {prodId:"41122604",label:"Hemocitómetros"},
    {prodId:"41122605",label:"Aceite de inmersión para microscopios"},
    {prodId:"41122606",label:"Dispensadores de portaobjetos para microscopio"},
    {prodId:"41122607",label:"Solución limpiadora de lentes para microscopio"},
    {prodId:"41122700",label:"Cintas y etiquetas de laboratorio"},
    {prodId:"41122701",label:"Etiquetas para portaobjetos o especímenes"},
    {prodId:"41122702",label:"Cintas etiquetadoras"},
    {prodId:"41122703",label:"Cintas de seguridad"},
    {prodId:"41122704",label:"Cintas contra alteración"},
    {prodId:"41122800",label:"Estantes, soportes y bandejas de laboratorio"},
    {prodId:"41122801",label:"Estantes o soportes para pipetas"},
    {prodId:"41122802",label:"Estantes para portaobjetos de microscopios"},
    {prodId:"41122803",label:"Estantes o soportes para tubos para sedimentación"},
    {prodId:"41122804",label:"Estantes para tubos de ensayo"},
    {prodId:"41122805",label:"Estantes para secado"},
    {prodId:"41122806",label:"Estantes para unidades de criopreservación"},
    {prodId:"41122807",label:"Bandejas para disección"},
    {prodId:"41122808",label:"Bandejas para propósitos generales"},
    {prodId:"41122809",label:"Estantes para placas petri"},
    {prodId:"41122810",label:"Estantes de almacenamiento de reactivos para la mesa de trabajo"},
    {prodId:"41122811",label:"Dispensador de tubos de cultivo y prueba"},
    {prodId:"41123000",label:"Desecadores y desecantes de laboratorio"},
    {prodId:"41123001",label:"Deshidratadores de frasco"},
    {prodId:"41123002",label:"Deshidratadores de gabinete"},
    {prodId:"41123003",label:"Desecantes"},
    {prodId:"41123004",label:"Deshidratadores de vacío"},
    {prodId:"41123005",label:"Contenedor de almacenamiento de disco desecante microbiológicamente susceptible"},
    {prodId:"41123300",label:"Contenedores y armarios de almacenamiento general para laboratorio"},
    {prodId:"41123302",label:"Cajas o folders para portaobjetos para microscopios"},
    {prodId:"41123303",label:"Gabinetes para portaobjetos para microscopios"},
    {prodId:"41123304",label:"Cajas de almacenamiento criogénicas"},
    {prodId:"41123305",label:"Gabinetes para casetes de tejidos o histología"},
    {prodId:"41123306",label:"Accesorios para casetes de tejidos o histología"},
    {prodId:"41123400",label:"Dispositivos de dosificación"},
    {prodId:"41123401",label:"Tazas dosificadoras"},
    {prodId:"41123402",label:"Cucharas dosificadoras"},
    {prodId:"41123403",label:"Goteros dosificadores"},
    {prodId:"42121500",label:"Equipo veterinario"},
    {prodId:"42121501",label:"Probadores de presión sanguínea para uso veterinario"},
    {prodId:"42121502",label:"Probador quimiógrafo para uso veterinario"},
    {prodId:"42121503",label:"Probador pirogénico para uso veterinario"},
    {prodId:"42121504",label:"Equipo estereotóxico para uso veterinario"},
    {prodId:"42121505",label:"Electrocardiógrafo ecg para uso veterinario"},
    {prodId:"42121506",label:"Sets de instrumentos quirúrgicos para uso veterinario"},
    {prodId:"42121507",label:"Unidades o accesorios de inyección o succión para uso veterinario"},
    {prodId:"42121508",label:"Sets de botellas para uso veterinario"},
    {prodId:"42121509",label:"Termómetros clínicos para uso veterinario"},
    {prodId:"42121510",label:"Cajas de instrumentos o accesorios para uso veterinario"},
    {prodId:"42121511",label:"Estuches enrollables para instrumentos o accesorios de uso veterinario"},
    {prodId:"42121512",label:"Espéculos para uso veterinario"},
    {prodId:"42121513",label:"Instrumentos de castración para uso veterinario"},
    {prodId:"42121514",label:"Kits de fijación externa para uso veterinario"},
    {prodId:"42121515",label:"Limas o cortaúñas para uso veterinario"},
    {prodId:"42121600",label:"Productos veterinarios"},
    {prodId:"42121601",label:"Productos gastrointestinales para uso veterinario"},
    {prodId:"42121602",label:"Productos para sangre o formación de sangre para uso veterinario"},
    {prodId:"42121603",label:"Productos para el sistema respiratorio para uso veterinario"},
    {prodId:"42121604",label:"Productos para el sistema musculo esquelético o nervioso para uso veterinario"},
    {prodId:"42121605",label:"Productos para el sistema cardiovascular para uso veterinario"},
    {prodId:"42121606",label:"Productos dermatológicos o anti protozoarios para uso veterinario"},
    {prodId:"42121607",label:"Productos para el sistema sexual genital urinario u hormonales para uso veterinario"},
    {prodId:"42121608",label:"Productos dentales para uso veterinario"},
    {prodId:"42121700",label:"Mobiliario clínico veterinario"},
    {prodId:"42121701",label:"Tablas quirúrgicas para uso veterinario"},
    {prodId:"42121702",label:"Armarios de almacenamiento para uso veterinario"},
    {prodId:"42121800",label:"Equipo y suministros para inseminación artificial veterinaria"},
    {prodId:"42121801",label:"Máquina para inseminación artificial"},
    {prodId:"42121802",label:"Instrumento para recolección de semen"},
    {prodId:"42121803",label:"Estimulador de eyaculación eléctrico"},
    {prodId:"42121804",label:"Instrumento para empaquetar semen"},
    {prodId:"42131500",label:"Vestuario para pacientes"},
    {prodId:"42131501",label:"Petos para pacientes"},
    {prodId:"42131502",label:"Gorras para pacientes"},
    {prodId:"42131503",label:"Capas de examen para pacientes"},
    {prodId:"42131504",label:"Batas para pacientes"},
    {prodId:"42131505",label:"Camisas o chalecos para pacientes bebés"},
    {prodId:"42131506",label:"Chaquetas para pacientes"},
    {prodId:"42131507",label:"Pantuflas para pacientes"},
    {prodId:"42131508",label:"Pijamas para pacientes"},
    {prodId:"42131509",label:"Batas de hospital"},
    {prodId:"42131510",label:"Pantalones para pacientes"},
    {prodId:"42131511",label:"Prenda para hidroterapia"},
    {prodId:"42131512",label:"Gorra o sombrero o material para gorra para paciente bebé"},
    {prodId:"42131600",label:"Vestuario para el personal sanitario y artículos relacionados"},
    {prodId:"42131601",label:"Delantales o petos para personal médico"},
    {prodId:"42131602",label:"Cobertores de barba para personal médico"},
    {prodId:"42131603",label:"Blusas o blusones para personal médico"},
    {prodId:"42131604",label:"Gorro de quirófano para personal médico"},
    {prodId:"42131605",label:"Overoles para personal médico"},
    {prodId:"42131606",label:"Máscaras quirúrgicas o de aislamiento para personal médico"},
    {prodId:"42131607",label:"Chaquetas o batas para personal médico"},
    {prodId:"42131608",label:"Vestidos para cirugía para personal médico"},
    {prodId:"42131609",label:"Cubiertas para zapatos para personal médico"},
    {prodId:"42131610",label:"Protección de mangas para personal médico"},
    {prodId:"42131611",label:"Gorros o capuchas para cirujano"},
    {prodId:"42131612",label:"Batas de aislamiento para personal médico"},
    {prodId:"42131613",label:"Protectores de ojos o visores para personal médico"},
    {prodId:"42131700",label:"Prendas textiles quirúrgicas"},
    {prodId:"42131701",label:"Cortinas de cirugía"},
    {prodId:"42131702",label:"Batas de cirugía"},
    {prodId:"42131704",label:"Toallas de cirugía"},
    {prodId:"42131705",label:"Pantalones (“leggings”) de cirugía"},
    {prodId:"42131706",label:"Vestidos enteros de cirugía"},
    {prodId:"42131707",label:"Vestidos o cascos o máscaras faciales o accesorios de aislamiento de cirugía"},
    {prodId:"42131708",label:"Paquete o kit para limpieza quirúrgica o rotación de salas quirúrgicas"},
    {prodId:"42132100",label:"Ropa blanca del hospital"},
    {prodId:"42132101",label:"Protectores de colchón o silla para hospital"},
    {prodId:"42132102",label:"Sábanas elásticas médicas"},
    {prodId:"42132103",label:"Cortinas de barrera para pacientes"},
    {prodId:"42132104",label:"Almohadas antimicrobianas para hospital"},
    {prodId:"42132105",label:"Sábanas para hospital"},
    {prodId:"42132106",label:"Colchas o cubrecamas para hospital"},
    {prodId:"42132107",label:"Cobijas para hospital"},
    {prodId:"42132108",label:"Fundas protectoras de almohada para hospital"},
    {prodId:"42132200",label:"Guantes y accesorios médicos"},
    {prodId:"42132201",label:"Cajas o dispensadores de guantes médicos"},
    {prodId:"42132202",label:"Protector de caucho para dedos."},
    {prodId:"42132203",label:"Guantes de examen o para procedimientos no quirúrgicos"},
    {prodId:"42132204",label:"Recubrimientos interiores para guantes médicos"},
    {prodId:"42132205",label:"Guantes de cirugía"},
    {prodId:"42141500",label:"Bolas de algodón y copitos aplicadores"},
    {prodId:"42141501",label:"Bolas o fibra de algodón"},
    {prodId:"42141502",label:"Palitos (copitos) con punta de fibra"},
    {prodId:"42141503",label:"Toallitas de preparación de la piel"},
    {prodId:"42141504",label:"Aplicadores o absorbentes medicados"},
    {prodId:"42141600",label:"Palanganas y Bacinillas de cama y orinales y equipos de ingreso"},
    {prodId:"42141601",label:"Kits de admisión para el cuidado del paciente"},
    {prodId:"42141602",label:"Patos (bacinillas) para uso general"},
    {prodId:"42141603",label:"Recipientes para emesis (vómito)"},
    {prodId:"42141605",label:"Recipientes o cuencos para soluciones o mezclas médicas"},
    {prodId:"42141606",label:"Recipientes multipropósito para usos médicos"},
    {prodId:"42141607",label:"Orinales de uso general para pacientes"},
    {prodId:"42141608",label:"Bolsas o recubrimientos para patos (bacinillas)"},
    {prodId:"42141700",label:"Productos para la prevención de decúbito"},
    {prodId:"42141701",label:"Sistemas de presión alterna"},
    {prodId:"42141702",label:"Marcos o elevadores de cobijas"},
    {prodId:"42141703",label:"Cunas para extremidades"},
    {prodId:"42141704",label:"Recubrimientos para colchones"},
    {prodId:"42141705",label:"Almohadones o almohadillas o almohadas para la posición del paciente"},
    {prodId:"42141800",label:"Equipo de electroterapia"},
    {prodId:"42141801",label:"Unidades de combinación de electroterapia"},
    {prodId:"42141802",label:"Electrodos o accesorios para electroterapia"},
    {prodId:"42141803",label:"Cables o alambres de plomo para electroterapia"},
    {prodId:"42141804",label:"Simuladores galvánicos o farádicos"},
    {prodId:"42141805",label:"Estimuladores o kits neuromusculares"},
    {prodId:"42141806",label:"Unidades de diatermia de onda corta"},
    {prodId:"42141807",label:"Unidades de estimulación nerviosa eléctrica transcutánea"},
    {prodId:"42141808",label:"Electrodos de diatermia"},
    {prodId:"42141809",label:"Sets de estimulador muscular"},
    {prodId:"42141810",label:"Electrodo de iontoforesis"},
    {prodId:"42141811",label:"Sistema de terapia de ondas de choque extracorpóreas"},
    {prodId:"42141812",label:"Generador de electricidad estática para estimulación eléctrica"},
    {prodId:"42141813",label:"Dispositivo electro convulsivo"},
    {prodId:"42141814",label:"Estimulador de electricidad estática"},
    {prodId:"42141815",label:"Estimulador de ondas electromagnéticas de alta frecuencia"},
    {prodId:"42141816",label:"Estimulador de terapia electromagnética"},
    {prodId:"42141817",label:"Estimulador de pulso magnético"},
    {prodId:"42141900",label:"Suministros para la administración de enema"},
    {prodId:"42141901",label:"Baldes para enema"},
    {prodId:"42141902",label:"Bolsas para enema"},
    {prodId:"42141903",label:"Kits o accesorios para enema"},
    {prodId:"42141904",label:"Tubos o tapas o pinzas para enema"},
    {prodId:"42141905",label:"Jabones para enema"},
    {prodId:"42142000",label:"Instrumentos de grado bajo floor grade"},
    {prodId:"42142001",label:"Fórceps o homeostatos de bajo grado"},
    {prodId:"42142002",label:"Cuchillo de bajo grado"},
    {prodId:"42142003",label:"Manijas para cuchillo de bajo grado"},
    {prodId:"42142004",label:"Corta uñas de bajo grado"},
    {prodId:"42142005",label:"Sujeta agujas de bajo grado"},
    {prodId:"42142006",label:"Retractores de bajo grado"},
    {prodId:"42142007",label:"Tijeras de bajo grado"},
    {prodId:"42142100",label:"Productos de terapia de frío y de calor"},
    {prodId:"42142101",label:"Cubiertas para productos para terapia de calor o frío"},
    {prodId:"42142102",label:"Unidades o accesorios de enfriamiento para almacenamiento frío para uso médico"},
    {prodId:"42142103",label:"Lámparas de calor o sus accesorios para uso médico"},
    {prodId:"42142104",label:"Hidro coladores o sus accesorios para uso médico"},
    {prodId:"42142105",label:"Unidades o sistemas de calentamiento o enfriamiento terapéutico"},
    {prodId:"42142106",label:"Cobijas o cortinas de calentamiento o enfriamiento terapéutico"},
    {prodId:"42142107",label:"Sistema y accesorios de terapia de compresión crio terapéutica"},
    {prodId:"42142108",label:"Almohadillas o compresas o bolsas de calentamiento o enfriamiento terapéutico"},
    {prodId:"42142109",label:"Guantes de terapia para terapia de calor o frío"},
    {prodId:"42142110",label:"Botellas para terapia de calor o frío"},
    {prodId:"42142111",label:"Bolsas o almohadas de hielo terapéuticas"},
    {prodId:"42142112",label:"Baños de parafina terapéuticos o sus accesorios"},
    {prodId:"42142113",label:"Máscaras para senos nasales terapéuticas"},
    {prodId:"42142114",label:"Pantalones terapéuticos para terapia fría o caliente"},
    {prodId:"42142119",label:"Aparatos de hipotermia"},
    {prodId:"42142120",label:"Manga terapéutica para terapia de crio compresión"},
    {prodId:"42142121",label:"Gabinete de vapor húmedo"},
    {prodId:"42142200",label:"Productos de hidroterapia"},
    {prodId:"42142201",label:"Baños o tanques de hidroterapia para las extremidades"},
    {prodId:"42142202",label:"Baños o tanques de hidroterapia para inmersión total del cuerpo"},
    {prodId:"42142203",label:"Accesorios para baños o tanques de hidroterapia"},
    {prodId:"42142204",label:"Asientos para baños de hidroterapia"},
    {prodId:"42142400",label:"Productos médicos de vacío o de succión"},
    {prodId:"42142401",label:"Solidificadores de fluidos para uso médico"},
    {prodId:"42142402",label:"Cánulas o tubos o accesorios de succión para uso médico"},
    {prodId:"42142403",label:"Contenedores de succión para uso médico"},
    {prodId:"42142404",label:"Aplicaciones al vacío o de succión para uso médico"},
    {prodId:"42142406",label:"Sets o kits de succión para uso médico"},
    {prodId:"42142407",label:"Estuches para cánulas de succión para uso médico"},
    {prodId:"42142500",label:"Agujas de inyección y aspiración y accesorios"},
    {prodId:"42142501",label:"Agujas para amniocentesis"},
    {prodId:"42142502",label:"Agujas para anestesia"},
    {prodId:"42142503",label:"Agujas arteriales"},
    {prodId:"42142504",label:"Agujas para biopsia"},
    {prodId:"42142505",label:"Soportes para agujas de recolección de sangre"},
    {prodId:"42142506",label:"Agujas romas"},
    {prodId:"42142507",label:"Agujas mariposa"},
    {prodId:"42142509",label:"Bandejas o accesorios epidurales"},
    {prodId:"42142510",label:"Agujas de filtro"},
    {prodId:"42142511",label:"Tapas o dispositivos protectores o accesorios para agujas"},
    {prodId:"42142512",label:"Agujas de fístula"},
    {prodId:"42142513",label:"Agujas para procedimientos de radiología"},
    {prodId:"42142514",label:"Bandejas o agujas espinales"},
    {prodId:"42142515",label:"Agujas de tubo al vacío"},
    {prodId:"42142516",label:"Agujas de transferencia"},
    {prodId:"42142517",label:"Tubos de extensión"},
    {prodId:"42142518",label:"Productos o accesorios aspiradores para biopsia"},
    {prodId:"42142519",label:"Guías para agujas"},
    {prodId:"42142520",label:"Alfileres dispensadores"},
    {prodId:"42142521",label:"Agujas para recolección de sangre"},
    {prodId:"42142522",label:"Agujas quirúrgicas para oídos o narices o gargantas"},
    {prodId:"42142523",label:"Agujas hipodérmicas"},
    {prodId:"42142524",label:"Agujas o sets para grabar"},
    {prodId:"42142525",label:"Agujas de irrigación"},
    {prodId:"42142526",label:"Protectores de agujas"},
    {prodId:"42142527",label:"Alambres para limpiar agujas"},
    {prodId:"42142528",label:"Agujas o sets para punción del esternón"},
    {prodId:"42142529",label:"Bandejas para agujas o porta agujas"},
    {prodId:"42142530",label:"Agujas para procedimientos diagnósticos"},
    {prodId:"42142531",label:"Contenedores o carritos o accesorios para desecho de agujas o cuchillas u otros objetos afilados"},
    {prodId:"42142532",label:"Agujas o kits o accesorios para pericardiocentesis"},
    {prodId:"42142533",label:"Agujas para administración de puertos intravenosos o arteriales"},
    {prodId:"42142534",label:"Aguja interósea"},
    {prodId:"42142535",label:"Aguja o bandeja para bloqueo nervioso anestésico"},
    {prodId:"42142536",label:"Aguja y catéter para toracentesis o paracentesis"},
    {prodId:"42142537",label:"Set o bandeja para toracentesis"},
    {prodId:"42142538",label:"Set o bandeja para paracentesis"},
    {prodId:"42142600",label:"Jeringas y accesorios"},
    {prodId:"42142601",label:"Jeringas para aspiración o irrigación médica"},
    {prodId:"42142602",label:"Peras de caucho para usos médicos"},
    {prodId:"42142603",label:"Jeringas de cartucho para uso médico"},
    {prodId:"42142604",label:"Jeringas de punta de catéter para uso médico"},
    {prodId:"42142605",label:"Jeringas para oídos para uso médico"},
    {prodId:"42142606",label:"Jeringas de entrega medida para uso médico"},
    {prodId:"42142607",label:"Micro jeringas para uso médico"},
    {prodId:"42142608",label:"Jeringas sin agujas para uso médico"},
    {prodId:"42142609",label:"Jeringas con agujas para uso médico"},
    {prodId:"42142610",label:"Jeringas de medicación líquida oral"},
    {prodId:"42142611",label:"Jeringas de tuberculina"},
    {prodId:"42142612",label:"Sets de jeringas de irrigación"},
    {prodId:"42142613",label:"Pistolas de inyección"},
    {prodId:"42142614",label:"Aparatos o accesorios para inyección hipodérmica"},
    {prodId:"42142615",label:"Accesorios para jeringas"},
    {prodId:"42142616",label:"Jeringas de recolección de sangre"},
    {prodId:"42142617",label:"Jeringas de fuente"},
    {prodId:"42142618",label:"Kits de jeringas de análisis de gas en la sangre"},
    {prodId:"42142619",label:"Jeringa de lavado pre llenada"},
    {prodId:"42142620",label:"Controlador o pistola de inyección de aguja interósea"},
    {prodId:"42142700",label:"Suministros urológicos"},
    {prodId:"42142701",label:"Catéteres urinarios supra púbicos"},
    {prodId:"42142702",label:"Catéteres urinarios uretrales"},
    {prodId:"42142703",label:"Tapones o pinzas para catéteres urinarios"},
    {prodId:"42142704",label:"Bolsas o medidores para drenaje urinario"},
    {prodId:"42142705",label:"Tiras o sujetadores para bolsas de drenaje urinario"},
    {prodId:"42142706",label:"Bandejas o paquetes o kits para procedimientos urológicos"},
    {prodId:"42142707",label:"Tubos de irrigación urinaria"},
    {prodId:"42142708",label:"Adaptadores para instrumentos de urología"},
    {prodId:"42142709",label:"Accesorios o sets para nefrostomía"},
    {prodId:"42142710",label:"Tubos o accesorios para drenaje urinario"},
    {prodId:"42142711",label:"Sets de auscultación uretral"},
    {prodId:"42142712",label:"Sets o accesorios de remoción de cálculos"},
    {prodId:"42142713",label:"Cubiertas o sets urológicos"},
    {prodId:"42142714",label:"Percoladores urológicos"},
    {prodId:"42142715",label:"Kits o accesorios de cateterización urológica"},
    {prodId:"42142716",label:"Dispositivo pesario"},
    {prodId:"42142717",label:"Catéter o drenaje para nefrostomía"},
    {prodId:"42142718",label:"Dispositivo de soporte y alargamiento del pene"},
    {prodId:"42142719",label:"Uretrotomo"},
    {prodId:"42142720",label:"Catéter urinario externo masculino"},
    {prodId:"42142721",label:"Alambre guía o alambre de deslizamiento urológico"},
    {prodId:"42142800",label:"Suministros y equipos para terapia vascular y de compresión"},
    {prodId:"42142801",label:"Dispositivos o tubos para compresión secuencial vascular"},
    {prodId:"42142802",label:"Prendas o soporte para compresión o vasculares"},
    {prodId:"42142900",label:"Corrección de la visión o gafas cosméticas y productos relacionados"},
    {prodId:"42142901",label:"Anteojos"},
    {prodId:"42142902",label:"Lentes para anteojos"},
    {prodId:"42142903",label:"Monturas para anteojos"},
    {prodId:"42142904",label:"Hardware para anteojos"},
    {prodId:"42142905",label:"Anteojos de sol"},
    {prodId:"42142906",label:"Estuches para anteojos"},
    {prodId:"42142907",label:"Pañitos para limpiar anteojos"},
    {prodId:"42142908",label:"Kits para limpiar anteojos"},
    {prodId:"42142909",label:"Retenedores de anteojos"},
    {prodId:"42142910",label:"Estuches para lentes de contacto"},
    {prodId:"42142911",label:"Insertores o removedores de lentes de contacto"},
    {prodId:"42142912",label:"Medidores de radio de lentes de contacto"},
    {prodId:"42142913",label:"Lentes de contacto"},
    {prodId:"42142914",label:"Solución limpiadora o humidificadora de lentes de contacto"},
    {prodId:"42143100",label:"Equipo y suministros de obstetricia y ginecología"},
    {prodId:"42143101",label:"Catéteres o kits de cateterización intrauterina"},
    {prodId:"42143102",label:"Dispositivos o accesorios uterinos"},
    {prodId:"42143103",label:"Dispositivos o accesorios contraceptivos intrauterinos"},
    {prodId:"42143104",label:"Kits o accesorios para amniocentesis"},
    {prodId:"42143105",label:"Unidades o accesorios de extracción obstétrica"},
    {prodId:"42143106",label:"Kits o accesorios de drenaje ginecológico"},
    {prodId:"42143107",label:"Perinómetro"},
    {prodId:"42143108",label:"Cámara de descompresión abdominal"},
    {prodId:"42143200",label:"Equipo y suministros de tratamiento de la fertilidad y la esterilidad"},
    {prodId:"42143201",label:"Kits o accesorios de preparación de semen"},
    {prodId:"42143202",label:"Productos o kits o accesorios para el tratamiento de la impotencia"},
    {prodId:"42143203",label:"Catéter de inseminación artificial"},
    {prodId:"42143204",label:"Catéter de transferencia de embriones"},
    {prodId:"42143400",label:"Equipo y suministros de control de hiperhidrosis"},
    {prodId:"42143401",label:"Equipos o accesorios para el control del sudor"},
    {prodId:"42143500",label:"Productos y accesorios de otorrinolaringología"},
    {prodId:"42143501",label:"Ayudas para la limpieza de moldes auditivos"},
    {prodId:"42143502",label:"Dispositivos o accesorios para irrigación nasal"},
    {prodId:"42143503",label:"Dispositivos o bombas para el control de la hemorragia nasal"},
    {prodId:"42143504",label:"Moldes auditivos o sus accesorios"},
    {prodId:"42143505",label:"Kits de reparación de moldes auditivos"},
    {prodId:"42143506",label:"Sets filiformes para la trompa de eustaquio"},
    {prodId:"42143507",label:"Paquetes de medicación de oídos"},
    {prodId:"42143508",label:"Capuchas de viento para oídos"},
    {prodId:"42143509",label:"Pegantes o cementos para moldes auditivos"},
    {prodId:"42143510",label:"Pantallas protectoras de viento para oídos"},
    {prodId:"42143511",label:"Ganchos de cera de oídos"},
    {prodId:"42143512",label:"Mechas para oídos"},
    {prodId:"42143513",label:"Instrumentos o accesorios otológicos"},
    {prodId:"42143514",label:"Sistema para lavado de oídos"},
    {prodId:"42143600",label:"Sujeciones y accesorios"},
    {prodId:"42143601",label:"Chalecos y chaquetas de fuerza (de restricción)"},
    {prodId:"42143602",label:"Elementos de restricción de cintura y torso"},
    {prodId:"42143603",label:"Elementos de restricción de extremidades"},
    {prodId:"42143604",label:"Elementos de restricción de cabeza no para ems"},
    {prodId:"42143605",label:"Correas o hebillas o accesorios o suministros de restricción"},
    {prodId:"42143606",label:"Elementos de restricción para todo el cuerpo"},
    {prodId:"42143607",label:"Sensores o alarmas o accesorios de movimiento de pacientes"},
    {prodId:"42143608",label:"Dispositivos o accesorios para estabilización o prevención de caídas de pacientes"},
    {prodId:"42143609",label:"Tablas o accesorios para restricción de pacientes"},
    {prodId:"42143700",label:"Sistemas y accesorios de fototerapia"},
    {prodId:"42143701",label:"Circuladores de aire o accesorios para fototerapia"},
    {prodId:"42143702",label:"Cobijas para fototerapia"},
    {prodId:"42143703",label:"Bombillos para fototerapia"},
    {prodId:"42143704",label:"Esteras de luz para fototerapia"},
    {prodId:"42143705",label:"Dispositivos de protección de pacientes para fototerapia"},
    {prodId:"42143706",label:"Unidades eléctricas para fototerapia"},
    {prodId:"42143707",label:"Calentadores de camas o accesorios para fototerapia"},
    {prodId:"42143708",label:"Chaleco para fototerapia"},
    {prodId:"42143709",label:"Radiador de luz visible"},
    {prodId:"42143900",label:"Productos para la incontinencia fecal"},
    {prodId:"42143901",label:"Bolsa o accesorios de recolección de incontinencia fecal"},
    {prodId:"42143902",label:"Sistema o kit de manejo fecal"},
    {prodId:"42143903",label:"Catéter de colon o tubo rectal"},
    {prodId:"42144000",label:"Partes y accesorios para dispositivos auditivos externos"},
    {prodId:"42144001",label:"Procesador de sonido externo para dispositivos auditivos implantados"},
    {prodId:"42144002",label:"Cable externo para dispositivos auditivos implantados"},
    {prodId:"42144003",label:"Audífonos procesadores de sonido externos para dispositivos auditivos implantados"},
    {prodId:"42144004",label:"Magneto externo para dispositivos auditivos implantados"},
    {prodId:"42144005",label:"Micrófono externo para dispositivos auditivos implantados"},
    {prodId:"42151500",label:"Suministros y equipos de odontología cosmética"},
    {prodId:"42151501",label:"Luces de curación o accesorios para odontología estética"},
    {prodId:"42151502",label:"Pozos mezcladores para odontología estética"},
    {prodId:"42151503",label:"Coronas o formas de coronas"},
    {prodId:"42151504",label:"Laca dental"},
    {prodId:"42151505",label:"Suministros de grabado de dientes"},
    {prodId:"42151506",label:"Suministros blanqueadores o decolorantes de dientes"},
    {prodId:"42151507",label:"Sombras dentales"},
    {prodId:"42151600",label:"Instrumentos y dispositivos dentales y de subespecialidad"},
    {prodId:"42151601",label:"Accesorios o partes de repuesto para instrumentos dentales"},
    {prodId:"42151602",label:"Bandas para matriz dental"},
    {prodId:"42151603",label:"Herramientas de colocación de hidróxido de calcio"},
    {prodId:"42151604",label:"Herramientas de colocación de compuestos"},
    {prodId:"42151605",label:"Removedores de coronas o puentes"},
    {prodId:"42151606",label:"Talladores de amalgamas dentales"},
    {prodId:"42151607",label:"Casetes para instrumentos dentales"},
    {prodId:"42151608",label:"Bandejas o cubetas para instrumentos dentales"},
    {prodId:"42151609",label:"Esterillas para instrumentos dentales"},
    {prodId:"42151610",label:"Alicates dentales"},
    {prodId:"42151611",label:"Cepillos operativos dentales"},
    {prodId:"42151612",label:"Retractores dentales"},
    {prodId:"42151613",label:"Pulidores dentales"},
    {prodId:"42151614",label:"Fresas dentales"},
    {prodId:"42151615",label:"Unidades crio quirúrgicas dentales"},
    {prodId:"42151616",label:"Medidores de profundidad dentales"},
    {prodId:"42151617",label:"Taladros o brocas dentales"},
    {prodId:"42151618",label:"Elevadores dentales"},
    {prodId:"42151619",label:"Excavadoras dentales"},
    {prodId:"42151620",label:"Limas o curetas dentales"},
    {prodId:"42151621",label:"Instrumentos para contorno de calzas dentales"},
    {prodId:"42151622",label:"Protectores de dedos para uso odontológico"},
    {prodId:"42151623",label:"Fórceps dentales"},
    {prodId:"42151624",label:"Piezas manuales o accesorios para uso odontológico"},
    {prodId:"42151625",label:"Instrumentos de higiene dental"},
    {prodId:"42151626",label:"Accesorios para afilar instrumentos dentales"},
    {prodId:"42151627",label:"Espejos o mangos de espejo para uso odontológico"},
    {prodId:"42151628",label:"Lozas mezcladoras para uso odontológico"},
    {prodId:"42151629",label:"Organizadores dentales"},
    {prodId:"42151630",label:"Instrumentos de colocación para uso odontológico"},
    {prodId:"42151631",label:"Sondas dentales"},
    {prodId:"42151632",label:"Escariadores dentales"},
    {prodId:"42151633",label:"Instrumentos de empaque de cordones de retracción para uso odontológico"},
    {prodId:"42151634",label:"Picos de punta de raíz para uso dental"},
    {prodId:"42151635",label:"Eyectores de saliva o dispositivos de succión oral o suministros dentales"},
    {prodId:"42151636",label:"Raspadores dentales o accesorios"},
    {prodId:"42151637",label:"Escalas dentales"},
    {prodId:"42151638",label:"Tijeras dentales"},
    {prodId:"42151639",label:"Espátulas dentales"},
    {prodId:"42151640",label:"Pinzas dentales"},
    {prodId:"42151641",label:"Grabadores de cera para uso odontológico"},
    {prodId:"42151642",label:"Cuchillos de gingivectomía"},
    {prodId:"42151643",label:"Cortadoras de margen dental"},
    {prodId:"42151644",label:"Soportes para la boca para uso odontológico"},
    {prodId:"42151645",label:"Kits o bandejas operativas reusables pre ensambladas para uso odontológico"},
    {prodId:"42151646",label:"Dispositivos protectores para dientes"},
    {prodId:"42151647",label:"Sujetadores absorbentes para uso odontológico"},
    {prodId:"42151648",label:"Calibradores para uso odontológico"},
    {prodId:"42151650",label:"Herramientas de detección de fracturas para uso odontológico"},
    {prodId:"42151651",label:"Separadores de dientes para uso odontológico"},
    {prodId:"42151652",label:"Introductor de espigas para uso odontológico"},
    {prodId:"42151653",label:"Doblador de espigas para uso odontológico"},
    {prodId:"42151654",label:"Guías para uso odontológico"},
    {prodId:"42151655",label:"Probadores de nervio o de vitalidad para uso odontológico"},
    {prodId:"42151656",label:"Esparcidores para uso odontológico"},
    {prodId:"42151657",label:"Tubos o accesorios para uso odontológico"},
    {prodId:"42151658",label:"Enhebradores de seda dental"},
    {prodId:"42151659",label:"Kit de extracción de partes de dientes"},
    {prodId:"42151660",label:"Aplicadores o absorbentes para uso odontológico"},
    {prodId:"42151661",label:"Estuches o bolsas para instrumentos dentales"},
    {prodId:"42151662",label:"Electrodos de anestesia o repuestos para uso odontológico"},
    {prodId:"42151663",label:"Cuñas o sets para uso odontológico"},
    {prodId:"42151664",label:"Discos cortadores o separadores para uso odontológico"},
    {prodId:"42151665",label:"Calibradores o accesorios para uso odontológico"},
    {prodId:"42151666",label:"Sujetadores bucales para uso odontológico"},
    {prodId:"42151667",label:"Matrices o sets para uso odontológico"},
    {prodId:"42151668",label:"Enrolladores de instrumentos para instrumentos o accesorios dentales"},
    {prodId:"42151669",label:"Deshidratadores para uso odontológico"},
    {prodId:"42151670",label:"Portadores de calor para uso odontológico"},
    {prodId:"42151671",label:"Calzas o kits de calzas para sistemas post dentales"},
    {prodId:"42151672",label:"Expansores para uso odontológico"},
    {prodId:"42151673",label:"Martillos para uso odontológico"},
    {prodId:"42151674",label:"Sujetadores de fresas para uso odontológico"},
    {prodId:"42151675",label:"Kits de restauración para uso odontológico"},
    {prodId:"42151676",label:"Cuchillos para uso odontológico"},
    {prodId:"42151677",label:"Kits indicadores de presión para uso odontológico"},
    {prodId:"42151678",label:"Sujetadores para almohadillas o láminas mezcladoras"},
    {prodId:"42151679",label:"Bolsas mezcladoras para uso odontológico"},
    {prodId:"42151680",label:"Topes o accesorios endodónticos"},
    {prodId:"42151681",label:"Sets o accesorios de anestesia para uso odontológico"},
    {prodId:"42151682",label:"Broca endodóntica"},
    {prodId:"42151683",label:"Arco facial"},
    {prodId:"42151700",label:"Muebles para la clínica dental"},
    {prodId:"42151701",label:"Sillas para examen dental o partes relacionadas o accesorios"},
    {prodId:"42151702",label:"Taburetes para uso odontológico"},
    {prodId:"42151703",label:"Gabinetes para uso odontológico"},
    {prodId:"42151704",label:"Mesas o accesorios para uso odontológico"},
    {prodId:"42151705",label:"Sets de mobiliarios combinados para procedimientos dentales"},
    {prodId:"42151800",label:"Rellenos y acabados dentales y suministros para pulir"},
    {prodId:"42151801",label:"Portadores de amalgamas"},
    {prodId:"42151802",label:"Obturadores o puntas o accesorios para uso odontológico"},
    {prodId:"42151803",label:"Cápsulas de amalgama para uso odontológico"},
    {prodId:"42151804",label:"Empaquetadores de cable para uso odontológico"},
    {prodId:"42151805",label:"Discos pulidores o de terminado para uso odontológico"},
    {prodId:"42151806",label:"Tiras pulidoras o de terminado para uso odontológico"},
    {prodId:"42151807",label:"Puntas pulidoras o de terminado para uso odontológico"},
    {prodId:"42151808",label:"Compuestos brilladores para uso odontológico"},
    {prodId:"42151809",label:"Paños comprimibles para uso odontológico"},
    {prodId:"42151810",label:"Kits pulidores o de terminado para uso odontológico"},
    {prodId:"42151811",label:"Copas o sets pulidores para uso odontológico"},
    {prodId:"42151812",label:"Resortes para abrasión o máquinas pulidoras para uso odontológico"},
    {prodId:"42151813",label:"Tubos de calzas para uso odontológico"},
    {prodId:"42151814",label:"Adaptadores de placas de sujeción para tallado o máquinas pulidoras para uso odontológico"},
    {prodId:"42151815",label:"Conos para tallado o máquinas pulidoras para uso odontológico"},
    {prodId:"42151816",label:"Portadores de lámina de oro"},
    {prodId:"42151900",label:"Equipo y suministros para higiene dental y cuidado preventivo"},
    {prodId:"42151901",label:"Cepillos para paladares o prótesis dentales"},
    {prodId:"42151902",label:"Kits de profilaxis para uso odontológico"},
    {prodId:"42151903",label:"Copas o contenedores para prótesis dentales"},
    {prodId:"42151904",label:"Soluciones o tabletas reveladoras"},
    {prodId:"42151905",label:"Geles o enjuagues de fluoruro"},
    {prodId:"42151906",label:"Tabletas o gotas de fluoruro"},
    {prodId:"42151907",label:"Tanques de profilaxis para uso odontológico"},
    {prodId:"42151908",label:"Frascos o sets para prótesis dentales"},
    {prodId:"42151909",label:"Pastas o kits de prevención dental"},
    {prodId:"42151910",label:"Dispositivos o accesorios para limpiar dientes"},
    {prodId:"42151911",label:"Sets acondicionadores de tejido dental"},
    {prodId:"42151912",label:"Aerosoles endodónticos"},
    {prodId:"42152000",label:"Equipo y suministros para hacer imágenes dentales"},
    {prodId:"42152001",label:"Sujetadores de aleta de mordida"},
    {prodId:"42152002",label:"Bloques o aletas o tabletas de mordida para uso odontológico"},
    {prodId:"42152003",label:"Procesadores de película para uso odontológico"},
    {prodId:"42152004",label:"Colgadores de película radiológica para uso odontológico"},
    {prodId:"42152005",label:"Sujetadores de película radiológica para uso odontológico"},
    {prodId:"42152006",label:"Monturas de película radiológica para uso odontológico"},
    {prodId:"42152007",label:"Duplicadores de rayos x para uso odontológico"},
    {prodId:"42152008",label:"Unidades de rayos x para uso odontológico"},
    {prodId:"42152009",label:"Visores o accesorios de rayos x para uso odontológico"},
    {prodId:"42152010",label:"Película radiológica para uso odontológico"},
    {prodId:"42152011",label:"Adaptadores de película de rayos x para uso odontológico"},
    {prodId:"42152012",label:"Partes o kits o accesorios para aparatos de rayos x para uso odontológico"},
    {prodId:"42152013",label:"Analizadores de película radiográfica para uso odontológico"},
    {prodId:"42152014",label:"Trazador radioactivo para uso odontológico"},
    {prodId:"42152100",label:"Equipo y suministros dentales para impresión y formar"},
    {prodId:"42152101",label:"Anillos de molde o suministros relacionados para uso odontológico"},
    {prodId:"42152102",label:"Formadores para uso odontológico"},
    {prodId:"42152103",label:"Adhesivos para bandejas de impresión para uso odontológico"},
    {prodId:"42152104",label:"Limpiadores de bandejas de impresión para uso odontológico"},
    {prodId:"42152105",label:"Bandejas de impresión para uso odontológico"},
    {prodId:"42152106",label:"Cuchillos para yeso para uso odontológico"},
    {prodId:"42152107",label:"Instrumentos de encerado para uso odontológico"},
    {prodId:"42152108",label:"Jeringas de material de impresión o accesorios para uso odontológico"},
    {prodId:"42152109",label:"Baños de agua para material de impresión o accesorios para uso odontológico"},
    {prodId:"42152110",label:"Dispositivos para marcar dientes"},
    {prodId:"42152111",label:"Endurecedores de material de impresión para uso odontológico"},
    {prodId:"42152112",label:"Instrumentos de secado o accesorios para uso odontológico"},
    {prodId:"42152113",label:"Kits de formación de base para prótesis dentales"},
    {prodId:"42152114",label:"Kits de entrega de materiales de impresión para uso odontológico"},
    {prodId:"42152115",label:"Canastas de impresión para uso odontológico"},
    {prodId:"42152200",label:"Equipo y suministros dentales de laboratorio y de esterilización"},
    {prodId:"42152201",label:"Láminas dentales"},
    {prodId:"42152202",label:"Unidades de abrasión por aire de laboratorio dental"},
    {prodId:"42152203",label:"Quemadores o antorchas de laboratorio dental"},
    {prodId:"42152204",label:"Máquinas para enyesar, sus partes o accesorios de laboratorio dental"},
    {prodId:"42152205",label:"Unidades de curado de laboratorio dental"},
    {prodId:"42152206",label:"Tintes de laboratorio dental"},
    {prodId:"42152207",label:"Recolectores de polvo de laboratorio dental"},
    {prodId:"42152208",label:"Máquinas o accesorios de laboratorio dental"},
    {prodId:"42152209",label:"Hornos de uso odontológico para laboratorios"},
    {prodId:"42152210",label:"Chapas de oro de laboratorio dental"},
    {prodId:"42152211",label:"Tornos o accesorios de laboratorio dental"},
    {prodId:"42152212",label:"Bordeadoras de modelos de laboratorio dental"},
    {prodId:"42152213",label:"Modelos de laboratorio dentales"},
    {prodId:"42152214",label:"Trampas de yeso de laboratorio dental"},
    {prodId:"42152215",label:"Lijadoras de chorro de arena o suministros de laboratorio dental"},
    {prodId:"42152216",label:"Máquinas soldadoras o suministros de laboratorio dental"},
    {prodId:"42152217",label:"Unidades de vacío o suministros de laboratorio dental"},
    {prodId:"42152218",label:"Vibradores de uso odontológico para laboratorios"},
    {prodId:"42152219",label:"Unidades de encerado de laboratorio dental"},
    {prodId:"42152220",label:"Piedras dentales para laboratorios"},
    {prodId:"42152221",label:"Protectores de moldes o estuches de laboratorio dental"},
    {prodId:"42152222",label:"Crisoles para máquinas de fundición de uso odontológico"},
    {prodId:"42152223",label:"Unidades o accesorios de procesamiento de resinas de laboratorio dental"},
    {prodId:"42152224",label:"Cantimploras de laboratorio dental"},
    {prodId:"42152400",label:"Materiales dentales"},
    {prodId:"42152401",label:"Aleaciones para amalgamas de uso odontológico"},
    {prodId:"42152402",label:"Materiales protectores de la boca para atletas"},
    {prodId:"42152403",label:"Aleaciones de molibdeno cromo cobalto fundido para implantes dentales"},
    {prodId:"42152404",label:"Materiales de impresión hidrocoloides irreversibles reversibles combinados"},
    {prodId:"42152405",label:"Plásticos para coronas o puentes"},
    {prodId:"42152406",label:"Pastas abrasivas de uso odontológico"},
    {prodId:"42152407",label:"Polvos abrasivos de uso odontológico"},
    {prodId:"42152408",label:"Puntos absorbentes de uso odontológico"},
    {prodId:"42152409",label:"Materiales de impresión agar de uso odontológico"},
    {prodId:"42152410",label:"Materiales de impresión alginadas de uso odontológico"},
    {prodId:"42152411",label:"Aleaciones de fundición de metal de base de uso odontológico"},
    {prodId:"42152412",label:"Ceras de placa de base de uso odontológico"},
    {prodId:"42152413",label:"Aleaciones de soldadura fuerte de uso odontológico"},
    {prodId:"42152414",label:"Aleaciones de fundición de uso odontológico"},
    {prodId:"42152415",label:"Cerámicas de uso odontológico"},
    {prodId:"42152416",label:"Materiales de duplicación de uso odontológico"},
    {prodId:"42152417",label:"Inversiones de sílice etilo de uso odontológico"},
    {prodId:"42152418",label:"Productos de yeso de uso odontológico"},
    {prodId:"42152419",label:"Materiales de impresión de pasta de óxido de zinc eugenol de uso odontológico"},
    {prodId:"42152420",label:"Ceras de fundición para incrustaciones de uso odontológico"},
    {prodId:"42152421",label:"Mercurio de uso odontológico"},
    {prodId:"42152422",label:"Puntos obturadores de uso odontológico"},
    {prodId:"42152423",label:"Sellantes de huecos o fisuras de uso odontológico"},
    {prodId:"42152424",label:"Cementos de base de agua de uso odontológico"},
    {prodId:"42152425",label:"Resinas de base para prótesis dentales"},
    {prodId:"42152426",label:"Resinas de forrado temporales de base para prótesis dentales"},
    {prodId:"42152427",label:"Resinas de reparación de cura fría para prótesis dentales"},
    {prodId:"42152428",label:"Resinas de relleno directo"},
    {prodId:"42152429",label:"Materiales elastoméricos para impresiones dentales"},
    {prodId:"42152430",label:"Materiales de relleno endodóntico"},
    {prodId:"42152431",label:"Elastómeros de prótesis maxilofacial extra oral"},
    {prodId:"42152432",label:"Inversiones de fundición de yeso unido para aleaciones de oro"},
    {prodId:"42152433",label:"Película radiográfica dental intraoral"},
    {prodId:"42152434",label:"Amalgamantes para uso odontológico"},
    {prodId:"42152435",label:"Materiales elastómeros para ortodoncia"},
    {prodId:"42152436",label:"Inversiones unidas de fosfato"},
    {prodId:"42152437",label:"Dientes de porcelana"},
    {prodId:"42152438",label:"Materiales refractarios de tinte"},
    {prodId:"42152439",label:"Materiales resistentes de recubrimiento para prótesis dentales removibles"},
    {prodId:"42152440",label:"Inversiones de soldadura"},
    {prodId:"42152441",label:"Dientes de polímeros sintéticos"},
    {prodId:"42152442",label:"Titanio sin aleación para implantes dentales"},
    {prodId:"42152443",label:"Cementos de óxido de zinc eugenol y de no eugenol"},
    {prodId:"42152444",label:"Líquido de retracción gingival"},
    {prodId:"42152445",label:"Sets de partes anteriores o posteriores de dientes"},
    {prodId:"42152446",label:"Recubrimientos o materiales adelgazantes o sets para caries dentales"},
    {prodId:"42152447",label:"Tintes o suministros de uso odontológico"},
    {prodId:"42152449",label:"Catalizadores de material de impresión de uso odontológico"},
    {prodId:"42152450",label:"Compuestos limpiadores de instrumentos de uso odontológico"},
    {prodId:"42152451",label:"Compuestos indicadores de presión de uso odontológico"},
    {prodId:"42152452",label:"Kits o accesorios de aislamiento de pastas de uso odontológico"},
    {prodId:"42152453",label:"Compuestos restauradores de uso odontológico"},
    {prodId:"42152454",label:"Protectores de nervios dentales"},
    {prodId:"42152455",label:"Estantes o soportes para productos abrasivos de uso odontológico"},
    {prodId:"42152456",label:"Agentes enmascaradores de uso odontológico"},
    {prodId:"42152457",label:"Kits de cementación de uso odontológico"},
    {prodId:"42152458",label:"Espaciadores de tintes de uso odontológico"},
    {prodId:"42152459",label:"Kits post endodoncia"},
    {prodId:"42152460",label:"Compuestos de recubrimiento para prótesis dentales"},
    {prodId:"42152461",label:"Compuestos de recubrimiento para modelos dentales"},
    {prodId:"42152462",label:"Kits de revestimiento de prótesis dentales"},
    {prodId:"42152463",label:"Solventes de cera de uso odontológico"},
    {prodId:"42152464",label:"Kits para implantes dentales"},
    {prodId:"42152465",label:"Lubricantes dentales"},
    {prodId:"42152466",label:"Probador de ajuste de material"},
    {prodId:"42152468",label:"Mezclador de material de impresión dental de alginato operado eléctricamente"},
    {prodId:"42152469",label:"Mezclador de material de impresión dental basado en caucho operado eléctricamente"},
    {prodId:"42152470",label:"Resina para aplicación de ortodoncia"},
    {prodId:"42152500",label:"Suministros dentales generales"},
    {prodId:"42152501",label:"Kits o bandejas desechables pre ensambladas de uso odontológico"},
    {prodId:"42152502",label:"Baberos de uso odontológico"},
    {prodId:"42152503",label:"Suministros hembra de uso odontológico"},
    {prodId:"42152504",label:"Recubrimientos de uso odontológico"},
    {prodId:"42152505",label:"Forros para el apoyacabezas de la silla de examen de uso odontológico"},
    {prodId:"42152506",label:"Bolitas absorbentes de uso odontológico"},
    {prodId:"42152507",label:"Rollos de uso odontológico"},
    {prodId:"42152508",label:"Jeringas o agujas o jeringas con agujas de uso odontológico"},
    {prodId:"42152509",label:"Cubiertas desechables para bandejas de uso odontológico"},
    {prodId:"42152510",label:"Estuches o cubiertas para instrumentos o sets de uso odontológico"},
    {prodId:"42152511",label:"Platos de uso odontológico"},
    {prodId:"42152512",label:"Pinzas de uso odontológico"},
    {prodId:"42152513",label:"Tazas medidoras de uso odontológico"},
    {prodId:"42152514",label:"Contenedores o accesorios de materiales de desecho de uso odontológico"},
    {prodId:"42152516",label:"Tazones mezcladores de uso odontológico"},
    {prodId:"42152517",label:"Sujetadores o dispensadores de servilletas de uso odontológico"},
    {prodId:"42152518",label:"Dispensadores de materiales de uso odontológico"},
    {prodId:"42152519",label:"Kits de accesorios para jeringas de uso odontológico"},
    {prodId:"42152520",label:"Escupideras de uso odontológico"},
    {prodId:"42152521",label:"Pantógrafo"},
    {prodId:"42152600",label:"Suministros específicos a la operación dental"},
    {prodId:"42152601",label:"Papeles articulados para operación o productos relacionados de uso odontológico"},
    {prodId:"42152602",label:"Barreras de control de infección para operaciones de uso odontológico"},
    {prodId:"42152603",label:"Tornillos de retención o productos relacionados para operaciones de uso odontológico"},
    {prodId:"42152604",label:"Cordones de retracción para operaciones de uso odontológico"},
    {prodId:"42152605",label:"Sets de sialografía de uso odontológico"},
    {prodId:"42152606",label:"Tablillas o sets de uso odontológico"},
    {prodId:"42152608",label:"Cartuchos de ligadura para ortodoncia"},
    {prodId:"42152700",label:"Equipo y suministros ortodoncia y prostodónticos"},
    {prodId:"42152701",label:"Articuladores o accesorios de uso odontológico"},
    {prodId:"42152702",label:"Soluciones hemostáticas de uso odontológico"},
    {prodId:"42152703",label:"Tornillos o fijadores o suministros relacionados de uso odontológico"},
    {prodId:"42152704",label:"Retenedores de uso odontológico"},
    {prodId:"42152705",label:"Pinzas de aplicación para ortodoncia"},
    {prodId:"42152706",label:"Frenillos brackets para ortodoncia"},
    {prodId:"42152707",label:"Tubos bucales para ortodoncia"},
    {prodId:"42152708",label:"Bobinas de resorte para ortodoncia"},
    {prodId:"42152709",label:"Elásticos para ortodoncia"},
    {prodId:"42152710",label:"Elastómeros para ortodoncia"},
    {prodId:"42152711",label:"Tornillos de expansión para ortodoncia"},
    {prodId:"42152712",label:"Bandas molares para ortodoncia"},
    {prodId:"42152713",label:"Alicates para ortodoncia"},
    {prodId:"42152714",label:"Estuches retenedores para ortodoncia"},
    {prodId:"42152715",label:"Bandas de colocación para ortodoncia"},
    {prodId:"42152716",label:"Topes o bloqueos para ortodoncia"},
    {prodId:"42152717",label:"Alambre para ortodoncia"},
    {prodId:"42152718",label:"Arco de alambre para ortodoncia"},
    {prodId:"42152800",label:"Equipo y suministro periodontal"},
    {prodId:"42152801",label:"Cinceles para periodoncia"},
    {prodId:"42152802",label:"Limas para periodoncia"},
    {prodId:"42152803",label:"Separadores para periodoncia"},
    {prodId:"42152804",label:"Instrumentos de prueba de implante periodontal a interface de hueso"},
    {prodId:"42152805",label:"Instrumentos de control para periodoncia"},
    {prodId:"42152806",label:"Disectores de tejido para periodoncia"},
    {prodId:"42152807",label:"Pastas protectoras para periodoncia"},
    {prodId:"42152808",label:"Curetas para periodoncia"},
    {prodId:"42152809",label:"Cuchillos para periodoncia"},
    {prodId:"42152810",label:"Membranas para periodoncia"},
    {prodId:"42161500",label:"Equipo de diálisis peritoneal y de equilibrio y suministros"},
    {prodId:"42161501",label:"Sets de transferencia de diálisis peritoneal ambulatoria continua capd"},
    {prodId:"42161502",label:"Calentadores de dialisato peritoneal"},
    {prodId:"42161503",label:"Sets de administración o cateterización de diálisis peritoneal"},
    {prodId:"42161504",label:"Adaptadores o pinzas o conectores de catéter para diálisis peritoneal"},
    {prodId:"42161505",label:"Bolsas o contenedores de drenaje de diálisis peritoneal"},
    {prodId:"42161506",label:"Derivaciones o catéteres o dispositivos de acceso permanente para diálisis peritoneal"},
    {prodId:"42161507",label:"Soluciones para diálisis peritoneal"},
    {prodId:"42161508",label:"Correas o arneses para unidades de diálisis peritoneal"},
    {prodId:"42161509",label:"Unidades de diálisis peritoneal"},
    {prodId:"42161510",label:"Kits de lavado para diálisis peritoneal"},
    {prodId:"42161600",label:"Equipo de hemodiálisis extracorpórea y suministros"},
    {prodId:"42161601",label:"Kits o sets o accesorios de administración de hemodiálisis"},
    {prodId:"42161602",label:"Muestreadores de bolsas de sangre para hemodiálisis"},
    {prodId:"42161603",label:"Aparato de demanda de oxígeno en la sangre para hemodiálisis"},
    {prodId:"42161604",label:"Sillas para hemodiálisis"},
    {prodId:"42161605",label:"Medidores de conductividad de dialisato para hemodiálisis"},
    {prodId:"42161606",label:"Detectores de nivel de dialisato para hemodiálisis"},
    {prodId:"42161607",label:"Bombas de presión de dialisato para hemodiálisis"},
    {prodId:"42161608",label:"Soluciones de dialisato para hemodiálisis"},
    {prodId:"42161609",label:"Tanques de dialisato para hemodiálisis"},
    {prodId:"42161610",label:"Tubos de dialisato para hemodiálisis"},
    {prodId:"42161611",label:"Baños calentadores de dialisato para hemodiálisis"},
    {prodId:"42161612",label:"Filtros de celuloide de dializador para hemodiálisis"},
    {prodId:"42161613",label:"Filtros de colodión de dializador para hemodiálisis"},
    {prodId:"42161614",label:"Filtros de filamento hueco de dializador para hemodiálisis"},
    {prodId:"42161615",label:"Sistemas de reprocesamiento de dializador para hemodiálisis"},
    {prodId:"42161616",label:"Detectores de burbujas de aire o espuma o coágulos, o trampas o alarmas para unidades de hemodiálisis"},
    {prodId:"42161617",label:"Monitores de presión arterial para unidades de hemodiálisis"},
    {prodId:"42161618",label:"Pinzas de línea de sangre para unidades de hemodiálisis"},
    {prodId:"42161619",label:"Tapas para válvulas de sangre para unidades de hemodiálisis"},
    {prodId:"42161620",label:"Bombas de sangre para unidades de hemodiálisis"},
    {prodId:"42161621",label:"Tapas para válvulas de dialisato unidades de hemodiálisis"},
    {prodId:"42161622",label:"Desinfectantes o limpiadores para unidades de hemodiálisis"},
    {prodId:"42161623",label:"Bombas de infusión de heparina unidades de hemodiálisis"},
    {prodId:"42161624",label:"Controladores de agujas únicas para unidades de hemodiálisis"},
    {prodId:"42161625",label:"Convertidores de un solo paso para unidades de hemodiálisis"},
    {prodId:"42161626",label:"Estantes o soportes o carritos para unidades de hemodiálisis"},
    {prodId:"42161627",label:"Monitores de temperatura para unidades de hemodiálisis"},
    {prodId:"42161628",label:"Equipos de prueba para unidades de hemodiálisis"},
    {prodId:"42161629",label:"Filtros transductores para unidades de hemodiálisis"},
    {prodId:"42161630",label:"Protectores transductores para unidades de hemodiálisis"},
    {prodId:"42161631",label:"Sistemas de purificación del agua para unidades de hemodiálisis"},
    {prodId:"42161632",label:"Unidades de hemodiálisis"},
    {prodId:"42161633",label:"Membranas dializadoras para aparatos de hemodiálisis"},
    {prodId:"42161634",label:"Bandejas de procedimiento o accesorios para hemodiálisis"},
    {prodId:"42161635",label:"Cartuchos para aparatos de hemodiálisis"},
    {prodId:"42161700",label:"Equipo de hemofiltración y suministros"},
    {prodId:"42161701",label:"Hemofiltro"},
    {prodId:"42161702",label:"Bolsas de recolección de hemofiltración"},
    {prodId:"42161703",label:"Puertos de infusión para hemofiltración"},
    {prodId:"42161704",label:"Puertos de muestreo para hemofiltración"},
    {prodId:"42161900",label:"Equipo y suministros para aféresis terapéutica"},
    {prodId:"42161901",label:"Kit para procedimientos de aféresis terapéutica"},
    {prodId:"42171600",label:"Productos de sacar, inmovilizar y trasladar de servicios médicos de urgencia"},
    {prodId:"42171601",label:"Camillas o accesorios para evacuación aérea"},
    {prodId:"42171602",label:"Camillas o accesorios para ambulancias"},
    {prodId:"42171603",label:"Prendas anti choque"},
    {prodId:"42171604",label:"Camillas canasta o accesorios"},
    {prodId:"42171605",label:"Ojales de cable de rescate"},
    {prodId:"42171606",label:"Férulas inflables para servicios médicos de emergencia"},
    {prodId:"42171607",label:"Collares cervicales o de extracción de víctimas para servicios médicos de emergencia"},
    {prodId:"42171608",label:"Inmovilizadores de cabeza para servicios médicos de emergencia"},
    {prodId:"42171609",label:"Correas de retención o espinales para servicios médicos de emergencia"},
    {prodId:"42171610",label:"Inmovilizadores de torso para servicios médicos de emergencia"},
    {prodId:"42171611",label:"Literas o camillas o accesorios para respuesta de emergencia"},
    {prodId:"42171612",label:"Camillas o accesorios redondas"},
    {prodId:"42171613",label:"Tablas espinales"},
    {prodId:"42171614",label:"Tubos o contenedores para rescate en agua"},
    {prodId:"42172000",label:"Kits para los servicios médicos de urgencias y campo"},
    {prodId:"42172001",label:"Kits de primeros auxilios para servicios médicos de emergencia"},
    {prodId:"42172002",label:"Kits de primera respuesta para servicios médicos de emergencia"},
    {prodId:"42172003",label:"Kits intravenosos iv para servicios médicos de emergencia"},
    {prodId:"42172004",label:"Kits de soporte de vida para servicios médicos de emergencia"},
    {prodId:"42172005",label:"Kits de trauma de respuesta de larga distancia ldr para servicios médicos de emergencia"},
    {prodId:"42172006",label:"Kits obstétricos para servicios médicos de emergencia"},
    {prodId:"42172007",label:"Kits de oxígeno o resucitación para servicios médicos de emergencia"},
    {prodId:"42172008",label:"Kits de rapel para servicios médicos de emergencia"},
    {prodId:"42172009",label:"Kits de búsqueda y rescate para servicios médicos de emergencia"},
    {prodId:"42172010",label:"Kits de trauma para servicios médicos de emergencia"},
    {prodId:"42172011",label:"Kits para técnicos médicos de emergencia emt"},
    {prodId:"42172012",label:"Kits de ventriculostomía para servicios médicos de emergencia"},
    {prodId:"42172013",label:"Kits de evacuación para servicios médicos de emergencia"},
    {prodId:"42172014",label:"Kits o suministros de transporte de pacientes para servicios médicos de emergencia"},
    {prodId:"42172015",label:"Kits de tratamiento dental para servicios médicos de emergencia"},
    {prodId:"42172016",label:"Kits de fracturas para servicios médicos de emergencia"},
    {prodId:"42172017",label:"Kits de equipos médicos de laboratorio o de campo o productos relacionados"},
    {prodId:"42172018",label:"Kit de lavado gástrico"},
    {prodId:"42181500",label:"Evaluación diagnóstica y productos de examen de uso general"},
    {prodId:"42181501",label:"Depresores de lengua o cuchillos o baja lenguas"},
    {prodId:"42181502",label:"Transiluminadores para exámenes de uso médico"},
    {prodId:"42181503",label:"Lubricantes o gelatinas personales o para examen"},
    {prodId:"42181504",label:"Sets de hemacitómetros"},
    {prodId:"42181505",label:"Sets o accesorios de muestreadores de células endometriales"},
    {prodId:"42181506",label:"Kits de determinación de asalto sexual"},
    {prodId:"42181507",label:"Bandas para sujetar el espejo a la frente o accesorios para exámenes médicos"},
    {prodId:"42181508",label:"Pañitos limpiadores para equipo diagnóstico"},
    {prodId:"42181509",label:"Sets de examen físico para cirujanos de vuelo"},
    {prodId:"42181510",label:"Medidores de ictericia transcutáneos"},
    {prodId:"42181511",label:"Evaluadores de impotencia masculina"},
    {prodId:"42181512",label:"Cucharas para examen de portadores de tifoidea"},
    {prodId:"42181513",label:"Kits de irrigación de los senos nasales"},
    {prodId:"42181514",label:"Fotómetros de hemoglobina"},
    {prodId:"42181515",label:"Bandejas de procedimiento para exámenes"},
    {prodId:"42181516",label:"Unidades o accesorios de electromiografía emg"},
    {prodId:"42181518",label:"Estuche para sets de instrumentos médicos o sus accesorios"},
    {prodId:"42181519",label:"Portador de gotas"},
    {prodId:"42181520",label:"Medidor de presión intratoráxica"},
    {prodId:"42181521",label:"Monitor de presión de fluido espinal"},
    {prodId:"42181522",label:"Aparato para análisis de manera de caminar"},
    {prodId:"42181523",label:"Sistema de prueba y evaluación isocinética"},
    {prodId:"42181524",label:"Analizador de rigidez"},
    {prodId:"42181525",label:"Ataxiágrafo"},
    {prodId:"42181526",label:"Algesímetro"},
    {prodId:"42181527",label:"Dinamómetro médico"},
    {prodId:"42181528",label:"Dispositivo para medir la resistencia de la piel"},
    {prodId:"42181529",label:"Estesiómetro"},
    {prodId:"42181530",label:"Sistema de inducción de sudor de iontoforesis"},
    {prodId:"42181531",label:"Instrumento medidor de conductividad eléctrica de la piel"},
    {prodId:"42181532",label:"Medidor de respuesta galvánica de la piel"},
    {prodId:"42181533",label:"Dispositivo de medición de la velocidad de conducción del nervio"},
    {prodId:"42181534",label:"Electroglotógrafo"},
    {prodId:"42181535",label:"Condicionador de señales electrónicas fisiológicas"},
    {prodId:"42181536",label:"Dispositivo eléctrico de detección de caries"},
    {prodId:"42181537",label:"Instrumento para medir la fuerza de la mordida para uso odontológico"},
    {prodId:"42181538",label:"Dispositivo medidor del sonido de oclusión para uso odontológico"},
    {prodId:"42181600",label:"Unidades de presión sanguínea y productos relacionados"},
    {prodId:"42181601",label:"Unidades de presión de sangre aneroides"},
    {prodId:"42181602",label:"Unidades de presión de sangre electrónicas"},
    {prodId:"42181603",label:"Unidades de presión de sangre de mercurio"},
    {prodId:"42181604",label:"Válvulas o peras inflables de liberación de presión del aire en la sangre"},
    {prodId:"42181605",label:"Mangas o vejigas de presión de la sangre"},
    {prodId:"42181606",label:"Mangueras de inflar o mangueras neumáticas o adaptadores de presión de sangre"},
    {prodId:"42181607",label:"Unidades de registro de presión de sangre"},
    {prodId:"42181608",label:"Accesorios para instrumentos de medición de presión de sangre"},
    {prodId:"42181609",label:"Kits de domo de monitoreo de presión de sangre"},
    {prodId:"42181610",label:"Kits de mangas de presión de sangre"},
    {prodId:"42181611",label:"Manómetro de presión venosa central cvp"},
    {prodId:"42181612",label:"Soportes móviles de presión de sangre"},
    {prodId:"42181700",label:"Unidades de electrocardiografía (ECG) y productos relacionados"},
    {prodId:"42181701",label:"Unidades de electrocardiografía ekg"},
    {prodId:"42181702",label:"Adaptadores o cables o conductores para electrocardiografía ekg"},
    {prodId:"42181703",label:"Probadores de cables o conductores o unidades de electrocardiografía ekg"},
    {prodId:"42181704",label:"Calibradores o reglas de electrocardiografía ekg"},
    {prodId:"42181705",label:"Registros gráficos de electrocardiografía ekg"},
    {prodId:"42181706",label:"Pantallas de monitor para electrocardiografía ekg"},
    {prodId:"42181707",label:"Electrodos de tira o anillo para electrocardiografía ekg neonatal"},
    {prodId:"42181708",label:"Electrodos de parche para electrocardiografía ekg"},
    {prodId:"42181710",label:"Probadores de superficie de electrodos para electrocardiografía ekg"},
    {prodId:"42181711",label:"Sistemas telefónicos receptores o transmisores para electrocardiografía ekg"},
    {prodId:"42181712",label:"Analizadores de unidad de electrocardiografía ekg"},
    {prodId:"42181713",label:"Sistemas de monitoreo de electrocardiografía ekg continua de larga duración o holter"},
    {prodId:"42181714",label:"Kits de accesorios de monitoreo para electrocardiografía ekg"},
    {prodId:"42181715",label:"Soluciones o cremas para electrodos"},
    {prodId:"42181716",label:"Accesorios para electrocardiografía ekg"},
    {prodId:"42181717",label:"Bombillos de electrodos para electrocardiografía ekg"},
    {prodId:"42181718",label:"Esferos para registro de electrocardiografía ekg"},
    {prodId:"42181719",label:"Transmisor o telemetría o accesorios para electrocardiografía ekg"},
    {prodId:"42181720",label:"Sistema de monitoreo de estrés por ejercicio"},
    {prodId:"42181721",label:"Esfigmógrafo"},
    {prodId:"42181722",label:"Analizador de ondas de pulso"},
    {prodId:"42181800",label:"Oxímetros de pulso"},
    {prodId:"42181801",label:"Unidades para oxímetros de pulso"},
    {prodId:"42181802",label:"Cables para oxímetros de pulso"},
    {prodId:"42181803",label:"Sondas o sensores para oxímetros de pulso"},
    {prodId:"42181804",label:"Accesorios para sondas o sensores para oxímetros de pulso"},
    {prodId:"42181805",label:"Accesorios para unidades de oxímetros de pulso"},
    {prodId:"42181900",label:"Unidades de control de cuidado intenso y productos relacionados"},
    {prodId:"42181901",label:"Unidades o accesorios para cuidado intensivo fetal o monitoreo materno"},
    {prodId:"42181902",label:"Unidades o accesorios para monitoreo de presión intracraneal icp"},
    {prodId:"42181903",label:"Unidades o accesorios para monitoreo de salida cardiaca co"},
    {prodId:"42181904",label:"Unidades o accesorios para unidades de signos vitales multi parámetro"},
    {prodId:"42181905",label:"Cables para monitor transductor para uso médico"},
    {prodId:"42181906",label:"Catéteres para monitoreo de presión intrauterina"},
    {prodId:"42181907",label:"Aparatos para metabolismo basal"},
    {prodId:"42181908",label:"Sets o accesorios de monitoreo de presión intra compartimientos"},
    {prodId:"42181910",label:"Kits intrauterinos transcervicales"},
    {prodId:"42181911",label:"Electrodos para cuero cabelludo fetal"},
    {prodId:"42181912",label:"Monitor electroencefalográfico fetal"},
    {prodId:"42182000",label:"Especulo y scopios y accesorios para uso del reconocimiento diagnóstico médico"},
    {prodId:"42182001",label:"Espéculos para examen anal o rectal"},
    {prodId:"42182002",label:"Anoscopios o proctoscopios"},
    {prodId:"42182003",label:"Colposcopios o vaginoscopios o accesorios"},
    {prodId:"42182004",label:"Dermatoscopios"},
    {prodId:"42182005",label:"Oftalmoscopios u otoscopios o sets de escopios"},
    {prodId:"42182006",label:"Espéculos para examen de laringe o bucofaríngeo"},
    {prodId:"42182007",label:"Bombillos o lámparas de escopios para exámenes médicos"},
    {prodId:"42182008",label:"Manijas de escopios o cargadores de escopios para exámenes médicos"},
    {prodId:"42182009",label:"Puntas de espéculos para escopios o dispensadores de puntas de espéculos para uso médico"},
    {prodId:"42182010",label:"Sujetadores o soportes para espéculos para exámenes médicos"},
    {prodId:"42182011",label:"Espéculos o puntas de dilatación o dispensadores de puntas para exámenes médicos"},
    {prodId:"42182012",label:"Espéculos o dilatadores para exámenes nasales"},
    {prodId:"42182013",label:"Espéculos para examen vaginal"},
    {prodId:"42182014",label:"Accesorios para otoscopios u oftalmoscopios"},
    {prodId:"42182015",label:"Espéculos para otoscopia"},
    {prodId:"42182016",label:"Nasofaringoscopios o accesorios"},
    {prodId:"42182017",label:"Sets de espéculos para oído"},
    {prodId:"42182018",label:"Broncoscopios o accesorios"},
    {prodId:"42182019",label:"Kits de dilatación"},
    {prodId:"42182020",label:"Angioscopios o accesorios"},
    {prodId:"42182100",label:"Estetoscopio y productos relacionados"},
    {prodId:"42182101",label:"Estetoscopios electrónicos o accesorios"},
    {prodId:"42182102",label:"Dopplers vasculares de mano o accesorios"},
    {prodId:"42182103",label:"Estetoscopio acústico para uso médico o accesorios"},
    {prodId:"42182104",label:"Estetoscopio de cabeza"},
    {prodId:"42182105",label:"Cubiertas para estetoscopios de cabeza"},
    {prodId:"42182106",label:"Fonocardiográfos estetoscópicos"},
    {prodId:"42182107",label:"Estetoscopios auriculares"},
    {prodId:"42182108",label:"Termorreguladores de pacientes"},
    {prodId:"42182200",label:"Termómetros médicos y accesorios"},
    {prodId:"42182201",label:"Termómetros electrónicos para uso médico"},
    {prodId:"42182202",label:"Termómetros de fibra óptica para uso médico"},
    {prodId:"42182203",label:"Estuches o forros para transportar termómetros para uso médico"},
    {prodId:"42182204",label:"Estantes para termómetros para uso médico"},
    {prodId:"42182205",label:"Fundas para puntas o sondas de termómetros para uso médico"},
    {prodId:"42182206",label:"Termómetros de mercurio para uso médico"},
    {prodId:"42182207",label:"Monitores de temperatura continua o de tendencia de temperatura del paciente"},
    {prodId:"42182208",label:"Bandas de temperatura del paciente"},
    {prodId:"42182209",label:"Sondas para termómetros"},
    {prodId:"42182210",label:"Termómetros de vidrio no de mercurio para uso médico"},
    {prodId:"42182211",label:"Sistema de termografía infrarroja"},
    {prodId:"42182212",label:"Termómetro de cristal líquido"},
    {prodId:"42182300",label:"Productos del examen neurológico"},
    {prodId:"42182301",label:"Molinillos de diagnóstico para uso médico"},
    {prodId:"42182302",label:"Martillos o martillos quirúrgicos para reflejos"},
    {prodId:"42182303",label:"Tarjetas de examen neurosiquiátrico"},
    {prodId:"42182304",label:"Sets o kits de pruebas de diagnóstico sicológico"},
    {prodId:"42182305",label:"Sets para procedimientos mielográficos"},
    {prodId:"42182306",label:"Discriminadores neurológicos"},
    {prodId:"42182307",label:"Alfileres neurológicos"},
    {prodId:"42182308",label:"Electroencefalógrafo eeg o accesorios"},
    {prodId:"42182310",label:"Electromiógrafos"},
    {prodId:"42182311",label:"Sensores neurológicos"},
    {prodId:"42182312",label:"Electrodos o sets para electromiógrafos"},
    {prodId:"42182313",label:"Sets para diagnóstico neurológico"},
    {prodId:"42182314",label:"Dispositivo de retroalimentación biológica"},
    {prodId:"42182315",label:"Analizador de espectro electroencefalográfico"},
    {prodId:"42182316",label:"Detector de respuesta evocada"},
    {prodId:"42182400",label:"Productos para probar el oír"},
    {prodId:"42182401",label:"Audiómetros o accesorios"},
    {prodId:"42182402",label:"Vibradores de hueso audiométricos o analizadores de oído medio"},
    {prodId:"42182403",label:"Cabinas audiométricas o cámaras acústicas para pruebas de audición"},
    {prodId:"42182404",label:"Sets de calibración de unidades de evaluación de función auditiva"},
    {prodId:"42182405",label:"Unidades de evaluación de función auditiva"},
    {prodId:"42182406",label:"Grabadoras gráficas de pruebas auditivas"},
    {prodId:"42182407",label:"Fenestrómetros de oído"},
    {prodId:"42182408",label:"Electrococleógrafos"},
    {prodId:"42182409",label:"Analizadores de audífonos o sistemas de prueba"},
    {prodId:"42182410",label:"Estuches para diapasones para uso médico"},
    {prodId:"42182411",label:"Martillos para diapasones para uso médico"},
    {prodId:"42182412",label:"Diapasones para uso médico"},
    {prodId:"42182413",label:"Sets de diapasones para uso médico"},
    {prodId:"42182414",label:"Analizadores de tinnitus (acúfenos)"},
    {prodId:"42182415",label:"Tubos de toynbee para diagnóstico"},
    {prodId:"42182416",label:"Timpanómetros o sus accesorios"},
    {prodId:"42182417",label:"Cordones de audiometría"},
    {prodId:"42182418",label:"Guías para fenestrómetro"},
    {prodId:"42182419",label:"Aparatos para control de audición"},
    {prodId:"42182420",label:"Sondas aurales"},
    {prodId:"42182421",label:"Calibradores de tapones o accesorios"},
    {prodId:"42182422",label:"Bolsas inflables para oído"},
    {prodId:"42182600",label:"Lámparas o luces de examen médico"},
    {prodId:"42182601",label:"Luces o lámparas de pie para exámenes médicos"},
    {prodId:"42182602",label:"Luces o lámparas instaladas para exámenes médicos"},
    {prodId:"42182603",label:"Luces de techo o lámparas de techo o accesorios para exámenes médicos"},
    {prodId:"42182604",label:"Linternas para exámenes médicos"},
    {prodId:"42182700",label:"Dispositivos de medición de talla de examen médico"},
    {prodId:"42182701",label:"Goniómetros"},
    {prodId:"42182702",label:"Cintas de medición para uso médico"},
    {prodId:"42182703",label:"Reglas de medición de altura de pacientes"},
    {prodId:"42182704",label:"Evaluadores de dobleces de piel"},
    {prodId:"42182705",label:"Analizador de composición del cuerpo"},
    {prodId:"42182706",label:"Malla o dispositivo de medición de heridas"},
    {prodId:"42182707",label:"Inclinómetro"},
    {prodId:"42182800",label:"Básculas médicas de peso"},
    {prodId:"42182801",label:"Básculas de pañales"},
    {prodId:"42182802",label:"Básculas de bebés"},
    {prodId:"42182803",label:"Básculas de mesa o cama para pacientes para uso general"},
    {prodId:"42182804",label:"Básculas de asiento para pacientes"},
    {prodId:"42182805",label:"Básculas de piso para pacientes"},
    {prodId:"42182806",label:"Básculas de eslinga para pacientes"},
    {prodId:"42182807",label:"Básculas de plataforma para sillas de ruedas"},
    {prodId:"42182808",label:"Forros o revestimientos para básculas de pesaje"},
    {prodId:"42183000",label:"Productos de examen diagnóstico oftálmico"},
    {prodId:"42183001",label:"Carteleras para examen de ojos o tarjetas de visión"},
    {prodId:"42183002",label:"Topógrafos corneales"},
    {prodId:"42183003",label:"Exoftalmómetros"},
    {prodId:"42183004",label:"Queratoscopios"},
    {prodId:"42183005",label:"Colorímetros oftálmicos"},
    {prodId:"42183006",label:"Distómetros oftálmicos"},
    {prodId:"42183007",label:"Tambores oftálmicos o sus accesorios"},
    {prodId:"42183008",label:"Eutiscopios oftálmicos"},
    {prodId:"42183009",label:"Lentes de prueba del ojo o sus accesorios para uso oftálmico"},
    {prodId:"42183010",label:"Lensómetros para uso oftálmico"},
    {prodId:"42183011",label:"Perímetros para uso oftálmico"},
    {prodId:"42183012",label:"Fotómetros para uso oftálmico"},
    {prodId:"42183013",label:"Prismas para uso oftálmico"},
    {prodId:"42183014",label:"Retinoscopios para uso oftálmico"},
    {prodId:"42183015",label:"Lámparas de hendidura para uso oftálmico"},
    {prodId:"42183016",label:"Espectrofotómetros para uso oftálmico"},
    {prodId:"42183017",label:"Espéculos para uso oftálmico"},
    {prodId:"42183018",label:"Tonómetros o accesorios para uso oftálmico"},
    {prodId:"42183019",label:"Transiluminadores para uso oftálmico"},
    {prodId:"42183020",label:"Trazadores de campo visual para uso oftálmico"},
    {prodId:"42183021",label:"Analizadores de función visual para uso oftálmico"},
    {prodId:"42183022",label:"Visiómetros para uso oftálmico"},
    {prodId:"42183023",label:"Oftalmómetros"},
    {prodId:"42183024",label:"Mesas para instrumentos o accesorios para uso oftálmico"},
    {prodId:"42183026",label:"Oftalmodinamómetros"},
    {prodId:"42183027",label:"Kits o accesorios de pruebas de monitoreo tangente"},
    {prodId:"42183028",label:"Accesorios para retinoscopio para uso oftálmico"},
    {prodId:"42183029",label:"Unidades de forópter"},
    {prodId:"42183030",label:"Oclusores de ojos"},
    {prodId:"42183031",label:"Sets o accesorios de placas pseudoisocromáticas"},
    {prodId:"42183032",label:"Taquistoscopio"},
    {prodId:"42183033",label:"Sets de ajuste de anteojos"},
    {prodId:"42183034",label:"Estereoscopios para probar la visión"},
    {prodId:"42183035",label:"Queratómetros refractores de combinación"},
    {prodId:"42183036",label:"Placas base para oftalmómetros"},
    {prodId:"42183037",label:"Proyectores de cuadros o accesorios"},
    {prodId:"42183038",label:"Almohadillas de uso oftálmico"},
    {prodId:"42183039",label:"Sujetadores de lentes oftálmicos"},
    {prodId:"42183040",label:"Herramientas o accesorios para optómetras"},
    {prodId:"42183041",label:"Linternas de pruebas de percepción del color"},
    {prodId:"42183042",label:"Aparatos de percepción de profundidad"},
    {prodId:"42183043",label:"Bombillos para oftalmómetros"},
    {prodId:"42183044",label:"Pantallas tangentes"},
    {prodId:"42183045",label:"Sistemas de electroretinograma"},
    {prodId:"42183046",label:"Sets o accesorios de prueba de visión binocular"},
    {prodId:"42183047",label:"Soportes miradores para pruebas de agudeza visual"},
    {prodId:"42183048",label:"Barras de fijación de niños para uso oftálmico"},
    {prodId:"42183049",label:"Cámara de fundus"},
    {prodId:"42183050",label:"Nistagmógrafo"},
    {prodId:"42183051",label:"Pupilómetro"},
    {prodId:"42183052",label:"Regla de punto cercano"},
    {prodId:"42183053",label:"Medidor de distancia entre pupilas"},
    {prodId:"42183054",label:"Pletismografía ocular"},
    {prodId:"42183055",label:"Contador de células endoteliales"},
    {prodId:"42183056",label:"Cámara anterior"},
    {prodId:"42183057",label:"Monitor de movimiento del ojo"},
    {prodId:"42183058",label:"Instrumento láser de campo de visión"},
    {prodId:"42183059",label:"Foto simulador"},
    {prodId:"42183060",label:"Tiras de schirmer"},
    {prodId:"42183061",label:"Aplicadores de presión ocular"},
    {prodId:"42183062",label:"Cámara de iris"},
    {prodId:"42183063",label:"Unidad de electrólisis oftálmica"},
    {prodId:"42183064",label:"Lentes de contacto terapéuticos"},
    {prodId:"42183065",label:"Lentes de contacto prescritos"},
    {prodId:"42183066",label:"Cámara corneal"},
    {prodId:"42183067",label:"Lentes de examen de diagnóstico de visión para uso oftálmico"},
    {prodId:"42191500",label:"Manejo de materiales de facilidad médica y equipo de distribución"},
    {prodId:"42191501",label:"Sistema de tubo neumático clínico"},
    {prodId:"42191502",label:"Bandejas o cubiertas para medicinas"},
    {prodId:"42191600",label:"Sistemas de construcción de facilidad médica"},
    {prodId:"42191601",label:"Iluminación para habitaciones de pacientes o accesorios"},
    {prodId:"42191602",label:"Iluminación para salas de cirugía o accesorios"},
    {prodId:"42191603",label:"Paneles de instrumentos para equipos hospitalarios"},
    {prodId:"42191604",label:"Brazos de monitoreo clínico"},
    {prodId:"42191605",label:"Columnas para equipos eléctricos hospitalarios"},
    {prodId:"42191606",label:"Brazos de techo para instalaciones médicas"},
    {prodId:"42191607",label:"Cortinas de cubículo o pantallas o hardware de rieles de cortinas para pacientes"},
    {prodId:"42191608",label:"Controles de enfermería o monitores de salida"},
    {prodId:"42191609",label:"Sistemas de pared principal para uso clínico"},
    {prodId:"42191610",label:"Módulos de trabajo para uso clínico"},
    {prodId:"42191611",label:"Módulos o sistemas de comunicación de enfermería"},
    {prodId:"42191612",label:"Sistemas de intercomunicación hospitalaria"},
    {prodId:"42191613",label:"Transmisor de seguridad para bebés o niños"},
    {prodId:"42191700",label:"Productos de gas de hospital"},
    {prodId:"42191701",label:"Pistas de servicio de electricidad o gas para uso médico"},
    {prodId:"42191702",label:"Columnas de entrega de gas para uso médico"},
    {prodId:"42191703",label:"Salidas de gas para uso médico"},
    {prodId:"42191704",label:"Sistemas de compresión de aire gas para uso médico"},
    {prodId:"42191705",label:"Alarmas de gas para uso médico"},
    {prodId:"42191706",label:"Colector de gas para uso medico"},
    {prodId:"42191707",label:"Sistemas de vacío para uso médico"},
    {prodId:"42191708",label:"Gabinetes de control de presión de aire para uso médico"},
    {prodId:"42191709",label:"Válvulas de cierre de gas o cajas de válvulas para uso médico"},
    {prodId:"42191710",label:"Carros o puestos o accesorios para cilindros de gas para uso médico"},
    {prodId:"42191711",label:"Tanques de aire comprimido o accesorios para uso quirúrgico"},
    {prodId:"42191800",label:"Camas de paciente y accesorios"},
    {prodId:"42191801",label:"Mesas para encima de la cama o accesorios"},
    {prodId:"42191802",label:"Incubadoras o calentadores de bebés para uso clínico"},
    {prodId:"42191803",label:"Moisés o cunas o camas pediátricas o accesorios"},
    {prodId:"42191804",label:"Barandas para camas para uso médico o quirúrgico"},
    {prodId:"42191805",label:"Columnas suspendidas para uso médico"},
    {prodId:"42191806",label:"Barras de trapecio para uso clínico"},
    {prodId:"42191807",label:"Camas o accesorios de cuidado del paciente para uso general"},
    {prodId:"42191808",label:"Camas o accesorios de cuidado del paciente para cuidado especial"},
    {prodId:"42191809",label:"Almohadillas o bombas de presión alterna"},
    {prodId:"42191810",label:"Colchones o accesorios para el cuidado del paciente"},
    {prodId:"42191811",label:"Cunas de posicionamiento para bebés"},
    {prodId:"42191812",label:"Kits de suministros de incubadoras para bebés"},
    {prodId:"42191813",label:"Forros de catre para el cuidado del paciente"},
    {prodId:"42191814",label:"Accesorios para incubadora o calentadora de bebés para uso clínico"},
    {prodId:"42191900",label:"Armarios clínicos"},
    {prodId:"42191901",label:"Gabinetes clínicos para junto a la cama o accesorios"},
    {prodId:"42191902",label:"Armarios para uso hospitalario"},
    {prodId:"42191903",label:"Gabinetes de monitoreo para uso médico"},
    {prodId:"42191904",label:"Gabinetes o cajas fuertes para narcóticos"},
    {prodId:"42191905",label:"Gabinetes de tratamiento para uso médico"},
    {prodId:"42191906",label:"Gabinetes calentadores de cobijas o soluciones"},
    {prodId:"42191907",label:"Gabinetes o muebles de almacenamiento de instrumentos para uso médico"},
    {prodId:"42191908",label:"Carritos de historias clínicas o accesorios"},
    {prodId:"42191909",label:"Gabinete computarizado dispensador de medicamentos"},
    {prodId:"42192000",label:"Mesas de examen o de procedimiento clínico"},
    {prodId:"42192001",label:"Mesas de examen o procedimientos médicos para uso general"},
    {prodId:"42192002",label:"Accesorios para mesas de examen o procedimientos médicos para uso general excluyendo sábanas para cubrirlas"},
    {prodId:"42192003",label:"Mesas para masajes de terapia física"},
    {prodId:"42192100",label:"Asientos y taburetes clínicos y productos relacionados"},
    {prodId:"42192101",label:"Asientos o accesorios para sacar sangre o flebotomía"},
    {prodId:"42192102",label:"Reclinadoras o accesorios para uso hospitalario"},
    {prodId:"42192103",label:"Asientos para pacientes"},
    {prodId:"42192104",label:"Taburetes médicos y accesorios"},
    {prodId:"42192106",label:"Asientos para visitantes de instalaciones médicas"},
    {prodId:"42192107",label:"Asientos de examen clínico o accesorios"},
    {prodId:"42192200",label:"Productos para transporte de paciente"},
    {prodId:"42192201",label:"Camillas con ruedas o accesorios para el transporte de pacientes"},
    {prodId:"42192202",label:"Elevadores de camillas o de tijeras"},
    {prodId:"42192203",label:"Asientos geriátricos o accesorios"},
    {prodId:"42192204",label:"Incubadoras para el transporte de pacientes o accesorios"},
    {prodId:"42192205",label:"Accesorios para carritos de pacientes"},
    {prodId:"42192206",label:"Carritos de pacientes"},
    {prodId:"42192207",label:"Camillas para pacientes o accesorios para camillas"},
    {prodId:"42192208",label:"Accesorios para sillas de ruedas"},
    {prodId:"42192209",label:"Rampas para sillas de ruedas"},
    {prodId:"42192210",label:"Sillas de ruedas"},
    {prodId:"42192211",label:"Tablas para mover pacientes o sus accesorios"},
    {prodId:"42192212",label:"Esterilla o sábana para transferencia de pacientes"},
    {prodId:"42192213",label:"Unidad de sistema de calefacción para mantener o evacuar pacientes o accesorios"},
    {prodId:"42192214",label:"Elevador de sillas de ruedas"},
    {prodId:"42192300",label:"Elevadores de paciente"},
    {prodId:"42192301",label:"Elevadores de pacientes o accesorios"},
    {prodId:"42192302",label:"Elevadores hidráulicos o accesorios para uso clínico"},
    {prodId:"42192303",label:"Asientos suspendidos o eslingas para pacientes"},
    {prodId:"42192304",label:"Montacargas de techo para pacientes"},
    {prodId:"42192305",label:"Eslingas para bebés o accesorios"},
    {prodId:"42192400",label:"Transporte de equipo médico y traslado de productos"},
    {prodId:"42192401",label:"Carritos de emergencia o resucitación"},
    {prodId:"42192402",label:"Carritos específicos para equipos de diagnóstico o monitoreo"},
    {prodId:"42192403",label:"Carritos de aislamiento para uso médico"},
    {prodId:"42192404",label:"Carritos o accesorios para uso médico"},
    {prodId:"42192405",label:"Puestos móviles de irrigación"},
    {prodId:"42192406",label:"Carritos para transportar orinales"},
    {prodId:"42192500",label:"Protectores para equipo médico"},
    {prodId:"42192501",label:"Forros para equipos médicos"},
    {prodId:"42192502",label:"Bolsas para equipos médicos"},
    {prodId:"42192600",label:"Dispositivos de dosificación y medición de medicamentos y suministros"},
    {prodId:"42192601",label:"Moldes de supositorios"},
    {prodId:"42192602",label:"Dispensadores de pastillas o medicamentos o accesorios"},
    {prodId:"42192603",label:"Tazas o botellas para administrar medicamentos o accesorios"},
    {prodId:"42192604",label:"Sistemas o accesorios para la entrega de drogas"},
    {prodId:"42192605",label:"Kits de encapsulamiento de aneurismas"},
    {prodId:"42192606",label:"Soplador de polvos"},
    {prodId:"42201500",label:"Sistemas de tomografía informatizada (TAC o CT) médica y productos relacionados"},
    {prodId:"42201501",label:"Instalación de unidad estacionaria completa para tomografía computarizada ct o cat para uso médico"},
    {prodId:"42201502",label:"Unidades móviles o transportables o unidad de camión para tomografía computarizada ct o cat para uso médico"},
    {prodId:"42201503",label:"Componentes de sistema tridimensional para tomografía computarizada ct o cat para uso médico"},
    {prodId:"42201504",label:"Componentes de hueso de contenido mineral para tomografía computarizada ct o cat para uso médico"},
    {prodId:"42201505",label:"Consolas para tomografía computarizada ct o cat para uso médico"},
    {prodId:"42201507",label:"Componentes de sistema helicoidal para tomografía computarizada ct o cat para uso médico"},
    {prodId:"42201508",label:"Monitores para tomografía computarizada ct o cat para uso médico"},
    {prodId:"42201509",label:"Acondicionadores de energía para tomografía computarizada ct o cat para uso médico"},
    {prodId:"42201510",label:"Simuladores o dispositivos de aseguramiento de calidad o de calibración para tomografía computarizada ct o cat para uso médico"},
    {prodId:"42201511",label:"Escáneres o tubos para tomografía computarizada ct o cat para uso médico"},
    {prodId:"42201512",label:"Mesas o puestos o asientos para tomografía computarizada ct o cat para uso médico"},
    {prodId:"42201513",label:"Componentes de sistema ultra rápido para tomografía computarizada ct o cat para uso médico"},
    {prodId:"42201700",label:"Productos de hacer imágenes de ultrasonido médico y de doppler y de eco"},
    {prodId:"42201701",label:"Ultrasonido cardiaco o doppler o unidades de eco o cardioscopios"},
    {prodId:"42201702",label:"Ultrasonido o unidades de eco fetales o ginecológicas"},
    {prodId:"42201703",label:"Ultrasonido o unidades de eco mamográficas"},
    {prodId:"42201704",label:"Densitómetros de hueso por ultrasonido para uso médico"},
    {prodId:"42201705",label:"Forros para ultrasonido o doppler o eco sonda para uso médico"},
    {prodId:"42201706",label:"Sondas para ultrasonido o doppler o eco para uso médico"},
    {prodId:"42201707",label:"Calentadores de gel para ultrasonido o doppler o eco para uso médico"},
    {prodId:"42201708",label:"Geles para ultrasonido o doppler o eco para uso médico"},
    {prodId:"42201709",label:"Monitores para ultrasonido o doppler o eco para uso médico"},
    {prodId:"42201710",label:"Impresoras para ultrasonido o doppler o eco para uso médico"},
    {prodId:"42201711",label:"Transductores o accesorios para ultrasonido o doppler o eco para uso médico"},
    {prodId:"42201712",label:"Unidades de ultrasonido o doppler o eco pulso o ecografía de diagnóstico general para uso médico"},
    {prodId:"42201713",label:"Componentes tridimensionales para ultrasonido o doppler o eco para uso médico"},
    {prodId:"42201714",label:"Tensiómetros"},
    {prodId:"42201715",label:"Sondas o accesorios para ultrasonido o eco vaginal"},
    {prodId:"42201716",label:"Unidades de ultrasonido vascular"},
    {prodId:"42201717",label:"Almohadillas de transmisión de ultrasonido para uso médico"},
    {prodId:"42201718",label:"Escáneres oftálmicos de ultrasonido para uso médico"},
    {prodId:"42201719",label:"Lociones de escáner por ultrasonido para uso médico"},
    {prodId:"42201800",label:"Productos radiográficos de diagnóstico médico"},
    {prodId:"42201801",label:"Películas de rayos x para cardiología"},
    {prodId:"42201802",label:"Equipos de rayos x para cardiología"},
    {prodId:"42201803",label:"Equipos de rayos x para mamografías"},
    {prodId:"42201804",label:"Unidades de brazo c de rayos x para uso médico"},
    {prodId:"42201805",label:"Equipo de cine fluoroscopio para uso médico"},
    {prodId:"42201806",label:"Equipo de radiología y fluoroscopia rf para uso médico"},
    {prodId:"42201807",label:"Escáneres de radioisótopos para uso médico"},
    {prodId:"42201808",label:"Rejillas móviles de rayos x para uso médico"},
    {prodId:"42201809",label:"Inyectores o accesorios de agentes de contraste para imágenes para uso médico"},
    {prodId:"42201810",label:"Casetes o película de rayos x de uso general para uso médico"},
    {prodId:"42201811",label:"Dispositivos de aseguramiento de calidad o calibración de rayos x para uso médico"},
    {prodId:"42201812",label:"Mesas o puestos o asientos o gabinetes o accesorios de rayos x para uso médico"},
    {prodId:"42201813",label:"Unidades de tomografía de rayos x para uso médico"},
    {prodId:"42201814",label:"Tubos de rayos x para uso médico"},
    {prodId:"42201815",label:"Unidades de rayos x de uso diagnóstico general para uso médico"},
    {prodId:"42201816",label:"Unidades de xerorradiografía para uso médico"},
    {prodId:"42201817",label:"Densitómetros de hueso de rayos x"},
    {prodId:"42201818",label:"Cuñas de pisada y trompos de combinación para equipo radiográfico"},
    {prodId:"42201819",label:"Colgadores o sus accesorios para películas de rayos x"},
    {prodId:"42201820",label:"Rejillas de equipo radiográfico para uso médico"},
    {prodId:"42201821",label:"Sujeta casetes de película radiográfica"},
    {prodId:"42201822",label:"Estuches o fundas o accesorios para equipos de rayos x para uso médico"},
    {prodId:"42201823",label:"Unidad de tubo y transformador de rayos x para uso médico"},
    {prodId:"42201824",label:"Sets de artrografía para uso médico"},
    {prodId:"42201825",label:"Insertos de tubo de aparatos de rayos x para uso médico"},
    {prodId:"42201826",label:"Kits de reparación de aparatos de rayos x para uso médico"},
    {prodId:"42201827",label:"Kits de reparación de carpa de cuarto oscuro de rayos x para uso médico"},
    {prodId:"42201828",label:"Filtros de aparatos de rayos x para uso médico"},
    {prodId:"42201829",label:"Localizadores radiográficos"},
    {prodId:"42201830",label:"Pantallas intensificadoras de rayos x para uso médico"},
    {prodId:"42201831",label:"Tapas de casetes o películas de rayos x para uso médico"},
    {prodId:"42201832",label:"Cubiertas de casetes o película radiográfica"},
    {prodId:"42201833",label:"Cambiadores de casetes o película radiográfica"},
    {prodId:"42201834",label:"Ensamblajes rectificadores de aparatos de rayos x radiográficos para uso médico"},
    {prodId:"42201835",label:"Ensamblajes de unidades de tubos de aparatos de rayos x para uso médico"},
    {prodId:"42201836",label:"Ensamblajes de bandas de compresión de aparatos de rayos x para uso médico"},
    {prodId:"42201837",label:"Enfriadores de agua de rayos x para uso médico"},
    {prodId:"42201838",label:"Catéteres o kits de catéteres de enteroclisis para uso médico"},
    {prodId:"42201839",label:"Bandejas de procedimientos de imágenes para uso médico"},
    {prodId:"42201840",label:"Dispositivos sellantes vasculares"},
    {prodId:"42201841",label:"Papeles de rayos x diagnósticos para uso médico"},
    {prodId:"42201843",label:"Marcador de piel de rayos x para uso médico"},
    {prodId:"42201844",label:"Regla diagnóstica de rayos x para uso médico"},
    {prodId:"42201845",label:"Funda o inserto o sobre para película de radiología"},
    {prodId:"42201846",label:"Colimador de rayos x radiográfico y fluoroscópico"},
    {prodId:"42201847",label:"Sistema móvil de rayos x"},
    {prodId:"42201848",label:"Sistema digital de imágenes de rayos x"},
    {prodId:"42201849",label:"Escáner fluorescente"},
    {prodId:"42201850",label:"Sistema de tomografía nuclear"},
    {prodId:"42201851",label:"Contador beta o gama para uso clínico"},
    {prodId:"42201852",label:"Pantalla fluorescente"},
    {prodId:"42203400",label:"Productos para hacer imágenes vasculares y para cardiología intervencionista y laboratorios de cateterización cardiaca"},
    {prodId:"42203401",label:"Stents coronarios"},
    {prodId:"42203402",label:"Catéteres o sets de diagnóstico o intervención vascular"},
    {prodId:"42203403",label:"Introductores de catéteres o sets de diagnóstico o intervención vascular"},
    {prodId:"42203404",label:"Alambre guía para imágenes vasculares"},
    {prodId:"42203405",label:"Catéter inflable para angioplastia"},
    {prodId:"42203406",label:"Dispositivos de remoción de catéteres o sets de diagnóstico o intervención vascular"},
    {prodId:"42203407",label:"Sets de entrega de medios de contraste para angiografía"},
    {prodId:"42203408",label:"Kits de fundas cardiovasculares"},
    {prodId:"42203409",label:"Valvulotomos angioscópicos"},
    {prodId:"42203410",label:"Funda de catéter cardiovascular"},
    {prodId:"42203411",label:"Carritos para catéteres"},
    {prodId:"42203412",label:"Stents periféricos"},
    {prodId:"42203413",label:"Bobina vascular"},
    {prodId:"42203414",label:"Filtro vascular"},
    {prodId:"42203415",label:"Equipo de laboratorio de cateterización cardíaca"},
    {prodId:"42203416",label:"Catéter de ablación cardíaca"},
    {prodId:"42203417",label:"Endógrafo"},
    {prodId:"42203418",label:"Catéter para arterectomía"},
    {prodId:"42203419",label:"Stents biliares"},
    {prodId:"42203420",label:"Lazo para imágenes vasculares"},
    {prodId:"42203421",label:"Bandejas o paquetes para diagnóstico o intervención vascular"},
    {prodId:"42203422",label:"Pegante de embolización"},
    {prodId:"42203423",label:"Esfera de embolización"},
    {prodId:"42203424",label:"Dispositivo de cierre de sitio de procedimiento de cateterización"},
    {prodId:"42203425",label:"Catéter para embolectomía trombectomía"},
    {prodId:"42203426",label:"Dispositivo de torsión de alambre de guía para angiografía"},
    {prodId:"42203427",label:"Aguja de introducción para angiografía"},
    {prodId:"42203428",label:"Válvula de control y válvula hemostática para angiografía"},
    {prodId:"42203429",label:"Catéter para pericardiocentesis"},
    {prodId:"42203430",label:"Catéter de mapeo para electrofisiología"},
    {prodId:"42203500",label:"Marcapasos cardiacos o productos relacionados"},
    {prodId:"42203501",label:"Generador de marcapasos cardíaco o marcapasos de terapia de re sincronización cardíaca"},
    {prodId:"42203502",label:"Cables o electrodos o accesorios para marcapasos cardíacos"},
    {prodId:"42203503",label:"Introductores de cables o sets para marcapasos cardíacos"},
    {prodId:"42203504",label:"Grabadora cardíaca"},
    {prodId:"42203505",label:"Desfibrilador cardiovertidor implantable icd o desfibrilador para terapia de re sincronización cardíaca crt-d"},
    {prodId:"42203506",label:"Cables o electrodos de desfibrilador cardiovertidor implantable icd o desfibrilador para terapia de re sincronización cardíaca crt-d"},
    {prodId:"42203600",label:"Productos de archivar y información de toma de imágenes radiológicos médicos"},
    {prodId:"42203601",label:"Equipos de sistema de red de imágenes digitales de defensa din"},
    {prodId:"42203602",label:"Equipos de sistema estándar de comunicaciones de imágenes digitales en medicina dicom"},
    {prodId:"42203603",label:"Sistemas de computador para archivo de fotografías médicas pacs"},
    {prodId:"42203604",label:"Hardware de sistema de archivo de película de rayos x para usos médicos"},
    {prodId:"42203605",label:"Software de sistema de archivo de película de rayos x para usos médicos"},
    {prodId:"42203606",label:"Sistemas de telerradiografía"},
    {prodId:"42203700",label:"Suministros y equipo de procesado de toma de imágenes médicos"},
    {prodId:"42203701",label:"Estampadores de luz del día para películas de rayos x o impresoras de identificación para uso médico"},
    {prodId:"42203702",label:"Procesadores para luz del día o cuarto oscuro de imágenes médicas"},
    {prodId:"42203703",label:"Cajas de paso de seguridad de rayos x para uso médico"},
    {prodId:"42203704",label:"Toners o desarrolladores para uso médico"},
    {prodId:"42203705",label:"Impresoras láser en seco para imágenes o reproductores de imágenes para uso médico"},
    {prodId:"42203706",label:"Kits de químicos para procesar películas de rayos x de uso médico"},
    {prodId:"42203707",label:"Equipos o suministros de cuarto oscuro de rayos x para uso médico"},
    {prodId:"42203708",label:"Fijadores para procesar película de rayos x para uso médico"},
    {prodId:"42203709",label:"Sets de calibradores para sistemas de combinación de desplegadores e impresoras de rayos x"},
    {prodId:"42203710",label:"Marcadores de películas de rayos x para uso médico"},
    {prodId:"42204000",label:"Productos de protección y resguardo radiológico médico"},
    {prodId:"42204001",label:"Estantes para delantales de protección radiológica para uso médico"},
    {prodId:"42204002",label:"Delantales o máscaras o cortinas de protección radiológica para uso médico"},
    {prodId:"42204003",label:"Contenedores portátiles de protección para materiales radiológicos radiactivos para uso médico"},
    {prodId:"42204004",label:"Auriculares de protección radiológica para uso médico"},
    {prodId:"42204005",label:"Guantes de protección radiológica para uso médico"},
    {prodId:"42204006",label:"Pantallas de pie o portátiles de protección radiológica para uso médico"},
    {prodId:"42204007",label:"Paneles instalados de piso o techo o pared de protección radiológica para uso médico"},
    {prodId:"42204008",label:"Cámaras o habitaciones o cajas fuertes de protección radiológica para uso médico"},
    {prodId:"42204009",label:"Anteojos de protección radiológica para uso médico"},
    {prodId:"42211500",label:"Ayuda de ambulación y traslado y posicionar para personas físicamente discapacitadas"},
    {prodId:"42211501",label:"Bastones o accesorios para bastones"},
    {prodId:"42211502",label:"Muletas o accesorios para muletas"},
    {prodId:"42211503",label:"Dispositivos de posicionamiento"},
    {prodId:"42211504",label:"Levantadores o ayudas para ponerse de pie"},
    {prodId:"42211505",label:"Accesorios para caminadores o andadores"},
    {prodId:"42211506",label:"Caminadores o andadores"},
    {prodId:"42211507",label:"Dispositivos de cuerpo entero para deslizarse o voltearse"},
    {prodId:"42211508",label:"Dispositivos o accesorios de movilidad multifuncional"},
    {prodId:"42211509",label:"Cascos protectores de cabeza o cara o dispositivos o accesorios para los discapacitados físicamente"},
    {prodId:"42211600",label:"Baño y ayudas de baño para personas físicamente discapacitadas"},
    {prodId:"42211601",label:"Tablas para bañarse para los discapacitados físicamente"},
    {prodId:"42211602",label:"Cepillos de baño o esponjas o estropajos para los discapacitados físicamente"},
    {prodId:"42211603",label:"Elevadores para baño o accesorios para los discapacitados físicamente"},
    {prodId:"42211604",label:"Guantes de baño para los discapacitados físicamente"},
    {prodId:"42211605",label:"Almohadas de baño para los discapacitados físicamente"},
    {prodId:"42211606",label:"Cómodas o accesorios para los discapacitados físicamente"},
    {prodId:"42211607",label:"Asientos de inodoro elevados para los discapacitados físicamente"},
    {prodId:"42211608",label:"Barras de sujeción o barandas para la tina para los discapacitados físicamente"},
    {prodId:"42211610",label:"Asientos o sillas para la ducha o el baño para los discapacitados físicamente"},
    {prodId:"42211611",label:"Baños de asiento para los discapacitados físicamente"},
    {prodId:"42211612",label:"Soportes de brazos para el inodoro para los discapacitados físicamente"},
    {prodId:"42211613",label:"Marcos para el inodoro para los discapacitados físicamente"},
    {prodId:"42211614",label:"Asientos de inodoro para los discapacitados físicamente"},
    {prodId:"42211615",label:"Levantadores de asientos de inodoro para los discapacitados físicamente"},
    {prodId:"42211616",label:"Ayudas de higiene o estimulación de tocador para los discapacitados físicamente"},
    {prodId:"42211617",label:"Bancos de transferencia para los discapacitados físicamente"},
    {prodId:"42211618",label:"Alarma de pulso de mojada en la cama para los discapacitados físicamente"},
    {prodId:"42211619",label:"Jabón protector de ojos y oídos para los discapacitados físicamente"},
    {prodId:"42211620",label:"Bandeja o contenedor de champú para los discapacitados físicamente"},
    {prodId:"42211800",label:"Ayuda para vestirse y asearse para personas físicamente discapacitadas"},
    {prodId:"42211801",label:"Ganchos para abotonarse para los discapacitados físicamente"},
    {prodId:"42211802",label:"Kits para vestirse para los discapacitados físicamente"},
    {prodId:"42211803",label:"Palos para vestirse para los discapacitados físicamente"},
    {prodId:"42211804",label:"Cepillos de pelo o peinillas para los discapacitados físicamente"},
    {prodId:"42211805",label:"Espejo de inspección para los discapacitados físicamente"},
    {prodId:"42211806",label:"Aplicadores de lociones para los discapacitados físicamente"},
    {prodId:"42211807",label:"Cuidado de la boca para los discapacitados físicamente"},
    {prodId:"42211808",label:"Cortaúñas o limas para los discapacitados físicamente"},
    {prodId:"42211809",label:"Ganchos para sujetarse los pantalones para los discapacitados físicamente"},
    {prodId:"42211810",label:"Calzadores para los discapacitados físicamente"},
    {prodId:"42211811",label:"Hormas de zapatos para los discapacitados físicamente"},
    {prodId:"42211812",label:"Media o ayudas para apilar para los discapacitados físicamente"},
    {prodId:"42211813",label:"Jaladores de cremalleras para los discapacitados físicamente"},
    {prodId:"42211900",label:"Ayuda de preparación de comida y bebida y alimentos para personas físicamente discapacitadas"},
    {prodId:"42211901",label:"Materiales anti resbalón para los discapacitados físicamente"},
    {prodId:"42211902",label:"Dispositivos de ayuda para cocinar para los discapacitados físicamente"},
    {prodId:"42211903",label:"Abre latas para los físicamente discapacitados"},
    {prodId:"42211904",label:"Picadores para los discapacitados físicamente"},
    {prodId:"42211905",label:"Tazas o tazones (mugs) para los discapacitados físicamente"},
    {prodId:"42211906",label:"Sujetadores de cubiertos o de utensilios para los discapacitados físicamente"},
    {prodId:"42211907",label:"Cubiertos o utensilios para los discapacitados físicamente"},
    {prodId:"42211908",label:"Tablas para cortar o pelar para los discapacitados físicamente"},
    {prodId:"42211909",label:"Sujetadores de bebida para los discapacitados físicamente"},
    {prodId:"42211910",label:"Baberos o recogedores de comida para los discapacitados físicamente"},
    {prodId:"42211911",label:"Forros para comida para los discapacitados físicamente"},
    {prodId:"42211912",label:"Sujetadores para dispositivos de cocina para los discapacitados físicamente"},
    {prodId:"42211913",label:"Temporizadores digitales jumbo para los discapacitados físicamente"},
    {prodId:"42212200",label:"Auxilio de manejo de medicamentos para personas físicamente discapacitadas"},
    {prodId:"42212201",label:"Trituradores o partidores de píldoras para los discapacitados físicamente"},
    {prodId:"42212202",label:"Organizadores de píldoras para los discapacitados físicamente"},
    {prodId:"42212203",label:"Recordadores de píldoras para los discapacitados físicamente"},
    {prodId:"42212204",label:"Exprimidores de tubos para los discapacitados físicamente"},
    {prodId:"42212300",label:"Ayuda para llegar o agarrar para personas físicamente discapacitadas"},
    {prodId:"42212301",label:"Materiales o dispositivos de agarre para los discapacitados físicamente"},
    {prodId:"42212302",label:"Sujetadores para los discapacitados físicamente"},
    {prodId:"42212303",label:"Abridores de contenedores para los discapacitados físicamente"},
    {prodId:"42212304",label:"Alcanzadores para los discapacitados físicamente"},
    {prodId:"42221500",label:"Accesorios y catéteres y cánulas intravenosas y arteriales"},
    {prodId:"42221501",label:"Catéteres de línea arterial"},
    {prodId:"42221502",label:"Válvulas de descarga de catéteres arteriales de línea continua"},
    {prodId:"42221503",label:"Catéteres venosos centrales"},
    {prodId:"42221504",label:"Catéteres intravenosos periféricos para uso general"},
    {prodId:"42221505",label:"Catéteres pediátricos o intravenosos para venas del cuero cabelludo o arteriales"},
    {prodId:"42221506",label:"Catéteres umbilicales"},
    {prodId:"42221507",label:"Kits de arranque intravenoso o arterial sin catéter"},
    {prodId:"42221508",label:"Kits de cuidado de la piel para catéter arterial o intravenoso"},
    {prodId:"42221509",label:"Bandejas para catéteres intravenosos o arteriales"},
    {prodId:"42221512",label:"Cánulas intravenosas o arteriales y accesorios"},
    {prodId:"42221513",label:"Kits de cateterización cardiovascular"},
    {prodId:"42221514",label:"Catéter de arteria pulmonar"},
    {prodId:"42221515",label:"Kit de reparación de catéter venoso central"},
    {prodId:"42221516",label:"Oclusor de la punta del catéter"},
    {prodId:"42221517",label:"Catéter para hemodiálisis"},
    {prodId:"42221518",label:"Material de vendaje de férulas"},
    {prodId:"42221600",label:"Tubería y juegos de administración arterial o intravenoso y productos relacionados"},
    {prodId:"42221601",label:"Detectores de burbujas de aire para administración arterial o intravenosa"},
    {prodId:"42221602",label:"Puertos o lugares de inyección o tapas o protectores para administración arterial o intravenosa"},
    {prodId:"42221603",label:"Tubos de extensión arteriales o intravenosos"},
    {prodId:"42221604",label:"Adaptadores o conectores o candados o tapas o protectores para tubos arteriales o intravenosos"},
    {prodId:"42221605",label:"Válvulas de chequeo de tubos arteriales o intravenosos"},
    {prodId:"42221606",label:"Pinzas de tubos intravenosos o arteriales"},
    {prodId:"42221607",label:"Filtros o pantallas para tubos arteriales o intravenosos de uso general"},
    {prodId:"42221608",label:"Etiquetas o cintas de identificación de tubos intravenosos o arteriales"},
    {prodId:"42221609",label:"Sets de administración de tubos intravenosos o arteriales"},
    {prodId:"42221610",label:"Puertos o tapas o protectores de entrada de espiga de tubos intravenosos"},
    {prodId:"42221611",label:"Espigas o tapas o protectores de tubos intravenosos"},
    {prodId:"42221612",label:"Puertos de inyección o llaves de paso o colectores sin aguja de tubos arteriales o intravenosos"},
    {prodId:"42221613",label:"Cierres o broches de extensión de transferencia de tubos intravenosos"},
    {prodId:"42221614",label:"Kits de administración de tubos intravenosos con catéter"},
    {prodId:"42221615",label:"Tubos intravenosos de medicación secundaria"},
    {prodId:"42221616",label:"Sets de extensión arterial o intravenosa"},
    {prodId:"42221617",label:"Calentadores de resistencia para agujas"},
    {prodId:"42221618",label:"Bandejas para procedimientos arteriales o intravenosos"},
    {prodId:"42221619",label:"Kit colector de inyecciones sin aguja"},
    {prodId:"42221700",label:"Bolsas de infusión y recipientes y productos relacionados intravenosos y arteriales"},
    {prodId:"42221701",label:"Bolsas o contenedores de infusión arterial o intravenosa de único puerto"},
    {prodId:"42221702",label:"Bolsas o contenedores de transferencia de infusión arterial o intravenosa"},
    {prodId:"42221703",label:"Calentadores de fluido de infusión arterial o intravenosa"},
    {prodId:"42221704",label:"Bolsas de infusión de presión arterial o intravenosa"},
    {prodId:"42221705",label:"Ensamblajes de vial de infusión de analgésicos"},
    {prodId:"42221706",label:"Picos de bolsas o contenedores de transferencia de infusión arterial o intravenosa"},
    {prodId:"42221707",label:"Sets o kits de infusión de analgésicos"},
    {prodId:"42221900",label:"Productos de regulación y medida del flujo intravenoso o arterial"},
    {prodId:"42221901",label:"Flujómetros o reguladores intravenosos calibrados por dial"},
    {prodId:"42221902",label:"Contadores o reguladores de goteo intravenoso"},
    {prodId:"42221903",label:"Detectores ultrasónicos de flujo de sangre"},
    {prodId:"42221904",label:"Flujómetro de sangre electromagnético"},
    {prodId:"42221905",label:"Flujómetro de sangre láser"},
    {prodId:"42221906",label:"Flujómetro de sangre de resonancia magnética"},
    {prodId:"42222000",label:"Bombas de infusión intravenosa, así como sus analizadores, sensores y accesorios"},
    {prodId:"42222001",label:"Bombas de infusión intravenosa de uso general"},
    {prodId:"42222002",label:"Bombas de infusión de jeringa intravenosa"},
    {prodId:"42222003",label:"Bombas de infusión intravenosa multicanal"},
    {prodId:"42222004",label:"Bombas de infusión controlada de analgésicos para pacientes"},
    {prodId:"42222005",label:"Partes o accesorios de bombas intravenosas"},
    {prodId:"42222006",label:"Analizadores o sensores de bombas de infusión intravenosas"},
    {prodId:"42222007",label:"Transductores de bombas de infusión intravenosas"},
    {prodId:"42222008",label:"Kits o accesorios de bombas de infusión"},
    {prodId:"42222009",label:"Actuador de jeringa para un inyector"},
    {prodId:"42222100",label:"Sistemas de transporte y suspensión de equipo arterial y intravenoso"},
    {prodId:"42222101",label:"Colgadores de equipos de línea arterial o intravenosa"},
    {prodId:"42222102",label:"Rieles o colgadores montados para sistemas de infusión intravenosa por gravedad"},
    {prodId:"42222103",label:"Postes de infusión intravenosa para sillas de ruedas"},
    {prodId:"42222104",label:"Postes o puestos de línea arterial o intravenosa"},
    {prodId:"42222300",label:"Productos de transfusión y administración de sangre"},
    {prodId:"42222301",label:"Kits de administración de transfusión de sangre"},
    {prodId:"42222302",label:"Filtros o accesorios o pantallas para transfusión de sangre"},
    {prodId:"42222303",label:"Sistemas de identificación para administración o transfusión de sangre"},
    {prodId:"42222304",label:"Tubos para administración o transfusión de sangre"},
    {prodId:"42222305",label:"Pinzas para tubos de administración o transfusión de sangre"},
    {prodId:"42222306",label:"Sistemas de recolección de desechos para administración o transfusión de sangre"},
    {prodId:"42222307",label:"Sistemas de calentamiento o transfusión de sangre"},
    {prodId:"42222308",label:"Bolsas o contenedores para administración o transfusión de sangre"},
    {prodId:"42222309",label:"Sistemas de conservación para administración o transfusión de sangre"},
    {prodId:"42231500",label:"Suministros y equipo de alimentación enteral"},
    {prodId:"42231501",label:"Bombas de infusión para alimentación enteral"},
    {prodId:"42231502",label:"Sets de administración de alimentación enteral"},
    {prodId:"42231503",label:"Sets o bandejas de irrigación para alimentación enteral"},
    {prodId:"42231504",label:"Bolsas o contenedores para nutrición enteral"},
    {prodId:"42231505",label:"Adaptadores o conectores o extensiones para alimentación enteral"},
    {prodId:"42231506",label:"Dispositivos para asegurar tubos naso entéricos"},
    {prodId:"42231507",label:"Cepillos para limpiar tubos enterales"},
    {prodId:"42231508",label:"Válvulas de fijación de alimentación enteral"},
    {prodId:"42231509",label:"Cámaras de pesaje de nutrición enteral"},
    {prodId:"42231510",label:"Sets de tubos de bombeo de infusión para alimentación enteral"},
    {prodId:"42231600",label:"Accesorios o dispositivos de acceso de jejunostomía y gastronomía"},
    {prodId:"42231601",label:"Tubos de gastrostomía para uso general"},
    {prodId:"42231602",label:"Tubos para gastrostomía endoscópica percutánea"},
    {prodId:"42231603",label:"Tubos para yeyunostomía"},
    {prodId:"42231604",label:"Botones de acceso gástrico"},
    {prodId:"42231605",label:"Sujetadores de tubos de gastrostomía o yeyunostomía"},
    {prodId:"42231606",label:"Kits de descompresión del colon"},
    {prodId:"42231608",label:"Sets de catéter y aguja para yeyunostomía"},
    {prodId:"42231609",label:"Kits de tubos de alimentación para gastrostomía"},
    {prodId:"42231800",label:"Fórmulas y productos para apoyo nutritivo"},
    {prodId:"42231801",label:"Fórmulas de suplementos para adultos de uso general"},
    {prodId:"42231802",label:"Fórmulas de suplementos pediátricos"},
    {prodId:"42231803",label:"Fórmulas de suplementos específicos para enfermedades de adultos"},
    {prodId:"42231804",label:"Fórmulas de suplementos específicos para enfermedades pediátricas"},
    {prodId:"42231805",label:"Barras nutricionales o pudín u otros suplementos"},
    {prodId:"42231806",label:"Espesantes de comidas o bebidas nutricionales para uso médico"},
    {prodId:"42231807",label:"Chupos de teteros"},
    {prodId:"42231808",label:"Botellas de alimentación o accesorios"},
    {prodId:"42231900",label:"Equipo y suministros de lactancia materna"},
    {prodId:"42231901",label:"Bombas de seno o sus accesorios"},
    {prodId:"42231902",label:"Conchas o protectores de senos"},
    {prodId:"42231903",label:"Kits de bombas de senos"},
    {prodId:"42231904",label:"Almohada para lactancia"},
    {prodId:"42232000",label:"Trituradores de pastillas, cortapastillas y productos relacionados"},
    {prodId:"42232001",label:"Pulverizadores de tabletas o accesorios"},
    {prodId:"42232002",label:"Dispensadores o accesorios de pulverizadores de tabletas"},
    {prodId:"42232003",label:"Cortadores de tabletas o accesorios"},
    {prodId:"42241500",label:"Suministros de vaciado de escayola y tablillas"},
    {prodId:"42241501",label:"Zapatos de yeso"},
    {prodId:"42241502",label:"Materiales para acolchonar yesos o tablillas"},
    {prodId:"42241503",label:"Protectores de yesos o tablillas"},
    {prodId:"42241504",label:"Media o revestimientos para yeso o tablillas"},
    {prodId:"42241505",label:"Rollos o cintas para enyesar para uso ortopédico"},
    {prodId:"42241506",label:"Material de yeso para tablillas para uso ortopédico"},
    {prodId:"42241507",label:"Sistemas de tablillas ortopédicas"},
    {prodId:"42241509",label:"Componentes de ortesis termoplástica"},
    {prodId:"42241510",label:"Kits o materiales para tablillas termoplásticas"},
    {prodId:"42241511",label:"Sets de tablillas de tracción"},
    {prodId:"42241512",label:"Materiales de unión de yesos o tablillas"},
    {prodId:"42241513",label:"Estuches para transportar y almacenar tablillas o tablillas pre cortadas o sistemas de tablillas"},
    {prodId:"42241514",label:"Rollos de instrumentos para sets o accesorios de tablillas"},
    {prodId:"42241515",label:"Estuches para tablillas o accesorios"},
    {prodId:"42241516",label:"Marcador para material de entablillado"},
    {prodId:"42241517",label:"Esparcidor de yeso"},
    {prodId:"42241600",label:"Equipo y piezas y accesorios de vaciado de escayola"},
    {prodId:"42241601",label:"Carritos para yeso o tablillas"},
    {prodId:"42241602",label:"Cortadores o sierras para yeso"},
    {prodId:"42241603",label:"Sistemas de remoción de yeso"},
    {prodId:"42241604",label:"Vacíos de yeso"},
    {prodId:"42241606",label:"Bandejas de impresión de yeso"},
    {prodId:"42241607",label:"Puestos de yeso"},
    {prodId:"42241700",label:"Productos blandos ortopédicos para la extremidad inferior"},
    {prodId:"42241701",label:"Fieltros de recubrimiento ortopédico para tobillo o pie"},
    {prodId:"42241702",label:"Fieltros de recubrimiento ortopédico para cadera"},
    {prodId:"42241703",label:"Soportes de abrazaderas de rodilla o de abrazaderas de rodilla articuladas"},
    {prodId:"42241704",label:"Inmovilizadores o vendas artroscópicas para rodilla"},
    {prodId:"42241705",label:"Fieltros de recubrimiento ortopédico para pierna o accesorios"},
    {prodId:"42241706",label:"Productos ortóticos o para el cuidado de los pies"},
    {prodId:"42241707",label:"Abrazaderas para caminar"},
    {prodId:"42241708",label:"Almohadas para fractura del fémur"},
    {prodId:"42241709",label:"Zapatos post operatorios o accesorios"},
    {prodId:"42241800",label:"Productos blando ortopédicos para el torso y extremidad superior"},
    {prodId:"42241801",label:"Fieltros de recubrimiento ortopédico para el brazo"},
    {prodId:"42241802",label:"Fieltros de recubrimiento ortopédico para la espalda o lumbares o para el sacro"},
    {prodId:"42241803",label:"Collares cervicales o abrazaderas para el cuello"},
    {prodId:"42241804",label:"Fieltros de recubrimiento ortopédico para la clavícula"},
    {prodId:"42241805",label:"Fieltros de recubrimiento ortopédico para el codo"},
    {prodId:"42241806",label:"Fieltros de recubrimiento ortopédico para el antebrazo o la muñeca o el pulgar"},
    {prodId:"42241807",label:"Fieltros de recubrimiento ortopédico para la mano o el dedo"},
    {prodId:"42241808",label:"Fieltros de recubrimiento ortopédico para las costillas o el abdomen"},
    {prodId:"42241809",label:"Fieltros de recubrimiento ortopédico para los hombros"},
    {prodId:"42241811",label:"Faja para hernias"},
    {prodId:"42242000",label:"Dispositivos, accesorios y suministros protésicos"},
    {prodId:"42242001",label:"Dispositivos prostéticos para las extremidades inferiores"},
    {prodId:"42242002",label:"Dispositivos prostéticos para las extremidades superiores"},
    {prodId:"42242003",label:"Dispositivos o accesorios de sujeción prostética"},
    {prodId:"42242004",label:"Media o forro prostético de muñón"},
    {prodId:"42242100",label:"Suministros y accesorios de tracción ortopédica"},
    {prodId:"42242101",label:"Suministros de tracción de brazo"},
    {prodId:"42242102",label:"Suministros de tracción de mano o dedos"},
    {prodId:"42242103",label:"Suministros de tracción de cabeza o cuello"},
    {prodId:"42242104",label:"Suministros de tracción de piernas"},
    {prodId:"42242105",label:"Carritos móviles de tracción"},
    {prodId:"42242106",label:"Suministros de tracción de pelvis o espalda"},
    {prodId:"42242107",label:"Suministros de terapia de tracción de perno"},
    {prodId:"42242108",label:"Hardware o pesas de tracción ortopédica"},
    {prodId:"42242109",label:"Fieltros de recubrimiento de tracción ortopédica para uso general"},
    {prodId:"42242300",label:"Equipo y suministros ortopédicos"},
    {prodId:"42242301",label:"Bombas de cicatriz ortopédica"},
    {prodId:"42242302",label:"Aplicaciones ortopédicas de miembro superior"},
    {prodId:"42251500",label:"Productos de terapia y evaluación sensoria y perceptual y dexteridad y cognitiva"},
    {prodId:"42251501",label:"Productos de enseñanza para vestirse"},
    {prodId:"42251502",label:"Productos para evaluación o pruebas cognitivas o de destreza o de percepción o sensoriales"},
    {prodId:"42251503",label:"Juegos terapéuticos"},
    {prodId:"42251504",label:"Tableros perforados o tableros de actividades de uso terapéutico"},
    {prodId:"42251505",label:"Rompecabezas de uso terapéutico"},
    {prodId:"42251506",label:"Cajas de decoración de uso terapéutico"},
    {prodId:"42251600",label:"Equipo y dispositivos de ejercicio de rehabilitación"},
    {prodId:"42251601",label:"Vigas de balance o de soporte o mecedoras para rehabilitación o terapia"},
    {prodId:"42251602",label:"Dispositivos para escalar para rehabilitación o terapia"},
    {prodId:"42251603",label:"Dispositivos o accesorios de moción pasiva continua cpm"},
    {prodId:"42251604",label:"Patines de ejercicio de extremidades para rehabilitación o terapia"},
    {prodId:"42251605",label:"Pedales de ejercicio para rehabilitación o terapia"},
    {prodId:"42251606",label:"Tablas para ejercicios sin gravedad para rehabilitación o terapia"},
    {prodId:"42251607",label:"Poleas o accesorios para rehabilitación o terapia"},
    {prodId:"42251608",label:"Bandas de ejercicio o masilla o tubos o accesorios para rehabilitación o terapia"},
    {prodId:"42251610",label:"Pelotas o accesorios terapéuticos"},
    {prodId:"42251611",label:"Dispositivos de movimiento vestibular para rehabilitación o terapia"},
    {prodId:"42251612",label:"Pesas o sets o accesorios para rehabilitación o terapia"},
    {prodId:"42251613",label:"Bandas caminadoras ejercitadoras para rehabilitación o terapia"},
    {prodId:"42251614",label:"Bolsas de arena o sets de bolsas de arena para rehabilitación o terapia"},
    {prodId:"42251615",label:"Cinturones de pesas o kits para rehabilitación o terapia"},
    {prodId:"42251616",label:"Vibradores eléctricos para rehabilitación o terapia"},
    {prodId:"42251617",label:"Asientos acolchonados o accesorios terapéuticos"},
    {prodId:"42251618",label:"Ejercitadoras de muñecas para rehabilitación o terapia"},
    {prodId:"42251619",label:"Arcilla cerámica terapéutica o accesorios"},
    {prodId:"42251620",label:"Esterillas o plataformas para rehabilitación o terapia"},
    {prodId:"42251700",label:"Productos para entrenamiento del modo de andar"},
    {prodId:"42251701",label:"Cinturones de manera de caminar para rehabilitación o terapia"},
    {prodId:"42251702",label:"Rampas de entrenamiento para rehabilitación o terapia"},
    {prodId:"42251703",label:"Escaleras de entrenamiento para rehabilitación o terapia"},
    {prodId:"42251705",label:"Barras paralelas para rehabilitación o terapia"},
    {prodId:"42251706",label:"Caminadoras o bicicletas o ejercitadoras de manera de caminar"},
    {prodId:"42251800",label:"Equipo para hacer dura el trabajo para rehabilitación o terapia"},
    {prodId:"42251801",label:"Tableros de salto o accesorios para rehabilitación o terapia"},
    {prodId:"42251802",label:"Cajas de levantar o accesorios para rehabilitación o terapia"},
    {prodId:"42251803",label:"Árboles de tubos o accesorios para rehabilitación o terapia"},
    {prodId:"42251804",label:"Carros de halar o empujar o accesorios para rehabilitación o terapia"},
    {prodId:"42251805",label:"Mesas o estaciones de trabajo o accesorios para rehabilitación o terapia"},
    {prodId:"42261600",label:"Suministros y equipo de autopsia"},
    {prodId:"42261601",label:"Recolectores de polvo de huesos"},
    {prodId:"42261602",label:"Bolsa para cadáver de uso médico"},
    {prodId:"42261604",label:"Apoya cabezas para autopsias"},
    {prodId:"42261605",label:"Tablas de cuerpo para autopsias"},
    {prodId:"42261606",label:"Balanzas colgantes para autopsias"},
    {prodId:"42261607",label:"Bolsas o contenedores para especímenes para autopsias"},
    {prodId:"42261608",label:"Kits de enfermedades infecciosas para autopsias"},
    {prodId:"42261609",label:"Cintas o brazaletes de identificación post mortem"},
    {prodId:"42261610",label:"Aspiradoras o tubos de vacío para recolección de fluidos para autopsias"},
    {prodId:"42261611",label:"Termómetros rectales post mortem"},
    {prodId:"42261612",label:"Dispositivos para enderezar dedos post mortem"},
    {prodId:"42261613",label:"Kits de construcción de tejidos de cadáveres"},
    {prodId:"42261800",label:"Equipo y suministros de transporte y almacenaje del cadáver"},
    {prodId:"42261801",label:"Gabinetes de almacenamiento de cadáveres"},
    {prodId:"42261805",label:"Cuartos refrigeradores para la morgue"},
    {prodId:"42261806",label:"Congeladores para morgues"},
    {prodId:"42261807",label:"Carritos de autopsia"},
    {prodId:"42261809",label:"Dispositivos de elevación o transferencia de cadáveres"},
    {prodId:"42261810",label:"Contenedores para transporte de cuerpos"},
    {prodId:"42261902",label:"Máscaras anti putrefacción"},
    {prodId:"42261903",label:"Kits o suministros de detección de sangre post mortem"},
    {prodId:"42261904",label:"Kits de recolección de evidencia biológica"},
    {prodId:"42262008",label:"Protectores de ojos"},
    {prodId:"42271500",label:"Productos para el control respiratorio"},
    {prodId:"42271501",label:"Monitores de apnea o accesorios"},
    {prodId:"42271502",label:"Monitores de gas arterial o accesorios"},
    {prodId:"42271503",label:"Monitores de óxido de carbono exhalado o suministros"},
    {prodId:"42271504",label:"Estetoscopios esofágicos"},
    {prodId:"42271505",label:"Kits de monitoreo respiratorio o sus accesorios"},
    {prodId:"42271506",label:"Monitores de oxígeno o suministros"},
    {prodId:"42271600",label:"Productos de comprobación y tratamiento de las funciones pulmonares"},
    {prodId:"42271601",label:"Pletismógrafos de cuerpo"},
    {prodId:"42271602",label:"Espirómetros o sus accesorios o suministros"},
    {prodId:"42271603",label:"Pantallas de función pulmonar para junto a la cama"},
    {prodId:"42271604",label:"Flujómetros de pico"},
    {prodId:"42271605",label:"Calculadoras de función pulmonar"},
    {prodId:"42271606",label:"Dispositivos de calibración pulmonar"},
    {prodId:"42271607",label:"Tubos de función pulmonar o accesorios"},
    {prodId:"42271608",label:"Productos de prueba de estrés pulmonar"},
    {prodId:"42271609",label:"Monitores de estudio de sueño o accesorios"},
    {prodId:"42271610",label:"Monitores transcutáneos o productos relacionados"},
    {prodId:"42271611",label:"Monitores de ventilación pulmonar"},
    {prodId:"42271612",label:"Analizadores o monitores de gas pulmonar"},
    {prodId:"42271613",label:"Monitores de presión pulmonar"},
    {prodId:"42271614",label:"Monitores de temperatura respiratoria"},
    {prodId:"42271615",label:"Neumotacos"},
    {prodId:"42271616",label:"Filtros de monitoreo de función pulmonar"},
    {prodId:"42271617",label:"Percusores de pecho"},
    {prodId:"42271618",label:"Estilógrafos grabadores para espirómetros"},
    {prodId:"42271619",label:"Entrenador de músculos de inhalación"},
    {prodId:"42271620",label:"Monitor de agua en los pulmones"},
    {prodId:"42271621",label:"Dispositivo de terapia de presión de exhalación positiva pep"},
    {prodId:"42271622",label:"Chaleco de despeje de vías aéreas"},
    {prodId:"42271700",label:"Sistemas y dispositivos de terapia de entrega de oxígeno"},
    {prodId:"42271701",label:"Cilindros de gas o dispositivos relacionados para uso médico"},
    {prodId:"42271702",label:"Concentradores de oxígeno"},
    {prodId:"42271703",label:"Mezcladores de aire de oxígeno"},
    {prodId:"42271704",label:"Temporizadores de oxígeno"},
    {prodId:"42271705",label:"Conectores o adaptadores de suministro de oxígeno"},
    {prodId:"42271706",label:"Compresores para terapia respiratoria"},
    {prodId:"42271707",label:"Sensores de flujo o reguladores o componentes"},
    {prodId:"42271708",label:"Máscaras de oxígeno o partes para uso médico"},
    {prodId:"42271709",label:"Cánulas nasales para uso médico"},
    {prodId:"42271710",label:"Catéteres nasales o kits de cateterización para uso médico"},
    {prodId:"42271711",label:"Capuchas para la cabeza para uso médico"},
    {prodId:"42271712",label:"Carpas de aerosol para uso médico"},
    {prodId:"42271713",label:"Cámaras hiperbáricas para uso médico"},
    {prodId:"42271714",label:"Incubadoras para uso médico"},
    {prodId:"42271715",label:"Tubos o conectores de oxígeno para uso médico"},
    {prodId:"42271716",label:"Estuches para insufladores nasales"},
    {prodId:"42271717",label:"Inhaladores o sets"},
    {prodId:"42271718",label:"Accesorios para productos de sistemas de entrega de oxígeno para terapia o sus suministros"},
    {prodId:"42271719",label:"Insuflador de oxígeno o sus accesorios"},
    {prodId:"42271720",label:"Convertidores de oxígeno líquido"},
    {prodId:"42271721",label:"Filtros de concentradores de oxígeno"},
    {prodId:"42271722",label:"Computador de toma de oxígeno"},
    {prodId:"42271800",label:"Productos de terapia aerosol y humedad respiratoria"},
    {prodId:"42271801",label:"Humidificadores o vaporizadores respiratorios"},
    {prodId:"42271802",label:"Nebulizadores o accesorios"},
    {prodId:"42271803",label:"Sets de transferencia para terapia respiratoria"},
    {prodId:"42271900",label:"Productos para la gestión de la vía aérea"},
    {prodId:"42271901",label:"Vías aéreas faríngeas"},
    {prodId:"42271902",label:"Tubos esofágicos"},
    {prodId:"42271903",label:"Tubos endotraqueales"},
    {prodId:"42271904",label:"Tubos de traqueotomía"},
    {prodId:"42271905",label:"Tubos endobronquiales"},
    {prodId:"42271906",label:"Kits de reparación de tubos endotraqueales o de traqueotomía"},
    {prodId:"42271907",label:"Productos de aspiradores respiratorios o accesorios"},
    {prodId:"42271908",label:"Sujetadores de vías aéreas artificiales"},
    {prodId:"42271909",label:"Accesorios de vías aéreas artificiales"},
    {prodId:"42271910",label:"Sets endotraqueales o de traqueotomía"},
    {prodId:"42271911",label:"Kits de manómetros respiratorios"},
    {prodId:"42271912",label:"Tubos nasofaríngeos"},
    {prodId:"42271913",label:"Vías aéreas faríngeas o kits de vías aéreas"},
    {prodId:"42271914",label:"Mordazas de presión de vías aéreas"},
    {prodId:"42271915",label:"Accesorios de traqueotomía"},
    {prodId:"42271916",label:"Faringómetro o accesorios"},
    {prodId:"42272000",label:"Suministros de intubación"},
    {prodId:"42272001",label:"Laringoscopios o accesorios"},
    {prodId:"42272002",label:"Aerosoles laríngeos"},
    {prodId:"42272003",label:"Bloques de mordida"},
    {prodId:"42272004",label:"Estiletes de intubación"},
    {prodId:"42272005",label:"Fórceps de intubación"},
    {prodId:"42272006",label:"Introductores"},
    {prodId:"42272007",label:"Herramientas de doblado"},
    {prodId:"42272008",label:"Sondas o guías de intubación"},
    {prodId:"42272009",label:"Detectores de dióxido de carbono para pacientes"},
    {prodId:"42272011",label:"Catéteres de succión o sus accesorios"},
    {prodId:"42272016",label:"Quillas laríngeas o accesorios"},
    {prodId:"42272017",label:"Componentes o accesorios de intubación"},
    {prodId:"42272200",label:"Ventiladores y accesorios de presión mecánica positiva"},
    {prodId:"42272201",label:"Máquinas respiradoras de presión positiva intermitente ippb"},
    {prodId:"42272202",label:"Máquinas de presión de aire positivo continuo no invasivo"},
    {prodId:"42272203",label:"Máquinas de doble nivel no invasivas"},
    {prodId:"42272204",label:"Ventiladores de transporte"},
    {prodId:"42272205",label:"Ventiladores para cuidados intensivos de adultos o pediátricos"},
    {prodId:"42272206",label:"Ventiladores para cuidado intensivo de bebés"},
    {prodId:"42272207",label:"Ventiladores de alta frecuencia"},
    {prodId:"42272208",label:"Ventiladores de cuidado en casa"},
    {prodId:"42272209",label:"Circuitos de ventiladores o de respiración"},
    {prodId:"42272210",label:"Bolsas de circuito de respiración"},
    {prodId:"42272211",label:"Productos de hiperventilación"},
    {prodId:"42272212",label:"Válvulas de presión de exhalación y positiva peep"},
    {prodId:"42272213",label:"Máscaras o correas de presión de vías aéreas positivas continuas cpap"},
    {prodId:"42272214",label:"Conectores o adaptadores o válvulas de circuitos"},
    {prodId:"42272215",label:"Suministros de chequeo de ventiladores"},
    {prodId:"42272216",label:"Termómetros de ventiladores"},
    {prodId:"42272217",label:"Trampas de agua para ventiladores"},
    {prodId:"42272218",label:"Puertos o líneas de muestreo de gas para ventiladores"},
    {prodId:"42272219",label:"Intercambiadores o filtros de calor o humedad para ventiladores"},
    {prodId:"42272220",label:"Accesorios para ventiladores"},
    {prodId:"42272221",label:"Productos de humidificación para ventiladores"},
    {prodId:"42272222",label:"Productos de retiro del tubo para ventiladores"},
    {prodId:"42272223",label:"Accesorios o suministros para respiradores"},
    {prodId:"42272224",label:"Kits de circuitos para ventiladores"},
    {prodId:"42272225",label:"Accesorios de presión para vías aéreas positivas de doble nivel bi pap"},
    {prodId:"42272300",label:"Suministros de resucitadores"},
    {prodId:"42272301",label:"Resucitadores manuales"},
    {prodId:"42272302",label:"Resucitadores neumáticos"},
    {prodId:"42272303",label:"Máscaras o accesorios de resucitación"},
    {prodId:"42272304",label:"Componentes o accesorios de resucitación"},
    {prodId:"42272305",label:"Conectores de resucitación"},
    {prodId:"42272306",label:"Kits de resucitación"},
    {prodId:"42272307",label:"Estuches para aparatos o accesorios de resucitación"},
    {prodId:"42272500",label:"Aparatos de anestesia, y accesorios y suministros"},
    {prodId:"42272501",label:"Aparatos de gas de anestesia"},
    {prodId:"42272502",label:"Unidades de absorción para aparatos de gas de anestesia"},
    {prodId:"42272503",label:"Inhaladores de anestesia o unidades de inhalación o accesorios"},
    {prodId:"42272505",label:"Tubos para aparatos de gas de anestesia o ensamblajes de tubos o ajustes de tubos o accesorios"},
    {prodId:"42272506",label:"Filtros de pantalla para aparatos de anestesia"},
    {prodId:"42272507",label:"Control de temperatura para aparatos de anestesia"},
    {prodId:"42272508",label:"Bomba intratecal"},
    {prodId:"42272509",label:"Calibradores para máquina de anestesia o accesorios"},
    {prodId:"42272510",label:"Kits o accesorios para actualización de máquina de anestesia"},
    {prodId:"42281500",label:"Equipo y accesorios de esterilizadores y de autoclave"},
    {prodId:"42281501",label:"Esterilizadores químicos o de gas"},
    {prodId:"42281502",label:"Esterilizadores de aire seco o de aire caliente"},
    {prodId:"42281503",label:"Esterilizadores de filtro"},
    {prodId:"42281504",label:"Esterilizadores de bolas de vidrio"},
    {prodId:"42281505",label:"Manijas de elevación para contenedores o bandejas de esterilización"},
    {prodId:"42281506",label:"Dispositivos o accesorios eléctricos para limpieza de instrumentos"},
    {prodId:"42281507",label:"Esterilizadores de radiación"},
    {prodId:"42281508",label:"Autoclaves o esterilizadores de vapor"},
    {prodId:"42281509",label:"Contenedores o bandejas de esterilización"},
    {prodId:"42281510",label:"Ganchos o estantes o sujetadores para instrumentos de esterilización"},
    {prodId:"42281511",label:"Lámparas de esterilización"},
    {prodId:"42281512",label:"Tapas de esterilización"},
    {prodId:"42281513",label:"Placas de identificación de esterilización"},
    {prodId:"42281514",label:"Calentadores sanitarios o accesorios"},
    {prodId:"42281515",label:"Gabinetes de esterilización"},
    {prodId:"42281516",label:"Filtros de esterilización"},
    {prodId:"42281517",label:"Sistemas de recuperación de agua de esterilización"},
    {prodId:"42281518",label:"Barras para cánulas de esterilización"},
    {prodId:"42281519",label:"Esterilizadores de agujas"},
    {prodId:"42281521",label:"Sets de esterilización"},
    {prodId:"42281522",label:"Instrumentos de esterilización o insertos de estuches de esterilización"},
    {prodId:"42281523",label:"Mangas de filtro de esterilización"},
    {prodId:"42281524",label:"Adaptadores o ensamblajes de adaptadores de esterilización"},
    {prodId:"42281525",label:"Aireador de gas o químico o de radiación de esterilización"},
    {prodId:"42281526",label:"Prueba de indicador biológico para incubadoras"},
    {prodId:"42281527",label:"Concentrado o casete o cartucho de esterilizante de esterilización"},
    {prodId:"42281528",label:"Accesorios para esterilizador químico o de gas"},
    {prodId:"42281529",label:"Accesorios para esterilizador de aire seco o de aire caliente"},
    {prodId:"42281530",label:"Accesorios para esterilizador o autoclave de vapor"},
    {prodId:"42281531",label:"Esterilizador ultravioleta"},
    {prodId:"42281532",label:"Esterilizador de plasma de gas"},
    {prodId:"42281600",label:"Soluciones de desinfectantes y esterilización en frío"},
    {prodId:"42281603",label:"Desinfectante o esterilizador de instrumentos"},
    {prodId:"42281604",label:"Desinfectantes de superficies para uso médico"},
    {prodId:"42281605",label:"Compuestos anti fijación para uso médico"},
    {prodId:"42281606",label:"Fumigadores de gas para uso médico"},
    {prodId:"42281700",label:"Soluciones y equipo de limpieza pre- esterilización"},
    {prodId:"42281701",label:"Limpiadores de cámara para autoclaves o esterilizadores"},
    {prodId:"42281702",label:"Baldes de remojo desinfectantes"},
    {prodId:"42281703",label:"Kits de cuidado de instrumentos"},
    {prodId:"42281704",label:"Limpiadores o detergentes para instrumentos"},
    {prodId:"42281705",label:"Equipo de lavado desinfectante para equipos e instrumentos de uso médico"},
    {prodId:"42281706",label:"Lubricantes o leche para instrumentos"},
    {prodId:"42281707",label:"Almohadillas para remover manchas de los instrumentos"},
    {prodId:"42281708",label:"Limpiadores de carros de esterilización"},
    {prodId:"42281709",label:"Cepillos de limpieza de esterilización"},
    {prodId:"42281710",label:"Desodorantes de esterilización"},
    {prodId:"42281711",label:"Desincrustadores de esterilización"},
    {prodId:"42281712",label:"Equipo de limpieza ultrasónica"},
    {prodId:"42281713",label:"Baldes de escurrido de esterilización"},
    {prodId:"42281800",label:"Controles y indicadores de esterilización"},
    {prodId:"42281801",label:"Tiras de prueba de desinfección"},
    {prodId:"42281802",label:"Etiquetas de esterilización"},
    {prodId:"42281803",label:"Kits biológicos de esterilización"},
    {prodId:"42281804",label:"Controles de esterilización"},
    {prodId:"42281805",label:"Registros indicadores de esterilización"},
    {prodId:"42281806",label:"Tiras indicadoras de esterilización"},
    {prodId:"42281807",label:"Cintas indicadoras de esterilización"},
    {prodId:"42281808",label:"Papeles u hojas de esterilización"},
    {prodId:"42281809",label:"Sobres de almacenamiento de registros de esterilización"},
    {prodId:"42281810",label:"Paquetes de prueba y accesorios de esterilización"},
    {prodId:"42281811",label:"Candado para contenedor de esterilización"},
    {prodId:"42281900",label:"Suministros de empaquetar y envoltura de esterilización"},
    {prodId:"42281901",label:"Sujetadores o carritos para envoltorios o sobres de esterilización"},
    {prodId:"42281902",label:"Fundas o sobre fundas de esterilización"},
    {prodId:"42281903",label:"Cubiertas contra el polvo de esterilización"},
    {prodId:"42281904",label:"Bolsas de esterilización"},
    {prodId:"42281905",label:"Selladores en caliente de esterilización"},
    {prodId:"42281906",label:"Bandas de instrumentos de esterilización"},
    {prodId:"42281907",label:"Protectores de instrumentos de esterilización"},
    {prodId:"42281908",label:"Recubrimientos de bandejas de instrumentos de esterilización"},
    {prodId:"42281909",label:"Pistolas o cintas o esferos etiquetadores de esterilización"},
    {prodId:"42281912",label:"Toallas de esterilización"},
    {prodId:"42281913",label:"Tunos de esterilización"},
    {prodId:"42281914",label:"Contenedores desechables de esterilización"},
    {prodId:"42281915",label:"Rollos de esterilización"},
    {prodId:"42281916",label:"Talegos de esterilización"},
    {prodId:"42291500",label:"Instrumentos quirúrgicos y productos relacionados para biopsia de los huesos"},
    {prodId:"42291501",label:"Molinos para biopsia de hueso para uso quirúrgico o productos relacionados"},
    {prodId:"42291502",label:"Trepanadores para biopsia de hueso para uso quirúrgico"},
    {prodId:"42291600",label:"Instrumentos quirúrgicos de cortar y lazos y productos relacionados"},
    {prodId:"42291601",label:"Escalpelos o cuchillos o manijas de cuchillos láser para uso quirúrgico"},
    {prodId:"42291602",label:"Tornillos o cables o alfileres o instrumentos para cortar alambre para uso quirúrgico"},
    {prodId:"42291603",label:"Fórceps para cortar hueso para uso quirúrgico"},
    {prodId:"42291604",label:"Sierras de mano o sierras de alambre o manijas para sierras para huesos para uso quirúrgico"},
    {prodId:"42291605",label:"Brocas para uso quirúrgico"},
    {prodId:"42291606",label:"Cinceles o perforadoras para uso quirúrgico"},
    {prodId:"42291607",label:"Curetas o lanzaderas para uso quirúrgico"},
    {prodId:"42291608",label:"Bloques o tablas o plataformas de cortado para uso quirúrgico"},
    {prodId:"42291609",label:"Pinzas para uso quirúrgico"},
    {prodId:"42291610",label:"Planos para uso quirúrgico"},
    {prodId:"42291611",label:"Raspadores para uso quirúrgico"},
    {prodId:"42291612",label:"Tenazas para uso quirúrgico"},
    {prodId:"42291613",label:"Escalpelos o cuchillos o cuchillas o trepanadores o accesorios para uso quirúrgico"},
    {prodId:"42291614",label:"Tijeras para uso quirúrgico"},
    {prodId:"42291615",label:"Tijeras para uso quirúrgico"},
    {prodId:"42291616",label:"Pinzas o cables de pinzas para uso quirúrgico"},
    {prodId:"42291617",label:"Espitas para uso quirúrgico"},
    {prodId:"42291619",label:"Tomos (pinzas de resección) para uso quirúrgico"},
    {prodId:"42291620",label:"Trocadores quirúrgicos para uso general o accesorios"},
    {prodId:"42291621",label:"Removedores de anillos de los dedos"},
    {prodId:"42291622",label:"Adenotomos"},
    {prodId:"42291623",label:"Periosteotomos"},
    {prodId:"42291624",label:"Meniscotomos"},
    {prodId:"42291625",label:"Instrumentos de incisión del talón de los bebés"},
    {prodId:"42291627",label:"Taladros quirúrgicos o sus accesorios"},
    {prodId:"42291700",label:"Taladros quirúrgicos de mano y escariadores y instrumentos de punción y accesorios y productos relacionados"},
    {prodId:"42291701",label:"Brocas de mano o de torsión o kits de brocas o accesorios para uso quirúrgico"},
    {prodId:"42291702",label:"Escariadores o punzones de mano para uso quirúrgico"},
    {prodId:"42291703",label:"Perforadoras para uso quirúrgico"},
    {prodId:"42291704",label:"Punzones o sujeta punzones o accesorios para uso quirúrgico"},
    {prodId:"42291705",label:"Adaptadores de escariadores para uso quirúrgico"},
    {prodId:"42291707",label:"Kits de craneotomía"},
    {prodId:"42291708",label:"Brocas o accesorios para uso quirúrgico"},
    {prodId:"42291709",label:"Cuchillas de sierra o accesorios para uso quirúrgico"},
    {prodId:"42291710",label:"Gancho amniótico"},
    {prodId:"42291800",label:"Abrazaderas quirúrgicas y fórceps y ligadores quirúrgicos y instrumentos relacionados"},
    {prodId:"42291801",label:"Aplicadores de ligantes de bandas o bandas o productos relacionados para uso quirúrgico"},
    {prodId:"42291802",label:"Pinzas o ganchos o fórceps o accesorios para uso quirúrgico"},
    {prodId:"42291803",label:"Pinzas o fórceps láser para uso quirúrgico"},
    {prodId:"42291804",label:"Pinzas o campana de circuncisión"},
    {prodId:"42291805",label:"Pinza de cordón umbilical de recién nacidos"},
    {prodId:"42292100",label:"Insertadores y extractores quirúrgicos y productos relacionados"},
    {prodId:"42292101",label:"Insertores o kits de insertores para uso quirúrgico"},
    {prodId:"42292102",label:"Extractores para uso quirúrgico"},
    {prodId:"42292103",label:"Porta tubos o llaves para uso quirúrgico"},
    {prodId:"42292300",label:"Hierros de flexión y herramientas de plegar y tenazas y tensionadores y torceduras quirúrgicos y productos relacionados"},
    {prodId:"42292301",label:"Instrumentos para doblar para uso quirúrgico"},
    {prodId:"42292302",label:"Engarzadores para uso quirúrgico"},
    {prodId:"42292303",label:"Alicates para uso quirúrgico"},
    {prodId:"42292304",label:"Tensores para uso quirúrgico"},
    {prodId:"42292305",label:"Agarres para uso quirúrgico"},
    {prodId:"42292306",label:"Fórceps o tenazas de alambre para uso quirúrgico"},
    {prodId:"42292307",label:"Llaves para uso quirúrgico"},
    {prodId:"42292600",label:"Dilatadores y sondas y ranuras quirúrgicas y productos relacionados"},
    {prodId:"42292601",label:"Dilatadores o accesorios para uso quirúrgico"},
    {prodId:"42292602",label:"Muescas para uso quirúrgico"},
    {prodId:"42292603",label:"Sondas o directores para uso quirúrgico"},
    {prodId:"42292700",label:"Disectores y Elevadores y Piquetas quirúrgicos y productos relacionados"},
    {prodId:"42292701",label:"Disectores para uso quirúrgico"},
    {prodId:"42292702",label:"Levantadores o niveladores para uso quirúrgico"},
    {prodId:"42292703",label:"Elevadores para uso quirúrgico"},
    {prodId:"42292704",label:"Picas para uso quirúrgico"},
    {prodId:"42292800",label:"Instrumentos quirúrgicos de marcar"},
    {prodId:"42292801",label:"Instrumentos de marcación oftálmica"},
    {prodId:"42292802",label:"Instrumentos de marcación quirúrgica para uso general"},
    {prodId:"42292803",label:"Dispositivo de sellamiento y marcado de biopsias"},
    {prodId:"42292900",label:"Instrumentos quirúrgicos de cerrar la sutura de tejido y productos relacionados"},
    {prodId:"42292901",label:"Instrumentos de cerclaje para uso quirúrgico"},
    {prodId:"42292902",label:"Sujetadores de aguja láser para uso quirúrgico"},
    {prodId:"42292903",label:"Sujetadores de agujas quirúrgicas para uso general"},
    {prodId:"42292904",label:"Suturas quirúrgicas o pasadores de alambre o productos relacionados"},
    {prodId:"42292907",label:"Sistemas para estirar la piel"},
    {prodId:"42292908",label:"Dispositivos de costura de bolsa para uso quirúrgico"},
    {prodId:"42293100",label:"Retractores quirúrgicos y productos relacionados"},
    {prodId:"42293101",label:"Retractores láser para uso quirúrgico"},
    {prodId:"42293102",label:"Ganchos de retracción para uso quirúrgico"},
    {prodId:"42293103",label:"Retractores de fibra óptica iluminados para uso quirúrgico"},
    {prodId:"42293104",label:"Mordazas o accesorios para uso quirúrgico"},
    {prodId:"42293105",label:"Retractores de inclinación para uso quirúrgico"},
    {prodId:"42293106",label:"Sets de retractores para uso quirúrgico"},
    {prodId:"42293107",label:"Retractores quirúrgicos para uso general"},
    {prodId:"42293108",label:"Estabilizadores para uso quirúrgico"},
    {prodId:"42293109",label:"Protectores de tejidos para uso quirúrgico"},
    {prodId:"42293110",label:"Retractores ortopédicos"},
    {prodId:"42293111",label:"Retractores oftálmicos"},
    {prodId:"42293112",label:"Retractores torácicos o cardiovasculares"},
    {prodId:"42293113",label:"Retractores de vena"},
    {prodId:"42293114",label:"Retractores bucales"},
    {prodId:"42293115",label:"Retractores traqueales"},
    {prodId:"42293116",label:"Retractores rectales"},
    {prodId:"42293117",label:"Retractores gastrointestinales"},
    {prodId:"42293118",label:"Retractores uterinos"},
    {prodId:"42293119",label:"Retractores abdominales"},
    {prodId:"42293120",label:"Retractores de columna o nervios"},
    {prodId:"42293121",label:"Retractores de glándulas"},
    {prodId:"42293122",label:"Retractores de oído"},
    {prodId:"42293123",label:"Retractores para cirugía plástica"},
    {prodId:"42293124",label:"Retractores de nervios"},
    {prodId:"42293125",label:"Retractores de esternón"},
    {prodId:"42293126",label:"Retractores para amputación"},
    {prodId:"42293127",label:"Retractores de tejidos"},
    {prodId:"42293128",label:"Retractores de piel"},
    {prodId:"42293129",label:"Retractores para micro cirugía"},
    {prodId:"42293130",label:"Retractores de pulmón"},
    {prodId:"42293131",label:"Retractores de párpado"},
    {prodId:"42293132",label:"Retractores de dedos"},
    {prodId:"42293133",label:"Anillos de retracción para uso quirúrgico"},
    {prodId:"42293134",label:"Retractores cervicales"},
    {prodId:"42293135",label:"Retractores de labios"},
    {prodId:"42293136",label:"Adaptadores de retractores"},
    {prodId:"42293137",label:"Cuchillas retractoras ortopédicas"},
    {prodId:"42293138",label:"Retractores para urología o sus accesorios para uso quirúrgico"},
    {prodId:"42293139",label:"Accesorios para retractores"},
    {prodId:"42293300",label:"Distractores y esparcidores y separadores quirúrgicos y productos relacionados"},
    {prodId:"42293301",label:"Distractores o accesorios para uso quirúrgico"},
    {prodId:"42293302",label:"Separadores para uso quirúrgico"},
    {prodId:"42293303",label:"Espéculos para uso quirúrgico"},
    {prodId:"42293304",label:"Extensores para uso quirúrgico"},
    {prodId:"42293500",label:"Cánulas y puntas y estiletes de irrigación y succión quirúrgica y productos relacionados"},
    {prodId:"42293501",label:"Piezas de mano de irrigación o succión o cánulas o puntas o productos relacionados para uso quirúrgico"},
    {prodId:"42293502",label:"Cánulas o puntas de succión o irrigación láser o productos relacionados para uso quirúrgico"},
    {prodId:"42293503",label:"Dispositivos o curetas de extracción al vacío o productos relacionados para uso quirúrgico"},
    {prodId:"42293504",label:"Suministros o accesorios de irrigación o aspiración oftálmica"},
    {prodId:"42293505",label:"Sondas de drenaje de succión para uso quirúrgico"},
    {prodId:"42293506",label:"Bulbos de succión para uso quirúrgico"},
    {prodId:"42293507",label:"Reservorios de succión para uso quirúrgico"},
    {prodId:"42293508",label:"Suministros o accesorios de irrigación o aspiración de oído nariz y garganta ent"},
    {prodId:"42293509",label:"Sets o accesorios de irrigación para uso quirúrgico"},
    {prodId:"42293600",label:"Bougies quirúrgicos y sondas y obturadores y productos relacionados"},
    {prodId:"42293601",label:"Bujías para uso quirúrgico"},
    {prodId:"42293602",label:"Obturadores para uso quirúrgico"},
    {prodId:"42293603",label:"Sondas para uso quirúrgico"},
    {prodId:"42294000",label:"Espátulas y cucharas y cucharones quirúrgicos y productos relacionados"},
    {prodId:"42294001",label:"Cucharas hondas para uso quirúrgico"},
    {prodId:"42294002",label:"Espátulas para uso quirúrgico"},
    {prodId:"42294003",label:"Cucharas para uso quirúrgico"},
    {prodId:"42294200",label:"Juegos de instrumentos y sistemas y bandejas quirúrgicos"},
    {prodId:"42294201",label:"Sets de instrumentos quirúrgicos torácicos o cardiovasculares"},
    {prodId:"42294202",label:"Sets o sistemas de instrumentos de fijación externa"},
    {prodId:"42294203",label:"Sets de instrumentos quirúrgicos generales"},
    {prodId:"42294204",label:"Sets de instrumentos para micro cirugía, cirugía plástica o cirugía delicada"},
    {prodId:"42294205",label:"Sets de instrumentos neuroquirúrgicos o espinales"},
    {prodId:"42294206",label:"Sets de instrumentos para cirugía oftálmica"},
    {prodId:"42294207",label:"Sets de instrumentos para cirugía maxilofacial"},
    {prodId:"42294208",label:"Sistemas de instrumentos para revisión ortopédica o completa de articulaciones"},
    {prodId:"42294209",label:"Sistemas de instrumentos para fijación de trauma ortopédico"},
    {prodId:"42294210",label:"Sets de instrumentos para cirugía otolaringológica"},
    {prodId:"42294211",label:"Bandejas de procedimientos o instrumentos especiales o a la medida para uso quirúrgico"},
    {prodId:"42294212",label:"Sets de instrumentos para cirugía urológica"},
    {prodId:"42294213",label:"Sets de instrumentos para cirugía por laparoscopia"},
    {prodId:"42294214",label:"Sets de instrumentos para cirugía de traqueotomía"},
    {prodId:"42294215",label:"Sets de instrumentos para cirugía de craneotomía"},
    {prodId:"42294216",label:"Sets de instrumentos para cirugía de angiografía"},
    {prodId:"42294217",label:"Sets de instrumentos para cirugía gastroscópica"},
    {prodId:"42294218",label:"Sets de instrumentos para cirugía de oído nariz y garganta ent"},
    {prodId:"42294219",label:"Sets de instrumentos para cirugía ortopédica"},
    {prodId:"42294220",label:"Sistemas de recuperación y entrega de sangre"},
    {prodId:"42294500",label:"Instrumentos de especialidad oftálmica y productos relacionados"},
    {prodId:"42294501",label:"Conformadores o protectores para cirugía oftálmica"},
    {prodId:"42294502",label:"Pesas de párpados para cirugía oftálmica"},
    {prodId:"42294503",label:"Anillos de fijación para cirugía oftálmica"},
    {prodId:"42294504",label:"Instrumentos de membrana intraocular para cirugía oftálmica"},
    {prodId:"42294505",label:"Placas de párpado para cirugía oftálmica"},
    {prodId:"42294506",label:"Rotadores de núcleo para cirugía oftálmica"},
    {prodId:"42294507",label:"Fresas o manijas o removedores de anillos de óxido para uso oftálmico"},
    {prodId:"42294508",label:"Puntas de aguja de irrigación o aspiración para uso oftálmico"},
    {prodId:"42294509",label:"Agujas para cirugía oftálmica"},
    {prodId:"42294510",label:"Esponjas para cirugía oftálmica"},
    {prodId:"42294511",label:"Cuchillos o cuchillas o tijeras o accesorios para cirugía oftálmica"},
    {prodId:"42294512",label:"Protectores de ojos o sus accesorios"},
    {prodId:"42294513",label:"Kits de vitrectomía oftálmica"},
    {prodId:"42294514",label:"Sondas de borrado hemostático"},
    {prodId:"42294515",label:"Pulidores de lentes oftálmicos"},
    {prodId:"42294516",label:"Sujetadores de ojos o sus accesorios"},
    {prodId:"42294517",label:"Insertos ópticos"},
    {prodId:"42294518",label:"Sets de ajuste de insertos ópticos"},
    {prodId:"42294519",label:"Cucharas o curetas oftálmicas"},
    {prodId:"42294520",label:"Sujetadores de lentes o accesorios para uso oftálmico"},
    {prodId:"42294521",label:"Componentes de combado escleral"},
    {prodId:"42294522",label:"Sets de punctum de tapón para uso oftálmico"},
    {prodId:"42294523",label:"Suministros para cirugía plástica oftálmica o sus productos relacionados"},
    {prodId:"42294524",label:"Magnetos de ojos para cirugía oftálmica"},
    {prodId:"42294525",label:"Sets de instrumentos médicos oftálmicos"},
    {prodId:"42294526",label:"Dilatadores o sets de lagrimales"},
    {prodId:"42294527",label:"Gas intraocular"},
    {prodId:"42294528",label:"Agente visco elástico o dispositivo visco quirúrgico"},
    {prodId:"42294529",label:"Cistotomo"},
    {prodId:"42294530",label:"Lente de vitrectomía"},
    {prodId:"42294531",label:"Lente láser para uso oftálmico"},
    {prodId:"42294700",label:"Equipo de perfusión de corazón abierto y monitores y accesorios y productos relacionados"},
    {prodId:"42294701",label:"Máquinas o accesorios de corazón y pulmones"},
    {prodId:"42294702",label:"Bombas y accesorios de balón intra aórtico"},
    {prodId:"42294703",label:"Dispositivos o accesorios de succión intra cardíaca"},
    {prodId:"42294704",label:"Filtros de perfusión o productos relacionados"},
    {prodId:"42294705",label:"Monitores de parámetros de sangre de perfusión o accesorios o productos relacionados"},
    {prodId:"42294706",label:"Trampas de burbujas de perfusión"},
    {prodId:"42294707",label:"Sets de cardioplegia de perfusión"},
    {prodId:"42294708",label:"Reservorios de cardiotomía de perfusión"},
    {prodId:"42294709",label:"Sistemas centrífugos de perfusión o accesorios"},
    {prodId:"42294710",label:"Equipos o accesorios calentadores o enfriadores o duales calentadores o enfriadores de perfusión"},
    {prodId:"42294712",label:"Monitores de saturación de oxígeno o hematocritos de perfusión o accesorios"},
    {prodId:"42294713",label:"Oxigenadores de perfusión o accesorios"},
    {prodId:"42294714",label:"Cabezas de bombas de perfusión"},
    {prodId:"42294715",label:"Tubos de empaque de bombas de perfusión"},
    {prodId:"42294716",label:"Reservorios venosos de perfusión"},
    {prodId:"42294717",label:"Dispositivos de asistencia ventricular"},
    {prodId:"42294718",label:"Bombas de perfusión"},
    {prodId:"42294719",label:"Reservorios cardiovasculares"},
    {prodId:"42294720",label:"Agujas de monitoreo de temperatura"},
    {prodId:"42294721",label:"Punzones aórticos"},
    {prodId:"42294722",label:"Sets de drenaje ventricular"},
    {prodId:"42294723",label:"Kits de inserción de bomba de inflar intra aórtica"},
    {prodId:"42294724",label:"Introductor de bomba de inflar intra aórtica"},
    {prodId:"42294800",label:"Endoscopios y accesorios y productos relacionados"},
    {prodId:"42294801",label:"Endoscopios rígidos o accesorios o productos relacionados"},
    {prodId:"42294802",label:"Endoscopios flexibles o accesorios o productos relacionados"},
    {prodId:"42294803",label:"Cistouretroscopios"},
    {prodId:"42294804",label:"Resectoscopios"},
    {prodId:"42294805",label:"Laparascopios o telescopios laparoscópicos"},
    {prodId:"42294806",label:"Cistoscopios"},
    {prodId:"42294807",label:"Esfinterómetros endoscópicos"},
    {prodId:"42294808",label:"Esofagoscopios o sus accesorios"},
    {prodId:"42294900",label:"Instrumentos endoscópicos y suministros accesorios y productos relacionados"},
    {prodId:"42294901",label:"Posicionadores o sujetadores de endoscopios o instrumentos"},
    {prodId:"42294902",label:"Aplicadores o elevadores de endoscopios"},
    {prodId:"42294903",label:"Agujas de aspiración o biopsia endoscópica"},
    {prodId:"42294904",label:"Bloques de mordida o correas endoscópicas"},
    {prodId:"42294905",label:"Cepillos de limpieza endoscópica o productos relacionados"},
    {prodId:"42294906",label:"Instrumentos de cortar para uso endoscópico"},
    {prodId:"42294907",label:"Cepillos de citología endoscópica o microbiología"},
    {prodId:"42294908",label:"Pinzas o disectores o agarraderas o fórceps o ligantes endoscópicos"},
    {prodId:"42294909",label:"Dilatadores o dispositivos inflables endoscópicos o productos relacionados"},
    {prodId:"42294910",label:"Electrodos o cables endoscópicos"},
    {prodId:"42294911",label:"Kits de fijación endoscópica"},
    {prodId:"42294912",label:"Sistemas o accesorios de manejo de fluido endoscópico"},
    {prodId:"42294913",label:"Balones hemostáticos o agujas o tubos o accesorios endoscópicos"},
    {prodId:"42294914",label:"Sets de instrumentos endoscópicos"},
    {prodId:"42294915",label:"Desplegadores de instrumentos endoscópicos"},
    {prodId:"42294916",label:"Filtros de insuflación endoscópica"},
    {prodId:"42294917",label:"Agujas de insuflación endoscópica"},
    {prodId:"42294918",label:"Tubos de insuflación endoscópica"},
    {prodId:"42294919",label:"Introductores o alambres guía o alambres de deslizamiento endoscópicos"},
    {prodId:"42294920",label:"Instrumentos o accesorios láser endoscópicos"},
    {prodId:"42294921",label:"Sistemas de empujadores de nudos o de entrega endoscópicos"},
    {prodId:"42294922",label:"Ligantes endoscópicos"},
    {prodId:"42294923",label:"Manipuladores endoscópicos"},
    {prodId:"42294924",label:"Instrumentos o accesorios manuales monopolares o bipolares para endoscopia o productos relacionados"},
    {prodId:"42294925",label:"Agujas o punzones para endoscopia"},
    {prodId:"42294926",label:"Sobre tubos para endoscopia"},
    {prodId:"42294927",label:"Paquetes o bandejas o kits de instrumentos para endoscopia"},
    {prodId:"42294928",label:"Sondas para endoscopia"},
    {prodId:"42294929",label:"Retractores para endoscopia"},
    {prodId:"42294930",label:"Pinzas o alambres de pinzas o accesorios para endoscopia"},
    {prodId:"42294931",label:"Fórceps o dispositivos de recuperación de especímenes para endoscopia"},
    {prodId:"42294933",label:"Puntas de irrigación o succión o sondas de coagulación o accesorios para endoscopia"},
    {prodId:"42294934",label:"Dispositivos de sutura para endoscopia"},
    {prodId:"42294935",label:"Trocar o funda u obturador o cánula para endoscopia"},
    {prodId:"42294936",label:"Elementos de trabajo o canales de trabajo para endoscopia"},
    {prodId:"42294937",label:"Dispositivos reductores de empañe para endoscopia o espejos"},
    {prodId:"42294938",label:"Tapas sellantes para endoscopia"},
    {prodId:"42294939",label:"Válvulas o accesorios para endoscopia"},
    {prodId:"42294940",label:"Convertidores para endoscopia"},
    {prodId:"42294941",label:"Sets de drenaje biliar para endoscopia"},
    {prodId:"42294942",label:"Sellos de instrumentos para endoscopia"},
    {prodId:"42294943",label:"Unidades de válvulas para endoscopia"},
    {prodId:"42294944",label:"Kits de accesorios para endoscopia"},
    {prodId:"42294945",label:"Esponjas para endoscopia"},
    {prodId:"42294946",label:"Mordazas para endoscopia"},
    {prodId:"42294947",label:"Diafragmas para endoscopia"},
    {prodId:"42294948",label:"Piezas bucales para endoscopia"},
    {prodId:"42294949",label:"Manijas de guía de alambre para endoscopia"},
    {prodId:"42294950",label:"Brocas o taladros para endoscopia"},
    {prodId:"42294951",label:"Sets de instrumentos para uniones pequeñas para endoscopia"},
    {prodId:"42294952",label:"Recuperadores o sets para endoscopia"},
    {prodId:"42294953",label:"Extractores para endoscopia"},
    {prodId:"42294954",label:"Dispositivos o accesorios para remover especímenes o tejidos para endoscopia"},
    {prodId:"42294955",label:"Ganchos o accesorios para endoscopia"},
    {prodId:"42294956",label:"Localizadores de guía de alambre para endoscopia"},
    {prodId:"42294957",label:"Cuchillas de afeitar o accesorios para endoscopia"},
    {prodId:"42294958",label:"Aditamentos y accesorios de cortar y sellar vasos para endoscopia"},
    {prodId:"42295000",label:"Equipo endoscópico y accesorios y productos relacionados"},
    {prodId:"42295001",label:"Unidades o accesorios de mantenimiento para endoscopia"},
    {prodId:"42295002",label:"Gabinetes de almacenamiento o accesorios para endoscopia"},
    {prodId:"42295003",label:"Colgadores de pared o accesorios para endoscopia"},
    {prodId:"42295004",label:"Carritos de equipos o procedimientos o accesorios para endoscopia"},
    {prodId:"42295005",label:"Sets de equipos endoscópicos"},
    {prodId:"42295006",label:"Unidades de sonda de calor o sondas de calor o accesorios para endoscopia"},
    {prodId:"42295007",label:"Sistemas de imágenes o accesorios para endoscopia"},
    {prodId:"42295008",label:"Unidades o accesorios de insuflación o distensión para endoscopia"},
    {prodId:"42295009",label:"Fuentes de luz quirúrgica o accesorios para endoscopia"},
    {prodId:"42295010",label:"Impresoras o accesorios para endoscopia"},
    {prodId:"42295011",label:"Video cámaras o grabadoras o adaptadores o accesorios para endoscopia"},
    {prodId:"42295012",label:"Botellas de agua o accesorios para endoscopia"},
    {prodId:"42295013",label:"Protectores o cubiertas de puntas de endoscopios"},
    {prodId:"42295014",label:"Estuches de instrumentos para endoscopia"},
    {prodId:"42295015",label:"Lentes endoscópicos"},
    {prodId:"42295016",label:"Video cable quirúrgico o endoscópico"},
    {prodId:"42295100",label:"Equipo quirúrgico y accesorios y productos relacionados"},
    {prodId:"42295101",label:"Puestos de lavamanos para uso quirúrgico"},
    {prodId:"42295102",label:"Equipos o accesorios para criocirugía"},
    {prodId:"42295103",label:"Mesas o accesorios para procedimientos de cesáreas o salas de partos o productos relacionados"},
    {prodId:"42295104",label:"Equipo electro quirúrgico o electro cauterizante"},
    {prodId:"42295105",label:"Mesas de instrumentos para uso quirúrgico u obstétrico o accesorios o productos relacionados"},
    {prodId:"42295106",label:"Bandejas mayo para el canto o puestos mayo para uso quirúrgico o sus accesorios"},
    {prodId:"42295107",label:"Carritos para estuches de salas de cirugía o carritos de procedimiento o gabinetes de pared o accesorios"},
    {prodId:"42295108",label:"Mesas de fractura de pacientes para salas de cirugía o mesas ortopédicas o accesorios o productos relacionados"},
    {prodId:"42295109",label:"Baldes para salas de cirugía o accesorios o productos relacionados"},
    {prodId:"42295111",label:"Dispositivos de posicionamiento de los pacientes para salas de cirugía o accesorios"},
    {prodId:"42295112",label:"Mesas de procedimientos para salas de cirugía o accesorios o productos relacionados"},
    {prodId:"42295114",label:"Equipos de facoemulsificación o extrusión o accesorios para cirugía oftálmica"},
    {prodId:"42295115",label:"Taburetes de cirujanos o accesorios"},
    {prodId:"42295116",label:"Escalones para salas de cirugía o accesorios"},
    {prodId:"42295118",label:"Equipos de bombas de irrigación para uso quirúrgico o lavado pulsado o accesorios con o sin succión"},
    {prodId:"42295119",label:"Láseres para uso quirúrgico o accesorios"},
    {prodId:"42295120",label:"Litotriptores o accesorios para uso quirúrgico"},
    {prodId:"42295121",label:"Microscopios o lupas o magnificadores o accesorios para uso quirúrgico"},
    {prodId:"42295122",label:"Torniquetes neumáticos o eléctricos o accesorios para uso quirúrgico"},
    {prodId:"42295123",label:"Máquinas de succión o extractores al vacío o aspiradores quirúrgicos ultrasónicos o reguladores o accesorios para uso quirúrgico"},
    {prodId:"42295124",label:"Evacuadores de humo o accesorios para uso quirúrgico"},
    {prodId:"42295125",label:"Mesas urológicas o accesorios para uso quirúrgico"},
    {prodId:"42295126",label:"Equipos de cirugía fragmatoma de retina vítrea para cirugía oftálmica"},
    {prodId:"42295127",label:"Equipos o accesorios de microcirugía"},
    {prodId:"42295128",label:"Instrumentos quirúrgicos maxilofaciales o accesorios"},
    {prodId:"42295129",label:"Dispensadores de medicamentos para salas de cirugía o productos relacionados"},
    {prodId:"42295130",label:"Tubos o accesorios de conexión de instrumentos quirúrgicos"},
    {prodId:"42295131",label:"Puestos o accesorios de equipos quirúrgicos"},
    {prodId:"42295132",label:"Estuches o accesorios para equipos quirúrgicos"},
    {prodId:"42295133",label:"Estantes de tornillos ortopédicos o accesorios"},
    {prodId:"42295134",label:"Sets o accesorios de suministro quirúrgico general"},
    {prodId:"42295135",label:"Dispositivos o accesorios de control ortopédico"},
    {prodId:"42295136",label:"Instrumentos de control para uso quirúrgico"},
    {prodId:"42295137",label:"Equipos o suministros o accesorios para gastroenterología"},
    {prodId:"42295138",label:"Dispositivos o accesorios de dilatación urológica para uso quirúrgico"},
    {prodId:"42295139",label:"Rollos de instrumentos para instrumentos quirúrgicos o accesorios"},
    {prodId:"42295140",label:"Gabinetes o muebles para instrumentos quirúrgicos"},
    {prodId:"42295141",label:"Kit de salvamento de sangre intra operativa"},
    {prodId:"42295142",label:"Máquina de salvamento de sangre intra operativa"},
    {prodId:"42295143",label:"Accesorios o aditamentos electro quirúrgicos o electro cauterizantes"},
    {prodId:"42295144",label:"Unidad de microondas quirúrgico"},
    {prodId:"42295145",label:"Depilador tipo aguja"},
    {prodId:"42295146",label:"Depilador tipo pinza"},
    {prodId:"42295147",label:"Instrumento de ultrasonido para uso quirúrgico"},
    {prodId:"42295148",label:"Sistema de navegación para uso quirúrgico"},
    {prodId:"42295200",label:"Equipo quirúrgico de energía y accesorios y productos relacionados"},
    {prodId:"42295201",label:"Dermotomos o aparatos para dermoabrasión o dermoreticulador para uso quirúrgico o accesorios"},
    {prodId:"42295202",label:"Sierras eléctricas o de pila o neumáticas o colocadores de tornillos o accesorios para uso quirúrgico"},
    {prodId:"42295203",label:"Sets o accesorios de equipos eléctricos para uso quirúrgico"},
    {prodId:"42295204",label:"Escariadores para uso quirúrgico"},
    {prodId:"42295205",label:"Equipos de afeitado o piezas de mano o cuchillas o accesorios para uso quirúrgico"},
    {prodId:"42295206",label:"Unidades de cirugía endoscópica"},
    {prodId:"42295207",label:"Guía o manga para taladro de uso quirúrgico"},
    {prodId:"42295400",label:"Suministros quirúrgicos auxiliares"},
    {prodId:"42295401",label:"Lápices de cauterización operados mediante pilas de uso quirúrgico"},
    {prodId:"42295402",label:"Esferos para marcar de uso quirúrgico"},
    {prodId:"42295405",label:"Introductores o ganchos de guía o guías de alambre o deslizadores de alambre para cirugía no endoscópica o procedimientos de corazón abierto"},
    {prodId:"42295406",label:"Esponja especial para laparotomía o detectable por rayos x o de uso quirúrgico"},
    {prodId:"42295407",label:"Máscaras para pacientes de uso quirúrgico"},
    {prodId:"42295408",label:"Cepillos para manos para cirujanos o soluciones o accesorios"},
    {prodId:"42295409",label:"Aplicadores de uso quirúrgico"},
    {prodId:"42295410",label:"Sets o paquetes de cuencas de uso quirúrgico"},
    {prodId:"42295411",label:"Sujetadores o interruptores de cuchilla de uso quirúrgico"},
    {prodId:"42295413",label:"Cepillos de canal de uso quirúrgico"},
    {prodId:"42295414",label:"Paquetes para procedimientos especiales o a la medida de uso quirúrgico"},
    {prodId:"42295415",label:"Fundas para equipos de uso quirúrgico"},
    {prodId:"42295416",label:"Evacuadores de uso quirúrgico"},
    {prodId:"42295417",label:"Fundas o manijas para luces de uso quirúrgico"},
    {prodId:"42295418",label:"Esterillas magnéticas de uso quirúrgico"},
    {prodId:"42295419",label:"Estimuladores de nervios o accesorios de uso quirúrgico"},
    {prodId:"42295420",label:"Catéteres endoscópicos o quirúrgicos o kits de cateterización o bolsas de drenaje"},
    {prodId:"42295421",label:"Lavado de preparación o soluciones de pintura para uso quirúrgico"},
    {prodId:"42295422",label:"Lavado o kits de preparación del paciente para uso quirúrgico"},
    {prodId:"42295423",label:"Mostradores de instrumentos afilados o esponjas para uso quirúrgico"},
    {prodId:"42295424",label:"Kits de afeitado o cuchillas de preparación o corta uñas para uso quirúrgico"},
    {prodId:"42295425",label:"Palitos o lápices o cristales de nitrato de plata para uso quirúrgico"},
    {prodId:"42295426",label:"Trampas o contenedores de recolección de especímenes para uso quirúrgico"},
    {prodId:"42295427",label:"Cepillos para instrumentos estériles o estiletes instrumentales o pañitos limpiadores de instrumentos para uso quirúrgico"},
    {prodId:"42295428",label:"Tubos o accesorios de irrigación o succión para uso quirúrgico"},
    {prodId:"42295431",label:"Cortinas transparentes de incisión o bolsas para instrumentos para uso quirúrgico"},
    {prodId:"42295433",label:"Catéteres urológicos o accesorios para uso quirúrgico"},
    {prodId:"42295435",label:"Protectores o escudos para oídos"},
    {prodId:"42295436",label:"Anillos de anastomosis"},
    {prodId:"42295437",label:"Sets de utensilios o gabinetes o accesorios para uso quirúrgico"},
    {prodId:"42295439",label:"Alisadores de hueso para uso ortopédico"},
    {prodId:"42295440",label:"Adaptadores de catéteres endoscópicos"},
    {prodId:"42295441",label:"Sets de recuperación para uso quirúrgico"},
    {prodId:"42295445",label:"Insertos de bandejas para aparatos quirúrgicos"},
    {prodId:"42295446",label:"Retenedores de órganos internos"},
    {prodId:"42295448",label:"Guardas de salpicaduras o accesorios para uso quirúrgico"},
    {prodId:"42295450",label:"Cargadores de baldes o accesorios para uso quirúrgico"},
    {prodId:"42295451",label:"Guantes de preparación para uso quirúrgico"},
    {prodId:"42295452",label:"Tazas de preparación para uso quirúrgico"},
    {prodId:"42295453",label:"Drenajes o sets o accesorios para uso quirúrgico"},
    {prodId:"42295454",label:"Protectores de mano para uso quirúrgico"},
    {prodId:"42295455",label:"Kits de resina para reparación craneal"},
    {prodId:"42295456",label:"Fieltros para uso quirúrgico"},
    {prodId:"42295457",label:"Lentes o capuchas de cascos para uso quirúrgico"},
    {prodId:"42295458",label:"Equipos para secar o empolvar guantes quirúrgicos"},
    {prodId:"42295459",label:"Dispositivos para decantar fluidos para uso quirúrgico"},
    {prodId:"42295460",label:"Capas protectoras para implantes ortopédicos"},
    {prodId:"42295461",label:"Pegamento para tejidos o sistemas o aplicadores o accesorios"},
    {prodId:"42295462",label:"Catéteres uro dinámicos o accesorios"},
    {prodId:"42295463",label:"Catéteres uretrales"},
    {prodId:"42295464",label:"Solución para preservación de órganos"},
    {prodId:"42295465",label:"Sistema de monitoreo neurofisiológico"},
    {prodId:"42295466",label:"Cemento para huesos para uso quirúrgico"},
    {prodId:"42295467",label:"Mezcladora de cemento para huesos para uso quirúrgico"},
    {prodId:"42295468",label:"Inyector de cemento para huesos o accesorios para uso quirúrgico"},
    {prodId:"42295469",label:"Kits de cemento para huesos o accesorios para uso quirúrgico"},
    {prodId:"42295470",label:"Sistema de concentración de plaquetas"},
    {prodId:"42295471",label:"Sistema de aspiración de médula ósea"},
    {prodId:"42295500",label:"Implantes quirúrgico y ensanchadores y extensores y alambres quirúrgicos y productos relacionados"},
    {prodId:"42295502",label:"Implantes de tejido humano"},
    {prodId:"42295503",label:"Puertos de infusión implantables o accesorios"},
    {prodId:"42295505",label:"Implantes oftálmicos"},
    {prodId:"42295506",label:"Implantes maxilofaciales bucales o sets"},
    {prodId:"42295508",label:"Implantes para otolaringología o sets"},
    {prodId:"42295509",label:"Implantes plásticos o cosméticos o expansores de tejidos o sets"},
    {prodId:"42295510",label:"Barreras de adhesión para uso quirúrgico"},
    {prodId:"42295511",label:"Estimuladores de hueso para uso quirúrgico"},
    {prodId:"42295512",label:"Derivaciones implantables o extensores de derivaciones para uso quirúrgico"},
    {prodId:"42295513",label:"Productos de malla o de barreras de tejido para uso quirúrgico"},
    {prodId:"42295514",label:"Láminas de silicona"},
    {prodId:"42295515",label:"Implantes de tejidos sintéticos"},
    {prodId:"42295516",label:"Implantes urológicos o sets"},
    {prodId:"42295517",label:"Extensores cardiovasculares"},
    {prodId:"42295518",label:"Protectores de ganchos"},
    {prodId:"42295519",label:"Kit de preparación para prótesis de pene"},
    {prodId:"42295520",label:"Kit de ventriculostomía"},
    {prodId:"42295521",label:"Implante gastrointestinal"},
    {prodId:"42295522",label:"Estimulador de nervios implantable"},
    {prodId:"42295523",label:"Implante o eslinga ginecológica"},
    {prodId:"42295524",label:"Lente intraocular iol"},
    {prodId:"42295525",label:"Implante de tejido biológico"},
    {prodId:"42295526",label:"Sistema de implantes de pelo"},
    {prodId:"42295800",label:"Sistemas y accesorios de termo - ablación quirúrgica"},
    {prodId:"42295801",label:"Casete de ablación térmica para uso quirúrgico"},
    {prodId:"42295802",label:"Set de tubos de ablación térmica para uso quirúrgico"},
    {prodId:"42295903",label:"Stents urológicos"},
    {prodId:"42296000",label:"Implantes cardiovasculares"},
    {prodId:"42296001",label:"Válvula aórtica"},
    {prodId:"42296002",label:"Conducto cardiovascular"},
    {prodId:"42296003",label:"Válvula mitral"},
    {prodId:"42296004",label:"Válvula tricúspide"},
    {prodId:"42296005",label:"Anillo de anuloplastia"},
    {prodId:"42296006",label:"Gancho vascular"},
    {prodId:"42296007",label:"Oclusor cardiovascular"},
    {prodId:"42296008",label:"Válvula pulmonar"},
    {prodId:"42301500",label:"Ayudas para formación médica"},
    {prodId:"42301501",label:"Modelos humanos anatómicos para educación o entrenamiento médico"},
    {prodId:"42301502",label:"Maniquíes humanos anatómicos para educación o entrenamiento médico"},
    {prodId:"42301503",label:"Ayudas de entrenamiento para resucitación cardiopulmonar cpr"},
    {prodId:"42301504",label:"Kits para educación o entrenamiento médico"},
    {prodId:"42301505",label:"Portapapeles para enfermeras o médicos"},
    {prodId:"42301506",label:"Estetoscopios de doble audífono"},
    {prodId:"42301507",label:"Videos de entrenamiento para la educación del personal médico"},
    {prodId:"42301508",label:"Videos de operación o de instrucciones para equipos médicos"},
    {prodId:"42311500",label:"Vendas y vendajes y productos relacionados"},
    {prodId:"42311501",label:"Clips para vendajes o compresas"},
    {prodId:"42311502",label:"Kits de inicio de vendajes o compresas"},
    {prodId:"42311503",label:"Enrolladores de vendajes"},
    {prodId:"42311504",label:"Vendajes o compresas para el cuidado de quemaduras"},
    {prodId:"42311505",label:"Vendajes o compresas para uso general"},
    {prodId:"42311506",label:"Vendas o compresas de compresión o presión"},
    {prodId:"42311508",label:"Bandejas para compresas"},
    {prodId:"42311510",label:"Vendajes de espuma"},
    {prodId:"42311511",label:"Vendajes de gasa"},
    {prodId:"42311512",label:"Esponjas de gasa"},
    {prodId:"42311513",label:"Compresas de gel"},
    {prodId:"42311514",label:"Vendajes germicidas"},
    {prodId:"42311515",label:"Vendajes hidrocoloides"},
    {prodId:"42311517",label:"Adhesivos líquidos para vendajes o compresas"},
    {prodId:"42311518",label:"Parches o almohadillas para los ojos para uso médico"},
    {prodId:"42311519",label:"Tiras no adhesivas para uso médico"},
    {prodId:"42311520",label:"Cintas no adhesivas para uso médico"},
    {prodId:"42311521",label:"Compresas de presión negativa"},
    {prodId:"42311522",label:"Compresas oclusivas"},
    {prodId:"42311523",label:"Vendajes de pasta"},
    {prodId:"42311524",label:"Compresas de petrolato"},
    {prodId:"42311527",label:"Compresas de película transparente"},
    {prodId:"42311528",label:"Sistemas de compresas húmedas"},
    {prodId:"42311531",label:"Cubiertas para compresas"},
    {prodId:"42311537",label:"Tijeras para vendajes o sus suministros"},
    {prodId:"42311538",label:"Kits de hiperalimentación"},
    {prodId:"42311539",label:"Aplicadores de vendajes"},
    {prodId:"42311540",label:"Compresas de alginato"},
    {prodId:"42311541",label:"Compresas biológicas"},
    {prodId:"42311542",label:"Compresas de control de olor"},
    {prodId:"42311543",label:"Retenedor de vendajes"},
    {prodId:"42311700",label:"Cintas adhesivas médicas y quirúrgicas y productos relacionados para el uso en especialidades"},
    {prodId:"42311702",label:"Cintas umbilicales para bebés"},
    {prodId:"42311703",label:"Cintas médicas o quirúrgicas para pegar la piel"},
    {prodId:"42311704",label:"Dispensadores de cinta médica o quirúrgica"},
    {prodId:"42311705",label:"Removedores de cinta médica o quirúrgica"},
    {prodId:"42311707",label:"Cintas de tejido para uso quirúrgico"},
    {prodId:"42311708",label:"Cintas adherentes médicas o quirúrgicas para uso general"},
    {prodId:"42312000",label:"Cierre de tejidos médicos y productos relacionados"},
    {prodId:"42312001",label:"Cierres de mariposa para piel"},
    {prodId:"42312002",label:"Clips para cierre de piel"},
    {prodId:"42312003",label:"Tiras de cierre para la piel o para heridas"},
    {prodId:"42312004",label:"Removedores de pegamento o adhesivo para uso médico"},
    {prodId:"42312005",label:"Adhesivos o pegamentos de cierre de piel para uso médico"},
    {prodId:"42312006",label:"Aplicadores de clip para uso interno para uso médico"},
    {prodId:"42312007",label:"Clips de uso interno para uso médico"},
    {prodId:"42312008",label:"Removedores de ganchos o clips para uso médico"},
    {prodId:"42312009",label:"Cosedoras para uso interno"},
    {prodId:"42312010",label:"Cosedoras para cierre de piel"},
    {prodId:"42312011",label:"Ganchos para uso interno"},
    {prodId:"42312012",label:"Ganchos para cierre de piel"},
    {prodId:"42312014",label:"Kits de cierre de tejidos o bandejas o paquetes o sets"},
    {prodId:"42312100",label:"Suministros de ostomía y productos no quirúrgicos de drenaje de heridas"},
    {prodId:"42312101",label:"Adhesivos para aplicación en ostomía"},
    {prodId:"42312102",label:"Aplicaciones para ostomía"},
    {prodId:"42312103",label:"Limpiadores o desodorantes para ostomía"},
    {prodId:"42312104",label:"Suministros de recolección para ostomía"},
    {prodId:"42312105",label:"Kits de barreras de piel o cuidado protector para ostomía"},
    {prodId:"42312106",label:"Insertos para ostomía"},
    {prodId:"42312107",label:"Obleas para ostomía"},
    {prodId:"42312108",label:"Bolsas de drenaje para ostomía"},
    {prodId:"42312109",label:"Anillos de bolsa para ostomía"},
    {prodId:"42312110",label:"Kits de inicio para ostomía"},
    {prodId:"42312111",label:"Fundas de bolsa para ostomía"},
    {prodId:"42312112",label:"Cinturón o accesorios para ostomía"},
    {prodId:"42312113",label:"Tapones de bolsa para ostomía"},
    {prodId:"42312115",label:"Mangas de irrigación para ostomía"},
    {prodId:"42312200",label:"Sutura y productos relacionados"},
    {prodId:"42312201",label:"Suturas"},
    {prodId:"42312202",label:"Kits o bandejas o paquetes o sets para sutura"},
    {prodId:"42312203",label:"Botones o puentes o accesorios relacionados para sutura"},
    {prodId:"42312204",label:"Botas y dispositivos de captura para sutura"},
    {prodId:"42312205",label:"Carritos o estantes para sutura"},
    {prodId:"42312206",label:"Agujas para sutura"},
    {prodId:"42312207",label:"Kits de remoción o bandejas o paquetes o sets para sutura"},
    {prodId:"42312208",label:"Removedores de suturas"},
    {prodId:"42312300",label:"Productos para limpiar la herida y desbridamiento"},
    {prodId:"42312301",label:"Absorbentes para limpieza de heridas"},
    {prodId:"42312302",label:"Esponjas de debridación"},
    {prodId:"42312303",label:"Sistemas de lavado pulsado o accesorios relacionados para el tratamiento de heridas"},
    {prodId:"42312304",label:"Productos de debridación autolítica para uso médico"},
    {prodId:"42312305",label:"Productos de debridación enzimática para uso médico"},
    {prodId:"42312306",label:"Productos de debridación mecánica para uso médico"},
    {prodId:"42312307",label:"Productos de debridación quirúrgica para uso médico"},
    {prodId:"42312309",label:"Sistemas de irrigación de heridas"},
    {prodId:"42312310",label:"Botellas limpiadoras"},
    {prodId:"42312311",label:"Kits de desinfectantes"},
    {prodId:"42312312",label:"Bandejas de cuidado de heridas o de limpieza"},
    {prodId:"42312313",label:"Soluciones de limpieza de heridas"},
    {prodId:"42312400",label:"Productos para la curación de heridas"},
    {prodId:"42312401",label:"Relleno de alginato de calcio para heridas"},
    {prodId:"42312402",label:"Férulas o stents nasales"},
    {prodId:"42312403",label:"Tiras de relleno para cuidado de heridas"},
    {prodId:"42312500",label:"Dispositivos de sujeción de heridas, y accesorios y suministros"},
    {prodId:"42312501",label:"Ligaduras de soporte mamario"},
    {prodId:"42312502",label:"Ligaduras abdominales"},
    {prodId:"42312503",label:"Soportes para escroto"},
    {prodId:"42312504",label:"Prenda de soporte facial"},
    {prodId:"42312600",label:"Productos de terapia de presión negativa para heridas"},
    {prodId:"42312601",label:"Equipo de terapia de presión negativa para heridas"},
    {prodId:"42312602",label:"Kits o sistemas de terapia de presión negativa para heridas"},
    {prodId:"42321500",label:"Implantes de trauma ortopédico"},
    {prodId:"42321501",label:"Alambre quirúrgico ortopédico"},
    {prodId:"42321502",label:"Guía de alambre ortopédico o gancho guía o varilla guía"},
    {prodId:"42321503",label:"Extensor de gancho óseo o sustituto"},
    {prodId:"42321504",label:"Clavo intramedular"},
    {prodId:"42321505",label:"Placas óseas"},
    {prodId:"42321506",label:"Destornillador óseo"},
    {prodId:"42321507",label:"Set de fijación interna"},
    {prodId:"42321508",label:"Tuercas ortopédicas"},
    {prodId:"42321509",label:"Lavadora ortopédica"},
    {prodId:"42321510",label:"Tapa ortopédica"},
    {prodId:"42321512",label:"Pernos de fijación ósea"},
    {prodId:"42321513",label:"Perno o alambre de fijación temporal"},
    {prodId:"42321514",label:"Grapa de fijación ósea"},
    {prodId:"42321515",label:"Hoja de sierra intramedular"},
    {prodId:"42321516",label:"Tornillo de cierre intramedular"},
    {prodId:"42321600",label:"Implantes espinales"},
    {prodId:"42321601",label:"Disco intervertebral"},
    {prodId:"42321602",label:"Cable espinal"},
    {prodId:"42321603",label:"Balón de cifoplastia"},
    {prodId:"42321604",label:"Dispositivo de unión cruzada espinal"},
    {prodId:"42321605",label:"Armazón intracorpórea espinal"},
    {prodId:"42321606",label:"Dispositivo de descompresión espinal"},
    {prodId:"42321607",label:"Gancho espinal"},
    {prodId:"42321608",label:"Dispositivo de fusión intracorpórea"},
    {prodId:"42321609",label:"Varilla espinal"},
    {prodId:"42321610",label:"Destornillador espinal"},
    {prodId:"42321611",label:"Placa espinal"},
    {prodId:"42321612",label:"Anillo de manga o tapa de extremo espinal"},
    {prodId:"42321613",label:"Tornillo múltiple o bloqueador espinal"},
    {prodId:"42321614",label:"Perno espinal"},
    {prodId:"42321615",label:"Silla o cuna espinal"},
    {prodId:"42321616",label:"Conector o acoplador espinal"},
    {prodId:"42321617",label:"Grapa espinal"},
    {prodId:"42321618",label:"Lavadora espinal"},
    {prodId:"42321619",label:"Restrictor de cemento espinal"},
    {prodId:"42321620",label:"Perno espinal"},
    {prodId:"42321621",label:"Tornillo o alambre de fijación espinal temporal"},
    {prodId:"42321622",label:"Kit o sistema de implante espinal"},
    {prodId:"42321700",label:"Implantes de cadera"},
    {prodId:"42321701",label:"Tapa acetabular de una pieza"},
    {prodId:"42321702",label:"Concha acetabular"},
    {prodId:"42321703",label:"Revestimiento de taza acetabular"},
    {prodId:"42321704",label:"Cabeza femoral"},
    {prodId:"42321705",label:"Centralizador distal de tallo femoral"},
    {prodId:"42321706",label:"Extensión de vástago de la cadera femoral"},
    {prodId:"42321707",label:"Kit o sistema de reemplazo total de cadera"},
    {prodId:"42321708",label:"Vástago de la cadera femoral"},
    {prodId:"42321709",label:"Prótesis hemiartroplástica de cadera de una pieza"},
    {prodId:"42321710",label:"Adaptador de componente de cadera modular femoral"},
    {prodId:"42321711",label:"Componente femoral resurgente"},
    {prodId:"42321712",label:"Centralizador proximal de tallo femoral"},
    {prodId:"42321713",label:"Tornillo acetabular"},
    {prodId:"42321714",label:"Aumento acetabular"},
    {prodId:"42321715",label:"Componente de cadera bipolar o unipolar"},
    {prodId:"42321716",label:"Restrictor de cemento de canal femoral"},
    {prodId:"42321717",label:"Tornillo componente de cadera"},
    {prodId:"42321718",label:"Armazón acetabular"},
    {prodId:"42321719",label:"Malla acetabular"},
    {prodId:"42321720",label:"Cubierta de orificio de tornillo acetabular"},
    {prodId:"42321721",label:"Eliminador o tapón de orificio acetabular"},
    {prodId:"42321722",label:"Anillo acetabular"},
    {prodId:"42321723",label:"Cuello femoral"},
    {prodId:"42321800",label:"Implantes de rodilla"},
    {prodId:"42321801",label:"Componente de rodilla femoral"},
    {prodId:"42321802",label:"Tallo de extensión de rodilla femoral"},
    {prodId:"42321803",label:"Tallo de extensión de tibia"},
    {prodId:"42321804",label:"Implante de rótula"},
    {prodId:"42321805",label:"Placa de base para la tibia"},
    {prodId:"42321806",label:"Inserto para la tibia"},
    {prodId:"42321808",label:"Kit o sistema de remplazo total de rodilla"},
    {prodId:"42321809",label:"Aumento de tibia o fémur o rótula"},
    {prodId:"42321810",label:"Tornillo de placa de base de la tibia"},
    {prodId:"42321811",label:"Tornillo de conexión de aumento"},
    {prodId:"42321812",label:"Espaciador de articulación de rodilla"},
    {prodId:"42321813",label:"Cuña de rodilla femoral"},
    {prodId:"42321900",label:"Implantes de hombro"},
    {prodId:"42321902",label:"Cabeza de húmero"},
    {prodId:"42321903",label:"Tallo de húmero"},
    {prodId:"42321904",label:"Componente de húmero resurgente"},
    {prodId:"42321905",label:"Esfera glenoidea"},
    {prodId:"42321906",label:"Componente de fijación glenoidea"},
    {prodId:"42321907",label:"Revestimiento o protector de inserto de húmero"},
    {prodId:"42321908",label:"Restrictor de cemento para el canal humeral"},
    {prodId:"42321909",label:"Concha o receptáculo humeral"},
    {prodId:"42321910",label:"Centralizador o manga de tallo humeral"},
    {prodId:"42321911",label:"Cuerpo humeral"},
    {prodId:"42321912",label:"Kit o sistema de remplazo total o parcial de hombro"},
    {prodId:"42322000",label:"Implantes ortopédicos de articulaciones distales"},
    {prodId:"42322001",label:"Implante de articulación de tobillo"},
    {prodId:"42322002",label:"Implante de articulación de muñeca"},
    {prodId:"42322003",label:"Implante de articulación de codo"},
    {prodId:"42322004",label:"Implante de articulación de dedo"},
    {prodId:"42322005",label:"Implante de articulación de dedos del pie"},
    {prodId:"42322006",label:"Varilla o espaciador de tendones"},
    {prodId:"42322200",label:"Productos para la fijación de tejidos blandos"},
    {prodId:"42322201",label:"Tornillo de interferencia"},
    {prodId:"42322202",label:"Anclaje no suturado de tejidos blandos"},
    {prodId:"42322203",label:"Anclaje de sutura"},
    {prodId:"42322204",label:"Lavador de fijación de tejidos blandos"},
    {prodId:"42322205",label:"Perno de fijación de tejidos blandos"},
    {prodId:"42322206",label:"Grapa de tejidos blandos"},
    {prodId:"43191500",label:"Dispositivos de comunicación personal"},
    {prodId:"43191501",label:"Teléfonos móviles"},
    {prodId:"43191502",label:"Busca personas"},
    {prodId:"43191503",label:"Teléfonos de pago"},
    {prodId:"43191504",label:"Teléfonos fijos"},
    {prodId:"43191505",label:"Máquinas contestadoras"},
    {prodId:"43191507",label:"Teléfonos para propósitos especiales"},
    {prodId:"43191508",label:"Teléfonos digitales"},
    {prodId:"43191509",label:"Teléfonos análogos"},
    {prodId:"43191510",label:"Radios de dos vías"},
    {prodId:"43191511",label:"Teléfonos ip"},
    {prodId:"43191512",label:"Teléfonos inalámbricos de telecomunicaciones inalámbricas digitales optimizadas dect"},
    {prodId:"43191513",label:"Video teléfono"},
    {prodId:"43191514",label:"Teléfono satelital"},
    {prodId:"43191515",label:"Teléfono manos libres"},
    {prodId:"43191516",label:"Sistema de conmutador telefónico"},
    {prodId:"43191600",label:"Partes o accesorios de dispositivos de comunicación personal"},
    {prodId:"43191601",label:"Placas frontales de teléfonos móviles"},
    {prodId:"43191602",label:"Marcadores de teléfonos"},
    {prodId:"43191603",label:"Cables de extensión para teléfonos"},
    {prodId:"43191604",label:"Placas frontales de teléfonos"},
    {prodId:"43191605",label:"Cables para auriculares de teléfonos"},
    {prodId:"43191606",label:"Auriculares de teléfonos"},
    {prodId:"43191607",label:"Almohadillas para el oído o el parlante de los auriculares de teléfonos"},
    {prodId:"43191608",label:"Tubos de voz para los auriculares de teléfonos"},
    {prodId:"43191609",label:"Teléfonos de diadema"},
    {prodId:"43191610",label:"Soportes o sujetadores o puestos de comunicación personal"},
    {prodId:"43191611",label:"Protectores de líneas telefónicas"},
    {prodId:"43191612",label:"Descansa teléfonos"},
    {prodId:"43191614",label:"Convertidores de voz para teléfonos"},
    {prodId:"43191615",label:"Set telefónico manos libres para vehículos"},
    {prodId:"43191616",label:"Consola central para teléfonos"},
    {prodId:"43191618",label:"Unidades de grabación de conversaciones"},
    {prodId:"43191619",label:"Dispositivos de señalización para teléfonos"},
    {prodId:"43191621",label:"Adaptadores de auriculares"},
    {prodId:"43191622",label:"Módulos o accesorios de busca personas"},
    {prodId:"43191623",label:"Teléfono de pago con mecanismos"},
    {prodId:"43191624",label:"Recipientes de monedas para teléfonos de pago"},
    {prodId:"43191625",label:"Cajas de monedas para teléfonos de pago"},
    {prodId:"43191626",label:"Tolvas para teléfonos de pago"},
    {prodId:"43191627",label:"Puertas de seguridad para las cajas de monedas de teléfonos de pago"},
    {prodId:"43191628",label:"Protectores de micrófono para teléfonos de pago"},
    {prodId:"43191629",label:"Pieles o placas frontales para notebook o palm"},
    {prodId:"43191630",label:"Kits de iniciación para teléfonos móviles"},
    {prodId:"43191631",label:"Clavijas de adaptadores o kits de campo o kits de viaje para teléfonos o módems"},
    {prodId:"43191632",label:"Antenas para teléfonos"},
    {prodId:"43191633",label:"Subconjuntos para conmutadores telefónicos"},
    {prodId:"43191634",label:"Tablero de extensión para conmutador telefónico"},
    {prodId:"43201400",label:"Tarjetas del sistema"},
    {prodId:"43201401",label:"Tarjetas aceleradoras de gráficas o video"},
    {prodId:"43201402",label:"Tarjetas de módulos de memoria"},
    {prodId:"43201403",label:"Tarjetas de módem"},
    {prodId:"43201404",label:"Tarjetas de interface de red"},
    {prodId:"43201405",label:"Tarjetas receptoras de red óptica"},
    {prodId:"43201406",label:"Tarjetas transmisoras de red óptica"},
    {prodId:"43201407",label:"Tarjetas periféricas controladoras"},
    {prodId:"43201409",label:"Tarjetas de interface de red inalámbrica"},
    {prodId:"43201410",label:"Tarjetas o puertos de interruptor"},
    {prodId:"43201411",label:"Tarjeta de interconexión de componente periférico pci"},
    {prodId:"43201412",label:"Tarjeta pcmcia o pc"},
    {prodId:"43201413",label:"Tarjeta debanda magnética"},
    {prodId:"43201414",label:"Protector de disco duro"},
    {prodId:"43201415",label:"Tarjeta de módulo de identidad del suscriptor sim"},
    {prodId:"43201416",label:"Tablero de adquisición de datos"},
    {prodId:"43201417",label:"Tableros multimedia integrados"},
    {prodId:"43201500",label:"Módulos o interfaces de procesadores de tablero (board) del sistema"},
    {prodId:"43201501",label:"Tarjetas de interface de telecomunicaciones de modo de transferencia asincrónico atm"},
    {prodId:"43201502",label:"Tarjetas de aceleración de audio"},
    {prodId:"43201503",label:"Procesadores de unidad de procesamiento central cpu"},
    {prodId:"43201507",label:"Placas secundarias"},
    {prodId:"43201508",label:"Módulo de fibra de compensación de dispersión dcfm"},
    {prodId:"43201509",label:"Módulos de intercambio de comunicación de datos"},
    {prodId:"43201513",label:"Placa central de microordenador."},
    {prodId:"43201522",label:"Tarjetas de puertos paralelos"},
    {prodId:"43201531",label:"Tarjetas de captura de video"},
    {prodId:"43201533",label:"Interfaces de interface digital de instrumentos musicales midi"},
    {prodId:"43201534",label:"Interfaces de componentes de intercambio codec"},
    {prodId:"43201535",label:"Puertos seriales infrarrojos"},
    {prodId:"43201537",label:"Servidores de impresoras"},
    {prodId:"43201538",label:"Enfriadores de unidades de procesamiento central"},
    {prodId:"43201539",label:"Procesador central controlador de consola"},
    {prodId:"43201540",label:"Convertidor de canales"},
    {prodId:"43201541",label:"Procesador central de interface canal a canal"},
    {prodId:"43201542",label:"Unidad de control"},
    {prodId:"43201543",label:"Procesador central de instalación de acoplamientos"},
    {prodId:"43201544",label:"Convertidor o controlador de bus de interface"},
    {prodId:"43201545",label:"Tarjetas de fax"},
    {prodId:"43201546",label:"Tarjetas de audio conferencia"},
    {prodId:"43201547",label:"Tarjetas de voz"},
    {prodId:"43201549",label:"Interruptores de interface de bus"},
    {prodId:"43201550",label:"Dispositivo de sincronización de paquete de datos de red"},
    {prodId:"43201552",label:"Adaptadores para hardware o telefonía"},
    {prodId:"43201553",label:"Convertidores de transceptores y medios"},
    {prodId:"43201554",label:"Sintonizadores de televisión personal por computador pc tv"},
    {prodId:"43201555",label:"Sintonizadores de radio para pc"},
    {prodId:"43201556",label:"Adaptadores de interconexión de sistemas para pequeños computadores scsi"},
    {prodId:"43201557",label:"Controladores de arreglo redundante de discos independientes raid"},
    {prodId:"43201558",label:"Controlador de canales de fibra"},
    {prodId:"43201559",label:"Tarjetas de puerto serial"},
    {prodId:"43201560",label:"Coprocesador"},
    {prodId:"43201600",label:"Componentes del chasis"},
    {prodId:"43201601",label:"Carcasas de computadoras"},
    {prodId:"43201602",label:"Carcasas de equipos de red"},
    {prodId:"43201603",label:"Componentes de apilamiento de carcasas"},
    {prodId:"43201604",label:"Bahías o canastas para equipos electrónicos"},
    {prodId:"43201605",label:"Expansores"},
    {prodId:"43201608",label:"Marcos de drive removibles"},
    {prodId:"43201609",label:"Bandejas o ensamblajes para almacenamiento de dispositivos"},
    {prodId:"43201610",label:"Placas traseras o paneles o ensamblajes"},
    {prodId:"43201611",label:"Bastidores (dispositivos de montaje) para computadores"},
    {prodId:"43201612",label:"Placas frontales para computadores"},
    {prodId:"43201614",label:"Extensores de consola"},
    {prodId:"43201615",label:"Kits de cubiertas para bahías de drives"},
    {prodId:"43201616",label:"Torres de arreglo de drives de discos duros"},
    {prodId:"43201617",label:"Armazones para tarjetas"},
    {prodId:"43201618",label:"Componente de rack de computador"},
    {prodId:"43201619",label:"Ventilador enfriador de computador"},
    {prodId:"43201800",label:"Dispositivos de almacenamiento"},
    {prodId:"43201801",label:"Unidades de disco flexible"},
    {prodId:"43201802",label:"Series de disco duro"},
    {prodId:"43201803",label:"Unidades de disco duro"},
    {prodId:"43201806",label:"Series de cintas"},
    {prodId:"43201807",label:"Unidades de cintas"},
    {prodId:"43201808",label:"Disco compacto cd de sólo lectura"},
    {prodId:"43201809",label:"Disco compacto cd de lectura y escritura"},
    {prodId:"43201810",label:"Disco versátil digital dvd de sólo lectura"},
    {prodId:"43201811",label:"Disco versátil digital dvd de lectura y escritura"},
    {prodId:"43201812",label:"Unidades de magneto óptico mo"},
    {prodId:"43201813",label:"Unidades de medios removibles de alta capacidad"},
    {prodId:"43201814",label:"Equipos electrónicos de duplicación de medios o información"},
    {prodId:"43201815",label:"Unidades de lectura y escritura de arquitectura de interconexión de componentes periféricos"},
    {prodId:"43201816",label:"Unidades ópticas de ultra densidad udo o autocargardores o bibliotecas"},
    {prodId:"43201817",label:"Unidades de disco compacto cd"},
    {prodId:"43201818",label:"Unidades de discos digitales de video dvd"},
    {prodId:"43201819",label:"Disco versátil digital de alta definición hd dvd de sólo lectura"},
    {prodId:"43201820",label:"Disco versátil digital de alta definición hd dvd de lectura y escritura"},
    {prodId:"43201821",label:"Disco de blu-ray bd de sólo lectura"},
    {prodId:"43201822",label:"Disco de blu-ray br de lectura y escritura"},
    {prodId:"43201823",label:"Reproductor de unidades multimedia de disco duro hdd"},
    {prodId:"43201825",label:"Publicador de discos"},
    {prodId:"43201826",label:"Series de memoria de discos compactos cd de sólo lectura"},
    {prodId:"43201827",label:"Dispositivo de almacenamiento de disco duro portátil"},
    {prodId:"43201828",label:"Audio disco de video digital dvd a"},
    {prodId:"43201829",label:"Video disco de video digital dvd v"},
    {prodId:"43201830",label:"Unidad de estado sólido ssd"},
    {prodId:"43201831",label:"Unidad de memoria de telecomunicaciones"},
    {prodId:"43201832",label:"Cargador de discos (juke box) compactos u ópticos"},
    {prodId:"43201833",label:"Sistema de conversión de digital a análogo"},
    {prodId:"43201834",label:"Controlador de dispositivo de almacenamiento"},
    {prodId:"43201835",label:"Dispositivo de almacenamiento unido a la red nas"},
    {prodId:"43201900",label:"Accesorios de dispositivos de almacenamiento"},
    {prodId:"43201902",label:"Cambiadores de discos ópticos"},
    {prodId:"43201903",label:"Bibliotecas de unidades de cinta"},
    {prodId:"43201904",label:"Audio video de video discos digitales dvd av"},
    {prodId:"43202000",label:"Dispositivos de almacenamiento extraíbles"},
    {prodId:"43202001",label:"Discos compactos cd"},
    {prodId:"43202002",label:"Cintas en blanco"},
    {prodId:"43202003",label:"Discos versátiles digitales dvd"},
    {prodId:"43202004",label:"Discos flexibles"},
    {prodId:"43202005",label:"Tarjeta flash de almacenamiento de memoria"},
    {prodId:"43202006",label:"Discos magneto ópticos mo"},
    {prodId:"43202007",label:"Discos en blanco de medios removibles de gran capacidad"},
    {prodId:"43202008",label:"Discos en blanco de alta densidad óptica udo"},
    {prodId:"43202009",label:"Cintas de audio en blanco"},
    {prodId:"43202010",label:"Unidad pen o flash"},
    {prodId:"43202100",label:"Accesorios de dispositivos de almacenamiento extraíbles"},
    {prodId:"43202101",label:"Estuches para discos compactos"},
    {prodId:"43202102",label:"Estuches para discos flexibles"},
    {prodId:"43202103",label:"Sujetadores de almacenadores de multimedia"},
    {prodId:"43202104",label:"Almacenadores para video cintas de escáner de hélice vertical vhs o accesorios"},
    {prodId:"43202105",label:"Gabinetes para medios múltiples"},
    {prodId:"43202106",label:"Estuches o billeteras para medios portátiles"},
    {prodId:"43202107",label:"Maleta para discos compactos o discos digitales versátiles cd/dvd"},
    {prodId:"43202108",label:"Estuche para tarjeta de memoria"},
    {prodId:"43202200",label:"Subconjuntos para dispositivos electrónicos"},
    {prodId:"43202201",label:"Partes de piezas de teléfono"},
    {prodId:"43202202",label:"Generadores de timbres de teléfono"},
    {prodId:"43202204",label:"Timbres externos o sus partes"},
    {prodId:"43202205",label:"Teclas o teclados"},
    {prodId:"43202206",label:"Componentes de dispositivo de entrada o unidad de almacenamiento"},
    {prodId:"43202207",label:"Brazos y ensamblaje de cabezal"},
    {prodId:"43202208",label:"Ensamblajes de plomo"},
    {prodId:"43202209",label:"Ensamblajes de apilamiento de cabezales"},
    {prodId:"43202210",label:"Tope de protección anti choque"},
    {prodId:"43202211",label:"Platos o discos"},
    {prodId:"43202212",label:"Ensamblajes de cabezal de lectura y escritura"},
    {prodId:"43202213",label:"Unidades de disco de motor"},
    {prodId:"43202214",label:"Ensamblajes de regleta"},
    {prodId:"43202215",label:"Cable de teclado o mouse"},
    {prodId:"43202216",label:"Cable de conexión de impresora"},
    {prodId:"43202217",label:"Sujetador de cables de computador"},
    {prodId:"43202218",label:"Cubierta de cables de computador"},
    {prodId:"43202219",label:"Ensamblaje de cabezales de control de aire acondicionado de ventilación de automotores hvac"},
    {prodId:"43202220",label:"Módulo de energía de aire acondicionado de ventilación de automotores hvac"},
    {prodId:"43202221",label:"Ensamblaje de resistencias de aire acondicionado de ventilación de automotores hvac"},
    {prodId:"43202222",label:"Cables de computador"},
    {prodId:"43211500",label:"Computadores"},
    {prodId:"43211501",label:"Servidores de computador"},
    {prodId:"43211502",label:"Servidores de computador de gama alta"},
    {prodId:"43211503",label:"Computadores notebook"},
    {prodId:"43211504",label:"Asistentes personales digitales pda u organizadores"},
    {prodId:"43211505",label:"Terminal de punto de venta pos"},
    {prodId:"43211506",label:"Computadores de cliente ligero"},
    {prodId:"43211507",label:"Computadores de escritorio"},
    {prodId:"43211508",label:"Computadores personales"},
    {prodId:"43211509",label:"Computadores de tableta"},
    {prodId:"43211510",label:"Consola central o terminales básicos (no inteligentes)"},
    {prodId:"43211511",label:"Dispositivos de computación de vestir"},
    {prodId:"43211512",label:"Computadores centrales"},
    {prodId:"43211513",label:"Computadores personales ultra móviles"},
    {prodId:"43211514",label:"Kioscos de computadores"},
    {prodId:"43211515",label:"Estaciones de trabajo para computadores"},
    {prodId:"43211516",label:"Minicomputadores"},
    {prodId:"43211517",label:"Computadores análogos"},
    {prodId:"43211518",label:"Computadores de múltiples pantallas"},
    {prodId:"43211600",label:"Accesorios de computador"},
    {prodId:"43211601",label:"Cajas de interruptores de computador"},
    {prodId:"43211602",label:"Estaciones de acoplamiento"},
    {prodId:"43211603",label:"Replicadores de puertos"},
    {prodId:"43211604",label:"Cajas de interruptores periféricos"},
    {prodId:"43211605",label:"Actualizaciones de procesadores de señales"},
    {prodId:"43211606",label:"Kits de multimedia"},
    {prodId:"43211607",label:"Parlantes de computador"},
    {prodId:"43211608",label:"Equipo codificador y decodificador"},
    {prodId:"43211609",label:"Concentrador de bus serial universal o conectores"},
    {prodId:"43211610",label:"Kits de países o localización para computadores"},
    {prodId:"43211611",label:"Kits de arranque de mano pda"},
    {prodId:"43211612",label:"Kits de accesorios de computador"},
    {prodId:"43211613",label:"Puestos para computadores o notebooks"},
    {prodId:"43211614",label:"Adaptador de bus serial universal usb bluetooth"},
    {prodId:"43211615",label:"Puerto de expansión de computador notebook"},
    {prodId:"43211616",label:"Sujetador de asistente personal digital pda"},
    {prodId:"43211617",label:"Cable de extensión de bus serial universal usb"},
    {prodId:"43211618",label:"Módem de radio servicio de paquete general de bus serial universal"},
    {prodId:"43211619",label:"Estuche para transportar computadores notebook"},
    {prodId:"43211700",label:"Dispositivos informáticos de entrada de datos"},
    {prodId:"43211701",label:"Equipo de lectura de código de barras"},
    {prodId:"43211702",label:"Lectores y codificadores de banda magnética"},
    {prodId:"43211704",label:"Equipo de reconocimiento de billetes"},
    {prodId:"43211705",label:"Almohadillas o joy sticks de juegos"},
    {prodId:"43211706",label:"Teclados"},
    {prodId:"43211707",label:"Lápiz (stylus) para computador de luz"},
    {prodId:"43211708",label:"Mouse o bola de seguimiento para computador"},
    {prodId:"43211709",label:"Lápiz (stylus) de presión"},
    {prodId:"43211710",label:"Dispositivos de identificación de radio frecuencia"},
    {prodId:"43211711",label:"Escáneres"},
    {prodId:"43211712",label:"Tabletas de gráficos"},
    {prodId:"43211713",label:"Almohadillas (pads) táctil (touch)"},
    {prodId:"43211714",label:"Equipos de identificación biométrica"},
    {prodId:"43211715",label:"Terminales portátiles de ingreso de información"},
    {prodId:"43211717",label:"Sistemas de reconocimiento de caracteres ópticos"},
    {prodId:"43211718",label:"Sistemas de visión basados en cámaras para recolección automática de información"},
    {prodId:"43211719",label:"Micrófonos de voz para computadores"},
    {prodId:"43211720",label:"Terminales de pago para puntos de venta"},
    {prodId:"43211721",label:"Lectores de tarjetas perforadas"},
    {prodId:"43211722",label:"Escáneres de tarjetas de presentación"},
    {prodId:"43211723",label:"Equipos de votación electrónica o de conteo de votos"},
    {prodId:"43211724",label:"Kit periférico para computador"},
    {prodId:"43211725",label:"Limpiador de discos compactos o de discos versátiles digitales cd/dvd"},
    {prodId:"43211726",label:"Esferográfico digital"},
    {prodId:"43211727",label:"Dispositivo magnético de reconocimiento de caracteres en tinta micr"},
    {prodId:"43211728",label:"Terminal simple de tarjetas inteligentes"},
    {prodId:"43211729",label:"Lector de marcas ópticas"},
    {prodId:"43211730",label:"Sistema de adquisición de datos"},
    {prodId:"43211731",label:"Analizador de datos"},
    {prodId:"43211732",label:"Lector de películas digitales"},
    {prodId:"43211800",label:"Accesorios de dispositivos de entrada de datos de computador"},
    {prodId:"43211801",label:"Forros de dispositivos de ingreso de datos al computador"},
    {prodId:"43211802",label:"Almohadillas (pads) para mouse"},
    {prodId:"43211803",label:"Pieles (películas transparentes) para teclados"},
    {prodId:"43211804",label:"Cajones o estantes para tableros"},
    {prodId:"43211805",label:"Dispositivos para almacenamiento de kits de servicio"},
    {prodId:"43211806",label:"Descansa muñecas para teclado"},
    {prodId:"43211807",label:"Descansa muñecas para mouse"},
    {prodId:"43211900",label:"Monitores y pantallas de computador"},
    {prodId:"43211901",label:"Monitores para tubos de rayo catódico crt"},
    {prodId:"43211902",label:"Paneles o monitores de pantalla de cristal líquido lcd"},
    {prodId:"43211903",label:"Monitores de pantalla táctil (touch)"},
    {prodId:"43211904",label:"Paneles de pantalla de plasma pdp"},
    {prodId:"43211905",label:"Pantallas emisoras de luz orgánica"},
    {prodId:"43211906",label:"Pantallas de caracteres"},
    {prodId:"43211907",label:"Cascos de realidad virtual"},
    {prodId:"43211908",label:"Pantallas de vacío fluorescente"},
    {prodId:"43211909",label:"Pantallas emisoras de diodos de poli luz"},
    {prodId:"43211910",label:"Mecanismo de inclinación de pantallas"},
    {prodId:"43211911",label:"Vidrio de pantalla táctil (touch)"},
    {prodId:"43211912",label:"Película de pantalla táctil (touch)"},
    {prodId:"43211913",label:"Pantalla activa lcd"},
    {prodId:"43211914",label:"Pantalla pasiva lcd"},
    {prodId:"43212000",label:"Accesorios de pantallas de computador"},
    {prodId:"43212001",label:"Protectores de brillo para pantallas de computador"},
    {prodId:"43212002",label:"Brazos o soportes para monitores"},
    {prodId:"43212100",label:"Impresoras de computador"},
    {prodId:"43212101",label:"Impresoras de banda"},
    {prodId:"43212102",label:"Impresoras de matriz de puntos"},
    {prodId:"43212104",label:"Impresoras de inyección de tinta"},
    {prodId:"43212105",label:"Impresoras láser"},
    {prodId:"43212106",label:"Impresoras de matriz de líneas"},
    {prodId:"43212107",label:"Impresoras de plotter"},
    {prodId:"43212108",label:"Impresoras de cinta térmica"},
    {prodId:"43212109",label:"Impresoras de etiquetas para bolsas"},
    {prodId:"43212110",label:"Impresoras de múltiples funciones"},
    {prodId:"43212111",label:"Impresoras de tiquetes aéreos o pases de abordaje atb"},
    {prodId:"43212112",label:"Impresoras de recibos para puntos de venta pos"},
    {prodId:"43212113",label:"Impresoras de discos compactos cd o de etiquetado"},
    {prodId:"43212114",label:"Impresoras de imágenes digitales"},
    {prodId:"43212115",label:"Impresoras de códigos de barra"},
    {prodId:"43212116",label:"Impresoras de etiquetas de identificación de radio frecuencia rfid"},
    {prodId:"43212117",label:"Controlador para impresoras"},
    {prodId:"43212200",label:"Sistemas de manejo de almacenamiento de datos de computador"},
    {prodId:"43212201",label:"Sistema de almacenamiento virtual"},
    {prodId:"43221500",label:"Sistemas de gestión de llamadas o accesorios"},
    {prodId:"43221501",label:"Sistemas de atención automatizada"},
    {prodId:"43221502",label:"Distribuidor automático de llamadas acd"},
    {prodId:"43221503",label:"Alto parlantes para telecomunicaciones"},
    {prodId:"43221504",label:"Central telefónica interna pbx"},
    {prodId:"43221505",label:"Identificador de llamadas telefónicas autónoma"},
    {prodId:"43221506",label:"Consola de teleconferencias"},
    {prodId:"43221507",label:"Auto marcadores"},
    {prodId:"43221508",label:"Campos de lámpara de teléfono ocupado"},
    {prodId:"43221509",label:"Sistemas de contabilización de llamadas telefónicas"},
    {prodId:"43221510",label:"Desviador o retransmisor de llamadas telefónicas"},
    {prodId:"43221513",label:"Secuenciadores de llamadas telefónicas"},
    {prodId:"43221514",label:"Unidades de respaldo de marcación telefónica"},
    {prodId:"43221515",label:"Dispositivos para compartir líneas telefónicas"},
    {prodId:"43221516",label:"Monitores de estado de líneas telefónicas"},
    {prodId:"43221517",label:"Unidades de observación de servicio de equipos de telefonía"},
    {prodId:"43221518",label:"Dispositivos para restringir llamadas para equipos de telefonía"},
    {prodId:"43221519",label:"Sistemas de correo de voz"},
    {prodId:"43221520",label:"Equipos interactivos de reconocimiento de voz"},
    {prodId:"43221521",label:"Unidades de acceso remoto de telecomunicaciones"},
    {prodId:"43221522",label:"Equipos de teleconferencia"},
    {prodId:"43221523",label:"Grabadora de música o mensaje en espera"},
    {prodId:"43221524",label:"Adaptador de música en espera"},
    {prodId:"43221525",label:"Sistemas de intercomunicación"},
    {prodId:"43221526",label:"Sistemas de entrada de teléfono"},
    {prodId:"43221527",label:"Protector de “chuzadas” (acceso ilegal) de cable"},
    {prodId:"43221528",label:"Unidad de señales de supervisión"},
    {prodId:"43221529",label:"Medidor de llamadas"},
    {prodId:"43221530",label:"Dispositivo de señales de conmutador"},
    {prodId:"43221700",label:"Equipo fijo de red y componentes"},
    {prodId:"43221701",label:"Equipo central de televisión"},
    {prodId:"43221702",label:"Equipo de acceso de televisión"},
    {prodId:"43221703",label:"Antenas de televisión"},
    {prodId:"43221704",label:"Equipo central de radio"},
    {prodId:"43221705",label:"Equipo de radio acceso"},
    {prodId:"43221706",label:"Antenas de radio"},
    {prodId:"43221707",label:"Equipo central de microondas"},
    {prodId:"43221708",label:"Equipo de acceso de microondas"},
    {prodId:"43221709",label:"Antenas de microondas"},
    {prodId:"43221710",label:"Equipo central de satélite"},
    {prodId:"43221711",label:"Equipo de acceso de satélite"},
    {prodId:"43221712",label:"Antenas de satélite"},
    {prodId:"43221713",label:"Equipo central de onda corta"},
    {prodId:"43221714",label:"Equipo de acceso de onda corta"},
    {prodId:"43221715",label:"Antenas de onda corta"},
    {prodId:"43221716",label:"Equipo central de buscapersonas"},
    {prodId:"43221717",label:"Equipo de acceso de buscapersonas"},
    {prodId:"43221718",label:"Antenas de radar"},
    {prodId:"43221719",label:"Antenas de aeronaves"},
    {prodId:"43221720",label:"Antenas de automotores"},
    {prodId:"43221721",label:"Equipo de comunicación de información de radio frecuencia"},
    {prodId:"43221722",label:"Antena de sistema de posicionamiento geográfico gps"},
    {prodId:"43221723",label:"Antena de celular"},
    {prodId:"43221724",label:"Generador sincronizado de tiempo"},
    {prodId:"43221725",label:"Divisor de señales"},
    {prodId:"43221726",label:"Reflector para antenas"},
    {prodId:"43221727",label:"Accesorio para antenas"},
    {prodId:"43221728",label:"Radomos o domo para radares"},
    {prodId:"43221729",label:"Antenas portátiles"},
    {prodId:"43221730",label:"Transmisor de telecomunicaciones por cable"},
    {prodId:"43221731",label:"Sistema de comunicación bajo el agua"},
    {prodId:"43221732",label:"Unidad de control de altavoces"},
    {prodId:"43221733",label:"Sistema de medición de lectura automática remota"},
    {prodId:"43221800",label:"Dispositivos de red óptica"},
    {prodId:"43221801",label:"Amplificadores ópticos"},
    {prodId:"43221802",label:"Filtros de redes o comunicaciones ópticas"},
    {prodId:"43221803",label:"Adaptadores ópticos"},
    {prodId:"43221804",label:"Láseres de red óptica"},
    {prodId:"43221805",label:"Equipo de red de modo de transferencia asincrónica atm"},
    {prodId:"43221806",label:"Equipos de red de red óptica sincrónica sonet"},
    {prodId:"43221807",label:"Filtros multiplexados de división de longitud de onda densa de telecomunicaciones dwdm"},
    {prodId:"43221808",label:"Equipos de telecomunicaciones jerárquicas digitales sincrónicas sdh"},
    {prodId:"43221809",label:"Transmisor óptico"},
    {prodId:"43221810",label:"Multiplexador óptico"},
    {prodId:"43221811",label:"Interruptor óptico"},
    {prodId:"43222500",label:"Equipo de seguridad de red"},
    {prodId:"43222501",label:"Equipo de seguridad de red cortafuegos (firewall)"},
    {prodId:"43222502",label:"Equipo de seguridad de redes vpn"},
    {prodId:"43222503",label:"Equipos de seguridad de evaluación de vulnerabilidad"},
    {prodId:"43222504",label:"Transmisor de interferencias de radio"},
    {prodId:"43222600",label:"Equipo de servicio de red"},
    {prodId:"43222602",label:"Equipo de centro distribuidor de cables"},
    {prodId:"43222604",label:"Equipo de red de entrega de contenido"},
    {prodId:"43222605",label:"Puerta de acceso de redes"},
    {prodId:"43222606",label:"Kit de inicio de nodo de servicio de internet"},
    {prodId:"43222607",label:"Equipo de motor de caché"},
    {prodId:"43222608",label:"Repetidores de red"},
    {prodId:"43222609",label:"Enrutadores (routers) de red"},
    {prodId:"43222610",label:"Concentradores o hubs de servicio de red"},
    {prodId:"43222611",label:"Unidades de servicio de canales o información de red"},
    {prodId:"43222612",label:"Interruptores de red"},
    {prodId:"43222615",label:"Interruptor de área de almacenamiento de red san"},
    {prodId:"43222619",label:"Equipo de video de red"},
    {prodId:"43222620",label:"Interruptor multi servicios"},
    {prodId:"43222621",label:"Interruptor de contenido"},
    {prodId:"43222622",label:"Dispositivo para balancear la carga del servidor"},
    {prodId:"43222623",label:"Equipos de conexión cruzada digital dcx"},
    {prodId:"43222624",label:"Equipos de conexión cruzada óptica"},
    {prodId:"43222625",label:"Servidores de acceso"},
    {prodId:"43222626",label:"Módems de cable"},
    {prodId:"43222627",label:"Dispositivos de acceso de redes digitales de servicios integrados isdn"},
    {prodId:"43222628",label:"Módems"},
    {prodId:"43222629",label:"Bancos de módems"},
    {prodId:"43222630",label:"Unidades de acceso multi estaciones"},
    {prodId:"43222631",label:"Estaciones base de fidelidad inalámbricas"},
    {prodId:"43222632",label:"Agregadores de banda ancha"},
    {prodId:"43222633",label:"Adaptadores de manejo remoto"},
    {prodId:"43222634",label:"Dispositivo de monitoreo o manejo de redes"},
    {prodId:"43222635",label:"Kit de actualización de equipo de redes"},
    {prodId:"43222636",label:"Motor de aplicación de redes"},
    {prodId:"43222637",label:"Red óptica pasiva de gigabytes gpon"},
    {prodId:"43222638",label:"Equipos de red de línea dedicada manejada mlln"},
    {prodId:"43222639",label:"Hardware de subsistemas multimedia de protocolo de internet ip"},
    {prodId:"43222640",label:"Punto de acceso inalámbrico"},
    {prodId:"43222641",label:"Dispositivo de protocolo de internet compartido"},
    {prodId:"43222642",label:"Interruptor de enrutador (router)"},
    {prodId:"43222643",label:"Probador de cable de red"},
    {prodId:"43222644",label:"Controlador de tráfico de red"},
    {prodId:"43222700",label:"Equipo telegráfico"},
    {prodId:"43222701",label:"Teclas de telégrafo"},
    {prodId:"43222702",label:"Electro magnetos de telégrafo"},
    {prodId:"43222703",label:"Receptor acústico de telégrafo"},
    {prodId:"43222704",label:"Conmutador de telégrafo"},
    {prodId:"43222800",label:"Equipo de telefonía"},
    {prodId:"43222801",label:"Equipo de conexión cruzada digital dcx de banda estrecha o de banda ancha"},
    {prodId:"43222802",label:"Equipo de circuito de conmutador"},
    {prodId:"43222803",label:"Cargador de circuito digital dlc"},
    {prodId:"43222805",label:"Equipo de central telefónica privada pbx"},
    {prodId:"43222806",label:"Bloques de conexiones"},
    {prodId:"43222811",label:"Unidades de alarma de equipos de telefonía"},
    {prodId:"43222813",label:"Kits de partes de conmutadores telefónicos"},
    {prodId:"43222814",label:"Kits de instalación o modificación de equipos de telecomunicaciones"},
    {prodId:"43222815",label:"Unidades de terminales de telecomunicaciones"},
    {prodId:"43222816",label:"Operadores de introducción de datos de telefonía"},
    {prodId:"43222817",label:"Repetidores de telecomunicaciones"},
    {prodId:"43222818",label:"Estructura de terminal de distribución de teléfono"},
    {prodId:"43222819",label:"Paneles de conexión de puertos"},
    {prodId:"43222820",label:"Equipo electrónico para cancelar eco de voz"},
    {prodId:"43222822",label:"Multiplexor de división de tiempo tdm"},
    {prodId:"43222823",label:"Multiplexor de división de onda wdm"},
    {prodId:"43222824",label:"Enrolladores de cables aéreos"},
    {prodId:"43222825",label:"Kits de modificación de teléfonos"},
    {prodId:"43222826",label:"Terminal portadora"},
    {prodId:"43222827",label:"Multiplexor o mux"},
    {prodId:"43222900",label:"Accesorios de equipo de telefonía"},
    {prodId:"43222901",label:"Acondicionadores de línea"},
    {prodId:"43222902",label:"Secadores de aire para cables de telefonía"},
    {prodId:"43222903",label:"Torre o soporte para telecomunicaciones"},
    {prodId:"43223100",label:"Componentes y equipo de infraestructura de redes móviles y digitales"},
    {prodId:"43223101",label:"Equipos y componentes de red básica móvil gsm 2g"},
    {prodId:"43223102",label:"Equipos y componentes de red de acceso inalámbrica gsm 2g"},
    {prodId:"43223103",label:"Equipos y componentes de red básica móvil gprs 2.5g"},
    {prodId:"43223104",label:"Equipos y componentes de red de acceso inalámbrica gprs 2.5g"},
    {prodId:"43223105",label:"Equipos y componentes de red básica móvil umts 3g"},
    {prodId:"43223106",label:"Equipos y componentes de red de acceso inalámbrica 3g umts"},
    {prodId:"43223107",label:"Equipos y componentes de red básica móvil wlan"},
    {prodId:"43223108",label:"Equipos y componentes de acceso inalámbrico wlan"},
    {prodId:"43223109",label:"Equipo de interrupción ssp in"},
    {prodId:"43223110",label:"Equipo básico móvil in"},
    {prodId:"43223111",label:"Equipos y componentes de red básica móvil oss"},
    {prodId:"43223112",label:"Equipos y componentes de red de acceso inalámbrica oss"},
    {prodId:"43223113",label:"Antenas lan umt gsm"},
    {prodId:"43223300",label:"Dispositivos y equipos para instalación de conectividad de redes y Datacom"},
    {prodId:"43223301",label:"Sistema y accesorios de conexión cruzada de comunicación de datos"},
    {prodId:"43223302",label:"Etiqueta de comunicación de datos"},
    {prodId:"43223303",label:"Cable de conexión de comunicación de datos"},
    {prodId:"43223305",label:"Ensamblaje de paneles de manejo de cables de red"},
    {prodId:"43223306",label:"Gabinete o cerramiento para sistemas de red"},
    {prodId:"43223307",label:"Caja de cables para sistemas de red"},
    {prodId:"43223308",label:"Estantes para equipos de sistemas de red"},
    {prodId:"43223309",label:"Panel de conexión"},
    {prodId:"43223310",label:"Conector de fibra óptica"},
    {prodId:"43223311",label:"Atenuador de fibra óptica"},
    {prodId:"43223312",label:"Pela cables de fibra óptica"},
    {prodId:"43223313",label:"Engarzador de fibra óptica"},
    {prodId:"43223314",label:"Dispositivo para pulir fibra óptica"},
    {prodId:"43223315",label:"Set de cuchillas para herramientas de fibra óptica"},
    {prodId:"43223316",label:"Punzón de fibra óptica"},
    {prodId:"43223317",label:"Removedor de búfer de fibra óptica"},
    {prodId:"43223318",label:"Emisor de fibra óptica"},
    {prodId:"43223319",label:"Detector de fibra óptica"},
    {prodId:"43223320",label:"Probador de continuidad de fibra óptica"},
    {prodId:"43223321",label:"Clip conector de fibra óptica"},
    {prodId:"43223322",label:"Gancho de soporte de fibra óptica"},
    {prodId:"43223323",label:"Adaptador de fibra óptica"},
    {prodId:"43223324",label:"Película pulidora de fibra óptica"},
    {prodId:"43223325",label:"Hisopos para fibra óptica"},
    {prodId:"43223326",label:"Cable de conexión de fibra óptica"},
    {prodId:"43223327",label:"Localizador de fallas de fibra óptica"},
    {prodId:"43223328",label:"Panel de conexión de fibra óptica"},
    {prodId:"43223329",label:"Fuente de luz de fibra óptica"},
    {prodId:"43223330",label:"Herramientas de ensamblaje de conector de fibra óptica"},
    {prodId:"43223331",label:"Inserto de fibra óptica"},
    {prodId:"43223332",label:"Kit de herramientas de fibra óptica"},
    {prodId:"43223333",label:"Lente de alcance de fibra óptica"},
    {prodId:"43223334",label:"Set de herramientas y tintes de fibra óptica"},
    {prodId:"43223335",label:"Cable de música de fibra óptica"},
    {prodId:"43223336",label:"Adaptador de alcance de fibra óptica"},
    {prodId:"43223337",label:"Cerramiento de fibra óptica"},
    {prodId:"43223338",label:"Cerramiento de conexión de fibra óptica"},
    {prodId:"43223339",label:"Caja de distribución de fibra óptica"},
    {prodId:"43223340",label:"Empalmadora de fusión de fibra óptica"},
    {prodId:"43223341",label:"Combinadora"},
    {prodId:"43223342",label:"Kit de conexión y guía de onda"},
    {prodId:"43223343",label:"Herramienta de “punchdown” de red"},
    {prodId:"43231500",label:"Software funcional específico de la empresa"},
    {prodId:"43231501",label:"Software de mesa de ayuda o centro de llamadas (call center)"},
    {prodId:"43231503",label:"Software de adquisiciones"},
    {prodId:"43231505",label:"Software de recursos humanos"},
    {prodId:"43231506",label:"Software de logística de planeación de requerimiento de materiales y cadena de suministros"},
    {prodId:"43231507",label:"Software de manejo de proyectos"},
    {prodId:"43231508",label:"Software de manejo de inventarios"},
    {prodId:"43231509",label:"Software de barras de códigos"},
    {prodId:"43231510",label:"Software para hacer etiquetas"},
    {prodId:"43231511",label:"Software de sistemas expertos"},
    {prodId:"43231512",label:"Software de manejo de licencias"},
    {prodId:"43231513",label:"Software para oficinas"},
    {prodId:"43231514",label:"Software de ventas y mercadeo"},
    {prodId:"43231515",label:"Software de envío y embarque"},
    {prodId:"43231600",label:"Software de planificación de recursos empresariales (ERP) y contabilidad financiera"},
    {prodId:"43231601",label:"Software de contabilidad"},
    {prodId:"43231602",label:"Software de planeación de recursos del negocio erp"},
    {prodId:"43231603",label:"Software de preparación tributaria"},
    {prodId:"43231604",label:"Software de análisis financiero"},
    {prodId:"43231605",label:"Software de contabilidad de tiempo"},
    {prodId:"43232000",label:"Software de entretenimiento o juegos de computador"},
    {prodId:"43232001",label:"Juegos de acción"},
    {prodId:"43232002",label:"Juegos de aventuras"},
    {prodId:"43232003",label:"Juegos de deportes"},
    {prodId:"43232004",label:"Software familiar"},
    {prodId:"43232005",label:"Software de edición de música o sonido"},
    {prodId:"43232300",label:"Software de consultas y gestión de datos"},
    {prodId:"43232301",label:"Software de categorización o clasificación"},
    {prodId:"43232302",label:"Software de agrupamiento de recursos"},
    {prodId:"43232303",label:"Software de manejo de relaciones con el cliente crm"},
    {prodId:"43232304",label:"Software de sistemas de manejo de base datos"},
    {prodId:"43232305",label:"Software de reportes de bases de datos"},
    {prodId:"43232306",label:"Software de interface y preguntas de usuario de base de datos"},
    {prodId:"43232307",label:"Software de extracción de datos"},
    {prodId:"43232309",label:"Software de recuperación o búsqueda de información"},
    {prodId:"43232310",label:"Software de manejo de metadata"},
    {prodId:"43232311",label:"Software de manejo de base de datos orientada al objeto"},
    {prodId:"43232312",label:"Software de servidor de portales"},
    {prodId:"43232313",label:"Software de servidor de transacciones"},
    {prodId:"43232400",label:"Programas de desarrollo"},
    {prodId:"43232401",label:"Software de manejo de configuraciones"},
    {prodId:"43232402",label:"Software de entorno de desarrollo"},
    {prodId:"43232403",label:"Software de integración de aplicaciones de empresas"},
    {prodId:"43232404",label:"Software de desarrollo de interface de usuario gráfica"},
    {prodId:"43232405",label:"Software de desarrollo orientado a objetos o componentes"},
    {prodId:"43232406",label:"Software de pruebas de programas"},
    {prodId:"43232407",label:"Software de arquitectura de sistemas y análisis de requerimientos"},
    {prodId:"43232408",label:"Software de desarrollo de plataformas web"},
    {prodId:"43232409",label:"Software para compilar y descompilar"},
    {prodId:"43232500",label:"Software educativo o de referencia"},
    {prodId:"43232501",label:"Software de idiomas extranjeros (traductores)"},
    {prodId:"43232502",label:"Software de entrenamiento basado en computadores"},
    {prodId:"43232503",label:"Correctores de ortografía"},
    {prodId:"43232504",label:"Software de navegación de rutas"},
    {prodId:"43232505",label:"Software educacional multimedios"},
    {prodId:"43232506",label:"Software de enciclopedias"},
    {prodId:"43232507",label:"Software de diccionarios"},
    {prodId:"43232508",label:"Software de libretas de teléfonos"},
    {prodId:"43232600",label:"Software específico para la industria"},
    {prodId:"43232601",label:"Software de soporte de aviación en tierra"},
    {prodId:"43232602",label:"Software de pruebas de aviación"},
    {prodId:"43232603",label:"Software de manejo de instalaciones"},
    {prodId:"43232604",label:"Software de diseño asistido de computador cad"},
    {prodId:"43232605",label:"Software analítico o científico"},
    {prodId:"43232606",label:"Software de cumplimiento (compliance)"},
    {prodId:"43232607",label:"Software de control de vuelos"},
    {prodId:"43232608",label:"Software de control industrial"},
    {prodId:"43232609",label:"Software de bibliotecas"},
    {prodId:"43232610",label:"Software médico"},
    {prodId:"43233200",label:"Software de seguridad y protección"},
    {prodId:"43233201",label:"Software de servidor de autenticación"},
    {prodId:"43233203",label:"Software de manejo de seguridad de red o de redes privadas virtuales vpn"},
    {prodId:"43233204",label:"Software de equipos de seguridad de red y de redes privadas virtuales vpn"},
    {prodId:"43233205",label:"Software de seguridad de transacciones y de protección contra virus"},
    {prodId:"43233400",label:"Software de controladores de dispositivos y utilidades"},
    {prodId:"43233401",label:"Software de servidor de discos compactos cd"},
    {prodId:"43233402",label:"Software de conversión de información"},
    {prodId:"43233403",label:"Software de compresión de información"},
    {prodId:"43233404",label:"Software discos compactos cd o dvd o tarjetas de sonido"},
    {prodId:"43233405",label:"Software de controladores o sistemas de dispositivos"},
    {prodId:"43233406",label:"Software de controladores de ethernet"},
    {prodId:"43233407",label:"Software de controladores de tarjetas de gráficos"},
    {prodId:"43233410",label:"Software de controladores de impresoras"},
    {prodId:"43233411",label:"Software de protectores de pantalla"},
    {prodId:"43233413",label:"Software de reconocimiento de voz"},
    {prodId:"43233414",label:"Software de carga de almacenamiento de medios"},
    {prodId:"43233415",label:"Software de respaldo o archivo"},
    {prodId:"43233416",label:"Plataformas de codificación - decodificación"},
    {prodId:"43233417",label:"Componentes de software de reconocimiento de escritura manuscrita"},
    {prodId:"43233418",label:"Controladores de memoria"},
    {prodId:"43233419",label:"Plataformas de multimedia"},
    {prodId:"43233420",label:"Software de conversión de texto a voz"},
    {prodId:"43233421",label:"Controladores de video"},
    {prodId:"43233600",label:"Software de equipo eléctrico"},
    {prodId:"43233601",label:"Software de controlador del motor"},
    {prodId:"43233602",label:"Software de monitor de energía"},
    {prodId:"43233603",label:"Software de control lógico programable"},
    {prodId:"44101500",label:"Máquinas de duplicación"},
    {prodId:"44101501",label:"Fotocopiadoras"},
    {prodId:"44101503",label:"Máquinas multifuncionales"},
    {prodId:"44101504",label:"Emisores (senders) digitales"},
    {prodId:"44101505",label:"Duplicadores digitales"},
    {prodId:"44101506",label:"Máquinas de interruptor de fax"},
    {prodId:"44101507",label:"Máquina de fax de inyección de tinta"},
    {prodId:"44101508",label:"Máquina de fax láser"},
    {prodId:"44101509",label:"Máquina de fax térmica"},
    {prodId:"44101510",label:"Fax o radiofax climático"},
    {prodId:"44101600",label:"Máquinas para procesamiento de papel y accesorios"},
    {prodId:"44101601",label:"Máquinas cortadoras de papel o accesorios"},
    {prodId:"44101602",label:"Máquinas perforadoras o para unir papel"},
    {prodId:"44101603",label:"Máquinas trituradoras de papel o accesorios"},
    {prodId:"44101604",label:"Tablas de protección de base"},
    {prodId:"44101605",label:"Máquinas para emparejar papel"},
    {prodId:"44101606",label:"Máquinas para clasificar papel"},
    {prodId:"44101607",label:"Máquinas de prensa de papel"},
    {prodId:"44101700",label:"Accesorios para impresoras, fotocopiadoras y aparatos de fax"},
    {prodId:"44101701",label:"Opciones de color o actualizaciones"},
    {prodId:"44101702",label:"Bandejas de impresión a doble cara"},
    {prodId:"44101703",label:"Unidades de impresión a doble cara"},
    {prodId:"44101704",label:"Terminales de facsímil"},
    {prodId:"44101705",label:"Bandejas o alimentadores de máquinas de oficina"},
    {prodId:"44101706",label:"Unidades de fotoconductores o imágenes"},
    {prodId:"44101707",label:"Unidades de grapadoras"},
    {prodId:"44101708",label:"Filtros de ozono"},
    {prodId:"44101709",label:"Ensamblajes de réplica"},
    {prodId:"44101710",label:"Ensamblajes magnéticos de recogida"},
    {prodId:"44101711",label:"Compresor de ensamblaje"},
    {prodId:"44101712",label:"Apiladores de buzón de correo"},
    {prodId:"44101713",label:"Contadores de copias"},
    {prodId:"44101714",label:"Unidades de facsímil para máquinas de oficina"},
    {prodId:"44101715",label:"Cubiertas de platinas"},
    {prodId:"44101716",label:"Unidades de perforación de orificios"},
    {prodId:"44101718",label:"Adaptadores infrarrojos"},
    {prodId:"44101719",label:"Accesorios de copiado o escaneado"},
    {prodId:"44101720",label:"Fuentes de lenguaje"},
    {prodId:"44101721",label:"Husos de medios"},
    {prodId:"44101722",label:"Actualizaciones de energía de buzón de correo multi recipientes"},
    {prodId:"44101723",label:"Buzones de correo multi recipientes"},
    {prodId:"44101724",label:"Actualizaciones de multi funciones"},
    {prodId:"44101725",label:"Gabinetes de impresora"},
    {prodId:"44101726",label:"Actualizaciones de emulación de impresoras"},
    {prodId:"44101727",label:"Puestos para impresoras"},
    {prodId:"44101728",label:"Alimentadores de rollos"},
    {prodId:"44101729",label:"Apiladores de salida"},
    {prodId:"44101730",label:"Clasificador automático de documentos"},
    {prodId:"44101800",label:"Máquinas calculadoras y accesorios"},
    {prodId:"44101802",label:"Máquinas sumadoras"},
    {prodId:"44101803",label:"Máquinas contabilizadoras"},
    {prodId:"44101804",label:"Cajas registradoras"},
    {prodId:"44101805",label:"Cintas para calculadoras"},
    {prodId:"44101806",label:"Cintas para cajas registradoras"},
    {prodId:"44101807",label:"Calculadoras de bolsillo"},
    {prodId:"44101808",label:"Calculadoras científicas"},
    {prodId:"44101809",label:"Calculadoras de escritorio"},
    {prodId:"44101810",label:"Calculadoras impresoras"},
    {prodId:"44101900",label:"Máquinas para endosar y escribir cheques"},
    {prodId:"44101901",label:"Máquinas de endoso de cheques"},
    {prodId:"44101902",label:"Máquinas para escribir cheques"},
    {prodId:"44101903",label:"Máquinas de actualización de chequeras o tiqueteras"},
    {prodId:"44102000",label:"Suministros para plastificado"},
    {prodId:"44102001",label:"Película de laminación"},
    {prodId:"44102002",label:"Bolsas de laminadores"},
    {prodId:"44102003",label:"Láminas de transferencia"},
    {prodId:"44102004",label:"Láminas creativas"},
    {prodId:"44102300",label:"Empaquetadoras"},
    {prodId:"44102301",label:"Máquinas de agrupación"},
    {prodId:"44102302",label:"Máquinas para envolver paquetes"},
    {prodId:"44102303",label:"Prensas de sellamiento"},
    {prodId:"44102304",label:"Máquinas selladoras"},
    {prodId:"44102305",label:"Tensionadores o selladoras para zunchado"},
    {prodId:"44102306",label:"Máquinas para amarrar"},
    {prodId:"44102307",label:"Intercaladores"},
    {prodId:"44102400",label:"Etiquetadoras"},
    {prodId:"44102402",label:"Máquinas para fechar o numerar"},
    {prodId:"44102403",label:"Máquinas de prensa de identificación id"},
    {prodId:"44102404",label:"Máquinas para aplicar de etiquetas"},
    {prodId:"44102405",label:"Máquinas para hacer etiquetas"},
    {prodId:"44102406",label:"Equipos para rotulado"},
    {prodId:"44102407",label:"Grabadora de relieve para cinta"},
    {prodId:"44102408",label:"Sistemas de etiquetado automático"},
    {prodId:"44102409",label:"Sistemas de etiquetado semiautomáticos"},
    {prodId:"44102411",label:"Dispensadores de etiquetas"},
    {prodId:"44102412",label:"Cartuchos de etiquetas adhesivas"},
    {prodId:"44102413",label:"Kit de sistema de etiquetado de discos versátiles digitales o discos compactos cd/dvd"},
    {prodId:"44102414",label:"Estampilla auto abastecedora de tinta"},
    {prodId:"44102600",label:"Máquinas de escribir y accesorios"},
    {prodId:"44102602",label:"Máquinas de escribir"},
    {prodId:"44102603",label:"Ruedas de margarita para impresoras"},
    {prodId:"44102604",label:"Máquinas de estenotipio"},
    {prodId:"44102605",label:"Máquinas para dictado"},
    {prodId:"44102606",label:"Cinta de máquinas de escribir"},
    {prodId:"44102607",label:"Procesadores de palabras"},
    {prodId:"44102608",label:"Elementos de impresión para máquinas de escribir"},
    {prodId:"44102609",label:"Kits de accesorios o suministros para máquinas de escribir"},
    {prodId:"44102610",label:"Kits de inicio para máquinas de escribir"},
    {prodId:"44102800",label:"Máquinas encuadernadoras y plastificadoras"},
    {prodId:"44102801",label:"Laminadoras"},
    {prodId:"44102802",label:"Máquinas de encuadernación térmica"},
    {prodId:"44102803",label:"Máquina de encuadernación en espiral"},
    {prodId:"44102804",label:"Máquina de encuadernación de peinilla"},
    {prodId:"44102805",label:"Máquina perforadora de encuadernación"},
    {prodId:"44102806",label:"Máquina de encuadernación de alambre"},
    {prodId:"44102900",label:"Accesorios de máquinas de oficina"},
    {prodId:"44102901",label:"Kits de viaje para máquinas de oficina"},
    {prodId:"44102902",label:"Accesorios de almacenamiento para máquinas de almacenamiento"},
    {prodId:"44102903",label:"Limpiadoras de cinta"},
    {prodId:"44102904",label:"Aerosol de aire comprimido"},
    {prodId:"44102905",label:"Pequeñas bolsas de papel de sales absorbentes húmedas"},
    {prodId:"44102906",label:"Kits de limpieza de computadores o equipos de oficina"},
    {prodId:"44102907",label:"Forros para equipos para protegerlos del polvo"},
    {prodId:"44102908",label:"Limpiadores de discos compactos o removedores de rayones"},
    {prodId:"44102909",label:"Limpiadores de unidades de discos compactos"},
    {prodId:"44102910",label:"Laminadora"},
    {prodId:"44102911",label:"Pañitos limpiadores para máquinas de oficina"},
    {prodId:"44102912",label:"Soluciones limpiadoras para equipos de oficina"},
    {prodId:"44102913",label:"Destructor de discos compactos"},
    {prodId:"44103000",label:"Fusores y Accesorios"},
    {prodId:"44103001",label:"Almohadillas o filtros limpiadores de fusores"},
    {prodId:"44103002",label:"Aceite de fusores"},
    {prodId:"44103003",label:"Limpiador de fusores"},
    {prodId:"44103004",label:"Fusores"},
    {prodId:"44103005",label:"Lámparas o ensamblajes de fusores"},
    {prodId:"44103100",label:"Suministros para impresora, fax y fotocopiadora"},
    {prodId:"44103101",label:"Correas de impresoras, fax o fotocopiadoras"},
    {prodId:"44103103",label:"Tóner para impresoras o fax"},
    {prodId:"44103104",label:"Rollos de transferencia"},
    {prodId:"44103105",label:"Cartuchos de tinta"},
    {prodId:"44103106",label:"Barras de tinta"},
    {prodId:"44103107",label:"Suministros de limpieza de impresoras o faxes o fotocopiadoras"},
    {prodId:"44103108",label:"Reveladores para impresoras o fotocopiadoras"},
    {prodId:"44103109",label:"Tambores para impresoras o faxes o fotocopiadoras"},
    {prodId:"44103110",label:"Cabezales de impresión"},
    {prodId:"44103111",label:"Rollos de tinta"},
    {prodId:"44103112",label:"Cinta de impresora"},
    {prodId:"44103113",label:"Kits de correctores de fase o inyección de tinta"},
    {prodId:"44103114",label:"Kits de recubrimiento de inyección de tinta"},
    {prodId:"44103116",label:"Kit para impresora"},
    {prodId:"44103117",label:"Cintas de fax"},
    {prodId:"44103118",label:"Película de transparencia"},
    {prodId:"44103119",label:"Papel de transferencia en caliente para copiadoras"},
    {prodId:"44103120",label:"Recolectores de tóner"},
    {prodId:"44103121",label:"Guías de rollo para impresoras, faxes o fotocopiadoras"},
    {prodId:"44103122",label:"Bandas de impresión"},
    {prodId:"44103123",label:"Lápices de plotter"},
    {prodId:"44103124",label:"Cinta térmica"},
    {prodId:"44103125",label:"Kit de mantenimiento de impresoras"},
    {prodId:"44103126",label:"Tinta de máquina duplicadora digital"},
    {prodId:"44103200",label:"Máquinas y accesorios de registrar la hora para la oficina"},
    {prodId:"44103201",label:"Máquina de tarjetas de tiempo"},
    {prodId:"44103202",label:"Máquinas estampadoras de tiempo"},
    {prodId:"44103203",label:"Cinta de repuesto para máquinas de tarjetas de tiempo"},
    {prodId:"44103205",label:"Tarjetas u hojas de tiempo"},
    {prodId:"44103206",label:"Máquina de huellas para control de tiempo y de acceso"},
    {prodId:"44103500",label:"Suministros de máquinas de encuadernar"},
    {prodId:"44103502",label:"Tapas de encuadernación"},
    {prodId:"44103503",label:"Lomos o cierres de encuadernación"},
    {prodId:"44103504",label:"Alambres o espirales de encuadernación"},
    {prodId:"44103505",label:"Peinillas o tiras de encuadernación"},
    {prodId:"44103506",label:"Cinta de encuadernación"},
    {prodId:"44103507",label:"Kits de encuadernación"},
    {prodId:"44103508",label:"Punzones de tinta de encuadernación"},
    {prodId:"44103600",label:"Equipo de eliminación de casetes y accesorios"},
    {prodId:"44103601",label:"Trituradores de casetes o cintas"},
    {prodId:"44111500",label:"Agendas y accesorios"},
    {prodId:"44111501",label:"Sujetadores o dispensadores de mensajes"},
    {prodId:"44111502",label:"Organizadores de cajones de escritorio"},
    {prodId:"44111503",label:"Organizadores o bandejas para el escritorio"},
    {prodId:"44111506",label:"Sujetadores o dispensadores de papeles o tacos"},
    {prodId:"44111507",label:"Sujeta libros"},
    {prodId:"44111509",label:"Sujetadores de esferos o lápices"},
    {prodId:"44111510",label:"Organizadores o accesorios de colgar"},
    {prodId:"44111511",label:"Sistemas de despliegue o sus accesorios"},
    {prodId:"44111512",label:"Estante de literatura"},
    {prodId:"44111513",label:"Soportes para diarios o calendarios"},
    {prodId:"44111514",label:"Estantes u organizadores para estampillas"},
    {prodId:"44111515",label:"Cajas u organizadores de almacenamiento de archivos"},
    {prodId:"44111516",label:"Organizadores personales"},
    {prodId:"44111517",label:"Puestos de estudio"},
    {prodId:"44111518",label:"Sujetadores de tarjetas de presentación"},
    {prodId:"44111519",label:"Repisas de distribución de documentos"},
    {prodId:"44111520",label:"Almohadillas o protectores de superficie"},
    {prodId:"44111521",label:"Sujetadores de copias"},
    {prodId:"44111522",label:"Sujetadores de libros"},
    {prodId:"44111600",label:"Suministros para el manejo de efectivo"},
    {prodId:"44111601",label:"Bolsas para billetes o billeteras"},
    {prodId:"44111603",label:"Sorteadores de monedas"},
    {prodId:"44111604",label:"Envoltorios para monedas o cintas para billetes"},
    {prodId:"44111605",label:"Cajas para efectivo o tiquetes"},
    {prodId:"44111606",label:"Bandejas de cajas de efectivo"},
    {prodId:"44111607",label:"Archivos de cheques"},
    {prodId:"44111608",label:"Bancos de monedas"},
    {prodId:"44111609",label:"Detectores de billetes falsos o suministros"},
    {prodId:"44111610",label:"Bandejas de monedas"},
    {prodId:"44111611",label:"Clips para billetes"},
    {prodId:"44111612",label:"Sellos para bolsas de monedas"},
    {prodId:"44111613",label:"Sujetadores de tarjetas de crédito"},
    {prodId:"44111614",label:"Estantes de correas de billetes"},
    {prodId:"44111615",label:"Bolsas de depósito"},
    {prodId:"44111616",label:"Separadores de cheques"},
    {prodId:"44111617",label:"Máquinas de inspección de monedas"},
    {prodId:"44111618",label:"Tarjetas de teléfono público o tarjetas de teléfono"},
    {prodId:"44111800",label:"Suministros de dibujo"},
    {prodId:"44111801",label:"Ayudas para esténciles o textos"},
    {prodId:"44111802",label:"Película de dibujo"},
    {prodId:"44111803",label:"Compases"},
    {prodId:"44111804",label:"Papeles de dibujo"},
    {prodId:"44111805",label:"Curvas"},
    {prodId:"44111806",label:"Transportadores"},
    {prodId:"44111807",label:"Escalas"},
    {prodId:"44111808",label:"Reglas t"},
    {prodId:"44111809",label:"Plantillas"},
    {prodId:"44111810",label:"Triángulos"},
    {prodId:"44111812",label:"Kits o sets de dibujo"},
    {prodId:"44111813",label:"Puntos o cintas de dibujo"},
    {prodId:"44111814",label:"Forros de protección para superficies de trabajo"},
    {prodId:"44111815",label:"Forros para mesas de dibujo"},
    {prodId:"44111816",label:"Máquina de dibujo multiplexora"},
    {prodId:"44111817",label:"Divisor de dibujo"},
    {prodId:"44111818",label:"Eidógrafo"},
    {prodId:"44111900",label:"Tableros"},
    {prodId:"44111901",label:"Tableros de planeación o accesorios"},
    {prodId:"44111902",label:"Tableros electrónicos de copia o accesorios"},
    {prodId:"44111903",label:"Caballetes o accesorios"},
    {prodId:"44111904",label:"Tableros de cartas o accesorios"},
    {prodId:"44111905",label:"Tableros de borrado en seco o accesorios"},
    {prodId:"44111906",label:"Tableros de tiza o accesorios"},
    {prodId:"44111907",label:"Tableros de noticias o accesorios"},
    {prodId:"44111908",label:"Tableros magnéticos o accesorios"},
    {prodId:"44111909",label:"Kits o accesorios para limpieza de tableros"},
    {prodId:"44111910",label:"Rieles o sujetadores para colgar tableros"},
    {prodId:"44111911",label:"Tableros blancos interactivos o accesorios"},
    {prodId:"44111912",label:"Borradores para tableros blancos"},
    {prodId:"44111913",label:"Borradores de pila para tableros blancos"},
    {prodId:"44111914",label:"Tabla de soporte para escribir"},
    {prodId:"44112000",label:"Sistemas de planificación"},
    {prodId:"44112001",label:"Libretas de direcciones o repuestos"},
    {prodId:"44112002",label:"Calendarios"},
    {prodId:"44112005",label:"Libretas de citas o repuestos"},
    {prodId:"44112006",label:"Diarios o repuestos"},
    {prodId:"44112007",label:"Cajas de sugerencias"},
    {prodId:"44112008",label:"Planeadores de pared o repuestos"},
    {prodId:"44121500",label:"Suministros de correo"},
    {prodId:"44121501",label:"Tubos de sobres"},
    {prodId:"44121503",label:"Sobres"},
    {prodId:"44121504",label:"Sobres de ventana"},
    {prodId:"44121505",label:"Sobres especiales"},
    {prodId:"44121506",label:"Sobres estándar"},
    {prodId:"44121507",label:"Sobres de catálogos o de gancho"},
    {prodId:"44121508",label:"Repositorios para mensajes"},
    {prodId:"44121509",label:"Bolsas para correo"},
    {prodId:"44121510",label:"Sellos para correo"},
    {prodId:"44121511",label:"Cajas para correo"},
    {prodId:"44121512",label:"Tapas de tubos para correo"},
    {prodId:"44121513",label:"Estampillas postales"},
    {prodId:"44121600",label:"Suministros de escritorio"},
    {prodId:"44121604",label:"Estampillas"},
    {prodId:"44121605",label:"Dispensadores de cinta"},
    {prodId:"44121611",label:"Punzones para papel u ojales"},
    {prodId:"44121612",label:"Cortadoras de papel o repuestos"},
    {prodId:"44121613",label:"Removedores de grapas (saca ganchos)"},
    {prodId:"44121614",label:"Campanas de llamada"},
    {prodId:"44121615",label:"Grapadoras"},
    {prodId:"44121617",label:"Abridor manual de cartas"},
    {prodId:"44121618",label:"Tijeras"},
    {prodId:"44121619",label:"Tajalápices manuales"},
    {prodId:"44121620",label:"Protector de plástico para dedos"},
    {prodId:"44121621",label:"Almohadillas para escritorio o sus accesorios"},
    {prodId:"44121622",label:"Humidificadores"},
    {prodId:"44121623",label:"Abridor de cartas mecánico"},
    {prodId:"44121624",label:"Herramientas de grabado en relieve"},
    {prodId:"44121625",label:"Pisa papeles"},
    {prodId:"44121626",label:"Removedor de adhesivo"},
    {prodId:"44121627",label:"Marcadores de libros"},
    {prodId:"44121628",label:"Contenedores o dispensadores de clips"},
    {prodId:"44121630",label:"Kit de cosedora"},
    {prodId:"44121631",label:"Dispensadores de goma o repuestos"},
    {prodId:"44121632",label:"Afilador de tijeras"},
    {prodId:"44121633",label:"Dispensadores de estampillas postales"},
    {prodId:"44121634",label:"Rollos adhesivos"},
    {prodId:"44121635",label:"Husos para cinta adhesiva"},
    {prodId:"44121636",label:"Tajalápices eléctricos"},
    {prodId:"44121700",label:"Instrumentos de escritura"},
    {prodId:"44121701",label:"Bolígrafos"},
    {prodId:"44121702",label:"Sets de esferos o lápices"},
    {prodId:"44121703",label:"Estilógrafos"},
    {prodId:"44121704",label:"Esferos de punta redonda"},
    {prodId:"44121705",label:"Lápices mecánicos"},
    {prodId:"44121706",label:"Lápices de madera"},
    {prodId:"44121707",label:"Lápices de colores"},
    {prodId:"44121708",label:"Marcadores"},
    {prodId:"44121709",label:"Crayolas"},
    {prodId:"44121710",label:"Tiza para escribir o accesorios"},
    {prodId:"44121711",label:"Rotuladores"},
    {prodId:"44121712",label:"Repuestos de marcadores"},
    {prodId:"44121713",label:"Plumas de estilógrafos"},
    {prodId:"44121714",label:"Asideras para lápices o esferos"},
    {prodId:"44121715",label:"Combinaciones de esfero y lápiz"},
    {prodId:"44121716",label:"Resaltadores"},
    {prodId:"44121717",label:"Combinación de esfero y resaltador"},
    {prodId:"44121718",label:"Sets de esferos asegurados"},
    {prodId:"44121719",label:"Estilógrafos desechables"},
    {prodId:"44121720",label:"Esferos multi función"},
    {prodId:"44121721",label:"Esfero de tinta borrable"},
    {prodId:"44121800",label:"Medios de corrección"},
    {prodId:"44121801",label:"Película o cinta de corrección"},
    {prodId:"44121802",label:"Fluido de corrección"},
    {prodId:"44121804",label:"Borradores"},
    {prodId:"44121805",label:"Esferos de corrección"},
    {prodId:"44121806",label:"Repuestos para esferos de corrección"},
    {prodId:"44121807",label:"Repuestos para borradores"},
    {prodId:"44121808",label:"Borradores eléctricos"},
    {prodId:"44121809",label:"Sujeta borrador"},
    {prodId:"44121900",label:"Repuestos de tinta y minas de lápices"},
    {prodId:"44121902",label:"Repuestos de minas"},
    {prodId:"44121904",label:"Repuestos de tinta"},
    {prodId:"44121905",label:"Almohadillas de tinta o estampillas"},
    {prodId:"44121906",label:"Cartuchos para rotuladores"},
    {prodId:"44121907",label:"Repuestos de tinta para estilógrafos"},
    {prodId:"44121908",label:"Repuestos de tinta para bolígrafos"},
    {prodId:"44122000",label:"Carpetas de archivo, carpetas y separadores"},
    {prodId:"44122001",label:"Archivos para tarjetas de índex"},
    {prodId:"44122002",label:"Protectores de hojas"},
    {prodId:"44122003",label:"Carpetas"},
    {prodId:"44122005",label:"Cubiertas para revistas o libros"},
    {prodId:"44122008",label:"Índices de fichas"},
    {prodId:"44122009",label:"Archivos para tarjetas rotativas o de presentación"},
    {prodId:"44122010",label:"Separadores"},
    {prodId:"44122011",label:"Folders"},
    {prodId:"44122012",label:"Portapapeles"},
    {prodId:"44122013",label:"Cubiertas para informes"},
    {prodId:"44122014",label:"Levantadores de hojas"},
    {prodId:"44122015",label:"Respaldos para archivos"},
    {prodId:"44122016",label:"Sujetador de documentos"},
    {prodId:"44122017",label:"Folders de colgar o accesorios"},
    {prodId:"44122018",label:"Insertos o pestañas para archivos"},
    {prodId:"44122019",label:"Bolsillos para archivos o accesorios"},
    {prodId:"44122020",label:"Bolsillos para tarjetas"},
    {prodId:"44122021",label:"Álbumes de estampillas"},
    {prodId:"44122022",label:"Accesorios de carpetas de folders"},
    {prodId:"44122023",label:"Tabletas gráficas para arquitectura"},
    {prodId:"44122024",label:"Manijas de carpetas"},
    {prodId:"44122025",label:"Bolsillos de carpetas o accesorios"},
    {prodId:"44122026",label:"Garras para papel"},
    {prodId:"44122027",label:"Folders de archivo expandibles"},
    {prodId:"44122028",label:"Canales para montar carpetas"},
    {prodId:"44122029",label:"Folders de clasificación"},
    {prodId:"44122030",label:"Kit de folders"},
    {prodId:"44122031",label:"Marco de rieles laterales para colgar folders"},
    {prodId:"44122032",label:"Folders de conferencias"},
    {prodId:"44122033",label:"Archivo de acordeón para folders"},
    {prodId:"44122034",label:"Separador de páginas de libros"},
    {prodId:"44122100",label:"Suministros de sujeción"},
    {prodId:"44122101",label:"Cauchos"},
    {prodId:"44122103",label:"Sujetadores de cierre"},
    {prodId:"44122104",label:"Clips para papel"},
    {prodId:"44122105",label:"Clips para carpetas o bulldog"},
    {prodId:"44122106",label:"Alfileres o taches"},
    {prodId:"44122107",label:"Grapas"},
    {prodId:"44122109",label:"Sujetador de aro y bucle"},
    {prodId:"44122110",label:"Monturas adhesivas"},
    {prodId:"44122111",label:"Refuerzos para orificios"},
    {prodId:"44122112",label:"Sujetadores de cabeza redonda"},
    {prodId:"44122113",label:"Sujetadores de etiquetas"},
    {prodId:"44122114",label:"Postes roscados"},
    {prodId:"44122115",label:"Esquinas adhesivas"},
    {prodId:"44122116",label:"Clips de bolsas"},
    {prodId:"44122117",label:"Anillos para libros"},
    {prodId:"44122118",label:"Sujetadores de pinza"},
    {prodId:"44122119",label:"Sujetadores auto adhesivos"},
    {prodId:"44122120",label:"Postes de carpetas"},
    {prodId:"44122121",label:"Clips de pared o tablero"},
    {prodId:"44122122",label:"Tachuelas magnéticas"},
    {prodId:"45101500",label:"Maquinaria y equipo de imprenta"},
    {prodId:"45101501",label:"Impresoras heliográficas"},
    {prodId:"45101502",label:"Prensas de impresión de offset"},
    {prodId:"45101503",label:"Equipos de impresión tipográfica"},
    {prodId:"45101504",label:"Equipos litográficos"},
    {prodId:"45101505",label:"Máquinas impresoras de fotograbados"},
    {prodId:"45101506",label:"Máquinas de serigrafía"},
    {prodId:"45101507",label:"Prensas impresoras"},
    {prodId:"45101508",label:"Máquinas perforadoras"},
    {prodId:"45101509",label:"Impresora rotativa ultravioleta uv"},
    {prodId:"45101510",label:"Impresora flexo gráfica"},
    {prodId:"45101511",label:"Impresora de inyección de tinta para aplicaciones de impresión comercial"},
    {prodId:"45101512",label:"Impresora de transferencia térmica para aplicaciones de impresión comercial"},
    {prodId:"45101513",label:"Impresora de estampado en caliente"},
    {prodId:"45101514",label:"Impresora de almohadillas"},
    {prodId:"45101515",label:"Impresora básica"},
    {prodId:"45101516",label:"Clichés de impresión de almohadillas"},
    {prodId:"45101517",label:"Prensa de pruebas de offset"},
    {prodId:"45101518",label:"Prensa de platinas de impresión"},
    {prodId:"45101600",label:"Accesorios para máquinas de imprenta"},
    {prodId:"45101602",label:"Equipos de cuarto oscuro de offset"},
    {prodId:"45101603",label:"Consumibles de impresión de offset"},
    {prodId:"45101604",label:"Procesadores de platinas de impresión de offset"},
    {prodId:"45101606",label:"Procesadores de película de offset"},
    {prodId:"45101607",label:"Lámparas de arco de serigrafía"},
    {prodId:"45101608",label:"Pantallas de serigrafía"},
    {prodId:"45101609",label:"Bastidores de impresión de serigrafía"},
    {prodId:"45101610",label:"Armazones de impresión al vacío de serigrafía"},
    {prodId:"45101611",label:"Escobillas de serigrafía"},
    {prodId:"45101612",label:"Plecas (cuchillas doctor)"},
    {prodId:"45101700",label:"Accesorios de imprenta"},
    {prodId:"45101701",label:"Ensambladoras de impresión"},
    {prodId:"45101702",label:"Guillotinas de impresión"},
    {prodId:"45101703",label:"Intercaladores o desintercaladores de impresión"},
    {prodId:"45101704",label:"Cortadoras de impresión"},
    {prodId:"45101705",label:"Bordeadoras de impresión"},
    {prodId:"45101706",label:"Punzones de impresión"},
    {prodId:"45101707",label:"Platinas de impresión"},
    {prodId:"45101708",label:"Leznas de impresión"},
    {prodId:"45101709",label:"Película de impresión"},
    {prodId:"45101900",label:"Equipo y accesorios de laboratorio de imprenta"},
    {prodId:"45101901",label:"Máquinas de anillado de libros"},
    {prodId:"45101902",label:"Clisadores"},
    {prodId:"45101903",label:"Máquinas de perforación de papel"},
    {prodId:"45101904",label:"Alargadores"},
    {prodId:"45101905",label:"Tableros de dibujo o retoque"},
    {prodId:"45111500",label:"Atriles, sistemas de sonido y accesorios"},
    {prodId:"45111501",label:"Atriles autónomos"},
    {prodId:"45111502",label:"Atriles de mesa"},
    {prodId:"45111503",label:"Mazos o bloques de sonido"},
    {prodId:"45111504",label:"Componentes de luz o energía o datos para atriles"},
    {prodId:"45111600",label:"Proyectores y suministros"},
    {prodId:"45111601",label:"Señaladores"},
    {prodId:"45111602",label:"Lámparas de proyección"},
    {prodId:"45111603",label:"Pantallas o desplegadores para proyección"},
    {prodId:"45111604",label:"Proyectores de filminas"},
    {prodId:"45111605",label:"Equipos de transparencias o suministros"},
    {prodId:"45111606",label:"Paneles de proyección de despliegue de cristal líquido"},
    {prodId:"45111607",label:"Proyectores de techo"},
    {prodId:"45111608",label:"Proyectores de películas"},
    {prodId:"45111609",label:"Proyectores multimedia"},
    {prodId:"45111610",label:"Epidiascopios"},
    {prodId:"45111612",label:"Controles de disolución"},
    {prodId:"45111613",label:"Proyectores de tubo de rayo catódico"},
    {prodId:"45111614",label:"Proyectores de despliegue de cristal líquido"},
    {prodId:"45111615",label:"Lentes de proyección"},
    {prodId:"45111616",label:"Proyectores de video"},
    {prodId:"45111617",label:"Proyector de techo o carritos de video"},
    {prodId:"45111618",label:"Cajas de luces de presentaciones"},
    {prodId:"45111619",label:"Bobina para películas"},
    {prodId:"45111620",label:"Copiadora de películas de filminas"},
    {prodId:"45111700",label:"Equipo de composición y presentación de sonido, hardware y controladores"},
    {prodId:"45111701",label:"Dispositivos de audición asistida"},
    {prodId:"45111702",label:"Cajas de conectores de audio"},
    {prodId:"45111703",label:"Centros de escucha"},
    {prodId:"45111704",label:"Consolas de mezclado de audio"},
    {prodId:"45111705",label:"Sistemas de comunicación pública"},
    {prodId:"45111706",label:"Combinación de mezclador y amplificador de audio"},
    {prodId:"45111707",label:"Conector y caja de escenario de equipo de audio"},
    {prodId:"45111708",label:"Controlador de dominio de transmisión"},
    {prodId:"45111709",label:"Amplificador remoto"},
    {prodId:"45111710",label:"Unidad de timbre y sirena"},
    {prodId:"45111711",label:"Deflector (bafle) de micrófono"},
    {prodId:"45111712",label:"Duplicador de cintas"},
    {prodId:"45111713",label:"Amplificador de distribución de audio"},
    {prodId:"45111714",label:"Convertidor de audio de análogo a digital ad"},
    {prodId:"45111715",label:"Convertidor de audio de digital a análogo da"},
    {prodId:"45111716",label:"Estación de audio digital daw"},
    {prodId:"45111717",label:"Reverberador"},
    {prodId:"45111718",label:"Unidad de retardo"},
    {prodId:"45111719",label:"Monitor de audio"},
    {prodId:"45111720",label:"Analizador de espectro de audio"},
    {prodId:"45111721",label:"Grabadora de cintas bobina a bobina"},
    {prodId:"45111722",label:"Lector de casetes"},
    {prodId:"45111723",label:"Grabadora de discos duros"},
    {prodId:"45111724",label:"Cabezas magnéticas"},
    {prodId:"45111800",label:"Equipo de presentación de vídeo y de mezcla de vídeo y sonido, hardware y controladores"},
    {prodId:"45111801",label:"Sistemas de control de medios"},
    {prodId:"45111802",label:"Soportes para televisiones"},
    {prodId:"45111803",label:"Convertidores de barrido (scan)"},
    {prodId:"45111804",label:"Duplicadores de línea"},
    {prodId:"45111805",label:"Editores de video"},
    {prodId:"45111806",label:"Sistemas de aprendizaje a distancia"},
    {prodId:"45111807",label:"Interfaces"},
    {prodId:"45111808",label:"Controles de iluminación"},
    {prodId:"45111809",label:"Accesorios de soporte de televisiones"},
    {prodId:"45111810",label:"Presentadores visuales"},
    {prodId:"45111811",label:"Monitor de precisión de video"},
    {prodId:"45111812",label:"Dispositivo de despliegue de información visual"},
    {prodId:"45111813",label:"Operador de introducción de datos corriente abajo"},
    {prodId:"45111814",label:"Equipo de efectos de video digital dve"},
    {prodId:"45111815",label:"Consola de audio video"},
    {prodId:"45111816",label:"Generador de logos"},
    {prodId:"45111817",label:"Generador de caracteres"},
    {prodId:"45111818",label:"Reducidor de ruido de videos"},
    {prodId:"45111819",label:"Mezclador de videos"},
    {prodId:"45111820",label:"Amplificador de procesamiento de videos"},
    {prodId:"45111821",label:"Generador de sincronización"},
    {prodId:"45111822",label:"Convertidor de tasa de aspecto de televisión"},
    {prodId:"45111823",label:"Convertidor de estándares de video"},
    {prodId:"45111824",label:"Convertidor de sincronización"},
    {prodId:"45111825",label:"Sincronizador de marco"},
    {prodId:"45111826",label:"Codificador de grupo de expertos de películas animadas mpeg"},
    {prodId:"45111827",label:"Verificador de video cintas"},
    {prodId:"45111828",label:"Indicador (prompter)"},
    {prodId:"45111829",label:"Cámara de proceso"},
    {prodId:"45111900",label:"Equipos, hardware y controladores para sistemas de teleconferencia y videoconferencia"},
    {prodId:"45111901",label:"Sistemas de audio conferencias"},
    {prodId:"45111902",label:"Sistemas de video conferencias"},
    {prodId:"45121500",label:"Cámaras"},
    {prodId:"45121501",label:"Cámaras fijas"},
    {prodId:"45121502",label:"Cámaras de impresión instantánea"},
    {prodId:"45121503",label:"Cámaras desechables"},
    {prodId:"45121504",label:"Cámaras digitales"},
    {prodId:"45121505",label:"Cámaras cinematográficas"},
    {prodId:"45121506",label:"Cámaras de video conferencia"},
    {prodId:"45121510",label:"Cámaras aéreas"},
    {prodId:"45121511",label:"Cámaras de alta velocidad"},
    {prodId:"45121512",label:"Cámaras para debajo del agua"},
    {prodId:"45121513",label:"Cámaras de offset"},
    {prodId:"45121514",label:"Cámara fotocopiadora"},
    {prodId:"45121515",label:"Cámaras grabadoras o video cámaras manuales"},
    {prodId:"45121516",label:"Cámaras grabadoras o video cámaras digitales"},
    {prodId:"45121517",label:"Cámaras para documentos"},
    {prodId:"45121518",label:"Kits de cámaras"},
    {prodId:"45121519",label:"Cámaras de baja luz"},
    {prodId:"45121520",label:"Cámaras de web"},
    {prodId:"45121521",label:"Cámaras de inspección"},
    {prodId:"45121522",label:"Cámaras infrarrojas"},
    {prodId:"45121523",label:"Cámaras astronómicas"},
    {prodId:"45121600",label:"Accesorios para cámaras"},
    {prodId:"45121601",label:"Flashes o iluminación para cámaras"},
    {prodId:"45121602",label:"Trípodes para cámaras"},
    {prodId:"45121603",label:"Lentes para cámaras"},
    {prodId:"45121604",label:"Oclusores para cámaras"},
    {prodId:"45121605",label:"Marcos de pantalla"},
    {prodId:"45121606",label:"Arneses para cámaras"},
    {prodId:"45121607",label:"Bloques o sujetadores para cámaras"},
    {prodId:"45121608",label:"Ensamblajes para cámaras"},
    {prodId:"45121609",label:"Abrazaderas para cámaras"},
    {prodId:"45121610",label:"Cables para cámaras"},
    {prodId:"45121611",label:"Cubiertas para lentes"},
    {prodId:"45121612",label:"Mesas para cámaras"},
    {prodId:"45121613",label:"Contenedores o forros para cámaras"},
    {prodId:"45121614",label:"Kits de reacondicionamiento"},
    {prodId:"45121615",label:"Anillos para cámaras"},
    {prodId:"45121616",label:"Cabezales panorámicos"},
    {prodId:"45121617",label:"Bolsas para cámaras"},
    {prodId:"45121618",label:"Adaptadores de lentes para cámaras"},
    {prodId:"45121619",label:"Billeteras para “picture card”"},
    {prodId:"45121620",label:"Adaptadores de electricidad para cámaras"},
    {prodId:"45121621",label:"Adaptadores para “picture card”"},
    {prodId:"45121622",label:"Limpiadores para lentes de cámara"},
    {prodId:"45121623",label:"Controladores de cámara"},
    {prodId:"45121624",label:"Reflector de luz para fotografía"},
    {prodId:"45121625",label:"Disparador"},
    {prodId:"45121626",label:"Respaldo de cámara digital (dispositivo que permite editar e imprimir)"},
    {prodId:"45121627",label:"Difusor de flash de cámara"},
    {prodId:"45121628",label:"Filtro de lente de cámara"},
    {prodId:"45121629",label:"Motor de arrastre de cámara"},
    {prodId:"45121630",label:"Reflector de parasol de cámara"},
    {prodId:"45121700",label:"Equipo de procesamiento fotográfico"},
    {prodId:"45121701",label:"Secadores de película"},
    {prodId:"45121702",label:"Lavadores de película"},
    {prodId:"45121703",label:"Cortadores de película"},
    {prodId:"45121704",label:"Editores de película"},
    {prodId:"45121705",label:"Alargadores fotográficos"},
    {prodId:"45121706",label:"Cortadoras o bordeadoras de fotografías"},
    {prodId:"45121707",label:"Secadora de impresión de fotografías"},
    {prodId:"45121708",label:"Lavadora de impresión de fotografías"},
    {prodId:"45121709",label:"Procesador de película automático"},
    {prodId:"45121710",label:"Tambor de hojas de película"},
    {prodId:"45121711",label:"Controlador de color"},
    {prodId:"45121712",label:"Escritorio de edición de película"},
    {prodId:"45121713",label:"Máquina de inspección de película"},
    {prodId:"45121714",label:"Lector de película"},
    {prodId:"45121715",label:"Colgador de película"},
    {prodId:"45121716",label:"Impresora de contacto"},
    {prodId:"45121717",label:"Sistema de platinas de película"},
    {prodId:"45121718",label:"Densitómetro de película"},
    {prodId:"45121719",label:"Kiosco de impresión de fotografías digitales"},
    {prodId:"45121720",label:"Cargador de película"},
    {prodId:"45131500",label:"Película para cámara fotográfica"},
    {prodId:"45131501",label:"Película de color"},
    {prodId:"45131502",label:"Película de blanco y negro"},
    {prodId:"45131503",label:"Película de fotografía instantánea"},
    {prodId:"45131505",label:"Película de rayos x"},
    {prodId:"45131506",label:"Película de filminas"},
    {prodId:"45131507",label:"Micro filmado procesado"},
    {prodId:"45131508",label:"Película de negativos"},
    {prodId:"45131509",label:"Película reconocedora de color"},
    {prodId:"45131510",label:"Tinte fotográfico"},
    {prodId:"45131700",label:"Almacenamiento para soportes audiovisuales"},
    {prodId:"45131701",label:"Bandejas u organizadores para filminas"},
    {prodId:"45141500",label:"Productos químicos para elaboración fotográfica"},
    {prodId:"45141501",label:"Solución reveladora"},
    {prodId:"45141502",label:"Fijadores"},
    {prodId:"45141503",label:"Kit de procesamiento de revelado de fotografías"},
    {prodId:"45141504",label:"Solución de optimización y corrección de fotografías"},
    {prodId:"46101500",label:"Armas de fuego"},
    {prodId:"46101501",label:"Ametralladoras"},
    {prodId:"46101502",label:"Escopetas para la policía p seguridad"},
    {prodId:"46101503",label:"Rifles militares"},
    {prodId:"46101504",label:"Pistolas"},
    {prodId:"46101505",label:"Rifles de aire o pistolas de aire"},
    {prodId:"46101506",label:"Partes de revólveres o pistolas"},
    {prodId:"46101600",label:"Munición"},
    {prodId:"46101601",label:"Municiones de defensa u orden público"},
    {prodId:"46101800",label:"Accesorios de armas y municiones"},
    {prodId:"46101801",label:"Estuches para revólveres"},
    {prodId:"46101802",label:"Cinturón para cartuchos"},
    {prodId:"46111700",label:"Sensores de rayos infrarrojos (IR)"},
    {prodId:"46111701",label:"Enfriadores infrarrojos ir"},
    {prodId:"46111702",label:"Detectores infrarrojos ir"},
    {prodId:"46111703",label:"Iluminadores infrarrojos ir"},
    {prodId:"46111704",label:"Receptores infrarrojos ir"},
    {prodId:"46111705",label:"Telescopios infrarrojos ir"},
    {prodId:"46151500",label:"Equipo de control de masas"},
    {prodId:"46151501",label:"Barricadas"},
    {prodId:"46151502",label:"Cascos anti motines"},
    {prodId:"46151503",label:"Escudos anti motines"},
    {prodId:"46151504",label:"Armadura para el cuerpo"},
    {prodId:"46151505",label:"Barreras"},
    {prodId:"46151506",label:"Bastones anti motines"},
    {prodId:"46151507",label:"Sistema de control de filas"},
    {prodId:"46151600",label:"Equipo de seguridad y control"},
    {prodId:"46151601",label:"Esposas"},
    {prodId:"46151602",label:"Cachiporras"},
    {prodId:"46151604",label:"Analizadores de alcohol"},
    {prodId:"46151605",label:"Detectores de armas o explosivos y suministros"},
    {prodId:"46151606",label:"Kits de pruebas de narcóticos"},
    {prodId:"46151607",label:"Cabos de amarre de seguridad"},
    {prodId:"46151608",label:"Dispositivos de protección contra bombas y suministros"},
    {prodId:"46151609",label:"Discriminador de documentos de identificación"},
    {prodId:"46151700",label:"Equipo forense y accesorios y suministros"},
    {prodId:"46151702",label:"Aplicadores o cepillos de huellas dactilares"},
    {prodId:"46151703",label:"Tinta de huellas dactilares"},
    {prodId:"46151704",label:"Removedores de tinta de huellas dactilares"},
    {prodId:"46151705",label:"Rodillos de tinta para huellas dactilares o de palma de la mano"},
    {prodId:"46151706",label:"Kit de impresión latente de huellas dactilares"},
    {prodId:"46151707",label:"Levantadores de huellas dactilares"},
    {prodId:"46151708",label:"Magnificadores para uso forense"},
    {prodId:"46151709",label:"Esferos de marcado de huellas dactilares"},
    {prodId:"46151710",label:"Polvos de huellas dactilares"},
    {prodId:"46151711",label:"Levantadores de huellas plantares"},
    {prodId:"46151712",label:"Estaciones de trabajo químico para uso forense"},
    {prodId:"46151713",label:"Químicos de impresiones latentes para uso forense"},
    {prodId:"46151714",label:"Gabinetes de secado de evidencia"},
    {prodId:"46151715",label:"Equipo de huellas dactilares"},
    {prodId:"46151716",label:"Detector de mentiras"},
    {prodId:"46151900",label:"Equipo y accesorios y suministros para el control químico biológico"},
    {prodId:"46151901",label:"Remolque de descontaminación biológica química"},
    {prodId:"46161500",label:"Control de tráfico"},
    {prodId:"46161501",label:"Sistemas de señalización para aeropuertos"},
    {prodId:"46161502",label:"Sistemas de señalización para ferrocarriles"},
    {prodId:"46161503",label:"Sistemas de señalización marinos"},
    {prodId:"46161504",label:"Señales de tráfico"},
    {prodId:"46161505",label:"Medidores de estacionamiento"},
    {prodId:"46161506",label:"Máquinas para derretir nieve o hielo"},
    {prodId:"46161507",label:"Cintas o cadenas de barrera"},
    {prodId:"46161508",label:"Conos o delineadores de tráfico"},
    {prodId:"46161509",label:"Paradas de velocidad"},
    {prodId:"46161510",label:"Sistemas de barrera para puertas"},
    {prodId:"46161511",label:"Balizas de tráfico"},
    {prodId:"46161512",label:"Cadenas de tráfico"},
    {prodId:"46161513",label:"Cerca de seguridad de tráfico"},
    {prodId:"46161514",label:"Montantes de carretera cats eye"},
    {prodId:"46161515",label:"Amortiguador de choques"},
    {prodId:"46161516",label:"Sistema de reconocimiento de placas"},
    {prodId:"46161517",label:"Riel de división de carriles"},
    {prodId:"46161518",label:"Tambor canalizador de control de tráfico"},
    {prodId:"46161519",label:"Cinta de carriles de tráfico"},
    {prodId:"46161520",label:"Señales de mensaje variable"},
    {prodId:"46161521",label:"Luz de advertencia de entrada y salida de estacionamientos"},
    {prodId:"46161522",label:"Luz de advertencia de cercanía de estacionamientos"},
    {prodId:"46161523",label:"Máquina para derretir nieve en estacionamientos"},
    {prodId:"46161524",label:"Analizador de flujo de tráfico"},
    {prodId:"46161525",label:"Cable de bucle para detección de vehículos"},
    {prodId:"46161526",label:"Sensor de peatones"},
    {prodId:"46161527",label:"Sensor de flujo de tráfico"},
    {prodId:"46161528",label:"Luz de advertencia para aeropuertos"},
    {prodId:"46161529",label:"Dispositivo de guía de voz para personas invidentes"},
    {prodId:"46161530",label:"Puerta de barrera de estacionamiento"},
    {prodId:"46161531",label:"Espejo de seguridad de tráfico"},
    {prodId:"46161600",label:"Seguridad del Agua"},
    {prodId:"46161601",label:"Líneas de flotadores"},
    {prodId:"46161602",label:"Salvavidas"},
    {prodId:"46161603",label:"Alarmas de piscina"},
    {prodId:"46161604",label:"Chalecos o protectores salvavidas"},
    {prodId:"46161605",label:"Boya salvavidas"},
    {prodId:"46161700",label:"Equipo y accesorios de rescate"},
    {prodId:"46161701",label:"Línea salvavidas descendente"},
    {prodId:"46161702",label:"Bolsa de aire de rescate"},
    {prodId:"46161703",label:"Esterilla aérea de seguridad"},
    {prodId:"46161704",label:"Lanzador de línea de rescate"},
    {prodId:"46161705",label:"Set de equipos hidráulicos de rescate"},
    {prodId:"46161706",label:"Tienda de aire"},
    {prodId:"46161707",label:"Abridor de puertas de rescate"},
    {prodId:"46161708",label:"Red de rescate"},
    {prodId:"46161710",label:"Luz de rescate"},
    {prodId:"46161711",label:"Detector de vida"},
    {prodId:"46161712",label:"Alerta de seguridad personal"},
    {prodId:"46161713",label:"Dispositivo de señales de humo"},
    {prodId:"46161714",label:"Línea salvavidas"},
    {prodId:"46161715",label:"Equipo de rescate en espacios confinados"},
    {prodId:"46171500",label:"Cerraduras, elementos de seguridad y accesorios"},
    {prodId:"46171501",label:"Candados"},
    {prodId:"46171502",label:"Candados de cable"},
    {prodId:"46171503",label:"Sets de candados"},
    {prodId:"46171504",label:"Candados de botón para oprimir"},
    {prodId:"46171505",label:"Llaves"},
    {prodId:"46171506",label:"Cajas fuertes"},
    {prodId:"46171507",label:"Barras de seguridad"},
    {prodId:"46171508",label:"Candados de números"},
    {prodId:"46171509",label:"Gabinetes u organizadores con llave"},
    {prodId:"46171510",label:"Candados de tiempo"},
    {prodId:"46171511",label:"Dispositivos de bloqueo"},
    {prodId:"46171512",label:"Candados de instrumentos"},
    {prodId:"46171513",label:"Levas de bloqueo"},
    {prodId:"46171514",label:"Cadenas de seguridad o accesorios"},
    {prodId:"46171515",label:"Cadenas de llaves o estuches de llaves"},
    {prodId:"46171516",label:"Guardas para puertas"},
    {prodId:"46171517",label:"Señales de chapa"},
    {prodId:"46171518",label:"Placa de impacto eléctrica"},
    {prodId:"46171519",label:"Accesorio cilíndrico para candados"},
    {prodId:"46171520",label:"Candados eléctricos"},
    {prodId:"46171521",label:"Placa de impacto no eléctrica"},
    {prodId:"46171522",label:"Cerramientos para candados"},
    {prodId:"46171523",label:"Sistema de bloqueo mecánico"},
    {prodId:"46171524",label:"Candado de llave de tarjeta"},
    {prodId:"46171600",label:"Equipo de vigilancia y detección"},
    {prodId:"46171602",label:"Alarmas de seguridad"},
    {prodId:"46171603",label:"Temporizadores de reloj"},
    {prodId:"46171604",label:"Sistemas de alarma"},
    {prodId:"46171605",label:"Timbres de puerta"},
    {prodId:"46171606",label:"Sirenas"},
    {prodId:"46171607",label:"Timbres de zumbido"},
    {prodId:"46171608",label:"Detectores de movimiento"},
    {prodId:"46171609",label:"Espejos convexos de seguridad"},
    {prodId:"46171610",label:"Cámaras de seguridad"},
    {prodId:"46171611",label:"Sistemas de identificación de video"},
    {prodId:"46171612",label:"Monitores de video"},
    {prodId:"46171613",label:"Detectores de gas"},
    {prodId:"46171615",label:"Cámaras de optimización de luz o dispositivos de visión"},
    {prodId:"46171616",label:"Detectores de radar"},
    {prodId:"46171617",label:"Ojos (mirillas) para puertas"},
    {prodId:"46171618",label:"Campanas para puertas"},
    {prodId:"46171619",label:"Sistemas de seguridad o de control de acceso"},
    {prodId:"46171620",label:"Cortinas de luz de seguridad"},
    {prodId:"46171621",label:"Grabadoras de video o audio de vigilancia"},
    {prodId:"46171622",label:"Sistema de televisión de circuito cerrado cctv"},
    {prodId:"46171623",label:"Alarma de seguridad para el hogar"},
    {prodId:"46171624",label:"Sistema de rayos x para inspección de equipaje"},
    {prodId:"46171625",label:"Sistema de monitoreo de comunicaciones por radio"},
    {prodId:"46171626",label:"Equipo de visión nocturna"},
    {prodId:"46171627",label:"Sistema de señalización inalámbrica para sordos"},
    {prodId:"46171628",label:"Indicador automático de la hora"},
    {prodId:"46171629",label:"Espejo de señales"},
    {prodId:"46171630",label:"Sensor de nivel de agua y lluvia para vehículos"},
    {prodId:"46171631",label:"Alarma sísmica"},
    {prodId:"46171632",label:"Sensor infrarrojo pasivo"},
    {prodId:"46171633",label:"Detector de metales de seguridad"},
    {prodId:"46171634",label:"Papel detector de agentes químicos"},
    {prodId:"46171635",label:"Detector de agentes químicos"},
    {prodId:"46171636",label:"Equipo de transmisión de facsímil de seguridad"},
    {prodId:"46171637",label:"Dispositivo de interceptación electrónica"},
    {prodId:"46171638",label:"Detector y alarma de radiación"},
    {prodId:"46171639",label:"Video compensador de tráfico"},
    {prodId:"46171640",label:"Sistema de monitoreo automático de tráfico"},
    {prodId:"46181500",label:"Ropa de seguridad"},
    {prodId:"46181501",label:"Delantales protectores"},
    {prodId:"46181502",label:"Chalecos anti balas"},
    {prodId:"46181503",label:"Overoles de protección"},
    {prodId:"46181504",label:"Guantes de protección"},
    {prodId:"46181505",label:"Rodilleras de protección"},
    {prodId:"46181506",label:"Ponchos de protección"},
    {prodId:"46181507",label:"Chalecos de seguridad"},
    {prodId:"46181508",label:"Ropa para demorar el fuego"},
    {prodId:"46181509",label:"Ropa de protección contra materiales peligrosos"},
    {prodId:"46181512",label:"Ropa para cuartos de limpieza"},
    {prodId:"46181514",label:"Protectores de codos"},
    {prodId:"46181516",label:"Mangas de seguridad"},
    {prodId:"46181517",label:"Vestidos de aislamiento o de flotación"},
    {prodId:"46181518",label:"Ropa resistente al calor"},
    {prodId:"46181520",label:"Protectores de piernas"},
    {prodId:"46181522",label:"Capuchas de seguridad"},
    {prodId:"46181526",label:"Camisas protectoras"},
    {prodId:"46181527",label:"Pantalones protectores"},
    {prodId:"46181528",label:"Vestido protector"},
    {prodId:"46181529",label:"Ropa aislante para entornos fríos"},
    {prodId:"46181530",label:"Forros protectores de dedos"},
    {prodId:"46181531",label:"Ropa reflectora o accesorios"},
    {prodId:"46181532",label:"Batas de laboratorio"},
    {prodId:"46181533",label:"Batas protectoras"},
    {prodId:"46181534",label:"Muñequeras protectoras"},
    {prodId:"46181535",label:"Medias o medias largas protectoras"},
    {prodId:"46181536",label:"Guantes anti cortadas"},
    {prodId:"46181537",label:"Guantes aislantes"},
    {prodId:"46181538",label:"Guantes térmicos"},
    {prodId:"46181539",label:"Guantes anti vibraciones"},
    {prodId:"46181540",label:"Guantes de soldadura"},
    {prodId:"46181541",label:"Guantes resistentes a los químicos"},
    {prodId:"46181542",label:"Mitones protectores"},
    {prodId:"46181543",label:"Chaqueta o gabardina impermeable"},
    {prodId:"46181544",label:"Pantalones impermeable"},
    {prodId:"46181545",label:"Vestido impermeable"},
    {prodId:"46181546",label:"Capa impermeable"},
    {prodId:"46181547",label:"Cubiertas de capa impermeables"},
    {prodId:"46181548",label:"Delantal de soldadura"},
    {prodId:"46181549",label:"Bufandas"},
    {prodId:"46181550",label:"Bufandas protectoras"},
    {prodId:"46181551",label:"Chaqueta de malla protectora"},
    {prodId:"46181552",label:"Lámpara de cabeza para minería"},
    {prodId:"46181600",label:"Calzado de protección"},
    {prodId:"46181601",label:"Calzado para demorar el fuego"},
    {prodId:"46181602",label:"Calzado protector contra materiales peligrosos"},
    {prodId:"46181603",label:"Calzado para cuartos de limpieza"},
    {prodId:"46181604",label:"Botas de seguridad"},
    {prodId:"46181605",label:"Zapatos de seguridad"},
    {prodId:"46181606",label:"Forros para calzado"},
    {prodId:"46181607",label:"Zuecos protectores"},
    {prodId:"46181608",label:"Sandalias protectoras"},
    {prodId:"46181609",label:"Plantillas protectoras"},
    {prodId:"46181610",label:"Zapatos aislados para clima frío"},
    {prodId:"46181611",label:"Botas impermeables"},
    {prodId:"46181612",label:"Botas militares"},
    {prodId:"46181613",label:"Botas para escalar montañas"},
    {prodId:"46181700",label:"Protectores de cara y cabeza"},
    {prodId:"46181701",label:"Cascos"},
    {prodId:"46181702",label:"Escudos faciales"},
    {prodId:"46181703",label:"Máscaras de soldadura"},
    {prodId:"46181704",label:"Cascos de seguridad"},
    {prodId:"46181705",label:"Cascos para motociclistas"},
    {prodId:"46181706",label:"Partes de cascos o accesorios"},
    {prodId:"46181707",label:"Partes de escudos faciales o accesorios"},
    {prodId:"46181708",label:"Red protectora para el cabello"},
    {prodId:"46181709",label:"Kit de protección facial"},
    {prodId:"46181710",label:"Capucha protectora"},
    {prodId:"46181711",label:"Casco de soldadura"},
    {prodId:"46181800",label:"Protección y accesorios para la visión"},
    {prodId:"46181801",label:"Sujetadores o estuches de anteojos"},
    {prodId:"46181802",label:"Anteojos de seguridad"},
    {prodId:"46181803",label:"Protectores de ojos"},
    {prodId:"46181804",label:"Gafas protectoras"},
    {prodId:"46181805",label:"Filtros de despliegue de video"},
    {prodId:"46181806",label:"Limpiadores de lentes"},
    {prodId:"46181808",label:"Cubiertas protectoras para gafas de seguridad"},
    {prodId:"46181809",label:"Ligas para protectores de ojos"},
    {prodId:"46181810",label:"Lavadores de ojos o estaciones para lavado de ojos"},
    {prodId:"46181811",label:"Lentes protectores"},
    {prodId:"46181900",label:"Protectores auditivos"},
    {prodId:"46181901",label:"Tapones de oídos"},
    {prodId:"46181902",label:"Tapa oídos"},
    {prodId:"46181903",label:"Partes de repuesto o accesorios para tapa oídos"},
    {prodId:"46181904",label:"Dispensador de tapones para oídos"},
    {prodId:"46182000",label:"Protección de la respiración"},
    {prodId:"46182001",label:"Máscaras o accesorios"},
    {prodId:"46182002",label:"Respiradores"},
    {prodId:"46182003",label:"Máscaras de gas"},
    {prodId:"46182004",label:"Aparatos respiratorios auto contenidos que suministran aire para respirar o accesorios"},
    {prodId:"46182005",label:"Filtros o accesorios para máscaras o respiradores"},
    {prodId:"46182006",label:"Películas protectoras"},
    {prodId:"46182007",label:"Sistema de respirador accionado purificador de aire papr o accesorios"},
    {prodId:"46182100",label:"Equipo y suministros antiestáticos"},
    {prodId:"46182101",label:"Muñequeras antiestáticas"},
    {prodId:"46182102",label:"Correas de conexión a tierra para el talón"},
    {prodId:"46182103",label:"Hardware de conexión a tierra"},
    {prodId:"46182104",label:"Esterillas de piso antiestáticas"},
    {prodId:"46182105",label:"Esterillas de mesa de trabajo antiestáticas"},
    {prodId:"46182106",label:"Cinturones antiestáticos"},
    {prodId:"46182107",label:"Kits de mantenimiento antiestáticos"},
    {prodId:"46182108",label:"Correas antiestáticas para los dedos de los pies"},
    {prodId:"46182200",label:"Equipo de apoyo ergonómico"},
    {prodId:"46182201",label:"Cinturones de soporte de la espalda"},
    {prodId:"46182202",label:"Soportes para codos"},
    {prodId:"46182203",label:"Descansos de soporte para la espalda"},
    {prodId:"46182204",label:"Férulas para la muñeca"},
    {prodId:"46182205",label:"Descansos para los pies"},
    {prodId:"46182206",label:"Descansos para las muñecas"},
    {prodId:"46182207",label:"Soportes para las pantorrillas"},
    {prodId:"46182208",label:"Plantillas para zapatos"},
    {prodId:"46182209",label:"Soportes para las rodillas"},
    {prodId:"46182210",label:"Protector o férula para los muslos"},
    {prodId:"46182211",label:"Protector lumbar o férula de espalda"},
    {prodId:"46182212",label:"Protector o soporte de hombro"},
    {prodId:"46182213",label:"Almohada cervical"},
    {prodId:"46182300",label:"Protección anti caída y equipo de rescate"},
    {prodId:"46182301",label:"Salvavidas o equipo salvavidas"},
    {prodId:"46182302",label:"Acollador de protección contra caídas"},
    {prodId:"46182303",label:"Rebobinadores de arneses de seguridad"},
    {prodId:"46182304",label:"Conector de anclaje"},
    {prodId:"46182305",label:"Acollador de auto retracción"},
    {prodId:"46182306",label:"Arneses o cinturones de seguridad"},
    {prodId:"46182307",label:"Arneses de evacuación"},
    {prodId:"46182308",label:"Agarres para halar"},
    {prodId:"46182309",label:"Escaleras de cuerda de seguridad y escaleras de cable de acero"},
    {prodId:"46182310",label:"Dispositivos mecánicos para conectar cargas a las cuerdas"},
    {prodId:"46182311",label:"Dispositivos de detención"},
    {prodId:"46182312",label:"Anillos para disminuir el desgaste de la cuerda"},
    {prodId:"46182313",label:"Bucle metálico para conectar componentes en sistemas críticos de seguridad"},
    {prodId:"46182314",label:"Eslingas de seguridad"},
    {prodId:"46182315",label:"Barandas de caucho"},
    {prodId:"46182400",label:"Equipo de limpieza de seguridad y materiales de descontaminación"},
    {prodId:"46182401",label:"Ducha de descontaminación"},
    {prodId:"46182402",label:"Unidades de lavado de seguridad"},
    {prodId:"46182403",label:"Cabina de limpieza"},
    {prodId:"46182404",label:"Armario (locker) de limpieza"},
    {prodId:"46182405",label:"Ducha de aire"},
    {prodId:"46182406",label:"Caja de paso de aire"},
    {prodId:"46182500",label:"Armas o dispositivos de seguridad personal"},
    {prodId:"46182501",label:"Repelentes para ataques caninos"},
    {prodId:"46182502",label:"Luz de seguridad personal"},
    {prodId:"46182503",label:"Alarma de bajo voltaje"},
    {prodId:"46182504",label:"Panel de control de alarmas contra incendio"},
    {prodId:"46182505",label:"Detector de fuga a tierra y alarma contra incendios"},
    {prodId:"46182506",label:"Equipo de protección contra interferencia electromagnética emi"},
    {prodId:"46182507",label:"Material de protección contra interferencia electromagnética emi"},
    {prodId:"46191500",label:"Prevención contra incendios"},
    {prodId:"46191501",label:"Detectores de humo"},
    {prodId:"46191502",label:"Detectores de calor"},
    {prodId:"46191503",label:"Recubrimientos o plastilinas o sellantes resistentes al calor"},
    {prodId:"46191504",label:"Detectores de llama"},
    {prodId:"46191505",label:"Sistemas de alarma contra incendios"},
    {prodId:"46191506",label:"Extintor de llamas"},
    {prodId:"46191507",label:"Sistema pasivo de detención del fuego"},
    {prodId:"46191508",label:"Sistema de monitoreo de cinturón"},
    {prodId:"46191509",label:"Detector de vapor tóxico"},
    {prodId:"46191510",label:"Dispositivo de observación térmica tod"},
    {prodId:"46191511",label:"Detector de punto caliente del incendio"},
    {prodId:"46191600",label:"Equipo contra incendios"},
    {prodId:"46191601",label:"Extintores"},
    {prodId:"46191602",label:"Sistemas de rociado para incendio"},
    {prodId:"46191603",label:"Mangueras o boquillas para incendios"},
    {prodId:"46191604",label:"Cobijas para incendios"},
    {prodId:"46191605",label:"Herramientas manuales de supresión de incendios"},
    {prodId:"46191606",label:"Espuma de supresión de incendios o compuestos similares"},
    {prodId:"46191607",label:"Aparatos respiratorios para incendios"},
    {prodId:"46191608",label:"Sistema de supresión de incendios"},
    {prodId:"46191609",label:"Equipos de escape de incendios"},
    {prodId:"46191610",label:"Cabezales de rociadores de incendios"},
    {prodId:"46191611",label:"Carretilla para la manguera contra incendios"},
    {prodId:"46191612",label:"Bomba de agua portátil"},
    {prodId:"46191613",label:"Agente extinguidor de incendios"},
    {prodId:"46191614",label:"Proveedor de espuma líquida contra incendios"},
    {prodId:"46191615",label:"Tubo vertical de alimentación de agua contra incendios"},
    {prodId:"46191616",label:"Tanques de agua para extinguir incendios para helicópteros"},
    {prodId:"46191617",label:"Lavadora de fuego para mangueras"},
    {prodId:"46191618",label:"Base para extintores de fuego"},
    {prodId:"46191619",label:"Monitor de agua para incendios"},
    {prodId:"46191620",label:"Ventilador de escape de humo"},
    {prodId:"46191621",label:"Caja de almacenamiento de extintores de incendios"},
    {prodId:"46201000",label:"Equipo de entrenamiento en seguridad pública"},
    {prodId:"46201001",label:"Maniquí de rescate"},
    {prodId:"46201002",label:"Sistema de simulación de entrenamiento en manejo de desastres"},
    {prodId:"46201100",label:"Equipo de entrenamiento de armas pequeñas"},
    {prodId:"46201101",label:"Rifle de imitación"},
    {prodId:"46201102",label:"Campo de pruebas de disparo"},
    {prodId:"47101500",label:"Equipo para el tratamiento y suministro de agua"},
    {prodId:"47101501",label:"Equipo de carbón activado"},
    {prodId:"47101502",label:"Equipo de remoción de amoniaco"},
    {prodId:"47101503",label:"Equipo de filtración de carbón"},
    {prodId:"47101504",label:"Equipo de remoción de bacterias"},
    {prodId:"47101505",label:"Equipo de manejo de cloro"},
    {prodId:"47101506",label:"Equipo de control de corrosión"},
    {prodId:"47101507",label:"Cámaras de sílice"},
    {prodId:"47101508",label:"Equipos de desalinización"},
    {prodId:"47101509",label:"Equipos de fluorización"},
    {prodId:"47101510",label:"Equipos de remoción de hierro"},
    {prodId:"47101511",label:"Equipos de intercambio de hierro"},
    {prodId:"47101512",label:"Mezcladores o agitadores"},
    {prodId:"47101513",label:"Generadores de oxígeno"},
    {prodId:"47101514",label:"Equipos de purificación de agua"},
    {prodId:"47101516",label:"Medidores de turbiedad"},
    {prodId:"47101517",label:"Equipos de desinfección ultravioleta"},
    {prodId:"47101518",label:"Acondicionadores de agua"},
    {prodId:"47101519",label:"Accesorios para suavizar el agua"},
    {prodId:"47101521",label:"Equipos de ultra filtrado"},
    {prodId:"47101522",label:"Sistemas empacados de tratamiento de agua"},
    {prodId:"47101523",label:"Tanques de recolección"},
    {prodId:"47101524",label:"Equipos de compostaje de barro o algas"},
    {prodId:"47101525",label:"Equipo de desagüe"},
    {prodId:"47101526",label:"Máquinas de peletización de lodo"},
    {prodId:"47101527",label:"Trituradores de lodo"},
    {prodId:"47101528",label:"Secadores para tratamiento de agua"},
    {prodId:"47101529",label:"Incineradores"},
    {prodId:"47101530",label:"Equipo de control de olores"},
    {prodId:"47101531",label:"Tanques sépticos"},
    {prodId:"47101532",label:"Tanques de asentamiento"},
    {prodId:"47101533",label:"Estaciones de elevación"},
    {prodId:"47101534",label:"Distribuidores de aguas residuales"},
    {prodId:"47101535",label:"Equipo de disposición de lodo"},
    {prodId:"47101536",label:"Recolectores de lodo"},
    {prodId:"47101537",label:"Equipo acondicionador de lodo"},
    {prodId:"47101538",label:"Tanques digestores de lodo o aguas residuales"},
    {prodId:"47101539",label:"Equipo de remoción de lodo o aguas residuales"},
    {prodId:"47101540",label:"Alimentador de químicos"},
    {prodId:"47101541",label:"Equipo de ozono de tratamiento de aguas"},
    {prodId:"47101542",label:"Equipo de flotación de aire"},
    {prodId:"47101543",label:"Aireador de superficie"},
    {prodId:"47101544",label:"Difusor de aire para tratamiento de aguas residuales"},
    {prodId:"47101545",label:"Contactor biológico rotatorio rbc"},
    {prodId:"47101546",label:"Sistema de remoción de arena"},
    {prodId:"47101547",label:"Dispositivo de lavado de superficies"},
    {prodId:"47101548",label:"Consola de control de filtro"},
    {prodId:"47101549",label:"Filtro de gravedad"},
    {prodId:"47101550",label:"Filtro de compresión"},
    {prodId:"47101551",label:"Filtro de flujo ascendente"},
    {prodId:"47101552",label:"Filtro de cama movible"},
    {prodId:"47101553",label:"Filtro de fibra"},
    {prodId:"47101554",label:"Probador de jarro"},
    {prodId:"47101555",label:"Tabique de interceptación"},
    {prodId:"47101556",label:"Bandeja de goteo de agua"},
    {prodId:"47101557",label:"Plato inclinado para asentamiento"},
    {prodId:"47101558",label:"Removedor de espuma"},
    {prodId:"47101559",label:"Equipo de remoción de mugre"},
    {prodId:"47101560",label:"Equipo de bajo drenaje de filtración"},
    {prodId:"47101561",label:"Equipo de tratamiento de adulteración"},
    {prodId:"47101562",label:"Pantalla de tambor"},
    {prodId:"47101563",label:"Separador coalescente de aguas residuales"},
    {prodId:"47101564",label:"Triturador de desperdicios"},
    {prodId:"47101565",label:"Quemador de gas de desperdicios"},
    {prodId:"47101566",label:"Filtro de medios múltiples"},
    {prodId:"47101567",label:"Máquina de electrodiálisis"},
    {prodId:"47101568",label:"Unidad de limpieza de piscinas"},
    {prodId:"47101569",label:"Control plano de aguas residuales"},
    {prodId:"47101570",label:"Cuneta de oxidación"},
    {prodId:"47101571",label:"Unidad de neutralización de gas de cloro"},
    {prodId:"47101572",label:"Control de disco"},
    {prodId:"47101573",label:"Floculador"},
    {prodId:"47101574",label:"Reactor de lotes de secuenciación"},
    {prodId:"47101600",label:"Consumibles para el tratamiento de agua"},
    {prodId:"47101601",label:"Alguicidas"},
    {prodId:"47101602",label:"Anti incrustante"},
    {prodId:"47101603",label:"Limpiadores anti calcáreos"},
    {prodId:"47101604",label:"Químicos de alimentación de calderas"},
    {prodId:"47101605",label:"Químicos de remoción bacteriana"},
    {prodId:"47101606",label:"Químicos de control de corrosión"},
    {prodId:"47101607",label:"Químicos de control de olor"},
    {prodId:"47101608",label:"Floculantes"},
    {prodId:"47101609",label:"Microbicidas"},
    {prodId:"47101610",label:"Compuestos para suavizar el agua"},
    {prodId:"47101611",label:"Demulsificantes"},
    {prodId:"47101612",label:"Polielectrolitos"},
    {prodId:"47101613",label:"Soluciones de neutralización"},
    {prodId:"47101614",label:"Agente espesante de lodo"},
    {prodId:"47101615",label:"Químico de fluorización del agua"},
    {prodId:"47111500",label:"Equipo de lavado y secado"},
    {prodId:"47111501",label:"Máquinas lavadoras o secadoras combinadas tipo lavandería"},
    {prodId:"47111502",label:"Máquinas lavadoras tipo lavandería"},
    {prodId:"47111503",label:"Secadoras de ropa"},
    {prodId:"47111505",label:"Puestos para equipos de lavandería"},
    {prodId:"47111506",label:"Extractor centrífugo de lavandería"},
    {prodId:"47111507",label:"Puesto de secado de lavandería"},
    {prodId:"47111508",label:"Tabla de manchas de lavandería"},
    {prodId:"47111509",label:"Mesa de trabajo de lavandería"},
    {prodId:"47111600",label:"Equipo de planchado"},
    {prodId:"47111601",label:"Máquinas para planchar o prensas"},
    {prodId:"47111602",label:"Máquinas para doblar"},
    {prodId:"47111603",label:"Máquinas de vapor para planchar"},
    {prodId:"47121500",label:"Carritos y accesorios para limpieza"},
    {prodId:"47121501",label:"Carritos de portero"},
    {prodId:"47121502",label:"Accesorios de carrito de portero"},
    {prodId:"47121600",label:"Máquinas y accesorios para pisos"},
    {prodId:"47121602",label:"Aspiradoras"},
    {prodId:"47121603",label:"Brilladoras de pisos"},
    {prodId:"47121604",label:"Aspiradoras de combinación secas o húmedas"},
    {prodId:"47121605",label:"Depuradores para pisos"},
    {prodId:"47121606",label:"Barredoras de alfombras"},
    {prodId:"47121607",label:"Suministros o accesorios de aspiradoras"},
    {prodId:"47121608",label:"Almohadillas de máquinas de piso"},
    {prodId:"47121609",label:"Equipo de limpieza de alfombras"},
    {prodId:"47121610",label:"Máquina para lavar pisos"},
    {prodId:"47121611",label:"Raspadores de pisos"},
    {prodId:"47121612",label:"Barredoras para pisos"},
    {prodId:"47121613",label:"Accesorios para brilladoras de pisos"},
    {prodId:"47121700",label:"Envases y accesorios para residuos"},
    {prodId:"47121701",label:"Bolsas de basura"},
    {prodId:"47121702",label:"Contenedores de desperdicios o revestimientos rígidos"},
    {prodId:"47121703",label:"Urnas de incineración o accesorios"},
    {prodId:"47121704",label:"Tapas de contenedores de basura"},
    {prodId:"47121705",label:"Bolsas de arena para urnas de incineración"},
    {prodId:"47121706",label:"Ceniceros"},
    {prodId:"47121707",label:"Bolsas para mareo"},
    {prodId:"47121708",label:"Bolsas higiénicas"},
    {prodId:"47121709",label:"Contenedor de residuos peligrosos"},
    {prodId:"47121800",label:"Equipo de limpieza"},
    {prodId:"47121801",label:"Plumeros para limpiar el polvo"},
    {prodId:"47121802",label:"Removedores de pelusa"},
    {prodId:"47121803",label:"Esponjas o esponjillas"},
    {prodId:"47121804",label:"Baldes para limpieza"},
    {prodId:"47121805",label:"Limpiadores de presión o de vapor"},
    {prodId:"47121806",label:"Escurridor de trapero"},
    {prodId:"47121807",label:"Émbolo del lavaplatos o inodoro"},
    {prodId:"47121808",label:"Equipo de limpieza de drenajes o tubos"},
    {prodId:"47121809",label:"Sartenes desengrasantes"},
    {prodId:"47121810",label:"Dispensador de trapos para limpiar"},
    {prodId:"47121811",label:"Máquinas para limpiar ductos"},
    {prodId:"47121812",label:"Raspadores de limpieza"},
    {prodId:"47121813",label:"Cuchillas de repuesto para raspadores"},
    {prodId:"47121814",label:"Separador de polvo"},
    {prodId:"47121815",label:"Limpiador de piscinas"},
    {prodId:"47121900",label:"Accesorios de equipo de limpieza"},
    {prodId:"47121901",label:"Cartucheras para esponjas o esponjillas"},
    {prodId:"47121902",label:"Accesorios para esponjas o esponjillas"},
    {prodId:"47121903",label:"Accesorios para limpiadores de vapor o presión"},
    {prodId:"47131500",label:"Trapos y paños de limpieza"},
    {prodId:"47131501",label:"Trapos"},
    {prodId:"47131502",label:"Pañitos o toallas para limpiar"},
    {prodId:"47131503",label:"Gamuzas o cueros para lavar"},
    {prodId:"47131600",label:"Escobas, traperos, cepillos y accesorios"},
    {prodId:"47131601",label:"Cepillos o recogedores para polvo"},
    {prodId:"47131602",label:"Almohadillas para restregar"},
    {prodId:"47131603",label:"Esponjas"},
    {prodId:"47131604",label:"Escobas"},
    {prodId:"47131605",label:"Cepillos de limpieza"},
    {prodId:"47131608",label:"Cepillos de baño"},
    {prodId:"47131609",label:"Manijas de escobas o traperos"},
    {prodId:"47131610",label:"Aplicador de terminado para pisos"},
    {prodId:"47131611",label:"Recogedor de basura"},
    {prodId:"47131612",label:"Cauchos de repuesto"},
    {prodId:"47131613",label:"Sujetador de traperos o escobas"},
    {prodId:"47131614",label:"Pinzas para equipos de limpieza"},
    {prodId:"47131615",label:"Cabezas de escoba"},
    {prodId:"47131616",label:"Sujetadores de pañitos de limpieza"},
    {prodId:"47131617",label:"Traperos para polvo"},
    {prodId:"47131618",label:"Traperos húmedos"},
    {prodId:"47131619",label:"Cabezas de traperos"},
    {prodId:"47131700",label:"Suministros para aseos"},
    {prodId:"47131701",label:"Dispensadores de toallas de papel"},
    {prodId:"47131702",label:"Dispensadores de productos sanitarios"},
    {prodId:"47131703",label:"Receptáculos para residuos sanitarios"},
    {prodId:"47131704",label:"Dispensadores institucionales de jabón o loción"},
    {prodId:"47131705",label:"Accesorios para urinales o inodoros"},
    {prodId:"47131706",label:"Dispensadores de ambientadores"},
    {prodId:"47131707",label:"Secadores de manos institucionales"},
    {prodId:"47131709",label:"Dispensadores de pañuelos faciales"},
    {prodId:"47131710",label:"Dispensadores de papel higiénico"},
    {prodId:"47131711",label:"Dispensadores de limpiador"},
    {prodId:"47131800",label:"Soluciones de limpieza y desinfección"},
    {prodId:"47131801",label:"Limpiadores de pisos"},
    {prodId:"47131802",label:"Terminados o ceras para pisos"},
    {prodId:"47131803",label:"Desinfectantes para uso doméstico"},
    {prodId:"47131804",label:"Limpiadores de amoniaco"},
    {prodId:"47131805",label:"Limpiadores de propósito general"},
    {prodId:"47131806",label:"Pulidores o ceras para muebles"},
    {prodId:"47131807",label:"Blanqueadores"},
    {prodId:"47131808",label:"Germicida seco"},
    {prodId:"47131809",label:"Productos para limpiar o brillar zapatos"},
    {prodId:"47131810",label:"Productos para el lavaplatos"},
    {prodId:"47131811",label:"Productos de lavandería"},
    {prodId:"47131812",label:"Refrescador de aire"},
    {prodId:"47131813",label:"Limpiador de pantallas"},
    {prodId:"47131814",label:"Limpiadores o pulidores de metales"},
    {prodId:"47131815",label:"Limpiador de drenajes"},
    {prodId:"47131816",label:"Desodorantes"},
    {prodId:"47131817",label:"Protectores de uso doméstico o para automotores"},
    {prodId:"47131818",label:"Antiséptico de aire"},
    {prodId:"47131819",label:"Limpiadores cáusticos"},
    {prodId:"47131820",label:"Limpiadores derivados del petróleo"},
    {prodId:"47131821",label:"Compuestos desengrasantes"},
    {prodId:"47131822",label:"Compuestos para remover carbón"},
    {prodId:"47131823",label:"Descongelantes o deshieladores"},
    {prodId:"47131824",label:"Limpiadores de vidrio o ventanas"},
    {prodId:"47131825",label:"Limpiadores de superficie de contacto"},
    {prodId:"47131826",label:"Limpiadores de alfombras o tapizados"},
    {prodId:"47131827",label:"Limpiadores o removedores de manchas"},
    {prodId:"47131828",label:"Limpiadores de automotores"},
    {prodId:"47131829",label:"Limpiadores de baños"},
    {prodId:"47131830",label:"Limpiadores de muebles"},
    {prodId:"47131831",label:"Ácido muriático"},
    {prodId:"47131832",label:"Productos anti polvo"},
    {prodId:"47131833",label:"Antisépticos para uso en alimentos"},
    {prodId:"47131834",label:"Soluciones para limpiar joyas"},
    {prodId:"47131835",label:"Agente desulfurizante"},
    {prodId:"47131900",label:"Absorbentes"},
    {prodId:"47131901",label:"Almohadillas absorbentes"},
    {prodId:"47131902",label:"Absorbentes granulares"},
    {prodId:"47131904",label:"Medias absorbentes"},
    {prodId:"47131905",label:"Kits para derrames"},
    {prodId:"47131906",label:"Bandejas absorbentes"},
    {prodId:"47131907",label:"Escobas absorbentes"},
    {prodId:"47131908",label:"Almohadas absorbentes"},
    {prodId:"47131909",label:"Almohadillas o rollos absorbentes"},
    {prodId:"47131910",label:"Polímero súper absorbente"},
    {prodId:"47132100",label:"Kits de limpieza"},
    {prodId:"47132101",label:"Kits de limpieza industrial"},
    {prodId:"47132102",label:"Kits de limpieza para uso general"},
    {prodId:"48101500",label:"Equipo para cocinar o calentar"},
    {prodId:"48101501",label:"Baños maría para uso comercial"},
    {prodId:"48101502",label:"Hornos de barbecue para uso comercial"},
    {prodId:"48101503",label:"Asadores de uso comercial"},
    {prodId:"48101504",label:"Parrillas de carbón para uso comercial"},
    {prodId:"48101505",label:"Cafeteras o máquinas para hacer té helado de uso comercial"},
    {prodId:"48101506",label:"Calentadoras de café para uso comercial"},
    {prodId:"48101507",label:"Hornos de convección para uso comercial"},
    {prodId:"48101508",label:"Tostadoras sinfín para uso comercial"},
    {prodId:"48101509",label:"Freidoras para uso comercial"},
    {prodId:"48101510",label:"Calentadoras de comida para uso comercial"},
    {prodId:"48101511",label:"Planchas para uso comercial"},
    {prodId:"48101512",label:"Parrillas metálicas para uso comercial"},
    {prodId:"48101513",label:"Lámparas de calor para uso comercial"},
    {prodId:"48101514",label:"Ollas de vapor de alta presión para uso comercial"},
    {prodId:"48101515",label:"Parrillas para perros calientes para uso comercial"},
    {prodId:"48101516",label:"Hornos microondas para uso comercial"},
    {prodId:"48101517",label:"Hornos para uso comercial"},
    {prodId:"48101518",label:"Ollas para cocinar pasta para uso comercial"},
    {prodId:"48101519",label:"Hornos de pizza para uso comercial"},
    {prodId:"48101520",label:"Máquinas de maíz pira para uso comercial"},
    {prodId:"48101521",label:"Planchas de estufa para uso comercial"},
    {prodId:"48101522",label:"Asadores para uso comercial"},
    {prodId:"48101523",label:"Ahumadores u hornos para ahumar para uso comercial"},
    {prodId:"48101524",label:"Ollas de vapor para uso comercial"},
    {prodId:"48101525",label:"Tostadoras para uso comercial"},
    {prodId:"48101526",label:"Hierros para waffles para uso comercial"},
    {prodId:"48101527",label:"Barbecues"},
    {prodId:"48101528",label:"Máquinas de crepes para uso comercial"},
    {prodId:"48101529",label:"Ollas de presión o freidoras de presión"},
    {prodId:"48101530",label:"Ollas arroceras para uso comercial"},
    {prodId:"48101531",label:"Ollas o calderas para salmón para uso comercial"},
    {prodId:"48101532",label:"Máquinas o accesorios para algodón dulce para uso comercial"},
    {prodId:"48101533",label:"Horno de combinación para uso comercial"},
    {prodId:"48101534",label:"Tabla de hervir para uso comercial"},
    {prodId:"48101535",label:"Parrillas “salamandra”"},
    {prodId:"48101536",label:"Horno de pastelería para uso comercial"},
    {prodId:"48101537",label:"Horno sinfín para uso comercial"},
    {prodId:"48101538",label:"Parrilla de doble contacto para uso comercial"},
    {prodId:"48101539",label:"Unidad de filtración de grasa para uso comercial"},
    {prodId:"48101540",label:"Unidad de calentamiento wok para uso comercial"},
    {prodId:"48101541",label:"Almacenador y escurridor de papas fritas para uso comercial"},
    {prodId:"48101542",label:"Tetera revestida de inclinación para uso comercial"},
    {prodId:"48101543",label:"Sartén revestido para uso comercial"},
    {prodId:"48101544",label:"Sartén para usar directamente en el calor para uso comercial"},
    {prodId:"48101545",label:"Puesto para horno para uso comercial"},
    {prodId:"48101546",label:"Máquina de decocción para uso comercial"},
    {prodId:"48101600",label:"Equipos para preparado de alimentos"},
    {prodId:"48101601",label:"Mezcladores para uso comercial"},
    {prodId:"48101602",label:"Abrelatas eléctricos para uso comercial"},
    {prodId:"48101603",label:"Cortadores o cortadores en cubos o en dados para uso comercial"},
    {prodId:"48101604",label:"Moledoras de café para uso comercial"},
    {prodId:"48101605",label:"Moledoras de alimentos para uso comercial"},
    {prodId:"48101606",label:"Rallos para uso comercial"},
    {prodId:"48101607",label:"Exprimidores de jugo para uso comercial"},
    {prodId:"48101608",label:"Licuadoras para uso comercial"},
    {prodId:"48101609",label:"Máquinas de pasta para uso comercial"},
    {prodId:"48101610",label:"Peladores para uso comercial"},
    {prodId:"48101611",label:"Escalas para uso comercial"},
    {prodId:"48101612",label:"Procesadores de alimentos para uso comercial"},
    {prodId:"48101613",label:"Máquinas de amasar para uso comercial"},
    {prodId:"48101614",label:"Sets o bolsas para hielo para uso comercial"},
    {prodId:"48101615",label:"Lavadoras de platos para uso comercial"},
    {prodId:"48101616",label:"Tajadores de alimentos para uso comercial"},
    {prodId:"48101617",label:"Espátulas plásticas para uso comercial"},
    {prodId:"48101618",label:"Partes de máquinas para lavar platos para uso comercial"},
    {prodId:"48101619",label:"Sistema de esterilización de agua"},
    {prodId:"48101700",label:"Distribuidores automáticos de comida y bebida"},
    {prodId:"48101701",label:"Dispensador de bebidas carbonatadas"},
    {prodId:"48101702",label:"Dispensador de bebidas no carbonatadas"},
    {prodId:"48101703",label:"Dispensadores de leche"},
    {prodId:"48101704",label:"Bombas de melaza (syrup)"},
    {prodId:"48101705",label:"Máquinas de cappuccino o expreso"},
    {prodId:"48101706",label:"Máquinas de leche malteada"},
    {prodId:"48101707",label:"Máquinas de helados suaves"},
    {prodId:"48101708",label:"Máquinas de granizados"},
    {prodId:"48101709",label:"Dispensadores de hielo"},
    {prodId:"48101710",label:"Fuentes o contenedores de burbujeo de bebidas"},
    {prodId:"48101711",label:"Dispensadores de agua embotellada o accesorios"},
    {prodId:"48101712",label:"Dispensadores de tazas"},
    {prodId:"48101713",label:"Mezcladores de cócteles o accesorios"},
    {prodId:"48101714",label:"Dispensadores de agua caliente"},
    {prodId:"48101715",label:"Máquina ralladora de hielo"},
    {prodId:"48101716",label:"Purificador de agua"},
    {prodId:"48101800",label:"Menaje y utensilios de cocina"},
    {prodId:"48101801",label:"Cubiertos para uso comercial"},
    {prodId:"48101802",label:"Moldes para uso comercial"},
    {prodId:"48101803",label:"Cucharones para uso comercial"},
    {prodId:"48101804",label:"Tazas medidoras para uso comercial"},
    {prodId:"48101805",label:"Tazones para mezclar para uso comercial"},
    {prodId:"48101806",label:"Latas de ponqués o pies para uso comercial"},
    {prodId:"48101807",label:"Latas de pizza para uso comercial"},
    {prodId:"48101808",label:"Sartenes de salsa o para sofreír para uso comercial"},
    {prodId:"48101809",label:"Ollas para salsas o cocción para uso comercial"},
    {prodId:"48101810",label:"Tapas para sartenes u ollas para uso comercial"},
    {prodId:"48101811",label:"Rodillos de amasar para uso comercial"},
    {prodId:"48101812",label:"Coladeras para uso comercial"},
    {prodId:"48101813",label:"Batidoras manuales para uso comercial"},
    {prodId:"48101814",label:"Woks para uso comercial"},
    {prodId:"48101815",label:"Cucharas de servir para uso comercial"},
    {prodId:"48101816",label:"Bolsa para cubiertos para uso comercial"},
    {prodId:"48101817",label:"Equipos o moldes para decorar ponqués"},
    {prodId:"48101818",label:"Desinfectante de vajillas"},
    {prodId:"48101819",label:"Esterilizador de manos"},
    {prodId:"48101820",label:"Campana extractora para uso comercial"},
    {prodId:"48102000",label:"Mobiliario de restaurantes"},
    {prodId:"48102001",label:"Sillas para restaurantes"},
    {prodId:"48102002",label:"Cabinas para restaurantes"},
    {prodId:"48102003",label:"Barras de ensalada"},
    {prodId:"48102004",label:"Superficies de mesa"},
    {prodId:"48102005",label:"Taburetes de bar"},
    {prodId:"48102006",label:"Bares permanentes"},
    {prodId:"48102007",label:"Bares portátiles"},
    {prodId:"48102008",label:"Mesas para condimentos"},
    {prodId:"48102009",label:"Mesas para servir o dispensar comidas"},
    {prodId:"48102010",label:"Carrito para recoger tazas"},
    {prodId:"48102100",label:"Suministros y equipo de manipulación y almacenamiento"},
    {prodId:"48102101",label:"Mostradores"},
    {prodId:"48102102",label:"Mostradores para mantener caliente la comida"},
    {prodId:"48102103",label:"Mostradores refrigerados"},
    {prodId:"48102104",label:"Mostradores para helados"},
    {prodId:"48102105",label:"Equipos para enfriar vasos"},
    {prodId:"48102106",label:"Contenedores fríos"},
    {prodId:"48102107",label:"Guantes para abastecimiento de comidas por encargo (catering) o dispensadores de guantes"},
    {prodId:"48102108",label:"Papel aluminio para envolver alimentos"},
    {prodId:"48102109",label:"Papel plástico para envolver alimentos"},
    {prodId:"48111000",label:"Máquinas expendedoras de líquidos"},
    {prodId:"48111001",label:"Máquinas dispensadoras de porciones únicas con tazas"},
    {prodId:"48111002",label:"Maquinas dispensadoras de cantidades grandes"},
    {prodId:"48111100",label:"Máquinas expendedoras de piezas/unidades"},
    {prodId:"48111101",label:"Máquinas expendedoras de botellas o latas"},
    {prodId:"48111102",label:"Máquinas de bolas de dulce o novedades para niños"},
    {prodId:"48111103",label:"Máquinas exhibidoras de botanas o productos en paquetes pequeños"},
    {prodId:"48111104",label:"Máquinas expendedoras de comidas a la carta"},
    {prodId:"48111105",label:"Máquinas de confitería helada"},
    {prodId:"48111106",label:"Dispensadores de ítems de acomodación personal"},
    {prodId:"48111107",label:"Máquinas de cigarrillos"},
    {prodId:"48111108",label:"Dispensadores de medicamentos"},
    {prodId:"48111109",label:"Máquina para lustrar calzado"},
    {prodId:"48111400",label:"Máquinas expendedoras de dinero efectivo"},
    {prodId:"48111401",label:"Máquinas de cajero automático atm"},
    {prodId:"48111402",label:"Máquinas para cambiar billetes en monedas"},
    {prodId:"48111403",label:"Máquinas para cambiar moneda extranjera"},
    {prodId:"48111404",label:"Equipos de transferencia electrónica de fondos para puntos de venta"},
    {prodId:"48111405",label:"Accesorios para máquinas de cajero automático"},
    {prodId:"48111406",label:"Sistema de numeración de filas"},
    {prodId:"48121100",label:"Máquinas de Juego operadas con monedas"},
    {prodId:"48121101",label:"Máquinas de póker o tragamonedas"},
    {prodId:"48121102",label:"Máquinas de lotería"},
    {prodId:"48121200",label:"Mesas de Juego y Juegos"},
    {prodId:"48121201",label:"Ruedas de ruleta"},
    {prodId:"48121202",label:"Mesas de naipes"},
    {prodId:"48131500",label:"Productos de entierro o tumbas"},
    {prodId:"48131501",label:"Cajas de cenizas funerarias o urnas de cremación"},
    {prodId:"48131502",label:"Ataúdes"},
    {prodId:"48131503",label:"Mortajas"},
    {prodId:"48131504",label:"Lápidas"},
    {prodId:"48131505",label:"Tabla de ofrecimientos en piedra"},
    {prodId:"49101600",label:"Coleccionables"},
    {prodId:"49101601",label:"Antigüedades"},
    {prodId:"49101602",label:"Recuerdos (souvenirs)"},
    {prodId:"49101603",label:"Colecciones de monedas"},
    {prodId:"49101604",label:"Colecciones de estampillas"},
    {prodId:"49101605",label:"Alfombras antiguas"},
    {prodId:"49101606",label:"Artículos excavados"},
    {prodId:"49101607",label:"Colecciones de historietas cómicas"},
    {prodId:"49101608",label:"Instrumentos musicales antiguos"},
    {prodId:"49101609",label:"Ornamentos o decoraciones"},
    {prodId:"49101611",label:"Amuletos"},
    {prodId:"49101612",label:"Hologramas"},
    {prodId:"49101613",label:"Cristales de vidrio"},
    {prodId:"49101614",label:"Máscaras rituales o para teatro"},
    {prodId:"49101700",label:"Premios"},
    {prodId:"49101701",label:"Medallas"},
    {prodId:"49101702",label:"Trofeos"},
    {prodId:"49101704",label:"Placas"},
    {prodId:"49101705",label:"Certificados"},
    {prodId:"49101706",label:"Premios de fotografía"},
    {prodId:"49101707",label:"Certificado de logro"},
    {prodId:"49101708",label:"Coronas"},
    {prodId:"49101709",label:"Tabletas de conmemoración"},
    {prodId:"49121500",label:"Equipos para camping y exteriores"},
    {prodId:"49121502",label:"Almohadas para dormir para acampar"},
    {prodId:"49121503",label:"Carpas"},
    {prodId:"49121504",label:"Sleeping bags (bolsas para dormir)"},
    {prodId:"49121505",label:"Cajas de hielo"},
    {prodId:"49121506",label:"Kit de reparación de carpas"},
    {prodId:"49121507",label:"Colchones neumáticos"},
    {prodId:"49121508",label:"Mosquiteros"},
    {prodId:"49121509",label:"Estufas para acampar o para exteriores"},
    {prodId:"49121510",label:"Enfriadores de bebidas"},
    {prodId:"49121511",label:"Reverberos para acampar o exteriores"},
    {prodId:"49121512",label:"Estacas para carpas"},
    {prodId:"49121513",label:"Tornillos para carpas"},
    {prodId:"49121600",label:"Muebles de camping"},
    {prodId:"49121601",label:"Asientos o taburetes para acampar"},
    {prodId:"49121602",label:"Mesas para acampar"},
    {prodId:"49121603",label:"Catres para acampar"},
    {prodId:"49131500",label:"Aparejos de pesca"},
    {prodId:"49131501",label:"Cañas de pescar"},
    {prodId:"49131502",label:"Hilo de pesca"},
    {prodId:"49131503",label:"Carreteles de pesca"},
    {prodId:"49131504",label:"Cebos para pesca"},
    {prodId:"49131505",label:"Carnadas"},
    {prodId:"49131506",label:"Pesas para pesca"},
    {prodId:"49131600",label:"Productos de cacería"},
    {prodId:"49131601",label:"Llamadas de animales"},
    {prodId:"49131602",label:"Señuelos para deportes"},
    {prodId:"49131603",label:"Trampas para deportes"},
    {prodId:"49131604",label:"Escopetas para deportes"},
    {prodId:"49131605",label:"Rifles para deportes"},
    {prodId:"49131606",label:"Munición para deportes"},
    {prodId:"49131607",label:"Cañones de escopetas"},
    {prodId:"49141500",label:"Escafandras autónomas y tubos de respiración"},
    {prodId:"49141501",label:"Compensadores de flotación"},
    {prodId:"49141502",label:"Tanques de buceo"},
    {prodId:"49141503",label:"Reguladores para buceo"},
    {prodId:"49141504",label:"Instrumentos de buceo o accesorios"},
    {prodId:"49141505",label:"Máscaras o aletas o esnórqueles"},
    {prodId:"49141506",label:"Trajes isotérmicos"},
    {prodId:"49141508",label:"Botas para buceo"},
    {prodId:"49141509",label:"Capucha para buceo"},
    {prodId:"49141600",label:"Equipos y accesorios para windsurf y natación"},
    {prodId:"49141602",label:"Tablas de esquí acuático o tablas para arrodillarse o boogieboards"},
    {prodId:"49141603",label:"Esquís acuáticos o accesorios"},
    {prodId:"49141604",label:"Equipos de windsurfing"},
    {prodId:"49141605",label:"Tablas de surf"},
    {prodId:"49141606",label:"Anteojos de natación o aletas de natación"},
    {prodId:"49141607",label:"Equipo de paravelismo"},
    {prodId:"49161500",label:"Equipos para deportes de campo"},
    {prodId:"49161501",label:"Toboganes de bloqueo para futbol"},
    {prodId:"49161502",label:"Guantes de beisbol"},
    {prodId:"49161503",label:"Pelotas de beisbol"},
    {prodId:"49161504",label:"Balones de futbol"},
    {prodId:"49161505",label:"Balones de soccer"},
    {prodId:"49161506",label:"Bates de beisbol"},
    {prodId:"49161507",label:"Bases de beisbol"},
    {prodId:"49161508",label:"Máquinas para lanzar (pitching)"},
    {prodId:"49161509",label:"Balones de softbol"},
    {prodId:"49161510",label:"Muñecos para jugadas de futbol"},
    {prodId:"49161511",label:"Palos de lacrosse"},
    {prodId:"49161512",label:"Pelotas de lacrosse"},
    {prodId:"49161513",label:"Palos de hockey de campo"},
    {prodId:"49161514",label:"Bolas de hockey de campo"},
    {prodId:"49161515",label:"Pelotas de equipos de balonmano"},
    {prodId:"49161516",label:"Sets escolares de equipos de balonmano"},
    {prodId:"49161517",label:"Equipo protector para beisbol o softbol"},
    {prodId:"49161518",label:"Ayudas para batear en beisbol"},
    {prodId:"49161519",label:"Defensas traseras o cercas para beisbol"},
    {prodId:"49161520",label:"Bates de softbol"},
    {prodId:"49161521",label:"Guantes de softbol"},
    {prodId:"49161522",label:"Tees de patada para futbol"},
    {prodId:"49161523",label:"Equipo de futbol de bandera"},
    {prodId:"49161524",label:"Equipo de marcado de campo para soccer"},
    {prodId:"49161525",label:"Equipo protector para soccer"},
    {prodId:"49161526",label:"Ayudas de entrenamiento para soccer"},
    {prodId:"49161527",label:"Palo de gateball"},
    {prodId:"49161600",label:"Equipos para deportes de raqueta y pista"},
    {prodId:"49161601",label:"Raquetas de racquetball"},
    {prodId:"49161602",label:"Raquetas de bádminton"},
    {prodId:"49161603",label:"Pelotas de básquetbol"},
    {prodId:"49161604",label:"Pelotas de tenis"},
    {prodId:"49161605",label:"Pelotas de racquet"},
    {prodId:"49161606",label:"Pelotas de squash"},
    {prodId:"49161607",label:"Raquetas de tenis"},
    {prodId:"49161608",label:"Balones de voleibol"},
    {prodId:"49161609",label:"Gallitos de bádminton"},
    {prodId:"49161610",label:"Raquetas de squash"},
    {prodId:"49161611",label:"Ayudas de entrenamiento para tenis"},
    {prodId:"49161612",label:"Equipo de cancha de tenis"},
    {prodId:"49161613",label:"Almacenamiento para balones o redes de voleibol"},
    {prodId:"49161614",label:"Estándares gimnásticos para voleibol"},
    {prodId:"49161615",label:"Sistemas de juego completo para basquetbol"},
    {prodId:"49161616",label:"Equipo protector para hockey de piso"},
    {prodId:"49161617",label:"Postes para red"},
    {prodId:"49161618",label:"Espiros"},
    {prodId:"49161619",label:"Cuerdas para raquetas"},
    {prodId:"49161620",label:"Agarres (“grips”) para raquetas"},
    {prodId:"49161621",label:"Máquinas para cuerdas de raqueta"},
    {prodId:"49161700",label:"Equipos para deportes de pista"},
    {prodId:"49161701",label:"Jabalinas"},
    {prodId:"49161702",label:"Barras para saltar"},
    {prodId:"49161703",label:"Discos"},
    {prodId:"49161704",label:"Balas de lanzamiento"},
    {prodId:"49161705",label:"Garrochas"},
    {prodId:"49161706",label:"Obstáculos"},
    {prodId:"49161707",label:"Bastones"},
    {prodId:"49161708",label:"Barra de salto y garrocha"},
    {prodId:"49161709",label:"Círculo de lanzamiento de disco"},
    {prodId:"49161710",label:"Martillo para lanzar"},
    {prodId:"49161711",label:"Círculo de lanzamiento de bala"},
    {prodId:"49171500",label:"Equipos de gimnasia"},
    {prodId:"49171501",label:"Barras o postes gimnásticos"},
    {prodId:"49171502",label:"Cuerdas o anillos o accesorios para trepar gimnásticos"},
    {prodId:"49171503",label:"Equipo de garrocha para uso gimnástico"},
    {prodId:"49171504",label:"Trampolines para uso gimnástico"},
    {prodId:"49171505",label:"Equipo de equilibrio"},
    {prodId:"49171506",label:"Caballo de borrén delantero para uso gimnástico"},
    {prodId:"49171507",label:"Club indio para uso gimnástico"},
    {prodId:"49171508",label:"Trampolín de salto o “beatboard” para uso gimnástico"},
    {prodId:"49171509",label:"Caja de salto para uso gimnástico"},
    {prodId:"49171600",label:"Equipos de boxeo"},
    {prodId:"49171601",label:"Rings para boxeo"},
    {prodId:"49171602",label:"Sacos de boxeo"},
    {prodId:"49171603",label:"Guantes de boxeo"},
    {prodId:"49171604",label:"Campana de boxeo"},
    {prodId:"49181500",label:"Equipos y juegos de mesa"},
    {prodId:"49181501",label:"Mesas de billar"},
    {prodId:"49181502",label:"Tacos de pool"},
    {prodId:"49181503",label:"Juegos de tejo"},
    {prodId:"49181504",label:"Juegos de pinball"},
    {prodId:"49181505",label:"Bolas de billar"},
    {prodId:"49181506",label:"Mesas para hockey aéreo o accesorios"},
    {prodId:"49181507",label:"Mesas de tenis (ping pong)"},
    {prodId:"49181508",label:"Raquetas de ping pong"},
    {prodId:"49181509",label:"Bolas de ping pong"},
    {prodId:"49181510",label:"Mesas de foosball"},
    {prodId:"49181511",label:"Bolas de foosball"},
    {prodId:"49181512",label:"Jugadores de repuesto para foosball"},
    {prodId:"49181513",label:"Puntas de tacos de billar"},
    {prodId:"49181514",label:"Tiza de billar"},
    {prodId:"49181515",label:"Estantes de billar"},
    {prodId:"49181600",label:"Equipo y juegos de tiro al blanco"},
    {prodId:"49181601",label:"Objetivos para tiro con arco"},
    {prodId:"49181602",label:"Arcos"},
    {prodId:"49181603",label:"Flechas"},
    {prodId:"49181605",label:"Tableros de dardos"},
    {prodId:"49181606",label:"Equipo de tiro al plato"},
    {prodId:"49181607",label:"Objetivos para lanzar"},
    {prodId:"49181608",label:"Cuerdas para arcos"},
    {prodId:"49181609",label:"Guantes para tiro con arco"},
    {prodId:"49181610",label:"Protectores de brazos para tiro con arco"},
    {prodId:"49181611",label:"Puestos de objetivos para tiro con arco"},
    {prodId:"49181612",label:"Defensas traseras para tiro con arco"},
    {prodId:"49181613",label:"Gukgung (arco coreano)"},
    {prodId:"49181614",label:"Ballesta para tiro con arco"},
    {prodId:"49201500",label:"Equipo de entrenamiento para aeróbicos"},
    {prodId:"49201501",label:"Trotadoras"},
    {prodId:"49201502",label:"Escaladores de escaleras"},
    {prodId:"49201503",label:"Bicicletas estáticas"},
    {prodId:"49201504",label:"Máquinas de remos"},
    {prodId:"49201512",label:"Lazos para saltar"},
    {prodId:"49201513",label:"Trampolines para ejercicios"},
    {prodId:"49201514",label:"Pelotas para ejercicios"},
    {prodId:"49201515",label:"Equipos de paso para aeróbicos (“step”)"},
    {prodId:"49201516",label:"Bicicletas elípticas"},
    {prodId:"49201517",label:"Máquina de inversión"},
    {prodId:"49201518",label:"Barras de pared"},
    {prodId:"49201519",label:"Torbellino de cuerpo"},
    {prodId:"49201520",label:"Set de paletas para viaje"},
    {prodId:"49201600",label:"Equipo de entrenamiento de pesas y resistencia"},
    {prodId:"49201601",label:"Pesas"},
    {prodId:"49201602",label:"Pesas de 280 libras"},
    {prodId:"49201603",label:"Máquinas de resistencia para la parte inferior del cuerpo"},
    {prodId:"49201604",label:"Bancos o estantes para pesas"},
    {prodId:"49201605",label:"Máquinas de resistencia para la parte superior del cuerpo"},
    {prodId:"49201606",label:"Pesas de estado físico"},
    {prodId:"49201607",label:"Máquinas de pilates"},
    {prodId:"49201608",label:"Máquina para dar fuerza de agarre"},
    {prodId:"49201609",label:"Bandas de resistencia"},
    {prodId:"49201610",label:"Tubos de resistencia"},
    {prodId:"49201611",label:"Multi gimnasios"},
    {prodId:"49201612",label:"Pesas de entrenamiento"},
    {prodId:"49211600",label:"Equipo de golf"},
    {prodId:"49211601",label:"Talegas de golf"},
    {prodId:"49211602",label:"Bolas de golf"},
    {prodId:"49211603",label:"Tacos de golf"},
    {prodId:"49211604",label:"Tees de golf"},
    {prodId:"49211605",label:"Forros para las cabezas de los tacos de golf"},
    {prodId:"49211606",label:"Guantes de golf"},
    {prodId:"49211607",label:"Divot fijador"},
    {prodId:"49211608",label:"Golfscopios"},
    {prodId:"49211609",label:"Compañero de putting para golf"},
    {prodId:"49211800",label:"Equipo de clase de educación física"},
    {prodId:"49211801",label:"Equipo de paracaidismo"},
    {prodId:"49211802",label:"Aros de hula o equipos de hula"},
    {prodId:"49211803",label:"Equipos de orientación"},
    {prodId:"49211804",label:"Materiales o marcadores de identificación de equipos"},
    {prodId:"49211805",label:"Cuerdas"},
    {prodId:"49211806",label:"Almacenamiento de equipos de educación física"},
    {prodId:"49211807",label:"Herramientas de evaluación para educación física"},
    {prodId:"49211808",label:"Herramienta para medir la obesidad"},
    {prodId:"49211809",label:"Herramienta para medir el cuerpo"},
    {prodId:"49211810",label:"Pistola de radar para medir la velocidad"},
    {prodId:"49211811",label:"Ergómetro"},
    {prodId:"49211812",label:"Dispositivo de medición de la flexión del tronco"},
    {prodId:"49211813",label:"Medidor de saltos"},
    {prodId:"49211814",label:"Instrumento de medición de fatiga"},
    {prodId:"49211815",label:"Sistema de medición de reacción de todo el cuerpo"},
    {prodId:"49211816",label:"Analizador de carreras"},
    {prodId:"49211817",label:"Instrumento de medición de fuerza muscular"},
    {prodId:"49211818",label:"Balón medicinal"},
    {prodId:"49211819",label:"Sistema de análisis de movimiento"},
    {prodId:"49211820",label:"Dinamómetro de agarre"},
    {prodId:"49211821",label:"Dinamómetro de espalda, piernas y pecho"},
    {prodId:"49211822",label:"Kit de evaluación de músculos de la mano"},
    {prodId:"49211823",label:"Instrumento de medición para el cuerpo humano"},
    {prodId:"49211824",label:"Kit de evaluación de postura"},
    {prodId:"49211825",label:"Dispositivo de prueba de inteligencia"},
    {prodId:"49211826",label:"Equipo de medición de características corporales"},
    {prodId:"49211827",label:"Probador de movimiento de dedos"},
    {prodId:"49211828",label:"Probador de estabilidad de la mano"},
    {prodId:"49211829",label:"Analizador de circulación sanguínea"},
    {prodId:"49211830",label:"Probador de paso lateral"},
    {prodId:"49211831",label:"Probador de abdomen (sentadillas)"},
    {prodId:"49211832",label:"Probador de equilibrio"},
    {prodId:"49211833",label:"Probador de resistencia de corazón y pulmones"},
    {prodId:"49221500",label:"Accesorios para deporte"},
    {prodId:"49221501",label:"Tableros de anotaciones para deportes"},
    {prodId:"49221502",label:"Porterías"},
    {prodId:"49221503",label:"Equipos de seguridad deportiva excepto para la cabeza"},
    {prodId:"49221504",label:"Artículos de seguridad para la cabeza para uso deportivo"},
    {prodId:"49221505",label:"Mallas o redes para deportes"},
    {prodId:"49221506",label:"Almohadillas o acolchado para deportes"},
    {prodId:"49221507",label:"Tableros de basquetbol"},
    {prodId:"49221508",label:"Aros de basquetbol"},
    {prodId:"49221509",label:"Patines o cuchillas de patines"},
    {prodId:"49221510",label:"Gorras deportivas"},
    {prodId:"49221511",label:"Bolsas para equipos deportivos"},
    {prodId:"49221512",label:"Patinetas"},
    {prodId:"49221513",label:"Banca para jugadores"},
    {prodId:"49221514",label:"Asiento o puesto para el árbitro"},
    {prodId:"49221515",label:"Bomba para inflar balones"},
    {prodId:"49221516",label:"Pistola de arranque"},
    {prodId:"49221517",label:"Bloque de arranque"},
    {prodId:"49221518",label:"Postes de mallas"},
    {prodId:"49221519",label:"Espada de esgrima"},
    {prodId:"49221520",label:"Puesto de herramientas atléticas"},
    {prodId:"49221521",label:"Carrito de productos deportivos"},
    {prodId:"49221522",label:"Maniquí de entrenamiento atlético"},
    {prodId:"49221523",label:"Podio de premiación"},
    {prodId:"49221524",label:"Chaqueta para esgrima"},
    {prodId:"49221525",label:"Tubo interior de caucho para atletismo"},
    {prodId:"49221526",label:"Cinturón para levantar pesas"},
    {prodId:"49221527",label:"Espada de bambú"},
    {prodId:"49221528",label:"Tablero trasero de basquetbol"},
    {prodId:"49221529",label:"Máquina de juzgamiento de esgrima"},
    {prodId:"49221530",label:"Temporizador para deportes"},
    {prodId:"49221531",label:"Poste para columpiarse"},
    {prodId:"49221532",label:"Sistema de juegos para patios de recreo"},
    {prodId:"49221533",label:"Tablas flotantes para natación"},
    {prodId:"49241500",label:"Equipo de parques infantiles"},
    {prodId:"49241501",label:"Columpios para patios de recreo"},
    {prodId:"49241502",label:"Aparatos de trepar para patios de recreo"},
    {prodId:"49241503",label:"Carruseles para patios de recreo"},
    {prodId:"49241504",label:"Rodaderos para patios de recreo"},
    {prodId:"49241505",label:"Balanzas para patios de recreo"},
    {prodId:"49241506",label:"Túneles para patios de recreo"},
    {prodId:"49241507",label:"Cajas de arena para patios de recreo"},
    {prodId:"49241508",label:"Graderías para patios de recreo"},
    {prodId:"49241509",label:"Equipo para escalar muros"},
    {prodId:"49241510",label:"Equipos para escalar lazos"},
    {prodId:"49241511",label:"Pérgolas"},
    {prodId:"49241700",label:"Equipo y suministros de piscina y spa"},
    {prodId:"49241701",label:"Trampolines"},
    {prodId:"49241702",label:"Rodaderos de piscina"},
    {prodId:"49241703",label:"Soplador de spa"},
    {prodId:"49241704",label:"Kit o soluciones de prueba de agua"},
    {prodId:"49241705",label:"Limpiador automático de piscinas"},
    {prodId:"49241706",label:"Cobija solar"},
    {prodId:"49241707",label:"Caldera para piscinas o spa"},
    {prodId:"49241708",label:"Generador de ozono"},
    {prodId:"49241709",label:"Rieles para cortinas solares"},
    {prodId:"49241710",label:"Almohada para spa"},
    {prodId:"49241711",label:"Cubiertas para spa"},
    {prodId:"49241712",label:"Químicos para piscinas o piscinas de hidromasaje"},
    {prodId:"49241800",label:"Equipos de sauna"},
    {prodId:"49241801",label:"Caldera para sauna"},
    {prodId:"50101700",label:"Frutos secos"},
    {prodId:"50101716",label:"Nueces y semillas enteras"},
    {prodId:"50101717",label:"Nueces y semillas sin cascara"},
    {prodId:"50111500",label:"Carne y aves de corral"},
    {prodId:"50111513",label:"Carne, mínimamente procesada sin aditivos"},
    {prodId:"50111514",label:"Cerdo, mínimamente procesado sin aditivos"},
    {prodId:"50111515",label:"Pollo, mínimamente procesado sin aditivos"},
    {prodId:"50111516",label:"Carne, mínimamente procesado con aditivos"},
    {prodId:"50111517",label:"Ternera, mínimamente procesado sin aditivos"},
    {prodId:"50111518",label:"Ternera, mínimamente procesado con aditivos"},
    {prodId:"50111519",label:"Cerdo, mínimamente procesado con aditivos"},
    {prodId:"50111520",label:"Pollo, mínimamente procesado con aditivos"},
    {prodId:"50111521",label:"Pavo, mínimamente procesado sin aditivos"},
    {prodId:"50111522",label:"Pavo, mínimamente procesado con aditivos"},
    {prodId:"50111523",label:"Cordero , mínimamente procesado sin aditivos"},
    {prodId:"50111524",label:"Cordero , mínimamente procesado con aditivos"},
    {prodId:"50111525",label:"Chivo, mínimamente procesado sin aditivos"},
    {prodId:"50111526",label:"Chivo, mínimamente procesado con aditivos"},
    {prodId:"50111527",label:"Carne especializada, mínimamente procesada sin aditivos"},
    {prodId:"50111528",label:"Carne especializada , mínimamente procesado con aditivos"},
    {prodId:"50111529",label:"Aves especializadas, mínimamente procesada sin aditivos"},
    {prodId:"50111530",label:"Aves especializadas, mínimamente procesada con aditivos"},
    {prodId:"50111531",label:"Carne especial mixta, mínimamente procesada sin aditivos"},
    {prodId:"50111532",label:"Carne especial mixta, mínimamente procesada con aditivos"},
    {prodId:"50111533",label:"Pato, mínimamente procesada con aditivos"},
    {prodId:"50111534",label:"Pato, mínimamente procesada sin aditivos"},
    {prodId:"50111535",label:"Caballo,  mínimamente procesada sin aditivos"},
    {prodId:"50111536",label:"Caballo, mínimamente procesada con aditivos"},
    {prodId:"50111537",label:"Conejo, mínimamente procesada sin aditivos"},
    {prodId:"50111538",label:"Conejo, , mínimamente procesada con aditivos"},
    {prodId:"50111539",label:"Rana,, mínimamente procesada sin aditivos"},
    {prodId:"50111540",label:"Rana, mínimamente procesada con aditivos"},
    {prodId:"50111541",label:"Caracol de tierra, mínimamente procesada sin aditivos"},
    {prodId:"50111542",label:"Caracol de tierra,  mínimamente procesada con aditivos"},
    {prodId:"50111543",label:"Culebra, mínimamente procesada sin aditivos"},
    {prodId:"50111544",label:"Culebra,  mínimamente procesada con aditivos"},
    {prodId:"50111545",label:"Tortuga, mínimamente procesada sin aditivos"},
    {prodId:"50111546",label:"Tortuga, mínimamente procesada con aditivos"},
    {prodId:"50111547",label:"Bisonte o búfalo, mínimamente procesada sin aditivos"},
    {prodId:"50111548",label:"Bisonte o búfalo, mínimamente procesada con aditivos"},
    {prodId:"50111549",label:"Llama o alpaca, mínimamente procesada sin aditivos"},
    {prodId:"50112000",label:"Carnes procesadas y preparadas"},
    {prodId:"50112004",label:"Carne, procesada sin aditivos"},
    {prodId:"50112005",label:"Carne, procesada con aditivos"},
    {prodId:"50112006",label:"Ternera. procesada sin aditivos"},
    {prodId:"50112007",label:"Ternera, procesada con aditivos"},
    {prodId:"50112008",label:"Cerdo, procesado sin aditivos"},
    {prodId:"50112009",label:"Cerdo, procesado con aditivos"},
    {prodId:"50112010",label:"Pollo, procesado sin aditivos"},
    {prodId:"50112011",label:"Pollo, procesado con aditivos"},
    {prodId:"50112012",label:"Pavo, procesado sin aditivos"},
    {prodId:"50112013",label:"Pavo, procesado con aditivos"},
    {prodId:"50112014",label:"Cordero, procesado sin aditivos"},
    {prodId:"50112015",label:"Cordero, procesado con aditivos"},
    {prodId:"50112016",label:"Chivo. procesado sin aditivos"},
    {prodId:"50112017",label:"Chivo, procesado con aditivos"},
    {prodId:"50112018",label:"Carne especializada, procesada sin aditivo"},
    {prodId:"50112019",label:"Carne especializada, procesada con aditivo"},
    {prodId:"50112020",label:"Aves especializadas, procesada sin aditivos"},
    {prodId:"50112021",label:"Aves especializadas, procesada con aditivos"},
    {prodId:"50112022",label:"Carne especializada mixta, procesada sin aditivos"},
    {prodId:"50112023",label:"Carne especializada mixta, procesada con aditivos"},
    {prodId:"50112024",label:"Pato, procesado con aditivos"},
    {prodId:"50112025",label:"Pato, procesado sin aditivos"},
    {prodId:"50112026",label:"Caballo, procesado sin aditivos"},
    {prodId:"50112027",label:"Caballo, procesado con aditivos"},
    {prodId:"50112028",label:"Conejo, procesado sin aditivos"},
    {prodId:"50112029",label:"Conejo, procesado con aditivos"},
    {prodId:"50112030",label:"Rana, procesado sin aditivos"},
    {prodId:"50112031",label:"Rana, procesado con aditivos"},
    {prodId:"50112032",label:"Caracol de tierra, procesado sin aditivos"},
    {prodId:"50112033",label:"Caracol de tierra, procesado con aditivo"},
    {prodId:"50112034",label:"Culebra, procesado, sin aditivos"},
    {prodId:"50112035",label:"Culebra, procesado con aditivos"},
    {prodId:"50112036",label:"Tortuga, procesada sin aditivos"},
    {prodId:"50112037",label:"Tortuga, procesada con aditivos"},
    {prodId:"50112038",label:"Visón  o búfalo, procesado sin aditivos"},
    {prodId:"50112039",label:"Visón  o búfalo, procesado con aditivos"},
    {prodId:"50112040",label:"Llama o alpaca, procesado sin aditivos"},
    {prodId:"50112041",label:"Llama o alpaca, procesado con aditivos"},
    {prodId:"50112042",label:"Ganso, procesado sin aditivos"},
    {prodId:"50112043",label:"Ganso, procesado con aditivos"},
    {prodId:"50112044",label:"Avestruz, procesado sin aditivos"},
    {prodId:"50112045",label:"Avestruz, procesado con aditivos"},
    {prodId:"50112046",label:"Jabalí, procesado sin aditivos"},
    {prodId:"50112047",label:"Jabalí, procesado con aditivos"},
    {prodId:"50112048",label:"Venado, procesado sin aditivos"},
    {prodId:"50112049",label:"Venado, procesado con aditivos"},
    {prodId:"50112050",label:"Faisán,  procesado sin aditivos"},
    {prodId:"50112051",label:"Faisán, procesado con aditivos"},
    {prodId:"50112052",label:"Codorniz, procesado sin aditivos"},
    {prodId:"50112053",label:"Codorniz, procesado con aditivos"},
    {prodId:"50121500",label:"Pescado"},
    {prodId:"50121537",label:"Pescado congelado"},
    {prodId:"50121538",label:"Pescado almacenado en repisa"},
    {prodId:"50121539",label:"Pescado fresco"},
    {prodId:"50121600",label:"Marisco fresco"},
    {prodId:"50121611",label:"Mariscos frescos"},
    {prodId:"50121612",label:"Mariscos congelados"},
    {prodId:"50121613",label:"Mariscos almacenados en repisa"},
    {prodId:"50121700",label:"Invertebrados acuáticos"},
    {prodId:"50121705",label:"Invertebrados acuáticos frescos"},
    {prodId:"50121706",label:"Invertebrados acuáticos congelados"},
    {prodId:"50121707",label:"Invertebrados acuáticos almacenado en repisa"},
    {prodId:"50121900",label:"Pescados y mariscos preservados en sal"},
    {prodId:"50121901",label:"Pulpo en escabeche"},
    {prodId:"50121902",label:"Huevos de abadejo salado"},
    {prodId:"50121903",label:"Camarón salado"},
    {prodId:"50131600",label:"Huevos y sustitutos"},
    {prodId:"50131609",label:"Huevos preparados"},
    {prodId:"50131612",label:"Huevos en la cascara de gallina"},
    {prodId:"50131613",label:"Huevos en la cascara  de pato"},
    {prodId:"50131614",label:"Huevos en la cascara de emú"},
    {prodId:"50131615",label:"Huevos en la cascara de ganso"},
    {prodId:"50131616",label:"Huevos en la cascara de gallineta"},
    {prodId:"50131617",label:"Huevos en la cascara de avestruz"},
    {prodId:"50131618",label:"Huevos en la cascara codorniz"},
    {prodId:"50131619",label:"Huevos en la cascara de ñandú"},
    {prodId:"50131620",label:"Huevos en la cascara de paloma"},
    {prodId:"50131621",label:"Huevos en la cascara de pavo"},
    {prodId:"50131622",label:"Huevos en la cascara de pájaros especializados"},
    {prodId:"50131623",label:"Huevos con cascara sin clasificación de gallina"},
    {prodId:"50131624",label:"Huevos con cascara sin clasificación de aves que son distintas de gallina"},
    {prodId:"50131625",label:"Huevos industriales revisados y sucios de gallina"},
    {prodId:"50131626",label:"Huevos industriales revisados y sucios que son distintas de gallina"},
    {prodId:"50131627",label:"Huevos cocidos de gallina"},
    {prodId:"50131628",label:"Huevos cocidos distintos de gallina"},
    {prodId:"50131629",label:"Productos derivados de huevos y sustitutos de gallina"},
    {prodId:"50131630",label:"Huevos de gallina deshidratados"},
    {prodId:"50131631",label:"Productos derivados de huevos y sustitutos de aves distinto a gallina"},
    {prodId:"50131632",label:"Huevos distinto a gallina deshidratados"},
    {prodId:"50131633",label:"Extracto de huevo de gallina"},
    {prodId:"50131634",label:"Extracto de huevo de aves distinto a gallina"},
    {prodId:"50131635",label:"Imitación de huevo"},
    {prodId:"50131700",label:"Productos de leche y mantequilla"},
    {prodId:"50131701",label:"Productos de leche o mantequilla frescos"},
    {prodId:"50131702",label:"Productos de leche o mantequilla de estante"},
    {prodId:"50131703",label:"Productos de leche o mantequilla congelados"},
    {prodId:"50131704",label:"Leche en polvo"},
    {prodId:"50131705",label:"Proteína de suero de leche"},
    {prodId:"50131706",label:"Lactosa"},
    {prodId:"50131800",label:"Queso"},
    {prodId:"50131801",label:"Queso natural"},
    {prodId:"50131802",label:"Queso procesado"},
    {prodId:"50131803",label:"Imitación de queso"},
    {prodId:"50151500",label:"Grasas y aceites vegetales comestibles"},
    {prodId:"50151513",label:"Aceites vegetales o  de planta comestibles"},
    {prodId:"50151514",label:"Grasas saturadas de vegetales o plantas comestibles"},
    {prodId:"50151515",label:"Leche de soya"},
    {prodId:"50151600",label:"Grasas y aceites animales comestibles"},
    {prodId:"50151604",label:"Aceites animal comestibles"},
    {prodId:"50151605",label:"Grasa saturada animal comestibles"},
    {prodId:"50161500",label:"Chocolates, azúcares, edulcorantes productos"},
    {prodId:"50161509",label:"Azucares naturales o productos endulzantes"},
    {prodId:"50161510",label:"Endulzantes artificiales"},
    {prodId:"50161511",label:"Chocolate o sustituto de chocolate"},
    {prodId:"50161512",label:"Almíbar"},
    {prodId:"50161800",label:"Productos de confitería"},
    {prodId:"50161813",label:"Chocolate o sustituto de chocolate, confite"},
    {prodId:"50161814",label:"Azúcar o sustituto de azúcar, confite"},
    {prodId:"50161815",label:"Goma de mascar"},
    {prodId:"50161900",label:"Productos de azúcar"},
    {prodId:"50171500",label:"Hierbas y especias y extractos"},
    {prodId:"50171548",label:"Hierbas frescas"},
    {prodId:"50171550",label:"Especies o extractos"},
    {prodId:"50171551",label:"Sal de mesa"},
    {prodId:"50171552",label:"Mezcla para adobar"},
    {prodId:"50171553",label:"Polvo de pimentón rojo"},
    {prodId:"50171554",label:"Tallo de hierbas y semillas para infusiones"},
    {prodId:"50171700",label:"Vinagres y vinos de cocinar"},
    {prodId:"50171707",label:"Vinagres"},
    {prodId:"50171708",label:"Vinos para cocinar"},
    {prodId:"50171800",label:"Salsas y condimentos y productos para untar"},
    {prodId:"50171830",label:"Salsas o condimentos o cremas de untar o marinados"},
    {prodId:"50171831",label:"Salsas para cocinar"},
    {prodId:"50171832",label:"Salsas para ensaladas o dips"},
    {prodId:"50171833",label:"Cremas de untar saladas o patés"},
    {prodId:"50171834",label:"Pasta de frijol chino"},
    {prodId:"50171900",label:"Salmuera y salsa y aceitunas"},
    {prodId:"50171901",label:"Encurtidos"},
    {prodId:"50171902",label:"Condimento"},
    {prodId:"50171904",label:"Conserva"},
    {prodId:"50172000",label:"Productos de fríjol de soya fermentados"},
    {prodId:"50172001",label:"Salsa soya"},
    {prodId:"50172003",label:"Pasta de frijol de soya"},
    {prodId:"50172004",label:"Pasta mixta basada en soya"},
    {prodId:"50172005",label:"Mejoo o frijol de soya fermentado"},
    {prodId:"50172006",label:"Queso de soya"},
    {prodId:"50181700",label:"Suministros y mezclas para hornear"},
    {prodId:"50181708",label:"Mezclas para hornear"},
    {prodId:"50181709",label:"Suministros para hornear"},
    {prodId:"50181900",label:"Pan, galletas y pastelitos dulces"},
    {prodId:"50181901",label:"Pan fresco"},
    {prodId:"50181902",label:"Pan congelado"},
    {prodId:"50181903",label:"Galletas sencillas de sal"},
    {prodId:"50181904",label:"Pan seco o cascaras de pan o pan tostado ( crotones)"},
    {prodId:"50181905",label:"Galletas de dulce"},
    {prodId:"50181906",label:"Pan de repisa"},
    {prodId:"50181907",label:"Maza congelada para galletas"},
    {prodId:"50181909",label:"Galletas de soda"},
    {prodId:"50182000",label:"Tartas y empanadas y pastas"},
    {prodId:"50182001",label:"Ponqués pasteles o biscochos frescos"},
    {prodId:"50182002",label:"Ponqués pasteles o biscochos congelados"},
    {prodId:"50182003",label:"Maza para pastelería congelada"},
    {prodId:"50182004",label:"Maza para galletas de soda congelada"},
    {prodId:"50182005",label:"Galletas de arroz"},
    {prodId:"50191500",label:"Sopas y estofados"},
    {prodId:"50191505",label:"Sopas o sudados preparados fresco"},
    {prodId:"50191506",label:"Sopas o sudados preparados congelados"},
    {prodId:"50191507",label:"Sopas o sudados preparados de repisa"},
    {prodId:"50192100",label:"Botanas"},
    {prodId:"50192109",label:"Papas fritas de talego o mezclas"},
    {prodId:"50192110",label:"Nueces o fruta disecada"},
    {prodId:"50192111",label:"Carne seca o procesada"},
    {prodId:"50192112",label:"Maíz pira"},
    {prodId:"50192113",label:"Mariscos y filete de pescado seco"},
    {prodId:"50192300",label:"Postres y decoraciones de postres"},
    {prodId:"50192301",label:"Postres preparados"},
    {prodId:"50192302",label:"Complementos de postres"},
    {prodId:"50192303",label:"Helado de sabor o helado o postre de helado o yogurt congelado"},
    {prodId:"50192304",label:"Conos o copas de helado comestibles"},
    {prodId:"50192400",label:"Mermeladas y gelatinas y pastas de untar de nuez y dulce y conservas de fruta"},
    {prodId:"50192401",label:"Mermeladas o preservativos de fruta"},
    {prodId:"50192402",label:"Mantequilla de nueces o mixto"},
    {prodId:"50192403",label:"Miel"},
    {prodId:"50192404",label:"Cristales de gelatina o mermelada"},
    {prodId:"50192405",label:"Gelatina real procesada"},
    {prodId:"50192406",label:"Gelatina de almidón de nuez"},
    {prodId:"50192500",label:"Sándwiches y panecillos con relleno"},
    {prodId:"50192501",label:"Emparedados frescos"},
    {prodId:"50192502",label:"Emparedados congelados"},
    {prodId:"50192503",label:"Rellenos frescos para emparedados"},
    {prodId:"50192504",label:"Rellenos congelados para emparedados"},
    {prodId:"50192600",label:"Acompañamientos preparados"},
    {prodId:"50192601",label:"Papas preparadas frescas o arroz o pasta o relleno"},
    {prodId:"50192602",label:"Papas preparadas y congeladas o arroz o pasta o relleno"},
    {prodId:"50192603",label:"Papas preparadas de repisa o arroz o pasta o relleno"},
    {prodId:"50192700",label:"Platos combinados empaquetados"},
    {prodId:"50192701",label:"Comidas combinadas frescas"},
    {prodId:"50192702",label:"Comidas combinadas congeladas"},
    {prodId:"50192703",label:"Comidas combinadas de repisa"},
    {prodId:"50192800",label:"Empanadas y quiches y pasteles"},
    {prodId:"50192801",label:"Pasteles de sal frescos"},
    {prodId:"50192802",label:"Pasteles de sal congelados"},
    {prodId:"50192803",label:"Pasteles de sal de repisa"},
    {prodId:"50192900",label:"Pasta o tallarines natural"},
    {prodId:"50192901",label:"Pasta sencilla o fideos"},
    {prodId:"50192902",label:"Pasta o fideos de repisa"},
    {prodId:"50193000",label:"Bebidas y Comidas Infantiles"},
    {prodId:"50193001",label:"Comida para infante"},
    {prodId:"50193002",label:"Bebidas para infantes"},
    {prodId:"50193100",label:"Materiales y mezclas instantáneas"},
    {prodId:"50193101",label:"Mezcla de botanas instantáneas"},
    {prodId:"50193102",label:"Mezcla  de postres"},
    {prodId:"50193103",label:"Mezcla de salsa"},
    {prodId:"50193104",label:"Base para sopas"},
    {prodId:"50193105",label:"Mezcla para rebosar o de pan"},
    {prodId:"50193106",label:"Puré instantáneo"},
    {prodId:"50193107",label:"Puré de papa instantáneo"},
    {prodId:"50193108",label:"Puré preparado de varios vegetales"},
    {prodId:"50193200",label:"Ensaladas preparadas"},
    {prodId:"50193201",label:"Ensalada fresca preparada"},
    {prodId:"50193202",label:"Ensalada preparada congelada"},
    {prodId:"50193203",label:"Ensalada fresca de repisa"},
    {prodId:"50201700",label:"Café y té"},
    {prodId:"50201706",label:"Café"},
    {prodId:"50201707",label:"Sustituto de café"},
    {prodId:"50201708",label:"Bebida de café"},
    {prodId:"50201709",label:"Café instantáneo"},
    {prodId:"50201710",label:"Té de hoja"},
    {prodId:"50201711",label:"Té instantáneo"},
    {prodId:"50201712",label:"Bebidas de té"},
    {prodId:"50201713",label:"Bolsas de té"},
    {prodId:"50201714",label:"Cremas no lácteas"},
    {prodId:"50201715",label:"Té de frutas"},
    {prodId:"50202200",label:"Bebidas alcohólicas"},
    {prodId:"50202201",label:"Cerveza"},
    {prodId:"50202202",label:"Cidra"},
    {prodId:"50202203",label:"Vino"},
    {prodId:"50202204",label:"Vino fortificado"},
    {prodId:"50202205",label:"Vino espumoso"},
    {prodId:"50202206",label:"Licor destilado"},
    {prodId:"50202207",label:"Cocteles de alcohol o bebidas mixtas"},
    {prodId:"50202208",label:"Takju - vino de arroz coreano"},
    {prodId:"50202209",label:"Soju - bebida destilada de grano"},
    {prodId:"50202210",label:"Vino de arroz"},
    {prodId:"50202300",label:"Bebidas no alcohólicas"},
    {prodId:"50202301",label:"Agua"},
    {prodId:"50202302",label:"Hielo"},
    {prodId:"50202303",label:"Jugos congelados"},
    {prodId:"50202304",label:"Jugos de repisa"},
    {prodId:"50202305",label:"Jugo fresco"},
    {prodId:"50202306",label:"Refrescos"},
    {prodId:"50202307",label:"Bebida de chocolate o malta u otros"},
    {prodId:"50202308",label:"Cocteles libre de alcohol o mezcla de bebidas"},
    {prodId:"50202309",label:"Bebidas deportivas o de energía"},
    {prodId:"50202310",label:"Agua mineral"},
    {prodId:"50202311",label:"Bebida mixta de polvo"},
    {prodId:"50202400",label:"Jugos o concentrados de cítricos frescos"},
    {prodId:"50202401",label:"Jugo de mandarina clementina"},
    {prodId:"50202403",label:"Jugo de kumquat"},
    {prodId:"50202404",label:"Jugo de limón"},
    {prodId:"50202405",label:"Jugo de limón amarillo"},
    {prodId:"50202406",label:"Jugo de lima"},
    {prodId:"50202407",label:"Jugo de mandarina"},
    {prodId:"50202408",label:"Jugo de tangelo minneola"},
    {prodId:"50202409",label:"Jugo de naranja"},
    {prodId:"50202410",label:"Jugo de pomelo"},
    {prodId:"50202411",label:"Jugo de satsuma"},
    {prodId:"50202412",label:"Jugo de tangelo"},
    {prodId:"50202413",label:"Jugo de tangerina"},
    {prodId:"50202414",label:"Jugo de temple"},
    {prodId:"50202415",label:"Jugo de ugli"},
    {prodId:"50202416",label:"Concentrado de mandarina clementina"},
    {prodId:"50202418",label:"Concentrado de kumquat"},
    {prodId:"50202419",label:"Concentrado de limón"},
    {prodId:"50202420",label:"Concentrado de limón amarillo"},
    {prodId:"50202421",label:"Concentrado de mandarina"},
    {prodId:"50202422",label:"Concentrado de minneola"},
    {prodId:"50202423",label:"Concentrado de naranja"},
    {prodId:"50202424",label:"Concentrado de pomelo"},
    {prodId:"50202425",label:"Concentrado de satsuma"},
    {prodId:"50202426",label:"Concentrado de tangelo"},
    {prodId:"50202427",label:"Concentrado de mandarina"},
    {prodId:"50202428",label:"Concentrado de temple"},
    {prodId:"50202429",label:"Concentrado de ugli"},
    {prodId:"50202500",label:"Jugos o concentrados de bayas frescas"},
    {prodId:"50202501",label:"Concentrado de mora"},
    {prodId:"50202502",label:"Concentrado de agras"},
    {prodId:"50202503",label:"Concentrado de arándano"},
    {prodId:"50202504",label:"Concentrado de uva"},
    {prodId:"50202505",label:"Concentrado de frambuesa"},
    {prodId:"50202506",label:"Concentrado de fresa"},
    {prodId:"50202507",label:"Jugo de boysenberry"},
    {prodId:"50202508",label:"Jugo de grosella"},
    {prodId:"50202509",label:"Jugo de mora"},
    {prodId:"50202510",label:"Jugo de agras"},
    {prodId:"50202511",label:"Jugo de frambuesa"},
    {prodId:"50202512",label:"Jugo de fresa"},
    {prodId:"50202513",label:"Jugo de uva"},
    {prodId:"50202600",label:"Jugos o concentrados de especies con pepitas frescas"},
    {prodId:"50202601",label:"Jugo de manzana"},
    {prodId:"50202602",label:"Jugo de pera"},
    {prodId:"50202700",label:"Jugos o concentrados de especies con una semilla grande frescas"},
    {prodId:"50202701",label:"Jugo de albaricoque"},
    {prodId:"50202702",label:"Jugo de cereza"},
    {prodId:"50202703",label:"Jugo de nectarina"},
    {prodId:"50202704",label:"Jugo de durazno"},
    {prodId:"50202705",label:"Jugo de ciruela"},
    {prodId:"50202706",label:"Jugo de ciruela pasa"},
    {prodId:"50202800",label:"Jugos o concentrados de especies tropicales frescas"},
    {prodId:"50202801",label:"Concentrado de crema de coco"},
    {prodId:"50202802",label:"Concentrado de fruta de kiwi"},
    {prodId:"50202803",label:"Concentrado de mango"},
    {prodId:"50202804",label:"Concentrado de maracuyá"},
    {prodId:"50202805",label:"Concentrado de piña"},
    {prodId:"50202806",label:"Concentrado de carambola"},
    {prodId:"50211502",label:"Cigarrillos o cigarros"},
    {prodId:"50211503",label:"Tabaco para pipa o tabaco de hoja"},
    {prodId:"50211504",label:"Tabaco para mascar"},
    {prodId:"50211505",label:"Cigarrillos herbales"},
    {prodId:"50211506",label:"Rapé"},
    {prodId:"50211600",label:"Productos, suministros y accesorios para tabaco"},
    {prodId:"50211607",label:"Papel para cigarrillos o filtros"},
    {prodId:"50211608",label:"Encendedores o mecha"},
    {prodId:"50211609",label:"Pipas para fumar"},
    {prodId:"50211610",label:"Limpiadores de tabaco para pipas"},
    {prodId:"50211611",label:"Juego de fumador"},
    {prodId:"50211612",label:"Encendedores para cigarrillos"},
    {prodId:"50221000",label:"Legumbres"},
    {prodId:"50221001",label:"Granos"},
    {prodId:"50221002",label:"Harina"},
    {prodId:"50221100",label:"Cereales"},
    {prodId:"50221101",label:"Grano de cereal"},
    {prodId:"50221102",label:"Grano de harina"},
    {prodId:"50221200",label:"Cereales procesados"},
    {prodId:"50221201",label:"Cereal caliente o listo para comer"},
    {prodId:"50221202",label:"Barras de desayuno o de salud"},
    {prodId:"50221300",label:"Harina y productos de molinos"},
    {prodId:"50221301",label:"Harina vegetal"},
    {prodId:"50221302",label:"Malta de cebada"},
    {prodId:"50221303",label:"Almidón o harina comestible"},
    {prodId:"50221304",label:"Harina de papa"},
    {prodId:"50301500",label:"Manzanas"},
    {prodId:"50301501",label:"Manzanas akane"},
    {prodId:"50301502",label:"Manzana ambrosia"},
    {prodId:"50301503",label:"Manzanas api"},
    {prodId:"50301504",label:"Manzanas baldwin"},
    {prodId:"50301505",label:"Manzanas brabum"},
    {prodId:"50301506",label:"Manzanas bramley"},
    {prodId:"50301507",label:"Manzana joven bramley"},
    {prodId:"50301508",label:"Manzana calville blanche d'hiver"},
    {prodId:"50301509",label:"Manzana cameo"},
    {prodId:"50301510",label:"Manzana charles ross"},
    {prodId:"50301511",label:"Manzana codlin"},
    {prodId:"50301512",label:"Manzana cortland"},
    {prodId:"50301513",label:"Manzana costard"},
    {prodId:"50301514",label:"Manzana court pendu plat"},
    {prodId:"50301515",label:"Manzana  cox's orange pippin"},
    {prodId:"50301516",label:"Manzana crab"},
    {prodId:"50301517",label:"Manzana crispin"},
    {prodId:"50301518",label:"Manzana delicious"},
    {prodId:"50301519",label:"Manzana duchess"},
    {prodId:"50301520",label:"Manzana earlidorado"},
    {prodId:"50301521",label:"Manzana early mcintosh"},
    {prodId:"50301522",label:"Manzana elstar"},
    {prodId:"50301523",label:"Manzana imperio"},
    {prodId:"50301524",label:"Manzana flor de kent"},
    {prodId:"50301525",label:"Manzana fuji"},
    {prodId:"50301526",label:"Manzana gala"},
    {prodId:"50301527",label:"Manzana gascoyne's scarlet"},
    {prodId:"50301528",label:"Manzana gilliflower"},
    {prodId:"50301529",label:"Manzana ginger dorado"},
    {prodId:"50301530",label:"Manzana gladstone"},
    {prodId:"50301531",label:"Manzana gloster"},
    {prodId:"50301532",label:"Manzana dorado supreme"},
    {prodId:"50301533",label:"Manzana dorado delicious"},
    {prodId:"50301534",label:"Manzana dorado nobel"},
    {prodId:"50301535",label:"Manzana granny smith"},
    {prodId:"50301536",label:"Manzana gravenstain"},
    {prodId:"50301537",label:"Manzana greening"},
    {prodId:"50301538",label:"Manzana greensleeves"},
    {prodId:"50301539",label:"Manzana honeycrisp"},
    {prodId:"50301540",label:"Manzana howgate wonder"},
    {prodId:"50301541",label:"Manzana rojo ida"},
    {prodId:"50301542",label:"Manzana james grives"},
    {prodId:"50301543",label:"Manzana jersey mac"},
    {prodId:"50301544",label:"Manzana jester"},
    {prodId:"50301545",label:"Manzana jonadorado"},
    {prodId:"50301546",label:"Manzana jonamac"},
    {prodId:"50301547",label:"Manzana  jonathan"},
    {prodId:"50301548",label:"Manzana katy"},
    {prodId:"50301549",label:"Manzana kidd's orange rojo"},
    {prodId:"50301550",label:"Manzana dama"},
    {prodId:"50301551",label:"Manzana ley de roma"},
    {prodId:"50301552",label:"Manzana laxton"},
    {prodId:"50301553",label:"Manzana lord derby"},
    {prodId:"50301554",label:"Manzana macoun"},
    {prodId:"50301555",label:"Manzana mcintosh"},
    {prodId:"50301556",label:"Manzana mutsu"},
    {prodId:"50301557",label:"Manzana newtown pippin"},
    {prodId:"50301558",label:"Manzana northen spy"},
    {prodId:"50301559",label:"Manzana orleans reinette"},
    {prodId:"50301560",label:"Manzana ozark dorado"},
    {prodId:"50301561",label:"Manzana pacific rose"},
    {prodId:"50301562",label:"Manzana paula rojo"},
    {prodId:"50301563",label:"Manzana peamain"},
    {prodId:"50301564",label:"Manzana dama rosada"},
    {prodId:"50301565",label:"Manzana pippin"},
    {prodId:"50301566",label:"Manzana pitmaston piña"},
    {prodId:"50301567",label:"Manzana pomme dápi"},
    {prodId:"50301568",label:"Manzana prime dorado"},
    {prodId:"50301569",label:"Manzana rojo astrachan"},
    {prodId:"50301570",label:"Manzana rojo boscoop"},
    {prodId:"50301571",label:"Manzana  jefe rojo"},
    {prodId:"50301572",label:"Manzana rojo delecioso"},
    {prodId:"50301573",label:"Manzana rojo gravenstain"},
    {prodId:"50301574",label:"Manzana roma roja"},
    {prodId:"50301575",label:"Manzana rojo stayman"},
    {prodId:"50301576",label:"Manzana rojo york"},
    {prodId:"50301577",label:"Manzana reinette"},
    {prodId:"50301578",label:"Manzana bellesa de roma"},
    {prodId:"50301579",label:"Manzana russet"},
    {prodId:"50301580",label:"Manzana  belleza sierra"},
    {prodId:"50301581",label:"Manzana spartan"},
    {prodId:"50301582",label:"Manzana stark crimson"},
    {prodId:"50301583",label:"Manzana starrey"},
    {prodId:"50301584",label:"Manzana stayman"},
    {prodId:"50301585",label:"Manzana stayman winesap"},
    {prodId:"50301586",label:"Manzana summer rambo"},
    {prodId:"50301587",label:"Manzana tsugaru"},
    {prodId:"50301588",label:"Manzanaveine onzas"},
    {prodId:"50301589",label:"Manzana tydeman rojo"},
    {prodId:"50301590",label:"Manzana vistabella"},
    {prodId:"50301591",label:"Manzana weatley"},
    {prodId:"50301592",label:"Manzana blanco joanetine"},
    {prodId:"50301593",label:"Manzana blanco transparente"},
    {prodId:"50301594",label:"Manzana winesap"},
    {prodId:"50301595",label:"Manzana worcester"},
    {prodId:"50301596",label:"Manzana york imperial"},
    {prodId:"50301597",label:"Manzana anna"},
    {prodId:"50301700",label:"Bananos"},
    {prodId:"50301701",label:"Banana apple"},
    {prodId:"50301702",label:"Banana bebe"},
    {prodId:"50301703",label:"Banana burro"},
    {prodId:"50301704",label:"Bananas cavendish"},
    {prodId:"50301705",label:"Bananas dominico"},
    {prodId:"50301706",label:"Bananas green"},
    {prodId:"50301707",label:"Bananas gros michel"},
    {prodId:"50301708",label:"Bananas lacatan"},
    {prodId:"50301709",label:"Bananas dedo de dama"},
    {prodId:"50301710",label:"Bananas manzano"},
    {prodId:"50301711",label:"Banana mysore"},
    {prodId:"50301712",label:"Banana pisang mas"},
    {prodId:"50301713",label:"Bananas rojo"},
    {prodId:"50301714",label:"Bananas saba"},
    {prodId:"50301715",label:"Bananas sucrier"},
    {prodId:"50301716",label:"Bananas palillo"},
    {prodId:"50301717",label:"Bananas purple"},
    {prodId:"50301718",label:"Bananas isla"},
    {prodId:"50302000",label:"Moras"},
    {prodId:"50302001",label:"Mora apache"},
    {prodId:"50302002",label:"Mora negro satin"},
    {prodId:"50302003",label:"Mora"},
    {prodId:"50302004",label:"Mora cherokee"},
    {prodId:"50302005",label:"Mora chester"},
    {prodId:"50302006",label:"Mora dirksen"},
    {prodId:"50302007",label:"Bayas josta"},
    {prodId:"50302008",label:"Bayas logan"},
    {prodId:"50302009",label:"Bayas marion"},
    {prodId:"50302010",label:"Mora navaho"},
    {prodId:"50302011",label:"Baya nectar"},
    {prodId:"50302012",label:"Mora olallie"},
    {prodId:"50302013",label:"Baya tay"},
    {prodId:"50302014",label:"Mora thomless hull"},
    {prodId:"50302015",label:"Baya young"},
    {prodId:"50302100",label:"Arándanos de mirto"},
    {prodId:"50302101",label:"Arandanos bog"},
    {prodId:"50302102",label:"Arandanos dwarf"},
    {prodId:"50302103",label:"Arandanos mountain"},
    {prodId:"50302104",label:"Arandanos oval-leaved"},
    {prodId:"50302500",label:"Cerezas"},
    {prodId:"50302501",label:"Cereza amarelle"},
    {prodId:"50302502",label:"Cereza brooks"},
    {prodId:"50302503",label:"Cereza bigarreu"},
    {prodId:"50302504",label:"Cereza bing"},
    {prodId:"50302505",label:"Cereza blach republic"},
    {prodId:"50302506",label:"Cereza negro schmidt"},
    {prodId:"50302507",label:"Cereza negrotartarian"},
    {prodId:"50302508",label:"Cereza fiesta bing"},
    {prodId:"50302509",label:"Cereza garnet"},
    {prodId:"50302510",label:"Cereza rey"},
    {prodId:"50302511",label:"Cereza chapman"},
    {prodId:"50302512",label:"Cereza lapin"},
    {prodId:"50302513",label:"Cereza larian"},
    {prodId:"50302514",label:"Cereza dark guines"},
    {prodId:"50302515",label:"Cereza montmorency"},
    {prodId:"50302516",label:"Cereza duque"},
    {prodId:"50302517",label:"Cereza early rivers"},
    {prodId:"50302518",label:"Cereza ruby bing"},
    {prodId:"50302519",label:"Cereza santine"},
    {prodId:"50302520",label:"Cereza geans/guines"},
    {prodId:"50302521",label:"Cereza sonata"},
    {prodId:"50302522",label:"Cereza lambert"},
    {prodId:"50302523",label:"Cereza stella"},
    {prodId:"50302524",label:"Cereza sweethart"},
    {prodId:"50302525",label:"Cereza tartarian"},
    {prodId:"50302527",label:"Cereza maraschino"},
    {prodId:"50302528",label:"Cereza van"},
    {prodId:"50302529",label:"Cereza morello"},
    {prodId:"50302530",label:"Cereza royal ann"},
    {prodId:"50302531",label:"Cereza ranier"},
    {prodId:"50302532",label:"Cereza royal"},
    {prodId:"50302533",label:"Cereza verde"},
    {prodId:"50302700",label:"Arándanos"},
    {prodId:"50302701",label:"Arandano ben lear"},
    {prodId:"50302702",label:"Arandano early negro"},
    {prodId:"50302703",label:"Arandano gryclesik"},
    {prodId:"50302704",label:"Arandano  howe"},
    {prodId:"50302705",label:"Bayas lingon"},
    {prodId:"50302706",label:"Arandano mcfarling"},
    {prodId:"50302707",label:"Arandano mauntain"},
    {prodId:"50302708",label:"Arandano pilgrim"},
    {prodId:"50302709",label:"Arandano searless"},
    {prodId:"50302710",label:"Arandano stevens"},
    {prodId:"50302900",label:"Dátiles"},
    {prodId:"50302901",label:"Dátiles asharasi"},
    {prodId:"50302902",label:"Dátiles barhi o barhee"},
    {prodId:"50302903",label:"Dátiles deglet noor"},
    {prodId:"50302904",label:"Dátiles fardh"},
    {prodId:"50302905",label:"Dátiles gundila"},
    {prodId:"50302906",label:"Dátiles halawi/halawy"},
    {prodId:"50302907",label:"Dátiles hilali"},
    {prodId:"50302908",label:"Dátiles khadrawi/khadrawy"},
    {prodId:"50302909",label:"Dátiles khalas"},
    {prodId:"50302910",label:"Dátiles khustawi"},
    {prodId:"50302911",label:"Dátiles khidri"},
    {prodId:"50302912",label:"Dátiles medjool/ medjol"},
    {prodId:"50302913",label:"Dátiles mactoum"},
    {prodId:"50302914",label:"Dátiles neghal"},
    {prodId:"50302915",label:"Dátiles yatimeh"},
    {prodId:"50302916",label:"Dátiles zahidi"},
    {prodId:"50303000",label:"Pitahayas"},
    {prodId:"50303001",label:"Pitahaya roja, rosada"},
    {prodId:"50303002",label:"Pitahaya roja, amarillo"},
    {prodId:"50303100",label:"Brevas"},
    {prodId:"50303101",label:"Breva bardajic"},
    {prodId:"50303102",label:"Breva brown turkey"},
    {prodId:"50303103",label:"Breva calimyma"},
    {prodId:"50303104",label:"Breva conadria"},
    {prodId:"50303105",label:"Breva dottado"},
    {prodId:"50303106",label:"Breva kadota"},
    {prodId:"50303107",label:"Breva mediterránea"},
    {prodId:"50303108",label:"Breva mision"},
    {prodId:"50303109",label:"Breva amyma"},
    {prodId:"50303110",label:"Breva verdona"},
    {prodId:"50303111",label:"Breva rey blanco"},
    {prodId:"50303300",label:"Toronjas"},
    {prodId:"50303301",label:"Toronja burgundi"},
    {prodId:"50303302",label:"Toronja duncan"},
    {prodId:"50303303",label:"Toronja foster"},
    {prodId:"50303304",label:"Toronja marsh"},
    {prodId:"50303305",label:"Toronja nueva zelandia"},
    {prodId:"50303306",label:"Toronja rio rojo"},
    {prodId:"50303307",label:"Toronja ruby rojo"},
    {prodId:"50303308",label:"Toronja star ruby"},
    {prodId:"50303309",label:"Toronja triumph"},
    {prodId:"50303400",label:"Uvas de mesa"},
    {prodId:"50303401",label:"Uva alicante"},
    {prodId:"50303402",label:"Uva almeira"},
    {prodId:"50303403",label:"Uva alphonse lavalle"},
    {prodId:"50303404",label:"Uva otoño rey grapes"},
    {prodId:"50303405",label:"Uva otoño royal"},
    {prodId:"50303406",label:"Uva otoño sin pepa"},
    {prodId:"50303407",label:"Uva baresana"},
    {prodId:"50303408",label:"Uva barlinka"},
    {prodId:"50303409",label:"Uva beaty sin pepa"},
    {prodId:"50303410",label:"Uva negro beauty sin pepa"},
    {prodId:"50303411",label:"Uva negro emerald"},
    {prodId:"50303412",label:"Uva negro gigante"},
    {prodId:"50303413",label:"Uva negro globo"},
    {prodId:"50303414",label:"Uva negro monnukka"},
    {prodId:"50303415",label:"Uva negro perla"},
    {prodId:"50303416",label:"Uva negro sin semilla"},
    {prodId:"50303417",label:"Uva bonheur"},
    {prodId:"50303418",label:"Uva calmeria"},
    {prodId:"50303419",label:"Uva cardinal"},
    {prodId:"50303420",label:"Uva catawba"},
    {prodId:"50303421",label:"Uva chasselas/doradoen chasselas"},
    {prodId:"50303422",label:"Uva christmas rose"},
    {prodId:"50303423",label:"Uva concord"},
    {prodId:"50303424",label:"Uva concord sin pepa"},
    {prodId:"50303425",label:"Uva crimson sin pepa"},
    {prodId:"50303426",label:"Uva dauphine"},
    {prodId:"50303427",label:"Uva daleware"},
    {prodId:"50303428",label:"Uva early muscat"},
    {prodId:"50303429",label:"Uva early sweet"},
    {prodId:"50303430",label:"Uva esmerald sin pepa"},
    {prodId:"50303431",label:"Uva emperatriz"},
    {prodId:"50303432",label:"Uva emperor"},
    {prodId:"50303433",label:"Uva empress"},
    {prodId:"50303434",label:"Uva exotic"},
    {prodId:"50303435",label:"Uva fantasia"},
    {prodId:"50303436",label:"Uva fantasy sin pepa"},
    {prodId:"50303437",label:"Uva llama"},
    {prodId:"50303438",label:"Uva llama sin pepa"},
    {prodId:"50303439",label:"Uva llama tokay"},
    {prodId:"50303440",label:"Uva flaming rojo"},
    {prodId:"50303441",label:"Uva galaxy sin pepa"},
    {prodId:"50303442",label:"Uva gamay"},
    {prodId:"50303443",label:"Uva dorada"},
    {prodId:"50303444",label:"Uva hanepot o honeypot"},
    {prodId:"50303445",label:"Uva italia"},
    {prodId:"50303446",label:"Uva jade sin pepa"},
    {prodId:"50303447",label:"Uva jubilee"},
    {prodId:"50303448",label:"Uva rey ruby"},
    {prodId:"50303449",label:"Uva kyoho"},
    {prodId:"50303450",label:"Uva la rochelle"},
    {prodId:"50303451",label:"Uva dedo de dama"},
    {prodId:"50303452",label:"Uva late sin pepa"},
    {prodId:"50303453",label:"Uva majestic sin pepa"},
    {prodId:"50303454",label:"Uva malaga"},
    {prodId:"50303455",label:"Uva marro sin pepa"},
    {prodId:"50303456",label:"Uva muscadine"},
    {prodId:"50303457",label:"Uva muscat llama"},
    {prodId:"50303458",label:"Uva muscat"},
    {prodId:"50303459",label:"Uva muscat sin semilla"},
    {prodId:"50303460",label:"Uva napoleon"},
    {prodId:"50303461",label:"Uva nigeria"},
    {prodId:"50303462",label:"Uva new cross"},
    {prodId:"50303463",label:"Uva nibella"},
    {prodId:"50303464",label:"Uva niagara"},
    {prodId:"50303465",label:"Uva olivette"},
    {prodId:"50303466",label:"Uva perlette"},
    {prodId:"50303467",label:"Uva perlon"},
    {prodId:"50303468",label:"Uva prima negro sin pepa"},
    {prodId:"50303469",label:"Uva princess"},
    {prodId:"50303470",label:"Uva reina"},
    {prodId:"50303471",label:"Uva roja blush"},
    {prodId:"50303472",label:"Uva roja globe"},
    {prodId:"50303473",label:"Uva roja malaga"},
    {prodId:"50303474",label:"Uva roja sin pepa"},
    {prodId:"50303475",label:"Uva regina"},
    {prodId:"50303476",label:"Uva ribier"},
    {prodId:"50303477",label:"Uva rosita"},
    {prodId:"50303478",label:"Uva rouge"},
    {prodId:"50303479",label:"Uva royal negra sin pepa"},
    {prodId:"50303480",label:"Uva ruby roja sin pepa"},
    {prodId:"50303481",label:"Uva ryby sin pepa"},
    {prodId:"50303482",label:"Uva scarlet royal"},
    {prodId:"50303483",label:"Uva scuppemong"},
    {prodId:"50303484",label:"Uva sugarose"},
    {prodId:"50303485",label:"Uva sugartirteen"},
    {prodId:"50303486",label:"Uva sugraone"},
    {prodId:"50303487",label:"Uva sugrasixteen"},
    {prodId:"50303488",label:"Uva sultana sun roja"},
    {prodId:"50303489",label:"Uva verano royal"},
    {prodId:"50303490",label:"Uva sunset"},
    {prodId:"50303491",label:"Uva superior sin semilla"},
    {prodId:"50303492",label:"Uva thompson sin semilla"},
    {prodId:"50303493",label:"Uva tokaylpinot gris"},
    {prodId:"50303494",label:"Uva waltman cross"},
    {prodId:"50303495",label:"Uva blanca sin pepa"},
    {prodId:"50303496",label:"Uva zante current"},
    {prodId:"50303497",label:"Uva quebranta"},
    {prodId:"50303498",label:"Uva burgundi"},
    {prodId:"50303499",label:"Uva torontel"},
    {prodId:"50303500",label:"Uvas pasas"},
    {prodId:"50303501",label:"Uva corinth negra"},
    {prodId:"50303502",label:"Uva canner"},
    {prodId:"50303503",label:"Uva dovine"},
    {prodId:"50303504",label:"Uva fiesta"},
    {prodId:"50303505",label:"Uva selme pete"},
    {prodId:"50303506",label:"Uva sultana"},
    {prodId:"50303700",label:"Guayabas"},
    {prodId:"50303701",label:"Guayaba beaumont"},
    {prodId:"50303702",label:"Guayaba carrley"},
    {prodId:"50303703",label:"Guayaba lucida"},
    {prodId:"50303704",label:"Guayaba piña"},
    {prodId:"50303800",label:"Arándanos Huckleberry"},
    {prodId:"50303801",label:"Arándano negro invierno"},
    {prodId:"50303802",label:"Arándano cascade"},
    {prodId:"50303803",label:"Arándano enano"},
    {prodId:"50303804",label:"Arándano montaña"},
    {prodId:"50303805",label:"Arándano rojo"},
    {prodId:"50303900",label:"Kiwis"},
    {prodId:"50303901",label:"Fruta de kiwi ananasnaja"},
    {prodId:"50303902",label:"Fruta de kiwi belleza del ártico"},
    {prodId:"50303903",label:"Fruta de kiwi blake"},
    {prodId:"50303904",label:"Fruta de kiwi hayward"},
    {prodId:"50303905",label:"Fruta de kiwi issai"},
    {prodId:"50303906",label:"Fruta de kiwi siberiana"},
    {prodId:"50304100",label:"Limones"},
    {prodId:"50304101",label:"Limón baboon"},
    {prodId:"50304102",label:"Limón bearss sicilian"},
    {prodId:"50304103",label:"Limón cameron highland"},
    {prodId:"50304104",label:"Limón escondido"},
    {prodId:"50304105",label:"Limón eureka"},
    {prodId:"50304106",label:"Limón lisbon"},
    {prodId:"50304107",label:"Limón meyer"},
    {prodId:"50304108",label:"Limón volkaner"},
    {prodId:"50304200",label:"Limas"},
    {prodId:"50304201",label:"Lima limón dulce indio"},
    {prodId:"50304202",label:"Lima limón"},
    {prodId:"50304203",label:"Lima limón mandarina"},
    {prodId:"50304204",label:"Lima limón filipino"},
    {prodId:"50304205",label:"Lima limón tahitian"},
    {prodId:"50304206",label:"Lima limón bearss"},
    {prodId:"50304207",label:"Lima limón persa"},
    {prodId:"50304208",label:"Lima limón sin pepas"},
    {prodId:"50304400",label:"Mandarinas"},
    {prodId:"50304401",label:"Naranjas clauselinas"},
    {prodId:"50304402",label:"Mandarinas clementinas"},
    {prodId:"50304403",label:"Naranja mandarina cleopatra"},
    {prodId:"50304404",label:"Mandarina dancy"},
    {prodId:"50304405",label:"Naranja ellensday"},
    {prodId:"50304406",label:"Naranja fairchild"},
    {prodId:"50304407",label:"Naranja fallglo"},
    {prodId:"50304408",label:"Naranja fortune"},
    {prodId:"50304409",label:"Mandarina naranja fremont"},
    {prodId:"50304410",label:"Naranja fremont"},
    {prodId:"50304411",label:"Naranja doradoen nugget"},
    {prodId:"50304412",label:"Mandarina naranja miel"},
    {prodId:"50304413",label:"Naranja miel"},
    {prodId:"50304414",label:"Mandarina miel"},
    {prodId:"50304415",label:"Naranja tanjelo honeybelle"},
    {prodId:"50304416",label:"Naranja mandarina rey"},
    {prodId:"50304417",label:"Naranja kinnow"},
    {prodId:"50304418",label:"Naranja andarinalee"},
    {prodId:"50304419",label:"Naranja makokkee"},
    {prodId:"50304420",label:"Naranja malvasion"},
    {prodId:"50304421",label:"Naranja mandarina mediterráneo"},
    {prodId:"50304422",label:"Naranja tangelo minneola"},
    {prodId:"50304423",label:"Naranja monica"},
    {prodId:"50304424",label:"Naranja murcott honey"},
    {prodId:"50304425",label:"Naranja murcott tangors"},
    {prodId:"50304426",label:"Naranja mandarina natsudaidai"},
    {prodId:"50304427",label:"Naranja mandarina natsumikan"},
    {prodId:"50304428",label:"Naranja tanjelo nocatee"},
    {prodId:"50304429",label:"Naranja tanjelo orlando"},
    {prodId:"50304430",label:"Mandarina ortanique"},
    {prodId:"50304431",label:"Naranja mandarina page"},
    {prodId:"50304432",label:"Naranja pixie"},
    {prodId:"50304433",label:"Naranja mandarina ponkan bantangas"},
    {prodId:"50304434",label:"Naranja reyna"},
    {prodId:"50304435",label:"Naranja robinson"},
    {prodId:"50304436",label:"Naranja saltenitas"},
    {prodId:"50304437",label:"Naranja tangelo sampson"},
    {prodId:"50304438",label:"Naranja mandarina satsuma"},
    {prodId:"50304439",label:"Naranja mandarina sunburst"},
    {prodId:"50304440",label:"Tangelo"},
    {prodId:"50304441",label:"Naranja mandarina"},
    {prodId:"50304442",label:"Naranja templo"},
    {prodId:"50304443",label:"Naranja thornton"},
    {prodId:"50304444",label:"Mandarina wekiwa"},
    {prodId:"50304445",label:"Mandarina wilkins"},
    {prodId:"50304446",label:"Mandarina willowleaf mediterranea"},
    {prodId:"50304500",label:"Mangos"},
    {prodId:"50304501",label:"Mango alphonso"},
    {prodId:"50304502",label:"Mango ataulfo"},
    {prodId:"50304503",label:"Mango criollo"},
    {prodId:"50304504",label:"Mango edwards"},
    {prodId:"50304505",label:"Mango francine"},
    {prodId:"50304506",label:"Mango francis"},
    {prodId:"50304507",label:"Mango gandaria"},
    {prodId:"50304508",label:"Mango haden"},
    {prodId:"50304509",label:"Mangos irwin"},
    {prodId:"50304510",label:"Mangos keitt"},
    {prodId:"50304511",label:"Mangos kent"},
    {prodId:"50304512",label:"Mangos kesar"},
    {prodId:"50304513",label:"Mangos kuini"},
    {prodId:"50304514",label:"Mangos manila super"},
    {prodId:"50304515",label:"Mangos manila"},
    {prodId:"50304516",label:"Mangos mayaguez"},
    {prodId:"50304517",label:"Mangos mulgoba"},
    {prodId:"50304518",label:"Mangos oro"},
    {prodId:"50304519",label:"Mangos palmer"},
    {prodId:"50304520",label:"Mango parvin"},
    {prodId:"50304521",label:"Mango sandersha"},
    {prodId:"50304522",label:"Mangos sensation"},
    {prodId:"50304523",label:"Mango smith"},
    {prodId:"50304524",label:"Mango tomy atkin"},
    {prodId:"50304525",label:"Mango van dyke"},
    {prodId:"50304600",label:"Melones"},
    {prodId:"50304601",label:"Melon allsweet"},
    {prodId:"50304602",label:"Melón athens"},
    {prodId:"50304603",label:"Melón diamante negro"},
    {prodId:"50304604",label:"Melón cal dulce"},
    {prodId:"50304605",label:"Melons cantaloupe"},
    {prodId:"50304606",label:"Melón carnical"},
    {prodId:"50304607",label:"Melón casaba"},
    {prodId:"50304608",label:"Melón cavaillon"},
    {prodId:"50304609",label:"Melón charentais"},
    {prodId:"50304610",label:"Sandia charleston gray"},
    {prodId:"50304611",label:"Melón crenshaw"},
    {prodId:"50304612",label:"Melón crimson sweet"},
    {prodId:"50304613",label:"Melón dixie lee"},
    {prodId:"50304614",label:"Melón eclipse"},
    {prodId:"50304615",label:"Melón ein dór"},
    {prodId:"50304616",label:"Melón fiesta"},
    {prodId:"50304617",label:"Melón galia"},
    {prodId:"50304618",label:"Melón gaya"},
    {prodId:"50304619",label:"Melón hami"},
    {prodId:"50304620",label:"Melón honey dew"},
    {prodId:"50304621",label:"Melón icebox"},
    {prodId:"50304622",label:"Melón ida pride"},
    {prodId:"50304623",label:"Melón juan canary"},
    {prodId:"50304624",label:"Melón jubileo"},
    {prodId:"50304625",label:"Melón jubilation"},
    {prodId:"50304626",label:"Melón kaki / kakri"},
    {prodId:"50304627",label:"Melón kiwano"},
    {prodId:"50304628",label:"Melon koreano"},
    {prodId:"50304629",label:"Melón long grey"},
    {prodId:"50304630",label:"Melón mayan"},
    {prodId:"50304631",label:"Melón micky lee"},
    {prodId:"50304632",label:"Melón mirage"},
    {prodId:"50304633",label:"Sandia luna y estrellas"},
    {prodId:"50304634",label:"Melón ogen"},
    {prodId:"50304635",label:"Melón patriot"},
    {prodId:"50304636",label:"Melón peacook"},
    {prodId:"50304637",label:"Melón pepino"},
    {prodId:"50304638",label:"Melón persa"},
    {prodId:"50304639",label:"Melón picnic"},
    {prodId:"50304640",label:"Melón piel de sapo"},
    {prodId:"50304641",label:"Melón piña"},
    {prodId:"50304642",label:"Melón quetzali"},
    {prodId:"50304643",label:"Melón goblin rojo"},
    {prodId:"50304644",label:"Melón regency"},
    {prodId:"50304645",label:"Melón royal mejestic"},
    {prodId:"50304646",label:"Melón estrella royal"},
    {prodId:"50304647",label:"Melón royal dulce"},
    {prodId:"50304648",label:"Malon santa claus"},
    {prodId:"50304649",label:"Melón sharlyn"},
    {prodId:"50304650",label:"Melón español"},
    {prodId:"50304651",label:"Melón sprite"},
    {prodId:"50304652",label:"Melón starbright"},
    {prodId:"50304653",label:"Melón stars and stripes"},
    {prodId:"50304654",label:"Melón sugar bebe"},
    {prodId:"50304655",label:"Sandia sugar bebe"},
    {prodId:"50304656",label:"Melón sunsweet"},
    {prodId:"50304657",label:"Sandia sweet heart sin semilla"},
    {prodId:"50304658",label:"Melón tentación"},
    {prodId:"50304659",label:"Melón tiger bebe"},
    {prodId:"50304660",label:"Melón tuscan type"},
    {prodId:"50304661",label:"Sandia bebe amarillo"},
    {prodId:"50304900",label:"Nectarinas"},
    {prodId:"50304901",label:"Durazno april glo"},
    {prodId:"50304902",label:"Durazno arctic mist"},
    {prodId:"50304903",label:"Durazno artic snow"},
    {prodId:"50304904",label:"Durazno artic star"},
    {prodId:"50304905",label:"Durazno artic sweet"},
    {prodId:"50304906",label:"Durazno artic glo"},
    {prodId:"50304907",label:"Durazno august fire"},
    {prodId:"50304908",label:"Durazno  august pearl"},
    {prodId:"50304909",label:"Durazno august rojo"},
    {prodId:"50304910",label:"Durazno august estrella"},
    {prodId:"50304911",label:"Durazno big john"},
    {prodId:"50304912",label:"Durazno bright pearl"},
    {prodId:"50304913",label:"Durazno diamante bright"},
    {prodId:"50304914",label:"Durazno diamante ray"},
    {prodId:"50304915",label:"Durazno earligo"},
    {prodId:"50304916",label:"Durazno diamante temprano"},
    {prodId:"50304917",label:"Durazno fairlane"},
    {prodId:"50304918",label:"Durazno fantasia"},
    {prodId:"50304919",label:"Durazno perla fuego"},
    {prodId:"50304920",label:"Durazno fuego dulce"},
    {prodId:"50304921",label:"Durazno llamakist"},
    {prodId:"50304922",label:"Durazno tipo plano"},
    {prodId:"50304923",label:"Durazno delicia de jardín"},
    {prodId:"50304924",label:"Durazno mina de oro"},
    {prodId:"50304925",label:"Durazno perla grande"},
    {prodId:"50304926",label:"Durazno hardirojo"},
    {prodId:"50304927",label:"Durazno miel de fuego"},
    {prodId:"50304928",label:"Durazno julio rojo"},
    {prodId:"50304929",label:"Durazno kay perla"},
    {prodId:"50304930",label:"Durazno key dulce"},
    {prodId:"50304931",label:"Durazno diamante mayo"},
    {prodId:"50304932",label:"Durazno mayfire"},
    {prodId:"50304933",label:"Durazno mayglo"},
    {prodId:"50304934",label:"Durazno mericrest"},
    {prodId:"50304935",label:"Durazno diamante rojo"},
    {prodId:"50304936",label:"Durazno oro rojo"},
    {prodId:"50304937",label:"Durazno jim rojo"},
    {prodId:"50304938",label:"Durazno roy rojo"},
    {prodId:"50304939",label:"Durazno rio rojo"},
    {prodId:"50304940",label:"Durazno diamante de rosa"},
    {prodId:"50304941",label:"Durazno royal glo"},
    {prodId:"50304942",label:"Durazno diamante ryby"},
    {prodId:"50304943",label:"Durazno ruby dulce"},
    {prodId:"50304944",label:"Durazno joya ruddy"},
    {prodId:"50304945",label:"Durazno septiembre rojo"},
    {prodId:"50304946",label:"Durazno reina de nieve"},
    {prodId:"50304947",label:"Durazno primavera clara"},
    {prodId:"50304948",label:"Durazno primavera roja"},
    {prodId:"50304949",label:"Durazno verano rojiso"},
    {prodId:"50304950",label:"Durazno verano claro"},
    {prodId:"50304951",label:"Durazno verano diamante"},
    {prodId:"50304952",label:"Durazno verano fuego"},
    {prodId:"50304953",label:"Durazno verano grande"},
    {prodId:"50304954",label:"Durazno sunglo"},
    {prodId:"50304955",label:"Durazno fuego zee"},
    {prodId:"50304956",label:"Durazno zee glo"},
    {prodId:"50304957",label:"Durazno zeegrand"},
    {prodId:"50305000",label:"Naranjas"},
    {prodId:"50305001",label:"Naranja áfrica agria"},
    {prodId:"50305002",label:"Naranja dulce amber"},
    {prodId:"50305003",label:"Naranja argentina agria"},
    {prodId:"50305004",label:"Naranja bahianinha"},
    {prodId:"50305005",label:"Naranja bergamot"},
    {prodId:"50305006",label:"Naranja berna"},
    {prodId:"50305007",label:"Naranja bigaradier apepu"},
    {prodId:"50305008",label:"Naranja agria dulce daidai"},
    {prodId:"50305009",label:"Naranja blonde"},
    {prodId:"50305010",label:"Naranja sangr"},
    {prodId:"50305011",label:"Naranja california navel"},
    {prodId:"50305012",label:"Naranja cara cara"},
    {prodId:"50305013",label:"Naranja chinotto"},
    {prodId:"50305014",label:"Naranja sueño nevel"},
    {prodId:"50305015",label:"Naranja gou tou"},
    {prodId:"50305016",label:"Naranja hamlin"},
    {prodId:"50305017",label:"Naranja jaffa"},
    {prodId:"50305018",label:"Naranja jincheng"},
    {prodId:"50305019",label:"Naranja k-temprano"},
    {prodId:"50305020",label:"Naranja kona"},
    {prodId:"50305021",label:"Naranja navel tarde"},
    {prodId:"50305022",label:"Naranja valencia tarde"},
    {prodId:"50305023",label:"Naranja limequat"},
    {prodId:"50305024",label:"Naranja mar"},
    {prodId:"50305025",label:"Naranja maledorado"},
    {prodId:"50305026",label:"Naranja moro"},
    {prodId:"50305027",label:"Naranja moro tabacco"},
    {prodId:"50305028",label:"Naranja navel"},
    {prodId:"50305029",label:"Naranja navelina"},
    {prodId:"50305030",label:"Naranja oro blanco"},
    {prodId:"50305031",label:"Naranja osceola"},
    {prodId:"50305032",label:"Naranja parson carmelito"},
    {prodId:"50305033",label:"Naranja pera"},
    {prodId:"50305034",label:"Naranja pummulo"},
    {prodId:"50305035",label:"Naranja rhode rojo"},
    {prodId:"50305036",label:"Naranja roble"},
    {prodId:"50305037",label:"Naranja salustianas"},
    {prodId:"50305038",label:"Naranja sanguine"},
    {prodId:"50305039",label:"Naranja sanguinelli"},
    {prodId:"50305040",label:"Naranja cevilla"},
    {prodId:"50305041",label:"Naranja shamouti jaffa"},
    {prodId:"50305042",label:"Naranja tunis"},
    {prodId:"50305043",label:"Naranja valencia"},
    {prodId:"50305044",label:"Naranja washington navel"},
    {prodId:"50305100",label:"Papayas"},
    {prodId:"50305101",label:"Papaya verde cocinar"},
    {prodId:"50305102",label:"Papaya maradol"},
    {prodId:"50305103",label:"Papaya amarillo mexicano"},
    {prodId:"50305104",label:"Papaya montaña"},
    {prodId:"50305105",label:"Papaya solo"},
    {prodId:"50305106",label:"Papaya tainung"},
    {prodId:"50305300",label:"Duraznos"},
    {prodId:"50305301",label:"Durazno escudo amber"},
    {prodId:"50305302",label:"Durazno nieve de abril"},
    {prodId:"50305303",label:"Durazno dama de agosto"},
    {prodId:"50305304",label:"Durazno llama de otoño"},
    {prodId:"50305305",label:"Durazno dama de otoño"},
    {prodId:"50305306",label:"Durazno babcock"},
    {prodId:"50305307",label:"Durazno brittany lane"},
    {prodId:"50305308",label:"Durazno cary mac"},
    {prodId:"50305309",label:"Durazno clasic"},
    {prodId:"50305310",label:"Durazno dulce del campo"},
    {prodId:"50305311",label:"Durazno escudo de cielo"},
    {prodId:"50305312",label:"Durazno dama crimson"},
    {prodId:"50305313",label:"Durazno príncipe corona"},
    {prodId:"50305314",label:"Durazno sol david"},
    {prodId:"50305315",label:"Durazno  princesa diamante"},
    {prodId:"50305316",label:"Durazno earlrich"},
    {prodId:"50305317",label:"Durazno majestuosa temprana"},
    {prodId:"50305318",label:"Durazno early treat"},
    {prodId:"50305319",label:"Durazno dama elegante"},
    {prodId:"50305320",label:"Durazno emperatriz"},
    {prodId:"50305321",label:"Durazno encoré"},
    {prodId:"50305322",label:"Durazno dama fancy"},
    {prodId:"50305323",label:"Durazno príncipe de fuego"},
    {prodId:"50305324",label:"Durazno escudo de llama"},
    {prodId:"50305325",label:"Durazno tipo plano"},
    {prodId:"50305326",label:"Durazno escudo de sabor"},
    {prodId:"50305327",label:"Durazno príncipe florida"},
    {prodId:"50305328",label:"Durazno luna llena"},
    {prodId:"50305329",label:"Durazno harvester"},
    {prodId:"50305330",label:"Durazno princesa de hielo"},
    {prodId:"50305331",label:"Durazno princesa de marfil"},
    {prodId:"50305332",label:"Durazno princesa reina jersey"},
    {prodId:"50305333",label:"Durazno john henry"},
    {prodId:"50305334",label:"Durazno príncipe de junio"},
    {prodId:"50305335",label:"Durazno kaweah"},
    {prodId:"50305336",label:"Durazno klondike"},
    {prodId:"50305337",label:"Durazno lindo"},
    {prodId:"50305338",label:"Durazno loring"},
    {prodId:"50305339",label:"Durazno majestuoso"},
    {prodId:"50305340",label:"Durazno o'henry"},
    {prodId:"50305341",label:"Durazno escudo de reina"},
    {prodId:"50305342",label:"Durazno dama roja"},
    {prodId:"50305343",label:"Durazno globo rojo"},
    {prodId:"50305344",label:"Durazno cielo rojo"},
    {prodId:"50305345",label:"Durazno redtop"},
    {prodId:"50305346",label:"Durazno regina"},
    {prodId:"50305347",label:"Durazno dama rica"},
    {prodId:"50305348",label:"Durazno mayo rico"},
    {prodId:"50305349",label:"Durazno gloria real"},
    {prodId:"50305350",label:"Durazno dama real"},
    {prodId:"50305351",label:"Durazno nieve de septiembre"},
    {prodId:"50305352",label:"Durazno sol de septiembre"},
    {prodId:"50305353",label:"Durazno gema sierra"},
    {prodId:"50305354",label:"Durazno angel de nieve"},
    {prodId:"50305355",label:"Durazno gema de nieve"},
    {prodId:"50305356",label:"Durazno rey de nieve"},
    {prodId:"50305357",label:"Durazno dama de primavera"},
    {prodId:"50305358",label:"Durazno nieve de primavera"},
    {prodId:"50305359",label:"Durazno escudo de primavera"},
    {prodId:"50305360",label:"Durazno dulce gigante"},
    {prodId:"50305361",label:"Durazno dama de azúcar"},
    {prodId:"50305362",label:"Durazno brillo de sol"},
    {prodId:"50305363",label:"Durazno sunhigh"},
    {prodId:"50305364",label:"Durazno dama super"},
    {prodId:"50305365",label:"Durazno super rico"},
    {prodId:"50305366",label:"Durazno surecrop"},
    {prodId:"50305367",label:"Durazno sueño duñce"},
    {prodId:"50305368",label:"Durazno septiembre dulce"},
    {prodId:"50305369",label:"Durazno vista"},
    {prodId:"50305370",label:"Durazno dama blanca"},
    {prodId:"50305371",label:"Durazno dama zee"},
    {prodId:"50305400",label:"Peras"},
    {prodId:"50305401",label:"Peras abate fetel"},
    {prodId:"50305402",label:"Peras anjou"},
    {prodId:"50305403",label:"Pera asiatica"},
    {prodId:"50305404",label:"Pera bartlett"},
    {prodId:"50305405",label:"Pera best ever"},
    {prodId:"50305406",label:"Pera beth"},
    {prodId:"50305407",label:"Pera beurre"},
    {prodId:"50305408",label:"Pera bosc"},
    {prodId:"50305409",label:"Pera clapp favorita"},
    {prodId:"50305410",label:"Pera comice"},
    {prodId:"50305411",label:"Pera concorde"},
    {prodId:"50305412",label:"Pera conference"},
    {prodId:"50305413",label:"Pera crimson rojo"},
    {prodId:"50305414",label:"Peras d' anjou"},
    {prodId:"50305415",label:"Pera dr jules guyot"},
    {prodId:"50305416",label:"Peras early"},
    {prodId:"50305417",label:"Peras emperador carmelito"},
    {prodId:"50305418",label:"Peras forelle"},
    {prodId:"50305419",label:"Pera mantequilla francesa"},
    {prodId:"50305420",label:"Pera glou morceau"},
    {prodId:"50305421",label:"Pera hosui"},
    {prodId:"50305422",label:"Pera mantequilla italiana"},
    {prodId:"50305423",label:"Pera jargonelle"},
    {prodId:"50305424",label:"Pera juno"},
    {prodId:"50305425",label:"Para kaiserlouise bonne de jersey"},
    {prodId:"50305426",label:"Pera keiffer"},
    {prodId:"50305427",label:"Pera rey royal"},
    {prodId:"50305428",label:"Pera limonera"},
    {prodId:"50305429",label:"Pera merton pride"},
    {prodId:"50305430",label:"Pera mauntain bartlette"},
    {prodId:"50305431",label:"Pera oliver de serrers"},
    {prodId:"50305432",label:"Pera onward"},
    {prodId:"50305433",label:"Pera packham's triumph"},
    {prodId:"50305434",label:"Pera paraíso"},
    {prodId:"50305435",label:"Pera passe crassane"},
    {prodId:"50305436",label:"Pera perry"},
    {prodId:"50305437",label:"Pera bartlette rojo"},
    {prodId:"50305438",label:"Pera dánjou  rojo"},
    {prodId:"50305439",label:"Pera rocha"},
    {prodId:"50305440",label:"Pera rosey rojo"},
    {prodId:"50305441",label:"Pera rosy roja"},
    {prodId:"50305442",label:"Pera majestuosa royal"},
    {prodId:"50305443",label:"Pera ruby rojo"},
    {prodId:"50305444",label:"Pera santa maria"},
    {prodId:"50305445",label:"Pera seckel"},
    {prodId:"50305446",label:"Pera sensación"},
    {prodId:"50305447",label:"Pera crimson estrella"},
    {prodId:"50305448",label:"Pera crimson stark"},
    {prodId:"50305449",label:"Pera bartlette de verano"},
    {prodId:"50305450",label:"Pera verano dorado"},
    {prodId:"50305451",label:"Pera sol dorado"},
    {prodId:"50305452",label:"Pera sunprite"},
    {prodId:"50305453",label:"Pera taylors dorado"},
    {prodId:"50305454",label:"Pera taylors rojo"},
    {prodId:"50305455",label:"Pera tientsin"},
    {prodId:"50305456",label:"Pera tosca"},
    {prodId:"50305457",label:"Pera warden"},
    {prodId:"50305458",label:"Pera williams bon chretien"},
    {prodId:"50305459",label:"Pera williams"},
    {prodId:"50305460",label:"Pera nelis de invierno"},
    {prodId:"50305600",label:"Piñas"},
    {prodId:"50305601",label:"Piña chirimoya"},
    {prodId:"50305602",label:"Piña dorada"},
    {prodId:"50305603",label:"Piña hilo"},
    {prodId:"50305604",label:"Piña kona sugarloaf"},
    {prodId:"50305605",label:"Piña reina natal"},
    {prodId:"50305606",label:"Piña pernabuco"},
    {prodId:"50305607",label:"Piña español rojo"},
    {prodId:"50305608",label:"Piña cayen suave"},
    {prodId:"50305609",label:"Piña sugarloaf"},
    {prodId:"50305610",label:"Piña variegated"},
    {prodId:"50305800",label:"Ciruelas"},
    {prodId:"50305801",label:"Ciruela joya amber"},
    {prodId:"50305802",label:"Ciruela angeleno"},
    {prodId:"50305803",label:"Ciruela aurora"},
    {prodId:"50305804",label:"Ciruela otoño bonito"},
    {prodId:"50305805",label:"Ciruela gigante de otoño"},
    {prodId:"50305806",label:"Ciruela orgullo de otoño"},
    {prodId:"50305807",label:"Ciruela rosa de otoño"},
    {prodId:"50305808",label:"Ciruela playero"},
    {prodId:"50305809",label:"Ciruela betty anne"},
    {prodId:"50305810",label:"Ciruela belleza negra"},
    {prodId:"50305811",label:"Ciruela bullase negra"},
    {prodId:"50305812",label:"Ciruela diamante negro"},
    {prodId:"50305813",label:"Ciruela gigante negro"},
    {prodId:"50305814",label:"Ciruela hielo negro"},
    {prodId:"50305815",label:"Ciruela esplendor negro"},
    {prodId:"50305816",label:"Ciruela ámbar negro"},
    {prodId:"50305817",label:"Ciruela vino tinto"},
    {prodId:"50305818",label:"Ciruela  carsbad"},
    {prodId:"50305819",label:"Ciruela casselman"},
    {prodId:"50305820",label:"Ciruela catalina"},
    {prodId:"50305821",label:"Ciruela damson"},
    {prodId:"50305822",label:"Ciruela dolly"},
    {prodId:"50305823",label:"Ciruela earliqueen"},
    {prodId:"50305824",label:"Ciruela rosa early"},
    {prodId:"50305825",label:"Ciruela ébano mayo"},
    {prodId:"50305826",label:"Ciruela ébano"},
    {prodId:"50305827",label:"Ciruela corazón de elefante"},
    {prodId:"50305828",label:"Ciruela belleza de esmeralda"},
    {prodId:"50305829",label:"Ciruela emperatriz"},
    {prodId:"50305830",label:"Ciruela libertad"},
    {prodId:"50305831",label:"Ciruela friar"},
    {prodId:"50305832",label:"Ciruela rojo gar"},
    {prodId:"50305833",label:"Ciruela gobernador"},
    {prodId:"50305834",label:"Ciruela rosa grande"},
    {prodId:"50305835",label:"Ciruela green gage"},
    {prodId:"50305836",label:"Ciruela greengage"},
    {prodId:"50305837",label:"Ciruela hiromi"},
    {prodId:"50305838",label:"Ciruela hiromi rojo"},
    {prodId:"50305839",label:"Ciruela vacacion"},
    {prodId:"50305840",label:"Ciruela howard sol"},
    {prodId:"50305841",label:"Ciruela tipo interspecific"},
    {prodId:"50305842",label:"Ciruela jamaiquina"},
    {prodId:"50305843",label:"Ciruela joanna rojo"},
    {prodId:"50305844",label:"Ciruela kelsey"},
    {prodId:"50305845",label:"Ciruela jaime rey"},
    {prodId:"50305846",label:"Ciruela laroda"},
    {prodId:"50305847",label:"Ciruela rosa tarde"},
    {prodId:"50305848",label:"Ciruela rosa linda"},
    {prodId:"50305849",label:"Ciruela estrella solitaria"},
    {prodId:"50305850",label:"Ciruela mariposa"},
    {prodId:"50305851",label:"Ciruela mercado negro"},
    {prodId:"50305852",label:"Ciruela mercado rojo"},
    {prodId:"50305853",label:"Ciruela maribel"},
    {prodId:"50305854",label:"Ciruelas sol de octubre"},
    {prodId:"50305855",label:"Ciruela owen t"},
    {prodId:"50305856",label:"Ciruela perdrigon"},
    {prodId:"50305857",label:"Ciruela placer rosado"},
    {prodId:"50305858",label:"Ciruela  presidente"},
    {prodId:"50305859",label:"Ciruela hora prima"},
    {prodId:"50305860",label:"Ciruela majestad purpura"},
    {prodId:"50305861",label:"Ciruela reina rosa"},
    {prodId:"50305862",label:"Ciruela quetsch"},
    {prodId:"50305863",label:"Ciruela belleza roja"},
    {prodId:"50305864",label:"Ciruela camino rojo"},
    {prodId:"50305865",label:"Ciruela ram rojo"},
    {prodId:"50305866",label:"Ciruela rosa roja"},
    {prodId:"50305867",label:"Ciruela rojo rico"},
    {prodId:"50305868",label:"Ciruela romero"},
    {prodId:"50305869",label:"Ciruela diamante rojo"},
    {prodId:"50305870",label:"Ciruela rojo real"},
    {prodId:"50305871",label:"Ciruela royal zee"},
    {prodId:"50305872",label:"Ciruela roysum"},
    {prodId:"50305873",label:"Ciruela santa rosa"},
    {prodId:"50305874",label:"Ciruela zafiro"},
    {prodId:"50305875",label:"Ciruela sloe"},
    {prodId:"50305876",label:"Ciruela sta catherine"},
    {prodId:"50305877",label:"Ciruela bullase blanco"},
    {prodId:"50305878",label:"Ciruela creole"},
    {prodId:"50306200",label:"Frambuesas"},
    {prodId:"50306201",label:"Frambuesa americana roja"},
    {prodId:"50306202",label:"Frambuesa bailey queensland"},
    {prodId:"50306203",label:"Frambuesa negra"},
    {prodId:"50306204",label:"Frambuesa oscura"},
    {prodId:"50306205",label:"Frambuesa deliciosa"},
    {prodId:"50306206",label:"Frambuesa enano focke"},
    {prodId:"50306207",label:"Frambuesa focke hojagris"},
    {prodId:"50306208",label:"Frambuesa focke fresa"},
    {prodId:"50306209",label:"Frambuesa  focke amarillo himalaya"},
    {prodId:"50306210",label:"Frambuesa dorado"},
    {prodId:"50306211",label:"Frambuesa gris nuevo mexico"},
    {prodId:"50306212",label:"Frambuesa jepson blancobark"},
    {prodId:"50306213",label:"Frambuesa kellogs san diego"},
    {prodId:"50306214",label:"Frambuesa leucodemis blancobark"},
    {prodId:"50306215",label:"Frambuesa munz cuyamaca"},
    {prodId:"50306216",label:"Frambuesa peck bartons"},
    {prodId:"50306217",label:"Frambuesa flor morada"},
    {prodId:"50306218",label:"Frambuesa roadside"},
    {prodId:"50306219",label:"Frambuesa san diego"},
    {prodId:"50306220",label:"Frambuesa nieve"},
    {prodId:"50306221",label:"Frambuesa pico de nieve"},
    {prodId:"50306222",label:"Frambuesa hoja de fresa"},
    {prodId:"50306223",label:"Frambuesa cultivo dulce"},
    {prodId:"50306224",label:"Frambuesa tor y gris blancobark"},
    {prodId:"50306225",label:"Frambuesa caribe"},
    {prodId:"50306226",label:"Frambuesa blancobark"},
    {prodId:"50306227",label:"Frambuesa vino"},
    {prodId:"50306228",label:"Frambuesa himalaya amarillo"},
    {prodId:"50306229",label:"Frambuesa yu-shan"},
    {prodId:"50306500",label:"Sapotes"},
    {prodId:"50306501",label:"Sapote blanco"},
    {prodId:"50306502",label:"Sapote negro"},
    {prodId:"50306700",label:"Fresas"},
    {prodId:"50306701",label:"Fresa chandler"},
    {prodId:"50306702",label:"Fresa rumbo junio"},
    {prodId:"50306703",label:"Fresa rumbo siempre"},
    {prodId:"50307000",label:"Frutas Nominant"},
    {prodId:"50307001",label:"Cupania"},
    {prodId:"50307002",label:"Babaco"},
    {prodId:"50307003",label:"Banana flor"},
    {prodId:"50307004",label:"Baobab"},
    {prodId:"50307005",label:"Naranja agria"},
    {prodId:"50307006",label:"Canistel"},
    {prodId:"50307007",label:"Baya nublosa"},
    {prodId:"50307008",label:"Coco"},
    {prodId:"50307009",label:"Baya drew"},
    {prodId:"50307010",label:"Durian"},
    {prodId:"50307011",label:"Baya elder"},
    {prodId:"50307012",label:"Feijoa"},
    {prodId:"50307013",label:"Mora"},
    {prodId:"50307014",label:"Espino"},
    {prodId:"50307015",label:"Baya miel"},
    {prodId:"50307016",label:"Jaca"},
    {prodId:"50307017",label:"Jambolan"},
    {prodId:"50307018",label:"Jujube"},
    {prodId:"50307019",label:"Liches"},
    {prodId:"50307020",label:"Mangostinos"},
    {prodId:"50307021",label:"Nísperos"},
    {prodId:"50307022",label:"Mombines"},
    {prodId:"50307023",label:"Monstera"},
    {prodId:"50307024",label:"Pepinos"},
    {prodId:"50307025",label:"Platano"},
    {prodId:"50307026",label:"Peras en escabeche"},
    {prodId:"50307027",label:"Mamonsillo"},
    {prodId:"50307028",label:"Rambután"},
    {prodId:"50307029",label:"Manzana rosa"},
    {prodId:"50307030",label:"Rosele"},
    {prodId:"50307031",label:"Baya rowan"},
    {prodId:"50307032",label:"Baya buckhom de mar"},
    {prodId:"50307033",label:"Baya plata"},
    {prodId:"50307034",label:"Baya sorbete"},
    {prodId:"50307035",label:"Guanabana"},
    {prodId:"50307036",label:"Manzana estrella"},
    {prodId:"50307037",label:"Tamarindo"},
    {prodId:"50307038",label:"Camu camu"},
    {prodId:"50307039",label:"Lúcuma"},
    {prodId:"50307040",label:"Araza"},
    {prodId:"50307041",label:"Copoazu"},
    {prodId:"50307042",label:"Poma rosa"},
    {prodId:"50307043",label:"Aguaje"},
    {prodId:"50307044",label:"Cocona"},
    {prodId:"50307045",label:"Guayaba"},
    {prodId:"50307046",label:"Carambola"},
    {prodId:"50307200",label:"Aceitunas"},
    {prodId:"50307201",label:"Aceitunas agrinion"},
    {prodId:"50307202",label:"Aceitunas aleppo"},
    {prodId:"50307203",label:"Aceitunas  alfonso"},
    {prodId:"50307204",label:"Aceitunas  amfisa"},
    {prodId:"50307205",label:"Aceitunas  arauco"},
    {prodId:"50307206",label:"Aceitunas  arbequina"},
    {prodId:"50307207",label:"Aceitunas  atlanta"},
    {prodId:"50307208",label:"Aceitunas  cerignola"},
    {prodId:"50307209",label:"Aceitunas  cracked provencal"},
    {prodId:"50307210",label:"Aceitunas  empeltre"},
    {prodId:"50307211",label:"Aceitunas gaeta"},
    {prodId:"50307212",label:"Aceitunas hondoelia"},
    {prodId:"50307213",label:"Aceitunas kalamata"},
    {prodId:"50307214",label:"Aceitunas kura"},
    {prodId:"50307215",label:"Aceitunas ligurian"},
    {prodId:"50307216",label:"Aceitunas lucque"},
    {prodId:"50307217",label:"Aceitunas lugano"},
    {prodId:"50307218",label:"Aceitunas masanilla"},
    {prodId:"50307219",label:"Aceitunas marche"},
    {prodId:"50307220",label:"Aceitunas misión"},
    {prodId:"50307221",label:"Aceitunas nafplion verde"},
    {prodId:"50307222",label:"Aceitunas nicoise"},
    {prodId:"50307223",label:"Aceitunas nyons"},
    {prodId:"50307224",label:"Aceitunas picholine"},
    {prodId:"50307225",label:"Aceitunas ponentine"},
    {prodId:"50307226",label:"Aceitunas royal"},
    {prodId:"50307227",label:"Aceitunas seracena"},
    {prodId:"50307228",label:"Aceitunas sevillano"},
    {prodId:"50307229",label:"Aceitunas sicilian"},
    {prodId:"50307230",label:"Aceitunas toscanella"},
    {prodId:"50307500",label:"Subproductos de frutas frescas"},
    {prodId:"50307501",label:"Cascara de frutas cítricas"},
    {prodId:"50307502",label:"Extracto de naranja para mezclar en agua"},
    {prodId:"50307503",label:"Pulpa"},
    {prodId:"50311700",label:"Bananos orgánicos"},
    {prodId:"50311701",label:"Banana apple orgánica"},
    {prodId:"50311702",label:"Banana baby orgánica"},
    {prodId:"50311703",label:"Banana burro orgánica"},
    {prodId:"50311704",label:"Bananas cavendish orgánica"},
    {prodId:"50311705",label:"Bananas dominico orgánica"},
    {prodId:"50311706",label:"Bananas verde orgánica"},
    {prodId:"50311707",label:"Bananas gros michel orgánica"},
    {prodId:"50311708",label:"Bananas lacatan orgánica"},
    {prodId:"50311709",label:"Bananas lady finger orgánica"},
    {prodId:"50311710",label:"Bananas manzano orgánica"},
    {prodId:"50311711",label:"Banana mysore orgánica"},
    {prodId:"50311712",label:"Banana pisang mas orgánica"},
    {prodId:"50311713",label:"Bananas rojo orgánica"},
    {prodId:"50311714",label:"Bananas saba orgánica"},
    {prodId:"50311715",label:"Bananas sucrier orgánica"},
    {prodId:"50312000",label:"Moras orgánicas"},
    {prodId:"50312001",label:"Mora apache orgánica"},
    {prodId:"50312002",label:"Mora negro satin orgánica"},
    {prodId:"50312003",label:"Mora orgánica"},
    {prodId:"50312004",label:"Mora cherokee orgánica"},
    {prodId:"50312005",label:"Mora chester orgánica"},
    {prodId:"50312006",label:"Mora dirksen orgánica"},
    {prodId:"50312007",label:"Bayas josta orgánica"},
    {prodId:"50312008",label:"Bayas logan orgánica"},
    {prodId:"50312009",label:"Bayas marion orgánica"},
    {prodId:"50312010",label:"Mora navaho orgánica"},
    {prodId:"50312011",label:"Baya nectar orgánica"},
    {prodId:"50312012",label:"Mora olallie orgánica"},
    {prodId:"50312013",label:"Baya tay orgánica"},
    {prodId:"50312014",label:"Mora thomless hull orgánica"},
    {prodId:"50312015",label:"Baya young orgánicos"},
    {prodId:"50312700",label:"Arándanos orgánicos"},
    {prodId:"50312701",label:"Arandano ben lear orgánica"},
    {prodId:"50312702",label:"Arandano early negro orgánica"},
    {prodId:"50312703",label:"Arandano grycleski orgánica"},
    {prodId:"50312704",label:"Arandano  howe orgánica"},
    {prodId:"50312705",label:"Bayas lingon orgánica"},
    {prodId:"50312706",label:"Arandano mcfarling orgánica"},
    {prodId:"50312707",label:"Arandano mauntain orgánica"},
    {prodId:"50312708",label:"Arandano pilgrim orgánica"},
    {prodId:"50312709",label:"Arandano searless orgánica"},
    {prodId:"50312710",label:"Arandano stevens orgánica"},
    {prodId:"50313400",label:"Uvas de mesa orgánicas"},
    {prodId:"50313401",label:"Uva alicante orgánica"},
    {prodId:"50313402",label:"Uva almeira orgánica"},
    {prodId:"50313403",label:"Uva alphonse lavalle orgánica"},
    {prodId:"50313404",label:"Uva otoño rey grapes orgánica"},
    {prodId:"50313405",label:"Uva otoño royal orgánica"},
    {prodId:"50313406",label:"Uva otoño sin pepa orgánica"},
    {prodId:"50313407",label:"Uva baresana orgánica"},
    {prodId:"50313408",label:"Uva barlinka orgánica"},
    {prodId:"50313409",label:"Uva beaty seedless orgánica"},
    {prodId:"50313410",label:"Uva negro beauty sin pepa orgánica"},
    {prodId:"50313411",label:"Uva negro emerald orgánica"},
    {prodId:"50313412",label:"Uva negro giant orgánica"},
    {prodId:"50313413",label:"Uva negro globo orgánica"},
    {prodId:"50313414",label:"Uva negro monnukka orgánica"},
    {prodId:"50313415",label:"Uva negro perla orgánica"},
    {prodId:"50313416",label:"Uva negro sin pepa orgánica"},
    {prodId:"50313417",label:"Uva bonheur orgánica"},
    {prodId:"50313418",label:"Uva calmeria orgánica"},
    {prodId:"50313419",label:"Uva cardinal orgánica"},
    {prodId:"50313420",label:"Uva catawba orgánica"},
    {prodId:"50313421",label:"Uva chasselas/doradoen chasselas orgánica"},
    {prodId:"50313422",label:"Uva christmas rose orgánica"},
    {prodId:"50313423",label:"Uva concord orgánica"},
    {prodId:"50313424",label:"Uva concord sin pepa orgánica"},
    {prodId:"50313425",label:"Uva crimson sin pepa orgánica"},
    {prodId:"50313426",label:"Uva dauphine  orgánica"},
    {prodId:"50313427",label:"Uva daleware orgánica"},
    {prodId:"50313428",label:"Uva early muscat orgánica"},
    {prodId:"50313429",label:"Uva early sweet orgánica"},
    {prodId:"50313430",label:"Uva esmeralda sin pepa orgánica"},
    {prodId:"50313431",label:"Uva emperatriz orgánica"},
    {prodId:"50313432",label:"Uva emperor orgánica"},
    {prodId:"50313433",label:"Uva empress orgánica"},
    {prodId:"50313434",label:"Uva exótica orgánica"},
    {prodId:"50313435",label:"Uva fantasia orgánica"},
    {prodId:"50313436",label:"Uva fantasy sin pepa orgánica"},
    {prodId:"50313437",label:"Uva llama orgánica"},
    {prodId:"50313438",label:"Uva llama sin pepa orgánica"},
    {prodId:"50313439",label:"Uva llama tokay orgánica"},
    {prodId:"50313440",label:"Uva flaming rojo orgánica"},
    {prodId:"50313441",label:"Uva galaxy sin pepa orgánica"},
    {prodId:"50313442",label:"Uva gamay orgánica"},
    {prodId:"50313443",label:"Uva dorada orgánica"},
    {prodId:"50313444",label:"Uva hanepot o honeypot orgánica"},
    {prodId:"50313445",label:"Uva italia orgánica"},
    {prodId:"50313446",label:"Uva jade sin pepa orgánica"},
    {prodId:"50313447",label:"Uva jubilee orgánica"},
    {prodId:"50313448",label:"Uva rey ruby orgánica"},
    {prodId:"50313449",label:"Uva kyoho orgánica"},
    {prodId:"50313450",label:"Uva la rochelle orgánica"},
    {prodId:"50313451",label:"Uva lady finger orgánica"},
    {prodId:"50313452",label:"Uva late sin pepa orgánica"},
    {prodId:"50313453",label:"Uva majestic sin pepa orgánica"},
    {prodId:"50313454",label:"Uva malaga orgánica"},
    {prodId:"50313455",label:"Uva marro sin pepa orgánica"},
    {prodId:"50313456",label:"Uva muscadine orgánica"},
    {prodId:"50313457",label:"Uva muscat llama orgánica"},
    {prodId:"50313458",label:"Uva muscat orgánica"},
    {prodId:"50313459",label:"Uva muscat sin papa orgánica"},
    {prodId:"50313460",label:"Uva napoleon orgánica"},
    {prodId:"50313461",label:"Uva nigeria orgánica"},
    {prodId:"50313462",label:"Uva new cross orgánica"},
    {prodId:"50313463",label:"Uva nibella orgánica"},
    {prodId:"50313464",label:"Uva niagara orgánica"},
    {prodId:"50313465",label:"Uva olivette orgánica"},
    {prodId:"50313466",label:"Uva perlette orgánica"},
    {prodId:"50313467",label:"Uva perlon orgánica"},
    {prodId:"50313468",label:"Uva prima negro sin pepa orgánica"},
    {prodId:"50313469",label:"Uva prncess orgánica"},
    {prodId:"50313470",label:"Uva reina orgánica"},
    {prodId:"50313471",label:"Uva roja blush orgánica"},
    {prodId:"50313472",label:"Uva roja globe orgánica"},
    {prodId:"50313473",label:"Uva roja malaga orgánica"},
    {prodId:"50313474",label:"Uva roja sin pepa orgánica"},
    {prodId:"50313475",label:"Uva regina orgánica"},
    {prodId:"50313476",label:"Uva ribier orgánica"},
    {prodId:"50313477",label:"Uva rosita orgánica"},
    {prodId:"50313478",label:"Uva rouge orgánica"},
    {prodId:"50313479",label:"Uva royal negra sin pepa orgánica"},
    {prodId:"50313480",label:"Uva ruby roja sin pepa orgánica"},
    {prodId:"50313481",label:"Uva ryby sin pepa orgánica"},
    {prodId:"50313482",label:"Uva scarlet royal orgánica"},
    {prodId:"50313483",label:"Uva scuppemong orgánica"},
    {prodId:"50313484",label:"Uva sugarose orgánica"},
    {prodId:"50313485",label:"Uva sugartirteen orgánica"},
    {prodId:"50313486",label:"Uva sugraone orgánica"},
    {prodId:"50313487",label:"Uva sugrasixteen orgánica"},
    {prodId:"50313488",label:"Uva sultana sun roja orgánica"},
    {prodId:"50313489",label:"Uva verano royal orgánica"},
    {prodId:"50313490",label:"Uva atardecer orgánica"},
    {prodId:"50313491",label:"Uva superior sin pepa orgánica"},
    {prodId:"50313492",label:"Uva thompson sin pepa orgánica"},
    {prodId:"50313493",label:"Uva tokaylpinot gris orgánica"},
    {prodId:"50313494",label:"Uva waltman cross orgánica"},
    {prodId:"50313495",label:"Uva blanca sin pepa orgánica"},
    {prodId:"50313496",label:"Uva zante current orgánica"},
    {prodId:"50313600",label:"Uvas para vino orgánicas"},
    {prodId:"50313601",label:"Uva uva alicante bouschet orgánica"},
    {prodId:"50313602",label:"Uva barbera orgánica"},
    {prodId:"50313603",label:"Uva burger orgánica"},
    {prodId:"50313604",label:"Uva cabemet franc orgánica"},
    {prodId:"50313605",label:"Uva cabenet sauvignon orgánica"},
    {prodId:"50313606",label:"Uva carignane orgánica"},
    {prodId:"50313607",label:"Uva carnelian orgánica"},
    {prodId:"50313608",label:"Uva catarratto orgánica"},
    {prodId:"50313609",label:"Uva centurian orgánica"},
    {prodId:"50313610",label:"Uva charbono orgánica"},
    {prodId:"50313611",label:"Uva chardonnay orgánica"},
    {prodId:"50313612",label:"Uva chenin blanco orgánica"},
    {prodId:"50313613",label:"Uva cinsaut orgánica"},
    {prodId:"50313614",label:"Uva dolcetto orgánica"},
    {prodId:"50313615",label:"Uva emerald riesling orgánica"},
    {prodId:"50313616",label:"Uva french colombard orgánica"},
    {prodId:"50313617",label:"Uva gamay o napa orgánica"},
    {prodId:"50313618",label:"Uva gamay beaujolais orgánica"},
    {prodId:"50313619",label:"Uva gewurztraminer orgánica"},
    {prodId:"50313620",label:"Uva grenache orgánica"},
    {prodId:"50313621",label:"Uva grenache blanc orgánica"},
    {prodId:"50313622",label:"Uva lagrein orgánica"},
    {prodId:"50313623",label:"Uva lambrusco orgánica"},
    {prodId:"50313624",label:"Uva malbec orgánica"},
    {prodId:"50313625",label:"Uva malvasía bianca orgánica"},
    {prodId:"50313626",label:"Uva marsanne orgánica"},
    {prodId:"50313627",label:"Uva mataro orgánica"},
    {prodId:"50313628",label:"Uva merlot orgánica"},
    {prodId:"50313629",label:"Uva meunier orgánica"},
    {prodId:"50313630",label:"Uva missiom orgánica"},
    {prodId:"50313631",label:"Uva montepulciano orgánica"},
    {prodId:"50313632",label:"Uva muscat blanc orgánica"},
    {prodId:"50313633",label:"Uva muscat hamburg orgánica"},
    {prodId:"50313634",label:"Uva muscat alexandria orgánica"},
    {prodId:"50313635",label:"Uva muscat naranja orgánica"},
    {prodId:"50313636",label:"Uva nebbiolo orgánica"},
    {prodId:"50313637",label:"Uva palomino orgánica"},
    {prodId:"50313638",label:"Uva petit verdot orgánica"},
    {prodId:"50313639",label:"Uva petite sirah orgánica"},
    {prodId:"50313640",label:"Uva pinot blanc orgánica"},
    {prodId:"50313641",label:"Uva pinot gris orgánica"},
    {prodId:"50313642",label:"Uva pinot noir orgánica"},
    {prodId:"50313643",label:"Uva primitivo orgánica"},
    {prodId:"50313644",label:"Uva roussane orgánica"},
    {prodId:"50313645",label:"Uva real  orgánica"},
    {prodId:"50313646",label:"Uva rubirojo orgánica"},
    {prodId:"50313647",label:"Uva ruby cabenet orgánica"},
    {prodId:"50313648",label:"Uva salvador orgánica"},
    {prodId:"50313649",label:"Uva sangiovese orgánica"},
    {prodId:"50313650",label:"Uva sauvignon blanc orgánica"},
    {prodId:"50313651",label:"Uva sauvignon musque orgánica"},
    {prodId:"50313652",label:"Uva semillon orgánica"},
    {prodId:"50313653",label:"Uva souzao orgánica"},
    {prodId:"50313654",label:"Uva st emilion orgánica"},
    {prodId:"50313655",label:"Uva symohony orgánica"},
    {prodId:"50313656",label:"Uva syrah  orgánica"},
    {prodId:"50313657",label:"Uva tannat orgánica"},
    {prodId:"50313658",label:"Uva temor anillo orgánica"},
    {prodId:"50313659",label:"Uvas teroldego orgánica"},
    {prodId:"50313660",label:"Uvas tocai friulano orgánica"},
    {prodId:"50313661",label:"Uvas touriga nacional orgánica"},
    {prodId:"50313662",label:"Uvas triplett blanc orgánica"},
    {prodId:"50313663",label:"Uvas viognier orgánica"},
    {prodId:"50313664",label:"Uvas blanco riesling orgánica"},
    {prodId:"50313665",label:"Uvas zinfandel orgánica"},
    {prodId:"50314200",label:"Limas orgánicas"},
    {prodId:"50314201",label:"Lima limón indian sweet orgánico"},
    {prodId:"50314202",label:"Lima limón orgánico"},
    {prodId:"50314203",label:"Lima limón mandarin orgánico"},
    {prodId:"50314204",label:"Lima limón philippine orgánico"},
    {prodId:"50314205",label:"Lima limón tahitian orgánico"},
    {prodId:"50314206",label:"Lima limón bearss orgánico"},
    {prodId:"50314207",label:"Lima limón persa orgánico"},
    {prodId:"50314208",label:"Lima limón sin pepas orgánico"},
    {prodId:"50314500",label:"Mangos orgánicos"},
    {prodId:"50314501",label:"Mango alphonso orgánico"},
    {prodId:"50314502",label:"Mango ataulfo orgánico"},
    {prodId:"50314503",label:"Mango criollo orgánico"},
    {prodId:"50314504",label:"Mango edwards orgánico"},
    {prodId:"50314505",label:"Mango francine orgánico"},
    {prodId:"50314506",label:"Mango francis orgánico"},
    {prodId:"50314507",label:"Mango gandaria orgánico"},
    {prodId:"50314508",label:"Mango haden orgánico"},
    {prodId:"50314509",label:"Mangos irwin orgánico"},
    {prodId:"50314510",label:"Mangos keitt orgánico"},
    {prodId:"50314511",label:"Mangos kent orgánico"},
    {prodId:"50314512",label:"Mangos kesar orgánico"},
    {prodId:"50314513",label:"Mangos kuini orgánico"},
    {prodId:"50314514",label:"Mangos manila super orgánico"},
    {prodId:"50314515",label:"Mangos manila orgánico"},
    {prodId:"50314516",label:"Mangos mayaguez orgánico"},
    {prodId:"50314517",label:"Mangos mulgoba orgánico"},
    {prodId:"50314518",label:"Mangos oro orgánico"},
    {prodId:"50314519",label:"Mangos palmer orgánico"},
    {prodId:"50314520",label:"Mango parvin orgánico"},
    {prodId:"50314521",label:"Mango sandersha orgánico"},
    {prodId:"50314522",label:"Mangos sensation orgánico"},
    {prodId:"50314523",label:"Mango smith orgánico"},
    {prodId:"50314524",label:"Mango tomy atkin orgánico"},
    {prodId:"50314525",label:"Mango van dyke orgánico"},
    {prodId:"50314600",label:"Melones orgánicos"},
    {prodId:"50314601",label:"Melon allsweet orgánico"},
    {prodId:"50314602",label:"Melón athens orgánico"},
    {prodId:"50314603",label:"Melón diamante negro orgánico"},
    {prodId:"50314604",label:"Melón cal dulce orgánico"},
    {prodId:"50314605",label:"Melons cantaloupe orgánico"},
    {prodId:"50314606",label:"Melón carnical orgánico"},
    {prodId:"50314607",label:"Melón casaba orgánico"},
    {prodId:"50314608",label:"Melón cavaillon orgánico"},
    {prodId:"50314609",label:"Melón charentais orgánico"},
    {prodId:"50314610",label:"Sandia charleston gray orgánico"},
    {prodId:"50314611",label:"Melón crenshaw orgánico"},
    {prodId:"50314612",label:"Melón crimson sweet orgánico"},
    {prodId:"50314613",label:"Melón dixie lee orgánico"},
    {prodId:"50314614",label:"Melón eclipse orgánico"},
    {prodId:"50314615",label:"Melón ein dór  orgánico"},
    {prodId:"50314616",label:"Melón fiesta orgánico"},
    {prodId:"50314617",label:"Melón galia orgánico"},
    {prodId:"50314618",label:"Melón gaya orgánico"},
    {prodId:"50314619",label:"Melón hami orgánico"},
    {prodId:"50314620",label:"Melón miel dew orgánico"},
    {prodId:"50314621",label:"Melón hielo orgánico"},
    {prodId:"50314622",label:"Melón ida orgullo orgánico"},
    {prodId:"50314623",label:"Melón juan canary orgánico"},
    {prodId:"50314624",label:"Melón jubileo orgánico"},
    {prodId:"50314625",label:"Melón jubilación orgánico"},
    {prodId:"50314626",label:"Melón kaki / kakri orgánico"},
    {prodId:"50314627",label:"Melón kiwano orgánico"},
    {prodId:"50314628",label:"Melon koreano orgánico"},
    {prodId:"50314629",label:"Melón long grey orgánico"},
    {prodId:"50314630",label:"Melón mayan orgánico"},
    {prodId:"50314631",label:"Melón micky lee orgánico"},
    {prodId:"50314632",label:"Melón mirage orgánico"},
    {prodId:"50314633",label:"Sandia luna y estrellas orgánico"},
    {prodId:"50314634",label:"Melón ogen orgánico"},
    {prodId:"50314635",label:"Melón patriot orgánico"},
    {prodId:"50314636",label:"Melón peacook orgánico"},
    {prodId:"50314637",label:"Melón pepino orgánico"},
    {prodId:"50314638",label:"Melón persian orgánico"},
    {prodId:"50314639",label:"Melón picnic orgánico"},
    {prodId:"50314640",label:"Melón piel de sapo orgánico"},
    {prodId:"50314641",label:"Melón piña orgánico"},
    {prodId:"50314642",label:"Melón quetzali orgánico"},
    {prodId:"50314643",label:"Melón goblin rojo orgánico"},
    {prodId:"50314644",label:"Melón regency orgánico"},
    {prodId:"50314645",label:"Melón royal mejestic orgánico"},
    {prodId:"50314646",label:"Melón royal star orgánico"},
    {prodId:"50314647",label:"Melón dulce royal orgánico"},
    {prodId:"50314648",label:"Malon santa claus orgánico"},
    {prodId:"50314649",label:"Melón sharlyn orgánico"},
    {prodId:"50314650",label:"Melón español orgánico"},
    {prodId:"50314651",label:"Melón sprite orgánic orgánico"},
    {prodId:"50314652",label:"Melón starbright orgánico"},
    {prodId:"50314653",label:"Melón stars and stripes orgánico"},
    {prodId:"50314654",label:"Melón bebe de azúcar orgánico"},
    {prodId:"50314655",label:"Sandia bebe de azúcar orgánica"},
    {prodId:"50314656",label:"Melón dulce sol orgánico"},
    {prodId:"50314657",label:"Sandia corazón dulce sin semilla orgánica"},
    {prodId:"50314658",label:"Melón tentación orgánico"},
    {prodId:"50314659",label:"Melón bebé tigre orgánico"},
    {prodId:"50314660",label:"Melón tuscan tipo orgánico"},
    {prodId:"50314661",label:"Sandia baby amarillo orgánica"},
    {prodId:"50316200",label:"Frambuesas orgánicas"},
    {prodId:"50316201",label:"Frambuesa americana roja orgánica"},
    {prodId:"50316202",label:"Frambuesa bailey queensland orgánica"},
    {prodId:"50316203",label:"Frambuesa negra orgánica"},
    {prodId:"50316204",label:"Frambuesa oscura orgánica"},
    {prodId:"50316205",label:"Frambuesa deliciosa orgánica"},
    {prodId:"50316206",label:"Frambuesa enano focke orgánica"},
    {prodId:"50316207",label:"Frambuesa focke hojagris orgánica"},
    {prodId:"50316208",label:"Frambuesa focke fresa orgánica"},
    {prodId:"50316209",label:"Frambuesa  focke amarillo himalaya orgánica"},
    {prodId:"50316210",label:"Frambuesa dorado orgánic"},
    {prodId:"50316211",label:"Frambuesa gris nuevo mexico orgánica"},
    {prodId:"50316212",label:"Frambuesa jepson blancobark orgánica"},
    {prodId:"50316213",label:"Frambuesa kellogs san diego orgánica"},
    {prodId:"50316214",label:"Frambuesa leucodemis blancobark orgánica"},
    {prodId:"50316215",label:"Frambuesa munz cuyamaca orgánica"},
    {prodId:"50316216",label:"Frambuesa peck bartons orgánica"},
    {prodId:"50316217",label:"Frambuesa flor morada orgánica"},
    {prodId:"50316218",label:"Frambuesa roadside orgánica"},
    {prodId:"50316219",label:"Frambuesa san diego orgánica"},
    {prodId:"50316220",label:"Frambuesa nieve orgánica"},
    {prodId:"50316221",label:"Frambuesa pico de nieve orgánica"},
    {prodId:"50316222",label:"Frambuesa hoja de fresa orgánica"},
    {prodId:"50316223",label:"Frambuesa cultivo dulce orgánica"},
    {prodId:"50316224",label:"Frambuesa tor y gris blancobark orgánica"},
    {prodId:"50316225",label:"Frambuesa caribe orgánica"},
    {prodId:"50316226",label:"Frambuesa blancobark orgánica"},
    {prodId:"50316227",label:"Frambuesa vino orgánica"},
    {prodId:"50316228",label:"Frambuesa himalaya amarillo orgánica"},
    {prodId:"50316229",label:"Frambuesa yu-shan orgánica"},
    {prodId:"50316700",label:"Fresas orgánicas"},
    {prodId:"50316701",label:"Fresa chandler orgánica"},
    {prodId:"50316702",label:"Fresa rumbo junio orgánica"},
    {prodId:"50316703",label:"Fresa rumbo siempre orgánica"},
    {prodId:"50317000",label:"Frutas Nominant orgánicas"},
    {prodId:"50317001",label:"Cupania orgánica"},
    {prodId:"50317002",label:"Babaco orgánico"},
    {prodId:"50317003",label:"Banana flor orgánica"},
    {prodId:"50317004",label:"Baobab orgánica"},
    {prodId:"50317005",label:"Naranja agria orgánica"},
    {prodId:"50317006",label:"Canistel orgánica"},
    {prodId:"50317007",label:"Baya nublosa orgánica"},
    {prodId:"50317008",label:"Coco orgánico"},
    {prodId:"50317009",label:"Baya drew orgánica"},
    {prodId:"50317010",label:"Durian orgánica"},
    {prodId:"50317011",label:"Baya elder orgánica"},
    {prodId:"50317012",label:"Feijoa orgánica"},
    {prodId:"50317013",label:"Mora orgánica"},
    {prodId:"50317014",label:"Cretaegus orgánica"},
    {prodId:"50317015",label:"Baya miel orgánica"},
    {prodId:"50317016",label:"Jaca orgánica"},
    {prodId:"50317017",label:"Jambolan orgánica"},
    {prodId:"50317018",label:"Jujube orgánica"},
    {prodId:"50317019",label:"Liches orgánicos"},
    {prodId:"50317020",label:"Mangostinos orgánicos"},
    {prodId:"50317021",label:"Nísperos orgánicos"},
    {prodId:"50317022",label:"Mombines orgánica"},
    {prodId:"50317023",label:"Monstera orgánica"},
    {prodId:"50317024",label:"Pepinos orgánico"},
    {prodId:"50317025",label:"Platono orgánico"},
    {prodId:"50317026",label:"Peras en escabeche orgánica"},
    {prodId:"50317027",label:"Mamonsillo orgánico"},
    {prodId:"50317028",label:"Rambután orgánico"},
    {prodId:"50317029",label:"Manzana rosa orgánica"},
    {prodId:"50317030",label:"Rosele orgánica"},
    {prodId:"50317031",label:"Baya rowan orgánica"},
    {prodId:"50317032",label:"Baya buckhom de mar orgánica"},
    {prodId:"50317033",label:"Baya plata orgánica"},
    {prodId:"50317034",label:"Baya sorbete orgánico"},
    {prodId:"50317035",label:"Guanabana orgánica"},
    {prodId:"50317036",label:"Manzana estrella orgánica"},
    {prodId:"50317037",label:"Tamarindo orgánico"},
    {prodId:"50321500",label:"Manzanas secas"},
    {prodId:"50321501",label:"Manzanas akane secas"},
    {prodId:"50321502",label:"Manzana ambrosia seca"},
    {prodId:"50321503",label:"Manzanas api secas"},
    {prodId:"50321504",label:"Manzanas baldwin secas"},
    {prodId:"50321505",label:"Manzanas brabum secas"},
    {prodId:"50321506",label:"Manzanas bramley secas"},
    {prodId:"50321507",label:"Manzana joven bramley sec"},
    {prodId:"50321508",label:"Manzana calville blanche d'hiver sec"},
    {prodId:"50321509",label:"Manzana cameo seca"},
    {prodId:"50321510",label:"Manzana charles ross seca"},
    {prodId:"50321511",label:"Manzana codlin seca"},
    {prodId:"50321512",label:"Manzana cortland seca"},
    {prodId:"50321513",label:"Manzana costard seca"},
    {prodId:"50321514",label:"Manzana court pendu plat seca"},
    {prodId:"50321515",label:"Manzana  cox's orange pippin seca"},
    {prodId:"50321516",label:"Manzana crab seca"},
    {prodId:"50321517",label:"Manzana crispin seca"},
    {prodId:"50321518",label:"Manzana delisioso seca"},
    {prodId:"50321519",label:"Manzana duchess seca"},
    {prodId:"50321520",label:"Manzana earlidorado seca"},
    {prodId:"50321521",label:"Manzana early mcintosh seca"},
    {prodId:"50321522",label:"Manzana elstar seca"},
    {prodId:"50321523",label:"Manzana empire seca"},
    {prodId:"50321524",label:"Manzana flor de seca"},
    {prodId:"50321525",label:"Manzana fiji seca"},
    {prodId:"50321526",label:"Manzana gala seca"},
    {prodId:"50321527",label:"Manzana gascoyne's scarlet seca"},
    {prodId:"50321528",label:"Manzana gillyflower seca"},
    {prodId:"50321529",label:"Manzana ginger dorado seca"},
    {prodId:"50321530",label:"Manzana gladstone seca"},
    {prodId:"50321531",label:"Manzana gloster seca"},
    {prodId:"50321532",label:"Manzana dorado supreme seca"},
    {prodId:"50321533",label:"Manzana doradoen delisioso seca"},
    {prodId:"50321534",label:"Manzana doradoen nobel seca"},
    {prodId:"50321535",label:"Manzana granny smith seca"},
    {prodId:"50321536",label:"Manzana gravenstain seca"},
    {prodId:"50321537",label:"Manzana verdeing seca"},
    {prodId:"50321538",label:"Manzana verdesleeves seca"},
    {prodId:"50321539",label:"Manzana honeycrisp seca"},
    {prodId:"50321540",label:"Manzana howgate wonder seca"},
    {prodId:"50321541",label:"Manzana roja ida seca"},
    {prodId:"50321542",label:"Manzana james grives seca"},
    {prodId:"50321543",label:"Manzana jersey mac seca"},
    {prodId:"50321544",label:"Manzana jaster seca"},
    {prodId:"50321545",label:"Manzana jonadorado seca"},
    {prodId:"50321546",label:"Manzana jonamac seca"},
    {prodId:"50321547",label:"Manzana  jonathan  seca"},
    {prodId:"50321548",label:"Manzana katy seca"},
    {prodId:"50321549",label:"Manzana kidd's orange roja seca"},
    {prodId:"50321550",label:"Manzana lady seca"},
    {prodId:"50321551",label:"Manzana law roma seca"},
    {prodId:"50321552",label:"Manzana laxton seca"},
    {prodId:"50321553",label:"Manzana lord derby seca"},
    {prodId:"50321554",label:"Manzana macoun  seca"},
    {prodId:"50321555",label:"Manzana mcintosh seca"},
    {prodId:"50321556",label:"Manzana mutsu seca"},
    {prodId:"50321557",label:"Manzana newtown pippin seca"},
    {prodId:"50321558",label:"Manzana northen spy seca seca"},
    {prodId:"50321559",label:"Manzana orleans reinette seca"},
    {prodId:"50321560",label:"Manzana ozark dorado seca"},
    {prodId:"50321561",label:"Manzana pacific rose seca"},
    {prodId:"50321562",label:"Manzana paula roja seca"},
    {prodId:"50321563",label:"Manzana peamain seca"},
    {prodId:"50321564",label:"Manzana pink lady seca"},
    {prodId:"50321565",label:"Manzana pippin seca"},
    {prodId:"50321566",label:"Manzana pitmaston pineapple seca"},
    {prodId:"50321567",label:"Manzana pomme d'api seca"},
    {prodId:"50321568",label:"Manzana prime dorado seca"},
    {prodId:"50321569",label:"Manzana roja astrakhan seca"},
    {prodId:"50321570",label:"Manzana roja boscoop seca"},
    {prodId:"50321571",label:"Manzana roja chief seca"},
    {prodId:"50321572",label:"Manzana roja delisioso seca"},
    {prodId:"50321573",label:"Manzana roja grvenstain seca"},
    {prodId:"50321574",label:"Manzana roja roma seca"},
    {prodId:"50321575",label:"Manzana roja stayman seca"},
    {prodId:"50321576",label:"Manzana roja york seca"},
    {prodId:"50321577",label:"Manzana reinette seca"},
    {prodId:"50321578",label:"Manzana roma beuty seca"},
    {prodId:"50321579",label:"Manzana russet seca"},
    {prodId:"50321580",label:"Manzana sierra beaty seca"},
    {prodId:"50321581",label:"Manzana spartan seca"},
    {prodId:"50321582",label:"Manzana stark crimson seca"},
    {prodId:"50321583",label:"Manzana starrey seca"},
    {prodId:"50321584",label:"Manzana stayman seca"},
    {prodId:"50321585",label:"Manzana stayman winesap seca"},
    {prodId:"50321586",label:"Manzana summer rambo seca"},
    {prodId:"50321587",label:"Manzana tsugaru seca"},
    {prodId:"50321588",label:"Manzana twenty ounce seca"},
    {prodId:"50321589",label:"Manzana tydeman roja seca"},
    {prodId:"50321590",label:"Manzana vistabella seca"},
    {prodId:"50321591",label:"Manzana weatley seca"},
    {prodId:"50321592",label:"Manzana blanco joanetine seca"},
    {prodId:"50321593",label:"Manzana blanco transparent seca"},
    {prodId:"50321594",label:"Manzana winesap seca"},
    {prodId:"50321595",label:"Manzana worcester seca"},
    {prodId:"50321596",label:"Manzana york imperial seca"},
    {prodId:"50321600",label:"Albaricoques secos"},
    {prodId:"50321601",label:"Abaricoques ambercot seca"},
    {prodId:"50321602",label:"Albaricoques apache seca"},
    {prodId:"50321603",label:"Albaricoques brittany dorado seca"},
    {prodId:"50321604",label:"Albaricoque negro seca"},
    {prodId:"50321605",label:"Albaricoque blenheim seca"},
    {prodId:"50321606",label:"Albaricoque bonny seca"},
    {prodId:"50321607",label:"Albaricoque biluda seca"},
    {prodId:"50321608",label:"Albaricoque castlebrite seca"},
    {prodId:"50321609",label:"Albaricoque  clutha dorado seca"},
    {prodId:"50321610",label:"Albaricoque clutha sun seca"},
    {prodId:"50321611",label:"Albaricoque derby royal seca"},
    {prodId:"50321612",label:"Albaricoque dina seca"},
    {prodId:"50321613",label:"Albaricoque earlicot seca"},
    {prodId:"50321614",label:"Albaricoque earliman seca"},
    {prodId:"50321615",label:"Albaricoque early bright seca"},
    {prodId:"50321616",label:"Albaricoque  flaming dorado seca"},
    {prodId:"50321617",label:"Albaricoque frenso seca"},
    {prodId:"50321618",label:"Albaricoque dorado bright seca"},
    {prodId:"50321619",label:"Albaricoque dorado barra seca"},
    {prodId:"50321620",label:"Albaricoque dorado en dulce seca"},
    {prodId:"50321621",label:"Albaricoque dorado rico seca"},
    {prodId:"50321622",label:"Albaricoque helena seca"},
    {prodId:"50321623",label:"Albaricoque honeycot seca"},
    {prodId:"50321624",label:"Albaricoque imperial seca"},
    {prodId:"50321625",label:"Albaricoque jordanne seca"},
    {prodId:"50321626",label:"Albaricoque jumbo cot seca"},
    {prodId:"50321627",label:"Albaricoque kandy kot seca"},
    {prodId:"50321628",label:"Albaricoque katy seca"},
    {prodId:"50321629",label:"Albaricoque rey seca"},
    {prodId:"50321630",label:"Albaricoque lambertin seca"},
    {prodId:"50321631",label:"Albaricoque loma seca"},
    {prodId:"50321632",label:"Albaricoque lulu belle seca"},
    {prodId:"50321633",label:"Albaricoque modesto seca"},
    {prodId:"50321634",label:"Albaricoque moorpark seca"},
    {prodId:"50321635",label:"Albaricoque orangeroja seca"},
    {prodId:"50321636",label:"Albaricoque palstein seca"},
    {prodId:"50321637",label:"Albaricoque patterson seca"},
    {prodId:"50321638",label:"Albaricoque perfection seca"},
    {prodId:"50321639",label:"Albaricoque poppy seca"},
    {prodId:"50321640",label:"Albaricoque poppycot seca"},
    {prodId:"50321641",label:"Albaricoque reina seca"},
    {prodId:"50321642",label:"Albaricoque riland seca"},
    {prodId:"50321643",label:"Albaricoque rival seca"},
    {prodId:"50321644",label:"Albaricoque robada seca"},
    {prodId:"50321645",label:"Albaricoque royal seca"},
    {prodId:"50321646",label:"Albaricoque  royal blenheim seca"},
    {prodId:"50321647",label:"Albaricoque  royal orange seca"},
    {prodId:"50321648",label:"Albaricoque dota de sol seca"},
    {prodId:"50321649",label:"Albaricoque tilton seca"},
    {prodId:"50321650",label:"Albaricoque tomcot seca"},
    {prodId:"50321651",label:"Albaricoque tracy seca"},
    {prodId:"50321652",label:"Albaricoque tri gem seca"},
    {prodId:"50321653",label:"Albaricoque valley dorado seca"},
    {prodId:"50321654",label:"Albaricoque westley seca"},
    {prodId:"50321655",label:"Albaricoque  york seca"},
    {prodId:"50321700",label:"Bananos secos"},
    {prodId:"50321701",label:"Banana manzana seca"},
    {prodId:"50321702",label:"Banana bebe seca"},
    {prodId:"50321703",label:"Banana burro seca"},
    {prodId:"50321704",label:"Bananas cavendish seca"},
    {prodId:"50321705",label:"Bananas dominico seca"},
    {prodId:"50321706",label:"Bananas verde seca"},
    {prodId:"50321707",label:"Bananas gros michel seca"},
    {prodId:"50321708",label:"Bananas lacatan seca"},
    {prodId:"50321709",label:"Bananas dedo de dama seca"},
    {prodId:"50321710",label:"Bananas manzano seca"},
    {prodId:"50321711",label:"Banana mysore seca"},
    {prodId:"50321712",label:"Banana pisang mas seca"},
    {prodId:"50321713",label:"Bananas roja seca"},
    {prodId:"50321714",label:"Bananas saba seca"},
    {prodId:"50321715",label:"Bananas sucrier seca"},
    {prodId:"50322500",label:"Cerezas secas"},
    {prodId:"50322501",label:"Cereza amarelle  seca"},
    {prodId:"50322502",label:"Cereza brooks seca"},
    {prodId:"50322503",label:"Cereza bigarreu seca"},
    {prodId:"50322504",label:"Cereza bing seca"},
    {prodId:"50322505",label:"Cereza blach republic seca"},
    {prodId:"50322506",label:"Cereza negro schmidt seca"},
    {prodId:"50322507",label:"Cereza negro tartarian seca"},
    {prodId:"50322508",label:"Cereza fiesta bing seca"},
    {prodId:"50322509",label:"Cereza garnet seca"},
    {prodId:"50322510",label:"Cereza rey seca"},
    {prodId:"50322511",label:"Cereza chapman seca"},
    {prodId:"50322512",label:"Cereza lapin seca"},
    {prodId:"50322513",label:"Cereza larian seca"},
    {prodId:"50322514",label:"Cereza dark guines seca"},
    {prodId:"50322515",label:"Cereza montmorency seca"},
    {prodId:"50322516",label:"Cereza duque seca"},
    {prodId:"50322517",label:"Cereza early rivers seca"},
    {prodId:"50322518",label:"Cereza ruby bing seca"},
    {prodId:"50322519",label:"Cereza santine seca"},
    {prodId:"50322520",label:"Cereza geans/guines seca"},
    {prodId:"50322521",label:"Cereza sonata seca"},
    {prodId:"50322522",label:"Cereza lambert seca"},
    {prodId:"50322523",label:"Cereza stella seca"},
    {prodId:"50322524",label:"Cereza sweethart seca"},
    {prodId:"50322525",label:"Cereza tartarian seca"},
    {prodId:"50322527",label:"Cereza maraschino seca"},
    {prodId:"50322528",label:"Cereza van seca"},
    {prodId:"50322529",label:"Cereza morello seca"},
    {prodId:"50322530",label:"Cereza ann real seca"},
    {prodId:"50322531",label:"Cereza ranier seca"},
    {prodId:"50322532",label:"Cereza real seca"},
    {prodId:"50322700",label:"Arándanos secos"},
    {prodId:"50322701",label:"Arandano ben lear seca"},
    {prodId:"50322702",label:"Arandano early negro seca"},
    {prodId:"50322703",label:"Arandano gryclesik seca"},
    {prodId:"50322704",label:"Arandano  howe seca"},
    {prodId:"50322705",label:"Bayas lingon seca"},
    {prodId:"50322706",label:"Arandano mcfarling seca"},
    {prodId:"50322707",label:"Arandano mauntain seca"},
    {prodId:"50322708",label:"Arandano pilgrim seca"},
    {prodId:"50322709",label:"Arandano searless seca"},
    {prodId:"50322710",label:"Arandano stevens seca"},
    {prodId:"50323400",label:"Uvas de mesa secas"},
    {prodId:"50323401",label:"Uva alicante seca"},
    {prodId:"50323402",label:"Uva almeira seca"},
    {prodId:"50323403",label:"Uva alphonse lavalle seca"},
    {prodId:"50323404",label:"Uva otoño rey grapes seca"},
    {prodId:"50323405",label:"Uva otoño royal seca"},
    {prodId:"50323406",label:"Uva otoño sin pepa seca"},
    {prodId:"50323407",label:"Uva baresana seca"},
    {prodId:"50323408",label:"Uva barlinka seca"},
    {prodId:"50323409",label:"Uva beaty seedless seca"},
    {prodId:"50323410",label:"Uva negro beauty sin pepa seca"},
    {prodId:"50323411",label:"Uva negro esmeralda seca"},
    {prodId:"50323412",label:"Uva negro gigante seca"},
    {prodId:"50323413",label:"Uva negro globo seca"},
    {prodId:"50323414",label:"Uva negro monnukka seca"},
    {prodId:"50323415",label:"Uva negro perla seca"},
    {prodId:"50323416",label:"Uva negro sin pepa seca"},
    {prodId:"50323417",label:"Uva bonheur seca"},
    {prodId:"50323418",label:"Uva calmeria seca"},
    {prodId:"50323419",label:"Uva cardinal seca"},
    {prodId:"50323420",label:"Uva catawba seca"},
    {prodId:"50323421",label:"Uva chasselas/doradoen chasselas seca"},
    {prodId:"50323422",label:"Uva christmas rose seca"},
    {prodId:"50323423",label:"Uva concord seca"},
    {prodId:"50323424",label:"Uva concord sin pepa seca"},
    {prodId:"50323425",label:"Uva crimson sin pepa seca"},
    {prodId:"50323426",label:"Uva dauphine  seca"},
    {prodId:"50323427",label:"Uva daleware seca"},
    {prodId:"50323428",label:"Uva early muscat seca"},
    {prodId:"50323429",label:"Uva early sweet seca"},
    {prodId:"50323430",label:"Uva esmeralda sin pepa seca"},
    {prodId:"50323431",label:"Uva emperatriz seca"},
    {prodId:"50323432",label:"Uva emperor seca"},
    {prodId:"50323433",label:"Uva empress seca"},
    {prodId:"50323434",label:"Uva exótica seca"},
    {prodId:"50323435",label:"Uva fantasia seca"},
    {prodId:"50323436",label:"Uva fantasy sin pepa seca"},
    {prodId:"50323437",label:"Uva llama seca"},
    {prodId:"50323438",label:"Uva llama sin pepa seca"},
    {prodId:"50323439",label:"Uva llama tokay seca"},
    {prodId:"50323440",label:"Uva ardiente roja seca"},
    {prodId:"50323441",label:"Uva galaxy sin pepa seca"},
    {prodId:"50323442",label:"Uva gamay seca"},
    {prodId:"50323443",label:"Uva dorada seca"},
    {prodId:"50323444",label:"Uva hanepot o honeypot seca"},
    {prodId:"50323445",label:"Uva italia seca"},
    {prodId:"50323446",label:"Uva jade sin pepa seca"},
    {prodId:"50323447",label:"Uva jubilee seca"},
    {prodId:"50323448",label:"Uva rey ruby seca"},
    {prodId:"50323449",label:"Uva kyoho seca"},
    {prodId:"50323450",label:"Uva la rochelle seca"},
    {prodId:"50323451",label:"Uva dedo de dama seca"},
    {prodId:"50323452",label:"Uva late sin pepa seca"},
    {prodId:"50323453",label:"Uva majestic sin pepa seca"},
    {prodId:"50323454",label:"Uva malaga seca"},
    {prodId:"50323455",label:"Uva marro sin pepa seca"},
    {prodId:"50323456",label:"Uva muscadine seca"},
    {prodId:"50323457",label:"Uva muscat llama seca"},
    {prodId:"50323458",label:"Uva muscat seca"},
    {prodId:"50323459",label:"Uva muscat sin papa seca"},
    {prodId:"50323460",label:"Uva napoleon seca"},
    {prodId:"50323461",label:"Uva nigeria seca"},
    {prodId:"50323462",label:"Uva new cross seca"},
    {prodId:"50323463",label:"Uva nibella seca"},
    {prodId:"50323464",label:"Uva niagara seca"},
    {prodId:"50323465",label:"Uva olivette seca"},
    {prodId:"50323466",label:"Uva perlette seca"},
    {prodId:"50323467",label:"Uva perlon seca"},
    {prodId:"50323468",label:"Uva prima negro sin pepa seca"},
    {prodId:"50323469",label:"Uva prncess seca"},
    {prodId:"50323470",label:"Uva reina seca"},
    {prodId:"50323471",label:"Uva roja blush seca"},
    {prodId:"50323472",label:"Uva roja globe seca"},
    {prodId:"50323473",label:"Uva roja malaga seca"},
    {prodId:"50323474",label:"Uva roja sin pepa seca"},
    {prodId:"50323475",label:"Uva regina seca"},
    {prodId:"50323476",label:"Uva ribier seca"},
    {prodId:"50323477",label:"Uva rosita seca"},
    {prodId:"50323478",label:"Uva rouge seca"},
    {prodId:"50323479",label:"Uva royal negra sin pepa seca"},
    {prodId:"50323480",label:"Uva ruby roja sin pepa seca"},
    {prodId:"50323481",label:"Uva ryby sin pepa seca"},
    {prodId:"50323482",label:"Uva scarlet royal seca"},
    {prodId:"50323483",label:"Uva scuppemong seca"},
    {prodId:"50323484",label:"Uva sugarose seca"},
    {prodId:"50323485",label:"Uva sugartirteen seca"},
    {prodId:"50323486",label:"Uva sugraone seca"},
    {prodId:"50323487",label:"Uva sugrasixteen seca"},
    {prodId:"50323488",label:"Uva sultana sun roja seca"},
    {prodId:"50323489",label:"Uva verano royal seca"},
    {prodId:"50323490",label:"Uva atardecer seca"},
    {prodId:"50323491",label:"Uva superior sin pepa seca"},
    {prodId:"50323492",label:"Uva thompson sin pepa seca"},
    {prodId:"50323493",label:"Uva tokaylpinot gris seca"},
    {prodId:"50323494",label:"Uva waltman cross seca"},
    {prodId:"50323495",label:"Uva blanca sin semilla seca"},
    {prodId:"50323496",label:"Uva zante current seca"},
    {prodId:"50323500",label:"Uvas pasas secas"},
    {prodId:"50323501",label:"Uva corinth negra seca"},
    {prodId:"50323502",label:"Uva canner seca"},
    {prodId:"50323503",label:"Uva dovine seca"},
    {prodId:"50323504",label:"Uva fiesta seca"},
    {prodId:"50323505",label:"Uva selme pete seca"},
    {prodId:"50323506",label:"Uva sultana seca"},
    {prodId:"50323800",label:"Arándanos Huckleberry secos"},
    {prodId:"50323801",label:"Arándano invierno negro seco"},
    {prodId:"50323802",label:"Arándano cascada seco"},
    {prodId:"50323803",label:"Arándano enano seco"},
    {prodId:"50323804",label:"Arándano montaña seco"},
    {prodId:"50323805",label:"Arándano rojo seco"},
    {prodId:"50323900",label:"Kiwis secos"},
    {prodId:"50323901",label:"Fruta de kiwi ananasnaja seca"},
    {prodId:"50323902",label:"Fruta de kiwi belleza del ártico seca"},
    {prodId:"50323903",label:"Fruta de kiwi blake seca"},
    {prodId:"50323904",label:"Fruta de kiwi hayward seca"},
    {prodId:"50323905",label:"Fruta de kiwi issai seca"},
    {prodId:"50323906",label:"Fruta de kiwi siberiana seca"},
    {prodId:"50324500",label:"Mangos secos"},
    {prodId:"50324501",label:"Mango alphonso seco"},
    {prodId:"50324502",label:"Mango ataulfo seco"},
    {prodId:"50324503",label:"Mango criollo seco"},
    {prodId:"50324504",label:"Mango edwards seco"},
    {prodId:"50324505",label:"Mango francine seco"},
    {prodId:"50324506",label:"Mango francis seco"},
    {prodId:"50324507",label:"Mango gandaria seco"},
    {prodId:"50324508",label:"Mango haden seco"},
    {prodId:"50324509",label:"Mangos irwin seco"},
    {prodId:"50324510",label:"Mangos keitt seco"},
    {prodId:"50324511",label:"Mangos kent seco"},
    {prodId:"50324512",label:"Mangos kesar seco"},
    {prodId:"50324513",label:"Mangos kuini seco"},
    {prodId:"50324514",label:"Mangos manila super seco"},
    {prodId:"50324515",label:"Mangos manila seco"},
    {prodId:"50324516",label:"Mangos mayaguez seco"},
    {prodId:"50324517",label:"Mangos mulgoba seco"},
    {prodId:"50324518",label:"Mangos oro seco"},
    {prodId:"50324519",label:"Mangos palmer seco"},
    {prodId:"50324520",label:"Mango parvin seco"},
    {prodId:"50324521",label:"Mango sandersha seco"},
    {prodId:"50324522",label:"Mangos sensation seco"},
    {prodId:"50324523",label:"Mango smith seco"},
    {prodId:"50324524",label:"Mango tomy atkin seco"},
    {prodId:"50324525",label:"Mango van dyke seco"},
    {prodId:"50324600",label:"Melones secos"},
    {prodId:"50324601",label:"Melon allsweet seco"},
    {prodId:"50324602",label:"Melón athens seco"},
    {prodId:"50324603",label:"Melón diamante negro seco"},
    {prodId:"50324604",label:"Melón cal sweet seco"},
    {prodId:"50324605",label:"Melons cantaloupe seco"},
    {prodId:"50324606",label:"Melón carnical seco"},
    {prodId:"50324607",label:"Melón casaba seco"},
    {prodId:"50324608",label:"Melón cavaillon seco"},
    {prodId:"50324609",label:"Melón charentais seco"},
    {prodId:"50324610",label:"Sandia charleston gray seco"},
    {prodId:"50324611",label:"Melón crenshaw seco"},
    {prodId:"50324612",label:"Melón crimson sweet seco"},
    {prodId:"50324613",label:"Melón dixie lee seco"},
    {prodId:"50324614",label:"Melón eclipse seco"},
    {prodId:"50324615",label:"Melón ein dór  seco"},
    {prodId:"50324616",label:"Melón fiesta seco"},
    {prodId:"50324617",label:"Melón galia seco"},
    {prodId:"50324618",label:"Melón gaya seco"},
    {prodId:"50324619",label:"Melón hami seco"},
    {prodId:"50324620",label:"Melón miel dew seco"},
    {prodId:"50324621",label:"Melón hielo seco"},
    {prodId:"50324622",label:"Melón ida orgullo seco"},
    {prodId:"50324623",label:"Melón juan canary seco"},
    {prodId:"50324624",label:"Melón jubileo seco"},
    {prodId:"50324625",label:"Melón jubilación seco"},
    {prodId:"50324626",label:"Melón kaki / kakri seco"},
    {prodId:"50324627",label:"Melón kiwano seco"},
    {prodId:"50324628",label:"Melon koreano seco"},
    {prodId:"50324629",label:"Melón long grey seco"},
    {prodId:"50324630",label:"Melón mayan seco"},
    {prodId:"50324631",label:"Melón micky lee seco"},
    {prodId:"50324632",label:"Melón mirage seco"},
    {prodId:"50324633",label:"Sandia luna y estrellas seco"},
    {prodId:"50324634",label:"Melón ogen seco"},
    {prodId:"50324635",label:"Melón patriot seco"},
    {prodId:"50324636",label:"Melón pavo real seco"},
    {prodId:"50324637",label:"Melón pepino seco"},
    {prodId:"50324638",label:"Melón persian seco"},
    {prodId:"50324639",label:"Melón picnic seco"},
    {prodId:"50324640",label:"Melón piel de sapo seco"},
    {prodId:"50324641",label:"Melón piña seco"},
    {prodId:"50324642",label:"Melón quetzali seco"},
    {prodId:"50324643",label:"Melón goblin rojo seco"},
    {prodId:"50324644",label:"Melón regency seco"},
    {prodId:"50324645",label:"Melón royal mejestic seco"},
    {prodId:"50324646",label:"Melón royal star seco"},
    {prodId:"50324647",label:"Melón dulce royal seco"},
    {prodId:"50324648",label:"Malon santa claus seco"},
    {prodId:"50324649",label:"Melón sharlyn seco"},
    {prodId:"50324650",label:"Melón español seco"},
    {prodId:"50324651",label:"Melón sprite sec seco"},
    {prodId:"50324652",label:"Melón starbright seco"},
    {prodId:"50324653",label:"Melón stars and stripes seco"},
    {prodId:"50324654",label:"Melón bebe de azúcar seco"},
    {prodId:"50324655",label:"Sandia bebe de azúcar seca"},
    {prodId:"50324656",label:"Melón dulce sol seco"},
    {prodId:"50324657",label:"Sandia corazón dulce sin semilla seca"},
    {prodId:"50324658",label:"Melón tentación seco"},
    {prodId:"50324659",label:"Melón bebe tigre seco"},
    {prodId:"50324660",label:"Melón tuscan tipo seco"},
    {prodId:"50324661",label:"Sandia bebe amarillo seca"},
    {prodId:"50325100",label:"Papayas secas"},
    {prodId:"50325101",label:"Papaya verde cocinar seca"},
    {prodId:"50325102",label:"Papaya maradol seca"},
    {prodId:"50325103",label:"Papaya amarillo mexicano seca"},
    {prodId:"50325104",label:"Papaya montaña seca"},
    {prodId:"50325105",label:"Papaya solo seca"},
    {prodId:"50325106",label:"Papaya tainung seca"},
    {prodId:"50325300",label:"Duraznos secos"},
    {prodId:"50325301",label:"Durazno escudo amber seco"},
    {prodId:"50325302",label:"Durazno nieve de abril seco"},
    {prodId:"50325303",label:"Durazno dama de agosto seco"},
    {prodId:"50325304",label:"Durazno llama de otoño seco"},
    {prodId:"50325305",label:"Durazno dama de otoño seco"},
    {prodId:"50325306",label:"Durazno babcock seco"},
    {prodId:"50325307",label:"Durazno brittany lane seco"},
    {prodId:"50325308",label:"Durazno cary mac seco"},
    {prodId:"50325309",label:"Durazno clásica seco"},
    {prodId:"50325310",label:"Durazno dulce del campo seco"},
    {prodId:"50325311",label:"Durazno escudo de cielo seco"},
    {prodId:"50325312",label:"Durazno dama crimson seco"},
    {prodId:"50325313",label:"Durazno príncipe corona seco"},
    {prodId:"50325314",label:"Durazno sol david seco"},
    {prodId:"50325315",label:"Durazno  princesa diamante seco"},
    {prodId:"50325316",label:"Durazno earlrich seco"},
    {prodId:"50325317",label:"Durazno majestuosa temprana seco"},
    {prodId:"50325318",label:"Durazno early treat seco"},
    {prodId:"50325319",label:"Durazno dama elegante seco"},
    {prodId:"50325320",label:"Durazno emperatriz seco"},
    {prodId:"50325321",label:"Durazno encoré seco"},
    {prodId:"50325322",label:"Durazno dama elegante seco"},
    {prodId:"50325323",label:"Durazno príncipe de fuego seco"},
    {prodId:"50325324",label:"Durazno escudo de llama seco"},
    {prodId:"50325325",label:"Durazno tipo plano seco"},
    {prodId:"50325326",label:"Durazno escudo de sabor seco"},
    {prodId:"50325327",label:"Durazno príncipe florida seco"},
    {prodId:"50325328",label:"Durazno luna llena seco"},
    {prodId:"50325329",label:"Durazno harvester seco"},
    {prodId:"50325330",label:"Durazno princesa de hielo seco"},
    {prodId:"50325331",label:"Durazno princesa de marfil seco"},
    {prodId:"50325332",label:"Durazno princesa reina jersey seco"},
    {prodId:"50325333",label:"Durazno john henry seco"},
    {prodId:"50325334",label:"Durazno príncipe de junio seco"},
    {prodId:"50325335",label:"Durazno kaweah seco"},
    {prodId:"50325336",label:"Durazno klondike seco"},
    {prodId:"50325337",label:"Durazno lindo seco"},
    {prodId:"50325338",label:"Durazno loring seco"},
    {prodId:"50325339",label:"Durazno majestuoso seco"},
    {prodId:"50325340",label:"Durazno o'henry seco"},
    {prodId:"50325341",label:"Durazno escudo de reina seco"},
    {prodId:"50325342",label:"Durazno dama roja seco"},
    {prodId:"50325343",label:"Durazno globo rojo seco"},
    {prodId:"50325344",label:"Durazno cielo rojo seco"},
    {prodId:"50325345",label:"Durazno redtop seco"},
    {prodId:"50325346",label:"Durazno regina seco"},
    {prodId:"50325347",label:"Durazno dama rica seco"},
    {prodId:"50325348",label:"Durazno mayo rico seco"},
    {prodId:"50325349",label:"Durazno gloria real seco"},
    {prodId:"50325350",label:"Durazno dama real seco"},
    {prodId:"50325351",label:"Durazno nieve de septiembre seco"},
    {prodId:"50325352",label:"Durazno sol de septiembre seco"},
    {prodId:"50325353",label:"Durazno gema sierra seco"},
    {prodId:"50325354",label:"Durazno angel de nieve seco"},
    {prodId:"50325355",label:"Durazno gema de nieve seco"},
    {prodId:"50325356",label:"Durazno rey de nieve seco"},
    {prodId:"50325357",label:"Durazno dama de primavera seco"},
    {prodId:"50325358",label:"Durazno nieve de primavera seco"},
    {prodId:"50325359",label:"Durazno escudo de primavera seco"},
    {prodId:"50325360",label:"Durazno dulce gigante seco"},
    {prodId:"50325361",label:"Durazno dama de azúcar seco"},
    {prodId:"50325362",label:"Durazno brillo de sol seco"},
    {prodId:"50325363",label:"Durazno sunhigh seco"},
    {prodId:"50325364",label:"Durazno dama super seco"},
    {prodId:"50325365",label:"Durazno super rico seco"},
    {prodId:"50325366",label:"Durazno surecrop seco"},
    {prodId:"50325367",label:"Durazno sueño dulce seco"},
    {prodId:"50325368",label:"Durazno septiembre dulce seco"},
    {prodId:"50325369",label:"Durazno vista seco"},
    {prodId:"50325370",label:"Durazno dama blanca seco"},
    {prodId:"50325371",label:"Durazno dama zee seco"},
    {prodId:"50325400",label:"Peras secas"},
    {prodId:"50325401",label:"Peras abate fetel seca"},
    {prodId:"50325402",label:"Peras anjou seca"},
    {prodId:"50325403",label:"Pera asiática seca"},
    {prodId:"50325404",label:"Pera bartlett seca"},
    {prodId:"50325405",label:"Pera best ever seca"},
    {prodId:"50325406",label:"Pera beth seca"},
    {prodId:"50325407",label:"Pera beurre seca"},
    {prodId:"50325408",label:"Pera bosc seca"},
    {prodId:"50325409",label:"Pera clapp favorita seca"},
    {prodId:"50325410",label:"Pera comice seca"},
    {prodId:"50325411",label:"Pera concorde seca"},
    {prodId:"50325412",label:"Pera conference seca"},
    {prodId:"50325413",label:"Pera crimson rojo seca"},
    {prodId:"50325414",label:"Peras d' anjou seca"},
    {prodId:"50325415",label:"Pera dr jules guyot seca"},
    {prodId:"50325416",label:"Peras early seca"},
    {prodId:"50325417",label:"Peras emperador carmelito seca"},
    {prodId:"50325418",label:"Peras forelle seca"},
    {prodId:"50325419",label:"Pera mantequilla francesa seca"},
    {prodId:"50325420",label:"Pera glou morceau seca"},
    {prodId:"50325421",label:"Pera hosui seca"},
    {prodId:"50325422",label:"Pera mantequilla italiana seca"},
    {prodId:"50325423",label:"Pera jargonelle seca"},
    {prodId:"50325424",label:"Pera juno seca"},
    {prodId:"50325425",label:"Para kaiserlouise bonne de jersey seca"},
    {prodId:"50325426",label:"Pera keiffer seca"},
    {prodId:"50325427",label:"Pera rey royal seca"},
    {prodId:"50325428",label:"Pera limonera seca"},
    {prodId:"50325429",label:"Pera merton pride seca"},
    {prodId:"50325430",label:"Pera mauntain bartlette seca"},
    {prodId:"50325431",label:"Pera oliver de serrers seca"},
    {prodId:"50325432",label:"Pera onward seca"},
    {prodId:"50325433",label:"Pera packham's triumph seca"},
    {prodId:"50325434",label:"Pera paraíso seca"},
    {prodId:"50325435",label:"Pera passe crassane seca"},
    {prodId:"50325436",label:"Pera perry seca"},
    {prodId:"50325437",label:"Pera bartlett rojo seca"},
    {prodId:"50325438",label:"Pera dánjou  rojo seca"},
    {prodId:"50325439",label:"Pera rocha seca"},
    {prodId:"50325440",label:"Pera rosey rojo seca"},
    {prodId:"50325441",label:"Pera rosy roja seca"},
    {prodId:"50325442",label:"Pera majestuosa royal seca"},
    {prodId:"50325443",label:"Pera ruby rojo seca"},
    {prodId:"50325444",label:"Pera santa maria seca"},
    {prodId:"50325445",label:"Pera seckel seca"},
    {prodId:"50325446",label:"Pera sensación seca"},
    {prodId:"50325447",label:"Pera crimson estrella seca"},
    {prodId:"50325448",label:"Pera crimson stark seca"},
    {prodId:"50325449",label:"Pera bartlette de verano seca"},
    {prodId:"50325450",label:"Pera verano dorado seca"},
    {prodId:"50325451",label:"Pera sol dorado seca"},
    {prodId:"50325452",label:"Pera sunprite seca"},
    {prodId:"50325453",label:"Pera taylors dorado seca"},
    {prodId:"50325454",label:"Pera taylors rojo seca"},
    {prodId:"50325455",label:"Pera tientsin seca"},
    {prodId:"50325456",label:"Pera tosca seca"},
    {prodId:"50325457",label:"Pera warden  seca"},
    {prodId:"50325458",label:"Pera williams bon chretien seca"},
    {prodId:"50325459",label:"Pera williams seca"},
    {prodId:"50325460",label:"Pera nelis de invierno seca"},
    {prodId:"50325600",label:"Piñas secas"},
    {prodId:"50325601",label:"Piña chirimoya seca"},
    {prodId:"50325602",label:"Piña dorada seca"},
    {prodId:"50325603",label:"Piña hilo seca"},
    {prodId:"50325604",label:"Piña kona sugarloaf seca"},
    {prodId:"50325605",label:"Piña reina natal seca"},
    {prodId:"50325606",label:"Piña pernabuco seca"},
    {prodId:"50325607",label:"Piña español rojo seca"},
    {prodId:"50325608",label:"Piña cayen suave seca"},
    {prodId:"50325609",label:"Piña sugarloaf seca"},
    {prodId:"50325610",label:"Piña variegated seca"},
    {prodId:"50325800",label:"Ciruelas secas"},
    {prodId:"50325801",label:"Ciruela joya amber seca"},
    {prodId:"50325802",label:"Ciruela angeleno seca"},
    {prodId:"50325803",label:"Ciruela aurora seca"},
    {prodId:"50325804",label:"Ciruela otoño bonito seca"},
    {prodId:"50325805",label:"Ciruela gigante de otoño seca"},
    {prodId:"50325806",label:"Ciruela orgullo de otoño seca"},
    {prodId:"50325807",label:"Ciruela rosa de otoño seca"},
    {prodId:"50325808",label:"Ciruela playero seca"},
    {prodId:"50325809",label:"Ciruela betty anne seca"},
    {prodId:"50325810",label:"Ciruela belleza negra seca"},
    {prodId:"50325811",label:"Ciruela bullase negra seca"},
    {prodId:"50325812",label:"Ciruela diamante negro seca"},
    {prodId:"50325813",label:"Ciruela gigante negro seca"},
    {prodId:"50325814",label:"Ciruela hielo negro seca"},
    {prodId:"50325815",label:"Ciruela esplendor negro seca"},
    {prodId:"50325816",label:"Ciruela ámbar negro seca"},
    {prodId:"50325817",label:"Ciruela vino tinto seca"},
    {prodId:"50325818",label:"Ciruela  carlsbad seca"},
    {prodId:"50325819",label:"Ciruela casselman seca"},
    {prodId:"50325820",label:"Ciruela catalina seca"},
    {prodId:"50325821",label:"Ciruela damson seca"},
    {prodId:"50325822",label:"Ciruela dolly seca"},
    {prodId:"50325823",label:"Ciruela earlireina seca"},
    {prodId:"50325824",label:"Ciruela rosa early seca"},
    {prodId:"50325825",label:"Ciruela ébano mayo seca"},
    {prodId:"50325826",label:"Ciruela ébano seca"},
    {prodId:"50325827",label:"Ciruela corazón de elefante seca"},
    {prodId:"50325828",label:"Ciruela belleza de esmeralda seca"},
    {prodId:"50325829",label:"Ciruela emperatriz seca"},
    {prodId:"50325830",label:"Ciruela libertad seca"},
    {prodId:"50325831",label:"Ciruela friar seca"},
    {prodId:"50325832",label:"Ciruela rojo gar seca"},
    {prodId:"50325833",label:"Ciruela gobernador seca"},
    {prodId:"50325834",label:"Ciruela rosa grande seca"},
    {prodId:"50325835",label:"Ciruela green gage seca"},
    {prodId:"50325836",label:"Ciruela greengage seca"},
    {prodId:"50325837",label:"Ciruela hiromi seca"},
    {prodId:"50325838",label:"Ciruela hiromi rojo seca"},
    {prodId:"50325839",label:"Ciruela vacacion seca"},
    {prodId:"50325840",label:"Ciruela howard sol seca"},
    {prodId:"50325841",label:"Ciruela tipo interspecific seca"},
    {prodId:"50325842",label:"Ciruela jamaico seca"},
    {prodId:"50325843",label:"Ciruela joanna rojo seca"},
    {prodId:"50325844",label:"Ciruela kelsey seca"},
    {prodId:"50325845",label:"Ciruela jaime rey seca"},
    {prodId:"50325846",label:"Ciruela laroda seca"},
    {prodId:"50325847",label:"Ciruela rosa tarde seca"},
    {prodId:"50325848",label:"Ciruela rosa linda seca"},
    {prodId:"50325849",label:"Ciruela estrella solitaria seca"},
    {prodId:"50325850",label:"Ciruela mariposa seca"},
    {prodId:"50325851",label:"Ciruela mercado negro seca"},
    {prodId:"50325852",label:"Ciruela mercado rojo seca"},
    {prodId:"50325853",label:"Ciruela maribel seca"},
    {prodId:"50325854",label:"Ciruelas sol de octubre seca"},
    {prodId:"50325855",label:"Ciruela owen t seca"},
    {prodId:"50325856",label:"Ciruela perdrigon seca"},
    {prodId:"50325857",label:"Ciruela placer rosado seca"},
    {prodId:"50325858",label:"Ciruela  presidente seca"},
    {prodId:"50325859",label:"Ciruela hora prima seca"},
    {prodId:"50325860",label:"Ciruela majestad purpura seca"},
    {prodId:"50325861",label:"Ciruela reina rosa seca"},
    {prodId:"50325862",label:"Ciruela quetsch seca"},
    {prodId:"50325863",label:"Ciruela belleza roja seca"},
    {prodId:"50325864",label:"Ciruela camino rojo seca"},
    {prodId:"50325865",label:"Ciruela ram rojo seca"},
    {prodId:"50325866",label:"Ciruela rosa roja seca"},
    {prodId:"50325867",label:"Ciruela rojo rico seca"},
    {prodId:"50325868",label:"Ciruela romaro seca"},
    {prodId:"50325869",label:"Ciruela diamante rojo seca"},
    {prodId:"50325870",label:"Ciruela rojo royal seca"},
    {prodId:"50325871",label:"Ciruela royal zee seca"},
    {prodId:"50325872",label:"Ciruela roysum seca"},
    {prodId:"50325873",label:"Ciruela santa rosa seca"},
    {prodId:"50325874",label:"Ciruela zafiro seca"},
    {prodId:"50325875",label:"Ciruela sloe seca"},
    {prodId:"50325876",label:"Ciruela sta catherine seca"},
    {prodId:"50325877",label:"Ciruela bullase blanco seca"},
    {prodId:"50326800",label:"Manzanas dulces secas"},
    {prodId:"50326801",label:"Manzana kampong malve dulce seca"},
    {prodId:"50326802",label:"Manzana dulce sin pepa seca"},
    {prodId:"50326803",label:"Manzana dulce thai lessand seca"},
    {prodId:"50327000",label:"Frutas Nominant secas"},
    {prodId:"50327001",label:"Cupania seca"},
    {prodId:"50327002",label:"Babaco seco"},
    {prodId:"50327003",label:"Banana flor seca"},
    {prodId:"50327004",label:"Baobab seca"},
    {prodId:"50327005",label:"Naranja agria seca"},
    {prodId:"50327006",label:"Canistel seca"},
    {prodId:"50327007",label:"Baya nublosa seca"},
    {prodId:"50327008",label:"Coco seco"},
    {prodId:"50327009",label:"Baya drew seca"},
    {prodId:"50327010",label:"Durian seca"},
    {prodId:"50327011",label:"Baya elder seca"},
    {prodId:"50327012",label:"Feijoa seca"},
    {prodId:"50327013",label:"Mora seca"},
    {prodId:"50327014",label:"Cretaegus seca"},
    {prodId:"50327015",label:"Baya miel seca"},
    {prodId:"50327016",label:"Jaca seca"},
    {prodId:"50327017",label:"Jambolan seca"},
    {prodId:"50327018",label:"Jujube seca"},
    {prodId:"50327019",label:"Liches secos"},
    {prodId:"50327020",label:"Mangostinos secos"},
    {prodId:"50327021",label:"Medlar seca"},
    {prodId:"50327022",label:"Mombines seca"},
    {prodId:"50327023",label:"Monstera seca"},
    {prodId:"50327024",label:"Pepinos seco"},
    {prodId:"50327025",label:"Platono seco"},
    {prodId:"50327026",label:"Peras en escabeche seca"},
    {prodId:"50327027",label:"Mamonsillo seco"},
    {prodId:"50327028",label:"Rambután seco"},
    {prodId:"50327029",label:"Manzana rosa seca"},
    {prodId:"50327030",label:"Rosele seca"},
    {prodId:"50327031",label:"Baya rowan seca"},
    {prodId:"50327032",label:"Baya buckhom de mar seca"},
    {prodId:"50327033",label:"Baya plata seca"},
    {prodId:"50327034",label:"Baya sorbete seco"},
    {prodId:"50327035",label:"Guanabana seca"},
    {prodId:"50327036",label:"Manzana estrella seca"},
    {prodId:"50327037",label:"Tamarindo seco"},
    {prodId:"50334500",label:"Mangos orgánicos secos"},
    {prodId:"50334501",label:"Mango alphonso orgánico seco"},
    {prodId:"50334502",label:"Mango ataulfo orgánico seco"},
    {prodId:"50334503",label:"Mango criollo orgánico seco"},
    {prodId:"50334504",label:"Mango edwards orgánico seco"},
    {prodId:"50334505",label:"Mango francine orgánico seco"},
    {prodId:"50334506",label:"Mango francis orgánico seco"},
    {prodId:"50334507",label:"Mango gandaria orgánico seco"},
    {prodId:"50334508",label:"Mango haden orgánico seco"},
    {prodId:"50334509",label:"Mangos irwin orgánico seco"},
    {prodId:"50334510",label:"Mangos keitt orgánico seco"},
    {prodId:"50334511",label:"Mangos kent orgánico seco"},
    {prodId:"50334512",label:"Mangos kesar orgánico seco"},
    {prodId:"50334513",label:"Mangos kuini orgánico seco"},
    {prodId:"50334514",label:"Mangos manila super orgánico seco"},
    {prodId:"50334515",label:"Mangos manila orgánico seco"},
    {prodId:"50334516",label:"Mangos mayaguez orgánico seco"},
    {prodId:"50334517",label:"Mangos mulgoba orgánico seco"},
    {prodId:"50334518",label:"Mangos oro orgánico seco"},
    {prodId:"50334519",label:"Mangos palmer orgánico seco"},
    {prodId:"50334520",label:"Mango parvin orgánico seco"},
    {prodId:"50334521",label:"Mango sandersha orgánico seco"},
    {prodId:"50334522",label:"Mangos sensation orgánico seco"},
    {prodId:"50334523",label:"Mango smith orgánico seco"},
    {prodId:"50334524",label:"Mango tomy atkin orgánico seco"},
    {prodId:"50334525",label:"Mango van dyke orgánico seco"},
    {prodId:"50335300",label:"Duraznos orgánicos secos"},
    {prodId:"50335301",label:"Durazno escudo amber orgánico seco"},
    {prodId:"50335302",label:"Durazno nieve de abril orgánico seco"},
    {prodId:"50335303",label:"Durazno dama de agosto orgánico seco"},
    {prodId:"50335304",label:"Durazno llama de otoño orgánico seco"},
    {prodId:"50335305",label:"Durazno dama de otoño orgánico seco"},
    {prodId:"50335306",label:"Durazno babcock orgánico seco"},
    {prodId:"50335307",label:"Durazno brittany lane orgánico seco"},
    {prodId:"50335308",label:"Durazno cary mac orgánico seco"},
    {prodId:"50335309",label:"Durazno clásica orgánico seco"},
    {prodId:"50335310",label:"Durazno dulce del campo orgánico seco"},
    {prodId:"50335311",label:"Durazno escudo de cielo orgánico seco"},
    {prodId:"50335312",label:"Durazno dama crimson orgánico seco"},
    {prodId:"50335313",label:"Durazno príncipe corona orgánico seco"},
    {prodId:"50335314",label:"Durazno sol david orgánico seco"},
    {prodId:"50335315",label:"Durazno  princesa diamante orgánico seco"},
    {prodId:"50335316",label:"Durazno earlrich orgánico seco"},
    {prodId:"50335317",label:"Durazno majestuosa temprana orgánico seco"},
    {prodId:"50335318",label:"Durazno early treat orgánico seco"},
    {prodId:"50335319",label:"Durazno dama elegante orgánico seco"},
    {prodId:"50335320",label:"Durazno emperatriz orgánico seco"},
    {prodId:"50335321",label:"Durazno encoré orgánico seco"},
    {prodId:"50335322",label:"Durazno dama elegante orgánico seco"},
    {prodId:"50335323",label:"Durazno príncipe de fuego orgánico seco"},
    {prodId:"50335324",label:"Durazno escudo de llama orgánico seco"},
    {prodId:"50335325",label:"Durazno tipo plano orgánico seco"},
    {prodId:"50335326",label:"Durazno escudo de sabor orgánico seco"},
    {prodId:"50335327",label:"Durazno príncipe florida orgánico seco"},
    {prodId:"50335328",label:"Durazno luna llena orgánico seco"},
    {prodId:"50335329",label:"Durazno harvester orgánico seco"},
    {prodId:"50335330",label:"Durazno princesa de hielo orgánico seco"},
    {prodId:"50335331",label:"Durazno princesa de marfil orgánico seco"},
    {prodId:"50335332",label:"Durazno princesa reina jersey orgánico seco"},
    {prodId:"50335333",label:"Durazno john henry orgánico seco"},
    {prodId:"50335334",label:"Durazno príncipe de junio orgánico seco"},
    {prodId:"50335335",label:"Durazno kaweah orgánico seco"},
    {prodId:"50335336",label:"Durazno klondike orgánico seco"},
    {prodId:"50335337",label:"Durazno lindo orgánico seco"},
    {prodId:"50335338",label:"Durazno loring orgánico seco"},
    {prodId:"50335339",label:"Durazno majestuoso orgánico seco"},
    {prodId:"50335340",label:"Durazno o'henry orgánico seco"},
    {prodId:"50335341",label:"Durazno escudo de reina orgánico seco"},
    {prodId:"50335342",label:"Durazno dama roja orgánico seco"},
    {prodId:"50335343",label:"Durazno globo rojo orgánico seco"},
    {prodId:"50335344",label:"Durazno cielo rojo orgánico seco"},
    {prodId:"50335345",label:"Durazno redtop orgánico seco"},
    {prodId:"50335346",label:"Durazno regina orgánico seco"},
    {prodId:"50335347",label:"Durazno dama rica orgánico seco"},
    {prodId:"50335348",label:"Durazno mayo rico orgánico seco"},
    {prodId:"50335349",label:"Durazno gloria royal orgánico seco"},
    {prodId:"50335350",label:"Durazno dama royal orgánico seco"},
    {prodId:"50335351",label:"Durazno nieve de septiembre orgánico seco"},
    {prodId:"50335352",label:"Durazno sol de septiembre orgánico seco"},
    {prodId:"50335353",label:"Durazno gema sierra orgánico seco"},
    {prodId:"50335354",label:"Durazno angel de nieve orgánico seco"},
    {prodId:"50335355",label:"Durazno gema de nieve orgánico seco"},
    {prodId:"50335356",label:"Durazno rey de nieve orgánico seco"},
    {prodId:"50335357",label:"Durazno dama de primavera orgánico seco"},
    {prodId:"50335358",label:"Durazno nieve de primavera orgánico seco"},
    {prodId:"50335359",label:"Durazno escudo de primavera orgánico seco"},
    {prodId:"50335360",label:"Durazno dulce gigante orgánico seco"},
    {prodId:"50335361",label:"Durazno dama de azúcar orgánico seco"},
    {prodId:"50335362",label:"Durazno brillo de sol orgánico seco"},
    {prodId:"50335363",label:"Durazno sunhigh orgánico seco"},
    {prodId:"50335364",label:"Durazno dama super orgánico seco"},
    {prodId:"50335365",label:"Durazno super rico orgánico seco"},
    {prodId:"50335366",label:"Durazno surecrop orgánico seco"},
    {prodId:"50335367",label:"Durazno sueño dulce orgánico seco"},
    {prodId:"50335368",label:"Durazno septiembre dulce orgánico seco"},
    {prodId:"50335369",label:"Durazno vista orgánico seco"},
    {prodId:"50335370",label:"Durazno dama blanca orgánico seco"},
    {prodId:"50335371",label:"Durazno dama zee orgánico seco"},
    {prodId:"50337000",label:"Frutas Nominant orgánicas secas"},
    {prodId:"50337001",label:"Cupania orgánicas secas"},
    {prodId:"50337002",label:"Babaco orgánico seco"},
    {prodId:"50337003",label:"Banana flor orgánicas secas"},
    {prodId:"50337004",label:"Baobab orgánicas secas"},
    {prodId:"50337005",label:"Naranja agria orgánicas secas"},
    {prodId:"50337006",label:"Canistel orgánicas secas"},
    {prodId:"50337007",label:"Baya nublosa orgánicas secas"},
    {prodId:"50337008",label:"Coco orgánico seco"},
    {prodId:"50337009",label:"Baya drew orgánicas secas"},
    {prodId:"50337010",label:"Durian orgánicas secas"},
    {prodId:"50337011",label:"Baya elder orgánicas secas"},
    {prodId:"50337012",label:"Feijoa orgánicas secas"},
    {prodId:"50337013",label:"Mora orgánicas secas"},
    {prodId:"50337014",label:"Cretaegus orgánicas secas"},
    {prodId:"50337015",label:"Baya miel orgánicas secas"},
    {prodId:"50337016",label:"Jaca orgánicas secas"},
    {prodId:"50337017",label:"Jambolan orgánicas secas"},
    {prodId:"50337018",label:"Jujube orgánicas secas"},
    {prodId:"50337019",label:"Liches orgánico secos secos"},
    {prodId:"50337020",label:"Mangostinos orgánico secos secos"},
    {prodId:"50337021",label:"Medlar orgánicas secas"},
    {prodId:"50337022",label:"Mombines orgánicas secas"},
    {prodId:"50337023",label:"Monstera orgánicas secas"},
    {prodId:"50337024",label:"Pepinos orgánico seco"},
    {prodId:"50337025",label:"Platono orgánico seco"},
    {prodId:"50337026",label:"Peras en escabeche orgánicas secas"},
    {prodId:"50337027",label:"Mamonsillo orgánico seco"},
    {prodId:"50337028",label:"Rambután orgánico seco"},
    {prodId:"50337029",label:"Manzana rosa orgánicas secas"},
    {prodId:"50337030",label:"Rosele orgánicas secas"},
    {prodId:"50337031",label:"Baya rowan orgánicas secas"},
    {prodId:"50337032",label:"Baya buckhom de mar orgánicas secas"},
    {prodId:"50337033",label:"Baya plata orgánicas secas"},
    {prodId:"50337034",label:"Baya sorbete orgánico seco"},
    {prodId:"50337035",label:"Wanabana orgánicas secas"},
    {prodId:"50337036",label:"Manzana estrella orgánicas secas"},
    {prodId:"50337037",label:"Tamarindo orgánico seco"},
    {prodId:"50341700",label:"Bananos congelados"},
    {prodId:"50341701",label:"Bananas manzana congeladas"},
    {prodId:"50341702",label:"Bananas bebe congeladas"},
    {prodId:"50341703",label:"Bananas burro congeladas"},
    {prodId:"50341704",label:"Bananas cavendish congeladas"},
    {prodId:"50341705",label:"Bananas dominico congeladas"},
    {prodId:"50341706",label:"Bananas green congeladas"},
    {prodId:"50341707",label:"Bananas gros michel congeladas"},
    {prodId:"50341708",label:"Bananas lacatan congeladas"},
    {prodId:"50341709",label:"Bananas dedo de dama congeladas"},
    {prodId:"50341710",label:"Bananas manzano congeladas"},
    {prodId:"50341711",label:"Bananas mysore congeladas"},
    {prodId:"50341712",label:"Bananas pisang mas congeladas"},
    {prodId:"50341713",label:"Bananas rojo congeladas"},
    {prodId:"50341714",label:"Bananas saba congeladas"},
    {prodId:"50341715",label:"Bananas sucrier congeladas"},
    {prodId:"50342000",label:"Moras congeladas"},
    {prodId:"50342001",label:"Mora apache congeladas"},
    {prodId:"50342002",label:"Mora negro satín congeladas"},
    {prodId:"50342003",label:"Mora congeladas"},
    {prodId:"50342004",label:"Mora cherokee congeladas"},
    {prodId:"50342005",label:"Mora chester congeladas"},
    {prodId:"50342006",label:"Mora dirksen congeladas"},
    {prodId:"50342007",label:"Bayas josta congeladas"},
    {prodId:"50342008",label:"Bayas logan congeladas"},
    {prodId:"50342009",label:"Bayas marion congeladas"},
    {prodId:"50342010",label:"Mora navaho congeladas"},
    {prodId:"50342011",label:"Bayanectar congeladas"},
    {prodId:"50342012",label:"Mora olallie congeladas"},
    {prodId:"50342013",label:"Bayatay congeladas"},
    {prodId:"50342014",label:"Mora thomless hull congeladas"},
    {prodId:"50342015",label:"Bayayoung congeladas"},
    {prodId:"50343700",label:"Guayabas congeladas"},
    {prodId:"50343701",label:"Guayaba beaumont congeladas"},
    {prodId:"50343702",label:"Guayaba carrley congeladas"},
    {prodId:"50343703",label:"Guayaba lucida congeladas"},
    {prodId:"50343704",label:"Guayaba piña congeladas"},
    {prodId:"50343800",label:"Arándanos Huckleberry congelados"},
    {prodId:"50343801",label:"Arándano invierno negro congelado"},
    {prodId:"50343802",label:"Arándano cascada congelado"},
    {prodId:"50343803",label:"Arándano enano congelado"},
    {prodId:"50343804",label:"Arándano montaña congelado"},
    {prodId:"50343805",label:"Arándano rojo congelado"},
    {prodId:"50344500",label:"Mangos congelados"},
    {prodId:"50344501",label:"Mango alphonso congelado"},
    {prodId:"50344502",label:"Mango ataulfo congelado"},
    {prodId:"50344503",label:"Mango criollo congelado"},
    {prodId:"50344504",label:"Mango edwards congelado"},
    {prodId:"50344505",label:"Mango francine congelado"},
    {prodId:"50344506",label:"Mango francis congelado"},
    {prodId:"50344507",label:"Mango gandaria congelado"},
    {prodId:"50344508",label:"Mango haden congelado"},
    {prodId:"50344509",label:"Mangos irwin congelado"},
    {prodId:"50344510",label:"Mangos keitt congelado"},
    {prodId:"50344511",label:"Mangos kent congelado"},
    {prodId:"50344512",label:"Mangos kesar congelado"},
    {prodId:"50344513",label:"Mangos kuini congelado"},
    {prodId:"50344514",label:"Mangos manila super congelado"},
    {prodId:"50344515",label:"Mangos manila congelado"},
    {prodId:"50344516",label:"Mangos mayaguez congelado"},
    {prodId:"50344517",label:"Mangos mulgoba congelado"},
    {prodId:"50344518",label:"Mangos oro congelado"},
    {prodId:"50344519",label:"Mangos palmer congelado"},
    {prodId:"50344520",label:"Mango parvin congelado"},
    {prodId:"50344521",label:"Mango sandersha congelado"},
    {prodId:"50344522",label:"Mangos sensation congelado"},
    {prodId:"50344523",label:"Mango smith congelado"},
    {prodId:"50344524",label:"Mango tomy atkin congelado"},
    {prodId:"50344525",label:"Mango van dyke congelado"},
    {prodId:"50344600",label:"Melones congelados"},
    {prodId:"50344601",label:"Melon allsweet congelado"},
    {prodId:"50344602",label:"Melón athens congelado"},
    {prodId:"50344603",label:"Melón diamante negro congelado"},
    {prodId:"50344604",label:"Melón cal sweet congelado"},
    {prodId:"50344605",label:"Melons cantaloupe congelado"},
    {prodId:"50344606",label:"Melón carnical congelado"},
    {prodId:"50344607",label:"Melón casaba congelado"},
    {prodId:"50344608",label:"Melón cavaillon congelado"},
    {prodId:"50344609",label:"Melón charentais congelado"},
    {prodId:"50344610",label:"Sandia charleston gray congelado"},
    {prodId:"50344611",label:"Melón crenshaw congelado"},
    {prodId:"50344612",label:"Melón crimson sweet congelado"},
    {prodId:"50344613",label:"Melón dixie lee congelado"},
    {prodId:"50344614",label:"Melón eclipse congelado"},
    {prodId:"50344615",label:"Melón ein dór  congelado"},
    {prodId:"50344616",label:"Melón fiesta congelado"},
    {prodId:"50344617",label:"Melón galia congelado"},
    {prodId:"50344618",label:"Melón gaya congelado"},
    {prodId:"50344619",label:"Melón hami congelado"},
    {prodId:"50344620",label:"Melón miel dew congelado"},
    {prodId:"50344621",label:"Melón hielo congelado"},
    {prodId:"50344622",label:"Melón ida orgullo congelado"},
    {prodId:"50344623",label:"Melón juan canary congelado"},
    {prodId:"50344624",label:"Melón jubileo congelado"},
    {prodId:"50344625",label:"Melón jubilación congelado"},
    {prodId:"50344626",label:"Melón kaki / kakri congelado"},
    {prodId:"50344627",label:"Melón kiwano congelado"},
    {prodId:"50344628",label:"Melon koreano congelado"},
    {prodId:"50344629",label:"Melón long grey congelado"},
    {prodId:"50344630",label:"Melón mayan congelado"},
    {prodId:"50344631",label:"Melón micky lee congelado"},
    {prodId:"50344632",label:"Melón mirage congelado"},
    {prodId:"50344633",label:"Sandia luna y estrellas congelado"},
    {prodId:"50344634",label:"Melón ogen congelado"},
    {prodId:"50344635",label:"Melón patriot congelado"},
    {prodId:"50344636",label:"Melón pavo real congelado"},
    {prodId:"50344637",label:"Melón pepino congelado"},
    {prodId:"50344638",label:"Melón persian congelado"},
    {prodId:"50344639",label:"Melón picnic congelado"},
    {prodId:"50344640",label:"Melón piel de sapo congelado"},
    {prodId:"50344641",label:"Melón piña congelado"},
    {prodId:"50344642",label:"Melón quetzali congelado"},
    {prodId:"50344643",label:"Melón goblin rojo congelado"},
    {prodId:"50344644",label:"Melón regency congelado"},
    {prodId:"50344645",label:"Melón royal mejestic congelado"},
    {prodId:"50344646",label:"Melón royal star congelado"},
    {prodId:"50344647",label:"Melón dulce royal congelado"},
    {prodId:"50344648",label:"Malon santa claus congelado"},
    {prodId:"50344649",label:"Melón sharlyn congelado"},
    {prodId:"50344650",label:"Melón español congelado"},
    {prodId:"50344651",label:"Melón sprite congelado"},
    {prodId:"50344652",label:"Melón starbright congelado"},
    {prodId:"50344653",label:"Melón stars and stripes congelado"},
    {prodId:"50344654",label:"Melón bebe de azúcar congelado"},
    {prodId:"50344655",label:"Sandia bebe de azúcar congeladas"},
    {prodId:"50344656",label:"Melón dulce sol congelado"},
    {prodId:"50344657",label:"Sandia corazón dulce sin semilla congeladas"},
    {prodId:"50344658",label:"Melón tentación congelado"},
    {prodId:"50344659",label:"Melón tigre bebe congelado"},
    {prodId:"50344660",label:"Melón tuscan tipo congelado"},
    {prodId:"50344661",label:"Sandia bebe amarillo congeladas"},
    {prodId:"50344700",label:"Moras mulberry congeladas"},
    {prodId:"50344701",label:"Mora negra congeladas"},
    {prodId:"50344702",label:"Mora blanca congeladas"},
    {prodId:"50345100",label:"Papayas congeladas"},
    {prodId:"50345101",label:"Papaya verde cocinar congeladas"},
    {prodId:"50345102",label:"Papaya maradol congeladas"},
    {prodId:"50345103",label:"Papaya amarillo mexicano congeladas"},
    {prodId:"50345104",label:"Papaya montaña congeladas"},
    {prodId:"50345105",label:"Papaya solo congeladas"},
    {prodId:"50345106",label:"Papaya tainung congeladas"},
    {prodId:"50345300",label:"Duraznos congelados"},
    {prodId:"50345301",label:"Durazno escudo amber congelado"},
    {prodId:"50345302",label:"Durazno nieve de abril congelado"},
    {prodId:"50345303",label:"Durazno dama de agosto congelado"},
    {prodId:"50345304",label:"Durazno llama de otoño congelado"},
    {prodId:"50345305",label:"Durazno dama de otoño congelado"},
    {prodId:"50345306",label:"Durazno babcock congelado"},
    {prodId:"50345307",label:"Durazno brittany lane congelado"},
    {prodId:"50345308",label:"Durazno cary mac congelado"},
    {prodId:"50345309",label:"Durazno clásica congelado"},
    {prodId:"50345310",label:"Durazno dulce del campo congelado"},
    {prodId:"50345311",label:"Durazno escudo de cielo congelado"},
    {prodId:"50345312",label:"Durazno dama crimson congelado"},
    {prodId:"50345313",label:"Durazno príncipe corona congelado"},
    {prodId:"50345314",label:"Durazno sol david congelado"},
    {prodId:"50345315",label:"Durazno  princesa diamante congelado"},
    {prodId:"50345316",label:"Durazno earlrich congelado"},
    {prodId:"50345317",label:"Durazno majestuosa temprana congelado"},
    {prodId:"50345318",label:"Durazno early treat congelado"},
    {prodId:"50345319",label:"Durazno dama elegante congelado"},
    {prodId:"50345320",label:"Durazno emperatriz congelado"},
    {prodId:"50345321",label:"Durazno encoré congelado"},
    {prodId:"50345322",label:"Durazno dama elegante congelado"},
    {prodId:"50345323",label:"Durazno príncipe de fuego congelado"},
    {prodId:"50345324",label:"Durazno escudo de llama congelado"},
    {prodId:"50345325",label:"Durazno tipo plano congelado"},
    {prodId:"50345326",label:"Durazno escudo de sabor congelado"},
    {prodId:"50345327",label:"Durazno príncipe florida congelado"},
    {prodId:"50345328",label:"Durazno luna llena congelado"},
    {prodId:"50345329",label:"Durazno harvester congelado"},
    {prodId:"50345330",label:"Durazno princesa de hielo congelado"},
    {prodId:"50345331",label:"Durazno princesa de marfil congelado"},
    {prodId:"50345332",label:"Durazno princesa reina jersey congelado"},
    {prodId:"50345333",label:"Durazno john henry congelado"},
    {prodId:"50345334",label:"Durazno príncipe de junio congelado"},
    {prodId:"50345335",label:"Durazno kaweah congelado"},
    {prodId:"50345336",label:"Durazno klondike congelado"},
    {prodId:"50345337",label:"Durazno lindo congelado"},
    {prodId:"50345338",label:"Durazno loring congelado"},
    {prodId:"50345339",label:"Durazno majestuoso congelado"},
    {prodId:"50345340",label:"Durazno o'henry congelado"},
    {prodId:"50345341",label:"Durazno escudo de reina congelado"},
    {prodId:"50345342",label:"Durazno dama roja congelado"},
    {prodId:"50345343",label:"Durazno globo rojo congelado"},
    {prodId:"50345344",label:"Durazno cielo rojo congelado"},
    {prodId:"50345345",label:"Durazno redtop congelado"},
    {prodId:"50345346",label:"Durazno regina congelado"},
    {prodId:"50345347",label:"Durazno dama rica congelado"},
    {prodId:"50345348",label:"Durazno mayo rico vo"},
    {prodId:"50345349",label:"Durazno gloria royal congelado"},
    {prodId:"50345350",label:"Durazno dama royal congelado"},
    {prodId:"50345351",label:"Durazno nieve de septiembre congelado"},
    {prodId:"50345352",label:"Durazno sol de septiembre congelado"},
    {prodId:"50345353",label:"Durazno gema sierra congelado"},
    {prodId:"50345354",label:"Durazno angel de nieve congelado"},
    {prodId:"50345355",label:"Durazno gema de nieve congelado"},
    {prodId:"50345356",label:"Durazno rey de nieve congelado"},
    {prodId:"50345357",label:"Durazno dama de primavera congelado"},
    {prodId:"50345358",label:"Durazno nieve de primavera congelado"},
    {prodId:"50345359",label:"Durazno escudo de primavera congelado"},
    {prodId:"50345360",label:"Durazno dulce gigante congelado"},
    {prodId:"50345361",label:"Durazno dama de azúcar congelado"},
    {prodId:"50345362",label:"Durazno brillo de sol congelado"},
    {prodId:"50345363",label:"Durazno sunhigh congelado"},
    {prodId:"50345364",label:"Durazno dama super congelado"},
    {prodId:"50345365",label:"Durazno super rico congelado"},
    {prodId:"50345366",label:"Durazno surecrop congelado"},
    {prodId:"50345367",label:"Durazno sueño dulce congelado"},
    {prodId:"50345368",label:"Durazno septiembre dulce congelado"},
    {prodId:"50345369",label:"Durazno vista congelado"},
    {prodId:"50345370",label:"Durazno dama blanca congelado"},
    {prodId:"50345371",label:"Durazno dama zee congelado"},
    {prodId:"50345600",label:"Piñas congeladas"},
    {prodId:"50345601",label:"Piña chirimoya congeladas"},
    {prodId:"50345602",label:"Piña dorada congeladas"},
    {prodId:"50345603",label:"Piña hilo congeladas"},
    {prodId:"50345604",label:"Piña kona sugarloaf congeladas"},
    {prodId:"50345605",label:"Piña reina natal congeladas"},
    {prodId:"50345606",label:"Piña pernabuco congeladas"},
    {prodId:"50345607",label:"Piña español rojo congeladas"},
    {prodId:"50345608",label:"Piña cayen suave congeladas"},
    {prodId:"50345609",label:"Piña sugarloaf congeladas"},
    {prodId:"50345610",label:"Piña variegated congeladas"},
    {prodId:"50346200",label:"Frambuesas congeladas"},
    {prodId:"50346201",label:"Frambuesa americana roja congeladas"},
    {prodId:"50346202",label:"Frambuesa bailey queensland congeladas"},
    {prodId:"50346203",label:"Frambuesa negra congeladas"},
    {prodId:"50346204",label:"Frambuesa oscura congeladas"},
    {prodId:"50346205",label:"Frambuesa deliciosa congeladas"},
    {prodId:"50346206",label:"Frambuesa enano focke congeladas"},
    {prodId:"50346207",label:"Frambuesa focke hojagris congeladas"},
    {prodId:"50346208",label:"Frambuesa focke fresa congeladas"},
    {prodId:"50346209",label:"Frambuesa  focke amarillo himalaya congeladas"},
    {prodId:"50346210",label:"Frambuesa dorado congelado"},
    {prodId:"50346211",label:"Frambuesa gris nuevo mexico congeladas"},
    {prodId:"50346212",label:"Frambuesa jepson blancobark congeladas"},
    {prodId:"50346213",label:"Frambuesa kellogs san diego congeladas"},
    {prodId:"50346214",label:"Frambuesa leucodemis blancobark congeladas"},
    {prodId:"50346215",label:"Frambuesa munz cuyamaca congeladas"},
    {prodId:"50346216",label:"Frambuesa peck bartons congeladas"},
    {prodId:"50346217",label:"Frambuesa flor morada congeladas"},
    {prodId:"50346218",label:"Frambuesa roadside congeladas"},
    {prodId:"50346219",label:"Frambuesa san diego congeladas"},
    {prodId:"50346220",label:"Frambuesa nieve congeladas"},
    {prodId:"50346221",label:"Frambuesa pico de nieve congeladas"},
    {prodId:"50346222",label:"Frambuesa hoja de fresa congeladas"},
    {prodId:"50346223",label:"Frambuesa cultivo dulce congeladas"},
    {prodId:"50346224",label:"Frambuesa tor y gris blancobark congeladas"},
    {prodId:"50346225",label:"Frambuesa caribe congeladas"},
    {prodId:"50346226",label:"Frambuesa blancobark congeladas"},
    {prodId:"50346227",label:"Frambuesa vino congeladas"},
    {prodId:"50346228",label:"Frambuesa himalaya amarillo congeladas"},
    {prodId:"50346229",label:"Frambuesa yu-shan congeladas"},
    {prodId:"50346700",label:"Fresas congeladas"},
    {prodId:"50346701",label:"Fresa chandler congeladas"},
    {prodId:"50346702",label:"Fresa rumbo junio congeladas"},
    {prodId:"50346703",label:"Fresa rumbo siempre congeladas"},
    {prodId:"50351700",label:"Bananos orgánicos congelados"},
    {prodId:"50351701",label:"Bananas manzana congelada orgánica"},
    {prodId:"50351702",label:"Bananas bebe congelada orgánica"},
    {prodId:"50351703",label:"Bananas burro congelada orgánica"},
    {prodId:"50351704",label:"Bananas cavendish congelada orgánica"},
    {prodId:"50351705",label:"Bananas dominico congelada orgánica"},
    {prodId:"50351706",label:"Bananas verde congelada orgánica"},
    {prodId:"50351707",label:"Bananas gros michel congelada orgánica"},
    {prodId:"50351708",label:"Bananas lacatan congelada orgánica"},
    {prodId:"50351709",label:"Bananas dedo de dama congelada orgánica"},
    {prodId:"50351710",label:"Bananas manzano congelada orgánica"},
    {prodId:"50351711",label:"Bananas mysore congelada orgánica"},
    {prodId:"50351712",label:"Bananas pisang mas congelada orgánica"},
    {prodId:"50351713",label:"Bananas roja congelada orgánica"},
    {prodId:"50351714",label:"Bananas saba congelada orgánica"},
    {prodId:"50351715",label:"Bananas sucrier congelada orgánica"},
    {prodId:"50354500",label:"Mangos orgánicos congelados"},
    {prodId:"50354501",label:"Mango alphonso congelado orgánico"},
    {prodId:"50354502",label:"Mango ataulfo congelado orgánico"},
    {prodId:"50354503",label:"Mango criollo congelado orgánico"},
    {prodId:"50354504",label:"Mango edwards congelado orgánico"},
    {prodId:"50354505",label:"Mango francine congelado orgánico"},
    {prodId:"50354506",label:"Mango francis congelado orgánico"},
    {prodId:"50354507",label:"Mango gandaria congelado orgánico"},
    {prodId:"50354508",label:"Mango haden congelado orgánico"},
    {prodId:"50354509",label:"Mangos irwin congelado orgánico"},
    {prodId:"50354510",label:"Mangos keitt congelado orgánico"},
    {prodId:"50354511",label:"Mangos kent congelado orgánico"},
    {prodId:"50354512",label:"Mangos kesar congelado orgánico"},
    {prodId:"50354513",label:"Mangos kuini congelado orgánico"},
    {prodId:"50354514",label:"Mangos manila super congelado orgánico"},
    {prodId:"50354515",label:"Mangos manila congelado orgánico"},
    {prodId:"50354516",label:"Mangos mayaguez congelado orgánico"},
    {prodId:"50354517",label:"Mangos mulgoba congelado orgánico"},
    {prodId:"50354518",label:"Mangos oro congelado orgánico"},
    {prodId:"50354519",label:"Mangos palmer congelado orgánico"},
    {prodId:"50354520",label:"Mango parvin congelado orgánico"},
    {prodId:"50354521",label:"Mango sandersha congelado orgánico"},
    {prodId:"50354522",label:"Mangos sensation congelado orgánico"},
    {prodId:"50354523",label:"Mango smith congelado orgánico"},
    {prodId:"50354524",label:"Mango tomy atkin congelado orgánico"},
    {prodId:"50354525",label:"Mango van dyke congelado orgánico"},
    {prodId:"50356700",label:"Fresas orgánicas congeladas"},
    {prodId:"50356701",label:"Fresa chandler congelada orgánica"},
    {prodId:"50356702",label:"Fresa rumbo junio congelada orgánica"},
    {prodId:"50356703",label:"Fresa rumbo siempre congelada orgánica"},
    {prodId:"50361600",label:"Albaricoques en lata o en frasco"},
    {prodId:"50361601",label:"Abaricoques ambercot en lata o en frasco"},
    {prodId:"50361602",label:"Albaricoques apache en lata o en frasco"},
    {prodId:"50361603",label:"Albaricoques brittany dorado en lata o en frasco"},
    {prodId:"50361604",label:"Albaricoque black en lata o en frasco"},
    {prodId:"50361605",label:"Albaricoque blenheim en lata o en frasco"},
    {prodId:"50361606",label:"Albaricoque bonny en lata o en frasco"},
    {prodId:"50361607",label:"Albaricoque biluda en lata o en frasco"},
    {prodId:"50361608",label:"Albaricoque castlebrite en lata o en frasco"},
    {prodId:"50361609",label:"Albaricoque  clutha gold en lata o en frasco"},
    {prodId:"50361610",label:"Albaricoque clutha sun en lata o en frasco"},
    {prodId:"50361611",label:"Albaricoque derby royal en lata o en frasco"},
    {prodId:"50361612",label:"Albaricoque dina en lata o en frasco"},
    {prodId:"50361613",label:"Albaricoque earlicot en lata o en frasco"},
    {prodId:"50361614",label:"Albaricoque earliman en lata o en frasco"},
    {prodId:"50361615",label:"Albaricoque early bright en lata o en frasco"},
    {prodId:"50361616",label:"Albaricoque  dorado en llamas en lata o en frasco"},
    {prodId:"50361617",label:"Albaricoque frenso en lata o en frasco"},
    {prodId:"50361618",label:"Albaricoque dorado claro en lata o en frasco"},
    {prodId:"50361619",label:"Albaricoque barra dorada en lata o en frasco"},
    {prodId:"50361620",label:"Albaricoque dorada dulce en lata o en frasco"},
    {prodId:"50361621",label:"Albaricoque dorada rica en lata o en frasco"},
    {prodId:"50361622",label:"Albaricoque helena en lata o en frasco"},
    {prodId:"50361623",label:"Albaricoque honeycot en lata o en frasco"},
    {prodId:"50361624",label:"Albaricoque imperial en lata o en frasco"},
    {prodId:"50361625",label:"Albaricoque jordanne en lata o en frasco"},
    {prodId:"50361626",label:"Albaricoque jumbo cot en lata o en frasco"},
    {prodId:"50361627",label:"Albaricoque kandy kot en lata o en frasco"},
    {prodId:"50361628",label:"Albaricoque katy en lata o en frasco"},
    {prodId:"50361629",label:"Albaricoque rey en lata o en frasco"},
    {prodId:"50361630",label:"Albaricoque lambertin en lata o en frasco"},
    {prodId:"50361631",label:"Albaricoque loma en lata o en frasco"},
    {prodId:"50361632",label:"Albaricoque lulu belle en lata o en frasco"},
    {prodId:"50361633",label:"Albaricoque modesto en lata o en frasco"},
    {prodId:"50361634",label:"Albaricoque moorpark en lata o en frasco"},
    {prodId:"50361635",label:"Albaricoque orangered en lata o en frasco"},
    {prodId:"50361636",label:"Albaricoque palstein en lata o en frasco"},
    {prodId:"50361637",label:"Albaricoque patterson en lata o en frasco"},
    {prodId:"50361638",label:"Albaricoque perfection en lata o en frasco"},
    {prodId:"50361639",label:"Albaricoque poppy en lata o en frasco"},
    {prodId:"50361640",label:"Albaricoque poppycot en lata o en frasco"},
    {prodId:"50361641",label:"Albaricoque reina en lata o en frasco"},
    {prodId:"50361642",label:"Albaricoque riland en lata o en frasco"},
    {prodId:"50361643",label:"Albaricoque rival en lata o en frasco"},
    {prodId:"50361644",label:"Albaricoque robada en lata o en frasco"},
    {prodId:"50361645",label:"Albaricoque royal en lata o en frasco"},
    {prodId:"50361646",label:"Albaricoque  royal blenheim en lata o en frasco"},
    {prodId:"50361647",label:"Albaricoque  royal orange en lata o en frasco"},
    {prodId:"50361648",label:"Albaricoque gota de sol en lata o en frasco"},
    {prodId:"50361649",label:"Albaricoque tilton en lata o en frasco"},
    {prodId:"50361650",label:"Albaricoque tomcot en lata o en frasco"},
    {prodId:"50361651",label:"Albaricoque tracy en lata o en frasco"},
    {prodId:"50361652",label:"Albaricoque tri gem en lata o en frasco"},
    {prodId:"50361653",label:"Albaricoque valle dorado en lata o en frasco"},
    {prodId:"50361654",label:"Albaricoque westley en lata o en frasco"},
    {prodId:"50361655",label:"Albaricoque  york en lata o en frasco"},
    {prodId:"50362500",label:"Cerezas en lata o en frasco"},
    {prodId:"50362501",label:"Cereza amarelle  en lata o en frasco"},
    {prodId:"50362502",label:"Cereza brooks en lata o en frasco"},
    {prodId:"50362503",label:"Cereza bigarreu en lata o en frasco"},
    {prodId:"50362504",label:"Cereza bing en lata o en frasco"},
    {prodId:"50362505",label:"Cereza blach republic en lata o en frasco"},
    {prodId:"50362506",label:"Cereza negra schmidt en lata o en frasco"},
    {prodId:"50362507",label:"Cereza blacktartarian en lata o en frasco"},
    {prodId:"50362508",label:"Cereza fiesta bing en lata o en frasco"},
    {prodId:"50362509",label:"Cereza garnet en lata o en frasco"},
    {prodId:"50362510",label:"Cereza king en lata o en frasco"},
    {prodId:"50362511",label:"Cereza champagne en lata o en frasco"},
    {prodId:"50362512",label:"Cereza lapin en lata o en frasco"},
    {prodId:"50362513",label:"Cereza larian en lata o en frasco"},
    {prodId:"50362514",label:"Cereza dark guines en lata o en frasco"},
    {prodId:"50362515",label:"Cereza montmorency en lata o en frasco"},
    {prodId:"50362516",label:"Cereza duque en lata o en frasco"},
    {prodId:"50362517",label:"Cereza early rivers en lata o en frasco"},
    {prodId:"50362518",label:"Cereza ruby bing en lata o en frasco"},
    {prodId:"50362519",label:"Cereza santine en lata o en frasco"},
    {prodId:"50362520",label:"Cereza geans/guines en lata o en frasco"},
    {prodId:"50362521",label:"Cereza sonata en lata o en frasco"},
    {prodId:"50362522",label:"Cereza lambert en lata o en frasco"},
    {prodId:"50362523",label:"Cereza stella en lata o en frasco"},
    {prodId:"50362524",label:"Cereza sweethart en lata o en frasco"},
    {prodId:"50362525",label:"Cereza tartarian en lata o en frasco"},
    {prodId:"50362527",label:"Cereza maraschino en lata o en frasco"},
    {prodId:"50362528",label:"Cereza van en lata o en frasco"},
    {prodId:"50362529",label:"Cereza morello en lata o en frasco"},
    {prodId:"50362530",label:"Cereza royal ann en lata o en frasco"},
    {prodId:"50362531",label:"Cereza ranier en lata o en frasco"},
    {prodId:"50362532",label:"Cereza royal en lata o en frasco"},
    {prodId:"50363700",label:"Guayabas en lata o en frasco"},
    {prodId:"50363701",label:"Guayabas beaumont en lata o en frasco"},
    {prodId:"50363702",label:"Guayabas carrley en lata o en frasco"},
    {prodId:"50363703",label:"Guayabas lucida en lata o en frasco"},
    {prodId:"50363704",label:"Guayaba piña en lata o en frasco"},
    {prodId:"50364500",label:"Mangos en lata o en frasco"},
    {prodId:"50364501",label:"Mango alphonso en lata o en frasco"},
    {prodId:"50364502",label:"Mango ataulfo en lata o en frasco"},
    {prodId:"50364503",label:"Mango criollo en lata o en frasco"},
    {prodId:"50364504",label:"Mango edwards en lata o en frasco"},
    {prodId:"50364505",label:"Mango francine en lata o en frasco"},
    {prodId:"50364506",label:"Mango francis en lata o en frasco"},
    {prodId:"50364507",label:"Mango gandaria en lata o en frasco"},
    {prodId:"50364508",label:"Mango haden en lata o en frasco"},
    {prodId:"50364509",label:"Mangos irwin en lata o en frasco"},
    {prodId:"50364510",label:"Mangos keitt en lata o en frasco"},
    {prodId:"50364511",label:"Mangos kent en lata o en frasco"},
    {prodId:"50364512",label:"Mangos kesar en lata o en frasco"},
    {prodId:"50364513",label:"Mangos kuini en lata o en frasco"},
    {prodId:"50364514",label:"Mangos manila super en lata o en frasco"},
    {prodId:"50364515",label:"Mangos manila en lata o en frasco"},
    {prodId:"50364516",label:"Mangos mayaguez en lata o en frasco"},
    {prodId:"50364517",label:"Mangos mulgoba en lata o en frasco"},
    {prodId:"50364518",label:"Mangos oro en lata o en frasco"},
    {prodId:"50364519",label:"Mangos palmer en lata o en frasco"},
    {prodId:"50364520",label:"Mango parvin en lata o en frasco"},
    {prodId:"50364521",label:"Mango sandersha en lata o en frasco"},
    {prodId:"50364522",label:"Mangos sensation en lata o en frasco"},
    {prodId:"50364523",label:"Mango smith en lata o en frasco"},
    {prodId:"50364524",label:"Mango tomy atkin en lata o en frasco"},
    {prodId:"50364525",label:"Mango van dyke en lata o en frasco"},
    {prodId:"50365300",label:"Duraznos en lata o en frasco"},
    {prodId:"50365301",label:"Durazno escudo amber en lata o en frasco"},
    {prodId:"50365302",label:"Durazno nieve de abril en lata o en frasco"},
    {prodId:"50365303",label:"Durazno dama de agosto en lata o en frasco"},
    {prodId:"50365304",label:"Durazno llama de otoño en lata o en frasco"},
    {prodId:"50365305",label:"Durazno dama de otoño en lata o en frasco"},
    {prodId:"50365306",label:"Durazno babcock en lata o en frasco"},
    {prodId:"50365307",label:"Durazno brittany lane en lata o en frasco"},
    {prodId:"50365308",label:"Durazno cary mac en lata o en frasco"},
    {prodId:"50365309",label:"Durazno clásica en lata o en frasco"},
    {prodId:"50365310",label:"Durazno dulce del campo en lata o en frasco"},
    {prodId:"50365311",label:"Durazno escudo de cielo en lata o en frasco"},
    {prodId:"50365312",label:"Durazno dama crimson en lata o en frasco"},
    {prodId:"50365313",label:"Durazno príncipe corona en lata o en frasco"},
    {prodId:"50365314",label:"Durazno sol david en lata o en frasco"},
    {prodId:"50365315",label:"Durazno  princesa diamante en lata o en frasco"},
    {prodId:"50365316",label:"Durazno earlrich en lata o en frasco"},
    {prodId:"50365317",label:"Durazno majestuosa temprana en lata o en frasco"},
    {prodId:"50365318",label:"Durazno early treat en lata o en frasco"},
    {prodId:"50365319",label:"Durazno dama elegante en lata o en frasco"},
    {prodId:"50365320",label:"Durazno emperatriz en lata o en frasco"},
    {prodId:"50365321",label:"Durazno encoré en lata o en frasco"},
    {prodId:"50365322",label:"Durazno dama elegante en lata o en frasco"},
    {prodId:"50365323",label:"Durazno príncipe de fuego en lata o en frasco"},
    {prodId:"50365324",label:"Durazno escudo de llama en lata o en frasco"},
    {prodId:"50365325",label:"Durazno tipo plano en lata o en frasco"},
    {prodId:"50365326",label:"Durazno escudo de sabor en lata o en frasco"},
    {prodId:"50365327",label:"Durazno príncipe florida en lata o en frasco"},
    {prodId:"50365328",label:"Durazno luna llena en lata o en frasco"},
    {prodId:"50365329",label:"Durazno harvester en lata o en frasco"},
    {prodId:"50365330",label:"Durazno princesa de hielo en lata o en frasco"},
    {prodId:"50365331",label:"Durazno princesa de marfil en lata o en frasco"},
    {prodId:"50365332",label:"Durazno princesa reina jersey en lata o en frasco"},
    {prodId:"50365333",label:"Durazno john henry en lata o en frasco"},
    {prodId:"50365334",label:"Durazno príncipe de junio en lata o en frasco"},
    {prodId:"50365335",label:"Durazno kaweah en lata o en frasco"},
    {prodId:"50365336",label:"Durazno klondike en lata o en frasco"},
    {prodId:"50365337",label:"Durazno lindo en lata o en frasco"},
    {prodId:"50365338",label:"Durazno loring en lata o en frasco"},
    {prodId:"50365339",label:"Durazno majestuoso en lata o en frasco"},
    {prodId:"50365340",label:"Durazno o'henry en lata o en frasco"},
    {prodId:"50365341",label:"Durazno escudo de reina en lata o en frasco"},
    {prodId:"50365342",label:"Durazno dama roja en lata o en frasco"},
    {prodId:"50365343",label:"Durazno globo rojo en lata o en frasco"},
    {prodId:"50365344",label:"Durazno cielo rojo en lata o en frasco"},
    {prodId:"50365345",label:"Durazno redtop en lata o en frasco"},
    {prodId:"50365346",label:"Durazno regina en lata o en frasco"},
    {prodId:"50365347",label:"Durazno dama rica en lata o en frasco"},
    {prodId:"50365348",label:"Durazno mayo rico en lata o en frasco"},
    {prodId:"50365349",label:"Durazno gloria royal en lata o en frasco"},
    {prodId:"50365350",label:"Durazno dama royal en lata o en frasco"},
    {prodId:"50365351",label:"Durazno nieve de septiembre en lata o en frasco"},
    {prodId:"50365352",label:"Durazno sol de septiembre en lata o en frasco"},
    {prodId:"50365353",label:"Durazno gema sierra en lata o en frasco"},
    {prodId:"50365354",label:"Durazno angel de nieve en lata o en frasco"},
    {prodId:"50365355",label:"Durazno gema de nieve en lata o en frasco"},
    {prodId:"50365356",label:"Durazno rey de nieve en lata o en frasco"},
    {prodId:"50365357",label:"Durazno dama de primavera en lata o en frasco"},
    {prodId:"50365358",label:"Durazno nieve de primavera en lata o en frasco"},
    {prodId:"50365359",label:"Durazno escudo de primavera en lata o en frasco"},
    {prodId:"50365360",label:"Durazno dulce gigante en lata o en frasco"},
    {prodId:"50365361",label:"Durazno dama de azúcar en lata o en frasco"},
    {prodId:"50365362",label:"Durazno brillo de sol en lata o en frasco"},
    {prodId:"50365363",label:"Durazno sunhigh en lata o en frasco"},
    {prodId:"50365364",label:"Durazno dama super en lata o en frasco"},
    {prodId:"50365365",label:"Durazno super rico en lata o en frasco"},
    {prodId:"50365366",label:"Durazno surecrop en lata o en frasco"},
    {prodId:"50365367",label:"Durazno sueño dulce en lata o en frasco"},
    {prodId:"50365368",label:"Durazno septiembre dulce en lata o en frasco"},
    {prodId:"50365369",label:"Durazno vista en lata o en frasco"},
    {prodId:"50365370",label:"Durazno dama blanca en lata o en frasco"},
    {prodId:"50365371",label:"Durazno dama zee en lata o en frasco"},
    {prodId:"50365600",label:"Piñas en lata o en frasco"},
    {prodId:"50365601",label:"Piña chirimoya en lata o en frasco"},
    {prodId:"50365602",label:"Piña dorada en lata o en frasco"},
    {prodId:"50365603",label:"Piña hilo en lata o en frasco"},
    {prodId:"50365604",label:"Piña kona sugarloaf en lata o en frasco"},
    {prodId:"50365605",label:"Piña reina natal en lata o en frasco"},
    {prodId:"50365606",label:"Piña pernabuco en lata o en frasco"},
    {prodId:"50365607",label:"Piña español rojo en lata o en frasco"},
    {prodId:"50365608",label:"Piña cayen suave en lata o en frasco"},
    {prodId:"50365609",label:"Piña sugarloaf en lata o en frasco"},
    {prodId:"50365610",label:"Piña variegated en lata o en frasco"},
    {prodId:"50365800",label:"Ciruelas en lata o en frasco"},
    {prodId:"50365801",label:"Ciruela joya amber en lata o en frasco"},
    {prodId:"50365802",label:"Ciruela angeleno en lata o en frasco"},
    {prodId:"50365803",label:"Ciruela aurora en lata o en frasco"},
    {prodId:"50365804",label:"Ciruela otoño bonito en lata o en frasco"},
    {prodId:"50365805",label:"Ciruela gigante de otoño en lata o en frasco"},
    {prodId:"50365806",label:"Ciruela orgullo de otoño en lata o en frasco"},
    {prodId:"50365807",label:"Ciruela rosa de otoño en lata o en frasco"},
    {prodId:"50365808",label:"Ciruela playero en lata o en frasco"},
    {prodId:"50365809",label:"Ciruela betty anne en lata o en frasco"},
    {prodId:"50365810",label:"Ciruela belleza negra en lata o en frasco"},
    {prodId:"50365811",label:"Ciruela bullase negra en lata o en frasco"},
    {prodId:"50365812",label:"Ciruela diamante negro en lata o en frasco"},
    {prodId:"50365813",label:"Ciruela gigante negro en lata o en frasco"},
    {prodId:"50365814",label:"Ciruela hielo negro en lata o en frasco"},
    {prodId:"50365815",label:"Ciruela esplendor negro en lata o en frasco"},
    {prodId:"50365816",label:"Ciruela ámbar negro en lata o en frasco"},
    {prodId:"50365817",label:"Ciruela purpura en lata o en frasco"},
    {prodId:"50365818",label:"Ciruela  carsbad en lata o en frasco"},
    {prodId:"50365819",label:"Ciruela casselman en lata o en frasco"},
    {prodId:"50365820",label:"Ciruela catalina en lata o en frasco"},
    {prodId:"50365821",label:"Ciruela damson en lata o en frasco"},
    {prodId:"50365822",label:"Ciruela dolly en lata o en frasco"},
    {prodId:"50365823",label:"Ciruela earliqueen en lata o en frasco"},
    {prodId:"50365824",label:"Ciruela rosa early en lata o en frasco"},
    {prodId:"50365825",label:"Ciruela ébano mayo en lata o en frasco"},
    {prodId:"50365826",label:"Ciruela ébano en lata o en frasco"},
    {prodId:"50365827",label:"Ciruela corazón de elefante en lata o en frasco"},
    {prodId:"50365828",label:"Ciruela belleza de esmeralda en lata o en frasco"},
    {prodId:"50365829",label:"Ciruela emperatriz en lata o en frasco"},
    {prodId:"50365830",label:"Ciruela libertad en lata o en frasco"},
    {prodId:"50365831",label:"Ciruela friar en lata o en frasco"},
    {prodId:"50365832",label:"Ciruela rojo gar en lata o en frasco"},
    {prodId:"50365833",label:"Ciruela gobernador en lata o en frasco"},
    {prodId:"50365834",label:"Ciruela rosa grande en lata o en frasco"},
    {prodId:"50365835",label:"Ciruela calibre verde en lata o en frasco"},
    {prodId:"50365836",label:"Ciruela calibre verde en lata o en frasco"},
    {prodId:"50365837",label:"Ciruela hiromi en lata o en frasco"},
    {prodId:"50365838",label:"Ciruela hiromi rojo en lata o en frasco"},
    {prodId:"50365839",label:"Ciruela vacacion en lata o en frasco"},
    {prodId:"50365840",label:"Ciruela howard sol en lata o en frasco"},
    {prodId:"50365841",label:"Ciruela tipo interspecific en lata o en frasco"},
    {prodId:"50365842",label:"Ciruela jamaico en lata o en frasco"},
    {prodId:"50365843",label:"Ciruela joanna rojo en lata o en frasco"},
    {prodId:"50365844",label:"Ciruela kelsey en lata o en frasco"},
    {prodId:"50365845",label:"Ciruela jaime rey en lata o en frasco"},
    {prodId:"50365846",label:"Ciruela laroda en lata o en frasco"},
    {prodId:"50365847",label:"Ciruela rosa tarde en lata o en frasco"},
    {prodId:"50365848",label:"Ciruela rosa linda en lata o en frasco"},
    {prodId:"50365849",label:"Ciruela estrella solitaria en lata o en frasco"},
    {prodId:"50365850",label:"Ciruela mariposa en lata o en frasco"},
    {prodId:"50365851",label:"Ciruela mercado negro en lata o en frasco"},
    {prodId:"50365852",label:"Ciruela mercado rojo en lata o en frasco"},
    {prodId:"50365853",label:"Ciruela maribel en lata o en frasco"},
    {prodId:"50365854",label:"Ciruelas sol de octubre en lata o en frasco"},
    {prodId:"50365855",label:"Ciruela owen t en lata o en frasco"},
    {prodId:"50365856",label:"Ciruela perdrigon en lata o en frasco"},
    {prodId:"50365857",label:"Ciruela placer rosado en lata o en frasco"},
    {prodId:"50365858",label:"Ciruela  presidente en lata o en frasco"},
    {prodId:"50365859",label:"Ciruela primetime en lata o en frasco"},
    {prodId:"50365860",label:"Ciruela majestad purpura en lata o en frasco"},
    {prodId:"50365861",label:"Ciruela reina rosa en lata o en frasco"},
    {prodId:"50365862",label:"Ciruela quetsch en lata o en frasco"},
    {prodId:"50365863",label:"Ciruela belleza roja en lata o en frasco"},
    {prodId:"50365864",label:"Ciruela camino rojo en lata o en frasco"},
    {prodId:"50365865",label:"Ciruela ram rojo en lata o en frasco"},
    {prodId:"50365866",label:"Ciruela rosa roja en lata o en frasco"},
    {prodId:"50365867",label:"Ciruela rojo rico en lata o en frasco"},
    {prodId:"50365868",label:"Ciruela romero en lata o en frasco"},
    {prodId:"50365869",label:"Ciruela diamante rojo en lata o en frasco"},
    {prodId:"50365870",label:"Ciruela rojo royal en lata o en frasco"},
    {prodId:"50365871",label:"Ciruela royal zee en lata o en frasco"},
    {prodId:"50365872",label:"Ciruela roysum en lata o en frasco"},
    {prodId:"50365873",label:"Ciruela santa rosa en lata o en frasco"},
    {prodId:"50365874",label:"Ciruela zafiro en lata o en frasco"},
    {prodId:"50365875",label:"Ciruela sloe en lata o en frasco"},
    {prodId:"50365876",label:"Ciruela sta catherine en lata o en frasco"},
    {prodId:"50365877",label:"Ciruela bullase blanco en lata o en frasco"},
    {prodId:"50366100",label:"Membrillos en lata o en frasco"},
    {prodId:"50366101",label:"Membrillo campeón en lata o en frasco"},
    {prodId:"50366102",label:"Membrillo piña en lata o en frasco"},
    {prodId:"50366103",label:"Membrillo smyma en lata o en frasco"},
    {prodId:"50366800",label:"Manzanas dulces en lata o en frasco"},
    {prodId:"50366801",label:"Manzana kampong malve dulce en lata o en frasco"},
    {prodId:"50366802",label:"Manzana dulce sin pepa en lata o en frasco"},
    {prodId:"50366803",label:"Manzana dulce thai lessand en lata o en frasco"},
    {prodId:"50366900",label:"Tamarillos en lata o en frasco"},
    {prodId:"50366901",label:"Tamarindo amberlea dorado en lata o en frasco"},
    {prodId:"50366902",label:"Tamarindo calvo dorado en lata o en frasco"},
    {prodId:"50366903",label:"Tamarindo mina de oro en lata o en frasco"},
    {prodId:"50366904",label:"Tamarindo oratia rojo en lata o en frasco"},
    {prodId:"50366905",label:"Tamarindo beau rojo en lata o en frasco"},
    {prodId:"50366906",label:"Tamarindo delicia roja en lata o en frasco"},
    {prodId:"50367000",label:"Frutas Nominant en lata o en frasco"},
    {prodId:"50367001",label:"Cupania en lata o en frasco"},
    {prodId:"50367002",label:"Babaco en lata o en frasco"},
    {prodId:"50367003",label:"Banana flor en lata o en frasco"},
    {prodId:"50367004",label:"Baobab en lata o en frasco"},
    {prodId:"50367005",label:"Naranja agria en lata o en frasco"},
    {prodId:"50367006",label:"Canistel en lata o en frasco"},
    {prodId:"50367007",label:"Baya nublosa en lata o en frasco"},
    {prodId:"50367008",label:"Coco en lata o en frasco"},
    {prodId:"50367009",label:"Baya drew en lata o en frasco"},
    {prodId:"50367010",label:"Durian en lata o en frasco"},
    {prodId:"50367011",label:"Baya elder en lata o en frasco"},
    {prodId:"50367012",label:"Feijoa en lata o en frasco"},
    {prodId:"50367013",label:"Mora en lata o en frasco"},
    {prodId:"50367014",label:"Cretaegus en lata o en frasco"},
    {prodId:"50367015",label:"Baya miel en lata o en frasco"},
    {prodId:"50367016",label:"Jaca en lata o en frasco"},
    {prodId:"50367017",label:"Jambolan en lata o en frasco"},
    {prodId:"50367018",label:"Jujube en lata o en frasco"},
    {prodId:"50367019",label:"Liches en lata o en frascos orgánicos"},
    {prodId:"50367020",label:"Mangostinos en lata o en frascos orgánicos"},
    {prodId:"50367021",label:"Medlar en lata o en frasco"},
    {prodId:"50367022",label:"Mombines en lata o en frasco"},
    {prodId:"50367023",label:"Monstera en lata o en frasco"},
    {prodId:"50367024",label:"Pepinos en lata o en frasco"},
    {prodId:"50367025",label:"Platono en lata o en frasco"},
    {prodId:"50367026",label:"Peras en escabeche en lata o en frasco"},
    {prodId:"50367027",label:"Mamonsillo en lata o en frasco"},
    {prodId:"50367028",label:"Rambután en lata o en frasco"},
    {prodId:"50367029",label:"Manzana rosa en lata o en frasco"},
    {prodId:"50367030",label:"Rosele en lata o en frasco"},
    {prodId:"50367031",label:"Baya rowan en lata o en frasco"},
    {prodId:"50367032",label:"Baya buckhom de mar en lata o en frasco"},
    {prodId:"50367033",label:"Baya plata en lata o en frasco"},
    {prodId:"50367034",label:"Baya sorbete en lata o en frasco"},
    {prodId:"50367035",label:"Guanábana en lata o en frasco"},
    {prodId:"50367036",label:"Manzana estrella en lata o en frasco"},
    {prodId:"50367037",label:"Tamarindo en lata o en frasco"},
    {prodId:"50367200",label:"Aceitunas en lata o en frasco"},
    {prodId:"50367201",label:"Aceitunas agrinion en lata o en frasco"},
    {prodId:"50367202",label:"Aceitunas aleppo en lata o en frasco"},
    {prodId:"50367203",label:"Aceitunas  alfonso en lata o en frasco"},
    {prodId:"50367204",label:"Aceitunas  amfisa en lata o en frasco"},
    {prodId:"50367205",label:"Aceitunas  arauco en lata o en frasco"},
    {prodId:"50367206",label:"Aceitunas  arbequina en lata o en frasco"},
    {prodId:"50367207",label:"Aceitunas  atlanta en lata o en frasco"},
    {prodId:"50367208",label:"Aceitunas  cerignola en lata o en frasco"},
    {prodId:"50367209",label:"Aceitunas  cracked provencal en lata o en frasco"},
    {prodId:"50367210",label:"Aceitunas  empeltre en lata o en frasco"},
    {prodId:"50367211",label:"Aceitunas gaeta  en lata o en frasco"},
    {prodId:"50367212",label:"Aceitunas hondoelia en lata o en frasco"},
    {prodId:"50367213",label:"Aceitunas kalamata en lata o en frasco"},
    {prodId:"50367214",label:"Aceitunas kura en lata o en frasco"},
    {prodId:"50367215",label:"Aceitunas ligurian en lata o en frasco"},
    {prodId:"50367216",label:"Aceitunas lucque en lata o en frasco"},
    {prodId:"50367217",label:"Aceitunas lugano en lata o en frasco"},
    {prodId:"50367218",label:"Aceitunas manzanilla en lata o en frasco"},
    {prodId:"50367219",label:"Aceitunas marche en lata o en frasco"},
    {prodId:"50367220",label:"Aceitunas misión en lata o en frasco"},
    {prodId:"50367221",label:"Aceitunas nafplion verde en lata o en frasco"},
    {prodId:"50367222",label:"Aceitunas nicoise en lata o en frasco"},
    {prodId:"50367223",label:"Aceitunas nyons en lata o en frasco"},
    {prodId:"50367224",label:"Aceitunas picholine en lata o en frasco"},
    {prodId:"50367225",label:"Aceitunas ponentine en lata o en frasco"},
    {prodId:"50367226",label:"Aceitunas royal en lata o en frasco"},
    {prodId:"50367227",label:"Aceitunas seracena en lata o en frasco"},
    {prodId:"50367228",label:"Aceitunas sevillano en lata o en frasco"},
    {prodId:"50367229",label:"Aceitunas sicilian en lata o en frasco"},
    {prodId:"50367230",label:"Aceitunas toscanella en lata o en frasco"},
    {prodId:"50371500",label:"Manzanas orgánicas en lata o en frasco"},
    {prodId:"50371501",label:"Manzanas akane orgánica en lata o en frascos"},
    {prodId:"50371502",label:"Manzana ambrosia orgánica en lata o en frasco"},
    {prodId:"50371503",label:"Manzanas api orgánica en lata o en frascos"},
    {prodId:"50371504",label:"Manzanas baldwin orgánica en lata o en  frasco"},
    {prodId:"50371505",label:"Manzanas brabum orgánica en lata o en frasco"},
    {prodId:"50371506",label:"Manzanas bramley orgánica en lata o en frasco"},
    {prodId:"50371507",label:"Manzana joven bramley orgánica  en lata o en frasco"},
    {prodId:"50371508",label:"Manzana calville blanche d'hiver orgánica en lata o en frasco"},
    {prodId:"50371509",label:"Manzana cameo orgánica en lata o en frasco"},
    {prodId:"50371510",label:"Manzana charles ross orgánica en lata o en frasco"},
    {prodId:"50371511",label:"Manzana codlin orgánica en lata o en frasco"},
    {prodId:"50371512",label:"Manzana cortland orgánica en lata o en frasco"},
    {prodId:"50371513",label:"Manzana costard orgánica en lata o en frasco"},
    {prodId:"50371514",label:"Manzana court pendu plat orgánica en lata o en frasco"},
    {prodId:"50371515",label:"Manzana  cox's orange pippin orgánica en lata o en frasco"},
    {prodId:"50371516",label:"Manzana crab orgánica en lata o en frasco"},
    {prodId:"50371517",label:"Manzana crispin orgánica en lata o en frasco"},
    {prodId:"50371518",label:"Manzana delicious orgánica en lata o en frasco"},
    {prodId:"50371519",label:"Manzana duquesa orgánica en lata o en frasco"},
    {prodId:"50371520",label:"Manzana earligold orgánica en lata o en frasco"},
    {prodId:"50371521",label:"Manzana early mcintosh orgánica en lata o en frasco"},
    {prodId:"50371522",label:"Manzana elstar orgánica en lata o en frasco"},
    {prodId:"50371523",label:"Manzana empire orgánica en lata o en frasco"},
    {prodId:"50371524",label:"Manzana flor de kent orgánica en lata o en frasco"},
    {prodId:"50371525",label:"Manzana fiji orgánica en lata o en frasco"},
    {prodId:"50371526",label:"Manzana gala orgánica en lata o en frasco"},
    {prodId:"50371527",label:"Manzana gascoyne's scarlet orgánica en lata o en frasco"},
    {prodId:"50371528",label:"Manzana gillyflower orgánica en lata o en frasco"},
    {prodId:"50371529",label:"Manzana ginger gold orgánica en lata o en frasco"},
    {prodId:"50371530",label:"Manzana gladstone orgánica en lata o en frasco"},
    {prodId:"50371531",label:"Manzana gloster orgánica en lata o en frasco"},
    {prodId:"50371532",label:"Manzana gold supreme orgánica en lata o en frasco"},
    {prodId:"50371533",label:"Manzana golden delicious orgánica en lata o en frasco"},
    {prodId:"50371534",label:"Manzana golden nobel orgánica en lata o en frasco"},
    {prodId:"50371535",label:"Manzana granny smith orgánica en lata o en frasco"},
    {prodId:"50371536",label:"Manzana gravenstain orgánica en lata o en frasco"},
    {prodId:"50371537",label:"Manzana greening orgánica en lata o en frasco"},
    {prodId:"50371538",label:"Manzana greensleeves orgánica en lata o en frasco"},
    {prodId:"50371539",label:"Manzana honeycrisp orgánica en lata o en frasco"},
    {prodId:"50371540",label:"Manzana howgate wonder orgánica en lata o en frasco"},
    {prodId:"50371541",label:"Manzana red ida orgánica en lata o en frasco"},
    {prodId:"50371542",label:"Manzana james grives orgánica en lata o en frasco"},
    {prodId:"50371543",label:"Manzana jersey mac orgánica en lata o en frasco"},
    {prodId:"50371544",label:"Manzana jaster orgánica en lata o en frasco"},
    {prodId:"50371545",label:"Manzana jonagold orgánica en lata o en frasco"},
    {prodId:"50371546",label:"Manzana jonamac orgánica en lata o en frasco"},
    {prodId:"50371547",label:"Manzana  jonathan  orgánica en lata o en frasco"},
    {prodId:"50371548",label:"Manzana katy orgánica en lata o en frasco"},
    {prodId:"50371549",label:"Manzana kidd's orange red orgánica en lata o en frasco"},
    {prodId:"50371550",label:"Manzana lady orgánica en lata o en frasco"},
    {prodId:"50371551",label:"Manzana law rome orgánica en lata o en frasco"},
    {prodId:"50371552",label:"Manzana laxton orgánica en lata o en frasco"},
    {prodId:"50371553",label:"Manzana lord derby orgánica en lata o en frasco"},
    {prodId:"50371554",label:"Manzana macoun  orgánica en lata o en frasco"},
    {prodId:"50371555",label:"Manzana mcintosh orgánica en lata o en frasco"},
    {prodId:"50371556",label:"Manzana mutsu orgánica en lata o en frasco"},
    {prodId:"50371557",label:"Manzana newtown pippin orgánica en lata o en frasco"},
    {prodId:"50371558",label:"Manzana northen spy orgánica en lata o en frasco"},
    {prodId:"50371559",label:"Manzana orleans reinette orgánica en lata o en frasco"},
    {prodId:"50371560",label:"Manzana ozark gold orgánica en lata o en frasco"},
    {prodId:"50371561",label:"Manzana rosa pacifica orgánica en lata o en frasco"},
    {prodId:"50371562",label:"Manzana paula roja orgánica en lata o en frasco"},
    {prodId:"50371563",label:"Manzana peamain orgánica en lata o en frasco"},
    {prodId:"50371564",label:"Manzana dama rosada orgánica en lata o en frasco"},
    {prodId:"50371565",label:"Manzana pippin orgánica en lata o en frasco"},
    {prodId:"50371566",label:"Manzana pitmaston pineapple orgánica en lata o en frasco"},
    {prodId:"50371567",label:"Manzana pomme dápi orgánica en lata o en frasco"},
    {prodId:"50371568",label:"Manzana prime gold orgánica en lata o en frasco"},
    {prodId:"50371569",label:"Manzana rojo astrakhan orgánica en lata o en frasco"},
    {prodId:"50371570",label:"Manzana rojo boscoop orgánica en lata o en frasco"},
    {prodId:"50371571",label:"Manzana rojo jefe orgánica en lata o en frasco"},
    {prodId:"50371572",label:"Manzana delicia rojo orgánica en lata o en frasco"},
    {prodId:"50371573",label:"Manzana rojo grvenstain orgánica en lata o en frasco"},
    {prodId:"50371574",label:"Manzana  roma orgánica en lata o en frasco"},
    {prodId:"50371575",label:"Manzana rojo stayman orgánica en lata o en frasco"},
    {prodId:"50371576",label:"Manzana rojo york orgánica en lata o en frasco"},
    {prodId:"50371577",label:"Manzana reinette orgánica en lata o en frasco"},
    {prodId:"50371578",label:"Manzana belleza romana orgánica en lata o en frasco"},
    {prodId:"50371579",label:"Manzana russet orgánica en lata o en frasco"},
    {prodId:"50371580",label:"Manzana sierra belleza orgánica en lata o en frasco"},
    {prodId:"50371581",label:"Manzana spartan orgánica en lata o en frasco"},
    {prodId:"50371582",label:"Manzana stark crimson orgánica en lata o en frasco"},
    {prodId:"50371583",label:"Manzana starking orgánica en lata o en frasco"},
    {prodId:"50371584",label:"Manzana stayman orgánica en lata o en frasco"},
    {prodId:"50371585",label:"Manzana stayman winesap orgánica en lata o en frasco"},
    {prodId:"50371586",label:"Manzana summer rambo orgánica en lata o en frasco"},
    {prodId:"50371587",label:"Manzana tsugaru orgánica en lata o en frasco"},
    {prodId:"50371588",label:"Manzana twenty ounce orgánica en lata o en frasco"},
    {prodId:"50371589",label:"Manzana tydeman red orgánica en lata o en frasco"},
    {prodId:"50371590",label:"Manzana vistabella orgánica en lata o en frasco"},
    {prodId:"50371591",label:"Manzana weatley orgánica en lata o en frasco"},
    {prodId:"50371592",label:"Manzana blanca joanetine orgánica en lata o en frasco"},
    {prodId:"50371593",label:"Manzana blanca transparent orgánica en lata o en frasco"},
    {prodId:"50371594",label:"Manzana winesap orgánica en lata o en frasco"},
    {prodId:"50371595",label:"Manzana worcester orgánica en lata o en frasco"},
    {prodId:"50371596",label:"Manzana york imperial orgánica en lata o en frasco"},
    {prodId:"50374500",label:"Mangos orgánicos en lata o en frasco"},
    {prodId:"50374501",label:"Mango alphonso orgánico en lata o en frasco"},
    {prodId:"50374502",label:"Mango ataulfo orgánico en lata o en frasco"},
    {prodId:"50374503",label:"Mango criollo orgánico en lata o en frasco"},
    {prodId:"50374504",label:"Mango edwards orgánico en lata o en frasco"},
    {prodId:"50374505",label:"Mango francine orgánico en lata o en frasco"},
    {prodId:"50374506",label:"Mango francis orgánico en lata o en frasco"},
    {prodId:"50374507",label:"Mango gandaria orgánico en lata o en frasco"},
    {prodId:"50374508",label:"Mango haden orgánico en lata o en frasco"},
    {prodId:"50374509",label:"Mangos irwin orgánico en lata o en frasco"},
    {prodId:"50374510",label:"Mangos keitt orgánico en lata o en frasco"},
    {prodId:"50374511",label:"Mangos kent orgánico en lata o en frasco"},
    {prodId:"50374512",label:"Mangos kesar orgánico en lata o en frasco"},
    {prodId:"50374513",label:"Mangos kuini orgánico en lata o en frasco"},
    {prodId:"50374514",label:"Mangos manila super orgánico en lata o en frasco"},
    {prodId:"50374515",label:"Mangos manila orgánico en lata o en frasco"},
    {prodId:"50374516",label:"Mangos mayaguez orgánico en lata o en frasco"},
    {prodId:"50374517",label:"Mangos mulgoba orgánico en lata o en frasco"},
    {prodId:"50374518",label:"Mangos oro orgánico en lata o en frasco"},
    {prodId:"50374519",label:"Mangos palmer orgánico en lata o en frasco"},
    {prodId:"50374520",label:"Mango parvin orgánico en lata o en frasco"},
    {prodId:"50374521",label:"Mango sandersha orgánico en lata o en frasco"},
    {prodId:"50374522",label:"Mangos sensation orgánico en lata o en frasco"},
    {prodId:"50374523",label:"Mango smith orgánico en lata o en frasco"},
    {prodId:"50374524",label:"Mango tomy atkin orgánico en lata o en frasco"},
    {prodId:"50374525",label:"Mango van dyke orgánico en lata o en frasco"},
    {prodId:"50376700",label:"Fresas orgánicas en lata o en frasco"},
    {prodId:"50376701",label:"Fresa chandler orgánica en lata o en frasco"},
    {prodId:"50376702",label:"Fresa rumbo junio orgánica en lata o en frasco"},
    {prodId:"50376703",label:"Fresa rumbo siempre orgánica en lata o en frasco"},
    {prodId:"50377000",label:"Frutas Nominant orgánicas en lata o en frasco"},
    {prodId:"50377001",label:"Cupania orgánica en lata o en frasco"},
    {prodId:"50377002",label:"Babaco orgánico en lata o en frasco"},
    {prodId:"50377003",label:"Banana flor orgánica en lata o en frasco"},
    {prodId:"50377004",label:"Baobab orgánica en lata o en frasco"},
    {prodId:"50377005",label:"Naranja agria orgánica en lata o en frasco"},
    {prodId:"50377006",label:"Canistel orgánica en lata o en frasco"},
    {prodId:"50377007",label:"Baya nublosa orgánica en lata o en frasco"},
    {prodId:"50377008",label:"Coco orgánico en lata o en frasco"},
    {prodId:"50377009",label:"Baya drew orgánica en lata o en frasco"},
    {prodId:"50377010",label:"Durian orgánica en lata o en frasco"},
    {prodId:"50377011",label:"Baya elder orgánica en lata o en frasco"},
    {prodId:"50377012",label:"Feijoa orgánica en lata o en frasco"},
    {prodId:"50377013",label:"Mora orgánica en lata o en frasco"},
    {prodId:"50377014",label:"Cretaegus orgánica en lata o en frasco"},
    {prodId:"50377015",label:"Baya miel orgánica en lata o en frasco"},
    {prodId:"50377016",label:"Jaca orgánica en lata o en frasco"},
    {prodId:"50377017",label:"Jambolan orgánica en lata o en frasco"},
    {prodId:"50377018",label:"Jujube orgánica en lata o en frasco"},
    {prodId:"50377019",label:"Liches orgánico en lata o en frascos en lata o en frasco"},
    {prodId:"50377020",label:"Mangostinos orgánico en lata o en frascos en lata o en frasco"},
    {prodId:"50377021",label:"Medlar orgánica en lata o en frasco"},
    {prodId:"50377022",label:"Mombines orgánica en lata o en frasco"},
    {prodId:"50377023",label:"Monstera orgánica en lata o en frasco"},
    {prodId:"50377024",label:"Pepinos orgánico en lata o en frasco"},
    {prodId:"50377025",label:"Platono orgánico en lata o en frasco"},
    {prodId:"50377026",label:"Peras en escabeche orgánica en lata o en frasco"},
    {prodId:"50377027",label:"Mamonsillo orgánico en lata o en frasco"},
    {prodId:"50377028",label:"Rambután orgánico en lata o en frasco"},
    {prodId:"50377029",label:"Manzana rosa orgánica en lata o en frasco"},
    {prodId:"50377030",label:"Rosele orgánica en lata o en frasco"},
    {prodId:"50377031",label:"Baya rowan orgánica en lata o en frasco"},
    {prodId:"50377032",label:"Baya buckhom de mar orgánica en lata o en frasco"},
    {prodId:"50377033",label:"Baya plata orgánica en lata o en frasco"},
    {prodId:"50377034",label:"Baya sorbete orgánico en lata o en frasco"},
    {prodId:"50377035",label:"Wanabana orgánica en lata o en frasco"},
    {prodId:"50377036",label:"Manzana estrella orgánica en lata o en frasco"},
    {prodId:"50377037",label:"Tamarindo orgánico en lata o en frasco"},
    {prodId:"50381600",label:"Purés de bayas"},
    {prodId:"50381601",label:"Puré de fresa"},
    {prodId:"50381602",label:"Puré de mora"},
    {prodId:"50381700",label:"Purés de frutas tropicales"},
    {prodId:"50381701",label:"Puré de mango"},
    {prodId:"50381702",label:"Puré de kiwi"},
    {prodId:"50401500",label:"Alcachofas"},
    {prodId:"50401501",label:"Alcachofa brittany"},
    {prodId:"50401502",label:"Alcachofa cantonesa"},
    {prodId:"50401503",label:"Alcachofa francesa"},
    {prodId:"50401504",label:"Alcachofa globo verde"},
    {prodId:"50401505",label:"Alcachofa gros camus de bretaña"},
    {prodId:"50401506",label:"Alcachofa midi"},
    {prodId:"50401507",label:"Alcachofa globo morado"},
    {prodId:"50401508",label:"Alcachofa morado cecilia"},
    {prodId:"50401509",label:"Alcachofa romanesco"},
    {prodId:"50401510",label:"Alcachofa espinoso sardo"},
    {prodId:"50401511",label:"Alcachofa vert de laon"},
    {prodId:"50401512",label:"Alcachofa violeta de chiggiia"},
    {prodId:"50401513",label:"Alcachofa violeta de toscana"},
    {prodId:"50401600",label:"Espárragos"},
    {prodId:"50401601",label:"Espárragos connovers colossal"},
    {prodId:"50401602",label:"Espárragos franklin"},
    {prodId:"50401603",label:"Espárragos mamut gigante"},
    {prodId:"50401604",label:"Espárragos lucullus"},
    {prodId:"50401605",label:"Espárragos martha washington"},
    {prodId:"50401700",label:"Aguacates"},
    {prodId:"50401701",label:"Aguacate ajax b - 7"},
    {prodId:"50401702",label:"Aguacate arue"},
    {prodId:"50401703",label:"Aguacate bacon"},
    {prodId:"50401704",label:"Aguacate benik"},
    {prodId:"50401705",label:"Aguacate bernecker"},
    {prodId:"50401706",label:"Aguacate beta"},
    {prodId:"50401707",label:"Aguacate biondo"},
    {prodId:"50401708",label:"Aguacate príncipe negro"},
    {prodId:"50401709",label:"Aguacate blair"},
    {prodId:"50401710",label:"Aguacate blair cabina"},
    {prodId:"50401711",label:"Aguacate cabina 1"},
    {prodId:"50401712",label:"Aguacate cabina 3"},
    {prodId:"50401713",label:"Aguacate cabina 5"},
    {prodId:"50401714",label:"Aguacate cabina 7"},
    {prodId:"50401715",label:"Aguacate cabina 8"},
    {prodId:"50401716",label:"Aguacate brooks 1978"},
    {prodId:"50401717",label:"Aguacate brookslate"},
    {prodId:"50401718",label:"Aguacate california haas"},
    {prodId:"50401719",label:"Aguacate catalina"},
    {prodId:"50401720",label:"Aguacate chica"},
    {prodId:"50401721",label:"Aguacate choquette"},
    {prodId:"50401722",label:"Aguacate cristina"},
    {prodId:"50401723",label:"Aguacate collison"},
    {prodId:"50401724",label:"Aguacate donnie"},
    {prodId:"50401725",label:"Aguacate dr dupis numero 2"},
    {prodId:"50401726",label:"Aguacate dr. dupie"},
    {prodId:"50401727",label:"Aguacate ettinger"},
    {prodId:"50401728",label:"Aguacate fuch"},
    {prodId:"50401729",label:"Aguacate fuch gwen"},
    {prodId:"50401730",label:"Aguacate fuerte"},
    {prodId:"50401731",label:"Aguacate gotham"},
    {prodId:"50401732",label:"Aguacate gossman"},
    {prodId:"50401733",label:"Aguacate guatemala sin pepa"},
    {prodId:"50401734",label:"Aguacate hall"},
    {prodId:"50401735",label:"Aguacate hardee"},
    {prodId:"50401736",label:"Aguacate haas"},
    {prodId:"50401737",label:"Aguacate herman"},
    {prodId:"50401738",label:"Aguacate hickson"},
    {prodId:"50401739",label:"Aguacate k-5"},
    {prodId:"50401740",label:"Aguacate k- 9"},
    {prodId:"50401741",label:"Aguacate cordero haas"},
    {prodId:"50401742",label:"Aguacate leona"},
    {prodId:"50401743",label:"Aguacate leona linda"},
    {prodId:"50401744",label:"Aguacate lisa p"},
    {prodId:"50401745",label:"Aguacate lisa loretta"},
    {prodId:"50401746",label:"Aguacate loretta"},
    {prodId:"50401747",label:"Aguacate lula"},
    {prodId:"50401748",label:"Aguacate lula mascarthur"},
    {prodId:"50401749",label:"Aguacate marcus"},
    {prodId:"50401750",label:"Aguacate melendez"},
    {prodId:"50401751",label:"Aguacate meya"},
    {prodId:"50401752",label:"Aguacate miguel p"},
    {prodId:"50401753",label:"Aguacate monroe"},
    {prodId:"50401754",label:"Aguacate murrieta verde"},
    {prodId:"50401755",label:"Aguacate nabal"},
    {prodId:"50401756",label:"Aguacate nadir"},
    {prodId:"50401757",label:"Aguacate nesbitt"},
    {prodId:"50401758",label:"Aguacate peterson"},
    {prodId:"50401759",label:"Aguacate pinelli"},
    {prodId:"50401760",label:"Aguacate pinkerton"},
    {prodId:"50401761",label:"Aguacate pollock"},
    {prodId:"50401762",label:"Aguacate puebla"},
    {prodId:"50401763",label:"Aguacate reed"},
    {prodId:"50401764",label:"Aguacaterue"},
    {prodId:"50401765",label:"Aguacateruehle"},
    {prodId:"50401766",label:"Aguacate ryan"},
    {prodId:"50401767",label:"Aguacate semil"},
    {prodId:"50401768",label:"Aguacate semil 43"},
    {prodId:"50401769",label:"Aguacate simonds"},
    {prodId:"50401770",label:"Aguacate simpson"},
    {prodId:"50401771",label:"Aguacate taylor"},
    {prodId:"50401772",label:"Aguacate tonelada"},
    {prodId:"50401773",label:"Aguacate torre"},
    {prodId:"50401774",label:"Aguacate torre li"},
    {prodId:"50401775",label:"Aguacate trapp"},
    {prodId:"50401776",label:"Aguacate semilla de caribe"},
    {prodId:"50401777",label:"Aguacate wagner"},
    {prodId:"50401778",label:"Aguacate waldin"},
    {prodId:"50401779",label:"Aguacate wurtz"},
    {prodId:"50401780",label:"Aguacate zio p"},
    {prodId:"50401781",label:"Aguacate ziu"},
    {prodId:"50401782",label:"Aguacate zultano"},
    {prodId:"50401800",label:"Fríjoles"},
    {prodId:"50401801",label:"Frijoles anasazi ®"},
    {prodId:"50401802",label:"Frijoles appaloosa"},
    {prodId:"50401803",label:"Frijoles azuki"},
    {prodId:"50401804",label:"Frijoles barlotti"},
    {prodId:"50401805",label:"Frijoles appaloosa negra"},
    {prodId:"50401806",label:"Frijoles negros"},
    {prodId:"50401807",label:"Frijoles gran negros"},
    {prodId:"50401808",label:"Frijoles shackamaxon negro"},
    {prodId:"50401809",label:"Frijoles ojo negro"},
    {prodId:"50401810",label:"Frijoles bobby"},
    {prodId:"50401811",label:"Frijoles bolita"},
    {prodId:"50401812",label:"Frijoles esposa perezosa carmelita"},
    {prodId:"50401813",label:"Frijoles calipso"},
    {prodId:"50401814",label:"Frijoles canelini"},
    {prodId:"50401815",label:"Frijoles castor"},
    {prodId:"50401816",label:"Frijoles amarillo chino"},
    {prodId:"50401817",label:"Frijoles lengua de dragón"},
    {prodId:"50401818",label:"Frijoles soldado europeo"},
    {prodId:"50401819",label:"Frijoles fava"},
    {prodId:"50401820",label:"Frijoles flageolet"},
    {prodId:"50401821",label:"Frijoles  horticultura francesa"},
    {prodId:"50401822",label:"Frijoles marina francesa"},
    {prodId:"50401823",label:"Frijoles coco gigante blanco"},
    {prodId:"50401824",label:"Frijoles verdes"},
    {prodId:"50401825",label:"Frijoles romano verde"},
    {prodId:"50401826",label:"Frijoles guar gum"},
    {prodId:"50401827",label:"Frijoles haricot"},
    {prodId:"50401828",label:"Frijoles hyacinth"},
    {prodId:"50401829",label:"Frijoles tipo italiano"},
    {prodId:"50401830",label:"Frijoles asombro jackson"},
    {prodId:"50401831",label:"Frijoles ganado jacobs"},
    {prodId:"50401832",label:"Frijoles asombro kentucky"},
    {prodId:"50401833",label:"Frijoles riñón"},
    {prodId:"50401834",label:"Frijoles lima"},
    {prodId:"50401835",label:"Frijoles madera"},
    {prodId:"50401836",label:"Frijoles medula"},
    {prodId:"50401837",label:"Frijoles mat"},
    {prodId:"50401838",label:"Frijoles monstoler ganzo salvaje"},
    {prodId:"50401839",label:"Frijoles  mortgage lifter"},
    {prodId:"50401840",label:"Frijoles polilla"},
    {prodId:"50401841",label:"Frijoles mung"},
    {prodId:"50401842",label:"Frijoles munsi wolf"},
    {prodId:"50401843",label:"Frijoles nuna"},
    {prodId:"50401844",label:"Frijoles pinto"},
    {prodId:"50401845",label:"Frijoles runner"},
    {prodId:"50401846",label:"String beans"},
    {prodId:"50401847",label:"Frijoles habichuelas"},
    {prodId:"50401848",label:"Frijoles tamarind"},
    {prodId:"50401849",label:"Frijoles tonka"},
    {prodId:"50401850",label:"Frijoles cera"},
    {prodId:"50401851",label:"Frijoles winged"},
    {prodId:"50401852",label:"Frijoles largos"},
    {prodId:"50401853",label:"Frijoles peruanos canario"},
    {prodId:"50401900",label:"Remolachas"},
    {prodId:"50401901",label:"Remolacha accion"},
    {prodId:"50401902",label:"Remolacha albina vereduna"},
    {prodId:"50401903",label:"Remolacha barababiotela de chiggia"},
    {prodId:"50401904",label:"Remolacha boltardy"},
    {prodId:"50401905",label:"Remolacha bonel"},
    {prodId:"50401906",label:"Remolacha burpees dorado"},
    {prodId:"50401907",label:"Remolacha cheltenham tapa verde"},
    {prodId:"50401908",label:"Remolacha cheltenham mono"},
    {prodId:"50401909",label:"Remolacha chioggia"},
    {prodId:"50401910",label:"Remolacha cilindra"},
    {prodId:"50401911",label:"Remolacha dégypte"},
    {prodId:"50401912",label:"Remolacha detroit 2 rojo oscuro"},
    {prodId:"50401913",label:"Remolacha detroit 2 bala chiquita"},
    {prodId:"50401914",label:"Remolacha egipcia plana"},
    {prodId:"50401915",label:"Remolacha  raíz de nabo egipcio"},
    {prodId:"50401916",label:"Remolacha fomanova"},
    {prodId:"50401917",label:"Remolacha forono"},
    {prodId:"50401918",label:"Remolacha monaco"},
    {prodId:"50401919",label:"Remolacha monograma"},
    {prodId:"50401920",label:"Remolacha pronto"},
    {prodId:"50401921",label:"Remolacha regalía"},
    {prodId:"50401922",label:"Remolacha dulce"},
    {prodId:"50402000",label:"Brócolis"},
    {prodId:"50402001",label:"Brócolini"},
    {prodId:"50402002",label:"Brócoli romanesco"},
    {prodId:"50402003",label:"Brócoli raab"},
    {prodId:"50402004",label:"Brócoli chino"},
    {prodId:"50402100",label:"Repollitos de Bruselas"},
    {prodId:"50402101",label:"Repollitas de bruselas citade"},
    {prodId:"50402102",label:"Repollitas de bruselas falstaff"},
    {prodId:"50402103",label:"Repollitas de bruselas oliver"},
    {prodId:"50402104",label:"Repollitas de bruselas peer gynt"},
    {prodId:"50402105",label:"Repollitas de bruselas rampart"},
    {prodId:"50402106",label:"Repollitas de bruselas rubine"},
    {prodId:"50402107",label:"Repollitas de bruselas  widgeon"},
    {prodId:"50402200",label:"Cebollas de ensalada"},
    {prodId:"50402201",label:"Cebolla belstville ensalada"},
    {prodId:"50402202",label:"Cebolla feast ensalada"},
    {prodId:"50402203",label:"Cebolla ishikura ensalada"},
    {prodId:"50402204",label:"Cebolla mercado kyoto"},
    {prodId:"50402205",label:"Cebolla barba roja ensalada"},
    {prodId:"50402206",label:"Cebolla amigo rojo ensalada"},
    {prodId:"50402207",label:"Cebolla santa claus"},
    {prodId:"50402208",label:"Cebolla tokyo ensalada"},
    {prodId:"50402209",label:"Cebolla lisbon blanca ensalada"},
    {prodId:"50402210",label:"Cebolla invierno blanca ensalada"},
    {prodId:"50402211",label:"Cebolla final de invierno ensalada"},
    {prodId:"50402300",label:"Repollos"},
    {prodId:"50402301",label:"Repollo negro"},
    {prodId:"50402302",label:"Repollo savoy"},
    {prodId:"50402303",label:"Repollo zorrillo"},
    {prodId:"50402304",label:"Repollo blanco"},
    {prodId:"50402305",label:"Repollo morado"},
    {prodId:"50402500",label:"Zanahorias"},
    {prodId:"50402501",label:"Zanahoria amsterdam"},
    {prodId:"50402502",label:"Zanahoria rey de otoño"},
    {prodId:"50402503",label:"Zanahoria berlicum"},
    {prodId:"50402504",label:"Zanahoria chantenay"},
    {prodId:"50402505",label:"Zanahoria nantes"},
    {prodId:"50402506",label:"Zanahoria mercado de parís"},
    {prodId:"50402507",label:"Zanahoria bebé"},
    {prodId:"50402600",label:"Coliflores"},
    {prodId:"50402601",label:"Coliflor todo el año"},
    {prodId:"50402602",label:"Coliflor alverda"},
    {prodId:"50402603",label:"Otoño gigante 3"},
    {prodId:"50402604",label:"Coliflordok elgon"},
    {prodId:"50402605",label:"Coliflor bola de nieve temprana"},
    {prodId:"50402606",label:"Coliflor luz de lima"},
    {prodId:"50402607",label:"Coliflor minaret"},
    {prodId:"50402608",label:"Coliflor buque de naranja"},
    {prodId:"50402609",label:"Coliflor capa morada"},
    {prodId:"50402610",label:"Coliflor bola de nieve"},
    {prodId:"50402611",label:"Coliflor invierno walcheren 3"},
    {prodId:"50402612",label:"Coliflor piedra blanca"},
    {prodId:"50402700",label:"Apios"},
    {prodId:"50402701",label:"Apio celebridad"},
    {prodId:"50402702",label:"Apio"},
    {prodId:"50402703",label:"Apio chino"},
    {prodId:"50402704",label:"Apio dinant francés"},
    {prodId:"50402705",label:"Apio gigante rosado"},
    {prodId:"50402706",label:"Apio gigante rojo"},
    {prodId:"50402707",label:"Apio gigante blanco"},
    {prodId:"50402708",label:"Apio dorado auto escalado"},
    {prodId:"50402709",label:"Apio greensleeves"},
    {prodId:"50402710",label:"Apio hopkins fenlander"},
    {prodId:"50402711",label:"Apio torre marfil"},
    {prodId:"50402712",label:"Apio lathom blanqueado"},
    {prodId:"50402713",label:"Apio sopa de amsterdam"},
    {prodId:"50402714",label:"Apio estándar cargado"},
    {prodId:"50402715",label:"Apio triunfo alto utah"},
    {prodId:"50402800",label:"Acelgas"},
    {prodId:"50402801",label:"Acelga luz brillante"},
    {prodId:"50402802",label:"Acelga gigante fordhook"},
    {prodId:"50402803",label:"Acelga luculus"},
    {prodId:"50402804",label:"Acelga espinaca perpetua"},
    {prodId:"50402805",label:"Acelga ruibarbo"},
    {prodId:"50402806",label:"Acelga suizo"},
    {prodId:"50402807",label:"Acelga volcán"},
    {prodId:"50402808",label:"Acelga rey blanco"},
    {prodId:"50403000",label:"Repollos chinos"},
    {prodId:"50403001",label:"Bok choy"},
    {prodId:"50403002",label:"Bok choy enano"},
    {prodId:"50403003",label:"Flor de repollo chino"},
    {prodId:"50403004",label:"Choy sum"},
    {prodId:"50403005",label:"Col (bok choy) enano"},
    {prodId:"50403006",label:"Col (bok choy) fengshan"},
    {prodId:"50403007",label:"Col (bok choy) jade pagoda"},
    {prodId:"50403008",label:"Col (bok choy) kasumi"},
    {prodId:"50403009",label:"Col (bok choy)  nerva"},
    {prodId:"50403010",label:"Col (bok choy) rosette"},
    {prodId:"50403011",label:"Col (bok choy) ruffles"},
    {prodId:"50403012",label:"Col (bok choy) hoja  santo serrated"},
    {prodId:"50403013",label:"Col (bok choy) shangahai"},
    {prodId:"50403014",label:"Shantung"},
    {prodId:"50403015",label:"Repollo tip top"},
    {prodId:"50403016",label:"Yau choy sum"},
    {prodId:"50403100",label:"Cebollines"},
    {prodId:"50403101",label:"Cebollín chino"},
    {prodId:"50403200",label:"Maíces"},
    {prodId:"50403201",label:"Maíz aloha"},
    {prodId:"50403202",label:"Maíz alpine"},
    {prodId:"50403203",label:"Maíz ambrosia"},
    {prodId:"50403204",label:"Maíz argent"},
    {prodId:"50403205",label:"Maíz aspen"},
    {prodId:"50403206",label:"Maíz avalancha"},
    {prodId:"50403207",label:"Maíz biqueen"},
    {prodId:"50403208",label:"Maíz  bodacious"},
    {prodId:"50403209",label:"Maíz  mantequilla y azúcar"},
    {prodId:"50403210",label:"Maíz  calico belle"},
    {prodId:"50403211",label:"Maíz  camelot"},
    {prodId:"50403212",label:"Maíz  challengercrisp ‘n dulce"},
    {prodId:"50403213",label:"Maíz  campeón"},
    {prodId:"50403214",label:"Maíz  algodón de azúcar"},
    {prodId:"50403215",label:"Maíz  dártagnan"},
    {prodId:"50403216",label:"Maíz  dazzle"},
    {prodId:"50403217",label:"Maíz  diamante y oro"},
    {prodId:"50403218",label:"Maíz  divinidad"},
    {prodId:"50403219",label:"Maíz  delicia doble"},
    {prodId:"50403220",label:"Maíz  gema doble"},
    {prodId:"50403221",label:"Maíz  earlivee"},
    {prodId:"50403222",label:"Maíz  temprano extra dulce"},
    {prodId:"50403223",label:"Maíz excel"},
    {prodId:"50403224",label:"Maíz  cruz dorada bantam"},
    {prodId:"50403225",label:"Maíz  miel y crema"},
    {prodId:"50403226",label:"Maíz  miel y perla"},
    {prodId:"50403227",label:"Maíz  miel dulce"},
    {prodId:"50403228",label:"Maíz  hudson"},
    {prodId:"50403229",label:"Maíz  dorado illini"},
    {prodId:"50403230",label:"Maíz  illini extra dulce"},
    {prodId:"50403231",label:"Maíz  increíble"},
    {prodId:"50403232",label:"Maíz  lochief"},
    {prodId:"50403233",label:"Maíz jubileo"},
    {prodId:"50403234",label:"Maíz jupbileo super dulce"},
    {prodId:"50403235",label:"Maíz  confite korn"},
    {prodId:"50403236",label:"Maíz  beso y acuso"},
    {prodId:"50403237",label:"Maíz  lancelot"},
    {prodId:"50403238",label:"Maíz dulce maple"},
    {prodId:"50403239",label:"Maíz  medley"},
    {prodId:"50403240",label:"Maíz merlin"},
    {prodId:"50403241",label:"Maíz milagro"},
    {prodId:"50403242",label:"Maíz nk - 199"},
    {prodId:"50403243",label:"Maíz durazno y crema"},
    {prodId:"50403244",label:"Maíz blanco perla"},
    {prodId:"50403245",label:"Maíz  pegasus"},
    {prodId:"50403246",label:"Maíz  fenomenal"},
    {prodId:"50403247",label:"Maíz  dama de platino"},
    {prodId:"50403248",label:"Maíz  precioso"},
    {prodId:"50403249",label:"Maíz   pristine"},
    {prodId:"50403250",label:"Maíz  rápido"},
    {prodId:"50403251",label:"Maíz  radiante"},
    {prodId:"50403252",label:"Maíz  seneca brave"},
    {prodId:"50403253",label:"Maíz  amanecer seneca"},
    {prodId:"50403254",label:"Maíz  horizonte seneca"},
    {prodId:"50403255",label:"Maíz  brillo de estrella seneca"},
    {prodId:"50403256",label:"Maíz  noche blanca seneca"},
    {prodId:"50403257",label:"Maíz  showcase"},
    {prodId:"50403258",label:"Maíz  reina plateada"},
    {prodId:"50403259",label:"Maíz  bella nieve"},
    {prodId:"50403260",label:"Maíz nieve de primavera"},
    {prodId:"50403261",label:"Maíz  premio de primavera"},
    {prodId:"50403262",label:"Maíz  azúcar y oro"},
    {prodId:"50403263",label:"Maíz rollo de azúcar"},
    {prodId:"50403264",label:"Maíz nieve de azúcar"},
    {prodId:"50403265",label:"Maíz baile de sol"},
    {prodId:"50403266",label:"Maíz estrella tell"},
    {prodId:"50403267",label:"Maíz terminador"},
    {prodId:"50403268",label:"Maíz tesoro"},
    {prodId:"50403269",label:"Maíz tuxedo"},
    {prodId:"50403300",label:"Berros"},
    {prodId:"50403301",label:"Berros de tierra"},
    {prodId:"50403302",label:"Nasturtium"},
    {prodId:"50403303",label:"Berros de agua"},
    {prodId:"50403304",label:"Berros de invierno"},
    {prodId:"50403400",label:"Cocombros"},
    {prodId:"50403401",label:"Cocombro arena"},
    {prodId:"50403402",label:"Cocombro americano"},
    {prodId:"50403403",label:"Cocombro atena"},
    {prodId:"50403404",label:"Cocombro blanco lungo de parigi"},
    {prodId:"50403405",label:"Cocombro verde burpless buen sabor"},
    {prodId:"50403406",label:"Cocombro chicago pickling"},
    {prodId:"50403407",label:"Cocombro manzana cristal"},
    {prodId:"50403408",label:"Cocombro limón cristal"},
    {prodId:"50403409",label:"Cocombro danimas"},
    {prodId:"50403410",label:"Cocombro gherkin"},
    {prodId:"50403411",label:"Cocombro hokus"},
    {prodId:"50403412",label:"Cocombro japonés"},
    {prodId:"50403413",label:"Cocombro karela"},
    {prodId:"50403414",label:"Cocombro korila"},
    {prodId:"50403415",label:"Cocombro verde largo mejorado"},
    {prodId:"50403416",label:"Cocombro mas mercado"},
    {prodId:"50403417",label:"Cocombro enano"},
    {prodId:"50403418",label:"Cocombro pickling nacional"},
    {prodId:"50403419",label:"Cocombro persa"},
    {prodId:"50403420",label:"Cocombro telégrafo"},
    {prodId:"50403421",label:"Cocombro telégrafo mejorado"},
    {prodId:"50403422",label:"Cocombro vert de massy cornichon"},
    {prodId:"50403423",label:"Cocombro yamato"},
    {prodId:"50403500",label:"Berenjenas"},
    {prodId:"50403501",label:"Berenjena bambino"},
    {prodId:"50403502",label:"Berenjena  belleza negra"},
    {prodId:"50403503",label:"Berenjena  negra enorme"},
    {prodId:"50403504",label:"Berenjena  chino"},
    {prodId:"50403505",label:"Berenjena  huevo de pascua"},
    {prodId:"50403506",label:"Berenjena  filipino"},
    {prodId:"50403507",label:"Berenjena   mercado de florida"},
    {prodId:"50403508",label:"Berenjena  india"},
    {prodId:"50403509",label:"Berenjena  italiano"},
    {prodId:"50403510",label:"Berenjena  japonés"},
    {prodId:"50403511",label:"Berenjena  morado largo"},
    {prodId:"50403512",label:"Berenjena   rayas larga"},
    {prodId:"50403513",label:"Berenjena  mercado de dinero"},
    {prodId:"50403514",label:"Berenjena  ova"},
    {prodId:"50403515",label:"Berenjena   arveja"},
    {prodId:"50403516",label:"Berenjena  tom bajito"},
    {prodId:"50403517",label:"Berenjena  siciliano"},
    {prodId:"50403518",label:"Berenjena  tailandés"},
    {prodId:"50403519",label:"Berenjena  violeta de florencia"},
    {prodId:"50403520",label:"Berenjena  blanco"},
    {prodId:"50403700",label:"Hinojos"},
    {prodId:"50403701",label:"Hinojo cantino"},
    {prodId:"50403702",label:"Hinojo fino"},
    {prodId:"50403703",label:"Hinojoherald"},
    {prodId:"50403704",label:"Hinojo perfección"},
    {prodId:"50403705",label:"Hinojo sirio"},
    {prodId:"50403706",label:"Hinojo florencia dulce"},
    {prodId:"50403707",label:"Hinojo tardo"},
    {prodId:"50403800",label:"Ajos"},
    {prodId:"50403801",label:"Ajo tarde california"},
    {prodId:"50403802",label:"Ajo tallos chinos"},
    {prodId:"50403803",label:"Ajo cebollin"},
    {prodId:"50403804",label:"Ajo germidor"},
    {prodId:"50403805",label:"Ajo guardado largo"},
    {prodId:"50403806",label:"Ajo ramson"},
    {prodId:"50403807",label:"Ajo rocambola"},
    {prodId:"50403808",label:"Ajo rosa de lautrec"},
    {prodId:"50403809",label:"Ajo solent blanco"},
    {prodId:"50403810",label:"Ajo morado español"},
    {prodId:"50403811",label:"Ajo valentina italiana"},
    {prodId:"50403900",label:"Calabazas"},
    {prodId:"50403901",label:"Petula"},
    {prodId:"50403902",label:"Melon amargo"},
    {prodId:"50403903",label:"Botella calabaza"},
    {prodId:"50403904",label:"Calabash gounds"},
    {prodId:"50403905",label:"Melón peludo"},
    {prodId:"50403906",label:"Calabaza amarga"},
    {prodId:"50403907",label:"Loofah suave"},
    {prodId:"50403908",label:"Serpiente de calabaza"},
    {prodId:"50403909",label:"Calabaza larga amarga"},
    {prodId:"50403910",label:"Calabaza tinda"},
    {prodId:"50403911",label:"Calabaza tindoori"},
    {prodId:"50404000",label:"Guisantes"},
    {prodId:"50404001",label:"Guisante chino"},
    {prodId:"50404002",label:"Guisante inglés"},
    {prodId:"50404003",label:"Guisante de jardín"},
    {prodId:"50404004",label:"Guisante nieve"},
    {prodId:"50404006",label:"Guisante snap dulce"},
    {prodId:"50404100",label:"Hierbas"},
    {prodId:"50404101",label:"Albahaca"},
    {prodId:"50404102",label:"Laurel"},
    {prodId:"50404103",label:"Borraja"},
    {prodId:"50404104",label:"Alcaravea"},
    {prodId:"50404105",label:"Perifollo"},
    {prodId:"50404106",label:"Cilantro"},
    {prodId:"50404107",label:"Chipolinos"},
    {prodId:"50404108",label:"Hoja de curry"},
    {prodId:"50404109",label:"Eneldo"},
    {prodId:"50404110",label:"Epazote"},
    {prodId:"50404111",label:"Fenogreco"},
    {prodId:"50404112",label:"Limonaria"},
    {prodId:"50404113",label:"Mejorana"},
    {prodId:"50404114",label:"Menta"},
    {prodId:"50404115",label:"Orégano"},
    {prodId:"50404116",label:"Pápalo"},
    {prodId:"50404117",label:"Pápalo delgado"},
    {prodId:"50404118",label:"Perilla"},
    {prodId:"50404119",label:"Recao"},
    {prodId:"50404120",label:"Romero"},
    {prodId:"50404121",label:"Salvia"},
    {prodId:"50404122",label:"Salsifí"},
    {prodId:"50404123",label:"Satureja"},
    {prodId:"50404124",label:"Estragón"},
    {prodId:"50404125",label:"Tomillo"},
    {prodId:"50404126",label:"Tumeric"},
    {prodId:"50404127",label:"Verdolaga"},
    {prodId:"50404200",label:"Coles"},
    {prodId:"50404201",label:"Col rizada"},
    {prodId:"50404202",label:"Repollo verde"},
    {prodId:"50404300",label:"Colinabos"},
    {prodId:"50404301",label:"Colinabo estrella azur"},
    {prodId:"50404302",label:"Colinabo verde viena"},
    {prodId:"50404303",label:"Colinabo lanro"},
    {prodId:"50404304",label:"Colinabo viena morada"},
    {prodId:"50404305",label:"Colinabo trero rowel"},
    {prodId:"50404306",label:"Colinabo viena blanca"},
    {prodId:"50404400",label:"Puerros"},
    {prodId:"50404401",label:"Puerro cobra gigante otoño"},
    {prodId:"50404402",label:"Puerro otoño mamut 2"},
    {prodId:"50404403",label:"Puerro blue de solaise"},
    {prodId:"50404404",label:"Puerro cortina"},
    {prodId:"50404405",label:"Puerro prelina"},
    {prodId:"50404406",label:"Puerro salvaje"},
    {prodId:"50404500",label:"Lentejas"},
    {prodId:"50404501",label:"Lenteja beluga"},
    {prodId:"50404502",label:"Lenteja  verde francesa"},
    {prodId:"50404503",label:"Lenteja verde"},
    {prodId:"50404504",label:"Lenteja crimson pequeña"},
    {prodId:"50404505",label:"Lenteja pardina española"},
    {prodId:"50404506",label:"Lenteja roja separada"},
    {prodId:"50404507",label:"Lenteja amarilla separad"},
    {prodId:"50404508",label:"Lenteja tarahumara rosada"},
    {prodId:"50404600",label:"Lechugas"},
    {prodId:"50404601",label:"Lechuga  bibb"},
    {prodId:"50404602",label:"Lechuga  boston"},
    {prodId:"50404603",label:"Lechuga frisee"},
    {prodId:"50404604",label:"Lechuga rosa lola"},
    {prodId:"50404605",label:"Lechuga  mixta masculina"},
    {prodId:"50404606",label:"Lechuga mizuna"},
    {prodId:"50404607",label:"Lechuga hoja roja"},
    {prodId:"50404608",label:"Lechuga oja roja roble"},
    {prodId:"50404609",label:"Lechuga romana ruby"},
    {prodId:"50404610",label:"Lechuga romana bebé roja"},
    {prodId:"50404611",label:"Lechuga cabeza de mantequilla"},
    {prodId:"50404612",label:"Lechuga china"},
    {prodId:"50404613",label:"Lechuga cabeza tostada"},
    {prodId:"50404614",label:"Lechuga hoja verde"},
    {prodId:"50404615",label:"Lechuga iceberg"},
    {prodId:"50404616",label:"Lechuga cordero"},
    {prodId:"50404617",label:"Lechuga hoja suelta"},
    {prodId:"50404618",label:"Lechuga mache"},
    {prodId:"50404619",label:"Lechuga boston roja"},
    {prodId:"50404620",label:"Lechuga cabeza roja"},
    {prodId:"50404621",label:"Lechuga romana"},
    {prodId:"50404622",label:"Lechuga mostza roja rusa"},
    {prodId:"50404623",label:"Lechuga tatsoi"},
    {prodId:"50404800",label:"Hongos o champiñones"},
    {prodId:"50404801",label:"Champiñón trompeta negra"},
    {prodId:"50404802",label:"Champiñón carmelito"},
    {prodId:"50404803",label:"Champiñón"},
    {prodId:"50404804",label:"Champiñón chanterelle"},
    {prodId:"50404805",label:"Champiñón cremini"},
    {prodId:"50404806",label:"Champiñón enoki"},
    {prodId:"50404807",label:"Champiñón puercoespín"},
    {prodId:"50404808",label:"Champiñón gallina del bosque"},
    {prodId:"50404809",label:"Champiñón langosta"},
    {prodId:"50404810",label:"Champiñón morels"},
    {prodId:"50404811",label:"Champiñón ostra"},
    {prodId:"50404812",label:"Champiñón pleurotus"},
    {prodId:"50404813",label:"Champiñón pompom"},
    {prodId:"50404814",label:"Champiñón porcieni"},
    {prodId:"50404815",label:"Champiñón portobello"},
    {prodId:"50404816",label:"Champiñón shitake"},
    {prodId:"50404817",label:"Champiñón shimeji"},
    {prodId:"50404818",label:"Champiñón san jorge"},
    {prodId:"50404819",label:"Champiñón blanco"},
    {prodId:"50404820",label:"Champiñón tompeta blanca"},
    {prodId:"50404821",label:"Champiñón woodear"},
    {prodId:"50404822",label:"Champiñón seta"},
    {prodId:"50404823",label:"Champión tonku"},
    {prodId:"50404900",label:"Mostazas"},
    {prodId:"50404902",label:"Mostaza ajo"},
    {prodId:"50404903",label:"Mostaza hoja gigante"},
    {prodId:"50404905",label:"Mostaza sureño"},
    {prodId:"50404906",label:"Mostaza corazón envuelto"},
    {prodId:"50405101",label:"Lámpara china"},
    {prodId:"50405104",label:"Tomatillo"},
    {prodId:"50405200",label:"Okras"},
    {prodId:"50405201",label:"Okra artista"},
    {prodId:"50405202",label:"Okra vinotinto"},
    {prodId:"50405203",label:"Okra sin columna clemson"},
    {prodId:"50405204",label:"Okra verde enano vaina larga"},
    {prodId:"50405205",label:"Okra  mamut sin columna"},
    {prodId:"50405206",label:"Okra terciopelo rojo"},
    {prodId:"50405207",label:"Okra estrella de david reliquia"},
    {prodId:"50405300",label:"Cebollas"},
    {prodId:"50405301",label:"Cebolla albion"},
    {prodId:"50405302",label:"Cebolla alisia craig"},
    {prodId:"50405303",label:"Cebolla hirviendo"},
    {prodId:"50405304",label:"Cebolla bufalo"},
    {prodId:"50405305",label:"Cebolla bulbo"},
    {prodId:"50405306",label:"Cebolla cremosa"},
    {prodId:"50405307",label:"Cebolla amarillo expreso o - x"},
    {prodId:"50405308",label:"Cebolla kelsae"},
    {prodId:"50405309",label:"Cebolla gigante marshal globo fen"},
    {prodId:"50405310",label:"Cebolla perla"},
    {prodId:"50405311",label:"Cebolla baron rojo"},
    {prodId:"50405312",label:"Cebolla roja"},
    {prodId:"50405313",label:"Cebolla rijnsberger"},
    {prodId:"50405314",label:"Cebolla senshyu semi - globo amarillo"},
    {prodId:"50405315",label:"Cebolla sturon"},
    {prodId:"50405316",label:"Cebolla sturgatter"},
    {prodId:"50405317",label:"Cebolla dulce"},
    {prodId:"50405318",label:"Cebolla torpedo o rojo italiana"},
    {prodId:"50405319",label:"Cebolla roja guardada"},
    {prodId:"50405320",label:"Cebollablanca guardada"},
    {prodId:"50405321",label:"Cebollaamarilla guardada"},
    {prodId:"50405322",label:"Cebollarosada"},
    {prodId:"50405323",label:"Cebolla verde"},
    {prodId:"50405500",label:"Maníes"},
    {prodId:"50405501",label:"Maní bambara"},
    {prodId:"50405502",label:"Maní florunner"},
    {prodId:"50405503",label:"Maní husa / kerstings"},
    {prodId:"50405504",label:"Maní español"},
    {prodId:"50405505",label:"Maní valencia"},
    {prodId:"50405506",label:"Maní virginia"},
    {prodId:"50405600",label:"Pimientos"},
    {prodId:"50405601",label:"Pimentón ají"},
    {prodId:"50405602",label:"Pimentón  árbol"},
    {prodId:"50405603",label:"Pimentón queso"},
    {prodId:"50405604",label:"Pimentón chilaca"},
    {prodId:"50405605",label:"Pimentón cubanelles"},
    {prodId:"50405606",label:"Pimentón fresno"},
    {prodId:"50405607",label:"Pimentón kapia"},
    {prodId:"50405608",label:"Pimentón coreano"},
    {prodId:"50405609",label:"Pimentón manzano"},
    {prodId:"50405610",label:"Pimentón melrose"},
    {prodId:"50405611",label:"Pimentón chile amarillo"},
    {prodId:"50405612",label:"Pimentón aji dulce"},
    {prodId:"50405613",label:"Pimentón anaheim"},
    {prodId:"50405614",label:"Pimentón ancho"},
    {prodId:"50405615",label:"Pimentón campana"},
    {prodId:"50405616",label:"Pimentón cascabel"},
    {prodId:"50405617",label:"Pimentón cayenne"},
    {prodId:"50405618",label:"Pimentón anfitrión cereza"},
    {prodId:"50405619",label:"Pimentón chitespin"},
    {prodId:"50405620",label:"Pimentón dedo picante"},
    {prodId:"50405621",label:"Pimentón guajillo"},
    {prodId:"50405622",label:"Pimentón guerro"},
    {prodId:"50405623",label:"Pimentón habanero"},
    {prodId:"50405624",label:"Pimentón cera de hungria"},
    {prodId:"50405625",label:"Pimentón jalapeño"},
    {prodId:"50405626",label:"Pimentón picante largo"},
    {prodId:"50405627",label:"Pimentón marasol"},
    {prodId:"50405628",label:"Pimentón pasilla"},
    {prodId:"50405630",label:"Pimentón pequin"},
    {prodId:"50405631",label:"Pimentón pimiento"},
    {prodId:"50405632",label:"Pimentón poblano"},
    {prodId:"50405633",label:"Pimentón scotch bonnet"},
    {prodId:"50405634",label:"Pimentón serrano"},
    {prodId:"50405635",label:"Pimentón tabasco"},
    {prodId:"50405636",label:"Pimentón tai"},
    {prodId:"50405637",label:"Pimentón tepin"},
    {prodId:"50405638",label:"Pimentón chili amaucho"},
    {prodId:"50405639",label:"Pimentón chili mochero"},
    {prodId:"50405640",label:"Pimentón chili limo"},
    {prodId:"50405700",label:"Papas"},
    {prodId:"50405701",label:"Papa blanca larga"},
    {prodId:"50405702",label:"Papa redonda blanca"},
    {prodId:"50405703",label:"Papa redonda roja"},
    {prodId:"50405704",label:"Papa  russet"},
    {prodId:"50405705",label:"Papa morada"},
    {prodId:"50405706",label:"Papa amarilla"},
    {prodId:"50405707",label:"Papa nueva"},
    {prodId:"50405708",label:"Papa especial"},
    {prodId:"50405709",label:"Papa huayro"},
    {prodId:"50405710",label:"Papa peruanita"},
    {prodId:"50405711",label:"Papa yunggay"},
    {prodId:"50405900",label:"Vegetales marinos"},
    {prodId:"50405901",label:"Agar-agar"},
    {prodId:"50405902",label:"Arame"},
    {prodId:"50405903",label:"Alga dulse"},
    {prodId:"50405904",label:"Habichuelas vert de mer"},
    {prodId:"50405905",label:"Hijiki"},
    {prodId:"50405906",label:"Musgo irlandes"},
    {prodId:"50405907",label:"Quelpo"},
    {prodId:"50405908",label:"Laver"},
    {prodId:"50405909",label:"Nori"},
    {prodId:"50405910",label:"Alga roja"},
    {prodId:"50405911",label:"Kale de mar"},
    {prodId:"50405913",label:"Algas de mar"},
    {prodId:"50405914",label:"Spirulina"},
    {prodId:"50405915",label:"Susabi nuri"},
    {prodId:"50405916",label:"Wakame"},
    {prodId:"50406200",label:"Espinacas"},
    {prodId:"50406201",label:"Espinaca americana"},
    {prodId:"50406202",label:"Espinaca bloomsdale"},
    {prodId:"50406203",label:"Espinaca invierno gigante"},
    {prodId:"50406204",label:"Espinaca horenso"},
    {prodId:"50406205",label:"Espinaca cuarto de cordero"},
    {prodId:"50406206",label:"Espinaca malabar"},
    {prodId:"50406207",label:"Espinaca medania"},
    {prodId:"50406208",label:"Espinaca orach"},
    {prodId:"50406209",label:"Espinaca savoy"},
    {prodId:"50406210",label:"Espinaca hoja de sigma"},
    {prodId:"50406211",label:"Espinaca espacio"},
    {prodId:"50406212",label:"Espinaca trinidad"},
    {prodId:"50406213",label:"Espinaca salvaje"},
    {prodId:"50406224",label:"Espinaca nueva zelandia"},
    {prodId:"50406225",label:"Espinaca iceplant"},
    {prodId:"50406300",label:"Calabazas"},
    {prodId:"50406301",label:"Calabaza boston"},
    {prodId:"50406302",label:"Calabaza buttemut"},
    {prodId:"50406303",label:"Calabaza costata romanesca"},
    {prodId:"50406304",label:"Calabaza crookneck"},
    {prodId:"50406305",label:"Calabaza  cucuzza"},
    {prodId:"50406306",label:"Calabaza  delicata"},
    {prodId:"50406307",label:"Calabaza  delicioso"},
    {prodId:"50406308",label:"Calabaza verano dorado cuello torsido"},
    {prodId:"50406309",label:"Calabaza prolífico temprano cuello recto"},
    {prodId:"50406310",label:"Calabaza  oro"},
    {prodId:"50406311",label:"Calabaza  jack pequeño"},
    {prodId:"50406312",label:"Calabaza  campo de kentucky"},
    {prodId:"50406313",label:"Calabaza marrow"},
    {prodId:"50406314",label:"Calabaza  medio oriente"},
    {prodId:"50406315",label:"Calabaza  miniatura"},
    {prodId:"50406316",label:"Calabaza  orangetti"},
    {prodId:"50406317",label:"Calabaza pattypan"},
    {prodId:"50406318",label:"Calabaza  rondini"},
    {prodId:"50406319",label:"Calabaza  redonda"},
    {prodId:"50406320",label:"Calabaza  espagueti"},
    {prodId:"50406321",label:"Calabaza  estripeti"},
    {prodId:"50406322",label:"Calabaza  molde dulce"},
    {prodId:"50406323",label:"Calabaza  empanada dulce"},
    {prodId:"50406324",label:"Calabaza  premio triple"},
    {prodId:"50406325",label:"Calabaza waltham butternut"},
    {prodId:"50406326",label:"Calabaza  arbusto scallop amarillo"},
    {prodId:"50406327",label:"Calabaza cuello recto amarillo"},
    {prodId:"50406328",label:"Calabaza zafiro"},
    {prodId:"50406329",label:"Calabaza zucchini"},
    {prodId:"50406400",label:"Batatas"},
    {prodId:"50406401",label:"Batata beauregard"},
    {prodId:"50406402",label:"Batata centennial"},
    {prodId:"50406403",label:"Batata diane"},
    {prodId:"50406404",label:"Batata dulce garnet"},
    {prodId:"50406405",label:"Batata georgia dulce rojo"},
    {prodId:"50406406",label:"Batata  dorado"},
    {prodId:"50406407",label:"Batata hanna"},
    {prodId:"50406408",label:"Batata japonés"},
    {prodId:"50406409",label:"Batata jersey"},
    {prodId:"50406410",label:"Batata joya"},
    {prodId:"50406411",label:"Batata meryland roja"},
    {prodId:"50406412",label:"Batata nema dorado"},
    {prodId:"50406413",label:"Batata o'henry"},
    {prodId:"50406414",label:"Batata okinawan"},
    {prodId:"50406415",label:"Batata naranja"},
    {prodId:"50406416",label:"Batata oriental"},
    {prodId:"50406417",label:"Batata jersey rojo"},
    {prodId:"50406418",label:"Batata mar rojo"},
    {prodId:"50406419",label:"Batata brillo rojo"},
    {prodId:"50406420",label:"Batata jersey amarillo"},
    {prodId:"50406421",label:"Batata morado"},
    {prodId:"50406500",label:"Tomates"},
    {prodId:"50406501",label:"Tomates ailsa craig"},
    {prodId:"50406502",label:"Tomates alicante"},
    {prodId:"50406503",label:"Tomates ciruela negra"},
    {prodId:"50406504",label:"Tomates vino de brandy"},
    {prodId:"50406505",label:"Tomates bella cereza"},
    {prodId:"50406506",label:"Tomates cereza"},
    {prodId:"50406507",label:"Tomates delicioso"},
    {prodId:"50406508",label:"Tomates dombito"},
    {prodId:"50406509",label:"Tomates delicia del jardín"},
    {prodId:"50406510",label:"Tomates uva"},
    {prodId:"50406511",label:"Tomates verde"},
    {prodId:"50406512",label:"Tomates super marmande"},
    {prodId:"50406513",label:"Tomates rayas naturales marvel"},
    {prodId:"50406514",label:"Tomates minibel"},
    {prodId:"50406515",label:"Tomates oaxacan"},
    {prodId:"50406516",label:"Tomates alerta roja"},
    {prodId:"50406517",label:"Tomates roma vf"},
    {prodId:"50406518",label:"Tomates marzano zan"},
    {prodId:"50406519",label:"Tomates shirley"},
    {prodId:"50406520",label:"Tomates siberia"},
    {prodId:"50406521",label:"Tomates super beefsteak"},
    {prodId:"50406522",label:"Tomates tigerella"},
    {prodId:"50406523",label:"Tomates tiny tim"},
    {prodId:"50406524",label:"Tomates tumbler"},
    {prodId:"50406525",label:"Tomates cocktail amarillo"},
    {prodId:"50406526",label:"Tomates forma de pera amarillo"},
    {prodId:"50406527",label:"Tomates perfección amarilla"},
    {prodId:"50406700",label:"Auyamas"},
    {prodId:"50406701",label:"Calabaza acorn"},
    {prodId:"50406702",label:"Calabaza gigante atlántico"},
    {prodId:"50406703",label:"Calabaza banana rosado"},
    {prodId:"50406704",label:"Calabaza max grande"},
    {prodId:"50406705",label:"Calabaza"},
    {prodId:"50406706",label:"Calabaza carnaval"},
    {prodId:"50406707",label:"Auyama queso"},
    {prodId:"50406708",label:"Calabaza príncipe coronado"},
    {prodId:"50406709",label:"Calabaza  curcibita"},
    {prodId:"50406710",label:"Calabaza cushaw"},
    {prodId:"50406711",label:"Calabaza  gigante auyama"},
    {prodId:"50406712",label:"Calabaza  hubbard"},
    {prodId:"50406713",label:"Calabaza  jerrahdale"},
    {prodId:"50406714",label:"Calabaza  kabocha"},
    {prodId:"50406715",label:"Calabaza queensland azul"},
    {prodId:"50406716",label:"Calabaza rouge vif détampes"},
    {prodId:"50406717",label:"Calabaza  turban turco"},
    {prodId:"50406718",label:"Calabaza  valenciano"},
    {prodId:"50406719",label:"Calabaza  warted hubbard"},
    {prodId:"50406720",label:"Auyama whangaparoa"},
    {prodId:"50406721",label:"Auyama china"},
    {prodId:"50406722",label:"Auyama  loche"},
    {prodId:"50406723",label:"Auyama  macre"},
    {prodId:"50407000",label:"Vegetales Nominant"},
    {prodId:"50407001",label:"Alfalfa"},
    {prodId:"50407002",label:"Hojas de aloe"},
    {prodId:"50407003",label:"Apio"},
    {prodId:"50407004",label:"Arrurruz"},
    {prodId:"50407005",label:"Punta de flecha"},
    {prodId:"50407006",label:"Rúcula"},
    {prodId:"50407007",label:"Arum"},
    {prodId:"50407008",label:"Raíz de bambú"},
    {prodId:"50407009",label:"Hojas de platano"},
    {prodId:"50407010",label:"Batatas"},
    {prodId:"50407011",label:"Brotes de frijol"},
    {prodId:"50407012",label:"Top de remolacha"},
    {prodId:"50407013",label:"Melón agrio"},
    {prodId:"50407014",label:"Bayas de alcaparra"},
    {prodId:"50407015",label:"Carob"},
    {prodId:"50407016",label:"Cha-om"},
    {prodId:"50407017",label:"Chayote o guatila"},
    {prodId:"50407018",label:"Garbanzo"},
    {prodId:"50407019",label:"Verde de crisantemos"},
    {prodId:"50407020",label:"Verde de diente de león"},
    {prodId:"50407021",label:"Diente de león"},
    {prodId:"50407022",label:"Dasheen"},
    {prodId:"50407023",label:"Puntas de alverja"},
    {prodId:"50407024",label:"Diakon"},
    {prodId:"50407025",label:"Donqua"},
    {prodId:"50407026",label:"Helecho"},
    {prodId:"50407027",label:"Gai choi"},
    {prodId:"50407028",label:"Gailon"},
    {prodId:"50407029",label:"Galanga"},
    {prodId:"50407030",label:"Raíz de jengibre"},
    {prodId:"50407031",label:"Gobo"},
    {prodId:"50407032",label:"Brote de lúpulo"},
    {prodId:"50407033",label:"Rabano blanco"},
    {prodId:"50407034",label:"Jicama"},
    {prodId:"50407035",label:"Kuduz"},
    {prodId:"50407036",label:"Bulbo de margarita"},
    {prodId:"50407037",label:"Linkok"},
    {prodId:"50407038",label:"Lo bok"},
    {prodId:"50407039",label:"Frijol largo"},
    {prodId:"50407040",label:"Raíz de lotus"},
    {prodId:"50407041",label:"Hojas de maguey"},
    {prodId:"50407042",label:"Mallows"},
    {prodId:"50407043",label:"Sapote mamey"},
    {prodId:"50407044",label:"Moap"},
    {prodId:"50407045",label:"Moo"},
    {prodId:"50407046",label:"Moqua"},
    {prodId:"50407047",label:"Opos"},
    {prodId:"50407048",label:"Corazon de palma"},
    {prodId:"50407049",label:"Paprika"},
    {prodId:"50407050",label:"Purslane"},
    {prodId:"50407051",label:"Raddichios"},
    {prodId:"50407052",label:"Sinquas"},
    {prodId:"50407053",label:"Frijol de soya"},
    {prodId:"50407054",label:"Spoonwart"},
    {prodId:"50407055",label:"Uva tassele hyacinth"},
    {prodId:"50407056",label:"Taro"},
    {prodId:"50407057",label:"Hoja de taro"},
    {prodId:"50407058",label:"Tallo de taro"},
    {prodId:"50407059",label:"Tapeguaje"},
    {prodId:"50407060",label:"Verde tierno"},
    {prodId:"50407061",label:"Tindora"},
    {prodId:"50407062",label:"Árbol de cebolla"},
    {prodId:"50407063",label:"Udo"},
    {prodId:"50407064",label:"Agua castaño"},
    {prodId:"50407065",label:"Agua espinaca"},
    {prodId:"50407066",label:"Yumpi"},
    {prodId:"50407067",label:"Yautia"},
    {prodId:"50407068",label:"Yu choy"},
    {prodId:"50407070",label:"Yuca"},
    {prodId:"50407071",label:"Caigua"},
    {prodId:"50407100",label:"Alverjas dulces"},
    {prodId:"50407101",label:"Alverja biquini"},
    {prodId:"50407102",label:"Alverja cavalier"},
    {prodId:"50407103",label:"Alverja margarita"},
    {prodId:"50407104",label:"Alverja darfon"},
    {prodId:"50407105",label:"Alverja early onward"},
    {prodId:"50407106",label:"Alverja primer feltham"},
    {prodId:"50407107",label:"Alverja verde hurst shaft"},
    {prodId:"50407108",label:"Guisante oregón sugar pod"},
    {prodId:"50407109",label:"Alverja príncipe alberto"},
    {prodId:"50407110",label:"Alverja reuzensuiker"},
    {prodId:"50407200",label:"Tubérculos"},
    {prodId:"50407201",label:"Arracacha"},
    {prodId:"50407202",label:"Maca"},
    {prodId:"50407203",label:"Oca"},
    {prodId:"50407204",label:"Olluco"},
    {prodId:"50407205",label:"Mashua"},
    {prodId:"50411800",label:"Fríjoles orgánicos"},
    {prodId:"50411801",label:"Frijoles anasazi™"},
    {prodId:"50411802",label:"Frijoles appaloosa orgánicos"},
    {prodId:"50411803",label:"Frijoles azuki orgánicos"},
    {prodId:"50411804",label:"Frijoles barlotti orgánicos"},
    {prodId:"50411805",label:"Frijoles appaloosa negra orgánicos"},
    {prodId:"50411806",label:"Frijoles negros orgánicos"},
    {prodId:"50411807",label:"Frijoles gran negros orgánicos"},
    {prodId:"50411808",label:"Frijoles shackamaxon negro orgánicos"},
    {prodId:"50411809",label:"Frijoles ojo negro orgánicos"},
    {prodId:"50411810",label:"Frijoles bobby orgánicos"},
    {prodId:"50411811",label:"Frijoles bolita orgánicos"},
    {prodId:"50411812",label:"Frijoles esposa perezosa carmelita orgánicos"},
    {prodId:"50411813",label:"Frijoles calipso orgánicos"},
    {prodId:"50411814",label:"Frijoles canelini orgánicos"},
    {prodId:"50411815",label:"Frijoles castor orgánicos"},
    {prodId:"50411816",label:"Frijoles amarillo chino orgánicos"},
    {prodId:"50411817",label:"Frijoles lengua de dragón orgánicos"},
    {prodId:"50411818",label:"Frijoles soldado europeo orgánicos"},
    {prodId:"50411819",label:"Frijoles fava orgánicos"},
    {prodId:"50411820",label:"Frijoles flageolet orgánicos"},
    {prodId:"50411821",label:"Frijoles  horticultura francesa orgánicos"},
    {prodId:"50411822",label:"Frijoles marina francesa orgánicos"},
    {prodId:"50411823",label:"Frijoles coco gigante blanco orgánicos"},
    {prodId:"50411824",label:"Frijoles verdes orgánicos"},
    {prodId:"50411825",label:"Frijoles romano verde orgánicos"},
    {prodId:"50411826",label:"Frijoles guar gum orgánicos"},
    {prodId:"50411827",label:"Frijoles haricot orgánicos"},
    {prodId:"50411828",label:"Frijoles hyacinth orgánicos"},
    {prodId:"50411829",label:"Frijoles tipo italiano orgánicos"},
    {prodId:"50411830",label:"Frijoles asombro jackson orgánicos"},
    {prodId:"50411831",label:"Frijoles ganado jacobs  orgánicos"},
    {prodId:"50411832",label:"Frijoles asombro kentucky orgánicos"},
    {prodId:"50411833",label:"Frijoles riñón orgánicos"},
    {prodId:"50411834",label:"Frijoles lima orgánicos"},
    {prodId:"50411835",label:"Frijoles madera orgánicos"},
    {prodId:"50411836",label:"Frijoles medula orgánicos"},
    {prodId:"50411837",label:"Frijoles mat orgánicos"},
    {prodId:"50411838",label:"Frijoles monstoler ganzo salvaje orgánicos"},
    {prodId:"50411839",label:"Frijoles  mortgage lifter orgánicos"},
    {prodId:"50411840",label:"Frijoles polilla orgánicos"},
    {prodId:"50411841",label:"Frijoles mung orgánicos"},
    {prodId:"50411842",label:"Frijoles munsi wolf orgánicos"},
    {prodId:"50411843",label:"Frijoles nuna orgánicos"},
    {prodId:"50411844",label:"Frijoles pinto orgánicos"},
    {prodId:"50411845",label:"Frijoles runner orgánicos"},
    {prodId:"50411846",label:"Frijoles string orgánicos"},
    {prodId:"50411847",label:"Frijoles habichuelas orgánicos"},
    {prodId:"50411848",label:"Frijoles tamarind orgánicos"},
    {prodId:"50411849",label:"Frijoles tonka orgánicos"},
    {prodId:"50411850",label:"Frijoles cera orgánicos"},
    {prodId:"50411851",label:"Frijoles winged orgánicos"},
    {prodId:"50411852",label:"Frijoles largos orgánicos"},
    {prodId:"50411900",label:"Remolachas orgánicas"},
    {prodId:"50411901",label:"Remolacha acción orgánicos"},
    {prodId:"50411902",label:"Remolacha albina vereduna orgánica"},
    {prodId:"50411903",label:"Remolacha barababiotela de chiggia orgánica"},
    {prodId:"50411904",label:"Remolacha boltardy orgánica"},
    {prodId:"50411905",label:"Remolacha bonel orgánica"},
    {prodId:"50411906",label:"Remolacha burpees dorado orgánica"},
    {prodId:"50411907",label:"Remolacha cheltenham tapa verde orgánica"},
    {prodId:"50411908",label:"Remolacha cheltenham mono orgánica"},
    {prodId:"50411909",label:"Remolacha chioggia orgánica"},
    {prodId:"50411910",label:"Remolacha cilindra orgánica"},
    {prodId:"50411911",label:"Remolacha dégypte orgánica"},
    {prodId:"50411912",label:"Remolacha detroit 2 rojo oscuro orgánica"},
    {prodId:"50411913",label:"Remolacha detroit 2 bala chiquita orgánica"},
    {prodId:"50411914",label:"Remolacha egipcia plana  orgánica"},
    {prodId:"50411915",label:"Remolacha  raíz de nabo egipcio orgánica"},
    {prodId:"50411916",label:"Remolacha fomanova orgánica"},
    {prodId:"50411917",label:"Remolacha forono orgánica"},
    {prodId:"50411918",label:"Remolacha monaco orgánica"},
    {prodId:"50411919",label:"Remolacha monograma orgánica"},
    {prodId:"50411920",label:"Remolacha pronto orgánica"},
    {prodId:"50411921",label:"Remolacha regalía orgánica"},
    {prodId:"50411922",label:"Remolacha dulce orgánica"},
    {prodId:"50412000",label:"Brócolis orgánicas"},
    {prodId:"50412001",label:"Brócoli orgánico"},
    {prodId:"50412002",label:"Brócoli romanesco orgánico"},
    {prodId:"50412003",label:"Brócoli raab orgánico"},
    {prodId:"50412004",label:"Brócoli chino orgánico"},
    {prodId:"50412100",label:"Repollitos de Bruselas orgánicas"},
    {prodId:"50412101",label:"Repollitas de bruselas citade orgánicas"},
    {prodId:"50412102",label:"Repollitas de bruselas falstaff orgánicas"},
    {prodId:"50412103",label:"Repollitas de bruselas oliver orgánicas"},
    {prodId:"50412104",label:"Repollitas de bruselas peer gynt orgánicas"},
    {prodId:"50412105",label:"Repollitas de bruselas rampart orgánicas"},
    {prodId:"50412106",label:"Repollitas de bruselas rubine orgánicas"},
    {prodId:"50412107",label:"Repollitas de bruselas  widgeon orgánicas"},
    {prodId:"50412800",label:"Acelgas orgánicas"},
    {prodId:"50412801",label:"Acelga  fordhook gigante orgánica"},
    {prodId:"50412802",label:"Acelga gigantelucullus orgánica"},
    {prodId:"50412803",label:"Acelga espinaca perpetua orgánica"},
    {prodId:"50412804",label:"Acelga ruibarbo perpetua orgánica"},
    {prodId:"50412805",label:"Acelga suizo orgánica"},
    {prodId:"50412806",label:"Acelga volcan orgánica"},
    {prodId:"50412807",label:"Acelga rey blanco orgánica"},
    {prodId:"50413100",label:"Cebollines orgánicos"},
    {prodId:"50413101",label:"Cebollín chino orgánico"},
    {prodId:"50413300",label:"Cocombros  orgánicos"},
    {prodId:"50413301",label:"Cocombro arena orgánico"},
    {prodId:"50413302",label:"Cocombro americano orgánico"},
    {prodId:"50413303",label:"Cocombro atena orgánico"},
    {prodId:"50413304",label:"Cocombro blanco lungo de parigi orgánico"},
    {prodId:"50413305",label:"Cocombro verde burpless buen sabor orgánico"},
    {prodId:"50413306",label:"Cocombro chicago pickling orgánico"},
    {prodId:"50413307",label:"Cocombro manzana cristal orgánico"},
    {prodId:"50413308",label:"Cocombro limón cristal orgánico"},
    {prodId:"50413309",label:"Cocombro danimas orgánico"},
    {prodId:"50413310",label:"Cocombro gherkin orgánico"},
    {prodId:"50413311",label:"Cocombro hokus orgánico"},
    {prodId:"50413312",label:"Cocombro japonés orgánico"},
    {prodId:"50413313",label:"Cocombro karela orgánico"},
    {prodId:"50413314",label:"Cocombro korila orgánico"},
    {prodId:"50413315",label:"Cocombro verde largo mejorado orgánico"},
    {prodId:"50413316",label:"Cocombro mas mercado orgánico"},
    {prodId:"50413317",label:"Cocombro enano orgánico"},
    {prodId:"50413318",label:"Cocombro pickling nacional orgánico"},
    {prodId:"50413319",label:"Cocombro persa orgánico"},
    {prodId:"50413320",label:"Cocombro telégrafo orgánico"},
    {prodId:"50413321",label:"Cocombro telégrafo mejorado orgánico"},
    {prodId:"50413322",label:"Cocombro vert de massy cornichon orgánico"},
    {prodId:"50413323",label:"Cocombro yamato orgánico"},
    {prodId:"50413400",label:"Berenjenas  orgánicas"},
    {prodId:"50413401",label:"Berenjena bambino orgánica"},
    {prodId:"50413402",label:"Berenjena  belleza negra orgánica"},
    {prodId:"50413403",label:"Berenjena  negra enorme orgánica"},
    {prodId:"50413404",label:"Berenjena  chino orgánica"},
    {prodId:"50413405",label:"Berenjena  huevo de pascua orgánica"},
    {prodId:"50413406",label:"Berenjena  filipino orgánica"},
    {prodId:"50413407",label:"Berenjena   mercado de florida orgánica"},
    {prodId:"50413408",label:"Berenjena  india orgánica"},
    {prodId:"50413409",label:"Berenjena  italiano orgánica"},
    {prodId:"50413410",label:"Berenjena  japonés orgánica"},
    {prodId:"50413411",label:"Berenjena  morado largo orgánica"},
    {prodId:"50413412",label:"Berenjena   rayas larga orgánica"},
    {prodId:"50413413",label:"Berenjena  mercado de dinero orgánica"},
    {prodId:"50413414",label:"Berenjena  ova orgánica"},
    {prodId:"50413415",label:"Berenjena   arveja orgánica"},
    {prodId:"50413416",label:"Berenjena  tom bajito orgánica"},
    {prodId:"50413417",label:"Berenjena  siciliano orgánica"},
    {prodId:"50413418",label:"Berenjena  tailandés orgánica"},
    {prodId:"50413419",label:"Berenjena  violeta de florencia orgánica"},
    {prodId:"50413420",label:"Berenjena  blanco orgánica"},
    {prodId:"50413800",label:"Calabazas orgánicas"},
    {prodId:"50413801",label:"Petula orgánica"},
    {prodId:"50413802",label:"Melon amargo orgánico"},
    {prodId:"50413803",label:"Botella calabaza orgánica"},
    {prodId:"50413804",label:"Calabash gounds orgánico"},
    {prodId:"50413805",label:"Melón peludo orgánico"},
    {prodId:"50413806",label:"Calabaza amarga orgánica"},
    {prodId:"50413807",label:"Loofah suave orgánico"},
    {prodId:"50413808",label:"Serpiente de calabaza orgánica"},
    {prodId:"50413809",label:"Calabaza larga amarga orgánica"},
    {prodId:"50413810",label:"Calabaza tinda orgánica"},
    {prodId:"50413811",label:"Calabaza tindoori orgánica"},
    {prodId:"50413900",label:"Guisantes orgánicos"},
    {prodId:"50413901",label:"Guisante chino orgánica"},
    {prodId:"50413902",label:"Guisante inglés orgánica"},
    {prodId:"50413903",label:"Guisante de jardín orgánica"},
    {prodId:"50413904",label:"Guisante nieve orgánica"},
    {prodId:"50413906",label:"Guisante snap dulce orgánica"},
    {prodId:"50414000",label:"Hierbas orgánicas"},
    {prodId:"50414001",label:"Albahaca orgánica"},
    {prodId:"50414002",label:"Laurel orgánico"},
    {prodId:"50414003",label:"Borraja orgánica"},
    {prodId:"50414004",label:"Alcaravea orgánica"},
    {prodId:"50414005",label:"Perifollo orgánico"},
    {prodId:"50414006",label:"Cilantro orgánico"},
    {prodId:"50414007",label:"Chipolinos orgánicos"},
    {prodId:"50414008",label:"Hoja de curry orgánica"},
    {prodId:"50414009",label:"Eneldo orgánico"},
    {prodId:"50414010",label:"Epazote orgánico"},
    {prodId:"50414011",label:"Fenogreco orgánico"},
    {prodId:"50414012",label:"Limonaria orgánica"},
    {prodId:"50414013",label:"Mejorana orgánica"},
    {prodId:"50414014",label:"Menta orgánica"},
    {prodId:"50414015",label:"Orégano orgánico"},
    {prodId:"50414016",label:"Pápalo orgánico"},
    {prodId:"50414017",label:"Pápalo delgado orgánico"},
    {prodId:"50414018",label:"Perilla orgánica"},
    {prodId:"50414019",label:"Recao orgánico"},
    {prodId:"50414020",label:"Romero orgánico"},
    {prodId:"50414021",label:"Salvia orgánica"},
    {prodId:"50414022",label:"Salsifí orgánica"},
    {prodId:"50414023",label:"Satureja orgánica"},
    {prodId:"50414024",label:"Estragón orgánico"},
    {prodId:"50414025",label:"Tomillo orgánico"},
    {prodId:"50414026",label:"Tumeric orgánico"},
    {prodId:"50414027",label:"Verdolaga orgánica"},
    {prodId:"50414500",label:"Lechugas  orgánicas"},
    {prodId:"50414501",label:"Lechuga  bibb orgánica"},
    {prodId:"50414502",label:"Lechuga  boston orgánica"},
    {prodId:"50414503",label:"Lechuga frisee orgánica"},
    {prodId:"50414504",label:"Lechuga rosa lola orgánica"},
    {prodId:"50414505",label:"Lechuga  mixta masculina orgánica"},
    {prodId:"50414506",label:"Lechuga mizuna orgánica"},
    {prodId:"50414507",label:"Lechuga hoja roja orgánica"},
    {prodId:"50414508",label:"Lechuga oja roja roble orgánica"},
    {prodId:"50414509",label:"Lechuga romana ruby orgánica"},
    {prodId:"50414510",label:"Lechuga romana bebé roja orgánica"},
    {prodId:"50414511",label:"Lechuga cabeza de mantequilla orgánica"},
    {prodId:"50414512",label:"Lechuga china orgánica"},
    {prodId:"50414513",label:"Lechuga cabeza tostada orgánica"},
    {prodId:"50414514",label:"Lechuga hoja verde orgánica"},
    {prodId:"50414515",label:"Lechuga iceberg orgánica"},
    {prodId:"50414516",label:"Lechuga cordero orgánica"},
    {prodId:"50414517",label:"Lechuga hoja suelta orgánica"},
    {prodId:"50414518",label:"Lechuga mache orgánica"},
    {prodId:"50414519",label:"Lechuga boston roja orgánica"},
    {prodId:"50414520",label:"Lechuga cabeza roja orgánica"},
    {prodId:"50414521",label:"Lechuga romana orgánica"},
    {prodId:"50414522",label:"Lechuga mostza roja rusa orgánica"},
    {prodId:"50414523",label:"Lechuga tatsoi orgánica"},
    {prodId:"50414700",label:"Hongos o champiñones  orgánicos"},
    {prodId:"50414701",label:"Champiñón trompeta negra orgánico"},
    {prodId:"50414702",label:"Champiñón carmelito orgánico"},
    {prodId:"50414703",label:"Champiñón  orgánico"},
    {prodId:"50414704",label:"Champiñón chanterelle orgánico"},
    {prodId:"50414705",label:"Champiñón cremini orgánico"},
    {prodId:"50414706",label:"Champiñón enoki orgánico"},
    {prodId:"50414707",label:"Champiñón puercoespín orgánico"},
    {prodId:"50414708",label:"Champiñón gallina del bosque orgánico"},
    {prodId:"50414709",label:"Champiñón langosta orgánico"},
    {prodId:"50414710",label:"Champiñón morels orgánico"},
    {prodId:"50414711",label:"Champiñón ostra orgánico"},
    {prodId:"50414712",label:"Champiñón pleurotus orgánico"},
    {prodId:"50414713",label:"Champiñón pompom orgánico"},
    {prodId:"50414714",label:"Champiñón porcieni orgánico"},
    {prodId:"50414715",label:"Champiñón portobello orgánico"},
    {prodId:"50414716",label:"Champiñón shitake orgánico"},
    {prodId:"50414717",label:"Champiñón shimeji orgánico"},
    {prodId:"50414718",label:"Champiñón san jorge orgánico"},
    {prodId:"50414719",label:"Champiñón blanco orgánico"},
    {prodId:"50414720",label:"Champiñón trompeta blanca orgánico"},
    {prodId:"50414721",label:"Champiñón woodear orgánico"},
    {prodId:"50415100",label:"Okras  orgánicas"},
    {prodId:"50415101",label:"Okra artista orgánica"},
    {prodId:"50415102",label:"Okra vinotinto orgánica"},
    {prodId:"50415103",label:"Okra sin columna clemson orgánica"},
    {prodId:"50415104",label:"Okra verde enano vaina larga orgánica"},
    {prodId:"50415105",label:"Okra  mamut sin columna orgánica"},
    {prodId:"50415106",label:"Okra terciopelo rojo orgánica"},
    {prodId:"50415107",label:"Okra estrella de david reliquia orgánica"},
    {prodId:"50415200",label:"Cebollas  orgánicas"},
    {prodId:"50415201",label:"Cebolla albion orgánica"},
    {prodId:"50415202",label:"Cebolla alisia craig orgánica"},
    {prodId:"50415203",label:"Cebolla hirviendo orgánica"},
    {prodId:"50415204",label:"Cebolla bufalo orgánica"},
    {prodId:"50415205",label:"Cebolla bulbo  orgánica"},
    {prodId:"50415206",label:"Cebolla cremosa orgánica"},
    {prodId:"50415207",label:"Cebolla amarillo expreso o - x orgánica"},
    {prodId:"50415208",label:"Cebolla kelsae orgánica"},
    {prodId:"50415209",label:"Cebolla gigante marshal globo fen orgánica"},
    {prodId:"50415210",label:"Cebolla perla orgánica"},
    {prodId:"50415211",label:"Cebolla baron rojo orgánica"},
    {prodId:"50415212",label:"Cebolla roja orgánica"},
    {prodId:"50415213",label:"Cebolla rijnsberger orgánica"},
    {prodId:"50415214",label:"Cebolla senshyu semi - globo amarillo orgánica"},
    {prodId:"50415215",label:"Cebolla sturon orgánica"},
    {prodId:"50415216",label:"Cebolla stuttgarter orgánica"},
    {prodId:"50415217",label:"Cebolla dulce orgánica"},
    {prodId:"50415218",label:"Cebolla torpedo o rojo italiana orgánica"},
    {prodId:"50415219",label:"Cebolla roja guardada orgánica"},
    {prodId:"50415220",label:"Cebolla blanca guardada orgánica"},
    {prodId:"50415221",label:"Cebolla amarilla guardada orgánica"},
    {prodId:"50415500",label:"Pimientos orgánicos"},
    {prodId:"50415501",label:"Pimentón ají  orgánico"},
    {prodId:"50415502",label:"Pimentón  árbol orgánico"},
    {prodId:"50415503",label:"Pimentón queso orgánico"},
    {prodId:"50415504",label:"Pimentón chilaca orgánico"},
    {prodId:"50415505",label:"Pimentón cubanelles orgánico"},
    {prodId:"50415506",label:"Pimentón fresno orgánico"},
    {prodId:"50415507",label:"Pimentón kapia orgánico"},
    {prodId:"50415508",label:"Pimentón coreano orgánico"},
    {prodId:"50415509",label:"Pimentón manzano orgánico"},
    {prodId:"50415510",label:"Pimentón melrose orgánico"},
    {prodId:"50415511",label:"Pimentón chile amarillo orgánico"},
    {prodId:"50415512",label:"Pimentón aji dulce orgánico"},
    {prodId:"50415513",label:"Pimentón anaheim orgánico"},
    {prodId:"50415514",label:"Pimentón ancho orgánico"},
    {prodId:"50415515",label:"Pimentón campana orgánico"},
    {prodId:"50415516",label:"Pimentón cascabel orgánico"},
    {prodId:"50415517",label:"Pimentón cayenne orgánico"},
    {prodId:"50415518",label:"Pimentón anfitrión cereza orgánico"},
    {prodId:"50415519",label:"Pimentón chitespin orgánico"},
    {prodId:"50415520",label:"Pimentón dedo picante orgánico"},
    {prodId:"50415521",label:"Pimentón guajillo orgánico"},
    {prodId:"50415522",label:"Pimentón guerro orgánico"},
    {prodId:"50415523",label:"Pimentón habanero orgánico"},
    {prodId:"50415524",label:"Pimentón cera de hungria orgánico"},
    {prodId:"50415525",label:"Pimentón jalapeño orgánico"},
    {prodId:"50415526",label:"Pimentón picante largo orgánico"},
    {prodId:"50415527",label:"Pimentón marasol orgánico"},
    {prodId:"50415528",label:"Pimentón pasilla orgánico"},
    {prodId:"50415529",label:"Pimentón peperoncini orgánico"},
    {prodId:"50415530",label:"Pimentón pequin orgánico"},
    {prodId:"50415531",label:"Pimentón pimiento orgánico"},
    {prodId:"50415532",label:"Pimentón poblano orgánico"},
    {prodId:"50415533",label:"Pimentón scotch bonnet orgánico"},
    {prodId:"50415534",label:"Pimentón serrano orgánico"},
    {prodId:"50415535",label:"Pimentón tabasco orgánico"},
    {prodId:"50415536",label:"Pimentón tai  orgánico"},
    {prodId:"50415537",label:"Pimentón tepin orgánico"},
    {prodId:"50416100",label:"Espinacas  orgánicas"},
    {prodId:"50416101",label:"Espinaca americana orgánica"},
    {prodId:"50416102",label:"Espinaca bloomsdale orgánica"},
    {prodId:"50416103",label:"Espinaca invierno gigante orgánica"},
    {prodId:"50416104",label:"Espinaca horenso orgánica"},
    {prodId:"50416105",label:"Espinaca cuarto de cordero orgánica"},
    {prodId:"50416106",label:"Espinaca malabar orgánica"},
    {prodId:"50416107",label:"Espinaca medania orgánica"},
    {prodId:"50416108",label:"Espinaca orach orgánica"},
    {prodId:"50416109",label:"Espinaca savoy orgánica"},
    {prodId:"50416110",label:"Espinaca hoja de sigma orgánica"},
    {prodId:"50416111",label:"Espinaca espacio orgánica"},
    {prodId:"50416112",label:"Espinaca trinidad orgánica"},
    {prodId:"50416113",label:"Espinaca salvaje orgánica"},
    {prodId:"50416114",label:"Espinaca nueva zelandia orgánica"},
    {prodId:"50416115",label:"Espinaca iceplant orgánica"},
    {prodId:"50416200",label:"Calabazas orgánicas"},
    {prodId:"50416201",label:"Calabaza boston  orgánica"},
    {prodId:"50416202",label:"Calabaza buttemut orgánica"},
    {prodId:"50416203",label:"Calabaza costata romanesca orgánica"},
    {prodId:"50416204",label:"Calabaza crookneck orgánica"},
    {prodId:"50416205",label:"Calabaza  cucuzza orgánica"},
    {prodId:"50416206",label:"Calabaza  delicata orgánica"},
    {prodId:"50416207",label:"Calabaza  delisioso orgánica"},
    {prodId:"50416208",label:"Calabaza verano dorado cuello torsido orgánica"},
    {prodId:"50416209",label:"Calabaza prolífico temprano cuello recto  orgánica"},
    {prodId:"50416210",label:"Calabaza  oro orgánica"},
    {prodId:"50416211",label:"Calabaza  jack pequeño orgánica"},
    {prodId:"50416212",label:"Calabaza  campo de kentucky orgánica"},
    {prodId:"50416213",label:"Calabaza marrow orgánica"},
    {prodId:"50416214",label:"Calabaza  medio oriente orgánica"},
    {prodId:"50416215",label:"Calabaza  miniatura orgánica"},
    {prodId:"50416216",label:"Calabaza  orangetti orgánica"},
    {prodId:"50416217",label:"Calabaza pattypan orgánica"},
    {prodId:"50416218",label:"Calabaza  rondini orgánica"},
    {prodId:"50416219",label:"Calabaza  redonda orgánica"},
    {prodId:"50416220",label:"Calabaza  espagueti orgánica"},
    {prodId:"50416221",label:"Calabaza  estripeti orgánica"},
    {prodId:"50416222",label:"Calabaza  molde dulce orgánica"},
    {prodId:"50416223",label:"Calabaza  empanada dulce orgánica"},
    {prodId:"50416224",label:"Calabaza  premio triple orgánica"},
    {prodId:"50416225",label:"Calabaza waltham butternut orgánica"},
    {prodId:"50416226",label:"Calabaza  arbusto scallop amarillo orgánica"},
    {prodId:"50416227",label:"Calabaza cuello recto amarillo orgánica"},
    {prodId:"50416228",label:"Calabaza zafiro orgánica"},
    {prodId:"50416229",label:"Calabaza zucchini orgánica"},
    {prodId:"50416400",label:"Tomates orgánicos"},
    {prodId:"50416401",label:"Tomates ailsa craig  orgánicos"},
    {prodId:"50416402",label:"Tomates alicante orgánicos"},
    {prodId:"50416403",label:"Tomates ciruela negra orgánicos"},
    {prodId:"50416404",label:"Tomates vino de brandy orgánicos"},
    {prodId:"50416405",label:"Tomates bella cereza orgánicos"},
    {prodId:"50416406",label:"Tomates cereza orgánicos"},
    {prodId:"50416407",label:"Tomates delicioso orgánicos"},
    {prodId:"50416408",label:"Tomates dombito orgánicos"},
    {prodId:"50416409",label:"Tomates delicia del jardín orgánicos"},
    {prodId:"50416410",label:"Tomates uva orgánicos"},
    {prodId:"50416411",label:"Tomates verde orgánicos"},
    {prodId:"50416412",label:"Tomates super marmande orgánicos"},
    {prodId:"50416413",label:"Tomates rayas naturales marvel orgánicos"},
    {prodId:"50416414",label:"Tomates minibel orgánicos"},
    {prodId:"50416415",label:"Tomates oaxacan orgánicos"},
    {prodId:"50416416",label:"Tomates alerta roja orgánicos"},
    {prodId:"50416417",label:"Tomates roma vf orgánicos"},
    {prodId:"50416418",label:"Tomates marzano zan orgánicos"},
    {prodId:"50416419",label:"Tomates shirley orgánicos"},
    {prodId:"50416420",label:"Tomates siberia orgánicos"},
    {prodId:"50416421",label:"Tomates super beefsteak orgánicos"},
    {prodId:"50416422",label:"Tomates tigerella orgánicos"},
    {prodId:"50416423",label:"Tomates tiny tim orgánicos"},
    {prodId:"50416424",label:"Tomates tumbler orgánicos"},
    {prodId:"50416425",label:"Tomates cocktail amarillo orgánicos"},
    {prodId:"50416426",label:"Tomates forma de pera amarillo orgánicos"},
    {prodId:"50416427",label:"Tomates perfección amarilla orgánicos"},
    {prodId:"50416600",label:"Auyamas orgánicas"},
    {prodId:"50416601",label:"Calabaza acorn orgánica"},
    {prodId:"50416602",label:"Calabaza gigante atlántico orgánica"},
    {prodId:"50416603",label:"Calabaza banana rosado orgánica"},
    {prodId:"50416604",label:"Calabaza max grande orgánica"},
    {prodId:"50416605",label:"Calabaza  orgánica"},
    {prodId:"50416606",label:"Calabaza carnaval orgánica"},
    {prodId:"50416607",label:"Auyama queso orgánica"},
    {prodId:"50416608",label:"Calabaza príncipe coronado orgánica"},
    {prodId:"50416609",label:"Calabaza  curcibita orgánica"},
    {prodId:"50416610",label:"Calabaza cushaw orgánica"},
    {prodId:"50416611",label:"Calabaza  gigante auyama orgánica"},
    {prodId:"50416612",label:"Calabaza  hubbard orgánica"},
    {prodId:"50416613",label:"Calabaza  jerrahdale orgánica"},
    {prodId:"50416614",label:"Calabaza  kabocha orgánica"},
    {prodId:"50416615",label:"Calabaza queensland azul orgánica"},
    {prodId:"50416616",label:"Calabaza rouge vif détampes orgánica"},
    {prodId:"50416617",label:"Calabaza  turban turco orgánica"},
    {prodId:"50416618",label:"Calabaza  valenciano orgánica"},
    {prodId:"50416619",label:"Calabaza  warted hubbard orgánica"},
    {prodId:"50416620",label:"Auyama whangaparoa orgánica"},
    {prodId:"50416800",label:"Ñames orgánicos"},
    {prodId:"50416801",label:"Maíz aloha orgánico"},
    {prodId:"50416802",label:"Maíz alpine orgánico"},
    {prodId:"50416803",label:"Maíz ambrosia orgánico"},
    {prodId:"50416804",label:"Maíz argent orgánico"},
    {prodId:"50416805",label:"Maíz aspen orgánico"},
    {prodId:"50416806",label:"Maíz avalancha orgánico"},
    {prodId:"50416807",label:"Maíz biqueen orgánico"},
    {prodId:"50416808",label:"Maíz  bodacious orgánico"},
    {prodId:"50416809",label:"Maíz  mantequilla y azúcar orgánico"},
    {prodId:"50416810",label:"Maíz  calico belle orgánico"},
    {prodId:"50416811",label:"Maíz  camelot orgánico"},
    {prodId:"50416812",label:"Maíz  challengercrisp ‘n dulce orgánico"},
    {prodId:"50416813",label:"Maíz  campeón orgánico"},
    {prodId:"50416814",label:"Maíz  algodón de azúcar orgánico"},
    {prodId:"50416815",label:"Maíz  dártagnan orgánico"},
    {prodId:"50416816",label:"Maíz  dazzle orgánico"},
    {prodId:"50416817",label:"Maíz  diamante y oro orgánico"},
    {prodId:"50416818",label:"Maíz  divinidad orgánico"},
    {prodId:"50416819",label:"Maíz  delicia doble orgánico"},
    {prodId:"50416820",label:"Maíz  gema doble orgánico"},
    {prodId:"50416821",label:"Maíz  earlivee orgánico"},
    {prodId:"50416822",label:"Maíz  temprano extra dulce orgánico"},
    {prodId:"50416823",label:"Maíz excel orgánico"},
    {prodId:"50416824",label:"Maíz  cruz dorada bantam orgánico"},
    {prodId:"50416825",label:"Maíz  miel y crema orgánico"},
    {prodId:"50416826",label:"Maíz  miel y perla orgánico"},
    {prodId:"50416827",label:"Maíz  miel dulce orgánico"},
    {prodId:"50416828",label:"Maíz  hudson orgánico"},
    {prodId:"50416829",label:"Maíz  dorado illini orgánico"},
    {prodId:"50416830",label:"Maíz  illini extra dulce orgánico"},
    {prodId:"50416831",label:"Maíz  increíble orgánico"},
    {prodId:"50416832",label:"Maíz  lochief orgánico"},
    {prodId:"50416833",label:"Maíz jubileo orgánico"},
    {prodId:"50416834",label:"Maíz jupbileo super dulce orgánico"},
    {prodId:"50416835",label:"Maíz  confite korn orgánico"},
    {prodId:"50416836",label:"Maíz  beso y acuso orgánico"},
    {prodId:"50416837",label:"Maíz  lancelot orgánico"},
    {prodId:"50416838",label:"Maíz dulce maple orgánico"},
    {prodId:"50416839",label:"Maíz  medley orgánico"},
    {prodId:"50416840",label:"Maíz merlin orgánico"},
    {prodId:"50416841",label:"Maíz milagro orgánico"},
    {prodId:"50416842",label:"Maíz nk - 199 orgánico"},
    {prodId:"50416843",label:"Maíz durazno y crema orgánico"},
    {prodId:"50416844",label:"Maíz blanco perla orgánico"},
    {prodId:"50416845",label:"Maíz  pegasus orgánico"},
    {prodId:"50416846",label:"Maíz  fenomenal orgánico"},
    {prodId:"50416847",label:"Maíz  dama de platino orgánico"},
    {prodId:"50416848",label:"Maíz  precioso orgánico"},
    {prodId:"50416849",label:"Maíz   pristine orgánico"},
    {prodId:"50416850",label:"Maíz  rápido orgánico"},
    {prodId:"50416851",label:"Maíz  radiante orgánico"},
    {prodId:"50416852",label:"Maíz  seneca brave orgánico"},
    {prodId:"50416853",label:"Maíz  amanecer seneca orgánico"},
    {prodId:"50416854",label:"Maíz  horizonte seneca orgánico"},
    {prodId:"50416855",label:"Maíz  brillo de estrella seneca orgánico"},
    {prodId:"50416856",label:"Maíz  noche blanca seneca orgánico"},
    {prodId:"50416857",label:"Maíz  showcase orgánico"},
    {prodId:"50416858",label:"Maíz  reina plateada orgánico"},
    {prodId:"50416859",label:"Maíz  bella nieve orgánico"},
    {prodId:"50416860",label:"Maíz nieve de primavera orgánico"},
    {prodId:"50416861",label:"Maíz  premio de primavera orgánico"},
    {prodId:"50416862",label:"Maíz  azúcar y oro orgánico"},
    {prodId:"50416863",label:"Maíz rollo de azúcar orgánico"},
    {prodId:"50416864",label:"Maíz nieve de azúcar orgánico"},
    {prodId:"50416865",label:"Maíz baile de sol orgánico"},
    {prodId:"50416866",label:"Maíz estrella tell orgánico"},
    {prodId:"50416867",label:"Maíz terminador orgánico"},
    {prodId:"50416868",label:"Maíz tesoro orgánico"},
    {prodId:"50416869",label:"Maíz tuxedo orgánico"},
    {prodId:"50417000",label:"Vegetales Nominant"},
    {prodId:"50417001",label:"Alfafa orgánico"},
    {prodId:"50417002",label:"Hojas de aloe orgánica"},
    {prodId:"50417003",label:"Apio orgánico"},
    {prodId:"50417004",label:"Arrurruz orgánica"},
    {prodId:"50417005",label:"Punta de flecha orgánica"},
    {prodId:"50417006",label:"rúcula orgánica"},
    {prodId:"50417007",label:"Arum orgánica"},
    {prodId:"50417008",label:"Raíz de bambú orgánica"},
    {prodId:"50417009",label:"Hojas de platano orgánica"},
    {prodId:"50417010",label:"Batatas orgánica"},
    {prodId:"50417011",label:"Brotes de frijol orgánico"},
    {prodId:"50417012",label:"Top de remolacha orgánico"},
    {prodId:"50417013",label:"Melón agrio orgánico"},
    {prodId:"50417014",label:"Bayas de alcaparra orgánicas"},
    {prodId:"50417015",label:"Carob orgánico"},
    {prodId:"50417016",label:"Cha-om orgánico"},
    {prodId:"50417017",label:"Chayote o guatila orgánico"},
    {prodId:"50417018",label:"Garbanzo orgánico"},
    {prodId:"50417019",label:"Verde de crisantemos orgánico"},
    {prodId:"50417020",label:"Verde de diente de león orgánico"},
    {prodId:"50417021",label:"Diente de león orgánico"},
    {prodId:"50417022",label:"Dasheen orgánico"},
    {prodId:"50417023",label:"Puntas de alverja orgánicas"},
    {prodId:"50417024",label:"Diakon orgánico"},
    {prodId:"50417025",label:"Donqua orgánica"},
    {prodId:"50417026",label:"Helecho orgánico"},
    {prodId:"50417027",label:"Gai choi orgánico"},
    {prodId:"50417028",label:"Gailon orgánico"},
    {prodId:"50417029",label:"Galanga orgánico"},
    {prodId:"50417030",label:"Raíz de jengibre orgánico"},
    {prodId:"50417031",label:"Gobo orgánico"},
    {prodId:"50417032",label:"Brote de lúpulo orgánico"},
    {prodId:"50417033",label:"Rabano blanco orgánico"},
    {prodId:"50417034",label:"Jicama orgánica"},
    {prodId:"50417035",label:"Kuduz orgánico"},
    {prodId:"50417036",label:"Bulbo de margarita orgánico"},
    {prodId:"50417037",label:"Linkok orgánico"},
    {prodId:"50417038",label:"Lo bok orgánico"},
    {prodId:"50417039",label:"Frijol largo orgánico"},
    {prodId:"50417040",label:"Raíz de lotus orgánico"},
    {prodId:"50417041",label:"Hojas de maguey orgánicas"},
    {prodId:"50417042",label:"Mallows orgánico"},
    {prodId:"50417043",label:"Sapote mamey orgánico"},
    {prodId:"50417044",label:"Moap orgánico"},
    {prodId:"50417045",label:"Moo orgánico"},
    {prodId:"50417046",label:"Moqua orgánica"},
    {prodId:"50417047",label:"Opos orgánico"},
    {prodId:"50417048",label:"Corazon de palma orgánico"},
    {prodId:"50417049",label:"Paprika orgánica"},
    {prodId:"50417050",label:"Purslane orgánico"},
    {prodId:"50417051",label:"Raddichios orgánico"},
    {prodId:"50417052",label:"Sinquas orgánico"},
    {prodId:"50417053",label:"Frijol de soya orgánicos"},
    {prodId:"50417054",label:"Spoonwart orgánico"},
    {prodId:"50417055",label:"Uva tassele hyacinth orgánico"},
    {prodId:"50417056",label:"Taro orgánico"},
    {prodId:"50417057",label:"Hoja de taro orgánico"},
    {prodId:"50417058",label:"Tallo de taro orgánico"},
    {prodId:"50417059",label:"Tapeguaje orgánico"},
    {prodId:"50417060",label:"Verde tierno orgánico"},
    {prodId:"50417061",label:"Tindora orgánico"},
    {prodId:"50417062",label:"Árbol de cebolla orgánico"},
    {prodId:"50417063",label:"Udo orgánico"},
    {prodId:"50417064",label:"Agua castaño orgánica"},
    {prodId:"50417065",label:"Agua espinaca orgánica"},
    {prodId:"50417066",label:"Yumpi orgánico"},
    {prodId:"50417067",label:"Yautia orgánico"},
    {prodId:"50417068",label:"Yu choy orgánico"},
    {prodId:"50417069",label:"Yuca orgánica"},
    {prodId:"50421500",label:"Alcachofas Secas"},
    {prodId:"50421501",label:"Alcachofa brittany seca"},
    {prodId:"50421502",label:"Alcachofa cantonesa seca"},
    {prodId:"50421503",label:"Alcachofa francesa seca"},
    {prodId:"50421504",label:"Alcachofa globo verde seca"},
    {prodId:"50421505",label:"Alcachofa gros camus de bretaña seca"},
    {prodId:"50421506",label:"Alcachofa midi seca"},
    {prodId:"50421507",label:"Alcachofa globo morado seca"},
    {prodId:"50421508",label:"Alcachofa morado cecilia seca"},
    {prodId:"50421509",label:"Alcachofa romanesco seca"},
    {prodId:"50421510",label:"Alcachofa espinoso sardo seca"},
    {prodId:"50421511",label:"Alcachofa vert de laon seca"},
    {prodId:"50421512",label:"Alcachofa violeta de chiggiia seca"},
    {prodId:"50421513",label:"Alcachofa violeta de toscana seca"},
    {prodId:"50421800",label:"Fríjoles Secos"},
    {prodId:"50421801",label:"Frijoles anasazi ® secos"},
    {prodId:"50421802",label:"Frijoles appaloosa secos"},
    {prodId:"50421803",label:"Frijoles azuki secos"},
    {prodId:"50421804",label:"Frijoles barlotti secos"},
    {prodId:"50421805",label:"Frijoles appaloosa negra secos"},
    {prodId:"50421806",label:"Frijoles negros secos"},
    {prodId:"50421807",label:"Frijoles gran negros secos"},
    {prodId:"50421808",label:"Frijoles shackamaxon negro secos"},
    {prodId:"50421809",label:"Frijoles ojo negro secos"},
    {prodId:"50421810",label:"Frijoles bobby secos"},
    {prodId:"50421811",label:"Frijoles bolita secos"},
    {prodId:"50421812",label:"Frijoles esposa perezosa carmelita secos"},
    {prodId:"50421813",label:"Frijoles calipso secos"},
    {prodId:"50421814",label:"Frijoles canelini secos"},
    {prodId:"50421815",label:"Frijoles castor secos"},
    {prodId:"50421816",label:"Frijoles amarillo chino secos"},
    {prodId:"50421817",label:"Frijoles lengua de dragón secos"},
    {prodId:"50421818",label:"Frijoles soldado europeo secos"},
    {prodId:"50421819",label:"Frijoles fava secos"},
    {prodId:"50421820",label:"Frijoles flageolet secos"},
    {prodId:"50421821",label:"Frijoles  horticultura francesa secos"},
    {prodId:"50421822",label:"Frijoles marina francesa secos"},
    {prodId:"50421823",label:"Frijoles coco gigante blanco secos"},
    {prodId:"50421824",label:"Frijoles verdes secos"},
    {prodId:"50421825",label:"Frijoles romano verde secos"},
    {prodId:"50421826",label:"Frijoles guar gum secos"},
    {prodId:"50421827",label:"Frijoles haricot secos"},
    {prodId:"50421828",label:"Frijoles hyacinth secos"},
    {prodId:"50421829",label:"Frijoles tipo italiano secos"},
    {prodId:"50421830",label:"Frijoles asombro jackson secos"},
    {prodId:"50421831",label:"Frijoles ganado jacobs  secos"},
    {prodId:"50421832",label:"Frijoles asombro kentucky secos"},
    {prodId:"50421833",label:"Frijoles riñón secos"},
    {prodId:"50421834",label:"Frijoles lima secos"},
    {prodId:"50421835",label:"Frijoles madera secos"},
    {prodId:"50421836",label:"Frijoles medula secos"},
    {prodId:"50421837",label:"Frijoles mat secos"},
    {prodId:"50421838",label:"Frijoles monstoler ganzo salvaje secos"},
    {prodId:"50421839",label:"Frijoles  mortgage lifter secos"},
    {prodId:"50421840",label:"Frijoles polilla secos"},
    {prodId:"50421841",label:"Frijoles mung secos"},
    {prodId:"50421842",label:"Frijoles munsi wolf secos"},
    {prodId:"50421843",label:"Frijoles nuna secos"},
    {prodId:"50421844",label:"Frijoles pinto secos"},
    {prodId:"50421845",label:"Frijoles runner secos"},
    {prodId:"50421846",label:"String beans secos"},
    {prodId:"50421847",label:"Frijoles habichuelas secos"},
    {prodId:"50421848",label:"Frijoles tamarind secos"},
    {prodId:"50421849",label:"Frijoles tonka secos"},
    {prodId:"50421850",label:"Frijoles cera secos"},
    {prodId:"50421851",label:"Frijoles winged secos"},
    {prodId:"50421852",label:"Frijoles largos secos"},
    {prodId:"50422500",label:"Zanahorias  Secas"},
    {prodId:"50422501",label:"Zanahoria amsterdam seca"},
    {prodId:"50422502",label:"Zanahoria rey de otoño seca"},
    {prodId:"50422503",label:"Zanahoria berlicum seca"},
    {prodId:"50422504",label:"Zanahoria chantenay seca"},
    {prodId:"50422505",label:"Zanahoria nantes seca"},
    {prodId:"50422506",label:"Zanahoria mercado de parís seca"},
    {prodId:"50423200",label:"Maíces Secos"},
    {prodId:"50423201",label:"Maíz aloha seco"},
    {prodId:"50423202",label:"Maíz alpine seco"},
    {prodId:"50423203",label:"Maíz ambrosia seco"},
    {prodId:"50423204",label:"Maíz argent seco"},
    {prodId:"50423205",label:"Maíz aspen seco"},
    {prodId:"50423206",label:"Maíz avalancha seco"},
    {prodId:"50423207",label:"Maíz biqueen seco"},
    {prodId:"50423208",label:"Maíz  bodacious seco"},
    {prodId:"50423209",label:"Maíz  mantequilla y azúcar seco"},
    {prodId:"50423210",label:"Maíz  calico belle seco"},
    {prodId:"50423211",label:"Maíz  camelot seco"},
    {prodId:"50423212",label:"Maíz  challengercrisp ‘n dulce seco"},
    {prodId:"50423213",label:"Maíz  campeón seco"},
    {prodId:"50423214",label:"Maíz  algodón de azúcar seco"},
    {prodId:"50423215",label:"Maíz  dártagnan seco"},
    {prodId:"50423216",label:"Maíz  dazzle seco"},
    {prodId:"50423217",label:"Maíz  diamante y oro seco"},
    {prodId:"50423218",label:"Maíz  divinidad seco"},
    {prodId:"50423219",label:"Maíz  delicia doble seco"},
    {prodId:"50423220",label:"Maíz  gema doble seco"},
    {prodId:"50423221",label:"Maíz  earlivee seco"},
    {prodId:"50423222",label:"Maíz  temprano extra dulce seco"},
    {prodId:"50423223",label:"Maíz excel seco"},
    {prodId:"50423224",label:"Maíz  cruz dorada bantam seco"},
    {prodId:"50423225",label:"Maíz  miel y crema seco"},
    {prodId:"50423226",label:"Maíz  miel y perla seco"},
    {prodId:"50423227",label:"Maíz  miel dulce seco"},
    {prodId:"50423228",label:"Maíz  hudson seco"},
    {prodId:"50423229",label:"Maíz  dorado illini seco"},
    {prodId:"50423230",label:"Maíz  illini extra dulce seco"},
    {prodId:"50423231",label:"Maíz  increíble seco"},
    {prodId:"50423232",label:"Maíz  lochief seco"},
    {prodId:"50423233",label:"Maíz jubileo seco"},
    {prodId:"50423234",label:"Maíz jupbileo super dulce seco"},
    {prodId:"50423235",label:"Maíz  confite korn seco"},
    {prodId:"50423236",label:"Maíz  beso y acuso seco"},
    {prodId:"50423237",label:"Maíz  lancelot seco"},
    {prodId:"50423238",label:"Maíz dulce maple seco"},
    {prodId:"50423239",label:"Maíz  medley seco"},
    {prodId:"50423240",label:"Maíz merlin seco"},
    {prodId:"50423241",label:"Maíz milagro seco"},
    {prodId:"50423242",label:"Maíz nk - 199 seco"},
    {prodId:"50423243",label:"Maíz durazno y crema seco"},
    {prodId:"50423244",label:"Maíz blanco perla seco"},
    {prodId:"50423245",label:"Maíz  pegasus seco"},
    {prodId:"50423246",label:"Maíz  fenomenal seco"},
    {prodId:"50423247",label:"Maíz  dama de platino seco"},
    {prodId:"50423248",label:"Maíz  precioso seco"},
    {prodId:"50423249",label:"Maíz   pristine seco"},
    {prodId:"50423250",label:"Maíz  rápido seco"},
    {prodId:"50423251",label:"Maíz  radiante seco"},
    {prodId:"50423252",label:"Maíz  seneca brave seco"},
    {prodId:"50423253",label:"Maíz  amanecer seneca seco"},
    {prodId:"50423254",label:"Maíz  horizonte seneca seco"},
    {prodId:"50423255",label:"Maíz  brillo de estrella seneca seco"},
    {prodId:"50423256",label:"Maíz  noche blanca seneca seco"},
    {prodId:"50423257",label:"Maíz  showcase seco"},
    {prodId:"50423258",label:"Maíz  reina plateada seco"},
    {prodId:"50423259",label:"Maíz  bella nieve seco"},
    {prodId:"50423260",label:"Maíz nieve de primavera seco"},
    {prodId:"50423261",label:"Maíz  premio de primavera seco"},
    {prodId:"50423262",label:"Maíz  azúcar y oro seco"},
    {prodId:"50423263",label:"Maíz rollo de azúcar seco"},
    {prodId:"50423264",label:"Maíz nieve de azúcar seco"},
    {prodId:"50423265",label:"Maíz baile de sol seco"},
    {prodId:"50423266",label:"Maíz estrella tell seco"},
    {prodId:"50423267",label:"Maíz terminador seco"},
    {prodId:"50423268",label:"Maíz tesoro seco"},
    {prodId:"50423269",label:"Maíz tuxedo seco"},
    {prodId:"50423700",label:"Hinojos Secos"},
    {prodId:"50423701",label:"Hinojo cantino seco"},
    {prodId:"50423702",label:"Hinojo fino seco"},
    {prodId:"50423703",label:"Hinojoherald seco"},
    {prodId:"50423704",label:"Hinojo perfección seco"},
    {prodId:"50423705",label:"Hinojo sirio seco"},
    {prodId:"50423706",label:"Hinojo florencia dulce seco"},
    {prodId:"50423707",label:"Hinojo tardo seco"},
    {prodId:"50423800",label:"Ajos Secos"},
    {prodId:"50423801",label:"Ajo tarde california seco"},
    {prodId:"50423802",label:"Ajo tallos chinos seco"},
    {prodId:"50423803",label:"Ajo cebollin  seco"},
    {prodId:"50423804",label:"Ajo germidor seco"},
    {prodId:"50423805",label:"Ajo guardado largo seco"},
    {prodId:"50423806",label:"Ajo ramson seco"},
    {prodId:"50423807",label:"Ajo rocambola seco"},
    {prodId:"50423808",label:"Ajo rosa de lautrec seco"},
    {prodId:"50423809",label:"Ajo solent blanco seco"},
    {prodId:"50423810",label:"Ajo morado español seco"},
    {prodId:"50423811",label:"Ajo valentina italiana seco"},
    {prodId:"50423900",label:"Calabazas Secas"},
    {prodId:"50423901",label:"Petula seca"},
    {prodId:"50423902",label:"Melon amargo seco"},
    {prodId:"50423903",label:"Botella calabaza seca"},
    {prodId:"50423904",label:"Calabash gounds seca"},
    {prodId:"50423905",label:"Melón peludo seco"},
    {prodId:"50423906",label:"Calabaza amarga seca"},
    {prodId:"50423907",label:"Loofah suave seco"},
    {prodId:"50423908",label:"Serpiente de calabaza seca"},
    {prodId:"50423909",label:"Calabaza larga amarga seca"},
    {prodId:"50423910",label:"Calabaza tinda seca"},
    {prodId:"50423911",label:"Calabaza tindoori seca"},
    {prodId:"50424000",label:"Guisantes Secas"},
    {prodId:"50424001",label:"Guisante chino seca"},
    {prodId:"50424002",label:"Guisante inglés seca"},
    {prodId:"50424003",label:"Guisante de jardín seca"},
    {prodId:"50424004",label:"Guisante nieve seca"},
    {prodId:"50424005",label:"Guisante snap dulce seca"},
    {prodId:"50424100",label:"Hierbas Secas"},
    {prodId:"50424101",label:"Albahaca seca"},
    {prodId:"50424102",label:"Laurel seco"},
    {prodId:"50424103",label:"Borraja seca"},
    {prodId:"50424104",label:"Alcaravea seca"},
    {prodId:"50424105",label:"Perifollo seco"},
    {prodId:"50424106",label:"Cilantro seco"},
    {prodId:"50424107",label:"Chipolinos seco"},
    {prodId:"50424108",label:"Hoja de curry seca"},
    {prodId:"50424109",label:"Eneldo seco"},
    {prodId:"50424110",label:"Epazote seco"},
    {prodId:"50424111",label:"Fenogreco seco"},
    {prodId:"50424112",label:"Limonaria seca"},
    {prodId:"50424113",label:"Mejorana seca"},
    {prodId:"50424114",label:"Menta seca"},
    {prodId:"50424115",label:"Orégano seco"},
    {prodId:"50424116",label:"Pápalo seco"},
    {prodId:"50424117",label:"Pápalo delgado seco"},
    {prodId:"50424118",label:"Perilla seca"},
    {prodId:"50424119",label:"Recao  seco"},
    {prodId:"50424120",label:"Romero seco"},
    {prodId:"50424121",label:"Salvia seca"},
    {prodId:"50424122",label:"Salsifí seca"},
    {prodId:"50424123",label:"Satureja seca"},
    {prodId:"50424124",label:"Estragón seco"},
    {prodId:"50424125",label:"Tomillo seco"},
    {prodId:"50424126",label:"Tumeric seco"},
    {prodId:"50424127",label:"Verdolaga seca"},
    {prodId:"50424500",label:"Lentejas  Secas"},
    {prodId:"50424501",label:"Lenteja beluga seca"},
    {prodId:"50424502",label:"Lenteja  verde francesa seca"},
    {prodId:"50424503",label:"Lenteja verde seca"},
    {prodId:"50424504",label:"Lenteja crimson pequeña seca"},
    {prodId:"50424505",label:"Lenteja pardina española seca"},
    {prodId:"50424506",label:"Lenteja roja separada seca"},
    {prodId:"50424507",label:"Lenteja amarilla separad seca"},
    {prodId:"50424508",label:"Lenteja tarahumara rosada seca"},
    {prodId:"50424900",label:"Mostazas Secas"},
    {prodId:"50424901",label:"Mostaza bambú  seca"},
    {prodId:"50424902",label:"Mostaza ajo seca"},
    {prodId:"50424903",label:"Mostaza hoja gigante seca"},
    {prodId:"50424904",label:"Mostaza rojo en nieve seca"},
    {prodId:"50424905",label:"Mostaza sureño seca"},
    {prodId:"50424906",label:"Mostaza corazón envuelto seca"},
    {prodId:"50425200",label:"Cebollas Secas"},
    {prodId:"50425201",label:"Cebolla albion seca"},
    {prodId:"50425202",label:"Cebolla alisia craig seca"},
    {prodId:"50425203",label:"Cebolla hirviendo seca"},
    {prodId:"50425204",label:"Cebolla bufalo seca"},
    {prodId:"50425205",label:"Cebolla bulbo seca"},
    {prodId:"50425206",label:"Cebolla cremosa seca"},
    {prodId:"50425207",label:"Cebolla amarillo expreso o - x seca"},
    {prodId:"50425208",label:"Cebolla kelsae seca"},
    {prodId:"50425209",label:"Cebolla gigante marshal globo fen seca"},
    {prodId:"50425210",label:"Cebolla perla seca"},
    {prodId:"50425211",label:"Cebolla baron rojo seca"},
    {prodId:"50425212",label:"Cebolla roja seca"},
    {prodId:"50425213",label:"Cebolla rijnsberger seca"},
    {prodId:"50425214",label:"Cebolla senshyu semi - globo amarillo seca"},
    {prodId:"50425215",label:"Cebolla sturon seca"},
    {prodId:"50425216",label:"Cebolla sturgatter seca"},
    {prodId:"50425217",label:"Cebolla dulce seca"},
    {prodId:"50425218",label:"Cebolla torpedo o rojo italiana seca"},
    {prodId:"50425219",label:"Cebolla roja guardada seca"},
    {prodId:"50425220",label:"Cebolla blanca guardada seca"},
    {prodId:"50425221",label:"Cebolla amarilla guardada seca"},
    {prodId:"50425300",label:"Alverjas Secas"},
    {prodId:"50425301",label:"Maní bambara seco"},
    {prodId:"50425302",label:"Maní florunner seco"},
    {prodId:"50425303",label:"Maní husa / kerstings seco"},
    {prodId:"50425304",label:"Maní español seco"},
    {prodId:"50425305",label:"Maní valencia seco"},
    {prodId:"50425306",label:"Maní virginia seco"},
    {prodId:"50425400",label:"Maníes Secos"},
    {prodId:"50425401",label:"Alverja casco morado seca"},
    {prodId:"50425402",label:"Alverja pinkeye seca"},
    {prodId:"50425403",label:"Alverja crowder seca"},
    {prodId:"50425404",label:"Alverja acre blanco seca"},
    {prodId:"50425405",label:"Alverja ojo negro seca"},
    {prodId:"50425406",label:"Alverja crema zipper seca"},
    {prodId:"50425500",label:"Pimientos Secos"},
    {prodId:"50425501",label:"Pimentón ají seco"},
    {prodId:"50425502",label:"Pimentón  árbol seco"},
    {prodId:"50425503",label:"Pimentón queso seco"},
    {prodId:"50425504",label:"Pimentón chilaca seco"},
    {prodId:"50425505",label:"Pimentón cubanelles seco"},
    {prodId:"50425506",label:"Pimentón fresno seco"},
    {prodId:"50425507",label:"Pimentón kapia seco"},
    {prodId:"50425508",label:"Pimentón coreano seco"},
    {prodId:"50425509",label:"Pimentón manzano seco"},
    {prodId:"50425510",label:"Pimentón melrose seco"},
    {prodId:"50425511",label:"Pimentón chile amarillo seco"},
    {prodId:"50425512",label:"Pimentón aji dulce seco"},
    {prodId:"50425513",label:"Pimentón anaheim seco"},
    {prodId:"50425514",label:"Pimentón ancho seco"},
    {prodId:"50425515",label:"Pimentón campana seco"},
    {prodId:"50425516",label:"Pimentón cascabel seco"},
    {prodId:"50425517",label:"Pimentón cayenne seco"},
    {prodId:"50425518",label:"Pimentón anfitrión cereza seco"},
    {prodId:"50425519",label:"Pimentón chitespin seco"},
    {prodId:"50425520",label:"Pimentón dedo picante seco"},
    {prodId:"50425521",label:"Pimentón guajillo seco"},
    {prodId:"50425522",label:"Pimentón guerro seco"},
    {prodId:"50425523",label:"Pimentón habanero seco"},
    {prodId:"50425524",label:"Pimentón cera de hungria seco"},
    {prodId:"50425525",label:"Pimentón jalapeño seco"},
    {prodId:"50425526",label:"Pimentón picante largo seco"},
    {prodId:"50425527",label:"Pimentón marasol seco"},
    {prodId:"50425528",label:"Pimentón pasilla seco"},
    {prodId:"50425529",label:"Pimentón peperoncini seco"},
    {prodId:"50425530",label:"Pimentón pequin seco"},
    {prodId:"50425531",label:"Pimentón pimiento seco"},
    {prodId:"50425532",label:"Pimentón poblano seco"},
    {prodId:"50425533",label:"Pimentón scotch bonnet seco"},
    {prodId:"50425534",label:"Pimentón serrano seco"},
    {prodId:"50425535",label:"Pimentón tabasco seco"},
    {prodId:"50425536",label:"Pimentón tai  seco"},
    {prodId:"50425537",label:"Pimentón tepin seco"},
    {prodId:"50425538",label:"Pimentón panca chilli seco"},
    {prodId:"50425800",label:"Vegetales marinos Secos"},
    {prodId:"50425801",label:"Agar-agar seco"},
    {prodId:"50425802",label:"Arame  seca"},
    {prodId:"50425803",label:"Alga dulce seca"},
    {prodId:"50425804",label:"Habichuelas vert de mer seca"},
    {prodId:"50425805",label:"Hijiki seca"},
    {prodId:"50425806",label:"Musgo irlandés seca"},
    {prodId:"50425807",label:"Quelpo seco"},
    {prodId:"50425808",label:"Laver seco"},
    {prodId:"50425809",label:"Nori seco"},
    {prodId:"50425810",label:"Alga roja seca"},
    {prodId:"50425811",label:"Kale de mar seca"},
    {prodId:"50425812",label:"Lechuga de mar seca"},
    {prodId:"50425813",label:"Algas de mar seca"},
    {prodId:"50425814",label:"Spirulina seca"},
    {prodId:"50425815",label:"Susabi nuri seca"},
    {prodId:"50425816",label:"Wakame seca"},
    {prodId:"50426300",label:"Batatas Secas"},
    {prodId:"50426301",label:"Batata beauregard seca"},
    {prodId:"50426302",label:"Batata centennial seca"},
    {prodId:"50426303",label:"Batata diane seca"},
    {prodId:"50426304",label:"Batata dulce garnet seca"},
    {prodId:"50426305",label:"Batata georgia dulce rojo seca"},
    {prodId:"50426306",label:"Batata  dorado seca"},
    {prodId:"50426307",label:"Batata hanna  seca"},
    {prodId:"50426308",label:"Batata japonés seca"},
    {prodId:"50426309",label:"Batata jersey seca"},
    {prodId:"50426310",label:"Batata joya seca"},
    {prodId:"50426311",label:"Batata meryland roja seca"},
    {prodId:"50426312",label:"Batata nema dorado seca"},
    {prodId:"50426313",label:"Batata o'henry seca"},
    {prodId:"50426314",label:"Batata okinawan seca"},
    {prodId:"50426315",label:"Batata naranja seca"},
    {prodId:"50426316",label:"Batata oriental seca"},
    {prodId:"50426317",label:"Batata jersey rojo seca"},
    {prodId:"50426318",label:"Batata mar rojo seca"},
    {prodId:"50426319",label:"Batata brillo rojo seca"},
    {prodId:"50426320",label:"Batata jersey amarillo seca"},
    {prodId:"50426400",label:"Tomates Secos"},
    {prodId:"50426401",label:"Tomates ailsa craig seco"},
    {prodId:"50426402",label:"Tomates alicante seco"},
    {prodId:"50426403",label:"Tomates ciruela negra seco"},
    {prodId:"50426404",label:"Tomates vino de brandy seco"},
    {prodId:"50426405",label:"Tomates bella cereza seco"},
    {prodId:"50426406",label:"Tomates cereza seco"},
    {prodId:"50426407",label:"Tomates delicioso seco"},
    {prodId:"50426408",label:"Tomates dombito seco"},
    {prodId:"50426409",label:"Tomates delicia del jardín seco"},
    {prodId:"50426410",label:"Tomates uva seco"},
    {prodId:"50426411",label:"Tomates verde seco"},
    {prodId:"50426412",label:"Tomates super marmande seco"},
    {prodId:"50426413",label:"Tomates rayas naturales marvel seco"},
    {prodId:"50426414",label:"Tomates minibel seco"},
    {prodId:"50426415",label:"Tomates oaxacan seco"},
    {prodId:"50426416",label:"Tomates alerta roja seco"},
    {prodId:"50426417",label:"Tomates roma vf seco"},
    {prodId:"50426418",label:"Tomates marzano zan seco"},
    {prodId:"50426419",label:"Tomates shirley seco"},
    {prodId:"50426420",label:"Tomates siberia seco"},
    {prodId:"50426421",label:"Tomates super beefsteak seco"},
    {prodId:"50426422",label:"Tomates tigerella seco"},
    {prodId:"50426423",label:"Tomates tiny tim seco"},
    {prodId:"50426424",label:"Tomates tumbler seco"},
    {prodId:"50426425",label:"Tomates cocktail amarillo seco"},
    {prodId:"50426426",label:"Tomates forma de pera amarillo seco"},
    {prodId:"50426427",label:"Tomates perfección amarilla seco"},
    {prodId:"50426800",label:"Vegetales Nominant Secos"},
    {prodId:"50426801",label:"Alfalfa seca"},
    {prodId:"50426802",label:"Hojas de aloe seca"},
    {prodId:"50426803",label:"Apio seco"},
    {prodId:"50426804",label:"Arrurruz seca"},
    {prodId:"50426805",label:"Punta de flecha seca"},
    {prodId:"50426806",label:"rúcula seca"},
    {prodId:"50426807",label:"Arum seca"},
    {prodId:"50426808",label:"Raíz de bambú seca"},
    {prodId:"50426809",label:"Hojas de plátano seca"},
    {prodId:"50426810",label:"Batatas seca"},
    {prodId:"50426811",label:"Brotes de frijol secos"},
    {prodId:"50426812",label:"Top de remolacha seca"},
    {prodId:"50426813",label:"Melón agrio seco"},
    {prodId:"50426814",label:"Bayas de alcaparra secas"},
    {prodId:"50426815",label:"Carob seco"},
    {prodId:"50426816",label:"Cha-om seco"},
    {prodId:"50426817",label:"Chayote o guatila seco"},
    {prodId:"50426818",label:"Garbanzo seco"},
    {prodId:"50426819",label:"Verde de crisantemos seco"},
    {prodId:"50426820",label:"Verde de diente de león seco"},
    {prodId:"50426821",label:"Diente de león seco"},
    {prodId:"50426822",label:"Dasheen seco"},
    {prodId:"50426823",label:"Puntas de alverja seco"},
    {prodId:"50426824",label:"Diakon seco"},
    {prodId:"50426825",label:"Donqua seca"},
    {prodId:"50426826",label:"Helecho seco"},
    {prodId:"50426827",label:"Gai choi seco"},
    {prodId:"50426828",label:"Gailon seco"},
    {prodId:"50426829",label:"Galanga seca"},
    {prodId:"50426830",label:"Raíz de jengibre seca"},
    {prodId:"50426831",label:"Gobo seco"},
    {prodId:"50426832",label:"Brote de lúpulo seco"},
    {prodId:"50426833",label:"Rabano blanco seco"},
    {prodId:"50426834",label:"Jicama seca"},
    {prodId:"50426835",label:"Kuduz seca"},
    {prodId:"50426836",label:"Bulbo de margarita seco"},
    {prodId:"50426837",label:"Linkok seco"},
    {prodId:"50426838",label:"Lo bok seco"},
    {prodId:"50426839",label:"Frijol largo seco"},
    {prodId:"50426840",label:"Raíz de lotus seco"},
    {prodId:"50426841",label:"Hojas de maguey seca"},
    {prodId:"50426842",label:"Mallows seco"},
    {prodId:"50426843",label:"Sapote mamey seco"},
    {prodId:"50426844",label:"Moap seco"},
    {prodId:"50426845",label:"Moo seco"},
    {prodId:"50426846",label:"Moqua seca"},
    {prodId:"50426847",label:"Opos secos"},
    {prodId:"50426848",label:"Corazon de palma seco"},
    {prodId:"50426849",label:"Paprika seca"},
    {prodId:"50426850",label:"Purslane seca"},
    {prodId:"50426851",label:"Raddichios seca"},
    {prodId:"50426852",label:"Sinquas seca"},
    {prodId:"50426853",label:"Frijol de soya secos"},
    {prodId:"50426854",label:"Spoonwart seco"},
    {prodId:"50426855",label:"Uva tassele hyacinth seco"},
    {prodId:"50426856",label:"Taro seco"},
    {prodId:"50426857",label:"Hoja de taro seca"},
    {prodId:"50426858",label:"Tallo de taro seco"},
    {prodId:"50426859",label:"Tapeguaje seco"},
    {prodId:"50426860",label:"Verde tierno seco"},
    {prodId:"50426861",label:"Tindora seco"},
    {prodId:"50426862",label:"Árbol de cebolla seco"},
    {prodId:"50426863",label:"Udo seco"},
    {prodId:"50426864",label:"Agua castaño seco"},
    {prodId:"50426865",label:"Yumpi seco"},
    {prodId:"50426866",label:"Yautia seco"},
    {prodId:"50426867",label:"Yu choy seco"},
    {prodId:"50426868",label:"Yuca seca"},
    {prodId:"50433800",label:"Ajos orgánicos secos"},
    {prodId:"50433801",label:"Ajo tarde california orgánico seco"},
    {prodId:"50433802",label:"Ajo tallos chinos orgánico seco"},
    {prodId:"50433803",label:"Ajo cebollin  orgánico seco"},
    {prodId:"50433804",label:"Ajo germidor orgánico seco"},
    {prodId:"50433805",label:"Ajo guardado largo orgánico seco"},
    {prodId:"50433806",label:"Ajo ramson orgánico seco"},
    {prodId:"50433807",label:"Ajo rocambola orgánico seco"},
    {prodId:"50433808",label:"Ajo rosa de lautrec orgánico seco"},
    {prodId:"50433809",label:"Ajo solent blanco orgánico seco"},
    {prodId:"50433810",label:"Ajo morado español orgánico seco"},
    {prodId:"50433811",label:"Ajo valentina italiana orgánico seco"},
    {prodId:"50434100",label:"Hierbas orgánicas secas"},
    {prodId:"50434101",label:"Albahaca orgánica seca"},
    {prodId:"50434102",label:"Laurel orgánico seco"},
    {prodId:"50434103",label:"Borraja orgánica seca"},
    {prodId:"50434104",label:"Alcaravea orgánica seca"},
    {prodId:"50434105",label:"Perifollo orgánico seco"},
    {prodId:"50434106",label:"Cilantro orgánico seco"},
    {prodId:"50434107",label:"Chipolinos orgánico seco"},
    {prodId:"50434108",label:"Hoja de curry orgánica seca"},
    {prodId:"50434109",label:"Eneldo orgánico seco"},
    {prodId:"50434110",label:"Epazote orgánico seco"},
    {prodId:"50434111",label:"Fenogreco orgánico seco"},
    {prodId:"50434112",label:"Limonaria orgánica seca"},
    {prodId:"50434113",label:"Mejorana orgánica seca"},
    {prodId:"50434114",label:"Menta orgánica seca"},
    {prodId:"50434115",label:"Orégano orgánico seco"},
    {prodId:"50434116",label:"Pápalo orgánico seco"},
    {prodId:"50434117",label:"Pápalo delgado orgánico seco"},
    {prodId:"50434118",label:"Perilla orgánica seca"},
    {prodId:"50434119",label:"Recao  orgánico seco"},
    {prodId:"50434120",label:"Romero orgánico seco"},
    {prodId:"50434121",label:"Salvia orgánica seca"},
    {prodId:"50434122",label:"Salsifí orgánica seca"},
    {prodId:"50434123",label:"Satureja orgánica seca"},
    {prodId:"50434124",label:"Estragón orgánico seco"},
    {prodId:"50434125",label:"Tomillo orgánico seco"},
    {prodId:"50434126",label:"Tumeric orgánico seco"},
    {prodId:"50434127",label:"Verdolaga orgánica seca"},
    {prodId:"50435200",label:"Cebollas orgánicas secas"},
    {prodId:"50435201",label:"Cebolla albion orgánica seca"},
    {prodId:"50435202",label:"Cebolla alisia craig orgánica seca"},
    {prodId:"50435203",label:"Cebolla hirviendo orgánica seca"},
    {prodId:"50435204",label:"Cebolla bufalo orgánica seca"},
    {prodId:"50435205",label:"Cebolla bulbo orgánica seca"},
    {prodId:"50435206",label:"Cebolla cremosa orgánica seca"},
    {prodId:"50435207",label:"Cebolla amarillo expreso o - x orgánica seca"},
    {prodId:"50435208",label:"Cebolla kelsae orgánica seca"},
    {prodId:"50435209",label:"Cebolla gigante marshal globo fen orgánica seca"},
    {prodId:"50435210",label:"Cebolla perla orgánica seca"},
    {prodId:"50435211",label:"Cebolla baron rojo orgánica seca"},
    {prodId:"50435212",label:"Cebolla roja orgánica seca"},
    {prodId:"50435213",label:"Cebolla rijnsberger orgánica seca"},
    {prodId:"50435214",label:"Cebolla senshyu semi - globo amarillo orgánica seca"},
    {prodId:"50435215",label:"Cebolla sturon orgánica seca"},
    {prodId:"50435216",label:"Cebolla sturgatter orgánica seca"},
    {prodId:"50435217",label:"Cebolla dulce orgánica seca"},
    {prodId:"50435218",label:"Cebolla torpedo o rojo italiana orgánica seca"},
    {prodId:"50435219",label:"Cebolla roja guardada orgánica seca"},
    {prodId:"50435220",label:"Cebolla blanca guardada orgánica seca"},
    {prodId:"50435221",label:"Cebolla amarilla guardada orgánica seca"},
    {prodId:"50435800",label:"Vegetales marinos orgánicos secos"},
    {prodId:"50435801",label:"Agar-agar orgánico seco"},
    {prodId:"50435802",label:"Arame  orgánica seca"},
    {prodId:"50435803",label:"Alga dulce orgánica seca"},
    {prodId:"50435804",label:"Habichuelas vert de mer orgánica seca"},
    {prodId:"50435805",label:"Hijiki orgánica seca"},
    {prodId:"50435806",label:"Musgo irlandés orgánica seca"},
    {prodId:"50435807",label:"Quelpo orgánico seco"},
    {prodId:"50435808",label:"Laver orgánico seco"},
    {prodId:"50435809",label:"Nori orgánico seco"},
    {prodId:"50435810",label:"Alga roja orgánica seca"},
    {prodId:"50435811",label:"Kale de mar orgánica seca"},
    {prodId:"50435812",label:"Lechuga de mar orgánica seca"},
    {prodId:"50435813",label:"Algas de mar orgánica seca"},
    {prodId:"50435814",label:"Spirulina orgánica seca"},
    {prodId:"50435815",label:"Susabi nuri orgánica seca"},
    {prodId:"50435816",label:"Wakame orgánica seca"},
    {prodId:"50436800",label:"Vegetales Nominant orgánicos secos"},
    {prodId:"50436801",label:"Alfalfa orgánica seca"},
    {prodId:"50436802",label:"Hojas de aloe orgánica seca"},
    {prodId:"50436803",label:"Apio orgánico seco"},
    {prodId:"50436804",label:"Arrurruz orgánica seca"},
    {prodId:"50436805",label:"Punta de flecha orgánica seca"},
    {prodId:"50436806",label:"rúcula orgánica seca"},
    {prodId:"50436807",label:"Arum orgánica seca"},
    {prodId:"50436808",label:"Raíz de bambú orgánica seca"},
    {prodId:"50436809",label:"Hojas de plátano orgánica seca"},
    {prodId:"50436810",label:"Batatas orgánica seca"},
    {prodId:"50436811",label:"Brotes de frijol orgánicos secos"},
    {prodId:"50436812",label:"Top de remolacha orgánica seca"},
    {prodId:"50436813",label:"Melón agrio orgánico seco"},
    {prodId:"50436814",label:"Bayas de alcaparra orgánicas secas"},
    {prodId:"50436815",label:"Carob orgánico seco"},
    {prodId:"50436816",label:"Cha-om orgánico seco"},
    {prodId:"50436817",label:"Chayote o guatila orgánico seco"},
    {prodId:"50436818",label:"Garbanzo orgánico seco"},
    {prodId:"50436819",label:"Verde de crisantemos orgánico seco"},
    {prodId:"50436820",label:"Verde de diente de león orgánico seco"},
    {prodId:"50436821",label:"Diente de león orgánico seco"},
    {prodId:"50436822",label:"Dasheen orgánico seco"},
    {prodId:"50436823",label:"Puntas de alverja orgánico seco"},
    {prodId:"50436824",label:"Diakon orgánico seco"},
    {prodId:"50436825",label:"Donqua orgánica seca"},
    {prodId:"50436826",label:"Helecho orgánico seco"},
    {prodId:"50436827",label:"Gai choi orgánico seco"},
    {prodId:"50436828",label:"Gailon orgánico seco"},
    {prodId:"50436829",label:"Galanga orgánica seca"},
    {prodId:"50436830",label:"Raíz de jengibre orgánica seca"},
    {prodId:"50436831",label:"Gobo orgánico seco"},
    {prodId:"50436832",label:"Brote de lúpulo orgánico seco"},
    {prodId:"50436833",label:"Rabano blanco orgánico seco"},
    {prodId:"50436834",label:"Jicama orgánica seca"},
    {prodId:"50436835",label:"Kuduz orgánica seca"},
    {prodId:"50436836",label:"Bulbo de margarita orgánico seco"},
    {prodId:"50436837",label:"Linkok orgánico seco"},
    {prodId:"50436838",label:"Lo bok orgánico seco"},
    {prodId:"50436839",label:"Frijol largo orgánico seco"},
    {prodId:"50436840",label:"Raíz de lotus orgánico seco"},
    {prodId:"50436841",label:"Hojas de maguey orgánica seca"},
    {prodId:"50436842",label:"Mallows orgánico seco"},
    {prodId:"50436843",label:"Sapote mamey orgánico seco"},
    {prodId:"50436844",label:"Moap orgánico seco"},
    {prodId:"50436845",label:"Moo orgánico seco"},
    {prodId:"50436846",label:"Moqua orgánica seca"},
    {prodId:"50436847",label:"Opos orgánicos secos"},
    {prodId:"50436848",label:"Corazon de palma orgánico seco"},
    {prodId:"50436849",label:"Paprika orgánica seca"},
    {prodId:"50436850",label:"Purslane orgánica seca"},
    {prodId:"50436851",label:"Raddichios orgánica seca"},
    {prodId:"50436852",label:"Sinquas orgánica seca"},
    {prodId:"50436853",label:"Frijol de soya orgánicos secos"},
    {prodId:"50436854",label:"Spoonwart orgánico seco"},
    {prodId:"50436855",label:"Uva tassele hyacinth orgánico seco"},
    {prodId:"50436856",label:"Taro orgánico seco"},
    {prodId:"50436857",label:"Hoja de taro orgánica seca"},
    {prodId:"50436858",label:"Tallo de taro orgánico seco"},
    {prodId:"50436859",label:"Tapeguaje orgánico seco"},
    {prodId:"50436860",label:"Verde tierno orgánico seco"},
    {prodId:"50436861",label:"Tindora orgánico seco"},
    {prodId:"50436862",label:"Árbol de cebolla orgánico seco"},
    {prodId:"50436863",label:"Udo orgánico seco"},
    {prodId:"50436864",label:"Agua castaño orgánico seco"},
    {prodId:"50436865",label:"Yumpi orgánico seco"},
    {prodId:"50436866",label:"Yautia orgánico seco"},
    {prodId:"50436867",label:"Yu choy orgánico seco"},
    {prodId:"50436868",label:"Yuca orgánica seca"},
    {prodId:"50441700",label:"Aguacates congelados"},
    {prodId:"50441701",label:"Aguacate ajax b - 7 congelado"},
    {prodId:"50441702",label:"Aguacate arue congelado"},
    {prodId:"50441703",label:"Aguacate bacon congelado"},
    {prodId:"50441704",label:"Aguacate benik congelado"},
    {prodId:"50441705",label:"Aguacate bernecker congelado"},
    {prodId:"50441706",label:"Aguacate beta congelado"},
    {prodId:"50441707",label:"Aguacate biondo congelado"},
    {prodId:"50441708",label:"Aguacate príncipe negro congelado"},
    {prodId:"50441709",label:"Aguacate blair congelado"},
    {prodId:"50441710",label:"Aguacate blair cabina congelado"},
    {prodId:"50441711",label:"Aguacate cabina 1 congelado"},
    {prodId:"50441712",label:"Aguacate cabina 3 congelado"},
    {prodId:"50441713",label:"Aguacate cabina 5 congelado"},
    {prodId:"50441714",label:"Aguacate cabina 7 congelado"},
    {prodId:"50441715",label:"Aguacate cabina 8 congelado"},
    {prodId:"50441716",label:"Aguacate brooks 1978 congelado"},
    {prodId:"50441717",label:"Aguacate brookslate congelado"},
    {prodId:"50441718",label:"Aguacate california haas congelado"},
    {prodId:"50441719",label:"Aguacate catalina congelado"},
    {prodId:"50441720",label:"Aguacate chica congelado"},
    {prodId:"50441721",label:"Aguacate choquette congelado"},
    {prodId:"50441722",label:"Aguacate cristina congelado"},
    {prodId:"50441723",label:"Aguacate collison congelado"},
    {prodId:"50441724",label:"Aguacate donnie congelado"},
    {prodId:"50441725",label:"Aguacate dr dupis numero 2 congelado"},
    {prodId:"50441726",label:"Aguacate dr. dupie  congelado"},
    {prodId:"50441727",label:"Aguacate ettinger congelado"},
    {prodId:"50441728",label:"Aguacate fuch congelado"},
    {prodId:"50441729",label:"Aguacate fuch gwen congelado"},
    {prodId:"50441730",label:"Aguacate fuerte congelado"},
    {prodId:"50441731",label:"Aguacate gotham congelado"},
    {prodId:"50441732",label:"Aguacate gossman congelado"},
    {prodId:"50441733",label:"Aguacate guatemala sin pepa congelado"},
    {prodId:"50441734",label:"Aguacate hall congelado"},
    {prodId:"50441735",label:"Aguacate hardee congelado"},
    {prodId:"50441736",label:"Aguacate haas congelado"},
    {prodId:"50441737",label:"Aguacate herman congelado"},
    {prodId:"50441738",label:"Aguacate hickson congelado"},
    {prodId:"50441739",label:"Aguacate k-5 congelado"},
    {prodId:"50441740",label:"Aguacate k- 9 congelado"},
    {prodId:"50441741",label:"Aguacate cordero haas congelado"},
    {prodId:"50441742",label:"Aguacate leona congelado"},
    {prodId:"50441743",label:"Aguacate leona linda congelado"},
    {prodId:"50441744",label:"Aguacate lisa p congelado"},
    {prodId:"50441745",label:"Aguacate lisa loretta congelado"},
    {prodId:"50441746",label:"Aguacate loretta congelado"},
    {prodId:"50441747",label:"Aguacate lula congelado"},
    {prodId:"50441748",label:"Aguacate lula mascarthur congelado"},
    {prodId:"50441749",label:"Aguacate marcus congelado"},
    {prodId:"50441750",label:"Aguacate melendez congelado"},
    {prodId:"50441751",label:"Aguacate meya congelado"},
    {prodId:"50441752",label:"Aguacate miguel p congelado"},
    {prodId:"50441753",label:"Aguacate monroe congelado"},
    {prodId:"50441754",label:"Aguacate murrieta verde congelado"},
    {prodId:"50441755",label:"Aguacate nabal congelado"},
    {prodId:"50441756",label:"Aguacate nadir congelado"},
    {prodId:"50441757",label:"Aguacate nesbitt congelado"},
    {prodId:"50441758",label:"Aguacate peterson congelado"},
    {prodId:"50441759",label:"Aguacate pinelli congelado"},
    {prodId:"50441760",label:"Aguacate pinkerton congelado"},
    {prodId:"50441761",label:"Aguacate pollock congelado"},
    {prodId:"50441762",label:"Aguacate puebla congelado"},
    {prodId:"50441763",label:"Aguacate reed congelado"},
    {prodId:"50441764",label:"Aguacaterue congelado"},
    {prodId:"50441765",label:"Aguacateruehle congelado"},
    {prodId:"50441766",label:"Aguacate ryan congelado"},
    {prodId:"50441767",label:"Aguacate semil congelado"},
    {prodId:"50441768",label:"Aguacate semil 43 congelado"},
    {prodId:"50441769",label:"Aguacate simonds congelado"},
    {prodId:"50441770",label:"Aguacate simpson congelado"},
    {prodId:"50441771",label:"Aguacate taylor congelado"},
    {prodId:"50441772",label:"Aguacate tonelada congelado"},
    {prodId:"50441773",label:"Aguacate torre congelado"},
    {prodId:"50441774",label:"Aguacate torre li congelado"},
    {prodId:"50441775",label:"Aguacate trapp congelado"},
    {prodId:"50441776",label:"Aguacate semilla de caribe congelado"},
    {prodId:"50441777",label:"Aguacate wagner congelado"},
    {prodId:"50441778",label:"Aguacate waldin congelado"},
    {prodId:"50441779",label:"Aguacate wurtz congelado"},
    {prodId:"50441780",label:"Aguacate zio p congelado"},
    {prodId:"50441781",label:"Aguacate ziu congelado"},
    {prodId:"50441782",label:"Aguacate zultano congelado"},
    {prodId:"50442000",label:"Brócolis  congeladas"},
    {prodId:"50442001",label:"Brócolini congelado"},
    {prodId:"50442002",label:"Brócoli romanesco congelado"},
    {prodId:"50442003",label:"Brócoli raab congelado"},
    {prodId:"50442004",label:"Brócoli chino congelado"},
    {prodId:"50442500",label:"Zanahorias  congeladas"},
    {prodId:"50442501",label:"Zanahoria amsterdam congelada"},
    {prodId:"50442502",label:"Zanahoria rey de otoño congelada"},
    {prodId:"50442503",label:"Zanahoria berlicum congelada"},
    {prodId:"50442504",label:"Zanahoria chantenay congelada"},
    {prodId:"50442505",label:"Zanahoria nantes congelada"},
    {prodId:"50442506",label:"Zanahoria mercado de parís congelada"},
    {prodId:"50442600",label:"Coliflores  congeladas"},
    {prodId:"50442601",label:"Coliflor todo el año  congelada"},
    {prodId:"50442602",label:"Coliflor alverda congelada"},
    {prodId:"50442603",label:"coliflor otoño gigante 3 congelada"},
    {prodId:"50442604",label:"Coliflo rdok elgon congelada"},
    {prodId:"50442605",label:"Coliflor bola de nieve temprana congelada"},
    {prodId:"50442606",label:"Coliflor luz de lima congelada"},
    {prodId:"50442607",label:"Coliflor minaret congelada"},
    {prodId:"50442608",label:"Coliflor buque de naranja congelada"},
    {prodId:"50442609",label:"Coliflor capa morada congelada"},
    {prodId:"50442610",label:"Coliflor bola de nieve congelada"},
    {prodId:"50442611",label:"Coliflor invierno walcheren 3 congelada"},
    {prodId:"50442612",label:"Coliflor piedra blanca congelada"},
    {prodId:"50443200",label:"Maíces congelados"},
    {prodId:"50443201",label:"Maíz aloha congelado"},
    {prodId:"50443202",label:"Maíz alpine congelado"},
    {prodId:"50443203",label:"Maíz ambrosia congelado"},
    {prodId:"50443204",label:"Maíz argent congelado"},
    {prodId:"50443205",label:"Maíz aspen congelado"},
    {prodId:"50443206",label:"Maíz avalancha congelado"},
    {prodId:"50443207",label:"Maíz biqueen congelado"},
    {prodId:"50443208",label:"Maíz  bodacious congelado"},
    {prodId:"50443209",label:"Maíz  mantequilla y azúcar congelado"},
    {prodId:"50443210",label:"Maíz  calico belle congelado"},
    {prodId:"50443211",label:"Maíz  camelot congelado"},
    {prodId:"50443212",label:"Maíz  challengercrisp ‘n dulce congelado"},
    {prodId:"50443213",label:"Maíz  campeón congelado"},
    {prodId:"50443214",label:"Maíz  algodón de azúcar congelado"},
    {prodId:"50443215",label:"Maíz  dártagnan congelado"},
    {prodId:"50443216",label:"Maíz  dazzle congelado"},
    {prodId:"50443217",label:"Maíz  diamante y oro congelado"},
    {prodId:"50443218",label:"Maíz  divinidad congelado"},
    {prodId:"50443219",label:"Maíz  delicia doble congelado"},
    {prodId:"50443220",label:"Maíz  gema doble congelado"},
    {prodId:"50443221",label:"Maíz  earlivee congelado"},
    {prodId:"50443222",label:"Maíz  temprano extra dulce congelado"},
    {prodId:"50443223",label:"Maíz excel congelado"},
    {prodId:"50443224",label:"Maíz  cruz dorada bantam congelado"},
    {prodId:"50443225",label:"Maíz  miel y crema congelado"},
    {prodId:"50443226",label:"Maíz  miel y perla congelado"},
    {prodId:"50443227",label:"Maíz  miel dulce congelado"},
    {prodId:"50443228",label:"Maíz  hudson congelado"},
    {prodId:"50443229",label:"Maíz  dorado illini congelado"},
    {prodId:"50443230",label:"Maíz  illini extra dulce congelado"},
    {prodId:"50443231",label:"Maíz  increíble congelado"},
    {prodId:"50443232",label:"Maíz  lochief congelado"},
    {prodId:"50443233",label:"Maíz jubileo congelado"},
    {prodId:"50443234",label:"Maíz jupbileo super dulce congelado"},
    {prodId:"50443235",label:"Maíz  confite korn congelado"},
    {prodId:"50443236",label:"Maíz  beso y acuso congelado"},
    {prodId:"50443237",label:"Maíz  lancelot congelado"},
    {prodId:"50443238",label:"Maíz dulce maple congelado"},
    {prodId:"50443239",label:"Maíz  medley congelado"},
    {prodId:"50443240",label:"Maíz merlin congelado"},
    {prodId:"50443241",label:"Maíz milagro congelado"},
    {prodId:"50443242",label:"Maíz nk - 199 congelado"},
    {prodId:"50443243",label:"Maíz durazno y crema congelado"},
    {prodId:"50443244",label:"Maíz blanco perla congelado"},
    {prodId:"50443245",label:"Maíz  pegasus congelado"},
    {prodId:"50443246",label:"Maíz  fenomenal congelado"},
    {prodId:"50443247",label:"Maíz  dama de platino congelado"},
    {prodId:"50443248",label:"Maíz  precioso congelado"},
    {prodId:"50443249",label:"Maíz   pristine congelado"},
    {prodId:"50443250",label:"Maíz  rápido congelado"},
    {prodId:"50443251",label:"Maíz  radiante congelado"},
    {prodId:"50443252",label:"Maíz  seneca brave congelado"},
    {prodId:"50443253",label:"Maíz  amanecer seneca congelado"},
    {prodId:"50443254",label:"Maíz  horizonte seneca congelado"},
    {prodId:"50443255",label:"Maíz  brillo de estrella seneca congelado"},
    {prodId:"50443256",label:"Maíz  noche blanca seneca congelado"},
    {prodId:"50443257",label:"Maíz  showcase congelado"},
    {prodId:"50443258",label:"Maíz  reina plateada congelado"},
    {prodId:"50443259",label:"Maíz  bella nieve congelado"},
    {prodId:"50443260",label:"Maíz nieve de primavera congelado"},
    {prodId:"50443261",label:"Maíz  premio de primavera congelado"},
    {prodId:"50443262",label:"Maíz  azúcar y oro congelado"},
    {prodId:"50443263",label:"Maíz rollo de azúcar congelado"},
    {prodId:"50443264",label:"Maíz nieve de azúcar congelado"},
    {prodId:"50443265",label:"Maíz baile de sol congelado"},
    {prodId:"50443266",label:"Maíz estrella tell congelado"},
    {prodId:"50443267",label:"Maíz terminador congelado"},
    {prodId:"50443268",label:"Maíz tesoro congelado"},
    {prodId:"50443269",label:"Maíz tuxedo congelado"},
    {prodId:"50445200",label:"Cebollas congeladas"},
    {prodId:"50445201",label:"Cebolla albion congelada"},
    {prodId:"50445202",label:"Cebolla alisia craig congelada"},
    {prodId:"50445203",label:"Cebolla hirviendo congelada"},
    {prodId:"50445204",label:"Cebolla bufalo congelada"},
    {prodId:"50445205",label:"Cebolla bulbo congelada"},
    {prodId:"50445206",label:"Cebolla cremosa congelada"},
    {prodId:"50445207",label:"Cebolla amarillo expreso o - x congelada"},
    {prodId:"50445208",label:"Cebolla kelsae congelada"},
    {prodId:"50445209",label:"Cebolla gigante marshal globo fen congelada"},
    {prodId:"50445210",label:"Cebolla perla congelada"},
    {prodId:"50445211",label:"Cebolla baron rojo congelada"},
    {prodId:"50445212",label:"Cebolla roja congelada"},
    {prodId:"50445213",label:"Cebolla rijnsberger congelada"},
    {prodId:"50445214",label:"Cebolla senshyu semi - globo amarillo congelada"},
    {prodId:"50445215",label:"Cebolla sturon congelada"},
    {prodId:"50445216",label:"Cebolla sturgatter congelada"},
    {prodId:"50445217",label:"Cebolla dulce congelada"},
    {prodId:"50445218",label:"Cebolla torpedo o rojo italiana congelada"},
    {prodId:"50445219",label:"Cebolla roja guardada congelada"},
    {prodId:"50445220",label:"Cebolla blanca guardada congelada"},
    {prodId:"50445221",label:"Cebolla amarilla guardada congelada"},
    {prodId:"50445500",label:"Pimientos congelados"},
    {prodId:"50445501",label:"Pimentón ají congelado"},
    {prodId:"50445502",label:"Pimentón  árbol congelado"},
    {prodId:"50445503",label:"Pimentón queso congelado"},
    {prodId:"50445504",label:"Pimentón chilaca congelado"},
    {prodId:"50445505",label:"Pimentón cubanelles congelado"},
    {prodId:"50445506",label:"Pimentón fresno congelado"},
    {prodId:"50445507",label:"Pimentón kapia congelado"},
    {prodId:"50445508",label:"Pimentón coreano congelado"},
    {prodId:"50445509",label:"Pimentón manzano congelado"},
    {prodId:"50445510",label:"Pimentón melrose congelado"},
    {prodId:"50445511",label:"Pimentón chile amarillo congelado"},
    {prodId:"50445512",label:"Pimentón aji dulce congelado"},
    {prodId:"50445513",label:"Pimentón anaheim congelado"},
    {prodId:"50445514",label:"Pimentón ancho congelado"},
    {prodId:"50445515",label:"Pimentón campana congelado"},
    {prodId:"50445516",label:"Pimentón cascabel congelado"},
    {prodId:"50445517",label:"Pimentón cayenne congelado"},
    {prodId:"50445518",label:"Pimentón anfitrión cereza congelado"},
    {prodId:"50445519",label:"Pimentón chitespin congelado"},
    {prodId:"50445520",label:"Pimentón dedo picante congelado"},
    {prodId:"50445521",label:"Pimentón guajillo congelado"},
    {prodId:"50445522",label:"Pimentón guerro congelado"},
    {prodId:"50445523",label:"Pimentón habanero congelado"},
    {prodId:"50445524",label:"Pimentón cera de hungria congelado"},
    {prodId:"50445525",label:"Pimentón jalapeño congelado"},
    {prodId:"50445526",label:"Pimentón picante largo congelado"},
    {prodId:"50445527",label:"Pimentón marasol congelado"},
    {prodId:"50445528",label:"Pimentón pasilla congelado"},
    {prodId:"50445529",label:"Pimentón peperoncini congelado"},
    {prodId:"50445530",label:"Pimentón pequin congelado"},
    {prodId:"50445531",label:"Pimentón pimiento congelado"},
    {prodId:"50445532",label:"Pimentón poblano congelado"},
    {prodId:"50445533",label:"Pimentón scotch bonnet congelado"},
    {prodId:"50445534",label:"Pimentón serrano congelado"},
    {prodId:"50445535",label:"Pimentón tabasco congelado"},
    {prodId:"50445536",label:"Pimentón tai  congelado"},
    {prodId:"50445537",label:"Pimentón tepin congelado"},
    {prodId:"50445600",label:"Papas congeladas"},
    {prodId:"50445601",label:"Papa blanca larga congelada"},
    {prodId:"50445602",label:"Papa redonda blanca congelada"},
    {prodId:"50445603",label:"Papa redonda roja congelada"},
    {prodId:"50445604",label:"Papa  russet congelada"},
    {prodId:"50445605",label:"Papa morada congelada"},
    {prodId:"50445606",label:"Papa amarilla congelada"},
    {prodId:"50445607",label:"Papa nueva congelada"},
    {prodId:"50445608",label:"Papa especial congelada"},
    {prodId:"50445609",label:"Papa coctel congelada"},
    {prodId:"50445610",label:"Papa peruanita congelada"},
    {prodId:"50445611",label:"Papa huayro congelada"},
    {prodId:"50446200",label:"Calabazas congeladas"},
    {prodId:"50446201",label:"Calabaza boston congelada"},
    {prodId:"50446202",label:"Calabaza buttemut congelada"},
    {prodId:"50446203",label:"Calabaza costata romanesca congelada"},
    {prodId:"50446204",label:"Calabaza crookneck congelada"},
    {prodId:"50446205",label:"Calabaza  cucuzza congelada"},
    {prodId:"50446206",label:"Calabaza  delicata congelada"},
    {prodId:"50446207",label:"Calabaza  delisioso congelada"},
    {prodId:"50446208",label:"Calabaza verano dorado cuello torcido congelada"},
    {prodId:"50446209",label:"Calabaza prolífico temprano cuello recto congelada"},
    {prodId:"50446210",label:"Calabaza  oro congelada"},
    {prodId:"50446211",label:"Calabaza  jack pequeño congelada"},
    {prodId:"50446212",label:"Calabaza  campo de kentucky congelada"},
    {prodId:"50446213",label:"Calabaza marrow congelada"},
    {prodId:"50446214",label:"Calabaza  medio oriente congelada"},
    {prodId:"50446215",label:"Calabaza  miniatura congelada"},
    {prodId:"50446216",label:"Calabaza  orangetti congelada"},
    {prodId:"50446217",label:"Calabaza pattypan congelada"},
    {prodId:"50446218",label:"Calabaza  rondini congelada"},
    {prodId:"50446219",label:"Calabaza  redonda congelada"},
    {prodId:"50446220",label:"Calabaza  espagueti congelada"},
    {prodId:"50446221",label:"Calabaza  estripeti congelada"},
    {prodId:"50446222",label:"Calabaza  molde dulce congelada"},
    {prodId:"50446223",label:"Calabaza  empanada dulce congelada"},
    {prodId:"50446224",label:"Calabaza  premio triple  congelada"},
    {prodId:"50446225",label:"Calabaza waltham butternut congelada"},
    {prodId:"50446226",label:"Calabaza  arbusto scallop amarillo congelada"},
    {prodId:"50446227",label:"Calabaza cuello recto amarillo congelada"},
    {prodId:"50446228",label:"Calabaza zafiro congelada"},
    {prodId:"50446229",label:"Calabaza zucchini congelada"},
    {prodId:"50446800",label:"Vegetales Nominant congelados"},
    {prodId:"50446801",label:"Alfalfa congelada"},
    {prodId:"50446802",label:"Hojas de aloe congelada"},
    {prodId:"50446803",label:"Apio congelado"},
    {prodId:"50446804",label:"Arrurruz congelada"},
    {prodId:"50446805",label:"Punta de flecha congelada"},
    {prodId:"50446806",label:"rúcula congelada"},
    {prodId:"50446807",label:"Arum congelada"},
    {prodId:"50446808",label:"Raíz de bambú congelada"},
    {prodId:"50446809",label:"Hojas de plátano congelada"},
    {prodId:"50446810",label:"Batatas congelada"},
    {prodId:"50446811",label:"Brotes de frijol congelados"},
    {prodId:"50446812",label:"Top de remolacha congelada"},
    {prodId:"50446813",label:"Melón agrio congelado"},
    {prodId:"50446814",label:"Bayas de alcaparra congeladas"},
    {prodId:"50446815",label:"Carob congelado"},
    {prodId:"50446816",label:"Cha-om congelado"},
    {prodId:"50446817",label:"Chayote o guatila congelado"},
    {prodId:"50446818",label:"Garbanzo congelado"},
    {prodId:"50446819",label:"Verde de crisantemos congelado"},
    {prodId:"50446820",label:"Verde de diente de león congelado"},
    {prodId:"50446821",label:"Diente de león congelado"},
    {prodId:"50446822",label:"Dasheen congelado"},
    {prodId:"50446823",label:"Puntas de alverja congelado"},
    {prodId:"50446824",label:"Diakon congelado"},
    {prodId:"50446825",label:"Donqua congelada"},
    {prodId:"50446826",label:"Helecho congelado"},
    {prodId:"50446827",label:"Gai choi congelado"},
    {prodId:"50446828",label:"Gailon congelado"},
    {prodId:"50446829",label:"Galanga congelada"},
    {prodId:"50446830",label:"Raíz de jengibre congelada"},
    {prodId:"50446831",label:"Gobo congelado"},
    {prodId:"50446832",label:"Brote de lúpulo congelado"},
    {prodId:"50446833",label:"Rabano blanco congelado"},
    {prodId:"50446834",label:"Jicama congelada"},
    {prodId:"50446835",label:"Kuduz congelada"},
    {prodId:"50446836",label:"Bulbo de margarita congelado"},
    {prodId:"50446837",label:"Linkok congelado"},
    {prodId:"50446838",label:"Lo bok congelado"},
    {prodId:"50446839",label:"Frijol largo congelado"},
    {prodId:"50446840",label:"Raíz de lotus congelado"},
    {prodId:"50446841",label:"Hojas de maguey congelada"},
    {prodId:"50446842",label:"Mallows congelado"},
    {prodId:"50446843",label:"Sapote mamey congelado"},
    {prodId:"50446844",label:"Moap congelado"},
    {prodId:"50446845",label:"Moo congelado"},
    {prodId:"50446846",label:"Moqua congelada"},
    {prodId:"50446847",label:"Opos congelados"},
    {prodId:"50446848",label:"Corazon de palma congelado"},
    {prodId:"50446849",label:"Paprika congelada"},
    {prodId:"50446850",label:"Purslane congelada"},
    {prodId:"50446851",label:"Raddichios congelada"},
    {prodId:"50446852",label:"Sinquas congelada"},
    {prodId:"50446853",label:"Frijol de soya congelados"},
    {prodId:"50446854",label:"Spoonwart congelado"},
    {prodId:"50446855",label:"Uva tassele hyacinth congelado"},
    {prodId:"50446856",label:"Taro congelado"},
    {prodId:"50446857",label:"Hoja de taro congelada"},
    {prodId:"50446858",label:"Tallo de taro congelado"},
    {prodId:"50446859",label:"Tapeguaje congelado"},
    {prodId:"50446860",label:"Verde tierno congelado"},
    {prodId:"50446861",label:"Tindora congelado"},
    {prodId:"50446862",label:"Árbol de cebolla congelado"},
    {prodId:"50446863",label:"Udo congelado"},
    {prodId:"50446864",label:"Agua castaño congelado"},
    {prodId:"50446865",label:"Yumpi congelado"},
    {prodId:"50446866",label:"Yautia congelado"},
    {prodId:"50446867",label:"Yu choy congelado"},
    {prodId:"50446868",label:"Yuca congelada"},
    {prodId:"50451700",label:"Aguacates orgánicos congelados"},
    {prodId:"50451701",label:"Aguacate ajax b - 7 orgánico congelado"},
    {prodId:"50451702",label:"Aguacate arue orgánico congelado"},
    {prodId:"50451703",label:"Aguacate bacon orgánico congelado"},
    {prodId:"50451704",label:"Aguacate benik orgánico congelado"},
    {prodId:"50451705",label:"Aguacate bernecker orgánico congelado"},
    {prodId:"50451706",label:"Aguacate beta orgánico congelado"},
    {prodId:"50451707",label:"Aguacate biondo orgánico congelado"},
    {prodId:"50451708",label:"Aguacate príncipe negro orgánico congelado"},
    {prodId:"50451709",label:"Aguacate blair orgánico congelado"},
    {prodId:"50451710",label:"Aguacate blair cabina orgánico congelado"},
    {prodId:"50451711",label:"Aguacate cabina 1 orgánico congelado"},
    {prodId:"50451712",label:"Aguacate cabina 3 orgánico congelado"},
    {prodId:"50451713",label:"Aguacate cabina 5 orgánico congelado"},
    {prodId:"50451714",label:"Aguacate cabina 7 orgánico congelado"},
    {prodId:"50451715",label:"Aguacate cabina 8 orgánico congelado"},
    {prodId:"50451716",label:"Aguacate brooks 1978 orgánico congelado"},
    {prodId:"50451717",label:"Aguacate brookslate orgánico congelado"},
    {prodId:"50451718",label:"Aguacate california haas orgánico congelado"},
    {prodId:"50451719",label:"Aguacate catalina orgánico congelado"},
    {prodId:"50451720",label:"Aguacate chica orgánico congelado"},
    {prodId:"50451721",label:"Aguacate choquette orgánico congelado"},
    {prodId:"50451722",label:"Aguacate cristina orgánico congelado"},
    {prodId:"50451723",label:"Aguacate collison orgánico congelado"},
    {prodId:"50451724",label:"Aguacate donnie orgánico congelado"},
    {prodId:"50451725",label:"Aguacate dr dupis numero 2 orgánico congelado"},
    {prodId:"50451726",label:"Aguacate dr. dupie  orgánico congelado"},
    {prodId:"50451727",label:"Aguacate ettinger orgánico congelado"},
    {prodId:"50451728",label:"Aguacate fuch orgánico congelado"},
    {prodId:"50451729",label:"Aguacate fuch gwen orgánico congelado"},
    {prodId:"50451730",label:"Aguacate fuerte orgánico congelado"},
    {prodId:"50451731",label:"Aguacate gotham orgánico congelado"},
    {prodId:"50451732",label:"Aguacate gossman orgánico congelado"},
    {prodId:"50451733",label:"Aguacate guatemala sin pepa orgánico congelado"},
    {prodId:"50451734",label:"Aguacate hall orgánico congelado"},
    {prodId:"50451735",label:"Aguacate hardee orgánico congelado"},
    {prodId:"50451736",label:"Aguacate haas orgánico congelado"},
    {prodId:"50451737",label:"Aguacate herman orgánico congelado"},
    {prodId:"50451738",label:"Aguacate hickson orgánico congelado"},
    {prodId:"50451739",label:"Aguacate k-5 orgánico congelado"},
    {prodId:"50451740",label:"Aguacate k- 9 orgánico congelado"},
    {prodId:"50451741",label:"Aguacate cordero haas orgánico congelado"},
    {prodId:"50451742",label:"Aguacate leona orgánico congelado"},
    {prodId:"50451743",label:"Aguacate leona linda orgánico congelado"},
    {prodId:"50451744",label:"Aguacate lisa p orgánico congelado"},
    {prodId:"50451745",label:"Aguacate lisa loretta orgánico congelado"},
    {prodId:"50451746",label:"Aguacate loretta orgánico congelado"},
    {prodId:"50451747",label:"Aguacate lula orgánico congelado"},
    {prodId:"50451748",label:"Aguacate lula mascarthur orgánico congelado"},
    {prodId:"50451749",label:"Aguacate marcus orgánico congelado"},
    {prodId:"50451750",label:"Aguacate melendez orgánico congelado"},
    {prodId:"50451751",label:"Aguacate meya orgánico congelado"},
    {prodId:"50451752",label:"Aguacate miguel p orgánico congelado"},
    {prodId:"50451753",label:"Aguacate monroe orgánico congelado"},
    {prodId:"50451754",label:"Aguacate murrieta verde orgánico congelado"},
    {prodId:"50451755",label:"Aguacate nabal orgánico congelado"},
    {prodId:"50451756",label:"Aguacate nadir orgánico congelado"},
    {prodId:"50451757",label:"Aguacate nesbitt orgánico congelado"},
    {prodId:"50451758",label:"Aguacate peterson orgánico congelado"},
    {prodId:"50451759",label:"Aguacate pinelli orgánico congelado"},
    {prodId:"50451760",label:"Aguacate pinkerton orgánico congelado"},
    {prodId:"50451761",label:"Aguacate pollock orgánico congelado"},
    {prodId:"50451762",label:"Aguacate puebla orgánico congelado"},
    {prodId:"50451763",label:"Aguacate reed orgánico congelado"},
    {prodId:"50451764",label:"Aguacaterue orgánico congelado"},
    {prodId:"50451765",label:"Aguacateruehle orgánico congelado"},
    {prodId:"50451766",label:"Aguacate ryan orgánico congelado"},
    {prodId:"50451767",label:"Aguacate semil orgánico congelado"},
    {prodId:"50451768",label:"Aguacate semil 43 orgánico congelado"},
    {prodId:"50451769",label:"Aguacate simonds orgánico congelado"},
    {prodId:"50451770",label:"Aguacate simpson orgánico congelado"},
    {prodId:"50451771",label:"Aguacate taylor orgánico congelado"},
    {prodId:"50451772",label:"Aguacate tonelada orgánico congelado"},
    {prodId:"50451773",label:"Aguacate torre orgánico congelado"},
    {prodId:"50451774",label:"Aguacate torre li orgánico congelado"},
    {prodId:"50451775",label:"Aguacate trapp orgánico congelado"},
    {prodId:"50451776",label:"Aguacate semilla de caribe orgánico congelado"},
    {prodId:"50451777",label:"Aguacate wagner orgánico congelado"},
    {prodId:"50451778",label:"Aguacate waldin orgánico congelado"},
    {prodId:"50451779",label:"Aguacate wurtz orgánico congelado"},
    {prodId:"50451780",label:"Aguacate zio p orgánico congelado"},
    {prodId:"50451781",label:"Aguacate ziu orgánico congelado"},
    {prodId:"50451782",label:"Aguacate zultano orgánico congelado"},
    {prodId:"50452500",label:"Zanahorias  orgánicas congeladas"},
    {prodId:"50452501",label:"Zanahoria amsterdam orgánica congelada"},
    {prodId:"50452502",label:"Zanahoria rey de otoño orgánica congelada"},
    {prodId:"50452503",label:"Zanahoria berlicum orgánica congelada"},
    {prodId:"50452504",label:"Zanahoria chantenay orgánica congelada"},
    {prodId:"50452505",label:"Zanahoria nantes orgánica congelada"},
    {prodId:"50452506",label:"Zanahoria mercado de parís orgánica congelada"},
    {prodId:"50461500",label:"Alcachofas en lata o en frasco"},
    {prodId:"50461501",label:"Alcachofa brittany enlatada o envasada"},
    {prodId:"50461502",label:"Alcachofa cantonesa enlatada o envasada"},
    {prodId:"50461503",label:"Alcachofa francesa enlatada o envasada"},
    {prodId:"50461504",label:"Alcachofa globo verde enlatada o envasada"},
    {prodId:"50461505",label:"Alcachofa gros camus de bretaña enlatada o envasada"},
    {prodId:"50461506",label:"Alcachofa midi enlatada o envasada"},
    {prodId:"50461507",label:"Alcachofa globo morado enlatada o envasada"},
    {prodId:"50461508",label:"Alcachofa morado cecilia enlatada o envasada"},
    {prodId:"50461509",label:"Alcachofa romanesco enlatada o envasada"},
    {prodId:"50461510",label:"Alcachofa espinoso sardo enlatada o envasada"},
    {prodId:"50461511",label:"Alcachofa vert de laon enlatada o envasada"},
    {prodId:"50461512",label:"Alcachofa violeta de chiggiia enlatada o envasada"},
    {prodId:"50461513",label:"Alcachofa violeta de toscana enlatada o envasada"},
    {prodId:"50461600",label:"Espárragos en lata o en frasco"},
    {prodId:"50461601",label:"Espárragos connovers colosal enlatados o envasados"},
    {prodId:"50461602",label:"Espárragos franklin enlatados o envasados"},
    {prodId:"50461603",label:"Espárragos mamut gigante enlatados o envasados"},
    {prodId:"50461604",label:"Espárragos lucullus enlatados o envasados"},
    {prodId:"50461605",label:"Espárragos martha washington enlatados o envasados"},
    {prodId:"50461700",label:"Aguacates en lata o en frasco"},
    {prodId:"50461701",label:"Aguacate ajax b - 7 enlatado o envasado"},
    {prodId:"50461702",label:"Aguacate arue enlatado o envasado"},
    {prodId:"50461703",label:"Aguacate bacon enlatado o envasado"},
    {prodId:"50461704",label:"Aguacate benik enlatado o envasado"},
    {prodId:"50461705",label:"Aguacate bernecker enlatado o envasado"},
    {prodId:"50461706",label:"Aguacate beta enlatado o envasado"},
    {prodId:"50461707",label:"Aguacate biondo enlatado o envasado"},
    {prodId:"50461708",label:"Aguacate príncipe negro enlatado o envasado"},
    {prodId:"50461709",label:"Aguacate blair enlatado o envasado"},
    {prodId:"50461710",label:"Aguacate blair cabina enlatado o envasado"},
    {prodId:"50461711",label:"Aguacate cabina 1 enlatado o envasado"},
    {prodId:"50461712",label:"Aguacate cabina 3 enlatado o envasado"},
    {prodId:"50461713",label:"Aguacate cabina 5 enlatado o envasado"},
    {prodId:"50461714",label:"Aguacate cabina 7 enlatado o envasado"},
    {prodId:"50461715",label:"Aguacate cabina 8 enlatado o envasado"},
    {prodId:"50461716",label:"Aguacate brooks 1978 enlatado o envasado"},
    {prodId:"50461717",label:"Aguacate brookslate enlatado o envasado"},
    {prodId:"50461718",label:"Aguacate california haas enlatado o envasado"},
    {prodId:"50461719",label:"Aguacate catalina enlatado o envasado"},
    {prodId:"50461720",label:"Aguacate chica enlatado o envasado"},
    {prodId:"50461721",label:"Aguacate choquette enlatado o envasado"},
    {prodId:"50461722",label:"Aguacate cristina enlatado o envasado"},
    {prodId:"50461723",label:"Aguacate collison enlatado o envasado"},
    {prodId:"50461724",label:"Aguacate donnie enlatado o envasado"},
    {prodId:"50461725",label:"Aguacate dr dupis numero 2 enlatado o envasado"},
    {prodId:"50461726",label:"Aguacate dr. dupie  enlatado o envasado"},
    {prodId:"50461727",label:"Aguacate ettinger enlatado o envasado"},
    {prodId:"50461728",label:"Aguacate fuch enlatado o envasado"},
    {prodId:"50461729",label:"Aguacate fuch gwen enlatado o envasado"},
    {prodId:"50461730",label:"Aguacate fuerte enlatado o envasado"},
    {prodId:"50461731",label:"Aguacate gotham enlatado o envasado"},
    {prodId:"50461732",label:"Aguacate gossman enlatado o envasado"},
    {prodId:"50461733",label:"Aguacate guatemala sin pepa enlatado o envasado"},
    {prodId:"50461734",label:"Aguacate hall enlatado o envasado"},
    {prodId:"50461735",label:"Aguacate hardee enlatado o envasado"},
    {prodId:"50461736",label:"Aguacate haas enlatado o envasado"},
    {prodId:"50461737",label:"Aguacate herman enlatado o envasado"},
    {prodId:"50461738",label:"Aguacate hickson enlatado o envasado"},
    {prodId:"50461739",label:"Aguacate k-5 enlatado o envasado"},
    {prodId:"50461740",label:"Aguacate k- 9 enlatado o envasado"},
    {prodId:"50461741",label:"Aguacate cordero haas enlatado o envasado"},
    {prodId:"50461742",label:"Aguacate leona enlatado o envasado"},
    {prodId:"50461743",label:"Aguacate leona linda enlatado o envasado"},
    {prodId:"50461744",label:"Aguacate lisa p enlatado o envasado"},
    {prodId:"50461745",label:"Aguacate lisa loretta enlatado o envasado"},
    {prodId:"50461746",label:"Aguacate loretta enlatado o envasado"},
    {prodId:"50461747",label:"Aguacate lula enlatado o envasado"},
    {prodId:"50461748",label:"Aguacate lula mascarthur enlatado o envasado"},
    {prodId:"50461749",label:"Aguacate marcus enlatado o envasado"},
    {prodId:"50461750",label:"Aguacate melendez enlatado o envasado"},
    {prodId:"50461751",label:"Aguacate meya enlatado o envasado"},
    {prodId:"50461752",label:"Aguacate miguel p enlatado o envasado"},
    {prodId:"50461753",label:"Aguacate monroe enlatado o envasado"},
    {prodId:"50461754",label:"Aguacate murrieta verde enlatado o envasado"},
    {prodId:"50461755",label:"Aguacate nabal enlatado o envasado"},
    {prodId:"50461756",label:"Aguacate nadir enlatado o envasado"},
    {prodId:"50461757",label:"Aguacate nesbitt enlatado o envasado"},
    {prodId:"50461758",label:"Aguacate peterson enlatado o envasado"},
    {prodId:"50461759",label:"Aguacate pinelli enlatado o envasado"},
    {prodId:"50461760",label:"Aguacate pinkerton enlatado o envasado"},
    {prodId:"50461761",label:"Aguacate pollock enlatado o envasado"},
    {prodId:"50461762",label:"Aguacate puebla enlatado o envasado"},
    {prodId:"50461763",label:"Aguacate reed enlatado o envasado"},
    {prodId:"50461764",label:"Aguacaterue enlatado o envasado"},
    {prodId:"50461765",label:"Aguacateruehle enlatado o envasado"},
    {prodId:"50461766",label:"Aguacate ryan enlatado o envasado"},
    {prodId:"50461767",label:"Aguacate semil enlatado o envasado"},
    {prodId:"50461768",label:"Aguacate semil 43 enlatado o envasado"},
    {prodId:"50461769",label:"Aguacate simonds enlatado o envasado"},
    {prodId:"50461770",label:"Aguacate simpson enlatado o envasado"},
    {prodId:"50461771",label:"Aguacate taylor enlatado o envasado"},
    {prodId:"50461772",label:"Aguacate tonelada enlatado o envasado"},
    {prodId:"50461773",label:"Aguacate torre enlatado o envasado"},
    {prodId:"50461774",label:"Aguacate torre li enlatado o envasado"},
    {prodId:"50461775",label:"Aguacate trapp enlatado o envasado"},
    {prodId:"50461776",label:"Aguacate semilla de caribe enlatado o envasado"},
    {prodId:"50461777",label:"Aguacate wagner enlatado o envasado"},
    {prodId:"50461778",label:"Aguacate waldin enlatado o envasado"},
    {prodId:"50461779",label:"Aguacate wurtz enlatado o envasado"},
    {prodId:"50461780",label:"Aguacate zio p enlatado o envasado"},
    {prodId:"50461781",label:"Aguacate ziu enlatado o envasado"},
    {prodId:"50461782",label:"Aguacate zultano enlatado o envasado"},
    {prodId:"50461800",label:"Fríjoles en lata o en frasco"},
    {prodId:"50461801",label:"Frijoles anasazi ® enlatados o envasados"},
    {prodId:"50461802",label:"Frijoles appaloosa enlatados o envasados"},
    {prodId:"50461803",label:"Frijoles azuki enlatados o envasados"},
    {prodId:"50461804",label:"Frijoles barlotti enlatados o envasados"},
    {prodId:"50461805",label:"Frijoles appaloosa negra enlatados o envasados"},
    {prodId:"50461806",label:"Frijoles negros enlatados o envasados"},
    {prodId:"50461807",label:"Frijoles gran negros enlatados o envasados"},
    {prodId:"50461808",label:"Frijoles shackamaxon negro enlatados o envasados"},
    {prodId:"50461809",label:"Frijoles ojo negro enlatados o envasados"},
    {prodId:"50461810",label:"Frijoles bobby enlatados o envasados"},
    {prodId:"50461811",label:"Frijoles bolita enlatados o envasados"},
    {prodId:"50461812",label:"Frijoles esposa perezosa carmelita enlatados o envasados"},
    {prodId:"50461813",label:"Frijoles calipso enlatados o envasados"},
    {prodId:"50461814",label:"Frijoles canelini enlatados o envasados"},
    {prodId:"50461815",label:"Frijoles castor enlatados o envasados"},
    {prodId:"50461816",label:"Frijoles amarillo chino enlatados o envasados"},
    {prodId:"50461817",label:"Frijoles lengua de dragón enlatados o envasados"},
    {prodId:"50461818",label:"Frijoles soldado europeo enlatados o envasados"},
    {prodId:"50461819",label:"Frijoles fava enlatados o envasados"},
    {prodId:"50461820",label:"Frijoles flageolet enlatados o envasados"},
    {prodId:"50461821",label:"Frijoles  horticultura francesa enlatados o envasados"},
    {prodId:"50461822",label:"Frijoles marina francesa enlatados o envasados"},
    {prodId:"50461823",label:"Frijoles coco gigante blanco enlatados o envasados"},
    {prodId:"50461824",label:"Frijoles verdes enlatados o envasados"},
    {prodId:"50461825",label:"Frijoles romano verde enlatados o envasados"},
    {prodId:"50461826",label:"Frijoles guar gum enlatados o envasados"},
    {prodId:"50461827",label:"Frijoles haricot enlatados o envasados"},
    {prodId:"50461828",label:"Frijoles hyacinth enlatados o envasados"},
    {prodId:"50461829",label:"Frijoles tipo italiano enlatados o envasados"},
    {prodId:"50461830",label:"Frijoles asombro jackson enlatados o envasados"},
    {prodId:"50461831",label:"Frijoles ganado jacobs  enlatados o envasados"},
    {prodId:"50461832",label:"Frijoles asombro kentucky enlatados o envasados"},
    {prodId:"50461833",label:"Frijoles riñón enlatados o envasados"},
    {prodId:"50461834",label:"Frijoles lima enlatados o envasados"},
    {prodId:"50461835",label:"Frijoles madera enlatados o envasados"},
    {prodId:"50461836",label:"Frijoles medula enlatados o envasados"},
    {prodId:"50461837",label:"Frijoles mat enlatados o envasados"},
    {prodId:"50461838",label:"Frijoles monstoler ganzo salvaje enlatados o envasados"},
    {prodId:"50461839",label:"Frijoles  mortgage lifter enlatados o envasados"},
    {prodId:"50461840",label:"Frijoles polilla enlatados o envasados"},
    {prodId:"50461841",label:"Frijoles mung enlatados o envasados"},
    {prodId:"50461842",label:"Frijoles munsi wolf enlatados o envasados"},
    {prodId:"50461843",label:"Frijoles nuna enlatados o envasados"},
    {prodId:"50461844",label:"Frijoles pinto enlatados o envasados"},
    {prodId:"50461845",label:"Frijoles runner enlatados o envasados"},
    {prodId:"50461846",label:"String beans enlatados o envasados"},
    {prodId:"50461847",label:"Frijoles habichuelas enlatados o envasados"},
    {prodId:"50461848",label:"Frijoles tamarind enlatados o envasados"},
    {prodId:"50461849",label:"Frijoles tonka enlatados o envasados"},
    {prodId:"50461850",label:"Frijoles cera enlatados o envasados"},
    {prodId:"50461851",label:"Frijoles winged enlatados o envasados"},
    {prodId:"50461852",label:"Frijoles largos enlatados o envasados"},
    {prodId:"50462200",label:"Cebollas de ensalada  en lata o en frasco"},
    {prodId:"50462201",label:"Cebolla belstville ensalada enlatada o envasada"},
    {prodId:"50462202",label:"Cebolla feast ensalada enlatada o envasada"},
    {prodId:"50462203",label:"Cebolla ishikura ensalada enlatada o envasada"},
    {prodId:"50462204",label:"Cebolla mercado kyoto enlatada o envasada"},
    {prodId:"50462205",label:"Cebolla barba roja ensalada enlatada o envasada"},
    {prodId:"50462206",label:"Cebolla amigo rojo ensalada enlatada o envasada"},
    {prodId:"50462207",label:"Cebolla santa claus enlatada o envasada"},
    {prodId:"50462208",label:"Cebolla tokyo ensalada enlatada o envasada"},
    {prodId:"50462209",label:"Cebolla lisbon blanca ensalada enlatada o envasada"},
    {prodId:"50462210",label:"Cebolla invierno blanca ensalada enlatada o envasada"},
    {prodId:"50462211",label:"Cebolla final de invierno ensalada enlatada o envasada"},
    {prodId:"50462500",label:"Zanahorias  en lata o en frasco"},
    {prodId:"50462501",label:"Zanahoria amsterdam enlatada o envasada"},
    {prodId:"50462502",label:"Zanahoria rey de otoño enlatada o envasada"},
    {prodId:"50462503",label:"Zanahoria berlicum enlatada o envasada"},
    {prodId:"50462504",label:"Zanahoria chantenay enlatada o envasada"},
    {prodId:"50462505",label:"Zanahoria nantes enlatada o envasada"},
    {prodId:"50462506",label:"Zanahoria mercado de parís enlatada o envasada"},
    {prodId:"50462800",label:"Acelgas  en lata o en frasco"},
    {prodId:"50462801",label:"Acelga luz brillante enlatada o envasada"},
    {prodId:"50462802",label:"Acelga gigante fordhook enlatada o envasada"},
    {prodId:"50462803",label:"Acelga luculus enlatada o envasada"},
    {prodId:"50462804",label:"Acelga espinaca perpetua enlatada o envasada"},
    {prodId:"50462805",label:"Acelga ruibarbo enlatada o envasada"},
    {prodId:"50462806",label:"Acelga suizo enlatada o envasada"},
    {prodId:"50462807",label:"Acelga volcán  enlatada o envasada"},
    {prodId:"50462808",label:"Acelga rey blanco enlatada o envasada"},
    {prodId:"50463100",label:"Cebollines en lata o en frasco"},
    {prodId:"50463101",label:"Cebollín chino enlatado o envasado"},
    {prodId:"50463200",label:"Maíces en lata o en frasco"},
    {prodId:"50463201",label:"Maíz aloha enlatado o envasado"},
    {prodId:"50463202",label:"Maíz alpine enlatado o envasado"},
    {prodId:"50463203",label:"Maíz ambrosia enlatado o envasado"},
    {prodId:"50463204",label:"Maíz argent enlatado o envasado"},
    {prodId:"50463205",label:"Maíz aspen enlatado o envasado"},
    {prodId:"50463206",label:"Maíz avalancha enlatado o envasado"},
    {prodId:"50463207",label:"Maíz biqueen enlatado o envasado"},
    {prodId:"50463208",label:"Maíz  bodacious enlatado o envasado"},
    {prodId:"50463209",label:"Maíz  mantequilla y azúcar enlatado o envasado"},
    {prodId:"50463210",label:"Maíz  calico belle enlatado o envasado"},
    {prodId:"50463211",label:"Maíz  camelot enlatado o envasado"},
    {prodId:"50463212",label:"Maíz  challengercrisp ‘n dulce enlatado o envasado"},
    {prodId:"50463213",label:"Maíz  campeón enlatado o envasado"},
    {prodId:"50463214",label:"Maíz  algodón de azúcar enlatado o envasado"},
    {prodId:"50463215",label:"Maíz  dártagnan enlatado o envasado"},
    {prodId:"50463216",label:"Maíz  dazzle enlatado o envasado"},
    {prodId:"50463217",label:"Maíz  diamante y oro enlatado o envasado"},
    {prodId:"50463218",label:"Maíz  divinidad enlatado o envasado"},
    {prodId:"50463219",label:"Maíz  delicia doble enlatado o envasado"},
    {prodId:"50463220",label:"Maíz  gema doble enlatado o envasado"},
    {prodId:"50463221",label:"Maíz  earlivee enlatado o envasado"},
    {prodId:"50463222",label:"Maíz  temprano extra dulce enlatado o envasado"},
    {prodId:"50463223",label:"Maíz excel enlatado o envasado"},
    {prodId:"50463224",label:"Maíz  cruz dorada bantam enlatado o envasado"},
    {prodId:"50463225",label:"Maíz  miel y crema enlatado o envasado"},
    {prodId:"50463226",label:"Maíz  miel y perla enlatado o envasado"},
    {prodId:"50463227",label:"Maíz  miel dulce enlatado o envasado"},
    {prodId:"50463228",label:"Maíz  hudson enlatado o envasado"},
    {prodId:"50463229",label:"Maíz  dorado illini enlatado o envasado"},
    {prodId:"50463230",label:"Maíz  illini extra dulce enlatado o envasado"},
    {prodId:"50463231",label:"Maíz  increíble enlatado o envasado"},
    {prodId:"50463232",label:"Maíz  lochief enlatado o envasado"},
    {prodId:"50463233",label:"Maíz jubileo enlatado o envasado"},
    {prodId:"50463234",label:"Maíz jupbileo super dulce enlatado o envasado"},
    {prodId:"50463235",label:"Maíz  confite korn enlatado o envasado"},
    {prodId:"50463236",label:"Maíz  beso y acuso enlatado o envasado"},
    {prodId:"50463237",label:"Maíz  lancelot enlatado o envasado"},
    {prodId:"50463238",label:"Maíz dulce maple enlatado o envasado"},
    {prodId:"50463239",label:"Maíz  medley enlatado o envasado"},
    {prodId:"50463240",label:"Maíz merlin enlatado o envasado"},
    {prodId:"50463241",label:"Maíz milagro enlatado o envasado"},
    {prodId:"50463242",label:"Maíz nk - 199 enlatado o envasado"},
    {prodId:"50463243",label:"Maíz durazno y crema enlatado o envasado"},
    {prodId:"50463244",label:"Maíz blanco perla enlatado o envasado"},
    {prodId:"50463245",label:"Maíz  pegasus enlatado o envasado"},
    {prodId:"50463246",label:"Maíz  fenomenal enlatado o envasado"},
    {prodId:"50463247",label:"Maíz  dama de platino enlatado o envasado"},
    {prodId:"50463248",label:"Maíz  precioso enlatado o envasado"},
    {prodId:"50463249",label:"Maíz   pristine enlatado o envasado"},
    {prodId:"50463250",label:"Maíz  rápido enlatado o envasado"},
    {prodId:"50463251",label:"Maíz  radiante enlatado o envasado"},
    {prodId:"50463252",label:"Maíz  seneca brave enlatado o envasado"},
    {prodId:"50463253",label:"Maíz  amanecer seneca enlatado o envasado"},
    {prodId:"50463254",label:"Maíz  horizonte seneca enlatado o envasado"},
    {prodId:"50463255",label:"Maíz  brillo de estrella seneca enlatado o envasado"},
    {prodId:"50463256",label:"Maíz  noche blanca seneca enlatado o envasado"},
    {prodId:"50463257",label:"Maíz  showcase enlatado o envasado"},
    {prodId:"50463258",label:"Maíz  reina plateada enlatado o envasado"},
    {prodId:"50463259",label:"Maíz  bella nieve enlatado o envasado"},
    {prodId:"50463260",label:"Maíz nieve de primavera enlatado o envasado"},
    {prodId:"50463261",label:"Maíz  premio de primavera enlatado o envasado"},
    {prodId:"50463262",label:"Maíz  azúcar y oro enlatado o envasado"},
    {prodId:"50463263",label:"Maíz rollo de azúcar enlatado o envasado"},
    {prodId:"50463264",label:"Maíz nieve de azúcar enlatado o envasado"},
    {prodId:"50463265",label:"Maíz baile de sol enlatado o envasado"},
    {prodId:"50463266",label:"Maíz estrella tell enlatado o envasado"},
    {prodId:"50463267",label:"Maíz terminador enlatado o envasado"},
    {prodId:"50463268",label:"Maíz tesoro enlatado o envasado"},
    {prodId:"50463269",label:"Maíz tuxedo enlatado o envasado"},
    {prodId:"50463800",label:"Ajos en lata o en frasco"},
    {prodId:"50463801",label:"Ajo tarde california enlatado o envasado"},
    {prodId:"50463802",label:"Ajo tallos chinos enlatado o envasado"},
    {prodId:"50463803",label:"Ajo cebollin  enlatado o envasado"},
    {prodId:"50463804",label:"Ajo germidor enlatado o envasado"},
    {prodId:"50463805",label:"Ajo guardado largo enlatado o envasado"},
    {prodId:"50463806",label:"Ajo ramson enlatado o envasado"},
    {prodId:"50463807",label:"Ajo rocambola enlatado o envasado"},
    {prodId:"50463808",label:"Ajo rosa de lautrec enlatado o envasado"},
    {prodId:"50463809",label:"Ajo solent blanco enlatado o envasado"},
    {prodId:"50463810",label:"Ajo morado español enlatado o envasado"},
    {prodId:"50463811",label:"Ajo valentina italiana enlatado o envasado"},
    {prodId:"50464000",label:"Guisantes en lata o en frasco"},
    {prodId:"50464001",label:"Guisante chino enlatada o envasada"},
    {prodId:"50464002",label:"Guisante inglés enlatada o envasada"},
    {prodId:"50464003",label:"Guisante de jardín enlatada o envasada"},
    {prodId:"50464004",label:"Guisante nieve enlatada o envasada"},
    {prodId:"50464005",label:"Guisante snap dulce enlatada o envasada"},
    {prodId:"50464100",label:"Hierbas en lata o en frasco"},
    {prodId:"50464101",label:"Albahaca enlatada o envasada"},
    {prodId:"50464102",label:"Laurel enlatado o envasado"},
    {prodId:"50464103",label:"Borraja enlatada o envasada"},
    {prodId:"50464104",label:"Alcaravea enlatada o envasada"},
    {prodId:"50464105",label:"Perifollo enlatado o envasado"},
    {prodId:"50464106",label:"Cilantro enlatado o envasado"},
    {prodId:"50464107",label:"Chipolinos enlatado o envasado"},
    {prodId:"50464108",label:"Hoja de curry enlatada o envasada"},
    {prodId:"50464109",label:"Eneldo enlatado o envasado"},
    {prodId:"50464110",label:"Epazote enlatado o envasado"},
    {prodId:"50464111",label:"Fenogreco enlatado o envasado"},
    {prodId:"50464112",label:"Limonaria enlatada o envasada"},
    {prodId:"50464113",label:"Mejorana enlatada o envasada"},
    {prodId:"50464114",label:"Menta enlatada o envasada"},
    {prodId:"50464115",label:"Orégano enlatado o envasado"},
    {prodId:"50464116",label:"Pápalo enlatado o envasado"},
    {prodId:"50464117",label:"Pápalo delgado enlatado o envasado"},
    {prodId:"50464118",label:"Perilla enlatada o envasada"},
    {prodId:"50464119",label:"Recao  enlatado o envasado"},
    {prodId:"50464120",label:"Romero enlatado o envasado"},
    {prodId:"50464121",label:"Salvia enlatada o envasada"},
    {prodId:"50464122",label:"Salsifí enlatada o envasada"},
    {prodId:"50464123",label:"Satureja enlatada o envasada"},
    {prodId:"50464124",label:"Estragón enlatado o envasado"},
    {prodId:"50464125",label:"Tomillo enlatado o envasado"},
    {prodId:"50464126",label:"Tumeric enlatado o envasado"},
    {prodId:"50464127",label:"Verdolaga enlatada o envasada"},
    {prodId:"50464800",label:"Hongos o champiñones en lata o en frasco"},
    {prodId:"50464801",label:"Champiñón trompeta negra enlatado o envasado"},
    {prodId:"50464802",label:"Champiñón carmelito enlatado o envasado"},
    {prodId:"50464803",label:"Champiñón  enlatado o envasado"},
    {prodId:"50464804",label:"Champiñón chanterelle enlatado o envasado"},
    {prodId:"50464805",label:"Champiñón cremini enlatado o envasado"},
    {prodId:"50464806",label:"Champiñón enoki enlatado o envasado"},
    {prodId:"50464807",label:"Champiñón puercoespín enlatado o envasado"},
    {prodId:"50464808",label:"Champiñón gallina del bosque enlatado o envasado"},
    {prodId:"50464809",label:"Champiñón langosta enlatado o envasado"},
    {prodId:"50464810",label:"Champiñón morels enlatado o envasado"},
    {prodId:"50464811",label:"Champiñón ostra enlatado o envasado"},
    {prodId:"50464812",label:"Champiñón pleurotus enlatado o envasado"},
    {prodId:"50464813",label:"Champiñón pompom enlatado o envasado"},
    {prodId:"50464814",label:"Champiñón porcieni enlatado o envasado"},
    {prodId:"50464815",label:"Champiñón portobello enlatado o envasado"},
    {prodId:"50464816",label:"Champiñón shitake enlatado o envasado"},
    {prodId:"50464817",label:"Champiñón shimeji enlatado o envasado"},
    {prodId:"50464818",label:"Champiñón san jorge enlatado o envasado"},
    {prodId:"50464819",label:"Champiñón blanco enlatado o envasado"},
    {prodId:"50464820",label:"Champiñón tompeta blanca enlatado o envasado"},
    {prodId:"50464821",label:"Champiñón woodear enlatado o envasado"},
    {prodId:"50464900",label:"Mostazas en lata o en frasco"},
    {prodId:"50464901",label:"Mostaza bambú  enlatada o envasada"},
    {prodId:"50464902",label:"Mostaza ajo enlatada o envasada"},
    {prodId:"50464903",label:"Mostaza hoja gigante enlatada o envasada"},
    {prodId:"50464904",label:"Mostaza rojo en nieve enlatada o envasada"},
    {prodId:"50464905",label:"Mostaza sureño enlatada o envasada"},
    {prodId:"50464906",label:"Mostaza corazón envuelto enlatada o envasada"},
    {prodId:"50465000",label:"Solanáceas (nightshades) en lata o en frasco"},
    {prodId:"50465001",label:"Lámpara china enlatada o envasada"},
    {prodId:"50465002",label:"Mora de jardín"},
    {prodId:"50465003",label:"Naranjilla orgánica enlatadas o envasadas"},
    {prodId:"50465004",label:"Tomatillo enlatada o envasada"},
    {prodId:"50465200",label:"Cebollas en lata o en frasco"},
    {prodId:"50465201",label:"Cebolla albion enlatada o envasada"},
    {prodId:"50465202",label:"Cebolla alisia craig enlatada o envasada"},
    {prodId:"50465203",label:"Cebolla hirviendo enlatada o envasada"},
    {prodId:"50465204",label:"Cebolla bufalo enlatada o envasada"},
    {prodId:"50465205",label:"Cebolla bulbo enlatada o envasada"},
    {prodId:"50465206",label:"Cebolla cremosa enlatada o envasada"},
    {prodId:"50465207",label:"Cebolla amarillo expreso o - x enlatada o envasada"},
    {prodId:"50465208",label:"Cebolla kelsae enlatada o envasada"},
    {prodId:"50465209",label:"Cebolla gigante marshal globo fen enlatada o envasada"},
    {prodId:"50465210",label:"Cebolla perla enlatada o envasada"},
    {prodId:"50465211",label:"Cebolla baron rojo enlatada o envasada"},
    {prodId:"50465212",label:"Cebolla roja enlatada o envasada"},
    {prodId:"50465213",label:"Cebolla rijnsberger enlatada o envasada"},
    {prodId:"50465214",label:"Cebolla senshyu semi - globo amarillo enlatada o envasada"},
    {prodId:"50465215",label:"Cebolla sturon enlatada o envasada"},
    {prodId:"50465216",label:"Cebolla sturgatter enlatada o envasada"},
    {prodId:"50465217",label:"Cebolla dulce enlatada o envasada"},
    {prodId:"50465218",label:"Cebolla torpedo o rojo italiana enlatada o envasada"},
    {prodId:"50465219",label:"Cebolla roja guardada enlatada o envasada"},
    {prodId:"50465220",label:"Cebolla blanca guardada enlatada o envasada"},
    {prodId:"50465221",label:"Cebolla amarilla guardada enlatada o envasada"},
    {prodId:"50465300",label:"Maníes en lata o en frasco"},
    {prodId:"50465301",label:"Maní bambara enlatado o envasado"},
    {prodId:"50465302",label:"Maní florunner enlatado o envasado"},
    {prodId:"50465303",label:"Maní husa / kerstings enlatado o envasado"},
    {prodId:"50465304",label:"Maní español enlatado o envasado"},
    {prodId:"50465305",label:"Maní valencia enlatado o envasado"},
    {prodId:"50465306",label:"Maní virginia enlatado o envasado"},
    {prodId:"50465500",label:"Pimientos en lata o en frasco"},
    {prodId:"50465501",label:"Pimentón ají enlatado o envasado"},
    {prodId:"50465502",label:"Pimentón  árbol enlatado o envasado"},
    {prodId:"50465503",label:"Pimentón queso enlatado o envasado"},
    {prodId:"50465504",label:"Pimentón chilaca enlatado o envasado"},
    {prodId:"50465505",label:"Pimentón cubanelles enlatado o envasado"},
    {prodId:"50465506",label:"Pimentón fresno enlatado o envasado"},
    {prodId:"50465507",label:"Pimentón kapia enlatado o envasado"},
    {prodId:"50465508",label:"Pimentón coreano enlatado o envasado"},
    {prodId:"50465509",label:"Pimentón manzano enlatado o envasado"},
    {prodId:"50465510",label:"Pimentón melrose enlatado o envasado"},
    {prodId:"50465511",label:"Pimentón chile amarillo enlatado o envasado"},
    {prodId:"50465512",label:"Pimentón aji dulce enlatado o envasado"},
    {prodId:"50465513",label:"Pimentón anaheim enlatado o envasado"},
    {prodId:"50465514",label:"Pimentón ancho enlatado o envasado"},
    {prodId:"50465515",label:"Pimentón campana enlatado o envasado"},
    {prodId:"50465516",label:"Pimentón cascabel enlatado o envasado"},
    {prodId:"50465517",label:"Pimentón cayenne enlatado o envasado"},
    {prodId:"50465518",label:"Pimentón anfitrión cereza enlatado o envasado"},
    {prodId:"50465519",label:"Pimentón chitespin enlatado o envasado"},
    {prodId:"50465520",label:"Pimentón dedo picante enlatado o envasado"},
    {prodId:"50465521",label:"Pimentón guajillo enlatado o envasado"},
    {prodId:"50465522",label:"Pimentón guerro enlatado o envasado"},
    {prodId:"50465523",label:"Pimentón habanero enlatado o envasado"},
    {prodId:"50465524",label:"Pimentón cera de hungria enlatado o envasado"},
    {prodId:"50465525",label:"Pimentón jalapeño enlatado o envasado"},
    {prodId:"50465526",label:"Pimentón picante largo enlatado o envasado"},
    {prodId:"50465527",label:"Pimentón marasol enlatado o envasado"},
    {prodId:"50465528",label:"Pimentón pasilla enlatado o envasado"},
    {prodId:"50465529",label:"Pimentón peperoncini enlatado o envasado"},
    {prodId:"50465530",label:"Pimentón pequin enlatado o envasado"},
    {prodId:"50465531",label:"Pimentón pimiento enlatado o envasado"},
    {prodId:"50465532",label:"Pimentón poblano enlatado o envasado"},
    {prodId:"50465533",label:"Pimentón scotch bonnet enlatado o envasado"},
    {prodId:"50465534",label:"Pimentón serrano enlatado o envasado"},
    {prodId:"50465535",label:"Pimentón tabasco enlatado o envasado"},
    {prodId:"50465536",label:"Pimentón tai  enlatado o envasado"},
    {prodId:"50465537",label:"Pimentón tepin enlatado o envasado"},
    {prodId:"50465600",label:"Papas en lata o en frasco"},
    {prodId:"50465601",label:"Papa blanca larga enlatada o envasada"},
    {prodId:"50465602",label:"Papa redonda blanca enlatada o envasada"},
    {prodId:"50465603",label:"Papa redonda roja enlatada o envasada"},
    {prodId:"50465604",label:"Papa  russet enlatada o envasada"},
    {prodId:"50465605",label:"Papa morada enlatada o envasada"},
    {prodId:"50465606",label:"Papa amarilla enlatada o envasada"},
    {prodId:"50465607",label:"Papa nueva enlatada o envasada"},
    {prodId:"50465608",label:"Papa especial enlatada o envasada"},
    {prodId:"50465800",label:"Vegetales marinos en lata o en frasco"},
    {prodId:"50465801",label:"Agar-agar enlatado o envasado"},
    {prodId:"50465802",label:"Arame  enlatada o envasada"},
    {prodId:"50465803",label:"Alga dulce enlatada o envasada"},
    {prodId:"50465804",label:"Habichuelas vert de mer enlatada o envasada"},
    {prodId:"50465805",label:"Hijiki enlatada o envasada"},
    {prodId:"50465806",label:"Musgo irlandés enlatada o envasada"},
    {prodId:"50465807",label:"Quelpo enlatado o envasado"},
    {prodId:"50465808",label:"Laver enlatado o envasado"},
    {prodId:"50465809",label:"Nori enlatado o envasado"},
    {prodId:"50465810",label:"Alga roja enlatada o envasada"},
    {prodId:"50465811",label:"Kale de mar enlatada o envasada"},
    {prodId:"50465812",label:"Lechuga de mar enlatada o envasada"},
    {prodId:"50465813",label:"Algas de mar enlatada o envasada"},
    {prodId:"50465814",label:"Spirulina enlatada o envasada"},
    {prodId:"50465815",label:"Susabi nuri enlatada o envasada"},
    {prodId:"50465816",label:"Wakame enlatada o envasada"},
    {prodId:"50466400",label:"Tomates en lata o en frasco"},
    {prodId:"50466401",label:"Tomates ailsa craig enlatado o envasado"},
    {prodId:"50466402",label:"Tomates alicante enlatado o envasado"},
    {prodId:"50466403",label:"Tomates ciruela negra enlatado o envasado"},
    {prodId:"50466404",label:"Tomates vino de brandy enlatado o envasado"},
    {prodId:"50466405",label:"Tomates bella cereza enlatado o envasado"},
    {prodId:"50466406",label:"Tomates cereza enlatado o envasado"},
    {prodId:"50466407",label:"Tomates delicioso enlatado o envasado"},
    {prodId:"50466408",label:"Tomates dombito enlatado o envasado"},
    {prodId:"50466409",label:"Tomates delicia del jardín enlatado o envasado"},
    {prodId:"50466410",label:"Tomates uva enlatado o envasado"},
    {prodId:"50466411",label:"Tomates verde enlatado o envasado"},
    {prodId:"50466412",label:"Tomates super marmande enlatado o envasado"},
    {prodId:"50466413",label:"Tomates rayas naturales marvel enlatado o envasado"},
    {prodId:"50466414",label:"Tomates minibel enlatado o envasado"},
    {prodId:"50466415",label:"Tomates oaxacan enlatado o envasado"},
    {prodId:"50466416",label:"Tomates alerta roja enlatado o envasado"},
    {prodId:"50466417",label:"Tomates roma vf enlatado o envasado"},
    {prodId:"50466418",label:"Tomates marzano zan enlatado o envasado"},
    {prodId:"50466419",label:"Tomates shirley enlatado o envasado"},
    {prodId:"50466420",label:"Tomates siberia enlatado o envasado"},
    {prodId:"50466421",label:"Tomates super beefsteak enlatado o envasado"},
    {prodId:"50466422",label:"Tomates tigerella enlatado o envasado"},
    {prodId:"50466423",label:"Tomates tiny tim enlatado o envasado"},
    {prodId:"50466424",label:"Tomates tumbler enlatado o envasado"},
    {prodId:"50466425",label:"Tomates cocktail amarillo enlatado o envasado"},
    {prodId:"50466426",label:"Tomates forma de pera amarillo enlatado o envasado"},
    {prodId:"50466427",label:"Tomates perfección amarilla enlatado o envasado"},
    {prodId:"50466800",label:"Vegetales Nominant en lata o en frasco"},
    {prodId:"50466801",label:"Alfalfa enlatada o envasada"},
    {prodId:"50466802",label:"Hojas de aloe enlatada o envasada"},
    {prodId:"50466803",label:"Apio enlatado o envasado"},
    {prodId:"50466804",label:"Arrurruz enlatada o envasada"},
    {prodId:"50466805",label:"Punta de flecha enlatada o envasada"},
    {prodId:"50466806",label:"rúcula enlatada o envasada"},
    {prodId:"50466807",label:"Arum enlatada o envasada"},
    {prodId:"50466808",label:"Raíz de bambú enlatada o envasada"},
    {prodId:"50466809",label:"Hojas de plátano enlatada o envasada"},
    {prodId:"50466810",label:"Batatas enlatada o envasada"},
    {prodId:"50466811",label:"Brotes de frijol enlatados o envasados"},
    {prodId:"50466812",label:"Top de remolacha enlatada o envasada"},
    {prodId:"50466813",label:"Melón agrio enlatado o envasado"},
    {prodId:"50466814",label:"Bayas de alcaparra orgánica enlatadas o envasadas"},
    {prodId:"50466815",label:"Carob enlatado o envasado"},
    {prodId:"50466816",label:"Cha-om enlatado o envasado"},
    {prodId:"50466817",label:"Chayote o guatila enlatado o envasado"},
    {prodId:"50466818",label:"Garbanzo enlatado o envasado"},
    {prodId:"50466819",label:"Verde de crisantemos enlatado o envasado"},
    {prodId:"50466820",label:"Verde de diente de león enlatado o envasado"},
    {prodId:"50466821",label:"Diente de león enlatado o envasado"},
    {prodId:"50466822",label:"Dasheen enlatado o envasado"},
    {prodId:"50466823",label:"Puntas de alverja enlatado o envasado"},
    {prodId:"50466824",label:"Diakon enlatado o envasado"},
    {prodId:"50466825",label:"Donqua enlatada o envasada"},
    {prodId:"50466826",label:"Helecho enlatado o envasado"},
    {prodId:"50466827",label:"Gai choi enlatado o envasado"},
    {prodId:"50466828",label:"Gailon enlatado o envasado"},
    {prodId:"50466829",label:"Galanga enlatada o envasada"},
    {prodId:"50466830",label:"Raíz de jengibre enlatada o envasada"},
    {prodId:"50466831",label:"Gobo enlatado o envasado"},
    {prodId:"50466832",label:"Brote de lúpulo enlatado o envasado"},
    {prodId:"50466833",label:"Rabano blanco enlatado o envasado"},
    {prodId:"50466834",label:"Jicama enlatada o envasada"},
    {prodId:"50466835",label:"Kuduz enlatada o envasada"},
    {prodId:"50466836",label:"Bulbo de margarita enlatado o envasado"},
    {prodId:"50466837",label:"Linkok enlatado o envasado"},
    {prodId:"50466838",label:"Lo bok enlatado o envasado"},
    {prodId:"50466839",label:"Frijol largo enlatado o envasado"},
    {prodId:"50466840",label:"Raíz de lotus enlatado o envasado"},
    {prodId:"50466841",label:"Hojas de maguey enlatada o envasada"},
    {prodId:"50466842",label:"Mallows enlatado o envasado"},
    {prodId:"50466843",label:"Sapote mamey enlatado o envasado"},
    {prodId:"50466844",label:"Moap enlatado o envasado"},
    {prodId:"50466845",label:"Moo enlatado o envasado"},
    {prodId:"50466846",label:"Moqua enlatada o envasada"},
    {prodId:"50466847",label:"Opos enlatados o envasados"},
    {prodId:"50466848",label:"Corazon de palma enlatado o envasado"},
    {prodId:"50466849",label:"Paprika enlatada o envasada"},
    {prodId:"50466850",label:"Purslane enlatada o envasada"},
    {prodId:"50466851",label:"Raddichios enlatada o envasada"},
    {prodId:"50466852",label:"Sinquas enlatada o envasada"},
    {prodId:"50466853",label:"Frijol de soya enlatados o envasados"},
    {prodId:"50466854",label:"Spoonwart enlatado o envasado"},
    {prodId:"50466855",label:"Uva tassele hyacinth enlatado o envasado"},
    {prodId:"50466856",label:"Taro enlatado o envasado"},
    {prodId:"50466857",label:"Hoja de taro enlatada o envasada"},
    {prodId:"50466858",label:"Tallo de taro enlatado o envasado"},
    {prodId:"50466859",label:"Tapeguaje enlatado o envasado"},
    {prodId:"50466860",label:"Verde tierno enlatado o envasado"},
    {prodId:"50466861",label:"Tindora enlatado o envasado"},
    {prodId:"50466862",label:"Árbol de cebolla enlatado o envasado"},
    {prodId:"50466863",label:"Udo enlatado o envasado"},
    {prodId:"50466864",label:"Agua castaño enlatado o envasado"},
    {prodId:"50466865",label:"Yumpi enlatado o envasado"},
    {prodId:"50466866",label:"Yautia enlatado o envasado"},
    {prodId:"50466867",label:"Yu choy enlatado o envasado"},
    {prodId:"50466868",label:"Yuca enlatada o envasada"},
    {prodId:"50467000",label:"Kimchis dulces en lata o en frasco"},
    {prodId:"50467001",label:"Baechukimchis"},
    {prodId:"50467002",label:"Rábanos jóvenes kimichi"},
    {prodId:"50467003",label:"Rábano de agua kimichi"},
    {prodId:"50467004",label:"Rábanos joven  escabeche kimichi"},
    {prodId:"50467005",label:"Rábanos cortados kimichi"},
    {prodId:"50467006",label:"Jangjorims enlatado"},
    {prodId:"50467007",label:"Atún enlatada"},
    {prodId:"50473200",label:"Maíces orgánicos en lata o en frasco"},
    {prodId:"50473201",label:"Maíz aloha orgánico enlatado o envasado"},
    {prodId:"50473202",label:"Maíz alpine orgánico enlatado o envasado"},
    {prodId:"50473203",label:"Maíz ambrosia orgánico enlatado o envasado"},
    {prodId:"50473204",label:"Maíz argent orgánico enlatado o envasado"},
    {prodId:"50473205",label:"Maíz aspen orgánico enlatado o envasado"},
    {prodId:"50473206",label:"Maíz avalancha orgánico enlatado o envasado"},
    {prodId:"50473207",label:"Maíz biqueen orgánico enlatado o envasado"},
    {prodId:"50473208",label:"Maíz  bodacious orgánico enlatado o envasado"},
    {prodId:"50473209",label:"Maíz  mantequilla y azúcar orgánico enlatado o envasado"},
    {prodId:"50473210",label:"Maíz  calico belle orgánico enlatado o envasado"},
    {prodId:"50473211",label:"Maíz  camelot orgánico enlatado o envasado"},
    {prodId:"50473212",label:"Maíz  challengercrisp ‘n dulce orgánico enlatado o envasado"},
    {prodId:"50473213",label:"Maíz  campeón orgánico enlatado o envasado"},
    {prodId:"50473214",label:"Maíz  algodón de azúcar orgánico enlatado o envasado"},
    {prodId:"50473215",label:"Maíz  dártagnan orgánico enlatado o envasado"},
    {prodId:"50473216",label:"Maíz  dazzle orgánico enlatado o envasado"},
    {prodId:"50473217",label:"Maíz  diamante y oro orgánico enlatado o envasado"},
    {prodId:"50473218",label:"Maíz  divinidad orgánico enlatado o envasado"},
    {prodId:"50473219",label:"Maíz  delicia doble orgánico enlatado o envasado"},
    {prodId:"50473220",label:"Maíz  gema doble orgánico enlatado o envasado"},
    {prodId:"50473221",label:"Maíz  earlivee orgánico enlatado o envasado"},
    {prodId:"50473222",label:"Maíz  temprano extra dulce orgánico enlatado o envasado"},
    {prodId:"50473223",label:"Maíz excel orgánico enlatado o envasado"},
    {prodId:"50473224",label:"Maíz  cruz dorada bantam orgánico enlatado o envasado"},
    {prodId:"50473226",label:"Maíz  miel y perla orgánico enlatado o envasado"},
    {prodId:"50473227",label:"Maíz  miel dulce orgánico enlatado o envasado"},
    {prodId:"50473228",label:"Maíz  hudson orgánico enlatado o envasado"},
    {prodId:"50473229",label:"Maíz  dorado illini orgánico enlatado o envasado"},
    {prodId:"50473230",label:"Maíz  illini extra dulce orgánico enlatado o envasado"},
    {prodId:"50473231",label:"Maíz  increíble orgánico enlatado o envasado"},
    {prodId:"50473232",label:"Maíz  lochief orgánico enlatado o envasado"},
    {prodId:"50473233",label:"Maíz jubileo orgánico enlatado o envasado"},
    {prodId:"50473234",label:"Maíz jupbileo super dulce orgánico enlatado o envasado"},
    {prodId:"50473235",label:"Maíz  confite korn orgánico enlatado o envasado"},
    {prodId:"50473236",label:"Maíz  beso y acuso orgánico enlatado o envasado"},
    {prodId:"50473237",label:"Maíz  lancelot orgánico enlatado o envasado"},
    {prodId:"50473238",label:"Maíz dulce maple orgánico enlatado o envasado"},
    {prodId:"50473239",label:"Maíz  medley orgánico enlatado o envasado"},
    {prodId:"50473240",label:"Maíz merlin orgánico enlatado o envasado"},
    {prodId:"50473241",label:"Maíz milagro orgánico enlatado o envasado"},
    {prodId:"50473242",label:"Maíz nk - 199 orgánico enlatado o envasado"},
    {prodId:"50473243",label:"Maíz durazno y crema orgánico enlatado o envasado"},
    {prodId:"50473244",label:"Maíz blanco perla orgánico enlatado o envasado"},
    {prodId:"50473245",label:"Maíz  pegasus orgánico enlatado o envasado"},
    {prodId:"50473246",label:"Maíz  fenomenal orgánico enlatado o envasado"},
    {prodId:"50473247",label:"Maíz  dama de platino orgánico enlatado o envasado"},
    {prodId:"50473248",label:"Maíz  precioso orgánico enlatado o envasado"},
    {prodId:"50473249",label:"Maíz   pristine orgánico enlatado o envasado"},
    {prodId:"50473250",label:"Maíz  rápido orgánico enlatado o envasado"},
    {prodId:"50473251",label:"Maíz  radiante orgánico enlatado o envasado"},
    {prodId:"50473252",label:"Maíz  seneca brave orgánico enlatado o envasado"},
    {prodId:"50473253",label:"Maíz  amanecer seneca orgánico enlatado o envasado"},
    {prodId:"50473254",label:"Maíz  horizonte seneca orgánico enlatado o envasado"},
    {prodId:"50473255",label:"Maíz  brillo de estrella seneca orgánico enlatado o envasado"},
    {prodId:"50473256",label:"Maíz  noche blanca seneca orgánico enlatado o envasado"},
    {prodId:"50473257",label:"Maíz  showcase orgánico enlatado o envasado"},
    {prodId:"50473258",label:"Maíz  reina plateada orgánico enlatado o envasado"},
    {prodId:"50473259",label:"Maíz  bella nieve orgánico enlatado o envasado"},
    {prodId:"50473260",label:"Maíz nieve de primavera orgánico enlatado o envasado"},
    {prodId:"50473261",label:"Maíz  premio de primavera orgánico enlatado o envasado"},
    {prodId:"50473262",label:"Maíz  azúcar y oro orgánico enlatado o envasado"},
    {prodId:"50473263",label:"Maíz rollo de azúcar orgánico enlatado o envasado"},
    {prodId:"50473264",label:"Maíz nieve de azúcar orgánico enlatado o envasado"},
    {prodId:"50473265",label:"Maíz baile de sol orgánico enlatado o envasado"},
    {prodId:"50473266",label:"Maíz estrella tell orgánico enlatado o envasado"},
    {prodId:"50473267",label:"Maíz terminador orgánico enlatado o envasado"},
    {prodId:"50473268",label:"Maíz tesoro orgánico enlatado o envasado"},
    {prodId:"50473269",label:"Maíz tuxedo orgánico enlatado o envasado"},
    {prodId:"50475525",label:"Pimentón jalapeño orgánico enlatado o envasado"},
    {prodId:"50475600",label:"Papas orgánicas en lata o en frasco"},
    {prodId:"50475601",label:"Papa blanca larga orgánica enlatada o envasada"},
    {prodId:"50475602",label:"Papa redonda blanca orgánica enlatada o envasada"},
    {prodId:"50475603",label:"Papa redonda roja orgánica enlatada o envasada"},
    {prodId:"50475604",label:"Papa  russet orgánica enlatada o envasada"},
    {prodId:"50475605",label:"Papa morada orgánica enlatada o envasada"},
    {prodId:"50475606",label:"Papa amarilla orgánica enlatada o envasada"},
    {prodId:"50475607",label:"Papa nueva orgánica enlatada o envasada"},
    {prodId:"50475608",label:"Papa especial orgánica enlatada o envasada"},
    {prodId:"50475800",label:"Vegetales marinos orgánicos en lata o en frasco"},
    {prodId:"50475801",label:"Agar-agar orgánico enlatado o envasado"},
    {prodId:"50475802",label:"Arame  orgánica enlatada o envasada"},
    {prodId:"50475803",label:"Alga dulce orgánica enlatada o envasada"},
    {prodId:"50475804",label:"Habichuelas vert de mer orgánica enlatada o envasada"},
    {prodId:"50475805",label:"Hijiki orgánica enlatada o envasada"},
    {prodId:"50475806",label:"Musgo irlandés orgánica enlatada o envasada"},
    {prodId:"50475807",label:"Quelpo orgánico enlatado o envasado"},
    {prodId:"50475808",label:"Laver orgánico enlatado o envasado"},
    {prodId:"50475809",label:"Nori orgánico enlatado o envasado"},
    {prodId:"50475810",label:"Alga roja orgánica enlatada o envasada"},
    {prodId:"50475811",label:"Kale de mar orgánica enlatada o envasada"},
    {prodId:"50475812",label:"Lechuga de mar orgánica enlatada o envasada"},
    {prodId:"50475813",label:"Algas de mar orgánica enlatada o envasada"},
    {prodId:"50475814",label:"Spirulina orgánica enlatada o envasada"},
    {prodId:"50475815",label:"Susabi nuri orgánica enlatada o envasada"},
    {prodId:"50475816",label:"Wakame orgánica enlatada o envasada"},
    {prodId:"50476800",label:"Vegetales Nominant orgánicos en lata o en frasco"},
    {prodId:"50476801",label:"Alfalfa orgánica enlatada o envasada"},
    {prodId:"50476802",label:"Hojas de aloe orgánica enlatada o envasada"},
    {prodId:"50476803",label:"Apio orgánico enlatado o envasado"},
    {prodId:"50476804",label:"Arrurruz orgánica enlatada o envasada"},
    {prodId:"50476805",label:"Punta de flecha orgánica enlatada o envasada"},
    {prodId:"50476806",label:"rúcula orgánica enlatada o envasada"},
    {prodId:"50476807",label:"Arum orgánica enlatada o envasada"},
    {prodId:"50476808",label:"Raíz de bambú orgánica enlatada o envasada"},
    {prodId:"50476809",label:"Hojas de plátano orgánica enlatada o envasada"},
    {prodId:"50476810",label:"Batatas orgánica enlatada o envasada"},
    {prodId:"50476811",label:"Brotes de frijol orgánicos enlatados o envasados"},
    {prodId:"50476812",label:"Top de remolacha orgánica enlatada o envasada"},
    {prodId:"50476813",label:"Melón agrio orgánico enlatado o envasado"},
    {prodId:"50476814",label:"Bayas de alcaparra orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50476815",label:"Carob orgánico enlatado o envasado"},
    {prodId:"50476816",label:"Cha-om orgánico enlatado o envasado"},
    {prodId:"50476817",label:"Chayote o guatila orgánico enlatado o envasado"},
    {prodId:"50476818",label:"Garbanzo orgánico enlatado o envasado"},
    {prodId:"50476819",label:"Verde de crisantemos orgánico enlatado o envasado"},
    {prodId:"50476820",label:"Verde de diente de león orgánico enlatado o envasado"},
    {prodId:"50476821",label:"Diente de león orgánico enlatado o envasado"},
    {prodId:"50476822",label:"Dasheen orgánico enlatado o envasado"},
    {prodId:"50476823",label:"Puntas de alverja orgánico enlatado o envasado"},
    {prodId:"50476824",label:"Diakon orgánico enlatado o envasado"},
    {prodId:"50476825",label:"Donqua orgánica enlatada o envasada"},
    {prodId:"50476826",label:"Helecho orgánico enlatado o envasado"},
    {prodId:"50476827",label:"Gai choi orgánico enlatado o envasado"},
    {prodId:"50476828",label:"Gailon orgánico enlatado o envasado"},
    {prodId:"50476829",label:"Galanga orgánica enlatada o envasada"},
    {prodId:"50476830",label:"Raíz de jengibre orgánica enlatada o envasada"},
    {prodId:"50476831",label:"Gobo orgánico enlatado o envasado"},
    {prodId:"50476832",label:"Brote de lúpulo orgánico enlatado o envasado"},
    {prodId:"50476833",label:"Rabano blanco orgánico enlatado o envasado"},
    {prodId:"50476834",label:"Jicama orgánica enlatada o envasada"},
    {prodId:"50476835",label:"Kuduz orgánica enlatada o envasada"},
    {prodId:"50476836",label:"Bulbo de margarita orgánico enlatado o envasado"},
    {prodId:"50476837",label:"Linkok orgánico enlatado o envasado"},
    {prodId:"50476838",label:"Lo bok orgánico enlatado o envasado"},
    {prodId:"50476839",label:"Frijol largo orgánico enlatado o envasado"},
    {prodId:"50476840",label:"Raíz de lotus orgánico enlatado o envasado"},
    {prodId:"50476841",label:"Hojas de maguey orgánica enlatada o envasada"},
    {prodId:"50476842",label:"Mallows orgánico enlatado o envasado"},
    {prodId:"50476843",label:"Sapote mamey orgánico enlatado o envasado"},
    {prodId:"50476844",label:"Moap orgánico enlatado o envasado"},
    {prodId:"50476845",label:"Moo orgánico enlatado o envasado"},
    {prodId:"50476846",label:"Moqua orgánica enlatada o envasada"},
    {prodId:"50476847",label:"Opos orgánicos enlatados o envasados"},
    {prodId:"50476848",label:"Corazon de palma orgánico enlatado o envasado"},
    {prodId:"50476849",label:"Paprika orgánica enlatada o envasada"},
    {prodId:"50476850",label:"Purslane orgánica enlatada o envasada"},
    {prodId:"50476851",label:"Raddichios orgánica enlatada o envasada"},
    {prodId:"50476852",label:"Sinquas orgánica enlatada o envasada"},
    {prodId:"50476853",label:"Frijol de soya orgánicos enlatados o envasados"},
    {prodId:"50476854",label:"Spoonwart orgánico enlatado o envasado"},
    {prodId:"50476855",label:"Uva tassele hyacinth orgánico enlatado o envasado"},
    {prodId:"50476856",label:"Taro orgánico enlatado o envasado"},
    {prodId:"50476857",label:"Hoja de taro orgánica enlatada o envasada"},
    {prodId:"50476858",label:"Tallo de taro orgánico enlatado o envasado"},
    {prodId:"50476859",label:"Tapeguaje orgánico enlatado o envasado"},
    {prodId:"50476860",label:"Verde tierno orgánico enlatado o envasado"},
    {prodId:"50476861",label:"Tindora orgánico enlatado o envasado"},
    {prodId:"50476862",label:"Árbol de cebolla orgánico enlatado o envasado"},
    {prodId:"50476863",label:"Udo orgánico enlatado o envasado"},
    {prodId:"50476864",label:"Agua castaño orgánico enlatado o envasado"},
    {prodId:"50476865",label:"Yumpi orgánico enlatado o envasado"},
    {prodId:"50476866",label:"Yautia orgánico enlatado o envasado"},
    {prodId:"50476867",label:"Yu choy orgánico enlatado o envasado"},
    {prodId:"50476868",label:"Yuca orgánica enlatada o envasada"},
    {prodId:"51101500",label:"Antibióticos"},
    {prodId:"51101503",label:"Cloranfenicol"},
    {prodId:"51101504",label:"Clindamicina"},
    {prodId:"51101507",label:"Penicilina"},
    {prodId:"51101508",label:"Sulfonamidas antibióticas"},
    {prodId:"51101509",label:"Tetraciclina"},
    {prodId:"51101510",label:"Oxitetraciclina"},
    {prodId:"51101511",label:"Amoxicilina"},
    {prodId:"51101512",label:"Cloxacilina"},
    {prodId:"51101513",label:"Neomicina"},
    {prodId:"51101514",label:"Sulfato framicetina"},
    {prodId:"51101515",label:"Clorhidrato de lincomicina"},
    {prodId:"51101516",label:"Gramicidina"},
    {prodId:"51101518",label:"Ofloxacina"},
    {prodId:"51101519",label:"Tiamfenicol"},
    {prodId:"51101521",label:"Pristinamicina"},
    {prodId:"51101522",label:"Claritromicina"},
    {prodId:"51101523",label:"Clorquinaldol"},
    {prodId:"51101524",label:"Bacitracina zinc"},
    {prodId:"51101525",label:"Peróxido de benzoilo"},
    {prodId:"51101526",label:"Polimixinas"},
    {prodId:"51101527",label:"Colistina metansulfonato"},
    {prodId:"51101528",label:"Tirotricina"},
    {prodId:"51101530",label:"Trimetoprima"},
    {prodId:"51101531",label:"Estreptograminas"},
    {prodId:"51101532",label:"Teicoplanina"},
    {prodId:"51101533",label:"Rifamicina"},
    {prodId:"51101534",label:"Ceftibuten"},
    {prodId:"51101535",label:"Cefradina"},
    {prodId:"51101536",label:"Moxifloxacina clorhidrato"},
    {prodId:"51101537",label:"Lomefloxacina cloridrato"},
    {prodId:"51101538",label:"Levofloxacina"},
    {prodId:"51101539",label:"Grepafloxacina clorhidrato"},
    {prodId:"51101540",label:"Gatifloxacina"},
    {prodId:"51101541",label:"Enoxacina"},
    {prodId:"51101542",label:"Ciprofloxacina"},
    {prodId:"51101543",label:"Cefapirina"},
    {prodId:"51101544",label:"Loracarbef"},
    {prodId:"51101545",label:"Ceftizoxima"},
    {prodId:"51101546",label:"Norfloxacina"},
    {prodId:"51101547",label:"Cefprozil"},
    {prodId:"51101548",label:"Fosfomicina trometamol"},
    {prodId:"51101549",label:"Linezolida"},
    {prodId:"51101550",label:"Cefalexina"},
    {prodId:"51101551",label:"Ceftriaxona"},
    {prodId:"51101552",label:"Ceftazidima"},
    {prodId:"51101553",label:"Alatrofloxacina"},
    {prodId:"51101554",label:"Dicloxacilina sódica"},
    {prodId:"51101555",label:"Aztreonam"},
    {prodId:"51101556",label:"Minociclina"},
    {prodId:"51101557",label:"Doxiciclina"},
    {prodId:"51101558",label:"Demeclociclina"},
    {prodId:"51101559",label:"Clortetraciclina"},
    {prodId:"51101560",label:"Ticarcilina"},
    {prodId:"51101561",label:"Piperacilina"},
    {prodId:"51101562",label:"Oxacilina sódica"},
    {prodId:"51101563",label:"Mesilato de trovafloxacina"},
    {prodId:"51101564",label:"Mezlocilina"},
    {prodId:"51101565",label:"Cefpodoxima proxetil"},
    {prodId:"51101566",label:"Carbenicilina"},
    {prodId:"51101567",label:"Ampicilina"},
    {prodId:"51101568",label:"Troleandomicina"},
    {prodId:"51101569",label:"Roxitromicina"},
    {prodId:"51101570",label:"Eritromicina"},
    {prodId:"51101571",label:"Diritromicina"},
    {prodId:"51101572",label:"Azitromicina"},
    {prodId:"51101573",label:"Cefuroxima"},
    {prodId:"51101574",label:"Furazolidona"},
    {prodId:"51101575",label:"Nafcilina sódica"},
    {prodId:"51101576",label:"Cefalotina"},
    {prodId:"51101577",label:"Cefdinir"},
    {prodId:"51101578",label:"Cefazolina"},
    {prodId:"51101579",label:"Cefamandol"},
    {prodId:"51101580",label:"Cefadroxilo"},
    {prodId:"51101581",label:"Cefaclor"},
    {prodId:"51101582",label:"Tobramicina"},
    {prodId:"51101583",label:"Cefditoren"},
    {prodId:"51101584",label:"Gentamicina"},
    {prodId:"51101585",label:"Netilmicina"},
    {prodId:"51101586",label:"Amikacina"},
    {prodId:"51101587",label:"Kanamicina"},
    {prodId:"51101588",label:"Sparfloxacina"},
    {prodId:"51101589",label:"Carbapenémicos incluyendo tienamicinas"},
    {prodId:"51101590",label:"Quinupristina"},
    {prodId:"51101591",label:"Vancomicina"},
    {prodId:"51101592",label:"Cefoxitina"},
    {prodId:"51101593",label:"Cefotaxima"},
    {prodId:"51101594",label:"Cefepima"},
    {prodId:"51101595",label:"Cefotetan"},
    {prodId:"51101596",label:"Cefoperazona"},
    {prodId:"51101597",label:"Mupirocina"},
    {prodId:"51101598",label:"Cefonicida"},
    {prodId:"51101599",label:"Cefixima"},
    {prodId:"51101600",label:"Amebicidas, Tricomonacidas y Antiprotozoarios"},
    {prodId:"51101601",label:"Atovacuona"},
    {prodId:"51101602",label:"Clorhidrato de eflomitina"},
    {prodId:"51101603",label:"Metronidazol"},
    {prodId:"51101604",label:"Antimoniato de meglumina"},
    {prodId:"51101606",label:"Rifapentina"},
    {prodId:"51101607",label:"Óxido de calcio"},
    {prodId:"51101610",label:"Clorocresol"},
    {prodId:"51101611",label:"Meropenem"},
    {prodId:"51101612",label:"Polynoxylin"},
    {prodId:"51101613",label:"Isetionato de pentamidina"},
    {prodId:"51101614",label:"Furoato de diloxanida"},
    {prodId:"51101616",label:"Melarsoprol"},
    {prodId:"51101617",label:"Tinidazol"},
    {prodId:"51101618",label:"Taurolidina"},
    {prodId:"51101619",label:"Secnidazol"},
    {prodId:"51101620",label:"Iodoquinol"},
    {prodId:"51101624",label:"Clorhidrato de metronidazol"},
    {prodId:"51101625",label:"Sulfato de paromomicina"},
    {prodId:"51101629",label:"Glucuronato de trimetrexato"},
    {prodId:"51101630",label:"Isetionato de propamidina"},
    {prodId:"51101631",label:"Nitazoxanida"},
    {prodId:"51101632",label:"Faropenem de sodio"},
    {prodId:"51101700",label:"Antihelmínticos y otros antiparasitarios"},
    {prodId:"51101701",label:"Albendazol"},
    {prodId:"51101702",label:"Mebendazol"},
    {prodId:"51101703",label:"Oxamniquina"},
    {prodId:"51101704",label:"Citrato de piperazina"},
    {prodId:"51101705",label:"Praziquantel"},
    {prodId:"51101706",label:"Pamoato de pirantel"},
    {prodId:"51101707",label:"Tiabendazol"},
    {prodId:"51101708",label:"Sulfanilamida"},
    {prodId:"51101709",label:"Niclosamida"},
    {prodId:"51101710",label:"Piperazina"},
    {prodId:"51101711",label:"Citrato de dietilcarbamazina"},
    {prodId:"51101712",label:"Tiocianoacetato de isobornilo"},
    {prodId:"51101713",label:"Antiparasitario tópico malatión"},
    {prodId:"51101714",label:"Metilparabeno"},
    {prodId:"51101715",label:"Antiparasitario tópico permetrina"},
    {prodId:"51101716",label:"Tetracloroetileno"},
    {prodId:"51101717",label:"Ivermectina"},
    {prodId:"51101718",label:"Benzoato de bencilo"},
    {prodId:"51101719",label:"Butóxido de piperonilo"},
    {prodId:"51101720",label:"Lindano"},
    {prodId:"51101721",label:"Febantel"},
    {prodId:"51101800",label:"Fungicidas"},
    {prodId:"51101801",label:"Anfotericina b"},
    {prodId:"51101802",label:"Clorhidrato de butenafina"},
    {prodId:"51101803",label:"Nitrato de butoconazol"},
    {prodId:"51101804",label:"Ciclopiroxolamina"},
    {prodId:"51101805",label:"Clotrimazol"},
    {prodId:"51101806",label:"Nitrato de econazol"},
    {prodId:"51101807",label:"Fluconazol"},
    {prodId:"51101808",label:"Flucitosina"},
    {prodId:"51101809",label:"Griseofulvina"},
    {prodId:"51101810",label:"Itraconazol"},
    {prodId:"51101811",label:"Ketoconazol"},
    {prodId:"51101812",label:"Miconazol"},
    {prodId:"51101813",label:"Clorhidrato de naftifina"},
    {prodId:"51101814",label:"Natamicina"},
    {prodId:"51101815",label:"Nistatina"},
    {prodId:"51101816",label:"Nitrato de oxiconazol"},
    {prodId:"51101817",label:"Nitrato de sulconazol"},
    {prodId:"51101818",label:"Clorhidrato de terbinafina"},
    {prodId:"51101819",label:"Nitrato de terconazol"},
    {prodId:"51101820",label:"Tioconazol"},
    {prodId:"51101821",label:"Tolnaftato"},
    {prodId:"51101824",label:"Ácido undecilénico"},
    {prodId:"51101825",label:"Ciclopirox"},
    {prodId:"51101826",label:"Sulfato de estreptomicina"},
    {prodId:"51101827",label:"Isoconazol"},
    {prodId:"51101828",label:"Terconazol"},
    {prodId:"51101829",label:"Undecilenato de calcio"},
    {prodId:"51101830",label:"Ácido octanoico"},
    {prodId:"51101831",label:"Triacetina"},
    {prodId:"51101832",label:"Voriconazol"},
    {prodId:"51101834",label:"Nitrato de miconazol"},
    {prodId:"51101835",label:"Acetato de caspofungina"},
    {prodId:"51101836",label:"Propionato de sodio"},
    {prodId:"51101837",label:"Clorhidrato de amorolfina"},
    {prodId:"51101900",label:"Medicamentos contra la malaria"},
    {prodId:"51101901",label:"Aminoquinolinas"},
    {prodId:"51101902",label:"Clorhidrato de mefloquina"},
    {prodId:"51101903",label:"Fosfato de primaquina"},
    {prodId:"51101904",label:"Sulfato de quinina"},
    {prodId:"51101905",label:"Cloroquina"},
    {prodId:"51101906",label:"Proguanil"},
    {prodId:"51101907",label:"Pirimetamina"},
    {prodId:"51101908",label:"Artemeter"},
    {prodId:"51101909",label:"Clorhidrato de cloroquina"},
    {prodId:"51101910",label:"Fosfato de cloroquina"},
    {prodId:"51101911",label:"Clorhidrato de halofantrina"},
    {prodId:"51101912",label:"Sulfato de hidroxicloroquina"},
    {prodId:"51101913",label:"Dihidrocloruro amodiaquina"},
    {prodId:"51101914",label:"Artesunato de sodio"},
    {prodId:"51101915",label:"Lumefantrina o benflumetol"},
    {prodId:"51102000",label:"Medicamentos antituberculosos"},
    {prodId:"51102001",label:"Cicloserina"},
    {prodId:"51102002",label:"Clorhidrato de etambutol"},
    {prodId:"51102003",label:"Isoniazida"},
    {prodId:"51102004",label:"Rifubutina"},
    {prodId:"51102005",label:"Rifampicina"},
    {prodId:"51102006",label:"Guayacol"},
    {prodId:"51102007",label:"Tiacetazona"},
    {prodId:"51102008",label:"Pirazinamida"},
    {prodId:"51102009",label:"Sulfato de capreomicina"},
    {prodId:"51102010",label:"Rifabutina"},
    {prodId:"51102100",label:"Leprostáticos"},
    {prodId:"51102101",label:"Clofazimina"},
    {prodId:"51102102",label:"Dapsona"},
    {prodId:"51102200",label:"Antiinfecciosos y analgésicos urinarios"},
    {prodId:"51102201",label:"Cinoxacina"},
    {prodId:"51102202",label:"Clorhidrato de flavoxato"},
    {prodId:"51102203",label:"Hipurato de metenamina"},
    {prodId:"51102204",label:"Mandelato de metenamina"},
    {prodId:"51102205",label:"Ácido nalidíxico"},
    {prodId:"51102206",label:"Nitrofurantoina"},
    {prodId:"51102207",label:"Cloruro de oxibutinina"},
    {prodId:"51102208",label:"Pentosano polisulfato sódico"},
    {prodId:"51102209",label:"Clorhidrato de fenazopiridina"},
    {prodId:"51102211",label:"Alfuzosina hidrocloruro"},
    {prodId:"51102212",label:"Ácido acetohidroxámico"},
    {prodId:"51102213",label:"Sulfato  de cefpiroma"},
    {prodId:"51102214",label:"Fenazopiridina"},
    {prodId:"51102300",label:"Medicamentos antivirales"},
    {prodId:"51102301",label:"Aciclovir"},
    {prodId:"51102302",label:"Clorhidrato de amantadina"},
    {prodId:"51102304",label:"Didanosina"},
    {prodId:"51102305",label:"Famciclovir"},
    {prodId:"51102306",label:"Foscarnet sódico"},
    {prodId:"51102307",label:"Ganciclovir sódico"},
    {prodId:"51102308",label:"Idoxuridina"},
    {prodId:"51102309",label:"Sulfato de indinavir"},
    {prodId:"51102310",label:"Lamivudina"},
    {prodId:"51102311",label:"Nevirapina"},
    {prodId:"51102312",label:"Ribavirina"},
    {prodId:"51102313",label:"Clorhidrato de rimantadina"},
    {prodId:"51102314",label:"Ritonavir"},
    {prodId:"51102315",label:"Mesilato de saquinavir"},
    {prodId:"51102316",label:"Estavidina"},
    {prodId:"51102317",label:"Trifluradina"},
    {prodId:"51102318",label:"Clorhidrato de valaciclovir"},
    {prodId:"51102319",label:"Vidarabina"},
    {prodId:"51102320",label:"Zalcitabina"},
    {prodId:"51102321",label:"Zidovudina"},
    {prodId:"51102322",label:"Sulfato de abacavir"},
    {prodId:"51102323",label:"Cidofovir"},
    {prodId:"51102324",label:"Mesilato de delavirdina"},
    {prodId:"51102325",label:"Docosanol"},
    {prodId:"51102326",label:"Efavirenz"},
    {prodId:"51102327",label:"Amprenavir"},
    {prodId:"51102328",label:"Fomivirsen sódico"},
    {prodId:"51102329",label:"Mesilato de nelfinavir"},
    {prodId:"51102330",label:"Penciclovir"},
    {prodId:"51102331",label:"Saquinavir"},
    {prodId:"51102332",label:"Fumarato de disoproxilo tenofovir"},
    {prodId:"51102333",label:"Hidrocloruro de valganciclovir"},
    {prodId:"51102334",label:"Zanamivir"},
    {prodId:"51102335",label:"Sodio de aciclovir"},
    {prodId:"51102336",label:"Moroxidina"},
    {prodId:"51102337",label:"Adefovir dipivoxil"},
    {prodId:"51102338",label:"Oseltamivir"},
    {prodId:"51102339",label:"Ganciclovir"},
    {prodId:"51102340",label:"Estavudina"},
    {prodId:"51102341",label:"Valaciclovir"},
    {prodId:"51102342",label:"Valganciclovir"},
    {prodId:"51102343",label:"Estavudina, lamivudina y nevirapina"},
    {prodId:"51102344",label:"Lamivudine and  zidovudine"},
    {prodId:"51102345",label:"Lopinavir y ritonavir"},
    {prodId:"51102346",label:"Zidovudina, neviparina and lamivudina"},
    {prodId:"51102400",label:"Oftálmico"},
    {prodId:"51102402",label:"Hipromelosa"},
    {prodId:"51102403",label:"Sulfacetamida"},
    {prodId:"51102500",label:"Antihelmínticos veterinarios"},
    {prodId:"51102501",label:"Naftenato de cobre"},
    {prodId:"51102502",label:"Toltrazuril"},
    {prodId:"51102503",label:"Flumetrina"},
    {prodId:"51102505",label:"Triclorfon"},
    {prodId:"51102506",label:"Oxfendazol"},
    {prodId:"51102507",label:"Clorhidrato de piperidolato"},
    {prodId:"51102600",label:"Antibióticos veterinarios"},
    {prodId:"51102601",label:"Enrofloxacina"},
    {prodId:"51102700",label:"Antisépticos"},
    {prodId:"51102701",label:"Clofoctol"},
    {prodId:"51102702",label:"Agua estéril para irrigación"},
    {prodId:"51102705",label:"Cetrimida"},
    {prodId:"51102706",label:"Ácido acético  antiséptico"},
    {prodId:"51102707",label:"Gluconato de clorhexidina"},
    {prodId:"51102708",label:"Formaldehído antiséptico"},
    {prodId:"51102709",label:"Peróxido de hidrógeno antiséptico"},
    {prodId:"51102710",label:"Antisépticos basados en alcohol o acetona"},
    {prodId:"51102711",label:"Oxiquinolina"},
    {prodId:"51102712",label:"Antisépticos fenólicos"},
    {prodId:"51102713",label:"Povidona yodada"},
    {prodId:"51102714",label:"Solución de cloruro sódico para irrigación"},
    {prodId:"51102715",label:"Clioquinol"},
    {prodId:"51102717",label:"Nitrofurazona"},
    {prodId:"51102718",label:"Nitrato de plata"},
    {prodId:"51102719",label:"Yodoformo"},
    {prodId:"51102720",label:"Cloroxilenol"},
    {prodId:"51102721",label:"Ictamol"},
    {prodId:"51102722",label:"Geles o soluciones tópicas de yodo"},
    {prodId:"51102723",label:"Merbromina"},
    {prodId:"51102724",label:"Cloruro de benzalconio"},
    {prodId:"51102725",label:"Hexilresorcinol"},
    {prodId:"51102726",label:"Peróxido de carbamida"},
    {prodId:"51102727",label:"Yoduro de timol"},
    {prodId:"51102728",label:"Perborato sódico"},
    {prodId:"51102729",label:"Cloruro de cetilpiridinio"},
    {prodId:"51102730",label:"Isetionato de hexamidina"},
    {prodId:"51111500",label:"Agentes Alquilante"},
    {prodId:"51111501",label:"Amifostina"},
    {prodId:"51111502",label:"Busulfán"},
    {prodId:"51111503",label:"Carboplatino"},
    {prodId:"51111504",label:"Carmustina"},
    {prodId:"51111505",label:"Clorambucil"},
    {prodId:"51111506",label:"Cisplatino"},
    {prodId:"51111507",label:"Ciclofosfamida"},
    {prodId:"51111508",label:"Dacarbazina"},
    {prodId:"51111509",label:"Ifosfamida"},
    {prodId:"51111510",label:"Lomustina"},
    {prodId:"51111511",label:"Clorhidrato de mecloretamina"},
    {prodId:"51111512",label:"Melfalán"},
    {prodId:"51111513",label:"Mesna"},
    {prodId:"51111514",label:"Pipobroman"},
    {prodId:"51111515",label:"Estreptozocina"},
    {prodId:"51111516",label:"Tiotepa"},
    {prodId:"51111517",label:"Mostaza de uracilo"},
    {prodId:"51111518",label:"Clorhidrato de melfalan"},
    {prodId:"51111519",label:"Temozolomida"},
    {prodId:"51111520",label:"Clorhidrato de procarbazina"},
    {prodId:"51111521",label:"Altretamina"},
    {prodId:"51111522",label:"Ceftriaxona sódica"},
    {prodId:"51111600",label:"Antimetabolitos"},
    {prodId:"51111601",label:"Cladribina"},
    {prodId:"51111602",label:"Citarabina"},
    {prodId:"51111603",label:"Floxuridina"},
    {prodId:"51111604",label:"Fosfato de fludarabina"},
    {prodId:"51111605",label:"Fluorouracilo"},
    {prodId:"51111606",label:"Hidroxiurea"},
    {prodId:"51111609",label:"Mercaptopurina"},
    {prodId:"51111610",label:"Metotrexato"},
    {prodId:"51111611",label:"Tenipósido"},
    {prodId:"51111612",label:"Tioguanina"},
    {prodId:"51111613",label:"Lenograstim"},
    {prodId:"51111614",label:"Etopósido"},
    {prodId:"51111615",label:"Glutatión"},
    {prodId:"51111616",label:"Capecitabina"},
    {prodId:"51111617",label:"Clorhidrato de gemcitabina"},
    {prodId:"51111618",label:"Metotrexato de sodio"},
    {prodId:"51111700",label:"Antibióticos antineoplásicos"},
    {prodId:"51111701",label:"Sulfato de bleomicina"},
    {prodId:"51111702",label:"Dactinomicina"},
    {prodId:"51111703",label:"Daunorubicinas"},
    {prodId:"51111704",label:"Mitomicina"},
    {prodId:"51111705",label:"Mitotano"},
    {prodId:"51111706",label:"Clorhidrato de mitoxantrona"},
    {prodId:"51111707",label:"Pentostatina"},
    {prodId:"51111708",label:"Plicamicina"},
    {prodId:"51111709",label:"Sulfato de vincristina"},
    {prodId:"51111710",label:"Epirubicina"},
    {prodId:"51111711",label:"Clorhidrato de doxorubicina"},
    {prodId:"51111712",label:"Pirarubicina"},
    {prodId:"51111713",label:"Alemtuzumab"},
    {prodId:"51111714",label:"Clorhidrato de doxorrubicina liposomal"},
    {prodId:"51111715",label:"Epirubicina hidrocloruro"},
    {prodId:"51111716",label:"Rituximab"},
    {prodId:"51111717",label:"Trastuzumab"},
    {prodId:"51111718",label:"Valrubicina"},
    {prodId:"51111719",label:"Clorhidrato de idarrubicina"},
    {prodId:"51111720",label:"Infliximab"},
    {prodId:"51111721",label:"Ibritumomab tiuxetan"},
    {prodId:"51111722",label:"Imatinib"},
    {prodId:"51111723",label:"Leuprorelina"},
    {prodId:"51111800",label:"Hormonas y antihormonas"},
    {prodId:"51111801",label:"Anastrozol"},
    {prodId:"51111802",label:"Bicalutamida"},
    {prodId:"51111803",label:"Fosfato sódico de estramustina"},
    {prodId:"51111804",label:"Flutamida"},
    {prodId:"51111805",label:"Acetato de goserelina"},
    {prodId:"51111806",label:"Clorhidrato de irinotecan"},
    {prodId:"51111807",label:"Acetato de leuprolide"},
    {prodId:"51111808",label:"Nilutamida"},
    {prodId:"51111809",label:"Tamoxifeno"},
    {prodId:"51111810",label:"Testolactona"},
    {prodId:"51111811",label:"Clorhidrato de topotecan"},
    {prodId:"51111812",label:"Sulfato de vinblastina"},
    {prodId:"51111813",label:"Sulfato de vincristina"},
    {prodId:"51111814",label:"Tartrato de vinorelbina"},
    {prodId:"51111815",label:"Triptorelina"},
    {prodId:"51111816",label:"Buserelina"},
    {prodId:"51111817",label:"Citrato de toremifeno"},
    {prodId:"51111818",label:"Pamoato de triptorelina"},
    {prodId:"51111819",label:"Fulvestrant"},
    {prodId:"51111820",label:"Letrozol"},
    {prodId:"51111821",label:"Citrato de tamoxifeno"},
    {prodId:"51111822",label:"Oxaliplatino"},
    {prodId:"51111823",label:"Ácido pamidrónico"},
    {prodId:"51111824",label:"Exemestano"},
    {prodId:"51111825",label:"Erlotinib"},
    {prodId:"51111826",label:"Mesilato de  gefitinib"},
    {prodId:"51111827",label:"Tibolona"},
    {prodId:"51111828",label:"Ciproterona"},
    {prodId:"51111900",label:"Productos antitumorales naturales"},
    {prodId:"51111901",label:"Asparaginasa"},
    {prodId:"51111902",label:"Docetaxel"},
    {prodId:"51111904",label:"Paclitaxel"},
    {prodId:"51111905",label:"Porfímero sódico"},
    {prodId:"51111906",label:"Pegaspargasa"},
    {prodId:"51111907",label:"Fenilbutirato de sodio"},
    {prodId:"51121500",label:"Agentes antiarritmia"},
    {prodId:"51121501",label:"Adenosina"},
    {prodId:"51121502",label:"Digoxina"},
    {prodId:"51121503",label:"Gluconato de quinidina"},
    {prodId:"51121504",label:"Flecainida"},
    {prodId:"51121506",label:"Clorhidrato de sotalol"},
    {prodId:"51121507",label:"Clorhidrato de hidroquinidina"},
    {prodId:"51121509",label:"Propafenona"},
    {prodId:"51121510",label:"Disopiramida"},
    {prodId:"51121511",label:"Clorhidrato de amiodarona"},
    {prodId:"51121512",label:"Tosilato de bretilio"},
    {prodId:"51121513",label:"Fosfato de disopiramida"},
    {prodId:"51121514",label:"Dofetilida"},
    {prodId:"51121515",label:"Acetato de flecainida"},
    {prodId:"51121516",label:"Ibutilida fumarato"},
    {prodId:"51121517",label:"Clorhidrato de mexiletina"},
    {prodId:"51121518",label:"Clorhidrato de moricizina"},
    {prodId:"51121519",label:"Clorhidrato de procainamida"},
    {prodId:"51121520",label:"Clorhidrato de propafenona"},
    {prodId:"51121521",label:"Quinidina poligalacturonato"},
    {prodId:"51121522",label:"Clorhidrato de tocainida"},
    {prodId:"51121523",label:"Sulfato de quinidina"},
    {prodId:"51121600",label:"Medicamentos antiangina"},
    {prodId:"51121601",label:"Nitrato de amilo"},
    {prodId:"51121602",label:"Dinitrato de isosorbida"},
    {prodId:"51121603",label:"Nitroglicerina"},
    {prodId:"51121604",label:"Tetranitrato de pentaeritrito"},
    {prodId:"51121607",label:"Nicorandi"},
    {prodId:"51121608",label:"Clorhidrato de nicardipina"},
    {prodId:"51121609",label:"Molsidomina"},
    {prodId:"51121610",label:"Pralidoxima"},
    {prodId:"51121611",label:"Trimetazidina"},
    {prodId:"51121614",label:"Mononitrato de isosorbida"},
    {prodId:"51121615",label:"Clorhidrato de bepridil"},
    {prodId:"51121616",label:"Clorhidrato de isoxsuprina"},
    {prodId:"51121617",label:"Ranolazina"},
    {prodId:"51121700",label:"Medicamentos antihipertensivos"},
    {prodId:"51121701",label:"Diazóxido"},
    {prodId:"51121702",label:"Terazosina"},
    {prodId:"51121703",label:"Captopril"},
    {prodId:"51121704",label:"Lisinopril"},
    {prodId:"51121705",label:"Felodipino"},
    {prodId:"51121706",label:"Isradipina"},
    {prodId:"51121707",label:"Verapamilo"},
    {prodId:"51121708",label:"Metildopa"},
    {prodId:"51121709",label:"Carvedilol"},
    {prodId:"51121710",label:"Losartán potásico"},
    {prodId:"51121711",label:"Minoxidil"},
    {prodId:"51121713",label:"Clorhidrato de diltiazem"},
    {prodId:"51121714",label:"Vincamina"},
    {prodId:"51121715",label:"Enalapril"},
    {prodId:"51121716",label:"Piretanida"},
    {prodId:"51121717",label:"Mesilato de eprosartán"},
    {prodId:"51121718",label:"Clorhidrato de clonidina"},
    {prodId:"51121721",label:"Tartrato de metoprolol"},
    {prodId:"51121722",label:"Reserpina"},
    {prodId:"51121724",label:"Naftidrofurilo oxalato"},
    {prodId:"51121725",label:"Bisoprolol fumarato"},
    {prodId:"51121726",label:"Indapamida"},
    {prodId:"51121727",label:"Penbutolol"},
    {prodId:"51121728",label:"Prazosina"},
    {prodId:"51121729",label:"Celiprolol"},
    {prodId:"51121730",label:"Ramipril"},
    {prodId:"51121731",label:"Trandolapril"},
    {prodId:"51121732",label:"Combinación de trandolapril y clorhidrato de verapamilo"},
    {prodId:"51121733",label:"Valsartán"},
    {prodId:"51121734",label:"Nicotinato de xantinol"},
    {prodId:"51121735",label:"Candesartán cilexetilo"},
    {prodId:"51121737",label:"Clorhidrato de benazepril"},
    {prodId:"51121738",label:"Deserpidina"},
    {prodId:"51121739",label:"Clorhidrato de verapamilo"},
    {prodId:"51121740",label:"Nisoldipino"},
    {prodId:"51121741",label:"Diclorhidrato de mibefradil"},
    {prodId:"51121742",label:"Diltiazem malato"},
    {prodId:"51121743",label:"Besilato de amlodipina"},
    {prodId:"51121744",label:"Maleato de enalpril"},
    {prodId:"51121745",label:"Dihidrato de enalaprilato"},
    {prodId:"51121746",label:"Mesilato de fenoldopam"},
    {prodId:"51121747",label:"Fosinopril sódico"},
    {prodId:"51121748",label:"Acetato de guanabenz"},
    {prodId:"51121749",label:"Sulfato de guanadrel"},
    {prodId:"51121750",label:"Sulfato de guanetidina"},
    {prodId:"51121751",label:"Clorhidrato de guanfacina"},
    {prodId:"51121752",label:"Hidralazina hidrocloruro"},
    {prodId:"51121753",label:"Irbesartán"},
    {prodId:"51121754",label:"Clorhidrato de mecamilamina"},
    {prodId:"51121755",label:"Clorhidrato de metildopato"},
    {prodId:"51121756",label:"Metirosina"},
    {prodId:"51121757",label:"Clorhidrato de moexipril"},
    {prodId:"51121758",label:"Nitroprusiato de sodio"},
    {prodId:"51121759",label:"Olmesartán medoxomilo"},
    {prodId:"51121760",label:"Perindopril erbumina"},
    {prodId:"51121761",label:"Clorhidrato de quinapril"},
    {prodId:"51121762",label:"Rauwolfia serpentina"},
    {prodId:"51121763",label:"Telmisartán"},
    {prodId:"51121764",label:"Quinapril"},
    {prodId:"51121765",label:"Metoprolol"},
    {prodId:"51121766",label:"Atomoxetina"},
    {prodId:"51121767",label:"Nebivolol"},
    {prodId:"51121768",label:"Benazepril"},
    {prodId:"51121769",label:"Perindopril"},
    {prodId:"51121770",label:"Cilazapril"},
    {prodId:"51121771",label:"Fosinopril"},
    {prodId:"51121772",label:"Gugulsterona"},
    {prodId:"51121773",label:"Clorhidrato de lercanidipina"},
    {prodId:"51121774",label:"Levosimendán"},
    {prodId:"51121775",label:"Tirofibán"},
    {prodId:"51121777",label:"Trimetazidina  dihidrocloruro"},
    {prodId:"51121778",label:"Rilmenidina"},
    {prodId:"51121779",label:"Clevidipina"},
    {prodId:"51121780",label:"Losartán"},
    {prodId:"51121800",label:"Agentes antihiperlipidémicos/hipocolesterolémicos"},
    {prodId:"51121801",label:"Fluvastatina sódica"},
    {prodId:"51121802",label:"Lovastatina"},
    {prodId:"51121803",label:"Simvastatina"},
    {prodId:"51121804",label:"Clofibrato"},
    {prodId:"51121805",label:"Gemfibrozilo"},
    {prodId:"51121806",label:"Pravastatina de sodio"},
    {prodId:"51121807",label:"Polidocanol"},
    {prodId:"51121808",label:"Probucol"},
    {prodId:"51121809",label:"Fenofibrato"},
    {prodId:"51121810",label:"Atorvastatina de calcio"},
    {prodId:"51121811",label:"Cerivastatina sódica"},
    {prodId:"51121812",label:"Colina"},
    {prodId:"51121813",label:"Bitartrato de colina"},
    {prodId:"51121814",label:"Inositol"},
    {prodId:"51121815",label:"Racemetionina"},
    {prodId:"51121816",label:"Fosfatidilcolina"},
    {prodId:"51121817",label:"Colestiramina"},
    {prodId:"51121818",label:"Atorvastatina"},
    {prodId:"51121819",label:"Ezetimiba"},
    {prodId:"51121820",label:"Sulfato de condroitina a"},
    {prodId:"51121821",label:"Pravastatina"},
    {prodId:"51121822",label:"Mevastatina"},
    {prodId:"51121823",label:"Rosuvastatina"},
    {prodId:"51121900",label:"Medicamentos usados para insuficiencia cardíaca congestiva"},
    {prodId:"51121901",label:"Lactato de inamrinona"},
    {prodId:"51121902",label:"Lactato de milrinona"},
    {prodId:"51121903",label:"Digitoxina"},
    {prodId:"51121904",label:"Nifedipina"},
    {prodId:"51121905",label:"Enoximona"},
    {prodId:"51121906",label:"Betaína"},
    {prodId:"51121907",label:"Nesiritida"},
    {prodId:"51121908",label:"Hoja de digital en polvo o preparados"},
    {prodId:"51121909",label:"Eplerenona"},
    {prodId:"51121910",label:"Lanatosido c"},
    {prodId:"51122100",label:"Vasodilatadores"},
    {prodId:"51122101",label:"Mesilato de dihidroergocristina"},
    {prodId:"51122102",label:"Clorhidrato de buflomedil"},
    {prodId:"51122103",label:"Clorhidrato de linsidomina"},
    {prodId:"51122104",label:"Papaverina"},
    {prodId:"51122105",label:"Pentifilina"},
    {prodId:"51122107",label:"Tartrato de ifenprodil"},
    {prodId:"51122108",label:"Mesilatos ergoloides"},
    {prodId:"51122109",label:"Clorhidrato de papaverina"},
    {prodId:"51122110",label:"Nimodipina"},
    {prodId:"51122111",label:"Nitrito de amilo"},
    {prodId:"51122112",label:"Alprostadil"},
    {prodId:"51122113",label:"Ácido nicotínico"},
    {prodId:"51122200",label:"Fármacos contra la hipotensión"},
    {prodId:"51122201",label:"Ornipresina"},
    {prodId:"51131500",label:"Medicamentos antianémicos"},
    {prodId:"51131501",label:"Fumarato ferroso"},
    {prodId:"51131502",label:"Gluconato ferroso"},
    {prodId:"51131503",label:"Sulfato ferroso"},
    {prodId:"51131504",label:"Pidolato de magnesio"},
    {prodId:"51131505",label:"Oximetolona"},
    {prodId:"51131506",label:"Eritropoyetina"},
    {prodId:"51131507",label:"Darbepoetina alfa"},
    {prodId:"51131508",label:"Epoetina alfa"},
    {prodId:"51131509",label:"Citrato de amonio férrico"},
    {prodId:"51131510",label:"Polipéptido de hierro heme"},
    {prodId:"51131511",label:"Hierro polisacárido"},
    {prodId:"51131512",label:"Pirofosfato férrico"},
    {prodId:"51131513",label:"Cacodilato ferroso"},
    {prodId:"51131514",label:"Carbonilo de hierro"},
    {prodId:"51131515",label:"Hemina o hematina"},
    {prodId:"51131516",label:"Hierro sacarosa"},
    {prodId:"51131517",label:"Ácido fólico"},
    {prodId:"51131518",label:"Sulfato ferroso y ácido fólico"},
    {prodId:"51131600",label:"Anticoagulantes"},
    {prodId:"51131601",label:"Aprotinina"},
    {prodId:"51131602",label:"Heparina de calcio"},
    {prodId:"51131603",label:"Heparina sódica"},
    {prodId:"51131604",label:"Warfarina sódica"},
    {prodId:"51131605",label:"Citrato de sodio"},
    {prodId:"51131606",label:"Apolato de sodio"},
    {prodId:"51131607",label:"Enoxaparina sódica"},
    {prodId:"51131608",label:"Lepirudina"},
    {prodId:"51131609",label:"Desirudina"},
    {prodId:"51131610",label:"Ardeparina de sodio"},
    {prodId:"51131611",label:"Dalteparina sódica"},
    {prodId:"51131612",label:"Danaparoide sódico"},
    {prodId:"51131613",label:"Dicumarol"},
    {prodId:"51131614",label:"Anisindiona"},
    {prodId:"51131615",label:"Fondaparinux sódico"},
    {prodId:"51131616",label:"Tinzaparina sódica"},
    {prodId:"51131617",label:"Solución  anticoagulante citrato fosfato dextrosa"},
    {prodId:"51131700",label:"Trombolíticos/inhibidores de agregación de plaquetas"},
    {prodId:"51131701",label:"Abciximab"},
    {prodId:"51131702",label:"Alteplasa"},
    {prodId:"51131703",label:"Estreptoquinasa"},
    {prodId:"51131704",label:"Uroquinasa"},
    {prodId:"51131705",label:"Dipiridamol"},
    {prodId:"51131706",label:"Argatroban"},
    {prodId:"51131707",label:"Bivalirudina"},
    {prodId:"51131708",label:"Cilostazol"},
    {prodId:"51131709",label:"Bisulfato de clopidogrel"},
    {prodId:"51131710",label:"Eptifibatida"},
    {prodId:"51131711",label:"Tenecteplasa"},
    {prodId:"51131712",label:"Clorhidrato de ticlopidina"},
    {prodId:"51131713",label:"Monohidrato hidrocloruro  tirofibán"},
    {prodId:"51131714",label:"Anistreplasa"},
    {prodId:"51131715",label:"Clorhidrato de anagrelida"},
    {prodId:"51131716",label:"Reteplasa"},
    {prodId:"51131800",label:"Agentes hemostáticos sistémicos y coagulantes"},
    {prodId:"51131801",label:"Fibrinógeno"},
    {prodId:"51131802",label:"Factores antihemofílicos o globulinas"},
    {prodId:"51131803",label:"Trombina"},
    {prodId:"51131804",label:"Oprelvekina"},
    {prodId:"51131805",label:"Etamsilato"},
    {prodId:"51131806",label:"Subsulfato férrico"},
    {prodId:"51131807",label:"Morruato de sodio"},
    {prodId:"51131808",label:"Ácido aminocaproico"},
    {prodId:"51131809",label:"Dobesilato de calcio"},
    {prodId:"51131810",label:"Parche hemostático"},
    {prodId:"51131811",label:"Ácido tranexámico"},
    {prodId:"51131900",label:"Sustitutos de plasma sanguíneo y extensores y expansores"},
    {prodId:"51131901",label:"Poligelina"},
    {prodId:"51131903",label:"Gelatina"},
    {prodId:"51131904",label:"Plasma sanguíneo humano"},
    {prodId:"51131905",label:"Dextrano"},
    {prodId:"51131906",label:"Pentastarch"},
    {prodId:"51131907",label:"Fracción de proteína de plasma humano"},
    {prodId:"51131908",label:"Hetastarch"},
    {prodId:"51131909",label:"Albúmina humana"},
    {prodId:"51131910",label:"Polividona o povidona"},
    {prodId:"51132000",label:"Agentes hemorreológicos"},
    {prodId:"51132001",label:"Pentoxifilina"},
    {prodId:"51141500",label:"Anticonvulsivos"},
    {prodId:"51141501",label:"Acetazolamida"},
    {prodId:"51141502",label:"Clonazepam"},
    {prodId:"51141503",label:"Felbamato"},
    {prodId:"51141504",label:"Lamotrigina"},
    {prodId:"51141505",label:"Fenobarbital"},
    {prodId:"51141506",label:"Fenobarbital sódico"},
    {prodId:"51141507",label:"Fenitoína"},
    {prodId:"51141508",label:"Etosuximida"},
    {prodId:"51141509",label:"Vigabatrina"},
    {prodId:"51141510",label:"Barbexaclona"},
    {prodId:"51141511",label:"Urbanyl"},
    {prodId:"51141512",label:"Clobazam"},
    {prodId:"51141513",label:"Carbamazepina"},
    {prodId:"51141514",label:"Zonisamida"},
    {prodId:"51141515",label:"Etotoína"},
    {prodId:"51141516",label:"Fosfenitoína sódica"},
    {prodId:"51141517",label:"Gabapentina"},
    {prodId:"51141518",label:"Levetiracetam"},
    {prodId:"51141519",label:"Mefenitoina"},
    {prodId:"51141520",label:"Mefobarbital"},
    {prodId:"51141521",label:"Metsuximida"},
    {prodId:"51141522",label:"Oxcarbazepina"},
    {prodId:"51141523",label:"Parametadiona"},
    {prodId:"51141524",label:"Fenacemida"},
    {prodId:"51141525",label:"Fenitoína sódica"},
    {prodId:"51141526",label:"Primidona"},
    {prodId:"51141527",label:"Clorhidrato de tiagabina"},
    {prodId:"51141528",label:"Topiramato"},
    {prodId:"51141529",label:"Trimetadiona"},
    {prodId:"51141530",label:"Valproato de sodio"},
    {prodId:"51141531",label:"Ácido valproico"},
    {prodId:"51141532",label:"Acetazolamida sódica"},
    {prodId:"51141533",label:"Divalproex sódico"},
    {prodId:"51141534",label:"Pregabalina"},
    {prodId:"51141535",label:"Cabergolina"},
    {prodId:"51141536",label:"Citalopram"},
    {prodId:"51141537",label:"Clorazepato"},
    {prodId:"51141538",label:"Donepezilo"},
    {prodId:"51141539",label:"Duloxetina"},
    {prodId:"51141541",label:"Memantina"},
    {prodId:"51141542",label:"Midazolam"},
    {prodId:"51141543",label:"Ziprasidona"},
    {prodId:"51141600",label:"Antidepresivos"},
    {prodId:"51141601",label:"Amitriptilina"},
    {prodId:"51141602",label:"Clorhidrato de doxepina"},
    {prodId:"51141603",label:"Pamoato de imipramina"},
    {prodId:"51141604",label:"Mirtazapina"},
    {prodId:"51141605",label:"Hidrocloruro de paroxetina"},
    {prodId:"51141606",label:"Clorhidrato de trazodona"},
    {prodId:"51141607",label:"Maleato de fluvoxamina"},
    {prodId:"51141608",label:"Amitriptilinoxida"},
    {prodId:"51141609",label:"Sulfato de tranilcipromina"},
    {prodId:"51141610",label:"Clorhidrato de desipramina"},
    {prodId:"51141611",label:"Trimipramina mesilato"},
    {prodId:"51141612",label:"Sibutramina"},
    {prodId:"51141613",label:"Clorhidrato de carpipramine"},
    {prodId:"51141614",label:"Clomipramina"},
    {prodId:"51141615",label:"Trimipramina"},
    {prodId:"51141616",label:"Clorhidrato de clomipramina"},
    {prodId:"51141617",label:"Bromhidrato de citalopram"},
    {prodId:"51141618",label:"Clorhidrato de fluoxetina"},
    {prodId:"51141619",label:"Hidrocloruro de sertralina"},
    {prodId:"51141620",label:"Mosto de st'john"},
    {prodId:"51141621",label:"Clorhidrato de imipramina"},
    {prodId:"51141622",label:"Isocarboxazida"},
    {prodId:"51141623",label:"Clorhidrato de caprotilina"},
    {prodId:"51141624",label:"Clorhidrato de nefazodona"},
    {prodId:"51141625",label:"Clorhidrato de nortriptilina"},
    {prodId:"51141626",label:"Clorhidrato de protriptilina"},
    {prodId:"51141627",label:"Maleato de trimipramina"},
    {prodId:"51141628",label:"Nortriptilina"},
    {prodId:"51141629",label:"Amoxapina"},
    {prodId:"51141630",label:"Tiroides"},
    {prodId:"51141631",label:"Hidrocloruro de bupropión"},
    {prodId:"51141632",label:"Sulfato de fenelzina"},
    {prodId:"51141633",label:"Oxalato de escitalopram"},
    {prodId:"51141634",label:"Aripiprazol"},
    {prodId:"51141635",label:"Clorhidrato de duloxetina"},
    {prodId:"51141636",label:"Venlafaxina hcl"},
    {prodId:"51141637",label:"Mirtazepina"},
    {prodId:"51141638",label:"Venlafaxina"},
    {prodId:"51141639",label:"Desvenlafaxina"},
    {prodId:"51141640",label:"Moclobemida"},
    {prodId:"51141700",label:"Agentes antipsicóticos"},
    {prodId:"51141701",label:"Fenotiazinas"},
    {prodId:"51141702",label:"Haloperidol"},
    {prodId:"51141703",label:"Olanzapina"},
    {prodId:"51141704",label:"Risperidona"},
    {prodId:"51141705",label:"Nicergolina"},
    {prodId:"51141706",label:"Citicolina"},
    {prodId:"51141707",label:"Clorhidrato de buspirona"},
    {prodId:"51141708",label:"Clordiazepóxido"},
    {prodId:"51141709",label:"Flufenazina"},
    {prodId:"51141710",label:"Periciazina"},
    {prodId:"51141711",label:"Levomepromazina"},
    {prodId:"51141712",label:"Zotepina"},
    {prodId:"51141713",label:"Palmitato de pipotiazina"},
    {prodId:"51141714",label:"Piracetam"},
    {prodId:"51141715",label:"Clozapina"},
    {prodId:"51141716",label:"Decanoato de haloperidol"},
    {prodId:"51141717",label:"Lactato de haloperidol"},
    {prodId:"51141718",label:"Clorhidrato de loxapina"},
    {prodId:"51141719",label:"Succinato de loxapina"},
    {prodId:"51141720",label:"Clorhidrato de molindona"},
    {prodId:"51141721",label:"Pimozida"},
    {prodId:"51141722",label:"Fumarato de quetiapina"},
    {prodId:"51141723",label:"Tiotixeno"},
    {prodId:"51141724",label:"Clorhidrato de tiotixeno"},
    {prodId:"51141725",label:"Clorhidrato de ziprasidona"},
    {prodId:"51141726",label:"Clorhidrato de triflupromazina"},
    {prodId:"51141727",label:"Mesilato de ziprasidona"},
    {prodId:"51141728",label:"Droperidol"},
    {prodId:"51141729",label:"Clorhidrato de tioridazina"},
    {prodId:"51141730",label:"Amisulprida"},
    {prodId:"51141731",label:"Modafinilo"},
    {prodId:"51141732",label:"Clorpromazina"},
    {prodId:"51141800",label:"Hipnóticos"},
    {prodId:"51141801",label:"Pentobarbital"},
    {prodId:"51141802",label:"Secobarbital sódico"},
    {prodId:"51141803",label:"Hidrato de cloral"},
    {prodId:"51141804",label:"Estazolam"},
    {prodId:"51141805",label:"Triazolam"},
    {prodId:"51141806",label:"Butobarbital"},
    {prodId:"51141807",label:"Flunitrazepam"},
    {prodId:"51141808",label:"Tartrato de zolpidem"},
    {prodId:"51141809",label:"Loprazolam mesilato"},
    {prodId:"51141810",label:"Zoplicona"},
    {prodId:"51141811",label:"Amobarbital sódico"},
    {prodId:"51141812",label:"Didrocloruro de dexmedetomidina"},
    {prodId:"51141813",label:"Etclorvinol"},
    {prodId:"51141814",label:"Clorhidrato de flurazepam"},
    {prodId:"51141815",label:"Glutetimida"},
    {prodId:"51141816",label:"Paraldehído"},
    {prodId:"51141817",label:"Quazepam"},
    {prodId:"51141818",label:"Triptófano"},
    {prodId:"51141819",label:"Zaleplon"},
    {prodId:"51141820",label:"Pentobarbital sódico"},
    {prodId:"51141821",label:"Doxilamina succinato"},
    {prodId:"51141822",label:"Butabarbital"},
    {prodId:"51141823",label:"Eszopiclona"},
    {prodId:"51141900",label:"Tranquilizantes y fármacos ansiolíticos y antimaniacos"},
    {prodId:"51141903",label:"Carbonato de litio"},
    {prodId:"51141904",label:"Citrato de litio"},
    {prodId:"51141907",label:"Sulpirida"},
    {prodId:"51141908",label:"Tiaprida"},
    {prodId:"51141910",label:"Bromazepam"},
    {prodId:"51141911",label:"Tofisopam"},
    {prodId:"51141912",label:"Hidrocloruro de chlordiazepoxide"},
    {prodId:"51141913",label:"Clormezanone"},
    {prodId:"51141914",label:"Clorazapato dipotásico"},
    {prodId:"51141915",label:"Halazepam"},
    {prodId:"51141916",label:"Lorazepam"},
    {prodId:"51141917",label:"Oxazepam"},
    {prodId:"51141918",label:"Meprobamato"},
    {prodId:"51141919",label:"Alprazolam"},
    {prodId:"51141920",label:"Diazepam"},
    {prodId:"51141921",label:"Clorhidrato de midazoloam"},
    {prodId:"51141922",label:"Temazepam"},
    {prodId:"51142000",label:"Analgésicos no narcóticos/antipiréticos"},
    {prodId:"51142001",label:"Acetaminofén"},
    {prodId:"51142002",label:"Ácido acetilsalicílico"},
    {prodId:"51142003",label:"Mesalamina"},
    {prodId:"51142004",label:"Meprobromato"},
    {prodId:"51142005",label:"Butetisalicilato de metilo"},
    {prodId:"51142006",label:"Oxaceprol"},
    {prodId:"51142009",label:"Metamizol sódico"},
    {prodId:"51142010",label:"Acetanilida"},
    {prodId:"51142011",label:"Salicilato de magnesio"},
    {prodId:"51142012",label:"Ácido mefenámico"},
    {prodId:"51142013",label:"Salicilamida"},
    {prodId:"51142014",label:"Tiosalicilato de sodio"},
    {prodId:"51142015",label:"Salicilato de trietanolamina"},
    {prodId:"51142016",label:"Solución de antipirina y benzocaína"},
    {prodId:"51142017",label:"Salsalato o ácido salicil salicílico"},
    {prodId:"51142018",label:"Inyección de alcohol deshidratado"},
    {prodId:"51142100",label:"Fármacos antiinflamatorios no esteroideos (NSAID)"},
    {prodId:"51142101",label:"Auranofin"},
    {prodId:"51142102",label:"Carpofen"},
    {prodId:"51142103",label:"Diclofenaco potásico"},
    {prodId:"51142104",label:"Diclofenaco sódico"},
    {prodId:"51142105",label:"Flurbiprofeno"},
    {prodId:"51142106",label:"Ibuprofeno"},
    {prodId:"51142107",label:"Indometacina"},
    {prodId:"51142108",label:"Ketoprofeno"},
    {prodId:"51142109",label:"Naproxeno"},
    {prodId:"51142110",label:"Naproxeno sódico"},
    {prodId:"51142111",label:"Oxaprozin"},
    {prodId:"51142112",label:"Sulindaco"},
    {prodId:"51142113",label:"Suprofeno"},
    {prodId:"51142114",label:"Clorhidrato de bencidamina"},
    {prodId:"51142116",label:"Salicilato de dietilamina"},
    {prodId:"51142117",label:"Dietilamina diclofenaco"},
    {prodId:"51142118",label:"Nimesulida"},
    {prodId:"51142119",label:"Benorilato"},
    {prodId:"51142120",label:"Serrapeptasa"},
    {prodId:"51142121",label:"Diclofenaco"},
    {prodId:"51142122",label:"Auriotomalato de sodio"},
    {prodId:"51142123",label:"Ketorolaco trometamol"},
    {prodId:"51142124",label:"Etodolac"},
    {prodId:"51142125",label:"Etofenamato"},
    {prodId:"51142126",label:"Floctafenina"},
    {prodId:"51142127",label:"Emorfazona"},
    {prodId:"51142128",label:"Piroxicam"},
    {prodId:"51142129",label:"Ácido tiaprofénico"},
    {prodId:"51142130",label:"Leflunomida"},
    {prodId:"51142131",label:"Celecoxib"},
    {prodId:"51142132",label:"Aurotioglucosa"},
    {prodId:"51142133",label:"Bromfenac sódico"},
    {prodId:"51142134",label:"Salicilato de colina"},
    {prodId:"51142135",label:"Fenoprofen cálcico"},
    {prodId:"51142136",label:"Flurbiprofen sódico"},
    {prodId:"51142137",label:"Indometacina trihidrato sódica"},
    {prodId:"51142138",label:"Ketorolac trometamina"},
    {prodId:"51142139",label:"Meclofenamato sódico"},
    {prodId:"51142140",label:"Meloxicam"},
    {prodId:"51142141",label:"Nabumetona"},
    {prodId:"51142142",label:"Rofecoxib"},
    {prodId:"51142143",label:"Tolmetin sódico"},
    {prodId:"51142144",label:"Valdecoxib"},
    {prodId:"51142145",label:"Adalimumab"},
    {prodId:"51142146",label:"Diflunisal"},
    {prodId:"51142147",label:"Anakinra"},
    {prodId:"51142148",label:"Hialuronato de sodio"},
    {prodId:"51142149",label:"Glucosamina"},
    {prodId:"51142150",label:"Acamprosato calcio"},
    {prodId:"51142151",label:"Aceclofenaco"},
    {prodId:"51142152",label:"Deracoxib"},
    {prodId:"51142153",label:"Etoricoxib"},
    {prodId:"51142154",label:"Lumiracoxib"},
    {prodId:"51142155",label:"Clonixinato lisina"},
    {prodId:"51142200",label:"Analgésicos narcóticos"},
    {prodId:"51142201",label:"Butorfanol tartrato"},
    {prodId:"51142202",label:"Fosfato codeina"},
    {prodId:"51142203",label:"Sulfato codeina"},
    {prodId:"51142205",label:"Clorhidrato meperidina"},
    {prodId:"51142206",label:"Sulfato de morfina"},
    {prodId:"51142207",label:"Oxicodona"},
    {prodId:"51142208",label:"Citrato sufentanilo"},
    {prodId:"51142209",label:"Clorhidrato nefopam"},
    {prodId:"51142210",label:"Clorhidrato etilmorfina"},
    {prodId:"51142211",label:"Hidrocodona"},
    {prodId:"51142212",label:"Dihidrocodeina resinato"},
    {prodId:"51142213",label:"Napsilato de propoxifeno"},
    {prodId:"51142214",label:"Clorhidrato de alfentanil"},
    {prodId:"51142215",label:"Hidrocloruro de buprenorfina"},
    {prodId:"51142216",label:"Dezocina"},
    {prodId:"51142217",label:"Dihidrocodéina tartrato"},
    {prodId:"51142218",label:"Dihidrocodéina"},
    {prodId:"51142219",label:"Fentanilo"},
    {prodId:"51142220",label:"Citrato de fentanilo"},
    {prodId:"51142221",label:"Bitartrato de hidrocodona"},
    {prodId:"51142222",label:"Hidrocloruro de hidromorfona"},
    {prodId:"51142223",label:"Polistirex de hidrocodona"},
    {prodId:"51142224",label:"Clorhidrato de acetato de levometadil"},
    {prodId:"51142225",label:"Tartrato de levorfanol"},
    {prodId:"51142226",label:"Clorhidrato de metadona"},
    {prodId:"51142227",label:"Clorhidrato de oxicodona"},
    {prodId:"51142228",label:"Clorhidrato de oximorfona"},
    {prodId:"51142229",label:"Clorhidrato de pentazocina"},
    {prodId:"51142230",label:"Lactato de pentazocina"},
    {prodId:"51142231",label:"Clorhidrato de propoxifeno"},
    {prodId:"51142232",label:"Clorhidrato de remifentanilo"},
    {prodId:"51142233",label:"Codeína"},
    {prodId:"51142234",label:"Bitartrato de dihidrocodeína"},
    {prodId:"51142235",label:"Clorhidrato de tramadol"},
    {prodId:"51142237",label:"Dextropropoxifeno"},
    {prodId:"51142300",label:"Antagonistas narcóticos"},
    {prodId:"51142301",label:"Clorhidrato de nalmefeno"},
    {prodId:"51142302",label:"Hidrocloruro de naloxona"},
    {prodId:"51142303",label:"Naltrexona"},
    {prodId:"51142304",label:"Clorhidrato de nalbufina"},
    {prodId:"51142305",label:"Roxatidina"},
    {prodId:"51142306",label:"Clorhidrato de atipamezol"},
    {prodId:"51142400",label:"Medicamentos para dolores de cabeza vasculares y migraña"},
    {prodId:"51142401",label:"Maleato de metisergida"},
    {prodId:"51142402",label:"Succinato de sumatriptán"},
    {prodId:"51142403",label:"Tartrato de ergotamina"},
    {prodId:"51142404",label:"Dihidroergotamina"},
    {prodId:"51142405",label:"Combinación de ácido acetilsalicílico paracetamol"},
    {prodId:"51142406",label:"Almotriptán malato"},
    {prodId:"51142407",label:"Mesilato de dihidroergotamina"},
    {prodId:"51142408",label:"Succinato de frovatriptan"},
    {prodId:"51142409",label:"Isometepteno"},
    {prodId:"51142410",label:"Naratriptán hidrocloruro"},
    {prodId:"51142411",label:"Benzoato de rizatriptán"},
    {prodId:"51142412",label:"Bromhidrato de eletriptan"},
    {prodId:"51142413",label:"Zolmitriptán"},
    {prodId:"51142414",label:"Sumatriptán"},
    {prodId:"51142415",label:"Combinación de acetaminofén butalbital"},
    {prodId:"51142500",label:"Medicamentos contra el Parkinson"},
    {prodId:"51142501",label:"Mesilato de bromocriptina"},
    {prodId:"51142502",label:"Carbidopa"},
    {prodId:"51142503",label:"Levodopa"},
    {prodId:"51142504",label:"Clorhidrato de selegilina"},
    {prodId:"51142505",label:"Biperideno"},
    {prodId:"51142506",label:"Clorhidrato de biperideno"},
    {prodId:"51142507",label:"Mesilato de pergolida"},
    {prodId:"51142508",label:"Dihidrocloruro de pramipexol"},
    {prodId:"51142509",label:"Clorhidrato de ropinirol"},
    {prodId:"51142510",label:"Tolcapone"},
    {prodId:"51142511",label:"Entacapona"},
    {prodId:"51142512",label:"Tropacine"},
    {prodId:"51142513",label:"Rasagilina"},
    {prodId:"51142514",label:"Levodopa y carbidopa"},
    {prodId:"51142600",label:"Estimulantes/anoréxicos"},
    {prodId:"51142601",label:"Sulfato de anfetamina"},
    {prodId:"51142602",label:"Fenfluramina"},
    {prodId:"51142603",label:"Mazindol"},
    {prodId:"51142604",label:"Pemolina"},
    {prodId:"51142605",label:"Tartrato de fendimetrazina"},
    {prodId:"51142606",label:"Clorhidrato de pipradol"},
    {prodId:"51142607",label:"Fenproporex"},
    {prodId:"51142608",label:"Trimetilxantina"},
    {prodId:"51142609",label:"Clorhidrato de anfepramona"},
    {prodId:"51142610",label:"Cafeína"},
    {prodId:"51142611",label:"Clorhidrato de dexfenfluramina"},
    {prodId:"51142612",label:"Clorhidrato de dietilpropión"},
    {prodId:"51142613",label:"Clorhidrato de fenfluramina"},
    {prodId:"51142614",label:"Clorhidrato de benzfetamina"},
    {prodId:"51142615",label:"Clorhidrato de  fentermina"},
    {prodId:"51142616",label:"Fentermina de resina"},
    {prodId:"51142617",label:"Clorhidrato de sibutramina monohidratada"},
    {prodId:"51142618",label:"Clorhidrato de metilfenidato"},
    {prodId:"51142619",label:"Espíritu aromático de amoníaco"},
    {prodId:"51142700",label:"Fármacos antiinflamatorios no esteroideos veterinarios (VNSAID)"},
    {prodId:"51142701",label:"Fenilbutazona"},
    {prodId:"51142702",label:"Ácido meclofenámico"},
    {prodId:"51142800",label:"Fármacos de tratamiento de la esclerosis lateral amiotrófica (ELA)"},
    {prodId:"51142801",label:"Riluzol"},
    {prodId:"51142900",label:"Fármacos anestésicos, complementos relacionados y analépticos"},
    {prodId:"51142901",label:"Cloroformo"},
    {prodId:"51142902",label:"Mezcla eutéctica de anestésicos locales"},
    {prodId:"51142903",label:"Ropivacaína"},
    {prodId:"51142904",label:"Lidocaína"},
    {prodId:"51142905",label:"Bupivacaína"},
    {prodId:"51142906",label:"Clorhidrato de ametocaína"},
    {prodId:"51142907",label:"Oxibuprocaína"},
    {prodId:"51142908",label:"Lignocaina"},
    {prodId:"51142909",label:"Benzocaína"},
    {prodId:"51142910",label:"Prilocaina"},
    {prodId:"51142911",label:"Clorhidrato de quinisocaina"},
    {prodId:"51142912",label:"Dibucaina"},
    {prodId:"51142913",label:"Halotano"},
    {prodId:"51142914",label:"Clorhidrato de amilocaína"},
    {prodId:"51142915",label:"Septocaina"},
    {prodId:"51142916",label:"Hialuronidasa"},
    {prodId:"51142917",label:"Mepivacaína"},
    {prodId:"51142918",label:"Articaína"},
    {prodId:"51142919",label:"Isoflurano"},
    {prodId:"51142920",label:"Procaina"},
    {prodId:"51142921",label:"Tiopental sódico"},
    {prodId:"51142922",label:"Clorhidrato de benoxinato"},
    {prodId:"51142923",label:"Butambeno"},
    {prodId:"51142924",label:"Clorobutanol"},
    {prodId:"51142925",label:"Clorhidrato de cloroprocaína"},
    {prodId:"51142927",label:"Desflurano"},
    {prodId:"51142928",label:"Clorhidrato de diclonina"},
    {prodId:"51142929",label:"Enflurano"},
    {prodId:"51142930",label:"Éter"},
    {prodId:"51142931",label:"Cloruro de etilo"},
    {prodId:"51142932",label:"Clorhidrato de etidocaína"},
    {prodId:"51142933",label:"Etomidato"},
    {prodId:"51142934",label:"Clorhidrato de ketamina"},
    {prodId:"51142935",label:"Levobupivacaína hidrocloruro"},
    {prodId:"51142936",label:"Levonordefrina"},
    {prodId:"51142937",label:"Clorhidrato de lidocaína"},
    {prodId:"51142938",label:"Metohexital sódico"},
    {prodId:"51142939",label:"Clorhidrato de pramoxina"},
    {prodId:"51142940",label:"Clorhidrato de proparacaína"},
    {prodId:"51142941",label:"Propofol"},
    {prodId:"51142942",label:"Sevoflurano"},
    {prodId:"51142943",label:"Tetracaína"},
    {prodId:"51142944",label:"Clorhidrato de tetracaina"},
    {prodId:"51142945",label:"Clorhidrato de doxapram"},
    {prodId:"51142946",label:"Metoxiflurano"},
    {prodId:"51142947",label:"Clorhidrato de cincocaína"},
    {prodId:"51142948",label:"Diclorotetrafluoroetano"},
    {prodId:"51151500",label:"Colinérgicos y inhibidores de la colinesterasa"},
    {prodId:"51151501",label:"Cloruro de betanecol"},
    {prodId:"51151502",label:"Cloruro de edrofonio"},
    {prodId:"51151503",label:"Fisostigmina"},
    {prodId:"51151504",label:"Nitrato de pilocarpina"},
    {prodId:"51151505",label:"Clorhidrato de tropatepina"},
    {prodId:"51151506",label:"Inhibidor de la esterasa"},
    {prodId:"51151507",label:"Cloruro de ambenonio"},
    {prodId:"51151508",label:"Dexpantenol"},
    {prodId:"51151509",label:"Donepezilo clorhidrato"},
    {prodId:"51151510",label:"Bromhidrato de galantamina"},
    {prodId:"51151511",label:"Bromuro de mepenzolato"},
    {prodId:"51151512",label:"Metilsulfato de neostigmina"},
    {prodId:"51151513",label:"Bromuro de neostigmina"},
    {prodId:"51151514",label:"Bromuro de piridostigmina"},
    {prodId:"51151515",label:"Tartrato de rivastigmina"},
    {prodId:"51151516",label:"Clorhidrato de tacrina"},
    {prodId:"51151517",label:"Clorhidrato de cevimelina"},
    {prodId:"51151518",label:"Lecitina o fosfatidilcolina"},
    {prodId:"51151600",label:"Agentes bloqueadores colinérgicos"},
    {prodId:"51151601",label:"Sulfato de atropina"},
    {prodId:"51151602",label:"Mesilato de benztropina"},
    {prodId:"51151603",label:"Clorhidrato de prociclidina"},
    {prodId:"51151604",label:"Clorhidrato de trihexifenidilo"},
    {prodId:"51151605",label:"Clorhidrato de ciclopentolato"},
    {prodId:"51151606",label:"Tropicamida"},
    {prodId:"51151607",label:"Bromuro de clidinio"},
    {prodId:"51151608",label:"Clorhidrato de diciclomina"},
    {prodId:"51151609",label:"Bromuro de propantelina"},
    {prodId:"51151610",label:"Alcaloides de belladona"},
    {prodId:"51151611",label:"Glicopirrolato"},
    {prodId:"51151612",label:"Sulfato de hiosciamina"},
    {prodId:"51151613",label:"Bromhidrato de escopolamina"},
    {prodId:"51151614",label:"Homatropina bromhidrato"},
    {prodId:"51151615",label:"Caramifeno"},
    {prodId:"51151616",label:"Atropina"},
    {prodId:"51151700",label:"Simpatomiméticos (adrenérgicos)"},
    {prodId:"51151701",label:"Albuterol"},
    {prodId:"51151702",label:"Tartrato de brimonidina"},
    {prodId:"51151703",label:"Epinefrina"},
    {prodId:"51151704",label:"Borato de epinefrina"},
    {prodId:"51151705",label:"Clorhidrato de epinefrina"},
    {prodId:"51151706",label:"Isoproterenol"},
    {prodId:"51151707",label:"Sulfato de isoproterenol"},
    {prodId:"51151708",label:"Bitartrato de levarterenol"},
    {prodId:"51151709",label:"Fenilpropanolamina clorhidrato"},
    {prodId:"51151710",label:"Clorhidrato de fenilefrina"},
    {prodId:"51151711",label:"Sulfato de terbutalina"},
    {prodId:"51151712",label:"Clorhidrato de arbutamina"},
    {prodId:"51151713",label:"Clorhidrato de cinamedrina"},
    {prodId:"51151714",label:"Sulfato de d-anfetamina"},
    {prodId:"51151715",label:"Sulfato de efedrina"},
    {prodId:"51151716",label:"Clorhidrato de isoproterenol"},
    {prodId:"51151717",label:"Sulfato de mefentermina"},
    {prodId:"51151718",label:"Bitartrato de metaraminol"},
    {prodId:"51151719",label:"Pseudoefedrina"},
    {prodId:"51151720",label:"Xinafoato de salmeterol"},
    {prodId:"51151721",label:"Clorhidrato de midodrina"},
    {prodId:"51151722",label:"Clorhidrato de levalbuterol"},
    {prodId:"51151723",label:"Clorhidrato de tetrahidrozolina"},
    {prodId:"51151724",label:"Clorhidrato de nafazolina"},
    {prodId:"51151725",label:"Dipivefrina"},
    {prodId:"51151726",label:"Fenilpropanolamina"},
    {prodId:"51151727",label:"Norepinefrina bitartrato"},
    {prodId:"51151728",label:"Bromhidrato hidroxianfetamina"},
    {prodId:"51151730",label:"Epinefrina bitartrato"},
    {prodId:"51151731",label:"Sulfato de metaproterenol"},
    {prodId:"51151732",label:"Clorhidrato de dobutamina"},
    {prodId:"51151733",label:"Clorhidrato de dipivefrina"},
    {prodId:"51151734",label:"Sulfato de albuterol"},
    {prodId:"51151735",label:"Aspartato de anfetamina"},
    {prodId:"51151736",label:"Mesilato de isoetarina"},
    {prodId:"51151737",label:"Clorhidrato de dopamina"},
    {prodId:"51151738",label:"Fumarato de formoterol"},
    {prodId:"51151739",label:"Clorhidrato de metoxamina"},
    {prodId:"51151740",label:"Clorhidrato de isoetarina"},
    {prodId:"51151741",label:"Efedrina"},
    {prodId:"51151742",label:"Fenilefrina"},
    {prodId:"51151743",label:"Clorhidrato de pseudoefedrina"},
    {prodId:"51151744",label:"Hidrocloruro de efedrina"},
    {prodId:"51151745",label:"Tanato de pseudoefedrina"},
    {prodId:"51151746",label:"Sulfato de pseudoefedrina"},
    {prodId:"51151747",label:"Hidrocloruro de racepinephrine"},
    {prodId:"51151748",label:"Nafazolina"},
    {prodId:"51151749",label:"Dextroanfetamina"},
    {prodId:"51151750",label:"Arformoterol"},
    {prodId:"51151800",label:"Agentes bloqueadores adrenérgicos"},
    {prodId:"51151801",label:"Atenolol"},
    {prodId:"51151802",label:"Hidrocloruro de esmolol"},
    {prodId:"51151803",label:"Nadolol"},
    {prodId:"51151804",label:"Pindolol"},
    {prodId:"51151805",label:"Maleato de timolol"},
    {prodId:"51151810",label:"Mesilato de fentolamina"},
    {prodId:"51151811",label:"Hidrocloruro de yohimbina"},
    {prodId:"51151812",label:"Hidrocloruro de propranolol"},
    {prodId:"51151813",label:"Acebutolol"},
    {prodId:"51151814",label:"Hidrocloruro de betaxolol"},
    {prodId:"51151815",label:"Hidrocloruro de tolazolina"},
    {prodId:"51151816",label:"Hidrocloruro de prazosín"},
    {prodId:"51151817",label:"Hidrocloruro de tamsulosina"},
    {prodId:"51151818",label:"Hidrocloruro de carteolol"},
    {prodId:"51151819",label:"Hidrocloruro de terazosina"},
    {prodId:"51151820",label:"Hidrocloruro de fenoxibenzamina"},
    {prodId:"51151821",label:"Sulfato de penbutolol"},
    {prodId:"51151822",label:"Succinato de metoprolol"},
    {prodId:"51151823",label:"Hidrocloruro de labetalol"},
    {prodId:"51151824",label:"Mesilato de doxazosina"},
    {prodId:"51151825",label:"Hidrocloruro de dapiprazol"},
    {prodId:"51151900",label:"Relajantes músculo-esqueléticos de acción centrada"},
    {prodId:"51151901",label:"Baclofeno"},
    {prodId:"51151902",label:"Clorzoxazona"},
    {prodId:"51151903",label:"Dantroleno sódico"},
    {prodId:"51151904",label:"Metocarbamol"},
    {prodId:"51151905",label:"Carisoprodol"},
    {prodId:"51151907",label:"Clorhidrato de ritodrina"},
    {prodId:"51151908",label:"Citrato de orfenadrina"},
    {prodId:"51151910",label:"Nonivamida"},
    {prodId:"51151911",label:"Suxametonio"},
    {prodId:"51151912",label:"Tiocolchicósido"},
    {prodId:"51151913",label:"Clorfenesina carbamato"},
    {prodId:"51151914",label:"Clorhidrato de ciclobenzaprina"},
    {prodId:"51151915",label:"Metaxalona"},
    {prodId:"51151916",label:"Cloruro de succinilcolina"},
    {prodId:"51151917",label:"Clorhidrato de tizanidina"},
    {prodId:"51151918",label:"Alfuzosina"},
    {prodId:"51151919",label:"Hci cropiverina"},
    {prodId:"51151920",label:"Tolterodina"},
    {prodId:"51152000",label:"Agentes bloqueadores neuromusculares"},
    {prodId:"51152001",label:"Besilato de atracurio"},
    {prodId:"51152002",label:"Cloruro de mivacurio"},
    {prodId:"51152003",label:"Bromuro de rocuronio"},
    {prodId:"51152004",label:"Bromuro de vecuronio"},
    {prodId:"51152005",label:"Toxina botulínica"},
    {prodId:"51152006",label:"Cisatracurio"},
    {prodId:"51152007",label:"Cloruro de doxacurio"},
    {prodId:"51152008",label:"Yoduro de metocurina"},
    {prodId:"51152009",label:"Bromuro de pancuronio"},
    {prodId:"51152010",label:"Bromuro de rapacuronio"},
    {prodId:"51152011",label:"Cloruro de tubocurarina"},
    {prodId:"51152012",label:"Bromuro de pipecuronio"},
    {prodId:"51161500",label:"Antiasmáticos"},
    {prodId:"51161501",label:"Mesilato de bitolterol"},
    {prodId:"51161502",label:"Nedocromil sódico"},
    {prodId:"51161503",label:"Acetato de pirbuterol"},
    {prodId:"51161504",label:"Aminofilina"},
    {prodId:"51161505",label:"Teofilina"},
    {prodId:"51161506",label:"Terbutalina"},
    {prodId:"51161507",label:"Formoterol"},
    {prodId:"51161508",label:"Sulfato de salbutamol"},
    {prodId:"51161510",label:"Teofilina"},
    {prodId:"51161511",label:"Cromoglicato de sodio"},
    {prodId:"51161513",label:"Difilina"},
    {prodId:"51161514",label:"Oxtrifilina"},
    {prodId:"51161515",label:"Montelukast sódico"},
    {prodId:"51161516",label:"Zafirlukast"},
    {prodId:"51161517",label:"Zileutón"},
    {prodId:"51161518",label:"Hidrato de pranlukast"},
    {prodId:"51161519",label:"Carmoterol"},
    {prodId:"51161520",label:"Ciclesonida"},
    {prodId:"51161521",label:"Cilomilast"},
    {prodId:"51161522",label:"Tulobuterol"},
    {prodId:"51161523",label:"Clorhidrato de reproterol"},
    {prodId:"51161524",label:"Bromuro de tiotropio"},
    {prodId:"51161525",label:"Beclometasona"},
    {prodId:"51161600",label:"Antihistamínicos (bloqueadores H1)"},
    {prodId:"51161601",label:"Astemizol"},
    {prodId:"51161602",label:"Fumarato de clemastina"},
    {prodId:"51161603",label:"Maleato de dexclorfeniramina"},
    {prodId:"51161605",label:"Clorhidrato de levocabastina"},
    {prodId:"51161606",label:"Loratadina"},
    {prodId:"51161607",label:"Terfenadina"},
    {prodId:"51161608",label:"Clorhidrato de betahistina"},
    {prodId:"51161609",label:"Hidrocloruro de triprolidina"},
    {prodId:"51161610",label:"Prometazina"},
    {prodId:"51161611",label:"Oxomemazina"},
    {prodId:"51161612",label:"Ebastina"},
    {prodId:"51161613",label:"Mesilato de dihidroergotoxina"},
    {prodId:"51161614",label:"Clorhidrato de meclozina"},
    {prodId:"51161615",label:"Cetirizina"},
    {prodId:"51161616",label:"Betahistina"},
    {prodId:"51161617",label:"Clorhidrato de buclicina"},
    {prodId:"51161618",label:"Alimemazina"},
    {prodId:"51161619",label:"Mepiramina maleato"},
    {prodId:"51161620",label:"Difenhidramina"},
    {prodId:"51161621",label:"Clorhidrato de fexofenadina"},
    {prodId:"51161622",label:"Cromoglicato de sodio"},
    {prodId:"51161623",label:"Hidroxizina pamoato"},
    {prodId:"51161624",label:"Maleato de  azatadina"},
    {prodId:"51161625",label:"Azelastina hidrocloruro"},
    {prodId:"51161626",label:"Bosentán"},
    {prodId:"51161627",label:"Maleato de bromfeniramina"},
    {prodId:"51161628",label:"Maleato de carbinoxamina"},
    {prodId:"51161629",label:"Hidrocloruro de cetirizina"},
    {prodId:"51161630",label:"Maleato de clorfenamina"},
    {prodId:"51161631",label:"Tanato de clorfenamina"},
    {prodId:"51161632",label:"Clorhidrato de ciproheptadina"},
    {prodId:"51161633",label:"Desloratadina"},
    {prodId:"51161634",label:"Maleato de dexbromfeniramina"},
    {prodId:"51161635",label:"Clorhidrato de difenhidramina"},
    {prodId:"51161636",label:"Tanato de difenhidramina"},
    {prodId:"51161637",label:"Clorhidrato de hidroxizina"},
    {prodId:"51161638",label:"Fumarato de ketotifeno"},
    {prodId:"51161639",label:"Clorhidrato de olopatadina"},
    {prodId:"51161640",label:"Tartrato de fenindamina"},
    {prodId:"51161646",label:"Acrivastina"},
    {prodId:"51161647",label:"Bromfeniramina"},
    {prodId:"51161648",label:"Difumarato de emedastina"},
    {prodId:"51161649",label:"Lodoxamida trometamina"},
    {prodId:"51161650",label:"Clorhidrato de prometazina"},
    {prodId:"51161651",label:"Potasio de pemirolast"},
    {prodId:"51161652",label:"Mizolastina"},
    {prodId:"51161653",label:"Clorhidrato de epinastina"},
    {prodId:"51161654",label:"Levocetirizina dihidrocloruro"},
    {prodId:"51161655",label:"Levocetirizina hemihidrato"},
    {prodId:"51161700",label:"Medicamentos para alteraciones del tracto respiratorio"},
    {prodId:"51161701",label:"Acetilcisteína"},
    {prodId:"51161702",label:"Beractant"},
    {prodId:"51161703",label:"Budesonida"},
    {prodId:"51161704",label:"Palmitato de colfoscerilo"},
    {prodId:"51161705",label:"Bromuro de ipratropio"},
    {prodId:"51161706",label:"Sobrerol"},
    {prodId:"51161707",label:"Clorhidrato de bamifilina"},
    {prodId:"51161708",label:"Dornasa alfa"},
    {prodId:"51161709",label:"Poractant alfa"},
    {prodId:"51161710",label:"Calfactant"},
    {prodId:"51161800",label:"Productos para tos, resfriados y antialérgicos"},
    {prodId:"51161801",label:"Benzonatato"},
    {prodId:"51161802",label:"Guaifenesina"},
    {prodId:"51161803",label:"Mentol"},
    {prodId:"51161805",label:"Carbocisteína"},
    {prodId:"51161806",label:"Cloruro de amonio"},
    {prodId:"51161808",label:"Dextrometorfano"},
    {prodId:"51161809",label:"Citrato de oxolamina"},
    {prodId:"51161810",label:"Folcodina"},
    {prodId:"51161811",label:"Bromhexina"},
    {prodId:"51161812",label:"Combinación de acetaminofen y clorfeniramina"},
    {prodId:"51161813",label:"Guayacolsulfonato de potasio"},
    {prodId:"51161814",label:"Bitartrato de fenilpropanolamina"},
    {prodId:"51161815",label:"Tanato de carbetapentano"},
    {prodId:"51161817",label:"Clorhidrato de dextrometorfano"},
    {prodId:"51161818",label:"Dextrometorfano polistirex"},
    {prodId:"51161819",label:"Tanato de dextrometorfano"},
    {prodId:"51161820",label:"Aceite de eucalipto o eucaliptol"},
    {prodId:"51161900",label:"Descongestionantes nasales"},
    {prodId:"51161901",label:"Clorhidrato de oximetazolina"},
    {prodId:"51161903",label:"Clorhidrato de xilometazolina"},
    {prodId:"51171500",label:"Antiácidos y antiflatulentos"},
    {prodId:"51171501",label:"Carbonato de calcio"},
    {prodId:"51171502",label:"Magaldrato"},
    {prodId:"51171503",label:"Hidróxido de magnesio"},
    {prodId:"51171504",label:"Antiácidos de bicarbonato de sodio"},
    {prodId:"51171505",label:"Simeticona"},
    {prodId:"51171507",label:"Hidrotalcita"},
    {prodId:"51171508",label:"Carbonato de magnesio"},
    {prodId:"51171509",label:"Clorhidrato de betaína"},
    {prodId:"51171510",label:"Trisilicato de magnesio"},
    {prodId:"51171511",label:"Hidróxido de aluminio"},
    {prodId:"51171513",label:"Dihidroxialuminio-carbonato de sodio"},
    {prodId:"51171600",label:"Laxantes"},
    {prodId:"51171601",label:"Cáscara sagrada"},
    {prodId:"51171602",label:"Docusato de calcio"},
    {prodId:"51171603",label:"Docusato potásico"},
    {prodId:"51171604",label:"Docusato de sodio"},
    {prodId:"51171605",label:"Lactulosa"},
    {prodId:"51171606",label:"Sulfato de magnesio"},
    {prodId:"51171607",label:"Mucílago hidrófilo del psilio"},
    {prodId:"51171608",label:"Glicerina"},
    {prodId:"51171609",label:"Ácido dehidrocólico"},
    {prodId:"51171610",label:"Sen o senósidos"},
    {prodId:"51171611",label:"Estimulante bisacodilo"},
    {prodId:"51171612",label:"Metilcelulosa"},
    {prodId:"51171613",label:"Aloína"},
    {prodId:"51171614",label:"Bisacodyl"},
    {prodId:"51171615",label:"Calcio policarbofilo"},
    {prodId:"51171616",label:"Casantranol"},
    {prodId:"51171617",label:"Aceite de ricino"},
    {prodId:"51171618",label:"Monohidrato de cisaprida"},
    {prodId:"51171619",label:"Supositorios de glicerina"},
    {prodId:"51171620",label:"Citrato de magnesio"},
    {prodId:"51171621",label:"Clorhidrato de metoclopramida"},
    {prodId:"51171622",label:"Fosfato de sodio"},
    {prodId:"51171623",label:"Fenolftaleína"},
    {prodId:"51171624",label:"Semilla de psyllium"},
    {prodId:"51171626",label:"Bitartrato de potasio"},
    {prodId:"51171627",label:"Tartrato de sodio y potasio"},
    {prodId:"51171628",label:"Fosfato de potasio"},
    {prodId:"51171629",label:"Sulfato de sodio"},
    {prodId:"51171630",label:"Aceite mineral"},
    {prodId:"51171631",label:"Polietilenglicol laxante"},
    {prodId:"51171632",label:"Cloruro de magnesio"},
    {prodId:"51171633",label:"Lubiprostona"},
    {prodId:"51171700",label:"Antidiarréicos"},
    {prodId:"51171701",label:"Clorhidrato de difenoxina"},
    {prodId:"51171702",label:"Hidroclorato de loperamida"},
    {prodId:"51171703",label:"Paregorico"},
    {prodId:"51171704",label:"Nifuroxazida"},
    {prodId:"51171706",label:"Atapulgita"},
    {prodId:"51171707",label:"Subsalicilato de bismuto"},
    {prodId:"51171708",label:"Clorhidrato de difenoxilato"},
    {prodId:"51171709",label:"Saccharomyces boulardii"},
    {prodId:"51171711",label:"Caolín y pectina"},
    {prodId:"51171712",label:"Pectina  purificada con acidophilus"},
    {prodId:"51171800",label:"Agentes antivértigo, antinauseantes y antieméticos"},
    {prodId:"51171801",label:"Dronabinol"},
    {prodId:"51171802",label:"Clorhidrato de granisetrón"},
    {prodId:"51171803",label:"Clorhidrato de meclizina"},
    {prodId:"51171804",label:"Clorhidrato de ondansetrón"},
    {prodId:"51171805",label:"Clorhidrato de trimetobenzamida"},
    {prodId:"51171806",label:"Metoclopramida"},
    {prodId:"51171807",label:"Cinarizina"},
    {prodId:"51171808",label:"Clorhidrato de difenidol"},
    {prodId:"51171809",label:"Ciclizina"},
    {prodId:"51171811",label:"Combinacion de dextrosa fructosa y ácido fosfórico"},
    {prodId:"51171812",label:"Proclorperazina"},
    {prodId:"51171813",label:"Metopimazina"},
    {prodId:"51171814",label:"Mesilato de dolasetrón"},
    {prodId:"51171815",label:"Clorhidrato de ciclizina"},
    {prodId:"51171816",label:"Ondansetrón"},
    {prodId:"51171817",label:"Edisilato de proclorperazina"},
    {prodId:"51171818",label:"Maleato de proclorperazina"},
    {prodId:"51171819",label:"Maleato de tietilperacina"},
    {prodId:"51171820",label:"Dimenhidrinato"},
    {prodId:"51171821",label:"Aprepitant"},
    {prodId:"51171822",label:"Clorhidrato de palonosetrón"},
    {prodId:"51171823",label:"Granisetrón  base"},
    {prodId:"51171900",label:"Fármacos antiúlcera y otros fármacos gastrointestinales (GI) relacionados"},
    {prodId:"51171901",label:"Cimetidina"},
    {prodId:"51171902",label:"Famotidina"},
    {prodId:"51171903",label:"Nizatidina"},
    {prodId:"51171904",label:"Clorhidrato de ranitidina"},
    {prodId:"51171905",label:"Cisapride"},
    {prodId:"51171906",label:"Lansoprazol"},
    {prodId:"51171907",label:"Clordiazepóxido clorhidrato o combinación de bromuro clinidio"},
    {prodId:"51171908",label:"Misoprostol"},
    {prodId:"51171909",label:"Omeprazol"},
    {prodId:"51171910",label:"Pancreatina"},
    {prodId:"51171911",label:"Sucralfato"},
    {prodId:"51171912",label:"Clorhidrato de cimetidina"},
    {prodId:"51171913",label:"Esomeprazol magnesico  trihidrato"},
    {prodId:"51171914",label:"Pantoprazol sódico sesquihidratado"},
    {prodId:"51171915",label:"Pantoprazol sódico"},
    {prodId:"51171916",label:"Rabeprazol sódico"},
    {prodId:"51171917",label:"Citrato de bismuto ranitidina"},
    {prodId:"51171918",label:"Carbonato de aluminio"},
    {prodId:"51171919",label:"Amlexanox"},
    {prodId:"51171920",label:"Pantopra"},
    {prodId:"51171921",label:"Doramectina"},
    {prodId:"51171922",label:"Maleato de tegaserod"},
    {prodId:"51171923",label:"Racecadotrilo"},
    {prodId:"51171924",label:"Ramosetron"},
    {prodId:"51171925",label:"Rebamipida"},
    {prodId:"51171926",label:"Sevelamer"},
    {prodId:"51172000",label:"Medicamentos para enfermedades de la vesícula biliar"},
    {prodId:"51172001",label:"Quenodiol"},
    {prodId:"51172002",label:"Monoctanoina"},
    {prodId:"51172003",label:"Ursodiol"},
    {prodId:"51172004",label:"Dimetilsulfóxido"},
    {prodId:"51172100",label:"Antiespasmódicos"},
    {prodId:"51172101",label:"Clorhidrato de mebeverina"},
    {prodId:"51172102",label:"Clorhidrato de dicicloverina"},
    {prodId:"51172103",label:"Clorhidrato de pitofenona"},
    {prodId:"51172105",label:"Floroglucinol"},
    {prodId:"51172106",label:"Clorhidrato de oxibutinina"},
    {prodId:"51172107",label:"Butilbromuro de hioscina"},
    {prodId:"51172108",label:"Clorhidrato de viquidil"},
    {prodId:"51172109",label:"Trimebutina"},
    {prodId:"51172110",label:"Clorhidrato de prozapina"},
    {prodId:"51172111",label:"Alverina"},
    {prodId:"51181500",label:"Agentes antidiabéticos y agentes hiperglicémicos"},
    {prodId:"51181501",label:"Acarbosa"},
    {prodId:"51181502",label:"Acetohexamida"},
    {prodId:"51181503",label:"Clorpropamida"},
    {prodId:"51181504",label:"Glimepirida"},
    {prodId:"51181505",label:"Glipizida"},
    {prodId:"51181506",label:"Insulina"},
    {prodId:"51181508",label:"Glucagón"},
    {prodId:"51181509",label:"Gliclazida"},
    {prodId:"51181510",label:"Clorhidrato de fenformina"},
    {prodId:"51181511",label:"Buformina"},
    {prodId:"51181513",label:"Nateglinida"},
    {prodId:"51181514",label:"Extracto de páncreas"},
    {prodId:"51181515",label:"Tolbutamida"},
    {prodId:"51181516",label:"Glibenclamida o gliburida"},
    {prodId:"51181517",label:"Hidrocloruro de metformina"},
    {prodId:"51181519",label:"Miglitol"},
    {prodId:"51181520",label:"Clorhidrato de pioglitazona"},
    {prodId:"51181521",label:"Repaglinida"},
    {prodId:"51181522",label:"Maleato de rosiglitazona"},
    {prodId:"51181523",label:"Tolazamida"},
    {prodId:"51181524",label:"Troglitazona"},
    {prodId:"51181525",label:"Mosaprida"},
    {prodId:"51181526",label:"Epalrestat"},
    {prodId:"51181527",label:"Voglibosa"},
    {prodId:"51181528",label:"Exenatida"},
    {prodId:"51181600",label:"Medicamentos tiroideas y antitiroideas"},
    {prodId:"51181601",label:"Levotiroxina sódica"},
    {prodId:"51181602",label:"Liotironina sódica"},
    {prodId:"51181603",label:"Liotrix"},
    {prodId:"51181604",label:"Tirotropina"},
    {prodId:"51181605",label:"Metimazol"},
    {prodId:"51181606",label:"Propiltiouracilo"},
    {prodId:"51181607",label:"Yoduro de potasio"},
    {prodId:"51181608",label:"Levotiroxina"},
    {prodId:"51181609",label:"Yoduro de sodio"},
    {prodId:"51181700",label:"Corticosteroides"},
    {prodId:"51181701",label:"Betametasona"},
    {prodId:"51181702",label:"Corticotropina"},
    {prodId:"51181703",label:"Cosintropina"},
    {prodId:"51181704",label:"Dexametasona"},
    {prodId:"51181705",label:"Flunisolida"},
    {prodId:"51181706",label:"Hidrocortisona"},
    {prodId:"51181707",label:"Metilprednisolona"},
    {prodId:"51181708",label:"Prednisolona"},
    {prodId:"51181709",label:"Triamcinolona"},
    {prodId:"51181710",label:"Desoximetasona"},
    {prodId:"51181711",label:"Meprednisona"},
    {prodId:"51181712",label:"Ácido risedrónico"},
    {prodId:"51181713",label:"Prednisona"},
    {prodId:"51181714",label:"Prednicarbato"},
    {prodId:"51181715",label:"Cortisona"},
    {prodId:"51181716",label:"Cortivazol"},
    {prodId:"51181717",label:"Acetato de desoxicortona"},
    {prodId:"51181718",label:"Deflazacort"},
    {prodId:"51181719",label:"Acetónido de fluocinolona"},
    {prodId:"51181720",label:"Diacetato de diflorasona"},
    {prodId:"51181721",label:"Corticorelina ovina triflutato"},
    {prodId:"51181722",label:"Fluticasona"},
    {prodId:"51181723",label:"Acetato de hidrocortisona"},
    {prodId:"51181724",label:"Hidrocortisona buteprato"},
    {prodId:"51181725",label:"Butirato de hidrocortisona"},
    {prodId:"51181726",label:"Fosfato sódico de hidrocortisona"},
    {prodId:"51181727",label:"Valerato de hidrocortisona"},
    {prodId:"51181728",label:"Acetato de metilprednisolona"},
    {prodId:"51181729",label:"Succinato sódico de metilprednisolona"},
    {prodId:"51181730",label:"Acetato de prednisolona"},
    {prodId:"51181731",label:"Acetato de triamcinolona"},
    {prodId:"51181732",label:"Diacetato de triamcinolona"},
    {prodId:"51181733",label:"Hexacetonido de triamcinolona"},
    {prodId:"51181734",label:"Dipropionato de alclometasona"},
    {prodId:"51181735",label:"Propionato de halobetasol"},
    {prodId:"51181736",label:"Fluorometolona"},
    {prodId:"51181737",label:"Halcinonida"},
    {prodId:"51181738",label:"Acetato de fludrocortisona"},
    {prodId:"51181739",label:"Rimexolona"},
    {prodId:"51181740",label:"Fosfato sódico de dexametasona"},
    {prodId:"51181741",label:"Loteprednol etabonato"},
    {prodId:"51181742",label:"Propionato de clobetasol"},
    {prodId:"51181743",label:"Pivalato de clocortolona"},
    {prodId:"51181744",label:"Fluocinonida"},
    {prodId:"51181745",label:"Flurandrenolida"},
    {prodId:"51181746",label:"Amcinonida"},
    {prodId:"51181747",label:"Probutato de hidrocortisona"},
    {prodId:"51181748",label:"Medrisona"},
    {prodId:"51181749",label:"Furoato de mometasona"},
    {prodId:"51181750",label:"Desonida"},
    {prodId:"51181751",label:"Fosfato sódico de prednisolona"},
    {prodId:"51181752",label:"Dipropionato de beclometasona"},
    {prodId:"51181753",label:"Acetato de cortisona"},
    {prodId:"51181754",label:"Dipropionato de betametasona"},
    {prodId:"51181755",label:"Valerato de betametasona"},
    {prodId:"51181756",label:"Acetato de melengestrol"},
    {prodId:"51181800",label:"Estrógenos, progesteronas y anticonceptivos internos"},
    {prodId:"51181801",label:"Clorotrianiseno"},
    {prodId:"51181802",label:"Estrona"},
    {prodId:"51181803",label:"Estrógenos conjugados"},
    {prodId:"51181804",label:"Estropipato"},
    {prodId:"51181805",label:"Levonorgestrel"},
    {prodId:"51181806",label:"Acetato de megestrol"},
    {prodId:"51181807",label:"Etinilestradiol"},
    {prodId:"51181808",label:"Mestranol"},
    {prodId:"51181810",label:"Acetato de clormadinona"},
    {prodId:"51181811",label:"Desogestrel y etinilestradiol"},
    {prodId:"51181812",label:"Dienestrol"},
    {prodId:"51181813",label:"Estradiol"},
    {prodId:"51181814",label:"Etisterona"},
    {prodId:"51181815",label:"Estradiol y acetato de noretisterona"},
    {prodId:"51181816",label:"Hidroxiestrona diacetato"},
    {prodId:"51181817",label:"Promestrieno"},
    {prodId:"51181818",label:"Progesterona"},
    {prodId:"51181819",label:"Promegestona"},
    {prodId:"51181820",label:"Gestrinona"},
    {prodId:"51181821",label:"Cipionato de estradiol"},
    {prodId:"51181822",label:"Valerato de estradiol"},
    {prodId:"51181823",label:"Estrógenos esterificados"},
    {prodId:"51181824",label:"Diacetato de etinodiol"},
    {prodId:"51181825",label:"Dietilestilbestrol"},
    {prodId:"51181826",label:"Caproato de hidroxiprogesterona"},
    {prodId:"51181827",label:"Acetato de medroxiprogesterona"},
    {prodId:"51181828",label:"Noretindrona"},
    {prodId:"51181829",label:"Acetato de noretindrona"},
    {prodId:"51181830",label:"Norgestimato"},
    {prodId:"51181831",label:"Norgestrel"},
    {prodId:"51181832",label:"Etonogestrel y etinil estradiol"},
    {prodId:"51181833",label:"Etinil-estradiol y diacetato de etinodiol"},
    {prodId:"51181834",label:"Noretindrona y  etinil estradiol"},
    {prodId:"51181835",label:"Altrenogest"},
    {prodId:"51181836",label:"Gestodeno"},
    {prodId:"51181900",label:"Hormonas gonadotrópicas y estimulantes e inhibidores ováricos"},
    {prodId:"51181901",label:"Gonadotropina coriónica"},
    {prodId:"51181902",label:"Danazol"},
    {prodId:"51181903",label:"Acetato de gonadorelina"},
    {prodId:"51181904",label:"Acetato de histrelina"},
    {prodId:"51181905",label:"Menotropinas"},
    {prodId:"51181906",label:"Urofolitropina"},
    {prodId:"51181908",label:"Fosfestrol de sodio"},
    {prodId:"51181911",label:"Citrato de clomifeno"},
    {prodId:"51181912",label:"Folitropina"},
    {prodId:"51181913",label:"Clorhidrato de gonadorelina"},
    {prodId:"51181914",label:"Linestrenol"},
    {prodId:"51182000",label:"Andrógenos e inhibidores androgénicos"},
    {prodId:"51182001",label:"Finasterida"},
    {prodId:"51182002",label:"Testosterona"},
    {prodId:"51182003",label:"Cipionato de testosterona"},
    {prodId:"51182004",label:"Enantato de testosterona"},
    {prodId:"51182005",label:"Propionato de testosterona"},
    {prodId:"51182006",label:"Acetato de trembolona"},
    {prodId:"51182007",label:"Metandriol"},
    {prodId:"51182008",label:"Fluoximesterona"},
    {prodId:"51182009",label:"Metiltestosterona"},
    {prodId:"51182010",label:"Decanoato de nandrolona"},
    {prodId:"51182011",label:"Fenilpropionato de nandrolona"},
    {prodId:"51182012",label:"Prasterona"},
    {prodId:"51182013",label:"Stanozolol"},
    {prodId:"51182014",label:"Dutasteride"},
    {prodId:"51182100",label:"Hormonas de la pituitaria posterior"},
    {prodId:"51182101",label:"Acetato de desmopresina"},
    {prodId:"51182102",label:"Vasopresina"},
    {prodId:"51182200",label:"Medicamentos para la inducción del parto"},
    {prodId:"51182201",label:"Dinoprostona"},
    {prodId:"51182202",label:"Maleato de metilergonovina"},
    {prodId:"51182203",label:"Oxitocina"},
    {prodId:"51182204",label:"Maleato de ergonovina"},
    {prodId:"51182300",label:"Hormonas del crecimiento y sus inhibidores"},
    {prodId:"51182301",label:"Somatrem"},
    {prodId:"51182302",label:"Somatropina"},
    {prodId:"51182303",label:"Somatostatina"},
    {prodId:"51182304",label:"Acetato de octreotida"},
    {prodId:"51182400",label:"Sales de calcio y reguladores del calcio"},
    {prodId:"51182401",label:"Cloruro de calcio"},
    {prodId:"51182403",label:"Gluconato de calcio"},
    {prodId:"51182404",label:"Lactato de calcio"},
    {prodId:"51182405",label:"Fosfato de calcio dibase"},
    {prodId:"51182406",label:"Alendronato sódico"},
    {prodId:"51182407",label:"Calcitonina"},
    {prodId:"51182408",label:"Fosfato  sódico de celulosa"},
    {prodId:"51182409",label:"Nitrato de galio"},
    {prodId:"51182410",label:"Etidronato disódico"},
    {prodId:"51182411",label:"Nandrolona"},
    {prodId:"51182412",label:"Fosfato calcio tribásico"},
    {prodId:"51182413",label:"Calcio fosfato de glicerol"},
    {prodId:"51182415",label:"Ácido zoledrónico"},
    {prodId:"51182416",label:"Pamidronato disódico"},
    {prodId:"51182417",label:"Risedronato de sodio"},
    {prodId:"51182418",label:"Acetato de calcio"},
    {prodId:"51182419",label:"Glucoheptonato de calcio  o gluceptato de calcio"},
    {prodId:"51182420",label:"Dihidrotaquisterol"},
    {prodId:"51182421",label:"Nitrendipino"},
    {prodId:"51182422",label:"Benidipina hcl"},
    {prodId:"51182423",label:"Ibandronato"},
    {prodId:"51182424",label:"Raloxifeno hcl"},
    {prodId:"51191500",label:"Diuréticos"},
    {prodId:"51191501",label:"Clorotiazida"},
    {prodId:"51191502",label:"Clortalidona"},
    {prodId:"51191503",label:"Metolazona"},
    {prodId:"51191504",label:"Bumetanida"},
    {prodId:"51191505",label:"Etacrinato de sodio"},
    {prodId:"51191506",label:"Clorhidrato de amilorida"},
    {prodId:"51191507",label:"Espironolactona"},
    {prodId:"51191508",label:"Triamtereno"},
    {prodId:"51191509",label:"Manitol"},
    {prodId:"51191510",label:"Furosemida"},
    {prodId:"51191511",label:"Canrenoato de potasio"},
    {prodId:"51191512",label:"Lasitone"},
    {prodId:"51191513",label:"Bendroflumetiazida"},
    {prodId:"51191514",label:"Ácido etacrínico"},
    {prodId:"51191515",label:"Hidroclorotiazida"},
    {prodId:"51191516",label:"Hidroflumetiazida"},
    {prodId:"51191517",label:"Isosorbide"},
    {prodId:"51191518",label:"Meticlotiazida"},
    {prodId:"51191519",label:"Pamabrom"},
    {prodId:"51191520",label:"Politiazida"},
    {prodId:"51191521",label:"Torasemida"},
    {prodId:"51191522",label:"Triclormetiazida"},
    {prodId:"51191523",label:"Diurético osmótico urea"},
    {prodId:"51191600",label:"Electrolitos"},
    {prodId:"51191601",label:"Dextrosa"},
    {prodId:"51191602",label:"Electrolitos de cloruro de sodio"},
    {prodId:"51191603",label:"Alimentación parenteral total o soluciones apt nutricionales"},
    {prodId:"51191604",label:"Solución ringer lactato"},
    {prodId:"51191605",label:"Agua estéril para inyección"},
    {prodId:"51191606",label:"Cloruro cúprico"},
    {prodId:"51191700",label:"Agentes alcalinizadores"},
    {prodId:"51191701",label:"Trometamina"},
    {prodId:"51191702",label:"Lactato de sodio"},
    {prodId:"51191703",label:"Acetato de sodio"},
    {prodId:"51191704",label:"Soluciones electrolíticas múltiples"},
    {prodId:"51191705",label:"Combinación de ácido cítrico y citrato de potasio"},
    {prodId:"51191706",label:"Combinación de ácido cítrico y citrato de sodio"},
    {prodId:"51191800",label:"Sales de potasio"},
    {prodId:"51191801",label:"Bicarbonato de potasio"},
    {prodId:"51191802",label:"Cloruro de potasio"},
    {prodId:"51191803",label:"Gluconato de potasio"},
    {prodId:"51191804",label:"Acetato de potasio"},
    {prodId:"51191805",label:"Suplemento de potasio"},
    {prodId:"51191900",label:"Suplementos dietéticos y productos de terapia alimenticia"},
    {prodId:"51191901",label:"Residuo de germen de maíz"},
    {prodId:"51191902",label:"Suplementos de amino ácidos"},
    {prodId:"51191903",label:"Pentermina"},
    {prodId:"51191904",label:"Fosfolípidos"},
    {prodId:"51191905",label:"Suplementos vitamínicos"},
    {prodId:"51191906",label:"Solución de rehidratación oral"},
    {prodId:"51191907",label:"Amonio molibdato"},
    {prodId:"51191908",label:"Orlistat"},
    {prodId:"51191909",label:"Tiamina"},
    {prodId:"51191910",label:"Calcitriol"},
    {prodId:"51191911",label:"Retinol"},
    {prodId:"51201500",label:"Inmunodepresores"},
    {prodId:"51201501",label:"Azatioprina"},
    {prodId:"51201502",label:"Ciclosporina"},
    {prodId:"51201503",label:"Mofetilo micofenolato"},
    {prodId:"51201504",label:"Tacrolimus"},
    {prodId:"51201505",label:"Antilinfocitos"},
    {prodId:"51201506",label:"Defibrotide"},
    {prodId:"51201507",label:"Aldesleukina"},
    {prodId:"51201508",label:"Basiliximab"},
    {prodId:"51201509",label:"Daclizumab"},
    {prodId:"51201510",label:"Imiquimod"},
    {prodId:"51201511",label:"Muromonab cd3"},
    {prodId:"51201512",label:"Clorhidrato de micofenolato de mofetilo"},
    {prodId:"51201513",label:"Peginterferón"},
    {prodId:"51201514",label:"Pimecrolimus"},
    {prodId:"51201515",label:"Sirolimus"},
    {prodId:"51201516",label:"Antilinfocito o inmunoglobulina linfocítica"},
    {prodId:"51201517",label:"Hidrocloruro de guanidina"},
    {prodId:"51201518",label:"Azatioprina sódica"},
    {prodId:"51201519",label:"Alefacept"},
    {prodId:"51201600",label:"Vacunas, antígenos y toxoides"},
    {prodId:"51201601",label:"Antígeno de ántrax"},
    {prodId:"51201602",label:"Antígeno brucélico"},
    {prodId:"51201603",label:"Vacuna contra el cólera"},
    {prodId:"51201604",label:"Difteria"},
    {prodId:"51201605",label:"Vacuna contra el virus de la encefalitis"},
    {prodId:"51201606",label:"Influenza hemofílica"},
    {prodId:"51201607",label:"Vacuna contra el virus de hepatitis b"},
    {prodId:"51201608",label:"Vacuna contra el virus de la influenza"},
    {prodId:"51201609",label:"Vacuna contra el virus del sarampión"},
    {prodId:"51201610",label:"Vacuna meningococo"},
    {prodId:"51201611",label:"Morbillivirus"},
    {prodId:"51201612",label:"Vacuna contra el virus de las paperas"},
    {prodId:"51201613",label:"Parotiditis"},
    {prodId:"51201614",label:"Vacuna contra la pertussis"},
    {prodId:"51201615",label:"Vacuna contra el neumococo"},
    {prodId:"51201616",label:"Vacuna contra el virus del polio"},
    {prodId:"51201617",label:"Vacuna contra la rabia"},
    {prodId:"51201618",label:"Rotavirus"},
    {prodId:"51201619",label:"Vacuna contra el virus de la rubeola"},
    {prodId:"51201620",label:"Viruela"},
    {prodId:"51201621",label:"Toxoide tetánico"},
    {prodId:"51201622",label:"Tuberculosis"},
    {prodId:"51201623",label:"Vacuna contra el tifo"},
    {prodId:"51201624",label:"Vacuna contra el virus de la varicela"},
    {prodId:"51201625",label:"Vacuna contra la fiebre amarilla"},
    {prodId:"51201626",label:"Hepatitis a"},
    {prodId:"51201627",label:"Vacuna contra la hemofilia b"},
    {prodId:"51201628",label:"Vacuna contra los virus del sarampión, paperas y rubeola"},
    {prodId:"51201629",label:"Difteria y toxoide tetánico adsorbido"},
    {prodId:"51201631",label:"Vacuna contra difteria y toxoide tetánico y pertussis acelular"},
    {prodId:"51201632",label:"Difteria y tétano y pertussis celular"},
    {prodId:"51201633",label:"Influenza b hemofílica con difteria y tétano y pertussis acelular"},
    {prodId:"51201634",label:"Influenza b hemofílica con difteria y tétano y pertussis celular conjugadas"},
    {prodId:"51201635",label:"Vacuna contra el virus de la hepatitis a"},
    {prodId:"51201636",label:"Vacuna contra la enfermedad de lyme (borreliosis)"},
    {prodId:"51201638",label:"Vacuna contra la plaga"},
    {prodId:"51201639",label:"Vacuna contra el estafilococo"},
    {prodId:"51201646",label:"Vacuna contra el virus del sarampión y la rubeola"},
    {prodId:"51201647",label:"Vacuna bcg"},
    {prodId:"51201648",label:"Vacuna contra el virus de la rubeola y las paperas"},
    {prodId:"51201649",label:"Vacuna contra el virus del papiloma humano"},
    {prodId:"51201700",label:"Vacunas aviares"},
    {prodId:"51201701",label:"Hipocruposis"},
    {prodId:"51201702",label:"E coli"},
    {prodId:"51201703",label:"Gumboro"},
    {prodId:"51201704",label:"Bronquitis infecciosa aviar"},
    {prodId:"51201705",label:"Newcastle"},
    {prodId:"51201800",label:"Agentes inmunoestimulantes"},
    {prodId:"51201801",label:"Inmunoglobulinas bacterianas"},
    {prodId:"51201802",label:"Filgrastim"},
    {prodId:"51201803",label:"Pelfigrastim"},
    {prodId:"51201804",label:"Sargramostim"},
    {prodId:"51201805",label:"Inmunoglobulina rho d"},
    {prodId:"51201806",label:"Inmunoglobulina o gamma igg"},
    {prodId:"51201807",label:"Inmunoglobulinas virales"},
    {prodId:"51201808",label:"Clorhidrato levamisol"},
    {prodId:"51201809",label:"Interferon"},
    {prodId:"51201810",label:"Galsulfasa"},
    {prodId:"51201811",label:"Talidomida"},
    {prodId:"51201900",label:"Agentes de la esclerosis múltiple (EM)"},
    {prodId:"51201901",label:"Acetato de glatiramer"},
    {prodId:"51211500",label:"Medicamentos antigota"},
    {prodId:"51211501",label:"Alopurinol"},
    {prodId:"51211502",label:"Colchicina"},
    {prodId:"51211503",label:"Probenecid"},
    {prodId:"51211504",label:"Sulfinpirazona"},
    {prodId:"51211505",label:"Benzbromarona"},
    {prodId:"51211600",label:"Antídotos y eméticos"},
    {prodId:"51211601",label:"Deferoxamina mesilato"},
    {prodId:"51211602",label:"Dexrazoxano"},
    {prodId:"51211603",label:"Digoxina inmune fab"},
    {prodId:"51211604",label:"Dimercaprol"},
    {prodId:"51211605",label:"Edetato disódico"},
    {prodId:"51211606",label:"Flumazenil"},
    {prodId:"51211607",label:"Ipecacuana"},
    {prodId:"51211608",label:"Penicilamina"},
    {prodId:"51211609",label:"Protamina sulfato"},
    {prodId:"51211610",label:"Sulfonato poliestireno sódico"},
    {prodId:"51211611",label:"Trientina"},
    {prodId:"51211612",label:"Leucovorina"},
    {prodId:"51211613",label:"Sorbitol"},
    {prodId:"51211615",label:"Azul de metileno"},
    {prodId:"51211616",label:"Anti venenos"},
    {prodId:"51211617",label:"Leucovorina cálcica"},
    {prodId:"51211618",label:"Antídoto de carbón activado"},
    {prodId:"51211619",label:"Edetato cálcico disódico"},
    {prodId:"51211620",label:"Fomepizol"},
    {prodId:"51211621",label:"Clorhidrato pralidoxima"},
    {prodId:"51211622",label:"Tiosulfato de sodio"},
    {prodId:"51211623",label:"Succimer"},
    {prodId:"51211624",label:"Sulfato de cobre"},
    {prodId:"51211625",label:"Exopeptidasa g2"},
    {prodId:"51211900",label:"Fármacos anestésicos veterinarios"},
    {prodId:"51211901",label:"Xilacina"},
    {prodId:"51212000",label:"Fármacos herbales"},
    {prodId:"51212001",label:"Aceite etérico"},
    {prodId:"51212002",label:"Extracto de centella asiática"},
    {prodId:"51212003",label:"Extracto de ipecacuana"},
    {prodId:"51212004",label:"Aesculus"},
    {prodId:"51212005",label:"Aconitum napellus (acónito común)"},
    {prodId:"51212006",label:"Valeriana"},
    {prodId:"51212007",label:"Ajo"},
    {prodId:"51212008",label:"Celidonia mayor"},
    {prodId:"51212009",label:"Saponaria"},
    {prodId:"51212010",label:"Prímula"},
    {prodId:"51212011",label:"Pimpinella"},
    {prodId:"51212012",label:"Cilantro"},
    {prodId:"51212013",label:"Hedera hélix"},
    {prodId:"51212014",label:"Goma arábiga"},
    {prodId:"51212015",label:"Boneset"},
    {prodId:"51212016",label:"Echinacea"},
    {prodId:"51212017",label:"Acónito"},
    {prodId:"51212018",label:"Coccus cacti"},
    {prodId:"51212020",label:"Menta de gato"},
    {prodId:"51212021",label:"Ginseng"},
    {prodId:"51212022",label:"Asafétida"},
    {prodId:"51212023",label:"Arándano"},
    {prodId:"51212024",label:"Árnica"},
    {prodId:"51212025",label:"Bálsamo del perú"},
    {prodId:"51212026",label:"Eufrasia"},
    {prodId:"51212027",label:"Ginkgo biloba"},
    {prodId:"51212028",label:"Hydrastis"},
    {prodId:"51212029",label:"Gotu kola"},
    {prodId:"51212030",label:"Palma enana americana (saw palmetto)"},
    {prodId:"51212031",label:"Pygeum africano"},
    {prodId:"51212032",label:"Raíz de angélica"},
    {prodId:"51212033",label:"Violeta geneciana"},
    {prodId:"51212034",label:"Capsaicina"},
    {prodId:"51212035",label:"Leche de cardo"},
    {prodId:"51212036",label:"Cohosh negro"},
    {prodId:"51212100",label:"Fármacos dentales"},
    {prodId:"51212101",label:"Floruro de sodio"},
    {prodId:"51212200",label:"Fármacos contra las adicciones"},
    {prodId:"51212201",label:"Disulfiram"},
    {prodId:"51212202",label:"Nicotina"},
    {prodId:"51212203",label:"Nicotina polacrilina"},
    {prodId:"51212204",label:"Vareniclina"},
    {prodId:"51212300",label:"Radiofármacos y agentes de diagnóstico"},
    {prodId:"51212301",label:"Aminohipurato sódico"},
    {prodId:"51212302",label:"Aceite etiodizado"},
    {prodId:"51212303",label:"Sodio fluoresceína"},
    {prodId:"51212304",label:"Indigotindisulfonato sódico"},
    {prodId:"51212305",label:"Indocianina verde"},
    {prodId:"51212306",label:"Cloruro de metacolina"},
    {prodId:"51212307",label:"Metirapona"},
    {prodId:"51212308",label:"Sodio de rosa de bengala i 131"},
    {prodId:"51212309",label:"Loxaglato de meglumina"},
    {prodId:"51212310",label:"Kit de preparación radio farmacéutica"},
    {prodId:"51212311",label:"Iopamidol"},
    {prodId:"51212400",label:"Fármacos para tratar la disfunción sexual"},
    {prodId:"51212401",label:"Sildenafil citrato"},
    {prodId:"51212402",label:"Clorhidrato de vardenafil"},
    {prodId:"51212403",label:"Dapoxetina"},
    {prodId:"51212404",label:"Tadalafil"},
    {prodId:"51212500",label:"Medio de contraste para imágenes médicas"},
    {prodId:"51212501",label:"Diazoato"},
    {prodId:"51212502",label:"Iodixanol"},
    {prodId:"51212503",label:"Sulfato de bario"},
    {prodId:"51212504",label:"Gadolinio"},
    {prodId:"51212505",label:"Ioversol"},
    {prodId:"51241000",label:"Preparados aurales"},
    {prodId:"51241001",label:"Dioctil sulfosuccinato de sodio"},
    {prodId:"51241002",label:"Oleato polipéptido de trietanolamina"},
    {prodId:"51241100",label:"Agentes oftálmicos"},
    {prodId:"51241101",label:"Cloruro acetilcolina"},
    {prodId:"51241102",label:"Clorhidrato de apraclonidina"},
    {prodId:"51241103",label:"Bimatoprost"},
    {prodId:"51241104",label:"Brinzolamida"},
    {prodId:"51241105",label:"Carbachol"},
    {prodId:"51241106",label:"Demecario bromuro"},
    {prodId:"51241107",label:"Dorzolimato clorhidrato"},
    {prodId:"51241108",label:"Iodeto de ecotiofato"},
    {prodId:"51241109",label:"Borato de epinephryl"},
    {prodId:"51241110",label:"Latanoprost"},
    {prodId:"51241111",label:"Clorhidrato de levobunolol"},
    {prodId:"51241112",label:"Metipranolol"},
    {prodId:"51241113",label:"Sulfato fisostigmina"},
    {prodId:"51241114",label:"Clorhidrato de pilocarpina"},
    {prodId:"51241115",label:"Timolol"},
    {prodId:"51241116",label:"Travoprost"},
    {prodId:"51241117",label:"Isopropil unoprostone"},
    {prodId:"51241118",label:"Diclorfenamida"},
    {prodId:"51241119",label:"Metalzolamida"},
    {prodId:"51241120",label:"Lágrimas artificiales"},
    {prodId:"51241121",label:"Alcohol polivinílico"},
    {prodId:"51241200",label:"Agentes dermatológicos"},
    {prodId:"51241201",label:"Ácido salicílico"},
    {prodId:"51241202",label:"Ácido azelaico"},
    {prodId:"51241203",label:"Subcarbonato de bismuto"},
    {prodId:"51241204",label:"Ácido bórico"},
    {prodId:"51241205",label:"Calamina"},
    {prodId:"51241206",label:"Cloroxina"},
    {prodId:"51241207",label:"Preparaciones tópicas de brea de hulla"},
    {prodId:"51241208",label:"Cremas o ungüentos hidrofilacios"},
    {prodId:"51241209",label:"Hidroquinona"},
    {prodId:"51241210",label:"Salicilato de metilo"},
    {prodId:"51241211",label:"Minoxidil solución tópica"},
    {prodId:"51241212",label:"Papaina"},
    {prodId:"51241213",label:"Preparación tópica de brea de pino"},
    {prodId:"51241214",label:"Podofilox"},
    {prodId:"51241215",label:"Resina podofilina"},
    {prodId:"51241216",label:"Piritionato de zinc"},
    {prodId:"51241217",label:"Resorcinol"},
    {prodId:"51241218",label:"Sulfato de selenio"},
    {prodId:"51241219",label:"Tazaroteno"},
    {prodId:"51241220",label:"Tretinoina"},
    {prodId:"51241221",label:"Tripsina cristalizada"},
    {prodId:"51241222",label:"Dimeticona"},
    {prodId:"51241223",label:"Calcipotriene"},
    {prodId:"51241224",label:"Antralina o ditranol"},
    {prodId:"51241225",label:"Preparaciones tópicas de alcanfor"},
    {prodId:"51241226",label:"Preparaciones tópicas de urea"},
    {prodId:"51241227",label:"Preparaciones tópicas de aceite de turpentina"},
    {prodId:"51241228",label:"Ácido láctico"},
    {prodId:"51241229",label:"Solución sustituta de la saliva"},
    {prodId:"51241230",label:"Niacinamida"},
    {prodId:"51241231",label:"Acitetrina"},
    {prodId:"51241232",label:"Isotetrinoina"},
    {prodId:"51241233",label:"Adapaleno"},
    {prodId:"51241234",label:"Ácido fusídico"},
    {prodId:"51241300",label:"Astringentes"},
    {prodId:"51241301",label:"Acetato de aluminio"},
    {prodId:"51241302",label:"Hamamelis o escoba de bruja"},
    {prodId:"51241303",label:"Acetato de zinc"},
    {prodId:"51241304",label:"Alumbre de amonio"},
    {prodId:"51241305",label:"Ácido tánico"},
    {prodId:"51251000",label:"Metabolitos"},
    {prodId:"51251001",label:"Butafosfan"},
    {prodId:"51251002",label:"Ractopamina"},
    {prodId:"52101500",label:"Alfombras y felpudos"},
    {prodId:"52101501",label:"Alfombras orientales"},
    {prodId:"52101502",label:"Alfombras"},
    {prodId:"52101503",label:"Alfombras de lana"},
    {prodId:"52101504",label:"Alfombras de algodón"},
    {prodId:"52101505",label:"Alfombras sintéticas"},
    {prodId:"52101506",label:"Alfombras trenzadas"},
    {prodId:"52101507",label:"Tapetes de baño"},
    {prodId:"52101508",label:"Tapetes de entrada"},
    {prodId:"52101509",label:"Tapetes decorativos"},
    {prodId:"52101510",label:"Tapetes anti fatiga"},
    {prodId:"52101511",label:"Tapetes de caucho o vinilo"},
    {prodId:"52101512",label:"Esterillas para sillas"},
    {prodId:"52101513",label:"Protector de carpetas"},
    {prodId:"52101514",label:"Tapetes de fibra de vidrio"},
    {prodId:"52101515",label:"Cojín de piso"},
    {prodId:"52101516",label:"Alfombra de estera de palma"},
    {prodId:"52121500",label:"Ropa de cama"},
    {prodId:"52121501",label:"Colchas"},
    {prodId:"52121502",label:"Edredones"},
    {prodId:"52121503",label:"Forros para edredones"},
    {prodId:"52121504",label:"Forros para colchones"},
    {prodId:"52121505",label:"Almohadas"},
    {prodId:"52121506",label:"Rellenos para colchones"},
    {prodId:"52121507",label:"Plumones"},
    {prodId:"52121508",label:"Cobijas"},
    {prodId:"52121509",label:"Sábanas"},
    {prodId:"52121510",label:"Volantes de cama"},
    {prodId:"52121511",label:"Fundas de edredón"},
    {prodId:"52121512",label:"Fundas de almohada"},
    {prodId:"52121513",label:"Cobertores"},
    {prodId:"52121514",label:"Fundas para asientos"},
    {prodId:"52121600",label:"Mantelerías de cocina y mesa y accesorios"},
    {prodId:"52121601",label:"Limpiones"},
    {prodId:"52121602",label:"Servilletas"},
    {prodId:"52121603",label:"Tiras de mesa"},
    {prodId:"52121604",label:"Manteles"},
    {prodId:"52121605",label:"Guantes de horno o coge ollas para uso doméstico"},
    {prodId:"52121606",label:"Individuales de mesa"},
    {prodId:"52121607",label:"Faldas de mesa"},
    {prodId:"52121608",label:"Clips para faldas de mesa"},
    {prodId:"52121700",label:"Toallas"},
    {prodId:"52121701",label:"Toallas de baño"},
    {prodId:"52121702",label:"Toallas playeras"},
    {prodId:"52121703",label:"Paños para lavar"},
    {prodId:"52121704",label:"Toallas de manos"},
    {prodId:"52121705",label:"Toallas para la cara"},
    {prodId:"52131500",label:"Cortinas y ropaje"},
    {prodId:"52131501",label:"Cortinas"},
    {prodId:"52131503",label:"Colgaduras"},
    {prodId:"52131600",label:"Persianas"},
    {prodId:"52131601",label:"Persianas venecianas"},
    {prodId:"52131602",label:"Persianas enrollables"},
    {prodId:"52131603",label:"Postigos interiores"},
    {prodId:"52131604",label:"Persianas verticales"},
    {prodId:"52131700",label:"Accesorios y ferretería para tratamiento de ventanas"},
    {prodId:"52131701",label:"Cenefas"},
    {prodId:"52131702",label:"Varillas para cortinas"},
    {prodId:"52131703",label:"Remates para varillas"},
    {prodId:"52131704",label:"Anillos o ganchos para cortinas"},
    {prodId:"52131705",label:"Rieles metálicos para cortinas"},
    {prodId:"52141500",label:"Electrodomésticos para cocina"},
    {prodId:"52141501",label:"Neveras para uso doméstico"},
    {prodId:"52141502",label:"Hornos microondas para uso doméstico"},
    {prodId:"52141503",label:"Trituradores de basura para uso doméstico"},
    {prodId:"52141504",label:"Fogones para uso doméstico"},
    {prodId:"52141505",label:"Lavadoras de platos para uso doméstico"},
    {prodId:"52141506",label:"Congeladores para uso doméstico"},
    {prodId:"52141507",label:"Congeladores verticales para uso doméstico"},
    {prodId:"52141508",label:"Congeladores horizontales para uso doméstico"},
    {prodId:"52141509",label:"Combinación de neveras y congeladores para uso doméstico"},
    {prodId:"52141510",label:"Aire acondicionado portátil para uso doméstico"},
    {prodId:"52141511",label:"Exprimidores de jugo para uso doméstico"},
    {prodId:"52141512",label:"Planchas para waffles para uso doméstico"},
    {prodId:"52141513",label:"Abrelatas eléctricos para uso doméstico"},
    {prodId:"52141514",label:"Procesadores de alimentos para uso doméstico"},
    {prodId:"52141515",label:"Compactadores de basura para uso doméstico"},
    {prodId:"52141516",label:"Freidoras para uso doméstico"},
    {prodId:"52141517",label:"Máquinas de maíz pira para uso doméstico"},
    {prodId:"52141518",label:"Máquinas para uso doméstico para hacer pan"},
    {prodId:"52141519",label:"Hornos convencionales para uso doméstico"},
    {prodId:"52141520",label:"Mezcladoras para uso doméstico"},
    {prodId:"52141521",label:"Hornos tostadores para uso doméstico"},
    {prodId:"52141522",label:"Tostadoras para uso doméstico"},
    {prodId:"52141523",label:"Teteras eléctricas para uso doméstico"},
    {prodId:"52141524",label:"Licuadoras para uso doméstico"},
    {prodId:"52141525",label:"Hornillas para uso doméstico"},
    {prodId:"52141526",label:"Cafeteras para uso doméstico"},
    {prodId:"52141527",label:"Cuchillos eléctricos para uso doméstico"},
    {prodId:"52141528",label:"Woks eléctricos para uso doméstico"},
    {prodId:"52141529",label:"Moledoras de café para uso doméstico"},
    {prodId:"52141530",label:"Partes de máquinas lavaplatos"},
    {prodId:"52141531",label:"Tajadores de alimentos para uso doméstico"},
    {prodId:"52141532",label:"Sartenes eléctricos para uso doméstico"},
    {prodId:"52141533",label:"Planchas eléctricas para uso doméstico"},
    {prodId:"52141534",label:"Sandwicheras eléctricas para uso doméstico"},
    {prodId:"52141535",label:"Parrillas eléctricas interiores para uso doméstico"},
    {prodId:"52141536",label:"Pizelles o máquinas para hacer galletas para uso doméstico"},
    {prodId:"52141537",label:"Olla de cocción lenta para uso doméstico"},
    {prodId:"52141538",label:"Calienta comidas para uso doméstico"},
    {prodId:"52141539",label:"Máquinas para hacer té para uso doméstico"},
    {prodId:"52141540",label:"Sellador de bolsas al vacío"},
    {prodId:"52141541",label:"Esterilizador de teteros para uso doméstico"},
    {prodId:"52141542",label:"Moledor de carne para uso doméstico"},
    {prodId:"52141543",label:"Aparato eléctrico para cocinar huevos"},
    {prodId:"52141544",label:"Aparato para uso doméstico para diversas formas de cocción"},
    {prodId:"52141545",label:"Placa de cocina para uso doméstico"},
    {prodId:"52141546",label:"Extractor de cocina para uso doméstico"},
    {prodId:"52141547",label:"Triturador o moledor de sal y pimienta para uso doméstico"},
    {prodId:"52141548",label:"“Pierrade” para uso doméstico"},
    {prodId:"52141549",label:"“Raclette” para uso doméstico"},
    {prodId:"52141550",label:"Máquina para uso doméstico para hacer yogurt"},
    {prodId:"52141551",label:"Horno de vapor para uso doméstico"},
    {prodId:"52141552",label:"Horno asador para uso doméstico"},
    {prodId:"52141553",label:"Olla arrocera eléctrica para uso doméstico"},
    {prodId:"52141554",label:"Congelador “kimchi” para uso doméstico"},
    {prodId:"52141600",label:"Lavadoras y suministros de uso doméstico"},
    {prodId:"52141601",label:"Lavadoras de ropa para uso doméstico"},
    {prodId:"52141602",label:"Secadoras de ropa para uso doméstico"},
    {prodId:"52141603",label:"Planchas de ropa para uso doméstico"},
    {prodId:"52141604",label:"Secadoras de calzado"},
    {prodId:"52141605",label:"Canastas de ropa"},
    {prodId:"52141606",label:"Cestas de ropa"},
    {prodId:"52141607",label:"Compuestos para remover arrugas de los textiles"},
    {prodId:"52141608",label:"Plancha de vapor para ropa"},
    {prodId:"52141609",label:"Dispositivo para uso doméstico para remover manchas"},
    {prodId:"52141610",label:"Gabinete de planchado para uso doméstico"},
    {prodId:"52141611",label:"Lavadero"},
    {prodId:"52141700",label:"Aparatos de baño domésticos"},
    {prodId:"52141701",label:"Cepillos de dientes eléctricos para uso doméstico"},
    {prodId:"52141703",label:"Secadores de pelo para uso doméstico"},
    {prodId:"52141704",label:"Máquinas de afeitar eléctricas para uso doméstico"},
    {prodId:"52141705",label:"Partes de afeitadoras o removedores de vello"},
    {prodId:"52141706",label:"Secadores de uñas"},
    {prodId:"52141707",label:"Secadores de toallas"},
    {prodId:"52141708",label:"Pinzas para rizar el cabello"},
    {prodId:"52141800",label:"Otros electrodomésticos"},
    {prodId:"52141801",label:"Máquinas de coser para uso doméstico"},
    {prodId:"52141802",label:"Calentadores de espacios para uso doméstico"},
    {prodId:"52141804",label:"Ventiladores eléctricos para uso doméstico"},
    {prodId:"52141805",label:"Biombos plegables"},
    {prodId:"52141806",label:"Peceras o tanques para peces"},
    {prodId:"52141807",label:"Abanicos de mano"},
    {prodId:"52151500",label:"Utensilios de cocina desechables domésticos"},
    {prodId:"52151501",label:"Utensilios de cocina desechables para uso doméstico"},
    {prodId:"52151502",label:"Platos desechables para uso doméstico"},
    {prodId:"52151503",label:"Cubiertos desechables para uso doméstico"},
    {prodId:"52151504",label:"Tazas o vasos o tapas desechables para uso doméstico"},
    {prodId:"52151505",label:"Agitadores desechables para uso doméstico"},
    {prodId:"52151506",label:"Contenedores de alimentos desechables para uso doméstico"},
    {prodId:"52151507",label:"Pitillos desechables para uso doméstico"},
    {prodId:"52151600",label:"Utensilios de cocina domésticos"},
    {prodId:"52151601",label:"Rodillos para uso doméstico"},
    {prodId:"52151602",label:"Tazones mezcladores para uso doméstico"},
    {prodId:"52151603",label:"Ralladores para uso doméstico"},
    {prodId:"52151604",label:"Coladores o coladeras para uso doméstico"},
    {prodId:"52151605",label:"Destapadores o abrelatas para uso doméstico"},
    {prodId:"52151606",label:"Tablas para cortar para uso doméstico"},
    {prodId:"52151607",label:"Tazas medidoras para uso doméstico"},
    {prodId:"52151608",label:"Brochas para rociar alimentos"},
    {prodId:"52151609",label:"Peladora de vegetales"},
    {prodId:"52151610",label:"Cortadores de galletas"},
    {prodId:"52151611",label:"Pinzas de cocina para uso doméstico"},
    {prodId:"52151612",label:"Batidoras de alambre de cocina para uso doméstico"},
    {prodId:"52151613",label:"Raspadores de comida para uso doméstico"},
    {prodId:"52151614",label:"Rejillas enfriadoras para uso doméstico"},
    {prodId:"52151615",label:"Cortadores de pizza para uso doméstico"},
    {prodId:"52151616",label:"Espátulas de cocina para uso doméstico"},
    {prodId:"52151617",label:"Cucharas de madera para uso doméstico"},
    {prodId:"52151618",label:"Paletas de madera para hornear para uso doméstico"},
    {prodId:"52151619",label:"Mezclador de masa para uso doméstico"},
    {prodId:"52151620",label:"Cernedor para uso doméstico"},
    {prodId:"52151621",label:"Estampador para galletas para uso doméstico"},
    {prodId:"52151622",label:"Pistola pastelera para uso doméstico"},
    {prodId:"52151623",label:"Prensa para galletas para uso doméstico"},
    {prodId:"52151624",label:"Afiladores de cuchillos para uso doméstico"},
    {prodId:"52151625",label:"Cortadores de galletas para uso doméstico"},
    {prodId:"52151626",label:"Mandolina para uso doméstico"},
    {prodId:"52151627",label:"Exprimidor de ajo para uso doméstico"},
    {prodId:"52151628",label:"Tajador de huevos para uso doméstico"},
    {prodId:"52151629",label:"Separador de huevos para uso doméstico"},
    {prodId:"52151630",label:"Tajadora de queso para uso doméstico"},
    {prodId:"52151631",label:"Molino de alimentos para uso doméstico"},
    {prodId:"52151632",label:"Embudos de cocina para uso doméstico"},
    {prodId:"52151633",label:"Herramientas para adobar para uso doméstico"},
    {prodId:"52151634",label:"Descorazonadores de manzanas para uso doméstico"},
    {prodId:"52151635",label:"Sacabocados para melón o mantequilla para uso doméstico"},
    {prodId:"52151636",label:"Palas o cucharas para alimentos para uso doméstico"},
    {prodId:"52151637",label:"Tallador de calabazas para uso doméstico"},
    {prodId:"52151638",label:"Cepillo para vegetales para uso doméstico"},
    {prodId:"52151639",label:"Batidora de huevos para uso doméstico"},
    {prodId:"52151640",label:"Estante para secar pasta para uso doméstico"},
    {prodId:"52151641",label:"Batidora de crema para uso doméstico"},
    {prodId:"52151642",label:"Prensa para masa para uso doméstico"},
    {prodId:"52151643",label:"Máquina para hacer ravioli para uso doméstico"},
    {prodId:"52151644",label:"Rociadores de rocío o de gatillo para uso doméstico"},
    {prodId:"52151645",label:"Pincel pastelero para uso doméstico"},
    {prodId:"52151646",label:"Pesas para cocina o dietas para uso doméstico"},
    {prodId:"52151647",label:"Relojes (temporizadores) de cocina para uso doméstico"},
    {prodId:"52151648",label:"Termómetros de alimentos o cocina para uso doméstico"},
    {prodId:"52151649",label:"Guía para tajar pan para uso doméstico"},
    {prodId:"52151650",label:"Escurridores para uso doméstico"},
    {prodId:"52151651",label:"Cucharas de medida para uso doméstico"},
    {prodId:"52151652",label:"Exprimidores de limones"},
    {prodId:"52151653",label:"Cuchillos para ostras"},
    {prodId:"52151654",label:"Pasapurés para papas"},
    {prodId:"52151655",label:"Centrifugador de ensaladas"},
    {prodId:"52151656",label:"Tajador de aguacates"},
    {prodId:"52151657",label:"Antorchas culinarias"},
    {prodId:"52151658",label:"Cuchillo para masas"},
    {prodId:"52151659",label:"Ablandador de carnes manual"},
    {prodId:"52151660",label:"Cuchara para spaghetti"},
    {prodId:"52151661",label:"Protector de salpicaduras para botellas"},
    {prodId:"52151662",label:"Colador de malla"},
    {prodId:"52151663",label:"Mortero para moler alimentos"},
    {prodId:"52151700",label:"Cubertería y cuchillería de uso doméstico"},
    {prodId:"52151701",label:"Utensilios para servir para uso doméstico"},
    {prodId:"52151702",label:"Cuchillos para uso doméstico"},
    {prodId:"52151703",label:"Tenedores para uso doméstico"},
    {prodId:"52151704",label:"Cucharas para uso doméstico"},
    {prodId:"52151705",label:"Apoya cucharas"},
    {prodId:"52151706",label:"Palillos"},
    {prodId:"52151707",label:"Set de cuchillos para uso doméstico"},
    {prodId:"52151708",label:"Cuchillos para mantequilla"},
    {prodId:"52151709",label:"Set de cubiertos"},
    {prodId:"52151800",label:"Batería de cocina doméstica"},
    {prodId:"52151801",label:"Cacerolas de hierro fundido con tapa para uso doméstico"},
    {prodId:"52151802",label:"Sartenes para uso doméstico"},
    {prodId:"52151803",label:"Cacerolas para uso doméstico"},
    {prodId:"52151804",label:"Teteras para uso doméstico"},
    {prodId:"52151805",label:"Woks para uso doméstico"},
    {prodId:"52151806",label:"Vaporeras para uso doméstico"},
    {prodId:"52151807",label:"Ollas para uso doméstico"},
    {prodId:"52151808",label:"Ollas a presión para uso doméstico"},
    {prodId:"52151809",label:"Sartenes para sofreír para uso doméstico"},
    {prodId:"52151810",label:"Samovares para uso doméstico"},
    {prodId:"52151811",label:"Planchas para uso doméstico"},
    {prodId:"52151812",label:"Cacerolas para baño maría"},
    {prodId:"52151813",label:"Protector de salpicaduras para uso doméstico"},
    {prodId:"52151900",label:"Utensilios para hornear domésticos"},
    {prodId:"52151901",label:"Moldes para muffins para uso doméstico"},
    {prodId:"52151902",label:"Cacerola para hornear para uso doméstico"},
    {prodId:"52151903",label:"Moldes para ponqués o pies para uso doméstico"},
    {prodId:"52151905",label:"Bandejas de horno para uso doméstico"},
    {prodId:"52151906",label:"Cacerolas para parrilla para uso doméstico"},
    {prodId:"52151907",label:"Moldes para hornear para uso doméstico"},
    {prodId:"52151908",label:"Cacerolas para pizza para uso doméstico"},
    {prodId:"52151909",label:"Cacerolas para hacer tortillas para uso doméstico"},
    {prodId:"52152000",label:"Platos, utensilios para servir y recipientes para almacenar"},
    {prodId:"52152001",label:"Jarras para uso doméstico"},
    {prodId:"52152002",label:"Contenedores para almacenar alimentos para uso doméstico"},
    {prodId:"52152003",label:"Tazones de ponche para uso doméstico"},
    {prodId:"52152004",label:"Platos para uso doméstico"},
    {prodId:"52152005",label:"Platos pequeños para uso doméstico"},
    {prodId:"52152006",label:"Bandejas o fuentes para uso doméstico"},
    {prodId:"52152007",label:"Tazones para servir para uso doméstico"},
    {prodId:"52152008",label:"Teteras o cafeteras para uso doméstico"},
    {prodId:"52152009",label:"Soperas o ensaladeras para uso doméstico"},
    {prodId:"52152010",label:"Frascos al vacío para uso doméstico"},
    {prodId:"52152011",label:"Partes internas de frascos al vacío"},
    {prodId:"52152012",label:"Cubetas para hielo"},
    {prodId:"52152013",label:"Saleros, pimenteros o especieros"},
    {prodId:"52152014",label:"Sets de garrafas"},
    {prodId:"52152015",label:"Plato para ponqués con tapa para uso doméstico"},
    {prodId:"52152016",label:"Set de servicio de mesa para uso doméstico"},
    {prodId:"52152017",label:"Arca de madera para guardar arroz"},
    {prodId:"52152018",label:"Loncheras"},
    {prodId:"52152100",label:"Cristalería de uso doméstico"},
    {prodId:"52152101",label:"Tazas de café o té para uso doméstico"},
    {prodId:"52152102",label:"Vasos para beber para uso doméstico"},
    {prodId:"52152103",label:"Tazones (“mugs”) para uso doméstico"},
    {prodId:"52152104",label:"Copas para uso doméstico"},
    {prodId:"52152200",label:"Lavado de platos y accesorios de almacenamiento de platos"},
    {prodId:"52152201",label:"Papel para forrar repisas"},
    {prodId:"52152202",label:"Escurridor de platos"},
    {prodId:"52152203",label:"Cepillo dispensador de jabón"},
    {prodId:"52152204",label:"Mesa para preparar alimentos"},
    {prodId:"52152300",label:"Suministros de cocina doméstica"},
    {prodId:"52152301",label:"Líquido limpiador de hornos para uso doméstico"},
    {prodId:"52161500",label:"Equipos audiovisuales"},
    {prodId:"52161502",label:"Reproductor de casetes o grabadora"},
    {prodId:"52161505",label:"Televisores"},
    {prodId:"52161507",label:"Radios reloj"},
    {prodId:"52161508",label:"Reproductores de discos laser"},
    {prodId:"52161509",label:"Sistemas de estéreo portátiles"},
    {prodId:"52161510",label:"Sistemas de audio de alta fidelidad para el hogar"},
    {prodId:"52161511",label:"Radios"},
    {prodId:"52161512",label:"Altoparlantes"},
    {prodId:"52161513",label:"Combinación de televisor, vhs y grabadora dvd"},
    {prodId:"52161514",label:"Audífonos"},
    {prodId:"52161515",label:"Reproductores o grabadoras de discos compactos"},
    {prodId:"52161516",label:"Reproductores o grabadoras de video discos digitales"},
    {prodId:"52161517",label:"Ecualizadores"},
    {prodId:"52161518",label:"Receptores de sistemas de posicionamiento global"},
    {prodId:"52161520",label:"Micrófonos"},
    {prodId:"52161521",label:"Receptores de multimedia"},
    {prodId:"52161522",label:"Escáneres de radio frecuencia"},
    {prodId:"52161523",label:"Transmisores o receptores de radio frecuencia"},
    {prodId:"52161524",label:"Receptores de radio"},
    {prodId:"52161525",label:"Control remoto"},
    {prodId:"52161526",label:"Receptores de satélite"},
    {prodId:"52161527",label:"Altavoces activos “subwoofer””"},
    {prodId:"52161529",label:"Reproductores o grabadoras de video casetes"},
    {prodId:"52161531",label:"Radio fonógrafos"},
    {prodId:"52161532",label:"Sistemas de karaoke"},
    {prodId:"52161533",label:"Megáfonos"},
    {prodId:"52161534",label:"Grabadora de chip de circuito integrado ic"},
    {prodId:"52161535",label:"Grabadoras de voz digitales"},
    {prodId:"52161536",label:"Grabadoras o reproductores de mini discos"},
    {prodId:"52161537",label:"Borradores magnéticos de almacenamiento de medios"},
    {prodId:"52161538",label:"Aparatos para rebobinar video cintas"},
    {prodId:"52161539",label:"Combinación de reproductor de video disco digital dvd, disco video casete vcd, disco compacto cd"},
    {prodId:"52161540",label:"Mezcladores de video"},
    {prodId:"52161541",label:"Mezcladores de audio"},
    {prodId:"52161542",label:"Pantallas de plasma"},
    {prodId:"52161543",label:"Reproductores o grabadoras mp3"},
    {prodId:"52161544",label:"Borradores de casetes de audio o video"},
    {prodId:"52161545",label:"Grabadoras de video digital"},
    {prodId:"52161546",label:"Sintonizadores de televisión"},
    {prodId:"52161547",label:"Amplificadores de audio"},
    {prodId:"52161548",label:"Consola de audio"},
    {prodId:"52161549",label:"Marco para fotografías digitales"},
    {prodId:"52161550",label:"Video grabadora personal pvr"},
    {prodId:"52161551",label:"Micrófono inalámbrico y sistema de amplificación de instrumentos"},
    {prodId:"52161552",label:"Receptor de luz"},
    {prodId:"52161553",label:"Reproductor o grabadora de video disco blu ray"},
    {prodId:"52161554",label:"Audífono"},
    {prodId:"52161555",label:"Set combinado portátil de video multimedia"},
    {prodId:"52161556",label:"Distribuidor de video"},
    {prodId:"52161557",label:"Consola fija de juegos de computador"},
    {prodId:"52161558",label:"Consola portátil de juegos de computador"},
    {prodId:"52161559",label:"Gafas tridimensionales 3d para uso con televisores en 3d y computadoras equipadas con gráficos en 3d"},
    {prodId:"52161560",label:"Sistema de cine en casa"},
    {prodId:"52161600",label:"Accesorios de equipo audiovisual"},
    {prodId:"52161601",label:"Almacenador de casetes"},
    {prodId:"52161602",label:"Limpiadores de cabezas de audio o video"},
    {prodId:"52161603",label:"Adaptador de video casetes compactos"},
    {prodId:"52161604",label:"Adaptadores de enchufes hembra de audífonos"},
    {prodId:"52161605",label:"Accesorios para reproductores portátiles de medios"},
    {prodId:"52161606",label:"Estuches o cubiertas para altavoces"},
    {prodId:"52161607",label:"Dispositivo para almacenar discos de vinilo"},
    {prodId:"52161608",label:"Caja divisora (“splitter”) scart"},
    {prodId:"52161609",label:"Amplificador de señal de discos de vinilo"},
    {prodId:"52161610",label:"Soporte para parlantes"},
    {prodId:"52161611",label:"Soporte para micrófonos"},
    {prodId:"52171000",label:"Tratamientos de pared de baño"},
    {prodId:"52171001",label:"Repisa u organizador colgante para elementos de aseo personal"},
    {prodId:"52171002",label:"Espejo para baño"},
    {prodId:"53101500",label:"Pantalones de deporte, pantalones y pantalones cortos"},
    {prodId:"53101501",label:"Pantalones largos o cortos o pantalonetas para niño"},
    {prodId:"53101502",label:"Pantalones largos o cortos o pantalonetas para hombre"},
    {prodId:"53101503",label:"Pantalones largos o cortos o pantalonetas para niña"},
    {prodId:"53101504",label:"Pantalones largos o cortos o pantalonetas para mujer"},
    {prodId:"53101505",label:"Pantalones largos o cortos o pantalonetas para bebés"},
    {prodId:"53101600",label:"Faldas y blusas"},
    {prodId:"53101601",label:"Camisas para niño"},
    {prodId:"53101602",label:"Camisas para hombre"},
    {prodId:"53101603",label:"Camisas o blusas para niña"},
    {prodId:"53101604",label:"Camisas o blusas para mujer"},
    {prodId:"53101605",label:"Camisas o blusas para bebé"},
    {prodId:"53101700",label:"Suéteres"},
    {prodId:"53101701",label:"Sweaters para niño"},
    {prodId:"53101702",label:"Sweaters para hombre"},
    {prodId:"53101703",label:"Sweaters para niña"},
    {prodId:"53101704",label:"Sweaters para mujer"},
    {prodId:"53101705",label:"Sweaters para bebé"},
    {prodId:"53101800",label:"Abrigos y chaquetas"},
    {prodId:"53101801",label:"Abrigos o chaquetas para niño"},
    {prodId:"53101802",label:"Abrigos o chaquetas para hombre"},
    {prodId:"53101803",label:"Abrigos o chaquetas para niña"},
    {prodId:"53101804",label:"Abrigos o chaquetas para mujer"},
    {prodId:"53101805",label:"Abrigos o chaquetas para bebé"},
    {prodId:"53101900",label:"Trajes"},
    {prodId:"53101901",label:"Trajes para niño"},
    {prodId:"53101902",label:"Trajes para hombre"},
    {prodId:"53101903",label:"Trajes para niña"},
    {prodId:"53101904",label:"Trajes para mujer"},
    {prodId:"53101905",label:"Trajes para bebé"},
    {prodId:"53102000",label:"Vestidos, faldas, saris y kimonos"},
    {prodId:"53102001",label:"Vestidos o faldas o saris o kimonos para niña"},
    {prodId:"53102002",label:"Vestidos o faldas o saris o kimonos para para mujer"},
    {prodId:"53102003",label:"Vestidos o faldas o saris o kimonos para bebé"},
    {prodId:"53102100",label:"Batas y monos"},
    {prodId:"53102101",label:"Overoles o monos para niño"},
    {prodId:"53102102",label:"Overoles o monos para hombre"},
    {prodId:"53102103",label:"Overoles o monos para niña"},
    {prodId:"53102104",label:"Overoles o monos para mujer"},
    {prodId:"53102105",label:"Overoles o monos para bebé"},
    {prodId:"53102200",label:"Ropa folclórica"},
    {prodId:"53102201",label:"Vestidos folclóricos para niño"},
    {prodId:"53102202",label:"Vestidos folclóricos para hombre"},
    {prodId:"53102203",label:"Vestidos folclóricos para niña"},
    {prodId:"53102204",label:"Vestidos folclóricos para mujer"},
    {prodId:"53102205",label:"Vestidos folclóricos para bebé"},
    {prodId:"53102300",label:"Ropa interior"},
    {prodId:"53102301",label:"Camisetas interiores"},
    {prodId:"53102302",label:"Combinaciones"},
    {prodId:"53102303",label:"Calzoncillos"},
    {prodId:"53102304",label:"Brassieres"},
    {prodId:"53102305",label:"Pañales para bebé"},
    {prodId:"53102306",label:"Pañales para adulto"},
    {prodId:"53102307",label:"Prendas para dar forma al cuerpo"},
    {prodId:"53102308",label:"Forros para pañales"},
    {prodId:"53102309",label:"Rellenos para busto"},
    {prodId:"53102310",label:"Forros para rellenos para busto"},
    {prodId:"53102400",label:"Medias y calcetines"},
    {prodId:"53102401",label:"Medias largas"},
    {prodId:"53102402",label:"Calcetines"},
    {prodId:"53102403",label:"Medias veladas"},
    {prodId:"53102404",label:"Medias veladas gruesas (“tights”)"},
    {prodId:"53102500",label:"Accesorios de vestir"},
    {prodId:"53102501",label:"Cinturones o tirantes"},
    {prodId:"53102502",label:"Corbatas o pañoletas o bufandas"},
    {prodId:"53102503",label:"Sombreros"},
    {prodId:"53102504",label:"Guantes o mitones"},
    {prodId:"53102505",label:"Sombrillas"},
    {prodId:"53102506",label:"Bandas para el sudor"},
    {prodId:"53102507",label:"Ganchos para colgar la ropa"},
    {prodId:"53102508",label:"Bandas para los brazos"},
    {prodId:"53102509",label:"Ligas"},
    {prodId:"53102510",label:"Borlas"},
    {prodId:"53102511",label:"Badanas"},
    {prodId:"53102512",label:"Pañuelos"},
    {prodId:"53102513",label:"Bandas para la cabeza"},
    {prodId:"53102514",label:"Protectores de bolsillos"},
    {prodId:"53102515",label:"Forros para botones"},
    {prodId:"53102516",label:"Gorras"},
    {prodId:"53102517",label:"Sujeta corbatas"},
    {prodId:"53102518",label:"Cheurones"},
    {prodId:"53102519",label:"Golas"},
    {prodId:"53102520",label:"Hombreras o charreteras"},
    {prodId:"53102600",label:"Ropa de dormir"},
    {prodId:"53102601",label:"Pijamas o camisas de dormir o batas para niño"},
    {prodId:"53102602",label:"Pijamas o camisas de dormir o batas para hombre"},
    {prodId:"53102603",label:"Pijamas o camisas de dormir o batas para niña"},
    {prodId:"53102604",label:"Pijamas o camisas de dormir o batas para mujer"},
    {prodId:"53102605",label:"Pijamas o camisas de dormir o batas para bebé"},
    {prodId:"53102606",label:"Batas de baño"},
    {prodId:"53102700",label:"Uniformes"},
    {prodId:"53102701",label:"Uniformes militares"},
    {prodId:"53102702",label:"Uniformes de agentes de aduna"},
    {prodId:"53102703",label:"Uniformes de policías"},
    {prodId:"53102704",label:"Uniformes institucionales para preparación de alimentos o servicio"},
    {prodId:"53102705",label:"Uniformes de colegio"},
    {prodId:"53102706",label:"Uniformes de personal de seguridad"},
    {prodId:"53102707",label:"Batas de doctor"},
    {prodId:"53102708",label:"Uniformes de enfermera"},
    {prodId:"53102709",label:"Uniformes de personal de ambulancias"},
    {prodId:"53102710",label:"Uniformes corporativos"},
    {prodId:"53102711",label:"Batas para personal de peluquerías"},
    {prodId:"53102712",label:"Uniformes de paramédicos"},
    {prodId:"53102713",label:"Uniformes de acomodadores"},
    {prodId:"53102714",label:"Togas judiciales"},
    {prodId:"53102715",label:"Uniformes de oficiales de cárceles"},
    {prodId:"53102716",label:"Uniformes de presos"},
    {prodId:"53102800",label:"Trajes de baño"},
    {prodId:"53102801",label:"Vestidos de baño para hombre"},
    {prodId:"53102802",label:"Vestidos de baño para mujer"},
    {prodId:"53102803",label:"Vestidos de baño para niño"},
    {prodId:"53102804",label:"Vestidos de baño para niña"},
    {prodId:"53102805",label:"Vestidos de baño para bebé"},
    {prodId:"53102900",label:"Prendas de deporte"},
    {prodId:"53102901",label:"Ropa atlética para mujer"},
    {prodId:"53102902",label:"Ropa atlética para hombre"},
    {prodId:"53102903",label:"Ropa atlética para niño"},
    {prodId:"53102904",label:"Ropa atlética para niña"},
    {prodId:"53103000",label:"Camisetas"},
    {prodId:"53103001",label:"Camisetas (t-shirts) para hombre"},
    {prodId:"53103100",label:"Chalecos"},
    {prodId:"53103101",label:"Chalecos para hombre"},
    {prodId:"53103200",label:"Ropas desechables"},
    {prodId:"53103201",label:"Bata de trabajo desechable"},
    {prodId:"53111500",label:"Botas"},
    {prodId:"53111501",label:"Botas para hombre"},
    {prodId:"53111502",label:"Botas para mujer"},
    {prodId:"53111503",label:"Botas para niño"},
    {prodId:"53111504",label:"Botas para niña"},
    {prodId:"53111505",label:"Botas para bebé"},
    {prodId:"53111600",label:"Zapatos"},
    {prodId:"53111601",label:"Zapatos para hombre"},
    {prodId:"53111602",label:"Zapatos para mujer"},
    {prodId:"53111603",label:"Zapatos para niño"},
    {prodId:"53111604",label:"Zapatos para niña"},
    {prodId:"53111605",label:"Zapatos para bebé"},
    {prodId:"53111700",label:"Zapatillas"},
    {prodId:"53111701",label:"Pantuflas para hombre"},
    {prodId:"53111702",label:"Pantuflas para mujer"},
    {prodId:"53111703",label:"Pantuflas para niño"},
    {prodId:"53111704",label:"Pantuflas para niña"},
    {prodId:"53111705",label:"Pantuflas para bebé"},
    {prodId:"53111800",label:"Sandalias"},
    {prodId:"53111801",label:"Sandalias para hombre"},
    {prodId:"53111802",label:"Sandalias para mujer"},
    {prodId:"53111803",label:"Sandalias para niño"},
    {prodId:"53111804",label:"Sandalias para niña"},
    {prodId:"53111805",label:"Sandalias para bebé"},
    {prodId:"53111900",label:"Calzado deportivo"},
    {prodId:"53111901",label:"Calzado atlético para hombre"},
    {prodId:"53111902",label:"Calzado atlético para mujer"},
    {prodId:"53111903",label:"Calzado atlético para niño"},
    {prodId:"53111904",label:"Calzado atlético para niña"},
    {prodId:"53111905",label:"Calzado atlético para bebé"},
    {prodId:"53112000",label:"Accesorios para el calzado"},
    {prodId:"53112001",label:"Calzadores"},
    {prodId:"53112002",label:"Cordones para zapatos"},
    {prodId:"53112003",label:"Almohadillas para talones"},
    {prodId:"53112005",label:"Aparato para medir el pie"},
    {prodId:"53112100",label:"Chanclos"},
    {prodId:"53112101",label:"Zapatones para hombre"},
    {prodId:"53112102",label:"Zapatones para mujer"},
    {prodId:"53112103",label:"Zapatones para niño"},
    {prodId:"53112104",label:"Zapatones para niña"},
    {prodId:"53112105",label:"Zapatones para bebé"},
    {prodId:"53121500",label:"Maletas"},
    {prodId:"53121501",label:"Porta trajes"},
    {prodId:"53121502",label:"Sets de maletas"},
    {prodId:"53121503",label:"Piezas individuales de maletas"},
    {prodId:"53121600",label:"Monederos, bolsos de mano y bolsas"},
    {prodId:"53121601",label:"Bolsos o carteras"},
    {prodId:"53121602",label:"Tulas"},
    {prodId:"53121603",label:"Morrales"},
    {prodId:"53121605",label:"Monederos"},
    {prodId:"53121606",label:"Estuches para labiales"},
    {prodId:"53121607",label:"Cigarrilleras"},
    {prodId:"53121608",label:"Bolsas para compras"},
    {prodId:"53121700",label:"Carteras"},
    {prodId:"53121701",label:"Maletines"},
    {prodId:"53121702",label:"Maletines (“attaches”)"},
    {prodId:"53121704",label:"Portafolios"},
    {prodId:"53121705",label:"Estuches para equipos"},
    {prodId:"53121706",label:"Maletines para computador"},
    {prodId:"53121800",label:"Juegos y accesorios de viaje"},
    {prodId:"53121801",label:"Kits de viaje"},
    {prodId:"53121802",label:"Carritos de viaje"},
    {prodId:"53121803",label:"Cepillos de ropa"},
    {prodId:"53121804",label:"Estuches de maquillaje o manicure"},
    {prodId:"53131500",label:"Dentales"},
    {prodId:"53131501",label:"Enjuague bucal"},
    {prodId:"53131502",label:"Dentífrico"},
    {prodId:"53131503",label:"Cepillos de dientes"},
    {prodId:"53131504",label:"Seda dental"},
    {prodId:"53131505",label:"Chupos o chupetes para bebé"},
    {prodId:"53131506",label:"Kits dentales"},
    {prodId:"53131507",label:"Palillos de dientes (mondadientes)"},
    {prodId:"53131508",label:"Tabletas para limpiar dentaduras postizas"},
    {prodId:"53131509",label:"Refrescadores de aliento"},
    {prodId:"53131510",label:"Pegante para dentaduras postizas"},
    {prodId:"53131600",label:"Baño y cuerpo"},
    {prodId:"53131601",label:"Gorros de baño"},
    {prodId:"53131602",label:"Artículos para el cuidado del cabello"},
    {prodId:"53131603",label:"Afeitadoras"},
    {prodId:"53131604",label:"Cepillos o peinillas para el cabello"},
    {prodId:"53131605",label:"Kits de tocador"},
    {prodId:"53131606",label:"Desodorantes"},
    {prodId:"53131607",label:"Lociones o aceites para manos o cuerpo"},
    {prodId:"53131608",label:"Jabones"},
    {prodId:"53131609",label:"Productos de protección solar"},
    {prodId:"53131610",label:"Artículos para el cuidado de los ojos"},
    {prodId:"53131611",label:"Cremas de afeitar"},
    {prodId:"53131612",label:"Geles de baño"},
    {prodId:"53131613",label:"Productos para el cuidado de la piel"},
    {prodId:"53131614",label:"Productos para el cuidado de los pies"},
    {prodId:"53131615",label:"Productos para la higiene femenina"},
    {prodId:"53131616",label:"Cremas o lociones para farmacéuticas"},
    {prodId:"53131617",label:"Implementos para la manicure"},
    {prodId:"53131618",label:"Implementos para la pedicura"},
    {prodId:"53131619",label:"Cosméticos"},
    {prodId:"53131620",label:"Perfumes o colonias o fragancias"},
    {prodId:"53131621",label:"Corta uñas"},
    {prodId:"53131622",label:"Condones"},
    {prodId:"53131623",label:"Productos depilatorios o para remover vello"},
    {prodId:"53131624",label:"Paños limpiadores desechables"},
    {prodId:"53131625",label:"Redecillas para el cabello o la barba"},
    {prodId:"53131626",label:"Desinfectante de manos"},
    {prodId:"53131627",label:"Limpiador de manos"},
    {prodId:"53131628",label:"Champús"},
    {prodId:"53131629",label:"Kits de maquillaje"},
    {prodId:"53131630",label:"Bálsamo labial"},
    {prodId:"53131631",label:"Tatuajes"},
    {prodId:"53131632",label:"Marrones calientes"},
    {prodId:"53131633",label:"Hebillas para el pelo"},
    {prodId:"53131634",label:"Productos químicos protectores"},
    {prodId:"53131635",label:"Brochas de afeitar"},
    {prodId:"53131636",label:"Agua de rosas"},
    {prodId:"53131637",label:"Almohadillas de lactancia"},
    {prodId:"53131638",label:"Esmalte para uñas"},
    {prodId:"53131639",label:"Protectores para incontinencia urinaria"},
    {prodId:"53131640",label:"Removedor de esmalte"},
    {prodId:"53131641",label:"Gelatina de petróleo"},
    {prodId:"53131642",label:"Bata o capa para cortar el pelo para barberías o peluquerías"},
    {prodId:"53131643",label:"Cortador de pelo eléctrico"},
    {prodId:"53131644",label:"Aceites esenciales de aromaterapia"},
    {prodId:"53131645",label:"Instrumento o pistola para perforar el lóbulo de la oreja"},
    {prodId:"53131646",label:"Removedor de espinillas"},
    {prodId:"53131647",label:"Set de higiene y cuidado personal de bebés"},
    {prodId:"53131700",label:"Equipo para masaje terapéutico"},
    {prodId:"53131701",label:"Unidad masajeadora manual"},
    {prodId:"53131702",label:"Máquina masajeadora"},
    {prodId:"53131800",label:"Accesorios y materiales para equipo de tatuajes"},
    {prodId:"53131801",label:"Tinta para tatuajes"},
    {prodId:"53131802",label:"Aguja para tatuajes"},
    {prodId:"53141500",label:"Afianzadores de costura"},
    {prodId:"53141501",label:"Alfileres rectos"},
    {prodId:"53141502",label:"Imperdibles"},
    {prodId:"53141503",label:"Cremalleras"},
    {prodId:"53141504",label:"Hebillas"},
    {prodId:"53141505",label:"Botones"},
    {prodId:"53141506",label:"Cierres"},
    {prodId:"53141507",label:"Broches"},
    {prodId:"53141508",label:"Remaches para ropa"},
    {prodId:"53141600",label:"Suministros de costura variados"},
    {prodId:"53141601",label:"Alfileteros"},
    {prodId:"53141602",label:"Kits de costura"},
    {prodId:"53141603",label:"Dedales"},
    {prodId:"53141604",label:"Patrones de costura"},
    {prodId:"53141605",label:"Agujas de costura"},
    {prodId:"53141606",label:"Bobinas o sujeta bobinas"},
    {prodId:"53141607",label:"Indicadores de costura"},
    {prodId:"53141608",label:"Aguja pasa cintas"},
    {prodId:"53141609",label:"Pasa presillas"},
    {prodId:"53141610",label:"Sujetadores de tiza para sastres o textiles"},
    {prodId:"53141611",label:"Marcadores de textiles o lápices para textiles o tiza para textiles"},
    {prodId:"53141612",label:"Rueda aserrada seguidora de patrones"},
    {prodId:"53141613",label:"Papel calcante"},
    {prodId:"53141614",label:"Agujas de bordado"},
    {prodId:"53141615",label:"Agujas de tejer"},
    {prodId:"53141616",label:"Lanzaderas para telares"},
    {prodId:"53141617",label:"Lanzaderas para tejidos de algodón"},
    {prodId:"53141618",label:"Lienzo para bordar"},
    {prodId:"53141619",label:"Varas magnéticas"},
    {prodId:"53141620",label:"Corta costuras"},
    {prodId:"53141621",label:"Enhebrador de agujas"},
    {prodId:"53141622",label:"Regla para sastrería"},
    {prodId:"53141623",label:"Refuerzo o cerrado líquido para hilo"},
    {prodId:"53141624",label:"Textiles o agujas para punto de cruz"},
    {prodId:"53141625",label:"Diseños para punto de cruz"},
    {prodId:"53141626",label:"Aro para bordado"},
    {prodId:"53141627",label:"Agujas de croché"},
    {prodId:"53141628",label:"Herramientas para hilvanar acolchados"},
    {prodId:"53141629",label:"Alfileres para acolchados"},
    {prodId:"53141630",label:"Tableros o almohadillas para cortar patrones"},
    {prodId:"54101500",label:"Joyería fina"},
    {prodId:"54101501",label:"Cadenas de oro o plata o platino"},
    {prodId:"54101502",label:"Collares de joyería fina"},
    {prodId:"54101503",label:"Anillos de  joyería fina"},
    {prodId:"54101504",label:"Aretes de joyería fina"},
    {prodId:"54101505",label:"Joyas finas para el cuerpo"},
    {prodId:"54101506",label:"Brazaletes de joyería fina"},
    {prodId:"54101507",label:"Tiaras"},
    {prodId:"54101508",label:"Revestimientos de joyería fina para reducir el tamaño de un anillo"},
    {prodId:"54101509",label:"Mancornas de joyería fina"},
    {prodId:"54101510",label:"Mariposas para aretes de joyería fina"},
    {prodId:"54101511",label:"Prendedores de joyería fina"},
    {prodId:"54101512",label:"Aros de prendedores para pasar cadenas de joyería fina"},
    {prodId:"54101513",label:"Pisacorbatas"},
    {prodId:"54101600",label:"Bisutería"},
    {prodId:"54101601",label:"Brazaletes"},
    {prodId:"54101602",label:"Collares"},
    {prodId:"54101603",label:"Anillos"},
    {prodId:"54101604",label:"Aretes"},
    {prodId:"54101605",label:"Joyas para el cuerpo"},
    {prodId:"54101700",label:"Herramientas y materiales de joyería"},
    {prodId:"54101701",label:"Compuestos conservantes"},
    {prodId:"54101702",label:"Bloques para producir hemisferios (“dapping punches”)"},
    {prodId:"54101703",label:"Molinos de alambre"},
    {prodId:"54101704",label:"Mandriles para joyería"},
    {prodId:"54101705",label:"Medidores de tamaño para anillos"},
    {prodId:"54101706",label:"Materiales o accesorios plásticos amigables maleables a bajas temperaturas"},
    {prodId:"54111500",label:"Relojes de pulsera o bolsillo"},
    {prodId:"54111501",label:"Relojes de pulso"},
    {prodId:"54111502",label:"Relojes de bolsillo"},
    {prodId:"54111503",label:"Relojes cronómetros"},
    {prodId:"54111504",label:"Cronómetros marinos"},
    {prodId:"54111505",label:"Relojes para bucear"},
    {prodId:"54111600",label:"Relojes de pared o de mesa"},
    {prodId:"54111601",label:"Relojes de pared"},
    {prodId:"54111602",label:"Relojes de mesa o repisa"},
    {prodId:"54111603",label:"Relojes verticales"},
    {prodId:"54111604",label:"Relojes de arena"},
    {prodId:"54111605",label:"Relojes maestros electrónicos"},
    {prodId:"54111606",label:"Relojes esclavos electrónicos"},
    {prodId:"54111700",label:"Piezas de relojes de pulsera o de pared o pie o accesorios"},
    {prodId:"54111701",label:"Manecillas para reloj"},
    {prodId:"54111702",label:"Cristales para reloj"},
    {prodId:"54111703",label:"Placas o puentes para relojes"},
    {prodId:"54111704",label:"Correas o bandas o pulseras para relojes"},
    {prodId:"54111705",label:"Estuches para relojes"},
    {prodId:"54111706",label:"Sujetadores para relojes"},
    {prodId:"54111707",label:"Abre estuches para relojes"},
    {prodId:"54111708",label:"Péndulos para relojes"},
    {prodId:"54111709",label:"Kits para reparar relojes"},
    {prodId:"54121500",label:"Piedras preciosas"},
    {prodId:"54121501",label:"Diamantes"},
    {prodId:"54121502",label:"Esmeraldas"},
    {prodId:"54121503",label:"Rubíes"},
    {prodId:"54121504",label:"Zafiros"},
    {prodId:"54121600",label:"Piedras semipreciosas"},
    {prodId:"54121601",label:"Granates"},
    {prodId:"54121602",label:"Jades"},
    {prodId:"54121603",label:"Ópalos"},
    {prodId:"54121700",label:"Perlas"},
    {prodId:"54121701",label:"Perlas cultivadas"},
    {prodId:"54121702",label:"Perlas naturales"},
    {prodId:"55101500",label:"Publicaciones impresas"},
    {prodId:"55101501",label:"Cartas de navegación o atlas o mapas"},
    {prodId:"55101502",label:"Directorios"},
    {prodId:"55101503",label:"Catálogos"},
    {prodId:"55101504",label:"Periódicos"},
    {prodId:"55101506",label:"Revistas"},
    {prodId:"55101507",label:"Libros para pintar o colorear o ilustrar para niños"},
    {prodId:"55101509",label:"Textos educacionales o vocacionales"},
    {prodId:"55101510",label:"Libros para entretenimiento"},
    {prodId:"55101513",label:"Tarjetas para intercambiar"},
    {prodId:"55101514",label:"Partituras"},
    {prodId:"55101515",label:"Material promocional o reportes anuales"},
    {prodId:"55101516",label:"Manuales operativos o de instrucciones"},
    {prodId:"55101517",label:"Dibujos dimensionales o de tolerancia"},
    {prodId:"55101518",label:"Diagramas o dibujos técnicos"},
    {prodId:"55101519",label:"Publicaciones periódicas"},
    {prodId:"55101520",label:"Hojas o folletos de instrucciones"},
    {prodId:"55101521",label:"Manuales de propietario o usuario"},
    {prodId:"55101522",label:"Globos terrestres o celestes"},
    {prodId:"55101523",label:"Libros de ejercicios"},
    {prodId:"55101524",label:"Libros de referencia"},
    {prodId:"55101525",label:"Enciclopedias"},
    {prodId:"55101526",label:"Diccionarios"},
    {prodId:"55101527",label:"Cancioneros"},
    {prodId:"55101528",label:"Libros religiosos"},
    {prodId:"55101529",label:"Chequeras o libretas de cuentas"},
    {prodId:"55101530",label:"Libros de códigos"},
    {prodId:"55101531",label:"Códigos (legales)"},
    {prodId:"55101532",label:"Álbumes de graduación o anuarios"},
    {prodId:"55111500",label:"Publicaciones electrónicas y música"},
    {prodId:"55111501",label:"Directorios electrónicos"},
    {prodId:"55111502",label:"Diccionarios electrónicos"},
    {prodId:"55111503",label:"Enciclopedias electrónicas"},
    {prodId:"55111504",label:"Catálogos electrónicos"},
    {prodId:"55111505",label:"Libros en cintas o en discos compactos"},
    {prodId:"55111506",label:"Revistas electrónicas"},
    {prodId:"55111507",label:"Periódicos electrónicos"},
    {prodId:"55111508",label:"Cartas de navegación o mapas o atlas electrónicos"},
    {prodId:"55111509",label:"Música de fondo"},
    {prodId:"55111510",label:"Películas de cine en celuloide"},
    {prodId:"55111511",label:"Películas de cine en video cinta"},
    {prodId:"55111512",label:"Música en cintas o discos compactos"},
    {prodId:"55111513",label:"Textos electrónicos educacionales o vocacionales"},
    {prodId:"55111514",label:"Películas de cine en discos de video digital dvd"},
    {prodId:"55111515",label:"Audio cinta de material educacional"},
    {prodId:"55121500",label:"Rótulos"},
    {prodId:"55121501",label:"Etiquetas para el equipaje"},
    {prodId:"55121502",label:"Etiquetas de seguridad"},
    {prodId:"55121503",label:"Etiquetas de identificación"},
    {prodId:"55121504",label:"Etiquetas para llaves"},
    {prodId:"55121505",label:"Porta etiquetas o accesorios"},
    {prodId:"55121506",label:"Etiquetas de precio"},
    {prodId:"55121507",label:"Etiquetas de bolsas de té o café"},
    {prodId:"55121600",label:"Etiquetas"},
    {prodId:"55121601",label:"Kits para remover etiquetas"},
    {prodId:"55121602",label:"Etiquetas para ropa"},
    {prodId:"55121604",label:"Etiquetas para latas o botellas"},
    {prodId:"55121605",label:"Etiquetas de direcciones o de correo"},
    {prodId:"55121606",label:"Etiquetas auto adhesivas"},
    {prodId:"55121607",label:"Calcomanías"},
    {prodId:"55121608",label:"Etiquetas de códigos de barra"},
    {prodId:"55121609",label:"Etiquetas para paquetes"},
    {prodId:"55121610",label:"Etiquetas numeradas consecutivamente"},
    {prodId:"55121611",label:"Cintas para hacer etiquetas"},
    {prodId:"55121612",label:"Etiquetas para impresoras"},
    {prodId:"55121613",label:"Etiquetas de códigos de color"},
    {prodId:"55121614",label:"Etiquetas removibles"},
    {prodId:"55121615",label:"Puntos o flechas adhesivas"},
    {prodId:"55121616",label:"Banderas auto adhesivas"},
    {prodId:"55121617",label:"Protectores de etiquetas"},
    {prodId:"55121618",label:"Porta etiquetas"},
    {prodId:"55121619",label:"Etiquetas no adhesivas"},
    {prodId:"55121620",label:"Etiquetas multipropósito"},
    {prodId:"55121621",label:"Sellos notariales"},
    {prodId:"55121622",label:"Etiquetas criogénicas"},
    {prodId:"55121700",label:"Señalización"},
    {prodId:"55121701",label:"Placas con inscripción metálicas"},
    {prodId:"55121702",label:"Placas con inscripción no metálicas"},
    {prodId:"55121703",label:"Señales iluminadas"},
    {prodId:"55121704",label:"Señales de seguridad"},
    {prodId:"55121705",label:"Señales auto adhesivas"},
    {prodId:"55121706",label:"Pancartas"},
    {prodId:"55121707",label:"Señales magnéticas"},
    {prodId:"55121708",label:"Señales de neón"},
    {prodId:"55121710",label:"Signos de tráfico"},
    {prodId:"55121712",label:"Señales direccionales"},
    {prodId:"55121713",label:"Señales de punto de venta"},
    {prodId:"55121714",label:"Pendones"},
    {prodId:"55121715",label:"Banderas o accesorios"},
    {prodId:"55121716",label:"Señales de madera"},
    {prodId:"55121717",label:"Placas de identificación de máquinas"},
    {prodId:"55121718",label:"Señales informativas"},
    {prodId:"55121719",label:"Componentes de señalización"},
    {prodId:"55121720",label:"Emblemas"},
    {prodId:"55121721",label:"Caracteres de señalización"},
    {prodId:"55121725",label:"Kits de señalización"},
    {prodId:"55121726",label:"Paneles de identificación"},
    {prodId:"55121728",label:"Cubiertas de señales"},
    {prodId:"55121729",label:"Indicadores"},
    {prodId:"55121730",label:"Señales de accidente"},
    {prodId:"55121731",label:"Marcadores de identificación"},
    {prodId:"55121732",label:"Divisores de tamaño"},
    {prodId:"55121734",label:"Láminas de material para señales"},
    {prodId:"55121735",label:"Señales temporales"},
    {prodId:"55121800",label:"Documentos de identificación"},
    {prodId:"55121801",label:"Discos de impuestos de vehículos"},
    {prodId:"55121802",label:"Tarjetas o bandas de identificación o productos similares"},
    {prodId:"55121803",label:"Pasaportes"},
    {prodId:"55121804",label:"Gafetes o porta gafetes"},
    {prodId:"55121806",label:"Kits de bandas de identificación personal o accesorios"},
    {prodId:"55121807",label:"Porta productos de identificación o accesorios"},
    {prodId:"55121808",label:"Clip para gafete"},
    {prodId:"55121900",label:"Señalizaciones"},
    {prodId:"55121901",label:"Columnas de publicidad"},
    {prodId:"55121902",label:"Stands o puestos"},
    {prodId:"55121903",label:"Señales de mensajes portátiles"},
    {prodId:"55121904",label:"Carteleras"},
    {prodId:"55121905",label:"Astas o partes o accesorios"},
    {prodId:"55121906",label:"Stands para banderas"},
    {prodId:"55121907",label:"Tableros de avisos"},
    {prodId:"55121908",label:"Soportes o stands para señales"},
    {prodId:"56101500",label:"Muebles"},
    {prodId:"56101501",label:"Stands"},
    {prodId:"56101502",label:"Sofás"},
    {prodId:"56101503",label:"Armarios para abrigos"},
    {prodId:"56101504",label:"Asientos"},
    {prodId:"56101505",label:"Centros de entretenimiento"},
    {prodId:"56101506",label:"Futones"},
    {prodId:"56101507",label:"Bibliotecas"},
    {prodId:"56101508",label:"Colchones o sets para dormir"},
    {prodId:"56101509",label:"Vestidores o armarios"},
    {prodId:"56101510",label:"Divisiones"},
    {prodId:"56101513",label:"Cunas o accesorios"},
    {prodId:"56101514",label:"Taburetes"},
    {prodId:"56101515",label:"Camas"},
    {prodId:"56101516",label:"Cómodas"},
    {prodId:"56101518",label:"Estanterías de pared"},
    {prodId:"56101519",label:"Mesas"},
    {prodId:"56101520",label:"Casilleros (“lockers”)"},
    {prodId:"56101521",label:"Cabeceras o pies de cama"},
    {prodId:"56101522",label:"Sillas de brazos"},
    {prodId:"56101523",label:"Paragüeros o soportes para paraguas"},
    {prodId:"56101524",label:"Mesas de plancha"},
    {prodId:"56101525",label:"Forros para mesas de plancha"},
    {prodId:"56101526",label:"Bar refrigerador"},
    {prodId:"56101527",label:"Secadores de linos tipo hogar"},
    {prodId:"56101528",label:"Plantas artificiales"},
    {prodId:"56101529",label:"Revisteros"},
    {prodId:"56101530",label:"Gabinetes de almacenamiento"},
    {prodId:"56101531",label:"Zapateras"},
    {prodId:"56101532",label:"Set de muebles"},
    {prodId:"56101533",label:"Descansa brazos"},
    {prodId:"56101535",label:"Mesas de ruedas"},
    {prodId:"56101536",label:"Trípodes para instrumentos"},
    {prodId:"56101537",label:"Tocadores"},
    {prodId:"56101538",label:"Mesas o bufetes para el comedor"},
    {prodId:"56101539",label:"Armazones o partes o accesorios para camas"},
    {prodId:"56101540",label:"Solterones"},
    {prodId:"56101541",label:"Ventiladores de colchones"},
    {prodId:"56101542",label:"Asientos plegables"},
    {prodId:"56101543",label:"Mesa de comedor"},
    {prodId:"56101544",label:"Espejos"},
    {prodId:"56101545",label:"Espejos decorativos"},
    {prodId:"56101600",label:"Muebles para el exterior"},
    {prodId:"56101601",label:"Paraguas para jardín"},
    {prodId:"56101602",label:"Sillas para jardín"},
    {prodId:"56101603",label:"Mesas para jardín o mesas para picnic"},
    {prodId:"56101604",label:"Columpios para jardín"},
    {prodId:"56101605",label:"Bancos para jardín"},
    {prodId:"56101606",label:"Materas"},
    {prodId:"56101607",label:"Secadora de ropa para exteriores"},
    {prodId:"56101608",label:"Estantes para bicicletas"},
    {prodId:"56101609",label:"Macetas de arcilla"},
    {prodId:"56101610",label:"Soportes para macetas"},
    {prodId:"56101700",label:"Muebles de oficina"},
    {prodId:"56101701",label:"Cajoneras o estanterías"},
    {prodId:"56101702",label:"Gabinetes de archivo o accesorios"},
    {prodId:"56101703",label:"Escritorios"},
    {prodId:"56101704",label:"Bases para mesas"},
    {prodId:"56101705",label:"Vitrinas"},
    {prodId:"56101706",label:"Mesas de conferencia"},
    {prodId:"56101707",label:"Mesas de dibujo"},
    {prodId:"56101708",label:"Archivadores móviles"},
    {prodId:"56101710",label:"Carritos o soportes para proyectores"},
    {prodId:"56101711",label:"Conectores de muebles modulares"},
    {prodId:"56101712",label:"Pedestales"},
    {prodId:"56101713",label:"Puestos (mesas) laterales de escritorios"},
    {prodId:"56101714",label:"Estantes para carpetas de información"},
    {prodId:"56101715",label:"Organizadores o clasificadores de correspondencia"},
    {prodId:"56101716",label:"Gavetas organizadoras para el escritorio"},
    {prodId:"56101717",label:"Eleva mesas"},
    {prodId:"56101718",label:"Estantes para periódicos"},
    {prodId:"56101719",label:"Mesas auxiliares"},
    {prodId:"56101800",label:"Accesorios y muebles de bebé y niño"},
    {prodId:"56101803",label:"Coches de bebé"},
    {prodId:"56101804",label:"Cunas o corrales o accesorios"},
    {prodId:"56101805",label:"Asientos de bebé para el carro"},
    {prodId:"56101806",label:"Asientos de comer (para bebés) o accesorios"},
    {prodId:"56101807",label:"Asientos rebotadores para bebés"},
    {prodId:"56101808",label:"Columpios o rebotadores o accesorios"},
    {prodId:"56101809",label:"Micas o bacinillas"},
    {prodId:"56101810",label:"Bañeras o tinas para bebés"},
    {prodId:"56101811",label:"Cunas"},
    {prodId:"56101812",label:"Mesas para cambiar al bebé o accesorios"},
    {prodId:"56101813",label:"Asientos para el baño para bebés"},
    {prodId:"56101900",label:"Piezas de mobiliario y accesorios"},
    {prodId:"56101901",label:"Tapas de muebles o superficies de trabajo"},
    {prodId:"56101902",label:"Discos movibles para muebles"},
    {prodId:"56101903",label:"Protectores o almohadillas o copas para las patas de los muebles"},
    {prodId:"56101904",label:"Bases o patas o extensiones de patas para muebles"},
    {prodId:"56101905",label:"Ensamblajes o secciones de paneles"},
    {prodId:"56101906",label:"Tablas de extensión para mesas"},
    {prodId:"56101907",label:"Fundas para muebles"},
    {prodId:"56111500",label:"Sitios de trabajo y paquetes para la oficina"},
    {prodId:"56111501",label:"Paquetes de muebles de recepción para oficinas"},
    {prodId:"56111502",label:"Paquetes de muebles para ejecutivos no modulares"},
    {prodId:"56111503",label:"Paquetes de muebles para ejecutivos modulares"},
    {prodId:"56111504",label:"Paquetes de muebles de gerencia no modulares"},
    {prodId:"56111505",label:"Paquetes de muebles de gerencia modulares"},
    {prodId:"56111506",label:"Paquetes de muebles para personal no modulares"},
    {prodId:"56111507",label:"Paquetes de muebles para personal modulares"},
    {prodId:"56111508",label:"Paquetes de muebles para técnicos no modulares"},
    {prodId:"56111509",label:"Paquetes de muebles para técnicos modulares"},
    {prodId:"56111510",label:"Paquetes de muebles secretariales no modulares"},
    {prodId:"56111511",label:"Paquetes de muebles secretariales modulares"},
    {prodId:"56111512",label:"Paquetes de muebles para recepción no modulares"},
    {prodId:"56111513",label:"Paquetes de muebles de salas de juntas no modulares"},
    {prodId:"56111514",label:"Paquetes de muebles de mostrador modulares"},
    {prodId:"56111600",label:"Sistemas de paneles"},
    {prodId:"56111601",label:"Biombos (mamparas) para sistemas de paneles"},
    {prodId:"56111602",label:"Almacenamiento para sistemas de paneles"},
    {prodId:"56111603",label:"Organizadores para sistemas de paneles"},
    {prodId:"56111604",label:"Superficies de trabajo para sistemas de paneles"},
    {prodId:"56111606",label:"Partes o accesorios para sistemas de paneles"},
    {prodId:"56111700",label:"Casegood y sistemas no modulares"},
    {prodId:"56111701",label:"Escritorios no modulares"},
    {prodId:"56111702",label:"Cajoneras o estanterías no modulares"},
    {prodId:"56111703",label:"Almacenamiento no modular"},
    {prodId:"56111704",label:"Organizadores no modular"},
    {prodId:"56111705",label:"Iluminación o electricidad o componentes de información no modulares"},
    {prodId:"56111706",label:"Partes o accesorios no modulares"},
    {prodId:"56111707",label:"Repisas no modulares"},
    {prodId:"56111800",label:"Muebles independientes"},
    {prodId:"56111801",label:"Iluminación o electricidad o componentes de información de pie"},
    {prodId:"56111802",label:"Mesas individuales (sin apoyo)"},
    {prodId:"56111803",label:"Almacenamiento individual (sin apoyo)"},
    {prodId:"56111804",label:"Organizadores individual (sin apoyo)"},
    {prodId:"56111805",label:"Partes o accesorios individuales (sin apoyo)"},
    {prodId:"56111806",label:"Silla giratoria para peluquería"},
    {prodId:"56111900",label:"Muebles industriales"},
    {prodId:"56111903",label:"Unidades de almacenamiento industriales"},
    {prodId:"56111904",label:"Organizadores industriales"},
    {prodId:"56111905",label:"Partes o accesorios industriales"},
    {prodId:"56111906",label:"Gabinetes o cajones o estantes industriales"},
    {prodId:"56111907",label:"Carritos de herramientas industriales"},
    {prodId:"56112000",label:"Muebles de apoyo de la computadora"},
    {prodId:"56112001",label:"Iluminación o electricidad o componentes de información de soporte para computadores"},
    {prodId:"56112002",label:"Superficies de trabajo de soporte para computadores"},
    {prodId:"56112003",label:"Accesorios de almacenamiento de soporte para computadores"},
    {prodId:"56112004",label:"Organizadores de soporte para computadores"},
    {prodId:"56112005",label:"Partes o accesorios de soporte para computadores"},
    {prodId:"56112100",label:"Asientos"},
    {prodId:"56112101",label:"Silletería para auditorios o estadios o uso especiales"},
    {prodId:"56112102",label:"Sillas para grupos de trabajo"},
    {prodId:"56112103",label:"Sillas para visitantes"},
    {prodId:"56112104",label:"Sillas para ejecutivos"},
    {prodId:"56112105",label:"Sillas para descansar"},
    {prodId:"56112106",label:"Sillas altas (taburetes)"},
    {prodId:"56112107",label:"Partes o accesorios para sillas"},
    {prodId:"56112108",label:"Combinación de asiento con escritorio"},
    {prodId:"56112109",label:"Bancos"},
    {prodId:"56112110",label:"Sillas para músicos"},
    {prodId:"56112111",label:"Sillas de seguridad para bebés"},
    {prodId:"56112200",label:"Sistemas de escritorios"},
    {prodId:"56112201",label:"Biombos (separadores) para escritorio"},
    {prodId:"56112202",label:"Componentes para almacenamiento de escritorio"},
    {prodId:"56112203",label:"Componentes organizadores par escritorio"},
    {prodId:"56112204",label:"Superficies de trabajo"},
    {prodId:"56112205",label:"Componentes de iluminación, electricidad o información"},
    {prodId:"56112206",label:"Partes y accesorios relacionados con sistemas de escritorio"},
    {prodId:"56112300",label:"Partes y accesorios para asientos de auditorios o estadios o de uso especial"},
    {prodId:"56112301",label:"Espaldares"},
    {prodId:"56112302",label:"Armazones para fijar asientos"},
    {prodId:"56112303",label:"Pivotes (ejes) para asientos"},
    {prodId:"56112304",label:"Cojín del asiento"},
    {prodId:"56121000",label:"Mobiliario de biblioteca"},
    {prodId:"56121001",label:"Carritos para libros"},
    {prodId:"56121002",label:"Escritorios o componentes de circulación para bibliotecarios"},
    {prodId:"56121003",label:"Muebles para devolver libros"},
    {prodId:"56121004",label:"Unidades de catálogo de tarjetas"},
    {prodId:"56121005",label:"Estanterías para diccionarios"},
    {prodId:"56121006",label:"Bancas tapizadas"},
    {prodId:"56121007",label:"Tablas de acceso público"},
    {prodId:"56121008",label:"Unidades para ojear libros"},
    {prodId:"56121009",label:"Mesas de lectura inclinadas"},
    {prodId:"56121010",label:"Kioscos de libros"},
    {prodId:"56121011",label:"Exhibidores de discos compactos o de audio casetes para bibliotecas"},
    {prodId:"56121012",label:"Stands de islas giratorias"},
    {prodId:"56121014",label:"Bolsas o estantes para bolsas"},
    {prodId:"56121015",label:"Estantes móviles para libros"},
    {prodId:"56121100",label:"Mobiliario de clase de arte"},
    {prodId:"56121101",label:"Caballetes"},
    {prodId:"56121102",label:"Mesas de dibujo para estudiantes"},
    {prodId:"56121200",label:"Mobiliario de sala de primeros auxilios"},
    {prodId:"56121201",label:"Camilla de primeros auxilios"},
    {prodId:"56121300",label:"Accesorios de instalaciones educativas generales"},
    {prodId:"56121301",label:"Escenarios pequeños (por ejemplo para un coro)"},
    {prodId:"56121302",label:"Carritos para mover mesas o asientos"},
    {prodId:"56121303",label:"Esteras de caucho para pisos"},
    {prodId:"56121304",label:"Mesas para planos"},
    {prodId:"56121400",label:"Mobiliario de cafetería y comedor"},
    {prodId:"56121401",label:"Mesas móviles para bancos"},
    {prodId:"56121402",label:"Mesas móviles para taburetes"},
    {prodId:"56121403",label:"Mesas móviles"},
    {prodId:"56121500",label:"Mobiliario general de aula"},
    {prodId:"56121501",label:"Mesas para actividades"},
    {prodId:"56121502",label:"Asientos para aulas de clase"},
    {prodId:"56121503",label:"Bancos para aulas de clase"},
    {prodId:"56121504",label:"Taburetes para aulas de clase"},
    {prodId:"56121505",label:"Mesas para aulas de clase"},
    {prodId:"56121506",label:"Pupitres"},
    {prodId:"56121507",label:"Bancas cubículo (pupitres con bloqueo visual para evitar distracciones)"},
    {prodId:"56121508",label:"Pupitres de computador para estudiantes"},
    {prodId:"56121509",label:"Mesas de computador para estudiantes"},
    {prodId:"56121510",label:"Pupitres de profesor"},
    {prodId:"56121600",label:"Mobiliario para el tiempo de descanso y ocio creativo para guardería infantil e instalaciones para niños"},
    {prodId:"56121601",label:"Sets de sala de tamaño de niños"},
    {prodId:"56121602",label:"Sofás de tamaño de niños"},
    {prodId:"56121603",label:"Sillones de tamaño de niños"},
    {prodId:"56121604",label:"Pufs de tamaño de niños"},
    {prodId:"56121605",label:"Biombos de poca altura o paneles para jugar"},
    {prodId:"56121606",label:"Esterillas de descanso para niños"},
    {prodId:"56121607",label:"Estantes o percheros para esterillas de descanso para niños"},
    {prodId:"56121608",label:"Catres para niños"},
    {prodId:"56121609",label:"Cargadores de catres para niños"},
    {prodId:"56121610",label:"Centros de actividades para catres para niños"},
    {prodId:"56121611",label:"Mesas para bebés"},
    {prodId:"56121700",label:"Módulos de almacenamiento general y de libros para las aulas"},
    {prodId:"56121701",label:"Unidades de almacenamiento general"},
    {prodId:"56121702",label:"Unidades de almacenamiento de libros"},
    {prodId:"56121703",label:"Cubos (modulares) para guardar juguetes"},
    {prodId:"56121704",label:"Gabinetes institucionales de almacenamiento"},
    {prodId:"56121800",label:"Mobiliario y accesorios de aulas vocacionales"},
    {prodId:"56121801",label:"Gabinetes para almacenamiento de herramientas de educación técnico o gabinetes con herramientas"},
    {prodId:"56121802",label:"Gabinetes para almacenamiento de herramientas de taller general o gabinetes con herramientas"},
    {prodId:"56121803",label:"Gabinetes para almacenamiento de herramientas para trabajar en madera o gabinetes con herramientas"},
    {prodId:"56121804",label:"Escritorio técnico para instructores"},
    {prodId:"56121805",label:"Archivos planos"},
    {prodId:"56121900",label:"Mobiliario de demostración"},
    {prodId:"56121901",label:"Mesas de demostración de máquinas de coser"},
    {prodId:"56121902",label:"Stands exhibidores"},
    {prodId:"56121903",label:"Gabinete de preservación de reliquias"},
    {prodId:"56122000",label:"Muebles de laboratorio"},
    {prodId:"56122001",label:"Bancas de laboratorio"},
    {prodId:"56122002",label:"Unidades o accesorios de almacenamiento para laboratorios"},
    {prodId:"56122003",label:"Puestos de trabajo para laboratorios"},
    {prodId:"56122004",label:"Unidades de bases para lavamanos"},
    {prodId:"56131500",label:"Maniquíes y formas"},
    {prodId:"56131501",label:"Formas de busto"},
    {prodId:"56131502",label:"Formas de cabezas"},
    {prodId:"56131503",label:"Formas de cuello"},
    {prodId:"56131504",label:"Formas de cuerpo completo o maniquíes"},
    {prodId:"56131600",label:"Exhibidores y accesorios auto soportados para mercancías"},
    {prodId:"56131601",label:"Stands de piso"},
    {prodId:"56131602",label:"Postes de exhibición individuales"},
    {prodId:"56131603",label:"Mostradores de venta"},
    {prodId:"56131604",label:"Componentes de centros de pinturas de colores"},
    {prodId:"56131605",label:"Tablas de madera para exhibir productos de pintura"},
    {prodId:"56131606",label:"Abanico para exhibir muestras de pintura"},
    {prodId:"56131607",label:"Selección de inspiración de color"},
    {prodId:"56131700",label:"Aparatos, estantes, sistemas y accesorios para instalación de mercancías"},
    {prodId:"56131701",label:"Barras para colgar artículos"},
    {prodId:"56131702",label:"Canastas para mercancías"},
    {prodId:"56131703",label:"Ganchos o cascadas exhibidoras"},
    {prodId:"56141500",label:"Adornos cerámicos para el hogar"},
    {prodId:"56141501",label:"Tazones de cerámica"},
    {prodId:"56141502",label:"Platos grandes o pequeños de cerámica"},
    {prodId:"56141503",label:"Vasos de cerámica"},
    {prodId:"56141504",label:"Estatuillas o figuras de cerámica"},
    {prodId:"56141600",label:"Adornos de vidrio para el hogar"},
    {prodId:"56141601",label:"Tazones de vidrio"},
    {prodId:"56141602",label:"Vasos de vidrio"},
    {prodId:"56141603",label:"Platos grandes o pequeños de vidrio"},
    {prodId:"56141604",label:"Estatuillas o figuras de vidrio"},
    {prodId:"60101000",label:"Kits de matemáticas"},
    {prodId:"60101001",label:"Kits de matemáticas para sumar"},
    {prodId:"60101002",label:"Kits de matemáticas para dividir"},
    {prodId:"60101003",label:"Kits de matemáticas de fracciones"},
    {prodId:"60101004",label:"Kits de matemáticas para bachillerato básico"},
    {prodId:"60101005",label:"Kits de matemáticas para la primera infancia"},
    {prodId:"60101006",label:"Kits de matemáticas para mediciones"},
    {prodId:"60101007",label:"Kits de matemáticas para multiplicar"},
    {prodId:"60101008",label:"Kits de matemáticas para primaria"},
    {prodId:"60101009",label:"Kits de matemáticas para restar"},
    {prodId:"60101010",label:"Kits de matemáticas para bachillerato"},
    {prodId:"60101100",label:"Materiales electrónicos de aprendizaje"},
    {prodId:"60101101",label:"Lectores electrónicos de tarjetas"},
    {prodId:"60101102",label:"Materiales de aprendizaje electrónico basados en el plan de estudios"},
    {prodId:"60101103",label:"Globos terráqueos electrónicos"},
    {prodId:"60101104",label:"Máquinas electrónicas de pruebas"},
    {prodId:"60101300",label:"Adhesivos educativos y suministros"},
    {prodId:"60101301",label:"Adhesivos basados en la biblia"},
    {prodId:"60101302",label:"Adhesivos gigantes"},
    {prodId:"60101304",label:"Adhesivos de fotos"},
    {prodId:"60101305",label:"Adhesivos de recompensa"},
    {prodId:"60101306",label:"Adhesivos perfumados"},
    {prodId:"60101307",label:"Adhesivos de formas"},
    {prodId:"60101308",label:"Adhesivos brillantes"},
    {prodId:"60101309",label:"Adhesivos de recompensa con forma de estrella"},
    {prodId:"60101310",label:"Surtidos de adhesivos"},
    {prodId:"60101311",label:"Libros de adhesivos"},
    {prodId:"60101312",label:"Cajas de adhesivos"},
    {prodId:"60101313",label:"Calcomanías para tatuaje"},
    {prodId:"60101314",label:"Tarjetas didácticas de sumas"},
    {prodId:"60101315",label:"Tarjetas didácticas de cultura general"},
    {prodId:"60101316",label:"Tarjetas didácticas en blanco"},
    {prodId:"60101317",label:"Tarjetas didácticas de divisiones"},
    {prodId:"60101318",label:"Tarjetas didácticas electrónicas"},
    {prodId:"60101319",label:"Tarjetas didácticas de equivalencias"},
    {prodId:"60101320",label:"Tarjetas didácticas de fracciones"},
    {prodId:"60101321",label:"Tarjetas didácticas de mayor que o menor que"},
    {prodId:"60101322",label:"Tarjetas didácticas de multiplicaciones"},
    {prodId:"60101323",label:"Tarjetas didácticas de restas"},
    {prodId:"60101324",label:"Tarjetas didácticas del alfabeto"},
    {prodId:"60101325",label:"Tarjetas didácticas de construcción de palabras"},
    {prodId:"60101326",label:"Tarjetas didácticas de fonética"},
    {prodId:"60101327",label:"Tarjetas didácticas de escritura o escritura a mano"},
    {prodId:"60101328",label:"Tarjetas didácticas de los números"},
    {prodId:"60101329",label:"Tarjetas didácticas del dinero"},
    {prodId:"60101330",label:"Tarjetas didácticas de la hora"},
    {prodId:"60101331",label:"Tarjetas de los estados (departamentos)"},
    {prodId:"60101400",label:"Premios de la clase"},
    {prodId:"60101401",label:"Insignias"},
    {prodId:"60101402",label:"Botones de premio"},
    {prodId:"60101403",label:"Coronas de celebración"},
    {prodId:"60101404",label:"Joyas de recompense"},
    {prodId:"60101405",label:"Cintas o escarapelas para el salón de clases"},
    {prodId:"60101600",label:"Diplomas o certificados educativos"},
    {prodId:"60101601",label:"Certificados basados en la biblia"},
    {prodId:"60101602",label:"Certificados en blanco"},
    {prodId:"60101603",label:"Marcos para certificados"},
    {prodId:"60101604",label:"Soportes para certificados"},
    {prodId:"60101605",label:"Cintas para certificados"},
    {prodId:"60101606",label:"Diplomas"},
    {prodId:"60101607",label:"Certificados de idioma extranjero"},
    {prodId:"60101608",label:"Certificados de buena actitud"},
    {prodId:"60101609",label:"Certificados específicos de grado"},
    {prodId:"60101610",label:"Certificados específicos de asignatura"},
    {prodId:"60101700",label:"Materiales de recursos del profesor"},
    {prodId:"60101701",label:"Libros de recursos para la evaluación"},
    {prodId:"60101702",label:"Calendarios o recortables"},
    {prodId:"60101703",label:"Materiales pedagógicos para la formación del carácter"},
    {prodId:"60101704",label:"Libros de actividades en clase"},
    {prodId:"60101705",label:"Materiales pedagógicos para el pensamiento crítico"},
    {prodId:"60101706",label:"Guías para planes de estudios integrados"},
    {prodId:"60101707",label:"Guías para el plan de estudios"},
    {prodId:"60101708",label:"Gráficos de tela"},
    {prodId:"60101709",label:"Materiales para flanelógrafos"},
    {prodId:"60101710",label:"Regalos del educador"},
    {prodId:"60101711",label:"Sellos para calificar"},
    {prodId:"60101712",label:"Pases (permisos) para salir al pasillo"},
    {prodId:"60101713",label:"Materiales pedagógicos para la educación en el hogar"},
    {prodId:"60101714",label:"Recursos para tareas en casa"},
    {prodId:"60101715",label:"Libros de ideas"},
    {prodId:"60101716",label:"Materiales para tableros magnéticos"},
    {prodId:"60101717",label:"Placas o etiquetas de identificación"},
    {prodId:"60101718",label:"Libros de planificación del profesor"},
    {prodId:"60101719",label:"Cartelera con bolsillos"},
    {prodId:"60101720",label:"Postales de comunicación del profesor"},
    {prodId:"60101721",label:"Libros de recursos profesionales para el profesor"},
    {prodId:"60101722",label:"Libros de calificaciones de la clase del profesor"},
    {prodId:"60101723",label:"Diagramas de asientos de los estudiantes en el salón de clases"},
    {prodId:"60101724",label:"Carpetas o formularios del profesor suplente"},
    {prodId:"60101725",label:"Libros de actividades o recursos tecnológicos"},
    {prodId:"60101726",label:"Guías de referencia sobre tecnología"},
    {prodId:"60101727",label:"Materiales pedagógicos  para hacer exámenes"},
    {prodId:"60101728",label:"Materiales de pedagógicos para unidades temáticas"},
    {prodId:"60101729",label:"Kits de enseñanza para bachillerato básico"},
    {prodId:"60101730",label:"Manuales de laboratorio"},
    {prodId:"60101731",label:"Rollos o tiras de frases"},
    {prodId:"60101732",label:"Punteros"},
    {prodId:"60101800",label:"Recursos educativos de las clases religiosas"},
    {prodId:"60101801",label:"Guías de referencia bíblica"},
    {prodId:"60101802",label:"Obras de teatro basadas en la biblia"},
    {prodId:"60101803",label:"Libros de actividades o recursos basados en la biblia"},
    {prodId:"60101804",label:"Libros de recursos para actividades en la escuela dominical"},
    {prodId:"60101805",label:"Recursos para escuelas bíblicas de vacaciones"},
    {prodId:"60101806",label:"Emblemas o símbolos sagrados"},
    {prodId:"60101807",label:"Rosarios"},
    {prodId:"60101808",label:"Ruedas de oración"},
    {prodId:"60101809",label:"Suministros o kits de productos religiosos"},
    {prodId:"60101810",label:"Patenas"},
    {prodId:"60101811",label:"Vestimentas"},
    {prodId:"60101900",label:"Recursos y materiales de destreza alfabética"},
    {prodId:"60101901",label:"Libros de actividades del alfabeto"},
    {prodId:"60101902",label:"Cubos con el alfabeto"},
    {prodId:"60101903",label:"Cintas adhesivas de escritorio con el alfabeto"},
    {prodId:"60101904",label:"Kits del alfabeto"},
    {prodId:"60101905",label:"Fichas con letras del alfabeto"},
    {prodId:"60101906",label:"Postales con el alfabeto"},
    {prodId:"60101907",label:"Guías de referencia del alfabeto"},
    {prodId:"60101908",label:"Libros de recursos del alfabeto"},
    {prodId:"60101909",label:"Sellos del alfabeto"},
    {prodId:"60101910",label:"Tarjetas del alfabeto para murales"},
    {prodId:"60101911",label:"Alfabetos táctiles"},
    {prodId:"60102000",label:"Materiales de recursos de creación de palabras y accesorios"},
    {prodId:"60102001",label:"Espejos para uso logopédico"},
    {prodId:"60102002",label:"Materiales pedagógicos para ortografía"},
    {prodId:"60102003",label:"Libros de actividades para construcción de palabras"},
    {prodId:"60102004",label:"Kits para construcción de palabras"},
    {prodId:"60102005",label:"Libros de recursos para construcción de palabras"},
    {prodId:"60102006",label:"Fichas para construcción de palabras"},
    {prodId:"60102007",label:"Murales de palabras"},
    {prodId:"60102300",label:"Libros de lectura y recursos"},
    {prodId:"60102301",label:"Libros de actividades de lectura"},
    {prodId:"60102302",label:"Libros de lectura para principiantes"},
    {prodId:"60102303",label:"Libros de literatura infantil basados en la biblia"},
    {prodId:"60102304",label:"Libros de literatura infantil"},
    {prodId:"60102305",label:"Habilidades para la lectura crítica"},
    {prodId:"60102306",label:"Flanelógrafos"},
    {prodId:"60102307",label:"Libros de recursos de poesía"},
    {prodId:"60102309",label:"Kits o materiales de desarrollo de la lectura"},
    {prodId:"60102310",label:"Libros de recursos de lectura"},
    {prodId:"60102311",label:"Unidades temáticas de lectura"},
    {prodId:"60102312",label:"Libros de recursos o actividades de vocabulario."},
    {prodId:"60102400",label:"Recursos de cuenta y temprano aprendizaje de matemáticas y accesorios"},
    {prodId:"60102401",label:"Abacos"},
    {prodId:"60102402",label:"Libros de recursos o actividades para trabajar con material didáctico manipulable de matemáticas temprana"},
    {prodId:"60102403",label:"Tarjetas de actividades para trabajar material didáctico manipulable de matemáticas temprana"},
    {prodId:"60102404",label:"Cuentas o sets de actividades con cuentas para matemáticas temprana"},
    {prodId:"60102405",label:"Contadores o sets de actividades con contadores para matemáticas temprana"},
    {prodId:"60102406",label:"Bandejas o boles para contar o clasificar para matemáticas tempranas"},
    {prodId:"60102407",label:"Cordones o sets de cordones para matemáticas temprana"},
    {prodId:"60102408",label:"Material didáctico manipulable de vinculación o sets de actividades de vinculación para matemáticas temprana"},
    {prodId:"60102409",label:"Fichas para juegos de matemáticas"},
    {prodId:"60102410",label:"Tarjetas con números"},
    {prodId:"60102411",label:"Modelos con formas de números o accesorios"},
    {prodId:"60102412",label:"Tableros perforados para matemáticas básicas"},
    {prodId:"60102413",label:"Ganchos para tablero perforado para  matemáticas temprana"},
    {prodId:"60102414",label:"Material didácticos manipulativos de clasificación o sets de actividades de clasificación para matemáticas temprana"},
    {prodId:"60102500",label:"Recursos y materiales de numeración y operaciones matemáticas básicas"},
    {prodId:"60102501",label:"Libros de recursos o de actividades de la suma"},
    {prodId:"60102502",label:"Modelos de las operaciones básicas"},
    {prodId:"60102503",label:"Guías de referencia de las operaciones básicas"},
    {prodId:"60102504",label:"Libros de recursos o actividades de la división"},
    {prodId:"60102505",label:"Libros de recursos o actividades de la multiplicación"},
    {prodId:"60102506",label:"Libros de recursos o actividades de la resta"},
    {prodId:"60102507",label:"Cintas adhesivas de escritorio con los números"},
    {prodId:"60102508",label:"Tableros o gráficas del uno al cien"},
    {prodId:"60102509",label:"Fichas con números del uno al cien"},
    {prodId:"60102510",label:"Kits de números"},
    {prodId:"60102511",label:"Rectas numéricas"},
    {prodId:"60102512",label:"libros de recursos o actividades de numeración"},
    {prodId:"60102513",label:"Dominós"},
    {prodId:"60102600",label:"Materiales de recursos del profesor de resolución de problemas, datos, posibilidades y probabilidades"},
    {prodId:"60102601",label:"Fichas o contadores de dos caras"},
    {prodId:"60102602",label:"Pirinolas"},
    {prodId:"60102603",label:"Juegos de dados"},
    {prodId:"60102604",label:"Libros de recursos o actividades de probabilidades"},
    {prodId:"60102605",label:"Libros de recursos o actividades de lógica"},
    {prodId:"60102606",label:"Bloques de atributos"},
    {prodId:"60102607",label:"Tarjetas de actividades de bloques de atributos"},
    {prodId:"60102608",label:"Libros de recursos o actividades de atributos"},
    {prodId:"60102609",label:"Esterilla de representación gráfica"},
    {prodId:"60102610",label:"Libros de recursos o actividades de representación gráfica"},
    {prodId:"60102611",label:"Juegos de lógica"},
    {prodId:"60102612",label:"Kits o sets de atributos"},
    {prodId:"60102613",label:"Libros de recursos o actividades de resolución de problemas"},
    {prodId:"60102614",label:"Tarjetas de actividades de resolución de problemas"},
    {prodId:"60102700",label:"Materiales de recursos del profesor de pensamiento lógico, percepción espacial, identificación de coincidencias y patrones"},
    {prodId:"60102701",label:"Libros de recursos o actividades de bloques geométricos o de bloques para patrones"},
    {prodId:"60102702",label:"Bloques para patrones"},
    {prodId:"60102703",label:"Tarjetas de patrones o actividades de bloques para patrones"},
    {prodId:"60102704",label:"Sets de actividades o juegos de bloques para patrones"},
    {prodId:"60102705",label:"Adhesivos de bloques para patrones"},
    {prodId:"60102706",label:"Espejo de bloques para patrones"},
    {prodId:"60102707",label:"Afiches o tablas de bloques para patrones"},
    {prodId:"60102708",label:"Bloques lógicos"},
    {prodId:"60102709",label:"Tarjetas de patrones o actividades de bloques lógicos"},
    {prodId:"60102710",label:"Sets de actividades de bloques lógicos"},
    {prodId:"60102711",label:"Libros de recursos o actividades del tangram"},
    {prodId:"60102712",label:"Tarjetas de patrones o actividades del tangram"},
    {prodId:"60102713",label:"Sets de actividades de rompecabezas tangram"},
    {prodId:"60102714",label:"Rompecabezas tangram"},
    {prodId:"60102800",label:"Materiales de recursos del profesor de valor de posición y base diez"},
    {prodId:"60102801",label:"Bloques de base diez"},
    {prodId:"60102802",label:"Libros de recursos o actividades de base diez o valor posicional"},
    {prodId:"60102803",label:"Tarjetas de actividades de  base diez o valor posicional"},
    {prodId:"60102804",label:"Sellos de caucho de base diez"},
    {prodId:"60102805",label:"Cuadrículas de valor posicional"},
    {prodId:"60102806",label:"Sets de actividades o juegos de valor posicional"},
    {prodId:"60102807",label:"Modelos o accesorios de valor posicional"},
    {prodId:"60102900",label:"Materiales de recursos del profesor del dinero y la hora"},
    {prodId:"60102901",label:"Libros de recursos o actividades sobre el dinero"},
    {prodId:"60102902",label:"Billetes de juego para la clase"},
    {prodId:"60102903",label:"Monedas de juego para la clase"},
    {prodId:"60102904",label:"Cubos o dados de monedas"},
    {prodId:"60102905",label:"Dinero magnético"},
    {prodId:"60102906",label:"Filminas de billetes"},
    {prodId:"60102907",label:"Filminas de monedas"},
    {prodId:"60102908",label:"Rompecabezas del dinero"},
    {prodId:"60102909",label:"Sellos de caucho del dinero"},
    {prodId:"60102910",label:"Alcancía de monedas"},
    {prodId:"60102911",label:"Kits o juegos del dinero"},
    {prodId:"60102912",label:"Cajas registradoras de juguete"},
    {prodId:"60102913",label:"Guías de referencia del dinero"},
    {prodId:"60102914",label:"Libros de recursos o actividades de la hora"},
    {prodId:"60102915",label:"Sellos de caucho de la hora"},
    {prodId:"60102916",label:"Kits de la hora"},
    {prodId:"60102917",label:"Guías de referencia de la hora"},
    {prodId:"60103000",label:"Fracciones, pentominós y materiales de enseñanza de decimales"},
    {prodId:"60103001",label:"Círculos o cuadrados de fracciones"},
    {prodId:"60103002",label:"Cuadrados de decimales"},
    {prodId:"60103003",label:"Libros de actividades de fracciones"},
    {prodId:"60103004",label:"Barras de fracciones"},
    {prodId:"60103005",label:"Gráficos de fracciones"},
    {prodId:"60103006",label:"Dados de fracciones"},
    {prodId:"60103007",label:"Juegos de fracciones"},
    {prodId:"60103008",label:"Kits de fracciones"},
    {prodId:"60103009",label:"Fichas con fracciones"},
    {prodId:"60103010",label:"Discos con fracciones"},
    {prodId:"60103012",label:"Libro de actividades de pentominós"},
    {prodId:"60103013",label:"Tarjetas de patrones de pentominós"},
    {prodId:"60103100",label:"Materiales de recursos del profesor de geometría"},
    {prodId:"60103101",label:"Libros de recursos o  actividades de geometría"},
    {prodId:"60103102",label:"Afiches o cuadros de geometría"},
    {prodId:"60103103",label:"Tableros geométricos"},
    {prodId:"60103104",label:"Sets de construcción de formas geométricas"},
    {prodId:"60103105",label:"Tableros geométricos de doble cara"},
    {prodId:"60103106",label:"Kits de actividades o juegos para tableros geométricos"},
    {prodId:"60103107",label:"Bandas elásticas para tableros geométricos"},
    {prodId:"60103108",label:"Tarjetas de actividades de tableros geométricos"},
    {prodId:"60103109",label:"Instrumentos de dibujo geométrico para la pizarra"},
    {prodId:"60103110",label:"Guías de referencia de geometría"},
    {prodId:"60103111",label:"Espejo geométrico"},
    {prodId:"60103112",label:"Modelos geométricos sólidos"},
    {prodId:"60103400",label:"Recursos de habilidad de lectura de mapas y geografía y accesorios"},
    {prodId:"60103401",label:"Afiches o carteles de geografía"},
    {prodId:"60103402",label:"Libros de recursos o actividades de los continentes"},
    {prodId:"60103403",label:"Materiales de aprendizaje electrónico de ciencias sociales"},
    {prodId:"60103404",label:"Guías de referencia de geografía"},
    {prodId:"60103405",label:"Estantes para mapas"},
    {prodId:"60103406",label:"Kits de plantillas de mapas"},
    {prodId:"60103407",label:"Mapas murales portátiles"},
    {prodId:"60103408",label:"Libros de recursos o actividades de geografía"},
    {prodId:"60103409",label:"Medidores de mapas"},
    {prodId:"60103410",label:"Material didáctico para la enseñanza de mapas"},
    {prodId:"60103600",label:"Recursos y actividades multiculturales"},
    {prodId:"60103601",label:"Recursos de civilizaciones de la antigüedad"},
    {prodId:"60103602",label:"Recursos de tradiciones, rituales o costumbres"},
    {prodId:"60103603",label:"Recursos de diversidad étnica"},
    {prodId:"60103604",label:"Recursos de genealogía"},
    {prodId:"60103605",label:"Recursos para festivos multiculturales"},
    {prodId:"60103606",label:"Unidades temáticas multiculturales"},
    {prodId:"60103700",label:"Recursos de lenguas extranjeras"},
    {prodId:"60103701",label:"Recursos para aprender a hablar español"},
    {prodId:"60103702",label:"Recursos para aprender a hablar francés"},
    {prodId:"60103703",label:"Recursos para aprender a hablar alemán"},
    {prodId:"60103704",label:"Recursos para aprender a hablar inglés"},
    {prodId:"60103705",label:"Recursos para aprender a hablar latín"},
    {prodId:"60103706",label:"Recursos para aprender a hablar italiano"},
    {prodId:"60103800",label:"Recursos de enseñanza de historia"},
    {prodId:"60103801",label:"Libros de recursos de historia africana"},
    {prodId:"60103802",label:"Afiches o cuadros de historia"},
    {prodId:"60103803",label:"Libros de recursos de historia europea"},
    {prodId:"60103804",label:"Mapas históricos"},
    {prodId:"60103805",label:"Unidades temáticas de historia"},
    {prodId:"60103806",label:"Tarjetas de fotos de historia"},
    {prodId:"60103807",label:"Libros de recursos de historia"},
    {prodId:"60103808",label:"Recursos de historia de la mujer"},
    {prodId:"60103809",label:"Recursos de historia mundial"},
    {prodId:"60103900",label:"Organismos vivos, especímenes conservados y materiales relacionados"},
    {prodId:"60103903",label:"Modelos de anfibios"},
    {prodId:"60103904",label:"Cultivos de hongos"},
    {prodId:"60103905",label:"Kits o materiales de cultivos"},
    {prodId:"60103906",label:"Cultivos de protozoos"},
    {prodId:"60103907",label:"Plantas de acuario"},
    {prodId:"60103908",label:"Plantas de terrario"},
    {prodId:"60103909",label:"Invertebrados vivos"},
    {prodId:"60103911",label:"Vertebrados vivos"},
    {prodId:"60103915",label:"Kits o materiales de disección"},
    {prodId:"60103916",label:"Embriones conservados"},
    {prodId:"60103918",label:"Biosferas"},
    {prodId:"60103919",label:"Kits o materiales para experimentos de biología"},
    {prodId:"60103920",label:"Kits o materiales para tinción"},
    {prodId:"60103921",label:"Especímenes de ciclo vital preservado"},
    {prodId:"60103922",label:"Guías de referencia de biología"},
    {prodId:"60103923",label:"Espécimen de esqueleto o hueso o concha"},
    {prodId:"60103924",label:"Libros de recursos o actividades de biología"},
    {prodId:"60103925",label:"Kits de estudio o actividades de biología"},
    {prodId:"60103926",label:"Afiches o cuadros de biología"},
    {prodId:"60103927",label:"Especímenes preservados de cuerpos u órganos de plantas"},
    {prodId:"60103928",label:"Tarjetas de fotos o actividades de biología"},
    {prodId:"60103929",label:"Especímenes del ciclo vital de plantas"},
    {prodId:"60103930",label:"Combinación de especímenes y organismos"},
    {prodId:"60103931",label:"Especímenes del cuerpo animal, partes u órganos"},
    {prodId:"60103932",label:"Exhibiciones de ecosistemas"},
    {prodId:"60103933",label:"Especímenes del cuerpo humano, partes u órganos"},
    {prodId:"60103934",label:"Cultivos de tejidos"},
    {prodId:"60103936",label:"Sets o diagramas de anatomía"},
    {prodId:"60104100",label:"Sistemas del cuerpo y materiales relacionados"},
    {prodId:"60104101",label:"Modelos del cuerpo humano, partes u órganos"},
    {prodId:"60104102",label:"Modelos de células"},
    {prodId:"60104103",label:"Kits de enseñanza de células"},
    {prodId:"60104104",label:"Kits de enseñanza de los sistemas del cuerpo"},
    {prodId:"60104105",label:"Materiales de enseñanza de los sistemas del cuerpo"},
    {prodId:"60104106",label:"Modelos del cuerpo de plantas, partes u órganos"},
    {prodId:"60104107",label:"Modelos del cuerpo de animales, partes u órganos"},
    {prodId:"60104200",label:"Pruebas del agua, conservación y ecología"},
    {prodId:"60104201",label:"Productos químicos de prueba del agua"},
    {prodId:"60104202",label:"Kits de muestreo y pruebas del agua"},
    {prodId:"60104203",label:"Modelos del agua"},
    {prodId:"60104204",label:"Materiales de ecología acuática"},
    {prodId:"60104400",label:"Geología y ciencias de la tierra"},
    {prodId:"60104401",label:"Sets de especímenes de roca"},
    {prodId:"60104402",label:"Especímenes de roca"},
    {prodId:"60104403",label:"Fósiles"},
    {prodId:"60104404",label:"Modelos de accidentes geográficos"},
    {prodId:"60104405",label:"Modelos de fósiles"},
    {prodId:"60104406",label:"Herramientas para geología de campo"},
    {prodId:"60104407",label:"Mesas de simulación de corrientes de agua"},
    {prodId:"60104408",label:"Kits de estudio de geología"},
    {prodId:"60104409",label:"Aparato para la formación de nubes"},
    {prodId:"60104410",label:"Aparato para observación lunar"},
    {prodId:"60104411",label:"Simulador del tiempo atmosférico"},
    {prodId:"60104412",label:"Aparato de acumulación de tierra"},
    {prodId:"60104413",label:"Aparato para la fuerza de coriolis"},
    {prodId:"60104414",label:"Globo terrestre"},
    {prodId:"60104415",label:"Modelo mineral"},
    {prodId:"60104416",label:"Cilindro rotatorio"},
    {prodId:"60104500",label:"Química, electroquímica y microquímica"},
    {prodId:"60104501",label:"Afiches o cuadros de la tabla periódica"},
    {prodId:"60104502",label:"Kits de análisis del consumidor"},
    {prodId:"60104503",label:"Kits para clase de química"},
    {prodId:"60104504",label:"Kits de demostración de química"},
    {prodId:"60104505",label:"Modelos atómicos"},
    {prodId:"60104506",label:"Modelos moleculares"},
    {prodId:"60104507",label:"Herramientas para demostración electroquímica"},
    {prodId:"60104508",label:"Kits de electroquímica"},
    {prodId:"60104509",label:"Pilas de combustible"},
    {prodId:"60104511",label:"Instrumentos de microquímica"},
    {prodId:"60104512",label:"Aparato para la gota de aceite de millikan"},
    {prodId:"60104600",label:"Materiales de física mecánica"},
    {prodId:"60104601",label:"Mesas de fuerzas"},
    {prodId:"60104602",label:"Modelos de gravedad o sets de modelos"},
    {prodId:"60104604",label:"Planos inclinados"},
    {prodId:"60104605",label:"Aparato de fricción"},
    {prodId:"60104606",label:"Carros de física"},
    {prodId:"60104607",label:"Equipo de péndulo"},
    {prodId:"60104608",label:"Equipo de torque"},
    {prodId:"60104609",label:"Aparato de movimiento de proyectiles"},
    {prodId:"60104610",label:"Mesas de aire"},
    {prodId:"60104611",label:"Equipos de aire"},
    {prodId:"60104612",label:"Aparato para luz o foto"},
    {prodId:"60104613",label:"Hemisferio de magdeburgo"},
    {prodId:"60104614",label:"Aparato del anillo de newton"},
    {prodId:"60104615",label:"Pista de aire"},
    {prodId:"60104616",label:"Aparato de fuerza centrípeta"},
    {prodId:"60104617",label:"Aparato del modulo elástico de tensión"},
    {prodId:"60104618",label:"Demostrador newtoniano"},
    {prodId:"60104619",label:"Temporizador de registros"},
    {prodId:"60104700",label:"Materiales de física de energía y electricidad"},
    {prodId:"60104701",label:"Dispositivos de recolección solar"},
    {prodId:"60104702",label:"Kits solares"},
    {prodId:"60104703",label:"Kits de demostración de energía"},
    {prodId:"60104704",label:"Kits de clases de energía"},
    {prodId:"60104705",label:"Kits de demostración de materia"},
    {prodId:"60104706",label:"Kits de clases de materia"},
    {prodId:"60104707",label:"Manómetros"},
    {prodId:"60104708",label:"Aparato de difusión de gases"},
    {prodId:"60104709",label:"Aparato de conducción de calor"},
    {prodId:"60104710",label:"Aparato de radiación y convección térmica"},
    {prodId:"60104711",label:"Aparato para la ley de hooke"},
    {prodId:"60104712",label:"Aparato de frank-hertz"},
    {prodId:"60104713",label:"Aparato de expansión de líquidos"},
    {prodId:"60104714",label:"Aparato para la ley de boyle"},
    {prodId:"60104715",label:"Aparato para el principio de pascal"},
    {prodId:"60104716",label:"Aparato para el principio de arquímedes"},
    {prodId:"60104717",label:"Aparato para el teorema de bernoulli"},
    {prodId:"60104718",label:"Aparato de bombeo centrífugo"},
    {prodId:"60104719",label:"Tubo de ventury"},
    {prodId:"60104720",label:"Aparato para la medición del número de reynolds"},
    {prodId:"60104721",label:"Aparato del momento de inercia"},
    {prodId:"60104722",label:"Aparato para medir  la fuerza gravitacional"},
    {prodId:"60104723",label:"Balanza de torsión"},
    {prodId:"60104724",label:"Analizador del sistema de refrigeración"},
    {prodId:"60104726",label:"Aparato para la ley de torricelli"},
    {prodId:"60104800",label:"Materiales de física del sonido y las ondas"},
    {prodId:"60104801",label:"Generadores de ondas"},
    {prodId:"60104802",label:"Tanques de ondas"},
    {prodId:"60104803",label:"Fuentes de ondas"},
    {prodId:"60104804",label:"Sets de demostración de ondas"},
    {prodId:"60104805",label:"Diapasones"},
    {prodId:"60104806",label:"Demostradores del efecto doppler"},
    {prodId:"60104807",label:"Equipo de resonancia"},
    {prodId:"60104808",label:"Fonómetros"},
    {prodId:"60104809",label:"Aparato de ondas"},
    {prodId:"60104810",label:"Espectroscopios"},
    {prodId:"60104811",label:"Diagramas del espectro"},
    {prodId:"60104812",label:"Kits de demostración de la luz"},
    {prodId:"60104813",label:"Cartas o muestras de colores"},
    {prodId:"60104814",label:"Radiómetro"},
    {prodId:"60104815",label:"Equipo de refracción o reflexión"},
    {prodId:"60104816",label:"Sets o kits ópticos"},
    {prodId:"60104817",label:"Aparato del tanque ripple"},
    {prodId:"60104818",label:"Aparato de la constante de planck"},
    {prodId:"60104819",label:"Lámpara de balmer"},
    {prodId:"60104820",label:"Aparato de kundt"},
    {prodId:"60104821",label:"Aparato del efecto fotoeléctrico"},
    {prodId:"60104822",label:"Set de banco óptico elemental"},
    {prodId:"60104823",label:"Oscilador armónico simple"},
    {prodId:"60104824",label:"Aparato de óptica de ondas"},
    {prodId:"60104825",label:"Aparato de holografía"},
    {prodId:"60104826",label:"Espejo mágico óptico"},
    {prodId:"60104827",label:"Equipo para medición de distancias focales"},
    {prodId:"60104900",label:"Materiales de física de electricidad"},
    {prodId:"60104901",label:"Generadores de van de graff"},
    {prodId:"60104902",label:"Aparato de electrostática"},
    {prodId:"60104903",label:"Kits de electrostática"},
    {prodId:"60104904",label:"Kits de electricidad"},
    {prodId:"60104905",label:"Tableros de demostración de electricidad"},
    {prodId:"60104906",label:"Kits de baterías"},
    {prodId:"60104907",label:"Generadores portátiles"},
    {prodId:"60104908",label:"Aparato de electromagnetismo"},
    {prodId:"60104909",label:"Aparato de magnetismo"},
    {prodId:"60104910",label:"Electroimanes"},
    {prodId:"60104911",label:"Campanas eléctricas o accesorios"},
    {prodId:"60104912",label:"Alambres o cables eléctricos"},
    {prodId:"60104913",label:"Aparato para la ley de ohm"},
    {prodId:"60104914",label:"Tubo de descarga"},
    {prodId:"60104915",label:"Aparato para experimentos de transformadores"},
    {prodId:"60104916",label:"Aparato del efecto termoeléctrico"},
    {prodId:"60104917",label:"Aparato de voltaje constante"},
    {prodId:"60104918",label:"Aparato fotoeléctrico"},
    {prodId:"60104919",label:"Botella de leyden"},
    {prodId:"60104920",label:"Cámara de nubes"},
    {prodId:"60104921",label:"Aparato del efecto zeeman"},
    {prodId:"60104922",label:"Aparato para la ley de coulomb"},
    {prodId:"60104923",label:"Descargador"},
    {prodId:"60104924",label:"Aparato para la ley de faraday"},
    {prodId:"60104925",label:"Aparato para la ley de fleming"},
    {prodId:"60104926",label:"Aparato del efecto hall"},
    {prodId:"60104927",label:"Sistema de pruebas de armaduras"},
    {prodId:"60104928",label:"Tubo de crookes"},
    {prodId:"60104929",label:"Generador de campo magnético rotatorio"},
    {prodId:"60104930",label:"Dispositivo de demostración de luz fluorescente"},
    {prodId:"60105000",label:"Materiales de física nuclear"},
    {prodId:"60105001",label:"Sets de radioactividad"},
    {prodId:"60105002",label:"Contadores geiger"},
    {prodId:"60105003",label:"Aparato de electrones"},
    {prodId:"60105005",label:"Diapositivas de física nuclear"},
    {prodId:"60105006",label:"Diagramas de física nuclear"},
    {prodId:"60105104",label:"Kits de aviones"},
    {prodId:"60105400",label:"Materiales educativos de vida independiente, administración del dinero y economía doméstica"},
    {prodId:"60105401",label:"Materiales de enseñanza del manejo del dinero o de las finanzas personales"},
    {prodId:"60105402",label:"Materiales de enseñanza de habilidades de consumo y comparación de precios"},
    {prodId:"60105403",label:"Materiales de enseñanza de vida independiente"},
    {prodId:"60105404",label:"Materiales de enseñanza para la comprensión de los créditos o préstamos de consumo"},
    {prodId:"60105405",label:"Materiales de enseñanza de comparación o cobertura de seguros"},
    {prodId:"60105406",label:"Materiales de enseñanza para compra de casa"},
    {prodId:"60105407",label:"Materiales de enseñanza para alquiler de apartamento"},
    {prodId:"60105408",label:"Materiales de enseñanza para compra de carro"},
    {prodId:"60105409",label:"Materiales de enseñanza  de publicidad o comercialización de marcas"},
    {prodId:"60105410",label:"Materiales de enseñanza de habilidades para la vida en familia o para el establecimiento de relaciones"},
    {prodId:"60105411",label:"Materiales de enseñanza para el desarrollo de la autoestima y el autoconcepto"},
    {prodId:"60105412",label:"Materiales de enseñanza de prevención de la violencia o de educación para la evasión de la violencia"},
    {prodId:"60105413",label:"Materiales de enseñanza  de formación para el manejo de la ira"},
    {prodId:"60105414",label:"Materiales educativos para la enseñanza de la paciencia"},
    {prodId:"60105415",label:"Materiales de enseñanza de formación de la tolerancia"},
    {prodId:"60105416",label:"Materiales de enseñanza de seguridad personal"},
    {prodId:"60105417",label:"Materiales de enseñanza de resolución de conflictos personales"},
    {prodId:"60105418",label:"Guías de asesoramiento práctico para adolescentes"},
    {prodId:"60105419",label:"Materiales de enseñanza de desarrollo de aptitudes sociales"},
    {prodId:"60105420",label:"Materiales de enseñanza de modales o etiqueta o cortesía"},
    {prodId:"60105421",label:"Materiales de enseñanza para entender o tratar con la diversidad cultural"},
    {prodId:"60105422",label:"Materiales de enseñanza para interpretar el lenguaje corporal"},
    {prodId:"60105423",label:"Materiales de enseñanza  para el desarrollo de la capacidad de adaptación"},
    {prodId:"60105424",label:"Materiales de enseñanza de comprensión del servicio a la comunidad"},
    {prodId:"60105425",label:"Materiales de enseñanza para el desarrollo de habilidades de negación"},
    {prodId:"60105426",label:"Materiales de enseñanza de habilidades de responsabilidad o toma de decisiones"},
    {prodId:"60105427",label:"Materiales de enseñanza de comprensión de los derechos legales de los adolescentes"},
    {prodId:"60105428",label:"Materiales de enseñanza de las repercusiones de la deserción escolar"},
    {prodId:"60105429",label:"Videos de las relaciones interraciales"},
    {prodId:"60105500",label:"Materiales educativos de diseño del hogar y de interiores"},
    {prodId:"60105501",label:"Materiales de enseñanza de feng shui"},
    {prodId:"60105502",label:"Materiales de enseñanza para utilizar color o pintura para la decoración del hogar"},
    {prodId:"60105503",label:"Materiales de enseñanza  para la  planificación o diseño del hogar"},
    {prodId:"60105504",label:"Materiales de enseñanza de diseño de jardines"},
    {prodId:"60105505",label:"Materiales de enseñanza para la decoración o amueblamiento del hogar"},
    {prodId:"60105600",label:"Materiales educativos de preparación de alimentos, nutrición y educación sobre la salud"},
    {prodId:"60105601",label:"Materiales de enseñanza de dietas balanceadas o pautas alimentarias"},
    {prodId:"60105602",label:"Materiales de enseñanza de habilidades de planificación de menús para planes de estudios de nutrición"},
    {prodId:"60105603",label:"Materiales de enseñanza de comprensión del etiquetado nutricional"},
    {prodId:"60105604",label:"Materiales de enseñanza de compra de alimentos"},
    {prodId:"60105605",label:"Módulos de demostración de opciones de alimentos saludables"},
    {prodId:"60105606",label:"Materiales de enseñanza de comprensión de los efectos de las grasas alimenticias"},
    {prodId:"60105607",label:"Materiales de enseñanza de comprensión del vegetarianismo"},
    {prodId:"60105608",label:"Libros de cocina o recetarios"},
    {prodId:"60105609",label:"Materiales de enseñanza de  educación acerca de los trastornos alimenticios"},
    {prodId:"60105610",label:"Materiales de enseñanza sobre el control de peso o el ejercicio"},
    {prodId:"60105611",label:"Materiales de enseñanza de la medición de sólidos o líquidos en la cocina"},
    {prodId:"60105612",label:"Materiales de enseñanza de equivalencias o matemáticas en la cocina"},
    {prodId:"60105613",label:"Materiales de enseñanza sobre los utensilios de cocina"},
    {prodId:"60105614",label:"Materiales de enseñanza sobre la higiene o la seguridad en la cocina"},
    {prodId:"60105615",label:"Materiales de enseñanza sobre la inocuidad de los alimentos"},
    {prodId:"60105616",label:"Materiales de enseñanza sobre actividades de la ciencia de los alimentos"},
    {prodId:"60105617",label:"Materiales de enseñanza de habilidades culinarias"},
    {prodId:"60105618",label:"Materiales de enseñanza de etiqueta o modales en la mesa"},
    {prodId:"60105619",label:"Materiales de enseñanza sobre cómo poner la mesa"},
    {prodId:"60105620",label:"Materiales de enseñanza de capacitación de los servicios de alimentación"},
    {prodId:"60105621",label:"Materiales de enseñanza de educación acerca del abuso de drogas o  tabaco o alcohol"},
    {prodId:"60105622",label:"Simuladores para fumar"},
    {prodId:"60105623",label:"Materiales de enseñanza de comprensión de las adicciones o cómo evitarlas"},
    {prodId:"60105624",label:"Materiales de enseñanza de los síntomas de depresión en adolescentes"},
    {prodId:"60105625",label:"Materiales de enseñanza de capacitación para evitar el suicidio de adolescentes"},
    {prodId:"60105626",label:"Materiales de enseñanza para lidiar con el estrés"},
    {prodId:"60105700",label:"Álbumes de recuerdos y suministros"},
    {prodId:"60105701",label:"Álbumes de recuerdos"},
    {prodId:"60105702",label:"Tornillos de extensión para álbumes de recuerdos"},
    {prodId:"60105703",label:"Papel para álbumes de recuerdos"},
    {prodId:"60105704",label:"Barras de pegante libres de ácido"},
    {prodId:"60105705",label:"Cinta pegante libre de ácido"},
    {prodId:"60105800",label:"Materiales educativos de diseño textil y de ropa"},
    {prodId:"60105801",label:"Materiales de enseñanza de habilidades para la costura"},
    {prodId:"60105802",label:"Materiales para proyectos de costura"},
    {prodId:"60105803",label:"Materiales de enseñanza de comprensión de la confección o hechura de ropa"},
    {prodId:"60105804",label:"Materiales de enseñanza de diseño o modas de ropa"},
    {prodId:"60105805",label:"Materiales de enseñanza de análisis de colores personales"},
    {prodId:"60105806",label:"Materiales de enseñanza de los principios básicos de la comercialización de la moda o la venta al por  menor"},
    {prodId:"60105807",label:"Materiales de enseñanza de la ciencia de los tejidos o las fibras"},
    {prodId:"60105808",label:"Materiales de enseñanza del cuidado o mantenimiento o lavado de la ropa"},
    {prodId:"60105809",label:"Materiales para la enseñanza del arte del color en el diseño  de telas"},
    {prodId:"60105810",label:"Materiales de enseñanza sobre pinturas y tintes para telas"},
    {prodId:"60105811",label:"Materiales de enseñanza para proyectos de acolchado"},
    {prodId:"60105900",label:"Materiales educativos sobre las relaciones y el sexo, el embarazo de adolescentes, aptitudes de crianza y desarrollo del niño"},
    {prodId:"60105901",label:"Materiales de enseñanza de educación sexual o enfermedades de transmisión sexual"},
    {prodId:"60105902",label:"Materiales de enseñanza de recursos de  nutrición prenatal o abuso fetal"},
    {prodId:"60105903",label:"Materiales de enseñanza de habilidades para la crianza de los hijos"},
    {prodId:"60105904",label:"Materiales de enseñanza sobre el desarrollo de niño"},
    {prodId:"60105905",label:"Materiales de enseñanza de comprensión de la violación en una cita o las habilidades para salir en cita o el acoso"},
    {prodId:"60105906",label:"Materiales de enseñanza de educación sobre el parto"},
    {prodId:"60105907",label:"Materiales de enseñanza sobre el embarazo, desde la concepción hasta el parto"},
    {prodId:"60105908",label:"Materiales de enseñanza de comprensión de los riesgos de defectos de nacimiento"},
    {prodId:"60105909",label:"Simuladores de embarazo"},
    {prodId:"60105910",label:"Simuladores de bebés y accesorios"},
    {prodId:"60105911",label:"Materiales de enseñanza de formación sobre puericultura"},
    {prodId:"60105912",label:"Materiales de enseñanza de comprensión del abuso infantil  físico o emocional"},
    {prodId:"60105913",label:"Materiales de enseñanza para padres sobre educación de habilidades para la disciplina"},
    {prodId:"60105914",label:"Materiales de enseñanza de seguridad del hogar o de protección para evitar usos indebidos por los niños"},
    {prodId:"60105915",label:"Materiales de enseñanza sobre resucitación cardiopulmonar o apoyo básico de vida"},
    {prodId:"60105916",label:"Materiales de enseñanza de comprensión de las enfermedades de la infancia"},
    {prodId:"60105917",label:"Materiales de enseñanza de comprensión del trastorno por déficit de atención con hiperactividad"},
    {prodId:"60105918",label:"Materiales de enseñanza para el cuidador de niños"},
    {prodId:"60105919",label:"Materiales de enseñanza para el cuidado de niños"},
    {prodId:"60106000",label:"Guías de planes de estudio, proyectos, actividades y lecciones"},
    {prodId:"60106001",label:"Libros de ideas o recursos del plan de estudios de bachillerato básico"},
    {prodId:"60106002",label:"Libros de ideas o recursos del plan de estudios de bachillerato"},
    {prodId:"60106003",label:"Proyectos de estudio independiente de economía doméstica"},
    {prodId:"60106004",label:"Recursos o guías de proyectos o actividades de economía doméstica"},
    {prodId:"60106100",label:"Materiales de enseñanza vocacional"},
    {prodId:"60106101",label:"Materiales didácticos sobre el automóvil"},
    {prodId:"60106102",label:"Materiales didácticos de construcción"},
    {prodId:"60106103",label:"Materiales didácticos de diseño o delineación"},
    {prodId:"60106104",label:"Materiales didácticos de electricidad o electrónica"},
    {prodId:"60106105",label:"Materiales didácticos de artes gráficas o fotografía"},
    {prodId:"60106106",label:"Materiales didácticos de  horticultura"},
    {prodId:"60106107",label:"Materiales didácticos de manufactura"},
    {prodId:"60106108",label:"Materiales didácticos de seguridad o peligro"},
    {prodId:"60106109",label:"Materiales didácticos de soldadura"},
    {prodId:"60106200",label:"Materiales de enseñanza de tecnología"},
    {prodId:"60106201",label:"Materiales didácticos de agricultura"},
    {prodId:"60106202",label:"Materiales didácticos de biotecnología"},
    {prodId:"60106203",label:"Materiales didácticos de comunicaciones"},
    {prodId:"60106204",label:"Materiales didácticos de informática"},
    {prodId:"60106205",label:"Materiales didácticos de energía o electricidad"},
    {prodId:"60106206",label:"Materiales didácticos del medio ambiente"},
    {prodId:"60106207",label:"Materiales didácticos de materiales"},
    {prodId:"60106208",label:"Materiales didácticos de medicina"},
    {prodId:"60106209",label:"Materiales didácticos de transporte"},
    {prodId:"60106210",label:"Materiales didácticos de sistemas de armas"},
    {prodId:"60106211",label:"Materiales didácticos del motor o sus partes"},
    {prodId:"60106212",label:"Materiales didácticos de instrumentos de navegación"},
    {prodId:"60106213",label:"Materiales didácticos de mecánica de fluidos o máquinas"},
    {prodId:"60106214",label:"Materiales didácticos de robótica"},
    {prodId:"60106215",label:"Materiales didácticos de sistemas de refrigeración"},
    {prodId:"60106400",label:"Equipo de enseñanza de electrónica y suministros"},
    {prodId:"60106401",label:"Kits de electrónica"},
    {prodId:"60106402",label:"Suministros para la enseñanza de electrónica"},
    {prodId:"60106500",label:"Suministros religiosos"},
    {prodId:"60106501",label:"Estatua budista"},
    {prodId:"60106502",label:"Estatua de la santa madre y el niño"},
    {prodId:"60106503",label:"Cruz eclesiástica"},
    {prodId:"60106504",label:"Servicio de mesa para ritos fúnebres"},
    {prodId:"60106505",label:"Quemador de incienso"},
    {prodId:"60106506",label:"Harubang"},
    {prodId:"60111000",label:"Pósteres o gráficos del aula"},
    {prodId:"60111001",label:"Paquetes de gráficos"},
    {prodId:"60111002",label:"Gráficos para el salón de clase"},
    {prodId:"60111003",label:"Afiches o sets para el salón de clases"},
    {prodId:"60111004",label:"Afiches de creación  personal"},
    {prodId:"60111005",label:"Soportes de gráficos o accesorios"},
    {prodId:"60111100",label:"Sets de tablón de anuncios"},
    {prodId:"60111101",label:"Sets de carteleras grandes"},
    {prodId:"60111102",label:"Sets de carteleras del calendario"},
    {prodId:"60111103",label:"Sets de carteleras para la primera infancia"},
    {prodId:"60111104",label:"Sets de carteleras de idiomas"},
    {prodId:"60111105",label:"Sets carteleras de matemáticas"},
    {prodId:"60111106",label:"Sets de carteleras multipropósito"},
    {prodId:"60111107",label:"Sets de carteleras de ciencias"},
    {prodId:"60111108",label:"Sets de carteleras de temporada"},
    {prodId:"60111109",label:"Sets de carteleras de ciencias sociales"},
    {prodId:"60111200",label:"Guardas y remates de tablón de anuncios"},
    {prodId:"60111201",label:"Pendones para el salón de clase"},
    {prodId:"60111202",label:"Paquetes de bordes"},
    {prodId:"60111203",label:"Carteles para el salón de clase"},
    {prodId:"60111204",label:"Bordes o ribetes corrugados"},
    {prodId:"60111205",label:"Bordes o ribetes festoneados y troquelados con formas"},
    {prodId:"60111206",label:"Bordes o ribetes brillantes"},
    {prodId:"60111207",label:"Bordes o ribetes rectos"},
    {prodId:"60111208",label:"Almacenaje de bordes o ribetes"},
    {prodId:"60111300",label:"Letras y números decorativos"},
    {prodId:"60111301",label:"Bloques con letras o números"},
    {prodId:"60111302",label:"Letras o números sueltos"},
    {prodId:"60111303",label:"Letras o números en cursiva"},
    {prodId:"60111304",label:"Letras o números autoadhesivos"},
    {prodId:"60111305",label:"Letras o números brillantes"},
    {prodId:"60111306",label:"Letras o números para calcar"},
    {prodId:"60111400",label:"Decoraciones especiales para el aula y accesorios decorativos"},
    {prodId:"60111401",label:"Kits de decoración para el salón de clase"},
    {prodId:"60111402",label:"Decoraciones para puertas"},
    {prodId:"60111403",label:"Móviles"},
    {prodId:"60111404",label:"Decoraciones de dos caras"},
    {prodId:"60111405",label:"Decoraciones para adherir a las ventanas"},
    {prodId:"60111407",label:"Sistemas decorativos de almacenaje"},
    {prodId:"60111408",label:"Cintas o cintas onduladas decorativas"},
    {prodId:"60111409",label:"Botones decorativos"},
    {prodId:"60111410",label:"Figuras o cuerdas decorativas"},
    {prodId:"60111411",label:"Aerosoles decorativos"},
    {prodId:"60121000",label:"Arte"},
    {prodId:"60121001",label:"Pinturas"},
    {prodId:"60121002",label:"Esculturas"},
    {prodId:"60121003",label:"Estatuas"},
    {prodId:"60121004",label:"Retratos"},
    {prodId:"60121005",label:"Dibujos"},
    {prodId:"60121006",label:"Cuadros"},
    {prodId:"60121007",label:"Litografías"},
    {prodId:"60121008",label:"Afiches"},
    {prodId:"60121009",label:"Vasija decorativa"},
    {prodId:"60121010",label:"Pergaminos"},
    {prodId:"60121011",label:"Fotografías"},
    {prodId:"60121012",label:"Adhesivos decorativos"},
    {prodId:"60121013",label:"Decoración artística para paredes"},
    {prodId:"60121014",label:"Frutas secas decorativas"},
    {prodId:"60121015",label:"Adhesivos decorativos en vinilo"},
    {prodId:"60121100",label:"Lienzos, películas, tableros y papeles de artista"},
    {prodId:"60121101",label:"Papel para dibujo de sulfito"},
    {prodId:"60121102",label:"Papel para dibujo de pasta de madera triturada"},
    {prodId:"60121103",label:"Papel para dibujo de calcar o pergamino"},
    {prodId:"60121104",label:"Papel bond para para dibujo"},
    {prodId:"60121105",label:"Papel de dibujo para carboncillo o pastel"},
    {prodId:"60121106",label:"Papel brístol para dibujo"},
    {prodId:"60121107",label:"Hojas de papel para acuarela"},
    {prodId:"60121108",label:"Cuadernos de papel para acuarela"},
    {prodId:"60121109",label:"Blocs de papel para acuarela"},
    {prodId:"60121110",label:"Papel para pintura dactilar"},
    {prodId:"60121111",label:"Cartulina de sulfito"},
    {prodId:"60121112",label:"Cartulina de pasta de madera triturada"},
    {prodId:"60121113",label:"Cartulina metalizada"},
    {prodId:"60121114",label:"Papeles para manualidades de origami"},
    {prodId:"60121115",label:"Confeti de plástico o de papel"},
    {prodId:"60121116",label:"Papel crepé para manualidades"},
    {prodId:"60121117",label:"Papel de seda para manualidades"},
    {prodId:"60121118",label:"Papel corrugado para manualidades"},
    {prodId:"60121119",label:"Papel con impresión de patrones para manualidades"},
    {prodId:"60121120",label:"Papel para manualidades autoadhesivo"},
    {prodId:"60121121",label:"Papel fluorescente"},
    {prodId:"60121123",label:"Papel hecho a mano"},
    {prodId:"60121124",label:"Papel kraft"},
    {prodId:"60121125",label:"Paneles de lienzo"},
    {prodId:"60121126",label:"Lienzo preestirado"},
    {prodId:"60121127",label:"Lienzo imprimado"},
    {prodId:"60121128",label:"Lienzo sin imprimar"},
    {prodId:"60121129",label:"Paneles de madera comprimida"},
    {prodId:"60121130",label:"Cuadernos de papel imitación lienzo"},
    {prodId:"60121131",label:"Papel japonés para grabado"},
    {prodId:"60121132",label:"Papel para grabado para huecograbado o litografía"},
    {prodId:"60121133",label:"Papel para grabado para impresión xilográfica"},
    {prodId:"60121134",label:"Papel metálico"},
    {prodId:"60121135",label:"Películas de acetato, vinilo o poliéster"},
    {prodId:"60121136",label:"Películas de celofán"},
    {prodId:"60121137",label:"Hojas de acrílico"},
    {prodId:"60121138",label:"Tableros de ilustración"},
    {prodId:"60121139",label:"Láminas de paspartú"},
    {prodId:"60121140",label:"Tablero de montaje"},
    {prodId:"60121141",label:"Tablero de montaje de núcleo de espuma"},
    {prodId:"60121142",label:"Cartón duro o cartón de colores de dos caras"},
    {prodId:"60121143",label:"Tablero para presentaciones"},
    {prodId:"60121144",label:"Papeles borrador para arte"},
    {prodId:"60121145",label:"Carteles borrador para arte"},
    {prodId:"60121146",label:"Accesorios borrador para arte"},
    {prodId:"60121147",label:"Papel brillante"},
    {prodId:"60121148",label:"Cartones de colores"},
    {prodId:"60121149",label:"Papel vegetal prensado"},
    {prodId:"60121150",label:"Papel de construcción"},
    {prodId:"60121151",label:"Accesorios o tableros para dibujo o bosquejo"},
    {prodId:"60121152",label:"Tablillas de escritura"},
    {prodId:"60121153",label:"Hojas de transferencia"},
    {prodId:"60121200",label:"Pintura del aula y de bellas artes, medios, aplicadores y accesorios"},
    {prodId:"60121201",label:"Pintura témpera líquida tradicional"},
    {prodId:"60121202",label:"Pintura témpera líquida contemporánea"},
    {prodId:"60121203",label:"Pintura témpera en polvo"},
    {prodId:"60121204",label:"Pintura témpera lavable"},
    {prodId:"60121205",label:"Pastillas de témpera"},
    {prodId:"60121206",label:"Pintura líquida para cuerpo o cara"},
    {prodId:"60121207",label:"Pintura en pasta para cuerpo o cara"},
    {prodId:"60121208",label:"Pintura de marcador para cuerpo o cara"},
    {prodId:"60121209",label:"Pintura de tatuaje provisional"},
    {prodId:"60121210",label:"Pintura dactilar  lavable"},
    {prodId:"60121211",label:"Pintura acrílica estilo escolar"},
    {prodId:"60121212",label:"Pintura acrílica para aerógrafo"},
    {prodId:"60121213",label:"Pinturas o medios al oleo sintéticos tratados con calor"},
    {prodId:"60121214",label:"Pinturas o medios al óleo solubles en agua"},
    {prodId:"60121215",label:"Pintura removible de baja viscosidad para vidrio o cerámica"},
    {prodId:"60121216",label:"Pintura permanente de baja viscosidad para vidrio o cerámica"},
    {prodId:"60121217",label:"Pintura gel removible de alta viscosidad para vidrio o cerámica"},
    {prodId:"60121218",label:"Pintura gel permanente de alta viscosidad para vidrio o cerámica"},
    {prodId:"60121219",label:"Pintura para cerámica o vidrio horneado"},
    {prodId:"60121220",label:"Pintura para vidrio o cerámica de sistema de marcador"},
    {prodId:"60121221",label:"Pintura de acuarela de platillo"},
    {prodId:"60121222",label:"Pintura de acuarela en tubo"},
    {prodId:"60121223",label:"Pintura de acuarela líquida"},
    {prodId:"60121224",label:"Pintura de enmascarar para acuarela líquida"},
    {prodId:"60121225",label:"Medios para pintura de acuarela"},
    {prodId:"60121226",label:"Pinceles para acuarela"},
    {prodId:"60121227",label:"Pinceles orientales"},
    {prodId:"60121228",label:"Pinceles de utilidad"},
    {prodId:"60121229",label:"Pinceles especializados"},
    {prodId:"60121230",label:"Pinceles de caballete"},
    {prodId:"60121231",label:"Espátulas de paleta"},
    {prodId:"60121232",label:"Rodillos para impresión a mano"},
    {prodId:"60121233",label:"Sellos de esponja"},
    {prodId:"60121234",label:"Espátulas para aplicación de pintura"},
    {prodId:"60121235",label:"Pipetas para mezclar pinturas o tintes"},
    {prodId:"60121236",label:"Peinillas o utensilios para aplicación de pintura o tinta"},
    {prodId:"60121237",label:"Paletas para mezclar pintura o tinta"},
    {prodId:"60121238",label:"Vasijas para pintura o para mezclar o almacenar pintura"},
    {prodId:"60121239",label:"Botellas o tazas para pintura"},
    {prodId:"60121241",label:"Productos de limpieza de utensilios o pinceles"},
    {prodId:"60121242",label:"Delantales para pintar"},
    {prodId:"60121243",label:"Blusones para artistas"},
    {prodId:"60121244",label:"Tiras extensoras"},
    {prodId:"60121245",label:"Extensores de lienzo"},
    {prodId:"60121246",label:"Caballetes metálicos"},
    {prodId:"60121247",label:"Caballetes de madera"},
    {prodId:"60121248",label:"Caballetes de sobremesa"},
    {prodId:"60121249",label:"Caballetes de presentación"},
    {prodId:"60121250",label:"Portafolio para dibujos"},
    {prodId:"60121251",label:"Pintura al gouache"},
    {prodId:"60121252",label:"Bandejas para pinturas"},
    {prodId:"60121253",label:"Aerógrafos para arte"},
    {prodId:"60121300",label:"Productos de corte de manualidades y arte"},
    {prodId:"60121301",label:"Guillotinas para cortar papel"},
    {prodId:"60121302",label:"Cortadora de paspartú"},
    {prodId:"60121303",label:"Cuchillas para paspartú"},
    {prodId:"60121304",label:"Espátulas de artista"},
    {prodId:"60121305",label:"Cortador rotativo de tela o papel"},
    {prodId:"60121306",label:"Cortadoras de papel en círculos u óvalos"},
    {prodId:"60121400",label:"Enmarcado de cuadro"},
    {prodId:"60121401",label:"Marcos de madera preensamblados"},
    {prodId:"60121402",label:"Marcos de secciones de madera"},
    {prodId:"60121403",label:"Marcos metálicos preensamblados"},
    {prodId:"60121404",label:"Marcos de secciones de metal"},
    {prodId:"60121405",label:"Marcos  ajustables"},
    {prodId:"60121406",label:"Marcos de plástico"},
    {prodId:"60121407",label:"Marcos en forma de cubo transparente"},
    {prodId:"60121408",label:"Clavadoras de puntillas o accesorios para marcos"},
    {prodId:"60121409",label:"Caja de ingletes"},
    {prodId:"60121410",label:"Dispositivos para colgar cuadros"},
    {prodId:"60121411",label:"Paneles acrílicos para marcos"},
    {prodId:"60121412",label:"Paneles de vidrio para marcos"},
    {prodId:"60121413",label:"Organizadores o álbumes para fotos"},
    {prodId:"60121414",label:"Monturas magnéticas para marcos"},
    {prodId:"60121415",label:"Kits de marcos"},
    {prodId:"60121500",label:"Utensilios de dibujo, suministros y accesorios"},
    {prodId:"60121501",label:"Marcadores a base de agua"},
    {prodId:"60121502",label:"Marcadores de base disolvente"},
    {prodId:"60121503",label:"Marcadores lavables"},
    {prodId:"60121504",label:"Marcadores para caligrafía"},
    {prodId:"60121505",label:"Marcadores de tela"},
    {prodId:"60121506",label:"Marcadores metálicos"},
    {prodId:"60121507",label:"Marcadores de témpera o tiza para ventanas"},
    {prodId:"60121508",label:"Marcadores de pintura"},
    {prodId:"60121509",label:"Crayones de cera"},
    {prodId:"60121510",label:"Crayones de base de soya"},
    {prodId:"60121511",label:"Crayones especializados"},
    {prodId:"60121512",label:"Crayones de acuarela"},
    {prodId:"60121513",label:"Pastel seco"},
    {prodId:"60121514",label:"Pastel de tiza"},
    {prodId:"60121515",label:"Pastel a base de aceite"},
    {prodId:"60121516",label:"Carboncillo"},
    {prodId:"60121517",label:"Carboncillo vine"},
    {prodId:"60121518",label:"Lápices de grafito"},
    {prodId:"60121519",label:"Lápices de colores para dibujar de base de cera"},
    {prodId:"60121520",label:"Carboncillos"},
    {prodId:"60121521",label:"Lápices de acuarela"},
    {prodId:"60121522",label:"Bolígrafos de base acuosa"},
    {prodId:"60121523",label:"Bolígrafos permanentes"},
    {prodId:"60121524",label:"Bolígrafos de gel"},
    {prodId:"60121525",label:"Rapidógrafos"},
    {prodId:"60121526",label:"Bolígrafos para caligrafía"},
    {prodId:"60121531",label:"Borradores de lápices color rosados"},
    {prodId:"60121532",label:"Borradores de goma moldeable"},
    {prodId:"60121533",label:"Borradores de vinilo"},
    {prodId:"60121534",label:"Borradores de plástico"},
    {prodId:"60121535",label:"Borradores de goma"},
    {prodId:"60121537",label:"Plumillas o sus accesorios"},
    {prodId:"60121538",label:"Kits de caligrafía"},
    {prodId:"60121539",label:"Fijadores de dibujo"},
    {prodId:"60121540",label:"Telas de dibujo"},
    {prodId:"60121600",label:"Materiales de estudio"},
    {prodId:"60121601",label:"Maniquíes de madera"},
    {prodId:"60121603",label:"Placas de fricción de plástico"},
    {prodId:"60121604",label:"Accesorios para ayudas de estudio"},
    {prodId:"60121605",label:"Modelos anatómicos"},
    {prodId:"60121606",label:"Pantallas de fondo"},
    {prodId:"60121700",label:"Suministros y accesorios de grabados"},
    {prodId:"60121701",label:"Sellos de estampación de caucho"},
    {prodId:"60121702",label:"Almohadilla para sellos de estampación de caucho"},
    {prodId:"60121703",label:"Accesorios para estampación de caucho"},
    {prodId:"60121704",label:"Linóleo para impresión xilográfica"},
    {prodId:"60121705",label:"Bloques de madera para impresión"},
    {prodId:"60121706",label:"Bloques sintéticos para impresión"},
    {prodId:"60121707",label:"Accesorios para impresión xilográfica"},
    {prodId:"60121708",label:"Planchas de huecograbado o litografía"},
    {prodId:"60121709",label:"Mantillas para huecograbado o litografía"},
    {prodId:"60121710",label:"Limpiones para huecograbado o litografía"},
    {prodId:"60121711",label:"Planchas calientes para huecograbado o litografía"},
    {prodId:"60121712",label:"Prensas de impresión para huecograbado o litografía"},
    {prodId:"60121713",label:"Rodillos y aplanadoras de impresión"},
    {prodId:"60121714",label:"Utensilios de grabado para huecograbado"},
    {prodId:"60121715",label:"Estaciones de impresión o pantallas para serigrafía"},
    {prodId:"60121716",label:"Accesorios para serigrafía"},
    {prodId:"60121717",label:"Punzones para grabado"},
    {prodId:"60121718",label:"Extendedores de tinta de impresión"},
    {prodId:"60121800",label:"Tintas de dibujo e impresión"},
    {prodId:"60121801",label:"Tintas para afiches a base de agua"},
    {prodId:"60121802",label:"Tintas acrílicas a base de agua"},
    {prodId:"60121803",label:"Tintas para serigrafía a base de aceite"},
    {prodId:"60121804",label:"Tintas para textiles a base de agua"},
    {prodId:"60121805",label:"Tintas para textiles a base de aceite"},
    {prodId:"60121806",label:"Tintas de sublimación para grabado"},
    {prodId:"60121807",label:"Tintas para huecograbado o litografía a base de aceite"},
    {prodId:"60121808",label:"Tintas para monoimpresión a base de aceite"},
    {prodId:"60121809",label:"Tintas para monoimpresión a base de agua"},
    {prodId:"60121810",label:"Tintas para dibujar a base de agua"},
    {prodId:"60121811",label:"Tintas para dibujar de base solvente"},
    {prodId:"60121812",label:"Tintas para caligrafía"},
    {prodId:"60121813",label:"Tintas para serigrafía"},
    {prodId:"60121814",label:"Barnices litográficos"},
    {prodId:"60121900",label:"Materiales de decoración de telas y arte textil y suministros"},
    {prodId:"60121901",label:"Muselina"},
    {prodId:"60121902",label:"Fieltro"},
    {prodId:"60121903",label:"Pieles para manualidades"},
    {prodId:"60121904",label:"Mezclas de algodón"},
    {prodId:"60121905",label:"Lienzos estampables"},
    {prodId:"60121906",label:"Estampables presensibilizados"},
    {prodId:"60121907",label:"Estampables de algodón"},
    {prodId:"60121908",label:"Estampables mezclados"},
    {prodId:"60121909",label:"Ceras para batik"},
    {prodId:"60121910",label:"Accesorios para batik"},
    {prodId:"60121911",label:"Tela para batik"},
    {prodId:"60121912",label:"Difuminadores"},
    {prodId:"60122000",label:"Equipo de tejido, pespunteado y cosido y accesorios"},
    {prodId:"60122002",label:"Accesorios para tejer"},
    {prodId:"60122003",label:"Agujas para coser a mano"},
    {prodId:"60122004",label:"Kits de manualidades con cuerda"},
    {prodId:"60122005",label:"Telares manuales"},
    {prodId:"60122006",label:"Telares de mesa"},
    {prodId:"60122007",label:"Telares de piso"},
    {prodId:"60122008",label:"Cordón plástico (rexlace)"},
    {prodId:"60122009",label:"Accesorios de encordado o acordonado"},
    {prodId:"60122100",label:"Velas artesanales"},
    {prodId:"60122101",label:"Mechas para la fabricación de velas"},
    {prodId:"60122102",label:"Moldes para la fabricación de velas"},
    {prodId:"60122103",label:"Accesorios para la fabricación de velas"},
    {prodId:"60122200",label:"Ebanistería"},
    {prodId:"60122201",label:"Materiales para artesanías en madera"},
    {prodId:"60122202",label:"Materiales para acabados"},
    {prodId:"60122203",label:"Herramientas para quemado de madera"},
    {prodId:"60122204",label:"Herramientas para tallar"},
    {prodId:"60122400",label:"Suministros de vidrieras de colores"},
    {prodId:"60122401",label:"Fragmentos de vidrios de colores"},
    {prodId:"60122402",label:"Herramientas o accesorios para vidrios de colores"},
    {prodId:"60122500",label:"Manualidades de papel y papel artesanal"},
    {prodId:"60122501",label:"Utensilios para dar formas al papel"},
    {prodId:"60122502",label:"Marcos de papel"},
    {prodId:"60122503",label:"Platos o bandejas de papel"},
    {prodId:"60122504",label:"Filtros de papel"},
    {prodId:"60122506",label:"Blondas de papel"},
    {prodId:"60122507",label:"Bastidores o moldes para papel hecho a mano"},
    {prodId:"60122508",label:"Láminas absorbentes o fieltros para papel hecho a mano"},
    {prodId:"60122509",label:"Pulpa o materias primas para papel hecho a mano"},
    {prodId:"60122600",label:"Mosaicos"},
    {prodId:"60122601",label:"Azulejos para mosaico"},
    {prodId:"60122602",label:"Moldes para mosaicos"},
    {prodId:"60122603",label:"Herramientas para mosaicos"},
    {prodId:"60122604",label:"Accesorios para mosaicos"},
    {prodId:"60122700",label:"Pinturas de esmaltado y accesorios"},
    {prodId:"60122701",label:"Pinturas o medios para esmaltar"},
    {prodId:"60122702",label:"Formas de cobre"},
    {prodId:"60122703",label:"Accesorios para esmaltar"},
    {prodId:"60122704",label:"Hornos para esmaltar"},
    {prodId:"60122800",label:"Máscaras"},
    {prodId:"60122801",label:"Formas para hechura de máscaras"},
    {prodId:"60122900",label:"Cuentas o accesorios de enhebrado de cuentas"},
    {prodId:"60122901",label:"Cuentas pequeñas"},
    {prodId:"60122902",label:"Cuentas tipo “pony”"},
    {prodId:"60122903",label:"Cuentas de madera"},
    {prodId:"60122904",label:"Cuentas de paja"},
    {prodId:"60122905",label:"Cuentas de cerámica"},
    {prodId:"60122906",label:"Cuentas de vidrio"},
    {prodId:"60122907",label:"Cuentas surtidas o de decoración"},
    {prodId:"60122908",label:"Accesorios de cuentas"},
    {prodId:"60122909",label:"Cuentas de plástico"},
    {prodId:"60123000",label:"Artesanía de espuma"},
    {prodId:"60123001",label:"Formas de icopor"},
    {prodId:"60123002",label:"Utensilios para artesanía de espuma"},
    {prodId:"60123100",label:"Artesanía de cañas de felpilla"},
    {prodId:"60123101",label:"Cañas de felpilla grandes"},
    {prodId:"60123102",label:"Cañas de felpilla de algodón"},
    {prodId:"60123103",label:"Cañas de felpilla abultadas"},
    {prodId:"60123200",label:"Materiales de artesanía de cintas"},
    {prodId:"60123201",label:"Lazos de papel"},
    {prodId:"60123202",label:"Cintas de seda"},
    {prodId:"60123203",label:"Cintas sintéticas"},
    {prodId:"60123204",label:"Cintas decorativas"},
    {prodId:"60123300",label:"Pompones de artesanía"},
    {prodId:"60123301",label:"Pompones acrílicos para manualidades"},
    {prodId:"60123302",label:"Pompones brillantes para manualidades"},
    {prodId:"60123303",label:"Cuentas de pompones para manualidades"},
    {prodId:"60123400",label:"Materiales de ojos móviles"},
    {prodId:"60123401",label:"Ojos móviles no autoadhesivos"},
    {prodId:"60123402",label:"Ojos móviles autoadhesivos"},
    {prodId:"60123403",label:"Ojos móviles decorativos"},
    {prodId:"60123500",label:"Materiales de artesanía de cuero"},
    {prodId:"60123501",label:"Materiales de cuero o de acordonados de cuero"},
    {prodId:"60123502",label:"Accesorios de cuero"},
    {prodId:"60123600",label:"Purpurina"},
    {prodId:"60123601",label:"Pegante de purpurina"},
    {prodId:"60123602",label:"Puntos de purpurina"},
    {prodId:"60123603",label:"Joyas de purpurina"},
    {prodId:"60123604",label:"Purpurina de plástico"},
    {prodId:"60123605",label:"Purpurina iridiscente"},
    {prodId:"60123606",label:"Purpurina metálica"},
    {prodId:"60123700",label:"Materiales de artesanía de macramé y accesorios"},
    {prodId:"60123701",label:"Cordón de macramé"},
    {prodId:"60123702",label:"Cuentas para macramé"},
    {prodId:"60123703",label:"Accesorios para macramé"},
    {prodId:"60123900",label:"Lentejuelas y ribetes"},
    {prodId:"60123901",label:"Lentejuelas o ribetes decorativos"},
    {prodId:"60124000",label:"Suministros de artesanía del corcho y accesorios"},
    {prodId:"60124001",label:"Planchas de corcho"},
    {prodId:"60124002",label:"Tapones de corcho o accesorios"},
    {prodId:"60124100",label:"Materiales de proyecto multicultural y accesorios"},
    {prodId:"60124102",label:"Productos de artesanía multicultural"},
    {prodId:"60124300",label:"Compuestos de modelado y arcilla, equipo de cerámica y accesorios"},
    {prodId:"60124301",label:"Arcilla húmeda cocida en horno"},
    {prodId:"60124302",label:"Arcilla seca cocida en horno"},
    {prodId:"60124303",label:"Mobiliario para hornos"},
    {prodId:"60124304",label:"Hornos para a cocción de cerámica"},
    {prodId:"60124305",label:"Accesorios de hornos para la cocción de  cerámica"},
    {prodId:"60124306",label:"Tornos de alfarero para cerámicas hechas a mano"},
    {prodId:"60124307",label:"Extrusoras para materiales de modelado"},
    {prodId:"60124308",label:"Conos para hornos de cocción"},
    {prodId:"60124309",label:"Paletas de alfarería"},
    {prodId:"60124310",label:"Tornos de decoración para alfarería"},
    {prodId:"60124311",label:"Utensilios para arcilla o modelado"},
    {prodId:"60124312",label:"Azulejos de cerámica cocidos"},
    {prodId:"60124313",label:"Recipientes para almacenamiento de arcilla"},
    {prodId:"60124314",label:"Compuestos para modelado plastificados no endurecibles"},
    {prodId:"60124315",label:"Compuestos para modelado  no endurecibles a base de aceite"},
    {prodId:"60124316",label:"Compuestos para modelado o arcilla secados al aire"},
    {prodId:"60124317",label:"Masa para modelado"},
    {prodId:"60124318",label:"Papel maché"},
    {prodId:"60124319",label:"Compuestos para modelado especializados"},
    {prodId:"60124320",label:"Compuestos de yeso"},
    {prodId:"60124321",label:"Compuestos para modelado o arcilla de endurecimiento en horno"},
    {prodId:"60124322",label:"Compuestos para modelado de plástico"},
    {prodId:"60124323",label:"Moldes para dar forma a los compuestos para modelado"},
    {prodId:"60124324",label:"Kits para modelado de arcilla"},
    {prodId:"60124325",label:"Arcilla de papel"},
    {prodId:"60124400",label:"Metales de artesanía"},
    {prodId:"60124401",label:"Hoja fina de metal de cobre"},
    {prodId:"60124402",label:"Hoja fina de metal de  aluminio"},
    {prodId:"60124403",label:"Alambre de aluminio"},
    {prodId:"60124404",label:"Hoja fina de metal de latón"},
    {prodId:"60124406",label:"Placas o láminas de plata"},
    {prodId:"60124407",label:"Alambre de plata"},
    {prodId:"60124408",label:"Bolitas de peltre"},
    {prodId:"60124409",label:"Lingotes de peltre"},
    {prodId:"60124410",label:"Placas de láminas de bronce"},
    {prodId:"60124411",label:"Alambre de cobre nu gold"},
    {prodId:"60124412",label:"Alambre suave galvanizado"},
    {prodId:"60124500",label:"Suministros y accesorios de escultura"},
    {prodId:"60124501",label:"Envoltura de yeso"},
    {prodId:"60124502",label:"Resinas para vaciado"},
    {prodId:"60124503",label:"Accesorios para esculturas"},
    {prodId:"60124504",label:"Repisas para rompecabezas"},
    {prodId:"60124505",label:"Ampollas o accesorios"},
    {prodId:"60124506",label:"Utensilios o moldes o juguetes de plástico para arena o agua"},
    {prodId:"60124507",label:"Arena para jugar"},
    {prodId:"60124508",label:"Centros de actividades o mesas de arena o agua"},
    {prodId:"60124509",label:"Sets de vehículos"},
    {prodId:"60124510",label:"Sets de vías de agua"},
    {prodId:"60124511",label:"Herramientas de juguete o kits de herramientas de juguete"},
    {prodId:"60124512",label:"Escúter"},
    {prodId:"60124513",label:"Bolsitas de fríjoles (beanbags)"},
    {prodId:"60124514",label:"Juguetes táctiles"},
    {prodId:"60124515",label:"Juguetes cognitivos"},
    {prodId:"60131000",label:"Instrumentos de teclado"},
    {prodId:"60131001",label:"Pianos"},
    {prodId:"60131002",label:"Acordeones"},
    {prodId:"60131003",label:"Órganos musicales"},
    {prodId:"60131004",label:"Celestas"},
    {prodId:"60131005",label:"Melodión"},
    {prodId:"60131006",label:"Sintetizador"},
    {prodId:"60131007",label:"Melódica"},
    {prodId:"60131100",label:"Instrumentos de metal"},
    {prodId:"60131101",label:"Trompetas"},
    {prodId:"60131102",label:"Trombones"},
    {prodId:"60131103",label:"Sousafones"},
    {prodId:"60131104",label:"Saxofones"},
    {prodId:"60131105",label:"Silbatos"},
    {prodId:"60131106",label:"Clarines"},
    {prodId:"60131107",label:"Saxhorno"},
    {prodId:"60131108",label:"Cornos franceses"},
    {prodId:"60131109",label:"Melófonos"},
    {prodId:"60131110",label:"Trompas alto"},
    {prodId:"60131111",label:"Bombardinos barítono"},
    {prodId:"60131112",label:"Fiscornos"},
    {prodId:"60131113",label:"Nabal"},
    {prodId:"60131114",label:"Tuba"},
    {prodId:"60131115",label:"Eufonio"},
    {prodId:"60131116",label:"Tubáfono"},
    {prodId:"60131200",label:"Instrumentos de viento de madera"},
    {prodId:"60131201",label:"Clarinetes"},
    {prodId:"60131202",label:"Oboes"},
    {prodId:"60131203",label:"Flautas musicales"},
    {prodId:"60131204",label:"Flautines"},
    {prodId:"60131205",label:"Cornetas musicales"},
    {prodId:"60131206",label:"Gaitas"},
    {prodId:"60131207",label:"Armónicas"},
    {prodId:"60131208",label:"Mirlitones"},
    {prodId:"60131209",label:"Cornos ingleses"},
    {prodId:"60131210",label:"Ocarinas"},
    {prodId:"60131211",label:"Daegum"},
    {prodId:"60131212",label:"Danso"},
    {prodId:"60131213",label:"Tungso"},
    {prodId:"60131214",label:"Taepyeongso"},
    {prodId:"60131215",label:"Jeok"},
    {prodId:"60131216",label:"Dangjeok"},
    {prodId:"60131217",label:"Dangpirie"},
    {prodId:"60131218",label:"Sepiri"},
    {prodId:"60131219",label:"So"},
    {prodId:"60131220",label:"Yak"},
    {prodId:"60131221",label:"Junggeum"},
    {prodId:"60131222",label:"Ji"},
    {prodId:"60131223",label:"Sogeum"},
    {prodId:"60131224",label:"Hyangpiri"},
    {prodId:"60131225",label:"Saenghwang"},
    {prodId:"60131226",label:"Hun"},
    {prodId:"60131227",label:"Nagak"},
    {prodId:"60131228",label:"Fagot"},
    {prodId:"60131229",label:"Cornamusa"},
    {prodId:"60131230",label:"Contrafagot"},
    {prodId:"60131231",label:"Sarrusofón"},
    {prodId:"60131232",label:"Corno sirena"},
    {prodId:"60131233",label:"Flauta dulce"},
    {prodId:"60131234",label:"Clave"},
    {prodId:"60131235",label:"Quena"},
    {prodId:"60131236",label:"Quenacho"},
    {prodId:"60131237",label:"Zampoña"},
    {prodId:"60131300",label:"Instrumentos de cuerda"},
    {prodId:"60131301",label:"Clavecines"},
    {prodId:"60131302",label:"Clavicordios"},
    {prodId:"60131303",label:"Guitarras"},
    {prodId:"60131304",label:"Violines"},
    {prodId:"60131305",label:"Arpas"},
    {prodId:"60131306",label:"Banyos"},
    {prodId:"60131307",label:"Mandolinas"},
    {prodId:"60131308",label:"Violonchelos"},
    {prodId:"60131309",label:"Bajos"},
    {prodId:"60131310",label:"Gayageum"},
    {prodId:"60131311",label:"Geomungo"},
    {prodId:"60131312",label:"Ajaeng"},
    {prodId:"60131313",label:"Geum"},
    {prodId:"60131314",label:"Dangbipa"},
    {prodId:"60131315",label:"Daejaeng"},
    {prodId:"60131316",label:"Seul"},
    {prodId:"60131317",label:"Wagonghu"},
    {prodId:"60131318",label:"Hyangbipa"},
    {prodId:"60131319",label:"Haegeum"},
    {prodId:"60131320",label:"Sogonghu"},
    {prodId:"60131321",label:"Sugonghu"},
    {prodId:"60131322",label:"Yangkum"},
    {prodId:"60131323",label:"Wolgeum"},
    {prodId:"60131324",label:"Ukelele"},
    {prodId:"60131325",label:"Viola"},
    {prodId:"60131326",label:"Instrumento nacional morin khuur"},
    {prodId:"60131327",label:"Yochin"},
    {prodId:"60131328",label:"Yatga"},
    {prodId:"60131329",label:"Shanz"},
    {prodId:"60131400",label:"Instrumentos de percusión"},
    {prodId:"60131401",label:"Platillos"},
    {prodId:"60131402",label:"Campanas"},
    {prodId:"60131403",label:"Panderetas"},
    {prodId:"60131404",label:"Castañuelas"},
    {prodId:"60131405",label:"Tambores"},
    {prodId:"60131406",label:"Xilófonos"},
    {prodId:"60131407",label:"Vibráfonos"},
    {prodId:"60131408",label:"Jing"},
    {prodId:"60131409",label:"Janggu"},
    {prodId:"60131410",label:"Kkwaengwari"},
    {prodId:"60131411",label:"Buk"},
    {prodId:"60131412",label:"Galgo"},
    {prodId:"60131413",label:"Geongo"},
    {prodId:"60131414",label:"Gyobanggo"},
    {prodId:"60131415",label:"Banghyang"},
    {prodId:"60131416",label:"Sakko"},
    {prodId:"60131417",label:"Sogo"},
    {prodId:"60131418",label:"Yonggo"},
    {prodId:"60131419",label:"Ulla"},
    {prodId:"60131420",label:"Eunggo"},
    {prodId:"60131421",label:"Jabara"},
    {prodId:"60131422",label:"Jeolgo"},
    {prodId:"60131423",label:"Jwago"},
    {prodId:"60131424",label:"Jingo"},
    {prodId:"60131425",label:"Teukkyeong"},
    {prodId:"60131426",label:"Teukjong"},
    {prodId:"60131427",label:"Pyeongyeong"},
    {prodId:"60131428",label:"Pyeonjong"},
    {prodId:"60131429",label:"Eo"},
    {prodId:"60131430",label:"Nogo"},
    {prodId:"60131431",label:"Nodo"},
    {prodId:"60131432",label:"Noego"},
    {prodId:"60131433",label:"Noedo"},
    {prodId:"60131434",label:"Bak"},
    {prodId:"60131435",label:"Bu"},
    {prodId:"60131436",label:"Yeonggo"},
    {prodId:"60131437",label:"Yeongdo"},
    {prodId:"60131438",label:"Junggo"},
    {prodId:"60131439",label:"Chuk"},
    {prodId:"60131440",label:"Mugo"},
    {prodId:"60131441",label:"Carrillón"},
    {prodId:"60131442",label:"Maracas"},
    {prodId:"60131443",label:"Cabasas brasil"},
    {prodId:"60131444",label:"Timbales"},
    {prodId:"60131445",label:"Bongoes"},
    {prodId:"60131446",label:"Congas"},
    {prodId:"60131447",label:"Armónica de metal"},
    {prodId:"60131448",label:"Marimbas"},
    {prodId:"60131449",label:"Triángulo instrumental"},
    {prodId:"60131450",label:"Maracas"},
    {prodId:"60131451",label:"Güiros"},
    {prodId:"60131452",label:"Agitadores (shakers) musicales"},
    {prodId:"60131453",label:"Gong de madera"},
    {prodId:"60131454",label:"Campanas (instrumento)"},
    {prodId:"60131455",label:"Gong de bronce o latón"},
    {prodId:"60131456",label:"Tom-tom"},
    {prodId:"60131457",label:"Timbal"},
    {prodId:"60131458",label:"Palo de lluvia"},
    {prodId:"60131459",label:"Tinya"},
    {prodId:"60131460",label:"Ton-ton"},
    {prodId:"60131500",label:"Piezas y accesorios de instrumentos musicales"},
    {prodId:"60131501",label:"Metrónomos"},
    {prodId:"60131502",label:"Lengüetas"},
    {prodId:"60131503",label:"Cuerdas o plectros para instrumentos"},
    {prodId:"60131504",label:"Clavijas de afinación"},
    {prodId:"60131505",label:"Bases o soportes de partituras para instrumentos musicales"},
    {prodId:"60131506",label:"Accesorios para instrumentos de cuerda"},
    {prodId:"60131507",label:"Accesorio para instrumentos de percusión"},
    {prodId:"60131508",label:"Cajas musicales o mecanismos"},
    {prodId:"60131509",label:"Boquillas"},
    {prodId:"60131510",label:"Fundas o estuches o accesorios para instrumentos musicales"},
    {prodId:"60131511",label:"Sordinas"},
    {prodId:"60131512",label:"Barras de afinación"},
    {prodId:"60131513",label:"Batutas"},
    {prodId:"60131514",label:"Almohadillas de flautín"},
    {prodId:"60131515",label:"Unidad de efectos para instrumentos musicales"},
    {prodId:"60131516",label:"Afinador de instrumentos electrónicos"},
    {prodId:"60131517",label:"Estuche o maleta de mano protectora para instrumentos musicales"},
    {prodId:"60131518",label:"Correa o arnés para instrumentos musicales"},
    {prodId:"60131519",label:"Pedestal para instrumentos musicales"},
    {prodId:"60131520",label:"Baqueta"},
    {prodId:"60131600",label:"Sets de instrumentos musicales"},
    {prodId:"60131601",label:"Sets de grupos de acompañamiento"},
    {prodId:"60131700",label:"Instrumentos musicales de sonidos alternativos"},
    {prodId:"60131701",label:"Tubos de percusión"},
    {prodId:"60131702",label:"Golpecitos de disco"},
    {prodId:"60131703",label:"Cascos de caballos"},
    {prodId:"60131800",label:"Accesorios de música y danza"},
    {prodId:"60131801",label:"Velos para danza"},
    {prodId:"60131802",label:"Palitos de ritmo"},
    {prodId:"60131803",label:"Aros o bastones de ritmo"},
    {prodId:"60141000",label:"Juguetes"},
    {prodId:"60141001",label:"Globos o pelotas de juguete"},
    {prodId:"60141002",label:"Muñecas"},
    {prodId:"60141003",label:"Casas de muñecas"},
    {prodId:"60141004",label:"Animales o títeres de peluche"},
    {prodId:"60141005",label:"Casas de juego"},
    {prodId:"60141006",label:"Bloques de construcción"},
    {prodId:"60141007",label:"Juguetes para montar"},
    {prodId:"60141008",label:"Juguetes para jalar"},
    {prodId:"60141009",label:"Kits de ciencias para niños"},
    {prodId:"60141010",label:"Vehículos de juguete"},
    {prodId:"60141011",label:"Trenes de juguete"},
    {prodId:"60141012",label:"Juguetes inflables"},
    {prodId:"60141013",label:"Partes o accesorios de muñecas"},
    {prodId:"60141014",label:"Yoyós"},
    {prodId:"60141015",label:"Cometas"},
    {prodId:"60141016",label:"Tazos"},
    {prodId:"60141017",label:"Caleidoscopios"},
    {prodId:"60141018",label:"Pompones"},
    {prodId:"60141019",label:"Piñatas"},
    {prodId:"60141020",label:"Bumeranes"},
    {prodId:"60141021",label:"Discos voladores"},
    {prodId:"60141022",label:"Baldes de juguete"},
    {prodId:"60141023",label:"Juguetes para el baño"},
    {prodId:"60141024",label:"Sonajeros"},
    {prodId:"60141025",label:"Armas de juguete"},
    {prodId:"60141026",label:"Trompos"},
    {prodId:"60141100",label:"Juegos"},
    {prodId:"60141101",label:"Juegos educativos"},
    {prodId:"60141102",label:"Juegos de mesa"},
    {prodId:"60141103",label:"Naipes"},
    {prodId:"60141104",label:"Juegos de video"},
    {prodId:"60141105",label:"Rompecabezas"},
    {prodId:"60141106",label:"Dados"},
    {prodId:"60141107",label:"Bingo"},
    {prodId:"60141108",label:"Juegos clásicos"},
    {prodId:"60141109",label:"Juegos colaborativos"},
    {prodId:"60141110",label:"Juegos de estrategia"},
    {prodId:"60141111",label:"Accesorios para juegos"},
    {prodId:"60141112",label:"Libros de juegos"},
    {prodId:"60141113",label:"Juegos de lotería"},
    {prodId:"60141114",label:"Juegos de memoria"},
    {prodId:"60141115",label:"Kits de juegos"},
    {prodId:"60141116",label:"Juego baduk o go y accesorios"},
    {prodId:"60141117",label:"Juego del lut o de cuatro palos y accesorios"},
    {prodId:"60141118",label:"Juego janggi y accesorios"},
    {prodId:"60141200",label:"Equipo de juegos activos y accesorios"},
    {prodId:"60141201",label:"equipo para motricidad gruesa o equilibrio"},
    {prodId:"60141202",label:"Piscinas de pelotas y accesorios"},
    {prodId:"60141203",label:"Casitas de juego"},
    {prodId:"60141204",label:"Triciclos o carretillas"},
    {prodId:"60141205",label:"zonas acolchadas de juego"},
    {prodId:"60141300",label:"Sistemas de construcción y bloques infantiles"},
    {prodId:"60141302",label:"Sets de construcción"},
    {prodId:"60141303",label:"Esteras para juegos"},
    {prodId:"60141304",label:"Sistemas de trenes o accesorios"},
    {prodId:"60141305",label:"Bloques de unidades"},
    {prodId:"60141306",label:"Vehículos de juego"},
    {prodId:"60141307",label:"Animales de juguete"},
    {prodId:"60141400",label:"Equipo de representación teatral y accesorios"},
    {prodId:"60141401",label:"Disfraces o accesorios"},
    {prodId:"60141402",label:"Centros para vestirse"},
    {prodId:"60141403",label:"Unidades o accesorios para el cuidado de la casa"},
    {prodId:"60141404",label:"Platos de comida de  juguete o accesorios"},
    {prodId:"60141405",label:"Kits o materiales para juegos de simulación"},
    {prodId:"10102000",label:"Animales salvajes vivos"},
    {prodId:"10102001",label:"Elefantes"},
    {prodId:"10102002",label:"Zorros vivos"},
    {prodId:"10102100",label:"Huevos de aves para incubar"},
    {prodId:"10122000",label:"Alimento para reptiles"},
    {prodId:"10122001",label:"Comida granulada para reptiles"},
    {prodId:"10122002",label:"Comida húmeda para reptiles"},
    {prodId:"10122003",label:"Comida viva para reptiles"},
    {prodId:"10131700",label:"Hábitat para animales"},
    {prodId:"10131701",label:"Terrarios"},
    {prodId:"10131702",label:"Acuarios"},
    {prodId:"10214708",label:"Jacinto vivo rosado medio"},
    {prodId:"10152400",label:"Plántulas y semillas de tubérculos"},
    {prodId:"10201500",label:"Rosales vivos azules o lavanda o púrpura"},
    {prodId:"10201501",label:"Rosal vivo allure o sterling 95"},
    {prodId:"10201502",label:"Rosal vivo amnesia"},
    {prodId:"10201503",label:"Rosal vivo augusta louise"},
    {prodId:"10201504",label:"Rosal vivo avant garde"},
    {prodId:"10201505",label:"Rosal vivo blue bird"},
    {prodId:"10201506",label:"Rosal vivo curiosa"},
    {prodId:"10201507",label:"Rosal vivo cool water"},
    {prodId:"10201508",label:"Rosal vivo delilah"},
    {prodId:"10201509",label:"Rosal vivo double party"},
    {prodId:"10201510",label:"Rosal vivo faith"},
    {prodId:"10201511",label:"Rosal vivo mami blue o mammy blue"},
    {prodId:"10201512",label:"Rosal vivo marítimo"},
    {prodId:"10201513",label:"Rosal vivo milano"},
    {prodId:"10201514",label:"Rosal vivo mistery"},
    {prodId:"10201515",label:"Rosal vivo ocean song o boyfriend"},
    {prodId:"10201516",label:"Rosal vivo cezanne púrpura"},
    {prodId:"10201517",label:"Rosal vivo purple fragrance"},
    {prodId:"10201518",label:"Rosal vivo sanaa"},
    {prodId:"10201519",label:"Rosal vivo silverstone"},
    {prodId:"10201520",label:"Rosal vivo soulmate"},
    {prodId:"10201521",label:"Rosal vivo stranger"},
    {prodId:"10201522",label:"Rosal vivo tinted blue"},
    {prodId:"10201523",label:"Rosal vivo two faces"},
    {prodId:"10201600",label:"Rosales vivos chocolate o marrón"},
    {prodId:"10201601",label:"Rosal vivo black lava"},
    {prodId:"10201602",label:"Rosal vivo cimarron"},
    {prodId:"10201603",label:"Rosal vivo coffee break"},
    {prodId:"10201604",label:"Rosal vivo estelle"},
    {prodId:"10201605",label:"Rosal vivo gypsy leonidas"},
    {prodId:"10201606",label:"Rosal vivo leonidas"},
    {prodId:"10201607",label:"Rosal vivo matilda"},
    {prodId:"10201608",label:"Rosal vivo sunny leonidas"},
    {prodId:"10201609",label:"Rosal vivo terra nostra"},
    {prodId:"10201610",label:"Rosal vivo terracotta"},
    {prodId:"10201700",label:"Rosales vivos crema"},
    {prodId:"10201701",label:"Rosal vivo advenire"},
    {prodId:"10201702",label:"Rosal vivo alex"},
    {prodId:"10201703",label:"Rosal vivo antique brass"},
    {prodId:"10201704",label:"Rosal vivo aubade"},
    {prodId:"10201705",label:"Rosal vivo beach"},
    {prodId:"10201706",label:"Rosal vivo belle pearl"},
    {prodId:"10201707",label:"Rosal vivo blush de los andes"},
    {prodId:"10201708",label:"Rosal vivo camel"},
    {prodId:"10201709",label:"Rosal vivo caramel antike o caramel antique"},
    {prodId:"10201710",label:"Rosal vivo champagne"},
    {prodId:"10201711",label:"Rosal vivo clear ocean"},
    {prodId:"10201712",label:"Rosal vivo combo"},
    {prodId:"10201713",label:"Rosal vivo creme de la creme"},
    {prodId:"10201714",label:"Rosal vivo emanuella"},
    {prodId:"10201715",label:"Rosal vivo evolution"},
    {prodId:"10201716",label:"Rosal vivo fedora"},
    {prodId:"10201717",label:"Rosal vivo fenice"},
    {prodId:"10201718",label:"Rosal vivo french vanilla"},
    {prodId:"10201719",label:"Rosal vivo hollywood"},
    {prodId:"10201720",label:"Rosal vivo ilios"},
    {prodId:"10201721",label:"Rosal vivo jelena"},
    {prodId:"10201722",label:"Rosal vivo kameleon"},
    {prodId:"10201723",label:"Rosal vivo kentucky"},
    {prodId:"10201724",label:"Rosal vivo kings pride"},
    {prodId:"10201725",label:"Rosal vivo latin fusion"},
    {prodId:"10201726",label:"Rosal vivo lemon dream"},
    {prodId:"10201727",label:"Rosal vivo magic moka"},
    {prodId:"10201728",label:"Rosal vivo mamamia"},
    {prodId:"10201729",label:"Rosal vivo message"},
    {prodId:"10201730",label:"Rosal vivo muneca o munieca"},
    {prodId:"10201731",label:"Rosal vivo parfum de rosas"},
    {prodId:"10201732",label:"Rosal vivo porcelina"},
    {prodId:"10201733",label:"Rosal vivo privilege"},
    {prodId:"10201734",label:"Rosal vivo quicksand"},
    {prodId:"10201735",label:"Rosal vivo rollercoaster"},
    {prodId:"10201736",label:"Rosal vivo romantic curiosa"},
    {prodId:"10201737",label:"Rosal vivo safari"},
    {prodId:"10201738",label:"Rosal vivo sahara"},
    {prodId:"10201739",label:"Rosal vivo sandy femma"},
    {prodId:"10201740",label:"Rosal vivo talea"},
    {prodId:"10201741",label:"Rosal vivo timeless"},
    {prodId:"10201742",label:"Rosal vivo transition"},
    {prodId:"10201743",label:"Rosal vivo trump"},
    {prodId:"10201744",label:"Rosal vivo twin"},
    {prodId:"10201745",label:"Rosal vivo vendela"},
    {prodId:"10201746",label:"Rosal vivo virginia"},
    {prodId:"10201800",label:"Rosales vivos verdes o lima"},
    {prodId:"10201801",label:"Rosal vivo amandine"},
    {prodId:"10201802",label:"Rosal vivo caipirinha"},
    {prodId:"10201803",label:"Rosal vivo green fashion"},
    {prodId:"10201804",label:"Rosal vivo green tea"},
    {prodId:"10201805",label:"Rosal vivo jade"},
    {prodId:"10201806",label:"Rosal vivo limbo"},
    {prodId:"10201807",label:"Rosal vivo limena o limenia"},
    {prodId:"10201808",label:"Rosal vivo limona"},
    {prodId:"10201809",label:"Rosal vivo old dutch"},
    {prodId:"10201810",label:"Rosal vivo super green"},
    {prodId:"10201811",label:"Rosal vivo sweet green"},
    {prodId:"10201812",label:"Rosal vivo viva"},
    {prodId:"10201813",label:"Rosal vivo zazu"},
    {prodId:"10201900",label:"Rosales vivos rosado claro"},
    {prodId:"10201901",label:"Rosal vivo anna"},
    {prodId:"10201902",label:"Rosal vivo bella vita"},
    {prodId:"10201903",label:"Rosal vivo bridal dream"},
    {prodId:"10201904",label:"Rosal vivo candy bianca"},
    {prodId:"10201905",label:"Rosal vivo caress"},
    {prodId:"10201906",label:"Rosal vivo carolina"},
    {prodId:"10201907",label:"Rosal vivo climax"},
    {prodId:"10201908",label:"Rosal vivo danny"},
    {prodId:"10201909",label:"Rosal vivo dolce vita"},
    {prodId:"10201910",label:"Rosal vivo elite"},
    {prodId:"10201911",label:"Rosal vivo emma"},
    {prodId:"10201912",label:"Rosal vivo engagement"},
    {prodId:"10201913",label:"Rosal vivo esther"},
    {prodId:"10201914",label:"Rosal vivo excalibur"},
    {prodId:"10201915",label:"Rosal vivo exciting"},
    {prodId:"10201916",label:"Rosal vivo first lady"},
    {prodId:"10201917",label:"Rosal vivo geraldine"},
    {prodId:"10201918",label:"Rosal vivo gotcha"},
    {prodId:"10201919",label:"Rosal vivo harmonie"},
    {prodId:"10201920",label:"Rosal vivo heaven"},
    {prodId:"10201921",label:"Rosal vivo high and elegant"},
    {prodId:"10201922",label:"Rosal vivo katherine"},
    {prodId:"10201923",label:"Rosal vivo king kong"},
    {prodId:"10201924",label:"Rosal vivo livia"},
    {prodId:"10201925",label:"Rosal vivo lorena"},
    {prodId:"10201926",label:"Rosal vivo lovely"},
    {prodId:"10201927",label:"Rosal vivo maaike"},
    {prodId:"10201928",label:"Rosal vivo marilyn"},
    {prodId:"10201929",label:"Rosal vivo marlise"},
    {prodId:"10201930",label:"Rosal vivo miranda o ausimmon"},
    {prodId:"10201931",label:"Rosal vivo mona lisa"},
    {prodId:"10201932",label:"Rosal vivo nirvana"},
    {prodId:"10201933",label:"Rosal vivo o hara"},
    {prodId:"10201934",label:"Rosal vivo ole"},
    {prodId:"10201935",label:"Rosal vivo olga"},
    {prodId:"10201936",label:"Rosal vivo pacífica"},
    {prodId:"10201937",label:"Rosal vivo party mix"},
    {prodId:"10201938",label:"Rosal vivo peckoubo o pekcoubo"},
    {prodId:"10201939",label:"Rosal vivo phoebe o ausnotice"},
    {prodId:"10201940",label:"Rosal vivo pink farfalla"},
    {prodId:"10201941",label:"Rosal vivo pink finess"},
    {prodId:"10201942",label:"Rosal vivo pink magic"},
    {prodId:"10201943",label:"Rosal vivo pink osiana"},
    {prodId:"10201944",label:"Rosal vivo pretty woman"},
    {prodId:"10201945",label:"Rosal vivo romance"},
    {prodId:"10201946",label:"Rosal vivo romantic antike o antique"},
    {prodId:"10201947",label:"Rosal vivo rosalind o astew"},
    {prodId:"10201948",label:"Rosal vivo rosita vendela"},
    {prodId:"10201949",label:"Rosal vivo secret garden"},
    {prodId:"10201950",label:"Rosal vivo solaire"},
    {prodId:"10201951",label:"Rosal vivo sophie"},
    {prodId:"10201952",label:"Rosal vivo sweet akito"},
    {prodId:"10201953",label:"Rosal vivo sweet avalanche"},
    {prodId:"10201954",label:"Rosal vivo sweet elegance"},
    {prodId:"10201955",label:"Rosal vivo sweet pink"},
    {prodId:"10201956",label:"Rosal vivo titanic"},
    {prodId:"10201957",label:"Rosal vivo toscanini"},
    {prodId:"10201958",label:"Rosal vivo vania"},
    {prodId:"10201959",label:"Rosal vivo vanity"},
    {prodId:"10201960",label:"Rosal vivo vision"},
    {prodId:"10201961",label:"Rosal vivo vivaldi"},
    {prodId:"10201962",label:"Rosal vivo whisper"},
    {prodId:"10202000",label:"Rosales vivos multicolor rosados"},
    {prodId:"10202001",label:"Rosal vivo attracta"},
    {prodId:"10202002",label:"Rosal vivo boheme"},
    {prodId:"10202003",label:"Rosal vivo carousel"},
    {prodId:"10202004",label:"Rosal vivo cezanne"},
    {prodId:"10202005",label:"Rosal vivo crazy one"},
    {prodId:"10202006",label:"Rosal vivo dance valley"},
    {prodId:"10202007",label:"Rosal vivo duett"},
    {prodId:"10202008",label:"Rosal vivo esperance"},
    {prodId:"10202009",label:"Rosal vivo fiesta"},
    {prodId:"10202010",label:"Rosal vivo halloween"},
    {prodId:"10202011",label:"Rosal vivo highlander"},
    {prodId:"10202012",label:"Rosal vivo hot ambiance"},
    {prodId:"10202013",label:"Rosal vivo la belle"},
    {prodId:"10202014",label:"Rosal vivo laguna"},
    {prodId:"10202015",label:"Rosal vivo latin ambiance"},
    {prodId:"10202016",label:"Rosal vivo latin breeze"},
    {prodId:"10202017",label:"Rosal vivo long arifa"},
    {prodId:"10202018",label:"Rosal vivo murano"},
    {prodId:"10202019",label:"Rosal vivo n-joy rose"},
    {prodId:"10202020",label:"Rosal vivo panama"},
    {prodId:"10202021",label:"Rosal vivo peppermint"},
    {prodId:"10202022",label:"Rosal vivo pijama party"},
    {prodId:"10202023",label:"Rosal vivo portofino"},
    {prodId:"10202024",label:"Rosal vivo priceless"},
    {prodId:"10202025",label:"Rosal vivo queen amazon"},
    {prodId:"10202026",label:"Rosal vivo ranuncula"},
    {prodId:"10202027",label:"Rosal vivo rossini"},
    {prodId:"10202028",label:"Rosal vivo sabina o sabrina"},
    {prodId:"10202029",label:"Rosal vivo scandal"},
    {prodId:"10202030",label:"Rosal vivo silvery pink"},
    {prodId:"10202031",label:"Rosal vivo something else"},
    {prodId:"10202032",label:"Rosal vivo soutine"},
    {prodId:"10202033",label:"Rosal vivo sovereign"},
    {prodId:"10202034",label:"Rosal vivo super disco"},
    {prodId:"10202035",label:"Rosal vivo ts 1968"},
    {prodId:"10202036",label:"Rosal vivo variance"},
    {prodId:"10202037",label:"Rosal vivo verdi"},
    {prodId:"10202400",label:"Rosales vivos rojos o burgundy"},
    {prodId:"10202401",label:"Rosal vivo african dawn"},
    {prodId:"10202402",label:"Rosal vivo amada"},
    {prodId:"10202403",label:"Rosal vivo black baccara"},
    {prodId:"10202404",label:"Rosal vivo black beauty"},
    {prodId:"10202405",label:"Rosal vivo black finess o black magic"},
    {prodId:"10202406",label:"Rosal vivo black magic"},
    {prodId:"10202407",label:"Rosal vivo bohemian o pasarela"},
    {prodId:"10202408",label:"Rosal vivo breathless"},
    {prodId:"10202409",label:"Rosal vivo caballero"},
    {prodId:"10202410",label:"Rosal vivo carrera"},
    {prodId:"10202411",label:"Rosal vivo charlene"},
    {prodId:"10202412",label:"Rosal vivo charlotte"},
    {prodId:"10202413",label:"Rosal vivo cherry lady"},
    {prodId:"10202414",label:"Rosal vivo cherry love"},
    {prodId:"10202415",label:"Rosal vivo classy rose"},
    {prodId:"10202416",label:"Rosal vivo colorado velvet"},
    {prodId:"10202417",label:"Rosal vivo corazon"},
    {prodId:"10202418",label:"Rosal vivo corrida"},
    {prodId:"10202419",label:"Rosal vivo dynamite"},
    {prodId:"10202420",label:"Rosal vivo eurored"},
    {prodId:"10202421",label:"Rosal vivo fashion"},
    {prodId:"10202422",label:"Rosal vivo fire and ice"},
    {prodId:"10202423",label:"Rosal vivo first red"},
    {prodId:"10202424",label:"Rosal vivo forever young"},
    {prodId:"10202425",label:"Rosal vivo freedom"},
    {prodId:"10202426",label:"Rosal vivo freestyle"},
    {prodId:"10202427",label:"Rosal vivo friendship"},
    {prodId:"10202428",label:"Rosal vivo gospel"},
    {prodId:"10202429",label:"Rosal vivo graffity"},
    {prodId:"10202430",label:"Rosal vivo grand gala"},
    {prodId:"10202431",label:"Rosal vivo grand prix"},
    {prodId:"10202432",label:"Rosal vivo grand classe"},
    {prodId:"10202433",label:"Rosal vivo hearts"},
    {prodId:"10202434",label:"Rosal vivo heat"},
    {prodId:"10202435",label:"Rosal vivo hocus pocus"},
    {prodId:"10202436",label:"Rosal vivo lady in red"},
    {prodId:"10202437",label:"Rosal vivo latin lady"},
    {prodId:"10202438",label:"Rosal vivo legend"},
    {prodId:"10202439",label:"Rosal vivo lulu"},
    {prodId:"10202440",label:"Rosal vivo luna rossa"},
    {prodId:"10202441",label:"Rosal vivo luxor"},
    {prodId:"10202442",label:"Rosal vivo madame delbard o carola"},
    {prodId:"10202443",label:"Rosal vivo miss paris"},
    {prodId:"10202444",label:"Rosal vivo nicole"},
    {prodId:"10202445",label:"Rosal vivo night fever"},
    {prodId:"10202446",label:"Rosal vivo obsession"},
    {prodId:"10202447",label:"Rosal vivo opium"},
    {prodId:"10202448",label:"Rosal vivo paz"},
    {prodId:"10202449",label:"Rosal vivo preference"},
    {prodId:"10202450",label:"Rosal vivo red berlin"},
    {prodId:"10202451",label:"Rosal vivo red bull"},
    {prodId:"10202452",label:"Rosal vivo red calypso"},
    {prodId:"10202453",label:"Rosal vivo red diamond"},
    {prodId:"10202454",label:"Rosal vivo red fantasy"},
    {prodId:"10202455",label:"Rosal vivo red france"},
    {prodId:"10202456",label:"Rosal vivo red intuition"},
    {prodId:"10202457",label:"Rosal vivo red jewel"},
    {prodId:"10202458",label:"Rosal vivo red magic"},
    {prodId:"10202459",label:"Rosal vivo red one"},
    {prodId:"10202460",label:"Rosal vivo red paris"},
    {prodId:"10202461",label:"Rosal vivo red princess"},
    {prodId:"10202462",label:"Rosal vivo red sensation o colorad"},
    {prodId:"10202463",label:"Rosal vivo red unique"},
    {prodId:"10202464",label:"Rosal vivo rockefeller"},
    {prodId:"10202465",label:"Rosal vivo romeo"},
    {prodId:"10202466",label:"Rosal vivo rouge baiser"},
    {prodId:"10202467",label:"Rosal vivo roulette"},
    {prodId:"10202468",label:"Rosal vivo royal massai"},
    {prodId:"10202469",label:"Rosal vivo royal red"},
    {prodId:"10202470",label:"Rosal vivo samurai"},
    {prodId:"10202471",label:"Rosal vivo sexy red"},
    {prodId:"10202472",label:"Rosal vivo starfire"},
    {prodId:"10202473",label:"Rosal vivo tango"},
    {prodId:"10202474",label:"Rosal vivo tiger tail"},
    {prodId:"10202475",label:"Rosal vivo tinto"},
    {prodId:"10202476",label:"Rosal vivo top secret"},
    {prodId:"10202477",label:"Rosal vivo vital"},
    {prodId:"10202478",label:"Rosal vivo wisdom"},
    {prodId:"10202479",label:"Rosal vivo xantia"},
    {prodId:"10202480",label:"Rosal vivo xcite"},
    {prodId:"10202500",label:"Rosales vivos sweetheart"},
    {prodId:"10202501",label:"Rosal vivo burgundy sweetheart"},
    {prodId:"10202502",label:"Rosal vivo cream sweetheart"},
    {prodId:"10202503",label:"Rosal vivo hot pink sweetheart"},
    {prodId:"10202504",label:"Rosal vivo lavender sweetheart"},
    {prodId:"10202505",label:"Rosal vivo light pink sweetheart"},
    {prodId:"10202506",label:"Rosal vivo orange sweetheart"},
    {prodId:"10202507",label:"Rosal vivo peach sweetheart"},
    {prodId:"10202508",label:"Rosal vivo red sweetheart"},
    {prodId:"10202509",label:"Rosal vivo white sweetheart"},
    {prodId:"10202510",label:"Rosal vivo yelllow sweetheart"},
    {prodId:"10202600",label:"Rosales vivos blancos"},
    {prodId:"10202601",label:"Rosal vivo absolut"},
    {prodId:"10202602",label:"Rosal vivo aida"},
    {prodId:"10202603",label:"Rosal vivo akito"},
    {prodId:"10202604",label:"Rosal vivo amelia"},
    {prodId:"10202605",label:"Rosal vivo anastasia"},
    {prodId:"10202606",label:"Rosal vivo andean crystal"},
    {prodId:"10202607",label:"Rosal vivo angel"},
    {prodId:"10202608",label:"Rosal vivo annemarie"},
    {prodId:"10202609",label:"Rosal vivo avalanche"},
    {prodId:"10202610",label:"Rosal vivo bianca"},
    {prodId:"10202611",label:"Rosal vivo blizzard"},
    {prodId:"10202612",label:"Rosal vivo bridal akito"},
    {prodId:"10202613",label:"Rosal vivo domenica"},
    {prodId:"10202614",label:"Rosal vivo escimo"},
    {prodId:"10202615",label:"Rosal vivo farfalla"},
    {prodId:"10202616",label:"Rosal vivo high and peace"},
    {prodId:"10202617",label:"Rosal vivo high and pure"},
    {prodId:"10202618",label:"Rosal vivo inocencia o innocenti"},
    {prodId:"10202619",label:"Rosal vivo ivory"},
    {prodId:"10202620",label:"Rosal vivo mondial"},
    {prodId:"10202621",label:"Rosal vivo mount everest"},
    {prodId:"10202622",label:"Rosal vivo nova zembla"},
    {prodId:"10202623",label:"Rosal vivo patience o auspator"},
    {prodId:"10202624",label:"Rosal vivo polar star"},
    {prodId:"10202625",label:"Rosal vivo polo"},
    {prodId:"10202626",label:"Rosal vivo proud"},
    {prodId:"10202627",label:"Rosal vivo snowy jewel"},
    {prodId:"10202628",label:"Rosal vivo tibet"},
    {prodId:"10202629",label:"Rosal vivo tineke"},
    {prodId:"10202630",label:"Rosal vivo vitality"},
    {prodId:"10202631",label:"Rosal vivo white cadillac"},
    {prodId:"10202632",label:"Rosal vivo white dove"},
    {prodId:"10202700",label:"Rosales vivos amarillos"},
    {prodId:"10202701",label:"Rosal vivo aalsmeer gold"},
    {prodId:"10202702",label:"Rosal vivo alina"},
    {prodId:"10202703",label:"Rosal vivo ambiance"},
    {prodId:"10202704",label:"Rosal vivo aquarel"},
    {prodId:"10202705",label:"Rosal vivo autumn"},
    {prodId:"10202706",label:"Rosal vivo brasil"},
    {prodId:"10202707",label:"Rosal vivo candle light"},
    {prodId:"10202708",label:"Rosal vivo cantata o cantate"},
    {prodId:"10202709",label:"Rosal vivo capriccio"},
    {prodId:"10202710",label:"Rosal vivo caribbean"},
    {prodId:"10202711",label:"Rosal vivo circus"},
    {prodId:"10202712",label:"Rosal vivo citran"},
    {prodId:"10202713",label:"Rosal vivo concorde"},
    {prodId:"10202714",label:"Rosal vivo conga"},
    {prodId:"10202715",label:"Rosal vivo deja vu"},
    {prodId:"10202716",label:"Rosal vivo desire"},
    {prodId:"10202717",label:"Rosal vivo donia sol"},
    {prodId:"10202718",label:"Rosal vivo dueto"},
    {prodId:"10202719",label:"Rosal vivo erin"},
    {prodId:"10202720",label:"Rosal vivo exotic curiosa"},
    {prodId:"10202721",label:"Rosal vivo feria"},
    {prodId:"10202722",label:"Rosal vivo fire bird"},
    {prodId:"10202723",label:"Rosal vivo florida"},
    {prodId:"10202724",label:"Rosal vivo friendly"},
    {prodId:"10202725",label:"Rosal vivo gallinda"},
    {prodId:"10202726",label:"Rosal vivo geisha"},
    {prodId:"10202727",label:"Rosal vivo gelbe"},
    {prodId:"10202728",label:"Rosal vivo gelosia o yellow flame"},
    {prodId:"10202729",label:"Rosal vivo gold rush"},
    {prodId:"10202730",label:"Rosal vivo gold star"},
    {prodId:"10202731",label:"Rosal vivo gold strike"},
    {prodId:"10202732",label:"Rosal vivo golda"},
    {prodId:"10202733",label:"Rosal vivo golden fashion"},
    {prodId:"10202734",label:"Rosal vivo golden gate"},
    {prodId:"10202735",label:"Rosal vivo gran dorado"},
    {prodId:"10202736",label:"Rosal vivo helio"},
    {prodId:"10202737",label:"Rosal vivo high and exotic"},
    {prodId:"10202738",label:"Rosal vivo high and yellow flame"},
    {prodId:"10202739",label:"Rosal vivo high and yellow magic"},
    {prodId:"10202740",label:"Rosal vivo high society"},
    {prodId:"10202741",label:"Rosal vivo hummer"},
    {prodId:"10202742",label:"Rosal vivo idole o elle"},
    {prodId:"10202743",label:"Rosal vivo inti"},
    {prodId:"10202744",label:"Rosal vivo jet set"},
    {prodId:"10202745",label:"Rosal vivo judy"},
    {prodId:"10202746",label:"Rosal vivo jupiter"},
    {prodId:"10202747",label:"Rosal vivo konfetti"},
    {prodId:"10202748",label:"Rosal vivo kyara o kira"},
    {prodId:"10202749",label:"Rosal vivo latin beauty"},
    {prodId:"10202750",label:"Rosal vivo latin spirit"},
    {prodId:"10202751",label:"Rosal vivo latina"},
    {prodId:"10202752",label:"Rosal vivo lina"},
    {prodId:"10202753",label:"Rosal vivo lindsey"},
    {prodId:"10202754",label:"Rosal vivo male"},
    {prodId:"10202755",label:"Rosal vivo marie clare"},
    {prodId:"10202756",label:"Rosal vivo marisa"},
    {prodId:"10202757",label:"Rosal vivo matchball"},
    {prodId:"10202758",label:"Rosal vivo melon"},
    {prodId:"10202759",label:"Rosal vivo mohana"},
    {prodId:"10202760",label:"Rosal vivo okie dokie"},
    {prodId:"10202761",label:"Rosal vivo pailine"},
    {prodId:"10202762",label:"Rosal vivo parrot"},
    {prodId:"10202763",label:"Rosal vivo rio d oro"},
    {prodId:"10202764",label:"Rosal vivo salami"},
    {prodId:"10202765",label:"Rosal vivo santa fe"},
    {prodId:"10202766",label:"Rosal vivo skyline"},
    {prodId:"10202767",label:"Rosal vivo sonrisa"},
    {prodId:"10202768",label:"Rosal vivo star ambiance"},
    {prodId:"10202769",label:"Rosal vivo starbust"},
    {prodId:"10202770",label:"Rosal vivo sun king"},
    {prodId:"10202771",label:"Rosal vivo sunmaster"},
    {prodId:"10202772",label:"Rosal vivo sunny milva"},
    {prodId:"10202773",label:"Rosal vivo sushi"},
    {prodId:"10202774",label:"Rosal vivo tabasco"},
    {prodId:"10202775",label:"Rosal vivo tara"},
    {prodId:"10202776",label:"Rosal vivo tresor 2000"},
    {prodId:"10202777",label:"Rosal vivo ooty"},
    {prodId:"10202778",label:"Rosal vivo yellow coral"},
    {prodId:"10202779",label:"Rosal vivo yellow finess"},
    {prodId:"10202780",label:"Rosal vivo yellow submarine"},
    {prodId:"10202781",label:"Rosal vivo yellow sunset"},
    {prodId:"10202782",label:"Rosal vivo yelllow timeless"},
    {prodId:"10202800",label:"Rosales vivos spray"},
    {prodId:"10202801",label:"Rosal vivo alegria spray"},
    {prodId:"10202802",label:"Rosal vivo andrea follies spray"},
    {prodId:"10202803",label:"Rosal vivo antara follies spray"},
    {prodId:"10202804",label:"Rosal vivo arrow follies spray"},
    {prodId:"10202805",label:"Rosal vivo babe spray"},
    {prodId:"10202806",label:"Rosal vivo bellina collection spray"},
    {prodId:"10202807",label:"Rosal vivo blue moon spray"},
    {prodId:"10202808",label:"Rosal vivo chablis spray"},
    {prodId:"10202809",label:"Rosal vivo cherry follies spray"},
    {prodId:"10202810",label:"Rosal vivo chess spray"},
    {prodId:"10202811",label:"Rosal vivo classic lydia spray"},
    {prodId:"10202812",label:"Rosal vivo cream gracia spray"},
    {prodId:"10202813",label:"Rosal vivo cream lydia spray"},
    {prodId:"10202814",label:"Rosal vivo cream sensation spray"},
    {prodId:"10202815",label:"Rosal vivo cremita spray"},
    {prodId:"10202816",label:"Rosal vivo diablo spray"},
    {prodId:"10202817",label:"Rosal vivo electra spray"},
    {prodId:"10202818",label:"Rosal vivo fire king spray"},
    {prodId:"10202819",label:"Rosal vivo fleur spray"},
    {prodId:"10202820",label:"Rosal vivo girlie follies spray"},
    {prodId:"10202821",label:"Rosal vivo giselle follies spray"},
    {prodId:"10202822",label:"Rosal vivo golden collection spray"},
    {prodId:"10202823",label:"Rosal vivo golden mimi spray"},
    {prodId:"10202824",label:"Rosal vivo gracia spray"},
    {prodId:"10202825",label:"Rosal vivo hot majolica spray"},
    {prodId:"10202826",label:"Rosal vivo hot pink follies spray"},
    {prodId:"10202827",label:"Rosal vivo ilse spray"},
    {prodId:"10202828",label:"Rosal vivo jelena spray"},
    {prodId:"10202829",label:"Rosal vivo laminuette spray"},
    {prodId:"10202830",label:"Rosal vivo lavender follies spray"},
    {prodId:"10202831",label:"Rosal vivo limoncello spray"},
    {prodId:"10202832",label:"Rosal vivo little silver spray"},
    {prodId:"10202833",label:"Rosal vivo lovely lydia spray"},
    {prodId:"10202834",label:"Rosal vivo lucy spray"},
    {prodId:"10202835",label:"Rosal vivo lydia spray"},
    {prodId:"10202836",label:"Rosal vivo macarena spray"},
    {prodId:"10202837",label:"Rosal vivo magic sensation spray"},
    {prodId:"10202838",label:"Rosal vivo majolica spray"},
    {prodId:"10202839",label:"Rosal vivo mambo number 5 spray"},
    {prodId:"10202840",label:"Rosal vivo mambo spray"},
    {prodId:"10202841",label:"Rosal vivo marlene spray"},
    {prodId:"10202842",label:"Rosal vivo mimi eden spray"},
    {prodId:"10202843",label:"Rosal vivo minou spray"},
    {prodId:"10202844",label:"Rosal vivo nikita spray"},
    {prodId:"10202845",label:"Rosal vivo novel collection spray"},
    {prodId:"10202846",label:"Rosal vivo orange success spray"},
    {prodId:"10202847",label:"Rosal vivo pepita spray"},
    {prodId:"10202848",label:"Rosal vivo pink flash spray"},
    {prodId:"10202849",label:"Rosal vivo pink sensation spray"},
    {prodId:"10202850",label:"Rosal vivo porcelina spray"},
    {prodId:"10202851",label:"Rosal vivo princess spray"},
    {prodId:"10202852",label:"Rosal vivo purple mikado spray"},
    {prodId:"10202853",label:"Rosal vivo red angel spray"},
    {prodId:"10202854",label:"Rosal vivo red collection spray"},
    {prodId:"10202855",label:"Rosal vivo red hero spray"},
    {prodId:"10202856",label:"Rosal vivo red mikado spray"},
    {prodId:"10202857",label:"Rosal vivo red vision spray"},
    {prodId:"10202858",label:"Rosal vivo ritmo spray"},
    {prodId:"10202859",label:"Rosal vivo romance mikado o eva spray"},
    {prodId:"10202860",label:"Rosal vivo romantica follies spray"},
    {prodId:"10202861",label:"Rosal vivo rubicon spray"},
    {prodId:"10202862",label:"Rosal vivo rumba spray"},
    {prodId:"10202863",label:"Rosal vivo salsa spray"},
    {prodId:"10202864",label:"Rosal vivo sangrita spray"},
    {prodId:"10202865",label:"Rosal vivo santa barbara spray"},
    {prodId:"10202866",label:"Rosal vivo sashaba spray"},
    {prodId:"10202867",label:"Rosal vivo scarlett spray"},
    {prodId:"10202868",label:"Rosal vivo seline spray"},
    {prodId:"10202869",label:"Rosal vivo sensation spray"},
    {prodId:"10202870",label:"Rosal vivo silver collection spray"},
    {prodId:"10202871",label:"Rosal vivo silver sensation spray"},
    {prodId:"10202872",label:"Rosal vivo snowdance spray"},
    {prodId:"10202873",label:"Rosal vivo snowflake spray"},
    {prodId:"10202874",label:"Rosal vivo suncity spray"},
    {prodId:"10202875",label:"Rosal vivo super nova spray"},
    {prodId:"10202876",label:"Rosal vivo sweet sensation spray"},
    {prodId:"10202877",label:"Rosal vivo taifun o typhoon spray"},
    {prodId:"10202878",label:"Rosal vivo tamango spray"},
    {prodId:"10202879",label:"Rosal vivo tanger follies spray"},
    {prodId:"10202880",label:"Rosal vivo tiara spray"},
    {prodId:"10202881",label:"Rosal vivo tiramisú spray"},
    {prodId:"10202882",label:"Rosal vivo twinkle bride spray"},
    {prodId:"10202883",label:"Rosal vivo viviane spray"},
    {prodId:"10202884",label:"Rosal vivo white majolica spray"},
    {prodId:"10202885",label:"Rosal vivo white mikado spray"},
    {prodId:"10202886",label:"Rosal vivo xentina spray"},
    {prodId:"10202887",label:"Rosal vivo yellow babe spray"},
    {prodId:"10202888",label:"Rosal vivo yellow follies spray"},
    {prodId:"10211500",label:"Anturios vivos"},
    {prodId:"10211501",label:"Anturio vivo chocolate"},
    {prodId:"10211502",label:"Anturio vivo rojo oscuro"},
    {prodId:"10211503",label:"Anturio vivo verde"},
    {prodId:"10211504",label:"Anturio vivo rosado fuerte"},
    {prodId:"10211505",label:"Anturio vivo mickey mouse"},
    {prodId:"10211506",label:"Anturio vivo obake verde y blanco"},
    {prodId:"10211507",label:"Anturio vivo obake rojo y verde"},
    {prodId:"10211508",label:"Anturio vivo anaranjado"},
    {prodId:"10211509",label:"Anturio vivo durazno"},
    {prodId:"10211510",label:"Anturio vivo picasso o pecoso"},
    {prodId:"10211511",label:"Anturio vivo rojo"},
    {prodId:"10211512",label:"Anturio vivo salpicadura"},
    {prodId:"10211513",label:"Anturio vivo fuego del trópico"},
    {prodId:"10211514",label:"Anturio vivo tulipán verde"},
    {prodId:"10211515",label:"Anturio vivo tulipán rosado"},
    {prodId:"10211516",label:"Anturio vivo tulipán púrpura"},
    {prodId:"10211517",label:"Anturio vivo tulipán rojo"},
    {prodId:"10211518",label:"Anturio vivo blanco"},
    {prodId:"10211519",label:"Anturio vivo cosa salvaje"},
    {prodId:"10211600",label:"Allium vivo"},
    {prodId:"10211601",label:"Allium vivo embajador"},
    {prodId:"10211602",label:"Allium vivo ampeloprasum"},
    {prodId:"10211603",label:"Allium vivo bullit o drumstick"},
    {prodId:"10211604",label:"Allium vivo christophii"},
    {prodId:"10211605",label:"Allium vivo cowanii spray white"},
    {prodId:"10211606",label:"Allium vivo gigante"},
    {prodId:"10211607",label:"Allium vivo gladiador"},
    {prodId:"10211608",label:"Allium vivo globemaster"},
    {prodId:"10211609",label:"Allium vivo bola de golf blanca"},
    {prodId:"10211610",label:"Allium vivo cabello"},
    {prodId:"10211611",label:"Allium vivo rosado gigante"},
    {prodId:"10211612",label:"Allium vivo sensación púrpura"},
    {prodId:"10211613",label:"Allium vivo sicilum hanging"},
    {prodId:"10211614",label:"Allium vivo spider schubertii"},
    {prodId:"10211615",label:"Allium vivo spray moly"},
    {prodId:"10211616",label:"Allium vivo spray roseum"},
    {prodId:"10211617",label:"Allium vivo tuberosum"},
    {prodId:"10211618",label:"Allium vivo unifolium o spray allium"},
    {prodId:"10211619",label:"Allium vivo monte everest blanco"},
    {prodId:"10211900",label:"Amaryllis vivo"},
    {prodId:"10211901",label:"Amaryllis vivo naranja"},
    {prodId:"10211902",label:"Amaryllis vivo orange nagano"},
    {prodId:"10211903",label:"Amaryllis vivo pygmee mini"},
    {prodId:"10211904",label:"Amaryllis vivo red lion"},
    {prodId:"10211905",label:"Amaryllis vivo rilona"},
    {prodId:"10211906",label:"Amaryllis vivo royal velvet"},
    {prodId:"10211907",label:"Amaryllis vivo sonatini orange"},
    {prodId:"10211908",label:"Amaryllis vivo sonatini red"},
    {prodId:"10211909",label:"Amaryllis vivo tango"},
    {prodId:"10211910",label:"Amaryllis vivo tinto night"},
    {prodId:"10212000",label:"Anémona viva"},
    {prodId:"10212001",label:"Anémona viva aubergine"},
    {prodId:"10212002",label:"Anémona viva negra"},
    {prodId:"10212003",label:"Anémona viva azul"},
    {prodId:"10212004",label:"Anémona viva cereza"},
    {prodId:"10212005",label:"Anémona viva coronaria"},
    {prodId:"10212006",label:"Anémona viva rosada fuerte"},
    {prodId:"10212007",label:"Anémona viva rosada claro"},
    {prodId:"10212008",label:"Anémona viva rosada"},
    {prodId:"10212009",label:"Anémona viva púrpura"},
    {prodId:"10212010",label:"Anémona viva roja"},
    {prodId:"10212011",label:"Anémona viva blanca"},
    {prodId:"10212100",label:"Asclepia viva"},
    {prodId:"10212101",label:"Asclepia viva lavanda"},
    {prodId:"10212102",label:"Asclepia viva moby dick"},
    {prodId:"10212103",label:"Asclepia viva tuberosa"},
    {prodId:"10212104",label:"Asclepia viva blanca"},
    {prodId:"10212200",label:"Aster viva"},
    {prodId:"10212201",label:"Aster viva belleza"},
    {prodId:"10212202",label:"Aster viva azul japonés"},
    {prodId:"10212203",label:"Aster viva verde japonés"},
    {prodId:"10212204",label:"Aster viva rosado fuerte japonés"},
    {prodId:"10212205",label:"Aster viva lavanda japonés"},
    {prodId:"10212206",label:"Aster viva rosado claro japonés"},
    {prodId:"10212207",label:"Aster viva durazno japonés"},
    {prodId:"10212208",label:"Aster viva rosado japonés"},
    {prodId:"10212209",label:"Aster viva púrpura japonés"},
    {prodId:"10212210",label:"Aster viva rojo japonés"},
    {prodId:"10212211",label:"Aster viva araña japonés"},
    {prodId:"10212212",label:"Aster viva blanca japonés"},
    {prodId:"10212213",label:"Aster viva rosado fuerte novi belgii"},
    {prodId:"10212214",label:"Aster viva lavanda novi belgii"},
    {prodId:"10212215",label:"Aster viva rosado novi belgii"},
    {prodId:"10212216",label:"Aster viva púrpura novi belgii"},
    {prodId:"10212217",label:"Aster viva blanca novi belgii"},
    {prodId:"10212218",label:"Aster viva solidago"},
    {prodId:"10212219",label:"Aster viva araña"},
    {prodId:"10212300",label:"Berzelia viva"},
    {prodId:"10212301",label:"Berzelia lanuginosa viva abrotanoides"},
    {prodId:"10212302",label:"Berzelia lanuginosa viva fireball"},
    {prodId:"10212303",label:"Berzelia lanuginosa viva galpinii"},
    {prodId:"10212304",label:"Berzelia lanuginosa viva galpini o baubles"},
    {prodId:"10212305",label:"Berzelia lanuginosa viva squarrosa"},
    {prodId:"10212400",label:"Bouvaria viva"},
    {prodId:"10212401",label:"Bouvardia viva doble rosado fuerte"},
    {prodId:"10212402",label:"Bouvardia viva rosado claro"},
    {prodId:"10212403",label:"Bouvardia viva doble rosado claro"},
    {prodId:"10212404",label:"Bouvardia viva roja"},
    {prodId:"10212405",label:"Bouvardia viva blanca"},
    {prodId:"10212500",label:"Brodiaea viva"},
    {prodId:"10212501",label:"Brodiaea viva congesta"},
    {prodId:"10212502",label:"Brodiaea viva congesta lavanda"},
    {prodId:"10212503",label:"Brodiaea viva hyacintha"},
    {prodId:"10212504",label:"Brodiaea viva ida maija"},
    {prodId:"10212505",label:"Brodiaea viva starlight"},
    {prodId:"10212600",label:"Calla viva"},
    {prodId:"10212601",label:"Calla viva green goddess"},
    {prodId:"10212602",label:"Calla viva ramillete albertville"},
    {prodId:"10212603",label:"Calla viva ramillete aranal"},
    {prodId:"10212604",label:"Calla viva ramillete black eyed beauty"},
    {prodId:"10212605",label:"Calla viva ramillete black star"},
    {prodId:"10212606",label:"Calla viva ramillete brisbane"},
    {prodId:"10212607",label:"Calla viva ramillete crystal blush"},
    {prodId:"10212608",label:"Calla viva ramillete crystal pink"},
    {prodId:"10212609",label:"Calla viva ramillete crystal white"},
    {prodId:"10212610",label:"Calla viva ramillete dark captain romanc"},
    {prodId:"10212611",label:"Calla viva ramillete dark mozart"},
    {prodId:"10212612",label:"Calla viva ramillete dark naomi"},
    {prodId:"10212613",label:"Calla viva ramillete deformed calla"},
    {prodId:"10212614",label:"Calla viva ramillete dordogne"},
    {prodId:"10212615",label:"Calla viva ramillete etude"},
    {prodId:"10212616",label:"Calla viva ramillete farao"},
    {prodId:"10212617",label:"Calla viva ramillete fire glow"},
    {prodId:"10212618",label:"Calla viva ramillete florex gold"},
    {prodId:"10212619",label:"Calla viva ramillete garnet glow"},
    {prodId:"10212620",label:"Calla viva ramillete hot chocolate"},
    {prodId:"10212621",label:"Calla viva ramillete lavender improved"},
    {prodId:"10212622",label:"Calla viva ramillete light cromance"},
    {prodId:"10212623",label:"Calla viva ramillete little suzy"},
    {prodId:"10212624",label:"Calla viva ramillete majestic red"},
    {prodId:"10212625",label:"Calla viva ramillete mango"},
    {prodId:"10212626",label:"Calla viva ramillete merlot"},
    {prodId:"10212627",label:"Calla viva ramillete mozart"},
    {prodId:"10212628",label:"Calla viva ramillete naomi"},
    {prodId:"10212629",label:"Calla viva ramillete night cap"},
    {prodId:"10212630",label:"Calla viva ramillete odessa"},
    {prodId:"10212631",label:"Calla viva ramillete pacific pink"},
    {prodId:"10212632",label:"Calla viva ramillete passion fruit"},
    {prodId:"10212633",label:"Calla viva ramillete picasso"},
    {prodId:"10212634",label:"Calla viva ramillete pillow talk"},
    {prodId:"10212635",label:"Calla viva ramillete pink persuation"},
    {prodId:"10212636",label:"Calla viva ramillete pisa"},
    {prodId:"10212637",label:"Calla viva ramillete pot of"},
    {prodId:"10212638",label:"Calla viva ramillete red sox"},
    {prodId:"10212639",label:"Calla viva ramillete rosa"},
    {prodId:"10212640",label:"Calla viva ramillete ruby light rose"},
    {prodId:"10212641",label:"Calla viva ramillete samur"},
    {prodId:"10212642",label:"Calla viva ramillete sapporo"},
    {prodId:"10212643",label:"Calla viva ramillete schwarzwalder"},
    {prodId:"10212644",label:"Calla viva ramillete serrada"},
    {prodId:"10212645",label:"Calla viva ramillete solemio"},
    {prodId:"10212646",label:"Calla viva ramillete sunrise"},
    {prodId:"10212647",label:"Calla viva ramillete super mac"},
    {prodId:"10212648",label:"Calla viva ramillete swan lake"},
    {prodId:"10212649",label:"Calla viva ramillete vermeer"},
    {prodId:"10212650",label:"Calla viva ramillete white butterfly"},
    {prodId:"10212651",label:"Calla viva ramillete yellow"},
    {prodId:"10212652",label:"Calla viva ramillete yellow mozart"},
    {prodId:"10212653",label:"Calla viva ramillete white large"},
    {prodId:"10212800",label:"Celosia viva"},
    {prodId:"10212801",label:"Celosia viva cresta de gallo verde"},
    {prodId:"10212802",label:"Celosia viva cresta de gallo anaranjado"},
    {prodId:"10212803",label:"Celosia viva cresta de gallo rosado"},
    {prodId:"10212804",label:"Celosia viva cresta de gallo púrpura"},
    {prodId:"10212805",label:"Celosia viva cresta de gallo rojo"},
    {prodId:"10212806",label:"Celosia viva cresta de gallo amarillo"},
    {prodId:"10212807",label:"Celosia viva pluma rosado claro"},
    {prodId:"10212808",label:"Celosia viva pluma anaranjado"},
    {prodId:"10212809",label:"Celosia viva pluma púrpura"},
    {prodId:"10212810",label:"Celosia viva pluma rojo"},
    {prodId:"10212811",label:"Celosia viva pluma amarillo"},
    {prodId:"10212812",label:"Celosia viva trigo rosado"},
    {prodId:"10212813",label:"Celosia viva trigo amarillo"},
    {prodId:"10212900",label:"Narciso vivo"},
    {prodId:"10212901",label:"Narciso vivo dick wilden"},
    {prodId:"10212902",label:"Narciso vivo dutch master"},
    {prodId:"10212903",label:"Narciso vivo ice follies"},
    {prodId:"10212904",label:"Narciso vivo ice king"},
    {prodId:"10212905",label:"Narciso vivo johan strauss"},
    {prodId:"10212906",label:"Narciso vivo yellow carlton"},
    {prodId:"10213000",label:"Dalia viva"},
    {prodId:"10213001",label:"Dalia viva bicolor"},
    {prodId:"10213002",label:"Dalia viva rosado fuerte"},
    {prodId:"10213003",label:"Dalia viva rosado claro"},
    {prodId:"10213004",label:"Dalia viva rosado medio"},
    {prodId:"10213005",label:"Dalia viva anaranjado"},
    {prodId:"10213006",label:"Dalia viva durazno"},
    {prodId:"10213007",label:"Dalia viva púrpura"},
    {prodId:"10213008",label:"Dalia viva roja"},
    {prodId:"10213009",label:"Dalia viva blanca"},
    {prodId:"10213010",label:"Dalia viva amarilla"},
    {prodId:"10213100",label:"Delphinium vivo"},
    {prodId:"10213101",label:"Delphinium vivo bella azul oscuro"},
    {prodId:"10213102",label:"Delphinium vivo bella azul claro"},
    {prodId:"10213103",label:"Delphinium vivo bella blanco"},
    {prodId:"10213104",label:"Delphinium vivo azul sombra"},
    {prodId:"10213105",label:"Delphinium vivo híbrido azul oscuro"},
    {prodId:"10213106",label:"Delphinium vivo híbrido azul claro"},
    {prodId:"10213107",label:"Delphinium vivo híbrido malva"},
    {prodId:"10213108",label:"Delphinium vivo híbrido rosado"},
    {prodId:"10213109",label:"Delphinium vivo híbrido púrpura"},
    {prodId:"10213110",label:"Delphinium vivo híbrido rojo"},
    {prodId:"10213111",label:"Delphinium vivo híbrido blanco"},
    {prodId:"10213112",label:"Delphinium vivo princesa carolina"},
    {prodId:"10213113",label:"Delphinium vivo volkerfrieden"},
    {prodId:"10213300",label:"Eremruses vivas"},
    {prodId:"10213301",label:"Eremurus vivo deruyter híbrido"},
    {prodId:"10213302",label:"Eremurus vivo himalaicus blanco"},
    {prodId:"10213303",label:"Eremurus vivo anaranjado"},
    {prodId:"10213304",label:"Eremurus vivo durazno"},
    {prodId:"10213305",label:"Eremurus vivo amarillo"},
    {prodId:"10213400",label:"Brezo (erica) viva"},
    {prodId:"10213401",label:"Brezo (erica) vivo campunalarus"},
    {prodId:"10213402",label:"Brezo (erica) vivo conica"},
    {prodId:"10213403",label:"Brezo (erica) vivo green ice"},
    {prodId:"10213404",label:"Brezo (erica) vivo rosado"},
    {prodId:"10213405",label:"Brezo (erica) vivo príncipe de gales"},
    {prodId:"10213500",label:"Euphorbia viva"},
    {prodId:"10213501",label:"Euphorbia viva characias"},
    {prodId:"10213502",label:"Euphorbia viva griffithii fireglow"},
    {prodId:"10213503",label:"Euphorbia viva martini"},
    {prodId:"10213504",label:"Euphorbia viva anaranjada"},
    {prodId:"10213505",label:"Euphorbia viva durazno"},
    {prodId:"10213506",label:"Euphorbia viva rosada"},
    {prodId:"10213507",label:"Euphorbia viva roja"},
    {prodId:"10213508",label:"Euphorbia viva blanca"},
    {prodId:"10213509",label:"Euphorbia viva amarilla"},
    {prodId:"10213510",label:"Euphorbia viva amarilla spurge"},
    {prodId:"10213600",label:"Fresia viva"},
    {prodId:"10213601",label:"Fresia viva crema"},
    {prodId:"10213602",label:"Fresia viva doble blanca"},
    {prodId:"10213603",label:"Fresia viva doble amarilla"},
    {prodId:"10213604",label:"Fresia viva rosado fuerte"},
    {prodId:"10213605",label:"Fresia viva lady brunet"},
    {prodId:"10213606",label:"Fresia viva lavanda"},
    {prodId:"10213607",label:"Fresia viva rosado medio"},
    {prodId:"10213608",label:"Fresia viva anaranjado"},
    {prodId:"10213609",label:"Fresia viva pimpinela"},
    {prodId:"10213610",label:"Fresia viva rosada"},
    {prodId:"10213611",label:"Fresia viva pùrpura"},
    {prodId:"10213612",label:"Fresia viva roja"},
    {prodId:"10213613",label:"Fresia viva blanca"},
    {prodId:"10213614",label:"Fresia viva amarilla"},
    {prodId:"10213800",label:"Hiniesta viva"},
    {prodId:"10213801",label:"Hiniesta viva verde"},
    {prodId:"10213802",label:"Hiniesta viva rosado fuerte"},
    {prodId:"10213803",label:"Hiniesta viva lavanda"},
    {prodId:"10213804",label:"Hiniesta viva rosado claro"},
    {prodId:"10213805",label:"Hiniesta viva durazno"},
    {prodId:"10213806",label:"Hiniesta viva púrpura"},
    {prodId:"10213807",label:"Hiniesta viva blanco"},
    {prodId:"10213808",label:"Hiniesta viva amarillo"},
    {prodId:"10213900",label:"Margarita viva"},
    {prodId:"10213901",label:"Margarita viva crema de centro negro"},
    {prodId:"10213902",label:"Margarita viva crema"},
    {prodId:"10213903",label:"Margarita viva dorada"},
    {prodId:"10213904",label:"Margarita viva rosado fuerte"},
    {prodId:"10213905",label:"Margarita viva rosado claro"},
    {prodId:"10213906",label:"Margarita viva magenta"},
    {prodId:"10213907",label:"Margarita viva mini coral"},
    {prodId:"10213908",label:"Margarita viva mini fucsia"},
    {prodId:"10213909",label:"Margarita viva mini rosado fuerte"},
    {prodId:"10213910",label:"Margarita viva mini anaranjado claro de centro negro"},
    {prodId:"10213911",label:"Margarita viva mini anaranjado de centro negro"},
    {prodId:"10213912",label:"Margarita viva mini anaranjado"},
    {prodId:"10213913",label:"Margarita viva mini rojo de centro negro"},
    {prodId:"10213914",label:"Margarita viva mini blanco"},
    {prodId:"10213915",label:"Margarita viva mini amarillo claro de centro negro"},
    {prodId:"10213916",label:"Margarita viva anaranjado de centro negro"},
    {prodId:"10213917",label:"Margarita viva anaranjada"},
    {prodId:"10213918",label:"Margarita viva durazno de centro negro"},
    {prodId:"10213919",label:"Margarita viva durazno"},
    {prodId:"10213920",label:"Margarita viva rosada de centro negro"},
    {prodId:"10213921",label:"Margarita viva rosada"},
    {prodId:"10213922",label:"Margarita viva roja de centro negro"},
    {prodId:"10213923",label:"Margarita viva roja"},
    {prodId:"10213924",label:"Margarita viva spider durazno"},
    {prodId:"10213925",label:"Margarita viva spider roja"},
    {prodId:"10213926",label:"Margarita viva terracota"},
    {prodId:"10213927",label:"Margarita viva blanca de centro negro"},
    {prodId:"10213928",label:"Margarita viva blanca"},
    {prodId:"10213929",label:"Margarita viva amarilla"},
    {prodId:"10214100",label:"Gladiolo vivo"},
    {prodId:"10214101",label:"Gladiolo vivo vinotinto"},
    {prodId:"10214102",label:"Gladiolo vivo fucsia"},
    {prodId:"10214103",label:"Gladiolo vivo verde"},
    {prodId:"10214104",label:"Gladiolo vivo rosado fuerte"},
    {prodId:"10214105",label:"Gladiolo vivo rosado claro"},
    {prodId:"10214106",label:"Gladiolo vivo anaranjado"},
    {prodId:"10214107",label:"Gladiolo vivo durazno"},
    {prodId:"10214108",label:"Gladiolo vivo rosado medio"},
    {prodId:"10214109",label:"Gladiolo vivo púrpura"},
    {prodId:"10214110",label:"Gladiolo vivo rojo bicolor"},
    {prodId:"10214111",label:"Gladiolo vivo rojo"},
    {prodId:"10214112",label:"Gladiolo vivo salmón"},
    {prodId:"10214113",label:"Gladiolo vivo blanco"},
    {prodId:"10214114",label:"Gladiolo vivo amarillo"},
    {prodId:"10214200",label:"Godetia viva"},
    {prodId:"10214201",label:"Godetia viva bicolor"},
    {prodId:"10214202",label:"Godetia viva fucsia"},
    {prodId:"10214203",label:"Godetia viva rosado fuerte"},
    {prodId:"10214204",label:"Godetia viva anaranjada"},
    {prodId:"10214205",label:"Godetia viva roja"},
    {prodId:"10214206",label:"Godetia viva blanca"},
    {prodId:"10214300",label:"Bromelia viva"},
    {prodId:"10214301",label:"Bromelia lingulata viva anaranjada"},
    {prodId:"10214302",label:"Bromelia lingulata viva roja"},
    {prodId:"10214303",label:"Bromelia lingulata viva blanca"},
    {prodId:"10214304",label:"Bromelia lingulata viva amarilla"},
    {prodId:"10214305",label:"Bromelia variegata viva"},
    {prodId:"10214400",label:"Gypsophilia viva"},
    {prodId:"10214401",label:"Gypsophilia viva bambino"},
    {prodId:"10214402",label:"Gypsophilia viva million stars"},
    {prodId:"10214403",label:"Gypsophilia viva mirabella"},
    {prodId:"10214404",label:"Gypsophilia viva new love"},
    {prodId:"10214405",label:"Gypsophilia viva orión"},
    {prodId:"10214406",label:"Gypsophilia viva perfecta"},
    {prodId:"10214500",label:"Brezo vivo"},
    {prodId:"10214501",label:"Brezo vivo augustine"},
    {prodId:"10214502",label:"Brezo vivo érica cuatro hermanas"},
    {prodId:"10214503",label:"Brezo vivo francés"},
    {prodId:"10214504",label:"Brezo vivo verde"},
    {prodId:"10214505",label:"Brezo sterling range blanco vivo"},
    {prodId:"10214506",label:"Brezo vivo rosado sunset"},
    {prodId:"10214507",label:"Brezo vivo blanco"},
    {prodId:"10214600",label:"Heliconia viva"},
    {prodId:"10214601",label:"Heliconia viva bihai claw"},
    {prodId:"10214602",label:"Heliconia viva bihai flash"},
    {prodId:"10214603",label:"Heliconia viva bihai lobster claw"},
    {prodId:"10214604",label:"Heliconia viva caribea red"},
    {prodId:"10214605",label:"Heliconia viva caribea yellow"},
    {prodId:"10214606",label:"Heliconia viva christmas"},
    {prodId:"10214607",label:"Heliconia viva edge of night"},
    {prodId:"10214608",label:"Heliconia viva green bihai"},
    {prodId:"10214609",label:"Heliconia viva marginata lutea"},
    {prodId:"10214610",label:"Heliconia viva psitt fire opal"},
    {prodId:"10214611",label:"Heliconia viva psittacorum"},
    {prodId:"10214612",label:"Heliconia viva richmond red"},
    {prodId:"10214613",label:"Heliconia viva rostrata"},
    {prodId:"10214614",label:"Heliconia viva sexy pink"},
    {prodId:"10214615",label:"Heliconia viva sexy scarlett"},
    {prodId:"10214616",label:"Heliconia viva shogun"},
    {prodId:"10214617",label:"Heliconia viva small red"},
    {prodId:"10214618",label:"Heliconia viva southern cross"},
    {prodId:"10214619",label:"Heliconia viva wagneriana"},
    {prodId:"10214705",label:"Jacinto vivo rosado fuerte"},
    {prodId:"10214709",label:"Jacinto vivo rosado"},
    {prodId:"10214706",label:"Jacinto vivo lavanda"},
    {prodId:"10214704",label:"Jacinto vivo fucsia"},
    {prodId:"10214710",label:"Jacinto vivo estrella púrpura"},
    {prodId:"10214702",label:"Jacinto vivo durazno"},
    {prodId:"10214711",label:"Jacinto vivo blanco"},
    {prodId:"10214701",label:"Jacinto vivo bean"},
    {prodId:"10214707",label:"Jacinto vivo azul claro"},
    {prodId:"10214703",label:"Jacinto vivo azul"},
    {prodId:"10214712",label:"Jacinto vivo amarillo"},
    {prodId:"10214700",label:"Jacinto vivo"},
    {prodId:"10414708",label:"Jacinto cortado seco rosado medio"},
    {prodId:"10214800",label:"Hydrangea viva"},
    {prodId:"10214801",label:"Hydrangea viva anabella"},
    {prodId:"10214802",label:"Hydrangea viva azul antiguo"},
    {prodId:"10214803",label:"Hydrangea viva azul antiguo  o verde o nueva zelandia"},
    {prodId:"10214804",label:"Hydrangea viva verde antiguo"},
    {prodId:"10214805",label:"Hydrangea viva rosado antiguo"},
    {prodId:"10214806",label:"Hydrangea viva púrpura antiguo o nueva zelandia"},
    {prodId:"10214807",label:"Hydrangea viva aubergene o nueva zelandia"},
    {prodId:"10214808",label:"Hydrangea viva azul oscuro"},
    {prodId:"10214809",label:"Hydrangea viva rosado oscuro"},
    {prodId:"10214810",label:"Hydrangea viva púrpura oscuro"},
    {prodId:"10214811",label:"Hydrangea viva berenjena"},
    {prodId:"10214812",label:"Hydrangea viva verde teñida"},
    {prodId:"10214813",label:"Hydrangea viva limón verde"},
    {prodId:"10214814",label:"Hydrangea viva rosado fuerte"},
    {prodId:"10214815",label:"Hydrangea viva jumbo blanca"},
    {prodId:"10214816",label:"Hydrangea viva lavanda o nueva zelandia"},
    {prodId:"10214817",label:"Hydrangea viva azul claro"},
    {prodId:"10214818",label:"Hydrangea viva rosado claro grande"},
    {prodId:"10214819",label:"Hydrangea viva verde lima grande"},
    {prodId:"10214820",label:"Hydrangea viva mini verde"},
    {prodId:"10214821",label:"Hydrangea viva oakleaf"},
    {prodId:"10214822",label:"Hydrangea viva oakleaf copo de nieve"},
    {prodId:"10214823",label:"Hydrangea viva rosado teñido"},
    {prodId:"10214824",label:"Hydrangea viva rosado"},
    {prodId:"10214825",label:"Hydrangea viva púrpura o nueva zelandia"},
    {prodId:"10214826",label:"Hydrangea viva rojo teñido"},
    {prodId:"10214827",label:"Hydrangea viva shocking blue"},
    {prodId:"10214828",label:"Hydrangea viva tardiva"},
    {prodId:"10214829",label:"Hydrangea viva blanca"},
    {prodId:"10214900",label:"Iris Viva"},
    {prodId:"10214901",label:"Iris viva negra barbada"},
    {prodId:"10214902",label:"Iris viva azul barbada"},
    {prodId:"10214903",label:"Iris viva lavanda barbada"},
    {prodId:"10214904",label:"Iris viva barbada azul clara"},
    {prodId:"10214905",label:"Iris viva barbada púrpura"},
    {prodId:"10214906",label:"Iris viva barbada roja"},
    {prodId:"10214907",label:"Iris viva barbada blanca"},
    {prodId:"10214908",label:"Iris viva baerbada blanca y púrpura"},
    {prodId:"10214909",label:"Iris viva amarilla barbada"},
    {prodId:"10214910",label:"Iris viva blue elegance"},
    {prodId:"10214911",label:"Iris viva casablanca"},
    {prodId:"10214912",label:"Iris viva golden beau"},
    {prodId:"10214913",label:"Iris viva hildegard"},
    {prodId:"10214914",label:"Iris viva hong kong"},
    {prodId:"10214915",label:"Iris viva ideal"},
    {prodId:"10214916",label:"Iris viva professor blue"},
    {prodId:"10214917",label:"Iris viva purple"},
    {prodId:"10214918",label:"Iris viva spuria"},
    {prodId:"10214919",label:"Iris viva telstar"},
    {prodId:"10215000",label:"Pata de canguro viva"},
    {prodId:"10215001",label:"Pata de canguro viva bicolor"},
    {prodId:"10215002",label:"Pata de canguro viva negra"},
    {prodId:"10215003",label:"Pata de canguro viva verde"},
    {prodId:"10215004",label:"Pata de canguro viva anaranjada"},
    {prodId:"10215005",label:"Pata de canguro viva rosada"},
    {prodId:"10215006",label:"Pata de canguro viva roja"},
    {prodId:"10215007",label:"Pata de canguro viva amarilla"},
    {prodId:"10215100",label:"Delfinio vivo"},
    {prodId:"10215101",label:"Delfinio viva azul nube"},
    {prodId:"10215102",label:"Delfinio viva rosado fuerte"},
    {prodId:"10215103",label:"Delfinio viva lavanda"},
    {prodId:"10215104",label:"Delfinio viva rosado claro"},
    {prodId:"10215105",label:"Delfinio viva púrpura"},
    {prodId:"10215106",label:"Delfinio viva blanca"},
    {prodId:"10215200",label:"Lepto vivo"},
    {prodId:"10215201",label:"Lepto vivo azul o floreciente"},
    {prodId:"10215202",label:"Lepto vivo rosado fuerte"},
    {prodId:"10215203",label:"Lepto vivo rosado claro"},
    {prodId:"10215204",label:"Lepto vivo rosado"},
    {prodId:"10215205",label:"Lepto vivo rojo"},
    {prodId:"10215206",label:"Lepto vivo blanco"},
    {prodId:"10215300",label:"Lila viva"},
    {prodId:"10215301",label:"Lila  viva híbrida francesa lavanda"},
    {prodId:"10215302",label:"Lila  viva híbrida francesa púrpura"},
    {prodId:"10215303",label:"Lila  viva púrpura"},
    {prodId:"10215304",label:"Lila  viva vino"},
    {prodId:"10215305",label:"Lila  viva blanca"},
    {prodId:"10215306",label:"Lila  viva blanca salvaje"},
    {prodId:"10215500",label:"Lavanda viva"},
    {prodId:"10215501",label:"Lavanda marina viva misty peach"},
    {prodId:"10215502",label:"Lavanda marina viva misty rosada"},
    {prodId:"10215503",label:"Lavanda marina viva misty blanca"},
    {prodId:"10215504",label:"Lavanda marina viva misty amarilla"},
    {prodId:"10215505",label:"Lavanda marina viva safora"},
    {prodId:"10215506",label:"Lavanda marina viva sinensis"},
    {prodId:"10215700",label:"Muscari viva"},
    {prodId:"10215701",label:"Muscari viva armeniacum"},
    {prodId:"10215702",label:"Muscari viva bortyoides blanca"},
    {prodId:"10215703",label:"Muscari viva verde"},
    {prodId:"10215704",label:"Muscari viva latifolia"},
    {prodId:"10215705",label:"Muscari viva valerie finn"},
    {prodId:"10215800",label:"Narciso vivo"},
    {prodId:"10215801",label:"Narciso vivo alegría"},
    {prodId:"10215802",label:"Narciso vivo amanecer dorado"},
    {prodId:"10215803",label:"Narciso vivo abba paperwhite"},
    {prodId:"10215804",label:"Narciso vivo paperwhite"},
    {prodId:"10215805",label:"Narciso vivo ojo de faisán"},
    {prodId:"10215806",label:"Narciso vivo soleil d' or"},
    {prodId:"10215807",label:"Narciso vivo tete a tete"},
    {prodId:"10215808",label:"Narciso vivo thalia"},
    {prodId:"10216000",label:"Pimientos ornamentales vivos"},
    {prodId:"10216001",label:"Pimiento chile vivo ornamental"},
    {prodId:"10216002",label:"Pimiento mezclado vivo ornamental"},
    {prodId:"10216003",label:"Pimiento anaranjado vivo ornamental"},
    {prodId:"10216004",label:"Pimiento rojo vivo ornamental"},
    {prodId:"10216005",label:"Pimiento amarillo vivo ornamental"},
    {prodId:"10216200",label:"Peonia viva"},
    {prodId:"10216201",label:"Peonia viva alexander fleming"},
    {prodId:"10216202",label:"Peonia viva coral charm"},
    {prodId:"10216203",label:"Peonia viva suset"},
    {prodId:"10216204",label:"Peonia viva coral supreme"},
    {prodId:"10216205",label:"Peonia viva gardenia doble"},
    {prodId:"10216206",label:"Peonia viva doble jues eli oscura"},
    {prodId:"10216207",label:"Peonia viva soble duquesa blanca"},
    {prodId:"10216208",label:"Peonia viva felix crousse"},
    {prodId:"10216209",label:"Peonia viva festiva máxima"},
    {prodId:"10216210",label:"Peonia viva tesoro del jardín"},
    {prodId:"10216211",label:"Peonia viva kansas rosado oscuro"},
    {prodId:"10216212",label:"Peonia viva karl rosenfelt"},
    {prodId:"10216213",label:"Peonia viva paula fay"},
    {prodId:"10216214",label:"Peonia viva encanto rojo"},
    {prodId:"10216215",label:"Peonia viva pasion roja"},
    {prodId:"10216216",label:"Peonia viva sarah bernhardt roja"},
    {prodId:"10216217",label:"Peonia viva scarlet o' hara"},
    {prodId:"10216218",label:"Peonia viva shirley temple"},
    {prodId:"10216300",label:"Banksia viva"},
    {prodId:"10216301",label:"Banksia viva ashbyi"},
    {prodId:"10216302",label:"Banksia viva baxteri"},
    {prodId:"10216306",label:"Banksia viva coccinea"},
    {prodId:"10216311",label:"Banksia viva ericifolia"},
    {prodId:"10216315",label:"Banksia viva verde"},
    {prodId:"10216322",label:"Banksia viva menziesii"},
    {prodId:"10216325",label:"Banksia viva blanco natural"},
    {prodId:"10216326",label:"Banksia viva anaranjado"},
    {prodId:"10216332",label:"Banksia viva rosada"},
    {prodId:"10216400",label:"Ranúculo vivo"},
    {prodId:"10216401",label:"Ranúnculo vivo chocolate"},
    {prodId:"10216402",label:"Ranúnculo vivo elegancia"},
    {prodId:"10216403",label:"Ranúnculo vivo verde"},
    {prodId:"10216404",label:"Ranúnculo vivo grimaldi"},
    {prodId:"10216405",label:"Ranúnculo vivo rosado fuerte"},
    {prodId:"10216406",label:"Ranúnculo vivo rosado claro"},
    {prodId:"10216407",label:"Ranúnculo vivo anaranjado"},
    {prodId:"10216408",label:"Ranúnculo vivo centro verde"},
    {prodId:"10216409",label:"Ranúnculo vivo rosado"},
    {prodId:"10216410",label:"Ranúnculo vivo rojo"},
    {prodId:"10216411",label:"Ranúnculo vivo blanco"},
    {prodId:"10216412",label:"Ranúnculo vivo amarillo"},
    {prodId:"10216413",label:"Ranúnculo vivo salmón"},
    {prodId:"10216600",label:"Retama de escobas viva"},
    {prodId:"10216601",label:"Retama de escobas viva rosada"},
    {prodId:"10216602",label:"Retama de escobas viva púrpura"},
    {prodId:"10216603",label:"Retama de escobas viva blanca"},
    {prodId:"10216604",label:"Retama de escobas viva amarilla"},
    {prodId:"10216700",label:"Boca de dragón viva"},
    {prodId:"10216701",label:"Boca de dragón viva bicolor"},
    {prodId:"10216702",label:"Boca de dragón viva burgundy"},
    {prodId:"10216703",label:"Boca de dragón viva rosado fuerte"},
    {prodId:"10216704",label:"Boca de dragón viva lavanda"},
    {prodId:"10216705",label:"Boca de dragón viva anaranjado claro"},
    {prodId:"10216706",label:"Boca de dragón viva rosado claro"},
    {prodId:"10216707",label:"Boca de dragón viva anaranjado"},
    {prodId:"10216708",label:"Boca de dragón viva blanco"},
    {prodId:"10216709",label:"Boca de dragón viva amarillo"},
    {prodId:"10217000",label:"Girasol viva"},
    {prodId:"10217001",label:"Girasol viva tinte festivo"},
    {prodId:"10217002",label:"Girasol viva mahogany"},
    {prodId:"10217003",label:"Girasol viva rayo de sol"},
    {prodId:"10217004",label:"Girasol viva brillo del sol"},
    {prodId:"10217005",label:"Girasol viva salpicada de sol"},
    {prodId:"10217006",label:"Girasol viva oso de peluche"},
    {prodId:"10217100",label:"Guisante de olor vivo"},
    {prodId:"10217101",label:"Guisante de olor vivo verde teñido"},
    {prodId:"10217102",label:"Guisante de olor vivo rosado fuerte"},
    {prodId:"10217103",label:"Guisante de olor vivo lavanda"},
    {prodId:"10217104",label:"Guisante de olor vivo rosado claro"},
    {prodId:"10217105",label:"Guisante de olor vivo anaranjado"},
    {prodId:"10217106",label:"Guisante de olor vivo durazno teñido"},
    {prodId:"10217107",label:"Guisante de olor vivo púrpura"},
    {prodId:"10217108",label:"Guisante de olor vivo blanco"},
    {prodId:"10217200",label:"Cardo vivo"},
    {prodId:"10217201",label:"Cardo vivo alpinum"},
    {prodId:"10217202",label:"Cardo vivo echinops"},
    {prodId:"10217203",label:"Cardo vivo eryngium árabe"},
    {prodId:"10217204",label:"Cardo vivo eryngium azul"},
    {prodId:"10217205",label:"Cardo vivo eryngium orión"},
    {prodId:"10217206",label:"Cardo vivo eryngium frambuesa"},
    {prodId:"10217207",label:"Cardo vivo eryngium supernova"},
    {prodId:"10217208",label:"Cardo vivo eryngium campanita"},
    {prodId:"10217300",label:"Tulipán vivo"},
    {prodId:"10217301",label:"Tulipán vivo adrem"},
    {prodId:"10217302",label:"Tulipán vivo apricot"},
    {prodId:"10217303",label:"Tulipán vivo bicolor rojo y amarillo"},
    {prodId:"10217304",label:"Tulipán vivo doble bicolor"},
    {prodId:"10217305",label:"Tulipán vivo doble rosado"},
    {prodId:"10217306",label:"Tulipán vivo doble rojo"},
    {prodId:"10217307",label:"Tulipán vivo doble blanco"},
    {prodId:"10217308",label:"Tulipán vivo doble amarillo"},
    {prodId:"10217309",label:"Tulipán vivo francés avignon"},
    {prodId:"10217310",label:"Tulipán vivo francés camarque"},
    {prodId:"10217311",label:"Tulipán vivo francés dordogne"},
    {prodId:"10217312",label:"Tulipán vivo francés fiat"},
    {prodId:"10217313",label:"Tulipán vivo francés flamboyant"},
    {prodId:"10217314",label:"Tulipán vivo francés flaming parrot"},
    {prodId:"10217315",label:"Tulipán vivo francés florissa"},
    {prodId:"10217316",label:"Tulipán vivo francés doble maureen"},
    {prodId:"10217317",label:"Tulipán vivo francés maureen"},
    {prodId:"10217318",label:"Tulipán vivo francés menton"},
    {prodId:"10217319",label:"Tulipán vivo francés montpellier"},
    {prodId:"10217320",label:"Tulipán vivo francés naranja unique"},
    {prodId:"10217321",label:"Tulipán vivo francés peonia reconocido único"},
    {prodId:"10217322",label:"Tulipán vivo francés loro rosado"},
    {prodId:"10217323",label:"Tulipán vivo francés princesa unique"},
    {prodId:"10217324",label:"Tulipán vivo francés reconocido"},
    {prodId:"10217325",label:"Tulipán vivo francés schepppers"},
    {prodId:"10217326",label:"Tulipán vivo francés gamuza"},
    {prodId:"10217327",label:"Tulipán vivo francés toyota"},
    {prodId:"10217328",label:"Tulipán vivo francés loro weber"},
    {prodId:"10217329",label:"Tulipán vivo francés loro blanco"},
    {prodId:"10217330",label:"Tulipán vivo lavanda de borde crespo"},
    {prodId:"10217331",label:"Tulipán vivo rosado fuerte"},
    {prodId:"10217332",label:"Tulipán vivo rosado fuerte hoja variegada"},
    {prodId:"10217333",label:"Tulipán vivo lavanda"},
    {prodId:"10217334",label:"Tulipán vivo rosado claro hoja variegada"},
    {prodId:"10217335",label:"Tulipán vivo viuda alegre"},
    {prodId:"10217336",label:"Tulipán vivo anaranjado"},
    {prodId:"10217337",label:"Tulipán vivo loro negro"},
    {prodId:"10217338",label:"Tulipán vivo loro estela rijnveld"},
    {prodId:"10217339",label:"Tulipán vivo llameante"},
    {prodId:"10217340",label:"Tulipán vivo loro verde"},
    {prodId:"10217341",label:"Tulipán vivo loro lavanda"},
    {prodId:"10217342",label:"Tulipán vivo loro anaranjado"},
    {prodId:"10217343",label:"Tulipán vivo loro durazno"},
    {prodId:"10217344",label:"Tulipán vivo loro rosado"},
    {prodId:"10217345",label:"Tulipán vivo loro rojo"},
    {prodId:"10217346",label:"Tulipán vivo loro rojo rococó"},
    {prodId:"10217347",label:"Tulipán vivo loro weber"},
    {prodId:"10217348",label:"Tulipán vivo loro blanco"},
    {prodId:"10217349",label:"Tulipán vivo loro amarillo"},
    {prodId:"10217350",label:"Tulipán vivo rosado"},
    {prodId:"10217351",label:"Tulipán vivo púrpura"},
    {prodId:"10217352",label:"Tulipán vivo rojo"},
    {prodId:"10217353",label:"Tulipán vivo especias"},
    {prodId:"10217354",label:"Tulipán vivo blanco"},
    {prodId:"10217355",label:"Tulipán vivo amarillo"},
    {prodId:"10217500",label:"Hierba de aquiles viva"},
    {prodId:"10217501",label:"Hierba de aquiles viva vinotinto"},
    {prodId:"10217502",label:"Hierba de aquiles viva crema campesina"},
    {prodId:"10217503",label:"Hierba de aquiles viva rosado campesino"},
    {prodId:"10217504",label:"Hierba de aquiles viva luz de luna"},
    {prodId:"10217505",label:"Hierba de aquiles viva anaranjado"},
    {prodId:"10217506",label:"Hierba de aquiles viva durazno"},
    {prodId:"10217507",label:"Hierba de aquiles viva rosada"},
    {prodId:"10217508",label:"Hierba de aquiles viva rojo teñido"},
    {prodId:"10217509",label:"Hierba de aquiles viva blanca"},
    {prodId:"10217510",label:"Hierba de aquiles viva amarilla"},
    {prodId:"10217600",label:"Zinnia viva"},
    {prodId:"10217601",label:"Zinia viva amarilla"},
    {prodId:"10217602",label:"Zinia viva mini"},
    {prodId:"10217603",label:"Zinia viva rosado"},
    {prodId:"10217604",label:"Zinia viva rojo"},
    {prodId:"10217605",label:"Zinia viva salmón"},
    {prodId:"10217606",label:"Zinia viva amarillo"},
    {prodId:"10217700",label:"Forsythia viva"},
    {prodId:"10217701",label:"Forsythia viva viridissima"},
    {prodId:"10217702",label:"Forsythia viva giraldiana"},
    {prodId:"10217703",label:"Forsythia viva mira"},
    {prodId:"10217704",label:"Forsythia viva suspensa"},
    {prodId:"10217705",label:"Forsythia viva intermedia"},
    {prodId:"10217706",label:"Forsythia viva variabilis"},
    {prodId:"10217707",label:"Forsythia viva ovate"},
    {prodId:"10217708",label:"Forsythia viva intermedia lynwood"},
    {prodId:"10217800",label:"Geranio vivo"},
    {prodId:"10217801",label:"Geranio vivo argenteum"},
    {prodId:"10217802",label:"Geranio vivo cinereum"},
    {prodId:"10217803",label:"Geranio vivo clarkei"},
    {prodId:"10217804",label:"Geranio vivo dalmaticum"},
    {prodId:"10217805",label:"Geranio vivo endressii"},
    {prodId:"10217806",label:"Geranio vivo eriostemon"},
    {prodId:"10217807",label:"Geranio vivo farreri"},
    {prodId:"10217808",label:"Geranio vivo himalayense o grandiflora"},
    {prodId:"10217809",label:"Geranio vivo ibericum"},
    {prodId:"10217810",label:"Geranio vivo macrorhizum o raiz grande"},
    {prodId:"10217811",label:"Geranio vivo maculatum"},
    {prodId:"10217812",label:"Geranio vivo nodosum"},
    {prodId:"10217813",label:"Geranio vivo phaeum"},
    {prodId:"10217814",label:"Geranio vivo platypetalum"},
    {prodId:"10217815",label:"Geranio vivo pratense"},
    {prodId:"10217816",label:"Geranio vivo procurrens"},
    {prodId:"10217817",label:"Geranio vivo psilostemon"},
    {prodId:"10217818",label:"Geranio vivo pylzowianum"},
    {prodId:"10217819",label:"Geranio vivo renardii"},
    {prodId:"10217820",label:"Geranio vivo sanguineum o sangruento"},
    {prodId:"10217821",label:"Geranio vivo sylvaticum"},
    {prodId:"10217822",label:"Geranio vivo traversii"},
    {prodId:"10217823",label:"Geranio vivo tuberosum"},
    {prodId:"10217824",label:"Geranio vivo versicolor"},
    {prodId:"10217825",label:"Geranio vivo wallachianum"},
    {prodId:"10217826",label:"Geranio vivo wlassovianum"},
    {prodId:"10217827",label:"Geranio vivo x magnificum o llamativo"},
    {prodId:"10217900",label:"Amaryllis viva"},
    {prodId:"10217901",label:"Amaryllis viva aglaiae"},
    {prodId:"10217902",label:"Amaryllis viva amaru"},
    {prodId:"10217903",label:"Amaryllis viva angustifolium"},
    {prodId:"10217904",label:"Amaryllis viva anzaldoi"},
    {prodId:"10217905",label:"Amaryllis viva araripinum"},
    {prodId:"10217906",label:"Amaryllis viva arboricola"},
    {prodId:"10217907",label:"Amaryllis viva argentinum"},
    {prodId:"10217908",label:"Amaryllis viva aulicum"},
    {prodId:"10217909",label:"Amaryllis viva aviflorum"},
    {prodId:"10217910",label:"Amaryllis viva barreirasum"},
    {prodId:"10217911",label:"Amaryllis viva blossfeldiae"},
    {prodId:"10217912",label:"Amaryllis viva blumenavium"},
    {prodId:"10217913",label:"Amaryllis viva brasilianum"},
    {prodId:"10217914",label:"Amaryllis viva breviflorum"},
    {prodId:"10217915",label:"Amaryllis viva bukasovii"},
    {prodId:"10217916",label:"Amaryllis viva calyptratum"},
    {prodId:"10217917",label:"Amaryllis viva caupolicanense"},
    {prodId:"10217918",label:"Amaryllis viva chionedyanthum"},
    {prodId:"10217919",label:"Amaryllis viva condemaita"},
    {prodId:"10217920",label:"Amaryllis viva corriense"},
    {prodId:"10217921",label:"Amaryllis viva cuzcoense"},
    {prodId:"10217922",label:"Amaryllis viva curitibanum"},
    {prodId:"10217923",label:"Amaryllis viva cybister"},
    {prodId:"10217924",label:"Amaryllis viva divijuliani"},
    {prodId:"10217925",label:"Amaryllis viva evansiae"},
    {prodId:"10217926",label:"Amaryllis viva ferreyrae"},
    {prodId:"10217927",label:"Amaryllis viva forgetii"},
    {prodId:"10217928",label:"Amaryllis viva fosteri"},
    {prodId:"10217929",label:"Amaryllis viva fuscum"},
    {prodId:"10217930",label:"Amaryllis viva glaucescens"},
    {prodId:"10217931",label:"Amaryllis viva goianum"},
    {prodId:"10217932",label:"Amaryllis viva guarapuavicum"},
    {prodId:"10217933",label:"Amaryllis viva harrisonii"},
    {prodId:"10217934",label:"Amaryllis viva hugoi"},
    {prodId:"10217935",label:"Amaryllis viva iguazuanum"},
    {prodId:"10217936",label:"Amaryllis viva illustre"},
    {prodId:"10217937",label:"Amaryllis viva intiflorum"},
    {prodId:"10217938",label:"Amaryllis viva kromeri"},
    {prodId:"10217939",label:"Amaryllis viva lapacense"},
    {prodId:"10217940",label:"Amaryllis viva leonardii"},
    {prodId:"10217941",label:"Amaryllis viva leopoldii"},
    {prodId:"10217942",label:"Amaryllis viva macbridei"},
    {prodId:"10217943",label:"Amaryllis viva machupijchense"},
    {prodId:"10217944",label:"Amaryllis viva mandonii"},
    {prodId:"10217945",label:"Amaryllis viva minasgerais"},
    {prodId:"10217946",label:"Amaryllis viva miniatum"},
    {prodId:"10217947",label:"Amaryllis viva mollevillquense"},
    {prodId:"10217948",label:"Amaryllis viva morelianum"},
    {prodId:"10217949",label:"Amaryllis viva nelsonii"},
    {prodId:"10217950",label:"Amaryllis viva oconoquense"},
    {prodId:"10217951",label:"Amaryllis viva papilio"},
    {prodId:"10217952",label:"Amaryllis viva paquichanum"},
    {prodId:"10217953",label:"Amaryllis viva paradisiacum"},
    {prodId:"10217954",label:"Amaryllis viva pardinum"},
    {prodId:"10217955",label:"Amaryllis viva parodii"},
    {prodId:"10217956",label:"Amaryllis viva petiolatum"},
    {prodId:"10217957",label:"Amaryllis viva psittacinum"},
    {prodId:"10217958",label:"Amaryllis viva puniceum"},
    {prodId:"10217959",label:"Amaryllis viva reginae"},
    {prodId:"10217960",label:"Amaryllis viva reticulatum"},
    {prodId:"10217961",label:"Amaryllis viva rubropictum"},
    {prodId:"10217962",label:"Amaryllis viva santacatarina"},
    {prodId:"10217963",label:"Amaryllis viva solandraeflorum"},
    {prodId:"10217964",label:"Amaryllis viva starkiorum"},
    {prodId:"10217965",label:"Amaryllis viva striatum"},
    {prodId:"10217966",label:"Amaryllis viva stylosum"},
    {prodId:"10217967",label:"Amaryllis viva teyucuarense"},
    {prodId:"10217968",label:"Amaryllis viva traubii"},
    {prodId:"10217969",label:"Amaryllis viva vargasii"},
    {prodId:"10217970",label:"Amaryllis viva variegatum"},
    {prodId:"10217971",label:"Amaryllis viva vittatum"},
    {prodId:"10217972",label:"Amaryllis viva yungacense"},
    {prodId:"10218000",label:"Rubdeckia viva"},
    {prodId:"10218001",label:"Rubdeckia viva alpicola"},
    {prodId:"10218002",label:"Rubdeckia viva amplexicaulis"},
    {prodId:"10218003",label:"Rubdeckia viva auriculata"},
    {prodId:"10218004",label:"Rubdeckia viva bicolor"},
    {prodId:"10218005",label:"Rubdeckia viva californica"},
    {prodId:"10218006",label:"Rubdeckia viva fulgida"},
    {prodId:"10218007",label:"Rubdeckia viva glaucescens"},
    {prodId:"10218008",label:"Rubdeckia viva graminifolia"},
    {prodId:"10218009",label:"Rubdeckia viva grandiflora"},
    {prodId:"10218010",label:"Rubdeckia viva heliopsidis"},
    {prodId:"10218011",label:"Rubdeckia viva hirta"},
    {prodId:"10218012",label:"Rubdeckia viva klamathensis"},
    {prodId:"10218013",label:"Rubdeckia viva laciniata"},
    {prodId:"10218014",label:"Rubdeckia viva máxima"},
    {prodId:"10218015",label:"Rubdeckia viva missouriensis"},
    {prodId:"10218016",label:"Rubdeckia viva mohrii"},
    {prodId:"10218017",label:"Rubdeckia viva mollis"},
    {prodId:"10218018",label:"Rubdeckia viva montana"},
    {prodId:"10218019",label:"Rubdeckia viva nítida"},
    {prodId:"10218020",label:"Rubdeckia viva occidentalis"},
    {prodId:"10218021",label:"Rubdeckia viva pinnata"},
    {prodId:"10218022",label:"Rubdeckia viva scabrifolia"},
    {prodId:"10218023",label:"Rubdeckia viva serotina"},
    {prodId:"10218024",label:"Rubdeckia viva speciosa"},
    {prodId:"10218025",label:"Rubdeckia viva subtomentosa"},
    {prodId:"10218026",label:"Rubdeckia viva texana"},
    {prodId:"10218027",label:"Rubdeckia viva triloba"},
    {prodId:"10218100",label:"Protea viva"},
    {prodId:"10218101",label:"Protea viva bouquet"},
    {prodId:"10218102",label:"Protea viva cepillo botella"},
    {prodId:"10218103",label:"Protea viva carnaval"},
    {prodId:"10218104",label:"Protea viva follaje cordata"},
    {prodId:"10218105",label:"Protea viva grandiceps"},
    {prodId:"10218106",label:"Protea viva visón verde"},
    {prodId:"10218107",label:"Protea viva hiedra"},
    {prodId:"10218108",label:"Protea viva rey"},
    {prodId:"10218109",label:"Protea viva nana cones"},
    {prodId:"10218110",label:"Protea viva alfiletero anaranjada"},
    {prodId:"10218111",label:"Protea viva alfiletero tango"},
    {prodId:"10218112",label:"Protea viva alfiletero amarillo"},
    {prodId:"10218113",label:"Protea viva hielo rosado"},
    {prodId:"10218114",label:"Protea viva visón rosado"},
    {prodId:"10218115",label:"Protea viva reina"},
    {prodId:"10218116",label:"Protea viva repens"},
    {prodId:"10218117",label:"Protea viva cuchara de rosas"},
    {prodId:"10218118",label:"Protea viva silvia"},
    {prodId:"10218119",label:"Protea viva sugarbush"},
    {prodId:"10218120",label:"Protea viva susara"},
    {prodId:"10218121",label:"Protea viva waratha"},
    {prodId:"10218122",label:"Protea viva visón blanco"},
    {prodId:"10218200",label:"leucadendron vivo"},
    {prodId:"10218201",label:"Leucadendron vivo argenteum"},
    {prodId:"10218202",label:"Leucadendron vivo delicia de crema"},
    {prodId:"10218203",label:"Leucadendron vivo cumosum"},
    {prodId:"10218204",label:"Leucadendron vivo discolor"},
    {prodId:"10218205",label:"Leucadendron vivo galpini"},
    {prodId:"10218206",label:"Leucadendron vivo mina de oro"},
    {prodId:"10218207",label:"Leucadendron vivo oro inca"},
    {prodId:"10218208",label:"Leucadendron vivo bufón"},
    {prodId:"10218209",label:"Leucadendron vivo laxum"},
    {prodId:"10218210",label:"Leucadendron vivo mini"},
    {prodId:"10218211",label:"Leucadendron vivo oro patea"},
    {prodId:"10218212",label:"Leucadendron vivo petra"},
    {prodId:"10218213",label:"Leucadendron vivo plumosum"},
    {prodId:"10218214",label:"Leucadendron vivo roseta"},
    {prodId:"10218215",label:"Leucadendron vivo atardecer safari"},
    {prodId:"10218216",label:"Leucadendron vivo atardecer safari spr"},
    {prodId:"10218217",label:"Leucadendron vivo speciosa"},
    {prodId:"10218218",label:"Leucadendron vivo spray"},
    {prodId:"10218219",label:"Leucadendron vivo maravilla wilson"},
    {prodId:"10218220",label:"Leucadendron vivo yarden"},
    {prodId:"10218300",label:"leucospermum vivo"},
    {prodId:"10218301",label:"Leucospermum vivo album"},
    {prodId:"10218302",label:"Leucospermum vivo attenuatum"},
    {prodId:"10218303",label:"Leucospermum vivo calligerum"},
    {prodId:"10218304",label:"Leucospermum vivo conocarpodendron"},
    {prodId:"10218305",label:"Leucospermum vivo cordatum"},
    {prodId:"10218306",label:"Leucospermum vivo cuneiforme"},
    {prodId:"10218307",label:"Leucospermum vivo formosum"},
    {prodId:"10218308",label:"Leucospermum vivo glabrum"},
    {prodId:"10218309",label:"Leucospermum vivo grandiflorum"},
    {prodId:"10218310",label:"Leucospermum vivo harmatum"},
    {prodId:"10218311",label:"Leucospermum vivo heterophyllum"},
    {prodId:"10218312",label:"Leucospermum vivo innovans"},
    {prodId:"10218313",label:"Leucospermum vivo muirii"},
    {prodId:"10218314",label:"Leucospermum vivo oleifolium"},
    {prodId:"10218315",label:"Leucospermum vivo patersonii"},
    {prodId:"10218316",label:"Leucospermum vivo pluridens"},
    {prodId:"10218317",label:"Leucospermum vivo praemorsum"},
    {prodId:"10218318",label:"Leucospermum vivo prostratum"},
    {prodId:"10218319",label:"Leucospermum vivo rodolentum"},
    {prodId:"10218320",label:"Leucospermum vivo saxatile"},
    {prodId:"10218321",label:"Leucospermum vivo secundifolium"},
    {prodId:"10218322",label:"Leucospermum vivo tomentosus"},
    {prodId:"10218323",label:"Leucospermum vivo truncatulum"},
    {prodId:"10218324",label:"Leucospermum vivo utriculosum"},
    {prodId:"10218325",label:"Leucospermum vivo winterii"},
    {prodId:"10218326",label:"Leucospermum vivo arenarium"},
    {prodId:"10218327",label:"Leucospermum vivo bolusii"},
    {prodId:"10218328",label:"Leucospermum vivo catherinae"},
    {prodId:"10218329",label:"Leucospermum vivo conocarpum"},
    {prodId:"10218330",label:"Leucospermum vivo cordifolium"},
    {prodId:"10218331",label:"Leucospermum vivo erubescens"},
    {prodId:"10218332",label:"Leucospermum vivo gerrardii"},
    {prodId:"10218333",label:"Leucospermum vivo gracile"},
    {prodId:"10218334",label:"Leucospermum vivo gueinzii"},
    {prodId:"10218335",label:"Leucospermum vivo harpagonatum"},
    {prodId:"10218336",label:"Leucospermum vivo hypophyllocarpodendron"},
    {prodId:"10218337",label:"Leucospermum vivo lineare"},
    {prodId:"10218338",label:"Leucospermum vivo mundii"},
    {prodId:"10218339",label:"Leucospermum vivo parile"},
    {prodId:"10218340",label:"Leucospermum vivo pendunculatum"},
    {prodId:"10218341",label:"Leucospermum vivo praecox"},
    {prodId:"10218342",label:"Leucospermum vivo profugum"},
    {prodId:"10218343",label:"Leucospermum vivo reflexum"},
    {prodId:"10218344",label:"Leucospermum vivo royenifolium"},
    {prodId:"10218345",label:"Leucospermum vivo saxosum"},
    {prodId:"10218346",label:"Leucospermum vivo spathulatum"},
    {prodId:"10218347",label:"Leucospermum vivo tottum"},
    {prodId:"10218348",label:"Leucospermum vivo truncatum"},
    {prodId:"10218349",label:"Leucospermum vivo vestitum"},
    {prodId:"10218350",label:"Leucospermum vivo wittebergense"},
    {prodId:"10221600",label:"Alchimilla viva"},
    {prodId:"10221601",label:"Alchimilla viva capa de dama"},
    {prodId:"10221602",label:"Alchimilla viva robustica"},
    {prodId:"10221700",label:"Astilbe vivo"},
    {prodId:"10221701",label:"Astilbe vivo rosado fuerte"},
    {prodId:"10221702",label:"Astilbe vivo rosado claro"},
    {prodId:"10221703",label:"Astilbe vivo rojo"},
    {prodId:"10221704",label:"Astilbe vivo blanco"},
    {prodId:"10221800",label:"angélica viva"},
    {prodId:"10221801",label:"Angélica viva gigas"},
    {prodId:"10221802",label:"Angélica viva sylvestris"},
    {prodId:"10221900",label:"ajenjo vivo"},
    {prodId:"10221901",label:"Ajenjo vivo verde"},
    {prodId:"10221902",label:"Ajenjo vivo rey plata"},
    {prodId:"10222000",label:"Flor de alcachofa viva"},
    {prodId:"10222001",label:"Flor de alcachofa viva chocolate"},
    {prodId:"10222002",label:"Flor de alcachofa viva verde"},
    {prodId:"10222003",label:"Flor de alcachofa viva púrpura o floreciente"},
    {prodId:"10222100",label:"Astrantia viva"},
    {prodId:"10222101",label:"Astrantia viva rosada"},
    {prodId:"10222102",label:"Astrantia viva blanca"},
    {prodId:"10222200",label:"Flor de banano viva"},
    {prodId:"10222201",label:"Flor de banano viva anaranjada"},
    {prodId:"10222202",label:"Flor de banano viva antorcha anaranjada"},
    {prodId:"10222203",label:"Flor de banano viva púrpura"},
    {prodId:"10222300",label:"Baptisia viva"},
    {prodId:"10222301",label:"Baptisia viva australis"},
    {prodId:"10222302",label:"Baptisia viva sphaerocarpa"},
    {prodId:"10222400",label:"Boronia viva"},
    {prodId:"10222401",label:"Boronia viva rosada"},
    {prodId:"10222402",label:"Boronia viva amarilla"},
    {prodId:"10222500",label:"Bromelia viva"},
    {prodId:"10222501",label:"Bromelia viva amarilla reg"},
    {prodId:"10222502",label:"Bromelia viva roja reg"},
    {prodId:"10222600",label:"Brunia viva"},
    {prodId:"10222601",label:"Brunia viva albiflora"},
    {prodId:"10222602",label:"Brunia viva albiflora verde"},
    {prodId:"10222603",label:"Brunia viva rocío de plata"},
    {prodId:"10222800",label:"Calcynia viva"},
    {prodId:"10222801",label:"Calcynia viva rosada"},
    {prodId:"10222802",label:"Calcynia viva princesa"},
    {prodId:"10222803",label:"Calcynia viva blanca"},
    {prodId:"10222900",label:"Caléndula viva"},
    {prodId:"10222901",label:"Caléndula viva anaranjada"},
    {prodId:"10222902",label:"Caléndula viva amarilla"},
    {prodId:"10223000",label:"Campanilla viva"},
    {prodId:"10223001",label:"Campanilla viva azul"},
    {prodId:"10223002",label:"Campanilla viva rosada"},
    {prodId:"10223003",label:"Campanilla viva blanca"},
    {prodId:"10223100",label:"Cestrum vivo"},
    {prodId:"10223101",label:"Cestrum vivo rojo"},
    {prodId:"10223102",label:"Cestrum vivo rojo zohar"},
    {prodId:"10223103",label:"Cestrum vivo amarillo"},
    {prodId:"10223200",label:"Chasmante viva"},
    {prodId:"10223201",label:"Chasmante floribunda viva amarilla"},
    {prodId:"10223202",label:"Chasmante floribunda viva anaranjada"},
    {prodId:"10223300",label:"Costus viva"},
    {prodId:"10223301",label:"Costus barbada viva"},
    {prodId:"10223302",label:"Costus viva cabeza de indio"},
    {prodId:"10223400",label:"Vara de San José viva"},
    {prodId:"10223401",label:"Vara de san josé lucifer viva"},
    {prodId:"10223402",label:"Vara de san josé viva vainas"},
    {prodId:"10223403",label:"Vara de san josé viva amarilla"},
    {prodId:"10223500",label:"Lirio del Fuego vivo"},
    {prodId:"10223501",label:"Lirio del fuego vivo anaranjado brillante"},
    {prodId:"10223502",label:"Lirio del fuego vivo crema"},
    {prodId:"10223503",label:"Lirio del fuego vivo anaranjado"},
    {prodId:"10223504",label:"Lirio del fuego vivo amarillo"},
    {prodId:"10223600",label:"Flor del muelle viva"},
    {prodId:"10223601",label:"Flor del muelle viva verde"},
    {prodId:"10223602",label:"Flor del muelle viva roja"},
    {prodId:"10223700",label:"Planta erizo viva"},
    {prodId:"10223701",label:"Planta erizo viva tintura negra"},
    {prodId:"10223702",label:"Planta erizo viva tintura anaranjada"},
    {prodId:"10223900",label:"Nomeolvides viva"},
    {prodId:"10223901",label:"Nomeolvides viva azul"},
    {prodId:"10223902",label:"Nomeolvides viva blanca"},
    {prodId:"10224000",label:"Gallardia viva"},
    {prodId:"10224001",label:"Gallardia viva anaranjada"},
    {prodId:"10224002",label:"Gallardia viva amarilla"},
    {prodId:"10224100",label:"Genciana viva"},
    {prodId:"10224101",label:"Genciana viva azul"},
    {prodId:"10224102",label:"Genciana viva blanca"},
    {prodId:"10224200",label:"Gladiolo vivo"},
    {prodId:"10224201",label:"Gladiolo vivo glamini rosado"},
    {prodId:"10224202",label:"Gladiolo vivo glamini rojo"},
    {prodId:"10224300",label:"Gloriosa viva"},
    {prodId:"10224301",label:"Gloriosa viva anaranjada"},
    {prodId:"10224302",label:"Gloriosa viva roja"},
    {prodId:"10224303",label:"Gloriosa viva amarilla"},
    {prodId:"10224400",label:"Violeta silvestre viva"},
    {prodId:"10224401",label:"Violeta silvestre viva anaranjada"},
    {prodId:"10224402",label:"Violeta silvestre viva roja"},
    {prodId:"10224500",label:"Eléboro vivo"},
    {prodId:"10224501",label:"Eléboro vivo verde"},
    {prodId:"10224502",label:"Eléboro vivo rayo de luna"},
    {prodId:"10224600",label:"Ixia viva"},
    {prodId:"10224601",label:"Ixia viva rosada"},
    {prodId:"10224602",label:"Ixia viva blanca"},
    {prodId:"10224700",label:"Liatrises vivas"},
    {prodId:"10224701",label:"Liatris viva púrpura"},
    {prodId:"10224702",label:"Liatris viva spray"},
    {prodId:"10224703",label:"Liatris viva blanca"},
    {prodId:"10224800",label:"Lysimachia viva"},
    {prodId:"10224801",label:"Lysimachia punctata viva"},
    {prodId:"10224802",label:"Lysimachia vulgaris viva"},
    {prodId:"10224803",label:"Lysimachia blanca viva"},
    {prodId:"10224804",label:"Lysimachia amarilla viva"},
    {prodId:"10225000",label:"Caléndula viva"},
    {prodId:"10225001",label:"Caléndula viva francesa"},
    {prodId:"10225002",label:"Caléndula viva verde"},
    {prodId:"10225003",label:"Caléndula (marigold) viva anaranjada"},
    {prodId:"10225004",label:"Caléndula (marigold) viva amarilla"},
    {prodId:"10225100",label:"Mimosa viva"},
    {prodId:"10225101",label:"Mimosa viva azul o púrpura"},
    {prodId:"10225102",label:"Mimosa dedo viva"},
    {prodId:"10225103",label:"Mimosa viva floribunda o italia"},
    {prodId:"10225104",label:"Mimosa viva mirandola"},
    {prodId:"10225200",label:"Nerina viva"},
    {prodId:"10225201",label:"Nerina viva rosada"},
    {prodId:"10225202",label:"Nerina samiensis blanca"},
    {prodId:"10225300",label:"Flor de nogal viva"},
    {prodId:"10225301",label:"Flor colgante de nogal blanco viva"},
    {prodId:"10225302",label:"Flor sin hojas de nogal blanco viva"},
    {prodId:"10225303",label:"Flor erecta de nogal blanco brasileño viva"},
    {prodId:"10225400",label:"Phlox viva"},
    {prodId:"10225401",label:"Phlox viva rosado oscur"},
    {prodId:"10225402",label:"Phlox viva lavanda"},
    {prodId:"10225403",label:"Phlox viva rosado claro"},
    {prodId:"10225404",label:"Phlox viva blanca"},
    {prodId:"10225500",label:"physostegia viva"},
    {prodId:"10225501",label:"Physostegia viva rosada"},
    {prodId:"10225502",label:"Physostegia viva vainas"},
    {prodId:"10225503",label:"Physostegia viva blanca"},
    {prodId:"10225600",label:"Saponaria viva"},
    {prodId:"10225601",label:"Saponaria viva rosada"},
    {prodId:"10225602",label:"Saponaria viva blanca"},
    {prodId:"10225700",label:"Sarracenias vivas"},
    {prodId:"10225701",label:"Sarracenia viva flava rugelii"},
    {prodId:"10225800",label:"Sicilia viva"},
    {prodId:"10225801",label:"Sicilia viva campanulata azul"},
    {prodId:"10225802",label:"Sicilia viva campanulata rosada"},
    {prodId:"10225803",label:"Sicilia viva campanulata blanca"},
    {prodId:"10225804",label:"Sicilia viva peruana"},
    {prodId:"10225900",label:"Sedum viva"},
    {prodId:"10225901",label:"Sedum viva marrón"},
    {prodId:"10225902",label:"Sedum viva verde"},
    {prodId:"10225903",label:"Sedum viva rosada"},
    {prodId:"10225904",label:"Sedum viva roja"},
    {prodId:"10226000",label:"Especies individuales o variedades de flores vivas"},
    {prodId:"10226001",label:"Agrsotema viva"},
    {prodId:"10226002",label:"Kniphophia o assegai poker viva"},
    {prodId:"10226003",label:"Bellis perennis viva"},
    {prodId:"10226004",label:"Campana de irlanda o molucella viva"},
    {prodId:"10226005",label:"Ave del paraíso viva"},
    {prodId:"10226006",label:"Novia sonrojada viva"},
    {prodId:"10226007",label:"Buddleia o arbusto mariposa viva"},
    {prodId:"10226008",label:"Bupleurum griffithii viva"},
    {prodId:"10226009",label:"California ginesta viva"},
    {prodId:"10226010",label:"Callicarpa púrpura viva"},
    {prodId:"10226011",label:"Campanilla blanca viva"},
    {prodId:"10226012",label:"Penacho dulce viva"},
    {prodId:"10226013",label:"Cariopteris viva"},
    {prodId:"10226014",label:"Centaurea o marco polo viva"},
    {prodId:"10226015",label:"Linterna china viva"},
    {prodId:"10226016",label:"Clematis recta purpurea viva"},
    {prodId:"10226017",label:"Cleome spinosa viva"},
    {prodId:"10226018",label:"Coreopsis viva"},
    {prodId:"10226019",label:"Farolitos de la virgen azul viva"},
    {prodId:"10226020",label:"Cosmos chocolate viva"},
    {prodId:"10226021",label:"Cotinus coggygria viva"},
    {prodId:"10226022",label:"Craspedia o billy bolas viva"},
    {prodId:"10226023",label:"Deutzia alta viva"},
    {prodId:"10226024",label:"Diosma viva"},
    {prodId:"10226025",label:"Echeveria suculenta chupahuevos viva"},
    {prodId:"10226026",label:"Echinacea purpurea viva"},
    {prodId:"10226027",label:"Edelweiss viva"},
    {prodId:"10226028",label:"Erythronium pagoda viva"},
    {prodId:"10226029",label:"Flor de eucalipto viva"},
    {prodId:"10226030",label:"Eucaris o lirio del amazonas vivo"},
    {prodId:"10226031",label:"Eucomis o lirio de piña vivo"},
    {prodId:"10226032",label:"Eupatorium maculatum vivo"},
    {prodId:"10226033",label:"Filipendula viva"},
    {prodId:"10226034",label:"Digitalis vivo"},
    {prodId:"10226035",label:"Gilia globo viva"},
    {prodId:"10226036",label:"Globularia de ojo azul viva"},
    {prodId:"10226037",label:"Washington hawthorne viva"},
    {prodId:"10226038",label:"Helenio viva"},
    {prodId:"10226039",label:"Helianto viva"},
    {prodId:"10226040",label:"Herperis matronalis viva"},
    {prodId:"10226041",label:"Houttuynia cordata chameleon viva"},
    {prodId:"10414705",label:"Jacinto cortado seco rosado fuerte"},
    {prodId:"10226043",label:"Maiz indio vivo"},
    {prodId:"10226044",label:"Jack en el púlpito vivo"},
    {prodId:"10226045",label:"Arbol del cielo japonés vivo"},
    {prodId:"10226046",label:"Enredadera de jazmin florecida viva"},
    {prodId:"10226047",label:"Jatropha curcas o pólvora viva"},
    {prodId:"10226048",label:"Knautia viva"},
    {prodId:"10226049",label:"Kochia sedifolia viva"},
    {prodId:"10226050",label:"Lachenalia romaud viva"},
    {prodId:"10226051",label:"Flor de oreja de oveja viva"},
    {prodId:"10226052",label:"Lavanda viva"},
    {prodId:"10226053",label:"Leucocoryne speciosa viva"},
    {prodId:"10226054",label:"Lythrum viva"},
    {prodId:"10226055",label:"Malva zebrina viva"},
    {prodId:"10226056",label:"Margarita blanca viva"},
    {prodId:"10226057",label:"Montbretia amarilla viva"},
    {prodId:"10226058",label:"Nebelia viva"},
    {prodId:"10226059",label:"Nicotiana viva verde"},
    {prodId:"10226060",label:"Nigella damascena o amor en la niebla viva"},
    {prodId:"10226061",label:"Nigella viva"},
    {prodId:"10226062",label:"Orquídea de monja viva"},
    {prodId:"10226063",label:"Orquídea verde paphiopedilum"},
    {prodId:"10226064",label:"Paranomus viva"},
    {prodId:"10226065",label:"Penstemon husker rojo vivo"},
    {prodId:"10226066",label:"Manzana peruana viva"},
    {prodId:"10226067",label:"Phlomis sarnia viva"},
    {prodId:"10226068",label:"Didiscus o flor de encaje rosada viva"},
    {prodId:"10226069",label:"Platycodon o flor globo viva"},
    {prodId:"10226070",label:"Retzia capensis viva"},
    {prodId:"10226071",label:"Ricino común vivo"},
    {prodId:"10226072",label:"Nieve en el monte viva"},
    {prodId:"10226073",label:"Solidago teñida viva"},
    {prodId:"10226074",label:"Scilla blanca viva"},
    {prodId:"10226075",label:"Stachys byzantina viva"},
    {prodId:"10226076",label:"Flor de paja viva"},
    {prodId:"10226077",label:"Oscularia suculenta viva"},
    {prodId:"10226078",label:"Flor de tillasandia viva"},
    {prodId:"10226079",label:"Triteleia viva"},
    {prodId:"10226080",label:"Tritoma naranja o chuzo caliente viva roja"},
    {prodId:"10226081",label:"Veronicastrum virginiana viva"},
    {prodId:"10226082",label:"Bromelia vriesea splendens viva"},
    {prodId:"10226084",label:"Hipericim o hierba de san juan viva"},
    {prodId:"10226085",label:"Spirea viva"},
    {prodId:"10226086",label:"Yerba de san bonifacio viva"},
    {prodId:"10226200",label:"Tanaceto vivo"},
    {prodId:"10226201",label:"Tanaceto vivo amazonas"},
    {prodId:"10226202",label:"Tanaceto vivo victoria doble blanco"},
    {prodId:"10226203",label:"Tanaceto vivo victoria sencillo blanco"},
    {prodId:"10226204",label:"Tanaceto vivo vegmo amarillo"},
    {prodId:"10226300",label:"Traquelio vivo"},
    {prodId:"10226301",label:"Trachelium jade viva"},
    {prodId:"10226302",label:"Trachelium púrpura viva"},
    {prodId:"10226303",label:"Trachelium blanca viva"},
    {prodId:"10226400",label:"Tuberosas vivas"},
    {prodId:"10226401",label:"Tuberosa viva doble"},
    {prodId:"10226402",label:"Tuberosa viva sencilla"},
    {prodId:"10226500",label:"Tweedias vivas"},
    {prodId:"10226501",label:"Tweedia azul viva"},
    {prodId:"10226502",label:"Tweedia blanca viva"},
    {prodId:"10226600",label:"Verónicas vivas"},
    {prodId:"10226601",label:"Verónica viva rosada"},
    {prodId:"10226602",label:"Verónica viva púrpura"},
    {prodId:"10226603",label:"Verónica viva blanca"},
    {prodId:"10226700",label:"Watsonias vivas"},
    {prodId:"10226701",label:"Watsonia viva anaranjada"},
    {prodId:"10226702",label:"Watsonia viva rosada"},
    {prodId:"10226703",label:"Watsonia viva roja"},
    {prodId:"10226704",label:"Watsonia viva blanca"},
    {prodId:"10231500",label:"Crisantemo vivo pompón"},
    {prodId:"10231501",label:"Crisantemo vivo pompón delirock"},
    {prodId:"10231502",label:"Crisantemo vivo pompón discovery"},
    {prodId:"10231503",label:"Crisantemo vivo pompón focus"},
    {prodId:"10231504",label:"Crisantemo vivo pompón jeanne"},
    {prodId:"10231505",label:"Crisantemo vivo pompón lady"},
    {prodId:"10231506",label:"Crisantemo vivo pompón leidi"},
    {prodId:"10231507",label:"Crisantemo vivo pompón lexy"},
    {prodId:"10231508",label:"Crisantemo vivo pompón ole"},
    {prodId:"10231509",label:"Crisantemo vivo pompón revise"},
    {prodId:"10231510",label:"Crisantemo vivo pompón estadista"},
    {prodId:"10231511",label:"Crisantemo vivo pompón dulce"},
    {prodId:"10231512",label:"Crisantemo vivo pompón yoko ono"},
    {prodId:"10231513",label:"Crisantemo vivo pompón zip"},
    {prodId:"10231600",label:"Crisantemo vivo pompón margarita"},
    {prodId:"10231601",label:"Crisantemo vivo pompón artista rosado"},
    {prodId:"10231602",label:"Crisantemo vivo pompón artista amarillo"},
    {prodId:"10231603",label:"Crisantemo vivo pompón atlantis rosado"},
    {prodId:"10231604",label:"Crisantemo vivo pompón atlantis blanco"},
    {prodId:"10231605",label:"Crisantemo vivo pompón atlantis amarillo"},
    {prodId:"10231606",label:"Crisantemo vivo pompón bennie jolink"},
    {prodId:"10231607",label:"Crisantemo vivo pompón bennie jolink amarillo"},
    {prodId:"10231608",label:"Crisantemo vivo pompón managua bronce"},
    {prodId:"10231609",label:"Crisantemo vivo pompón clave"},
    {prodId:"10231610",label:"Crisantemo vivo pompón ficción coral"},
    {prodId:"10231611",label:"Crisantemo vivo pompón cumbia"},
    {prodId:"10231612",label:"Crisantemo vivo pompón cantata oscura"},
    {prodId:"10231613",label:"Crisantemo vivo pompón lineker oscuro"},
    {prodId:"10231614",label:"Crisantemo vivo pompón dipper"},
    {prodId:"10231615",label:"Crisantemo vivo pompón rosado elite"},
    {prodId:"10231616",label:"Crisantemo vivo pompón blanco elite"},
    {prodId:"10231617",label:"Crisantemo vivo pompón amarillo elite"},
    {prodId:"10231618",label:"Crisantemo vivo pompón factor"},
    {prodId:"10231619",label:"Crisantemo vivo pompón ficción"},
    {prodId:"10231620",label:"Crisantemo vivo pompón fuerza"},
    {prodId:"10231621",label:"Crisantemo vivo pompón reagan mejorado"},
    {prodId:"10231622",label:"Crisantemo vivo pompón life"},
    {prodId:"10231623",label:"Crisantemo vivo pompón managua anaranjado"},
    {prodId:"10231624",label:"Crisantemo vivo pompón novedsd bronce cocarde"},
    {prodId:"10231625",label:"Crisantemo vivo pompón reagan anaranjado"},
    {prodId:"10231626",label:"Crisantemo vivo pompón orinoco"},
    {prodId:"10231627",label:"Crisantemo vivo pompón petra"},
    {prodId:"10231628",label:"Crisantemo vivo pompón balsas rosado"},
    {prodId:"10231629",label:"Crisantemo vivo pompón mona lisa rosado"},
    {prodId:"10231630",label:"Crisantemo vivo pompón reagan rosado"},
    {prodId:"10231631",label:"Crisantemo vivo pompón marfil reagan"},
    {prodId:"10231632",label:"Crisantemo vivo pompón reagan rosado"},
    {prodId:"10231633",label:"Crisantemo vivo pompón rebasco"},
    {prodId:"10231634",label:"Crisantemo vivo pompón redock"},
    {prodId:"10231635",label:"Crisantemo vivo pompón salmón lineker"},
    {prodId:"10231636",label:"Crisantemo vivo pompón sheba"},
    {prodId:"10231637",label:"Crisantemo vivo pompón sirius"},
    {prodId:"10231638",label:"Crisantemo vivo pompón reagan espléndido"},
    {prodId:"10231639",label:"Crisantemo vivo pompón reagan soleado"},
    {prodId:"10231640",label:"Crisantemo vivo pompón tina"},
    {prodId:"10231641",label:"Crisantemo vivo pompón vero"},
    {prodId:"10231642",label:"Crisantemo vivo pompón volare"},
    {prodId:"10231643",label:"Crisantemo vivo pompón vida blanca"},
    {prodId:"10231644",label:"Crisantemo vivo pompón regan blanco"},
    {prodId:"10231645",label:"Crisantemo vivo pompón rino blanco"},
    {prodId:"10231646",label:"Crisantemo vivo pompón vero amarillo"},
    {prodId:"10231647",label:"Crisantemo vivo pompón zenith"},
    {prodId:"10231700",label:"Crisantemo vivo espollado cremon"},
    {prodId:"10231701",label:"Crisantemo vivo espollado cremon annecy oscura"},
    {prodId:"10231702",label:"Crisantemo vivo espollado cremon"},
    {prodId:"10231703",label:"Crisantemo vivo espollado cremon atlantis rosado"},
    {prodId:"10231704",label:"Crisantemo vivo espollado cremon eleonora bronce"},
    {prodId:"10231705",label:"Crisantemo vivo espollado cremon eleonora lila"},
    {prodId:"10231706",label:"Crisantemo vivo espollado cremon eleonora rosado"},
    {prodId:"10231707",label:"Crisantemo vivo espollado cremon eleonora nieve"},
    {prodId:"10231708",label:"Crisantemo vivo espollado cremon eleonora amarillo"},
    {prodId:"10231709",label:"Crisantemo vivo espollado cremon idea"},
    {prodId:"10231710",label:"Crisantemo vivo espollado cremon ivanna púrpura"},
    {prodId:"10231711",label:"Crisantemo vivo espollado cremon minka rosado"},
    {prodId:"10231712",label:"Crisantemo vivo espollado cremon listo"},
    {prodId:"10231713",label:"Crisantemo vivo espollado cremon sinatra"},
    {prodId:"10231714",label:"Crisantemo vivo rover rojo"},
    {prodId:"10231800",label:"Crisantemo vivo espollado football"},
    {prodId:"10231801",label:"Crisantemo vivo espollado blaze"},
    {prodId:"10231802",label:"Crisantemo vivo espollado beso de football"},
    {prodId:"10231803",label:"Crisantemo vivo espollado football lavanda / rosado"},
    {prodId:"10231804",label:"Crisantemo vivo espollado football resouci"},
    {prodId:"10231805",label:"Crisantemo vivo espollado footbal blanco"},
    {prodId:"10231806",label:"Crisantemo vivo espollado football amarillo"},
    {prodId:"10231807",label:"Crisantemo vivo espollado promenade"},
    {prodId:"10231808",label:"Crisantemo vivo espollado rebonnet"},
    {prodId:"10231809",label:"Crisantemo vivo espollado reflex"},
    {prodId:"10231810",label:"Crisantemo vivo espollado residence"},
    {prodId:"10231811",label:"Crisantemo vivo espollado resomee perla"},
    {prodId:"10231812",label:"Crisantemo vivo espollado resouci"},
    {prodId:"10231900",label:"Crisantemo spider vivo"},
    {prodId:"10231901",label:"Crisantemo spider vivo anastasia bronce"},
    {prodId:"10231902",label:"Crisantemo spider vivo anastasia bronce oscuro"},
    {prodId:"10231903",label:"Crisantemo spider vivo anastasia verde"},
    {prodId:"10231904",label:"Crisantemo spider vivo anastasia lila"},
    {prodId:"10231905",label:"Crisantemo spider vivo anastasia rosado"},
    {prodId:"10231906",label:"Crisantemo spider vivo anastasia púrpura"},
    {prodId:"10231907",label:"Crisantemo spider vivo anastasia soleado"},
    {prodId:"10231908",label:"Crisantemo spider vivo anastasia blanco"},
    {prodId:"10231909",label:"Crisantemo spider vivo bradford"},
    {prodId:"10231910",label:"Crisantemo spider vivo delistar blanco"},
    {prodId:"10231911",label:"Crisantemo spider vivo delistar amarillo"},
    {prodId:"10231912",label:"Crisantemo spider vivo minka"},
    {prodId:"10231913",label:"Crisantemo spider vivo natasha soleado"},
    {prodId:"10231914",label:"Crisantemo spider vivo pirouette"},
    {prodId:"10231915",label:"Crisantemo spider vivo reflejo"},
    {prodId:"10231916",label:"Crisantemo spider vivo regata"},
    {prodId:"10231917",label:"Crisantemo spider vivo render"},
    {prodId:"10231918",label:"Crisantemo spider vivo repertorio"},
    {prodId:"10231919",label:"Crisantemo spider vivo resolute"},
    {prodId:"10231920",label:"Crisantemo spider vivo resomac"},
    {prodId:"10231921",label:"Crisantemo spider vivo trébol"},
    {prodId:"10231922",label:"Crisantemo spider vivo talante bronce"},
    {prodId:"10231923",label:"Crisantemo spider vivo super blanco"},
    {prodId:"10231924",label:"Crisantemo spider vivo super amarillo"},
    {prodId:"10231925",label:"Crisantemo spider vivo tierno"},
    {prodId:"10231926",label:"Crisantemo spider vivo zembia"},
    {prodId:"10232000",label:"Crisantemo vivo  novelty"},
    {prodId:"10232001",label:"Crisantemo vivo pompón anecy rosado"},
    {prodId:"10232002",label:"Crisantemo vivo pompón ardilo royal"},
    {prodId:"10232003",label:"Crisantemo vivo pompón athos"},
    {prodId:"10232004",label:"Crisantemo vivo pompón biarritz"},
    {prodId:"10232005",label:"Crisantemo vivo pompón bradford anaranjado"},
    {prodId:"10232006",label:"Crisantemo vivo pompón bradford"},
    {prodId:"10232007",label:"Crisantemo vivo pompón vela"},
    {prodId:"10232008",label:"Crisantemo vivo pompón candor"},
    {prodId:"10232009",label:"Crisantemo vivo pompón gallardía"},
    {prodId:"10232010",label:"Crisantemo vivo pompón décima"},
    {prodId:"10232012",label:"Crisantemo vivo pompón delisun"},
    {prodId:"10232013",label:"Crisantemo vivo pompón dion"},
    {prodId:"10232014",label:"Crisantemo vivo pompón dorena"},
    {prodId:"10232015",label:"Crisantemo vivo pompón dublín"},
    {prodId:"10232016",label:"Crisantemo vivo pompón everglades"},
    {prodId:"10232017",label:"Crisantemo vivo pompón buenmozo"},
    {prodId:"10232018",label:"Crisantemo vivo pompón hasting"},
    {prodId:"10232019",label:"Crisantemo vivo pompón high five"},
    {prodId:"10232020",label:"Crisantemo vivo pompón mundial mejorado"},
    {prodId:"10232021",label:"Crisantemo vivo pompón juanes"},
    {prodId:"10232022",label:"Crisantemo vivo pompón kiato verde"},
    {prodId:"10232023",label:"Crisantemo vivo pompón kiato"},
    {prodId:"10232024",label:"Crisantemo vivo pompón kiwi"},
    {prodId:"10232025",label:"Crisantemo vivo pompón madeira"},
    {prodId:"10232026",label:"Crisantemo vivo pompón magneto"},
    {prodId:"10232027",label:"Crisantemo vivo pompón marimo"},
    {prodId:"10232028",label:"Crisantemo vivo pompón matrix"},
    {prodId:"10232029",label:"Crisantemo vivo pompón miletta"},
    {prodId:"10232030",label:"Crisantemo vivo pompón monalisa"},
    {prodId:"10232031",label:"Crisantemo vivo pompón omaha"},
    {prodId:"10232032",label:"Crisantemo vivo pompón orinoco púrpura"},
    {prodId:"10232033",label:"Crisantemo vivo pompón orinoco amarillo"},
    {prodId:"10232034",label:"Crisantemo vivo pompón verde pacífico"},
    {prodId:"10232035",label:"Crisantemo vivo pompón blanco puma"},
    {prodId:"10232036",label:"Crisantemo vivo pompón amarillo puma"},
    {prodId:"10232037",label:"Crisantemo vivo pompón mundial púrpura"},
    {prodId:"10232038",label:"Crisantemo vivo pompón regata"},
    {prodId:"10232039",label:"Crisantemo vivo pompón remco"},
    {prodId:"10232040",label:"Crisantemo vivo pompón royal mundial"},
    {prodId:"10232041",label:"Crisantemo vivo pompón sabrina"},
    {prodId:"10232042",label:"Crisantemo vivo pompón shakira blanco"},
    {prodId:"10232043",label:"Crisantemo vivo pompón sharp"},
    {prodId:"10232044",label:"Crisantemo vivo pompón shock"},
    {prodId:"10232045",label:"Crisantemo vivo pompón sizzle verde"},
    {prodId:"10232046",label:"Crisantemo vivo pompón sizzle rosado"},
    {prodId:"10232047",label:"Crisantemo vivo pompón sizzle"},
    {prodId:"10232048",label:"Crisantemo vivo pompón sizzle púrpura"},
    {prodId:"10232049",label:"Crisantemo vivo pompón sizzle salmón"},
    {prodId:"10232050",label:"Crisantemo vivo pompón sizzle amarillo"},
    {prodId:"10232051",label:"Crisantemo vivo pompón bandera española"},
    {prodId:"10232052",label:"Crisantemo vivo pompón starbust o copo de nieve"},
    {prodId:"10232053",label:"Crisantemo vivo pompón cisne"},
    {prodId:"10232054",label:"Crisantemo vivo pompón tedcha anaranjado"},
    {prodId:"10232055",label:"Crisantemo vivo pompón tierno"},
    {prodId:"10232056",label:"Crisantemo vivo pompón oropel"},
    {prodId:"10232057",label:"Crisantemo vivo pompón toque"},
    {prodId:"10232058",label:"Crisantemo vivo pompón troyes"},
    {prodId:"10232059",label:"Crisantemo vivo pompón valesca"},
    {prodId:"10232060",label:"Crisantemo vivo pompón anaranjado vikingo"},
    {prodId:"10232061",label:"Crisantemo vivo pompón vikingo"},
    {prodId:"10232062",label:"Crisantemo vivo pompón reloj"},
    {prodId:"10232063",label:"Crisantemo vivo pompón aguja blanco"},
    {prodId:"10232064",label:"Crisantemo vivo pompón noche blanca"},
    {prodId:"10232065",label:"Crisantemo vivo pompón artista amarillo"},
    {prodId:"10232066",label:"Crisantemo vivo pompón ficción amarillo"},
    {prodId:"10232067",label:"Crisantemo vivo pompón sharp amarillo"},
    {prodId:"10232100",label:"Crisantemo vivo santini"},
    {prodId:"10232101",label:"Crisantemo vivo pompón alma"},
    {prodId:"10232102",label:"Crisantemo vivo pompón barón"},
    {prodId:"10232103",label:"Crisantemo vivo pompón bernardo"},
    {prodId:"10232104",label:"Crisantemo vivo pompón bistro"},
    {prodId:"10232105",label:"Crisantemo vivo pompón bodega"},
    {prodId:"10232106",label:"Crisantemo vivo pompón brisa"},
    {prodId:"10232107",label:"Crisantemo vivo pompón centella bronce"},
    {prodId:"10232108",label:"Crisantemo vivo pompón costa blanca"},
    {prodId:"10232109",label:"Crisantemo vivo pompón creta"},
    {prodId:"10232110",label:"Crisantemo vivo pompón deliflame"},
    {prodId:"10232111",label:"Crisantemo vivo pompón dalila"},
    {prodId:"10232112",label:"Crisantemo vivo pompón dígito"},
    {prodId:"10232113",label:"Crisantemo vivo pompón evilio"},
    {prodId:"10232114",label:"Crisantemo vivo pompón furense"},
    {prodId:"10232115",label:"Crisantemo vivo pompón guía"},
    {prodId:"10232116",label:"Crisantemo vivo pompón kerry"},
    {prodId:"10232117",label:"Crisantemo vivo pompón kess"},
    {prodId:"10232118",label:"Crisantemo vivo pompón lima"},
    {prodId:"10232119",label:"Crisantemo vivo pompón lupo"},
    {prodId:"10232120",label:"Crisantemo vivo pompón lineker anaranjado"},
    {prodId:"10232121",label:"Crisantemo vivo pompón panuco rojo"},
    {prodId:"10232122",label:"Crisantemo vivo pompón costa rosado"},
    {prodId:"10232123",label:"Crisantemo vivo pompón rafael"},
    {prodId:"10232124",label:"Crisantemo vivo pompón refine"},
    {prodId:"10232125",label:"Crisantemo vivo pompón regaliz"},
    {prodId:"10232126",label:"Crisantemo vivo pompón renella"},
    {prodId:"10232127",label:"Crisantemo vivo pompón retorno"},
    {prodId:"10232128",label:"Crisantemo vivo pompón río"},
    {prodId:"10232129",label:"Crisantemo vivo pompón sabas"},
    {prodId:"10232130",label:"Crisantemo vivo pompón target"},
    {prodId:"10232131",label:"Crisantemo vivo pompón texto"},
    {prodId:"10232132",label:"Crisantemo vivo pompón caballo amarillo"},
    {prodId:"10241600",label:"Clavel vivo mini o spray"},
    {prodId:"10241601",label:"Clavel vivo mini o spray burgundy"},
    {prodId:"10241602",label:"Clavel vivo mini o spray crema"},
    {prodId:"10241603",label:"Clavel vivo mini o spray rosado fuerte"},
    {prodId:"10241604",label:"Clavel vivo mini o spray lavanda"},
    {prodId:"10241605",label:"Clavel vivo mini o spray rosado claro"},
    {prodId:"10241606",label:"Clavel vivo mini o spray anaranjado"},
    {prodId:"10241607",label:"Clavel vivo mini o spray durazno"},
    {prodId:"10241608",label:"Clavel vivo mini o spray menta"},
    {prodId:"10241609",label:"Clavel vivo mini o spray rosado"},
    {prodId:"10241610",label:"Clavel vivo mini o spray púrpura bicolor"},
    {prodId:"10241611",label:"Clavel vivo mini o spray púrpura"},
    {prodId:"10241612",label:"Clavel vivo mini o spray rojo"},
    {prodId:"10241613",label:"Clavel vivo mini o spray blanco"},
    {prodId:"10241614",label:"Clavel vivo mini o spray amarillo"},
    {prodId:"10251500",label:"Orquidea cypripedium  o pantuflas de dama viva"},
    {prodId:"10251501",label:"Orquidea cypripedium viva verde"},
    {prodId:"10251502",label:"Orquidea cypripedium viva francia"},
    {prodId:"10251503",label:"Orquidea cypripedium viva púrpura rey arturo"},
    {prodId:"10251504",label:"Orquidea paphiopedilum viva verde"},
    {prodId:"10251505",label:"Orquidea aranthera maggie vie viva"},
    {prodId:"10251600",label:"Orquídea mocara viva"},
    {prodId:"10251601",label:"Orquídea mocara viva omyai"},
    {prodId:"10251602",label:"Orquídea mocara viva roja"},
    {prodId:"10251603",label:"Orquídea mocara viva calipso"},
    {prodId:"10251604",label:"Orquídea mocara viva nora"},
    {prodId:"10251605",label:"Orquídea mocara viva panee"},
    {prodId:"10251700",label:"Orquídea catleya viva"},
    {prodId:"10251701",label:"Orquídea cattleya viva blanca"},
    {prodId:"10251702",label:"Orquídea cattleya viva r b lavanda"},
    {prodId:"10251800",label:"Orquídea disa viva"},
    {prodId:"10251801",label:"Orquídea disa viva roja"},
    {prodId:"10251802",label:"Orquídea disa viva anaranjada"},
    {prodId:"10251803",label:"Orquídea disa viva rosada"},
    {prodId:"10251804",label:"Orquídea disa viva anaranjada y amarilla bicolor"},
    {prodId:"10251805",label:"Orquídea disa viva durazno y amarilla bicolor"},
    {prodId:"10251806",label:"Orquídea disa viva amarilla y roja bicolor"},
    {prodId:"10251900",label:"Orquídea arachnis viva"},
    {prodId:"10251901",label:"Orquídea arachnis viva james storie roja"},
    {prodId:"10251902",label:"Orquídea arachnis viva maggie oei roja"},
    {prodId:"10251903",label:"Orquídea arachnis viva maggie oei amarilla"},
    {prodId:"10251904",label:"Orquídea arachnis viva maroon maggie"},
    {prodId:"10251905",label:"Orquídea arachnis viva merry maggie"},
    {prodId:"10252100",label:"Orquídea dendrobium viva"},
    {prodId:"10252101",label:"Orquídea dendrobium viva bom"},
    {prodId:"10252102",label:"Orquídea dendrobium viva burana"},
    {prodId:"10252103",label:"Orquídea dendrobium viva chita"},
    {prodId:"10252104",label:"Orquídea dendrobium viva fátima"},
    {prodId:"10252105",label:"Orquídea dendrobium viva intuwong"},
    {prodId:"10252106",label:"Orquídea dendrobium viva jumbo blanca"},
    {prodId:"10252107",label:"Orquídea dendrobium viva kating dang"},
    {prodId:"10252108",label:"Orquídea dendrobium viva libertad"},
    {prodId:"10252109",label:"Orquídea dendrobium viva hawaii"},
    {prodId:"10252110",label:"Orquídea dendrobium viva sakura sweet rosada"},
    {prodId:"10252111",label:"Orquídea dendrobium viva sensacional púrpura"},
    {prodId:"10252112",label:"Orquídea dendrobium viva blanca"},
    {prodId:"10252200",label:"Orquídea cymbidium viva"},
    {prodId:"10252201",label:"Orquídea cymbidium viva crema"},
    {prodId:"10252202",label:"Orquídea cymbidium viva verde"},
    {prodId:"10252203",label:"Orquídea cymbidium viva mini verde"},
    {prodId:"10252204",label:"Orquídea cymbidium viva mini rosada"},
    {prodId:"10252205",label:"Orquídea cymbidium viva mini roja"},
    {prodId:"10252206",label:"Orquídea cymbidium viva mini blanca"},
    {prodId:"10252207",label:"Orquídea cymbidium viva mini amarilla"},
    {prodId:"10252208",label:"Orquídea cymbidium viva chocolate"},
    {prodId:"10252209",label:"Orquídea cymbidium viva rosado oscuro"},
    {prodId:"10252210",label:"Orquídea cymbidium viva anaranjada"},
    {prodId:"10252211",label:"Orquídea cymbidium viva rosada"},
    {prodId:"10252212",label:"Orquídea cymbidium viva blanca"},
    {prodId:"10252213",label:"Orquídea cymbidium viva amarilla"},
    {prodId:"10252300",label:"Orquídea oncidium viva"},
    {prodId:"10252301",label:"Orquídea oncidium viva ducha dorada"},
    {prodId:"10252302",label:"Orquídea oncidium viva rhamsey"},
    {prodId:"10252400",label:"Orquídea vanda viva"},
    {prodId:"10252401",label:"Orquídea vanda alizarin"},
    {prodId:"10252402",label:"Orquídea vanda rosada fuerte"},
    {prodId:"10252403",label:"Orquídea vanda lavanda"},
    {prodId:"10252404",label:"Orquídea vanda púrpura"},
    {prodId:"10252405",label:"Orquídea vanda hazme cosquillas rosada"},
    {prodId:"10252406",label:"Orquídea vanda amarilla"},
    {prodId:"10301500",label:"Rosas cortadas frescas azules o lavanda o púrpura"},
    {prodId:"10301501",label:"Rosal cortado fresco allure o sterling 95"},
    {prodId:"10301502",label:"Rosal cortado fresco amnesia"},
    {prodId:"10301503",label:"Rosal cortado fresco augusta louise"},
    {prodId:"10301504",label:"Rosal cortado fresco avant garde"},
    {prodId:"10301505",label:"Rosal cortado fresco blue bird"},
    {prodId:"10301506",label:"Rosal cortado fresco curiosa"},
    {prodId:"10301507",label:"Rosal cortado fresco cool water"},
    {prodId:"10301508",label:"Rosal cortado fresco delilah"},
    {prodId:"10301509",label:"Rosal cortado fresco double party"},
    {prodId:"10301510",label:"Rosal cortado fresco faith"},
    {prodId:"10301511",label:"Rosal cortado fresco mami blue o mammy blue"},
    {prodId:"10301512",label:"Rosal cortado fresco maritime"},
    {prodId:"10301513",label:"Rosal cortado fresco milano"},
    {prodId:"10301514",label:"Rosal cortado fresco mistery"},
    {prodId:"10301515",label:"Rosal cortado fresco ocean song o boyfriend"},
    {prodId:"10301516",label:"Rosal cortado fresco cezanne púrpura"},
    {prodId:"10301517",label:"Rosal cortado fresco purple fragrance"},
    {prodId:"10301518",label:"Rosal cortado fresco sanaa"},
    {prodId:"10301519",label:"Rosal cortado fresco silverstone"},
    {prodId:"10301520",label:"Rosal cortado fresco soulmate"},
    {prodId:"10301521",label:"Rosal cortado fresco stranger"},
    {prodId:"10301522",label:"Rosal cortado fresco tinted blue"},
    {prodId:"10301523",label:"Rosal cortado fresco two faces"},
    {prodId:"10301600",label:"Rosas cortadas frescas chocolate o marrón"},
    {prodId:"10301601",label:"Rosal cortado fresco black lava"},
    {prodId:"10301602",label:"Rosal cortado fresco cimarrón"},
    {prodId:"10301603",label:"Rosal cortado fresco coffee break"},
    {prodId:"10301604",label:"Rosal cortado fresco estelle"},
    {prodId:"10301605",label:"Rosal cortado fresco gypsy leonidas"},
    {prodId:"10301606",label:"Rosal cortado fresco leonidas"},
    {prodId:"10301607",label:"Rosal cortado fresco matilda"},
    {prodId:"10301608",label:"Rosal cortado fresco sunny leonidas"},
    {prodId:"10301609",label:"Rosal cortado fresco terra nostra"},
    {prodId:"10301610",label:"Rosal cortado fresco terracotta"},
    {prodId:"10301700",label:"Rosas cortadas frescas crema"},
    {prodId:"10301701",label:"Rosal cortado fresco advenire"},
    {prodId:"10301702",label:"Rosal cortado fresco alex"},
    {prodId:"10301703",label:"Rosal cortado fresco antique brass"},
    {prodId:"10301704",label:"Rosal cortado fresco aubade"},
    {prodId:"10301705",label:"Rosal cortado fresco beach"},
    {prodId:"10301706",label:"Rosal cortado fresco belle pearl"},
    {prodId:"10301707",label:"Rosal cortado fresco blush o blush de los andes"},
    {prodId:"10301708",label:"Rosal cortado fresco camel"},
    {prodId:"10301709",label:"Rosal cortado fresco caramel antike o caramel antique"},
    {prodId:"10301710",label:"Rosal cortado fresco champagne"},
    {prodId:"10301711",label:"Rosal cortado fresco clear ocean"},
    {prodId:"10301712",label:"Rosal cortado fresco combo"},
    {prodId:"10301713",label:"Rosal cortado fresco creme de la creme"},
    {prodId:"10301714",label:"Rosal cortado fresco emanuella"},
    {prodId:"10301715",label:"Rosal cortado fresco evolution"},
    {prodId:"10301716",label:"Rosal cortado fresco fedora"},
    {prodId:"10301717",label:"Rosal cortado fresco fenice"},
    {prodId:"10301718",label:"Rosal cortado fresco french vanilla"},
    {prodId:"10301719",label:"Rosal cortado fresco hollywood"},
    {prodId:"10301720",label:"Rosal cortado fresco ilios"},
    {prodId:"10301721",label:"Rosal cortado fresco jelena"},
    {prodId:"10301722",label:"Rosal cortado fresco kameleon"},
    {prodId:"10301723",label:"Rosal cortado fresco kentucky"},
    {prodId:"10301724",label:"Rosal cortado fresco kings pride"},
    {prodId:"10301725",label:"Rosal cortado fresco latin fusion"},
    {prodId:"10301726",label:"Rosal cortado fresco lemon dream"},
    {prodId:"10301727",label:"Rosal cortado fresco magic moka"},
    {prodId:"10301728",label:"Rosal cortado fresco mama mia"},
    {prodId:"10301729",label:"Rosal cortado fresco message"},
    {prodId:"10301730",label:"Rosal cortado fresco muñeca o munieca"},
    {prodId:"10301731",label:"Rosal cortado fresco parfum de rosas"},
    {prodId:"10301732",label:"Rosal cortado fresco porcelina"},
    {prodId:"10301733",label:"Rosal cortado fresco privilege"},
    {prodId:"10301734",label:"Rosal cortado fresco quick sand"},
    {prodId:"10301735",label:"Rosal cortado fresco rollercoaster"},
    {prodId:"10301736",label:"Rosal cortado fresco romantic curiosa"},
    {prodId:"10301737",label:"Rosal cortado fresco safari"},
    {prodId:"10301738",label:"Rosal cortado fresco sahara"},
    {prodId:"10301739",label:"Rosal cortado fresco sandy femma"},
    {prodId:"10301740",label:"Rosal cortado fresco talea"},
    {prodId:"10301741",label:"Rosal cortado fresco timeless"},
    {prodId:"10301742",label:"Rosal cortado fresco transition"},
    {prodId:"10301743",label:"Rosal cortado fresco trump"},
    {prodId:"10301744",label:"Rosal cortado fresco twin"},
    {prodId:"10301745",label:"Rosal cortado fresco vendela"},
    {prodId:"10301746",label:"Rosal cortado fresco virginia"},
    {prodId:"10301800",label:"Rosas cortadas frescas verdes o lima"},
    {prodId:"10301801",label:"Rosal cortado fresco amandina"},
    {prodId:"10301802",label:"Rosal cortado fresco caipirinha"},
    {prodId:"10301803",label:"Rosal cortado fresco green fashion"},
    {prodId:"10301804",label:"Rosal cortado fresco green tea"},
    {prodId:"10301805",label:"Rosal cortado fresco jade"},
    {prodId:"10301806",label:"Rosal cortado fresco limbo"},
    {prodId:"10301807",label:"Rosal cortado fresco limeña o limenia"},
    {prodId:"10301808",label:"Rosal cortado fresco limona"},
    {prodId:"10301809",label:"Rosal cortado fresco old dutch"},
    {prodId:"10301810",label:"Rosal cortado fresco super green"},
    {prodId:"10301811",label:"Rosal cortado fresco sweet green"},
    {prodId:"10301812",label:"Rosal cortado fresco cortada fresca"},
    {prodId:"10301813",label:"Rosal cortado fresco zazu"},
    {prodId:"10301900",label:"Rosas cortadas frescas rosado claro"},
    {prodId:"10301901",label:"Rosal cortado fresco anna"},
    {prodId:"10301902",label:"Rosal cortado fresco bella vita"},
    {prodId:"10301903",label:"Rosal cortado fresco bridal dream"},
    {prodId:"10301904",label:"Rosal cortado fresco candy bianca"},
    {prodId:"10301905",label:"Rosal cortado fresco caress"},
    {prodId:"10301906",label:"Rosal cortado fresco carolina"},
    {prodId:"10301907",label:"Rosal cortado fresco climax"},
    {prodId:"10301908",label:"Rosal cortado fresco danny"},
    {prodId:"10301909",label:"Rosal cortado fresco dolce vita"},
    {prodId:"10301910",label:"Rosal cortado fresco elite"},
    {prodId:"10301911",label:"Rosal cortado fresco emma"},
    {prodId:"10301912",label:"Rosal cortado fresco engagement"},
    {prodId:"10301913",label:"Rosal cortado fresco esther"},
    {prodId:"10301914",label:"Rosal cortado fresco excalibur"},
    {prodId:"10301915",label:"Rosal cortado fresco exciting"},
    {prodId:"10301916",label:"Rosal cortado fresco first lady"},
    {prodId:"10301917",label:"Rosal cortado fresco geraldine"},
    {prodId:"10301918",label:"Rosal cortado fresco gotcha"},
    {prodId:"10301919",label:"Rosal cortado fresco harmonie"},
    {prodId:"10301920",label:"Rosal cortado fresco heaven"},
    {prodId:"10301921",label:"Rosal cortado fresco high and elegant"},
    {prodId:"10301922",label:"Rosal cortado fresco katherine"},
    {prodId:"10301923",label:"Rosal cortado fresco king kong"},
    {prodId:"10301924",label:"Rosal cortado fresco livia"},
    {prodId:"10301925",label:"Rosal cortado fresco lorena"},
    {prodId:"10301926",label:"Rosal cortado fresco lovely"},
    {prodId:"10301927",label:"Rosal cortado fresco maaike"},
    {prodId:"10301928",label:"Rosal cortado fresco marilyn"},
    {prodId:"10301929",label:"Rosal cortado fresco marlise"},
    {prodId:"10301930",label:"Rosal cortado fresco miranda o ausimmon"},
    {prodId:"10301931",label:"Rosal cortado fresco mona lisa"},
    {prodId:"10301932",label:"Rosal cortado fresco nirvana"},
    {prodId:"10301933",label:"Rosal cortado fresco o'hara"},
    {prodId:"10301934",label:"Rosal cortado fresco ole"},
    {prodId:"10301935",label:"Rosal cortado fresco olga"},
    {prodId:"10301936",label:"Rosal cortado fresco pacífica"},
    {prodId:"10301937",label:"Rosal cortado fresco party mix"},
    {prodId:"10301938",label:"Rosal cortado fresco pekoubo o pekcoubo"},
    {prodId:"10301939",label:"Rosal cortado fresco phoebe o ausnotice"},
    {prodId:"10301940",label:"Rosal cortado fresco pink farfalla"},
    {prodId:"10301941",label:"Rosal cortado fresco pink finess"},
    {prodId:"10301942",label:"Rosal cortado fresco pink magic"},
    {prodId:"10301943",label:"Rosal cortado fresco pink osiana"},
    {prodId:"10301944",label:"Rosal cortado fresco pretty woman"},
    {prodId:"10301945",label:"Rosal cortado fresco romance"},
    {prodId:"10301946",label:"Rosal cortado fresco romantic antike o antique"},
    {prodId:"10301947",label:"Rosal cortado fresco rosalind o astew"},
    {prodId:"10301948",label:"Rosal cortado fresco rosita vendela"},
    {prodId:"10301949",label:"Rosal cortado fresco secret garden"},
    {prodId:"10301950",label:"Rosal cortado fresco solaire"},
    {prodId:"10301951",label:"Rosal cortado fresco sophie"},
    {prodId:"10301952",label:"Rosal cortado fresco sweet akito"},
    {prodId:"10301953",label:"Rosal cortado fresco sweet avalanche"},
    {prodId:"10301954",label:"Rosal cortado fresco sweet elegance"},
    {prodId:"10301955",label:"Rosal cortado fresco sweet pink"},
    {prodId:"10301956",label:"Rosal cortado fresco titanic"},
    {prodId:"10301957",label:"Rosal cortado fresco toscanini"},
    {prodId:"10301958",label:"Rosal cortado fresco vania"},
    {prodId:"10301959",label:"Rosal cortado fresco vanity"},
    {prodId:"10301960",label:"Rosal cortado fresco vision"},
    {prodId:"10301961",label:"Rosal cortado fresco vivaldi"},
    {prodId:"10301962",label:"Rosal cortado fresco whisper"},
    {prodId:"10302000",label:"Rosas cortadas frescas multicolor rosados"},
    {prodId:"10302001",label:"Rosal cortado fresco attracta"},
    {prodId:"10302002",label:"Rosal cortado fresco boheme"},
    {prodId:"10302003",label:"Rosal cortado fresco carousel"},
    {prodId:"10302004",label:"Rosal cortado fresco cezanne"},
    {prodId:"10302005",label:"Rosal cortado fresco crazy one"},
    {prodId:"10302006",label:"Rosal cortado fresco dance valley"},
    {prodId:"10302007",label:"Rosal cortado fresco duett"},
    {prodId:"10302008",label:"Rosal cortado fresco esperance"},
    {prodId:"10302009",label:"Rosal cortado fresco fiesta"},
    {prodId:"10302010",label:"Rosal cortado fresco halloween"},
    {prodId:"10302011",label:"Rosal cortado fresco highlander"},
    {prodId:"10302012",label:"Rosal cortado fresco hot ambiance"},
    {prodId:"10302013",label:"Rosal cortado fresco la belle"},
    {prodId:"10302014",label:"Rosal cortado fresco laguna"},
    {prodId:"10302015",label:"Rosal cortado fresco latin ambiance"},
    {prodId:"10302016",label:"Rosal cortado fresco latin breeze"},
    {prodId:"10302017",label:"Rosal cortado fresco long arifa"},
    {prodId:"10302018",label:"Rosal cortado fresco murano"},
    {prodId:"10302019",label:"Rosal cortado fresco n - joy rose"},
    {prodId:"10302020",label:"Rosal cortado fresco panama"},
    {prodId:"10302021",label:"Rosal cortado fresco peppermint"},
    {prodId:"10302022",label:"Rosal cortado fresco pujama party"},
    {prodId:"10302023",label:"Rosal cortado fresco portofino"},
    {prodId:"10302024",label:"Rosal cortado fresco priceless"},
    {prodId:"10302025",label:"Rosal cortado fresco queen amazon"},
    {prodId:"10302026",label:"Rosal cortado fresco ranuncula"},
    {prodId:"10302027",label:"Rosal cortado fresco rossini"},
    {prodId:"10302028",label:"Rosal cortado fresco sabina o sabrina"},
    {prodId:"10302029",label:"Rosal cortado fresco scandal"},
    {prodId:"10302030",label:"Rosal cortado fresco silvery pink"},
    {prodId:"10302031",label:"Rosal cortado fresco something else"},
    {prodId:"10302032",label:"Rosal cortado fresco southine"},
    {prodId:"10302033",label:"Rosal cortado fresco sovereign"},
    {prodId:"10302034",label:"Rosal cortado fresco super disco"},
    {prodId:"10302035",label:"Rosal cortado fresco ts 1968"},
    {prodId:"10302036",label:"Rosal cortado fresco variance"},
    {prodId:"10302037",label:"Rosal cortado fresco verdi"},
    {prodId:"10302100",label:"Rosas cortadas frescas anaranjados"},
    {prodId:"10302101",label:"Rosal cortado fresco alhambra"},
    {prodId:"10302102",label:"Rosal cortado fresco aloha"},
    {prodId:"10302103",label:"Rosal cortado fresco amber"},
    {prodId:"10302104",label:"Rosal cortado fresco apache"},
    {prodId:"10302105",label:"Rosal cortado fresco arabia"},
    {prodId:"10302106",label:"Rosal cortado fresco bengala"},
    {prodId:"10302107",label:"Rosal cortado fresco bibi"},
    {prodId:"10302108",label:"Rosal cortado fresco caramba"},
    {prodId:"10302109",label:"Rosal cortado fresco caramella"},
    {prodId:"10302110",label:"Rosal cortado fresco carla"},
    {prodId:"10302111",label:"Rosal cortado fresco cartagena"},
    {prodId:"10302112",label:"Rosal cortado fresco chanson"},
    {prodId:"10302113",label:"Rosal cortado fresco charmer"},
    {prodId:"10302114",label:"Rosal cortado fresco cherry brandy"},
    {prodId:"10302115",label:"Rosal cortado fresco chilis"},
    {prodId:"10302116",label:"Rosal cortado fresco cinnamon"},
    {prodId:"10302117",label:"Rosal cortado fresco colandro"},
    {prodId:"10302118",label:"Rosal cortado fresco coral sea"},
    {prodId:"10302119",label:"Rosal cortado fresco corvette o red covette"},
    {prodId:"10302120",label:"Rosal cortado fresco dark milva"},
    {prodId:"10302121",label:"Rosal cortado fresco donna"},
    {prodId:"10302122",label:"Rosal cortado fresco dreamer"},
    {prodId:"10302123",label:"Rosal cortado fresco el dorado"},
    {prodId:"10302124",label:"Rosal cortado fresco el toro"},
    {prodId:"10302125",label:"Rosal cortado fresco elena"},
    {prodId:"10302126",label:"Rosal cortado fresco ensueño"},
    {prodId:"10302127",label:"Rosal cortado fresco euforia"},
    {prodId:"10302128",label:"Rosal cortado fresco exótica"},
    {prodId:"10302129",label:"Rosal cortado fresco fancy amazon"},
    {prodId:"10302130",label:"Rosal cortado fresco fiction"},
    {prodId:"10302131",label:"Rosal cortado fresco finess"},
    {prodId:"10302132",label:"Rosal cortado fresco flameco"},
    {prodId:"10302133",label:"Rosal cortado fresco free spirit"},
    {prodId:"10302134",label:"Rosal cortado fresco gelato"},
    {prodId:"10302135",label:"Rosal cortado fresco gypsy curiosa"},
    {prodId:"10302136",label:"Rosal cortado fresco high and magic"},
    {prodId:"10302137",label:"Rosal cortado fresco high and orange"},
    {prodId:"10302138",label:"Rosal cortado fresco iguana o alegra"},
    {prodId:"10302139",label:"Rosal cortado fresco impulse"},
    {prodId:"10302140",label:"Rosal cortado fresco indian femma"},
    {prodId:"10302141",label:"Rosal cortado fresco indian sunset"},
    {prodId:"10302142",label:"Rosal cortado fresco karusso"},
    {prodId:"10302143",label:"Rosal cortado fresco kerio"},
    {prodId:"10302144",label:"Rosal cortado fresco kiki"},
    {prodId:"10302145",label:"Rosal cortado fresco latin circus"},
    {prodId:"10302146",label:"Rosal cortado fresco leonisa"},
    {prodId:"10302147",label:"Rosal cortado fresco lipstick"},
    {prodId:"10302148",label:"Rosal cortado fresco lobita"},
    {prodId:"10302149",label:"Rosal cortado fresco lica"},
    {prodId:"10302150",label:"Rosal cortado fresco manitou"},
    {prodId:"10302151",label:"Rosal cortado fresco mariana"},
    {prodId:"10302152",label:"Rosal cortado fresco marjan o pk sensation"},
    {prodId:"10302153",label:"Rosal cortado fresco milonga"},
    {prodId:"10302154",label:"Rosal cortado fresco milva"},
    {prodId:"10302155",label:"Rosal cortado fresco miracle"},
    {prodId:"10302156",label:"Rosal cortado fresco mirage"},
    {prodId:"10302157",label:"Rosal cortado fresco monte carlo"},
    {prodId:"10302158",label:"Rosal cortado fresco movie star"},
    {prodId:"10302159",label:"Rosal cortado fresco nikita"},
    {prodId:"10302160",label:"Rosal cortado fresco orange flame"},
    {prodId:"10302161",label:"Rosal cortado fresco orange france"},
    {prodId:"10302162",label:"Rosal cortado fresco orange intuition"},
    {prodId:"10302163",label:"Rosal cortado fresco orange unique"},
    {prodId:"10302164",label:"Rosal cortado fresco orangine u orangina"},
    {prodId:"10302165",label:"Rosal cortado fresco papaya"},
    {prodId:"10302166",label:"Rosal cortado fresco pareo"},
    {prodId:"10302167",label:"Rosal cortado fresco peach"},
    {prodId:"10302168",label:"Rosal cortado fresco queensday"},
    {prodId:"10302169",label:"Rosal cortado fresco rosselle"},
    {prodId:"10302170",label:"Rosal cortado fresco royal circus"},
    {prodId:"10302171",label:"Rosal cortado fresco sari"},
    {prodId:"10302172",label:"Rosal cortado fresco sensual"},
    {prodId:"10302173",label:"Rosal cortado fresco soap"},
    {prodId:"10302174",label:"Rosal cortado fresco sombrero"},
    {prodId:"10302175",label:"Rosal cortado fresco spicy"},
    {prodId:"10302176",label:"Rosal cortado fresco estrella 2000"},
    {prodId:"10302177",label:"Rosal cortado fresco summer versilia"},
    {prodId:"10302178",label:"Rosal cortado fresco trixx"},
    {prodId:"10302179",label:"Rosal cortado fresco tropical amazon"},
    {prodId:"10302180",label:"Rosal cortado fresco utopia"},
    {prodId:"10302181",label:"Rosal cortado fresco valentine"},
    {prodId:"10302182",label:"Rosal cortado fresco verano"},
    {prodId:"10302183",label:"Rosal cortado fresco versilia"},
    {prodId:"10302184",label:"Rosal cortado fresco voodoo"},
    {prodId:"10302185",label:"Rosal cortado fresco wow"},
    {prodId:"10302186",label:"Rosal cortado fresco yabadabadoo"},
    {prodId:"10302200",label:"Rosas cortadas frescas durazno"},
    {prodId:"10302201",label:"Rosal cortado fresco alejandra"},
    {prodId:"10302202",label:"Rosal cortado fresco azafrán"},
    {prodId:"10302203",label:"Rosal cortado fresco big fun"},
    {prodId:"10302204",label:"Rosal cortado fresco cabaret"},
    {prodId:"10302205",label:"Rosal cortado fresco capuccino"},
    {prodId:"10302206",label:"Rosal cortado fresco carpe diem"},
    {prodId:"10302207",label:"Rosal cortado fresco cosima"},
    {prodId:"10302208",label:"Rosal cortado fresco cumbia"},
    {prodId:"10302209",label:"Rosal cortado fresco dream"},
    {prodId:"10302210",label:"Rosal cortado fresco época"},
    {prodId:"10302211",label:"Rosal cortado fresco fado"},
    {prodId:"10302212",label:"Rosal cortado fresco femma"},
    {prodId:"10302213",label:"Rosal cortado fresco guajira"},
    {prodId:"10302214",label:"Rosal cortado fresco high and arena"},
    {prodId:"10302215",label:"Rosal cortado fresco high and dandy"},
    {prodId:"10302216",label:"Rosal cortado fresco high and lucky"},
    {prodId:"10302217",label:"Rosal cortado fresco high and peach"},
    {prodId:"10302218",label:"Rosal cortado fresco imagination"},
    {prodId:"10302219",label:"Rosal cortado fresco isis"},
    {prodId:"10302220",label:"Rosal cortado fresco joy o light versilia"},
    {prodId:"10302221",label:"Rosal cortado fresco juliet ausjameson"},
    {prodId:"10302222",label:"Rosal cortado fresco la parisienne"},
    {prodId:"10302223",label:"Rosal cortado fresco la perla"},
    {prodId:"10302224",label:"Rosal cortado fresco lovita sunblaze"},
    {prodId:"10302225",label:"Rosal cortado fresco malilena o marilena"},
    {prodId:"10302226",label:"Rosal cortado fresco monyna"},
    {prodId:"10302227",label:"Rosal cortado fresco nectarine"},
    {prodId:"10302228",label:"Rosal cortado fresco oriental curiosa"},
    {prodId:"10302229",label:"Rosal cortado fresco osiana"},
    {prodId:"10302230",label:"Rosal cortado fresco preach avalanche"},
    {prodId:"10302231",label:"Rosal cortado fresco peach deja vu"},
    {prodId:"10302232",label:"Rosal cortado fresco picanto"},
    {prodId:"10302233",label:"Rosal cortado fresco prima donna"},
    {prodId:"10302234",label:"Rosal cortado fresco sheril"},
    {prodId:"10302235",label:"Rosal cortado fresco sirocco"},
    {prodId:"10302236",label:"Rosal cortado fresco tamara"},
    {prodId:"10302237",label:"Rosal cortado fresco taxo"},
    {prodId:"10302238",label:"Rosal cortado fresco trust"},
    {prodId:"10302239",label:"Rosal cortado fresco valencia"},
    {prodId:"10302240",label:"Rosal cortado fresco cinci"},
    {prodId:"10302241",label:"Rosal cortado fresco wanda"},
    {prodId:"10302300",label:"Rosas cortadas frescas rosados"},
    {prodId:"10302301",label:"Rosal cortado fresco aerobic"},
    {prodId:"10302302",label:"Rosal cortado fresco after party"},
    {prodId:"10302303",label:"Rosal cortado fresco amsterdam"},
    {prodId:"10302304",label:"Rosal cortado fresco aqua rose"},
    {prodId:"10302305",label:"Rosal cortado fresco attache"},
    {prodId:"10302306",label:"Rosal cortado fresco attitude"},
    {prodId:"10302307",label:"Rosal cortado fresco ballet"},
    {prodId:"10302308",label:"Rosal cortado fresco belami"},
    {prodId:"10302309",label:"Rosal cortado fresco bella voo o belle vue"},
    {prodId:"10302310",label:"Rosal cortado fresco bling bling"},
    {prodId:"10302311",label:"Rosal cortado fresco blushing akito"},
    {prodId:"10302312",label:"Rosal cortado fresco brooke"},
    {prodId:"10302313",label:"Rosal cortado fresco bugatti"},
    {prodId:"10302314",label:"Rosal cortado fresco cadillac"},
    {prodId:"10302315",label:"Rosal cortado fresco carnaval"},
    {prodId:"10302316",label:"Rosal cortado fresco cereza"},
    {prodId:"10302317",label:"Rosal cortado fresco charming"},
    {prodId:"10302318",label:"Rosal cortado fresco cherry o"},
    {prodId:"10302319",label:"Rosal cortado fresco ciciolina"},
    {prodId:"10302320",label:"Rosal cortado fresco classic cezanne"},
    {prodId:"10302321",label:"Rosal cortado fresco clasic duett"},
    {prodId:"10302322",label:"Rosal cortado fresco cosmiq"},
    {prodId:"10302323",label:"Rosal cortado fresco dark engagement"},
    {prodId:"10302324",label:"Rosal cortado fresco daytona"},
    {prodId:"10302325",label:"Rosal cortado fresco dekora"},
    {prodId:"10302326",label:"Rosal cortado fresco dolores"},
    {prodId:"10302327",label:"Rosal cortado fresco eliza"},
    {prodId:"10302328",label:"Rosal cortado fresco baccara"},
    {prodId:"10302329",label:"Rosal cortado fresco full house"},
    {prodId:"10302330",label:"Rosal cortado fresco funky"},
    {prodId:"10302331",label:"Rosal cortado fresco giliane"},
    {prodId:"10302332",label:"Rosal cortado fresco gran europe"},
    {prodId:"10302333",label:"Rosal cortado fresco habari"},
    {prodId:"10302334",label:"Rosal cortado fresco hanseat"},
    {prodId:"10302335",label:"Rosal cortado fresco high and amazing"},
    {prodId:"10302336",label:"Rosal cortado fresco high and bonita"},
    {prodId:"10302337",label:"Rosal cortado fresco high and booming"},
    {prodId:"10302338",label:"Rosal cortado fresco high and fantasy"},
    {prodId:"10302339",label:"Rosal cortado fresco high and rich"},
    {prodId:"10302340",label:"Rosal cortado fresco hot lady"},
    {prodId:"10302341",label:"Rosal cortado fresco hot princess"},
    {prodId:"10302342",label:"Rosal cortado fresco inspiration"},
    {prodId:"10302343",label:"Rosal cortado fresco jeimy"},
    {prodId:"10302344",label:"Rosal cortado fresco kachita"},
    {prodId:"10302345",label:"Rosal cortado fresco karen"},
    {prodId:"10302346",label:"Rosal cortado fresco kenji"},
    {prodId:"10302347",label:"Rosal cortado fresco kiko"},
    {prodId:"10302348",label:"Rosal cortado fresco laser"},
    {prodId:"10302349",label:"Rosal cortado fresco latin duett"},
    {prodId:"10302350",label:"Rosal cortado fresco latin fever"},
    {prodId:"10302351",label:"Rosal cortado fresco lifestyle"},
    {prodId:"10302352",label:"Rosal cortado fresco light orlando"},
    {prodId:"10302353",label:"Rosal cortado fresco lovely dreams"},
    {prodId:"10302354",label:"Rosal cortado fresco loyalty"},
    {prodId:"10302355",label:"Rosal cortado fresco malibu"},
    {prodId:"10302356",label:"Rosal cortado fresco mata - hari"},
    {prodId:"10302357",label:"Rosal cortado fresco memphis"},
    {prodId:"10302358",label:"Rosal cortado fresco mi amor"},
    {prodId:"10302359",label:"Rosal cortado fresco miami"},
    {prodId:"10302360",label:"Rosal cortado fresco michelle"},
    {prodId:"10302361",label:"Rosal cortado fresco mikaela"},
    {prodId:"10302362",label:"Rosal cortado fresco orchestra"},
    {prodId:"10302363",label:"Rosal cortado fresco orlando"},
    {prodId:"10302364",label:"Rosal cortado fresco osadia"},
    {prodId:"10302365",label:"Rosal cortado fresco paeonia freelander"},
    {prodId:"10302366",label:"Rosal cortado fresco paula"},
    {prodId:"10302367",label:"Rosal cortado fresco pavarotti"},
    {prodId:"10302368",label:"Rosal cortado fresco pink intuition"},
    {prodId:"10302369",label:"Rosal cortado fresco poison"},
    {prodId:"10302370",label:"Rosal cortado fresco princess"},
    {prodId:"10302371",label:"Rosal cortado fresco queen mary"},
    {prodId:"10302372",label:"Rosal cortado fresco raphaela"},
    {prodId:"10302373",label:"Rosal cortado fresco raspberry"},
    {prodId:"10302374",label:"Rosal cortado fresco ravel"},
    {prodId:"10302375",label:"Rosal cortado fresco riviera"},
    {prodId:"10302376",label:"Rosal cortado fresco sade"},
    {prodId:"10302377",label:"Rosal cortado fresco sashimi"},
    {prodId:"10302378",label:"Rosal cortado fresco shanya"},
    {prodId:"10302379",label:"Rosal cortado fresco shocking versilia"},
    {prodId:"10302380",label:"Rosal cortado fresco solitaire"},
    {prodId:"10302381",label:"Rosal cortado fresco something different"},
    {prodId:"10302382",label:"Rosal cortado fresco splendid renate"},
    {prodId:"10302383",label:"Rosal cortado fresco star"},
    {prodId:"10302384",label:"Rosal cortado fresco sweet candia"},
    {prodId:"10302385",label:"Rosal cortado fresco sweet moments"},
    {prodId:"10302386",label:"Rosal cortado fresco sweet unique"},
    {prodId:"10302387",label:"Rosal cortado fresco taboo"},
    {prodId:"10302388",label:"Rosal cortado fresco timona"},
    {prodId:"10302389",label:"Rosal cortado fresco topaz"},
    {prodId:"10302390",label:"Rosal cortado fresco vogue"},
    {prodId:"10302391",label:"Rosal cortado fresco voila"},
    {prodId:"10302392",label:"Rosal cortado fresco wild"},
    {prodId:"10302393",label:"Rosal cortado fresco yves piaget"},
    {prodId:"10302500",label:"Rosas cortadas frescas sweetheart"},
    {prodId:"10302501",label:"Rosal cortado fresco burgundy sweetheart"},
    {prodId:"10302502",label:"Rosal cortado fresco cream sweetheart"},
    {prodId:"10302503",label:"Rosal cortado fresco hot pink sweetheart"},
    {prodId:"10302504",label:"Rosal cortado fresco lavender sweetheart"},
    {prodId:"10302505",label:"Rosal cortado fresco light pink sweetheart"},
    {prodId:"10302506",label:"Rosal cortado fresco orange sweetheart"},
    {prodId:"10302507",label:"Rosal cortado fresco peach sweetheart"},
    {prodId:"10302508",label:"Rosal cortado fresco red sweetheart"},
    {prodId:"10302509",label:"Rosal cortado fresco white sweetheart"},
    {prodId:"10302510",label:"Rosal cortado fresco yelllow sweetheart"},
    {prodId:"10302600",label:"Rosas cortadas frescas blancos"},
    {prodId:"10302601",label:"Rosal cortado fresco absolut"},
    {prodId:"10302602",label:"Rosal cortado fresco aida"},
    {prodId:"10302603",label:"Rosal cortado fresco akito"},
    {prodId:"10302604",label:"Rosal cortado fresco amelia"},
    {prodId:"10302605",label:"Rosal cortado fresco anastasia"},
    {prodId:"10302606",label:"Rosal cortado fresco andean crystal"},
    {prodId:"10302607",label:"Rosal cortado fresco angel"},
    {prodId:"10302608",label:"Rosal cortado fresco annemarie"},
    {prodId:"10302609",label:"Rosal cortado fresco avalanche"},
    {prodId:"10302610",label:"Rosal cortado fresco bianca"},
    {prodId:"10302611",label:"Rosal cortado fresco blizzard"},
    {prodId:"10302612",label:"Rosal cortado fresco bridal akito"},
    {prodId:"10302613",label:"Rosal cortado fresco domenica"},
    {prodId:"10302614",label:"Rosal cortado fresco escimo"},
    {prodId:"10302615",label:"Rosal cortado fresco farfalla"},
    {prodId:"10302616",label:"Rosal cortado fresco high and peace"},
    {prodId:"10302617",label:"Rosal cortado fresco high and pure"},
    {prodId:"10302618",label:"Rosal cortado fresco inocencia o innocenti"},
    {prodId:"10302619",label:"Rosal cortado fresco ivory"},
    {prodId:"10302620",label:"Rosal cortado fresco mondial"},
    {prodId:"10302621",label:"Rosal cortado fresco mount everest"},
    {prodId:"10302622",label:"Rosal cortado fresco nova zembla"},
    {prodId:"10302623",label:"Rosal cortado fresco patience o auspator"},
    {prodId:"10302624",label:"Rosal cortado fresco polar star"},
    {prodId:"10302625",label:"Rosal cortado fresco polo"},
    {prodId:"10302626",label:"Rosal cortado fresco proud"},
    {prodId:"10302627",label:"Rosal cortado fresco snowy jewel"},
    {prodId:"10302628",label:"Rosal cortado fresco tibet"},
    {prodId:"10302629",label:"Rosal cortado fresco tineke"},
    {prodId:"10302630",label:"Rosal cortado fresco vitality"},
    {prodId:"10302631",label:"Rosal cortado fresco white cadillac"},
    {prodId:"10302632",label:"Rosal cortado fresco white dove"},
    {prodId:"10302800",label:"Rosas cortadas frescas spray"},
    {prodId:"10302801",label:"Rosal cortado fresco alegría spray"},
    {prodId:"10302802",label:"Rosal cortado fresco andrea follies"},
    {prodId:"10302803",label:"Rosal cortado fresco antara follies"},
    {prodId:"10302804",label:"Rosal cortado fresco arrow follies"},
    {prodId:"10302805",label:"Rosal cortado fresco babe spray"},
    {prodId:"10302806",label:"Rosal cortado fresco bellina collection"},
    {prodId:"10302807",label:"Rosal cortado fresco blue moon"},
    {prodId:"10302808",label:"Rosal cortado fresco chablis spray"},
    {prodId:"10302809",label:"Rosal cortado fresco cherry follies"},
    {prodId:"10302810",label:"Rosal cortado fresco chess spray"},
    {prodId:"10302811",label:"Rosal cortado fresco classic lydia spray"},
    {prodId:"10302812",label:"Rosal cortado fresco cream gracia spray"},
    {prodId:"10302813",label:"Rosal cortado fresco cream lydia srpay"},
    {prodId:"10302814",label:"Rosal cortado fresco cream sensation spray"},
    {prodId:"10302815",label:"Rosal cortado fresco cremita spray"},
    {prodId:"10302816",label:"Rosal cortado fresco diablo spray"},
    {prodId:"10302817",label:"Rosal cortado fresco electra spray"},
    {prodId:"10302818",label:"Rosal cortado fresco fire king spray"},
    {prodId:"10302819",label:"Rosal cortado fresco fleurspray"},
    {prodId:"10302820",label:"Rosal cortado fresco girlie follies spray"},
    {prodId:"10302821",label:"Rosal cortado fresco giselle follies spray"},
    {prodId:"10302822",label:"Rosal cortado fresco golden collection spray"},
    {prodId:"10302823",label:"Rosal cortado fresco golden mimi spray"},
    {prodId:"10302824",label:"Rosal cortado fresco gracia spray"},
    {prodId:"10302825",label:"Rosal cortado fresco hot majolica spray"},
    {prodId:"10302826",label:"Rosal cortado fresco hot pink follies spray"},
    {prodId:"10302827",label:"Rosal cortado fresco ilse spray"},
    {prodId:"10302828",label:"Rosal cortado fresco jelena spray"},
    {prodId:"10302829",label:"Rosal cortado fresco laminuette spray"},
    {prodId:"10302830",label:"Rosal cortado fresco lavender follies spray"},
    {prodId:"10302831",label:"Rosal cortado fresco limoncello spray"},
    {prodId:"10302832",label:"Rosal cortado fresco little silver spray"},
    {prodId:"10302833",label:"Rosal cortado fresco lovely lydia spray"},
    {prodId:"10302834",label:"Rosal cortado fresco lucy spray"},
    {prodId:"10302835",label:"Rosal cortado fresco lydia spray"},
    {prodId:"10302836",label:"Rosal cortado fresco macarena spray"},
    {prodId:"10302837",label:"Rosal cortado fresco magic sensation spray"},
    {prodId:"10302838",label:"Rosal cortado fresco majolica spray"},
    {prodId:"10302839",label:"Rosal cortado fresco mambo número 5 spray"},
    {prodId:"10302840",label:"Rosal cortado fresco mambo spray"},
    {prodId:"10302841",label:"Rosal cortado fresco marlene spray"},
    {prodId:"10302842",label:"Rosal cortado fresco mimi eden spray"},
    {prodId:"10302843",label:"Rosal cortado fresco minou spray"},
    {prodId:"10302844",label:"Rosal cortado fresco nikita spray"},
    {prodId:"10302845",label:"Rosal cortado fresco novel collection spray"},
    {prodId:"10302846",label:"Rosal cortado fresco orange success spray"},
    {prodId:"10302847",label:"Rosal cortado fresco pepita spray"},
    {prodId:"10302848",label:"Rosal cortado fresco pink flash spray"},
    {prodId:"10302849",label:"Rosal cortado fresco pink sensation spray"},
    {prodId:"10302850",label:"Rosal cortado fresco porcelina spray"},
    {prodId:"10302851",label:"Rosal cortado fresco princess spray"},
    {prodId:"10302852",label:"Rosal cortado fresco purple mikado spray"},
    {prodId:"10302853",label:"Rosal cortado fresco red angel spray"},
    {prodId:"10302854",label:"Rosal cortado fresco red collection spray"},
    {prodId:"10302855",label:"Rosal cortado fresco red hero spray"},
    {prodId:"10302856",label:"Rosal cortado fresco red mikado spray"},
    {prodId:"10302857",label:"Rosal cortado fresco red vision spray"},
    {prodId:"10302858",label:"Rosal cortado fresco ritmo spray"},
    {prodId:"10302859",label:"Rosal cortado fresco romance mikado o eva spray"},
    {prodId:"10302860",label:"Rosal cortado fresco romántica follies spray"},
    {prodId:"10302861",label:"Rosal cortado fresco rubicon spray"},
    {prodId:"10302862",label:"Rosal cortado fresco rumba spray"},
    {prodId:"10302863",label:"Rosal cortado fresco salsa spray"},
    {prodId:"10302864",label:"Rosal cortado fresco sangrita spray"},
    {prodId:"10302865",label:"Rosal cortado fresco santa bárbara spray"},
    {prodId:"10302866",label:"Rosal cortado fresco sashaba spray"},
    {prodId:"10302867",label:"Rosal cortado fresco scarlett spray"},
    {prodId:"10302868",label:"Rosal cortado fresco seline spray"},
    {prodId:"10302869",label:"Rosal cortado fresco sensation spray"},
    {prodId:"10302870",label:"Rosal cortado fresco silver collection spray"},
    {prodId:"10302871",label:"Rosal cortado fresco silver sensation spray"},
    {prodId:"10302872",label:"Rosal cortado fresco snowdance spray"},
    {prodId:"10302873",label:"Rosal cortado fresco snowflake spray"},
    {prodId:"10302874",label:"Rosal cortado fresco suncity spray"},
    {prodId:"10302875",label:"Rosal cortado fresco super nova spray"},
    {prodId:"10302876",label:"Rosal cortado fresco sweet sensation spray"},
    {prodId:"10302877",label:"Rosal cortado fresco taifun o typhoon spray"},
    {prodId:"10302878",label:"Rosal cortado fresco tamango spray"},
    {prodId:"10302879",label:"Rosal cortado fresco tanger follies spray"},
    {prodId:"10302880",label:"Rosal cortado fresco tiara spray"},
    {prodId:"10302881",label:"Rosal cortado fresco tiramisú spray"},
    {prodId:"10302882",label:"Rosal cortado fresco twinkle bride spray"},
    {prodId:"10302883",label:"Rosal cortado fresco viviane spray"},
    {prodId:"10302884",label:"Rosal cortado fresco white majolica spray"},
    {prodId:"10302885",label:"Rosal cortado fresco white mikado spray"},
    {prodId:"10302886",label:"Rosal cortado fresco xentina spray"},
    {prodId:"10302887",label:"Rosal cortado fresco yellow babe spray"},
    {prodId:"10302888",label:"Rosal cortado fresco yellow follies spray"},
    {prodId:"10311500",label:"Anturios cortados frescos"},
    {prodId:"10311501",label:"Anturio cortado fresco chocolate"},
    {prodId:"10311502",label:"Anturio cortado fresco rojo oscuro"},
    {prodId:"10311503",label:"Anturio cortado fresco verde"},
    {prodId:"10311504",label:"Anturio cortado fresco rosado fuerte"},
    {prodId:"10311505",label:"Anturio cortado fresco mickey mouse"},
    {prodId:"10311506",label:"Anturio cortado fresco obake verde y blanco"},
    {prodId:"10311507",label:"Anturio cortado fresco obake rojo y verde"},
    {prodId:"10311508",label:"Anturio cortado fresco anaranjado"},
    {prodId:"10311509",label:"Anturio cortado fresco durazno"},
    {prodId:"10311510",label:"Anturio cortado fresco picasso o pecoso"},
    {prodId:"10311511",label:"Anturio cortado fresco rojo"},
    {prodId:"10311512",label:"Anturio cortado fresco splash"},
    {prodId:"10311513",label:"Anturio cortado fresco tropic fire"},
    {prodId:"10311514",label:"Anturio cortado fresco verde tulipán"},
    {prodId:"10311515",label:"Anturio cortado fresco rosado tulipán"},
    {prodId:"10311516",label:"Anturio cortado fresco púrpura tulipán"},
    {prodId:"10311517",label:"Anturio cortado fresco rojo tulipán"},
    {prodId:"10311518",label:"Anturio cortado fresco blanco"},
    {prodId:"10311519",label:"Anturio cortado fresco blanco salvaje"},
    {prodId:"10311600",label:"Allium cortado fresco"},
    {prodId:"10311601",label:"Allium cortado fresco ambassador"},
    {prodId:"10311602",label:"Allium cortado fresco ampeloprasum"},
    {prodId:"10311603",label:"Allium cortado fresco bullit o drumstick"},
    {prodId:"10311604",label:"Allium cortado fresco christophii"},
    {prodId:"10311605",label:"Allium cortado fresco cowanii"},
    {prodId:"10311606",label:"Allium cortado fresco gigante"},
    {prodId:"10311607",label:"Allium cortado fresco gladiador"},
    {prodId:"10311608",label:"Allium cortado fresco globemaster"},
    {prodId:"10311609",label:"Allium cortado fresco bola de golf"},
    {prodId:"10311610",label:"Allium cortado fresco cabello"},
    {prodId:"10311611",label:"Allium cortado fresco rosado gigante"},
    {prodId:"10311612",label:"Allium cortado fresco sensación púrpura"},
    {prodId:"10311613",label:"Allium cortado fresco sicilum hanging"},
    {prodId:"10311614",label:"Allium cortado fresco spider schubertii"},
    {prodId:"10311615",label:"Allium cortado fresco spray moly"},
    {prodId:"10311616",label:"Allium cortado fresco spray roseum"},
    {prodId:"10311617",label:"Allium cortado fresco tuberosum"},
    {prodId:"10311618",label:"Allium cortado fresco unifolium o spray"},
    {prodId:"10311619",label:"Allium cortado fresco monte everest blanco"},
    {prodId:"10311700",label:"Astromelia cortada fresca"},
    {prodId:"10311701",label:"Astromelia cortada fresca agropoli"},
    {prodId:"10311702",label:"Astromelia cortada fresca bourgogne"},
    {prodId:"10311703",label:"Astromelia cortada fresca cairo"},
    {prodId:"10311704",label:"Astromelia cortada fresca charmes"},
    {prodId:"10311705",label:"Astromelia cortada fresca cherry bay"},
    {prodId:"10311706",label:"Astromelia cortada fresca cherry white"},
    {prodId:"10311707",label:"Astromelia cortada fresca dame blanche"},
    {prodId:"10311708",label:"Astromelia cortada fresca diamond"},
    {prodId:"10311709",label:"Astromelia cortada fresca gran canaria"},
    {prodId:"10311710",label:"Astromelia cortada fresca harlekijn"},
    {prodId:"10311711",label:"Astromelia cortada fresca indian summer"},
    {prodId:"10311712",label:"Astromelia cortada fresca jamaica"},
    {prodId:"10311713",label:"Astromelia cortada fresca macondo"},
    {prodId:"10311714",label:"Astromelia cortada fresca mistique"},
    {prodId:"10311715",label:"Astromelia cortada fresca my fair"},
    {prodId:"10311716",label:"Astromelia cortada fresca new cairo"},
    {prodId:"10311717",label:"Astromelia cortada fresca nice"},
    {prodId:"10311718",label:"Astromelia cortada fresca orange bowl"},
    {prodId:"10311719",label:"Astromelia cortada fresca orange queens"},
    {prodId:"10311720",label:"Astromelia cortada fresca orange sun"},
    {prodId:"10311721",label:"Astromelia cortada fresca paris"},
    {prodId:"10311722",label:"Astromelia cortada fresca picasso"},
    {prodId:"10311723",label:"Astromelia cortada fresca pink panther"},
    {prodId:"10311724",label:"Astromelia cortada fresca prima donna"},
    {prodId:"10311725",label:"Astromelia cortada fresca red silhouette"},
    {prodId:"10311726",label:"Astromelia cortada fresca sacha"},
    {prodId:"10311727",label:"Astromelia cortada fresca salmón"},
    {prodId:"10311728",label:"Astromelia cortada fresca santiago"},
    {prodId:"10311729",label:"Astromelia cortada fresca senna"},
    {prodId:"10311730",label:"Astromelia cortada fresca snowball"},
    {prodId:"10311731",label:"Astromelia cortada fresca sublime"},
    {prodId:"10311732",label:"Astromelia cortada fresca tropicana"},
    {prodId:"10311733",label:"Astromelia cortada fresca virginia"},
    {prodId:"10311734",label:"Astromelia cortada fresca blanca"},
    {prodId:"10311900",label:"Amaryllis cortado fresco"},
    {prodId:"10311901",label:"Amaryllis cortado fresco naranja"},
    {prodId:"10311902",label:"Amaryllis cortado fresco orange nagano"},
    {prodId:"10311903",label:"Amaryllis cortado fresco pigmeo mini"},
    {prodId:"10311904",label:"Amaryllis cortado fresco red lion"},
    {prodId:"10311905",label:"Amaryllis cortado fresco rilona"},
    {prodId:"10311906",label:"Amaryllis cortado fresco royal velvet"},
    {prodId:"10311907",label:"Amaryllis cortado fresco sonatini naranja"},
    {prodId:"10311908",label:"Amaryllis cortado fresco sonatini rojo"},
    {prodId:"10311909",label:"Amaryllis cortado fresco tango"},
    {prodId:"10311910",label:"Amaryllis cortado fresco tinto night"},
    {prodId:"10312000",label:"Anémona cortada fresca"},
    {prodId:"10312001",label:"Anémona cortada fresca aubergine"},
    {prodId:"10312002",label:"Anémona cortada fresca negra"},
    {prodId:"10312003",label:"Anémona cortada fresca azul"},
    {prodId:"10312004",label:"Anémona cortada fresca cereza"},
    {prodId:"10312005",label:"Anémona cortada fresca coronaria"},
    {prodId:"10312006",label:"Anémona cortada fresca rosado fuerte"},
    {prodId:"10312007",label:"Anémona cortada fresca rosado claro"},
    {prodId:"10312008",label:"Anémona cortada fresca rosado"},
    {prodId:"10312009",label:"Anémona cortada fresca púrpura"},
    {prodId:"10312010",label:"Anémona cortada fresca roja"},
    {prodId:"10312011",label:"Anémona cortada fresca blanca"},
    {prodId:"10312300",label:"Berzelia cortada fresca"},
    {prodId:"10312301",label:"Berzelia lanuginosa cortada fresca abrotnoides"},
    {prodId:"10312302",label:"Berzelia lanuginosa cortada fresca fireball"},
    {prodId:"10312303",label:"Berzelia lanuginosa cortada fresca galpinii"},
    {prodId:"10312304",label:"Berzelia lanuginosa cortada fresca galpini o baubles"},
    {prodId:"10312305",label:"Berzelia lanuginosa cortada fresca squarrosa"},
    {prodId:"10312400",label:"Bouvaria cortada fresca"},
    {prodId:"10312401",label:"Bouvardia cortada fresca doble rosado fuerte"},
    {prodId:"10312402",label:"Bouvardia cortada fresca rosado claro"},
    {prodId:"10312403",label:"Bouvardia cortada fresca doble rosado claro"},
    {prodId:"10312404",label:"Bouvardia cortada fresca roja"},
    {prodId:"10312405",label:"Bouvardia cortada fresca blanca"},
    {prodId:"10312500",label:"Brodiaea cortada fresca"},
    {prodId:"10312501",label:"Brodiaea cortada fresca congesta"},
    {prodId:"10312502",label:"Brodiaea cortada fresca congesta lavanda"},
    {prodId:"10312503",label:"Brodiaea cortada fresca jacinta"},
    {prodId:"10312504",label:"Brodiaea cortada fresca ida maija"},
    {prodId:"10312505",label:"Brodiaea cortada fresca starlight"},
    {prodId:"10312600",label:"Calla cortada fresca"},
    {prodId:"10312601",label:"Calla cortada fresca green goddess"},
    {prodId:"10312602",label:"Calla cortada fresca ramillete albertville"},
    {prodId:"10312603",label:"Calla cortada fresca ramillete aranal"},
    {prodId:"10312604",label:"Calla cortada fresca ramillete black eyed beauty"},
    {prodId:"10312605",label:"Calla cortada fresca black star"},
    {prodId:"10312606",label:"Calla cortada fresca brisbane"},
    {prodId:"10312607",label:"Calla cortada fresca crystal blush"},
    {prodId:"10312608",label:"Calla cortada fresca crystal pink"},
    {prodId:"10312609",label:"Calla cortada fresca crystal white"},
    {prodId:"10312610",label:"Calla cortada fresca dark captain romanc"},
    {prodId:"10312611",label:"Calla cortada fresca ramillete dark mozart"},
    {prodId:"10312612",label:"Calla cortada fresca ramillete dark naomi"},
    {prodId:"10312613",label:"Calla cortada fresca ramillete deformed calla"},
    {prodId:"10312614",label:"Calla cortada fresca ramillete dordogne"},
    {prodId:"10312615",label:"Calla cortada fresca ramillete etude"},
    {prodId:"10312616",label:"Calla cortada fresca ramillete farao"},
    {prodId:"10312617",label:"Calla cortada fresca ramillete fire glow"},
    {prodId:"10312618",label:"Calla cortada fresca ramillete florex gold"},
    {prodId:"10312619",label:"Calla cortada fresca ramillete garnet glow"},
    {prodId:"10312620",label:"Calla cortada fresca ramillete hot chocolate"},
    {prodId:"10312621",label:"Calla cortada fresca ramillete lavender improved"},
    {prodId:"10312622",label:"Calla cortada fresca ramillete light cromance"},
    {prodId:"10312623",label:"Calla cortada fresca ramillete little suzy"},
    {prodId:"10312624",label:"Calla cortada fresca ramillete majestic red"},
    {prodId:"10312625",label:"Calla cortada fresca ramillete mango"},
    {prodId:"10312626",label:"Calla cortada fresca ramillete merlot"},
    {prodId:"10312627",label:"Calla cortada fresca ramillete mozart"},
    {prodId:"10312628",label:"Calla cortada fresca ramillete naomi"},
    {prodId:"10312629",label:"Calla cortada fresca ramillete night cap"},
    {prodId:"10312630",label:"Calla cortada fresca ramillete odessa"},
    {prodId:"10312631",label:"Calla cortada fresca ramillete pacific pink"},
    {prodId:"10312632",label:"Calla cortada fresca ramillete passion fruit"},
    {prodId:"10312633",label:"Calla cortada fresca ramillete picasso"},
    {prodId:"10312634",label:"Calla cortada fresca ramillete pillow talk"},
    {prodId:"10312635",label:"Calla cortada fresca ramillete pink persuation"},
    {prodId:"10312636",label:"Calla cortada fresca ramillete pisa"},
    {prodId:"10312637",label:"Calla cortada fresca ramillete pot of"},
    {prodId:"10312638",label:"Calla cortada fresca ramillete red sox"},
    {prodId:"10312639",label:"Calla cortada fresca ramillete rosa"},
    {prodId:"10312640",label:"Calla cortada fresca ramillete ruby light rose"},
    {prodId:"10312641",label:"Calla cortada fresca ramillete samur"},
    {prodId:"10312642",label:"Calla cortada fresca ramillete sapporo"},
    {prodId:"10312643",label:"Calla cortada fresca ramillete schwarzwalder"},
    {prodId:"10312644",label:"Calla cortada fresca ramillete serrada"},
    {prodId:"10312645",label:"Calla cortada fresca ramillete solemio"},
    {prodId:"10312646",label:"Calla cortada fresca ramillete sunrise"},
    {prodId:"10312647",label:"Calla cortada fresca ramillete super mac"},
    {prodId:"10312648",label:"Calla cortada fresca ramillete swan lake"},
    {prodId:"10312649",label:"Calla cortada fresca ramillete vermeer"},
    {prodId:"10312650",label:"Calla cortada fresca ramillete white butterfly"},
    {prodId:"10312651",label:"Calla cortada fresca ramillete amarilla"},
    {prodId:"10312652",label:"Calla cortada fresca ramillete yellow mozart"},
    {prodId:"10312653",label:"Calla cortada fresca ramillete white large"},
    {prodId:"10312800",label:"Celosia cortada fresca"},
    {prodId:"10312801",label:"Cresta de gallo (celosia) cortada fresca verde"},
    {prodId:"10312802",label:"Cresta de gallo (celosia) cortada fresca anaranjado"},
    {prodId:"10312803",label:"Cresta de gallo (celosia) cortada fresca rosado"},
    {prodId:"10312804",label:"Cresta de gallo (celosia) cortada fresca púrpura"},
    {prodId:"10312805",label:"Cresta de gallo (celosia) cortada fresca rojo"},
    {prodId:"10312806",label:"Cresta de gallo (celosia) cortada fresca amarillo"},
    {prodId:"10312807",label:"Cresta de gallo (celosia) cortada fresca plume light"},
    {prodId:"10312808",label:"Cresta de gallo (celosia) cortada fresca plume orange"},
    {prodId:"10312809",label:"Cresta de gallo (celosia) cortada fresca plume purple"},
    {prodId:"10312810",label:"Cresta de gallo (celosia) cortada fresca plume red"},
    {prodId:"10312811",label:"Cresta de gallo (celosia) cortada fresca plume yellow"},
    {prodId:"10312812",label:"Cresta de gallo (celosia) cortada fresca wheat pink"},
    {prodId:"10312813",label:"Cresta de gallo (celosia) cortada fresca wheat yellow"},
    {prodId:"10312900",label:"Narciso cortado fresco"},
    {prodId:"10312901",label:"Narciso cortado fresco dick wilden"},
    {prodId:"10312902",label:"Narciso cortado fresco dutch master"},
    {prodId:"10312903",label:"Narciso cortado fresco ice follies"},
    {prodId:"10312904",label:"Narciso cortado fresco ice king"},
    {prodId:"10312905",label:"Narciso cortado fresco johan strauss"},
    {prodId:"10312906",label:"Narciso cortado fresco yellow carlton"},
    {prodId:"10313000",label:"Dalia cortada fresca"},
    {prodId:"10313001",label:"Dalia cortada fresca bicolor"},
    {prodId:"10313002",label:"Dalia cortada fresca rosado fuerte"},
    {prodId:"10313003",label:"Dalia cortada fresca rosado claro"},
    {prodId:"10313004",label:"Dalia cortada fresca rosado medio"},
    {prodId:"10313005",label:"Dalia cortada fresca anaranjado"},
    {prodId:"10313006",label:"Dalia cortada fresca durazno"},
    {prodId:"10313007",label:"Dalia cortada fresca púrpura"},
    {prodId:"10313008",label:"Dalia cortada fresca roja"},
    {prodId:"10313009",label:"Dalia cortada fresca blanca"},
    {prodId:"10313010",label:"Dalia cortada fresca amarilla"},
    {prodId:"10313100",label:"Delphinium cortado fresco"},
    {prodId:"10313101",label:"Delphinium cortado fresco bella azul oscuro"},
    {prodId:"10313102",label:"Delphinium cortado fresco bella azul claro"},
    {prodId:"10313103",label:"Delphinium cortado fresco bella blanco"},
    {prodId:"10313104",label:"Delphinium cortado fresco azul sombra"},
    {prodId:"10313105",label:"Delphinium cortado fresco híbrido azul oscuro"},
    {prodId:"10313106",label:"Delphinium cortado fresco híbrido azul claro"},
    {prodId:"10313107",label:"Delphinium cortado fresco híbrido malva"},
    {prodId:"10313108",label:"Delphinium cortado fresco híbrido rosado"},
    {prodId:"10313109",label:"Delphinium cortado fresco híbrido púrpura"},
    {prodId:"10313110",label:"Delphinium cortado fresco híbrido rojo"},
    {prodId:"10313111",label:"Delphinium cortado fresco híbrido blanco"},
    {prodId:"10313112",label:"Delphinium cortado fresco princesa carolina"},
    {prodId:"10313113",label:"Delphinium cortado fresco volkerfrieden"},
    {prodId:"10313300",label:"Eremruses cortadas frescas"},
    {prodId:"10313301",label:"Eremurus cortado fresco deruyter híbrido"},
    {prodId:"10313302",label:"Eremurus cortado fresco himalaicus blanco"},
    {prodId:"10313303",label:"Eremurus cortado fresco anaranjado"},
    {prodId:"10313304",label:"Eremurus cortado fresco durazno"},
    {prodId:"10313305",label:"Eremurus cortado fresco amarillo"},
    {prodId:"10313400",label:"Brezo (erica) cortada fresca"},
    {prodId:"10313401",label:"Brezo (erica) cortado fresco campunalarus"},
    {prodId:"10313402",label:"Brezo (erica) cortado fresco conica"},
    {prodId:"10313403",label:"Brezo (erica) cortado fresco green ice"},
    {prodId:"10313404",label:"Brezo (erica) cortado fresco rosado"},
    {prodId:"10313405",label:"Brezo (erica) cortado fresco príncipe de gales"},
    {prodId:"10313500",label:"Euphorbia cortada fresca"},
    {prodId:"10313501",label:"Euphorbia cortada fresca characias"},
    {prodId:"10313502",label:"Euphorbia cortada fresca griffithii fireglow"},
    {prodId:"10313503",label:"Euphorbia cortada fresca martini"},
    {prodId:"10313504",label:"Euphorbia cortada fresca anaranjada"},
    {prodId:"10313505",label:"Euphorbia cortada fresca durazno"},
    {prodId:"10313506",label:"Euphorbia cortada fresca rosada"},
    {prodId:"10313507",label:"Euphorbia cortada fresca roja"},
    {prodId:"10313508",label:"Euphorbia cortada fresca blanca"},
    {prodId:"10313509",label:"Euphorbia cortada fresca amarilla"},
    {prodId:"10313510",label:"Euphorbia cortada fresca amarilla spurge"},
    {prodId:"10313600",label:"Fresia cortada fresca"},
    {prodId:"10313601",label:"Fresia cortada fresca crema"},
    {prodId:"10313602",label:"Fresia cortada fresca doble blanca"},
    {prodId:"10313603",label:"Fresia cortada fresca doble amarilla"},
    {prodId:"10313604",label:"Fresia cortada fresca rosado fuerte"},
    {prodId:"10313605",label:"Fresia cortada fresca lady brunet"},
    {prodId:"10313606",label:"Fresia cortada fresca lavanda"},
    {prodId:"10313607",label:"Fresia cortada fresca rosado medio"},
    {prodId:"10313608",label:"Fresia cortada fresca anaranjado"},
    {prodId:"10313609",label:"Fresia cortada fresca pimpinela"},
    {prodId:"10313610",label:"Fresia cortada fresca rosada"},
    {prodId:"10313611",label:"Fresia cortada fresca pùrpura"},
    {prodId:"10313612",label:"Fresia cortada fresca roja"},
    {prodId:"10313613",label:"Fresia cortada fresca blanca"},
    {prodId:"10313614",label:"Fresia cortada fresca amarilla"},
    {prodId:"10313700",label:"Ajedrezada cortada fresca"},
    {prodId:"10313701",label:"Ajedrezada cortada fresca acmopelata"},
    {prodId:"10313702",label:"Ajedrezada cortada fresca assyriaca"},
    {prodId:"10313703",label:"Ajedrezada cortada fresca assyriaca uva vulpis"},
    {prodId:"10313704",label:"Ajedrezada cortada fresca elysee"},
    {prodId:"10313705",label:"Ajedrezada cortada fresca imperialis anaranjada"},
    {prodId:"10313706",label:"Ajedrezada cortada fresca imperialis amarilla"},
    {prodId:"10313707",label:"Ajedrezada cortada fresca meleagris"},
    {prodId:"10313708",label:"Ajedrezada cortada fresca michailowski"},
    {prodId:"10313709",label:"Ajedrezada cortada fresca uva vulpis"},
    {prodId:"10313800",label:"Hiniesta cortada fresca"},
    {prodId:"10313801",label:"Hiniesta cortada fresca verde"},
    {prodId:"10313802",label:"Hiniesta cortada fresca rosado fuerte"},
    {prodId:"10313803",label:"Hiniesta cortada fresca lavanda"},
    {prodId:"10313804",label:"Hiniesta cortada fresca rosado claro"},
    {prodId:"10313805",label:"Hiniesta cortada fresca durazno"},
    {prodId:"10313806",label:"Hiniesta cortada fresca púrpura"},
    {prodId:"10313807",label:"Hiniesta cortada fresca blanco"},
    {prodId:"10313808",label:"Hiniesta cortada fresca amarillo"},
    {prodId:"10314000",label:"Jengibre cortado fresco"},
    {prodId:"10314001",label:"Jengibre cortado fresco  indonesio"},
    {prodId:"10314002",label:"Jengibre cortado fresco jungle king rosado"},
    {prodId:"10314003",label:"Jengibre cortado fresco jungle king rojo"},
    {prodId:"10314004",label:"Jengibre cortado fresco rosado"},
    {prodId:"10314005",label:"Jengibre cortado fresco rojo"},
    {prodId:"10314006",label:"Jengibre cortado fresco torch"},
    {prodId:"10314100",label:"Gladiolo cortado fresco"},
    {prodId:"10314101",label:"Gladiolo cortado fresco burgundy"},
    {prodId:"10314102",label:"Gladiolo cortado fresco fucsia"},
    {prodId:"10314103",label:"Gladiolo cortado fresco verde"},
    {prodId:"10314104",label:"Gladiolo cortado fresco rosado fuerte"},
    {prodId:"10314105",label:"Gladiolo cortado fresco rosado claro"},
    {prodId:"10314106",label:"Gladiolo cortado fresco anaranjado"},
    {prodId:"10314107",label:"Gladiolo cortado fresco durazno"},
    {prodId:"10314108",label:"Gladiolo cortado fresco rosado medio"},
    {prodId:"10314109",label:"Gladiolo cortado fresco púrpura"},
    {prodId:"10314110",label:"Gladiolo cortado fresco rojo bi - color"},
    {prodId:"10314111",label:"Gladiolo cortado fresco rojo"},
    {prodId:"10314112",label:"Gladiolo cortado fresco salmón"},
    {prodId:"10314113",label:"Gladiolo cortado fresco blanco"},
    {prodId:"10314114",label:"Gladiolo cortado fresco amarillo"},
    {prodId:"10314200",label:"Godetia cortada fresca"},
    {prodId:"10314201",label:"Godetia cortada fresca bicolor"},
    {prodId:"10314202",label:"Godetia cortada fresca fucsia"},
    {prodId:"10314203",label:"Godetia cortada fresca rosado fuerte"},
    {prodId:"10314204",label:"Godetia cortada fresca anaranjada"},
    {prodId:"10314205",label:"Godetia cortada fresca roja"},
    {prodId:"10314206",label:"Godetia cortada fresca blanca"},
    {prodId:"10314300",label:"Bromelia cortada fresca"},
    {prodId:"10314301",label:"Bromelia lingulata cortada fresca anaranjada"},
    {prodId:"10314302",label:"Bromelia lingulata cortada fresca roja"},
    {prodId:"10314303",label:"Bromelia lingulata cortada fresca blanca"},
    {prodId:"10314304",label:"Bromelia lingulata cortada fresca amarilla"},
    {prodId:"10314305",label:"Bromelia variegata cortada fresca"},
    {prodId:"10314500",label:"Brezo cortado fresco"},
    {prodId:"10314501",label:"Brezo cortado fresco augustine"},
    {prodId:"10314502",label:"Brezo cortado fresco érica cuatro hermanas"},
    {prodId:"10314503",label:"Brezo cortado fresco francés"},
    {prodId:"10314504",label:"Brezo cortado fresco verde"},
    {prodId:"10314505",label:"Brezo corte fresco sterling range blanco"},
    {prodId:"10314506",label:"Brezo cortado fresco rosado sunset"},
    {prodId:"10314507",label:"Brezo cortado fresco blanco"},
    {prodId:"10314600",label:"Heliconia cortada fresca"},
    {prodId:"10314601",label:"Heliconia cortada fresca bihai claw"},
    {prodId:"10314602",label:"Heliconia cortada fresca bihai flash"},
    {prodId:"10314603",label:"Heliconia cortada fresca bihai lobster claw"},
    {prodId:"10314604",label:"Heliconia cortada fresca caribea red"},
    {prodId:"10314605",label:"Heliconia cortada fresca caribea yellow"},
    {prodId:"10314606",label:"Heliconia cortada fresca christmas"},
    {prodId:"10314607",label:"Heliconia cortada fresca edge of night"},
    {prodId:"10314608",label:"Heliconia cortada fresca green bihai"},
    {prodId:"10314609",label:"Heliconia cortada fresca marginata lutea"},
    {prodId:"10314610",label:"Heliconia cortada fresca psitt fire opal"},
    {prodId:"10314611",label:"Heliconia cortada fresca psittacorum"},
    {prodId:"10314612",label:"Heliconia cortada fresca richmond red"},
    {prodId:"10314613",label:"Heliconia cortada fresca rostrata"},
    {prodId:"10314614",label:"Heliconia cortada fresca sexy pink"},
    {prodId:"10314615",label:"Heliconia cortada fresca sexy scarlett"},
    {prodId:"10314616",label:"Heliconia cortada fresca shogun"},
    {prodId:"10314617",label:"Heliconia cortada fresca small red"},
    {prodId:"10314618",label:"Heliconia cortada fresca southern cross"},
    {prodId:"10314619",label:"Heliconia cortada fresca wagneriana"},
    {prodId:"10414709",label:"Jacinto cortado seco rosado"},
    {prodId:"10414706",label:"Jacinto cortado seco lavanda"},
    {prodId:"10414704",label:"Jacinto cortado seco fucsia"},
    {prodId:"10414710",label:"Jacinto cortado seco estrella púrpura"},
    {prodId:"10414702",label:"Jacinto cortado seco durazno"},
    {prodId:"10414711",label:"Jacinto cortado seco blanco"},
    {prodId:"10414701",label:"Jacinto cortado seco bean"},
    {prodId:"10414707",label:"Jacinto cortado seco azul claro"},
    {prodId:"10414703",label:"Jacinto cortado seco azul"},
    {prodId:"10414712",label:"Jacinto cortado seco amarillo"},
    {prodId:"10414700",label:"Jacinto cortado seco"},
    {prodId:"10314708",label:"Jacinto cortado fresco rosado medio"},
    {prodId:"10314705",label:"Jacinto cortado fresco rosado fuerte"},
    {prodId:"10314800",label:"Hydrangea cortada fresca"},
    {prodId:"10314801",label:"Hydrangea cortada fresca anabella"},
    {prodId:"10314802",label:"Hydrangea cortada fresca azul antiguo"},
    {prodId:"10314803",label:"Hydrangea cortada fresca azul antiguo  o verde o nueva zelandia"},
    {prodId:"10314804",label:"Hydrangea cortada fresca verde antiguo"},
    {prodId:"10314805",label:"Hydrangea cortada fresca rosado antiguo"},
    {prodId:"10314806",label:"Hydrangea cortada fresca púrpura antiguo o nueva zelandia"},
    {prodId:"10314807",label:"Hydrangea cortada fresca aubergene o nueva zelandia"},
    {prodId:"10314808",label:"Hydrangea cortada fresca azul oscuro"},
    {prodId:"10314809",label:"Hydrangea cortada fresca rosado oscuro"},
    {prodId:"10314810",label:"Hydrangea cortada fresca púrpura oscuro"},
    {prodId:"10314811",label:"Hydrangea cortada fresca berenjena"},
    {prodId:"10314812",label:"Hydrangea cortada fresca verde teñida"},
    {prodId:"10314813",label:"Hydrangea cortada fresca limón verde"},
    {prodId:"10314814",label:"Hydrangea cortada fresca rosado fuerte"},
    {prodId:"10314815",label:"Hydrangea cortada fresca jumbo blanca"},
    {prodId:"10314816",label:"Hydrangea cortada fresca lavanda o nueva zelandia"},
    {prodId:"10314817",label:"Hydrangea cortada fresca azul claro"},
    {prodId:"10314818",label:"Hydrangea cortada fresca rosado claro grande"},
    {prodId:"10314819",label:"Hydrangea cortada fresca verde lima grande"},
    {prodId:"10314820",label:"Hydrangea cortada fresca mini verde"},
    {prodId:"10314821",label:"Hydrangea cortada fresca oakleaf"},
    {prodId:"10314822",label:"Hydrangea cortada fresca oakleaf copo de nieve"},
    {prodId:"10314823",label:"Hydrangea cortada fresca rosado teñido"},
    {prodId:"10314824",label:"Hydrangea cortada fresca rosado"},
    {prodId:"10314825",label:"Hydrangea cortada fresca púrpura o nueva zelandia"},
    {prodId:"10314826",label:"Hydrangea cortada fresca rojo teñido"},
    {prodId:"10314827",label:"Hydrangea cortada fresca shocking blue"},
    {prodId:"10314828",label:"Hydrangea cortada fresca tardiva"},
    {prodId:"10314829",label:"Hydrangea cortada fresca blanca"},
    {prodId:"10314900",label:"Iris cortada fresca"},
    {prodId:"10314901",label:"Iris cortada fresca negra barbada"},
    {prodId:"10314902",label:"Iris cortada fresca azul barbada"},
    {prodId:"10314903",label:"Iris cortada fresca lavanda barbada"},
    {prodId:"10314904",label:"Iris cortada fresca barbada azul clara"},
    {prodId:"10314905",label:"Iris cortada fresca barbada púrpura"},
    {prodId:"10314906",label:"Iris cortada fresca barbada roja"},
    {prodId:"10314907",label:"Iris cortada fresca barbada blanca"},
    {prodId:"10314908",label:"Iris cortada fresca baerbada blanca y púrpura"},
    {prodId:"10314909",label:"Iris cortada fresca amarilla barbada"},
    {prodId:"10314910",label:"Iris cortada fresca blue elegance"},
    {prodId:"10314911",label:"Iris cortada fresca casablanca"},
    {prodId:"10314912",label:"Iris cortada fresca golden beau"},
    {prodId:"10314913",label:"Iris cortada fresca hildegard"},
    {prodId:"10314914",label:"Iris cortada fresca hong kong"},
    {prodId:"10314915",label:"Iris cortada fresca ideal"},
    {prodId:"10314916",label:"Iris cortada fresca professor blue"},
    {prodId:"10314917",label:"Iris cortada fresca purple"},
    {prodId:"10314918",label:"Iris cortada fresca spuria"},
    {prodId:"10314919",label:"Iris cortada fresca telstar"},
    {prodId:"10315000",label:"Pata de canguro cortada fresca"},
    {prodId:"10315001",label:"Pata de canguro cortada fresca bicolor"},
    {prodId:"10315002",label:"Pata de canguro cortada fresca negra"},
    {prodId:"10315003",label:"Pata de canguro cortada fresca verde"},
    {prodId:"10315004",label:"Pata de canguro cortada fresca anaranjada"},
    {prodId:"10315005",label:"Pata de canguro cortada fresca rosada"},
    {prodId:"10315006",label:"Pata de canguro cortada fresca roja"},
    {prodId:"10315007",label:"Pata de canguro cortada fresca amarilla"},
    {prodId:"10315200",label:"Lepto cortado fresco"},
    {prodId:"10315201",label:"Lepto cortado fresco azul o floreciente"},
    {prodId:"10315202",label:"Lepto cortado fresco rosado fuerte"},
    {prodId:"10315203",label:"Lepto cortado fresco rosado claro"},
    {prodId:"10315204",label:"Lepto cortado fresco rosado"},
    {prodId:"10315205",label:"Lepto cortado fresco rojo"},
    {prodId:"10315206",label:"Lepto cortado fresco blanco"},
    {prodId:"10315300",label:"Lila cortada fresca"},
    {prodId:"10315301",label:"Lila  cortada fresca híbrida francesa lavanda"},
    {prodId:"10315302",label:"Lila  cortada fresca híbrida francesa púrpura"},
    {prodId:"10315303",label:"Lila  cortada fresca púrpura"},
    {prodId:"10315304",label:"Lila  cortada fresca vino"},
    {prodId:"10315305",label:"Lila  cortada fresca blanca"},
    {prodId:"10315306",label:"Lila  cortada fresca blanca salvaje"},
    {prodId:"10315400",label:"Azucena cortada fresca"},
    {prodId:"10315401",label:"Azucena cortada fresca alteza longiflorum e híbrido asiática"},
    {prodId:"10315402",label:"Azucena cortada fresca black out asiática"},
    {prodId:"10315403",label:"Azucena cortada fresca rosado oscuro asiática"},
    {prodId:"10315404",label:"Azucena cortada fresca leéctrica asiática"},
    {prodId:"10315405",label:"Azucena cortada fresca festival asiática"},
    {prodId:"10315406",label:"Azucena cortada fresca ginebra asiática"},
    {prodId:"10315407",label:"Azucena cortada fresca rosado claro asiática"},
    {prodId:"10315408",label:"Azucena cortada fresca colombina asiática"},
    {prodId:"10315409",label:"Azucena cortada fresca miss américa púrpura asiática"},
    {prodId:"10315410",label:"Azucena cortada fresca monte negro asiática"},
    {prodId:"10315411",label:"Azucena cortada fresca anaranjada asiática"},
    {prodId:"10315412",label:"Azucena cortada fresca durazno cannes asiática"},
    {prodId:"10315413",label:"Azucena cortada fresca risada asiática"},
    {prodId:"10315414",label:"Azucena cortada fresca sancerre asiátia"},
    {prodId:"10315415",label:"Azucena cortada fresca white dream asiática"},
    {prodId:"10315416",label:"Azucena cortada fresca amarilla asiática"},
    {prodId:"10315417",label:"Azucena cortada fresca diamante brillante longiflorum e híbrido asiática"},
    {prodId:"10315418",label:"Azucena cortada fresca brindisi longiflorum e híbrido asiática"},
    {prodId:"10315419",label:"Azucena cortada fresca carmine longiflorum e híbrido asiática"},
    {prodId:"10315420",label:"Azucena cortada fresca cinnabar longiflorum e híbrido asiática"},
    {prodId:"10315421",label:"Azucena cortada fresca club longiflorum e híbrido asiática"},
    {prodId:"10315422",label:"Azucena cortada fresca discovery longiflorum e híbrido asiática"},
    {prodId:"10315423",label:"Azucena cortada fresca de pascua"},
    {prodId:"10315424",label:"Azucena cortada fresca isis longiflorum e híbrido asiática"},
    {prodId:"10315425",label:"Azucena cortada fresca la hybrid justice longiflorum e híbrido asiática"},
    {prodId:"10315426",label:"Azucena cortada fresca lace longiflorum e híbrido asiática"},
    {prodId:"10315427",label:"Azucena cortada fresca lirio de los valles"},
    {prodId:"10315428",label:"Azucena cortada fresca love longiflorum e híbrido asiática"},
    {prodId:"10315429",label:"Azucena cortada fresca menorca longiflorum e híbrido asiática"},
    {prodId:"10315430",label:"Azucena cortada fresca oriental acapulco"},
    {prodId:"10315431",label:"Azucena cortada fresca oriental albion"},
    {prodId:"10315432",label:"Azucena cortada fresca oriental argentina"},
    {prodId:"10315433",label:"Azucena cortada fresca oriental auratum"},
    {prodId:"10315434",label:"Azucena cortada fresca oriental barbaresco"},
    {prodId:"10315435",label:"Azucena cortada fresca oriental bernini"},
    {prodId:"10315436",label:"Azucena cortada fresca oriental beseno"},
    {prodId:"10315437",label:"Azucena cortada fresca oriental broadway"},
    {prodId:"10315438",label:"Azucena cortada fresca oriental canada"},
    {prodId:"10315439",label:"Azucena cortada fresca oriental casablanca"},
    {prodId:"10315440",label:"Azucena cortada fresca oriental chili"},
    {prodId:"10315441",label:"Azucena cortada fresca oriental chrystal"},
    {prodId:"10315442",label:"Azucena cortada fresca oriental cobra"},
    {prodId:"10315443",label:"Azucena cortada fresca oriental conca d' or"},
    {prodId:"10315444",label:"Azucena cortada fresca oriental cote d' ivor"},
    {prodId:"10315445",label:"Azucena cortada fresca oriental dizzy"},
    {prodId:"10315446",label:"Azucena cortada fresca oriental fireball"},
    {prodId:"10315447",label:"Azucena cortada fresca oriental gluhwein"},
    {prodId:"10315448",label:"Azucena cortada fresca oriental goldband"},
    {prodId:"10315449",label:"Azucena cortada fresca oriental halifax"},
    {prodId:"10315450",label:"Azucena cortada fresca oriental kathryn"},
    {prodId:"10315451",label:"Azucena cortada fresca oriental kyoto"},
    {prodId:"10315452",label:"Azucena cortada fresca oriental la mancha"},
    {prodId:"10315453",label:"Azucena cortada fresca oriental medusa"},
    {prodId:"10315454",label:"Azucena cortada fresca oriental montezuma"},
    {prodId:"10315455",label:"Azucena cortada fresca oriental muscadet"},
    {prodId:"10315456",label:"Azucena cortada fresca oriental nippon"},
    {prodId:"10315457",label:"Azucena cortada fresca oriental opus one"},
    {prodId:"10315458",label:"Azucena cortada fresca oriental pompeii"},
    {prodId:"10315459",label:"Azucena cortada fresca oriental rialto"},
    {prodId:"10315460",label:"Azucena cortada fresca oriental robina"},
    {prodId:"10315461",label:"Azucena cortada fresca oriental rousilon"},
    {prodId:"10315462",label:"Azucena cortada fresca oriental siberia"},
    {prodId:"10315463",label:"Azucena cortada fresca oriental sorbonne"},
    {prodId:"10315464",label:"Azucena cortada fresca oriental starfighter"},
    {prodId:"10315465",label:"Azucena cortada fresca oriental stargazer"},
    {prodId:"10315466",label:"Azucena cortada fresca oriental sumatra"},
    {prodId:"10315467",label:"Azucena cortada fresca oriental time out"},
    {prodId:"10315468",label:"Azucena cortada fresca oriental tom pouche"},
    {prodId:"10315469",label:"Azucena cortada fresca oriental tropical"},
    {prodId:"10315470",label:"Azucena cortada fresca oriental white cup"},
    {prodId:"10315471",label:"Azucena cortada fresca oriental white merostar"},
    {prodId:"10315472",label:"Azucena cortada fresca oriental white montana"},
    {prodId:"10315473",label:"Azucena cortada fresca oriental white stargazer"},
    {prodId:"10315474",label:"Azucena cortada fresca oriental yellow band"},
    {prodId:"10315475",label:"Azucena cortada fresca oriental yellow dream"},
    {prodId:"10315476",label:"Azucena cortada fresca oriental yellow queen"},
    {prodId:"10315477",label:"Azucena cortada fresca oriental yellow star"},
    {prodId:"10315478",label:"Azucena cortada fresca oriental yeloween"},
    {prodId:"10315479",label:"Azucena cortada fresca ot red dutch"},
    {prodId:"10315480",label:"Azucena cortada fresca sonata nimph"},
    {prodId:"10315481",label:"Azucena cortada fresca sonata shocking"},
    {prodId:"10315482",label:"Azucena cortada fresca sonata triumphateer"},
    {prodId:"10315483",label:"Azucena cortada fresca sunset longiflorum e híbrido asiática"},
    {prodId:"10315484",label:"Azucena cortada fresca de agua"},
    {prodId:"10315500",label:"Lavanda cortada fresca"},
    {prodId:"10315501",label:"Lavanda marina cortada fresca misty peach"},
    {prodId:"10315502",label:"Lavanda marina cortada fresca misty rosada"},
    {prodId:"10315503",label:"Lavanda marina cortada fresca misty blanca"},
    {prodId:"10315504",label:"Lavanda marina cortada fresca misty amarilla"},
    {prodId:"10315505",label:"Lavanda marina cortada fresca safora"},
    {prodId:"10315506",label:"Lavanda marina cortada fresca sinensis"},
    {prodId:"10315600",label:"Lisianthus cortado fresco"},
    {prodId:"10315601",label:"Lisianthus cortado fresco crema"},
    {prodId:"10315602",label:"Lisianthus cortado fresco rosado fuerte"},
    {prodId:"10315603",label:"Lisianthus cortado fresco verde"},
    {prodId:"10315604",label:"Lisianthus cortado fresco lavanda"},
    {prodId:"10315605",label:"Lisianthus cortado fresco rosado claro"},
    {prodId:"10315606",label:"Lisianthus cortado fresco mini blanco"},
    {prodId:"10315607",label:"Lisianthus cortado fresco durazno"},
    {prodId:"10315608",label:"Lisianthus cortado fresco rosado con borde blanco"},
    {prodId:"10315609",label:"Lisianthus cortado fresco púrpura"},
    {prodId:"10315610",label:"Lisianthus cortado fresco púrpura con borde blanco"},
    {prodId:"10315611",label:"Lisianthus cortado fresco blanco con borde rosado"},
    {prodId:"10315612",label:"Lisianthus cortado fresco blanco"},
    {prodId:"10315613",label:"Lisianthus cortado fresco blanco con borde pùrpura"},
    {prodId:"10315700",label:"Muscari cortada fresca"},
    {prodId:"10315701",label:"Muscari cortada fresca armeniacum"},
    {prodId:"10315702",label:"Muscari cortada fresca bortyoides blanca"},
    {prodId:"10315703",label:"Muscari cortada fresca verde"},
    {prodId:"10315704",label:"Muscari cortada fresca latifolia"},
    {prodId:"10315705",label:"Muscari cortada fresca valerie finn"},
    {prodId:"10315800",label:"Narciso cortado fresco"},
    {prodId:"10315801",label:"Narciso cortado fresco alegría"},
    {prodId:"10315802",label:"Narciso cortado fresco amanecer dorado"},
    {prodId:"10315803",label:"Narciso cortado fresco abba paperwhite"},
    {prodId:"10315804",label:"Narciso cortado fresco paperwhite"},
    {prodId:"10315805",label:"Narciso cortado fresco ojo de faisán"},
    {prodId:"10315806",label:"Narciso cortado fresco soleil d' or"},
    {prodId:"10315807",label:"Narciso cortado fresco tete a tete"},
    {prodId:"10315808",label:"Narciso cortado fresco thalia"},
    {prodId:"10316100",label:"Estrella de Belén cortada fresca"},
    {prodId:"10316101",label:"Estrella de belén cortada fresca arábica"},
    {prodId:"10316102",label:"Estrella de belén cortada fresca dubium anaranjada"},
    {prodId:"10316103",label:"Estrella de belén cortada fresca umbellada"},
    {prodId:"10316104",label:"Estrella de belén cortada fresca dubium blanca"},
    {prodId:"10316105",label:"Estrella de belén cortada fresca dubium amarilla"},
    {prodId:"10316200",label:"Peonia cortada fresca"},
    {prodId:"10316201",label:"Peonia cortada fresca alexander fleming"},
    {prodId:"10316202",label:"Peonia cortada fresca coral charm"},
    {prodId:"10316203",label:"Peonia cortada fresca suset"},
    {prodId:"10316204",label:"Peonia cortada fresca coral supreme"},
    {prodId:"10316205",label:"Peonia cortada fresca gardenia doble"},
    {prodId:"10316206",label:"Peonia cortada fresca doble jues eli oscura"},
    {prodId:"10316207",label:"Peonia cortada fresca soble duquesa blanca"},
    {prodId:"10316208",label:"Peonia cortada fresca felix crousse"},
    {prodId:"10316209",label:"Peonia cortada fresca festiva máxima"},
    {prodId:"10316210",label:"Peonia cortada fresca tesoro del jardín"},
    {prodId:"10316211",label:"Peonia cortada fresca kansas rosado oscuro"},
    {prodId:"10316212",label:"Peonia cortada fresca karl rosenfelt"},
    {prodId:"10316213",label:"Peonia cortada fresca paula fay"},
    {prodId:"10316214",label:"Peonia cortada fresca encanto rojo"},
    {prodId:"10316215",label:"Peonia cortada fresca pasion roja"},
    {prodId:"10316216",label:"Peonia cortada fresca sarah bernhardt roja"},
    {prodId:"10316217",label:"Peonia cortada fresca scarlet o' hara"},
    {prodId:"10316218",label:"Peonia cortada fresca shirley temple"},
    {prodId:"10316300",label:"Banksia cortada fresca"},
    {prodId:"10316301",label:"Banksia cortada fresca ashbyi"},
    {prodId:"10316302",label:"Banksia cortada fresca baxteri"},
    {prodId:"10316306",label:"Banksia cortada fresca coccinea"},
    {prodId:"10316311",label:"Banksia cortada fresca ericifolia"},
    {prodId:"10316315",label:"Banksia cortada fresca verde"},
    {prodId:"10316322",label:"Banksia cortada fresca menziesii"},
    {prodId:"10316325",label:"Banksia cortada fresca blanco natural"},
    {prodId:"10316326",label:"Banksia cortada fresca anaranjado"},
    {prodId:"10316332",label:"Banksia cortada fresca rosada"},
    {prodId:"10316400",label:"Ranúculo cortado fresco"},
    {prodId:"10316401",label:"Ranúnculo cortado fresco chocolate"},
    {prodId:"10316402",label:"Ranúnculo cortado fresco elegancia"},
    {prodId:"10316403",label:"Ranúnculo cortado fresco verde"},
    {prodId:"10316404",label:"Ranúnculo cortado fresco grimaldi"},
    {prodId:"10316405",label:"Ranúnculo cortado fresco rosado fuerte"},
    {prodId:"10316406",label:"Ranúnculo cortado fresco rosado claro"},
    {prodId:"10316407",label:"Ranúnculo cortado fresco anaranjado"},
    {prodId:"10316408",label:"Ranúnculo cortado fresco centro verde"},
    {prodId:"10316409",label:"Ranúnculo cortado fresco rosado"},
    {prodId:"10316410",label:"Ranúnculo cortado fresco rojo"},
    {prodId:"10316411",label:"Ranúnculo cortado fresco blanco"},
    {prodId:"10316412",label:"Ranúnculo cortado fresco amarillo"},
    {prodId:"10316413",label:"Ranúnculo cortado fresco salmón"},
    {prodId:"10316600",label:"Retama de escobas cortada fresca"},
    {prodId:"10316601",label:"Retama de escobas cortada fresca rosada"},
    {prodId:"10316602",label:"Retama de escobas cortada fresca púrpura"},
    {prodId:"10316603",label:"Retama de escobas cortada fresca blanca"},
    {prodId:"10316604",label:"Retama de escobas cortada fresca amarilla"},
    {prodId:"10317300",label:"Tulipán cortado fresco"},
    {prodId:"10317301",label:"Tulipán cortado fresco adrem"},
    {prodId:"10317302",label:"Tulipán cortado fresco apricot"},
    {prodId:"10317303",label:"Tulipán cortado fresco bicolor rojo y amarillo"},
    {prodId:"10317304",label:"Tulipán cortado fresco doble bicolor"},
    {prodId:"10317305",label:"Tulipán cortado fresco doble rosado"},
    {prodId:"10317306",label:"Tulipán cortado fresco doble rojo"},
    {prodId:"10317307",label:"Tulipán cortado fresco doble blanco"},
    {prodId:"10317308",label:"Tulipán cortado fresco doble amarillo"},
    {prodId:"10317309",label:"Tulipán cortado fresco francés avignon"},
    {prodId:"10317310",label:"Tulipán cortado fresco francés camarque"},
    {prodId:"10317311",label:"Tulipán cortado fresco francés dordogne"},
    {prodId:"10317312",label:"Tulipán cortado fresco francés fiat"},
    {prodId:"10317313",label:"Tulipán cortado fresco francés flamboyant"},
    {prodId:"10317314",label:"Tulipán cortado fresco francés flaming parrot"},
    {prodId:"10317315",label:"Tulipán cortado fresco francés florissa"},
    {prodId:"10317316",label:"Tulipán cortado fresco francés doble maureen"},
    {prodId:"10317317",label:"Tulipán cortado fresco francés maureen"},
    {prodId:"10317318",label:"Tulipán cortado fresco francés menton"},
    {prodId:"10317319",label:"Tulipán cortado fresco francés montpellier"},
    {prodId:"10317320",label:"Tulipán cortado fresco francés naranja unique"},
    {prodId:"10317321",label:"Tulipán cortado fresco francés peonia reconocido único"},
    {prodId:"10317322",label:"Tulipán cortado fresco francés loro rosado"},
    {prodId:"10317323",label:"Tulipán cortado fresco francés princesa unique"},
    {prodId:"10317324",label:"Tulipán cortado fresco francés reconocido"},
    {prodId:"10317325",label:"Tulipán cortado fresco francés schepppers"},
    {prodId:"10317326",label:"Tulipán cortado fresco francés gamuza"},
    {prodId:"10317327",label:"Tulipán cortado fresco francés toyota"},
    {prodId:"10317328",label:"Tulipán cortado fresco francés loro weber"},
    {prodId:"10317329",label:"Tulipán cortado fresco francés loro blanco"},
    {prodId:"10317330",label:"Tulipán cortado fresco lavanda de borde crespo"},
    {prodId:"10317331",label:"Tulipán cortado fresco rosado fuerte"},
    {prodId:"10317332",label:"Tulipán cortado fresco rosado fuerte hoja variegada"},
    {prodId:"10317333",label:"Tulipán cortado fresco lavanda"},
    {prodId:"10317334",label:"Tulipán cortado fresco rosado claro hoja variegada"},
    {prodId:"10317335",label:"Tulipán cortado fresco viuda alegre"},
    {prodId:"10317336",label:"Tulipán cortado fresco anaranjado"},
    {prodId:"10317337",label:"Tulipán cortado fresco loro negro"},
    {prodId:"10317338",label:"Tulipán cortado fresco loro estela rijnveld"},
    {prodId:"10317339",label:"Tulipán cortado fresco llameante"},
    {prodId:"10317340",label:"Tulipán cortado fresco loro verde"},
    {prodId:"10317341",label:"Tulipán cortado fresco loro lavanda"},
    {prodId:"10317342",label:"Tulipán cortado fresco loro anaranjado"},
    {prodId:"10317343",label:"Tulipán cortado fresco loro durazno"},
    {prodId:"10317344",label:"Tulipán cortado fresco loro rosado"},
    {prodId:"10317345",label:"Tulipán cortado fresco loro rojo"},
    {prodId:"10317346",label:"Tulipán cortado fresco loro rojo rococó"},
    {prodId:"10317347",label:"Tulipán cortado fresco loro weber"},
    {prodId:"10317348",label:"Tulipán cortado fresco loro blanco"},
    {prodId:"10317349",label:"Tulipán cortado fresco loro rosado"},
    {prodId:"10317350",label:"Tulipán cortado fresco rosado"},
    {prodId:"10317351",label:"Tulipán cortado fresco púrpura"},
    {prodId:"10317352",label:"Tulipán cortado fresco rojo"},
    {prodId:"10317353",label:"Tulipán cortado fresco especias"},
    {prodId:"10317354",label:"Tulipán cortado fresco blanco"},
    {prodId:"10317355",label:"Tulipán cortado fresco amarillo"},
    {prodId:"10317500",label:"Hierba de aquiles cortada fresca"},
    {prodId:"10317501",label:"Hierba de aquiles cortada fresca burgundy"},
    {prodId:"10317502",label:"Hierba de aquiles cortada fresca crema campesina"},
    {prodId:"10317503",label:"Hierba de aquiles cortada fresca rosado campesino"},
    {prodId:"10317504",label:"Hierba de aquiles cortada fresca moonshine"},
    {prodId:"10317505",label:"Hierba de aquiles cortada fresca anaranjado"},
    {prodId:"10317506",label:"Hierba de aquiles cortada fresca durazno"},
    {prodId:"10317507",label:"Hierba de aquiles cortada fresca rosada"},
    {prodId:"10317508",label:"Hierba de aquiles cortada fresca rojo teñido"},
    {prodId:"10317509",label:"Hierba de aquiles cortada fresca blanca"},
    {prodId:"10317510",label:"Hierba de aquiles cortada fresca amarilla"},
    {prodId:"10317600",label:"Zinia cortada fresca"},
    {prodId:"10317601",label:"Zinia cortada fresca amarilla"},
    {prodId:"10317602",label:"Zinia cortada fresca mini"},
    {prodId:"10317603",label:"Zinia cortada fresca rosado"},
    {prodId:"10317604",label:"Zinia cortada fresca rojo"},
    {prodId:"10317605",label:"Zinia cortada fresca salmón"},
    {prodId:"10317606",label:"Zinia cortada fresca amarillo"},
    {prodId:"10317700",label:"Forsythia cortada fresca"},
    {prodId:"10317701",label:"Forsythia cortada fresca viridissima"},
    {prodId:"10317702",label:"Forsythia cortada fresca giraldiana"},
    {prodId:"10317703",label:"Forsythia cortada fresca mira"},
    {prodId:"10317704",label:"Forsythia cortada fresca suspensa"},
    {prodId:"10317705",label:"Forsythia cortada fresca intermedia"},
    {prodId:"10317706",label:"Forsythia cortada fresca variabilis"},
    {prodId:"10317707",label:"Forsythia cortada fresca ovate"},
    {prodId:"10317708",label:"Forsythia cortada fresca intermedia lynwood"},
    {prodId:"10317800",label:"Geranio cortado fresco"},
    {prodId:"10317801",label:"Geranio cortado fresco argenteum"},
    {prodId:"10317802",label:"Geranio cortado fresco cinereum"},
    {prodId:"10317803",label:"Geranio cortado fresco clarkei"},
    {prodId:"10317804",label:"Geranio cortado fresco dalmaticum"},
    {prodId:"10317805",label:"Geranio cortado fresco endressii"},
    {prodId:"10317806",label:"Geranio cortado fresco eriostemon"},
    {prodId:"10317807",label:"Geranio cortado fresco farreri"},
    {prodId:"10317808",label:"Geranio cortado fresco himalayense o grandiflora"},
    {prodId:"10317809",label:"Geranio cortado fresco ibericum"},
    {prodId:"10317810",label:"Geranio cortado fresco macrorhizum o raiz grande"},
    {prodId:"10317811",label:"Geranio cortado fresco maculatum"},
    {prodId:"10317812",label:"Geranio cortado fresco nodosum"},
    {prodId:"10317813",label:"Geranio cortado fresco phaeum"},
    {prodId:"10317814",label:"Geranio cortado fresco platypetalum"},
    {prodId:"10317815",label:"Geranio cortado fresco pratense"},
    {prodId:"10317816",label:"Geranio cortado fresco procurrens"},
    {prodId:"10317817",label:"Geranio cortado fresco psilostemon"},
    {prodId:"10317818",label:"Geranio cortado fresco pylzowianum"},
    {prodId:"10317819",label:"Geranio cortado fresco renardii"},
    {prodId:"10317820",label:"Geranio cortado fresco sanguineum o sangruento"},
    {prodId:"10317821",label:"Geranio cortado fresco sylvaticum"},
    {prodId:"10317822",label:"Geranio cortado fresco traversii"},
    {prodId:"10317823",label:"Geranio cortado fresco tuberosum"},
    {prodId:"10317824",label:"Geranio cortado fresco versicolor"},
    {prodId:"10317825",label:"Geranio cortado fresco wallachianum"},
    {prodId:"10317826",label:"Geranio cortado fresco wlassovianum"},
    {prodId:"10317827",label:"Geranio cortado fresco x magnificum o llamativo"},
    {prodId:"10317900",label:"Amaryllis cortada fresca"},
    {prodId:"10317901",label:"Amaryllis cortada fresca aglaiae"},
    {prodId:"10317902",label:"Amaryllis cortada fresca amaru"},
    {prodId:"10317903",label:"Amaryllis cortada fresca angustifolium"},
    {prodId:"10317904",label:"Amaryllis cortada fresca anzaldoi"},
    {prodId:"10317905",label:"Amaryllis cortada fresca araripinum"},
    {prodId:"10317906",label:"Amaryllis cortada fresca arboricola"},
    {prodId:"10317907",label:"Amaryllis cortada fresca argentinum"},
    {prodId:"10317908",label:"Amaryllis cortada fresca aulicum"},
    {prodId:"10317909",label:"Amaryllis cortada fresca aviflorum"},
    {prodId:"10317910",label:"Amaryllis cortada fresca barreirasum"},
    {prodId:"10317911",label:"Amaryllis cortada fresca blossfeldiae"},
    {prodId:"10317912",label:"Amaryllis cortada fresca blumenavium"},
    {prodId:"10317913",label:"Amaryllis cortada fresca brasilianum"},
    {prodId:"10317914",label:"Amaryllis cortada fresca breviflorum"},
    {prodId:"10317915",label:"Amaryllis cortada fresca bukasovii"},
    {prodId:"10317916",label:"Amaryllis cortada fresca calyptratum"},
    {prodId:"10317917",label:"Amaryllis cortada fresca caupolicanense"},
    {prodId:"10317918",label:"Amaryllis cortada fresca chionedyanthum"},
    {prodId:"10317919",label:"Amaryllis cortada fresca condemaita"},
    {prodId:"10317920",label:"Amaryllis cortada fresca corriense"},
    {prodId:"10317921",label:"Amaryllis cortada fresca cuzcoense"},
    {prodId:"10317922",label:"Amaryllis cortada fresca curitibanum"},
    {prodId:"10317923",label:"Amaryllis cortada fresca cybister"},
    {prodId:"10317924",label:"Amaryllis cortada fresca divijuliani"},
    {prodId:"10317925",label:"Amaryllis cortada fresca evansiae"},
    {prodId:"10317926",label:"Amaryllis cortada fresca ferreyrae"},
    {prodId:"10317927",label:"Amaryllis cortada fresca forgetii"},
    {prodId:"10317928",label:"Amaryllis cortada fresca fosteri"},
    {prodId:"10317929",label:"Amaryllis cortada fresca fuscum"},
    {prodId:"10317930",label:"Amaryllis cortada fresca glaucescens"},
    {prodId:"10317931",label:"Amaryllis cortada fresca goianum"},
    {prodId:"10317932",label:"Amaryllis cortada fresca guarapuavicum"},
    {prodId:"10317933",label:"Amaryllis cortada fresca harrisonii"},
    {prodId:"10317934",label:"Amaryllis cortada fresca hugoi"},
    {prodId:"10317935",label:"Amaryllis cortada fresca iguazuanum"},
    {prodId:"10317936",label:"Amaryllis cortada fresca illustre"},
    {prodId:"10317937",label:"Amaryllis cortada fresca intiflorum"},
    {prodId:"10317938",label:"Amaryllis cortada fresca kromeri"},
    {prodId:"10317939",label:"Amaryllis cortada fresca lapacense"},
    {prodId:"10317940",label:"Amaryllis cortada fresca leonardii"},
    {prodId:"10317941",label:"Amaryllis cortada fresca leopoldii"},
    {prodId:"10317942",label:"Amaryllis cortada fresca macbridei"},
    {prodId:"10317943",label:"Amaryllis cortada fresca machupijchense"},
    {prodId:"10317944",label:"Amaryllis cortada fresca mandonii"},
    {prodId:"10317945",label:"Amaryllis cortada fresca minasgerais"},
    {prodId:"10317946",label:"Amaryllis cortada fresca miniatum"},
    {prodId:"10317947",label:"Amaryllis cortada fresca mollevillquense"},
    {prodId:"10317948",label:"Amaryllis cortada fresca morelianum"},
    {prodId:"10317949",label:"Amaryllis cortada fresca nelsonii"},
    {prodId:"10317950",label:"Amaryllis cortada fresca oconoquense"},
    {prodId:"10317951",label:"Amaryllis cortada fresca papilio"},
    {prodId:"10317952",label:"Amaryllis cortada fresca paquichanum"},
    {prodId:"10317953",label:"Amaryllis cortada fresca paradisiacum"},
    {prodId:"10317954",label:"Amaryllis cortada fresca pardinum"},
    {prodId:"10317955",label:"Amaryllis cortada fresca parodii"},
    {prodId:"10317956",label:"Amaryllis cortada fresca petiolatum"},
    {prodId:"10317957",label:"Amaryllis cortada fresca psittacinum"},
    {prodId:"10317958",label:"Amaryllis cortada fresca puniceum"},
    {prodId:"10317959",label:"Amaryllis cortada fresca reginae"},
    {prodId:"10317960",label:"Amaryllis cortada fresca reticulatum"},
    {prodId:"10317961",label:"Amaryllis cortada fresca rubropictum"},
    {prodId:"10317962",label:"Amaryllis cortada fresca santacatarina"},
    {prodId:"10317963",label:"Amaryllis cortada fresca solandraeflorum"},
    {prodId:"10317964",label:"Amaryllis cortada fresca starkiorum"},
    {prodId:"10317965",label:"Amaryllis cortada fresca striatum"},
    {prodId:"10317966",label:"Amaryllis cortada fresca stylosum"},
    {prodId:"10317967",label:"Amaryllis cortada fresca teyucuarense"},
    {prodId:"10317968",label:"Amaryllis cortada fresca traubii"},
    {prodId:"10317969",label:"Amaryllis cortada fresca vargasii"},
    {prodId:"10317970",label:"Amaryllis cortada fresca variegatum"},
    {prodId:"10317971",label:"Amaryllis cortada fresca vittatum"},
    {prodId:"10317972",label:"Amaryllis cortada fresca yungacense"},
    {prodId:"10318000",label:"Rubdeckia cortada fresca"},
    {prodId:"10318001",label:"Rubdeckia cortada fresca alpicola"},
    {prodId:"10318002",label:"Rubdeckia cortada fresca amplexicaulis"},
    {prodId:"10318003",label:"Rubdeckia cortada fresca auriculata"},
    {prodId:"10318004",label:"Rubdeckia cortada fresca bicolor"},
    {prodId:"10318005",label:"Rubdeckia cortada fresca californica"},
    {prodId:"10318006",label:"Rubdeckia cortada fresca fulgida"},
    {prodId:"10318007",label:"Rubdeckia cortada fresca glaucescens"},
    {prodId:"10318008",label:"Rubdeckia cortada fresca graminifolia"},
    {prodId:"10318009",label:"Rubdeckia cortada fresca grandiflora"},
    {prodId:"10318010",label:"Rubdeckia cortada fresca heliopsidis"},
    {prodId:"10318011",label:"Rubdeckia cortada fresca hirta"},
    {prodId:"10318012",label:"Rubdeckia cortada fresca klamathensis"},
    {prodId:"10318013",label:"Rubdeckia cortada fresca laciniata"},
    {prodId:"10318014",label:"Rubdeckia cortada fresca máxima"},
    {prodId:"10318015",label:"Rubdeckia cortada fresca missouriensis"},
    {prodId:"10318016",label:"Rubdeckia cortada fresca mohrii"},
    {prodId:"10318017",label:"Rubdeckia cortada fresca mollis"},
    {prodId:"10318018",label:"Rubdeckia cortada fresca montana"},
    {prodId:"10318019",label:"Rubdeckia cortada fresca nítida"},
    {prodId:"10318020",label:"Rubdeckia cortada fresca occidentalis"},
    {prodId:"10318021",label:"Rubdeckia cortada fresca pinnata"},
    {prodId:"10318022",label:"Rubdeckia cortada fresca scabrifolia"},
    {prodId:"10318023",label:"Rubdeckia cortada fresca serotina"},
    {prodId:"10318024",label:"Rubdeckia cortada fresca speciosa"},
    {prodId:"10318025",label:"Rubdeckia cortada fresca subtomentosa"},
    {prodId:"10318026",label:"Rubdeckia cortada fresca texana"},
    {prodId:"10318027",label:"Rubdeckia cortada fresca triloba"},
    {prodId:"10318100",label:"Protea cortada fresca"},
    {prodId:"10318101",label:"Protea cortada fresca bouquet"},
    {prodId:"10318102",label:"Protea cortada fresca cepillo botella"},
    {prodId:"10318103",label:"Protea cortada fresca carnaval"},
    {prodId:"10318104",label:"Protea cortada fresca follaje cordata"},
    {prodId:"10318105",label:"Protea cortada fresca grandiceps"},
    {prodId:"10318106",label:"Protea cortada fresca visón verde"},
    {prodId:"10318107",label:"Protea cortada fresca hiedra"},
    {prodId:"10318108",label:"Protea cortada fresca rey"},
    {prodId:"10318109",label:"Protea cortada fresca nana cones"},
    {prodId:"10318110",label:"Protea cortada fresca alfiletero anaranjada"},
    {prodId:"10318111",label:"Protea cortada fresca alfiletero tango"},
    {prodId:"10318112",label:"Protea cortada fresca alfiletero amarillo"},
    {prodId:"10318113",label:"Protea cortada fresca hielo rosado"},
    {prodId:"10318114",label:"Protea cortada fresca visón rosado"},
    {prodId:"10318115",label:"Protea cortada fresca reina"},
    {prodId:"10318116",label:"Protea cortada fresca repens"},
    {prodId:"10318117",label:"Protea cortada fresca cuchara de rosas"},
    {prodId:"10318118",label:"Protea cortada fresca silvia"},
    {prodId:"10318119",label:"Protea cortada fresca sugarbush"},
    {prodId:"10318120",label:"Protea cortada fresca susara"},
    {prodId:"10318121",label:"Protea cortada fresca waratha"},
    {prodId:"10318122",label:"Protea cortada fresca visón blanco"},
    {prodId:"10318200",label:"leucadendron cortado fresco"},
    {prodId:"10318201",label:"Leucadendron cortado fresco argenteum"},
    {prodId:"10318202",label:"Leucadendron cortado fresco delicia de crema"},
    {prodId:"10318203",label:"Leucadendron cortado fresco cumosum"},
    {prodId:"10318204",label:"Leucadendron cortado fresco discolor"},
    {prodId:"10318205",label:"Leucadendron cortado fresco galpini"},
    {prodId:"10318206",label:"Leucadendron cortado fresco mina de oro"},
    {prodId:"10318207",label:"Leucadendron cortado fresco oro inca"},
    {prodId:"10318208",label:"Leucadendron cortado fresco bufón"},
    {prodId:"10318209",label:"Leucadendron cortado fresco laxum"},
    {prodId:"10318210",label:"Leucadendron cortado fresco mini"},
    {prodId:"10318211",label:"Leucadendron cortado fresco oro patea"},
    {prodId:"10318212",label:"Leucadendron cortado fresco petra"},
    {prodId:"10318213",label:"Leucadendron cortado fresco plumosum"},
    {prodId:"10318214",label:"Leucadendron cortado fresco roseta"},
    {prodId:"10318215",label:"Leucadendron cortado fresco atardecer safari"},
    {prodId:"10318216",label:"Leucadendron cortado fresco atardecer safari spr"},
    {prodId:"10318217",label:"Leucadendron cortado fresco speciosa"},
    {prodId:"10318218",label:"Leucadendron cortado fresco spray"},
    {prodId:"10318219",label:"Leucadendron cortado fresco maravilla wilson"},
    {prodId:"10318220",label:"Leucadendron cortado fresco yarden"},
    {prodId:"10318300",label:"leucospermum cortado fresco"},
    {prodId:"10318301",label:"Leucospermum cortado fresco album"},
    {prodId:"10318302",label:"Leucospermum cortado fresco attenuatum"},
    {prodId:"10318303",label:"Leucospermum cortado fresco calligerum"},
    {prodId:"10318304",label:"Leucospermum cortado fresco conocarpodendron"},
    {prodId:"10318305",label:"Leucospermum cortado fresco cordatum"},
    {prodId:"10318306",label:"Leucospermum cortado fresco cuneiforme"},
    {prodId:"10318307",label:"Leucospermum cortado fresco formosum"},
    {prodId:"10318308",label:"Leucospermum cortado fresco glabrum"},
    {prodId:"10318309",label:"Leucospermum cortado fresco grandiflorum"},
    {prodId:"10318310",label:"Leucospermum cortado fresco harmatum"},
    {prodId:"10318311",label:"Leucospermum cortado fresco heterophyllum"},
    {prodId:"10318312",label:"Leucospermum cortado fresco innovans"},
    {prodId:"10318313",label:"Leucospermum cortado fresco muirii"},
    {prodId:"10318314",label:"Leucospermum cortado fresco oleifolium"},
    {prodId:"10318315",label:"Leucospermum cortado fresco patersonii"},
    {prodId:"10318316",label:"Leucospermum cortado fresco pluridens"},
    {prodId:"10318317",label:"Leucospermum cortado fresco praemorsum"},
    {prodId:"10318318",label:"Leucospermum cortado fresco prostratum"},
    {prodId:"10318319",label:"Leucospermum cortado fresco rodolentum"},
    {prodId:"10318320",label:"Leucospermum cortado fresco saxatile"},
    {prodId:"10318321",label:"Leucospermum cortado fresco secundifolium"},
    {prodId:"10318322",label:"Leucospermum cortado fresco tomentosus"},
    {prodId:"10318323",label:"Leucospermum cortado fresco truncatulum"},
    {prodId:"10318324",label:"Leucospermum cortado fresco utriculosum"},
    {prodId:"10318325",label:"Leucospermum cortado fresco winterii"},
    {prodId:"10318326",label:"Leucospermum cortado fresco arenarium"},
    {prodId:"10318327",label:"Leucospermum cortado fresco bolusii"},
    {prodId:"10318328",label:"Leucospermum cortado fresco catherinae"},
    {prodId:"10318329",label:"Leucospermum cortado fresco conocarpum"},
    {prodId:"10318330",label:"Leucospermum cortado fresco cordifolium"},
    {prodId:"10318331",label:"Leucospermum cortado fresco erubescens"},
    {prodId:"10318332",label:"Leucospermum cortado fresco gerrardii"},
    {prodId:"10318333",label:"Leucospermum cortado fresco gracile"},
    {prodId:"10318334",label:"Leucospermum cortado fresco gueinzii"},
    {prodId:"10318335",label:"Leucospermum cortado fresco harpagonatum"},
    {prodId:"10318336",label:"Leucospermum cortado fresco hypophyllocarpodendron"},
    {prodId:"10318337",label:"Leucospermum cortado fresco lineare"},
    {prodId:"10318338",label:"Leucospermum cortado fresco mundii"},
    {prodId:"10318339",label:"Leucospermum cortado fresco parile"},
    {prodId:"10318340",label:"Leucospermum cortado fresco pendunculatum"},
    {prodId:"10318341",label:"Leucospermum cortado fresco praecox"},
    {prodId:"10318342",label:"Leucospermum cortado fresco profugum"},
    {prodId:"10318343",label:"Leucospermum cortado fresco reflexum"},
    {prodId:"10318344",label:"Leucospermum cortado fresco royenifolium"},
    {prodId:"10318345",label:"Leucospermum cortado fresco saxosum"},
    {prodId:"10318346",label:"Leucospermum cortado fresco spathulatum"},
    {prodId:"10318347",label:"Leucospermum cortado fresco tottum"},
    {prodId:"10318348",label:"Leucospermum cortado fresco truncatum"},
    {prodId:"10318349",label:"Leucospermum cortado fresco vestitum"},
    {prodId:"10318350",label:"Leucospermum cortado fresco wittebergense"},
    {prodId:"10321500",label:"Agapanto cortado fresco"},
    {prodId:"10321501",label:"Agapanto cortado fresco azul"},
    {prodId:"10321502",label:"Agapanto cortado fresco blanco"},
    {prodId:"10321600",label:"Alchimilla cortada fresca"},
    {prodId:"10321601",label:"Alchimilla cortada fresca capa de dama"},
    {prodId:"10321602",label:"Alchimilla cortada fresca robustica"},
    {prodId:"10321700",label:"Astilbe cortado fresco"},
    {prodId:"10321701",label:"Astilbe cortado fresco rosado fuerte"},
    {prodId:"10321702",label:"Astilbe cortado fresco rosado claro"},
    {prodId:"10321703",label:"Astilbe cortado fresco rojo"},
    {prodId:"10321704",label:"Astilbe cortado fresco blanco"},
    {prodId:"10321900",label:"ajenjo cortado fresco"},
    {prodId:"10321901",label:"Ajenjo cortado fresco verde"},
    {prodId:"10321902",label:"Ajenjo cortado fresco rey dorado"},
    {prodId:"10322000",label:"Flor de alcachofa cortada fresca"},
    {prodId:"10322001",label:"Flor de alcachofa cortada fresca chocolate"},
    {prodId:"10322002",label:"Flor de alcachofa cortada fresca verde"},
    {prodId:"10322003",label:"Flor de alcachofa cortada fresca púrpura o floreciente"},
    {prodId:"10322100",label:"Astrantia cortada fresca"},
    {prodId:"10322101",label:"Astrantia cortada fresca rosada"},
    {prodId:"10322102",label:"Astrantia cortada fresca blanca"},
    {prodId:"10322200",label:"Flor de banano cortada fresca"},
    {prodId:"10322201",label:"Flor de banano cortada fresca anaranjada"},
    {prodId:"10322202",label:"Flor de banano cortada fresca antorcha anaranjada"},
    {prodId:"10322203",label:"Flor de banano cortada fresca púrpura"},
    {prodId:"10322300",label:"Baptisia cortada fresca"},
    {prodId:"10322301",label:"Baptisia cortada fresca australis"},
    {prodId:"10322302",label:"Baptisia cortada fresca sphaerocarpa"},
    {prodId:"10322400",label:"Boronia cortada fresca"},
    {prodId:"10322401",label:"Boronia cortada fresca rosada"},
    {prodId:"10322402",label:"Boronia cortada fresca amarilla"},
    {prodId:"10322500",label:"Bromelia cortada fresca"},
    {prodId:"10322501",label:"Bromelia cortada fresca amarilla reg"},
    {prodId:"10322502",label:"Bromelia cortada fresca roja reg"},
    {prodId:"10322600",label:"Brunia cortada fresca"},
    {prodId:"10322601",label:"Brunia albiflora cortada fresca"},
    {prodId:"10322602",label:"Brunia albiflora cortada fresca verde"},
    {prodId:"10322603",label:"Brunia cortada fresca rocío de plata"},
    {prodId:"10322800",label:"Calcynia cortada fresca"},
    {prodId:"10322801",label:"Calcynia cortada fresca rosada"},
    {prodId:"10322802",label:"Calcynia cortada fresca princesa"},
    {prodId:"10322803",label:"Calcynia cortada fresca blanca"},
    {prodId:"10322900",label:"Caléndula cortada fresca"},
    {prodId:"10322901",label:"Caléndula cortada fresca anaranjada"},
    {prodId:"10322902",label:"Caléndula cortada fresca amarilla"},
    {prodId:"10323000",label:"Campanilla cortada fresca"},
    {prodId:"10323001",label:"Campanilla cortada fresca azul"},
    {prodId:"10323002",label:"Campanilla cortada fresca rosada"},
    {prodId:"10323003",label:"Campanilla cortada fresca blanca"},
    {prodId:"10323100",label:"Cestrum cortado fresco"},
    {prodId:"10323101",label:"Cestrum cortado fresco rojo"},
    {prodId:"10323102",label:"Cestrum cortado fresco rojo zohar"},
    {prodId:"10323103",label:"Cestrum cortado fresco amarillo"},
    {prodId:"10323200",label:"Chasmante cortada fresca"},
    {prodId:"10323201",label:"Chasmante floribunda cortada fresca amarilla"},
    {prodId:"10323202",label:"Chasmante floribunda cortada fresca anaranjada"},
    {prodId:"10323300",label:"Costus cortada fresca"},
    {prodId:"10323301",label:"Costus barbada cortada fresca"},
    {prodId:"10323302",label:"Costus cortada fresca cabeza de indio"},
    {prodId:"10323400",label:"Vara de San José cortada fresca"},
    {prodId:"10323401",label:"Vara de san josé lucifer cortada fresca"},
    {prodId:"10323402",label:"Vara de san josé cortada fresca vainas"},
    {prodId:"10323403",label:"Vara de san josé cortada fresca amarilla"},
    {prodId:"10323500",label:"Lirio del Fuego cortado fresco"},
    {prodId:"10323501",label:"Lirio del fuego cortado fresco anaranjado brillante"},
    {prodId:"10323502",label:"Lirio del fuego cortado fresco crema"},
    {prodId:"10323503",label:"Lirio del fuego cortado fresco anaranjado"},
    {prodId:"10323504",label:"Lirio del fuego cortado fresco amarillo"},
    {prodId:"10323600",label:"Flor del muelle cortada fresca"},
    {prodId:"10323601",label:"Flor del muelle cortada fresca verde"},
    {prodId:"10323602",label:"Flor del muelle cortada fresca roja"},
    {prodId:"10323700",label:"Planta erizo cortada fresca"},
    {prodId:"10323701",label:"Planta erizo cortada fresca tintura negra"},
    {prodId:"10323702",label:"Planta erizo cortada fresca tintura anaranjada"},
    {prodId:"10323800",label:"Manzanilla cortada fresca"},
    {prodId:"10323801",label:"Manzanilla cortada fresca sencilla vegmo"},
    {prodId:"10323802",label:"Manzanilla cortada fresca doble blanca"},
    {prodId:"10323803",label:"Manzanilla cortada fresca bola de nieve"},
    {prodId:"10323804",label:"Manzanilla cortada fresca blanca"},
    {prodId:"10323900",label:"Nomeolvides cortada fresca"},
    {prodId:"10323901",label:"Nomeolvides cortada fresca azul"},
    {prodId:"10323902",label:"Nomeolvides cortada fresca blanca"},
    {prodId:"10324000",label:"Gallardia cortada fresca"},
    {prodId:"10324001",label:"Gallardia cortada fresca anaranjada"},
    {prodId:"10324002",label:"Gallardia cortada fresca amarilla"},
    {prodId:"10324100",label:"Genciana cortada fresca"},
    {prodId:"10324101",label:"Genciana cortada fresca azul"},
    {prodId:"10324102",label:"Genciana cortada fresca blanca"},
    {prodId:"10324200",label:"Gladiolo cortado fresco"},
    {prodId:"10324201",label:"Gladiolo cortado fresco glamini rosado"},
    {prodId:"10324202",label:"Gladiolo cortado fresco glamini rojo"},
    {prodId:"10324300",label:"Gloriosa cortada fresca"},
    {prodId:"10324301",label:"Gloriosa cortada fresca anaranjada"},
    {prodId:"10324302",label:"Gloriosa cortada fresca roja"},
    {prodId:"10324303",label:"Gloriosa cortada fresca amarilla"},
    {prodId:"10324400",label:"Violeta silvestre cortada fresca"},
    {prodId:"10324401",label:"Violeta silvestre cortada fresca anaranjada"},
    {prodId:"10324402",label:"Violeta silvestre cortada fresca roja"},
    {prodId:"10324500",label:"Eléboro cortado fresco"},
    {prodId:"10324501",label:"Eléboro cortado fresco verde"},
    {prodId:"10324502",label:"Eléboro cortado fresco rayo de luna"},
    {prodId:"10324600",label:"Ixia cortada fresca"},
    {prodId:"10324601",label:"Ixia cortada fresca rosada"},
    {prodId:"10324602",label:"Ixia cortada fresca blanca"},
    {prodId:"10324700",label:"Liatrises cortadas frescas"},
    {prodId:"10324701",label:"Liatris cortada fresca púrpura"},
    {prodId:"10324702",label:"Liatris cortada fresca spray"},
    {prodId:"10324703",label:"Liatris cortada fresca blanca"},
    {prodId:"10324800",label:"Lysimachia cortada fresca"},
    {prodId:"10324801",label:"Lysimachia punctata cortada fresca"},
    {prodId:"10324802",label:"Lysimachia vulgaris cortada fresca"},
    {prodId:"10324803",label:"Lysimachia blanca cortada fresca"},
    {prodId:"10324804",label:"Lysimachia amarilla cortada fresca"},
    {prodId:"10324900",label:"Maraca cortada fresca"},
    {prodId:"10324901",label:"Maraca cortada fresca marrón"},
    {prodId:"10324902",label:"Maraca cortada fresca shampoo ginger"},
    {prodId:"10325000",label:"Caléndula cortada fresca"},
    {prodId:"10325001",label:"Caléndula cortada fresca francesa"},
    {prodId:"10325002",label:"Caléndula cortada fresca verde"},
    {prodId:"10325003",label:"Caléndula (marigold) cortada fresca anaranjada"},
    {prodId:"10325004",label:"Caléndula (marigold) cortada fresca amarilla"},
    {prodId:"10325100",label:"Mimosa cortada fresca"},
    {prodId:"10325101",label:"Mimosa cortada fresca azul o púrpura"},
    {prodId:"10325102",label:"Mimosa dedo cortada fresca"},
    {prodId:"10325103",label:"Mimosa cortada fresca floribunda o italia"},
    {prodId:"10325104",label:"Mimosa cortada fresca mirandola"},
    {prodId:"10325200",label:"Nerina cortada fresca"},
    {prodId:"10325201",label:"Nerina cortada fresca rosada"},
    {prodId:"10325202",label:"Nerina samiensis blanca"},
    {prodId:"10325300",label:"Flor de nogal cortada fresca"},
    {prodId:"10325301",label:"Flor colgante de nogal blanco cortada fresca"},
    {prodId:"10325302",label:"Flor sin hojas de nogal blanco cortada fresca"},
    {prodId:"10325303",label:"Flor erecta de nogal blanco brasileño cortada fresca"},
    {prodId:"10325400",label:"Phlox cortada fresca"},
    {prodId:"10325401",label:"Phlox cortada fresca rosado oscur"},
    {prodId:"10325402",label:"Phlox cortada fresca lavanda"},
    {prodId:"10325403",label:"Phlox cortada fresca rosado claro"},
    {prodId:"10325404",label:"Phlox cortada fresca blanca"},
    {prodId:"10325500",label:"physostegia cortada fresca"},
    {prodId:"10325501",label:"Physostegia cortada fresca rosada"},
    {prodId:"10325502",label:"Physostegia cortada fresca vainas"},
    {prodId:"10325503",label:"Physostegia cortada fresca blanca"},
    {prodId:"10325600",label:"Saponaria cortada fresca"},
    {prodId:"10325601",label:"Saponaria cortada fresca rosada"},
    {prodId:"10325602",label:"Saponaria cortada fresca blanca"},
    {prodId:"10325700",label:"Sarracenias cortadas frescas"},
    {prodId:"10325701",label:"Sarracenia cortada fresca flava rugelii"},
    {prodId:"10325800",label:"Sicilia cortada fresca"},
    {prodId:"10325801",label:"Sicilia cortada fresca campanulata azul"},
    {prodId:"10325802",label:"Sicilia cortada fresca campanulata rosada"},
    {prodId:"10325803",label:"Sicilia cortada fresca campanulata blanca"},
    {prodId:"10325804",label:"Sicilia cortada fresca peruana"},
    {prodId:"10325900",label:"Sedum cortada fresca"},
    {prodId:"10325901",label:"Sedum cortada fresca marrón"},
    {prodId:"10325902",label:"Sedum cortada fresca verde"},
    {prodId:"10325903",label:"Sedum cortada fresca rosada"},
    {prodId:"10325904",label:"Sedum cortada fresca roja"},
    {prodId:"10314709",label:"Jacinto cortado fresco rosado"},
    {prodId:"10326100",label:"Sello de salomón (polygonato) cortada fresca"},
    {prodId:"10326101",label:"Sello de salomón (polygonato) falso cortada fresca"},
    {prodId:"10326102",label:"Sello de salomón (polygonato) variegado cortada fresca"},
    {prodId:"10326200",label:"Tanaceto cortado fresco"},
    {prodId:"10326201",label:"Tanaceto cortado fresco amazonas"},
    {prodId:"10326202",label:"Tanaceto cortado fresco victoria doble blanco"},
    {prodId:"10326203",label:"Tanaceto cortado fresco victoria sencillo blanco"},
    {prodId:"10326204",label:"Tanaceto cortado fresco vegmo amarillo"},
    {prodId:"10326300",label:"Traquelio cortado fresco"},
    {prodId:"10326301",label:"Trachelium jade cortada fresca"},
    {prodId:"10326302",label:"Trachelium púrpura cortada fresca"},
    {prodId:"10326303",label:"Trachelium blanca cortada fresca"},
    {prodId:"10326400",label:"Tuberosas cortadas frescas"},
    {prodId:"10326401",label:"Tuberosa cortada fresca doble"},
    {prodId:"10326402",label:"Tuberosa cortada fresca sencilla"},
    {prodId:"10326500",label:"Tweedias cortadas frescas"},
    {prodId:"10326501",label:"Tweedia azul cortada fresca"},
    {prodId:"10326502",label:"Tweedia blanca cortada fresca"},
    {prodId:"10326600",label:"Verónicas cortadas frescas"},
    {prodId:"10326601",label:"Verónica cortada fresca rosada"},
    {prodId:"10326602",label:"Verónica cortada fresca púrpura"},
    {prodId:"10326603",label:"Verónica cortada fresca blanca"},
    {prodId:"10326700",label:"Watsonias cortadas frescas"},
    {prodId:"10326701",label:"Watsonia cortada fresca anaranjada"},
    {prodId:"10326702",label:"Watsonia cortada fresca rosada"},
    {prodId:"10326703",label:"Watsonia cortada fresca roja"},
    {prodId:"10326704",label:"Watsonia cortada fresca blanca"},
    {prodId:"10331500",label:"Crisantemo cortado fresco pompón"},
    {prodId:"10331501",label:"Crisantemo cortado fresco pompón delirock"},
    {prodId:"10331502",label:"Crisantemo cortado fresco pompón discovery"},
    {prodId:"10331503",label:"Crisantemo cortado fresco pompón focus"},
    {prodId:"10331504",label:"Crisantemo cortado fresco pompón jeanne"},
    {prodId:"10331505",label:"Crisantemo cortado fresco pompón lady"},
    {prodId:"10331506",label:"Crisantemo cortado fresco pompón leidi"},
    {prodId:"10331507",label:"Crisantemo cortado fresco pompón lexy"},
    {prodId:"10331508",label:"Crisantemo cortado fresco pompón ole"},
    {prodId:"10331509",label:"Crisantemo cortado fresco pompón revise"},
    {prodId:"10331510",label:"Crisantemo cortado fresco pompón estadista"},
    {prodId:"10331511",label:"Crisantemo cortado fresco pompón dulce"},
    {prodId:"10331512",label:"Crisantemo cortado fresco pompón yoko ono"},
    {prodId:"10331513",label:"Crisantemo cortado fresco pompón zip"},
    {prodId:"10331600",label:"Crisantemo cortado fresco pompón margarita"},
    {prodId:"10331601",label:"Crisantemo cortado fresco pompón artista rosado"},
    {prodId:"10331602",label:"Crisantemo cortado fresco pompón artista amarillo"},
    {prodId:"10331603",label:"Crisantemo cortado fresco pompón atlantis rosado"},
    {prodId:"10331604",label:"Crisantemo cortado fresco pompón atlantis blanco"},
    {prodId:"10331605",label:"Crisantemo cortado fresco pompón atlantis amarillo"},
    {prodId:"10331606",label:"Crisantemo cortado fresco pompón bennie jolink"},
    {prodId:"10331607",label:"Crisantemo cortado fresco pompón bennie jolink amarillo"},
    {prodId:"10331608",label:"Crisantemo cortado fresco pompón managua bronce"},
    {prodId:"10331609",label:"Crisantemo cortado fresco pompón clave"},
    {prodId:"10331610",label:"Crisantemo cortado fresco pompón ficción coral"},
    {prodId:"10331611",label:"Crisantemo cortado fresco pompón cumbia"},
    {prodId:"10331612",label:"Crisantemo cortado fresco pompón cantata oscura"},
    {prodId:"10331613",label:"Crisantemo cortado fresco pompón lineker oscuro"},
    {prodId:"10331614",label:"Crisantemo cortado fresco pompón dipper"},
    {prodId:"10331615",label:"Crisantemo cortado fresco pompón rosado elite"},
    {prodId:"10331616",label:"Crisantemo cortado fresco pompón blanco elite"},
    {prodId:"10331617",label:"Crisantemo cortado fresco pompón amarillo elite"},
    {prodId:"10331618",label:"Crisantemo cortado fresco pompón factor"},
    {prodId:"10331619",label:"Crisantemo cortado fresco pompón ficción"},
    {prodId:"10331620",label:"Crisantemo cortado fresco pompón fuerza"},
    {prodId:"10331621",label:"Crisantemo cortado fresco pompón reagan mejorado"},
    {prodId:"10331622",label:"Crisantemo cortado fresco pompón life"},
    {prodId:"10331623",label:"Crisantemo cortado fresco pompón managua anaranjado"},
    {prodId:"10331624",label:"Crisantemo cortado fresco pompón novedsd bronce cocarde"},
    {prodId:"10331625",label:"Crisantemo cortado fresco pompón reagan anaranjado"},
    {prodId:"10331626",label:"Crisantemo cortado fresco pompón orinoco"},
    {prodId:"10331627",label:"Crisantemo cortado fresco pompón petra"},
    {prodId:"10331628",label:"Crisantemo cortado fresco pompón balsas rosado"},
    {prodId:"10331629",label:"Crisantemo cortado fresco pompón mona lisa rosado"},
    {prodId:"10331630",label:"Crisantemo cortado fresco pompón reagan rosado"},
    {prodId:"10331631",label:"Crisantemo cortado fresco pompón marfil reagan"},
    {prodId:"10331632",label:"Crisantemo cortado fresco pompón reagan rosado"},
    {prodId:"10331633",label:"Crisantemo cortado fresco pompón rebasco"},
    {prodId:"10331634",label:"Crisantemo cortado fresco pompón redock"},
    {prodId:"10331635",label:"Crisantemo cortado fresco pompón salmón lineker"},
    {prodId:"10331636",label:"Crisantemo cortado fresco pompón sheba"},
    {prodId:"10331637",label:"Crisantemo cortado fresco pompón sirius"},
    {prodId:"10331638",label:"Crisantemo cortado fresco pompón reagan espléndido"},
    {prodId:"10331639",label:"Crisantemo cortado fresco pompón reagan soleado"},
    {prodId:"10331640",label:"Crisantemo cortado fresco pompón tina"},
    {prodId:"10331641",label:"Crisantemo cortado fresco pompón vero"},
    {prodId:"10331642",label:"Crisantemo cortado fresco pompón volare"},
    {prodId:"10331643",label:"Crisantemo cortado fresco pompón vida blanca"},
    {prodId:"10331644",label:"Crisantemo cortado fresco pompón regan blanco"},
    {prodId:"10331645",label:"Crisantemo cortado fresco pompón rino blanco"},
    {prodId:"10331646",label:"Crisantemo cortado fresco pompón vero amarillo"},
    {prodId:"10331647",label:"Crisantemo cortado fresco pompón zenith"},
    {prodId:"10331700",label:"Crisantemo cortado fresco espollado cremon"},
    {prodId:"10331701",label:"Crisantemo cortado fresco espollado cremon annecy oscura"},
    {prodId:"10331702",label:"Crisantemo cortado fresco espollado cremon"},
    {prodId:"10331703",label:"Crisantemo cortado fresco espollado cremon atlantis rosado"},
    {prodId:"10331704",label:"Crisantemo cortado fresco espollado cremon eleonora bronce"},
    {prodId:"10331705",label:"Crisantemo cortado fresco espollado cremon eleonora lila"},
    {prodId:"10331706",label:"Crisantemo cortado fresco espollado cremon eleonora rosado"},
    {prodId:"10331707",label:"Crisantemo cortado fresco espollado cremon eleonora nieve"},
    {prodId:"10331708",label:"Crisantemo cortado fresco espollado cremon eleonora amarillo"},
    {prodId:"10331709",label:"Crisantemo cortado fresco espollado cremon idea"},
    {prodId:"10331710",label:"Crisantemo cortado fresco espollado cremon ivanna púrpura"},
    {prodId:"10331711",label:"Crisantemo cortado fresco espollado cremon minka rosado"},
    {prodId:"10331712",label:"Crisantemo cortado fresco espollado cremon listo"},
    {prodId:"10331713",label:"Crisantemo cortado fresco espollado cremon sinatra"},
    {prodId:"10331714",label:"Crisantemo cortado fresco rover rojo"},
    {prodId:"10331800",label:"Crisantemo cortado fresco espollado football"},
    {prodId:"10331801",label:"Crisantemo cortado fresco espollado blaze"},
    {prodId:"10331802",label:"Crisantemo cortado fresco espollado beso de football"},
    {prodId:"10331803",label:"Crisantemo cortado fresco espollado football lavanda / rosado"},
    {prodId:"10331804",label:"Crisantemo cortado fresco espollado football resouci"},
    {prodId:"10331805",label:"Crisantemo cortado fresco espollado footbal blanco"},
    {prodId:"10331806",label:"Crisantemo cortado fresco espollado football amarillo"},
    {prodId:"10331807",label:"Crisantemo cortado fresco espollado promenade"},
    {prodId:"10331808",label:"Crisantemo cortado fresco espollado rebonnet"},
    {prodId:"10331809",label:"Crisantemo cortado fresco espollado reflex"},
    {prodId:"10331810",label:"Crisantemo cortado fresco espollado residence"},
    {prodId:"10331811",label:"Crisantemo cortado fresco espollado resomee perla"},
    {prodId:"10331812",label:"Crisantemo cortado fresco espollado resouci"},
    {prodId:"10331900",label:"Crisantemo spider cortado fresco"},
    {prodId:"10331901",label:"Crisantemo spider cortado fresco anastasia bronce"},
    {prodId:"10331902",label:"Crisantemo spider cortado fresco anastasia bronce oscuro"},
    {prodId:"10331903",label:"Crisantemo spider cortado fresco anastasia verde"},
    {prodId:"10331904",label:"Crisantemo spider cortado fresco anastasia lila"},
    {prodId:"10331905",label:"Crisantemo spider cortado fresco anastasia rosado"},
    {prodId:"10331906",label:"Crisantemo spider cortado fresco anastasia púrpura"},
    {prodId:"10331907",label:"Crisantemo spider cortado fresco anastasia soleado"},
    {prodId:"10331908",label:"Crisantemo spider cortado fresco anastasia blanco"},
    {prodId:"10331909",label:"Crisantemo spider cortado fresco bradford"},
    {prodId:"10331910",label:"Crisantemo spider cortado fresco delistar blanco"},
    {prodId:"10331911",label:"Crisantemo spider cortado fresco delistar amarillo"},
    {prodId:"10331912",label:"Crisantemo spider cortado fresco minka"},
    {prodId:"10331913",label:"Crisantemo spider cortado fresco natasha soleado"},
    {prodId:"10331914",label:"Crisantemo spider cortado fresco pirouette"},
    {prodId:"10331915",label:"Crisantemo spider cortado fresco reflejo"},
    {prodId:"10331916",label:"Crisantemo spider cortado fresco regata"},
    {prodId:"10331917",label:"Crisantemo spider cortado fresco render"},
    {prodId:"10331918",label:"Crisantemo spider cortado fresco repertorio"},
    {prodId:"10331919",label:"Crisantemo spider cortado fresco resolute"},
    {prodId:"10331920",label:"Crisantemo spider cortado fresco resomac"},
    {prodId:"10331921",label:"Crisantemo spider cortado fresco trébol"},
    {prodId:"10331922",label:"Crisantemo spider cortado fresco talante bronce"},
    {prodId:"10331923",label:"Crisantemo spider cortado fresco super blanco"},
    {prodId:"10331924",label:"Crisantemo spider cortado fresco super amarillo"},
    {prodId:"10331925",label:"Crisantemo spider cortado fresco tierno"},
    {prodId:"10331926",label:"Crisantemo spider cortado fresco zembia"},
    {prodId:"10332000",label:"Crisantemo cortado fresco  novelty"},
    {prodId:"10332001",label:"Crisantemo cortado fresco pompón anecy rosado"},
    {prodId:"10332002",label:"Crisantemo cortado fresco pompón ardilo royal"},
    {prodId:"10332003",label:"Crisantemo cortado fresco pompón athos"},
    {prodId:"10332004",label:"Crisantemo cortado fresco pompón biarritz"},
    {prodId:"10332005",label:"Crisantemo cortado fresco pompón bradford anaranjado"},
    {prodId:"10332006",label:"Crisantemo cortado fresco pompón bradford"},
    {prodId:"10332007",label:"Crisantemo cortado fresco pompón esperma"},
    {prodId:"10332008",label:"Crisantemo cortado fresco pompón candor"},
    {prodId:"10332009",label:"Crisantemo cortado fresco pompón gallardía"},
    {prodId:"10332010",label:"Crisantemo cortado fresco pompón décima"},
    {prodId:"10332012",label:"Crisantemo cortado fresco pompón delisun"},
    {prodId:"10332013",label:"Crisantemo cortado fresco pompón dion"},
    {prodId:"10332014",label:"Crisantemo cortado fresco pompón dorena"},
    {prodId:"10332015",label:"Crisantemo cortado fresco pompón dublín"},
    {prodId:"10332016",label:"Crisantemo cortado fresco pompón everglades"},
    {prodId:"10332017",label:"Crisantemo cortado fresco pompón buenmozo"},
    {prodId:"10332018",label:"Crisantemo cortado fresco pompón hasting"},
    {prodId:"10332019",label:"Crisantemo cortado fresco pompón high five"},
    {prodId:"10332020",label:"Crisantemo cortado fresco pompón mundial mejorado"},
    {prodId:"10332021",label:"Crisantemo cortado fresco pompón juanes"},
    {prodId:"10332022",label:"Crisantemo cortado fresco pompón kiato verde"},
    {prodId:"10332023",label:"Crisantemo cortado fresco pompón kiato"},
    {prodId:"10332024",label:"Crisantemo cortado fresco pompón kiwi"},
    {prodId:"10332025",label:"Crisantemo cortado fresco pompón madeira"},
    {prodId:"10332026",label:"Crisantemo cortado fresco pompón magneto"},
    {prodId:"10332027",label:"Crisantemo cortado fresco pompón marimo"},
    {prodId:"10332028",label:"Crisantemo cortado fresco pompón matrix"},
    {prodId:"10332029",label:"Crisantemo cortado fresco pompón miletta"},
    {prodId:"10332030",label:"Crisantemo cortado fresco pompón monalisa"},
    {prodId:"10332031",label:"Crisantemo cortado fresco pompón omaha"},
    {prodId:"10332032",label:"Crisantemo cortado fresco pompón orinoco púrpura"},
    {prodId:"10332033",label:"Crisantemo cortado fresco pompón orinoco amarillo"},
    {prodId:"10332034",label:"Crisantemo cortado fresco pompón verde pacífico"},
    {prodId:"10332035",label:"Crisantemo cortado fresco pompón blanco puma"},
    {prodId:"10332036",label:"Crisantemo cortado fresco pompón amarillo puma"},
    {prodId:"10332037",label:"Crisantemo cortado fresco pompón mundial púrpura"},
    {prodId:"10332038",label:"Crisantemo cortado fresco pompón regata"},
    {prodId:"10332039",label:"Crisantemo cortado fresco pompón remco"},
    {prodId:"10332040",label:"Crisantemo cortado fresco pompón royal mundial"},
    {prodId:"10332041",label:"Crisantemo cortado fresco pompón sabrina"},
    {prodId:"10332042",label:"Crisantemo cortado fresco pompón shakira blanco"},
    {prodId:"10332043",label:"Crisantemo cortado fresco pompón sharp"},
    {prodId:"10332044",label:"Crisantemo cortado fresco pompón shock"},
    {prodId:"10332045",label:"Crisantemo cortado fresco pompón sizzle verde"},
    {prodId:"10332046",label:"Crisantemo cortado fresco pompón sizzle rosado"},
    {prodId:"10332047",label:"Crisantemo cortado fresco pompón sizzle"},
    {prodId:"10332048",label:"Crisantemo cortado fresco pompón sizzle púrpura"},
    {prodId:"10332049",label:"Crisantemo cortado fresco pompón sizzle salmón"},
    {prodId:"10332050",label:"Crisantemo cortado fresco pompón sizzle amarillo"},
    {prodId:"10332051",label:"Crisantemo cortado fresco pompón bandera española"},
    {prodId:"10332052",label:"Crisantemo cortado fresco pompón starbust o copo de nieve"},
    {prodId:"10332053",label:"Crisantemo cortado fresco pompón cisne"},
    {prodId:"10332054",label:"Crisantemo cortado fresco pompón tedcha anaranjado"},
    {prodId:"10332055",label:"Crisantemo cortado fresco pompón tierno"},
    {prodId:"10332056",label:"Crisantemo cortado fresco pompón oropel"},
    {prodId:"10332057",label:"Crisantemo cortado fresco pompón toque"},
    {prodId:"10332058",label:"Crisantemo cortado fresco pompón troyes"},
    {prodId:"10332059",label:"Crisantemo cortado fresco pompón valesca"},
    {prodId:"10332060",label:"Crisantemo cortado fresco pompón anaranjado vikingo"},
    {prodId:"10332061",label:"Crisantemo cortado fresco pompón vikingo"},
    {prodId:"10332062",label:"Crisantemo cortado fresco pompón reloj"},
    {prodId:"10332063",label:"Crisantemo cortado fresco pompón aguja blanco"},
    {prodId:"10332064",label:"Crisantemo cortado fresco pompón noche blanca"},
    {prodId:"10332065",label:"Crisantemo cortado fresco pompón artista amarillo"},
    {prodId:"10332066",label:"Crisantemo cortado fresco pompón ficción amarillo"},
    {prodId:"10332067",label:"Crisantemo cortado fresco pompón sharp amarillo"},
    {prodId:"10332100",label:"Crisantemo cortado fresco santini"},
    {prodId:"10332101",label:"Crisantemo cortado fresco pompón alma"},
    {prodId:"10332102",label:"Crisantemo cortado fresco pompón barón"},
    {prodId:"10332103",label:"Crisantemo cortado fresco pompón bernardo"},
    {prodId:"10332104",label:"Crisantemo cortado fresco pompón bistro"},
    {prodId:"10332105",label:"Crisantemo cortado fresco pompón bodega"},
    {prodId:"10332106",label:"Crisantemo cortado fresco pompón brisa"},
    {prodId:"10332107",label:"Crisantemo cortado fresco pompón centella bronce"},
    {prodId:"10332108",label:"Crisantemo cortado fresco pompón costa blanca"},
    {prodId:"10332109",label:"Crisantemo cortado fresco pompón creta"},
    {prodId:"10332110",label:"Crisantemo cortado fresco pompón deliflame"},
    {prodId:"10332111",label:"Crisantemo cortado fresco pompón dalila"},
    {prodId:"10332112",label:"Crisantemo cortado fresco pompón dígito"},
    {prodId:"10332113",label:"Crisantemo cortado fresco pompón evilio"},
    {prodId:"10332114",label:"Crisantemo cortado fresco pompón furense"},
    {prodId:"10332115",label:"Crisantemo cortado fresco pompón guía"},
    {prodId:"10332116",label:"Crisantemo cortado fresco pompón kerry"},
    {prodId:"10332117",label:"Crisantemo cortado fresco pompón kess"},
    {prodId:"10332118",label:"Crisantemo cortado fresco pompón lima"},
    {prodId:"10332119",label:"Crisantemo cortado fresco pompón lupo"},
    {prodId:"10332120",label:"Crisantemo cortado fresco pompón lineker anaranjado"},
    {prodId:"10332121",label:"Crisantemo cortado fresco pompón panuco rojo"},
    {prodId:"10332122",label:"Crisantemo cortado fresco pompón costa rosado"},
    {prodId:"10332123",label:"Crisantemo cortado fresco pompón rafael"},
    {prodId:"10332124",label:"Crisantemo cortado fresco pompón refine"},
    {prodId:"10332125",label:"Crisantemo cortado fresco pompón regaliz"},
    {prodId:"10332126",label:"Crisantemo cortado fresco pompón renella"},
    {prodId:"10332127",label:"Crisantemo cortado fresco pompón retorno"},
    {prodId:"10332128",label:"Crisantemo cortado fresco pompón río"},
    {prodId:"10332129",label:"Crisantemo cortado fresco pompón sabas"},
    {prodId:"10332130",label:"Crisantemo cortado fresco pompón target"},
    {prodId:"10332131",label:"Crisantemo cortado fresco pompón texto"},
    {prodId:"10332132",label:"Crisantemo cortado fresco pompón caballo amarillo"},
    {prodId:"10341700",label:"Bouquet cortado fresco de mini clavel"},
    {prodId:"10341701",label:"Bouquet cortado fresco de mini clavel burgundy"},
    {prodId:"10341800",label:"Bouquet cortado fresco de lirio"},
    {prodId:"10341801",label:"Bouquet cortado fresco de lirio asiático black out"},
    {prodId:"10341900",label:"Bouquet cortado fresco de crisantemo pompón"},
    {prodId:"10341901",label:"Bouquet cortado fresco de crisantemo pompón margarita"},
    {prodId:"10342000",label:"Bouquet cortado fresco de astromelia"},
    {prodId:"10342001",label:"Bouquet cortado fresco de astromelia agropoli"},
    {prodId:"10351500",label:"Bouquet cortado fresco de clavel de floración sencilla"},
    {prodId:"10351501",label:"Clavel cortado fresco de floración sencilla burgundy bicolor"},
    {prodId:"10351502",label:"Clavel cortado fresco de floración sencilla burgundy"},
    {prodId:"10351503",label:"Clavel cortado fresco de floración sencilla cinderella"},
    {prodId:"10351504",label:"Clavel cortado fresco de floración sencilla crema bicolor"},
    {prodId:"10351505",label:"Clavel cortado fresco de floración sencilla crema"},
    {prodId:"10351506",label:"Clavel cortado fresco de floración sencilla verde o prado"},
    {prodId:"10351507",label:"Clavel cortado fresco de floración sencilla rosado fuerte"},
    {prodId:"10351508",label:"Clavel cortado fresco de floración sencilla verde claro"},
    {prodId:"10351509",label:"Clavel cortado fresco de floración sencilla rosado claro"},
    {prodId:"10351510",label:"Clavel cortado fresco de floración sencilla anaranjado bicolor"},
    {prodId:"10351511",label:"Clavel cortado fresco de floración sencilla anaranjado"},
    {prodId:"10351512",label:"Clavel cortado fresco de floración sencilla durazno"},
    {prodId:"10351513",label:"Clavel cortado fresco de floración sencilla menta bicolor"},
    {prodId:"10351514",label:"Clavel cortado fresco de floración sencilla rosado bicolor"},
    {prodId:"10351515",label:"Clavel cortado fresco de floración sencilla rosado"},
    {prodId:"10351516",label:"Clavel cortado fresco de floración sencilla púrpura bicolor"},
    {prodId:"10351517",label:"Clavel cortado fresco de floración sencilla rojo bicolor"},
    {prodId:"10351518",label:"Clavel cortado fresco de floración sencilla rojo"},
    {prodId:"10351519",label:"Clavel cortado fresco de floración sencilla blanco"},
    {prodId:"10351520",label:"Clavel cortado fresco de floración sencilla amarillo"},
    {prodId:"10351600",label:"Bouquet cortado fresco de clavel min o spray"},
    {prodId:"10351601",label:"Clavel cortado fresco mini o spray burgundy"},
    {prodId:"10351602",label:"Clavel cortado fresco mini o spray"},
    {prodId:"10351603",label:"Clavel cortado fresco mini o spray rosado fuerte"},
    {prodId:"10351604",label:"Clavel cortado fresco mini o spray lavanda"},
    {prodId:"10351605",label:"Clavel cortado fresco mini o spray rosado claro"},
    {prodId:"10351606",label:"Clavel cortado fresco mini o spray anaranjado"},
    {prodId:"10351607",label:"Clavel cortado fresco mini o spray durazno"},
    {prodId:"10351608",label:"Clavel cortado fresco mini o spray menta"},
    {prodId:"10351609",label:"Clavel cortado fresco mini o spray rosado"},
    {prodId:"10351610",label:"Clavel cortado fresco mini o spray púrpura bicolor"},
    {prodId:"10351611",label:"Clavel cortado fresco mini o spray púrpura"},
    {prodId:"10351612",label:"Clavel cortado fresco mini o spray rojo"},
    {prodId:"10351613",label:"Clavel cortado fresco mini o spray blanco"},
    {prodId:"10351614",label:"Clavel cortado fresco mini o spray amarillo"},
    {prodId:"10361500",label:"Orquidea cypripedium  o pantuflas de dama cortada fresca"},
    {prodId:"10361501",label:"Orquidea cypripedium cortada fresca verde"},
    {prodId:"10361502",label:"Orquidea cypripedium cortada fresca francia"},
    {prodId:"10361503",label:"Orquidea cypripedium cortada fresca púrpura rey arturo"},
    {prodId:"10361504",label:"Orquidea paphiopedilum cortada fresca verde"},
    {prodId:"10361505",label:"Orquidea aranthera maggie vie cortada fresca"},
    {prodId:"10361600",label:"Orquídea mocara cortada fresca"},
    {prodId:"10361601",label:"Orquídea mocara cortada fresca omyai"},
    {prodId:"10361602",label:"Orquídea mocara cortada fresca roja"},
    {prodId:"10361603",label:"Orquídea mocara cortada fresca calipso"},
    {prodId:"10361604",label:"Orquídea mocara cortada fresca nora"},
    {prodId:"10361605",label:"Orquídea mocara cortada fresca panee"},
    {prodId:"10361700",label:"Orquídea catleya cortada fresca"},
    {prodId:"10361701",label:"Orquídea cattleya cortada fresca blanca"},
    {prodId:"10361702",label:"Orquídea cattleya cortada fresca r b lavanda"},
    {prodId:"10361800",label:"Orquídea disa cortada fresca"},
    {prodId:"10361801",label:"Orquídea disa cortada fresca roja"},
    {prodId:"10361802",label:"Orquídea disa cortada fresca anaranjada"},
    {prodId:"10361803",label:"Orquídea disa cortada fresca rosada"},
    {prodId:"10361804",label:"Orquídea disa cortada fresca anaranjada y amarilla bicolor"},
    {prodId:"10361805",label:"Orquídea disa cortada fresca durazno y amarilla bicolor"},
    {prodId:"10361806",label:"Orquídea disa cortada fresca amarilla y roja bicolor"},
    {prodId:"10361900",label:"Orquídea arachnis cortada fresca"},
    {prodId:"10361901",label:"Orquídea arachnis cortada fresca james storie roja"},
    {prodId:"10361902",label:"Orquídea arachnis cortada fresca maggie oei roja"},
    {prodId:"10361903",label:"Orquídea arachnis cortada fresca maggie oei amarilla"},
    {prodId:"10361904",label:"Orquídea arachnis cortada fresca maroon maggie"},
    {prodId:"10361905",label:"Orquídea arachnis cortada fresca merry maggie"},
    {prodId:"10362000",label:"Orquídea phalaenopsis cortada fresca"},
    {prodId:"10362001",label:"Orquídea phalaenopsis cortada fresca amabilis"},
    {prodId:"10362002",label:"Orquídea phalaenopsis cortada fresca amboinensis"},
    {prodId:"10362003",label:"Orquídea phalaenopsis cortada fresca afrodita"},
    {prodId:"10362004",label:"Orquídea phalaenopsis cortada fresca appendiculata"},
    {prodId:"10362005",label:"Orquídea phalaenopsis cortada fresca bastianii"},
    {prodId:"10362006",label:"Orquídea phalaenopsis cortada fresca bellina"},
    {prodId:"10362007",label:"Orquídea phalaenopsis cortada fresca borneensis"},
    {prodId:"10362008",label:"Orquídea phalaenopsis cortada fresca braceana"},
    {prodId:"10362009",label:"Orquídea phalaenopsis cortada fresca buyssoniana"},
    {prodId:"10362010",label:"Orquídea phalaenopsis cortada fresca celebensis"},
    {prodId:"10362011",label:"Orquídea phalaenopsis cortada fresca chibae"},
    {prodId:"10362012",label:"Orquídea phalaenopsis cortada fresca cochlearis"},
    {prodId:"10362013",label:"Orquídea phalaenopsis cortada fresca corningiana"},
    {prodId:"10362014",label:"Orquídea phalaenopsis cortada fresca cornu-cervi"},
    {prodId:"10362015",label:"Orquídea phalaenopsis cortada fresca deliciosa"},
    {prodId:"10362016",label:"Orquídea phalaenopsis cortada fresca doweryënsis"},
    {prodId:"10362017",label:"Orquídea phalaenopsis cortada fresca equestris"},
    {prodId:"10362018",label:"Orquídea phalaenopsis cortada fresca fasciata"},
    {prodId:"10362019",label:"Orquídea phalaenopsis cortada fresca fimbriata"},
    {prodId:"10362020",label:"Orquídea phalaenopsis cortada fresca floresensis"},
    {prodId:"10362021",label:"Orquídea phalaenopsis cortada fresca fuscata"},
    {prodId:"10362022",label:"Orquídea phalaenopsis cortada fresca gibbosa"},
    {prodId:"10362023",label:"Orquídea phalaenopsis cortada fresca hainanensis"},
    {prodId:"10362024",label:"Orquídea phalaenopsis cortada fresca hieroglyphica"},
    {prodId:"10362025",label:"Orquídea phalaenopsis cortada fresca honghenensis"},
    {prodId:"10362026",label:"Orquídea phalaenopsis cortada fresca inscriptiosinensis"},
    {prodId:"10362027",label:"Orquídea phalaenopsis cortada fresca javanica"},
    {prodId:"10362028",label:"Orquídea phalaenopsis cortada fresca kunstleri"},
    {prodId:"10362029",label:"Orquídea phalaenopsis cortada fresca lamelligera"},
    {prodId:"10362030",label:"Orquídea phalaenopsis cortada fresca lindenii"},
    {prodId:"10362031",label:"Orquídea phalaenopsis cortada fresca lobbii"},
    {prodId:"10362032",label:"Orquídea phalaenopsis cortada fresca lowii"},
    {prodId:"10362033",label:"Orquídea phalaenopsis cortada fresca lueddemanniana"},
    {prodId:"10362034",label:"Orquídea phalaenopsis cortada fresca mambo"},
    {prodId:"10362035",label:"Orquídea phalaenopsis cortada fresca luteola"},
    {prodId:"10362036",label:"Orquídea phalaenopsis cortada fresca maculata"},
    {prodId:"10362037",label:"Orquídea phalaenopsis cortada fresca malipoensis"},
    {prodId:"10362038",label:"Orquídea phalaenopsis cortada fresca mannii"},
    {prodId:"10362039",label:"Orquídea phalaenopsis cortada fresca mariae"},
    {prodId:"10362040",label:"Orquídea phalaenopsis cortada fresca micholitzii"},
    {prodId:"10362041",label:"Orquídea phalaenopsis cortada fresca modesta"},
    {prodId:"10362042",label:"Orquídea phalaenopsis cortada fresca mysorensis"},
    {prodId:"10362043",label:"Orquídea phalaenopsis cortada fresca pallens"},
    {prodId:"10362044",label:"Orquídea phalaenopsis cortada fresca pantherina"},
    {prodId:"10362045",label:"Orquídea phalaenopsis cortada fresca parishii"},
    {prodId:"10362046",label:"Orquídea phalaenopsis cortada fresca petelotii"},
    {prodId:"10362047",label:"Orquídea phalaenopsis cortada fresca philippinensis"},
    {prodId:"10362048",label:"Orquídea phalaenopsis cortada fresca pulcherrima"},
    {prodId:"10362049",label:"Orquídea phalaenopsis cortada fresca pulchra"},
    {prodId:"10362050",label:"Orquídea phalaenopsis cortada fresca regnieriana"},
    {prodId:"10362051",label:"Orquídea phalaenopsis cortada fresca reichenbachiana"},
    {prodId:"10362052",label:"Orquídea phalaenopsis cortada fresca nivacolor"},
    {prodId:"10362053",label:"Orquídea phalaenopsis cortada fresca sanderiana"},
    {prodId:"10362054",label:"Orquídea phalaenopsis cortada fresca schilleriana"},
    {prodId:"10362055",label:"Orquídea phalaenopsis cortada fresca speciosa"},
    {prodId:"10362056",label:"Orquídea phalaenopsis cortada fresca stobartiana"},
    {prodId:"10362057",label:"Orquídea phalaenopsis cortada fresca stuartiana"},
    {prodId:"10362058",label:"Orquídea phalaenopsis cortada fresca sumatrana"},
    {prodId:"10362059",label:"Orquídea phalaenopsis cortada fresca taenialis"},
    {prodId:"10362060",label:"Orquídea phalaenopsis cortada fresca tetraspis"},
    {prodId:"10362061",label:"Orquídea phalaenopsis cortada fresca venosa"},
    {prodId:"10362062",label:"Orquídea phalaenopsis cortada fresca violácea"},
    {prodId:"10362063",label:"Orquídea phalaenopsis cortada fresca viridis"},
    {prodId:"10362064",label:"Orquídea phalaenopsis cortada fresca wilsonii"},
    {prodId:"10362065",label:"Orquídea phalaenopsis cortada fresca zebrina"},
    {prodId:"10362067",label:"Orquídea phalaenopsis cortada fresca labio lavanda"},
    {prodId:"10362100",label:"Orquídea dendrobium cortada fresca"},
    {prodId:"10362101",label:"Orquídea dendrobium cortada fresca bom"},
    {prodId:"10362102",label:"Orquídea dendrobium cortada fresca burana"},
    {prodId:"10362103",label:"Orquídea dendrobium cortada fresca chita"},
    {prodId:"10362104",label:"Orquídea dendrobium cortada fresca fátima"},
    {prodId:"10362105",label:"Orquídea dendrobium cortada fresca intuwong"},
    {prodId:"10362106",label:"Orquídea dendrobium cortada fresca jumbo blanca"},
    {prodId:"10362107",label:"Orquídea dendrobium cortada fresca kating dang"},
    {prodId:"10362108",label:"Orquídea dendrobium cortada fresca libertad"},
    {prodId:"10362109",label:"Orquídea dendrobium cortada fresca hawaii"},
    {prodId:"10362110",label:"Orquídea dendrobium cortada fresca sakura sweet rosada"},
    {prodId:"10362111",label:"Orquídea dendrobium cortada fresca sensacional púrpura"},
    {prodId:"10362112",label:"Orquídea dendrobium cortada fresca blanca"},
    {prodId:"10362200",label:"Orquídea cymbidium cortada fresca"},
    {prodId:"10362201",label:"Orquídea cymbidium cortada fresca crema"},
    {prodId:"10362202",label:"Orquídea cymbidium cortada fresca verde"},
    {prodId:"10362203",label:"Orquídea cymbidium cortada fresca mini verde"},
    {prodId:"10362204",label:"Orquídea cymbidium cortada fresca mini rosada"},
    {prodId:"10362205",label:"Orquídea cymbidium cortada fresca mini roja"},
    {prodId:"10362206",label:"Orquídea cymbidium cortada fresca mini blanca"},
    {prodId:"10362207",label:"Orquídea cymbidium cortada fresca mini amarilla"},
    {prodId:"10362208",label:"Orquídea cymbidium cortada fresca chocolate"},
    {prodId:"10362209",label:"Orquídea cymbidium cortada fresca rosado oscuro"},
    {prodId:"10362210",label:"Orquídea cymbidium cortada fresca anaranjada"},
    {prodId:"10362211",label:"Orquídea cymbidium cortada fresca rosada"},
    {prodId:"10362212",label:"Orquídea cymbidium cortada fresca blanca"},
    {prodId:"10362213",label:"Orquídea cymbidium cortada fresca amarilla"},
    {prodId:"10362300",label:"Orquídea oncidium cortada fresca"},
    {prodId:"10362301",label:"Orquídea oncidium cortada fresca ducha dorada"},
    {prodId:"10362302",label:"Orquídea oncidium cortada fresca rhamsey"},
    {prodId:"10362400",label:"Orquídea vanda cortada fresca"},
    {prodId:"10362401",label:"Orquídea cortada fresca vanda alizarin"},
    {prodId:"10362402",label:"Orquídea cortada fresca vanda rosada fuerte"},
    {prodId:"10362403",label:"Orquídea cortada fresca vanda lavanda"},
    {prodId:"10362404",label:"Orquídea cortada fresca vanda púrpura"},
    {prodId:"10362405",label:"Orquídea cortada fresca vanda hazme cosquillas rosada"},
    {prodId:"10362406",label:"Orquídea cortada fresca vanda amarilla"},
    {prodId:"10401500",label:"Rosas cortadas secas azules o lavanda o púrpura"},
    {prodId:"10401501",label:"Rosal cortado seco allure o sterling 95"},
    {prodId:"10401502",label:"Rosal cortado seco amnesia"},
    {prodId:"10401503",label:"Rosal cortado seco augusta louise"},
    {prodId:"10401504",label:"Rosal cortado seco avant garde"},
    {prodId:"10401505",label:"Rosal cortado seco blue bird"},
    {prodId:"10401506",label:"Rosal cortado seco curiosa"},
    {prodId:"10401507",label:"Rosal cortado seco cool water"},
    {prodId:"10401508",label:"Rosal cortado seco delilah"},
    {prodId:"10401509",label:"Rosal cortado seco double party"},
    {prodId:"10401510",label:"Rosal cortado seco faith"},
    {prodId:"10401511",label:"Rosal cortado seco mami blue o mammy blue"},
    {prodId:"10401512",label:"Rosal cortado seco maritime"},
    {prodId:"10401513",label:"Rosal cortado seco milano"},
    {prodId:"10401514",label:"Rosal cortado seco mistery"},
    {prodId:"10401515",label:"Rosal cortado seco ocean song o boyfriend"},
    {prodId:"10401516",label:"Rosal cortado seco cezanne púrpura"},
    {prodId:"10401517",label:"Rosal cortado seco purple fragrance"},
    {prodId:"10401518",label:"Rosal cortado seco sanaa"},
    {prodId:"10401519",label:"Rosal cortado seco silverstone"},
    {prodId:"10401520",label:"Rosal cortado seco soulmate"},
    {prodId:"10401521",label:"Rosal cortado seco stranger"},
    {prodId:"10401522",label:"Rosal cortado seco tinted blue"},
    {prodId:"10401523",label:"Rosal cortado seco two faces"},
    {prodId:"10401600",label:"Rosas cortadas secas chocolate o marrón"},
    {prodId:"10401601",label:"Rosal cortado seco black lava"},
    {prodId:"10401602",label:"Rosal cortado seco cimarrón"},
    {prodId:"10401603",label:"Rosal cortado seco coffee break"},
    {prodId:"10401604",label:"Rosal cortado seco estelle"},
    {prodId:"10401605",label:"Rosal cortado seco gypsy leonidas"},
    {prodId:"10401606",label:"Rosal cortado seco leonidas"},
    {prodId:"10401607",label:"Rosal cortado seco matilda"},
    {prodId:"10401608",label:"Rosal cortado seco sunny leonidas"},
    {prodId:"10401609",label:"Rosal cortado seco terra nostra"},
    {prodId:"10401610",label:"Rosal cortado seco terracotta"},
    {prodId:"10401700",label:"Rosas cortadas secas crema"},
    {prodId:"10401701",label:"Rosal cortado seco advenire"},
    {prodId:"10401702",label:"Rosal cortado seco alex"},
    {prodId:"10401703",label:"Rosal cortado seco antique brass"},
    {prodId:"10401704",label:"Rosal cortado seco aubade"},
    {prodId:"10401705",label:"Rosal cortado seco beach"},
    {prodId:"10401706",label:"Rosal cortado seco belle pearl"},
    {prodId:"10401707",label:"Rosal cortado seco blush o blush de los andes"},
    {prodId:"10401708",label:"Rosal cortado seco camel"},
    {prodId:"10401709",label:"Rosal cortado seco caramel antike o caramel antique"},
    {prodId:"10401710",label:"Rosal cortado seco champagne"},
    {prodId:"10401711",label:"Rosal cortado seco clear ocean"},
    {prodId:"10401712",label:"Rosal cortado seco combo"},
    {prodId:"10401713",label:"Rosal cortado seco creme de la creme"},
    {prodId:"10401714",label:"Rosal cortado seco emanuella"},
    {prodId:"10401715",label:"Rosal cortado seco evolution"},
    {prodId:"10401716",label:"Rosal cortado seco fedora"},
    {prodId:"10401717",label:"Rosal cortado seco fenice"},
    {prodId:"10401718",label:"Rosal cortado seco french vanilla"},
    {prodId:"10401719",label:"Rosal cortado seco hollywood"},
    {prodId:"10401720",label:"Rosal cortado seco ilios"},
    {prodId:"10401721",label:"Rosal cortado seco jelena"},
    {prodId:"10401722",label:"Rosal cortado seco kameleon"},
    {prodId:"10401723",label:"Rosal cortado seco kentucky"},
    {prodId:"10401724",label:"Rosal cortado seco kings pride"},
    {prodId:"10401725",label:"Rosal cortado seco latin fusion"},
    {prodId:"10401726",label:"Rosal cortado seco lemon dream"},
    {prodId:"10401727",label:"Rosal cortado seco magic moka"},
    {prodId:"10401728",label:"Rosal cortado seco mama mia"},
    {prodId:"10401729",label:"Rosal cortado seco message"},
    {prodId:"10401730",label:"Rosal cortado seco muñeca o munieca"},
    {prodId:"10401731",label:"Rosal cortado seco parfum de rosas"},
    {prodId:"10401732",label:"Rosal cortado seco porcelina"},
    {prodId:"10401733",label:"Rosal cortado seco privilege"},
    {prodId:"10401734",label:"Rosal cortado seco quick sand"},
    {prodId:"10401735",label:"Rosal cortado seco rollercoaster"},
    {prodId:"10401736",label:"Rosal cortado seco romantic curiosa"},
    {prodId:"10401737",label:"Rosal cortado seco safari"},
    {prodId:"10401738",label:"Rosal cortado seco sahara"},
    {prodId:"10401739",label:"Rosal cortado seco sandy femma"},
    {prodId:"10401740",label:"Rosal cortado seco talea"},
    {prodId:"10401741",label:"Rosal cortado seco timeless"},
    {prodId:"10401742",label:"Rosal cortado seco transition"},
    {prodId:"10401743",label:"Rosal cortado seco trump"},
    {prodId:"10401744",label:"Rosal cortado seco twin"},
    {prodId:"10401745",label:"Rosal cortado seco vendela"},
    {prodId:"10401746",label:"Rosal cortado seco virginia"},
    {prodId:"10401800",label:"Rosas cortadas secas verdes o lima"},
    {prodId:"10401801",label:"Rosal cortado seco amandina"},
    {prodId:"10401802",label:"Rosal cortado seco caipirinha"},
    {prodId:"10401803",label:"Rosal cortado seco green fashion"},
    {prodId:"10401804",label:"Rosal cortado seco green tea"},
    {prodId:"10401805",label:"Rosal cortado seco jade"},
    {prodId:"10401806",label:"Rosal cortado seco limbo"},
    {prodId:"10401807",label:"Rosal cortado seco limeña o limenia"},
    {prodId:"10401808",label:"Rosal cortado seco limona"},
    {prodId:"10401809",label:"Rosal cortado seco old dutch"},
    {prodId:"10401810",label:"Rosal cortado seco super green"},
    {prodId:"10401811",label:"Rosal cortado seco sweet green"},
    {prodId:"10401812",label:"Rosal cortado seco cortada seca"},
    {prodId:"10401813",label:"Rosal cortado seco zazu"},
    {prodId:"10401900",label:"Rosas cortadas secas rosado claro"},
    {prodId:"10401901",label:"Rosal cortado seco anna"},
    {prodId:"10401902",label:"Rosal cortado seco bella vita"},
    {prodId:"10401903",label:"Rosal cortado seco bridal dream"},
    {prodId:"10401904",label:"Rosal cortado seco candy bianca"},
    {prodId:"10401905",label:"Rosal cortado seco caress"},
    {prodId:"10401906",label:"Rosal cortado seco carolina"},
    {prodId:"10401907",label:"Rosal cortado seco climax"},
    {prodId:"10401908",label:"Rosal cortado seco danny"},
    {prodId:"10401909",label:"Rosal cortado seco dolce vita"},
    {prodId:"10401910",label:"Rosal cortado seco elite"},
    {prodId:"10401911",label:"Rosal cortado seco emma"},
    {prodId:"10401912",label:"Rosal cortado seco engagement"},
    {prodId:"10401913",label:"Rosal cortado seco esther"},
    {prodId:"10401914",label:"Rosal cortado seco excalibur"},
    {prodId:"10401915",label:"Rosal cortado seco exciting"},
    {prodId:"10401916",label:"Rosal cortado seco first lady"},
    {prodId:"10401917",label:"Rosal cortado seco geraldine"},
    {prodId:"10401918",label:"Rosal cortado seco gotcha"},
    {prodId:"10401919",label:"Rosal cortado seco harmonie"},
    {prodId:"10401920",label:"Rosal cortado seco heaven"},
    {prodId:"10401921",label:"Rosal cortado seco high and elegant"},
    {prodId:"10401922",label:"Rosal cortado seco katherine"},
    {prodId:"10401923",label:"Rosal cortado seco king kong"},
    {prodId:"10401924",label:"Rosal cortado seco livia"},
    {prodId:"10401925",label:"Rosal cortado seco lorena"},
    {prodId:"10401926",label:"Rosal cortado seco lovely"},
    {prodId:"10401927",label:"Rosal cortado seco maaike"},
    {prodId:"10401928",label:"Rosal cortado seco marilyn"},
    {prodId:"10401929",label:"Rosal cortado seco marlise"},
    {prodId:"10401930",label:"Rosal cortado seco miranda o ausimmon"},
    {prodId:"10401931",label:"Rosal cortado seco mona lisa"},
    {prodId:"10401932",label:"Rosal cortado seco nirvana"},
    {prodId:"10401933",label:"Rosal cortado seco o'hara"},
    {prodId:"10401934",label:"Rosal cortado seco ole"},
    {prodId:"10401935",label:"Rosal cortado seco olga"},
    {prodId:"10401936",label:"Rosal cortado seco pacífica"},
    {prodId:"10401937",label:"Rosal cortado seco party mix"},
    {prodId:"10401938",label:"Rosal cortado seco pekoubo o pekcoubo"},
    {prodId:"10401939",label:"Rosal cortado seco phoebe o ausnotice"},
    {prodId:"10401940",label:"Rosal cortado seco pink farfalla"},
    {prodId:"10401941",label:"Rosal cortado seco pink finess"},
    {prodId:"10401942",label:"Rosal cortado seco pink magic"},
    {prodId:"10401943",label:"Rosal cortado seco pink osiana"},
    {prodId:"10401944",label:"Rosal cortado seco pretty woman"},
    {prodId:"10401945",label:"Rosal cortado seco romance"},
    {prodId:"10401946",label:"Rosal cortado seco romantic antike o antique"},
    {prodId:"10401947",label:"Rosal cortado seco rosalind o astew"},
    {prodId:"10401948",label:"Rosal cortado seco rosita vendela"},
    {prodId:"10401949",label:"Rosal cortado seco secret garden"},
    {prodId:"10401950",label:"Rosal cortado seco solaire"},
    {prodId:"10401951",label:"Rosal cortado seco sophie"},
    {prodId:"10401952",label:"Rosal cortado seco sweet akito"},
    {prodId:"10401953",label:"Rosal cortado seco sweet avalanche"},
    {prodId:"10401954",label:"Rosal cortado seco sweet elegance"},
    {prodId:"10401955",label:"Rosal cortado seco sweet pink"},
    {prodId:"10401956",label:"Rosal cortado seco titanic"},
    {prodId:"10401957",label:"Rosal cortado seco toscanini"},
    {prodId:"10401958",label:"Rosal cortado seco vania"},
    {prodId:"10401959",label:"Rosal cortado seco vanity"},
    {prodId:"10401960",label:"Rosal cortado seco vision"},
    {prodId:"10401961",label:"Rosal cortado seco vivaldi"},
    {prodId:"10401962",label:"Rosal cortado seco whisper"},
    {prodId:"10402000",label:"Rosas cortadas secas multicolor rosados"},
    {prodId:"10402001",label:"Rosal cortado seco attracta"},
    {prodId:"10402002",label:"Rosal cortado seco boheme"},
    {prodId:"10402003",label:"Rosal cortado seco carousel"},
    {prodId:"10402004",label:"Rosal cortado seco cezanne"},
    {prodId:"10402005",label:"Rosal cortado seco crazy one"},
    {prodId:"10402006",label:"Rosal cortado seco dance valley"},
    {prodId:"10402007",label:"Rosal cortado seco duett"},
    {prodId:"10402008",label:"Rosal cortado seco esperance"},
    {prodId:"10402009",label:"Rosal cortado seco fiesta"},
    {prodId:"10402010",label:"Rosal cortado seco halloween"},
    {prodId:"10402011",label:"Rosal cortado seco highlander"},
    {prodId:"10402012",label:"Rosal cortado seco hot ambiance"},
    {prodId:"10402013",label:"Rosal cortado seco la belle"},
    {prodId:"10402014",label:"Rosal cortado seco laguna"},
    {prodId:"10402015",label:"Rosal cortado seco latin ambiance"},
    {prodId:"10402016",label:"Rosal cortado seco latin breeze"},
    {prodId:"10402017",label:"Rosal cortado seco long arifa"},
    {prodId:"10402018",label:"Rosal cortado seco murano"},
    {prodId:"10402019",label:"Rosal cortado seco n - joy rose"},
    {prodId:"10402020",label:"Rosal cortado seco panama"},
    {prodId:"10402021",label:"Rosal cortado seco peppermint"},
    {prodId:"10402022",label:"Rosal cortado seco pujama party"},
    {prodId:"10402023",label:"Rosal cortado seco portofino"},
    {prodId:"10402024",label:"Rosal cortado seco priceless"},
    {prodId:"10402025",label:"Rosal cortado seco queen amazon"},
    {prodId:"10402026",label:"Rosal cortado seco ranuncula"},
    {prodId:"10402027",label:"Rosal cortado seco rossini"},
    {prodId:"10402028",label:"Rosal cortado seco sabina o sabrina"},
    {prodId:"10402029",label:"Rosal cortado seco scandal"},
    {prodId:"10402030",label:"Rosal cortado seco silvery pink"},
    {prodId:"10402031",label:"Rosal cortado seco something else"},
    {prodId:"10402032",label:"Rosal cortado seco southine"},
    {prodId:"10402033",label:"Rosal cortado seco sovereign"},
    {prodId:"10402034",label:"Rosal cortado seco super disco"},
    {prodId:"10402035",label:"Rosal cortado seco ts 1968"},
    {prodId:"10402036",label:"Rosal cortado seco variance"},
    {prodId:"10402037",label:"Rosal cortado seco verdi"},
    {prodId:"10402100",label:"Rosas cortadas secas anaranjados"},
    {prodId:"10402101",label:"Rosal cortado seco alhambra"},
    {prodId:"10402102",label:"Rosal cortado seco aloha"},
    {prodId:"10402103",label:"Rosal cortado seco amber"},
    {prodId:"10402104",label:"Rosal cortado seco apache"},
    {prodId:"10402105",label:"Rosal cortado seco arabia"},
    {prodId:"10402106",label:"Rosal cortado seco bengala"},
    {prodId:"10402107",label:"Rosal cortado seco bibi"},
    {prodId:"10402108",label:"Rosal cortado seco caramba"},
    {prodId:"10402109",label:"Rosal cortado seco caramella"},
    {prodId:"10402110",label:"Rosal cortado seco carla"},
    {prodId:"10402111",label:"Rosal cortado seco cartagena"},
    {prodId:"10402112",label:"Rosal cortado seco chanson"},
    {prodId:"10402113",label:"Rosal cortado seco charmer"},
    {prodId:"10402114",label:"Rosal cortado seco cherry brandy"},
    {prodId:"10402115",label:"Rosal cortado seco chilis"},
    {prodId:"10402116",label:"Rosal cortado seco cinnamon"},
    {prodId:"10402117",label:"Rosal cortado seco colandro"},
    {prodId:"10402118",label:"Rosal cortado seco coral sea"},
    {prodId:"10402119",label:"Rosal cortado seco corvette o red covette"},
    {prodId:"10402120",label:"Rosal cortado seco dark milva"},
    {prodId:"10402121",label:"Rosal cortado seco donna"},
    {prodId:"10402122",label:"Rosal cortado seco dreamer"},
    {prodId:"10402123",label:"Rosal cortado seco el dorado"},
    {prodId:"10402124",label:"Rosal cortado seco el toro"},
    {prodId:"10402125",label:"Rosal cortado seco elena"},
    {prodId:"10402126",label:"Rosal cortado seco ensueño"},
    {prodId:"10402127",label:"Rosal cortado seco euforia"},
    {prodId:"10402128",label:"Rosal cortado seco exótica"},
    {prodId:"10402129",label:"Rosal cortado seco fancy amazon"},
    {prodId:"10402130",label:"Rosal cortado seco fiction"},
    {prodId:"10402131",label:"Rosal cortado seco finess"},
    {prodId:"10402132",label:"Rosal cortado seco flameco"},
    {prodId:"10402133",label:"Rosal cortado seco free spirit"},
    {prodId:"10402134",label:"Rosal cortado seco gelato"},
    {prodId:"10402135",label:"Rosal cortado seco gypsy curiosa"},
    {prodId:"10402136",label:"Rosal cortado seco high and magic"},
    {prodId:"10402137",label:"Rosal cortado seco high and orange"},
    {prodId:"10402138",label:"Rosal cortado seco iguana o alegra"},
    {prodId:"10402139",label:"Rosal cortado seco impulse"},
    {prodId:"10402140",label:"Rosal cortado seco indian femma"},
    {prodId:"10402141",label:"Rosal cortado seco indian sunset"},
    {prodId:"10402142",label:"Rosal cortado seco karusso"},
    {prodId:"10402143",label:"Rosal cortado seco kerio"},
    {prodId:"10402144",label:"Rosal cortado seco kiki"},
    {prodId:"10402145",label:"Rosal cortado seco latin circus"},
    {prodId:"10402146",label:"Rosal cortado seco leonisa"},
    {prodId:"10402147",label:"Rosal cortado seco lipstick"},
    {prodId:"10402148",label:"Rosal cortado seco lobita"},
    {prodId:"10402149",label:"Rosal cortado seco luca"},
    {prodId:"10402150",label:"Rosal cortado seco manitou"},
    {prodId:"10402151",label:"Rosal cortado seco mariana"},
    {prodId:"10402152",label:"Rosal cortado seco marjan o pk sensation"},
    {prodId:"10402153",label:"Rosal cortado seco milonga"},
    {prodId:"10402154",label:"Rosal cortado seco milva"},
    {prodId:"10402155",label:"Rosal cortado seco miracle"},
    {prodId:"10402156",label:"Rosal cortado seco mirage"},
    {prodId:"10402157",label:"Rosal cortado seco monte carlo"},
    {prodId:"10402158",label:"Rosal cortado seco movie star"},
    {prodId:"10402159",label:"Rosal cortado seco nikita"},
    {prodId:"10402160",label:"Rosal cortado seco orange flame"},
    {prodId:"10402161",label:"Rosal cortado seco orange france"},
    {prodId:"10402162",label:"Rosal cortado seco orange intuition"},
    {prodId:"10402163",label:"Rosal cortado seco orange unique"},
    {prodId:"10402164",label:"Rosal cortado seco orangine u orangina"},
    {prodId:"10402165",label:"Rosal cortado seco papaya"},
    {prodId:"10402166",label:"Rosal cortado seco pareo"},
    {prodId:"10402167",label:"Rosal cortado seco peach sherbet"},
    {prodId:"10402168",label:"Rosal cortado seco queensday"},
    {prodId:"10402169",label:"Rosal cortado seco rosselle"},
    {prodId:"10402170",label:"Rosal cortado seco royal circus"},
    {prodId:"10402171",label:"Rosal cortado seco sari"},
    {prodId:"10402172",label:"Rosal cortado seco sensual"},
    {prodId:"10402173",label:"Rosal cortado seco soap"},
    {prodId:"10402174",label:"Rosal cortado seco sombrero"},
    {prodId:"10402175",label:"Rosal cortado seco spicy"},
    {prodId:"10402176",label:"Rosal cortado seco estrella 2000"},
    {prodId:"10402177",label:"Rosal cortado seco summer versilia"},
    {prodId:"10402178",label:"Rosal cortado seco trixx"},
    {prodId:"10402179",label:"Rosal cortado seco tropical amazon"},
    {prodId:"10402180",label:"Rosal cortado seco utopia"},
    {prodId:"10402181",label:"Rosal cortado seco valentine"},
    {prodId:"10402182",label:"Rosal cortado seco verano"},
    {prodId:"10402183",label:"Rosal cortado seco versilia"},
    {prodId:"10402184",label:"Rosal cortado seco voodoo"},
    {prodId:"10402185",label:"Rosal cortado seco wow"},
    {prodId:"10402186",label:"Rosal cortado seco yabadabadoo"},
    {prodId:"10402200",label:"Rosas cortadas secas durazno"},
    {prodId:"10402201",label:"Rosal cortado seco alejandra"},
    {prodId:"10402202",label:"Rosal cortado seco azafrán"},
    {prodId:"10402203",label:"Rosal cortado seco big fun"},
    {prodId:"10402204",label:"Rosal cortado seco cabaret"},
    {prodId:"10402205",label:"Rosal cortado seco capuccino"},
    {prodId:"10402206",label:"Rosal cortado seco carpe diem"},
    {prodId:"10402207",label:"Rosal cortado seco cosima"},
    {prodId:"10402208",label:"Rosal cortado seco cumbia"},
    {prodId:"10402209",label:"Rosal cortado seco dream"},
    {prodId:"10402210",label:"Rosal cortado seco época"},
    {prodId:"10402211",label:"Rosal cortado seco fado"},
    {prodId:"10402212",label:"Rosal cortado seco femma"},
    {prodId:"10402213",label:"Rosal cortado seco guajira"},
    {prodId:"10402214",label:"Rosal cortado seco high and arena"},
    {prodId:"10402215",label:"Rosal cortado seco high and dandy"},
    {prodId:"10402216",label:"Rosal cortado seco high and lucky"},
    {prodId:"10402217",label:"Rosal cortado seco high and peach"},
    {prodId:"10402218",label:"Rosal cortado seco imagination"},
    {prodId:"10402219",label:"Rosal cortado seco isis"},
    {prodId:"10402220",label:"Rosal cortado seco joy o light versilia"},
    {prodId:"10402221",label:"Rosal cortado seco juliet ausjameson"},
    {prodId:"10402222",label:"Rosal cortado seco la parisienne"},
    {prodId:"10402223",label:"Rosal cortado seco la perla"},
    {prodId:"10402224",label:"Rosal cortado seco lovita sunblaze"},
    {prodId:"10402225",label:"Rosal cortado seco malilena o marilena"},
    {prodId:"10402226",label:"Rosal cortado seco monyna"},
    {prodId:"10402227",label:"Rosal cortado seco nectarine"},
    {prodId:"10402228",label:"Rosal cortado seco oriental curiosa"},
    {prodId:"10402229",label:"Rosal cortado seco osiana"},
    {prodId:"10402230",label:"Rosal cortado seco preach avalanche"},
    {prodId:"10402231",label:"Rosal cortado seco peach deja vu"},
    {prodId:"10402232",label:"Rosal cortado seco picanto"},
    {prodId:"10402233",label:"Rosal cortado seco prima donna"},
    {prodId:"10402234",label:"Rosal cortado seco sheril"},
    {prodId:"10402235",label:"Rosal cortado seco sirocco"},
    {prodId:"10402236",label:"Rosal cortado seco tamara"},
    {prodId:"10402237",label:"Rosal cortado seco taxo"},
    {prodId:"10402238",label:"Rosal cortado seco trust"},
    {prodId:"10402239",label:"Rosal cortado seco valencia"},
    {prodId:"10402240",label:"Rosal cortado seco cinci"},
    {prodId:"10402241",label:"Rosal cortado seco wanda"},
    {prodId:"10402300",label:"Rosas cortadas secas rosados"},
    {prodId:"10402301",label:"Rosal cortado seco aerobic"},
    {prodId:"10402302",label:"Rosal cortado seco after party"},
    {prodId:"10402303",label:"Rosal cortado seco amsterdam"},
    {prodId:"10402304",label:"Rosal cortado seco aqua rose"},
    {prodId:"10402305",label:"Rosal cortado seco attache"},
    {prodId:"10402306",label:"Rosal cortado seco attitude"},
    {prodId:"10402307",label:"Rosal cortado seco ballet"},
    {prodId:"10402308",label:"Rosal cortado seco belami"},
    {prodId:"10402309",label:"Rosal cortado seco bella voo o belle vue"},
    {prodId:"10402310",label:"Rosal cortado seco bling bling"},
    {prodId:"10402311",label:"Rosal cortado seco blushing akito"},
    {prodId:"10402312",label:"Rosal cortado seco brooke"},
    {prodId:"10402313",label:"Rosal cortado seco bugatti"},
    {prodId:"10402314",label:"Rosal cortado seco cadillac"},
    {prodId:"10402315",label:"Rosal cortado seco carnaval"},
    {prodId:"10402316",label:"Rosal cortado seco cereza"},
    {prodId:"10402317",label:"Rosal cortado seco charming"},
    {prodId:"10402318",label:"Rosal cortado seco cherry o"},
    {prodId:"10402319",label:"Rosal cortado seco ciciolina"},
    {prodId:"10402320",label:"Rosal cortado seco classic cezanne"},
    {prodId:"10402321",label:"Rosal cortado seco clasic duett"},
    {prodId:"10402322",label:"Rosal cortado seco cosmiq"},
    {prodId:"10402323",label:"Rosal cortado seco dark engagement"},
    {prodId:"10402324",label:"Rosal cortado seco daytona"},
    {prodId:"10402325",label:"Rosal cortado seco dekora"},
    {prodId:"10402326",label:"Rosal cortado seco dolores"},
    {prodId:"10402327",label:"Rosal cortado seco eliza"},
    {prodId:"10402328",label:"Rosal cortado seco baccara"},
    {prodId:"10402329",label:"Rosal cortado seco full house"},
    {prodId:"10402330",label:"Rosal cortado seco funky"},
    {prodId:"10402331",label:"Rosal cortado seco giliane"},
    {prodId:"10402332",label:"Rosal cortado seco gran europe"},
    {prodId:"10402333",label:"Rosal cortado seco habari"},
    {prodId:"10402334",label:"Rosal cortado seco hanseat"},
    {prodId:"10402335",label:"Rosal cortado seco high and amazing"},
    {prodId:"10402336",label:"Rosal cortado seco high and bonita"},
    {prodId:"10402337",label:"Rosal cortado seco high and booming"},
    {prodId:"10402338",label:"Rosal cortado seco high and fantasy"},
    {prodId:"10402339",label:"Rosal cortado seco high and rich"},
    {prodId:"10402340",label:"Rosal cortado seco hot lady"},
    {prodId:"10402341",label:"Rosal cortado seco hot princess"},
    {prodId:"10402342",label:"Rosal cortado seco inspiration"},
    {prodId:"10402343",label:"Rosal cortado seco jeimy"},
    {prodId:"10402344",label:"Rosal cortado seco kachita"},
    {prodId:"10402345",label:"Rosal cortado seco karen"},
    {prodId:"10402346",label:"Rosal cortado seco kenji"},
    {prodId:"10402347",label:"Rosal cortado seco kiko"},
    {prodId:"10402348",label:"Rosal cortado seco laser"},
    {prodId:"10402349",label:"Rosal cortado seco latin duett"},
    {prodId:"10402350",label:"Rosal cortado seco latin fever"},
    {prodId:"10402351",label:"Rosal cortado seco lifestyle"},
    {prodId:"10402352",label:"Rosal cortado seco light orlando"},
    {prodId:"10402353",label:"Rosal cortado seco lovely dreams"},
    {prodId:"10402354",label:"Rosal cortado seco loyalty"},
    {prodId:"10402355",label:"Rosal cortado seco malibu"},
    {prodId:"10402356",label:"Rosal cortado seco mata - hari"},
    {prodId:"10402357",label:"Rosal cortado seco memphis"},
    {prodId:"10402358",label:"Rosal cortado seco mi amor"},
    {prodId:"10402359",label:"Rosal cortado seco miami"},
    {prodId:"10402360",label:"Rosal cortado seco michelle"},
    {prodId:"10402361",label:"Rosal cortado seco mikaela"},
    {prodId:"10402362",label:"Rosal cortado seco orchestra"},
    {prodId:"10402363",label:"Rosal cortado seco orlando"},
    {prodId:"10402364",label:"Rosal cortado seco osadia"},
    {prodId:"10402365",label:"Rosal cortado seco paeonia freelander"},
    {prodId:"10402366",label:"Rosal cortado seco paula"},
    {prodId:"10402367",label:"Rosal cortado seco pavarotti"},
    {prodId:"10402368",label:"Rosal cortado seco pink intuition"},
    {prodId:"10402369",label:"Rosal cortado seco poison"},
    {prodId:"10402370",label:"Rosal cortado seco princess"},
    {prodId:"10402371",label:"Rosal cortado seco queen mary"},
    {prodId:"10402372",label:"Rosal cortado seco raphaela"},
    {prodId:"10402373",label:"Rosal cortado seco raspberry"},
    {prodId:"10402374",label:"Rosal cortado seco ravel"},
    {prodId:"10402375",label:"Rosal cortado seco riviera"},
    {prodId:"10402376",label:"Rosal cortado seco sade"},
    {prodId:"10402377",label:"Rosal cortado seco sashimi"},
    {prodId:"10402378",label:"Rosal cortado seco shanya"},
    {prodId:"10402379",label:"Rosal cortado seco shocking versilia"},
    {prodId:"10402380",label:"Rosal cortado seco solitaire"},
    {prodId:"10402381",label:"Rosal cortado seco something different"},
    {prodId:"10402382",label:"Rosal cortado seco splendid renate"},
    {prodId:"10402383",label:"Rosal cortado seco star"},
    {prodId:"10402384",label:"Rosal cortado seco sweet candia"},
    {prodId:"10402385",label:"Rosal cortado seco sweet moments"},
    {prodId:"10402386",label:"Rosal cortado seco sweet unique"},
    {prodId:"10402387",label:"Rosal cortado seco taboo"},
    {prodId:"10402388",label:"Rosal cortado seco timona"},
    {prodId:"10402389",label:"Rosal cortado seco topaz"},
    {prodId:"10402390",label:"Rosal cortado seco vogue"},
    {prodId:"10402391",label:"Rosal cortado seco voila"},
    {prodId:"10402392",label:"Rosal cortado seco wild"},
    {prodId:"10402393",label:"Rosal cortado seco yves piaget"},
    {prodId:"10402400",label:"Rosas cortadas secas rojos o burgundy"},
    {prodId:"10402401",label:"Rosal cortado seco african dawn"},
    {prodId:"10402402",label:"Rosal cortado seco amada"},
    {prodId:"10402403",label:"Rosal cortado seco black baccara"},
    {prodId:"10402404",label:"Rosal cortado seco black beauty"},
    {prodId:"10402405",label:"Rosal cortado seco black finess o black magic"},
    {prodId:"10402406",label:"Rosal cortado seco black magic"},
    {prodId:"10402407",label:"Rosal cortado seco bohemian o pasarela"},
    {prodId:"10402408",label:"Rosal cortado seco breathless"},
    {prodId:"10402409",label:"Rosal cortado seco caballero"},
    {prodId:"10402410",label:"Rosal cortado seco carrera"},
    {prodId:"10402411",label:"Rosal cortado seco charlene"},
    {prodId:"10402412",label:"Rosal cortado seco charlotte"},
    {prodId:"10402413",label:"Rosal cortado seco cherry lady"},
    {prodId:"10402414",label:"Rosal cortado seco cherry love"},
    {prodId:"10402415",label:"Rosal cortado seco classy rose"},
    {prodId:"10402416",label:"Rosal cortado seco colorado velvet"},
    {prodId:"10402417",label:"Rosal cortado seco corazón"},
    {prodId:"10402418",label:"Rosal cortado seco corrida"},
    {prodId:"10402419",label:"Rosal cortado seco dynamite"},
    {prodId:"10402420",label:"Rosal cortado seco eurored"},
    {prodId:"10402421",label:"Rosal cortado seco fashion"},
    {prodId:"10402422",label:"Rosal cortado seco fire and ice"},
    {prodId:"10402423",label:"Rosal cortado seco first red"},
    {prodId:"10402424",label:"Rosal cortado seco forever young"},
    {prodId:"10402425",label:"Rosal cortado seco freedom"},
    {prodId:"10402426",label:"Rosal cortado seco freestyle"},
    {prodId:"10402427",label:"Rosal cortado seco friendship"},
    {prodId:"10402428",label:"Rosal cortado seco gospel"},
    {prodId:"10402429",label:"Rosal cortado seco graffity"},
    {prodId:"10402430",label:"Rosal cortado seco grand gala"},
    {prodId:"10402431",label:"Rosal cortado seco grand prix"},
    {prodId:"10402432",label:"Rosal cortado seco grand classe"},
    {prodId:"10402433",label:"Rosal cortado seco hearts"},
    {prodId:"10402434",label:"Rosal cortado seco heat"},
    {prodId:"10402435",label:"Rosal cortado seco hocus pocus"},
    {prodId:"10402436",label:"Rosal cortado seco lady in red"},
    {prodId:"10402437",label:"Rosal cortado seco latin lady"},
    {prodId:"10402438",label:"Rosal cortado seco legend"},
    {prodId:"10402439",label:"Rosal cortado seco lulu"},
    {prodId:"10402440",label:"Rosal cortado seco luna rossa"},
    {prodId:"10402441",label:"Rosal cortado seco luxor"},
    {prodId:"10402442",label:"Rosal cortado seco madame delbard o carola"},
    {prodId:"10402443",label:"Rosal cortado seco miss paris"},
    {prodId:"10402444",label:"Rosal cortado seco nicole"},
    {prodId:"10402445",label:"Rosal cortado seco night fever"},
    {prodId:"10402446",label:"Rosal cortado seco obsession"},
    {prodId:"10402447",label:"Rosal cortado seco opium"},
    {prodId:"10402448",label:"Rosal cortado seco paz"},
    {prodId:"10402449",label:"Rosal cortado seco preference"},
    {prodId:"10402450",label:"Rosal cortado seco red berlin"},
    {prodId:"10402451",label:"Rosal cortado seco red bull"},
    {prodId:"10402452",label:"Rosal cortado seco red calypso"},
    {prodId:"10402453",label:"Rosal cortado seco red diamond"},
    {prodId:"10402454",label:"Rosal cortado seco red fantasy"},
    {prodId:"10402455",label:"Rosal cortado seco red france"},
    {prodId:"10402456",label:"Rosal cortado seco red intuition"},
    {prodId:"10402457",label:"Rosal cortado seco red jewel"},
    {prodId:"10402458",label:"Rosal cortado seco red magic"},
    {prodId:"10402459",label:"Rosal cortado seco red one"},
    {prodId:"10402460",label:"Rosal cortado seco red paris"},
    {prodId:"10402461",label:"Rosal cortado seco red princess"},
    {prodId:"10402462",label:"Rosal cortado seco red sensation o colorad"},
    {prodId:"10402463",label:"Rosal cortado seco red unique"},
    {prodId:"10402464",label:"Rosal cortado seco rockefeller"},
    {prodId:"10402465",label:"Rosal cortado seco romeo"},
    {prodId:"10402466",label:"Rosal cortado seco rouge baiser"},
    {prodId:"10402467",label:"Rosal cortado seco roulette"},
    {prodId:"10402468",label:"Rosal cortado seco royal massai"},
    {prodId:"10402469",label:"Rosal cortado seco royal red"},
    {prodId:"10402470",label:"Rosal cortado seco samurai"},
    {prodId:"10402471",label:"Rosal cortado seco sexy red"},
    {prodId:"10402472",label:"Rosal cortado seco starfire"},
    {prodId:"10402473",label:"Rosal cortado seco tango"},
    {prodId:"10402474",label:"Rosal cortado seco tiger tail"},
    {prodId:"10402475",label:"Rosal cortado seco tinto"},
    {prodId:"10402476",label:"Rosal cortado seco top secret"},
    {prodId:"10402477",label:"Rosal cortado seco vital"},
    {prodId:"10402478",label:"Rosal cortado seco wisdom"},
    {prodId:"10402479",label:"Rosal cortado seco xantia"},
    {prodId:"10402480",label:"Rosal cortado seco xcite"},
    {prodId:"10402500",label:"Rosas cortadas secas sweetheart"},
    {prodId:"10402501",label:"Rosal cortado seco burgundy sweetheart"},
    {prodId:"10402502",label:"Rosal cortado seco cream sweetheart"},
    {prodId:"10402503",label:"Rosal cortado seco hot pink sweetheart"},
    {prodId:"10402504",label:"Rosal cortado seco lavender sweetheart"},
    {prodId:"10402505",label:"Rosal cortado seco light pink sweetheart"},
    {prodId:"10402506",label:"Rosal cortado seco orange sweetheart"},
    {prodId:"10402507",label:"Rosal cortado seco peach sweetheart"},
    {prodId:"10402508",label:"Rosal cortado seco red sweetheart"},
    {prodId:"10402509",label:"Rosal cortado seco white sweetheart"},
    {prodId:"10402510",label:"Rosal cortado seco yelllow sweetheart"},
    {prodId:"10402600",label:"Rosas cortadas secas blancos"},
    {prodId:"10402601",label:"Rosal cortado seco absolut"},
    {prodId:"10402602",label:"Rosal cortado seco aida"},
    {prodId:"10402603",label:"Rosal cortado seco akito"},
    {prodId:"10402604",label:"Rosal cortado seco amelia"},
    {prodId:"10402605",label:"Rosal cortado seco anastasia"},
    {prodId:"10402606",label:"Rosal cortado seco andean crystal"},
    {prodId:"10402607",label:"Rosal cortado seco angel"},
    {prodId:"10402608",label:"Rosal cortado seco annemarie"},
    {prodId:"10402609",label:"Rosal cortado seco avalanche"},
    {prodId:"10402610",label:"Rosal cortado seco bianca"},
    {prodId:"10402611",label:"Rosal cortado seco blizzard"},
    {prodId:"10402612",label:"Rosal cortado seco bridal akito"},
    {prodId:"10402613",label:"Rosal cortado seco domenica"},
    {prodId:"10402614",label:"Rosal cortado seco escimo"},
    {prodId:"10402615",label:"Rosal cortado seco farfalla"},
    {prodId:"10402616",label:"Rosal cortado seco high and peace"},
    {prodId:"10402617",label:"Rosal cortado seco high and pure"},
    {prodId:"10402618",label:"Rosal cortado seco inocencia o innocenti"},
    {prodId:"10402619",label:"Rosal cortado seco ivory"},
    {prodId:"10402620",label:"Rosal cortado seco mondial"},
    {prodId:"10402621",label:"Rosal cortado seco mount everest"},
    {prodId:"10402622",label:"Rosal cortado seco nova zembla"},
    {prodId:"10402623",label:"Rosal cortado seco patience o auspator"},
    {prodId:"10402624",label:"Rosal cortado seco polar star"},
    {prodId:"10402625",label:"Rosal cortado seco polo"},
    {prodId:"10402626",label:"Rosal cortado seco proud"},
    {prodId:"10402627",label:"Rosal cortado seco snowy jewel"},
    {prodId:"10402628",label:"Rosal cortado seco tibet"},
    {prodId:"10402629",label:"Rosal cortado seco tineke"},
    {prodId:"10402630",label:"Rosal cortado seco vitality"},
    {prodId:"10402631",label:"Rosal cortado seco white cadillac"},
    {prodId:"10402632",label:"Rosal cortado seco white dove"},
    {prodId:"10402700",label:"Rosas cortadas secas amarillos"},
    {prodId:"10402701",label:"Rosal cortado seco aalsmeer gold"},
    {prodId:"10402702",label:"Rosal cortado seco alina"},
    {prodId:"10402703",label:"Rosal cortado seco ambiance"},
    {prodId:"10402704",label:"Rosal cortado seco aquarel"},
    {prodId:"10402705",label:"Rosal cortado seco autumn"},
    {prodId:"10402706",label:"Rosal cortado seco brasil"},
    {prodId:"10402707",label:"Rosal cortado seco candle light"},
    {prodId:"10402708",label:"Rosal cortado seco cantata o cantate"},
    {prodId:"10402709",label:"Rosal cortado seco capriccio"},
    {prodId:"10402710",label:"Rosal cortado seco caribbean"},
    {prodId:"10402711",label:"Rosal cortado seco circus"},
    {prodId:"10402712",label:"Rosal cortado seco citran"},
    {prodId:"10402713",label:"Rosal cortado seco concorde"},
    {prodId:"10402714",label:"Rosal cortado seco conga"},
    {prodId:"10402715",label:"Rosal cortado seco deja vu"},
    {prodId:"10402716",label:"Rosal cortado seco desire"},
    {prodId:"10402717",label:"Rosal cortado seco donia sol"},
    {prodId:"10402718",label:"Rosal cortado seco dueto"},
    {prodId:"10402719",label:"Rosal cortado seco erin"},
    {prodId:"10402720",label:"Rosal cortado seco exotic curiosa"},
    {prodId:"10402721",label:"Rosal cortado seco feria"},
    {prodId:"10402722",label:"Rosal cortado seco fire bird"},
    {prodId:"10402723",label:"Rosal cortado seco florida"},
    {prodId:"10402724",label:"Rosal cortado seco friendly"},
    {prodId:"10402725",label:"Rosal cortado seco gallinda"},
    {prodId:"10402726",label:"Rosal cortado seco geisha"},
    {prodId:"10402727",label:"Rosal cortado seco gelbe"},
    {prodId:"10402728",label:"Rosal cortado seco gelosia o yellow flame"},
    {prodId:"10402729",label:"Rosal cortado seco gold rush"},
    {prodId:"10402730",label:"Rosal cortado seco gold star"},
    {prodId:"10402731",label:"Rosal cortado seco gold strike"},
    {prodId:"10402732",label:"Rosal cortado seco golda"},
    {prodId:"10402733",label:"Rosal cortado seco golden fashion"},
    {prodId:"10402734",label:"Rosal cortado seco golden gate"},
    {prodId:"10402735",label:"Rosal cortado seco gran dorado"},
    {prodId:"10402736",label:"Rosal cortado seco helio"},
    {prodId:"10402737",label:"Rosal cortado seco high and exotic"},
    {prodId:"10402738",label:"Rosal cortado seco high and yellow"},
    {prodId:"10402739",label:"Rosal cortado seco high and yellow magic"},
    {prodId:"10402740",label:"Rosal cortado seco high society"},
    {prodId:"10402741",label:"Rosal cortado seco hummer"},
    {prodId:"10402742",label:"Rosal cortado seco idole o elle"},
    {prodId:"10402743",label:"Rosal cortado seco inti"},
    {prodId:"10402744",label:"Rosal cortado seco jet set"},
    {prodId:"10402745",label:"Rosal cortado seco judy"},
    {prodId:"10402746",label:"Rosal cortado seco jupiter"},
    {prodId:"10402747",label:"Rosal cortado seco konfetti"},
    {prodId:"10402748",label:"Rosal cortado seco kyara o kira"},
    {prodId:"10402749",label:"Rosal cortado seco latin beauty"},
    {prodId:"10402750",label:"Rosal cortado seco latin spirit"},
    {prodId:"10402751",label:"Rosal cortado seco latina"},
    {prodId:"10402752",label:"Rosal cortado seco lina"},
    {prodId:"10402753",label:"Rosal cortado seco lindsey"},
    {prodId:"10402754",label:"Rosal cortado seco male"},
    {prodId:"10402755",label:"Rosal cortado seco marie clare"},
    {prodId:"10402756",label:"Rosal cortado seco marisa"},
    {prodId:"10402757",label:"Rosal cortado seco matchball"},
    {prodId:"10402758",label:"Rosal cortado seco melon"},
    {prodId:"10402759",label:"Rosal cortado seco mohana"},
    {prodId:"10402760",label:"Rosal cortado seco okie dokie"},
    {prodId:"10402761",label:"Rosal cortado seco pailine"},
    {prodId:"10402762",label:"Rosal cortado seco parrot"},
    {prodId:"10402763",label:"Rosal cortado seco rio d oro"},
    {prodId:"10402764",label:"Rosal cortado seco salami"},
    {prodId:"10402765",label:"Rosal cortado seco santa fe"},
    {prodId:"10402766",label:"Rosal cortado seco skyline"},
    {prodId:"10402767",label:"Rosal cortado seco sonrisa"},
    {prodId:"10402768",label:"Rosal cortado seco star ambiance"},
    {prodId:"10402769",label:"Rosal cortado seco starbust"},
    {prodId:"10402770",label:"Rosal cortado seco sun king"},
    {prodId:"10402771",label:"Rosal cortado seco sunmaster"},
    {prodId:"10402772",label:"Rosal cortado seco sunny milva"},
    {prodId:"10402773",label:"Rosal cortado seco sushi"},
    {prodId:"10402774",label:"Rosal cortado seco tabasco"},
    {prodId:"10402775",label:"Rosal cortado seco tara"},
    {prodId:"10402776",label:"Rosal cortado seco tresor 2000"},
    {prodId:"10402777",label:"Rosal cortado seco ooty"},
    {prodId:"10402778",label:"Rosal cortado seco yellow coral"},
    {prodId:"10402779",label:"Rosal cortado seco yellow finess"},
    {prodId:"10402780",label:"Rosal cortado seco yellow submarine"},
    {prodId:"10402781",label:"Rosal cortado seco yellow sunset"},
    {prodId:"10402782",label:"Rosal cortado seco yelllow timeless"},
    {prodId:"10402800",label:"Rosas cortadas secas spray"},
    {prodId:"10402801",label:"Rosal cortado seco alegría spray"},
    {prodId:"10402802",label:"Rosal cortado seco andrea follies"},
    {prodId:"10402803",label:"Rosal cortado seco antara follies"},
    {prodId:"10402804",label:"Rosal cortado seco arrow follies"},
    {prodId:"10402805",label:"Rosal cortado seco babe spray"},
    {prodId:"10402806",label:"Rosal cortado seco bellina collection"},
    {prodId:"10402807",label:"Rosal cortado seco blue moon"},
    {prodId:"10402808",label:"Rosal cortado seco chablis spray"},
    {prodId:"10402809",label:"Rosal cortado seco cherry follies"},
    {prodId:"10402810",label:"Rosal cortado seco chess spray"},
    {prodId:"10402811",label:"Rosal cortado seco classic lydia spray"},
    {prodId:"10402812",label:"Rosal cortado seco cream gracia spray"},
    {prodId:"10402813",label:"Rosal cortado seco cream lydia srpay"},
    {prodId:"10402814",label:"Rosal cortado seco cream sensation spray"},
    {prodId:"10402815",label:"Rosal cortado seco cremita spray"},
    {prodId:"10402816",label:"Rosal cortado seco diablo spray"},
    {prodId:"10402817",label:"Rosal cortado seco electra spray"},
    {prodId:"10402818",label:"Rosal cortado seco fire king spray"},
    {prodId:"10402819",label:"Rosal cortado seco fleurspray"},
    {prodId:"10402820",label:"Rosal cortado seco girlie follies spray"},
    {prodId:"10402821",label:"Rosal cortado seco giselle follies spray"},
    {prodId:"10402822",label:"Rosal cortado seco golden collection spray"},
    {prodId:"10402823",label:"Rosal cortado seco golden mimi spray"},
    {prodId:"10402824",label:"Rosal cortado seco gracia spray"},
    {prodId:"10402825",label:"Rosal cortado seco hot majolica spray"},
    {prodId:"10402826",label:"Rosal cortado seco hot pink follies spray"},
    {prodId:"10402827",label:"Rosal cortado seco ilse spray"},
    {prodId:"10402828",label:"Rosal cortado seco jelena spray"},
    {prodId:"10402829",label:"Rosal cortado seco laminuette spray"},
    {prodId:"10402830",label:"Rosal cortado seco lavender follies spray"},
    {prodId:"10402831",label:"Rosal cortado seco limoncello spray"},
    {prodId:"10402832",label:"Rosal cortado seco little silver spray"},
    {prodId:"10402833",label:"Rosal cortado seco lovely lydia spray"},
    {prodId:"10402834",label:"Rosal cortado seco lucy spray"},
    {prodId:"10402835",label:"Rosal cortado seco lydia spray"},
    {prodId:"10402836",label:"Rosal cortado seco macarena spray"},
    {prodId:"10402837",label:"Rosal cortado seco magic sensation spray"},
    {prodId:"10402838",label:"Rosal cortado seco majolica spray"},
    {prodId:"10402839",label:"Rosal cortado seco mambo número 5 spray"},
    {prodId:"10402840",label:"Rosal cortado seco mambo spray"},
    {prodId:"10402841",label:"Rosal cortado seco marlene spray"},
    {prodId:"10402842",label:"Rosal cortado seco mimi eden spray"},
    {prodId:"10402843",label:"Rosal cortado seco minou spray"},
    {prodId:"10402844",label:"Rosal cortado seco nikita spray"},
    {prodId:"10402845",label:"Rosal cortado seco novel collection spray"},
    {prodId:"10402846",label:"Rosal cortado seco orange success spray"},
    {prodId:"10402847",label:"Rosal cortado seco pepita spray"},
    {prodId:"10402848",label:"Rosal cortado seco pink flash spray"},
    {prodId:"10402849",label:"Rosal cortado seco pink sensation spray"},
    {prodId:"10402850",label:"Rosal cortado seco porcelina spray"},
    {prodId:"10402851",label:"Rosal cortado seco princess spray"},
    {prodId:"10402852",label:"Rosal cortado seco purple mikado spray"},
    {prodId:"10402853",label:"Rosal cortado seco red angel spray"},
    {prodId:"10402854",label:"Rosal cortado seco red collection spray"},
    {prodId:"10402855",label:"Rosal cortado seco red hero spray"},
    {prodId:"10402856",label:"Rosal cortado seco red mikado spray"},
    {prodId:"10402857",label:"Rosal cortado seco red vision spray"},
    {prodId:"10402858",label:"Rosal cortado seco ritmo spray"},
    {prodId:"10402859",label:"Rosal cortado seco romance mikado o eva spray"},
    {prodId:"10402860",label:"Rosal cortado seco romántica follies spray"},
    {prodId:"10402861",label:"Rosal cortado seco rubicon spray"},
    {prodId:"10402862",label:"Rosal cortado seco rumba spray"},
    {prodId:"10402863",label:"Rosal cortado seco salsa spray"},
    {prodId:"10402864",label:"Rosal cortado seco sangrita spray"},
    {prodId:"10402865",label:"Rosal cortado seco santa bárbara spray"},
    {prodId:"10402866",label:"Rosal cortado seco sashaba spray"},
    {prodId:"10402867",label:"Rosal cortado seco scarlett spray"},
    {prodId:"10402868",label:"Rosal cortado seco seline spray"},
    {prodId:"10402869",label:"Rosal cortado seco sensation spray"},
    {prodId:"10402870",label:"Rosal cortado seco silver collection spray"},
    {prodId:"10402871",label:"Rosal cortado seco silver sensation spray"},
    {prodId:"10402872",label:"Rosal cortado seco snowdance spray"},
    {prodId:"10402873",label:"Rosal cortado seco snowflake spray"},
    {prodId:"10402874",label:"Rosal cortado seco suncity spray"},
    {prodId:"10402875",label:"Rosal cortado seco super nova spray"},
    {prodId:"10402876",label:"Rosal cortado seco sweet sensation spray"},
    {prodId:"10402877",label:"Rosal cortado seco taifun o typhoon spray"},
    {prodId:"10402878",label:"Rosal cortado seco tamango spray"},
    {prodId:"10402879",label:"Rosal cortado seco tanger follies spray"},
    {prodId:"10402880",label:"Rosal cortado seco tiara spray"},
    {prodId:"10402881",label:"Rosal cortado seco tiramisú spray"},
    {prodId:"10402882",label:"Rosal cortado seco twinkle bride spray"},
    {prodId:"10402883",label:"Rosal cortado seco viviane spray"},
    {prodId:"10402884",label:"Rosal cortado seco white majolica spray"},
    {prodId:"10402885",label:"Rosal cortado seco white mikado spray"},
    {prodId:"10402886",label:"Rosal cortado seco xentina spray"},
    {prodId:"10402887",label:"Rosal cortado seco yellow babe spray"},
    {prodId:"10402888",label:"Rosal cortado seco yellow follies spray"},
    {prodId:"10411500",label:"Anturios cortado secos"},
    {prodId:"10411501",label:"Anturio cortado seco chocolate"},
    {prodId:"10411502",label:"Anturio cortado seco rojo oscuro"},
    {prodId:"10411503",label:"Anturio cortado seco verde"},
    {prodId:"10411504",label:"Anturio cortado seco rosado fuerte"},
    {prodId:"10411505",label:"Anturio cortado seco mickey mouse"},
    {prodId:"10411506",label:"Anturio cortado seco obake verde y blanco"},
    {prodId:"10411507",label:"Anturio cortado seco obake rojo y verde"},
    {prodId:"10411508",label:"Anturio cortado seco anaranjado"},
    {prodId:"10411509",label:"Anturio cortado seco durazno"},
    {prodId:"10411510",label:"Anturio cortado seco picasso o pecoso"},
    {prodId:"10411511",label:"Anturio cortado seco rojo"},
    {prodId:"10411512",label:"Anturio cortado seco splash"},
    {prodId:"10411513",label:"Anturio cortado seco tropic fire"},
    {prodId:"10411514",label:"Anturio cortado seco verde tulipán"},
    {prodId:"10411515",label:"Anturio cortado seco rosado tulipán"},
    {prodId:"10411516",label:"Anturio cortado seco púrpura tulipán"},
    {prodId:"10411517",label:"Anturio cortado seco rojo tulipán"},
    {prodId:"10411518",label:"Anturio cortado seco blanco"},
    {prodId:"10411519",label:"Anturio cortado seco blanco salvaje"},
    {prodId:"10411600",label:"Allium cortado seco"},
    {prodId:"10411601",label:"Allium cortado seco ambassador"},
    {prodId:"10411602",label:"Allium cortado seco ampeloprasum"},
    {prodId:"10411603",label:"Allium cortado seco bullit o drumstick"},
    {prodId:"10411604",label:"Allium cortado seco christophii"},
    {prodId:"10411605",label:"Allium cortado seco cowanii"},
    {prodId:"10411606",label:"Allium cortado seco gigante"},
    {prodId:"10411607",label:"Allium cortado seco gladiador"},
    {prodId:"10411608",label:"Allium cortado seco globemaster"},
    {prodId:"10411609",label:"Allium cortado seco bola de golf"},
    {prodId:"10411610",label:"Allium cortado seco cabello"},
    {prodId:"10411611",label:"Allium cortado seco rosado gigante"},
    {prodId:"10411612",label:"Allium cortado seco sensación púrpura"},
    {prodId:"10411613",label:"Allium cortado seco sicilum hanging"},
    {prodId:"10411614",label:"Allium cortado seco spider schubertii"},
    {prodId:"10411615",label:"Allium cortado seco spray moly"},
    {prodId:"10411616",label:"Allium cortado seco spray roseum"},
    {prodId:"10411617",label:"Allium cortado seco tuberosum"},
    {prodId:"10411618",label:"Allium cortado seco unifolium o spray"},
    {prodId:"10411619",label:"Allium cortado seco monte everest blanco"},
    {prodId:"10411900",label:"Amaryllis cortado seco"},
    {prodId:"10411901",label:"Amaryllis cortado seco naranja"},
    {prodId:"10411902",label:"Amaryllis cortado seco orange nagano"},
    {prodId:"10411903",label:"Amaryllis cortado seco pigmeo mini"},
    {prodId:"10411904",label:"Amaryllis cortado seco red lion"},
    {prodId:"10411905",label:"Amaryllis cortado seco rilona"},
    {prodId:"10411906",label:"Amaryllis cortado seco royal velvet"},
    {prodId:"10411907",label:"Amaryllis cortado seco sonatini naranja"},
    {prodId:"10411908",label:"Amaryllis cortado seco sonatini rojo"},
    {prodId:"10411909",label:"Amaryllis cortado seco tango"},
    {prodId:"10411910",label:"Amaryllis cortado seco tinto night"},
    {prodId:"10412000",label:"Anémona cortada seca"},
    {prodId:"10412001",label:"Anémona cortada seca aubergine"},
    {prodId:"10412002",label:"Anémona cortada seca negra"},
    {prodId:"10412003",label:"Anémona cortada seca azul"},
    {prodId:"10412004",label:"Anémona cortada seca cereza"},
    {prodId:"10412005",label:"Anémona cortada seca coronaria"},
    {prodId:"10412006",label:"Anémona cortada seca rosado fuerte"},
    {prodId:"10412007",label:"Anémona cortada seca rosado claro"},
    {prodId:"10412008",label:"Anémona cortada seca rosado"},
    {prodId:"10412009",label:"Anémona cortada seca púrpura"},
    {prodId:"10412010",label:"Anémona cortada seca roja"},
    {prodId:"10412011",label:"Anémona cortada seca blanca"},
    {prodId:"10412100",label:"Asclepia cortada seca"},
    {prodId:"10412101",label:"Asclepia cortada seca lavanda"},
    {prodId:"10412102",label:"Asclepia cortada seca moby dick"},
    {prodId:"10412103",label:"Asclepia cortada seca tuberosa"},
    {prodId:"10412104",label:"Asclepia cortada seca blanca"},
    {prodId:"10412200",label:"Aster cortada seca"},
    {prodId:"10412201",label:"Aster cortada seca beauty"},
    {prodId:"10412202",label:"Aster cortada seca japonés azul"},
    {prodId:"10412203",label:"Aster cortada seca japonés verde"},
    {prodId:"10412204",label:"Aster cortada seca japonés rosado fuerte"},
    {prodId:"10412205",label:"Aster cortada seca japonés lavanda"},
    {prodId:"10412206",label:"Aster cortada seca japonés rosado claro"},
    {prodId:"10412207",label:"Aster cortada seca japonés durazno"},
    {prodId:"10412208",label:"Aster cortada seca japonés rosado"},
    {prodId:"10412209",label:"Aster cortada seca japonés púrpura"},
    {prodId:"10412210",label:"Aster cortada seca japonés rojo"},
    {prodId:"10412211",label:"Aster cortada seca japonés spider"},
    {prodId:"10412212",label:"Aster cortada seca japonés  blanco"},
    {prodId:"10412213",label:"Aster cortada seca novi belgii rosado fuerte"},
    {prodId:"10412214",label:"Aster cortada seca novi belgii lavanda"},
    {prodId:"10412215",label:"Aster cortada seca novi belgii rosado"},
    {prodId:"10412216",label:"Aster cortada seca novi belgii púrpura"},
    {prodId:"10412217",label:"Aster cortada seca novi belgii blanco"},
    {prodId:"10412218",label:"Aster cortada seca solidago"},
    {prodId:"10412219",label:"Aster cortada seca spider"},
    {prodId:"10412300",label:"Berzelia cortada seca"},
    {prodId:"10412301",label:"Berzelia lanuginosa cortada seca abrotnoides"},
    {prodId:"10412302",label:"Berzelia lanuginosa cortada seca fireball"},
    {prodId:"10412303",label:"Berzelia lanuginosa cortada seca galpinii"},
    {prodId:"10412304",label:"Berzelia lanuginosa cortada seca galpini o baubles"},
    {prodId:"10412305",label:"Berzelia lanuginosa cortada seca squarrosa"},
    {prodId:"10412400",label:"Bouvaria cortada seca"},
    {prodId:"10412401",label:"Bouvardia cortada seca doble rosado fuerte"},
    {prodId:"10412402",label:"Bouvardia cortada seca rosado claro"},
    {prodId:"10412403",label:"Bouvardia cortada seca doble rosado claro"},
    {prodId:"10412404",label:"Bouvardia cortada seca roja"},
    {prodId:"10412405",label:"Bouvardia cortada seca blanca"},
    {prodId:"10412500",label:"Brodiaea cortada seca"},
    {prodId:"10412501",label:"Brodiaea cortada seca congesta"},
    {prodId:"10412502",label:"Brodiaea cortada seca congesta lavanda"},
    {prodId:"10412503",label:"Brodiaea cortada seca jacinta"},
    {prodId:"10412504",label:"Brodiaea cortada seca ida maija"},
    {prodId:"10412505",label:"Brodiaea cortada seca starlight"},
    {prodId:"10412600",label:"Calla cortada seca"},
    {prodId:"10412601",label:"Calla cortada seca green goddess"},
    {prodId:"10412602",label:"Calla cortada seca ramillete albertville"},
    {prodId:"10412603",label:"Calla cortada seca ramillete aranal"},
    {prodId:"10412604",label:"Calla cortada seca ramillete black eyed beauty"},
    {prodId:"10412605",label:"Calla cortada seca black star"},
    {prodId:"10412606",label:"Calla cortada seca brisbane"},
    {prodId:"10412607",label:"Calla cortada seca crystal blush"},
    {prodId:"10412608",label:"Calla cortada seca crystal pink"},
    {prodId:"10412609",label:"Calla cortada seca crystal white"},
    {prodId:"10412610",label:"Calla cortada seca dark captain romanc"},
    {prodId:"10412611",label:"Calla cortada seca ramillete dark mozart"},
    {prodId:"10412612",label:"Calla cortada seca ramillete dark naomi"},
    {prodId:"10412613",label:"Calla cortada seca ramillete deformed calla"},
    {prodId:"10412614",label:"Calla cortada seca ramillete dordogne"},
    {prodId:"10412615",label:"Calla cortada seca ramillete etude"},
    {prodId:"10412616",label:"Calla cortada seca ramillete farao"},
    {prodId:"10412617",label:"Calla cortada seca ramillete fire glow"},
    {prodId:"10412618",label:"Calla cortada seca ramillete florex gold"},
    {prodId:"10412619",label:"Calla cortada seca ramillete garnet glow"},
    {prodId:"10412620",label:"Calla cortada seca ramillete hot chocolate"},
    {prodId:"10412621",label:"Calla cortada seca ramillete lavender improved"},
    {prodId:"10412622",label:"Calla cortada seca ramillete light cromance"},
    {prodId:"10412623",label:"Calla cortada seca ramillete little suzy"},
    {prodId:"10412624",label:"Calla cortada seca ramillete majestic red"},
    {prodId:"10412625",label:"Calla cortada seca ramillete mango"},
    {prodId:"10412626",label:"Calla cortada seca ramillete merlot"},
    {prodId:"10412627",label:"Calla cortada seca ramillete mozart"},
    {prodId:"10412628",label:"Calla cortada seca ramillete naomi"},
    {prodId:"10412629",label:"Calla cortada seca ramillete night cap"},
    {prodId:"10412630",label:"Calla cortada seca ramillete odessa"},
    {prodId:"10412631",label:"Calla cortada seca ramillete pacific pink"},
    {prodId:"10412632",label:"Calla cortada seca ramillete passion fruit"},
    {prodId:"10412633",label:"Calla cortada seca ramillete picasso"},
    {prodId:"10412634",label:"Calla cortada seca ramillete pillow talk"},
    {prodId:"10412635",label:"Calla cortada seca ramillete pink persuation"},
    {prodId:"10412636",label:"Calla cortada seca ramillete pisa"},
    {prodId:"10412637",label:"Calla cortada seca ramillete pot of"},
    {prodId:"10412638",label:"Calla cortada seca ramillete red sox"},
    {prodId:"10412639",label:"Calla cortada seca ramillete rosa"},
    {prodId:"10412640",label:"Calla cortada seca ramillete ruby light rose"},
    {prodId:"10412641",label:"Calla cortada seca ramillete samur"},
    {prodId:"10412642",label:"Calla cortada seca ramillete sapporo"},
    {prodId:"10412643",label:"Calla cortada seca ramillete schwarzwalder"},
    {prodId:"10412644",label:"Calla cortada seca ramillete serrada"},
    {prodId:"10412645",label:"Calla cortada seca ramillete solemio"},
    {prodId:"10412646",label:"Calla cortada seca ramillete sunrise"},
    {prodId:"10412647",label:"Calla cortada seca ramillete super mac"},
    {prodId:"10412648",label:"Calla cortada seca ramillete swan lake"},
    {prodId:"10412649",label:"Calla cortada seca ramillete vermeer"},
    {prodId:"10412650",label:"Calla cortada seca ramillete white butterfly"},
    {prodId:"10412651",label:"Calla cortada seca ramillete amarilla"},
    {prodId:"10412652",label:"Calla cortada seca ramillete yellow mozart"},
    {prodId:"10412653",label:"Calla cortada seca ramillete white large"},
    {prodId:"10412800",label:"Celosia cortada seca"},
    {prodId:"10412801",label:"Cresta de gallo (celosia) cortada seca verde"},
    {prodId:"10412802",label:"Cresta de gallo (celosia) cortada seca anaranjado"},
    {prodId:"10412803",label:"Cresta de gallo (celosia) cortada seca rosado"},
    {prodId:"10412804",label:"Cresta de gallo (celosia) cortada seca púrpura"},
    {prodId:"10412805",label:"Cresta de gallo (celosia) cortada seca rojo"},
    {prodId:"10412806",label:"Cresta de gallo (celosia) cortada seca amarillo"},
    {prodId:"10412807",label:"Cresta de gallo (celosia) cortada seca plume light"},
    {prodId:"10412808",label:"Cresta de gallo (celosia) cortada seca plume orange"},
    {prodId:"10412809",label:"Cresta de gallo (celosia) cortada seca plume purple"},
    {prodId:"10412810",label:"Cresta de gallo (celosia) cortada seca plume red"},
    {prodId:"10412811",label:"Cresta de gallo (celosia) cortada seca plume yellow"},
    {prodId:"10412812",label:"Cresta de gallo (celosia) cortada seca wheat pink"},
    {prodId:"10412813",label:"Cresta de gallo (celosia) cortada seca wheat yellow"},
    {prodId:"10412900",label:"Narciso cortado seco"},
    {prodId:"10412901",label:"Narciso cortado seco dick wilden"},
    {prodId:"10412902",label:"Narciso cortado seco dutch master"},
    {prodId:"10412903",label:"Narciso cortado seco ice follies"},
    {prodId:"10412904",label:"Narciso cortado seco ice king"},
    {prodId:"10412905",label:"Narciso cortado seco johan strauss"},
    {prodId:"10412906",label:"Narciso cortado seco yellow carlton"},
    {prodId:"10413000",label:"Dalia cortada seca"},
    {prodId:"10413001",label:"Dalia cortada seca bicolor"},
    {prodId:"10413002",label:"Dalia cortada seca rosado fuerte"},
    {prodId:"10413003",label:"Dalia cortada seca rosado claro"},
    {prodId:"10413004",label:"Dalia cortada seca rosado medio"},
    {prodId:"10413005",label:"Dalia cortada seca anaranjado"},
    {prodId:"10413006",label:"Dalia cortada seca durazno"},
    {prodId:"10413007",label:"Dalia cortada seca púrpura"},
    {prodId:"10413008",label:"Dalia cortada seca roja"},
    {prodId:"10413009",label:"Dalia cortada seca blanca"},
    {prodId:"10413010",label:"Dalia cortada seca amarilla"},
    {prodId:"10413100",label:"Delphinium cortado seco"},
    {prodId:"10413101",label:"Delphinium cortado seco bella azul oscuro"},
    {prodId:"10413102",label:"Delphinium cortado seco bella azul claro"},
    {prodId:"10413103",label:"Delphinium cortado seco bella blanco"},
    {prodId:"10413104",label:"Delphinium cortado seco azul sombra"},
    {prodId:"10413105",label:"Delphinium cortado seco híbrido azul oscuro"},
    {prodId:"10413106",label:"Delphinium cortado seco híbrido azul claro"},
    {prodId:"10413107",label:"Delphinium cortado seco híbrido malva"},
    {prodId:"10413108",label:"Delphinium cortado seco híbrido rosado"},
    {prodId:"10413109",label:"Delphinium cortado seco híbrido púrpura"},
    {prodId:"10413110",label:"Delphinium cortado seco híbrido rojo"},
    {prodId:"10413111",label:"Delphinium cortado seco híbrido blanco"},
    {prodId:"10413112",label:"Delphinium cortado seco princesa carolina"},
    {prodId:"10413113",label:"Delphinium cortado seco volkerfrieden"},
    {prodId:"10413200",label:"Clavel (dianthus) cortado seco"},
    {prodId:"10413201",label:"Clavel (dianthus) cortado seco chocolate"},
    {prodId:"10413202",label:"Clavel (dianthus) cortado seco fucsia"},
    {prodId:"10413203",label:"Clavel (dianthus) cortado seco bola verde"},
    {prodId:"10413204",label:"Clavel (dianthus) cortado seco rosado fuerte"},
    {prodId:"10413205",label:"Clavel (dianthus) cortado seco lavanda"},
    {prodId:"10413206",label:"Clavel (dianthus) cortado seco frambuesa"},
    {prodId:"10413207",label:"Clavel (dianthus) cortado seco rojo"},
    {prodId:"10413208",label:"Clavel (dianthus) cortado seco rosado"},
    {prodId:"10413300",label:"Eremruses cortadas secas"},
    {prodId:"10413301",label:"Eremurus cortado seco deruyter híbrido"},
    {prodId:"10413302",label:"Eremurus cortado seco himalaicus blanco"},
    {prodId:"10413303",label:"Eremurus cortado seco anaranjado"},
    {prodId:"10413304",label:"Eremurus cortado seco durazno"},
    {prodId:"10413305",label:"Eremurus cortado seco amarillo"},
    {prodId:"10413400",label:"Brezo (erica) cortada seca"},
    {prodId:"10413401",label:"Brezo (erica) cortado seco campunalarus"},
    {prodId:"10413402",label:"Brezo (erica) cortado seco conica"},
    {prodId:"10413403",label:"Brezo (erica) cortado seco green ice"},
    {prodId:"10413404",label:"Brezo (erica) cortado seco rosado"},
    {prodId:"10413405",label:"Brezo (erica) cortado seco príncipe de gales"},
    {prodId:"10413500",label:"Euphorbia cortada seca"},
    {prodId:"10413501",label:"Euphorbia cortada seca characias"},
    {prodId:"10413502",label:"Euphorbia cortada seca griffithii fireglow"},
    {prodId:"10413503",label:"Euphorbia cortada seca martini"},
    {prodId:"10413504",label:"Euphorbia cortada seca anaranjada"},
    {prodId:"10413505",label:"Euphorbia cortada seca durazno"},
    {prodId:"10413506",label:"Euphorbia cortada seca rosada"},
    {prodId:"10413507",label:"Euphorbia cortada seca roja"},
    {prodId:"10413508",label:"Euphorbia cortada seca blanca"},
    {prodId:"10413509",label:"Euphorbia cortada seca amarilla"},
    {prodId:"10413510",label:"Euphorbia cortada seca amarilla spurge"},
    {prodId:"10413600",label:"Fresia cortada seca"},
    {prodId:"10413601",label:"Fresia cortada seca crema"},
    {prodId:"10413602",label:"Fresia cortada seca doble blanca"},
    {prodId:"10413603",label:"Fresia cortada seca doble amarilla"},
    {prodId:"10413604",label:"Fresia cortada seca rosado fuerte"},
    {prodId:"10413605",label:"Fresia cortada seca lady brunet"},
    {prodId:"10413606",label:"Fresia cortada seca lavanda"},
    {prodId:"10413607",label:"Fresia cortada seca rosado medio"},
    {prodId:"10413608",label:"Fresia cortada seca anaranjado"},
    {prodId:"10413609",label:"Fresia cortada seca pimpinela"},
    {prodId:"10413610",label:"Fresia cortada seca rosada"},
    {prodId:"10413611",label:"Fresia cortada seca pùrpura"},
    {prodId:"10413612",label:"Fresia cortada seca roja"},
    {prodId:"10413613",label:"Fresia cortada seca blanca"},
    {prodId:"10413614",label:"Fresia cortada seca amarilla"},
    {prodId:"10413700",label:"Ajedrezada cortada seca"},
    {prodId:"10413701",label:"Ajedrezada cortada seca acmopelata"},
    {prodId:"10413702",label:"Ajedrezada cortada seca assyriaca"},
    {prodId:"10413703",label:"Ajedrezada cortada seca assyriaca uva vulpis"},
    {prodId:"10413704",label:"Ajedrezada cortada seca elysee"},
    {prodId:"10413705",label:"Ajedrezada cortada seca imperialis anaranjada"},
    {prodId:"10413706",label:"Ajedrezada cortada seca imperialis amarilla"},
    {prodId:"10413707",label:"Ajedrezada cortada seca meleagris"},
    {prodId:"10413708",label:"Ajedrezada cortada seca michailowski"},
    {prodId:"10413709",label:"Ajedrezada cortada seca uva vulpis"},
    {prodId:"10413800",label:"Hiniesta cortada seca"},
    {prodId:"10413801",label:"Hiniesta cortada seca verde"},
    {prodId:"10413802",label:"Hiniesta cortada seca rosado fuerte"},
    {prodId:"10413803",label:"Hiniesta cortada seca lavanda"},
    {prodId:"10413804",label:"Hiniesta cortada seca rosado claro"},
    {prodId:"10413805",label:"Hiniesta cortada seca durazno"},
    {prodId:"10413806",label:"Hiniesta cortada seca púrpura"},
    {prodId:"10413807",label:"Hiniesta cortada seca blanco"},
    {prodId:"10413808",label:"Hiniesta cortada seca amarillo"},
    {prodId:"10413900",label:"Margarita cortada seca"},
    {prodId:"10413901",label:"Margarita cortada seca crema de centro negro"},
    {prodId:"10413902",label:"Margarita cortada seca crema"},
    {prodId:"10413903",label:"Margarita cortada seca dorada"},
    {prodId:"10413904",label:"Margarita cortada seca rosado fuerte"},
    {prodId:"10413905",label:"Margarita cortada seca rosado claro"},
    {prodId:"10413906",label:"Margarita cortada seca magenta"},
    {prodId:"10413907",label:"Margarita cortada seca mini coral"},
    {prodId:"10413908",label:"Margarita cortada seca mini fucsia"},
    {prodId:"10413909",label:"Margarita cortada seca mini rosado fuerte"},
    {prodId:"10413910",label:"Margarita cortada seca mini anaranjado claro de centro negro"},
    {prodId:"10413911",label:"Margarita cortada seca mini anaranjado de centro negro"},
    {prodId:"10413912",label:"Margarita cortada seca mini anaranjado"},
    {prodId:"10413913",label:"Margarita cortada seca mini rojo de centro negro"},
    {prodId:"10413914",label:"Margarita cortada seca mini blanco"},
    {prodId:"10413915",label:"Margarita cortada seca mini amarillo claro de centro negro"},
    {prodId:"10413916",label:"Margarita cortada seca anaranjado de centro negro"},
    {prodId:"10413917",label:"Margarita cortada seca anaranjada"},
    {prodId:"10413918",label:"Margarita cortada seca durazno de centro negro"},
    {prodId:"10413919",label:"Margarita cortada seca durazno"},
    {prodId:"10413920",label:"Margarita cortada seca rosada de centro negro"},
    {prodId:"10413921",label:"Margarita cortada seca rosada"},
    {prodId:"10413922",label:"Margarita cortada seca roja de centro negro"},
    {prodId:"10413923",label:"Margarita cortada seca roja"},
    {prodId:"10413924",label:"Margarita cortada seca spider durazno"},
    {prodId:"10413925",label:"Margarita cortada seca spider roja"},
    {prodId:"10413926",label:"Margarita cortada seca terracota"},
    {prodId:"10413927",label:"Margarita cortada seca blanca de centro negro"},
    {prodId:"10413928",label:"Margarita cortada seca blanca"},
    {prodId:"10413929",label:"Margarita cortada seca amarilla"},
    {prodId:"10414100",label:"Gladiolo cortado seco"},
    {prodId:"10414101",label:"Gladiolo cortado seco burgundy"},
    {prodId:"10414102",label:"Gladiolo cortado seco fucsia"},
    {prodId:"10414103",label:"Gladiolo cortado seco verde"},
    {prodId:"10414104",label:"Gladiolo cortado seco rosado fuerte"},
    {prodId:"10414105",label:"Gladiolo cortado seco rosado claro"},
    {prodId:"10414106",label:"Gladiolo cortado seco anaranjado"},
    {prodId:"10414107",label:"Gladiolo cortado seco durazno"},
    {prodId:"10414108",label:"Gladiolo cortado seco rosado medio"},
    {prodId:"10414109",label:"Gladiolo cortado seco púrpura"},
    {prodId:"10414110",label:"Gladiolo cortado seco rojo bi - color"},
    {prodId:"10414111",label:"Gladiolo cortado seco rojo"},
    {prodId:"10414112",label:"Gladiolo cortado seco salmón"},
    {prodId:"10414113",label:"Gladiolo cortado seco blanco"},
    {prodId:"10414114",label:"Gladiolo cortado seco amarillo"},
    {prodId:"10414200",label:"Godetia cortada seca"},
    {prodId:"10414201",label:"Godetia cortada seca bicolor"},
    {prodId:"10414202",label:"Godetia cortada seca fucsia"},
    {prodId:"10414203",label:"Godetia cortada seca rosado fuerte"},
    {prodId:"10414204",label:"Godetia cortada seca anaranjada"},
    {prodId:"10414205",label:"Godetia cortada seca roja"},
    {prodId:"10414206",label:"Godetia cortada seca blanca"},
    {prodId:"10414300",label:"Bromelia cortada seca"},
    {prodId:"10414301",label:"Bromelia lingulata cortada seca anaranjada"},
    {prodId:"10414302",label:"Bromelia lingulata cortada seca roja"},
    {prodId:"10414303",label:"Bromelia lingulata cortada seca blanca"},
    {prodId:"10414304",label:"Bromelia lingulata cortada seca amarilla"},
    {prodId:"10414305",label:"Bromelia variegata cortada seca"},
    {prodId:"10414400",label:"Gypsophilia cortada seca"},
    {prodId:"10414401",label:"Gypsophilia cortada seca bambino"},
    {prodId:"10414402",label:"Gypsophilia cortada seca million stars"},
    {prodId:"10414403",label:"Gypsophilia cortada seca mirabella"},
    {prodId:"10414404",label:"Gypsophilia cortada seca new love"},
    {prodId:"10414405",label:"Gypsophilia cortada seca orión"},
    {prodId:"10414406",label:"Gypsophilia cortada seca perfecta"},
    {prodId:"10414500",label:"Brezo cortado seco"},
    {prodId:"10414501",label:"Brezo cortado seco augustine"},
    {prodId:"10414502",label:"Brezo cortado seco érica cuatro hermanas"},
    {prodId:"10414503",label:"Brezo cortado seco francés"},
    {prodId:"10414504",label:"Brezo cortado seco verde"},
    {prodId:"10414505",label:"Brezo cortado seco sterling range blanco"},
    {prodId:"10414506",label:"Brezo cortado seco rosado sunset"},
    {prodId:"10414507",label:"Brezo cortado seco blanco"},
    {prodId:"10414600",label:"Heliconia cortada seca"},
    {prodId:"10414601",label:"Heliconia cortada seca bihai claw"},
    {prodId:"10414602",label:"Heliconia cortada seca bihai flash"},
    {prodId:"10414603",label:"Heliconia cortada seca bihai lobster claw"},
    {prodId:"10414604",label:"Heliconia cortada seca caribea red"},
    {prodId:"10414605",label:"Heliconia cortada seca caribea yellow"},
    {prodId:"10414606",label:"Heliconia cortada seca christmas"},
    {prodId:"10414607",label:"Heliconia cortada seca edge of night"},
    {prodId:"10414608",label:"Heliconia cortada seca green bihai"},
    {prodId:"10414609",label:"Heliconia cortada seca marginata lutea"},
    {prodId:"10414610",label:"Heliconia cortada seca psitt fire opal"},
    {prodId:"10414611",label:"Heliconia cortada seca psittacorum"},
    {prodId:"10414612",label:"Heliconia cortada seca richmond red"},
    {prodId:"10414613",label:"Heliconia cortada seca rostrata"},
    {prodId:"10414614",label:"Heliconia cortada seca sexy pink"},
    {prodId:"10414615",label:"Heliconia cortada seca sexy scarlett"},
    {prodId:"10414616",label:"Heliconia cortada seca shogun"},
    {prodId:"10414617",label:"Heliconia cortada seca small red"},
    {prodId:"10414618",label:"Heliconia cortada seca southern cross"},
    {prodId:"10414619",label:"Heliconia cortada seca wagneriana"},
    {prodId:"10314706",label:"Jacinto cortado fresco lavanda"},
    {prodId:"10314704",label:"Jacinto cortado fresco fucsia"},
    {prodId:"10314710",label:"Jacinto cortado fresco estrella púrpura"},
    {prodId:"10314702",label:"Jacinto cortado fresco durazno"},
    {prodId:"10314711",label:"Jacinto cortado fresco blanco"},
    {prodId:"10314701",label:"Jacinto cortado fresco bean"},
    {prodId:"10314707",label:"Jacinto cortado fresco azul claro"},
    {prodId:"10314703",label:"Jacinto cortado fresco azul"},
    {prodId:"10314712",label:"Jacinto cortado fresco amarillo"},
    {prodId:"10314700",label:"Jacinto cortado fresco"},
    {prodId:"10226042",label:"Jacinto con bulbo viva"},
    {prodId:"10414800",label:"Hydrangea cortada seca"},
    {prodId:"10414801",label:"Hydrangea cortada seca anabella"},
    {prodId:"10414802",label:"Hydrangea cortada seca azul antiguo"},
    {prodId:"10414803",label:"Hydrangea cortada seca azul antiguo  o verde o nueva zelandia"},
    {prodId:"10414804",label:"Hydrangea cortada seca verde antiguo"},
    {prodId:"10414805",label:"Hydrangea cortada seca rosado antiguo"},
    {prodId:"10414806",label:"Hydrangea cortada seca púrpura antiguo o nueva zelandia"},
    {prodId:"10414807",label:"Hydrangea cortada seca aubergene o nueva zelandia"},
    {prodId:"10414808",label:"Hydrangea cortada seca azul oscuro"},
    {prodId:"10414809",label:"Hydrangea cortada seca rosado oscuro"},
    {prodId:"10414810",label:"Hydrangea cortada seca púrpura oscuro"},
    {prodId:"10414811",label:"Hydrangea cortada seca berenjena"},
    {prodId:"10414812",label:"Hydrangea cortada seca verde teñida"},
    {prodId:"10414813",label:"Hydrangea cortada seca limón verde"},
    {prodId:"10414814",label:"Hydrangea cortada seca rosado fuerte"},
    {prodId:"10414815",label:"Hydrangea cortada seca jumbo blanca"},
    {prodId:"10414816",label:"Hydrangea cortada seca lavanda o nueva zelandia"},
    {prodId:"10414817",label:"Hydrangea cortada seca azul claro"},
    {prodId:"10414818",label:"Hydrangea cortada seca rosado claro grande"},
    {prodId:"10414819",label:"Hydrangea cortada seca verde lima grande"},
    {prodId:"10414820",label:"Hydrangea cortada seca mini verde"},
    {prodId:"10414821",label:"Hydrangea cortada seca oakleaf"},
    {prodId:"10414822",label:"Hydrangea cortada seca oakleaf copo de nieve"},
    {prodId:"10414823",label:"Hydrangea cortada seca rosado teñido"},
    {prodId:"10414824",label:"Hydrangea cortada seca rosado"},
    {prodId:"10414825",label:"Hydrangea cortada seca púrpura o nueva zelandia"},
    {prodId:"10414826",label:"Hydrangea cortada seca rojo teñido"},
    {prodId:"10414827",label:"Hydrangea cortada seca shocking blue"},
    {prodId:"10414828",label:"Hydrangea cortada seca tardiva"},
    {prodId:"10414829",label:"Hydrangea cortada seca blanca"},
    {prodId:"10414900",label:"Iris cortada seca"},
    {prodId:"10414901",label:"Iris cortada seca negra barbada"},
    {prodId:"10414902",label:"Iris cortada seca azul barbada"},
    {prodId:"10414903",label:"Iris cortada seca lavanda barbada"},
    {prodId:"10414904",label:"Iris cortada seca barbada azul clara"},
    {prodId:"10414905",label:"Iris cortada seca barbada púrpura"},
    {prodId:"10414906",label:"Iris cortada seca barbada roja"},
    {prodId:"10414907",label:"Iris cortada seca barbada blanca"},
    {prodId:"10414908",label:"Iris cortada seca baerbada blanca y púrpura"},
    {prodId:"10414909",label:"Iris cortada seca amarilla barbada"},
    {prodId:"10414910",label:"Iris cortada seca blue elegance"},
    {prodId:"10414911",label:"Iris cortada seca casablanca"},
    {prodId:"10414912",label:"Iris cortada seca golden beau"},
    {prodId:"10414913",label:"Iris cortada seca hildegard"},
    {prodId:"10414914",label:"Iris cortada seca hong kong"},
    {prodId:"10414915",label:"Iris cortada seca ideal"},
    {prodId:"10414916",label:"Iris cortada seca professor blue"},
    {prodId:"10414917",label:"Iris cortada seca purple"},
    {prodId:"10414918",label:"Iris cortada seca spuria"},
    {prodId:"10414919",label:"Iris cortada seca telstar"},
    {prodId:"10415000",label:"Pata de canguro cortada seca"},
    {prodId:"10415001",label:"Pata de canguro cortada seca bicolor"},
    {prodId:"10415002",label:"Pata de canguro cortada seca negra"},
    {prodId:"10415003",label:"Pata de canguro cortada seca verde"},
    {prodId:"10415004",label:"Pata de canguro cortada seca anaranjada"},
    {prodId:"10415005",label:"Pata de canguro cortada seca rosada"},
    {prodId:"10415006",label:"Pata de canguro cortada seca roja"},
    {prodId:"10415007",label:"Pata de canguro cortada seca amarilla"},
    {prodId:"10415100",label:"Delfinio cortado seco"},
    {prodId:"10415101",label:"Delfinio cortada seca azul nube"},
    {prodId:"10415102",label:"Delfinio cortada seca rosado fuerte"},
    {prodId:"10415103",label:"Delfinio cortada seca lavanda"},
    {prodId:"10415104",label:"Delfinio cortada seca rosado claro"},
    {prodId:"10415105",label:"Delfinio cortada seca púrpura"},
    {prodId:"10415106",label:"Delfinio cortada seca blanca"},
    {prodId:"10415200",label:"Lepto cortado seco"},
    {prodId:"10415201",label:"Lepto cortado seco azul o floreciente"},
    {prodId:"10415202",label:"Lepto cortado seco rosado fuerte"},
    {prodId:"10415203",label:"Lepto cortado seco rosado claro"},
    {prodId:"10415204",label:"Lepto cortado seco rosado"},
    {prodId:"10415205",label:"Lepto cortado seco rojo"},
    {prodId:"10415206",label:"Lepto cortado seco blanco"},
    {prodId:"10415300",label:"Lila cortada seca"},
    {prodId:"10415301",label:"Lila  cortada seca híbrida francesa lavanda"},
    {prodId:"10415302",label:"Lila  cortada seca híbrida francesa púrpura"},
    {prodId:"10415303",label:"Lila  cortada seca púrpura"},
    {prodId:"10415304",label:"Lila  cortada seca vino"},
    {prodId:"10415305",label:"Lila  cortada seca blanca"},
    {prodId:"10415306",label:"Lila  cortada seca blanca salvaje"},
    {prodId:"10415400",label:"Azucena cortada seca"},
    {prodId:"10415401",label:"Azucena cortada seca alteza longiflorum e híbrido asiática"},
    {prodId:"10415402",label:"Azucena cortada seca black out"},
    {prodId:"10415403",label:"Azucena cortada seca rosado oscuro"},
    {prodId:"10415404",label:"Azucena cortada seca leéctrica asiática"},
    {prodId:"10415405",label:"Azucena cortada seca festival asiática"},
    {prodId:"10415406",label:"Azucena cortada seca ginebra asiática"},
    {prodId:"10415407",label:"Azucena cortada seca rosado claro asiática"},
    {prodId:"10415408",label:"Azucena cortada seca colombina asiática"},
    {prodId:"10415409",label:"Azucena cortada seca miss américa púrpura asiática"},
    {prodId:"10415410",label:"Azucena cortada seca monte negro asiática"},
    {prodId:"10415411",label:"Azucena cortada seca anaranjada asiática"},
    {prodId:"10415412",label:"Azucena cortada seca durazno cannes asiática"},
    {prodId:"10415413",label:"Azucena cortada seca risada asiática"},
    {prodId:"10415414",label:"Azucena cortada seca sancerre asiátia"},
    {prodId:"10415415",label:"Azucena cortada seca white dream asiática"},
    {prodId:"10415416",label:"Azucena cortada seca amarilla asiática"},
    {prodId:"10415417",label:"Azucena cortada seca diamante brillante longiflorum e híbrido asiática"},
    {prodId:"10415418",label:"Azucena cortada seca brindisi longiflorum e híbrido asiática"},
    {prodId:"10415419",label:"Azucena cortada seca carmine longiflorum e híbrido asiática"},
    {prodId:"10415420",label:"Azucena cortada seca cinnabar longiflorum e híbrido asiática"},
    {prodId:"10415421",label:"Azucena cortada seca club longiflorum e híbrido asiática"},
    {prodId:"10415422",label:"Azucena cortada seca discovery longiflorum e híbrido asiática"},
    {prodId:"10415423",label:"Azucena cortada seca de pascua"},
    {prodId:"10415424",label:"Azucena cortada seca isis longiflorum e híbrido asiática"},
    {prodId:"10415425",label:"Azucena cortada seca la hybrid justice longiflorum e híbrido asiática"},
    {prodId:"10415426",label:"Azucena cortada seca lace longiflorum e híbrido asiática"},
    {prodId:"10415427",label:"Azucena cortada seca lirio de los valles"},
    {prodId:"10415428",label:"Azucena cortada seca love longiflorum e híbrido asiática"},
    {prodId:"10415429",label:"Azucena cortada seca menorca longiflorum e híbrido asiática"},
    {prodId:"10415430",label:"Azucena cortada seca oriental acapulco"},
    {prodId:"10415431",label:"Azucena cortada seca oriental albion"},
    {prodId:"10415432",label:"Azucena cortada seca oriental argentina"},
    {prodId:"10415433",label:"Azucena cortada seca oriental auratum"},
    {prodId:"10415434",label:"Azucena cortada seca oriental barbaresco"},
    {prodId:"10415435",label:"Azucena cortada seca oriental bernini"},
    {prodId:"10415436",label:"Azucena cortada seca oriental beseno"},
    {prodId:"10415437",label:"Azucena cortada seca oriental broadway"},
    {prodId:"10415438",label:"Azucena cortada seca oriental canada"},
    {prodId:"10415439",label:"Azucena cortada seca oriental casablanca"},
    {prodId:"10415440",label:"Azucena cortada seca oriental chili"},
    {prodId:"10415441",label:"Azucena cortada seca oriental chrystal"},
    {prodId:"10415442",label:"Azucena cortada seca oriental cobra"},
    {prodId:"10415443",label:"Azucena cortada seca oriental conca d' or"},
    {prodId:"10415444",label:"Azucena cortada seca oriental cote d' ivor"},
    {prodId:"10415445",label:"Azucena cortada seca oriental dizzy"},
    {prodId:"10415446",label:"Azucena cortada seca oriental fireball"},
    {prodId:"10415447",label:"Azucena cortada seca oriental gluhwein"},
    {prodId:"10415448",label:"Azucena cortada seca oriental goldband"},
    {prodId:"10415449",label:"Azucena cortada seca oriental halifax"},
    {prodId:"10415450",label:"Azucena cortada seca oriental kathryn"},
    {prodId:"10415451",label:"Azucena cortada seca oriental kyoto"},
    {prodId:"10415452",label:"Azucena cortada seca oriental la mancha"},
    {prodId:"10415453",label:"Azucena cortada seca oriental medusa"},
    {prodId:"10415454",label:"Azucena cortada seca oriental montezuma"},
    {prodId:"10415455",label:"Azucena cortada seca oriental muscadet"},
    {prodId:"10415456",label:"Azucena cortada seca oriental nippon"},
    {prodId:"10415457",label:"Azucena cortada seca oriental opus one"},
    {prodId:"10415458",label:"Azucena cortada seca oriental pompeii"},
    {prodId:"10415459",label:"Azucena cortada seca oriental rialto"},
    {prodId:"10415460",label:"Azucena cortada seca oriental robina"},
    {prodId:"10415461",label:"Azucena cortada seca oriental rousilon"},
    {prodId:"10415462",label:"Azucena cortada seca oriental siberia"},
    {prodId:"10415463",label:"Azucena cortada seca oriental sorbonne"},
    {prodId:"10415464",label:"Azucena cortada seca oriental starfighter"},
    {prodId:"10415465",label:"Azucena cortada seca oriental stargazer"},
    {prodId:"10415466",label:"Azucena cortada seca oriental sumatra"},
    {prodId:"10415467",label:"Azucena cortada seca oriental time out"},
    {prodId:"10415468",label:"Azucena cortada seca oriental tom pouche"},
    {prodId:"10415469",label:"Azucena cortada seca oriental tropical"},
    {prodId:"10415470",label:"Azucena cortada seca oriental white cup"},
    {prodId:"10415471",label:"Azucena cortada seca oriental white merostar"},
    {prodId:"10415472",label:"Azucena cortada seca oriental white montana"},
    {prodId:"10415473",label:"Azucena cortada seca oriental white stargazer"},
    {prodId:"10415474",label:"Azucena cortada seca oriental yellow band"},
    {prodId:"10415475",label:"Azucena cortada seca oriental yellow dream"},
    {prodId:"10415476",label:"Azucena cortada seca oriental yellow queen"},
    {prodId:"10415477",label:"Azucena cortada seca oriental yellow star"},
    {prodId:"10415478",label:"Azucena cortada seca oriental yeloween"},
    {prodId:"10415479",label:"Azucena cortada seca ot red dutch"},
    {prodId:"10415480",label:"Azucena cortada seca sonata nimph"},
    {prodId:"10415481",label:"Azucena cortada seca sonata shocking"},
    {prodId:"10415482",label:"Azucena cortada seca sonata triumphateer"},
    {prodId:"10415483",label:"Azucena cortada seca sunset longiflorum e híbrido asiática"},
    {prodId:"10415484",label:"Azucena cortada seca de agua"},
    {prodId:"10415500",label:"Lavanda cortada seca"},
    {prodId:"10415501",label:"Lavanda marina cortada seca misty peach"},
    {prodId:"10415502",label:"Lavanda marina cortada seca misty rosada"},
    {prodId:"10415503",label:"Lavanda marina cortada seca misty blanca"},
    {prodId:"10415504",label:"Lavanda marina cortada seca misty amarilla"},
    {prodId:"10415505",label:"Lavanda marina cortada seca safora"},
    {prodId:"10415506",label:"Lavanda marina cortada seca sinensis"},
    {prodId:"10415600",label:"Lisianthus cortado seco"},
    {prodId:"10415601",label:"Lisianthus cortado seco crema"},
    {prodId:"10415602",label:"Lisianthus cortado seco rosado fuerte"},
    {prodId:"10415603",label:"Lisianthus cortado seco verde"},
    {prodId:"10415604",label:"Lisianthus cortado seco lavanda"},
    {prodId:"10415605",label:"Lisianthus cortado seco rosado claro"},
    {prodId:"10415606",label:"Lisianthus cortado seco mini blanco"},
    {prodId:"10415607",label:"Lisianthus cortado seco durazno"},
    {prodId:"10415608",label:"Lisianthus cortado seco rosado con borde blanco"},
    {prodId:"10415609",label:"Lisianthus cortado seco púrpura"},
    {prodId:"10415610",label:"Lisianthus cortado seco púrpura con borde blanco"},
    {prodId:"10415611",label:"Lisianthus cortado seco blanco con borde rosado"},
    {prodId:"10415612",label:"Lisianthus cortado seco blanco"},
    {prodId:"10415613",label:"Lisianthus cortado seco blanco con borde pùrpura"},
    {prodId:"10415700",label:"Muscari cortada seca"},
    {prodId:"10415701",label:"Muscari cortada seca armeniacum"},
    {prodId:"10415702",label:"Muscari cortada seca bortyoides blanca"},
    {prodId:"10415703",label:"Muscari cortada seca verde"},
    {prodId:"10415704",label:"Muscari cortada seca latifolia"},
    {prodId:"10415705",label:"Muscari cortada seca valerie finn"},
    {prodId:"10415800",label:"Narciso cortado seco"},
    {prodId:"10415801",label:"Narciso cortado seco alegría"},
    {prodId:"10415802",label:"Narciso cortado seco amanecer dorado"},
    {prodId:"10415803",label:"Narciso cortado seco abba paperwhite"},
    {prodId:"10415804",label:"Narciso cortado seco paperwhite"},
    {prodId:"10415805",label:"Narciso cortado seco ojo de faisán"},
    {prodId:"10415806",label:"Narciso cortado seco soleil d' or"},
    {prodId:"10415807",label:"Narciso cortado seco tete a tete"},
    {prodId:"10415808",label:"Narciso cortado seco thalia"},
    {prodId:"10416100",label:"Estrella de Belén cortada seca"},
    {prodId:"10416101",label:"Estrella de belén cortada seca arábica"},
    {prodId:"10416102",label:"Estrella de belén cortada seca dubium anaranjada"},
    {prodId:"10416103",label:"Estrella de belén cortada seca umbellada"},
    {prodId:"10416104",label:"Estrella de belén cortada seca dubium blanca"},
    {prodId:"10416105",label:"Estrella de belén cortada seca dubium amarilla"},
    {prodId:"10416200",label:"Peonia cortada seca"},
    {prodId:"10416201",label:"Peonia cortada seca alexander fleming"},
    {prodId:"10416202",label:"Peonia cortada seca coral charm"},
    {prodId:"10416203",label:"Peonia cortada seca suset"},
    {prodId:"10416204",label:"Peonia cortada seca coral supreme"},
    {prodId:"10416205",label:"Peonia cortada seca gardenia doble"},
    {prodId:"10416206",label:"Peonia cortada seca doble jues eli oscura"},
    {prodId:"10416207",label:"Peonia cortada seca soble duquesa blanca"},
    {prodId:"10416208",label:"Peonia cortada seca felix crousse"},
    {prodId:"10416209",label:"Peonia cortada seca festiva máxima"},
    {prodId:"10416210",label:"Peonia cortada seca tesoro del jardín"},
    {prodId:"10416211",label:"Peonia cortada seca kansas rosado oscuro"},
    {prodId:"10416212",label:"Peonia cortada seca karl rosenfelt"},
    {prodId:"10416213",label:"Peonia cortada seca paula fay"},
    {prodId:"10416214",label:"Peonia cortada seca encanto rojo"},
    {prodId:"10416215",label:"Peonia cortada seca pasion roja"},
    {prodId:"10416216",label:"Peonia cortada seca sarah bernhardt roja"},
    {prodId:"10416217",label:"Peonia cortada seca scarlet o' hara"},
    {prodId:"10416218",label:"Peonia cortada seca shirley temple"},
    {prodId:"10416300",label:"Banksia cortada seca"},
    {prodId:"10416301",label:"Banksia cortada seca ashbyi"},
    {prodId:"10416302",label:"Banksia cortada seca baxteri"},
    {prodId:"10416306",label:"Banksia cortada seca coccinea"},
    {prodId:"10416311",label:"Banksia cortada seca ericifolia"},
    {prodId:"10416315",label:"Banksia cortada seca verde"},
    {prodId:"10416322",label:"Banksia cortada seca menziesii"},
    {prodId:"10416325",label:"Banksia cortada seca blanco natural"},
    {prodId:"10416326",label:"Banksia cortada seca anaranjado"},
    {prodId:"10416332",label:"Banksia cortada seca rosada"},
    {prodId:"10416400",label:"Ranúculo cortado seco"},
    {prodId:"10416401",label:"Ranúnculo cortado seco chocolate"},
    {prodId:"10416402",label:"Ranúnculo cortado seco elegancia"},
    {prodId:"10416403",label:"Ranúnculo cortado seco verde"},
    {prodId:"10416404",label:"Ranúnculo cortado seco grimaldi"},
    {prodId:"10416405",label:"Ranúnculo cortado seco rosado fuerte"},
    {prodId:"10416406",label:"Ranúnculo cortado seco rosado claro"},
    {prodId:"10416407",label:"Ranúnculo cortado seco anaranjado"},
    {prodId:"10416408",label:"Ranúnculo cortado seco centro verde"},
    {prodId:"10416409",label:"Ranúnculo cortado seco rosado"},
    {prodId:"10416410",label:"Ranúnculo cortado seco rojo"},
    {prodId:"10416411",label:"Ranúnculo cortado seco blanco"},
    {prodId:"10416412",label:"Ranúnculo cortado seco amarillo"},
    {prodId:"10416413",label:"Ranúnculo cortado seco salmón"},
    {prodId:"10416500",label:"Escabiosa cortada seca"},
    {prodId:"10416501",label:"Escabiosa cortada seca anual"},
    {prodId:"10416502",label:"Escabiosa cortada seca negra"},
    {prodId:"10416503",label:"Escabiosa cortada seca caucásica azul"},
    {prodId:"10416504",label:"Escabiosa cortada seca caucásica rosada"},
    {prodId:"10416505",label:"Escabiosa cortada seca caucásica vainas"},
    {prodId:"10416506",label:"Escabiosa cortada seca caucásica blanca"},
    {prodId:"10416507",label:"Escabiosa cortada seca fresa"},
    {prodId:"10416600",label:"Retama de escobas cortada seca"},
    {prodId:"10416601",label:"Retama de escobas cortada seca rosada"},
    {prodId:"10416602",label:"Retama de escobas cortada seca púrpura"},
    {prodId:"10416603",label:"Retama de escobas cortada seca blanca"},
    {prodId:"10416604",label:"Retama de escobas cortada seca amarilla"},
    {prodId:"10416700",label:"Boca de dragón cortada seca"},
    {prodId:"10416701",label:"Boca de dragón cortada seca bicolor"},
    {prodId:"10416702",label:"Boca de dragón cortada seca burgundy"},
    {prodId:"10416703",label:"Boca de dragón cortada seca rosado fuerte"},
    {prodId:"10416704",label:"Boca de dragón cortada seca lavanda"},
    {prodId:"10416705",label:"Boca de dragón cortada seca anaranjado claro"},
    {prodId:"10416706",label:"Boca de dragón cortada seca rosado claro"},
    {prodId:"10416707",label:"Boca de dragón cortada seca anaranjado"},
    {prodId:"10416708",label:"Boca de dragón cortada seca blanco"},
    {prodId:"10416709",label:"Boca de dragón cortada seca amarillo"},
    {prodId:"10416800",label:"Romero del pantano cortada seca"},
    {prodId:"10416801",label:"Romero del pantano cortada seca azul"},
    {prodId:"10416802",label:"Romero del pantano cortada seca lavanda"},
    {prodId:"10416803",label:"Romero del pantano cortada seca durazno"},
    {prodId:"10416804",label:"Romero del pantano cortada seca rosada"},
    {prodId:"10416805",label:"Romero del pantano cortada seca púrpura"},
    {prodId:"10416806",label:"Romero del pantano cortada seca espuma de mar"},
    {prodId:"10416807",label:"Romero del pantano cortada seca blanca"},
    {prodId:"10416808",label:"Romero del pantano cortada seca amarilla"},
    {prodId:"10416900",label:"Matiola cortada seca"},
    {prodId:"10416901",label:"Matiola cortada seca apricot"},
    {prodId:"10416902",label:"Matiola cortada seca crema"},
    {prodId:"10416903",label:"Matiola cortada seca fucsia"},
    {prodId:"10416904",label:"Matiola cortada seca lavanda"},
    {prodId:"10416905",label:"Matiola cortada seca lavanda claro"},
    {prodId:"10416906",label:"Matiola cortada seca rosado pacífico"},
    {prodId:"10416907",label:"Matiola cortada seca púrpura"},
    {prodId:"10416908",label:"Matiola cortada seca rojo rubí"},
    {prodId:"10416909",label:"Matiola cortada seca enamorada rosda"},
    {prodId:"10416910",label:"Matiola cortada seca blanca"},
    {prodId:"10417100",label:"Guisante de olor cortado seco"},
    {prodId:"10417101",label:"Guisante de olor cortado seco verde teñido"},
    {prodId:"10417102",label:"Guisante de olor cortado seco rosado fuerte"},
    {prodId:"10417103",label:"Guisante de olor cortado seco lavanda"},
    {prodId:"10417104",label:"Guisante de olor cortado seco rosado claro"},
    {prodId:"10417105",label:"Guisante de olor cortado seco anaranjado"},
    {prodId:"10417106",label:"Guisante de olor cortado seco durazno teñido"},
    {prodId:"10417107",label:"Guisante de olor cortado seco púrpura"},
    {prodId:"10417108",label:"Guisante de olor cortado seco blanco"},
    {prodId:"10417200",label:"Cardo cortado seco"},
    {prodId:"10417201",label:"Cardo cortado seco alpinum"},
    {prodId:"10417202",label:"Cardo cortado seco echinops"},
    {prodId:"10417203",label:"Cardo cortado seco eryngium árabe"},
    {prodId:"10417204",label:"Cardo cortado seco eryngium azul"},
    {prodId:"10417205",label:"Cardo cortado seco eryngium orión"},
    {prodId:"10417206",label:"Cardo cortado seco eryngium frambuesa"},
    {prodId:"10417207",label:"Cardo cortado seco eryngium supernova"},
    {prodId:"10417208",label:"Cardo cortado seco eryngium campanita"},
    {prodId:"10417300",label:"Tulipán cortado seco"},
    {prodId:"10417301",label:"Tulipán cortado seco adrem"},
    {prodId:"10417302",label:"Tulipán cortado seco apricot"},
    {prodId:"10417303",label:"Tulipán cortado seco bicolor rojo y amarillo"},
    {prodId:"10417304",label:"Tulipán cortado seco doble bicolor"},
    {prodId:"10417305",label:"Tulipán cortado seco doble rosado"},
    {prodId:"10417306",label:"Tulipán cortado seco doble rojo"},
    {prodId:"10417307",label:"Tulipán cortado seco doble blanco"},
    {prodId:"10417308",label:"Tulipán cortado seco doble amarillo"},
    {prodId:"10417309",label:"Tulipán cortado seco francés avignon"},
    {prodId:"10417310",label:"Tulipán cortado seco francés camarque"},
    {prodId:"10417311",label:"Tulipán cortado seco francés dordogne"},
    {prodId:"10417312",label:"Tulipán cortado seco francés fiat"},
    {prodId:"10417313",label:"Tulipán cortado seco francés flamboyant"},
    {prodId:"10417314",label:"Tulipán cortado seco francés flaming parrot"},
    {prodId:"10417315",label:"Tulipán cortado seco francés florissa"},
    {prodId:"10417316",label:"Tulipán cortado seco francés doble maureen"},
    {prodId:"10417317",label:"Tulipán cortado seco francés maureen"},
    {prodId:"10417318",label:"Tulipán cortado seco francés menton"},
    {prodId:"10417319",label:"Tulipán cortado seco francés montpellier"},
    {prodId:"10417320",label:"Tulipán cortado seco francés naranja unique"},
    {prodId:"10417321",label:"Tulipán cortado seco francés peonia reconocido único"},
    {prodId:"10417322",label:"Tulipán cortado seco francés loro rosado"},
    {prodId:"10417323",label:"Tulipán cortado seco francés princesa unique"},
    {prodId:"10417324",label:"Tulipán cortado seco francés reconocido"},
    {prodId:"10417325",label:"Tulipán cortado seco francés schepppers"},
    {prodId:"10417326",label:"Tulipán cortado seco francés gamuza"},
    {prodId:"10417327",label:"Tulipán cortado seco francés toyota"},
    {prodId:"10417328",label:"Tulipán cortado seco francés loro weber"},
    {prodId:"10417329",label:"Tulipán cortado seco francés loro blanco"},
    {prodId:"10417330",label:"Tulipán cortado seco lavanda de borde crespo"},
    {prodId:"10417331",label:"Tulipán cortado seco rosado fuerte"},
    {prodId:"10417332",label:"Tulipán cortado seco rosado fuerte hoja variegada"},
    {prodId:"10417333",label:"Tulipán cortado seco lavanda"},
    {prodId:"10417334",label:"Tulipán cortado seco rosado claro hoja variegada"},
    {prodId:"10417335",label:"Tulipán cortado seco viuda alegre"},
    {prodId:"10417336",label:"Tulipán cortado seco anaranjado"},
    {prodId:"10417337",label:"Tulipán cortado seco loro negro"},
    {prodId:"10417338",label:"Tulipán cortado seco loro estela rijnveld"},
    {prodId:"10417339",label:"Tulipán cortado seco llameante"},
    {prodId:"10417340",label:"Tulipán cortado seco loro verde"},
    {prodId:"10417341",label:"Tulipán cortado seco loro lavanda"},
    {prodId:"10417342",label:"Tulipán cortado seco loro anaranjado"},
    {prodId:"10417343",label:"Tulipán cortado seco loro durazno"},
    {prodId:"10417344",label:"Tulipán cortado seco loro rosado"},
    {prodId:"10417345",label:"Tulipán cortado seco loro rojo"},
    {prodId:"10417346",label:"Tulipán cortado seco loro rojo rococó"},
    {prodId:"10417347",label:"Tulipán cortado seco loro weber"},
    {prodId:"10417348",label:"Tulipán cortado seco loro blanco"},
    {prodId:"10417349",label:"Tulipán cortado seco loro rosado"},
    {prodId:"10417350",label:"Tulipán cortado seco rosado"},
    {prodId:"10417351",label:"Tulipán cortado seco púrpura"},
    {prodId:"10417352",label:"Tulipán cortado seco rojo"},
    {prodId:"10417353",label:"Tulipán cortado seco especias"},
    {prodId:"10417354",label:"Tulipán cortado seco blanco"},
    {prodId:"10417355",label:"Tulipán cortado seco amarillo"},
    {prodId:"10417400",label:"Flor de cera cortada seca"},
    {prodId:"10417401",label:"Flor de cera cortada seca alba"},
    {prodId:"10417402",label:"Flor de cera cortada seca bicolor"},
    {prodId:"10417403",label:"Flor de cera cortada seca chichilla"},
    {prodId:"10417404",label:"Flor de cera cortada seca reina danzante"},
    {prodId:"10417405",label:"Flor de cera cortada seca dinamarca"},
    {prodId:"10417406",label:"Flor de cera cortada seca denmar"},
    {prodId:"10417407",label:"Flor de cera cortada seca híbrida pastel flor gema"},
    {prodId:"10417408",label:"Flor de cera cortada seca híbrida rosada flor gem"},
    {prodId:"10417409",label:"Flor de cera cortada seca híbrida blanca rubia"},
    {prodId:"10417410",label:"Flor de cera cortada seca híbrida eric john"},
    {prodId:"10417411",label:"Flor de cera cortada seca híbrida dama pintada"},
    {prodId:"10417412",label:"Flor de cera cortada seca híbrida revelación"},
    {prodId:"10417413",label:"Flor de cera cortada seca híbrida bola de nieve"},
    {prodId:"10417414",label:"Flor de cera cortada seca juriens brook"},
    {prodId:"10417415",label:"Flor de cera cortada seca lady stephanie rosada"},
    {prodId:"10417416",label:"Flor de cera cortada seca madonna"},
    {prodId:"10417417",label:"Flor de cera cortada seca mini blanca"},
    {prodId:"10417418",label:"Flor de cera cortada seca anaranjada"},
    {prodId:"10417419",label:"Flor de cera cortada seca perla"},
    {prodId:"10417420",label:"Flor de cera cortada seca pixie moon"},
    {prodId:"10417421",label:"Flor de cera cortada seca orgullo púrpura"},
    {prodId:"10417422",label:"Flor de cera cortada seca roja"},
    {prodId:"10417423",label:"Flor de cera cortada seca wanaroo"},
    {prodId:"10417424",label:"Flor de cera cortada seca amarilla"},
    {prodId:"10417500",label:"Hierba de aquiles cortada seca"},
    {prodId:"10417501",label:"Hierba de aquiles cortada seca burgundy"},
    {prodId:"10417502",label:"Hierba de aquiles cortada seca crema campesina"},
    {prodId:"10417503",label:"Hierba de aquiles cortada seca rosado campesino"},
    {prodId:"10417504",label:"Hierba de aquiles cortada seca moonshine"},
    {prodId:"10417505",label:"Hierba de aquiles cortada seca anaranjado"},
    {prodId:"10417506",label:"Hierba de aquiles cortada seca durazno"},
    {prodId:"10417507",label:"Hierba de aquiles cortada seca rosada"},
    {prodId:"10417508",label:"Hierba de aquiles cortada seca rojo teñido"},
    {prodId:"10417509",label:"Hierba de aquiles cortada seca blanca"},
    {prodId:"10417510",label:"Hierba de aquiles cortada seca amarilla"},
    {prodId:"10417600",label:"Zinia cortada seca"},
    {prodId:"10417601",label:"Zinia cortada seca rosado fuerte"},
    {prodId:"10417602",label:"Zinia cortada seca mini"},
    {prodId:"10417603",label:"Zinia cortada seca rosado"},
    {prodId:"10417604",label:"Zinia cortada seca rojo"},
    {prodId:"10417605",label:"Zinia cortada seca salmón"},
    {prodId:"10417606",label:"Zinia cortada seca amarillo"},
    {prodId:"10417700",label:"Forsythia cortada seca"},
    {prodId:"10417701",label:"Forsythia cortada seca viridissima"},
    {prodId:"10417702",label:"Forsythia cortada seca giraldiana"},
    {prodId:"10417703",label:"Forsythia cortada seca mira"},
    {prodId:"10417704",label:"Forsythia cortada seca suspensa"},
    {prodId:"10417705",label:"Forsythia cortada seca intermedia"},
    {prodId:"10417706",label:"Forsythia cortada seca variabilis"},
    {prodId:"10417707",label:"Forsythia cortada seca ovate"},
    {prodId:"10417708",label:"Forsythia cortada seca intermedia lynwood"},
    {prodId:"10417800",label:"Geranio cortado seco"},
    {prodId:"10417801",label:"Geranio cortado seco argenteum"},
    {prodId:"10417802",label:"Geranio cortado seco cinereum"},
    {prodId:"10417803",label:"Geranio cortado seco clarkei"},
    {prodId:"10417804",label:"Geranio cortado seco dalmaticum"},
    {prodId:"10417805",label:"Geranio cortado seco endressii"},
    {prodId:"10417806",label:"Geranio cortado seco eriostemon"},
    {prodId:"10417807",label:"Geranio cortado seco farreri"},
    {prodId:"10417808",label:"Geranio cortado seco himalayense o grandiflora"},
    {prodId:"10417809",label:"Geranio cortado seco ibericum"},
    {prodId:"10417810",label:"Geranio cortado seco macrorhizum o raiz grande"},
    {prodId:"10417811",label:"Geranio cortado seco maculatum"},
    {prodId:"10417812",label:"Geranio cortado seco nodosum"},
    {prodId:"10417813",label:"Geranio cortado seco phaeum"},
    {prodId:"10417814",label:"Geranio cortado seco platypetalum"},
    {prodId:"10417815",label:"Geranio cortado seco pratense"},
    {prodId:"10417816",label:"Geranio cortado seco procurrens"},
    {prodId:"10417817",label:"Geranio cortado seco psilostemon"},
    {prodId:"10417818",label:"Geranio cortado seco pylzowianum"},
    {prodId:"10417819",label:"Geranio cortado seco renardii"},
    {prodId:"10417820",label:"Geranio cortado seco sanguineum o sangruento"},
    {prodId:"10417821",label:"Geranio cortado seco sylvaticum"},
    {prodId:"10417822",label:"Geranio cortado seco traversii"},
    {prodId:"10417823",label:"Geranio cortado seco tuberosum"},
    {prodId:"10417824",label:"Geranio cortado seco versicolor"},
    {prodId:"10417825",label:"Geranio cortado seco wallachianum"},
    {prodId:"10417826",label:"Geranio cortado seco wlassovianum"},
    {prodId:"10417827",label:"Geranio cortado seco x magnificum o llamativo"},
    {prodId:"10417900",label:"Amaryllis cortada seca"},
    {prodId:"10417901",label:"Amaryllis cortada seca aglaiae"},
    {prodId:"10417902",label:"Amaryllis cortada seca amaru"},
    {prodId:"10417903",label:"Amaryllis cortada seca angustifolium"},
    {prodId:"10417904",label:"Amaryllis cortada seca anzaldoi"},
    {prodId:"10417905",label:"Amaryllis cortada seca araripinum"},
    {prodId:"10417906",label:"Amaryllis cortada seca arboricola"},
    {prodId:"10417907",label:"Amaryllis cortada seca argentinum"},
    {prodId:"10417908",label:"Amaryllis cortada seca aulicum"},
    {prodId:"10417909",label:"Amaryllis cortada seca aviflorum"},
    {prodId:"10417910",label:"Amaryllis cortada seca barreirasum"},
    {prodId:"10417911",label:"Amaryllis cortada seca blossfeldiae"},
    {prodId:"10417912",label:"Amaryllis cortada seca blumenavium"},
    {prodId:"10417913",label:"Amaryllis cortada seca brasilianum"},
    {prodId:"10417914",label:"Amaryllis cortada seca breviflorum"},
    {prodId:"10417915",label:"Amaryllis cortada seca bukasovii"},
    {prodId:"10417916",label:"Amaryllis cortada seca calyptratum"},
    {prodId:"10417917",label:"Amaryllis cortada seca caupolicanense"},
    {prodId:"10417918",label:"Amaryllis cortada seca chionedyanthum"},
    {prodId:"10417919",label:"Amaryllis cortada seca condemaita"},
    {prodId:"10417920",label:"Amaryllis cortada seca corriense"},
    {prodId:"10417921",label:"Amaryllis cortada seca cuzcoense"},
    {prodId:"10417922",label:"Amaryllis cortada seca curitibanum"},
    {prodId:"10417923",label:"Amaryllis cortada seca cybister"},
    {prodId:"10417924",label:"Amaryllis cortada seca divijuliani"},
    {prodId:"10417925",label:"Amaryllis cortada seca evansiae"},
    {prodId:"10417926",label:"Amaryllis cortada seca ferreyrae"},
    {prodId:"10417927",label:"Amaryllis cortada seca forgetii"},
    {prodId:"10417928",label:"Amaryllis cortada seca fosteri"},
    {prodId:"10417929",label:"Amaryllis cortada seca fuscum"},
    {prodId:"10417930",label:"Amaryllis cortada seca glaucescens"},
    {prodId:"10417931",label:"Amaryllis cortada seca goianum"},
    {prodId:"10417932",label:"Amaryllis cortada seca guarapuavicum"},
    {prodId:"10417933",label:"Amaryllis cortada seca harrisonii"},
    {prodId:"10417934",label:"Amaryllis cortada seca hugoi"},
    {prodId:"10417935",label:"Amaryllis cortada seca iguazuanum"},
    {prodId:"10417936",label:"Amaryllis cortada seca illustre"},
    {prodId:"10417937",label:"Amaryllis cortada seca intiflorum"},
    {prodId:"10417938",label:"Amaryllis cortada seca kromeri"},
    {prodId:"10417939",label:"Amaryllis cortada seca lapacense"},
    {prodId:"10417940",label:"Amaryllis cortada seca leonardii"},
    {prodId:"10417941",label:"Amaryllis cortada seca leopoldii"},
    {prodId:"10417942",label:"Amaryllis cortada seca macbridei"},
    {prodId:"10417943",label:"Amaryllis cortada seca machupijchense"},
    {prodId:"10417944",label:"Amaryllis cortada seca mandonii"},
    {prodId:"10417945",label:"Amaryllis cortada seca minasgerais"},
    {prodId:"10417946",label:"Amaryllis cortada seca miniatum"},
    {prodId:"10417947",label:"Amaryllis cortada seca mollevillquense"},
    {prodId:"10417948",label:"Amaryllis cortada seca morelianum"},
    {prodId:"10417949",label:"Amaryllis cortada seca nelsonii"},
    {prodId:"10417950",label:"Amaryllis cortada seca oconoquense"},
    {prodId:"10417951",label:"Amaryllis cortada seca papilio"},
    {prodId:"10417952",label:"Amaryllis cortada seca paquichanum"},
    {prodId:"10417953",label:"Amaryllis cortada seca paradisiacum"},
    {prodId:"10417954",label:"Amaryllis cortada seca pardinum"},
    {prodId:"10417955",label:"Amaryllis cortada seca parodii"},
    {prodId:"10417956",label:"Amaryllis cortada seca petiolatum"},
    {prodId:"10417957",label:"Amaryllis cortada seca psittacinum"},
    {prodId:"10417958",label:"Amaryllis cortada seca puniceum"},
    {prodId:"10417959",label:"Amaryllis cortada seca reginae"},
    {prodId:"10417960",label:"Amaryllis cortada seca reticulatum"},
    {prodId:"10417961",label:"Amaryllis cortada seca rubropictum"},
    {prodId:"10417962",label:"Amaryllis cortada seca santacatarina"},
    {prodId:"10417963",label:"Amaryllis cortada seca solandraeflorum"},
    {prodId:"10417964",label:"Amaryllis cortada seca starkiorum"},
    {prodId:"10417965",label:"Amaryllis cortada seca striatum"},
    {prodId:"10417966",label:"Amaryllis cortada seca stylosum"},
    {prodId:"10417967",label:"Amaryllis cortada seca teyucuarense"},
    {prodId:"10417968",label:"Amaryllis cortada seca traubii"},
    {prodId:"10417969",label:"Amaryllis cortada seca vargasii"},
    {prodId:"10417970",label:"Amaryllis cortada seca variegatum"},
    {prodId:"10417971",label:"Amaryllis cortada seca vittatum"},
    {prodId:"10417972",label:"Amaryllis cortada seca yungacense"},
    {prodId:"10418000",label:"Rubdeckia cortada seca"},
    {prodId:"10418001",label:"Rubdeckia cortada seca alpicola"},
    {prodId:"10418002",label:"Rubdeckia cortada seca amplexicaulis"},
    {prodId:"10418003",label:"Rubdeckia cortada seca auriculata"},
    {prodId:"10418004",label:"Rubdeckia cortada seca bicolor"},
    {prodId:"10418005",label:"Rubdeckia cortada seca californica"},
    {prodId:"10418006",label:"Rubdeckia cortada seca fulgida"},
    {prodId:"10418007",label:"Rubdeckia cortada seca glaucescens"},
    {prodId:"10418008",label:"Rubdeckia cortada seca graminifolia"},
    {prodId:"10418009",label:"Rubdeckia cortada seca grandiflora"},
    {prodId:"10418010",label:"Rubdeckia cortada seca heliopsidis"},
    {prodId:"10418011",label:"Rubdeckia cortada seca hirta"},
    {prodId:"10418012",label:"Rubdeckia cortada seca klamathensis"},
    {prodId:"10418013",label:"Rubdeckia cortada seca laciniata"},
    {prodId:"10418014",label:"Rubdeckia cortada seca máxima"},
    {prodId:"10418015",label:"Rubdeckia cortada seca missouriensis"},
    {prodId:"10418016",label:"Rubdeckia cortada seca mohrii"},
    {prodId:"10418017",label:"Rubdeckia cortada seca mollis"},
    {prodId:"10418018",label:"Rubdeckia cortada seca montana"},
    {prodId:"10418019",label:"Rubdeckia cortada seca nítida"},
    {prodId:"10418020",label:"Rubdeckia cortada seca occidentalis"},
    {prodId:"10418021",label:"Rubdeckia cortada seca pinnata"},
    {prodId:"10418022",label:"Rubdeckia cortada seca scabrifolia"},
    {prodId:"10418023",label:"Rubdeckia cortada seca serotina"},
    {prodId:"10418024",label:"Rubdeckia cortada seca speciosa"},
    {prodId:"10418025",label:"Rubdeckia cortada seca subtomentosa"},
    {prodId:"10418026",label:"Rubdeckia cortada seca texana"},
    {prodId:"10418027",label:"Rubdeckia cortada seca triloba"},
    {prodId:"10418100",label:"Protea cortada seca"},
    {prodId:"10418101",label:"Protea cortada seca bouquet"},
    {prodId:"10418102",label:"Protea cortada seca cepillo botella"},
    {prodId:"10418103",label:"Protea cortada seca carnaval"},
    {prodId:"10418104",label:"Protea cortada seca follaje cordata"},
    {prodId:"10418105",label:"Protea cortada seca grandiceps"},
    {prodId:"10418106",label:"Protea cortada seca visón verde"},
    {prodId:"10418107",label:"Protea cortada seca hiedra"},
    {prodId:"10418108",label:"Protea cortada seca rey"},
    {prodId:"10418109",label:"Protea cortada seca nana cones"},
    {prodId:"10418110",label:"Protea cortada seca alfiletero anaranjada"},
    {prodId:"10418111",label:"Protea cortada seca alfiletero tango"},
    {prodId:"10418112",label:"Protea cortada seca alfiletero amarillo"},
    {prodId:"10418113",label:"Protea cortada seca hielo rosado"},
    {prodId:"10418114",label:"Protea cortada seca visón rosado"},
    {prodId:"10418115",label:"Protea cortada seca reina"},
    {prodId:"10418116",label:"Protea cortada seca repens"},
    {prodId:"10418117",label:"Protea cortada seca cuchara de rosas"},
    {prodId:"10418118",label:"Protea cortada seca silvia"},
    {prodId:"10418119",label:"Protea cortada seca sugarbush"},
    {prodId:"10418120",label:"Protea cortada seca susara"},
    {prodId:"10418121",label:"Protea cortada seca waratha"},
    {prodId:"10418122",label:"Protea cortada seca visón blanco"},
    {prodId:"10418200",label:"leucadendron cortado seco"},
    {prodId:"10418201",label:"Leucadendron cortado seco argenteum"},
    {prodId:"10418202",label:"Leucadendron cortado seco delicia de crema"},
    {prodId:"10418203",label:"Leucadendron cortado seco cumosum"},
    {prodId:"10418204",label:"Leucadendron cortado seco discolor"},
    {prodId:"10418205",label:"Leucadendron cortado seco galpini"},
    {prodId:"10418206",label:"Leucadendron cortado seco mina de oro"},
    {prodId:"10418207",label:"Leucadendron cortado seco oro inca"},
    {prodId:"10418208",label:"Leucadendron cortado seco bufón"},
    {prodId:"10418209",label:"Leucadendron cortado seco laxum"},
    {prodId:"10418210",label:"Leucadendron cortado seco mini"},
    {prodId:"10418211",label:"Leucadendron cortado seco oro patea"},
    {prodId:"10418212",label:"Leucadendron cortado seco petra"},
    {prodId:"10418213",label:"Leucadendron cortado seco plumosum"},
    {prodId:"10418214",label:"Leucadendron cortado seco roseta"},
    {prodId:"10418215",label:"Leucadendron cortado seco atardecer safari"},
    {prodId:"10418216",label:"Leucadendron cortado seco atardecer safari spr"},
    {prodId:"10418217",label:"Leucadendron cortado seco speciosa"},
    {prodId:"10418218",label:"Leucadendron cortado seco spray"},
    {prodId:"10418219",label:"Leucadendron cortado seco maravilla wilson"},
    {prodId:"10418220",label:"Leucadendron cortado seco yarden"},
    {prodId:"10418300",label:"leucospermum cortado seco"},
    {prodId:"10418301",label:"Leucospermum cortado seco album"},
    {prodId:"10418302",label:"Leucospermum cortado seco attenuatum"},
    {prodId:"10418303",label:"Leucospermum cortado seco calligerum"},
    {prodId:"10418304",label:"Leucospermum cortado seco conocarpodendron"},
    {prodId:"10418305",label:"Leucospermum cortado seco cordatum"},
    {prodId:"10418306",label:"Leucospermum cortado seco cuneiforme"},
    {prodId:"10418307",label:"Leucospermum cortado seco formosum"},
    {prodId:"10418308",label:"Leucospermum cortado seco glabrum"},
    {prodId:"10418309",label:"Leucospermum cortado seco grandiflorum"},
    {prodId:"10418310",label:"Leucospermum cortado seco harmatum"},
    {prodId:"10418311",label:"Leucospermum cortado seco heterophyllum"},
    {prodId:"10418312",label:"Leucospermum cortado seco innovans"},
    {prodId:"10418313",label:"Leucospermum cortado seco muirii"},
    {prodId:"10418314",label:"Leucospermum cortado seco oleifolium"},
    {prodId:"10418315",label:"Leucospermum cortado seco patersonii"},
    {prodId:"10418316",label:"Leucospermum cortado seco pluridens"},
    {prodId:"10418317",label:"Leucospermum cortado seco praemorsum"},
    {prodId:"10418318",label:"Leucospermum cortado seco prostratum"},
    {prodId:"10418319",label:"Leucospermum cortado seco rodolentum"},
    {prodId:"10418320",label:"Leucospermum cortado seco saxatile"},
    {prodId:"10418321",label:"Leucospermum cortado seco secundifolium"},
    {prodId:"10418322",label:"Leucospermum cortado seco tomentosus"},
    {prodId:"10418323",label:"Leucospermum cortado seco truncatulum"},
    {prodId:"10418324",label:"Leucospermum cortado seco utriculosum"},
    {prodId:"10418325",label:"Leucospermum cortado seco winterii"},
    {prodId:"10418326",label:"Leucospermum cortado seco arenarium"},
    {prodId:"10418327",label:"Leucospermum cortado seco bolusii"},
    {prodId:"10418328",label:"Leucospermum cortado seco catherinae"},
    {prodId:"10418329",label:"Leucospermum cortado seco conocarpum"},
    {prodId:"10418330",label:"Leucospermum cortado seco cordifolium"},
    {prodId:"10418331",label:"Leucospermum cortado seco erubescens"},
    {prodId:"10418332",label:"Leucospermum cortado seco gerrardii"},
    {prodId:"10418333",label:"Leucospermum cortado seco gracile"},
    {prodId:"10418334",label:"Leucospermum cortado seco gueinzii"},
    {prodId:"10418335",label:"Leucospermum cortado seco harpagonatum"},
    {prodId:"10418336",label:"Leucospermum cortado seco hypophyllocarpodendron"},
    {prodId:"10418337",label:"Leucospermum cortado seco lineare"},
    {prodId:"10418338",label:"Leucospermum cortado seco mundii"},
    {prodId:"10418339",label:"Leucospermum cortado seco parile"},
    {prodId:"10418340",label:"Leucospermum cortado seco pendunculatum"},
    {prodId:"10418341",label:"Leucospermum cortado seco praecox"},
    {prodId:"10418342",label:"Leucospermum cortado seco profugum"},
    {prodId:"10418343",label:"Leucospermum cortado seco reflexum"},
    {prodId:"10418344",label:"Leucospermum cortado seco royenifolium"},
    {prodId:"10418345",label:"Leucospermum cortado seco saxosum"},
    {prodId:"10418346",label:"Leucospermum cortado seco spathulatum"},
    {prodId:"10418347",label:"Leucospermum cortado seco tottum"},
    {prodId:"10418348",label:"Leucospermum cortado seco truncatum"},
    {prodId:"10418349",label:"Leucospermum cortado seco vestitum"},
    {prodId:"10418350",label:"Leucospermum cortado seco wittebergense"},
    {prodId:"10421500",label:"Agapanto cortado seco"},
    {prodId:"10421501",label:"Agapanto cortado seco azul"},
    {prodId:"10421502",label:"Agapanto cortado seco blanco"},
    {prodId:"10421600",label:"Alchimilla cortada seca"},
    {prodId:"10421601",label:"Alchimilla cortada seca capa de dama"},
    {prodId:"10421602",label:"Alchimilla cortada seca robustica"},
    {prodId:"10421700",label:"Astilbe cortado seco"},
    {prodId:"10421701",label:"Astilbe cortado seco rosado fuerte"},
    {prodId:"10421702",label:"Astilbe cortado seco rosado claro"},
    {prodId:"10421703",label:"Astilbe cortado seco rojo"},
    {prodId:"10421704",label:"Astilbe cortado seco blanco"},
    {prodId:"10421800",label:"angélica cortada seca"},
    {prodId:"10421801",label:"Angélica cortada seca gigas"},
    {prodId:"10421802",label:"Angélica cortada seca sylvestris"},
    {prodId:"10421900",label:"ajenjo cortado seco"},
    {prodId:"10421901",label:"Ajenjo cortado seco verde"},
    {prodId:"10421902",label:"Ajenjo cortado seco rey dorado"},
    {prodId:"10422000",label:"Flor de alcachofa cortada seca"},
    {prodId:"10422001",label:"Flor de alcachofa cortada seca chocolate"},
    {prodId:"10422002",label:"Flor de alcachofa cortada seca verde"},
    {prodId:"10422003",label:"Flor de alcachofa cortada seca púrpura o floreciente"},
    {prodId:"10422100",label:"Astrantia cortada seca"},
    {prodId:"10422101",label:"Astrantia cortada seca rosada"},
    {prodId:"10422102",label:"Astrantia cortada seca blanca"},
    {prodId:"10422200",label:"Flor de banano cortada seca"},
    {prodId:"10422201",label:"Flor de banano cortada seca anaranjada"},
    {prodId:"10422202",label:"Flor de banano cortada seca antorcha anaranjada"},
    {prodId:"10422203",label:"Flor de banano cortada seca púrpura"},
    {prodId:"10422300",label:"Baptisia cortada seca"},
    {prodId:"10422301",label:"Baptisia cortada seca australis"},
    {prodId:"10422302",label:"Baptisia cortada seca sphaerocarpa"},
    {prodId:"10422400",label:"Boronia cortada seca"},
    {prodId:"10422401",label:"Boronia cortada seca rosada"},
    {prodId:"10422402",label:"Boronia cortada seca amarilla"},
    {prodId:"10422500",label:"Bromelia cortada seca"},
    {prodId:"10422501",label:"Bromelia cortada seca amarilla reg"},
    {prodId:"10422502",label:"Bromelia cortada seca roja reg"},
    {prodId:"10422600",label:"Brunia cortada seca"},
    {prodId:"10422601",label:"Brunia albiflora cortada seca"},
    {prodId:"10422602",label:"Brunia albiflora cortada seca verde"},
    {prodId:"10422603",label:"Brunia cortada seca rocío de plata"},
    {prodId:"10422700",label:"Catalea cortada seca"},
    {prodId:"10422701",label:"Calatea cortada seca cigarro"},
    {prodId:"10422702",label:"Calatea cortada seca hielo verde"},
    {prodId:"10422703",label:"Calatea cortada seca serpiente cascabel"},
    {prodId:"10422800",label:"Calcynia cortada seca"},
    {prodId:"10422801",label:"Calcynia cortada seca rosada"},
    {prodId:"10422802",label:"Calcynia cortada seca princesa"},
    {prodId:"10422803",label:"Calcynia cortada seca blanca"},
    {prodId:"10422900",label:"Caléndula cortada seca"},
    {prodId:"10422901",label:"Caléndula cortada seca anaranjada"},
    {prodId:"10422902",label:"Caléndula cortada seca amarilla"},
    {prodId:"10423000",label:"Campanilla cortada seca"},
    {prodId:"10423001",label:"Campanilla cortada seca azul"},
    {prodId:"10423002",label:"Campanilla cortada seca rosada"},
    {prodId:"10423003",label:"Campanilla cortada seca blanca"},
    {prodId:"10423100",label:"Cestrum cortado seco"},
    {prodId:"10423101",label:"Cestrum cortado seco rojo"},
    {prodId:"10423102",label:"Cestrum cortado seco rojo zohar"},
    {prodId:"10423103",label:"Cestrum cortado seco amarillo"},
    {prodId:"10423200",label:"Chasmante cortada seca"},
    {prodId:"10423201",label:"Chasmante floribunda cortada seca amarilla"},
    {prodId:"10423202",label:"Chasmante floribunda cortada seca anaranjada"},
    {prodId:"10423300",label:"Costus cortada seca"},
    {prodId:"10423301",label:"Costus barbada cortada seca"},
    {prodId:"10423302",label:"Costus cortada seca cabeza de indio"},
    {prodId:"10423400",label:"Vara de San José cortada seca"},
    {prodId:"10423401",label:"Vara de san josé lucifer cortada seca"},
    {prodId:"10423402",label:"Vara de san josé cortada seca vainas"},
    {prodId:"10423403",label:"Vara de san josé cortada seca amarilla"},
    {prodId:"10423500",label:"Lirio del Fuego cortado seco"},
    {prodId:"10423501",label:"Lirio del fuego cortado seco anaranjado brillante"},
    {prodId:"10423502",label:"Lirio del fuego cortado seco crema"},
    {prodId:"10423503",label:"Lirio del fuego cortado seco anaranjado"},
    {prodId:"10423504",label:"Lirio del fuego cortado seco amarillo"},
    {prodId:"10423600",label:"Flor del muelle cortada seca"},
    {prodId:"10423601",label:"Flor del muelle cortada seca verde"},
    {prodId:"10423602",label:"Flor del muelle cortada seca roja"},
    {prodId:"10423700",label:"Planta erizo cortada seca"},
    {prodId:"10423701",label:"Planta erizo cortada seca tintura negra"},
    {prodId:"10423702",label:"Planta erizo cortada seca tintura anaranjada"},
    {prodId:"10423800",label:"Manzanilla cortada seca"},
    {prodId:"10423801",label:"Manzanilla cortada seca sencilla vegmo"},
    {prodId:"10423802",label:"Manzanilla cortada seca doble blanca"},
    {prodId:"10423803",label:"Manzanilla cortada seca bola de nieve"},
    {prodId:"10423804",label:"Manzanilla cortada seca blanca"},
    {prodId:"10423900",label:"Nomeolvides cortada seca"},
    {prodId:"10423901",label:"Nomeolvides cortada seca azul"},
    {prodId:"10423902",label:"Nomeolvides cortada seca blanca"},
    {prodId:"10424000",label:"Gallardia cortada seca"},
    {prodId:"10424001",label:"Gallardia cortada seca anaranjada"},
    {prodId:"10424002",label:"Gallardia cortada seca amarilla"},
    {prodId:"10424100",label:"Genciana cortada seca"},
    {prodId:"10424101",label:"Genciana cortada seca azul"},
    {prodId:"10424102",label:"Genciana cortada seca blanca"},
    {prodId:"10424200",label:"Gladiolo cortado seco"},
    {prodId:"10424201",label:"Gladiolo cortado seco glamini rosado"},
    {prodId:"10424202",label:"Gladiolo cortado seco glamini rojo"},
    {prodId:"10424300",label:"Gloriosa cortada seca"},
    {prodId:"10424301",label:"Gloriosa cortada seca anaranjada"},
    {prodId:"10424302",label:"Gloriosa cortada seca roja"},
    {prodId:"10424303",label:"Gloriosa cortada seca amarilla"},
    {prodId:"10424400",label:"Violeta silvestre cortada seca"},
    {prodId:"10424401",label:"Violeta silvestre cortada seca anaranjada"},
    {prodId:"10424402",label:"Violeta silvestre cortada seca roja"},
    {prodId:"10424500",label:"Eléboro cortado seco"},
    {prodId:"10424501",label:"Eléboro cortado seco verde"},
    {prodId:"10424502",label:"Eléboro cortado seco rayo de luna"},
    {prodId:"10424600",label:"Ixia cortada seca"},
    {prodId:"10424601",label:"Ixia cortada seca rosada"},
    {prodId:"10424602",label:"Ixia cortada seca blanca"},
    {prodId:"10424700",label:"Liatrises cortadas secas"},
    {prodId:"10424701",label:"Liatris cortada seca púrpura"},
    {prodId:"10424702",label:"Liatris cortada seca spray"},
    {prodId:"10424703",label:"Liatris cortada seca blanca"},
    {prodId:"10424800",label:"Lysimachia cortada seca"},
    {prodId:"10424801",label:"Lysimachia punctata cortada seca"},
    {prodId:"10424802",label:"Lysimachia vulgaris cortada seca"},
    {prodId:"10424803",label:"Lysimachia blanca cortada seca"},
    {prodId:"10424804",label:"Lysimachia amarilla cortada seca"},
    {prodId:"10424900",label:"Maraca cortada seca"},
    {prodId:"10424901",label:"Maraca cortada seca marrón"},
    {prodId:"10424902",label:"Maraca cortada seca shampoo ginger"},
    {prodId:"10425000",label:"Caléndula cortada seca"},
    {prodId:"10425001",label:"Caléndula cortada seca francesa"},
    {prodId:"10425002",label:"Caléndula cortada seca verde"},
    {prodId:"10425003",label:"Caléndula (marigold) cortada seca anaranjada"},
    {prodId:"10425004",label:"Caléndula (marigold) cortada seca amarilla"},
    {prodId:"10425200",label:"Nerina cortada seca"},
    {prodId:"10425201",label:"Nerina cortada seca rosada"},
    {prodId:"10425202",label:"Nerina samiensis blanca"},
    {prodId:"10425300",label:"Flor de nogal cortada seca"},
    {prodId:"10425301",label:"Flor colgante de nogal blanco cortada seca"},
    {prodId:"10425302",label:"Flor sin hojas de nogal blanco cortada seca"},
    {prodId:"10425303",label:"Flor erecta de nogal blanco brasileño cortada seca"},
    {prodId:"10425400",label:"Phlox cortada seca"},
    {prodId:"10425401",label:"Phlox cortada seca rosado oscur"},
    {prodId:"10425402",label:"Phlox cortada seca lavanda"},
    {prodId:"10425403",label:"Phlox cortada seca rosado claro"},
    {prodId:"10425404",label:"Phlox cortada seca blanca"},
    {prodId:"10425500",label:"physostegia cortada seca"},
    {prodId:"10425501",label:"Physostegia cortada seca rosada"},
    {prodId:"10425502",label:"Physostegia cortada seca vainas"},
    {prodId:"10425503",label:"Physostegia cortada seca blanca"},
    {prodId:"10425600",label:"Saponaria cortada seca"},
    {prodId:"10425601",label:"Saponaria cortada seca rosada"},
    {prodId:"10425602",label:"Saponaria cortada seca blanca"},
    {prodId:"10425700",label:"Sarracenias cortadas secas"},
    {prodId:"10425701",label:"Sarracenia cortada seca flava rugelii"},
    {prodId:"10425800",label:"Sicilia cortada seca"},
    {prodId:"10425801",label:"Sicilia cortada seca campanulata azul"},
    {prodId:"10425802",label:"Sicilia cortada seca campanulata rosada"},
    {prodId:"10425803",label:"Sicilia cortada seca campanulata blanca"},
    {prodId:"10425804",label:"Sicilia cortada seca peruana"},
    {prodId:"10425900",label:"Sedum cortada seca"},
    {prodId:"10425901",label:"Sedum cortada seca marrón"},
    {prodId:"10425902",label:"Sedum cortada seca verde"},
    {prodId:"10425903",label:"Sedum cortada seca rosada"},
    {prodId:"10425904",label:"Sedum cortada seca roja"},
    {prodId:"10426200",label:"Tanaceto cortado seco"},
    {prodId:"10426201",label:"Tanaceto cortado seco amazonas"},
    {prodId:"10426202",label:"Tanaceto cortado seco victoria doble blanco"},
    {prodId:"10426203",label:"Tanaceto cortado seco victoria sencillo blanco"},
    {prodId:"10426204",label:"Tanaceto cortado seco vegmo amarillo"},
    {prodId:"10426300",label:"Traquelio cortado seco"},
    {prodId:"10426301",label:"Trachelium jade cortada seca"},
    {prodId:"10426302",label:"Trachelium púrpura cortada seca"},
    {prodId:"10426303",label:"Trachelium blanca cortada seca"},
    {prodId:"10426400",label:"Tuberosas cortadas secas"},
    {prodId:"10426401",label:"Tuberosa cortada seca doble"},
    {prodId:"10426402",label:"Tuberosa cortada seca sencilla"},
    {prodId:"10426500",label:"Tweedias cortadas secas"},
    {prodId:"10426501",label:"Tweedia azul cortada seca"},
    {prodId:"10426502",label:"Tweedia blanca cortada seca"},
    {prodId:"10426600",label:"Verónicas cortadas secas"},
    {prodId:"10426601",label:"Verónica cortada seca rosada"},
    {prodId:"10426602",label:"Verónica cortada seca púrpura"},
    {prodId:"10426603",label:"Verónica cortada seca blanca"},
    {prodId:"10426700",label:"Watsonias cortadas secas"},
    {prodId:"10426701",label:"Watsonia cortada seca anaranjada"},
    {prodId:"10426702",label:"Watsonia cortada seca rosada"},
    {prodId:"10426703",label:"Watsonia cortada seca roja"},
    {prodId:"10426704",label:"Watsonia cortada seca blanca"},
    {prodId:"10431500",label:"Crisantemo cortado seco pompón"},
    {prodId:"10431501",label:"Crisantemo cortado seco pompón delirock"},
    {prodId:"10431502",label:"Crisantemo cortado seco pompón discovery"},
    {prodId:"10431503",label:"Crisantemo cortado seco pompón focus"},
    {prodId:"10431504",label:"Crisantemo cortado seco pompón jeanne"},
    {prodId:"10431505",label:"Crisantemo cortado seco pompón lady"},
    {prodId:"10431506",label:"Crisantemo cortado seco pompón leidi"},
    {prodId:"10431507",label:"Crisantemo cortado seco pompón lexy"},
    {prodId:"10431508",label:"Crisantemo cortado seco pompón ole"},
    {prodId:"10431509",label:"Crisantemo cortado seco pompón revise"},
    {prodId:"10431510",label:"Crisantemo cortado seco pompón estadista"},
    {prodId:"10431511",label:"Crisantemo cortado seco pompón dulce"},
    {prodId:"10431512",label:"Crisantemo cortado seco pompón yoko ono"},
    {prodId:"10431513",label:"Crisantemo cortado seco pompón zip"},
    {prodId:"10431600",label:"Crisantemo cortado seco pompón margarita"},
    {prodId:"10431601",label:"Crisantemo cortado seco pompón artista rosado"},
    {prodId:"10431602",label:"Crisantemo cortado seco pompón artista amarillo"},
    {prodId:"10431603",label:"Crisantemo cortado seco pompón atlantis rosado"},
    {prodId:"10431604",label:"Crisantemo cortado seco pompón atlantis blanco"},
    {prodId:"10431605",label:"Crisantemo cortado seco pompón atlantis amarillo"},
    {prodId:"10431606",label:"Crisantemo cortado seco pompón bennie jolink"},
    {prodId:"10431607",label:"Crisantemo cortado seco pompón bennie jolink amarillo"},
    {prodId:"10431608",label:"Crisantemo cortado seco pompón managua bronce"},
    {prodId:"10431609",label:"Crisantemo cortado seco pompón clave"},
    {prodId:"10431610",label:"Crisantemo cortado seco pompón ficción coral"},
    {prodId:"10431611",label:"Crisantemo cortado seco pompón cumbia"},
    {prodId:"10431612",label:"Crisantemo cortado seco pompón cantata oscura"},
    {prodId:"10431613",label:"Crisantemo cortado seco pompón lineker oscuro"},
    {prodId:"10431614",label:"Crisantemo cortado seco pompón dipper"},
    {prodId:"10431615",label:"Crisantemo cortado seco pompón rosado elite"},
    {prodId:"10431616",label:"Crisantemo cortado seco pompón blanco elite"},
    {prodId:"10431617",label:"Crisantemo cortado seco pompón amarillo elite"},
    {prodId:"10431618",label:"Crisantemo cortado seco pompón factor"},
    {prodId:"10431619",label:"Crisantemo cortado seco pompón ficción"},
    {prodId:"10431620",label:"Crisantemo cortado seco pompón fuerza"},
    {prodId:"10431621",label:"Crisantemo cortado seco pompón reagan mejorado"},
    {prodId:"10431622",label:"Crisantemo cortado seco pompón life"},
    {prodId:"10431623",label:"Crisantemo cortado seco pompón managua anaranjado"},
    {prodId:"10431624",label:"Crisantemo cortado seco pompón novedsd bronce cocarde"},
    {prodId:"10431625",label:"Crisantemo cortado seco pompón reagan anaranjado"},
    {prodId:"10431626",label:"Crisantemo cortado seco pompón orinoco"},
    {prodId:"10431627",label:"Crisantemo cortado seco pompón petra"},
    {prodId:"10431628",label:"Crisantemo cortado seco pompón balsas rosado"},
    {prodId:"10431629",label:"Crisantemo cortado seco pompón mona lisa rosado"},
    {prodId:"10431630",label:"Crisantemo cortado seco pompón reagan rosado"},
    {prodId:"10431631",label:"Crisantemo cortado seco pompón marfil reagan"},
    {prodId:"10431632",label:"Crisantemo cortado seco pompón reagan rosado"},
    {prodId:"10431633",label:"Crisantemo cortado seco pompón rebasco"},
    {prodId:"10431634",label:"Crisantemo cortado seco pompón redock"},
    {prodId:"10431635",label:"Crisantemo cortado seco pompón salmón lineker"},
    {prodId:"10431636",label:"Crisantemo cortado seco pompón sheba"},
    {prodId:"10431637",label:"Crisantemo cortado seco pompón sirius"},
    {prodId:"10431638",label:"Crisantemo cortado seco pompón reagan espléndido"},
    {prodId:"10431639",label:"Crisantemo cortado seco pompón reagan soleado"},
    {prodId:"10431640",label:"Crisantemo cortado seco pompón tina"},
    {prodId:"10431641",label:"Crisantemo cortado seco pompón vero"},
    {prodId:"10431642",label:"Crisantemo cortado seco pompón volare"},
    {prodId:"10431643",label:"Crisantemo cortado seco pompón vida blanca"},
    {prodId:"10431644",label:"Crisantemo cortado seco pompón regan blanco"},
    {prodId:"10431645",label:"Crisantemo cortado seco pompón rino blanco"},
    {prodId:"10431646",label:"Crisantemo cortado seco pompón vero amarillo"},
    {prodId:"10431647",label:"Crisantemo cortado seco pompón zenith"},
    {prodId:"10431700",label:"Crisantemo cortado seco espollado cremon"},
    {prodId:"10431701",label:"Crisantemo cortado seco espollado cremon annecy oscura"},
    {prodId:"10431702",label:"Crisantemo cortado seco espollado cremon"},
    {prodId:"10431703",label:"Crisantemo cortado seco espollado cremon atlantis rosado"},
    {prodId:"10431704",label:"Crisantemo cortado seco espollado cremon eleonora bronce"},
    {prodId:"10431705",label:"Crisantemo cortado seco espollado cremon eleonora lila"},
    {prodId:"10431706",label:"Crisantemo cortado seco espollado cremon eleonora rosado"},
    {prodId:"10431707",label:"Crisantemo cortado seco espollado cremon eleonora nieve"},
    {prodId:"10431708",label:"Crisantemo cortado seco espollado cremon eleonora amarillo"},
    {prodId:"10431709",label:"Crisantemo cortado seco espollado cremon idea"},
    {prodId:"10431710",label:"Crisantemo cortado seco espollado cremon ivanna púrpura"},
    {prodId:"10431711",label:"Crisantemo cortado seco espollado cremon minka rosado"},
    {prodId:"10431712",label:"Crisantemo cortado seco espollado cremon listo"},
    {prodId:"10431713",label:"Crisantemo cortado seco espollado cremon sinatra"},
    {prodId:"10431714",label:"Crisantemo cortado seco rover rojo"},
    {prodId:"10431800",label:"Crisantemo cortado seco espollado football"},
    {prodId:"10431801",label:"Crisantemo cortado seco espollado blaze"},
    {prodId:"10431802",label:"Crisantemo cortado seco espollado beso de football"},
    {prodId:"10431803",label:"Crisantemo cortado seco espollado football lavanda / rosado"},
    {prodId:"10431804",label:"Crisantemo cortado seco espollado football resouci"},
    {prodId:"10431805",label:"Crisantemo cortado seco espollado footbal blanco"},
    {prodId:"10431806",label:"Crisantemo cortado seco espollado football amarillo"},
    {prodId:"10431807",label:"Crisantemo cortado seco espollado promenade"},
    {prodId:"10431808",label:"Crisantemo cortado seco espollado rebonnet"},
    {prodId:"10431809",label:"Crisantemo cortado seco espollado reflex"},
    {prodId:"10431810",label:"Crisantemo cortado seco espollado residence"},
    {prodId:"10431811",label:"Crisantemo cortado seco espollado resomee perla"},
    {prodId:"10431812",label:"Crisantemo cortado seco espollado resouci"},
    {prodId:"10431900",label:"Crisantemo spider cortado seco"},
    {prodId:"10431901",label:"Crisantemo spider cortado seco anastasia bronce"},
    {prodId:"10431902",label:"Crisantemo spider cortado seco anastasia bronce oscuro"},
    {prodId:"10431903",label:"Crisantemo spider cortado seco anastasia verde"},
    {prodId:"10431904",label:"Crisantemo spider cortado seco anastasia lila"},
    {prodId:"10431905",label:"Crisantemo spider cortado seco anastasia rosado"},
    {prodId:"10431906",label:"Crisantemo spider cortado seco anastasia púrpura"},
    {prodId:"10431907",label:"Crisantemo spider cortado seco anastasia soleado"},
    {prodId:"10431908",label:"Crisantemo spider cortado seco anastasia blanco"},
    {prodId:"10431909",label:"Crisantemo spider cortado seco bradford"},
    {prodId:"10431910",label:"Crisantemo spider cortado seco delistar blanco"},
    {prodId:"10431911",label:"Crisantemo spider cortado seco delistar amarillo"},
    {prodId:"10431912",label:"Crisantemo spider cortado seco minka"},
    {prodId:"10431913",label:"Crisantemo spider cortado seco natasha soleado"},
    {prodId:"10431914",label:"Crisantemo spider cortado seco pirouette"},
    {prodId:"10431915",label:"Crisantemo spider cortado seco reflejo"},
    {prodId:"10431916",label:"Crisantemo spider cortado seco regata"},
    {prodId:"10431917",label:"Crisantemo spider cortado seco render"},
    {prodId:"10431918",label:"Crisantemo spider cortado seco repertorio"},
    {prodId:"10431919",label:"Crisantemo spider cortado seco resolute"},
    {prodId:"10431920",label:"Crisantemo spider cortado seco resomac"},
    {prodId:"10431921",label:"Crisantemo spider cortado seco trébol"},
    {prodId:"10431922",label:"Crisantemo spider cortado seco talante bronce"},
    {prodId:"10431923",label:"Crisantemo spider cortado seco super blanco"},
    {prodId:"10431924",label:"Crisantemo spider cortado seco super amarillo"},
    {prodId:"10431925",label:"Crisantemo spider cortado seco tierno"},
    {prodId:"10431926",label:"Crisantemo spider cortado seco zembia"},
    {prodId:"10432000",label:"Crisantemo cortado seco  novelty"},
    {prodId:"10432001",label:"Crisantemo cortado seco pompón anecy rosado"},
    {prodId:"10432002",label:"Crisantemo cortado seco pompón ardilo royal"},
    {prodId:"10432003",label:"Crisantemo cortado seco pompón athos"},
    {prodId:"10432004",label:"Crisantemo cortado seco pompón biarritz"},
    {prodId:"10432005",label:"Crisantemo cortado seco pompón bradford anaranjado"},
    {prodId:"10432006",label:"Crisantemo cortado seco pompón bradford"},
    {prodId:"10432007",label:"Crisantemo cortado seco pompón vela"},
    {prodId:"10432008",label:"Crisantemo cortado seco pompón candor"},
    {prodId:"10432009",label:"Crisantemo cortado seco pompón gallardía"},
    {prodId:"10432010",label:"Crisantemo cortado seco pompón décima"},
    {prodId:"10432012",label:"Crisantemo cortado seco pompón delisun"},
    {prodId:"10432013",label:"Crisantemo cortado seco pompón dion"},
    {prodId:"10432014",label:"Crisantemo cortado seco pompón dorena"},
    {prodId:"10432015",label:"Crisantemo cortado seco pompón dublín"},
    {prodId:"10432016",label:"Crisantemo cortado seco pompón everglades"},
    {prodId:"10432017",label:"Crisantemo cortado seco pompón buenmozo"},
    {prodId:"10432018",label:"Crisantemo cortado seco pompón hasting"},
    {prodId:"10432019",label:"Crisantemo cortado seco pompón high five"},
    {prodId:"10432020",label:"Crisantemo cortado seco pompón mundial mejorado"},
    {prodId:"10432021",label:"Crisantemo cortado seco pompón juanes"},
    {prodId:"10432022",label:"Crisantemo cortado seco pompón kiato verde"},
    {prodId:"10432023",label:"Crisantemo cortado seco pompón kiato"},
    {prodId:"10432024",label:"Crisantemo cortado seco pompón kiwi"},
    {prodId:"10432025",label:"Crisantemo cortado seco pompón madeira"},
    {prodId:"10432026",label:"Crisantemo cortado seco pompón magneto"},
    {prodId:"10432027",label:"Crisantemo cortado seco pompón marimo"},
    {prodId:"10432028",label:"Crisantemo cortado seco pompón matrix"},
    {prodId:"10432029",label:"Crisantemo cortado seco pompón miletta"},
    {prodId:"10432030",label:"Crisantemo cortado seco pompón monalisa"},
    {prodId:"10432031",label:"Crisantemo cortado seco pompón omaha"},
    {prodId:"10432032",label:"Crisantemo cortado seco pompón orinoco púrpura"},
    {prodId:"10432033",label:"Crisantemo cortado seco pompón orinoco amarillo"},
    {prodId:"10432034",label:"Crisantemo cortado seco pompón verde pacífico"},
    {prodId:"10432035",label:"Crisantemo cortado seco pompón blanco puma"},
    {prodId:"10432036",label:"Crisantemo cortado seco pompón amarillo puma"},
    {prodId:"10432037",label:"Crisantemo cortado seco pompón mundial púrpura"},
    {prodId:"10432038",label:"Crisantemo cortado seco pompón regata"},
    {prodId:"10432039",label:"Crisantemo cortado seco pompón remco"},
    {prodId:"10432040",label:"Crisantemo cortado seco pompón royal mundial"},
    {prodId:"10432041",label:"Crisantemo cortado seco pompón sabrina"},
    {prodId:"10432042",label:"Crisantemo cortado seco pompón shakira blanco"},
    {prodId:"10432043",label:"Crisantemo cortado seco pompón sharp"},
    {prodId:"10432044",label:"Crisantemo cortado seco pompón shock"},
    {prodId:"10432045",label:"Crisantemo cortado seco pompón sizzle verde"},
    {prodId:"10432046",label:"Crisantemo cortado seco pompón sizzle rosado"},
    {prodId:"10432047",label:"Crisantemo cortado seco pompón sizzle"},
    {prodId:"10432048",label:"Crisantemo cortado seco pompón sizzle púrpura"},
    {prodId:"10432049",label:"Crisantemo cortado seco pompón sizzle salmón"},
    {prodId:"10432050",label:"Crisantemo cortado seco pompón sizzle amarillo"},
    {prodId:"10432051",label:"Crisantemo cortado seco pompón bandera española"},
    {prodId:"10432052",label:"Crisantemo cortado seco pompón starbust o copo de nieve"},
    {prodId:"10432053",label:"Crisantemo cortado seco pompón cisne"},
    {prodId:"10432054",label:"Crisantemo cortado seco pompón tedcha anaranjado"},
    {prodId:"10432055",label:"Crisantemo cortado seco pompón tierno"},
    {prodId:"10432056",label:"Crisantemo cortado seco pompón oropel"},
    {prodId:"10432057",label:"Crisantemo cortado seco pompón toque"},
    {prodId:"10432058",label:"Crisantemo cortado seco pompón troyes"},
    {prodId:"10432059",label:"Crisantemo cortado seco pompón valesca"},
    {prodId:"10432060",label:"Crisantemo cortado seco pompón anaranjado vikingo"},
    {prodId:"10432061",label:"Crisantemo cortado seco pompón vikingo"},
    {prodId:"10432062",label:"Crisantemo cortado seco pompón reloj"},
    {prodId:"10432063",label:"Crisantemo cortado seco pompón aguja blanco"},
    {prodId:"10432064",label:"Crisantemo cortado seco pompón noche blanca"},
    {prodId:"10432065",label:"Crisantemo cortado seco pompón artista amarillo"},
    {prodId:"10432066",label:"Crisantemo cortado seco pompón ficción amarillo"},
    {prodId:"10432067",label:"Crisantemo cortado seco pompón sharp amarillo"},
    {prodId:"10432100",label:"Crisantemo cortado seco santini"},
    {prodId:"10432101",label:"Crisantemo cortado seco pompón alma"},
    {prodId:"10432102",label:"Crisantemo cortado seco pompón barón"},
    {prodId:"10432103",label:"Crisantemo cortado seco pompón bernardo"},
    {prodId:"10432104",label:"Crisantemo cortado seco pompón bistro"},
    {prodId:"10432105",label:"Crisantemo cortado seco pompón bodega"},
    {prodId:"10432106",label:"Crisantemo cortado seco pompón brisa"},
    {prodId:"10432107",label:"Crisantemo cortado seco pompón centella bronce"},
    {prodId:"10432108",label:"Crisantemo cortado seco pompón costa blanca"},
    {prodId:"10432109",label:"Crisantemo cortado seco pompón creta"},
    {prodId:"10432110",label:"Crisantemo cortado seco pompón deliflame"},
    {prodId:"10432111",label:"Crisantemo cortado seco pompón dalila"},
    {prodId:"10432112",label:"Crisantemo cortado seco pompón dígito"},
    {prodId:"10432113",label:"Crisantemo cortado seco pompón evilio"},
    {prodId:"10432114",label:"Crisantemo cortado seco pompón furense"},
    {prodId:"10432115",label:"Crisantemo cortado seco pompón guía"},
    {prodId:"10432116",label:"Crisantemo cortado seco pompón kerry"},
    {prodId:"10432117",label:"Crisantemo cortado seco pompón kess"},
    {prodId:"10432118",label:"Crisantemo cortado seco pompón lima"},
    {prodId:"10432119",label:"Crisantemo cortado seco pompón lupo"},
    {prodId:"10432120",label:"Crisantemo cortado seco pompón lineker anaranjado"},
    {prodId:"10432121",label:"Crisantemo cortado seco pompón panuco rojo"},
    {prodId:"10432122",label:"Crisantemo cortado seco pompón costa rosado"},
    {prodId:"10432123",label:"Crisantemo cortado seco pompón rafael"},
    {prodId:"10432124",label:"Crisantemo cortado seco pompón refine"},
    {prodId:"10432125",label:"Crisantemo cortado seco pompón regaliz"},
    {prodId:"10432126",label:"Crisantemo cortado seco pompón renella"},
    {prodId:"10432127",label:"Crisantemo cortado seco pompón retorno"},
    {prodId:"10432128",label:"Crisantemo cortado seco pompón río"},
    {prodId:"10432129",label:"Crisantemo cortado seco pompón sabas"},
    {prodId:"10432130",label:"Crisantemo cortado seco pompón target"},
    {prodId:"10432131",label:"Crisantemo cortado seco pompón texto"},
    {prodId:"10432132",label:"Crisantemo cortado seco pompón caballo amarillo"},
    {prodId:"10441500",label:"Clavel cortado seco de floración sencilla"},
    {prodId:"10441501",label:"Clavel cortado seco de floración sencilla burgundy bicolor"},
    {prodId:"10441502",label:"Clavel cortado seco de floración sencilla burgundy"},
    {prodId:"10441503",label:"Clavel cortado seco de floración sencilla cinderella"},
    {prodId:"10441504",label:"Clavel cortado seco de floración sencilla crema bicolor"},
    {prodId:"10441505",label:"Clavel cortado seco de floración sencilla crema"},
    {prodId:"10441506",label:"Clavel cortado seco de floración sencilla verde o prado"},
    {prodId:"10441507",label:"Clavel cortado seco de floración sencilla rosado fuerte"},
    {prodId:"10441508",label:"Clavel cortado seco de floración sencilla verde claro"},
    {prodId:"10441509",label:"Clavel cortado seco de floración sencilla rosado claro"},
    {prodId:"10441510",label:"Clavel cortado seco de floración sencilla anaranjado bicolor"},
    {prodId:"10441511",label:"Clavel cortado seco de floración sencilla anaranjado"},
    {prodId:"10441512",label:"Clavel cortado seco de floración sencilla durazno"},
    {prodId:"10441513",label:"Clavel cortado seco de floración sencilla menta bicolor"},
    {prodId:"10441514",label:"Clavel cortado seco de floración sencilla rosado bicolor"},
    {prodId:"10441515",label:"Clavel cortado seco de floración sencilla rosado"},
    {prodId:"10441516",label:"Clavel cortado seco de floración sencilla púrpura bicolor"},
    {prodId:"10441517",label:"Clavel cortado seco de floración sencilla rojo bicolor"},
    {prodId:"10441518",label:"Clavel cortado seco de floración sencilla rojo"},
    {prodId:"10441519",label:"Clavel cortado seco de floración sencilla blanco"},
    {prodId:"10441520",label:"Clavel cortado seco de floración sencilla amarillo"},
    {prodId:"10441600",label:"Clavel cortado seco mini o spray"},
    {prodId:"10441601",label:"Clavel cortado seco mini o spray burgundy"},
    {prodId:"10441602",label:"Clavel cortado seco mini o spray"},
    {prodId:"10441603",label:"Clavel cortado seco mini o spray rosado fuerte"},
    {prodId:"10441604",label:"Clavel cortado seco mini o spray lavanda"},
    {prodId:"10441605",label:"Clavel cortado seco mini o spray rosado claro"},
    {prodId:"10441606",label:"Clavel cortado seco mini o spray anaranjado"},
    {prodId:"10441607",label:"Clavel cortado seco mini o spray durazno"},
    {prodId:"10441608",label:"Clavel cortado seco mini o spray menta"},
    {prodId:"10441609",label:"Clavel cortado seco mini o spray rosado"},
    {prodId:"10441610",label:"Clavel cortado seco mini o spray púrpura bicolor"},
    {prodId:"10441611",label:"Clavel cortado seco mini o spray púrpura"},
    {prodId:"10441612",label:"Clavel cortado seco mini o spray rojo"},
    {prodId:"10441613",label:"Clavel cortado seco mini o spray blanco"},
    {prodId:"10441614",label:"Clavel cortado seco mini o spray amarillo"},
    {prodId:"10451500",label:"Orquidea cypripedium  o pantuflas de dama cortada seca"},
    {prodId:"10451501",label:"Orquidea cypripedium cortada seca verde"},
    {prodId:"10451502",label:"Orquidea cypripedium cortada seca francia"},
    {prodId:"10451503",label:"Orquidea cypripedium cortada seca púrpura rey arturo"},
    {prodId:"10451504",label:"Orquidea paphiopedilum cortada seca verde"},
    {prodId:"10451505",label:"Orquidea aranthera maggie vie cortada seca"},
    {prodId:"10451600",label:"Orquídea mocara cortada seca"},
    {prodId:"10451601",label:"Orquídea mocara cortada seca omyai"},
    {prodId:"10451602",label:"Orquídea mocara cortada seca roja"},
    {prodId:"10451603",label:"Orquídea mocara cortada seca calipso"},
    {prodId:"10451604",label:"Orquídea mocara cortada seca nora"},
    {prodId:"10451605",label:"Orquídea mocara cortada seca panee"},
    {prodId:"10451700",label:"Orquídea catleya cortada seca"},
    {prodId:"10451701",label:"Orquídea cattleya cortada seca blanca"},
    {prodId:"10451702",label:"Orquídea cattleya cortada seca r b lavanda"},
    {prodId:"10451800",label:"Orquídea disa cortada seca"},
    {prodId:"10451801",label:"Orquídea disa cortada seca roja"},
    {prodId:"10451802",label:"Orquídea disa cortada seca anaranjada"},
    {prodId:"10451803",label:"Orquídea disa cortada seca rosada"},
    {prodId:"10451804",label:"Orquídea disa cortada seca anaranjada y amarilla bicolor"},
    {prodId:"10451805",label:"Orquídea disa cortada seca durazno y amarilla bicolor"},
    {prodId:"10451806",label:"Orquídea disa cortada seca amarilla y roja bicolor"},
    {prodId:"10451900",label:"Orquídea arachnis cortada seca"},
    {prodId:"10451901",label:"Orquídea arachnis cortada seca james storie roja"},
    {prodId:"10451902",label:"Orquídea arachnis cortada seca maggie oei roja"},
    {prodId:"10451903",label:"Orquídea arachnis cortada seca maggie oei amarilla"},
    {prodId:"10451904",label:"Orquídea arachnis cortada seca maroon maggie"},
    {prodId:"10451905",label:"Orquídea arachnis cortada seca merry maggie"},
    {prodId:"10452000",label:"Orquídea phalaenopsis cortada seca"},
    {prodId:"10452001",label:"Orquídea phalaenopsis cortada seca amabilis"},
    {prodId:"10452002",label:"Orquídea phalaenopsis cortada seca amboinensis"},
    {prodId:"10452003",label:"Orquídea phalaenopsis cortada seca afrodita"},
    {prodId:"10452004",label:"Orquídea phalaenopsis cortada seca appendiculata"},
    {prodId:"10452005",label:"Orquídea phalaenopsis cortada seca bastianii"},
    {prodId:"10452006",label:"Orquídea phalaenopsis cortada seca bellina"},
    {prodId:"10452007",label:"Orquídea phalaenopsis cortada seca borneensis"},
    {prodId:"10452008",label:"Orquídea phalaenopsis cortada seca braceana"},
    {prodId:"10452009",label:"Orquídea phalaenopsis cortada seca buyssoniana"},
    {prodId:"10452010",label:"Orquídea phalaenopsis cortada seca celebensis"},
    {prodId:"10452011",label:"Orquídea phalaenopsis cortada seca chibae"},
    {prodId:"10452012",label:"Orquídea phalaenopsis cortada seca cochlearis"},
    {prodId:"10452013",label:"Orquídea phalaenopsis cortada seca corningiana"},
    {prodId:"10452014",label:"Orquídea phalaenopsis cortada seca cornu-cervi"},
    {prodId:"10452015",label:"Orquídea phalaenopsis cortada seca deliciosa"},
    {prodId:"10452016",label:"Orquídea phalaenopsis cortada seca doweryënsis"},
    {prodId:"10452017",label:"Orquídea phalaenopsis cortada seca equestris"},
    {prodId:"10452018",label:"Orquídea phalaenopsis cortada seca fasciata"},
    {prodId:"10452019",label:"Orquídea phalaenopsis cortada seca fimbriata"},
    {prodId:"10452020",label:"Orquídea phalaenopsis cortada seca floresensis"},
    {prodId:"10452021",label:"Orquídea phalaenopsis cortada seca fuscata"},
    {prodId:"10452022",label:"Orquídea phalaenopsis cortada seca gibbosa"},
    {prodId:"10452023",label:"Orquídea phalaenopsis cortada seca hainanensis"},
    {prodId:"10452024",label:"Orquídea phalaenopsis cortada seca hieroglyphica"},
    {prodId:"10452025",label:"Orquídea phalaenopsis cortada seca honghenensis"},
    {prodId:"10452026",label:"Orquídea phalaenopsis cortada seca inscriptiosinensis"},
    {prodId:"10452027",label:"Orquídea phalaenopsis cortada seca javanica"},
    {prodId:"10452028",label:"Orquídea phalaenopsis cortada seca kunstleri"},
    {prodId:"10452029",label:"Orquídea phalaenopsis cortada seca lamelligera"},
    {prodId:"10452030",label:"Orquídea phalaenopsis cortada seca lindenii"},
    {prodId:"10452031",label:"Orquídea phalaenopsis cortada seca lobbii"},
    {prodId:"10452032",label:"Orquídea phalaenopsis cortada seca lowii"},
    {prodId:"10452033",label:"Orquídea phalaenopsis cortada seca lueddemanniana"},
    {prodId:"10452034",label:"Orquídea phalaenopsis cortada seca mambo"},
    {prodId:"10452035",label:"Orquídea phalaenopsis cortada seca luteola"},
    {prodId:"10452036",label:"Orquídea phalaenopsis cortada seca maculata"},
    {prodId:"10452037",label:"Orquídea phalaenopsis cortada seca malipoensis"},
    {prodId:"10452038",label:"Orquídea phalaenopsis cortada seca mannii"},
    {prodId:"10452039",label:"Orquídea phalaenopsis cortada seca mariae"},
    {prodId:"10452040",label:"Orquídea phalaenopsis cortada seca micholitzii"},
    {prodId:"10452041",label:"Orquídea phalaenopsis cortada seca modesta"},
    {prodId:"10452042",label:"Orquídea phalaenopsis cortada seca mysorensis"},
    {prodId:"10452043",label:"Orquídea phalaenopsis cortada seca pallens"},
    {prodId:"10452044",label:"Orquídea phalaenopsis cortada seca pantherina"},
    {prodId:"10452045",label:"Orquídea phalaenopsis cortada seca parishii"},
    {prodId:"10452046",label:"Orquídea phalaenopsis cortada seca petelotii"},
    {prodId:"10452047",label:"Orquídea phalaenopsis cortada seca philippinensis"},
    {prodId:"10452048",label:"Orquídea phalaenopsis cortada seca pulcherrima"},
    {prodId:"10452049",label:"Orquídea phalaenopsis cortada seca pulchra"},
    {prodId:"10452050",label:"Orquídea phalaenopsis cortada seca regnieriana"},
    {prodId:"10452051",label:"Orquídea phalaenopsis cortada seca reichenbachiana"},
    {prodId:"10452052",label:"Orquídea phalaenopsis cortada seca nivacolor"},
    {prodId:"10452053",label:"Orquídea phalaenopsis cortada seca sanderiana"},
    {prodId:"10452054",label:"Orquídea phalaenopsis cortada seca schilleriana"},
    {prodId:"10452055",label:"Orquídea phalaenopsis cortada seca speciosa"},
    {prodId:"10452056",label:"Orquídea phalaenopsis cortada seca stobartiana"},
    {prodId:"10452057",label:"Orquídea phalaenopsis cortada seca stuartiana"},
    {prodId:"10452058",label:"Orquídea phalaenopsis cortada seca sumatrana"},
    {prodId:"10452059",label:"Orquídea phalaenopsis cortada seca taenialis"},
    {prodId:"10452060",label:"Orquídea phalaenopsis cortada seca tetraspis"},
    {prodId:"10452061",label:"Orquídea phalaenopsis cortada seca venosa"},
    {prodId:"10452062",label:"Orquídea phalaenopsis cortada seca violácea"},
    {prodId:"10452063",label:"Orquídea phalaenopsis cortada seca viridis"},
    {prodId:"10452064",label:"Orquídea phalaenopsis cortada seca wilsonii"},
    {prodId:"10452065",label:"Orquídea phalaenopsis cortada seca zebrina"},
    {prodId:"10452067",label:"Orquídea phalaenopsis cortada seca labio lavanda"},
    {prodId:"10452100",label:"Orquídea dendrobium cortada seca"},
    {prodId:"10452101",label:"Orquídea dendrobium cortada seca bom"},
    {prodId:"10452102",label:"Orquídea dendrobium cortada seca burana"},
    {prodId:"10452103",label:"Orquídea dendrobium cortada seca chita"},
    {prodId:"10452104",label:"Orquídea dendrobium cortada seca fátima"},
    {prodId:"10452105",label:"Orquídea dendrobium cortada seca intuwong"},
    {prodId:"10452106",label:"Orquídea dendrobium cortada seca jumbo blanca"},
    {prodId:"10452107",label:"Orquídea dendrobium cortada seca kating dang"},
    {prodId:"10452108",label:"Orquídea dendrobium cortada seca libertad"},
    {prodId:"10452109",label:"Orquídea dendrobium cortada seca hawaii"},
    {prodId:"10452110",label:"Orquídea dendrobium cortada seca sakura sweet rosada"},
    {prodId:"10452111",label:"Orquídea dendrobium cortada seca sensacional púrpura"},
    {prodId:"10452112",label:"Orquídea dendrobium cortada seca blanca"},
    {prodId:"10452200",label:"Orquídea cymbidium cortada seca"},
    {prodId:"10452201",label:"Orquídea cymbidium cortada seca crema"},
    {prodId:"10452202",label:"Orquídea cymbidium cortada seca verde"},
    {prodId:"10452203",label:"Orquídea cymbidium cortada seca mini verde"},
    {prodId:"10452204",label:"Orquídea cymbidium cortada seca mini rosada"},
    {prodId:"10452205",label:"Orquídea cymbidium cortada seca mini roja"},
    {prodId:"10452206",label:"Orquídea cymbidium cortada seca mini blanca"},
    {prodId:"10452207",label:"Orquídea cymbidium cortada seca mini amarilla"},
    {prodId:"10452208",label:"Orquídea cymbidium cortada seca chocolate"},
    {prodId:"10452209",label:"Orquídea cymbidium cortada seca rosado oscuro"},
    {prodId:"10452210",label:"Orquídea cymbidium cortada seca anaranjada"},
    {prodId:"10452211",label:"Orquídea cymbidium cortada seca rosada"},
    {prodId:"10452212",label:"Orquídea cymbidium cortada seca blanca"},
    {prodId:"10452213",label:"Orquídea cymbidium cortada seca amarilla"},
    {prodId:"10452300",label:"Orquídea oncidium cortada seca"},
    {prodId:"10452301",label:"Orquídea oncidium cortada seca ducha dorada"},
    {prodId:"10452302",label:"Orquídea oncidium cortada seca rhamsey"},
    {prodId:"10452400",label:"Orquídea vanda cortada seca"},
    {prodId:"10452401",label:"Orquídea vanda alizarin"},
    {prodId:"10452402",label:"Orquídea vanda rosada fuerte"},
    {prodId:"10452403",label:"Orquídea vanda lavanda"},
    {prodId:"10452404",label:"Orquídea vanda púrpura"},
    {prodId:"10452405",label:"Orquídea vanda hazme cosquillas rosada"},
    {prodId:"10452406",label:"Orquídea vanda amarilla"},
    {prodId:"10501500",label:"Aspidistras cortadas frescas"},
    {prodId:"10501501",label:"Aspidistra cortada fresca verde"},
    {prodId:"10501502",label:"Aspidistra cortada fresca vía láctea"},
    {prodId:"10501503",label:"Aspidistra cortada fresca variegada"},
    {prodId:"10501700",label:"Haya cortada fresca"},
    {prodId:"10501701",label:"Haya cortada fresca bronce"},
    {prodId:"10501702",label:"Haya cortada fresca verde"},
    {prodId:"10501703",label:"Haya cortada fresca rojo"},
    {prodId:"10501900",label:"Helechos cortados frescos"},
    {prodId:"10501901",label:"Helecho cortado fresco coontie"},
    {prodId:"10501902",label:"Helecho cortado fresco pluma"},
    {prodId:"10501903",label:"Helecho cortado fresco plano"},
    {prodId:"10501904",label:"Helecho cortado fresco pelo de mujer"},
    {prodId:"10501905",label:"Helecho cortado fresco ming"},
    {prodId:"10501906",label:"Helecho cortado fresco espada florida alto"},
    {prodId:"10501907",label:"Helecho cortado fresco árbol cortado"},
    {prodId:"10501908",label:"Helecho cortado fresco sombrilla"},
    {prodId:"10502200",label:"mora huckleberry fresca cortada"},
    {prodId:"10502201",label:"Mora huckleberry fresca cortada verde"},
    {prodId:"10502202",label:"Mora huckleberry fresca cortada roja"},
    {prodId:"10502300",label:"Hiedra cortada fresca"},
    {prodId:"10502301",label:"Hiedra fresca cortada verde"},
    {prodId:"10502302",label:"Hiedra fresca cortada varigada"},
    {prodId:"10502303",label:"Hiedra fresca cortada erecta"},
    {prodId:"10502304",label:"Hiedra fresca cortada verde de árbol"},
    {prodId:"10502305",label:"Hiedra fresca cortada varirgada de árbol"},
    {prodId:"10502400",label:"Integrifolia cortada fresca"},
    {prodId:"10502401",label:"Integrifolia fresca cortada roja"},
    {prodId:"10502402",label:"Integrifolia fresca cortada natural"},
    {prodId:"10502600",label:"Hojas frescas cortadas de roble"},
    {prodId:"10502601",label:"Hojas frescas cortadas de roble rojo de holanda"},
    {prodId:"10502700",label:"Oregonia fresca cortada"},
    {prodId:"10502701",label:"Oregonia fresca cortada verde"},
    {prodId:"10502702",label:"Oregonia fresca cortada plateada"},
    {prodId:"10502703",label:"Oregonia fresca cortada variegada"},
    {prodId:"10502800",label:"pittosporum fresco cortado"},
    {prodId:"10502801",label:"Pittosporum fresco cortado verde"},
    {prodId:"10502802",label:"Pittosporum fresco cortado nevatus"},
    {prodId:"10502803",label:"Pittosporum fresco cortado nueva zelandia"},
    {prodId:"10502804",label:"Pittosporum fresco cortado nigra"},
    {prodId:"10502805",label:"Pittosporum fresco cortado variegado"},
    {prodId:"11171700",label:"Aceros rápidos"},
    {prodId:"11171701",label:"Acero de alta velocidad z90wdcv6542 o m2"},
    {prodId:"11171702",label:"Acero de alta velocidad z90wdkcv65542 or m35"},
    {prodId:"11171800",label:"Superaleaciones de níquel"},
    {prodId:"11171801",label:"Súper aleación inconel 600"},
    {prodId:"11171900",label:"Superaleaciones de titanio"},
    {prodId:"11171901",label:"Súper aleación ta6v"},
    {prodId:"11172100",label:"Superaleaciones de cobalto"},
    {prodId:"11172101",label:"Aleación pigmalión ú 846"},
    {prodId:"11172300",label:"Aleaciones con base de manganeso"},
    {prodId:"11172301",label:"Aleación de ferro manganeso"},
    {prodId:"11172302",label:"Aleación de ferro manganeso carbono medio"},
    {prodId:"11172303",label:"Aleación de silicona manganeso"},
    {prodId:"11172304",label:"Aleación de ferro manganeso carbono bajo"},
    {prodId:"11191700",label:"Virutas"},
    {prodId:"11191701",label:"Torneados (virutas) de níquel"},
    {prodId:"11191702",label:"Torneados (virutas) de bronce"},
    {prodId:"12142109",label:"Hielo seco"},
    {prodId:"12142110",label:"Amoniaco líquido"},
    {prodId:"12162400",label:"Rompedores de polímero"},
    {prodId:"12162401",label:"Rompedores de polímero ácido"},
    {prodId:"12162402",label:"Rompedores de polímero orgánico"},
    {prodId:"12162500",label:"Rompedores de emulsión"},
    {prodId:"12162501",label:"Rompedores de emulsión agua en aceite"},
    {prodId:"12162502",label:"Rompedores de emulsión aceite en agua"},
    {prodId:"12162503",label:"Ayudas de flotación"},
    {prodId:"12162600",label:"Estabilizadores de arcilla"},
    {prodId:"12162601",label:"Estabilizadores de arcilla inorgánica"},
    {prodId:"12162602",label:"Estabilizadores de arcilla orgánica"},
    {prodId:"12163000",label:"Mezclas para quitar barro"},
    {prodId:"12163001",label:"Agentes de limpieza de lodo"},
    {prodId:"12163200",label:"Agentes de migración anti gas"},
    {prodId:"12163201",label:"Aditivos de migración anti gas"},
    {prodId:"12163700",label:"Reguladores de hidrato de gas"},
    {prodId:"12163701",label:"Controladores de hidrato de gas"},
    {prodId:"12164506",label:"Aglutinante de tabletas"},
    {prodId:"12164507",label:"Recubrimiento de tabletas"},
    {prodId:"12164700",label:"Antisépticos industriales"},
    {prodId:"12164701",label:"Aditivo antiséptico"},
    {prodId:"12352125",label:"Azidas o azinas"},
    {prodId:"13102021",label:"Polimida pi"},
    {prodId:"15131600",label:"Instalación de combustible de fisión"},
    {prodId:"15131601",label:"Barra de combustible nuclear"},
    {prodId:"20102200",label:"Maquinaria de cargue de explosivos"},
    {prodId:"20102201",label:"Maquinaria para cargar nitrato de amonio y fuel oil - anfo"},
    {prodId:"20102202",label:"Maquinaria para cargar emulsión"},
    {prodId:"20102203",label:"Repuestos y accesorios para maquinaria de cargue de explosivos"},
    {prodId:"20121200",label:"Equipo de fracturar"},
    {prodId:"20121201",label:"Equipo de fractura líquida a granel"},
    {prodId:"20121202",label:"Equipo de fractura a granel usando unidades de soporte"},
    {prodId:"20121203",label:"Unidades de control de fracturación"},
    {prodId:"20121204",label:"Sensores de densidad de fracturación"},
    {prodId:"20121205",label:"Unidades múltiples de fracturación"},
    {prodId:"20121206",label:"Equipo de transporte de fracturación usando unidades de soporte"},
    {prodId:"20121207",label:"Unidades de bombeo de fracturación"},
    {prodId:"20121208",label:"Unidades de mezclado de lechada fracturación"},
    {prodId:"20121209",label:"Unidades de mezcla de gel"},
    {prodId:"20121210",label:"Misiles de fracturación"},
    {prodId:"20121211",label:"Monitores de integridad de bombeo"},
    {prodId:"20121212",label:"Tapones de servicio de fracturación"},
    {prodId:"20121213",label:"Unidades de estimulación de bombeo"},
    {prodId:"20122113",label:"Accesorios de pistola de perforación de tubos completa"},
    {prodId:"20122200",label:"Equipo de prueba del pozo"},
    {prodId:"20122201",label:"Booms de bengalas"},
    {prodId:"20122202",label:"Quemadores de bengalas"},
    {prodId:"20122203",label:"Herramientas de prueba de hoyo"},
    {prodId:"20122204",label:"Colector del choke"},
    {prodId:"20122205",label:"Colector de desviación"},
    {prodId:"20122206",label:"Canastas de flowhead"},
    {prodId:"20122207",label:"Pivote de flowhead"},
    {prodId:"20122208",label:"Flowheads"},
    {prodId:"20122209",label:"Herramientas de formación de cierre"},
    {prodId:"20122210",label:"Bengalas de gas"},
    {prodId:"20122211",label:"Analizadores de gas de lodos"},
    {prodId:"20122212",label:"Muestreadores de petróleo"},
    {prodId:"20122214",label:"Tubería de superficie de pruebas de pozo"},
    {prodId:"20122215",label:"Tanques de compensación"},
    {prodId:"20122216",label:"Herramientas de prueba de fondo de pozo"},
    {prodId:"20122600",label:"Equipo sísmico"},
    {prodId:"20122601",label:"Sensores análogos sísmicos"},
    {prodId:"20122602",label:"Arreglos sísmicos"},
    {prodId:"20122603",label:"Aves de cable de serpentina sísmica"},
    {prodId:"20122604",label:"Tanqueros de taladro sísmico"},
    {prodId:"20122605",label:"Geófonos sísmicos"},
    {prodId:"20122606",label:"Sistemas de gravedad sísmicos"},
    {prodId:"20122607",label:"Sistemas de malacate de cañón sísmico"},
    {prodId:"20122608",label:"Hidrofonos sísmicos"},
    {prodId:"20122609",label:"Fuentes de impulso sísmicos"},
    {prodId:"20122610",label:"Cables serpentinas marinos sísmicos"},
    {prodId:"20122611",label:"Cables del fondo del océano sísmico"},
    {prodId:"20122612",label:"Sistemas magnéticos sísmicos"},
    {prodId:"20122613",label:"Equipo de posicionar sísmico"},
    {prodId:"20122614",label:"Arietes sísmicos"},
    {prodId:"20122615",label:"Receptores sísmicos"},
    {prodId:"20122616",label:"Sistemas de refracción sísmicos"},
    {prodId:"20122617",label:"Controladores de fuente sísmicos"},
    {prodId:"20122618",label:"Dispositivos de bobinar sísmicos"},
    {prodId:"20122619",label:"Bloques de remolque sísmicos"},
    {prodId:"20122620",label:"Puntos de remolque sísmicos"},
    {prodId:"20122621",label:"Vibradores sísmicos"},
    {prodId:"20122622",label:"Sistemas de grabar sísmicos"},
    {prodId:"20122623",label:"Sistemas de procesamiento de datos sísmicos"},
    {prodId:"20123000",label:"Equipo multilateral"},
    {prodId:"20123001",label:"Revestimiento multilateral"},
    {prodId:"20123002",label:"Uniones multilaterales"},
    {prodId:"20123003",label:"Tapones multilaterales"},
    {prodId:"20123004",label:"Partes y accesorios de tapones multilaterales"},
    {prodId:"20123100",label:"Herramienta para salida de revestimientos"},
    {prodId:"20123101",label:"Herramienta de ajuste de salida de revestimientos"},
    {prodId:"20123102",label:"Whipstock de salida de revestimientos"},
    {prodId:"20123300",label:"Herramientas para revestimientos mientras se perfora"},
    {prodId:"20123301",label:"Zapata de taladro"},
    {prodId:"20123302",label:"Partes y accesorios de zapata de taladro"},
    {prodId:"20123303",label:"Lanza de taladro"},
    {prodId:"20123304",label:"Partes y accesorios de lanza de taladro"},
    {prodId:"20131010",label:"Agentes para engruesar el lodo"},
    {prodId:"20131100",label:"Apuntalantes de fracturamiento de pozo"},
    {prodId:"20131101",label:"Apuntaladores cerámicos"},
    {prodId:"20131102",label:"Arenas de fracturación"},
    {prodId:"20131103",label:"Apuntaladores cerámicos cubiertos de resina"},
    {prodId:"20131104",label:"Arenas de fracturación cubiertas de resina"},
    {prodId:"20131105",label:"Bauxitas sinterizadas cubierta de resina"},
    {prodId:"20131106",label:"Bauxitas sinterizadas"},
    {prodId:"20131200",label:"Fluidos de completar"},
    {prodId:"20131201",label:"Salmueras divalentes"},
    {prodId:"20131202",label:"Salmueras monovalentes"},
    {prodId:"20131300",label:"Cemento de pozo petrolero"},
    {prodId:"20131301",label:"Cemento a granel de pozo petrolero"},
    {prodId:"20131302",label:"Pozo petrolero cemento tipo i clase a"},
    {prodId:"20131303",label:"Pozo petrolero cemento tipo ii clase b"},
    {prodId:"20131304",label:"Pozo petrolero cemento clase c"},
    {prodId:"20131305",label:"Pozo petrolero cemento clase g"},
    {prodId:"20131306",label:"Pozo petrolero cemento clase h"},
    {prodId:"20131307",label:"Cemento liviano de pozo petrolero"},
    {prodId:"20131308",label:"Pozo petrolero cemento estándar fino tipo iii"},
    {prodId:"20141012",label:"Revestimiento del cabezal de la carcasa"},
    {prodId:"20141013",label:"Carrete del cabezal de la tubería"},
    {prodId:"20141100",label:"Sistemas de inyección químicos"},
    {prodId:"20141101",label:"Sistemas de inyección de parafina"},
    {prodId:"20141200",label:"Equipo de desarenar"},
    {prodId:"20141201",label:"Equipo de desarenar producción"},
    {prodId:"20141400",label:"Accesorios de producción de barrenas hacia abajo"},
    {prodId:"20141401",label:"Topes de tubería"},
    {prodId:"20141600",label:"Bombas de exportación"},
    {prodId:"20141601",label:"Bombas neumáticas de exportación"},
    {prodId:"20141800",label:"Contadores para medir el flujo de producción del pozo"},
    {prodId:"20141801",label:"Contadores de la turbina de gas de producción del pozo"},
    {prodId:"20141900",label:"Equipo de tratamiento de gas"},
    {prodId:"20141901",label:"Equipo de tratamiento de gas para producción del pozo"},
    {prodId:"20142000",label:"Regeneradores de glicol"},
    {prodId:"20142001",label:"Regeneradores de glicol de pozo petrolero"},
    {prodId:"20142100",label:"Tratadores de calentadores"},
    {prodId:"20142101",label:"Tratadores de calentadores de aceite para pozo"},
    {prodId:"20142300",label:"Deslizaderos de inyección de producción"},
    {prodId:"20142301",label:"Patines de inyección neumática de metanol"},
    {prodId:"20142400",label:"Equipo para el lecho del océano"},
    {prodId:"20142401",label:"Equipo para producción submarina de boca de pozo"},
    {prodId:"20142402",label:"Tuberías submarinas umbilicales o flexibles"},
    {prodId:"20142403",label:"Sistema múltiple de distribución de producción submarina"},
    {prodId:"20142404",label:"Sistema de bombeo y separación anular vertical"},
    {prodId:"20142405",label:"Cruz de producción y componente submarino"},
    {prodId:"20142406",label:"Sistema de control submarino"},
    {prodId:"20142407",label:"Ensamble de terminación umbilical submarina"},
    {prodId:"20142500",label:"Equipo para tratar agua producida"},
    {prodId:"20142501",label:"Centrífugas de petróleo del agua del campo petrolífero"},
    {prodId:"20142600",label:"Instrumentación del sistema de control de producción"},
    {prodId:"20142601",label:"Sistemas inalámbricos de control de producción"},
    {prodId:"20143300",label:"Equipo para servicio de oleoductos"},
    {prodId:"20143301",label:"Dispositivo limpiador de oleoductos"},
    {prodId:"20143302",label:"Equipo para inspeccionar el interior del oleoducto"},
    {prodId:"20143303",label:"Cortador robótico del interior del oleoducto"},
    {prodId:"23101503",label:"Brochadoras"},
    {prodId:"23101505",label:"Maquinas abre huecos"},
    {prodId:"23152000",label:"Manejo del Web y maquinaria de control y equipo y suministros"},
    {prodId:"23152001",label:"Tratadores de corona"},
    {prodId:"23152002",label:"Tratadores por llama"},
    {prodId:"23153102",label:"Limitador de papel"},
    {prodId:"23153800",label:"Sistemas de revestimiento"},
    {prodId:"23153801",label:"Fluxer electrostático"},
    {prodId:"23153802",label:"Máquina para rebobinar bobinas eléctricas"},
    {prodId:"23181518",label:"Dispositivo para fermentar alimentos"},
    {prodId:"23221000",label:"Equipo y maquinaria del departamento de recepción"},
    {prodId:"23221001",label:"Sistema de descarga de jaulas"},
    {prodId:"23221002",label:"Lavador de jaulas"},
    {prodId:"23221100",label:"Equipo y maquinaria del departamento de matanza y desplumamiento"},
    {prodId:"23221101",label:"Aturdidor"},
    {prodId:"23221102",label:"Máquina desplumadora de pollos"},
    {prodId:"23221200",label:"Equipo y maquinaria del departamento de eviscerado"},
    {prodId:"23221201",label:"Cortador de aves"},
    {prodId:"23231800",label:"Troceadoras de madera y accesorios"},
    {prodId:"23231801",label:"Fijador de cuchilla"},
    {prodId:"23241700",label:"Máquinas rebabadoras de metales"},
    {prodId:"23241701",label:"Maquina de granallado"},
    {prodId:"23241702",label:"Máquina rebabadora de energía térmica"},
    {prodId:"23241703",label:"Máquina de terminado vibradora o de barril"},
    {prodId:"23242200",label:"Máquinas para fabricación de engranajes"},
    {prodId:"23242201",label:"Generador de engranajes biselados"},
    {prodId:"23242202",label:"Máquina talladora de engranajes"},
    {prodId:"23242203",label:"Máquina para el perfeccionamiento de engranajes"},
    {prodId:"23242204",label:"Máquinas de volteado de engranajes"},
    {prodId:"23242205",label:"Máquina para dar forma a engranajes"},
    {prodId:"23242206",label:"Máquina de cepillado"},
    {prodId:"23242207",label:"Máquina pulidora de engranajes"},
    {prodId:"23242208",label:"Máquina para biselar los dientes de engranajes"},
    {prodId:"23242700",label:"Maquinaria para molinos de tubos"},
    {prodId:"23242701",label:"Máquina para hacer componentes tubulares doblados"},
    {prodId:"23242702",label:"Máquina para hacer componentes tubulares soldados"},
    {prodId:"23251900",label:"Maquinaria y equipo para la fabricación de contenedores de metal"},
    {prodId:"23251901",label:"Alimentador de láminas de metal"},
    {prodId:"23291600",label:"Herramientas de molienda industrial"},
    {prodId:"23291601",label:"Inserto de molienda"},
    {prodId:"25131500",label:"Aeronave de alas fijas motorizada"},
    {prodId:"25131501",label:"Aeronave agrícola de ala fija"},
    {prodId:"25131502",label:"Avión de carga de hélice"},
    {prodId:"25131503",label:"Hidroaviones"},
    {prodId:"25131504",label:"Avión de hélice comercial de pasajeros"},
    {prodId:"25131505",label:"Avión jet de carga"},
    {prodId:"25131506",label:"Avión jet de pasajeros"},
    {prodId:"25131507",label:"Avión de hélice privado o de negocios"},
    {prodId:"25131508",label:"Jet privado o de negocios"},
    {prodId:"25131509",label:"Aeronave de entrenamiento de ala fija"},
    {prodId:"25131700",label:"Aeronave de ala fija militar"},
    {prodId:"25131701",label:"Avión bombardero"},
    {prodId:"25131702",label:"Avión cazabombardero"},
    {prodId:"25131703",label:"Avión de caza"},
    {prodId:"25131704",label:"Avión de ataque"},
    {prodId:"25131705",label:"Aviones no tripulados objetivo o de reconocimiento"},
    {prodId:"25131706",label:"Hidroaviones militares"},
    {prodId:"25131707",label:"Aeronave de reconocimiento o vigilancia"},
    {prodId:"25131708",label:"Avión antisubmarino"},
    {prodId:"25131709",label:"Avión de transporte militar"},
    {prodId:"25131800",label:"Avión especial"},
    {prodId:"25131801",label:"Dirigibles"},
    {prodId:"25131802",label:"Aeromodelo"},
    {prodId:"25131900",label:"Avión militar de ala giratoria"},
    {prodId:"25131902",label:"Helicópteros de transporte militar"},
    {prodId:"25131903",label:"Helicópteros de ataque"},
    {prodId:"25131904",label:"Helicópteros de reconocimiento"},
    {prodId:"25131905",label:"Helicópteros antisubmarinos"},
    {prodId:"25131906",label:"Aeronave de alas giratorias basculantes"},
    {prodId:"25151500",label:"Naves espaciales"},
    {prodId:"25151501",label:"Nave espacial tripulada"},
    {prodId:"25151502",label:"Estructuras de naves espaciales"},
    {prodId:"25191600",label:"Sistemas y equipo de apoyo para transporte espacial"},
    {prodId:"25191601",label:"Simuladores de vuelo de naves espaciales"},
    {prodId:"25191602",label:"Sistemas de suministro de carga para naves espaciales"},
    {prodId:"25191603",label:"Vehículos de lanzamiento líquido"},
    {prodId:"25191604",label:"Vehículos de lanzamiento sólido"},
    {prodId:"25191605",label:"Módulos de servicio para naves espaciales"},
    {prodId:"25201800",label:"Sistemas de control principal del avión"},
    {prodId:"25201801",label:"Sistemas de computadores de vuelo"},
    {prodId:"25201802",label:"Módulos de comando de naves especiales"},
    {prodId:"25201900",label:"Sistemas de urgencia del avión"},
    {prodId:"25201901",label:"Sistemas de control o extinción de incendios en aeronaves"},
    {prodId:"25201902",label:"Sistemas de escape o eyección de aeronaves"},
    {prodId:"25201903",label:"Sistemas de advertencia del avión"},
    {prodId:"25201904",label:"Paracaídas"},
    {prodId:"25202000",label:"Sistemas de energía de avión"},
    {prodId:"25202001",label:"Células solares de la nave espacial"},
    {prodId:"25202002",label:"Formaciones solares de la nave espacial"},
    {prodId:"25202003",label:"Unidades de la fuente de alimentación de avión"},
    {prodId:"25202004",label:"Sistemas de grupo electrógeno de pista (apu)"},
    {prodId:"25202300",label:"Controles de pasajeros de avión"},
    {prodId:"25202301",label:"Cinturones de seguridad del avión"},
    {prodId:"25202302",label:"Arneses de seguridad del avión"},
    {prodId:"26141600",label:"Equipo para ensamblaje subcrítico"},
    {prodId:"26141601",label:"Combustible para conjunto subcrítico"},
    {prodId:"26141602",label:"Componentes para conjunto subcrítico"},
    {prodId:"26141603",label:"Moderador para conjunto subcrítico"},
    {prodId:"26141800",label:"Aparatos para celda caliente"},
    {prodId:"26141801",label:"Equipo teledirigido para recintos radiactivos"},
    {prodId:"26141802",label:"Aparato de visión teledirigido para recintos radiactivos"},
    {prodId:"26141803",label:"Puertas de blindaje para recintos radiactivos"},
    {prodId:"26141804",label:"Toma muestras para recintos radiactivos"},
    {prodId:"26141805",label:"Material de elaboración de muestras para recintos radiactivos"},
    {prodId:"26141806",label:"Herramientas especiales para recintos radiactivos"},
    {prodId:"26141807",label:"Ventanas de vidrio de plomo para recintos radiactivos"},
    {prodId:"26141808",label:"Sistemas de descontaminación para recintos radiactivos"},
    {prodId:"26141809",label:"Aparatos de penetración para recintos radiactivos"},
    {prodId:"26141900",label:"Instrumentos nucleónicos industriales"},
    {prodId:"26141901",label:"Sistemas nucleónicos industriales de medida de polvo en el aire"},
    {prodId:"26141902",label:"Sistemas nucleónicos industriales de medida de la densidad"},
    {prodId:"26141904",label:"Indicadores de nivel de líquido nucleónico industrial"},
    {prodId:"26141905",label:"Sistemas de medida de masa nucleónica industrial por unidad de mineral"},
    {prodId:"26141906",label:"Sistemas de medida de la humedad industrial nucleónica"},
    {prodId:"26141907",label:"Sistemas de medida el espesor industrial nucleónico"},
    {prodId:"26141908",label:"Sistemas de medida del flujo industrial nucleónico"},
    {prodId:"26141909",label:"Separadores de isótopos"},
    {prodId:"26141910",label:"Instalaciones de producción de isótopos"},
    {prodId:"26141911",label:"Medidores de actividad del calibrador de isótopos"},
    {prodId:"26142000",label:"Equipo de irradiación"},
    {prodId:"26142001",label:"Fuentes de irradiación gamma"},
    {prodId:"26142002",label:"Sistemas de imanes"},
    {prodId:"26142003",label:"Unidades electrónicas nucleares nim"},
    {prodId:"26142004",label:"Irradiadores de neutrones"},
    {prodId:"26142005",label:"Cápsulas para ensayos de irradiación"},
    {prodId:"26142006",label:"Sistema de transferencia de muestras de irradiación"},
    {prodId:"26142007",label:"Generadores de neutrones"},
    {prodId:"26142100",label:"Equipos para reactores nucleares"},
    {prodId:"26142101",label:"Recipientes de irradiación de especímenes para reactores nucleares"},
    {prodId:"26142106",label:"Sistemas de varilla de mando para reactores nucleares"},
    {prodId:"26142108",label:"Instrumentación de flujo de neutrones incorporada al núcleo para reactores nucleares"},
    {prodId:"26142117",label:"Instrumentación de terremotos para reactores nucleares"},
    {prodId:"26142200",label:"Equipo para combustible nuclear"},
    {prodId:"26142201",label:"Tubos con revestimiento para combustible nuclear"},
    {prodId:"26142202",label:"Sistemas de detección de fallo de elementos para reactores nucleares"},
    {prodId:"30121900",label:"Materiales para estabilización y refuerzo de suelos"},
    {prodId:"30121901",label:"Estabilizador de suelo"},
    {prodId:"30151600",label:"Accesorios para tejados"},
    {prodId:"30151601",label:"Plafones de tejado"},
    {prodId:"30151602",label:"Planchas de escurrimiento"},
    {prodId:"30151603",label:"Parada de grava"},
    {prodId:"30151605",label:"Drenajes de tejados"},
    {prodId:"30151607",label:"Claraboyas de ventilación"},
    {prodId:"30151608",label:"Sofitos"},
    {prodId:"30151609",label:"Bordes de tejado"},
    {prodId:"30151610",label:"Escarificadores de tejado"},
    {prodId:"30152100",label:"Superficie"},
    {prodId:"30152101",label:"Acero de perdigones"},
    {prodId:"30161805",label:"Armarios de laboratorio"},
    {prodId:"30261800",label:"Barras y láminas de magnesio"},
    {prodId:"30261801",label:"Barra de magnesio extruida"},
    {prodId:"30261802",label:"Lámina de magnesio enrollada en frío"},
    {prodId:"30261900",label:"Lingotes, bandas, palanquillas y alambres de magnesio"},
    {prodId:"30261901",label:"Alambre de magnesio"},
    {prodId:"30261902",label:"Banda de magnesio"},
    {prodId:"30261903",label:"Palanquilla de magnesio"},
    {prodId:"30261904",label:"Lingote de magnesio"},
    {prodId:"30262000",label:"Barras y láminas de titanio"},
    {prodId:"30262001",label:"Barra de titanio fundida"},
    {prodId:"30262002",label:"Lámina de titanio enrollada en caliente"},
    {prodId:"30262100",label:"Lingotes, bandas, palanquillas y alambres de titanio"},
    {prodId:"30262101",label:"Alambre de titanio"},
    {prodId:"30262102",label:"Banda de titanio"},
    {prodId:"30262103",label:"Palanquilla de titanio"},
    {prodId:"30262104",label:"Lingote de titanio"},
    {prodId:"30262300",label:"Láminas de cobre"},
    {prodId:"30262301",label:"Lámina de cobre enrollada en caliente"},
    {prodId:"30262302",label:"Lámina de cobre enrollada en frío"},
    {prodId:"30262600",label:"Láminas de bronce"},
    {prodId:"30262601",label:"Lámina de bronce fósforo enrollada en caliente"},
    {prodId:"30262602",label:"Lámina de bronce fósforo enrollada en frío"},
    {prodId:"30262603",label:"Lámina de bronce aluminio enrollada en caliente"},
    {prodId:"30262604",label:"Lámina de bronce aluminio estirada en frío"},
    {prodId:"30262605",label:"Lámina de bronce silicona enrollada en caliente"},
    {prodId:"30262606",label:"Lámina de bronce silicona estirada en frío"},
    {prodId:"30262700",label:"Lingotes, bandas, palanquillas y alambres de bronce"},
    {prodId:"30262701",label:"Rollo de alambre de bronce"},
    {prodId:"30262702",label:"Banda de bronce"},
    {prodId:"30262703",label:"Palanquilla de bronce"},
    {prodId:"30262704",label:"Lingote de bronce"},
    {prodId:"30263100",label:"Láminas de estaño"},
    {prodId:"30263101",label:"Lámina de estaño enrollada en caliente"},
    {prodId:"30263102",label:"Lámina de estaño enrollada en frío"},
    {prodId:"30263300",label:"Barras de plomo"},
    {prodId:"30263301",label:"Barra de plomo extruido"},
    {prodId:"30263302",label:"Barra de plomo enrollada en caliente"},
    {prodId:"30263303",label:"Barra de plomo estirada en frío"},
    {prodId:"30263400",label:"Láminas de plomo"},
    {prodId:"30263401",label:"Lámina de plomo enrollada en caliente"},
    {prodId:"30263402",label:"Lámina de plomo enrollada en frío"},
    {prodId:"30264100",label:"Láminas de aleación de acero"},
    {prodId:"30264101",label:"Lámina de aleación de acero al carbón serie sae 4000 enrollada en caliente"},
    {prodId:"30264102",label:"Lámina de aleación de acero al carbón serie sae 5000 enrollada en caliente"},
    {prodId:"30264103",label:"Lámina de aleación de acero al carbón serie sae 6000 enrollada en caliente"},
    {prodId:"30264104",label:"Lámina de aleación de acero al carbón serie sae 8000 enrollada en caliente"},
    {prodId:"30264105",label:"Lámina de aleación de acero al carbón serie sae 9000 enrollada en caliente"},
    {prodId:"30264106",label:"Lámina de aleación de acero al carbón serie sae 4000 enrollada en frío"},
    {prodId:"30264107",label:"Lámina de aleación de acero al carbón serie sae 5000 enrollada en frío"},
    {prodId:"30264108",label:"Lámina de aleación de acero al carbón serie sae 6000 enrollada en frío"},
    {prodId:"30264109",label:"Lámina de aleación de acero al carbón serie sae 8000 enrollada en frío"},
    {prodId:"30264110",label:"Lámina de aleación de acero al carbón serie sae 9000 enrollada en frío"},
    {prodId:"30265402",label:"Lámina de hierro gris enrollada en caliente"},
    {prodId:"30265500",label:"Lingotes, bandas, palanquillas y alambres de hierro"},
    {prodId:"30265501",label:"Alambre de hierro dúctil"},
    {prodId:"30265502",label:"Banda de hierro dúctil"},
    {prodId:"30265503",label:"Palanquilla de hierro dúctil"},
    {prodId:"30265504",label:"Palanquilla de hierro gris"},
    {prodId:"30265505",label:"Palanquilla de hierro blanco"},
    {prodId:"30265506",label:"Palanquilla de hierro maleable"},
    {prodId:"30265600",label:"Barras y láminas de aleaciones ferrosas"},
    {prodId:"30265601",label:"Barra de aleación ferrosa"},
    {prodId:"30266000",label:"Barras de metales preciosos y metales especiales"},
    {prodId:"30266001",label:"Barra de metal precioso"},
    {prodId:"30266002",label:"Barra de aleación no ferrosa"},
    {prodId:"30266100",label:"Láminas de metales preciosos y metales especiales"},
    {prodId:"30266101",label:"Lámina de aleación de berilio"},
    {prodId:"30266102",label:"Lámina de metal compuesto"},
    {prodId:"30266103",label:"Lámina de aleación de no ferrosa"},
    {prodId:"30266600",label:"Láminas y lingotes de níckel"},
    {prodId:"30266601",label:"Lámina de níquel"},
    {prodId:"30266602",label:"Lingote de níquel"},
    {prodId:"31101618",label:"Fundición en arena de hierro dúctil"},
    {prodId:"31101619",label:"Fundición en arena de hierro maleable"},
    {prodId:"31102100",label:"Piezas fundidas en molde cerámico"},
    {prodId:"31102101",label:"Objetos de aleación no ferrosa fundidos en molde cerámico"},
    {prodId:"31102102",label:"Objetos de aleación ferrosa fundidos en molde cerámico"},
    {prodId:"31102103",label:"Objetos de acero fundidos en molde cerámico"},
    {prodId:"31102104",label:"Objetos de acero inoxidable fundidos en molde cerámico"},
    {prodId:"31102105",label:"Objetos de hierro fundidos en molde cerámico"},
    {prodId:"31102106",label:"Objetos de aluminio fundidos en molde cerámico"},
    {prodId:"31102107",label:"Objetos de magnesio fundidos en molde cerámico"},
    {prodId:"31102108",label:"Objetos de titanio fundidos en molde cerámico"},
    {prodId:"31102109",label:"Objetos de berilio fundidos en molde cerámico"},
    {prodId:"31102110",label:"Objetos de cobre fundidos en molde cerámico"},
    {prodId:"31102111",label:"Objetos de latón fundidos en molde cerámico"},
    {prodId:"31102112",label:"Objetos de bronce fundidos en molde cerámico"},
    {prodId:"31102113",label:"Objetos de zinc fundidos en molde cerámico"},
    {prodId:"31102114",label:"Objetos de estaño fundidos en molde cerámico"},
    {prodId:"31102115",label:"Objetos de plomo fundidos en molde cerámico"},
    {prodId:"31102116",label:"Objetos de metal precioso fundidos en molde cerámico"},
    {prodId:"31102500",label:"Fundiciones con troquel y ensambles de fundiciones permanentes de baja presión"},
    {prodId:"31102501",label:"Objetos de aluminio de baja presión fundidos en molde fijo"},
    {prodId:"31102700",label:"Fundiciones y ensambles de fundiciones por apriete"},
    {prodId:"31102701",label:"Objetos de aluminio moldeados por apriete (squeeze)"},
    {prodId:"31102800",label:"Fundiciones con troquel y ensambles de fundiciones semi - sólidas"},
    {prodId:"31102801",label:"Objetos de aluminio de alta presión moldeados con troquel"},
    {prodId:"31111700",label:"Extrusiones en frío"},
    {prodId:"31111701",label:"Extrusiones en frío de aluminio"},
    {prodId:"31111702",label:"Extrusiones en frío de berilio"},
    {prodId:"31111703",label:"Extrusiones en frío de latón"},
    {prodId:"31111704",label:"Extrusiones en frío de bronce"},
    {prodId:"31111705",label:"Extrusiones en frío de cobre"},
    {prodId:"31111706",label:"Extrusiones en frío de aleación ferrosa"},
    {prodId:"31111707",label:"Extrusiones en frío de plomo"},
    {prodId:"31111708",label:"Extrusiones en frío de magnesio"},
    {prodId:"31111709",label:"Extrusiones en frío de aleación no ferrosa"},
    {prodId:"31111710",label:"Extrusiones en frío de plástico"},
    {prodId:"31111711",label:"Extrusiones en frío de metal precioso"},
    {prodId:"31111712",label:"Extrusiones en frío de caucho"},
    {prodId:"31111713",label:"Extrusiones en frío de acero inoxidable"},
    {prodId:"31111714",label:"Extrusiones en frío de acero"},
    {prodId:"31111715",label:"Extrusiones en frío de estaño"},
    {prodId:"31111716",label:"Extrusiones en frío de titanio"},
    {prodId:"31111717",label:"Extrusiones en frío de zinc"},
    {prodId:"31121400",label:"Fundiciones de molde de yeso mecanizadas"},
    {prodId:"31121401",label:"Objetos maquinados de aleación no ferrosa fundidos en molde de yeso"},
    {prodId:"31121402",label:"Objetos maquinados de aleación ferrosa fundidos en molde de yeso"},
    {prodId:"31121403",label:"Objetos maquinados de acero fundidos en molde de yeso"},
    {prodId:"31121404",label:"Objetos maquinados de acero inoxidable fundidos en molde de yeso"},
    {prodId:"31121405",label:"Objetos maquinados de hierro fundidos en molde de yeso"},
    {prodId:"31121406",label:"Objetos maquinados de aluminio fundidos en molde de yeso"},
    {prodId:"31121407",label:"Objetos maquinados de magnesio fundidos en molde de yeso"},
    {prodId:"31121408",label:"Objetos maquinados de titanio fundidos en molde de yeso"},
    {prodId:"31121409",label:"Objetos maquinados de berilio fundidos en molde de yeso"},
    {prodId:"31121410",label:"Objetos maquinados de cobre fundidos en molde de yeso"},
    {prodId:"31121411",label:"Objetos maquinados de latón fundidos en molde de yeso"},
    {prodId:"31121412",label:"Objetos maquinados de bronce fundidos en molde de yeso"},
    {prodId:"31121413",label:"Objetos maquinados de cinc fundidos en molde de yeso"},
    {prodId:"31121414",label:"Objetos maquinados de estaño fundidos en molde de yeso"},
    {prodId:"31121415",label:"Objetos maquinados de plomo fundidos en molde de yeso"},
    {prodId:"31121416",label:"Objetos maquinados de metal precioso fundidos en molde de yeso"},
    {prodId:"31121417",label:"Objetos maquinados de compuestos fundidos en molde de yeso"},
    {prodId:"31121418",label:"Objetos maquinados de aleación de níquel fundidos en molde de yeso"},
    {prodId:"31121419",label:"Objetos maquinados no metálicos fundidos en molde de yeso"},
    {prodId:"31121500",label:"Fundiciones de molde de concha"},
    {prodId:"31121501",label:"Objetos maquinados de aleación no ferrosa fundidos en molde en concha"},
    {prodId:"31121502",label:"Objetos maquinados de aleación ferrosa fundidos en molde en concha"},
    {prodId:"31121503",label:"Objetos maquinados de acero fundidos en molde en concha"},
    {prodId:"31121504",label:"Objetos maquinados de acero inoxidable fundidos en molde en concha"},
    {prodId:"31121505",label:"Objetos maquinados de hierro fundidos en molde en concha"},
    {prodId:"31121506",label:"Objetos maquinados de aluminio fundidos en molde en concha"},
    {prodId:"31121507",label:"Objetos maquinados de magnesio fundidos en molde en concha"},
    {prodId:"31121508",label:"Objetos maquinados de titanio fundidos en molde en concha"},
    {prodId:"31121509",label:"Objetos maquinados de berilio fundidos en molde en concha"},
    {prodId:"31121510",label:"Objetos maquinados de cobre fundidos en molde en concha"},
    {prodId:"31121511",label:"Objetos maquinados de latón fundidos en molde en concha"},
    {prodId:"31121512",label:"Objetos maquinados de bronce fundidos en molde en concha"},
    {prodId:"31121513",label:"Objetos maquinados de cinc fundidos en molde en concha"},
    {prodId:"31121514",label:"Objetos maquinados de estaño fundidos en molde en concha"},
    {prodId:"31121515",label:"Objetos maquinados de plomo fundidos en molde en concha"},
    {prodId:"31121516",label:"Objetos maquinados de metal precioso fundidos en molde en concha"},
    {prodId:"31121517",label:"Objetos maquinados de compuestos fundidos en molde en concha"},
    {prodId:"31121518",label:"Objetos maquinados de aleación de níquel fundidos en molde en concha"},
    {prodId:"31121519",label:"Objetos maquinados no metálicos fundidos en molde en concha"},
    {prodId:"31121520",label:"Objetos maquinados de hierro dúctil fundidos en molde en concha"},
    {prodId:"31121521",label:"Ensamble de objetos maquinados de hierro dúctil fundidos en molde en concha"},
    {prodId:"31121600",label:"Piezas fundidas mecanizadas de inversión"},
    {prodId:"31121601",label:"Objetos maquinados de aleación no ferrosa fundidos a la cera perdida"},
    {prodId:"31121602",label:"Objetos maquinados de aleación ferrosa fundidos a la cera perdida"},
    {prodId:"31121603",label:"Objetos maquinados de acero fundidos a la cera perdida"},
    {prodId:"31121604",label:"Objetos maquinados de acero inoxidable fundidos a la cera perdida"},
    {prodId:"31121605",label:"Objetos maquinados de hierro fundidos a la cera perdida"},
    {prodId:"31121606",label:"Objetos maquinados de aluminio fundidos a la cera perdida"},
    {prodId:"31121607",label:"Objetos maquinados de magnesio fundidos a la cera perdida"},
    {prodId:"31121608",label:"Objetos maquinados de zinc fundidos a la cera perdida"},
    {prodId:"31121609",label:"Objetos maquinados de estaño fundidos a la cera perdida"},
    {prodId:"31121610",label:"Objetos maquinados de plomo fundidos a la cera perdida"},
    {prodId:"31121611",label:"Objetos maquinados de metales preciosos fundidos a la cera perdida"},
    {prodId:"31121612",label:"Objetos maquinados de titanio fundidos a la cera perdida"},
    {prodId:"31121613",label:"Objetos maquinados compuestos fundidos a la cera perdida"},
    {prodId:"31121614",label:"Objetos maquinados de aleación de níquel fundidos a la cera perdida"},
    {prodId:"31121615",label:"Objetos maquinados no metálicos fundidos a la cera perdida"},
    {prodId:"31121616",label:"Ensamble de objetos maquinados aluminio fundidos a la cera perdida"},
    {prodId:"31121617",label:"Objetos maquinados de aleación de acero fundidos a la cera perdida"},
    {prodId:"31121618",label:"Ensamble de objetos maquinados aleación de acero fundidos a la cera perdida"},
    {prodId:"31121800",label:"Fundiciones labradas de molde de cerámica"},
    {prodId:"31121801",label:"Objetos maquinados de aleación no ferrosa fundidos en molde cerámico"},
    {prodId:"31121802",label:"Objetos maquinados de aleación ferrosa fundidos en molde cerámico"},
    {prodId:"31121803",label:"Objetos maquinados de acero fundidos en molde cerámico"},
    {prodId:"31121804",label:"Objetos maquinados de acero inoxidable fundidos en molde cerámico"},
    {prodId:"31121805",label:"Objetos maquinados de hierro fundidos en molde cerámico"},
    {prodId:"31121806",label:"Objetos maquinados de aluminio fundidos en molde cerámico"},
    {prodId:"31121807",label:"Objetos maquinados de magnesio fundidos en molde cerámico"},
    {prodId:"31121808",label:"Objetos maquinados de titanio fundidos en molde cerámico"},
    {prodId:"31121809",label:"Objetos maquinados de berilio fundidos en molde cerámico"},
    {prodId:"31121810",label:"Objetos maquinados de cobre fundidos en molde cerámico"},
    {prodId:"31121811",label:"Objetos maquinados de latón fundidos en molde cerámico"},
    {prodId:"31121812",label:"Objetos maquinados de bronce fundidos en molde cerámico"},
    {prodId:"31121813",label:"Objetos maquinados de cinc fundidos en molde cerámico"},
    {prodId:"31121814",label:"Objetos maquinados de estaño fundidos en molde cerámico"},
    {prodId:"31121815",label:"Objetos maquinados de plomo fundidos en molde cerámico"},
    {prodId:"31121816",label:"Objetos maquinados de metal precioso fundidos en molde cerámico"},
    {prodId:"31121817",label:"Objetos maquinados de compuestos fundidos en molde cerámico"},
    {prodId:"31121818",label:"Objetos maquinados de aleación de níquel fundidos en molde cerámico"},
    {prodId:"31121819",label:"Objetos maquinados no metálicos fundidos en molde cerámico"},
    {prodId:"31121900",label:"Fundiciones labradas de molde de grafito"},
    {prodId:"31121901",label:"Objetos maquinados de aleación no ferrosa fundidos en molde de grafito"},
    {prodId:"31121902",label:"Objetos maquinados de aleación ferrosa fundidos en molde de grafito"},
    {prodId:"31121903",label:"Objetos maquinados de acero fundidos en molde de grafito"},
    {prodId:"31121904",label:"Objetos maquinados de acero inoxidable fundidos en molde de grafito"},
    {prodId:"31121905",label:"Objetos maquinados de hierro fundidos en molde de grafito"},
    {prodId:"31121906",label:"Objetos maquinados de aluminio fundidos en molde de grafito"},
    {prodId:"31121907",label:"Objetos maquinados de magnesio fundidos en molde de grafito"},
    {prodId:"31121908",label:"Objetos maquinados de titanio fundidos en molde de grafito"},
    {prodId:"31121909",label:"Objetos maquinados de berilio fundidos en molde de grafito"},
    {prodId:"31121910",label:"Objetos maquinados de cobre fundidos en molde de grafito"},
    {prodId:"31121911",label:"Objetos maquinados de latón fundidos en molde de grafito"},
    {prodId:"31121912",label:"Objetos maquinados de bronce fundidos en molde de grafito"},
    {prodId:"31121913",label:"Objetos maquinados de cinc fundidos en molde de grafito"},
    {prodId:"31121914",label:"Objetos maquinados de estaño fundidos en molde de grafito"},
    {prodId:"31121915",label:"Objetos maquinados de plomo fundidos en molde de grafito"},
    {prodId:"31121916",label:"Objetos maquinados de metal precioso fundidos en molde de grafito"},
    {prodId:"31121917",label:"Objetos maquinados de compuestos fundidos en molde de grafito"},
    {prodId:"31121918",label:"Objetos maquinados de aleación de níquel fundidos en molde de grafito"},
    {prodId:"31121919",label:"Objetos maquinados no metálicos fundidos en molde de grafito"},
    {prodId:"31122000",label:"Fundiciones y ensambles de fundiciones maquinadas por apriete"},
    {prodId:"31122001",label:"Objetos maquinados de aluminio moldeados por apriete (squeeze)"},
    {prodId:"31122002",label:"Ensamble de objetos maquinados de aluminio moldeados por apriete (squeeze)"},
    {prodId:"31122100",label:"Fundiciones y ensambles de fundiciones maquinadas semi - sólidas"},
    {prodId:"31122101",label:"Objetos maquinados de aluminio moldeados semisólidos"},
    {prodId:"31122102",label:"Ensamble de objetos maquinados de aluminio moldeados semisólidos"},
    {prodId:"31122200",label:"Fundiciones y ensambles de fundiciones maquinadas por gravedad permanente"},
    {prodId:"31122201",label:"Objetos maquinados de aluminio moldeados por gravedad permanente"},
    {prodId:"31122202",label:"Ensamble de objetos maquinados de aluminio moldeados por gravedad permanente"},
    {prodId:"31132200",label:"Forjas de latón"},
    {prodId:"31132201",label:"Forja de latón maquinado forjado en frío"},
    {prodId:"31132202",label:"Forja de latón tratado con calor forjado en frío"},
    {prodId:"31132203",label:"Forja de latón maquinado y tratado con calor forjado en frío"},
    {prodId:"31132204",label:"Forja de latón tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31132205",label:"Forja de latón maquinado forjado en tibio"},
    {prodId:"31132206",label:"Forja de latón tratado con calor forjado en tibio"},
    {prodId:"31132207",label:"Forja de latón maquinado y tratado con calor forjado en tibio"},
    {prodId:"31132208",label:"Forja de latón tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31132400",label:"Forjas de aleaciones no ferrosas"},
    {prodId:"31132401",label:"Forja de aleación no ferrosa maquinado forjado en frío"},
    {prodId:"31132402",label:"Forja de aleación no ferrosa tratado con calor forjado en frío"},
    {prodId:"31132403",label:"Forja de aleación no ferrosa maquinado y tratado con calor forjado en frío"},
    {prodId:"31132404",label:"Forja de aleación no ferrosa tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31132405",label:"Forja de aleación no ferrosa maquinado forjado en tibio"},
    {prodId:"31132406",label:"Forja de aleación no ferrosa tratado con calor forjado en tibio"},
    {prodId:"31132407",label:"Forja de aleación no ferrosa maquinado y tratado con calor forjado en tibio"},
    {prodId:"31132408",label:"Forja de aleación no ferrosa tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31132409",label:"Forja de aleación no ferrosa maquinado forjado en caliente"},
    {prodId:"31132410",label:"Forja de aleación no ferrosa tratado con calor forjado en caliente"},
    {prodId:"31132411",label:"Forja de aleación no ferrosa maquinado y tratado con calor forjado en caliente"},
    {prodId:"31132412",label:"Forja de aleación no ferrosa tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31132500",label:"Forjas de aleaciones ferrosas"},
    {prodId:"31132501",label:"Forja de aleación ferrosa maquinado forjado en frío"},
    {prodId:"31132502",label:"Forja de aleación ferrosa tratado con calor forjado en frío"},
    {prodId:"31132503",label:"Forja de aleación ferrosa maquinado y tratado con calor forjado en frío"},
    {prodId:"31132504",label:"Forja de aleación ferrosa tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31132505",label:"Forja de aleación ferrosa maquinado forjado en tibio"},
    {prodId:"31132506",label:"Forja de aleación ferrosa tratado con calor forjado en tibio"},
    {prodId:"31132507",label:"Forja de aleación ferrosa maquinado y tratado con calor forjado en tibio"},
    {prodId:"31132508",label:"Forja de aleación ferrosa tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31132509",label:"Forja de aleación ferrosa maquinado forjado en caliente"},
    {prodId:"31132510",label:"Forja de aleación ferrosa tratado con calor forjado en caliente"},
    {prodId:"31132511",label:"Forja de aleación ferrosa maquinado y tratado con calor forjado en caliente"},
    {prodId:"31132512",label:"Forja de aleación ferrosa tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31132600",label:"Forjas de acero inoxidable"},
    {prodId:"31132601",label:"Forja de acero inoxidable maquinado forjado en frío"},
    {prodId:"31132602",label:"Forja de acero inoxidable tratado con calor forjado en frío"},
    {prodId:"31132603",label:"Forja de acero inoxidable maquinado y tratado con calor forjado en frío"},
    {prodId:"31132604",label:"Forja de acero inoxidable tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31132605",label:"Forja de acero inoxidable maquinado forjado en tibio"},
    {prodId:"31132606",label:"Forja de acero inoxidable tratado con calor forjado en tibio"},
    {prodId:"31132607",label:"Forja de acero inoxidable maquinado y tratado con calor forjado en tibio"},
    {prodId:"31132608",label:"Forja de acero inoxidable tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31132609",label:"Forja de acero inoxidable maquinado forjado en caliente"},
    {prodId:"31132610",label:"Forja de acero inoxidable tratado con calor forjado en caliente"},
    {prodId:"31132611",label:"Forja de acero inoxidable maquinado y tratado con calor forjado en caliente"},
    {prodId:"31132612",label:"Forja de acero inoxidable tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31132800",label:"Forjas de magnesio"},
    {prodId:"31132801",label:"Forja de magnesio maquinado forjado en frío"},
    {prodId:"31132802",label:"Forja de magnesio tratado con calor forjado en frío"},
    {prodId:"31132803",label:"Forja de magnesio maquinado y tratado con calor forjado en frío"},
    {prodId:"31132804",label:"Forja de magnesio tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31132805",label:"Forja de magnesio maquinado forjado en tibio"},
    {prodId:"31132806",label:"Forja de magnesio tratado con calor forjado en tibio"},
    {prodId:"31132807",label:"Forja de magnesio maquinado y tratado con calor forjado en tibio"},
    {prodId:"31132808",label:"Forja de magnesio tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31132809",label:"Forja de magnesio maquinado forjado en caliente"},
    {prodId:"31132810",label:"Forja de magnesio tratado con calor forjado en caliente"},
    {prodId:"31132811",label:"Forja de magnesio maquinado y tratado con calor forjado en caliente"},
    {prodId:"31132812",label:"Forja de magnesio tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31133000",label:"Forjas de berilio"},
    {prodId:"31133001",label:"Forja de berilio maquinado forjado en frío"},
    {prodId:"31133002",label:"Forja de berilio tratado con calor forjado en frío"},
    {prodId:"31133003",label:"Forja de berilio maquinado y tratado con calor forjado en frío"},
    {prodId:"31133004",label:"Forja de berilio tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31133005",label:"Forja de berilio maquinado forjado en tibio"},
    {prodId:"31133006",label:"Forja de berilio tratado con calor forjado en tibio"},
    {prodId:"31133007",label:"Forja de berilio maquinado y tratado con calor forjado en tibio"},
    {prodId:"31133008",label:"Forja de berilio tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31133009",label:"Forja de berilio maquinado forjado en caliente"},
    {prodId:"31133010",label:"Forja de berilio tratado con calor forjado en caliente"},
    {prodId:"31133011",label:"Forja de berilio maquinado y tratado con calor forjado en caliente"},
    {prodId:"31133012",label:"Forja de berilio tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31133300",label:"Forjas de bronce"},
    {prodId:"31133301",label:"Forja de bronce maquinado forjado en frío"},
    {prodId:"31133302",label:"Forja de bronce tratado con calor forjado en frío"},
    {prodId:"31133303",label:"Forja de bronce maquinado y tratado con calor forjado en frío"},
    {prodId:"31133304",label:"Forja de bronce tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31133305",label:"Forja de bronce maquinado forjado en tibio"},
    {prodId:"31133306",label:"Forja de bronce tratado con calor forjado en tibio"},
    {prodId:"31133307",label:"Forja de bronce maquinado y tratado con calor forjado en tibio"},
    {prodId:"31133308",label:"Forja de bronce tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31133309",label:"Forja de bronce maquinado forjado en caliente"},
    {prodId:"31133310",label:"Forja de bronce tratado con calor forjado en caliente"},
    {prodId:"31133311",label:"Forja de bronce maquinado y tratado con calor forjado en caliente"},
    {prodId:"31133312",label:"Forja de bronce tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31133400",label:"Forjas de estaño"},
    {prodId:"31133401",label:"Forja de estaño maquinado forjado en frío"},
    {prodId:"31133402",label:"Forja de estaño tratado con calor forjado en frío"},
    {prodId:"31133403",label:"Forja de estaño maquinado y tratado con calor forjado en frío"},
    {prodId:"31133404",label:"Forja de estaño tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31133405",label:"Forja de estaño maquinado forjado en tibio"},
    {prodId:"31133406",label:"Forja de estaño tratado con calor forjado en tibio"},
    {prodId:"31133407",label:"Forja de estaño maquinado y tratado con calor forjado en tibio"},
    {prodId:"31133408",label:"Forja de estaño tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31133409",label:"Forja de estaño maquinado forjado en caliente"},
    {prodId:"31133410",label:"Forja de estaño tratado con calor forjado en caliente"},
    {prodId:"31133411",label:"Forja de estaño maquinado y tratado con calor forjado en caliente"},
    {prodId:"31133412",label:"Forja de estaño tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31133600",label:"Forjas de metales preciosos"},
    {prodId:"31133601",label:"Forja de metal precioso maquinado forjado en frío"},
    {prodId:"31133602",label:"Forja de metal precioso tratado con calor forjado en frío"},
    {prodId:"31133603",label:"Forja de metal precioso maquinado y tratado con calor forjado en frío"},
    {prodId:"31133604",label:"Forja de metal precioso tratado con calor y dimensionado en frío forjado en frío"},
    {prodId:"31133605",label:"Forja de metal precioso maquinado forjado en tibio"},
    {prodId:"31133606",label:"Forja de metal precioso tratado con calor forjado en tibio"},
    {prodId:"31133607",label:"Forja de metal precioso maquinado y tratado con calor forjado en tibio"},
    {prodId:"31133608",label:"Forja de metal precioso tratado con calor y dimensionado en frío forjado en tibio"},
    {prodId:"31133609",label:"Forja de metal precioso maquinado forjado en caliente"},
    {prodId:"31133610",label:"Forja de metal precioso tratado con calor forjado en caliente"},
    {prodId:"31133611",label:"Forja de metal precioso maquinado y tratado con calor forjado en caliente"},
    {prodId:"31133612",label:"Forja de metal precioso tratado con calor y dimensionado en frío forjado en caliente"},
    {prodId:"31142100",label:"Engastes de moldeado termoplástico"},
    {prodId:"31142101",label:"Engaste de moldeado termoplástico por compresión"},
    {prodId:"31142102",label:"Engaste de moldeado termoplástico por inmersión"},
    {prodId:"31142103",label:"Engaste de moldeado termoplástico por aire"},
    {prodId:"31142104",label:"Engaste de moldeado termoplástico por inyección"},
    {prodId:"31142105",label:"Engaste de moldeado termoplástico por inyección asistida por gas"},
    {prodId:"31142106",label:"Engaste de moldeado termoplástico por inyección de alta precisión"},
    {prodId:"31142107",label:"Engaste de moldeado termoplástico por transferencia"},
    {prodId:"31142200",label:"Ensambles de engastes termoplástico moldeado por disparo múltiple"},
    {prodId:"31142201",label:"Ensamble de engaste termoplástico moldeado por disparo múltiple por compresión"},
    {prodId:"31142202",label:"Ensamble de engaste termoplástico moldeado por disparo múltiple por inmersión"},
    {prodId:"31142203",label:"Ensamble de engaste termoplástico moldeado por disparo múltiple por aire"},
    {prodId:"31142204",label:"Ensamble de engaste termoplástico moldeado por disparo múltiple por inyección"},
    {prodId:"31142205",label:"Ensamble de engaste termoplástico moldeado por disparo múltiple por inyección asistida por gas"},
    {prodId:"31142206",label:"Ensamble de engaste termoplástico moldeado por disparo múltiple por inyección de alta precisión"},
    {prodId:"31142207",label:"Ensamble de engaste termoplástico moldeado por disparo múltiple por transferencia"},
    {prodId:"31142300",label:"Ensambles de engastes termoplástico moldeado por inyección"},
    {prodId:"31142301",label:"Ensamble de engaste termoplástico moldeado por compresión"},
    {prodId:"31142302",label:"Ensamble de engaste termoplástico moldeado por inmersión"},
    {prodId:"31142303",label:"Ensamble de engaste termoplástico moldeado por aire"},
    {prodId:"31142304",label:"Ensamble de engaste termoplástico moldeado por inyección"},
    {prodId:"31142305",label:"Ensamble de engaste termoplástico moldeado por inyección asistida por gas"},
    {prodId:"31142306",label:"Ensamble de engaste termoplástico moldeado por inyección de alta precisión"},
    {prodId:"31142307",label:"Ensamble de engaste termoplástico moldeado por transferencia"},
    {prodId:"31142400",label:"Ensambles de moldeado termofraguado"},
    {prodId:"31142401",label:"Ensamble de moldeado termofraguado por compresión"},
    {prodId:"31142402",label:"Ensamble de moldeado termofraguado por inmersión"},
    {prodId:"31142403",label:"Ensamble de moldeado termofraguado por aire"},
    {prodId:"31142404",label:"Ensamble de moldeado termofraguado por inyección"},
    {prodId:"31142405",label:"Ensamble de moldeado termofraguado por inyección asistida por gas"},
    {prodId:"31142406",label:"Ensamble de moldeado termofraguado por inyección de alta precisión"},
    {prodId:"31142407",label:"Ensamble de moldeado termofraguado por transferencia"},
    {prodId:"31142500",label:"Engastes de moldeado termofraguado"},
    {prodId:"31142501",label:"Engaste de moldeado termofraguado por compresión"},
    {prodId:"31142502",label:"Engaste de moldeado termofraguado por inmersión"},
    {prodId:"31142503",label:"Engaste de moldeado termofraguado por aire"},
    {prodId:"31142504",label:"Engaste de moldeado termofraguado por inyección"},
    {prodId:"31142505",label:"Engaste de moldeado termofraguado por inyección asistida por gas"},
    {prodId:"31142506",label:"Engaste de moldeado termofraguado por inyección de alta precisión"},
    {prodId:"31142507",label:"Engaste de moldeado termofraguado por transferencia"},
    {prodId:"31142600",label:"Ensambles de engaste termofraguado moldeado por disparo múltiple"},
    {prodId:"31142601",label:"Ensamble de engaste termofraguado moldeado por disparo múltiple por compresión"},
    {prodId:"31142602",label:"Ensamble de engaste termofraguado moldeado por disparo múltiple por inmersión"},
    {prodId:"31142603",label:"Ensamble de engaste termofraguado moldeado por disparo múltiple por aire"},
    {prodId:"31142604",label:"Ensamble de engaste termofraguado moldeado por disparo múltiple por inyección"},
    {prodId:"31142605",label:"Ensamble de engaste termofraguado moldeado por disparo múltiple por inyección asistida por gas"},
    {prodId:"31142606",label:"Ensamble de engaste termofraguado moldeado por disparo múltiple por inyección de alta precisión"},
    {prodId:"31142607",label:"Ensamble de engaste termofraguado moldeado por disparo múltiple por transferencia"},
    {prodId:"31142700",label:"Ensambles de engaste termofraguado moldeado"},
    {prodId:"31142701",label:"Ensamble de engaste termofraguado moldeado por compresión"},
    {prodId:"31142702",label:"Ensamble de engaste termofraguado moldeado por inmersión"},
    {prodId:"31142703",label:"Ensamble de engaste termofraguado moldeado por aire"},
    {prodId:"31142704",label:"Ensamble de engaste termofraguado moldeado por inyección"},
    {prodId:"31142705",label:"Ensamble de engaste termofraguado moldeado por inyección asistida por gas"},
    {prodId:"31142706",label:"Ensamble de engaste termofraguado moldeado por inyección de alta precisión"},
    {prodId:"31142707",label:"Ensamble de engaste termofraguado moldeado por transferencia"},
    {prodId:"31143000",label:"Moldeados asistidos por gas"},
    {prodId:"31143001",label:"Moldeado de plástico asistido por gas"},
    {prodId:"31143002",label:"Moldeado de espuma estructural asistido por gas"},
    {prodId:"31143200",label:"Moldeados decorados por inyección post moldeado"},
    {prodId:"31143201",label:"Moldeado por inyección pintado"},
    {prodId:"31143202",label:"Moldeado por inyección plateado"},
    {prodId:"31143203",label:"Moldeado por inyección metalizado"},
    {prodId:"31143204",label:"Moldeado por inyección tratado con láser"},
    {prodId:"31143205",label:"Moldeado por inyección pintado y tratado con láser"},
    {prodId:"31191603",label:"Ruedas abrasivas de carburo de tungsteno"},
    {prodId:"31241900",label:"Bóvedas ópticas"},
    {prodId:"31241901",label:"Bóvedas de especialidad"},
    {prodId:"31241902",label:"Bóvedas torneadas con diamante"},
    {prodId:"31241903",label:"Bóvedas metálicas"},
    {prodId:"31241904",label:"Bóvedas de vidrio moldeado"},
    {prodId:"31241905",label:"Bóvedas moldeadas del policarbonato"},
    {prodId:"31241906",label:"Bóvedas reproducidas"},
    {prodId:"31241907",label:"Bóvedas formadas"},
    {prodId:"31241908",label:"Bóvedas frangibles"},
    {prodId:"31242000",label:"Ventanas de láser y lentes"},
    {prodId:"31242001",label:"Ventana externa de láser o lentes"},
    {prodId:"31242002",label:"Ventanas de lentes infrarrojos o de láser"},
    {prodId:"31242003",label:"Ventana visual de láser o lentes"},
    {prodId:"31281900",label:"Componentes formados por estiraje"},
    {prodId:"31281901",label:"Componentes de aluminio formados por estiramiento"},
    {prodId:"31281902",label:"Componentes de berilio formados por estiramiento"},
    {prodId:"31281903",label:"Componentes de latón formados por estiramiento"},
    {prodId:"31281904",label:"Componentes de bronce formados por estiramiento"},
    {prodId:"31281905",label:"Componentes compuestos formados por estiramiento"},
    {prodId:"31281906",label:"Componentes de cobre formados por estiramiento"},
    {prodId:"31281907",label:"Componentes de aleación ferrosa formados por estiramiento"},
    {prodId:"31281908",label:"Componentes de hierro formados por estiramiento"},
    {prodId:"31281909",label:"Componentes de plomo formados por estiramiento"},
    {prodId:"31281910",label:"Componentes de magnesio formados por estiramiento"},
    {prodId:"31281911",label:"Componentes de aleación de níquel formados por estiramiento"},
    {prodId:"31281912",label:"Componentes de aleación no ferrosa formados por estiramiento"},
    {prodId:"31281913",label:"Componentes no metálicos formados por estiramiento"},
    {prodId:"31281914",label:"Componentes de metal precioso formados por estiramiento"},
    {prodId:"31281915",label:"Componentes de acero inoxidable formados por estiramiento"},
    {prodId:"31281916",label:"Componentes de acero formados por estiramiento"},
    {prodId:"31281917",label:"Componentes de estaño formados por estiramiento"},
    {prodId:"31281918",label:"Componentes de titanio formados por estiramiento"},
    {prodId:"31281919",label:"Componentes de zinc formados por estiramiento"},
    {prodId:"31282400",label:"Componentes formados de explosivos"},
    {prodId:"31282401",label:"Componentes de aluminio formados con explosivos"},
    {prodId:"31282402",label:"Componentes de berilio formados con explosivos"},
    {prodId:"31282403",label:"Componentes de latón formados con explosivos"},
    {prodId:"31282404",label:"Componentes de bronce formados con explosivos"},
    {prodId:"31282405",label:"Componentes compuestos formados con explosivos"},
    {prodId:"31282406",label:"Componentes de cobre formados con explosivos"},
    {prodId:"31282407",label:"Componentes de aleación ferrosa formados con explosivos"},
    {prodId:"31282408",label:"Componentes de hierro formados con explosivos"},
    {prodId:"31282409",label:"Componentes de plomo formados con explosivos"},
    {prodId:"31282410",label:"Componentes de magnesio formados con explosivos"},
    {prodId:"31282411",label:"Componentes de aleación de níquel formados con explosivos"},
    {prodId:"31282412",label:"Componentes de aleación no ferrosa formados con explosivos"},
    {prodId:"31282413",label:"Componentes no metálicos formados con explosivos"},
    {prodId:"31282414",label:"Componentes de metal precioso formados con explosivos"},
    {prodId:"31282415",label:"Componentes de acero inoxidable formados con explosivos"},
    {prodId:"31282416",label:"Componentes de acero formados con explosivos"},
    {prodId:"31282417",label:"Componentes de estaño formados con explosivos"},
    {prodId:"31282418",label:"Componentes de titanio formados con explosivos"},
    {prodId:"31282419",label:"Componentes de zinc formados con explosivos"},
    {prodId:"31291100",label:"Estiramientos por presión hidrostática labrados"},
    {prodId:"31291101",label:"Componentes de aluminio maquinados por extrusión hidrostática"},
    {prodId:"31291102",label:"Componentes de berilio maquinados por extrusión hidrostática"},
    {prodId:"31291103",label:"Componentes de latón maquinados por extrusión hidrostática"},
    {prodId:"31291104",label:"Componentes de bronce maquinados por extrusión hidrostática"},
    {prodId:"31291105",label:"Componentes de cobre maquinados por extrusión hidrostática"},
    {prodId:"31291106",label:"Componentes de aleación ferrosa maquinados por extrusión hidrostática"},
    {prodId:"31291107",label:"Componentes de plomo maquinados por extrusión hidrostática"},
    {prodId:"31291108",label:"Componentes de magnesio maquinados por extrusión hidrostática"},
    {prodId:"31291109",label:"Componentes de aleación no ferrosa maquinados por extrusión hidrostática"},
    {prodId:"31291110",label:"Componentes plásticos maquinados por extrusión hidrostática"},
    {prodId:"31291111",label:"Componentes de metal precioso maquinados por extrusión hidrostática"},
    {prodId:"31291112",label:"Componentes de caucho maquinados por extrusión hidrostática"},
    {prodId:"31291113",label:"Componentes de acero inoxidable maquinados por extrusión hidrostática"},
    {prodId:"31291114",label:"Componentes de acero maquinados por extrusión hidrostática"},
    {prodId:"31291115",label:"Componentes de estaño maquinados por extrusión hidrostática"},
    {prodId:"31291116",label:"Componentes de titanio maquinados por extrusión hidrostática"},
    {prodId:"31291117",label:"Componentes de zinc maquinados por extrusión hidrostática"},
    {prodId:"31291118",label:"Componentes compuestos maquinados por extrusión hidrostática"},
    {prodId:"31291119",label:"Componentes de aleación de níquel maquinados por extrusión hidrostática"},
    {prodId:"31291120",label:"Componentes no metálicos maquinados por extrusión hidrostática"},
    {prodId:"31291300",label:"Estiramientos por presión en frío labrados"},
    {prodId:"31291301",label:"Componentes de aluminio maquinados por extrusión en frío"},
    {prodId:"31291302",label:"Componentes de berilio maquinados por extrusión en frío"},
    {prodId:"31291303",label:"Componentes de latón maquinados por extrusión en frío"},
    {prodId:"31291304",label:"Componentes de bronce maquinados por extrusión en frío"},
    {prodId:"31291305",label:"Componentes de cobre maquinados por extrusión en frío"},
    {prodId:"31291306",label:"Componentes de aleación ferrosa maquinados por extrusión en frío"},
    {prodId:"31291307",label:"Componentes de plomo maquinados por extrusión en frío"},
    {prodId:"31291308",label:"Componentes de magnesio maquinados por extrusión en frío"},
    {prodId:"31291309",label:"Componentes de aleación no ferrosa maquinados por extrusión en frío"},
    {prodId:"31291310",label:"Componentes plásticos maquinados por extrusión en frío"},
    {prodId:"31291311",label:"Componentes de metal precioso maquinados por extrusión en frío"},
    {prodId:"31291312",label:"Componentes de caucho maquinados por extrusión en frío"},
    {prodId:"31291313",label:"Componentes de acero inoxidable maquinados por extrusión en frío"},
    {prodId:"31291314",label:"Componentes de acero maquinados por extrusión en frío"},
    {prodId:"31291315",label:"Componentes de estaño maquinados por extrusión en frío"},
    {prodId:"31291316",label:"Componentes de titanio maquinados por extrusión en frío"},
    {prodId:"31291317",label:"Componentes de zinc maquinados por extrusión en frío"},
    {prodId:"31291318",label:"Componentes compuestos maquinados por extrusión en frío"},
    {prodId:"31291319",label:"Componentes de aleación de níquel maquinados por extrusión en frío"},
    {prodId:"31291320",label:"Componentes no metálicos maquinados por extrusión en frío"},
    {prodId:"31301100",label:"Forjas labradas con troquel abierto"},
    {prodId:"31301101",label:"Forjas de aleación no ferrosa maquinadas con troquel abierto"},
    {prodId:"31301102",label:"Forjas de aleación ferrosa maquinadas con troquel abierto"},
    {prodId:"31301103",label:"Forjas de acero maquinadas con troquel abierto"},
    {prodId:"31301104",label:"Forjas de acero inoxidable maquinadas con troquel abierto"},
    {prodId:"31301105",label:"Forjas de hierro maquinadas con troquel abierto"},
    {prodId:"31301106",label:"Forjas de aluminio maquinadas con troquel abierto"},
    {prodId:"31301107",label:"Forjas de magnesio maquinadas con troquel abierto"},
    {prodId:"31301108",label:"Forjas de titanio maquinadas con troquel abierto"},
    {prodId:"31301109",label:"Forjas de berilio maquinadas con troquel abierto"},
    {prodId:"31301110",label:"Forjas de cobre maquinadas con troquel abierto"},
    {prodId:"31301111",label:"Forjas de latón maquinadas con troquel abierto"},
    {prodId:"31301112",label:"Forjas de bronce maquinadas con troquel abierto"},
    {prodId:"31301113",label:"Forjas de zinc maquinadas con troquel abierto"},
    {prodId:"31301114",label:"Forjas de estaño maquinadas con troquel abierto"},
    {prodId:"31301115",label:"Forjas de plomo maquinadas con troquel abierto"},
    {prodId:"31301116",label:"Forjas de metales preciosos maquinadas con troquel abierto"},
    {prodId:"31301117",label:"Forjas compuestas maquinadas con troquel abierto"},
    {prodId:"31301118",label:"Forjas de aleación de níquel maquinadas con troquel abierto"},
    {prodId:"31301119",label:"Forjas no metálicas maquinadas con troquel abierto"},
    {prodId:"31301200",label:"Forjas labradas con troquel cerrado"},
    {prodId:"31301201",label:"Forjas de aleación no ferrosa maquinadas con troquel cerrado"},
    {prodId:"31301202",label:"Forjas de aleación ferrosa maquinadas con troquel cerrado"},
    {prodId:"31301203",label:"Forjas de acero maquinadas con troquel cerrado"},
    {prodId:"31301204",label:"Forjas de acero inoxidable maquinadas con troquel cerrado"},
    {prodId:"31301205",label:"Forjas de hierro maquinadas con troquel cerrado"},
    {prodId:"31301206",label:"Forjas de aluminio maquinadas con troquel cerrado"},
    {prodId:"31301207",label:"Forjas de magnesio maquinadas con troquel cerrado"},
    {prodId:"31301208",label:"Forjas de titanio maquinadas con troquel cerrado"},
    {prodId:"31301209",label:"Forjas de berilio maquinadas con troquel cerrado"},
    {prodId:"31301210",label:"Forjas de cobre maquinadas con troquel cerrado"},
    {prodId:"31301211",label:"Forjas de latón maquinadas con troquel cerrado"},
    {prodId:"31301212",label:"Forjas de bronce maquinadas con troquel cerrado"},
    {prodId:"31301213",label:"Forjas de zinc maquinadas con troquel cerrado"},
    {prodId:"31301214",label:"Forjas de estaño maquinadas con troquel cerrado"},
    {prodId:"31301215",label:"Forjas de plomo maquinadas con troquel cerrado"},
    {prodId:"31301216",label:"Forjas de metales preciosos maquinadas con troquel cerrado"},
    {prodId:"31301217",label:"Forjas compuestas maquinadas con troquel cerrado"},
    {prodId:"31301218",label:"Forjas de aleación de níquel maquinadas con troquel cerrado"},
    {prodId:"31301219",label:"Forjas no metálicas maquinadas con troquel cerrado"},
    {prodId:"31301300",label:"Forjas labradas de troquel de impresión"},
    {prodId:"31301301",label:"Forjas de aleación no ferrosa maquinadas con impresión por troquel"},
    {prodId:"31301302",label:"Forjas de aleación ferrosa maquinadas con impresión por troquel"},
    {prodId:"31301303",label:"Forjas de acero maquinadas con impresión por troquel"},
    {prodId:"31301304",label:"Forjas de acero inoxidable maquinadas con impresión por troquel"},
    {prodId:"31301305",label:"Forjas de hierro maquinadas con impresión por troquel"},
    {prodId:"31301306",label:"Forjas de aluminio maquinadas con impresión por troquel"},
    {prodId:"31301307",label:"Forjas de magnesio maquinadas con impresión por troquel"},
    {prodId:"31301308",label:"Forjas de titanio maquinadas con impresión por troquel"},
    {prodId:"31301309",label:"Forjas de berilio maquinadas con impresión por troquel"},
    {prodId:"31301310",label:"Forjas de cobre maquinadas con impresión por troquel"},
    {prodId:"31301311",label:"Forjas de latón maquinadas con impresión por troquel"},
    {prodId:"31301312",label:"Forjas de bronce maquinadas con impresión por troquel"},
    {prodId:"31301313",label:"Forjas de zinc maquinadas con impresión por troquel"},
    {prodId:"31301314",label:"Forjas de estaño maquinadas con impresión por troquel"},
    {prodId:"31301315",label:"Forjas de plomo maquinadas con impresión por troquel"},
    {prodId:"31301316",label:"Forjas de metales preciosos maquinadas con impresión por troquel"},
    {prodId:"31301317",label:"Forjas compuestas maquinadas con impresión por troquel"},
    {prodId:"31301318",label:"Forjas de aleación de níquel maquinadas con impresión por troquel"},
    {prodId:"31301319",label:"Forjas no metálicas maquinadas con impresión por troquel"},
    {prodId:"31301400",label:"Forjas labradas por reducción"},
    {prodId:"31301401",label:"Forjas de aleación no ferrosa maquinadas por reducción"},
    {prodId:"31301402",label:"Forjas de aleación ferrosa maquinadas por reducción"},
    {prodId:"31301403",label:"Forjas de acero maquinadas por reducción"},
    {prodId:"31301404",label:"Forjas de acero inoxidable maquinadas por reducción"},
    {prodId:"31301405",label:"Forjas de hierro maquinadas por reducción"},
    {prodId:"31301406",label:"Forjas de aluminio maquinadas por reducción"},
    {prodId:"31301407",label:"Forjas de magnesio maquinadas por reducción"},
    {prodId:"31301408",label:"Forjas de titanio maquinadas por reducción"},
    {prodId:"31301409",label:"Forjas de berilio maquinadas por reducción"},
    {prodId:"31301410",label:"Forjas de cobre maquinadas por reducción"},
    {prodId:"31301411",label:"Forjas de latón maquinadas por reducción"},
    {prodId:"31301412",label:"Forjas de bronce maquinadas por reducción"},
    {prodId:"31301413",label:"Forjas de zinc maquinadas por reducción"},
    {prodId:"31301414",label:"Forjas de estaño maquinadas por reducción"},
    {prodId:"31301415",label:"Forjas de plomo maquinadas por reducción"},
    {prodId:"31301416",label:"Forjas de metales preciosos maquinadas por reducción"},
    {prodId:"31301417",label:"Forjas compuestas maquinadas por reducción"},
    {prodId:"31301418",label:"Forjas de aleación de níquel maquinadas por reducción"},
    {prodId:"31301419",label:"Forjas no metálicas maquinadas por reducción"},
    {prodId:"31301500",label:"Forjas labradas de anillo cilindrado"},
    {prodId:"31301501",label:"Forjas de aluminio maquinadas por anillo enrollado"},
    {prodId:"31301502",label:"Forjas de berilio maquinadas por anillo enrollado"},
    {prodId:"31301503",label:"Forjas de latón maquinadas por anillo enrollado"},
    {prodId:"31301504",label:"Forjas de bronce maquinadas por anillo enrollado"},
    {prodId:"31301505",label:"Forjas de cobre maquinadas por anillo enrollado"},
    {prodId:"31301506",label:"Forjas de hierro maquinadas por anillo enrollado"},
    {prodId:"31301507",label:"Forjas de plomo maquinadas por anillo enrollado"},
    {prodId:"31301508",label:"Forjas de magnesio maquinadas por anillo enrollado"},
    {prodId:"31301509",label:"Forjas de metal precioso maquinadas por anillo enrollado"},
    {prodId:"31301510",label:"Forjas de acero inoxidable maquinadas por anillo enrollado"},
    {prodId:"31301511",label:"Forjas de estaño maquinadas por anillo enrollado"},
    {prodId:"31301512",label:"Forjas de titanio maquinadas por anillo enrollado"},
    {prodId:"31301513",label:"Forjas de zinc maquinadas por anillo enrollado"},
    {prodId:"31301514",label:"Forjas de aleación no ferrosa maquinadas por anillo enrollado"},
    {prodId:"31301515",label:"Forjas de aleación ferrosa maquinadas por anillo enrollado"},
    {prodId:"31301516",label:"Forjas de acero maquinadas por anillo enrollado"},
    {prodId:"31301517",label:"Forjas compuestas maquinadas por anillo enrollado"},
    {prodId:"31301518",label:"Forjas de aleación de níquel maquinadas por anillo enrollado"},
    {prodId:"31301519",label:"Forjas no metálicos maquinadas por anillo enrollado"},
    {prodId:"31311100",label:"Conjuntos de tubería soldada de solvente"},
    {prodId:"31311101",label:"Ensambles de tubería soldada de solvente de aluminio"},
    {prodId:"31311102",label:"Ensambles de tubería soldada de solvente de acero de carbono"},
    {prodId:"31311103",label:"Ensambles de tubería soldada de solvente de aleación hast x"},
    {prodId:"31311104",label:"Ensambles de tubería soldada de solvente de inconel"},
    {prodId:"31311105",label:"Ensambles de tubería soldada de solvente de acero de aleación baja"},
    {prodId:"31311106",label:"Ensambles de tubería soldada de solvente no metálico"},
    {prodId:"31311109",label:"Ensambles de tubería soldada de solvente de acero inoxidable"},
    {prodId:"31311110",label:"Ensambles de tubería soldada de solvente de titanio"},
    {prodId:"31311111",label:"Ensambles de tubería soldada de solvente de aleación wasp"},
    {prodId:"31311112",label:"Ensambles de tubería soldada de solvente de cobre"},
    {prodId:"31311113",label:"Ensambles de tubería soldada de solvente de latón"},
    {prodId:"31311200",label:"Conjuntos de tubería remachada"},
    {prodId:"31311201",label:"Ensambles de tubería remachada de aluminio"},
    {prodId:"31311202",label:"Ensambles de tubería remachada de acero al carbono"},
    {prodId:"31311203",label:"Ensambles de tubería remachada de aleación hast x"},
    {prodId:"31311204",label:"Ensambles de tubería remachada de inconel"},
    {prodId:"31311205",label:"Ensambles de tubería remachada de acero de aleación baja"},
    {prodId:"31311206",label:"Ensambles de tubería remachada no metálica"},
    {prodId:"31311209",label:"Ensambles de tubería remachada de acero inoxidable"},
    {prodId:"31311210",label:"Ensambles de tubería remachada de titanio"},
    {prodId:"31311211",label:"Ensambles de tubería remachada de aleación wasp"},
    {prodId:"31311212",label:"Ensambles de tubería remachada de cobre"},
    {prodId:"31311213",label:"Ensambles de tubería remachada de latón"},
    {prodId:"31311300",label:"Conjuntos empernados de tubería"},
    {prodId:"31311301",label:"Ensambles de tubería atornillada de aluminio"},
    {prodId:"31311302",label:"Ensambles de tubería atornillada de acero al carbono"},
    {prodId:"31311303",label:"Ensambles de tubería atornillada de aleación hast x"},
    {prodId:"31311304",label:"Ensambles de tubería atornillada de inconel"},
    {prodId:"31311305",label:"Ensambles de tubería atornillada de acero de aleación baja"},
    {prodId:"31311306",label:"Ensambles de tubería atornillada no metálica"},
    {prodId:"31311309",label:"Ensambles de tubería atornillada de acero inoxidable"},
    {prodId:"31311310",label:"Ensambles de tubería atornillada de titanio"},
    {prodId:"31311311",label:"Ensambles de tubería atornillada de aleación wasp"},
    {prodId:"31311312",label:"Ensambles de tubería atornillada de cobre"},
    {prodId:"31311313",label:"Ensambles de tubería atornillada de latón"},
    {prodId:"31311400",label:"Conjuntos de tuberías soldadas con ultra violeta UV"},
    {prodId:"31311401",label:"Ensambles de tubería soldada con ultra violeta de aluminio"},
    {prodId:"31311402",label:"Ensambles de tubería soldada con ultra violeta de acero al carbono"},
    {prodId:"31311403",label:"Ensambles de tubería soldada con ultra violeta de aleación hast x"},
    {prodId:"31311404",label:"Ensambles de tubería soldada con ultra violeta de inconel"},
    {prodId:"31311405",label:"Ensambles de tubería soldada con ultra violeta de acero de aleación baja"},
    {prodId:"31311406",label:"Ensambles de tubería soldada con ultra violeta no metálica"},
    {prodId:"31311409",label:"Ensambles de tubería soldada con ultra violeta de acero inoxidable"},
    {prodId:"31311410",label:"Ensambles de tubería soldada con ultra violeta de titanio"},
    {prodId:"31311411",label:"Ensambles de tubería soldada con ultra violeta de aleación wasp"},
    {prodId:"31311412",label:"Ensambles de tubería soldada con ultra violeta de cobre"},
    {prodId:"31311413",label:"Ensambles de tubería soldada con ultra violeta de latón"},
    {prodId:"31311500",label:"Conjuntos de tubería soldado o soldado con latón"},
    {prodId:"31311501",label:"Ensambles de tubería con soldadura fuerte o débil de aluminio"},
    {prodId:"31311502",label:"Ensambles de tubería con soldadura fuerte o débil de acero al carbono"},
    {prodId:"31311503",label:"Ensambles de tubería con soldadura fuerte o débil de aleación hast x"},
    {prodId:"31311504",label:"Ensambles de tubería con soldadura fuerte o débil de inconel"},
    {prodId:"31311505",label:"Ensambles de tubería con soldadura fuerte o débil de acero de aleación baja"},
    {prodId:"31311506",label:"Ensambles de tubería con soldadura fuerte o débil no metálica"},
    {prodId:"31311509",label:"Ensambles de tubería con soldadura fuerte o débil de acero inoxidable"},
    {prodId:"31311510",label:"Ensambles de tubería con soldadura fuerte o débil de titanio"},
    {prodId:"31311511",label:"Ensambles de tubería con soldadura fuerte o débil de aleación wasp"},
    {prodId:"31311512",label:"Ensambles de tubería con soldadura fuerte o débil de cobre"},
    {prodId:"31311513",label:"Ensambles de tubería con soldadura fuerte o débil de latón"},
    {prodId:"31311600",label:"Conjuntos de tubería soldada sónica"},
    {prodId:"31311601",label:"Ensambles de tubería con soldadura sónica de aluminio"},
    {prodId:"31311602",label:"Ensambles de tubería con soldadura sónica de acero al carbono"},
    {prodId:"31311603",label:"Ensambles de tubería con soldadura sónica de aleación hast x"},
    {prodId:"31311604",label:"Ensambles de tubería con soldadura sónica de inconel"},
    {prodId:"31311605",label:"Ensambles de tubería con soldadura sónica de acero de aleación baja"},
    {prodId:"31311606",label:"Ensambles de tubería con soldadura sónica no metálica"},
    {prodId:"31311609",label:"Ensambles de tubería con soldadura sónica de acero inoxidable"},
    {prodId:"31311610",label:"Ensambles de tubería con soldadura sónica de titanio"},
    {prodId:"31311611",label:"Ensambles de tubería con soldadura sónica de aleación wasp"},
    {prodId:"31311612",label:"Ensambles de tubería con soldadura sónica de cobre"},
    {prodId:"31311613",label:"Ensambles de tubería con soldadura sónica de latón"},
    {prodId:"31311700",label:"Conjuntos de tubería pegada"},
    {prodId:"31311701",label:"Ensambles de tubería pegada de aluminio"},
    {prodId:"31311702",label:"Ensambles de tubería pegada de acero al carbono"},
    {prodId:"31311703",label:"Ensambles de tubería pegada de aleación hast x"},
    {prodId:"31311704",label:"Ensambles de tubería pegada de inconel"},
    {prodId:"31311705",label:"Ensambles de tubería pegada de acero de aleación baja"},
    {prodId:"31311706",label:"Ensambles de tubería pegada no metálica"},
    {prodId:"31311709",label:"Ensambles de tubería pegada de acero inoxidable"},
    {prodId:"31311710",label:"Ensambles de tubería pegada de titanio"},
    {prodId:"31311711",label:"Ensambles de tubería pegada de aleación wasp"},
    {prodId:"31311712",label:"Ensambles de tubería pegada de cobre"},
    {prodId:"31311713",label:"Ensambles de tubería pegada de latón"},
    {prodId:"31321200",label:"Conjuntos soldados de solvente de material en barras"},
    {prodId:"31321201",label:"Ensambles de barras soldadas con solvente de aluminio"},
    {prodId:"31321202",label:"Ensambles de barras soldadas con solvente de acero al carbono"},
    {prodId:"31321203",label:"Ensambles de barras soldadas con solvente de aleación hast x"},
    {prodId:"31321204",label:"Ensambles de barras soldadas con solvente de inconel"},
    {prodId:"31321205",label:"Ensambles de barras soldadas con solvente de acero de aleación baja"},
    {prodId:"31321206",label:"Ensambles de barras soldadas con solvente no metálica"},
    {prodId:"31321209",label:"Ensambles de barras soldadas con solvente de acero inoxidable"},
    {prodId:"31321210",label:"Ensambles de barras soldadas con solvente de titanio"},
    {prodId:"31321211",label:"Ensambles de barras soldadas con solvente de aleación wasp"},
    {prodId:"31321212",label:"Ensambles de barras soldadas con solvente de cobre"},
    {prodId:"31321213",label:"Ensambles de barras soldadas con solvente de latón"},
    {prodId:"31321300",label:"Conjuntos remachados de material en barras"},
    {prodId:"31321301",label:"Ensambles de barras remachadas de aluminio"},
    {prodId:"31321302",label:"Ensambles de barras remachadas de acero al carbono"},
    {prodId:"31321303",label:"Ensambles de barras remachadas de aleación hast x"},
    {prodId:"31321304",label:"Ensambles de barras remachadas de inconel"},
    {prodId:"31321305",label:"Ensambles de barras remachadas de acero de aleación baja"},
    {prodId:"31321306",label:"Ensambles de barras remachadas no metálica"},
    {prodId:"31321309",label:"Ensambles de barras remachadas de acero inoxidable"},
    {prodId:"31321310",label:"Ensambles de barras remachadas de titanio"},
    {prodId:"31321311",label:"Ensambles de barras remachadas de aleación wasp"},
    {prodId:"31321312",label:"Ensambles de barras remachadas de cobre"},
    {prodId:"31321313",label:"Ensambles de barras remachadas de latón"},
    {prodId:"31321500",label:"Conjuntos de barras soldadas con ultra violeta UV"},
    {prodId:"31321501",label:"Ensambles de barras soldadas con soldadura ultra violeta de aluminio"},
    {prodId:"31321502",label:"Ensambles de barras soldadas con soldadura ultra violeta de acero al carbono"},
    {prodId:"31321503",label:"Ensambles de barras soldadas con soldadura ultra violeta de aleación hast x"},
    {prodId:"31321504",label:"Ensambles de barras soldadas con soldadura ultra violeta de inconel"},
    {prodId:"31321505",label:"Ensambles de barras soldadas con soldadura ultra violeta de acero de aleación baja"},
    {prodId:"31321506",label:"Ensambles de barras soldadas con soldadura ultra violeta no metálica"},
    {prodId:"31321509",label:"Ensambles de barras soldadas con soldadura ultra violeta de acero inoxidable"},
    {prodId:"31321510",label:"Ensambles de barras soldadas con soldadura ultra violeta de titanio"},
    {prodId:"31321511",label:"Ensambles de barras soldadas con soldadura ultra violeta de aleación wasp"},
    {prodId:"31321512",label:"Ensambles de barras soldadas con soldadura ultra violeta de cobre"},
    {prodId:"31321513",label:"Ensambles de barras soldadas con soldadura ultra violeta de latón"},
    {prodId:"31321600",label:"Conjuntos sónicos soldados de material en barras"},
    {prodId:"31321601",label:"Ensambles de barras soldadas con soldadura sónica de aluminio"},
    {prodId:"31321602",label:"Ensambles de barras soldadas con soldadura sónica de acero al carbono"},
    {prodId:"31321603",label:"Ensambles de barras soldadas con soldadura sónica de aleación hast x"},
    {prodId:"31321604",label:"Ensambles de barras soldadas con soldadura sónica de inconel"},
    {prodId:"31321605",label:"Ensambles de barras soldadas con soldadura sónica de acero de aleación baja"},
    {prodId:"31321606",label:"Ensambles de barras soldadas con soldadura sónica no metálica"},
    {prodId:"31321609",label:"Ensambles de barras soldadas con soldadura sónica de acero inoxidable"},
    {prodId:"31321610",label:"Ensambles de barras soldadas con soldadura sónica de titanio"},
    {prodId:"31321611",label:"Ensambles de barras soldadas con soldadura sónica de aleación wasp"},
    {prodId:"31321612",label:"Ensambles de barras soldadas con soldadura sónica de cobre"},
    {prodId:"31321613",label:"Ensambles de barras soldadas con soldadura sónica de latón"},
    {prodId:"31321700",label:"Conjuntos empernados de material en barras"},
    {prodId:"31321701",label:"Ensambles de barras atornilladas de aluminio"},
    {prodId:"31321702",label:"Ensambles de barras atornilladas de acero al carbono"},
    {prodId:"31321703",label:"Ensambles de barras atornilladas de aleación hast x"},
    {prodId:"31321704",label:"Ensambles de barras atornilladas de inconel"},
    {prodId:"31321705",label:"Ensambles de barras atornilladas de acero de aleación baja"},
    {prodId:"31321706",label:"Ensambles de barras atornilladas no metálica"},
    {prodId:"31321709",label:"Ensambles de barras atornilladas de acero inoxidable"},
    {prodId:"31321710",label:"Ensambles de barras atornilladas de titanio"},
    {prodId:"31321711",label:"Ensambles de barras atornilladas de aleación wasp"},
    {prodId:"31321712",label:"Ensambles de barras atornilladas de cobre"},
    {prodId:"31321713",label:"Ensambles de barras atornilladas de latón"},
    {prodId:"31331500",label:"Conjuntos estructurales soldado de solvente"},
    {prodId:"31331501",label:"Ensambles estructurales con soldadura de solvente de aluminio"},
    {prodId:"31331502",label:"Ensambles estructurales con soldadura de solvente de acero al carbono"},
    {prodId:"31331503",label:"Ensambles estructurales con soldadura de solvente de aleación hast x"},
    {prodId:"31331504",label:"Ensambles estructurales con soldadura de solvente de inconel"},
    {prodId:"31331505",label:"Ensambles estructurales con soldadura de solvente de acero de aleación baja"},
    {prodId:"31331506",label:"Ensambles estructurales con soldadura de solvente no metálica"},
    {prodId:"31331509",label:"Ensambles estructurales con soldadura de solvente de acero inoxidable"},
    {prodId:"31331510",label:"Ensambles estructurales con soldadura de solvente de titanio"},
    {prodId:"31331511",label:"Ensambles estructurales con soldadura de solvente de aleación wasp"},
    {prodId:"31331512",label:"Ensambles estructurales con soldadura de solvente de cobre"},
    {prodId:"31331513",label:"Ensambles estructurales con soldadura de solvente de latón"},
    {prodId:"31341200",label:"Conjuntos remachados de placa"},
    {prodId:"31341201",label:"Ensambles de láminas remachadas de aluminio"},
    {prodId:"31341202",label:"Ensambles de láminas remachadas de acero al carbono"},
    {prodId:"31341203",label:"Ensambles de láminas remachadas de aleación hast x"},
    {prodId:"31341204",label:"Ensambles de láminas remachadas de inconel"},
    {prodId:"31341205",label:"Ensambles de láminas remachadas de acero de aleación baja"},
    {prodId:"31341206",label:"Ensambles de láminas remachadas no metálica"},
    {prodId:"31341209",label:"Ensambles de láminas remachadas de acero inoxidable"},
    {prodId:"31341210",label:"Ensambles de láminas remachadas de titanio"},
    {prodId:"31341211",label:"Ensambles de láminas remachadas de aleación wasp"},
    {prodId:"31341212",label:"Ensambles de láminas remachadas de cobre"},
    {prodId:"31341213",label:"Ensambles de láminas remachadas de latón"},
    {prodId:"31341300",label:"Conjuntos de láminas soldadas con ultra violeta UV"},
    {prodId:"31341301",label:"Ensambles de láminas soldadas con soldadura ultra violeta de aluminio"},
    {prodId:"31341302",label:"Ensambles de láminas soldadas con soldadura ultra violeta de acero al carbono"},
    {prodId:"31341303",label:"Ensambles de láminas soldadas con soldadura ultra violeta de aleación hast x"},
    {prodId:"31341304",label:"Ensambles de láminas soldadas con soldadura ultra violeta de inconel"},
    {prodId:"31341305",label:"Ensambles de láminas soldadas con soldadura ultra violeta de acero de aleación baja"},
    {prodId:"31341306",label:"Ensambles de láminas soldadas con soldadura ultra violeta no metálica"},
    {prodId:"31341309",label:"Ensambles de láminas soldadas con soldadura ultra violeta de acero inoxidable"},
    {prodId:"31341310",label:"Ensambles de láminas soldadas con soldadura ultra violeta de titanio"},
    {prodId:"31341311",label:"Ensambles de láminas soldadas con soldadura ultra violeta de aleación wasp"},
    {prodId:"31341312",label:"Ensambles de láminas soldadas con soldadura ultra violeta de cobre"},
    {prodId:"31341313",label:"Ensambles de láminas soldadas con soldadura ultra violeta de latón"},
    {prodId:"31341400",label:"Conjuntos de lámina soldada sónica"},
    {prodId:"31341401",label:"Ensambles de láminas soldadas con soldadura sónica de aluminio"},
    {prodId:"31341402",label:"Ensambles de láminas soldadas con soldadura sónica de acero al carbono"},
    {prodId:"31341403",label:"Ensambles de láminas soldadas con soldadura sónica de aleación hast x"},
    {prodId:"31341404",label:"Ensambles de láminas soldadas con soldadura sónica de inconel"},
    {prodId:"31341405",label:"Ensambles de láminas soldadas con soldadura sónica de acero de aleación baja"},
    {prodId:"31341406",label:"Ensambles de láminas soldadas con soldadura sónica no metálica"},
    {prodId:"31341409",label:"Ensambles de láminas soldadas con soldadura sónica de acero inoxidable"},
    {prodId:"31341410",label:"Ensambles de láminas soldadas con soldadura sónica de titanio"},
    {prodId:"31341411",label:"Ensambles de láminas soldadas con soldadura sónica de aleación wasp"},
    {prodId:"31341412",label:"Ensambles de láminas soldadas con soldadura sónica de cobre"},
    {prodId:"31341413",label:"Ensambles de láminas soldadas con soldadura sónica de latón"},
    {prodId:"31341500",label:"Conjuntos de lámina soldada de solvente"},
    {prodId:"31341501",label:"Ensambles de láminas soldadas con soldadura solvente de aluminio"},
    {prodId:"31341502",label:"Ensambles de láminas soldadas con soldadura solvente de acero al carbono"},
    {prodId:"31341503",label:"Ensambles de láminas soldadas con soldadura solvente de aleación hast x"},
    {prodId:"31341504",label:"Ensambles de láminas soldadas con soldadura solvente de inconel"},
    {prodId:"31341505",label:"Ensambles de láminas soldadas con soldadura solvente de acero de aleación baja"},
    {prodId:"31341506",label:"Ensambles de láminas soldadas con soldadura solvente no metálica"},
    {prodId:"31341509",label:"Ensambles de láminas soldadas con soldadura solvente de acero inoxidable"},
    {prodId:"31341510",label:"Ensambles de láminas soldadas con soldadura solvente de titanio"},
    {prodId:"31341511",label:"Ensambles de láminas soldadas con soldadura solvente de aleación wasp"},
    {prodId:"31341512",label:"Ensambles de láminas soldadas con soldadura solvente de cobre"},
    {prodId:"31341513",label:"Ensambles de láminas soldadas con soldadura solvente de latón"},
    {prodId:"31341600",label:"Conjuntos de lámina con acabado ligado"},
    {prodId:"31341601",label:"Ensambles de láminas pegadas de aluminio"},
    {prodId:"31341602",label:"Ensambles de láminas pegadas de acero al carbono"},
    {prodId:"31341603",label:"Ensambles de láminas pegadas de aleación hast x"},
    {prodId:"31341604",label:"Ensambles de láminas pegadas de inconel"},
    {prodId:"31341605",label:"Ensambles de láminas pegadas de acero de aleación baja"},
    {prodId:"31341606",label:"Ensambles de láminas pegadas no metálica"},
    {prodId:"31341609",label:"Ensambles de láminas pegadas de acero inoxidable"},
    {prodId:"31341610",label:"Ensambles de láminas pegadas de titanio"},
    {prodId:"31341611",label:"Ensambles de láminas pegadas de aleación wasp"},
    {prodId:"31341612",label:"Ensambles de láminas pegadas de cobre"},
    {prodId:"31341613",label:"Ensambles de láminas pegadas de latón"},
    {prodId:"31341700",label:"Conjuntos de lámina empernada"},
    {prodId:"31341701",label:"Ensambles de láminas atornilladas de aluminio"},
    {prodId:"31341702",label:"Ensambles de láminas atornilladas de acero al carbono"},
    {prodId:"31341703",label:"Ensambles de láminas atornilladas de aleación hast x"},
    {prodId:"31341704",label:"Ensambles de láminas atornilladas de inconel"},
    {prodId:"31341705",label:"Ensambles de láminas atornilladas de acero de aleación baja"},
    {prodId:"31341706",label:"Ensambles de láminas atornilladas no metálica"},
    {prodId:"31341709",label:"Ensambles de láminas atornilladas de acero inoxidable"},
    {prodId:"31341710",label:"Ensambles de láminas atornilladas de titanio"},
    {prodId:"31341711",label:"Ensambles de láminas atornilladas de aleación wasp"},
    {prodId:"31341712",label:"Ensambles de láminas atornilladas de cobre"},
    {prodId:"31341713",label:"Ensambles de láminas atornilladas de latón"},
    {prodId:"31351100",label:"Conjuntos de tubos soldados con ultra violeta UV"},
    {prodId:"31351101",label:"Ensambles de tubos soldados con soldadura ultra violeta de aluminio"},
    {prodId:"31351102",label:"Ensambles de tubos soldados con soldadura ultra violeta de acero al carbono"},
    {prodId:"31351103",label:"Ensambles de tubos soldados con soldadura ultra violeta de aleación hast x"},
    {prodId:"31351104",label:"Ensambles de tubos soldados con soldadura ultra violeta de inconel"},
    {prodId:"31351105",label:"Ensambles de tubos soldados con soldadura ultra violeta de acero de aleación baja"},
    {prodId:"31351106",label:"Ensambles de tubos soldados con soldadura ultra violeta no metálica"},
    {prodId:"31351109",label:"Ensambles de tubos soldados con soldadura ultra violeta de acero inoxidable"},
    {prodId:"31351110",label:"Ensambles de tubos soldados con soldadura ultra violeta de titanio"},
    {prodId:"31351111",label:"Ensambles de tubos soldados con soldadura ultra violeta de aleación wasp"},
    {prodId:"31351112",label:"Ensambles de tubos soldados con soldadura ultra violeta de cobre"},
    {prodId:"31351113",label:"Ensambles de tubos soldados con soldadura ultra violeta de latón"},
    {prodId:"31351300",label:"Conjuntos de tubería remachada"},
    {prodId:"31351301",label:"Ensambles de tubos remachados de aluminio"},
    {prodId:"31351302",label:"Ensambles de tubos remachados de acero al carbono"},
    {prodId:"31351303",label:"Ensambles de tubos remachados de aleación hast x"},
    {prodId:"31351304",label:"Ensambles de tubos remachados de inconel"},
    {prodId:"31351305",label:"Ensambles de tubos remachados de acero de aleación baja"},
    {prodId:"31351306",label:"Ensambles de tubos remachados no metálica"},
    {prodId:"31351309",label:"Ensambles de tubos remachados de acero inoxidable"},
    {prodId:"31351310",label:"Ensambles de tubos remachados de titanio"},
    {prodId:"31351311",label:"Ensambles de tubos remachados de aleación wasp"},
    {prodId:"31351312",label:"Ensambles de tubos remachados de cobre"},
    {prodId:"31351313",label:"Ensambles de tubos remachados de latón"},
    {prodId:"31351600",label:"Conjuntos de tubería soldada de solvente"},
    {prodId:"31351601",label:"Ensambles de tubos soldados con disolvente de aluminio"},
    {prodId:"31351602",label:"Ensambles de tubos soldados con disolvente de acero al carbono"},
    {prodId:"31351603",label:"Ensambles de tubos soldados con disolvente de aleación hast x"},
    {prodId:"31351604",label:"Ensambles de tubos soldados con disolvente de inconel"},
    {prodId:"31351605",label:"Ensambles de tubos soldados con disolvente de acero de aleación baja"},
    {prodId:"31351606",label:"Ensambles de tubos soldados con disolvente no metálica"},
    {prodId:"31351609",label:"Ensambles de tubos soldados con disolvente de acero inoxidable"},
    {prodId:"31351610",label:"Ensambles de tubos soldados con disolvente de titanio"},
    {prodId:"31351611",label:"Ensambles de tubos soldados con disolvente de aleación wasp"},
    {prodId:"31351612",label:"Ensambles de tubos soldados con disolvente de cobre"},
    {prodId:"31351613",label:"Ensambles de tubos soldados con disolvente de latón"},
    {prodId:"31351700",label:"Conjuntos de tubería soldada sónica"},
    {prodId:"31351701",label:"Ensambles de tubos soldados con soldadura sónica de aluminio"},
    {prodId:"31351702",label:"Ensambles de tubos soldados con soldadura sónica de acero al carbono"},
    {prodId:"31351703",label:"Ensambles de tubos soldados con soldadura sónica de aleación hast x"},
    {prodId:"31351704",label:"Ensambles de tubos soldados con soldadura sónica de inconel"},
    {prodId:"31351705",label:"Ensambles de tubos soldados con soldadura sónica de acero de aleación baja"},
    {prodId:"31351706",label:"Ensambles de tubos soldados con soldadura sónica no metálica"},
    {prodId:"31351709",label:"Ensambles de tubos soldados con soldadura sónica de acero inoxidable"},
    {prodId:"31351710",label:"Ensambles de tubos soldados con soldadura sónica de titanio"},
    {prodId:"31351711",label:"Ensambles de tubos soldados con soldadura sónica de aleación wasp"},
    {prodId:"31351712",label:"Ensambles de tubos soldados con soldadura sónica de cobre"},
    {prodId:"31351713",label:"Ensambles de tubos soldados con soldadura sónica de latón"},
    {prodId:"31361300",label:"Conjuntos de placa soldados de solvente"},
    {prodId:"31361301",label:"Ensambles de placas soldadas con solvente de aluminio"},
    {prodId:"31361302",label:"Ensambles de placas soldadas con solvente de acero al carbono"},
    {prodId:"31361303",label:"Ensambles de placas soldadas con solvente de aleación hast x"},
    {prodId:"31361304",label:"Ensambles de placas soldadas con solvente de inconel"},
    {prodId:"31361305",label:"Ensambles de placas soldadas con solvente de acero de aleación baja"},
    {prodId:"31361306",label:"Ensambles de placas soldadas con solvente no metálica"},
    {prodId:"31361309",label:"Ensambles de placas soldadas con solvente de acero inoxidable"},
    {prodId:"31361310",label:"Ensambles de placas soldadas con solvente de titanio"},
    {prodId:"31361311",label:"Ensambles de placas soldadas con solvente de aleación wasp"},
    {prodId:"31361312",label:"Ensambles de placas soldadas con solvente de cobre"},
    {prodId:"31361313",label:"Ensambles de placas soldadas con solvente de latón"},
    {prodId:"31361400",label:"Conjuntos de placas soldadas o soldadas con latón"},
    {prodId:"31361401",label:"Ensambles de placas soldadas con soldadura fuerte o débil de aluminio"},
    {prodId:"31361402",label:"Ensambles de placas soldadas con soldadura fuerte o débil de acero al carbono"},
    {prodId:"31361403",label:"Ensambles de placas soldadas con soldadura fuerte o débil de aleación hast x"},
    {prodId:"31361404",label:"Ensambles de placas soldadas con soldadura fuerte o débil de inconel"},
    {prodId:"31361405",label:"Ensambles de placas soldadas con soldadura fuerte o débil de acero de aleación baja"},
    {prodId:"31361406",label:"Ensambles de placas soldadas con soldadura fuerte o débil no metálica"},
    {prodId:"31361409",label:"Ensambles de placas soldadas con soldadura fuerte o débil de acero inoxidable"},
    {prodId:"31361410",label:"Ensambles de placas soldadas con soldadura fuerte o débil de titanio"},
    {prodId:"31361411",label:"Ensambles de placas soldadas con soldadura fuerte o débil de aleación wasp"},
    {prodId:"31361412",label:"Ensambles de placas soldadas con soldadura fuerte o débil de cobre"},
    {prodId:"31361413",label:"Ensambles de placas soldadas con soldadura fuerte o débil de latón"},
    {prodId:"31361500",label:"Conjuntos de placas soldadas con ultra violeta UV"},
    {prodId:"31361501",label:"Ensambles de placas soldadas con soldadura ultra violeta de aluminio"},
    {prodId:"31361502",label:"Ensambles de placas soldadas con soldadura ultra violeta de acero al carbono"},
    {prodId:"31361503",label:"Ensambles de placas soldadas con soldadura ultra violeta de aleación hast x"},
    {prodId:"31361504",label:"Ensambles de placas soldadas con soldadura ultra violeta de inconel"},
    {prodId:"31361505",label:"Ensambles de placas soldadas con soldadura ultra violeta de acero de aleación baja"},
    {prodId:"31361506",label:"Ensambles de placas soldadas con soldadura ultra violeta no metálica"},
    {prodId:"31361509",label:"Ensambles de placas soldadas con soldadura ultra violeta de acero inoxidable"},
    {prodId:"31361510",label:"Ensambles de placas soldadas con soldadura ultra violeta de titanio"},
    {prodId:"31361511",label:"Ensambles de placas soldadas con soldadura ultra violeta de aleación wasp"},
    {prodId:"31361512",label:"Ensambles de placas soldadas con soldadura ultra violeta de cobre"},
    {prodId:"31361513",label:"Ensambles de placas soldadas con soldadura ultra violeta de latón"},
    {prodId:"31361600",label:"Conjuntos de placas soldadas con sónico"},
    {prodId:"31361601",label:"Ensambles de placas soldadas con soldadura sónica de aluminio"},
    {prodId:"31361602",label:"Ensambles de placas soldadas con soldadura sónica de acero al carbono"},
    {prodId:"31361603",label:"Ensambles de placas soldadas con soldadura sónica de aleación hast x"},
    {prodId:"31361604",label:"Ensambles de placas soldadas con soldadura sónica de inconel"},
    {prodId:"31361605",label:"Ensambles de placas soldadas con soldadura sónica de acero de aleación baja"},
    {prodId:"31361606",label:"Ensambles de placas soldadas con soldadura sónica no metálica"},
    {prodId:"31361609",label:"Ensambles de placas soldadas con soldadura sónica de acero inoxidable"},
    {prodId:"31361610",label:"Ensambles de placas soldadas con soldadura sónica de titanio"},
    {prodId:"31361611",label:"Ensambles de placas soldadas con soldadura sónica de aleación wasp"},
    {prodId:"31361612",label:"Ensambles de placas soldadas con soldadura sónica de cobre"},
    {prodId:"31361613",label:"Ensambles de placas soldadas con soldadura sónica de latón"},
    {prodId:"31361700",label:"Conjuntos de placas remachadas"},
    {prodId:"31361701",label:"Ensambles de placas remachadas de aluminio"},
    {prodId:"31361702",label:"Ensambles de placas remachadas de acero al carbono"},
    {prodId:"31361703",label:"Ensambles de placas remachadas de aleación hast x"},
    {prodId:"31361704",label:"Ensambles de placas remachadas de inconel"},
    {prodId:"31361705",label:"Ensambles de placas remachadas de acero de aleación baja"},
    {prodId:"31361706",label:"Ensambles de placas remachadas no metálica"},
    {prodId:"31361709",label:"Ensambles de placas remachadas de acero inoxidable"},
    {prodId:"31361710",label:"Ensambles de placas remachadas de titanio"},
    {prodId:"31361711",label:"Ensambles de placas remachadas de aleación wasp"},
    {prodId:"31361712",label:"Ensambles de placas remachadas de cobre"},
    {prodId:"31361713",label:"Ensambles de placas remachadas de latón"},
    {prodId:"31371400",label:"Losetas refractarias"},
    {prodId:"31371401",label:"Tejas de sílice"},
    {prodId:"31381300",label:"Imanes prensados y ensambles magnéticos"},
    {prodId:"31381301",label:"Imán isotrópico prensado y sinterizado maquinado de ferrita"},
    {prodId:"31381302",label:"Imán isotrópico prensado y sinterizado maquinado de bario ferrita"},
    {prodId:"31381303",label:"Imán isotrópico prensado y sinterizado maquinado de estroncio ferrita"},
    {prodId:"31381304",label:"Imán isotrópico prensado y sinterizado maquinado de neodimio"},
    {prodId:"31381305",label:"Imán isotrópico prensado y sinterizado maquinado de samario cobalto"},
    {prodId:"31381306",label:"Imán isotrópico prensado y sinterizado maquinado de aluminio níquel cobalto ferroso"},
    {prodId:"31381307",label:"Imán anisotrópico prensado y sinterizado maquinado de ferrita"},
    {prodId:"31381308",label:"Imán anisotrópico prensado y sinterizado maquinado de bario ferrita"},
    {prodId:"31381309",label:"Imán anisotrópico prensado y sinterizado maquinado de estroncio ferrita"},
    {prodId:"31381310",label:"Imán anisotrópico prensado y sinterizado maquinado de samario cobalto"},
    {prodId:"31381311",label:"Imán anisotrópico prensado y sinterizado maquinado de aluminio níquel cobalto ferroso"},
    {prodId:"31381312",label:"Imán isotrópico prensado y sinterizado, maquinado y recubierto de ferrita"},
    {prodId:"31381313",label:"Imán isotrópico prensado y sinterizado, maquinado y recubierto de bario ferrita"},
    {prodId:"31381314",label:"Imán isotrópico prensado y sinterizado, maquinado y recubierto de estroncio ferrita"},
    {prodId:"31381315",label:"Imán isotrópico prensado y sinterizado, maquinado y recubierto de neodimio"},
    {prodId:"31381316",label:"Imán isotrópico prensado y sinterizado, maquinado y recubierto de samario cobalto"},
    {prodId:"31381317",label:"Imán isotrópico prensado y sinterizado, maquinado y recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381318",label:"Imán anisotrópico prensado y sinterizado, maquinado y recubierto  de ferrita"},
    {prodId:"31381319",label:"Imán anisotrópico prensado y sinterizado, maquinado y recubierto  de bario ferrita"},
    {prodId:"31381320",label:"Imán anisotrópico prensado y sinterizado, maquinado y recubierto de estroncio ferrita"},
    {prodId:"31381321",label:"Imán anisotrópico prensado y sinterizado, maquinado y recubierto de samario cobalto"},
    {prodId:"31381322",label:"Imán anisotrópico prensado y sinterizado, maquinado y recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381323",label:"Imán isotrópico prensado y sinterizado recubierto de ferrita"},
    {prodId:"31381324",label:"Imán isotrópico prensado y sinterizado recubierto de bario ferrita"},
    {prodId:"31381325",label:"Imán isotrópico prensado y sinterizado recubierto de estroncio ferrita"},
    {prodId:"31381326",label:"Imán isotrópico prensado y sinterizado recubierto de neodimio"},
    {prodId:"31381327",label:"Imán isotrópico prensado y sinterizado recubierto de samario cobalto"},
    {prodId:"31381328",label:"Imán isotrópico prensado y sinterizado recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381329",label:"Imán anisotrópico prensado y sinterizado recubierto de ferrita"},
    {prodId:"31381330",label:"Imán anisotrópico prensado y sinterizado recubierto de bario ferrita"},
    {prodId:"31381331",label:"Imán anisotrópico prensado y sinterizado recubierto de estroncio ferrita"},
    {prodId:"31381332",label:"Imán anisotrópico prensado y sinterizado recubierto de samario cobalto"},
    {prodId:"31381333",label:"Imán anisotrópico prensado y sinterizado recubierto de aluminio níquel cobalto ferroso"},
    {prodId:"31381334",label:"Herramienta isotrópica prensada y sinterizada maquinada de ferrita"},
    {prodId:"31381335",label:"Herramienta isotrópica prensada y sinterizada maquinada de bario ferrita"},
    {prodId:"31381336",label:"Herramienta isotrópica prensada y sinterizada maquinada de estroncio ferrita"},
    {prodId:"31381337",label:"Herramienta isotrópica prensada y sinterizada maquinada de neodimio"},
    {prodId:"31381338",label:"Herramienta isotrópica prensada y sinterizada maquinada de samario cobalto"},
    {prodId:"31381339",label:"Herramienta isotrópica prensada y sinterizada maquinada de aluminio níquel cobalto ferroso"},
    {prodId:"31381340",label:"Herramienta anisotrópica prensada y sinterizada maquinada de ferrita"},
    {prodId:"31381341",label:"Herramienta anisotrópica prensada y sinterizada maquinada de bario ferrita"},
    {prodId:"31381342",label:"Herramienta anisotrópica prensada y sinterizada maquinada de estroncio ferrita"},
    {prodId:"31381343",label:"Herramienta anisotrópica prensada y sinterizada maquinada de samario cobalto"},
    {prodId:"31381344",label:"Herramienta anisotrópica prensada y sinterizada maquinada de aluminio níquel cobalto ferroso"},
    {prodId:"31381345",label:"Ensamble de imán isotrópico prensado y sinterizado de ferrita"},
    {prodId:"31381346",label:"Ensamble de imán isotrópico prensado y sinterizado de bario ferrita"},
    {prodId:"31381347",label:"Ensamble de imán isotrópico prensado y sinterizado de estroncio ferrita"},
    {prodId:"31381348",label:"Ensamble de imán isotrópico prensado y sinterizado de neodimio"},
    {prodId:"31381349",label:"Ensamble de imán isotrópico prensado y sinterizado de samario cobalto"},
    {prodId:"31381350",label:"Ensamble de imán isotrópico prensado y sinterizado de aluminio níquel cobalto ferroso"},
    {prodId:"31381351",label:"Ensamble de imán anisotrópico prensado y sinterizado de ferrita"},
    {prodId:"31381352",label:"Ensamble de imán anisotrópico prensado y sinterizado de bario ferrita"},
    {prodId:"31381353",label:"Ensamble de imán anisotrópico prensado y sinterizado de estroncio ferrita"},
    {prodId:"31381354",label:"Ensamble de imán anisotrópico prensado y sinterizado de samario cobalto"},
    {prodId:"31381355",label:"Ensamble de imán anisotrópico prensado y sinterizado de aluminio níquel cobalto ferroso"},
    {prodId:"32121800",label:"Filtros de señales"},
    {prodId:"32121801",label:"Filtro de onda acústica de superficie saw"},
    {prodId:"32121802",label:"Filtro cerámico"},
    {prodId:"32121803",label:"Filtro de microondas"},
    {prodId:"32121804",label:"Filtro de cristal"},
    {prodId:"32121900",label:"Resonadores"},
    {prodId:"32121901",label:"Resonador de  onda acústica de superficie saw"},
    {prodId:"32121902",label:"Resonador cerámico"},
    {prodId:"32152000",label:"Control de procesos o sistemas empaquetados de automatización"},
    {prodId:"32152001",label:"Sistema empaquetado de control distribuido dcs"},
    {prodId:"32152002",label:"Sistema empaquetado de control de supervisión y adquisición de datos scada"},
    {prodId:"32152003",label:"Sistema empaquetado de seguridad instrumentada sis"},
    {prodId:"40171800",label:"Anillos frontales de ángulos de tubos"},
    {prodId:"40171801",label:"Anillo frontal de ángulo de tubo de acero al carbono"},
    {prodId:"40171802",label:"Anillo frontal de ángulo de tubo de acero forjado"},
    {prodId:"40171803",label:"Anillo frontal de ángulo de tubo de acero inoxidable"},
    {prodId:"40173700",label:"Bridas reductoras para tubos"},
    {prodId:"40173701",label:"Brida reductora para tubos de latón"},
    {prodId:"40173702",label:"Brida reductora para tubos de hierro forjado"},
    {prodId:"40173703",label:"Brida reductora para tubos de hierro dúctil"},
    {prodId:"40173704",label:"Brida reductora para tubos de acero forjado"},
    {prodId:"40173705",label:"Brida reductora para tubos de hierro maleable"},
    {prodId:"40173706",label:"Brida reductora para tubos de acero inoxidable"},
    {prodId:"40173707",label:"Brida reductora para tubos de plástico pvc"},
    {prodId:"40173708",label:"Brida reductora para tubos de plástico cpvc"},
    {prodId:"40173709",label:"Brida reductora para tubos de plástico abs"},
    {prodId:"40173710",label:"Brida reductora para tubos de plástico hdpe"},
    {prodId:"40173711",label:"Brida reductora para tubos de cobre"},
    {prodId:"40174000",label:"Brida deslizante para tubos"},
    {prodId:"40174001",label:"Brida deslizante para tubos de latón"},
    {prodId:"40174002",label:"Brida deslizante para tubos de acero al carbono"},
    {prodId:"40174003",label:"Brida deslizante para tubos de hierro forjado"},
    {prodId:"40174004",label:"Brida deslizante para tubos de hierro dúctil"},
    {prodId:"40174005",label:"Brida deslizante para tubos de acero forjado"},
    {prodId:"40174006",label:"Brida deslizante para tubos de acero inoxidable"},
    {prodId:"40174007",label:"Brida deslizante para tubos de cobre"},
    {prodId:"40174200",label:"Sockolets para tubos"},
    {prodId:"40174201",label:"Sockolet para tubos de acero al carbono"},
    {prodId:"40174202",label:"Sockolet para tubos de acero forjado"},
    {prodId:"40174203",label:"Sockolet para tubos de acero inoxidable"},
    {prodId:"40175100",label:"Brida encajada por soldadura"},
    {prodId:"40175101",label:"Brida encajada por soldadura para tubos de acero al carbono"},
    {prodId:"40175102",label:"Brida encajada por soldadura para tubos de acero forjado"},
    {prodId:"40175103",label:"Brida encajada por soldadura para tubos de acero inoxidable"},
    {prodId:"40181500",label:"Tubos de cobre soldados"},
    {prodId:"40181501",label:"Tubo doblado de cobre soldado"},
    {prodId:"40181502",label:"Tubo perforado de cobre soldado"},
    {prodId:"40181503",label:"Tubo de remate final de cobre soldado"},
    {prodId:"40181504",label:"Tubo multipuerto de cobre soldado"},
    {prodId:"40181505",label:"Tubo biselado de cobre soldado"},
    {prodId:"40181506",label:"Ensamblaje de tubos de cobre soldado"},
    {prodId:"40181600",label:"Tubos de latón soldados"},
    {prodId:"40181601",label:"Tubo doblado de latón soldada"},
    {prodId:"40181602",label:"Tubo perforado de latón soldada"},
    {prodId:"40181603",label:"Tubo de remate final de latón soldada"},
    {prodId:"40181604",label:"Tubo multipuerto de latón soldada"},
    {prodId:"40181605",label:"Tubo biselado de latón soldada"},
    {prodId:"40181606",label:"Ensamblaje de tubos de latón soldada"},
    {prodId:"40182100",label:"Tubos de latón sin uniones"},
    {prodId:"40182101",label:"Tubo doblado de latón sin uniones"},
    {prodId:"40182102",label:"Tubo perforado de latón sin uniones"},
    {prodId:"40182103",label:"Tubo de remate final de latón sin uniones"},
    {prodId:"40182104",label:"Tubo multipuerto de latón sin uniones"},
    {prodId:"40182105",label:"Tubo biselado de latón sin uniones"},
    {prodId:"40182106",label:"Ensamblaje de tubos de latón sin uniones"},
    {prodId:"40182200",label:"Tubos de aluminio sin uniones"},
    {prodId:"40182201",label:"Tubo doblado de aluminio sin uniones"},
    {prodId:"40182202",label:"Tubo perforado de aluminio sin uniones"},
    {prodId:"40182203",label:"Tubo de remate final de aluminio sin uniones"},
    {prodId:"40182204",label:"Tubo multipuerto de aluminio sin uniones"},
    {prodId:"40182205",label:"Tubo biselado de aluminio sin uniones"},
    {prodId:"40182206",label:"Ensamblaje de tubos de aluminio sin uniones"},
    {prodId:"40182500",label:"Tubos de cobre extruidos"},
    {prodId:"40182501",label:"Tubo doblado de cobre extruido"},
    {prodId:"40182502",label:"Tubo perforado de cobre extruido"},
    {prodId:"40182503",label:"Tubo de remate final de cobre extruido"},
    {prodId:"40182504",label:"Tubo multipuerto de cobre extruido"},
    {prodId:"40182505",label:"Tubo biselado de cobre extruido"},
    {prodId:"40182506",label:"Ensamblaje de tubos de cobre extruido"},
    {prodId:"40182600",label:"Tubos de latón extruidos"},
    {prodId:"40182601",label:"Tubo doblado de latón extruida"},
    {prodId:"40182602",label:"Tubo perforado de latón extruida"},
    {prodId:"40182603",label:"Tubo de remate final de latón extruida"},
    {prodId:"40182604",label:"Tubo multipuerto de latón extruida"},
    {prodId:"40182605",label:"Tubo biselado de latón extruida"},
    {prodId:"40182606",label:"Ensamblaje de tubos de latón extruida"},
    {prodId:"40182800",label:"Tubos de acero extruidos"},
    {prodId:"40182801",label:"Tubo doblado de acero extruido"},
    {prodId:"40182802",label:"Tubo perforado de acero extruido"},
    {prodId:"40182803",label:"Tubo de remate final de acero extruido"},
    {prodId:"40182804",label:"Tubo multipuerto de acero extruido"},
    {prodId:"40182805",label:"Tubo biselado de acero extruido"},
    {prodId:"40182806",label:"Ensamblaje de tubos de acero extruido"},
    {prodId:"41101900",label:"Equipo iónico de laboratorio"},
    {prodId:"41101901",label:"Fuentes de iones"},
    {prodId:"41101902",label:"Aparatos de intercambio de iones"},
    {prodId:"41101903",label:"Equipos de implantación de iones"},
    {prodId:"41102700",label:"Equipo de cristalografía"},
    {prodId:"41102701",label:"Modelos de enrejados de cristal"},
    {prodId:"41102702",label:"Ensamblajes de cristales centellantes"},
    {prodId:"41102703",label:"Equipo de dispersión de luz"},
    {prodId:"41102704",label:"Equipo de difracción de rayos x"},
    {prodId:"41102705",label:"Cristalizadores"},
    {prodId:"41102706",label:"Equipo de crecimiento de cristal"},
    {prodId:"41104700",label:"Secadoras por congelación y liofilizadores de laboratorio, y accesorios"},
    {prodId:"41104701",label:"Secadores de congelación o liofolizantes"},
    {prodId:"41104702",label:"Cristalería para secadores de congelación"},
    {prodId:"41104703",label:"Secadores de bandeja"},
    {prodId:"41104704",label:"Accesorios para secadores de congelación o liofolizantes"},
    {prodId:"41105600",label:"Productos secuenciadores de ácido desoxirribonucleico (ADN)"},
    {prodId:"41105601",label:"Kits o enzimas para secuenciación"},
    {prodId:"41105700",label:"Matrices génicas"},
    {prodId:"41105701",label:"Acido nucleico inmovilizado en membranas de vidrio o nylon"},
    {prodId:"41105800",label:"Productos de transcripción y traducción in vitro"},
    {prodId:"41105801",label:"Conjugados o derivados oligoméricos"},
    {prodId:"41105802",label:"Ribonucleotidos"},
    {prodId:"41105803",label:"Sistemas o kits de transcripción o traducción"},
    {prodId:"41105804",label:"Accesorios de etiquetado de traducción"},
    {prodId:"41106000",label:"Sistemas de etiquetado y detección de ácidos nucleicos"},
    {prodId:"41106001",label:"Materiales de detección de ácido nucleico quimio fluorescente"},
    {prodId:"41106002",label:"Materiales de detección de ácido nucleico quimio luminiscente"},
    {prodId:"41106003",label:"Materiales de detección nucleicos y cromogénicos"},
    {prodId:"41106004",label:"Kits de etiquetado no radiactivos de ácido nucleico"},
    {prodId:"41106005",label:"Kits de etiquetado radiactivos de ácido nucleico"},
    {prodId:"41106006",label:"Radio nucleótidos o nucleosidos"},
    {prodId:"41106600",label:"Vectores"},
    {prodId:"41106601",label:"Vectores enfocados a cromosomas"},
    {prodId:"41106602",label:"Vectores enfocados a expresión bacteriana"},
    {prodId:"41106603",label:"Vectores de casete"},
    {prodId:"41106604",label:"Despliegue de mapas o secuencias vectoriales"},
    {prodId:"41106605",label:"Mapas o secuencias de reportero de vector enzimático"},
    {prodId:"41106606",label:"Vectores de expresión de ácido desoxirribonucleico complementario cdna"},
    {prodId:"41106607",label:"Mapas o secuencias de vectores proteínicos fluorescentes"},
    {prodId:"41106608",label:"Vectores de fusión"},
    {prodId:"41106609",label:"Vectores enfocados a genes"},
    {prodId:"41106610",label:"Vectores de clonación general"},
    {prodId:"41106611",label:"Vectores o kits de sistemas hídricos"},
    {prodId:"41106612",label:"Vectores de expresión de insectos"},
    {prodId:"41106613",label:"Vectores de construcción de muestrarios"},
    {prodId:"41106614",label:"Vectores de expresión celular de mamíferos"},
    {prodId:"41106615",label:"Kits o vectores de clonación de reacción en cadena de polimerasa pcr"},
    {prodId:"41106616",label:"Ácido desoxirribonucleico dna fágico o viral"},
    {prodId:"41106617",label:"Kits o vectores de muta génesis plásmida"},
    {prodId:"41106618",label:"Productos de expresión o clonación de recombinación mediada"},
    {prodId:"41106619",label:"Vectores de secuenciación"},
    {prodId:"41106620",label:"Mapas o secuencias de reporteros de vectores por señal de transducción"},
    {prodId:"41106621",label:"Kits o vectores de expresión de virus mediado"},
    {prodId:"41106622",label:"Vectores de expresión de levadura"},
    {prodId:"41112600",label:"Equipo de monitorización y comprobación higiénica"},
    {prodId:"41112601",label:"Kits de prueba de frotis manual"},
    {prodId:"41112602",label:"Kits de prueba de frotis automático"},
    {prodId:"41112700",label:"Equipo para semillas y piensos"},
    {prodId:"41112701",label:"Analizadores de grano"},
    {prodId:"41112702",label:"Contadores de semillas"},
    {prodId:"41112704",label:"Analizadores de alimentación"},
    {prodId:"41113643",label:"Medidores o registros de demanda"},
    {prodId:"41113678",label:"Probador de abrazadera"},
    {prodId:"41113679",label:"Equipo de prueba de transformador de corriente y transformador potencial"},
    {prodId:"41113680",label:"Medidor de factor de energía"},
    {prodId:"41113685",label:"Equipo para medir electricidad estática"},
    {prodId:"41114000",label:"Equipo de medida de rocas y estratos"},
    {prodId:"41114001",label:"Clinómetros"},
    {prodId:"41114100",label:"Instrumentos sismológicos"},
    {prodId:"41114102",label:"Simuladores de terremotos"},
    {prodId:"41114103",label:"Módulos de alarma sísmica"},
    {prodId:"41114104",label:"Amplificadores sísmicos"},
    {prodId:"41114105",label:"Aparatos sísmicos portátiles"},
    {prodId:"41114106",label:"Sismógrafos o grabadoras sísmicas"},
    {prodId:"41114107",label:"Sismómetros"},
    {prodId:"41114108",label:"Vibrómetros"},
    {prodId:"41114300",label:"Instrumentos hidrológicos"},
    {prodId:"41114301",label:"Medidores de corriente para corriente abierta"},
    {prodId:"41114302",label:"Instrumentos de acceso a pozos de agua"},
    {prodId:"41114303",label:"Grabadoras de nivel del agua en corrientes abiertas"},
    {prodId:"41114800",label:"Instrumentos para comprobación de cerámica y vidrio"},
    {prodId:"41114801",label:"Instrumentos para probar cerámicas"},
    {prodId:"41114802",label:"Instrumentos para probar vidrio"},
    {prodId:"41114803",label:"Instrumentos para probar alfarería"},
    {prodId:"41115504",label:"Analizadores de velocidad del sonido"},
    {prodId:"41115505",label:"Cuartos para pruebas acústicas"},
    {prodId:"41115506",label:"Generador de ruidos"},
    {prodId:"41116140",label:"Kits o suministros para parasitología o micología"},
    {prodId:"41116149",label:"Probador de lisado de limulus amebocita"},
    {prodId:"41116151",label:"Controles de calidad o calibradores o estándares para androgénesis y fertilidad"},
    {prodId:"41116300",label:"Comprobadores de punto de inflamación para laboratorio"},
    {prodId:"41116301",label:"Probadores de punto de inflamación"},
    {prodId:"41116302",label:"Probador de incombustibilidad"},
    {prodId:"41116303",label:"Probador de inflamabilidad"},
    {prodId:"41116304",label:"Probador de punto de ignición"},
    {prodId:"41121817",label:"Vidrio curvado para laboratorio"},
    {prodId:"41122300",label:"Protectores y revestimientos de banco de laboratorio"},
    {prodId:"41122301",label:"Protectores o revestimientos de mesa de trabajo"},
    {prodId:"41122408",label:"Tijeras para laboratorio"},
    {prodId:"41123100",label:"Suministros de diálisis para laboratorio"},
    {prodId:"41123101",label:"Tubos de diálisis"},
    {prodId:"41123102",label:"Pinzas de diálisis"},
    {prodId:"41123103",label:"Casete de diálisis"},
    {prodId:"41123104",label:"Membrana de diálisis"},
    {prodId:"41123105",label:"Porta casete de diálisis"},
    {prodId:"41123200",label:"Especímenes preservados y suministros"},
    {prodId:"41123201",label:"Portaobjetos preparados preservados"},
    {prodId:"41123202",label:"Animales y organismos preservados"},
    {prodId:"42142300",label:"Productos de documentación médica"},
    {prodId:"42142301",label:"Etiquetas médicas para uso general"},
    {prodId:"42142302",label:"Componentes o accesorios para sistemas de planillas médicas"},
    {prodId:"42142303",label:"Productos de identificación de pacientes"},
    {prodId:"42143300",label:"Equipo y suministros de quimioterapia"},
    {prodId:"42143301",label:"Sets o kits de administración de quimioterapia"},
    {prodId:"42143302",label:"Almohadilla de preparación para quimioterapia"},
    {prodId:"42143303",label:"Bolsa para transportar quimioterapia"},
    {prodId:"42143800",label:"Instrumentos y suministros y equipos para la medición de presión urológica"},
    {prodId:"42143801",label:"Sistemas o accesorios para cistometría"},
    {prodId:"42143802",label:"Transductores para cistometría"},
    {prodId:"42143803",label:"Catéteres uro dinámicos"},
    {prodId:"42144100",label:"Productos para drenaje de la cavidad pleural"},
    {prodId:"42144101",label:"Unidad o contenedor o accesorios para drenaje de la cavidad pléurica"},
    {prodId:"42144102",label:"Tubo de pecho"},
    {prodId:"42144103",label:"Kit de tubos de pecho"},
    {prodId:"42144200",label:"Equipo de acupuntura"},
    {prodId:"42144201",label:"Aguja de acupuntura"},
    {prodId:"42144202",label:"Bolita o semilla magnética para acupuntura"},
    {prodId:"42144203",label:"Brazalete para acu presión"},
    {prodId:"42144300",label:"Productos de contra pulsación externa"},
    {prodId:"42144301",label:"Sistema de contra pulsación externa ecp"},
    {prodId:"42144302",label:"Mangas o pantalones para contra pulsación externa ecp"},
    {prodId:"42152300",label:"Láseres y equipo de iluminación y fibra óptica para odontología, y suministros"},
    {prodId:"42152301",label:"Láseres de uso odontológico"},
    {prodId:"42152302",label:"Dentoscopios"},
    {prodId:"42152303",label:"Luces para uso odontológico general o sus accesorios"},
    {prodId:"42152304",label:"Luces intraorales"},
    {prodId:"42152305",label:"Trípodes de luces de operación para uso odontológico"},
    {prodId:"42152306",label:"Luces o accesorios de fibra óptica para uso odontológico"},
    {prodId:"42152307",label:"Sets de iluminación de operación para uso odontológico"},
    {prodId:"42152607",label:"Anillos para instrumentos de uso odontológico"},
    {prodId:"42161800",label:"Equipo y suministros de terapia renal sustitutiva continua (CRRT)"},
    {prodId:"42161801",label:"Unidades de diálisis arterio venosa continua cavhd o productos relacionados"},
    {prodId:"42161802",label:"Unidades de hemofiltración arterio venosa continua cavh o productos relacionados"},
    {prodId:"42161803",label:"Unidades de hemodiálisis o hemofiltración  venosa continua o productos relacionados"},
    {prodId:"42161804",label:"Unidades de ultrafiltración continua lenta scuf o productos relacionados"},
    {prodId:"42171500",label:"Productos de servicio médico de urgencia en la gestión de desastre"},
    {prodId:"42171501",label:"Bolsas para cuerpos para desastres para servicios médicos de emergencia"},
    {prodId:"42171502",label:"Etiquetas de triage para servicios médicos de emergencia"},
    {prodId:"42171700",label:"Mantas de servicios médicos de urgencia"},
    {prodId:"42171701",label:"Cobijas para emergencias o rescates"},
    {prodId:"42171702",label:"Cobijas para primeros auxilios"},
    {prodId:"42171703",label:"Cobertores o cobijas de protección contra el calor para servicios médicos de emergencia"},
    {prodId:"42171704",label:"Fajas para bebés o porta bebés para servicios médicos de emergencia"},
    {prodId:"42171800",label:"Equipo de servicios médicos de urgencia de gestión del vía de aire"},
    {prodId:"42171801",label:"Kits de intubación o vía aérea oro faríngea para servicios médicos de emergencia"},
    {prodId:"42171802",label:"Kits de laringoscopia para servicios médicos de emergencia"},
    {prodId:"42171803",label:"Unidades o accesorios de succión para servicios médicos de emergencia"},
    {prodId:"42171804",label:"Kits de cricotiroidotomía o tubo traqueal para servicios médicos de emergencia"},
    {prodId:"42171805",label:"Desplegadores de mandíbula"},
    {prodId:"42171806",label:"Palos para convulsiones"},
    {prodId:"42171900",label:"Cajas o bolsas de almacenaje para servicios médicos de urgencia"},
    {prodId:"42171901",label:"Bolsas de manejo de vía aérea para servicios médicos de emergencia"},
    {prodId:"42171902",label:"Estuches de desfibriladores para servicios médicos de emergencia"},
    {prodId:"42171903",label:"Estuches de medicamentos para servicios médicos de emergencia"},
    {prodId:"42171904",label:"Estuches o bolsas de productos de extracción para servicios médicos de emergencia"},
    {prodId:"42171905",label:"Estuches intravenosos iv para servicios médicos de emergencia"},
    {prodId:"42171906",label:"Estuches de intubación para servicios médicos de emergencia"},
    {prodId:"42171907",label:"Bolsas de laringoscopia para servicios médicos de emergencia"},
    {prodId:"42171908",label:"Estuches de soporte de vida para servicios médicos de emergencia"},
    {prodId:"42171909",label:"Paquetes de trauma para respuesta de larga distancia ldr para servicios médicos de emergencia"},
    {prodId:"42171910",label:"Estuches paramédicos para servicios médicos de emergencia"},
    {prodId:"42171911",label:"Estuches de oxígeno portátil o resucitación para servicios médicos de emergencia"},
    {prodId:"42171912",label:"Estuches o bolsas de rescate para servicios médicos de emergencia"},
    {prodId:"42171913",label:"Estuches o bolsas de respuesta para servicios médicos de emergencia"},
    {prodId:"42171914",label:"Estuches o bolsas de trauma para servicios médicos de emergencia"},
    {prodId:"42171915",label:"Estuches o bolsas para técnicos médicos de emergencia emt"},
    {prodId:"42171916",label:"Estuches para transportar o almacenar sets de inmovilización para servicios médicos de emergencia"},
    {prodId:"42171917",label:"Estuches o bolsas o accesorios de primeros auxilios para servicios médicos de emergencia"},
    {prodId:"42171918",label:"Estuches de equipos de tratamiento de víctimas de gas para servicios médicos de emergencia"},
    {prodId:"42171919",label:"Bolsas o revestimientos de evacuación para servicios médicos de emergencia"},
    {prodId:"42171920",label:"Estuches o accesorios de ropa para servicios médicos de emergencia"},
    {prodId:"42172100",label:"Productos de resucitación de servicios médicos de emergencia"},
    {prodId:"42172101",label:"Desfibriladores externos automatizados aed o paletas duras"},
    {prodId:"42172102",label:"Escudos o máscaras protectoras para resucitación cardiopulmonar cpr"},
    {prodId:"42172103",label:"Kits aspiradores o resucitadores para emergencias"},
    {prodId:"42172104",label:"Tablas de resucitación cardiopulmonar cpr"},
    {prodId:"42172105",label:"Accesorios para desfibriladores externos automatizados aed"},
    {prodId:"42172200",label:"Suministros de servicios médicos de emergencia"},
    {prodId:"42172201",label:"Pinzas o torniquetes para servicios médicos de emergencia"},
    {prodId:"42182500",label:"Metros de función nasal"},
    {prodId:"42182501",label:"Olfatómetros"},
    {prodId:"42182502",label:"Medidores de flujo nasal o rinoanemómetros"},
    {prodId:"42182900",label:"Mesas de examen de especialidad y productos relacionados"},
    {prodId:"42182901",label:"Mesas de examen obstétrico o ginecológico"},
    {prodId:"42182902",label:"Mesas de examen con apoya pies para examen obstétrico o ginecológico"},
    {prodId:"42182903",label:"Mesas para examen pediátrico"},
    {prodId:"42182904",label:"Mesas de retención o sistemas de medición para examen pediátrico"},
    {prodId:"42183100",label:"Medidores de la capacidad gustativa"},
    {prodId:"42183101",label:"Gustómetros"},
    {prodId:"42183200",label:"Equipo y suministros de alergología"},
    {prodId:"42183201",label:"Instrumentos o accesorios para detectar o probar alergias"},
    {prodId:"42183300",label:"Accesorios de unidades de examen de otorrinolaringología y productos relacionados"},
    {prodId:"42183301",label:"Accesorios para espejos de examen de oído nariz garganta ent"},
    {prodId:"42201600",label:"Productos de resonancia magnética (MRI) médica"},
    {prodId:"42201601",label:"Instalación de unidad estacionaria completa de imágenes de resonancia magnética mri para uso médico"},
    {prodId:"42201602",label:"Sistemas móviles o transportables o de camión de imágenes de resonancia magnética mri para uso médico"},
    {prodId:"42201603",label:"Componentes de sistema tridimensional de imágenes de resonancia magnética mri para uso médico"},
    {prodId:"42201604",label:"Simuladores o dispositivos de aseguramiento de calidad o calibración de imágenes de resonancia magnética mri para uso médico"},
    {prodId:"42201605",label:"Bobinas de imágenes de resonancia magnética mri para uso médico"},
    {prodId:"42201607",label:"Monitores de imágenes de resonancia magnética mri para uso médico"},
    {prodId:"42201608",label:"Consolas primarias o remotas o secundarias de imágenes de resonancia magnética mri para uso médico"},
    {prodId:"42201609",label:"Escáneres de imágenes de resonancia magnética mri para uso médico"},
    {prodId:"42201610",label:"Instrumentos quirúrgicos o sistemas de guía de imágenes de resonancia magnética mri para uso médico"},
    {prodId:"42201611",label:"Mesas de imágenes de resonancia magnética mri para uso médico"},
    {prodId:"42201900",label:"Fuentes de iluminación y equipo de visión de película radiográfica médica"},
    {prodId:"42201901",label:"Luces de punto caliente de película de rayos x para uso médico"},
    {prodId:"42201902",label:"Sistemas de estanterías de despliegue largas de rayos x para uso médico"},
    {prodId:"42201903",label:"Cajas de despliegue de película de rayos x para uso médico"},
    {prodId:"42201904",label:"Ventanas o pantallas iluminadoras de película de rayos x para uso médico"},
    {prodId:"42201905",label:"Estuches de transferencia de película de rayos x para uso médico"},
    {prodId:"42201906",label:"Ganchos iluminadores de película de rayos x para uso médico"},
    {prodId:"42201907",label:"Estereoscopios de película de rayos x para uso médico"},
    {prodId:"42201908",label:"Viseras para monitores fluoroscopios para uso médico"},
    {prodId:"42202000",label:"Cámaras médicas gamma de diagnóstico y productos relacionados"},
    {prodId:"42202001",label:"Cámaras gamma de uso general para uso médico"},
    {prodId:"42202002",label:"Sistemas de navegación o accesorios para mapeo linfático"},
    {prodId:"42202003",label:"Sondas para mapeo linfático"},
    {prodId:"42202004",label:"Colimadores para mapeo linfático"},
    {prodId:"42202005",label:"Paquete de procedimiento para mapeo linfático"},
    {prodId:"42202006",label:"Densitómetro óseo"},
    {prodId:"42202100",label:"Productos de Braquiterapia"},
    {prodId:"42202101",label:"Contenedores o semillas de intra cavidad para braquiterapia"},
    {prodId:"42202102",label:"Catéteres o jeringas o insertores o aplicadores para braquiterapia"},
    {prodId:"42202103",label:"Contenedores de almacenamiento de semillas para braquiterapia"},
    {prodId:"42202104",label:"Kits de captura de semillas para braquiterapia"},
    {prodId:"42202105",label:"Unidades de braquiterapia"},
    {prodId:"42202106",label:"Sistema de aplicador radionúclido manual"},
    {prodId:"42202200",label:"Productos de radiación gamma"},
    {prodId:"42202201",label:"Colimadores o cascos de cuchillo gamma para radio cirugía"},
    {prodId:"42202202",label:"Unidades o centelladores de cuchillos gamma radio quirúrgicos"},
    {prodId:"42202203",label:"Sets de almohadillas para cascos de radio cirugía"},
    {prodId:"42202204",label:"Sistema de re inhalación radionúclido"},
    {prodId:"42202205",label:"Bloque de moldeo de haz de terapia de radiación"},
    {prodId:"42202300",label:"Productos de acelerador lineal de terapia de radiación de intensidad modulada (IMRT) médica"},
    {prodId:"42202301",label:"Unidades bidimensionales de terapia de radiación de intensidad modulada de acelerador lineal imrt para uso médico"},
    {prodId:"42202302",label:"Unidades tridimensionales de terapia de radiación de intensidad modulada de acelerador lineal imrt para uso médico"},
    {prodId:"42202303",label:"Colimadores de terapia de radiación de intensidad modulada de acelerador lineal imrt para uso médico"},
    {prodId:"42202400",label:"Equipo de tomografía por emisión de positrones (PET) médica y productos relacionados"},
    {prodId:"42202401",label:"Unidades de tomografía de emisión de positrones pet para uso médico"},
    {prodId:"42202500",label:"Equipo de tomografía informatizada por emisión de fotones simples (SPECT) médica y productos relacionados"},
    {prodId:"42202501",label:"Unidades de tomografía de emisión computarizada de fotones únicos spect para uso médico"},
    {prodId:"42202600",label:"Productos de administración de radioisótopos y radioimunoterapia"},
    {prodId:"42202601",label:"Suministros para terapia de irradiación de tiroides"},
    {prodId:"42202602",label:"Kits de pruebas para radio inmunoterapia"},
    {prodId:"42202700",label:"Productos de radioterapia tele terapia"},
    {prodId:"42202701",label:"Equipo de cobalto 60 para teleterapia radioterapia"},
    {prodId:"42202702",label:"Aceleradores lineales para teleterapia radioterapia"},
    {prodId:"42202703",label:"Máquinas de rayos x de ortovoltaje para teleterapia radioterapia"},
    {prodId:"42202704",label:"Máquinas de rayos x superficiales para teleterapia radioterapia"},
    {prodId:"42202900",label:"Equipo radiográfico médico de bajo consumo eléctrico"},
    {prodId:"42202901",label:"Unidades de rayos x de baja energía para uso médico"},
    {prodId:"42203000",label:"Aceleradores médicos lineales y productos relacionados"},
    {prodId:"42203001",label:"Aceleradores lineales móviles o transportables para uso médico"},
    {prodId:"42203100",label:"Instrumentos radio biológicos"},
    {prodId:"42203101",label:"Micro dosímetros de efecto radio biológico"},
    {prodId:"42203200",label:"Simuladores de radioterapia"},
    {prodId:"42203201",label:"Simuladores de planeación de radioterapia rf de fluoroscopia y rayos x"},
    {prodId:"42203202",label:"Simuladores de radioterapia de tomografía computarizada ct o cat"},
    {prodId:"42203300",label:"Sistemas médicos estereotáctica"},
    {prodId:"42203301",label:"Sistemas de terapia estereotáctica sin marco"},
    {prodId:"42203302",label:"Marcos para la cabeza para terapia estereotáctica"},
    {prodId:"42203303",label:"Sistemas de biopsia estereotáctica"},
    {prodId:"42203800",label:"Auxilios de posicionamiento radiológico médico"},
    {prodId:"42203801",label:"Ayudas de posicionamiento para tomografía computarizada ct o cat para uso médico"},
    {prodId:"42203802",label:"Ayudas de posicionamiento para imágenes de resonancia magnética mri para uso médico"},
    {prodId:"42203803",label:"Ayudas de posicionamiento para uso radiológico general para uso médico"},
    {prodId:"42203804",label:"Ayudas de posicionamiento para terapias de radiación"},
    {prodId:"42203900",label:"Productos para controlar o detectar radiación médica"},
    {prodId:"42203901",label:"Dosímetros de radiación para uso médico"},
    {prodId:"42203902",label:"Películas o brazaletes de radiación para uso médico"},
    {prodId:"42203903",label:"Sistema de terapia de radiación de neutrones para uso médico"},
    {prodId:"42204100",label:"Equipo caliente de laboratorio y productos relacionados para medicina nuclear"},
    {prodId:"42204101",label:"Sujetador o cargador o protector para jeringas"},
    {prodId:"42211700",label:"Ayudas de comunicación para personas físicamente discapacitadas"},
    {prodId:"42211701",label:"Interruptores de comunicación adaptativa para los discapacitados físicamente"},
    {prodId:"42211702",label:"Dispositivos braille para los discapacitados físicamente"},
    {prodId:"42211703",label:"Papel o plástico para escribir braille para los discapacitados físicamente"},
    {prodId:"42211704",label:"Señaladores de cabeza o palitos para la boca para los discapacitados físicamente"},
    {prodId:"42211705",label:"Ayudas auditivas para los discapacitados físicamente"},
    {prodId:"42211706",label:"Tableros de letras o símbolos para los discapacitados físicamente"},
    {prodId:"42211707",label:"Dispositivos de telecomunicación tdd o teletipos tty para los discapacitados físicamente"},
    {prodId:"42211708",label:"Ayudas telefónicas para los discapacitados físicamente"},
    {prodId:"42211709",label:"Ayudas de mecanografía para los discapacitados físicamente"},
    {prodId:"42211710",label:"Ayudas de escritura para los discapacitados físicamente"},
    {prodId:"42211711",label:"Sintetizadores de voz para los discapacitados físicamente"},
    {prodId:"42211712",label:"Estuches para ayudas auditivas"},
    {prodId:"42211914",label:"Ayudas para medir para los discapacitados físicamente"},
    {prodId:"42211915",label:"Auto alimentadores o accesorios para los discapacitados físicamente"},
    {prodId:"42211916",label:"Pitillos o sujeta pitillos para los discapacitados físicamente"},
    {prodId:"42211917",label:"Vajillas para los discapacitados físicamente"},
    {prodId:"42211918",label:"Balanzas de comida habladoras para los discapacitados físicamente"},
    {prodId:"42212000",label:"Cuidado de la casa y ayuda para el cuidado de la casa para personas físicamente discapacitadas"},
    {prodId:"42212001",label:"Abridores de puertas para los discapacitados físicamente"},
    {prodId:"42212002",label:"Dispositivos para echar llave o sujetadores para los discapacitados físicamente"},
    {prodId:"42212003",label:"Dispositivos para abrir puertas para los discapacitados físicamente"},
    {prodId:"42212004",label:"Extensiones de interruptores de luz para los discapacitados físicamente"},
    {prodId:"42212005",label:"Plumeros o cepillos de mango largo para los discapacitados físicamente"},
    {prodId:"42212006",label:"Tijeras que se abren solas para los discapacitados físicamente"},
    {prodId:"42212007",label:"Cepillos de succión para los discapacitados físicamente"},
    {prodId:"42212100",label:"Aparatos de tiempo libre y recreo para personas físicamente discapacitadas"},
    {prodId:"42212101",label:"Barajadores de naipe automáticos para los discapacitados físicamente"},
    {prodId:"42212102",label:"Juegos de mesa para los discapacitados físicamente"},
    {prodId:"42212103",label:"Sujetadores de libros para los discapacitados físicamente"},
    {prodId:"42212104",label:"Naipes de figuras grandes o braille para los discapacitados físicamente"},
    {prodId:"42212105",label:"Equipo de camping para los discapacitados físicamente"},
    {prodId:"42212106",label:"Ayudas de pesca o cacería para los discapacitados físicamente"},
    {prodId:"42212107",label:"Ayudas de flotación o natación para los discapacitados físicamente"},
    {prodId:"42212108",label:"Herramientas de jardinería para los discapacitados físicamente"},
    {prodId:"42212109",label:"Herramientas o materiales o equipos de manualidades para los discapacitados físicamente"},
    {prodId:"42212110",label:"Volteadores de páginas para los discapacitados físicamente"},
    {prodId:"42212111",label:"Sujetadores de naipes para los discapacitados físicamente"},
    {prodId:"42212112",label:"Ayudas de costura para los discapacitados físicamente"},
    {prodId:"42212113",label:"Ayudas para fumar para los discapacitados físicamente"},
    {prodId:"42221800",label:"Aparatos para posicionar aguja y catéter intravenoso o arterial"},
    {prodId:"42221801",label:"Forros para tablas de brazo arterial o intravenoso"},
    {prodId:"42221802",label:"Tablas de brazo arterial o intravenoso"},
    {prodId:"42221803",label:"Cintas o vendajes o correas o mangas para posicionamiento de catéter arterial o intravenoso"},
    {prodId:"42222200",label:"Sistemas de inyectar y retirar intravenosos sin aguja"},
    {prodId:"42222201",label:"Sets de jeringas de inyección intravenosa sin aguja o cánula de inyección"},
    {prodId:"42222202",label:"Cánulas o adaptadores o decantadores para el retiro de vial o bolsa sin aguja"},
    {prodId:"42231700",label:"Tubos naso-entéricos"},
    {prodId:"42231701",label:"Tubos nasogástricos"},
    {prodId:"42231702",label:"Tubos nasoyeyunales"},
    {prodId:"42231703",label:"Tubos de descompresión gástrica"},
    {prodId:"42231704",label:"Tubos naso entéricos no clasificados en otra parte"},
    {prodId:"42231705",label:"Filtros nasogástricos"},
    {prodId:"42241900",label:"Suministros de tablillas dinámicas y batangas"},
    {prodId:"42241901",label:"Kits de tablillas estabilizadoras"},
    {prodId:"42241902",label:"Partes o accesorios de tablillas estabilizadoras"},
    {prodId:"42251609",label:"Patinetas o patines de ocho figuras para rehabilitación o terapia"},
    {prodId:"42251621",label:"Ejercitadoras de bota para rehabilitación o terapia"},
    {prodId:"42251622",label:"Ejercitadoras de pulmón para rehabilitación o terapia"},
    {prodId:"42251623",label:"Aparatos o suministros para terapia ultrasónica"},
    {prodId:"42251624",label:"Máquinas de pesas para rehabilitación o terapia"},
    {prodId:"42251625",label:"Equipos de ejercicio de mandíbulas"},
    {prodId:"42251704",label:"Barras de manera de caminar para rehabilitación o terapia"},
    {prodId:"42261500",label:"Suministros y instrumentos para disección de patología"},
    {prodId:"42261501",label:"Tijeras para autopsias"},
    {prodId:"42261502",label:"Fórceps de disección de uso general para autopsias"},
    {prodId:"42261503",label:"Sondas de bala para autopsias"},
    {prodId:"42261504",label:"Jaladores de hilo o aguja para autopsias"},
    {prodId:"42261505",label:"Cuchillos o cuchillas para autopsias"},
    {prodId:"42261506",label:"Cinceles u osteotomos para autopsias"},
    {prodId:"42261507",label:"Hilo post mortem"},
    {prodId:"42261508",label:"Agujas post mortem"},
    {prodId:"42261509",label:"Kits de disección para autopsias"},
    {prodId:"42261510",label:"Ganchos de incisión post mortem"},
    {prodId:"42261511",label:"Directores de vena para autopsias"},
    {prodId:"42261512",label:"Sierras para autopsias"},
    {prodId:"42261513",label:"Cuchillas de sierra o accesorios para autopsias"},
    {prodId:"42261514",label:"Tablas o almohadillas de disección"},
    {prodId:"42261515",label:"Estuches para instrumentos o accesorios quirúrgicos post mortem"},
    {prodId:"42261516",label:"Rollos de instrumentos para instrumentos o accesorios quirúrgicos post mortem"},
    {prodId:"42261700",label:"Muebles de autopsia"},
    {prodId:"42261701",label:"Estaciones de trabajo para autopsia macroscópica o accesorios"},
    {prodId:"42261702",label:"Lavaderos o accesorios para autopsias"},
    {prodId:"42261703",label:"Tablas o accesorios para autopsias"},
    {prodId:"42261704",label:"Tablas para necropsia o accesorios"},
    {prodId:"42261705",label:"Tablas o accesorios para disección de animales post mortem"},
    {prodId:"42261706",label:"Estaciones de trabajo para embalsamar o accesorios"},
    {prodId:"42261707",label:"Estaciones de trabajo para autopsias de drenaje o accesorios"},
    {prodId:"42261802",label:"Transportadores de cadáveres"},
    {prodId:"42261803",label:"Poleas de elevación de tijeras para cadáveres"},
    {prodId:"42261804",label:"Gabinetes refrigeradores para las morgues"},
    {prodId:"42261808",label:"Bandejas para cadáveres"},
    {prodId:"42261900",label:"Equipo y suministros forenses clínicos"},
    {prodId:"42261901",label:"Materiales de huellas dactilares o impresión post mortem"},
    {prodId:"42262000",label:"Equipo y suministros de embalsamar"},
    {prodId:"42262001",label:"Inyectores de cavidad para embalsamar"},
    {prodId:"42262002",label:"Tubos de drenaje de venas para embalsamar"},
    {prodId:"42262003",label:"Fluidos para embalsamar o tratamientos químicos"},
    {prodId:"42262004",label:"Tubos de inyección para embalsamar"},
    {prodId:"42262005",label:"Lavaderos o accesorios para embalsamar"},
    {prodId:"42262006",label:"Kits para embalsamar"},
    {prodId:"42262007",label:"Agujas inyectoras para embalsamar"},
    {prodId:"42262100",label:"Equipo y suministros funerarios"},
    {prodId:"42262101",label:"Trajes mortuorios"},
    {prodId:"42262102",label:"Paquetes mortuorios"},
    {prodId:"42262103",label:"Lienzos mortuorios"},
    {prodId:"42262104",label:"Aspiradoras mortuorias"},
    {prodId:"42262105",label:"Compuestos endurecedores mortuorios"},
    {prodId:"42272100",label:"Ventiladores de presión mecánica negativa"},
    {prodId:"42272101",label:"Pulmón de hierro"},
    {prodId:"42272102",label:"Productos de coraza de pecho"},
    {prodId:"42291900",label:"Posicionadores y portadores de tubos y instrumentos quirúrgicos"},
    {prodId:"42291901",label:"Sujetadores o posicionadores de instrumentos quirúrgicos"},
    {prodId:"42291902",label:"Sujetadores o posicionadores de tubos para uso quirúrgico"},
    {prodId:"42292000",label:"Espejos quirúrgicos"},
    {prodId:"42292001",label:"Espejos de otolaringología o accesorios para uso quirúrgico"},
    {prodId:"42292200",label:"Aproximadores y compresores y depresores quirúrgicos y productos relacionados"},
    {prodId:"42292201",label:"Aproximadores para uso quirúrgico"},
    {prodId:"42292202",label:"Compresores para uso quirúrgico"},
    {prodId:"42292203",label:"Depresores para uso quirúrgico"},
    {prodId:"42292400",label:"Machos de roscar y impulsores quirúrgicos y productos relacionados"},
    {prodId:"42292401",label:"Roscas para uso quirúrgico"},
    {prodId:"42292402",label:"Controladores o sus partes o accesorios para uso quirúrgico"},
    {prodId:"42292403",label:"Mangos de hachas para uso quirúrgico"},
    {prodId:"42292500",label:"Martillos y malletes y impactadores y prensas quirúrgicos y productos relacionados"},
    {prodId:"42292501",label:"Tapones para uso quirúrgico"},
    {prodId:"42292502",label:"Martillos o martillos quirúrgicos para uso quirúrgico"},
    {prodId:"42292503",label:"Impactadores o empacadores para uso quirúrgico"},
    {prodId:"42292504",label:"Prensas para uso quirúrgico"},
    {prodId:"42292505",label:"Martillos o cabezas de martillo para uso quirúrgico"},
    {prodId:"42293000",label:"Dispositivos quirúrgicos de medir y productos relacionados"},
    {prodId:"42293001",label:"Calibradores o reglas para uso quirúrgico"},
    {prodId:"42293002",label:"Sondas o varas de medición para uso quirúrgico"},
    {prodId:"42293003",label:"Instrumentos de medición de ganchos para uso quirúrgico"},
    {prodId:"42293004",label:"Instrumentos quirúrgicos de determinación de tamaño para uso general"},
    {prodId:"42293005",label:"Instrumentos de determinación de tamaño de válvula para uso quirúrgico"},
    {prodId:"42293006",label:"Cintas de medición para uso quirúrgico"},
    {prodId:"42293200",label:"Instrumentos quirúrgicos de mioma"},
    {prodId:"42293201",label:"Destornilladores para miomas para uso quirúrgico"},
    {prodId:"42293400",label:"Manipuladores y posicionadores quirúrgicos de implante y productos relacionados"},
    {prodId:"42293401",label:"Guías para uso quirúrgico"},
    {prodId:"42293403",label:"Sujetadores de implantes para uso quirúrgico"},
    {prodId:"42293404",label:"Empujadores para uso quirúrgico"},
    {prodId:"42293405",label:"Instrumentos de manipulación para uso quirúrgico"},
    {prodId:"42293406",label:"Posicionadores de implantes para uso quirúrgico"},
    {prodId:"42293407",label:"Filiforme para dilatar el uréter o la uretra"},
    {prodId:"42293408",label:"Seguidor para uréter o uretra"},
    {prodId:"42293700",label:"Machacadoras y excavadoras y fragmentadoras quirúrgicas y productos relacionados"},
    {prodId:"42293701",label:"Trituradores para uso quirúrgico"},
    {prodId:"42293702",label:"Excavadoras para uso quirúrgico"},
    {prodId:"42293703",label:"Molinos de hueso para uso quirúrgico"},
    {prodId:"42293800",label:"Agarradores y buscadores y excavadores y desmoldadores quirúrgicos y productos relacionados"},
    {prodId:"42293801",label:"Pasadores para uso quirúrgico"},
    {prodId:"42293802",label:"Buscadores para uso quirúrgico"},
    {prodId:"42293803",label:"Removedores para uso quirúrgico"},
    {prodId:"42293804",label:"Excavadoras o accesorios quirúrgicos."},
    {prodId:"42293900",label:"Instrumentos quirúrgicos para empaque de heridas y productos relacionados"},
    {prodId:"42293901",label:"Anillos de laparoscopia para uso quirúrgico"},
    {prodId:"42293902",label:"Instrumentos para cubrir heridas para uso quirúrgico"},
    {prodId:"42294100",label:"Dispositivos esqueléticos quirúrgicos de tracción y productos relacionados"},
    {prodId:"42294101",label:"Lazos de tracción o bucles de tracción o productos relacionados para uso quirúrgico"},
    {prodId:"42294102",label:"Dispositivos de tracción del cráneo para uso quirúrgico o productos relacionados"},
    {prodId:"42294103",label:"Collares de tracción para uso quirúrgico"},
    {prodId:"42294300",label:"Instrumentos de biopsia de invasiva mínima del pecho y suministros y equipo"},
    {prodId:"42294301",label:"Conductores o módulos de disparo o accesorios para biopsia de seno mínimamente invasiva"},
    {prodId:"42294302",label:"Unidades de carga premium para biopsia de seno mínimamente invasiva"},
    {prodId:"42294303",label:"Guías de aguja para biopsia de seno mínimamente invasiva"},
    {prodId:"42294304",label:"Instrumentos marcadores para biopsia de seno mínimamente invasiva"},
    {prodId:"42294305",label:"Unidades de vacío o accesorios para biopsia de seno mínimamente invasiva"},
    {prodId:"42294306",label:"Aguja de localización de seno"},
    {prodId:"42294400",label:"Sistemas cardiacos y vasculares"},
    {prodId:"42294401",label:"Sistemas de cosecha de venas"},
    {prodId:"42294402",label:"Sistemas de visualización coronaria"},
    {prodId:"42294600",label:"Productos de autotransfusión"},
    {prodId:"42294601",label:"Bolsas de autotransfusión o transferencia de sangre"},
    {prodId:"42294602",label:"Kits de contenedores de autotransfusión o kits centrífugos"},
    {prodId:"42294603",label:"Unidades de autotransfusión"},
    {prodId:"42294604",label:"Filtros de autotransfusión"},
    {prodId:"42294605",label:"Reservorios o sus accesorios de autotransfusión"},
    {prodId:"42294606",label:"Sets o kits de tubos de autotransfusión"},
    {prodId:"42294607",label:"Válvulas de autotransfusión"},
    {prodId:"42294711",label:"Hemoconcentradores de perfusión o accesorios"},
    {prodId:"42295300",label:"Suministros y accesorios para cirugía de corazón abierto y productos relacionados"},
    {prodId:"42295301",label:"Sopladores o vaporizadores o accesorios de uso quirúrgico"},
    {prodId:"42295302",label:"Cánulas de perfusión de uso quirúrgico"},
    {prodId:"42295303",label:"Catéteres o conectores o accesorios de uso quirúrgico"},
    {prodId:"42295304",label:"Almohadillas para nervios frénicos o almohadas cardíacas de uso quirúrgico"},
    {prodId:"42295305",label:"Torniquetes u oclusores vasculares o ligantes o accesorios de uso quirúrgico"},
    {prodId:"42295306",label:"Lanzaderas de vasos o cintas de retracción de uso quirúrgico"},
    {prodId:"42295307",label:"Decapantes de arteria carótida o accesorios de uso quirúrgico"},
    {prodId:"42295308",label:"Sets de perfusión coronaria"},
    {prodId:"42295600",label:"Productos y accesorios de drenaje de fluido cerebroespinal"},
    {prodId:"42295601",label:"Catéter o adaptador de drenaje ventricular cerebral"},
    {prodId:"42295602",label:"Bolsa o reservorio de drenaje ventricular cerebral externa"},
    {prodId:"42295603",label:"Drenaje de fluido cerebroespinal lumbar o kit"},
    {prodId:"42295803",label:"Catéteres de ablación térmica para uso quirúrgico"},
    {prodId:"42295900",label:"Stents quirúrgicos"},
    {prodId:"42295901",label:"Stents gastrointestinales"},
    {prodId:"42295902",label:"Stents traqueales"},
    {prodId:"42295904",label:"Stents uterinos o vaginales"},
    {prodId:"42295905",label:"Endoprótesis o stents de pared o tubos"},
    {prodId:"42295906",label:"Stents esofágicos"},
    {prodId:"42296100",label:"Implantes neuroquirúrgicos"},
    {prodId:"42296101",label:"Clip implantable para aneurisma"},
    {prodId:"42296102",label:"Clip temporal para aneurisma"},
    {prodId:"42296103",label:"Reemplazo dural o dispositivo de reparación"},
    {prodId:"42296104",label:"Placa craneal o cubierta de orificio de broca"},
    {prodId:"42296105",label:"Malla craneal"},
    {prodId:"42296106",label:"Gancho de reparación de nervios"},
    {prodId:"42296107",label:"Pinza craneal"},
    {prodId:"42296200",label:"Equipo y accesorios de robótica quirúrgica y productos relacionados"},
    {prodId:"42296201",label:"Instrumentos robóticos de aplicación de clips para uso quirúrgico"},
    {prodId:"42296202",label:"Instrumentos robóticos de disección para uso quirúrgico"},
    {prodId:"42296203",label:"Instrumentos robóticos de electro cauterización para uso quirúrgico"},
    {prodId:"42296204",label:"Agarradores o fórceps robóticos para uso quirúrgico"},
    {prodId:"42296205",label:"Instrumentos robóticos de gancho para uso quirúrgico"},
    {prodId:"42296206",label:"Empujador de nudos o sistema de entrega robótico para uso quirúrgico"},
    {prodId:"42296207",label:"Conductor de aguja robótico para uso quirúrgico"},
    {prodId:"42296208",label:"Instrumento robótico de retracción para uso quirúrgico"},
    {prodId:"42296209",label:"Escalpelo y cuchilla robótica para uso quirúrgico"},
    {prodId:"42296210",label:"Tijeras robóticas para uso quirúrgico"},
    {prodId:"42296211",label:"Instrumento robótico de energía ultrasónica para uso quirúrgico"},
    {prodId:"42296212",label:"Trocar o funda u obturador o cánula robótica para uso quirúrgico"},
    {prodId:"42311600",label:"Agentes hemoestáticos tópicos exógenos"},
    {prodId:"42311601",label:"Esponjas de gelatina absorbible"},
    {prodId:"42311602",label:"Cera para huesos"},
    {prodId:"42311603",label:"Celulosa oxidada"},
    {prodId:"42311604",label:"Hemostáticos de colágeno micro fibrilar"},
    {prodId:"42311605",label:"Parche no absorbible de trombina"},
    {prodId:"42311900",label:"Drenajes médicos de incisión y bolsas de drenaje y depósitos y productos relacionados"},
    {prodId:"42311901",label:"Accesorios de drenaje para uso médico"},
    {prodId:"42311902",label:"Bolsas o reservorios de drenaje de incisiones para uso médico"},
    {prodId:"42311903",label:"Drenajes de incisión para uso médico"},
    {prodId:"42312700",label:"Productos de terapia de oxígeno para heridas tópicas"},
    {prodId:"42312701",label:"Cámara de uso único de terapia de oxígeno para heridas tópicas"},
    {prodId:"42322100",label:"Implantes ortopédicos de prueba"},
    {prodId:"42322101",label:"Implante de ensayo de rodilla"},
    {prodId:"42322102",label:"Implante de ensayo de cadera"},
    {prodId:"42322103",label:"Implante de ensayo de hombro"},
    {prodId:"42322104",label:"V de articulación distal"},
    {prodId:"42322105",label:"Implante de ensayo de columna"},
    {prodId:"42322106",label:"Implante de ensayo de trauma"},
    {prodId:"43212103",label:"Impresoras de sublimación de teñido"},
    {prodId:"43221600",label:"Componentes y equipo de acceso de abonado de línea digital (DSL) y accesorios"},
    {prodId:"43221601",label:"Separador de sistema de antiguo teléfono simple de empresa cautiva pots de circuito de subscriptor digital dsl"},
    {prodId:"43221602",label:"Estante para separador de empresa cautiva de circuito de subscriptor digital dsl"},
    {prodId:"43221603",label:"Separador de sistema de antiguo teléfono simple pots de equipo de premisa de cliente cpe de circuito de subscriptor digital dsl"},
    {prodId:"43221604",label:"Equipo de circuito o línea de subscriptor digital xdsl"},
    {prodId:"43223000",label:"Equipo de teletipo"},
    {prodId:"43223001",label:"Dispositivos de entrada de teletipos"},
    {prodId:"43223200",label:"Plataformas de mensajería por móvil"},
    {prodId:"43223201",label:"Portal de mensajes de voz"},
    {prodId:"43223202",label:"Centro de servicio de mensajes cortos"},
    {prodId:"43223203",label:"Centros de servicio multimedia"},
    {prodId:"43223204",label:"Plataforma de mensajes unificados"},
    {prodId:"43223205",label:"Plataforma de mensajes instantáneos"},
    {prodId:"43223206",label:"Puerta de enlace de internet inalámbrico"},
    {prodId:"43223207",label:"Sistema de flujo de video"},
    {prodId:"43223208",label:"Plataforma de juegos móvil o de mensajes"},
    {prodId:"43223209",label:"Plataforma de servicios de mensajes basados en locación"},
    {prodId:"43223210",label:"Sistemas de mensajes de micro pagos"},
    {prodId:"43223211",label:"Controladores de buscapersonas"},
    {prodId:"43223212",label:"Terminales de buscapersonas"},
    {prodId:"43232100",label:"Software de edición y creación de contenidos"},
    {prodId:"43232101",label:"Software de diseño de patrones"},
    {prodId:"43232102",label:"Software de imágenes gráficas o de fotografía"},
    {prodId:"43232103",label:"Software de creación y edición de video"},
    {prodId:"43232104",label:"Software de procesamiento de palabras"},
    {prodId:"43232105",label:"Software de gráficas"},
    {prodId:"43232106",label:"Software de presentación"},
    {prodId:"43232107",label:"Software de creación y edición de páginas web"},
    {prodId:"43232108",label:"Software de calendario y programación de citas"},
    {prodId:"43232110",label:"Software de hoja de cálculo"},
    {prodId:"43232111",label:"Software de lector de caracteres ópticos ocr o de escáner"},
    {prodId:"43232112",label:"Software de autoedición"},
    {prodId:"43232200",label:"Software de gestión de contenidos"},
    {prodId:"43232201",label:"Software de flujo de trabajo de contenido"},
    {prodId:"43232202",label:"Software de manejo de documentos"},
    {prodId:"43232203",label:"Software de versiones de archivo"},
    {prodId:"43232204",label:"Software de ingreso de texto incrustado"},
    {prodId:"43232205",label:"Software de tipos de letra"},
    {prodId:"43232611",label:"Software de puntos de venta pos"},
    {prodId:"43232612",label:"Software de fabricación asistida por computador cam"},
    {prodId:"43232613",label:"Software de sistema de ejecución de fabricación mes"},
    {prodId:"43232614",label:"Sistema de fabricación asistida por computador cam y de diseño asistido por computador cad"},
    {prodId:"43232700",label:"Software de aplicaciones de red"},
    {prodId:"43232701",label:"Software de servidor de aplicaciones"},
    {prodId:"43232702",label:"Software de comunicaciones de escritorio"},
    {prodId:"43232703",label:"Software de respuesta de voz interactiva"},
    {prodId:"43232704",label:"Software de servicios de directorio por internet"},
    {prodId:"43232705",label:"Software de navegador de internet"},
    {prodId:"43232800",label:"Software de administración de redes"},
    {prodId:"43232801",label:"Software de monitoreo de red"},
    {prodId:"43232802",label:"Software de optimización del sistema operativo de red"},
    {prodId:"43232803",label:"Software de manejo de red óptica"},
    {prodId:"43232804",label:"Software de administración"},
    {prodId:"43232805",label:"Software de subsistema de multimedia de protocolo de internet ip"},
    {prodId:"43232900",label:"Software para trabajo en redes"},
    {prodId:"43232901",label:"Software de acceso"},
    {prodId:"43232902",label:"Software de servidor de comunicaciones"},
    {prodId:"43232903",label:"Software de centro de contactos"},
    {prodId:"43232904",label:"Software de fax"},
    {prodId:"43232905",label:"Software de lan"},
    {prodId:"43232906",label:"Software de multiplexor"},
    {prodId:"43232907",label:"Software de almacenamiento de red"},
    {prodId:"43232908",label:"Software de interruptor o enrutador"},
    {prodId:"43232909",label:"Software y firmware de interruptor wan"},
    {prodId:"43232910",label:"Software inalámbrico"},
    {prodId:"43232911",label:"Software de emulación de terminal de conectividad de red"},
    {prodId:"43232912",label:"Software de puerta de acceso"},
    {prodId:"43232913",label:"Software de puente"},
    {prodId:"43232914",label:"Software de módem"},
    {prodId:"43232915",label:"Software de interconectividad de plataformas"},
    {prodId:"43232916",label:"Software irda de transferencia de información infrarrojo"},
    {prodId:"43233000",label:"Software de entorno operativo"},
    {prodId:"43233001",label:"Software de sistema de archivo"},
    {prodId:"43233002",label:"Software de sistema de operación de red"},
    {prodId:"43233004",label:"Software de sistema operativo"},
    {prodId:"43233005",label:"Firmware de computador"},
    {prodId:"43233500",label:"Software de intercambio de información"},
    {prodId:"43233501",label:"Software de correo electrónico"},
    {prodId:"43233502",label:"Software de video conferencias"},
    {prodId:"43233503",label:"Software de conferencias de red"},
    {prodId:"43233504",label:"Software de mensajería instantánea"},
    {prodId:"43233505",label:"Software de música ambiental o publicidad para mensajería"},
    {prodId:"43233506",label:"Software de creación de mapas"},
    {prodId:"43233507",label:"Software estándar específico para operadores de móviles"},
    {prodId:"43233508",label:"Software de aplicación específica para operadores de móviles"},
    {prodId:"43233509",label:"Software de servicios de mensajería para móviles"},
    {prodId:"43233510",label:"Software de servicios de internet para móviles"},
    {prodId:"43233511",label:"Software de servicios basados en ubicación para móviles"},
    {prodId:"43233512",label:"Software de tonos de timbre"},
    {prodId:"43233700",label:"Software de administración de sistemas"},
    {prodId:"43233701",label:"Software de manejo de sistemas de empresas"},
    {prodId:"44102100",label:"Máquinas de correo"},
    {prodId:"44102101",label:"Máquinas de franqueo o estampillado"},
    {prodId:"44102102",label:"Máquinas para abrir correspondencia"},
    {prodId:"44102103",label:"Máquinas para sellar correspondencia"},
    {prodId:"44102104",label:"Máquinas para cancelar estampillas"},
    {prodId:"44102105",label:"Máquinas para direcciones"},
    {prodId:"44102106",label:"Dobladores de cartas"},
    {prodId:"44102107",label:"Colocadores de estampillas"},
    {prodId:"44102108",label:"Accesorios para máquinas de correo"},
    {prodId:"44102109",label:"Máquina automática para postales o correspondencia"},
    {prodId:"44102200",label:"Accesorios para escáneres"},
    {prodId:"44102201",label:"Endosador"},
    {prodId:"44102202",label:"Alimentadores de documentos para escáneres"},
    {prodId:"44102203",label:"Adaptadores de transparencia para escáneres"},
    {prodId:"44102500",label:"Máquinas clasificadoras"},
    {prodId:"44102501",label:"Máquinas contadoras de dinero"},
    {prodId:"44102502",label:"Sorteadoras"},
    {prodId:"44102503",label:"Máquinas para envolver monedas"},
    {prodId:"44103204",label:"Estantes para tarjetas de tiempo"},
    {prodId:"44112004",label:"Planeadores de reuniones"},
    {prodId:"45101800",label:"Equipo y accesorios para el cosido y encuadernación de libros"},
    {prodId:"45101801",label:"Máquinas para arrugas de los libros"},
    {prodId:"45101802",label:"Máquinas cortadoras de libros"},
    {prodId:"45101803",label:"Máquinas perforadoras de libros"},
    {prodId:"45101804",label:"Máquinas cosedoras de libros"},
    {prodId:"45101805",label:"Máquinas de emparejamiento de libros"},
    {prodId:"45101806",label:"Máquinas recogedoras de libros"},
    {prodId:"45101807",label:"Máquinas dobladoras de libros"},
    {prodId:"45101808",label:"Máquinas de encuadernado térmico de libros"},
    {prodId:"45102000",label:"Máquinas y accesorios de composición"},
    {prodId:"45102001",label:"Máquinas de composición de intertipos"},
    {prodId:"45102002",label:"Máquinas de composición de linotipos"},
    {prodId:"45102003",label:"Materiales de fotocomposición"},
    {prodId:"45102004",label:"Máquinas de composición de monotipos"},
    {prodId:"45102005",label:"Máquinas de configuración de fototipos"},
    {prodId:"45112000",label:"Equipo y suministros de microfilm"},
    {prodId:"45112001",label:"Dispositivos para ver micro fichas o micro cintas"},
    {prodId:"45112002",label:"Impresoras lectoras de micro fichas"},
    {prodId:"45112003",label:"Componentes o accesorios de micro fichas o micro cintas"},
    {prodId:"45112004",label:"Componentes o accesorios de impresoras lectoras de micro fichas"},
    {prodId:"45121800",label:"Equipo y suministros de producción de microfilmes"},
    {prodId:"45121801",label:"Cámaras de micro filmado"},
    {prodId:"45121802",label:"Duplicadores de micro filmado"},
    {prodId:"45121803",label:"Filtros de cubierta de micro filmado"},
    {prodId:"45121804",label:"Procesadores de micro filmado"},
    {prodId:"45121805",label:"Componentes o accesorios de cámara de micro filmado"},
    {prodId:"45121806",label:"Componentes o accesorios de duplicador de micro filmado"},
    {prodId:"45121807",label:"Componentes o accesorios de filtro de cubierta de micro filmado"},
    {prodId:"45121808",label:"Componentes o accesorios de procesadores de micro filmado"},
    {prodId:"45121809",label:"Suministros de película de micro filmado"},
    {prodId:"45121810",label:"Componentes o accesorios diversos de micro filmado"},
    {prodId:"45131600",label:"Medios cinematográficos"},
    {prodId:"45131601",label:"Película de cámara de películas animadas"},
    {prodId:"45131604",label:"Cintas de video en blanco"},
    {prodId:"45141600",label:"Suministros de cuarto oscuro"},
    {prodId:"45141601",label:"Bandejas de revelado"},
    {prodId:"45141602",label:"Tanques de revelado"},
    {prodId:"45141603",label:"Pinzas de revelado"},
    {prodId:"45141604",label:"Procesador de impresión fotográfica"},
    {prodId:"45141605",label:"Purificador de fotografías"},
    {prodId:"45141606",label:"Bobina de tanque de revelado"},
    {prodId:"45141607",label:"Cámara oscura"},
    {prodId:"45141608",label:"Escurridor de película"},
    {prodId:"45141609",label:"Mesa de trabajo de máquina reveladora de película"},
    {prodId:"45141610",label:"Temporizador fotográfico"},
    {prodId:"45141611",label:"Regulador de temperatura para procesamiento fotográfico"},
    {prodId:"45141612",label:"Cuarto oscuro portátil"},
    {prodId:"46101700",label:"Sistemas de manipulación de munición"},
    {prodId:"46101701",label:"Sistemas de manipulación de municiones de tanque"},
    {prodId:"46101702",label:"Sistemas de manipulación de municiones de aeronaves"},
    {prodId:"46101703",label:"Contenedor de explosivos"},
    {prodId:"46151800",label:"Equipo y accesorios y suministros de control de explosivos"},
    {prodId:"46151801",label:"Contenedor de protección de onda expansiva"},
    {prodId:"47111700",label:"Equipo de limpieza en seco"},
    {prodId:"47111701",label:"Máquinas de lavado en seco"},
    {prodId:"47131903",label:"Compuesto taponador"},
    {prodId:"48101900",label:"Tazas para servicio de mesa"},
    {prodId:"48101901",label:"Vajilla fina para servicio de comidas"},
    {prodId:"48101902",label:"Cubertería para servicio de comidas"},
    {prodId:"48101903",label:"Vasos para servicio de comidas"},
    {prodId:"48101904",label:"Copas para servicio de comidas"},
    {prodId:"48101905",label:"Tazas o tazones (mugs) para servicio de comidas"},
    {prodId:"48101906",label:"Canastas para servir para servicio de comidas"},
    {prodId:"48101907",label:"Jarras para servicio de comidas"},
    {prodId:"48101908",label:"Ollas para servicio de comidas"},
    {prodId:"48101909",label:"Teteras o cafeteras para servicio de comidas"},
    {prodId:"48101910",label:"Vasijas de barro para servicio de comidas"},
    {prodId:"48101911",label:"Baldes de hielo o baldes para enfriar el vino para servicio de comidas"},
    {prodId:"48101912",label:"Dispensadores de condimentos para servicio de comidas"},
    {prodId:"48101913",label:"Tazones de ponche para servicio de comidas"},
    {prodId:"48101914",label:"Botellones de vino para servicio de comidas"},
    {prodId:"48101915",label:"Bandejas para servicio de comidas"},
    {prodId:"48101916",label:"Dispensadores de servilletas para servicio de comidas"},
    {prodId:"48101917",label:"Aparatos de fondue para servicio de comidas"},
    {prodId:"48101918",label:"Rollos para cubrir la mesa para servicio de comidas"},
    {prodId:"48101919",label:"Vasos o tazas o tazones (mugs) o tapas de contenedores para servicio de comidas"},
    {prodId:"48101920",label:"Dispensadores de pitillos"},
    {prodId:"48111200",label:"Máquinas expendedoras de alimentos mandados hacer"},
    {prodId:"48111201",label:"Máquinas expendedoras de papas a la francesa"},
    {prodId:"48111202",label:"Máquinas expendedoras de maíz pira"},
    {prodId:"48111300",label:"Máquinas expendedoras servicios y boletas"},
    {prodId:"48111301",label:"Máquinas expendedoras de boletas"},
    {prodId:"48111302",label:"Máquinas expendedoras de pólizas de seguros"},
    {prodId:"48111303",label:"Máquinas de estampillas"},
    {prodId:"48111304",label:"Máquina automática de verificación de tiquete y cobro"},
    {prodId:"48111305",label:"Máquinas automáticas de cambio de moneda"},
    {prodId:"48111306",label:"Sistema de manejo de clientes para restaurantes"},
    {prodId:"48121300",label:"Gestión de Juego y sistemas de apuestas en red"},
    {prodId:"48121301",label:"Sistemas de manejo de mesas de juego"},
    {prodId:"48121302",label:"Juegos de apuestas en red"},
    {prodId:"49141507",label:"Trajes secos"},
    {prodId:"49151500",label:"Equipos de esquí y snowboard"},
    {prodId:"49151501",label:"Botas de esquí"},
    {prodId:"49151502",label:"Esquís"},
    {prodId:"49151503",label:"Varillas de esquí"},
    {prodId:"49151504",label:"Amarres"},
    {prodId:"49151505",label:"Tablas de esquí en nieve"},
    {prodId:"49151506",label:"Guantes de esquí"},
    {prodId:"49151600",label:"Equipos de patinaje y hockey sobre hielo"},
    {prodId:"49151601",label:"Discos de hockey"},
    {prodId:"49151602",label:"Patines de hielo"},
    {prodId:"49151603",label:"Palos de hockey"},
    {prodId:"49181604",label:"Dardos"},
    {prodId:"49211700",label:"Equipo de bolos y accesorios y suministros"},
    {prodId:"49211701",label:"Equipo de bolos"},
    {prodId:"49211702",label:"Suministros de bolos"},
    {prodId:"49211703",label:"Accesorios de bolos"},
    {prodId:"49241600",label:"Equipo de recreo"},
    {prodId:"49241601",label:"Sets de croquet"},
    {prodId:"49241602",label:"Equipos de bolos de jardín"},
    {prodId:"49241603",label:"Equipos de pistas para lanzar herraduras"},
    {prodId:"49241604",label:"Dardos de jardín"},
    {prodId:"50111550",label:"Llama o alpaca, mínimamente procesada con aditivos"},
    {prodId:"50111551",label:"Ganso, mínimamente procesada sin aditivos"},
    {prodId:"50111552",label:"Ganso, , mínimamente procesada con aditivos"},
    {prodId:"50111553",label:"Avestruz, mínimamente procesada sin aditivos"},
    {prodId:"50111554",label:"Avestruz, mínimamente procesada con aditivos"},
    {prodId:"50111555",label:"Jabalí, mínimamente procesada sin aditivos"},
    {prodId:"50111556",label:"Jabalí, mínimamente procesada con aditivos"},
    {prodId:"50111557",label:"Venado, mínimamente procesada sin aditivos"},
    {prodId:"50111558",label:"Venado, mínimamente procesada con aditivos"},
    {prodId:"50111559",label:"Faisán, mínimamente procesada sin aditivos"},
    {prodId:"50111560",label:"Faisán, mínimamente procesada con aditivos"},
    {prodId:"50111561",label:"Codorniz, mínimamente procesada sin aditivos"},
    {prodId:"50111562",label:"Codorniz, mínimamente procesada con aditivos"},
    {prodId:"50121800",label:"Plantas acuáticas"},
    {prodId:"50121802",label:"Plantas acuáticas frescas"},
    {prodId:"50121803",label:"Plantas acuáticas congeladas"},
    {prodId:"50121804",label:"Plantas acuáticas almacenadas en repisa"},
    {prodId:"50172002",label:"Pasta de pimientos picantes basada en soya"},
    {prodId:"50181908",label:"Maza congelada para pan"},
    {prodId:"50203000",label:"Jugos o concentrados de melones frescos"},
    {prodId:"50203001",label:"Concentrado de cantalupo"},
    {prodId:"50203002",label:"Concentrado de melón honeydrew"},
    {prodId:"50203003",label:"Concentrado de melón"},
    {prodId:"50301600",label:"Albaricoques"},
    {prodId:"50301601",label:"Abaricoques ambercot"},
    {prodId:"50301602",label:"Albaricoques apache"},
    {prodId:"50301603",label:"Albaricoques brittany dorado"},
    {prodId:"50301604",label:"Albaricoque negro"},
    {prodId:"50301605",label:"Albaricoque blenheim"},
    {prodId:"50301606",label:"Albaricoque bonny"},
    {prodId:"50301607",label:"Albaricoque biluda"},
    {prodId:"50301608",label:"Albaricoque castlebrite"},
    {prodId:"50301609",label:"Albaricoque  clutha dorado"},
    {prodId:"50301610",label:"Albaricoque clutha sun"},
    {prodId:"50301611",label:"Albaricoque derby royal"},
    {prodId:"50301612",label:"Albaricoque dina"},
    {prodId:"50301613",label:"Albaricoque earlicot"},
    {prodId:"50301614",label:"Albaricoque earliman"},
    {prodId:"50301615",label:"Albaricoque early bright"},
    {prodId:"50301616",label:"Albaricoque  flaming dorado"},
    {prodId:"50301617",label:"Albaricoque frenso"},
    {prodId:"50301618",label:"Albaricoque dorado bright"},
    {prodId:"50301619",label:"Albaricoque doradobar"},
    {prodId:"50301620",label:"Albaricoque doradoen sweet"},
    {prodId:"50301621",label:"Albaricoque doradorich"},
    {prodId:"50301622",label:"Albaricoque helena"},
    {prodId:"50301623",label:"Albaricoque honeycot"},
    {prodId:"50301624",label:"Albaricoque imperial"},
    {prodId:"50301625",label:"Albaricoque jordanne"},
    {prodId:"50301626",label:"Albaricoque jumbo cot"},
    {prodId:"50301627",label:"Albaricoque kandy kot"},
    {prodId:"50301628",label:"Albaricoque katy"},
    {prodId:"50301629",label:"Albaricoque rey"},
    {prodId:"50301630",label:"Albaricoque lambertin"},
    {prodId:"50301631",label:"Albaricoque loma"},
    {prodId:"50301632",label:"Albaricoque lulu belle"},
    {prodId:"50301633",label:"Albaricoque modesto"},
    {prodId:"50301634",label:"Albaricoque moorpark"},
    {prodId:"50301635",label:"Albaricoque orangerojo"},
    {prodId:"50301636",label:"Albaricoque palstein"},
    {prodId:"50301637",label:"Albaricoque patterson"},
    {prodId:"50301638",label:"Albaricoque perfection"},
    {prodId:"50301639",label:"Albaricoque poppy"},
    {prodId:"50301640",label:"Albaricoque poppycot"},
    {prodId:"50301641",label:"Albaricoque queen"},
    {prodId:"50301642",label:"Albaricoque riland"},
    {prodId:"50301643",label:"Albaricoque rival"},
    {prodId:"50301644",label:"Albaricoque robada"},
    {prodId:"50301645",label:"Albaricoque royal"},
    {prodId:"50301646",label:"Albaricoque  royal blenheim"},
    {prodId:"50301647",label:"Albaricoque  royal orange"},
    {prodId:"50301648",label:"Albaricoque sundrop"},
    {prodId:"50301649",label:"Albaricoque tilton"},
    {prodId:"50301650",label:"Albaricoque tomcot"},
    {prodId:"50301651",label:"Albaricoque tracy"},
    {prodId:"50301652",label:"Albaricoque tri gem"},
    {prodId:"50301653",label:"Albaricoque valley dorado"},
    {prodId:"50301654",label:"Albaricoque westley"},
    {prodId:"50301655",label:"Albaricoque  york"},
    {prodId:"50301800",label:"Barberries"},
    {prodId:"50301801",label:"Berberís paleleaf"},
    {prodId:"50301802",label:"Barberís chenault"},
    {prodId:"50301803",label:"Barberries rojo"},
    {prodId:"50301804",label:"Barberís wintergreen"},
    {prodId:"50301805",label:"Barberís korean"},
    {prodId:"50301806",label:"Barberís mentor"},
    {prodId:"50301807",label:"Barberís japanese"},
    {prodId:"50301808",label:"Barberís atropurpurea"},
    {prodId:"50301809",label:"Barberís aurea"},
    {prodId:"50301810",label:"Barberís bagatelle"},
    {prodId:"50301811",label:"Barberís crimsom pygmy"},
    {prodId:"50301812",label:"Barberís kobold"},
    {prodId:"50301813",label:"Barberís warty"},
    {prodId:"50301814",label:"Barberís  europea"},
    {prodId:"50301900",label:"Gayubas"},
    {prodId:"50301901",label:"Gayuba alpine"},
    {prodId:"50301902",label:"Gayuba roja"},
    {prodId:"50301903",label:"Gayuba comun"},
    {prodId:"50302200",label:"Agrás"},
    {prodId:"50302201",label:"Agras bluecrop"},
    {prodId:"50302202",label:"Agras bluetta"},
    {prodId:"50302203",label:"Agras brigitta"},
    {prodId:"50302204",label:"Agras chandler"},
    {prodId:"50302205",label:"Agras duque"},
    {prodId:"50302206",label:"Agras hrdyblue"},
    {prodId:"50302207",label:"Agras legacy"},
    {prodId:"50302208",label:"Agras misty"},
    {prodId:"50302209",label:"Agras nelson"},
    {prodId:"50302210",label:"Agras northblue"},
    {prodId:"50302211",label:"Agras northcountry"},
    {prodId:"50302212",label:"Agras northsky"},
    {prodId:"50302213",label:"Agras patriot"},
    {prodId:"50302214",label:"Agras spartan"},
    {prodId:"50302215",label:"Agras toro"},
    {prodId:"50302300",label:"Fruta del pan"},
    {prodId:"50302301",label:"Fruta de pan chataigne"},
    {prodId:"50302302",label:"Fruta de pan sin pepa"},
    {prodId:"50302303",label:"Fruta de pan corazón blanco"},
    {prodId:"50302304",label:"Fruta de pan corazón amarillo"},
    {prodId:"50302400",label:"Chirimoyas"},
    {prodId:"50302401",label:"Chirimoya chays"},
    {prodId:"50302402",label:"Chirimoya bronceada"},
    {prodId:"50302403",label:"Chirimoya burtons"},
    {prodId:"50302404",label:"Chirimoya burton favorite"},
    {prodId:"50302405",label:"Chirimoya jete"},
    {prodId:"50302406",label:"Chirimoya reretai"},
    {prodId:"50302407",label:"Chirimoya smoothey"},
    {prodId:"50302408",label:"Chirimoya español"},
    {prodId:"50302409",label:"Chirimoya blanco"},
    {prodId:"50302600",label:"Citrones"},
    {prodId:"50302601",label:"Citrones buddha's hand"},
    {prodId:"50302602",label:"Citrones dedo rojo"},
    {prodId:"50302603",label:"Citrones fo shoukan"},
    {prodId:"50302604",label:"Citrones bushakan"},
    {prodId:"50302605",label:"Citones diamante"},
    {prodId:"50302606",label:"Citrones etrog"},
    {prodId:"50302607",label:"Citrones ponderosa"},
    {prodId:"50302800",label:"pasas de Corinto"},
    {prodId:"50302801",label:"Pasa hudson bay"},
    {prodId:"50302802",label:"Pasa waxy"},
    {prodId:"50302803",label:"Pasa desert"},
    {prodId:"50302804",label:"Pasa negra"},
    {prodId:"50302805",label:"Pasa roja"},
    {prodId:"50302806",label:"Pasa blanca"},
    {prodId:"50303200",label:"Grosellas"},
    {prodId:"50303201",label:"Grosella early sulphur"},
    {prodId:"50303202",label:"Grosella doradoendrop"},
    {prodId:"50303203",label:"Grosella langley grace"},
    {prodId:"50303204",label:"Grocella leveller"},
    {prodId:"50303205",label:"Grosella london"},
    {prodId:"50303206",label:"Grosella worcestershire"},
    {prodId:"50303207",label:"Grosella american worcesterberry"},
    {prodId:"50303600",label:"Uvas para vino"},
    {prodId:"50303601",label:"Uva uva alicante bouschet"},
    {prodId:"50303602",label:"Uva barbera"},
    {prodId:"50303603",label:"Uva burger"},
    {prodId:"50303604",label:"Uva cabemet franc"},
    {prodId:"50303605",label:"Uva cabenet sauvignon"},
    {prodId:"50303606",label:"Uva carignane"},
    {prodId:"50303607",label:"Uva camelian"},
    {prodId:"50303608",label:"Uva cattarrato"},
    {prodId:"50303609",label:"Uva centurian"},
    {prodId:"50303610",label:"Uva charbono"},
    {prodId:"50303611",label:"Uva chardonnay"},
    {prodId:"50303612",label:"Uva chenin blanco"},
    {prodId:"50303613",label:"Uva cinsaut"},
    {prodId:"50303614",label:"Uva dolcetto"},
    {prodId:"50303615",label:"Uva emerald riesling"},
    {prodId:"50303616",label:"Uva french colombard"},
    {prodId:"50303617",label:"Uva granny nap"},
    {prodId:"50303618",label:"Uva gamay beaujolais"},
    {prodId:"50303619",label:"Uva gewurztraminer"},
    {prodId:"50303620",label:"Uva grenache"},
    {prodId:"50303621",label:"Uva grenache blanc"},
    {prodId:"50303622",label:"Uva lagrein"},
    {prodId:"50303623",label:"Uva lambruso"},
    {prodId:"50303624",label:"Uva malbec"},
    {prodId:"50303625",label:"Uva malvasía bianca"},
    {prodId:"50303626",label:"Uva marsanne"},
    {prodId:"50303627",label:"Uva matano"},
    {prodId:"50303628",label:"Uva merlot"},
    {prodId:"50303629",label:"Uva meunier"},
    {prodId:"50303630",label:"Uva missiom"},
    {prodId:"50303631",label:"Uva montepulceano"},
    {prodId:"50303632",label:"Uva muscat blanc"},
    {prodId:"50303633",label:"Uva muscat hamburg"},
    {prodId:"50303634",label:"Uva muscat alexandria"},
    {prodId:"50303635",label:"Uva muscat orange"},
    {prodId:"50303636",label:"Uva nebbiolo"},
    {prodId:"50303637",label:"Uva palomino"},
    {prodId:"50303638",label:"Uva petit verdor"},
    {prodId:"50303639",label:"Uva petit sirah"},
    {prodId:"50303640",label:"Uva pinot blanc"},
    {prodId:"50303641",label:"Uva pinot gris"},
    {prodId:"50303642",label:"Uva pinot noir"},
    {prodId:"50303643",label:"Uva primitivo"},
    {prodId:"50303644",label:"Uva roussane"},
    {prodId:"50303645",label:"Uva royalty"},
    {prodId:"50303646",label:"Uva rubirojo"},
    {prodId:"50303647",label:"Uva ruby cabenet"},
    {prodId:"50303648",label:"Uva salvador"},
    {prodId:"50303649",label:"Uva sangiovese"},
    {prodId:"50303650",label:"Uva sauvignon blanc"},
    {prodId:"50303651",label:"Uva sauvignon musque"},
    {prodId:"50303652",label:"Uva semillon"},
    {prodId:"50303653",label:"Uva souzao"},
    {prodId:"50303654",label:"Uva st emilion"},
    {prodId:"50303655",label:"Uva symphony"},
    {prodId:"50303656",label:"Uva syrah"},
    {prodId:"50303657",label:"Uva tannat"},
    {prodId:"50303658",label:"Uva tempranillo"},
    {prodId:"50303659",label:"Uvas teroldego"},
    {prodId:"50303660",label:"Uvas tocai friulano"},
    {prodId:"50303661",label:"Uvas touriga nacional"},
    {prodId:"50303662",label:"Uvas triplett blanc"},
    {prodId:"50303663",label:"Uvas viogner"},
    {prodId:"50303664",label:"Uvas blanco riesling"},
    {prodId:"50303665",label:"Uvas zinfandel"},
    {prodId:"50304000",label:"Naranjos Chinos (kumquats)"},
    {prodId:"50304001",label:"Naranjo chino hong kong"},
    {prodId:"50304002",label:"Naranjo chino limequat"},
    {prodId:"50304003",label:"Naranjo long fruit"},
    {prodId:"50304004",label:"Naranjo chino malayan"},
    {prodId:"50304005",label:"Naranjo meiwa"},
    {prodId:"50304006",label:"Naranjo chino nagami"},
    {prodId:"50304300",label:"Nísperos"},
    {prodId:"50304301",label:"Níspero advance"},
    {prodId:"50304302",label:"Níspero benlehr"},
    {prodId:"50304303",label:"Níspero big jim"},
    {prodId:"50304304",label:"Níspero champagne"},
    {prodId:"50304305",label:"Níspero early rojo"},
    {prodId:"50304306",label:"Níspero dorado nugget"},
    {prodId:"50304307",label:"Níspero herd's mammoth"},
    {prodId:"50304308",label:"Níspero mogi"},
    {prodId:"50304309",label:"Níspero mrs cooksey"},
    {prodId:"50304310",label:"Níspero fresa"},
    {prodId:"50304311",label:"Níspero tanaka"},
    {prodId:"50304312",label:"Níspero victoria vista blanco"},
    {prodId:"50304313",label:"Níspero wolfe"},
    {prodId:"50304700",label:"Mora mulberry"},
    {prodId:"50304701",label:"Mora negra"},
    {prodId:"50304702",label:"Mora blanca"},
    {prodId:"50304800",label:"Mirtos"},
    {prodId:"50304801",label:"Mirto bog"},
    {prodId:"50305200",label:"Maracuyás"},
    {prodId:"50305201",label:"Fruta maracuyá banana"},
    {prodId:"50305202",label:"Maracuyá flor azul"},
    {prodId:"50305203",label:"Maracuyá crackerjack"},
    {prodId:"50305204",label:"Maracuyá granadilla gigante"},
    {prodId:"50305205",label:"Maracuyá granadilla dorada"},
    {prodId:"50305206",label:"Maracuyá maypops"},
    {prodId:"50305207",label:"Maracuyá roja"},
    {prodId:"50305208",label:"Maracuyá granadilla dulce"},
    {prodId:"50305209",label:"Maracuyá sandia"},
    {prodId:"50305210",label:"Maracuyá  wing- steam"},
    {prodId:"50305500",label:"Caquis"},
    {prodId:"50305501",label:"Caqui americano"},
    {prodId:"50305502",label:"Caqui sapote negro"},
    {prodId:"50305503",label:"Caqui chapote / negro"},
    {prodId:"50305504",label:"Caqui dátale ciruela"},
    {prodId:"50305505",label:"Caqui fuyu"},
    {prodId:"50305506",label:"Caqui gigante fuyu"},
    {prodId:"50305507",label:"Caqui hachiya"},
    {prodId:"50305508",label:"Caqui mantequilla / mabolo"},
    {prodId:"50305509",label:"Caqui príncipe ito"},
    {prodId:"50305510",label:"Caqui brillante royal"},
    {prodId:"50305511",label:"Caqui sharon"},
    {prodId:"50305512",label:"Caqui triumph"},
    {prodId:"50305700",label:"Ciruelos Damascos"},
    {prodId:"50305701",label:"Ciruelo / damasco negro kat"},
    {prodId:"50305702",label:"Ciruelo / damasco gusto azul"},
    {prodId:"50305703",label:"Ciruelo / damasco corazón de crimson"},
    {prodId:"50305704",label:"Ciruelo / damasco dapply dandy"},
    {prodId:"50305705",label:"Ciruelo / damasco dapple fuego"},
    {prodId:"50305706",label:"Ciruelo / damasco  dapple temprano"},
    {prodId:"50305707",label:"Ciruelo / damasco  caída de sabor"},
    {prodId:"50305708",label:"Ciruelo / damasco sabor de oro"},
    {prodId:"50305709",label:"Ciruelo / damasco sabor granada"},
    {prodId:"50305710",label:"Ciruelo / damasco sabor de corazón"},
    {prodId:"50305711",label:"Ciruelo / damasco joya de sabor"},
    {prodId:"50305712",label:"Ciruelo / damasco sabor de rey"},
    {prodId:"50305713",label:"Ciruelo / damasco sabor de reina"},
    {prodId:"50305714",label:"Ciruelo / damasco sabor supremo"},
    {prodId:"50305715",label:"Ciruelo / damasco sabor premio"},
    {prodId:"50305716",label:"Ciruelo / damasco saborella"},
    {prodId:"50305717",label:"Ciruelo / damasco saborrico"},
    {prodId:"50305718",label:"Ciruelo / damasco  sabor rosa"},
    {prodId:"50305719",label:"Ciruelo / damasco orgullo geo"},
    {prodId:"50305720",label:"Ciruelo / damasco kat rojo"},
    {prodId:"50305721",label:"Ciruelo / damasco premio royal"},
    {prodId:"50305722",label:"Ciruelo / damasco rosa sierra"},
    {prodId:"50305723",label:"Ciruelo / damasco geisha dulce"},
    {prodId:"50305900",label:"Granadas"},
    {prodId:"50305901",label:"Granada foothhill"},
    {prodId:"50305902",label:"Granada, granada"},
    {prodId:"50305903",label:"Granada rojo feliz"},
    {prodId:"50305904",label:"Granada nana"},
    {prodId:"50305905",label:"Granada rojo de pat"},
    {prodId:"50305906",label:"Granada pinkhan"},
    {prodId:"50305907",label:"Granada terciopelo morado"},
    {prodId:"50305908",label:"Granada grandioso"},
    {prodId:"50306000",label:"Pomelos"},
    {prodId:"50306001",label:"Pomelo chandler"},
    {prodId:"50306002",label:"Pomelo hirado butan"},
    {prodId:"50306003",label:"Pomelo liang ping yau"},
    {prodId:"50306004",label:"Pomelo panda wangi"},
    {prodId:"50306005",label:"Pomelo rosado"},
    {prodId:"50306006",label:"Pomelo shaddock rojo"},
    {prodId:"50306007",label:"Pomelo siamese dulce"},
    {prodId:"50306008",label:"Pomelo waingwright"},
    {prodId:"50306100",label:"Membrillos"},
    {prodId:"50306101",label:"Membrillo campeón"},
    {prodId:"50306102",label:"Membrillo piña"},
    {prodId:"50306103",label:"Membrillo smyma"},
    {prodId:"50306300",label:"Ruibarbos"},
    {prodId:"50306301",label:"Ruibarbo crimson rojo"},
    {prodId:"50306302",label:"Ruibarbo champagne temprana"},
    {prodId:"50306303",label:"Ruibarbo glasrey perpetual"},
    {prodId:"50306304",label:"Ruibarbo sutton"},
    {prodId:"50306305",label:"Ruibarbo timperley temprano"},
    {prodId:"50306306",label:"Ruibarbo valentina"},
    {prodId:"50306307",label:"Ruibarbo victoria"},
    {prodId:"50306308",label:"Ruibarbo zwolle de cemilla"},
    {prodId:"50306309",label:"Ruibarbo macdonald"},
    {prodId:"50306310",label:"Ruibarbo tilden"},
    {prodId:"50306400",label:"Escaramujos"},
    {prodId:"50306401",label:"Escaramujo rosa brier"},
    {prodId:"50306402",label:"Escaramujo rosa elegante"},
    {prodId:"50306403",label:"Escaramujo rosa rugosa"},
    {prodId:"50306404",label:"Escaramujo rosa burnet o scotch"},
    {prodId:"50306600",label:"Bayas saskatoon"},
    {prodId:"50306601",label:"Baya mielmadera saskatoon"},
    {prodId:"50306602",label:"Baya northline saskatoon"},
    {prodId:"50306603",label:"Baya saskatoon ahumado"},
    {prodId:"50306604",label:"Baya saskatoon thiessen"},
    {prodId:"50306800",label:"Manzanas dulces"},
    {prodId:"50306801",label:"Manzana kampong malve dulce"},
    {prodId:"50306802",label:"Manzana dulce sin pepa"},
    {prodId:"50306803",label:"Manzana dulce thai lessand"},
    {prodId:"50306900",label:"Tamarillos"},
    {prodId:"50306901",label:"Tamarillo amberlea dorado"},
    {prodId:"50306902",label:"Tamarillo calvo dorado"},
    {prodId:"50306903",label:"Tamarillo mina de oro"},
    {prodId:"50306904",label:"Tamarillo oratia rojo"},
    {prodId:"50306905",label:"Tamarillo beau rojo"},
    {prodId:"50306906",label:"Tamarillo delicia roja"},
    {prodId:"50307100",label:"Aronias"},
    {prodId:"50307101",label:"Aronia magia de otoño"},
    {prodId:"50307102",label:"Aronia brillantísima"},
    {prodId:"50307103",label:"Aronia nero"},
    {prodId:"50307104",label:"Aronia vikingo"},
    {prodId:"50311500",label:"Manzanas orgánicas"},
    {prodId:"50311501",label:"Manzanas akane orgánicas"},
    {prodId:"50311502",label:"Manzana ambrosia orgánica"},
    {prodId:"50311503",label:"Manzanas api orgánicas"},
    {prodId:"50311504",label:"Manzanas baldwin orgánicas"},
    {prodId:"50311505",label:"Manzanas brabum orgánicas"},
    {prodId:"50311506",label:"Manzanas bramley orgánicas"},
    {prodId:"50311507",label:"Manzana joven bramley orgánic"},
    {prodId:"50311508",label:"Manzana calville blanche d'hiver orgánic"},
    {prodId:"50311509",label:"Manzana cameo orgánica"},
    {prodId:"50311510",label:"Manzana charles ross orgánica"},
    {prodId:"50311511",label:"Manzana codlin orgánica"},
    {prodId:"50311512",label:"Manzana cortland orgánica"},
    {prodId:"50311513",label:"Manzana costard orgánica"},
    {prodId:"50311514",label:"Manzana court pendu plat orgánica"},
    {prodId:"50311515",label:"Manzana  cox's orange pippin orgánica"},
    {prodId:"50311516",label:"Manzana crab orgánica"},
    {prodId:"50311517",label:"Manzana crispin orgánica"},
    {prodId:"50311518",label:"Manzana delicioso orgánica"},
    {prodId:"50311519",label:"Manzana duquesa orgánica"},
    {prodId:"50311520",label:"Manzana earli dorado orgánica"},
    {prodId:"50311521",label:"Manzana early mcintosh orgánica"},
    {prodId:"50311522",label:"Manzana elstar orgánica"},
    {prodId:"50311523",label:"Manzana empire orgánica"},
    {prodId:"50311524",label:"Manzana flor de kent orgánica"},
    {prodId:"50311525",label:"Manzana fiji orgánica"},
    {prodId:"50311526",label:"Manzana gala orgánica"},
    {prodId:"50311527",label:"Manzana gascoyne's scarlet orgánica"},
    {prodId:"50311528",label:"Manzana gillyflower orgánica"},
    {prodId:"50311529",label:"Manzana ginger dorado orgánica"},
    {prodId:"50311530",label:"Manzana gladstone orgánica"},
    {prodId:"50311531",label:"Manzana gloster orgánica"},
    {prodId:"50311532",label:"Manzana dorado supreme orgánica"},
    {prodId:"50311533",label:"Manzana doradoen delicioso orgánica"},
    {prodId:"50311534",label:"Manzana doradoen nobel orgánica"},
    {prodId:"50311535",label:"Manzana granny smith orgánica"},
    {prodId:"50311536",label:"Manzana gravenstain orgánica"},
    {prodId:"50311537",label:"Manzana verdeing orgánica"},
    {prodId:"50311538",label:"Manzana verdesleeves orgánica"},
    {prodId:"50311539",label:"Manzana honeycrisp orgánica"},
    {prodId:"50311540",label:"Manzana howgate wonder orgánica"},
    {prodId:"50311541",label:"Manzana rojo ida orgánica"},
    {prodId:"50311542",label:"Manzana james grives orgánica"},
    {prodId:"50311543",label:"Manzana jersey mac orgánica"},
    {prodId:"50311544",label:"Manzana jaster orgánica"},
    {prodId:"50311545",label:"Manzana jonadorado orgánica"},
    {prodId:"50311546",label:"Manzana jonamac orgánica"},
    {prodId:"50311547",label:"Manzana  jonathan  orgánica"},
    {prodId:"50311548",label:"Manzana katy orgánica"},
    {prodId:"50311549",label:"Manzana kidd's orange rojo orgánica"},
    {prodId:"50311550",label:"Manzana lady orgánica"},
    {prodId:"50311551",label:"Manzana law rome orgánica"},
    {prodId:"50311552",label:"Manzana laxton orgánica"},
    {prodId:"50311553",label:"Manzana lord derby orgánica"},
    {prodId:"50311554",label:"Manzana macoun  orgánica"},
    {prodId:"50311555",label:"Manzana mcintosh orgánica"},
    {prodId:"50311556",label:"Manzana mutsu orgánica"},
    {prodId:"50311557",label:"Manzana newtown pippin orgánica"},
    {prodId:"50311558",label:"Manzana northen spy orgánica orgánica"},
    {prodId:"50311559",label:"Manzana orleans reinette orgánica"},
    {prodId:"50311560",label:"Manzana ozark dorado orgánica"},
    {prodId:"50311561",label:"Manzana pacific rose orgánica"},
    {prodId:"50311562",label:"Manzana paula rojo orgánica"},
    {prodId:"50311563",label:"Manzana peamain orgánica"},
    {prodId:"50311564",label:"Manzana pink lady orgánica"},
    {prodId:"50311565",label:"Manzana pippin orgánica"},
    {prodId:"50311566",label:"Manzana pitmaston pineapple orgánica"},
    {prodId:"50311567",label:"Manzana pomme dápi orgánica"},
    {prodId:"50311568",label:"Manzana prime dorado orgánica"},
    {prodId:"50311569",label:"Manzana rojo astrakhan orgánica"},
    {prodId:"50311570",label:"Manzana rojo boscoop orgánica"},
    {prodId:"50311571",label:"Manzana  rojo chief orgánica"},
    {prodId:"50311572",label:"Manzana rojo delicioso orgánica"},
    {prodId:"50311573",label:"Manzana rojo grvenstain orgánica"},
    {prodId:"50311574",label:"Manzana re rome orgánica"},
    {prodId:"50311575",label:"Manzana rojo stayman orgánica"},
    {prodId:"50311576",label:"Manzana rojo york orgánica"},
    {prodId:"50311577",label:"Manzana reinette orgánica"},
    {prodId:"50311578",label:"Manzana rome beuty orgánica"},
    {prodId:"50311579",label:"Manzana russet orgánica"},
    {prodId:"50311580",label:"Manzana sierra beaty orgánica"},
    {prodId:"50311581",label:"Manzana spartan orgánica"},
    {prodId:"50311582",label:"Manzana stark crimson orgánica"},
    {prodId:"50311583",label:"Manzana starrey orgánica"},
    {prodId:"50311584",label:"Manzana stayman orgánica"},
    {prodId:"50311585",label:"Manzana stayman winesap orgánica"},
    {prodId:"50311586",label:"Manzana verano rambo orgánica"},
    {prodId:"50311587",label:"Manzana tsugaru orgánica"},
    {prodId:"50311588",label:"Manzana veinte onzas orgánica"},
    {prodId:"50311589",label:"Manzana tydeman rojo orgánica"},
    {prodId:"50311590",label:"Manzana vistabella orgánica"},
    {prodId:"50311591",label:"Manzana weatley orgánica"},
    {prodId:"50311592",label:"Manzana blanco joanetine orgánica"},
    {prodId:"50311593",label:"Manzana blanco transparent orgánica"},
    {prodId:"50311594",label:"Manzana winesap orgánica"},
    {prodId:"50311595",label:"Manzana worcester orgánica"},
    {prodId:"50311596",label:"Manzana york imperial orgánica"},
    {prodId:"50311600",label:"Albaricoques orgánicos"},
    {prodId:"50311601",label:"Abaricoques ambercot orgánica"},
    {prodId:"50311602",label:"Albaricoques apache orgánica"},
    {prodId:"50311603",label:"Albaricoques brittany dorado orgánica"},
    {prodId:"50311604",label:"Albaricoque negro orgánica"},
    {prodId:"50311605",label:"Albaricoque blenheim orgánica"},
    {prodId:"50311606",label:"Albaricoque bonny orgánica"},
    {prodId:"50311607",label:"Albaricoque biluda orgánica"},
    {prodId:"50311608",label:"Albaricoque castlebrite orgánica"},
    {prodId:"50311609",label:"Albaricoque  clutha dorado orgánica"},
    {prodId:"50311610",label:"Albaricoque clutha sun orgánica"},
    {prodId:"50311611",label:"Albaricoque derby royal orgánica"},
    {prodId:"50311612",label:"Albaricoque dina orgánica"},
    {prodId:"50311613",label:"Albaricoque earlicot orgánica"},
    {prodId:"50311614",label:"Albaricoque earliman orgánica"},
    {prodId:"50311615",label:"Albaricoque early bright orgánica"},
    {prodId:"50311616",label:"Albaricoque  flaming dorado orgánica"},
    {prodId:"50311617",label:"Albaricoque frenso orgánica"},
    {prodId:"50311618",label:"Albaricoque dorado bright orgánica"},
    {prodId:"50311619",label:"Albaricoque doradobar orgánica"},
    {prodId:"50311620",label:"Albaricoque doradoen sweet orgánica"},
    {prodId:"50311621",label:"Albaricoque doradorich orgánica"},
    {prodId:"50311622",label:"Albaricoque helena orgánica"},
    {prodId:"50311623",label:"Albaricoque honeycot orgánica"},
    {prodId:"50311624",label:"Albaricoque imperial orgánica"},
    {prodId:"50311625",label:"Albaricoque jordanne orgánica"},
    {prodId:"50311626",label:"Albaricoque jumbo cot orgánica"},
    {prodId:"50311627",label:"Albaricoque kandy kot orgánica"},
    {prodId:"50311628",label:"Albaricoque katy orgánica"},
    {prodId:"50311629",label:"Albaricoque rey orgánica"},
    {prodId:"50311630",label:"Albaricoque lambertin orgánica"},
    {prodId:"50311631",label:"Albaricoque loma orgánica"},
    {prodId:"50311632",label:"Albaricoque lulu belle orgánica"},
    {prodId:"50311633",label:"Albaricoque modesto orgánica"},
    {prodId:"50311634",label:"Albaricoque moorpark orgánica"},
    {prodId:"50311635",label:"Albaricoque orangerojo orgánica"},
    {prodId:"50311636",label:"Albaricoque palstein orgánica"},
    {prodId:"50311637",label:"Albaricoque patterson orgánica"},
    {prodId:"50311638",label:"Albaricoque perfection orgánica"},
    {prodId:"50311639",label:"Albaricoque poppy orgánica"},
    {prodId:"50311640",label:"Albaricoque poppycot orgánica"},
    {prodId:"50311641",label:"Albaricoque reina orgánica"},
    {prodId:"50311642",label:"Albaricoque riland orgánica"},
    {prodId:"50311643",label:"Albaricoque rival orgánica"},
    {prodId:"50311644",label:"Albaricoque robada orgánica"},
    {prodId:"50311645",label:"Albaricoque royal orgánica"},
    {prodId:"50311646",label:"Albaricoque  royal blenheim orgánica"},
    {prodId:"50311647",label:"Albaricoque  royal orange orgánica"},
    {prodId:"50311648",label:"Albaricoque sundrop orgánica"},
    {prodId:"50311649",label:"Albaricoque tilton orgánica"},
    {prodId:"50311650",label:"Albaricoque tomcot orgánica"},
    {prodId:"50311651",label:"Albaricoque tracy orgánica"},
    {prodId:"50311652",label:"Albaricoque tri gem orgánica"},
    {prodId:"50311653",label:"Albaricoque valley dorado orgánica"},
    {prodId:"50311654",label:"Albaricoque westley orgánica"},
    {prodId:"50311655",label:"Albaricoque  york orgánica"},
    {prodId:"50311800",label:"Barberries orgánicos"},
    {prodId:"50311801",label:"Berberís paleleaf orgánica"},
    {prodId:"50311802",label:"Barberís chenault orgánica"},
    {prodId:"50311803",label:"Barberries rojo orgánica"},
    {prodId:"50311804",label:"Barberís winterverde orgánica"},
    {prodId:"50311805",label:"Barberís korean orgánica"},
    {prodId:"50311806",label:"Barberís mentor orgánica"},
    {prodId:"50311807",label:"Barberís japanese orgánica"},
    {prodId:"50311808",label:"Barberís atropurpurea orgánica"},
    {prodId:"50311809",label:"Barberís aurea orgánica"},
    {prodId:"50311810",label:"Barberís bagatelle orgánica"},
    {prodId:"50311811",label:"Barberís crimsom pygmy orgánica"},
    {prodId:"50311812",label:"Barberís kobold orgánica"},
    {prodId:"50311813",label:"Barberís warty orgánica"},
    {prodId:"50311814",label:"Barberís european orgánica"},
    {prodId:"50311900",label:"Gayubas orgánicas"},
    {prodId:"50311901",label:"Gayuba alpine orgánica"},
    {prodId:"50311902",label:"Gayuba roja orgánica"},
    {prodId:"50311903",label:"Gayuba comun orgánica"},
    {prodId:"50312100",label:"Arándanos de mirto orgánicos"},
    {prodId:"50312101",label:"Arandanos bog orgánicos"},
    {prodId:"50312102",label:"Arandanos dwarf orgánicos"},
    {prodId:"50312103",label:"Arandanos mountain orgánicos"},
    {prodId:"50312104",label:"Arandanos oval-leaved orgánicos"},
    {prodId:"50312200",label:"Agrás orgánicas"},
    {prodId:"50312201",label:"Agras bluetta orgánica"},
    {prodId:"50312202",label:"Agras duque orgánica"},
    {prodId:"50312203",label:"Agras spartan orgánica"},
    {prodId:"50312204",label:"Agras patriot orgánica"},
    {prodId:"50312205",label:"Agras toro orgánica"},
    {prodId:"50312206",label:"Agras hardyblue orgánica"},
    {prodId:"50312207",label:"Agras bluecrop orgánica"},
    {prodId:"50312208",label:"Agras legacy orgánica"},
    {prodId:"50312209",label:"Agras nelson orgánica"},
    {prodId:"50312210",label:"Agras chandler orgánica"},
    {prodId:"50312211",label:"Agras brigitta orgánica"},
    {prodId:"50312212",label:"Agras northcountry orgánica"},
    {prodId:"50312213",label:"Agras northsky orgánica"},
    {prodId:"50312214",label:"Agras northblue orgánica"},
    {prodId:"50312215",label:"Agras misty orgánica"},
    {prodId:"50312300",label:"Frutas del pan orgánicas"},
    {prodId:"50312301",label:"Fruta de pan chataigne orgánica"},
    {prodId:"50312302",label:"Fruta de pan sin semilla orgánica"},
    {prodId:"50312303",label:"Fruta de pan corazón blanco orgánica"},
    {prodId:"50312304",label:"Fruta de pan corazón amarillo orgánica"},
    {prodId:"50312400",label:"Chirimoyas orgánicas"},
    {prodId:"50312401",label:"Chirimoya chays orgánica"},
    {prodId:"50312402",label:"Chirimoya bronceada orgánica"},
    {prodId:"50312403",label:"Chirimoya burtons orgánica"},
    {prodId:"50312404",label:"Chirimoya burton favorite orgánica"},
    {prodId:"50312405",label:"Chirimoya jete orgánica"},
    {prodId:"50312406",label:"Chirimoya reretai orgánica"},
    {prodId:"50312407",label:"Chirimoya smoothey orgánica"},
    {prodId:"50312408",label:"Chirimoya español orgánica"},
    {prodId:"50312409",label:"Chirimoya blanco orgánica"},
    {prodId:"50312500",label:"Cerezas orgánicas"},
    {prodId:"50312501",label:"Cereza amarelle  orgánica"},
    {prodId:"50312502",label:"Cereza brooks orgánica"},
    {prodId:"50312503",label:"Cereza bigarreu orgánica"},
    {prodId:"50312504",label:"Cereza bing orgánica"},
    {prodId:"50312505",label:"Cereza blach republic orgánica"},
    {prodId:"50312506",label:"Cereza negro schmidt orgánica"},
    {prodId:"50312507",label:"Cereza negrotartarian orgánica"},
    {prodId:"50312508",label:"Cereza fiesta bing orgánica"},
    {prodId:"50312509",label:"Cereza garnet orgánica"},
    {prodId:"50312510",label:"Cereza rey orgánica"},
    {prodId:"50312511",label:"Cereza chapman orgánica"},
    {prodId:"50312512",label:"Cereza lapin orgánica"},
    {prodId:"50312513",label:"Cereza larian orgánica"},
    {prodId:"50312514",label:"Cereza dark guines orgánica"},
    {prodId:"50312515",label:"Cereza montmorency orgánica"},
    {prodId:"50312516",label:"Cereza duque orgánica"},
    {prodId:"50312517",label:"Cereza early rivers orgánica"},
    {prodId:"50312518",label:"Cereza ruby bing orgánica"},
    {prodId:"50312519",label:"Cereza santine orgánica"},
    {prodId:"50312520",label:"Cereza geans/guines orgánica"},
    {prodId:"50312521",label:"Cereza sonata orgánica"},
    {prodId:"50312522",label:"Cereza lambert orgánica"},
    {prodId:"50312523",label:"Cereza stella orgánica"},
    {prodId:"50312524",label:"Cereza sweethart orgánica"},
    {prodId:"50312525",label:"Cereza tartarian orgánica"},
    {prodId:"50312527",label:"Cereza maraschino orgánica"},
    {prodId:"50312528",label:"Cereza van orgánica"},
    {prodId:"50312529",label:"Cereza morello orgánica"},
    {prodId:"50312530",label:"Cereza royal ann orgánica"},
    {prodId:"50312531",label:"Cereza ranier orgánica"},
    {prodId:"50312532",label:"Cereza royal orgánica"},
    {prodId:"50312600",label:"Citrones orgánicos"},
    {prodId:"50312601",label:"Citrones buddha's hand orgánica"},
    {prodId:"50312602",label:"Citrones fingerojo orgánica"},
    {prodId:"50312603",label:"Citrones fo shoukan orgánica"},
    {prodId:"50312604",label:"Cotrones bushakan orgánica"},
    {prodId:"50312605",label:"Citones diamante orgánica"},
    {prodId:"50312606",label:"Citrones etrog orgánica"},
    {prodId:"50312607",label:"Citrones ponderosa orgánica"},
    {prodId:"50312800",label:"pasas de Corinto orgánicas"},
    {prodId:"50312801",label:"Pasa hudson bay orgánica"},
    {prodId:"50312802",label:"Pasa waxy orgánica"},
    {prodId:"50312803",label:"Pasa desert orgánica"},
    {prodId:"50312804",label:"Pasa negra orgánica"},
    {prodId:"50312805",label:"Pasa roja orgánica"},
    {prodId:"50312806",label:"Pasa blanca orgánica"},
    {prodId:"50312900",label:"Dátiles orgánicos"},
    {prodId:"50312901",label:"Dátiles asharasi  orgánica"},
    {prodId:"50312902",label:"Dátiles barhi o barhee orgánica"},
    {prodId:"50312903",label:"Dátiles deglet noor orgánica"},
    {prodId:"50312904",label:"Dátiles fardh orgánica"},
    {prodId:"50312905",label:"Dátiles gundila orgánica"},
    {prodId:"50312906",label:"Dátiles halawi/halawy orgánica"},
    {prodId:"50312907",label:"Dátiles hilali orgánica"},
    {prodId:"50312908",label:"Dátiles khadrawi/khadrawy orgánica"},
    {prodId:"50312909",label:"Dátiles khalas orgánica"},
    {prodId:"50312910",label:"Dátiles khustawi orgánica"},
    {prodId:"50312911",label:"Dátiles khidri orgánica"},
    {prodId:"50312912",label:"Dátiles medjool/ medjol orgánica"},
    {prodId:"50312913",label:"Dátiles mactoum orgánica"},
    {prodId:"50312914",label:"Dátiles neghal orgánica"},
    {prodId:"50312915",label:"Dátiles yatimeh orgánica"},
    {prodId:"50312916",label:"Dátiles zahidi orgánica"},
    {prodId:"50313000",label:"Pitahayas orgánicas"},
    {prodId:"50313001",label:"Pitahaya roja, rosada orgánica"},
    {prodId:"50313002",label:"Pitahaya roja, amarillo orgánica"},
    {prodId:"50313100",label:"Brevas orgánicas"},
    {prodId:"50313101",label:"Breva bardajic orgánica"},
    {prodId:"50313102",label:"Breva brown turkey orgánica"},
    {prodId:"50313103",label:"Breva calimyma  orgánica"},
    {prodId:"50313104",label:"Breva conadria orgánica"},
    {prodId:"50313105",label:"Breva dotado orgánica"},
    {prodId:"50313106",label:"Breva kadota orgánica"},
    {prodId:"50313107",label:"Breva mediterránea orgánica"},
    {prodId:"50313108",label:"Breva misión orgánica"},
    {prodId:"50313109",label:"Breva amyma orgánica"},
    {prodId:"50313110",label:"Breva verdona orgánica"},
    {prodId:"50313111",label:"Breva rey blanco orgánica"},
    {prodId:"50313200",label:"Grosellas orgánicas"},
    {prodId:"50313201",label:"Grosella early sulphur orgánica"},
    {prodId:"50313202",label:"Grosella doradoendrop orgánica"},
    {prodId:"50313203",label:"Grosella langley grace orgánica"},
    {prodId:"50313204",label:"Grocella leveller orgánica"},
    {prodId:"50313205",label:"Grosella london orgánica"},
    {prodId:"50313206",label:"Grosella worcestershire orgánica"},
    {prodId:"50313207",label:"Grosella american worcesterberry orgánic"},
    {prodId:"50313300",label:"Toronjas orgánicas"},
    {prodId:"50313301",label:"Toronja burgundi orgánica"},
    {prodId:"50313302",label:"Toronja duncan orgánica"},
    {prodId:"50313303",label:"Toronja foster orgánica"},
    {prodId:"50313304",label:"Toronja marsh orgánica"},
    {prodId:"50313305",label:"Toronja nueva zelandia orgánica"},
    {prodId:"50313306",label:"Toronja rio rojo orgánica"},
    {prodId:"50313307",label:"Toronja ruby rojo orgánica"},
    {prodId:"50313308",label:"Toronja star ruby orgánica"},
    {prodId:"50313309",label:"Toronja triumph orgánica"},
    {prodId:"50313500",label:"Uvas pasas orgánicas"},
    {prodId:"50313501",label:"Uva corinth negra orgánica"},
    {prodId:"50313502",label:"Uva canner orgánica"},
    {prodId:"50313503",label:"Uva dovine orgánica"},
    {prodId:"50313504",label:"Uva fiesta orgánica"},
    {prodId:"50313505",label:"Uva selme pete orgánica"},
    {prodId:"50313506",label:"Uva sultana orgánica"},
    {prodId:"50313700",label:"Guayabas orgánicas"},
    {prodId:"50313701",label:"Guayaba beaumont orgánica"},
    {prodId:"50313702",label:"Guayaba carrley orgánica"},
    {prodId:"50313703",label:"Guayaba lucida orgánica"},
    {prodId:"50313704",label:"Guayaba piña orgánica"},
    {prodId:"50313800",label:"Arándanos Huckleberry orgánicos"},
    {prodId:"50313801",label:"Arándano invierno negro orgánico"},
    {prodId:"50313802",label:"Arándano cascada orgánico"},
    {prodId:"50313803",label:"Arándano enano orgánico"},
    {prodId:"50313804",label:"Arándano montaña orgánico"},
    {prodId:"50313805",label:"Arándano rojo orgánico"},
    {prodId:"50313900",label:"Kiwis orgánicos"},
    {prodId:"50313901",label:"Fruta de kiwi ananasnaja orgánica"},
    {prodId:"50313902",label:"Fruta de kiwi belleza del ártico orgánica"},
    {prodId:"50313903",label:"Fruta de kiwi blake orgánica"},
    {prodId:"50313904",label:"Fruta de kiwi hayward orgánica"},
    {prodId:"50313905",label:"Fruta de kiwi issai orgánica"},
    {prodId:"50313906",label:"Fruta de kiwi siberiana orgánica"},
    {prodId:"50314000",label:"Naranjos Chinos (kumquats) orgánicos"},
    {prodId:"50314001",label:"Naranjo chino hong kong orgánico"},
    {prodId:"50314002",label:"Naranjo chino limequat orgánico"},
    {prodId:"50314003",label:"Naranjo long fruit orgánico"},
    {prodId:"50314004",label:"Naranjo chino malayan orgánico"},
    {prodId:"50314005",label:"Naranjo meiwa orgánico"},
    {prodId:"50314006",label:"Naranja chino nagami orgánico"},
    {prodId:"50314100",label:"Limones orgánicos"},
    {prodId:"50314101",label:"Limón baboon orgánico"},
    {prodId:"50314102",label:"Limón bearss sicilian orgánico"},
    {prodId:"50314103",label:"Limón cameron highland orgánico"},
    {prodId:"50314104",label:"Limón escondido orgánico"},
    {prodId:"50314105",label:"Limón eureka orgánico"},
    {prodId:"50314106",label:"Limón lisbon orgánico"},
    {prodId:"50314107",label:"Limón meyer orgánico"},
    {prodId:"50314108",label:"Limón volkaner orgánico"},
    {prodId:"50314300",label:"Nísperos orgánicos"},
    {prodId:"50314301",label:"Níspero advance orgánico"},
    {prodId:"50314302",label:"Níspero benlehr orgánico"},
    {prodId:"50314303",label:"Níspero big jim orgánico"},
    {prodId:"50314304",label:"Níspero champagne orgánico"},
    {prodId:"50314305",label:"Níspero early rojo orgánico"},
    {prodId:"50314306",label:"Níspero nuget dorado orgánico"},
    {prodId:"50314307",label:"Níspero herd's mammoth orgánico"},
    {prodId:"50314308",label:"Níspero mogi orgánico"},
    {prodId:"50314309",label:"Níspero mrs cooksey orgánico"},
    {prodId:"50314310",label:"Níspero fresa orgánico"},
    {prodId:"50314311",label:"Níspero tanaka orgánico"},
    {prodId:"50314312",label:"Níspero victoria vista blanco orgánico"},
    {prodId:"50314313",label:"Níspero wolfe orgánico"},
    {prodId:"50314400",label:"Mandarinas orgánicas"},
    {prodId:"50314401",label:"Naranjas clauselinas orgánicas"},
    {prodId:"50314402",label:"Mandarinas clementinas orgánicas"},
    {prodId:"50314403",label:"Naranja mandarina cleopatra"},
    {prodId:"50314404",label:"Mandarina dancy orgánica"},
    {prodId:"50314405",label:"Naranja ellensday orgánica"},
    {prodId:"50314406",label:"Naranja fairchild orgánica"},
    {prodId:"50314407",label:"Naranja fallglo orgánica"},
    {prodId:"50314408",label:"Naranja fortuna orgánica"},
    {prodId:"50314409",label:"Mandarina naranja fremont orgánica"},
    {prodId:"50314410",label:"Naranja fremont orgánica"},
    {prodId:"50314411",label:"Naranja nuget dorada orgánica"},
    {prodId:"50314412",label:"Mandarina naranja miel orgánica"},
    {prodId:"50314413",label:"Naranja miel orgánica"},
    {prodId:"50314414",label:"Mandarina miel orgánica"},
    {prodId:"50314415",label:"Natanja tanjelo honebelle orgánica"},
    {prodId:"50314416",label:"Naranja mandarina rey orgánica"},
    {prodId:"50314417",label:"Naranja kinnow orgánica"},
    {prodId:"50314418",label:"Naranja andarinalee orgánica"},
    {prodId:"50314419",label:"Naranja makokkee orgánica"},
    {prodId:"50314420",label:"Naranja malvasion orgánica"},
    {prodId:"50314421",label:"Naranja mandarina mediterránea orgánica"},
    {prodId:"50314422",label:"Naranja tangelo minneola orgánica"},
    {prodId:"50314423",label:"Naranja monica orgánica"},
    {prodId:"50314424",label:"Naranja murcott miel orgánica"},
    {prodId:"50314425",label:"Naranja murcott tangors orgánica"},
    {prodId:"50314426",label:"Naranja mandarina natsudaidai  orgánica"},
    {prodId:"50314427",label:"Naranja mandarina natsumikan orgánica"},
    {prodId:"50314428",label:"Naranja tanjelo nocatee orgánica"},
    {prodId:"50314429",label:"Naranja tanjelo orlando orgánica"},
    {prodId:"50314430",label:"Mandarina ortanique orgánica"},
    {prodId:"50314431",label:"Naranja mandarina pagina orgánica"},
    {prodId:"50314432",label:"Naranja pixie orgánica"},
    {prodId:"50314433",label:"Naranja mandarina ponkan bantangas orgánica"},
    {prodId:"50314434",label:"Naranja reina orgánica"},
    {prodId:"50314435",label:"Naranja robinson orgánica"},
    {prodId:"50314436",label:"Naranja saltenitas orgánica"},
    {prodId:"50314437",label:"Naranja tangelo sampson orgánica"},
    {prodId:"50314438",label:"Naranja mandarina satsuma orgánica"},
    {prodId:"50314439",label:"Naranja mandarina sunburst orgánica"},
    {prodId:"50314440",label:"Tangelo orgánico"},
    {prodId:"50314441",label:"Naranja mandarina orgánica"},
    {prodId:"50314442",label:"Naranja templo orgánica"},
    {prodId:"50314443",label:"Naranja thornton orgánica"},
    {prodId:"50314444",label:"Mandarina wekiwa orgánica"},
    {prodId:"50314445",label:"Mandarina wilkins orgánica"},
    {prodId:"50314446",label:"Mandarina willowleaf mediterránea orgánica"},
    {prodId:"50314700",label:"Moras mulberry orgánicas"},
    {prodId:"50314701",label:"Mora negra orgánica"},
    {prodId:"50314702",label:"Mora blanca orgánica"},
    {prodId:"50314800",label:"Mirtos orgánicos"},
    {prodId:"50314801",label:"Mirto bog orgánico"},
    {prodId:"50314900",label:"Nectarinas orgánicos"},
    {prodId:"50314901",label:"Durazno april glo orgánico"},
    {prodId:"50314902",label:"Durazno arctic mist orgánico"},
    {prodId:"50314903",label:"Durazno artic snow orgánico"},
    {prodId:"50314904",label:"Durazno artic star orgánico"},
    {prodId:"50314905",label:"Durazno artic dulce orgánico"},
    {prodId:"50314906",label:"Durazno artic glo orgánico"},
    {prodId:"50314907",label:"Durazno august fire orgánico"},
    {prodId:"50314908",label:"Durazno  perla de agosto orgánico"},
    {prodId:"50314909",label:"Durazno agosto rojo orgánico"},
    {prodId:"50314910",label:"Durazno estrella de agosto orgánico"},
    {prodId:"50314911",label:"Durazno big john orgánico"},
    {prodId:"50314912",label:"Durazno perla brillante orgánico"},
    {prodId:"50314913",label:"Durazno diamante brillante orgánico"},
    {prodId:"50314914",label:"Durazno diamante ray orgánico"},
    {prodId:"50314915",label:"Durazno earligo orgánico"},
    {prodId:"50314916",label:"Durazno diamante temprano orgánico"},
    {prodId:"50314917",label:"Durazno fairlane orgánico"},
    {prodId:"50314918",label:"Durazno fantasia orgánico"},
    {prodId:"50314919",label:"Durazno perla fuego orgánico"},
    {prodId:"50314920",label:"Durazno fuego dulce orgánico"},
    {prodId:"50314921",label:"Durazno llamakist orgánico"},
    {prodId:"50314922",label:"Durazno tipo plano orgánico"},
    {prodId:"50314923",label:"Durazno delicia de jardín orgánico"},
    {prodId:"50314924",label:"Durazno mina de oro orgánico"},
    {prodId:"50314925",label:"Durazno perla grande orgánico"},
    {prodId:"50314926",label:"Durazno hardirojo orgánico"},
    {prodId:"50314927",label:"Durazno miel de fuego orgánico"},
    {prodId:"50314928",label:"Durazno julio rojo orgánico"},
    {prodId:"50314929",label:"Durazno kay perla orgánico"},
    {prodId:"50314930",label:"Durazno key dulce orgánico"},
    {prodId:"50314931",label:"Durazno diamante mayo orgánico"},
    {prodId:"50314932",label:"Durazno mayfire orgánico"},
    {prodId:"50314933",label:"Durazno mayglo orgánico"},
    {prodId:"50314934",label:"Durazno mericrest orgánico"},
    {prodId:"50314935",label:"Durazno diamante rojo orgánico"},
    {prodId:"50314936",label:"Durazno oro rojo orgánico"},
    {prodId:"50314937",label:"Durazno jim rojo orgánico"},
    {prodId:"50314938",label:"Durazno roy rojo orgánico"},
    {prodId:"50314939",label:"Durazno rio rojo orgánico"},
    {prodId:"50314940",label:"Durazno diamante de rosa orgánico"},
    {prodId:"50314941",label:"Durazno rotal glo orgánico"},
    {prodId:"50314942",label:"Durazno diamante ryby orgánico"},
    {prodId:"50314943",label:"Durazno ruby dulce orgánico"},
    {prodId:"50314944",label:"Durazno joya ruddy orgánico"},
    {prodId:"50314945",label:"Durazno septiembre rojo orgánico"},
    {prodId:"50314946",label:"Durazno reina de nieve orgánico"},
    {prodId:"50314947",label:"Durazno primavera clara orgánico"},
    {prodId:"50314948",label:"Durazno primavera roja orgánico"},
    {prodId:"50314949",label:"Durazno verano rojiso orgánico"},
    {prodId:"50314950",label:"Durazno verano claro orgánico"},
    {prodId:"50314951",label:"Durazno verano diamante orgánico"},
    {prodId:"50314952",label:"Durazno verano fuego orgánico"},
    {prodId:"50314953",label:"Durazno verano grande orgánico"},
    {prodId:"50314954",label:"Durazno sunglo orgánico"},
    {prodId:"50314955",label:"Durazno fuego zee orgánico"},
    {prodId:"50314956",label:"Durazno zee glo orgánico"},
    {prodId:"50314957",label:"Durazno zeegrand orgánico"},
    {prodId:"50315000",label:"Naranjas orgánicas"},
    {prodId:"50315001",label:"Naranja áfrica agria orgánica"},
    {prodId:"50315002",label:"Naranja dulce amber orgánica"},
    {prodId:"50315003",label:"Naranja argentina agria orgánica"},
    {prodId:"50315004",label:"Naranja bahianinha orgánico"},
    {prodId:"50315005",label:"Naranja bergamot orgánica"},
    {prodId:"50315006",label:"Naranja berna orgánica"},
    {prodId:"50315007",label:"Naranja bigaradier apepu orgánica"},
    {prodId:"50315008",label:"Naranja agria dulce daidai orgánica"},
    {prodId:"50315009",label:"Naranja mono orgánica"},
    {prodId:"50315010",label:"Naranja sangre orgánica"},
    {prodId:"50315011",label:"Naranja california navel orgánica"},
    {prodId:"50315012",label:"Naranja cara cara orgánica"},
    {prodId:"50315013",label:"Naranja chinotto orgánica"},
    {prodId:"50315014",label:"Naranja sueño nevel orgánica"},
    {prodId:"50315015",label:"Naranja gou tou orgánica"},
    {prodId:"50315016",label:"Naranja hamlin orgánica"},
    {prodId:"50315017",label:"Naranja jaffa orgánica"},
    {prodId:"50315018",label:"Naranja jincheng orgánica"},
    {prodId:"50315019",label:"Naranja k-temprano orgánica"},
    {prodId:"50315020",label:"Naranja kona orgánica"},
    {prodId:"50315021",label:"Naranja navel tarde orgánica orgánica"},
    {prodId:"50315022",label:"Naranja valencia tarde orgánica"},
    {prodId:"50315023",label:"Naranja limequat orgánica"},
    {prodId:"50315024",label:"Naranja mar orgánica"},
    {prodId:"50315025",label:"Naranja maledorado orgánica"},
    {prodId:"50315026",label:"Naranja moro orgánica"},
    {prodId:"50315027",label:"Naranja moro tabaco orgánica"},
    {prodId:"50315028",label:"Naranja navel orgánica"},
    {prodId:"50315029",label:"Naranja navelina orgánica"},
    {prodId:"50315030",label:"Naranja oro blanco orgánica"},
    {prodId:"50315031",label:"Naranja osceola orgánica"},
    {prodId:"50315032",label:"Naranja parson carmelito orgánica"},
    {prodId:"50315033",label:"Naranja pera orgánica"},
    {prodId:"50315034",label:"Naranjo pummulo orgánica"},
    {prodId:"50315035",label:"Naranja rhode rojo orgánica"},
    {prodId:"50315036",label:"Naranja roble orgánica"},
    {prodId:"50315037",label:"Naranja salustianas orgánica"},
    {prodId:"50315038",label:"Naranja sanguine orgánica"},
    {prodId:"50315039",label:"Naranja sanguinelli orgánica"},
    {prodId:"50315040",label:"Naranja cevilla orgánica"},
    {prodId:"50315041",label:"Naranja shamouti jaffa orgánica"},
    {prodId:"50315042",label:"Naranja tunis orgánica"},
    {prodId:"50315043",label:"Naranja valencia orgánica"},
    {prodId:"50315044",label:"Naranja washington navel orgánica"},
    {prodId:"50315100",label:"Papayas orgánicas"},
    {prodId:"50315101",label:"Papaya verde cocinar orgánica"},
    {prodId:"50315102",label:"Papaya maradol orgánica"},
    {prodId:"50315103",label:"Papaya amarillo mexicano orgánica"},
    {prodId:"50315104",label:"Papaya montaña orgánica"},
    {prodId:"50315105",label:"Papaya solo orgánica"},
    {prodId:"50315106",label:"Papaya tainung orgánica"},
    {prodId:"50315200",label:"Maracuyás orgánicas"},
    {prodId:"50315201",label:"Fruta maracuyá banana orgánica"},
    {prodId:"50315202",label:"Maracuyá flor azul orgánica"},
    {prodId:"50315203",label:"Maracuyá crackerjack orgánica"},
    {prodId:"50315204",label:"Maracuyá granadilla gigante orgánica"},
    {prodId:"50315205",label:"Maracuyá granadilla dorada orgánica"},
    {prodId:"50315206",label:"Maracuyá maypops orgánica"},
    {prodId:"50315207",label:"Maracuyá roja orgánica"},
    {prodId:"50315208",label:"Maracuyá granadilla dulce orgánica"},
    {prodId:"50315209",label:"Maracuyá sandia orgánica"},
    {prodId:"50315210",label:"Maracuyá  wing- steam orgánica"},
    {prodId:"50315300",label:"Duraznos orgánicos"},
    {prodId:"50315301",label:"Durazno escudo amber orgánico"},
    {prodId:"50315302",label:"Durazno nieve de abril orgánico"},
    {prodId:"50315303",label:"Durazno dama de agosto orgánico"},
    {prodId:"50315304",label:"Durazno llama de otoño orgánico"},
    {prodId:"50315305",label:"Durazno dama de otoño orgánico"},
    {prodId:"50315306",label:"Durazno babcock orgánico"},
    {prodId:"50315307",label:"Durazno brittany lane orgánico"},
    {prodId:"50315308",label:"Durazno cary mac orgánico"},
    {prodId:"50315309",label:"Durazno clásica orgánico"},
    {prodId:"50315310",label:"Durazno dulce del campo orgánico"},
    {prodId:"50315311",label:"Durazno escudo de cielo orgánico"},
    {prodId:"50315312",label:"Durazno dama crimson orgánico"},
    {prodId:"50315313",label:"Durazno príncipe corona orgánico"},
    {prodId:"50315314",label:"Durazno sol david orgánico"},
    {prodId:"50315315",label:"Durazno  princesa diamante orgánico"},
    {prodId:"50315316",label:"Durazno earlrich orgánico"},
    {prodId:"50315317",label:"Durazno majestuosa temprana orgánico"},
    {prodId:"50315318",label:"Durazno early treat orgánico"},
    {prodId:"50315319",label:"Durazno dama elegante orgánico"},
    {prodId:"50315320",label:"Durazno emperatriz orgánico"},
    {prodId:"50315321",label:"Durazno encoré orgánico"},
    {prodId:"50315322",label:"Durazno dama elegante orgánico"},
    {prodId:"50315323",label:"Durazno príncipe de fuego orgánico"},
    {prodId:"50315324",label:"Durazno escudo de llama orgánico"},
    {prodId:"50315325",label:"Durazno tipo plano orgánico"},
    {prodId:"50315326",label:"Durazno escudo de sabor orgánico"},
    {prodId:"50315327",label:"Durazno príncipe florida orgánico"},
    {prodId:"50315328",label:"Durazno luna llena orgánico"},
    {prodId:"50315329",label:"Durazno harvester orgánico"},
    {prodId:"50315330",label:"Durazno princesa de hielo orgánico"},
    {prodId:"50315331",label:"Durazno princesa de marfil orgánico"},
    {prodId:"50315332",label:"Durazno princesa reina jersey orgánico"},
    {prodId:"50315333",label:"Durazno john henry orgánico"},
    {prodId:"50315334",label:"Durazno príncipe de junio orgánico"},
    {prodId:"50315335",label:"Durazno kaweah orgánico"},
    {prodId:"50315336",label:"Durazno klondike orgánico"},
    {prodId:"50315337",label:"Durazno lindo orgánico"},
    {prodId:"50315338",label:"Durazno loring orgánico"},
    {prodId:"50315339",label:"Durazno majestuoso orgánico"},
    {prodId:"50315340",label:"Durazno o'henry orgánico"},
    {prodId:"50315341",label:"Durazno escudo de reina orgánico"},
    {prodId:"50315342",label:"Durazno dama roja orgánico"},
    {prodId:"50315343",label:"Durazno globo rojo orgánico"},
    {prodId:"50315344",label:"Durazno cielo rojo orgánico"},
    {prodId:"50315345",label:"Durazno retop orgánico"},
    {prodId:"50315346",label:"Durazno regina orgánico"},
    {prodId:"50315347",label:"Durazno dama rica orgánico"},
    {prodId:"50315348",label:"Durazno mayo rico vo"},
    {prodId:"50315349",label:"Durazno gloria royal orgánico"},
    {prodId:"50315350",label:"Durazno dama royal orgánico"},
    {prodId:"50315351",label:"Durazno nieve de septiembre orgánico"},
    {prodId:"50315352",label:"Durazno sol de septiembre orgánico"},
    {prodId:"50315353",label:"Durazno gema sierra orgánico"},
    {prodId:"50315354",label:"Durazno angel de nieve orgánico"},
    {prodId:"50315355",label:"Durazno gema de nieve orgánico"},
    {prodId:"50315356",label:"Durazno rey de nieve orgánico"},
    {prodId:"50315357",label:"Durazno dama de primavera orgánico"},
    {prodId:"50315358",label:"Durazno nieve de primavera orgánico"},
    {prodId:"50315359",label:"Durazno escudo de primavera orgánico"},
    {prodId:"50315360",label:"Durazno dulce gigante orgánico"},
    {prodId:"50315361",label:"Durazno dama de azúcar orgánico"},
    {prodId:"50315362",label:"Durazno brillo de sol orgánico"},
    {prodId:"50315363",label:"Durazno sunhigh orgánico"},
    {prodId:"50315364",label:"Durazno dama super orgánico"},
    {prodId:"50315365",label:"Durazno super rico orgánico"},
    {prodId:"50315366",label:"Durazno surecrop orgánico"},
    {prodId:"50315367",label:"Durazno sueño dulce orgánico"},
    {prodId:"50315368",label:"Durazno septiembre dulce orgánico"},
    {prodId:"50315369",label:"Durazno vista orgánico"},
    {prodId:"50315370",label:"Durazno dama blanca orgánico"},
    {prodId:"50315371",label:"Durazno dama zee orgánico"},
    {prodId:"50315400",label:"Peras orgánicas"},
    {prodId:"50315401",label:"Peras abate fetel orgánica"},
    {prodId:"50315402",label:"Peras anjou orgánica"},
    {prodId:"50315403",label:"Pera asiática orgánica"},
    {prodId:"50315404",label:"Pera bartlett orgánica"},
    {prodId:"50315405",label:"Pera best ever orgánica"},
    {prodId:"50315406",label:"Pera beth orgánica"},
    {prodId:"50315407",label:"Pera beurre orgánica"},
    {prodId:"50315408",label:"Pera bosc orgánica"},
    {prodId:"50315409",label:"Pera clapp favorita orgánica"},
    {prodId:"50315410",label:"Pera comice orgánica"},
    {prodId:"50315411",label:"Pera concorde orgánica"},
    {prodId:"50315412",label:"Pera conference orgánica"},
    {prodId:"50315413",label:"Pera crimson rojo orgánica"},
    {prodId:"50315414",label:"Peras d' anjou orgánica"},
    {prodId:"50315415",label:"Pera dr jules guyot orgánica"},
    {prodId:"50315416",label:"Peras early orgánica"},
    {prodId:"50315417",label:"Peras emperador carmelito orgánica"},
    {prodId:"50315418",label:"Peras forelle orgánica"},
    {prodId:"50315419",label:"Pera mantequilla francesa orgánica"},
    {prodId:"50315420",label:"Pera glou morceau orgánica"},
    {prodId:"50315421",label:"Pera hosui orgánica"},
    {prodId:"50315422",label:"Pera mantequilla italiana orgánica"},
    {prodId:"50315423",label:"Pera jargonelle orgánica"},
    {prodId:"50315424",label:"Pera juno orgánica"},
    {prodId:"50315425",label:"Para kaiserlouise bonne de jersey orgánica"},
    {prodId:"50315426",label:"Pera keiffer orgánica"},
    {prodId:"50315427",label:"Pera rey royal orgánica"},
    {prodId:"50315428",label:"Pera limonera orgánica"},
    {prodId:"50315429",label:"Pera merton pride orgánica"},
    {prodId:"50315430",label:"Pera mauntain bartlette orgánica"},
    {prodId:"50315431",label:"Pera oliver de serrers orgánica"},
    {prodId:"50315432",label:"Pera onward orgánica"},
    {prodId:"50315433",label:"Pera packham's triumph orgánica"},
    {prodId:"50315434",label:"Pera paraíso orgánica"},
    {prodId:"50315435",label:"Pera passe crassane orgánica"},
    {prodId:"50315436",label:"Pera perry orgánica"},
    {prodId:"50315437",label:"Pera bartlette rojo orgánica"},
    {prodId:"50315438",label:"Pera dánjou  rojo orgánica"},
    {prodId:"50315439",label:"Pera rocha orgánica"},
    {prodId:"50315440",label:"Pera rosey rojo orgánica"},
    {prodId:"50315441",label:"Pera rosy roja orgánica"},
    {prodId:"50315442",label:"Pera majestuosa royal orgánica"},
    {prodId:"50315443",label:"Pera ruby rojo orgánica"},
    {prodId:"50315444",label:"Pera santa maria orgánica"},
    {prodId:"50315445",label:"Pera seckel orgánica"},
    {prodId:"50315446",label:"Pera sensación orgánica"},
    {prodId:"50315447",label:"Pera crimson estrella orgánica"},
    {prodId:"50315448",label:"Pera crimson stark orgánica"},
    {prodId:"50315449",label:"Pera bartlette de verano orgánica"},
    {prodId:"50315450",label:"Pera verano dorado orgánica"},
    {prodId:"50315451",label:"Pera sol dorado orgánica"},
    {prodId:"50315452",label:"Pera sunprite orgánica"},
    {prodId:"50315453",label:"Pera taylors dorado orgánica"},
    {prodId:"50315454",label:"Pera taylors rojo orgánica"},
    {prodId:"50315455",label:"Pera tientsin orgánica"},
    {prodId:"50315456",label:"Pera tosca orgánica"},
    {prodId:"50315457",label:"Pera warden  orgánica"},
    {prodId:"50315458",label:"Pera williams bon chretien orgánica"},
    {prodId:"50315459",label:"Pera williams orgánica"},
    {prodId:"50315460",label:"Pera nelis de invierno orgánica"},
    {prodId:"50315500",label:"Caquis orgánicos"},
    {prodId:"50315501",label:"Caqui americano orgánica"},
    {prodId:"50315502",label:"Caqui sapote negro orgánica"},
    {prodId:"50315503",label:"Caqui chapote / negro orgánica"},
    {prodId:"50315504",label:"Caqui dátale ciruela orgánica"},
    {prodId:"50315505",label:"Caqui fuyu orgánica"},
    {prodId:"50315506",label:"Caqui gigante fuyu orgánica"},
    {prodId:"50315507",label:"Caqui hachiya orgánica"},
    {prodId:"50315508",label:"Caqui mantequilla / mabolo orgánica"},
    {prodId:"50315509",label:"Caqui príncipe ito orgánica"},
    {prodId:"50315510",label:"Caqui brillante royal orgánica"},
    {prodId:"50315511",label:"Caqui sharon orgánica"},
    {prodId:"50315512",label:"Caqui triumph orgánica"},
    {prodId:"50315600",label:"Piñas orgánicas"},
    {prodId:"50315601",label:"Piña chirimoya orgánica"},
    {prodId:"50315602",label:"Piña dorada orgánica"},
    {prodId:"50315603",label:"Piña hilo orgánica"},
    {prodId:"50315604",label:"Piña kona sugarloaf orgánica"},
    {prodId:"50315605",label:"Piña reina natal orgánica"},
    {prodId:"50315606",label:"Piña pernambuco orgánica"},
    {prodId:"50315607",label:"Piña español rojo orgánica"},
    {prodId:"50315608",label:"Piña cayen suave orgánica"},
    {prodId:"50315609",label:"Piña sugarloaf orgánica"},
    {prodId:"50315610",label:"Piña variegated orgánica"},
    {prodId:"50315700",label:"Ciruelos Damascos orgánicos"},
    {prodId:"50315701",label:"Ciruelo / damasco negro kat orgánico"},
    {prodId:"50315702",label:"Ciruelo / damasco gusto azul orgánio"},
    {prodId:"50315703",label:"Ciruelo / damasco corazón de crimson orgánico"},
    {prodId:"50315704",label:"Ciruelo / damasco dapply dandy orgánico"},
    {prodId:"50315705",label:"Ciruelo / damasco dapple fuego orgánico"},
    {prodId:"50315706",label:"Ciruelo / damasco  dapple temprano orgánico"},
    {prodId:"50315707",label:"Ciruelo / damasco  caída de sabor orgánico"},
    {prodId:"50315708",label:"Ciruelo / damasco sabor de oro orgánico"},
    {prodId:"50315709",label:"Ciruelo / damasco sabor granada orgánico"},
    {prodId:"50315710",label:"Ciruelo / damasco sabor de corazón orgánico"},
    {prodId:"50315711",label:"Ciruelo / damasco joya de sabor orgánico"},
    {prodId:"50315712",label:"Ciruelo / damasco sabor de rey orgánico"},
    {prodId:"50315713",label:"Ciruelo / damasco sabor de reina orgánico"},
    {prodId:"50315714",label:"Ciruelo / damasco sabor supremo orgánico"},
    {prodId:"50315715",label:"Ciruelo / damasco sabor premio orgánico"},
    {prodId:"50315716",label:"Ciruelo / damasco saborella orgánico"},
    {prodId:"50315717",label:"Ciruelo / damasco saborrico orgánico"},
    {prodId:"50315718",label:"Ciruelo / damasco  sabor rosa orgánico"},
    {prodId:"50315719",label:"Ciruelo / damasco orgullo geo orgánico"},
    {prodId:"50315720",label:"Ciruelo / damasco kat rojo orgánico"},
    {prodId:"50315721",label:"Ciruelo / damasco premio royal orgánico"},
    {prodId:"50315722",label:"Ciruelo / damasco rosa sierra orgánico"},
    {prodId:"50315723",label:"Ciruelo / damasco geisha dulce orgánico"},
    {prodId:"50315800",label:"Ciruelas orgánicas"},
    {prodId:"50315801",label:"Ciruela joya amber orgánica"},
    {prodId:"50315802",label:"Ciruela angeleno orgánica"},
    {prodId:"50315803",label:"Ciruela aurora orgánica"},
    {prodId:"50315804",label:"Ciruela otoño bonito orgánica"},
    {prodId:"50315805",label:"Ciruela gigante de otoño orgánica"},
    {prodId:"50315806",label:"Ciruela orgullo de otoño orgánica"},
    {prodId:"50315807",label:"Ciruela rosa de otoño orgánica"},
    {prodId:"50315808",label:"Ciruela playero orgánica"},
    {prodId:"50315809",label:"Ciruela betty anne orgánica"},
    {prodId:"50315810",label:"Ciruela belleza negra orgánica"},
    {prodId:"50315811",label:"Ciruela bullase negra orgánica"},
    {prodId:"50315812",label:"Ciruela diamante negro orgánica"},
    {prodId:"50315813",label:"Ciruela gigante negro orgánica"},
    {prodId:"50315814",label:"Ciruela hielo negro orgánica"},
    {prodId:"50315815",label:"Ciruela esplendor negro orgánica"},
    {prodId:"50315816",label:"Ciruela ámbar negro orgánica"},
    {prodId:"50315817",label:"Ciruela purpura orgánica"},
    {prodId:"50315818",label:"Ciruela  carsbad orgánica"},
    {prodId:"50315819",label:"Ciruela casselman orgánica"},
    {prodId:"50315820",label:"Ciruela catalina orgánica"},
    {prodId:"50315821",label:"Ciruela damson orgánica"},
    {prodId:"50315822",label:"Ciruela dolly orgánica"},
    {prodId:"50315823",label:"Ciruela earliqueen orgánica"},
    {prodId:"50315824",label:"Ciruela rosa early orgánica"},
    {prodId:"50315825",label:"Ciruela ébano mayo orgánica"},
    {prodId:"50315826",label:"Ciruela ébano orgánica"},
    {prodId:"50315827",label:"Ciruela corazón de elefante orgánica"},
    {prodId:"50315828",label:"Ciruela belleza de esmeralda orgánica"},
    {prodId:"50315829",label:"Ciruela emperatriz orgánica"},
    {prodId:"50315830",label:"Ciruela libertad orgánica"},
    {prodId:"50315831",label:"Ciruela friar orgánica"},
    {prodId:"50315832",label:"Ciruela rojo gar orgánica"},
    {prodId:"50315833",label:"Ciruela gobernador orgánica"},
    {prodId:"50315834",label:"Ciruela rosa grande orgánica"},
    {prodId:"50315835",label:"Ciruela green gage orgánica"},
    {prodId:"50315836",label:"Ciruela greengage orgánica"},
    {prodId:"50315837",label:"Ciruela hiromi orgánica"},
    {prodId:"50315838",label:"Ciruela hiromi rojo orgánica"},
    {prodId:"50315839",label:"Ciruela vacacion orgánica"},
    {prodId:"50315840",label:"Ciruela howard sol orgánica"},
    {prodId:"50315841",label:"Ciruela tipo interspecific orgánica"},
    {prodId:"50315842",label:"Ciruela jamaico orgánica"},
    {prodId:"50315843",label:"Ciruela joanna rojo orgánica"},
    {prodId:"50315844",label:"Ciruela kelsey orgánica"},
    {prodId:"50315845",label:"Ciruela jaime rey orgánica"},
    {prodId:"50315846",label:"Ciruela laroda orgánica"},
    {prodId:"50315847",label:"Ciruela rosa tarde orgánica"},
    {prodId:"50315848",label:"Ciruela rosa linda orgánica"},
    {prodId:"50315849",label:"Ciruela estrella solitaria orgánica"},
    {prodId:"50315850",label:"Ciruela mariposa orgánica"},
    {prodId:"50315851",label:"Ciruela mercado negro orgánica"},
    {prodId:"50315852",label:"Ciruela mercado rojo orgánica"},
    {prodId:"50315853",label:"Ciruela maribel orgánica"},
    {prodId:"50315854",label:"Ciruelas sol de octubre orgánica"},
    {prodId:"50315855",label:"Ciruela owen t orgánica"},
    {prodId:"50315856",label:"Ciruela perdrigon orgánica"},
    {prodId:"50315857",label:"Ciruela placer rosado orgánica"},
    {prodId:"50315858",label:"Ciruela  presidente orgánica"},
    {prodId:"50315859",label:"Ciruela hora prima orgánica"},
    {prodId:"50315860",label:"Ciruela majestad purpura orgánica"},
    {prodId:"50315861",label:"Ciruela reina rosa orgánica"},
    {prodId:"50315862",label:"Ciruela quetsch orgánica"},
    {prodId:"50315863",label:"Ciruela belleza roja orgánica"},
    {prodId:"50315864",label:"Ciruela camino rojo orgánica"},
    {prodId:"50315865",label:"Ciruela ram rojo orgánica"},
    {prodId:"50315866",label:"Ciruela rosa roja orgánica"},
    {prodId:"50315867",label:"Ciruela rojo rico orgánica"},
    {prodId:"50315868",label:"Ciruela romero orgánica"},
    {prodId:"50315869",label:"Ciruela diamante rojo orgánica"},
    {prodId:"50315870",label:"Ciruela rojo royal orgánica"},
    {prodId:"50315871",label:"Ciruela royal zee orgánica"},
    {prodId:"50315872",label:"Ciruela roysum orgánica"},
    {prodId:"50315873",label:"Ciruela santa rosa orgánica"},
    {prodId:"50315874",label:"Ciruela zafiro orgánica"},
    {prodId:"50315875",label:"Ciruela sloe orgánica"},
    {prodId:"50315876",label:"Ciruela st catherine orgánica"},
    {prodId:"50315877",label:"Ciruela bullase blanca orgánica"},
    {prodId:"50315900",label:"Granadas orgánicas"},
    {prodId:"50315901",label:"Granada foothhill orgánica"},
    {prodId:"50315902",label:"Granada granada orgánica"},
    {prodId:"50315903",label:"Granada rojo feliz orgánica"},
    {prodId:"50315904",label:"Granada nana orgánica"},
    {prodId:"50315905",label:"Granada rojo de pat orgánica"},
    {prodId:"50315906",label:"Granada pinkhan orgánic"},
    {prodId:"50315907",label:"Granada terciopelo morado orgánica"},
    {prodId:"50315908",label:"Granada grandioso orgánica"},
    {prodId:"50316000",label:"Pomelos orgánicos"},
    {prodId:"50316001",label:"Pomelo chandler orgánico"},
    {prodId:"50316002",label:"Pomelo hirado butan orgánico"},
    {prodId:"50316003",label:"Pomelo liang ping yau orgánico"},
    {prodId:"50316004",label:"Pomelo panda wangi orgánico"},
    {prodId:"50316005",label:"Pomelo rosado orgánico"},
    {prodId:"50316006",label:"Pomelo shaddock rojo orgánico"},
    {prodId:"50316007",label:"Pomelo siamese dulce orgánico"},
    {prodId:"50316008",label:"Pomelo waingwright orgánico"},
    {prodId:"50316100",label:"Membrillos orgánicos"},
    {prodId:"50316101",label:"Membrillo campeón orgánico"},
    {prodId:"50316102",label:"Membrillo piña orgánico"},
    {prodId:"50316103",label:"Membrillo smyma orgánico"},
    {prodId:"50316300",label:"Ruibarbos orgánicos"},
    {prodId:"50316301",label:"Ruibarbo crimson rojo orgánico"},
    {prodId:"50316302",label:"Ruibarbo champagne temprana orgánico"},
    {prodId:"50316303",label:"Ruibarbo glasrey perpetual  orgánico"},
    {prodId:"50316304",label:"Ruibarbo sutton orgánico"},
    {prodId:"50316305",label:"Ruibarbo timperley temprano orgánico"},
    {prodId:"50316306",label:"Ruibarbo valentina orgánico"},
    {prodId:"50316307",label:"Ruibarbo victoria orgánico"},
    {prodId:"50316308",label:"Ruibarbo zwolle de cemilla orgánico"},
    {prodId:"50316309",label:"Ruibarbo macdonald orgánico"},
    {prodId:"50316310",label:"Ruibarbo tilden orgánico"},
    {prodId:"50316400",label:"Escaramujos orgánicos"},
    {prodId:"50316401",label:"Escaramujo rosa brier orgánico"},
    {prodId:"50316402",label:"Escaramujo rosa elegante orgánico"},
    {prodId:"50316403",label:"Escaramujo rosa rugosa orgánico"},
    {prodId:"50316404",label:"Escaramujo rosa burnet o scotch orgánico"},
    {prodId:"50316500",label:"Sapotes orgánicos"},
    {prodId:"50316501",label:"Zapote blanco orgánico"},
    {prodId:"50316502",label:"Zapote negro orgánico"},
    {prodId:"50316600",label:"Bayas saskatoon orgánicas"},
    {prodId:"50316601",label:"Baya mielmadera saskatoon orgánica"},
    {prodId:"50316602",label:"Baya northline saskatoon orgánica"},
    {prodId:"50316603",label:"Baya saskatoon ahumado orgánica"},
    {prodId:"50316604",label:"Baya saskatoon thiessen orgánica"},
    {prodId:"50316800",label:"Manzanas dulces orgánicas"},
    {prodId:"50316801",label:"Manzana kampong malve dulce orgánica"},
    {prodId:"50316802",label:"Manzana dulce sin pepa orgánica"},
    {prodId:"50316803",label:"Manzana dulce thai lessand orgánica"},
    {prodId:"50316900",label:"Tamarillos orgánicos"},
    {prodId:"50316901",label:"Tamarindo amberlea dorado orgánico"},
    {prodId:"50316902",label:"Tamarindo calvo dorado orgánico"},
    {prodId:"50316903",label:"Tamarindo mina de oro orgánico"},
    {prodId:"50316904",label:"Tamarindo oratia rojo orgánico"},
    {prodId:"50316905",label:"Tamarindo beau rojo orgánico"},
    {prodId:"50316906",label:"Tamarindo delicia roja orgánico"},
    {prodId:"50317100",label:"Aronias orgánicas"},
    {prodId:"50317101",label:"Aronia magia de otoño orgánica"},
    {prodId:"50317102",label:"Aronia brillantísima orgánica"},
    {prodId:"50317103",label:"Aronia nero orgánica"},
    {prodId:"50317104",label:"Aronia vikingo orgánica"},
    {prodId:"50317200",label:"Aceitunas orgánicas"},
    {prodId:"50317201",label:"Aceitunas agrinion orgánica"},
    {prodId:"50317202",label:"Aceitunas aleppo orgánica"},
    {prodId:"50317203",label:"Aceitunas  alfonso orgánica"},
    {prodId:"50317204",label:"Aceitunas  amfisa orgánica"},
    {prodId:"50317205",label:"Aceitunas  arauco orgánica"},
    {prodId:"50317206",label:"Aceitunas  arbequina orgánica"},
    {prodId:"50317207",label:"Aceitunas  atlanta orgánica"},
    {prodId:"50317208",label:"Aceitunas  cerignola orgánica"},
    {prodId:"50317209",label:"Aceitunas  cracked provencal orgánica"},
    {prodId:"50317210",label:"Aceitunas  empeltre orgánica"},
    {prodId:"50317211",label:"Aceitunas gaeta  orgánica"},
    {prodId:"50317212",label:"Aceitunas hondoelia orgánica"},
    {prodId:"50317213",label:"Aceitunas kalamata orgánica"},
    {prodId:"50317214",label:"Aceitunas kura orgánica"},
    {prodId:"50317215",label:"Aceitunas ligurian orgánica"},
    {prodId:"50317216",label:"Aceitunas lucque orgánica"},
    {prodId:"50317217",label:"Aceitunas lugano orgánica"},
    {prodId:"50317218",label:"Aceitunas manzanilla orgánica"},
    {prodId:"50317219",label:"Aceitunas marche orgánica"},
    {prodId:"50317220",label:"Aceitunas misión orgánica"},
    {prodId:"50317221",label:"Aceitunas nafplion verde orgánica"},
    {prodId:"50317222",label:"Aceitunas nicoise orgánica"},
    {prodId:"50317223",label:"Aceitunas nyons orgánica"},
    {prodId:"50317224",label:"Aceitunas picholine orgánica"},
    {prodId:"50317225",label:"Aceitunas ponentine orgánica"},
    {prodId:"50317226",label:"Aceitunas real orgánica"},
    {prodId:"50317227",label:"Aceitunas seracena orgánica"},
    {prodId:"50317228",label:"Aceitunas sevillano orgánica"},
    {prodId:"50317229",label:"Aceitunas sicilian orgánica"},
    {prodId:"50317230",label:"Aceitunas toscanella orgánica"},
    {prodId:"50321800",label:"Barberries secos"},
    {prodId:"50321801",label:"Berberís paleleaf seca"},
    {prodId:"50321802",label:"Barberís chenault seca"},
    {prodId:"50321803",label:"Barberries roja seca"},
    {prodId:"50321804",label:"Barberís winterverde seca"},
    {prodId:"50321805",label:"Barberís korean seca"},
    {prodId:"50321806",label:"Barberís mentor seca"},
    {prodId:"50321807",label:"Barberís japanese seca"},
    {prodId:"50321808",label:"Barberís atropurpurea seca"},
    {prodId:"50321809",label:"Barberís aurea seca"},
    {prodId:"50321810",label:"Barberís bagatelle seca"},
    {prodId:"50321811",label:"Barberís crimsom pygmy seca"},
    {prodId:"50321812",label:"Barberís kobold seca"},
    {prodId:"50321813",label:"Barberís warty seca"},
    {prodId:"50321814",label:"Barberís european seca"},
    {prodId:"50321900",label:"Gayubas secas"},
    {prodId:"50321901",label:"Gayuba alpine seca"},
    {prodId:"50321902",label:"Gayuba roja seca"},
    {prodId:"50321903",label:"Gayuba comun seca"},
    {prodId:"50322000",label:"Moras secas"},
    {prodId:"50322001",label:"Mora apache seca"},
    {prodId:"50322002",label:"Mora negro satin seca"},
    {prodId:"50322003",label:"Mora seca"},
    {prodId:"50322004",label:"Mora cherokee seca"},
    {prodId:"50322005",label:"Mora chester seca"},
    {prodId:"50322006",label:"Mora dirksen seca"},
    {prodId:"50322007",label:"Bayas josta seca"},
    {prodId:"50322008",label:"Bayas logan seca"},
    {prodId:"50322009",label:"Bayas marion seca"},
    {prodId:"50322010",label:"Mora navaho seca"},
    {prodId:"50322011",label:"Baya nectar seca"},
    {prodId:"50322012",label:"Mora olallie seca"},
    {prodId:"50322013",label:"Baya tay seca"},
    {prodId:"50322014",label:"Mora thomless hull seca"},
    {prodId:"50322015",label:"Baya joven seca"},
    {prodId:"50322100",label:"Arándanos de mirto secos"},
    {prodId:"50322101",label:"Arandanos bog seca"},
    {prodId:"50322102",label:"Arandanos enana seca"},
    {prodId:"50322103",label:"Arandanos montaña seca"},
    {prodId:"50322104",label:"Arandanos oval-leaved seca"},
    {prodId:"50322200",label:"Agrás secas"},
    {prodId:"50322201",label:"Agras bluecrop seca"},
    {prodId:"50322202",label:"Agras bluetta seca"},
    {prodId:"50322203",label:"Agras brigitta seca"},
    {prodId:"50322204",label:"Agras chandler seca"},
    {prodId:"50322205",label:"Agras duque seca"},
    {prodId:"50322206",label:"Agras hardyblue seca"},
    {prodId:"50322207",label:"Agras legacy seca"},
    {prodId:"50322208",label:"Agras misty seca"},
    {prodId:"50322209",label:"Agras nelson seca"},
    {prodId:"50322210",label:"Agras northblue seca"},
    {prodId:"50322211",label:"Agras northcountry seca"},
    {prodId:"50322212",label:"Agras northsky seca"},
    {prodId:"50322213",label:"Agras patriot seca"},
    {prodId:"50322214",label:"Agras spartan seca"},
    {prodId:"50322215",label:"Agras toro seca"},
    {prodId:"50322300",label:"Frutas del pan secas"},
    {prodId:"50322301",label:"Fruta de pan chataigne seca"},
    {prodId:"50322302",label:"Fruta de pan sin pepa seca"},
    {prodId:"50322303",label:"Fruta de pan corazón blanco seca"},
    {prodId:"50322304",label:"Fruta de pan corazón amarillo seca"},
    {prodId:"50322400",label:"Chirimoyas secas"},
    {prodId:"50322401",label:"Chirimoya chays seca"},
    {prodId:"50322402",label:"Chirimoya bronceada seca"},
    {prodId:"50322403",label:"Chirimoya burtons seca"},
    {prodId:"50322404",label:"Chirimoya burton favorite seca"},
    {prodId:"50322405",label:"Chirimoya jete seca"},
    {prodId:"50322406",label:"Chirimoya reretai seca"},
    {prodId:"50322407",label:"Chirimoya smoothey seca"},
    {prodId:"50322408",label:"Chirimoya españa seca"},
    {prodId:"50322409",label:"Chirimoya blanco seca"},
    {prodId:"50322600",label:"Citrones secos"},
    {prodId:"50322601",label:"Citrones buddha's hand seca"},
    {prodId:"50322602",label:"Citrones fingeroja seca"},
    {prodId:"50322603",label:"Citrones fo shoukan seca"},
    {prodId:"50322604",label:"Cotrones bushakan seca"},
    {prodId:"50322605",label:"Citones diamante seca"},
    {prodId:"50322606",label:"Citrones etrog seca"},
    {prodId:"50322607",label:"Citrones ponderosa seca"},
    {prodId:"50322800",label:"pasas de Corinto secas"},
    {prodId:"50322801",label:"Pasa hudson bay seca"},
    {prodId:"50322802",label:"Pasa waxy seca"},
    {prodId:"50322803",label:"Pasa desert seca"},
    {prodId:"50322804",label:"Pasa negra seca"},
    {prodId:"50322805",label:"Pasa roja seca"},
    {prodId:"50322806",label:"Pasa blanca seca"},
    {prodId:"50322900",label:"Dátiles secos"},
    {prodId:"50322901",label:"Dátiles asharasi  seca"},
    {prodId:"50322902",label:"Dátiles barhi o barhee seca"},
    {prodId:"50322903",label:"Dátiles deglet noor seca"},
    {prodId:"50322904",label:"Dátiles fardh seca"},
    {prodId:"50322905",label:"Dátiles gundila seca"},
    {prodId:"50322906",label:"Dátiles halawi/halawy seca"},
    {prodId:"50322907",label:"Dátiles hilali seca"},
    {prodId:"50322908",label:"Dátiles khadrawi/khadrawy seca"},
    {prodId:"50322909",label:"Dátiles khalas seca"},
    {prodId:"50322910",label:"Dátiles khustawi seca"},
    {prodId:"50322911",label:"Dátiles khidri seca"},
    {prodId:"50322912",label:"Dátiles medjool/ medjol seca"},
    {prodId:"50322913",label:"Dátiles mactoum seca"},
    {prodId:"50322914",label:"Dátiles neghal seca"},
    {prodId:"50322915",label:"Dátiles yatimeh seca"},
    {prodId:"50322916",label:"Dátiles zahidi seca"},
    {prodId:"50323000",label:"Pitahayas secas"},
    {prodId:"50323001",label:"Pitahaya roja, rosada seca"},
    {prodId:"50323002",label:"Pitahaya roja, amarillo seca"},
    {prodId:"50323100",label:"Brevas secas"},
    {prodId:"50323101",label:"Breva bardajic seca"},
    {prodId:"50323102",label:"Breva brown turkey seca"},
    {prodId:"50323103",label:"Breva calimyma  seca"},
    {prodId:"50323104",label:"Breva conadria seca"},
    {prodId:"50323105",label:"Breva dotado seca"},
    {prodId:"50323106",label:"Breva kadota seca"},
    {prodId:"50323107",label:"Breva mediterránea seca"},
    {prodId:"50323108",label:"Breva misión seca"},
    {prodId:"50323109",label:"Breva amyma seca"},
    {prodId:"50323110",label:"Breva verdona seca"},
    {prodId:"50323111",label:"Breva rey blanco seca"},
    {prodId:"50323200",label:"Grosellas secas"},
    {prodId:"50323201",label:"Grosella early sulphur seca"},
    {prodId:"50323202",label:"Grosella dorado caida seca"},
    {prodId:"50323203",label:"Grosella langley grace seca"},
    {prodId:"50323204",label:"Grocella leveller seca"},
    {prodId:"50323205",label:"Grosella london seca"},
    {prodId:"50323206",label:"Grosella worcestershire seca"},
    {prodId:"50323207",label:"Grosella americana  worcesterberry sec"},
    {prodId:"50323300",label:"Toronjas secas"},
    {prodId:"50323301",label:"Toronja burgundi seca"},
    {prodId:"50323302",label:"Toronja duncan seca"},
    {prodId:"50323303",label:"Toronja foster seca"},
    {prodId:"50323304",label:"Toronja marsh seca"},
    {prodId:"50323305",label:"Toronja nueva zelandia seca"},
    {prodId:"50323306",label:"Toronja rio rojo seca"},
    {prodId:"50323307",label:"Toronja ruby rojo seca"},
    {prodId:"50323308",label:"Toronja star ruby seca"},
    {prodId:"50323309",label:"Toronja triumph seca"},
    {prodId:"50323600",label:"Uvas para vino secas"},
    {prodId:"50323601",label:"Uva alicante bouschet seca"},
    {prodId:"50323602",label:"Uva barbera seca"},
    {prodId:"50323603",label:"Uva burger seca"},
    {prodId:"50323604",label:"Uva cabemet franc seca"},
    {prodId:"50323605",label:"Uva cabenet sauvignon seca"},
    {prodId:"50323606",label:"Uva carignane seca"},
    {prodId:"50323607",label:"Uva carnelian seca"},
    {prodId:"50323608",label:"Uva catarratto seca"},
    {prodId:"50323609",label:"Uva centurian seca"},
    {prodId:"50323610",label:"Uva charbono seca"},
    {prodId:"50323611",label:"Uva chardonnay seca"},
    {prodId:"50323612",label:"Uva chenin blanco seca"},
    {prodId:"50323613",label:"Uva cinsaut seca"},
    {prodId:"50323614",label:"Uva dolcetto seca"},
    {prodId:"50323615",label:"Uva esmeralda  riesling seca"},
    {prodId:"50323616",label:"Uva french colombard seca"},
    {prodId:"50323617",label:"Uva granny nap seca"},
    {prodId:"50323618",label:"Uva gamay beaujolais seca"},
    {prodId:"50323619",label:"Uva gewurztraminer seca"},
    {prodId:"50323620",label:"Uva grnache seca"},
    {prodId:"50323621",label:"Uva grinache blanc seca"},
    {prodId:"50323622",label:"Uva lagrein seca"},
    {prodId:"50323623",label:"Uva lambruso seca"},
    {prodId:"50323624",label:"Uva malbec seca"},
    {prodId:"50323625",label:"Uva malvasía bianca seca"},
    {prodId:"50323626",label:"Uva marsanne seca"},
    {prodId:"50323627",label:"Uva matano seca"},
    {prodId:"50323628",label:"Uva merlot seca"},
    {prodId:"50323629",label:"Uva meunier seca"},
    {prodId:"50323630",label:"Uva missiom seca"},
    {prodId:"50323631",label:"Uva montepulceano seca"},
    {prodId:"50323632",label:"Uva muscat blanc seca"},
    {prodId:"50323633",label:"Uva muscat hamburg seca"},
    {prodId:"50323634",label:"Uva muscat alexandria seca"},
    {prodId:"50323635",label:"Uva muscat naranja seca"},
    {prodId:"50323636",label:"Uva nebbiolo seca"},
    {prodId:"50323637",label:"Uva palomino seca"},
    {prodId:"50323638",label:"Uva petit verdot seca"},
    {prodId:"50323639",label:"Uva petite sirah seca"},
    {prodId:"50323640",label:"Uva pinot blanc seca"},
    {prodId:"50323641",label:"Uva pinot gris seca"},
    {prodId:"50323642",label:"Uva pinot noir seca"},
    {prodId:"50323643",label:"Uva primitivo seca"},
    {prodId:"50323644",label:"Uva roussane seca"},
    {prodId:"50323645",label:"Uva realeza seca"},
    {prodId:"50323646",label:"Uva rubiroja seca"},
    {prodId:"50323647",label:"Uva ruby cabernet seca"},
    {prodId:"50323648",label:"Uva salvador seca"},
    {prodId:"50323649",label:"Uva sangiovese seca"},
    {prodId:"50323650",label:"Uva sauvignon blanc seca"},
    {prodId:"50323651",label:"Uva sauvignon musque seca"},
    {prodId:"50323652",label:"Uva semillon seca"},
    {prodId:"50323653",label:"Uva souzao seca"},
    {prodId:"50323654",label:"Uva st emilion seca"},
    {prodId:"50323655",label:"Uva symphony seca"},
    {prodId:"50323656",label:"Uva syrah  seca"},
    {prodId:"50323657",label:"Uva tannat seca"},
    {prodId:"50323658",label:"Uva tempranillo seca"},
    {prodId:"50323659",label:"Uvas teroldego seca"},
    {prodId:"50323660",label:"Uvas tocai friulano seca"},
    {prodId:"50323661",label:"Uvas touriga nacional seca"},
    {prodId:"50323662",label:"Uvas triplett blanc seca"},
    {prodId:"50323663",label:"Uvas viognier seca"},
    {prodId:"50323664",label:"Uvas blanco riesling seca"},
    {prodId:"50323665",label:"Uvas zinfandel seca"},
    {prodId:"50323700",label:"Guayabas secas"},
    {prodId:"50323701",label:"Guayaba beaumont seca"},
    {prodId:"50323702",label:"Guayaba carrley seca"},
    {prodId:"50323703",label:"Guayaba lucida seca"},
    {prodId:"50323704",label:"Guayaba piña seca"},
    {prodId:"50324000",label:"Naranjos Chinos (kumquats) secos"},
    {prodId:"50324001",label:"Naranjo hong kong seco"},
    {prodId:"50324002",label:"Naranjo limequat seco"},
    {prodId:"50324003",label:"Naranjo long fruit seco"},
    {prodId:"50324004",label:"Naranjo malayo seco"},
    {prodId:"50324005",label:"Naranjo meiwa seco"},
    {prodId:"50324006",label:"Naranja nagami seco"},
    {prodId:"50324100",label:"Limones secos"},
    {prodId:"50324101",label:"Limón baboon seco"},
    {prodId:"50324102",label:"Limón bearss sicilian seco"},
    {prodId:"50324103",label:"Limón cameron highland seco"},
    {prodId:"50324104",label:"Limón escondido seco"},
    {prodId:"50324105",label:"Limón eureka seco"},
    {prodId:"50324106",label:"Limón lisbon seco"},
    {prodId:"50324107",label:"Limón meyer seco"},
    {prodId:"50324108",label:"Limón volkaner seco"},
    {prodId:"50324200",label:"Limas secas"},
    {prodId:"50324201",label:"Lima limón indian sweet seco"},
    {prodId:"50324202",label:"Lima limón seco"},
    {prodId:"50324203",label:"Lima limón mandarin seco"},
    {prodId:"50324204",label:"Lima limón philippine seco"},
    {prodId:"50324205",label:"Lima limón tahitian seco"},
    {prodId:"50324206",label:"Lima limón bearss seco"},
    {prodId:"50324207",label:"Lima limón persa seco"},
    {prodId:"50324208",label:"Lima limón sin pepas seco"},
    {prodId:"50324300",label:"Nísperos secos"},
    {prodId:"50324301",label:"Níspero japonés advance seco"},
    {prodId:"50324302",label:"Níspero japonés benlehr seco"},
    {prodId:"50324303",label:"Níspero japonés big jim seco"},
    {prodId:"50324304",label:"Níspero japonés champagne seco"},
    {prodId:"50324305",label:"Níspero japonés early rojo seco"},
    {prodId:"50324306",label:"Níspero japonés nugget dorado seco"},
    {prodId:"50324307",label:"Níspero japonés herd's mammoth seco"},
    {prodId:"50324308",label:"Níspero japonés mogi seco"},
    {prodId:"50324309",label:"Níspero japonés mrs cooksey seco"},
    {prodId:"50324310",label:"Níspero japonés fresa seco"},
    {prodId:"50324311",label:"Níspero japonés tanaka seco"},
    {prodId:"50324312",label:"Níspero japonés victoria vista blanco seco"},
    {prodId:"50324313",label:"Níspero japonés wolfe seco"},
    {prodId:"50324400",label:"Mandarinas secas"},
    {prodId:"50324401",label:"Naranjas clauselinas secas"},
    {prodId:"50324402",label:"Mandarinas clementinas secas"},
    {prodId:"50324403",label:"Naranja mandarina cleopatra"},
    {prodId:"50324404",label:"Mandarina dancy seca"},
    {prodId:"50324405",label:"Naranja ellensday seca"},
    {prodId:"50324406",label:"Naranja fairchild seca"},
    {prodId:"50324407",label:"Naranja fallglo seca"},
    {prodId:"50324408",label:"Naranja fortuna seca"},
    {prodId:"50324409",label:"Mandarina naranja fremont seca"},
    {prodId:"50324410",label:"Naranja fremont seca"},
    {prodId:"50324411",label:"Naranja nuget dorada seca"},
    {prodId:"50324412",label:"Mandarina naranja miel seca"},
    {prodId:"50324413",label:"Naranja miel seca"},
    {prodId:"50324414",label:"Mandarina miel seca"},
    {prodId:"50324415",label:"Natanja tanjelo honebelle seca"},
    {prodId:"50324416",label:"Naranja mandarina rey seca"},
    {prodId:"50324417",label:"Naranja kinnow seca"},
    {prodId:"50324418",label:"Naranja andarinalee seca"},
    {prodId:"50324419",label:"Naranja makokkee seca"},
    {prodId:"50324420",label:"Naranja malvasion seca"},
    {prodId:"50324421",label:"Naranja mandarina mediterránea seca"},
    {prodId:"50324422",label:"Naranja tangelo minneola seca"},
    {prodId:"50324423",label:"Naranja monica seca"},
    {prodId:"50324424",label:"Naranja murcott miel seca"},
    {prodId:"50324425",label:"Naranja murcott tangors seca"},
    {prodId:"50324426",label:"Naranja mandarina natsudaidai  seca"},
    {prodId:"50324427",label:"Naranja mandarina natsumikan seca"},
    {prodId:"50324428",label:"Naranja tanjelo nocatee seca"},
    {prodId:"50324429",label:"Naranja tanjelo orlando seca"},
    {prodId:"50324430",label:"Mandarina ortanique seca"},
    {prodId:"50324431",label:"Naranja mandarina pagina seca"},
    {prodId:"50324432",label:"Naranja pixie seca"},
    {prodId:"50324433",label:"Naranja mandarina ponkan bantangas seca"},
    {prodId:"50324434",label:"Naranja reina seca"},
    {prodId:"50324435",label:"Naranja robinson seca"},
    {prodId:"50324436",label:"Naranja saltenitas seca"},
    {prodId:"50324437",label:"Naranja tangelo sampson seca"},
    {prodId:"50324438",label:"Naranja mandarina satsuma seca"},
    {prodId:"50324439",label:"Naranja mandarina sunburst seca"},
    {prodId:"50324440",label:"Tangelo seco"},
    {prodId:"50324441",label:"Naranja mandarina seca"},
    {prodId:"50324442",label:"Naranja templo seca"},
    {prodId:"50324443",label:"Naranja thornton seca"},
    {prodId:"50324444",label:"Mandarina wekiwa seca"},
    {prodId:"50324445",label:"Mandarina wilkins seca"},
    {prodId:"50324446",label:"Mandarina willowleaf mediterránea seca"},
    {prodId:"50324700",label:"Moras mulberry secas"},
    {prodId:"50324701",label:"Mora negra seca"},
    {prodId:"50324702",label:"Mora blanca seca"},
    {prodId:"50324800",label:"Mirtos secos"},
    {prodId:"50324801",label:"Mirto bog seco"},
    {prodId:"50324900",label:"Nectarinas secos"},
    {prodId:"50324901",label:"Durazno april glo seco"},
    {prodId:"50324902",label:"Durazno arctic mist seco"},
    {prodId:"50324903",label:"Durazno artic snow seco"},
    {prodId:"50324904",label:"Durazno artic star seco"},
    {prodId:"50324905",label:"Durazno artic dulce seco"},
    {prodId:"50324906",label:"Durazno artic glo seco"},
    {prodId:"50324907",label:"Durazno august fire seco"},
    {prodId:"50324908",label:"Durazno  perla de agosto seco"},
    {prodId:"50324909",label:"Durazno agosto rojo seco"},
    {prodId:"50324910",label:"Durazno estrella de agosto seco"},
    {prodId:"50324911",label:"Durazno big john seco"},
    {prodId:"50324912",label:"Durazno perla brillante seco"},
    {prodId:"50324913",label:"Durazno diamante brillante seco"},
    {prodId:"50324914",label:"Durazno diamante ray seco"},
    {prodId:"50324915",label:"Durazno earligo seco"},
    {prodId:"50324916",label:"Durazno diamante temprano seco"},
    {prodId:"50324917",label:"Durazno fairlane seco"},
    {prodId:"50324918",label:"Durazno fantasia seco"},
    {prodId:"50324919",label:"Durazno perla fuego seco"},
    {prodId:"50324920",label:"Durazno fuego dulce seco"},
    {prodId:"50324921",label:"Durazno llamakist seco"},
    {prodId:"50324922",label:"Durazno tipo plano seco"},
    {prodId:"50324923",label:"Durazno delicia de jardín seco"},
    {prodId:"50324924",label:"Durazno mina de oro seco"},
    {prodId:"50324925",label:"Durazno perla grande seco"},
    {prodId:"50324926",label:"Durazno hardiroja seco"},
    {prodId:"50324927",label:"Durazno miel de fuego seco"},
    {prodId:"50324928",label:"Durazno julio rojo seco"},
    {prodId:"50324929",label:"Durazno kay perla seco"},
    {prodId:"50324930",label:"Durazno key dulce seco"},
    {prodId:"50324931",label:"Durazno diamante mayo seco"},
    {prodId:"50324932",label:"Durazno mayfire seco"},
    {prodId:"50324933",label:"Durazno mayglo seco"},
    {prodId:"50324934",label:"Durazno mericrest seco"},
    {prodId:"50324935",label:"Durazno diamante rojo seco"},
    {prodId:"50324936",label:"Durazno oro rojo seco"},
    {prodId:"50324937",label:"Durazno jim rojo seco"},
    {prodId:"50324938",label:"Durazno roy rojo seco"},
    {prodId:"50324939",label:"Durazno rio rojo seco"},
    {prodId:"50324940",label:"Durazno diamante de rosa seco"},
    {prodId:"50324941",label:"Durazno rotal glo seco"},
    {prodId:"50324942",label:"Durazno diamante ryby seco"},
    {prodId:"50324943",label:"Durazno ruby dulce seco"},
    {prodId:"50324944",label:"Durazno joya ruddy seco"},
    {prodId:"50324945",label:"Durazno septiembre rojo seco"},
    {prodId:"50324946",label:"Durazno reina de nieve seco"},
    {prodId:"50324947",label:"Durazno primavera clara seco"},
    {prodId:"50324948",label:"Durazno primavera roja seco"},
    {prodId:"50324949",label:"Durazno verano rojiso seco"},
    {prodId:"50324950",label:"Durazno verano claro seco"},
    {prodId:"50324951",label:"Durazno verano diamante seco"},
    {prodId:"50324952",label:"Durazno verano fuego seco"},
    {prodId:"50324953",label:"Durazno verano grande seco"},
    {prodId:"50324954",label:"Durazno sunglo seco"},
    {prodId:"50324955",label:"Durazno fuego zee seco"},
    {prodId:"50324956",label:"Durazno zee glo seco"},
    {prodId:"50324957",label:"Durazno zeegrand seco"},
    {prodId:"50325000",label:"Naranjas secas"},
    {prodId:"50325001",label:"Naranja áfrica agria seca"},
    {prodId:"50325002",label:"Naranja dulce amber seca"},
    {prodId:"50325003",label:"Naranja argentina agria seca"},
    {prodId:"50325004",label:"Naranja bahianinha seco"},
    {prodId:"50325005",label:"Naranja bergamot seca"},
    {prodId:"50325006",label:"Naranja berna seca"},
    {prodId:"50325007",label:"Naranja bigaradier apepu seca"},
    {prodId:"50325008",label:"Naranja agria dulce daidai seca"},
    {prodId:"50325009",label:"Naranja mono seca"},
    {prodId:"50325010",label:"Naranja sangre seca"},
    {prodId:"50325011",label:"Naranja california navel seca"},
    {prodId:"50325012",label:"Naranja cara cara seca"},
    {prodId:"50325013",label:"Naranja chinotto seca"},
    {prodId:"50325014",label:"Naranja sueño nevel seca"},
    {prodId:"50325015",label:"Naranja gou tou seca"},
    {prodId:"50325016",label:"Naranja hamlin seca"},
    {prodId:"50325017",label:"Naranja jaffa seca"},
    {prodId:"50325018",label:"Naranja jincheng seca"},
    {prodId:"50325019",label:"Naranja k-temprano seca"},
    {prodId:"50325020",label:"Naranja kona seca"},
    {prodId:"50325021",label:"Naranja navel tarde seca seca"},
    {prodId:"50325022",label:"Naranja valencia tarde seca"},
    {prodId:"50325023",label:"Naranja limequat seca"},
    {prodId:"50325024",label:"Naranja mar seca"},
    {prodId:"50325025",label:"Naranja maledorado seca"},
    {prodId:"50325026",label:"Naranja moro seca"},
    {prodId:"50325027",label:"Naranja moro tabaco seca"},
    {prodId:"50325028",label:"Naranja navel seca"},
    {prodId:"50325029",label:"Naranja navelina seca"},
    {prodId:"50325030",label:"Naranja oro blanco seca"},
    {prodId:"50325031",label:"Naranja osceola seca"},
    {prodId:"50325032",label:"Naranja parson carmelito seca"},
    {prodId:"50325033",label:"Naranja pera seca"},
    {prodId:"50325034",label:"Naranjo pummulo seca"},
    {prodId:"50325035",label:"Naranja rhode rojo seca"},
    {prodId:"50325036",label:"Naranja roble seca"},
    {prodId:"50325037",label:"Naranja salustianas seca"},
    {prodId:"50325038",label:"Naranja sanguine seca"},
    {prodId:"50325039",label:"Naranja sanguinelli seca"},
    {prodId:"50325040",label:"Naranja cevilla seca"},
    {prodId:"50325041",label:"Naranja shamouti jaffa seca"},
    {prodId:"50325042",label:"Naranja tunis seca"},
    {prodId:"50325043",label:"Naranja valencia seca"},
    {prodId:"50325044",label:"Naranja washington navel seca"},
    {prodId:"50325200",label:"Maracuyás secas"},
    {prodId:"50325201",label:"Fruta maracuyá banana seca"},
    {prodId:"50325202",label:"Maracuyá flor azul seca"},
    {prodId:"50325203",label:"Maracuyá crackerjack seca"},
    {prodId:"50325204",label:"Maracuyá granadilla gigante seca"},
    {prodId:"50325205",label:"Maracuyá granadilla dorada seca"},
    {prodId:"50325206",label:"Maracuyá maypops seca"},
    {prodId:"50325207",label:"Maracuyá roja seca"},
    {prodId:"50325208",label:"Maracuyá granadilla dulce seca"},
    {prodId:"50325209",label:"Maracuyá sandia seca"},
    {prodId:"50325210",label:"Maracuyá  wing- steam seca"},
    {prodId:"50325500",label:"Caquis secos"},
    {prodId:"50325501",label:"Caqui americano seca"},
    {prodId:"50325502",label:"Caqui sapote negro seca"},
    {prodId:"50325503",label:"Caqui chapote / negro seca"},
    {prodId:"50325504",label:"Caqui dátale ciruela seca"},
    {prodId:"50325505",label:"Caqui fuyu seca"},
    {prodId:"50325506",label:"Caqui gigante fuyu seca"},
    {prodId:"50325507",label:"Caqui hachiya seca"},
    {prodId:"50325508",label:"Caqui mantequilla / mabolo seca"},
    {prodId:"50325509",label:"Caqui príncipe ito seca"},
    {prodId:"50325510",label:"Caqui brillante royal seca"},
    {prodId:"50325511",label:"Caqui sharon seca"},
    {prodId:"50325512",label:"Caqui triumph seca"},
    {prodId:"50325700",label:"Ciruelos Damascos secos"},
    {prodId:"50325701",label:"Ciruelo / damasco negro kat seco"},
    {prodId:"50325702",label:"Ciruelo / damasco gusto azul seco"},
    {prodId:"50325703",label:"Ciruelo / damasco corazón de crimson seco"},
    {prodId:"50325704",label:"Ciruelo / damasco dapply dandy seco"},
    {prodId:"50325705",label:"Ciruelo / damasco dapple fuego seco"},
    {prodId:"50325706",label:"Ciruelo / damasco  dapple temprano seco"},
    {prodId:"50325707",label:"Ciruelo / damasco  caída de sabor seco"},
    {prodId:"50325708",label:"Ciruelo / damasco sabor de oro seco"},
    {prodId:"50325709",label:"Ciruelo / damasco sabor granada seco"},
    {prodId:"50325710",label:"Ciruelo / damasco sabor de corazón seco"},
    {prodId:"50325711",label:"Ciruelo / damasco joya de sabor seco"},
    {prodId:"50325712",label:"Ciruelo / damasco sabor de rey seco"},
    {prodId:"50325713",label:"Ciruelo / damasco sabor de reina seco"},
    {prodId:"50325714",label:"Ciruelo / damasco sabor supremo seco"},
    {prodId:"50325715",label:"Ciruelo / damasco sabor premio seco"},
    {prodId:"50325716",label:"Ciruelo / damasco saborella seco"},
    {prodId:"50325717",label:"Ciruelo / damasco saborrico seco"},
    {prodId:"50325718",label:"Ciruelo / damasco  sabor rosa seco"},
    {prodId:"50325719",label:"Ciruelo / damasco orgullo geo seco"},
    {prodId:"50325720",label:"Ciruelo / damasco kat rojo seco"},
    {prodId:"50325721",label:"Ciruelo / damasco premio royal seco"},
    {prodId:"50325722",label:"Ciruelo / damasco rosa sierra seco"},
    {prodId:"50325723",label:"Ciruelo / damasco geisha dulce seco"},
    {prodId:"50325900",label:"Granadas secas"},
    {prodId:"50325901",label:"Granada foothhill seca"},
    {prodId:"50325902",label:"Granada, granada seca"},
    {prodId:"50325903",label:"Granada rojo feliz seca"},
    {prodId:"50325904",label:"Granada nana seca"},
    {prodId:"50325905",label:"Granada rojo de pat seca"},
    {prodId:"50325906",label:"Granada pinkhan sec"},
    {prodId:"50325907",label:"Granada terciopelo morado seca"},
    {prodId:"50325908",label:"Granada grandioso seca"},
    {prodId:"50326000",label:"Pomelos secos"},
    {prodId:"50326001",label:"Pomelo chandler seco"},
    {prodId:"50326002",label:"Pomelo hirado butan seco"},
    {prodId:"50326003",label:"Pomelo liang ping yau seco"},
    {prodId:"50326004",label:"Pomelo panda wangi seco"},
    {prodId:"50326005",label:"Pomelo rosado seco"},
    {prodId:"50326006",label:"Pomelo shaddock rojo seco"},
    {prodId:"50326007",label:"Pomelo siamese dulce seco"},
    {prodId:"50326008",label:"Pomelo waingwright seco"},
    {prodId:"50326100",label:"Membrillos secos"},
    {prodId:"50326101",label:"Membrillo campeón seco"},
    {prodId:"50326102",label:"Membrillo piña seco"},
    {prodId:"50326103",label:"Membrillo smyma seco"},
    {prodId:"50326200",label:"Frambuesas secas"},
    {prodId:"50326201",label:"Frambuesa americana roja seca"},
    {prodId:"50326202",label:"Frambuesa bailey reinasland seca"},
    {prodId:"50326203",label:"Frambuesa negra seca"},
    {prodId:"50326204",label:"Frambuesa oscura seca"},
    {prodId:"50326205",label:"Frambuesa deliciosa seca"},
    {prodId:"50326206",label:"Frambuesa enano focke seca"},
    {prodId:"50326207",label:"Frambuesa focke hojagris seca"},
    {prodId:"50326208",label:"Frambuesa focke fresa seca"},
    {prodId:"50326209",label:"Frambuesa  focke amarillo himalaya seca"},
    {prodId:"50326210",label:"Frambuesa dorado sec"},
    {prodId:"50326211",label:"Frambuesa gris nuevo mexico seca"},
    {prodId:"50326212",label:"Frambuesa jepson blancobark seca"},
    {prodId:"50326213",label:"Frambuesa kellogs san diego seca"},
    {prodId:"50326214",label:"Frambuesa leucodemis blancobark seca"},
    {prodId:"50326215",label:"Frambuesa munz cuyamaca seca"},
    {prodId:"50326216",label:"Frambuesa peck bartons seca"},
    {prodId:"50326217",label:"Frambuesa flor morada seca"},
    {prodId:"50326218",label:"Frambuesa roadside seca"},
    {prodId:"50326219",label:"Frambuesa san diego seca"},
    {prodId:"50326220",label:"Frambuesa nieve seca"},
    {prodId:"50326221",label:"Frambuesa pico de nieve seca"},
    {prodId:"50326222",label:"Frambuesa hoja de fresa seca"},
    {prodId:"50326223",label:"Frambuesa cultivo dulce seca"},
    {prodId:"50326224",label:"Frambuesa tor y gris blancobark seca"},
    {prodId:"50326225",label:"Frambuesa caribe seca"},
    {prodId:"50326226",label:"Frambuesa blancobark seca"},
    {prodId:"50326227",label:"Frambuesa vino seca"},
    {prodId:"50326228",label:"Frambuesa himalaya amarillo seca"},
    {prodId:"50326229",label:"Frambuesa yu-shan seca"},
    {prodId:"50326300",label:"Ruibarbos secos"},
    {prodId:"50326301",label:"Ruibarbo crimson rojo seco"},
    {prodId:"50326302",label:"Ruibarbo champagne temprana seco"},
    {prodId:"50326303",label:"Ruibarbo glasrey perpetual  seco"},
    {prodId:"50326304",label:"Ruibarbo sutton seco"},
    {prodId:"50326305",label:"Ruibarbo timperley temprano seco"},
    {prodId:"50326306",label:"Ruibarbo valentina seco"},
    {prodId:"50326307",label:"Ruibarbo victoria seco"},
    {prodId:"50326308",label:"Ruibarbo zwolle de cemilla seco"},
    {prodId:"50326309",label:"Ruibarbo macdonald seco"},
    {prodId:"50326310",label:"Ruibarbo tilden seco"},
    {prodId:"50326400",label:"Escaramujos secos"},
    {prodId:"50326401",label:"Escaramujo rosa brier seco"},
    {prodId:"50326402",label:"Escaramujo rosa elegante seco"},
    {prodId:"50326403",label:"Escaramujo rosa rugosa seco"},
    {prodId:"50326404",label:"Escaramujo rosa burnet o scotch seco"},
    {prodId:"50326500",label:"Sapotes secos"},
    {prodId:"50326501",label:"Sapote blanco seco"},
    {prodId:"50326502",label:"Sapote negro seco"},
    {prodId:"50326600",label:"Bayas saskatoon secas"},
    {prodId:"50326601",label:"Baya mielmadera saskatoon seca"},
    {prodId:"50326602",label:"Baya northline saskatoon seca"},
    {prodId:"50326603",label:"Baya saskatoon ahumado seca"},
    {prodId:"50326604",label:"Baya saskatoon thiessen seca"},
    {prodId:"50326700",label:"Fresas secas"},
    {prodId:"50326701",label:"Fresa chandler seca"},
    {prodId:"50326702",label:"Fresa rumbo junio seca"},
    {prodId:"50326703",label:"Fresa rumbo siempre seca"},
    {prodId:"50326900",label:"Tamarillos secos"},
    {prodId:"50326901",label:"Tamarindo amberlea dorado seco"},
    {prodId:"50326902",label:"Tamarindo calvo dorado seco"},
    {prodId:"50326903",label:"Tamarindo mina de oro seco"},
    {prodId:"50326904",label:"Tamarindo oratia rojo seco"},
    {prodId:"50326905",label:"Tamarindo beau rojo seco"},
    {prodId:"50326906",label:"Tamarindo delicia roja seco"},
    {prodId:"50327100",label:"Aronias secas"},
    {prodId:"50327101",label:"Aronia magia de otoño seca"},
    {prodId:"50327102",label:"Aronia brillantísima seca"},
    {prodId:"50327103",label:"Aronia nero seca"},
    {prodId:"50327104",label:"Aronia viquingo seca"},
    {prodId:"50327200",label:"Aceitunas secas"},
    {prodId:"50327201",label:"Aceitunas agrinion seca"},
    {prodId:"50327202",label:"Aceitunas aleppo seca"},
    {prodId:"50327203",label:"Aceitunas  alfonso seca"},
    {prodId:"50327204",label:"Aceitunas  amfisa seca"},
    {prodId:"50327205",label:"Aceitunas  arauco seca"},
    {prodId:"50327206",label:"Aceitunas  arbequina seca"},
    {prodId:"50327207",label:"Aceitunas  atlanta seca"},
    {prodId:"50327208",label:"Aceitunas  cerignola seca"},
    {prodId:"50327209",label:"Aceitunas  cracked provencal seca"},
    {prodId:"50327210",label:"Aceitunas  empeltre seca"},
    {prodId:"50327211",label:"Aceitunas gaeta  seca"},
    {prodId:"50327212",label:"Aceitunas hondoelia seca"},
    {prodId:"50327213",label:"Aceitunas kalamata seca"},
    {prodId:"50327214",label:"Aceitunas kura seca"},
    {prodId:"50327215",label:"Aceitunas ligurian seca"},
    {prodId:"50327216",label:"Aceitunas lucque seca"},
    {prodId:"50327217",label:"Aceitunas lugano seca"},
    {prodId:"50327218",label:"Aceitunas manzanilla seca"},
    {prodId:"50327219",label:"Aceitunas marche seca"},
    {prodId:"50327220",label:"Aceitunas misión seca"},
    {prodId:"50327221",label:"Aceitunas nafplion verde seca"},
    {prodId:"50327222",label:"Aceitunas nicoise seca"},
    {prodId:"50327223",label:"Aceitunas nyons seca"},
    {prodId:"50327224",label:"Aceitunas picholine seca"},
    {prodId:"50327225",label:"Aceitunas ponentine seca"},
    {prodId:"50327226",label:"Aceitunas royal seca"},
    {prodId:"50327227",label:"Aceitunas seracena seca"},
    {prodId:"50327228",label:"Aceitunas sevillano seca"},
    {prodId:"50327229",label:"Aceitunas sicilian seca"},
    {prodId:"50327230",label:"Aceitunas toscanella seca"},
    {prodId:"50331500",label:"Manzanas orgánicas secas"},
    {prodId:"50331501",label:"Manzanas akane orgánicas secas"},
    {prodId:"50331502",label:"Manzana ambrosia orgánicas seca"},
    {prodId:"50331503",label:"Manzanas api orgánicas secas"},
    {prodId:"50331504",label:"Manzanas baldwin orgánicas secas"},
    {prodId:"50331505",label:"Manzanas brabum orgánicas secas"},
    {prodId:"50331506",label:"Manzanas bramley orgánicas secas"},
    {prodId:"50331507",label:"Manzana joven bramley orgánic"},
    {prodId:"50331508",label:"Manzana calville blanche d'hiver orgánic"},
    {prodId:"50331509",label:"Manzana cameo orgánicas seca"},
    {prodId:"50331510",label:"Manzana charles ross orgánicas seca"},
    {prodId:"50331511",label:"Manzana codlin orgánicas seca"},
    {prodId:"50331512",label:"Manzana cortland orgánicas seca"},
    {prodId:"50331513",label:"Manzana costard orgánicas seca"},
    {prodId:"50331514",label:"Manzana court pendu plat orgánicas seca"},
    {prodId:"50331515",label:"Manzana  cox's orange pippin orgánicas seca"},
    {prodId:"50331516",label:"Manzana crab orgánicas seca"},
    {prodId:"50331517",label:"Manzana crispin orgánicas seca"},
    {prodId:"50331518",label:"Manzana delicious orgánicas seca"},
    {prodId:"50331519",label:"Manzana duchess orgánicas seca"},
    {prodId:"50331520",label:"Manzana earligold orgánicas seca"},
    {prodId:"50331521",label:"Manzana early mcintosh orgánicas seca"},
    {prodId:"50331522",label:"Manzana elstar orgánicas seca"},
    {prodId:"50331523",label:"Manzana empire orgánicas seca"},
    {prodId:"50331524",label:"Manzana flower of kent orgánicas seca"},
    {prodId:"50331525",label:"Manzana fiji orgánicas seca"},
    {prodId:"50331526",label:"Manzana gala orgánicas seca"},
    {prodId:"50331527",label:"Manzana gascoyne's scarlet orgánicas seca"},
    {prodId:"50331528",label:"Manzana gillyflower orgánicas seca"},
    {prodId:"50331529",label:"Manzana ginger gold orgánicas seca"},
    {prodId:"50331530",label:"Manzana gladstone orgánicas seca"},
    {prodId:"50331531",label:"Manzana gloster orgánicas seca"},
    {prodId:"50331532",label:"Manzana gold supreme orgánicas seca"},
    {prodId:"50331533",label:"Manzana dorado delicious orgánicas seca"},
    {prodId:"50331534",label:"Manzana dorado nobel orgánicas seca"},
    {prodId:"50331535",label:"Manzana granny smith orgánicas seca"},
    {prodId:"50331536",label:"Manzana gravenstain orgánicas seca"},
    {prodId:"50331537",label:"Manzana greening orgánicas seca"},
    {prodId:"50331538",label:"Manzana greensleeves orgánicas seca"},
    {prodId:"50331539",label:"Manzana honeycrisp orgánicas seca"},
    {prodId:"50331540",label:"Manzana howgate wonder orgánicas seca"},
    {prodId:"50331541",label:"Manzana roja ida orgánicas seca"},
    {prodId:"50331542",label:"Manzana james grives orgánicas seca"},
    {prodId:"50331543",label:"Manzana jersey mac orgánicas seca"},
    {prodId:"50331544",label:"Manzana jaster orgánicas seca"},
    {prodId:"50331545",label:"Manzana jonagold orgánicas seca"},
    {prodId:"50331546",label:"Manzana jonamac orgánicas seca"},
    {prodId:"50331547",label:"Manzana  jonathan  orgánicas seca"},
    {prodId:"50331548",label:"Manzana katy orgánicas seca"},
    {prodId:"50331549",label:"Manzana kidd's orange roja orgánicas seca"},
    {prodId:"50331550",label:"Manzana lady orgánicas seca"},
    {prodId:"50331551",label:"Manzana law roma orgánicas seca"},
    {prodId:"50331552",label:"Manzana laxton orgánicas seca"},
    {prodId:"50331553",label:"Manzana lord derby orgánicas seca"},
    {prodId:"50331554",label:"Manzana macoun  orgánicas seca"},
    {prodId:"50331555",label:"Manzana mcintosh orgánicas seca"},
    {prodId:"50331556",label:"Manzana mutsu orgánicas seca"},
    {prodId:"50331557",label:"Manzana newtown pippin orgánicas seca"},
    {prodId:"50331558",label:"Manzana northen spy orgánicas seca orgánicas seca"},
    {prodId:"50331559",label:"Manzana orleans reinette orgánicas seca"},
    {prodId:"50331560",label:"Manzana ozark gold orgánicas seca"},
    {prodId:"50331561",label:"Manzana pacific rose orgánicas seca"},
    {prodId:"50331562",label:"Manzana paula roja orgánicas seca"},
    {prodId:"50331563",label:"Manzana peamain orgánicas seca"},
    {prodId:"50331564",label:"Manzana pink lady orgánicas seca"},
    {prodId:"50331565",label:"Manzana pippin orgánicas seca"},
    {prodId:"50331566",label:"Manzana pitmaston pineapple orgánicas seca"},
    {prodId:"50331567",label:"Manzana pomme dápi orgánicas seca"},
    {prodId:"50331568",label:"Manzana prime gold orgánicas seca"},
    {prodId:"50331569",label:"Manzana roja astrakhan orgánicas seca"},
    {prodId:"50331570",label:"Manzana roja boscoop orgánicas seca"},
    {prodId:"50331571",label:"Manzana  roja chief orgánicas seca"},
    {prodId:"50331572",label:"Manzana roja delicious orgánicas seca"},
    {prodId:"50331573",label:"Manzana roja grvenstain orgánicas seca"},
    {prodId:"50331574",label:"Manzana re roma orgánicas seca"},
    {prodId:"50331575",label:"Manzanaroja stayman orgánicas seca"},
    {prodId:"50331576",label:"Manzana roja york orgánicas seca"},
    {prodId:"50331577",label:"Manzana reinette orgánicas seca"},
    {prodId:"50331578",label:"Manzana roma beuty orgánicas seca"},
    {prodId:"50331579",label:"Manzana russet orgánicas seca"},
    {prodId:"50331580",label:"Manzana sierra beaty orgánicas seca"},
    {prodId:"50331581",label:"Manzana spartan orgánicas seca"},
    {prodId:"50331582",label:"Manzana stark crimson orgánicas seca"},
    {prodId:"50331583",label:"Manzana starrey orgánicas seca"},
    {prodId:"50331584",label:"Manzana stayman orgánicas seca"},
    {prodId:"50331585",label:"Manzana stayman winesap orgánicas seca"},
    {prodId:"50331586",label:"Manzana summer rambo orgánicas seca"},
    {prodId:"50331587",label:"Manzana tsugaru orgánicas seca"},
    {prodId:"50331588",label:"Manzana twenty ounce orgánicas seca"},
    {prodId:"50331589",label:"Manzana tydeman roja orgánicas seca"},
    {prodId:"50331590",label:"Manzana vistabella orgánicas seca"},
    {prodId:"50331591",label:"Manzana weatley orgánicas seca"},
    {prodId:"50331592",label:"Manzana blanco joanetine orgánicas seca"},
    {prodId:"50331593",label:"Manzana blanco transparent orgánicas seca"},
    {prodId:"50331594",label:"Manzana winesap orgánicas seca"},
    {prodId:"50331595",label:"Manzana worcester orgánicas seca"},
    {prodId:"50331596",label:"Manzana york imperial orgánicas seca"},
    {prodId:"50331600",label:"Albaricoques orgánicos secos"},
    {prodId:"50331601",label:"Abaricoques ambercot orgánicas seca"},
    {prodId:"50331602",label:"Albaricoques apache orgánicas seca"},
    {prodId:"50331603",label:"Albaricoques brittany gold orgánicas seca"},
    {prodId:"50331604",label:"Albaricoque negro orgánicas seca"},
    {prodId:"50331605",label:"Albaricoque blenheim orgánicas seca"},
    {prodId:"50331606",label:"Albaricoque bonny orgánicas seca"},
    {prodId:"50331607",label:"Albaricoque biluda orgánicas seca"},
    {prodId:"50331608",label:"Albaricoque castlebrite orgánicas seca"},
    {prodId:"50331609",label:"Albaricoque  clutha gold orgánicas seca"},
    {prodId:"50331610",label:"Albaricoque clutha sun orgánicas seca"},
    {prodId:"50331611",label:"Albaricoque derby royal orgánicas seca"},
    {prodId:"50331612",label:"Albaricoque dina orgánicas seca"},
    {prodId:"50331613",label:"Albaricoque earlicot orgánicas seca"},
    {prodId:"50331614",label:"Albaricoque earliman orgánicas seca"},
    {prodId:"50331615",label:"Albaricoque early bright orgánicas seca"},
    {prodId:"50331616",label:"Albaricoque  flaming gold orgánicas seca"},
    {prodId:"50331617",label:"Albaricoque frenso orgánicas seca"},
    {prodId:"50331618",label:"Albaricoque gold bright orgánicas seca"},
    {prodId:"50331619",label:"Albaricoque goldbar orgánicas seca"},
    {prodId:"50331620",label:"Albaricoque dorado sweet orgánicas seca"},
    {prodId:"50331621",label:"Albaricoque goldrich orgánicas seca"},
    {prodId:"50331622",label:"Albaricoque helena orgánicas seca"},
    {prodId:"50331623",label:"Albaricoque honeycot orgánicas seca"},
    {prodId:"50331624",label:"Albaricoque imperial orgánicas seca"},
    {prodId:"50331625",label:"Albaricoque jordanne orgánicas seca"},
    {prodId:"50331626",label:"Albaricoque jumbo cot orgánicas seca"},
    {prodId:"50331627",label:"Albaricoque kandy kot orgánicas seca"},
    {prodId:"50331628",label:"Albaricoque katy orgánicas seca"},
    {prodId:"50331629",label:"Albaricoque rey orgánicas seca"},
    {prodId:"50331630",label:"Albaricoque lambertin orgánicas seca"},
    {prodId:"50331631",label:"Albaricoque loma orgánicas seca"},
    {prodId:"50331632",label:"Albaricoque lulu belle orgánicas seca"},
    {prodId:"50331633",label:"Albaricoque modesto orgánicas seca"},
    {prodId:"50331634",label:"Albaricoque moorpark orgánicas seca"},
    {prodId:"50331635",label:"Albaricoque orangeroja orgánicas seca"},
    {prodId:"50331636",label:"Albaricoque palstein orgánicas seca"},
    {prodId:"50331637",label:"Albaricoque patterson orgánicas seca"},
    {prodId:"50331638",label:"Albaricoque perfection orgánicas seca"},
    {prodId:"50331639",label:"Albaricoque poppy orgánicas seca"},
    {prodId:"50331640",label:"Albaricoque poppycot orgánicas seca"},
    {prodId:"50331641",label:"Albaricoque reina orgánicas seca"},
    {prodId:"50331642",label:"Albaricoque riland orgánicas seca"},
    {prodId:"50331643",label:"Albaricoque rival orgánicas seca"},
    {prodId:"50331644",label:"Albaricoque robada orgánicas seca"},
    {prodId:"50331645",label:"Albaricoque royal orgánicas seca"},
    {prodId:"50331646",label:"Albaricoque  royal blenheim orgánicas seca"},
    {prodId:"50331647",label:"Albaricoque  royal orange orgánicas seca"},
    {prodId:"50331648",label:"Albaricoque sundrop orgánicas seca"},
    {prodId:"50331649",label:"Albaricoque tilton orgánicas seca"},
    {prodId:"50331650",label:"Albaricoque tomcot orgánicas seca"},
    {prodId:"50331651",label:"Albaricoque tracy orgánicas seca"},
    {prodId:"50331652",label:"Albaricoque tri gem orgánicas seca"},
    {prodId:"50331653",label:"Albaricoque valley gold orgánicas seca"},
    {prodId:"50331654",label:"Albaricoque westley orgánicas seca"},
    {prodId:"50331655",label:"Albaricoque  york orgánicas seca"},
    {prodId:"50331700",label:"Bananos orgánicos secos"},
    {prodId:"50331701",label:"Banana manzana orgánicas seca"},
    {prodId:"50331702",label:"Banana bebe orgánicas seca"},
    {prodId:"50331703",label:"Banana burro orgánicas seca"},
    {prodId:"50331704",label:"Bananas cavendish orgánicas seca"},
    {prodId:"50331705",label:"Bananas dominico orgánicas seca"},
    {prodId:"50331706",label:"Bananas green orgánicas seca"},
    {prodId:"50331707",label:"Bananas gros michel orgánicas seca"},
    {prodId:"50331708",label:"Bananas lacatan orgánicas seca"},
    {prodId:"50331709",label:"Bananas dedo de dama orgánicas seca"},
    {prodId:"50331710",label:"Bananas manzano orgánicas seca"},
    {prodId:"50331711",label:"Banana mysore orgánicas seca"},
    {prodId:"50331712",label:"Banana pisang mas orgánicas seca"},
    {prodId:"50331713",label:"Bananas roja orgánicas seca"},
    {prodId:"50331714",label:"Bananas saba orgánicas seca"},
    {prodId:"50331715",label:"Bananas sucrier orgánicas seca"},
    {prodId:"50331800",label:"Barberries orgánicos secos"},
    {prodId:"50331801",label:"Berberís paleleaf orgánicas seca"},
    {prodId:"50331802",label:"Barberís chenault orgánicas seca"},
    {prodId:"50331803",label:"Barberís roja orgánicas seca"},
    {prodId:"50331804",label:"Barberís wintergreen orgánicas seca"},
    {prodId:"50331805",label:"Barberís korean orgánicas seca"},
    {prodId:"50331806",label:"Barberís mentor orgánicas seca"},
    {prodId:"50331807",label:"Barberís japanese orgánicas seca"},
    {prodId:"50331808",label:"Barberís atropurpurea orgánicas secas"},
    {prodId:"50331809",label:"Barberís aurea orgánicas secas"},
    {prodId:"50331810",label:"Barberís bagatelle orgánicas secas"},
    {prodId:"50331811",label:"Barberís crimsom pygmy orgánicas secas"},
    {prodId:"50331812",label:"Barberís kobold orgánicas secas"},
    {prodId:"50331813",label:"Barberís warty orgánicas secas"},
    {prodId:"50331814",label:"Barberís european orgánicas secas"},
    {prodId:"50331900",label:"Gayubas orgánicas secas"},
    {prodId:"50331901",label:"Gayuba alpine orgánicas secas"},
    {prodId:"50331902",label:"Gayuba roja orgánicas secas"},
    {prodId:"50331903",label:"Gayuba comun orgánicas secas"},
    {prodId:"50332000",label:"Moras orgánicas secas"},
    {prodId:"50332001",label:"Mora apache orgánicas secas"},
    {prodId:"50332002",label:"Mora negro satin orgánicas secas"},
    {prodId:"50332003",label:"Mora orgánicas secas"},
    {prodId:"50332004",label:"Mora cherokee orgánicas secas"},
    {prodId:"50332005",label:"Mora chester orgánicas secas"},
    {prodId:"50332006",label:"Mora dirksen orgánicas secas"},
    {prodId:"50332007",label:"Bayasjosta orgánicas secas"},
    {prodId:"50332008",label:"Bayaslogan orgánicas secas"},
    {prodId:"50332009",label:"Bayasmarion orgánicas secas"},
    {prodId:"50332010",label:"Mora navaho orgánicas secas"},
    {prodId:"50332011",label:"Bayanectar orgánicas secas"},
    {prodId:"50332012",label:"Mora olallie orgánicas secas"},
    {prodId:"50332013",label:"Bayatay orgánicas secas"},
    {prodId:"50332014",label:"Mora thomless hull orgánicas secas"},
    {prodId:"50332015",label:"Bayayoung orgánicas secas"},
    {prodId:"50332100",label:"Arándanos de mirto orgánicos secos"},
    {prodId:"50332101",label:"Arandanos bog orgánicas secas"},
    {prodId:"50332102",label:"Arandanos dwarf orgánicas secas"},
    {prodId:"50332103",label:"Arandanos mountain orgánicas secas"},
    {prodId:"50332104",label:"Arandanos oval-leaved orgánicas secas"},
    {prodId:"50332200",label:"Agrás orgánicas secas"},
    {prodId:"50332201",label:"Agras bluecrop orgánicas secas"},
    {prodId:"50332202",label:"Agras bluetta orgánicas secas"},
    {prodId:"50332203",label:"Agras brigitta orgánicas secas"},
    {prodId:"50332204",label:"Agras chandler orgánicas secas"},
    {prodId:"50332205",label:"Agras duke orgánicas secas"},
    {prodId:"50332206",label:"Agras hrdyblue orgánicas secas"},
    {prodId:"50332207",label:"Agras legacy orgánicas secas"},
    {prodId:"50332208",label:"Agras misty orgánicas secas"},
    {prodId:"50332209",label:"Agras nelson orgánicas secas"},
    {prodId:"50332210",label:"Agras northblue orgánicas secas"},
    {prodId:"50332211",label:"Agras northcountry orgánicas secas"},
    {prodId:"50332212",label:"Agras northsky orgánicas secas"},
    {prodId:"50332213",label:"Agras patriot orgánicas secas"},
    {prodId:"50332214",label:"Agras spartan orgánicas secas"},
    {prodId:"50332215",label:"Agras toro orgánicas secas"},
    {prodId:"50332300",label:"Frutas del pan orgánicas secas"},
    {prodId:"50332301",label:"Fruta de pan chataigne orgánicas secas"},
    {prodId:"50332302",label:"Fruta de pan sin pepa orgánicas secas"},
    {prodId:"50332303",label:"Fruta de pan corazón blanco orgánicas secas"},
    {prodId:"50332304",label:"Fruta de pan corazón amarillo orgánicas secas"},
    {prodId:"50332400",label:"Chirimoyas orgánicas secas"},
    {prodId:"50332401",label:"Chirimoya chays orgánicas secas"},
    {prodId:"50332402",label:"Chirimoya bronceada orgánicas secas"},
    {prodId:"50332403",label:"Chirimoya burtons orgánicas secas"},
    {prodId:"50332404",label:"Chirimoya burton favorite orgánicas secas"},
    {prodId:"50332405",label:"Chirimoya jete orgánicas secas"},
    {prodId:"50332406",label:"Chirimoya reretai orgánicas secas"},
    {prodId:"50332407",label:"Chirimoya smoothey orgánicas secas"},
    {prodId:"50332408",label:"Chirimoya spain orgánicas secas"},
    {prodId:"50332409",label:"Chirimoya blanco orgánicas secas"},
    {prodId:"50332500",label:"Cerezas orgánicas secas"},
    {prodId:"50332501",label:"Cereza amarelle  orgánicas secas"},
    {prodId:"50332502",label:"Cereza brooks orgánicas secas"},
    {prodId:"50332503",label:"Cereza bigarreu orgánicas secas"},
    {prodId:"50332504",label:"Cereza bing orgánicas secas"},
    {prodId:"50332505",label:"Cereza blach republic orgánicas secas"},
    {prodId:"50332506",label:"Cereza negro schmidt orgánicas secas"},
    {prodId:"50332507",label:"Cereza negrotartarian orgánicas secas"},
    {prodId:"50332508",label:"Cereza fiesta bing orgánicas secas"},
    {prodId:"50332509",label:"Cereza garnet orgánicas secas"},
    {prodId:"50332510",label:"Cereza rey orgánicas secas"},
    {prodId:"50332511",label:"Cereza chapman orgánicas secas"},
    {prodId:"50332512",label:"Cereza lapin orgánicas secas"},
    {prodId:"50332513",label:"Cereza larian orgánicas secas"},
    {prodId:"50332514",label:"Cereza dark guines orgánicas secas"},
    {prodId:"50332515",label:"Cereza montmorency orgánicas secas"},
    {prodId:"50332516",label:"Cereza duke orgánicas secas"},
    {prodId:"50332517",label:"Cereza early rivers orgánicas secas"},
    {prodId:"50332518",label:"Cereza ruby bing orgánicas secas"},
    {prodId:"50332519",label:"Cereza santine orgánicas secas"},
    {prodId:"50332520",label:"Cereza geans/guines orgánicas secas"},
    {prodId:"50332521",label:"Cereza sonata orgánicas secas"},
    {prodId:"50332522",label:"Cereza lambert orgánicas secas"},
    {prodId:"50332523",label:"Cereza stella orgánicas secas"},
    {prodId:"50332524",label:"Cereza sweethart orgánicas secas"},
    {prodId:"50332525",label:"Cereza tartarian orgánicas secas"},
    {prodId:"50332527",label:"Cereza maraschino orgánicas secas"},
    {prodId:"50332528",label:"Cereza van orgánicas secas"},
    {prodId:"50332529",label:"Cereza morello orgánicas secas"},
    {prodId:"50332530",label:"Cereza royal ann orgánicas secas"},
    {prodId:"50332531",label:"Cereza ranier orgánicas secas"},
    {prodId:"50332532",label:"Cereza royal orgánicas secas"},
    {prodId:"50332600",label:"Citrones orgánicos secos"},
    {prodId:"50332601",label:"Citrones buddha's hand orgánicas secas"},
    {prodId:"50332602",label:"Citrones fingeroja orgánicas secas"},
    {prodId:"50332603",label:"Citrones fo shoukan orgánicas secas"},
    {prodId:"50332604",label:"Cotrones bushakan orgánicas secas"},
    {prodId:"50332605",label:"Citones diamante orgánicas secas"},
    {prodId:"50332606",label:"Citrones etrog orgánicas secas"},
    {prodId:"50332607",label:"Citrones ponderosa orgánicas secas"},
    {prodId:"50332700",label:"Arándanos orgánicos secos"},
    {prodId:"50332701",label:"Arandano ben lear orgánicas secas"},
    {prodId:"50332702",label:"Arandano early negro orgánicas secas"},
    {prodId:"50332703",label:"Arandano gryclesik orgánicas secas"},
    {prodId:"50332704",label:"Arandano howe orgánicas secas"},
    {prodId:"50332705",label:"Bayas lingon orgánicas secas"},
    {prodId:"50332706",label:"Arandano mcfarling orgánicas secas"},
    {prodId:"50332707",label:"Arandano mountain orgánicas secas"},
    {prodId:"50332708",label:"Arandano pilgrim orgánicas secas"},
    {prodId:"50332709",label:"Arandano searless orgánicas secas"},
    {prodId:"50332710",label:"Arandano stevens orgánicas secas"},
    {prodId:"50332800",label:"pasas de Corinto orgánicas secas"},
    {prodId:"50332801",label:"Pasa hudson bay orgánicas secas"},
    {prodId:"50332802",label:"Pasa waxy orgánicas secas"},
    {prodId:"50332803",label:"Pasa desert orgánicas secas"},
    {prodId:"50332804",label:"Pasa negra orgánicas secas"},
    {prodId:"50332805",label:"Pasa roja orgánicas secas"},
    {prodId:"50332806",label:"Pasa blanca orgánicas secas"},
    {prodId:"50332900",label:"Dátiles orgánicos secos"},
    {prodId:"50332901",label:"Dátiles asharasi  orgánicas secas"},
    {prodId:"50332902",label:"Dátiles barhi o barhee orgánicas secas"},
    {prodId:"50332903",label:"Dátiles deglet noor orgánicas secas"},
    {prodId:"50332904",label:"Dátiles fardh orgánicas secas"},
    {prodId:"50332905",label:"Dátiles gundila orgánicas secas"},
    {prodId:"50332906",label:"Dátiles halawi/halawy orgánicas secas"},
    {prodId:"50332907",label:"Dátiles hilali orgánicas secas"},
    {prodId:"50332908",label:"Dátiles khadrawi/khadrawy orgánicas secas"},
    {prodId:"50332909",label:"Dátiles khalas orgánicas secas"},
    {prodId:"50332910",label:"Dátiles khustawi orgánicas secas"},
    {prodId:"50332911",label:"Dátiles khidri orgánicas secas"},
    {prodId:"50332912",label:"Dátiles medjool/ medjol orgánicas secas"},
    {prodId:"50332913",label:"Dátiles mactoum orgánicas secas"},
    {prodId:"50332914",label:"Dátiles neghal orgánicas secas"},
    {prodId:"50332915",label:"Dátiles yatimeh orgánicas secas"},
    {prodId:"50332916",label:"Dátiles zahidi orgánicas secas"},
    {prodId:"50333000",label:"Pitahayas orgánicas secas"},
    {prodId:"50333001",label:"Pitahaya roja, rosada orgánicas secas"},
    {prodId:"50333002",label:"Pitahaya roja, amarillo orgánicas secas"},
    {prodId:"50333100",label:"Brevas orgánicas secas"},
    {prodId:"50333101",label:"Breva bardajic orgánicas secas"},
    {prodId:"50333102",label:"Breva brown turkey orgánicas secas"},
    {prodId:"50333103",label:"Breva calimyma  orgánicas secas"},
    {prodId:"50333104",label:"Breva conadria orgánicas secas"},
    {prodId:"50333105",label:"Breva dotado orgánicas secas"},
    {prodId:"50333106",label:"Breva kadota orgánicas secas"},
    {prodId:"50333107",label:"Breva mediterránea orgánicas secas"},
    {prodId:"50333108",label:"Breva misión orgánicas secas"},
    {prodId:"50333109",label:"Breva amyma orgánicas secas"},
    {prodId:"50333110",label:"Breva verdona orgánicas secas"},
    {prodId:"50333111",label:"Breva rey blanco orgánicas secas"},
    {prodId:"50333200",label:"Grosellas orgánicas secas"},
    {prodId:"50333201",label:"Grosella early sulphur orgánicas secas"},
    {prodId:"50333202",label:"Grosella doradodrop orgánicas secas"},
    {prodId:"50333203",label:"Grosella langley grace orgánicas secas"},
    {prodId:"50333204",label:"Grocella leveller orgánicas secas"},
    {prodId:"50333205",label:"Grosella london orgánicas secas"},
    {prodId:"50333206",label:"Grosella worcestershire orgánicas secas"},
    {prodId:"50333207",label:"Grosella american worcesterberry orgánic"},
    {prodId:"50333300",label:"Toronjas orgánicas secas"},
    {prodId:"50333301",label:"Toronja burgundi orgánicas secas"},
    {prodId:"50333302",label:"Toronja duncan orgánicas secas"},
    {prodId:"50333303",label:"Toronja foster orgánica seca"},
    {prodId:"50333304",label:"Toronja marsh orgánicas secas"},
    {prodId:"50333305",label:"Toronja nueva zelandia orgánicas secas"},
    {prodId:"50333306",label:"Toronja rio rojo orgánicas secas"},
    {prodId:"50333307",label:"Toronja ruby rojo orgánicas secas"},
    {prodId:"50333308",label:"Toronja star ruby orgánicas secas"},
    {prodId:"50333309",label:"Toronja triumph orgánicas secas"},
    {prodId:"50333400",label:"Uvas de mesa orgánicas secas"},
    {prodId:"50333401",label:"Uva alicante orgánicas secas"},
    {prodId:"50333402",label:"Uva almeira orgánicas secas"},
    {prodId:"50333403",label:"Uva alphonse lavalle orgánicas secas"},
    {prodId:"50333404",label:"Uva otoño rey grapes orgánicas secas"},
    {prodId:"50333405",label:"Uva otoño royal orgánicas secas"},
    {prodId:"50333406",label:"Uva otoño sin pepa orgánicas secas"},
    {prodId:"50333407",label:"Uva baresana orgánicas secas"},
    {prodId:"50333408",label:"Uva barlinka orgánicas secas"},
    {prodId:"50333409",label:"Uva beaty seedless orgánicas secas"},
    {prodId:"50333410",label:"Uva negra beauty sin semilla orgánicas secas"},
    {prodId:"50333411",label:"Uva negra emerald orgánicas secas"},
    {prodId:"50333412",label:"Uva negra gigante orgánicas secas"},
    {prodId:"50333413",label:"Uva negra globe orgánicas secas"},
    {prodId:"50333414",label:"Uva negra monnukka orgánicas secas"},
    {prodId:"50333415",label:"Uva negra pearl orgánicas secas"},
    {prodId:"50333416",label:"Uva negra sin semilla orgánicas secas"},
    {prodId:"50333417",label:"Uva bonheur orgánicas secas"},
    {prodId:"50333418",label:"Uva calmeria orgánicas secas"},
    {prodId:"50333419",label:"Uva cardinal orgánicas secas"},
    {prodId:"50333420",label:"Uva catawba orgánicas secas"},
    {prodId:"50333421",label:"Uva chasselas/dorado chasselas orgánicas secas"},
    {prodId:"50333422",label:"Uva christmas rose orgánicas secas"},
    {prodId:"50333423",label:"Uva concord orgánicas secas"},
    {prodId:"50333424",label:"Uva concord sin pepa orgánicas secas"},
    {prodId:"50333425",label:"Uva crimson sin pepa orgánicas secas"},
    {prodId:"50333426",label:"Uva dauphine  orgánicas secas"},
    {prodId:"50333427",label:"Uva daleware orgánicas secas"},
    {prodId:"50333428",label:"Uva early muscat orgánicas secas"},
    {prodId:"50333429",label:"Uva early sweet orgánicas secas"},
    {prodId:"50333430",label:"Uva esmerald sin pepa orgánicas secas"},
    {prodId:"50333431",label:"Uva emperatriz orgánicas secas"},
    {prodId:"50333432",label:"Uva emperor orgánicas secas"},
    {prodId:"50333433",label:"Uva empress orgánicas secas"},
    {prodId:"50333434",label:"Uva exótica orgánicas secas"},
    {prodId:"50333435",label:"Uva fantasia orgánicas secas"},
    {prodId:"50333436",label:"Uva fantasy sin pepa orgánicas secas"},
    {prodId:"50333437",label:"Uva llama orgánicas secas"},
    {prodId:"50333438",label:"Uva llama sin pepa orgánicas secas"},
    {prodId:"50333439",label:"Uva llama tokay orgánicas secas"},
    {prodId:"50333440",label:"Uva flaming roja orgánicas secas"},
    {prodId:"50333441",label:"Uva galaxy sin pepa orgánicas secas"},
    {prodId:"50333442",label:"Uva gamay orgánicas secas"},
    {prodId:"50333443",label:"Uva dorada orgánicas secas"},
    {prodId:"50333444",label:"Uva hanepot o honeypot orgánicas secas"},
    {prodId:"50333445",label:"Uva italia orgánicas secas"},
    {prodId:"50333446",label:"Uva jade sin pepa orgánicas secas"},
    {prodId:"50333447",label:"Uva jubileo orgánicas secas"},
    {prodId:"50333448",label:"Uva rey ruby orgánicas secas"},
    {prodId:"50333449",label:"Uva kyoho orgánicas secas"},
    {prodId:"50333450",label:"Uva la rochelle orgánicas secas"},
    {prodId:"50333451",label:"Uva dedo de dama orgánicas secas"},
    {prodId:"50333452",label:"Uva late sin pepa orgánicas secas"},
    {prodId:"50333453",label:"Uva majestic sin pepa orgánicas secas"},
    {prodId:"50333454",label:"Uva malaga orgánicas secas"},
    {prodId:"50333455",label:"Uva marro sin pepa orgánicas secas"},
    {prodId:"50333456",label:"Uva muscadine orgánicas secas"},
    {prodId:"50333457",label:"Uva muscat llama orgánicas secas"},
    {prodId:"50333458",label:"Uva muscat orgánicas secas"},
    {prodId:"50333459",label:"Uva muscat sin papa orgánicas secas"},
    {prodId:"50333460",label:"Uva napoleon orgánicas secas"},
    {prodId:"50333461",label:"Uva nigeria orgánicas secas"},
    {prodId:"50333462",label:"Uva new cross orgánicas secas"},
    {prodId:"50333463",label:"Uva nibella orgánicas secas"},
    {prodId:"50333464",label:"Uva niagara orgánicas secas"},
    {prodId:"50333465",label:"Uva olivette orgánicas secas"},
    {prodId:"50333466",label:"Uva perlette orgánicas secas"},
    {prodId:"50333467",label:"Uva perlon orgánicas secas"},
    {prodId:"50333468",label:"Uva prima negro sin pepa orgánicas secas"},
    {prodId:"50333469",label:"Uva prncess orgánicas secas"},
    {prodId:"50333470",label:"Uva reina orgánicas secas"},
    {prodId:"50333471",label:"Uva roja blush orgánicas secas"},
    {prodId:"50333472",label:"Uva roja globe orgánicas secas"},
    {prodId:"50333473",label:"Uva roja malaga orgánicas secas"},
    {prodId:"50333474",label:"Uva roja sin pepa orgánicas secas"},
    {prodId:"50333475",label:"Uva regina orgánicas secas"},
    {prodId:"50333476",label:"Uva ribier orgánicas secas"},
    {prodId:"50333477",label:"Uva rosita orgánicas secas"},
    {prodId:"50333478",label:"Uva rouge orgánicas secas"},
    {prodId:"50333479",label:"Uva royal negra sin pepa orgánicas secas"},
    {prodId:"50333480",label:"Uva ruby roja sin pepa orgánicas secas"},
    {prodId:"50333481",label:"Uva ryby sin pepa orgánicas secas"},
    {prodId:"50333482",label:"Uva scarlet royal orgánicas secas"},
    {prodId:"50333483",label:"Uva scuppemong orgánicas secas"},
    {prodId:"50333484",label:"Uva sugarose orgánicas secas"},
    {prodId:"50333485",label:"Uva sugartirteen orgánicas secas"},
    {prodId:"50333486",label:"Uva sugraone orgánicas secas"},
    {prodId:"50333487",label:"Uva sugrasixteen orgánicas secas"},
    {prodId:"50333488",label:"Uva sultana sun roja orgánicas secas"},
    {prodId:"50333489",label:"Uva verano royal orgánicas secas"},
    {prodId:"50333490",label:"Uva sunset orgánicas secas"},
    {prodId:"50333491",label:"Uva superior sin pepa orgánicas secas"},
    {prodId:"50333492",label:"Uva thompson sin pepa orgánicas secas"},
    {prodId:"50333493",label:"Uva tokaylpinot gris orgánicas secas"},
    {prodId:"50333494",label:"Uva waltman cross orgánicas secas"},
    {prodId:"50333495",label:"Uva blanca sin pepa orgánicas secas"},
    {prodId:"50333496",label:"Uva zante current orgánicas secas"},
    {prodId:"50333500",label:"Uvas pasas orgánicas secas"},
    {prodId:"50333501",label:"Uva corinth negra orgánicas secas"},
    {prodId:"50333502",label:"Uva canner orgánicas secas"},
    {prodId:"50333503",label:"Uva dovine orgánicas secas"},
    {prodId:"50333504",label:"Uva fiesta orgánicas secas"},
    {prodId:"50333505",label:"Uva selme pete orgánicas secas"},
    {prodId:"50333506",label:"Uva sultana orgánicas secas"},
    {prodId:"50333600",label:"Uvas para vino orgánicas secas"},
    {prodId:"50333601",label:"Uva uva alicante bouschet orgánicas secas"},
    {prodId:"50333602",label:"Uva barbera orgánicas secas"},
    {prodId:"50333603",label:"Uva burger orgánicas secas"},
    {prodId:"50333604",label:"Uva cabemet franc orgánicas secas"},
    {prodId:"50333605",label:"Uva cabenet sauvignon orgánicas secas"},
    {prodId:"50333606",label:"Uva carignane orgánicas secas"},
    {prodId:"50333607",label:"Uva camelian orgánicas secas"},
    {prodId:"50333608",label:"Uva cattarrato orgánicas secas"},
    {prodId:"50333609",label:"Uva centurian orgánicas secas"},
    {prodId:"50333610",label:"Uva charbono orgánicas secas"},
    {prodId:"50333611",label:"Uva chardonnay orgánicas secas"},
    {prodId:"50333612",label:"Uva chenin blanco orgánicas secas"},
    {prodId:"50333613",label:"Uva cinsaut orgánicas secas"},
    {prodId:"50333614",label:"Uva dolcetto orgánicas secas"},
    {prodId:"50333615",label:"Uva emerald riesling orgánicas secas"},
    {prodId:"50333616",label:"Uva frances colombard orgánicas secas"},
    {prodId:"50333617",label:"Uva granny nap orgánicas secas"},
    {prodId:"50333618",label:"Uva gamay beaujolais orgánicas secas"},
    {prodId:"50333619",label:"Uva gewurztraminer orgánicas secas"},
    {prodId:"50333620",label:"Uva grnache orgánicas secas"},
    {prodId:"50333621",label:"Uva grinache blanc orgánicas secas"},
    {prodId:"50333622",label:"Uva lagrein orgánicas secas"},
    {prodId:"50333623",label:"Uva lambruso orgánicas secas"},
    {prodId:"50333624",label:"Uva malbec orgánicas secas"},
    {prodId:"50333625",label:"Uva malvasía bianca orgánicas secas"},
    {prodId:"50333626",label:"Uva marsanne orgánicas secas"},
    {prodId:"50333627",label:"Uva matano orgánicas secas"},
    {prodId:"50333628",label:"Uva merlot orgánicas secas"},
    {prodId:"50333629",label:"Uva meunier orgánicas secas"},
    {prodId:"50333630",label:"Uva missiom orgánicas secas"},
    {prodId:"50333631",label:"Uva montepulceano orgánicas secas"},
    {prodId:"50333632",label:"Uva muscat blanc orgánicas secas"},
    {prodId:"50333633",label:"Uva muscat hamburg orgánicas secas"},
    {prodId:"50333634",label:"Uva muscat alexandria orgánicas secas"},
    {prodId:"50333635",label:"Uva muscat naranja orgánicas secas"},
    {prodId:"50333636",label:"Uva nebbiolo orgánicas secas"},
    {prodId:"50333637",label:"Uva palomino orgánicas secas"},
    {prodId:"50333638",label:"Uva petit verdor orgánicas secas"},
    {prodId:"50333639",label:"Uva petit sirah orgánicas secas"},
    {prodId:"50333640",label:"Uva pinot blanc orgánicas secas"},
    {prodId:"50333641",label:"Uva pinot gris orgánicas secas"},
    {prodId:"50333642",label:"Uva pinot noir orgánicas secas"},
    {prodId:"50333643",label:"Uva primitivo orgánicas secas"},
    {prodId:"50333644",label:"Uva roussane orgánicas secas"},
    {prodId:"50333645",label:"Uva real  orgánicas secas"},
    {prodId:"50333646",label:"Uva rubiroja orgánicas secas"},
    {prodId:"50333647",label:"Uva ruby cabenet orgánicas secas"},
    {prodId:"50333648",label:"Uva salvador orgánicas secas"},
    {prodId:"50333649",label:"Uva sangiovese orgánicas secas"},
    {prodId:"50333650",label:"Uva sauvignon blanc orgánicas secas"},
    {prodId:"50333651",label:"Uva sauvignon musque orgánicas secas"},
    {prodId:"50333652",label:"Uva semillon orgánicas secas"},
    {prodId:"50333653",label:"Uva souzao orgánicas secas"},
    {prodId:"50333654",label:"Uva st emilion orgánicas secas"},
    {prodId:"50333655",label:"Uva symohony orgánicas secas"},
    {prodId:"50333656",label:"Uva syrah  orgánicas secas"},
    {prodId:"50333657",label:"Uva tannat orgánicas secas"},
    {prodId:"50333658",label:"Uva tempranillo orgánicas secas"},
    {prodId:"50333659",label:"Uvas teroldego orgánicas secas"},
    {prodId:"50333660",label:"Uvas tocai friulano orgánicas secas"},
    {prodId:"50333661",label:"Uvas touriga nacional orgánicas secas"},
    {prodId:"50333662",label:"Uvas triplett blanc orgánicas secas"},
    {prodId:"50333663",label:"Uvas viognier orgánicas secas"},
    {prodId:"50333664",label:"Uvas blanco riesling orgánicas secas"},
    {prodId:"50333665",label:"Uvas zinfandel orgánicas secas"},
    {prodId:"50333700",label:"Guayabas orgánicas secas"},
    {prodId:"50333701",label:"Guayaba beaumont orgánicas secas"},
    {prodId:"50333702",label:"Guayaba carrley orgánicas secas"},
    {prodId:"50333703",label:"Guayaba lucida orgánicas secas"},
    {prodId:"50333704",label:"Guayaba piña orgánicas secas"},
    {prodId:"50333800",label:"Arándanos Huckleberry orgánicos secos"},
    {prodId:"50333801",label:"Arándano invierno negro orgánico seco"},
    {prodId:"50333802",label:"Arándano cascada orgánico seco"},
    {prodId:"50333803",label:"Arándano enano orgánico seco"},
    {prodId:"50333804",label:"Arándano montaña orgánico seco"},
    {prodId:"50333805",label:"Arándano rojo orgánico seco"},
    {prodId:"50333900",label:"Kiwis orgánicos secos"},
    {prodId:"50333901",label:"Fruta de kiwi ananasnaja orgánicas secas"},
    {prodId:"50333902",label:"Fruta de kiwi belleza del ártico orgánicas secas"},
    {prodId:"50333903",label:"Fruta de kiwi blake orgánicas secas"},
    {prodId:"50333904",label:"Fruta de kiwi hayward orgánicas secas"},
    {prodId:"50333905",label:"Fruta de kiwi issai orgánicas secas"},
    {prodId:"50333906",label:"Fruta de kiwi siberiana orgánicas secas"},
    {prodId:"50334000",label:"Naranjos Chinos (kumquats) orgánicos secos"},
    {prodId:"50334001",label:"Naranjo chino hong kong orgánico seco"},
    {prodId:"50334002",label:"Naranjo chino limequat orgánico seco"},
    {prodId:"50334003",label:"Naranjo long fruit orgánico seco"},
    {prodId:"50334004",label:"Naranjo chino malayan orgánico seco"},
    {prodId:"50334005",label:"Naranjo meiwa orgánico seco"},
    {prodId:"50334006",label:"Naranja chino nagami orgánico seco"},
    {prodId:"50334100",label:"Limones orgánicos secos"},
    {prodId:"50334101",label:"Limón baboon orgánico seco"},
    {prodId:"50334102",label:"Limón bearss sicilian orgánico seco"},
    {prodId:"50334103",label:"Limón cameron highland orgánico seco"},
    {prodId:"50334104",label:"Limón escondido orgánico seco"},
    {prodId:"50334105",label:"Limón eureka orgánico seco"},
    {prodId:"50334106",label:"Limón lisbon orgánico seco"},
    {prodId:"50334107",label:"Limón meyer orgánico seco"},
    {prodId:"50334108",label:"Limón volkaner orgánico seco"},
    {prodId:"50334200",label:"Limas orgánicas secas"},
    {prodId:"50334201",label:"Lima limón indian sweet orgánico seco"},
    {prodId:"50334202",label:"Lima limón orgánico seco"},
    {prodId:"50334203",label:"Lima limón mandarin orgánico seco"},
    {prodId:"50334204",label:"Lima limón philippine orgánico seco"},
    {prodId:"50334205",label:"Lima limón tahitian orgánico seco"},
    {prodId:"50334206",label:"Lima limón bearss orgánico seco"},
    {prodId:"50334207",label:"Lima limón persa orgánico seco"},
    {prodId:"50334208",label:"Lima limón sin pepas orgánico seco"},
    {prodId:"50334300",label:"Nísperos orgánicos secos"},
    {prodId:"50334301",label:"Níspero japonés advance orgánico seco"},
    {prodId:"50334302",label:"Níspero japonés benlehr orgánico seco"},
    {prodId:"50334303",label:"Níspero japonés big jim orgánico seco"},
    {prodId:"50334304",label:"Níspero japonés champagne orgánico seco"},
    {prodId:"50334305",label:"Níspero japonés early rojo orgánico seco"},
    {prodId:"50334306",label:"Níspero japonés nugget dorado orgánico seco"},
    {prodId:"50334307",label:"Níspero japonés herd's mammoth orgánico seco"},
    {prodId:"50334308",label:"Níspero japonés mogi orgánico seco"},
    {prodId:"50334309",label:"Níspero japonés mrs cooksey orgánico seco"},
    {prodId:"50334310",label:"Níspero japonés fresa orgánico seco"},
    {prodId:"50334311",label:"Níspero japonés tanaka orgánico seco"},
    {prodId:"50334312",label:"Níspero japonés victoria vista blanco orgánico seco"},
    {prodId:"50334313",label:"Níspero japonés wolfe orgánico seco"},
    {prodId:"50334400",label:"Mandarinas orgánicas secas"},
    {prodId:"50334401",label:"Naranjas clauselinas orgánicas secas"},
    {prodId:"50334402",label:"Mandarinas clementinas orgánicas secas"},
    {prodId:"50334403",label:"Naranja mandarina cleopatra"},
    {prodId:"50334404",label:"Mandarina dancy orgánicas secas"},
    {prodId:"50334405",label:"Naranja ellensday orgánicas secas"},
    {prodId:"50334406",label:"Naranja fairchild orgánicas secas"},
    {prodId:"50334407",label:"Naranja fallglo orgánicas secas"},
    {prodId:"50334408",label:"Naranja fortuna orgánicas secas"},
    {prodId:"50334409",label:"Mandarina naranja fremont orgánicas secas"},
    {prodId:"50334410",label:"Naranja fremont orgánicas secas"},
    {prodId:"50334411",label:"Naranja nuget dorada orgánicas secas"},
    {prodId:"50334412",label:"Mandarina naranja miel orgánicas secas"},
    {prodId:"50334413",label:"Naranja miel orgánicas secas"},
    {prodId:"50334414",label:"Mandarina miel orgánicas secas"},
    {prodId:"50334415",label:"Natanja tanjelo honebelle orgánicas secas"},
    {prodId:"50334416",label:"Naranja mandarina rey orgánicas secas"},
    {prodId:"50334417",label:"Naranja kinnow orgánicas secas"},
    {prodId:"50334418",label:"Naranja mandarina lee orgánicas secas"},
    {prodId:"50334419",label:"Naranja makokkee orgánicas secas"},
    {prodId:"50334420",label:"Naranja malvasios orgánicas secas"},
    {prodId:"50334421",label:"Naranja mandarina mediterránea orgánicas secas"},
    {prodId:"50334422",label:"Naranja tangelo minneola orgánicas secas"},
    {prodId:"50334423",label:"Naranja monica orgánicas secas"},
    {prodId:"50334424",label:"Naranja murcott miel orgánicas secas"},
    {prodId:"50334425",label:"Naranja murcott tangors orgánicas secas"},
    {prodId:"50334426",label:"Naranja mandarina natsudaidai  orgánicas secas"},
    {prodId:"50334427",label:"Naranja mandarina natsumikan orgánicas secas"},
    {prodId:"50334428",label:"Naranja tanjelo nocatee orgánicas secas"},
    {prodId:"50334429",label:"Naranja tanjelo orlando orgánicas secas"},
    {prodId:"50334430",label:"Mandarina ortanique orgánicas secas"},
    {prodId:"50334431",label:"Naranja mandarina pagina orgánicas secas"},
    {prodId:"50334432",label:"Naranja pixie orgánicas secas"},
    {prodId:"50334433",label:"Naranja mandarina ponkan bantangas orgánicas secas"},
    {prodId:"50334434",label:"Naranja reina orgánicas secas"},
    {prodId:"50334435",label:"Naranja robinson orgánicas secas"},
    {prodId:"50334436",label:"Naranja saltenitas orgánicas secas"},
    {prodId:"50334437",label:"Naranja tangelo sampson orgánicas secas"},
    {prodId:"50334438",label:"Naranja mandarina satsuma orgánicas secas"},
    {prodId:"50334439",label:"Naranja mandarina sunburst orgánicas secas"},
    {prodId:"50334440",label:"Tangelo orgánico seco"},
    {prodId:"50334441",label:"Naranja mandarina orgánicas secas"},
    {prodId:"50334442",label:"Naranja templo orgánicas secas"},
    {prodId:"50334443",label:"Naranja thornton orgánicas secas"},
    {prodId:"50334444",label:"Mandarina wekiwa orgánicas secas"},
    {prodId:"50334445",label:"Mandarina wilkins orgánicas secas"},
    {prodId:"50334446",label:"Mandarina willowleaf mediterránea orgánicas secas"},
    {prodId:"50334600",label:"Melones orgánicos secos"},
    {prodId:"50334601",label:"Melon allsweet orgánico seco"},
    {prodId:"50334602",label:"Melón athena orgánico seco"},
    {prodId:"50334603",label:"Melón diamante negro orgánico seco"},
    {prodId:"50334604",label:"Melón cal dulce orgánico seco"},
    {prodId:"50334605",label:"Melón carnical orgánico seco"},
    {prodId:"50334606",label:"Melón cantaloupe orgánico seco"},
    {prodId:"50334607",label:"Melón casaba orgánico seco"},
    {prodId:"50334608",label:"Melón cavaillon orgánico seco"},
    {prodId:"50334609",label:"Melón charentais orgánico seco"},
    {prodId:"50334610",label:"Sandia charleston gray orgánico seco"},
    {prodId:"50334611",label:"Melón crenshaw orgánico seco"},
    {prodId:"50334612",label:"Melón crimson sweet orgánico seco"},
    {prodId:"50334613",label:"Melón dixie lee orgánico seco"},
    {prodId:"50334614",label:"Melón eclipse orgánico seco"},
    {prodId:"50334615",label:"Melón ein dór  orgánico seco"},
    {prodId:"50334616",label:"Melón fiesta orgánico seco"},
    {prodId:"50334617",label:"Melón galia orgánico seco"},
    {prodId:"50334618",label:"Melón gaya orgánico seco"},
    {prodId:"50334619",label:"Melón hami orgánico seco"},
    {prodId:"50334620",label:"Melón miel dew orgánico seco"},
    {prodId:"50334621",label:"Melón hielo orgánico seco"},
    {prodId:"50334622",label:"Melón ida orgullo orgánico seco"},
    {prodId:"50334623",label:"Melón juan canary orgánico seco"},
    {prodId:"50334624",label:"Melón jubileo orgánico seco"},
    {prodId:"50334625",label:"Melón jubilación orgánico seco"},
    {prodId:"50334626",label:"Melón kaki / kakri orgánico seco"},
    {prodId:"50334627",label:"Melón kiwano orgánico seco"},
    {prodId:"50334628",label:"Melon koreano orgánico seco"},
    {prodId:"50334629",label:"Melón long grey orgánico seco"},
    {prodId:"50334630",label:"Melón mayan orgánico seco"},
    {prodId:"50334631",label:"Melón micky lee orgánico seco"},
    {prodId:"50334632",label:"Melón mirage orgánico seco"},
    {prodId:"50334633",label:"Sandia luna y estrellas orgánico seco"},
    {prodId:"50334634",label:"Melón ogen orgánico seco"},
    {prodId:"50334635",label:"Melón patriot orgánico seco"},
    {prodId:"50334636",label:"Melón peacook orgánico seco"},
    {prodId:"50334637",label:"Melón pepino orgánico seco"},
    {prodId:"50334638",label:"Melón persian orgánico seco"},
    {prodId:"50334639",label:"Melón picnic orgánico seco"},
    {prodId:"50334640",label:"Melón piel de sapo orgánico seco"},
    {prodId:"50334641",label:"Melón piña orgánico seco"},
    {prodId:"50334642",label:"Melón quetzali orgánico seco"},
    {prodId:"50334643",label:"Melón goblin rojo orgánico seco"},
    {prodId:"50334644",label:"Melón regency orgánico seco"},
    {prodId:"50334645",label:"Melón royal mejestic orgánico seco"},
    {prodId:"50334646",label:"Melón royal star orgánico seco"},
    {prodId:"50334647",label:"Melón dulce royal orgánico seco"},
    {prodId:"50334648",label:"Malon santa claus orgánico seco"},
    {prodId:"50334649",label:"Melón sharlyn orgánico seco"},
    {prodId:"50334650",label:"Melón español orgánico seco"},
    {prodId:"50334651",label:"Melón sprite orgánic orgánico seco"},
    {prodId:"50334652",label:"Melón starbright orgánico seco"},
    {prodId:"50334653",label:"Melón stars and stripes orgánico seco"},
    {prodId:"50334654",label:"Melón bebe de azúcar orgánico seco"},
    {prodId:"50334655",label:"Sandia bebe de azúcar orgánicas secas"},
    {prodId:"50334656",label:"Melón dulce sol orgánico seco"},
    {prodId:"50334657",label:"Sandia corazón dulce sin semilla orgánicas secas"},
    {prodId:"50334658",label:"Melón tentación orgánico seco"},
    {prodId:"50334659",label:"Melón bebe tigre orgánico seco"},
    {prodId:"50334660",label:"Melón tuscan tipo orgánico seco"},
    {prodId:"50334661",label:"Sandia bebe amarillo orgánicas secas"},
    {prodId:"50334700",label:"Moras mulberry orgánicas secas"},
    {prodId:"50334701",label:"Mora negra orgánicas secas"},
    {prodId:"50334702",label:"Mora blanca orgánicas secas"},
    {prodId:"50334800",label:"Mirtos orgánicos secos"},
    {prodId:"50334801",label:"Mirto bog orgánico seco"},
    {prodId:"50334900",label:"Nectarinas orgánicos secos"},
    {prodId:"50334901",label:"Durazno april glo orgánico seco"},
    {prodId:"50334902",label:"Durazno arctic mist orgánico seco"},
    {prodId:"50334903",label:"Durazno artic snow orgánico seco"},
    {prodId:"50334904",label:"Durazno artic star orgánico seco"},
    {prodId:"50334905",label:"Durazno artic dulce orgánico seco"},
    {prodId:"50334906",label:"Durazno artic glo orgánico seco"},
    {prodId:"50334907",label:"Durazno august fire orgánico seco"},
    {prodId:"50334908",label:"Durazno  perla de agosto orgánico seco"},
    {prodId:"50334909",label:"Durazno agosto rojo orgánico seco"},
    {prodId:"50334910",label:"Durazno estrella de agosto orgánico seco"},
    {prodId:"50334911",label:"Durazno big john orgánico seco"},
    {prodId:"50334912",label:"Durazno perla brillante orgánico seco"},
    {prodId:"50334913",label:"Durazno diamante brillante orgánico seco"},
    {prodId:"50334914",label:"Durazno diamante ray orgánico seco"},
    {prodId:"50334915",label:"Durazno earligo orgánico seco"},
    {prodId:"50334916",label:"Durazno diamante temprano orgánico seco"},
    {prodId:"50334917",label:"Durazno fairlane orgánico seco"},
    {prodId:"50334918",label:"Durazno fantasia orgánico seco"},
    {prodId:"50334919",label:"Durazno perla fuego orgánico seco"},
    {prodId:"50334920",label:"Durazno fuego dulce orgánico seco"},
    {prodId:"50334921",label:"Durazno llamakist orgánico seco"},
    {prodId:"50334922",label:"Durazno tipo plano orgánico seco"},
    {prodId:"50334923",label:"Durazno delicia de jardín orgánico seco"},
    {prodId:"50334924",label:"Durazno mina de oro orgánico seco"},
    {prodId:"50334925",label:"Durazno perla grande orgánico seco"},
    {prodId:"50334926",label:"Durazno hardiroja orgánico seco"},
    {prodId:"50334927",label:"Durazno miel de fuego orgánico seco"},
    {prodId:"50334928",label:"Durazno julio rojo orgánico seco"},
    {prodId:"50334929",label:"Durazno kay perla orgánico seco"},
    {prodId:"50334930",label:"Durazno key dulce orgánico seco"},
    {prodId:"50334931",label:"Durazno diamante mayo orgánico seco"},
    {prodId:"50334932",label:"Durazno mayfire orgánico seco"},
    {prodId:"50334933",label:"Durazno mayglo orgánico seco"},
    {prodId:"50334934",label:"Durazno mericrest orgánico seco"},
    {prodId:"50334935",label:"Durazno diamante rojo orgánico seco"},
    {prodId:"50334936",label:"Durazno oro rojo orgánico seco"},
    {prodId:"50334937",label:"Durazno jim rojo orgánico seco"},
    {prodId:"50334938",label:"Durazno roy rojo orgánico seco"},
    {prodId:"50334939",label:"Durazno rio rojo orgánico seco"},
    {prodId:"50334940",label:"Durazno diamante de rosa orgánico seco"},
    {prodId:"50334941",label:"Durazno rotal glo orgánico seco"},
    {prodId:"50334942",label:"Durazno diamante ryby orgánico seco"},
    {prodId:"50334943",label:"Durazno ruby dulce orgánico seco"},
    {prodId:"50334944",label:"Durazno joya ruddy orgánico seco"},
    {prodId:"50334945",label:"Durazno septiembre rojo orgánico seco"},
    {prodId:"50334946",label:"Durazno reina de nieve orgánico seco"},
    {prodId:"50334947",label:"Durazno primavera clara orgánico seco"},
    {prodId:"50334948",label:"Durazno primavera roja orgánico seco"},
    {prodId:"50334949",label:"Durazno verano rojiso orgánico seco"},
    {prodId:"50334950",label:"Durazno verano claro orgánico seco"},
    {prodId:"50334951",label:"Durazno verano diamante orgánico seco"},
    {prodId:"50334952",label:"Durazno verano fuego orgánico seco"},
    {prodId:"50334953",label:"Durazno verano grande orgánico seco"},
    {prodId:"50334954",label:"Durazno sunglo orgánico seco"},
    {prodId:"50334955",label:"Durazno fuego zee orgánico seco"},
    {prodId:"50334956",label:"Durazno zee glo orgánico seco"},
    {prodId:"50334957",label:"Durazno zeegrand orgánico seco"},
    {prodId:"50335000",label:"Naranjas orgánicas secas"},
    {prodId:"50335001",label:"Naranja áfrica agria orgánicas secas"},
    {prodId:"50335002",label:"Naranja dulce amber orgánicas secas"},
    {prodId:"50335003",label:"Naranja argentina agria orgánicas secas"},
    {prodId:"50335004",label:"Naranja bahianinha orgánico seco"},
    {prodId:"50335005",label:"Naranja bergamot orgánicas secas"},
    {prodId:"50335006",label:"Naranja berna orgánicas secas"},
    {prodId:"50335007",label:"Naranja bigaradier apepu orgánicas secas"},
    {prodId:"50335008",label:"Naranja agria dulce daidai orgánicas secas"},
    {prodId:"50335009",label:"Naranja mono orgánicas secas"},
    {prodId:"50335010",label:"Naranja sangre orgánicas secas"},
    {prodId:"50335011",label:"Naranja california navel orgánicas secas"},
    {prodId:"50335012",label:"Naranja cara cara orgánicas secas"},
    {prodId:"50335013",label:"Naranja chinotto orgánicas secas"},
    {prodId:"50335014",label:"Naranja sueño nevel orgánicas secas"},
    {prodId:"50335015",label:"Naranja gou tou orgánicas secas"},
    {prodId:"50335016",label:"Naranja hamlin orgánicas secas"},
    {prodId:"50335017",label:"Naranja jaffa orgánicas secas"},
    {prodId:"50335018",label:"Naranja jincheng orgánicas secas"},
    {prodId:"50335019",label:"Naranja k-temprano orgánicas secas"},
    {prodId:"50335020",label:"Naranja kona orgánicas secas"},
    {prodId:"50335021",label:"Naranja navel tarde orgánicas secas orgánicas secas"},
    {prodId:"50335022",label:"Naranja valencia tarde orgánicas secas"},
    {prodId:"50335023",label:"Naranja limequat orgánicas secas"},
    {prodId:"50335024",label:"Naranja mar orgánicas secas"},
    {prodId:"50335025",label:"Naranja malegold orgánicas secas"},
    {prodId:"50335026",label:"Naranja moro orgánicas secas"},
    {prodId:"50335027",label:"Naranja moro tabaco orgánicas secas"},
    {prodId:"50335028",label:"Naranja navel orgánicas secas"},
    {prodId:"50335029",label:"Naranja navelina orgánicas secas"},
    {prodId:"50335030",label:"Naranja oro blanco orgánicas secas"},
    {prodId:"50335031",label:"Naranja osceola orgánicas secas"},
    {prodId:"50335032",label:"Naranja parson carmelito orgánicas secas"},
    {prodId:"50335033",label:"Naranja pera orgánicas secas"},
    {prodId:"50335034",label:"Naranjo pummulo orgánicas secas"},
    {prodId:"50335035",label:"Naranja rhode rojo orgánicas secas"},
    {prodId:"50335036",label:"Naranja roble orgánicas secas"},
    {prodId:"50335037",label:"Naranja salustianas orgánicas secas"},
    {prodId:"50335038",label:"Naranja sanguine orgánicas secas"},
    {prodId:"50335039",label:"Naranja sanguinelli orgánicas secas"},
    {prodId:"50335040",label:"Naranja cevilla orgánicas secas"},
    {prodId:"50335041",label:"Naranja shamouti jaffa orgánicas secas"},
    {prodId:"50335042",label:"Naranja tunis orgánicas secas"},
    {prodId:"50335043",label:"Naranja valencia orgánicas secas"},
    {prodId:"50335044",label:"Naranja washington navel orgánicas secas"},
    {prodId:"50335100",label:"Papayas orgánicas secas"},
    {prodId:"50335101",label:"Papaya verde cocinar orgánicas secas"},
    {prodId:"50335102",label:"Papaya maradol orgánicas secas"},
    {prodId:"50335103",label:"Papaya amarillo mexicano orgánicas secas"},
    {prodId:"50335104",label:"Papaya montaña orgánicas secas"},
    {prodId:"50335105",label:"Papaya solo orgánicas secas"},
    {prodId:"50335106",label:"Papaya tainung orgánicas secas"},
    {prodId:"50335200",label:"Maracuyás orgánicas secas"},
    {prodId:"50335201",label:"Maracuyá banana orgánicas secas"},
    {prodId:"50335202",label:"Maracuyá azul orgánicas secas"},
    {prodId:"50335203",label:"Maracuyá crackerjack orgánicas secas"},
    {prodId:"50335204",label:"Maracuyá granadilla gigante orgánicas secas"},
    {prodId:"50335205",label:"Maracuyá granadilla dorada orgánicas secas"},
    {prodId:"50335206",label:"Maracuyá maypops orgánicas secas"},
    {prodId:"50335207",label:"Maracuyá roja orgánicas secas"},
    {prodId:"50335208",label:"Maracuyá granadilla dulce orgánicas secas"},
    {prodId:"50335209",label:"Maracuyá sandia orgánicas secas"},
    {prodId:"50335210",label:"Maracuyá  wing- stemmed orgánicas secas"},
    {prodId:"50335400",label:"Peras orgánicas secas"},
    {prodId:"50335401",label:"Peras abate fetel orgánicas secas"},
    {prodId:"50335402",label:"Peras anjou orgánicas secas"},
    {prodId:"50335403",label:"Pera asiática orgánicas secas"},
    {prodId:"50335404",label:"Pera bartlett orgánicas secas"},
    {prodId:"50335405",label:"Pera best ever orgánicas secas"},
    {prodId:"50335406",label:"Pera beth orgánicas secas"},
    {prodId:"50335407",label:"Pera beurre orgánicas secas"},
    {prodId:"50335408",label:"Pera bosc orgánicas secas"},
    {prodId:"50335409",label:"Pera clapp favorita orgánicas secas"},
    {prodId:"50335410",label:"Pera comice orgánicas secas"},
    {prodId:"50335411",label:"Pera concorde orgánicas secas"},
    {prodId:"50335412",label:"Pera conference orgánicas secas"},
    {prodId:"50335413",label:"Pera crimson rojo orgánicas secas"},
    {prodId:"50335414",label:"Peras d' anjou orgánicas secas"},
    {prodId:"50335415",label:"Pera dr jules guyot orgánicas secas"},
    {prodId:"50335416",label:"Peras early orgánicas secas"},
    {prodId:"50335417",label:"Peras emperador carmelito orgánicas secas"},
    {prodId:"50335418",label:"Peras forelle orgánicas secas"},
    {prodId:"50335419",label:"Pera mantequilla francesa orgánicas secas"},
    {prodId:"50335420",label:"Pera glou morceau orgánicas secas"},
    {prodId:"50335421",label:"Pera hosui orgánicas secas"},
    {prodId:"50335422",label:"Pera mantequilla italiana orgánicas secas"},
    {prodId:"50335423",label:"Pera jargonelle orgánicas secas"},
    {prodId:"50335424",label:"Pera juno orgánicas secas"},
    {prodId:"50335425",label:"Para kaiserlouise bonne de jersey orgánicas secas"},
    {prodId:"50335426",label:"Pera keiffer orgánicas secas"},
    {prodId:"50335427",label:"Pera rey royal orgánicas secas"},
    {prodId:"50335428",label:"Pera limonera orgánicas secas"},
    {prodId:"50335429",label:"Pera merton pride orgánicas secas"},
    {prodId:"50335430",label:"Pera mauntain bartlette orgánicas secas"},
    {prodId:"50335431",label:"Pera oliver de serrers orgánicas secas"},
    {prodId:"50335432",label:"Pera onward orgánicas secas"},
    {prodId:"50335433",label:"Pera packham's triumph orgánicas secas"},
    {prodId:"50335434",label:"Pera paraíso orgánicas secas"},
    {prodId:"50335435",label:"Pera passe crassane orgánicas secas"},
    {prodId:"50335436",label:"Pera perry orgánicas secas"},
    {prodId:"50335437",label:"Pera bartlette rojo orgánicas secas"},
    {prodId:"50335438",label:"Pera dánjou  rojo orgánicas secas"},
    {prodId:"50335439",label:"Pera rocha orgánicas secas"},
    {prodId:"50335440",label:"Pera rosey rojo orgánicas secas"},
    {prodId:"50335441",label:"Pera rosy roja orgánicas secas"},
    {prodId:"50335442",label:"Pera majestuosa royal orgánicas secas"},
    {prodId:"50335443",label:"Pera ruby rojo orgánicas secas"},
    {prodId:"50335444",label:"Pera santa maria orgánicas secas"},
    {prodId:"50335445",label:"Pera seckel orgánicas secas"},
    {prodId:"50335446",label:"Pera sensación orgánicas secas"},
    {prodId:"50335447",label:"Pera crimson estrella orgánicas secas"},
    {prodId:"50335448",label:"Pera crimson stark orgánicas secas"},
    {prodId:"50335449",label:"Pera bartlette de verano orgánicas secas"},
    {prodId:"50335450",label:"Pera verano dorado orgánicas secas"},
    {prodId:"50335451",label:"Pera sol dorado orgánicas secas"},
    {prodId:"50335452",label:"Pera sunprite orgánicas secas"},
    {prodId:"50335453",label:"Pera taylors dorado orgánicas secas"},
    {prodId:"50335454",label:"Pera taylors rojo orgánicas secas"},
    {prodId:"50335455",label:"Pera tientsin orgánicas secas"},
    {prodId:"50335456",label:"Pera tosca orgánicas secas"},
    {prodId:"50335457",label:"Pera warden  orgánicas secas"},
    {prodId:"50335458",label:"Pera williams bon chretien orgánicas secas"},
    {prodId:"50335459",label:"Pera williams orgánicas secas"},
    {prodId:"50335460",label:"Pera nelis de invierno orgánicas secas"},
    {prodId:"50335500",label:"Caquis orgánicos secos"},
    {prodId:"50335501",label:"Caqui americano orgánicas secas"},
    {prodId:"50335502",label:"Caqui sapote negro orgánicas secas"},
    {prodId:"50335503",label:"Caqui chapote / negro orgánicas secas"},
    {prodId:"50335504",label:"Caqui dátale ciruela orgánicas secas"},
    {prodId:"50335505",label:"Caqui fuyu orgánicas secas"},
    {prodId:"50335506",label:"Caqui gigante fuyu orgánicas secas"},
    {prodId:"50335507",label:"Caqui hachiya orgánicas secas"},
    {prodId:"50335508",label:"Caqui mantequilla / mabolo orgánicas secas"},
    {prodId:"50335509",label:"Caqui príncipe ito orgánicas secas"},
    {prodId:"50335510",label:"Caqui brillante royal orgánicas secas"},
    {prodId:"50335511",label:"Caqui sharon orgánicas secas"},
    {prodId:"50335512",label:"Caqui triumph orgánicas secas"},
    {prodId:"50335600",label:"Piñas orgánicas secas"},
    {prodId:"50335601",label:"Piña chirimoya orgánicas secas"},
    {prodId:"50335602",label:"Piña dorada orgánicas secas"},
    {prodId:"50335603",label:"Piña hilo orgánicas secas"},
    {prodId:"50335604",label:"Piña kona sugarloaf orgánicas secas"},
    {prodId:"50335605",label:"Piña reina natal orgánicas secas"},
    {prodId:"50335606",label:"Piña pernabuco orgánicas secas"},
    {prodId:"50335607",label:"Piña español rojo orgánicas secas"},
    {prodId:"50335608",label:"Piña cayen suave orgánicas secas"},
    {prodId:"50335609",label:"Piña sugarloaf orgánicas secas"},
    {prodId:"50335610",label:"Piña variegated orgánicas secas"},
    {prodId:"50335700",label:"Ciruelos Damascos orgánicos secos"},
    {prodId:"50335701",label:"Ciruelo / damasco negro kat orgánico seco"},
    {prodId:"50335702",label:"Ciruelo / damasco gusto azul orgánico seco"},
    {prodId:"50335703",label:"Ciruelo / damasco corazón de crimson orgánico seco"},
    {prodId:"50335704",label:"Ciruelo / damasco dapply dandy orgánico seco"},
    {prodId:"50335705",label:"Ciruelo / damasco dapple fuego orgánico seco"},
    {prodId:"50335706",label:"Ciruelo / damasco  dapple temprano orgánico seco"},
    {prodId:"50335707",label:"Ciruelo / damasco  caída de sabor orgánico seco"},
    {prodId:"50335708",label:"Ciruelo / damasco sabor de oro orgánico seco"},
    {prodId:"50335709",label:"Ciruelo / damasco sabor granada orgánico seco"},
    {prodId:"50335710",label:"Ciruelo / damasco sabor de corazón orgánico seco"},
    {prodId:"50335711",label:"Ciruelo / damasco joya de sabor orgánico seco"},
    {prodId:"50335712",label:"Ciruelo / damasco sabor de rey orgánico seco"},
    {prodId:"50335713",label:"Ciruelo / damasco sabor de reina orgánico seco"},
    {prodId:"50335714",label:"Ciruelo / damasco sabor supremo orgánico seco"},
    {prodId:"50335715",label:"Ciruelo / damasco sabor premio orgánico seco"},
    {prodId:"50335716",label:"Ciruelo / damasco saborella orgánico seco"},
    {prodId:"50335717",label:"Ciruelo / damasco saborrico orgánico seco"},
    {prodId:"50335718",label:"Ciruelo / damasco  sabor rosa orgánico seco"},
    {prodId:"50335719",label:"Ciruelo / damasco orgullo geo orgánico seco"},
    {prodId:"50335720",label:"Ciruelo / damasco kat rojo orgánico seco"},
    {prodId:"50335721",label:"Ciruelo / damasco premio royal orgánico seco"},
    {prodId:"50335722",label:"Ciruelo / damasco rosa sierra orgánico seco"},
    {prodId:"50335723",label:"Ciruelo / damasco geisha dulce orgánico seco"},
    {prodId:"50335800",label:"Ciruelas orgánicas secas"},
    {prodId:"50335801",label:"Ciruela joya amber orgánicas secas"},
    {prodId:"50335802",label:"Ciruela angeleno orgánicas secas"},
    {prodId:"50335803",label:"Ciruela aurora orgánicas secas"},
    {prodId:"50335804",label:"Ciruela otoño bonito orgánicas secas"},
    {prodId:"50335805",label:"Ciruela gigante de otoño orgánicas secas"},
    {prodId:"50335806",label:"Ciruela orgullo de otoño orgánicas secas"},
    {prodId:"50335807",label:"Ciruela rosa de otoño orgánicas secas"},
    {prodId:"50335808",label:"Ciruela playero orgánicas secas"},
    {prodId:"50335809",label:"Ciruela betty anne orgánicas secas"},
    {prodId:"50335810",label:"Ciruela belleza negra orgánicas secas"},
    {prodId:"50335811",label:"Ciruela bullase negra orgánicas secas"},
    {prodId:"50335812",label:"Ciruela diamante negro orgánicas secas"},
    {prodId:"50335813",label:"Ciruela gigante negro orgánicas secas"},
    {prodId:"50335814",label:"Ciruela hielo negro orgánicas secas"},
    {prodId:"50335815",label:"Ciruela esplendor negro orgánicas secas"},
    {prodId:"50335816",label:"Ciruela ámbar negro orgánicas secas"},
    {prodId:"50335817",label:"Ciruela purpura orgánicas secas"},
    {prodId:"50335818",label:"Ciruela  carsbad orgánicas secas"},
    {prodId:"50335819",label:"Ciruela casselman orgánicas secas"},
    {prodId:"50335820",label:"Ciruela catalina orgánicas secas"},
    {prodId:"50335821",label:"Ciruela damson orgánicas secas"},
    {prodId:"50335822",label:"Ciruela dolly orgánicas secas"},
    {prodId:"50335823",label:"Ciruela earlireina orgánicas secas"},
    {prodId:"50335824",label:"Ciruela rosa early orgánicas secas"},
    {prodId:"50335825",label:"Ciruela ébano mayo orgánicas secas"},
    {prodId:"50335826",label:"Ciruela ébano orgánicas secas"},
    {prodId:"50335827",label:"Ciruela corazón de elefante orgánicas secas"},
    {prodId:"50335828",label:"Ciruela belleza de esmeralda orgánicas secas"},
    {prodId:"50335829",label:"Ciruela emperatriz orgánicas secas"},
    {prodId:"50335830",label:"Ciruela libertad orgánicas secas"},
    {prodId:"50335831",label:"Ciruela friar orgánicas secas"},
    {prodId:"50335832",label:"Ciruela rojo gar orgánicas secas"},
    {prodId:"50335833",label:"Ciruela gobernador orgánicas secas"},
    {prodId:"50335834",label:"Ciruela rosa grande orgánicas secas"},
    {prodId:"50335835",label:"Ciruela green gage orgánicas secas"},
    {prodId:"50335836",label:"Ciruela greengage orgánicas secas"},
    {prodId:"50335837",label:"Ciruela hiromi orgánicas secas"},
    {prodId:"50335838",label:"Ciruela hiromi rojo orgánicas secas"},
    {prodId:"50335839",label:"Ciruela vacacion orgánicas secas"},
    {prodId:"50335840",label:"Ciruela howard sol orgánicas secas"},
    {prodId:"50335841",label:"Ciruela tipo interspecific orgánicas secas"},
    {prodId:"50335842",label:"Ciruela jamaico orgánicas secas"},
    {prodId:"50335843",label:"Ciruela joanna rojo orgánicas secas"},
    {prodId:"50335844",label:"Ciruela kelsey orgánicas secas"},
    {prodId:"50335845",label:"Ciruela jaime rey orgánicas secas"},
    {prodId:"50335846",label:"Ciruela laroda orgánicas secas"},
    {prodId:"50335847",label:"Ciruela rosa tarde orgánicas secas"},
    {prodId:"50335848",label:"Ciruela rosa linda orgánicas secas"},
    {prodId:"50335849",label:"Ciruela estrella solitaria orgánicas secas"},
    {prodId:"50335850",label:"Ciruela mariposa orgánicas secas"},
    {prodId:"50335851",label:"Ciruela mercado negro orgánicas secas"},
    {prodId:"50335852",label:"Ciruela mercado rojo orgánicas secas"},
    {prodId:"50335853",label:"Ciruela maribel orgánicas secas"},
    {prodId:"50335854",label:"Ciruelas sol de octubre orgánicas secas"},
    {prodId:"50335855",label:"Ciruela owen t orgánicas secas"},
    {prodId:"50335856",label:"Ciruela perdrigon orgánicas secas"},
    {prodId:"50335857",label:"Ciruela placer rosado orgánicas secas"},
    {prodId:"50335858",label:"Ciruela  presidente orgánicas secas"},
    {prodId:"50335859",label:"Ciruela hora prima orgánicas secas"},
    {prodId:"50335860",label:"Ciruela majestad purpura orgánicas secas"},
    {prodId:"50335861",label:"Ciruela reina rosa orgánicas secas"},
    {prodId:"50335862",label:"Ciruela quetsch orgánicas secas"},
    {prodId:"50335863",label:"Ciruela belleza roja orgánicas secas"},
    {prodId:"50335864",label:"Ciruela camino rojo orgánicas secas"},
    {prodId:"50335865",label:"Ciruela ram rojo orgánicas secas"},
    {prodId:"50335866",label:"Ciruela rosa roja orgánicas secas"},
    {prodId:"50335867",label:"Ciruela rojo rico orgánicas secas"},
    {prodId:"50335868",label:"Ciruela romaro orgánicas secas"},
    {prodId:"50335869",label:"Ciruela diamante rojo orgánicas secas"},
    {prodId:"50335870",label:"Ciruela rojo royal orgánicas secas"},
    {prodId:"50335871",label:"Ciruela royal zee orgánicas secas"},
    {prodId:"50335872",label:"Ciruela roysum orgánicas secas"},
    {prodId:"50335873",label:"Ciruela santa rosa orgánicas secas"},
    {prodId:"50335874",label:"Ciruela zafiro orgánicas secas"},
    {prodId:"50335875",label:"Ciruela sloe orgánicas secas"},
    {prodId:"50335876",label:"Ciruela sta catherine orgánicas secas"},
    {prodId:"50335877",label:"Ciruela bullase blanco orgánicas secas"},
    {prodId:"50335900",label:"Granadas orgánicas secas"},
    {prodId:"50335901",label:"Granada foothhill orgánicas secas"},
    {prodId:"50335902",label:"Granada, granada orgánicas secas"},
    {prodId:"50335903",label:"Granada rojo feliz orgánicas secas"},
    {prodId:"50335904",label:"Granada nana orgánicas secas"},
    {prodId:"50335905",label:"Granada rojo de pat orgánicas secas"},
    {prodId:"50335906",label:"Granada pinkhan orgánic"},
    {prodId:"50335907",label:"Granada terciopelo morado orgánicas secas"},
    {prodId:"50335908",label:"Granada grandioso orgánicas secas"},
    {prodId:"50336000",label:"Pomelos orgánicos secos"},
    {prodId:"50336001",label:"Pomelo chandler orgánico seco"},
    {prodId:"50336002",label:"Pomelo hirado butan orgánico seco"},
    {prodId:"50336003",label:"Pomelo liang ping yau orgánico seco"},
    {prodId:"50336004",label:"Pomelo panda wangi orgánico seco"},
    {prodId:"50336005",label:"Pomelo rosado orgánico seco"},
    {prodId:"50336006",label:"Pomelo shaddock rojo orgánico seco"},
    {prodId:"50336007",label:"Pomelo siamese dulce orgánico seco"},
    {prodId:"50336008",label:"Pomelo waingwright orgánico seco"},
    {prodId:"50336100",label:"Membrillos orgánicos secos"},
    {prodId:"50336101",label:"Membrillo campeón orgánico seco"},
    {prodId:"50336102",label:"Membrillo piña orgánico seco"},
    {prodId:"50336103",label:"Membrillo smyma orgánico seco"},
    {prodId:"50336200",label:"Frambuesas orgánicas secas"},
    {prodId:"50336201",label:"Frambuesa americana roja orgánicas secas"},
    {prodId:"50336202",label:"Frambuesa bailey reinasland orgánicas secas"},
    {prodId:"50336203",label:"Frambuesa negra orgánicas secas"},
    {prodId:"50336204",label:"Frambuesa oscura orgánicas secas"},
    {prodId:"50336205",label:"Frambuesa deliciosa orgánicas secas"},
    {prodId:"50336206",label:"Frambuesa enano focke orgánicas secas"},
    {prodId:"50336207",label:"Frambuesa focke hojagris orgánicas secas"},
    {prodId:"50336208",label:"Frambuesa focke fresa orgánicas secas"},
    {prodId:"50336209",label:"Frambuesa  focke amarillo himalaya orgánicas secas"},
    {prodId:"50336210",label:"Frambuesa dorado orgánic"},
    {prodId:"50336211",label:"Frambuesa gris nuevo mexico orgánicas secas"},
    {prodId:"50336212",label:"Frambuesa jepson blancobark orgánicas secas"},
    {prodId:"50336213",label:"Frambuesa kellogs san diego orgánicas secas"},
    {prodId:"50336214",label:"Frambuesa leucodemis blancobark orgánicas secas"},
    {prodId:"50336215",label:"Frambuesa munz cuyamaca orgánicas secas"},
    {prodId:"50336216",label:"Frambuesa peck bartons orgánicas secas"},
    {prodId:"50336217",label:"Frambuesa flor morada orgánicas secas"},
    {prodId:"50336218",label:"Frambuesa roadside orgánicas secas"},
    {prodId:"50336219",label:"Frambuesa san diego orgánicas secas"},
    {prodId:"50336220",label:"Frambuesa nieve orgánicas secas"},
    {prodId:"50336221",label:"Frambuesa pico de nieve orgánicas secas"},
    {prodId:"50336222",label:"Frambuesa hoja de fresa orgánicas secas"},
    {prodId:"50336223",label:"Frambuesa cultivo dulce orgánicas secas"},
    {prodId:"50336224",label:"Frambuesa tor y gris blancobark orgánicas secas"},
    {prodId:"50336225",label:"Frambuesa caribe orgánicas secas"},
    {prodId:"50336226",label:"Frambuesa blancobark orgánicas secas"},
    {prodId:"50336227",label:"Frambuesa vino orgánicas secas"},
    {prodId:"50336228",label:"Frambuesa himalaya amarillo orgánicas secas"},
    {prodId:"50336229",label:"Frambuesa yu-shan orgánicas secas"},
    {prodId:"50336300",label:"Ruibarbos orgánicos secos"},
    {prodId:"50336301",label:"Ruibarbo crimson rojo orgánico seco"},
    {prodId:"50336302",label:"Ruibarbo champagne temprana orgánico seco"},
    {prodId:"50336303",label:"Ruibarbo glasrey perpetual  orgánico seco"},
    {prodId:"50336304",label:"Ruibarbo sutton orgánico seco"},
    {prodId:"50336305",label:"Ruibarbo timperley temprano orgánico seco"},
    {prodId:"50336306",label:"Ruibarbo valentina orgánico seco"},
    {prodId:"50336307",label:"Ruibarbo victoria orgánico seco"},
    {prodId:"50336308",label:"Ruibarbo zwolle de cemilla orgánico seco"},
    {prodId:"50336309",label:"Ruibarbo macdonald orgánico seco"},
    {prodId:"50336310",label:"Ruibarbo tilden orgánico seco"},
    {prodId:"50336400",label:"Escaramujos orgánicos secos"},
    {prodId:"50336401",label:"Escaramujo rosa brier orgánico seco"},
    {prodId:"50336402",label:"Escaramujo rosa elegante orgánico seco"},
    {prodId:"50336403",label:"Escaramujo rosa rugosa orgánico seco"},
    {prodId:"50336404",label:"Escaramujo rosa burnet o scotch orgánico seco"},
    {prodId:"50336500",label:"Sapotes orgánicos secos"},
    {prodId:"50336501",label:"Sapote blanco orgánico seco"},
    {prodId:"50336502",label:"Sapote negro orgánico seco"},
    {prodId:"50336600",label:"Bayas saskatoon orgánicas secas"},
    {prodId:"50336601",label:"Baya mielmadera saskatoon orgánicas secas"},
    {prodId:"50336602",label:"Baya northline saskatoon orgánicas secas"},
    {prodId:"50336603",label:"Baya saskatoon ahumado orgánicas secas"},
    {prodId:"50336604",label:"Baya saskatoon thiessen orgánicas secas"},
    {prodId:"50336700",label:"Fresas orgánicas secas"},
    {prodId:"50336701",label:"Fresa chandler orgánicas secas"},
    {prodId:"50336702",label:"Fresa rumbo junio orgánicas secas"},
    {prodId:"50336703",label:"Fresa rumbo siempre orgánicas secas"},
    {prodId:"50336800",label:"Manzanas dulces orgánicas seca"},
    {prodId:"50336801",label:"Manzana kampong malve dulce orgánicas secas"},
    {prodId:"50336802",label:"Manzana dulce sin pepa orgánicas secas"},
    {prodId:"50336803",label:"Manzana dulce thai lessand orgánicas secas"},
    {prodId:"50336900",label:"Tamarillos orgánicos secos"},
    {prodId:"50336901",label:"Tamarindo amberlea dorado orgánico seco"},
    {prodId:"50336902",label:"Tamarindo calvo dorado orgánico seco"},
    {prodId:"50336903",label:"Tamarindo mina de oro orgánico seco"},
    {prodId:"50336904",label:"Tamarindo oratia rojo orgánico seco"},
    {prodId:"50336905",label:"Tamarindo beau rojo orgánico seco"},
    {prodId:"50336906",label:"Tamarindo delicia roja orgánico seco"},
    {prodId:"50337100",label:"Aronias orgánicas secas"},
    {prodId:"50337101",label:"Aronia magia de otoño orgánicas secas"},
    {prodId:"50337102",label:"Aronia brillantísima orgánicas secas"},
    {prodId:"50337103",label:"Aronia nero orgánicas secas"},
    {prodId:"50337104",label:"Aronia viquingo orgánicas secas"},
    {prodId:"50337200",label:"Aceitunas orgánicas secas"},
    {prodId:"50337201",label:"Aceitunas agrinion orgánicas secas"},
    {prodId:"50337202",label:"Aceitunas aleppo orgánicas secas"},
    {prodId:"50337203",label:"Aceitunas  alfonso orgánicas secas"},
    {prodId:"50337204",label:"Aceitunas  amfisa orgánicas secas"},
    {prodId:"50337205",label:"Aceitunas  arauco orgánicas secas"},
    {prodId:"50337206",label:"Aceitunas  arbequina orgánicas secas"},
    {prodId:"50337207",label:"Aceitunas  atlanta orgánicas secas"},
    {prodId:"50337208",label:"Aceitunas  cerignola orgánicas secas"},
    {prodId:"50337209",label:"Aceitunas  cracked provencal orgánicas secas"},
    {prodId:"50337210",label:"Aceitunas  empeltre orgánicas secas"},
    {prodId:"50337211",label:"Aceitunas gaeta  orgánicas secas"},
    {prodId:"50337212",label:"Aceitunas hondroelia orgánicas secas"},
    {prodId:"50337213",label:"Aceitunas kalamata orgánicas secas"},
    {prodId:"50337214",label:"Aceitunas kura orgánicas secas"},
    {prodId:"50337215",label:"Aceitunas ligurian orgánicas secas"},
    {prodId:"50337216",label:"Aceitunas lucque orgánicas secas"},
    {prodId:"50337217",label:"Aceitunas lugano orgánicas secas"},
    {prodId:"50337218",label:"Aceitunas manzanilla orgánicas secas"},
    {prodId:"50337219",label:"Aceitunas marche orgánicas secas"},
    {prodId:"50337220",label:"Aceitunas misión orgánicas secas"},
    {prodId:"50337221",label:"Aceitunas nafplion verde orgánicas secas"},
    {prodId:"50337222",label:"Aceitunas nicoise orgánicas secas"},
    {prodId:"50337223",label:"Aceitunas nyons orgánicas secas"},
    {prodId:"50337224",label:"Aceitunas picholine orgánicas secas"},
    {prodId:"50337225",label:"Aceitunas ponentine orgánicas secas"},
    {prodId:"50337226",label:"Aceitunas royal orgánicas secas"},
    {prodId:"50337227",label:"Aceitunas seracena orgánicas secas"},
    {prodId:"50337228",label:"Aceitunas sevillano orgánicas secas"},
    {prodId:"50337229",label:"Aceitunas sicilian orgánicas secas"},
    {prodId:"50337230",label:"Aceitunas toscanelle orgánicas secas"},
    {prodId:"50341500",label:"Manzanas congeladas"},
    {prodId:"50341501",label:"Manzanas akane congeladas"},
    {prodId:"50341502",label:"Manzana ambrosia congelada"},
    {prodId:"50341503",label:"Manzanas api congeladas"},
    {prodId:"50341504",label:"Manzanas baldwin congeladas"},
    {prodId:"50341505",label:"Manzanas braebum congeladas"},
    {prodId:"50341506",label:"Manzanas bramley congeladas"},
    {prodId:"50341507",label:"Manzana joven bramley congelado"},
    {prodId:"50341508",label:"Manzana calville blanche d'hiver congelado"},
    {prodId:"50341509",label:"Manzana cameo congeladas"},
    {prodId:"50341510",label:"Manzana charles ross congeladas"},
    {prodId:"50341511",label:"Manzana codlin congeladas"},
    {prodId:"50341512",label:"Manzana cortland congeladas"},
    {prodId:"50341513",label:"Manzana costard congeladas"},
    {prodId:"50341514",label:"Manzana court pendu plat congeladas"},
    {prodId:"50341515",label:"Manzana  cox's orange pippin congeladas"},
    {prodId:"50341516",label:"Manzana crab congeladas"},
    {prodId:"50341517",label:"Manzana crispin congeladas"},
    {prodId:"50341518",label:"Manzana deliciosa congeladas"},
    {prodId:"50341519",label:"Manzana duquesa congeladas"},
    {prodId:"50341520",label:"Manzana earligold congeladas"},
    {prodId:"50341521",label:"Manzana early mcintosh congeladas"},
    {prodId:"50341522",label:"Manzana elstar congeladas"},
    {prodId:"50341523",label:"Manzana empire congeladas"},
    {prodId:"50341524",label:"Manzana flower of kent congeladas"},
    {prodId:"50341525",label:"Manzana fiji congeladas"},
    {prodId:"50341526",label:"Manzana gala congeladas"},
    {prodId:"50341527",label:"Manzana gascoyne's scarlet congeladas"},
    {prodId:"50341528",label:"Manzana gillyflower congeladas"},
    {prodId:"50341529",label:"Manzana ginger gold congeladas"},
    {prodId:"50341530",label:"Manzana gladstone congeladas"},
    {prodId:"50341531",label:"Manzana gloster congeladas"},
    {prodId:"50341532",label:"Manzana gold supreme congeladas"},
    {prodId:"50341533",label:"Manzana dorado delicious congeladas"},
    {prodId:"50341534",label:"Manzana dorado nobel congeladas"},
    {prodId:"50341535",label:"Manzana granny smith congeladas"},
    {prodId:"50341536",label:"Manzana gravenstain congeladas"},
    {prodId:"50341537",label:"Manzana greening congeladas"},
    {prodId:"50341538",label:"Manzana greensleeves congeladas"},
    {prodId:"50341539",label:"Manzana honeycrisp congeladas"},
    {prodId:"50341540",label:"Manzana howgate wonder congeladas"},
    {prodId:"50341541",label:"Manzana roja ida congeladas"},
    {prodId:"50341542",label:"Manzana james grives congeladas"},
    {prodId:"50341543",label:"Manzana jersey mac congeladas"},
    {prodId:"50341544",label:"Manzana jaster congeladas"},
    {prodId:"50341545",label:"Manzana jonagold congeladas"},
    {prodId:"50341546",label:"Manzana jonamac congeladas"},
    {prodId:"50341547",label:"Manzana  jonathan  congeladas"},
    {prodId:"50341548",label:"Manzana katy congeladas"},
    {prodId:"50341549",label:"Manzana kidd's orange rojo congeladas"},
    {prodId:"50341550",label:"Manzana dama congeladas"},
    {prodId:"50341551",label:"Manzana ley de roma congeladas"},
    {prodId:"50341552",label:"Manzana laxton congeladas"},
    {prodId:"50341553",label:"Manzana lord derby congeladas"},
    {prodId:"50341554",label:"Manzana macoun  congeladas"},
    {prodId:"50341555",label:"Manzana mcintosh congeladas"},
    {prodId:"50341556",label:"Manzana mutsu congeladas"},
    {prodId:"50341557",label:"Manzana newtown pippin congeladas"},
    {prodId:"50341558",label:"Manzana northen spy congeladas congeladas"},
    {prodId:"50341559",label:"Manzana orleans reinette congeladas"},
    {prodId:"50341560",label:"Manzana ozark gold congeladas"},
    {prodId:"50341561",label:"Manzana pacific rosa congeladas"},
    {prodId:"50341562",label:"Manzana paula roja congeladas"},
    {prodId:"50341563",label:"Manzana peamain congeladas"},
    {prodId:"50341564",label:"Manzana dama rosada congeladas"},
    {prodId:"50341565",label:"Manzana pippin congeladas"},
    {prodId:"50341566",label:"Manzana pitmaston pineapple congeladas"},
    {prodId:"50341567",label:"Manzana pomme dàpi congeladas"},
    {prodId:"50341568",label:"Manzana prime dorado congeladas"},
    {prodId:"50341569",label:"Manzana roja astrakhan congeladas"},
    {prodId:"50341570",label:"Manzana roja boscoop congeladas"},
    {prodId:"50341571",label:"Manzana  roja chief congeladas"},
    {prodId:"50341572",label:"Manzana roja delicious congeladas"},
    {prodId:"50341573",label:"Manzana roja gravenstain congeladas"},
    {prodId:"50341574",label:"Manzana roja roma congeladas"},
    {prodId:"50341575",label:"Manzanarojo stayman congeladas"},
    {prodId:"50341576",label:"Manzana roja york congeladas"},
    {prodId:"50341577",label:"Manzana reinette congeladas"},
    {prodId:"50341578",label:"Manzana belleza de roma congeladas"},
    {prodId:"50341579",label:"Manzana russet congeladas"},
    {prodId:"50341580",label:"Manzana sierra belleza congeladas"},
    {prodId:"50341581",label:"Manzana spartan congeladas"},
    {prodId:"50341582",label:"Manzana stark crimson congeladas"},
    {prodId:"50341583",label:"Manzana starking congeladas"},
    {prodId:"50341584",label:"Manzana stayman congeladas"},
    {prodId:"50341585",label:"Manzana stayman winesap congeladas"},
    {prodId:"50341586",label:"Manzana verano rambo congeladas"},
    {prodId:"50341587",label:"Manzana tsugaru congeladas"},
    {prodId:"50341588",label:"Manzana veinte onzas congeladas"},
    {prodId:"50341589",label:"Manzana tydeman rojo congeladas"},
    {prodId:"50341590",label:"Manzana vistabella congeladas"},
    {prodId:"50341591",label:"Manzana weatley congeladas"},
    {prodId:"50341592",label:"Manzana blanco joanetine congeladas"},
    {prodId:"50341593",label:"Manzana blanco transparent congeladas"},
    {prodId:"50341594",label:"Manzana winesap congeladas"},
    {prodId:"50341595",label:"Manzana worcester congeladas"},
    {prodId:"50341596",label:"Manzana york imperial congeladas"},
    {prodId:"50341600",label:"Albaricoques congelados"},
    {prodId:"50341601",label:"Abaricoques ambercot congeladas"},
    {prodId:"50341602",label:"Albaricoques apache congeladas"},
    {prodId:"50341603",label:"Albaricoques brittany dorado congeladas"},
    {prodId:"50341604",label:"Albaricoque negro congeladas"},
    {prodId:"50341605",label:"Albaricoque blenheim congeladas"},
    {prodId:"50341606",label:"Albaricoque bonny congeladas"},
    {prodId:"50341607",label:"Albaricoque biluda congeladas"},
    {prodId:"50341608",label:"Albaricoque castlebrite congeladas"},
    {prodId:"50341609",label:"Albaricoque  clutha gold congeladas"},
    {prodId:"50341610",label:"Albaricoque clutha sun congeladas"},
    {prodId:"50341611",label:"Albaricoque derby royal congeladas"},
    {prodId:"50341612",label:"Albaricoque dina congeladas"},
    {prodId:"50341613",label:"Albaricoque earlicot congeladas"},
    {prodId:"50341614",label:"Albaricoque earliman congeladas"},
    {prodId:"50341615",label:"Albaricoque early bright congeladas"},
    {prodId:"50341616",label:"Albaricoque  flaming gold congeladas"},
    {prodId:"50341617",label:"Albaricoque frenso congeladas"},
    {prodId:"50341618",label:"Albaricoque gold bright congeladas"},
    {prodId:"50341619",label:"Albaricoque goldbar congeladas"},
    {prodId:"50341620",label:"Albaricoque dorado sweet congeladas"},
    {prodId:"50341621",label:"Albaricoque goldrich congeladas"},
    {prodId:"50341622",label:"Albaricoque helena congeladas"},
    {prodId:"50341623",label:"Albaricoque honeycot congeladas"},
    {prodId:"50341624",label:"Albaricoque imperial congeladas"},
    {prodId:"50341625",label:"Albaricoque jordanne congeladas"},
    {prodId:"50341626",label:"Albaricoque jumbo cot congeladas"},
    {prodId:"50341627",label:"Albaricoque kandy kot congeladas"},
    {prodId:"50341628",label:"Albaricoque katy congeladas"},
    {prodId:"50341629",label:"Albaricoque rey congeladas"},
    {prodId:"50341630",label:"Albaricoque lambertin congeladas"},
    {prodId:"50341631",label:"Albaricoque loma congeladas"},
    {prodId:"50341632",label:"Albaricoque lulu belle congeladas"},
    {prodId:"50341633",label:"Albaricoque modesto congeladas"},
    {prodId:"50341634",label:"Albaricoque moorpark congeladas"},
    {prodId:"50341635",label:"Albaricoque naranja ojo congeladas"},
    {prodId:"50341636",label:"Albaricoque palstein congeladas"},
    {prodId:"50341637",label:"Albaricoque patterson congeladas"},
    {prodId:"50341638",label:"Albaricoque perfection congeladas"},
    {prodId:"50341639",label:"Albaricoque poppy congeladas"},
    {prodId:"50341640",label:"Albaricoque poppycot congeladas"},
    {prodId:"50341641",label:"Albaricoque reina congeladas"},
    {prodId:"50341642",label:"Albaricoque riland congeladas"},
    {prodId:"50341643",label:"Albaricoque rival congeladas"},
    {prodId:"50341644",label:"Albaricoque robada congeladas"},
    {prodId:"50341645",label:"Albaricoque royal congeladas"},
    {prodId:"50341646",label:"Albaricoque  royal blenheim congeladas"},
    {prodId:"50341647",label:"Albaricoque  royal orange congeladas"},
    {prodId:"50341648",label:"Albaricoque sundrop congeladas"},
    {prodId:"50341649",label:"Albaricoque tilton congeladas"},
    {prodId:"50341650",label:"Albaricoque tomcot congeladas"},
    {prodId:"50341651",label:"Albaricoque tracy congeladas"},
    {prodId:"50341652",label:"Albaricoque tri gem congeladas"},
    {prodId:"50341653",label:"Albaricoque valley gold congeladas"},
    {prodId:"50341654",label:"Albaricoque westley congeladas"},
    {prodId:"50341655",label:"Albaricoque  york congeladas"},
    {prodId:"50341800",label:"Barberries congelados"},
    {prodId:"50341801",label:"Berberís paleleaf congeladas"},
    {prodId:"50341802",label:"Barberís chenault congeladas"},
    {prodId:"50341803",label:"Barberís rojo congeladas"},
    {prodId:"50341804",label:"Barberís wintergreen congeladas"},
    {prodId:"50341805",label:"Barberís korean congeladas"},
    {prodId:"50341806",label:"Barberís mentor congeladas"},
    {prodId:"50341807",label:"Barberís japanese congeladas"},
    {prodId:"50341808",label:"Barberís atropurpurea congeladas"},
    {prodId:"50341809",label:"Barberís aurea congeladas"},
    {prodId:"50341810",label:"Barberís bagatelle congeladas"},
    {prodId:"50341811",label:"Barberís crimsom pygmy congeladas"},
    {prodId:"50341812",label:"Barberís kobold congeladas"},
    {prodId:"50341813",label:"Barberís warty congeladas"},
    {prodId:"50341814",label:"Barberís europa congeladas"},
    {prodId:"50341900",label:"Gayubas congeladas"},
    {prodId:"50341901",label:"Gayuba alpine congeladas"},
    {prodId:"50341902",label:"Gayuba roja congeladas"},
    {prodId:"50341903",label:"Gayuba común congeladas"},
    {prodId:"50342100",label:"Arándanos de mirto congelados"},
    {prodId:"50342101",label:"Arandanos bog congeladas"},
    {prodId:"50342102",label:"Arandanos enano congeladas"},
    {prodId:"50342103",label:"Arandanos montaña congeladas"},
    {prodId:"50342104",label:"Arandanos oval-leaved congeladas"},
    {prodId:"50342200",label:"Agrás congeladas"},
    {prodId:"50342201",label:"Agras bluecrop congeladas"},
    {prodId:"50342202",label:"Agras bluetta congeladas"},
    {prodId:"50342203",label:"Agras brigitta congeladas"},
    {prodId:"50342204",label:"Agras chandler congeladas"},
    {prodId:"50342205",label:"Agras duque congeladas"},
    {prodId:"50342206",label:"Agras hardyblue congeladas"},
    {prodId:"50342207",label:"Agras legacy congeladas"},
    {prodId:"50342208",label:"Agras misti congeladas"},
    {prodId:"50342209",label:"Agras nelson congeladas"},
    {prodId:"50342210",label:"Agras northblue congeladas"},
    {prodId:"50342211",label:"Agras northcountry congeladas"},
    {prodId:"50342212",label:"Agras northsky congeladas"},
    {prodId:"50342213",label:"Agras patriot congeladas"},
    {prodId:"50342214",label:"Agras spartan congeladas"},
    {prodId:"50342215",label:"Agras toro congeladas"},
    {prodId:"50342300",label:"Frutas del pan congeladas"},
    {prodId:"50342301",label:"Fruta de pan chataigne congeladas"},
    {prodId:"50342302",label:"Fruta de pan sin pepa congeladas"},
    {prodId:"50342303",label:"Fruta de pan corazón blanco congeladas"},
    {prodId:"50342304",label:"Fruta de pan corazón amarillo congeladas"},
    {prodId:"50342400",label:"Chirimoyas congeladas"},
    {prodId:"50342401",label:"Chirimoya bays congeladas"},
    {prodId:"50342402",label:"Chirimoya bronceada congeladas"},
    {prodId:"50342403",label:"Chirimoya burtons congeladas"},
    {prodId:"50342404",label:"Chirimoya burton favorite congeladas"},
    {prodId:"50342405",label:"Chirimoya jete congeladas"},
    {prodId:"50342406",label:"Chirimoya reretai congeladas"},
    {prodId:"50342407",label:"Chirimoya smoothey congeladas"},
    {prodId:"50342408",label:"Chirimoya españa congeladas"},
    {prodId:"50342409",label:"Chirimoya blanco congeladas"},
    {prodId:"50342500",label:"Cerezas congeladas"},
    {prodId:"50342501",label:"Cereza amarelle congeladas"},
    {prodId:"50342502",label:"Cereza brooks congeladas"},
    {prodId:"50342503",label:"Cereza bigarreu congeladas"},
    {prodId:"50342504",label:"Cereza bing congeladas"},
    {prodId:"50342505",label:"Cereza republica negra congeladas"},
    {prodId:"50342506",label:"Cereza negro schmidt congeladas"},
    {prodId:"50342507",label:"Cereza negrotartarian congeladas"},
    {prodId:"50342508",label:"Cereza fiesta bing congeladas"},
    {prodId:"50342509",label:"Cereza garnet congeladas"},
    {prodId:"50342510",label:"Cereza rey congeladas"},
    {prodId:"50342511",label:"Cereza chapman congeladas"},
    {prodId:"50342512",label:"Cereza lapin congeladas"},
    {prodId:"50342513",label:"Cereza larian congeladas"},
    {prodId:"50342514",label:"Cereza dark guines congeladas"},
    {prodId:"50342515",label:"Cereza montmorency congeladas"},
    {prodId:"50342516",label:"Cereza duque congeladas"},
    {prodId:"50342517",label:"Cereza early rivers congeladas"},
    {prodId:"50342518",label:"Cereza ruby bing congeladas"},
    {prodId:"50342519",label:"Cereza santine congeladas"},
    {prodId:"50342520",label:"Cereza geans/guines congeladas"},
    {prodId:"50342521",label:"Cereza sonata congeladas"},
    {prodId:"50342522",label:"Cereza lambert congeladas"},
    {prodId:"50342523",label:"Cereza stella congeladas"},
    {prodId:"50342524",label:"Cereza sweethart congeladas"},
    {prodId:"50342525",label:"Cereza tartarian congeladas"},
    {prodId:"50342527",label:"Cereza maraschino congeladas"},
    {prodId:"50342528",label:"Cereza van congeladas"},
    {prodId:"50342529",label:"Cereza morello congeladas"},
    {prodId:"50342530",label:"Cereza royal ann congeladas"},
    {prodId:"50342531",label:"Cereza ranier congeladas"},
    {prodId:"50342532",label:"Cereza royal congeladas"},
    {prodId:"50342600",label:"Citrones congelados"},
    {prodId:"50342601",label:"Citrones buddha's hand congeladas"},
    {prodId:"50342602",label:"Citrones fingerojo congeladas"},
    {prodId:"50342603",label:"Citrones fo shoukan congeladas"},
    {prodId:"50342604",label:"Cotrones bushakan congeladas"},
    {prodId:"50342605",label:"Citones diamante congeladas"},
    {prodId:"50342606",label:"Citrones etrog congeladas"},
    {prodId:"50342607",label:"Citrones ponderosa congeladas"},
    {prodId:"50342700",label:"Arándanos congelados"},
    {prodId:"50342701",label:"Arandano ben lear congeladas"},
    {prodId:"50342702",label:"Arandano early negro congeladas"},
    {prodId:"50342703",label:"Arandano gryclesik congeladas"},
    {prodId:"50342704",label:"Arandano  howe congeladas"},
    {prodId:"50342705",label:"Bayas lingon congeladas"},
    {prodId:"50342706",label:"Arandano mcfarling congeladas"},
    {prodId:"50342707",label:"Arandano mountain congeladas"},
    {prodId:"50342708",label:"Arandano pilgrim congeladas"},
    {prodId:"50342709",label:"Arandano searless congeladas"},
    {prodId:"50342710",label:"Arandano stevens congeladas"},
    {prodId:"50342800",label:"pasas de Corinto congeladas"},
    {prodId:"50342801",label:"Pasa hudson bay congeladas"},
    {prodId:"50342802",label:"Pasa waxy congeladas"},
    {prodId:"50342803",label:"Pasa desierta congeladas"},
    {prodId:"50342804",label:"Pasa negra congeladas"},
    {prodId:"50342805",label:"Pasa roja congeladas"},
    {prodId:"50342806",label:"Pasa blanca congeladas"},
    {prodId:"50342900",label:"Dátiles congelados"},
    {prodId:"50342901",label:"Dátiles asharasi  congeladas"},
    {prodId:"50342902",label:"Dátiles barhi o barhee congeladas"},
    {prodId:"50342903",label:"Dátiles deglet noor congeladas"},
    {prodId:"50342904",label:"Dátiles fardh congeladas"},
    {prodId:"50342905",label:"Dátiles gundila congeladas"},
    {prodId:"50342906",label:"Dátiles halawi/halawy congeladas"},
    {prodId:"50342907",label:"Dátiles hilali congeladas"},
    {prodId:"50342908",label:"Dátiles khadrawi/khadrawy congeladas"},
    {prodId:"50342909",label:"Dátiles khalas congeladas"},
    {prodId:"50342910",label:"Dátiles khustawi congeladas"},
    {prodId:"50342911",label:"Dátiles khidri congeladas"},
    {prodId:"50342912",label:"Dátiles medjool/ medjol congeladas"},
    {prodId:"50342913",label:"Dátiles mactoum congeladas"},
    {prodId:"50342914",label:"Dátiles neghal congeladas"},
    {prodId:"50342915",label:"Dátiles yatimeh congeladas"},
    {prodId:"50342916",label:"Dátiles zahidi congeladas"},
    {prodId:"50343000",label:"Pitahayas congeladas"},
    {prodId:"50343001",label:"Pitahaya roja, rosada congeladas"},
    {prodId:"50343002",label:"Pitahaya roja, amarillo congeladas"},
    {prodId:"50343100",label:"Brevas congeladas"},
    {prodId:"50343101",label:"Breva bardajic congeladas"},
    {prodId:"50343102",label:"Breva brown turkey congeladas"},
    {prodId:"50343103",label:"Breva calimyma  congeladas"},
    {prodId:"50343104",label:"Breva conadria congeladas"},
    {prodId:"50343105",label:"Breva dotado congeladas"},
    {prodId:"50343106",label:"Breva kadota congeladas"},
    {prodId:"50343107",label:"Breva mediterránea congeladas"},
    {prodId:"50343108",label:"Breva misión congeladas"},
    {prodId:"50343109",label:"Breva amyma congeladas"},
    {prodId:"50343110",label:"Breva verdona congeladas"},
    {prodId:"50343111",label:"Breva rey blanco congeladas"},
    {prodId:"50343200",label:"Grosellas congeladas"},
    {prodId:"50343201",label:"Grosella early sulphur congeladas"},
    {prodId:"50343202",label:"Grosella doradodrop congeladas"},
    {prodId:"50343203",label:"Grosella langley grace congeladas"},
    {prodId:"50343204",label:"Grocella leveller congeladas"},
    {prodId:"50343205",label:"Grosella london congeladas"},
    {prodId:"50343206",label:"Grosella worcestershire congeladas"},
    {prodId:"50343207",label:"Grosella americana worcesterberry congelada"},
    {prodId:"50343300",label:"Toronjas congeladas"},
    {prodId:"50343301",label:"Toronja burgundi congeladas"},
    {prodId:"50343302",label:"Toronja duncan congeladas"},
    {prodId:"50343303",label:"Toronja foster congelada"},
    {prodId:"50343304",label:"Toronja marsh congeladas"},
    {prodId:"50343305",label:"Toronja nueva zelandia congeladas"},
    {prodId:"50343306",label:"Toronja rio rojo congeladas"},
    {prodId:"50343307",label:"Toronja rojo rubí congeladas"},
    {prodId:"50343308",label:"Toronja estrella rubí congeladas"},
    {prodId:"50343309",label:"Toronja triumph congeladas"},
    {prodId:"50343400",label:"Uvas de mesa congeladas"},
    {prodId:"50343401",label:"Uva alicante congeladas"},
    {prodId:"50343402",label:"Uva almeira congeladas"},
    {prodId:"50343403",label:"Uva alphonse lavalle congeladas"},
    {prodId:"50343404",label:"Uva autumn rey grapes congeladas"},
    {prodId:"50343405",label:"Uva autumn royal congeladas"},
    {prodId:"50343406",label:"Uva autum sin semilla congeladas"},
    {prodId:"50343407",label:"Uva baresana congeladas"},
    {prodId:"50343408",label:"Uva barlinka congeladas"},
    {prodId:"50343409",label:"Uva beauty sin semilla congeladas"},
    {prodId:"50343410",label:"Uva negro beauty sin semilla congeladas"},
    {prodId:"50343411",label:"Uva negro emerald congeladas"},
    {prodId:"50343412",label:"Uva negro giant congeladas"},
    {prodId:"50343413",label:"Uva negro globe congeladas"},
    {prodId:"50343414",label:"Uva negro monnukka congeladas"},
    {prodId:"50343415",label:"Uva negro pearl congeladas"},
    {prodId:"50343416",label:"Uva negro sin pepa congeladas"},
    {prodId:"50343417",label:"Uva bonheur congeladas"},
    {prodId:"50343418",label:"Uva calmeria congeladas"},
    {prodId:"50343419",label:"Uva cardinal congeladas"},
    {prodId:"50343420",label:"Uva catawba congeladas"},
    {prodId:"50343421",label:"Uva chasselas/dorado chasselas congeladas"},
    {prodId:"50343422",label:"Uva christmas rose congeladas"},
    {prodId:"50343423",label:"Uva concord congeladas"},
    {prodId:"50343424",label:"Uva concord sin semilla congeladas"},
    {prodId:"50343425",label:"Uva crimson sin semilla congeladas"},
    {prodId:"50343426",label:"Uva dauphine  congeladas"},
    {prodId:"50343427",label:"Uva daleware congeladas"},
    {prodId:"50343428",label:"Uva early muscat congeladas"},
    {prodId:"50343429",label:"Uva early sweet congeladas"},
    {prodId:"50343430",label:"Uva esmerald sin semilla congeladas"},
    {prodId:"50343431",label:"Uva emperatriz congeladas"},
    {prodId:"50343432",label:"Uva emperor congeladas"},
    {prodId:"50343433",label:"Uva empress congeladas"},
    {prodId:"50343434",label:"Uva exótica congeladas"},
    {prodId:"50343435",label:"Uva fantasía congeladas"},
    {prodId:"50343436",label:"Uva fantasia sin semilla congeladas"},
    {prodId:"50343437",label:"Uva llama congeladas"},
    {prodId:"50343438",label:"Uva llama sin pepa congeladas"},
    {prodId:"50343439",label:"Uva llama tokay congeladas"},
    {prodId:"50343440",label:"Uva ardiente rojo congeladas"},
    {prodId:"50343441",label:"Uva galaxy sin semilla congeladas"},
    {prodId:"50343442",label:"Uva gamay congeladas"},
    {prodId:"50343443",label:"Uva dorada congeladas"},
    {prodId:"50343444",label:"Uva hanepot o honeypot congeladas"},
    {prodId:"50343445",label:"Uva italia congeladas"},
    {prodId:"50343446",label:"Uva jade sin semilla congeladas"},
    {prodId:"50343447",label:"Uva jubileo congeladas"},
    {prodId:"50343448",label:"Uva rey ruby congeladas"},
    {prodId:"50343449",label:"Uva kyoho congeladas"},
    {prodId:"50343450",label:"Uva la rochelle congeladas"},
    {prodId:"50343451",label:"Uva dedo de dama congeladas"},
    {prodId:"50343452",label:"Uva late sin semilla congeladas"},
    {prodId:"50343453",label:"Uva majestosa sin semilla congeladas"},
    {prodId:"50343454",label:"Uva malaga congeladas"},
    {prodId:"50343455",label:"Uva marro sin pepa congeladas"},
    {prodId:"50343456",label:"Uva muscadine congeladas"},
    {prodId:"50343457",label:"Uva muscat llama congeladas"},
    {prodId:"50343458",label:"Uva muscat congeladas"},
    {prodId:"50343459",label:"Uva muscat sin papa congeladas"},
    {prodId:"50343460",label:"Uva napoleon congeladas"},
    {prodId:"50343461",label:"Uva nigeria congeladas"},
    {prodId:"50343462",label:"Uva new cross congeladas"},
    {prodId:"50343463",label:"Uva nibella congeladas"},
    {prodId:"50343464",label:"Uva niagara congeladas"},
    {prodId:"50343465",label:"Uva olivette congeladas"},
    {prodId:"50343466",label:"Uva perlette congeladas"},
    {prodId:"50343467",label:"Uva perlon congeladas"},
    {prodId:"50343468",label:"Uva prima negro sin semilla congeladas"},
    {prodId:"50343469",label:"Uva prncess congeladas"},
    {prodId:"50343470",label:"Uva reina congeladas"},
    {prodId:"50343471",label:"Uva roja blush congeladas"},
    {prodId:"50343472",label:"Uva roja globe congeladas"},
    {prodId:"50343473",label:"Uva roja malaga congeladas"},
    {prodId:"50343474",label:"Uva roja sin pepa congeladas"},
    {prodId:"50343475",label:"Uva regina congeladas"},
    {prodId:"50343476",label:"Uva ribier congeladas"},
    {prodId:"50343477",label:"Uva rosita congeladas"},
    {prodId:"50343478",label:"Uva rouge congeladas"},
    {prodId:"50343479",label:"Uva royal negra sin pepa congeladas"},
    {prodId:"50343480",label:"Uva ruby roja sin pepa congeladas"},
    {prodId:"50343481",label:"Uva ryby sin pepa congeladas"},
    {prodId:"50343482",label:"Uva scarlet royal congeladas"},
    {prodId:"50343483",label:"Uva scuppemong congeladas"},
    {prodId:"50343484",label:"Uva sugarose congeladas"},
    {prodId:"50343485",label:"Uva sugartirteen congeladas"},
    {prodId:"50343486",label:"Uva sugraone congeladas"},
    {prodId:"50343487",label:"Uva sugrasixteen congeladas"},
    {prodId:"50343488",label:"Uva sultana sun roja congeladas"},
    {prodId:"50343489",label:"Uva verano royal congeladas"},
    {prodId:"50343490",label:"Uva atardecer congeladas"},
    {prodId:"50343491",label:"Uva superior sin pepa congeladas"},
    {prodId:"50343492",label:"Uva thompson sin pepa congeladas"},
    {prodId:"50343493",label:"Uva tokaylpinot gris congeladas"},
    {prodId:"50343494",label:"Uva waltman cross congeladas"},
    {prodId:"50343495",label:"Uva blanca sin semilla congeladas"},
    {prodId:"50343496",label:"Uva zante current congeladas"},
    {prodId:"50343500",label:"Uvas pasas congeladas"},
    {prodId:"50343501",label:"Uva corinth negra congeladas"},
    {prodId:"50343502",label:"Uva canner congeladas"},
    {prodId:"50343503",label:"Uva dovine congeladas"},
    {prodId:"50343504",label:"Uva fiesta congeladas"},
    {prodId:"50343505",label:"Uva selme pete congeladas"},
    {prodId:"50343506",label:"Uva sultana congeladas"},
    {prodId:"50343600",label:"Uvas para vino congeladas"},
    {prodId:"50343601",label:"Uva uva alicante bouschet congeladas"},
    {prodId:"50343602",label:"Uva barbera congeladas"},
    {prodId:"50343603",label:"Uva burger congeladas"},
    {prodId:"50343604",label:"Uva cabemet franc congeladas"},
    {prodId:"50343605",label:"Uva cabenet sauvignon congeladas"},
    {prodId:"50343606",label:"Uva carignane congeladas"},
    {prodId:"50343607",label:"Uva camelian congeladas"},
    {prodId:"50343608",label:"Uva cattarrato congeladas"},
    {prodId:"50343609",label:"Uva centurian congeladas"},
    {prodId:"50343610",label:"Uva charbono congeladas"},
    {prodId:"50343611",label:"Uva chardonnay congeladas"},
    {prodId:"50343612",label:"Uva chenin blanco congeladas"},
    {prodId:"50343613",label:"Uva cinsaut congeladas"},
    {prodId:"50343614",label:"Uva dolcetto congeladas"},
    {prodId:"50343615",label:"Uva emerald riesling congeladas"},
    {prodId:"50343616",label:"Uva french colombard congeladas"},
    {prodId:"50343617",label:"Uva granny nap congeladas"},
    {prodId:"50343618",label:"Uva gamay beaujolais congeladas"},
    {prodId:"50343619",label:"Uva gewurztraminer congeladas"},
    {prodId:"50343620",label:"Uva grnache congeladas"},
    {prodId:"50343621",label:"Uva grinache blanc congeladas"},
    {prodId:"50343622",label:"Uva lagrein congeladas"},
    {prodId:"50343623",label:"Uva lambruso congeladas"},
    {prodId:"50343624",label:"Uva malbec congeladas"},
    {prodId:"50343625",label:"Uva malvasía bianca congeladas"},
    {prodId:"50343626",label:"Uva marsanne congeladas"},
    {prodId:"50343627",label:"Uva matano congeladas"},
    {prodId:"50343628",label:"Uva merlot congeladas"},
    {prodId:"50343629",label:"Uva meunier congeladas"},
    {prodId:"50343630",label:"Uva missiom congeladas"},
    {prodId:"50343631",label:"Uva montepulceano congeladas"},
    {prodId:"50343632",label:"Uva muscat blanc congeladas"},
    {prodId:"50343633",label:"Uva muscat hamburg congeladas"},
    {prodId:"50343634",label:"Uva muscat alexandria congeladas"},
    {prodId:"50343635",label:"Uva muscat naranja congeladas"},
    {prodId:"50343636",label:"Uva nebbiolo congeladas"},
    {prodId:"50343637",label:"Uva palomino congeladas"},
    {prodId:"50343638",label:"Uva petit verdor congeladas"},
    {prodId:"50343639",label:"Uva petit sirah congeladas"},
    {prodId:"50343640",label:"Uva pinot blanc congeladas"},
    {prodId:"50343641",label:"Uva pinot gris congeladas"},
    {prodId:"50343642",label:"Uva pinot noir congeladas"},
    {prodId:"50343643",label:"Uva primitivo congeladas"},
    {prodId:"50343644",label:"Uva roussane congeladas"},
    {prodId:"50343645",label:"Uva real  congeladas"},
    {prodId:"50343646",label:"Uva rubirojo congeladas"},
    {prodId:"50343647",label:"Uva ruby cabenet congeladas"},
    {prodId:"50343648",label:"Uva salvador congeladas"},
    {prodId:"50343649",label:"Uva sangiovese congeladas"},
    {prodId:"50343650",label:"Uva sauvignon blanc congeladas"},
    {prodId:"50343651",label:"Uva sauvignon musque congeladas"},
    {prodId:"50343652",label:"Uva semillon congeladas"},
    {prodId:"50343653",label:"Uva souzao congeladas"},
    {prodId:"50343654",label:"Uva st emilion congeladas"},
    {prodId:"50343655",label:"Uva symohony congeladas"},
    {prodId:"50343656",label:"Uva syrah  congeladas"},
    {prodId:"50343657",label:"Uva tannat congeladas"},
    {prodId:"50343658",label:"Uva temoranillo congeladas"},
    {prodId:"50343659",label:"Uvas teroldego congeladas"},
    {prodId:"50343660",label:"Uvas tocai friulano congeladas"},
    {prodId:"50343661",label:"Uvas touriga nacional congeladas"},
    {prodId:"50343662",label:"Uvas triplett blanc congeladas"},
    {prodId:"50343663",label:"Uvas viogner congeladas"},
    {prodId:"50343664",label:"Uvas blanco riesling congeladas"},
    {prodId:"50343665",label:"Uvas zinfandel congeladas"},
    {prodId:"50343900",label:"Kiwis congelados"},
    {prodId:"50343901",label:"Fruta de kiwi ananasnaja congeladas"},
    {prodId:"50343902",label:"Fruta de kiwi belleza del ártico congeladas"},
    {prodId:"50343903",label:"Fruta de kiwi blake congeladas"},
    {prodId:"50343904",label:"Fruta de kiwi hayward congeladas"},
    {prodId:"50343905",label:"Fruta de kiwi issai congeladas"},
    {prodId:"50343906",label:"Fruta de kiwi siberiana congeladas"},
    {prodId:"50344000",label:"Naranjos Chinos (kumquats) congelados"},
    {prodId:"50344001",label:"Naranjo chino hong kong congelado"},
    {prodId:"50344002",label:"Naranjo chino limequat congelado"},
    {prodId:"50344003",label:"Naranjo long fruit congelado"},
    {prodId:"50344004",label:"Naranjo chino malayan congelado"},
    {prodId:"50344005",label:"Naranjo meiwa congelado"},
    {prodId:"50344006",label:"Naranja chino nagami congelado"},
    {prodId:"50344100",label:"Limones congelados"},
    {prodId:"50344101",label:"Limón baboon congelado"},
    {prodId:"50344102",label:"Limón bearss sicilian congelado"},
    {prodId:"50344103",label:"Limón cameron highland congelado"},
    {prodId:"50344104",label:"Limón escondido congelado"},
    {prodId:"50344105",label:"Limón eureka congelado"},
    {prodId:"50344106",label:"Limón lisbon congelado"},
    {prodId:"50344107",label:"Limón meyer congelado"},
    {prodId:"50344108",label:"Limón volkaner congelado"},
    {prodId:"50344200",label:"Limas congeladas"},
    {prodId:"50344201",label:"Lima limón indian sweet congelado"},
    {prodId:"50344202",label:"Lima limón congelado"},
    {prodId:"50344203",label:"Lima limón mandarin congelado"},
    {prodId:"50344204",label:"Lima limón philippine congelado"},
    {prodId:"50344205",label:"Lima limón tahitian congelado"},
    {prodId:"50344206",label:"Lima limón bearss congelado"},
    {prodId:"50344207",label:"Lima limón persa congelado"},
    {prodId:"50344208",label:"Lima limón sin pepas congelado"},
    {prodId:"50344300",label:"Nísperos congelados"},
    {prodId:"50344301",label:"Níspero advance congelado"},
    {prodId:"50344302",label:"Níspero benlehr congelado"},
    {prodId:"50344303",label:"Níspero big jim congelado"},
    {prodId:"50344304",label:"Níspero champagne congelado"},
    {prodId:"50344305",label:"Níspero early rojo congelado"},
    {prodId:"50344306",label:"Níspero nuget dorado congelado"},
    {prodId:"50344307",label:"Níspero herd's mammoth congelado"},
    {prodId:"50344308",label:"Níspero mogi congelado"},
    {prodId:"50344309",label:"Níspero mrs cooksey congelado"},
    {prodId:"50344310",label:"Níspero fresa congelado"},
    {prodId:"50344311",label:"Níspero tanaka congelado"},
    {prodId:"50344312",label:"Níspero victoria vista blanco congelado"},
    {prodId:"50344313",label:"Níspero wolfe congelado"},
    {prodId:"50344400",label:"Mandarinas congeladas"},
    {prodId:"50344401",label:"Naranjas clauselinas congeladas"},
    {prodId:"50344402",label:"Mandarinas clementinas congeladas"},
    {prodId:"50344403",label:"Naranja mandarina cleopatra"},
    {prodId:"50344404",label:"Mandarina dancy congeladas"},
    {prodId:"50344405",label:"Naranja ellensday congeladas"},
    {prodId:"50344406",label:"Naranja fairchild congeladas"},
    {prodId:"50344407",label:"Naranja fallglo congeladas"},
    {prodId:"50344408",label:"Naranja fortuna congeladas"},
    {prodId:"50344409",label:"Mandarina naranja fremont congeladas"},
    {prodId:"50344410",label:"Naranja fremont congeladas"},
    {prodId:"50344411",label:"Naranja nuget dorada congeladas"},
    {prodId:"50344412",label:"Mandarina naranja miel congeladas"},
    {prodId:"50344413",label:"Naranja miel congeladas"},
    {prodId:"50344414",label:"Mandarina miel congeladas"},
    {prodId:"50344415",label:"Naranja tangelo honeybelle congeladas"},
    {prodId:"50344416",label:"Naranja mandarina rey congeladas"},
    {prodId:"50344417",label:"Naranja kinnow congeladas"},
    {prodId:"50344418",label:"Naranja andarinalee congeladas"},
    {prodId:"50344419",label:"Naranja makokkee congeladas"},
    {prodId:"50344420",label:"Naranja malvasion congeladas"},
    {prodId:"50344421",label:"Naranja mandarina mediterránea congeladas"},
    {prodId:"50344422",label:"Naranja tangelo minneola congeladas"},
    {prodId:"50344423",label:"Naranja monica congeladas"},
    {prodId:"50344424",label:"Naranja murcott miel congeladas"},
    {prodId:"50344425",label:"Naranja murcott tangors congeladas"},
    {prodId:"50344426",label:"Naranja mandarina natsudaidai  congeladas"},
    {prodId:"50344427",label:"Naranja mandarina natsumikan congeladas"},
    {prodId:"50344428",label:"Naranja tanjelo nocatee congeladas"},
    {prodId:"50344429",label:"Naranja tanjelo orlando congeladas"},
    {prodId:"50344430",label:"Mandarina ortanique congeladas"},
    {prodId:"50344431",label:"Naranja mandarina pagina congeladas"},
    {prodId:"50344432",label:"Naranja pixie congeladas"},
    {prodId:"50344433",label:"Naranja mandarina ponkan bantangas congeladas"},
    {prodId:"50344434",label:"Naranja reina congeladas"},
    {prodId:"50344435",label:"Naranja robinson congeladas"},
    {prodId:"50344436",label:"Naranja saltenitas congeladas"},
    {prodId:"50344437",label:"Naranja tangelo sampson congeladas"},
    {prodId:"50344438",label:"Naranja mandarina satsuma congeladas"},
    {prodId:"50344439",label:"Naranja mandarina sunburst congeladas"},
    {prodId:"50344440",label:"Tangelo congelado"},
    {prodId:"50344441",label:"Naranja mandarina congeladas"},
    {prodId:"50344442",label:"Naranja templo congeladas"},
    {prodId:"50344443",label:"Naranja thornton congeladas"},
    {prodId:"50344444",label:"Mandarina wekiwa congeladas"},
    {prodId:"50344445",label:"Mandarina wilkins congeladas"},
    {prodId:"50344446",label:"Mandarina willowleaf mediterránea congeladas"},
    {prodId:"50344800",label:"Mirtos congelados"},
    {prodId:"50344801",label:"Mirto bog congelado"},
    {prodId:"50344900",label:"Nectarinas congelados"},
    {prodId:"50344901",label:"Durazno april glo congelado"},
    {prodId:"50344902",label:"Durazno arctic mist congelado"},
    {prodId:"50344903",label:"Durazno artic snow congelado"},
    {prodId:"50344904",label:"Durazno artic star congelado"},
    {prodId:"50344905",label:"Durazno artic dulce congelado"},
    {prodId:"50344906",label:"Durazno artic glo congelado"},
    {prodId:"50344907",label:"Durazno agosto fire congelado"},
    {prodId:"50344908",label:"Durazno  perla de agosto congelado"},
    {prodId:"50344909",label:"Durazno agosto rojo congelado"},
    {prodId:"50344910",label:"Durazno estrella de agosto congelado"},
    {prodId:"50344911",label:"Durazno big john congelado"},
    {prodId:"50344912",label:"Durazno perla brillante congelado"},
    {prodId:"50344913",label:"Durazno diamante brillante congelado"},
    {prodId:"50344914",label:"Durazno diamante ray congelado"},
    {prodId:"50344915",label:"Durazno earligo congelado"},
    {prodId:"50344916",label:"Durazno diamante temprano congelado"},
    {prodId:"50344917",label:"Durazno fairlane congelado"},
    {prodId:"50344918",label:"Durazno fantasia congelado"},
    {prodId:"50344919",label:"Durazno perla fuego congelado"},
    {prodId:"50344920",label:"Durazno fuego dulce congelado"},
    {prodId:"50344921",label:"Durazno llamakist congelado"},
    {prodId:"50344922",label:"Durazno tipo plano congelado"},
    {prodId:"50344923",label:"Durazno delicia de jardín congelado"},
    {prodId:"50344924",label:"Durazno mina de oro congelado"},
    {prodId:"50344925",label:"Durazno perla grande congelado"},
    {prodId:"50344926",label:"Durazno hardirojo congelado"},
    {prodId:"50344927",label:"Durazno miel de fuego congelado"},
    {prodId:"50344928",label:"Durazno julio rojo congelado"},
    {prodId:"50344929",label:"Durazno kay perla congelado"},
    {prodId:"50344930",label:"Durazno key dulce congelado"},
    {prodId:"50344931",label:"Durazno diamante mayo congelado"},
    {prodId:"50344932",label:"Durazno mayfire congelado"},
    {prodId:"50344933",label:"Durazno mayglo congelado"},
    {prodId:"50344934",label:"Durazno mericrest congelado"},
    {prodId:"50344935",label:"Durazno diamante rojo congelado"},
    {prodId:"50344936",label:"Durazno oro rojo congelado"},
    {prodId:"50344937",label:"Durazno jim rojo congelado"},
    {prodId:"50344938",label:"Durazno roy rojo congelado"},
    {prodId:"50344939",label:"Durazno rio rojo congelado"},
    {prodId:"50344940",label:"Durazno diamante de rosa congelado"},
    {prodId:"50344941",label:"Durazno rotal glo congelado"},
    {prodId:"50344942",label:"Durazno diamante ryby congelado"},
    {prodId:"50344943",label:"Durazno ruby dulce congelado"},
    {prodId:"50344944",label:"Durazno joya ruddy congelado"},
    {prodId:"50344945",label:"Durazno septiembre rojo congelado"},
    {prodId:"50344946",label:"Durazno reina de nieve congelado"},
    {prodId:"50344947",label:"Durazno primavera clara congelado"},
    {prodId:"50344948",label:"Durazno primavera roja congelado"},
    {prodId:"50344949",label:"Durazno verano rojiso congelado"},
    {prodId:"50344950",label:"Durazno verano claro congelado"},
    {prodId:"50344951",label:"Durazno verano diamante congelado"},
    {prodId:"50344952",label:"Durazno verano fuego congelado"},
    {prodId:"50344953",label:"Durazno verano grande congelado"},
    {prodId:"50344954",label:"Durazno sunglo congelado"},
    {prodId:"50344955",label:"Durazno fuego zee congelado"},
    {prodId:"50344956",label:"Durazno zee glo congelado"},
    {prodId:"50344957",label:"Durazno zeegrand congelado"},
    {prodId:"50345000",label:"Naranjas congeladas"},
    {prodId:"50345001",label:"Naranja áfrica agria congeladas"},
    {prodId:"50345002",label:"Naranja dulce amber congeladas"},
    {prodId:"50345003",label:"Naranja argentina agria congeladas"},
    {prodId:"50345004",label:"Naranja bahianinha congelado"},
    {prodId:"50345005",label:"Naranja bergamot congeladas"},
    {prodId:"50345006",label:"Naranja berna congeladas"},
    {prodId:"50345007",label:"Naranja bigaradier apepu congeladas"},
    {prodId:"50345008",label:"Naranja agria dulce daidai congeladas"},
    {prodId:"50345009",label:"Naranja mono congeladas"},
    {prodId:"50345010",label:"Naranja sangre congeladas"},
    {prodId:"50345011",label:"Naranja california navel congeladas"},
    {prodId:"50345012",label:"Naranja cara cara congeladas"},
    {prodId:"50345013",label:"Naranja chinotto congeladas"},
    {prodId:"50345014",label:"Naranja sueño nevel congeladas"},
    {prodId:"50345015",label:"Naranja gou tou congeladas"},
    {prodId:"50345016",label:"Naranja hamlin congeladas"},
    {prodId:"50345017",label:"Naranja jaffa congeladas"},
    {prodId:"50345018",label:"Naranja jincheng congeladas"},
    {prodId:"50345019",label:"Naranja k-temprano congeladas"},
    {prodId:"50345020",label:"Naranja kona congeladas"},
    {prodId:"50345021",label:"Naranja navel tarde congeladas congeladas"},
    {prodId:"50345022",label:"Naranja valencia tarde congeladas"},
    {prodId:"50345023",label:"Naranja limequat congeladas"},
    {prodId:"50345024",label:"Naranja mar congeladas"},
    {prodId:"50345025",label:"Naranja malegold congeladas"},
    {prodId:"50345026",label:"Naranja moro congeladas"},
    {prodId:"50345027",label:"Naranja moro tabaco congeladas"},
    {prodId:"50345028",label:"Naranja navel congeladas"},
    {prodId:"50345029",label:"Naranja navelina congeladas"},
    {prodId:"50345030",label:"Naranja oro blanco congeladas"},
    {prodId:"50345031",label:"Naranja osceola congeladas"},
    {prodId:"50345032",label:"Naranja parson carmelito congeladas"},
    {prodId:"50345033",label:"Naranja pera congeladas"},
    {prodId:"50345034",label:"Naranja pummulo congeladas"},
    {prodId:"50345035",label:"Naranja rhode rojo congeladas"},
    {prodId:"50345036",label:"Naranja roble congeladas"},
    {prodId:"50345037",label:"Naranja salustianas congeladas"},
    {prodId:"50345038",label:"Naranja sanguine congeladas"},
    {prodId:"50345039",label:"Naranja sanguinelli congeladas"},
    {prodId:"50345040",label:"Naranja sevilla congeladas"},
    {prodId:"50345041",label:"Naranja shamouti jaffa congeladas"},
    {prodId:"50345042",label:"Naranja tunis congeladas"},
    {prodId:"50345043",label:"Naranja valencia congeladas"},
    {prodId:"50345044",label:"Naranja washington navel congeladas"},
    {prodId:"50345200",label:"Maracuyás congeladas"},
    {prodId:"50345201",label:"Maracuyá banana congeladas"},
    {prodId:"50345202",label:"Maracuyá flor azul congeladas"},
    {prodId:"50345203",label:"Maracuyá crackerjack congeladas"},
    {prodId:"50345204",label:"Maracuyá granadilla gigante congeladas"},
    {prodId:"50345205",label:"Maracuyá granadilla dorada congeladas"},
    {prodId:"50345206",label:"Maracuyá maypops congeladas"},
    {prodId:"50345207",label:"Maracuyá roja congeladas"},
    {prodId:"50345208",label:"Maracuyá granadilla dulce congeladas"},
    {prodId:"50345209",label:"Maracuyá sandia congeladas"},
    {prodId:"50345210",label:"Maracuyá  wing- steam congeladas"},
    {prodId:"50345400",label:"Peras congeladas"},
    {prodId:"50345401",label:"Peras abate fetel congeladas"},
    {prodId:"50345402",label:"Peras anjou congeladas"},
    {prodId:"50345403",label:"Pera asiática congeladas"},
    {prodId:"50345404",label:"Pera bartlett congeladas"},
    {prodId:"50345405",label:"Pera best ever congeladas"},
    {prodId:"50345406",label:"Pera beth congeladas"},
    {prodId:"50345407",label:"Pera beurre congeladas"},
    {prodId:"50345408",label:"Pera bosc congeladas"},
    {prodId:"50345409",label:"Pera clapp favorita congeladas"},
    {prodId:"50345410",label:"Pera comice congeladas"},
    {prodId:"50345411",label:"Pera concorde congeladas"},
    {prodId:"50345412",label:"Pera conference congeladas"},
    {prodId:"50345413",label:"Pera crimson rojo congeladas"},
    {prodId:"50345414",label:"Peras d' anjou congeladas"},
    {prodId:"50345415",label:"Pera dr jules guyot congeladas"},
    {prodId:"50345416",label:"Peras early congeladas"},
    {prodId:"50345417",label:"Peras emperador carmelito congeladas"},
    {prodId:"50345418",label:"Peras forelle congeladas"},
    {prodId:"50345419",label:"Pera mantequilla francesa congeladas"},
    {prodId:"50345420",label:"Pera glou morceau congeladas"},
    {prodId:"50345421",label:"Pera hosui congeladas"},
    {prodId:"50345422",label:"Pera mantequilla italiana congeladas"},
    {prodId:"50345423",label:"Pera jargonelle congeladas"},
    {prodId:"50345424",label:"Pera juno congeladas"},
    {prodId:"50345425",label:"Para kaiserlouise bonne de jersey congeladas"},
    {prodId:"50345426",label:"Pera keiffer congeladas"},
    {prodId:"50345427",label:"Pera rey royal congeladas"},
    {prodId:"50345428",label:"Pera limonera congeladas"},
    {prodId:"50345429",label:"Pera merton pride congeladas"},
    {prodId:"50345430",label:"Pera mountain bartlette congeladas"},
    {prodId:"50345431",label:"Pera oliver de serrers congeladas"},
    {prodId:"50345432",label:"Pera onward congeladas"},
    {prodId:"50345433",label:"Pera packham's triumph congeladas"},
    {prodId:"50345434",label:"Pera paraíso congeladas"},
    {prodId:"50345435",label:"Pera passe crassane congeladas"},
    {prodId:"50345436",label:"Pera perry congeladas"},
    {prodId:"50345437",label:"Pera bartlette rojo congeladas"},
    {prodId:"50345438",label:"Pera dánjou  rojo congeladas"},
    {prodId:"50345439",label:"Pera rocha congeladas"},
    {prodId:"50345440",label:"Pera rosey rojo congeladas"},
    {prodId:"50345441",label:"Pera rosy roja congeladas"},
    {prodId:"50345442",label:"Pera majestuosa royal congeladas"},
    {prodId:"50345443",label:"Pera ruby rojo congeladas"},
    {prodId:"50345444",label:"Pera santa maria congeladas"},
    {prodId:"50345445",label:"Pera seckel congeladas"},
    {prodId:"50345446",label:"Pera sensación congeladas"},
    {prodId:"50345447",label:"Pera crimson estrella congeladas"},
    {prodId:"50345448",label:"Pera crimson stark congeladas"},
    {prodId:"50345449",label:"Pera bartlette de verano congeladas"},
    {prodId:"50345450",label:"Pera verano dorado congeladas"},
    {prodId:"50345451",label:"Pera sol dorado congeladas"},
    {prodId:"50345452",label:"Pera sunprite congeladas"},
    {prodId:"50345453",label:"Pera taylors dorado congeladas"},
    {prodId:"50345454",label:"Pera taylors rojo congeladas"},
    {prodId:"50345455",label:"Pera tientsin congeladas"},
    {prodId:"50345456",label:"Pera tosca congeladas"},
    {prodId:"50345457",label:"Pera warden  congeladas"},
    {prodId:"50345458",label:"Pera williams bon chretien congeladas"},
    {prodId:"50345459",label:"Pera williams congeladas"},
    {prodId:"50345460",label:"Pera nelis de invierno congeladas"},
    {prodId:"50345500",label:"Caquis congelados"},
    {prodId:"50345501",label:"Caqui americano congeladas"},
    {prodId:"50345502",label:"Caqui sapote negro congeladas"},
    {prodId:"50345503",label:"Caqui chapote / negro congeladas"},
    {prodId:"50345504",label:"Caqui dátale ciruela congeladas"},
    {prodId:"50345505",label:"Caqui fuyu congeladas"},
    {prodId:"50345506",label:"Caqui gigante fuyu congeladas"},
    {prodId:"50345507",label:"Caqui hachiya congeladas"},
    {prodId:"50345508",label:"Caqui mantequilla / mabolo congeladas"},
    {prodId:"50345509",label:"Caqui príncipe ito congeladas"},
    {prodId:"50345510",label:"Caqui brillante royal congeladas"},
    {prodId:"50345511",label:"Caqui sharon congeladas"},
    {prodId:"50345512",label:"Caqui triumph congeladas"},
    {prodId:"50345700",label:"Ciruelos Damascos congelados"},
    {prodId:"50345701",label:"Ciruelo / damasco negro kat congelado"},
    {prodId:"50345702",label:"Ciruelo / damasco gusto azul congelado"},
    {prodId:"50345703",label:"Ciruelo / damasco corazón de crimson congelado"},
    {prodId:"50345704",label:"Ciruelo / damasco dapply dandy congelado"},
    {prodId:"50345705",label:"Ciruelo / damasco dapple fuego congelado"},
    {prodId:"50345706",label:"Ciruelo / damasco  dapple temprano congelado"},
    {prodId:"50345707",label:"Ciruelo / damasco  caída de sabor congelado"},
    {prodId:"50345708",label:"Ciruelo / damasco sabor de oro congelado"},
    {prodId:"50345709",label:"Ciruelo / damasco sabor granada congelado"},
    {prodId:"50345710",label:"Ciruelo / damasco sabor de corazón congelado"},
    {prodId:"50345711",label:"Ciruelo / damasco joya de sabor congelado"},
    {prodId:"50345712",label:"Ciruelo / damasco sabor de rey congelado"},
    {prodId:"50345713",label:"Ciruelo / damasco sabor de reina congelado"},
    {prodId:"50345714",label:"Ciruelo / damasco sabor supremo congelado"},
    {prodId:"50345715",label:"Ciruelo / damasco sabor premio congelado"},
    {prodId:"50345716",label:"Ciruelo / damasco saborella congelado"},
    {prodId:"50345717",label:"Ciruelo / damasco saborrico congelado"},
    {prodId:"50345718",label:"Ciruelo / damasco  sabor rosa congelado"},
    {prodId:"50345719",label:"Ciruelo / damasco orgullo geo congelado"},
    {prodId:"50345720",label:"Ciruelo / damasco kat rojo congelado"},
    {prodId:"50345721",label:"Ciruelo / damasco premio royal congelado"},
    {prodId:"50345722",label:"Ciruelo / damasco rosa sierra congelado"},
    {prodId:"50345723",label:"Ciruelo / damasco geisha dulce congelado"},
    {prodId:"50345800",label:"Ciruelas congeladas"},
    {prodId:"50345801",label:"Ciruela joya amber congeladas"},
    {prodId:"50345802",label:"Ciruela angeleno congeladas"},
    {prodId:"50345803",label:"Ciruela aurora congeladas"},
    {prodId:"50345804",label:"Ciruela otoño bonito congeladas"},
    {prodId:"50345805",label:"Ciruela otoño gigante congeladas"},
    {prodId:"50345806",label:"Ciruela orgullo de otoño congeladas"},
    {prodId:"50345807",label:"Ciruela rosa de otoño congeladas"},
    {prodId:"50345808",label:"Ciruela playero congeladas"},
    {prodId:"50345809",label:"Ciruela betty anne congeladas"},
    {prodId:"50345810",label:"Ciruela belleza negra congeladas"},
    {prodId:"50345811",label:"Ciruela bullase negra congeladas"},
    {prodId:"50345812",label:"Ciruela diamante negro congeladas"},
    {prodId:"50345813",label:"Ciruela gigante negro congeladas"},
    {prodId:"50345814",label:"Ciruela hielo negro congeladas"},
    {prodId:"50345815",label:"Ciruela esplendor negro congeladas"},
    {prodId:"50345816",label:"Ciruela ámbar negro congeladas"},
    {prodId:"50345817",label:"Ciruela purpura congeladas"},
    {prodId:"50345818",label:"Ciruela  carlsbad congeladas"},
    {prodId:"50345819",label:"Ciruela casselman congeladas"},
    {prodId:"50345820",label:"Ciruela catalina congeladas"},
    {prodId:"50345821",label:"Ciruela damson congeladas"},
    {prodId:"50345822",label:"Ciruela dolly congeladas"},
    {prodId:"50345823",label:"Ciruela earliqueen congeladas"},
    {prodId:"50345824",label:"Ciruela rosa early congeladas"},
    {prodId:"50345825",label:"Ciruela ébano mayo congeladas"},
    {prodId:"50345826",label:"Ciruela ébano congeladas"},
    {prodId:"50345827",label:"Ciruela corazón de elefante congeladas"},
    {prodId:"50345828",label:"Ciruela belleza de esmeralda congeladas"},
    {prodId:"50345829",label:"Ciruela emperatriz congeladas"},
    {prodId:"50345830",label:"Ciruela libertad congeladas"},
    {prodId:"50345831",label:"Ciruela friar congeladas"},
    {prodId:"50345832",label:"Ciruela rojo gar congeladas"},
    {prodId:"50345833",label:"Ciruela gobernador congeladas"},
    {prodId:"50345834",label:"Ciruela rosa grande congeladas"},
    {prodId:"50345835",label:"Ciruela green gage congeladas"},
    {prodId:"50345836",label:"Ciruela greengage congeladas"},
    {prodId:"50345837",label:"Ciruela hiromi congeladas"},
    {prodId:"50345838",label:"Ciruela hiromi rojo congeladas"},
    {prodId:"50345839",label:"Ciruela vacacion congeladas"},
    {prodId:"50345840",label:"Ciruela howard sol congeladas"},
    {prodId:"50345841",label:"Ciruela tipo interspecific congeladas"},
    {prodId:"50345842",label:"Ciruela jamaico congeladas"},
    {prodId:"50345843",label:"Ciruela joanna rojo congeladas"},
    {prodId:"50345844",label:"Ciruela kelsey congeladas"},
    {prodId:"50345845",label:"Ciruela jaime rey congeladas"},
    {prodId:"50345846",label:"Ciruela laroda congeladas"},
    {prodId:"50345847",label:"Ciruela rosa tarde congeladas"},
    {prodId:"50345848",label:"Ciruela rosa linda congeladas"},
    {prodId:"50345849",label:"Ciruela estrella solitaria congeladas"},
    {prodId:"50345850",label:"Ciruela mariposa congeladas"},
    {prodId:"50345851",label:"Ciruela mercado negro congeladas"},
    {prodId:"50345852",label:"Ciruela mercado rojo congeladas"},
    {prodId:"50345853",label:"Ciruela maribel congeladas"},
    {prodId:"50345854",label:"Ciruelas sol de octubre congeladas"},
    {prodId:"50345855",label:"Ciruela owen t congeladas"},
    {prodId:"50345856",label:"Ciruela perdrigon congeladas"},
    {prodId:"50345857",label:"Ciruela placer rosado congeladas"},
    {prodId:"50345858",label:"Ciruela  presidente congeladas"},
    {prodId:"50345859",label:"Ciruela hora prima congeladas"},
    {prodId:"50345860",label:"Ciruela majestad purpura congeladas"},
    {prodId:"50345861",label:"Ciruela reina rosa congeladas"},
    {prodId:"50345862",label:"Ciruela quetsch congeladas"},
    {prodId:"50345863",label:"Ciruela belleza roja congeladas"},
    {prodId:"50345864",label:"Ciruela camino rojo congeladas"},
    {prodId:"50345865",label:"Ciruela ram rojo congeladas"},
    {prodId:"50345866",label:"Ciruela rosa roja congeladas"},
    {prodId:"50345867",label:"Ciruela rojo rico congeladas"},
    {prodId:"50345868",label:"Ciruela romero congeladas"},
    {prodId:"50345869",label:"Ciruela diamante rojo congeladas"},
    {prodId:"50345870",label:"Ciruela rojo royal congeladas"},
    {prodId:"50345871",label:"Ciruela royal zee congeladas"},
    {prodId:"50345872",label:"Ciruela roysum congeladas"},
    {prodId:"50345873",label:"Ciruela santa rosa congeladas"},
    {prodId:"50345874",label:"Ciruela zafiro congeladas"},
    {prodId:"50345875",label:"Ciruela sloe congeladas"},
    {prodId:"50345876",label:"Ciruela sta catherine congeladas"},
    {prodId:"50345877",label:"Ciruela bullase blanco congeladas"},
    {prodId:"50345900",label:"Granadas congeladas"},
    {prodId:"50345901",label:"Granada foothhill congeladas"},
    {prodId:"50345902",label:"Granada, granada congeladas"},
    {prodId:"50345903",label:"Granada rojo feliz congeladas"},
    {prodId:"50345904",label:"Granada nana congeladas"},
    {prodId:"50345905",label:"Granada rojo de pat congeladas"},
    {prodId:"50345906",label:"Granada pinkhan congelado"},
    {prodId:"50345907",label:"Granada terciopelo morado congeladas"},
    {prodId:"50345908",label:"Granada grandioso congeladas"},
    {prodId:"50346000",label:"Pomelos congelados"},
    {prodId:"50346001",label:"Pomelo chandler congelado"},
    {prodId:"50346002",label:"Pomelo hirado butan congelado"},
    {prodId:"50346003",label:"Pomelo liang ping yau congelado"},
    {prodId:"50346004",label:"Pomelo panda wangi congelado"},
    {prodId:"50346005",label:"Pomelo rosado congelado"},
    {prodId:"50346006",label:"Pomelo shaddock rojo congelado"},
    {prodId:"50346007",label:"Pomelo siamese dulce congelado"},
    {prodId:"50346008",label:"Pomelo waingwright congelado"},
    {prodId:"50346100",label:"Membrillos congelados"},
    {prodId:"50346101",label:"Membrillo campeón congelado"},
    {prodId:"50346102",label:"Membrillo piña congelado"},
    {prodId:"50346103",label:"Membrillo smyma congelado"},
    {prodId:"50346300",label:"Ruibarbos congelados"},
    {prodId:"50346301",label:"Ruibarbo crimson rojo congelado"},
    {prodId:"50346302",label:"Ruibarbo champagne temprana congelado"},
    {prodId:"50346303",label:"Ruibarbo glasrey perpetual  congelado"},
    {prodId:"50346304",label:"Ruibarbo sutton congelado"},
    {prodId:"50346305",label:"Ruibarbo timperley temprano congelado"},
    {prodId:"50346306",label:"Ruibarbo valentina congelado"},
    {prodId:"50346307",label:"Ruibarbo victoria congelado"},
    {prodId:"50346308",label:"Ruibarbo zwolle de cemilla congelado"},
    {prodId:"50346309",label:"Ruibarbo macdonald congelado"},
    {prodId:"50346310",label:"Ruibarbo tilden congelado"},
    {prodId:"50346400",label:"Escaramujos congelados"},
    {prodId:"50346401",label:"Escaramujo rosa brier congelado"},
    {prodId:"50346402",label:"Escaramujo rosa elegante congelado"},
    {prodId:"50346403",label:"Escaramujo rosa rugosa congelado"},
    {prodId:"50346404",label:"Escaramujo rosa burnet o scotch congelado"},
    {prodId:"50346500",label:"Sapotes congelados"},
    {prodId:"50346501",label:"Sapote blanco congelado"},
    {prodId:"50346502",label:"Sapote negro congelado"},
    {prodId:"50346600",label:"Bayas saskatoon congeladas"},
    {prodId:"50346601",label:"Baya mielmadera saskatoon congeladas"},
    {prodId:"50346602",label:"Baya northline saskatoon congeladas"},
    {prodId:"50346603",label:"Baya saskatoon ahumado congeladas"},
    {prodId:"50346604",label:"Baya saskatoon thiessen congeladas"},
    {prodId:"50346800",label:"Manzanas dulces congeladas"},
    {prodId:"50346801",label:"Manzana kampong malve dulce congeladas"},
    {prodId:"50346802",label:"Manzana dulce sin semilla congeladas"},
    {prodId:"50346803",label:"Manzana dulce thai lessand congeladas"},
    {prodId:"50346900",label:"Tamarillos congelados"},
    {prodId:"50346901",label:"Tamarindo amberlea dorado congelado"},
    {prodId:"50346902",label:"Tamarindo calvo dorado congelado"},
    {prodId:"50346903",label:"Tamarindo mina de oro congelado"},
    {prodId:"50346904",label:"Tamarindo oratia rojo congelado"},
    {prodId:"50346905",label:"Tamarindo beau rojo congelado"},
    {prodId:"50346906",label:"Tamarindo delicia roja congelado"},
    {prodId:"50347000",label:"Frutas Nominant congeladas"},
    {prodId:"50347001",label:"Cupania congeladas"},
    {prodId:"50347002",label:"Babaco congelado"},
    {prodId:"50347003",label:"Banana flor congeladas"},
    {prodId:"50347004",label:"Baobab congeladas"},
    {prodId:"50347005",label:"Naranja agria congeladas"},
    {prodId:"50347006",label:"Canistel congeladas"},
    {prodId:"50347007",label:"Coco congelado"},
    {prodId:"50347008",label:"Baya nube congelado"},
    {prodId:"50347009",label:"Baya drew congeladas"},
    {prodId:"50347010",label:"Durian congeladas"},
    {prodId:"50347011",label:"Baya elder congeladas"},
    {prodId:"50347012",label:"Feijoa congeladas"},
    {prodId:"50347013",label:"Mora congeladas"},
    {prodId:"50347014",label:"Cretaegus congeladas"},
    {prodId:"50347015",label:"Baya miel congeladas"},
    {prodId:"50347016",label:"Jaca congeladas"},
    {prodId:"50347017",label:"Jambolan congeladas"},
    {prodId:"50347018",label:"Jujube congeladas"},
    {prodId:"50347019",label:"Liches congelados"},
    {prodId:"50347020",label:"Mangostinos congelados"},
    {prodId:"50347021",label:"Medlar congeladas"},
    {prodId:"50347022",label:"Mombines congeladas"},
    {prodId:"50347023",label:"Monstera congeladas"},
    {prodId:"50347024",label:"Pepinos congelado"},
    {prodId:"50347025",label:"Platono congelado"},
    {prodId:"50347026",label:"Peras en escabeche congeladas"},
    {prodId:"50347027",label:"Mamonsillo congelado"},
    {prodId:"50347028",label:"Rambután congelado"},
    {prodId:"50347029",label:"Manzana rosa congeladas"},
    {prodId:"50347030",label:"Rosele congeladas"},
    {prodId:"50347031",label:"Baya rowan congeladas"},
    {prodId:"50347032",label:"Baya buckhom de mar congeladas"},
    {prodId:"50347033",label:"Baya plata congeladas"},
    {prodId:"50347034",label:"Baya sorbete congelado"},
    {prodId:"50347035",label:"Wanabana congeladas"},
    {prodId:"50347036",label:"Manzana estrella congeladas"},
    {prodId:"50347037",label:"Tamarindo congelado"},
    {prodId:"50347100",label:"Aronias congeladas"},
    {prodId:"50347101",label:"Aronia magia de otoño congeladas"},
    {prodId:"50347102",label:"Aronia brillantísima congeladas"},
    {prodId:"50347103",label:"Aronia nero congeladas"},
    {prodId:"50347104",label:"Aronia viquingo congeladas"},
    {prodId:"50347200",label:"Aceitunas congeladas"},
    {prodId:"50347201",label:"Aceitunas agrinion congeladas"},
    {prodId:"50347202",label:"Aceitunas alepo congeladas"},
    {prodId:"50347203",label:"Aceitunas  alfonso congeladas"},
    {prodId:"50347204",label:"Aceitunas  anfisa congeladas"},
    {prodId:"50347205",label:"Aceitunas  arauco congeladas"},
    {prodId:"50347206",label:"Aceitunas  arbequina congeladas"},
    {prodId:"50347207",label:"Aceitunas  atlanta congeladas"},
    {prodId:"50347208",label:"Aceitunas  cerignola congeladas"},
    {prodId:"50347209",label:"Aceitunas  cracked provencal congeladas"},
    {prodId:"50347210",label:"Aceitunas  empeltre congeladas"},
    {prodId:"50347211",label:"Aceitunas gaeta  congeladas"},
    {prodId:"50347212",label:"Aceitunas hondoelia congeladas"},
    {prodId:"50347213",label:"Aceitunas kalamata congeladas"},
    {prodId:"50347214",label:"Aceitunas kura congeladas"},
    {prodId:"50347215",label:"Aceitunas ligurian congeladas"},
    {prodId:"50347216",label:"Aceitunas lucque congeladas"},
    {prodId:"50347217",label:"Aceitunas lugano congeladas"},
    {prodId:"50347218",label:"Aceitunas manzanilla congeladas"},
    {prodId:"50347219",label:"Aceitunas marche congeladas"},
    {prodId:"50347220",label:"Aceitunas misión congeladas"},
    {prodId:"50347221",label:"Aceitunas nafplion verde congeladas"},
    {prodId:"50347222",label:"Aceitunas nicoise congeladas"},
    {prodId:"50347223",label:"Aceitunas nyons congeladas"},
    {prodId:"50347224",label:"Aceitunas picholine congeladas"},
    {prodId:"50347225",label:"Aceitunas ponentine congeladas"},
    {prodId:"50347226",label:"Aceitunas royal congeladas"},
    {prodId:"50347227",label:"Aceitunas seracena congeladas"},
    {prodId:"50347228",label:"Aceitunas sevillano congeladas"},
    {prodId:"50347229",label:"Aceitunas sicilian congeladas"},
    {prodId:"50347230",label:"Aceitunas toscanella congeladas"},
    {prodId:"50351500",label:"Manzanas orgánicas congeladas"},
    {prodId:"50351501",label:"Manzanas akane congelada orgánica"},
    {prodId:"50351502",label:"Manzana ambrosia congelada orgánica"},
    {prodId:"50351503",label:"Manzanas api congelada orgánica"},
    {prodId:"50351504",label:"Manzanas baldwin congelada orgánica"},
    {prodId:"50351505",label:"Manzanas brabum congelada orgánica"},
    {prodId:"50351506",label:"Manzanas bramley congelada orgánica"},
    {prodId:"50351507",label:"Manzana joven bramley congelado orgánico"},
    {prodId:"50351508",label:"Manzana calville blanche d'hiver congelado orgánico"},
    {prodId:"50351509",label:"Manzana cameo congelada orgánica"},
    {prodId:"50351510",label:"Manzana charles ross congelada orgánica"},
    {prodId:"50351511",label:"Manzana codlin congelada orgánica"},
    {prodId:"50351512",label:"Manzana cortland congelada orgánica"},
    {prodId:"50351513",label:"Manzana costard congelada orgánica"},
    {prodId:"50351514",label:"Manzana court pendu plat congelada orgánica"},
    {prodId:"50351515",label:"Manzana  cox's orange pippin congelada orgánica"},
    {prodId:"50351516",label:"Manzana crab congelada orgánica"},
    {prodId:"50351517",label:"Manzana crispin congelada orgánica"},
    {prodId:"50351518",label:"Manzana delicious congelada orgánica"},
    {prodId:"50351519",label:"Manzana duchess congelada orgánica"},
    {prodId:"50351520",label:"Manzana earligold congelada orgánica"},
    {prodId:"50351521",label:"Manzana early mcintosh congelada orgánica"},
    {prodId:"50351522",label:"Manzana elstar congelada orgánica"},
    {prodId:"50351523",label:"Manzana empire congelada orgánica"},
    {prodId:"50351524",label:"Manzana flower of kent congelada orgánica"},
    {prodId:"50351525",label:"Manzana fiji congelada orgánica"},
    {prodId:"50351526",label:"Manzana gala congelada orgánica"},
    {prodId:"50351527",label:"Manzana gascoyne's scarlet congelada orgánica"},
    {prodId:"50351528",label:"Manzana gillyflower congelada orgánica"},
    {prodId:"50351529",label:"Manzana ginger gold congelada orgánica"},
    {prodId:"50351530",label:"Manzana gladstone congelada orgánica"},
    {prodId:"50351531",label:"Manzana gloster congelada orgánica"},
    {prodId:"50351532",label:"Manzana gold supreme congelada orgánica"},
    {prodId:"50351533",label:"Manzana golden delicious congelada orgánica"},
    {prodId:"50351534",label:"Manzana golden nobel congelada orgánica"},
    {prodId:"50351535",label:"Manzana granny smith congelada orgánica"},
    {prodId:"50351536",label:"Manzana gravenstain congelada orgánica"},
    {prodId:"50351537",label:"Manzana greening congelada orgánica"},
    {prodId:"50351538",label:"Manzana greensleeves congelada orgánica"},
    {prodId:"50351539",label:"Manzana honeycrisp congelada orgánica"},
    {prodId:"50351540",label:"Manzana howgate wonder congelada orgánica"},
    {prodId:"50351541",label:"Manzana red ida congelada orgánica"},
    {prodId:"50351542",label:"Manzana james grives congelada orgánica"},
    {prodId:"50351543",label:"Manzana jersey mac congelada orgánica"},
    {prodId:"50351544",label:"Manzana jaster congelada orgánica"},
    {prodId:"50351545",label:"Manzana jonagold congelada orgánica"},
    {prodId:"50351546",label:"Manzana jonamac congelada orgánica"},
    {prodId:"50351547",label:"Manzana  jonathan  congelada orgánica"},
    {prodId:"50351548",label:"Manzana katy congelada orgánica"},
    {prodId:"50351549",label:"Manzana kidd's orange red congelada orgánica"},
    {prodId:"50351550",label:"Manzana lady congelada orgánica"},
    {prodId:"50351551",label:"Manzana law rome congelada orgánica"},
    {prodId:"50351552",label:"Manzana laxton congelada orgánica"},
    {prodId:"50351553",label:"Manzana lord derby congelada orgánica"},
    {prodId:"50351554",label:"Manzana macoun  congelada orgánica"},
    {prodId:"50351555",label:"Manzana mcintosh congelada orgánica"},
    {prodId:"50351556",label:"Manzana mutsu congelada orgánica"},
    {prodId:"50351557",label:"Manzana newtown pippin congelada orgánica"},
    {prodId:"50351558",label:"Manzana northen spy congelada orgánica congelada orgánica"},
    {prodId:"50351559",label:"Manzana orleans reinette congelada orgánica"},
    {prodId:"50351560",label:"Manzana ozark dorado congelada orgánica"},
    {prodId:"50351561",label:"Manzana rosa pacifica rose congelada orgánica"},
    {prodId:"50351562",label:"Manzana paula roja congelada orgánica"},
    {prodId:"50351563",label:"Manzana peamain congelada orgánica"},
    {prodId:"50351564",label:"Manzana dama rosada lady congelada orgánica"},
    {prodId:"50351565",label:"Manzana pippin congelada orgánica"},
    {prodId:"50351566",label:"Manzana pitmaston pineapple congelada orgánica"},
    {prodId:"50351567",label:"Manzana pomme dápi congelada orgánica"},
    {prodId:"50351568",label:"Manzana dorada primaria congelada orgánica"},
    {prodId:"50351569",label:"Manzana roja astrakhan congelada orgánica"},
    {prodId:"50351570",label:"Manzana roja boscoop congelada orgánica"},
    {prodId:"50351571",label:"Manzana  roja jefe congelada orgánica"},
    {prodId:"50351572",label:"Manzana roja deliciosa congelada orgánica"},
    {prodId:"50351573",label:"Manzana red grvenstain congelada orgánica"},
    {prodId:"50351574",label:"Manzana roma roja congelada orgánica"},
    {prodId:"50351575",label:"Manzana roja stayman congelada orgánica"},
    {prodId:"50351576",label:"Manzana red york congelada orgánica"},
    {prodId:"50351577",label:"Manzana reinette congelada orgánica"},
    {prodId:"50351578",label:"Manzana belleza romana congelada orgánica"},
    {prodId:"50351579",label:"Manzana russet congelada orgánica"},
    {prodId:"50351580",label:"Manzana sierra belleza congelada orgánica"},
    {prodId:"50351581",label:"Manzana spartan congelada orgánica"},
    {prodId:"50351582",label:"Manzana stark crimson congelada orgánica"},
    {prodId:"50351583",label:"Manzana starking congelada orgánica"},
    {prodId:"50351584",label:"Manzana stayman congelada orgánica"},
    {prodId:"50351585",label:"Manzana stayman winesap congelada orgánica"},
    {prodId:"50351586",label:"Manzana summer rambo congelada orgánica"},
    {prodId:"50351587",label:"Manzana tsugaru congelada orgánica"},
    {prodId:"50351588",label:"Manzana veinte onzas congelada orgánica"},
    {prodId:"50351589",label:"Manzana tydeman red congelada orgánica"},
    {prodId:"50351590",label:"Manzana vistabella congelada orgánica"},
    {prodId:"50351591",label:"Manzana weatley congelada orgánica"},
    {prodId:"50351592",label:"Manzana blanca joanetine congelada orgánica"},
    {prodId:"50351593",label:"Manzana blanca transparent congelada orgánica"},
    {prodId:"50351594",label:"Manzana winesap congelada orgánica"},
    {prodId:"50351595",label:"Manzana worcester congelada orgánica"},
    {prodId:"50351596",label:"Manzana york imperial congelada orgánica"},
    {prodId:"50351600",label:"Albaricoques orgánicos congelados"},
    {prodId:"50351601",label:"Abaricoques ambercot congelada orgánica"},
    {prodId:"50351602",label:"Albaricoques apache congelada orgánica"},
    {prodId:"50351603",label:"Albaricoques brittany dorado congelada orgánica"},
    {prodId:"50351604",label:"Albaricoque negra congelada orgánica"},
    {prodId:"50351605",label:"Albaricoque blenheim congelada orgánica"},
    {prodId:"50351606",label:"Albaricoque bonny congelada orgánica"},
    {prodId:"50351607",label:"Albaricoque biluda congelada orgánica"},
    {prodId:"50351608",label:"Albaricoque castlebrite congelada orgánica"},
    {prodId:"50351609",label:"Albaricoque  clutha dorada congelada orgánica"},
    {prodId:"50351610",label:"Albaricoque clutha sol congelada orgánica"},
    {prodId:"50351611",label:"Albaricoque derby royal congelada orgánica"},
    {prodId:"50351612",label:"Albaricoque dina congelada orgánica"},
    {prodId:"50351613",label:"Albaricoque earlicot congelada orgánica"},
    {prodId:"50351614",label:"Albaricoque earliman congelada orgánica"},
    {prodId:"50351615",label:"Albaricoque early bright congelada orgánica"},
    {prodId:"50351616",label:"Albaricoque  dorado en llamas congelada orgánica"},
    {prodId:"50351617",label:"Albaricoque frenso congelada orgánica"},
    {prodId:"50351618",label:"Albaricoque dorado claro congelada orgánica"},
    {prodId:"50351619",label:"Albaricoque barra dorado congelada orgánica"},
    {prodId:"50351620",label:"Albaricoque dorado dulce congelada orgánica"},
    {prodId:"50351621",label:"Albaricoque dorado rico congelada orgánica"},
    {prodId:"50351622",label:"Albaricoque helena congelada orgánica"},
    {prodId:"50351623",label:"Albaricoque honeycot congelada orgánica"},
    {prodId:"50351624",label:"Albaricoque imperial congelada orgánica"},
    {prodId:"50351625",label:"Albaricoque jordanne congelada orgánica"},
    {prodId:"50351626",label:"Albaricoque jumbo cot congelada orgánica"},
    {prodId:"50351627",label:"Albaricoque kandy kot congelada orgánica"},
    {prodId:"50351628",label:"Albaricoque katy congelada orgánica"},
    {prodId:"50351629",label:"Albaricoque rey congelada orgánica"},
    {prodId:"50351630",label:"Albaricoque lambertin congelada orgánica"},
    {prodId:"50351631",label:"Albaricoque loma congelada orgánica"},
    {prodId:"50351632",label:"Albaricoque lulu belle congelada orgánica"},
    {prodId:"50351633",label:"Albaricoque modesto congelada orgánica"},
    {prodId:"50351634",label:"Albaricoque moorpark congelada orgánica"},
    {prodId:"50351635",label:"Albaricoque orangered congelada orgánica"},
    {prodId:"50351636",label:"Albaricoque palstein congelada orgánica"},
    {prodId:"50351637",label:"Albaricoque perfeccion congelada orgánica"},
    {prodId:"50351638",label:"Albaricoque perfection congelada orgánica"},
    {prodId:"50351639",label:"Albaricoque poppy congelada orgánica"},
    {prodId:"50351640",label:"Albaricoque poppycot congelada orgánica"},
    {prodId:"50351641",label:"Albaricoque reina congelada orgánica"},
    {prodId:"50351642",label:"Albaricoque riland congelada orgánica"},
    {prodId:"50351643",label:"Albaricoque rival congelada orgánica"},
    {prodId:"50351644",label:"Albaricoque robada congelada orgánica"},
    {prodId:"50351645",label:"Albaricoque royal congelada orgánica"},
    {prodId:"50351646",label:"Albaricoque  royal blenheim congelada orgánica"},
    {prodId:"50351647",label:"Albaricoque  royal orange congelada orgánica"},
    {prodId:"50351648",label:"Albaricoque gota de sol congelada orgánica"},
    {prodId:"50351649",label:"Albaricoque tilton congelada orgánica"},
    {prodId:"50351650",label:"Albaricoque tomcot congelada orgánica"},
    {prodId:"50351651",label:"Albaricoque tracy congelada orgánica"},
    {prodId:"50351652",label:"Albaricoque tri gem congelada orgánica"},
    {prodId:"50351653",label:"Albaricoque valle dorado congelada orgánica"},
    {prodId:"50351654",label:"Albaricoque westley congelada orgánica"},
    {prodId:"50351655",label:"Albaricoque  york congelada orgánica"},
    {prodId:"50351800",label:"Barberries orgánicos congelados"},
    {prodId:"50351801",label:"Berberís paleleaf congelada orgánica"},
    {prodId:"50351802",label:"Barberís chenault congelada orgánica"},
    {prodId:"50351803",label:"Barberís roja congelada orgánica"},
    {prodId:"50351804",label:"Barberís invierno verde congelada orgánica"},
    {prodId:"50351805",label:"Barberís korean congelada orgánica"},
    {prodId:"50351806",label:"Barberís mentor congelada orgánica"},
    {prodId:"50351807",label:"Barberís japones congelada orgánica"},
    {prodId:"50351808",label:"Barberís atropurpurea congelada orgánica"},
    {prodId:"50351809",label:"Barberís aurea congelada orgánica"},
    {prodId:"50351810",label:"Barberís bagatelle congelada orgánica"},
    {prodId:"50351811",label:"Barberís crimsom pygmy congelada orgánica"},
    {prodId:"50351812",label:"Barberís kobold congelada orgánica"},
    {prodId:"50351813",label:"Barberís warty congelada orgánica"},
    {prodId:"50351814",label:"Barberís europea congelada orgánica"},
    {prodId:"50351900",label:"Gayubas orgánicas congeladas"},
    {prodId:"50351901",label:"Gayuba alpine congelada orgánica"},
    {prodId:"50351902",label:"Gayuba roja congelada orgánica"},
    {prodId:"50351903",label:"Gayuba común congelada orgánica"},
    {prodId:"50352000",label:"Moras orgánicas congeladas"},
    {prodId:"50352001",label:"Mora apache congelada orgánica"},
    {prodId:"50352002",label:"Mora satin negro congelada orgánica"},
    {prodId:"50352003",label:"Mora congelada orgánica"},
    {prodId:"50352004",label:"Mora cherokee congelada orgánica"},
    {prodId:"50352005",label:"Mora chester congelada orgánica"},
    {prodId:"50352006",label:"Mora dirksen congelada orgánica"},
    {prodId:"50352007",label:"Bayasjosta congelada orgánica"},
    {prodId:"50352008",label:"Bayaslogan congelada orgánica"},
    {prodId:"50352009",label:"Bayasmarion congelada orgánica"},
    {prodId:"50352010",label:"Mora navaho congelada orgánica"},
    {prodId:"50352011",label:"Bayanectar congelada orgánica"},
    {prodId:"50352012",label:"Mora olallie congelada orgánica"},
    {prodId:"50352013",label:"Bayatay congelada orgánica"},
    {prodId:"50352014",label:"Mora thomless hull congelada orgánica"},
    {prodId:"50352015",label:"Bayayoung congelada orgánica"},
    {prodId:"50352100",label:"Arándanos de mirto orgánicos congelados"},
    {prodId:"50352101",label:"Arandanos bog congelada orgánica"},
    {prodId:"50352102",label:"Arandanos enano congelada orgánica"},
    {prodId:"50352103",label:"Arandanos montaña congelada orgánica"},
    {prodId:"50352104",label:"Arandanos oval-leaved congelada orgánica"},
    {prodId:"50352200",label:"Agrás orgánicas congeladas"},
    {prodId:"50352201",label:"Agras bluecrop congelada orgánica"},
    {prodId:"50352202",label:"Agras bluetta congelada orgánica"},
    {prodId:"50352203",label:"Agras brigitta congelada orgánica"},
    {prodId:"50352204",label:"Agras chandler congelada orgánica"},
    {prodId:"50352205",label:"Agras duque congelada orgánica"},
    {prodId:"50352206",label:"Agras hrdyblue congelada orgánica"},
    {prodId:"50352207",label:"Agras legacy congelada orgánica"},
    {prodId:"50352208",label:"Agras misty congelada orgánica"},
    {prodId:"50352209",label:"Agras nelson congelada orgánica"},
    {prodId:"50352210",label:"Agras northblue congelada orgánica"},
    {prodId:"50352211",label:"Agras northcountry congelada orgánica"},
    {prodId:"50352212",label:"Agras northsky congelada orgánica"},
    {prodId:"50352213",label:"Agras patriota congelada orgánica"},
    {prodId:"50352214",label:"Agras spartan congelada orgánica"},
    {prodId:"50352215",label:"Agras toro congelada orgánica"},
    {prodId:"50352300",label:"Frutas del pan orgánicas congeladas"},
    {prodId:"50352301",label:"Fruta de pan chataigne congelada orgánica"},
    {prodId:"50352302",label:"Fruta de pan sin pepa congelada orgánica"},
    {prodId:"50352303",label:"Fruta de pan corazón blanco congelada orgánica"},
    {prodId:"50352304",label:"Fruta de pan corazón amarillo congelada orgánica"},
    {prodId:"50352400",label:"Chirimoyas orgánicas congeladas"},
    {prodId:"50352401",label:"Chirimoya chays congelada orgánica"},
    {prodId:"50352402",label:"Chirimoya bronceada congelada orgánica"},
    {prodId:"50352403",label:"Chirimoya burtons congelada orgánica"},
    {prodId:"50352404",label:"Chirimoya burton favorite congelada orgánica"},
    {prodId:"50352405",label:"Chirimoya jete congelada orgánica"},
    {prodId:"50352406",label:"Chirimoya reretai congelada orgánica"},
    {prodId:"50352407",label:"Chirimoya smoothey congelada orgánica"},
    {prodId:"50352408",label:"Chirimoya española congelada orgánica"},
    {prodId:"50352409",label:"Chirimoya blanca congelada orgánica"},
    {prodId:"50352500",label:"Cerezas orgánicas congeladas"},
    {prodId:"50352501",label:"Cereza amarelle  congelada orgánica"},
    {prodId:"50352502",label:"Cereza brooks congelada orgánica"},
    {prodId:"50352503",label:"Cereza bigarreu congelada orgánica"},
    {prodId:"50352504",label:"Cereza bing congelada orgánica"},
    {prodId:"50352505",label:"Cereza negra republic congelada orgánica"},
    {prodId:"50352506",label:"Cereza negra schmidt congelada orgánica"},
    {prodId:"50352507",label:"Cereza negra tartarian congelada orgánica"},
    {prodId:"50352508",label:"Cereza fiesta bing congelada orgánica"},
    {prodId:"50352509",label:"Cereza garnet congelada orgánica"},
    {prodId:"50352510",label:"Cereza rey congelada orgánica"},
    {prodId:"50352511",label:"Cereza champan congelada orgánica"},
    {prodId:"50352512",label:"Cereza lapin congelada orgánica"},
    {prodId:"50352513",label:"Cereza larian congelada orgánica"},
    {prodId:"50352514",label:"Cereza dark guines congelada orgánica"},
    {prodId:"50352515",label:"Cereza montmorency congelada orgánica"},
    {prodId:"50352516",label:"Cereza duque congelada orgánica"},
    {prodId:"50352517",label:"Cereza early rivers congelada orgánica"},
    {prodId:"50352518",label:"Cereza ruby bing congelada orgánica"},
    {prodId:"50352519",label:"Cereza santine congelada orgánica"},
    {prodId:"50352520",label:"Cereza geans/guines congelada orgánica"},
    {prodId:"50352521",label:"Cereza sonata congelada orgánica"},
    {prodId:"50352522",label:"Cereza lambert congelada orgánica"},
    {prodId:"50352523",label:"Cereza stella congelada orgánica"},
    {prodId:"50352524",label:"Cereza sweethart congelada orgánica"},
    {prodId:"50352525",label:"Cereza tartarian congelada orgánica"},
    {prodId:"50352527",label:"Cereza maraschino congelada orgánica"},
    {prodId:"50352528",label:"Cereza van congelada orgánica"},
    {prodId:"50352529",label:"Cereza morello congelada orgánica"},
    {prodId:"50352530",label:"Cereza royal ann congelada orgánica"},
    {prodId:"50352531",label:"Cereza ranier congelada orgánica"},
    {prodId:"50352532",label:"Cereza royal congelada orgánica"},
    {prodId:"50352600",label:"Citrones orgánicos congelados"},
    {prodId:"50352601",label:"Citrones buddha's hand congelada orgánica"},
    {prodId:"50352602",label:"Citrones fingered congelada orgánica"},
    {prodId:"50352603",label:"Citrones fo shoukan congelada orgánica"},
    {prodId:"50352604",label:"Cotrones bushakan congelada orgánica"},
    {prodId:"50352605",label:"Citones diamante congelada orgánica"},
    {prodId:"50352606",label:"Citrones etrog congelada orgánica"},
    {prodId:"50352607",label:"Citrones ponderosa congelada orgánica"},
    {prodId:"50352700",label:"Arándanos orgánicos congelados"},
    {prodId:"50352701",label:"Arandano ben lear congelada orgánica"},
    {prodId:"50352702",label:"Arandano temprana negra congelada orgánica"},
    {prodId:"50352703",label:"Arandano gryclesik congelada orgánica"},
    {prodId:"50352704",label:"Arandano  howe congelada orgánica"},
    {prodId:"50352705",label:"Bayas lingon congelada orgánica"},
    {prodId:"50352706",label:"Arandano mcfarling congelada orgánica"},
    {prodId:"50352707",label:"Arandano mauntain congelada orgánica"},
    {prodId:"50352708",label:"Arandano pilgrim congelada orgánica"},
    {prodId:"50352709",label:"Arandano searless congelada orgánica"},
    {prodId:"50352710",label:"Arandano stevens congelada orgánica"},
    {prodId:"50352800",label:"pasas de Corinto orgánicas congeladas"},
    {prodId:"50352801",label:"Pasa hudson bay congelada orgánica"},
    {prodId:"50352802",label:"Pasa waxy congelada orgánica"},
    {prodId:"50352803",label:"Pasa desierto congelada orgánica"},
    {prodId:"50352804",label:"Pasa negra congelada orgánica"},
    {prodId:"50352805",label:"Pasa roja congelada orgánica"},
    {prodId:"50352806",label:"Pasa blanca congelada orgánica"},
    {prodId:"50352900",label:"Dátiles orgánicos congelados"},
    {prodId:"50352901",label:"Dátiles asharasi  congelada orgánica"},
    {prodId:"50352902",label:"Dátiles barhi o barhee congelada orgánica"},
    {prodId:"50352903",label:"Dátiles deglet noor congelada orgánica"},
    {prodId:"50352904",label:"Dátiles fardh congelada orgánica"},
    {prodId:"50352905",label:"Dátiles gundila congelada orgánica"},
    {prodId:"50352906",label:"Dátiles halawi/halawy congelada orgánica"},
    {prodId:"50352907",label:"Dátiles hilali congelada orgánica"},
    {prodId:"50352908",label:"Dátiles khadrawi/khadrawy congelada orgánica"},
    {prodId:"50352909",label:"Dátiles khalas congelada orgánica"},
    {prodId:"50352910",label:"Dátiles khustawi congelada orgánica"},
    {prodId:"50352911",label:"Dátiles khidri congelada orgánica"},
    {prodId:"50352912",label:"Dátiles medjool/ medjol congelada orgánica"},
    {prodId:"50352913",label:"Dátiles mactoum congelada orgánica"},
    {prodId:"50352914",label:"Dátiles neghal congelada orgánica"},
    {prodId:"50352915",label:"Dátiles yatimeh congelada orgánica"},
    {prodId:"50352916",label:"Dátiles zahidi congelada orgánica"},
    {prodId:"50353000",label:"Pitahayas orgánicas congeladas"},
    {prodId:"50353001",label:"Pitahaya roja, rosada congelada orgánica"},
    {prodId:"50353002",label:"Pitahaya roja, amarillo congelada orgánica"},
    {prodId:"50353100",label:"Brevas orgánicas congeladas"},
    {prodId:"50353101",label:"Breva bardajic congelada orgánica"},
    {prodId:"50353102",label:"Breva pavo carmelito congelada orgánica"},
    {prodId:"50353103",label:"Breva calimyma  congelada orgánica"},
    {prodId:"50353104",label:"Breva conadria congelada orgánica"},
    {prodId:"50353105",label:"Breva dotado congelada orgánica"},
    {prodId:"50353106",label:"Breva kadota congelada orgánica"},
    {prodId:"50353107",label:"Breva mediterránea congelada orgánica"},
    {prodId:"50353108",label:"Breva misión congelada orgánica"},
    {prodId:"50353109",label:"Breva amyma congelada orgánica"},
    {prodId:"50353110",label:"Breva verdona congelada orgánica"},
    {prodId:"50353111",label:"Breva rey blanco congelada orgánica"},
    {prodId:"50353200",label:"Grosellas orgánicas congeladas"},
    {prodId:"50353201",label:"Grosella early sulphur congelada orgánica"},
    {prodId:"50353202",label:"Grosella gota dorada congelada orgánica"},
    {prodId:"50353203",label:"Grosella langley grace congelada orgánica"},
    {prodId:"50353204",label:"Grocella leveller congelada orgánica"},
    {prodId:"50353205",label:"Grosella london congelada orgánica"},
    {prodId:"50353206",label:"Grosella worcestershire congelada orgánica"},
    {prodId:"50353207",label:"Grosella americana  worcesterberry congelada orgánica"},
    {prodId:"50353300",label:"Toronjas orgánicas congeladas"},
    {prodId:"50353301",label:"Toronja burgundi congelada orgánica"},
    {prodId:"50353302",label:"Toronja duncan congelada orgánica"},
    {prodId:"50353303",label:"Toronja foster congelada orgánica"},
    {prodId:"50353304",label:"Toronja marsh congelada orgánica"},
    {prodId:"50353305",label:"Toronja nueva zelandia congelada orgánica"},
    {prodId:"50353306",label:"Toronja rio rojo congelada orgánica"},
    {prodId:"50353307",label:"Toronja ruby rojo congelada orgánica"},
    {prodId:"50353308",label:"Toronja star ruby congelada orgánica"},
    {prodId:"50353309",label:"Toronja triumph congelada orgánica"},
    {prodId:"50353400",label:"Uvas de mesa orgánicas congeladas"},
    {prodId:"50353401",label:"Uva alicante congelada orgánica"},
    {prodId:"50353402",label:"Uva almeira congelada orgánica"},
    {prodId:"50353403",label:"Uva alphonse lavalle congelada orgánica"},
    {prodId:"50353404",label:"Uva otoño rey grapes congelada orgánica"},
    {prodId:"50353405",label:"Uva otoño royal congelada orgánica"},
    {prodId:"50353406",label:"Uva otoño sin semilla congelada orgánica"},
    {prodId:"50353407",label:"Uva baresana congelada orgánica"},
    {prodId:"50353408",label:"Uva barlinka congelada orgánica"},
    {prodId:"50353409",label:"Uva bellisima seedless congelada orgánica"},
    {prodId:"50353410",label:"Uva bellesa negra sin pepa congelada orgánica"},
    {prodId:"50353411",label:"Uva esmeralda negra congelada orgánica"},
    {prodId:"50353412",label:"Uva negra gigante congelada orgánica"},
    {prodId:"50353413",label:"Uva globo negro congelada orgánica"},
    {prodId:"50353414",label:"Uva negra monnukka congelada orgánica"},
    {prodId:"50353415",label:"Uva perla negra congelada orgánica"},
    {prodId:"50353416",label:"Uva negra sin semilla congelada orgánica"},
    {prodId:"50353417",label:"Uva bonheur congelada orgánica"},
    {prodId:"50353418",label:"Uva calmeria congelada orgánica"},
    {prodId:"50353419",label:"Uva cardinal congelada orgánica"},
    {prodId:"50353420",label:"Uva catawba congelada orgánica"},
    {prodId:"50353421",label:"Uva chasselas/golden chasselas congelada orgánica"},
    {prodId:"50353422",label:"Uva rosa navidad congelada orgánica"},
    {prodId:"50353423",label:"Uva concord congelada orgánica"},
    {prodId:"50353424",label:"Uva concord sin pepa congelada orgánica"},
    {prodId:"50353425",label:"Uva crimson sin pepa congelada orgánica"},
    {prodId:"50353426",label:"Uva dauphine  congelada orgánica"},
    {prodId:"50353427",label:"Uva daleware congelada orgánica"},
    {prodId:"50353428",label:"Uva early muscat congelada orgánica"},
    {prodId:"50353429",label:"Uva early sweet congelada orgánica"},
    {prodId:"50353430",label:"Uva esmerald sin pepa congelada orgánica"},
    {prodId:"50353431",label:"Uva emperatriz congelada orgánica"},
    {prodId:"50353432",label:"Uva emperor congelada orgánica"},
    {prodId:"50353433",label:"Uva empress congelada orgánica"},
    {prodId:"50353434",label:"Uva exótica congelada orgánica"},
    {prodId:"50353435",label:"Uva fantasia congelada orgánica"},
    {prodId:"50353436",label:"Uva fantasy sin pepa congelada orgánica"},
    {prodId:"50353437",label:"Uva llama congelada orgánica"},
    {prodId:"50353438",label:"Uva llama sin semilla congelada orgánica"},
    {prodId:"50353439",label:"Uva llama tokay congelada orgánica"},
    {prodId:"50353440",label:"Uva rojo ardiente congelada orgánica"},
    {prodId:"50353441",label:"Uva galaxy sin pepa congelada orgánica"},
    {prodId:"50353442",label:"Uva gamay congelada orgánica"},
    {prodId:"50353443",label:"Uva dorada congelada orgánica"},
    {prodId:"50353444",label:"Uva hanepot o honeypot congelada orgánica"},
    {prodId:"50353445",label:"Uva italia congelada orgánica"},
    {prodId:"50353446",label:"Uva jade sin pepa congelada orgánica"},
    {prodId:"50353447",label:"Uva jubileo congelada orgánica"},
    {prodId:"50353448",label:"Uva rey ruby congelada orgánica"},
    {prodId:"50353449",label:"Uva kyoho congelada orgánica"},
    {prodId:"50353450",label:"Uva la rochelle congelada orgánica"},
    {prodId:"50353451",label:"Uva dedo de dama congelada orgánica"},
    {prodId:"50353452",label:"Uva late sin semilla congelada orgánica"},
    {prodId:"50353453",label:"Uva majestic sin semilla congelada orgánica"},
    {prodId:"50353454",label:"Uva malaga congelada orgánica"},
    {prodId:"50353455",label:"Uva marro sin pepa congelada orgánica"},
    {prodId:"50353456",label:"Uva muscadine congelada orgánica"},
    {prodId:"50353457",label:"Uva muscat flame congelada orgánica"},
    {prodId:"50353458",label:"Uva muscat congelada orgánica"},
    {prodId:"50353459",label:"Uva muscat sin semilla congelada orgánica"},
    {prodId:"50353460",label:"Uva napoleon congelada orgánica"},
    {prodId:"50353461",label:"Uva nigeria congelada orgánica"},
    {prodId:"50353462",label:"Uva new cross congelada orgánica"},
    {prodId:"50353463",label:"Uva nibella congelada orgánica"},
    {prodId:"50353464",label:"Uva niagara congelada orgánica"},
    {prodId:"50353465",label:"Uva olivette congelada orgánica"},
    {prodId:"50353466",label:"Uva perlette congelada orgánica"},
    {prodId:"50353467",label:"Uva perlon congelada orgánica"},
    {prodId:"50353468",label:"Uva prima negra sin pepa congelada orgánica"},
    {prodId:"50353469",label:"Uva princesa congelada orgánica"},
    {prodId:"50353470",label:"Uva reina congelada orgánica"},
    {prodId:"50353471",label:"Uva roja blush congelada orgánica"},
    {prodId:"50353472",label:"Uva roja globe congelada orgánica"},
    {prodId:"50353473",label:"Uva roja malaga congelada orgánica"},
    {prodId:"50353474",label:"Uva roja sin pepa congelada orgánica"},
    {prodId:"50353475",label:"Uva regina congelada orgánica"},
    {prodId:"50353476",label:"Uva ribier congelada orgánica"},
    {prodId:"50353477",label:"Uva rosita congelada orgánica"},
    {prodId:"50353478",label:"Uva rouge congelada orgánica"},
    {prodId:"50353479",label:"Uva royal negra sin pepa congelada orgánica"},
    {prodId:"50353480",label:"Uva ruby roja sin pepa congelada orgánica"},
    {prodId:"50353481",label:"Uva ryby sin pepa congelada orgánica"},
    {prodId:"50353482",label:"Uva scarlet royal congelada orgánica"},
    {prodId:"50353483",label:"Uva scuppemong congelada orgánica"},
    {prodId:"50353484",label:"Uva sugarose congelada orgánica"},
    {prodId:"50353485",label:"Uva sugartirteen congelada orgánica"},
    {prodId:"50353486",label:"Uva sugraone congelada orgánica"},
    {prodId:"50353487",label:"Uva sugrasixteen congelada orgánica"},
    {prodId:"50353488",label:"Uva sultana sun roja congelada orgánica"},
    {prodId:"50353489",label:"Uva verano royal congelada orgánica"},
    {prodId:"50353490",label:"Uva atardecer congelada orgánica"},
    {prodId:"50353491",label:"Uva superior sin pepa congelada orgánica"},
    {prodId:"50353492",label:"Uva thompson sin pepa congelada orgánica"},
    {prodId:"50353493",label:"Uva tokaylpinot gris congelada orgánica"},
    {prodId:"50353494",label:"Uva waltman cross congelada orgánica"},
    {prodId:"50353495",label:"Uva blanca sin pepa congelada orgánica"},
    {prodId:"50353496",label:"Uva zante current congelada orgánica"},
    {prodId:"50353500",label:"Uvas pasas orgánicas congeladas"},
    {prodId:"50353501",label:"Uva corinth negra congelada orgánica"},
    {prodId:"50353502",label:"Uva canner congelada orgánica"},
    {prodId:"50353503",label:"Uva dovine congelada orgánica"},
    {prodId:"50353504",label:"Uva fiesta congelada orgánica"},
    {prodId:"50353505",label:"Uva selme pete congelada orgánica"},
    {prodId:"50353506",label:"Uva sultana congelada orgánica"},
    {prodId:"50353600",label:"Uvas para vino orgánicas congeladas"},
    {prodId:"50353601",label:"Uva uva alicante bouschet congelada orgánica"},
    {prodId:"50353602",label:"Uva barbera congelada orgánica"},
    {prodId:"50353603",label:"Uva burger congelada orgánica"},
    {prodId:"50353604",label:"Uva cabemet franc congelada orgánica"},
    {prodId:"50353605",label:"Uva cabenet sauvignon congelada orgánica"},
    {prodId:"50353606",label:"Uva carignane congelada orgánica"},
    {prodId:"50353607",label:"Uva camelian congelada orgánica"},
    {prodId:"50353608",label:"Uva cattarrato congelada orgánica"},
    {prodId:"50353609",label:"Uva centurian congelada orgánica"},
    {prodId:"50353610",label:"Uva charbono congelada orgánica"},
    {prodId:"50353611",label:"Uva chardonnay congelada orgánica"},
    {prodId:"50353612",label:"Uva chenin blanco congelada orgánica"},
    {prodId:"50353613",label:"Uva cinsaut congelada orgánica"},
    {prodId:"50353614",label:"Uva dolcetto congelada orgánica"},
    {prodId:"50353615",label:"Uva emerald riesling congelada orgánica"},
    {prodId:"50353616",label:"Uva francesa colombard congelada orgánica"},
    {prodId:"50353617",label:"Uva granny nap congelada orgánica"},
    {prodId:"50353618",label:"Uva gamay beaujolais congelada orgánica"},
    {prodId:"50353619",label:"Uva gewurztraminer congelada orgánica"},
    {prodId:"50353620",label:"Uva grnache congelada orgánica"},
    {prodId:"50353621",label:"Uva grinache blanc congelada orgánica"},
    {prodId:"50353622",label:"Uva lagrein congelada orgánica"},
    {prodId:"50353623",label:"Uva lambruso congelada orgánica"},
    {prodId:"50353624",label:"Uva malbec congelada orgánica"},
    {prodId:"50353625",label:"Uva malvasía bianca congelada orgánica"},
    {prodId:"50353626",label:"Uva marsanne congelada orgánica"},
    {prodId:"50353627",label:"Uva matano congelada orgánica"},
    {prodId:"50353628",label:"Uva merlot congelada orgánica"},
    {prodId:"50353629",label:"Uva meunier congelada orgánica"},
    {prodId:"50353630",label:"Uva missiom congelada orgánica"},
    {prodId:"50353631",label:"Uva montepulceano congelada orgánica"},
    {prodId:"50353632",label:"Uva muscat blanc congelada orgánica"},
    {prodId:"50353633",label:"Uva muscat hamburg congelada orgánica"},
    {prodId:"50353634",label:"Uva muscat alexandria congelada orgánica"},
    {prodId:"50353635",label:"Uva muscat naranja congelada orgánica"},
    {prodId:"50353636",label:"Uva nebbiolo congelada orgánica"},
    {prodId:"50353637",label:"Uva palomino congelada orgánica"},
    {prodId:"50353638",label:"Uva petit verdor congelada orgánica"},
    {prodId:"50353639",label:"Uva petit sirah congelada orgánica"},
    {prodId:"50353640",label:"Uva pinot blanc congelada orgánica"},
    {prodId:"50353641",label:"Uva pinot gris congelada orgánica"},
    {prodId:"50353642",label:"Uva pinot noir congelada orgánica"},
    {prodId:"50353643",label:"Uva primitivo congelada orgánica"},
    {prodId:"50353644",label:"Uva roussane congelada orgánica"},
    {prodId:"50353645",label:"Uva real congelada orgánica"},
    {prodId:"50353646",label:"Uva rubired congelada orgánica"},
    {prodId:"50353647",label:"Uva ruby cabenet congelada orgánica"},
    {prodId:"50353648",label:"Uva salvador congelada orgánica"},
    {prodId:"50353649",label:"Uva sangiovese congelada orgánica"},
    {prodId:"50353650",label:"Uva sauvignon blanc congelada orgánica"},
    {prodId:"50353651",label:"Uva sauvignon musque congelada orgánica"},
    {prodId:"50353652",label:"Uva semillon congelada orgánica"},
    {prodId:"50353653",label:"Uva souzao congelada orgánica"},
    {prodId:"50353654",label:"Uva st emilion congelada orgánica"},
    {prodId:"50353655",label:"Uva symohony congelada orgánica"},
    {prodId:"50353656",label:"Uva syrah  congelada orgánica"},
    {prodId:"50353657",label:"Uva tannat congelada orgánica"},
    {prodId:"50353658",label:"Uva tempranillo congelada orgánica"},
    {prodId:"50353659",label:"Uvas teroldego congelada orgánica"},
    {prodId:"50353660",label:"Uvas tocai friulano congelada orgánica"},
    {prodId:"50353661",label:"Uvas touriga nacional congelada orgánica"},
    {prodId:"50353662",label:"Uvas triplett blanc congelada orgánica"},
    {prodId:"50353663",label:"Uvas viogner congelada orgánica"},
    {prodId:"50353664",label:"Uvas blanca riesling congelada orgánica"},
    {prodId:"50353665",label:"Uvas zinfandel congelada orgánica"},
    {prodId:"50353700",label:"Guayabas orgánicas congeladas"},
    {prodId:"50353701",label:"Guayabas beaumont congelada orgánica"},
    {prodId:"50353702",label:"Guayabas carrley congelada orgánica"},
    {prodId:"50353703",label:"Guayabas lucida congelada orgánica"},
    {prodId:"50353704",label:"Guayaba piña congelada orgánica"},
    {prodId:"50353800",label:"Arándanos Huckleberry orgánicos congelados"},
    {prodId:"50353801",label:"Arándano invierno negro congelado orgánico"},
    {prodId:"50353802",label:"Arándano cascada congelado orgánico"},
    {prodId:"50353803",label:"Arándano enano congelado orgánico"},
    {prodId:"50353804",label:"Arándano montaña congelado orgánico"},
    {prodId:"50353805",label:"Arándano rojo congelado orgánico"},
    {prodId:"50353900",label:"Kiwis orgánicos congelados"},
    {prodId:"50353901",label:"Fruta de kiwi ananasnaja congelada orgánica"},
    {prodId:"50353902",label:"Fruta de kiwi belleza del ártico congelada orgánica"},
    {prodId:"50353903",label:"Fruta de kiwi blake congelada orgánica"},
    {prodId:"50353904",label:"Fruta de kiwi hayward congelada orgánica"},
    {prodId:"50353905",label:"Fruta de kiwi issai congelada orgánica"},
    {prodId:"50353906",label:"Fruta de kiwi siberiana congelada orgánica"},
    {prodId:"50354000",label:"Naranjos Chinos (kumquats) orgánicos congelados"},
    {prodId:"50354001",label:"Naranjo chino hong kong congelado orgánico"},
    {prodId:"50354002",label:"Naranjo chino limequat congelado orgánico"},
    {prodId:"50354003",label:"Naranjo fruta larga congelado orgánico"},
    {prodId:"50354004",label:"Naranjo chino malayan congelado orgánico"},
    {prodId:"50354005",label:"Naranjo meiwa congelado orgánico"},
    {prodId:"50354006",label:"Naranja chino nagami congelado orgánico"},
    {prodId:"50354100",label:"Limones orgánicos congelados"},
    {prodId:"50354101",label:"Limón baboon congelado orgánico"},
    {prodId:"50354102",label:"Limón bearss sicilian congelado orgánico"},
    {prodId:"50354103",label:"Limón cameron highland congelado orgánico"},
    {prodId:"50354104",label:"Limón escondido congelado orgánico"},
    {prodId:"50354105",label:"Limón eureka congelado orgánico"},
    {prodId:"50354106",label:"Limón lisbon congelado orgánico"},
    {prodId:"50354107",label:"Limón meyer congelado orgánico"},
    {prodId:"50354108",label:"Limón volkaner congelado orgánico"},
    {prodId:"50354200",label:"Limas orgánicas congeladas"},
    {prodId:"50354201",label:"Lima limón indian sweet congelado orgánico"},
    {prodId:"50354202",label:"Lima limón congelado orgánico"},
    {prodId:"50354203",label:"Lima limón mandarin congelado orgánico"},
    {prodId:"50354204",label:"Lima limón philippine congelado orgánico"},
    {prodId:"50354205",label:"Lima limón tahitian congelado orgánico"},
    {prodId:"50354206",label:"Lima limón bearss congelado orgánico"},
    {prodId:"50354207",label:"Lima limón persa congelado orgánico"},
    {prodId:"50354208",label:"Lima limón sin pepas congelado orgánico"},
    {prodId:"50354300",label:"Nísperos orgánicos congelados"},
    {prodId:"50354301",label:"Níspero advance congelado orgánico"},
    {prodId:"50354302",label:"Níspero benlehr congelado orgánico"},
    {prodId:"50354303",label:"Níspero big jim congelado orgánico"},
    {prodId:"50354304",label:"Níspero champagne congelado orgánico"},
    {prodId:"50354305",label:"Níspero early rojo congelado orgánico"},
    {prodId:"50354306",label:"Níspero nuget dorado congelado orgánico"},
    {prodId:"50354307",label:"Níspero herd's mammoth congelado orgánico"},
    {prodId:"50354308",label:"Níspero mogi congelado orgánico"},
    {prodId:"50354309",label:"Níspero mrs cooksey congelado orgánico"},
    {prodId:"50354310",label:"Níspero fresa congelado orgánico"},
    {prodId:"50354311",label:"Níspero tanaka congelado orgánico"},
    {prodId:"50354312",label:"Níspero victoria vista blanco congelado orgánico"},
    {prodId:"50354313",label:"Níspero wolfe congelado orgánico"},
    {prodId:"50354400",label:"Mandarinas orgánicas congeladas"},
    {prodId:"50354401",label:"Naranjas clauselinas congelada orgánica"},
    {prodId:"50354402",label:"Mandarinas clementinas congelada orgánica"},
    {prodId:"50354403",label:"Naranja mandarina cleopatra congelada orgánica"},
    {prodId:"50354404",label:"Mandarina dancy congelada orgánica"},
    {prodId:"50354405",label:"Naranja ellensday congelada orgánica"},
    {prodId:"50354406",label:"Naranja fairchild congelada orgánica"},
    {prodId:"50354407",label:"Naranja fallglo congelada orgánica"},
    {prodId:"50354408",label:"Naranja fortuna congelada orgánica"},
    {prodId:"50354409",label:"Mandarina naranja fremont congelada orgánica"},
    {prodId:"50354410",label:"Naranja fremont congelada orgánica"},
    {prodId:"50354411",label:"Naranja nuget dorada congelada orgánica"},
    {prodId:"50354412",label:"Mandarina naranja miel congelada orgánica"},
    {prodId:"50354413",label:"Naranja miel congelada orgánica"},
    {prodId:"50354414",label:"Mandarina miel congelada orgánica"},
    {prodId:"50354415",label:"Natanja tanjelo honebelle congelada orgánica"},
    {prodId:"50354416",label:"Naranja mandarina rey congelada orgánica"},
    {prodId:"50354417",label:"Naranja kinnow congelada orgánica"},
    {prodId:"50354418",label:"Naranja andarinalee congelada orgánica"},
    {prodId:"50354419",label:"Naranja makokkee congelada orgánica"},
    {prodId:"50354420",label:"Naranja malvasion congelada orgánica"},
    {prodId:"50354421",label:"Naranja mandarina mediterránea congelada orgánica"},
    {prodId:"50354422",label:"Naranja tangelo minneola congelada orgánica"},
    {prodId:"50354423",label:"Naranja monica congelada orgánica"},
    {prodId:"50354424",label:"Naranja murcott miel congelada orgánica"},
    {prodId:"50354425",label:"Naranja murcott tangors congelada orgánica"},
    {prodId:"50354426",label:"Naranja mandarina natsudaidai  congelada orgánica"},
    {prodId:"50354427",label:"Naranja mandarina natsumikan congelada orgánica"},
    {prodId:"50354428",label:"Naranja tanjelo nocatee congelada orgánica"},
    {prodId:"50354429",label:"Naranja tanjelo orlando congelada orgánica"},
    {prodId:"50354430",label:"Mandarina ortanique congelada orgánica"},
    {prodId:"50354431",label:"Naranja mandarina pagina congelada orgánica"},
    {prodId:"50354432",label:"Naranja pixie congelada orgánica"},
    {prodId:"50354433",label:"Naranja mandarina ponkan bantangas congelada orgánica"},
    {prodId:"50354434",label:"Naranja reina congelada orgánica"},
    {prodId:"50354435",label:"Naranja robinson congelada orgánica"},
    {prodId:"50354436",label:"Naranja saltenitas congelada orgánica"},
    {prodId:"50354437",label:"Naranja tangelo sampson congelada orgánica"},
    {prodId:"50354438",label:"Naranja mandarina satsuma congelada orgánica"},
    {prodId:"50354439",label:"Naranja mandarina sunburst congelada orgánica"},
    {prodId:"50354440",label:"Tangelo congelado orgánico"},
    {prodId:"50354441",label:"Naranja mandarina congelada orgánica"},
    {prodId:"50354442",label:"Naranja templo congelada orgánica"},
    {prodId:"50354443",label:"Naranja thornton congelada orgánica"},
    {prodId:"50354444",label:"Mandarina wekiwa congelada orgánica"},
    {prodId:"50354445",label:"Mandarina wilkins congelada orgánica"},
    {prodId:"50354446",label:"Mandarina willowleaf mediterránea congelada orgánica"},
    {prodId:"50354600",label:"Melones orgánicos congelados"},
    {prodId:"50354601",label:"Melon allsweet congelado orgánico"},
    {prodId:"50354602",label:"Melón athens congelado orgánico"},
    {prodId:"50354603",label:"Melón diamante negro congelado orgánico"},
    {prodId:"50354604",label:"Melón cal dulce congelado orgánico"},
    {prodId:"50354605",label:"Melons cantaloupe congelado orgánico"},
    {prodId:"50354606",label:"Melón carnical congelado orgánico"},
    {prodId:"50354607",label:"Melón casaba congelado orgánico"},
    {prodId:"50354608",label:"Melón cavaillon congelado orgánico"},
    {prodId:"50354609",label:"Melón charentais congelado orgánico"},
    {prodId:"50354610",label:"Sandia charleston gray congelado orgánico"},
    {prodId:"50354611",label:"Melón crenshaw congelado orgánico"},
    {prodId:"50354612",label:"Melón crimson sweet congelado orgánico"},
    {prodId:"50354613",label:"Melón dixie lee congelado orgánico"},
    {prodId:"50354614",label:"Melón eclipse congelado orgánico"},
    {prodId:"50354615",label:"Melón ein dór  congelado orgánico"},
    {prodId:"50354616",label:"Melón fiesta congelado orgánico"},
    {prodId:"50354617",label:"Melón galia congelado orgánico"},
    {prodId:"50354618",label:"Melón gaya congelado orgánico"},
    {prodId:"50354619",label:"Melón hami congelado orgánico"},
    {prodId:"50354620",label:"Melón miel dew congelado orgánico"},
    {prodId:"50354621",label:"Melón hielo congelado orgánico"},
    {prodId:"50354622",label:"Melón ida orgullo congelado orgánico"},
    {prodId:"50354623",label:"Melón juan canary congelado orgánico"},
    {prodId:"50354624",label:"Melón jubileo congelado orgánico"},
    {prodId:"50354625",label:"Melón jubilación congelado orgánico"},
    {prodId:"50354626",label:"Melón kaki / kakri congelado orgánico"},
    {prodId:"50354627",label:"Melón kiwano congelado orgánico"},
    {prodId:"50354628",label:"Melon koreano congelado orgánico"},
    {prodId:"50354629",label:"Melón long grey congelado orgánico"},
    {prodId:"50354630",label:"Melón mayan congelado orgánico"},
    {prodId:"50354631",label:"Melón micky lee congelado orgánico"},
    {prodId:"50354632",label:"Melón mirage congelado orgánico"},
    {prodId:"50354633",label:"Sandia luna y estrellas congelado orgánico"},
    {prodId:"50354634",label:"Melón ogen congelado orgánico"},
    {prodId:"50354635",label:"Melón patriota congelado orgánico"},
    {prodId:"50354636",label:"Melón pavo real congelado orgánico"},
    {prodId:"50354637",label:"Melón pepino congelado orgánico"},
    {prodId:"50354638",label:"Melón persian congelado orgánico"},
    {prodId:"50354639",label:"Melón picnic congelado orgánico"},
    {prodId:"50354640",label:"Melón piel de sapo congelado orgánico"},
    {prodId:"50354641",label:"Melón piña congelado orgánico"},
    {prodId:"50354642",label:"Melón quetzali congelado orgánico"},
    {prodId:"50354643",label:"Melón goblin rojo congelado orgánico"},
    {prodId:"50354644",label:"Melón regency congelado orgánico"},
    {prodId:"50354645",label:"Melón royal mejestic congelado orgánico"},
    {prodId:"50354646",label:"Melón royal star congelado orgánico"},
    {prodId:"50354647",label:"Melón dulce royal congelado orgánico"},
    {prodId:"50354648",label:"Malon santa claus congelado orgánico"},
    {prodId:"50354649",label:"Melón sharlyn congelado orgánico"},
    {prodId:"50354650",label:"Melón español congelado orgánico"},
    {prodId:"50354651",label:"Melón sprite congelado orgánico"},
    {prodId:"50354652",label:"Melón starbright congelado orgánico"},
    {prodId:"50354653",label:"Melón stars and stripes congelado orgánico"},
    {prodId:"50354654",label:"Melón bebe de azúcar congelado orgánico"},
    {prodId:"50354655",label:"Sandia bebe de azúcar congelada orgánica"},
    {prodId:"50354656",label:"Melón dulce sol congelado orgánico"},
    {prodId:"50354657",label:"Sandia corazón dulce sin pepa congelada orgánica"},
    {prodId:"50354658",label:"Melón tentación congelado orgánico"},
    {prodId:"50354659",label:"Melón tigre bebe congelado orgánico"},
    {prodId:"50354660",label:"Melón tuscan tipo congelado orgánico"},
    {prodId:"50354661",label:"Sandia bebe amarillo congelada orgánica"},
    {prodId:"50354700",label:"Moras mulberry orgánicas congeladas"},
    {prodId:"50354701",label:"Mora negra congelada orgánica"},
    {prodId:"50354702",label:"Mora blanca congelada orgánica"},
    {prodId:"50354800",label:"Mirtos orgánicos congelados"},
    {prodId:"50354801",label:"Mirto bog congelado orgánico"},
    {prodId:"50354900",label:"Nectarinas orgánicos congelados"},
    {prodId:"50354901",label:"Durazno april glo congelado orgánico"},
    {prodId:"50354902",label:"Durazno arctic mist congelado orgánico"},
    {prodId:"50354903",label:"Durazno artic snow congelado orgánico"},
    {prodId:"50354904",label:"Durazno artic star congelado orgánico"},
    {prodId:"50354905",label:"Durazno artic dulce congelado orgánico"},
    {prodId:"50354906",label:"Durazno artic glo congelado orgánico"},
    {prodId:"50354907",label:"Durazno august fire congelado orgánico"},
    {prodId:"50354908",label:"Durazno  perla de agosto congelado orgánico"},
    {prodId:"50354909",label:"Durazno agosto rojo congelado orgánico"},
    {prodId:"50354910",label:"Durazno estrella de agosto congelado orgánico"},
    {prodId:"50354911",label:"Durazno big john congelado orgánico"},
    {prodId:"50354912",label:"Durazno perla brillante congelado orgánico"},
    {prodId:"50354913",label:"Durazno diamante brillante congelado orgánico"},
    {prodId:"50354914",label:"Durazno diamante ray congelado orgánico"},
    {prodId:"50354915",label:"Durazno earligo congelado orgánico"},
    {prodId:"50354916",label:"Durazno diamante temprano congelado orgánico"},
    {prodId:"50354917",label:"Durazno fairlane congelado orgánico"},
    {prodId:"50354918",label:"Durazno fantasia congelado orgánico"},
    {prodId:"50354919",label:"Durazno perla fuego congelado orgánico"},
    {prodId:"50354920",label:"Durazno fuego dulce congelado orgánico"},
    {prodId:"50354921",label:"Durazno flamekist congelado orgánico"},
    {prodId:"50354922",label:"Durazno tipo plano congelado orgánico"},
    {prodId:"50354923",label:"Durazno delicia de jardín congelado orgánico"},
    {prodId:"50354924",label:"Durazno mina de oro congelado orgánico"},
    {prodId:"50354925",label:"Durazno perla grande congelado orgánico"},
    {prodId:"50354926",label:"Durazno hardired congelado orgánico"},
    {prodId:"50354927",label:"Durazno miel de fuego congelado orgánico"},
    {prodId:"50354928",label:"Durazno julio rojo congelado orgánico"},
    {prodId:"50354929",label:"Durazno kay perla congelado orgánico"},
    {prodId:"50354930",label:"Durazno key dulce congelado orgánico"},
    {prodId:"50354931",label:"Durazno diamante mayo congelado orgánico"},
    {prodId:"50354932",label:"Durazno mayfire congelado orgánico"},
    {prodId:"50354933",label:"Durazno mayglo congelado orgánico"},
    {prodId:"50354934",label:"Durazno mericrest congelado orgánico"},
    {prodId:"50354935",label:"Durazno diamante rojo congelado orgánico"},
    {prodId:"50354936",label:"Durazno oro rojo congelado orgánico"},
    {prodId:"50354937",label:"Durazno jim rojo congelado orgánico"},
    {prodId:"50354938",label:"Durazno roy rojo congelado orgánico"},
    {prodId:"50354939",label:"Durazno rio rojo congelado orgánico"},
    {prodId:"50354940",label:"Durazno diamante de rosa congelado orgánico"},
    {prodId:"50354941",label:"Durazno rotal glo congelado orgánico"},
    {prodId:"50354942",label:"Durazno diamante ryby congelado orgánico"},
    {prodId:"50354943",label:"Durazno ruby dulce congelado orgánico"},
    {prodId:"50354944",label:"Durazno joya ruddy congelado orgánico"},
    {prodId:"50354945",label:"Durazno septiembre rojo congelado orgánico"},
    {prodId:"50354946",label:"Durazno reina de nieve congelado orgánico"},
    {prodId:"50354947",label:"Durazno primavera clara congelado orgánico"},
    {prodId:"50354948",label:"Durazno primavera roja congelado orgánico"},
    {prodId:"50354949",label:"Durazno verano rojiso congelado orgánico"},
    {prodId:"50354950",label:"Durazno verano claro congelado orgánico"},
    {prodId:"50354951",label:"Durazno verano diamante congelado orgánico"},
    {prodId:"50354952",label:"Durazno verano fuego congelado orgánico"},
    {prodId:"50354953",label:"Durazno verano grande congelado orgánico"},
    {prodId:"50354954",label:"Durazno sunglo congelado orgánico"},
    {prodId:"50354955",label:"Durazno fuego zee congelado orgánico"},
    {prodId:"50354956",label:"Durazno zee glo congelado orgánico"},
    {prodId:"50354957",label:"Durazno zeegrand congelado orgánico"},
    {prodId:"50355000",label:"Naranjas orgánicas congeladas"},
    {prodId:"50355001",label:"Naranja áfrica agria congelada orgánica"},
    {prodId:"50355002",label:"Naranja dulce amber congelada orgánica"},
    {prodId:"50355003",label:"Naranja argentina agria congelada orgánica"},
    {prodId:"50355004",label:"Naranja bahianinha congelado orgánico"},
    {prodId:"50355005",label:"Naranja bergamot congelada orgánica"},
    {prodId:"50355006",label:"Naranja berna congelada orgánica"},
    {prodId:"50355007",label:"Naranja bigaradier apepu congelada orgánica"},
    {prodId:"50355008",label:"Naranja agria dulce daidai congelada orgánica"},
    {prodId:"50355009",label:"Naranja mono congelada orgánica"},
    {prodId:"50355010",label:"Naranja sangre congelada orgánica"},
    {prodId:"50355011",label:"Naranja california navel congelada orgánica"},
    {prodId:"50355012",label:"Naranja cara cara congelada orgánica"},
    {prodId:"50355013",label:"Naranja chinotto congelada orgánica"},
    {prodId:"50355014",label:"Naranja sueño nevel congelada orgánica"},
    {prodId:"50355015",label:"Naranja gou tou congelada orgánica"},
    {prodId:"50355016",label:"Naranja hamlin congelada orgánica"},
    {prodId:"50355017",label:"Naranja jaffa congelada orgánica"},
    {prodId:"50355018",label:"Naranja jincheng congelada orgánica"},
    {prodId:"50355019",label:"Naranja k-temprano congelada orgánica"},
    {prodId:"50355020",label:"Naranja kona congelada orgánica"},
    {prodId:"50355021",label:"Naranja navel tarde congelada orgánica congelada orgánica"},
    {prodId:"50355022",label:"Naranja valencia tarde congelada orgánica"},
    {prodId:"50355023",label:"Naranja limequat congelada orgánica"},
    {prodId:"50355024",label:"Naranja mar congelada orgánica"},
    {prodId:"50355025",label:"Naranja malegold congelada orgánica"},
    {prodId:"50355026",label:"Naranja moro congelada orgánica"},
    {prodId:"50355027",label:"Naranja moro tabaco congelada orgánica"},
    {prodId:"50355028",label:"Naranja navel congelada orgánica"},
    {prodId:"50355029",label:"Naranja navelina congelada orgánica"},
    {prodId:"50355030",label:"Naranja oro blanco congelada orgánica"},
    {prodId:"50355031",label:"Naranja osceola congelada orgánica"},
    {prodId:"50355032",label:"Naranja parson carmelito congelada orgánica"},
    {prodId:"50355033",label:"Naranja pera congelada orgánica"},
    {prodId:"50355034",label:"Naranjo pummulo congelada orgánica"},
    {prodId:"50355035",label:"Naranja rhode rojo congelada orgánica"},
    {prodId:"50355036",label:"Naranja roble congelada orgánica"},
    {prodId:"50355037",label:"Naranja salustianas congelada orgánica"},
    {prodId:"50355038",label:"Naranja sanguine congelada orgánica"},
    {prodId:"50355039",label:"Naranja sanguinelli congelada orgánica"},
    {prodId:"50355040",label:"Naranja cevilla congelada orgánica"},
    {prodId:"50355041",label:"Naranja shamouti jaffa congelada orgánica"},
    {prodId:"50355042",label:"Naranja tunis congelada orgánica"},
    {prodId:"50355043",label:"Naranja valencia congelada orgánica"},
    {prodId:"50355044",label:"Naranja washington navel congelada orgánica"},
    {prodId:"50355100",label:"Papayas orgánicas congeladas"},
    {prodId:"50355101",label:"Papaya verde cocinar congelada orgánica"},
    {prodId:"50355102",label:"Papaya maradol congelada orgánica"},
    {prodId:"50355103",label:"Papaya amarillo mexicano congelada orgánica"},
    {prodId:"50355104",label:"Papaya montaña congelada orgánica"},
    {prodId:"50355105",label:"Papaya solo congelada orgánica"},
    {prodId:"50355106",label:"Papaya tainung congelada orgánica"},
    {prodId:"50355200",label:"Maracuyás orgánicas congeladas"},
    {prodId:"50355201",label:"Fruta maracuyá banana congelada orgánica"},
    {prodId:"50355202",label:"Maracuyá flor azul congelada orgánica"},
    {prodId:"50355203",label:"Maracuyá crackerjack congelada orgánica"},
    {prodId:"50355204",label:"Maracuyá granadilla gigante congelada orgánica"},
    {prodId:"50355205",label:"Maracuyá granadilla dorada congelada orgánica"},
    {prodId:"50355206",label:"Maracuyá maypops congelada orgánica"},
    {prodId:"50355207",label:"Maracuyá roja congelada orgánica"},
    {prodId:"50355208",label:"Maracuyá granadilla dulce congelada orgánica"},
    {prodId:"50355209",label:"Maracuyá sandia congelada orgánica"},
    {prodId:"50355210",label:"Maracuyá  wing- steam congelada orgánica"},
    {prodId:"50355300",label:"Duraznos orgánicos congelados"},
    {prodId:"50355301",label:"Durazno escudo amber congelado orgánico"},
    {prodId:"50355302",label:"Durazno nieve de abril congelado orgánico"},
    {prodId:"50355303",label:"Durazno dama de agosto congelado orgánico"},
    {prodId:"50355304",label:"Durazno llama de otoño congelado orgánico"},
    {prodId:"50355305",label:"Durazno dama de otoño congelado orgánico"},
    {prodId:"50355306",label:"Durazno babcock congelado orgánico"},
    {prodId:"50355307",label:"Durazno brittany lane congelado orgánico"},
    {prodId:"50355308",label:"Durazno cary mac congelado orgánico"},
    {prodId:"50355309",label:"Durazno clásica congelado orgánico"},
    {prodId:"50355310",label:"Durazno dulce del campo congelado orgánico"},
    {prodId:"50355311",label:"Durazno escudo de cielo congelado orgánico"},
    {prodId:"50355312",label:"Durazno dama crimson congelado orgánico"},
    {prodId:"50355313",label:"Durazno príncipe corona congelado orgánico"},
    {prodId:"50355314",label:"Durazno sol david congelado orgánico"},
    {prodId:"50355315",label:"Durazno  princesa diamante congelado orgánico"},
    {prodId:"50355316",label:"Durazno earlrich congelado orgánico"},
    {prodId:"50355317",label:"Durazno majestuosa temprana congelado orgánico"},
    {prodId:"50355318",label:"Durazno early treat congelado orgánico"},
    {prodId:"50355319",label:"Durazno dama elegante congelado orgánico"},
    {prodId:"50355320",label:"Durazno emperatriz congelado orgánico"},
    {prodId:"50355321",label:"Durazno encoré congelado orgánico"},
    {prodId:"50355322",label:"Durazno dama elegante congelado orgánico"},
    {prodId:"50355323",label:"Durazno príncipe de fuego congelado orgánico"},
    {prodId:"50355324",label:"Durazno escudo de llama congelado orgánico"},
    {prodId:"50355325",label:"Durazno tipo plano congelado orgánico"},
    {prodId:"50355326",label:"Durazno escudo de sabor congelado orgánico"},
    {prodId:"50355327",label:"Durazno príncipe florida congelado orgánico"},
    {prodId:"50355328",label:"Durazno luna llena congelado orgánico"},
    {prodId:"50355329",label:"Durazno harvester congelado orgánico"},
    {prodId:"50355330",label:"Durazno princesa de hielo congelado orgánico"},
    {prodId:"50355331",label:"Durazno princesa de marfil congelado orgánico"},
    {prodId:"50355332",label:"Durazno princesa reina jersey congelado orgánico"},
    {prodId:"50355333",label:"Durazno john henry congelado orgánico"},
    {prodId:"50355334",label:"Durazno príncipe de junio congelado orgánico"},
    {prodId:"50355335",label:"Durazno kaweah congelado orgánico"},
    {prodId:"50355336",label:"Durazno klondike congelado orgánico"},
    {prodId:"50355337",label:"Durazno lindo congelado orgánico"},
    {prodId:"50355338",label:"Durazno loring congelado orgánico"},
    {prodId:"50355339",label:"Durazno majestuoso congelado orgánico"},
    {prodId:"50355340",label:"Durazno o'henry congelado orgánico"},
    {prodId:"50355341",label:"Durazno escudo de reina congelado orgánico"},
    {prodId:"50355342",label:"Durazno dama roja congelado orgánico"},
    {prodId:"50355343",label:"Durazno globo rojo congelado orgánico"},
    {prodId:"50355344",label:"Durazno cielo rojo congelado orgánico"},
    {prodId:"50355345",label:"Durazno redtop congelado orgánico"},
    {prodId:"50355346",label:"Durazno regina congelado orgánico"},
    {prodId:"50355347",label:"Durazno dama rica congelado orgánico"},
    {prodId:"50355348",label:"Durazno mayo rico congelado orgánico"},
    {prodId:"50355349",label:"Durazno gloria royal congelado orgánico"},
    {prodId:"50355350",label:"Durazno dama royal congelado orgánico"},
    {prodId:"50355351",label:"Durazno nieve de septiembre congelado orgánico"},
    {prodId:"50355352",label:"Durazno sol de septiembre congelado orgánico"},
    {prodId:"50355353",label:"Durazno gema sierra congelado orgánico"},
    {prodId:"50355354",label:"Durazno angel de nieve congelado orgánico"},
    {prodId:"50355355",label:"Durazno gema de nieve congelado orgánico"},
    {prodId:"50355356",label:"Durazno rey de nieve congelado orgánico"},
    {prodId:"50355357",label:"Durazno dama de primavera congelado orgánico"},
    {prodId:"50355358",label:"Durazno nieve de primavera congelado orgánico"},
    {prodId:"50355359",label:"Durazno escudo de primavera congelado orgánico"},
    {prodId:"50355360",label:"Durazno dulce gigante congelado orgánico"},
    {prodId:"50355361",label:"Durazno dama de azúcar congelado orgánico"},
    {prodId:"50355362",label:"Durazno brillo de sol congelado orgánico"},
    {prodId:"50355363",label:"Durazno sunhigh congelado orgánico"},
    {prodId:"50355364",label:"Durazno dama super congelado orgánico"},
    {prodId:"50355365",label:"Durazno super rico congelado orgánico"},
    {prodId:"50355366",label:"Durazno surecrop congelado orgánico"},
    {prodId:"50355367",label:"Durazno sueño dulce congelado orgánico"},
    {prodId:"50355368",label:"Durazno septiembre dulce congelado orgánico"},
    {prodId:"50355369",label:"Durazno vista congelado orgánico"},
    {prodId:"50355370",label:"Durazno dama blanca congelado orgánico"},
    {prodId:"50355371",label:"Durazno dama zee congelado orgánico"},
    {prodId:"50355400",label:"Peras orgánicas congeladas"},
    {prodId:"50355401",label:"Peras abate fetel congelada orgánica"},
    {prodId:"50355402",label:"Peras anjou congelada orgánica"},
    {prodId:"50355403",label:"Pera asiática congelada orgánica"},
    {prodId:"50355404",label:"Pera bartlett congelada orgánica"},
    {prodId:"50355405",label:"Pera best ever congelada orgánica"},
    {prodId:"50355406",label:"Pera beth congelada orgánica"},
    {prodId:"50355407",label:"Pera beurre congelada orgánica"},
    {prodId:"50355408",label:"Pera bosc congelada orgánica"},
    {prodId:"50355409",label:"Pera clapp favorita congelada orgánica"},
    {prodId:"50355410",label:"Pera comice congelada orgánica"},
    {prodId:"50355411",label:"Pera concorde congelada orgánica"},
    {prodId:"50355412",label:"Pera conference congelada orgánica"},
    {prodId:"50355413",label:"Pera crimson rojo congelada orgánica"},
    {prodId:"50355414",label:"Peras d' anjou congelada orgánica"},
    {prodId:"50355415",label:"Pera dr jules guyot congelada orgánica"},
    {prodId:"50355416",label:"Peras early congelada orgánica"},
    {prodId:"50355417",label:"Peras emperador carmelito congelada orgánica"},
    {prodId:"50355418",label:"Peras forelle congelada orgánica"},
    {prodId:"50355419",label:"Pera mantequilla francesa congelada orgánica"},
    {prodId:"50355420",label:"Pera glou morceau congelada orgánica"},
    {prodId:"50355421",label:"Pera hosui congelada orgánica"},
    {prodId:"50355422",label:"Pera mantequilla italiana congelada orgánica"},
    {prodId:"50355423",label:"Pera jargonelle congelada orgánica"},
    {prodId:"50355424",label:"Pera juno congelada orgánica"},
    {prodId:"50355425",label:"Para kaiserlouise bonne de jersey congelada orgánica"},
    {prodId:"50355426",label:"Pera keiffer congelada orgánica"},
    {prodId:"50355427",label:"Pera rey royal congelada orgánica"},
    {prodId:"50355428",label:"Pera limonera congelada orgánica"},
    {prodId:"50355429",label:"Pera merton pride congelada orgánica"},
    {prodId:"50355430",label:"Pera mauntain bartlette congelada orgánica"},
    {prodId:"50355431",label:"Pera oliver de serrers congelada orgánica"},
    {prodId:"50355432",label:"Pera onward congelada orgánica"},
    {prodId:"50355433",label:"Pera packham's triumph congelada orgánica"},
    {prodId:"50355434",label:"Pera paraíso congelada orgánica"},
    {prodId:"50355435",label:"Pera passe crassane congelada orgánica"},
    {prodId:"50355436",label:"Pera perry congelada orgánica"},
    {prodId:"50355437",label:"Pera bartlette rojo congelada orgánica"},
    {prodId:"50355438",label:"Pera dánjou  rojo congelada orgánica"},
    {prodId:"50355439",label:"Pera rocha congelada orgánica"},
    {prodId:"50355440",label:"Pera rosey rojo congelada orgánica"},
    {prodId:"50355441",label:"Pera rosy roja congelada orgánica"},
    {prodId:"50355442",label:"Pera majestuosa royal congelada orgánica"},
    {prodId:"50355443",label:"Pera ruby rojo congelada orgánica"},
    {prodId:"50355444",label:"Pera santa maria congelada orgánica"},
    {prodId:"50355445",label:"Pera seckel congelada orgánica"},
    {prodId:"50355446",label:"Pera sensación congelada orgánica"},
    {prodId:"50355447",label:"Pera crimson estrella congelada orgánica"},
    {prodId:"50355448",label:"Pera crimson stark congelada orgánica"},
    {prodId:"50355449",label:"Pera bartlette de verano congelada orgánica"},
    {prodId:"50355450",label:"Pera verano dorado congelada orgánica"},
    {prodId:"50355451",label:"Pera sol dorado congelada orgánica"},
    {prodId:"50355452",label:"Pera sunprite congelada orgánica"},
    {prodId:"50355453",label:"Pera taylors dorado congelada orgánica"},
    {prodId:"50355454",label:"Pera taylors rojo congelada orgánica"},
    {prodId:"50355455",label:"Pera tientsin congelada orgánica"},
    {prodId:"50355456",label:"Pera tosca congelada orgánica"},
    {prodId:"50355457",label:"Pera warden  congelada orgánica"},
    {prodId:"50355458",label:"Pera williams bon chretien congelada orgánica"},
    {prodId:"50355459",label:"Pera williams congelada orgánica"},
    {prodId:"50355460",label:"Pera nelis de invierno congelada orgánica"},
    {prodId:"50355500",label:"Caquis orgánicos congelados"},
    {prodId:"50355501",label:"Caqui americano congelada orgánica"},
    {prodId:"50355502",label:"Caqui sapote negro congelada orgánica"},
    {prodId:"50355503",label:"Caqui chapote / negro congelada orgánica"},
    {prodId:"50355504",label:"Caqui dátale ciruela congelada orgánica"},
    {prodId:"50355505",label:"Caqui fuyu congelada orgánica"},
    {prodId:"50355506",label:"Caqui gigante fuyu congelada orgánica"},
    {prodId:"50355507",label:"Caqui hachiya congelada orgánica"},
    {prodId:"50355508",label:"Caqui mantequilla / mabolo congelada orgánica"},
    {prodId:"50355509",label:"Caqui príncipe ito congelada orgánica"},
    {prodId:"50355510",label:"Caqui brillante royal congelada orgánica"},
    {prodId:"50355511",label:"Caqui sharon congelada orgánica"},
    {prodId:"50355512",label:"Caqui triumph congelada orgánica"},
    {prodId:"50355600",label:"Piñas orgánicas congeladas"},
    {prodId:"50355601",label:"Piña chirimoya congelada orgánica"},
    {prodId:"50355602",label:"Piña dorada congelada orgánica"},
    {prodId:"50355603",label:"Piña hilo congelada orgánica"},
    {prodId:"50355604",label:"Piña kona sugarloaf congelada orgánica"},
    {prodId:"50355605",label:"Piña reina natal congelada orgánica"},
    {prodId:"50355606",label:"Piña pernabuco congelada orgánica"},
    {prodId:"50355607",label:"Piña español rojo congelada orgánica"},
    {prodId:"50355608",label:"Piña cayen suave congelada orgánica"},
    {prodId:"50355609",label:"Piña sugarloaf congelada orgánica"},
    {prodId:"50355610",label:"Piña variegated congelada orgánica"},
    {prodId:"50355700",label:"Ciruelos Damascos orgánicos congelados"},
    {prodId:"50355701",label:"Ciruelo / damasco negro kat congelado orgánico"},
    {prodId:"50355702",label:"Ciruelo / damasco gusto azul congelado orgánico"},
    {prodId:"50355703",label:"Ciruelo / damasco corazón de crimson congelado orgánico"},
    {prodId:"50355704",label:"Ciruelo / damasco dapply dandy congelado orgánico"},
    {prodId:"50355705",label:"Ciruelo / damasco dapple fuego congelado orgánico"},
    {prodId:"50355706",label:"Ciruelo / damasco  dapple temprano congelado orgánico"},
    {prodId:"50355707",label:"Ciruelo / damasco  caída de sabor congelado orgánico"},
    {prodId:"50355708",label:"Ciruelo / damasco sabor de oro congelado orgánico"},
    {prodId:"50355709",label:"Ciruelo / damasco sabor granada congelado orgánico"},
    {prodId:"50355710",label:"Ciruelo / damasco sabor de corazón congelado orgánico"},
    {prodId:"50355711",label:"Ciruelo / damasco joya de sabor congelado orgánico"},
    {prodId:"50355712",label:"Ciruelo / damasco sabor de rey congelado orgánico"},
    {prodId:"50355713",label:"Ciruelo / damasco sabor de reina congelado orgánico"},
    {prodId:"50355714",label:"Ciruelo / damasco sabor supremo congelado orgánico"},
    {prodId:"50355715",label:"Ciruelo / damasco sabor premio congelado orgánico"},
    {prodId:"50355716",label:"Ciruelo / damasco saborella congelado orgánico"},
    {prodId:"50355717",label:"Ciruelo / damasco saborrico congelado orgánico"},
    {prodId:"50355718",label:"Ciruelo / damasco  sabor rosa congelado orgánico"},
    {prodId:"50355719",label:"Ciruelo / damasco orgullo geo congelado orgánico"},
    {prodId:"50355720",label:"Ciruelo / damasco kat rojo congelado orgánico"},
    {prodId:"50355721",label:"Ciruelo / damasco premio royal congelado orgánico"},
    {prodId:"50355722",label:"Ciruelo / damasco rosa sierra congelado orgánico"},
    {prodId:"50355723",label:"Ciruelo / damasco geisha dulce congelado orgánico"},
    {prodId:"50355800",label:"Ciruelas orgánicas congeladas"},
    {prodId:"50355801",label:"Ciruela joya amber congelada orgánica"},
    {prodId:"50355802",label:"Ciruela angeleno congelada orgánica"},
    {prodId:"50355803",label:"Ciruela aurora congelada orgánica"},
    {prodId:"50355804",label:"Ciruela otoño bonito congelada orgánica"},
    {prodId:"50355805",label:"Ciruela gigante de otoño congelada orgánica"},
    {prodId:"50355806",label:"Ciruela orgullo de otoño congelada orgánica"},
    {prodId:"50355807",label:"Ciruela rosa de otoño congelada orgánica"},
    {prodId:"50355808",label:"Ciruela playero congelada orgánica"},
    {prodId:"50355809",label:"Ciruela betty anne congelada orgánica"},
    {prodId:"50355810",label:"Ciruela belleza negra congelada orgánica"},
    {prodId:"50355811",label:"Ciruela bullase negra congelada orgánica"},
    {prodId:"50355812",label:"Ciruela diamante negro congelada orgánica"},
    {prodId:"50355813",label:"Ciruela gigante negro congelada orgánica"},
    {prodId:"50355814",label:"Ciruela hielo negro congelada orgánica"},
    {prodId:"50355815",label:"Ciruela esplendor negro congelada orgánica"},
    {prodId:"50355816",label:"Ciruela ámbar negro congelada orgánica"},
    {prodId:"50355817",label:"Ciruela purpura congelada orgánica"},
    {prodId:"50355818",label:"Ciruela  carsbad congelada orgánica"},
    {prodId:"50355819",label:"Ciruela casselman congelada orgánica"},
    {prodId:"50355820",label:"Ciruela catalina congelada orgánica"},
    {prodId:"50355821",label:"Ciruela damson congelada orgánica"},
    {prodId:"50355822",label:"Ciruela dolly congelada orgánica"},
    {prodId:"50355823",label:"Ciruela earliqueen congelada orgánica"},
    {prodId:"50355824",label:"Ciruela rosa early congelada orgánica"},
    {prodId:"50355825",label:"Ciruela ébano mayo congelada orgánica"},
    {prodId:"50355826",label:"Ciruela ébano congelada orgánica"},
    {prodId:"50355827",label:"Ciruela corazón de elefante congelada orgánica"},
    {prodId:"50355828",label:"Ciruela belleza de esmeralda congelada orgánica"},
    {prodId:"50355829",label:"Ciruela emperatriz congelada orgánica"},
    {prodId:"50355830",label:"Ciruela libertad congelada orgánica"},
    {prodId:"50355831",label:"Ciruela friar congelada orgánica"},
    {prodId:"50355832",label:"Ciruela rojo gar congelada orgánica"},
    {prodId:"50355833",label:"Ciruela gobernador congelada orgánica"},
    {prodId:"50355834",label:"Ciruela rosa grande congelada orgánica"},
    {prodId:"50355835",label:"Ciruela green gage congelada orgánica"},
    {prodId:"50355836",label:"Ciruela greengage congelada orgánica"},
    {prodId:"50355837",label:"Ciruela hiromi congelada orgánica"},
    {prodId:"50355838",label:"Ciruela hiromi rojo congelada orgánica"},
    {prodId:"50355839",label:"Ciruela vacacion congelada orgánica"},
    {prodId:"50355840",label:"Ciruela howard sol congelada orgánica"},
    {prodId:"50355841",label:"Ciruela tipo interspecific congelada orgánica"},
    {prodId:"50355842",label:"Ciruela jamaico congelada orgánica"},
    {prodId:"50355843",label:"Ciruela joanna rojo congelada orgánica"},
    {prodId:"50355844",label:"Ciruela kelsey congelada orgánica"},
    {prodId:"50355845",label:"Ciruela jaime rey congelada orgánica"},
    {prodId:"50355846",label:"Ciruela laroda congelada orgánica"},
    {prodId:"50355847",label:"Ciruela rosa tarde congelada orgánica"},
    {prodId:"50355848",label:"Ciruela rosa linda congelada orgánica"},
    {prodId:"50355849",label:"Ciruela estrella solitaria congelada orgánica"},
    {prodId:"50355850",label:"Ciruela mariposa congelada orgánica"},
    {prodId:"50355851",label:"Ciruela mercado negro congelada orgánica"},
    {prodId:"50355852",label:"Ciruela mercado rojo congelada orgánica"},
    {prodId:"50355853",label:"Ciruela maribel congelada orgánica"},
    {prodId:"50355854",label:"Ciruelas sol de octubre congelada orgánica"},
    {prodId:"50355855",label:"Ciruela owen t congelada orgánica"},
    {prodId:"50355856",label:"Ciruela perdrigon congelada orgánica"},
    {prodId:"50355857",label:"Ciruela placer rosado congelada orgánica"},
    {prodId:"50355858",label:"Ciruela  presidente congelada orgánica"},
    {prodId:"50355859",label:"Ciruela hora prima congelada orgánica"},
    {prodId:"50355860",label:"Ciruela majestad purpura congelada orgánica"},
    {prodId:"50355861",label:"Ciruela reina rosa congelada orgánica"},
    {prodId:"50355862",label:"Ciruela quetsch congelada orgánica"},
    {prodId:"50355863",label:"Ciruela belleza roja congelada orgánica"},
    {prodId:"50355864",label:"Ciruela camino rojo congelada orgánica"},
    {prodId:"50355865",label:"Ciruela ram rojo congelada orgánica"},
    {prodId:"50355866",label:"Ciruela rosa roja congelada orgánica"},
    {prodId:"50355867",label:"Ciruela rojo rico congelada orgánica"},
    {prodId:"50355868",label:"Ciruela romero congelada orgánica"},
    {prodId:"50355869",label:"Ciruela diamante rojo congelada orgánica"},
    {prodId:"50355870",label:"Ciruela rojo royal congelada orgánica"},
    {prodId:"50355871",label:"Ciruela royal zee congelada orgánica"},
    {prodId:"50355872",label:"Ciruela roysum congelada orgánica"},
    {prodId:"50355873",label:"Ciruela santa rosa congelada orgánica"},
    {prodId:"50355874",label:"Ciruela zafiro congelada orgánica"},
    {prodId:"50355875",label:"Ciruela sloe congelada orgánica"},
    {prodId:"50355876",label:"Ciruela sta catherine congelada orgánica"},
    {prodId:"50355877",label:"Ciruela bullase blanco congelada orgánica"},
    {prodId:"50355900",label:"Granadas orgánicas congeladas"},
    {prodId:"50355901",label:"Granada foothhill congelada orgánica"},
    {prodId:"50355902",label:"Granada, granada congelada orgánica"},
    {prodId:"50355903",label:"Granada rojo feliz congelada orgánica"},
    {prodId:"50355904",label:"Granada nana congelada orgánica"},
    {prodId:"50355905",label:"Granada rojo de pat congelada orgánica"},
    {prodId:"50355906",label:"Granada pinkhan congelado orgánico"},
    {prodId:"50355907",label:"Granada terciopelo morado congelada orgánica"},
    {prodId:"50355908",label:"Granada grandioso congelada orgánica"},
    {prodId:"50356000",label:"Pomelos orgánicos congelados"},
    {prodId:"50356001",label:"Pomelo chandler congelado orgánico"},
    {prodId:"50356002",label:"Pomelo hirado butan congelado orgánico"},
    {prodId:"50356003",label:"Pomelo liang ping yau congelado orgánico"},
    {prodId:"50356004",label:"Pomelo panda wangi congelado orgánico"},
    {prodId:"50356005",label:"Pomelo rosado congelado orgánico"},
    {prodId:"50356006",label:"Pomelo shaddock rojo congelado orgánico"},
    {prodId:"50356007",label:"Pomelo siamese dulce congelado orgánico"},
    {prodId:"50356008",label:"Pomelo waingwright congelado orgánico"},
    {prodId:"50356100",label:"Membrillos orgánicos congelados"},
    {prodId:"50356101",label:"Membrillo campeón congelado orgánico"},
    {prodId:"50356102",label:"Membrillo piña congelado orgánico"},
    {prodId:"50356103",label:"Membrillo smyma congelado orgánico"},
    {prodId:"50356200",label:"Frambuesas orgánicas congeladas"},
    {prodId:"50356201",label:"Frambuesa americana roja congelada orgánica"},
    {prodId:"50356202",label:"Frambuesa bailey queensland congelada orgánica"},
    {prodId:"50356203",label:"Frambuesa negra congelada orgánica"},
    {prodId:"50356204",label:"Frambuesa oscura congelada orgánica"},
    {prodId:"50356205",label:"Frambuesa deliciosa congelada orgánica"},
    {prodId:"50356206",label:"Frambuesa enano focke congelada orgánica"},
    {prodId:"50356207",label:"Frambuesa focke hojagris congelada orgánica"},
    {prodId:"50356208",label:"Frambuesa focke fresa congelada orgánica"},
    {prodId:"50356209",label:"Frambuesa  focke amarillo himalaya congelada orgánica"},
    {prodId:"50356210",label:"Frambuesa dorado congelado orgánico"},
    {prodId:"50356211",label:"Frambuesa gris nuevo mexico congelada orgánica"},
    {prodId:"50356212",label:"Frambuesa jepson whitebark congelada orgánica"},
    {prodId:"50356213",label:"Frambuesa kellogs san diego congelada orgánica"},
    {prodId:"50356214",label:"Frambuesa leucodemis whitebark congelada orgánica"},
    {prodId:"50356215",label:"Frambuesa munz cuyamaca congelada orgánica"},
    {prodId:"50356216",label:"Frambuesa peck bartons congelada orgánica"},
    {prodId:"50356217",label:"Frambuesa flor morada congelada orgánica"},
    {prodId:"50356218",label:"Frambuesa roadside congelada orgánica"},
    {prodId:"50356219",label:"Frambuesa san diego congelada orgánica"},
    {prodId:"50356220",label:"Frambuesa nieve congelada orgánica"},
    {prodId:"50356221",label:"Frambuesa pico de nieve congelada orgánica"},
    {prodId:"50356222",label:"Frambuesa hoja de fresa congelada orgánica"},
    {prodId:"50356223",label:"Frambuesa cultivo dulce congelada orgánica"},
    {prodId:"50356224",label:"Frambuesa tor y gris whitebark congelada orgánica"},
    {prodId:"50356225",label:"Frambuesa caribe congelada orgánica"},
    {prodId:"50356226",label:"Frambuesa whitebark congelada orgánica"},
    {prodId:"50356227",label:"Frambuesa vino congelada orgánica"},
    {prodId:"50356228",label:"Frambuesa himalaya amarillo congelada orgánica"},
    {prodId:"50356229",label:"Frambuesa yu-shan congelada orgánica"},
    {prodId:"50356300",label:"Ruibarbos orgánicos congelados"},
    {prodId:"50356301",label:"Ruibarbo crimson rojo congelado orgánico"},
    {prodId:"50356302",label:"Ruibarbo champagne temprana congelado orgánico"},
    {prodId:"50356303",label:"Ruibarbo glasking perpetual  congelado orgánico"},
    {prodId:"50356304",label:"Ruibarbo sutton congelado orgánico"},
    {prodId:"50356305",label:"Ruibarbo timperley temprano congelado orgánico"},
    {prodId:"50356306",label:"Ruibarbo valentina congelado orgánico"},
    {prodId:"50356307",label:"Ruibarbo victoria congelado orgánico"},
    {prodId:"50356308",label:"Ruibarbo zwolle de cemilla congelado orgánico"},
    {prodId:"50356309",label:"Ruibarbo macdonald congelado orgánico"},
    {prodId:"50356310",label:"Ruibarbo tilden congelado orgánico"},
    {prodId:"50356400",label:"Escaramujos orgánicos congelados"},
    {prodId:"50356401",label:"Escaramujo rosa brier congelado orgánico"},
    {prodId:"50356402",label:"Escaramujo rosa elegante congelado orgánico"},
    {prodId:"50356403",label:"Escaramujo rosa rugosa congelado orgánico"},
    {prodId:"50356404",label:"Escaramujo rosa burnet o scotch congelado orgánico"},
    {prodId:"50356500",label:"Sapotes orgánicos congelados"},
    {prodId:"50356501",label:"Sapote blanco congelado orgánico"},
    {prodId:"50356502",label:"Sapote negro congelado orgánico"},
    {prodId:"50356600",label:"Bayas saskatoon orgánicas congeladas"},
    {prodId:"50356601",label:"Baya mielmadera saskatoon congelada orgánica"},
    {prodId:"50356602",label:"Baya northline saskatoon congelada orgánica"},
    {prodId:"50356603",label:"Baya saskatoon ahumado congelada orgánica"},
    {prodId:"50356604",label:"Baya saskatoon thiessen congelada orgánica"},
    {prodId:"50356800",label:"Manzanas dulces orgánicas congeladas"},
    {prodId:"50356801",label:"Manzana kampong malve dulce congelada orgánica"},
    {prodId:"50356802",label:"Manzana dulce sin pepa congelada orgánica"},
    {prodId:"50356803",label:"Manzana dulce thai lessand congelada orgánica"},
    {prodId:"50356900",label:"Tamarillos orgánicos congelados"},
    {prodId:"50356901",label:"Tamarindo amberlea dorado congelado orgánico"},
    {prodId:"50356902",label:"Tamarindo calvo dorado congelado orgánico"},
    {prodId:"50356903",label:"Tamarindo mina de oro congelado orgánico"},
    {prodId:"50356904",label:"Tamarindo oratia rojo congelado orgánico"},
    {prodId:"50356905",label:"Tamarindo beau rojo congelado orgánico"},
    {prodId:"50356906",label:"Tamarindo delicia roja congelado orgánico"},
    {prodId:"50357000",label:"Frutas Nominant orgánicas congeladas"},
    {prodId:"50357001",label:"Cupania congelada orgánica"},
    {prodId:"50357002",label:"Babaco congelado orgánico"},
    {prodId:"50357003",label:"Banana flor congelada orgánica"},
    {prodId:"50357004",label:"Baobab congelada orgánica"},
    {prodId:"50357005",label:"Naranja agria congelada orgánica"},
    {prodId:"50357006",label:"Canistel congelada orgánica"},
    {prodId:"50357007",label:"Coco congelado orgánico"},
    {prodId:"50357008",label:"Baya nublosa congelada orgánica"},
    {prodId:"50357009",label:"Baya drew congelada orgánica"},
    {prodId:"50357010",label:"Durian congelada orgánica"},
    {prodId:"50357011",label:"Baya elder congelada orgánica"},
    {prodId:"50357012",label:"Feijoa congelada orgánica"},
    {prodId:"50357013",label:"Mora congelada orgánica"},
    {prodId:"50357014",label:"Cretaegus congelada orgánica"},
    {prodId:"50357015",label:"Baya miel congelada orgánica"},
    {prodId:"50357016",label:"Jackfruit congelada orgánica"},
    {prodId:"50357017",label:"Jambolan congelada orgánica"},
    {prodId:"50357018",label:"Jujube congelada orgánica"},
    {prodId:"50357019",label:"Liches congelado orgánicos orgánicos"},
    {prodId:"50357020",label:"Mangostinos congelado orgánicos orgánicos"},
    {prodId:"50357021",label:"Medlar congelada orgánica"},
    {prodId:"50357022",label:"Mombines congelada orgánica"},
    {prodId:"50357023",label:"Monstera congelada orgánica"},
    {prodId:"50357024",label:"Pepinos congelado orgánico"},
    {prodId:"50357025",label:"Platono congelado orgánico"},
    {prodId:"50357026",label:"Peras en escabeche congelada orgánica"},
    {prodId:"50357027",label:"Mamonsillo congelado orgánico"},
    {prodId:"50357028",label:"Rambután congelado orgánico"},
    {prodId:"50357029",label:"Manzana rosa congelada orgánica"},
    {prodId:"50357030",label:"Rosele congelada orgánica"},
    {prodId:"50357031",label:"Baya rowan congelada orgánica"},
    {prodId:"50357032",label:"Baya buckhom de mar congelada orgánica"},
    {prodId:"50357033",label:"Baya plata congelada orgánica"},
    {prodId:"50357034",label:"Baya sorbete congelado orgánico"},
    {prodId:"50357035",label:"Guanabana congelada orgánica"},
    {prodId:"50357036",label:"Manzana estrella congelada orgánica"},
    {prodId:"50357037",label:"Tamarindo congelado orgánico"},
    {prodId:"50357100",label:"Aronias orgánicas congeladas"},
    {prodId:"50357101",label:"Aronia magia de otoño congelada orgánica"},
    {prodId:"50357102",label:"Aronia brillantísima congelada orgánica"},
    {prodId:"50357103",label:"Aronia nero congelada orgánica"},
    {prodId:"50357104",label:"Aronia viquingo congelada orgánica"},
    {prodId:"50357200",label:"Aceitunas orgánicas congeladas"},
    {prodId:"50357201",label:"Aceitunas agrinion congelada orgánica"},
    {prodId:"50357202",label:"Aceitunas aleppo congelada orgánica"},
    {prodId:"50357203",label:"Aceitunas  alfonso congelada orgánica"},
    {prodId:"50357204",label:"Aceitunas  amfisa congelada orgánica"},
    {prodId:"50357205",label:"Aceitunas  arauco congelada orgánica"},
    {prodId:"50357206",label:"Aceitunas  arbequina congelada orgánica"},
    {prodId:"50357207",label:"Aceitunas  atlanta congelada orgánica"},
    {prodId:"50357208",label:"Aceitunas  cerignola congelada orgánica"},
    {prodId:"50357209",label:"Aceitunas  cracked provencal congelada orgánica"},
    {prodId:"50357210",label:"Aceitunas  empeltre congelada orgánica"},
    {prodId:"50357211",label:"Aceitunas gaeta  congelada orgánica"},
    {prodId:"50357212",label:"Aceitunas hondoelia congelada orgánica"},
    {prodId:"50357213",label:"Aceitunas kalamata congelada orgánica"},
    {prodId:"50357214",label:"Aceitunas kura congelada orgánica"},
    {prodId:"50357215",label:"Aceitunas ligurian congelada orgánica"},
    {prodId:"50357216",label:"Aceitunas lucque congelada orgánica"},
    {prodId:"50357217",label:"Aceitunas lugano congelada orgánica"},
    {prodId:"50357218",label:"Aceitunas manzanilla congelada orgánica"},
    {prodId:"50357219",label:"Aceitunas marche congelada orgánica"},
    {prodId:"50357220",label:"Aceitunas misión congelada orgánica"},
    {prodId:"50357221",label:"Aceitunas nafplion verde congelada orgánica"},
    {prodId:"50357222",label:"Aceitunas nicoise congelada orgánica"},
    {prodId:"50357223",label:"Aceitunas nyons congelada orgánica"},
    {prodId:"50357224",label:"Aceitunas picholine congelada orgánica"},
    {prodId:"50357225",label:"Aceitunas ponentine congelada orgánica"},
    {prodId:"50357226",label:"Aceitunas royal congelada orgánica"},
    {prodId:"50357227",label:"Aceitunas seracena congelada orgánica"},
    {prodId:"50357228",label:"Aceitunas sevillano congelada orgánica"},
    {prodId:"50357229",label:"Aceitunas sicilian congelada orgánica"},
    {prodId:"50357230",label:"Aceitunas toscanella congelada orgánica"},
    {prodId:"50361500",label:"Manzanas en lata o en frasco"},
    {prodId:"50361501",label:"Manzanas akane en lata o en frasco"},
    {prodId:"50361502",label:"Manzana ambrosia en lata o en frasco"},
    {prodId:"50361503",label:"Manzanas api en lata o en frasco"},
    {prodId:"50361504",label:"Manzanas baldwin en lata o en frasco"},
    {prodId:"50361505",label:"Manzanas brabum en lata o en frasco"},
    {prodId:"50361506",label:"Manzanas bramley en lata o en frasco"},
    {prodId:"50361507",label:"Manzana joven bramley en lata o en frasco"},
    {prodId:"50361508",label:"Manzana calville blanche d'hiver en lata o en frasco"},
    {prodId:"50361509",label:"Manzana cameo en lata o en frasco"},
    {prodId:"50361510",label:"Manzana charles ross en lata o en frasco"},
    {prodId:"50361511",label:"Manzana codlin en lata o en frasco"},
    {prodId:"50361512",label:"Manzana cortland en lata o en frasco"},
    {prodId:"50361513",label:"Manzana costard en lata o en frasco"},
    {prodId:"50361514",label:"Manzana court pendu plat en lata o en frasco"},
    {prodId:"50361515",label:"Manzana  cox's orange pippin en lata o en frasco"},
    {prodId:"50361516",label:"Manzana crab en lata o en frasco"},
    {prodId:"50361517",label:"Manzana crispin en lata o en frasco"},
    {prodId:"50361518",label:"Manzana delicious en lata o en frasco"},
    {prodId:"50361519",label:"Manzana duquesa en lata o en frasco"},
    {prodId:"50361520",label:"Manzana earligold en lata o en frasco"},
    {prodId:"50361521",label:"Manzana early mcintosh en lata o en frasco"},
    {prodId:"50361522",label:"Manzana elstar en lata o en frasco"},
    {prodId:"50361523",label:"Manzana imperio en lata o en frasco"},
    {prodId:"50361524",label:"Manzana flower of kent en lata o en frasco"},
    {prodId:"50361525",label:"Manzana fiji en lata o en frasco"},
    {prodId:"50361526",label:"Manzana gala en lata o en frasco"},
    {prodId:"50361527",label:"Manzana gascoyne's scarlet en lata o en frasco"},
    {prodId:"50361528",label:"Manzana gillyflower en lata o en frasco"},
    {prodId:"50361529",label:"Manzana ginger gold en lata o en frasco"},
    {prodId:"50361530",label:"Manzana gladstone en lata o en frasco"},
    {prodId:"50361531",label:"Manzana gloster en lata o en frasco"},
    {prodId:"50361532",label:"Manzana dorado supremo en lata o en frasco"},
    {prodId:"50361533",label:"Manzana dorado delicioso en lata o en frasco"},
    {prodId:"50361534",label:"Manzana dorado noble nobel en lata o en frasco"},
    {prodId:"50361535",label:"Manzana granny smith en lata o en frasco"},
    {prodId:"50361536",label:"Manzana gravenstain en lata o en frasco"},
    {prodId:"50361537",label:"Manzana greening en lata o en frasco"},
    {prodId:"50361538",label:"Manzana greensleeves en lata o en frasco"},
    {prodId:"50361539",label:"Manzana honeycrisp en lata o en frasco"},
    {prodId:"50361540",label:"Manzana howgate wonder en lata o en frasco"},
    {prodId:"50361541",label:"Manzana red ida en lata o en frasco"},
    {prodId:"50361542",label:"Manzana james grives en lata o en frasco"},
    {prodId:"50361543",label:"Manzana jersey mac en lata o en frasco"},
    {prodId:"50361544",label:"Manzana jaster en lata o en frasco"},
    {prodId:"50361545",label:"Manzana jonagold en lata o en frasco"},
    {prodId:"50361546",label:"Manzana jonamac en lata o en frasco"},
    {prodId:"50361547",label:"Manzana  jonathan  en lata o en frasco"},
    {prodId:"50361548",label:"Manzana katy en lata o en frasco"},
    {prodId:"50361549",label:"Manzana kidd's orange red en lata o en frasco"},
    {prodId:"50361550",label:"Manzana lady en lata o en frasco"},
    {prodId:"50361551",label:"Manzana law rome en lata o en frasco"},
    {prodId:"50361552",label:"Manzana laxton en lata o en frasco"},
    {prodId:"50361553",label:"Manzana lord derby en lata o en frasco"},
    {prodId:"50361554",label:"Manzana macoun  en lata o en frasco"},
    {prodId:"50361555",label:"Manzana mcintosh en lata o en frasco"},
    {prodId:"50361556",label:"Manzana mutsu en lata o en frasco"},
    {prodId:"50361557",label:"Manzana newtown pippin en lata o en frasco"},
    {prodId:"50361558",label:"Manzana northen spy en lata o en frasco en lata o en frasco"},
    {prodId:"50361559",label:"Manzana orleans reinette en lata o en frasco"},
    {prodId:"50361560",label:"Manzana ozark gold en lata o en frasco"},
    {prodId:"50361561",label:"Manzana pacific rose en lata o en frasco"},
    {prodId:"50361562",label:"Manzana paula red en lata o en frasco"},
    {prodId:"50361563",label:"Manzana peamain en lata o en frasco"},
    {prodId:"50361564",label:"Manzana pink lady en lata o en frasco"},
    {prodId:"50361565",label:"Manzana pippin en lata o en frasco"},
    {prodId:"50361566",label:"Manzana pitmaston pineapple en lata o en frasco"},
    {prodId:"50361567",label:"Manzana pomme dápi en lata o en frasco"},
    {prodId:"50361568",label:"Manzana prime gold en lata o en frasco"},
    {prodId:"50361569",label:"Manzana roja astrakhan en lata o en frasco"},
    {prodId:"50361570",label:"Manzana roja boscoop en lata o en frasco"},
    {prodId:"50361571",label:"Manzana  roja chief en lata o en frasco"},
    {prodId:"50361572",label:"Manzana roja delicious en lata o en frasco"},
    {prodId:"50361573",label:"Manzana roja grvenstain en lata o en frasco"},
    {prodId:"50361574",label:"Manzana  roma en lata o en frasco"},
    {prodId:"50361575",label:"Manzanared stayman en lata o en frasco"},
    {prodId:"50361576",label:"Manzana roja york en lata o en frasco"},
    {prodId:"50361577",label:"Manzana reinette en lata o en frasco"},
    {prodId:"50361578",label:"Manzana rome beuty en lata o en frasco"},
    {prodId:"50361579",label:"Manzana russet en lata o en frasco"},
    {prodId:"50361580",label:"Manzana sierra belleza en lata o en frasco"},
    {prodId:"50361581",label:"Manzana spartan en lata o en frasco"},
    {prodId:"50361582",label:"Manzana stark crimson en lata o en frasco"},
    {prodId:"50361583",label:"Manzana starking en lata o en frasco"},
    {prodId:"50361584",label:"Manzana stayman en lata o en frasco"},
    {prodId:"50361585",label:"Manzana stayman winesap en lata o en frasco"},
    {prodId:"50361586",label:"Manzana verano rambo en lata o en frasco"},
    {prodId:"50361587",label:"Manzana tsugaru en lata o en frasco"},
    {prodId:"50361588",label:"Manzana veinte onzas en lata o en frasco"},
    {prodId:"50361589",label:"Manzana tydeman red en lata o en frasco"},
    {prodId:"50361590",label:"Manzana vistabella en lata o en frasco"},
    {prodId:"50361591",label:"Manzana weatley en lata o en frasco"},
    {prodId:"50361592",label:"Manzana blanca joanetine en lata o en frasco"},
    {prodId:"50361593",label:"Manzana blanca transparente en lata o en frasco"},
    {prodId:"50361594",label:"Manzana winesap en lata o en frasco"},
    {prodId:"50361595",label:"Manzana worcester en lata o en frasco"},
    {prodId:"50361596",label:"Manzana york imperial en lata o en frasco"},
    {prodId:"50361700",label:"Bananos en lata o en frasco"},
    {prodId:"50361701",label:"Banana manzana en lata o en frasco"},
    {prodId:"50361702",label:"Banana bebe en lata o en frasco"},
    {prodId:"50361703",label:"Banana burro en lata o en frasco"},
    {prodId:"50361704",label:"Bananas cavendish en lata o en frasco"},
    {prodId:"50361705",label:"Bananas dominico en lata o en frasco"},
    {prodId:"50361706",label:"Bananas verde en lata o en frasco"},
    {prodId:"50361707",label:"Bananas gros michel en lata o en frasco"},
    {prodId:"50361708",label:"Bananas lacatan en lata o en frasco"},
    {prodId:"50361709",label:"Bananas lady finger en lata o en frasco"},
    {prodId:"50361710",label:"Bananas manzano en lata o en frasco"},
    {prodId:"50361711",label:"Banana mysore en lata o en frasco"},
    {prodId:"50361712",label:"Banana pisang mas en lata o en frasco"},
    {prodId:"50361713",label:"Bananas red en lata o en frasco"},
    {prodId:"50361714",label:"Bananas saba en lata o en frasco"},
    {prodId:"50361715",label:"Bananas sucrier en lata o en frasco"},
    {prodId:"50361800",label:"Barberries en lata o en frasco"},
    {prodId:"50361801",label:"Berberís paleleaf en lata o en frasco"},
    {prodId:"50361802",label:"Barberís chenault en lata o en frasco"},
    {prodId:"50361803",label:"Barberries red en lata o en frasco"},
    {prodId:"50361804",label:"Barberís wintergreen en lata o en frasco"},
    {prodId:"50361805",label:"Barberís korean en lata o en frasco"},
    {prodId:"50361806",label:"Barberís mentor en lata o en frasco"},
    {prodId:"50361807",label:"Barberís japanese en lata o en frasco"},
    {prodId:"50361808",label:"Barberís atropurpurea en lata o en frasco"},
    {prodId:"50361809",label:"Barberís aurea en lata o en frasco"},
    {prodId:"50361810",label:"Barberís bagatelle en lata o en frasco"},
    {prodId:"50361811",label:"Barberís crimsom pygmy en lata o en frasco"},
    {prodId:"50361812",label:"Barberís kobold en lata o en frasco"},
    {prodId:"50361813",label:"Barberís warty en lata o en frasco"},
    {prodId:"50361814",label:"Barberís european en lata o en frasco"},
    {prodId:"50361900",label:"Gayubas en lata o en frasco"},
    {prodId:"50361901",label:"Gayuba alpine en lata o en frasco"},
    {prodId:"50361902",label:"Gayuba roja en lata o en frasco"},
    {prodId:"50361903",label:"Gayuba común en lata o en frasco"},
    {prodId:"50362000",label:"Moras en lata o en frasco"},
    {prodId:"50362001",label:"Mora apache en lata o en frasco"},
    {prodId:"50362002",label:"Mora satín negro en lata o en frasco"},
    {prodId:"50362003",label:"Mora boysen en lata o en frasco"},
    {prodId:"50362004",label:"Mora cherokee en lata o en frasco"},
    {prodId:"50362005",label:"Mora chester en lata o en frasco"},
    {prodId:"50362006",label:"Mora dirksen en lata o en frasco"},
    {prodId:"50362007",label:"Bayasjosta en lata o en frasco"},
    {prodId:"50362008",label:"Bayaslogan en lata o en frasco"},
    {prodId:"50362009",label:"Bayasmarion en lata o en frasco"},
    {prodId:"50362010",label:"Mora navaho en lata o en frasco"},
    {prodId:"50362011",label:"Bayanectar en lata o en frasco"},
    {prodId:"50362012",label:"Mora olallie en lata o en frasco"},
    {prodId:"50362013",label:"Baya tay en lata o en frasco"},
    {prodId:"50362014",label:"Mora thomless hull en lata o en frasco"},
    {prodId:"50362015",label:"Baya joven en lata o en frasco"},
    {prodId:"50362100",label:"Arándanos de mirto en lata o en frasco"},
    {prodId:"50362101",label:"Arandanos bog en lata o en frasco"},
    {prodId:"50362102",label:"Arandanos dwarf en lata o en frasco"},
    {prodId:"50362103",label:"Arandanos mountain en lata o en frasco"},
    {prodId:"50362104",label:"Arandanos oval-leaved en lata o en frasco"},
    {prodId:"50362200",label:"Agrás en lata o en frasco"},
    {prodId:"50362201",label:"Agras bluecrop en lata o en frasco"},
    {prodId:"50362202",label:"Agras bluetta en lata o en frasco"},
    {prodId:"50362203",label:"Agras brigitta en lata o en frasco"},
    {prodId:"50362204",label:"Agras chandler en lata o en frasco"},
    {prodId:"50362205",label:"Agras duke en lata o en frasco"},
    {prodId:"50362206",label:"Agras hardyblue en lata o en frasco"},
    {prodId:"50362207",label:"Agras legacy en lata o en frasco"},
    {prodId:"50362208",label:"Agras misty en lata o en frasco"},
    {prodId:"50362209",label:"Agras nelson en lata o en frasco"},
    {prodId:"50362210",label:"Agras northblue en lata o en frasco"},
    {prodId:"50362211",label:"Agras northcountry en lata o en frasco"},
    {prodId:"50362212",label:"Agras northsky en lata o en frasco"},
    {prodId:"50362213",label:"Agras patriot en lata o en frasco"},
    {prodId:"50362214",label:"Agras spartan en lata o en frasco"},
    {prodId:"50362215",label:"Agras toro en lata o en frasco"},
    {prodId:"50362300",label:"Frutas del pan en lata o en frasco"},
    {prodId:"50362301",label:"Fruta de pan chataigne en lata o en frasco"},
    {prodId:"50362302",label:"Fruta de pan sin semilla en lata o en frasco"},
    {prodId:"50362303",label:"Fruta de pan corazón blanco en lata o en frasco"},
    {prodId:"50362304",label:"Fruta de pan corazón amarillo en lata o en frasco"},
    {prodId:"50362400",label:"Chirimoyas en lata o en frasco"},
    {prodId:"50362401",label:"Chirimoya chays en lata o en frasco"},
    {prodId:"50362402",label:"Chirimoya bronceada en lata o en frasco"},
    {prodId:"50362403",label:"Chirimoya burtons en lata o en frasco"},
    {prodId:"50362404",label:"Chirimoya burton favorite en lata o en frasco"},
    {prodId:"50362405",label:"Chirimoya jete en lata o en frasco"},
    {prodId:"50362406",label:"Chirimoya reretai en lata o en frasco"},
    {prodId:"50362407",label:"Chirimoya suave en lata o en frasco"},
    {prodId:"50362408",label:"Chirimoya española en lata o en frasco"},
    {prodId:"50362409",label:"Chirimoya white en lata o en frasco"},
    {prodId:"50362600",label:"Citrones en lata o en frasco"},
    {prodId:"50362601",label:"Citrones buddha's hand en lata o en frasco"},
    {prodId:"50362602",label:"Citrones fingered en lata o en frasco"},
    {prodId:"50362603",label:"Citrones fo shoukan en lata o en frasco"},
    {prodId:"50362604",label:"Cotrones bushakan en lata o en frasco"},
    {prodId:"50362605",label:"Citones diamante en lata o en frasco"},
    {prodId:"50362606",label:"Citrones etrog en lata o en frasco"},
    {prodId:"50362607",label:"Citrones ponderosa en lata o en frasco"},
    {prodId:"50362700",label:"Arándanos en lata o en frasco"},
    {prodId:"50362701",label:"Arandano ben lear en lata o en frasco"},
    {prodId:"50362702",label:"Arandano early black en lata o en frasco"},
    {prodId:"50362703",label:"Arandano gryclesik en lata o en frasco"},
    {prodId:"50362704",label:"Arandano  howe en lata o en frasco"},
    {prodId:"50362705",label:"Bayas lingon en lata o en frasco"},
    {prodId:"50362706",label:"Arandano mcfarling en lata o en frasco"},
    {prodId:"50362707",label:"Arandano mauntain en lata o en frasco"},
    {prodId:"50362708",label:"Arandano pilgrim en lata o en frasco"},
    {prodId:"50362709",label:"Arandano searless en lata o en frasco"},
    {prodId:"50362710",label:"Arandano stevens en lata o en frasco"},
    {prodId:"50362800",label:"pasas de Corinto en lata o en frasco"},
    {prodId:"50362801",label:"Pasa hudson bay en lata o en frasco"},
    {prodId:"50362802",label:"Pasa cera en lata o en frasco"},
    {prodId:"50362803",label:"Pasa postre en lata o en frasco"},
    {prodId:"50362804",label:"Pasa negra en lata o en frasco"},
    {prodId:"50362805",label:"Pasa roja en lata o en frasco"},
    {prodId:"50362806",label:"Pasa blanca en lata o en frasco"},
    {prodId:"50362900",label:"Dátiles en lata o en frasco"},
    {prodId:"50362901",label:"Dátiles asharasi  en lata o en frasco"},
    {prodId:"50362902",label:"Dátiles barhi o barhee en lata o en frasco"},
    {prodId:"50362903",label:"Dátiles deglet noor en lata o en frasco"},
    {prodId:"50362904",label:"Dátiles fardh en lata o en frasco"},
    {prodId:"50362905",label:"Dátiles gundila en lata o en frasco"},
    {prodId:"50362906",label:"Dátiles halawi/halawy en lata o en frasco"},
    {prodId:"50362907",label:"Dátiles hilali en lata o en frasco"},
    {prodId:"50362908",label:"Dátiles khadrawi/khadrawy en lata o en frasco"},
    {prodId:"50362909",label:"Dátiles khalas en lata o en frasco"},
    {prodId:"50362910",label:"Dátiles khustawi en lata o en frasco"},
    {prodId:"50362911",label:"Dátiles khidri en lata o en frasco"},
    {prodId:"50362912",label:"Dátiles medjool/ medjol en lata o en frasco"},
    {prodId:"50362913",label:"Dátiles mactoum en lata o en frasco"},
    {prodId:"50362914",label:"Dátiles neghal en lata o en frasco"},
    {prodId:"50362915",label:"Dátiles yatimeh en lata o en frasco"},
    {prodId:"50362916",label:"Dátiles zahidi en lata o en frasco"},
    {prodId:"50363000",label:"Pitahayas en lata o en frasco"},
    {prodId:"50363001",label:"Pitahaya roja, rosada en lata o en frasco"},
    {prodId:"50363002",label:"Pitahaya roja, amarillo en lata o en frasco"},
    {prodId:"50363100",label:"Brevas en lata o en frasco"},
    {prodId:"50363101",label:"Breva bardajic en lata o en frasco"},
    {prodId:"50363102",label:"Breva brown turkey en lata o en frasco"},
    {prodId:"50363103",label:"Breva calimyma  en lata o en frasco"},
    {prodId:"50363104",label:"Breva conadria en lata o en frasco"},
    {prodId:"50363105",label:"Breva dotado en lata o en frasco"},
    {prodId:"50363106",label:"Breva kadota en lata o en frasco"},
    {prodId:"50363107",label:"Breva mediterránea en lata o en frasco"},
    {prodId:"50363108",label:"Breva misión en lata o en frasco"},
    {prodId:"50363109",label:"Breva amyma en lata o en frasco"},
    {prodId:"50363110",label:"Breva verdona en lata o en frasco"},
    {prodId:"50363111",label:"Breva rey blanco en lata o en frasco"},
    {prodId:"50363200",label:"Grosellas en lata o en frasco"},
    {prodId:"50363201",label:"Grosella early sulphur en lata o en frasco"},
    {prodId:"50363202",label:"Grosella gota dorada en lata o en frasco"},
    {prodId:"50363203",label:"Grosella langley grace en lata o en frasco"},
    {prodId:"50363204",label:"Grocella leveller en lata o en frasco"},
    {prodId:"50363205",label:"Grosella london en lata o en frasco"},
    {prodId:"50363206",label:"Grosella worcestershire en lata o en frasco"},
    {prodId:"50363207",label:"Grosella americana worcesterberry en lata o en frasco"},
    {prodId:"50363300",label:"Toronjas en lata o en frasco"},
    {prodId:"50363301",label:"Toronja burgundi en lata o en frasco"},
    {prodId:"50363302",label:"Toronja duncan en lata o en frasco"},
    {prodId:"50363303",label:"Toronja foster enlatada o enfrascada"},
    {prodId:"50363304",label:"Toronja marsh en lata o en frasco"},
    {prodId:"50363305",label:"Toronja nueva zelandia en lata o en frasco"},
    {prodId:"50363306",label:"Toronja rio rojo en lata o en frasco"},
    {prodId:"50363307",label:"Toronja ruby rojo en lata o en frasco"},
    {prodId:"50363308",label:"Toronja star ruby en lata o en frasco"},
    {prodId:"50363309",label:"Toronja triumph en lata o en frasco"},
    {prodId:"50363400",label:"Uvas de mesa en lata o en frasco"},
    {prodId:"50363401",label:"Uva alicante en lata o en frasco"},
    {prodId:"50363402",label:"Uva almeira en lata o en frasco"},
    {prodId:"50363403",label:"Uva alphonse lavalle en lata o en frasco"},
    {prodId:"50363404",label:"Uva otoño rey grapes en lata o en frasco"},
    {prodId:"50363405",label:"Uva otoño real en lata o en frasco"},
    {prodId:"50363406",label:"Uva otoño sin pepa en lata o en frasco"},
    {prodId:"50363407",label:"Uva baresana en lata o en frasco"},
    {prodId:"50363408",label:"Uva barlinka en lata o en frasco"},
    {prodId:"50363409",label:"Uva belleza seedless en lata o en frasco"},
    {prodId:"50363410",label:"Uva black beauty sin pepa en lata o en frasco"},
    {prodId:"50363411",label:"Uva esmeralda negra en lata o en frasco"},
    {prodId:"50363412",label:"Uva gigante negra en lata o en frasco"},
    {prodId:"50363413",label:"Uva globo negro en lata o en frasco"},
    {prodId:"50363414",label:"Uva negra monnukka en lata o en frasco"},
    {prodId:"50363415",label:"Uva perla negra en lata o en frasco"},
    {prodId:"50363416",label:"Uva negra sin pepa en lata o en frasco"},
    {prodId:"50363417",label:"Uva bonheur en lata o en frasco"},
    {prodId:"50363418",label:"Uva calmeria en lata o en frasco"},
    {prodId:"50363419",label:"Uva cardinal en lata o en frasco"},
    {prodId:"50363420",label:"Uva catawba en lata o en frasco"},
    {prodId:"50363421",label:"Uva chasselas/golden chasselas en lata o en frasco"},
    {prodId:"50363422",label:"Uva christmas rose en lata o en frasco"},
    {prodId:"50363423",label:"Uva concord en lata o en frasco"},
    {prodId:"50363424",label:"Uva concord sin pepa en lata o en frasco"},
    {prodId:"50363425",label:"Uva crimson sin pepa en lata o en frasco"},
    {prodId:"50363426",label:"Uva dauphine  en lata o en frasco"},
    {prodId:"50363427",label:"Uva daleware en lata o en frasco"},
    {prodId:"50363428",label:"Uva early muscat en lata o en frasco"},
    {prodId:"50363429",label:"Uva early sweet en lata o en frasco"},
    {prodId:"50363430",label:"Uva esmeralda sin pepa en lata o en frasco"},
    {prodId:"50363431",label:"Uva emperatriz en lata o en frasco"},
    {prodId:"50363432",label:"Uva emperador en lata o en frasco"},
    {prodId:"50363433",label:"Uva empress en lata o en frasco"},
    {prodId:"50363434",label:"Uva exótica en lata o en frasco"},
    {prodId:"50363435",label:"Uva fantasía en lata o en frasco"},
    {prodId:"50363436",label:"Uva fantasía sin pepa en lata o en frasco"},
    {prodId:"50363437",label:"Uva llama en lata o en frasco"},
    {prodId:"50363438",label:"Uva llama sin pepa en lata o en frasco"},
    {prodId:"50363439",label:"Uva llama tokay en lata o en frasco"},
    {prodId:"50363440",label:"Uva llama red en lata o en frasco"},
    {prodId:"50363441",label:"Uva galaxy sin pepa en lata o en frasco"},
    {prodId:"50363442",label:"Uva gamay en lata o en frasco"},
    {prodId:"50363443",label:"Uva dorada en lata o en frasco"},
    {prodId:"50363444",label:"Uva haneypot o honeypot en lata o en frasco"},
    {prodId:"50363445",label:"Uva italia en lata o en frasco"},
    {prodId:"50363446",label:"Uva jade sin pepa en lata o en frasco"},
    {prodId:"50363447",label:"Uva jubilee en lata o en frasco"},
    {prodId:"50363448",label:"Uva king ruby en lata o en frasco"},
    {prodId:"50363449",label:"Uva kyoho en lata o en frasco"},
    {prodId:"50363450",label:"Uva la rochelle en lata o en frasco"},
    {prodId:"50363451",label:"Uva dedo de dama en lata o en frasco"},
    {prodId:"50363452",label:"Uva late sin pepa en lata o en frasco"},
    {prodId:"50363453",label:"Uva majestuoso sin pepa en lata o en frasco"},
    {prodId:"50363454",label:"Uva malaga en lata o en frasco"},
    {prodId:"50363455",label:"Uva marro sin pepa en lata o en frasco"},
    {prodId:"50363456",label:"Uva muscadine en lata o en frasco"},
    {prodId:"50363457",label:"Uva muscat llama en lata o en frasco"},
    {prodId:"50363458",label:"Uva muscat en lata o en frasco"},
    {prodId:"50363459",label:"Uva muscat sin papa en lata o en frasco"},
    {prodId:"50363460",label:"Uva napoleon en lata o en frasco"},
    {prodId:"50363461",label:"Uva nigeria en lata o en frasco"},
    {prodId:"50363462",label:"Uva new cross en lata o en frasco"},
    {prodId:"50363463",label:"Uva nibella en lata o en frasco"},
    {prodId:"50363464",label:"Uva niagara en lata o en frasco"},
    {prodId:"50363465",label:"Uva olivette en lata o en frasco"},
    {prodId:"50363466",label:"Uva perlette en lata o en frasco"},
    {prodId:"50363467",label:"Uva perlon en lata o en frasco"},
    {prodId:"50363468",label:"Uva prima negra sin pepa en lata o en frasco"},
    {prodId:"50363469",label:"Uva princesa en lata o en frasco"},
    {prodId:"50363470",label:"Uva reina en lata o en frasco"},
    {prodId:"50363471",label:"Uva roja blush en lata o en frasco"},
    {prodId:"50363472",label:"Uva roja globo en lata o en frasco"},
    {prodId:"50363473",label:"Uva roja malaga en lata o en frasco"},
    {prodId:"50363474",label:"Uva roja sin pepa en lata o en frasco"},
    {prodId:"50363475",label:"Uva regina en lata o en frasco"},
    {prodId:"50363476",label:"Uva ribier en lata o en frasco"},
    {prodId:"50363477",label:"Uva rosita en lata o en frasco"},
    {prodId:"50363478",label:"Uva rouge en lata o en frasco"},
    {prodId:"50363479",label:"Uva royal negra sin pepa en lata o en frasco"},
    {prodId:"50363480",label:"Uva ruby roja sin pepa en lata o en frasco"},
    {prodId:"50363481",label:"Uva ruby sin pepa en lata o en frasco"},
    {prodId:"50363482",label:"Uva scarlet royal en lata o en frasco"},
    {prodId:"50363483",label:"Uva scuppemong en lata o en frasco"},
    {prodId:"50363484",label:"Uva sugarose en lata o en frasco"},
    {prodId:"50363485",label:"Uva sugartirteen en lata o en frasco"},
    {prodId:"50363486",label:"Uva sugraone en lata o en frasco"},
    {prodId:"50363487",label:"Uva sugrasixteen en lata o en frasco"},
    {prodId:"50363488",label:"Uva sultana sun roja en lata o en frasco"},
    {prodId:"50363489",label:"Uva verano royal en lata o en frasco"},
    {prodId:"50363490",label:"Uva atardecer en lata o en frasco"},
    {prodId:"50363491",label:"Uva superior sin pepa en lata o en frasco"},
    {prodId:"50363492",label:"Uva thompson sin pepa en lata o en frasco"},
    {prodId:"50363493",label:"Uva tokaylpinot gris en lata o en frasco"},
    {prodId:"50363494",label:"Uva waltman cross en lata o en frasco"},
    {prodId:"50363495",label:"Uva blanca sin pepa en lata o en frasco"},
    {prodId:"50363496",label:"Uva zante current en lata o en frasco"},
    {prodId:"50363500",label:"Uvas pasas en lata o en frasco"},
    {prodId:"50363501",label:"Uva corinth negra en lata o en frasco"},
    {prodId:"50363502",label:"Uva canner en lata o en frasco"},
    {prodId:"50363503",label:"Uva dovine en lata o en frasco"},
    {prodId:"50363504",label:"Uva fiesta en lata o en frasco"},
    {prodId:"50363505",label:"Uva selme pete en lata o en frasco"},
    {prodId:"50363506",label:"Uva sultana en lata o en frasco"},
    {prodId:"50363600",label:"Uvas para vino en lata o en frasco"},
    {prodId:"50363601",label:"Uva alicante bouschet en lata o en frasco"},
    {prodId:"50363602",label:"Uva barbera en lata o en frasco"},
    {prodId:"50363603",label:"Uva burger en lata o en frasco"},
    {prodId:"50363604",label:"Uva cabemet franc en lata o en frasco"},
    {prodId:"50363605",label:"Uva cabenet sauvignon en lata o en frasco"},
    {prodId:"50363606",label:"Uva carignane en lata o en frasco"},
    {prodId:"50363607",label:"Uva camelian en lata o en frasco"},
    {prodId:"50363608",label:"Uva cattarrato en lata o en frasco"},
    {prodId:"50363609",label:"Uva centurian en lata o en frasco"},
    {prodId:"50363610",label:"Uva charbono en lata o en frasco"},
    {prodId:"50363611",label:"Uva chardonnay en lata o en frasco"},
    {prodId:"50363612",label:"Uva chenin blanco en lata o en frasco"},
    {prodId:"50363613",label:"Uva cinsaut en lata o en frasco"},
    {prodId:"50363614",label:"Uva dolcetto en lata o en frasco"},
    {prodId:"50363615",label:"Uva emerald riesling en lata o en frasco"},
    {prodId:"50363616",label:"Uva frances colombard en lata o en frasco"},
    {prodId:"50363617",label:"Uva granny nap en lata o en frasco"},
    {prodId:"50363618",label:"Uva gamay beaujolais en lata o en frasco"},
    {prodId:"50363619",label:"Uva gewurztraminer en lata o en frasco"},
    {prodId:"50363620",label:"Uva grnache en lata o en frasco"},
    {prodId:"50363621",label:"Uva grinache blanc en lata o en frasco"},
    {prodId:"50363622",label:"Uva lagrein en lata o en frasco"},
    {prodId:"50363623",label:"Uva lambruso en lata o en frasco"},
    {prodId:"50363624",label:"Uva malbec en lata o en frasco"},
    {prodId:"50363625",label:"Uva malvasía bianca en lata o en frasco"},
    {prodId:"50363626",label:"Uva marsanne en lata o en frasco"},
    {prodId:"50363627",label:"Uva matano en lata o en frasco"},
    {prodId:"50363628",label:"Uva merlot en lata o en frasco"},
    {prodId:"50363629",label:"Uva meunier en lata o en frasco"},
    {prodId:"50363630",label:"Uva missiom en lata o en frasco"},
    {prodId:"50363631",label:"Uva montepulceano en lata o en frasco"},
    {prodId:"50363632",label:"Uva muscat blanc en lata o en frasco"},
    {prodId:"50363633",label:"Uva muscat hamburg en lata o en frasco"},
    {prodId:"50363634",label:"Uva muscat alexandria en lata o en frasco"},
    {prodId:"50363635",label:"Uva muscat naranja en lata o en frasco"},
    {prodId:"50363636",label:"Uva nebbiolo en lata o en frasco"},
    {prodId:"50363637",label:"Uva palomino en lata o en frasco"},
    {prodId:"50363638",label:"Uva petit verdor en lata o en frasco"},
    {prodId:"50363639",label:"Uva petit sirah en lata o en frasco"},
    {prodId:"50363640",label:"Uva pinot blanc en lata o en frasco"},
    {prodId:"50363641",label:"Uva pinot gris en lata o en frasco"},
    {prodId:"50363642",label:"Uva pinot noir en lata o en frasco"},
    {prodId:"50363643",label:"Uva primitivo en lata o en frasco"},
    {prodId:"50363644",label:"Uva roussane en lata o en frasco"},
    {prodId:"50363645",label:"Uva real  en lata o en frasco"},
    {prodId:"50363646",label:"Uva rubired en lata o en frasco"},
    {prodId:"50363647",label:"Uva ruby cabenet en lata o en frasco"},
    {prodId:"50363648",label:"Uva salvador en lata o en frasco"},
    {prodId:"50363649",label:"Uva sangiovese en lata o en frasco"},
    {prodId:"50363650",label:"Uva sauvignon blanc en lata o en frasco"},
    {prodId:"50363651",label:"Uva sauvignon musque en lata o en frasco"},
    {prodId:"50363652",label:"Uva semillon en lata o en frasco"},
    {prodId:"50363653",label:"Uva souzao en lata o en frasco"},
    {prodId:"50363654",label:"Uva st emilion en lata o en frasco"},
    {prodId:"50363655",label:"Uva symohony en lata o en frasco"},
    {prodId:"50363656",label:"Uva syrah  en lata o en frasco"},
    {prodId:"50363657",label:"Uva tannat en lata o en frasco"},
    {prodId:"50363658",label:"Uva tempranillo en lata o en frasco"},
    {prodId:"50363659",label:"Uvas teroldego en lata o en frasco"},
    {prodId:"50363660",label:"Uvas tocai friulano en lata o en frasco"},
    {prodId:"50363661",label:"Uvas touriga nacional en lata o en frasco"},
    {prodId:"50363662",label:"Uvas triplett blanc en lata o en frasco"},
    {prodId:"50363663",label:"Uvas viogner en lata o en frasco"},
    {prodId:"50363664",label:"Uvas white riesling en lata o en frasco"},
    {prodId:"50363665",label:"Uvas zinfandel en lata o en frasco"},
    {prodId:"50363800",label:"Arándanos Huckleberry en lata o en frasco"},
    {prodId:"50363801",label:"Arándano invierno negro en lata o en frasco"},
    {prodId:"50363802",label:"Arándano cascada en lata o en frasco"},
    {prodId:"50363803",label:"Arándano enano en lata o en frasco"},
    {prodId:"50363804",label:"Arándano montaña en lata o en frasco"},
    {prodId:"50363805",label:"Arándano rojo en lata o en frasco"},
    {prodId:"50363900",label:"Kiwis en lata o en frasco"},
    {prodId:"50363901",label:"Fruta de kiwi ananasnaja en lata o en frasco"},
    {prodId:"50363902",label:"Fruta de kiwi belleza del ártico en lata o en frasco"},
    {prodId:"50363903",label:"Fruta de kiwi blake en lata o en frasco"},
    {prodId:"50363904",label:"Fruta de kiwi hayward en lata o en frasco"},
    {prodId:"50363905",label:"Fruta de kiwi issai en lata o en frasco"},
    {prodId:"50363906",label:"Fruta de kiwi siberiana en lata o en frasco"},
    {prodId:"50364000",label:"Naranjos Chinos (kumquats) en lata o en frasco"},
    {prodId:"50364001",label:"Naranjo chino hong kong en lata o en frasco"},
    {prodId:"50364002",label:"Naranjo chino limequat en lata o en frasco"},
    {prodId:"50364003",label:"Naranjo long fruit en lata o en frasco"},
    {prodId:"50364004",label:"Naranjo chino malayan en lata o en frasco"},
    {prodId:"50364005",label:"Naranjo meiwa en lata o en frasco"},
    {prodId:"50364006",label:"Naranja chino nagami en lata o en frasco"},
    {prodId:"50364100",label:"Limones en lata o en frasco"},
    {prodId:"50364101",label:"Limón baboon en lata o en frasco"},
    {prodId:"50364102",label:"Limón bearss sicilian en lata o en frasco"},
    {prodId:"50364103",label:"Limón cameron highland en lata o en frasco"},
    {prodId:"50364104",label:"Limón escondido en lata o en frasco"},
    {prodId:"50364105",label:"Limón eureka en lata o en frasco"},
    {prodId:"50364106",label:"Limón lisbon en lata o en frasco"},
    {prodId:"50364107",label:"Limón meyer en lata o en frasco"},
    {prodId:"50364108",label:"Limón volkaner en lata o en frasco"},
    {prodId:"50364200",label:"Limas en lata o en frasco"},
    {prodId:"50364201",label:"Lima limón indian sweet en lata o en frasco"},
    {prodId:"50364202",label:"Lima limón en lata o en frasco"},
    {prodId:"50364203",label:"Lima limón mandarin en lata o en frasco"},
    {prodId:"50364204",label:"Lima limón philippine en lata o en frasco"},
    {prodId:"50364205",label:"Lima limón tahitian en lata o en frasco"},
    {prodId:"50364206",label:"Lima limón bearss en lata o en frasco"},
    {prodId:"50364207",label:"Lima limón persa en lata o en frasco"},
    {prodId:"50364208",label:"Lima limón sin pepas en lata o en frasco"},
    {prodId:"50364300",label:"Nísperos en lata o en frasco"},
    {prodId:"50364301",label:"Níspero advance en lata o en frasco"},
    {prodId:"50364302",label:"Níspero benlehr en lata o en frasco"},
    {prodId:"50364303",label:"Níspero big jim en lata o en frasco"},
    {prodId:"50364304",label:"Níspero champagne en lata o en frasco"},
    {prodId:"50364305",label:"Níspero early rojo en lata o en frasco"},
    {prodId:"50364306",label:"Níspero nuget dorado en lata o en frasco"},
    {prodId:"50364307",label:"Níspero herd's mammoth en lata o en frasco"},
    {prodId:"50364308",label:"Níspero mogi en lata o en frasco"},
    {prodId:"50364309",label:"Níspero mrs cooksey en lata o en frasco"},
    {prodId:"50364310",label:"Níspero fresa en lata o en frasco"},
    {prodId:"50364311",label:"Níspero tanaka en lata o en frasco"},
    {prodId:"50364312",label:"Níspero victoria vista blanco en lata o en frasco"},
    {prodId:"50364313",label:"Níspero wolfe en lata o en frasco"},
    {prodId:"50364400",label:"Mandarinas en lata o en frasco"},
    {prodId:"50364401",label:"Naranjas clauselinas en lata o en frasco"},
    {prodId:"50364402",label:"Mandarinas clementinas en lata o en frasco"},
    {prodId:"50364403",label:"Naranja mandarina cleopatra"},
    {prodId:"50364404",label:"Mandarina dancy en lata o en frasco"},
    {prodId:"50364405",label:"Naranja ellensday en lata o en frasco"},
    {prodId:"50364406",label:"Naranja fairchild en lata o en frasco"},
    {prodId:"50364407",label:"Naranja fallglo en lata o en frasco"},
    {prodId:"50364408",label:"Naranja fortuna en lata o en frasco"},
    {prodId:"50364409",label:"Mandarina naranja fremont en lata o en frasco"},
    {prodId:"50364410",label:"Naranja fremont en lata o en frasco"},
    {prodId:"50364411",label:"Naranja nuget dorada en lata o en frasco"},
    {prodId:"50364412",label:"Mandarina naranja miel en lata o en frasco"},
    {prodId:"50364413",label:"Naranja miel en lata o en frasco"},
    {prodId:"50364414",label:"Mandarina miel en lata o en frasco"},
    {prodId:"50364415",label:"Natanja tanjelo honebelle en lata o en frasco"},
    {prodId:"50364416",label:"Naranja mandarina rey en lata o en frasco"},
    {prodId:"50364417",label:"Naranja kinnow en lata o en frasco"},
    {prodId:"50364418",label:"Naranja andarinalee en lata o en frasco"},
    {prodId:"50364419",label:"Naranja makokkee en lata o en frasco"},
    {prodId:"50364420",label:"Naranja malvasion en lata o en frasco"},
    {prodId:"50364421",label:"Naranja mandarina mediterránea en lata o en frasco"},
    {prodId:"50364422",label:"Naranja tangelo minneola en lata o en frasco"},
    {prodId:"50364423",label:"Naranja monica en lata o en frasco"},
    {prodId:"50364424",label:"Naranja murcott miel en lata o en frasco"},
    {prodId:"50364425",label:"Naranja murcott tangors en lata o en frasco"},
    {prodId:"50364426",label:"Naranja mandarina natsudaidai  en lata o en frasco"},
    {prodId:"50364427",label:"Naranja mandarina natsumikan en lata o en frasco"},
    {prodId:"50364428",label:"Naranja tanjelo nocatee en lata o en frasco"},
    {prodId:"50364429",label:"Naranja tanjelo orlando en lata o en frasco"},
    {prodId:"50364430",label:"Mandarina ortanique en lata o en frasco"},
    {prodId:"50364431",label:"Naranja mandarina pagina en lata o en frasco"},
    {prodId:"50364432",label:"Naranja pixie en lata o en frasco"},
    {prodId:"50364433",label:"Naranja mandarina ponkan bantangas en lata o en frasco"},
    {prodId:"50364434",label:"Naranja reina en lata o en frasco"},
    {prodId:"50364435",label:"Naranja robinson en lata o en frasco"},
    {prodId:"50364436",label:"Naranja saltenitas en lata o en frasco"},
    {prodId:"50364437",label:"Naranja tangelo sampson en lata o en frasco"},
    {prodId:"50364438",label:"Naranja mandarina satsuma en lata o en frasco"},
    {prodId:"50364439",label:"Naranja mandarina sunburst en lata o en frasco"},
    {prodId:"50364440",label:"Tangelo en lata o en frasco"},
    {prodId:"50364441",label:"Naranja mandarina en lata o en frasco"},
    {prodId:"50364442",label:"Naranja templo en lata o en frasco"},
    {prodId:"50364443",label:"Naranja thornton en lata o en frasco"},
    {prodId:"50364444",label:"Mandarina wekiwa en lata o en frasco"},
    {prodId:"50364445",label:"Mandarina wilkins en lata o en frasco"},
    {prodId:"50364446",label:"Mandarina willowleaf mediterránea en lata o en frasco"},
    {prodId:"50364600",label:"Melones en lata o en frasco"},
    {prodId:"50364601",label:"Melon  todo dulce en lata o en frasco"},
    {prodId:"50364602",label:"Melón athens en lata o en frasco"},
    {prodId:"50364603",label:"Melón diamante negro en lata o en frasco"},
    {prodId:"50364604",label:"Melón cal dulce en lata o en frasco"},
    {prodId:"50364605",label:"Melons cantaloupe en lata o en frasco"},
    {prodId:"50364606",label:"Melón carnical en lata o en frasco"},
    {prodId:"50364607",label:"Melón casaba en lata o en frasco"},
    {prodId:"50364608",label:"Melón cavaillon en lata o en frasco"},
    {prodId:"50364609",label:"Melón charentais en lata o en frasco"},
    {prodId:"50364610",label:"Sandia charleston gray en lata o en frasco"},
    {prodId:"50364611",label:"Melón crenshaw en lata o en frasco"},
    {prodId:"50364612",label:"Melón crimson sweet en lata o en frasco"},
    {prodId:"50364613",label:"Melón dixie lee en lata o en frasco"},
    {prodId:"50364614",label:"Melón eclipse en lata o en frasco"},
    {prodId:"50364615",label:"Melón ein dór  en lata o en frasco"},
    {prodId:"50364616",label:"Melón fiesta en lata o en frasco"},
    {prodId:"50364617",label:"Melón galia en lata o en frasco"},
    {prodId:"50364618",label:"Melón gaya en lata o en frasco"},
    {prodId:"50364619",label:"Melón hami en lata o en frasco"},
    {prodId:"50364620",label:"Melón miel dew en lata o en frasco"},
    {prodId:"50364621",label:"Melón hielo en lata o en frasco"},
    {prodId:"50364622",label:"Melón ida orgullo en lata o en frasco"},
    {prodId:"50364623",label:"Melón juan canary en lata o en frasco"},
    {prodId:"50364624",label:"Melón jubileo en lata o en frasco"},
    {prodId:"50364625",label:"Melón jubilación en lata o en frasco"},
    {prodId:"50364626",label:"Melón kaki / kakri en lata o en frasco"},
    {prodId:"50364627",label:"Melón kiwano en lata o en frasco"},
    {prodId:"50364628",label:"Melon koreano en lata o en frasco"},
    {prodId:"50364629",label:"Melóngris largo grey en lata o en frasco"},
    {prodId:"50364630",label:"Melón mayan en lata o en frasco"},
    {prodId:"50364631",label:"Melón micky lee en lata o en frasco"},
    {prodId:"50364632",label:"Melón mirage en lata o en frasco"},
    {prodId:"50364633",label:"Sandia luna y estrellas en lata o en frasco"},
    {prodId:"50364634",label:"Melón ogen en lata o en frasco"},
    {prodId:"50364635",label:"Melón patriota en lata o en frasco"},
    {prodId:"50364636",label:"Melón pavo real en lata o en frasco"},
    {prodId:"50364637",label:"Melón pepino en lata o en frasco"},
    {prodId:"50364638",label:"Melón persa en lata o en frasco"},
    {prodId:"50364639",label:"Melón picnic en lata o en frasco"},
    {prodId:"50364640",label:"Melón piel de sapo en lata o en frasco"},
    {prodId:"50364641",label:"Melón piña en lata o en frasco"},
    {prodId:"50364642",label:"Melón quetzali en lata o en frasco"},
    {prodId:"50364643",label:"Melón goblin rojo en lata o en frasco"},
    {prodId:"50364644",label:"Melón regency en lata o en frasco"},
    {prodId:"50364645",label:"Melón royal mejestic en lata o en frasco"},
    {prodId:"50364646",label:"Melón royal star en lata o en frasco"},
    {prodId:"50364647",label:"Melón dulce royal en lata o en frasco"},
    {prodId:"50364648",label:"Malon santa claus en lata o en frasco"},
    {prodId:"50364649",label:"Melón sharlyn en lata o en frasco"},
    {prodId:"50364650",label:"Melón español en lata o en frasco"},
    {prodId:"50364651",label:"Melón sprite en lata o en frasco"},
    {prodId:"50364652",label:"Melón starbright en lata o en frasco"},
    {prodId:"50364653",label:"Melón stars and stripes en lata o en frasco"},
    {prodId:"50364654",label:"Melón bebe de azúcar en lata o en frasco"},
    {prodId:"50364655",label:"Sandia bebe de azúcar en lata o en frasco"},
    {prodId:"50364656",label:"Melón dulce sol en lata o en frasco"},
    {prodId:"50364657",label:"Sandia corazón dulce sin pepa en lata o en frasco"},
    {prodId:"50364658",label:"Melón tentación en lata o en frasco"},
    {prodId:"50364659",label:"Melón tigre bebe en lata o en frasco"},
    {prodId:"50364660",label:"Melón tuscan tipo en lata o en frasco"},
    {prodId:"50364661",label:"Sandia bebe amarillo en lata o en frasco"},
    {prodId:"50364700",label:"Moras mulberry en lata o en frasco"},
    {prodId:"50364701",label:"Mora negra en lata o en frasco"},
    {prodId:"50364702",label:"Mora blanca en lata o en frasco"},
    {prodId:"50364800",label:"Mirtos en lata o en frasco"},
    {prodId:"50364801",label:"Mirto bog en lata o en frasco"},
    {prodId:"50364900",label:"Nectarinas en lata o en frasco"},
    {prodId:"50364901",label:"Durazno abril glo en lata o en frasco"},
    {prodId:"50364902",label:"Durazno arctic mist en lata o en frasco"},
    {prodId:"50364903",label:"Durazno artic snow en lata o en frasco"},
    {prodId:"50364904",label:"Durazno artic star en lata o en frasco"},
    {prodId:"50364905",label:"Durazno artic dulce en lata o en frasco"},
    {prodId:"50364906",label:"Durazno artic glo en lata o en frasco"},
    {prodId:"50364907",label:"Durazno fuego de agosto en lata o en frasco"},
    {prodId:"50364908",label:"Durazno  perla de agosto en lata o en frasco"},
    {prodId:"50364909",label:"Durazno agosto rojo en lata o en frasco"},
    {prodId:"50364910",label:"Durazno estrella de agosto en lata o en frasco"},
    {prodId:"50364911",label:"Durazno grande john en lata o en frasco"},
    {prodId:"50364912",label:"Durazno perla brillante en lata o en frasco"},
    {prodId:"50364913",label:"Durazno diamante brillante en lata o en frasco"},
    {prodId:"50364914",label:"Durazno diamante rayo en lata o en frasco"},
    {prodId:"50364915",label:"Durazno earligo en lata o en frasco"},
    {prodId:"50364916",label:"Durazno diamante temprano en lata o en frasco"},
    {prodId:"50364917",label:"Durazno fairlane en lata o en frasco"},
    {prodId:"50364918",label:"Durazno fantasia en lata o en frasco"},
    {prodId:"50364919",label:"Durazno perla fuego en lata o en frasco"},
    {prodId:"50364920",label:"Durazno fuego dulce en lata o en frasco"},
    {prodId:"50364921",label:"Durazno flamekist en lata o en frasco"},
    {prodId:"50364922",label:"Durazno tipo plano en lata o en frasco"},
    {prodId:"50364923",label:"Durazno delicia de jardín en lata o en frasco"},
    {prodId:"50364924",label:"Durazno mina de oro en lata o en frasco"},
    {prodId:"50364925",label:"Durazno perla grande en lata o en frasco"},
    {prodId:"50364926",label:"Durazno hardired en lata o en frasco"},
    {prodId:"50364927",label:"Durazno miel de fuego en lata o en frasco"},
    {prodId:"50364928",label:"Durazno julio rojo en lata o en frasco"},
    {prodId:"50364929",label:"Durazno kay perla en lata o en frasco"},
    {prodId:"50364930",label:"Durazno key dulce en lata o en frasco"},
    {prodId:"50364931",label:"Durazno diamante mayo en lata o en frasco"},
    {prodId:"50364932",label:"Durazno mayfire en lata o en frasco"},
    {prodId:"50364933",label:"Durazno mayglo en lata o en frasco"},
    {prodId:"50364934",label:"Durazno mericrest en lata o en frasco"},
    {prodId:"50364935",label:"Durazno diamante rojo en lata o en frasco"},
    {prodId:"50364936",label:"Durazno oro rojo en lata o en frasco"},
    {prodId:"50364937",label:"Durazno jim rojo en lata o en frasco"},
    {prodId:"50364938",label:"Durazno roy rojo en lata o en frasco"},
    {prodId:"50364939",label:"Durazno rio rojo en lata o en frasco"},
    {prodId:"50364940",label:"Durazno diamante de rosa en lata o en frasco"},
    {prodId:"50364941",label:"Durazno rotal glo en lata o en frasco"},
    {prodId:"50364942",label:"Durazno diamante ryby en lata o en frasco"},
    {prodId:"50364943",label:"Durazno ruby dulce en lata o en frasco"},
    {prodId:"50364944",label:"Durazno joya ruddy en lata o en frasco"},
    {prodId:"50364945",label:"Durazno septiembre rojo en lata o en frasco"},
    {prodId:"50364946",label:"Durazno reina de nieve en lata o en frasco"},
    {prodId:"50364947",label:"Durazno primavera clara en lata o en frasco"},
    {prodId:"50364948",label:"Durazno primavera roja en lata o en frasco"},
    {prodId:"50364949",label:"Durazno verano rojiso en lata o en frasco"},
    {prodId:"50364950",label:"Durazno verano claro en lata o en frasco"},
    {prodId:"50364951",label:"Durazno verano diamante en lata o en frasco"},
    {prodId:"50364952",label:"Durazno verano fuego en lata o en frasco"},
    {prodId:"50364953",label:"Durazno verano grande en lata o en frasco"},
    {prodId:"50364954",label:"Durazno sunglo en lata o en frasco"},
    {prodId:"50364955",label:"Durazno fuego zee en lata o en frasco"},
    {prodId:"50364956",label:"Durazno zee glo en lata o en frasco"},
    {prodId:"50364957",label:"Durazno zeegrand en lata o en frasco"},
    {prodId:"50365000",label:"Naranjas en lata o en frasco"},
    {prodId:"50365001",label:"Naranja áfrica agria en lata o en frasco"},
    {prodId:"50365002",label:"Naranja dulce amber en lata o en frasco"},
    {prodId:"50365003",label:"Naranja argentina agria en lata o en frasco"},
    {prodId:"50365004",label:"Naranja bahianinha en lata o en frasco"},
    {prodId:"50365005",label:"Naranja bergamot en lata o en frasco"},
    {prodId:"50365006",label:"Naranja berna en lata o en frasco"},
    {prodId:"50365007",label:"Naranja bigaradier apepu en lata o en frasco"},
    {prodId:"50365008",label:"Naranja agria dulce daidai en lata o en frasco"},
    {prodId:"50365009",label:"Naranja mono en lata o en frasco"},
    {prodId:"50365010",label:"Naranja sangre en lata o en frasco"},
    {prodId:"50365011",label:"Naranja california navel en lata o en frasco"},
    {prodId:"50365012",label:"Naranja cara cara en lata o en frasco"},
    {prodId:"50365013",label:"Naranja chinotto en lata o en frasco"},
    {prodId:"50365014",label:"Naranja sueño nevel en lata o en frasco"},
    {prodId:"50365015",label:"Naranja gou tou en lata o en frasco"},
    {prodId:"50365016",label:"Naranja hamlin en lata o en frasco"},
    {prodId:"50365017",label:"Naranja jaffa en lata o en frasco"},
    {prodId:"50365018",label:"Naranja jincheng en lata o en frasco"},
    {prodId:"50365019",label:"Naranja k-temprano en lata o en frasco"},
    {prodId:"50365020",label:"Naranja kona en lata o en frasco"},
    {prodId:"50365021",label:"Naranja navel tarde en lata o en frasco en lata o en frasco"},
    {prodId:"50365022",label:"Naranja valencia tarde en lata o en frasco"},
    {prodId:"50365023",label:"Naranja limequat en lata o en frasco"},
    {prodId:"50365024",label:"Naranja mar en lata o en frasco"},
    {prodId:"50365025",label:"Naranja malegold en lata o en frasco"},
    {prodId:"50365026",label:"Naranja moro en lata o en frasco"},
    {prodId:"50365027",label:"Naranja moro tabaco en lata o en frasco"},
    {prodId:"50365028",label:"Naranja navel en lata o en frasco"},
    {prodId:"50365029",label:"Naranja navelina en lata o en frasco"},
    {prodId:"50365030",label:"Naranja oro blanco en lata o en frasco"},
    {prodId:"50365031",label:"Naranja osceola en lata o en frasco"},
    {prodId:"50365032",label:"Naranja parson carmelito en lata o en frasco"},
    {prodId:"50365033",label:"Naranja pera en lata o en frasco"},
    {prodId:"50365034",label:"Naranjo pummulo en lata o en frasco"},
    {prodId:"50365035",label:"Naranja rhode rojo en lata o en frasco"},
    {prodId:"50365036",label:"Naranja roble en lata o en frasco"},
    {prodId:"50365037",label:"Naranja salustianas en lata o en frasco"},
    {prodId:"50365038",label:"Naranja sanguine en lata o en frasco"},
    {prodId:"50365039",label:"Naranja sanguinelli en lata o en frasco"},
    {prodId:"50365040",label:"Naranja cevilla en lata o en frasco"},
    {prodId:"50365041",label:"Naranja shamouti jaffa en lata o en frasco"},
    {prodId:"50365042",label:"Naranja tunis en lata o en frasco"},
    {prodId:"50365043",label:"Naranja valencia en lata o en frasco"},
    {prodId:"50365044",label:"Naranja washington navel en lata o en frasco"},
    {prodId:"50365100",label:"Papayas en lata o en frasco"},
    {prodId:"50365101",label:"Papaya verde cocinar en lata o en frasco"},
    {prodId:"50365102",label:"Papaya maradol en lata o en frasco"},
    {prodId:"50365103",label:"Papaya amarillo mexicano en lata o en frasco"},
    {prodId:"50365104",label:"Papaya montaña en lata o en frasco"},
    {prodId:"50365105",label:"Papaya solo en lata o en frasco"},
    {prodId:"50365106",label:"Papaya tainung en lata o en frasco"},
    {prodId:"50365200",label:"Maracuyás en lata o en frasco"},
    {prodId:"50365201",label:"Maracuyá banana en lata o en frasco"},
    {prodId:"50365202",label:"Maracuyá flor azul en lata o en frasco"},
    {prodId:"50365203",label:"Maracuyá crackerjack en lata o en frasco"},
    {prodId:"50365204",label:"Maracuyá granadilla gigante en lata o en frasco"},
    {prodId:"50365205",label:"Maracuyá granadilla dorada en lata o en frasco"},
    {prodId:"50365206",label:"Maracuyá maypops en lata o en frasco"},
    {prodId:"50365207",label:"Maracuyá roja en lata o en frasco"},
    {prodId:"50365208",label:"Maracuyá granadilla dulce en lata o en frasco"},
    {prodId:"50365209",label:"Maracuyá sandia en lata o en frasco"},
    {prodId:"50365210",label:"Maracuyá  wing- steam en lata o en frasco"},
    {prodId:"50365400",label:"Peras en lata o en frasco"},
    {prodId:"50365401",label:"Peras abate fetel en lata o en frasco"},
    {prodId:"50365402",label:"Peras anjou en lata o en frasco"},
    {prodId:"50365403",label:"Pera asiática en lata o en frasco"},
    {prodId:"50365404",label:"Pera bartlett en lata o en frasco"},
    {prodId:"50365405",label:"Pera best ever en lata o en frasco"},
    {prodId:"50365406",label:"Pera beth en lata o en frasco"},
    {prodId:"50365407",label:"Pera beurre en lata o en frasco"},
    {prodId:"50365408",label:"Pera bosc en lata o en frasco"},
    {prodId:"50365409",label:"Pera clapp favorita en lata o en frasco"},
    {prodId:"50365410",label:"Pera comice en lata o en frasco"},
    {prodId:"50365411",label:"Pera concorde en lata o en frasco"},
    {prodId:"50365412",label:"Pera conference en lata o en frasco"},
    {prodId:"50365413",label:"Pera crimson rojo en lata o en frasco"},
    {prodId:"50365414",label:"Peras d' anjou en lata o en frasco"},
    {prodId:"50365415",label:"Pera dr jules guyot en lata o en frasco"},
    {prodId:"50365416",label:"Peras early en lata o en frasco"},
    {prodId:"50365417",label:"Peras emperador carmelito en lata o en frasco"},
    {prodId:"50365418",label:"Peras forelle en lata o en frasco"},
    {prodId:"50365419",label:"Pera mantequilla francesa en lata o en frasco"},
    {prodId:"50365420",label:"Pera glou morceau en lata o en frasco"},
    {prodId:"50365421",label:"Pera hosui en lata o en frasco"},
    {prodId:"50365422",label:"Pera mantequilla italiana en lata o en frasco"},
    {prodId:"50365423",label:"Pera jargonelle en lata o en frasco"},
    {prodId:"50365424",label:"Pera juno en lata o en frasco"},
    {prodId:"50365425",label:"Para kaiserlouise bonne de jersey en lata o en frasco"},
    {prodId:"50365426",label:"Pera keiffer en lata o en frasco"},
    {prodId:"50365427",label:"Pera rey royal en lata o en frasco"},
    {prodId:"50365428",label:"Pera limonera en lata o en frasco"},
    {prodId:"50365429",label:"Pera merton pride en lata o en frasco"},
    {prodId:"50365430",label:"Pera mauntain bartlette en lata o en frasco"},
    {prodId:"50365431",label:"Pera oliver de serrers en lata o en frasco"},
    {prodId:"50365432",label:"Pera onward en lata o en frasco"},
    {prodId:"50365433",label:"Pera packham's triumph en lata o en frasco"},
    {prodId:"50365434",label:"Pera paraíso en lata o en frasco"},
    {prodId:"50365435",label:"Pera passe crassane en lata o en frasco"},
    {prodId:"50365436",label:"Pera perry en lata o en frasco"},
    {prodId:"50365437",label:"Pera bartlette rojo en lata o en frasco"},
    {prodId:"50365438",label:"Pera dánjou  rojo en lata o en frasco"},
    {prodId:"50365439",label:"Pera rocha en lata o en frasco"},
    {prodId:"50365440",label:"Pera rosey rojo en lata o en frasco"},
    {prodId:"50365441",label:"Pera rosy roja en lata o en frasco"},
    {prodId:"50365442",label:"Pera majestuosa royal en lata o en frasco"},
    {prodId:"50365443",label:"Pera ruby rojo en lata o en frasco"},
    {prodId:"50365444",label:"Pera santa maria en lata o en frasco"},
    {prodId:"50365445",label:"Pera seckel en lata o en frasco"},
    {prodId:"50365446",label:"Pera sensación en lata o en frasco"},
    {prodId:"50365447",label:"Pera crimson estrella en lata o en frasco"},
    {prodId:"50365448",label:"Pera crimson stark en lata o en frasco"},
    {prodId:"50365449",label:"Pera bartlette de verano en lata o en frasco"},
    {prodId:"50365450",label:"Pera verano dorado en lata o en frasco"},
    {prodId:"50365451",label:"Pera sol dorado en lata o en frasco"},
    {prodId:"50365452",label:"Pera sunprite en lata o en frasco"},
    {prodId:"50365453",label:"Pera taylors dorado en lata o en frasco"},
    {prodId:"50365454",label:"Pera taylors rojo en lata o en frasco"},
    {prodId:"50365455",label:"Pera tientsin en lata o en frasco"},
    {prodId:"50365456",label:"Pera tosca en lata o en frasco"},
    {prodId:"50365457",label:"Pera warden  en lata o en frasco"},
    {prodId:"50365458",label:"Pera williams bon chretien en lata o en frasco"},
    {prodId:"50365459",label:"Pera williams en lata o en frasco"},
    {prodId:"50365460",label:"Pera nelis de invierno en lata o en frasco"},
    {prodId:"50365500",label:"Caquis en lata o en frasco"},
    {prodId:"50365501",label:"Caqui americano en lata o en frasco"},
    {prodId:"50365502",label:"Caqui sapote negro en lata o en frasco"},
    {prodId:"50365503",label:"Caqui chapote / negro en lata o en frasco"},
    {prodId:"50365504",label:"Caqui dátale ciruela en lata o en frasco"},
    {prodId:"50365505",label:"Caqui fuyu en lata o en frasco"},
    {prodId:"50365506",label:"Caqui gigante fuyu en lata o en frasco"},
    {prodId:"50365507",label:"Caqui hachiya en lata o en frasco"},
    {prodId:"50365508",label:"Caqui mantequilla / mabolo en lata o en frasco"},
    {prodId:"50365509",label:"Caqui príncipe ito en lata o en frasco"},
    {prodId:"50365510",label:"Caqui brillante royal en lata o en frasco"},
    {prodId:"50365511",label:"Caqui sharon en lata o en frasco"},
    {prodId:"50365512",label:"Caqui triumph en lata o en frasco"},
    {prodId:"50365700",label:"Ciruelos Damascos en lata o en frasco"},
    {prodId:"50365701",label:"Ciruelo / damasco negro kat en lata o en frasco"},
    {prodId:"50365702",label:"Ciruelo / damasco gusto azul en lata o en frasco"},
    {prodId:"50365703",label:"Ciruelo / damasco corazón de crimson en lata o en frasco"},
    {prodId:"50365704",label:"Ciruelo / damasco dapply dandy en lata o en frasco"},
    {prodId:"50365705",label:"Ciruelo / damasco dapple fuego en lata o en frasco"},
    {prodId:"50365706",label:"Ciruelo / damasco  dapple temprano en lata o en frasco"},
    {prodId:"50365707",label:"Ciruelo / damasco  caída de sabor en lata o en frasco"},
    {prodId:"50365708",label:"Ciruelo / damasco sabor de oro en lata o en frasco"},
    {prodId:"50365709",label:"Ciruelo / damasco sabor granada en lata o en frasco"},
    {prodId:"50365710",label:"Ciruelo / damasco sabor de corazón en lata o en frasco"},
    {prodId:"50365711",label:"Ciruelo / damasco joya de sabor en lata o en frasco"},
    {prodId:"50365712",label:"Ciruelo / damasco sabor de rey en lata o en frasco"},
    {prodId:"50365713",label:"Ciruelo / damasco sabor de reina en lata o en frasco"},
    {prodId:"50365714",label:"Ciruelo / damasco sabor supremo en lata o en frasco"},
    {prodId:"50365715",label:"Ciruelo / damasco sabor premio en lata o en frasco"},
    {prodId:"50365716",label:"Ciruelo / damasco saborella en lata o en frasco"},
    {prodId:"50365717",label:"Ciruelo / damasco saborrico en lata o en frasco"},
    {prodId:"50365718",label:"Ciruelo / damasco  sabor rosa en lata o en frasco"},
    {prodId:"50365719",label:"Ciruelo / damasco orgullo geo en lata o en frasco"},
    {prodId:"50365720",label:"Ciruelo / damasco kat rojo en lata o en frasco"},
    {prodId:"50365721",label:"Ciruelo / damasco premio royal en lata o en frasco"},
    {prodId:"50365722",label:"Ciruelo / damasco rosa sierra en lata o en frasco"},
    {prodId:"50365723",label:"Ciruelo / damasco geisha dulce en lata o en frasco"},
    {prodId:"50365900",label:"Granadas en lata o en frasco"},
    {prodId:"50365901",label:"Granada foothhill en lata o en frasco"},
    {prodId:"50365902",label:"Granada, granada en lata o en frasco"},
    {prodId:"50365903",label:"Granada rojo feliz en lata o en frasco"},
    {prodId:"50365904",label:"Granada nana en lata o en frasco"},
    {prodId:"50365905",label:"Granada rojo de pat en lata o en frasco"},
    {prodId:"50365906",label:"Granada pinkhan en lata o en frasco"},
    {prodId:"50365907",label:"Granada terciopelo morado en lata o en frasco"},
    {prodId:"50365908",label:"Granada grandioso en lata o en frasco"},
    {prodId:"50366000",label:"Pomelos en lata o en frasco"},
    {prodId:"50366001",label:"Pomelo chandler en lata o en frasco"},
    {prodId:"50366002",label:"Pomelo hirado butan en lata o en frasco"},
    {prodId:"50366003",label:"Pomelo liang ping yau en lata o en frasco"},
    {prodId:"50366004",label:"Pomelo panda wangi en lata o en frasco"},
    {prodId:"50366005",label:"Pomelo rosado en lata o en frasco"},
    {prodId:"50366006",label:"Pomelo shaddock rojo en lata o en frasco"},
    {prodId:"50366007",label:"Pomelo siamese dulce en lata o en frasco"},
    {prodId:"50366008",label:"Pomelo waingwright en lata o en frasco"},
    {prodId:"50366200",label:"Frambuesas en lata o en frasco"},
    {prodId:"50366201",label:"Frambuesa americana roja en lata o en frasco"},
    {prodId:"50366202",label:"Frambuesa bailey queensland en lata o en frasco"},
    {prodId:"50366203",label:"Frambuesa negra en lata o en frasco"},
    {prodId:"50366204",label:"Frambuesa oscura en lata o en frasco"},
    {prodId:"50366205",label:"Frambuesa deliciosa en lata o en frasco"},
    {prodId:"50366206",label:"Frambuesa enano focke en lata o en frasco"},
    {prodId:"50366207",label:"Frambuesa focke hojagris en lata o en frasco"},
    {prodId:"50366208",label:"Frambuesa focke fresa en lata o en frasco"},
    {prodId:"50366209",label:"Frambuesa  focke amarillo himalaya en lata o en frasco"},
    {prodId:"50366210",label:"Frambuesa dorado en lata o en frasco"},
    {prodId:"50366211",label:"Frambuesa gris nuevo mexico en lata o en frasco"},
    {prodId:"50366212",label:"Frambuesa jepson whitebark en lata o en frasco"},
    {prodId:"50366213",label:"Frambuesa kellogs san diego en lata o en frasco"},
    {prodId:"50366214",label:"Frambuesa leucodemis whitebark en lata o en frasco"},
    {prodId:"50366215",label:"Frambuesa munz cuyamaca en lata o en frasco"},
    {prodId:"50366216",label:"Frambuesa peck bartons en lata o en frasco"},
    {prodId:"50366217",label:"Frambuesa flor morada en lata o en frasco"},
    {prodId:"50366218",label:"Frambuesa roadside en lata o en frasco"},
    {prodId:"50366219",label:"Frambuesa san diego en lata o en frasco"},
    {prodId:"50366220",label:"Frambuesa nieve en lata o en frasco"},
    {prodId:"50366221",label:"Frambuesa pico de nieve en lata o en frasco"},
    {prodId:"50366222",label:"Frambuesa hoja de fresa en lata o en frasco"},
    {prodId:"50366223",label:"Frambuesa cultivo dulce en lata o en frasco"},
    {prodId:"50366224",label:"Frambuesa tor y gris whitebark en lata o en frasco"},
    {prodId:"50366225",label:"Frambuesa caribe en lata o en frasco"},
    {prodId:"50366226",label:"Frambuesa whitebark en lata o en frasco"},
    {prodId:"50366227",label:"Frambuesa vino en lata o en frasco"},
    {prodId:"50366228",label:"Frambuesa himalaya amarillo en lata o en frasco"},
    {prodId:"50366229",label:"Frambuesa yu-shan en lata o en frasco"},
    {prodId:"50366300",label:"Ruibarbos en lata o en frasco"},
    {prodId:"50366301",label:"Ruibarbo crimson rojo en lata o en frasco"},
    {prodId:"50366302",label:"Ruibarbo champagne temprana en lata o en frasco"},
    {prodId:"50366303",label:"Ruibarbo glasking perpetual  en lata o en frasco"},
    {prodId:"50366304",label:"Ruibarbo sutton en lata o en frasco"},
    {prodId:"50366305",label:"Ruibarbo timperley temprano en lata o en frasco"},
    {prodId:"50366306",label:"Ruibarbo valentina en lata o en frasco"},
    {prodId:"50366307",label:"Ruibarbo victoria en lata o en frasco"},
    {prodId:"50366308",label:"Ruibarbo zwolle de cemilla en lata o en frasco"},
    {prodId:"50366309",label:"Ruibarbo macdonald en lata o en frasco"},
    {prodId:"50366310",label:"Ruibarbo tilden en lata o en frasco"},
    {prodId:"50366400",label:"Escaramujos en lata o en frasco"},
    {prodId:"50366401",label:"Escaramujo rosa brier en lata o en frasco"},
    {prodId:"50366402",label:"Escaramujo rosa elegante en lata o en frasco"},
    {prodId:"50366403",label:"Escaramujo rosa rugosa en lata o en frasco"},
    {prodId:"50366404",label:"Escaramujo rosa burnet o scotch en lata o en frasco"},
    {prodId:"50366500",label:"Sapotes en lata o en frasco"},
    {prodId:"50366501",label:"Sapote blanco en lata o en frasco"},
    {prodId:"50366502",label:"Sapote negro en lata o en frasco"},
    {prodId:"50366600",label:"Bayas saskatoon en lata o en frasco"},
    {prodId:"50366601",label:"Baya mielmadera saskatoon en lata o en frasco"},
    {prodId:"50366602",label:"Baya northline saskatoon en lata o en frasco"},
    {prodId:"50366603",label:"Baya saskatoon ahumado en lata o en frasco"},
    {prodId:"50366604",label:"Baya saskatoon thiessen en lata o en frasco"},
    {prodId:"50366700",label:"Fresas en lata o en frasco"},
    {prodId:"50366701",label:"Fresa chandler en lata o en frasco"},
    {prodId:"50366702",label:"Fresa rumbo junio en lata o en frasco"},
    {prodId:"50366703",label:"Fresa rumbo siempre en lata o en frasco"},
    {prodId:"50367100",label:"Aronias en lata o en frasco"},
    {prodId:"50367101",label:"Aronia magia de otoño en lata o en frasco"},
    {prodId:"50367102",label:"Aronia brillantísima en lata o en frasco"},
    {prodId:"50367103",label:"Aronia nero en lata o en frasco"},
    {prodId:"50367104",label:"Aronia viquingo en lata o en frasco"},
    {prodId:"50371600",label:"Albaricoques orgánicos en lata o en frasco"},
    {prodId:"50371601",label:"Abaricoques ambercot orgánica en lata o en frasco"},
    {prodId:"50371602",label:"Albaricoques apache orgánica en lata o en frasco"},
    {prodId:"50371603",label:"Albaricoques brittany gold orgánica en lata o en frasco"},
    {prodId:"50371604",label:"Albaricoque black orgánica en lata o en frasco"},
    {prodId:"50371605",label:"Albaricoque blenheim orgánica en lata o en frasco"},
    {prodId:"50371606",label:"Albaricoque bonny orgánica en lata o en frasco"},
    {prodId:"50371607",label:"Albaricoque biluda orgánica en lata o en frasco"},
    {prodId:"50371608",label:"Albaricoque castlebrite orgánica en lata o en frasco"},
    {prodId:"50371609",label:"Albaricoque  clutha gold orgánica en lata o en frasco"},
    {prodId:"50371610",label:"Albaricoque clutha sun orgánica en lata o en frasco"},
    {prodId:"50371611",label:"Albaricoque derby royal orgánica en lata o en frasco"},
    {prodId:"50371612",label:"Albaricoque dina orgánica en lata o en frasco"},
    {prodId:"50371613",label:"Albaricoque earlicot orgánica en lata o en frasco"},
    {prodId:"50371614",label:"Albaricoque earliman orgánica en lata o en frasco"},
    {prodId:"50371615",label:"Albaricoque early bright orgánica en lata o en frasco"},
    {prodId:"50371616",label:"Albaricoque dorado en llamas orgánica en lata o en frasco"},
    {prodId:"50371617",label:"Albaricoque frenso orgánica en lata o en frasco"},
    {prodId:"50371618",label:"Albaricoque gold bright orgánica en lata o en frasco"},
    {prodId:"50371619",label:"Albaricoque goldbar orgánica en lata o en frasco"},
    {prodId:"50371620",label:"Albaricoque dulce dorado orgánica en lata o en frasco"},
    {prodId:"50371621",label:"Albaricoque rico dorado orgánica en lata o en frasco"},
    {prodId:"50371622",label:"Albaricoque helena orgánica en lata o en frasco"},
    {prodId:"50371623",label:"Albaricoque honeycot orgánica en lata o en frasco"},
    {prodId:"50371624",label:"Albaricoque imperial orgánica en lata o en frasco"},
    {prodId:"50371625",label:"Albaricoque jordanne orgánica en lata o en frasco"},
    {prodId:"50371626",label:"Albaricoque jumbo cot orgánica en lata o en frasco"},
    {prodId:"50371627",label:"Albaricoque kandy kot orgánica en lata o en frasco"},
    {prodId:"50371628",label:"Albaricoque katy orgánica en lata o en frasco"},
    {prodId:"50371629",label:"Albaricoque rey orgánica en lata o en frasco"},
    {prodId:"50371630",label:"Albaricoque lambertin orgánica en lata o en frasco"},
    {prodId:"50371631",label:"Albaricoque loma orgánica en lata o en frasco"},
    {prodId:"50371632",label:"Albaricoque lulu belle orgánica en lata o en frasco"},
    {prodId:"50371633",label:"Albaricoque modesto orgánica en lata o en frasco"},
    {prodId:"50371634",label:"Albaricoque moorpark orgánica en lata o en frasco"},
    {prodId:"50371635",label:"Albaricoque orangered orgánica en lata o en frasco"},
    {prodId:"50371636",label:"Albaricoque palstein orgánica en lata o en frasco"},
    {prodId:"50371637",label:"Albaricoque patterson orgánica en lata o en frasco"},
    {prodId:"50371638",label:"Albaricoque perfection orgánica en lata o en frasco"},
    {prodId:"50371639",label:"Albaricoque poppy orgánica en lata o en frasco"},
    {prodId:"50371640",label:"Albaricoque poppycot orgánica en lata o en frasco"},
    {prodId:"50371641",label:"Albaricoque reina orgánica en lata o en frasco"},
    {prodId:"50371642",label:"Albaricoque riland orgánica en lata o en frasco"},
    {prodId:"50371643",label:"Albaricoque rival orgánica en lata o en frasco"},
    {prodId:"50371644",label:"Albaricoque robada orgánica en lata o en frasco"},
    {prodId:"50371645",label:"Albaricoque royal orgánica en lata o en frasco"},
    {prodId:"50371646",label:"Albaricoque  royal blenheim orgánica en lata o en frasco"},
    {prodId:"50371647",label:"Albaricoque  royal orange orgánica en lata o en frasco"},
    {prodId:"50371648",label:"Albaricoque sundrop orgánica en lata o en frasco"},
    {prodId:"50371649",label:"Albaricoque tilton orgánica en lata o en frasco"},
    {prodId:"50371650",label:"Albaricoque tomcot orgánica en lata o en frasco"},
    {prodId:"50371651",label:"Albaricoque tracy orgánica en lata o en frasco"},
    {prodId:"50371652",label:"Albaricoque tri gem orgánica en lata o en frasco"},
    {prodId:"50371653",label:"Albaricoque valley gold orgánica en lata o en frasco"},
    {prodId:"50371654",label:"Albaricoque westley orgánica en lata o en frasco"},
    {prodId:"50371655",label:"Albaricoque  york orgánica en lata o en frasco"},
    {prodId:"50371700",label:"Bananos orgánicos en lata o en frasco"},
    {prodId:"50371701",label:"Banana manzana orgánica en lata o en frasco"},
    {prodId:"50371702",label:"Banana baby orgánica en lata o en frasco"},
    {prodId:"50371703",label:"Banana burro orgánica en lata o en frasco"},
    {prodId:"50371704",label:"Bananas cavendish orgánica en lata o en frasco"},
    {prodId:"50371705",label:"Bananas dominico orgánica en lata o en frasco"},
    {prodId:"50371706",label:"Bananas verde orgánica en lata o en frasco"},
    {prodId:"50371707",label:"Bananas gros michel orgánica en lata o en frasco"},
    {prodId:"50371708",label:"Bananas lacatan orgánica en lata o en frasco"},
    {prodId:"50371709",label:"Bananas dedos de dama orgánica en lata o en frasco"},
    {prodId:"50371710",label:"Bananas manzano orgánica en lata o en frasco"},
    {prodId:"50371711",label:"Banana mysore orgánica en lata o en frasco"},
    {prodId:"50371712",label:"Banana pisang mas orgánica en lata o en frasco"},
    {prodId:"50371713",label:"Bananas red orgánica en lata o en frasco"},
    {prodId:"50371714",label:"Bananas saba orgánica en lata o en frasco"},
    {prodId:"50371715",label:"Bananas sucrier orgánica en lata o en frasco"},
    {prodId:"50371800",label:"Barberries orgánicos en lata o en frasco"},
    {prodId:"50371801",label:"Berberís paleleaf orgánica en lata o en frasco"},
    {prodId:"50371802",label:"Barberís chenault orgánica en lata o en frasco"},
    {prodId:"50371803",label:"Barberries red orgánica en lata o en frasco"},
    {prodId:"50371804",label:"Barberís wintergreen orgánica en lata o en frasco"},
    {prodId:"50371805",label:"Barberís korean orgánica en lata o en frasco"},
    {prodId:"50371806",label:"Barberís mentor orgánica en lata o en frasco"},
    {prodId:"50371807",label:"Barberís japonesa orgánica en lata o en frasco"},
    {prodId:"50371808",label:"Barberís atropurpurea orgánica en lata o en frasco"},
    {prodId:"50371809",label:"Barberís aurea orgánica en lata o en frasco"},
    {prodId:"50371810",label:"Barberís bagatelle orgánica en lata o en frasco"},
    {prodId:"50371811",label:"Barberís crimsom pygmy orgánica en lata o en frasco"},
    {prodId:"50371812",label:"Barberís kobold orgánica en lata o en frasco"},
    {prodId:"50371813",label:"Barberís warty orgánica en lata o en frasco"},
    {prodId:"50371814",label:"Barberís europa orgánica en lata o en frasco"},
    {prodId:"50371900",label:"Gayubas orgánicas en lata o en frasco"},
    {prodId:"50371901",label:"Gayuba alpine orgánica en lata o en frasco"},
    {prodId:"50371902",label:"Gayuba roja orgánica en lata o en frasco"},
    {prodId:"50371903",label:"Gayuba común orgánica en lata o en frasco"},
    {prodId:"50372000",label:"Moras orgánicas en lata o en frasco"},
    {prodId:"50372001",label:"Mora apache orgánica en lata o en frasco"},
    {prodId:"50372002",label:"Mora satín negro orgánica en lata o en frasco"},
    {prodId:"50372003",label:"Mora orgánica en lata o en frasco"},
    {prodId:"50372004",label:"Mora cherokee orgánica en lata o en frasco"},
    {prodId:"50372005",label:"Mora chester orgánica en lata o en frasco"},
    {prodId:"50372006",label:"Mora dirksen orgánica en lata o en frasco"},
    {prodId:"50372007",label:"Bayas josta orgánica en lata o en frasco"},
    {prodId:"50372008",label:"Bayas logan orgánica en lata o en frasco"},
    {prodId:"50372009",label:"Bayas marión orgánica en lata o en frasco"},
    {prodId:"50372010",label:"Mora navaho orgánica en lata o en frasco"},
    {prodId:"50372011",label:"Baya nectar orgánica en lata o en frasco"},
    {prodId:"50372012",label:"Mora olallie orgánica en lata o en frasco"},
    {prodId:"50372013",label:"Bayatay orgánica en lata o en frasco"},
    {prodId:"50372014",label:"Mora thomless hull orgánica en lata o en frasco"},
    {prodId:"50372015",label:"Bayayoung orgánica en lata o en frasco"},
    {prodId:"50372100",label:"Arándanos de mirto orgánicos en lata o en frasco"},
    {prodId:"50372101",label:"Arandanos bog orgánica en lata o en frasco"},
    {prodId:"50372102",label:"Arandanos enano orgánica en lata o en frasco"},
    {prodId:"50372103",label:"Arandanos montaña orgánica en lata o en frasco"},
    {prodId:"50372104",label:"Arandanos oval-leaved orgánica en lata o en frasco"},
    {prodId:"50372200",label:"Agrás orgánicas en lata o en frasco"},
    {prodId:"50372201",label:"Agras bluecrop orgánica en lata o en frasco"},
    {prodId:"50372202",label:"Agras bluetta orgánica en lata o en frasco"},
    {prodId:"50372203",label:"Agras brigitta orgánica en lata o en frasco"},
    {prodId:"50372204",label:"Agras chandler orgánica en lata o en frasco"},
    {prodId:"50372205",label:"Agras duke orgánica en lata o en frasco"},
    {prodId:"50372206",label:"Agras hrdyblue orgánica en lata o en frasco"},
    {prodId:"50372207",label:"Agras legacy orgánica en lata o en frasco"},
    {prodId:"50372208",label:"Agras misty orgánica en lata o en frasco"},
    {prodId:"50372209",label:"Agras nelson orgánica en lata o en frasco"},
    {prodId:"50372210",label:"Agras northblue orgánica en lata o en frasco"},
    {prodId:"50372211",label:"Agras northcountry orgánica en lata o en frasco"},
    {prodId:"50372212",label:"Agras northsky orgánica en lata o en frasco"},
    {prodId:"50372213",label:"Agras patriot orgánica en lata o en frasco"},
    {prodId:"50372214",label:"Agras spartan orgánica en lata o en frasco"},
    {prodId:"50372215",label:"Agras toro orgánica en lata o en frasco"},
    {prodId:"50372300",label:"Frutas del pan orgánicas en lata o en frasco"},
    {prodId:"50372301",label:"Fruta de pan chataigne orgánica en lata o en frasco"},
    {prodId:"50372302",label:"Fruta de pan sin pepa orgánica en lata o en frasco"},
    {prodId:"50372303",label:"Fruta de pan corazón blanco orgánica en lata o en frasco"},
    {prodId:"50372304",label:"Fruta de pan corazón amarillo orgánica en lata o en frasco"},
    {prodId:"50372400",label:"Chirimoyas orgánicas en lata o en frasco"},
    {prodId:"50372401",label:"Chirimoya chays orgánica en lata o en frasco"},
    {prodId:"50372402",label:"Chirimoya bronceada orgánica en lata o en frasco"},
    {prodId:"50372403",label:"Chirimoya burtons orgánica en lata o en frasco"},
    {prodId:"50372404",label:"Chirimoya burton favorite orgánica en lata o en frasco"},
    {prodId:"50372405",label:"Chirimoya jete orgánica en lata o en frasco"},
    {prodId:"50372406",label:"Chirimoya reretai orgánica en lata o en frasco"},
    {prodId:"50372407",label:"Chirimoya smoothey orgánica en lata o en frasco"},
    {prodId:"50372408",label:"Chirimoya españa orgánica en lata o en frasco"},
    {prodId:"50372409",label:"Chirimoya white orgánica en lata o en frasco"},
    {prodId:"50372500",label:"Cerezas orgánicas en lata o en frasco"},
    {prodId:"50372501",label:"Cereza amarelle  orgánica en lata o en frasco"},
    {prodId:"50372502",label:"Cereza brooks orgánica en lata o en frasco"},
    {prodId:"50372503",label:"Cereza bigarreu orgánica en lata o en frasco"},
    {prodId:"50372504",label:"Cereza bing orgánica en lata o en frasco"},
    {prodId:"50372505",label:"Cereza blach republica orgánica en lata o en frasco"},
    {prodId:"50372506",label:"Cereza negra schmidt orgánica en lata o en frasco"},
    {prodId:"50372507",label:"Cereza blacktartarian orgánica en lata o en frasco"},
    {prodId:"50372508",label:"Cereza fiesta bing orgánica en lata o en frasco"},
    {prodId:"50372509",label:"Cereza garnet orgánica en lata o en frasco"},
    {prodId:"50372510",label:"Cereza king orgánica en lata o en frasco"},
    {prodId:"50372511",label:"Cereza chapman orgánica en lata o en frasco"},
    {prodId:"50372512",label:"Cereza lapin orgánica en lata o en frasco"},
    {prodId:"50372513",label:"Cereza larian orgánica en lata o en frasco"},
    {prodId:"50372514",label:"Cereza oscuro guines orgánica en lata o en frasco"},
    {prodId:"50372515",label:"Cereza montmorency orgánica en lata o en frasco"},
    {prodId:"50372516",label:"Cereza duque orgánica en lata o en frasco"},
    {prodId:"50372517",label:"Cereza early rivers orgánica en lata o en frasco"},
    {prodId:"50372518",label:"Cereza ruby bing orgánica en lata o en frasco"},
    {prodId:"50372519",label:"Cereza santin orgánica en lata o en frasco"},
    {prodId:"50372520",label:"Cereza geans/guines orgánica en lata o en frasco"},
    {prodId:"50372521",label:"Cereza sonata orgánica en lata o en frasco"},
    {prodId:"50372522",label:"Cereza lambert orgánica en lata o en frasco"},
    {prodId:"50372523",label:"Cereza stella orgánica en lata o en frasco"},
    {prodId:"50372524",label:"Cereza sweethart orgánica en lata o en frasco"},
    {prodId:"50372525",label:"Cereza tartarian orgánica en lata o en frasco"},
    {prodId:"50372527",label:"Cereza maraschino orgánica en lata o en frasco"},
    {prodId:"50372528",label:"Cereza van orgánica en lata o en frasco"},
    {prodId:"50372529",label:"Cereza morello orgánica en lata o en frasco"},
    {prodId:"50372530",label:"Cereza royal ann orgánica en lata o en frasco"},
    {prodId:"50372531",label:"Cereza ranier orgánica en lata o en frasco"},
    {prodId:"50372532",label:"Cereza royal orgánica en lata o en frasco"},
    {prodId:"50372600",label:"Citrones orgánicos en lata o en frasco"},
    {prodId:"50372601",label:"Citrones buddha's mano orgánica en lata o en frasco"},
    {prodId:"50372602",label:"Citrones fingered orgánica en lata o en frasco"},
    {prodId:"50372603",label:"Citrones fo shoukan orgánica en lata o en frasco"},
    {prodId:"50372604",label:"Citrones bushakan orgánica en lata o en frasco"},
    {prodId:"50372605",label:"Citones diamante orgánica en lata o en frasco"},
    {prodId:"50372606",label:"Citrones etrog orgánica en lata o en frasco"},
    {prodId:"50372607",label:"Citrones ponderosa orgánica en lata o en frasco"},
    {prodId:"50372700",label:"Arándanos orgánicos en lata o en frasco"},
    {prodId:"50372701",label:"Arandano ben lear orgánica en lata o en frasco"},
    {prodId:"50372702",label:"Arandano early negro orgánica en lata o en frasco"},
    {prodId:"50372703",label:"Arandano grayclesik orgánica en lata o en frasco"},
    {prodId:"50372704",label:"Arandano  howe orgánica en lata o en frasco"},
    {prodId:"50372705",label:"Bayas lingon orgánica en lata o en frasco"},
    {prodId:"50372706",label:"Arandano mcfarling orgánica en lata o en frasco"},
    {prodId:"50372707",label:"Arandano montaña orgánica en lata o en frasco"},
    {prodId:"50372708",label:"Arandano pilgrim orgánica en lata o en frasco"},
    {prodId:"50372709",label:"Arandano searless orgánica en lata o en frasco"},
    {prodId:"50372710",label:"Arandano stevens orgánica en lata o en frasco"},
    {prodId:"50372800",label:"pasas de Corinto orgánicas en lata o en frasco"},
    {prodId:"50372801",label:"Pasa hudson bay orgánica en lata o en frasco"},
    {prodId:"50372802",label:"Pasa waxy orgánica en lata o en frasco"},
    {prodId:"50372803",label:"Pasa desierto orgánica en lata o en frasco"},
    {prodId:"50372804",label:"Pasa negra orgánica en lata o en frasco"},
    {prodId:"50372805",label:"Pasa roja orgánica en lata o en frasco"},
    {prodId:"50372806",label:"Pasa blanca orgánica en lata o en frasco"},
    {prodId:"50372900",label:"Dátiles orgánicos en lata o en frasco"},
    {prodId:"50372901",label:"Dátiles asharasi  orgánica en lata o en frasco"},
    {prodId:"50372902",label:"Dátiles barhi o barhee orgánica en lata o en frasco"},
    {prodId:"50372903",label:"Dátiles deglet noor orgánica en lata o en frasco"},
    {prodId:"50372904",label:"Dátiles fardh orgánica en lata o en frasco"},
    {prodId:"50372905",label:"Dátiles gundila orgánica en lata o en frasco"},
    {prodId:"50372906",label:"Dátiles halawi/halawy orgánica en lata o en frasco"},
    {prodId:"50372907",label:"Dátiles hilali orgánica en lata o en frasco"},
    {prodId:"50372908",label:"Dátiles khadrawi/khadrawy orgánica en lata o en frasco"},
    {prodId:"50372909",label:"Dátiles khalas orgánica en lata o en frasco"},
    {prodId:"50372910",label:"Dátiles khustawi orgánica en lata o en frasco"},
    {prodId:"50372911",label:"Dátiles khidri orgánica en lata o en frasco"},
    {prodId:"50372912",label:"Dátiles medjool/ medjol orgánica en lata o en frasco"},
    {prodId:"50372913",label:"Dátiles mactoum orgánica en lata o en frasco"},
    {prodId:"50372914",label:"Dátiles neghal orgánica en lata o en frasco"},
    {prodId:"50372915",label:"Dátiles yatimeh orgánica en lata o en frasco"},
    {prodId:"50372916",label:"Dátiles zahidi orgánica en lata o en frasco"},
    {prodId:"50373000",label:"Pitahayas orgánicas en lata o en frasco"},
    {prodId:"50373001",label:"Pitahaya roja, rosada orgánica en lata o en frasco"},
    {prodId:"50373002",label:"Pitahaya roja, amarillo orgánica en lata o en frasco"},
    {prodId:"50373100",label:"Brevas orgánicas en lata o en frasco"},
    {prodId:"50373101",label:"Breva bardajic orgánica en lata o en frasco"},
    {prodId:"50373102",label:"Breva brown turkey orgánica en lata o en frasco"},
    {prodId:"50373103",label:"Breva calimyma  orgánica en lata o en frasco"},
    {prodId:"50373104",label:"Breva conadria orgánica en lata o en frasco"},
    {prodId:"50373105",label:"Breva dotado orgánica en lata o en frasco"},
    {prodId:"50373106",label:"Breva kadota orgánica en lata o en frasco"},
    {prodId:"50373107",label:"Breva mediterránea orgánica en lata o en frasco"},
    {prodId:"50373108",label:"Breva misión orgánica en lata o en frasco"},
    {prodId:"50373109",label:"Breva amyma orgánica en lata o en frasco"},
    {prodId:"50373110",label:"Breva verdona orgánica en lata o en frasco"},
    {prodId:"50373111",label:"Breva rey blanco orgánica en lata o en frasco"},
    {prodId:"50373200",label:"Grosellas orgánicas en lata o en frasco"},
    {prodId:"50373201",label:"Grosella early sulphur orgánica en lata o en frasco"},
    {prodId:"50373202",label:"Grosella goldendrop orgánica en lata o en frasco"},
    {prodId:"50373203",label:"Grosella langley grace orgánica en lata o en frasco"},
    {prodId:"50373204",label:"Grocella leveller orgánica en lata o en frasco"},
    {prodId:"50373205",label:"Grosella london orgánica en lata o en frasco"},
    {prodId:"50373206",label:"Grosella worcestershire orgánica en lata o en frasco"},
    {prodId:"50373207",label:"Grosella americana worcesterberry orgánic"},
    {prodId:"50373300",label:"Toronjas orgánicas en lata o en frasco"},
    {prodId:"50373301",label:"Toronja burgundi orgánica en lata o en frasco"},
    {prodId:"50373302",label:"Toronja duncan orgánica en lata o en frasco"},
    {prodId:"50373303",label:"Toronja foster orgánica en lata o en frasco"},
    {prodId:"50373304",label:"Toronja marsh orgánica en lata o en frasco"},
    {prodId:"50373305",label:"Toronja nueva zelandia orgánica en lata o en frasco"},
    {prodId:"50373306",label:"Toronja rio rojo orgánica en lata o en frasco"},
    {prodId:"50373307",label:"Toronja ruby rojo orgánica en lata o en frasco"},
    {prodId:"50373308",label:"Toronja star ruby orgánica en lata o en frasco"},
    {prodId:"50373309",label:"Toronja triunfo orgánica en lata o en frasco"},
    {prodId:"50373400",label:"Uvas de mesa orgánicas en lata o en frasco"},
    {prodId:"50373401",label:"Uva alicante orgánica en lata o en frasco"},
    {prodId:"50373402",label:"Uva almeira orgánica en lata o en frasco"},
    {prodId:"50373403",label:"Uva alphonse lavalle orgánica en lata o en frasco"},
    {prodId:"50373404",label:"Uva otoñoking grapes orgánica en lata o en frasco"},
    {prodId:"50373405",label:"Uva autum royal orgánica en lata o en frasco"},
    {prodId:"50373406",label:"Uva autum sin pepa orgánica en lata o en frasco"},
    {prodId:"50373407",label:"Uva baresana orgánica en lata o en frasco"},
    {prodId:"50373408",label:"Uva barlinka orgánica en lata o en frasco"},
    {prodId:"50373409",label:"Uva belleza sin pepa orgánica en lata o en frasco"},
    {prodId:"50373410",label:"Uva black beauty sin pepa orgánica en lata o en frasco"},
    {prodId:"50373411",label:"Uva black esmeralda orgánica en lata o en frasco"},
    {prodId:"50373412",label:"Uva gigante negra orgánica en lata o en frasco"},
    {prodId:"50373413",label:"Uva globo negro orgánica en lata o en frasco"},
    {prodId:"50373414",label:"Uva negra monnukka orgánica en lata o en frasco"},
    {prodId:"50373415",label:"Uva black pearl orgánica en lata o en frasco"},
    {prodId:"50373416",label:"Uva black sin pepa orgánica en lata o en frasco"},
    {prodId:"50373417",label:"Uva bonheur orgánica en lata o en frasco"},
    {prodId:"50373418",label:"Uva calmeria orgánica en lata o en frasco"},
    {prodId:"50373419",label:"Uva cardinal orgánica en lata o en frasco"},
    {prodId:"50373420",label:"Uva catawba orgánica en lata o en frasco"},
    {prodId:"50373421",label:"Uva chasselas/dorado chasselas orgánica en lata o en frasco"},
    {prodId:"50373422",label:"Uva christmas rose orgánica en lata o en frasco"},
    {prodId:"50373423",label:"Uva concord orgánica en lata o en frasco"},
    {prodId:"50373424",label:"Uva concord sin pepa orgánica en lata o en frasco"},
    {prodId:"50373425",label:"Uva crimson sin pepa orgánica en lata o en frasco"},
    {prodId:"50373426",label:"Uva dauphine  orgánica en lata o en frasco"},
    {prodId:"50373427",label:"Uva daleware orgánica en lata o en frasco"},
    {prodId:"50373428",label:"Uva early muscat orgánica en lata o en frasco"},
    {prodId:"50373429",label:"Uva early sweet orgánica en lata o en frasco"},
    {prodId:"50373430",label:"Uva esmeralda sin pepa orgánica en lata o en frasco"},
    {prodId:"50373431",label:"Uva emperatriz orgánica en lata o en frasco"},
    {prodId:"50373432",label:"Uva emperador orgánica en lata o en frasco"},
    {prodId:"50373433",label:"Uva empres orgánica en lata o en frasco"},
    {prodId:"50373434",label:"Uva exótica orgánica en lata o en frasco"},
    {prodId:"50373435",label:"Uva fantasía orgánica en lata o en frasco"},
    {prodId:"50373436",label:"Uva fantasia sin pepa orgánica en lata o en frasco"},
    {prodId:"50373437",label:"Uva llama orgánica en lata o en frasco"},
    {prodId:"50373438",label:"Uva llama sin pepa orgánica en lata o en frasco"},
    {prodId:"50373439",label:"Uva llama tokay orgánica en lata o en frasco"},
    {prodId:"50373440",label:"Uva flaming red orgánica en lata o en frasco"},
    {prodId:"50373441",label:"Uva galaxia sin pepa orgánica en lata o en frasco"},
    {prodId:"50373442",label:"Uva gamay orgánica en lata o en frasco"},
    {prodId:"50373443",label:"Uva dorada orgánica en lata o en frasco"},
    {prodId:"50373444",label:"Uva miel o honeypot orgánica en lata o en frasco"},
    {prodId:"50373445",label:"Uva italia orgánica en lata o en frasco"},
    {prodId:"50373446",label:"Uva jade sin pepa orgánica en lata o en frasco"},
    {prodId:"50373447",label:"Uva jubilee orgánica en lata o en frasco"},
    {prodId:"50373448",label:"Uva king ruby orgánica en lata o en frasco"},
    {prodId:"50373449",label:"Uva kyoho orgánica en lata o en frasco"},
    {prodId:"50373450",label:"Uva la rochelle orgánica en lata o en frasco"},
    {prodId:"50373451",label:"Uva dedo de dama orgánica en lata o en frasco"},
    {prodId:"50373452",label:"Uva late sin pepa orgánica en lata o en frasco"},
    {prodId:"50373453",label:"Uva majestic sin pepa orgánica en lata o en frasco"},
    {prodId:"50373454",label:"Uva malaga orgánica en lata o en frasco"},
    {prodId:"50373455",label:"Uva marro sin pepa orgánica en lata o en frasco"},
    {prodId:"50373456",label:"Uva muscadine orgánica en lata o en frasco"},
    {prodId:"50373457",label:"Uva muscat llama orgánica en lata o en frasco"},
    {prodId:"50373458",label:"Uva muscat orgánica en lata o en frasco"},
    {prodId:"50373459",label:"Uva muscat sin papa orgánica en lata o en frasco"},
    {prodId:"50373460",label:"Uva napoleon orgánica en lata o en frasco"},
    {prodId:"50373461",label:"Uva nigeria orgánica en lata o en frasco"},
    {prodId:"50373462",label:"Uva new cross orgánica en lata o en frasco"},
    {prodId:"50373463",label:"Uva nibella orgánica en lata o en frasco"},
    {prodId:"50373464",label:"Uva niagara orgánica en lata o en frasco"},
    {prodId:"50373465",label:"Uva olivette orgánica en lata o en frasco"},
    {prodId:"50373466",label:"Uva perlette orgánica en lata o en frasco"},
    {prodId:"50373467",label:"Uva perlon orgánica en lata o en frasco"},
    {prodId:"50373468",label:"Uva prima black sin pepa orgánica en lata o en frasco"},
    {prodId:"50373469",label:"Uva principe orgánica en lata o en frasco"},
    {prodId:"50373470",label:"Uva reina orgánica en lata o en frasco"},
    {prodId:"50373471",label:"Uva roja blush orgánica en lata o en frasco"},
    {prodId:"50373472",label:"Uva roja globo orgánica en lata o en frasco"},
    {prodId:"50373473",label:"Uva roja malaga orgánica en lata o en frasco"},
    {prodId:"50373474",label:"Uva roja sin pepa orgánica en lata o en frasco"},
    {prodId:"50373475",label:"Uva regina orgánica en lata o en frasco"},
    {prodId:"50373476",label:"Uva ribier orgánica en lata o en frasco"},
    {prodId:"50373477",label:"Uva rosita orgánica en lata o en frasco"},
    {prodId:"50373478",label:"Uva rouge orgánica en lata o en frasco"},
    {prodId:"50373479",label:"Uva royal negra sin pepa orgánica en lata o en frasco"},
    {prodId:"50373480",label:"Uva ruby roja sin pepa orgánica en lata o en frasco"},
    {prodId:"50373481",label:"Uva ryby sin pepa orgánica en lata o en frasco"},
    {prodId:"50373482",label:"Uva scarlet royal orgánica en lata o en frasco"},
    {prodId:"50373483",label:"Uva scuppemong orgánica en lata o en frasco"},
    {prodId:"50373484",label:"Uva sugarose orgánica en lata o en frasco"},
    {prodId:"50373485",label:"Uva sugartirteen orgánica en lata o en frasco"},
    {prodId:"50373486",label:"Uva sugraone orgánica en lata o en frasco"},
    {prodId:"50373487",label:"Uva sugrasixteen orgánica en lata o en frasco"},
    {prodId:"50373488",label:"Uva sultana sun roja orgánica en lata o en frasco"},
    {prodId:"50373489",label:"Uva verano royal orgánica en lata o en frasco"},
    {prodId:"50373490",label:"Uva atardecer orgánica en lata o en frasco"},
    {prodId:"50373491",label:"Uva superior sin pepa orgánica en lata o en frasco"},
    {prodId:"50373492",label:"Uva thompson sin pepa orgánica en lata o en frasco"},
    {prodId:"50373493",label:"Uva tokaylpinot gris orgánica en lata o en frasco"},
    {prodId:"50373494",label:"Uva waltman cross orgánica en lata o en frasco"},
    {prodId:"50373495",label:"Uva blanca sin pepa orgánica en lata o en frasco"},
    {prodId:"50373496",label:"Uva zante current orgánica en lata o en frasco"},
    {prodId:"50373500",label:"Uvas pasas orgánicas en lata o en frasco"},
    {prodId:"50373501",label:"Uva corinth negra orgánica en lata o en frasco"},
    {prodId:"50373502",label:"Uva canner orgánica en lata o en frasco"},
    {prodId:"50373503",label:"Uva dovine orgánica en lata o en frasco"},
    {prodId:"50373504",label:"Uva fiesta orgánica en lata o en frasco"},
    {prodId:"50373505",label:"Uva selme pete orgánica en lata o en frasco"},
    {prodId:"50373506",label:"Uva sultana orgánica en lata o en frasco"},
    {prodId:"50373600",label:"Uvas para vino orgánicas en lata o en frasco"},
    {prodId:"50373601",label:"Uva licante bouschet orgánica en lata o en frasco"},
    {prodId:"50373602",label:"Uva barbera orgánica en lata o en frasco"},
    {prodId:"50373603",label:"Uva burger orgánica en lata o en frasco"},
    {prodId:"50373604",label:"Uva cabemet franc orgánica en lata o en frasco"},
    {prodId:"50373605",label:"Uva cabenet sauvignon orgánica en lata o en frasco"},
    {prodId:"50373606",label:"Uva carignane orgánica en lata o en frasco"},
    {prodId:"50373607",label:"Uva camelian orgánica en lata o en frasco"},
    {prodId:"50373608",label:"Uva cattarrato orgánica en lata o en frasco"},
    {prodId:"50373609",label:"Uva centurian orgánica en lata o en frasco"},
    {prodId:"50373610",label:"Uva charbono orgánica en lata o en frasco"},
    {prodId:"50373611",label:"Uva chardonnay orgánica en lata o en frasco"},
    {prodId:"50373612",label:"Uva chenin blanco orgánica en lata o en frasco"},
    {prodId:"50373613",label:"Uva cinsaut orgánica en lata o en frasco"},
    {prodId:"50373614",label:"Uva dolcetto orgánica en lata o en frasco"},
    {prodId:"50373615",label:"Uva emerald riesling orgánica en lata o en frasco"},
    {prodId:"50373616",label:"Uva frances colombard orgánica en lata o en frasco"},
    {prodId:"50373617",label:"Uva granny nap orgánica en lata o en frasco"},
    {prodId:"50373618",label:"Uva gamay beaujolais orgánica en lata o en frasco"},
    {prodId:"50373619",label:"Uva gewurztraminer orgánica en lata o en frasco"},
    {prodId:"50373620",label:"Uva grnache orgánica en lata o en frasco"},
    {prodId:"50373621",label:"Uva grinache blanc orgánica en lata o en frasco"},
    {prodId:"50373622",label:"Uva lagrein orgánica en lata o en frasco"},
    {prodId:"50373623",label:"Uva lambruso orgánica en lata o en frasco"},
    {prodId:"50373624",label:"Uva malbec orgánica en lata o en frasco"},
    {prodId:"50373625",label:"Uva malvasía bianca orgánica en lata o en frasco"},
    {prodId:"50373626",label:"Uva marsanne orgánica en lata o en frasco"},
    {prodId:"50373627",label:"Uva matano orgánica en lata o en frasco"},
    {prodId:"50373628",label:"Uva merlot orgánica en lata o en frasco"},
    {prodId:"50373629",label:"Uva meunier orgánica en lata o en frasco"},
    {prodId:"50373630",label:"Uva missiom orgánica en lata o en frasco"},
    {prodId:"50373631",label:"Uva montepulceano orgánica en lata o en frasco"},
    {prodId:"50373632",label:"Uva muscat blanc orgánica en lata o en frasco"},
    {prodId:"50373633",label:"Uva muscat hamburg orgánica en lata o en frasco"},
    {prodId:"50373634",label:"Uva muscat alexandria orgánica en lata o en frasco"},
    {prodId:"50373635",label:"Uva muscat naranja orgánica en lata o en frasco"},
    {prodId:"50373636",label:"Uva nebbiolo orgánica en lata o en frasco"},
    {prodId:"50373637",label:"Uva palomino orgánica en lata o en frasco"},
    {prodId:"50373638",label:"Uva petit verdor orgánica en lata o en frasco"},
    {prodId:"50373639",label:"Uva petit sirah orgánica en lata o en frasco"},
    {prodId:"50373640",label:"Uva pinot blanc orgánica en lata o en frasco"},
    {prodId:"50373641",label:"Uva pinot gris orgánica en lata o en frasco"},
    {prodId:"50373642",label:"Uva pinot noir orgánica en lata o en frasco"},
    {prodId:"50373643",label:"Uva primitivo orgánica en lata o en frasco"},
    {prodId:"50373644",label:"Uva roussane orgánica en lata o en frasco"},
    {prodId:"50373645",label:"Uva real  orgánica en lata o en frasco"},
    {prodId:"50373646",label:"Uva rubired orgánica en lata o en frasco"},
    {prodId:"50373647",label:"Uva ruby cabenet orgánica en lata o en frasco"},
    {prodId:"50373648",label:"Uva salvador orgánica en lata o en frasco"},
    {prodId:"50373649",label:"Uva sangiovese orgánica en lata o en frasco"},
    {prodId:"50373650",label:"Uva sauvignon blanc orgánica en lata o en frasco"},
    {prodId:"50373651",label:"Uva sauvignon musque orgánica en lata o en frasco"},
    {prodId:"50373652",label:"Uva semillon orgánica en lata o en frasco"},
    {prodId:"50373653",label:"Uva souzao orgánica en lata o en frasco"},
    {prodId:"50373654",label:"Uva st emilion orgánica en lata o en frasco"},
    {prodId:"50373655",label:"Uva symohony orgánica en lata o en frasco"},
    {prodId:"50373656",label:"Uva syrah  orgánica en lata o en frasco"},
    {prodId:"50373657",label:"Uva tannat orgánica en lata o en frasco"},
    {prodId:"50373658",label:"Uva temoranillo orgánica en lata o en frasco"},
    {prodId:"50373659",label:"Uvas teroldego orgánica en lata o en frasco"},
    {prodId:"50373660",label:"Uvas tocai friulano orgánica en lata o en frasco"},
    {prodId:"50373661",label:"Uvas touriga nacional orgánica en lata o en frasco"},
    {prodId:"50373662",label:"Uvas triplett blanc orgánica en lata o en frasco"},
    {prodId:"50373663",label:"Uvas viogner orgánica en lata o en frasco"},
    {prodId:"50373664",label:"Uvas white riesling orgánica en lata o en frasco"},
    {prodId:"50373665",label:"Uvas zinfandel orgánica en lata o en frasco"},
    {prodId:"50373700",label:"Guayabas orgánicas en lata o en frasco"},
    {prodId:"50373701",label:"Guayabas beaumont orgánica en lata o en frasco"},
    {prodId:"50373702",label:"Guayabas carrley orgánica en lata o en frasco"},
    {prodId:"50373703",label:"Guayabas lucida orgánica en lata o en frasco"},
    {prodId:"50373704",label:"Guayaba piña orgánica en lata o en frasco"},
    {prodId:"50373800",label:"Arándanos Huckleberry orgánicos en lata o en frasco"},
    {prodId:"50373801",label:"Arándano invierno negro orgánico en lata o en frasco"},
    {prodId:"50373802",label:"Arándano cascada orgánico en lata o en frasco"},
    {prodId:"50373803",label:"Arándano enano orgánico en lata o en frasco"},
    {prodId:"50373804",label:"Arándano montaña orgánico en lata o en frasco"},
    {prodId:"50373805",label:"Arándano rojo orgánico en lata o en frasco"},
    {prodId:"50373900",label:"Kiwis orgánicos en lata o en frasco"},
    {prodId:"50373901",label:"Fruta de kiwi ananasnaja orgánica en lata o en frasco"},
    {prodId:"50373902",label:"Fruta de kiwi belleza del ártico orgánica en lata o en frasco"},
    {prodId:"50373903",label:"Fruta de kiwi blake orgánica en lata o en frasco"},
    {prodId:"50373904",label:"Fruta de kiwi hayward orgánica en lata o en frasco"},
    {prodId:"50373905",label:"Fruta de kiwi issai orgánica en lata o en frasco"},
    {prodId:"50373906",label:"Fruta de kiwi siberiana orgánica en lata o en frasco"},
    {prodId:"50374000",label:"Naranjos Chinos (kumquats) orgánicos en lata o en frasco"},
    {prodId:"50374001",label:"Naranjo chino hong kong orgánico en lata o en frasco"},
    {prodId:"50374002",label:"Naranjo chino limequat orgánico en lata o en frasco"},
    {prodId:"50374003",label:"Naranjo long fruit orgánico en lata o en frasco"},
    {prodId:"50374004",label:"Naranjo chino malayan orgánico en lata o en frasco"},
    {prodId:"50374005",label:"Naranjo meiwa orgánico en lata o en frasco"},
    {prodId:"50374006",label:"Naranja chino nagami orgánico en lata o en frasco"},
    {prodId:"50374100",label:"Limones orgánicos en lata o en frasco"},
    {prodId:"50374101",label:"Limón baboon orgánico en lata o en frasco"},
    {prodId:"50374102",label:"Limón bearss sicilian orgánico en lata o en frasco"},
    {prodId:"50374103",label:"Limón cameron highland orgánico en lata o en frasco"},
    {prodId:"50374104",label:"Limón escondido orgánico en lata o en frasco"},
    {prodId:"50374105",label:"Limón eureka orgánico en lata o en frasco"},
    {prodId:"50374106",label:"Limón lisbon orgánico en lata o en frasco"},
    {prodId:"50374107",label:"Limón meyer orgánico en lata o en frasco"},
    {prodId:"50374108",label:"Limón volkaner orgánico en lata o en frasco"},
    {prodId:"50374200",label:"Limas orgánicas en lata o en frasco"},
    {prodId:"50374201",label:"Lima limón indian sweet orgánico en lata o en frasco"},
    {prodId:"50374202",label:"Lima limón orgánico en lata o en frasco"},
    {prodId:"50374203",label:"Lima limón mandarin orgánico en lata o en frasco"},
    {prodId:"50374204",label:"Lima limón philippine orgánico en lata o en frasco"},
    {prodId:"50374205",label:"Lima limón tahitian orgánico en lata o en frasco"},
    {prodId:"50374206",label:"Lima limón bearss orgánico en lata o en frasco"},
    {prodId:"50374207",label:"Lima limón persa orgánico en lata o en frasco"},
    {prodId:"50374208",label:"Lima limón sin pepas orgánico en lata o en frasco"},
    {prodId:"50374300",label:"Nísperos orgánicos en lata o en frasco"},
    {prodId:"50374301",label:"Níspero advance orgánico en lata o en frasco"},
    {prodId:"50374302",label:"Níspero benlehr orgánico en lata o en frasco"},
    {prodId:"50374303",label:"Níspero big jim orgánico en lata o en frasco"},
    {prodId:"50374304",label:"Níspero champagne orgánico en lata o en frasco"},
    {prodId:"50374305",label:"Níspero early rojo orgánico en lata o en frasco"},
    {prodId:"50374306",label:"Níspero nuget dorado orgánico en lata o en frasco"},
    {prodId:"50374307",label:"Níspero herd's mammoth orgánico en lata o en frasco"},
    {prodId:"50374308",label:"Níspero mogi orgánico en lata o en frasco"},
    {prodId:"50374309",label:"Níspero mrs cooksey orgánico en lata o en frasco"},
    {prodId:"50374310",label:"Níspero fresa orgánico en lata o en frasco"},
    {prodId:"50374311",label:"Níspero tanaka orgánico en lata o en frasco"},
    {prodId:"50374312",label:"Níspero victoria vista blanco orgánico en lata o en frasco"},
    {prodId:"50374313",label:"Níspero wolfe orgánico en lata o en frasco"},
    {prodId:"50374400",label:"Mandarinas orgánicas en lata o en frasco"},
    {prodId:"50374401",label:"Naranjas clauselinas orgánica en lata o en frascos en lata o en frasco"},
    {prodId:"50374402",label:"Mandarinas clementinas orgánica en lata o en frascos en lata o en frasco"},
    {prodId:"50374403",label:"Naranja mandarina cleopatra"},
    {prodId:"50374404",label:"Mandarina dancy orgánica en lata o en frasco"},
    {prodId:"50374405",label:"Naranja ellensday orgánica en lata o en frasco"},
    {prodId:"50374406",label:"Naranja fairchild orgánica en lata o en frasco"},
    {prodId:"50374407",label:"Naranja fallglo orgánica en lata o en frasco"},
    {prodId:"50374408",label:"Naranja fortuna orgánica en lata o en frasco"},
    {prodId:"50374409",label:"Mandarina naranja fremont orgánica en lata o en frasco"},
    {prodId:"50374410",label:"Naranja fremont orgánica en lata o en frasco"},
    {prodId:"50374411",label:"Naranja nuget dorada orgánica en lata o en frasco"},
    {prodId:"50374412",label:"Mandarina naranja miel orgánica en lata o en frasco"},
    {prodId:"50374413",label:"Naranja miel orgánica en lata o en frasco"},
    {prodId:"50374414",label:"Mandarina miel orgánica en lata o en frasco"},
    {prodId:"50374415",label:"Natanja tanjelo honebelle orgánica en lata o en frasco"},
    {prodId:"50374416",label:"Naranja mandarina rey orgánica en lata o en frasco"},
    {prodId:"50374417",label:"Naranja kinnow orgánica en lata o en frasco"},
    {prodId:"50374418",label:"Naranja andarinalee orgánica en lata o en frasco"},
    {prodId:"50374419",label:"Naranja makokkee orgánica en lata o en frasco"},
    {prodId:"50374420",label:"Naranja malvasion orgánica en lata o en frasco"},
    {prodId:"50374421",label:"Naranja mandarina mediterránea orgánica en lata o en frasco"},
    {prodId:"50374422",label:"Naranja tangelo minneola orgánica en lata o en frasco"},
    {prodId:"50374423",label:"Naranja monica orgánica en lata o en frasco"},
    {prodId:"50374424",label:"Naranja murcott miel orgánica en lata o en frasco"},
    {prodId:"50374425",label:"Naranja murcott tangors orgánica en lata o en frasco"},
    {prodId:"50374426",label:"Naranja mandarina natsudaidai  orgánica en lata o en frasco"},
    {prodId:"50374427",label:"Naranja mandarina natsumikan orgánica en lata o en frasco"},
    {prodId:"50374428",label:"Naranja tanjelo nocatee orgánica en lata o en frasco"},
    {prodId:"50374429",label:"Naranja tanjelo orlando orgánica en lata o en frasco"},
    {prodId:"50374430",label:"Mandarina ortanique orgánica en lata o en frasco"},
    {prodId:"50374431",label:"Naranja mandarina pagina orgánica en lata o en frasco"},
    {prodId:"50374432",label:"Naranja pixie orgánica en lata o en frasco"},
    {prodId:"50374433",label:"Naranja mandarina ponkan bantangas orgánica en lata o en frasco"},
    {prodId:"50374434",label:"Naranja reina orgánica en lata o en frasco"},
    {prodId:"50374435",label:"Naranja robinson orgánica en lata o en frasco"},
    {prodId:"50374436",label:"Naranja saltenitas orgánica en lata o en frasco"},
    {prodId:"50374437",label:"Naranja tangelo sampson orgánica en lata o en frasco"},
    {prodId:"50374438",label:"Naranja mandarina satsuma orgánica en lata o en frasco"},
    {prodId:"50374439",label:"Naranja mandarina sunburst orgánica en lata o en frasco"},
    {prodId:"50374440",label:"Tangelo orgánico en lata o en frasco"},
    {prodId:"50374441",label:"Naranja mandarina orgánica en lata o en frasco"},
    {prodId:"50374442",label:"Naranja templo orgánica en lata o en frasco"},
    {prodId:"50374443",label:"Naranja thornton orgánica en lata o en frasco"},
    {prodId:"50374444",label:"Mandarina wekiwa orgánica en lata o en frasco"},
    {prodId:"50374445",label:"Mandarina wilkins orgánica en lata o en frasco"},
    {prodId:"50374446",label:"Mandarina willowleaf mediterránea orgánica en lata o en frasco"},
    {prodId:"50374600",label:"Melones orgánicos en lata o en frasco"},
    {prodId:"50374601",label:"Melon allsweet orgánico en lata o en frasco"},
    {prodId:"50374602",label:"Melón athens orgánico en lata o en frasco"},
    {prodId:"50374603",label:"Melón diamante negro orgánico en lata o en frasco"},
    {prodId:"50374604",label:"Melón cal sweet orgánico en lata o en frasco"},
    {prodId:"50374605",label:"Melons cantaloupe orgánico en lata o en frasco"},
    {prodId:"50374606",label:"Melón carnical orgánico en lata o en frasco"},
    {prodId:"50374607",label:"Melón casaba orgánico en lata o en frasco"},
    {prodId:"50374608",label:"Melón cavaillon orgánico en lata o en frasco"},
    {prodId:"50374609",label:"Melón charentais orgánico en lata o en frasco"},
    {prodId:"50374610",label:"Sandia charleston gray orgánico en lata o en frasco"},
    {prodId:"50374611",label:"Melón crenshaw orgánico en lata o en frasco"},
    {prodId:"50374612",label:"Melón crimson sweet orgánico en lata o en frasco"},
    {prodId:"50374613",label:"Melón dixie lee orgánico en lata o en frasco"},
    {prodId:"50374614",label:"Melón eclipse orgánico en lata o en frasco"},
    {prodId:"50374615",label:"Melón ein dór  orgánico en lata o en frasco"},
    {prodId:"50374616",label:"Melón fiesta orgánico en lata o en frasco"},
    {prodId:"50374617",label:"Melón galia orgánico en lata o en frasco"},
    {prodId:"50374618",label:"Melón gaya orgánico en lata o en frasco"},
    {prodId:"50374619",label:"Melón hami orgánico en lata o en frasco"},
    {prodId:"50374620",label:"Melón miel dew orgánico en lata o en frasco"},
    {prodId:"50374621",label:"Melón hielo orgánico en lata o en frasco"},
    {prodId:"50374622",label:"Melón ida orgullo orgánico en lata o en frasco"},
    {prodId:"50374623",label:"Melón juan canary orgánico en lata o en frasco"},
    {prodId:"50374624",label:"Melón jubileo orgánico en lata o en frasco"},
    {prodId:"50374625",label:"Melón jubilación orgánico en lata o en frasco"},
    {prodId:"50374626",label:"Melón kaki / kakri orgánico en lata o en frasco"},
    {prodId:"50374627",label:"Melón kiwano orgánico en lata o en frasco"},
    {prodId:"50374628",label:"Melon koreano orgánico en lata o en frasco"},
    {prodId:"50374629",label:"Melón long grey orgánico en lata o en frasco"},
    {prodId:"50374630",label:"Melón mayan orgánico en lata o en frasco"},
    {prodId:"50374631",label:"Melón micky lee orgánico en lata o en frasco"},
    {prodId:"50374632",label:"Melón mirage orgánico en lata o en frasco"},
    {prodId:"50374633",label:"Sandia luna y estrellas orgánico en lata o en frasco"},
    {prodId:"50374634",label:"Melón ogen orgánico en lata o en frasco"},
    {prodId:"50374635",label:"Melón patriot orgánico en lata o en frasco"},
    {prodId:"50374636",label:"Melón peacook orgánico en lata o en frasco"},
    {prodId:"50374637",label:"Melón pepino orgánico en lata o en frasco"},
    {prodId:"50374638",label:"Melón persian orgánico en lata o en frasco"},
    {prodId:"50374639",label:"Melón picnic orgánico en lata o en frasco"},
    {prodId:"50374640",label:"Melón piel de sapo orgánico en lata o en frasco"},
    {prodId:"50374641",label:"Melón piña orgánico en lata o en frasco"},
    {prodId:"50374642",label:"Melón quetzali orgánico en lata o en frasco"},
    {prodId:"50374643",label:"Melón goblin rojo orgánico en lata o en frasco"},
    {prodId:"50374644",label:"Melón regency orgánico en lata o en frasco"},
    {prodId:"50374645",label:"Melón royal mejestic orgánico en lata o en frasco"},
    {prodId:"50374646",label:"Melón royal star orgánico en lata o en frasco"},
    {prodId:"50374647",label:"Melón dulce royal orgánico en lata o en frasco"},
    {prodId:"50374648",label:"Malon santa claus orgánico en lata o en frasco"},
    {prodId:"50374649",label:"Melón sharlyn orgánico en lata o en frasco"},
    {prodId:"50374650",label:"Melón español orgánico en lata o en frasco"},
    {prodId:"50374651",label:"Melón sprite orgánic orgánico en lata o en frasco"},
    {prodId:"50374652",label:"Melón starbright orgánico en lata o en frasco"},
    {prodId:"50374653",label:"Melón stars and stripes orgánico en lata o en frasco"},
    {prodId:"50374654",label:"Melón bebe de azúcar orgánico en lata o en frasco"},
    {prodId:"50374655",label:"Sandia bebe de azúcar orgánica en lata o en frasco"},
    {prodId:"50374656",label:"Melón dulce sol orgánico en lata o en frasco"},
    {prodId:"50374657",label:"Sandia corazón dulce sin pepa orgánica en lata o en frasco"},
    {prodId:"50374658",label:"Melón tentación orgánico en lata o en frasco"},
    {prodId:"50374659",label:"Melón tigre bebe orgánico en lata o en frasco"},
    {prodId:"50374660",label:"Melón tuscan tipo orgánico en lata o en frasco"},
    {prodId:"50374661",label:"Sandia baby amarillo orgánica en lata o en frasco"},
    {prodId:"50374700",label:"Moras mulberry orgánicas en lata o en frasco"},
    {prodId:"50374701",label:"Mora negra orgánica en lata o en frasco"},
    {prodId:"50374702",label:"Mora blanca orgánica en lata o en frasco"},
    {prodId:"50374800",label:"Mirtos orgánicos en lata o en frasco"},
    {prodId:"50374801",label:"Mirto bog orgánico en lata o en frasco"},
    {prodId:"50374900",label:"Nectarinas orgánicos en lata o en frasco"},
    {prodId:"50374901",label:"Durazno april glo orgánico en lata o en frasco"},
    {prodId:"50374902",label:"Durazno arctic mist orgánico en lata o en frasco"},
    {prodId:"50374903",label:"Durazno artic snow orgánico en lata o en frasco"},
    {prodId:"50374904",label:"Durazno artic star orgánico en lata o en frasco"},
    {prodId:"50374905",label:"Durazno artic dulce orgánico en lata o en frasco"},
    {prodId:"50374906",label:"Durazno artic glo orgánico en lata o en frasco"},
    {prodId:"50374907",label:"Durazno agosto llama orgánico en lata o en frasco"},
    {prodId:"50374908",label:"Durazno  perla de agosto orgánico en lata o en frasco"},
    {prodId:"50374909",label:"Durazno agosto rojo orgánico en lata o en frasco"},
    {prodId:"50374910",label:"Durazno estrella de agosto orgánico en lata o en frasco"},
    {prodId:"50374911",label:"Durazno big john orgánico en lata o en frasco"},
    {prodId:"50374912",label:"Durazno perla brillante orgánico en lata o en frasco"},
    {prodId:"50374913",label:"Durazno diamante brillante orgánico en lata o en frasco"},
    {prodId:"50374914",label:"Durazno diamante rayo orgánico en lata o en frasco"},
    {prodId:"50374915",label:"Durazno earligo orgánico en lata o en frasco"},
    {prodId:"50374916",label:"Durazno diamante temprano orgánico en lata o en frasco"},
    {prodId:"50374917",label:"Durazno fairlane orgánico en lata o en frasco"},
    {prodId:"50374918",label:"Durazno fantasía orgánico en lata o en frasco"},
    {prodId:"50374919",label:"Durazno perla fuego orgánico en lata o en frasco"},
    {prodId:"50374920",label:"Durazno fuego dulce orgánico en lata o en frasco"},
    {prodId:"50374921",label:"Durazno flamekist orgánico en lata o en frasco"},
    {prodId:"50374922",label:"Durazno tipo plano orgánico en lata o en frasco"},
    {prodId:"50374923",label:"Durazno delicia de jardín orgánico en lata o en frasco"},
    {prodId:"50374924",label:"Durazno mina de oro orgánico en lata o en frasco"},
    {prodId:"50374925",label:"Durazno perla grande orgánico en lata o en frasco"},
    {prodId:"50374926",label:"Durazno hardired orgánico en lata o en frasco"},
    {prodId:"50374927",label:"Durazno miel de fuego orgánico en lata o en frasco"},
    {prodId:"50374928",label:"Durazno julio rojo orgánico en lata o en frasco"},
    {prodId:"50374929",label:"Durazno kay perla orgánico en lata o en frasco"},
    {prodId:"50374930",label:"Durazno key dulce orgánico en lata o en frasco"},
    {prodId:"50374931",label:"Durazno diamante mayo orgánico en lata o en frasco"},
    {prodId:"50374932",label:"Durazno mayfire orgánico en lata o en frasco"},
    {prodId:"50374933",label:"Durazno mayglo orgánico en lata o en frasco"},
    {prodId:"50374934",label:"Durazno mericrest orgánico en lata o en frasco"},
    {prodId:"50374935",label:"Durazno diamante rojo orgánico en lata o en frasco"},
    {prodId:"50374936",label:"Durazno oro rojo orgánico en lata o en frasco"},
    {prodId:"50374937",label:"Durazno jim rojo orgánico en lata o en frasco"},
    {prodId:"50374938",label:"Durazno roy rojo orgánico en lata o en frasco"},
    {prodId:"50374939",label:"Durazno rio rojo orgánico en lata o en frasco"},
    {prodId:"50374940",label:"Durazno diamante de rosa orgánico en lata o en frasco"},
    {prodId:"50374941",label:"Durazno rotal glo orgánico en lata o en frasco"},
    {prodId:"50374942",label:"Durazno diamante ryby orgánico en lata o en frasco"},
    {prodId:"50374943",label:"Durazno ruby dulce orgánico en lata o en frasco"},
    {prodId:"50374944",label:"Durazno joya ruddy orgánico en lata o en frasco"},
    {prodId:"50374945",label:"Durazno septiembre rojo orgánico en lata o en frasco"},
    {prodId:"50374946",label:"Durazno reina de nieve orgánico en lata o en frasco"},
    {prodId:"50374947",label:"Durazno primavera clara orgánico en lata o en frasco"},
    {prodId:"50374948",label:"Durazno primavera roja orgánico en lata o en frasco"},
    {prodId:"50374949",label:"Durazno verano rojiso orgánico en lata o en frasco"},
    {prodId:"50374950",label:"Durazno verano claro orgánico en lata o en frasco"},
    {prodId:"50374951",label:"Durazno verano diamante orgánico en lata o en frasco"},
    {prodId:"50374952",label:"Durazno verano fuego orgánico en lata o en frasco"},
    {prodId:"50374953",label:"Durazno verano grande orgánico en lata o en frasco"},
    {prodId:"50374954",label:"Durazno sunglo orgánico en lata o en frasco"},
    {prodId:"50374955",label:"Durazno fuego zee orgánico en lata o en frasco"},
    {prodId:"50374956",label:"Durazno zee glo orgánico en lata o en frasco"},
    {prodId:"50374957",label:"Durazno zeegrand orgánico en lata o en frasco"},
    {prodId:"50375000",label:"Naranjas orgánicas en lata o en frasco"},
    {prodId:"50375001",label:"Naranja áfrica agria orgánica en lata o en frasco"},
    {prodId:"50375002",label:"Naranja dulce amber orgánica en lata o en frasco"},
    {prodId:"50375003",label:"Naranja argentina agria orgánica en lata o en frasco"},
    {prodId:"50375004",label:"Naranja bahianinha orgánico en lata o en frasco"},
    {prodId:"50375005",label:"Naranja bergamot orgánica en lata o en frasco"},
    {prodId:"50375006",label:"Naranja berna orgánica en lata o en frasco"},
    {prodId:"50375007",label:"Naranja bigaradier apepu orgánica en lata o en frasco"},
    {prodId:"50375008",label:"Naranja agria dulce daidai orgánica en lata o en frasco"},
    {prodId:"50375009",label:"Naranja mono orgánica en lata o en frasco"},
    {prodId:"50375010",label:"Naranja sangre orgánica en lata o en frasco"},
    {prodId:"50375011",label:"Naranja california navel orgánica en lata o en frasco"},
    {prodId:"50375012",label:"Naranja cara cara orgánica en lata o en frasco"},
    {prodId:"50375013",label:"Naranja chinotto orgánica en lata o en frasco"},
    {prodId:"50375014",label:"Naranja sueño nevel orgánica en lata o en frasco"},
    {prodId:"50375015",label:"Naranja gou tou orgánica en lata o en frasco"},
    {prodId:"50375016",label:"Naranja hamlin orgánica en lata o en frasco"},
    {prodId:"50375017",label:"Naranja jaffa orgánica en lata o en frasco"},
    {prodId:"50375018",label:"Naranja jincheng orgánica en lata o en frasco"},
    {prodId:"50375019",label:"Naranja k-temprano orgánica en lata o en frasco"},
    {prodId:"50375020",label:"Naranja kona orgánica en lata o en frasco"},
    {prodId:"50375021",label:"Naranja navel tarde orgánica en lata o en frasco"},
    {prodId:"50375022",label:"Naranja valencia tarde orgánica en lata o en frasco"},
    {prodId:"50375023",label:"Naranja limequat orgánica en lata o en frasco"},
    {prodId:"50375024",label:"Naranja mar orgánica en lata o en frasco"},
    {prodId:"50375025",label:"Naranja malegold orgánica en lata o en frasco"},
    {prodId:"50375026",label:"Naranja moro orgánica en lata o en frasco"},
    {prodId:"50375027",label:"Naranja moro tabaco orgánica en lata o en frasco"},
    {prodId:"50375028",label:"Naranja navel orgánica en lata o en frasco"},
    {prodId:"50375029",label:"Naranja navelina orgánica en lata o en frasco"},
    {prodId:"50375030",label:"Naranja oro blanco orgánica en lata o en frasco"},
    {prodId:"50375031",label:"Naranja osceola orgánica en lata o en frasco"},
    {prodId:"50375032",label:"Naranja parson carmelito orgánica en lata o en frasco"},
    {prodId:"50375033",label:"Naranja pera orgánica en lata o en frasco"},
    {prodId:"50375034",label:"Naranjo pummulo orgánica en lata o en frasco"},
    {prodId:"50375035",label:"Naranja rhode rojo orgánica en lata o en frasco"},
    {prodId:"50375036",label:"Naranja roble orgánica en lata o en frasco"},
    {prodId:"50375037",label:"Naranja salustianas orgánica en lata o en frasco"},
    {prodId:"50375038",label:"Naranja sanguine orgánica en lata o en frasco"},
    {prodId:"50375039",label:"Naranja sanguinelli orgánica en lata o en frasco"},
    {prodId:"50375040",label:"Naranja cevilla orgánica en lata o en frasco"},
    {prodId:"50375041",label:"Naranja shamouti jaffa orgánica en lata o en frasco"},
    {prodId:"50375042",label:"Naranja tunis orgánica en lata o en frasco"},
    {prodId:"50375043",label:"Naranja valencia orgánica en lata o en frasco"},
    {prodId:"50375044",label:"Naranja washington navel orgánica en lata o en frasco"},
    {prodId:"50375100",label:"Papayas orgánicas en lata o en frasco"},
    {prodId:"50375101",label:"Papaya verde cocinar orgánica en lata o en frasco"},
    {prodId:"50375102",label:"Papaya maradol orgánica en lata o en frasco"},
    {prodId:"50375103",label:"Papaya amarillo mexicano orgánica en lata o en frasco"},
    {prodId:"50375104",label:"Papaya montaña orgánica en lata o en frasco"},
    {prodId:"50375105",label:"Papaya solo orgánica en lata o en frasco"},
    {prodId:"50375106",label:"Papaya tainung orgánica en lata o en frasco"},
    {prodId:"50375200",label:"Maracuyás orgánicas en lata o en frasco"},
    {prodId:"50375201",label:"Fruta maracuyá banana orgánica en lata o en frasco"},
    {prodId:"50375202",label:"Maracuyá flor azul orgánica en lata o en frasco"},
    {prodId:"50375203",label:"Maracuyá crackerjack orgánica en lata o en frasco"},
    {prodId:"50375204",label:"Maracuyá granadilla gigante orgánica en lata o en frasco"},
    {prodId:"50375205",label:"Maracuyá granadilla dorada orgánica en lata o en frasco"},
    {prodId:"50375206",label:"Maracuyá maypops orgánica en lata o en frasco"},
    {prodId:"50375207",label:"Maracuyá roja orgánica en lata o en frasco"},
    {prodId:"50375208",label:"Maracuyá granadilla dulce orgánica en lata o en frasco"},
    {prodId:"50375209",label:"Maracuyá sandia orgánica en lata o en frasco"},
    {prodId:"50375210",label:"Maracuyá  wing- steam orgánica en lata o en frasco"},
    {prodId:"50375300",label:"Duraznos orgánicos en lata o en frasco"},
    {prodId:"50375301",label:"Durazno escudo amber orgánico en lata o en frasco"},
    {prodId:"50375302",label:"Durazno nieve de abril orgánico en lata o en frasco"},
    {prodId:"50375303",label:"Durazno dama de agosto orgánico en lata o en frasco"},
    {prodId:"50375304",label:"Durazno llama de otoño orgánico en lata o en frasco"},
    {prodId:"50375305",label:"Durazno dama de otoño orgánico en lata o en frasco"},
    {prodId:"50375306",label:"Durazno babcock orgánico en lata o en frasco"},
    {prodId:"50375307",label:"Durazno brittany lane orgánico en lata o en frasco"},
    {prodId:"50375308",label:"Durazno cary mac orgánico en lata o en frasco"},
    {prodId:"50375309",label:"Durazno clásica orgánico en lata o en frasco"},
    {prodId:"50375310",label:"Durazno dulce del campo orgánico en lata o en frasco"},
    {prodId:"50375311",label:"Durazno escudo de cielo orgánico en lata o en frasco"},
    {prodId:"50375312",label:"Durazno dama crimson orgánico en lata o en frasco"},
    {prodId:"50375313",label:"Durazno príncipe corona orgánico en lata o en frasco"},
    {prodId:"50375314",label:"Durazno sol david orgánico en lata o en frasco"},
    {prodId:"50375315",label:"Durazno  princesa diamante orgánico en lata o en frasco"},
    {prodId:"50375316",label:"Durazno earlrich orgánico en lata o en frasco"},
    {prodId:"50375317",label:"Durazno majestuosa temprana orgánico en lata o en frasco"},
    {prodId:"50375318",label:"Durazno early treat orgánico en lata o en frasco"},
    {prodId:"50375319",label:"Durazno dama elegante orgánico en lata o en frasco"},
    {prodId:"50375320",label:"Durazno emperatriz orgánico en lata o en frasco"},
    {prodId:"50375321",label:"Durazno encoré orgánico en lata o en frasco"},
    {prodId:"50375322",label:"Durazno dama elegante orgánico en lata o en frasco"},
    {prodId:"50375323",label:"Durazno príncipe de fuego orgánico en lata o en frasco"},
    {prodId:"50375324",label:"Durazno escudo de llama orgánico en lata o en frasco"},
    {prodId:"50375325",label:"Durazno tipo plano orgánico en lata o en frasco"},
    {prodId:"50375326",label:"Durazno escudo de sabor orgánico en lata o en frasco"},
    {prodId:"50375327",label:"Durazno príncipe florida orgánico en lata o en frasco"},
    {prodId:"50375328",label:"Durazno luna llena orgánico en lata o en frasco"},
    {prodId:"50375329",label:"Durazno harvester orgánico en lata o en frasco"},
    {prodId:"50375330",label:"Durazno princesa de hielo orgánico en lata o en frasco"},
    {prodId:"50375331",label:"Durazno princesa de marfil orgánico en lata o en frasco"},
    {prodId:"50375332",label:"Durazno princesa reina jersey orgánico en lata o en frasco"},
    {prodId:"50375333",label:"Durazno john henry orgánico en lata o en frasco"},
    {prodId:"50375334",label:"Durazno príncipe de junio orgánico en lata o en frasco"},
    {prodId:"50375335",label:"Durazno kaweah orgánico en lata o en frasco"},
    {prodId:"50375336",label:"Durazno klondike orgánico en lata o en frasco"},
    {prodId:"50375337",label:"Durazno lindo orgánico en lata o en frasco"},
    {prodId:"50375338",label:"Durazno loring orgánico en lata o en frasco"},
    {prodId:"50375339",label:"Durazno majestuoso orgánico en lata o en frasco"},
    {prodId:"50375340",label:"Durazno o'henry orgánico en lata o en frasco"},
    {prodId:"50375341",label:"Durazno escudo de reina orgánico en lata o en frasco"},
    {prodId:"50375342",label:"Durazno dama roja orgánico en lata o en frasco"},
    {prodId:"50375343",label:"Durazno globo rojo orgánico en lata o en frasco"},
    {prodId:"50375344",label:"Durazno cielo rojo orgánico en lata o en frasco"},
    {prodId:"50375345",label:"Durazno redtop orgánico en lata o en frasco"},
    {prodId:"50375346",label:"Durazno regina orgánico en lata o en frasco"},
    {prodId:"50375347",label:"Durazno dama rica orgánico en lata o en frasco"},
    {prodId:"50375348",label:"Durazno mayo rico orgánico en lata o en frasco"},
    {prodId:"50375349",label:"Durazno gloria royal orgánico en lata o en frasco"},
    {prodId:"50375350",label:"Durazno dama royal orgánico en lata o en frasco"},
    {prodId:"50375351",label:"Durazno nieve de septiembre orgánico en lata o en frasco"},
    {prodId:"50375352",label:"Durazno sol de septiembre orgánico en lata o en frasco"},
    {prodId:"50375353",label:"Durazno gema sierra orgánico en lata o en frasco"},
    {prodId:"50375354",label:"Durazno angel de nieve orgánico en lata o en frasco"},
    {prodId:"50375355",label:"Durazno gema de nieve orgánico en lata o en frasco"},
    {prodId:"50375356",label:"Durazno rey de nieve orgánico en lata o en frasco"},
    {prodId:"50375357",label:"Durazno dama de primavera orgánico en lata o en frasco"},
    {prodId:"50375358",label:"Durazno nieve de primavera orgánico en lata o en frasco"},
    {prodId:"50375359",label:"Durazno escudo de primavera orgánico en lata o en frasco"},
    {prodId:"50375360",label:"Durazno dulce gigante orgánico en lata o en frasco"},
    {prodId:"50375361",label:"Durazno dama de azúcar orgánico en lata o en frasco"},
    {prodId:"50375362",label:"Durazno brillo de sol orgánico en lata o en frasco"},
    {prodId:"50375363",label:"Durazno sunhigh orgánico en lata o en frasco"},
    {prodId:"50375364",label:"Durazno dama super orgánico en lata o en frasco"},
    {prodId:"50375365",label:"Durazno super rico orgánico en lata o en frasco"},
    {prodId:"50375366",label:"Durazno surecrop orgánico en lata o en frasco"},
    {prodId:"50375367",label:"Durazno sueño dulce orgánico en lata o en frasco"},
    {prodId:"50375368",label:"Durazno septiembre dulce orgánico en lata o en frasco"},
    {prodId:"50375369",label:"Durazno vista orgánico en lata o en frasco"},
    {prodId:"50375370",label:"Durazno dama blanca orgánico en lata o en frasco"},
    {prodId:"50375371",label:"Durazno dama zee orgánico en lata o en frasco"},
    {prodId:"50375400",label:"Peras orgánicas en lata o en frasco"},
    {prodId:"50375401",label:"Peras abate fetel orgánica en lata o en frasco"},
    {prodId:"50375402",label:"Peras anjou orgánica en lata o en frasco"},
    {prodId:"50375403",label:"Pera asiática orgánica en lata o en frasco"},
    {prodId:"50375404",label:"Pera bartlett orgánica en lata o en frasco"},
    {prodId:"50375405",label:"Pera best ever orgánica en lata o en frasco"},
    {prodId:"50375406",label:"Pera beth orgánica en lata o en frasco"},
    {prodId:"50375407",label:"Pera beurre orgánica en lata o en frasco"},
    {prodId:"50375408",label:"Pera bosc orgánica en lata o en frasco"},
    {prodId:"50375409",label:"Pera clapp favorita orgánica en lata o en frasco"},
    {prodId:"50375410",label:"Pera comice orgánica en lata o en frasco"},
    {prodId:"50375411",label:"Pera concorde orgánica en lata o en frasco"},
    {prodId:"50375412",label:"Pera conference orgánica en lata o en frasco"},
    {prodId:"50375413",label:"Pera crimson rojo orgánica en lata o en frasco"},
    {prodId:"50375414",label:"Peras d' anjou orgánica en lata o en frasco"},
    {prodId:"50375415",label:"Pera dr jules guyot orgánica en lata o en frasco"},
    {prodId:"50375416",label:"Peras early orgánica en lata o en frasco"},
    {prodId:"50375417",label:"Peras emperador carmelito orgánica en lata o en frasco"},
    {prodId:"50375418",label:"Peras forelle orgánica en lata o en frasco"},
    {prodId:"50375419",label:"Pera mantequilla francesa orgánica en lata o en frasco"},
    {prodId:"50375420",label:"Pera glou morceau orgánica en lata o en frasco"},
    {prodId:"50375421",label:"Pera hosui orgánica en lata o en frasco"},
    {prodId:"50375422",label:"Pera mantequilla italiana orgánica en lata o en frasco"},
    {prodId:"50375423",label:"Pera jargonelle orgánica en lata o en frasco"},
    {prodId:"50375424",label:"Pera juno orgánica en lata o en frasco"},
    {prodId:"50375425",label:"Para kaiserlouise bonne de jersey orgánica en lata o en frasco"},
    {prodId:"50375426",label:"Pera keiffer orgánica en lata o en frasco"},
    {prodId:"50375427",label:"Pera rey royal orgánica en lata o en frasco"},
    {prodId:"50375428",label:"Pera limonera orgánica en lata o en frasco"},
    {prodId:"50375429",label:"Pera merton pride orgánica en lata o en frasco"},
    {prodId:"50375430",label:"Pera mauntain bartlette orgánica en lata o en frasco"},
    {prodId:"50375431",label:"Pera oliver de serrers orgánica en lata o en frasco"},
    {prodId:"50375432",label:"Pera onward orgánica en lata o en frasco"},
    {prodId:"50375433",label:"Pera packham's triumph orgánica en lata o en frasco"},
    {prodId:"50375434",label:"Pera paraíso orgánica en lata o en frasco"},
    {prodId:"50375435",label:"Pera passe crassane orgánica en lata o en frasco"},
    {prodId:"50375436",label:"Pera perry orgánica en lata o en frasco"},
    {prodId:"50375437",label:"Pera bartlette rojo orgánica en lata o en frasco"},
    {prodId:"50375438",label:"Pera dánjou  rojo orgánica en lata o en frasco"},
    {prodId:"50375439",label:"Pera rocha orgánica en lata o en frasco"},
    {prodId:"50375440",label:"Pera rosey rojo orgánica en lata o en frasco"},
    {prodId:"50375441",label:"Pera rosy roja orgánica en lata o en frasco"},
    {prodId:"50375442",label:"Pera majestuosa royal orgánica en lata o en frasco"},
    {prodId:"50375443",label:"Pera ruby rojo orgánica en lata o en frasco"},
    {prodId:"50375444",label:"Pera santa maria orgánica en lata o en frasco"},
    {prodId:"50375445",label:"Pera seckel orgánica en lata o en frasco"},
    {prodId:"50375446",label:"Pera sensación orgánica en lata o en frasco"},
    {prodId:"50375447",label:"Pera crimson estrella orgánica en lata o en frasco"},
    {prodId:"50375448",label:"Pera crimson stark orgánica en lata o en frasco"},
    {prodId:"50375449",label:"Pera bartlette de verano orgánica en lata o en frasco"},
    {prodId:"50375450",label:"Pera verano dorado orgánica en lata o en frasco"},
    {prodId:"50375451",label:"Pera sol dorado orgánica en lata o en frasco"},
    {prodId:"50375452",label:"Pera sunprite orgánica en lata o en frasco"},
    {prodId:"50375453",label:"Pera taylors dorado orgánica en lata o en frasco"},
    {prodId:"50375454",label:"Pera taylors rojo orgánica en lata o en frasco"},
    {prodId:"50375455",label:"Pera tientsin orgánica en lata o en frasco"},
    {prodId:"50375456",label:"Pera tosca orgánica en lata o en frasco"},
    {prodId:"50375457",label:"Pera warden  orgánica en lata o en frasco"},
    {prodId:"50375458",label:"Pera williams bon chretien orgánica en lata o en frasco"},
    {prodId:"50375459",label:"Pera williams orgánica en lata o en frasco"},
    {prodId:"50375460",label:"Pera nelis de invierno orgánica en lata o en frasco"},
    {prodId:"50375500",label:"Caquis orgánicos en lata o en frasco"},
    {prodId:"50375501",label:"Caqui americano orgánica en lata o en frasco"},
    {prodId:"50375502",label:"Caqui sapote negro orgánica en lata o en frasco"},
    {prodId:"50375503",label:"Caqui chapote / negro orgánica en lata o en frasco"},
    {prodId:"50375504",label:"Caqui dátale ciruela orgánica en lata o en frasco"},
    {prodId:"50375505",label:"Caqui fuyu orgánica en lata o en frasco"},
    {prodId:"50375506",label:"Caqui gigante fuyu orgánica en lata o en frasco"},
    {prodId:"50375507",label:"Caqui hachiya orgánica en lata o en frasco"},
    {prodId:"50375508",label:"Caqui mantequilla / mabolo orgánica en lata o en frasco"},
    {prodId:"50375509",label:"Caqui príncipe ito orgánica en lata o en frasco"},
    {prodId:"50375510",label:"Caqui brillante royal orgánica en lata o en frasco"},
    {prodId:"50375511",label:"Caqui sharon orgánica en lata o en frasco"},
    {prodId:"50375512",label:"Caqui triumph orgánica en lata o en frasco"},
    {prodId:"50375600",label:"Piñas orgánicas en lata o en frasco"},
    {prodId:"50375601",label:"Piña chirimoya orgánica en lata o en frasco"},
    {prodId:"50375602",label:"Piña dorada orgánica en lata o en frasco"},
    {prodId:"50375603",label:"Piña hilo orgánica en lata o en frasco"},
    {prodId:"50375604",label:"Piña kona sugarloaf orgánica en lata o en frasco"},
    {prodId:"50375605",label:"Piña reina natal orgánica en lata o en frasco"},
    {prodId:"50375606",label:"Piña pernabuco orgánica en lata o en frasco"},
    {prodId:"50375607",label:"Piña español rojo orgánica en lata o en frasco"},
    {prodId:"50375608",label:"Piña cayen suave orgánica en lata o en frasco"},
    {prodId:"50375609",label:"Piña sugarloaf orgánica en lata o en frasco"},
    {prodId:"50375610",label:"Piña variegated orgánica en lata o en frasco"},
    {prodId:"50375700",label:"Ciruelos Damascos orgánicos en lata o en frasco"},
    {prodId:"50375701",label:"Ciruelo / damasco negro kat orgánico en lata o en frasco"},
    {prodId:"50375702",label:"Ciruelo / damasco gusto azul orgánico en lata o en frasco"},
    {prodId:"50375703",label:"Ciruelo / damasco corazón de crimson orgánico en lata o en frasco"},
    {prodId:"50375704",label:"Ciruelo / damasco dapply dandy orgánico en lata o en frasco"},
    {prodId:"50375705",label:"Ciruelo / damasco dapple fuego orgánico en lata o en frasco"},
    {prodId:"50375706",label:"Ciruelo / damasco  dapple temprano orgánico en lata o en frasco"},
    {prodId:"50375707",label:"Ciruelo / damasco  caída de sabor orgánico en lata o en frasco"},
    {prodId:"50375708",label:"Ciruelo / damasco sabor de oro orgánico en lata o en frasco"},
    {prodId:"50375709",label:"Ciruelo / damasco sabor granada orgánico en lata o en frasco"},
    {prodId:"50375710",label:"Ciruelo / damasco sabor de corazón orgánico en lata o en frasco"},
    {prodId:"50375711",label:"Ciruelo / damasco joya de sabor orgánico en lata o en frasco"},
    {prodId:"50375712",label:"Ciruelo / damasco sabor de rey orgánico en lata o en frasco"},
    {prodId:"50375713",label:"Ciruelo / damasco sabor de reina orgánico en lata o en frasco"},
    {prodId:"50375714",label:"Ciruelo / damasco sabor supremo orgánico en lata o en frasco"},
    {prodId:"50375715",label:"Ciruelo / damasco sabor premio orgánico en lata o en frasco"},
    {prodId:"50375716",label:"Ciruelo / damasco saborella orgánico en lata o en frasco"},
    {prodId:"50375717",label:"Ciruelo / damasco saborrico orgánico en lata o en frasco"},
    {prodId:"50375718",label:"Ciruelo / damasco  sabor rosa orgánico en lata o en frasco"},
    {prodId:"50375719",label:"Ciruelo / damasco orgullo geo orgánico en lata o en frasco"},
    {prodId:"50375720",label:"Ciruelo / damasco kat rojo orgánico en lata o en frasco"},
    {prodId:"50375721",label:"Ciruelo / damasco premio royal orgánico en lata o en frasco"},
    {prodId:"50375722",label:"Ciruelo / damasco rosa sierra orgánico en lata o en frasco"},
    {prodId:"50375723",label:"Ciruelo / damasco geisha dulce orgánico en lata o en frasco"},
    {prodId:"50375800",label:"Ciruelas orgánicas en lata o en frasco"},
    {prodId:"50375801",label:"Ciruela joya amber orgánica en lata o en frasco"},
    {prodId:"50375802",label:"Ciruela angeleno orgánica en lata o en frasco"},
    {prodId:"50375803",label:"Ciruela aurora orgánica en lata o en frasco"},
    {prodId:"50375804",label:"Ciruela otoño bonito orgánica en lata o en frasco"},
    {prodId:"50375805",label:"Ciruela gigante de otoño orgánica en lata o en frasco"},
    {prodId:"50375806",label:"Ciruela orgullo de otoño orgánica en lata o en frasco"},
    {prodId:"50375807",label:"Ciruela rosa de otoño orgánica en lata o en frasco"},
    {prodId:"50375808",label:"Ciruela playero orgánica en lata o en frasco"},
    {prodId:"50375809",label:"Ciruela betty anne orgánica en lata o en frasco"},
    {prodId:"50375810",label:"Ciruela belleza negra orgánica en lata o en frasco"},
    {prodId:"50375811",label:"Ciruela bullase negra orgánica en lata o en frasco"},
    {prodId:"50375812",label:"Ciruela diamante negro orgánica en lata o en frasco"},
    {prodId:"50375813",label:"Ciruela gigante negro orgánica en lata o en frasco"},
    {prodId:"50375814",label:"Ciruela hielo negro orgánica en lata o en frasco"},
    {prodId:"50375815",label:"Ciruela esplendor negro orgánica en lata o en frasco"},
    {prodId:"50375816",label:"Ciruela ámbar negro orgánica en lata o en frasco"},
    {prodId:"50375817",label:"Ciruela purpura orgánica en lata o en frasco"},
    {prodId:"50375818",label:"Ciruela  carsbad orgánica en lata o en frasco"},
    {prodId:"50375819",label:"Ciruela casselman orgánica en lata o en frasco"},
    {prodId:"50375820",label:"Ciruela catalina orgánica en lata o en frasco"},
    {prodId:"50375821",label:"Ciruela damson orgánica en lata o en frasco"},
    {prodId:"50375822",label:"Ciruela dolly orgánica en lata o en frasco"},
    {prodId:"50375823",label:"Ciruela earliqueen orgánica en lata o en frasco"},
    {prodId:"50375824",label:"Ciruela rosa early orgánica en lata o en frasco"},
    {prodId:"50375825",label:"Ciruela ébano mayo orgánica en lata o en frasco"},
    {prodId:"50375826",label:"Ciruela ébano orgánica en lata o en frasco"},
    {prodId:"50375827",label:"Ciruela corazón de elefante orgánica en lata o en frasco"},
    {prodId:"50375828",label:"Ciruela belleza de esmeralda orgánica en lata o en frasco"},
    {prodId:"50375829",label:"Ciruela emperatriz orgánica en lata o en frasco"},
    {prodId:"50375830",label:"Ciruela libertad orgánica en lata o en frasco"},
    {prodId:"50375831",label:"Ciruela friar orgánica en lata o en frasco"},
    {prodId:"50375832",label:"Ciruela rojo gar orgánica en lata o en frasco"},
    {prodId:"50375833",label:"Ciruela gobernador orgánica en lata o en frasco"},
    {prodId:"50375834",label:"Ciruela rosa grande orgánica en lata o en frasco"},
    {prodId:"50375835",label:"Ciruela green gage orgánica en lata o en frasco"},
    {prodId:"50375836",label:"Ciruela greengage orgánica en lata o en frasco"},
    {prodId:"50375837",label:"Ciruela hiromi orgánica en lata o en frasco"},
    {prodId:"50375838",label:"Ciruela hiromi rojo orgánica en lata o en frasco"},
    {prodId:"50375839",label:"Ciruela vacacion orgánica en lata o en frasco"},
    {prodId:"50375840",label:"Ciruela howard sol orgánica en lata o en frasco"},
    {prodId:"50375841",label:"Ciruela tipo interspecific orgánica en lata o en frasco"},
    {prodId:"50375842",label:"Ciruela jamaico orgánica en lata o en frasco"},
    {prodId:"50375843",label:"Ciruela joanna rojo orgánica en lata o en frasco"},
    {prodId:"50375844",label:"Ciruela kelsey orgánica en lata o en frasco"},
    {prodId:"50375845",label:"Ciruela jaime rey orgánica en lata o en frasco"},
    {prodId:"50375846",label:"Ciruela laroda orgánica en lata o en frasco"},
    {prodId:"50375847",label:"Ciruela rosa tarde orgánica en lata o en frasco"},
    {prodId:"50375848",label:"Ciruela rosa linda orgánica en lata o en frasco"},
    {prodId:"50375849",label:"Ciruela estrella solitaria orgánica en lata o en frasco"},
    {prodId:"50375850",label:"Ciruela mariposa orgánica en lata o en frasco"},
    {prodId:"50375851",label:"Ciruela mercado negro orgánica en lata o en frasco"},
    {prodId:"50375852",label:"Ciruela mercado rojo orgánica en lata o en frasco"},
    {prodId:"50375853",label:"Ciruela maribel orgánica en lata o en frasco"},
    {prodId:"50375854",label:"Ciruelas sol de octubre orgánica en lata o en frasco"},
    {prodId:"50375855",label:"Ciruela owen t orgánica en lata o en frasco"},
    {prodId:"50375856",label:"Ciruela perdrigon orgánica en lata o en frasco"},
    {prodId:"50375857",label:"Ciruela placer rosado orgánica en lata o en frasco"},
    {prodId:"50375858",label:"Ciruela  presidente orgánica en lata o en frasco"},
    {prodId:"50375859",label:"Ciruela hora prima orgánica en lata o en frasco"},
    {prodId:"50375860",label:"Ciruela majestad purpura orgánica en lata o en frasco"},
    {prodId:"50375861",label:"Ciruela reina rosa orgánica en lata o en frasco"},
    {prodId:"50375862",label:"Ciruela quetsch orgánica en lata o en frasco"},
    {prodId:"50375863",label:"Ciruela belleza roja orgánica en lata o en frasco"},
    {prodId:"50375864",label:"Ciruela camino rojo orgánica en lata o en frasco"},
    {prodId:"50375865",label:"Ciruela ram rojo orgánica en lata o en frasco"},
    {prodId:"50375866",label:"Ciruela rosa roja orgánica en lata o en frasco"},
    {prodId:"50375867",label:"Ciruela rojo rico orgánica en lata o en frasco"},
    {prodId:"50375868",label:"Ciruela romero orgánica en lata o en frasco"},
    {prodId:"50375869",label:"Ciruela diamante rojo orgánica en lata o en frasco"},
    {prodId:"50375870",label:"Ciruela rojo royal orgánica en lata o en frasco"},
    {prodId:"50375871",label:"Ciruela royal zee orgánica en lata o en frasco"},
    {prodId:"50375872",label:"Ciruela roysum orgánica en lata o en frasco"},
    {prodId:"50375873",label:"Ciruela santa rosa orgánica en lata o en frasco"},
    {prodId:"50375874",label:"Ciruela zafiro orgánica en lata o en frasco"},
    {prodId:"50375875",label:"Ciruela sloe orgánica en lata o en frasco"},
    {prodId:"50375876",label:"Ciruela sta catherine orgánica en lata o en frasco"},
    {prodId:"50375877",label:"Ciruela bullase blanco orgánica en lata o en frasco"},
    {prodId:"50375900",label:"Granadas orgánicas en lata o en frasco"},
    {prodId:"50375901",label:"Granada foothhill orgánica en lata o en frasco"},
    {prodId:"50375902",label:"Granada, granada orgánica en lata o en frasco"},
    {prodId:"50375903",label:"Granada rojo feliz orgánica en lata o en frasco"},
    {prodId:"50375904",label:"Granada nana orgánica en lata o en frasco"},
    {prodId:"50375905",label:"Granada rojo de pat orgánica en lata o en frasco"},
    {prodId:"50375906",label:"Granada pinkhan orgánic"},
    {prodId:"50375907",label:"Granada terciopelo morado orgánica en lata o en frasco"},
    {prodId:"50375908",label:"Granada grandioso orgánica en lata o en frasco"},
    {prodId:"50376000",label:"Pomelos orgánicos en lata o en frasco"},
    {prodId:"50376001",label:"Pomelo chandler orgánico en lata o en frasco"},
    {prodId:"50376002",label:"Pomelo hirado butan orgánico en lata o en frasco"},
    {prodId:"50376003",label:"Pomelo liang ping yau orgánico en lata o en frasco"},
    {prodId:"50376004",label:"Pomelo panda wangi orgánico en lata o en frasco"},
    {prodId:"50376005",label:"Pomelo rosado orgánico en lata o en frasco"},
    {prodId:"50376006",label:"Pomelo shaddock rojo orgánico en lata o en frasco"},
    {prodId:"50376007",label:"Pomelo siamese dulce orgánico en lata o en frasco"},
    {prodId:"50376008",label:"Pomelo waingwright orgánico en lata o en frasco"},
    {prodId:"50376100",label:"Membrillos orgánicos en lata o en frasco"},
    {prodId:"50376101",label:"Membrillo campeón orgánico en lata o en frasco"},
    {prodId:"50376102",label:"Membrillo piña orgánico en lata o en frasco"},
    {prodId:"50376103",label:"Membrillo smyma orgánico en lata o en frasco"},
    {prodId:"50376200",label:"Frambuesas orgánicas en lata o en frasco"},
    {prodId:"50376201",label:"Frambuesa americana roja orgánica en lata o en frasco"},
    {prodId:"50376202",label:"Frambuesa bailey queensland orgánica en lata o en frasco"},
    {prodId:"50376203",label:"Frambuesa negra orgánica en lata o en frasco"},
    {prodId:"50376204",label:"Frambuesa oscura orgánica en lata o en frasco"},
    {prodId:"50376205",label:"Frambuesa deliciosa orgánica en lata o en frasco"},
    {prodId:"50376206",label:"Frambuesa enano focke orgánica en lata o en frasco"},
    {prodId:"50376207",label:"Frambuesa focke hojagris orgánica en lata o en frasco"},
    {prodId:"50376208",label:"Frambuesa focke fresa orgánica en lata o en frasco"},
    {prodId:"50376209",label:"Frambuesa  focke amarillo himalaya orgánica en lata o en frasco"},
    {prodId:"50376210",label:"Frambuesa dorado orgánica en lata o en frasco"},
    {prodId:"50376211",label:"Frambuesa gris nuevo mexico orgánica en lata o en frasco"},
    {prodId:"50376212",label:"Frambuesa jepson whitebark orgánica en lata o en frasco"},
    {prodId:"50376213",label:"Frambuesa kellogs san diego orgánica en lata o en frasco"},
    {prodId:"50376214",label:"Frambuesa leucodemis whitebark orgánica en lata o en frasco"},
    {prodId:"50376215",label:"Frambuesa munz cuyamaca orgánica en lata o en frasco"},
    {prodId:"50376216",label:"Frambuesa peck bartons orgánica en lata o en frasco"},
    {prodId:"50376217",label:"Frambuesa flor morada orgánica en lata o en frasco"},
    {prodId:"50376218",label:"Frambuesa roadside orgánica en lata o en frasco"},
    {prodId:"50376219",label:"Frambuesa san diego orgánica en lata o en frasco"},
    {prodId:"50376220",label:"Frambuesa nieve orgánica en lata o en frasco"},
    {prodId:"50376221",label:"Frambuesa pico de nieve orgánica en lata o en frasco"},
    {prodId:"50376222",label:"Frambuesa hoja de fresa orgánica en lata o en frasco"},
    {prodId:"50376223",label:"Frambuesa cultivo dulce orgánica en lata o en frasco"},
    {prodId:"50376224",label:"Frambuesa tor y gris whitebark orgánica en lata o en frasco"},
    {prodId:"50376225",label:"Frambuesa caribe orgánica en lata o en frasco"},
    {prodId:"50376226",label:"Frambuesa whitebark orgánica en lata o en frasco"},
    {prodId:"50376227",label:"Frambuesa vino orgánica en lata o en frasco"},
    {prodId:"50376228",label:"Frambuesa himalaya amarillo orgánica en lata o en frasco"},
    {prodId:"50376229",label:"Frambuesa yu-shan orgánica en lata o en frasco"},
    {prodId:"50376300",label:"Ruibarbos orgánicos en lata o en frasco"},
    {prodId:"50376301",label:"Ruibarbo crimson rojo orgánico en lata o en frasco"},
    {prodId:"50376302",label:"Ruibarbo champagne temprana orgánico en lata o en frasco"},
    {prodId:"50376303",label:"Ruibarbo glasking perpetual  orgánico en lata o en frasco"},
    {prodId:"50376304",label:"Ruibarbo sutton orgánico en lata o en frasco"},
    {prodId:"50376305",label:"Ruibarbo timperley temprano orgánico en lata o en frasco"},
    {prodId:"50376306",label:"Ruibarbo valentina orgánico en lata o en frasco"},
    {prodId:"50376307",label:"Ruibarbo victoria orgánico en lata o en frasco"},
    {prodId:"50376308",label:"Ruibarbo zwolle de cemilla orgánico en lata o en frasco"},
    {prodId:"50376309",label:"Ruibarbo macdonald orgánico en lata o en frasco"},
    {prodId:"50376310",label:"Ruibarbo tilden orgánico en lata o en frasco"},
    {prodId:"50376400",label:"Escaramujos orgánicos en lata o en frasco"},
    {prodId:"50376401",label:"Escaramujo rosa brier orgánico en lata o en frasco"},
    {prodId:"50376402",label:"Escaramujo rosa elegante orgánico en lata o en frasco"},
    {prodId:"50376403",label:"Escaramujo rosa rugosa orgánico en lata o en frasco"},
    {prodId:"50376404",label:"Escaramujo rosa burnet o scotch orgánico en lata o en frasco"},
    {prodId:"50376500",label:"Sapotes orgánicos en lata o en frasco"},
    {prodId:"50376501",label:"Zapote blanco orgánico en lata o en frasco"},
    {prodId:"50376502",label:"Zapote negro orgánico en lata o en frasco"},
    {prodId:"50376600",label:"Bayas saskatoon orgánicas en lata o en frasco"},
    {prodId:"50376601",label:"Baya mielmadera saskatoon orgánica en lata o en frasco"},
    {prodId:"50376602",label:"Baya northline saskatoon orgánica en lata o en frasco"},
    {prodId:"50376603",label:"Baya saskatoon ahumado orgánica en lata o en frasco"},
    {prodId:"50376604",label:"Baya saskatoon thiessen orgánica en lata o en frasco"},
    {prodId:"50376800",label:"Manzanas dulces orgánicas en lata o en frasco"},
    {prodId:"50376801",label:"Manzana kampong malve dulce orgánica en lata o en frasco"},
    {prodId:"50376802",label:"Manzana dulce sin pepa orgánica en lata o en frasco"},
    {prodId:"50376803",label:"Manzana dulce thai lessand orgánica en lata o en frasco"},
    {prodId:"50376900",label:"Tamarillos orgánicos en lata o en frasco"},
    {prodId:"50376901",label:"Tamarindo amberlea dorado orgánico en lata o en frasco"},
    {prodId:"50376902",label:"Tamarindo calvo dorado orgánico en lata o en frasco"},
    {prodId:"50376903",label:"Tamarindo mina de oro orgánico en lata o en frasco"},
    {prodId:"50376904",label:"Tamarindo oratia rojo orgánico en lata o en frasco"},
    {prodId:"50376905",label:"Tamarindo beau rojo orgánico en lata o en frasco"},
    {prodId:"50376906",label:"Tamarindo delicia roja orgánico en lata o en frasco"},
    {prodId:"50377100",label:"Aronias orgánicas en lata o en frasco"},
    {prodId:"50377101",label:"Aronia magia de otoño orgánica en lata o en frasco"},
    {prodId:"50377102",label:"Aronia brillantísima orgánica en lata o en frasco"},
    {prodId:"50377103",label:"Aronia nero orgánica en lata o en frasco"},
    {prodId:"50377104",label:"Aronia viquingo orgánica en lata o en frasco"},
    {prodId:"50377200",label:"Aceitunas orgánicas en lata o en frasco"},
    {prodId:"50377201",label:"Aceitunas agrinion orgánica en lata o en frasco"},
    {prodId:"50377202",label:"Aceitunas aleppo orgánica en lata o en frasco"},
    {prodId:"50377203",label:"Aceitunas  alfonso orgánica en lata o en frasco"},
    {prodId:"50377204",label:"Aceitunas  amfisa orgánica en lata o en frasco"},
    {prodId:"50377205",label:"Aceitunas  arauco orgánica en lata o en frasco"},
    {prodId:"50377206",label:"Aceitunas  arbequina orgánica en lata o en frasco"},
    {prodId:"50377207",label:"Aceitunas  atlanta orgánica en lata o en frasco"},
    {prodId:"50377208",label:"Aceitunas  cerignola orgánica en lata o en frasco"},
    {prodId:"50377209",label:"Aceitunas  cracked provencal orgánica en lata o en frasco"},
    {prodId:"50377210",label:"Aceitunas  empeltre orgánica en lata o en frasco"},
    {prodId:"50377211",label:"Aceitunas gaeta  orgánica en lata o en frasco"},
    {prodId:"50377212",label:"Aceitunas hondoelia orgánica en lata o en frasco"},
    {prodId:"50377213",label:"Aceitunas kalamata orgánica en lata o en frasco"},
    {prodId:"50377214",label:"Aceitunas kura orgánica en lata o en frasco"},
    {prodId:"50377215",label:"Aceitunas ligurian orgánica en lata o en frasco"},
    {prodId:"50377216",label:"Aceitunas lucque orgánica en lata o en frasco"},
    {prodId:"50377217",label:"Aceitunas lugano orgánica en lata o en frasco"},
    {prodId:"50377218",label:"Aceitunas manzanilla orgánica en lata o en frasco"},
    {prodId:"50377219",label:"Aceitunas marche orgánica en lata o en frasco"},
    {prodId:"50377220",label:"Aceitunas misión orgánica en lata o en frasco"},
    {prodId:"50377221",label:"Aceitunas nafplion verde orgánica en lata o en frasco"},
    {prodId:"50377222",label:"Aceitunas nicoise orgánica en lata o en frasco"},
    {prodId:"50377223",label:"Aceitunas nyons orgánica en lata o en frasco"},
    {prodId:"50377224",label:"Aceitunas picholine orgánica en lata o en frasco"},
    {prodId:"50377225",label:"Aceitunas ponentine orgánica en lata o en frasco"},
    {prodId:"50377226",label:"Aceitunas royal orgánica en lata o en frasco"},
    {prodId:"50377227",label:"Aceitunas seracena orgánica en lata o en frasco"},
    {prodId:"50377228",label:"Aceitunas sevillano orgánica en lata o en frasco"},
    {prodId:"50377229",label:"Aceitunas sicilian orgánica en lata o en frasco"},
    {prodId:"50377230",label:"Aceitunas toscanella orgánica en lata o en frasco"},
    {prodId:"50381500",label:"Purés de cítricos"},
    {prodId:"50381501",label:"Puré de naranja"},
    {prodId:"50381502",label:"Puré de lima"},
    {prodId:"50381503",label:"Puré de limón"},
    {prodId:"50381800",label:"Purés de melón"},
    {prodId:"50381801",label:"Puré de melón"},
    {prodId:"50381802",label:"Puré de sandía"},
    {prodId:"50402400",label:"Cardos"},
    {prodId:"50402401",label:"Cardos lunghi"},
    {prodId:"50402402",label:"Cardos gobbi"},
    {prodId:"50402900",label:"Achicorias"},
    {prodId:"50402901",label:"Achicoria batavian hoja ancha"},
    {prodId:"50402902",label:"Achicoria en cornet de bordeaux"},
    {prodId:"50402903",label:"Achicoria rollo verde ruffee"},
    {prodId:"50402904",label:"Achicoria roll verde"},
    {prodId:"50402905",label:"Achicoria limnoe lone"},
    {prodId:"50402906",label:"Achicoria pancalieri riccia"},
    {prodId:"50402907",label:"Achicoria ensalada rey"},
    {prodId:"50402908",label:"Achicoria sanda"},
    {prodId:"50402909",label:"Achicoria scarola verde"},
    {prodId:"50402910",label:"Achicoria tres fine maraichere"},
    {prodId:"50402911",label:"Achicoria wallone freisee wescelkkopf"},
    {prodId:"50403600",label:"Endivias"},
    {prodId:"50403601",label:"Endivias witloof de brucelas"},
    {prodId:"50403602",label:"Endivias francocastel"},
    {prodId:"50403603",label:"Endivias catalogna de galantina"},
    {prodId:"50403604",label:"Endivias chioggia"},
    {prodId:"50403605",label:"Endivias grumolo verde"},
    {prodId:"50403606",label:"Endivias raíz larga magdeburg"},
    {prodId:"50403607",label:"Endivias palla rosa zorzi precoce"},
    {prodId:"50403608",label:"Endivias radice amare"},
    {prodId:"50403609",label:"Endivias rosa de traviso"},
    {prodId:"50403610",label:"Endivias  rosa de verona"},
    {prodId:"50403611",label:"Endivias soncino"},
    {prodId:"50403612",label:"Endivias sugarthi"},
    {prodId:"50403613",label:"Endivias verona"},
    {prodId:"50403614",label:"Endivias witloof zoom"},
    {prodId:"50404700",label:"Ñames"},
    {prodId:"50404701",label:"Ñame amarilla"},
    {prodId:"50404702",label:"Ñame blanca"},
    {prodId:"50404703",label:"Ñame coco"},
    {prodId:"50404704",label:"Ñame eddoes"},
    {prodId:"50404705",label:"Ñame isleña"},
    {prodId:"50404706",label:"Ñame lila"},
    {prodId:"50404901",label:"Mostaza bambú"},
    {prodId:"50404904",label:"Mostaza rojo en nieve"},
    {prodId:"50405100",label:"Solanáceas (nightshades)"},
    {prodId:"50405102",label:"Mora de jardin"},
    {prodId:"50405103",label:"Naranjilla"},
    {prodId:"50405400",label:"Alverjas"},
    {prodId:"50405401",label:"Alverja casco morado"},
    {prodId:"50405402",label:"Alverja pinkeye"},
    {prodId:"50405403",label:"Alverja crowder"},
    {prodId:"50405404",label:"Alverja acre blanco"},
    {prodId:"50405405",label:"Alverja ojo negro"},
    {prodId:"50405406",label:"Alverja crema zipper"},
    {prodId:"50405629",label:"Pimentón peperoncini"},
    {prodId:"50405800",label:"Nabos suecos"},
    {prodId:"50405801",label:"Nabo sueco acme"},
    {prodId:"50405802",label:"Nabo sueco angela"},
    {prodId:"50405803",label:"Nabo sueco el mejor"},
    {prodId:"50405804",label:"Nabo sueco marian"},
    {prodId:"50405912",label:"Lechuga de mar"},
    {prodId:"50406000",label:"Chalotes"},
    {prodId:"50406001",label:"Chalote atlántica"},
    {prodId:"50406002",label:"Chalote creación"},
    {prodId:"50406003",label:"Chalote drittler nido blanco"},
    {prodId:"50406004",label:"Chalote gigante mejorado amarillo"},
    {prodId:"50406005",label:"Chalote gourmet dorado"},
    {prodId:"50406006",label:"Chalote grise de bagnolet"},
    {prodId:"50406007",label:"Chalote hative de nort"},
    {prodId:"50406008",label:"Chalote pikant"},
    {prodId:"50406009",label:"Chalote papa roja"},
    {prodId:"50406010",label:"Chalote sante"},
    {prodId:"50406011",label:"Chalote topper"},
    {prodId:"50406100",label:"Alazanes"},
    {prodId:"50406101",label:"Alazán dock"},
    {prodId:"50406102",label:"Alazán jardín"},
    {prodId:"50406103",label:"Alazán oveja"},
    {prodId:"50406104",label:"Alazán madera"},
    {prodId:"50406600",label:"Nabos"},
    {prodId:"50406601",label:"Nabo globo verde"},
    {prodId:"50406602",label:"Nabo bola dorada"},
    {prodId:"50406603",label:"Nabo mercado de manchester"},
    {prodId:"50406604",label:"Nabo top morado milan"},
    {prodId:"50406605",label:"Nabo top morado blanco"},
    {prodId:"50406606",label:"Nabo bola de nieve"},
    {prodId:"50406607",label:"Nabo tokio"},
    {prodId:"50406608",label:"Nabo cruz de tokio"},
    {prodId:"50406800",label:"Ñames"},
    {prodId:"50406801",label:"Ñame agria africana"},
    {prodId:"50406802",label:"Ñame agria asiática"},
    {prodId:"50406803",label:"Ñame china"},
    {prodId:"50406804",label:"Ñame globo"},
    {prodId:"50406805",label:"Ñame grater"},
    {prodId:"50406806",label:"Ñame japonesa"},
    {prodId:"50406807",label:"Ñame lesser"},
    {prodId:"50406808",label:"Ñame papa"},
    {prodId:"50406809",label:"Ñame guinea blanca"},
    {prodId:"50406810",label:"Ñame guinea amarillo"},
    {prodId:"50411500",label:"Alcachofas orgánicas"},
    {prodId:"50411501",label:"Alcachofa britany orgánica"},
    {prodId:"50411502",label:"Alcachofa cantonesa orgánica"},
    {prodId:"50411503",label:"Alcachofa francesa orgánica"},
    {prodId:"50411504",label:"Alcachofa globo verde orgánico"},
    {prodId:"50411505",label:"Alcachofa gros cumus de bretaña orgánico"},
    {prodId:"50411506",label:"Alcachofa midi orgánico"},
    {prodId:"50411507",label:"Alcachofa globo morado orgánico"},
    {prodId:"50411508",label:"Alcachofa siciliano morado orgánico"},
    {prodId:"50411509",label:"Alcachofa romanesco orgánico"},
    {prodId:"50411510",label:"Alcachofa espinoso sardo orgánico"},
    {prodId:"50411511",label:"Alcachofa vert de loan  orgánico"},
    {prodId:"50411512",label:"Alcachofa violeta di chioggia orgánica"},
    {prodId:"50411513",label:"Alcachofa violeta de toscana orgánica"},
    {prodId:"50411600",label:"Espárragos orgánicos"},
    {prodId:"50411601",label:"Espárragos convers colosal organico"},
    {prodId:"50411602",label:"Espárragos franklin orgánico"},
    {prodId:"50411603",label:"Espárragos mamut gigante orgánico"},
    {prodId:"50411604",label:"Espárragos lucullus orgánico"},
    {prodId:"50411605",label:"Espárragos martha washington orgánico"},
    {prodId:"50411700",label:"Aguacates orgánicos"},
    {prodId:"50411701",label:"Aguacate ajax b-7 orgánico"},
    {prodId:"50411702",label:"Aguacate arue orgánico"},
    {prodId:"50411703",label:"Aguacate bacon orgánico"},
    {prodId:"50411704",label:"Aguacate benik orgánico"},
    {prodId:"50411705",label:"Aguacate bemecker orgánico"},
    {prodId:"50411706",label:"Aguacate beta orgánico"},
    {prodId:"50411707",label:"Aguacate biondo orgánico"},
    {prodId:"50411708",label:"Aguacate príncipe negro orgánico"},
    {prodId:"50411709",label:"Aguacate blair orgánico"},
    {prodId:"50411710",label:"Aguacate blair booth orgánico"},
    {prodId:"50411711",label:"Aguacate booth 1 orgánico"},
    {prodId:"50411712",label:"Aguacate booth 3 orgánico"},
    {prodId:"50411713",label:"Aguacate  booth 5 orgánico"},
    {prodId:"50411714",label:"Aguacate booth 7 orgánico"},
    {prodId:"50411715",label:"Aguacate booth 8 orgánico"},
    {prodId:"50411716",label:"Aguacate brooks 1978 orgánico"},
    {prodId:"50411717",label:"Aguacate brookslate orgánico"},
    {prodId:"50411718",label:"Aguacate california haas orgánico"},
    {prodId:"50411719",label:"Aguacate catalina orgánico"},
    {prodId:"50411720",label:"Aguacate chica orgánic"},
    {prodId:"50411721",label:"Aguacate choquette orgánico"},
    {prodId:"50411722",label:"Aguacate cristina orgánico"},
    {prodId:"50411723",label:"Aguacate colison orgánico"},
    {prodId:"50411724",label:"Aguacate donie orgánico"},
    {prodId:"50411725",label:"Aguacate dr. depuis numero 2 orgánico"},
    {prodId:"50411726",label:"Aguacate de. depus orgánico"},
    {prodId:"50411727",label:"Aguacate etinger orgánico"},
    {prodId:"50411728",label:"Aguacate fuchs orgánico"},
    {prodId:"50411729",label:"Aguacate fuchs gwen orgánico"},
    {prodId:"50411730",label:"Aguacate fuerte orgánico"},
    {prodId:"50411731",label:"Aguacate gorham orgánico"},
    {prodId:"50411732",label:"Aguacate gossam orgánico"},
    {prodId:"50411733",label:"Aguacate gutemala sin pepe orgánico"},
    {prodId:"50411734",label:"Aguacate hall orgánico"},
    {prodId:"50411735",label:"Aguacate hardee orgánico"},
    {prodId:"50411736",label:"Aguacate haas orgánico"},
    {prodId:"50411737",label:"Aguacate herman orgánico"},
    {prodId:"50411738",label:"Aguacate hickson orgánico"},
    {prodId:"50411739",label:"Aguacate k- 5 orgánico"},
    {prodId:"50411740",label:"Aguacate k - 9 orgánico"},
    {prodId:"50411741",label:"Aguacate oveja haas orgánico"},
    {prodId:"50411742",label:"Aguacate leona orgánico"},
    {prodId:"50411743",label:"Aguacate linda leona orgánico"},
    {prodId:"50411744",label:"Aguacate lisa p orgánico"},
    {prodId:"50411745",label:"Aguacate lisa loretta orgánico"},
    {prodId:"50411746",label:"Aguacate loretta organico"},
    {prodId:"50411747",label:"Aguacate lula orgánico"},
    {prodId:"50411748",label:"Aguacate lula macarthur orgánico"},
    {prodId:"50411749",label:"Aguacate marcus orgánico"},
    {prodId:"50411750",label:"Aguacate melendez orgánico"},
    {prodId:"50411751",label:"Aguacate meya p orgánico"},
    {prodId:"50411752",label:"Aguacate miguel p orgánico"},
    {prodId:"50411753",label:"Aguacate monroe orgánico"},
    {prodId:"50411754",label:"Aguacate murrieta verde orgánico"},
    {prodId:"50411755",label:"Aguacate nabal orgánico"},
    {prodId:"50411756",label:"Aguacate nadir orgánico"},
    {prodId:"50411757",label:"Aguacate nesbitt orgánico"},
    {prodId:"50411758",label:"Aguacate peterson orgánico"},
    {prodId:"50411759",label:"Aguacate pinelli orgánico"},
    {prodId:"50411760",label:"Aguacate pinkerton orgánico"},
    {prodId:"50411761",label:"Aguacate pollock orgánico"},
    {prodId:"50411762",label:"Aguacate puebla orgánico"},
    {prodId:"50411763",label:"Aguacate reed orgánico"},
    {prodId:"50411764",label:"Aguacate rue orgánico"},
    {prodId:"50411765",label:"Aguacate ruehle orgánico"},
    {prodId:"50411766",label:"Aguacate ryan orgánico"},
    {prodId:"50411767",label:"Aguacate semil orgánico"},
    {prodId:"50411768",label:"Aguacate semil 43 orgánico"},
    {prodId:"50411769",label:"Aguacate simmond orgánico"},
    {prodId:"50411770",label:"Aguacate  simpson orgánico"},
    {prodId:"50411771",label:"Aguacate taylor orgánico"},
    {prodId:"50411772",label:"Aguacate tonelada orgánico"},
    {prodId:"50411773",label:"Aguacate torre orgánico"},
    {prodId:"50411774",label:"Aguacate torre li orgánico"},
    {prodId:"50411775",label:"Aguacate trapp orgánico"},
    {prodId:"50411776",label:"Aguacate caribe orgánico"},
    {prodId:"50411777",label:"Aguacate wagner orgánico"},
    {prodId:"50411778",label:"Aguacate waldin orgánico"},
    {prodId:"50411779",label:"Aguacate wurtz orgánico"},
    {prodId:"50411780",label:"Aguacate zio p orgánico"},
    {prodId:"50411781",label:"Aguacate ziu orgánico"},
    {prodId:"50411782",label:"Aguacate appaloosa orgánico"},
    {prodId:"50412200",label:"Cebollas de ensalada orgánicas"},
    {prodId:"50412201",label:"Cebolla belstville ensalada orgánica"},
    {prodId:"50412202",label:"Cebolla feast ensalada orgánica"},
    {prodId:"50412203",label:"Cebolla ishikura ensalada orgánica"},
    {prodId:"50412204",label:"Cebolla mercado kyoto orgánica"},
    {prodId:"50412205",label:"Cebolla barba roja ensalada orgánica"},
    {prodId:"50412206",label:"Cebolla amigo rojo ensalada orgánica"},
    {prodId:"50412207",label:"Cebolla santa claus orgánica"},
    {prodId:"50412208",label:"Cebolla tokyo ensalada orgánica"},
    {prodId:"50412209",label:"Cebolla lisbon blanca ensalada orgánica"},
    {prodId:"50412210",label:"Cebolla invierno blanca ensalada orgánica"},
    {prodId:"50412211",label:"Cebolla final de invierno ensalada orgánica"},
    {prodId:"50412300",label:"Repollos orgánicos"},
    {prodId:"50412301",label:"Repollo negro orgánico"},
    {prodId:"50412302",label:"Repollo savoy orgánico"},
    {prodId:"50412303",label:"Repollo zorrillo orgánico"},
    {prodId:"50412304",label:"Repollo blanco orgánico"},
    {prodId:"50412400",label:"Cardos orgánicos"},
    {prodId:"50412401",label:"Cardos lunghi orgánico"},
    {prodId:"50412402",label:"Cardos gobbi orgánico"},
    {prodId:"50412500",label:"Zanahorias orgánicas"},
    {prodId:"50412501",label:"Zanahoria amsterdam orgánica"},
    {prodId:"50412502",label:"Zanahoria rey de otoño orgánica"},
    {prodId:"50412503",label:"Zanahoria berlicum orgánica"},
    {prodId:"50412504",label:"Zanahoria chantenay orgánica"},
    {prodId:"50412505",label:"Zanahoria nantes orgánica"},
    {prodId:"50412506",label:"Zanahoria mercado de parís orgánica"},
    {prodId:"50412600",label:"Coliflores orgánicas"},
    {prodId:"50412601",label:"Coliflor todo el año orgánica"},
    {prodId:"50412602",label:"Coliflor alverda orgánica"},
    {prodId:"50412603",label:"Coliflor otoño gigante 3 orgánica"},
    {prodId:"50412604",label:"Coliflor dok elgon orgánica"},
    {prodId:"50412605",label:"Coliflor bola de nieve temprana orgánica"},
    {prodId:"50412606",label:"Coliflor luz de lima orgánica"},
    {prodId:"50412607",label:"Coliflor minaret orgánica"},
    {prodId:"50412608",label:"Coliflor buque de naranja orgánica"},
    {prodId:"50412609",label:"Coliflor capa morada orgánica"},
    {prodId:"50412610",label:"Coliflor bola de nieve orgánica"},
    {prodId:"50412611",label:"Coliflor invierno walcheren 3 orgánica"},
    {prodId:"50412612",label:"Coliflor piedra blanca orgánica"},
    {prodId:"50412700",label:"Apios orgánicos"},
    {prodId:"50412701",label:"Apio celebridad orgánico"},
    {prodId:"50412702",label:"Apio  orgánico"},
    {prodId:"50412703",label:"Apio chino orgánico"},
    {prodId:"50412704",label:"Apio dinant francés orgánico"},
    {prodId:"50412705",label:"Apio gigante rosado orgánico"},
    {prodId:"50412706",label:"Apio gigante rojo orgánico"},
    {prodId:"50412707",label:"Apio gigante blanco orgánico"},
    {prodId:"50412708",label:"Apio dorado auto escalado orgánico"},
    {prodId:"50412709",label:"Apio greensleeves orgánico"},
    {prodId:"50412710",label:"Apio hopkins fenlander orgánico"},
    {prodId:"50412711",label:"Apio torre marfil orgánico"},
    {prodId:"50412712",label:"Apio lathom blanqueado orgánico"},
    {prodId:"50412713",label:"Apio sopa de amsterdam orgánico"},
    {prodId:"50412714",label:"Apio estándar cargado orgánico"},
    {prodId:"50412715",label:"Apio triunfo alto utah orgánico"},
    {prodId:"50412900",label:"Achicorias  orgánicas"},
    {prodId:"50412901",label:"Achicoria batavian hoja ancha orgánica"},
    {prodId:"50412902",label:"Achicoria en cornet de bordeaux orgánica"},
    {prodId:"50412903",label:"Achicoria rollo verde ruffee orgánica"},
    {prodId:"50412904",label:"Achicoria roll verde orgánica"},
    {prodId:"50412905",label:"Achicoria limnoe lone orgánica"},
    {prodId:"50412906",label:"Achicoria pancalieri riccia orgánica"},
    {prodId:"50412907",label:"Achicoria ensalada rey orgánica"},
    {prodId:"50412908",label:"Achicoria sanda orgánica"},
    {prodId:"50412909",label:"Achicoria scarola verde orgánica"},
    {prodId:"50412910",label:"Achicoria tres fine maraichere orgánica"},
    {prodId:"50412911",label:"Achicoria wallone freisee wescelkkopf orgánica"},
    {prodId:"50413000",label:"Repollos chinos orgánicos"},
    {prodId:"50413001",label:"Bok choy orgánico"},
    {prodId:"50413002",label:"Bok choy enano orgánico"},
    {prodId:"50413003",label:"Flor de repollo chino orgánico"},
    {prodId:"50413004",label:"Choy sum orgánico"},
    {prodId:"50413005",label:"Col (bok choy) enano orgánico"},
    {prodId:"50413006",label:"Col (bok choy) fengshan orgánico"},
    {prodId:"50413007",label:"Col (bok choy) jade pagoda orgánico"},
    {prodId:"50413008",label:"Col (bok choy) kasumi orgánico"},
    {prodId:"50413009",label:"Col (bok choy)  nerva orgánico"},
    {prodId:"50413010",label:"Col (bok choy) rosette orgánico"},
    {prodId:"50413011",label:"Col (bok choy) ruffles orgánico"},
    {prodId:"50413012",label:"Col (bok choy) hoja  santo serrated orgánico"},
    {prodId:"50413013",label:"Col (bok choy) shangahai orgánico"},
    {prodId:"50413014",label:"Shantung orgánico"},
    {prodId:"50413015",label:"Repollo tip top orgánico"},
    {prodId:"50413016",label:"Yau choy sum orgánico"},
    {prodId:"50413200",label:"Berros  orgánicos"},
    {prodId:"50413201",label:"Berros de tierra orgánico"},
    {prodId:"50413202",label:"Nasturtium orgánico"},
    {prodId:"50413203",label:"Berros de agua orgánico"},
    {prodId:"50413204",label:"Berros de invierno orgánico"},
    {prodId:"50413500",label:"Endivias  orgánicas"},
    {prodId:"50413501",label:"Endivias witloof de brucelas orgánicas"},
    {prodId:"50413502",label:"Endivias francocastel orgánicas"},
    {prodId:"50413503",label:"Endivias catalogna de galantina orgánicas"},
    {prodId:"50413504",label:"Endivias chioggia orgánicas"},
    {prodId:"50413505",label:"Endivias grumolo verde orgánicas"},
    {prodId:"50413506",label:"Endivias raíz larga magdeburg orgánicas"},
    {prodId:"50413507",label:"Endivias palla rosa zorzi precoce orgánicas"},
    {prodId:"50413508",label:"Endivias radice amare orgánicas"},
    {prodId:"50413509",label:"Endivias rosa de traviso orgánicas"},
    {prodId:"50413510",label:"Endivias  rosa de verona orgánicas"},
    {prodId:"50413511",label:"Endivias soncino orgánicas"},
    {prodId:"50413512",label:"Endivias sugarthi orgánicas"},
    {prodId:"50413513",label:"Endivias verona orgánicas"},
    {prodId:"50413514",label:"Endivias witloof zoom orgánicas"},
    {prodId:"50413600",label:"Hinojos  orgánicos"},
    {prodId:"50413601",label:"Hinojo cantino orgánico"},
    {prodId:"50413602",label:"Hinojo fino orgánico"},
    {prodId:"50413603",label:"Hinojoherald orgánico"},
    {prodId:"50413604",label:"Hinojo perfección orgánico"},
    {prodId:"50413605",label:"Hinojo sirio orgánico"},
    {prodId:"50413606",label:"Hinojo florencia dulce orgánico"},
    {prodId:"50413607",label:"Hinojo tardo orgánico"},
    {prodId:"50413700",label:"Ajos  orgánicos"},
    {prodId:"50413701",label:"Ajo tarde california orgánico"},
    {prodId:"50413702",label:"Ajo tallos chinos orgánico"},
    {prodId:"50413703",label:"Ajo cebollin orgánico"},
    {prodId:"50413704",label:"Ajo germidor orgánico"},
    {prodId:"50413705",label:"Ajo guardado largo orgánico"},
    {prodId:"50413706",label:"Ajo ramson orgánico"},
    {prodId:"50413707",label:"Ajo rocambola orgánico"},
    {prodId:"50413708",label:"Ajo rosa de lautrec orgánico"},
    {prodId:"50413709",label:"Ajo solent blanco orgánico"},
    {prodId:"50413710",label:"Ajo morado español orgánico"},
    {prodId:"50413711",label:"Ajo valentina italiana orgánico"},
    {prodId:"50414100",label:"Coles  orgánicos"},
    {prodId:"50414101",label:"Col rizada orgánica"},
    {prodId:"50414102",label:"Repollo verde orgánico"},
    {prodId:"50414200",label:"Colinabos orgánicos"},
    {prodId:"50414201",label:"Colinabo estrella azur orgánico"},
    {prodId:"50414202",label:"Colinabo verde viena orgánico"},
    {prodId:"50414203",label:"Colinabo lanro orgánico"},
    {prodId:"50414204",label:"Colinabo viena morada orgánico"},
    {prodId:"50414205",label:"Colinabo trero rowel orgánico"},
    {prodId:"50414206",label:"Colinabo viena blanca orgánico"},
    {prodId:"50414300",label:"Puerros orgánicos"},
    {prodId:"50414301",label:"Puerro cobra gigante otoño orgánico"},
    {prodId:"50414302",label:"Puerro otoño mamut 2 orgánico"},
    {prodId:"50414303",label:"Puerro blue de solaise orgánico"},
    {prodId:"50414304",label:"Puerro cortina orgánico"},
    {prodId:"50414305",label:"Puerro prelina orgánico"},
    {prodId:"50414306",label:"Puerro salvaje orgánico"},
    {prodId:"50414400",label:"Lentejas  orgánicas"},
    {prodId:"50414401",label:"Lenteja beluga orgánica"},
    {prodId:"50414402",label:"Lenteja  verde francesa orgánica"},
    {prodId:"50414403",label:"Lenteja verde orgánica"},
    {prodId:"50414404",label:"Lenteja crimson pequeña orgánica"},
    {prodId:"50414405",label:"Lenteja pardina española orgánica"},
    {prodId:"50414406",label:"Lenteja roja separada orgánica"},
    {prodId:"50414407",label:"Lenteja amarilla separad orgánica"},
    {prodId:"50414408",label:"Lenteja tarahumara rosada orgánica"},
    {prodId:"50414600",label:"Ñames orgánicos"},
    {prodId:"50414601",label:"Ñame amarilla orgánica"},
    {prodId:"50414602",label:"Ñame blanca orgánica"},
    {prodId:"50414603",label:"Ñame coco orgánica"},
    {prodId:"50414604",label:"Ñame eddoes orgánica"},
    {prodId:"50414605",label:"Ñame isleña orgánica"},
    {prodId:"50414606",label:"Ñame lila orgánica"},
    {prodId:"50414800",label:"Mostazas  orgánicas"},
    {prodId:"50414801",label:"Mostaza bambú orgánica"},
    {prodId:"50414802",label:"Mostaza ajo orgánica"},
    {prodId:"50414803",label:"Mostaza hoja gigante orgánica"},
    {prodId:"50414804",label:"Mostaza rojo en nieve orgánica"},
    {prodId:"50414805",label:"Mostaza sureño orgánica"},
    {prodId:"50414806",label:"Mostaza corazón envuelto orgánica"},
    {prodId:"50415000",label:"Solanáceas (nightshades)  orgánicas"},
    {prodId:"50415001",label:"Lámpara china orgánica"},
    {prodId:"50415002",label:"Mora de jardín orgánica"},
    {prodId:"50415003",label:"Naranjilla orgánica"},
    {prodId:"50415004",label:"Tomatillo orgánico"},
    {prodId:"50415300",label:"Maníes orgánicos"},
    {prodId:"50415301",label:"Maní bambarra orgánico"},
    {prodId:"50415302",label:"Maní florunner  orgánico"},
    {prodId:"50415303",label:"Maní hausa / kersting orgánico"},
    {prodId:"50415304",label:"Maní español orgánico"},
    {prodId:"50415305",label:"Maní de valencia orgánico"},
    {prodId:"50415306",label:"Maní de virginia orgánico"},
    {prodId:"50415400",label:"Alverjas Orgánicas"},
    {prodId:"50415401",label:"Alverja casco morado orgánica"},
    {prodId:"50415402",label:"Alverja pinkeye orgánica"},
    {prodId:"50415403",label:"Alverja crowder orgánica"},
    {prodId:"50415404",label:"Alverja acre blanco orgánica"},
    {prodId:"50415405",label:"Alverja ojo negro orgánica"},
    {prodId:"50415406",label:"Alverja crema zipper orgánica"},
    {prodId:"50415600",label:"Papas  orgánicas"},
    {prodId:"50415601",label:"Papa blanca larga orgánica"},
    {prodId:"50415602",label:"Papa redonda blanca orgánica"},
    {prodId:"50415603",label:"Papa redonda roja orgánica"},
    {prodId:"50415604",label:"Papa  russet orgánica"},
    {prodId:"50415605",label:"Papa morada orgánica"},
    {prodId:"50415606",label:"Papa amarilla orgánica"},
    {prodId:"50415607",label:"Papa nueva orgánica"},
    {prodId:"50415608",label:"Papa especial orgánica"},
    {prodId:"50415700",label:"Nabos suecos orgánicos"},
    {prodId:"50415701",label:"Nabo sueco acme orgánico"},
    {prodId:"50415702",label:"Nabo sueco angela orgánico"},
    {prodId:"50415703",label:"Nabo sueco el mejor orgánico"},
    {prodId:"50415704",label:"Nabo sueco marian orgánico"},
    {prodId:"50415800",label:"Vegetales marinos orgánicos"},
    {prodId:"50415801",label:"Agar-agar orgánico"},
    {prodId:"50415802",label:"Arame  orgánica"},
    {prodId:"50415803",label:"Alga dulce orgánica"},
    {prodId:"50415804",label:"Habichuelas vert de mer orgánica"},
    {prodId:"50415805",label:"Hijiki orgánica"},
    {prodId:"50415806",label:"Musgo irlandés orgánico"},
    {prodId:"50415807",label:"Quelpo orgánico"},
    {prodId:"50415808",label:"Laver orgánica"},
    {prodId:"50415809",label:"Nori orgánico"},
    {prodId:"50415810",label:"Alga roja orgánica"},
    {prodId:"50415811",label:"Kale de mar orgánico"},
    {prodId:"50415812",label:"Lechuga de mar orgánica"},
    {prodId:"50415813",label:"Algas de mar orgánica"},
    {prodId:"50415814",label:"Spirulina orgánica"},
    {prodId:"50415815",label:"Susabi nuri orgánico"},
    {prodId:"50415816",label:"Wakame orgánica"},
    {prodId:"50415900",label:"Chalotes orgánicos"},
    {prodId:"50415901",label:"Chalote atlántica orgánico"},
    {prodId:"50415902",label:"Chalote creación orgánico"},
    {prodId:"50415903",label:"Chalote drittler nido blanco orgánico"},
    {prodId:"50415904",label:"Chalote gigante mejorado amarillo orgánico"},
    {prodId:"50415905",label:"Chalote gourmet dorado orgánico"},
    {prodId:"50415906",label:"Chalote grise de bagnolet orgánico"},
    {prodId:"50415907",label:"Chalote hative de nort orgánico"},
    {prodId:"50415908",label:"Chalote pikant orgánico"},
    {prodId:"50415909",label:"Chalote papa roja orgánico"},
    {prodId:"50415910",label:"Chalote sante orgánico"},
    {prodId:"50415911",label:"Chalote topper orgánico"},
    {prodId:"50416000",label:"Alazanes orgánicos"},
    {prodId:"50416001",label:"Alazán dock orgánica"},
    {prodId:"50416002",label:"Alazán jardín orgánica"},
    {prodId:"50416003",label:"Alazán oveja orgánica"},
    {prodId:"50416004",label:"Alazán madera orgánica"},
    {prodId:"50416300",label:"Batatas orgánicas"},
    {prodId:"50416301",label:"Batata beauregard orgánica"},
    {prodId:"50416302",label:"Batata centennial orgánica"},
    {prodId:"50416303",label:"Batata diane orgánica"},
    {prodId:"50416304",label:"Batata dulce garnet orgánica"},
    {prodId:"50416305",label:"Batata georgia dulce rojo orgánica"},
    {prodId:"50416306",label:"Batata  dorado orgánica"},
    {prodId:"50416307",label:"Batata hanna  orgánica"},
    {prodId:"50416308",label:"Batata japonés orgánica"},
    {prodId:"50416309",label:"Batata jersey orgánica"},
    {prodId:"50416310",label:"Batata joya orgánica"},
    {prodId:"50416311",label:"Batata meryland roja orgánica"},
    {prodId:"50416312",label:"Batata nema dorado orgánica"},
    {prodId:"50416313",label:"Batata o'henry orgánica"},
    {prodId:"50416314",label:"Batata okinawan orgánica"},
    {prodId:"50416315",label:"Batata naranja orgánica"},
    {prodId:"50416316",label:"Batata oriental orgánica"},
    {prodId:"50416317",label:"Batata jersey rojo orgánica"},
    {prodId:"50416318",label:"Batata mar rojo orgánica"},
    {prodId:"50416319",label:"Batata brillo rojo orgánica"},
    {prodId:"50416320",label:"Batata jersey amarillo orgánica"},
    {prodId:"50416500",label:"Nabos orgánicos"},
    {prodId:"50416501",label:"Nabo globo verde orgánico"},
    {prodId:"50416502",label:"Nabo bola dorada orgánico"},
    {prodId:"50416503",label:"Nabo mercado de manchester orgánico"},
    {prodId:"50416504",label:"Nabo top morado milan orgánico"},
    {prodId:"50416505",label:"Nabo top morado blanco orgánico"},
    {prodId:"50416506",label:"Nabo bola de nieve orgánico"},
    {prodId:"50416507",label:"Nabo tokio orgánico"},
    {prodId:"50416508",label:"Nabo cruz de tokio orgánico"},
    {prodId:"50416700",label:"Maíces Orgánicos"},
    {prodId:"50416701",label:"Ñame agria africana orgánico"},
    {prodId:"50416702",label:"Ñame agria asiática orgánico"},
    {prodId:"50416703",label:"Ñame china orgánico"},
    {prodId:"50416704",label:"Ñame globo orgánico"},
    {prodId:"50416705",label:"Ñame grater orgánico"},
    {prodId:"50416706",label:"Ñame japonesa"},
    {prodId:"50416707",label:"Ñame lesser orgánico"},
    {prodId:"50416708",label:"Ñame papa orgánico"},
    {prodId:"50416709",label:"Ñame guinea blanca orgánico"},
    {prodId:"50416710",label:"Ñame guinea amarillo orgánico"},
    {prodId:"50417100",label:"Alverjas dulces orgánicas"},
    {prodId:"50417101",label:"Alverja biquini orgánica"},
    {prodId:"50417102",label:"Alverja cavalier orgánica"},
    {prodId:"50417103",label:"Alverja margarita orgánica"},
    {prodId:"50417104",label:"Alverja darfon orgánica"},
    {prodId:"50417105",label:"Alverja early onward orgánica"},
    {prodId:"50417106",label:"Alverja primer feltham orgánica"},
    {prodId:"50417107",label:"Alverja verde hurst shaft orgánica"},
    {prodId:"50417108",label:"Guisante oregón sugar pod orgánicas"},
    {prodId:"50417109",label:"Alverja príncipe alberto orgánica"},
    {prodId:"50417110",label:"Alverja reuzensuiker orgánica"},
    {prodId:"50421600",label:"Espárragos Secos"},
    {prodId:"50421601",label:"Espárragos connovers colosal secos"},
    {prodId:"50421602",label:"Espárragos franklin srcos"},
    {prodId:"50421603",label:"Espárragos mamut gigante secos"},
    {prodId:"50421604",label:"Espárragos lucullus secos"},
    {prodId:"50421605",label:"Espárragos martha washington secos"},
    {prodId:"50421700",label:"Aguacates Secos"},
    {prodId:"50421701",label:"Aguacate ajax b - 7 seco"},
    {prodId:"50421702",label:"Aguacate arue seco"},
    {prodId:"50421703",label:"Aguacate bacon seco"},
    {prodId:"50421704",label:"Aguacate benik seco"},
    {prodId:"50421705",label:"Aguacate bernecker seco"},
    {prodId:"50421706",label:"Aguacate beta seco"},
    {prodId:"50421707",label:"Aguacate biondo seco"},
    {prodId:"50421708",label:"Aguacate príncipe negro seco"},
    {prodId:"50421709",label:"Aguacate blair seco"},
    {prodId:"50421710",label:"Aguacate blair cabina seco"},
    {prodId:"50421711",label:"Aguacate cabina 1 seco"},
    {prodId:"50421712",label:"Aguacate cabina 3 seco"},
    {prodId:"50421713",label:"Aguacate cabina 5 seco"},
    {prodId:"50421714",label:"Aguacate cabina 7 seco"},
    {prodId:"50421715",label:"Aguacate cabina 8 seco"},
    {prodId:"50421716",label:"Aguacate brooks 1978 seco"},
    {prodId:"50421717",label:"Aguacate brookslate seco"},
    {prodId:"50421718",label:"Aguacate california haas seco"},
    {prodId:"50421719",label:"Aguacate catalina seco"},
    {prodId:"50421720",label:"Aguacate chica seco"},
    {prodId:"50421721",label:"Aguacate choquette seco"},
    {prodId:"50421722",label:"Aguacate cristina seco"},
    {prodId:"50421723",label:"Aguacate collison seco"},
    {prodId:"50421724",label:"Aguacate donnie seco"},
    {prodId:"50421725",label:"Aguacate dr dupis numero 2 seco"},
    {prodId:"50421726",label:"Aguacate dr. dupie  seco"},
    {prodId:"50421727",label:"Aguacate ettinger seco"},
    {prodId:"50421728",label:"Aguacate fuch seco"},
    {prodId:"50421729",label:"Aguacate fuch gwen secos"},
    {prodId:"50421730",label:"Aguacate fuerte seco"},
    {prodId:"50421731",label:"Aguacate gotham seco"},
    {prodId:"50421732",label:"Aguacate gossman seco"},
    {prodId:"50421733",label:"Aguacate guatemala sin pepa seco"},
    {prodId:"50421734",label:"Aguacate hall seco"},
    {prodId:"50421735",label:"Aguacate hardee seco"},
    {prodId:"50421736",label:"Aguacate haas seco"},
    {prodId:"50421737",label:"Aguacate herman seco"},
    {prodId:"50421738",label:"Aguacate hickson seco"},
    {prodId:"50421739",label:"Aguacate k-5 seco"},
    {prodId:"50421740",label:"Aguacate k- 9 seco"},
    {prodId:"50421741",label:"Aguacate cordero haas seco"},
    {prodId:"50421742",label:"Aguacate leona seco"},
    {prodId:"50421743",label:"Aguacate leona linda seco"},
    {prodId:"50421744",label:"Aguacate lisa p seco"},
    {prodId:"50421745",label:"Aguacate lisa loretta seco"},
    {prodId:"50421746",label:"Aguacate loretta seco"},
    {prodId:"50421747",label:"Aguacate lula seco"},
    {prodId:"50421748",label:"Aguacate lula mascarthur seco"},
    {prodId:"50421749",label:"Aguacate marcus seco"},
    {prodId:"50421750",label:"Aguacate melendez seco"},
    {prodId:"50421751",label:"Aguacate meya seco"},
    {prodId:"50421752",label:"Aguacate miguel p seco"},
    {prodId:"50421753",label:"Aguacate monroe seco"},
    {prodId:"50421754",label:"Aguacate murrieta verde seco"},
    {prodId:"50421755",label:"Aguacate nabal seco"},
    {prodId:"50421756",label:"Aguacate nadir seco"},
    {prodId:"50421757",label:"Aguacate nesbitt seco"},
    {prodId:"50421758",label:"Aguacate peterson seco"},
    {prodId:"50421759",label:"Aguacate pinelli seco"},
    {prodId:"50421760",label:"Aguacate pinkerton seco"},
    {prodId:"50421761",label:"Aguacate pollock seco"},
    {prodId:"50421762",label:"Aguacate puebla seco"},
    {prodId:"50421763",label:"Aguacate reed seco"},
    {prodId:"50421764",label:"Aguacaterue seco"},
    {prodId:"50421765",label:"Aguacateruehle seco"},
    {prodId:"50421766",label:"Aguacate ryan seco"},
    {prodId:"50421767",label:"Aguacate semil seco"},
    {prodId:"50421768",label:"Aguacate semil 43 seco"},
    {prodId:"50421769",label:"Aguacate simonds seco"},
    {prodId:"50421770",label:"Aguacate simpson seco"},
    {prodId:"50421771",label:"Aguacate taylor seco"},
    {prodId:"50421772",label:"Aguacate tonelada seco"},
    {prodId:"50421773",label:"Aguacate torre seco"},
    {prodId:"50421774",label:"Aguacate torre li seco"},
    {prodId:"50421775",label:"Aguacate trapp seco"},
    {prodId:"50421776",label:"Aguacate semilla de caribe seco"},
    {prodId:"50421777",label:"Aguacate wagner seco"},
    {prodId:"50421778",label:"Aguacate waldin seco"},
    {prodId:"50421779",label:"Aguacate wurtz seco"},
    {prodId:"50421780",label:"Aguacate zio p seco"},
    {prodId:"50421781",label:"Aguacate ziu seco"},
    {prodId:"50421782",label:"Aguacate zultano seco"},
    {prodId:"50421900",label:"Remolachas  Secas"},
    {prodId:"50421901",label:"Remolacha acción seca"},
    {prodId:"50421902",label:"Remolacha albina vereduna seca"},
    {prodId:"50421903",label:"Remolacha barababiotela de chiggia seca"},
    {prodId:"50421904",label:"Remolacha boltardy seca"},
    {prodId:"50421905",label:"Remolacha bonel seca"},
    {prodId:"50421906",label:"Remolacha burpees dorado seca"},
    {prodId:"50421907",label:"Remolacha cheltenham tapa verde seca"},
    {prodId:"50421908",label:"Remolacha cheltenham mono seca"},
    {prodId:"50421909",label:"Remolacha chioggia seca"},
    {prodId:"50421910",label:"Remolacha cilindra seca"},
    {prodId:"50421911",label:"Remolacha dégypte seca"},
    {prodId:"50421912",label:"Remolacha detroit 2 rojo oscuro seca"},
    {prodId:"50421913",label:"Remolacha detroit 2 bala chiquita seca"},
    {prodId:"50421914",label:"Remolacha egipcia plana  seca"},
    {prodId:"50421915",label:"Remolacha  raíz de nabo egipcio seca"},
    {prodId:"50421916",label:"Remolacha fomanova seca"},
    {prodId:"50421917",label:"Remolacha forono seca"},
    {prodId:"50421918",label:"Remolacha monaco seca"},
    {prodId:"50421919",label:"Remolacha monograma seca"},
    {prodId:"50421920",label:"Remolacha pronto seca"},
    {prodId:"50421921",label:"Remolacha regalía seca"},
    {prodId:"50421922",label:"Remolacha dulce seca"},
    {prodId:"50422000",label:"Brócolis  Secas"},
    {prodId:"50422001",label:"Brócolini seco"},
    {prodId:"50422002",label:"Brócoli romanesco seco"},
    {prodId:"50422003",label:"Brócoli raab seco"},
    {prodId:"50422004",label:"Brócoli chino seco"},
    {prodId:"50422100",label:"Repollitos de Bruselas  Secas"},
    {prodId:"50422101",label:"Repollitas de bruselas citade secas"},
    {prodId:"50422102",label:"Repollitas de bruselas falstaff secas"},
    {prodId:"50422103",label:"Repollitas de bruselas oliver secas"},
    {prodId:"50422104",label:"Repollitas de bruselas peer gynt secas"},
    {prodId:"50422105",label:"Repollitas de bruselas rampart secas"},
    {prodId:"50422106",label:"Repollitas de bruselas rubine secas"},
    {prodId:"50422107",label:"Repollitas de bruselas  widgeon secas"},
    {prodId:"50422200",label:"Cebollas de ensalada  Secas"},
    {prodId:"50422201",label:"Cebolla belstville ensalada seca"},
    {prodId:"50422202",label:"Cebolla feast ensalada seca"},
    {prodId:"50422203",label:"Cebolla ishikura ensalada seca"},
    {prodId:"50422204",label:"Cebolla mercado kyoto seca"},
    {prodId:"50422205",label:"Cebolla barba roja ensalada seca"},
    {prodId:"50422206",label:"Cebolla amigo rojo ensalada seca"},
    {prodId:"50422207",label:"Cebolla santa claus seca"},
    {prodId:"50422208",label:"Cebolla tokyo ensalada seca"},
    {prodId:"50422209",label:"Cebolla lisbon blanca ensalada seca"},
    {prodId:"50422210",label:"Cebolla invierno blanca ensalada seca"},
    {prodId:"50422211",label:"Cebolla final de invierno ensalada seca"},
    {prodId:"50422300",label:"Repollos Secos"},
    {prodId:"50422301",label:"Repollo negro seco"},
    {prodId:"50422302",label:"Repollo savoy seco"},
    {prodId:"50422303",label:"Repollo zorrillo seco"},
    {prodId:"50422304",label:"Repollo blanco seco"},
    {prodId:"50422400",label:"Cardos Secos"},
    {prodId:"50422401",label:"Cardos lunghi secos"},
    {prodId:"50422402",label:"Cardos gobbi secos"},
    {prodId:"50422600",label:"Coliflores  Secas"},
    {prodId:"50422601",label:"Coliflor todo el año seca"},
    {prodId:"50422602",label:"Coliflor alverda seca"},
    {prodId:"50422603",label:"coliflor otoño gigante 3 seca"},
    {prodId:"50422604",label:"Coliflo rdok elgon seca"},
    {prodId:"50422605",label:"Coliflor bola de nieve temprana seca"},
    {prodId:"50422606",label:"Coliflor luz de lima seca"},
    {prodId:"50422607",label:"Coliflor minaret seca"},
    {prodId:"50422608",label:"Coliflor buque de naranja seca"},
    {prodId:"50422609",label:"Coliflor capa morada seca"},
    {prodId:"50422610",label:"Coliflor bola de nieve seca"},
    {prodId:"50422611",label:"Coliflor invierno walcheren 3 seca"},
    {prodId:"50422612",label:"Coliflor piedra blanca seca"},
    {prodId:"50422700",label:"Apios Secos"},
    {prodId:"50422701",label:"Apio celebridad seco"},
    {prodId:"50422702",label:"Apio nabo seco"},
    {prodId:"50422703",label:"Apio chino seco"},
    {prodId:"50422704",label:"Apio dinant francés seco"},
    {prodId:"50422705",label:"Apio gigante rosado seco"},
    {prodId:"50422706",label:"Apio gigante rojo seco"},
    {prodId:"50422707",label:"Apio gigante blanco seco"},
    {prodId:"50422708",label:"Apio dorado auto escalado seco"},
    {prodId:"50422709",label:"Apio greensleeves seco"},
    {prodId:"50422710",label:"Apio hopkins fenlander seco"},
    {prodId:"50422711",label:"Apio torre marfil seco"},
    {prodId:"50422712",label:"Apio lathom blanqueado seco"},
    {prodId:"50422713",label:"Apio sopa de amsterdam seco"},
    {prodId:"50422714",label:"Apio estándar cargado seco"},
    {prodId:"50422715",label:"Apio triunfo alto utah seco"},
    {prodId:"50422800",label:"Acelgas  Secas"},
    {prodId:"50422801",label:"Acelga luz brillante seca"},
    {prodId:"50422802",label:"Acelga gigante fordhook seca"},
    {prodId:"50422803",label:"Acelga luculus seca"},
    {prodId:"50422804",label:"Acelga espinaca perpetua seca"},
    {prodId:"50422805",label:"Acelga ruibarbo seca"},
    {prodId:"50422806",label:"Acelga suizo seca"},
    {prodId:"50422807",label:"Acelga volcán  seca"},
    {prodId:"50422808",label:"Acelga rey blanco seca"},
    {prodId:"50422900",label:"Achicorias Secas"},
    {prodId:"50422901",label:"Achicoria batavian hoja ancha seca"},
    {prodId:"50422902",label:"Achicoria en cornet de bordeaux seca"},
    {prodId:"50422903",label:"Achicoria rollo verde ruffee seca"},
    {prodId:"50422904",label:"Achicoria roll verde seca"},
    {prodId:"50422905",label:"Achicoria limnoe lone seca"},
    {prodId:"50422906",label:"Achicoria pancalieri riccia seca"},
    {prodId:"50422907",label:"Achicoria ensalada rey seca"},
    {prodId:"50422908",label:"Achicoria sanda seca"},
    {prodId:"50422909",label:"Achicoria scarola verde seca"},
    {prodId:"50422910",label:"Achicoria tres fine maraichere seca"},
    {prodId:"50422911",label:"Achicoria wallone freisee wescelkkopf seca"},
    {prodId:"50423000",label:"Repollos chinos secos"},
    {prodId:"50423001",label:"Bok choy seco"},
    {prodId:"50423002",label:"Bok choy enano seco"},
    {prodId:"50423003",label:"Flor de repollo chino seca"},
    {prodId:"50423004",label:"Choy sum seco"},
    {prodId:"50423005",label:"Col (bok choy) enano seco"},
    {prodId:"50423006",label:"Col (bok choy) fengshan seco"},
    {prodId:"50423007",label:"Col (bok choy) jade pagoda seco"},
    {prodId:"50423008",label:"Col (bok choy) kasumi seco"},
    {prodId:"50423009",label:"col (bok choy)  nerva seco"},
    {prodId:"50423010",label:"Col (bok choy) rosette seco"},
    {prodId:"50423011",label:"Col (bok choy) ruffles seco"},
    {prodId:"50423012",label:"Col (bok choy) hoja  santo serrated seco"},
    {prodId:"50423013",label:"Col (bok choy) shangahai seco"},
    {prodId:"50423014",label:"Shantung seco"},
    {prodId:"50423015",label:"Repollo tip top seco"},
    {prodId:"50423016",label:"Yau choy sum seco"},
    {prodId:"50423100",label:"Cebollines Secos"},
    {prodId:"50423101",label:"Cebollín chino seco"},
    {prodId:"50423300",label:"Berros  Secos"},
    {prodId:"50423301",label:"Berros de tierra secos"},
    {prodId:"50423302",label:"Nasturtium seco"},
    {prodId:"50423303",label:"Berros de agua secos"},
    {prodId:"50423304",label:"Berros de invierno secos"},
    {prodId:"50423400",label:"Cocombros secos"},
    {prodId:"50423401",label:"Cocombro arena seco"},
    {prodId:"50423402",label:"Cocombro americano seco"},
    {prodId:"50423403",label:"Cocombro atena seco"},
    {prodId:"50423404",label:"Cocombro blanco lungo de parigi seco"},
    {prodId:"50423405",label:"Cocombro verde burpless buen sabor seco"},
    {prodId:"50423406",label:"Cocombro chicago pickling seco"},
    {prodId:"50423407",label:"Cocombro manzana cristal seco"},
    {prodId:"50423408",label:"Cocombro limón cristal seco"},
    {prodId:"50423409",label:"Cocombro danimas seco"},
    {prodId:"50423410",label:"Cocombro gherkin seco"},
    {prodId:"50423411",label:"Cocombro hokus seco"},
    {prodId:"50423412",label:"Cocombro japonés seco"},
    {prodId:"50423413",label:"Cocombro karela seco"},
    {prodId:"50423414",label:"Cocombro korila seco"},
    {prodId:"50423415",label:"Cocombro verde largo mejorado seco"},
    {prodId:"50423416",label:"Cocombro mas mercado seco"},
    {prodId:"50423417",label:"Cocombro enano seco"},
    {prodId:"50423418",label:"Cocombro pickling nacional seco"},
    {prodId:"50423419",label:"Cocombro persa seco"},
    {prodId:"50423420",label:"Cocombro telégrafo seco"},
    {prodId:"50423421",label:"Cocombro telégrafo mejorado seco"},
    {prodId:"50423422",label:"Cocombro vert de massy cornichon seco"},
    {prodId:"50423423",label:"Cocombro yamato seco"},
    {prodId:"50423500",label:"Berenjenas Secas"},
    {prodId:"50423501",label:"Berenjena bambino seca"},
    {prodId:"50423502",label:"Berenjena  belleza negra seca"},
    {prodId:"50423503",label:"Berenjena  negra enorme seca"},
    {prodId:"50423504",label:"Berenjena  chino seca"},
    {prodId:"50423505",label:"Berenjena  huevo de pascua seca"},
    {prodId:"50423506",label:"Berenjena  filipino seca"},
    {prodId:"50423507",label:"Berenjena   mercado de florida seca"},
    {prodId:"50423508",label:"Berenjena  india seca"},
    {prodId:"50423509",label:"Berenjena  italiano seca"},
    {prodId:"50423510",label:"Berenjena  japonés seca"},
    {prodId:"50423511",label:"Berenjena  morado largo seca"},
    {prodId:"50423512",label:"Berenjena   rayas larga seca"},
    {prodId:"50423513",label:"Berenjena  mercado de dinero seca"},
    {prodId:"50423514",label:"Berenjena  ova seca"},
    {prodId:"50423515",label:"Berenjena   arveja seca"},
    {prodId:"50423516",label:"Berenjena  tom bajito seca"},
    {prodId:"50423517",label:"Berenjena  siciliano seca"},
    {prodId:"50423518",label:"Berenjena  tailandés seca"},
    {prodId:"50423519",label:"Berenjena  violeta de florencia seca"},
    {prodId:"50423520",label:"Berenjena  blanco seca"},
    {prodId:"50423600",label:"Endivias Secas"},
    {prodId:"50423601",label:"Endivias witloof de brucelas secas"},
    {prodId:"50423602",label:"Endivias francocastel secas"},
    {prodId:"50423603",label:"Endivias catalogna de galantina secas"},
    {prodId:"50423604",label:"Endivias chioggia secas"},
    {prodId:"50423605",label:"Endivias grumolo verde secas"},
    {prodId:"50423606",label:"Endivias raíz larga magdeburg secas"},
    {prodId:"50423607",label:"Endivias palla rosa zorzi precoce secas"},
    {prodId:"50423608",label:"Endivias radice amare secas"},
    {prodId:"50423609",label:"Endivias rosa de traviso secas"},
    {prodId:"50423610",label:"Endivias  rosa de verona secas"},
    {prodId:"50423611",label:"Endivias soncino secas"},
    {prodId:"50423612",label:"Endivias sugarthi secas"},
    {prodId:"50423613",label:"Endivias verona secas"},
    {prodId:"50423614",label:"Endivias witloof zoom secas"},
    {prodId:"50424200",label:"Coles Secas"},
    {prodId:"50424201",label:"Col rizada seca"},
    {prodId:"50424202",label:"Repollo verde seco"},
    {prodId:"50424300",label:"Colinabos Secos"},
    {prodId:"50424301",label:"Colinabo estrella azur seco"},
    {prodId:"50424302",label:"Colinabo verde viena seco"},
    {prodId:"50424303",label:"Colinabo lanro seco"},
    {prodId:"50424304",label:"Colinabo viena morada seco"},
    {prodId:"50424305",label:"Colinabo trero rowel seco"},
    {prodId:"50424306",label:"Colinabo viena blanca seco"},
    {prodId:"50424400",label:"Puerros Secos"},
    {prodId:"50424401",label:"Puerro cobra gigante otoño seco"},
    {prodId:"50424402",label:"Puerro otoño mamut 2 seco"},
    {prodId:"50424403",label:"Puerro blue de solaise seco"},
    {prodId:"50424404",label:"Puerro cortina seco"},
    {prodId:"50424405",label:"Puerro prelina seco"},
    {prodId:"50424406",label:"Puerro salvaje seco"},
    {prodId:"50424600",label:"Lechugas Secas"},
    {prodId:"50424601",label:"Lechuga  bibb seca"},
    {prodId:"50424602",label:"Lechuga  boston seca"},
    {prodId:"50424603",label:"Lechuga frisee seca"},
    {prodId:"50424604",label:"Lechuga rosa lola seca"},
    {prodId:"50424605",label:"Lechuga  mixta masculina seca"},
    {prodId:"50424606",label:"Lechuga mizuna seca"},
    {prodId:"50424607",label:"Lechuga hoja roja seca"},
    {prodId:"50424608",label:"Lechuga oja roja roble seca"},
    {prodId:"50424609",label:"Lechuga romana ruby seca"},
    {prodId:"50424610",label:"Lechuga romana bebé roja seca"},
    {prodId:"50424611",label:"Lechuga cabeza de mantequilla seca"},
    {prodId:"50424612",label:"Lechuga china seca"},
    {prodId:"50424613",label:"Lechuga cabeza tostada seca"},
    {prodId:"50424614",label:"Lechuga hoja verde seca"},
    {prodId:"50424615",label:"Lechuga iceberg seca"},
    {prodId:"50424616",label:"Lechuga cordero seca"},
    {prodId:"50424617",label:"Lechuga hoja suelta seca"},
    {prodId:"50424618",label:"Lechuga mache seca"},
    {prodId:"50424619",label:"Lechuga boston roja seca"},
    {prodId:"50424620",label:"Lechuga cabeza roja seca"},
    {prodId:"50424621",label:"Lechuga romana seca"},
    {prodId:"50424622",label:"Lechuga mostza roja rusa seca"},
    {prodId:"50424623",label:"Lechuga tatsoi seca"},
    {prodId:"50424700",label:"Ñames Secos"},
    {prodId:"50424701",label:"Ñame amarilla seca"},
    {prodId:"50424702",label:"Ñame blanca seca"},
    {prodId:"50424703",label:"Ñame coco seca"},
    {prodId:"50424704",label:"Ñame eddoes seca"},
    {prodId:"50424705",label:"Ñame isleña seca"},
    {prodId:"50424706",label:"Ñame lila seca"},
    {prodId:"50424800",label:"Hongos o champiñones Secos"},
    {prodId:"50424801",label:"Champiñón trompeta negra seco"},
    {prodId:"50424802",label:"Champiñón carmelito seco"},
    {prodId:"50424803",label:"Champiñón  seco"},
    {prodId:"50424804",label:"Champiñón chanterelle seco"},
    {prodId:"50424805",label:"Champiñón cremini seco"},
    {prodId:"50424806",label:"Champiñón enoki seco"},
    {prodId:"50424807",label:"Champiñón puercoespín seco"},
    {prodId:"50424808",label:"Champiñón gallina del bosque seco"},
    {prodId:"50424809",label:"Champiñón langosta seco"},
    {prodId:"50424810",label:"Champiñón morels seco"},
    {prodId:"50424811",label:"Champiñón ostra seco"},
    {prodId:"50424812",label:"Champiñón pleurotus seco"},
    {prodId:"50424813",label:"Champiñón pompom seco"},
    {prodId:"50424814",label:"Champiñón porcieni seco"},
    {prodId:"50424815",label:"Champiñón portobello seco"},
    {prodId:"50424816",label:"Champiñón shitake seco"},
    {prodId:"50424817",label:"Champiñón shimeji seco"},
    {prodId:"50424818",label:"Champiñón san jorge seco"},
    {prodId:"50424819",label:"Champiñón blanco seco"},
    {prodId:"50424820",label:"Champiñón tompeta blanca seco"},
    {prodId:"50424821",label:"Champiñón woodear seco"},
    {prodId:"50425000",label:"Solanáceas (nightshades) Secas"},
    {prodId:"50425001",label:"Lámpara china seca"},
    {prodId:"50425002",label:"Mora de jardín"},
    {prodId:"50425003",label:"Naranjilla secas"},
    {prodId:"50425004",label:"Tomatillo seca"},
    {prodId:"50425100",label:"Okras Secas"},
    {prodId:"50425101",label:"Okra artista seca"},
    {prodId:"50425102",label:"Okra vinotinto seca"},
    {prodId:"50425103",label:"Okra sin columna clemson seca"},
    {prodId:"50425104",label:"Okra verde enano vaina larga seca"},
    {prodId:"50425105",label:"Okra  mamut sin columna seca"},
    {prodId:"50425106",label:"Okra terciopelo rojo seca"},
    {prodId:"50425107",label:"Okra estrella de david reliquia seca"},
    {prodId:"50425600",label:"Papas Secas"},
    {prodId:"50425601",label:"Papa blanca larga seca"},
    {prodId:"50425602",label:"Papa redonda blanca seca"},
    {prodId:"50425603",label:"Papa redonda roja seca"},
    {prodId:"50425604",label:"Papa  russet seca"},
    {prodId:"50425605",label:"Papa morada seca"},
    {prodId:"50425606",label:"Papa amarilla seca"},
    {prodId:"50425607",label:"Papa nueva seca"},
    {prodId:"50425608",label:"Papa especial seca"},
    {prodId:"50425609",label:"Tocosh seco"},
    {prodId:"50425700",label:"Nabos suecos secos"},
    {prodId:"50425701",label:"Nabo sueco acme seco"},
    {prodId:"50425702",label:"Nabo sueco angela seco"},
    {prodId:"50425703",label:"Nabo sueco el mejor seco"},
    {prodId:"50425704",label:"Nabo sueco marian seco"},
    {prodId:"50425900",label:"Chalotes Secos"},
    {prodId:"50425901",label:"Chalote atlántica seco"},
    {prodId:"50425902",label:"Chalote creación seco"},
    {prodId:"50425903",label:"Chalote drittler nido blanco seco"},
    {prodId:"50425904",label:"Chalote gigante mejorado amarillo seco"},
    {prodId:"50425905",label:"Chalote gourmet dorado seco"},
    {prodId:"50425906",label:"Chalote grise de bagnolet seco"},
    {prodId:"50425907",label:"Chalote hative de nort seco"},
    {prodId:"50425908",label:"Chalote pikant seco"},
    {prodId:"50425909",label:"Chalote papa roja seco"},
    {prodId:"50425910",label:"Chalote sante seco"},
    {prodId:"50425911",label:"Chalote topper seco"},
    {prodId:"50426000",label:"Alazanes Secos"},
    {prodId:"50426001",label:"Alazán dock seca"},
    {prodId:"50426002",label:"Alazán jardín seca"},
    {prodId:"50426003",label:"Alazán oveja seca"},
    {prodId:"50426004",label:"Alazán madera seca"},
    {prodId:"50426100",label:"Espinacas Secas"},
    {prodId:"50426101",label:"Espinaca americana seca"},
    {prodId:"50426102",label:"Espinaca bloomsdale seca"},
    {prodId:"50426103",label:"Espinaca invierno gigante seca"},
    {prodId:"50426104",label:"Espinaca horenso seca"},
    {prodId:"50426105",label:"Espinaca planta de hielo seca"},
    {prodId:"50426106",label:"Espinaca  cuarto de cordero seca"},
    {prodId:"50426107",label:"Espinaca malabra seca"},
    {prodId:"50426108",label:"Espinaca mendania seca"},
    {prodId:"50426109",label:"Espinaca nueva zelandia seca"},
    {prodId:"50426110",label:"Espinaca orach seca"},
    {prodId:"50426111",label:"Espinaca savoy seca"},
    {prodId:"50426112",label:"Espinaca hoja de sigma seca"},
    {prodId:"50426113",label:"Espinaca espacio seca"},
    {prodId:"50426114",label:"Espinaca trinidad seca"},
    {prodId:"50426115",label:"Espinaca agua seca"},
    {prodId:"50426116",label:"Espinaca de agua sesa"},
    {prodId:"50426200",label:"Calabazas Secas"},
    {prodId:"50426201",label:"Calabaza boston seca"},
    {prodId:"50426202",label:"Calabaza buttemut seca"},
    {prodId:"50426203",label:"Calabaza costata romanesca seca"},
    {prodId:"50426204",label:"Calabaza crookneck seca"},
    {prodId:"50426205",label:"Calabaza  cucuzza seca"},
    {prodId:"50426206",label:"Calabaza  delicata seca"},
    {prodId:"50426207",label:"Calabaza  delisioso seca"},
    {prodId:"50426208",label:"Calabaza verano dorado cuello torcido seca"},
    {prodId:"50426209",label:"Calabaza prolífico temprano cuello recto seca"},
    {prodId:"50426210",label:"Calabaza  oro seca"},
    {prodId:"50426211",label:"Calabaza  jack pequeño seca"},
    {prodId:"50426212",label:"Calabaza  campo de kentucky seca"},
    {prodId:"50426213",label:"Calabaza marrow seca"},
    {prodId:"50426214",label:"Calabaza  medio oriente seca"},
    {prodId:"50426215",label:"Calabaza  miniatura seca"},
    {prodId:"50426216",label:"Calabaza  orangetti seca"},
    {prodId:"50426217",label:"Calabaza pattypan seca"},
    {prodId:"50426218",label:"Calabaza  rondini seca"},
    {prodId:"50426219",label:"Calabaza  redonda seca"},
    {prodId:"50426220",label:"Calabaza  espagueti seca"},
    {prodId:"50426221",label:"Calabaza  estripeti seca"},
    {prodId:"50426222",label:"Calabaza  molde dulce seca"},
    {prodId:"50426223",label:"Calabaza  empanada dulce seca"},
    {prodId:"50426224",label:"Calabaza  premio triple  seca"},
    {prodId:"50426225",label:"Calabaza waltham butternut seca"},
    {prodId:"50426226",label:"Calabaza  arbusto scallop amarillo seca"},
    {prodId:"50426227",label:"Calabaza cuello recto amarillo seca"},
    {prodId:"50426228",label:"Calabaza zafiro seca"},
    {prodId:"50426229",label:"Calabaza zucchini seca"},
    {prodId:"50426500",label:"Nabos Secos"},
    {prodId:"50426501",label:"Nabo globo verde seco"},
    {prodId:"50426502",label:"Nabo bola dorada seco"},
    {prodId:"50426503",label:"Nabo mercado de manchester seco"},
    {prodId:"50426504",label:"Nabo top morado milan seco"},
    {prodId:"50426505",label:"Nabo top morado blanco seco"},
    {prodId:"50426506",label:"Nabo bola de nieve seco"},
    {prodId:"50426507",label:"Nabo tokio seco"},
    {prodId:"50426508",label:"Nabo cruz de tokio seco"},
    {prodId:"50426600",label:"Auyamas Secas"},
    {prodId:"50426601",label:"Calabaza acorn seca"},
    {prodId:"50426602",label:"Calabaza gigante atlántico seca"},
    {prodId:"50426603",label:"Calabaza banana rosado seca"},
    {prodId:"50426604",label:"Calabaza max grande seca"},
    {prodId:"50426605",label:"Calabaza  seca"},
    {prodId:"50426606",label:"Calabaza carnaval seca"},
    {prodId:"50426607",label:"Auyama queso seca"},
    {prodId:"50426608",label:"Calabaza príncipe coronado seca"},
    {prodId:"50426609",label:"Calabaza  curcibita seca"},
    {prodId:"50426610",label:"Calabaza cushaw seca"},
    {prodId:"50426611",label:"Calabaza  gigante auyama seca"},
    {prodId:"50426612",label:"Calabaza  hubbard seca"},
    {prodId:"50426613",label:"Calabaza  jerrahdale seca"},
    {prodId:"50426614",label:"Calabaza  kabocha seca"},
    {prodId:"50426615",label:"Calabaza queensland azul seca"},
    {prodId:"50426616",label:"Calabaza rouge vif détampes seca"},
    {prodId:"50426617",label:"Calabaza  turban turco seca"},
    {prodId:"50426618",label:"Calabaza  valenciano seca"},
    {prodId:"50426619",label:"Calabaza  warted hubbard seca"},
    {prodId:"50426620",label:"Auyama whangaparoa seca"},
    {prodId:"50426700",label:"Ñames Secos"},
    {prodId:"50426701",label:"Ñame agria africana seca"},
    {prodId:"50426702",label:"Ñame agria asiática seca"},
    {prodId:"50426703",label:"Ñame china seco"},
    {prodId:"50426704",label:"Ñame globo seco"},
    {prodId:"50426705",label:"Ñame grater seco"},
    {prodId:"50426706",label:"Ñame japonesa seco"},
    {prodId:"50426707",label:"Ñame lesser seco"},
    {prodId:"50426708",label:"Ñame papa seco"},
    {prodId:"50426709",label:"Ñame guinea blanca seco"},
    {prodId:"50426710",label:"Ñame guinea amarillo seco"},
    {prodId:"50426900",label:"Alverjas dulces Secas"},
    {prodId:"50426901",label:"Alverja biquini seca"},
    {prodId:"50426902",label:"Alverja cavalier seca"},
    {prodId:"50426903",label:"Alverja margarita seca"},
    {prodId:"50426904",label:"Alverja dar fon seca"},
    {prodId:"50426905",label:"Alverja early onward seca"},
    {prodId:"50426906",label:"Alverja primer feltham seca"},
    {prodId:"50426907",label:"Alverja verde hurst shaft seca"},
    {prodId:"50426908",label:"Guisante oregón sugar pod seca"},
    {prodId:"50426909",label:"Alverja príncipe alberto seca"},
    {prodId:"50426910",label:"Alverja reuzensuiker seca"},
    {prodId:"50431500",label:"Alcachofas orgánicas secas"},
    {prodId:"50431501",label:"Alcachofa brittany orgánica seca"},
    {prodId:"50431502",label:"Alcachofa cantonesa orgánica seca"},
    {prodId:"50431503",label:"Alcachofa francesa orgánica seca"},
    {prodId:"50431504",label:"Alcachofa globo verde orgánica seca"},
    {prodId:"50431505",label:"Alcachofa gros camus de bretaña orgánica seca"},
    {prodId:"50431506",label:"Alcachofa midi orgánica seca"},
    {prodId:"50431507",label:"Alcachofa globo morado orgánica seca"},
    {prodId:"50431508",label:"Alcachofa morado cecilia orgánica seca"},
    {prodId:"50431509",label:"Alcachofa romanesco orgánica seca"},
    {prodId:"50431510",label:"Alcachofa espinoso sardo orgánica seca"},
    {prodId:"50431511",label:"Alcachofa vert de laon orgánica seca"},
    {prodId:"50431512",label:"Alcachofa violeta de chiggiia orgánica seca"},
    {prodId:"50431513",label:"Alcachofa violeta de toscana orgánica seca"},
    {prodId:"50431600",label:"Espárragos orgánicos secos"},
    {prodId:"50431601",label:"Espárragos connovers colosal orgánicos secos"},
    {prodId:"50431602",label:"Espárragos franklin orgánicos secos"},
    {prodId:"50431603",label:"Espárragos mamut gigante orgánicos secos"},
    {prodId:"50431604",label:"Espárragos lucullus orgánicos secos"},
    {prodId:"50431605",label:"Espárragos martha washington orgánicos secos"},
    {prodId:"50431700",label:"Aguacates orgánicos secos"},
    {prodId:"50431701",label:"Aguacate ajax b - 7 orgánico seco"},
    {prodId:"50431702",label:"Aguacate arue orgánico seco"},
    {prodId:"50431703",label:"Aguacate bacon orgánico seco"},
    {prodId:"50431704",label:"Aguacate benik orgánico seco"},
    {prodId:"50431705",label:"Aguacate bernecker orgánico seco"},
    {prodId:"50431706",label:"Aguacate beta orgánico seco"},
    {prodId:"50431707",label:"Aguacate biondo orgánico seco"},
    {prodId:"50431708",label:"Aguacate príncipe negro orgánico seco"},
    {prodId:"50431709",label:"Aguacate blair orgánico seco"},
    {prodId:"50431710",label:"Aguacate blair cabina orgánico seco"},
    {prodId:"50431711",label:"Aguacate cabina 1 orgánico seco"},
    {prodId:"50431712",label:"Aguacate cabina 3 orgánico seco"},
    {prodId:"50431713",label:"Aguacate cabina 5 orgánico seco"},
    {prodId:"50431714",label:"Aguacate cabina 7 orgánico seco"},
    {prodId:"50431715",label:"Aguacate cabina 8 orgánico seco"},
    {prodId:"50431716",label:"Aguacate brooks 1978 orgánico seco"},
    {prodId:"50431717",label:"Aguacate brookslate orgánico seco"},
    {prodId:"50431718",label:"Aguacate california haas orgánico seco"},
    {prodId:"50431719",label:"Aguacate catalina orgánico seco"},
    {prodId:"50431720",label:"Aguacate chica orgánico seco"},
    {prodId:"50431721",label:"Aguacate choquette orgánico seco"},
    {prodId:"50431722",label:"Aguacate cristina orgánico seco"},
    {prodId:"50431723",label:"Aguacate collison orgánico seco"},
    {prodId:"50431724",label:"Aguacate donnie orgánico seco"},
    {prodId:"50431725",label:"Aguacate dr dupis numero 2 orgánico seco"},
    {prodId:"50431726",label:"Aguacate dr. dupie  orgánico seco"},
    {prodId:"50431727",label:"Aguacate ettinger orgánico seco"},
    {prodId:"50431728",label:"Aguacate fuch orgánico seco"},
    {prodId:"50431729",label:"Aguacate fuch gwen orgánico seco"},
    {prodId:"50431730",label:"Aguacate fuerte orgánico seco"},
    {prodId:"50431731",label:"Aguacate gotham orgánico seco"},
    {prodId:"50431732",label:"Aguacate gossman orgánico seco"},
    {prodId:"50431733",label:"Aguacate guatemala sin pepa orgánico seco"},
    {prodId:"50431734",label:"Aguacate hall orgánico seco"},
    {prodId:"50431735",label:"Aguacate hardee orgánico seco"},
    {prodId:"50431736",label:"Aguacate haas orgánico seco"},
    {prodId:"50431737",label:"Aguacate herman orgánico seco"},
    {prodId:"50431738",label:"Aguacate hickson orgánico seco"},
    {prodId:"50431739",label:"Aguacate k-5 orgánico seco"},
    {prodId:"50431740",label:"Aguacate k- 9 orgánico seco"},
    {prodId:"50431741",label:"Aguacate cordero haas orgánico seco"},
    {prodId:"50431742",label:"Aguacate leona orgánico seco"},
    {prodId:"50431743",label:"Aguacate leona linda orgánico seco"},
    {prodId:"50431744",label:"Aguacate lisa p orgánico seco"},
    {prodId:"50431745",label:"Aguacate lisa loretta orgánico seco"},
    {prodId:"50431746",label:"Aguacate loretta orgánico seco"},
    {prodId:"50431747",label:"Aguacate lula orgánico seco"},
    {prodId:"50431748",label:"Aguacate lula mascarthur orgánico seco"},
    {prodId:"50431749",label:"Aguacate marcus orgánico seco"},
    {prodId:"50431750",label:"Aguacate melendez orgánico seco"},
    {prodId:"50431751",label:"Aguacate meya orgánico seco"},
    {prodId:"50431752",label:"Aguacate miguel p orgánico seco"},
    {prodId:"50431753",label:"Aguacate monroe orgánico seco"},
    {prodId:"50431754",label:"Aguacate murrieta verde orgánico seco"},
    {prodId:"50431755",label:"Aguacate nabal orgánico seco"},
    {prodId:"50431756",label:"Aguacate nadir orgánico seco"},
    {prodId:"50431757",label:"Aguacate nesbitt orgánico seco"},
    {prodId:"50431758",label:"Aguacate peterson orgánico seco"},
    {prodId:"50431759",label:"Aguacate pinelli orgánico seco"},
    {prodId:"50431760",label:"Aguacate pinkerton orgánico seco"},
    {prodId:"50431761",label:"Aguacate pollock orgánico seco"},
    {prodId:"50431762",label:"Aguacate puebla orgánico seco"},
    {prodId:"50431763",label:"Aguacate reed orgánico seco"},
    {prodId:"50431764",label:"Aguacaterue orgánico seco"},
    {prodId:"50431765",label:"Aguacateruehle orgánico seco"},
    {prodId:"50431766",label:"Aguacate ryan orgánico seco"},
    {prodId:"50431767",label:"Aguacate semil orgánico seco"},
    {prodId:"50431768",label:"Aguacate semil 43 orgánico seco"},
    {prodId:"50431769",label:"Aguacate simonds orgánico seco"},
    {prodId:"50431770",label:"Aguacate simpson orgánico seco"},
    {prodId:"50431771",label:"Aguacate taylor orgánico seco"},
    {prodId:"50431772",label:"Aguacate tonelada orgánico seco"},
    {prodId:"50431773",label:"Aguacate torre orgánico seco"},
    {prodId:"50431774",label:"Aguacate torre li orgánico seco"},
    {prodId:"50431775",label:"Aguacate trapp orgánico seco"},
    {prodId:"50431776",label:"Aguacate semilla de caribe orgánico seco"},
    {prodId:"50431777",label:"Aguacate wagner orgánico seco"},
    {prodId:"50431778",label:"Aguacate waldin orgánico seco"},
    {prodId:"50431779",label:"Aguacate wurtz orgánico seco"},
    {prodId:"50431780",label:"Aguacate zio p orgánico seco"},
    {prodId:"50431781",label:"Aguacate ziu orgánico seco"},
    {prodId:"50431782",label:"Aguacate zultano orgánico seco"},
    {prodId:"50431800",label:"Fríjoles orgánicos secos"},
    {prodId:"50431801",label:"Frijoles anasazi ® orgánicos secos"},
    {prodId:"50431802",label:"Frijoles appaloosa orgánicos secos"},
    {prodId:"50431803",label:"Frijoles azuki orgánicos secos"},
    {prodId:"50431804",label:"Frijoles barlotti orgánicos secos"},
    {prodId:"50431805",label:"Frijoles appaloosa negra orgánicos secos"},
    {prodId:"50431806",label:"Frijoles negros orgánicos secos"},
    {prodId:"50431807",label:"Frijoles gran negros orgánicos secos"},
    {prodId:"50431808",label:"Frijoles shackamaxon negro orgánicos secos"},
    {prodId:"50431809",label:"Frijoles ojo negro orgánicos secos"},
    {prodId:"50431810",label:"Frijoles bobby orgánicos secos"},
    {prodId:"50431811",label:"Frijoles bolita orgánicos secos"},
    {prodId:"50431812",label:"Frijoles esposa perezosa carmelita orgánicos secos"},
    {prodId:"50431813",label:"Frijoles calipso orgánicos secos"},
    {prodId:"50431814",label:"Frijoles canelini orgánicos secos"},
    {prodId:"50431815",label:"Frijoles castor orgánicos secos"},
    {prodId:"50431816",label:"Frijoles amarillo chino orgánicos secos"},
    {prodId:"50431817",label:"Frijoles lengua de dragón orgánicos secos"},
    {prodId:"50431818",label:"Frijoles soldado europeo orgánicos secos"},
    {prodId:"50431819",label:"Frijoles fava orgánicos secos"},
    {prodId:"50431820",label:"Frijoles flageolet orgánicos secos"},
    {prodId:"50431821",label:"Frijoles  horticultura francesa orgánicos secos"},
    {prodId:"50431822",label:"Frijoles marina francesa orgánicos secos"},
    {prodId:"50431823",label:"Frijoles coco gigante blanco orgánicos secos"},
    {prodId:"50431824",label:"Frijoles verdes orgánicos secos"},
    {prodId:"50431825",label:"Frijoles romano verde orgánicos secos"},
    {prodId:"50431826",label:"Frijoles guar gum orgánicos secos"},
    {prodId:"50431827",label:"Frijoles haricot orgánicos secos"},
    {prodId:"50431828",label:"Frijoles hyacinth orgánicos secos"},
    {prodId:"50431829",label:"Frijoles tipo italiano orgánicos secos"},
    {prodId:"50431830",label:"Frijoles asombro jackson orgánicos secos"},
    {prodId:"50431831",label:"Frijoles ganado jacobs  orgánicos secos"},
    {prodId:"50431832",label:"Frijoles asombro kentucky orgánicos secos"},
    {prodId:"50431833",label:"Frijoles riñón orgánicos secos"},
    {prodId:"50431834",label:"Frijoles lima orgánicos secos"},
    {prodId:"50431835",label:"Frijoles madera orgánicos secos"},
    {prodId:"50431836",label:"Frijoles medula orgánicos secos"},
    {prodId:"50431837",label:"Frijoles mat orgánicos secos"},
    {prodId:"50431838",label:"Frijoles monstoler ganzo salvaje orgánicos secos"},
    {prodId:"50431839",label:"Frijoles  mortgage lifter orgánicos secos"},
    {prodId:"50431840",label:"Frijoles polilla orgánicos secos"},
    {prodId:"50431841",label:"Frijoles mung orgánicos secos"},
    {prodId:"50431842",label:"Frijoles munsi wolf orgánicos secos"},
    {prodId:"50431843",label:"Frijoles nuna orgánicos secos"},
    {prodId:"50431844",label:"Frijoles pinto orgánicos secos"},
    {prodId:"50431845",label:"Frijoles runner orgánicos secos"},
    {prodId:"50431846",label:"String beans orgánicos secos"},
    {prodId:"50431847",label:"Frijoles habichuelas orgánicos secos"},
    {prodId:"50431848",label:"Frijoles tamarind orgánicos secos"},
    {prodId:"50431849",label:"Frijoles tonka orgánicos secos"},
    {prodId:"50431850",label:"Frijoles cera orgánicos secos"},
    {prodId:"50431851",label:"Frijoles winged orgánicos secos"},
    {prodId:"50431852",label:"Frijoles largos orgánicos secos"},
    {prodId:"50431900",label:"Remolachas  orgánicas secas"},
    {prodId:"50431901",label:"Remolacha acción orgánica seca"},
    {prodId:"50431902",label:"Remolacha albina vereduna orgánica seca"},
    {prodId:"50431903",label:"Remolacha barababiotela de chiggia orgánica seca"},
    {prodId:"50431904",label:"Remolacha boltardy orgánica seca"},
    {prodId:"50431905",label:"Remolacha bonel orgánica seca"},
    {prodId:"50431906",label:"Remolacha burpees dorado orgánica seca"},
    {prodId:"50431907",label:"Remolacha cheltenham tapa verde orgánica seca"},
    {prodId:"50431908",label:"Remolacha cheltenham mono orgánica seca"},
    {prodId:"50431909",label:"Remolacha chioggia orgánica seca"},
    {prodId:"50431910",label:"Remolacha cilindra orgánica seca"},
    {prodId:"50431911",label:"Remolacha dégypte orgánica seca"},
    {prodId:"50431912",label:"Remolacha detroit 2 rojo oscuro orgánica seca"},
    {prodId:"50431913",label:"Remolacha detroit 2 bala chiquita orgánica seca"},
    {prodId:"50431914",label:"Remolacha egipcia plana  orgánica seca"},
    {prodId:"50431915",label:"Remolacha  raíz de nabo egipcio orgánica seca"},
    {prodId:"50431916",label:"Remolacha fomanova orgánica seca"},
    {prodId:"50431917",label:"Remolacha forono orgánica seca"},
    {prodId:"50431918",label:"Remolacha monaco orgánica seca"},
    {prodId:"50431919",label:"Remolacha monograma orgánica seca"},
    {prodId:"50431920",label:"Remolacha pronto orgánica seca"},
    {prodId:"50431921",label:"Remolacha regalía orgánica seca"},
    {prodId:"50431922",label:"Remolacha dulce orgánica seca"},
    {prodId:"50432000",label:"Brócolis  orgánicas secas"},
    {prodId:"50432001",label:"Brócolini orgánico seco"},
    {prodId:"50432002",label:"Brócoli romanesco orgánico seco"},
    {prodId:"50432003",label:"Brócoli raab orgánico seco"},
    {prodId:"50432004",label:"Brócoli chino orgánico seco"},
    {prodId:"50432100",label:"Repollitos de Bruselas  orgánicas secas"},
    {prodId:"50432101",label:"Repollitas de bruselas citade orgánicas secas"},
    {prodId:"50432102",label:"Repollitas de bruselas falstaff orgánicas secas"},
    {prodId:"50432103",label:"Repollitas de bruselas oliver orgánicas secas"},
    {prodId:"50432104",label:"Repollitas de bruselas peer gynt orgánicas secas"},
    {prodId:"50432105",label:"Repollitas de bruselas rampart orgánicas secas"},
    {prodId:"50432106",label:"Repollitas de bruselas rubine orgánicas secas"},
    {prodId:"50432107",label:"Repollitas de bruselas  widgeon orgánicas secas"},
    {prodId:"50432200",label:"Cebollas de ensalada  orgánicas secas"},
    {prodId:"50432201",label:"Cebolla belstville ensalada orgánica seca"},
    {prodId:"50432202",label:"Cebolla feast ensalada orgánica seca"},
    {prodId:"50432203",label:"Cebolla ishikura ensalada orgánica seca"},
    {prodId:"50432204",label:"Cebolla mercado kyoto orgánica seca"},
    {prodId:"50432205",label:"Cebolla barba roja ensalada orgánica seca"},
    {prodId:"50432206",label:"Cebolla amigo rojo ensalada orgánica seca"},
    {prodId:"50432207",label:"Cebolla santa claus orgánica seca"},
    {prodId:"50432208",label:"Cebolla tokyo ensalada orgánica seca"},
    {prodId:"50432209",label:"Cebolla lisbon blanca ensalada orgánica seca"},
    {prodId:"50432210",label:"Cebolla invierno blanca ensalada orgánica seca"},
    {prodId:"50432211",label:"Cebolla final de invierno ensalada orgánica seca"},
    {prodId:"50432300",label:"Repollos orgánicos secos"},
    {prodId:"50432301",label:"Repollo negro orgánico seco"},
    {prodId:"50432302",label:"Repollo savoy orgánico seco"},
    {prodId:"50432303",label:"Repollo zorrillo orgánico seco"},
    {prodId:"50432304",label:"Repollo blanco orgánico seco"},
    {prodId:"50432400",label:"Cardos orgánicos secos"},
    {prodId:"50432401",label:"Cardos lunghi orgánicos secos"},
    {prodId:"50432402",label:"Cardos gobbi orgánicos secos"},
    {prodId:"50432500",label:"Zanahorias  orgánicas secas"},
    {prodId:"50432501",label:"Zanahoria amsterdam orgánica seca"},
    {prodId:"50432502",label:"Zanahoria rey de otoño orgánica seca"},
    {prodId:"50432503",label:"Zanahoria berlicum orgánica seca"},
    {prodId:"50432504",label:"Zanahoria chantenay orgánica seca"},
    {prodId:"50432505",label:"Zanahoria nantes orgánica seca"},
    {prodId:"50432506",label:"Zanahoria mercado de parís orgánica seca"},
    {prodId:"50432600",label:"Coliflores  orgánicas secas"},
    {prodId:"50432601",label:"Coliflor todo el año  orgánica seca"},
    {prodId:"50432602",label:"Coliflor alverda orgánica seca"},
    {prodId:"50432603",label:"coliflor otoño gigante 3 orgánica seca"},
    {prodId:"50432604",label:"Coliflo rdok elgon orgánica seca"},
    {prodId:"50432605",label:"Coliflor bola de nieve temprana orgánica seca"},
    {prodId:"50432606",label:"Coliflor luz de lima orgánica seca"},
    {prodId:"50432607",label:"Coliflor minaret orgánica seca"},
    {prodId:"50432608",label:"Coliflor buque de naranja orgánica seca"},
    {prodId:"50432609",label:"Coliflor capa morada orgánica seca"},
    {prodId:"50432610",label:"Coliflor bola de nieve orgánica seca"},
    {prodId:"50432611",label:"Coliflor invierno walcheren 3 orgánica seca"},
    {prodId:"50432612",label:"Coliflor piedra blanca orgánica seca"},
    {prodId:"50432700",label:"Apios orgánicos secos"},
    {prodId:"50432701",label:"Apio celebridad orgánico seco"},
    {prodId:"50432702",label:"Apio nabo orgánico seco"},
    {prodId:"50432703",label:"Apio chino orgánico seco"},
    {prodId:"50432704",label:"Apio dinant francés orgánico seco"},
    {prodId:"50432705",label:"Apio gigante rosado orgánico seco"},
    {prodId:"50432706",label:"Apio gigante rojo orgánico seco"},
    {prodId:"50432707",label:"Apio gigante blanco orgánico seco"},
    {prodId:"50432708",label:"Apio dorado auto escalado orgánico seco"},
    {prodId:"50432709",label:"Apio greensleeves orgánico seco"},
    {prodId:"50432710",label:"Apio hopkins fenlander orgánico seco"},
    {prodId:"50432711",label:"Apio torre marfil orgánico seco"},
    {prodId:"50432712",label:"Apio lathom blanqueado orgánico seco"},
    {prodId:"50432713",label:"Apio sopa de amsterdam orgánico seco"},
    {prodId:"50432714",label:"Apio estándar cargado orgánico seco"},
    {prodId:"50432715",label:"Apio triunfo alto utah orgánico seco"},
    {prodId:"50432800",label:"Acelgas  orgánicas secas"},
    {prodId:"50432801",label:"Acelga luz brillante orgánica seca"},
    {prodId:"50432802",label:"Acelga gigante fordhook orgánica seca"},
    {prodId:"50432803",label:"Acelga luculus orgánica seca"},
    {prodId:"50432804",label:"Acelga espinaca perpetua orgánica seca"},
    {prodId:"50432805",label:"Acelga ruibarbo orgánica seca"},
    {prodId:"50432806",label:"Acelga suizo orgánica seca"},
    {prodId:"50432807",label:"Acelga volcán  orgánica seca"},
    {prodId:"50432808",label:"Acelga rey blanco orgánica seca"},
    {prodId:"50432900",label:"Achicorias orgánicas secas"},
    {prodId:"50432901",label:"Achicoria batavian hoja ancha orgánica seca"},
    {prodId:"50432902",label:"Achicoria en cornet de bordeaux orgánica seca"},
    {prodId:"50432903",label:"Achicoria rollo verde ruffee orgánica seca"},
    {prodId:"50432904",label:"Achicoria roll verde orgánica seca"},
    {prodId:"50432905",label:"Achicoria limnoe lone orgánica seca"},
    {prodId:"50432906",label:"Achicoria pancalieri riccia orgánica seca"},
    {prodId:"50432907",label:"Achicoria ensalada rey orgánica seca"},
    {prodId:"50432908",label:"Achicoria sanda orgánica seca"},
    {prodId:"50432909",label:"Achicoria scarola verde orgánica seca"},
    {prodId:"50432910",label:"Achicoria tres fine maraichere orgánica seca"},
    {prodId:"50432911",label:"Achicoria wallone freisee wescelkkopf orgánica seca"},
    {prodId:"50433000",label:"Repollos chinos orgánicos secos"},
    {prodId:"50433001",label:"Bok choy orgánico seco"},
    {prodId:"50433002",label:"Bok choy enano orgánico seco"},
    {prodId:"50433003",label:"Flor de repollo chino orgánica seca"},
    {prodId:"50433004",label:"Choy sum orgánico seco"},
    {prodId:"50433005",label:"Col (bok choy) enano orgánico seco"},
    {prodId:"50433006",label:"Col (bok choy) fengshan orgánico seco"},
    {prodId:"50433007",label:"Col (bok choy) jade pagoda orgánico seco"},
    {prodId:"50433008",label:"Col (bok choy) kasumi orgánico seco"},
    {prodId:"50433009",label:"col (bok choy)  nerva orgánico seco"},
    {prodId:"50433010",label:"Col (bok choy) rosette orgánico seco"},
    {prodId:"50433011",label:"Col (bok choy) ruffles orgánico seco"},
    {prodId:"50433012",label:"Col (bok choy) hoja  santo serrated orgánico seco"},
    {prodId:"50433013",label:"Col (bok choy) shangahai orgánico seco"},
    {prodId:"50433014",label:"Shantung orgánico seco"},
    {prodId:"50433015",label:"Repollo tip top orgánico seco"},
    {prodId:"50433016",label:"Yau choy sum orgánico seco"},
    {prodId:"50433100",label:"Cebollines orgánicos secos"},
    {prodId:"50433101",label:"Cebollín chino orgánico seco"},
    {prodId:"50433200",label:"Maíces orgánicos secos"},
    {prodId:"50433201",label:"Maíz aloha orgánico seco"},
    {prodId:"50433202",label:"Maíz alpine orgánico seco"},
    {prodId:"50433203",label:"Maíz ambrosia orgánico seco"},
    {prodId:"50433204",label:"Maíz argent orgánico seco"},
    {prodId:"50433205",label:"Maíz aspen orgánico seco"},
    {prodId:"50433206",label:"Maíz avalancha orgánico seco"},
    {prodId:"50433207",label:"Maíz biqueen orgánico seco"},
    {prodId:"50433208",label:"Maíz  bodacious orgánico seco"},
    {prodId:"50433209",label:"Maíz  mantequilla y azúcar orgánico seco"},
    {prodId:"50433210",label:"Maíz  calico belle orgánico seco"},
    {prodId:"50433211",label:"Maíz  camelot orgánico seco"},
    {prodId:"50433212",label:"Maíz  challengercrisp ‘n dulce orgánico seco"},
    {prodId:"50433213",label:"Maíz  campeón orgánico seco"},
    {prodId:"50433214",label:"Maíz  algodón de azúcar orgánico seco"},
    {prodId:"50433215",label:"Maíz  dártagnan orgánico seco"},
    {prodId:"50433216",label:"Maíz  dazzle orgánico seco"},
    {prodId:"50433217",label:"Maíz  diamante y oro orgánico seco"},
    {prodId:"50433218",label:"Maíz  divinidad orgánico seco"},
    {prodId:"50433219",label:"Maíz  delicia doble orgánico seco"},
    {prodId:"50433220",label:"Maíz  gema doble orgánico seco"},
    {prodId:"50433221",label:"Maíz  earlivee orgánico seco"},
    {prodId:"50433222",label:"Maíz  temprano extra dulce orgánico seco"},
    {prodId:"50433223",label:"Maíz excel orgánico seco"},
    {prodId:"50433224",label:"Maíz  cruz dorada bantam orgánico seco"},
    {prodId:"50433225",label:"Maíz  miel y crema orgánico seco"},
    {prodId:"50433226",label:"Maíz  miel y perla orgánico seco"},
    {prodId:"50433227",label:"Maíz  miel dulce orgánico seco"},
    {prodId:"50433228",label:"Maíz  hudson orgánico seco"},
    {prodId:"50433229",label:"Maíz  dorado illini orgánico seco"},
    {prodId:"50433230",label:"Maíz  illini extra dulce orgánico seco"},
    {prodId:"50433231",label:"Maíz  increíble orgánico seco"},
    {prodId:"50433232",label:"Maíz  lochief orgánico seco"},
    {prodId:"50433233",label:"Maíz jubileo orgánico seco"},
    {prodId:"50433234",label:"Maíz jupbileo super dulce orgánico seco"},
    {prodId:"50433235",label:"Maíz  confite korn orgánico seco"},
    {prodId:"50433236",label:"Maíz  beso y acuso orgánico seco"},
    {prodId:"50433237",label:"Maíz  lancelot orgánico seco"},
    {prodId:"50433238",label:"Maíz dulce maple orgánico seco"},
    {prodId:"50433239",label:"Maíz  medley orgánico seco"},
    {prodId:"50433240",label:"Maíz merlin orgánico seco"},
    {prodId:"50433241",label:"Maíz milagro orgánico seco"},
    {prodId:"50433242",label:"Maíz nk - 199 orgánico seco"},
    {prodId:"50433243",label:"Maíz durazno y crema orgánico seco"},
    {prodId:"50433244",label:"Maíz blanco perla orgánico seco"},
    {prodId:"50433245",label:"Maíz  pegasus orgánico seco"},
    {prodId:"50433246",label:"Maíz  fenomenal orgánico seco"},
    {prodId:"50433247",label:"Maíz  dama de platino orgánico seco"},
    {prodId:"50433248",label:"Maíz  precioso orgánico seco"},
    {prodId:"50433249",label:"Maíz   pristine orgánico seco"},
    {prodId:"50433250",label:"Maíz  rápido orgánico seco"},
    {prodId:"50433251",label:"Maíz  radiante orgánico seco"},
    {prodId:"50433252",label:"Maíz  seneca brave orgánico seco"},
    {prodId:"50433253",label:"Maíz  amanecer seneca orgánico seco"},
    {prodId:"50433254",label:"Maíz  horizonte seneca orgánico seco"},
    {prodId:"50433255",label:"Maíz  brillo de estrella seneca orgánico seco"},
    {prodId:"50433256",label:"Maíz  noche blanca seneca orgánico seco"},
    {prodId:"50433257",label:"Maíz  showcase orgánico seco"},
    {prodId:"50433258",label:"Maíz  reina plateada orgánico seco"},
    {prodId:"50433259",label:"Maíz  bella nieve orgánico seco"},
    {prodId:"50433260",label:"Maíz nieve de primavera orgánico seco"},
    {prodId:"50433261",label:"Maíz  premio de primavera orgánico seco"},
    {prodId:"50433262",label:"Maíz  azúcar y oro orgánico seco"},
    {prodId:"50433263",label:"Maíz rollo de azúcar orgánico seco"},
    {prodId:"50433264",label:"Maíz nieve de azúcar orgánico seco"},
    {prodId:"50433265",label:"Maíz baile de sol orgánico seco"},
    {prodId:"50433266",label:"Maíz estrella tell orgánico seco"},
    {prodId:"50433267",label:"Maíz terminador orgánico seco"},
    {prodId:"50433268",label:"Maíz tesoro orgánico seco"},
    {prodId:"50433269",label:"Maíz tuxedo orgánico seco"},
    {prodId:"50433300",label:"Berros  orgánicos secos"},
    {prodId:"50433301",label:"Berros de tierra orgánicos secos"},
    {prodId:"50433302",label:"Nasturtium orgánico seco"},
    {prodId:"50433303",label:"Berros de agua orgánicos secos"},
    {prodId:"50433304",label:"Berros de invierno orgánicos secos"},
    {prodId:"50433400",label:"Cocombros orgánicos secos"},
    {prodId:"50433401",label:"Cocombro arena orgánico seco"},
    {prodId:"50433402",label:"Cocombro americano orgánico seco"},
    {prodId:"50433403",label:"Cocombro atena orgánico seco"},
    {prodId:"50433404",label:"Cocombro blanco lungo de parigi orgánico seco"},
    {prodId:"50433405",label:"Cocombro verde burpless buen sabor orgánico seco"},
    {prodId:"50433406",label:"Cocombro chicago pickling orgánico seco"},
    {prodId:"50433407",label:"Cocombro manzana cristal orgánico seco"},
    {prodId:"50433408",label:"Cocombro limón cristal orgánico seco"},
    {prodId:"50433409",label:"Cocombro danimas orgánico seco"},
    {prodId:"50433410",label:"Cocombro gherkin orgánico seco"},
    {prodId:"50433411",label:"Cocombro hokus orgánico seco"},
    {prodId:"50433412",label:"Cocombro japonés orgánico seco"},
    {prodId:"50433413",label:"Cocombro karela orgánico seco"},
    {prodId:"50433414",label:"Cocombro korila orgánico seco"},
    {prodId:"50433415",label:"Cocombro verde largo mejorado orgánico seco"},
    {prodId:"50433416",label:"Cocombro mas mercado orgánico seco"},
    {prodId:"50433417",label:"Cocombro enano orgánico seco"},
    {prodId:"50433418",label:"Cocombro pickling nacional orgánico seco"},
    {prodId:"50433419",label:"Cocombro persa orgánico seco"},
    {prodId:"50433420",label:"Cocombro telégrafo orgánico seco"},
    {prodId:"50433421",label:"Cocombro telégrafo mejorado orgánico seco"},
    {prodId:"50433422",label:"Cocombro vert de massy cornichon orgánico seco"},
    {prodId:"50433423",label:"Cocombro yamato orgánico seco"},
    {prodId:"50433500",label:"Berenjenas orgánicas secas"},
    {prodId:"50433501",label:"Berenjena bambino orgánica seca"},
    {prodId:"50433502",label:"Berenjena  belleza negra orgánica seca"},
    {prodId:"50433503",label:"Berenjena  negra enorme orgánica seca"},
    {prodId:"50433504",label:"Berenjena  chino orgánica seca"},
    {prodId:"50433505",label:"Berenjena  huevo de pascua orgánica seca"},
    {prodId:"50433506",label:"Berenjena  filipino orgánica seca"},
    {prodId:"50433507",label:"Berenjena   mercado de florida orgánica seca"},
    {prodId:"50433508",label:"Berenjena  india orgánica seca"},
    {prodId:"50433509",label:"Berenjena  italiano orgánica seca"},
    {prodId:"50433510",label:"Berenjena  japonés orgánica seca"},
    {prodId:"50433511",label:"Berenjena  morado largo orgánica seca"},
    {prodId:"50433512",label:"Berenjena   rayas larga orgánica seca"},
    {prodId:"50433513",label:"Berenjena  mercado de dinero orgánica seca"},
    {prodId:"50433514",label:"Berenjena  ova orgánica seca"},
    {prodId:"50433515",label:"Berenjena   arveja orgánica seca"},
    {prodId:"50433516",label:"Berenjena  tom bajito orgánica seca"},
    {prodId:"50433517",label:"Berenjena  siciliano orgánica seca"},
    {prodId:"50433518",label:"Berenjena  tailandés orgánica seca"},
    {prodId:"50433519",label:"Berenjena  violeta de florencia orgánica seca"},
    {prodId:"50433520",label:"Berenjena  blanco orgánica seca"},
    {prodId:"50433600",label:"Endivias orgánicas secas"},
    {prodId:"50433601",label:"Endivias witloof de brucelas orgánicas secas"},
    {prodId:"50433602",label:"Endivias francocastel orgánicas secas"},
    {prodId:"50433603",label:"Endivias catalogna de galantina orgánicas secas"},
    {prodId:"50433604",label:"Endivias chioggia orgánicas secas"},
    {prodId:"50433605",label:"Endivias grumolo verde orgánicas secas"},
    {prodId:"50433606",label:"Endivias raíz larga magdeburg orgánicas secas"},
    {prodId:"50433607",label:"Endivias palla rosa zorzi precoce orgánicas secas"},
    {prodId:"50433608",label:"Endivias radice amare orgánicas secas"},
    {prodId:"50433609",label:"Endivias rosa de traviso orgánicas secas"},
    {prodId:"50433610",label:"Endivias  rosa de verona orgánicas secas"},
    {prodId:"50433611",label:"Endivias soncino orgánicas secas"},
    {prodId:"50433612",label:"Endivias sugarthi orgánicas secas"},
    {prodId:"50433613",label:"Endivias verona orgánicas secas"},
    {prodId:"50433614",label:"Endivias witloof zoom orgánicas secas"},
    {prodId:"50433700",label:"Hinojos orgánicos secos"},
    {prodId:"50433701",label:"Hinojo cantino orgánico seco"},
    {prodId:"50433702",label:"Hinojo fino orgánico seco"},
    {prodId:"50433703",label:"Hinojoherald orgánico seco"},
    {prodId:"50433704",label:"Hinojo perfección orgánico seco"},
    {prodId:"50433705",label:"Hinojo sirio orgánico seco"},
    {prodId:"50433706",label:"Hinojo florencia dulce orgánico seco"},
    {prodId:"50433707",label:"Hinojo tardo orgánico seco"},
    {prodId:"50433900",label:"Calabazas orgánicas secas"},
    {prodId:"50433901",label:"Petula orgánica seca"},
    {prodId:"50433902",label:"Melon amargo orgánico seco"},
    {prodId:"50433903",label:"Botella calabaza orgánica seca"},
    {prodId:"50433904",label:"Calabash gounds orgánica seca"},
    {prodId:"50433905",label:"Melón peludo orgánico seco"},
    {prodId:"50433906",label:"Calabaza amarga orgánica seca"},
    {prodId:"50433907",label:"Loofah suave orgánico seco"},
    {prodId:"50433908",label:"Serpiente de calabaza orgánica seca"},
    {prodId:"50433909",label:"Calabaza larga amarga orgánica seca"},
    {prodId:"50433910",label:"Calabaza tinda orgánica seca"},
    {prodId:"50433911",label:"Calabaza tindoori orgánica seca"},
    {prodId:"50434000",label:"Guisantes orgánicas secas"},
    {prodId:"50434001",label:"Guisante chino orgánica seca"},
    {prodId:"50434002",label:"Guisante inglés orgánica seca"},
    {prodId:"50434003",label:"Guisante de jardín orgánica seca"},
    {prodId:"50434004",label:"Guisante nieve orgánica seca"},
    {prodId:"50434005",label:"Guisante snap dulce orgánica seca"},
    {prodId:"50434200",label:"Coles orgánicas secas"},
    {prodId:"50434201",label:"Col rizada orgánica seca"},
    {prodId:"50434202",label:"Repollo verde orgánico seco"},
    {prodId:"50434300",label:"Colinabos orgánicos secos"},
    {prodId:"50434301",label:"Colinabo estrella azur orgánico seco"},
    {prodId:"50434302",label:"Colinabo verde viena orgánico seco"},
    {prodId:"50434303",label:"Colinabo lanro orgánico seco"},
    {prodId:"50434304",label:"Colinabo viena morada orgánico seco"},
    {prodId:"50434305",label:"Colinabo trero rowel orgánico seco"},
    {prodId:"50434306",label:"Colinabo viena blanca orgánico seco"},
    {prodId:"50434400",label:"Puerros orgánicos secos"},
    {prodId:"50434401",label:"Puerro cobra gigante otoño orgánico seco"},
    {prodId:"50434402",label:"Puerro otoño mamut 2 orgánico seco"},
    {prodId:"50434403",label:"Puerro blue de solaise orgánico seco"},
    {prodId:"50434404",label:"Puerro cortina orgánico seco"},
    {prodId:"50434405",label:"Puerro prelina orgánico seco"},
    {prodId:"50434406",label:"Puerro salvaje orgánico seco"},
    {prodId:"50434500",label:"Lentejas  orgánicas secas"},
    {prodId:"50434501",label:"Lenteja beluga orgánica seca"},
    {prodId:"50434502",label:"Lenteja  verde francesa orgánica seca"},
    {prodId:"50434503",label:"Lenteja verde orgánica seca"},
    {prodId:"50434504",label:"Lenteja crimson pequeña orgánica seca"},
    {prodId:"50434505",label:"Lenteja pardina española orgánica seca"},
    {prodId:"50434506",label:"Lenteja roja separada orgánica seca"},
    {prodId:"50434507",label:"Lenteja amarilla separad orgánica seca"},
    {prodId:"50434508",label:"Lenteja tarahumara rosada orgánica seca"},
    {prodId:"50434600",label:"Lechugas orgánicas secas"},
    {prodId:"50434601",label:"Lechuga  bibb orgánica seca"},
    {prodId:"50434602",label:"Lechuga  boston orgánica seca"},
    {prodId:"50434603",label:"Lechuga frisee orgánica seca"},
    {prodId:"50434604",label:"Lechuga rosa lola orgánica seca"},
    {prodId:"50434605",label:"Lechuga  mixta masculina orgánica seca"},
    {prodId:"50434606",label:"Lechuga mizuna orgánica seca"},
    {prodId:"50434607",label:"Lechuga hoja roja orgánica seca"},
    {prodId:"50434608",label:"Lechuga oja roja roble orgánica seca"},
    {prodId:"50434609",label:"Lechuga romana ruby orgánica seca"},
    {prodId:"50434610",label:"Lechuga romana bebé roja orgánica seca"},
    {prodId:"50434611",label:"Lechuga cabeza de mantequilla orgánica seca"},
    {prodId:"50434612",label:"Lechuga china orgánica seca"},
    {prodId:"50434613",label:"Lechuga cabeza tostada orgánica seca"},
    {prodId:"50434614",label:"Lechuga hoja verde orgánica seca"},
    {prodId:"50434615",label:"Lechuga iceberg orgánica seca"},
    {prodId:"50434616",label:"Lechuga cordero orgánica seca"},
    {prodId:"50434617",label:"Lechuga hoja suelta orgánica seca"},
    {prodId:"50434618",label:"Lechuga mache orgánica seca"},
    {prodId:"50434619",label:"Lechuga boston roja orgánica seca"},
    {prodId:"50434620",label:"Lechuga cabeza roja orgánica seca"},
    {prodId:"50434621",label:"Lechuga romana orgánica seca"},
    {prodId:"50434622",label:"Lechuga mostza roja rusa orgánica seca"},
    {prodId:"50434623",label:"Lechuga tatsoi orgánica seca"},
    {prodId:"50434700",label:"Ñames orgánicos secos"},
    {prodId:"50434701",label:"Ñame amarilla orgánica seca"},
    {prodId:"50434702",label:"Ñame blanca orgánica seca"},
    {prodId:"50434703",label:"Ñame coco orgánica seca"},
    {prodId:"50434704",label:"Ñame eddoes orgánica seca"},
    {prodId:"50434705",label:"Ñame isleña orgánica seca"},
    {prodId:"50434706",label:"Ñame lila orgánica seca"},
    {prodId:"50434800",label:"Hongos o champiñones orgánicos secos"},
    {prodId:"50434801",label:"Champiñón trompeta negra orgánico seco"},
    {prodId:"50434802",label:"Champiñón carmelito orgánico seco"},
    {prodId:"50434803",label:"Champiñón  orgánico seco"},
    {prodId:"50434804",label:"Champiñón chanterelle orgánico seco"},
    {prodId:"50434805",label:"Champiñón cremini orgánico seco"},
    {prodId:"50434806",label:"Champiñón enoki orgánico seco"},
    {prodId:"50434807",label:"Champiñón puercoespín orgánico seco"},
    {prodId:"50434808",label:"Champiñón gallina del bosque orgánico seco"},
    {prodId:"50434809",label:"Champiñón langosta orgánico seco"},
    {prodId:"50434810",label:"Champiñón morels orgánico seco"},
    {prodId:"50434811",label:"Champiñón ostra orgánico seco"},
    {prodId:"50434812",label:"Champiñón pleurotus orgánico seco"},
    {prodId:"50434813",label:"Champiñón pompom orgánico seco"},
    {prodId:"50434814",label:"Champiñón porcieni orgánico seco"},
    {prodId:"50434815",label:"Champiñón portobello orgánico seco"},
    {prodId:"50434816",label:"Champiñón shitake orgánico seco"},
    {prodId:"50434817",label:"Champiñón shimeji orgánico seco"},
    {prodId:"50434818",label:"Champiñón san jorge orgánico seco"},
    {prodId:"50434819",label:"Champiñón blanco orgánico seco"},
    {prodId:"50434820",label:"Champiñón tompeta blanca orgánico seco"},
    {prodId:"50434821",label:"Champiñón woodear orgánico seco"},
    {prodId:"50434900",label:"Mostazas orgánicas secas"},
    {prodId:"50434901",label:"Mostaza bambú  orgánica seca"},
    {prodId:"50434902",label:"Mostaza ajo orgánica seca"},
    {prodId:"50434903",label:"Mostaza hoja gigante orgánica seca"},
    {prodId:"50434904",label:"Mostaza rojo en nieve orgánica seca"},
    {prodId:"50434905",label:"Mostaza sureño orgánica seca"},
    {prodId:"50434906",label:"Mostaza corazón envuelto orgánica seca"},
    {prodId:"50435000",label:"Solanáceas (nightshades) orgánicas secas"},
    {prodId:"50435001",label:"Lámpara china orgánica seca"},
    {prodId:"50435002",label:"Mora de jardín"},
    {prodId:"50435003",label:"Naranjilla orgánicas secas"},
    {prodId:"50435004",label:"Tomatillo orgánica seca"},
    {prodId:"50435100",label:"Okras orgánicas secas"},
    {prodId:"50435101",label:"Okra artista orgánica seca"},
    {prodId:"50435102",label:"Okra vinotinto orgánica seca"},
    {prodId:"50435103",label:"Okra sin columna clemson orgánica seca"},
    {prodId:"50435104",label:"Okra verde enano vaina larga orgánica seca"},
    {prodId:"50435105",label:"Okra  mamut sin columna orgánica seca"},
    {prodId:"50435106",label:"Okra terciopelo rojo orgánica seca"},
    {prodId:"50435107",label:"Okra estrella de david reliquia orgánica seca"},
    {prodId:"50435300",label:"Maníes orgánicos secos"},
    {prodId:"50435301",label:"Maní bambara orgánico seco"},
    {prodId:"50435302",label:"Maní florunner orgánico seco"},
    {prodId:"50435303",label:"Maní husa / kerstings orgánico seco"},
    {prodId:"50435304",label:"Maní español orgánico seco"},
    {prodId:"50435305",label:"Maní valencia orgánico seco"},
    {prodId:"50435306",label:"Maní virginia orgánico seco"},
    {prodId:"50435400",label:"Alverjas orgánicas secas"},
    {prodId:"50435401",label:"Alverja casco morado seca orgánica"},
    {prodId:"50435402",label:"Alverja pinkeye seca orgánica"},
    {prodId:"50435403",label:"Alverja crowder seca orgánica"},
    {prodId:"50435404",label:"Alverja acre blanco orgánica seca"},
    {prodId:"50435405",label:"Alverja ojo negro seca orgánica"},
    {prodId:"50435406",label:"Alverja crema zipper seca orgánica"},
    {prodId:"50435500",label:"Pimientos orgánicos secos"},
    {prodId:"50435501",label:"Pimentón ají orgánico seco"},
    {prodId:"50435502",label:"Pimentón  árbol orgánico seco"},
    {prodId:"50435503",label:"Pimentón queso orgánico seco"},
    {prodId:"50435504",label:"Pimentón chilaca orgánico seco"},
    {prodId:"50435505",label:"Pimentón cubanelles orgánico seco"},
    {prodId:"50435506",label:"Pimentón fresno orgánico seco"},
    {prodId:"50435507",label:"Pimentón kapia orgánico seco"},
    {prodId:"50435508",label:"Pimentón coreano orgánico seco"},
    {prodId:"50435509",label:"Pimentón manzano orgánico seco"},
    {prodId:"50435510",label:"Pimentón melrose orgánico seco"},
    {prodId:"50435511",label:"Pimentón chile amarillo orgánico seco"},
    {prodId:"50435512",label:"Pimentón aji dulce orgánico seco"},
    {prodId:"50435513",label:"Pimentón anaheim orgánico seco"},
    {prodId:"50435514",label:"Pimentón ancho orgánico seco"},
    {prodId:"50435515",label:"Pimentón campana orgánico seco"},
    {prodId:"50435516",label:"Pimentón cascabel orgánico seco"},
    {prodId:"50435517",label:"Pimentón cayenne orgánico seco"},
    {prodId:"50435518",label:"Pimentón anfitrión cereza orgánico seco"},
    {prodId:"50435519",label:"Pimentón chitespin orgánico seco"},
    {prodId:"50435520",label:"Pimentón dedo picante orgánico seco"},
    {prodId:"50435521",label:"Pimentón guajillo orgánico seco"},
    {prodId:"50435522",label:"Pimentón guerro orgánico seco"},
    {prodId:"50435523",label:"Pimentón habanero orgánico seco"},
    {prodId:"50435524",label:"Pimentón cera de hungria orgánico seco"},
    {prodId:"50435525",label:"Pimentón jalapeño orgánico seco"},
    {prodId:"50435526",label:"Pimentón picante largo orgánico seco"},
    {prodId:"50435527",label:"Pimentón marasol orgánico seco"},
    {prodId:"50435528",label:"Pimentón pasilla orgánico seco"},
    {prodId:"50435529",label:"Pimentón peperoncini orgánico seco"},
    {prodId:"50435530",label:"Pimentón pequin orgánico seco"},
    {prodId:"50435531",label:"Pimentón pimiento orgánico seco"},
    {prodId:"50435532",label:"Pimentón poblano orgánico seco"},
    {prodId:"50435533",label:"Pimentón scotch bonnet orgánico seco"},
    {prodId:"50435534",label:"Pimentón serrano orgánico seco"},
    {prodId:"50435535",label:"Pimentón tabasco orgánico seco"},
    {prodId:"50435536",label:"Pimentón tai  orgánico seco"},
    {prodId:"50435537",label:"Pimentón tepin orgánico seco"},
    {prodId:"50435600",label:"Papas orgánicas secas"},
    {prodId:"50435601",label:"Papa blanca larga orgánica seca"},
    {prodId:"50435602",label:"Papa redonda blanca orgánica seca"},
    {prodId:"50435603",label:"Papa redonda roja orgánica seca"},
    {prodId:"50435604",label:"Papa  russet orgánica seca"},
    {prodId:"50435605",label:"Papa morada orgánica seca"},
    {prodId:"50435606",label:"Papa amarilla orgánica seca"},
    {prodId:"50435607",label:"Papa nueva orgánica seca"},
    {prodId:"50435608",label:"Papa especial orgánica seca"},
    {prodId:"50435700",label:"Nabos suecos orgánicos secos"},
    {prodId:"50435701",label:"Nabo sueco acme orgánico seco"},
    {prodId:"50435702",label:"Nabo sueco angela orgánico seco"},
    {prodId:"50435703",label:"Nabo sueco el mejor orgánico seco"},
    {prodId:"50435704",label:"Nabo sueco marian orgánico seco"},
    {prodId:"50435900",label:"Chalotes orgánicos secos"},
    {prodId:"50435901",label:"Chalote atlántica orgánico seco"},
    {prodId:"50435902",label:"Chalote creación orgánico seco"},
    {prodId:"50435903",label:"Chalote drittler nido blanco orgánico seco"},
    {prodId:"50435904",label:"Chalote gigante mejorado amarillo orgánico seco"},
    {prodId:"50435905",label:"Chalote gourmet dorado orgánico seco"},
    {prodId:"50435906",label:"Chalote grise de bagnolet orgánico seco"},
    {prodId:"50435907",label:"Chalote hative de nort orgánico seco"},
    {prodId:"50435908",label:"Chalote pikant orgánico seco"},
    {prodId:"50435909",label:"Chalote papa roja orgánico seco"},
    {prodId:"50435910",label:"Chalote sante orgánico seco"},
    {prodId:"50435911",label:"Chalote topper orgánico seco"},
    {prodId:"50436000",label:"Alazanes orgánicos secos"},
    {prodId:"50436001",label:"Alazán dock orgánica seca"},
    {prodId:"50436002",label:"Alazán jardín orgánica seca"},
    {prodId:"50436003",label:"Alazán oveja orgánica seca"},
    {prodId:"50436004",label:"Alazán madera orgánica seca"},
    {prodId:"50436100",label:"Espinacas orgánicas secas"},
    {prodId:"50436101",label:"Espinaca americana orgánica seca"},
    {prodId:"50436102",label:"Espinaca bloomsdale orgánica seca"},
    {prodId:"50436103",label:"Espinaca invierno gigante orgánica seca"},
    {prodId:"50436104",label:"Espinaca horenso orgánica seca"},
    {prodId:"50436105",label:"Espinaca planta de hielo orgánica seca"},
    {prodId:"50436106",label:"Espinaca  cuarto de cordero orgánica seca"},
    {prodId:"50436107",label:"Espinaca malabra orgánica seca"},
    {prodId:"50436108",label:"Espinaca mendania orgánica seca"},
    {prodId:"50436109",label:"Espinaca nueva zelandia orgánica seca"},
    {prodId:"50436110",label:"Espinaca orach orgánica seca"},
    {prodId:"50436111",label:"Espinaca savoy orgánica seca"},
    {prodId:"50436112",label:"Espinaca hoja de sigma orgánica seca"},
    {prodId:"50436113",label:"Espinaca espacio orgánica seca"},
    {prodId:"50436114",label:"Espinaca trinidad orgánica seca"},
    {prodId:"50436115",label:"Espinaca agua orgánica seca"},
    {prodId:"50436116",label:"Espinaca de agua sesa"},
    {prodId:"50436200",label:"Calabazas orgánicas secas"},
    {prodId:"50436201",label:"Calabaza boston orgánica seca"},
    {prodId:"50436202",label:"Calabaza buttemut orgánica seca"},
    {prodId:"50436203",label:"Calabaza costata romanesca orgánica seca"},
    {prodId:"50436204",label:"Calabaza crookneck orgánica seca"},
    {prodId:"50436205",label:"Calabaza  cucuzza orgánica seca"},
    {prodId:"50436206",label:"Calabaza  delicata orgánica seca"},
    {prodId:"50436207",label:"Calabaza  delisioso orgánica seca"},
    {prodId:"50436208",label:"Calabaza verano dorado cuello torcido orgánica seca"},
    {prodId:"50436209",label:"Calabaza prolífico temprano cuello recto orgánica seca"},
    {prodId:"50436210",label:"Calabaza  oro orgánica seca"},
    {prodId:"50436211",label:"Calabaza  jack pequeño orgánica seca"},
    {prodId:"50436212",label:"Calabaza  campo de kentucky orgánica seca"},
    {prodId:"50436213",label:"Calabaza marrow orgánica seca"},
    {prodId:"50436214",label:"Calabaza  medio oriente orgánica seca"},
    {prodId:"50436215",label:"Calabaza  miniatura orgánica seca"},
    {prodId:"50436216",label:"Calabaza  orangetti orgánica seca"},
    {prodId:"50436217",label:"Calabaza pattypan orgánica seca"},
    {prodId:"50436218",label:"Calabaza  rondini orgánica seca"},
    {prodId:"50436219",label:"Calabaza  redonda orgánica seca"},
    {prodId:"50436220",label:"Calabaza  espagueti orgánica seca"},
    {prodId:"50436221",label:"Calabaza  estripeti orgánica seca"},
    {prodId:"50436222",label:"Calabaza  molde dulce orgánica seca"},
    {prodId:"50436223",label:"Calabaza  empanada dulce orgánica seca"},
    {prodId:"50436224",label:"Calabaza  premio triple  orgánica seca"},
    {prodId:"50436225",label:"Calabaza waltham butternut orgánica seca"},
    {prodId:"50436226",label:"Calabaza  arbusto scallop amarillo orgánica seca"},
    {prodId:"50436227",label:"Calabaza cuello recto amarillo orgánica seca"},
    {prodId:"50436228",label:"Calabaza zafiro orgánica seca"},
    {prodId:"50436229",label:"Calabaza zucchini orgánica seca"},
    {prodId:"50436300",label:"Batatas orgánicas secas"},
    {prodId:"50436301",label:"Batata beauregard orgánica seca"},
    {prodId:"50436302",label:"Batata centennial orgánica seca"},
    {prodId:"50436303",label:"Batata diane orgánica seca"},
    {prodId:"50436304",label:"Batata dulce garnet orgánica seca"},
    {prodId:"50436305",label:"Batata georgia dulce rojo orgánica seca"},
    {prodId:"50436306",label:"Batata  dorado orgánica seca"},
    {prodId:"50436307",label:"Batata hanna  orgánica seca"},
    {prodId:"50436308",label:"Batata japonés orgánica seca"},
    {prodId:"50436309",label:"Batata jersey orgánica seca"},
    {prodId:"50436310",label:"Batata joya orgánica seca"},
    {prodId:"50436311",label:"Batata meryland roja orgánica seca"},
    {prodId:"50436312",label:"Batata nema dorado orgánica seca"},
    {prodId:"50436313",label:"Batata o'henry orgánica seca"},
    {prodId:"50436314",label:"Batata okinawan orgánica seca"},
    {prodId:"50436315",label:"Batata naranja orgánica seca"},
    {prodId:"50436316",label:"Batata oriental orgánica seca"},
    {prodId:"50436317",label:"Batata jersey rojo orgánica seca"},
    {prodId:"50436318",label:"Batata mar rojo orgánica seca"},
    {prodId:"50436319",label:"Batata brillo rojo orgánica seca"},
    {prodId:"50436320",label:"Batata jersey amarillo orgánica seca"},
    {prodId:"50436400",label:"Tomates orgánicos secos"},
    {prodId:"50436401",label:"Tomates ailsa craig orgánico seco"},
    {prodId:"50436402",label:"Tomates alicante orgánico seco"},
    {prodId:"50436403",label:"Tomates ciruela negra orgánico seco"},
    {prodId:"50436404",label:"Tomates vino de brandy orgánico seco"},
    {prodId:"50436405",label:"Tomates bella cereza orgánico seco"},
    {prodId:"50436406",label:"Tomates cereza orgánico seco"},
    {prodId:"50436407",label:"Tomates delicioso orgánico seco"},
    {prodId:"50436408",label:"Tomates dombito orgánico seco"},
    {prodId:"50436409",label:"Tomates delicia del jardín orgánico seco"},
    {prodId:"50436410",label:"Tomates uva orgánico seco"},
    {prodId:"50436411",label:"Tomates verde orgánico seco"},
    {prodId:"50436412",label:"Tomates super marmande orgánico seco"},
    {prodId:"50436413",label:"Tomates rayas naturales marvel orgánico seco"},
    {prodId:"50436414",label:"Tomates minibel orgánico seco"},
    {prodId:"50436415",label:"Tomates oaxacan orgánico seco"},
    {prodId:"50436416",label:"Tomates alerta roja orgánico seco"},
    {prodId:"50436417",label:"Tomates roma vf orgánico seco"},
    {prodId:"50436418",label:"Tomates marzano zan orgánico seco"},
    {prodId:"50436419",label:"Tomates shirley orgánico seco"},
    {prodId:"50436420",label:"Tomates siberia orgánico seco"},
    {prodId:"50436421",label:"Tomates super beefsteak orgánico seco"},
    {prodId:"50436422",label:"Tomates tigerella orgánico seco"},
    {prodId:"50436423",label:"Tomates tiny tim orgánico seco"},
    {prodId:"50436424",label:"Tomates tumbler orgánico seco"},
    {prodId:"50436425",label:"Tomates cocktail amarillo orgánico seco"},
    {prodId:"50436426",label:"Tomates forma de pera amarillo orgánico seco"},
    {prodId:"50436427",label:"Tomates perfección amarilla orgánico seco"},
    {prodId:"50436500",label:"Nabos orgánicos secos"},
    {prodId:"50436501",label:"Nabo globo verde orgánico seco"},
    {prodId:"50436502",label:"Nabo bola dorada orgánico seco"},
    {prodId:"50436503",label:"Nabo mercado de manchester orgánico seco"},
    {prodId:"50436504",label:"Nabo top morado milan orgánico seco"},
    {prodId:"50436505",label:"Nabo top morado blanco orgánico seco"},
    {prodId:"50436506",label:"Nabo bola de nieve orgánico seco"},
    {prodId:"50436507",label:"Nabo tokio orgánico seco"},
    {prodId:"50436508",label:"Nabo cruz de tokio orgánico seco"},
    {prodId:"50436600",label:"Auyamas orgánicas secas"},
    {prodId:"50436601",label:"Calabaza acorn seca orgánica"},
    {prodId:"50436602",label:"Calabaza gigante atlántico orgánica seca"},
    {prodId:"50436603",label:"Calabaza banana rosado orgánica seca"},
    {prodId:"50436604",label:"Calabaza max grande orgánica seca"},
    {prodId:"50436605",label:"Calabaza  orgánica seca"},
    {prodId:"50436606",label:"Calabaza carnaval orgánica seca"},
    {prodId:"50436607",label:"Auyama queso orgánica seca"},
    {prodId:"50436608",label:"Calabaza príncipe coronado orgánica seca"},
    {prodId:"50436609",label:"Calabaza  curcibita orgánica seca"},
    {prodId:"50436610",label:"Calabaza cushaw orgánica seca"},
    {prodId:"50436611",label:"Calabaza  gigante auyama orgánica seca"},
    {prodId:"50436612",label:"Calabaza  hubbard orgánica seca"},
    {prodId:"50436613",label:"Calabaza  jerrahdale orgánica seca"},
    {prodId:"50436614",label:"Calabaza  kabocha orgánica seca"},
    {prodId:"50436615",label:"Calabaza queensland azul orgánica seca"},
    {prodId:"50436616",label:"Calabaza rouge vif détampes orgánica seca"},
    {prodId:"50436617",label:"Calabaza  turban turco orgánica seca"},
    {prodId:"50436618",label:"Calabaza  valenciano orgánica seca"},
    {prodId:"50436619",label:"Calabaza  warted hubbard orgánica seca"},
    {prodId:"50436620",label:"Auyama whangaparoa orgánica seca"},
    {prodId:"50436700",label:"Ñames orgánicos secos"},
    {prodId:"50436701",label:"Ñame agria africana orgánica seca"},
    {prodId:"50436702",label:"Ñame agria asiática orgánica seca"},
    {prodId:"50436703",label:"Ñame china orgánico seco"},
    {prodId:"50436704",label:"Ñame globo orgánico seco"},
    {prodId:"50436705",label:"Ñame grater orgánico seco"},
    {prodId:"50436706",label:"Ñame japonesa orgánico seco"},
    {prodId:"50436707",label:"Ñame lesser orgánico seco"},
    {prodId:"50436708",label:"Ñame papa orgánico seco"},
    {prodId:"50436709",label:"Ñame guinea blanca orgánico seco"},
    {prodId:"50436710",label:"Ñame guinea amarillo orgánico seco"},
    {prodId:"50436900",label:"Alverjas dulces orgánicas secas"},
    {prodId:"50436901",label:"Alverja biquini orgánica seca"},
    {prodId:"50436902",label:"Alverja cavalier orgánica seca"},
    {prodId:"50436903",label:"Alverja margarita orgánica seca"},
    {prodId:"50436904",label:"Alverja dar fon orgánica seca"},
    {prodId:"50436905",label:"Alverja early onward orgánica seca"},
    {prodId:"50436906",label:"Alverja primer feltham orgánica seca"},
    {prodId:"50436907",label:"Alverja verde hurst shaft orgánica seca"},
    {prodId:"50436908",label:"Guisante oregón sugar pod orgánica seca"},
    {prodId:"50436909",label:"Alverja príncipe alberto orgánica seca"},
    {prodId:"50436910",label:"Alverja reuzensuiker orgánica seca"},
    {prodId:"50441500",label:"Alcachofas congeladas"},
    {prodId:"50441501",label:"Alcachofa brittany congelada"},
    {prodId:"50441502",label:"Alcachofa cantonesa congelada"},
    {prodId:"50441503",label:"Alcachofa francesa congelada"},
    {prodId:"50441504",label:"Alcachofa globo verde congelada"},
    {prodId:"50441505",label:"Alcachofa gros camus de bretaña congelada"},
    {prodId:"50441506",label:"Alcachofa midi congelada"},
    {prodId:"50441507",label:"Alcachofa globo morado congelada"},
    {prodId:"50441508",label:"Alcachofa morado cecilia congelada"},
    {prodId:"50441509",label:"Alcachofa romanesco congelada"},
    {prodId:"50441510",label:"Alcachofa espinoso sardo congelada"},
    {prodId:"50441511",label:"Alcachofa vert de laon congelada"},
    {prodId:"50441512",label:"Alcachofa violeta de chiggiia congelada"},
    {prodId:"50441513",label:"Alcachofa violeta de toscana congelada"},
    {prodId:"50441600",label:"Espárragos congelados"},
    {prodId:"50441601",label:"Espárragos connovers colosal congelados"},
    {prodId:"50441602",label:"Espárragos franklin congelados"},
    {prodId:"50441603",label:"Espárragos mamut gigante congelados"},
    {prodId:"50441604",label:"Espárragos lucullus congelados"},
    {prodId:"50441605",label:"Espárragos martha washington congelados"},
    {prodId:"50441800",label:"Fríjoles congelados"},
    {prodId:"50441801",label:"Frijoles anasazi ® congelados"},
    {prodId:"50441802",label:"Frijoles appaloosa congelados"},
    {prodId:"50441803",label:"Frijoles azuki congelados"},
    {prodId:"50441804",label:"Frijoles barlotti congelados"},
    {prodId:"50441805",label:"Frijoles appaloosa negra congelados"},
    {prodId:"50441806",label:"Frijoles negros congelados"},
    {prodId:"50441807",label:"Frijoles gran negros congelados"},
    {prodId:"50441808",label:"Frijoles shackamaxon negro congelados"},
    {prodId:"50441809",label:"Frijoles ojo negro congelados"},
    {prodId:"50441810",label:"Frijoles bobby congelados"},
    {prodId:"50441811",label:"Frijoles bolita congelados"},
    {prodId:"50441812",label:"Frijoles esposa perezosa carmelita congelados"},
    {prodId:"50441813",label:"Frijoles calipso congelados"},
    {prodId:"50441814",label:"Frijoles canelini congelados"},
    {prodId:"50441815",label:"Frijoles castor congelados"},
    {prodId:"50441816",label:"Frijoles amarillo chino congelados"},
    {prodId:"50441817",label:"Frijoles lengua de dragón congelados"},
    {prodId:"50441818",label:"Frijoles soldado europeo congelados"},
    {prodId:"50441819",label:"Frijoles fava congelados"},
    {prodId:"50441820",label:"Frijoles flageolet congelados"},
    {prodId:"50441821",label:"Frijoles  horticultura francesa congelados"},
    {prodId:"50441822",label:"Frijoles marina francesa congelados"},
    {prodId:"50441823",label:"Frijoles coco gigante blanco congelados"},
    {prodId:"50441824",label:"Frijoles verdes congelados"},
    {prodId:"50441825",label:"Frijoles romano verde congelados"},
    {prodId:"50441826",label:"Frijoles guar gum congelados"},
    {prodId:"50441827",label:"Frijoles haricot congelados"},
    {prodId:"50441828",label:"Frijoles hyacinth congelados"},
    {prodId:"50441829",label:"Frijoles tipo italiano congelados"},
    {prodId:"50441830",label:"Frijoles asombro jackson congelados"},
    {prodId:"50441831",label:"Frijoles ganado jacobs  congelados"},
    {prodId:"50441832",label:"Frijoles asombro kentucky congelados"},
    {prodId:"50441833",label:"Frijoles riñón congelados"},
    {prodId:"50441834",label:"Frijoles lima congelados"},
    {prodId:"50441835",label:"Frijoles madera congelados"},
    {prodId:"50441836",label:"Frijoles medula congelados"},
    {prodId:"50441837",label:"Frijoles mat congelados"},
    {prodId:"50441838",label:"Frijoles monstoler ganzo salvaje congelados"},
    {prodId:"50441839",label:"Frijoles  mortgage lifter congelados"},
    {prodId:"50441840",label:"Frijoles polilla congelados"},
    {prodId:"50441841",label:"Frijoles mung congelados"},
    {prodId:"50441842",label:"Frijoles munsi wolf congelados"},
    {prodId:"50441843",label:"Frijoles nuna congelados"},
    {prodId:"50441844",label:"Frijoles pinto congelados"},
    {prodId:"50441845",label:"Frijoles runner congelados"},
    {prodId:"50441846",label:"String beans congelados"},
    {prodId:"50441847",label:"Frijoles habichuelas congelados"},
    {prodId:"50441848",label:"Frijoles tamarind congelados"},
    {prodId:"50441849",label:"Frijoles tonka congelados"},
    {prodId:"50441850",label:"Frijoles cera congelados"},
    {prodId:"50441851",label:"Frijoles winged congelados"},
    {prodId:"50441852",label:"Frijoles largos congelados"},
    {prodId:"50441900",label:"Remolachas  congeladas"},
    {prodId:"50441901",label:"Remolacha acción congelada"},
    {prodId:"50441902",label:"Remolacha albina vereduna congelada"},
    {prodId:"50441903",label:"Remolacha barababiotela de chiggia congelada"},
    {prodId:"50441904",label:"Remolacha boltardy congelada"},
    {prodId:"50441905",label:"Remolacha bonel congelada"},
    {prodId:"50441906",label:"Remolacha burpees dorado congelada"},
    {prodId:"50441907",label:"Remolacha cheltenham tapa verde congelada"},
    {prodId:"50441908",label:"Remolacha cheltenham mono congelada"},
    {prodId:"50441909",label:"Remolacha chioggia congelada"},
    {prodId:"50441910",label:"Remolacha cilindra congelada"},
    {prodId:"50441911",label:"Remolacha dégypte congelada"},
    {prodId:"50441912",label:"Remolacha detroit 2 rojo oscuro congelada"},
    {prodId:"50441913",label:"Remolacha detroit 2 bala chiquita congelada"},
    {prodId:"50441914",label:"Remolacha egipcia plana  congelada"},
    {prodId:"50441915",label:"Remolacha  raíz de nabo egipcio congelada"},
    {prodId:"50441916",label:"Remolacha fomanova congelada"},
    {prodId:"50441917",label:"Remolacha forono congelada"},
    {prodId:"50441918",label:"Remolacha monaco congelada"},
    {prodId:"50441919",label:"Remolacha monograma congelada"},
    {prodId:"50441920",label:"Remolacha pronto congelada"},
    {prodId:"50441921",label:"Remolacha regalía congelada"},
    {prodId:"50441922",label:"Remolacha dulce congelada"},
    {prodId:"50442100",label:"Repollitos de Bruselas  congeladas"},
    {prodId:"50442101",label:"Repollitas de bruselas citade congeladas"},
    {prodId:"50442102",label:"Repollitas de bruselas falstaff congeladas"},
    {prodId:"50442103",label:"Repollitas de bruselas oliver congeladas"},
    {prodId:"50442104",label:"Repollitas de bruselas peer gynt congeladas"},
    {prodId:"50442105",label:"Repollitas de bruselas rampart congeladas"},
    {prodId:"50442106",label:"Repollitas de bruselas rubine congeladas"},
    {prodId:"50442107",label:"Repollitas de bruselas  widgeon congeladas"},
    {prodId:"50442200",label:"Cebollas de ensalada  congeladas"},
    {prodId:"50442201",label:"Cebolla belstville ensalada congelada"},
    {prodId:"50442202",label:"Cebolla feast ensalada congelada"},
    {prodId:"50442203",label:"Cebolla ishikura ensalada congelada"},
    {prodId:"50442204",label:"Cebolla mercado kyoto congelada"},
    {prodId:"50442205",label:"Cebolla barba roja ensalada congelada"},
    {prodId:"50442206",label:"Cebolla amigo rojo ensalada congelada"},
    {prodId:"50442207",label:"Cebolla santa claus congelada"},
    {prodId:"50442208",label:"Cebolla tokyo ensalada congelada"},
    {prodId:"50442209",label:"Cebolla lisbon blanca ensalada congelada"},
    {prodId:"50442210",label:"Cebolla invierno blanca ensalada congelada"},
    {prodId:"50442211",label:"Cebolla final de invierno ensalada congelada"},
    {prodId:"50442300",label:"Repollos congelados"},
    {prodId:"50442301",label:"Repollo negro congelado"},
    {prodId:"50442302",label:"Repollo savoy congelado"},
    {prodId:"50442303",label:"Repollo zorrillo congelado"},
    {prodId:"50442304",label:"Repollo blanco congelado"},
    {prodId:"50442400",label:"Cardos congelados"},
    {prodId:"50442401",label:"Cardos lunghi congelados"},
    {prodId:"50442402",label:"Cardos gobbi congelados"},
    {prodId:"50442700",label:"Apios congelados"},
    {prodId:"50442701",label:"Apio celebridad congelado"},
    {prodId:"50442702",label:"Apio nabo congelado"},
    {prodId:"50442703",label:"Apio chino congelado"},
    {prodId:"50442704",label:"Apio dinant francés congelado"},
    {prodId:"50442705",label:"Apio gigante rosado congelado"},
    {prodId:"50442706",label:"Apio gigante rojo congelado"},
    {prodId:"50442707",label:"Apio gigante blanco congelado"},
    {prodId:"50442708",label:"Apio dorado auto escalado congelado"},
    {prodId:"50442709",label:"Apio greensleeves congelado"},
    {prodId:"50442710",label:"Apio hopkins fenlander congelado"},
    {prodId:"50442711",label:"Apio torre marfil congelado"},
    {prodId:"50442712",label:"Apio lathom blanqueado congelado"},
    {prodId:"50442713",label:"Apio sopa de amsterdam congelado"},
    {prodId:"50442714",label:"Apio estándar cargado congelado"},
    {prodId:"50442715",label:"Apio triunfo alto utah congelado"},
    {prodId:"50442800",label:"Acelgas  congeladas"},
    {prodId:"50442801",label:"Acelga luz brillante congelada"},
    {prodId:"50442802",label:"Acelga gigante fordhook congelada"},
    {prodId:"50442803",label:"Acelga luculus congelada"},
    {prodId:"50442804",label:"Acelga espinaca perpetua congelada"},
    {prodId:"50442805",label:"Acelga ruibarbo congelada"},
    {prodId:"50442806",label:"Acelga suizo congelada"},
    {prodId:"50442807",label:"Acelga volcán  congelada"},
    {prodId:"50442808",label:"Acelga rey blanco congelada"},
    {prodId:"50442900",label:"Achicorias congeladas"},
    {prodId:"50442901",label:"Achicoria batavian hoja ancha congelada"},
    {prodId:"50442902",label:"Achicoria en cornet de bordeaux congelada"},
    {prodId:"50442903",label:"Achicoria rollo verde ruffee congelada"},
    {prodId:"50442904",label:"Achicoria roll verde congelada"},
    {prodId:"50442905",label:"Achicoria limnoe lone congelada"},
    {prodId:"50442906",label:"Achicoria pancalieri riccia congelada"},
    {prodId:"50442907",label:"Achicoria ensalada rey congelada"},
    {prodId:"50442908",label:"Achicoria sanda congelada"},
    {prodId:"50442909",label:"Achicoria scarola verde congelada"},
    {prodId:"50442910",label:"Achicoria tres fine maraichere congelada"},
    {prodId:"50442911",label:"Achicoria wallone freisee wescelkkopf congelada"},
    {prodId:"50443000",label:"Repollos chinos congelados"},
    {prodId:"50443001",label:"Bok choy congelado"},
    {prodId:"50443002",label:"Bok choy enano congelado"},
    {prodId:"50443003",label:"Flor de repollo chino congelada"},
    {prodId:"50443004",label:"Choy sum congelado"},
    {prodId:"50443005",label:"Col (bok choy) enano congelado"},
    {prodId:"50443006",label:"Col (bok choy) fengshan congelado"},
    {prodId:"50443007",label:"Col (bok choy) jade pagoda congelado"},
    {prodId:"50443008",label:"Col (bok choy) kasumi congelado"},
    {prodId:"50443009",label:"col (bok choy)  nerva congelado"},
    {prodId:"50443010",label:"Col (bok choy) rosette congelado"},
    {prodId:"50443011",label:"Col (bok choy) ruffles congelado"},
    {prodId:"50443012",label:"Col (bok choy) hoja  santo serrated congelado"},
    {prodId:"50443013",label:"Col (bok choy) shangahai congelado"},
    {prodId:"50443014",label:"Shantung congelado"},
    {prodId:"50443015",label:"Repollo tip top congelado"},
    {prodId:"50443016",label:"Yau choy sum congelado"},
    {prodId:"50443100",label:"Cebollines congelados"},
    {prodId:"50443101",label:"Cebollín chino congelado"},
    {prodId:"50443300",label:"Berros  congelados"},
    {prodId:"50443301",label:"Berros de tierra congelados"},
    {prodId:"50443302",label:"Nasturtium congelado"},
    {prodId:"50443303",label:"Berros de agua congelados"},
    {prodId:"50443304",label:"Berros de invierno congelados"},
    {prodId:"50443400",label:"Cocombros congelados"},
    {prodId:"50443401",label:"Cocombro arena congelado"},
    {prodId:"50443402",label:"Cocombro americano congelado"},
    {prodId:"50443403",label:"Cocombro atena congelado"},
    {prodId:"50443404",label:"Cocombro blanco lungo de parigi congelado"},
    {prodId:"50443405",label:"Cocombro verde burpless buen sabor congelado"},
    {prodId:"50443406",label:"Cocombro chicago pickling congelado"},
    {prodId:"50443407",label:"Cocombro manzana cristal congelado"},
    {prodId:"50443408",label:"Cocombro limón cristal congelado"},
    {prodId:"50443409",label:"Cocombro danimas congelado"},
    {prodId:"50443410",label:"Cocombro gherkin congelado"},
    {prodId:"50443411",label:"Cocombro hokus congelado"},
    {prodId:"50443412",label:"Cocombro japonés congelado"},
    {prodId:"50443413",label:"Cocombro karela congelado"},
    {prodId:"50443414",label:"Cocombro korila congelado"},
    {prodId:"50443415",label:"Cocombro verde largo mejorado congelado"},
    {prodId:"50443416",label:"Cocombro mas mercado congelado"},
    {prodId:"50443417",label:"Cocombro enano congelado"},
    {prodId:"50443418",label:"Cocombro pickling nacional congelado"},
    {prodId:"50443419",label:"Cocombro persa congelado"},
    {prodId:"50443420",label:"Cocombro telégrafo congelado"},
    {prodId:"50443421",label:"Cocombro telégrafo mejorado congelado"},
    {prodId:"50443422",label:"Cocombro vert de massy cornichon congelado"},
    {prodId:"50443423",label:"Cocombro yamato congelado"},
    {prodId:"50443500",label:"Berenjenas congeladas"},
    {prodId:"50443501",label:"Berenjena bambino congelada"},
    {prodId:"50443502",label:"Berenjena  belleza negra congelada"},
    {prodId:"50443503",label:"Berenjena  negra enorme congelada"},
    {prodId:"50443504",label:"Berenjena  chino congelada"},
    {prodId:"50443505",label:"Berenjena  huevo de pascua congelada"},
    {prodId:"50443506",label:"Berenjena  filipino congelada"},
    {prodId:"50443507",label:"Berenjena   mercado de florida congelada"},
    {prodId:"50443508",label:"Berenjena  india congelada"},
    {prodId:"50443509",label:"Berenjena  italiano congelada"},
    {prodId:"50443510",label:"Berenjena  japonés congelada"},
    {prodId:"50443511",label:"Berenjena  morado largo congelada"},
    {prodId:"50443512",label:"Berenjena   rayas larga congelada"},
    {prodId:"50443513",label:"Berenjena  mercado de dinero congelada"},
    {prodId:"50443514",label:"Berenjena  ova congelada"},
    {prodId:"50443515",label:"Berenjena   arveja congelada"},
    {prodId:"50443516",label:"Berenjena  tom bajito congelada"},
    {prodId:"50443517",label:"Berenjena  siciliano congelada"},
    {prodId:"50443518",label:"Berenjena  tailandés congelada"},
    {prodId:"50443519",label:"Berenjena  violeta de florencia congelada"},
    {prodId:"50443520",label:"Berenjena  blanco congelada"},
    {prodId:"50443600",label:"Endivias congeladas"},
    {prodId:"50443601",label:"Endivias witloof de brucelas congeladas"},
    {prodId:"50443602",label:"Endivias francocastel congeladas"},
    {prodId:"50443603",label:"Endivias catalogna de galantina congeladas"},
    {prodId:"50443604",label:"Endivias chioggia congeladas"},
    {prodId:"50443605",label:"Endivias grumolo verde congeladas"},
    {prodId:"50443606",label:"Endivias raíz larga magdeburg congeladas"},
    {prodId:"50443607",label:"Endivias palla rosa zorzi precoce congeladas"},
    {prodId:"50443608",label:"Endivias radice amare congeladas"},
    {prodId:"50443609",label:"Endivias rosa de traviso congeladas"},
    {prodId:"50443610",label:"Endivias  rosa de verona congeladas"},
    {prodId:"50443611",label:"Endivias soncino congeladas"},
    {prodId:"50443612",label:"Endivias sugarthi congeladas"},
    {prodId:"50443613",label:"Endivias verona congeladas"},
    {prodId:"50443614",label:"Endivias witloof zoom congeladas"},
    {prodId:"50443700",label:"Hinojos congelados"},
    {prodId:"50443701",label:"Hinojo cantino congelado"},
    {prodId:"50443702",label:"Hinojo fino congelado"},
    {prodId:"50443703",label:"Hinojoherald congelado"},
    {prodId:"50443704",label:"Hinojo perfección congelado"},
    {prodId:"50443705",label:"Hinojo sirio congelado"},
    {prodId:"50443706",label:"Hinojo florencia dulce congelado"},
    {prodId:"50443707",label:"Hinojo tardo congelado"},
    {prodId:"50443800",label:"Ajos congelados"},
    {prodId:"50443801",label:"Ajo tarde california congelado"},
    {prodId:"50443802",label:"Ajo tallos chinos congelado"},
    {prodId:"50443803",label:"Ajo cebollin  congelado"},
    {prodId:"50443804",label:"Ajo germidor congelado"},
    {prodId:"50443805",label:"Ajo guardado largo congelado"},
    {prodId:"50443806",label:"Ajo ramson congelado"},
    {prodId:"50443807",label:"Ajo rocambola congelado"},
    {prodId:"50443808",label:"Ajo rosa de lautrec congelado"},
    {prodId:"50443809",label:"Ajo solent blanco congelado"},
    {prodId:"50443810",label:"Ajo morado español congelado"},
    {prodId:"50443811",label:"Ajo valentina italiana congelado"},
    {prodId:"50443900",label:"Calabazas congeladas"},
    {prodId:"50443901",label:"Petula congelada"},
    {prodId:"50443902",label:"Melon amargo congelado"},
    {prodId:"50443903",label:"Botella calabaza congelada"},
    {prodId:"50443904",label:"Calabash gounds congelada"},
    {prodId:"50443905",label:"Melón peludo congelado"},
    {prodId:"50443906",label:"Calabaza amarga congelada"},
    {prodId:"50443907",label:"Loofah suave congelado"},
    {prodId:"50443908",label:"Serpiente de calabaza congelada"},
    {prodId:"50443909",label:"Calabaza larga amarga congelada"},
    {prodId:"50443910",label:"Calabaza tinda congelada"},
    {prodId:"50443911",label:"Calabaza tindoori congelada"},
    {prodId:"50444000",label:"Guisantes congeladas"},
    {prodId:"50444001",label:"Guisante chino congelada"},
    {prodId:"50444002",label:"Guisante inglés congelada"},
    {prodId:"50444003",label:"Guisante de jardín congelada"},
    {prodId:"50444004",label:"Guisante nieve congelada"},
    {prodId:"50444005",label:"Guisante snap dulce congelada"},
    {prodId:"50444100",label:"Hierbas congeladas"},
    {prodId:"50444101",label:"Albahaca congelada"},
    {prodId:"50444102",label:"Laurel congelado"},
    {prodId:"50444103",label:"Borraja congelada"},
    {prodId:"50444104",label:"Alcaravea congelada"},
    {prodId:"50444105",label:"Perifollo congelado"},
    {prodId:"50444106",label:"Cilantro congelado"},
    {prodId:"50444107",label:"Chipolinos congelado"},
    {prodId:"50444108",label:"Hoja de curry congelada"},
    {prodId:"50444109",label:"Eneldo congelado"},
    {prodId:"50444110",label:"Epazote congelado"},
    {prodId:"50444111",label:"Fenogreco congelado"},
    {prodId:"50444112",label:"Limonaria congelada"},
    {prodId:"50444113",label:"Mejorana congelada"},
    {prodId:"50444114",label:"Menta congelada"},
    {prodId:"50444115",label:"Orégano congelado"},
    {prodId:"50444116",label:"Pápalo congelado"},
    {prodId:"50444117",label:"Pápalo delgado congelado"},
    {prodId:"50444118",label:"Perilla congelada"},
    {prodId:"50444119",label:"Recao  congelado"},
    {prodId:"50444120",label:"Romero congelado"},
    {prodId:"50444121",label:"Salvia congelada"},
    {prodId:"50444122",label:"Salsifí congelada"},
    {prodId:"50444123",label:"Satureja congelada"},
    {prodId:"50444124",label:"Estragón congelado"},
    {prodId:"50444125",label:"Tomillo congelado"},
    {prodId:"50444126",label:"Tumeric congelado"},
    {prodId:"50444127",label:"Verdolaga congelada"},
    {prodId:"50444200",label:"Coles congeladas"},
    {prodId:"50444201",label:"Col rizada congelada"},
    {prodId:"50444202",label:"Repollo verde congelado"},
    {prodId:"50444300",label:"Colinabos congelados"},
    {prodId:"50444301",label:"Colinabo estrella azur congelado"},
    {prodId:"50444302",label:"Colinabo verde viena congelado"},
    {prodId:"50444303",label:"Colinabo lanro congelado"},
    {prodId:"50444304",label:"Colinabo viena morada congelado"},
    {prodId:"50444305",label:"Colinabo trero rowel congelado"},
    {prodId:"50444306",label:"Colinabo viena blanca congelado"},
    {prodId:"50444400",label:"Puerros congelados"},
    {prodId:"50444401",label:"Puerro cobra gigante otoño congelado"},
    {prodId:"50444402",label:"Puerro otoño mamut 2 congelado"},
    {prodId:"50444403",label:"Puerro blue de solaise congelado"},
    {prodId:"50444404",label:"Puerro cortina congelado"},
    {prodId:"50444405",label:"Puerro prelina congelado"},
    {prodId:"50444406",label:"Puerro salvaje congelado"},
    {prodId:"50444500",label:"Lentejas  congeladas"},
    {prodId:"50444501",label:"Lenteja beluga congelada"},
    {prodId:"50444502",label:"Lenteja  verde francesa congelada"},
    {prodId:"50444503",label:"Lenteja verde congelada"},
    {prodId:"50444504",label:"Lenteja crimson pequeña congelada"},
    {prodId:"50444505",label:"Lenteja pardina española congelada"},
    {prodId:"50444506",label:"Lenteja roja separada congelada"},
    {prodId:"50444507",label:"Lenteja amarilla separad congelada"},
    {prodId:"50444508",label:"Lenteja tarahumara rosada congelada"},
    {prodId:"50444600",label:"Lechugas congeladas"},
    {prodId:"50444601",label:"Lechuga  bibb congelada"},
    {prodId:"50444602",label:"Lechuga  boston congelada"},
    {prodId:"50444603",label:"Lechuga frisee congelada"},
    {prodId:"50444604",label:"Lechuga rosa lola congelada"},
    {prodId:"50444605",label:"Lechuga  mixta masculina congelada"},
    {prodId:"50444606",label:"Lechuga mizuna congelada"},
    {prodId:"50444607",label:"Lechuga hoja roja congelada"},
    {prodId:"50444608",label:"Lechuga oja roja roble congelada"},
    {prodId:"50444609",label:"Lechuga romana ruby congelada"},
    {prodId:"50444610",label:"Lechuga romana bebé roja congelada"},
    {prodId:"50444611",label:"Lechuga cabeza de mantequilla congelada"},
    {prodId:"50444612",label:"Lechuga china congelada"},
    {prodId:"50444613",label:"Lechuga cabeza tostada congelada"},
    {prodId:"50444614",label:"Lechuga hoja verde congelada"},
    {prodId:"50444615",label:"Lechuga iceberg congelada"},
    {prodId:"50444616",label:"Lechuga cordero congelada"},
    {prodId:"50444617",label:"Lechuga hoja suelta congelada"},
    {prodId:"50444618",label:"Lechuga mache congelada"},
    {prodId:"50444619",label:"Lechuga boston roja congelada"},
    {prodId:"50444620",label:"Lechuga cabeza roja congelada"},
    {prodId:"50444621",label:"Lechuga romana congelada"},
    {prodId:"50444622",label:"Lechuga mostza roja rusa congelada"},
    {prodId:"50444623",label:"Lechuga tatsoi congelada"},
    {prodId:"50444700",label:"Ñames congelados"},
    {prodId:"50444701",label:"Ñame amarilla congelada"},
    {prodId:"50444702",label:"Ñame blanca congelada"},
    {prodId:"50444703",label:"Ñame coco congelada"},
    {prodId:"50444704",label:"Ñame eddoes congelada"},
    {prodId:"50444705",label:"Ñame isleña congelada"},
    {prodId:"50444706",label:"Ñame lila congelada"},
    {prodId:"50444800",label:"Hongos o champiñones congelados"},
    {prodId:"50444801",label:"Champiñón trompeta negra congelado"},
    {prodId:"50444802",label:"Champiñón carmelito congelado"},
    {prodId:"50444803",label:"Champiñón  congelado"},
    {prodId:"50444804",label:"Champiñón chanterelle congelado"},
    {prodId:"50444805",label:"Champiñón cremini congelado"},
    {prodId:"50444806",label:"Champiñón enoki congelado"},
    {prodId:"50444807",label:"Champiñón puercoespín congelado"},
    {prodId:"50444808",label:"Champiñón gallina del bosque congelado"},
    {prodId:"50444809",label:"Champiñón langosta congelado"},
    {prodId:"50444810",label:"Champiñón morels congelado"},
    {prodId:"50444811",label:"Champiñón ostra congelado"},
    {prodId:"50444812",label:"Champiñón pleurotus congelado"},
    {prodId:"50444813",label:"Champiñón pompom congelado"},
    {prodId:"50444814",label:"Champiñón porcieni congelado"},
    {prodId:"50444815",label:"Champiñón portobello congelado"},
    {prodId:"50444816",label:"Champiñón shitake congelado"},
    {prodId:"50444817",label:"Champiñón shimeji congelado"},
    {prodId:"50444818",label:"Champiñón san jorge congelado"},
    {prodId:"50444819",label:"Champiñón blanco congelado"},
    {prodId:"50444820",label:"Champiñón tompeta blanca congelado"},
    {prodId:"50444821",label:"Champiñón woodear congelado"},
    {prodId:"50444900",label:"Mostazas congeladas"},
    {prodId:"50444901",label:"Mostaza bambú  congelada"},
    {prodId:"50444902",label:"Mostaza ajo congelada"},
    {prodId:"50444903",label:"Mostaza hoja gigante congelada"},
    {prodId:"50444904",label:"Mostaza rojo en nieve congelada"},
    {prodId:"50444905",label:"Mostaza sureño congelada"},
    {prodId:"50444906",label:"Mostaza corazón envuelto congelada"},
    {prodId:"50445000",label:"Solanáceas (nightshades) congeladas"},
    {prodId:"50445001",label:"Lámpara china congelada"},
    {prodId:"50445002",label:"Mora de jardín"},
    {prodId:"50445003",label:"Naranjilla congeladas"},
    {prodId:"50445004",label:"Tomatillo congelada"},
    {prodId:"50445100",label:"Okras congeladas"},
    {prodId:"50445101",label:"Okra artista congelada"},
    {prodId:"50445102",label:"Okra vinotinto congelada"},
    {prodId:"50445103",label:"Okra sin columna clemson congelada"},
    {prodId:"50445104",label:"Okra verde enano vaina larga congelada"},
    {prodId:"50445105",label:"Okra  mamut sin columna congelada"},
    {prodId:"50445106",label:"Okra terciopelo rojo congelada"},
    {prodId:"50445107",label:"Okra estrella de david reliquia congelada"},
    {prodId:"50445300",label:"Maníes congelados"},
    {prodId:"50445301",label:"Maní bambara congelado"},
    {prodId:"50445302",label:"Maní florunner congelado"},
    {prodId:"50445303",label:"Maní husa / kerstings congelado"},
    {prodId:"50445304",label:"Maní español congelado"},
    {prodId:"50445305",label:"Maní valencia congelado"},
    {prodId:"50445306",label:"Maní virginia congelado"},
    {prodId:"50445400",label:"Alverjas congeladas"},
    {prodId:"50445401",label:"Alverja casco morado congelada"},
    {prodId:"50445402",label:"Alverja pinkeye congelada"},
    {prodId:"50445403",label:"Alverja crowder congelada"},
    {prodId:"50445404",label:"Alverja acre blanco congelada"},
    {prodId:"50445405",label:"Alverja ojo negro congelada"},
    {prodId:"50445406",label:"Alverja crema zipper congelada"},
    {prodId:"50445700",label:"Nabos suecos congelados"},
    {prodId:"50445701",label:"Nabo sueco acme congelado"},
    {prodId:"50445702",label:"Nabo sueco angela congelado"},
    {prodId:"50445703",label:"Nabo sueco el mejor congelado"},
    {prodId:"50445704",label:"Nabo sueco marian congelado"},
    {prodId:"50445800",label:"Vegetales marinos congelados"},
    {prodId:"50445801",label:"Agar-agar congelado"},
    {prodId:"50445802",label:"Arame  congelada"},
    {prodId:"50445803",label:"Alga dulce congelada"},
    {prodId:"50445804",label:"Habichuelas vert de mer congelada"},
    {prodId:"50445805",label:"Hijiki congelada"},
    {prodId:"50445806",label:"Musgo irlandés congelada"},
    {prodId:"50445807",label:"Quelpo congelado"},
    {prodId:"50445808",label:"Laver congelado"},
    {prodId:"50445809",label:"Nori congelado"},
    {prodId:"50445810",label:"Alga roja congelada"},
    {prodId:"50445811",label:"Kale de mar congelada"},
    {prodId:"50445812",label:"Lechuga de mar congelada"},
    {prodId:"50445813",label:"Algas de mar congelada"},
    {prodId:"50445814",label:"Spirulina congelada"},
    {prodId:"50445815",label:"Susabi nuri congelada"},
    {prodId:"50445816",label:"Wakame congelada"},
    {prodId:"50445900",label:"Chalotes congelados"},
    {prodId:"50445901",label:"Chalote atlántica congelado"},
    {prodId:"50445902",label:"Chalote creación congelado"},
    {prodId:"50445903",label:"Chalote drittler nido blanco congelado"},
    {prodId:"50445904",label:"Chalote gigante mejorado amarillo congelado"},
    {prodId:"50445905",label:"Chalote gourmet dorado congelado"},
    {prodId:"50445906",label:"Chalote grise de bagnolet congelado"},
    {prodId:"50445907",label:"Chalote hative de niort congelado"},
    {prodId:"50445908",label:"Chalote pikant congelado"},
    {prodId:"50445909",label:"Chalote papa roja congelado"},
    {prodId:"50445910",label:"Chalote sante congelado"},
    {prodId:"50445911",label:"Chalote topper congelado"},
    {prodId:"50446000",label:"Alazanes congelados"},
    {prodId:"50446001",label:"Alazán dock congelada"},
    {prodId:"50446002",label:"Alazán jardín congelada"},
    {prodId:"50446003",label:"Alazán oveja congelada"},
    {prodId:"50446004",label:"Alazán madera congelada"},
    {prodId:"50446100",label:"Espinacas congeladas"},
    {prodId:"50446101",label:"Espinaca americana congelada"},
    {prodId:"50446102",label:"Espinaca bloomsdale congelada"},
    {prodId:"50446103",label:"Espinaca invierno gigante congelada"},
    {prodId:"50446104",label:"Espinaca horenso congelada"},
    {prodId:"50446105",label:"Espinaca planta de hielo congelada"},
    {prodId:"50446106",label:"Espinaca  cuarto de cordero congelada"},
    {prodId:"50446107",label:"Espinaca malabra congelada"},
    {prodId:"50446108",label:"Espinaca mendania congelada"},
    {prodId:"50446109",label:"Espinaca nueva zelandia congelada"},
    {prodId:"50446110",label:"Espinaca orach congelada"},
    {prodId:"50446111",label:"Espinaca savoy congelada"},
    {prodId:"50446112",label:"Espinaca hoja de sigma congelada"},
    {prodId:"50446113",label:"Espinaca espacio congelada"},
    {prodId:"50446114",label:"Espinaca trinidad congelada"},
    {prodId:"50446115",label:"Espinaca agua congelada"},
    {prodId:"50446116",label:"Espinaca de agua sesa"},
    {prodId:"50446300",label:"Batatas congeladas"},
    {prodId:"50446301",label:"Batata beauregard congelada"},
    {prodId:"50446302",label:"Batata centennial congelada"},
    {prodId:"50446303",label:"Batata diane congelada"},
    {prodId:"50446304",label:"Batata dulce garnet congelada"},
    {prodId:"50446305",label:"Batata georgia dulce rojo congelada"},
    {prodId:"50446306",label:"Batata  dorado congelada"},
    {prodId:"50446307",label:"Batata hanna  congelada"},
    {prodId:"50446308",label:"Batata japonés congelada"},
    {prodId:"50446309",label:"Batata jersey congelada"},
    {prodId:"50446310",label:"Batata joya congelada"},
    {prodId:"50446311",label:"Batata meryland roja congelada"},
    {prodId:"50446312",label:"Batata nema dorado congelada"},
    {prodId:"50446313",label:"Batata o'henry congelada"},
    {prodId:"50446314",label:"Batata okinawan congelada"},
    {prodId:"50446315",label:"Batata naranja congelada"},
    {prodId:"50446316",label:"Batata oriental congelada"},
    {prodId:"50446317",label:"Batata jersey rojo congelada"},
    {prodId:"50446318",label:"Batata mar rojo congelada"},
    {prodId:"50446319",label:"Batata brillo rojo congelada"},
    {prodId:"50446320",label:"Batata jersey amarillo congelada"},
    {prodId:"50446321",label:"Batata morada congelada"},
    {prodId:"50446400",label:"Tomates congelados"},
    {prodId:"50446401",label:"Tomates ailsa craig congelado"},
    {prodId:"50446402",label:"Tomates alicante congelado"},
    {prodId:"50446403",label:"Tomates ciruela negra congelado"},
    {prodId:"50446404",label:"Tomates vino de brandy congelado"},
    {prodId:"50446405",label:"Tomates bella cereza congelado"},
    {prodId:"50446406",label:"Tomates cereza congelado"},
    {prodId:"50446407",label:"Tomates delicioso congelado"},
    {prodId:"50446408",label:"Tomates dombito congelado"},
    {prodId:"50446409",label:"Tomates delicia del jardín congelado"},
    {prodId:"50446410",label:"Tomates uva congelado"},
    {prodId:"50446411",label:"Tomates verde congelado"},
    {prodId:"50446412",label:"Tomates super marmande congelado"},
    {prodId:"50446413",label:"Tomates rayas naturales marvel congelado"},
    {prodId:"50446414",label:"Tomates minibel congelado"},
    {prodId:"50446415",label:"Tomates oaxacan congelado"},
    {prodId:"50446416",label:"Tomates alerta roja congelado"},
    {prodId:"50446417",label:"Tomates roma vf congelado"},
    {prodId:"50446418",label:"Tomates marzano zan congelado"},
    {prodId:"50446419",label:"Tomates shirley congelado"},
    {prodId:"50446420",label:"Tomates siberia congelado"},
    {prodId:"50446421",label:"Tomates super beefsteak congelado"},
    {prodId:"50446422",label:"Tomates tigerella congelado"},
    {prodId:"50446423",label:"Tomates tiny tim congelado"},
    {prodId:"50446424",label:"Tomates tumbler congelado"},
    {prodId:"50446425",label:"Tomates cocktail amarillo congelado"},
    {prodId:"50446426",label:"Tomates forma de pera amarillo congelado"},
    {prodId:"50446427",label:"Tomates perfección amarilla congelado"},
    {prodId:"50446500",label:"Nabos congelados"},
    {prodId:"50446501",label:"Nabo globo verde congelado"},
    {prodId:"50446502",label:"Nabo bola dorada congelado"},
    {prodId:"50446503",label:"Nabo mercado de manchester congelado"},
    {prodId:"50446504",label:"Nabo top morado milan congelado"},
    {prodId:"50446505",label:"Nabo top morado blanco congelado"},
    {prodId:"50446506",label:"Nabo bola de nieve congelado"},
    {prodId:"50446507",label:"Nabo tokio congelado"},
    {prodId:"50446508",label:"Nabo cruz de tokio congelado"},
    {prodId:"50446600",label:"Auyamas congeladas"},
    {prodId:"50446601",label:"Calabaza acorn congelada"},
    {prodId:"50446602",label:"Calabaza gigante atlántico congelada"},
    {prodId:"50446603",label:"Calabaza banana rosado congelada"},
    {prodId:"50446604",label:"Calabaza max grande congelada"},
    {prodId:"50446605",label:"Calabaza  congelada"},
    {prodId:"50446606",label:"Calabaza carnaval congelada"},
    {prodId:"50446607",label:"Auyama queso congelada"},
    {prodId:"50446608",label:"Calabaza príncipe coronado congelada"},
    {prodId:"50446609",label:"Calabaza  curcibita congelada"},
    {prodId:"50446610",label:"Calabaza cushaw congelada"},
    {prodId:"50446611",label:"Calabaza  gigante auyama congelada"},
    {prodId:"50446612",label:"Calabaza  hubbard congelada"},
    {prodId:"50446613",label:"Calabaza  jerrahdale congelada"},
    {prodId:"50446614",label:"Calabaza  kabocha congelada"},
    {prodId:"50446615",label:"Calabaza queensland azul congelada"},
    {prodId:"50446616",label:"Calabaza rouge vif détampes congelada"},
    {prodId:"50446617",label:"Calabaza  turban turco congelada"},
    {prodId:"50446618",label:"Calabaza  valenciano congelada"},
    {prodId:"50446619",label:"Calabaza  warted hubbard congelada"},
    {prodId:"50446620",label:"Auyama whangaparoa congelada"},
    {prodId:"50446700",label:"Ñames congelados"},
    {prodId:"50446701",label:"Ñame agria africana congelada"},
    {prodId:"50446702",label:"Ñame agria asiática congelada"},
    {prodId:"50446703",label:"Ñame china congelado"},
    {prodId:"50446704",label:"Ñame globo congelado"},
    {prodId:"50446705",label:"Ñame grater congelado"},
    {prodId:"50446706",label:"Ñame japonesa congelado"},
    {prodId:"50446707",label:"Ñame lesser congelado"},
    {prodId:"50446708",label:"Ñame papa congelado"},
    {prodId:"50446709",label:"Ñame guinea blanca congelado"},
    {prodId:"50446710",label:"Ñame guinea amarillo congelado"},
    {prodId:"50446900",label:"Alverjas dulces congeladas"},
    {prodId:"50446901",label:"Alverja biquini congelada"},
    {prodId:"50446902",label:"Alverja cavalier congelada"},
    {prodId:"50446903",label:"Alverja margarita congelada"},
    {prodId:"50446904",label:"Alverja dar fon congelada"},
    {prodId:"50446905",label:"Alverja early onward congelada"},
    {prodId:"50446906",label:"Alverja primer feltham congelada"},
    {prodId:"50446907",label:"Alverja verde hurst shaft congelada"},
    {prodId:"50446908",label:"Guisante oregón sugar pod congelada"},
    {prodId:"50446909",label:"Alverja príncipe alberto congelada"},
    {prodId:"50446910",label:"Alverja reuzensuiker congelada"},
    {prodId:"50451500",label:"Alcachofas orgánicas congeladas"},
    {prodId:"50451501",label:"Alcachofa brittany orgánica congelada"},
    {prodId:"50451502",label:"Alcachofa cantonesa orgánica congelada"},
    {prodId:"50451503",label:"Alcachofa francesa orgánica congelada"},
    {prodId:"50451504",label:"Alcachofa globo verde orgánica congelada"},
    {prodId:"50451505",label:"Alcachofa gros camus de bretaña orgánica congelada"},
    {prodId:"50451506",label:"Alcachofa midi orgánica congelada"},
    {prodId:"50451507",label:"Alcachofa globo morado orgánica congelada"},
    {prodId:"50451508",label:"Alcachofa morado cecilia orgánica congelada"},
    {prodId:"50451509",label:"Alcachofa romanesco orgánica congelada"},
    {prodId:"50451510",label:"Alcachofa espinoso sardo orgánica congelada"},
    {prodId:"50451511",label:"Alcachofa vert de laon orgánica congelada"},
    {prodId:"50451512",label:"Alcachofa violeta de chiggiia orgánica congelada"},
    {prodId:"50451513",label:"Alcachofa violeta de toscana orgánica congelada"},
    {prodId:"50451600",label:"Espárragos orgánicos congelados"},
    {prodId:"50451601",label:"Espárragos connovers colosal orgánicos congelados"},
    {prodId:"50451602",label:"Espárragos franklin orgánicos congelados"},
    {prodId:"50451603",label:"Espárragos mamut gigante orgánicos congelados"},
    {prodId:"50451604",label:"Espárragos lucullus orgánicos congelados"},
    {prodId:"50451605",label:"Espárragos martha washington orgánicos congelados"},
    {prodId:"50451800",label:"Fríjoles orgánicos congelados"},
    {prodId:"50451801",label:"Frijoles anasazi ® orgánicos congelados"},
    {prodId:"50451802",label:"Frijoles appaloosa orgánicos congelados"},
    {prodId:"50451803",label:"Frijoles azuki orgánicos congelados"},
    {prodId:"50451804",label:"Frijoles barlotti orgánicos congelados"},
    {prodId:"50451805",label:"Frijoles appaloosa negra orgánicos congelados"},
    {prodId:"50451806",label:"Frijoles negros orgánicos congelados"},
    {prodId:"50451807",label:"Frijoles gran negros orgánicos congelados"},
    {prodId:"50451808",label:"Frijoles shackamaxon negro orgánicos congelados"},
    {prodId:"50451809",label:"Frijoles ojo negro orgánicos congelados"},
    {prodId:"50451810",label:"Frijoles bobby orgánicos congelados"},
    {prodId:"50451811",label:"Frijoles bolita orgánicos congelados"},
    {prodId:"50451812",label:"Frijoles esposa perezosa carmelita orgánicos congelados"},
    {prodId:"50451813",label:"Frijoles calipso orgánicos congelados"},
    {prodId:"50451814",label:"Frijoles canelini orgánicos congelados"},
    {prodId:"50451815",label:"Frijoles castor orgánicos congelados"},
    {prodId:"50451816",label:"Frijoles amarillo chino orgánicos congelados"},
    {prodId:"50451817",label:"Frijoles lengua de dragón orgánicos congelados"},
    {prodId:"50451818",label:"Frijoles soldado europeo orgánicos congelados"},
    {prodId:"50451819",label:"Frijoles fava orgánicos congelados"},
    {prodId:"50451820",label:"Frijoles flageolet orgánicos congelados"},
    {prodId:"50451821",label:"Frijoles  horticultura francesa orgánicos congelados"},
    {prodId:"50451822",label:"Frijoles marina francesa orgánicos congelados"},
    {prodId:"50451823",label:"Frijoles coco gigante blanco orgánicos congelados"},
    {prodId:"50451824",label:"Frijoles verdes orgánicos congelados"},
    {prodId:"50451825",label:"Frijoles romano verde orgánicos congelados"},
    {prodId:"50451826",label:"Frijoles guar gum orgánicos congelados"},
    {prodId:"50451827",label:"Frijoles haricot orgánicos congelados"},
    {prodId:"50451828",label:"Frijoles hyacinth orgánicos congelados"},
    {prodId:"50451829",label:"Frijoles tipo italiano orgánicos congelados"},
    {prodId:"50451830",label:"Frijoles asombro jackson orgánicos congelados"},
    {prodId:"50451831",label:"Frijoles ganado jacobs  orgánicos congelados"},
    {prodId:"50451832",label:"Frijoles asombro kentucky orgánicos congelados"},
    {prodId:"50451833",label:"Frijoles riñón orgánicos congelados"},
    {prodId:"50451834",label:"Frijoles lima orgánicos congelados"},
    {prodId:"50451835",label:"Frijoles madera orgánicos congelados"},
    {prodId:"50451836",label:"Frijoles medula orgánicos congelados"},
    {prodId:"50451837",label:"Frijoles mat orgánicos congelados"},
    {prodId:"50451838",label:"Frijoles monstoler ganzo salvaje orgánicos congelados"},
    {prodId:"50451839",label:"Frijoles  mortgage lifter orgánicos congelados"},
    {prodId:"50451840",label:"Frijoles polilla orgánicos congelados"},
    {prodId:"50451841",label:"Frijoles mung orgánicos congelados"},
    {prodId:"50451842",label:"Frijoles munsi wolf orgánicos congelados"},
    {prodId:"50451843",label:"Frijoles nuna orgánicos congelados"},
    {prodId:"50451844",label:"Frijoles pinto orgánicos congelados"},
    {prodId:"50451845",label:"Frijoles runner orgánicos congelados"},
    {prodId:"50451846",label:"String beans orgánicos congelados"},
    {prodId:"50451847",label:"Frijoles habichuelas orgánicos congelados"},
    {prodId:"50451848",label:"Frijoles tamarind orgánicos congelados"},
    {prodId:"50451849",label:"Frijoles tonka orgánicos congelados"},
    {prodId:"50451850",label:"Frijoles cera orgánicos congelados"},
    {prodId:"50451851",label:"Frijoles winged orgánicos congelados"},
    {prodId:"50451852",label:"Frijoles largos orgánicos congelados"},
    {prodId:"50451900",label:"Remolachas  orgánicas congeladas"},
    {prodId:"50451901",label:"Remolacha acción orgánica congelada"},
    {prodId:"50451902",label:"Remolacha albina vereduna orgánica congelada"},
    {prodId:"50451903",label:"Remolacha barababiotela de chiggia orgánica congelada"},
    {prodId:"50451904",label:"Remolacha boltardy orgánica congelada"},
    {prodId:"50451905",label:"Remolacha bonel orgánica congelada"},
    {prodId:"50451906",label:"Remolacha burpees dorado orgánica congelada"},
    {prodId:"50451907",label:"Remolacha cheltenham tapa verde orgánica congelada"},
    {prodId:"50451908",label:"Remolacha cheltenham mono orgánica congelada"},
    {prodId:"50451909",label:"Remolacha chioggia orgánica congelada"},
    {prodId:"50451910",label:"Remolacha cilindra orgánica congelada"},
    {prodId:"50451911",label:"Remolacha dégypte orgánica congelada"},
    {prodId:"50451912",label:"Remolacha detroit 2 rojo oscuro orgánica congelada"},
    {prodId:"50451913",label:"Remolacha detroit 2 bala chiquita orgánica congelada"},
    {prodId:"50451914",label:"Remolacha egipcia plana  orgánica congelada"},
    {prodId:"50451915",label:"Remolacha  raíz de nabo egipcio orgánica congelada"},
    {prodId:"50451916",label:"Remolacha fomanova orgánica congelada"},
    {prodId:"50451917",label:"Remolacha forono orgánica congelada"},
    {prodId:"50451918",label:"Remolacha monaco orgánica congelada"},
    {prodId:"50451919",label:"Remolacha monograma orgánica congelada"},
    {prodId:"50451920",label:"Remolacha pronto orgánica congelada"},
    {prodId:"50451921",label:"Remolacha regalía orgánica congelada"},
    {prodId:"50451922",label:"Remolacha dulce orgánica congelada"},
    {prodId:"50452000",label:"Brócolis  orgánicas congeladas"},
    {prodId:"50452001",label:"Brócolini orgánico congelado"},
    {prodId:"50452002",label:"Brócoli romanesco orgánico congelado"},
    {prodId:"50452003",label:"Brócoli raab orgánico congelado"},
    {prodId:"50452004",label:"Brócoli chino orgánico congelado"},
    {prodId:"50452100",label:"Repollitos de Bruselas  orgánicas congeladas"},
    {prodId:"50452101",label:"Repollitas de bruselas citade orgánica orgánicas congeladas"},
    {prodId:"50452102",label:"Repollitas de bruselas falstaff orgánica orgánicas congeladas"},
    {prodId:"50452103",label:"Repollitas de bruselas oliver orgánica orgánicas congeladas"},
    {prodId:"50452104",label:"Repollitas de bruselas peer gynt orgánica orgánicas congeladas"},
    {prodId:"50452105",label:"Repollitas de bruselas rampart orgánica orgánicas congeladas"},
    {prodId:"50452106",label:"Repollitas de bruselas rubine orgánica orgánicas congeladas"},
    {prodId:"50452107",label:"Repollitas de bruselas  widgeon orgánica orgánicas congeladas"},
    {prodId:"50452200",label:"Cebollas de ensalada  orgánicas congeladas"},
    {prodId:"50452201",label:"Cebolla belstville ensalada orgánica congelada"},
    {prodId:"50452202",label:"Cebolla feast ensalada orgánica congelada"},
    {prodId:"50452203",label:"Cebolla ishikura ensalada orgánica congelada"},
    {prodId:"50452204",label:"Cebolla mercado kyoto orgánica congelada"},
    {prodId:"50452205",label:"Cebolla barba roja ensalada orgánica congelada"},
    {prodId:"50452206",label:"Cebolla amigo rojo ensalada orgánica congelada"},
    {prodId:"50452207",label:"Cebolla santa claus orgánica congelada"},
    {prodId:"50452208",label:"Cebolla tokyo ensalada orgánica congelada"},
    {prodId:"50452209",label:"Cebolla lisbon blanca ensalada orgánica congelada"},
    {prodId:"50452210",label:"Cebolla invierno blanca ensalada orgánica congelada"},
    {prodId:"50452211",label:"Cebolla final de invierno ensalada orgánica congelada"},
    {prodId:"50452300",label:"Repollos orgánicos congelados"},
    {prodId:"50452301",label:"Repollo negro orgánico congelado"},
    {prodId:"50452302",label:"Repollo savoy orgánico congelado"},
    {prodId:"50452303",label:"Repollo zorrillo orgánico congelado"},
    {prodId:"50452304",label:"Repollo blanco orgánico congelado"},
    {prodId:"50452400",label:"Cardos orgánicos congelados"},
    {prodId:"50452401",label:"Cardos lunghi orgánicos congelados"},
    {prodId:"50452402",label:"Cardos gobbi orgánicos congelados"},
    {prodId:"50452600",label:"Coliflores  orgánicas congeladas"},
    {prodId:"50452601",label:"Coliflor todo el año  orgánica congelada"},
    {prodId:"50452602",label:"Coliflor alverda orgánica congelada"},
    {prodId:"50452603",label:"coliflor otoño gigante 3 orgánica congelada"},
    {prodId:"50452604",label:"Coliflo rdok elgon orgánica congelada"},
    {prodId:"50452605",label:"Coliflor bola de nieve temprana orgánica congelada"},
    {prodId:"50452606",label:"Coliflor luz de lima orgánica congelada"},
    {prodId:"50452607",label:"Coliflor minaret orgánica congelada"},
    {prodId:"50452608",label:"Coliflor buque de naranja orgánica congelada"},
    {prodId:"50452609",label:"Coliflor capa morada orgánica congelada"},
    {prodId:"50452610",label:"Coliflor bola de nieve orgánica congelada"},
    {prodId:"50452611",label:"Coliflor invierno walcheren 3 orgánica congelada"},
    {prodId:"50452612",label:"Coliflor piedra blanca orgánica congelada"},
    {prodId:"50452700",label:"Apios orgánicos congelados"},
    {prodId:"50452701",label:"Apio celebridad orgánico congelado"},
    {prodId:"50452702",label:"Apio nabo orgánico congelado"},
    {prodId:"50452703",label:"Apio chino orgánico congelado"},
    {prodId:"50452704",label:"Apio dinant francés orgánico congelado"},
    {prodId:"50452705",label:"Apio gigante rosado orgánico congelado"},
    {prodId:"50452706",label:"Apio gigante rojo orgánico congelado"},
    {prodId:"50452707",label:"Apio gigante blanco orgánico congelado"},
    {prodId:"50452708",label:"Apio dorado auto escalado orgánico congelado"},
    {prodId:"50452709",label:"Apio greensleeves orgánico congelado"},
    {prodId:"50452710",label:"Apio hopkins fenlander orgánico congelado"},
    {prodId:"50452711",label:"Apio torre marfil orgánico congelado"},
    {prodId:"50452712",label:"Apio lathom blanqueado orgánico congelado"},
    {prodId:"50452713",label:"Apio sopa de amsterdam orgánico congelado"},
    {prodId:"50452714",label:"Apio estándar cargado orgánico congelado"},
    {prodId:"50452715",label:"Apio triunfo alto utah orgánico congelado"},
    {prodId:"50452800",label:"Acelgas  orgánicas congeladas"},
    {prodId:"50452801",label:"Acelga luz brillante orgánica congelada"},
    {prodId:"50452802",label:"Acelga gigante fordhook orgánica congelada"},
    {prodId:"50452803",label:"Acelga luculus orgánica congelada"},
    {prodId:"50452804",label:"Acelga espinaca perpetua orgánica congelada"},
    {prodId:"50452805",label:"Acelga ruibarbo orgánica congelada"},
    {prodId:"50452806",label:"Acelga suizo orgánica congelada"},
    {prodId:"50452807",label:"Acelga volcán  orgánica congelada"},
    {prodId:"50452808",label:"Acelga rey blanco orgánica congelada"},
    {prodId:"50452900",label:"Achicorias orgánicas congeladas"},
    {prodId:"50452901",label:"Achicoria batavian hoja ancha orgánica congelada"},
    {prodId:"50452902",label:"Achicoria en cornet de bordeaux orgánica congelada"},
    {prodId:"50452903",label:"Achicoria rollo verde ruffee orgánica congelada"},
    {prodId:"50452904",label:"Achicoria roll verde orgánica congelada"},
    {prodId:"50452905",label:"Achicoria limnoe lone orgánica congelada"},
    {prodId:"50452906",label:"Achicoria pancalieri riccia orgánica congelada"},
    {prodId:"50452907",label:"Achicoria ensalada rey orgánica congelada"},
    {prodId:"50452908",label:"Achicoria sanda orgánica congelada"},
    {prodId:"50452909",label:"Achicoria scarola verde orgánica congelada"},
    {prodId:"50452910",label:"Achicoria tres fine maraichere orgánica congelada"},
    {prodId:"50452911",label:"Achicoria wallone freisee wescelkkopf orgánica congelada"},
    {prodId:"50453000",label:"Repollos chinos orgánicos congelados"},
    {prodId:"50453001",label:"Bok choy orgánico congelado"},
    {prodId:"50453002",label:"Bok choy enano orgánico congelado"},
    {prodId:"50453003",label:"Flor de repollo chino orgánica congelada"},
    {prodId:"50453004",label:"Choy sum orgánico congelado"},
    {prodId:"50453005",label:"Col (bok choy) enano orgánico congelado"},
    {prodId:"50453006",label:"Col (bok choy) fengshan orgánico congelado"},
    {prodId:"50453007",label:"Col (bok choy) jade pagoda orgánico congelado"},
    {prodId:"50453008",label:"Col (bok choy) kasumi orgánico congelado"},
    {prodId:"50453009",label:"col (bok choy)  nerva orgánico congelado"},
    {prodId:"50453010",label:"Col (bok choy) rosette orgánico congelado"},
    {prodId:"50453011",label:"Col (bok choy) ruffles orgánico congelado"},
    {prodId:"50453012",label:"Col (bok choy) hoja  santo serrated orgánico congelado"},
    {prodId:"50453013",label:"Col (bok choy) shangahai orgánico congelado"},
    {prodId:"50453014",label:"Shantung orgánico congelado"},
    {prodId:"50453015",label:"Repollo tip top orgánico congelado"},
    {prodId:"50453016",label:"Yau choy sum orgánico congelado"},
    {prodId:"50453100",label:"Cebollines orgánicos congelados"},
    {prodId:"50453101",label:"Cebollín chino  orgánico congelado"},
    {prodId:"50453200",label:"Maíces orgánicos congelados"},
    {prodId:"50453201",label:"Maíz aloha orgánico congelado"},
    {prodId:"50453202",label:"Maíz alpine orgánico congelado"},
    {prodId:"50453203",label:"Maíz ambrosia orgánico congelado"},
    {prodId:"50453204",label:"Maíz argent orgánico congelado"},
    {prodId:"50453205",label:"Maíz aspen orgánico congelado"},
    {prodId:"50453206",label:"Maíz avalancha orgánico congelado"},
    {prodId:"50453207",label:"Maíz biqueen orgánico congelado"},
    {prodId:"50453208",label:"Maíz  bodacious orgánico congelado"},
    {prodId:"50453209",label:"Maíz  mantequilla y azúcar orgánico congelado"},
    {prodId:"50453210",label:"Maíz  calico belle orgánico congelado"},
    {prodId:"50453211",label:"Maíz  camelot orgánico congelado"},
    {prodId:"50453212",label:"Maíz  challengercrisp ‘n dulce orgánico congelado"},
    {prodId:"50453213",label:"Maíz  campeón orgánico congelado"},
    {prodId:"50453214",label:"Maíz  algodón de azúcar orgánico congelado"},
    {prodId:"50453215",label:"Maíz  dártagnan orgánico congelado"},
    {prodId:"50453216",label:"Maíz  dazzle orgánico congelado"},
    {prodId:"50453217",label:"Maíz  diamante y oro orgánico congelado"},
    {prodId:"50453218",label:"Maíz  divinidad orgánico congelado"},
    {prodId:"50453219",label:"Maíz  delicia doble orgánico congelado"},
    {prodId:"50453220",label:"Maíz  gema doble orgánico congelado"},
    {prodId:"50453221",label:"Maíz  earlivee orgánico congelado"},
    {prodId:"50453222",label:"Maíz  temprano extra dulce orgánico congelado"},
    {prodId:"50453223",label:"Maíz excel orgánico congelado"},
    {prodId:"50453224",label:"Maíz  cruz dorada bantam orgánico congelado"},
    {prodId:"50453225",label:"Maíz  miel y crema orgánico congelado"},
    {prodId:"50453226",label:"Maíz  miel y perla orgánico congelado"},
    {prodId:"50453227",label:"Maíz  miel dulce orgánico congelado"},
    {prodId:"50453228",label:"Maíz  hudson orgánico congelado"},
    {prodId:"50453229",label:"Maíz  dorado illini orgánico congelado"},
    {prodId:"50453230",label:"Maíz  illini extra dulce orgánico congelado"},
    {prodId:"50453231",label:"Maíz  increíble orgánico congelado"},
    {prodId:"50453232",label:"Maíz  lochief orgánico congelado"},
    {prodId:"50453233",label:"Maíz jubileo orgánico congelado"},
    {prodId:"50453234",label:"Maíz jupbileo super dulce orgánico congelado"},
    {prodId:"50453235",label:"Maíz  confite korn orgánico congelado"},
    {prodId:"50453236",label:"Maíz  beso y acuso orgánico congelado"},
    {prodId:"50453237",label:"Maíz  lancelot orgánico congelado"},
    {prodId:"50453238",label:"Maíz dulce maple orgánico congelado"},
    {prodId:"50453239",label:"Maíz  medley orgánico congelado"},
    {prodId:"50453240",label:"Maíz merlin orgánico congelado"},
    {prodId:"50453241",label:"Maíz milagro orgánico congelado"},
    {prodId:"50453242",label:"Maíz nk - 199 orgánico congelado"},
    {prodId:"50453243",label:"Maíz durazno y crema orgánico congelado"},
    {prodId:"50453244",label:"Maíz blanco perla orgánico congelado"},
    {prodId:"50453245",label:"Maíz  pegasus orgánico congelado"},
    {prodId:"50453246",label:"Maíz  fenomenal orgánico congelado"},
    {prodId:"50453247",label:"Maíz  dama de platino orgánico congelado"},
    {prodId:"50453248",label:"Maíz  precioso orgánico congelado"},
    {prodId:"50453249",label:"Maíz   pristine orgánico congelado"},
    {prodId:"50453250",label:"Maíz  rápido orgánico congelado"},
    {prodId:"50453251",label:"Maíz  radiante orgánico congelado"},
    {prodId:"50453252",label:"Maíz  seneca brave orgánico congelado"},
    {prodId:"50453253",label:"Maíz  amanecer seneca orgánico congelado"},
    {prodId:"50453254",label:"Maíz  horizonte seneca orgánico congelado"},
    {prodId:"50453255",label:"Maíz  brillo de estrella seneca orgánico congelado"},
    {prodId:"50453256",label:"Maíz  noche blanca seneca orgánico congelado"},
    {prodId:"50453257",label:"Maíz  showcase orgánico congelado"},
    {prodId:"50453258",label:"Maíz  reina plateada orgánico congelado"},
    {prodId:"50453259",label:"Maíz  bella nieve orgánico congelado"},
    {prodId:"50453260",label:"Maíz nieve de primavera orgánico congelado"},
    {prodId:"50453261",label:"Maíz  premio de primavera orgánico congelado"},
    {prodId:"50453262",label:"Maíz  azúcar y oro orgánico congelado"},
    {prodId:"50453263",label:"Maíz rollo de azúcar orgánico congelado"},
    {prodId:"50453264",label:"Maíz nieve de azúcar orgánico congelado"},
    {prodId:"50453265",label:"Maíz baile de sol orgánico congelado"},
    {prodId:"50453266",label:"Maíz estrella tell orgánico congelado"},
    {prodId:"50453267",label:"Maíz terminador orgánico congelado"},
    {prodId:"50453268",label:"Maíz tesoro orgánico congelado"},
    {prodId:"50453269",label:"Maíz tuxedo orgánico congelado"},
    {prodId:"50453300",label:"Berros  orgánicos congelados"},
    {prodId:"50453301",label:"Berros de tierra orgánicos congelados"},
    {prodId:"50453302",label:"Nasturtium orgánico congelado"},
    {prodId:"50453303",label:"Berros de agua orgánicos congelados"},
    {prodId:"50453304",label:"Berros de invierno orgánicos congelados"},
    {prodId:"50453400",label:"Cocombros orgánicos congelados"},
    {prodId:"50453401",label:"Cocombro arena orgánico congelado"},
    {prodId:"50453402",label:"Cocombro americano orgánico congelado"},
    {prodId:"50453403",label:"Cocombro atena orgánico congelado"},
    {prodId:"50453404",label:"Cocombro blanco lungo de parigi orgánico congelado"},
    {prodId:"50453405",label:"Cocombro verde burpless buen sabor orgánico congelado"},
    {prodId:"50453406",label:"Cocombro chicago pickling orgánico congelado"},
    {prodId:"50453407",label:"Cocombro manzana cristal orgánico congelado"},
    {prodId:"50453408",label:"Cocombro limón cristal orgánico congelado"},
    {prodId:"50453409",label:"Cocombro danimas orgánico congelado"},
    {prodId:"50453410",label:"Cocombro gherkin orgánico congelado"},
    {prodId:"50453411",label:"Cocombro hokus orgánico congelado"},
    {prodId:"50453412",label:"Cocombro japonés orgánico congelado"},
    {prodId:"50453413",label:"Cocombro karela orgánico congelado"},
    {prodId:"50453414",label:"Cocombro korila orgánico congelado"},
    {prodId:"50453415",label:"Cocombro verde largo mejorado orgánico congelado"},
    {prodId:"50453416",label:"Cocombro mas mercado orgánico congelado"},
    {prodId:"50453417",label:"Cocombro enano orgánico congelado"},
    {prodId:"50453418",label:"Cocombro pickling nacional orgánico congelado"},
    {prodId:"50453419",label:"Cocombro persa orgánico congelado"},
    {prodId:"50453420",label:"Cocombro telégrafo orgánico congelado"},
    {prodId:"50453421",label:"Cocombro telégrafo mejorado orgánico congelado"},
    {prodId:"50453422",label:"Cocombro vert de massy cornichon orgánico congelado"},
    {prodId:"50453423",label:"Cocombro yamato orgánico congelado"},
    {prodId:"50453500",label:"Berenjenas orgánicas congeladas"},
    {prodId:"50453501",label:"Berenjena bambino orgánica congelada"},
    {prodId:"50453502",label:"Berenjena  belleza negra orgánica congelada"},
    {prodId:"50453503",label:"Berenjena  negra enorme orgánica congelada"},
    {prodId:"50453504",label:"Berenjena  chino orgánica congelada"},
    {prodId:"50453505",label:"Berenjena  huevo de pascua orgánica congelada"},
    {prodId:"50453506",label:"Berenjena  filipino orgánica congelada"},
    {prodId:"50453507",label:"Berenjena   mercado de florida orgánica congelada"},
    {prodId:"50453508",label:"Berenjena  india orgánica congelada"},
    {prodId:"50453509",label:"Berenjena  italiano orgánica congelada"},
    {prodId:"50453510",label:"Berenjena  japonés orgánica congelada"},
    {prodId:"50453511",label:"Berenjena  morado largo orgánica congelada"},
    {prodId:"50453512",label:"Berenjena   rayas larga orgánica congelada"},
    {prodId:"50453513",label:"Berenjena  mercado de dinero orgánica congelada"},
    {prodId:"50453514",label:"Berenjena  ova orgánica congelada"},
    {prodId:"50453515",label:"Berenjena   arveja orgánica congelada"},
    {prodId:"50453516",label:"Berenjena  tom bajito orgánica congelada"},
    {prodId:"50453517",label:"Berenjena  siciliano orgánica congelada"},
    {prodId:"50453518",label:"Berenjena  tailandés orgánica congelada"},
    {prodId:"50453519",label:"Berenjena  violeta de florencia orgánica congelada"},
    {prodId:"50453520",label:"Berenjena  blanco orgánica congelada"},
    {prodId:"50453600",label:"Endivias orgánicas congeladas"},
    {prodId:"50453601",label:"Endivias witloof de brucelas orgánica orgánicas congeladas"},
    {prodId:"50453602",label:"Endivias francocastel orgánica orgánicas congeladas"},
    {prodId:"50453603",label:"Endivias catalogna de galantina orgánica orgánicas congeladas"},
    {prodId:"50453604",label:"Endivias chioggia orgánica orgánicas congeladas"},
    {prodId:"50453605",label:"Endivias grumolo verde orgánica orgánicas congeladas"},
    {prodId:"50453606",label:"Endivias raíz larga magdeburg orgánica orgánicas congeladas"},
    {prodId:"50453607",label:"Endivias palla rosa zorzi precoce orgánica orgánicas congeladas"},
    {prodId:"50453608",label:"Endivias radice amare orgánica orgánicas congeladas"},
    {prodId:"50453609",label:"Endivias rosa de traviso orgánica orgánicas congeladas"},
    {prodId:"50453610",label:"Endivias  rosa de verona orgánica orgánicas congeladas"},
    {prodId:"50453611",label:"Endivias soncino orgánica orgánicas congeladas"},
    {prodId:"50453612",label:"Endivias sugarthi orgánica orgánicas congeladas"},
    {prodId:"50453613",label:"Endivias verona orgánica orgánicas congeladas"},
    {prodId:"50453614",label:"Endivias witloof zoom orgánica orgánicas congeladas"},
    {prodId:"50453700",label:"Hinojos orgánicos congelados"},
    {prodId:"50453701",label:"Hinojo cantino orgánico congelado"},
    {prodId:"50453702",label:"Hinojo fino orgánico congelado"},
    {prodId:"50453703",label:"Hinojoherald orgánico congelado"},
    {prodId:"50453704",label:"Hinojo perfección orgánico congelado"},
    {prodId:"50453705",label:"Hinojo sirio orgánico congelado"},
    {prodId:"50453706",label:"Hinojo florencia dulce orgánico congelado"},
    {prodId:"50453707",label:"Hinojo tardo orgánico congelado"},
    {prodId:"50453800",label:"Ajos orgánicos congelados"},
    {prodId:"50453801",label:"Ajo tarde california orgánico congelado"},
    {prodId:"50453802",label:"Ajo tallos chinos orgánico congelado"},
    {prodId:"50453803",label:"Ajo cebollin  orgánico congelado"},
    {prodId:"50453804",label:"Ajo germidor orgánico congelado"},
    {prodId:"50453805",label:"Ajo guardado largo orgánico congelado"},
    {prodId:"50453806",label:"Ajo ramson orgánico congelado"},
    {prodId:"50453807",label:"Ajo rocambola orgánico congelado"},
    {prodId:"50453808",label:"Ajo rosa de lautrec orgánico congelado"},
    {prodId:"50453809",label:"Ajo solent blanco orgánico congelado"},
    {prodId:"50453810",label:"Ajo morado español orgánico congelado"},
    {prodId:"50453811",label:"Ajo valentina italiana orgánico congelado"},
    {prodId:"50453900",label:"Calabazas orgánicas congeladas"},
    {prodId:"50453901",label:"Petula orgánica congelada"},
    {prodId:"50453902",label:"Melon amargo orgánico congelado"},
    {prodId:"50453903",label:"Botella calabaza orgánica congelada"},
    {prodId:"50453904",label:"Calabash gounds orgánica congelada"},
    {prodId:"50453905",label:"Melón peludo orgánico congelado"},
    {prodId:"50453906",label:"Calabaza amarga orgánica congelada"},
    {prodId:"50453907",label:"Loofah suave orgánico congelado"},
    {prodId:"50453908",label:"Serpiente de calabaza orgánica congelada"},
    {prodId:"50453909",label:"Calabaza larga amarga orgánica congelada"},
    {prodId:"50453910",label:"Calabaza tinda orgánica congelada"},
    {prodId:"50453911",label:"Calabaza tindoori orgánica congelada"},
    {prodId:"50454000",label:"Guisantes orgánicas congeladas"},
    {prodId:"50454001",label:"Guisante chino orgánica congelada"},
    {prodId:"50454002",label:"Guisante inglés orgánica congelada"},
    {prodId:"50454003",label:"Guisante de jardín orgánica congelada"},
    {prodId:"50454004",label:"Guisante nieve orgánica congelada"},
    {prodId:"50454005",label:"Guisante snap dulce orgánica congelada"},
    {prodId:"50454100",label:"Hierbas orgánicas congeladas"},
    {prodId:"50454101",label:"Albahaca orgánica congelada"},
    {prodId:"50454102",label:"Laurel orgánico congelado"},
    {prodId:"50454103",label:"Borraja orgánica congelada"},
    {prodId:"50454104",label:"Alcaravea orgánica congelada"},
    {prodId:"50454105",label:"Perifollo orgánico congelado"},
    {prodId:"50454106",label:"Cilantro orgánico congelado"},
    {prodId:"50454107",label:"Chipolinos orgánico congelado"},
    {prodId:"50454108",label:"Hoja de curry orgánica congelada"},
    {prodId:"50454109",label:"Eneldo orgánico congelado"},
    {prodId:"50454110",label:"Epazote orgánico congelado"},
    {prodId:"50454111",label:"Fenogreco orgánico congelado"},
    {prodId:"50454112",label:"Limonaria orgánica congelada"},
    {prodId:"50454113",label:"Mejorana orgánica congelada"},
    {prodId:"50454114",label:"Menta orgánica congelada"},
    {prodId:"50454115",label:"Orégano orgánico congelado"},
    {prodId:"50454116",label:"Pápalo orgánico congelado"},
    {prodId:"50454117",label:"Pápalo delgado orgánico congelado"},
    {prodId:"50454118",label:"Perilla orgánica congelada"},
    {prodId:"50454119",label:"Recao  orgánico congelado"},
    {prodId:"50454120",label:"Romero orgánico congelado"},
    {prodId:"50454121",label:"Salvia orgánica congelada"},
    {prodId:"50454122",label:"Salsifí orgánica congelada"},
    {prodId:"50454123",label:"Satureja orgánica congelada"},
    {prodId:"50454124",label:"Estragón orgánico congelado"},
    {prodId:"50454125",label:"Tomillo orgánico congelado"},
    {prodId:"50454126",label:"Tumeric orgánico congelado"},
    {prodId:"50454127",label:"Verdolaga orgánica congelada"},
    {prodId:"50454200",label:"Coles orgánicas congeladas"},
    {prodId:"50454201",label:"Col rizada orgánica congelada"},
    {prodId:"50454202",label:"Repollo verde orgánico congelado"},
    {prodId:"50454300",label:"Colinabos orgánicos congelados"},
    {prodId:"50454301",label:"Colinabo estrella azur orgánico congelado"},
    {prodId:"50454302",label:"Colinabo verde viena orgánico congelado"},
    {prodId:"50454303",label:"Colinabo lanro orgánico congelado"},
    {prodId:"50454304",label:"Colinabo viena morada orgánico congelado"},
    {prodId:"50454305",label:"Colinabo trero rowel orgánico congelado"},
    {prodId:"50454306",label:"Colinabo viena blanca orgánico congelado"},
    {prodId:"50454400",label:"Puerros orgánicos congelados"},
    {prodId:"50454401",label:"Puerro cobra gigante otoño orgánico congelado"},
    {prodId:"50454402",label:"Puerro otoño mamut 2 orgánico congelado"},
    {prodId:"50454403",label:"Puerro blue de solaise orgánico congelado"},
    {prodId:"50454404",label:"Puerro cortina orgánico congelado"},
    {prodId:"50454405",label:"Puerro prelina orgánico congelado"},
    {prodId:"50454406",label:"Puerro salvaje orgánico congelado"},
    {prodId:"50454500",label:"Lentejas  orgánicas congeladas"},
    {prodId:"50454501",label:"Lenteja beluga orgánica congelada"},
    {prodId:"50454502",label:"Lenteja  verde francesa orgánica congelada"},
    {prodId:"50454503",label:"Lenteja verde orgánica congelada"},
    {prodId:"50454504",label:"Lenteja crimson pequeña orgánica congelada"},
    {prodId:"50454505",label:"Lenteja pardina española orgánica congelada"},
    {prodId:"50454506",label:"Lenteja roja separada orgánica congelada"},
    {prodId:"50454507",label:"Lenteja amarilla separad orgánica congelada"},
    {prodId:"50454508",label:"Lenteja tarahumara rosada orgánica congelada"},
    {prodId:"50454600",label:"Lechugas orgánicas congeladas"},
    {prodId:"50454601",label:"Lechuga  bibb orgánica congelada"},
    {prodId:"50454602",label:"Lechuga  boston orgánica congelada"},
    {prodId:"50454603",label:"Lechuga frisee orgánica congelada"},
    {prodId:"50454604",label:"Lechuga rosa lola orgánica congelada"},
    {prodId:"50454605",label:"Lechuga  mixta masculina orgánica congelada"},
    {prodId:"50454606",label:"Lechuga mizuna orgánica congelada"},
    {prodId:"50454607",label:"Lechuga hoja roja orgánica congelada"},
    {prodId:"50454608",label:"Lechuga oja roja roble orgánica congelada"},
    {prodId:"50454609",label:"Lechuga romana ruby orgánica congelada"},
    {prodId:"50454610",label:"Lechuga romana bebé roja orgánica congelada"},
    {prodId:"50454611",label:"Lechuga cabeza de mantequilla orgánica congelada"},
    {prodId:"50454612",label:"Lechuga china orgánica congelada"},
    {prodId:"50454613",label:"Lechuga cabeza tostada orgánica congelada"},
    {prodId:"50454614",label:"Lechuga hoja verde orgánica congelada"},
    {prodId:"50454615",label:"Lechuga iceberg orgánica congelada"},
    {prodId:"50454616",label:"Lechuga cordero orgánica congelada"},
    {prodId:"50454617",label:"Lechuga hoja suelta orgánica congelada"},
    {prodId:"50454618",label:"Lechuga mache orgánica congelada"},
    {prodId:"50454619",label:"Lechuga boston roja orgánica congelada"},
    {prodId:"50454620",label:"Lechuga cabeza roja orgánica congelada"},
    {prodId:"50454621",label:"Lechuga romana orgánica congelada"},
    {prodId:"50454622",label:"Lechuga mostza roja rusa orgánica congelada"},
    {prodId:"50454623",label:"Lechuga tatsoi orgánica congelada"},
    {prodId:"50454700",label:"Ñames orgánicos congelados"},
    {prodId:"50454701",label:"Ñame amarilla orgánica congelada"},
    {prodId:"50454702",label:"Ñame blanca orgánica congelada"},
    {prodId:"50454703",label:"Ñame coco orgánica congelada"},
    {prodId:"50454704",label:"Ñame eddoes orgánica congelada"},
    {prodId:"50454705",label:"Ñame isleña orgánica congelada"},
    {prodId:"50454706",label:"Ñame lila orgánica congelada"},
    {prodId:"50454800",label:"Hongos o champiñones orgánicos congelados"},
    {prodId:"50454801",label:"Champiñón trompeta negra orgánico congelado"},
    {prodId:"50454802",label:"Champiñón carmelito orgánico congelado"},
    {prodId:"50454803",label:"Champiñón  orgánico congelado"},
    {prodId:"50454804",label:"Champiñón chanterelle orgánico congelado"},
    {prodId:"50454805",label:"Champiñón cremini orgánico congelado"},
    {prodId:"50454806",label:"Champiñón enoki orgánico congelado"},
    {prodId:"50454807",label:"Champiñón puercoespín orgánico congelado"},
    {prodId:"50454808",label:"Champiñón gallina del bosque orgánico congelado"},
    {prodId:"50454809",label:"Champiñón langosta orgánico congelado"},
    {prodId:"50454810",label:"Champiñón morels orgánico congelado"},
    {prodId:"50454811",label:"Champiñón ostra orgánico congelado"},
    {prodId:"50454812",label:"Champiñón pleurotus orgánico congelado"},
    {prodId:"50454813",label:"Champiñón pompom orgánico congelado"},
    {prodId:"50454814",label:"Champiñón porcieni orgánico congelado"},
    {prodId:"50454815",label:"Champiñón portobello orgánico congelado"},
    {prodId:"50454816",label:"Champiñón shitake orgánico congelado"},
    {prodId:"50454817",label:"Champiñón shimeji orgánico congelado"},
    {prodId:"50454818",label:"Champiñón san jorge orgánico congelado"},
    {prodId:"50454819",label:"Champiñón blanco orgánico congelado"},
    {prodId:"50454820",label:"Champiñón tompeta blanca orgánico congelado"},
    {prodId:"50454821",label:"Champiñón woodear orgánico congelado"},
    {prodId:"50454900",label:"Mostazas orgánicas congeladas"},
    {prodId:"50454901",label:"Mostaza bambú  orgánica congelada"},
    {prodId:"50454902",label:"Mostaza ajo orgánica congelada"},
    {prodId:"50454903",label:"Mostaza hoja gigante orgánica congelada"},
    {prodId:"50454904",label:"Mostaza rojo en nieve orgánica congelada"},
    {prodId:"50454905",label:"Mostaza sureño orgánica congelada"},
    {prodId:"50454906",label:"Mostaza corazón envuelto orgánica congelada"},
    {prodId:"50455000",label:"Solanáceas (nightshades) orgánicas congeladas"},
    {prodId:"50455001",label:"Lámpara china orgánica congelada"},
    {prodId:"50455002",label:"Mora de jardín"},
    {prodId:"50455003",label:"Naranjilla orgánica orgánicas congeladas"},
    {prodId:"50455004",label:"Tomatillo orgánica congelada"},
    {prodId:"50455100",label:"Okras orgánicas congeladas"},
    {prodId:"50455101",label:"Okra artista orgánica congelada"},
    {prodId:"50455102",label:"Okra vinotinto orgánica congelada"},
    {prodId:"50455103",label:"Okra sin columna clemson orgánica congelada"},
    {prodId:"50455104",label:"Okra verde enano vaina larga orgánica congelada"},
    {prodId:"50455105",label:"Okra  mamut sin columna orgánica congelada"},
    {prodId:"50455106",label:"Okra terciopelo rojo orgánica congelada"},
    {prodId:"50455107",label:"Okra estrella de david reliquia orgánica congelada"},
    {prodId:"50455200",label:"Cebollas orgánicas congeladas"},
    {prodId:"50455201",label:"Cebolla albion orgánica congelada"},
    {prodId:"50455202",label:"Cebolla alisia craig orgánica congelada"},
    {prodId:"50455203",label:"Cebolla hirviendo orgánica congelada"},
    {prodId:"50455204",label:"Cebolla bufalo orgánica congelada"},
    {prodId:"50455205",label:"Cebolla bulbo orgánica congelada"},
    {prodId:"50455206",label:"Cebolla cremosa orgánica congelada"},
    {prodId:"50455207",label:"Cebolla amarillo expreso o - x orgánica congelada"},
    {prodId:"50455208",label:"Cebolla kelsae orgánica congelada"},
    {prodId:"50455209",label:"Cebolla gigante marshal globo fen orgánica congelada"},
    {prodId:"50455210",label:"Cebolla perla orgánica congelada"},
    {prodId:"50455211",label:"Cebolla baron rojo orgánica congelada"},
    {prodId:"50455212",label:"Cebolla roja orgánica congelada"},
    {prodId:"50455213",label:"Cebolla rijnsberger orgánica congelada"},
    {prodId:"50455214",label:"Cebolla senshyu semi - globo amarillo orgánica congelada"},
    {prodId:"50455215",label:"Cebolla sturon orgánica congelada"},
    {prodId:"50455216",label:"Cebolla sturgatter orgánica congelada"},
    {prodId:"50455217",label:"Cebolla dulce orgánica congelada"},
    {prodId:"50455218",label:"Cebolla torpedo o rojo italiana orgánica congelada"},
    {prodId:"50455219",label:"Cebolla roja guardada orgánica congelada"},
    {prodId:"50455220",label:"Cebolla blanca guardada orgánica congelada"},
    {prodId:"50455221",label:"Cebolla amarilla guardada orgánica congelada"},
    {prodId:"50455300",label:"Maníes orgánicos congelados"},
    {prodId:"50455301",label:"Maní bambara orgánico congelado"},
    {prodId:"50455302",label:"Maní florunner orgánico congelado"},
    {prodId:"50455303",label:"Maní husa / kerstings orgánico congelado"},
    {prodId:"50455304",label:"Maní español orgánico congelado"},
    {prodId:"50455305",label:"Maní valencia orgánico congelado"},
    {prodId:"50455306",label:"Maní virginia orgánico congelado"},
    {prodId:"50455400",label:"Alverjas orgánicas congeladas"},
    {prodId:"50455401",label:"Alverja casco morado orgánico y congelado"},
    {prodId:"50455402",label:"Alverja pinkeye orgánica y congelado"},
    {prodId:"50455403",label:"Alverja crowder orgánica y congelado"},
    {prodId:"50455404",label:"Alverja acre blanca  orgánoca y congelada"},
    {prodId:"50455405",label:"Alverja ojo negro orgánica y congelada"},
    {prodId:"50455406",label:"Alverja crema zipper orgánica y congelada"},
    {prodId:"50455500",label:"Pimientos orgánicos congelados"},
    {prodId:"50455501",label:"Pimentón ají orgánico congelado"},
    {prodId:"50455502",label:"Pimentón  árbol orgánico congelado"},
    {prodId:"50455503",label:"Pimentón queso orgánico congelado"},
    {prodId:"50455504",label:"Pimentón chilaca orgánico congelado"},
    {prodId:"50455505",label:"Pimentón cubanelles orgánico congelado"},
    {prodId:"50455506",label:"Pimentón fresno orgánico congelado"},
    {prodId:"50455507",label:"Pimentón kapia orgánico congelado"},
    {prodId:"50455508",label:"Pimentón coreano orgánico congelado"},
    {prodId:"50455509",label:"Pimentón manzano orgánico congelado"},
    {prodId:"50455510",label:"Pimentón melrose orgánico congelado"},
    {prodId:"50455511",label:"Pimentón chile amarillo orgánico congelado"},
    {prodId:"50455512",label:"Pimentón aji dulce orgánico congelado"},
    {prodId:"50455513",label:"Pimentón anaheim orgánico congelado"},
    {prodId:"50455514",label:"Pimentón ancho orgánico congelado"},
    {prodId:"50455515",label:"Pimentón campana orgánico congelado"},
    {prodId:"50455516",label:"Pimentón cascabel orgánico congelado"},
    {prodId:"50455517",label:"Pimentón cayenne orgánico congelado"},
    {prodId:"50455518",label:"Pimentón anfitrión cereza orgánico congelado"},
    {prodId:"50455519",label:"Pimentón chitespin orgánico congelado"},
    {prodId:"50455520",label:"Pimentón dedo picante orgánico congelado"},
    {prodId:"50455521",label:"Pimentón guajillo orgánico congelado"},
    {prodId:"50455522",label:"Pimentón guerro orgánico congelado"},
    {prodId:"50455523",label:"Pimentón habanero orgánico congelado"},
    {prodId:"50455524",label:"Pimentón cera de hungria orgánico congelado"},
    {prodId:"50455525",label:"Pimentón jalapeño orgánico congelado"},
    {prodId:"50455526",label:"Pimentón picante largo orgánico congelado"},
    {prodId:"50455527",label:"Pimentón marasol orgánico congelado"},
    {prodId:"50455528",label:"Pimentón pasilla orgánico congelado"},
    {prodId:"50455529",label:"Pimentón peperoncini orgánico congelado"},
    {prodId:"50455530",label:"Pimentón pequin orgánico congelado"},
    {prodId:"50455531",label:"Pimentón pimiento orgánico congelado"},
    {prodId:"50455532",label:"Pimentón poblano orgánico congelado"},
    {prodId:"50455533",label:"Pimentón scotch bonnet orgánico congelado"},
    {prodId:"50455534",label:"Pimentón serrano orgánico congelado"},
    {prodId:"50455535",label:"Pimentón tabasco orgánico congelado"},
    {prodId:"50455536",label:"Pimentón tai  orgánico congelado"},
    {prodId:"50455537",label:"Pimentón tepin orgánico congelado"},
    {prodId:"50455600",label:"Papas orgánicas congeladas"},
    {prodId:"50455601",label:"Papa blanca larga orgánica congelada"},
    {prodId:"50455602",label:"Papa redonda blanca orgánica congelada"},
    {prodId:"50455603",label:"Papa redonda roja orgánica congelada"},
    {prodId:"50455604",label:"Papa  russet orgánica congelada"},
    {prodId:"50455605",label:"Papa morada orgánica congelada"},
    {prodId:"50455606",label:"Papa amarilla orgánica congelada"},
    {prodId:"50455607",label:"Papa nueva orgánica congelada"},
    {prodId:"50455608",label:"Papa especial orgánica congelada"},
    {prodId:"50455700",label:"Nabos suecos orgánicos congelados"},
    {prodId:"50455701",label:"Nabo sueco acme orgánico congelado"},
    {prodId:"50455702",label:"Nabo sueco angela orgánico congelado"},
    {prodId:"50455703",label:"Nabo sueco el mejor orgánico congelado"},
    {prodId:"50455704",label:"Nabo sueco marian orgánico congelado"},
    {prodId:"50455800",label:"Vegetales marinos orgánicos congelados"},
    {prodId:"50455801",label:"Agar-agar orgánico congelado"},
    {prodId:"50455802",label:"Arame  orgánica congelada"},
    {prodId:"50455803",label:"Alga dulce orgánica congelada"},
    {prodId:"50455804",label:"Habichuelas vert de mer orgánica congelada"},
    {prodId:"50455805",label:"Hijiki orgánica congelada"},
    {prodId:"50455806",label:"Musgo irlandés orgánica congelada"},
    {prodId:"50455807",label:"Quelpo orgánico congelado"},
    {prodId:"50455808",label:"Laver orgánico congelado"},
    {prodId:"50455809",label:"Nori orgánico congelado"},
    {prodId:"50455810",label:"Alga roja orgánica congelada"},
    {prodId:"50455811",label:"Kale de mar orgánica congelada"},
    {prodId:"50455812",label:"Lechuga de mar orgánica congelada"},
    {prodId:"50455813",label:"Algas de mar orgánica congelada"},
    {prodId:"50455814",label:"Spirulina orgánica congelada"},
    {prodId:"50455815",label:"Susabi nuri orgánica congelada"},
    {prodId:"50455816",label:"Wakame orgánica congelada"},
    {prodId:"50455900",label:"Chalotes orgánicos congelados"},
    {prodId:"50455901",label:"Chalote atlántica orgánico congelado"},
    {prodId:"50455902",label:"Chalote creación orgánico congelado"},
    {prodId:"50455903",label:"Chalote drittler nido blanco orgánico congelado"},
    {prodId:"50455904",label:"Chalote gigante mejorado amarillo orgánico congelado"},
    {prodId:"50455905",label:"Chalote gourmet dorado orgánico congelado"},
    {prodId:"50455906",label:"Chalote grise de bagnolet orgánico congelado"},
    {prodId:"50455907",label:"Chalote hative de nort orgánico congelado"},
    {prodId:"50455908",label:"Chalote pikant orgánico congelado"},
    {prodId:"50455909",label:"Chalote papa roja orgánico congelado"},
    {prodId:"50455910",label:"Chalote sante orgánico congelado"},
    {prodId:"50455911",label:"Chalote topper orgánico congelado"},
    {prodId:"50456000",label:"Alazanes orgánicos congelados"},
    {prodId:"50456001",label:"Alazán dock orgánica congelada"},
    {prodId:"50456002",label:"Alazán jardín orgánica congelada"},
    {prodId:"50456003",label:"Alazán oveja orgánica congelada"},
    {prodId:"50456004",label:"Alazán madera orgánica congelada"},
    {prodId:"50456100",label:"Espinacas orgánicas congeladas"},
    {prodId:"50456101",label:"Espinaca americana orgánica congelada"},
    {prodId:"50456102",label:"Espinaca bloomsdale orgánica congelada"},
    {prodId:"50456103",label:"Espinaca invierno gigante orgánica congelada"},
    {prodId:"50456104",label:"Espinaca horenso orgánica congelada"},
    {prodId:"50456105",label:"Espinaca planta de hielo orgánica congelada"},
    {prodId:"50456106",label:"Espinaca  cuarto de cordero orgánica congelada"},
    {prodId:"50456107",label:"Espinaca malabra orgánica congelada"},
    {prodId:"50456108",label:"Espinaca mendania orgánica congelada"},
    {prodId:"50456109",label:"Espinaca nueva zelandia orgánica congelada"},
    {prodId:"50456110",label:"Espinaca orach orgánica congelada"},
    {prodId:"50456111",label:"Espinaca savoy orgánica congelada"},
    {prodId:"50456112",label:"Espinaca hoja de sigma orgánica congelada"},
    {prodId:"50456113",label:"Espinaca espacio orgánica congelada"},
    {prodId:"50456114",label:"Espinaca trinidad orgánica congelada"},
    {prodId:"50456115",label:"Espinaca agua orgánica congelada"},
    {prodId:"50456116",label:"Espinaca de agua sesa"},
    {prodId:"50456200",label:"Calabazas orgánicas congeladas"},
    {prodId:"50456201",label:"Calabaza boston orgánica congelada"},
    {prodId:"50456202",label:"Calabaza buttemut orgánica congelada"},
    {prodId:"50456203",label:"Calabaza costata romanesca orgánica congelada"},
    {prodId:"50456204",label:"Calabaza crookneck orgánica congelada"},
    {prodId:"50456205",label:"Calabaza  cucuzza orgánica congelada"},
    {prodId:"50456206",label:"Calabaza  delicata orgánica congelada"},
    {prodId:"50456207",label:"Calabaza  delisioso orgánica congelada"},
    {prodId:"50456208",label:"Calabaza verano dorado cuello torcido orgánica congelada"},
    {prodId:"50456209",label:"Calabaza prolífico temprano cuello recto orgánica congelada"},
    {prodId:"50456210",label:"Calabaza  oro orgánica congelada"},
    {prodId:"50456211",label:"Calabaza  jack pequeño orgánica congelada"},
    {prodId:"50456212",label:"Calabaza  campo de kentucky orgánica congelada"},
    {prodId:"50456213",label:"Calabaza marrow orgánica congelada"},
    {prodId:"50456214",label:"Calabaza  medio oriente orgánica congelada"},
    {prodId:"50456215",label:"Calabaza  miniatura orgánica congelada"},
    {prodId:"50456216",label:"Calabaza  orangetti orgánica congelada"},
    {prodId:"50456217",label:"Calabaza pattypan orgánica congelada"},
    {prodId:"50456218",label:"Calabaza  rondini orgánica congelada"},
    {prodId:"50456219",label:"Calabaza  redonda orgánica congelada"},
    {prodId:"50456220",label:"Calabaza  espagueti orgánica congelada"},
    {prodId:"50456221",label:"Calabaza  estripeti orgánica congelada"},
    {prodId:"50456222",label:"Calabaza  molde dulce orgánica congelada"},
    {prodId:"50456223",label:"Calabaza  empanada dulce orgánica congelada"},
    {prodId:"50456224",label:"Calabaza  premio triple  orgánica congelada"},
    {prodId:"50456225",label:"Calabaza waltham butternut orgánica congelada"},
    {prodId:"50456226",label:"Calabaza  arbusto scallop amarillo orgánica congelada"},
    {prodId:"50456227",label:"Calabaza cuello recto amarillo orgánica congelada"},
    {prodId:"50456228",label:"Calabaza zafiro orgánica congelada"},
    {prodId:"50456229",label:"Calabaza zucchini orgánica congelada"},
    {prodId:"50456300",label:"Batatas orgánicas congeladas"},
    {prodId:"50456301",label:"Batata beauregard orgánica congelada"},
    {prodId:"50456302",label:"Batata centennial orgánica congelada"},
    {prodId:"50456303",label:"Batata diane orgánica congelada"},
    {prodId:"50456304",label:"Batata dulce garnet orgánica congelada"},
    {prodId:"50456305",label:"Batata georgia dulce rojo orgánica congelada"},
    {prodId:"50456306",label:"Batata  dorado orgánica congelada"},
    {prodId:"50456307",label:"Batata hanna  orgánica congelada"},
    {prodId:"50456308",label:"Batata japonés orgánica congelada"},
    {prodId:"50456309",label:"Batata jersey orgánica congelada"},
    {prodId:"50456310",label:"Batata joya orgánica congelada"},
    {prodId:"50456311",label:"Batata meryland roja orgánica congelada"},
    {prodId:"50456312",label:"Batata nema dorado orgánica congelada"},
    {prodId:"50456313",label:"Batata o'henry orgánica congelada"},
    {prodId:"50456314",label:"Batata okinawan orgánica congelada"},
    {prodId:"50456315",label:"Batata naranja orgánica congelada"},
    {prodId:"50456316",label:"Batata oriental orgánica congelada"},
    {prodId:"50456317",label:"Batata jersey rojo orgánica congelada"},
    {prodId:"50456318",label:"Batata mar rojo orgánica congelada"},
    {prodId:"50456319",label:"Batata brillo rojo orgánica congelada"},
    {prodId:"50456320",label:"Batata jersey amarillo orgánica congelada"},
    {prodId:"50456400",label:"Tomates orgánicos congelados"},
    {prodId:"50456401",label:"Tomates ailsa craig orgánico congelado"},
    {prodId:"50456402",label:"Tomates alicante orgánico congelado"},
    {prodId:"50456403",label:"Tomates ciruela negra orgánico congelado"},
    {prodId:"50456404",label:"Tomates vino de brandy orgánico congelado"},
    {prodId:"50456405",label:"Tomates bella cereza orgánico congelado"},
    {prodId:"50456406",label:"Tomates cereza orgánico congelado"},
    {prodId:"50456407",label:"Tomates delicioso orgánico congelado"},
    {prodId:"50456408",label:"Tomates dombito orgánico congelado"},
    {prodId:"50456409",label:"Tomates delicia del jardín orgánico congelado"},
    {prodId:"50456410",label:"Tomates uva orgánico congelado"},
    {prodId:"50456411",label:"Tomates verde orgánico congelado"},
    {prodId:"50456412",label:"Tomates super marmande orgánico congelado"},
    {prodId:"50456413",label:"Tomates rayas naturales marvel orgánico congelado"},
    {prodId:"50456414",label:"Tomates minibel orgánico congelado"},
    {prodId:"50456415",label:"Tomates oaxacan orgánico congelado"},
    {prodId:"50456416",label:"Tomates alerta roja orgánico congelado"},
    {prodId:"50456417",label:"Tomates roma vf orgánico congelado"},
    {prodId:"50456418",label:"Tomates marzano zan orgánico congelado"},
    {prodId:"50456419",label:"Tomates shirley orgánico congelado"},
    {prodId:"50456420",label:"Tomates siberia orgánico congelado"},
    {prodId:"50456421",label:"Tomates super beefsteak orgánico congelado"},
    {prodId:"50456422",label:"Tomates tigerella orgánico congelado"},
    {prodId:"50456423",label:"Tomates tiny tim orgánico congelado"},
    {prodId:"50456424",label:"Tomates tumbler orgánico congelado"},
    {prodId:"50456425",label:"Tomates cocktail amarillo orgánico congelado"},
    {prodId:"50456426",label:"Tomates forma de pera amarillo orgánico congelado"},
    {prodId:"50456427",label:"Tomates perfección amarilla orgánico congelado"},
    {prodId:"50456500",label:"Nabos orgánicos congelados"},
    {prodId:"50456501",label:"Nabo globo verde orgánico congelado"},
    {prodId:"50456502",label:"Nabo bola dorada orgánico congelado"},
    {prodId:"50456503",label:"Nabo mercado de manchester orgánico congelado"},
    {prodId:"50456504",label:"Nabo top morado milan orgánico congelado"},
    {prodId:"50456505",label:"Nabo top morado blanco orgánico congelado"},
    {prodId:"50456506",label:"Nabo bola de nieve orgánico congelado"},
    {prodId:"50456507",label:"Nabo tokio orgánico congelado"},
    {prodId:"50456508",label:"Nabo cruz de tokio orgánico congelado"},
    {prodId:"50456600",label:"Auyamas orgánicas congeladas"},
    {prodId:"50456601",label:"Calabaza acorn congelada orgánica"},
    {prodId:"50456602",label:"Calabaza gigante atlántico orgánica congelada"},
    {prodId:"50456603",label:"Calabaza banana rosado orgánica congelada"},
    {prodId:"50456604",label:"Calabaza max grande orgánica congelada"},
    {prodId:"50456605",label:"Calabaza  orgánica congelada"},
    {prodId:"50456606",label:"Calabaza carnaval orgánica congelada"},
    {prodId:"50456607",label:"Auyama queso orgánica congelada"},
    {prodId:"50456608",label:"Calabaza príncipe coronado orgánica congelada"},
    {prodId:"50456609",label:"Calabaza  curcibita orgánica congelada"},
    {prodId:"50456610",label:"Calabaza cushaw orgánica congelada"},
    {prodId:"50456611",label:"Calabaza  gigante auyama orgánica congelada"},
    {prodId:"50456612",label:"Calabaza  hubbard orgánica congelada"},
    {prodId:"50456613",label:"Calabaza  jerrahdale orgánica congelada"},
    {prodId:"50456614",label:"Calabaza  kabocha orgánica congelada"},
    {prodId:"50456615",label:"Calabaza queensland azul orgánica congelada"},
    {prodId:"50456616",label:"Calabaza rouge vif détampes orgánica congelada"},
    {prodId:"50456617",label:"Calabaza  turban turco orgánica congelada"},
    {prodId:"50456618",label:"Calabaza  valenciano orgánica congelada"},
    {prodId:"50456619",label:"Calabaza  warted hubbard orgánica congelada"},
    {prodId:"50456620",label:"Auyama whangaparoa orgánica congelada"},
    {prodId:"50456700",label:"Ñames orgánicos congelados"},
    {prodId:"50456701",label:"Ñame agria africana orgánica congelada"},
    {prodId:"50456702",label:"Ñame agria asiática orgánica congelada"},
    {prodId:"50456703",label:"Ñame china orgánico congelado"},
    {prodId:"50456704",label:"Ñame globo orgánico congelado"},
    {prodId:"50456705",label:"Ñame grater orgánico congelado"},
    {prodId:"50456706",label:"Ñame japonesa orgánico congelado"},
    {prodId:"50456707",label:"Ñame lesser orgánico congelado"},
    {prodId:"50456708",label:"Ñame papa orgánico congelado"},
    {prodId:"50456709",label:"Ñame guinea blanca orgánico congelado"},
    {prodId:"50456710",label:"Ñame guinea amarillo orgánico congelado"},
    {prodId:"50456800",label:"Vegetales Nominant orgánicos congelados"},
    {prodId:"50456801",label:"Alfalfa orgánica congelada"},
    {prodId:"50456802",label:"Hojas de aloe orgánica congelada"},
    {prodId:"50456803",label:"Apio orgánico congelado"},
    {prodId:"50456804",label:"Arrurruz orgánica congelada"},
    {prodId:"50456805",label:"Punta de flecha orgánica congelada"},
    {prodId:"50456806",label:"rúcula orgánica congelada"},
    {prodId:"50456807",label:"Arum orgánica congelada"},
    {prodId:"50456808",label:"Raíz de bambú orgánica congelada"},
    {prodId:"50456809",label:"Hojas de plátano orgánica congelada"},
    {prodId:"50456810",label:"Batatas orgánica congelada"},
    {prodId:"50456811",label:"Brotes de frijol orgánicos congelados"},
    {prodId:"50456812",label:"Top de remolacha orgánica congelada"},
    {prodId:"50456813",label:"Melón agrio orgánico congelado"},
    {prodId:"50456814",label:"Bayas de alcaparra orgánica orgánicas congeladas"},
    {prodId:"50456815",label:"Carob orgánico congelado"},
    {prodId:"50456816",label:"Cha-om orgánico congelado"},
    {prodId:"50456817",label:"Chayote o guatila orgánico congelado"},
    {prodId:"50456818",label:"Garbanzo orgánico congelado"},
    {prodId:"50456819",label:"Verde de crisantemos orgánico congelado"},
    {prodId:"50456820",label:"Verde de diente de león orgánico congelado"},
    {prodId:"50456821",label:"Diente de león orgánico congelado"},
    {prodId:"50456822",label:"Dasheen orgánico congelado"},
    {prodId:"50456823",label:"Puntas de alverja orgánico congelado"},
    {prodId:"50456824",label:"Diakon orgánico congelado"},
    {prodId:"50456825",label:"Donqua orgánica congelada"},
    {prodId:"50456826",label:"Helecho orgánico congelado"},
    {prodId:"50456827",label:"Gai choi orgánico congelado"},
    {prodId:"50456828",label:"Gailon orgánico congelado"},
    {prodId:"50456829",label:"Galanga orgánica congelada"},
    {prodId:"50456830",label:"Raíz de jengibre orgánica congelada"},
    {prodId:"50456831",label:"Gobo orgánico congelado"},
    {prodId:"50456832",label:"Brote de lúpulo orgánico congelado"},
    {prodId:"50456833",label:"Rabano blanco orgánico congelado"},
    {prodId:"50456834",label:"Jicama orgánica congelada"},
    {prodId:"50456835",label:"Kuduz orgánica congelada"},
    {prodId:"50456836",label:"Bulbo de margarita orgánico congelado"},
    {prodId:"50456837",label:"Linkok orgánico congelado"},
    {prodId:"50456838",label:"Lo bok orgánico congelado"},
    {prodId:"50456839",label:"Frijol largo orgánico congelado"},
    {prodId:"50456840",label:"Raíz de lotus orgánico congelado"},
    {prodId:"50456841",label:"Hojas de maguey orgánica congelada"},
    {prodId:"50456842",label:"Mallows orgánico congelado"},
    {prodId:"50456843",label:"Sapote mamey orgánico congelado"},
    {prodId:"50456844",label:"Moap orgánico congelado"},
    {prodId:"50456845",label:"Moo orgánico congelado"},
    {prodId:"50456846",label:"Moqua orgánica congelada"},
    {prodId:"50456847",label:"Opos orgánicos congelados"},
    {prodId:"50456848",label:"Corazon de palma orgánico congelado"},
    {prodId:"50456849",label:"Paprika orgánica congelada"},
    {prodId:"50456850",label:"Purslane orgánica congelada"},
    {prodId:"50456851",label:"Raddichios orgánica congelada"},
    {prodId:"50456852",label:"Sinquas orgánica congelada"},
    {prodId:"50456853",label:"Frijol de soya orgánicos congelados"},
    {prodId:"50456854",label:"Spoonwart orgánico congelado"},
    {prodId:"50456855",label:"Uva tassele hyacinth orgánico congelado"},
    {prodId:"50456856",label:"Taro orgánico congelado"},
    {prodId:"50456857",label:"Hoja de taro orgánica congelada"},
    {prodId:"50456858",label:"Tallo de taro orgánico congelado"},
    {prodId:"50456859",label:"Tapeguaje orgánico congelado"},
    {prodId:"50456860",label:"Verde tierno orgánico congelado"},
    {prodId:"50456861",label:"Tindora orgánico congelado"},
    {prodId:"50456862",label:"Árbol de cebolla orgánico congelado"},
    {prodId:"50456863",label:"Udo orgánico congelado"},
    {prodId:"50456864",label:"Agua castaño orgánico congelado"},
    {prodId:"50456865",label:"Yumpi orgánico congelado"},
    {prodId:"50456866",label:"Yautia orgánico congelado"},
    {prodId:"50456867",label:"Yu choy orgánico congelado"},
    {prodId:"50456868",label:"Yuca orgánica congelada"},
    {prodId:"50456900",label:"Alverjas dulces orgánicas congeladas"},
    {prodId:"50456901",label:"Alverja biquini orgánica congelada"},
    {prodId:"50456902",label:"Alverja cavalier orgánica congelada"},
    {prodId:"50456903",label:"Alverja margarita orgánica congelada"},
    {prodId:"50456904",label:"Alverja dar fon orgánica congelada"},
    {prodId:"50456905",label:"Alverja early onward orgánica congelada"},
    {prodId:"50456906",label:"Alverja primer feltham orgánica congelada"},
    {prodId:"50456907",label:"Alverja verde hurst shaft orgánica congelada"},
    {prodId:"50456908",label:"Guisante oregón sugar pod orgánica congelada"},
    {prodId:"50456909",label:"Alverja príncipe alberto orgánica congelada"},
    {prodId:"50456910",label:"Alverja reuzensuiker orgánica congelada"},
    {prodId:"50461900",label:"Remolachas  en lata o en frasco"},
    {prodId:"50461901",label:"Remolacha acción enlatada o envasada"},
    {prodId:"50461902",label:"Remolacha albina vereduna enlatada o envasada"},
    {prodId:"50461903",label:"Remolacha barababiotela de chiggia enlatada o envasada"},
    {prodId:"50461904",label:"Remolacha boltardy enlatada o envasada"},
    {prodId:"50461905",label:"Remolacha bonel enlatada o envasada"},
    {prodId:"50461906",label:"Remolacha burpees dorado enlatada o envasada"},
    {prodId:"50461907",label:"Remolacha cheltenham tapa verde enlatada o envasada"},
    {prodId:"50461908",label:"Remolacha cheltenham mono enlatada o envasada"},
    {prodId:"50461909",label:"Remolacha chioggia enlatada o envasada"},
    {prodId:"50461910",label:"Remolacha cilindra enlatada o envasada"},
    {prodId:"50461911",label:"Remolacha dégypte enlatada o envasada"},
    {prodId:"50461912",label:"Remolacha detroit 2 rojo oscuro enlatada o envasada"},
    {prodId:"50461913",label:"Remolacha detroit 2 bala chiquita enlatada o envasada"},
    {prodId:"50461914",label:"Remolacha egipcia plana  enlatada o envasada"},
    {prodId:"50461915",label:"Remolacha  raíz de nabo egipcio enlatada o envasada"},
    {prodId:"50461916",label:"Remolacha fomanova enlatada o envasada"},
    {prodId:"50461917",label:"Remolacha forono enlatada o envasada"},
    {prodId:"50461918",label:"Remolacha monaco enlatada o envasada"},
    {prodId:"50461919",label:"Remolacha monograma enlatada o envasada"},
    {prodId:"50461920",label:"Remolacha pronto enlatada o envasada"},
    {prodId:"50461921",label:"Remolacha regalía enlatada o envasada"},
    {prodId:"50461922",label:"Remolacha dulce enlatada o envasada"},
    {prodId:"50462000",label:"Brócolis  en lata o en frasco"},
    {prodId:"50462001",label:"Brócolini enlatado o envasado"},
    {prodId:"50462002",label:"Brócoli romanesco enlatado o envasado"},
    {prodId:"50462003",label:"Brócoli raab enlatado o envasado"},
    {prodId:"50462004",label:"Brócoli chino enlatado o envasado"},
    {prodId:"50462100",label:"Repollitos de Bruselas  en lata o en frasco"},
    {prodId:"50462101",label:"Repollitas de bruselas citade orgánica enlatadas o envasadas"},
    {prodId:"50462102",label:"Repollitas de bruselas falstaff orgánica enlatadas o envasadas"},
    {prodId:"50462103",label:"Repollitas de bruselas oliver orgánica enlatadas o envasadas"},
    {prodId:"50462104",label:"Repollitas de bruselas peer gynt orgánica enlatadas o envasadas"},
    {prodId:"50462105",label:"Repollitas de bruselas rampart orgánica enlatadas o envasadas"},
    {prodId:"50462106",label:"Repollitas de bruselas rubine orgánica enlatadas o envasadas"},
    {prodId:"50462107",label:"Repollitas de bruselas  widgeon orgánica enlatadas o envasadas"},
    {prodId:"50462300",label:"Repollos en lata o en frasco"},
    {prodId:"50462301",label:"Repollo negro enlatado o envasado"},
    {prodId:"50462302",label:"Repollo savoy enlatado o envasado"},
    {prodId:"50462303",label:"Repollo zorrillo enlatado o envasado"},
    {prodId:"50462304",label:"Repollo blanco enlatado o envasado"},
    {prodId:"50462400",label:"Cardos en lata o en frasco"},
    {prodId:"50462401",label:"Cardos lunghi enlatados o envasados"},
    {prodId:"50462402",label:"Cardos gobbi enlatados o envasados"},
    {prodId:"50462600",label:"Coliflores  en lata o en frasco"},
    {prodId:"50462601",label:"Coliflor todo el año  enlatada o envasada"},
    {prodId:"50462602",label:"Coliflor alverda enlatada o envasada"},
    {prodId:"50462603",label:"coliflor otoño gigante 3 enlatada o envasada"},
    {prodId:"50462604",label:"Coliflo rdok elgon enlatada o envasada"},
    {prodId:"50462605",label:"Coliflor bola de nieve temprana enlatada o envasada"},
    {prodId:"50462606",label:"Coliflor luz de lima enlatada o envasada"},
    {prodId:"50462607",label:"Coliflor minaret enlatada o envasada"},
    {prodId:"50462608",label:"Coliflor buque de naranja enlatada o envasada"},
    {prodId:"50462609",label:"Coliflor capa morada enlatada o envasada"},
    {prodId:"50462610",label:"Coliflor bola de nieve enlatada o envasada"},
    {prodId:"50462611",label:"Coliflor invierno walcheren 3 enlatada o envasada"},
    {prodId:"50462612",label:"Coliflor piedra blanca enlatada o envasada"},
    {prodId:"50462700",label:"Apios en lata o en frasco"},
    {prodId:"50462701",label:"Apio celebridad enlatado o envasado"},
    {prodId:"50462702",label:"Apio nabo enlatado o envasado"},
    {prodId:"50462703",label:"Apio chino enlatado o envasado"},
    {prodId:"50462704",label:"Apio dinant francés enlatado o envasado"},
    {prodId:"50462705",label:"Apio gigante rosado enlatado o envasado"},
    {prodId:"50462706",label:"Apio gigante rojo enlatado o envasado"},
    {prodId:"50462707",label:"Apio gigante blanco enlatado o envasado"},
    {prodId:"50462708",label:"Apio dorado auto escalado enlatado o envasado"},
    {prodId:"50462709",label:"Apio greensleeves enlatado o envasado"},
    {prodId:"50462710",label:"Apio hopkins fenlander enlatado o envasado"},
    {prodId:"50462711",label:"Apio torre marfil enlatado o envasado"},
    {prodId:"50462712",label:"Apio lathom blanqueado enlatado o envasado"},
    {prodId:"50462713",label:"Apio sopa de amsterdam enlatado o envasado"},
    {prodId:"50462714",label:"Apio estándar cargado enlatado o envasado"},
    {prodId:"50462715",label:"Apio triunfo alto utah enlatado o envasado"},
    {prodId:"50462900",label:"Achicorias en lata o en frasco"},
    {prodId:"50462901",label:"Achicoria batavian hoja ancha enlatada o envasada"},
    {prodId:"50462902",label:"Achicoria en cornet de bordeaux enlatada o envasada"},
    {prodId:"50462903",label:"Achicoria rollo verde ruffee enlatada o envasada"},
    {prodId:"50462904",label:"Achicoria roll verde enlatada o envasada"},
    {prodId:"50462905",label:"Achicoria limnoe lone enlatada o envasada"},
    {prodId:"50462906",label:"Achicoria pancalieri riccia enlatada o envasada"},
    {prodId:"50462907",label:"Achicoria ensalada rey enlatada o envasada"},
    {prodId:"50462908",label:"Achicoria sanda enlatada o envasada"},
    {prodId:"50462909",label:"Achicoria scarola verde enlatada o envasada"},
    {prodId:"50462910",label:"Achicoria tres fine maraichere enlatada o envasada"},
    {prodId:"50462911",label:"Achicoria wallone freisee wescelkkopf enlatada o envasada"},
    {prodId:"50463000",label:"Repollos chinos en lata o en frasco"},
    {prodId:"50463001",label:"Bok choy enlatado o envasado"},
    {prodId:"50463002",label:"Bok choy enano enlatado o envasado"},
    {prodId:"50463003",label:"Flor de repollo chino enlatada o envasada"},
    {prodId:"50463004",label:"Choy sum enlatado o envasado"},
    {prodId:"50463005",label:"Col (bok choy) enano enlatado o envasado"},
    {prodId:"50463006",label:"Col (bok choy) fengshan enlatado o envasado"},
    {prodId:"50463007",label:"Col (bok choy) jade pagoda enlatado o envasado"},
    {prodId:"50463008",label:"Col (bok choy) kasumi enlatado o envasado"},
    {prodId:"50463009",label:"col (bok choy)  nerva enlatado o envasado"},
    {prodId:"50463010",label:"Col (bok choy) rosette enlatado o envasado"},
    {prodId:"50463011",label:"Col (bok choy) ruffles enlatado o envasado"},
    {prodId:"50463012",label:"Col (bok choy) hoja  santo serrated enlatado o envasado"},
    {prodId:"50463013",label:"Col (bok choy) shangahai enlatado o envasado"},
    {prodId:"50463014",label:"Shantung enlatado o envasado"},
    {prodId:"50463015",label:"Repollo tip top enlatado o envasado"},
    {prodId:"50463016",label:"Yau choy sum enlatado o envasado"},
    {prodId:"50463300",label:"Berros  en lata o en frasco"},
    {prodId:"50463301",label:"Berros de tierra enlatados o envasados"},
    {prodId:"50463302",label:"Nasturtium enlatado o envasado"},
    {prodId:"50463303",label:"Berros de agua enlatados o envasados"},
    {prodId:"50463304",label:"Berros de invierno enlatados o envasados"},
    {prodId:"50463400",label:"Cocombros en lata o en frasco"},
    {prodId:"50463401",label:"Cocombro arena enlatado o envasado"},
    {prodId:"50463402",label:"Cocombro americano enlatado o envasado"},
    {prodId:"50463403",label:"Cocombro atena enlatado o envasado"},
    {prodId:"50463404",label:"Cocombro blanco lungo de parigi enlatado o envasado"},
    {prodId:"50463405",label:"Cocombro verde burpless buen sabor enlatado o envasado"},
    {prodId:"50463406",label:"Cocombro chicago pickling enlatado o envasado"},
    {prodId:"50463407",label:"Cocombro manzana cristal enlatado o envasado"},
    {prodId:"50463408",label:"Cocombro limón cristal enlatado o envasado"},
    {prodId:"50463409",label:"Cocombro danimas enlatado o envasado"},
    {prodId:"50463410",label:"Cocombro gherkin enlatado o envasado"},
    {prodId:"50463411",label:"Cocombro hokus enlatado o envasado"},
    {prodId:"50463412",label:"Cocombro japonés enlatado o envasado"},
    {prodId:"50463413",label:"Cocombro karela enlatado o envasado"},
    {prodId:"50463414",label:"Cocombro korila enlatado o envasado"},
    {prodId:"50463415",label:"Cocombro verde largo mejorado enlatado o envasado"},
    {prodId:"50463416",label:"Cocombro mas mercado enlatado o envasado"},
    {prodId:"50463417",label:"Cocombro enano enlatado o envasado"},
    {prodId:"50463418",label:"Cocombro pickling nacional enlatado o envasado"},
    {prodId:"50463419",label:"Cocombro persa enlatado o envasado"},
    {prodId:"50463420",label:"Cocombro telégrafo enlatado o envasado"},
    {prodId:"50463421",label:"Cocombro telégrafo mejorado enlatado o envasado"},
    {prodId:"50463422",label:"Cocombro vert de massy cornichon enlatado o envasado"},
    {prodId:"50463423",label:"Cocombro yamato enlatado o envasado"},
    {prodId:"50463500",label:"Berenjenas en lata o en frasco"},
    {prodId:"50463501",label:"Berenjena bambino enlatada o envasada"},
    {prodId:"50463502",label:"Berenjena  belleza negra enlatada o envasada"},
    {prodId:"50463503",label:"Berenjena  negra enorme enlatada o envasada"},
    {prodId:"50463504",label:"Berenjena  chino enlatada o envasada"},
    {prodId:"50463505",label:"Berenjena  huevo de pascua enlatada o envasada"},
    {prodId:"50463506",label:"Berenjena  filipino enlatada o envasada"},
    {prodId:"50463507",label:"Berenjena   mercado de florida enlatada o envasada"},
    {prodId:"50463508",label:"Berenjena  india enlatada o envasada"},
    {prodId:"50463509",label:"Berenjena  italiano enlatada o envasada"},
    {prodId:"50463510",label:"Berenjena  japonés enlatada o envasada"},
    {prodId:"50463511",label:"Berenjena  morado largo enlatada o envasada"},
    {prodId:"50463512",label:"Berenjena   rayas larga enlatada o envasada"},
    {prodId:"50463513",label:"Berenjena  mercado de dinero enlatada o envasada"},
    {prodId:"50463514",label:"Berenjena  ova enlatada o envasada"},
    {prodId:"50463515",label:"Berenjena   arveja enlatada o envasada"},
    {prodId:"50463516",label:"Berenjena  tom bajito enlatada o envasada"},
    {prodId:"50463517",label:"Berenjena  siciliano enlatada o envasada"},
    {prodId:"50463518",label:"Berenjena  tailandés enlatada o envasada"},
    {prodId:"50463519",label:"Berenjena  violeta de florencia enlatada o envasada"},
    {prodId:"50463520",label:"Berenjena  blanco enlatada o envasada"},
    {prodId:"50463600",label:"Endivias en lata o en frasco"},
    {prodId:"50463601",label:"Endivias witloof de brucelas orgánica enlatadas o envasadas"},
    {prodId:"50463602",label:"Endivias francocastel orgánica enlatadas o envasadas"},
    {prodId:"50463603",label:"Endivias catalogna de galantina orgánica enlatadas o envasadas"},
    {prodId:"50463604",label:"Endivias chioggia orgánica enlatadas o envasadas"},
    {prodId:"50463605",label:"Endivias grumolo verde orgánica enlatadas o envasadas"},
    {prodId:"50463606",label:"Endivias raíz larga magdeburg orgánica enlatadas o envasadas"},
    {prodId:"50463607",label:"Endivias palla rosa zorzi precoce orgánica enlatadas o envasadas"},
    {prodId:"50463608",label:"Endivias radice amare orgánica enlatadas o envasadas"},
    {prodId:"50463609",label:"Endivias rosa de traviso orgánica enlatadas o envasadas"},
    {prodId:"50463610",label:"Endivias  rosa de verona orgánica enlatadas o envasadas"},
    {prodId:"50463611",label:"Endivias soncino orgánica enlatadas o envasadas"},
    {prodId:"50463612",label:"Endivias sugarthi orgánica enlatadas o envasadas"},
    {prodId:"50463613",label:"Endivias verona orgánica enlatadas o envasadas"},
    {prodId:"50463614",label:"Endivias witloof zoom orgánica enlatadas o envasadas"},
    {prodId:"50463700",label:"Hinojos en lata o en frasco"},
    {prodId:"50463701",label:"Hinojo cantino enlatado o envasado"},
    {prodId:"50463702",label:"Hinojo fino enlatado o envasado"},
    {prodId:"50463703",label:"Hinojoherald enlatado o envasado"},
    {prodId:"50463704",label:"Hinojo perfección enlatado o envasado"},
    {prodId:"50463705",label:"Hinojo sirio enlatado o envasado"},
    {prodId:"50463706",label:"Hinojo florencia dulce enlatado o envasado"},
    {prodId:"50463707",label:"Hinojo tardo enlatado o envasado"},
    {prodId:"50463900",label:"Calabazas en lata o en frasco"},
    {prodId:"50463901",label:"Petula enlatada o envasada"},
    {prodId:"50463902",label:"Melon amargo enlatado o envasado"},
    {prodId:"50463903",label:"Botella calabaza enlatada o envasada"},
    {prodId:"50463904",label:"Calabash gounds enlatada o envasada"},
    {prodId:"50463905",label:"Melón peludo enlatado o envasado"},
    {prodId:"50463906",label:"Calabaza amarga enlatada o envasada"},
    {prodId:"50463907",label:"Loofah suave enlatado o envasado"},
    {prodId:"50463908",label:"Serpiente de calabaza enlatada o envasada"},
    {prodId:"50463909",label:"Calabaza larga amarga enlatada o envasada"},
    {prodId:"50463910",label:"Calabaza tinda enlatada o envasada"},
    {prodId:"50463911",label:"Calabaza tindoori enlatada o envasada"},
    {prodId:"50464200",label:"Coles en lata o en frasco"},
    {prodId:"50464201",label:"Col rizada enlatada o envasada"},
    {prodId:"50464202",label:"Repollo verde enlatado o envasado"},
    {prodId:"50464300",label:"Colinabos en lata o en frasco"},
    {prodId:"50464301",label:"Colinabo estrella azur enlatado o envasado"},
    {prodId:"50464302",label:"Colinabo verde viena enlatado o envasado"},
    {prodId:"50464303",label:"Colinabo lanro enlatado o envasado"},
    {prodId:"50464304",label:"Colinabo viena morada enlatado o envasado"},
    {prodId:"50464305",label:"Colinabo trero rowel enlatado o envasado"},
    {prodId:"50464306",label:"Colinabo viena blanca enlatado o envasado"},
    {prodId:"50464400",label:"Puerros en lata o en frasco"},
    {prodId:"50464401",label:"Puerro cobra gigante otoño enlatado o envasado"},
    {prodId:"50464402",label:"Puerro otoño mamut 2 enlatado o envasado"},
    {prodId:"50464403",label:"Puerro blue de solaise enlatado o envasado"},
    {prodId:"50464404",label:"Puerro cortina enlatado o envasado"},
    {prodId:"50464405",label:"Puerro prelina enlatado o envasado"},
    {prodId:"50464406",label:"Puerro salvaje enlatado o envasado"},
    {prodId:"50464500",label:"Lentejas  en lata o en frasco"},
    {prodId:"50464501",label:"Lenteja beluga enlatada o envasada"},
    {prodId:"50464502",label:"Lenteja  verde francesa enlatada o envasada"},
    {prodId:"50464503",label:"Lenteja verde enlatada o envasada"},
    {prodId:"50464504",label:"Lenteja crimson pequeña enlatada o envasada"},
    {prodId:"50464505",label:"Lenteja pardina española enlatada o envasada"},
    {prodId:"50464506",label:"Lenteja roja separada enlatada o envasada"},
    {prodId:"50464507",label:"Lenteja amarilla separad enlatada o envasada"},
    {prodId:"50464508",label:"Lenteja tarahumara rosada enlatada o envasada"},
    {prodId:"50464600",label:"Lechugas en lata o en frasco"},
    {prodId:"50464601",label:"Lechuga  bibb enlatada o envasada"},
    {prodId:"50464602",label:"Lechuga  boston enlatada o envasada"},
    {prodId:"50464603",label:"Lechuga frisee enlatada o envasada"},
    {prodId:"50464604",label:"Lechuga rosa lola enlatada o envasada"},
    {prodId:"50464605",label:"Lechuga  mixta masculina enlatada o envasada"},
    {prodId:"50464606",label:"Lechuga mizuna enlatada o envasada"},
    {prodId:"50464607",label:"Lechuga hoja roja enlatada o envasada"},
    {prodId:"50464608",label:"Lechuga oja roja roble enlatada o envasada"},
    {prodId:"50464609",label:"Lechuga romana ruby enlatada o envasada"},
    {prodId:"50464610",label:"Lechuga romana bebé roja enlatada o envasada"},
    {prodId:"50464611",label:"Lechuga cabeza de mantequilla enlatada o envasada"},
    {prodId:"50464612",label:"Lechuga china enlatada o envasada"},
    {prodId:"50464613",label:"Lechuga cabeza tostada enlatada o envasada"},
    {prodId:"50464614",label:"Lechuga hoja verde enlatada o envasada"},
    {prodId:"50464615",label:"Lechuga iceberg enlatada o envasada"},
    {prodId:"50464616",label:"Lechuga cordero enlatada o envasada"},
    {prodId:"50464617",label:"Lechuga hoja suelta enlatada o envasada"},
    {prodId:"50464618",label:"Lechuga mache enlatada o envasada"},
    {prodId:"50464619",label:"Lechuga boston roja enlatada o envasada"},
    {prodId:"50464620",label:"Lechuga cabeza roja enlatada o envasada"},
    {prodId:"50464621",label:"Lechuga romana enlatada o envasada"},
    {prodId:"50464622",label:"Lechuga mostza roja rusa enlatada o envasada"},
    {prodId:"50464623",label:"Lechuga tatsoi enlatada o envasada"},
    {prodId:"50464700",label:"Ñames en lata o en frasco"},
    {prodId:"50464701",label:"Ñame amarilla enlatada o envasada"},
    {prodId:"50464702",label:"Ñame blanca enlatada o envasada"},
    {prodId:"50464703",label:"Ñame coco enlatada o envasada"},
    {prodId:"50464704",label:"Ñame eddoes enlatada o envasada"},
    {prodId:"50464705",label:"Ñame isleña enlatada o envasada"},
    {prodId:"50464706",label:"Ñame lila enlatada o envasada"},
    {prodId:"50465100",label:"Okras en lata o en frasco"},
    {prodId:"50465101",label:"Okra artista enlatada o envasada"},
    {prodId:"50465102",label:"Okra vinotinto enlatada o envasada"},
    {prodId:"50465103",label:"Okra sin columna clemson enlatada o envasada"},
    {prodId:"50465104",label:"Okra verde enano vaina larga enlatada o envasada"},
    {prodId:"50465105",label:"Okra  mamut sin columna enlatada o envasada"},
    {prodId:"50465106",label:"Okra terciopelo rojo enlatada o envasada"},
    {prodId:"50465107",label:"Okra estrella de david reliquia enlatada o envasada"},
    {prodId:"50465400",label:"Alverjas en lata o en frasco"},
    {prodId:"50465401",label:"Alverja casco morado enlatada o envasada"},
    {prodId:"50465402",label:"Alverja pinkeye enlatada o envasada"},
    {prodId:"50465403",label:"Alverja crowder enlatada o envasada"},
    {prodId:"50465404",label:"Alverja acre blanca enlatada o envasada"},
    {prodId:"50465405",label:"Alverja ojo negro enlatada o envasada"},
    {prodId:"50465406",label:"Alverja crema zipper enlatada o envasada"},
    {prodId:"50465700",label:"Nabos suecos en lata o en frasco"},
    {prodId:"50465701",label:"Nabo sueco acme enlatado o envasado"},
    {prodId:"50465702",label:"Nabo sueco angela enlatado o envasado"},
    {prodId:"50465703",label:"Nabo sueco el mejor enlatado o envasado"},
    {prodId:"50465704",label:"Nabo sueco marian enlatado o envasado"},
    {prodId:"50465900",label:"Chalotes en lata o en frasco"},
    {prodId:"50465901",label:"Chalote atlántica enlatado o envasado"},
    {prodId:"50465902",label:"Chalote creación enlatado o envasado"},
    {prodId:"50465903",label:"Chalote drittler nido blanco enlatado o envasado"},
    {prodId:"50465904",label:"Chalote gigante mejorado amarillo enlatado o envasado"},
    {prodId:"50465905",label:"Chalote gourmet dorado enlatado o envasado"},
    {prodId:"50465906",label:"Chalote grise de bagnolet enlatado o envasado"},
    {prodId:"50465907",label:"Chalote hative de nort enlatado o envasado"},
    {prodId:"50465908",label:"Chalote pikant enlatado o envasado"},
    {prodId:"50465909",label:"Chalote papa roja enlatado o envasado"},
    {prodId:"50465910",label:"Chalote sante enlatado o envasado"},
    {prodId:"50465911",label:"Chalote topper enlatado o envasado"},
    {prodId:"50466000",label:"Alazanes en lata o en frasco"},
    {prodId:"50466001",label:"Alazán dock enlatada o envasada"},
    {prodId:"50466002",label:"Alazán jardín enlatada o envasada"},
    {prodId:"50466003",label:"Alazán oveja enlatada o envasada"},
    {prodId:"50466004",label:"Alazán madera enlatada o envasada"},
    {prodId:"50466100",label:"Espinacas en lata o en frasco"},
    {prodId:"50466101",label:"Espinaca americana enlatada o envasada"},
    {prodId:"50466102",label:"Espinaca bloomsdale enlatada o envasada"},
    {prodId:"50466103",label:"Espinaca invierno gigante enlatada o envasada"},
    {prodId:"50466104",label:"Espinaca horenso enlatada o envasada"},
    {prodId:"50466105",label:"Espinaca planta de hielo enlatada o envasada"},
    {prodId:"50466106",label:"Espinaca  cuarto de cordero enlatada o envasada"},
    {prodId:"50466107",label:"Espinaca malabra enlatada o envasada"},
    {prodId:"50466108",label:"Espinaca mendania enlatada o envasada"},
    {prodId:"50466109",label:"Espinaca nueva zelandia enlatada o envasada"},
    {prodId:"50466110",label:"Espinaca orach enlatada o envasada"},
    {prodId:"50466111",label:"Espinaca savoy enlatada o envasada"},
    {prodId:"50466112",label:"Espinaca hoja de sigma enlatada o envasada"},
    {prodId:"50466113",label:"Espinaca espacio enlatada o envasada"},
    {prodId:"50466114",label:"Espinaca trinidad enlatada o envasada"},
    {prodId:"50466115",label:"Espinaca agua enlatada o envasada"},
    {prodId:"50466116",label:"Espinaca de agua sesa"},
    {prodId:"50466200",label:"Calabazas en lata o en frasco"},
    {prodId:"50466201",label:"Calabaza boston enlatada o envasada"},
    {prodId:"50466202",label:"Calabaza buttemut enlatada o envasada"},
    {prodId:"50466203",label:"Calabaza costata romanesca enlatada o envasada"},
    {prodId:"50466204",label:"Calabaza crookneck enlatada o envasada"},
    {prodId:"50466205",label:"Calabaza  cucuzza enlatada o envasada"},
    {prodId:"50466206",label:"Calabaza  delicata enlatada o envasada"},
    {prodId:"50466207",label:"Calabaza  delisioso enlatada o envasada"},
    {prodId:"50466208",label:"Calabaza verano dorado cuello torcido enlatada o envasada"},
    {prodId:"50466209",label:"Calabaza prolífico temprano cuello recto enlatada o envasada"},
    {prodId:"50466210",label:"Calabaza  oro enlatada o envasada"},
    {prodId:"50466211",label:"Calabaza  jack pequeño enlatada o envasada"},
    {prodId:"50466212",label:"Calabaza  campo de kentucky enlatada o envasada"},
    {prodId:"50466213",label:"Calabaza marrow enlatada o envasada"},
    {prodId:"50466214",label:"Calabaza  medio oriente enlatada o envasada"},
    {prodId:"50466215",label:"Calabaza  miniatura enlatada o envasada"},
    {prodId:"50466216",label:"Calabaza  orangetti enlatada o envasada"},
    {prodId:"50466217",label:"Calabaza pattypan enlatada o envasada"},
    {prodId:"50466218",label:"Calabaza  rondini enlatada o envasada"},
    {prodId:"50466219",label:"Calabaza  redonda enlatada o envasada"},
    {prodId:"50466220",label:"Calabaza  espagueti enlatada o envasada"},
    {prodId:"50466221",label:"Calabaza  estripeti enlatada o envasada"},
    {prodId:"50466222",label:"Calabaza  molde dulce enlatada o envasada"},
    {prodId:"50466223",label:"Calabaza  empanada dulce enlatada o envasada"},
    {prodId:"50466224",label:"Calabaza  premio triple  enlatada o envasada"},
    {prodId:"50466225",label:"Calabaza waltham butternut enlatada o envasada"},
    {prodId:"50466226",label:"Calabaza  arbusto scallop amarillo enlatada o envasada"},
    {prodId:"50466227",label:"Calabaza cuello recto amarillo enlatada o envasada"},
    {prodId:"50466228",label:"Calabaza zafiro enlatada o envasada"},
    {prodId:"50466229",label:"Calabaza zucchini enlatada o envasada"},
    {prodId:"50466300",label:"Batatas en lata o en frasco"},
    {prodId:"50466301",label:"Batata beauregard enlatada o envasada"},
    {prodId:"50466302",label:"Batata centennial enlatada o envasada"},
    {prodId:"50466303",label:"Batata diane enlatada o envasada"},
    {prodId:"50466304",label:"Batata dulce garnet enlatada o envasada"},
    {prodId:"50466305",label:"Batata georgia dulce rojo enlatada o envasada"},
    {prodId:"50466306",label:"Batata  dorado enlatada o envasada"},
    {prodId:"50466307",label:"Batata hanna  enlatada o envasada"},
    {prodId:"50466308",label:"Batata japonés enlatada o envasada"},
    {prodId:"50466309",label:"Batata jersey enlatada o envasada"},
    {prodId:"50466310",label:"Batata joya enlatada o envasada"},
    {prodId:"50466311",label:"Batata meryland roja enlatada o envasada"},
    {prodId:"50466312",label:"Batata nema dorado enlatada o envasada"},
    {prodId:"50466313",label:"Batata o'henry enlatada o envasada"},
    {prodId:"50466314",label:"Batata okinawan enlatada o envasada"},
    {prodId:"50466315",label:"Batata naranja enlatada o envasada"},
    {prodId:"50466316",label:"Batata oriental enlatada o envasada"},
    {prodId:"50466317",label:"Batata jersey rojo enlatada o envasada"},
    {prodId:"50466318",label:"Batata mar rojo enlatada o envasada"},
    {prodId:"50466319",label:"Batata brillo rojo enlatada o envasada"},
    {prodId:"50466320",label:"Batata jersey amarillo enlatada o envasada"},
    {prodId:"50466500",label:"Nabos en lata o en frasco"},
    {prodId:"50466501",label:"Nabo globo verde enlatado o envasado"},
    {prodId:"50466502",label:"Nabo bola dorada enlatado o envasado"},
    {prodId:"50466503",label:"Nabo mercado de manchester enlatado o envasado"},
    {prodId:"50466504",label:"Nabo top morado milan enlatado o envasado"},
    {prodId:"50466505",label:"Nabo top morado blanco enlatado o envasado"},
    {prodId:"50466506",label:"Nabo bola de nieve enlatado o envasado"},
    {prodId:"50466507",label:"Nabo tokio enlatado o envasado"},
    {prodId:"50466508",label:"Nabo cruz de tokio enlatado o envasado"},
    {prodId:"50466600",label:"Auyamas en lata o en frasco"},
    {prodId:"50466601",label:"Calabaza acorn enlatada o envasada"},
    {prodId:"50466602",label:"Calabaza gigante atlántico enlatada o envasada"},
    {prodId:"50466603",label:"Calabaza banana rosado enlatada o envasada"},
    {prodId:"50466604",label:"Calabaza max grande enlatada o envasada"},
    {prodId:"50466605",label:"Calabaza  enlatada o envasada"},
    {prodId:"50466606",label:"Calabaza carnaval enlatada o envasada"},
    {prodId:"50466607",label:"Auyama queso enlatada o envasada"},
    {prodId:"50466608",label:"Calabaza príncipe coronado enlatada o envasada"},
    {prodId:"50466609",label:"Calabaza  curcibita enlatada o envasada"},
    {prodId:"50466610",label:"Calabaza cushaw enlatada o envasada"},
    {prodId:"50466611",label:"Calabaza  gigante auyama enlatada o envasada"},
    {prodId:"50466612",label:"Calabaza  hubbard enlatada o envasada"},
    {prodId:"50466613",label:"Calabaza  jerrahdale enlatada o envasada"},
    {prodId:"50466614",label:"Calabaza  kabocha enlatada o envasada"},
    {prodId:"50466615",label:"Calabaza queensland azul enlatada o envasada"},
    {prodId:"50466616",label:"Calabaza rouge vif détampes enlatada o envasada"},
    {prodId:"50466617",label:"Calabaza  turban turco enlatada o envasada"},
    {prodId:"50466618",label:"Calabaza  valenciano enlatada o envasada"},
    {prodId:"50466619",label:"Calabaza  warted hubbard enlatada o envasada"},
    {prodId:"50466620",label:"Auyama whangaparoa enlatada o envasada"},
    {prodId:"50466700",label:"Ñames en lata o en frasco"},
    {prodId:"50466701",label:"Ñame agria africana enlatada o envasada"},
    {prodId:"50466702",label:"Ñame agria asiática enlatada o envasada"},
    {prodId:"50466703",label:"Ñame china enlatado o envasado"},
    {prodId:"50466704",label:"Ñame globo enlatado o envasado"},
    {prodId:"50466705",label:"Ñame grater enlatado o envasado"},
    {prodId:"50466706",label:"Ñame japonesa enlatado o envasado"},
    {prodId:"50466707",label:"Ñame lesser enlatado o envasado"},
    {prodId:"50466708",label:"Ñame papa enlatado o envasado"},
    {prodId:"50466709",label:"Ñame guinea blanca enlatado o envasado"},
    {prodId:"50466710",label:"Ñame guinea amarillo enlatado o envasado"},
    {prodId:"50466900",label:"Alverjas dulces en lata o en frasco"},
    {prodId:"50466901",label:"Alverja biquini enlatada o envasada"},
    {prodId:"50466902",label:"Alverja cavalier enlatada o envasada"},
    {prodId:"50466903",label:"Alverja margarita enlatada o envasada"},
    {prodId:"50466904",label:"Alverja dar fon enlatada o envasada"},
    {prodId:"50466905",label:"Alverja early onward enlatada o envasada"},
    {prodId:"50466906",label:"Alverja primer feltham enlatada o envasada"},
    {prodId:"50466907",label:"Alverja verde hurst shaft enlatada o envasada"},
    {prodId:"50466908",label:"Guisante oregón sugar pod enlatada o envasada"},
    {prodId:"50466909",label:"Alverja príncipe alberto enlatada o envasada"},
    {prodId:"50466910",label:"Alverja reuzensuiker enlatada o envasada"},
    {prodId:"50471500",label:"Alcachofas orgánicas en lata o en frasco"},
    {prodId:"50471501",label:"Alcachofa brittany orgánica enlatada o envasada"},
    {prodId:"50471502",label:"Alcachofa cantonesa orgánica enlatada o envasada"},
    {prodId:"50471503",label:"Alcachofa francesa orgánica enlatada o envasada"},
    {prodId:"50471504",label:"Alcachofa globo verde orgánica enlatada o envasada"},
    {prodId:"50471505",label:"Alcachofa gros camus de bretaña orgánica enlatada o envasada"},
    {prodId:"50471506",label:"Alcachofa midi orgánica enlatada o envasada"},
    {prodId:"50471507",label:"Alcachofa globo morado orgánica enlatada o envasada"},
    {prodId:"50471508",label:"Alcachofa morado cecilia orgánica enlatada o envasada"},
    {prodId:"50471509",label:"Alcachofa romanesco orgánica enlatada o envasada"},
    {prodId:"50471510",label:"Alcachofa espinoso sardo orgánica enlatada o envasada"},
    {prodId:"50471511",label:"Alcachofa vert de laon orgánica enlatada o envasada"},
    {prodId:"50471512",label:"Alcachofa violeta de chiggiia orgánica enlatada o envasada"},
    {prodId:"50471513",label:"Alcachofa violeta de toscana orgánica enlatada o envasada"},
    {prodId:"50471600",label:"Espárragos orgánicos en lata o en frasco"},
    {prodId:"50471601",label:"Espárragos connovers colosal orgánicos enlatados o envasados"},
    {prodId:"50471602",label:"Espárragos franklin orgánicos enlatados o envasados"},
    {prodId:"50471603",label:"Espárragos mamut gigante orgánicos enlatados o envasados"},
    {prodId:"50471604",label:"Espárragos lucullus orgánicos enlatados o envasados"},
    {prodId:"50471605",label:"Espárragos martha washington orgánicos enlatados o envasados"},
    {prodId:"50471700",label:"Aguacates orgánicos en lata o en frasco"},
    {prodId:"50471701",label:"Aguacate ajax b - 7 orgánico enlatado o envasado"},
    {prodId:"50471702",label:"Aguacate arue orgánico enlatado o envasado"},
    {prodId:"50471703",label:"Aguacate bacon orgánico enlatado o envasado"},
    {prodId:"50471704",label:"Aguacate benik orgánico enlatado o envasado"},
    {prodId:"50471705",label:"Aguacate bernecker orgánico enlatado o envasado"},
    {prodId:"50471706",label:"Aguacate beta orgánico enlatado o envasado"},
    {prodId:"50471707",label:"Aguacate biondo orgánico enlatado o envasado"},
    {prodId:"50471708",label:"Aguacate príncipe negro orgánico enlatado o envasado"},
    {prodId:"50471709",label:"Aguacate blair orgánico enlatado o envasado"},
    {prodId:"50471710",label:"Aguacate blair cabina orgánico enlatado o envasado"},
    {prodId:"50471711",label:"Aguacate cabina 1 orgánico enlatado o envasado"},
    {prodId:"50471712",label:"Aguacate cabina 3 orgánico enlatado o envasado"},
    {prodId:"50471713",label:"Aguacate cabina 5 orgánico enlatado o envasado"},
    {prodId:"50471714",label:"Aguacate cabina 7 orgánico enlatado o envasado"},
    {prodId:"50471715",label:"Aguacate cabina 8 orgánico enlatado o envasado"},
    {prodId:"50471716",label:"Aguacate brooks 1978 orgánico enlatado o envasado"},
    {prodId:"50471717",label:"Aguacate brookslate orgánico enlatado o envasado"},
    {prodId:"50471718",label:"Aguacate california haas orgánico enlatado o envasado"},
    {prodId:"50471719",label:"Aguacate catalina orgánico enlatado o envasado"},
    {prodId:"50471720",label:"Aguacate chica orgánico enlatado o envasado"},
    {prodId:"50471721",label:"Aguacate choquette orgánico enlatado o envasado"},
    {prodId:"50471722",label:"Aguacate cristina orgánico enlatado o envasado"},
    {prodId:"50471723",label:"Aguacate collison orgánico enlatado o envasado"},
    {prodId:"50471724",label:"Aguacate donnie orgánico enlatado o envasado"},
    {prodId:"50471725",label:"Aguacate dr dupis numero 2 orgánico enlatado o envasado"},
    {prodId:"50471726",label:"Aguacate dr. dupie  orgánico enlatado o envasado"},
    {prodId:"50471727",label:"Aguacate ettinger orgánico enlatado o envasado"},
    {prodId:"50471728",label:"Aguacate fuch orgánico enlatado o envasado"},
    {prodId:"50471729",label:"Aguacate fuch gwen orgánico enlatado o envasado"},
    {prodId:"50471730",label:"Aguacate fuerte orgánico enlatado o envasado"},
    {prodId:"50471731",label:"Aguacate gotham orgánico enlatado o envasado"},
    {prodId:"50471732",label:"Aguacate gossman orgánico enlatado o envasado"},
    {prodId:"50471733",label:"Aguacate guatemala sin pepa orgánico enlatado o envasado"},
    {prodId:"50471734",label:"Aguacate hall orgánico enlatado o envasado"},
    {prodId:"50471735",label:"Aguacate hardee orgánico enlatado o envasado"},
    {prodId:"50471736",label:"Aguacate haas orgánico enlatado o envasado"},
    {prodId:"50471737",label:"Aguacate herman orgánico enlatado o envasado"},
    {prodId:"50471738",label:"Aguacate hickson orgánico enlatado o envasado"},
    {prodId:"50471739",label:"Aguacate k-5 orgánico enlatado o envasado"},
    {prodId:"50471740",label:"Aguacate k- 9 orgánico enlatado o envasado"},
    {prodId:"50471741",label:"Aguacate cordero haas orgánico enlatado o envasado"},
    {prodId:"50471742",label:"Aguacate leona orgánico enlatado o envasado"},
    {prodId:"50471743",label:"Aguacate leona linda orgánico enlatado o envasado"},
    {prodId:"50471744",label:"Aguacate lisa p orgánico enlatado o envasado"},
    {prodId:"50471745",label:"Aguacate lisa loretta orgánico enlatado o envasado"},
    {prodId:"50471746",label:"Aguacate loretta orgánico enlatado o envasado"},
    {prodId:"50471747",label:"Aguacate lula orgánico enlatado o envasado"},
    {prodId:"50471748",label:"Aguacate lula mascarthur orgánico enlatado o envasado"},
    {prodId:"50471749",label:"Aguacate marcus orgánico enlatado o envasado"},
    {prodId:"50471750",label:"Aguacate melendez orgánico enlatado o envasado"},
    {prodId:"50471751",label:"Aguacate meya orgánico enlatado o envasado"},
    {prodId:"50471752",label:"Aguacate miguel p orgánico enlatado o envasado"},
    {prodId:"50471753",label:"Aguacate monroe orgánico enlatado o envasado"},
    {prodId:"50471754",label:"Aguacate murrieta verde orgánico enlatado o envasado"},
    {prodId:"50471755",label:"Aguacate nabal orgánico enlatado o envasado"},
    {prodId:"50471756",label:"Aguacate nadir orgánico enlatado o envasado"},
    {prodId:"50471757",label:"Aguacate nesbitt orgánico enlatado o envasado"},
    {prodId:"50471758",label:"Aguacate peterson orgánico enlatado o envasado"},
    {prodId:"50471759",label:"Aguacate pinelli orgánico enlatado o envasado"},
    {prodId:"50471760",label:"Aguacate pinkerton orgánico enlatado o envasado"},
    {prodId:"50471761",label:"Aguacate pollock orgánico enlatado o envasado"},
    {prodId:"50471762",label:"Aguacate puebla orgánico enlatado o envasado"},
    {prodId:"50471763",label:"Aguacate reed orgánico enlatado o envasado"},
    {prodId:"50471764",label:"Aguacaterue orgánico enlatado o envasado"},
    {prodId:"50471765",label:"Aguacateruehle orgánico enlatado o envasado"},
    {prodId:"50471766",label:"Aguacate ryan orgánico enlatado o envasado"},
    {prodId:"50471767",label:"Aguacate semil orgánico enlatado o envasado"},
    {prodId:"50471768",label:"Aguacate semil 43 orgánico enlatado o envasado"},
    {prodId:"50471769",label:"Aguacate simonds orgánico enlatado o envasado"},
    {prodId:"50471770",label:"Aguacate simpson orgánico enlatado o envasado"},
    {prodId:"50471771",label:"Aguacate taylor orgánico enlatado o envasado"},
    {prodId:"50471772",label:"Aguacate tonelada orgánico enlatado o envasado"},
    {prodId:"50471773",label:"Aguacate torre orgánico enlatado o envasado"},
    {prodId:"50471774",label:"Aguacate torre li orgánico enlatado o envasado"},
    {prodId:"50471775",label:"Aguacate trapp orgánico enlatado o envasado"},
    {prodId:"50471776",label:"Aguacate semilla de caribe orgánico enlatado o envasado"},
    {prodId:"50471777",label:"Aguacate wagner orgánico enlatado o envasado"},
    {prodId:"50471778",label:"Aguacate waldin orgánico enlatado o envasado"},
    {prodId:"50471779",label:"Aguacate wurtz orgánico enlatado o envasado"},
    {prodId:"50471780",label:"Aguacate zio p orgánico enlatado o envasado"},
    {prodId:"50471781",label:"Aguacate ziu orgánico enlatado o envasado"},
    {prodId:"50471782",label:"Aguacate zultano orgánico enlatado o envasado"},
    {prodId:"50471800",label:"Fríjoles orgánicos en lata o en frasco"},
    {prodId:"50471801",label:"Frijoles anasazi ® orgánicos enlatados o envasados"},
    {prodId:"50471802",label:"Frijoles appaloosa orgánicos enlatados o envasados"},
    {prodId:"50471803",label:"Frijoles azuki orgánicos enlatados o envasados"},
    {prodId:"50471804",label:"Frijoles barlotti orgánicos enlatados o envasados"},
    {prodId:"50471805",label:"Frijoles appaloosa negra orgánicos enlatados o envasados"},
    {prodId:"50471806",label:"Frijoles negros orgánicos enlatados o envasados"},
    {prodId:"50471807",label:"Frijoles gran negros orgánicos enlatados o envasados"},
    {prodId:"50471808",label:"Frijoles shackamaxon negro orgánicos enlatados o envasados"},
    {prodId:"50471809",label:"Frijoles ojo negro orgánicos enlatados o envasados"},
    {prodId:"50471810",label:"Frijoles bobby orgánicos enlatados o envasados"},
    {prodId:"50471811",label:"Frijoles bolita orgánicos enlatados o envasados"},
    {prodId:"50471812",label:"Frijoles esposa perezosa carmelita orgánicos enlatados o envasados"},
    {prodId:"50471813",label:"Frijoles calipso orgánicos enlatados o envasados"},
    {prodId:"50471814",label:"Frijoles canelini orgánicos enlatados o envasados"},
    {prodId:"50471815",label:"Frijoles castor orgánicos enlatados o envasados"},
    {prodId:"50471816",label:"Frijoles amarillo chino orgánicos enlatados o envasados"},
    {prodId:"50471817",label:"Frijoles lengua de dragón orgánicos enlatados o envasados"},
    {prodId:"50471818",label:"Frijoles soldado europeo orgánicos enlatados o envasados"},
    {prodId:"50471819",label:"Frijoles fava orgánicos enlatados o envasados"},
    {prodId:"50471820",label:"Frijoles flageolet orgánicos enlatados o envasados"},
    {prodId:"50471821",label:"Frijoles  horticultura francesa orgánicos enlatados o envasados"},
    {prodId:"50471822",label:"Frijoles marina francesa orgánicos enlatados o envasados"},
    {prodId:"50471823",label:"Frijoles coco gigante blanco orgánicos enlatados o envasados"},
    {prodId:"50471824",label:"Frijoles verdes orgánicos enlatados o envasados"},
    {prodId:"50471825",label:"Frijoles romano verde orgánicos enlatados o envasados"},
    {prodId:"50471826",label:"Frijoles guar gum orgánicos enlatados o envasados"},
    {prodId:"50471827",label:"Frijoles haricot orgánicos enlatados o envasados"},
    {prodId:"50471828",label:"Frijoles hyacinth orgánicos enlatados o envasados"},
    {prodId:"50471829",label:"Frijoles tipo italiano orgánicos enlatados o envasados"},
    {prodId:"50471830",label:"Frijoles asombro jackson orgánicos enlatados o envasados"},
    {prodId:"50471831",label:"Frijoles ganado jacobs  orgánicos enlatados o envasados"},
    {prodId:"50471832",label:"Frijoles asombro kentucky orgánicos enlatados o envasados"},
    {prodId:"50471833",label:"Frijoles riñón orgánicos enlatados o envasados"},
    {prodId:"50471834",label:"Frijoles lima orgánicos enlatados o envasados"},
    {prodId:"50471835",label:"Frijoles madera orgánicos enlatados o envasados"},
    {prodId:"50471836",label:"Frijoles medula orgánicos enlatados o envasados"},
    {prodId:"50471837",label:"Frijoles mat orgánicos enlatados o envasados"},
    {prodId:"50471838",label:"Frijoles monstoler ganzo salvaje orgánicos enlatados o envasados"},
    {prodId:"50471839",label:"Frijoles  mortgage lifter orgánicos enlatados o envasados"},
    {prodId:"50471840",label:"Frijoles polilla orgánicos enlatados o envasados"},
    {prodId:"50471841",label:"Frijoles mung orgánicos enlatados o envasados"},
    {prodId:"50471842",label:"Frijoles munsi wolf orgánicos enlatados o envasados"},
    {prodId:"50471843",label:"Frijoles nuna orgánicos enlatados o envasados"},
    {prodId:"50471844",label:"Frijoles pinto orgánicos enlatados o envasados"},
    {prodId:"50471845",label:"Frijoles runner orgánicos enlatados o envasados"},
    {prodId:"50471846",label:"String beans orgánicos enlatados o envasados"},
    {prodId:"50471847",label:"Frijoles habichuelas orgánicos enlatados o envasados"},
    {prodId:"50471848",label:"Frijoles tamarind orgánicos enlatados o envasados"},
    {prodId:"50471849",label:"Frijoles tonka orgánicos enlatados o envasados"},
    {prodId:"50471850",label:"Frijoles cera orgánicos enlatados o envasados"},
    {prodId:"50471851",label:"Frijoles winged orgánicos enlatados o envasados"},
    {prodId:"50471852",label:"Frijoles largos orgánicos enlatados o envasados"},
    {prodId:"50471900",label:"Remolachas  orgánicas en lata o en frasco"},
    {prodId:"50471901",label:"Remolacha acción orgánica enlatada o envasada"},
    {prodId:"50471902",label:"Remolacha albina vereduna orgánica enlatada o envasada"},
    {prodId:"50471903",label:"Remolacha barababiotela de chiggia orgánica enlatada o envasada"},
    {prodId:"50471904",label:"Remolacha boltardy orgánica enlatada o envasada"},
    {prodId:"50471905",label:"Remolacha bonel orgánica enlatada o envasada"},
    {prodId:"50471906",label:"Remolacha burpees dorado orgánica enlatada o envasada"},
    {prodId:"50471907",label:"Remolacha cheltenham tapa verde orgánica enlatada o envasada"},
    {prodId:"50471908",label:"Remolacha cheltenham mono orgánica enlatada o envasada"},
    {prodId:"50471909",label:"Remolacha chioggia orgánica enlatada o envasada"},
    {prodId:"50471910",label:"Remolacha cilindra orgánica enlatada o envasada"},
    {prodId:"50471911",label:"Remolacha dégypte orgánica enlatada o envasada"},
    {prodId:"50471912",label:"Remolacha detroit 2 rojo oscuro orgánica enlatada o envasada"},
    {prodId:"50471913",label:"Remolacha detroit 2 bala chiquita orgánica enlatada o envasada"},
    {prodId:"50471914",label:"Remolacha egipcia plana  orgánica enlatada o envasada"},
    {prodId:"50471915",label:"Remolacha  raíz de nabo egipcio orgánica enlatada o envasada"},
    {prodId:"50471916",label:"Remolacha fomanova orgánica enlatada o envasada"},
    {prodId:"50471917",label:"Remolacha forono orgánica enlatada o envasada"},
    {prodId:"50471918",label:"Remolacha monaco orgánica enlatada o envasada"},
    {prodId:"50471919",label:"Remolacha monograma orgánica enlatada o envasada"},
    {prodId:"50471920",label:"Remolacha pronto orgánica enlatada o envasada"},
    {prodId:"50471921",label:"Remolacha regalía orgánica enlatada o envasada"},
    {prodId:"50471922",label:"Remolacha dulce orgánica enlatada o envasada"},
    {prodId:"50472000",label:"Brócolis  orgánicas en lata o en frasco"},
    {prodId:"50472001",label:"Brócolini orgánico enlatado o envasado"},
    {prodId:"50472002",label:"Brócoli romanesco orgánico enlatado o envasado"},
    {prodId:"50472003",label:"Brócoli raab orgánico enlatado o envasado"},
    {prodId:"50472004",label:"Brócoli chino orgánico enlatado o envasado"},
    {prodId:"50472100",label:"Repollitos de Bruselas  orgánicas en lata o en frasco"},
    {prodId:"50472101",label:"Repollitas de bruselas citade orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50472102",label:"Repollitas de bruselas falstaff orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50472103",label:"Repollitas de bruselas oliver orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50472104",label:"Repollitas de bruselas peer gynt orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50472105",label:"Repollitas de bruselas rampart orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50472106",label:"Repollitas de bruselas rubine orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50472107",label:"Repollitas de bruselas  widgeon orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50472200",label:"Cebollas de ensalada  orgánicas en lata o en frasco"},
    {prodId:"50472201",label:"Cebolla belstville ensalada orgánica enlatada o envasada"},
    {prodId:"50472202",label:"Cebolla feast ensalada orgánica enlatada o envasada"},
    {prodId:"50472203",label:"Cebolla ishikura ensalada orgánica enlatada o envasada"},
    {prodId:"50472204",label:"Cebolla mercado kyoto orgánica enlatada o envasada"},
    {prodId:"50472205",label:"Cebolla barba roja ensalada orgánica enlatada o envasada"},
    {prodId:"50472206",label:"Cebolla amigo rojo ensalada orgánica enlatada o envasada"},
    {prodId:"50472207",label:"Cebolla santa claus orgánica enlatada o envasada"},
    {prodId:"50472208",label:"Cebolla tokyo ensalada orgánica enlatada o envasada"},
    {prodId:"50472209",label:"Cebolla lisbon blanca ensalada orgánica enlatada o envasada"},
    {prodId:"50472210",label:"Cebolla invierno blanca ensalada orgánica enlatada o envasada"},
    {prodId:"50472211",label:"Cebolla final de invierno ensalada orgánica enlatada o envasada"},
    {prodId:"50472300",label:"Repollos orgánicos en lata o en frasco"},
    {prodId:"50472301",label:"Repollo negro orgánico enlatado o envasado"},
    {prodId:"50472302",label:"Repollo savoy orgánico enlatado o envasado"},
    {prodId:"50472303",label:"Repollo zorrillo orgánico enlatado o envasado"},
    {prodId:"50472304",label:"Repollo blanco orgánico enlatado o envasado"},
    {prodId:"50472400",label:"Cardos orgánicos en lata o en frasco"},
    {prodId:"50472401",label:"Cardos lunghi orgánicos enlatados o envasados"},
    {prodId:"50472402",label:"Cardos gobbi orgánicos enlatados o envasados"},
    {prodId:"50472500",label:"Zanahorias  orgánicas en lata o en frasco"},
    {prodId:"50472501",label:"Zanahoria amsterdam orgánica enlatada o envasada"},
    {prodId:"50472502",label:"Zanahoria rey de otoño orgánica enlatada o envasada"},
    {prodId:"50472503",label:"Zanahoria berlicum orgánica enlatada o envasada"},
    {prodId:"50472504",label:"Zanahoria chantenay orgánica enlatada o envasada"},
    {prodId:"50472505",label:"Zanahoria nantes orgánica enlatada o envasada"},
    {prodId:"50472506",label:"Zanahoria mercado de parís orgánica enlatada o envasada"},
    {prodId:"50472600",label:"Coliflores  orgánicas en lata o en frasco"},
    {prodId:"50472601",label:"Coliflor todo el año  orgánica enlatada o envasada"},
    {prodId:"50472602",label:"Coliflor alverda orgánica enlatada o envasada"},
    {prodId:"50472603",label:"coliflor otoño gigante 3 orgánica enlatada o envasada"},
    {prodId:"50472604",label:"Coliflo rdok elgon orgánica enlatada o envasada"},
    {prodId:"50472605",label:"Coliflor bola de nieve temprana orgánica enlatada o envasada"},
    {prodId:"50472606",label:"Coliflor luz de lima orgánica enlatada o envasada"},
    {prodId:"50472607",label:"Coliflor minaret orgánica enlatada o envasada"},
    {prodId:"50472608",label:"Coliflor buque de naranja orgánica enlatada o envasada"},
    {prodId:"50472609",label:"Coliflor capa morada orgánica enlatada o envasada"},
    {prodId:"50472610",label:"Coliflor bola de nieve orgánica enlatada o envasada"},
    {prodId:"50472611",label:"Coliflor invierno walcheren 3 orgánica enlatada o envasada"},
    {prodId:"50472612",label:"Coliflor piedra blanca orgánica enlatada o envasada"},
    {prodId:"50472700",label:"Apios orgánicos en lata o en frasco"},
    {prodId:"50472701",label:"Apio celebridad orgánico enlatado o envasado"},
    {prodId:"50472702",label:"Apio nabo orgánico enlatado o envasado"},
    {prodId:"50472703",label:"Apio chino orgánico enlatado o envasado"},
    {prodId:"50472704",label:"Apio dinant francés orgánico enlatado o envasado"},
    {prodId:"50472705",label:"Apio gigante rosado orgánico enlatado o envasado"},
    {prodId:"50472706",label:"Apio gigante rojo orgánico enlatado o envasado"},
    {prodId:"50472707",label:"Apio gigante blanco orgánico enlatado o envasado"},
    {prodId:"50472708",label:"Apio dorado auto escalado orgánico enlatado o envasado"},
    {prodId:"50472709",label:"Apio greensleeves orgánico enlatado o envasado"},
    {prodId:"50472710",label:"Apio hopkins fenlander orgánico enlatado o envasado"},
    {prodId:"50472711",label:"Apio torre marfil orgánico enlatado o envasado"},
    {prodId:"50472712",label:"Apio lathom blanqueado orgánico enlatado o envasado"},
    {prodId:"50472713",label:"Apio sopa de amsterdam orgánico enlatado o envasado"},
    {prodId:"50472714",label:"Apio estándar cargado orgánico enlatado o envasado"},
    {prodId:"50472715",label:"Apio triunfo alto utah orgánico enlatado o envasado"},
    {prodId:"50472800",label:"Acelgas  orgánicas en lata o en frasco"},
    {prodId:"50472801",label:"Acelga luz brillante orgánica enlatada o envasada"},
    {prodId:"50472802",label:"Acelga gigante fordhook orgánica enlatada o envasada"},
    {prodId:"50472803",label:"Acelga luculus orgánica enlatada o envasada"},
    {prodId:"50472804",label:"Acelga espinaca perpetua orgánica enlatada o envasada"},
    {prodId:"50472805",label:"Acelga ruibarbo orgánica enlatada o envasada"},
    {prodId:"50472806",label:"Acelga suizo orgánica enlatada o envasada"},
    {prodId:"50472807",label:"Acelga volcán  orgánica enlatada o envasada"},
    {prodId:"50472808",label:"Acelga rey blanco orgánica enlatada o envasada"},
    {prodId:"50472900",label:"Achicorias orgánicas en lata o en frasco"},
    {prodId:"50472901",label:"Achicoria batavian hoja ancha orgánica enlatada o envasada"},
    {prodId:"50472902",label:"Achicoria en cornet de bordeaux orgánica enlatada o envasada"},
    {prodId:"50472903",label:"Achicoria rollo verde ruffee orgánica enlatada o envasada"},
    {prodId:"50472904",label:"Achicoria roll verde orgánica enlatada o envasada"},
    {prodId:"50472905",label:"Achicoria limnoe lone orgánica enlatada o envasada"},
    {prodId:"50472906",label:"Achicoria pancalieri riccia orgánica enlatada o envasada"},
    {prodId:"50472907",label:"Achicoria ensalada rey orgánica enlatada o envasada"},
    {prodId:"50472908",label:"Achicoria sanda orgánica enlatada o envasada"},
    {prodId:"50472909",label:"Achicoria scarola verde orgánica enlatada o envasada"},
    {prodId:"50472910",label:"Achicoria tres fine maraichere orgánica enlatada o envasada"},
    {prodId:"50472911",label:"Achicoria wallone freisee wescelkkopf orgánica enlatada o envasada"},
    {prodId:"50473000",label:"Repollos chinos orgánicos en lata o en frasco"},
    {prodId:"50473001",label:"Bok choy orgánico enlatado o envasado"},
    {prodId:"50473002",label:"Bok choy enano orgánico enlatado o envasado"},
    {prodId:"50473003",label:"Flor de repollo chino orgánica enlatada o envasada"},
    {prodId:"50473004",label:"Choy sum orgánico enlatado o envasado"},
    {prodId:"50473005",label:"Col (bok choy) enano orgánico enlatado o envasado"},
    {prodId:"50473006",label:"Col (bok choy) fengshan orgánico enlatado o envasado"},
    {prodId:"50473007",label:"Col (bok choy) jade pagoda orgánico enlatado o envasado"},
    {prodId:"50473008",label:"Col (bok choy) kasumi orgánico enlatado o envasado"},
    {prodId:"50473009",label:"col (bok choy)  nerva orgánico enlatado o envasado"},
    {prodId:"50473010",label:"Col (bok choy) rosette orgánico enlatado o envasado"},
    {prodId:"50473011",label:"Col (bok choy) ruffles orgánico enlatado o envasado"},
    {prodId:"50473012",label:"Col (bok choy) hoja  santo serrated orgánico enlatado o envasado"},
    {prodId:"50473013",label:"Col (bok choy) shangahai orgánico enlatado o envasado"},
    {prodId:"50473014",label:"Shantung orgánico enlatado o envasado"},
    {prodId:"50473015",label:"Repollo tip top orgánico enlatado o envasado"},
    {prodId:"50473016",label:"Yau choy sum orgánico enlatado o envasado"},
    {prodId:"50473100",label:"Cebollines orgánicos en lata o en frasco"},
    {prodId:"50473101",label:"Cebollín chino orgánico enlatado o envasado"},
    {prodId:"50473225",label:"Maíz  miel y crema orgánico enlatado o envasado"},
    {prodId:"50473300",label:"Berros  orgánicos en lata o en frasco"},
    {prodId:"50473301",label:"Berros de tierra orgánicos enlatados o envasados"},
    {prodId:"50473302",label:"Nasturtium orgánico enlatado o envasado"},
    {prodId:"50473303",label:"Berros de agua orgánicos enlatados o envasados"},
    {prodId:"50473304",label:"Berros de invierno orgánicos enlatados o envasados"},
    {prodId:"50473400",label:"Cocombros orgánicos en lata o en frasco"},
    {prodId:"50473401",label:"Cocombro arena orgánico enlatado o envasado"},
    {prodId:"50473402",label:"Cocombro americano orgánico enlatado o envasado"},
    {prodId:"50473403",label:"Cocombro atena orgánico enlatado o envasado"},
    {prodId:"50473404",label:"Cocombro blanco lungo de parigi orgánico enlatado o envasado"},
    {prodId:"50473405",label:"Cocombro verde burpless buen sabor orgánico enlatado o envasado"},
    {prodId:"50473406",label:"Cocombro chicago pickling orgánico enlatado o envasado"},
    {prodId:"50473407",label:"Cocombro manzana cristal orgánico enlatado o envasado"},
    {prodId:"50473408",label:"Cocombro limón cristal orgánico enlatado o envasado"},
    {prodId:"50473409",label:"Cocombro danimas orgánico enlatado o envasado"},
    {prodId:"50473410",label:"Cocombro gherkin orgánico enlatado o envasado"},
    {prodId:"50473411",label:"Cocombro hokus orgánico enlatado o envasado"},
    {prodId:"50473412",label:"Cocombro japonés orgánico enlatado o envasado"},
    {prodId:"50473413",label:"Cocombro karela orgánico enlatado o envasado"},
    {prodId:"50473414",label:"Cocombro korila orgánico enlatado o envasado"},
    {prodId:"50473415",label:"Cocombro verde largo mejorado orgánico enlatado o envasado"},
    {prodId:"50473416",label:"Cocombro mas mercado orgánico enlatado o envasado"},
    {prodId:"50473417",label:"Cocombro enano orgánico enlatado o envasado"},
    {prodId:"50473418",label:"Cocombro pickling nacional orgánico enlatado o envasado"},
    {prodId:"50473419",label:"Cocombro persa orgánico enlatado o envasado"},
    {prodId:"50473420",label:"Cocombro telégrafo orgánico enlatado o envasado"},
    {prodId:"50473421",label:"Cocombro telégrafo mejorado orgánico enlatado o envasado"},
    {prodId:"50473422",label:"Cocombro vert de massy cornichon orgánico enlatado o envasado"},
    {prodId:"50473423",label:"Cocombro yamato orgánico enlatado o envasado"},
    {prodId:"50473500",label:"Berenjenas orgánicas en lata o en frasco"},
    {prodId:"50473501",label:"Berenjena bambino orgánica enlatada o envasada"},
    {prodId:"50473502",label:"Berenjena  belleza negra orgánica enlatada o envasada"},
    {prodId:"50473503",label:"Berenjena  negra enorme orgánica enlatada o envasada"},
    {prodId:"50473504",label:"Berenjena  chino orgánica enlatada o envasada"},
    {prodId:"50473505",label:"Berenjena  huevo de pascua orgánica enlatada o envasada"},
    {prodId:"50473506",label:"Berenjena  filipino orgánica enlatada o envasada"},
    {prodId:"50473507",label:"Berenjena   mercado de florida orgánica enlatada o envasada"},
    {prodId:"50473508",label:"Berenjena  india orgánica enlatada o envasada"},
    {prodId:"50473509",label:"Berenjena  italiano orgánica enlatada o envasada"},
    {prodId:"50473510",label:"Berenjena  japonés orgánica enlatada o envasada"},
    {prodId:"50473511",label:"Berenjena  morado largo orgánica enlatada o envasada"},
    {prodId:"50473512",label:"Berenjena   rayas larga orgánica enlatada o envasada"},
    {prodId:"50473513",label:"Berenjena  mercado de dinero orgánica enlatada o envasada"},
    {prodId:"50473514",label:"Berenjena  ova orgánica enlatada o envasada"},
    {prodId:"50473515",label:"Berenjena   arveja orgánica enlatada o envasada"},
    {prodId:"50473516",label:"Berenjena  tom bajito orgánica enlatada o envasada"},
    {prodId:"50473517",label:"Berenjena  siciliano orgánica enlatada o envasada"},
    {prodId:"50473518",label:"Berenjena  tailandés orgánica enlatada o envasada"},
    {prodId:"50473519",label:"Berenjena  violeta de florencia orgánica enlatada o envasada"},
    {prodId:"50473520",label:"Berenjena  blanco orgánica enlatada o envasada"},
    {prodId:"50473600",label:"Endivias orgánicas en lata o en frasco"},
    {prodId:"50473601",label:"Endivias witloof de brucelas orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473602",label:"Endivias francocastel orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473603",label:"Endivias catalogna de galantina orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473604",label:"Endivias chioggia orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473605",label:"Endivias grumolo verde orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473606",label:"Endivias raíz larga magdeburg orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473607",label:"Endivias palla rosa zorzi precoce orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473608",label:"Endivias radice amare orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473609",label:"Endivias rosa de traviso orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473610",label:"Endivias  rosa de verona orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473611",label:"Endivias soncino orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473612",label:"Endivias sugarthi orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473613",label:"Endivias verona orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473614",label:"Endivias witloof zoom orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50473700",label:"Hinojos orgánicos en lata o en frasco"},
    {prodId:"50473701",label:"Hinojo cantino orgánico enlatado o envasado"},
    {prodId:"50473702",label:"Hinojo fino orgánico enlatado o envasado"},
    {prodId:"50473703",label:"Hinojoherald orgánico enlatado o envasado"},
    {prodId:"50473704",label:"Hinojo perfección orgánico enlatado o envasado"},
    {prodId:"50473705",label:"Hinojo sirio orgánico enlatado o envasado"},
    {prodId:"50473706",label:"Hinojo florencia dulce orgánico enlatado o envasado"},
    {prodId:"50473707",label:"Hinojo tardo orgánico enlatado o envasado"},
    {prodId:"50473800",label:"Ajos orgánicos en lata o en frasco"},
    {prodId:"50473801",label:"Ajo tarde california orgánico enlatado o envasado"},
    {prodId:"50473802",label:"Ajo tallos chinos orgánico enlatado o envasado"},
    {prodId:"50473803",label:"Ajo cebollin  orgánico enlatado o envasado"},
    {prodId:"50473804",label:"Ajo germidor orgánico enlatado o envasado"},
    {prodId:"50473805",label:"Ajo guardado largo orgánico enlatado o envasado"},
    {prodId:"50473806",label:"Ajo ramson orgánico enlatado o envasado"},
    {prodId:"50473807",label:"Ajo rocambola orgánico enlatado o envasado"},
    {prodId:"50473808",label:"Ajo rosa de lautrec orgánico enlatado o envasado"},
    {prodId:"50473809",label:"Ajo solent blanco orgánico enlatado o envasado"},
    {prodId:"50473810",label:"Ajo morado español orgánico enlatado o envasado"},
    {prodId:"50473811",label:"Ajo valentina italiana orgánico enlatado o envasado"},
    {prodId:"50473900",label:"Calabazas orgánicas en lata o en frasco"},
    {prodId:"50473901",label:"Petula orgánica enlatada o envasada"},
    {prodId:"50473902",label:"Melon amargo orgánico enlatado o envasado"},
    {prodId:"50473903",label:"Botella calabaza orgánica enlatada o envasada"},
    {prodId:"50473904",label:"Calabash gounds orgánica enlatada o envasada"},
    {prodId:"50473905",label:"Melón peludo orgánico enlatado o envasado"},
    {prodId:"50473906",label:"Calabaza amarga orgánica enlatada o envasada"},
    {prodId:"50473907",label:"Loofah suave orgánico enlatado o envasado"},
    {prodId:"50473908",label:"Serpiente de calabaza orgánica enlatada o envasada"},
    {prodId:"50473909",label:"Calabaza larga amarga orgánica enlatada o envasada"},
    {prodId:"50473910",label:"Calabaza tinda orgánica enlatada o envasada"},
    {prodId:"50473911",label:"Calabaza tindoori orgánica enlatada o envasada"},
    {prodId:"50474000",label:"Guisantes orgánicas en lata o en frasco"},
    {prodId:"50474001",label:"Guisante chino orgánica enlatada o envasada"},
    {prodId:"50474002",label:"Guisante inglés orgánica enlatada o envasada"},
    {prodId:"50474003",label:"Guisante de jardín orgánica enlatada o envasada"},
    {prodId:"50474004",label:"Guisante nieve orgánica enlatada o envasada"},
    {prodId:"50474005",label:"Guisante snap dulce orgánica enlatada o envasada"},
    {prodId:"50474100",label:"Hierbas orgánicas en lata o en frasco"},
    {prodId:"50474101",label:"Albahaca orgánica enlatada o envasada"},
    {prodId:"50474102",label:"Laurel orgánico enlatado o envasado"},
    {prodId:"50474103",label:"Borraja orgánica enlatada o envasada"},
    {prodId:"50474104",label:"Alcaravea orgánica enlatada o envasada"},
    {prodId:"50474105",label:"Perifollo orgánico enlatado o envasado"},
    {prodId:"50474106",label:"Cilantro orgánico enlatado o envasado"},
    {prodId:"50474107",label:"Chipolinos orgánico enlatado o envasado"},
    {prodId:"50474108",label:"Hoja de curry orgánica enlatada o envasada"},
    {prodId:"50474109",label:"Eneldo orgánico enlatado o envasado"},
    {prodId:"50474110",label:"Epazote orgánico enlatado o envasado"},
    {prodId:"50474111",label:"Fenogreco orgánico enlatado o envasado"},
    {prodId:"50474112",label:"Limonaria orgánica enlatada o envasada"},
    {prodId:"50474113",label:"Mejorana orgánica enlatada o envasada"},
    {prodId:"50474114",label:"Menta orgánica enlatada o envasada"},
    {prodId:"50474115",label:"Orégano orgánico enlatado o envasado"},
    {prodId:"50474116",label:"Pápalo orgánico enlatado o envasado"},
    {prodId:"50474117",label:"Pápalo delgado orgánico enlatado o envasado"},
    {prodId:"50474118",label:"Perilla orgánica enlatada o envasada"},
    {prodId:"50474119",label:"Recao  orgánico enlatado o envasado"},
    {prodId:"50474120",label:"Romero orgánico enlatado o envasado"},
    {prodId:"50474121",label:"Salvia orgánica enlatada o envasada"},
    {prodId:"50474122",label:"Salsifí orgánica enlatada o envasada"},
    {prodId:"50474123",label:"Satureja orgánica enlatada o envasada"},
    {prodId:"50474124",label:"Estragón orgánico enlatado o envasado"},
    {prodId:"50474125",label:"Tomillo orgánico enlatado o envasado"},
    {prodId:"50474126",label:"Tumeric orgánico enlatado o envasado"},
    {prodId:"50474127",label:"Verdolaga orgánica enlatada o envasada"},
    {prodId:"50474200",label:"Coles orgánicas en lata o en frasco"},
    {prodId:"50474201",label:"Col rizada orgánica enlatada o envasada"},
    {prodId:"50474202",label:"Repollo verde orgánico enlatado o envasado"},
    {prodId:"50474300",label:"Colinabos orgánicos en lata o en frasco"},
    {prodId:"50474301",label:"Colinabo estrella azur orgánico enlatado o envasado"},
    {prodId:"50474302",label:"Colinabo verde viena orgánico enlatado o envasado"},
    {prodId:"50474303",label:"Colinabo lanro orgánico enlatado o envasado"},
    {prodId:"50474304",label:"Colinabo viena morada orgánico enlatado o envasado"},
    {prodId:"50474305",label:"Colinabo trero rowel orgánico enlatado o envasado"},
    {prodId:"50474306",label:"Colinabo viena blanca orgánico enlatado o envasado"},
    {prodId:"50474400",label:"Puerros orgánicos en lata o en frasco"},
    {prodId:"50474401",label:"Puerro cobra gigante otoño orgánico enlatado o envasado"},
    {prodId:"50474402",label:"Puerro otoño mamut 2 orgánico enlatado o envasado"},
    {prodId:"50474403",label:"Puerro blue de solaise orgánico enlatado o envasado"},
    {prodId:"50474404",label:"Puerro cortina orgánico enlatado o envasado"},
    {prodId:"50474405",label:"Puerro prelina orgánico enlatado o envasado"},
    {prodId:"50474406",label:"Puerro salvaje orgánico enlatado o envasado"},
    {prodId:"50474500",label:"Lentejas  orgánicas en lata o en frasco"},
    {prodId:"50474501",label:"Lenteja beluga orgánica enlatada o envasada"},
    {prodId:"50474502",label:"Lenteja  verde francesa orgánica enlatada o envasada"},
    {prodId:"50474503",label:"Lenteja verde orgánica enlatada o envasada"},
    {prodId:"50474504",label:"Lenteja crimson pequeña orgánica enlatada o envasada"},
    {prodId:"50474505",label:"Lenteja pardina española orgánica enlatada o envasada"},
    {prodId:"50474506",label:"Lenteja roja separada orgánica enlatada o envasada"},
    {prodId:"50474507",label:"Lenteja amarilla separad orgánica enlatada o envasada"},
    {prodId:"50474508",label:"Lenteja tarahumara rosada orgánica enlatada o envasada"},
    {prodId:"50474600",label:"Lechugas orgánicas en lata o en frasco"},
    {prodId:"50474601",label:"Lechuga  bibb orgánica enlatada o envasada"},
    {prodId:"50474602",label:"Lechuga  boston orgánica enlatada o envasada"},
    {prodId:"50474603",label:"Lechuga frisee orgánica enlatada o envasada"},
    {prodId:"50474604",label:"Lechuga rosa lola orgánica enlatada o envasada"},
    {prodId:"50474605",label:"Lechuga  mixta masculina orgánica enlatada o envasada"},
    {prodId:"50474606",label:"Lechuga mizuna orgánica enlatada o envasada"},
    {prodId:"50474607",label:"Lechuga hoja roja orgánica enlatada o envasada"},
    {prodId:"50474608",label:"Lechuga oja roja roble orgánica enlatada o envasada"},
    {prodId:"50474609",label:"Lechuga romana ruby orgánica enlatada o envasada"},
    {prodId:"50474610",label:"Lechuga romana bebé roja orgánica enlatada o envasada"},
    {prodId:"50474611",label:"Lechuga cabeza de mantequilla orgánica enlatada o envasada"},
    {prodId:"50474612",label:"Lechuga china orgánica enlatada o envasada"},
    {prodId:"50474613",label:"Lechuga cabeza tostada orgánica enlatada o envasada"},
    {prodId:"50474614",label:"Lechuga hoja verde orgánica enlatada o envasada"},
    {prodId:"50474615",label:"Lechuga iceberg orgánica enlatada o envasada"},
    {prodId:"50474616",label:"Lechuga cordero orgánica enlatada o envasada"},
    {prodId:"50474617",label:"Lechuga hoja suelta orgánica enlatada o envasada"},
    {prodId:"50474618",label:"Lechuga mache orgánica enlatada o envasada"},
    {prodId:"50474619",label:"Lechuga boston roja orgánica enlatada o envasada"},
    {prodId:"50474620",label:"Lechuga cabeza roja orgánica enlatada o envasada"},
    {prodId:"50474621",label:"Lechuga romana orgánica enlatada o envasada"},
    {prodId:"50474622",label:"Lechuga mostza roja rusa orgánica enlatada o envasada"},
    {prodId:"50474623",label:"Lechuga tatsoi orgánica enlatada o envasada"},
    {prodId:"50474700",label:"Ñames orgánicos en lata o en frasco"},
    {prodId:"50474701",label:"Ñame amarilla orgánica enlatada o envasada"},
    {prodId:"50474702",label:"Ñame blanca orgánica enlatada o envasada"},
    {prodId:"50474703",label:"Ñame coco orgánica enlatada o envasada"},
    {prodId:"50474704",label:"Ñame eddoes orgánica enlatada o envasada"},
    {prodId:"50474705",label:"Ñame isleña orgánica enlatada o envasada"},
    {prodId:"50474706",label:"Ñame lila orgánica enlatada o envasada"},
    {prodId:"50474800",label:"Hongos o champiñones orgánicos en lata o en frasco"},
    {prodId:"50474801",label:"Champiñón trompeta negra orgánico enlatado o envasado"},
    {prodId:"50474802",label:"Champiñón carmelito orgánico enlatado o envasado"},
    {prodId:"50474803",label:"Champiñón  orgánico enlatado o envasado"},
    {prodId:"50474804",label:"Champiñón chanterelle orgánico enlatado o envasado"},
    {prodId:"50474805",label:"Champiñón cremini orgánico enlatado o envasado"},
    {prodId:"50474806",label:"Champiñón enoki orgánico enlatado o envasado"},
    {prodId:"50474807",label:"Champiñón puercoespín orgánico enlatado o envasado"},
    {prodId:"50474808",label:"Champiñón gallina del bosque orgánico enlatado o envasado"},
    {prodId:"50474809",label:"Champiñón langosta orgánico enlatado o envasado"},
    {prodId:"50474810",label:"Champiñón morels orgánico enlatado o envasado"},
    {prodId:"50474811",label:"Champiñón ostra orgánico enlatado o envasado"},
    {prodId:"50474812",label:"Champiñón pleurotus orgánico enlatado o envasado"},
    {prodId:"50474813",label:"Champiñón pompom orgánico enlatado o envasado"},
    {prodId:"50474814",label:"Champiñón porcieni orgánico enlatado o envasado"},
    {prodId:"50474815",label:"Champiñón portobello orgánico enlatado o envasado"},
    {prodId:"50474816",label:"Champiñón shitake orgánico enlatado o envasado"},
    {prodId:"50474817",label:"Champiñón shimeji orgánico enlatado o envasado"},
    {prodId:"50474818",label:"Champiñón san jorge orgánico enlatado o envasado"},
    {prodId:"50474819",label:"Champiñón blanco orgánico enlatado o envasado"},
    {prodId:"50474820",label:"Champiñón tompeta blanca orgánico enlatado o envasado"},
    {prodId:"50474821",label:"Champiñón woodear orgánico enlatado o envasado"},
    {prodId:"50474900",label:"Mostazas orgánicas en lata o en frasco"},
    {prodId:"50474901",label:"Mostaza bambú  orgánica enlatada o envasada"},
    {prodId:"50474902",label:"Mostaza ajo orgánica enlatada o envasada"},
    {prodId:"50474903",label:"Mostaza hoja gigante orgánica enlatada o envasada"},
    {prodId:"50474904",label:"Mostaza rojo en nieve orgánica enlatada o envasada"},
    {prodId:"50474905",label:"Mostaza sureño orgánica enlatada o envasada"},
    {prodId:"50474906",label:"Mostaza corazón envuelto orgánica enlatada o envasada"},
    {prodId:"50475000",label:"Solanáceas (nightshades) orgánicas en lata o en frasco"},
    {prodId:"50475001",label:"Lámpara china orgánica enlatada o envasada"},
    {prodId:"50475002",label:"Mora de jardín"},
    {prodId:"50475003",label:"Naranjilla orgánica orgánicas enlatadas o envasadas"},
    {prodId:"50475004",label:"Tomatillo orgánica enlatada o envasada"},
    {prodId:"50475100",label:"Okras orgánicas en lata o en frasco"},
    {prodId:"50475101",label:"Okra artista orgánica enlatada o envasada"},
    {prodId:"50475102",label:"Okra vinotinto orgánica enlatada o envasada"},
    {prodId:"50475103",label:"Okra sin columna clemson orgánica enlatada o envasada"},
    {prodId:"50475104",label:"Okra verde enano vaina larga orgánica enlatada o envasada"},
    {prodId:"50475105",label:"Okra  mamut sin columna orgánica enlatada o envasada"},
    {prodId:"50475106",label:"Okra terciopelo rojo orgánica enlatada o envasada"},
    {prodId:"50475107",label:"Okra estrella de david reliquia orgánica enlatada o envasada"},
    {prodId:"50475200",label:"Cebollas orgánicas en lata o en frasco"},
    {prodId:"50475201",label:"Cebolla albion orgánica enlatada o envasada"},
    {prodId:"50475202",label:"Cebolla alisia craig orgánica enlatada o envasada"},
    {prodId:"50475203",label:"Cebolla hirviendo orgánica enlatada o envasada"},
    {prodId:"50475204",label:"Cebolla bufalo orgánica enlatada o envasada"},
    {prodId:"50475205",label:"Cebolla bulbo orgánica enlatada o envasada"},
    {prodId:"50475206",label:"Cebolla cremosa orgánica enlatada o envasada"},
    {prodId:"50475207",label:"Cebolla amarillo expreso o - x orgánica enlatada o envasada"},
    {prodId:"50475208",label:"Cebolla kelsae orgánica enlatada o envasada"},
    {prodId:"50475209",label:"Cebolla gigante marshal globo fen orgánica enlatada o envasada"},
    {prodId:"50475210",label:"Cebolla perla orgánica enlatada o envasada"},
    {prodId:"50475211",label:"Cebolla baron rojo orgánica enlatada o envasada"},
    {prodId:"50475212",label:"Cebolla roja orgánica enlatada o envasada"},
    {prodId:"50475213",label:"Cebolla rijnsberger orgánica enlatada o envasada"},
    {prodId:"50475214",label:"Cebolla senshyu semi - globo amarillo orgánica enlatada o envasada"},
    {prodId:"50475215",label:"Cebolla sturon orgánica enlatada o envasada"},
    {prodId:"50475216",label:"Cebolla sturgatter orgánica enlatada o envasada"},
    {prodId:"50475217",label:"Cebolla dulce orgánica enlatada o envasada"},
    {prodId:"50475218",label:"Cebolla torpedo o rojo italiana orgánica enlatada o envasada"},
    {prodId:"50475219",label:"Cebolla roja guardada orgánica enlatada o envasada"},
    {prodId:"50475220",label:"Cebolla blanca guardada orgánica enlatada o envasada"},
    {prodId:"50475221",label:"Cebolla amarilla guardada orgánica enlatada o envasada"},
    {prodId:"50475300",label:"Maníes orgánicos en lata o en frasco"},
    {prodId:"50475301",label:"Maní bambara orgánico enlatado o envasado"},
    {prodId:"50475302",label:"Maní florunner orgánico enlatado o envasado"},
    {prodId:"50475303",label:"Maní husa / kerstings orgánico enlatado o envasado"},
    {prodId:"50475304",label:"Maní español orgánico enlatado o envasado"},
    {prodId:"50475305",label:"Maní valencia orgánico enlatado o envasado"},
    {prodId:"50475306",label:"Maní virginia orgánico enlatado o envasado"},
    {prodId:"50475400",label:"Alverjas orgánicas en lata o en frasco"},
    {prodId:"50475401",label:"Alverja casco morado orgánico enlatada o encasada"},
    {prodId:"50475402",label:"Alverja pinkeye orgánica enlatada o envasada"},
    {prodId:"50475403",label:"Alverja crowder orgánica enlatada o envasada"},
    {prodId:"50475404",label:"Alverja acre blanca  orgánica enlatada o envasada"},
    {prodId:"50475405",label:"Alverja ojo negro orgánica enlatada o envasada"},
    {prodId:"50475406",label:"Alverja crema zipper orgánica enlatada o envasada"},
    {prodId:"50475500",label:"Pimientos orgánicos en lata o en frasco"},
    {prodId:"50475501",label:"Pimentón ají orgánico enlatado o envasado"},
    {prodId:"50475502",label:"Pimentón  árbol orgánico enlatado o envasado"},
    {prodId:"50475503",label:"Pimentón queso orgánico enlatado o envasado"},
    {prodId:"50475504",label:"Pimentón chilaca orgánico enlatado o envasado"},
    {prodId:"50475505",label:"Pimentón cubanelles orgánico enlatado o envasado"},
    {prodId:"50475506",label:"Pimentón fresno orgánico enlatado o envasado"},
    {prodId:"50475507",label:"Pimentón kapia orgánico enlatado o envasado"},
    {prodId:"50475508",label:"Pimentón coreano orgánico enlatado o envasado"},
    {prodId:"50475509",label:"Pimentón manzano orgánico enlatado o envasado"},
    {prodId:"50475510",label:"Pimentón melrose orgánico enlatado o envasado"},
    {prodId:"50475511",label:"Pimentón chile amarillo orgánico enlatado o envasado"},
    {prodId:"50475512",label:"Pimentón aji dulce orgánico enlatado o envasado"},
    {prodId:"50475513",label:"Pimentón anaheim orgánico enlatado o envasado"},
    {prodId:"50475514",label:"Pimentón ancho orgánico enlatado o envasado"},
    {prodId:"50475515",label:"Pimentón campana orgánico enlatado o envasado"},
    {prodId:"50475516",label:"Pimentón cascabel orgánico enlatado o envasado"},
    {prodId:"50475517",label:"Pimentón cayenne orgánico enlatado o envasado"},
    {prodId:"50475518",label:"Pimentón anfitrión cereza orgánico enlatado o envasado"},
    {prodId:"50475519",label:"Pimentón chitespin orgánico enlatado o envasado"},
    {prodId:"50475520",label:"Pimentón dedo picante orgánico enlatado o envasado"},
    {prodId:"50475521",label:"Pimentón guajillo orgánico enlatado o envasado"},
    {prodId:"50475522",label:"Pimentón guerro orgánico enlatado o envasado"},
    {prodId:"50475523",label:"Pimentón habanero orgánico enlatado o envasado"},
    {prodId:"50475524",label:"Pimentón cera de hungria orgánico enlatado o envasado"},
    {prodId:"50475526",label:"Pimentón picante largo orgánico enlatado o envasado"},
    {prodId:"50475527",label:"Pimentón marasol orgánico enlatado o envasado"},
    {prodId:"50475528",label:"Pimentón pasilla orgánico enlatado o envasado"},
    {prodId:"50475529",label:"Pimentón peperoncini orgánico enlatado o envasado"},
    {prodId:"50475530",label:"Pimentón pequin orgánico enlatado o envasado"},
    {prodId:"50475531",label:"Pimentón pimiento orgánico enlatado o envasado"},
    {prodId:"50475532",label:"Pimentón poblano orgánico enlatado o envasado"},
    {prodId:"50475533",label:"Pimentón scotch bonnet orgánico enlatado o envasado"},
    {prodId:"50475534",label:"Pimentón serrano orgánico enlatado o envasado"},
    {prodId:"50475535",label:"Pimentón tabasco orgánico enlatado o envasado"},
    {prodId:"50475536",label:"Pimentón tai  orgánico enlatado o envasado"},
    {prodId:"50475537",label:"Pimentón tepin orgánico enlatado o envasado"},
    {prodId:"50475700",label:"Nabos suecos orgánicos en lata o en frasco"},
    {prodId:"50475701",label:"Nabo sueco acme orgánico enlatado o envasado"},
    {prodId:"50475702",label:"Nabo sueco angela orgánico enlatado o envasado"},
    {prodId:"50475703",label:"Nabo sueco el mejor orgánico enlatado o envasado"},
    {prodId:"50475704",label:"Nabo sueco marian orgánico enlatado o envasado"},
    {prodId:"50475900",label:"Chalotes orgánicos en lata o en frasco"},
    {prodId:"50475901",label:"Chalote atlántica orgánico enlatado o envasado"},
    {prodId:"50475902",label:"Chalote creación orgánico enlatado o envasado"},
    {prodId:"50475903",label:"Chalote drittler nido blanco orgánico enlatado o envasado"},
    {prodId:"50475904",label:"Chalote gigante mejorado amarillo orgánico enlatado o envasado"},
    {prodId:"50475905",label:"Chalote gourmet dorado orgánico enlatado o envasado"},
    {prodId:"50475906",label:"Chalote grise de bagnolet orgánico enlatado o envasado"},
    {prodId:"50475907",label:"Chalote hative de nort orgánico enlatado o envasado"},
    {prodId:"50475908",label:"Chalote pikant orgánico enlatado o envasado"},
    {prodId:"50475909",label:"Chalote papa roja orgánico enlatado o envasado"},
    {prodId:"50475910",label:"Chalote sante orgánico enlatado o envasado"},
    {prodId:"50475911",label:"Chalote topper orgánico enlatado o envasado"},
    {prodId:"50476000",label:"Alazanes orgánicos en lata o en frasco"},
    {prodId:"50476001",label:"Alazán dock orgánica enlatada o envasada"},
    {prodId:"50476002",label:"Alazán jardín orgánica enlatada o envasada"},
    {prodId:"50476003",label:"Alazán oveja orgánica enlatada o envasada"},
    {prodId:"50476004",label:"Alazán madera orgánica enlatada o envasada"},
    {prodId:"50476100",label:"Espinacas orgánicas en lata o en frasco"},
    {prodId:"50476101",label:"Espinaca americana orgánica enlatada o envasada"},
    {prodId:"50476102",label:"Espinaca bloomsdale orgánica enlatada o envasada"},
    {prodId:"50476103",label:"Espinaca invierno gigante orgánica enlatada o envasada"},
    {prodId:"50476104",label:"Espinaca horenso orgánica enlatada o envasada"},
    {prodId:"50476105",label:"Espinaca planta de hielo orgánica enlatada o envasada"},
    {prodId:"50476106",label:"Espinaca  cuarto de cordero orgánica enlatada o envasada"},
    {prodId:"50476107",label:"Espinaca malabra orgánica enlatada o envasada"},
    {prodId:"50476108",label:"Espinaca mendania orgánica enlatada o envasada"},
    {prodId:"50476109",label:"Espinaca nueva zelandia orgánica enlatada o envasada"},
    {prodId:"50476110",label:"Espinaca orach orgánica enlatada o envasada"},
    {prodId:"50476111",label:"Espinaca savoy orgánica enlatada o envasada"},
    {prodId:"50476112",label:"Espinaca hoja de sigma orgánica enlatada o envasada"},
    {prodId:"50476113",label:"Espinaca espacio orgánica enlatada o envasada"},
    {prodId:"50476114",label:"Espinaca trinidad orgánica enlatada o envasada"},
    {prodId:"50476115",label:"Espinaca agua orgánica enlatada o envasada"},
    {prodId:"50476116",label:"Espinaca de agua sesa"},
    {prodId:"50476200",label:"Calabazas orgánicas en lata o en frasco"},
    {prodId:"50476201",label:"Calabaza boston orgánica enlatada o envasada"},
    {prodId:"50476202",label:"Calabaza buttemut orgánica enlatada o envasada"},
    {prodId:"50476203",label:"Calabaza costata romanesca orgánica enlatada o envasada"},
    {prodId:"50476204",label:"Calabaza crookneck orgánica enlatada o envasada"},
    {prodId:"50476205",label:"Calabaza  cucuzza orgánica enlatada o envasada"},
    {prodId:"50476206",label:"Calabaza  delicata orgánica enlatada o envasada"},
    {prodId:"50476207",label:"Calabaza  delisioso orgánica enlatada o envasada"},
    {prodId:"50476208",label:"Calabaza verano dorado cuello torcido orgánica enlatada o envasada"},
    {prodId:"50476209",label:"Calabaza prolífico temprano cuello recto orgánica enlatada o envasada"},
    {prodId:"50476210",label:"Calabaza  oro orgánica enlatada o envasada"},
    {prodId:"50476211",label:"Calabaza  jack pequeño orgánica enlatada o envasada"},
    {prodId:"50476212",label:"Calabaza  campo de kentucky orgánica enlatada o envasada"},
    {prodId:"50476213",label:"Calabaza marrow orgánica enlatada o envasada"},
    {prodId:"50476214",label:"Calabaza  medio oriente orgánica enlatada o envasada"},
    {prodId:"50476215",label:"Calabaza  miniatura orgánica enlatada o envasada"},
    {prodId:"50476216",label:"Calabaza  orangetti orgánica enlatada o envasada"},
    {prodId:"50476217",label:"Calabaza pattypan orgánica enlatada o envasada"},
    {prodId:"50476218",label:"Calabaza  rondini orgánica enlatada o envasada"},
    {prodId:"50476219",label:"Calabaza  redonda orgánica enlatada o envasada"},
    {prodId:"50476220",label:"Calabaza  espagueti orgánica enlatada o envasada"},
    {prodId:"50476221",label:"Calabaza  estripeti orgánica enlatada o envasada"},
    {prodId:"50476222",label:"Calabaza  molde dulce orgánica enlatada o envasada"},
    {prodId:"50476223",label:"Calabaza  empanada dulce orgánica enlatada o envasada"},
    {prodId:"50476224",label:"Calabaza  premio triple  orgánica enlatada o envasada"},
    {prodId:"50476225",label:"Calabaza waltham butternut orgánica enlatada o envasada"},
    {prodId:"50476226",label:"Calabaza  arbusto scallop amarillo orgánica enlatada o envasada"},
    {prodId:"50476227",label:"Calabaza cuello recto amarillo orgánica enlatada o envasada"},
    {prodId:"50476228",label:"Calabaza zafiro orgánica enlatada o envasada"},
    {prodId:"50476229",label:"Calabaza zucchini orgánica enlatada o envasada"},
    {prodId:"50476300",label:"Batatas orgánicas en lata o en frasco"},
    {prodId:"50476301",label:"Batata beauregard orgánica enlatada o envasada"},
    {prodId:"50476302",label:"Batata centennial orgánica enlatada o envasada"},
    {prodId:"50476303",label:"Batata diane orgánica enlatada o envasada"},
    {prodId:"50476304",label:"Batata dulce garnet orgánica enlatada o envasada"},
    {prodId:"50476305",label:"Batata georgia dulce rojo orgánica enlatada o envasada"},
    {prodId:"50476306",label:"Batata  dorado orgánica enlatada o envasada"},
    {prodId:"50476307",label:"Batata hanna  orgánica enlatada o envasada"},
    {prodId:"50476308",label:"Batata japonés orgánica enlatada o envasada"},
    {prodId:"50476309",label:"Batata jersey orgánica enlatada o envasada"},
    {prodId:"50476310",label:"Batata joya orgánica enlatada o envasada"},
    {prodId:"50476311",label:"Batata meryland roja orgánica enlatada o envasada"},
    {prodId:"50476312",label:"Batata nema dorado orgánica enlatada o envasada"},
    {prodId:"50476313",label:"Batata o'henry orgánica enlatada o envasada"},
    {prodId:"50476314",label:"Batata okinawan orgánica enlatada o envasada"},
    {prodId:"50476315",label:"Batata naranja orgánica enlatada o envasada"},
    {prodId:"50476316",label:"Batata oriental orgánica enlatada o envasada"},
    {prodId:"50476317",label:"Batata jersey rojo orgánica enlatada o envasada"},
    {prodId:"50476318",label:"Batata mar rojo orgánica enlatada o envasada"},
    {prodId:"50476319",label:"Batata brillo rojo orgánica enlatada o envasada"},
    {prodId:"50476320",label:"Batata jersey amarillo orgánica enlatada o envasada"},
    {prodId:"50476400",label:"Tomates orgánicos en lata o en frasco"},
    {prodId:"50476401",label:"Tomates ailsa craig orgánico enlatado o envasado"},
    {prodId:"50476402",label:"Tomates alicante orgánico enlatado o envasado"},
    {prodId:"50476403",label:"Tomates ciruela negra orgánico enlatado o envasado"},
    {prodId:"50476404",label:"Tomates vino de brandy orgánico enlatado o envasado"},
    {prodId:"50476405",label:"Tomates bella cereza orgánico enlatado o envasado"},
    {prodId:"50476406",label:"Tomates cereza orgánico enlatado o envasado"},
    {prodId:"50476407",label:"Tomates delicioso orgánico enlatado o envasado"},
    {prodId:"50476408",label:"Tomates dombito orgánico enlatado o envasado"},
    {prodId:"50476409",label:"Tomates delicia del jardín orgánico enlatado o envasado"},
    {prodId:"50476410",label:"Tomates uva orgánico enlatado o envasado"},
    {prodId:"50476411",label:"Tomates verde orgánico enlatado o envasado"},
    {prodId:"50476412",label:"Tomates super marmande orgánico enlatado o envasado"},
    {prodId:"50476413",label:"Tomates rayas naturales marvel orgánico enlatado o envasado"},
    {prodId:"50476414",label:"Tomates minibel orgánico enlatado o envasado"},
    {prodId:"50476415",label:"Tomates oaxacan orgánico enlatado o envasado"},
    {prodId:"50476416",label:"Tomates alerta roja orgánico enlatado o envasado"},
    {prodId:"50476417",label:"Tomates roma vf orgánico enlatado o envasado"},
    {prodId:"50476418",label:"Tomates marzano zan orgánico enlatado o envasado"},
    {prodId:"50476419",label:"Tomates shirley orgánico enlatado o envasado"},
    {prodId:"50476420",label:"Tomates siberia orgánico enlatado o envasado"},
    {prodId:"50476421",label:"Tomates super beefsteak orgánico enlatado o envasado"},
    {prodId:"50476422",label:"Tomates tigerella orgánico enlatado o envasado"},
    {prodId:"50476423",label:"Tomates tiny tim orgánico enlatado o envasado"},
    {prodId:"50476424",label:"Tomates tumbler orgánico enlatado o envasado"},
    {prodId:"50476425",label:"Tomates cocktail amarillo orgánico enlatado o envasado"},
    {prodId:"50476426",label:"Tomates forma de pera amarillo orgánico enlatado o envasado"},
    {prodId:"50476427",label:"Tomates perfección amarilla orgánico enlatado o envasado"},
    {prodId:"50476500",label:"Nabos orgánicos en lata o en frasco"},
    {prodId:"50476501",label:"Nabo globo verde orgánico enlatado o envasado"},
    {prodId:"50476502",label:"Nabo bola dorada orgánico enlatado o envasado"},
    {prodId:"50476503",label:"Nabo mercado de manchester orgánico enlatado o envasado"},
    {prodId:"50476504",label:"Nabo top morado milan orgánico enlatado o envasado"},
    {prodId:"50476505",label:"Nabo top morado blanco orgánico enlatado o envasado"},
    {prodId:"50476506",label:"Nabo bola de nieve orgánico enlatado o envasado"},
    {prodId:"50476507",label:"Nabo tokio orgánico enlatado o envasado"},
    {prodId:"50476508",label:"Nabo cruz de tokio orgánico enlatado o envasado"},
    {prodId:"50476600",label:"Auyamas orgánicas en lata o en frasco"},
    {prodId:"50476601",label:"Calabaza acorn orgánica enlatada o envasada"},
    {prodId:"50476602",label:"Calabaza gigante atlántico orgánica enlatada o envasada"},
    {prodId:"50476603",label:"Calabaza banana rosado orgánica enlatada o envasada"},
    {prodId:"50476604",label:"Calabaza max grande orgánica enlatada o envasada"},
    {prodId:"50476605",label:"Calabaza  orgánica enlatada o envasada"},
    {prodId:"50476606",label:"Calabaza carnaval orgánica enlatada o envasada"},
    {prodId:"50476607",label:"Auyama queso orgánica enlatada o envasada"},
    {prodId:"50476608",label:"Calabaza príncipe coronado orgánica enlatada o envasada"},
    {prodId:"50476609",label:"Calabaza  curcibita orgánica enlatada o envasada"},
    {prodId:"50476610",label:"Calabaza cushaw orgánica enlatada o envasada"},
    {prodId:"50476611",label:"Calabaza  gigante auyama orgánica enlatada o envasada"},
    {prodId:"50476612",label:"Calabaza  hubbard orgánica enlatada o envasada"},
    {prodId:"50476613",label:"Calabaza  jerrahdale orgánica enlatada o envasada"},
    {prodId:"50476614",label:"Calabaza  kabocha orgánica enlatada o envasada"},
    {prodId:"50476615",label:"Calabaza queensland azul orgánica enlatada o envasada"},
    {prodId:"50476616",label:"Calabaza rouge vif détampes orgánica enlatada o envasada"},
    {prodId:"50476617",label:"Calabaza  turban turco orgánica enlatada o envasada"},
    {prodId:"50476618",label:"Calabaza  valenciano orgánica enlatada o envasada"},
    {prodId:"50476619",label:"Calabaza  warted hubbard orgánica enlatada o envasada"},
    {prodId:"50476620",label:"Auyama whangaparoa orgánica enlatada o envasada"},
    {prodId:"50476700",label:"Ñames orgánicos en lata o en frasco"},
    {prodId:"50476701",label:"Ñame agria africana orgánica enlatada o envasada"},
    {prodId:"50476702",label:"Ñame agria asiática orgánica enlatada o envasada"},
    {prodId:"50476703",label:"Ñame china orgánico enlatado o envasado"},
    {prodId:"50476704",label:"Ñame globo orgánico enlatado o envasado"},
    {prodId:"50476705",label:"Ñame grater orgánico enlatado o envasado"},
    {prodId:"50476706",label:"Ñame japonesa orgánico enlatado o envasado"},
    {prodId:"50476707",label:"Ñame lesser orgánico enlatado o envasado"},
    {prodId:"50476708",label:"Ñame papa orgánico enlatado o envasado"},
    {prodId:"50476709",label:"Ñame guinea blanca orgánico enlatado o envasado"},
    {prodId:"50476710",label:"Ñame guinea amarillo orgánico enlatado o envasado"},
    {prodId:"50476900",label:"Alverjas dulces orgánicas en lata o en frasco"},
    {prodId:"50476901",label:"Alverja biquini orgánica enlatada o envasada"},
    {prodId:"50476902",label:"Alverja cavalier orgánica enlatada o envasada"},
    {prodId:"50476903",label:"Alverja margarita orgánica enlatada o envasada"},
    {prodId:"50476904",label:"Alverja dar fon orgánica enlatada o envasada"},
    {prodId:"50476905",label:"Alverja early onward orgánica enlatada o envasada"},
    {prodId:"50476906",label:"Alverja primer feltham orgánica enlatada o envasada"},
    {prodId:"50476907",label:"Alverja verde hurst shaft orgánica enlatada o envasada"},
    {prodId:"50476908",label:"Guisante oregón sugar pod orgánica enlatada o envasada"},
    {prodId:"50476909",label:"Alverja príncipe alberto orgánica enlatada o envasada"},
    {prodId:"50476910",label:"Alverja reuzensuiker orgánica enlatada o envasada"},
    {prodId:"51122300",label:"Agentes cardiopléjicos"},
    {prodId:"51122301",label:"Solución de cardioplejia"},
    {prodId:"51151906",label:"Isoxsuprina"},
    {prodId:"51171710",label:"Clorhidrato de alosetrón"},
    {prodId:"51201650",label:"Vacuna contra el virus del COVID"},
    {prodId:"52141612",label:"Hidroextractor para la ropa"},
    {prodId:"52141803",label:"Cobijas eléctricas para uso doméstico"},
    {prodId:"52151904",label:"Moldes para asar para uso doméstico"},
    {prodId:"53112004",label:"Estirador de calzado"},
    {prodId:"54121800",label:"Piedras preciosas o semipreciosas industriales"},
    {prodId:"54121801",label:"Diamantes industriales"},
    {prodId:"54121802",label:"Granates industriales"},
    {prodId:"55101505",label:"Historietas cómicas"},
    {prodId:"55111600",label:"Material de referencia de software electrónico"},
    {prodId:"55111601",label:"Documentación de software o manuales de usuario electrónicos"},
    {prodId:"55121733",label:"Señales en blanco"},
    {prodId:"56111605",label:"Iluminación o electricidad o componentes de información para sistemas de paneles"},
    {prodId:"56111901",label:"Iluminación o electricidad o componentes de información industriales"},
    {prodId:"56111902",label:"Superficies de trabajo industriales"},
    {prodId:"60101200",label:"Incentivos educativos"},
    {prodId:"60101201",label:"Adhesivos de seguimiento basados en la biblia"},
    {prodId:"60101202",label:"Tablas de incentivo basados en la biblia"},
    {prodId:"60101203",label:"Adhesivos para pegar en las tablas de incentivo"},
    {prodId:"60101204",label:"Tablas de incentivo"},
    {prodId:"60101205",label:"Tarjetas perforadas de incentivo"},
    {prodId:"60102100",label:"Materiales de recursos de lenguaje"},
    {prodId:"60102101",label:"Libros de recursos de adjetivos"},
    {prodId:"60102102",label:"Libros de recursos de adverbios"},
    {prodId:"60102103",label:"Libros de recursos de gramática"},
    {prodId:"60102104",label:"Libros de recursos de sustantivos"},
    {prodId:"60102105",label:"Libros de recursos de puntuación"},
    {prodId:"60102106",label:"Libros de recursos de verbos"},
    {prodId:"60102200",label:"Materiales de recursos de fonética y accesorios"},
    {prodId:"60102201",label:"Libros de actividades de fonética"},
    {prodId:"60102202",label:"Tarjetas de ejercicios fonéticos de repetición"},
    {prodId:"60102203",label:"Kit de fonética"},
    {prodId:"60102204",label:"Tarjetas de imágenes de fonética"},
    {prodId:"60102205",label:"Libros de recursos de fonética"},
    {prodId:"60102206",label:"Fichas de fonética"},
    {prodId:"60102308",label:"Materiales de comprensión de lectura"},
    {prodId:"60102715",label:"Libros de recursos y actividades de pentominós"},
    {prodId:"60102717",label:"Pentominós"},
    {prodId:"60102718",label:"Sets de actividades de pentominós"},
    {prodId:"60103200",label:"Materiales de recursos del profesor de álgebra"},
    {prodId:"60103201",label:"Libros de recursos o de actividades de álgebra"},
    {prodId:"60103202",label:"Cubos de centímetro"},
    {prodId:"60103203",label:"Guías de referencia de preálgebra o álgebra"},
    {prodId:"60103204",label:"Modelos o accesorios de álgebra"},
    {prodId:"60103300",label:"Materiales de recursos del profesor de precálculo y cálculo"},
    {prodId:"60103301",label:"Libros de recursos o actividades de cálculo"},
    {prodId:"60103302",label:"Guías de referencia de precálculo o cálculo"},
    {prodId:"60103303",label:"Libros de recursos o actividades de precálculo"},
    {prodId:"60103500",label:"Ciencias políticas"},
    {prodId:"60103501",label:"Libros de recursos o actividades de economía"},
    {prodId:"60103502",label:"Libros de recursos o actividades del gobierno"},
    {prodId:"60103503",label:"Guías de referencia del gobierno"},
    {prodId:"60103504",label:"Unidades temáticas de los estados"},
    {prodId:"60104000",label:"Biotecnología, bioquímica, genética, microbiología y materiales relacionados"},
    {prodId:"60104001",label:"Modelos de ácido desoxirribonucleico (adn)"},
    {prodId:"60104002",label:"Kits de experimentos de ácido desoxirribonucleico (adn)"},
    {prodId:"60104003",label:"Libros de genética"},
    {prodId:"60104004",label:"Kits de genética"},
    {prodId:"60104005",label:"Kits de enseñanza de bacterias"},
    {prodId:"60104006",label:"Materiales de prueba de bacterias"},
    {prodId:"60104007",label:"Kits o materiales de enzimología"},
    {prodId:"60104008",label:"Kits o materiales de prueba de proteínas"},
    {prodId:"60104300",label:"Astronomía"},
    {prodId:"60104301",label:"Modelos de astronomía"},
    {prodId:"60104302",label:"Diagramas de astronomía"},
    {prodId:"60104303",label:"Kits de estudio de astronomía"},
    {prodId:"60104304",label:"Compás solar"},
    {prodId:"60104305",label:"Simulador solar"},
    {prodId:"60104306",label:"Planetario"},
    {prodId:"60104307",label:"Modelo planetario"},
    {prodId:"60104308",label:"Globo celeste"},
    {prodId:"60104309",label:"Carta celeste manual"},
    {prodId:"60105004",label:"Señales de advertencia de radiación"},
    {prodId:"60105100",label:"Cohetes, materiales de vuelo y suministros"},
    {prodId:"60105101",label:"Sets de cohetes"},
    {prodId:"60105102",label:"Aparato de lanzamiento"},
    {prodId:"60105103",label:"Dispositivos para la medición de alturas"},
    {prodId:"60105200",label:"Materiales educativos de recursos de aptitudes de vida"},
    {prodId:"60105201",label:"Materiales de enseñanza para desarrollar habilidades de escucha"},
    {prodId:"60105202",label:"Materiales de enseñanza de  habilidades de estudio"},
    {prodId:"60105203",label:"Materiales de enseñanza de preparación de exámenes"},
    {prodId:"60105300",label:"Materiales educativos de educación de carrera"},
    {prodId:"60105301",label:"Materiales de enseñanza de habilidades para la educación o planificación o toma de decisiones para el trabajo"},
    {prodId:"60105302",label:"Materiales de enseñanza de  habilidades laborales básicas"},
    {prodId:"60105303",label:"Materiales de enseñanza de habilidades para la búsqueda de empleo"},
    {prodId:"60105304",label:"Materiales de enseñanza de habilidades para el manejo del tiempo"},
    {prodId:"60105305",label:"Materiales de enseñanza de habilidades para entrevistas"},
    {prodId:"60105306",label:"Materiales de enseñanza de habilidades para elaborar la hoja de vida"},
    {prodId:"60105307",label:"Materiales de enseñanza de formación de ética laboral o actitudes"},
    {prodId:"60105308",label:"Materiales de enseñanza para desarrollar habilidades de trabajo en equipo"},
    {prodId:"60105309",label:"Materiales de enseñanza de etiqueta  empresarial"},
    {prodId:"60106300",label:"Equipo de enseñanza de ciencia forense y suministros"},
    {prodId:"60106301",label:"Kits de ciencias forenses"},
    {prodId:"60106302",label:"Materiales de enseñanza de ciencias forenses"},
    {prodId:"60106600",label:"Materiales para pruebas de aptitud y vocacionales"},
    {prodId:"60106601",label:"Test de ensamble de bloques"},
    {prodId:"60106602",label:"Test de percepción de formas"},
    {prodId:"60106603",label:"Test de reacción de velocidad de anticipación"},
    {prodId:"60106604",label:"Set de evaluación vocacional"},
    {prodId:"60106605",label:"Test de reacción mental"},
    {prodId:"60106606",label:"Test de ejecución de trabajos manuales de precisión"},
    {prodId:"60106607",label:"Test de movimientos de reacción"},
    {prodId:"60106608",label:"Test de coordinación de los dos brazos"},
    {prodId:"60106609",label:"Herramienta de discriminación"},
    {prodId:"60106610",label:"Herramienta para pruebas de ensamble"},
    {prodId:"60106611",label:"Herramienta para pruebas de empaquetado"},
    {prodId:"60106612",label:"Test de coordinación ojos- brazos"},
    {prodId:"60121536",label:"Borrador de crayón"},
    {prodId:"60121602",label:"Paneles o espejos de acrílico transparente"},
    {prodId:"60122300",label:"Suministros de cestería"},
    {prodId:"60122301",label:"Juncos para cestería"},
    {prodId:"60122302",label:"Kits para proyectos de cestería"},
    {prodId:"60123800",label:"Suministros y accesorios de veteado"},
    {prodId:"60123801",label:"Tintas para veteado"},
    {prodId:"60123802",label:"Accesorios para veteado"},
    {prodId:"60124101",label:"Productos de pintura multicultural"},
    {prodId:"60124200",label:"Proyectos completos de planes de estudios"},
    {prodId:"60124201",label:"Esculturas de mylar"},
    {prodId:"64122100",label:"Contratos de seguros de salud"}
]*/

// export const // agregar todo el payload en la tabla de Mis envios


