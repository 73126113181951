import { ThunkAction } from 'redux-thunk'
import { AnyAction } from '@reduxjs/toolkit';
import { 
    UserDirectoryFav,
    ComissionSaved, 
    SET_COMISSIONS, 
    SET_CARRIER_PRICES,
    SET_CARRIER_PRICE_TO_EDIT,
    SET_STORES, 
    SET_EDIT_STORE, 
    Store, 
    StoreAction, 
    CarrierPrice } from '../../types/storeTypes';
import { User, UserBalanceLog, UserBalance } from '../../types/authTypes';
import { RootState } from '..';
import { setError, setLoading } from './genericActions';
import { firestore } from '../../firebase/config';
import { collection, doc, setDoc, getDoc, serverTimestamp, Timestamp, addDoc, where, getDocs, query, deleteDoc, onSnapshot, QuerySnapshot } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"

//create store
export const createStore = (data: Store, user: User): ThunkAction<void, RootState, null, AnyAction> =>{ 
    return async (dispatch) => {
        try {
            if (data && user) {
                let storeData: Store = {
                    ...data,
                    createdAt: serverTimestamp(),
                    createdBy: user.uid,
                    lastModifiedAt:serverTimestamp(),
                    lastModifiedBy: user.uid,
                }
                const storesRef = collection(firestore, 'franchises')
                const docSaved = await addDoc(storesRef, storeData)
                await setDoc(doc(storesRef, docSaved.id), {_id:docSaved.id}, {merge:true})
                dispatch<any>(setLoading(false))
            }
        } catch (err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}

export const setCarrierPrices = (carrierPrices: Array<CarrierPrice>): ThunkAction<void, RootState, null, StoreAction > => {
    return dispatch => {
        dispatch({
            type: SET_CARRIER_PRICES,
            payload: carrierPrices
        })
    }
}

export const setCarrierPriceToEdit = (carrierPrice?: CarrierPrice): ThunkAction<void, RootState, null, StoreAction > => {
    return async (dispatch) => {
        try {
            if(carrierPrice) {
                dispatch({
                    type: SET_CARRIER_PRICE_TO_EDIT,
                    payload: carrierPrice
                })   
            } else if(!carrierPrice) {
                dispatch({
                    type: SET_CARRIER_PRICE_TO_EDIT,
                    payload: null
                })   

            }
        } catch(err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}

export const newCarrierPrice = (data: CarrierPrice , userId:string): ThunkAction<void, RootState, null, AnyAction> =>{ 
    return async (dispatch) => {
        try {
            if (data && userId) {
                let carrierPriceData: CarrierPrice = {
                    ...data,
                    createdAt: serverTimestamp(),
                    createdBy: userId,
                    lastModifiedAt:serverTimestamp(),
                    lastModifiedBy: userId,
                }
                const carrierRef = collection(firestore, 'carrierPrices')
                const docSaved = await addDoc(carrierRef, carrierPriceData)
                await setDoc(doc(carrierRef, docSaved.id), {_id:docSaved.id}, {merge:true})
                dispatch<any>(setLoading(false))
            }
        } catch (err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}

export const updateCarrierPrice = (data: CarrierPrice , userId:string): ThunkAction<void, RootState, null, AnyAction> =>{ 
    return async (dispatch) => {
        try {
            if (data && userId) {
                let carrierPriceData: CarrierPrice = {
                    ...data,
                    lastModifiedAt:serverTimestamp(),
                    lastModifiedBy: userId,
                }
                const carrierRef = collection(firestore, 'carrierPrices')
                const docReference = doc(carrierRef, carrierPriceData._id)
                await setDoc(docReference, carrierPriceData, {merge:true})
                dispatch<any>(setLoading(false))
            }
        } catch (err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}

export const deleteCarrierPrice = (data: CarrierPrice, userId:string): ThunkAction<void, RootState, null, AnyAction> =>{ 
    return async (dispatch) => {
        try {
            if (data && userId) {
                const carrierRef = collection(firestore, 'carrierPrices')
                const docReference = doc(carrierRef, data._id)
                await deleteDoc(docReference)
                dispatch<any>(setLoading(false))
            }
        } catch (err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}


export const createDirectory = (data: UserDirectoryFav, user:User): ThunkAction<void, RootState, null, AnyAction> =>{ 
    return async (dispatch) => {
        try {
            if (data && user) {
                let newDirData: UserDirectoryFav = {
                    ...data,
                    createdAt: serverTimestamp(),
                    createdBy: user.uid,
                    lastModifiedAt:serverTimestamp(),
                    lastModifiedBy: user.uid,
                }
                const userDirRef = collection(firestore, 'userDirectory')
                const docSaved = await addDoc(userDirRef, newDirData)
                await setDoc(doc(userDirRef, docSaved.id), {_id:docSaved.id}, {merge:true})
                dispatch<any>(setLoading(false))
            }
        } catch (err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}

export const getStores = (stores: Array<Store>): ThunkAction<void, RootState, null, StoreAction> =>{
    return async (dispatch) => {
        try {
            if(stores) {
                dispatch({
                    type: SET_STORES,
                    payload: stores
                })
            }

        } catch(err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}

export const getComissions = (comissions: Array<ComissionSaved>): ThunkAction<void, RootState, null, StoreAction> =>{
    return async (dispatch) => {
        try {
            if(comissions) {
                dispatch({
                    type: SET_COMISSIONS,
                    payload: comissions
                })
            }

        } catch(err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}

export const updateAnotherUser = (user:User, userAdminId: string): ThunkAction<void, RootState, null, StoreAction> =>{
    return async (dispatch) => {
        try {
            if(user) {
                const userToSave: User = {
                    ...user,
                    lastModifiedBy: userAdminId,
                    lastModifiedAt: serverTimestamp()
                }
                const usersRef = collection(firestore, 'users')
                const docReference = doc(usersRef, userToSave.uid)
                await setDoc(docReference, userToSave, {merge:true})
                dispatch<any>(setLoading(false))
                
            }

        } catch(err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}

export const updateUserBalanceLog = (adminUserId:string, userToUpdateId: string, newUserBalanceLogArray:Array<UserBalanceLog>,  balanceLogId?: string): ThunkAction<void, RootState, null, StoreAction> =>{
    return async (dispatch) => {
        try {
            if(adminUserId && newUserBalanceLogArray) {
                if(balanceLogId) {
                
                    const balanceLogIdDocRef = doc(firestore, 'userBalance', balanceLogId)
                    const docSnap = await getDoc(balanceLogIdDocRef)
                    if(docSnap.exists()){
                        const userBalanceLogFromDb: UserBalance = docSnap.data() as UserBalance
                        const updatedLogArray: Array<UserBalanceLog> = Array.from(userBalanceLogFromDb.updates).concat(newUserBalanceLogArray)
                        const balanceLogIdRef = collection(firestore, 'userBalance')
                        await setDoc(doc(balanceLogIdRef, userBalanceLogFromDb._id), {updates:updatedLogArray}, {merge:true})
                    }
                    
                }
                if(!balanceLogId) {

                    const userBalanceData: UserBalance = {
                        _id:'tbd',
                        userId: userToUpdateId,
                        updates: newUserBalanceLogArray
                    }

                    const balanceLogIdRef = collection(firestore, 'userBalance')
                    const balanceDocSaved = await addDoc(balanceLogIdRef, userBalanceData)
                    await setDoc(doc(balanceLogIdRef, balanceDocSaved.id), {_id:balanceDocSaved.id}, {merge:true})
                    const userRef =  collection(firestore, 'users')
                    await setDoc(doc(userRef, userToUpdateId),{userBalanceLogId:balanceDocSaved.id}, {merge:true})
                    dispatch<any>(setLoading(false))

                }

                dispatch<any>(setLoading(false))
                
            }

        } catch(err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}

export const updateStore = (storeToUpdate:Store, userId?:string): ThunkAction<void, RootState, null, StoreAction> =>{
    return async (dispatch) => {
        try {
            if(storeToUpdate) {
                const storeUpdated: Store ={
                    ...storeToUpdate,
                    lastModifiedBy: userId? userId:storeToUpdate.lastModifiedBy,
                    lastModifiedAt: serverTimestamp()
                }
                const storesRef = collection(firestore, 'franchises')
                const docReference = doc(storesRef, storeToUpdate._id)
                await setDoc(docReference, storeUpdated, {merge:true})
                dispatch<any>(setLoading(false))
                
            }

        } catch(err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}

export const setEditStore = (storeToEdit?:Store): ThunkAction<void, RootState, null, StoreAction> =>{
    return async (dispatch) => {
        try {
            if(storeToEdit) {
                dispatch({
                    type: SET_EDIT_STORE,
                    payload: storeToEdit
                })   
            } else if(!storeToEdit) {
                dispatch({
                    type: SET_EDIT_STORE,
                    payload: null
                })   

            }
        } catch(err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}

export const updateStoreAvatar = (file: File, store: Store, userId:string): ThunkAction<void, RootState, null, StoreAction> => {
    return async dispatch=> {
        try{
            if(file && store) {

                const randomId = Math.random().toString(36).substring(2, 8);
                const storageRoute = store.avatarStorageRef ? store.avatarStorageRef : `franchiseAvatar/${store._id + randomId}` // removed S from the storage
                const storage = getStorage();
                const spaceRef = ref(storage, storageRoute)
                await uploadBytesResumable(spaceRef, file)
                const newAvatarUrl = await getDownloadURL(spaceRef)
                let storeData: Store = {
                    ...store,
                    storeAvatar: newAvatarUrl,
                    avatarStorageRef: storageRoute,
                    lastModifiedAt:serverTimestamp(),
                    lastModifiedBy: userId,
                }
                const storesRef = collection(firestore, 'franchises')
                await setDoc(doc(storesRef, store._id), storeData, { merge: true });
                dispatch(setLoading(false))
            }

        }catch (err: any) {
            dispatch(setError(err.message))
        }
    }
}


export const createComissions = (comission:ComissionSaved): ThunkAction<void, RootState, null, StoreAction> =>{
    return async (dispatch) => {
        try {
            if(comission) {
                let comissionToCreate: ComissionSaved = {
                    ...comission,
                    submittedAt: Timestamp.now()
                }
                const comissionsRef = collection(firestore, 'comissions')
                const docSaved = await addDoc(comissionsRef, comissionToCreate)
                await setDoc(doc(comissionsRef, docSaved.id), {_id:docSaved.id}, {merge:true});
                dispatch<any>(setLoading(false))
            }

        } catch(err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}

export const comissionByDateRole = (storeId:string, startDate: Date, endDate: Date, isAdmin:boolean, submittedById?: string): ThunkAction<void, RootState, null, StoreAction> =>{
    return async (dispatch) => {
        try {
            const comissionsRef = collection(firestore, 'comissions')
            const queries = []
            if(storeId) {
                queries.push(where("storeId","==", storeId))
            }
            if(startDate) {
                queries.push(where("createDate",">=", startDate))
            }
            if(endDate) {
                queries.push(where("createDate","<=", endDate))
            }
            if(submittedById) {
                queries.push(where("submittedBy","==", submittedById))
            }

            const comissionsQuery = query((comissionsRef), ...queries) 
            const comissionDocs = await getDocs(comissionsQuery)
            const comissions: Array<ComissionSaved> = comissionDocs.docs.map((doc) => {
                const comissionDoc: ComissionSaved = {...doc.data() as ComissionSaved, _id:doc.id}
                return comissionDoc;
            })
            dispatch({
                type: SET_COMISSIONS,
                payload: comissions
            })

        } catch(err: any) {
            console.log(err)
            dispatch(setError(err.message))
        }
    }
}