import React, {FC, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { UserBasicData, StoreBasicData, User } from '../../../types/authTypes';
import { Store } from '../../../types/storeTypes';
import { RootState } from '../../../store';
import { setLoading } from '../../../store/actions/genericActions';
import { updateAnotherUser, updateStore } from '../../../store/actions/storeActions';
import { roleTypeArray } from '../../../features/referenceData/userLists';

import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import LoadingButton from '@mui/lab/LoadingButton';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';

interface UpdateStoreUserParams {
    userExt: User
    userAdmin: User
}

const UpdateStoreButton:FC<UpdateStoreUserParams> = (props) => {

    const dispatch = useDispatch()

    const { userExt, userAdmin } = props
    const { stores } = useSelector((state: RootState) => state.stores);
    const { loading } = useSelector((state: RootState) => state.generic);
    const [anchorNotifsEl, setAnchorNotifsEl] = useState<null | HTMLElement>(null);
    const openNotifsEl = Boolean(anchorNotifsEl);

    const clickChangeStore = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorNotifsEl(event.currentTarget);
    };
    const handleCloseStore = () => {
        setAnchorNotifsEl(null);
    };
    const updateStoreHandler = (store:Store) => {
        
        const hasStoreAlready: boolean = userExt.storesIds ? userExt.storesIds.includes(store._id) : false

        if(hasStoreAlready) {
            return;
        }

        dispatch<any>(setLoading(true))

        const storeSupervisor: UserBasicData = {
            uid: store.storeSupervisor?.uid ? store.storeSupervisor.uid :'N/A',
            userName: store.storeSupervisor?.userName ? store.storeSupervisor.userName :'N/A',
        }
        const storeToAdd: StoreBasicData = {
            _id:store._id,
            storeName: store.storeName,
            supervisor: storeSupervisor
        }

        const updatedListOfStores: Array<StoreBasicData> = userExt.stores ? [...userExt.stores, storeToAdd] : [storeToAdd]
        const updatedStoreIds: Array<string> = userExt.storesIds ? [...userExt.storesIds, store._id] : [store._id]
        const updatedSuperIds: Array<string> = userExt.supervisorIds && store.storeSupervisor ? [...userExt.supervisorIds, storeSupervisor.uid] : [storeSupervisor.uid]
        const updatedStoreMemberIds: Array<string> =  store.members ? [...store.members, userExt.uid] : [userExt.uid]
        let uniqueStoreIds= updatedStoreIds.filter((id, index) => {
            return updatedStoreIds.indexOf(id) === index;
        });
        let uniqueSuperIds= updatedSuperIds.filter((id, index) => {
            return updatedSuperIds.indexOf(id) === index;
        });
        let uniqueStoreMemberIds= updatedStoreMemberIds.filter((id, index) => {
            return updatedStoreMemberIds.indexOf(id) === index;
        });

        const userToUpdate: User = {
            ...userExt,
            stores: updatedListOfStores,
            storesIds: uniqueStoreIds,
            supervisorIds: uniqueSuperIds
        }
        const storeToUpdate: Store = {
            ...store,
            members: uniqueStoreMemberIds
        }
        if(userToUpdate && userAdmin && storeToUpdate) {
            dispatch<any>(updateAnotherUser(userToUpdate, userAdmin.uid))
            dispatch<any>(updateStore(storeToUpdate))
        }
    }

    return (
        <Box sx={{width:'100%'}} className="d-flex-column-start-centered">
            <LoadingButton 
                aria-label="notifications"
                onClick={clickChangeStore}
                variant="text"
                color="primary"
                size="small"
                disableElevation
                disabled={userAdmin.role !== roleTypeArray[1]}
                fullWidth
                loading={loading}
                sx={{my:.5, bgcolor:'grey.50'}}
                startIcon={<StorefrontOutlinedIcon/>}
                >
                Agregar a grupo
            </LoadingButton>
            <Menu
                anchorEl={anchorNotifsEl}
                open={openNotifsEl}
                onClose={handleCloseStore}
                onClick={handleCloseStore}
                PaperProps={{
                elevation: 2,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 4px 4px rgba(55, 61, 135,0.25))',
                    border:1,
                    borderRadius:3,
                    borderColor:'grey.500',
                    mt: .75,
                }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuList sx={{boxShadow:0, px:2}}>
                    <MenuItem sx={{borderBottom:1, borderColor:'grey.300'}}>
                        <ListItemText>
                            <Typography 
                                variant="body2" 
                                sx={{color:'grey.800'}}>
                                    Selecciona una tienda
                            </Typography>
                        </ListItemText>
                    </MenuItem>
                    { userAdmin && userExt && stores && stores.map((store: Store, index: number ) => {
                            return(
                                <MenuItem 
                                    key={index} 
                                    onClick={() => updateStoreHandler(store)}>
                                    <ListItemIcon>
                                        <StorefrontOutlinedIcon sx={{fontSize:22, color:'grey.500'}} />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={
                                            <Typography
                                                component='h6'
                                                variant='body2'
                                                sx={{fontWeight:400}}
                                                >
                                                {store.storeName}
                                            </Typography>
                                        }
                                        />
                                </MenuItem>
                            )
                        })
                    } 
                </MenuList>
            </Menu>
        </Box>
    )
}

export default UpdateStoreButton