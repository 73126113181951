export function generateHappyId():string{
    const randomIdNumber:number= Math.floor(100000 + Math.random() * 9000000);
    const enviosHappyId:string= 'ENHPPY'+randomIdNumber.toString()
    return enviosHappyId
}

export function formatCurrencyMxn(balance:number): string{
    let userBalance = ''
    userBalance = '$'+balance.toLocaleString(undefined, {maximumFractionDigits:2})+' MXN'
    return userBalance
}

export function calculateMarginPct(price: number, cost:number):string{
    let marginPct = ''
    marginPct = Math.round((100*(price-cost)/price))+'%'
    return marginPct 
}