import React, {FC, useState, useMemo} from 'react'
import { DataGrid,
  GridOverlay,
  GridColDef, 
  GridToolbarContainer, 
  GridToolbarExport, 
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridRenderCellParams
} 
from '@mui/x-data-grid';

import {RootState}from'../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { UserDirectoryFav } from '../../../types/storeTypes';
import { grid_esES_Text }from '../../../features/referenceData/reqTableDef';


import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

interface DirectoryListTable {
  directory: Array<UserDirectoryFav>
}

const DirectoryList: FC<DirectoryListTable> = (props) => {

  const {directory} = props

  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.generic);
  const [pageSize, setPageSize] = useState<number>(10);
  const [columns, setColumns] = useState<any>()

  const rows = directory;
  const editDirectoryHandler = (data: any) => {
  }
  const deleteDirectoryHandler = (data: any) => {
  }
  


  useMemo(() => {
    let reqColDef: GridColDef[] = [
        {field:'actions', headerName: 'Acciones', align:'center', sortable:true, width:80, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Box sx={{width:'100%'}} className="d-flex-row-center-centered">
                        <Tooltip title='Editar' placement='left'>
                            <span>
                                <IconButton 
                                    color="inherit"
                                    onClick={()=> editDirectoryHandler(params.row)}>
                                    <EditIcon sx={{fontSize:20}}/>
                                </IconButton>

                            </span>
                        </Tooltip>
                        <Tooltip title='Borrar' placement='right'>
                            <span>
                                <IconButton 
                                    color="error"
                                    onClick={()=> deleteDirectoryHandler(params.row)}>
                                    <DeleteForeverOutlinedIcon sx={{fontSize:20}}/>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                )
            })
        },
        {field:'alias', headerName: 'Alias', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.alias}
                    </Typography>
                )
            })
        },
        {field:'fullName', headerName: 'Nombre', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.fullName}
                    </Typography>
                )
            })
        },
        {field:'phone', headerName: 'Teléfono', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.phone}
                    </Typography>
                )
            })
        },
        {field:'zipCode', headerName: 'Código Postal', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.zipCode}
                    </Typography>
                )
            })
        },
        {field:'address', headerName: 'Dirección', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.address}
                    </Typography>
                )
            })
        },
        {field:'email', headerName: 'Email', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.email}
                    </Typography>
                )
            })
        },
        {field:'neighborhood', headerName: 'Colonia', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.neighborhood}
                    </Typography>
                )
            })
        },
        {field:'city', headerName: 'Ciudad', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.city}
                    </Typography>
                )
            })
        },
        {field:'state', headerName: 'Estado', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.state}
                    </Typography>
                )
            })
        },
        {field:'country', headerName: 'País', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.country}
                    </Typography>
                )
            })
        },
        {field:'company', headerName: 'Compañía', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.company}
                    </Typography>
                )
            })
        },
        {field:'reference', headerName: 'Referencia', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.reference}
                    </Typography>
                )
            })
        },
      ]
      const columns: GridColDef[] = reqColDef;
      setColumns(columns)
  },[
    directory
  ])    

  function CustomToolbar() {
      return (
        <GridToolbarContainer style={{padding:16, color:'grey.800'}}>
          <GridToolbarColumnsButton size="small" style={{marginLeft: 8, color:'grey.800'}} />
          <GridToolbarFilterButton style={{marginLeft: 8, color:'grey.800'}} />
          <GridToolbarDensitySelector size="small" style={{marginLeft: 8, color:'grey.800'}}/>
          <GridToolbarExport size="small" style={{marginLeft: 8, color:'grey.800'}} />
        </GridToolbarContainer>
      );
  }

  return (
      <div style={{ height: '85vh', width: '100%', backgroundColor:'white' }}>
          <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }}>
                  <DataGrid
                      localeText={grid_esES_Text}
                      rows={rows}
                      columns={columns}
                      getRowId={(row)=>row._id}
                      // editMode="row"
                      // checkboxSelection
                      density='compact'
                      disableSelectionOnClick
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[10, 20, 40]}
                      pagination
                      components={{
                          Toolbar: CustomToolbar,
                          LoadingOverlay: GridOverlay
                      }}
                  />
              </div>
          </div>
      </div>
  )
}

export default DirectoryList