import React, {FC} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import { RootState } from '../../../store';
import { setEditStore } from '../../../store/actions/storeActions';
import { setShowForm } from '../../../store/actions/genericActions';
import { Store } from '../../../types/storeTypes'

import AvatarCustomed from '../../UI/AvatarCustomed';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import LoadingButton from '@mui/lab/LoadingButton';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

interface StoreParams {
    stores: Array<Store>
    userAdmin: boolean
}

const StoreCard: FC<StoreParams> = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {stores, userAdmin} = props;
    const { loading, showForm } = useSelector((state: RootState) => state.generic);

    const editStoreHandler = (store:Store) => {
        if(store) {
            dispatch<any>(setEditStore(store))
            dispatch<any>(setShowForm(!showForm))
        }
    }

    const goToStoreHandler = (storeId:string) => {
        navigate("/storeProfile", {state:{ id: storeId}})
    }

    return (
        <Grid container spacing={2} sx={{px:2, mt:1}}>
            { stores && stores.map((store:Store, index: number) => {
                return(
                    <Grid key={index} item xs={12} md={6}>
                        <Card elevation={1} sx={{p:2, borderRadius:'30px'}}>
                            <CardContent className="d-flex-row-start-start">
                                {store ? 
                                    <AvatarCustomed 
                                        userName={store.storeName} 
                                        size={72} 
                                        userPhoto={store.storeAvatar}  
                                        fontWeight={600}
                                        fontSize={24}
                                        variant="rounded"
                                    />
                                    :null
                                }
                                <Box sx={{ml:2}}>
                                    <Typography variant='h6' sx={{fontWeight:700}}>
                                        {store.storeName}
                                    </Typography>
                                    <Typography variant='body2' sx={{fontWeight:500}}>
                                        {store.storeAddress}. {store.storeNeighborhood}
                                    </Typography>
                                </Box>
                            </CardContent>
                            <Stack sx={{px:1}} 
                                justifyContent="flex-start" 
                                alignItems={{ xs: 'flex-start', md: 'center' }} 
                                direction={{ xs: 'column', md: 'row' }} 
                                spacing={1}>
                                <Chip 
                                    size="small"
                                    // icon={<PeopleOutlineOutlinedIcon />}
                                    sx={{bgcolor:'transparent'}}
                                    label={store.storeSupervisor?.userName ? 'Supervisor: '+store.storeSupervisor.userName : 'Sin supervisor'}
                                    variant="filled"
                                />
                                <Chip 
                                    size="small"
                                    icon={<PlaceOutlinedIcon />}
                                    sx={{bgcolor:'transparent'}}
                                    label={store.storeCity ? store.storeCity : 'Sin ciudad'} 
                                    variant="filled"
                                />
                                <Chip 
                                    size="small"
                                    icon={<WhatsAppIcon />}
                                    sx={{bgcolor:'transparent'}}
                                    label={store.storePhone ? store.storePhone : 'Sin teléfono'} 
                                    variant="filled"
                                    />
                            </Stack>
                            <CardActions>
                                <LoadingButton 
                                    loading={loading}
                                    color="secondary"
                                    disableElevation
                                    size='small'
                                    sx={{my:1.5}}
                                    variant="contained"
                                    onClick={() => goToStoreHandler(store._id)}
                                    >
                                    Ver Grupo
                                </LoadingButton>
                                {userAdmin ?
                                    <LoadingButton 
                                        loading={loading}
                                        color="secondary"
                                        size='small'
                                        sx={{my:1.5}}
                                        variant="text"
                                        onClick={() => editStoreHandler(store)}
                                        startIcon={
                                            <ModeEditOutlinedIcon />
                                        }
                                    >
                                        Editar grupo
                                    </LoadingButton>
                                    :null
                                }
                            </CardActions>
                        </Card>
                    </Grid>
                )
            })
            }
        </Grid>
    )
}

export default StoreCard