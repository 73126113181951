import React, { FC, useState, useEffect  }from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserListTable from './UserListTable';

import {dialogTypes} from '../../../features/referenceData/appLists';
import {roleTypeArray} from '../../../features/referenceData/userLists';
import { RootState } from '../../../store';
import {User} from '../../../types/authTypes'
import { setDialog, setDialogType } from '../../../store/actions/genericActions';

import Box from '@mui/system/Box'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

const AddBalanceCard:FC = () => {

    const user: User | null = useSelector((state: RootState) => state.auth.user)
    const users: Array<User> | null = useSelector((state: RootState) => state.generic.usersAdmin)

    const dispatch = useDispatch();

    const [searchUserName, setSearchUserName] = useState<string>();
    const [filteredUsers, setFilteredUsers] = useState<Array<User> |null>(users ? users : null)

    const openDialogHandler = () => {
        dispatch<any>(setDialog(true))
        dispatch<any>(setDialogType(dialogTypes[2]))
    }

    useEffect(()=> {
        if(searchUserName) {
          setFilteredUsers(users && users.filter((user:User) => {
                return user.fullName.toLowerCase().includes(searchUserName)
            }))
        }
        if(!searchUserName || searchUserName === ''){
          setFilteredUsers(users)
        }
  
    },[
      searchUserName,
      users
    ])

    const searchByName = (stringToSearch:string) => {
        const stringToSearchToLowerCase: string = stringToSearch.toLowerCase()
        setSearchUserName(stringToSearchToLowerCase);
      }

    return (
        <Card elevation={0} sx={{borderRadius:'30px'}}>
            <CardContent>
                {user?.role === roleTypeArray[1] || user?.role === roleTypeArray[2]?
                    <Box sx={{width:'100%'}} className="d-flex-col-start-centered">
                        <Box sx={{width:'100%', mt:1, mb:3}} className="d-flex-row-start-centered">
                            <Typography variant="body1" sx={{fontWeight:600}}>
                                Agregar saldo a usuarios
                            </Typography>
                            <TextField
                                required
                                color="secondary"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                    ),
                                }}
                                sx={{my:1.5, mx:1.5}}
                                id="outlined-required"
                                onChange={(e:any) => searchByName(e.currentTarget.value)}
                                placeholder='Buscar por nombre'
                                type="text"
                            />
                        </Box>
                        <UserListTable userList={filteredUsers} />
                    </Box>
                    :
                    <Box sx={{width:'100%'}} className="d-flex-col-center-centered">
                        Por el momento no es posible agregar saldo. Comunícate al 33-21-23-15-83 para más información
                    </Box>
                }
            </CardContent>
        </Card>
    )
}

export default AddBalanceCard