import { ThunkAction } from 'redux-thunk'
import { AuthAction, User, SignUpData, SignInData, SET_USER, SIGN_OUT, NEED_VERIFICATION, UserActivityState, SET_USER_ACTIVITY_STATE } from '../../types/authTypes';
import { RootState } from '..';
import { setLoading, setError, setSuccess } from './genericActions';
import { roleTypeArray } from '../../features/referenceData/userLists';
import{ auth, firestore, facebookProvider, googleProvider } from '../../firebase/config';
import {
    createUserWithEmailAndPassword, 
    signInWithPopup, 
    sendEmailVerification, 
    signInWithEmailAndPassword, 
    signOut,
    sendPasswordResetEmail
} from '@firebase/auth'
import { collection, doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"

const saveToLocalStorage = (user: User, givenName: string) => {
    let userData = JSON.stringify(user);
    localStorage.setItem('user', userData);
    localStorage.setItem('givenName', givenName)
}

export const signup = (data: SignUpData): ThunkAction<void, RootState, null, AuthAction> =>{
    return async dispatch => {
        try {
            const res = await createUserWithEmailAndPassword(auth, data.email, data.password);
            if(res.user) {
                const userData: User = {
                    uid: res.user.uid,
                    fullName: data.fullName,
                    email: data.email,
                    phoneNumber: data.phone,
                    createdAt:serverTimestamp(),
                    role: roleTypeArray[3], //member
                    isGoogle: false,
                    isFacebook: false,
                    active: false,
                    userAvatar:'',
                    emailVerified: res.user.emailVerified
                };
                const usersRef = collection(firestore, 'users')
                await setDoc(doc(usersRef, userData.uid), userData)
                const givenName = userData.fullName ? userData.fullName?.split(' ')[0] : 'NoName';
                saveToLocalStorage(userData, givenName);
                dispatch({
                    type:SET_USER,
                    payload: {...userData, givenName}
                });
                if (!userData.emailVerified && auth.currentUser) {
                    await sendEmailVerification(auth.currentUser);
                    dispatch({
                        type: NEED_VERIFICATION
                    });
                }
            }
        } catch(err: any) {
            dispatch(setError(err.message));
        }
    }
}

//facebook Login
export const facebookSignin = () : ThunkAction<void, RootState, null, AuthAction> => {
    return async dispatch => {
        try {
            const res = await signInWithPopup(auth, facebookProvider);
            if(res.user) {
                const userDoc = doc(firestore, 'users', res.user.uid)
                const docSnap = await getDoc(userDoc)
                if(docSnap.exists()) {
                    const userData = docSnap.data() as User;
                    const givenName = userData.fullName?.split(' ')[0]
                    dispatch( {
                        type: SET_USER,
                        payload: {...userData, givenName}
                    });
                } else {
                    const userData: User = {
                        fullName: res.user.displayName?res.user.displayName:'',
                        email: res.user.email?res.user.email:'',
                        uid: res.user.uid,
                        createdAt:serverTimestamp(),
                        role: roleTypeArray[3], //member
                        isFacebook: true,
                        active: false,
                        userAvatar:res.user.photoURL ? res.user.photoURL : '',
                        emailVerified: res.user.emailVerified,
                    };
                    const usersRef = collection(firestore, 'users')
                    await setDoc(doc(usersRef, userData.uid), userData)
                    const givenName = userData.fullName ? userData.fullName?.split(' ')[0] : 'NoName';
                    saveToLocalStorage(userData, givenName);
                    dispatch({
                        type:SET_USER,
                        payload: {...userData, givenName}
                    });
                    if (!userData.emailVerified && auth.currentUser) {
                        await sendEmailVerification(auth.currentUser);
                        dispatch({
                            type: NEED_VERIFICATION
                        });
                    }
                }
            }
        } catch(err: any) {
            dispatch(setError(err.message));
        }
    }
}

//google login
export const googleSignin = (): ThunkAction<void, RootState, null, AuthAction> => {
    return async dispatch => {
        try {
            const res = await signInWithPopup(auth, googleProvider);
            if(res.user) {
                if(res.user) {
                    const userDoc = doc(firestore, 'users', res.user.uid)
                    const docSnap = await getDoc(userDoc)
                    if(docSnap.exists()) {
                        const userData = docSnap.data() as User;
                        const givenName = userData.fullName?.split(' ')[0]
                        dispatch( {
                            type: SET_USER,
                            payload: {...userData, givenName}
                        });
                    } else {
                        const usersRef = collection(firestore, 'users')
                        const userData: User = {
                            fullName: res.user.displayName?res.user.displayName:'',
                            email: res.user.email?res.user.email:'',
                            uid: res.user.uid,
                            createdAt: serverTimestamp(),
                            role: roleTypeArray[3], //member
                            isGoogle: true,
                            active: false,
                            userAvatar:res.user.photoURL ? res.user.photoURL : '',
                            emailVerified: res.user.emailVerified,
                        };
                        await setDoc(doc(usersRef, userData.uid), userData)
                        const givenName = userData.fullName ? userData.fullName?.split(' ')[0] : 'NoName';
                        saveToLocalStorage(userData, givenName);
                        dispatch({
                            type:SET_USER,
                            payload: {...userData, givenName}
                        });

                    }
                } 
            }
        } catch(err: any) {
            dispatch(setError(err.message));
        }
    }
}

//get user by ID
export const getUserById = (userId: string, isAnonym: boolean): ThunkAction<void, RootState, unknown, AuthAction> =>{ 
    return async dispatch => {
        try {
            if(!isAnonym) {
                const userDoc = doc(firestore, 'users', userId)
                const docSnap = await getDoc(userDoc)
                if(docSnap.exists()) {
                    const userData = docSnap.data() as User;
                    const givenName = userData.fullName?.split(' ')[0]
                    dispatch( {
                        type: SET_USER,
                        payload: {...userData, givenName}
                    });
                    
                }
            }
        }catch (err: any) {
            console.log(err.message);
        }
    }
}

//Set User Activity State
export const setUserActivityState = (userActivity: UserActivityState, setStore: boolean): ThunkAction<void, RootState, null, AuthAction> => {
    return async dispatch => {
        try{
            if(setStore){
                dispatch({
                    type:SET_USER_ACTIVITY_STATE,
                    payload: userActivity
                })
            } else{
                dispatch({
                    type:SET_USER_ACTIVITY_STATE,
                    payload:null
                })
            }

        } catch(err: any) {
            dispatch(setError(err.message))
        }
    }
}

//login
export const signin = (data: SignInData): ThunkAction<void, RootState, null, AuthAction> =>{ 
    return async dispatch => {
        try {
            await signInWithEmailAndPassword(auth, data.email, data.password);
        } catch(err: any) {
            dispatch(setError(err.message))
        }
    }
}

// logout
export const signout = () : ThunkAction<void, RootState, null, AuthAction> =>{ 
    return async dispatch => {
        try {
            await signOut(auth);
            dispatch({
                type: SIGN_OUT
            });
        } catch(err) {
            console.log(err);
            dispatch(setLoading(false));
        }
    }
}

//set need verification
export const setNeedVerification = (): ThunkAction<void, RootState, null, AuthAction> =>{ 
    return dispatch => {
        dispatch({
            type: NEED_VERIFICATION
        })
    }
}

//send pwd reset email
export const sendPasswordReset = (email: string, successMsg: string): ThunkAction<void, RootState, null, AuthAction> =>{ 
    return async dispatch => {
        try {
            await sendPasswordResetEmail(auth, email);
            dispatch(setSuccess(successMsg))
        } catch (err: any) {
            dispatch(setError(err.message))
        }
    }
}


export const updateUserInfo = (user: User, onError:() => void ): ThunkAction<void, RootState, null, AuthAction> =>{ 
    return async dispatch => {
        try {
            if(user) {
                let userData: User = {
                    ...user,
                    uid: user.uid,
                    lastModifiedAt:serverTimestamp(),
                    lastModifiedBy: user.uid,
                }

                const usersRef = collection(firestore, 'users')
                await setDoc(doc(usersRef, userData.uid), userData, { merge: true })
                const givenName = userData.fullName ? userData.fullName?.split(' ')[0] : 'NoName';
                saveToLocalStorage(userData, givenName);
                dispatch({
                    type:SET_USER,
                    payload: {...userData, givenName}
                });
                dispatch(setLoading(false))

            } else {
                return;
            }

        } catch (err: any) {
            dispatch(setError(err.message))
        }
    }
}

export const updateUserAvatar = (file: File, user: User): ThunkAction<void, RootState, null, AuthAction> => {
    return async dispatch=> {
        try{
            if(file && user) {

                const randomId = Math.random().toString(36).substring(2, 8);
                const storageRoute = user.avatarStorageRef ? user.avatarStorageRef : `userAvatar/${user.uid + randomId}` //removed S from the backend
                const storage = getStorage();
                const spaceRef = ref(storage, storageRoute)
                await uploadBytesResumable(spaceRef, file)
                const newAvatarUrl = await getDownloadURL(spaceRef)
                let userData: User = {
                    ...user,
                    userAvatar: newAvatarUrl,
                    avatarStorageRef: storageRoute,
                    lastModifiedAt:serverTimestamp(),
                    lastModifiedBy: user.uid,
                }
                const usersRef = collection(firestore, 'users')
                await setDoc(doc(usersRef, userData.uid), userData, { merge: true })
                const givenName = userData.fullName ? userData.fullName?.split(' ')[0] : 'NoName';
                saveToLocalStorage(userData, givenName);
                dispatch({
                    type:SET_USER,
                    payload: {...userData, givenName}
                });
                dispatch(setLoading(false))
            }

        }catch (err: any) {
            dispatch(setError(err.message))
        }
    }
}