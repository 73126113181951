import React, {FC, useEffect, useState} from 'react';
import { 
    ComissionSaved,
    ComissionItem
} from '../../../types/storeTypes';
import { getMonthYear, getTodayDate } from '../../../features/functions/handleDates';


import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface ComissionSummaryViewParams {
    comissionToDisplay?:ComissionSaved,
    comissionsAlone?:any,
    deductionsAlone?:any,
}

const SummaryViewCard: FC<ComissionSummaryViewParams> = (props) => {

    const { comissionToDisplay, comissionsAlone, deductionsAlone }=props
    const MonthYear: string = getMonthYear();
    const TodayDate: string = getTodayDate(); 

    const currencyFormat = new Intl.NumberFormat(undefined, {
        style:'currency',
        currency:'MXN'
    })

    const [expandComissionsBox, setExpandComissionsBox] = useState<boolean>(false)
    const [expandDeductionsBox, setExpandDeductionsBox] = useState<boolean>(false)
    const [comissionSubTotal, setComissionSubTotal] = useState<number>(0)
    const [deductionSubTotal, setDeductionSubTotal] = useState<number>(0)
    const [comissionsTotal, setComissionsTotal] = useState<number>(0)

    const comissionsOnly: Array<ComissionItem> | undefined = comissionToDisplay ? 
        comissionToDisplay.comissions?.filter((comission: ComissionItem) => comission.type === 0) : 
        comissionsAlone ? comissionsAlone : undefined

    const deductionsOnly: Array<ComissionItem> | undefined = comissionToDisplay ?
        comissionToDisplay.comissions?.filter((comission: ComissionItem) => comission.type === 1) :
        deductionsAlone ? deductionsAlone : undefined

    useEffect(() => {
        if(comissionsOnly) {
            let comissionsSub:number = 0
            for(const comm of comissionsOnly) {
                comissionsSub = comm.totalAmount ? comissionsSub+comm.totalAmount : comissionsSub
            }
            setComissionSubTotal(Math.round(comissionsSub*100)/100)
        }
        if(deductionsOnly) {
            let deductionSub:number = 0
            for(const comm of deductionsOnly) {
                deductionSub = comm.totalAmount ? deductionSub+comm.totalAmount : deductionSub
            }
            setDeductionSubTotal(Math.round(deductionSub*100)/100)
        }
        setComissionsTotal(Math.round((comissionSubTotal-deductionSubTotal)*100)/100)

    },[
        comissionsOnly,
        deductionsOnly,
        comissionSubTotal,
        deductionSubTotal
    ])
    

    return ( 
    <Card elevation={2}>
        <CardContent>
            <Grid item xs={12}>
                <Box sx={{
                    display:{xs:'block', md:'flex'},
                    flexDirection:{xs:'column', md:'row'},
                    justifyContent:{xs:'flex-start', md:'space-between'},
                    alignContent:{xs:'flex-start', md:'space-between'}
                }}>
                    <Box sx={{width:{xs:'100%', md:'50%'}}} 
                            >
                        <Typography sx={{textTransform:'capitalize'}} variant="h6" component="div">
                            Resumen {comissionToDisplay ? TodayDate : MonthYear}
                        </Typography>
                        <Typography variant='caption' sx={{fontWeight:400, color:'grey.700', mt:1}}>
                            Total de comisiones registradas al día de hoy.
                        </Typography>
                    </Box>
                    <Typography variant="h5" sx={{fontWeight:700}}>
                        Total: {currencyFormat.format(comissionsTotal)}
                    </Typography>
                </Box>
            </Grid>
            <Grid container spacing={3} sx={{mt:.1}}>
                <Grid item xs={12}>
                    <List dense disablePadding> 
                        <ListItemButton 
                            onClick={() => setExpandComissionsBox(!expandComissionsBox)}
                            sx={{
                                borderBottom:1,
                                borderBottomColor:'grey.100',
                                borderLeft:expandComissionsBox?2:0,
                                borderLeftColor:expandComissionsBox?'secondary.main':'inherit'

                            }}
                            >
                            <ListItemIcon>
                                <CheckBoxIcon sx={{color: expandComissionsBox?'secondary.main' : 'secondary.main'}} />
                            </ListItemIcon>
                            <ListItemText 
                                primary={
                                    <Typography variant="body1" sx={{fontWeight:600}}>
                                        Comisiones
                                    </Typography>
                                }
                            />
                            <Typography variant="body1" sx={{fontWeight:600, mr:1}}>
                                {currencyFormat.format(comissionSubTotal)}
                            </Typography>
                            {expandComissionsBox ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse 
                            in={expandComissionsBox} 
                            timeout="auto" 
                            unmountOnExit
                        >
                            <Box
                                sx={{
                                    bgcolor:'grey.100',
                                    borderLeft:2,
                                    borderLeftColor:'secondary.main',
                                    overflowY:'scroll',
                                    height:'50vh',
                                    maxHeight:'50vh'
                                }}
                            >
                                <List component="div" disablePadding>
                                    {comissionsOnly && comissionsOnly.map((comission:ComissionItem, index:number) => {
                                        return(
                                            <ListItem 
                                                key={index}
                                                sx={{borderBottom:.5, borderColor:'grey.300'}}
                                                >
                                                <ListItemText
                                                    primary={
                                                        <Box className='d-flex-row-between-centered'>
                                                            <Typography variant='body1' sx={{fontWeight:500}}>
                                                                {comission.name}
                                                                <Typography sx={{ml:1, fontStyle:'italic'}} variant="body2" component="span">
                                                                    {
                                                                        comission.subtype ? comission.subtype :
                                                                        comission.cost && comission.percentage ?
                                                                        `$${comission.cost} (Com: ${comission.percentage}%)` 
                                                                        : ''
                                                                    }
                                                                </Typography>
                                                            </Typography>
                                                            { comission.totalAmount ? 
                                                                <Box>
                                                                    <Typography align="right" variant='body2'>
                                                                        {comission.todayDate}
                                                                    </Typography>
                                                                    <Typography align="right" variant='body2'>
                                                                        Cantidad: {comission.qty}
                                                                    </Typography>
                                                                    <Typography align="right" variant='body1' sx={{fontWeight:500}}>
                                                                        {currencyFormat.format(comission.totalAmount)}
                                                                    </Typography>
                                                                </Box>
                                                                :null
                                                            }
                                                        </Box>
                                                    }
                                                    >
                                                    
                                                </ListItemText>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Box>
                        </Collapse>
                    </List>
                </Grid>
                <Grid item xs={12}>
                    <List dense disablePadding>
                        <ListItemButton 
                            onClick={() => setExpandDeductionsBox(!expandDeductionsBox)}
                            sx={{
                                borderBottom:1,
                                borderBottomColor:'grey.100',
                                borderLeft:expandDeductionsBox?2:0,
                                borderLeftColor:expandDeductionsBox?'secondary.main':'inherit'

                            }}
                            >
                            <ListItemIcon>
                                <CheckBoxIcon sx={{color: expandDeductionsBox?'secondary.main' : 'secondary.main'}} />
                            </ListItemIcon>
                            <ListItemText 
                                primary={
                                    <Typography variant="body1" sx={{fontWeight:600}}>
                                        Préstamos
                                    </Typography>
                                }
                            />
                            <Typography variant="body1" sx={{fontWeight:600, mr:1, color:'error.dark'}}>
                              -{currencyFormat.format(deductionSubTotal)}
                            </Typography>
                            {expandDeductionsBox ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton> 
                        <Collapse 
                            in={expandDeductionsBox} 
                            timeout="auto" 
                            unmountOnExit
                        >
                            <Box
                                sx={{
                                    bgcolor:'grey.100',
                                    borderLeft:2,
                                    borderLeftColor:'secondary.main',
                                    overflowY:'scroll',
                                    height:'50vh',
                                    maxHeight:'50vh'
                                }}
                            >
                                <List component="div" disablePadding>
                                    {deductionsOnly && deductionsOnly.map((deduction:ComissionItem, index:number) => {
                                        return(
                                            <ListItem 
                                                key={index}
                                                sx={{borderBottom:.5, borderColor:'grey.300'}}
                                                >
                                                <ListItemText
                                                    primary={
                                                        <Box className='d-flex-row-between-centered'>
                                                                <Typography variant='body1' sx={{fontWeight:500}}>
                                                                    {deduction.name}
                                                                </Typography>
                                                                {deduction.totalAmount ?
                                                                    <Box>
                                                                        <Typography align="right" variant='body2'>
                                                                            {deduction.todayDate}
                                                                        </Typography>
                                                                        <Typography align="right" variant='body1' sx={{fontWeight:500}}>
                                                                            {currencyFormat.format(deduction.totalAmount)}
                                                                        </Typography>
                                                                    </Box>
                                                                    :null
                                                                }
                                                        </Box>
                                                    }
                                                    >
                                                    
                                                </ListItemText>
                                            </ListItem>
                                        )
                                    })}
                                </List>

                            </Box>
                        </Collapse>
                    </List>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
    )
}

export default SummaryViewCard