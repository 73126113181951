import React, { FC, useState, useEffect  }from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { User } from '../../../types/authTypes';
import { RootState } from '../../../store';
import { CarrierPrice } from '../../../types/storeTypes';
import { setLoading } from '../../../store/actions/genericActions';

import { packageTypes } from '../../../features/referenceData/appLists';
import { carrierServiceTypes } from '../../../features/referenceData/storeLists';
import { validatePositiveNumber, validateLengthInput } from '../../../features/functions/validatorField';

import MemoizedCarrierResultsForShipmentTable from '../../pages/shipments/CarrierResultsForShipmentTable';

import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

interface createShipmentProps {
    user:User
}

const CreateQuoteForm: FC<createShipmentProps> = (props) => {

    const {user} = props

    const dispatch= useDispatch();  
  
    const { loading } = useSelector((state: RootState) => state.generic);
    const carrierPrices: Array<CarrierPrice>|null = useSelector((state:RootState) => state.stores.carrierPrices)
    
    const [filteredCarriers, setFilteredCarriers] = useState<Array<CarrierPrice> |null>(carrierPrices?carrierPrices:null)
    const [peso, setPeso] = useState<number| null>(null)
    const [envio, setEnvio] = useState<string>(packageTypes[0])
    const [zipCodeOrigin, setZipCodeOrigin] = useState<string| null>('')
    const [zipCodeDestiny, setZipCodeDestiny] = useState<string| null>('')
    const [qty, setQuantity] = useState<number| null>(null)
    const [largo, setLargo] = useState<number| null>(null)
    const [ancho, setAncho] = useState<number| null>(null)
    const [alto, setAlto] = useState<number| null>(null)
    const [pesoVol, setPesoVol] = useState<number| null>(null)
    const [traceable,setTraceable] = useState<boolean>(true)
    const [pickup,setPickup] = useState<boolean>(false)
    const [useQuotedRates,setUseQuotedRates] = useState<boolean>(false)
    const [serviceType,setServiceType] = useState<string>(carrierServiceTypes[0])

    const [showResults, setShowResults] = useState<boolean>(false)
    
    
    const [formValid, setFormValid] = useState<boolean>(false);

    const [invalidPeso, setInvalidPeso] = useState<boolean>(false);
    const [invalidEnvio, setInvalidEnvio] = useState<boolean>(false);
    const [invalidZipCodeOrigin, setInvalidZipCodeOrigin] = useState<boolean>(false);
    const [invalidZipCodeDestiny, setInvalidZipCodeDestiny] = useState<boolean>(false);
    const [invalidQty, setInvalidQty] = useState<boolean>(false);
    const [invalidLargo, setInvalidLargo] = useState<boolean>(false);
    const [invalidAncho, setInvalidAncho] = useState<boolean>(false);
    const [invalidAlto, setInvalidAlto] = useState<boolean>(false);

    
    useEffect(() => {

        if(!invalidAlto&&!invalidLargo&&!invalidAncho&&largo&&ancho&&alto){
            const pesoVolCalc= Math.round(((largo*ancho*alto)/5000)*100)/100
            setPesoVol(pesoVolCalc)
        }
        if(
            (( envio===packageTypes[0]&&(//caja
                    peso!==null&&
                    envio!== null&&
                    qty!== null&&
                    zipCodeOrigin!=null&&
                    zipCodeDestiny!=null&&
                    largo!=null&&
                    ancho!=null&&
                    alto!=null
                )    
            )
            ||
            ( envio===packageTypes[1]&&(   //sobre
                    peso!==null&&
                    envio!== null&&
                    qty!== null&&
                    zipCodeOrigin!=null&&
                    zipCodeDestiny!=null
                ) 
            ))
        && 
            (
            !invalidPeso&&
            !invalidEnvio&&
            !invalidQty&&
            !invalidZipCodeDestiny&&
            !invalidZipCodeOrigin&&
            !invalidLargo&&
            !invalidAncho&&
            !invalidAlto
            )
            ){
                setFormValid(true)
            } else{
            setFormValid(false)
        }
  
    }, [
        peso,
        envio,
        qty,
        largo,
        zipCodeOrigin,
        zipCodeDestiny,
        ancho,
        alto,
        invalidPeso,
        invalidEnvio,
        invalidZipCodeDestiny,
        invalidZipCodeOrigin,
        invalidQty,
        invalidLargo,
        invalidAncho,
        invalidAlto,
        formValid
    ])

    const pesoHandler = (e:any) => {
        setPeso(e)
        setInvalidPeso(validatePositiveNumber(e))
    }
    const envioHandler = (e:SelectChangeEvent) => {
        const value=e.target.value as string
        setEnvio(value)
        setInvalidEnvio(validateLengthInput(50,value))
    }

    const zipCodeOriginHandler = (e: any) => {
        setZipCodeOrigin(e)
        setInvalidZipCodeOrigin(e.length !== 5)
        // setInvalidZipCodeOrigin(validateLengthInput(6,e))
    }

    const zipCodeDestinyHandler = (e: any) => {
        setZipCodeDestiny(e)
        setInvalidZipCodeDestiny(e.length !== 5)
        // setInvalidZipCodeDestiny(validateLengthInput(6,e))
    }
    const quantityHandler = (e:any) => {
        setQuantity(e)
        setInvalidQty(validatePositiveNumber(e))
    }
    const largoHandler = (e:any) => {
        setLargo(e)
        if(envio===packageTypes[0])
        setInvalidLargo(validatePositiveNumber(e))
        if(envio===packageTypes[1])
        setInvalidLargo(false)
    }
    const anchoHandler = (e:any) => {
        setAncho(e)
        if(envio===packageTypes[0])
        setInvalidAncho(validatePositiveNumber(e))
        if(envio===packageTypes[1])
        setInvalidAncho(false)
    }
    const altoHandler = (e:any) => {
        setAlto(e)
        if(envio===packageTypes[0])
        setInvalidAlto(validatePositiveNumber(e))
        if(envio===packageTypes[1])
        setInvalidAlto(false)
    }
  
    const clearFieldsHandler = () => {
        clearFields();
        return;
    }

    const handleSetTraceable = (e:any) => {
        setTraceable(e)
    }
    const handleSetPickup = (e:any) => {
        setPickup(e)
    }
    const handleSetUseQuotedRates = (e:any) => {
        setUseQuotedRates(e)
    }
    const handleSetServiceType = (e:any) => {
        setServiceType(e)
    }
  
    const sendQuoteHandler = () => {
        if( user && formValid&&peso){
            const pesoToUse:number = pesoVol ? Math.max(peso,pesoVol):peso;
            setShowResults(true)
            setFilteredCarriers(carrierPrices&&carrierPrices.filter((carrier:CarrierPrice) => {
            return (
                pesoToUse>=carrier.kgStart
                &&pesoToUse<=carrier.kgEnd
                &&serviceType===carrier.serviceType?.name
                &&pickup===carrier.pickup
                &&traceable===carrier.traceable
                &&useQuotedRates===carrier.useQuotedRates
                )
            }
        ));
        }
    }

    const clearFields = () => {
        setEnvio(packageTypes[0])
        setZipCodeOrigin('')
        setZipCodeDestiny('')
        setQuantity(null)
        setLargo(null)
        setAncho(null)
        setAlto(null)
        setPesoVol(null)
        setTraceable(true)
        setPickup(false)
        setUseQuotedRates(false)
        setServiceType(carrierServiceTypes[0])
    }



    return (
        <Card elevation={0} sx={{borderRadius:'30px'}}>
            <CardContent>
                {!showResults?
                    <Box 
                        component="form"
                        sx={{
                            width:'100%',
                        }}
                        // onSubmit={submitHandler}
                        noValidate
                        autoComplete="off"
                        >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{fontWeight:600, mb:2}}>
                                    Ingresa la información del paquete
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl size='small' sx={{my:1}} color="secondary" fullWidth>
                                    <InputLabel id="package-type-label">Tipo de Paquete</InputLabel>
                                    <Select
                                        labelId="package-type-label"
                                        id="package-type"
                                        value={envio}
                                        label="Tipo de Paquete"
                                        onChange={envioHandler}
                                    >
                                        { packageTypes.map((type: string, index:number) => {
                                                return(
                                                    <MenuItem key={index}  sx={{boxShadow:0}} value={type}>{type}</MenuItem>
                                                )
                                            }
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    required
                                    size='small'
                                    color="secondary"
                                    value={zipCodeOrigin}
                                    fullWidth
                                    sx={{my:1}}
                                    onChange={(e:any) => zipCodeOriginHandler(e.currentTarget.value)}
                                    label="Código Postal Origen"
                                    type="number"
                                    error={invalidZipCodeOrigin}
                                    helperText={ invalidZipCodeOrigin ? 'Ingresa un número de seis dígitos':null}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    required
                                    size='small'
                                    color="secondary"
                                    value={zipCodeDestiny}
                                    fullWidth
                                    sx={{my:1}}
                                    onChange={(e:any) => zipCodeDestinyHandler(e.currentTarget.value)}
                                    label="Código Postal Destino"
                                    type="number"
                                    error={invalidZipCodeDestiny }
                                    helperText={ invalidZipCodeDestiny ? 'Ingresa un número de seis dígitos':null}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    required
                                    size='small'
                                    color="secondary"
                                    value={peso?peso:''}
                                    fullWidth
                                    sx={{my:1}}
                                    onChange={(e:any) => pesoHandler(e.currentTarget.value)}
                                    label="Peso (KG)"
                                    type="number"
                                    error={invalidPeso}
                                    helperText={ invalidPeso ? 'Ingresa un número mayor a 0':null}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    required
                                    size='small'
                                    color="secondary"
                                    value={qty?qty:''}
                                    fullWidth
                                    sx={{my:1}}
                                    onChange={(e:any) => quantityHandler(e.currentTarget.value)}
                                    label="Cantidad"
                                    type="number"
                                    error={invalidQty}
                                    helperText={invalidQty ?'Ingresa un número mayor a 0':null}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    size='small'
                                    required={envio===packageTypes[0]?true:false}
                                    disabled={envio===packageTypes[1]?true:false}
                                    color="secondary"
                                    variant='standard'
                                    value={largo?largo:''}
                                    fullWidth
                                    sx={{my:1}}
                                    onChange={(e:any) => largoHandler(e.currentTarget.value)}
                                    label="Largo (cm)"
                                    type="number"
                                    error={invalidLargo}
                                    helperText={invalidLargo ? 'Ingresa un número mayor a 0':null}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    size='small'
                                    required={envio===packageTypes[0]?true:false}
                                    disabled={envio===packageTypes[1]?true:false}
                                    color="secondary"
                                    variant='standard'
                                    value={ancho?ancho:''}
                                    fullWidth
                                    sx={{my:1}}
                                    onChange={(e:any) => anchoHandler(e.currentTarget.value)}
                                    label="Ancho (cm)"
                                    type="number"
                                    error={invalidAncho}
                                    helperText={invalidAncho ? 'Ingresa un número mayor a 0':null}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    size='small'
                                    required={envio===packageTypes[0]?true:false}
                                    disabled={envio===packageTypes[1]?true:false}
                                    color="secondary"
                                    variant='standard'
                                    value={alto?alto:''}
                                    fullWidth
                                    sx={{my:1}}
                                    onChange={(e:any) => altoHandler(e.currentTarget.value)}
                                    label="Alto (cm)"
                                    type="number"
                                    error={invalidAlto}
                                    helperText={invalidAlto? 'Ingresa un número mayor a 0':null}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography variant="caption">
                                    Peso Volumétrico (kg)
                                </Typography>
                                {envio===packageTypes[0] ?
                                    <Typography variant="h6" sx={{fontWeight:600}}>
                                    {pesoVol?pesoVol:0} kg
                                    </Typography>
                                    :
                                    <Typography variant="h6" sx={{fontWeight:600}}>
                                        NA
                                    </Typography>
                                }  
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Box className="d-flex-row-start-centered">
                                    <Typography sx={{mr:1}} variant="body2">Tipo de Servicio</Typography>
                                    <RadioGroup
                                        row
                                        aria-labelledby="row-tipo-servicio"
                                        name="row-tipo-servicio"
                                        value={serviceType}
                                        onChange={(e:any) => handleSetServiceType(e.currentTarget.value)}
                                    >
                                        <FormControlLabel value={carrierServiceTypes[0]} control={<Radio color="secondary" />} label={carrierServiceTypes[0]} />
                                        <FormControlLabel value={carrierServiceTypes[1]} control={<Radio color="secondary" />} label={carrierServiceTypes[1]} />
                                        <FormControlLabel value={carrierServiceTypes[2]} control={<Radio color="secondary" />} label={carrierServiceTypes[2]} />
                                    </RadioGroup>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <FormControlLabel control={
                                        <Checkbox onChange={(e:any) => handleSetTraceable(e.currentTarget.checked)}
                                        value={traceable} checked={traceable} color='secondary'/>
                                    } label="Rastreable" />
                                <FormControlLabel control={
                                        <Checkbox onChange={(e:any) => handleSetPickup(e.currentTarget.checked)}
                                        value={pickup} checked={pickup} color='secondary' />
                                    } label="Recolección" />
                                <FormControlLabel control={
                                        <Checkbox onChange={(e:any) => handleSetUseQuotedRates(e.currentTarget.checked)}
                                        value={useQuotedRates} checked={useQuotedRates} color='secondary' />
                                    } label="Utilice tarifas cotizadas" />
                            </Grid>
                        </Grid>
                    </Box>
                    :null
                }
                {showResults&&filteredCarriers&&filteredCarriers.length>0?
                    <MemoizedCarrierResultsForShipmentTable quoteOnly={true} carrierResults={filteredCarriers} groupSelected={'NA'} qty = {qty?qty:1}/>
                    :null
                }
                {showResults&&filteredCarriers&&filteredCarriers.length===0?
                    <Typography>No hay resultados, intenta una nueva búsqueda</Typography>
                    :null
                }
            </CardContent>
            <CardActions className="d-flex-row-end-centered">
                    <LoadingButton
                    loading={loading}
                    color="secondary"
                    sx={{my:1, bgcolor:'grey.50'}}
                    variant="text"
                    onClick={clearFieldsHandler}
                >
                    Borrar campos
                </LoadingButton>
                <LoadingButton
                    loading={loading}
                    color="secondary"
                    disableElevation
                    sx={{my:1}}
                    variant="contained"
                    disabled={!formValid}
                    onClick={sendQuoteHandler}
                >
                    Obtener cotización
                </LoadingButton>
            </CardActions>
        </Card>
    )
}

const MemoizedCreateQuoteForm = React.memo(CreateQuoteForm)

export default MemoizedCreateQuoteForm