import React, { FC, useState, useEffect  }from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {RootState} from '../../../store'
import { OptionsForShipment } from '../../../types/storeTypes';
import { setOptionsForShipment } from '../../../store/actions/genericActions';
import {  carrierServiceTypes } from '../../../features/referenceData/storeLists';

import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import LoadingButton from '@mui/lab/LoadingButton';

const ServicesForShipmentForm: FC = React.memo(() => {

    const dispatch= useDispatch();
    const {gralText,loading } = useSelector((state: RootState) => state.generic);
    useEffect(() => {})

    return (
        <Card elevation={0}>
            <CardContent>
                <Box 
                    component="form"
                    sx={{
                        width:'100%',
                    }}
                    // onSubmit={submitHandler}
                    noValidate
                    autoComplete="off"
                    >
                    <Typography align="center" variant="body1" sx={{my:3}}>
                        Proveedor de servicios de envios constesto:
                    </Typography>
                    <Typography align="center" variant="body2" sx={{my:3}}>
                        {gralText}
                    </Typography>
                    <Typography align="center" variant="body2" sx={{my:3}}>
                        <LoadingButton
                            loading={loading}
                            color="secondary"
                            sx={{my:1, bgcolor:'grey.50'}}
                            variant="text"
                            //onClick={()=>handleSteps(directionStepsForms[0])}
                            >
                            Crear Carta Porte 
                        </LoadingButton>
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )
})

export default ServicesForShipmentForm