import React, {FC} from 'react'

interface SvgParams {
  width:any,
  height:any
}

const EHIsotipo:FC<SvgParams> = (props) => {
  const{width, height} = props
  return (
    <svg width={width} height={height} viewBox="0 0 133 129" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1104_2)">
        <path d="M51.009 65.264C51.009 67.9022 48.8918 70.0226 46.3121 70.0226C43.7324 70.0226 41.5664 67.8775 41.5664 65.264C41.5664 62.6504 43.6837 60.4561 46.3121 60.4561C48.9404 60.4807 51.009 62.6258 51.009 65.264Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M74.9576 87.5776L68.3867 95.6401C67.7296 96.5031 66.7318 96.8976 65.7583 96.8976C64.7849 96.8976 63.8357 96.5031 63.1056 95.6401L56.5591 87.5776C54.7095 85.3093 56.2914 81.9561 59.1874 81.9561H72.3049C75.201 81.9561 76.7585 85.3093 74.9576 87.5776Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M89.9231 65.264C89.9231 67.9022 87.8058 70.0226 85.2261 70.0226C82.6464 70.0226 80.4805 67.8775 80.4805 65.264C80.4805 62.6504 82.6221 60.4561 85.2261 60.4561C87.8302 60.4807 89.9231 62.6258 89.9231 65.264Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M127.085 41.6929C127.085 41.6929 131.612 41.1751 129.519 28.0089C127.889 17.3821 114.698 14.1522 109.733 13.3139C113.603 13.7084 120.953 13.807 109.685 5.79383C96.1291 -3.77268 69.9915 4.48706 66.487 5.64589C62.9826 4.46241 36.7963 -3.77268 23.2894 5.79383C11.9972 13.7824 19.3712 13.7084 23.2408 13.3139C18.2517 14.1769 5.10993 17.3821 3.45503 28.0089C1.41075 41.1751 5.8887 41.6929 5.8887 41.6929C5.8887 41.6929 -2.16674 60.5794 2.89529 68.5926C7.93298 76.5811 14.6986 68.5926 14.6986 68.5926C14.6986 68.5926 6.27809 97.1442 18.1787 104.344C30.055 111.593 29.4709 104.344 29.4709 104.344C29.4709 104.344 33.8272 126.411 45.2411 127.57C53.5886 128.383 62.3741 126.386 66.5114 125.252C70.6486 126.386 79.4585 128.408 87.7817 127.57C99.2199 126.411 103.552 104.344 103.552 104.344C103.552 104.344 102.943 111.593 114.844 104.344C126.72 97.1195 118.324 68.5926 118.324 68.5926C118.324 68.5926 125.114 76.5811 130.127 68.5926C135.19 60.604 127.134 41.6929 127.134 41.6929H127.085ZM101.8 80.8219C99.2442 83.756 97.4433 87.3064 96.6645 91.1528C93.7441 105.724 81.0404 116.672 65.7813 116.672C50.5222 116.672 37.7941 105.7 34.898 91.1528C34.1193 87.3064 32.367 83.756 29.763 80.8219C23.8005 74.0415 20.5637 64.7709 21.7075 54.7606C21.7319 54.6126 21.7562 54.4647 21.7562 54.2921C22.9731 44.8242 28.3028 36.5398 35.8715 31.3128C39.5463 28.7485 43.7809 26.9486 48.2589 26.0857C54.5134 24.9022 60.4272 25.6172 65.7326 27.6144C71.038 25.6172 76.9518 24.9022 83.2064 26.0857C87.733 26.9486 91.9432 28.7732 95.5937 31.3128C103.162 36.5152 108.517 44.8242 109.709 54.2921C109.709 54.4401 109.758 54.588 109.758 54.7606C110.975 64.7956 107.665 74.0169 101.751 80.8219H101.8V80.8219Z" stroke="#4E02F7" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M35.5 31.0083C27.9313 36.2107 22.9731 44.8247 21.7806 54.2925C16.962 52.2214 13.6035 47.3889 13.6035 41.7427C13.6035 34.1979 19.6634 28.0586 27.1104 28.0586C30.4688 28.0586 33.5596 29.316 35.8959 31.3378L35.5 31.0083Z" stroke="#4E02F7" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M117.911 41.6933C117.911 47.3149 114.528 52.1721 109.758 54.2432C108.541 44.7753 103.211 36.4909 95.6426 31.2639C98.0032 29.2174 101.07 27.96 104.428 27.96C111.875 28.0093 117.911 34.124 117.911 41.6933Z" stroke="#4E02F7" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M109.832 54.7358C109.832 54.5878 109.784 54.4399 109.784 54.2673C108.567 44.7994 103.237 36.515 95.6683 31.2879C91.9934 28.7237 87.7588 26.9238 83.2809 26.0609C77.0263 24.8774 71.1125 25.5924 65.8071 27.5895C60.5017 25.5924 54.5879 24.8774 48.3334 26.0609C43.8068 26.9238 39.5965 28.7484 35.946 31.2879C28.3773 36.4903 23.0232 44.7994 21.8307 54.2673C21.8064 54.4152 21.7821 54.5632 21.7821 54.7358C20.6139 64.7954 23.875 74.0167 29.8375 80.7971C32.3929 83.7312 34.1938 87.2816 34.9725 91.128C37.893 105.7 50.5967 116.647 65.8558 116.647C81.1149 116.647 93.8187 105.675 96.7391 91.128C97.5178 87.2816 99.2944 83.7312 101.874 80.7971C107.764 73.9921 111.049 64.7461 109.881 54.7358H109.832V54.7358ZM46.3378 70.0225C43.7094 70.0225 41.5921 67.8774 41.5921 65.2638C41.5921 62.6503 43.7094 60.4559 46.3378 60.4559C48.9661 60.4559 51.0348 62.601 51.0348 65.2638C51.0104 67.9267 48.9175 70.0225 46.3378 70.0225ZM74.9577 87.5775L68.3868 95.64C67.7297 96.503 66.7319 96.8975 65.7585 96.8975C64.785 96.8975 63.8359 96.503 63.1058 95.64L56.5592 87.5775C54.7096 85.3092 56.2915 81.9559 59.1876 81.9559H72.305C75.2011 81.9559 76.7586 85.3092 74.9577 87.5775V87.5775ZM85.2035 70.0225C82.5994 70.0225 80.4578 67.8774 80.4578 65.2638C80.4578 62.6503 82.5994 60.4559 85.2035 60.4559C87.8075 60.4559 89.9004 62.601 89.9004 65.2638C89.9004 67.9267 87.7832 70.0225 85.2035 70.0225Z" stroke="#4E02F7" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M51.011 65.264C51.011 67.9022 48.8937 70.0226 46.314 70.0226C43.7343 70.0226 41.5684 67.8775 41.5684 65.264C41.5684 62.6504 43.6857 60.4561 46.314 60.4561C48.9424 60.4807 51.011 62.6258 51.011 65.264Z" stroke="#4E02F7" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M74.9576 87.5776L68.3867 95.6401C67.7296 96.5031 66.7318 96.8976 65.7583 96.8976C64.7849 96.8976 63.8357 96.5031 63.1056 95.6401L56.5591 87.5776C54.7095 85.3093 56.2914 81.9561 59.1874 81.9561H72.3049C75.201 81.9561 76.7585 85.3093 74.9576 87.5776Z" stroke="#4E02F7" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M89.9251 65.264C89.9251 67.9022 87.8078 70.0226 85.2281 70.0226C82.6484 70.0226 80.4824 67.8775 80.4824 65.264C80.4824 62.6504 82.6241 60.4561 85.2281 60.4561C87.8321 60.4807 89.9251 62.6258 89.9251 65.264Z" stroke="#4E02F7" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M74.9576 87.5776L68.3867 95.6401C67.7296 96.5031 66.7318 96.8976 65.7583 96.8976C64.7849 96.8976 63.8357 96.5031 63.1056 95.6401L56.5591 87.5776C54.7095 85.3093 56.2914 81.9561 59.1874 81.9561H72.3049C75.201 81.9561 76.7585 85.3093 74.9576 87.5776Z" stroke="#4E02F7" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M65.7578 116.672V96.8975" stroke="#4E02F7" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M51.011 65.264C51.011 67.9022 48.8937 70.0226 46.314 70.0226C43.7343 70.0226 41.5684 67.8775 41.5684 65.264C41.5684 62.6504 43.6857 60.4561 46.314 60.4561C48.9424 60.4807 51.011 62.6258 51.011 65.264Z" stroke="#4E02F7" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M89.9251 65.264C89.9251 67.9022 87.8078 70.0226 85.2281 70.0226C82.6484 70.0226 80.4824 67.8775 80.4824 65.264C80.4824 62.6504 82.6241 60.4561 85.2281 60.4561C87.8321 60.4807 89.9251 62.6258 89.9251 65.264Z" stroke="#4E02F7" strokeWidth="2" strokeMiterlimit="10"/>
        </g>
        <defs>
        <clipPath id="clip0_1104_2">
        <rect width="133" height="129" fill="white"/>
        </clipPath>
        </defs>
    </svg>
  )
}

export default EHIsotipo