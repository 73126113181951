export const rabeeApiHeader: HeadersInit={
    'User-Agent':'Apipost client Runtime/+https://www.apipost.cn/',
    'Content-Type': 'application/json'
}

export const redpackApiHeader: HeadersInit ={
    'Accept': 'application/x-www-form-urlencoded',
    'Content-Type': 'application/x-www-form-urlencoded',
}

export const estafetaApiHeader: HeadersInit = {
    'Accept': 'application/x-www-form-urlencoded',
    'Content-Type': 'application/x-www-form-urlencoded',
    // 'Access-Control-Allow-Origin': 'http://localhost:3000'
    // 'Access-Control-Allow-Headers': 'Content-Type',
    // 'Access-Control-Allow-Methods':'OPTIONS, DELETE, POST, GET, PATCH, PUT',
}

export const enviosRapiApiHeader: HeadersInit={
    'Content-Type': 'application/json',
    'Postman-Token': 'db265457-c0e6-4b95-b978-046c9f4197fb',
    'cache-control': 'no-cache'
}

export const estafetaServiceType: string [] = [
    'Día Siguiente Prepagado sin crédito',
    'Día Siguiente Prepagado',
    'Terrestre Prepagado sin crédito',
    'Terrestre Prepagado',
]