import React, {FC} from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import { User } from '../../../types/authTypes';

import RegisterComissionsCard from './RegisterComissionsCard';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';

const SubmitComission: FC = () => {

    const user: User | null = useSelector((state: RootState) => state.auth.user)
    
    return (
        <Container maxWidth='xl' sx={{bgcolor:'#F4F7F9', minHeight:'100vh'}}>
          <Toolbar sx={{mb:3}}/>
            { user ? 
                <Grid container spacing={2} sx={{width:'100%', px:{xs:.5,md:4}, pb:4}}>
                    <Box sx={{width:'100%', pl:2, mt:2}}>
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <RegisterComissionsCard user={user}/>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                :null
            }
        </Container>
    )
}

export default SubmitComission