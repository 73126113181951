import React, {FC} from 'react';
import { useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom'

import { User } from '../../../types/authTypes';
import ShipmentTable from './ShipmentTable';

import {RootState}from'../../../store';

import FilterShipments from '../../pages/shipments/FilterShipments';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

interface PackagesShippedProps {
    user:User,
    hideMenuActions: boolean,
    view?: string
}


const ShipmentTableCard: FC<PackagesShippedProps> = (props) => {

    const navigate = useNavigate()
    const { shipments } = useSelector((state: RootState) => state.apis);

    const {user, view, hideMenuActions} = props

    const navigateNewShipmentHandler = () => {
        navigate('/newShipment')
    }
    const navigateCreateQuoteHandler = () => {
        navigate('/quoter')
    }

    return (
        <Card elevation={0} sx={{borderRadius:'30px'}}>
            <CardContent>
                {shipments.data ?
                    <ShipmentTable shipments={shipments} hideMenuActions={hideMenuActions} />
                    : 
                    <Typography>Aún no tienes envíos</Typography>
                }
            </CardContent>
        </Card>
    )
}

export default ShipmentTableCard