export const SET_USER = 'SET_USER';
export const SIGN_OUT = 'SIGN_OUT';
export const NEED_VERIFICATION = 'NEED_VERIFICATION';
export const SET_USER_ACTIVITY_STATE = 'SET_USER_ACTIVITY_STATE';

export interface User {
    uid: string;
    fullName: string;
    email: string;
    createdAt: any;
    lastModifiedAt?: any;
    lastModifiedBy?: string;
    givenName?: string;
    birthday?: Date;
    balance?: number; //calculated Balance
    abono?: number; // calculated Abono
    userBalanceLogId?: string; //new to log abonos and add balance - UserBalance
    role?: string;
    phoneNumber?: any;
    about?: string;
    supervisor?: UserBasicData; //LATEST: this can be removed as franquicias are retreived based on Franchises collection including userID - comeback to check the type - REMOVED, replace by arrays
    currentStore?: StoreBasicData; //LATEST: this can be removed as franquicias are retreived based on Franchises collection including userID - 
    stores?: Array<StoreBasicData>; //LATEST: this can be removed as franquicias are retreived based on Franchises collection including userID - 
    storesIds?: Array<string>; //LATEST: this can be removed as franquicias are retreived based on Franchises collection including userID - 
    supervisorIds?: Array<string>; //LATEST: this can be removed as franquicias are retreived based on Franchises collection including userID - 
    salesTeam?: Array<UserBasicData>; //LATEST: this can be removed as franquicias are retreived based on Franchises collection including userID - 
    isGoogle?: boolean;
    isFacebook?: boolean;
    rapiApiCredentials?: any; //comback to this once rapiApi in
    userAvatar?: string | null;
    avatarStorageRef?: string | null;
    emailVerified?: boolean;
    active?: boolean;
}

export interface UserActivityState {
    active: boolean,
    balance?: number,
    abono?: number,
    about?: string,
    phoneNumber?: any,
    birthday?: Date;
}

export interface UserBalance {
    _id: string,
    userId: string,
    updates: Array<UserBalanceLog>
}

export interface UserBalanceLog {
    type: string; //abono, saldo
    amount: number;
    action: string; //agregar,quitar
    timestamp: any;
    updatedById: string;
    updatedbyName: string;
    comment: string;
}

export interface UserBasicData {
    uid: string;
    userName?: string;
    // userEmail?: string;
    // addedAt?: any;
    // deactivatedAt?: any;
    // active?: boolean;
}

export interface StoreBasicData {
    _id:string;
    storeName: string;
    supervisor?:UserBasicData;
    // storeAvatar: string| null;
    // location: StoreAddress;
    // isCurrent?: boolean;
    // salesTeam?: Array<UserBasicData>; // Uids
}

export interface StoreAddress {
    address: string;
    neighborhood: string;
    state: string;
    country: string;
    phoneNumber: string;
}


export interface AuthState {
    user: User | null;
    authenticated: boolean;
    needVerification: boolean;
    userActivityState: UserActivityState | null
}

export interface SignUpData {
    fullName: string;
    email: string;
    phone:string;
    password: string;
}

export interface SignInData {
    email: string;
    password: string;
}

// Actions

interface SetUserAction {
    type: typeof SET_USER;
    payload: User;
}

interface SetUserActivityState {
    type: typeof SET_USER_ACTIVITY_STATE
    payload: UserActivityState | null;
}

interface SignOutAction {
    type: typeof SIGN_OUT;
}

interface NeedVerificationAction {
    type: typeof NEED_VERIFICATION;
}

export type AuthAction = SetUserAction  | SignOutAction  | NeedVerificationAction | SetUserActivityState ;