import { PackageBasicData, OptionsForShipment, CarrierPrice, UserDirectoryFav } from "./storeTypes";
import { StoreBasicData, User } from './authTypes';
import { calculateMarginPct, generateHappyId } from '../features/functions/genericFunctions';
import { collection, doc, setDoc, serverTimestamp, addDoc, where, query, getDocs, Timestamp} from 'firebase/firestore';
import { stateToID } from "../features/referenceData/appLists";
import moment from "moment"
import { AriaAttributes } from "react";
import { Console } from "console";

export const SET_ACTIVE_APIS = 'SET_ACTIVE_APIS';
export const SET_SHIPMENTS = 'SET_SHIPMENTS';

export interface ApiState {
    activeApis: Array<ApiConnection> | null,
    shipments: {data: Array<ShipmentDataHolder> |null,
        page: number | undefined,
        count: number | undefined,
        isLoading : boolean,
        isBulkData : boolean}
}

export interface ApiConnection {
    _id: string,
    provider: string,
    production: boolean,
    prodCredentials: ApiCredentials,
    testCredentials: ApiCredentials,
    IsPercentagedPrice: boolean,
    cancellWaybill: ApiMethod,
    createWaybill: ApiMethod,
    label: ApiMethod,
    trackWaybill: ApiMethod,
    updateWaybill: ApiMethod,
    requestToken: ApiMethod,
    registerUser?: ApiMethod,
    dynamicPrice? : true
}

export interface ApiMethod {
    header: string,
    method: string,
    prodUrl: string,
    testUrl: string
}

export interface RapiApiRegisterUser {
    name: string
    last_name: string,
    email: string,
    password: string,
    phone: string
}

export interface ApiCredentials {
    customerNmbr: string, //client_id for Redpack
    password: string, //portal pwd for redpack
    user: string, //portal user for redpack
    base64Encoded: string
    apiKey?:string,
    apiSecretKey?: string,//client_secret,
    serviceList : Array<carrierServiceType>,
    others: any
}

export interface carrierServiceType{
    name : string,
    val: string
}

export interface FedexCreateExpressOneLabelRequest{

}

//FEDEX EXPRESSLABEL REVIEW AND ADJUST FOR FedexCreateExpressOneLabelRequest
export interface Pokedex {
    labelResponseOptions: string;
    oneLabelAtATime:      boolean;
    requestedShipment:    RequestedShipment;
    accountNumber:        AccountNumber;
}

export interface AccountNumber {
    value: string;
}

export interface RequestedShipment {
    serviceType:               string;
    shipper:                   Shipper;
    recipients:                Recipient[];
    rateRequestType:           string[];
    labelSpecification:        LabelSpecification;
    customsClearanceDetail:    CustomsClearanceDetail;
    blockInsightVisibility:    boolean;
    shipDatestamp:             Date;
    pickupType:                string;
    edtRequestType:            string;
    shippingChargesPayment:    ShippingChargesPayment;
    packagingType:             string;
    totalPackageCount:         number;
    requestedPackageLineItems: RequestedPackageLineItem[];
}

export interface CustomsClearanceDetail {
    isDocumentOnly: string;
    dutiesPayment:  DutiesPayment;
    commodities:    Commodity[];
}

export interface Commodity {
    description:          string;
    countryOfManufacture: string;
    numberOfPieces:       string;
    weight:               Weight;
    cIMarksAndNumbers:    string;
    exportLicenseDetail:  ExportLicenseDetail;
    quantity:             string;
    quantityUnits:        string;
    unitPrice:            CustomsValue;
    customsValue:         CustomsValue;
}

export interface CustomsValue {
    amount:   string;
    currency: string;
}

export interface ExportLicenseDetail {
    number:         string;
    expirationDate: Date;
}

export interface Weight {
    value: string;
    units: string;
}

export interface DutiesPayment {
    paymentType: string;
}

export interface LabelSpecification {
    imageType:                string;
    labelFormatType:          string;
    labelStockType:           string;
    labelPrintingOrientation: string;
    autoPrint:                boolean;
}

export interface Recipient {
    contact: RecipientContact;
    address: Address;
}

export interface Address {
    city:                string;
    stateOrProvinceCode: string;
    postalCode:          string;
    countryCode:         string;
    residential:         boolean;
    streetLines:         string[];
}

export interface RecipientContact {
    personName:    string;
    phoneNumber:   number;
    companyName:   string;
    emailAddress?: string;
}

export interface RequestedPackageLineItem {
    sequenceNumber:   number;
    weight:           Weight;
    subPackagingType: string;
}

export interface Shipper {
    address: Address;
    contact: RecipientContact;
    tins:    Tin[];
}

export interface Tin {
    number:  string;
    tinType: string;
    usage:   string;
}

export interface ShippingChargesPayment {
    payor:       Payor;
    paymentType: string;
}

export interface Payor {
    responsibleParty: ResponsibleParty;
}

export interface ResponsibleParty {
    address:       Address;
    contact:       ResponsiblePartyContact;
    accountNumber: AccountNumber;
}

export interface ResponsiblePartyContact {
    personName:   string;
    emailAddress: string;
    phoneNumber:  string;
    companyName:  string;
}
//FEDEX EXPRESSLABEL REVIEW AND ADJUST FOR FedexCreateExpressOneLabelRequest

export interface Goods{
    enName: string,
    quantity: number
}

// Actions

interface SetActiveApisAction {
    type: typeof SET_ACTIVE_APIS;
    payload: Array<ApiConnection>;
}
export interface SetShipmentsAction {
    type: typeof SET_SHIPMENTS;
    payload: {data : Array<ShipmentInterface>, page : number, count : number, isLoading : boolean, isBulkData : boolean};
}

export interface ShipmentInterface {
    apiCreatedWaybill: Waybill;  //K
    apiTrackNumber?:string;      //K
    happyTrackNumber: string;   //K
    labelInfo:LabelData;       //K
    groupId:string;             //K
    groupName: string;          //K
    groupSupervisor: string;    //K
    groupSupervisorId: string;  //K
    createdBy:string;           //K
    createdByName:string;       //K
    lastModBy:string;           //K
    createdAt:any;              //K
    lastModAt:any;              //K
    packageToShip?:PackageBasicData;
    optionsForShipment?:OptionsForShipment;
    selectedCarrier?:CarrierPrice;
    _id?: string;
    status?: string;            //K
    statusId?: number;          //K
    provider: string;
}

export interface Waybill{
    goods: Array<Goods>;
    waybill: {
        hasCartaPorte: boolean,
        frCity:string,
        frCode:string,
        frCountry:string,
        frName:string,
        frCorporateName:string,
        frPhone:string,
        frEmail?:string,
        frState:string
        frStreet:string,
        frNeighborhood:string,
        frReference:string,
        frRFC:string,
        height:number,
        length:number,
        serviceType:string,
        size:number,
        toCity:string,
        toCode:string,
        toCountry:string,
        toName:string,
        toCorporateName:string,
        toPhone:string,
        toEmail?:string,
        toState:string,
        toStreet:string,
        toNeighborhood:string,
        toReference:string,
        toRFC:string,
        totalAmount:number
        waybillNo?:string,
        weight:number,
        width:number,
        reference?: string
    }
}

export interface LabelData{
    type?: string,
    data?: string
}

export class ShipmentDataHolder implements ShipmentInterface{
    apiCreatedWaybill: Waybill;  //K
    apiTrackNumber?:string;      //K
    happyTrackNumber: string;   //K
    labelInfo:LabelData;       //K
    groupId:string = "void";             //K
    groupName: string = "void";          //K
    groupSupervisor: string = "void";    //K
    groupSupervisorId: string = "void";  //K
    createdBy:string;           //K
    createdByName:string;       //K
    lastModBy:string;           //K
    createdAt:any;              //K
    lastModAt:any;              //K
    packageToShip?:PackageBasicData;
    optionsForShipment?:OptionsForShipment;
    selectedCarrier?:CarrierPrice;
    _id?: string;
    status?: string;            //K
    statusId?: number;          //K
    provider: string;           //K*/
    constructor(
        user: User, 
        origin : UserDirectoryFav, 
        destination: UserDirectoryFav,
        packageObj : PackageBasicData,
        price : CarrierPrice,
        franchise: StoreBasicData|undefined|null,
        provider : string
    ){
        const happyWaybillNbr = generateHappyId();
        const goods: Goods = {
            enName:packageObj.contenido?packageObj.contenido:'',
            quantity: packageObj.qty?packageObj.qty:0
        }
        const wabillObject: Waybill = {
            goods:[goods],
            waybill: {
                frCity:origin.city?origin.city:'',
                frCode:origin.zipCode?origin.zipCode:'',
                frCountry:origin.country?origin.country:'',
                frName:origin.fullName?origin.fullName:'',
                frCorporateName:origin.company?origin.company:'',
                frPhone:origin.phone?origin.phone:'',
                frEmail:origin.email?origin.email:'',
                frState:origin.state?origin.state:'',
                frStreet:origin.address?origin.address:'',
                frNeighborhood:origin.neighborhood?origin.neighborhood:"",
                frReference:origin.reference?origin.reference:"",
                frRFC:origin.rfc?origin.rfc:"",
                height:packageObj.alto,
                length:packageObj.largo,
                serviceType:packageObj.tipoServicio?packageObj.tipoServicio:'STD',
                size: packageObj.qty,
                toCity:destination.city?destination.city:'',
                toCode:destination.zipCode?destination.zipCode:'',
                toCountry:destination.country?destination.country:'',
                toName:destination.fullName?destination.fullName:'',
                toCorporateName: destination.company? destination.company:"",
                toPhone:destination.phone?destination.phone:'',
                toEmail:destination.email?destination.email:'',
                toState:destination.state?destination.state:'',
                toStreet:destination.address?destination.address:'',
                toNeighborhood:destination.neighborhood?destination.neighborhood:"",
                toReference:destination.reference?destination.reference:"",
                toRFC:destination.rfc?destination.rfc:"",
                totalAmount:price.amount,
                weight:packageObj.peso,
                width:packageObj.ancho,
                reference:packageObj.comments?packageObj.comments:'',
                hasCartaPorte:false
            }      
        };
        this.apiCreatedWaybill = wabillObject;
        this.happyTrackNumber = happyWaybillNbr;
        if(franchise){
            this.groupId = franchise._id;
            this.groupName = franchise.storeName;
            this.groupSupervisor = franchise.supervisor&&franchise.supervisor.userName?franchise.supervisor.userName:'sin supervisor';
            this.groupSupervisorId = franchise.supervisor&&franchise.supervisor&&franchise.supervisor.uid?franchise.supervisor.uid:'sin supervisorId';
        }
        this.createdBy = user.uid;
        this.createdByName = user.fullName;
        this.status ='Iniciado';
        this.statusId = 0;
        this.lastModBy = user.uid;
        this.createdAt = serverTimestamp();
        this.lastModAt = serverTimestamp();
        this.provider = provider;
        this.packageToShip = packageObj;
        this.selectedCarrier = price;
        this.labelInfo = {
            type : "",
            data : ""
        };
    }
}

abstract class transactableShipment{
    //categories? : Array<number>;
    shipmentObj?: ShipmentDataHolder;
    user?: User;
    origin? : UserDirectoryFav; //k
    destination?: UserDirectoryFav; //K
    packageObj? : PackageBasicData; //k
    price? : CarrierPrice;  // K
    franchise?: StoreBasicData|undefined|null;
    provider? : string;
    isObjComplete: boolean = false;
    abstract returnInterface() : ShipmentInterface;
    //abstract calculateWeigthCategory():number;
    //abstract processData():void;
    abstract getTransactableDataObj() : any;
    abstract processCarrierApiResponse(value: any): Array<ShipmentInterface>;
    abstract getRatingDataObj(service : string) : any;
    constructor(provider: string){
        this.provider = provider;
    }
    isComplete(): boolean{
        return this.isObjComplete;
    }
    setCartaPorte(hasCartaPorte:boolean):void{
        if(this.shipmentObj){
            this.shipmentObj.apiCreatedWaybill.waybill.hasCartaPorte = hasCartaPorte;
        }  
    }
    
    setPesoFinal(peso: number):void{
        if(this.packageObj)
            this.packageObj.pesoFinal = peso;
    }
    updateData(
        user?: User,
        origin? : UserDirectoryFav,
        destination?: UserDirectoryFav,
        packageObj? : PackageBasicData,
        price? : CarrierPrice,
        franchise?: StoreBasicData) : boolean{
            if(user)
                this.user = user;
            if(origin)
                this.origin = origin;
            if(destination)
                this.destination = destination;
            if(packageObj){
                this.packageObj = packageObj;
                if(this.packageObj.peso != undefined && this.packageObj.pesoVol != undefined)
                    this.setPesoFinal(this.packageObj.peso > this.packageObj.pesoVol?this.packageObj.peso:this.packageObj.pesoVol)
            }
            if(price)
                this.price = price;
            if(franchise)
                this.franchise = franchise;
            if(this.user && this.origin && this.destination && this.packageObj && this.price && this.franchise &&  this.provider){
                this.shipmentObj = new ShipmentDataHolder(
                    this.user as User,
                    this.origin as UserDirectoryFav,
                    this.destination as UserDirectoryFav,
                    this.packageObj as PackageBasicData,
                    this.price as CarrierPrice,
                    this.franchise as StoreBasicData, 
                    this.provider);
                this.shipmentObj.labelInfo.type = "base64"; // esto necesita pasarse como parametro o calcularse de otro modo (DB OBJ?)
                this.isObjComplete = true
                //this.processData();
            }else
                this.isObjComplete = false;
            return this.isObjComplete
        }
}

export class EstafetaShipment extends transactableShipment{
    suscriberId : string;
    customerNumber : string;
    salesOrganization: string;
    constructor(
        suscriberId : string,
        customerNumber : string,
        salesOrganization: string,
    ){
        super("Estafeta");
        //
        this.suscriberId = suscriberId;
        this.customerNumber = customerNumber;
        this.salesOrganization = salesOrganization;
        //this.processData();
    }
    processCarrierApiResponse(value: any): Array<ShipmentInterface> {
        let shipmentCol = new Array<ShipmentInterface>()
        if( value.data.data && value.data.labelPetitionResults.length >=1 && this.shipmentObj){
            for(let i = 0; i < value.data.labelPetitionResults.length; i++ ){
                for(let j = 0; j < value.data.labelPetitionResults[i].elements.length; j++){
                        shipmentCol.push({...this.shipmentObj})
                        shipmentCol[shipmentCol.length-1].apiTrackNumber = value.data.labelPetitionResults[i].elements[j].wayBill;
                        shipmentCol[shipmentCol.length-1].apiCreatedWaybill.waybill.waybillNo = value.data.labelPetitionResults[i].elements[j].trackingCode;
                        shipmentCol[shipmentCol.length-1].labelInfo.data = value.data.data;
                }
            }
            return shipmentCol;
        }
        return shipmentCol;

    }
    
    getRatingDataObj(service : string){
        return {};
    }
    returnInterface() : ShipmentInterface{
        return this.shipmentObj as ShipmentInterface;
    }

    getTransactableDataObj() {
        // condiciones por las cuales detener la transaccion 
        let itemDesc : Object;
        let contactOrigin;
        let contactDest;
        if(this.shipmentObj?.packageToShip && this.shipmentObj.packageToShip.envio == "Caja")
            itemDesc = {
                "parcelId": 4,
                "weight": this.shipmentObj.packageToShip.pesoFinal,
                "height": this.shipmentObj.packageToShip.alto,
                "length": this.shipmentObj.packageToShip.largo,
                "width": this.shipmentObj.packageToShip.ancho
            };
        else
            itemDesc = {
                "parcelId": 1,
                "weight": this.shipmentObj?.packageToShip?.pesoFinal,
            };
        
        if(this.shipmentObj?.apiCreatedWaybill.waybill.frEmail && this.shipmentObj.apiCreatedWaybill.waybill.frEmail !="")
            contactOrigin ={
                "corporateName": this.shipmentObj.apiCreatedWaybill.waybill.frCorporateName,
                "contactName": this.shipmentObj.apiCreatedWaybill.waybill.frName,
                "cellPhone": this.shipmentObj.apiCreatedWaybill.waybill.frPhone,
                "telephone": this.shipmentObj.apiCreatedWaybill.waybill.frPhone,
                "phoneExt": "",
                "email" : this.shipmentObj.apiCreatedWaybill.waybill.frEmail
            }
        else
            contactOrigin ={
                "corporateName": this.shipmentObj?.apiCreatedWaybill.waybill.frCorporateName,
                "contactName": this.shipmentObj?.apiCreatedWaybill.waybill.frName,
                "cellPhone": this.shipmentObj?.apiCreatedWaybill.waybill.frPhone,
                "telephone": this.shipmentObj?.apiCreatedWaybill.waybill.frPhone,
                "phoneExt": ""
            }

        if(this.shipmentObj?.apiCreatedWaybill.waybill.toEmail && this.shipmentObj.apiCreatedWaybill.waybill.toEmail !="")
            contactDest ={
                "corporateName": this.shipmentObj.apiCreatedWaybill.waybill.toCorporateName,
                "contactName": this.shipmentObj.apiCreatedWaybill.waybill.toName,
                "cellPhone": this.shipmentObj.apiCreatedWaybill.waybill.toPhone,
                "telephone": this.shipmentObj.apiCreatedWaybill.waybill.toPhone,
                "phoneExt": "",
                "email" : this.shipmentObj.apiCreatedWaybill.waybill.toEmail
            }
        else
            contactDest ={
                "corporateName": this.shipmentObj?.apiCreatedWaybill.waybill.toCorporateName,
                "contactName": this.shipmentObj?.apiCreatedWaybill.waybill.toName,
                "cellPhone": this.shipmentObj?.apiCreatedWaybill.waybill.toPhone,
                "telephone": this.shipmentObj?.apiCreatedWaybill.waybill.toPhone,
                "phoneExt": ""
            }
        const labelDef = {
            "wayBillDocument": {
                "aditionalInfo": this.shipmentObj?.packageToShip?.comments==""?"No Comments":this.shipmentObj?.packageToShip?.comments,
                "content": this.shipmentObj?.packageToShip?.contenido,
                "costCenter": "SPMXA12345",
                "customerShipmentId": null,
                "referenceNumber": "Ref1",
                "groupShipmentId": null
            },
            "itemDescription" : itemDesc,
            "serviceConfiguration": {
                "isServiceUsesKilos":"True",
                "quantityOfLabels": this.shipmentObj?.apiCreatedWaybill.waybill.size,
                "serviceTypeId": this.shipmentObj?.selectedCarrier?.serviceType?.val.trim(),
                "salesOrganization": this.salesOrganization,
                "originZipCodeForRouting": "06170",
                "isInsurance": false,
                "isReturnDocument": false
            },
            "location": {
                "isDRAAlternative": false,
                "origin": {
                    "contact": contactOrigin,
                    "address": {
                        "bUsedCode": false, 
                        "roadTypeAbbName": "-",
                        "roadName": this.shipmentObj?.apiCreatedWaybill.waybill.frStreet,
                        "townshipName": this.shipmentObj?.apiCreatedWaybill.waybill.frCity,
                        "settlementTypeAbbName": "-",
                        "settlementName": this.shipmentObj?.apiCreatedWaybill.waybill.frNeighborhood,
                        "zipCode": this.shipmentObj?.apiCreatedWaybill.waybill.frCode,
                        "countryName": "MEX",
                        "addressReference": this.shipmentObj?.apiCreatedWaybill.waybill.frReference==""?"-":this.shipmentObj?.apiCreatedWaybill.waybill.frReference,
                        "externalNum": "-"
                    }
                },
                "destination": {
                    "isDeliveryToPUDO": false,
                    "homeAddress": {
                        "contact": contactDest,
                        "address": {
                            "bUsedCode": false,
                            "roadTypeAbbName": "-",
                            "roadName": this.shipmentObj?.apiCreatedWaybill.waybill.toStreet,
                            "townshipName": this.shipmentObj?.apiCreatedWaybill.waybill.toCity,
                            "settlementTypeAbbName": "-",
                            "settlementName": this.shipmentObj?.apiCreatedWaybill.waybill.toNeighborhood,
                            "zipCode": this.shipmentObj?.apiCreatedWaybill.waybill.toCode,
                            "countryName": "MEX",
                            "addressReference": this.shipmentObj?.apiCreatedWaybill.waybill.toReference==""?"-":this.shipmentObj?.apiCreatedWaybill.waybill.toReference,
                            "externalNum": "-",
                            "indoorInformation": this.shipmentObj?.apiCreatedWaybill.waybill.toPhone
                        }
                    }
                }
            }
        };
        return {
            "identification": {
                "suscriberId": this.suscriberId,
                "customerNumber": this.customerNumber
            },
            "systemInformation": {
                "id": "AP01",
                "name": "AP01",
                "version": "1.10.20"
            },
            "labelDefinitions": [labelDef]
        }; 
    }
}

export class DHLShipment extends transactableShipment{
    categories = [
        //0, 1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70
    ];
    customerNmbr: string;
    constructor(
        customerNmbr: string
    ){
        super("DHL");
        //this.labelInfo.type = "base64";
        this.customerNmbr = customerNmbr;
        //this.processData();
    }

    processCarrierApiResponse(value: any): Array<ShipmentInterface> {
        let shipmentCol = new Array<ShipmentInterface>()
        if( value.data.packages.length >=1 && this.shipmentObj){
            for(let i = 0; i < value.data.packages.length; i++ ){
                shipmentCol.push({...this.shipmentObj})
                shipmentCol[shipmentCol.length-1].apiTrackNumber = value.data.packages[i].trackingNumber;
                shipmentCol[shipmentCol.length-1].apiCreatedWaybill.waybill.waybillNo = value.data.shipmentTrackingNumber;
                shipmentCol[shipmentCol.length-1].labelInfo.data = value.data.documents[0].content;
            }
            return shipmentCol;
        }
        return shipmentCol;
    } 
    getRatingDataObj( service: string){
        return {};
    }
    
    processData(): void {
        if(this.shipmentObj?.packageToShip)
            this.shipmentObj.packageToShip.pesoFinal = this.calculateWeigthCategory();
    }

    returnInterface() : ShipmentInterface{
        return this.shipmentObj as ShipmentInterface;
    }

    calculateWeigthCategory(): number {
        let pesoFinal = 0;
        if(this.shipmentObj?.packageToShip?.peso != undefined && this.shipmentObj.packageToShip?.pesoVol != undefined){
            if(this.shipmentObj.packageToShip.peso < this.shipmentObj.packageToShip.pesoVol){
                pesoFinal = this.shipmentObj.packageToShip.pesoVol;
            }else{
                pesoFinal = this.shipmentObj.packageToShip.peso;
            }
        }else{
            return -1;
        }
        return Math.ceil(pesoFinal);
    }

    getTransactableDataObj() {
        let packages : Object;
        let plannedShippingDateAndTime;
        let date = moment().add(this.shipmentObj?.selectedCarrier?.days,"days");
        let arrPackages = [];
        let quantity = Number(this.shipmentObj?.apiCreatedWaybill.goods[0].quantity)
        plannedShippingDateAndTime = date.format("YYYY-MM-DDThh:mm:ss [GMT]Z");
        if(this.shipmentObj?.packageToShip && this.shipmentObj.packageToShip.envio == "Caja"){
            packages = {
                "weight": this.shipmentObj.packageToShip.pesoFinal,
                "dimensions": {
                    "length": this.shipmentObj.packageToShip.largo,
                    "width": this.shipmentObj.packageToShip.ancho,
                    "height": this.shipmentObj.packageToShip.alto
                }
            };
        }else{
            packages = {
                "weight": this.shipmentObj?.packageToShip?.pesoFinal,
                "dimensions": {
                    "length": this.shipmentObj?.packageToShip?.largo,
                    "width": this.shipmentObj?.packageToShip?.ancho,
                    "height": this.shipmentObj?.packageToShip?.alto
                }
            };
        }
        for(let i = 0; i < quantity ; i++ ){
            arrPackages.push(packages);
        }
        const labelDef = {
            "productCode": this.shipmentObj?.selectedCarrier?.serviceType?.val,
            "plannedShippingDateAndTime": plannedShippingDateAndTime,
            "pickup": {
                "isRequested": false
            },
            "accounts": [
                {
                    "number": this.customerNmbr,
                    "typeCode": "shipper"
                }
            ],
            "outputImageProperties": {
                "imageOptions": [
                    {
                        "typeCode": "label",
                        "templateName": "ECOM26_84_001"
                    },
                    {
                        "typeCode": "waybillDoc",
                        "templateName": "ARCH_8X4",
                        "isRequested": true,
                        "hideAccountNumber": true,
                        "numberOfCopies": 1
                    }
                ],
                "splitTransportAndWaybillDocLabels": true,
                "allDocumentsInOneImage": true,
                "splitDocumentsByPages": true,
                "splitInvoiceAndReceipt": true
            },
            "customerDetails": {
                "shipperDetails": {
                    "postalAddress": {
                        "cityName": this.shipmentObj?.apiCreatedWaybill.waybill.frCity,
                        "countryCode": "MX",
                        "postalCode": this.shipmentObj?.apiCreatedWaybill.waybill.frCode,
                        "addressLine1": this.shipmentObj?.apiCreatedWaybill.waybill.frStreet
                    },
                    "contactInformation": {
                        "phone": this.shipmentObj?.apiCreatedWaybill.waybill.frPhone,
                        "companyName": this.shipmentObj?.apiCreatedWaybill.waybill.frCorporateName,
                        "fullName": this.shipmentObj?.apiCreatedWaybill.waybill.frName
                    }
                },
                "receiverDetails": {
                    "postalAddress": {
                        "cityName": this.shipmentObj?.apiCreatedWaybill.waybill.toCity,
                        "countryCode": "MX",
                        "postalCode": this.shipmentObj?.apiCreatedWaybill.waybill.toCode,
                        "addressLine1": this.shipmentObj?.apiCreatedWaybill.waybill.toStreet,
                        "countyName": this.shipmentObj?.apiCreatedWaybill.waybill.toCountry
                    },
                    "contactInformation": {
                        "phone": this.shipmentObj?.apiCreatedWaybill.waybill.toPhone,
                        "companyName": this.shipmentObj?.apiCreatedWaybill.waybill.toCorporateName,
                        "fullName": this.shipmentObj?.apiCreatedWaybill.waybill.toName,
                        "email": this.shipmentObj?.apiCreatedWaybill.waybill.toEmail
                    }
                }
            },
            "content": {
                "unitOfMeasurement": "metric",
                "incoterm": "DAP",
                "isCustomsDeclarable": false,
                "description": this.shipmentObj?.packageToShip?.contenido,
                "packages": arrPackages
            }

        };
        return labelDef
    }
}

export class FedexShipment  extends transactableShipment{
    customerNumber : string;
    //salesOrganization: string;
    constructor(
        //suscriberId : string,
        customerNumber : string,
        //salesOrganization: string,
    ){
        super("Fedex");
        //this.labelInfo.type = "base64";
       // this.suscriberId = suscriberId;
        this.customerNumber = customerNumber;
        //this.salesOrganization = salesOrganization;
        //this.processData();
    }

    processCarrierApiResponse(value: any): Array<ShipmentInterface> {
        let shipmentCol = new Array<ShipmentInterface>()
        if( value.data.output && value.data.output.transactionShipments.length >=1 && this.shipmentObj){
            for(let i = 0; i < value.data.output.transactionShipments.length; i++ ){
                for(let j = 0; j < value.data.output.transactionShipments[i].pieceResponses.length; j++){
                    for(let k = 0; k <value.data.output.transactionShipments[i].pieceResponses[j].packageDocuments.length; k++ ){
                        shipmentCol.push({...this.shipmentObj })
                        shipmentCol[shipmentCol.length-1].apiTrackNumber = value.data.output.transactionShipments[i].masterTrackingNumber;
                        shipmentCol[shipmentCol.length-1].apiCreatedWaybill.waybill.waybillNo = value.data.output.transactionShipments[i].pieceResponses[j].trackingNumber;
                        shipmentCol[shipmentCol.length-1].labelInfo.data = value.data.output.transactionShipments[i].pieceResponses[j].packageDocuments[k].encodedLabel
                    }
                }
            }
            return shipmentCol;
        }
        return shipmentCol;
    }

    getRatingDataObj(service : string){
        if(this.customerNumber && this.origin && this.destination, this.packageObj){
            return {
                "accountNumber": {
                    "value": this.customerNumber
                },
                "requestedShipment": {
                    "shipper": {
                    "address": {
                        "postalCode": this.origin?.zipCode,
                        "countryCode": "MX"
                    }
                    },
                    "recipient": {
                    "address": {
                        "postalCode": this.destination?.zipCode,
                        "countryCode": "MX"
                    }
                    },
                    "pickupType": "DROPOFF_AT_FEDEX_LOCATION",
                    "serviceType": service,
                    "packagingType": "YOUR_PACKAGING",
                    "rateRequestType": [
                    "LIST",
                    "ACCOUNT"
                    ],
                    "requestedPackageLineItems": [
                    {
                        "weight": {
                        "units": "KG",
                        "value": this.packageObj.pesoFinal
                        }
                    }
                    ]
                }
            };
        }
    }

    returnInterface() : ShipmentInterface{
        return this.shipmentObj as ShipmentInterface;
    }

    getTransactableDataObj() {
        // condiciones por las cuales detener la transaccion 
        let itemDesc : Object;
        if(this.shipmentObj?.packageToShip && this.shipmentObj?.packageToShip.envio == "Caja")
            itemDesc = {
                "parcelId": 4,
                "weight": this.shipmentObj.packageToShip.pesoFinal,
                "height": this.shipmentObj.packageToShip.alto,
                "length": this.shipmentObj.packageToShip.largo,
                "width": this.shipmentObj.packageToShip.ancho
            };
        else
            itemDesc = {
                "parcelId": 1,
                "weight": this.shipmentObj?.packageToShip?.pesoFinal,
            };

        const labelDef = {
            "labelResponseOptions": "LABEL",
            "mergeLabelDocOption": "LABELS_AND_DOCS",
            "requestedShipment": {
            "shipper": {
                "contact": {
                "personName": this.shipmentObj?.apiCreatedWaybill.waybill.frName,
                "phoneNumber": this.shipmentObj?.apiCreatedWaybill.waybill.frPhone,
                "companyName": this.shipmentObj?.apiCreatedWaybill.waybill.frCorporateName
                },
                "address": {
                "streetLines": [
                    this.shipmentObj?.apiCreatedWaybill.waybill.frStreet,
                    this.shipmentObj?.apiCreatedWaybill.waybill.frReference,
                    this.shipmentObj?.apiCreatedWaybill.waybill.frNeighborhood
                ],
                "city": this.shipmentObj?.apiCreatedWaybill.waybill.frCity,
                "stateOrProvinceCode": stateToID(this.shipmentObj?.apiCreatedWaybill.waybill.frState, "fedex"),
                "postalCode": this.shipmentObj?.apiCreatedWaybill.waybill.frCode,
                "countryCode": "MX"
                }
            },
            "recipients": [
                {
                "contact": {
                    "personName": this.shipmentObj?.apiCreatedWaybill.waybill.toName,
                    "phoneNumber": this.shipmentObj?.apiCreatedWaybill.waybill.toPhone,
                    "companyName": this.shipmentObj?.apiCreatedWaybill.waybill.toCorporateName
                },
                "address": {
                    "streetLines": [
                        this.shipmentObj?.apiCreatedWaybill.waybill.toStreet,
                        this.shipmentObj?.apiCreatedWaybill.waybill.toReference,
                        this.shipmentObj?.apiCreatedWaybill.waybill.toNeighborhood
                    ],
                    "city": this.shipmentObj?.apiCreatedWaybill.waybill.toCity,
                    "stateOrProvinceCode": stateToID(this.shipmentObj?.apiCreatedWaybill.waybill.toState, "fedex"),
                    "postalCode": this.shipmentObj?.apiCreatedWaybill.waybill.toCode,
                    "countryCode": "MX"
                },
                "deliveryInstructions" : this.shipmentObj?.apiCreatedWaybill.waybill.toReference?this.shipmentObj?.apiCreatedWaybill.waybill.toReference:""
                }
            ],
            "serviceType": this.shipmentObj?.selectedCarrier?.serviceType?.val,
            "packagingType": "YOUR_PACKAGING",
            "pickupType": "DROPOFF_AT_FEDEX_LOCATION",
            "blockInsightVisibility": false,
            "shippingChargesPayment": {
                "paymentType": "SENDER"
            },
            "labelSpecification": {
                "imageType": "PDF",
                "labelStockType": "PAPER_85X11_TOP_HALF_LABEL"
            },
            "requestedPackageLineItems": [
                {
                    "groupPackageCount": 1,
                    "itemDescription": this.shipmentObj?.packageToShip?.contenido,
                    "weight": {
                        "units": "KG",
                        "value": this.shipmentObj?.packageToShip?.pesoFinal
                    },
                    "dimensions" :{
                        "length" : this.shipmentObj?.packageToShip?.largo,
                        "width" : this.shipmentObj?.packageToShip?.ancho,
                        "height" : this.shipmentObj?.packageToShip?.alto,
                        "units" : "CM"
                    }
                }
            ]
            },
            "accountNumber": {
                "value": this.customerNumber
            }
        };
        return labelDef
    }
}

export class EnviosHappyShipment  extends transactableShipment{
    //customerNumber : string;
    //salesOrganization: string;
    constructor(
        //suscriberId : string,
        //customerNumber : string,
        //salesOrganization: string,
    ){
        super("EnviosHappy");
        //this.labelInfo.type = "base64";
       // this.suscriberId = suscriberId;
        //this.customerNumber = customerNumber;
        //this.salesOrganization = salesOrganization;
        //this.processData();
    }

    processCarrierApiResponse(value: any): Array<ShipmentInterface> {
        let shipmentCol = new Array<ShipmentInterface>()
        if( value.file && value.status == "success" && this.shipmentObj){
            shipmentCol.push({...this.shipmentObj })
            shipmentCol[0].apiTrackNumber = value.data.codigo_rastreo+"";
            shipmentCol[0].apiCreatedWaybill.waybill.waybillNo = value.data.codigo_rastreo+"";
            shipmentCol[0].labelInfo.data = value.file;
            return shipmentCol;
        }
        return shipmentCol;
    }

    getRatingDataObj(service : string){
        return '';
    }

    returnInterface() : ShipmentInterface{
        return this.shipmentObj as ShipmentInterface;
    }

    getTransactableDataObj() {
        let itemDesc : Object;
        const labelDef= {
            contenido : this.packageObj?.contenido,
            peso_kg : this.packageObj?.peso+"kg",
            peso_volumetrico : this.packageObj?.pesoVol+"kg",
            largo_cm : this.packageObj?.largo+"cm",
            ancho_cm : this.packageObj?.ancho+"cm",
            alto_cm : this.packageObj?.alto+"cm",
            referencia_paquete : this.packageObj?.comments,
            rem_nombres : this.origin?.fullName,
            rem_alias : this.origin?.alias,
            rem_phone : this.origin?.phone,
            rem_direccion1 : this.origin?.address,
            rem_direccion2 : this.origin?.address2?this.origin?.address2:'',
            rem_compania :this.origin?.company,
            rem_dom_colonia : this.origin?.neighborhood,
            rem_dom_cp : this.origin?.zipCode,
            rem_dom_pais : "MX",
            rem_dom_estado : this.origin?.state,
            rem_dom_ciudad : this.origin?.city,
            rem_dom_referencia : this.origin?.reference,
            dest_nombres : this.destination?.fullName,
            dest_alias : this.destination?.alias,
            dest_phone : this.destination?.phone,
            dest_compania : this.destination?.company,
            dest_direccion1 : this.destination?.address,
            dest_direccion2 : this.destination?.address2?this.destination?.address2:'',
            dest_dom_colonia : this.destination?.neighborhood,
            dest_dom_cp : this.destination?.zipCode,
            dest_dom_pais : "MX",
            dest_dom_estado : this.destination?.state,
            dest_dom_ciudad : this.destination?.city,
            dest_dom_referencia : this.destination?.reference
        }

        return labelDef
    }
}

export type ApiAction = SetActiveApisAction | SetShipmentsAction