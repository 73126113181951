import React, { FC, useState, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../store';
import { facebookSignin, googleSignin } from '../../store/actions/authActions';

import UserAccessForm from '../UI/forms/UserAccessForm'
import LoginVector from '../UI/assets/LoginVector'

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

const SignIn :FC= () => {
    const dispatch =  useDispatch();
    const navigate = useNavigate()

    const { loading } = useSelector((state: RootState) => state.generic);
    const [showSignIn, setShowSignIn] = useState<boolean>(true)
    const [showLogin, setShowLogin] = useState<boolean>(false)

    const signInHandler = () => {
        setShowSignIn(true)
        setShowLogin(false)
    }
    const loginHandler = () => {
        setShowLogin(true)
        setShowSignIn(false)
    }

    const retrievePasswordHandler = () => {
        navigate('/retrieve-password')
    }

    const loginFacebookHandler = (e: FormEvent) => {
        e.preventDefault();
        dispatch<any>(facebookSignin());
    }
    
    const loginGoogleHandler = (e: FormEvent) => {
        e.preventDefault();
        dispatch<any>(googleSignin());
    }
    
    const handleGoToTyc = () =>{
        navigate('/tyc')
    }
    const handleGoHome = () =>{
        navigate('/')
    }

    return (
        <Box sx={{p:0,m:0, display:'flex', flexDirection:'row'}} >
            <Box sx={{width: {xs:'100vw', md:'50vw'}, display:{xs:'none', md:'flex'}, height:'100vh'}} className='gradient-bg d-flex-col-center-around'>
                <Box component="div">
                    <Typography sx={{mt:3, fontWeight:400, color:'white'}} align="center" variant="h5" component="h6">
                        Bienvenido a 
                    </Typography>
                    <Typography align="center" variant="h5" component="h6">
                        <Link color='grey.50' sx={{cursor:'pointer', fontWeight:700, color:'white'}} variant="h5" underline='hover' onClick={handleGoHome}>
                        EnviosHappy 2.1.5 TEST
                        </Link>
                    </Typography>
                    <Typography sx={{mt:2, px:5, color:'white'}} align="center" variant="body2" component="h6">
                        Para poder accesar por favor llena los datos o accesa con tu cuenta
                    </Typography>
                    {/* <Typography sx={{mt:2, px:5, color:'white'}} align="center" variant="body2" component="h6">
                        Ingresa con tu cuenta o crea una
                    </Typography> */}
                    <LoginVector/>
                </Box>
                <Typography sx={{px:5, color:'white'}} align="center" variant="body2" component="h6">
                    Al registrar una cuenta usted confirma que acepta nuestros <Link color='grey.50' sx={{cursor:'pointer'}} variant="body2" underline='hover' onClick={handleGoToTyc}>Términos y Políticas</Link>
                </Typography>
            </Box>
            <Box sx={{width:{xs:'100vw', md:'50vw'}, height:'100vh', px:{xs:2, md:10}}} className='d-flex-col-center-centered'>
                <Box sx={{width:'100%'}}>
                    <Box sx={{width:'100%'}} className="d-flex-row-start-centered">
                        {showSignIn ?
                            <Typography sx={{fontWeight:700}} variant="h6" component="h6">
                                ¿Ya tienes una cuenta con correo?
                            </Typography>
                            :null
                        }
                        <LoadingButton
                            loading={loading}
                            size="small"
                            variant="contained"
                            sx={{ml:showSignIn ? 2: 0}}
                            color="secondary"
                            disableElevation
                            onClick={() => {
                                showSignIn? loginHandler() : signInHandler();
                            }}
                        >
                            {showSignIn ? 'Entra aquí':'Regresar a regístro'}
                        </LoadingButton>
                    </Box>
                    <Box sx={{width:'100%', mt:4}} className="d-flex-row-start-centered">
                        <LoadingButton
                            loading={loading}
                            color="secondary"
                            variant="outlined"
                            sx={{mb:2, width: {xs: '100%', md:'100%'}}}
                            onClick={loginGoogleHandler}
                            endIcon={
                                <svg viewBox="0 0 24 24" width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                                    <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                                        <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
                                        <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
                                        <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
                                        <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
                                    </g>
                                </svg>
                            }
                            >   
                            <Typography variant="body1" component="span" sx={{color:'#000'}}>
                                Accesa con Google
                            </Typography>
                        </LoadingButton>
                        {/*<LoadingButton
                                loading={loading}
                                color="secondary"
                                variant="outlined"
                                sx={{ml:1, mb:2, width: {xs: '100%', md:'60%'}}}
                                onClick={loginFacebookHandler}
                                endIcon={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 14222 14222">
                                        <circle cx="7111" cy="7112" r="7111" fill="#1977f3"/>
                                        <path d="M9879 9168l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9168z" fill="#fff"/>
                                    </svg>
                                }
                                >   
                                <Typography variant="body1" component="span" sx={{color:'#000'}}>
                                    Accesa con Facebook
                                </Typography>
                            </LoadingButton>*/}
                    </Box>
                    <Divider>
                        <Typography variant="subtitle1" sx={{my:3}} align="center">
                            {showSignIn ? 'O REGÍSTRATE' : showLogin ? 'Ingresa tus credenciales' : ''}
                        </Typography>
                    </Divider>
                </Box>
                <Box sx={{width:'100%'}}>
                    <UserAccessForm signIn={showSignIn} logIn={showLogin}  />
                    <Link
                        component="button"
                        align='center'
                        sx={{width:'100%'}}
                        variant='body1'
                        onClick={() => {
                            retrievePasswordHandler()
                        }}
                        >
                        Recupera tu contraseña aquí
                    </Link>
                </Box>
            </Box>
        </Box>
    )
}

export default SignIn