import React from 'react'

import PublicHeader from './PublicHeader';
import PublicFooter from './PublicFooter';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Policy = () => {
    return (
        <Container 
        maxWidth={false}
        disableGutters
        >
            <PublicHeader color="black" includeHomeBttn={true}/> 
            <Grid container>
                <Grid item xs={12} sx={{m:12}}>
                <Box sx={{width:'100%'}}>
                <Typography align="center" variant="h5" sx={{fontWeight:600, mb:5}}>
                POLÍTICA DE DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN, OPOSICIÓN Y REVOCACIÓN DEL CONSENTIMIENTO
                </Typography>
                <Typography variant="body1">
                El procedimiento para exigibilidad de los Derechos ARCO se sujetará a las
especificaciones precisadas por la Ley Federal de Protección de Datos Personales
en Posesión de Particulares, en su capítulo IV y al Reglamento de la ley antes
citada, en su capítulo VII sección I a la V., JAIR LEÓN FUERTES conocido social y
comercialmente e identificado para efectos del presente Aviso de Privacidad como
JAIR LEÓN FUERTES de antemano se compromete a dar el debido seguimiento al
desahogo del procedimiento respecto del ejercicio de los Derechos ARCO.
Deberá facilitarse la debida información de identificación del Titular de los Datos
Personales, así como datos de localización a efecto de realizar la debida notificación
y respuesta al proceso que inicie mediante el ejercicio de sus Derechos ARCO.
Tales datos serán debidamente resguardados a través del Aviso de Privacidad de
JAIR LEÓN FUERTES el cual puede ser solicitado en las oficinas de Calle Dionisio
Rodríguez #500, Colonia San Juan de Dios, Guadalajara, Jalisco, México C.P.
44360, o a través del correo electrónico: jairleon96@hotmail.com
</Typography>
<Typography variant="body1">
Cuando la queja sea relativa al ENVÍO DE PUBLICIDAD, se deberá de hacer claro
el señalamiento sobre si la Publicidad recibida es únicamente de JAIR LEÓN
FUERTES o de terceros relacionados, para realizar lo siguiente:
▪ Para dejar de recibir publicidad relativa a JAIR LEÓN FUERTES; se podrá
solicitar en las oficinas de atención al cliente o mediante solicitud enviada al
correo electrónico.
▪ Para dejar de recibir publicidad relativa a cualquier otro tercero; deberá
entregarse en las oficinas respectivas del tercero correspondiente.
En los trámites realizados para efectos de suspender la publicidad, se deberá
señalar precisamente, puntualmente y sin excepción el o los números telefónicos y/o
las direcciones de correo electrónico, de los que desea dejar de recibir la publicidad.
</Typography>
<Typography variant="h6">
Procedimiento de Exigibilidad de los Derechos ARCO –JAIR LEÓN FUERTES

DEFINICIONES
</Typography>
<Typography variant="body1">
ACCESO.- Es el Derecho de Conocer los Datos Personales que han sido
puestos a disposición de JAIR LEÓN FUERTES relativo al Titular de los
Datos Personales.
▪ RECTIFICACIÓN.- Es el Derecho de Modificar o Completar los Datos
Personales relativos al Titular cuando se encuentren incompletos o erróneos.
▪ CANCELACIÓN.- Procede cuando el Titular no guarde relación jurídica y
contractual con JAIR LEÓN FUERTES.

▪ OPOSICIÓN.- Es el Derecho del Titular de exigir que JAIR LEÓN FUERTES
no utilice cierta información para publicidad que genere alguna afectación a
su persona.
▪ REVOCACIÓN DEL CONSENTIMIENTO.- Es la anulación del
consentimiento a cargo del Titular respecto al uso de sus datos personales.
▪ TITULAR.- Es el Individuo que Goza de los Derechos ARCO, en este caso el
socio, cliente, usuario, proveedor, prestador de servicios o colaborador de
JAIR LEÓN FUERTES.
</Typography>
<Typography variant="h6">
SOLICITUD
</Typography>
<Typography variant="body1">

Según las especificaciones legales y las relativas a la Protección de Datos
Personales en su Art. 28 el Titular o su Representante legal podrán solicitar al
responsable en cualquier momento el Acceso, Rectificación, Cancelación u
Oposición, respecto de los datos personales con los que cuenta Jair León
Fuertes, quién dará seguimiento a dicha solicitud como lo indica el
REGLAMENTO.
II. Para el ejercicio de los Derechos ARCO se requiere la siguiente información
(en original y copia):

Solicitud Personal del Titular Solicitud a través de Representante

Legal

Presentar solicitud firmada Presentar solicitud firmada por el Titular y

Representante

Comprobante de domicilio actual y

vigente

Comprobante de domicilio actual y
vigente del Titular
Identificación oficial con fotografía. Identificación oficial del Titular y del
Representante Legal

Poder Notarial otorgado al Representante
para ejercer tales funciones.

La solicitud del Titular o la que presente el Representante Legal a cargo del
Titular deberá describir de manera clara y precisa, los datos personales
respecto de los cuales busca ejercer sus Derechos ARCO, solicitud que
deberá ir firmada por el Titular o por su Representante Legal en su caso.
</Typography>
<Typography variant="h6">
JUSTIFICACIÓN Y PROCEDIMIENTO
</Typography>
<Typography variant="body1">
Los Derechos ARCO serán exigibles a través de las especificaciones del
REGLAMENTO además de la debida identificación y acreditación del Titular

ante la institución y su relación con tales datos.
II. El derecho de ACCESO se reconocerá mediante señalamiento específico de
los datos personales a conocer. Tendrá 15 días desde la presentación de su
solicitud para revisar la información que JAIR LEÓN FUERTES ponga a su
disposición, ya sean datos personales o el Aviso de Privacidad; si el Titular de
los Datos Personales no hace exigible su derecho dentro de los 15 días, este
deberá presentar una solicitud nueva y la anterior quedará sin efectos. (Art.
23 de la LEY y 101-102 del REGLAMENTO)
III. El derecho de RECTIFICACIÓN de algún dato deberá presentar la
información relativa que permita su confirmación y la evidencia para realizar
la modificación o complemento, así como Identificación Oficial que lo acredite
como el Titular de los Datos Personales a modificar. Bajo ninguna
circunstancia un tercero ajeno podrá solicitar la modificación de las bases de
datos y de registros físicos de JAIR LEÓN FUERTES respecto del Titular de
los Datos Personales a excepción del Representante Legal (Art. 24 LEY y
103-104 del REGLAMENTO)
Ejemplo de Rectificación de

Datos Documentos Requeridos (Evidencia)
Cambio de Domicilio Comprobante de Domicilio Actual e INE
Cambio de Nombre Acta de Nacimiento (Nueva) e INE

IV. El derecho de CANCELACIÓN procede en los siguientes casos:
- Cuando el Titular de los Datos Personales no guarde relación jurídica o
contractual alguna con JAIR LEÓN FUERTES.
- Que ya existente la relación el Titular de los Datos Personales quiera
darla por terminada.
- Que existente la relación jurídica o contractual, únicamente quiera
cancelar el tratamiento de datos con respecto a las finalidades no
necesarias para la relación.
Si existiera ya una relación contractual el Titular deberá dar por
terminada(s) las relaciones contractuales pendientes antes de poder
cancelar todas las finalidades del tratamiento y el uso de sus Datos
Personales de los que JAIR LEÓN FUERTES es responsable. Tras
cancelar sus datos estos serán bloqueados del sistema para evitar que
sean tratados, sin embargo serán conservados por el periodo
mencionado en el siguiente párrafo para dar cumplimiento a
responsabilidades legales, para su posterior supresión (Arts. 25 - 26
LEY y 105-108 REGLAMENTO).

V. La cancelación de la información tendrá como consecuencia el retirar de uso
la información del Socio, Cliente, Usuario, Proveedor, Prestador de Servicio o
Colaborador; pero la información se mantendrá almacenada hasta que

prescriban las obligaciones legales respecto de su retención por 5 cinco años
en materia fiscal y por 10 diez años en materia civil y comercial. (Art. 30
Código Fiscal de la Federación y Art. 46 Código de Comercio).
VI. La REVOCACIÓN DEL CONSENTIMIENTO, procederá cuando el Titular no
tenga ninguna relación jurídica con JAIR LEÓN FUERTES, o que teniéndola,
la revocación únicamente se refiera a las finalidades no necesarias para la
relación jurídica. Tal revocación generará el Bloqueo de los Datos Personales
hasta que se proceda a la supresión de los mismos. La Revocación del
Consentimiento podrá dar por terminado los lazos pre-contractuales, y en el
caso de los Titulares que no tengan relación jurídica alguna, no habrá
necesidad de retener la información frente obligaciones legales pues no
existe lazo contractual o de responsabilidad.
VII. El Derecho de OPOSICIÓN, requerirá del señalamiento específico de los
datos a los que se opone que se esté dando un uso específico. Tales datos
deberán de no generar una afectación en su uso en contra del Titular pero a
su vez no afectar la relación jurídica con JAIR LEÓN FUERTES y las
obligaciones que tenga la Sociedad frente a la Autoridad (Art. 27 LEY y 109
REGLAMENTO).
VIII. Se entregará solicitud de ejercicio de los Derechos ARCO, la cual estará
firmada en cada una de sus hojas al calce por el Representante Legal de
JAIR LEÓN FUERTES y por el Titular y/o Representante Legal. La Solicitud
podrá ser en el formato que JAIR LEÓN FUERTES ponga a disposición del
Titular de los Datos Personales o en formato personalizado por el Titular de
los Datos Personales.
IX. JAIR LEÓN FUERTES deberá notificar en el plazo de 20 veinte días hábiles
una respuesta al Titular de los Datos Personales. En caso de que sea
procedente la solicitud de Derechos ARCO, el Responsable procederá a su
bloqueo dentro de los 15 quince días hábiles siguientes a la notificación. (Art.
32 de la LEY)
X. Si así fuera necesario desahogar el procedimiento contencioso, señalado en
el artículo 45 de la LEY y 113 del REGLAMENTO, éste deberá someterse a
la jurisdicción aplicable que designe el INAI y su normatividad.
</Typography>

            </Box>
            </Grid>
            </Grid>
            <PublicFooter includeHomeBttn={true}/>
        </Container>
      )
    }

export default Policy