import React from 'react'

import DirectoryCard from './DirectoryCard';

import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const DirectoryPage = () => {
  return (
    <Container maxWidth='xl' sx={{bgcolor:'#F4F7F9', minHeight:'100vh'}}>
      <Toolbar sx={{mb:3}}/>
        <Typography variant='h6' component="h6" sx={{fontWeight:500, mb:2}}>Directorio</Typography>
        <DirectoryCard />
    </Container>
  )
}

export default DirectoryPage