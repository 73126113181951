import { ThunkAction } from 'redux-thunk';
import { GenericAction, 
        SET_LOADING, 
        SET_ERROR, 
        SET_SUCCESS, 
        SET_DIALOG,
        SET_DIALOG_TYPE,
        SET_SHOW_FORM,
        SET_USER_DIRECTORY,
        SET_NEW_DOC_ID,
        SET_RIGHT_DRAWER,
        SET_LEFT_DRAWER,
        SET_USERS_ADMIN,
        SET_UPDATE_USER,
        SET_ORIGIN_DIR_SHIPMENT,
        SET_DESTINY_DIR_SHIPMENT,
        SET_PACKAGE_TO_SHIP,
        SET_OPTIONS_FOR_SHIPMENT,
        SET_DIALOG_BODY,
        SET_SELECTED_PRICE_CARRIER,
        GRAL_TEXT,
        SET_DESTINATION_EXTZONE,
        SET_ORIGIN_EXTZONE,
        RATING_UPDATE,
        SET_PUBLIC_PRICE,
        SET_WAYBILL_AVAILABLE
 } from '../../types/genericTypes';
 import { User } from '../../types/authTypes';
 import { PackageBasicData, UserDirectoryFav, OptionsForShipment, CarrierPrice, carrierExtendedZone } from '../../types/storeTypes';

import { RootState } from '..';
import { ShipmentInterface } from '../../types/apiTypes';

//set loading
export const setLoading = (value: boolean): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_LOADING,
            payload: value
        })
    }
}
//set dialog
export const setDialog = (value: boolean): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_DIALOG,
            payload: value
        })
    }
}
//set dialog type
export const setDialogType = (value: string): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_DIALOG_TYPE,
            payload: value
        })
    }
}

export const setTextMessage = (value: string): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: GRAL_TEXT,
            payload: value
        })
    }
}
export const setDialogBody = (object: ShipmentInterface | null): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_DIALOG_BODY,
            payload: object
        })
    }
}
export const setShowForm= (value: boolean): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_SHOW_FORM,
            payload: value
        })
    }
}
export const setPublicPrice= (value: boolean): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_PUBLIC_PRICE,
            payload: value
        })
    }
}
export const setUsersAdmin= (users: Array<User>): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_USERS_ADMIN,
            payload: users
        })
    }
}
export const setUserDirectory= (userDirectory: Array<UserDirectoryFav>): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_USER_DIRECTORY,
            payload: userDirectory
        })
    }
}

export const setOriginDirShipment= (setStore:boolean, dir?: UserDirectoryFav, saveInFavs?: boolean, role?: string):ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        if(setStore&&dir&&saveInFavs!==undefined&&role) {
            dispatch({
                type: SET_ORIGIN_DIR_SHIPMENT,
                payload: [dir, saveInFavs, role]
            })
        }
        if(!setStore&&!dir) {
            dispatch({
                type: SET_ORIGIN_DIR_SHIPMENT,
                payload: null
            })
        }
    }
}

export const setDestinyDirShipment= (setStore:boolean, dir?: UserDirectoryFav, saveInFavs?: boolean, role?: string):ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        if(setStore&&dir&&saveInFavs!==undefined&&role) {
            dispatch({
                type: SET_DESTINY_DIR_SHIPMENT,
                payload:[dir, saveInFavs, role]
            })
        }
        if(!setStore&&!dir) {
            dispatch({
                type: SET_DESTINY_DIR_SHIPMENT,
                payload:null
            })
        }
    }
}

export const setPackageToShip=(setStore:boolean, packageData?: PackageBasicData):ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        if(setStore&&packageData) {
            dispatch({
                type: SET_PACKAGE_TO_SHIP,
                payload:packageData
            })
        }
        if(!setStore&&!packageData) {
            dispatch({
                type: SET_PACKAGE_TO_SHIP,
                payload:null
            })
        }
    }
}

export const setOptionsForShipment=(setStore:boolean, optionsData?: OptionsForShipment):ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        if(setStore&&optionsData) {
            dispatch({
                type: SET_OPTIONS_FOR_SHIPMENT,
                payload:optionsData
            })
        }
        if(!setStore&&!optionsData) {
            dispatch({
                type: SET_OPTIONS_FOR_SHIPMENT,
                payload:null
            })
        }
    }
}

export const setSelectedPriceCarrier=(setStore:boolean, selectedPrice?: CarrierPrice):ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        if(setStore&&selectedPrice) {
            dispatch({
                type: SET_SELECTED_PRICE_CARRIER,
                payload:selectedPrice
            })
        }
        if(!setStore&&!selectedPrice) {
            dispatch({
                type: SET_SELECTED_PRICE_CARRIER,
                payload:null
            })
        }
    }
}

export const setUserToUpdate= (setExtUserInStore: boolean, userToUpdate?: User): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        if(setExtUserInStore&&userToUpdate){
            dispatch({
                type: SET_UPDATE_USER,
                payload: userToUpdate
            })
        }
        if(!setExtUserInStore&&!userToUpdate){
            dispatch({
                type: SET_UPDATE_USER,
                payload: null
            })
        }
    }
}
//set error
export const setError = (msg: string): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_ERROR,
            payload: msg
        });
    }
}
//set success
export const setSuccess = (msg: string):ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_SUCCESS,
            payload: msg
        });
    }
}
export const setRightDrawer = (value: boolean):ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_RIGHT_DRAWER,
            payload: value
        });
    }
}
export const setLefttDrawer = (value: boolean):ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_LEFT_DRAWER,
            payload: value
        });
    }
}
//Set new doc Id
export const setNewDocId = (projectId: string): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_NEW_DOC_ID,
            payload: projectId
        })
    }
}

export const updateCarrierRatings = (rating: any | null): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: RATING_UPDATE,
            payload: rating
        })
    }
}

export const setExtendedZone = (isDataAvailable: boolean, isOrigin: boolean, data: Array<carrierExtendedZone>): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        if(isOrigin)
            dispatch({
                type: SET_ORIGIN_EXTZONE,
                payload: {isAvailable: isDataAvailable, carriers : data}
            })
        else
            dispatch({
                type: SET_DESTINATION_EXTZONE,
                payload: {isAvailable: isDataAvailable, carriers : data}
            })
    }
}

export const shipmentToObj = (obj: ShipmentInterface): ShipmentInterface => {
    return  {
        apiCreatedWaybill : obj.apiCreatedWaybill,
        apiTrackNumber : obj.apiTrackNumber,
        happyTrackNumber : obj.happyTrackNumber,
        labelInfo : obj.labelInfo,
        groupId : obj.groupId,
        groupName : obj.groupName,
        groupSupervisor : obj.groupSupervisor,
        groupSupervisorId : obj.groupSupervisorId,
        createdBy : obj.createdBy,
        createdByName : obj.createdByName,
        lastModBy : obj.lastModBy,
        createdAt : obj.createdAt,
        lastModAt : obj.lastModAt,
        packageToShip : obj.packageToShip,
        selectedCarrier : obj.selectedCarrier,
        status : obj.status,
        statusId : obj.statusId,
        provider : obj.provider
    }
}
//set waybillAvailable
/*export const setWaybillAvailable = (value: boolean): ThunkAction<void, RootState, null, GenericAction> => {
    return dispatch => {
        dispatch({
            type: SET_WAYBILL_AVAILABLE,
            payload: value
        })
    }
}*/