import React from 'react'
import { useNavigate } from 'react-router-dom'

import PublicFooter from './PublicFooter';
import PublicHeader from './PublicHeader';

import EHLogotipo from '../../UI/assets/EHLogotipo'
import EHLionShipping from '../../UI/assets/EHLionShipping'
import PaqueteExpress from '../../UI/assets/carriers/PaqueteExpress';
// import Sendex from '../../UI/assets/carriers/Sendex';
import Estafeta from '../../UI/assets/carriers/Estafeta';
import DHL from '../../UI/assets/carriers/DHL';
import FedEx from '../../UI/assets/carriers/FedEx';
import UPS from '../../UI/assets/carriers/UPS';

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'

const Homepage = () => {

    const navigate = useNavigate()

    const handleLogin = () =>{
        navigate('/sign-in')
    }

    return (

    <Container 
    maxWidth={false}
    sx={{bgcolor:'#48147D'}}
    // className="linear-gradient-bg"
    disableGutters
    >
        <Grid container spacing={1}>
            <PublicHeader/> 
            <Grid item xs={12} md={6} sx={{height:{xs:'70vh', md:'80vh'}, width:'100%'}} className="d-flex-col-center-centered">
                <Box sx={{pl:{md:26, xs:4}, pr:4}}>
                    <Box sx={{display:{xs:'none', md:'block'}}}>
                        <EHLogotipo width={450} height={64}/>
                    </Box>
                    <Box sx={{display:{xs:'block', md:'none'}}}>
                        <EHLogotipo width={300} height={56}/>
                    </Box>
                    <Typography sx={{fontSize:{xs:28, md:40}, mt:{xs:4, md:6}, color:'white', fontWeight:700, lineHeight:'36px'}}>
                        Manda todo lo que se te ocurra a precios de locura
                    </Typography>
                    <Typography sx={{fontSize:{xs:14, md:16}, mt:2, color:'white'}}>
                        Entra a nuestro sistema de guías pre-pagadas donde 
                        descubrirás los mejores precios del mercado y
                        con servicio express
                    </Typography>
                    <LoadingButton
                        variant='outlined'
                        color="secondary"
                        size="large"
                        sx={{
                            width:'40%',
                            mt:5, 
                            color:'white', 
                            border:2, 
                            borderColor:'white', 
                            ":hover":{
                                color:'secondary.dark',
                                border:2, 
                                borderColor:'white', 
                                bgcolor:'white'
                            }
                        }}
                        onClick={handleLogin}
                        
                    >
                        Entrar
                    </LoadingButton>
                </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{height:{xs:'auto', md:'80vh'}, width:'100%', display:'flex', flexDirection:'column', justifyContent:{xs:'start', md:'center'}, alignItems:'center'}}>
                <Box sx={{p:0, m:0}}>
                    <EHLionShipping width="334" height="300"/>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{height:{xs:'35vh', md:'20vh'}, width:'100%'}}>
                <Box sx={{px:0, m:0, pb:{xs:4, md:6}, bgcolor:'#FFAA5C', width:'100%'}} className='d-flex-col-center-centered'>
                    <Typography sx={{textAlign:'center', width:'50%', fontSize:{xs:18, md:24}, mt:{xs:4, md:6}, color:'white', fontWeight:700, lineHeight:'28px'}}>
                        Nos importa que tú y tus clientes reciban todo en tiempo y forma
                    </Typography>
                    <Typography sx={{textAlign:'center', width:'50%', fontSize:{xs:14, md:16}, mt:2, color:'white'}}>
                        Te brindamos las herramientas para que elijas la mejor opción de env ío y costos con el cual puedas localizar en tiempo real tus envíos a cualquier destino
                    </Typography>
                </Box>
                <Box sx={{px:0, m:0, width:'100%', bgcolor:'#4B4AEC', py:4}} className='d-flex-col-center-centered'>
                    <Box sx={{display:'flex', width:'100%', py:3, flexDirection:{xs:'column', md:'row'}, justifyContent:'center', alignItems:'center'}}>
                        <Box sx={{px:1}}>
                        <PaqueteExpress  width="173" height="73" />
                        </Box>
                        <Box sx={{px:1}}>
                        <Estafeta  width="173" height="73" />
                        </Box>
                        {/* <Box sx={{px:1}}> //remove DHL
                        <DHL  width="150" height="60" />
                        </Box> */}
                        <Box sx={{px:1}}>
                        <FedEx  width="150" height="60" />
                        </Box>
                        <Box sx={{px:1}}>
                        <UPS  width="150" height="60" />
                        </Box>
                    </Box>
                    <Typography sx={{textAlign:'center', width:'50%', fontSize:{xs:18, md:24}, mt:{xs:4, md:6}, color:'white', fontWeight:700, lineHeight:'28px'}}>
                        Nuestro objetivo es proporcionar un valor excepcional tanto para el negocio como para los clientes
                    </Typography>
                </Box>
                <Box sx={{px:0, m:0, pb:{xs:4, md:6}, bgcolor:'#FFAA5C', width:'100%'}} className='d-flex-col-center-centered'>
                    <Typography sx={{textAlign:'center', width:'50%', fontSize:{xs:18, md:24}, my:{xs:4, md:6}, color:'white', fontWeight:700, lineHeight:'28px'}}>
                        Las razones principales por las cuales elegir nuestra empresa
                    </Typography>
                    <Grid container spacing={2} justifyContent="space-around" alignItems='space-around'>
                        <Grid item xs={12} md={3}>
                            <Box sx={{bgcolor:'white', p:4, borderRadius:'15px', borderColor:'black', border:2, alignItems:'center', justifyContent:'center', flexDirection:'column', display:'flex'}}>
                                <Box sx={{mb:2}}>
                                <svg width="64" height="64" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M76 38C76 58.9865 58.9865 76 38 76C17.0135 76 0 58.9865 0 38C0 17.0135 17.0135 0 38 0C58.9865 0 76 17.0135 76 38Z" fill="#B06EBA"/>
                                <path d="M54.329 35.9427V29.5328C54.329 28.0275 53.1062 26.8091 51.6008 26.8091C50.0955 26.8091 48.8771 28.0319 48.8771 29.5328V35.9427H54.329Z" fill="#1A1A1A"/>
                                <path d="M26.9106 35.9427V29.5328C26.9106 28.0275 25.6922 26.8091 24.1869 26.8091C22.6815 26.8091 21.4631 28.0319 21.4631 29.5328V35.9427H26.915H26.9106Z" fill="#1A1A1A"/>
                                <path d="M28.7737 18.5804C26.1647 16.3599 22.2137 16.3599 19.6003 18.5804" stroke="#1A1A1A" strokeWidth="3.96" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M56.1921 20.6156C53.5832 18.3951 49.6278 18.3951 47.0188 20.6156" stroke="#1A1A1A" strokeWidth="3.96" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M24.1871 28.9412C24.1871 29.4224 23.7942 29.8153 23.313 29.8153C22.8319 29.8153 22.439 29.4224 22.439 28.9412C22.439 28.46 22.8319 28.0671 23.313 28.0671C23.7942 28.0671 24.1871 28.46 24.1871 28.9412Z" fill="#F2F2F2"/>
                                <path d="M51.6053 28.9412C51.6053 29.4224 51.2124 29.8153 50.7313 29.8153C50.2501 29.8153 49.8572 29.4224 49.8572 28.9412C49.8572 28.46 50.2501 28.0671 50.7313 28.0671C51.2124 28.0671 51.6053 28.46 51.6053 28.9412Z" fill="#F2F2F2"/>
                                <path d="M13.4199 42.9265C14.8899 55.0355 25.1978 64.4163 37.7041 64.4163C50.2103 64.4163 60.5182 55.0355 61.9882 42.9265H13.4199Z" fill="#1A1A1A"/>
                                <path d="M50.7532 60.6416C46.9744 58.2534 42.5025 56.8628 37.6995 56.8628C32.8966 56.8628 28.4247 58.2489 24.6459 60.6416C28.4247 63.0298 32.8966 64.416 37.6995 64.416C42.5025 64.416 46.9744 63.0298 50.7532 60.6416Z" fill="#F49AC0"/>
                                <path d="M61.3217 46.2992C61.6174 45.2 61.847 44.0787 61.9838 42.9265H13.4155C13.5568 44.0787 13.7863 45.2044 14.0821 46.2992H61.3173H61.3217Z" fill="#F2F2F2"/>
                                </svg>
                                </Box>
                                <Typography variant='h6' sx={{fontWeight:600}} align='center'>Profesionalismo</Typography>
                                <Typography align='center'>
                                    Nos apoyamos en las más avanzadas tecnologías y servicios para ofrecerte el mejor resultado.
                                </Typography>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{bgcolor:'white', p:4, borderRadius:'15px', borderColor:'black', border:2, alignItems:'center', justifyContent:'center', flexDirection:'column', display:'flex'}}>
                                <Box sx={{mb:2}}>
                                <svg width="64" height="64" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M76 38C76 58.9865 58.9865 76 38 76C17.0135 76 0 58.9865 0 38C0 17.0135 17.0135 0 38 0C58.9865 0 76 17.0135 76 38V38Z" fill="#FFA538"/>
                                <path d="M45.7167 53.7333C39.5849 56.6027 33.3252 58.3067 27.3656 58.9247C31.2459 62.9154 36.6714 65.3964 42.6751 65.3964C54.475 65.3964 64.0368 55.8346 64.0368 44.0346C64.0368 42.8604 63.9397 41.7126 63.7587 40.5869C59.1323 45.7342 52.9962 50.3253 45.7211 53.7289L45.7167 53.7333Z" fill="#1A1A1A"/>
                                <path d="M45.7167 53.7333C39.5849 56.6027 33.3252 58.3067 27.3656 58.9247C31.2459 62.9154 36.6714 65.3964 42.6751 65.3964C54.475 65.3964 64.0368 55.8346 64.0368 44.0346C64.0368 42.8604 63.9397 41.7126 63.7587 40.5869C59.1323 45.7342 52.9962 50.3253 45.7211 53.7289L45.7167 53.7333Z" fill="#F2F2F2"/>
                                <path d="M63.8383 46.9263C63.3704 48.4449 60.9292 50.3475 58.563 51.7337" stroke="#FFA538" strokeWidth="1.58" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M25.9486 30.1776C25.9486 31.8595 24.5845 33.2236 22.9026 33.2236C21.2207 33.2236 19.8566 31.8595 19.8566 30.1776C19.8566 28.4957 21.2207 27.1316 22.9026 27.1316C24.5845 27.1316 25.9486 28.4957 25.9486 30.1776Z" fill="#1A1A1A"/>
                                <path d="M22.8983 29.2991C22.8983 29.7847 22.5054 30.1732 22.0242 30.1732C21.543 30.1732 21.1501 29.7803 21.1501 29.2991C21.1501 28.8179 21.543 28.425 22.0242 28.425C22.5054 28.425 22.8983 28.8179 22.8983 29.2991Z" fill="#F2F2F2"/>
                                <path d="M55.5036 30.1776C55.5036 31.8595 54.1395 33.2236 52.4576 33.2236C50.7757 33.2236 49.4116 31.8595 49.4116 30.1776C49.4116 28.4957 50.7757 27.1316 52.4576 27.1316C54.1395 27.1316 55.5036 28.4957 55.5036 30.1776Z" fill="#1A1A1A"/>
                                <path d="M52.4531 29.2991C52.4531 29.7847 52.0602 30.1732 51.579 30.1732C51.0979 30.1732 50.705 29.7803 50.705 29.2991C50.705 28.8179 51.0979 28.425 51.579 28.425C52.0602 28.425 52.4531 28.8179 52.4531 29.2991Z" fill="#F2F2F2"/>
                                <path d="M18.4351 20.9468C21.2294 22.2535 24.5668 22.2535 27.3612 20.9468" stroke="#1A1A1A" strokeWidth="3.96" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M55.5742 13.5437C53.5568 12.9213 51.3584 12.9213 49.3365 13.5437" stroke="#1A1A1A" strokeWidth="3.96" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M32.7732 62.9685C32.7732 62.9685 36.7816 62.2313 39.5671 60.8584" stroke="#FFA538" strokeWidth="1.58" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                                </Box>
                                <Typography variant='h6' sx={{fontWeight:600}} align='center'>Confianza</Typography>
                                <Typography align='center'>
                                    El generar a través del mejor servicio la mejor experiencia para que no te preocupes y puedas disfrutar del día.
                                </Typography>
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{bgcolor:'white', p:4, borderRadius:'15px', borderColor:'black', border:2, alignItems:'center', justifyContent:'center', flexDirection:'column', display:'flex'}}>
                                <Box sx={{mb:2}}>
                                <svg width="64" height="64" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M76 38C76 58.9865 58.9865 76 38 76C17.0135 76 0 58.9865 0 38C0 17.0135 17.0135 0 38 0C58.9865 0 76 17.0135 76 38Z" fill="#5FA4E3"/>
                                <path d="M38.0002 47.831C28.4472 47.831 19.4946 46.5376 11.7825 44.2773C13.8087 56.9337 24.7699 66.6059 38.0002 66.6059C51.2304 66.6059 62.1916 56.9337 64.2179 44.2773C56.5057 46.5376 47.5531 47.831 38.0002 47.831Z" fill="#1A1A1A"/>
                                <path d="M26.2707 30.1774C26.2707 31.8593 24.9066 33.2234 23.2247 33.2234C21.5428 33.2234 20.1787 31.8593 20.1787 30.1774C20.1787 28.4954 21.5428 27.1313 23.2247 27.1313C24.9066 27.1313 26.2707 28.4954 26.2707 30.1774Z" fill="#1A1A1A"/>
                                <path d="M23.2206 29.2989C23.2206 29.7845 22.8277 30.1729 22.3465 30.1729C21.8653 30.1729 21.4724 29.7801 21.4724 29.2989C21.4724 28.8177 21.8653 28.4248 22.3465 28.4248C22.8277 28.4248 23.2206 28.8177 23.2206 29.2989Z" fill="#F2F2F2"/>
                                <path d="M55.8259 30.1774C55.8259 31.8593 54.4618 33.2234 52.7799 33.2234C51.098 33.2234 49.7339 31.8593 49.7339 30.1774C49.7339 28.4954 51.098 27.1313 52.7799 27.1313C54.4618 27.1313 55.8259 28.4954 55.8259 30.1774Z" fill="#1A1A1A"/>
                                <path d="M52.7757 29.2989C52.7757 29.7845 52.3828 30.1729 51.9017 30.1729C51.4205 30.1729 51.0276 29.7801 51.0276 29.2989C51.0276 28.8177 51.4205 28.4248 51.9017 28.4248C52.3828 28.4248 52.7757 28.8177 52.7757 29.2989Z" fill="#F2F2F2"/>
                                <path d="M11.7825 44.2773C12.1356 46.489 12.7669 48.608 13.6321 50.5989C21.0529 52.1352 29.3036 52.996 38.0002 52.996C46.6967 52.996 54.9474 52.1396 62.3682 50.5989C63.2334 48.6036 63.8647 46.489 64.2179 44.2773C56.5057 46.5376 47.5531 47.831 38.0002 47.831C28.4472 47.831 19.4946 46.5376 11.7825 44.2773Z" fill="#F2F2F2"/>
                                <path d="M53.217 61.8116C49.0276 60.6153 43.7479 59.9001 38.0002 59.9001C32.2525 59.9001 26.9728 60.6153 22.7834 61.8116C27.092 64.8311 32.3364 66.6102 38.0002 66.6102C43.664 66.6102 48.9084 64.8356 53.217 61.8116Z" fill="#D65158"/>
                                <path d="M29.9655 18.4613C26.1293 15.1945 20.3154 15.1945 16.4792 18.4613" stroke="#1A1A1A" strokeWidth="3.96" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M58.5187 14.7841C55.0799 12.5062 50.4755 12.5062 47.0322 14.7841" stroke="#1A1A1A" strokeWidth="3.96" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                                </Box>
                                <Typography variant='h6' sx={{fontWeight:600}} align='center'>Seguridad</Typography>
                                <Typography align='center'>
                                    Enfocados en  proveer el mejor servicio aliándonos con marcas reconocidas para hacer tu entrega a tiempo.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{px:0, m:0, pb:{xs:4, md:6}, bgcolor:'white', width:'100%'}} className='d-flex-col-center-centered'>
                    <Typography sx={{textAlign:'center', width:'50%', fontSize:{xs:18, md:24}, mt:{xs:4, md:6}, color:'black', fontWeight:700, lineHeight:'28px'}}>
                    Regístrate y comprueba que lo que nos hace feliz es verte feliz
                    </Typography>
                </Box>
                <PublicFooter />
            </Grid>
        </Grid>
    </Container>
    )
}

export default Homepage