import React, {FC, useState} from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setDialog, setDialogBody, setDialogType } from '../../../store/actions/genericActions';
import { ShipmentInterface } from '../../../types/apiTypes';
import { User } from '../../../types/authTypes';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface DialogShipmentProps {
    user:User,
    shipmentSaved: ShipmentInterface,
} 

//Check creacion carta porte
//const [waybillAvailable, setWaybillAvailable] = useState<boolean>(false)

const DialogShipmentCreated:FC<DialogShipmentProps> = (props) => {
    const {shipmentSaved} = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let pdfData = "";
    var createWaybillAvailable = shipmentSaved.apiCreatedWaybill.waybill.hasCartaPorte;

    if(shipmentSaved.labelInfo.type=="link")
        pdfData = shipmentSaved.labelInfo.data||"";
    if(shipmentSaved.labelInfo.type=="base64")
        pdfData = `data:application/pdf;base64,${shipmentSaved.labelInfo.data||""}`
    /*if(
        shipmentSaved?.packageToShip?.valueGoods&&
        shipmentSaved?.packageToShip?.unitKey&&
        shipmentSaved?.packageToShip?.goodsTransported&&
        shipmentSaved?.apiCreatedWaybill.waybill.toRFC&&
        shipmentSaved?.apiCreatedWaybill.waybill.frRFC
       ){
        createWaybillAvailable = true;
    }*/
    var [pdfUrl, setPdfUrl] = useState(pdfData);

    const goToShipmentsHandler = () =>{
        navigate('/myShipments')
        dispatch<any>(setDialog(false))
        dispatch<any>(setDialogType(''))
        dispatch<any>(setDialogBody(null))
    }

    const downloadPDFHandler = () => {
        const url = pdfData?pdfData:"";
        var fileName = shipmentSaved.happyTrackNumber;
        if(url){
            const aTag = document.createElement("a");
            aTag.href = url;
            aTag.setAttribute("download", fileName)
            document.body.appendChild(aTag);
            aTag.click();
            aTag.remove();
        }
    }

    /*const createWaybillHandler = () => {
        //Crear carta porte
        console.log("Crear carta porte original ...")
        dispatch<any>(setLoading(true));
        dispatch<any>(customerCartaPorteFEDEX(props));
    }*/

    return (
    <Box sx={{width:'100%'}}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{width:'100%'}}>
                    <Typography variant="h6" sx={{weigth:600, width:'100%'}}>Happy No. {shipmentSaved.happyTrackNumber}</Typography>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{fontWeight:500}}> Carta Porte: 
                        <Tooltip
                            title= {createWaybillAvailable?"Disponible":"No Disponible"}
                            placement="right-end"
                            arrow
                            >
                            <span>
                                <CheckCircleOutlineIcon sx={{color:createWaybillAvailable?"#39ad87":"#c23458"}}/>
                            </span>
                        </Tooltip>
                        </Typography>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box className="d-flex-col-start-centered" sx={{width:'100%'}}>
                    <Typography variant="body1">Remitente</Typography>
                    <Typography variant="h5" sx={{fontWeight:600}}>
                        {shipmentSaved.apiCreatedWaybill.waybill.frName}
                    </Typography>
                    <Typography variant="body1">{shipmentSaved.apiCreatedWaybill.waybill.frStreet}</Typography>
                    <Typography variant="body2">{shipmentSaved.apiCreatedWaybill.waybill.frCity}</Typography>
                    <Typography variant="body2">{shipmentSaved.apiCreatedWaybill.waybill.frCountry}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box className="d-flex-col-start-centered" sx={{width:'100%'}}>
                    <Typography variant="body1">Destinatario</Typography>
                    <Typography variant="h5" sx={{fontWeight:600}}>
                        {shipmentSaved.apiCreatedWaybill.waybill.toName}
                    </Typography>
                    <Typography variant="body1">{shipmentSaved.apiCreatedWaybill.waybill.toStreet}</Typography>
                    <Typography variant="body2">{shipmentSaved.apiCreatedWaybill.waybill.toCity}</Typography>
                    <Typography variant="body2">{shipmentSaved.apiCreatedWaybill.waybill.toCountry}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                <LoadingButton 
                    type="submit"
                    fullWidth
                    disableElevation
                    onClick={goToShipmentsHandler}
                    color="secondary"
                    variant="outlined"
                    sx={{mt:5}}
                    size="large"
                >
                    Mis envíos
                </LoadingButton>
            
            </Grid>
            <Grid item xs={12} md={4}>
                <LoadingButton 
                    type="submit"
                    fullWidth
                    disableElevation
                    onClick={downloadPDFHandler}
                    color="secondary"
                    variant="contained"
                    sx={{mt:5}}
                    size="large"
                >
                    Descargar guía
                </LoadingButton>
            </Grid>
        </Grid>
    </Box>
    )
}

export default DialogShipmentCreated