import React, {FC, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../store';
import { User } from '../../../types/authTypes';

import AdminUsers from './AdminUsers';
import AdminStores from './AdminStores';
import AdminCarriers from './AdminCarriers';
import { setPublicPrice } from '../../../store/actions/genericActions';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const TabPanel = (props: any) => {
    
    const { children, value, index, ...other } = props;
  
    return (
      <Box
        component="div"
        role="tabpanel"
        sx={{width:'100%', overflowY:'hidden'}}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box component="div" sx={{ py:3 }}>
            <Typography sx={{fontWeight:500}} component="div">{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  const tabKeyProps = (index:any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const AdminSummary : FC = () => {

    const user: User | null = useSelector((state: RootState) => state.auth.user)
    const [indexOptions, setIndexOptons] = useState<number>(0);
    const dispatch = useDispatch();

    const handleIndexChange = (event: any, newValue:any) => {
      console.log("OPCION: ",newValue)
      if(newValue == 2){
        dispatch<any>(setPublicPrice(false))
      }
      if(newValue == 0){
        dispatch<any>(setPublicPrice(true))
      }
        setIndexOptons(newValue);
    };   

    return (
      <Container maxWidth='xl' sx={{bgcolor:'#F4F7F9', minHeight:'100vh'}}>
        <Toolbar sx={{mb:3}}/>
            { user ? 
                <Grid sx={{mb:1}} container direction="row" justify-content="space-between" alignItems="flex-start" spacing={1}>
                    <Grid item xs={12}>
                        <Box sx={{width:'100%'}}>
                            <Typography 
                                component="h6" 
                                variant="h6" 
                                sx={{fontWeight:500}}>
                                Administración
                            </Typography>
                            <Typography 
                                component="h6" 
                                variant="body2" 
                                sx={{fontWeight:500}}>
                                Usuario: {user?.fullName}
                            </Typography>
                            <Typography 
                                component="h6" 
                                variant="body2" 
                                sx={{fontWeight:500}}>
                                Rol: {user?.role}
                            </Typography>
                            <Tabs 
                                textColor="primary"
                                indicatorColor="primary" 
                                value={indexOptions} 
                                onChange={handleIndexChange}
                                variant="scrollable"
                                sx={{fontWeight:600, mt:2}}
                                scrollButtons="auto"
                                aria-label="Info usuario edit">
                                <Tab sx={{fontWeight:600}} label={"Carriers/Precios"} {...tabKeyProps(0)}/>
                                <Tab sx={{fontWeight:600}} label="Grupos" {...tabKeyProps(1)}/>
                                <Tab sx={{fontWeight:600}} label="Usuarios" {...tabKeyProps(2)}/>
                            </Tabs>
                            <TabPanel value={indexOptions} index={0}>
                                <AdminCarriers />
                            </TabPanel>
                            <TabPanel value={indexOptions} index={1}>
                                <AdminStores />
                            </TabPanel>
                            <TabPanel value={indexOptions} index={2}>
                                <AdminUsers />
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>
                :null
            }
        </Container>
    )
}

export default AdminSummary