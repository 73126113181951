import React, {FC} from 'react'

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

interface SummaryShipmentsStatus {
    type?: string,
    total?: number,
    range?: number,
}

const SimpleSummaryCard: FC<SummaryShipmentsStatus> = (props) => {

    const { type, total, range } = props

    return (
        <Card elevation={0} sx={{borderRadius:'30px'}}>
            <CardContent>
                <Typography variant='subtitle1' sx={{fontWeight:500, mt:1}}>
                    {type}
                </Typography>
                <Typography variant='h5' sx={{fontWeight:700, mt:.5}}>
                    {total}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default SimpleSummaryCard