import React, {FC, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../../store';
import { StoreBasicData, User } from '../../../types/authTypes';
import { Store } from '../../../types/storeTypes';
import { updateAnotherUser, updateStore } from '../../../store/actions/storeActions';
import { roleTypeArray } from '../../../features/referenceData/userLists';

import { translateDate, translateDateFromDb } from '../../../features/functions/handleDates';

import EditUserButtons from './EditUserButtons'
import AvatarCustomed from '../../UI/AvatarCustomed'
import CarrierPricesTable from './CarrierPricesTable'

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

const AdminUsers: FC = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {carrierPrices} = useSelector((state: RootState) => state.stores)
  const user: User | null = useSelector((state: RootState) => state.auth.user)
  const users: Array<User> | null = useSelector((state: RootState) => state.generic.usersAdmin)
  const { stores } = useSelector((state: RootState) => state.stores);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [toggleUserIndex, setToggleUserIndex] = useState<number>(0);

  const [searchUserName, setSearchUserName] = useState<string>();
  const [filteredUsers, setFilteredUsers] = useState<Array<User> |null>(users ? users : null)
  
  const searchByName = (stringToSearch:string) => {
    const stringToSearchToLowerCase: string = stringToSearch.toLowerCase()
    setSearchUserName(stringToSearchToLowerCase);
  }

  const handleToggleUserDetail = (index: number) => {
    setToggleUserIndex(index);
    setOpenUser(!openUser)
  }

  useEffect(()=> {
      if(searchUserName) {
        setFilteredUsers(users && users.filter((user:User) => {
              return user.fullName.toLowerCase().includes(searchUserName)
          }))
      }
      if(!searchUserName || searchUserName === ''){
        setFilteredUsers(users)
      }

  },[
    searchUserName,
    users
  ])

  const removeStoreFromUser = (userToEdit:User, storeUser:StoreBasicData) => {

    if(userToEdit.storesIds && userToEdit.stores) {

      const storesObjToUpdate: Array<StoreBasicData> = [...userToEdit.stores]
      const storeIdsToUpdate: Array<string> = [...userToEdit.storesIds]
      const storeToUpdate: Store | null | undefined =  stores && stores?.find((store:Store) => {
          return store._id === storeUser._id ?? store
      })
      const storeMemberIdsToUpdate: Array<string> = storeToUpdate && storeToUpdate.members ? storeToUpdate.members : []

      const storeObjectIndex: number = storesObjToUpdate.findIndex((data) => data._id === storeUser._id)
      const storeIdIndex: number = storeIdsToUpdate.indexOf(storeUser._id)
      const storeMemberIndex: number = storeMemberIdsToUpdate.indexOf(userToEdit.uid)
      storesObjToUpdate.splice(storeObjectIndex,1)
      storeIdsToUpdate.splice(storeIdIndex,1)
      storeMemberIdsToUpdate.splice(storeMemberIndex,1)
      
      const userToSave: User = {
        ...userToEdit,
        stores: storesObjToUpdate,
        storesIds: storeIdsToUpdate
      }

      if(userToSave && user && storeToUpdate) {
        const storeToSave: Store = {
            ...storeToUpdate,
            members: storeMemberIdsToUpdate
        }
        dispatch<any>(updateAnotherUser(userToSave, user.uid))
        dispatch<any>(updateStore(storeToSave))

      }
    }
  }

  return (
    <Card elevation={0} sx={{borderRadius:'30px'}}>
        <CardContent>
            <List subheader={
              <Box className = "d-flex-row-start-centered">
                <ListSubheader component="div" id="nested-list-subheader">
                    Usuarios
                </ListSubheader>
                <TextField
                    required
                    color="secondary"
                    size="small"
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                        ),
                    }}
                    sx={{my:1.5, mx:1.5}}
                    id="outlined-required"
                    onChange={(e:any) => searchByName(e.currentTarget.value)}
                    placeholder='Buscar por nombre'
                    type="text"
                />
              </Box>
            }>
              {user && filteredUsers && filteredUsers?.map((userExt:User, index:number) => {
                const openThis: boolean = openUser && toggleUserIndex === index
                const allowEdit = userExt.uid === user.uid ? false : true
                const userPrices = carrierPrices?.filter((price)=>{
                  return price.customerId == userExt.uid
                })
                return(
                  <Box key={index} sx={{width:'100%'}}>
                    <ListItemButton
                      alignItems="center"
                      onClick={() => handleToggleUserDetail(index)}
                      disabled={!allowEdit}
                      sx={{
                        borderBottom: openThis ? 0:1.25, 
                        borderBottomColor:'grey.100',
                        borderLeft:openThis ? 4 : 0,
                        borderTopLeftRadius:5,
                        borderLeftColor: openThis ? 'secondary.main':'white',
                        bgcolor: openThis?'grey.50' : 'inherit'
                    }} 
                      >
                        <ListItemAvatar>
                          <AvatarCustomed 
                            userName={userExt.fullName} 
                            size={56} 
                            userPhoto={userExt.userAvatar}  
                            fontWeight={500}
                            fontSize={18}
                            />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ml:1.5}}
                          primary={
                            <Typography sx={{fontWeight:500}} variant="body1">
                                {userExt.fullName}
                            </Typography>
                          }
                          secondary={
                            <span>
                              <Typography sx={{fontWeight:400, color:'grey.700'}} variant="body2">
                                {userExt.email}
                              </Typography>
                              {/* <Typography sx={{fontWeight:400, color:'grey.700'}} variant="body2">
                                Cumple: {userExt.birthday ? translateDate(translateDateFromDb(userExt.birthday)) : 'Sin fecha de nac'} 
                              </Typography> */}
                              <Typography sx={{fontWeight:400, color:'grey.700'}} variant="caption">
                                Rol: {userExt.role}
                              </Typography>
                            </span>
                          }
                        />
                        {openUser ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse 
                      sx={{
                          p:2,
                          px:4,
                          pb:4,
                          width:'95%', 
                          borderBottom: !openThis ? 0:1, 
                          borderBottomColor:'grey.100',
                          borderLeft:4, 
                          borderLeftColor:'secondary.main',
                          borderBottomLeftRadius:5,
                          display:'flex',
                          flexDirection: {xs:'column', md:'row'},
                          justifyContent:{xs:'flex-start', md: 'space-between'},
                          alignItems:{xs:'flex-start', md:'center'}
                      }}
                      in={openThis} 
                      timeout="auto" 
                      unmountOnExit>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Typography sx={{fontWeight:500, pb:2}} variant="body2">
                              <Link
                                onClick={()=>navigate("/userProfile", {state:{ id: userExt?.uid}})}
                                underline='always' sx={{':hover':{
                                  cursor:'pointer'
                              }}}>
                                Ver el perfil de {userExt.fullName}
                              </Link>
                            </Typography>
                            <List>
                              {!userExt.stores ? 
                                <ListItem>
                                  <ListItemText primary="Sin grupos asignados" />
                                </ListItem>
                                :null
                              }
                              {userExt.stores && userExt.stores.map((storeUser:StoreBasicData, index:number)=> {
                                return(
                                  <ListItem 
                                    key={index} 
                                    disablePadding 
                                    disableGutters
                                    >
                                    {user.role === roleTypeArray[1] ? 
                                      <IconButton 
                                        disabled={user.role !== roleTypeArray[1]}
                                        onClick={() => removeStoreFromUser(userExt, storeUser)} 
                                        sx={{mr:1}} color="error"
                                        >
                                        <RemoveCircleOutlineOutlinedIcon fontSize='small'/>
                                      </IconButton>
                                      :null
                                    }
                                    <ListItemIcon>
                                      <StorefrontOutlinedIcon fontSize='small'/>
                                    </ListItemIcon>
                                    <ListItemText 
                                      primary={
                                        <Typography sx={{fontWeight:500}} variant="body2">
                                          {storeUser.storeName}
                                        </Typography>
                                      }
                                    />
                                  </ListItem>
                                )
                              })}
                            </List>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <EditUserButtons userExt={userExt} userAdmin={user} />
                        </Grid>
                      </Grid>
                      {userPrices?.length && userPrices?.length>0 ? 
                        <Grid item xs={12} md={12}>
                          <CarrierPricesTable user={user} carrierPrices={userPrices} />
                        </Grid>
                        :null
                      }
                    </Collapse>
                  </Box>
                )
              })}
            </List>
        </CardContent>
    </Card>
  )
}

export default AdminUsers