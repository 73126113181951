import React, {FC} from 'react'
import { useSelector } from 'react-redux'
import { User } from '../../../types/authTypes';

import {RootState}from'../../../store';

import ShipmentTableCard from './ShipmentTableCard';

import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';


const MyShipmentsSummary: FC = () => {

  const user:User|null = useSelector((state:RootState)=> state.auth.user)
  return (
    <Container maxWidth='xl' sx={{bgcolor:'#F4F7F9', minHeight:'100vh'}}>
      <Toolbar sx={{mb:3}}/>
        <Typography variant='h6' component="h6" sx={{fontWeight:500, mb:2}}>Mis Envíos</Typography>
        { user ?
            <ShipmentTableCard hideMenuActions={false} view={'normal'} user={user}/>
            :null
        }

    </Container>
  )
}

export default MyShipmentsSummary