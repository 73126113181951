import { ApiAction, ApiState, SET_ACTIVE_APIS, SET_SHIPMENTS } from '../../types/apiTypes'

const initialState: ApiState = {
    activeApis: null,
    shipments: {data : null, page : 1, count : 0, isLoading : true,
        isBulkData : false}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: ApiAction) => {
    switch(action.type) {
        case SET_ACTIVE_APIS:
            return {
                ...state,
                activeApis: action.payload
            }
        case SET_SHIPMENTS:
            return {
                ...state,
                shipments: action.payload
            }
        default:
            return state;
    }
}