import React from 'react';
import {useSelector} from 'react-redux';

import { RootState } from '../../../store';
import AdminBalanceCard from './AdminBalanceCard';

import{User}from'../../../types/authTypes';
import{roleTypeArray}from'../../../features/referenceData/userLists'

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AddBalanceCard from './AddBalanceCard';

const AdminBalancePage = () => {

  const user: User | null = useSelector((state: RootState) => state.auth.user)

  return (
    <Container maxWidth='xl' sx={{bgcolor:'#F4F7F9', minHeight:'100vh'}}>
      <Toolbar sx={{mb:3}}/>
      <Typography variant='h6' component="h6" sx={{fontWeight:500, mb:2}}>Agregar saldo</Typography>
      <AdminBalanceCard />

      {user?.role === roleTypeArray[1] || user?.role === roleTypeArray[2]?
        <Box sx={{p:0, m:0, mt:2}}>
          <AddBalanceCard/>
        </Box>
        : null
      }

    </Container>

  )
}

export default AdminBalancePage