import { ThunkAction } from 'redux-thunk';
import { 
    ApiAction,
    ApiConnection,  FedexCreateExpressOneLabelRequest,
    //ShipmentSavedObject,
    ApiMethod,
    SET_ACTIVE_APIS,
    SET_SHIPMENTS,
    //RabeeGoodsObject,
    RapiApiRegisterUser,
    EstafetaShipment,
    DHLShipment,
    FedexShipment,
    Waybill,
    ShipmentInterface,
    Goods,
    ShipmentDataHolder,
    SetShipmentsAction,
    EnviosHappyShipment
} from '../../types/apiTypes';
import { roleTypeArray } from '../../features/referenceData/userLists';
import { UserDirectoryFav, PackageBasicData, OptionsForShipment, CarrierPrice, carrierExtendedZone } from '../../types/storeTypes';
import { StoreBasicData, User } from '../../types/authTypes';
import { rabeeApiHeader, enviosRapiApiHeader, redpackApiHeader } from '../../features/referenceData/apiReference';
import { apiProviders, dialogTypes } from '../../features/referenceData/appLists';
import { RootState } from '..';
import { createDirectory } from './storeActions';
import { updateUserInfo } from './authActions';
import { setDestinyDirShipment, 
    setDialog, 
    setDialogBody, 
    setDialogType, 
    setError, 
    setLoading, 
    setOriginDirShipment, 
    setPackageToShip, 
    setOptionsForShipment, 
    setSelectedPriceCarrier,
    shipmentToObj, setTextMessage, setExtendedZone, updateCarrierRatings} from './genericActions';

import { generateHappyId } from '../../features/functions/genericFunctions';
import { Store} from '../../types/storeTypes';

import { app, firestore } from '../../firebase/config';
import { collection, doc, setDoc, serverTimestamp, addDoc, where, query, getDocs, Timestamp, limit, orderBy, onSnapshot, getCountFromServer, endBefore, startAfter, DocumentData, startAt, endAt, QuerySnapshot} from 'firebase/firestore';
import {isRunningLocal} from "../../firebase/config"
import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator,
  } from "firebase/functions";
import { DateRange, Range } from 'react-date-range';
const functions = getFunctions(app);
if (isRunningLocal){
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}



export const setActiveApis = (activeApis: Array<ApiConnection>): ThunkAction<void, RootState, null, ApiAction> => {
    return dispatch => {
        dispatch({
            type: SET_ACTIVE_APIS,
            payload: activeApis
        })
    }
}

export const getShipmentsByDate = (startDate: Date, endDate: Date, isUserAdmin: boolean, submittedBy?: string): ThunkAction<void, RootState, null, ApiAction> => {
    return async (dispatch) => {
    }
     /*   try {
            const shipmentsRef = collection(firestore, 'shipments')
            const queries = []
            if(startDate) {
                queries.push(where("createdAt",">=", Timestamp.fromDate(startDate)))
            }
            if(endDate) {
                queries.push(where("createdAt","<=", Timestamp.fromDate(endDate)))
            }
            if(!isUserAdmin&&submittedBy!=='') {
                queries.push(where("createdBy","==", submittedBy))
            }

            const shipmentQuery = query((shipmentsRef), ...queries) 
            const shipmentDocs = await getDocs(shipmentQuery)
            const shipments: Array<ShipmentInterface> = shipmentDocs.docs.map((doc) => {
                const shipmentDoc: ShipmentInterface = {...doc.data() as ShipmentInterface, _id:doc.id}
                return shipmentDoc;
            })*/
            /*dispatch({
                type: SET_SHIPMENTS,
                payload: shipments
            })*/

        /*} catch(err: any) {
            dispatch(setError(err.message))
        }*/
    //}
}

export const setShipments = (shipments: Array<ShipmentInterface>, page: number, count: number, isLoading : boolean|null, isBulkData : boolean): ThunkAction<void, RootState, null, ApiAction> => {
    let isLoadingT = true;
    if(isBulkData){
        if(count == shipments.length)
            isLoadingT = false;
    }else{
        isLoadingT = isLoading!=null? isLoading: true;
    }
    return dispatch => {
        dispatch({
            type: SET_SHIPMENTS,
            payload: {
                data : shipments, 
                page: page, 
                count : count, 
                isLoading : isLoadingT,
                isBulkData : isBulkData
            }
        })
    }
}

export const refreshRabeeWaybill = (shipmentToTrack: ShipmentInterface, user:User, rabeeApi?: ApiConnection|null): ThunkAction<void, RootState, null, ApiAction> => {
    return async dispatch => {
        try {
            if(user&&shipmentToTrack&&rabeeApi) {
                const rabeeId = shipmentToTrack.apiTrackNumber
                const productionEnv: boolean = rabeeApi.production; //change this flag to false in DB when changing to test
                // const productionEnv: boolean = false; //change this flag to false in DB when changing to test
                const rabeeTrackWaybill:ApiMethod = rabeeApi.trackWaybill;
                const encodedCredentials: string = productionEnv ? rabeeApi.prodCredentials.base64Encoded : rabeeApi.testCredentials.base64Encoded
                let authorizationHeaderString = 'Basic ' + encodedCredentials;
                let headersToUse: HeadersInit= {...rabeeApiHeader,'Authorization':authorizationHeaderString}
                const response = await fetch(
                    productionEnv? rabeeTrackWaybill.prodUrl+`${rabeeId}`: rabeeTrackWaybill.testUrl+`${rabeeId}`,
                    {
                        method:rabeeTrackWaybill.method,
                        headers: headersToUse
                    }
                )
                if(response.status ===200){
                    const success = await response.json()
                    const dataRetrieved = success.data[0]
                    const shipmentToUpdate:ShipmentInterface = {
                        ...shipmentToTrack,
                        status: dataRetrieved.pubEsContext,
                        statusId: parseInt(dataRetrieved.operationMove)
                    }
                    const shipmentsRef = collection(firestore, 'shipments')
                    await setDoc(doc(shipmentsRef, shipmentToUpdate._id), shipmentToUpdate, { merge: true })
                    dispatch<any>(setLoading(false))
                }
            }
        } catch(err: any) {
            dispatch(setError(err.message));
        }
    }
}

export const registerUserEnviosRapiApi = (user:User, rapiApi:ApiConnection): ThunkAction<void, RootState, null, ApiAction> => {
    return async dispatch => {
        try {
            if(user&&rapiApi&&rapiApi.provider===apiProviders[1]) {
                const productionEnv: boolean = rapiApi.production; //change this flag to false in DB when changing to test
                // const productionEnv: boolean = false; //change this flag to false in DB when changing to test
                const rapiRegisterUser:ApiMethod|null = rapiApi.registerUser ? rapiApi.registerUser :null;
                const userName: string = user.givenName? user.givenName :user.fullName.split(" ")[0]
                const userLastName: string = user.fullName.split(" ")[1];
                const bodyObj: RapiApiRegisterUser = {
                    name: userName,
                    last_name: userLastName,
                    email: user.email,
                    password: rapiApi.prodCredentials.password,
                    phone: user.phoneNumber ? user.phoneNumber : '1234567891'
                }
                const bodyToSend = JSON.stringify(bodyObj)
                const headersToUse:HeadersInit = {...enviosRapiApiHeader}

                if(rapiRegisterUser){
                    const response= await fetch(productionEnv ? rapiRegisterUser.prodUrl : rapiRegisterUser.testUrl, {
                        method: rapiRegisterUser.method,//'Metodo POST'
                        headers: headersToUse,
                        body: bodyToSend
                    })
                }
            }
        } catch(err: any) {
            dispatch(setError(err.message));
        }
    }
}

export const rabeeCreateWaybill = (
    rabeeCreateWabillObject: Waybill, 
    user:User, 
    rabeeApi?: ApiConnection|null, 
    group?:StoreBasicData,
    packageToShip?:PackageBasicData,
    selectedCarrier?:CarrierPrice,
    optionsForShipment?:OptionsForShipment
    ): ThunkAction<void, RootState, null, ApiAction> => {
    return async dispatch => {
        try {
            if(rabeeCreateWabillObject&&rabeeApi&&group&&packageToShip&&selectedCarrier){
                const productionEnv: boolean = rabeeApi.production; //change this flag to false in DB when changing to test
                // const productionEnv: boolean = false; //change this flag to false in DB when changing to test
                const rabeeCreateWaybill:ApiMethod = rabeeApi.createWaybill;
                const rabeeLabel: ApiMethod = rabeeApi.label
                const encodedCredentials: string = productionEnv ? rabeeApi.prodCredentials.base64Encoded : rabeeApi.testCredentials.base64Encoded
                let authorizationHeaderString = 'Basic ' + encodedCredentials;
                let headersToUse: HeadersInit= {...rabeeApiHeader,'Authorization':authorizationHeaderString}
                const bodyToSend = JSON.stringify(rabeeCreateWabillObject)
                const response = await fetch(productionEnv ? rabeeCreateWaybill.prodUrl : rabeeCreateWaybill.testUrl, {
                    method:rabeeCreateWaybill.method,//'Metodo POST'
                    headers: headersToUse,
                    body: bodyToSend
                })
                if(response.status ===200){
                    const success = await response.json()
                    const labelResponse = await fetch(
                        productionEnv? rabeeLabel.prodUrl+`${success.data.rabeeNo}`: rabeeLabel.testUrl+`${success.data.rabeeNo}`,
                        {
                        method:rabeeLabel.method, //'Metodo GET',
                        headers: headersToUse
                        }
                    )
                    const successLabel = await labelResponse.json()
                    const labelUrl = successLabel.code === 200 ? successLabel.data.imgUrl : 'No hay liga'
                    const shipmentToSave:ShipmentInterface = {
                        apiCreatedWaybill:rabeeCreateWabillObject,
                        apiTrackNumber:success.data.rabeeNo,
                        happyTrackNumber: rabeeCreateWabillObject.waybill.waybillNo||"",
                        labelInfo:{type :"link",
                            data:labelUrl},
                        groupId:group._id,
                        groupName: group.storeName,
                        groupSupervisor: group.supervisor&&group.supervisor.userName?group.supervisor.userName:'sin supervisor',
                        groupSupervisorId: group.supervisor&&group.supervisor&&group.supervisor.uid?group.supervisor.uid:'sin supervisorId',
                        createdBy: user.uid,
                        createdByName: user.fullName,
                        status:'Iniciado',
                        statusId: 0,
                        provider: rabeeApi.provider,
                        lastModBy: user.uid,
                        createdAt:serverTimestamp(),
                        lastModAt:serverTimestamp(),
                        packageToShip,
                        // optionsForShipment:optionsForShipment?optionsForShipment:undefined,
                        selectedCarrier
                    }
                    const shipmentsRef = collection(firestore, 'shipments')
                    const docSaved = await addDoc(shipmentsRef, shipmentToSave)
                    await setDoc(doc(shipmentsRef, docSaved.id), {_id:docSaved.id}, {merge:true})
                    //open Dialog to show success
                    dispatch<any>(setDialog(true))
                    dispatch<any>(setDialogType(dialogTypes[0]))
                    dispatch<any>(setDialogBody(shipmentToSave))
                    //set origin, destiny and package in store
                    const setStore: boolean = false
                    dispatch<any>(setOriginDirShipment(setStore))
                    dispatch<any>(setDestinyDirShipment(setStore))
                    dispatch<any>(setPackageToShip(setStore))
                    dispatch<any>(setOptionsForShipment(setStore))
                    dispatch<any>(setSelectedPriceCarrier(setStore))
                    dispatch<any>(setLoading(false))
                }
            }
        } catch(err: any) {
            dispatch(setError(err.message));
        }
    }
}

export const submitNewShipmentForEstafeta = (
    originDir: UserDirectoryFav,
    destinyDir:UserDirectoryFav,
    originSaveFav:boolean,
    destinySaveFav:boolean,
    packageToShip:PackageBasicData,
    selectedCarrier:CarrierPrice,
    apiConnection:Array<ApiConnection>,
    user:User,
    groupSelected:StoreBasicData|null|undefined,
    carrierID : string,
    optionsForShipment?:OptionsForShipment
): ThunkAction<void, RootState, null, ApiAction> => {
    return async dispatch => {
        try {
            let environmentObj = apiConnection[0].production?apiConnection[0].prodCredentials:apiConnection[0].testCredentials;
            let estafetaObj = new EstafetaShipment(environmentObj.others.suscriberId, environmentObj.customerNmbr, environmentObj.others.salesOrganization);//// hereeee
            estafetaObj.updateData(user, originDir, destinyDir, packageToShip, selectedCarrier, groupSelected?groupSelected:undefined);
            if(selectedCarrier.apiProvider&&apiConnection[0].provider===apiProviders[0]){
                var estafetaFunct = httpsCallable(functions,"estafetaApiCallV2");
                estafetaFunct({
                    shipment : estafetaObj.getTransactableDataObj(),
                    carrierID : apiConnection[0]._id
                }).then(async (value:any)=>{
                    if(value.data.data){
                        let shipmentColecction = estafetaObj.processCarrierApiResponse(value);
                        if(groupSelected){
                            if(originSaveFav && user && originDir){
                                dispatch<any>(createDirectory(originDir, user))
                            }
                            if(originSaveFav && user && destinyDir){
                                dispatch<any>(createDirectory(destinyDir, user))
                            }        
                            const userNewBalance:User = {
                                ...user,
                                balance:user.balance ? user.balance - (selectedCarrier.amount*packageToShip.qty) : 0
                            }
                            dispatch<any>(updateUserInfo(userNewBalance, ()=>setLoading(true)))
                            const shipmentsRef = collection(firestore, 'shipments')
                            for(let i =0; i < shipmentColecction.length; i ++){
                                const docSaved = await addDoc(shipmentsRef,  shipmentToObj(shipmentColecction[i]));
                                await setDoc(doc(shipmentsRef, docSaved.id), {_id:docSaved.id}, {merge:true})
                            }
                            //open Dialog to show success
                            dispatch<any>(setDialog(true))
                            dispatch<any>(setDialogType(dialogTypes[0]))
                            dispatch<any>(setDialogBody(estafetaObj.returnInterface()))
                            //set origin, destiny and package in store
                            const setStore: boolean = false
                            dispatch<any>(setOriginDirShipment(setStore))
                            dispatch<any>(setDestinyDirShipment(setStore))
                            dispatch<any>(setPackageToShip(setStore))
                            dispatch<any>(setOptionsForShipment(setStore))
                            dispatch<any>(setSelectedPriceCarrier(setStore))
                            dispatch<any>(setLoading(false))
                        }
                    }else{
                        dispatch<any>(setDialog(true))
                        dispatch<any>(setDialogType(dialogTypes[5]))
                        dispatch<any>(setTextMessage(JSON.stringify(value)));
                        //dispatch<any>(setDialogBody(estafetaObj))
                        dispatch<any>(setLoading(false))
                    }
                }).catch((error)=>{
                    dispatch<any>(setDialog(true))
                    dispatch<any>(setDialogType(dialogTypes[5]))
                    dispatch<any>(setTextMessage(JSON.stringify(error)));
                    dispatch<any>(setLoading(false))
                });
            }
        }
        catch (err: any){
            dispatch<any>(setDialog(true))
            dispatch<any>(setDialogType(dialogTypes[5]))
            dispatch<any>(setTextMessage(JSON.stringify(err)));
            dispatch<any>(setLoading(false))
        }
    }
}

function isExtendedZone(val :string): boolean{
    let is = false
    if(val == "Y")
        is = true
    if(val == "1")
        is = true
    if(val == "Extendida")
        is = true
    return is;
}

export const getExtendedZone = (
    zip : string,
    isOrigin: boolean
): ThunkAction<void, RootState, null, ApiAction> => {
    return async dispatch => {
        try {
            dispatch<any>(setLoading(true));
            var extendedZoneFunct = httpsCallable(functions,"zonaExtendida3rdParty");
            extendedZoneFunct({
                cp : zip,
            }).then(async (value:any)=>{
                let data = value.data;
                let zonaExt = Array<carrierExtendedZone>();
                if(data.informacion.estado&&data.informacion.municipio){
                    if(data.informacion["99minutos"])
                        zonaExt.push({carrier : "99minutos", isExtendedZone: isExtendedZone(data.informacion["99minutos"].zonaExtendida)? true:false})
                    if(data.informacion["DHL"])
                        zonaExt.push({carrier : "DHL", isExtendedZone: isExtendedZone(data.informacion["DHL"].zonaExtendida)? true:false})
                    if(data.informacion["Estafeta"])
                        zonaExt.push({carrier : "Estafeta", isExtendedZone: isExtendedZone(data.informacion["Estafeta"].zonaExtendida)? true:false})
                    if(data.informacion["Fedex"])
                        zonaExt.push({carrier : "Fedex", isExtendedZone: isExtendedZone(data.informacion["Fedex"].zonaExtendida)? true:false})
                    if(data.informacion["RedPack"])
                        zonaExt.push({carrier : "RedPack", isExtendedZone: isExtendedZone(data.informacion["RedPack"].zonaExtendida)? true:false})
                    dispatch<any>(setExtendedZone(true, isOrigin, zonaExt));
                }else{
                    dispatch<any>(setExtendedZone(false, isOrigin, zonaExt));
                }
                dispatch<any>(setLoading(false))
            }).catch((error)=>{
                dispatch<any>(setLoading(false))
                dispatch(setError(error));
            });
        }
        catch (err: any){
            dispatch(setError(err.message));
        }
    }

}

export const submitNewShipmentForRedpack = (
    originDir: UserDirectoryFav,
    destinyDir:UserDirectoryFav,
    originSaveFav:boolean,
    destinySaveFav:boolean,
    packageToShip:PackageBasicData,
    selectedCarrier:CarrierPrice,
    apiConnection:Array<ApiConnection>,
    user:User,
    groupSelected:any,
    optionsForShipment?:OptionsForShipment
): ThunkAction<void, RootState, null, ApiAction> => {
    return async dispatch => {
        try {
            if(selectedCarrier.apiProvider&&apiConnection[0].provider===apiProviders[2]){ //redpack
                const redpackApiCredentials:ApiConnection = apiConnection[0]
                const enviosHappyId:string= generateHappyId()
                //get redpack token
                const username = redpackApiCredentials.production ? redpackApiCredentials.prodCredentials.user : redpackApiCredentials.testCredentials.user;
                const password = redpackApiCredentials.production ? redpackApiCredentials.prodCredentials.password : redpackApiCredentials.testCredentials.password;
                const tokenUrl = redpackApiCredentials.production && redpackApiCredentials.requestToken ? 
                redpackApiCredentials.requestToken.prodUrl : redpackApiCredentials.requestToken.testUrl
                // const encodedCredentials = redpackApiCredentials.production ? redpackApiCredentials.prodCredentials.base64Encoded : redpackApiCredentials.testCredentials.base64Encoded;
                const encodedCredentials = 'YXBwLXJlZHBhY2std2ViOlIzZFBhY2smMjAyMA==' //replace with Backend above, once ready

                let authorizationHeaderString = 'Basic ' + encodedCredentials;
                let headersToUse: HeadersInit= {...redpackApiHeader,'Authorization':authorizationHeaderString}
                const setDataForToken:any = {
                    grant_type: 'password',
                    username: username,
                    password: password,
                };
                const paramsToSend = Object.keys(setDataForToken)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(setDataForToken[key])}`)
                .join('&'); //format is x-www-form-urlencoded
                const response= await fetch(
                    tokenUrl, {
                    method:redpackApiCredentials.requestToken?.method,
                    headers: headersToUse,
                    body: paramsToSend
                }).then(response => {
                    if (response.ok) {
                      return response.json(); // Parse response body as JSON
                    } else {
                      throw new Error('Request failed');
                    }
                  })
                  .then(data => {
                    // Data is now the parsed JSON object
                    console.log(data);
                    // Use the data as needed
                  })
                  .catch(error => {
                    console.error(error);
                    // Handle any errors that occurred during the request
                  });

                //Token back? create redpack Wabill payload
                //console.log(enviosHappyId)
                
            }
        }
        catch (err: any){
            //console.log(err)
        }
    }

}

/*export const submitNewShipmentForFedex = (
    originDir: UserDirectoryFav,
    destinyDir:UserDirectoryFav,
    originSaveFav:boolean,
    destinySaveFav:boolean,
    packageToShip:PackageBasicData,
    selectedCarrier:CarrierPrice,
    apiConnection:Array<ApiConnection>,
    user:User,
    groupSelected:any,
    optionsForShipment?:OptionsForShipment
): ThunkAction<void, RootState, null, ApiAction> => {
    return async dispatch => {
        try {
            if(selectedCarrier.apiProvider&&apiConnection[0].provider===apiProviders[1]){ //fedex
                const fedexApiCredentials:ApiConnection = apiConnection[0]
                const enviosHappyId:string= generateHappyId()
                //create Fedex Wabill payload
                console.log('are you making it here for Fedex? ')
                console.log(fedexApiCredentials)
                console.log(enviosHappyId)
                const fedexCreateWabillObject: FedexCreateExpressOneLabelRequest = {
                }
            }
        }
        catch (err: any){

        }
    }

}*/

export const submitNewShipmentForRabee = (
        originDir: UserDirectoryFav,
        destinyDir:UserDirectoryFav,
        originSaveFav:boolean,
        destinySaveFav:boolean,
        packageToShip:PackageBasicData,
        selectedCarrier:CarrierPrice,
        apiConnection:Array<ApiConnection>,
        user:User,
        groupSelected:any,
        optionsForShipment?:OptionsForShipment
    ): ThunkAction<void, RootState, null, ApiAction> => {
        return async dispatch => {
            try {
                if(selectedCarrier.apiProvider&&apiConnection[0].provider===apiProviders[4]){ //discontinued
                    const rabeeApiCredentials:ApiConnection = apiConnection[0]
                    const enviosHappyId:string= generateHappyId()
                    const rabeeGoods: Goods = {
                        enName:packageToShip.contenido?packageToShip.contenido:'',
                        quantity: packageToShip.qty?packageToShip.qty:0
                    }

                      const rabeeCreateWabillObject: Waybill = {
                        goods:[rabeeGoods],
                        waybill: {
                          frCity:originDir.city?originDir.city:'',
                          frCode:originDir.zipCode?originDir.zipCode:'',
                          frCountry:originDir.country?originDir.country:'',
                          frName:originDir.fullName?originDir.fullName:'',
                          frCorporateName:originDir.company?originDir.company:'',
                          frPhone:originDir.phone?originDir.phone:'',
                          frState:originDir.state?originDir.state:'',
                          frStreet:originDir.address?originDir.address:'',
                          frNeighborhood:originDir.neighborhood?originDir.neighborhood:"",
                          frReference:originDir.reference?originDir.reference:"",
                          frRFC:originDir.rfc?originDir.rfc:"",
                          height:packageToShip.alto,
                          length:packageToShip.largo,
                          serviceType:packageToShip.tipoServicio?packageToShip.tipoServicio:'STD', //this can be EXP si se quiere express, preguntar a Jair
                          size:1,
                          toCity:destinyDir.city?destinyDir.city:'',
                          toCode:destinyDir.zipCode?destinyDir.zipCode:'',
                          toCountry:destinyDir.country?destinyDir.country:'',
                          toName:destinyDir.fullName?destinyDir.fullName:'',
                          toCorporateName:destinyDir.company?destinyDir.company:'',
                          toPhone:destinyDir.phone?destinyDir.phone:'',
                          toState:destinyDir.state?destinyDir.state:'',
                          toStreet:destinyDir.address?destinyDir.address:'',
                          toNeighborhood:destinyDir.neighborhood?destinyDir.neighborhood:"",
                          toReference:destinyDir.reference?destinyDir.reference:"",
                          toRFC:destinyDir.rfc?destinyDir.rfc:"",
                          totalAmount:2000,
                          waybillNo:enviosHappyId,
                          weight:packageToShip.peso,
                          width:packageToShip.ancho,
                          reference:packageToShip.comments?packageToShip.comments:'',
                          hasCartaPorte:false
                        }  
                      }
                      if(originSaveFav){
                        let newDirectoryData: UserDirectoryFav = {
                          _id: '',
                          createdBy: user?user.uid:'',
                          createdAt:'',
                          alias: originDir.alias?originDir.alias:'',
                          fullName:originDir.fullName?originDir.fullName:'',
                          phone:originDir.phone?originDir.phone:'',
                          zipCode:originDir.zipCode?originDir.zipCode:'',
                          address:originDir.address?originDir.address:'',
                          email:originDir.email?originDir.email:'',
                          neighborhood:originDir.neighborhood?originDir.neighborhood:'',
                          city:originDir.city?originDir.city:'',
                          state:originDir.state?originDir.state:'',
                          country:originDir.country?originDir.country:'',
                          company:originDir.company?originDir.company:'',
                          reference:originDir.reference?originDir.reference:'',
                          rfc:originDir.rfc?originDir.rfc:''
                        }
                        if(user && newDirectoryData) {
                          dispatch<any>(createDirectory(newDirectoryData, user))
                        }
                      }
                      if(destinySaveFav){
                        let newDirectoryData: UserDirectoryFav = {
                          _id: '',
                          createdBy: user?user.uid:'',
                          createdAt:'',
                          alias: destinyDir.alias?destinyDir.alias:'',
                          fullName:destinyDir.fullName?destinyDir.fullName:'',
                          phone:destinyDir.phone?destinyDir.phone:'',
                          zipCode:destinyDir.zipCode?destinyDir.zipCode:'',
                          address:destinyDir.address?destinyDir.address:'',
                          email:destinyDir.email?destinyDir.email:'',
                          neighborhood:destinyDir.neighborhood?destinyDir.neighborhood:'',
                          city:destinyDir.city?destinyDir.city:'',
                          state:destinyDir.state?destinyDir.state:'',
                          country:destinyDir.country?destinyDir.country:'',
                          company:destinyDir.company?destinyDir.company:'',
                          reference:destinyDir.reference?destinyDir.reference:'',
                          rfc:destinyDir.rfc?destinyDir.rfc:''
                        }
                        if(user && newDirectoryData) {
                          dispatch<any>(createDirectory(newDirectoryData, user))
                        }
                      }
                      
                      dispatch<any>(rabeeCreateWaybill(
                        rabeeCreateWabillObject, 
                        user, 
                        rabeeApiCredentials, 
                        groupSelected, 
                        packageToShip,
                        selectedCarrier,
                        optionsForShipment?optionsForShipment:undefined
                        )
                    )
                    const userNewBalance:User = {
                        ...user,
                        balance:user.balance ? user.balance - selectedCarrier.amount : 0
                    }
                    dispatch<any>(updateUserInfo(userNewBalance, ()=>setLoading(true)))
                }

            } catch(err: any) {
                //console.log(err.message)
                dispatch(setError(err.message));
            }
        }
    }

export const submitNewShipmentForDHL = (
    originDir: UserDirectoryFav,
    destinyDir:UserDirectoryFav,
    originSaveFav:boolean,
    destinySaveFav:boolean,
    packageToShip:PackageBasicData,
    selectedCarrier:CarrierPrice,
    apiConnection:ApiConnection, 
    user:User,
    groupSelected:StoreBasicData|null|undefined,
    carrierID : string,
    optionsForShipment?:OptionsForShipment
): ThunkAction<void, RootState, null, ApiAction> => {
    return async dispatch => {
        try {
            let DHLObj = new DHLShipment(apiConnection.testCredentials.customerNmbr);
            DHLObj.updateData(user, originDir, destinyDir, packageToShip, selectedCarrier, groupSelected?groupSelected:undefined);
            if(selectedCarrier.apiProvider&&apiConnection.provider===apiProviders[3]){
                var DHLFunct = httpsCallable(functions,"DHLApiCall");
                DHLFunct({
                    shipment : DHLObj.getTransactableDataObj(),
                    carrierID : carrierID,
                }).then(async (value:any)=>{
                    if(value.data){
                        let shipmentColecction = DHLObj.processCarrierApiResponse(value);
                        if(groupSelected){
                            if(originSaveFav && user && originDir){
                                dispatch<any>(createDirectory(originDir, user))
                            }
                            if(originSaveFav && user && destinyDir){
                                dispatch<any>(createDirectory(destinyDir, user))
                            }        
                            const userNewBalance:User = {
                                ...user,
                                balance:user.balance ? user.balance - (selectedCarrier.amount*packageToShip.qty) : 0
                            }
                            dispatch<any>(updateUserInfo(userNewBalance, ()=>setLoading(true)))
                            const shipmentsRef = collection(firestore, 'shipments')
                            for(let i =0; i < shipmentColecction.length; i ++){
                                const docSaved = await addDoc(shipmentsRef,  shipmentToObj(shipmentColecction[i]));
                                await setDoc(doc(shipmentsRef, docSaved.id), {_id:docSaved.id}, {merge:true})
                            }
                            //open Dialog to show success
                            dispatch<any>(setDialog(true))
                            dispatch<any>(setDialogType(dialogTypes[0]))
                            dispatch<any>(setDialogBody(DHLObj.returnInterface()))
                            //set origin, destiny and package in store
                            const setStore: boolean = false
                            dispatch<any>(setOriginDirShipment(setStore))
                            dispatch<any>(setDestinyDirShipment(setStore))
                            dispatch<any>(setPackageToShip(setStore))
                            dispatch<any>(setOptionsForShipment(setStore))
                            dispatch<any>(setSelectedPriceCarrier(setStore))
                            dispatch<any>(setLoading(false))
                        }
                    }else{
                        dispatch<any>(setDialog(true))
                        dispatch<any>(setDialogType(dialogTypes[4]))
                        //dispatch<any>(setDialogBody(estafetaObj))
                        dispatch<any>(setLoading(false))
                    }
                }).catch((error)=>{
                    dispatch<any>(setDialog(true))
                    dispatch<any>(setDialogType(dialogTypes[5]))
                    dispatch<any>(setTextMessage(JSON.stringify(error)));
                    dispatch<any>(setLoading(false))
                });
            }
        }
        catch (err: any){
            dispatch<any>(setDialog(true))
            dispatch<any>(setDialogType(dialogTypes[5]))
            dispatch<any>(setTextMessage(JSON.stringify(err)));
            dispatch<any>(setLoading(false))
        }
    }

}

export const submitNewShipmentForFedex = (
    originDir: UserDirectoryFav,
    destinyDir:UserDirectoryFav,
    originSaveFav:boolean,
    destinySaveFav:boolean,
    packageToShip:PackageBasicData,
    selectedCarrier:CarrierPrice,
    apiConnection:Array<ApiConnection>,
    user:User,
    groupSelected:StoreBasicData|null|undefined,
    carrierID : string,
    optionsForShipment?:OptionsForShipment
): ThunkAction<void, RootState, null, ApiAction> => {
    return async dispatch => {
        try {
            let environmentObj = apiConnection[0].production?apiConnection[0].prodCredentials:apiConnection[0].testCredentials;
            let FedexObj = new FedexShipment(environmentObj.customerNmbr);
            FedexObj.updateData(user, originDir, destinyDir, packageToShip, selectedCarrier, groupSelected?groupSelected:undefined);
            if(selectedCarrier.apiProvider&&(apiConnection[0].provider===apiProviders[1]||apiConnection[0].provider===apiProviders[4])){
                var estafetaFunct = httpsCallable(functions,"FedexApiCall");
                for(let i = 0; i < packageToShip.qty; i++){
                    estafetaFunct({
                        shipment : FedexObj.getTransactableDataObj(),
                        carrierID : apiConnection[0]._id
                    }).then(async (value:any)=>{
                        if(value.data.output){
                            let shipmentColecction = FedexObj.processCarrierApiResponse(value);
                            console.log(FedexObj);
                            if(originDir.rfc != "" && destinyDir.rfc!= "", packageToShip.unitKey != '' && packageToShip.goodsTransported != '' && packageToShip.valueGoods && packageToShip.valueGoods >= 0){
                                customerCartaPorteFEDEX(FedexObj, value, async (hasCartaPorte:boolean)=>{
                                    FedexObj.setCartaPorte(hasCartaPorte);
                                    if(groupSelected){
                                        if(originSaveFav && user && originDir){
                                            dispatch<any>(createDirectory(originDir, user))
                                        }
                                        if(originSaveFav && user && destinyDir){
                                            dispatch<any>(createDirectory(destinyDir, user))
                                        }        
                                        const userNewBalance:User = {
                                            ...user,
                                            balance:user.balance ? user.balance - selectedCarrier.amount : 0
                                        }
                                        dispatch<any>(updateUserInfo(userNewBalance, ()=>setLoading(true)))
                                        const shipmentsRef = collection(firestore, 'shipments')
                                        for(let i =0; i < shipmentColecction.length; i ++){
                                            const docSaved = await addDoc(shipmentsRef,  shipmentToObj(shipmentColecction[i]));
                                            await setDoc(doc(shipmentsRef, docSaved.id), {_id:docSaved.id}, {merge:true})
                                        }
                                        //open Dialog to show success
                                        dispatch<any>(setDialog(true))
                                        dispatch<any>(setDialogType(dialogTypes[0]))
                                        dispatch<any>(setDialogBody(FedexObj.returnInterface()))
                                        //set origin, destiny and package in store
                                        const setStore: boolean = false
                                        dispatch<any>(setOriginDirShipment(setStore))
                                        dispatch<any>(setDestinyDirShipment(setStore))
                                        dispatch<any>(setPackageToShip(setStore))
                                        dispatch<any>(setOptionsForShipment(setStore))
                                        dispatch<any>(setSelectedPriceCarrier(setStore))
                                        //dispatch<any>(waybillAvailable)
                                        dispatch<any>(setLoading(false))
                                    }
                                })
                            }else{
                                if(groupSelected){
                                    if(originSaveFav && user && originDir){
                                        dispatch<any>(createDirectory(originDir, user))
                                    }
                                    if(originSaveFav && user && destinyDir){
                                        dispatch<any>(createDirectory(destinyDir, user))
                                    }        
                                    const userNewBalance:User = {
                                        ...user,
                                        balance:user.balance ? user.balance - selectedCarrier.amount : 0
                                    }
                                    dispatch<any>(updateUserInfo(userNewBalance, ()=>setLoading(true)))
                                    const shipmentsRef = collection(firestore, 'shipments')
                                    for(let i =0; i < shipmentColecction.length; i ++){
                                        const docSaved = await addDoc(shipmentsRef,  shipmentToObj(shipmentColecction[i]));
                                        await setDoc(doc(shipmentsRef, docSaved.id), {_id:docSaved.id}, {merge:true})
                                    }
                                    //open Dialog to show success
                                    dispatch<any>(setDialog(true))
                                    dispatch<any>(setDialogType(dialogTypes[0]))
                                    dispatch<any>(setDialogBody(FedexObj.returnInterface()))
                                    //set origin, destiny and package in store
                                    const setStore: boolean = false
                                    dispatch<any>(setOriginDirShipment(setStore))
                                    dispatch<any>(setDestinyDirShipment(setStore))
                                    dispatch<any>(setPackageToShip(setStore))
                                    dispatch<any>(setOptionsForShipment(setStore))
                                    dispatch<any>(setSelectedPriceCarrier(setStore))
                                    //dispatch<any>(waybillAvailable)
                                    dispatch<any>(setLoading(false))
                                }
                            }
                            //let waybillAvailable = customerCartaPorteFEDEX(FedexObj, value) //Comprobacion Carta Porte FEDEX
                        }else{
                            dispatch<any>(setDialog(true))
                            dispatch<any>(setDialogType(dialogTypes[5]))
                            dispatch<any>(setTextMessage(JSON.stringify(value)));
                            //dispatch<any>(setDialogBody(FedexObj))
                            //dispatch<any>(waybillAvailable)
                            dispatch<any>(setLoading(false))
                        }
                    }).catch((error)=>{
                        dispatch<any>(setDialog(true))
                        dispatch<any>(setDialogType(dialogTypes[5]))
                        dispatch<any>(setTextMessage(JSON.stringify(error)));
                        //dispatch<any>(waybillAvailable)
                        dispatch<any>(setLoading(false))
                    });
                }
                
            }

        }
        catch (err: any){
            dispatch<any>(setDialog(true))
            dispatch<any>(setDialogType(dialogTypes[5]))
            dispatch<any>(setTextMessage(JSON.stringify(err)));
            dispatch<any>(setLoading(false))
        }
    }
}


export const submitNewShipmentForComandoEnviosHappy = (
    originDir: UserDirectoryFav,
    destinyDir:UserDirectoryFav,
    originSaveFav:boolean,
    destinySaveFav:boolean,
    packageToShip:PackageBasicData,
    selectedCarrier:CarrierPrice,
    apiConnection:Array<ApiConnection>,
    user:User,
    groupSelected:StoreBasicData|null|undefined,
    carrierID : string,
    optionsForShipment?:OptionsForShipment
): ThunkAction<void, RootState, null, ApiAction> => {
    return async dispatch => {
        try {
            let environmentObj = apiConnection[0].production?apiConnection[0].prodCredentials:apiConnection[0].testCredentials;
            let envHappyObj = new EnviosHappyShipment();
            envHappyObj.updateData(user, originDir, destinyDir, packageToShip, selectedCarrier, groupSelected?groupSelected:undefined);
            if(selectedCarrier.apiProvider && apiConnection[0].provider===apiProviders[5]){
                var estafetaFunct = httpsCallable(functions,"enviosHappyComando");
                for(let i = 0; i < packageToShip.qty; i++){
                    estafetaFunct({
                        shipment : envHappyObj.getTransactableDataObj(),
                        carrierID : apiConnection[0]._id
                    }).then(async (value:any)=>{
                        if(value.data.status=="success"){
                            let shipmentColecction = envHappyObj.processCarrierApiResponse(value.data);
                            if(groupSelected){
                                if(originSaveFav && user && originDir){
                                    dispatch<any>(createDirectory(originDir, user))
                                }
                                if(originSaveFav && user && destinyDir){
                                    dispatch<any>(createDirectory(destinyDir, user))
                                }        
                                const userNewBalance:User = {
                                    ...user,
                                    balance:user.balance ? user.balance - selectedCarrier.amount : 0
                                }
                                dispatch<any>(updateUserInfo(userNewBalance, ()=>setLoading(true)))
                                const shipmentsRef = collection(firestore, 'shipments')
                                for(let i =0; i < shipmentColecction.length; i ++){
                                    const docSaved = await addDoc(shipmentsRef,  shipmentToObj(shipmentColecction[i]));
                                    await setDoc(doc(shipmentsRef, docSaved.id), {_id:docSaved.id}, {merge:true})
                                }
                                //open Dialog to show success
                                dispatch<any>(setDialog(true))
                                dispatch<any>(setDialogType(dialogTypes[0]))
                                dispatch<any>(setDialogBody(envHappyObj.returnInterface()))
                                //set origin, destiny and package in store
                                const setStore: boolean = false
                                dispatch<any>(setOriginDirShipment(setStore))
                                dispatch<any>(setDestinyDirShipment(setStore))
                                dispatch<any>(setPackageToShip(setStore))
                                dispatch<any>(setOptionsForShipment(setStore))
                                dispatch<any>(setSelectedPriceCarrier(setStore))
                                dispatch<any>(setLoading(false))
                            }
                        }else{
                            dispatch<any>(setDialog(true))
                            dispatch<any>(setDialogType(dialogTypes[5]))
                            dispatch<any>(setTextMessage(JSON.stringify(value)));
                            //dispatch<any>(setDialogBody(FedexObj))
                            dispatch<any>(setLoading(false))
                        }
                    }).catch((error)=>{
                        dispatch<any>(setDialog(true))
                        dispatch<any>(setDialogType(dialogTypes[5]))
                        dispatch<any>(setTextMessage(JSON.stringify(error)));
                        dispatch<any>(setLoading(false))
                    });
                }
                
            }

        }
        catch (err: any){
            dispatch<any>(setDialog(true))
            dispatch<any>(setDialogType(dialogTypes[5]))
            dispatch<any>(setTextMessage(JSON.stringify(err)));
            dispatch<any>(setLoading(false))
        }
    }
}

export const fedexRatingSimple = (
    carrierID: string,
    ratingObj : any
): Promise<Array<any>> | null | undefined=> {
    try {
        var estafetaFunct = httpsCallable(functions,"FedexApiRateCall");
        estafetaFunct({
            ratingObj : ratingObj,
            carrierID : carrierID
        }).then(async (value:any)=>{
            if(value.data.output.rateReplyDetails[0]){
                let rtnArr = value.data.output.rateReplyDetails[0].ratedShipmentDetails as Array<any>;
                rtnArr.forEach((r=>{
                    r.serviceType = value.data.output.rateReplyDetails[0].serviceType;
                }))
                return new Promise((resolve)=>{
                    resolve(rtnArr)
                });
            }else
                return new Promise((resolve)=>{
                    resolve(null)
                });
        }).catch((error)=>{
            return new Promise((resolve)=>{
                resolve(null)
            });
        });

    }
    catch (err: any){
        return new Promise((resolve)=>{
            resolve(new Array<any>())
        });
    }
}


/*export const fedexRating = (
    carrierID: string,
    ratingObj : any,
    callbac : any
): ThunkAction<void, RootState, null, ApiAction> => {
    return async dispatch => {
        try {
            var estafetaFunct = httpsCallable(functions,"FedexApiRateCall");
            estafetaFunct({
                ratingObj : ratingObj,
                carrierID : carrierID
            }).then(async (value:any)=>{
                if(value.data.output&&value.data.output.rateReplyDetails[0]){
                    let rtnArr = value.data.output.rateReplyDetails[0].ratedShipmentDetails as Array<any>;
                    rtnArr.forEach((r=>{
                        r.serviceType = value.data.output.rateReplyDetails[0].serviceType;
                        dispatch<any>(updateCarrierRatings({carrier: "Fedex", rating : r}))
                    }))
                    callbac();
                  }
            }).catch((error)=>{
                return error;
            });
    
        }
        catch (err: any){
            console.log("Error Rating", err)
        }
    }
}*/

export const fedexRating = (
    carrierID: string,
    ratingObj : any,
    callback : any
): void => {
    //return async dispatch => {
        try {
            var estafetaFunct = httpsCallable(functions,"FedexApiRateCall");
            estafetaFunct({
                ratingObj : ratingObj,
                carrierID : carrierID
            }).then(async (value:any)=>{
                if(value.data.output&&value.data.output.rateReplyDetails[0]){
                    let rtnArr = value.data.output.rateReplyDetails[0].ratedShipmentDetails as Array<any>;
                    for(let i = 0; i < rtnArr.length; i++){
                        rtnArr[i].serviceType = value.data.output.rateReplyDetails[0].serviceType;
                        //updateCarrierRatings({carrier: "Fedex", rating : r})
                    }
                    /*rtnArr.forEach((r=>{
                        r.serviceType = value.data.output.rateReplyDetails[0].serviceType;
                        updateCarrierRatings({carrier: "Fedex", rating : r})
                    }))*/
                    callback(rtnArr);
                  }
            }).catch((error)=>{
                callback(null)
            });
    
        }
        catch (err: any){
            console.log("Error Rating", err)
        }
    //}
}

export const getShipmentData = (page : number, user : User | null, prevSubset:  {data : ShipmentInterface[], page : number}|null, franchises : Array<Store>|null):ThunkAction<void, RootState, null, SetShipmentsAction> => {

    return async dispatch => {
        try {
            const size = 100;
            const start = ((page -1) * size)+1;
            let now = new Date();
            now.setMonth(now.getMonth()-1);
            now.setHours(0,0,0,0);
            const currentMonth: Date = new Date(now.getFullYear()+'-'+(now.getMonth() + 1).toString().padStart(2, '0')+ '-'+now.getDate().toString().padStart(2, '0'))
            const shipmentsRef = collection(firestore, 'shipments')
            let shipmentQuery = query((shipmentsRef),   
                where('lastModAt','>=', Timestamp.fromDate(currentMonth)),
                orderBy("lastModAt", "desc"));
            if(user&&user.role === roleTypeArray[1])
                console.log("shipments for members")
            if(user&&user.role === roleTypeArray[2]){ //supervisor
                if(franchises){
                    let condtmp = new Array<string>();
                    for(let i = 0; i<franchises.length; i++){
                        condtmp.push(franchises[i]._id)
                    }
                    shipmentQuery = query(shipmentQuery, where("groupId","in", condtmp))
                }
            }
            if(user&&user.role === roleTypeArray[3]){
                shipmentQuery = query(shipmentQuery, where('createdBy','==', user.uid))
            }
            getCountFromServer(shipmentQuery).then((val) => {
                if(prevSubset && prevSubset.page < page){
                    shipmentQuery = query(shipmentQuery, startAfter(prevSubset.data[prevSubset.data.length-1].lastModAt)) ;
                    
                }else if(prevSubset && prevSubset.page > page){
                    shipmentQuery = query(shipmentQuery, endBefore(prevSubset.data[0].lastModAt));
                }
                shipmentQuery = query(shipmentQuery, limit(100))
                onSnapshot(shipmentQuery, (querySnapshot) => {
                    let shipmentsArray: Array<ShipmentInterface> = querySnapshot.docs.map((doc) => {
                        const shipmentDoc:ShipmentInterface = {...doc.data() as ShipmentInterface, _id:doc.id}
                        return shipmentDoc
                    })
                    if(prevSubset && prevSubset.page > page)
                    shipmentsArray.pop();
                    dispatch<any>(setShipments(shipmentsArray,page,val.data().count, false, false))
                })
            }).catch((r)=>{
                //console.log("error catch",r);
            });
        }
        catch (err: any){
            //console.log("Error Rating", err)
        }
    }
}
 
export const getBulkShipmentData = (user : User | null, franchises : Array<Store>|null, dateRange : Range):ThunkAction<void, RootState, null, SetShipmentsAction> => {
    return async dispatch => {
        try {
            if(dateRange.startDate && dateRange.endDate){
                const shipmentsRef = collection(firestore, 'shipments')
                let shipmentQuery = query((shipmentsRef),   
                    where('lastModAt','>=', Timestamp.fromDate(dateRange.startDate)),
                    where('lastModAt','<=', Timestamp.fromDate(dateRange.endDate)),
                    orderBy("lastModAt", "desc"));
                if(user&&user.role === roleTypeArray[2]){ 
                    if(franchises){
                        let condtmp = new Array<string>();
                        for(let i = 0; i<franchises.length; i++){
                            condtmp.push(franchises[i]._id)
                        }
                        shipmentQuery = query(shipmentQuery, where("groupId","in", condtmp))
                    }
                }
                if(user&&user.role === roleTypeArray[3]){
                    shipmentQuery = query(shipmentQuery, where('createdBy','==', user.uid))
                }
                getCountFromServer(shipmentQuery).then((val) => {
                    onSnapshot(shipmentQuery, (querySnapshot) => {
                        let shipmentsArray: Array<ShipmentInterface> = querySnapshot.docs.map((doc, i, arr) => {
                            const shipmentDoc:ShipmentInterface = {...doc.data() as ShipmentInterface, _id:doc.id}
                            return shipmentDoc
                        })
                        dispatch<any>(setShipments(shipmentsArray,-1,val.data().count, false, true))
                    })
                })
                
            }
            
        }
        catch (err: any){
            console.log("Error Rating", err)
        }
    }
}

//customerCartaPorteFDX

export const customerCartaPorteFEDEX = (FedexObj:any, value:any, callback:any): void => {
    const cartaPorte = {
        "valueGoods"        :   FedexObj.packageObj?.valueGoods,
        "unitKey"           :   FedexObj.packageObj?.unitKey,
        "goodsTransported"  :   FedexObj.packageObj?.goodsTransported,
        "content"           :   FedexObj.packageObj.contenido,

        "frAddress"         :   FedexObj.origin.address+" , "+
                                FedexObj.origin.neighborhood+" , "+
                                FedexObj.origin.city+" , "+
                                FedexObj.origin.country,
        "frRFC"             :   FedexObj.origin?.rfc,

        "toAddress"         :   FedexObj.destination.address+" , "+
                                FedexObj.destination.neighborhood+" , "+
                                FedexObj.destination.city+" , "+
                                FedexObj.destination.country,
        "toRFC"             :   FedexObj.destination?.rfc,

        "waybillNo"         :   value.data.output.transactionShipments[0].masterTrackingNumber
    }
    //console.log("Comprobando Carta Porte... ")
    //console.log("cartaPorte: ",cartaPorte)
    try {
        var createWaybill = httpsCallable(functions,"createWaybill_FEDEX");
        createWaybill(cartaPorte).then(async (value:any)=>{
            //console.log("DATA",value.data)
            callback(true) 
            //dispatch<any>(setWaybillAvailable(true))
            
        }).catch((error)=>{
            callback(false) 
            //dispatch<any>(setWaybillAvailable(true))
            //console.log("Error")
        });
    }
    catch (err: any){
        callback(false) 
        //setWaybillAvailable(false)
        //sconsole.log("Error Rating", err)
    }
}

function useState<T>(arg0: boolean): [any, any] {
    throw new Error('Function not implemented.');
}
