import React, {FC, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { User } from '../../../types/authTypes';
import { CarrierPrice } from '../../../types/storeTypes';

import { roleTypeArray } from '../../../features/referenceData/userLists';

import { setEditStore, setCarrierPriceToEdit } from '../../../store/actions/storeActions';
import { setShowForm, setUserToUpdate, setPublicPrice } from '../../../store/actions/genericActions';

import MemoizedNewCarrierForm from '../../UI/forms/NewCarrierForm';
import CarrierPricesTable from './CarrierPricesTable';

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const AdminCarriers: FC = () => {

    const dispatch = useDispatch();
    const user: User | null = useSelector((state: RootState) => state.auth.user);
    const { loading, showForm } = useSelector((state: RootState) => state.generic);
    const {carrierPrices} = useSelector((state: RootState) => state.stores)
    const carrierPriceToEdit:CarrierPrice|null = useSelector((state: RootState) => state.stores.carrierPriceToEdit)
    const updateCarrierPriceFlag:boolean = carrierPriceToEdit&&carrierPriceToEdit._id?true:false

    const [searchName, setSearchName] = useState<string>();
    const [filteredCarriers, setFilteredCarriers] = useState<Array<CarrierPrice> |null>(
        carrierPrices ? carrierPrices : null
    )
    
    const toggleFormHandler = () => {
        const currentState = showForm
        if(showForm){
            dispatch<any>(setEditStore(undefined))
        }
        dispatch<any>(setShowForm(!currentState))
        const saveUserInStore: boolean = false
        dispatch<any>(setUserToUpdate(saveUserInStore))
        dispatch<any>(setCarrierPriceToEdit())
        dispatch<any>(setPublicPrice(true))
    }
    
    const searchByName = (stringToSearch:string) => {
      const stringToSearchToLowerCase: string = stringToSearch.toLowerCase()
      setSearchName(stringToSearchToLowerCase);
    }

    useEffect(()=> {
        if(searchName) {
            setFilteredCarriers(carrierPrices && carrierPrices.filter((carrier:CarrierPrice) => {
                return carrier.name.toLowerCase().includes(searchName)
            }))
        }
        if(!searchName || searchName === ''){
            setFilteredCarriers(carrierPrices)
        }
  
    },[
        searchName,
        carrierPrices
    ])

    return (
    <Card elevation={0} sx={{borderRadius:'30px'}}>
        {user? 
            <CardContent>
                <Box className="d-flex-row-start-centered">
                    <Typography variant="h6" sx={{fontWeight:600, my:1.5, mr:2.5}}>
                        {   
                            !showForm&&!carrierPriceToEdit ? 'Carriers y precios' :
                            showForm&&!carrierPriceToEdit ? 'Agregar precio de servicio' :
                            showForm&&carrierPriceToEdit&&carrierPriceToEdit._id ? 'Editar precio de servicio' :
                            ''
                        }
                    </Typography>
                    {!showForm ?
                        <TextField
                            required
                            color="secondary"
                            size="small"
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                ),
                            }}
                            sx={{my:1.5, mr:2.5}}
                            id="outlined-required"
                            onChange={(e:any) => searchByName(e.currentTarget.value)}
                            placeholder='Filtrar por nombre'
                            type="text"
                        />
                        :null
                    }
                    {user.role === roleTypeArray[1] || user.role === roleTypeArray[2]?
                        <LoadingButton
                            loading={loading}
                            color='secondary'
                            // size='small'
                            disableElevation
                            sx={{my:1.5, mx:1.5}}
                            variant={showForm ? 'text':"contained"}
                            onClick={toggleFormHandler}
                            startIcon={
                                !showForm ?
                                <AddBoxIcon />
                                :
                                <ArrowBackIosIcon />
                            }
                        >   
                            
                            {showForm ? 'Regresar' : 'Crear nuevo precio'}
                        </LoadingButton>
                        :null
                    }
                </Box>
                {!showForm && carrierPrices ?
                    <CarrierPricesTable user={user} carrierPrices={filteredCarriers} />
                    :null
                }
                {showForm ?
                    <MemoizedNewCarrierForm user={user} client = {user} />
                    :null
                }
            </CardContent>
            :null
        }
    </Card>

    )
}

export default AdminCarriers