import React, {FC} from 'react'
interface SvgParams {
    width:any,
    height:any
  }
const EHLogotipoWhite: FC<SvgParams> = (props) => {
    const{width, height} = props
  return (
    <svg width={width} height={height} viewBox="0 0 55 53" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_233_22555)">
        <path d="M20.96 26.48C20.96 27.55 20.09 28.41 19.03 28.41C17.97 28.41 17.08 27.54 17.08 26.48C17.08 25.42 17.95 24.53 19.03 24.53C20.11 24.54 20.96 25.41 20.96 26.48Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M30.8 35.5301L28.1 38.8001C27.83 39.1501 27.42 39.3101 27.02 39.3101C26.62 39.3101 26.23 39.1501 25.93 38.8001L23.24 35.5301C22.48 34.6101 23.13 33.2501 24.32 33.2501H29.71C30.9 33.2401 31.54 34.6101 30.8 35.5301Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M36.9499 26.48C36.9499 27.55 36.0799 28.41 35.0199 28.41C33.9599 28.41 33.0699 27.54 33.0699 26.48C33.0699 25.42 33.9499 24.53 35.0199 24.53C36.0899 24.54 36.9499 25.41 36.9499 26.48Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M52.22 16.9199C52.22 16.9199 54.08 16.7099 53.22 11.3699C52.55 7.05993 47.13 5.74993 45.09 5.40993C46.68 5.56993 49.7 5.60993 45.07 2.35993C39.5 -1.52007 28.76 1.82993 27.32 2.29993C25.88 1.81993 15.12 -1.52007 9.57002 2.35993C4.93002 5.59993 7.96002 5.56993 9.55002 5.40993C7.50003 5.75993 2.10003 7.05993 1.42003 11.3699C0.580025 16.7099 2.42003 16.9199 2.42003 16.9199C2.42003 16.9199 -0.889975 24.5799 1.19003 27.8299C3.26003 31.0699 6.04003 27.8299 6.04003 27.8299C6.04003 27.8299 2.58003 39.4099 7.47003 42.3299C12.35 45.2699 12.11 42.3299 12.11 42.3299C12.11 42.3299 13.9 51.2799 18.59 51.7499C22.02 52.0799 25.63 51.2699 27.33 50.8099C29.03 51.2699 32.65 52.0899 36.07 51.7499C40.77 51.2799 42.55 42.3299 42.55 42.3299C42.55 42.3299 42.3 45.2699 47.19 42.3299C52.07 39.3999 48.62 27.8299 48.62 27.8299C48.62 27.8299 51.41 31.0699 53.47 27.8299C55.55 24.5899 52.24 16.9199 52.24 16.9199H52.22ZM41.83 32.7899C40.78 33.9799 40.04 35.4199 39.72 36.9799C38.52 42.8899 33.3 47.3299 27.03 47.3299C20.76 47.3299 15.53 42.8799 14.34 36.9799C14.02 35.4199 13.3 33.9799 12.23 32.7899C9.78002 30.0399 8.45002 26.2799 8.92002 22.2199C8.93002 22.1599 8.94002 22.0999 8.94002 22.0299C9.44002 18.1899 11.63 14.8299 14.74 12.7099C16.25 11.6699 17.99 10.9399 19.83 10.5899C22.4 10.1099 24.83 10.3999 27.01 11.2099C29.19 10.3999 31.62 10.1099 34.19 10.5899C36.05 10.9399 37.78 11.6799 39.28 12.7099C42.39 14.8199 44.59 18.1899 45.08 22.0299C45.09 22.0899 45.1 22.1499 45.1 22.2199C45.6 26.2899 44.24 30.0299 41.81 32.7899H41.83Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M14.75 12.7101C11.64 14.8201 9.43997 18.1901 8.94997 22.0301C6.96997 21.1901 5.58997 19.2301 5.58997 16.9401C5.58997 13.8801 8.07997 11.3901 11.14 11.3901C12.52 11.3901 13.79 11.9001 14.75 12.7201V12.7101Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M48.4499 16.9201C48.4499 19.2001 47.0599 21.1701 45.0999 22.0101C44.5999 18.1701 42.4099 14.8101 39.2999 12.6901C40.2699 11.8601 41.5299 11.3501 42.9099 11.3501C45.9699 11.3701 48.4499 13.8501 48.4499 16.9201Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M45.13 22.21C45.12 22.15 45.11 22.09 45.11 22.02C44.61 18.18 42.42 14.82 39.31 12.7C37.8 11.66 36.06 10.93 34.22 10.58C31.65 10.1 29.22 10.39 27.04 11.2C24.86 10.39 22.43 10.1 19.86 10.58C18 10.93 16.27 11.67 14.77 12.7C11.66 14.81 9.46003 18.18 8.97003 22.02C8.96003 22.08 8.95003 22.14 8.95003 22.21C8.47003 26.29 9.81003 30.03 12.26 32.78C13.31 33.97 14.05 35.41 14.37 36.97C15.57 42.88 20.79 47.32 27.06 47.32C33.33 47.32 38.55 42.87 39.75 36.97C40.07 35.41 40.8 33.97 41.86 32.78C44.28 30.02 45.63 26.27 45.15 22.21H45.13ZM19.04 28.41C17.96 28.41 17.09 27.54 17.09 26.48C17.09 25.42 17.96 24.53 19.04 24.53C20.12 24.53 20.97 25.4 20.97 26.48C20.96 27.56 20.1 28.41 19.04 28.41ZM30.8 35.53L28.1 38.8C27.83 39.15 27.42 39.31 27.02 39.31C26.62 39.31 26.23 39.15 25.93 38.8L23.24 35.53C22.48 34.61 23.13 33.25 24.32 33.25H29.71C30.9 33.24 31.54 34.61 30.8 35.53V35.53ZM35.01 28.41C33.94 28.41 33.06 27.54 33.06 26.48C33.06 25.42 33.94 24.53 35.01 24.53C36.08 24.53 36.94 25.4 36.94 26.48C36.94 27.56 36.07 28.41 35.01 28.41Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M20.9601 26.48C20.9601 27.55 20.0901 28.41 19.0301 28.41C17.9701 28.41 17.0801 27.54 17.0801 26.48C17.0801 25.42 17.9501 24.53 19.0301 24.53C20.1101 24.54 20.9601 25.41 20.9601 26.48Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M30.8 35.5301L28.1 38.8001C27.83 39.1501 27.42 39.3101 27.02 39.3101C26.62 39.3101 26.23 39.1501 25.93 38.8001L23.24 35.5301C22.48 34.6101 23.13 33.2501 24.32 33.2501H29.71C30.9 33.2401 31.54 34.6101 30.8 35.5301Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M36.9501 26.48C36.9501 27.55 36.0801 28.41 35.0201 28.41C33.9601 28.41 33.0701 27.54 33.0701 26.48C33.0701 25.42 33.9501 24.53 35.0201 24.53C36.0901 24.54 36.9501 25.41 36.9501 26.48Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M30.8 35.5301L28.1 38.8001C27.83 39.1501 27.42 39.3101 27.02 39.3101C26.62 39.3101 26.23 39.1501 25.93 38.8001L23.24 35.5301C22.48 34.6101 23.13 33.2501 24.32 33.2501H29.71C30.9 33.2401 31.54 34.6101 30.8 35.5301Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M27.02 47.3301V39.3101" stroke="white" strokeMiterlimit="10"/>
        <path d="M20.9601 26.48C20.9601 27.55 20.0901 28.41 19.0301 28.41C17.9701 28.41 17.0801 27.54 17.0801 26.48C17.0801 25.42 17.9501 24.53 19.0301 24.53C20.1101 24.54 20.9601 25.41 20.9601 26.48Z" stroke="white" strokeMiterlimit="10"/>
        <path d="M36.9501 26.48C36.9501 27.55 36.0801 28.41 35.0201 28.41C33.9601 28.41 33.0701 27.54 33.0701 26.48C33.0701 25.42 33.9501 24.53 35.0201 24.53C36.0901 24.54 36.9501 25.41 36.9501 26.48Z" stroke="white" strokeMiterlimit="10"/>
        </g>
        <defs>
        <clipPath id="clip0_233_22555">
        <rect width="54.64" height="52.33" fill="white"/>
        </clipPath>
        </defs>
        </svg>

  )
}

export default EHLogotipoWhite