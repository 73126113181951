import React, { FC, useState, useEffect, ChangeEvent  }from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { User} from '../../../types/authTypes';
import { CarrierPrice } from '../../../types/storeTypes';
import { pricesTypes, priceScopeTypes, dialogTypes } from'../../../features/referenceData/appLists';
import { RootState } from '../../../store';
import { newCarrierPrice, setCarrierPriceToEdit, updateCarrierPrice, setEditStore } from '../../../store/actions/storeActions';
import { setShowForm, setUserToUpdate, setDialog } from '../../../store/actions/genericActions';
import { validateLengthInput, validatePositiveNumber } from '../../../features/functions/validatorField';
import { formatCurrencyMxn, calculateMarginPct } from '../../../features/functions/genericFunctions';
import { activeInactive } from '../../../features/referenceData/storeLists';
import { estafetaServiceType } from '../../../features/referenceData/apiReference';
import { roleTypeArray } from '../../../features/referenceData/userLists';
import { ApiConnection, carrierServiceType } from '../../../types/apiTypes';
import SearchUserByName from './SearchUserByName';
import AvatarCustomed from '../../UI/AvatarCustomed';
import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import PublicIcon from '@mui/icons-material/Public';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse';
import Modal from '@mui/material/Modal';

interface UserType {
    user: User,
    client: User
} 

const NewCarrierForm: FC<UserType> = (props) => {
    const {user,client} = props;
    const dispatch = useDispatch();
    const { loading, showForm, userToUpdate, publicPrice, dialogType } = useSelector((state: RootState) => state.generic);
    const {activeApis} = useSelector((state: RootState) => state.apis)
    const carrierPriceToEdit:CarrierPrice|null = useSelector((state: RootState) => state.stores.carrierPriceToEdit)
    const updateCarrierPriceFlag:boolean = carrierPriceToEdit&&carrierPriceToEdit._id?true:false
    const [priceScope, setPriceScope]= useState<string|null>(
        carrierPriceToEdit&&carrierPriceToEdit.priceScope?carrierPriceToEdit.priceScope
        :
        user.role===roleTypeArray[2]?priceScopeTypes[1]
        :
        null
    )
    // const [searchUserByName, setSearchUserByName] = useState<string|null>(null)
    // const [usersFound, setUsersFound] = useState<Array<User>|null>(null) 
    const [userSelected, setUserSelected] = useState<User|null>(userToUpdate?userToUpdate:null)
    const [apiProviderSelected, setApiProviderSelected] = useState<ApiConnection|null| undefined>(activeApis&&activeApis.length>0?activeApis[0]:undefined)
    const [apiProvider, setApiProvider] = useState<string>(carrierPriceToEdit?carrierPriceToEdit.apiProvider:'Estafeta')
    const [carrier, setCarrier] = useState<string>(carrierPriceToEdit?carrierPriceToEdit.carrier:'')
    const [name,setName] = useState<string>(carrierPriceToEdit?carrierPriceToEdit.name:'')
    const [active,setActive] = useState<boolean>(carrierPriceToEdit?carrierPriceToEdit.active:true)
    const [days,setDays] = useState<number|null>(carrierPriceToEdit?carrierPriceToEdit.days:null)
    const [serviceType,setServiceType] = useState<carrierServiceType|null|undefined>(carrierPriceToEdit?carrierPriceToEdit.serviceType:null)
    const [customerId, setCustomerId] = useState<string>(carrierPriceToEdit&&carrierPriceToEdit.customerId?carrierPriceToEdit.customerId:'na')
    const [priceType, setPriceType] = useState<string|null>(carrierPriceToEdit&&carrierPriceToEdit.IsPercentagedPrice?"Porcentaje":"Fijo")
    const [isPorcentaje, setIsPorcentaje] = useState<boolean>(
        apiProviderSelected&&!carrierPriceToEdit?.IsPercentagedPrice?apiProviderSelected.IsPercentagedPrice:false||
        carrierPriceToEdit&&carrierPriceToEdit.IsPercentagedPrice?true:false)
    
    //Validaciones
    const [formValid, setFormValid] = useState<boolean>(false)
    const [subFormatValid_1, setSubFormatValid_1] = useState<boolean>(false)
    const [subFormatValid_2, setSubFormatValid_2] = useState<boolean>(false)
    const [isReadOnly, setIsReadOnly] = React.useState(false);

    //Collapse
    const [open, setOpen] = React.useState(false);

    //Variables
    const [kgStart,setKgStart] = useState<number|null>(null)
    const [kgEnd,setKgEnd] = useState<number|null>(null)
    const [amount,setAmount] = useState<number|null>(null)
    const [cost,setCost] = useState<number|null>(null)
    const [priceExtendedZone,setPriceExtendedZone] = useState<number|null>(null)
    const [costExtendedZone,setCostExtendedZone] = useState<number|null>(null)
    const [percentage, setPercentage] = useState<number|null>(null)

    //Objeto Kilaje
    const [kilajeData, setKilajeData] = useState({
        kgStart:0,
        kgEnd:0,
        amount:0,
        cost:0,
        priceExtendedZone:0,
        costExtendedZone:0,
        percentage:0
    })
    //Arreglo kilaje
    const [kilajeArr, setKilajeArr] = useState(carrierPriceToEdit?carrierPriceToEdit.kilajeArr:new Array<typeof kilajeData>) 
    
    //Invalid
    const [invalidCarrier, setInvalidCarrier] = useState<boolean>(false)
    const [invalidName, setInvalidName] = useState<boolean>(false)
    const [invalidActive, setInvalidActive] = useState<boolean>(false)
    const [invalidDays, setInvalidDays] = useState<boolean>(false)
    const [invalidKgStart, setInvalidKgStart] = useState<boolean>(false)
    const [invalidKgEnd, setInvalidKgEnd] = useState<boolean>(false)
    const [invalidAmount, setInvalidAmount] = useState<boolean>(false)
    const [invalidCost, setInvalidCost] = useState<boolean>(false)
    const [invalidCostExtendedZone, setInvalidCostExtendedZone] = useState<boolean>(false)
    const [serviceTypeList, setServiceTypeList] = useState<Array<carrierServiceType>|null>(null)
    const [invalidPercentage, setInvalidPercentage] = useState<boolean>(false)
    const [invalidkilageObj, setInvalidKilageObj] = useState<boolean>(false)
    
    
    //fields not required for MVP but may be used in the future
    // const [code,setCode] = useState<string>(carrierPriceToEdit?carrierPriceToEdit.code:'')
    // const [zone,setZone] = useState<string|undefined>(carrierPriceToEdit?carrierPriceToEdit.zone:'')
    // const [priceExtraKg,setPriceExtraKg] = useState<number|null|undefined>(carrierPriceToEdit?carrierPriceToEdit.priceExtraKg:null)
    // const [traceable,setTraceable] = useState<boolean>(carrierPriceToEdit?carrierPriceToEdit.traceable:true)
    // const [pickup,setPickup] = useState<boolean>(carrierPriceToEdit?carrierPriceToEdit.pickup:false)
    // const [useQuotedRates,setUseQuotedRates] = useState<boolean>(carrierPriceToEdit?carrierPriceToEdit.useQuotedRates:false)
    // const [customerPct,setCustomerPct] = useState<number|null>(carrierPriceToEdit&&carrierPriceToEdit.customerPct?carrierPriceToEdit.customerPct:null)
    // const [exRate,setExRate] = useState<number|null>(carrierPriceToEdit&&carrierPriceToEdit.exRate?carrierPriceToEdit.exRate:null)

    // const [invalidPriceExtraKg, setInvalidPriceExtraKg] = useState<boolean>(false)
    // const [invalidZone, setInvalidZone] = useState<boolean>(false)
    // const [invalidCode, setInvalidCode] = useState<boolean>(false)
    // const [invalidServiceType, setInvalidServiceType] = useState<boolean>(false)

    useEffect(() => {
        if(userToUpdate){
            setUserSelected(userToUpdate)
            setCustomerId(userToUpdate.uid)
        }
        if(carrierPriceToEdit){
            let apiTMP = activeApis?.filter((api)=>{
                return api.provider == carrierPriceToEdit.apiProvider;
            }) 
            if(apiTMP&&apiTMP.length>0){
                setApiProviderSelected(apiTMP[0])
                setApiProvider(apiTMP[0].provider)
            }
        }
        if(apiProviderSelected){
            if(apiProviderSelected?.production){
                setServiceTypeList(apiProviderSelected?.prodCredentials.serviceList);
            }else{
                setServiceTypeList(apiProviderSelected?.testCredentials.serviceList);
            }
        }
        //publicPrice
        if(publicPrice == true){
            setPriceScope('Global')
        }
        if(publicPrice == false){
            setPriceScope('Cliente')
        }

        //Guardar
        if(
            subFormatValid_1 && kilajeArr.length!=0
        ){
            setFormValid(true)
        }else {
            setFormValid(false)
        }

        //Agregar kilaje
        if(
            (
                carrier!==null&&
                name!==null&&
                active!==null&&
                days!==null&&
                isPorcentaje!==null&&
                serviceType!=null
            )
            &&
            (
                !invalidCarrier&&
                !invalidName&&
                !invalidActive&&
                !invalidDays 
            )
            ||
            (carrierPriceToEdit)
        ){
            setSubFormatValid_1(true)
        }else {
            setSubFormatValid_1(false)
        }

        //Agregar
        if(
            (
                kgStart!==null&&
                kgEnd!==null&&
                (isPorcentaje==false && amount!==null && cost!==null||
                isPorcentaje==true && percentage!==null)
            )
            &&
            (
                !invalidKgStart&&
                !invalidKgEnd&&
                !invalidAmount&&
                !invalidCost&&
                !invalidPercentage
            )
        ){
            setSubFormatValid_2(true)
        }else {
            setSubFormatValid_2(false)
        }

    }, [
        carrier,
        name,
        active,
        days,
        kgStart,
        kgEnd,
        amount,
        cost,
        percentage,
        invalidCarrier,
        invalidName,
        invalidActive,
        invalidDays,
        invalidKgStart,
        invalidKgEnd,
        invalidAmount,
        invalidCost,
        invalidCostExtendedZone,
        apiProviderSelected,
        userToUpdate,
        invalidPercentage,
        kilajeArr
    ])

    const handleApiProviderSelected = (event: SelectChangeEvent) => {
        let selectedApiProviderId = event.target.value as string
        const selectedApiProviderObj = activeApis && activeApis.find((carrier:ApiConnection) => {
            return carrier._id === selectedApiProviderId
        })
        if(selectedApiProviderObj){
            setApiProviderSelected(selectedApiProviderObj)
            setApiProvider(selectedApiProviderObj.provider)
            cleanerVar()
            if(!carrierPriceToEdit){ 
                setKilajeArr([]);
            }
        }
    }

    const handleSetServiceType = (e:SelectChangeEvent) =>{
        const svctmp = serviceTypeList?.find((svc:carrierServiceType)=>{
            return e.target.value == svc.val;
        })
        setServiceType(svctmp)
    }

    
    const handleSetCarrier = (e:any) => {
        const value: string = e as string
        setCarrier(value)
        setInvalidCarrier(validateLengthInput(50,value))
    }
    
    const handleSetName = (e:any) => {
        const value: string = e as string
        setName(value)
        setInvalidName(validateLengthInput(50,value))
    }

    const handleSetActive = (e:any) => {
        e === activeInactive[0]?setActive(true):setActive(false)
    }

    const handleSetDays = (e:any) => {
        setDays(parseFloat(e))
        setInvalidDays(validatePositiveNumber(e))
    }

    const handleSetKgStart = (value:any) => {
        //Comprobacion del valor
        setInvalidKgStart(validatePositiveNumber(value));
        //Agregar el valor
        setKilajeData({... kilajeData,["kgStart"]:parseFloat(value)})
        //mostrar valor
        setKgStart(value)
    }
    const handleSetKgEnd = (value:any) => {
        //Comprobacion del valor
        setInvalidKgEnd(validatePositiveNumber(value))
        //Agregar el valor
        setKilajeData({... kilajeData,["kgEnd"]:parseFloat(value)})
        //mostrar valor
        setKgEnd(value)
    }
    const handleSetAmount = (value:any) => {
        //Comprobacion del valor
        setInvalidAmount(validatePositiveNumber(value))
        //Agregar el valor
        setKilajeData({... kilajeData,["amount"]:parseFloat(value)})
        //mostrar valor
        setAmount(value)
    }
    const handleSetCost = (value:any) => {
        //Comprobacion del valor
        setInvalidCost(validatePositiveNumber(value))
        //Agregar el valor
        setKilajeData({... kilajeData,["cost"]:parseFloat(value)})
        //mostrar valor
        setCost(value)
    }
    const handleSetCostExtendedZone = (value:any) => {
        //Comprobacion del valor
        setInvalidCostExtendedZone(validatePositiveNumber(value))
        //Agregar el valor
        setKilajeData({... kilajeData,["costExtendedZone"]:parseFloat(value)})
        //mostrar valor
        setCostExtendedZone(value)
    }
    const handleSetPriceExtendedZone = (value:any) => {
        //Agregar el valor
        setKilajeData({... kilajeData,["priceExtendedZone"]:parseFloat(value)})
        //mostrar valor
        setPriceExtendedZone(value)
    }
    const handleSetPercentage = (value:any) => {
        //Comprobacion del valor
        setInvalidPercentage(validatePositiveNumber(value))
        //Agregar el valor
        setKilajeData({... kilajeData,["percentage"]:parseFloat(value)})
        //mostrar valor
        setPercentage(value)
    }

    //Guardar
    const saveCarrierPriceHandler = () => {
        dispatch<any>(setDialog(false))
        if( user && formValid){
            const priceForCustomerObj = carrierPriceToEdit && carrierPriceToEdit.priceForCustomer && carrierPriceToEdit.priceScope === priceScopeTypes[1] ?
            {
                uid: carrierPriceToEdit.priceForCustomer.uid,
                fullName: carrierPriceToEdit.priceForCustomer.fullName,
                email: carrierPriceToEdit.priceForCustomer.email,
                userAvatar: carrierPriceToEdit.priceForCustomer.userAvatar? carrierPriceToEdit.priceForCustomer.userAvatar : null
            } :
            !carrierPriceToEdit && client && publicPrice == false? 
            {
                uid: client.uid,
                fullName: client.fullName, 
                email: client.email,
                userAvatar: client.userAvatar? client.userAvatar : null
            } : {
                uid: 'N/A',
                fullName: 'N/A',
                email: 'N/A',
                userAvatar: 'N/A'
            }
            
            let carrierPriceToSave: CarrierPrice = {
                _id: carrierPriceToEdit&&carrierPriceToEdit._id?carrierPriceToEdit._id:'...',
                apiProvider,
                carrier: carrier.toLowerCase(),
                name,
                active,
                days:days?days:1,
                priceScope: priceScope?priceScope:'na',
                serviceType:serviceType?serviceType:{name : "N/A", val : "N/A"},
                customerId: client.uid?customerId:customerId,
                priceForCustomer: priceForCustomerObj,
                kgStart:0,
                kgEnd:0,
                amount:0,
                cost:0,
                priceExtendedZone:0,
                costExtendedZone:0,
                percentage:0,
                IsPercentagedPrice:isPorcentaje,
                kilajeArr:kilajeArr,
                dynamicPrice: apiProviderSelected?.dynamicPrice!=undefined?apiProviderSelected.dynamicPrice:false
            }
            if(!updateCarrierPriceFlag){
                dispatch<any>(newCarrierPrice(carrierPriceToSave, user.uid))
                dispatch<any>(setCarrierPriceToEdit())
                toggleFormHandler()
            }
            if(updateCarrierPriceFlag){
                dispatch<any>(updateCarrierPrice(carrierPriceToSave, user.uid))
                dispatch<any>(setCarrierPriceToEdit())
                toggleFormHandler()
            }
        }
    }
      const toggleFormHandler = () => {
        const currentState = showForm
        const saveUserInStore: boolean = false;
        dispatch<any>(setUserToUpdate(saveUserInStore))
        if(showForm){
            dispatch<any>(setEditStore(undefined))
            dispatch<any>(setShowForm(!currentState))
        }
        if(!showForm){
            dispatch<any>(setShowForm(currentState))
        }
    }

    //Tipo de precio
    /*let isPorcentaje = false;
    if(apiProviderSelected?.IsPercentagedPrice == false||apiProviderSelected?.IsPercentagedPrice == undefined){ isPorcentaje = false;}
    if(apiProviderSelected?.IsPercentagedPrice == true){ isPorcentaje = true;}*/

    const priceTypeHandler = (e:any) => {
        cleanerVar();
        if(e == "Fijo"){
            setIsPorcentaje(false)
        }
        if(e == "Porcentaje"){
            setIsPorcentaje(true)
        }
        setPriceType(e);
        setKilajeArr([]);
    }

    const cleanerVar = () =>{
        setKilajeData({
            kgStart:0,
            kgEnd:0,
            amount:0,
            cost:0,
            priceExtendedZone:0,
            costExtendedZone:0,
            percentage:0
        });
        setKgStart(null)
        setKgEnd(null)
        setAmount(null)
        setCost(null)
        setPercentage(null)
        setCostExtendedZone(null)
        setPriceExtendedZone(null)
    }

    //Boton mostrar menu kilaje
    const handleClick = () => {
        setOpen(!open);
        setIsReadOnly(!isReadOnly);
    };    

    //Boton gregar Data al Kilaje
    const addKilajeButton = (e:any)=>{
        //Agregado
        setKilajeArr([... kilajeArr,kilajeData])
        kilajeArr.push(kilajeData)
        //Limpieza
        cleanerVar()
    }
    
    //Editar 
    const [edit, setEdit] = React.useState(false);
    const [editKilajePos, setEditKilajePos] = React.useState(0);

    const editKilajeButton = (value:any)=>{
        setEditKilajePos(value);
        cleanerVar();
        setEdit(true);
    }

    const SaveEditKilajeButton = (value:any)=>{
        kilajeArr[editKilajePos] = kilajeData;
        cleanerVar()
        setEdit(false);
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    return (
        <Box sx={{width:'100%'}}>
            <CardContent sx={{mt:0}}>
                { priceScope === priceScopeTypes[0] 
                    || (priceScope === priceScopeTypes[1] && client) 
                    || carrierPriceToEdit
                    ?
                    <Grid container spacing={2}>
                        {priceScope === priceScopeTypes[1] && client && dialogType == dialogTypes[6]?
                            <Grid item sm={12}>
                                <Typography variant="subtitle1"><b>Creando precio para</b></Typography>
                                <Box sx={{mb:2}}>
                                <ListItem
                                    alignItems="center"
                                    sx={{
                                        borderBottom:  1.25, 
                                        borderBottomColor:'grey.100',
                                        borderTopLeftRadius:5,
                                    }} 
                                    >
                                        <ListItemAvatar>
                                            <AvatarCustomed 
                                            userName={client.fullName} 
                                            size={42} 
                                            userPhoto={client.userAvatar}  
                                            fontWeight={500}
                                            fontSize={14}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                        sx={{ml:1.5}}
                                        primary={
                                            <Typography sx={{fontWeight:500}} variant="body1">
                                                {client.fullName}
                                            </Typography>
                                        }
                                        secondary={
                                            <span>
                                            <Typography sx={{fontWeight:400, color:'grey.700'}} variant="body2">
                                                {client.email}
                                            </Typography>
                                            <Typography sx={{fontWeight:400, color:'grey.700'}} variant="caption">
                                                Rol: {client.role}
                                            </Typography>
                                            </span>
                                        }
                                        />
                                    </ListItem>
                                </Box>
                            </Grid>
                            :null
                        }
                        {carrierPriceToEdit && carrierPriceToEdit.priceScope === priceScopeTypes[1] && carrierPriceToEdit.priceForCustomer?.fullName && dialogType != dialogTypes[6] ?
                             <Grid item sm={12}>
                                <Typography variant="subtitle1">
                                    Editando precio de cliente:
                                    <Typography sx={{ml:.75, fontWeight:500}} variant="subtitle1" component='span' color='secondary'>
                                        {carrierPriceToEdit.priceForCustomer?.fullName}
                                    </Typography>
                                </Typography>
                                <Typography variant="subtitle2" sx={{mb:2}}>{carrierPriceToEdit.priceForCustomer?.email}</Typography>
                             </Grid>
                            :null
                        }
                        <Grid item sm={12} md={3}>
                            <FormControl
                                sx={{bgcolor:'white', width:{xs:'100%'}}}
                                size={'small'}
                                color='secondary'
                                >
                                <InputLabel id="select-store-label">Proveedor de guía</InputLabel>
                                <Select
                                    labelId="select-store-label"
                                    id="select-store"
                                    value={
                                        apiProviderSelected ? 
                                        apiProviderSelected._id :
                                        'Proveedor de guía'
                                    }
                                    label="Proveedor de guía"
                                    type='string'
                                    onChange={(e) => handleApiProviderSelected(e)}
                                    disabled={isReadOnly||carrierPriceToEdit?true:false}
                                    >
                                    <MenuItem selected disabled value="Selecciona un grupo">
                                        Proveedor de guía
                                    </MenuItem>
                                    {activeApis && activeApis.map((carrier:ApiConnection, index:number) => {
                                        return(
                                            <MenuItem
                                                key={index}
                                                sx={{textTransform:'uppercase'}}
                                                value={carrier._id}>
                                                    {carrierPriceToEdit?carrierPriceToEdit.apiProvider:carrier.provider}
                                            </MenuItem> 
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} md={3}>
                            <FormControl
                                sx={{bgcolor:'white', width:{xs:'100%'}}}
                                size={'small'}
                                color='secondary'
                                >
                                <InputLabel id="select-store-label">Tipo de servicio</InputLabel>
                                <Select
                                    labelId="select-store-label"
                                    id="select-store"
                                    value={
                                        serviceType ? 
                                        serviceType.val :"Selecciona un grupo"
                                    }
                                    label="Tipo de servicio"
                                    type='string'
                                    onChange={(e) => handleSetServiceType(e)}
                                    disabled={isReadOnly}
                                    >
                                    <MenuItem selected disabled value="Selecciona un grupo">
                                        Tipo de servicio
                                    </MenuItem>
                                    {serviceTypeList && serviceTypeList.map((service:carrierServiceType, index:number) => {
                                        return( //needs to be dynamic when more providers are added, this one is for estafeta
                                            <MenuItem
                                                    key={index}
                                                    value={service.val}>
                                                {service.name}
                                            </MenuItem> 
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} md={3}>
                            <TextField
                                required
                                color="secondary"
                                value={name}
                                fullWidth
                                size='small'
                                id="outlined-required"
                                onChange={(e:any) => handleSetName(e.currentTarget.value)}
                                placeholder='Envios Rappi Terrestre 11-15Kg'
                                label="Nombre"
                                type="text"
                                error={invalidName}
                                helperText={
                                    invalidName?
                                        `Intenta una nombre más cort. ${name.length}/50`
                                        :null
                                }
                                disabled={isReadOnly}
                            />
                        </Grid>
                        <Grid item sm={12} md={3}>
                            <TextField
                                required
                                color="secondary"
                                value={carrier}
                                fullWidth
                                size='small'
                                id="outlined-required"
                                onChange={(e:any) => handleSetCarrier(e.currentTarget.value)}
                                placeholder='Estafeta'
                                label="Carrier"
                                type="text"
                                error={invalidCarrier}
                                helperText={
                                    invalidCarrier?
                                        `Intenta una nombre más cort. ${carrier.length}/50`
                                        :null
                                }
                                disabled={isReadOnly}
                            />
                        </Grid>
                        <Grid item sm={12} md={3}>
                            <FormControl
                                sx={{bgcolor:'white', width:{xs:'100%', md:'100%'}}}
                                size={'small'}
                                color='secondary'
                                >
                                <InputLabel id="select-store-label">Status</InputLabel>
                                <Select
                                    labelId="select-store-label"
                                    id="select-store"
                                    value={active ? activeInactive[0] : activeInactive[1]}
                                    label="Status"
                                    type='string'
                                    onChange={(e) => handleSetActive(e.target.value)}
                                    disabled={isReadOnly}
                                    >
                                    {activeInactive && activeInactive.map((status:string, index:number) => {
                                        return(
                                            <MenuItem
                                                key={index} 
                                                value={status}>
                                                    {status}
                                            </MenuItem> 
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} md={3}>
                            <TextField
                                required
                                color="secondary"
                                value={days?days:''}
                                fullWidth
                                size='small'
                                id="outlined-required"
                                onChange={(e:any) => handleSetDays(e.currentTarget.value)}
                                label="Día(s)"
                                type="number"
                                error={invalidDays}
                                helperText={
                                    invalidDays?
                                    'Ingresa un número positivo'
                                    :null
                                }
                                disabled={isReadOnly}
                            />
                        </Grid>
                        <Grid item sm={12} md={3}>
                            {
                                apiProviderSelected?.IsPercentagedPrice == false||apiProviderSelected?.IsPercentagedPrice == undefined||
                                carrierPriceToEdit?
                                <TextField
                                color="secondary"
                                fullWidth
                                size='small'
                                id="outlined-disabled"
                                label="Tipo de Precio"
                                value={priceType}
                                disabled={isReadOnly}
                                />:
                                <FormControl 
                                    sx={{bgcolor:'white', width:{xs:'100%'}}}
                                    size={'small'}
                                    color='secondary'
                                    >
                                    <InputLabel id="demo-simple-select-label">Tipo de Precio</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={priceType}
                                        label="Tipo de Precio"
                                        type='string'
                                        //disabled={kilajeArr.length>0?true:isReadOnly} <--No permite cambiar despues de agregar 1 kilaje
                                        disabled={isReadOnly}
                                        onChange={(e:any) => priceTypeHandler(e.target.value)}
                                        >
                                        {pricesTypes.map((priceType) => (
                                            <MenuItem
                                            key={priceType}
                                            value={priceType}
                                            >
                                            {priceType}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item sm={12} md={3}>
                            <Box sx={{width:'90%', px:1}}>
                                <LoadingButton
                                    color="secondary"
                                    disableElevation
                                    fullWidth
                                    variant="contained"
                                    disabled={!subFormatValid_1}
                                    onClick={handleClick}
                                >
                                    Agregar kilaje ...
                                </LoadingButton>
                            </Box>
                        </Grid>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                        {kilajeArr.map((kljObj,i)=>{
                            return(
                                <CardContent sx={{mt:0}} key={"kilaje"+i}>
                                { 
                                    i < 1?
                                    <Typography variant="h6" sx={{fontWeight:600, my:1.5, mr:2.5}}>
                                    Kilaje agregado:
                                    </Typography>:null 
                                }
                                <Typography variant="subtitle1" sx={{fontWeight:600, my:1.5, mr:2.5}}>
                                kilaje #{i+1}
                                </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} md={2}>
                                            <TextField
                                                required
                                                id="outlined-required"
                                                name='kgStart'
                                                color="secondary"
                                                value={kljObj.kgStart?kljObj.kgStart:''}
                                                fullWidth
                                                size='small'
                                                placeholder='11'
                                                label="Empieza (Kg)"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={2}>
                                            <TextField
                                            required
                                            id="outlined-required"
                                                name='kgEnd'
                                                color="secondary"
                                                value={kljObj.kgEnd?kljObj.kgEnd:''}
                                                fullWidth
                                                size='small'
                                                placeholder='15'
                                                label="Termina (Kg)"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={2}>
                                            <TextField
                                            required
                                            id="outlined-required"
                                                name='amount'
                                                disabled={isPorcentaje}
                                                color="secondary"
                                                value={kljObj.amount?kljObj.amount:''}
                                                fullWidth
                                                size='small'
                                                placeholder='250'
                                                label="Precio (Solo números)"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={2}>
                                            <TextField
                                            required
                                            id="outlined-required"
                                                name='cost'
                                                disabled={isPorcentaje}
                                                color="secondary"
                                                value={kljObj.cost?kljObj.cost:''}
                                                fullWidth
                                                size='small'
                                                placeholder='250'
                                                label="Costo (Solo números)"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={2}>
                                            <TextField
                                            required
                                            id="outlined-required"
                                                name='priceExtendedZone'
                                                disabled={isPorcentaje}
                                                color="secondary"
                                                value={kljObj.priceExtendedZone?kljObj.priceExtendedZone:''}
                                                fullWidth
                                                size='small'
                                                label="Precio Adtl Zona Extendida (solo números)"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={2}>
                                            <TextField
                                            required
                                            id="outlined-required"
                                                name='costExtendedZone'
                                                disabled={isPorcentaje}
                                                color="secondary"
                                                value={kljObj.costExtendedZone?kljObj.costExtendedZone:''}
                                                fullWidth
                                                size='small'
                                                label="Costo Adtl Zona Extendida (solo números)"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={2}>
                                            <TextField
                                            required
                                            id="outlined-required"
                                                name='percentage'
                                                color="secondary"
                                                value={kljObj.percentage?kljObj.percentage:''}
                                                fullWidth
                                                size='small'
                                                placeholder='250'
                                                label="Porcentaje (Solo números)"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            /> 
                                        </Grid>
                                        <Grid item sm={12} md={2} className="d-flex-col-center-centered">
                                            <Box sx={{width:'90%', px:1}}>
                                                <LoadingButton
                                                    color="secondary"
                                                    disableElevation
                                                    fullWidth
                                                    variant="contained"
                                                    //disabled={!subFormatValid_2}
                                                    onClick={()=>editKilajeButton(i)}
                                                >Editar</LoadingButton>
                                                <Modal
                                                    open={edit}
                                                    onClose={SaveEditKilajeButton}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={style}>
                                                    <Typography variant="h6" sx={{fontWeight:600, my:1.5, mr:2.5}}>
                                                        Editar kilaje:
                                                    </Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid item sm={12} md={2}>
                                                            <TextField
                                                                name='kgStart'
                                                                required
                                                                color="secondary"
                                                                value={kgStart?kgStart:''}
                                                                //value = {kilajeData.kgStart?kilajeData.kgStart:''}
                                                                fullWidth
                                                                size='small'
                                                                id="outlined-required"
                                                                onChange={(e:any) => handleSetKgStart(e.currentTarget.value)}
                                                                placeholder='11'
                                                                label="Empieza (Kg)"
                                                                type="number"
                                                                error={invalidKgStart}
                                                                helperText={
                                                                    invalidKgStart?
                                                                    'Ingresa un número positivo'
                                                                    :null
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item sm={12} md={2}>
                                                            <TextField
                                                                name='kgEnd'
                                                                required
                                                                color="secondary"
                                                                value={kgEnd?kgEnd:''}
                                                                //value={kilajeData.kgEnd?kilajeData.kgEnd:''}
                                                                fullWidth
                                                                size='small'
                                                                id="outlined-required"
                                                                onChange={(e:any) => handleSetKgEnd(e.currentTarget.value)}
                                                                placeholder='15'
                                                                label="Termina (Kg)"
                                                                type="number"
                                                                error={invalidKgEnd}
                                                                helperText={
                                                                    invalidKgEnd?
                                                                    'Ingresa un número positivo y verifica que el Kg de Inicio sea menor'
                                                                    :null
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item sm={12} md={2}>
                                                            <TextField
                                                                required
                                                                name='amount'
                                                                disabled={isPorcentaje}
                                                                color="secondary"
                                                                value={amount?amount:''}
                                                                //value={kilajeData.amount?kilajeData.amount:''}
                                                                fullWidth
                                                                size='small'
                                                                id="outlined-required"
                                                                onChange={(e:any) => handleSetAmount(e.currentTarget.value)}
                                                                placeholder='250'
                                                                label="Precio (Solo números)"
                                                                type="number"
                                                                error={invalidAmount}
                                                                helperText={
                                                                    invalidAmount?
                                                                    'Ingresa un número positivo'
                                                                    :null
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item sm={12} md={2}>
                                                            <TextField
                                                                required
                                                                name='cost'
                                                                disabled={isPorcentaje}
                                                                color="secondary"
                                                                value={cost?cost:''}
                                                                //value={kilajeData.cost?kilajeData.cost:''}
                                                                fullWidth
                                                                size='small'
                                                                id="outlined-required"
                                                                onChange={(e:any) => handleSetCost(e.currentTarget.value)}
                                                                placeholder='250'
                                                                label="Costo (Solo números)"
                                                                type="number"
                                                                error={invalidCost}
                                                                helperText={
                                                                    invalidCost?
                                                                    'Ingresa un número positivo'
                                                                    :null
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item sm={12} md={2}>
                                                            <TextField
                                                                required
                                                                name='priceExtendedZone'
                                                                disabled={isPorcentaje}
                                                                color="secondary"
                                                                value={priceExtendedZone?priceExtendedZone:''}
                                                                //value={kilajeData.priceExtendedZone?kilajeData.priceExtendedZone:''}
                                                                fullWidth
                                                                size='small'
                                                                id="outlined-required"
                                                                onChange={(e:any) => handleSetPriceExtendedZone(e.currentTarget.value)}
                                                                label="Precio Adtl Zona Extendida (solo números)"
                                                                type="number"
                                                                // error={invalidPriceExtendedZone}
                                                                // helperText={
                                                                //     invalidPriceExtendedZone?
                                                                //     'Ingresa un número positivo'
                                                                //     :null
                                                                // }
                                                            />
                                                        </Grid>
                                                        <Grid item sm={12} md={2}>
                                                            <TextField
                                                                required
                                                                name='costExtendedZone'
                                                                disabled={isPorcentaje}
                                                                color="secondary"
                                                                value={costExtendedZone?costExtendedZone:''}
                                                                //value={kilajeData.costExtendedZone?kilajeData.costExtendedZone:''}
                                                                fullWidth
                                                                size='small'
                                                                id="outlined-required"
                                                                onChange={(e:any) => handleSetCostExtendedZone(e.currentTarget.value)}
                                                                label="Costo Adtl Zona Extendida (solo números)"
                                                                type="number"
                                                                // error={invalidPriceExtendedZone}
                                                                // helperText={
                                                                //     invalidPriceExtendedZone?
                                                                //     'Ingresa un número positivo'
                                                                //     :null
                                                                // }
                                                            />
                                                        </Grid>
                                                        <Grid item sm={12} md={2}>
                                                            <TextField
                                                                required
                                                                name='percentage'
                                                                disabled={!isPorcentaje}
                                                                color="secondary"
                                                                value={percentage?percentage:''}
                                                                //value={kilajeData.percentage?kilajeData.percentage:''}
                                                                fullWidth
                                                                size='small'
                                                                id="outlined-required"
                                                                onChange={(e:any) => handleSetPercentage(e.currentTarget.value)}
                                                                placeholder='250'
                                                                label="Porcentaje (Solo números)"
                                                                type="number"
                                                                error={invalidPercentage}
                                                                helperText={
                                                                    invalidPercentage?
                                                                    'Ingresa un número posivito menor o igual a 100'
                                                                    :null
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item sm={12} md={2} className="d-flex-col-center-centered">
                                                            <Box sx={{width:'90%', px:1}}>
                                                                <LoadingButton
                                                                    color="secondary"
                                                                    disableElevation
                                                                    fullWidth
                                                                    variant="contained"
                                                                    disabled={!subFormatValid_2}
                                                                    onClick={()=>SaveEditKilajeButton(editKilajePos)}
                                                                >
                                                                    Guardar
                                                                </LoadingButton>
                                                            </Box>
                                                        </Grid> 
                                                    </Grid>
                                                    </Box>
                                                </Modal>
                                            </Box>
                                        </Grid> 
                                    </Grid>
                                </CardContent>            
                            )
                        })}
                        <CardContent sx={{mt:0}}>
                        <Typography variant="h6" sx={{fontWeight:600, my:1.5, mr:1.5}}>
                        Agregar nuevo kilaje:
                        </Typography>
                            <Grid container spacing={2}>
                                <Grid item sm={12} md={2}>
                                    <TextField
                                        name='kgStart'
                                        required
                                        color="secondary"
                                        value={kgStart?kgStart:''}
                                        //value = {kilajeData.kgStart?kilajeData.kgStart:''}
                                        fullWidth
                                        size='small'
                                        id="outlined-required"
                                        onChange={(e:any) => handleSetKgStart(e.currentTarget.value)}
                                        placeholder='11'
                                        label="Empieza (Kg)"
                                        type="number"
                                        error={invalidKgStart}
                                        helperText={
                                            invalidKgStart?
                                            'Ingresa un número positivo'
                                            :null
                                        }
                                    />
                                </Grid>
                                <Grid item sm={12} md={2}>
                                    <TextField
                                        name='kgEnd'
                                        required
                                        color="secondary"
                                        value={kgEnd?kgEnd:''}
                                        //value={kilajeData.kgEnd?kilajeData.kgEnd:''}
                                        fullWidth
                                        size='small'
                                        id="outlined-required"
                                        onChange={(e:any) => handleSetKgEnd(e.currentTarget.value)}
                                        placeholder='15'
                                        label="Termina (Kg)"
                                        type="number"
                                        error={invalidKgEnd}
                                        helperText={
                                            invalidKgEnd?
                                            'Ingresa un número positivo y verifica que el Kg de Inicio sea menor'
                                            :null
                                        }
                                    />
                                </Grid>
                                <Grid item sm={12} md={2}>
                                    <TextField
                                        required
                                        name='amount'
                                        disabled={isPorcentaje}
                                        color="secondary"
                                        value={amount?amount:''}
                                        //value={kilajeData.amount?kilajeData.amount:''}
                                        fullWidth
                                        size='small'
                                        id="outlined-required"
                                        onChange={(e:any) => handleSetAmount(e.currentTarget.value)}
                                        placeholder='250'
                                        label="Precio (Solo números)"
                                        type="number"
                                        error={invalidAmount}
                                        helperText={
                                            invalidAmount?
                                            'Ingresa un número positivo'
                                            :null
                                        }
                                    />
                                </Grid>
                                <Grid item sm={12} md={2}>
                                    <TextField
                                        required
                                        name='cost'
                                        disabled={isPorcentaje}
                                        color="secondary"
                                        value={cost?cost:''}
                                        //value={kilajeData.cost?kilajeData.cost:''}
                                        fullWidth
                                        size='small'
                                        id="outlined-required"
                                        onChange={(e:any) => handleSetCost(e.currentTarget.value)}
                                        placeholder='250'
                                        label="Costo (Solo números)"
                                        type="number"
                                        error={invalidCost}
                                        helperText={
                                            invalidCost?
                                            'Ingresa un número positivo'
                                            :null
                                        }
                                    />
                                </Grid>
                                <Grid item sm={12} md={2}>
                                    <TextField
                                        required
                                        name='priceExtendedZone'
                                        disabled={isPorcentaje}
                                        color="secondary"
                                        value={priceExtendedZone?priceExtendedZone:''}
                                        //value={kilajeData.priceExtendedZone?kilajeData.priceExtendedZone:''}
                                        fullWidth
                                        size='small'
                                        id="outlined-required"
                                        onChange={(e:any) => handleSetPriceExtendedZone(e.currentTarget.value)}
                                        label="Precio Adtl Zona Extendida (solo números)"
                                        type="number"
                                        // error={invalidPriceExtendedZone}
                                        // helperText={
                                        //     invalidPriceExtendedZone?
                                        //     'Ingresa un número positivo'
                                        //     :null
                                        // }
                                    />
                                </Grid>
                                <Grid item sm={12} md={2}>
                                    <TextField
                                        required
                                        name='costExtendedZone'
                                        disabled={isPorcentaje}
                                        color="secondary"
                                        value={costExtendedZone?costExtendedZone:''}
                                        //value={kilajeData.costExtendedZone?kilajeData.costExtendedZone:''}
                                        fullWidth
                                        size='small'
                                        id="outlined-required"
                                        onChange={(e:any) => handleSetCostExtendedZone(e.currentTarget.value)}
                                        label="Costo Adtl Zona Extendida (solo números)"
                                        type="number"
                                        // error={invalidPriceExtendedZone}
                                        // helperText={
                                        //     invalidPriceExtendedZone?
                                        //     'Ingresa un número positivo'
                                        //     :null
                                        // }
                                    />
                                </Grid>
                                <Grid item sm={12} md={2}>
                                    <TextField
                                        required
                                        name='percentage'
                                        disabled={!isPorcentaje}
                                        color="secondary"
                                        value={percentage?percentage:''}
                                        //value={kilajeData.percentage?kilajeData.percentage:''}
                                        fullWidth
                                        size='small'
                                        id="outlined-required"
                                        onChange={(e:any) => handleSetPercentage(e.currentTarget.value)}
                                        placeholder='250'
                                        label="Porcentaje (Solo números)"
                                        type="number"
                                        error={invalidPercentage}
                                        helperText={
                                            invalidPercentage?
                                            'Ingresa un número posivito menor o igual a 100'
                                            :null
                                        }
                                    /> 
                                </Grid>
                                <Grid item sm={12} md={2} className="d-flex-col-center-centered">
                                    <Box sx={{width:'90%', px:1}}>
                                        <LoadingButton
                                            color="secondary"
                                            disableElevation
                                            fullWidth
                                            variant="contained"
                                            disabled={!subFormatValid_2}
                                            onClick={addKilajeButton}
                                        >
                                            Agregar
                                        </LoadingButton>
                                    </Box>
                                </Grid> 
                            </Grid>
                        </CardContent>
                        </Collapse>
                        <Grid item sm={12} md={3}>
                        <Box sx={{width:'90%', px:1}}>
                            <LoadingButton
                                color="secondary"
                                disableElevation
                                fullWidth
                                variant="contained"
                                disabled={!formValid}
                                onClick={saveCarrierPriceHandler}
                            >
                                Guardar
                            </LoadingButton> 
                        </Box>
                        </Grid>
                    </Grid>
                    :null
                }
            </CardContent>
        </Box>
    )
}
const MemoizedNewCarrierForm = React.memo(NewCarrierForm)

export default MemoizedNewCarrierForm