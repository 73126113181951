import React from 'react'

import PublicHeader from './PublicHeader';
import PublicFooter from './PublicFooter';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const AvisoPrivacidadInt = () => {
    return (
        <Container 
        maxWidth={false}
        disableGutters
        >
            <PublicHeader color="black" includeHomeBttn={true}/> 
            <Grid container>
                <Grid item xs={12} sx={{m:12}}>
                    <Box sx={{width:'100%'}}>
                        <Typography align="center" variant="h5" sx={{fontWeight:600, mb:5}}>
                        AVISO DE PRIVACIDAD INTEGRAL
                        </Typography>
                        <Typography variant="body1">
                        Con base a la normativa fijada por la “Ley Federal de Protección de Datos
        Personales en Posesión de Particulares” (LEY) y en el “Reglamento a la Ley
        Federal de Protección de Datos Personales en Posesión de Particulares”
        (REGLAMENTO), así como en las especificaciones y parámetros previstos en los
        lineamientos del Aviso de Privacidad publicados en el Diario Oficial de la
        Federación; JAIR LEÓN FUERTES, conocido social y comercialmente e identificado
        para efectos del presente Aviso de Privacidad como JAIR LEÓN FUERTES, pone a
        disposición del público el Aviso de Privacidad Integral para la Protección de los
        Datos Personales (Aviso de Privacidad).
        </Typography>
        <Typography variant="body1">
        ▪ Responsable de los Datos Personales
        El responsable de la recepción, manejo y salvaguarda de la información será JAIR
        LEÓN FUERTES, información que será utilizada con base al tratamiento y
        finalidades que se prevén a lo largo del presente Aviso de Privacidad.
        La obligación del cuidado de la información podrá ser compartida, cuando se prevea
        en la negociación con terceros y se tenga debidamente regulada mediante
        instrumento privado que así lo ampare, dándolo a conocer al Titular de los Datos
        Personales, quien deberá expresar su consentimiento a través del presente Aviso,
        como se verá en el apartado “Transferencia de datos”.
        </Typography>
        <Typography variant="body1">
        ▪ Datos Personales que se recaban
        JAIR LEÓN FUERTES solicitará de sus clientes, beneficiarios y/o usuarios de los
        servicios, proveedores y prestadores de servicios los siguientes datos:
        I. Nombre completo.
        II. Domicilio.
        III. Número teléfonico (oficina, celular, casa).
        IV. Correo electrónico.
        Asímismo se específica que no se solicitarán ni recabaran de los clientes datos
        personales sensibles.
        </Typography>
        <Typography variant="body1">
        ▪ Datos Personales que se recaban exclusivamente de los
        colaboradores/trabajadores.
        JAIR LEÓN FUERTES solicitará y dará uso respecto de los siguientes datos de sus
        colaboradores:
        I. Nombre completo de la persona física.
        II. Domicilio
        III. Código postal.
        IV. Datos de guía de prepago.

        V. Número teléfonico (oficina, celular, casa).
        VI. Correo electrónico.

        De igual forma se considera como obtención de datos personales los relacionados
        con las cuentas bancarias que sean utilizadas para realizar el pago de los servicios
        de venta y generación de guías prepagadas, los cuales comprenden:
        I. Nombre del tarjetahabiente.
        II. Número de cuenta.
        III. Institución bancaria.
        Toda aquella información que sea facilitada por colaboradores respecto de Datos
        Personales de Terceros como elementos de referencia u otros, se sujetarán a las
        medidas de protección de la información de JAIR LEÓN FUERTES, no quedará
        exento de responsabilidad quien facilite tal información, de manera que dicho
        colaborador tendrá responsabilidad compartida desde el momento que la
        información esté a disposición de JAIR LEÓN FUERTES y de la cual se da a
        conocer a las partes involucradas a través del presente Aviso de Privacidad.
        </Typography>
        <Typography variant="body1">
        ▪ Finalidad del Uso de Información
        A través del presente Aviso de Privacidad JAIR LEÓN FUERTES declara que todos
        los datos personales solicitados y recabados tendrán las siguientes finalidades:
        Finalidades que son necesarias para la relación jurídica entre el Titular y el
        Responsable:
        - Identificar continuamente a los clientes/proveedores/prestadores de servicios
        y colaboradores, con carácter informativo.
        - Para la generación de guías prepagadas.
        - Atención de consultas, dudas, aclaraciones o quejas.
        - Brindar un mejor servicio, atención y trato a los titulares de la información.
        - Con fines de comunicación con clientes/usuarios/proveedores/prestadores de
        servicios.
        Finalidades que no son necesarias para la relación jurídica entre el Titular y el
        Responsable:
        - Analizar al cliente/proveedor/prestador de servicios y colaboradores con que
        se contrata.
        - Integración del expediente de información de los titulares de la información.
        - Futuras consultas para dar cumplimiento a obligaciones de carácter fiscal o
        comercial.
        Mismos datos personales que serán tratados mientras dure la relación comercial,
        para fines de seguridad, y que serán conservados durante el tiempo que sea
        necesario para cumplir las finalidades aquí establecidas, y que serán cancelados
        cuando terminen o dejen de cumplir con dichas finalidades. Lo anterior a excepción

        de procedimientos o requerimientos judiciales, fiscales, o que el propio particular
        ejerza la cancelación de los mismos.

        De ser el caso que cambien o aumenten las anteriores, se notificará nuevamente al
        Titular de los Derechos para que emita su aceptación o rechazo a las nuevas
        finalidades. Se entenderá que hubo aceptación tácita a las nuevas finalidades,
        cuando no obstante haber recibido el nuevo Aviso de Privacidad, no responda o
        manifieste objeción alguna dentro de los 5 cinco días posteriores, de acuerdo al
        artículo 14 del Reglamento.
        </Typography>
        <Typography variant="body1">
        ▪ Confidencialidad y Limitaciones
        JAIR LEÓN FUERTES garantiza por medio de todas las medidas de seguridad
        administrativas, técnicas y físicas a las que tenga alcance y que involucren un
        esfuerzo razonable, el proteger su información, buscando garantizar estricta
        confidencialidad de la información a través de la implementación de políticas de
        privacidad y de control de la misma, así como lo estipulado contractualmente,
        sujetándose a los parámetros de la LEY y su REGLAMENTO y las demás
        normativas aplicables. Aunado a lo anterior JAIR LEÓN FUERTES administrará y
        resguardará los Datos Personales obtenidos mediante bases de datos, las cuales
        son administradas única y exclusivamente por las personas designadas para tales
        efectos, restringiendo todo tipo de acceso a personas no autorizadas, y bajo
        ninguna circunstancia venderá, alquilará o enajenará información relativa a sus
        datos personales ni los compartirá, transmitirá o transferirá sin que medie el
        consentimiento del titular. Las bases de datos están protegidas en todo momento
        por sistemas de seguridad de cómputo, con la finalidad de restringir el acceso o
        tratamiento de la información a personas ajenas y/o terceros no autorizados.

        Asimismo, cualquier vulneración a la seguridad de la información será informada al
        Titular a fin de que este último pueda tomar las medidas correspondientes a la
        defensa de sus derechos.
        En caso de que el titular desee limitar el uso o divulgación de sus Datos Personales,
        en relación con una o varias de las formalidades del tratamiento de Datos
        Personales, podrá enviar la solicitud respectiva conforme al procedimiento previsto
        en el módulo de Derechos Arco del presente Aviso de Privacidad.
        </Typography>
        <Typography variant="body1">
        ▪ Transferencia de Datos Personales
        JAIR LEÓN FUERTES podrá transferir sus datos personales a terceros cuando se
        cuente con el consentimiento expreso o tácito del Titular de los Derechos
        Personales al presente aviso; lo anterior para la prestación de servicios de envío de
        paquetería. Asimismo, le informamos que tales terceros asumen las mismas
        obligaciones y responsabilidades frente a sus datos personales en términos de éste
        Aviso de Privacidad y los principios de protección de datos personales previstos en
        la LEY y su REGLAMENTO.
        La transferencia de datos financieros o patrimoniales de los Titulares
        únicamente la realizará previo su consentimiento expreso, a menos que la
        transmisión de dicha información sea necesaria para llevar a cabo las
        relaciones jurídicas entre el Titular y Jair León Fuertes.
        </Typography>
        <Typography variant="body1">
        ▪ Atención a Dudas y Reclamaciones
        Las oficinas de JAIR LEÓN FUERTES se encuentran ubicadas en; Calle Dionisio
        Rodríguez #500, Colonia San Juan de Dios, Guadalajara, Jalisco, México, C.P.
        44360. También puede ser contactado a través de su correo electrónico
        jairleon96@hotmail.com para la atención de dudas o reclamaciones, así como para
        el seguimiento al Procedimiento de Derechos ARCO a favor del Titular de los Datos
        Personales, en un horario de lunes a viernes de 09:00 a.m. a 20:00 p.m. y los
        sábados de 09:00 a.m. a 16:00 p.m.
        </Typography>
        <Typography variant="body1">
        ▪ Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición)
        El Titular de los Datos Personales por sí mismo o a través de su Representante
        Legal debidamente acreditado podrá iniciar el procedimiento de derechos ARCO a
        efecto de exigir la debida atención de sus Datos Personales así como solicitar la
        finalización en el uso de su información.
        JAIR LEÓN FUERTES como particular responsable se compromete a desahogar el
        procedimiento conforme lo estipulado en la LEY y su REGLAMENTO. Tal
        procedimiento podrá desahogarse por escrito en el domicilio señalado en el
        presente Aviso de Privacidad en el apartado de atención a dudas y reclamaciones,
        así como por correo electrónico en la cuenta señalada.
        El procedimiento de derechos ARCO se explica debidamente en el Módulo de
        Derechos ARCO que JAIR LEÓN FUERTES pone a su disposición a través de su
        página de internet: www.envioshappy.com y el cual es exigible por el Titular de los
        Derechos si así lo requiere ya sea por medio electrónico siempre y cuando guarde
        relación con la responsable del manejo de la información.
        JAIR LEÓN FUERTES le informa que agotadas todas las vías legales ante la
        responsable y sin haber recibido una respuesta satisfactoria, podrá iniciar el
        Procedimiento de Protección de Derechos ante el Instituto Nacional de Acceso a la
        Información Pública y Protección de Datos Personales (INAI) cuyo enlace
        electrónico es (https://home.inai.org.mx/) dentro de los 15 días siguientes a la
        recepción o falta de respuesta de su solicitud.
        </Typography>
        <Typography variant="body1">
        ▪ Manifestación del consentimiento
        Para que JAIR LEÓN FUERTES pueda llevar a cabo el tratamiento de los Datos
        Personales proporcionados, el titular deberá de otorgar la aceptación expresa y/o
        tácita para dicho tratamiento, la cual se llevará a cabo de la siguiente manera:
        Aceptación Expresa.- Conforme a lo establecido por el numeral 8 de la Ley, se
        entenderá que el Titular otorga su consentimiento expreso para que JAIR LEÓN
        FUERTES realice el tratamiento de sus datos personales y/o su transferencia,
        cuando manifieste su voluntad de manera verbal, por escrito, por medios
        electrónicos, ópticos o por cualquier otra tecnología. JAIR LEÓN FUERTES pondrá
        a su disposición el presente Aviso de Privacidad por medio de una solicitud al correo
        electrónico: jairleon96@hotmail.com y/o solicitándolo físicamente en el domicilio
        que se señala en el apartado de Atención a Dudas y Reclamaciones dentro del
        presente Aviso de Privacidad, en un horario de lunes a viernes de 09:00 a.m. a
        20:00 p.m. y los sábados de 09:00 a.m. a 16:00 p.m. Esto con el fin primordial de
        que conozca su contenido, otorgue y/o niegue su consentimiento.
        Cuando los Datos Personales se obtengan personalmente del Titular de los mismos,
        JAIR LEÓN FUERTES pondrá a su disposición el presente Aviso de Privacidad de
        manera previa al recabado de los Datos Personales y de una forma clara y
        fehaciente por medio de los formatos y medios que se señalan en el presente Aviso
        de Privacidad. Además JAIR LEÓN FUERTES recabará la aceptación o negación
        del consentimiento del titular para someter dichos datos a las finalidades del
        Tratamiento establecidas en el presente Aviso de Privacidad.
        Aceptación Tácita.- Conforme al numeral 8 de la Ley, el contenido del presente
        Aviso de Privacidad, así como cualquier modificación al mismo serán dadas a
        conocer a través de los medios establecidos en el presente Aviso para tales efectos,
        y en caso de que el Titular no manifieste oposición al contenido y alcances de los
        mismos dentro de un periodo de 05 cinco días contados a partir de que JAIR LEÓN
        FUERTES ponga a su disposición el presente Aviso de Privacidad o sus
        modificaciones, se entenderá que otorga su consentimiento tácito para que JAIR
        LEÓN FUERTES efectúe el tratamiento y transferencia de sus Datos Personales
        con base en las finalidades del Tratamiento establecidas en el presente Aviso.
        Aceptación Retroactiva.- Para todos nuestros clientes, usuarios, prestadores de
        servicios, proveedores, trabajadores y/o titulares de los Datos Personales que en
        fechas anteriores a la publicación del presente Aviso de Privacidad nos hayan
        proporcionado sus Datos Personales, les será puesto a su disposición el presente
        Aviso de Privacidad conforme a lo establecido en los apartados que anteceden, con
        el fin de que otorguen o nieguen su consentimiento, ya sea de manera expresa y/o
        tácita, para que JAIR LEÓN FUERTES pueda continuar o no llevando a cabo el
        tratamiento de sus Datos Personales con base en las finalidades del tratamiento
        establecidas en el presente Aviso de Privacidad, en el entendido de que en caso de no existir manifestación alguna de oposición al contenido y alcances del presente
        Aviso de Privacidad al momento de haber estado a tu disposición, tácitamente
        otorga su consentimiento para llevar cabo el tratamiento de sus Datos Personales
        con base al presente Aviso de Privacidad.
        </Typography>
        <Typography variant="body1">
        ▪ Revocación del Consentimiento
        La revocación del consentimiento en el manejo de la información procederá cuando
        así lo solicite el Titular de los Datos Personales por su propio derecho o por medio
        de su Representante Legal debidamente acreditado con Poder Notariado al
        momento del ejercicio de sus Derechos ARCO, siempre y cuando no se formalice
        una relación contractual en los términos pactados entre las partes por escrito y de
        conformidad a lo establecido por los numerales 16 fracción II de la Ley, 14, 30, 40,
        41 y 42 del Reglamento.
        </Typography>
        <Typography variant="body1">
        ▪ Listado de Exclusión / Cancelación
        JAIR LEÓN FUERTES no podrá bajo ninguna circunstancia contractual retener la
        información del Titular de los Datos Personales cuando ésta se haya cancelado
        debidamente con el procedimiento previsto, excepto por las siguientes causas:
        inconformidades laborales, presunción delictuosa, por disposición legal y petición de
        la autoridad.
        Respecto de lo anterior será responsabilidad de JAIR LEÓN FUERTES conforme a
        las especificaciones legales el retener la información; cuando sea respecto a
        información contable de 5 a 10 años según lo previsto en el Código Fiscal de la
        Federación en su Art. 30 y en el caso de información comercial 10 años según lo
        previsto en el Art. 46 del Código de Comercio. Agotado ese plazo de tiempo la
        información podrá ser eliminada.
        JAIR LEÓN FUERTES se compromete a incluir su información en un Listado de
        Exclusión para suspender toda actividad contractual y ofrecimiento de servicios que
        pudiera recaer en actos de molestia respecto del uso de su información, tal
        información será debidamente resguardada hasta la conclusión de los términos
        señalados por ley y/o por petición de autoridad para proceder a su cancelación, de
        la cual será debidamente notificado.
        </Typography>
        <Typography variant="body1">
        ▪ Tecnologías y uso de cookies
        JAIR LEÓN FUERTES utiliza mecanismos en medios remotos o locales de
        comunicación electrónica, óptica u otra tecnología, por la cual recabamos datos
        personales de manera automática y simultánea al tiempo en que usted hace
        contacto con los mismos.
        A continuación definimos los medios que utilizamos:

        Cookies: Son archivos enviados por un sitio que después son almacenados en la
        computadora o dispositivo del usuario, en los cuales se registran las actividades
        previas y datos ingresados para ofrecer una experiencia más personalizada.
        Dentro de estas cookies puede almacenarse las páginas que ha visitado dentro del
        sitio o la información que ha sido ingresada por el usuario mediante formularios,
        entre otras; con el fin de hacer más fluida y personalizada su experiencia dentro del
        sitio. Un ejemplo de la información utilizada dentro de las cookies es el nombre de
        usuario y contraseña, con los cuales el sitio identifica al usuario y permite desplegar
        información que está destinada solo para esa persona.
        </Typography>
        <Typography variant="body1">
        ▪ Actualización y Modificación Aviso de Privacidad
        Nos reservamos el derecho de efectuar en cualquier momento modificaciones o
        actualizaciones al presente aviso de privacidad, para la atención de políticas
        internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros
        servicios o productos. Estas modificaciones estarán disponibles al público a través
        de nuestra página de internet en www.envioshappy.com en la sección de aviso de
        privacidad.
                        </Typography> 
                    </Box>
                </Grid>
            </Grid>
            <PublicFooter includeHomeBttn={true}/>
        </Container>
      )
    }

export default AvisoPrivacidadInt