import React from 'react'

import PublicHeader from './PublicHeader';
import PublicFooter from './PublicFooter';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
const TyC = () => {
  return (
    <Container 
    maxWidth={false}
    disableGutters
    >
        <PublicHeader color="black" includeHomeBttn={true}/> 
        <Grid container>
            <Grid item xs={12} sx={{m:12}}>
            <Box sx={{width:'100%'}}>
            <Typography align="center" variant="h5" sx={{fontWeight:600, mb:5}}>
            AVISO DE PRIVACIDAD CORTO
            </Typography>
            <Typography variant="body1">
            JAIR LEÓN FUERTES, es el responsable del tratamiento y resguardo de los datos
            personales que usted como persona física proporciona al solicitar los servicios
            ofrecidos a través de sus oficinas con domicilio ubicado calle Dionisio Rodríguez
            #500, Colonia San Juan de Dios, Guadalajara, Jalisco, México, C.P. 44360 y/o en la
            página web: www.envioshappy.com.
            Sus datos personales podrán ser utilizados para Identificar continuamente a los
            clientes/proveedores/prestadores de servicios y colaboradores, con carácter
            informativo; para la generación de guías prepagadas; atención de consultas, dudas,
            aclaraciones o quejas;brindar un mejor servicio, atención y trato a los titulares de la
            información; con fines de comunicación con
            clientes/usuarios/proveedores/prestadores de servicios.
            Los datos personales que proporcione serán protegidos conforme a lo dispuesto por
            la Ley Federal de Protección de Datos Personales en Posesión de Particulares
            y el Reglamento a la Ley Federal de Protección de Datos Personales en
            Posesión de Particulares, bajo los principios de consentimiento, finalidad, lealtad,
            proporcionalidad, calidad, legalidad, responsabilidad, así como con las medidas de
            seguridad administrativas, técnicas y físicas.
            El aviso de privacidad integral se podrá consultar también en esta dirección
            electrónica: www.envioshappy.com/aviso-integral
            </Typography> 
        </Box>
        </Grid>
        </Grid>
        <PublicFooter includeHomeBttn={true}/>
    </Container>
  )
}

export default TyC