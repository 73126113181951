import React, {FC, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange, Range } from 'react-date-range';
import es from 'date-fns/locale/es';

import { translateDate } from '../../../features/functions/handleDates';

import { RootState } from '../../../store';
import { comissionByDateRole } from '../../../store/actions/storeActions';

import { Store } from '../../../types/storeTypes';
import { User } from '../../../types/authTypes';

import { roleTypeArray } from '../../../features/referenceData/userLists';
import AvatarCustomed from '../../UI/AvatarCustomed';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import LoadingButton from '@mui/lab/LoadingButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ListItemIcon from '@mui/material/ListItemIcon';

interface StoreHeaderProps {
    store: Store,
    user: User
}
const StoreHeader: FC<StoreHeaderProps> = (props) => {

    const dispatch = useDispatch()
    const {store, user} = props;
    const isUserAdmin: boolean = user && (user.role === roleTypeArray[1] || user.role === roleTypeArray[2]) ? true : false 
    const storeId: string = store._id;
    const users: Array<User> | null = useSelector((state: RootState) => state.generic.usersAdmin)

    const date = new Date();

    const getDayOfMonth: any = (year:any, month:any, day:number) => new Date(year, month, day);
    const currentDate = date.getDate()
    const firstDayCurrentMonth = getDayOfMonth(date.getFullYear(), date.getMonth(), 1);
    const midDayCurrentMonth = getDayOfMonth(date.getFullYear(), date.getMonth(), 16);
    const lastDayCurrentMonth = new Date(getDayOfMonth(date.getFullYear(), date.getMonth()+1, 0).setHours(23,59,59,59));

    const [startDateQuery, setStartDateQuery] = useState<Date>(currentDate >= 16 ? midDayCurrentMonth:firstDayCurrentMonth)
    const [endDateQuery, setEndDateQuery] = useState<Date>(currentDate < 16 ? midDayCurrentMonth:lastDayCurrentMonth)
    const [dateRange, setDateRange] = useState<Array<Range>>([{
        startDate: currentDate >= 16 ? midDayCurrentMonth:firstDayCurrentMonth,
        endDate: currentDate < 16 ? midDayCurrentMonth:lastDayCurrentMonth,
        key: 'selection'
    }])
    const [storeMembers, setStoreMembers] = useState<Array<User>>()
    const [submittedByUser, setSubmittedByUser] = useState<User | undefined>(!isUserAdmin ? user:undefined)

    const startDateToDisplay: string = translateDate(startDateQuery)
    const endDateToDisplay: string = translateDate(endDateQuery)

    const [anchorDatesEl, setAnchorDatesEl] = useState<null | HTMLElement>(null);
    const openCalendarEl = Boolean(anchorDatesEl);
    const [anchorTeamEl, setAnchorTeamEl] = useState<null | HTMLElement>(null);
    const openTeamEl = Boolean(anchorTeamEl);

    const handleCloseCalendar = () => {
        setAnchorDatesEl(null);
    };

    const clickChangeCalendar = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorDatesEl(event.currentTarget);
    };

    const clickChangeTeamMember = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorTeamEl(event.currentTarget);
    };
    const handleCloseTeam = () => {
        setAnchorTeamEl(null);
    };

    const dateRangeHandler = (range:Array<Range>) => {
        const newStartDate: Date | undefined = range[0].startDate ? new Date(range[0].startDate.setHours(0,0,0,0)) : undefined;
        const newEndDate: Date | undefined = range[0].endDate ?  new Date(range[0].endDate.setHours(23,59,59,59)) : undefined;
        setDateRange(range)
        if(newStartDate && newEndDate) {
            setStartDateQuery(newStartDate)
            setEndDateQuery(newEndDate)
        }
    }
    
    useEffect(() => {    
        
        if(users) {
            const members = users.filter((user:User) => {
                const isUserInStore = user.storesIds?.includes(storeId)
                if(isUserInStore) {return user}
                return null
            })
            setStoreMembers(members)
        }

        dispatch<any>(comissionByDateRole(storeId, startDateQuery, endDateQuery, isUserAdmin, submittedByUser?.uid))
    }, [
        dispatch,
        user,
        users,
        storeId,
        isUserAdmin,
        startDateQuery,
        endDateQuery,
        submittedByUser
    ])

    return (
    <Card elevation={0} sx={{borderRadius:'30px', p:.5}}>
        <CardContent className="d-flex-row-start-start">
            {store ? 
                <AvatarCustomed 
                    userName={store.storeName} 
                    size={140} 
                    userPhoto={store.storeAvatar}  
                    fontWeight={600}
                    fontSize={40}
                    variant="rounded"
                />
                :null
            }
            <Box sx={{ml:2}}>
                <Typography variant='h5' sx={{fontWeight:700, mb:1}}>
                    {store.storeName}
                </Typography>
                <Typography variant='subtitle1' sx={{fontWeight:500}}>
                    {store.storeAddress}. {store.storeNeighborhood}
                </Typography>
                <Typography variant='subtitle1' sx={{fontWeight:500}}>
                    {store.storeSupervisor?.userName ? `Supervisor: ${store.storeSupervisor.userName}` : 'Sin supervisor'}
                </Typography>
                <Stack sx={{mt:1}}
                    justifyContent="flex-start" 
                    alignItems={{ xs: 'flex-start', md: 'center' }} 
                    direction={{ xs: 'column', md: 'row' }} 
                    spacing={1}>
                    <Chip 
                        size='medium'
                        icon={<PlaceOutlinedIcon />}
                        sx={{bgcolor:'transparent'}}
                        label={store.storeCity ? store.storeCity : 'Sin ciudad'} 
                        variant="filled"
                    />
                    <Chip 
                        size='medium'
                        icon={<WhatsAppIcon />}
                        sx={{bgcolor:'transparent'}}
                        label={store.storePhone ? store.storePhone : 'Sin teléfono'} 
                        variant="filled"
                        />
                </Stack>
            </Box>
        </CardContent>
        {/* <CardActions sx={{pt:0}}>
            <LoadingButton 
                aria-label="calendar"
                disableElevation
                color="secondary"
                variant="text"
                onClick={clickChangeCalendar}
                sx={{textTransform:'capitalize', mr:1}}
                startIcon={
                    <DateRangeIcon fontSize="small" />
                }
                >
                {`${startDateToDisplay} - ${endDateToDisplay}`}
            </LoadingButton>
            <Menu
                anchorEl={anchorDatesEl}
                open={openCalendarEl}
                onClose={handleCloseCalendar}
                PaperProps={{
                elevation: 2,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 4px 4px rgba(55, 61, 135,0.25))',
                    border:1,
                    borderRadius:3,
                    borderColor:'grey.500',
                    mt: .75,
                }
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                <DateRange 
                    editableDateInputs={true}
                    color='#1A4D2E'
                    onChange={(item:any) => dateRangeHandler([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRange}
                    locale={es}
                    rangeColors={['#1A4D2E']}
                />
            </Menu>
            { isUserAdmin ?
                <Box>
                    <LoadingButton
                        color="secondary"
                        variant="text"
                        disableElevation
                        onClick={clickChangeTeamMember}
                        startIcon ={
                            submittedByUser ?
                            <AvatarCustomed 
                                userName={submittedByUser.fullName} 
                                size={24} 
                                userPhoto={submittedByUser.userAvatar}  
                                fontWeight={400}
                                fontSize={14}
                            />
                            :<StorefrontOutlinedIcon />
                        }
                        >
                            { submittedByUser ? 
                                submittedByUser.fullName
                                :'Tienda'
                            }
                    </LoadingButton>
                    <Menu
                        anchorEl={anchorTeamEl}
                        open={openTeamEl}
                        onClose={handleCloseTeam}
                        onClick={handleCloseTeam}
                        PaperProps={{
                        elevation: 2,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 4px 4px rgba(55, 61, 135,0.25))',
                            border:1,
                            borderRadius:3,
                            borderColor:'grey.500',
                            mt: .75,
                        }
                        }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    >
                        <MenuList sx={{boxShadow:0, px:2}}>
                            <ListItem sx={{borderBottom:1, borderColor:'grey.300'}}>
                                <ListItemText>
                                    <Typography 
                                        variant="body2" 
                                        sx={{color:'grey.800'}}>
                                            Seleccionar miembro
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItemButton onClick={() => setSubmittedByUser(undefined)}>
                                <ListItemIcon>
                                    <StorefrontOutlinedIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="body2" sx={{fontWeight:500}}>
                                            Ver toda la tienda
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                            {storeMembers && storeMembers.map((storeMember:User, index:number) => {
                                return(
                                    <MenuItem 
                                    key={index} 
                                    onClick={() => setSubmittedByUser(storeMember)}
                                    >
                                        <ListItemAvatar>
                                            <AvatarCustomed 
                                                userName={storeMember.fullName} 
                                                size={32} 
                                                userPhoto={storeMember.userAvatar}  
                                                fontWeight={400}
                                                fontSize={18}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText 
                                            primary={
                                                <Typography variant="body2" sx={{fontWeight:500}}>
                                                    {storeMember.fullName}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography variant="caption" sx={{fontWeight:500}}>
                                                    {storeMember.role}
                                                </Typography>
                                            }
                                        />
                                    </MenuItem>
                                )
                            })}
                        </MenuList>
                    </Menu>
                </Box>
                :null
            }
        </CardActions> */}
    </Card>
    )
}

export default StoreHeader