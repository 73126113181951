import React, {FC, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";

import { RootState } from '../../../store';
import { setShowForm } from '../../../store/actions/genericActions';
import { User } from '../../../types/authTypes';

import EditProfile from './EditProfile';
import UserCard from './UserCard';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const UserProfile: FC = () => {
    const dispatch = useDispatch();
    const location:any = useLocation();
    const user: User | null = useSelector((state: RootState) => state.auth.user)
    const externalUser: User | undefined = useSelector((state: RootState) => state.generic.usersAdmin?state.generic.usersAdmin.find((user:User) => user.uid === location.state.id):undefined)
    const { loading, showForm } = useSelector((state: RootState) => state.generic);
    // const [ editUser, setEditUser] = useState<boolean>(false);
    const [ currentUser, setCurrentUser] = useState<boolean>(location.state.id === user?.uid ? true:false);

    const [userToDisplay, setUserToDisplay] =useState<User|undefined|null>(user && !externalUser ? user: externalUser ? externalUser : undefined)

    useEffect(() => {
        user && location.state.id === user?.uid ? setCurrentUser(true):setCurrentUser(false)
    }, [location, user])

    const toggleFormHandler = () => {
        const currentState = showForm
        dispatch<any>(setShowForm(!currentState))
    }

    return (
        <Container maxWidth='xl' sx={{bgcolor:'#F4F7F9', minHeight:'100vh'}}>
          <Toolbar sx={{mb:3}}/>
            { userToDisplay ? 
                <Grid container spacing={2} sx={{width:'100%', px:{xs:.5,md:4}, pb:4}}>
                    <Grid item xs={12}>
                        <Box sx={{width:'100%', mb: currentUser? 0:3}}>
                            <Typography 
                                component="h6" 
                                variant="h5" 
                                sx={{fontWeight:700}}>
                                {userToDisplay.fullName}
                            </Typography>
                            {currentUser ? 
                                <LoadingButton
                                    loading={loading}
                                    color='secondary'
                                    size='small'
                                    sx={{my:1.5}}
                                    disableElevation
                                    variant={showForm ? 'text':'outlined'}
                                    onClick={toggleFormHandler}
                                    startIcon={
                                        !showForm?
                                        <EditIcon />
                                        :
                                        <ArrowBackIosIcon />
                                    }
                                >   
                                    {showForm ? 'Regresar' : 'Editar perfil'}
                                </LoadingButton>
                                :null
                            }
                        </Box>
                    </Grid>
                    { showForm && user ? 
                        <Grid item container xs={12} md={6}>
                            <EditProfile user={user} />
                        </Grid>
                        :null
                    }
                    { user ? 
                        <Grid item container xs={12} md={6}>
                            <UserCard user={userToDisplay} currentUser={currentUser} />
                        </Grid>
                        :null
                    }   
                </Grid>
                :null
            }
        </Container>
    )
}

export default UserProfile