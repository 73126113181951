import React, {FC, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange, Range } from 'react-date-range';
import es from 'date-fns/locale/es';

import { translateDate, getLocalDateFromDb, translateDateFromDb } from '../../../features/functions/handleDates'

import { RootState } from '../../../store';
import { comissionByDateRole } from '../../../store/actions/storeActions';

import { Store } from '../../../types/storeTypes';
import { User } from '../../../types/authTypes';

import { formatCurrencyMxn } from '../../../features/functions/genericFunctions';
import { roleTypeArray } from '../../../features/referenceData/userLists';
import AvatarCustomed from '../../UI/AvatarCustomed';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';

interface StoreHeaderProps {
    store: Store,
    user: User
}
const StoreBody: FC<StoreHeaderProps> = (props) => {

    const dispatch = useDispatch()
    const {store, user} = props;
    const isUserAdmin: boolean = user && (user.role === roleTypeArray[1] || user.role === roleTypeArray[2]) ? true : false 
    const storeId: string = store._id;
    const users: Array<User> | null = useSelector((state: RootState) => state.generic.usersAdmin)

    const date = new Date();
    const getDayOfMonth: any = (year:any, month:any, day:number) => new Date(year, month, day);
    const currentDate = date.getDate()
    const firstDayCurrentMonth = getDayOfMonth(date.getFullYear(), date.getMonth(), 1);
    const midDayCurrentMonth = getDayOfMonth(date.getFullYear(), date.getMonth(), 16);
    const lastDayCurrentMonth = new Date(getDayOfMonth(date.getFullYear(), date.getMonth()+1, 0).setHours(23,59,59,59));

    const [startDateQuery, setStartDateQuery] = useState<Date>(currentDate >= 16 ? midDayCurrentMonth:firstDayCurrentMonth)
    const [endDateQuery, setEndDateQuery] = useState<Date>(currentDate < 16 ? midDayCurrentMonth:lastDayCurrentMonth)
    const [dateRange, setDateRange] = useState<Array<Range>>([{
        startDate: currentDate >= 16 ? midDayCurrentMonth:firstDayCurrentMonth,
        endDate: currentDate < 16 ? midDayCurrentMonth:lastDayCurrentMonth,
        key: 'selection'
    }])
    const [storeMembers, setStoreMembers] = useState<Array<User>>()
    const [submittedByUser, setSubmittedByUser] = useState<User | undefined>(!isUserAdmin ? user:undefined)

    const startDateToDisplay: string = translateDate(startDateQuery)
    const endDateToDisplay: string = translateDate(endDateQuery)

    const [anchorDatesEl, setAnchorDatesEl] = useState<null | HTMLElement>(null);
    const openCalendarEl = Boolean(anchorDatesEl);
    const [anchorTeamEl, setAnchorTeamEl] = useState<null | HTMLElement>(null);
    const openTeamEl = Boolean(anchorTeamEl);

    const handleCloseCalendar = () => {
        setAnchorDatesEl(null);
    };

    const clickChangeCalendar = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorDatesEl(event.currentTarget);
    };

    const clickChangeTeamMember = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorTeamEl(event.currentTarget);
    };
    const handleCloseTeam = () => {
        setAnchorTeamEl(null);
    };

    const dateRangeHandler = (range:Array<Range>) => {
        const newStartDate: Date | undefined = range[0].startDate ? new Date(range[0].startDate.setHours(0,0,0,0)) : undefined;
        const newEndDate: Date | undefined = range[0].endDate ?  new Date(range[0].endDate.setHours(23,59,59,59)) : undefined;
        setDateRange(range)
        if(newStartDate && newEndDate) {
            setStartDateQuery(newStartDate)
            setEndDateQuery(newEndDate)
        }
    }
    
    useEffect(() => {    
        
        if(users) {
            const members = users.filter((user:User) => {
                const isUserInStore = user.storesIds?.includes(storeId)
                if(isUserInStore) {return user}
                return null
            })
            setStoreMembers(members)
        }

        dispatch<any>(comissionByDateRole(storeId, startDateQuery, endDateQuery, isUserAdmin, submittedByUser?.uid))
    }, [
        dispatch,
        user,
        users,
        storeId,
        isUserAdmin,
        startDateQuery,
        endDateQuery,
        submittedByUser
    ])

    return (
    <Card elevation={0} sx={{borderRadius:'30px', p:.5}}>
        <CardContent>
            <List subheader={
              <Box className = "d-flex-row-start-centered">
                <ListSubheader component="div" id="nested-list-subheader">
                    Miembros
                </ListSubheader>
              </Box>
            }>
              {user && storeMembers && storeMembers.map((userExt:User, index:number) => {
                const currentBalance = userExt.balance ? userExt.balance: 0
                const currentDeposit = userExt.abono ? userExt.abono: 0
                const translatedDate:Date= translateDateFromDb(userExt.createdAt)
                const dateToDisplay: string = getLocalDateFromDb(translatedDate)
                return(
                  <Box key={index} sx={{width:'100%'}}>
                    <ListItem
                      alignItems="center"
                      sx={{
                        borderBottom: 1.25, 
                        borderBottomColor:'grey.100',
                        borderLeft:0,
                        borderTopLeftRadius:5,
                    }} 
                      >
                        <Grid container>
                            <Grid item xs={12} md={4} className="d-flex-row-center-centered">
                                <ListItemAvatar>
                                <AvatarCustomed 
                                    userName={userExt.fullName} 
                                    size={65} 
                                    userPhoto={userExt.userAvatar}  
                                    fontWeight={500}
                                    fontSize={22}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                sx={{ml:1.5}}
                                primary={
                                    <Typography sx={{fontWeight:500}} variant="body1">
                                        {userExt.fullName}
                                    </Typography>
                                }
                                secondary={
                                    <span>
                                    <Typography sx={{fontWeight:400, color:'grey.700'}} variant="body2">
                                        {userExt.email}
                                    </Typography>
                                    <Typography sx={{fontWeight:400, color:'grey.700'}} variant="body2">
                                        {userExt.phoneNumber?userExt.phoneNumber:'Sin número de tel'} 
                                    </Typography>
                                    <Typography sx={{fontWeight:400, color:'grey.700'}} variant="body2">
                                        Rol: {userExt.role}
                                    </Typography>
                                    </span>
                                }
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ListItemText
                                sx={{ml:1.5}}
                                primary={
                                    <Typography sx={{fontWeight:500}} variant="body1">
                                        Balance
                                    </Typography>
                                }
                                secondary={
                                    <span>
                                        <Typography sx={{fontWeight:400, color:'grey.700'}} variant="body2">
                                            Saldo: {formatCurrencyMxn(currentBalance)}
                                        </Typography>
                                        <Typography sx={{fontWeight:400, color:'grey.700'}} variant="body2">
                                            Abono: {formatCurrencyMxn(currentDeposit)}
                                        </Typography>
                                        <Typography sx={{fontWeight:400, color:'grey.700'}} variant="body2">
                                            {currentDeposit>currentBalance?
                                                `A favor ${formatCurrencyMxn(currentBalance&&currentDeposit?(-1*(currentBalance-currentDeposit)):0)}`
                                                :
                                                `Por pagar: ${formatCurrencyMxn(currentBalance&&currentDeposit?currentBalance-currentDeposit:0)}`
                                            }
                                        </Typography>
                                    </span>
                                }
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ListItemText
                                sx={{ml:1.5}}
                                primary={
                                    <Typography sx={{fontWeight:500}} variant="body1">
                                        Info
                                    </Typography>
                                }
                                secondary={
                                    <span>
                                        <Typography sx={{fontWeight:400, color:'grey.700'}} variant="body2">
                                            Status: {userExt.active?'Activo':'Deshabilitado'}
                                        </Typography>
                                        <Typography sx={{fontWeight:400, color:'grey.700'}} variant="body2">
                                            {userExt.emailVerified ? 'Email verificado':'Email sin verificar'}
                                        </Typography>
                                        <Typography sx={{fontWeight:400, color:'grey.700'}} variant="body2">
                                            Usuario creado el: {dateToDisplay}
                                        </Typography>
                                    </span>
                                }
                                />
                            </Grid>

                        </Grid>
                    </ListItem>
                  </Box>
                )
              })}
            </List>
        </CardContent>
    </Card>
    )
}

export default StoreBody