import React, {FC} from 'react'
import  {useNavigate} from 'react-router-dom';

import EHIsotipoWhite from '../../UI/assets/EHIsotipoWhite';
import EHIsotipo from '../../UI/assets/EHIsotipo';

import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'

interface HeaderProps {
  color?: string
  includeHomeBttn?: boolean
}

const PublicHeader: FC<HeaderProps> = (props) => {

  const {color, includeHomeBttn} = props

  const navigate = useNavigate()

  const handleLogin = () =>{
      navigate('/sign-in')
  }
  const handleGoHome = () =>{
      navigate('/')
  }

  return (
    <Grid container spacing={12}>
      <Grid item xs={12} className='d-flex-row-between-centered'> 
        <Box sx={{px:3, pt:2, pb:0, cursor:'pointer'}} onClick={handleGoHome}>
          {includeHomeBttn?
            <EHIsotipo width={35} height={35} />
            :
            <EHIsotipoWhite width={35} height={35} />
          }
        </Box>
        <Box sx={{px:3, pt:1, pb:0, display: 'flex', width:'100%', flexDirection:'row', justifyContent:'flex-end'}}>
            {includeHomeBttn ?
              <LoadingButton
                variant='text'
                color="secondary"
                size="small"
                sx={{
                    // width:'40%',
                    // mt:3,
                    mr:3, 
                    color:color?color:'white', 
                    ":hover":{
                        color:'secondary.dark',
                        bgcolor:'white'
                    }
                }}
                onClick={handleGoHome}
                
            >
              Inicio
            </LoadingButton>
            :null
          }
            <LoadingButton
              variant='text'
              color="secondary"
              size="small"
              sx={{
                  // width:'40%',
                  // mt:3,
                  mr:3, 
                  color:color?color:'white', 
                  ":hover":{
                      color:'secondary.dark',
                      bgcolor:'white'
                  }
              }}
              onClick={handleLogin}
              
          >
              Crea una cuenta
            </LoadingButton>
        </Box>
      </Grid>
    </Grid>
    // </Toolbar>
  )
}

export default PublicHeader