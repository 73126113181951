import React, { FC }from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {dialogTypes} from '../../../features/referenceData/appLists';

import { RootState } from '../../../store';
import { setDialog, setDialogType } from '../../../store/actions/genericActions';

import DirectoryListTable from './DirectoryList'

import Box from '@mui/system/Box'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import LoadingButton from '@mui/lab/LoadingButton';
import DoNotDisturbOutlinedIcon from '@mui/icons-material/DoNotDisturbOutlined';

const DirectoryCard:FC = () => {

    const dispatch = useDispatch()
    const { userDirectory } = useSelector((state: RootState) => state.generic);

    const openDialogHandler = () => {
        dispatch<any>(setDialog(true))
        dispatch<any>(setDialogType(dialogTypes[2]))
    }

    return (
    <Box sx={{ width:'100%'}}>
        <Card sx={{borderRadius:'30px'}}>
            <CardContent>
                <Box sx={{width:'100%'}} className="d-flex-row-end-centered">
                    <LoadingButton
                        // loading={loading}
                        color="secondary"
                        variant="contained"
                        disableElevation
                        sx={{mb:2}}
                        onClick={openDialogHandler}
                    >
                        Agregar dirección
                    </LoadingButton>
                </Box>
                {userDirectory && userDirectory.length>0 ?
                    <Box sx={{width:'100%'}} className="d-flex-col-center-centered">
                        <DirectoryListTable directory={userDirectory}/>
                    </Box>
                    :
                    <Box sx={{width:'100%'}} className="d-flex-col-center-centered">
                        <Typography variant="h6" component="h5" sx={{color:'grey.700'}}>
                            <DoNotDisturbOutlinedIcon sx={{fontSize:32}}/>
                        </Typography>
                        <Typography variant="h6" component="h5" sx={{color:'grey.700'}}>
                            Aún no tienes direcciones en favoritos
                        </Typography>
                    </Box>
                }
            </CardContent>
        </Card>
    </Box>
    )
}

export default DirectoryCard