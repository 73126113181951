import React, {FC} from "react";

import DefaultAvatar from '../UI/DefaultAvatar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/system/Box';

interface AvatarCustomProps {
    userName: string | null,
    size: number | null,
    userPhoto?: string | null,
    fontWeight?: number | null,
    fontSize?: number | null
    variant?: any

}

const AvatarCustomed: FC <AvatarCustomProps> = (props) => {

    const { userName, size, userPhoto, fontWeight, fontSize, variant } = props

  return (
    <Box>
        {userPhoto ? 
            <Avatar variant={variant ? variant : 'circular'} sx={{width: size, height:size}} src={userPhoto} />
            :
            <DefaultAvatar
                variant={variant ? variant : 'circular'}  
                sx={{width: size, height:size, fontSize:fontSize ? fontSize : 24, border:1.5, borderColor:'secondary.main', bgcolor:'grey.100', color:'secondary.main', fontWeight: fontWeight ? fontWeight :600}}
                fullName={userName}
            />
        }
    </Box>
  )
}

export default AvatarCustomed