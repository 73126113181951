import React, {FC} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { RootState } from '../../../store';

import { User } from '../../../types/authTypes';
import { Store } from '../../../types/storeTypes';

import StoreHeader from './StoreHeader';
import StoreBody from './StoreBody';
import ComissionsSummary from '../comissions/ComissionsSummary';

import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';

const StoreProfile:FC = () => {

    const location:any = useLocation();
    const storeId: string = location.state.id;
    const store: Store | undefined = useSelector((state: RootState) => state.stores.stores ?state.stores.stores.find((store:Store) => store._id === storeId ):undefined)
    const user: User | null = useSelector((state: RootState) => state.auth.user);

    return (
        <Container maxWidth='xl' sx={{bgcolor:'#F4F7F9', minHeight:'100vh'}}>
          <Toolbar sx={{mb:3}}/>
            {store && user ?
                <Grid container spacing={2} sx={{width:'100%', px:{xs:.5,md:4}, pb:4}}>
                    <Grid item xs={12}>
                        <StoreHeader store={store} user={user} />
                    </Grid>
                    <Grid item xs={12} sx={{mt:1}}>
                        <StoreBody store={store} user={user} />
                    </Grid>
                </Grid>
                :null
            }
        </Container>
    )
}

export default StoreProfile