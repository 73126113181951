export const roleTypeArray: string[] = [
    'En revisión', //0
    'Admin', //1
    'Supervisor', //2
    'Miembro', //3
    'Solo lectura' //4
]

export const actionUpdateUser: string[] = [
    'deactivate', //0
    'activate', //1
    'delete', //2
    'updateStore', //3
    'updateManager', //4
    'addPrice'  //5
]