import React, {FC} from 'react';

import { useDispatch } from 'react-redux';
import { setError, setSuccess, setLoading } from '../../store/actions/genericActions';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface AlertMessageProps {
    message: string,
    severity: any,
}

const AlertMessage: FC<AlertMessageProps> = (props) => {
    const {message, severity} = props

    const dispatch = useDispatch();

    const resetAlert = () => {
        dispatch<any>(setError(''))
        dispatch<any>(setSuccess(''))
        dispatch<any>(setLoading(false))
    }

    return (
        <Alert
            variant="filled"
            severity={severity}
            sx={{my:3, width:'80%'}}
            action={
                <Button 
                    color="inherit" 
                    size="small"
                    onClick={resetAlert}
                    >
                    Cerrar
                </Button>
            }
        >
            <Typography variant="body2" sx={{fontWeight:700}}>
                {message}
            </Typography>
        </Alert>
    )
}

export default AlertMessage