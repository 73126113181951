import React, { FC } from 'react'
import Avatar from '@mui/material/Avatar'

interface DefaultAvatarProps {
    fullName: any,
    sx: any,
    variant?: any
}

const DefaultAvatar: FC<DefaultAvatarProps> = ( props) => {

    
    function stringAvatar(name: any) {

        const nameToSplit =  name ? name : 'Default';

        const nameArray = nameToSplit.split(' ')
        const initials = nameArray.length > 1 ? 
            (nameArray[0][0] + nameArray[1][0]).toString().toUpperCase()
            : 
            (nameArray[0][0] +  nameArray[0][1]).toString().toUpperCase()
        return {
          children: initials,
        };
      }


    return (
        <Avatar variant={props.variant} className="default-avatar-color" sx={props.sx} {...stringAvatar(props.fullName)} />
    )
}

export default DefaultAvatar
