import React, { FC, useState, useEffect, useMemo  }from 'react';
import { useSelector, useDispatch } from 'react-redux';


import { User } from '../../../types/authTypes';
import { UserDirectoryFav, carrierExtendedZone } from '../../../types/storeTypes';
import {directoryRole} from'../../../features/referenceData/appLists';
import {availableCountries} from'../../../features/referenceData/appLists';
import {mexStates, stateToID} from'../../../features/referenceData/appLists';

import { RootState } from '../../../store';
import { setLoading, setDialog, setDialogType, setOriginDirShipment, setDestinyDirShipment, setExtendedZone } from '../../../store/actions/genericActions';
import { createDirectory } from '../../../store/actions/storeActions';

import { validateLengthInput, validateExactLengthInput, validateEmail } from '../../../features/functions/validatorField';

import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { getExtendedZone } from '../../../store/actions/apiActions'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import Tooltip from '@mui/material/Tooltip'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface createShipmentProps {
    dirRole: string;
}

const DirectoryForShipmentForm: FC<createShipmentProps> = (props) => {

    const {dirRole} = props

    const dispatch= useDispatch();  
  
    const { userDirectory, originDirectionShipment, destinyDirectionShipment, originExtendedZone, destinationExtendedZone } = useSelector((state: RootState) => state.generic);
    const user: User | null = useSelector((state: RootState) => state.auth.user)
    // const [sendOrReceive, setSendOrReceive] = useState<string>(dirRole)
    // const [favDir, setFavDir] = useState<string>('')
    const [favId, setFavId] = useState<string>(
        dirRole===directoryRole[0]&&originDirectionShipment?
        originDirectionShipment[0]._id:
        dirRole===directoryRole[1]&&destinyDirectionShipment?
        destinyDirectionShipment[0]._id:
        ''
    )
    const [alias, setAlias] = useState<string| null>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].alias?
        originDirectionShipment[0].alias:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].alias?
        destinyDirectionShipment[0].alias:
        ''
    )
    const [fullName, setFullName] = useState<string| null>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].fullName?
        originDirectionShipment[0].fullName:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].fullName?
        destinyDirectionShipment[0].fullName:
        ''
    )
    /*const [email, setEmail] = useState<string| null>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].email?
        originDirectionShipment[0].email:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].email?
        destinyDirectionShipment[0].email:
        ''
    )*/
    const [phone, setPhone] = useState<string| null>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].phone?
        originDirectionShipment[0].phone:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].phone?
        destinyDirectionShipment[0].phone:
        ''
    )
    const [company, setCompany] = useState<string| null>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].company?
        originDirectionShipment[0].company:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].company?
        destinyDirectionShipment[0].company:
        ''
    )
    const [rfc, setRFC] = useState<string| null>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].rfc?
        originDirectionShipment[0].rfc:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].rfc?
        destinyDirectionShipment[0].rfc:
        ''
    )
    const [address, setAddress] = useState<string| null>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].address?
        originDirectionShipment[0].address:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].address?
        destinyDirectionShipment[0].address:
        ''
    )
    const [address2, setAddress2] = useState<string| null>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].address2?
        originDirectionShipment[0].address2:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].address2?
        destinyDirectionShipment[0].address2:
        ''
    )

    const [zipCode, setZipCode] = useState<string| null>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].zipCode?
        originDirectionShipment[0].zipCode:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].zipCode?
        destinyDirectionShipment[0].zipCode:
        ''
    )
    const [neighborhood, setNeighborhood] = useState<string| null>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].neighborhood?
        originDirectionShipment[0].neighborhood:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].neighborhood?
        destinyDirectionShipment[0].neighborhood:
        ''
    )
    const [country, setCountry] = useState<string| undefined>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].country?
        originDirectionShipment[0].country:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].country?
        destinyDirectionShipment[0].country:
        ''
    )
    const [state, setState] = useState<string| undefined>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].state&&stateToID(originDirectionShipment[0].state,"satid")!=""?
        originDirectionShipment[0].state:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].state&&stateToID(destinyDirectionShipment[0].state,"satid")!=""?
        destinyDirectionShipment[0].state:
        ''
    )
    const [city, setCity] = useState<string| null>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].city?
        originDirectionShipment[0].city:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].city?
        destinyDirectionShipment[0].city:
        ''
    )
    const [reference, setReference] = useState<string| null>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[0].reference?
        originDirectionShipment[0].reference:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[0].reference?
        destinyDirectionShipment[0].reference:
        ''
    )
    const [saveInFavs, setSaveInFavs] = useState<boolean>(
        dirRole===directoryRole[0]&&originDirectionShipment&&originDirectionShipment[1]?
        originDirectionShipment[1]:
        dirRole===directoryRole[1]&&destinyDirectionShipment&&destinyDirectionShipment[1]?
        destinyDirectionShipment[1]:
        false
    )
    const [disableSaveFav, setDisableSaveFav] = useState<boolean>(
        dirRole===directoryRole[0]&&originDirectionShipment?
        true:
        dirRole===directoryRole[1]&&destinyDirectionShipment?
        true:
        false
    )
    
    const [formValid, setFormValid] = useState<boolean>(false);

    const [invalidAlias, setInvalidAlias] = useState<boolean>(false);
    const [invalidFullName, setInvalidFullName] = useState<boolean>(false);
    const [invalidPhone, setInvalidPhone] = useState<boolean>(false);
    const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
    const [invalidCompany, setInvalidCompany] = useState<boolean>(false);
    const [invalidAddress, setInvalidAddress] = useState<boolean>(false);
    const [invalidAddress2, setInvalidAddress2] = useState<boolean>(false);
    const [invalidZipCode, setInvalidZipCode] = useState<boolean>(false);
    const [invalidNeighborhood, setInvalidNeighborhood] = useState<boolean>(false);
    const [invalidCountry, setInvalidCountry] = useState<boolean>(false);
    const [invalidState, setInvalidState] = useState<boolean>(false);
    const [invalidCity, setInvalidCity] = useState<boolean>(false);
    const [invalidReference, setInvalidReference] = useState<boolean>(false);
    const [invalidRFC, setInvalidRFC] = useState<boolean>(false);
    let sendStates = mexStates;

    useEffect(() => {
        if(country==""){
            setCountry(availableCountries[0]);
        }
        if((
            fullName!== ''&&
            phone!== ''&&
            company!== ''&&
            address!== ''&&
            zipCode!== ''&&
            neighborhood!== ''&&
            country!== ''&&
            state!== ''&&
            city!== ''
        ) 
        && 
        (
            !invalidAlias&&
            !invalidFullName&&
            !invalidPhone&&
            !invalidCompany&&
            !invalidAddress&&
            !invalidAddress2&&
            !invalidZipCode&&
            !invalidNeighborhood&&
            !invalidCountry&&
            !invalidState&&
            !invalidCity&&
            !invalidReference&&
            !invalidRFC
        )
        ){
            setFormValid(true)
            const dirToCache: UserDirectoryFav = {
                _id:favId?favId:'',
                createdBy:user&&user.uid?user.uid:'',
                alias:alias?alias:'',
                fullName:fullName?fullName:'',
                phone: phone?phone:'',
                zipCode: zipCode?zipCode:'',
                address: address?address:'',
                //email: email?email:'',
                neighborhood: neighborhood?neighborhood:'',
                city: city?city:'',
                state: state?state:'',
                country: country?country:'',
                company: company?company:'',
                reference: reference?reference:'',
                rfc: rfc?rfc:'',
            }
            const setStore:boolean = true
            if(dirRole===directoryRole[0])
            dispatch<any>(setOriginDirShipment(setStore, dirToCache, saveInFavs,dirRole))
            if(dirRole===directoryRole[1])
            dispatch<any>(setDestinyDirShipment(setStore, dirToCache, saveInFavs,dirRole))
        } else{
            setFormValid(false)
            const setStore:boolean = false
            if(dirRole===directoryRole[0])
            dispatch<any>(setOriginDirShipment(setStore))
            if(dirRole===directoryRole[1])
            dispatch<any>(setDestinyDirShipment(setStore))
        }
  
    }, [
        dispatch,
        user,
        favId,
        dirRole,
        saveInFavs,
        alias,
        fullName,
        //email,
        phone,
        company,
        address,
        zipCode,
        neighborhood,
        country,
        state,
        city,
        reference,
        invalidAlias,
        invalidFullName,
        invalidEmail,
        invalidPhone,
        invalidCompany,
        invalidAddress,
        invalidZipCode,
        invalidNeighborhood,
        invalidCountry,
        invalidState,
        invalidCity,
        formValid,
        invalidRFC
    ]);

    const color = (extZone : {isAvailable : boolean, carriers : Array<carrierExtendedZone>})=>{
        if(invalidZipCode||zipCode == ""){
            return "#BBB"
        }else{
            if(extZone.isAvailable){
                for(let i =0; i < extZone.carriers.length ; i ++){
                    let color = "#39ad87"
                    if(extZone.carriers[i].isExtendedZone)
                        color = "#c23458";
                    return color;
                }
            }else{
                return "#f5b562";
            }
        }
    }

    const zonaExtentidaTooltip = (extZone : {isAvailable : boolean, carriers : Array<carrierExtendedZone>})=>{
        if(invalidZipCode||zipCode == ""){
            return (
                <Typography variant="body2" color="#FFF">
                    <span>
                        Zona Extendida
                    </span><br/><br/>
                    <span>
                        Ingresa un código postal válido
                    </span><br/>
                </Typography>
            )
        }else{
            if(extZone.isAvailable){
                return(
                    <Box>
                        <span>
                            Zona Extendida
                        </span><br/><br/>
                        {
                            extZone.carriers.map((carrier:carrierExtendedZone, index: number)=>{
                                return(
                                    <Typography key={index} variant="body2" color={carrier.isExtendedZone?"#d4b0b1":"#FFF"}>
                                        <span>
                                            {carrier.carrier + " : " + (carrier.isExtendedZone?"Zona Extendida":"Local")}
                                        </span><br/>
                                    </Typography>
                                )
                            })
                        }
                        
                    </Box>
                )
            }else{
                return (
                    <Typography variant="body2" color="#e6dabc">
                        <span>
                            Zona Extendida
                        </span><br/><br/>
                        <span>
                            Este código postal no puede ser validado
                        </span><br/>
                    </Typography>
                )
            }
        }
    }

    const favDirHandler = (e:any, value?:UserDirectoryFav|null|undefined) => {
        if(value){
            const favId = value._id
            const filterFav: Array<UserDirectoryFav> | null = userDirectory ? userDirectory.filter((fav:UserDirectoryFav) => fav._id === favId) : []    
            if( filterFav && filterFav[0]._id){
                const favToUse = filterFav[0]
                setFullName(favToUse.fullName?favToUse.fullName:'')
                //setEmail(favToUse.email?favToUse.email:'')
                setPhone(favToUse.phone?favToUse.phone:'')
                setCompany(favToUse.company?favToUse.company:'')
                setAddress(favToUse.address?favToUse.address:'')
                setAddress2(favToUse.address2?favToUse.address2:'')
                setZipCode(favToUse.zipCode?favToUse.zipCode:'')
                setNeighborhood(favToUse.neighborhood?favToUse.neighborhood:'')
                countryHandler(favToUse.country?favToUse.country:'')
                if(favToUse.state && stateToID(favToUse.state,"satid") != "")
                    stateHandler(favToUse.state?favToUse.state:'')
                setCity(favToUse.city?favToUse.city:'')
                setFavId(favToUse._id?favToUse._id:'')
                setDisableSaveFav(true)
                setSaveInFavs(false)
                zipCodeHandler(favToUse.zipCode?favToUse.zipCode:'')
                setReference(favToUse.reference? favToUse.reference: "");
                setRFC(favToUse.rfc? favToUse.rfc: "");
            }
        }
        if(!value){
            setFullName('')
            //setEmail('')
            setPhone('')
            setCompany('')
            setAddress('')
            setAddress2('')
            setZipCode('')
            setNeighborhood('')
            setCountry('')
            setState('')
            setCity('')
            setFavId('')
            setDisableSaveFav(false)
            setSaveInFavs(false)
            zipCodeHandler('')
        }
        
        return
    }

    const aliasHandler = (e:any) =>{
        e = setNormalize(e)
        setAlias(e)
        setInvalidAlias(validateLengthInput(50,e))
    }

    const fullNameHandler = (e:any) => {
        e = setNormalize(e)
        setFullName(e)
        setInvalidFullName(validateLengthInput(30,e))
    }
    /*const emailHandler = (e:any) => {
        setEmail(e)
        setInvalidEmail(validateEmail(e))
    }*/
    const phoneHandler = (e:any) => {
        setPhone(e)
        setInvalidPhone(validateLengthInput(11,e))
    }
    const companyHandler = (e:any) => {
        e = setNormalize(e)
        setCompany(e)
        setInvalidCompany(validateLengthInput(50,e))
    }
    const RFC_Handler = (e:any) => {
        e = setNormalize(e)
        setRFC(e)
        setInvalidRFC(validateLengthInput(14,e))
    }
    const addressHandler = (e:any) => {
        e = setNormalize(e)
        setAddress(e)
        setInvalidAddress(validateLengthInput(35,e))
    }
    const zipCodeHandler = (e:any) => {
        setZipCode(e)
        let isntvalid = validateExactLengthInput(5,e)
        if(!isntvalid){
            dispatch<any>(getExtendedZone(e, dirRole===directoryRole[0]))
        }else{
            dispatch<any>(setExtendedZone(false ,dirRole===directoryRole[0],[]))
        }
        setInvalidZipCode(isntvalid)
    }

    const direccion2Handler = (e:any) => {
        e = setNormalize(e)
        setAddress2(e)
        setInvalidAddress2(validateLengthInput(35,e))
    }

    const referenceHandler = (e:any) => {
        e = setNormalize(e)
        setReference(e)
        setInvalidReference(validateLengthInput(35,e))
    }
    const neighborhoodHandler = (e:any) => {
        e = setNormalize(e)
        setNeighborhood(e)
        setInvalidNeighborhood(validateLengthInput(57,e))
    }
    
    const countryHandler = (e:any) => {
        setCountry(e);
        setInvalidCountry( e== "")
    }
    const stateHandler = (e:any) => {
        setState(e);
        setInvalidState( e== "");
    }
    const cityHandler = (e:any) => {
        e = setNormalize(e)
        setCity(e)
        setInvalidCity(validateLengthInput(40,e))
    }
    const setSaveInFavsHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const status= event.target.checked
        setSaveInFavs(status)
    };
    const setNormalize = (e:any)=>{
        return e.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    //const referenceHandler = (e:any) => {
      //  setReference(e)
        // setInvalidReference(validateLengthInput(50,e))
    //}
  
    const saveDirectoryHandler = () => {
      dispatch<any>(setLoading(true))
      if( user && formValid){
          let newDirectoryData: UserDirectoryFav = {
            _id: '',
            createdBy: user?user.uid:'',
            createdAt:'',
            alias: alias?alias:'',
            fullName:fullName?fullName:'',
            phone:phone?phone:'',
            zipCode:zipCode?zipCode:'',
            address:address?address:'',
            //email:email?email:'',
            neighborhood:neighborhood?neighborhood:'',
            city:city?city:'',
            state:state?state:'',
            country:country?country:'',
            company:company?company:'',
            reference:reference?reference:'',
            rfc:rfc?rfc:''
          }
          if(user && newDirectoryData) {
            dispatch<any>(createDirectory(newDirectoryData, user))
            clearFields()
            setFormValid(false)
            dispatch<any>(setDialog(false))
            dispatch<any>(setDialogType(''))
          }
      }
    }

    const clearFields = () => {
        setAlias(null)
        setFullName(null)
        //setEmail(null)
        setPhone(null)
        setCompany(null)
        setAddress(null)
        setZipCode(null)
        setNeighborhood(null)
        setCountry(undefined)
        setState(undefined)
        setCity(null)
        setReference(null)
        setRFC(null)
    }

    return (
        <Card elevation={0}>
            <CardContent>
                <Box 
                    component="form"
                    sx={{
                        width:'100%',
                    }}
                    // onSubmit={submitHandler}
                    noValidate
                    autoComplete="off"
                    >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {userDirectory?
                                <Autocomplete
                                    id="country-select-Dir"
                                    options={userDirectory}
                                    autoComplete
                                    clearOnBlur
                                    clearOnEscape
                                    autoHighlight
                                    isOptionEqualToValue={(option: UserDirectoryFav, value: UserDirectoryFav)=> option._id === value._id}
                                    clearText='Borrar'
                                    onChange={(e, value) => favDirHandler(e, value)}
                                    getOptionLabel={(favorite:UserDirectoryFav) => {
                                        if(favorite.alias) 
                                            return favorite.alias
                                        else 
                                            return 'N/A'
                                    }}
                                    renderOption={(props, favorite: UserDirectoryFav) => (
                                        <Box component="li" sx={{p:1}} {...props}>
                                            <Typography variant="body2" sx={{fontWeight:600}}>
                                                { favorite.alias ?
                                                favorite.alias : 'N/A'
                                                }
                                            </Typography>
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        size="small"
                                        color="primary"
                                        placeholder="Buscar dirección"
                                        inputProps={{
                                            ...params.inputProps,
                                            startadornment:<SearchOutlinedIcon fontSize="small" sx={{color:'#000'}}/>,
                                            autoComplete: 'dirección', // disable autocomplete and autofill
                                        }}
                                        />
                                    )}
                                />
                                :null
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={alias}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => aliasHandler(e.currentTarget.value)}
                                label="Alias (Opcional)"
                                type="text"
                                error={invalidAlias}
                                helperText={alias&&invalidAlias ? `Intenta un texto más corto. ${alias.length}/50`:null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={fullName}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => fullNameHandler(e.currentTarget.value)}
                                label="Nombre completo"
                                type="text"
                                error={invalidFullName}
                                helperText={fullName&&invalidFullName ? `Intenta un texto más corto. ${fullName.length}/30`:null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={company}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => companyHandler(e.currentTarget.value)}
                                label="Compañia"
                                type="text"
                                error={invalidCompany}
                                helperText={ company&&invalidCompany ? `Intenta un texto más corto. ${company.length}/50`:null}
                            />
                        </Grid>
                        {/*<Grid item xs={12} md={6}>
                            <TextField
                                variant='standard'
                                color="secondary"
                                value={email}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => emailHandler(e.currentTarget.value)}
                                label="Email"
                                placeholder='jperez@mail.com'
                                type="email"
                                // error={invalidEmail}
                                // helperText={ invalidEmail ? 'Ingresa un correo válido':null}
                            />
                        </Grid>*/}
                        <Grid item xs={12}  md={6}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={address}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => addressHandler(e.currentTarget.value)}
                                label="Dirección y Número"
                                type="text"
                                error={invalidAddress}
                                helperText={ address&&invalidAddress ? `Intenta un texto más corto. ${address.length}/36`:null}
                            />
                        </Grid>
                        <Grid item xs={12}  md={6}>
                            <TextField
                                variant='standard'
                                color="secondary"
                                value={address2}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => direccion2Handler(e.currentTarget.value)}
                                label="Direccion 2"
                                type="text"
                                error={invalidAddress2}
                                helperText={ address2&&invalidAddress2 ? `Intenta un texto más corto. ${address2.length}/36`:null}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={phone}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => phoneHandler(e.currentTarget.value)}
                                label="Teléfono"
                                placeholder='3310987655'
                                type="text"
                                error={invalidPhone}
                                helperText={ invalidPhone ? `Ingresa 10 dígitos y no utilices caracteres especiales`:null}
                            />
                        </Grid>
                        <Grid item xs={10} md={5}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={zipCode}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => zipCodeHandler(e.currentTarget.value)}
                                label="Código Postal"
                                type="text"
                                error={invalidZipCode}
                                helperText={ invalidZipCode ? 'Ingresa 5 digitos y no utilices caracteres especiales':null}
                            />
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <Tooltip
                                title= {zonaExtentidaTooltip(dirRole===directoryRole[0]?originExtendedZone: destinationExtendedZone)}
                                placement="right-end"
                                arrow
                                >
                                <span>
                                    <NotificationImportantIcon sx={{color:color(dirRole===directoryRole[0]?originExtendedZone: destinationExtendedZone)}}/>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl 
                                variant='standard'
                                required
                                fullWidth
                                color="secondary"
                                sx={{my:1}}
                                >
                                <InputLabel id="demo-simple-select-label">Pais</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={"MEX"}
                                    onChange={(e:any) => countryHandler(e.target.value)}
                                    >
                                    {availableCountries.map((country) => (
                                        <MenuItem
                                        key={country}
                                        value={country}
                                        >
                                        {country}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl 
                                variant='standard'
                                required
                                fullWidth
                                color="secondary"
                                sx={{my:1}}
                                >
                                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state}
                                    onChange={(e:any) => stateHandler(e.target.value)}
                                    >
                                    {sendStates.map((states) => (
                                        <MenuItem
                                        key={states.satId}
                                        value={states.label}
                                        >
                                        {states.label}
                                        </MenuItem>
                                    ))}
                                    
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={city}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => cityHandler(e.currentTarget.value)}
                                label="Ciudad"
                                type="text"
                                error={invalidCity}
                                helperText={ city&&invalidCity ? `Intenta un texto más corto. ${city.length}/40`:null}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={neighborhood}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => neighborhoodHandler(e.currentTarget.value)}
                                label="Colonia"
                                type="text"
                                error={invalidNeighborhood}
                                helperText={ neighborhood&&invalidNeighborhood ? `Intenta un texto más corto. ${neighborhood.length}/57`:null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant='standard'
                                //required
                                color="secondary"
                                value={rfc}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => RFC_Handler(e.currentTarget.value)}
                                label="RFC"
                                type="text"
                                error={invalidRFC}
                                helperText={ rfc && invalidRFC ? `El RFC no debe exceder los 13 caracteres. ${rfc.length}/13`:null}
                            />
                        </Grid>
                        <Grid item xs={12}  md={12}>
                            <TextField
                                variant='standard'
                                color="secondary"
                                value={reference}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => referenceHandler(e.currentTarget.value)}
                                label="Referencia"
                                type="text"
                                error={invalidReference}
                                helperText={ reference&&invalidReference ? `Intenta un texto más corto. ${reference.length}/35`:null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        value={saveInFavs}
                                        disabled={disableSaveFav}
                                        onChange={setSaveInFavsHandler} 
                                        color='secondary'  
                                        name="saveInFavs" 
                                    />
                                }
                                label="Guardar en mis direcciones"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    )
}

const MemoizedDirectoryForShipmentForm = React.memo(DirectoryForShipmentForm)

export default MemoizedDirectoryForShipmentForm;