import React, {FC, useState, useEffect, useMemo} from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../store';
import { StoreBasicData, User } from '../../../types/authTypes';
import { CarrierPrice } from '../../../types/storeTypes';
import { ApiConnection} from '../../../types/apiTypes';
import { submitNewShipmentForRedpack, /*submitNewShipmentForRabee,*/ submitNewShipmentForFedex, submitNewShipmentForEstafeta, submitNewShipmentForDHL, fedexRatingSimple, submitNewShipmentForComandoEnviosHappy } from '../../../store/actions/apiActions'
import { setLoading, setOriginDirShipment, setDestinyDirShipment,
  setPackageToShip, setOptionsForShipment, setSelectedPriceCarrier, updateCarrierRatings } from '../../../store/actions/genericActions';
import { formatCurrencyMxn } from '../../../features/functions/genericFunctions';

import MemoizedDirectoryForShipmentForm from '../../UI/forms/DirectoryForShipmentForm';
import MemoizedPackageForShipment from '../../UI/forms/PackageForShipmentForm';
// import ServicesForShipmentForm from '../../UI/forms/ServicesForShipmentForm';
import MemoizedCarrierResultsForShipmentTable from './CarrierResultsForShipmentTable';

import {apiProviders, directionStepsForms, directoryRole, priceScopeTypes} from'../../../features/referenceData/appLists';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {fedexRating} from '../../../store/actions/apiActions'
import {
  EstafetaShipment,
  DHLShipment,
  FedexShipment
} from '../../../types/apiTypes';
import { AnyAction } from 'redux';
import { useGridState } from '@mui/x-data-grid';

const NewShipmentCard: FC = () => {

  const dispatch = useDispatch()
  
  const user: User | null = useSelector((state: RootState) => state.auth.user)
  const userStores:  Array<StoreBasicData>|null = useSelector((state: RootState) => state.stores.stores)
  const {activeApis} = useSelector((state: RootState) => state.apis)
  const carrierPrices: Array<CarrierPrice>|null = useSelector((state:RootState) => state.stores.carrierPrices)
  const {loading, destinyDirectionShipment, originDirectionShipment, packageToShip, selectedPriceCarrier, carrierRatings} = useSelector((state: RootState) => state.generic)

  const [toggleRole, setToggleRole] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [disableFwdButton, setDisableFwdButton] = useState<boolean>(true)
  const [disableClearButton, setDisableClearButton] = useState<boolean>(true)
  const [groupSelected, setGroupSelected] = useState<StoreBasicData|null|undefined>(userStores&&userStores.length>0?userStores[0]:undefined)
  const [carrierstmp, setCarriersTmp] = useState<StoreBasicData|null|undefined>(userStores&&userStores.length>0?userStores[0]:undefined)
  const [countcarriers, setCount] = useState<number>(0)

  const [filteredCarriers, setFilteredCarriers] = useState<Array<CarrierPrice> |null>(null);
  const [fedexListRatin, updateFedexlistRtng] = useState<Array<any>>(new Array<any>())

  const toggleDirRoleHandler = () =>{
    const status = toggleRole
    setToggleRole(!status)
  }

  const handleSteps = (direction:string) => {
    const currentStepValue: number = currentStep
    if (direction===directionStepsForms[0]) { //backwards
      setCurrentStep(currentStepValue-1)
    }
    if (direction===directionStepsForms[1]) { //fordwards
      setCurrentStep(currentStepValue+1)
    }
  }

  useEffect(() =>{
    //enable this piece of code once the Rapi api works!
    // if(user&&!user.rapiApiCredentials&&activeApis){
    //     const rapiApi: Array<ApiConnection> = activeApis.filter((apiConnection:ApiConnection)=> {
    //       return apiConnection.provider === apiProviders[1]
    //     })
    //     dispatch<any>(registerUserEnviosRapiApi(user, rapiApi[0] ))
    // }

    if(!destinyDirectionShipment&&!originDirectionShipment&&!packageToShip&&!selectedPriceCarrier){
      setDisableClearButton(true)
    } else{
      setDisableClearButton(false)
    }

    if(currentStep===1){
      if(
        (destinyDirectionShipment&&originDirectionShipment)
        &&
        (destinyDirectionShipment[0]._id!==originDirectionShipment[0]._id || 
          !destinyDirectionShipment[0]._id || !originDirectionShipment[0]._id)
        &&
        (destinyDirectionShipment[0].phone!==originDirectionShipment[0].phone)
      ){setDisableFwdButton(false)}
      else{setDisableFwdButton(true)}
      //dispatch<any>(updateCarrierRatings(null))
    }
    if(currentStep===2){
      if(
        packageToShip&&packageToShip.qty>0&&
        packageToShip.alto>0&&packageToShip.ancho>0&&
        packageToShip.largo>0&&packageToShip.peso>0
      ){setDisableFwdButton(false)}
      else if(
        !packageToShip||(packageToShip&&
        (packageToShip.qty===0||packageToShip.alto===0||
          packageToShip.ancho===0||packageToShip.largo===0||
          packageToShip.peso===0
        ))
      ){setDisableFwdButton(true)}
    }
    if(currentStep===3&&packageToShip){
      const pesoToUse:number = packageToShip.pesoVol ? Math.max(packageToShip.peso,packageToShip.pesoVol): packageToShip.peso;
      let userPrices = carrierPrices&&carrierPrices.filter((carrier:CarrierPrice, i: number) => {
        return ( //filter active carriers by kg range, global and customer id
          carrier.active === true &&
          (//(carrier.priceScope===priceScopeTypes[0]) ||
          (carrier.priceScope===priceScopeTypes[1]
          &&carrier.priceForCustomer?.uid===user?.uid))
          )
      });
      let carrierstmp = carrierPrices&&carrierPrices.filter((carrier:CarrierPrice, i: number) => {
        return ( //filter active carriers by kg range, global and customer id
          carrier.active === true && 
          ((carrier.priceScope === priceScopeTypes[0] || 
          (carrier.priceScope === priceScopeTypes[1] && carrier.priceForCustomer?.uid===user?.uid))
          ))
      });
      //Eliminado de precios globales
      userPrices?.forEach((element) => {
        carrierstmp?.forEach((element2,i) => {
          if((element2.apiProvider == element.apiProvider) && (element2.serviceType?.name == element.serviceType?.name) && element2.priceScope == "Global"){
            carrierstmp?.splice(i,1)
          }
        });
      });
      carrierstmp?.forEach((cp)=>{
          cp.kgStart =-1
          cp.kgEnd = -1;
          const finalp = packageToShip.pesoVol!= undefined&&packageToShip.peso > packageToShip.pesoVol? packageToShip.peso:packageToShip.pesoVol;
          let st = cp.kilajeArr[0].kgStart;
          let nd = cp.kilajeArr[cp.kilajeArr.length-1].kgEnd
        if(st && finalp && nd && finalp >= st && finalp <= nd){
          cp.kilajeArr.forEach((kg)=>{
            if(finalp && kg.kgStart&& kg.kgEnd && finalp >= kg.kgStart && finalp <= kg.kgEnd && cp.kgStart == -1 && cp.kgEnd == -1){
              cp.kgStart =kg.kgStart
              cp.kgEnd = kg.kgEnd;
              if(!cp.dynamicPrice){
                cp.cost=kg.cost?kg.cost*packageToShip.qty: 0;
                cp.amount = kg.amount?kg.amount*packageToShip.qty: 0;
                cp.priceExtendedZone = kg.priceExtendedZone?kg.priceExtendedZone*packageToShip.qty:0;
                cp.costExtendedZone = kg.costExtendedZone?kg.costExtendedZone*packageToShip.qty:0;
              }else{
                if(cp.IsPercentagedPrice== true)
                  cp.percentage = kg.percentage?kg.percentage:0;
                else
                  cp.amount = kg&&kg.amount?kg.amount:0;
              }
            }
          })
        }
      })
      console.log("countcarriers",countcarriers)
      if(originDirectionShipment && destinyDirectionShipment && packageToShip){
          let dynamicPrices = carrierstmp?.filter((carrier)=> carrier.dynamicPrice);
          let dynamicAPIs = activeApis?.filter((api)=>{
            if(dynamicPrices)
              for(let i = 0; i < dynamicPrices?.length; i++){
                if( api.provider == dynamicPrices[i].apiProvider)
                  return true
              }
              return false
          });
          //console.log(dynamicPrices, dynamicAPIs);
          dynamicAPIs?.forEach((api)=>{
            const providerPrices = dynamicPrices?.filter((price)=> price.apiProvider == api.provider);
            providerPrices?.forEach((dyn)=>{
              if(dyn.apiProvider == "Fedex Jaro" || dyn.apiProvider == "Fedex"){
                if(activeApis){
                  //let api = activeApis.filter((lapi:ApiConnection)=>{return lapi.provider == dyn.apiProvider});
                  let shipment = new FedexShipment(api.production== true?api.prodCredentials.customerNmbr : api.testCredentials.customerNmbr);
                  shipment.updateData(undefined, originDirectionShipment[0], destinyDirectionShipment[0], packageToShip, undefined, undefined)
                  //shipment.setPesoFinal(dyn.kgEnd);
                  fedexRating(api._id, shipment.getRatingDataObj(dyn.serviceType?dyn.serviceType.val:""), (ratings:Array<any>|null)=>{
                    if(ratings){
                      console.log("ratiiiing", dyn.cost, dyn.amount, dyn.carrier, dyn.dynamicPrice, dyn.IsPercentagedPrice  );
                      const fedexListRatings = ratings.filter((r:any)=> {
                        return r.rateType == "LIST"
                      });
                      const fedexAcctRatings = ratings.filter((r:any)=> r.rateType == "ACCOUNT" )
                      if( fedexAcctRatings?.length > 0 ){
                        if(dyn.IsPercentagedPrice)
                          dyn.amount = (fedexAcctRatings[0].totalNetCharge*(1+(dyn.percentage/100)));
                        dyn.cost = fedexAcctRatings[0].totalNetCharge;
                      }else{
                        if(dyn.IsPercentagedPrice)
                          dyn.amount = (fedexListRatings[0].totalNetCharge*(1+(dyn.percentage/100)));
                        dyn.cost = fedexListRatings[0].totalNetCharge;
                      }
                      if(countcarriers < providerPrices.length)
                        setCount(countcarriers + 1)
                    }
                    //updateCarrierRatings({carrier: "Fedex", rating : ratings})
                  })
                }
              }
            })
          })
          setFilteredCarriers(carrierstmp);
      }
      if(!selectedPriceCarrier){
        setDisableFwdButton(true)
      } else {
        setDisableFwdButton(false)
      }
    }
  },[
    currentStep,
    destinyDirectionShipment,
    originDirectionShipment,
    packageToShip,
    selectedPriceCarrier,
    user,
    activeApis,
    carrierRatings,
    carrierPrices, 
    countcarriers
    //filteredCarriers
  ])

  const handleStoreSelected = (event: SelectChangeEvent) => {
    let selectedStoreId = event.target.value as string
    const selectedStoreObj = userStores && userStores.find((store:StoreBasicData) => {
        return store._id === selectedStoreId
    })
    if(selectedStoreObj){
      setGroupSelected(selectedStoreObj)
    }
}

  const generateWaybill = () => {
    dispatch<any>(setLoading(true))
    if(
        user&&originDirectionShipment&&
        destinyDirectionShipment&&packageToShip&&currentStep===3&&
        selectedPriceCarrier&&user.balance&&(user.balance>=selectedPriceCarrier.amount)
      ){
      const originDir = originDirectionShipment[0];
      const destinyDir = destinyDirectionShipment[0];
      const originSaveFav =  originDirectionShipment[1];
      const destinySaveFav =  destinyDirectionShipment[1];
      const apiConnection:Array<ApiConnection> | null = activeApis && activeApis.filter((api:ApiConnection)=> api.provider === selectedPriceCarrier.apiProvider)
      // submit for estafet
      if(selectedPriceCarrier.apiProvider&&apiConnection&&apiConnection[0].provider===apiProviders[0]){
        dispatch<any>(submitNewShipmentForEstafeta(
          originDir,
          destinyDir,
          originSaveFav,
          destinySaveFav,
          packageToShip,
          selectedPriceCarrier,
          apiConnection,
          user,
          groupSelected,
          apiConnection[0]._id
          // optionsForShipment,
        ))
      }
      // submit for Fedex and Fedex jaro
      if(selectedPriceCarrier.apiProvider&&apiConnection&&(apiConnection[0].provider===apiProviders[1]||apiConnection[0].provider===apiProviders[4])){
        dispatch<any>(submitNewShipmentForFedex(
          originDir,
          destinyDir,
          originSaveFav,
          destinySaveFav,
          packageToShip,
          selectedPriceCarrier,
          apiConnection,
          user,
          groupSelected,
          apiConnection[0]._id
          // optionsForShipment,
        ))
      }
      // submit for redPack
      if(selectedPriceCarrier.apiProvider&&apiConnection&&apiConnection[0].provider===apiProviders[2]){
        dispatch<any>(submitNewShipmentForRedpack(
          originDir,
          destinyDir,
          originSaveFav,
          destinySaveFav,
          packageToShip,
          selectedPriceCarrier,
          apiConnection,
          user,
          groupSelected
          // optionsForShipment,
        ))
      }
      // submit for DHL
      if(selectedPriceCarrier.apiProvider&&apiConnection&&apiConnection[0].provider===apiProviders[3]){
        dispatch<any>(submitNewShipmentForDHL(
          originDir,
          destinyDir,
          originSaveFav,
          destinySaveFav,
          packageToShip,
          selectedPriceCarrier,
          apiConnection[0],
          user,
          groupSelected,
          apiConnection[0]._id
          // optionsForShipment,
        ))
      }
      if(selectedPriceCarrier.apiProvider&&apiConnection&&apiConnection[0].provider===apiProviders[5]){//------------------------------------
        dispatch<any>(submitNewShipmentForComandoEnviosHappy(
          originDir,
          destinyDir,
          originSaveFav,
          destinySaveFav,
          packageToShip,
          selectedPriceCarrier,
          apiConnection,
          user,
          groupSelected,
          apiConnection[0]._id
          // optionsForShipment,
        ))
      }
      
      //submit for Rabee
      /*if(selectedPriceCarrier.apiProvider&&apiConnection&&apiConnection[0].provider===apiProviders[4]){ //discontinued
          dispatch<any>(submitNewShipmentForRabee(
            originDir,
            destinyDir,
            originSaveFav,
            destinySaveFav,
            packageToShip,
            selectedPriceCarrier,
            apiConnection,
            user,
            groupSelected
            // optionsForShipment,
          ))
      }*/
    }
    
  }

  const clearFields = () =>{
    const setStore: boolean = false
    dispatch<any>(setOriginDirShipment(setStore))
    dispatch<any>(setDestinyDirShipment(setStore))
    dispatch<any>(setPackageToShip(setStore))
    dispatch<any>(setOptionsForShipment(setStore))
    dispatch<any>(setSelectedPriceCarrier(setStore))
    setCurrentStep(1)
  }

  return (
    <Container maxWidth='xl' sx={{bgcolor:'#F4F7F9', minHeight:'100vh'}}>
      <Toolbar sx={{mb:3}}/>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{width:'100%', mb:2}}>
            <Typography 
                component="h6" 
                variant="h6" 
                sx={{fontWeight:500}}>
                Crear un envío
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Card elevation={0} sx={{borderRadius:'30px', mb:2}}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={5}>
              <Box className="d-flex-col-start-centered" sx={{height:'100%'}}>
                <Typography align="center" variant="subtitle2" component="h5" sx={{color:'grey.700'}}>
                  Tu saldo
                </Typography>
                <Typography align="center" variant="h6" color="secondary" sx={{fontWeight:'800'}}> 
                  {user && user.balance ? formatCurrencyMxn(user.balance) : '0MXN'}
                </Typography>
              </Box>
              {/* <Box sx={{width:'100%', height:'100%'}} className="d-flex-row-start-centered">
                <FormControl
                    sx={{bgcolor:'white', width:{xs:'100%', md:'50%'}}}
                    size={'small'}
                    color='secondary'
                    >
                    <InputLabel id="select-store-label">Selecciona un grupo</InputLabel>
                    <Select
                        labelId="select-store-label"
                        id="select-store"
                        value={
                          groupSelected ? 
                          groupSelected._id :
                          'Selecciona una tienda'
                        }
                        label="Selecciona un grupo"
                        type='string'
                        onChange={(e) => handleStoreSelected(e)}
                        >
                        <MenuItem selected disabled value="Selecciona un grupo">
                            Selecciona un grupo
                        </MenuItem>
                        {userStores && userStores.map((store:StoreBasicData, index:number) => {
                            return(
                                <MenuItem
                                    key={index}
                                    value={store._id}>
                                        {store.storeName}
                                </MenuItem> 
                            )
                        })}
                    </Select>
                </FormControl>
              </Box> */}
            </Grid>
            <Grid item xs={12} md={7}>
                <CardActions className="d-flex-row-end-centered">
                  <LoadingButton
                    loading={loading}
                    color="secondary"
                    disabled={disableClearButton}
                    sx={{my:1}}
                    variant="text"
                    onClick={clearFields}
                  >
                    Limpiar campos
                  </LoadingButton>
                  {currentStep>1?
                    <LoadingButton
                      loading={loading}
                      color="secondary"
                      sx={{my:1, bgcolor:'grey.50'}}
                      variant="text"
                      onClick={()=>handleSteps(directionStepsForms[0])}
                    >
                      Atrás
                    </LoadingButton>
                    :null
                  }
                  <LoadingButton
                      loading={loading}
                      disabled={disableFwdButton}
                      color="secondary"
                      disableElevation
                      sx={{my:1}}
                      variant="contained"
                      onClick={
                        ()=>
                        currentStep===3&&selectedPriceCarrier?
                        generateWaybill()
                        :
                        handleSteps(directionStepsForms[1])
                      }
                      >
                      {
                      currentStep===1?
                        `Siguiente`
                        :
                      currentStep===2?
                        'Obtener precios'
                        :
                      currentStep===3?
                        'Generar guía'
                        :
                        ''
                      }
                    </LoadingButton>
                </CardActions>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {user?
        <Grid container spacing={2}>
            {currentStep===1?
              <Grid item xs={12} md={6}>
                  <Card elevation={0} sx={{borderRadius:'30px'}}>
                    <CardContent>
                      <Box sx={{p:0, m:0}} className="d-flex-row-start-between">
                        <Typography variant="subtitle1" sx={{fontWeight:600}}>
                          {!toggleRole?directoryRole[0]:directoryRole[1]} (Paso {currentStep} de 3)
                        </Typography>
                        <Tooltip title="Cambiar direcciones">
                          <span>
                            <IconButton 
                              disabled={destinyDirectionShipment===null||originDirectionShipment===null} 
                              size="small" onClick={toggleDirRoleHandler} >
                                <SyncAltIcon 
                                  sx={{
                                    color:destinyDirectionShipment===null||originDirectionShipment===null ? 'grey.400':'secondary.main'}} 
                                />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                      <MemoizedDirectoryForShipmentForm 
                        dirRole={!toggleRole?directoryRole[0]:directoryRole[1]}
                        />
                    </CardContent>
                  </Card>
              </Grid>
              :null
            }
            {currentStep===1?
              <Grid item xs={12} md={6}>
                    <Card elevation={0} sx={{borderRadius:'30px'}}>
                      <CardContent>
                        <Typography variant="subtitle1" sx={{fontWeight:600}}>
                          {!toggleRole?directoryRole[1]:directoryRole[0]}
                        </Typography>
                        <MemoizedDirectoryForShipmentForm
                          dirRole={!toggleRole?directoryRole[1]:directoryRole[0]}
                          />
                      </CardContent>
                  </Card>
              </Grid>
              :null
            }
            {currentStep===2?
              <Grid item xs={12}>
                    <Card elevation={0} sx={{borderRadius:'30px'}}>
                      <CardContent>
                        <Typography variant="subtitle1" sx={{fontWeight:600}}>
                          Información del paquete (paso {currentStep} de 3)
                        </Typography>
                        <MemoizedPackageForShipment />
                      </CardContent>
                  </Card>
              </Grid>
              :null
            }
            {currentStep===3&&filteredCarriers?
              <Grid item xs={12}>
                    <Card elevation={0} sx={{borderRadius:'30px'}}>
                      <CardContent>
                        <Typography variant="h6" sx={{fontWeight:600, mb:2}}>
                          Resultados
                        </Typography>
                        <MemoizedCarrierResultsForShipmentTable quoteOnly={false} carrierResults={filteredCarriers} groupSelected={groupSelected} qty = {packageToShip&&packageToShip.qty?packageToShip.qty:1}/>
                      </CardContent>
                  </Card>
              </Grid>
              :null
            }
            <Grid item xs={12}>
              <Card elevation={0} sx={{borderRadius:'30px'}}>
                  <CardActions className="d-flex-row-end-centered">
                    {currentStep>1?
                      <LoadingButton
                        loading={loading}
                        color="secondary"
                        sx={{my:1, bgcolor:'grey.50'}}
                        variant="text"
                        onClick={()=>handleSteps(directionStepsForms[0])}
                      >
                        Atrás
                      </LoadingButton>
                      :null
                    }
                    <LoadingButton
                      loading={loading}
                      disabled={disableFwdButton}
                      color="secondary"
                      disableElevation
                      sx={{my:1}}
                      variant="contained"
                      onClick={
                        ()=>
                        currentStep===3&&selectedPriceCarrier?
                        generateWaybill()
                        :
                        handleSteps(directionStepsForms[1])
                      }
                      >
                      {
                      currentStep===1?
                        `Siguiente`
                        :
                      currentStep===2?
                        'Obtener precios'
                        :
                      currentStep===3?
                        'Generar guía'
                        :
                        ''
                      }
                    </LoadingButton>
                  </CardActions>
              </Card>
            </Grid>
        </Grid>
        : null
      }
    </Container>
  )
}

const MemoizedNewShipmentCard = React.memo(NewShipmentCard)

export default MemoizedNewShipmentCard
