import React, { FC, useState, FormEvent, useEffect,  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AlertMsg from '../UI/AlertMessage';
import { sendPasswordReset } from '../../store/actions/authActions';
import { setError, setSuccess } from '../../store/actions/genericActions';
import { RootState } from '../../store'; 

import PublicHeader from './public/PublicHeader';
import PublicFooter from './public/PublicFooter';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

const ForgotPassword: FC = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    // const [errorEmail, setErrorEmail] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { error, success } = useSelector((state: RootState) => state.generic);

    useEffect(() => {
        return() => {
            if(error) {
                dispatch<any>(setError(''))
            }
            if(success) {
                dispatch<any>(setSuccess(''))
            }
        }
    }, [error, dispatch, success]);

    const submitHandler = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        dispatch<any>(sendPasswordReset(email, "Enviamos un link al correo registrado."));
        setLoading(false);

    }

    const backHomeHandler = () =>{
        navigate('/')
    }

    return(
        <Container maxWidth='xl' className="mt-off-bar">
            <PublicHeader color="black" includeHomeBttn={true}/> 
            <Grid container direction="row" justify-content="flex-start" alignItems="flex-start" spacing={.75}>
                <Grid item mx="auto" my="auto" md={6} xs={12}>
                    <Card
                        sx={{p:2}}
                        className="border-card-basic" 
                        elevation={0}
                    >
                        <CardContent>
                            <Typography align="center" sx={{fontWeight: '500'}} variant="h6">
                                Recuperar tu contraseña
                            </Typography>
                            <Typography align="center" variant="body1" sx={{my:3}}>
                                Enviaremos un link de recuperación al correo que tienes registrado.
                            </Typography>
                            <Link
                            component="button"
                            align='center'
                            sx={{width:'100%', my:1, mb:6}}
                            variant='body1'
                            onClick={() => {
                                backHomeHandler()
                            }}
                            >
                               Regresar
                            </Link>
                            <form action="" method="post" onSubmit={submitHandler}>
                                {error && <AlertMsg severity="danger" message={error}></AlertMsg>}
                                {success && <AlertMsg severity="success" message={success}></AlertMsg>}
                                <TextField
                                    required
                                    color="secondary"
                                    value={email}
                                    sx={{mb:2}}
                                    id="outlined-email"
                                    onChange={(e:any) => setEmail(e.currentTarget.value)}
                                    placeholder='jperez@email.com'
                                    autoComplete="Correo"
                                    fullWidth
                                    label="Correo electrónico"
                                    variant="standard"
                                    type="email"
                                />
                                <LoadingButton 
                                    type="submit"
                                    fullWidth
                                    disableElevation
                                    color="secondary"
                                    variant="contained"
                                    sx={{mt:5}}
                                    size="large"
                                >
                                    {loading ? "Creando link..." : "Enviar link"}
                                </LoadingButton>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <PublicFooter includeHomeBttn={true}/>
        </Container>
    )
}

export default ForgotPassword;