import React, { FC, useEffect  } from 'react';
import './App.css';

import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { auth } from './firebase/config';
import { RootState } from './store';
import { getUserById, setNeedVerification, } from './store/actions/authActions';
import { setLoading } from './store/actions/genericActions';
import { ThemeProvider } from '@mui/material/styles';
import { omTheme } from './theme';

import Homepage from './components/pages/public/Homepage';
import TyC from './components/pages/public/TyC';
import AvisoPrivacidadInt from './components/pages/public/AvisoPrivacidadInt';
import Policy from './components/pages/public/Policy';
import SignIn from './components/pages/SignIn';
import Header from './components/sections/Header'
import LeftDrawer from './components/sections/LeftDrawer';
import Dashboard from './components/pages/Dashboard';
import StoresSummary from './components/pages/stores/StoresSummary';
import UserProfile from './components/pages/user/UserProfile';
import AdminSummary from './components/pages/admin/AdminSummary';
import StoreProfile from './components/pages/stores/StoreProfile';
import SubmitComission from './components/pages/comissions/SubmitComission';
import QuoterFormCard from './components/pages/quoter/QuoterFormCard';
import MemoizedNewShipmentCard from './components/pages/shipments/NewShipmentCard';
import AdminBalanceCard from './components/pages/balance/AdminBalancePage';
import MyShipmentsSummary from './components/pages/shipments/MyShipmentsSummary';
import DirectoryPage from './components/pages/directory/DirectoryPage';
import DrawerRight from './components/sections/DrawerRight';
import ForgotPassword from './components/pages/ForgotPassword';
import Box from '@mui/material/Box'

const App: FC = () => {

  const dispatch = useDispatch();
  const { authenticated } = useSelector((state: RootState) => state.auth);
  const { rightDrawer } = useSelector((state: RootState) => state.generic);

  useEffect(() => {
    dispatch<any>(setLoading(true));
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if(user && user.uid) {
        await dispatch<any>(getUserById(user.uid, user.isAnonymous));
        if (!user.emailVerified) {
          dispatch<any>(setNeedVerification());
        }
      }
      dispatch<any>(setLoading(false));
    });

    return () => {
      unsubscribe();
    }

  }, [dispatch, authenticated])

  return (
    <ThemeProvider theme={omTheme}>
        <Router>
          {authenticated ? <Header/> : null}
          <Box sx={{display:'flex'}} component='div'>
            {authenticated ? <LeftDrawer/> : null}
            { rightDrawer ? 
                <DrawerRight />
                : null

              }
            <Routes>
              Not auth routes
              <Route path="/" element={!authenticated ? (<Homepage />) : (<Navigate to="/dashboard" />)}/>
              {/* <Route path="/" element={!authenticated ? (<SignIn />) : (<Navigate to="/dashboard" />)}/> */}
              <Route path="/sign-in" element={!authenticated ? (<SignIn />) : (<Navigate to="/dashboard" />)}/>
              <Route path="/retrieve-password" element={!authenticated ? (<ForgotPassword />) : (<Navigate to="/dashboard" />)}/>
              <Route path="/tyc" element={!authenticated ? (<TyC />) : (<Navigate to="/dashboard" />)}/>
              <Route path="/aviso-integral" element={!authenticated ? (<AvisoPrivacidadInt />) : (<Navigate to="/dashboard" />)}/>
              <Route path="/politicas" element={!authenticated ? (<Policy />) : (<Navigate to="/dashboard" />)}/>
              Auth routes
              <Route path="/dashboard" element={authenticated ? (<Dashboard />) : (<Navigate to="/" />)}/>
              <Route path="/newShipment" element={authenticated ? (<MemoizedNewShipmentCard />) : (<Navigate to="/" />)}/>
              <Route path="/quoter" element={authenticated ? (<QuoterFormCard />) : (<Navigate to="/" />)}/>
              <Route path="/balance" element={authenticated ? (<AdminBalanceCard />) : (<Navigate to="/" />)}/>
              <Route path="/myShipments" element={authenticated ? (<MyShipmentsSummary />) : (<Navigate to="/" />)}/>
              <Route path="/directory" element={authenticated ? (<DirectoryPage />) : (<Navigate to="/" />)}/>
              <Route path="/submitComission" element={authenticated ? (<SubmitComission />) : (<Navigate to="/" />)}/>
              <Route path="/stores" element={authenticated ? (<StoresSummary />) : (<Navigate to="/" />)}/>
              <Route path="/storeProfile" element={authenticated ? (<StoreProfile />) : (<Navigate to="/" />)}/>
              <Route path="/editStore" element={authenticated ? (<StoresSummary />) : (<Navigate to="/" />)}/>
              <Route path="/userProfile" element={authenticated ? (<UserProfile />) : (<Navigate to="/" />)}/>
              <Route path="/admin" element={authenticated ? (<AdminSummary />) : (<Navigate to="/" />)}/>
            </Routes>
          </Box>
        </Router>
      </ThemeProvider>
  );
}

export default App;
