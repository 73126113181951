import React, {FC} from 'react'
import {useNavigate} from 'react-router-dom'

import EHIsotipoWhite from '../../UI/assets/EHIsotipoWhite'

import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'

interface FooterProps {
  includeHomeBttn?: boolean
}

const PublicFooter:FC<FooterProps> = (props) => {
  const{includeHomeBttn} = props
  const navigate = useNavigate()
  const handleGoToTyC = () => {
    navigate('/tyc')
  }
  const handlePrivacyIntegral= () => {
    navigate('/aviso-integral')
  }
  const handlePolicy= () => {
    navigate('/politicas')
  }
  const handleGoHome = () =>{
    navigate('/')
}
  return (
    <Toolbar disableGutters sx={{mx:'auto', pt:7, pb:25}} className='linear-gradient-bg'>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box sx={{pl:{md:26, xs:4}, pr:4}}>
            <EHIsotipoWhite width={40} height={40} />
            <Typography variant='h6' sx={{fontWeight:600, color:'white', mb:3}}>
              EnviosHappy
            </Typography>
            <Typography variant="body2" sx={{color:'white'}}>
              Dionisio Rodríguez #500
            </Typography>
            <Typography variant="body2" sx={{color:'white'}}>
              Col. San Juan de Dios, Sector Reforma 44360
            </Typography>
            <Typography variant="body2" sx={{color:'white'}}>
              Guadalajara, Jalisco
            </Typography>
            <Typography variant="body2" sx={{color:'white'}}>
              hola@envioshappy.com
            </Typography>
            <Typography variant="body2" sx={{color:'white'}}>
              +52 33  1402  7895
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {includeHomeBttn?
            <Box sx={{pl:{md:26, xs:4}, pr:4}}>
              <LoadingButton onClick={handleGoHome} variant="text" size='small' disableRipple disableElevation sx={{color:'white'}}>
                Inicio
              </LoadingButton>
            </Box>
            :null
          }
          <Box sx={{pl:{md:26, xs:4}, pr:4}}>
            <LoadingButton onClick={handleGoToTyC} variant="text" size='small' disableRipple disableElevation sx={{color:'white'}}>
              Aviso de Privacidad
            </LoadingButton>
          </Box>
          <Box sx={{pl:{md:26, xs:4}, pr:4}}>
            <LoadingButton onClick={handlePrivacyIntegral} variant="text" size='small' disableRipple disableElevation sx={{color:'white'}}>
              Aviso de Privacidad Integral
            </LoadingButton>
          </Box>
          <Box sx={{pl:{md:26, xs:4}, pr:4}}>
            <LoadingButton onClick={handlePolicy} variant="text" size='small' disableRipple disableElevation sx={{color:'white'}}>
              Politica de Derechos
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  )
}

export default PublicFooter