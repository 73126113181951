import React, { FC }from 'react';
import { useSelector } from 'react-redux';

import {roleTypeArray} from '../../../features/referenceData/userLists';
import {formatCurrencyMxn } from '../../../features/functions/genericFunctions';

import { RootState } from '../../../store';
import {User} from '../../../types/authTypes'

import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';


const AdminBalanceCard:FC = () => {

    const user: User | null = useSelector((state: RootState) => state.auth.user)
    const userBalance: string = user && user.balance ? formatCurrencyMxn(user.balance) : '$0 MXN'
    const userDeposit: string = user && user.abono ? formatCurrencyMxn(user.abono) : '$0 MXN'
    const userPendingToPay: string = user && user.abono && user.balance ? formatCurrencyMxn(user.balance-user.abono) : '$0 MXN'


    return (
        <Card elevation={0} sx={{borderRadius:'30px'}}>
            <CardContent>
                <Grid container spacing={1} >
                    <Grid item xs={12} md={4}>
                        <Box sx={{my:2}} className="d-flex-col-center-centered">
                            <Typography align="center" variant="h6" component="h5" sx={{color:'grey.700'}}>
                                Tu saldo
                            </Typography>
                            <Typography align="center" variant="h5" sx={{fontWeight:'800', my:'auto'}}> 
                                {userBalance}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{my:2}} className="d-flex-col-center-centered">
                            <Typography align="center" variant="h6" component="h5" sx={{color:'grey.700'}}>
                                Tu abono
                            </Typography>
                            <Typography align="center" variant="h5" sx={{fontWeight:'800', my:'auto'}}> 
                                {userDeposit}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{my:2}} className="d-flex-col-center-centered">
                            <Typography align="center" variant="h6" component="h5" sx={{color:'grey.700'}}>
                                Por pagar
                            </Typography>
                            <Typography align="center" variant="h5" sx={{fontWeight:'800', my:'auto'}}> 
                                {userPendingToPay}
                            </Typography>
                        </Box>
                    </Grid>
                    {user?.role === roleTypeArray[1] || user?.role === roleTypeArray[2]?
                        null
                        :
                        <Grid item xs={12}>
                            <Typography variant='subtitle1' align='center'>
                                Para agregar saldo o reportar un abono, por favor comunícate al teléfono 332-1231-583 
                            </Typography>   
                        </Grid>
                    }
                    
                </Grid>
            </CardContent>
        </Card>
    )
}

export default AdminBalanceCard