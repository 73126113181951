import React, {FC, useState, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridRenderCellParams} 
from '@mui/x-data-grid';

import {RootState}from'../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { CarrierPrice } from '../../../types/storeTypes';
import { User } from '../../../types/authTypes';
import { ApiConnection } from '../../../types/apiTypes'
import { formatCurrencyMxn } from '../../../features/functions/genericFunctions';
import { submitNewShipmentForRabee } from '../../../store/actions/apiActions';
import { setLoading, setSelectedPriceCarrier } from '../../../store/actions/genericActions';
import { grid_esES_Text }from '../../../features/referenceData/reqTableDef';
import { priceScopeTypes } from '../../../features/referenceData/appLists';

import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import PublicIcon from '@mui/icons-material/Public';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

interface CarriersFoundProps {
    carrierResults: any
    groupSelected:any
    quoteOnly:boolean
    qty: number
}

const CarrierResultsForShipmentTable: FC<CarriersFoundProps> = (props) => {
  const {carrierResults, groupSelected, quoteOnly, qty} = props
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const  user:User|null = useSelector((state: RootState) => state.auth.user);
  const {activeApis} = useSelector((state: RootState) => state.apis)
  const { loading, destinyDirectionShipment, originDirectionShipment, packageToShip, optionsForShipment } = useSelector((state: RootState) => state.generic);
  const [pageSize, setPageSize] = useState<number>(10);
  const [columns, setColumns] = useState<any>()
  const [selectedPrice, setSelectedPrice] = useState<any>()
  const rows = carrierResults;

  useMemo(() => {
    let reqColDef: GridColDef[] = user?.role == "Admin"?
    [
      {field:'actions', headerName:'Seleccionar', align:'center', sortable:true, width:120, renderCell: ((params: GridRenderCellParams) => {
        const totalPrice:number = params.row.priceExtendedZone&&params.row.priceExtendedZone>0 ? 
          params.row.amount+params.row.priceExtendedZone : params.row.amount
        const enoughUserBalance: boolean = user&&user.balance&&user.balance>=totalPrice? true:false
        const disableButtonIfNoBalance: boolean = !quoteOnly&&user&&user.balance&&(user.balance<params.row.amount) ? true:false
        const navigateNewShipmentHandler = ()=>{
          navigate('/newShipment')
        } // for quote only

        const handleSelectedPrice = (e:boolean, selectedCarrier: CarrierPrice) =>{
          setSelectedPrice(selectedCarrier)
          const setStore:boolean = true
          dispatch<any>(setSelectedPriceCarrier(setStore, selectedCarrier))
        }
        return(
          <Box sx={{width:'100%'}}>
            {quoteOnly ? 
              <Tooltip 
                title={
                  'Crear envío'
                } 
                placement='top'>
                <span>      
                    <LoadingButton
                        loading={loading}
                        color="secondary"
                        variant="contained"
                        disableElevation
                        size='small'
                        disabled={disableButtonIfNoBalance}
                        onClick={navigateNewShipmentHandler}
                    >
                      Crear envío
                    </LoadingButton>
                  </span>
                </Tooltip>
                :
                <Tooltip
                  placement='top'
                  title={
                    enoughUserBalance ?
                      'Se descontará el total de tu balance actual'
                      :
                      'Tu saldo actual no es suficiente para generar esta guía'
                  }
                >
                  <span>
                    <RadioGroup
                      key={params.row._id}
                      value={selectedPrice&&selectedPrice._id===params.row._id?selectedPrice._id:''}
                      onChange={(e:any) => handleSelectedPrice(e.currentTarget.checked, params.row)}
                      defaultValue=""
                    >
                      <FormControlLabel
                        label=''
                        control={
                          <Radio
                            disabled={!enoughUserBalance || params.row.kgStart < 0}
                            value={params.row._id}
                            sx={{ml:4}}
                            color="secondary" 
                          />
                        }
                      />
                    </RadioGroup>
                  </span>
                </Tooltip>
            }
          </Box>
        )
      })
      },
      {field:'priceScope', headerName: 'Tipo de precio', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
          return(
            <Box className="d-flex-row-center-centered">
              <Typography variant="body2" component="span">
                {params.row.priceScope === priceScopeTypes[0] ?
                  <PublicIcon fontSize="small" sx={{color:'grey.600', fontSize:18}} />
                  :
                  <PersonOutlineIcon fontSize='small' sx={{color:'grey.600', fontSize:18}} />
                }
              </Typography>
              <Typography variant="body2" component="span" className='capitalized-first' sx={{fontWeight:500, ml:.5}}>
                {params.row.priceScope}
              </Typography>
            </Box>
          )
        })
      },
      {field:'carrier', headerName: 'Carrier', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:600}}>
              {params.row.carrier}
            </Typography>
          )
        })
      },
      {field:'peso', headerName: 'Kilos', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
        return(
          <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
            {params.row.kgStart>=0&&params.row.kgEnd>=0?params.row.kgStart+"kg - "+params.row.kgEnd+" kgs":"Peso no Disponible"}
          </Typography>
        )
      })
    },
      {field:'totalPrice', headerName: 'Total (MXN)', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
          const totalPrice:string = formatCurrencyMxn(params.row.priceExtendedZone&&params.row.priceExtendedZone>0 ? 
          (params.row.amount+params.row.priceExtendedZone) * qty : params.row.amount * qty)
          return(
            <Typography variant="body2" className='capitalized-first' color="secondary" sx={{fontWeight:600}}>
              {totalPrice}
            </Typography>
          )
        })
      },
      {field:'amount', headerName: 'Precio (MXN)', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
          const amount:string = formatCurrencyMxn(params.row.amount)
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
              {amount}
            </Typography>
          )
        })
      },
      {field:'priceExtendedZone', headerName: 'Precio zona Extendida (MXN)', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
          const priceExt:string = formatCurrencyMxn(params.row.priceExtendedZone?params.row.priceExtendedZone:0)
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
              {priceExt}
            </Typography>
          )
        })
      },
        {field:'costototal', headerName: 'Costo total', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
          const amount:string = formatCurrencyMxn(params.row.cost?  params.row.cost * qty: 0)
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
              {amount}
            </Typography>
          )
        })
      },
      {field:'costo', headerName: 'Costo Unitario', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
          const amount:string = formatCurrencyMxn(params.row.cost?params.row.cost:0)
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
              {amount}
            </Typography>
          )
        })
      },
      {field:'name', headerName: 'Nombre del servicio', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
              {params.row.name}
            </Typography>
          )
        })
      },
      {field:'serviceType', headerName: 'Tipo de Servicio', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
              {params.row.serviceType.name?params.row.serviceType.name:"INVALID"}
            </Typography>
          )
        })
      },
      {field:'days', headerName: 'Días', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
              {params.row.days}
            </Typography>
          )
        })
      }
    ]
    :
    [
      {field:'actions', headerName: 'Seleccionar', align:'center', sortable:true, width:120, renderCell: ((params: GridRenderCellParams) => {
        const totalPrice:number = params.row.priceExtendedZone&&params.row.priceExtendedZone>0 ? 
          params.row.amount+params.row.priceExtendedZone : params.row.amount
        const enoughUserBalance: boolean = user&&user.balance&&user.balance>=totalPrice? true:false
        const disableButtonIfNoBalance: boolean = !quoteOnly&&user&&user.balance&&(user.balance<params.row.amount) ? true:false
        const navigateNewShipmentHandler = ()=>{
          navigate('/newShipment')
        } 
        // for quote only
        const handleSelectedPrice = (e:boolean, selectedCarrier: CarrierPrice) =>{
          setSelectedPrice(selectedCarrier)
          const setStore:boolean = true
          dispatch<any>(setSelectedPriceCarrier(setStore, selectedCarrier))
        }
        return(
          <Box sx={{width:'100%'}}>
            {quoteOnly ? 
              <Tooltip 
                title={
                  'Crear envío'
                } 
                placement='top'>
                <span>      
                    <LoadingButton
                        loading={loading}
                        color="secondary"
                        variant="contained"
                        disableElevation
                        size='small'
                        disabled={disableButtonIfNoBalance}
                        onClick={navigateNewShipmentHandler}
                    >
                      Crear envío
                    </LoadingButton>
                  </span>
                </Tooltip>
                :
                <Tooltip
                  placement='top'
                  title={
                    enoughUserBalance ?
                      'Se descontará el total de tu balance actual'
                      :
                      'Tu saldo actual no es suficiente para generar esta guía'
                  }
                >
                  <span>
                    <RadioGroup
                      key={params.row._id}
                      value={selectedPrice&&selectedPrice._id===params.row._id?selectedPrice._id:''}
                      onChange={(e:any) => handleSelectedPrice(e.currentTarget.checked, params.row)}
                      defaultValue=""
                    >
                      <FormControlLabel
                        label=''
                        control={
                          <Radio
                            disabled={!enoughUserBalance}
                            value={params.row._id}
                            sx={{ml:4}}
                            color="secondary" 
                          />
                        }
                      />
                    </RadioGroup>
                  </span>
                </Tooltip>
            }
          </Box>
        )
      })
    },
      {field:'priceScope', headerName: 'Tipo de precio', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
          return(
            <Box className="d-flex-row-center-centered">
              <Typography variant="body2" component="span">
                {params.row.priceScope === priceScopeTypes[0] ?
                  <PublicIcon fontSize="small" sx={{color:'grey.600', fontSize:18}} />
                  :
                  <PersonOutlineIcon fontSize='small' sx={{color:'grey.600', fontSize:18}} />
                }
              </Typography>
              <Typography variant="body2" component="span" className='capitalized-first' sx={{fontWeight:500, ml:.5}}>
                {params.row.priceScope}
              </Typography>
            </Box>
          )
        })
      },
      {field:'carrier', headerName: 'Carrier', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:600}}>
              {params.row.carrier}
            </Typography>
          )
        })
      },
      {field:'totalPrice', headerName: 'Total (MXN)', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
          const totalPrice:string = formatCurrencyMxn(params.row.priceExtendedZone&&params.row.priceExtendedZone>0 ? 
          (params.row.amount+params.row.priceExtendedZone) * qty : params.row.amount * qty)
          return(
            <Typography variant="body2" className='capitalized-first' color="secondary" sx={{fontWeight:600}}>
              {totalPrice}
            </Typography>
          )
        })
      },
      {field:'amount', headerName: 'Precio (MXN)', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
          const amount:string = formatCurrencyMxn(params.row.amount)
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
              {amount}
            </Typography>
          )
        })
      },
      {field:'priceExtendedZone', headerName: 'Precio zona Extendida (MXN)', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
          const priceExt:string = formatCurrencyMxn(params.row.priceExtendedZone?params.row.priceExtendedZone:0)
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
              {priceExt}
            </Typography>
          )
        })
      },
      {field:'name', headerName: 'Nombre del servicio', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
              {params.row.name}
            </Typography>
          )
        })
      },
      {field:'serviceType', headerName: 'Tipo de Servicio', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
              {params.row.serviceType.name?params.row.serviceType.name:"INVALID"}
            </Typography>
          )
        })
      },
      {field:'days', headerName: 'Días', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
          return(
            <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
              {params.row.days}
            </Typography>
          )
        })
      }
    ]
      const columns: GridColDef[] = reqColDef;
      setColumns(columns)
  },[
    user,
    loading,
    // originDirectionShipment,
    // destinyDirectionShipment,
    // packageToShip,
    // optionsForShipment,
    // activeApis,
    // groupSelected,
    quoteOnly,
    selectedPrice,
    navigate,
    dispatch,
  ])    

  return (
      <div style={{ height: '85vh', width: '100%', backgroundColor:'white' }}>
          <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1, overflowY: 'scroll' }}>
                  <DataGrid
                      localeText={grid_esES_Text}
                      rows={rows}
                      columns={columns}
                      getRowId={(row)=>row._id}
                      density='compact'
                      disableSelectionOnClick
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[10, 20, 40]}
                      pagination
                  />
              </div>
          </div>
      </div>
  )
}

const MemoizedCarrierResultsForShipmentTable = React.memo(CarrierResultsForShipmentTable)

export default MemoizedCarrierResultsForShipmentTable