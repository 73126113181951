import React, {FC} from 'react'
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import { User } from "../../../types/authTypes"
import { roleTypeArray } from '../../../features/referenceData/userLists';
import { translateDate, translateDateFromDb } from '../../../features/functions/handleDates'

import AvatarCustomed from '../../UI/AvatarCustomed';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import LoadingButton from '@mui/lab/LoadingButton';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

interface UserCardParams {
    user: User,
    currentUser:boolean
}
const UserCard: FC<UserCardParams> = (props) => {

    const{user, currentUser} = props;
    const { loading } = useSelector((state: RootState) => state.generic);

    return (
        <Box sx={{width:'100%'}}>
            <Card elevation={2} sx={{p:2.5}}>
                <CardContent className="d-flex-row-start-start">
                    {user ? 
                        <AvatarCustomed 
                            userName={user.fullName} 
                            size={72} 
                            userPhoto={user.userAvatar}  
                            fontWeight={600}
                            fontSize={28}
                        />
                        :null
                    }
                    <Box sx={{ml:2}}>
                        <Typography variant='h6' sx={{fontWeight:700}}>
                            {user.fullName}
                        </Typography>
                        <Typography variant='body2' sx={{fontWeight:500}}>
                            {user.email}
                        </Typography>
                        { user.phoneNumber ?
                            <Typography variant='body2' sx={{fontWeight:500}}>
                                Tel: {user.phoneNumber}
                            </Typography>
                            :null
                        }
                    </Box>
                </CardContent>
                <Stack sx={{mt:1, px:2}} justifyContent="flex-start" alignItems="center" direction="row" spacing={1}>
                    {/* <Chip 
                        size="small"
                        icon={<CakeOutlinedIcon />}
                        sx={{bgcolor:'transparent', textTransform:'capitalize'}}
                        label={user.birthday ? translateDate(translateDateFromDb(user.birthday)) : 'Sin fecha de nac'} 
                        variant="filled"
                        /> */}
                    <Chip 
                        size="small"
                        icon={<StorefrontIcon />}
                        sx={{bgcolor:'transparent'}}
                        label={user.currentStore ? user.currentStore.storeName : 'Sin grupo'} 
                        variant="filled"
                    />
                </Stack>
                <CardContent>
                    <Typography 
                        variant="body2" 
                        sx={{
                            fontWeight:user.about ? 500:300, 
                            fontStyle: user.about ? 'normal': 'italic',
                            borderLeft:3.75,
                            pl:2,
                            borderRadius:1,
                            borderColor:'success.dark'
                        }}>
                        {user.about ? user.about : 'No hay información adicional'}
                    </Typography>
                </CardContent>
                {user.role === roleTypeArray[1] ?
                    <CardActions >
                        <LoadingButton
                            loading={loading}
                            size='small'
                            disabled={currentUser}
                            sx={{my:1.5, bgcolor:'grey.200', color:'grey.900'}}
                            variant="text"
                            startIcon={
                                <NotInterestedIcon />
                            }
                            // onClick={loginFacebookHandler}
                        >
                            Desactivar usuario
                        </LoadingButton>
                        {/* <LoadingButton 
                            loading={loading}
                            color="secondary"
                            size='small'
                            disabled={currentUser}
                            sx={{my:1.5}}
                            variant="outlined"
                            // onClick={loginFacebookHandler}
                            startIcon={
                                <StorefrontIcon />
                            }
                        >
                            Cambiar grupo
                        </LoadingButton> */}
                    </CardActions>
                    :null
                }
            </Card>
        </Box>
    )
}

export default UserCard