import { AuthAction, AuthState, SET_USER, SIGN_OUT, NEED_VERIFICATION, SET_USER_ACTIVITY_STATE} from '../../types/authTypes';

const initialState: AuthState = {
    user: null,
    authenticated: false,
    needVerification: false,
    userActivityState: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: AuthAction) => {
    switch(action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload,
                authenticated: true
            }
        case SET_USER_ACTIVITY_STATE:
            return {
                ...state,
                userActivityState: action.payload
            }
        case SIGN_OUT: 
            return {
                ...state,
                user: null,
                authenticated: false
            }
        case NEED_VERIFICATION:
            return {
                ...state,
                needVerification: true
            }
        default:
            return state;
    }
}