import React, {FC, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../store';
import { User } from '../../../types/authTypes';
import { setDialog, setDialogType, setUserToUpdate} from '../../../store/actions/genericActions';
import { dialogTypes } from '../../../features/referenceData/appLists';
import { roleTypeArray } from '../../../features/referenceData/userLists';
import { setEditStore,setCarrierPriceToEdit } from '../../../store/actions/storeActions';

import MemoizedCreateQuoteForm from '../forms/CreateQuoteForm';
import MemoizedCreateFavoriteDirForm from '../forms/CreateFavoriteDirForm';
import UpdateBalanceForm from '../forms/UpdateBalanceForm';
import GeneralPurposeMessage from '../forms/GeneralPurposeMesaage';
import DialogShipmentCreated from '../../pages/shipments/DialogShipmentCreated';
import MemoizedNewCarrierForm from '../forms/NewCarrierForm';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ShipmentTable from '../../pages/shipments/ShipmentTable'
import { ShipmentDataHolder } from '../../../types/apiTypes';
import { DateRange, Range } from 'react-date-range';
import es from 'date-fns/locale/es';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CenterFocusStrong } from '@mui/icons-material';
import {getBulkShipmentData } from '../../../store/actions/apiActions';

interface DialogProps {
    user:User
}

const GeneralPurposeDialog: FC<DialogProps> = (props) => {

    const {user} = props
    const dispatch = useDispatch();
    const globalPrice = false;
    const [dateRange, setDateRange] = useState<Array<Range>>([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])
    const[loaded, setloaded] = useState(false)
    const dateRangeHandler = (range:Array<Range>) => {
        const newStartDate: Date | undefined = range[0].startDate ? new Date(range[0].startDate.setHours(0,0,0,0)) : undefined;
        const newEndDate: Date | undefined = range[0].endDate ?  new Date(range[0].endDate.setHours(23,59,59,59)) : undefined;
        setDateRange(range)
    }

    const { dialog, dialogType, dialogBody, userToUpdate, gralText, showForm} = useSelector((state: RootState) => state.generic);
    const { stores} = useSelector((state: RootState) => state.stores);

    const closeDialogHandler = () => {
        dispatch<any>(setDialog(false))
        dispatch<any>(setDialogType(''))
        if(showForm){
            dispatch<any>(setEditStore(undefined))
        }
        const saveUserInStore: boolean = false
        dispatch<any>(setUserToUpdate(saveUserInStore))
        dispatch<any>(setCarrierPriceToEdit())
        return;
    }

    return (
        
        <Dialog open={dialog} onClose={closeDialogHandler} fullWidth maxWidth={dialogType === dialogTypes[2] ?'lg':'md'}>

            <DialogTitle sx={{mt:1}} className="d-flex-row-between-centered"> 
                <Typography sx={{fontWeight:600}} variant="h5" component="p">
                    {
                        dialogType === dialogTypes[0] ? '¡Tu envío happy se ha creado con éxito!' : 
                        dialogType === dialogTypes[1] ? 'Crear cotización': 
                        dialogType === dialogTypes[2] ? 'Agregar dirección': 
                        dialogType === dialogTypes[3] &&
                            (user?.role === roleTypeArray[1] || user?.role === roleTypeArray[2]) ? 'Administrar saldo': 
                        dialogType === dialogTypes[5] ? 'Creacion del shipment ha fallado':
                        dialogType === dialogTypes[6] ? 'Agregar precio por cliente':
                        dialogType === dialogTypes[7] ? 'Editar precio del cliente': 
                        dialogType === dialogTypes[8] ? 'Descargar reporte especializado': ""}
                </Typography>
                <IconButton onClick={closeDialogHandler} aria-label="close" >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {user && (dialogType === dialogTypes[0] && dialogBody) ? 
                    <DialogShipmentCreated user={user} shipmentSaved={dialogBody}  />
                    :null
                }
                {user && dialogType === dialogTypes[1] ? 
                    <MemoizedCreateQuoteForm user={user}  />
                    :null
                }
                {user && dialogType === dialogTypes[2] ? 
                    <MemoizedCreateFavoriteDirForm user={user}  />
                    :null
                }
                {user && dialogType === dialogTypes[3] && userToUpdate ? 
                    <UpdateBalanceForm userToUpdate={userToUpdate} adminUser={user} />
                    :null
                }
                {dialogType === dialogTypes[5] ? 
                    <GeneralPurposeMessage />
                    :null
                }
                {dialogType === dialogTypes[6] && userToUpdate?
                    <MemoizedNewCarrierForm user={user} client={userToUpdate}/>
                    :null
                }
                {dialogType === dialogTypes[7] && userToUpdate?
                    <MemoizedNewCarrierForm user={user} client={userToUpdate}/>
                    :null
                }
            </DialogContent>
        </Dialog>
    )
}

export default GeneralPurposeDialog