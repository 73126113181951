import React, {FC, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { StoreBasicData, User } from '../../../types/authTypes';
import { ComissionType, 
        ComissionSaved,
        ComissionItem
} from '../../../types/storeTypes';

import { RootState } from '../../../store';
import { comissionsSales, deductionsSales } from '../../../features/referenceData/storeLists';
import { getMonthYear, getTodayDate } from '../../../features/functions/handleDates';
import { getComissionsOnly, getDeductionsOnly } from '../../../features/functions/comissionsDataHandler';
import { createComissions } from '../../../store/actions/storeActions';
import { setLoading, setShowForm } from '../../../store/actions/genericActions';

import AvatarCustomed from '../../UI/AvatarCustomed';
import SummaryViewCard from './SummaryViewCard'

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import AddBoxIcon from '@mui/icons-material/AddBox';
import TextField from '@mui/material/TextField';
import ListItemIcon from '@mui/material/ListItemIcon';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface RegisterComissionProps {
    user: User
}

const RegisterComissionsCard: FC<RegisterComissionProps> = (props) => {

    const { user } = props
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const MonthYear: string = getMonthYear();
    const todayDate: string = getTodayDate();

    const initiateComissionObj: ComissionSaved ={
        _id:'...',
        storeId:'',
        supervisorId:user&&user.supervisor?.uid?user.supervisor?.uid:'',
        submittedBy:user?user.uid:'',
        monthYear: MonthYear,
        createDate: new Date(),
        comissions:undefined,
        notes:'...'
    }
    const { loading } = useSelector((state:RootState) => state.generic)
    // const comissionTypes: Array<ComissionType> | null = useSelector((state: RootState) => state.generic.comissionTypes)
    const [openComission, setOpenComission] = useState<boolean>(false);
    const [indexToggleComission, setIndexToggleComission] = useState<number>(0)
    const [openDeduction, setOpenDeduction] = useState<boolean>(false);
    const [indexToggleDeduction, setIndexToggleDeduction] = useState<number>(0)
    const [showSummary, setShowSummary] = useState<boolean>(false)
    const [invalidField, setInvalidField] = useState<boolean>(false) 
    const [storeSelected, setStoreSelected] = useState<StoreBasicData | undefined>(undefined)
    const [comissionItemToSave, setComissionItemToSave] = useState<ComissionItem>({})
    const [comissionObjectToSave, setComissionObjectToSave] =  useState<ComissionSaved>(initiateComissionObj)

    const userStores:  Array<StoreBasicData> | undefined = user.stores ?  user.stores : undefined 

    // const comissionsOnly: Array<ComissionType> | null = comissionTypes ? getComissionsOnly(comissionTypes) : null;
    // const deductionsOnly: Array<ComissionType> | null = comissionTypes ? getDeductionsOnly(comissionTypes) : null;

    const handleToggleComissionLine = (index: number) =>{
        setComissionItemToSave({})
        setOpenComission(!openComission)
        setIndexToggleComission(index)
    }
    
    const handleToggleDeductionLine = (index: number) =>{
        setComissionItemToSave({})
        setOpenDeduction(!openDeduction)
        setIndexToggleDeduction(index)
    }

    const handleStoreSelected = (event: SelectChangeEvent) => {
        let selectedStoreId = event.target.value as string
        const selectedStoreObj = userStores && userStores.find((store:StoreBasicData) => {
            return store._id === selectedStoreId
        })
        setStoreSelected(selectedStoreObj)
    }

    const handleComissionSubtype = (event: SelectChangeEvent, comissionSubtypes?:any) => {
        const selectedSubtype = event.target.value as string
        const subtypeToSave = comissionSubtypes.find((comission:any) => {
            return comission.name ===selectedSubtype 
        })
        setComissionItemToSave({
            ...comissionItemToSave,
            subtype: subtypeToSave.name,
            amount: subtypeToSave.amount
        })
    }

    const handleComissionQty = (e:any) => {
        const qtyToSave: number = parseFloat(e.target.value)
        if(qtyToSave < 0) {
            setInvalidField(true);
            return;
        } else {
            setComissionItemToSave({
                ...comissionItemToSave,
                qty: qtyToSave
            })
            setInvalidField(false)
        }
    }

    const handleComissionCost = (e:any, comissionObjToSave?:ComissionItem) => {
        const costToSave: number = parseFloat(e.target.value)
        if(costToSave < 0){
            setInvalidField(true)
        } else {
            setComissionItemToSave({
                ...comissionItemToSave,
                cost: costToSave,
                percentage:comissionObjToSave?.percentage
            })
            setInvalidField(false)
        }
    }

    const handleDeductionTotalAmnt = (e:any, deductionObjToSave?:ComissionItem) => {
        const deductionTotal: number = parseFloat(e.target.value)
        if(deductionTotal<0) {
            setInvalidField(true)
        } else {
            setComissionItemToSave({
                ...deductionObjToSave,
                totalAmount:deductionTotal
            })
            setInvalidField(false)
        }
    }

    const addComissionHandler = (comissionObjToSave: ComissionItem, percentage?: number) => {
        const qtyToUse = comissionItemToSave.qty ? comissionItemToSave.qty:0
        const amountToUse = comissionItemToSave.subtype && comissionItemToSave.amount ? comissionItemToSave.amount : 
            comissionObjToSave.amount ? comissionObjToSave.amount :
            percentage && comissionItemToSave.cost && comissionItemToSave.qty ?
            Math.round((comissionItemToSave.qty*(comissionItemToSave.cost*(percentage/100)))*100)/100 :
            0;
        const addedComission: ComissionItem = {
            ...comissionObjToSave,
            qty:qtyToUse,
            cost: percentage?comissionItemToSave.cost:0,
            percentage:percentage?comissionItemToSave.percentage:0,
            amount: amountToUse,
            todayDate: todayDate,
            subtype: comissionItemToSave.subtype ? comissionItemToSave.subtype : '',
            totalAmount: comissionItemToSave.type===1?comissionItemToSave.totalAmount: percentage?amountToUse : qtyToUse * amountToUse
        }
        let comissionArrayToAdd: Array<ComissionItem> = comissionObjectToSave&& comissionObjectToSave.comissions?
            [...comissionObjectToSave.comissions, addedComission] :[addedComission]
        setComissionObjectToSave({...comissionObjectToSave, comissions: comissionArrayToAdd})
        setOpenComission(false)
        setOpenDeduction(false)
    }


    const saveComissionsHandler = () => {
        dispatch<any>(setLoading(true))
        if(storeSelected) {
            const comissionWithStore: ComissionSaved ={
                ...comissionObjectToSave,
                storeId: storeSelected._id,
            }
            if(comissionWithStore && comissionWithStore.comissions) {
                dispatch<any>(createComissions(comissionWithStore))
                dispatch<any>(setShowForm(false))
            }
            setComissionObjectToSave(initiateComissionObj)
            setComissionItemToSave({})
            setStoreSelected(undefined)
            setShowSummary(false)
            
        }
    }

    return (
        <Box sx={{width:'100', pb:10}}>
            {/* {user ? 
                <Card sx={{mb:1}}>
                    <Box sx={{
                        p:2,
                        display:{xs:'block', md:'flex'},
                        flexDirection: {xs:'column', md:'row'},
                        justifyContent:{xs:'flex-start', md: 'space-between'},
                        alignItems:{xs:'flex-start', md:'center'}
                    }}>
                        <Box className="d-flex-row-start-centered">
                            <AvatarCustomed 
                            userName={user?.fullName} 
                            size={42} 
                            userPhoto={user?.userAvatar}  
                            fontWeight={500}
                            fontSize={18}
                            />
                            <Box sx={{ml:1.5}}>
                                <Typography sx={{fontWeight:500}} variant="body1">
                                    <Link
                                        onClick={()=>navigate("/userProfile", {state:{ id: user?.uid}})}
                                        underline='hover' sx={{':hover':{
                                        cursor:'pointer'
                                    }}}>
                                        {user?.fullName}
                                    </Link>
                                </Typography>
                                <Typography sx={{fontWeight:400}} variant="body2">
                                    {user?.role}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{mt:{xs:1.5, md:0}}}>
                            <FormControl
                                sx={{mr:2, mt:.75, bgcolor:'white', width:{xs:'100%', md:'auto'}}}
                                size={'small'}
                                color='success'
                                >
                                <InputLabel id="select-store-label">Selecciona tienda</InputLabel>
                                <Select
                                    labelId="select-store-label"
                                    id="select-store"
                                    value={
                                        storeSelected ? 
                                        storeSelected._id :
                                        'Selecciona una tienda'
                                    }
                                    label="Selecciona tienda"
                                    type='string'
                                    onChange={(e) => handleStoreSelected(e)}
                                    >
                                    <MenuItem selected disabled value="Selecciona una tienda">
                                        Selecciona una tienda
                                    </MenuItem>
                                    {userStores && userStores.map((store:StoreBasicData, index:number) => {
                                        return(
                                            <MenuItem
                                                key={index}
                                                value={store._id}>
                                                    {store.storeName}
                                            </MenuItem> 
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <LoadingButton 
                                onClick={()=>setShowSummary(!showSummary)}
                                aria-label="hide-comissions"
                                disabled={!comissionObjectToSave.comissions}
                                variant="contained"
                                color="warning"
                                disableElevation
                                loading={loading}
                                sx={{my:.5, mr:1}}
                                >
                                {showSummary?'Ocultar':'Ver'} resumen
                            </LoadingButton>
                            <LoadingButton 
                                onClick={saveComissionsHandler}
                                aria-label="save-comission"
                                disabled={!comissionObjectToSave.comissions}
                                variant="contained"
                                color="secondary"
                                disableElevation
                                loading={loading}
                                sx={{my:.5, mx:1}}
                                >
                                Guardar
                            </LoadingButton>
                        </Box>
                    </Box>
                </Card>
                :null
            } */}
            { showSummary ? 
                <SummaryViewCard comissionToDisplay={comissionObjectToSave} />
                :null
            }
            {/* { storeSelected ?
                <Card elevation={2} sx={{mt:1}}>
                    <CardContent sx={{pt:0}}>
                        <List disablePadding subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Comisiones
                            </ListSubheader>
                        }>
                            {comissionsSales.map((comission:string, index:number) => {
                                const openThis: boolean = openComission && indexToggleComission === index
                                const isRepair:boolean = comission === comissionsSales[2] ? true:false;
                                const comissionObject: ComissionType | null | undefined = comissionsOnly 
                                    && comissionsOnly.find((comissionData: ComissionType) => {
                                        return comissionData.name === comission
                                    })
                                let comissionObjToSave: ComissionItem ={
                                    type:0,
                                    name: comissionObject?.name,
                                    amount: !comissionObject?.subtypes ? comissionObject?.amount : 0,
                                    percentage: isRepair&&comissionObject?.percentage?comissionObject?.percentage:undefined
                                }
                                const comissionSubtypes = comissionObject && comissionObject.subtypes ? comissionObject.subtypes : null
                                // setComissionItemToSave({...comissionObjToSave})
                                return(
                                    <Box key={index} sx={{width:'100%'}}>
                                        <ListItemButton
                                            sx={{
                                                borderBottom: openThis ? 0:1.25, 
                                                borderBottomColor:'grey.100',
                                                borderLeft:openThis ? 3 : 0, 
                                                borderLeftColor: openThis ? 'success.main':'white'
                                            }} 
                                            onClick={() => handleToggleComissionLine(index)}
                                            >
                                            <ListItemIcon>
                                                {openThis ? 
                                                    <CheckBoxIcon color="secondary" />
                                                    :
                                                    <AddBoxIcon color="secondary" />
                                                }
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={
                                                    <Typography 
                                                        sx={{
                                                            color: openThis ? 'success.main':'inherit',
                                                            fontWeight: openThis ? 700 : '300'
                                                        }}>
                                                        {comission}
                                                    </Typography>
                                                } />
                                                {openThis ? <ExpandLess /> : <ExpandMore />
                                            }
                                        </ListItemButton>
                                        <Collapse 
                                            sx={{
                                                borderBottom:1.25,
                                                borderBottomColor:'grey.100',
                                                width:'100%'
                                            }}
                                            in={openThis} 
                                            timeout="auto" 
                                            unmountOnExit>
                                            <Box 
                                                sx={{p:2,
                                                    width:'95%', 
                                                    borderLeft:3, 
                                                    borderColor:'success.main',
                                                    bgcolor:'grey.50',
                                                    display:'flex',
                                                    flexDirection: {xs:'column', md:'row'},
                                                    justifyContent:{xs:'flex-start', md: 'space-between'},
                                                    alignItems:{xs:'flex-start', md:'center'}
                                                }}
                                                >
                                                {comissionObject && comissionObject.subtypes ? 
                                                    <FormControl
                                                        sx={{my:1, bgcolor:'white', width:{xs:'100%', md:'auto'}}}
                                                        size={'small'}
                                                        >
                                                        <InputLabel id="demo-simple-select-label">Opciones</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={
                                                                comissionItemToSave.subtype ? 
                                                                comissionItemToSave.subtype:
                                                                'Selecciona una opción'
                                                            }
                                                            label="Opciones"
                                                            type='string'
                                                            onChange={(event) => handleComissionSubtype(event, comissionSubtypes)}
                                                            >
                                                                <MenuItem value="Selecciona una opción">
                                                                    Selecciona una opción
                                                                </MenuItem>
                                                            {comissionObject.subtypes.map((type:any, index:number) => {
                                                                return(
                                                                    <MenuItem
                                                                        key={index}
                                                                        value={type.name}>
                                                                            {type.name}
                                                                    </MenuItem> 
                                                                )
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                    :null
                                                }
                                                <TextField
                                                    color="secondary"
                                                    size="small"
                                                    sx={{my:1, bgcolor:'white', width:{xs:'100%', md:'auto'}}}
                                                    autoFocus={openThis}
                                                    disabled={comissionObject?.subtypes && !comissionItemToSave.subtype}
                                                    id="outlined-required"
                                                    onChange={(e) =>handleComissionQty(e)}
                                                    label={'Cantidad'}
                                                    type="number"
                                                    error={invalidField}
                                                    helperText={ invalidField ? 
                                                        <Typography variant="caption" color="error">
                                                            Ingresa un número mayor a cero
                                                        </Typography>
                                                        :null
                                                    }
                                                />
                                                {isRepair ?
                                                    <TextField
                                                        color="secondary"
                                                        size="small"
                                                        sx={{my:1, bgcolor:'white', width:{xs:'100%', md:'auto'}}}
                                                        id="outlined-required"
                                                        onChange={(e) =>handleComissionCost(e, comissionObjToSave)}
                                                        label={'Utilidad ($)'}
                                                        type="number"
                                                        error={invalidField}
                                                        helperText={ invalidField ? 
                                                            <Typography variant="caption" color="error">
                                                                Ingresa un número mayor a cero
                                                            </Typography>
                                                            :null
                                                        }
                                                    />
                                                    :null
                                                }
                                                <Box sx={{my:1, mx:1.5}}>
                                                    <Typography
                                                        component='span'
                                                        variant="caption" 
                                                        sx={{fontWeight:400, mr:1}}>
                                                            Unidad
                                                    </Typography>
                                                    <Typography 
                                                        variant="body2"
                                                        align="left" 
                                                        sx={{fontWeight:700, fontSize:16}}>
                                                            {
                                                                comissionObject?.amount && !comissionObject?.subtypes ? 
                                                                `$${comissionObject.amount}.00`: 
                                                                comissionObject?.subtypes && comissionItemToSave.amount?
                                                                `$${comissionItemToSave.amount}.00` : 
                                                                isRepair ? `${comissionObject?.percentage}%` :
                                                                '00' }
                                                    </Typography>
                                                </Box>
                                                <Box sx={{my:1, mx:1.5}}>
                                                    <Typography
                                                        component='span'
                                                        variant="caption" 
                                                        sx={{fontWeight:400}}>
                                                            Total
                                                    </Typography>
                                                    <Typography 
                                                        variant="body2"
                                                        align="left" 
                                                        sx={{fontWeight:700, fontSize:20, color:'success.main'}}>
                                                            {
                                                            comissionItemToSave.qty&&comissionObject?.amount ? 
                                                            "$"+comissionItemToSave.qty*comissionObject?.amount :
                                                            comissionItemToSave.qty&&comissionItemToSave.subtype&&comissionItemToSave.amount ? 
                                                            "$"+comissionItemToSave.qty*comissionItemToSave.amount :
                                                            isRepair && comissionItemToSave.cost && comissionItemToSave.qty && comissionObject?.percentage ?
                                                            "$"+Math.round((comissionItemToSave.qty*(comissionItemToSave.cost*(comissionObject.percentage/100)))*100)/100
                                                            :0 
                                                            }
                                                    </Typography>
                                                </Box>
                                                <LoadingButton 
                                                    onClick={()=>addComissionHandler(comissionObjToSave, isRepair&&comissionObject?.percentage?comissionObject.percentage:undefined)}
                                                    aria-label="add-item"
                                                    size="small"
                                                    variant="contained"
                                                    color="secondary"
                                                    disableElevation
                                                    disabled={!comissionItemToSave.qty || invalidField}
                                                    // loading={loading}
                                                    sx={{my:1, mx:1, width:{xs:'100%', md:'auto'}}}
                                                    startIcon={<AddBoxIcon />}
                                                    >
                                                    Agregar
                                                </LoadingButton>
                                            </Box>
                                        </Collapse>
                                    </Box>
                                )
                            })}
                        </List>
                        <List disablePadding subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Descuentos
                            </ListSubheader>
                        }>
                            {deductionsSales.map((deduction:string, index:number) => {
                                const openThis: boolean = openDeduction && indexToggleDeduction === index
                                const deductionObject: ComissionType | null | undefined = deductionsOnly 
                                    && deductionsOnly.find((deductionData: ComissionType) => {
                                        return deductionData.name === deduction
                                    })
                                let deductionObjToSave: ComissionItem ={
                                    type:1,
                                    name: deductionObject?.name,
                                }
                                return(
                                    <Box key={index} sx={{width:'100%'}}>
                                        <ListItemButton
                                            sx={{
                                                borderBottom: openThis ? 0:1.25, 
                                                borderBottomColor:'grey.100',
                                                borderLeft:openThis ? 3 : 0, 
                                                borderLeftColor: openThis ? 'success.main':'white'
                                            }} 
                                            onClick={() => handleToggleDeductionLine(index)}
                                            >
                                            <ListItemIcon>
                                                {openThis ? 
                                                    <CheckBoxIcon color="secondary" />
                                                    :
                                                    <AddBoxIcon color="secondary" />
                                                }
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={
                                                    <Typography 
                                                        sx={{
                                                            color: openThis ? 'success.main':'inherit',
                                                            fontWeight: openThis ? 700 : '300'
                                                        }}>
                                                        {deduction}
                                                    </Typography>
                                                } />
                                                {openThis ? <ExpandLess /> : <ExpandMore />
                                            }
                                        </ListItemButton>
                                        <Collapse 
                                            sx={{
                                                borderBottom:1.25,
                                                borderBottomColor:'grey.100',
                                                width:'100%'
                                            }}
                                            in={openThis} 
                                            timeout="auto" 
                                            unmountOnExit>
                                            <Box 
                                                sx={{p:2,
                                                    width:'95%', 
                                                    borderLeft:3, 
                                                    borderColor:'success.main',
                                                    bgcolor:'grey.50',
                                                    display:'flex',
                                                    flexDirection: {xs:'column', md:'row'},
                                                    justifyContent:{xs:'flex-start', md: 'space-between'},
                                                    alignItems:{xs:'flex-start', md:'center'}
                                                }}
                                                >
                                                <TextField
                                                    color="secondary"
                                                    size="small"
                                                    sx={{my:1, bgcolor:'white', width:{xs:'100%', md:'auto'}}}
                                                    autoFocus={openThis}
                                                    id="outlined-required"
                                                    onChange={(e:any) => handleDeductionTotalAmnt(e, deductionObjToSave)}
                                                    label={'Cantidad ($)'}
                                                    type="number"
                                                    error={invalidField}
                                                    helperText={ invalidField ? 
                                                        <Typography variant="caption" color="error">
                                                            Ingresa un número mayor a cero
                                                        </Typography>
                                                        :null
                                                    }
                                                />
                                                <Box sx={{my:1, mx:1.5}}>
                                                    <Typography
                                                        component='span'
                                                        variant="caption" 
                                                        sx={{fontWeight:400}}>
                                                            Total
                                                    </Typography>
                                                    <Typography 
                                                        variant="body2"
                                                        align="left" 
                                                        sx={{fontWeight:700, fontSize:20, color:'success.main'}}>
                                                            ${comissionItemToSave.totalAmount ?comissionItemToSave.totalAmount :'0.00' }
                                                    </Typography>
                                                </Box>
                                                <LoadingButton  onClick={()=>addComissionHandler(deductionObjToSave)}
                                                    aria-label="add-item"
                                                    size="small"
                                                    variant="contained"
                                                    color="secondary"
                                                    disableElevation
                                                    disabled={!comissionItemToSave.totalAmount}
                                                    // loading={loading}
                                                    sx={{my:1, mx:1, width:{xs:'100%', md:'auto'}}}
                                                    startIcon={<AddBoxIcon />}
                                                    >
                                                    Agregar
                                                </LoadingButton>
                                            </Box>
                                        </Collapse>
                                    </Box>
                                )
                            })}
                        </List>
                    </CardContent>
                </Card>
                :null

            } */}
        </Box>
    )
}

export default RegisterComissionsCard