import React, {FC, useState, useEffect, FormEvent} from 'react'
import {useSelector, useDispatch} from 'react-redux';

import { RootState } from '../../../store';
import { signin, signup } from '../../../store/actions/authActions';
import { setLoading } from '../../../store/actions/genericActions';
import { SignUpData, SignInData } from '../../../types/authTypes';
import {validateLengthInput, validatePassword, validateEmail, validatePhone} from '../../../features/functions/validatorField'

import AlertMessage from '../AlertMessage';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

interface UserAccesFormParams {
    signIn: boolean
    logIn: boolean
}
const UserAccessForm: FC<UserAccesFormParams> = (props) => {
    const {signIn, logIn} = props
    const dispatch =  useDispatch();

    const { error, success, loading } = useSelector((state: RootState) => state.generic);

    const [userName, setUserName] = useState<string>('')
    const [userEmail, setUserEmail] = useState<string>('')
    const [userPhone, setUserPhone] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [repeatPassword, setRepeatPassword] = useState<string>('')

    const [enableForm, setEnableForm] = useState<boolean>(false);
    const [errorFullName, setErrorFullName] = useState<boolean>(false);
    const [errorEmail, setErrorEmail] = useState<boolean>(false);
    const [errorPhone, setErrorPhone] = useState<boolean>(false);
    const [errorPassword, setErrorPassword] = useState<boolean>(false);
    const [errorPasswordRepeat, setErrorPasswordRepeat] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPasswordRepeat, setShowPasswordRepeat] = useState<boolean>(false);

    useEffect(()=>{
        if(signIn) {
            if(userName !== ''){
                setErrorFullName(validateLengthInput(35, userName))
            }
            if(userEmail !== ''){
                setErrorEmail(validateEmail(userEmail))
            }
            if(userPhone !== ''){
                setErrorPhone(validatePhone(userPhone))
            }
            if(password !== '') {
                setErrorPassword(validateLengthInput(60, password))
                setErrorPassword(validatePassword(password))
            }
            if(repeatPassword !== '' && password !== '' && repeatPassword !== password){
                setErrorPasswordRepeat(true)
            } else if(repeatPassword !== '' && password !== '' && repeatPassword === password){
                setErrorPasswordRepeat(false)
            }
            if(userName!=='' && userEmail!=='' && password!=='' && repeatPassword!=='' && !errorFullName && !errorEmail && !errorPassword && !errorPasswordRepeat) {
                setEnableForm(true)
            } else {
                setEnableForm(false)
            }
        }
        if(logIn) {
            if(userEmail !== ''){
                setErrorEmail(validateEmail(userEmail))
            }
            if(password !== '') {
                setErrorPassword(false)
            }
            if(userEmail !== '' && password !== ''){
                setEnableForm(true)
            } else {
                setEnableForm(false)
            }
        }
    }, [
        signIn,
        logIn,
        userName,
        userEmail,
        userPhone,
        password,
        repeatPassword,
        errorFullName,
        errorEmail,
        errorPassword,
        errorPasswordRepeat
    ])

    const handleClickShowPassword = ()=>{
        const showPwdStatus = showPassword;
        setShowPassword(!showPwdStatus)
    }
    const handleClickShowPasswordRepeat = ()=>{
        const showPwdStatus = showPasswordRepeat;
        setShowPasswordRepeat(!showPwdStatus)
    }

    const userNameHandler = (e:any) => {
        setUserName(e)
    }
    const userEmailHandler = (e:any) => {
        setUserEmail(e)
    }
    const userPhoneHandler = (e:any) => {
        setUserPhone(e)
    }
    const passwordHandler = (e:any) => {
        setPassword(e)
    }
    const passwordRepeatHandler = (e:any) => {
        setRepeatPassword(e)
        // setErrorPasswordRepeat(validateLengthInput(30, repeatPassword))
    }

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const submitHandler = (e: FormEvent) => {
        e.preventDefault();
        dispatch<any>(setLoading(true))
        if(signIn) {
            let userToSave: SignUpData = {
                fullName: userName,
                email: userEmail,
                phone: userPhone,
                password
            }
            if(userToSave.email&&userToSave.password) {
                dispatch<any>(signup(userToSave))
            }
        }
        if(logIn) {
            let userToLogin: SignInData = {
                email:userEmail,
                password
            }
            if(userToLogin.email&&userToLogin.password) {
                dispatch<any>(signin(userToLogin))
            }
        }
    }

    return (
        <Box
            component="form"
            sx={{width:'100%', display:'flex', flexDirection:'column'}}
            onSubmit={submitHandler}
            noValidate
            className='d-flex-col-start-centered'
            autoComplete="off"
        >
            {signIn ? 
                <TextField
                    required
                    color="primary"
                    value={userName}
                    sx={{mb:2}}
                    id="outlined-required"
                    onChange={(e) => userNameHandler(e.currentTarget.value)}
                    autoFocus
                    placeholder='Julian Perez'
                    label="Nombre completo"
                    variant="standard"
                    type="text"
                    fullWidth
                    error={errorFullName}
                    helperText={
                        errorFullName?
                        // <Typography variant="body2" color="error">
                            `Intenta un nombre más corto. ${userName.length}/25`
                        // </Typography>
                        :null
                    }
                    />
                    :null
                }
            <TextField
                required
                color="secondary"
                value={userEmail}
                sx={{mb:2}}
                id="outlined-email"
                onChange={(e) => userEmailHandler(e.currentTarget.value)}
                placeholder='jperez@email.com'
                autoComplete="Correo"
                fullWidth
                label="Correo electrónico"
                variant="standard"
                autoFocus={ logIn ? true : false}
                type="email"
                error={errorEmail}
                helperText={
                        errorEmail?
                        // <Typography variant="body2" color="error">
                            'Intenta un correo válido'
                        // </Typography>
                        :null
                    }
            />
            
            {signIn ?
                <TextField
                    required
                    color="secondary"
                    value={userPhone}
                    sx={{mb:2}}
                    id="outlined-phone"
                    onChange={(e) => userPhoneHandler(e.currentTarget.value)}
                    placeholder='3310787733'
                    autoComplete="Teléfono"
                    fullWidth
                    label="Teléfono (10 Dígitos)"
                    variant="standard"
                    autoFocus={ logIn ? true : false}
                    type="email"
                    error={errorPhone}
                    helperText={
                            errorPhone?
                            // <Typography variant="body2" color="error">
                                'El número de teléfono debe tener 10 dígitos. Elimina cualquier espacio o caracter especial.'
                            // </Typography>
                            :null
                        }
                        />
                    : null
            }
            <TextField
                required
                color="secondary"
                value={password}
                sx={{mb:2}}
                id="outlined-password-input"
                label="Contraseña"
                variant="standard"
                autoComplete="Contraseña"
                fullWidth
                onChange={(e) => passwordHandler(e.currentTarget.value)}
                placeholder='contraseña'
                type={showPassword ? 'text': "password"}
                error={errorPassword}
                helperText={
                    errorPassword?
                        // <Typography variant="body2" color="error">
                            'La contraseña debe de tener minimo 8 caracteres, una mayúscula, un caracter especial y un número.'
                        // </Typography>
                        :null
                    }
                InputProps={{
                    endAdornment: 
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {showPassword ? 
                                <VisibilityOff sx={{fontSize:20}} color="secondary"/> : <Visibility sx={{fontSize:20}} color="secondary"/>}
                            </IconButton>
                        </InputAdornment>
                }} 
            />
            {signIn ?
                <TextField
                    required
                    color="secondary"
                    value={repeatPassword}
                    sx={{mb:2}}
                    id="outlined-pwd-repeat"
                    disabled={password === ''}
                    onChange={(e) => passwordRepeatHandler(e.currentTarget.value)}
                    placeholder='Repite contraseña'
                    fullWidth
                    label="Repite contraseña"
                    variant="standard"
                    type={showPasswordRepeat ? 'text': "password"}
                    error={errorPasswordRepeat}
                    helperText={
                        errorPasswordRepeat?
                            // <Typography variant="body2" color="error">
                                'Las contraseñas no coinciden.'
                            // </Typography>
                            :null
                        }
                    InputProps={{
                        endAdornment: 
                            <InputAdornment position="end">
                                <IconButton
                                    disabled={repeatPassword === ''}
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordRepeat}
                                    onMouseDown={handleMouseDownPassword}
                                    >
                                {showPassword ? 
                                    <VisibilityOff sx={{fontSize:20}} color="secondary"/>  : <Visibility sx={{fontSize:20}} color="secondary"/>}
                                </IconButton>
                            </InputAdornment>
                    }} 
                />
                :null
            }
            <LoadingButton
                loading={loading}
                disabled={!enableForm}
                disableElevation
                fullWidth
                type='submit'
                sx={{mb:2}}
                variant="contained"
                color="secondary"
            >   
                {logIn ? 'Entrar' :'Crear mi cuenta'}
            </LoadingButton>
            { error || success ? 
                <AlertMessage message={error} severity={ error ? 'error' : success ? 'success': 'success' } />
                :null
            }
        </Box>
    )
}

export default UserAccessForm