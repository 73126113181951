import { createTheme } from '@mui/material/styles';


export const omTheme = createTheme( {
    palette: {
        mode:"light",
        common: {
            black:"#000",
            white:"#F1EDE9"
        },
        primary: {
            main: '#000',
            // main: '#030202'
        },
        secondary: {
            light: '#9F0CE3',
            // main: '#AF3ED6', 
            main: '#6421F8', 
            dark: '#5E1181',
            // dark: '#1b2b81'
        },
        warning: {
            light: '#fdedb0',
            main: '#fcd33a',
            dark: '#e2bd34',
            contrastText: '#000'
        },
        success: {
            light:'#bac9c0',
            main:'#1A4D2E',
            dark:'#123520'
        },
        info: {
            light: '#d5daf7',
            main: '#2D49D8',
            dark: '#1b2b81'
        },
        error: {
            light: '#fdcdd0',
            main: '#F90716',
            dark: '#c70511',
            // dark: '#c70511'
        }
    },
    shape: {
        borderRadius: 4
    },
    typography: {
        fontFamily: 'Titillium Web',
        fontWeightLight: 100,
        fontWeightRegular: 300,
        fontWeightMedium: 400,
        fontWeightBold: 700,
        button: {
            textTransform: 'none'
        }
    },
    mixins: {
        toolbar: {
            minHeight: 55
        }
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Titillium Web',
                    fontWeightLight: 100,
                    fontWeightRegular: 300,
                    fontWeightMedium: 400,
                    fontWeightBold: 700,
                }
            },
        },
        MuiDialog: {
            styleOverrides:{
                paper:{
                    borderRadius:'30px'
                },
            }
        },
        MuiListItemIcon:{
            styleOverrides:{
                root:{
                    minWidth:35
                }
            }
        },
        MuiFormControl:{
            styleOverrides:{
                root: {
                    select:{
                        fontWeight: 500,
                        fontSize:'14px'
                    },
                    textarea:{
                        fontWeight: 500,
                        fontSize:'14px'
                    },
                  }
            }
        },
        MuiFormControlLabel :{
            styleOverrides:{
                root: {
                    select:{
                        fontWeight: 600,
                        fontSize:'14px'
                    },
                    textarea:{
                        fontWeight: 600,
                        fontSize:'14px'
                    },
                    
                },
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: 'Titillium Web',
                    fontWeight: 500,
                    fontSize:'14px'
                }
            }
        },
        MuiInputLabel:{
            styleOverrides: {
                root: {
                    fontFamily: 'Titillium Web',
                    fontWeight: 500,
                    fontSize:'14px'
                }
            }
        },
        MuiOutlinedInput:{
            styleOverrides: {
                root: {
                    fontFamily: 'Titillium Web',
                    fontWeight: 500,
                    fontSize:'14px'
                }
            }
        },
        MuiTextField:{
            styleOverrides:{
                root: {
                    background:'#fff',
                    input:{
                        fontWeight: 500,
                        fontSize:'14px'
                        
                    },
                    textarea:{
                        fontWeight: 500,
                        fontSize:'14px',
                        
                    }
                },
            },
            variants:[
                {
                    props:{ variant:'standard'},
                    style:{
                        backgroundColor:'#f5f5f8'
                    }
                }
            ]
            
        },
        MuiButton:{
            styleOverrides: {
                sizeSmall:{
                    fontSize: 14,
                    fontWeight:500,
                    borderRadius: '15px',
                    // padding:4
                },
                sizeMedium:{
                    fontSize: 16,
                    fontWeight:500,
                    borderRadius: '15px'
                    // padding:4
                },
                sizeLarge:{
                    fontSize: 17,
                    fontWeight:600,
                    borderRadius: '15px'
                    // padding:4
                }
            }
        }
    }
})
