import React, {FC} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../../../types/authTypes';

import { RootState } from '../../../store';
import { setLoading, setShowForm, setUserToUpdate, setPublicPrice } from '../../../store/actions/genericActions';
import { updateAnotherUser } from '../../../store/actions/storeActions';

import { actionUpdateUser, roleTypeArray } from '../../../features/referenceData/userLists';
import UpdateStoreButton from './UpdateStoreButton'
import UpdateRoleButton from './UpdateRoleButton'

import Box from '@mui/material/Box';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import { setDialog, setDialogType } from '../../../store/actions/genericActions';
import {dialogTypes} from '../../../features/referenceData/appLists';

interface EditUserButtonsProps {
    userExt: User,
    userAdmin: User,
}

const EditUserButtons:FC<EditUserButtonsProps> = (props) => {
    
    const {userExt, userAdmin} = props

    const { loading } = useSelector((state: RootState) => state.generic);
    const dispatch = useDispatch()

    const openDialogHandler = () => {
        /*dispatch<any>(setCarrierPriceToEdit())
        const saveUserInStore: boolean = false;
        dispatch<any>(setUserToUpdate(saveUserInStore))*/
        dispatch<any>(setUserToUpdate(true,userExt))
        dispatch<any>(setDialog(true))
        dispatch<any>(setPublicPrice(false))
        dispatch<any>(setDialogType(dialogTypes[6]))
    }

    const updateUserHandler = (type:string) =>{
        dispatch<any>(setLoading(true))
        const userToUpdate: User = {
            ...userExt,
            active: type === actionUpdateUser[0] && userExt.active ? !userExt.active: 
                    type === actionUpdateUser[1] && !userExt.active ? true: false
        }
        if(userToUpdate && userAdmin) {
            dispatch<any>(updateAnotherUser(userToUpdate, userAdmin.uid))
        }
    }

    return (
        <Box sx={{width:'100%'}}>
        {userExt.active ? 
            <Box sx={{width:'100%'}} className="d-flex-col-start-start">
                {userAdmin.role === roleTypeArray[1] ?
                    <Box className="d-flex-col-start-start">
                        <UpdateRoleButton userExt={userExt} userAdmin={userAdmin} />
                        <UpdateStoreButton userExt={userExt} userAdmin={userAdmin} />
                    </Box>
                    :null
                }
                <LoadingButton // needs additional work to navigate to Carriers/Precios tab and create new price
                    onClick={openDialogHandler}
                    aria-label="crear precio"
                    size="small"
                    variant="text"
                    color="primary"
                    fullWidth
                    disableElevation
                    loading={loading}
                    sx={{my:.5, bgcolor:'grey.50'}}
                    startIcon={<AttachMoneyOutlinedIcon/>}
                    >
                    Crear precio para cliente
                </LoadingButton>
                <LoadingButton 
                    onClick={()=>updateUserHandler(actionUpdateUser[0])}
                    aria-label="notifications"
                    size="small"
                    variant="outlined"
                    color="error"
                    fullWidth
                    disableElevation
                    loading={loading}
                    sx={{my:.5}}
                    startIcon={<DoNotDisturbAltOutlinedIcon/>}
                    >
                    Desactivar usuario
                </LoadingButton>
            </Box>
            :
            null
        }
        {!userExt.active&& userAdmin.role === roleTypeArray[1] ? 
            <Box sx={{width:'100%'}} className="d-flex-row-start-start">
                <LoadingButton 
                    onClick={()=>updateUserHandler(actionUpdateUser[1])}
                    aria-label="notifications"
                    size="small"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disableElevation
                    loading={loading}
                    sx={{my:.5, mx:1}}
                    startIcon={<CheckCircleOutlineOutlinedIcon/>}
                    >
                    Activar usuario
                </LoadingButton>
                <LoadingButton 
                    onClick={()=>updateUserHandler(actionUpdateUser[2])}
                    aria-label="notifications"
                    size="small"
                    variant="contained"
                    color="error"
                    fullWidth
                    disableElevation
                    loading={loading}
                    sx={{my:.5, ml:1}}
                    startIcon={<DeleteOutlineOutlinedIcon/>}
                    >
                    Eliminar usuario
                </LoadingButton>
            </Box>
            :null
        }
        </Box>
    )
}

export default EditUserButtons