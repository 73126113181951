import React, {FC, useState, useMemo} from 'react'
import { DataGrid,
  GridOverlay,
  GridColDef, 
  GridToolbarContainer, 
  GridToolbarExport, 
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridRenderCellParams
} 
from '@mui/x-data-grid';

import AvatarCustomed from '../../UI/AvatarCustomed';
import { roleTypeArray } from '../../../features/referenceData/userLists';

import {RootState}from'../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { CarrierPrice, UserDirectoryFav } from '../../../types/storeTypes';
import { User } from '../../../types/authTypes';
import { ApiConnection, ShipmentInterface } from '../../../types/apiTypes'
import { refreshRabeeWaybill } from '../../../store/actions/apiActions';
import { setLoading, setShowForm, setDialog, setDialogType, setUserToUpdate } from '../../../store/actions/genericActions';
import { setCarrierPriceToEdit, setEditStore, deleteCarrierPrice } from '../../../store/actions/storeActions';
import { grid_esES_Text }from '../../../features/referenceData/reqTableDef';
import { activeInactive } from '../../../features/referenceData/storeLists';
import {formatCurrencyMxn, calculateMarginPct } from '../../../features/functions/genericFunctions';
import {dialogTypes} from '../../../features/referenceData/appLists';

import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

interface CarrierPricesProps{
    carrierPrices: any,
    user: User
}

const CarrierPricesTable: FC<CarrierPricesProps> = (props) => {

  const {carrierPrices, user} = props
  const dispatch = useDispatch();
  const { loading, showForm, publicPrice } = useSelector((state: RootState) => state.generic);
  const {activeApis} = useSelector((state: RootState) => state.apis)
  const [pageSize, setPageSize] = useState<number>(10);
  const [columns, setColumns] = useState<any>()

  const rows = carrierPrices;


  const openLabelHandler = (labelUrl: string) =>{
    window.open(labelUrl,'_blank')
 }

  useMemo(() => {
    let reqColDef: GridColDef[] = [
        {field:'actions', headerName: 'Acciones', align:'center', sortable:true, width:110, renderCell: ((params: GridRenderCellParams) => {
            
            const handleEditCarrierPrice = (carrierPriceObj: any) => {
                /*dispatch<any>(setCarrierPriceToEdit())
                const saveUserInStore: boolean = false;
                dispatch<any>(setUserToUpdate(saveUserInStore))*/
                const carrierPriceToEdit: CarrierPrice ={
                    ...carrierPriceObj
                }
                if(publicPrice == false){
                    dispatch<any>(setUserToUpdate(true,carrierPriceObj.priceForCustomer))
                    dispatch<any>(setDialog(true))
                    dispatch<any>(setDialogType(dialogTypes[7]))
                }else{
                    const currentState = showForm
                    dispatch<any>(setShowForm(!currentState))
                    if(showForm){
                        dispatch<any>(setEditStore(undefined))
                    }
                }
                dispatch<any>(setCarrierPriceToEdit(carrierPriceToEdit))
            }

            const handleDeleteCarrierPrice = (carrierPriceObj: any) => {
                if(window.confirm('¿Deseas eliminar este precio?')){
                    const carrierPriceToDelete: CarrierPrice = {
                        ...carrierPriceObj
                    }
                    dispatch<any>(deleteCarrierPrice(carrierPriceToDelete, user.uid))
                }else{
                    return;
                }
            }   
            return(
                <Box sx={{width:'100%'}} className="d-flex-row-center-centered">
                    <Tooltip title={user.role === roleTypeArray[1] ? "Editar":'Solo los admins pueden editar precios'} placement='left'>
                        <span>
                            <IconButton 
                                color="inherit"
                                onClick={()=> handleEditCarrierPrice(params.row)}>
                                <EditIcon sx={{fontSize:20}}/>
                            </IconButton>

                        </span>
                    </Tooltip>
                    <Tooltip title={user.role === roleTypeArray[1] ? "Borrar":'Solo los admins pueden borrar precios'} placement='right'>
                        <span>
                            <IconButton 
                                color="error"
                                onClick={()=> handleDeleteCarrierPrice(params.row)}>
                                <DeleteForeverOutlinedIcon sx={{fontSize:20}}/>
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            )
            })
        },
        {field:'active', headerName: 'Estado', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                const statusText: string = params.row.active ? activeInactive[0] : activeInactive[1];
                const statusBoolean: boolean = params.row.active
                return(
                    <Chip
                        variant='outlined'
                        label={statusText}
                        color={!statusBoolean ? 'default' : 'success'} 
                        sx={{fontWeight:500}} 
                    />
                )
            })
        },
        {field:'priceForCustomerVsGlobal', headerName: 'Cliente o Público', align:'left', sortable:true, width:150, renderCell: ((params: GridRenderCellParams) => {
                return(
                    params.row.priceForCustomer && params.row.priceForCustomer.fullName !== 'N/A' ?
                    <Box className="d-flex-row-center-centered">
                        <AvatarCustomed 
                        userName={params.row.priceForCustomer.fullName} 
                        size={18} 
                        userPhoto={params.row.priceForCustomer.userAvatar}  
                        fontWeight={400}
                        fontSize={12}
                        />
                        <Typography variant="body2" sx={{ml:.75}}>
                            {params.row.priceForCustomer.fullName} 
                        </Typography>
                    </Box>
                    :
                    <Typography variant="body2">
                        Precio global
                    </Typography>
                )
            })
        },
        {field:'customerEmail', headerName: 'Email del cliente', align:'left', sortable:true, width:170, renderCell: ((params: GridRenderCellParams) => {
                return(
                    params.row.priceForCustomer && params.row.priceForCustomer.fullName !== 'N/A' ?
                    <Typography variant="body2">
                        {params.row.priceForCustomer.email} 
                    </Typography>
                    :
                    <Typography variant="body2">
                        Precio global
                    </Typography>
                )
            })
        },
        {field:'carrier', headerName: 'Carrier', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" className='capitalized-first' sx={{fontWeight:500}}>
                        {params.row.carrier}
                    </Typography>
                )
            })
        },  
        {field:'serviceType', headerName: 'Tipo de Servicio', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.serviceType.name?params.row.serviceType.name:"INVALID"}
                    </Typography>
                )
            })
        },
        {field:'name', headerName: 'Nombre', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.name}
                    </Typography>
                )
            })
        },
        {field:'amount', headerName: 'Precio', align:'left', sortable:true, width:120, renderCell: ((params: GridRenderCellParams) => {
                const amountFormatted: string = params.row.amount? formatCurrencyMxn(params.row.amount) : '$0 MXN'
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {amountFormatted}
                    </Typography>
                )
            })
        },        
        {field:'cost', headerName: 'Costo', align:'left', sortable:true, width:120, renderCell: ((params: GridRenderCellParams) => {
                const amountFormatted: string = params.row.cost? formatCurrencyMxn(params.row.cost) : '$0 MXN'
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {amountFormatted}
                    </Typography>
                )
            })
        },
        {field:'profitNormalAmnt', headerName: 'Ganancia ($)', align:'left', sortable:true, width:120, renderCell: ((params: GridRenderCellParams) => {
                const amountFormatted: string = params.row.amount&&params.row.cost? formatCurrencyMxn(params.row.amount-params.row.cost) : '$0 MXN'
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {amountFormatted}
                    </Typography>
                )
            })
        },    
        {field:'profitNormalPct', headerName: 'Ganancia (%)', align:'left', sortable:true, width:120, renderCell: ((params: GridRenderCellParams) => {
                const amountFormatted: string = params.row.amount&&params.row.cost? calculateMarginPct(params.row.amount, params.row.cost/*,params.row.cost*/) : '0%'
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {amountFormatted}
                    </Typography>
                )
            })
        },     
        {field:'priceExtendedZone', headerName: 'Precio Zona Ext', align:'left', sortable:true, width:120, renderCell: ((params: GridRenderCellParams) => {
                const amountFormatted: string = params.row.priceExtendedZone? formatCurrencyMxn(params.row.priceExtendedZone) : '$0 MXN'
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {amountFormatted}
                    </Typography>
                )
            })
        },        
        {field:'costExtendedZone', headerName: 'Costo Zona Ext', align:'left', sortable:true, width:120, renderCell: ((params: GridRenderCellParams) => {
                const amountFormatted: string = params.row.costExtendedZone? formatCurrencyMxn(params.row.costExtendedZone) : '$0 MXN'
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {amountFormatted}
                    </Typography>
                )
            })
        },               
        {field:'profitExtZoneAmnt', headerName: 'Ganancia Zona Ext ($)', align:'left', sortable:true, width:120, renderCell: ((params: GridRenderCellParams) => {
                const amountFormatted: string = params.row.priceExtendedZone&&params.row.costExtendedZone? formatCurrencyMxn(params.row.priceExtendedZone-params.row.costExtendedZone) : '$0 MXN'
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {amountFormatted}
                    </Typography>
                )
            })
        },        
        {field:'profitExtZonePct', headerName: 'Ganancia Zona Ext (%)', align:'left', sortable:true, width:120, renderCell: ((params: GridRenderCellParams) => {
                const amountFormatted: string = params.row.priceExtendedZone&&params.row.costExtendedZone? calculateMarginPct(params.row.priceExtendedZone,params.row.costExtendedZone/*,params.row.costExtendedZone*/) : '0%'
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {amountFormatted}
                    </Typography>
                )
            })
        },
        {field:'days', headerName: 'Días', align:'left', sortable:true, width:120, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.days}
                    </Typography>
                )
            })
        }, 
        {field:'kgStart', headerName: 'Kg Inicio', align:'left', sortable:true, width:120, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.kgStart}
                    </Typography>
                )
            })
        }, 
        {field:'kgEnd', headerName: 'Kg Fin', align:'left', sortable:true, width:120, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.kgEnd}
                    </Typography>
                )
            })
        }
      ]
      const columns: GridColDef[] = reqColDef;
      setColumns(columns)
  },[dispatch,showForm, user.uid])    

  function CustomToolbar() {
      return (
        <GridToolbarContainer style={{padding:16, color:'grey.800'}}>
          <GridToolbarColumnsButton size="small" style={{marginLeft: 8, color:'grey.800'}} />
          <GridToolbarFilterButton style={{marginLeft: 8, color:'grey.800'}} />
          <GridToolbarDensitySelector size="small" style={{marginLeft: 8, color:'grey.800'}}/>
          <GridToolbarExport size="small" style={{marginLeft: 8, color:'grey.800'}} />
        </GridToolbarContainer>
      );
  }

  return (
      <div style={{ height: '85vh', width: '100%', backgroundColor:'white' }}>
          <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1, overflowY: 'scroll' }}>
                  <DataGrid
                      localeText={grid_esES_Text}
                      rows={rows}
                      columns={columns}
                      getRowId={(row)=>row._id}
                      density='compact'
                      disableSelectionOnClick
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[10, 20, 40]}
                      pagination
                      components={{
                          Toolbar:  CustomToolbar,
                          LoadingOverlay: GridOverlay
                      }}
                  />
              </div>
          </div>
      </div>
  )
}

export default CarrierPricesTable