import React, {FC, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../../store';
import { setLoading } from '../../../store/actions/genericActions';
import { updateAnotherUser } from '../../../store/actions/storeActions';

import { roleTypeArray } from '../../../features/referenceData/userLists';

import { User } from '../../../types/authTypes';

import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import LoadingButton from '@mui/lab/LoadingButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';

interface UpdateRoleUserParams {
    userExt: User
    userAdmin: User
}

const UpdateRoleButton:FC<UpdateRoleUserParams> = (props) => {

    const dispatch = useDispatch()
    const { userExt, userAdmin } = props
    const { loading } = useSelector((state: RootState) => state.generic);
    const [anchorNotifsEl, setAnchorNotifsEl] = useState<null | HTMLElement>(null);
    const openNotifsEl = Boolean(anchorNotifsEl);

    const clickChangeStore = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorNotifsEl(event.currentTarget);
    };
    const handleCloseStore = () => {
        setAnchorNotifsEl(null);
    };
    const updateUserHandler = (role:string) => {
        dispatch<any>(setLoading(true))
        const userToUpdate: User = {
            ...userExt,
            role: role
        }
        if(userToUpdate && userAdmin) {
            dispatch<any>(updateAnotherUser(userToUpdate, userAdmin.uid))
        }
    }
    return (
    <Box sx={{width:'100%'}}className="d-flex-column-start-centered">
        <LoadingButton 
            aria-label="change-rol"
            onClick={clickChangeStore}
            variant="text"
            color="primary"
            size="small"
            fullWidth
            disabled={userAdmin.role !== roleTypeArray[1]}
            disableElevation
            loading={loading}
            sx={{my:.5, bgcolor:'grey.50'}}
            startIcon={<PermIdentityOutlinedIcon/>}
            // endIcon={<KeyboardArrowDownIcon />}
            >
            Cambiar rol: {userExt.role ? userExt.role : ''}
        </LoadingButton>
        <Menu
            anchorEl={anchorNotifsEl}
            open={openNotifsEl}
            onClose={handleCloseStore}
            onClick={handleCloseStore}
            PaperProps={{
            elevation: 2,
            sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 4px 4px rgba(55, 61, 135,0.25))',
                border:1,
                borderRadius:3,
                borderColor:'grey.500',
                mt: .75,
            }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuList sx={{boxShadow:0, px:2}}>
                <MenuItem sx={{borderBottom:1, borderColor:'grey.300'}}>
                    <ListItemText>
                        <Typography 
                            variant="body2" 
                            sx={{color:'grey.800'}}>
                                Seleccionar rol
                        </Typography>
                    </ListItemText>
                </MenuItem>
                { userExt && userAdmin && roleTypeArray.map((role: string, index: number ) => {
                        return(
                            <MenuItem 
                                key={index} 
                                onClick={() => updateUserHandler(role)}>
                                <ListItemIcon>
                                    <PermIdentityOutlinedIcon sx={{fontSize:22, color:'grey.500'}} />
                                </ListItemIcon>
                                <ListItemText 
                                    primary={
                                        <Typography
                                            component='h6'
                                            variant='body2'
                                            sx={{fontWeight:400}}
                                            >
                                            {role}
                                        </Typography>
                                    }
                                    />
                            </MenuItem>
                        )
                    })
                } 
            </MenuList>
        </Menu>
    </Box>
    )
}

export default UpdateRoleButton