import React, {FC} from 'react'

// interface SvgParams {
//   width:number|string,
//   height:number|string
// }

const LoginVector:FC = () => {
  return (
    <svg
      version="1.1" id="Capa_1" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	    viewBox="0 0 792 612"
      // style="enable-background:new 0 0 792 612;" 
      xmlSpace="preserve">
      <g>
        <defs>
          <rect id="SVGID_1_" x="44.17" y="153.49" width="727.98" height="312.84"/>
        </defs>
        <clipPath id="SVGID_00000126323761351070573460000012166237451382120121_">
          <use xlinkHref="#SVGID_1_" 
            // style="overflow:visible;"
          />
        </clipPath>
        <g 
          // style="clipPath:url(#SVGID_00000126323761351070573460000012166237451382120121_);"
          >
          <polygon className="st1" points="508.88,227.56 508.88,266.57 498.79,266.57 498.79,250.47 456.19,250.47 456.19,333.52 
            548.07,333.52 548.07,227.56 534.77,224.08 		"/>
          <polygon className="st2" points="548.07,333.52 534.77,333.52 534.77,224.08 548.07,227.56 		"/>
          <rect x="489.37" y="250.47" className="st2" width="9.42" height="16.1"/>
          <polygon className="st2" points="561.7,288.49 530.58,288.49 521.14,274.11 552.26,274.11 		"/>
          <polygon className="st2" points="488.95,296.06 456.19,296.06 446.26,280.92 479.01,280.92 		"/>
          <rect x="456.19" y="296.06" className="st3" width="32.76" height="28.22"/>
          <polygon className="st1" points="446.26,280.92 436.33,296.06 436.33,324.27 446.26,324.27 456.19,324.27 456.19,296.06 		"/>
          <rect x="530.58" y="288.49" className="st3" width="31.12" height="35.78"/>
          <rect x="793.59" y="239.02" className="st1" width="32.32" height="94.5"/>
          <rect x="767.59" y="250.47" className="st1" width="26" height="83.04"/>
          <rect x="718.74" y="208.89" className="st1" width="48.85" height="124.63"/>
          
            <rect x="643.41" y="239.02" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1298.2463 572.532)" className="st1" width="11.43" height="94.5"/>
          <polygon className="st3" points="738.99,262.73 728.54,280.54 728.54,325.39 749.45,325.39 749.45,280.54 		"/>
          <rect x="582.33" y="227.56" className="st1" width="61.08" height="105.96"/>
          <polygon className="st1" points="654.84,163.51 687.31,159.27 700.43,163.51 700.43,333.52 654.84,333.52 		"/>
          <polygon className="st2" points="700.43,333.52 687.31,333.52 687.31,159.27 700.43,163.51 		"/>
          <polygon className="st2" points="652.93,264.4 678.29,264.4 689.22,249.87 663.86,249.87 		"/>
          <polygon className="st2" points="783.93,280.54 749.45,280.54 738.99,262.73 773.47,262.73 		"/>
          <path className="st4" d="M825.9,411.17V288.79c-19.68-1.32-44.17,11.56-72.47,11.56c-32.93,0-48.62-14.08-81.51-24
            c-28.1-8.48-57.11-7.4-86.8,1.26c-27.01,7.88-57.22,22.16-84.37,31.43c-28.15,9.62-57.7,9.61-86.53,5.22
            c-28.88-4.39-49.71-11.3-79.71-11.3c-21.7,0-38.7,3.13-52.76,7.53c-13.91,4.35-27.27,10.83-41.28,14.76
            c-28.53,8.01-54.4,5.33-83.2,0.89c-29.43-4.54-56.08-14.39-91.67-14.06c-17.84,0.17-34.73,3.83-40.75,5.77v93.31H825.9z"/>
          <path className="st5" d="M24.86,419.28v34.04c18.7,1.16,49.14-7.34,64.34-10.92c15.3-3.61,30.98-5.16,46.6-4.47
            c15.85,0.7,31.6,3.06,47.44,4.03c15.93,0.98,31.93,0.17,47.7-2.53c15.45-2.64,30.85-6.22,46.54-6.46
            c15.88-0.24,31.62,2.56,47.41,3.93c15.87,1.38,31.88,0.67,47.56-2.51c7.71-1.57,15.38-3.62,23.21-4.28
            c7.9-0.67,15.76,0.49,23.61,1.34c7.58,0.82,15.24,0.91,22.79-0.6c10.49-2.09,21.69-11.02,22.36-11.58H24.86z"/>
          <rect x="678.8" y="239.02" className="st3" width="30.14" height="86.38"/>
          <rect x="708.94" y="239.02" className="st1" width="19.59" height="86.38"/>
          <g>
            <rect x="674.67" y="323.03" className="st6" width="143.44" height="2.36"/>
          </g>
          <g>
            <polygon className="st2" points="747.92,335.48 783.14,335.48 796.39,315.3 761.17,315.3 			"/>
            
              <rect x="747.92" y="335.48" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1531.058 712.983)" className="st3" width="35.22" height="42.01"/>
            <polygon className="st1" points="796.39,315.3 809.64,335.48 809.64,377.5 783.14,377.5 783.14,335.48 			"/>
          </g>
          <path className="st2" d="M825.9,297.64c-10.46,1.77-15.89,23.16-17.4,29.42c-1.85,7.66-5.6,33.29-14.03,34.12
            c-5.45,0.54-8.55-6.18-15.67-4.79c-10.67,2.08-11.21,19.21-17.79,34.41c-2.9,6.71-9.49,16-14.97,14.87
            c-5.97-1.23-7.98-7.45-14.43-8.29c-7.6-0.99-18.66,10.89-26.8,21.9H825.7c0.07-0.1,0.13-0.19,0.2-0.29V297.64z"/>
          <path className="st2" d="M692.74,325.39c1.47-1.61,2.63-4.59,0.86-6.74c-1.51-1.84-6.32-1.43-8.01-3.2c-1.46-1.54,0.49-5.81-1.24-8.41
            c-1.51-2.29-5.56-1.54-11.72,1.53c-3.25,1.62-5.52,3.35-8.45,2.71c-1.97-0.43-1.93-2.67-3.9-3.64c-3.57-1.74-8.78,2.97-11.76,0.84
            c-2.94-2.1-0.55-7.69-3.72-9.98c-3.72-2.69-9.96,1.67-12.6,4.44c-3.41,3.58-4.79,7.74-8.16,10.93c-4.2,3.98-6.79-1.03-11.16,0.26
            c-2.38,0.71-3.82,4.86-6.46,4.31c-1.94-0.4-2.16-3.78-3.44-4.97c-1.74-1.62-2.93-1.38-5.13-0.41c-2.68,1.18-10.99,7.8-11.13,12.35
            H692.74z"/>
          <path className="st6" d="M825.9,419.28v-15.57c-1.97,1.83-3.65,4.09-5.12,6.36c-1.94,2.98-3.53,6.06-5.01,9.22H825.9z"/>
          <path className="st2" d="M734.42,325.39c0.68-2.27,0.94-7.5-1.48-8.94c-2.89-1.73-7.97,3.72-11.84,4.11c-3.45,0.35-5.41-2.2-8.82-1.19
            c-1.91,0.57-6.22,2.25-9.7,6.01H734.42z"/>
          
            <rect x="708.94" y="239.02" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1437.4784 480.3929)" className="st3" width="19.59" height="2.36"/>
          <rect x="751.24" y="211.25" className="st2" width="16.35" height="39.23"/>
          <path className="st6" d="M547.7,411.17c-0.99-5.7-4.45-14.3-16.58-14.9c-10.49-0.52-28.82,6.59-35.79,4.24
            c-7.15-2.41-5.37-10.36-12.25-14.98c-5.94-3.99-22.73,1.85-24.68-1.29c-1.7-2.74,4.35-8.5,3.77-12.56
            c-1.48-10.29-16.89-4.58-23.99-0.88c-4.64,2.42-9.79,5.83-15.26,6.28c-5.75,0.47-10.8-2.85-13.83-4.03
            c-11.98-4.69-23.5,1.93-32.54,7.11c-11.83,6.78-24.75,11.62-39.09,10.32c-7.46-0.67-22.17-2.84-31.18-1.73
            c-17.42,2.14-32.93,15.39-37.96,22.41H547.7z"/>
          <path className="st2" d="M98.02,411.17c1.9-4.74,0.05-14.69-3.7-19.58c-5.43-7.08-15.95-8.53-23.67-4.83
            c-11.36,5.45-2.77-15.5-9.97-18.56c-7.63-3.25-14.22,7.25-18.31,4.24c-4.28-3.16,1.63-15.64-1.29-24.11
            c-3.04-8.83-10.9-13.94-16.23-12.81v75.66H98.02z"/>
          <g>
            <path className="st6" d="M523.08,323.37c0-0.02,0-0.03,0-0.05c0,0,0,0.01,0,0.01C523.07,323.34,523.07,323.35,523.08,323.37z"/>
            <path className="st6" d="M683.51,360.56c-10.29-2.13-46.2-8.85-67.16-12.62c-21.18-3.81-52.11-9.95-60.86-12.3
              c-18.66-5-30.93-8.13-30.93-12.59c0-4.35,11.23-7.8,14.38-9.01c7.1-2.71,14.37-4.91,21.65-7c13.07-3.75,34.92-9.41,47.99-13.17
              c5.54-1.59,15.67-4.91,21.85-7.96c5.51-2.72,7.42-5.16,7.42-7.11c0-3.3-4.65-4.77-7.91-5.53c-2.59-0.6-8.66-1.52-13.68-1.98
              c-2.14,0.23-3.96,0.42-6.11,0.76c4.28,0.44,9.59,1.32,12.63,1.85c3,0.52,11.19,2.07,11.19,4.62c0,2.92-10.94,6.69-16.26,8.51
              c-5.37,1.84-14.12,4.08-26.2,7.22c-15.09,3.91-32.73,8.43-37.3,9.73c-10.25,2.91-38.97,9.73-38.97,19.06
              c0,7.95,12,12.25,26.71,16.71c15.37,4.67,30.57,8.14,46.86,11.29c17.44,3.36,37.16,6.6,60,10.87
              c11.36,2.12,55.58,8.92,55.76,18.04c0.16,8.56-42.26,17.13-61.8,21.06c-22.19,4.47-57.78,10.15-105.32,10.15
              c-117.4,0-418.41-0.02-512.58-0.01v8.13h544.48c34.45,0,71.12-4.69,89.34-8.11c2.1-0.4,3.96-0.77,5.54-1.12
              c19.43-4.32,56.16-16.39,56.16-30.68C720.38,369.7,697.13,363.38,683.51,360.56z M710.68,379.7c0-0.03,0-0.06,0.01-0.09
              C710.69,379.64,710.69,379.67,710.68,379.7z"/>
          </g>
          <g>
            <g>
              <path className="st7" d="M195.15,374.21c12.45,0,21.58,10.09,20.4,22.54c-1.18,12.45-12.22,22.54-24.67,22.54h-11.51l4.27-45.07
                H195.15z"/>
              
                <ellipse transform="matrix(0.7236 -0.6902 0.6902 0.7236 -223.6667 234.9288)" className="st7" cx="181.51" cy="396.74" rx="23.63" ry="21.5"/>
              
                <ellipse transform="matrix(0.7236 -0.6902 0.6902 0.7236 -223.6682 234.9326)" className="st8" cx="181.51" cy="396.74" rx="15.12" ry="13.76"/>
            </g>
            <g>
              <path className="st7" d="M364.28,374.21c12.45,0,21.58,10.09,20.4,22.54c-1.18,12.45-12.22,22.54-24.67,22.54h-11.51l4.27-45.07
                H364.28z"/>
              
                <ellipse transform="matrix(0.7236 -0.6902 0.6902 0.7236 -176.9218 351.664)" className="st7" cx="350.64" cy="396.74" rx="23.63" ry="21.5"/>
              
                <ellipse transform="matrix(0.7236 -0.6902 0.6902 0.7236 -176.922 351.6671)" className="st8" cx="350.64" cy="396.74" rx="15.12" ry="13.76"/>
            </g>
            <path className="st7" d="M327.32,374.21c12.45,0,21.58,10.09,20.4,22.54c-1.18,12.45-12.22,22.54-24.67,22.54h-11.51l4.27-45.07
              H327.32z"/>
            <path className="st7" d="M158.19,374.21c12.45,0,21.58,10.09,20.4,22.54c-1.18,12.45-12.22,22.54-24.67,22.54h-11.51l4.27-45.07
              H158.19z"/>
            <path className="st9" d="M404.41,340.61c-2.06-2.62-3.55-5.66-4.37-8.96l-22.96-91.86c-1.35-5.39-6.07-9-11.77-9h-120l-15.69,165.76
              h171.14c3.54,0,6.68-2.87,7.01-6.41l3.35-35.35c0.31-3.25-0.58-6.39-2.5-8.83L404.41,340.61z"/>
            <path className="st2" d="M360.15,345.96l-4.21-5.35c-2.06-2.62-3.55-5.66-4.37-8.96l-22.96-91.86c-1.35-5.39-6.07-9-11.77-9H127.65
              c-6.38,0-12.14,4.92-13.22,11.28l-13.58,80.3l-5.75,60.79c-0.42,4.45,2.67,8.14,7.08,8.46l69.78,4.93H352.3
              c3.54,0,6.68-2.87,7.01-6.41l3.35-35.35C362.96,351.54,362.07,348.4,360.15,345.96z"/>
            <path className="st10" d="M267.26,347.32c-0.19,2.02-2,3.65-4.03,3.65H159.5l-40.14-2.81c-2.54-0.18-4.32-2.28-4.07-4.82l3.31-34.66
              l7.81-45.78c0.62-3.63,3.93-6.43,7.6-6.43h136.13c3.28,0,6,2.06,6.77,5.13L267.26,347.32z"/>
            <path className="st11" d="M117.53,392.7l0.32-3.42c1.43-15.13,14.86-27.4,29.99-27.4s26.24,12.27,24.81,27.4l-0.69,7.27L117.53,392.7
              z"/>
            <path className="st11" d="M286.3,396.55l0.69-7.27c1.43-15.13,14.86-27.4,29.99-27.4s26.24,12.27,24.81,27.4l-0.69,7.27L286.3,396.55
              z"/>
            <path className="st7" d="M341.79,389.28l-0.69,7.27l-27.4,0l2.11-22.35h11.51c5.24,0,9.9,1.79,13.44,4.79
              C341.75,382.17,342.13,385.65,341.79,389.28z"/>
            
              <ellipse transform="matrix(0.7236 -0.6902 0.6902 0.7236 -187.1369 326.1534)" className="st8" cx="313.68" cy="396.74" rx="23.63" ry="21.5"/>
            
              <ellipse transform="matrix(0.7236 -0.6902 0.6902 0.7236 -187.1374 326.1567)" className="st12" cx="313.68" cy="396.74" rx="15.12" ry="13.76"/>
            <path className="st13" d="M320.45,388.84c0.17,1.14,0.16,2.34-0.05,3.58c-0.95,5.69-5.93,10.31-11.66,10.79
              c-1.17,0.1-2.3,0.03-3.35-0.2c-0.91-0.19-1.65,0.91-1.08,1.61c1.92,2.35,4.87,3.82,8.31,3.8c6.44-0.02,12.2-5.35,12.75-11.78
              c0.3-3.51-0.98-6.65-3.25-8.77C321.46,387.26,320.32,387.92,320.45,388.84z"/>
            <polygon className="st6" points="353.12,385.68 359.73,385.68 360.37,378.96 353.76,378.96 			"/>
            <g>
              <path className="st14" d="M296.88,335.55h-15.17l0.38-4.07h15.17L296.88,335.55z M282.53,334.8h13.66l0.24-2.56h-13.66L282.53,334.8
                z"/>
            </g>
            <g>
              <path className="st14" d="M340.57,356.86h-23.08v-0.75h23.08c1.28,0,2.43-1.04,2.55-2.33l1.76-18.64l0.75,0.07l-1.76,18.64
                C343.71,355.51,342.24,356.86,340.57,356.86z"/>
            </g>
            <g>
              <path className="st14" d="M284.5,383.45h-97.2v-0.75h96.61c3.88-14.93,18.57-26.59,33.57-26.59v0.75
                c-14.77,0-29.23,11.56-32.92,26.31L284.5,383.45z"/>
            </g>
            <g>
              <path className="st14" d="M187.31,383.45h-11.05l-0.02-0.35c-0.89-14.71-13.13-26.24-27.87-26.24c-9.94,0-21.45,7.13-25.57,13.54
                l-0.11,0.17H96.32v-0.75h25.94c2.24-3.39,6.24-6.81,11-9.41c5.1-2.77,10.45-4.3,15.09-4.3c7.46,0,14.51,2.85,19.86,8.02
                c5.13,4.96,8.23,11.54,8.74,18.57h10.35V383.45z"/>
            </g>
            <path className="st11" d="M280.65,310.45l5.32-56.24c0.2-2.06,2.03-3.74,4.09-3.74h33.91c1.63,0,2.98,1.06,3.32,2.62l16.66,76.12
              c0.59,2.7-2,5.32-4.72,4.79l-48.21-9.39C284.35,323.31,280,317.37,280.65,310.45z"/>
            <path className="st15" d="M172.65,389.28l-0.69,7.27l-27.4,0l2.11-22.35h11.51c5.24,0,9.9,1.79,13.44,4.79
              C172.62,382.17,173,385.65,172.65,389.28z"/>
            
              <ellipse transform="matrix(0.7236 -0.6902 0.6902 0.7236 -233.8817 209.4182)" className="st8" cx="144.55" cy="396.74" rx="23.63" ry="21.5"/>
            
              <ellipse transform="matrix(0.7236 -0.6902 0.6902 0.7236 -233.8821 209.4191)" className="st12" cx="144.55" cy="396.74" rx="15.12" ry="13.76"/>
            <path className="st13" d="M151.32,388.84c0.17,1.14,0.16,2.34-0.05,3.58c-0.95,5.69-5.93,10.31-11.66,10.79
              c-1.17,0.1-2.3,0.03-3.35-0.2c-0.91-0.19-1.65,0.91-1.08,1.61c1.92,2.35,4.87,3.82,8.31,3.8c6.44-0.02,12.2-5.35,12.75-11.78
              c0.3-3.51-0.98-6.65-3.25-8.77C152.33,387.26,151.18,387.92,151.32,388.84z"/>
            <polygon className="st6" points="396.53,334.07 356.22,334.07 335.32,250.47 375.63,250.47 			"/>
            <polygon className="st1" points="367.65,385.68 359.73,385.68 360.37,378.96 368.28,378.96 			"/>
            <polygon className="st1" points="408.2,385.68 400.29,385.68 400.92,378.96 408.84,378.96 			"/>
            <path className="st1" d="M409.67,370.19h-6.31c-0.88,0-1.53-0.72-1.45-1.6l0.91-9.62c0.17-1.76,0.45-3.52,0.84-5.26l1.23-5.43
              c0.3-1.33,1.53-2.32,2.87-2.32h0.86l0,0c1.92,2.44,2.81,5.58,2.5,8.83L409.67,370.19z"/>
            <g>
              
                <rect x="209.21" y="316.4" transform="matrix(0.0942 -0.9955 0.9955 0.0942 -65.552 561.4999)" className="st14" width="133.2" height="0.75"/>
            </g>
            <g>
              <rect x="112.3" y="250.1" className="st14" width="268.39" height="0.75"/>
            </g>
            <g>
              
                <rect x="344.23" y="329.14" transform="matrix(0.9769 -0.2138 0.2138 0.9769 -63.0668 81.3787)" className="st14" width="0.75" height="6.1"/>
            </g>
            <path className="st6" d="M355.52,347.9l0.32,2.64c0.23,1.86,0.25,3.76,0.07,5.66l-1.17,12.39c-0.08,0.88,0.57,1.6,1.45,1.6h5.01
              l1.46-15.4c0.31-3.25-0.58-6.39-2.5-8.83h-2.88C356.25,345.96,355.4,346.9,355.52,347.9z"/>
            <polygon className="st11" points="401.75,370.19 369.11,370.19 370.43,356.33 403.06,356.33 			"/>
            <path className="st1" d="M368.43,352.95l-3.03-5.13c-0.69-1.16-1.94-1.86-3.35-1.86h-1.89c1.92,2.44,2.81,5.58,2.5,8.83l-1.46,15.4
              h6.31c0.88,0,1.67-0.72,1.75-1.6l0.77-8.17C370.29,357.76,369.73,355.15,368.43,352.95z"/>
            <path className="st16" d="M101.16,317.43l-0.31,4.94l-4.53,47.82h9.6l4.74-50.1c0.14-1.47-0.94-2.67-2.42-2.67H101.16z"/>
            <polygon className="st17" points="103.26,347.64 98.46,347.64 96.32,370.19 101.12,370.19 			"/>
            <g>
              <path className="st6" d="M108.43,347.68l-0.75-0.07l2.6-27.54c0.06-0.61-0.12-1.18-0.51-1.6c-0.38-0.42-0.93-0.65-1.53-0.65h-7.09
                v-0.75h7.09c0.82,0,1.56,0.32,2.09,0.9c0.53,0.58,0.78,1.36,0.7,2.18L108.43,347.68z"/>
            </g>
            <path className="st1" d="M388.44,334.07h-32.22l-16.88-67.5h31.58c1.63,0,2.98,1.06,3.32,2.62L388.44,334.07z"/>
            <path className="st6" d="M339.23,334l-11.13-2.17c-0.49-1.64-0.68-3.43-0.5-5.28l4.76-50.31l11.6,52.97
              C344.54,331.91,341.96,334.53,339.23,334z"/>
            <g>
              <path className="st14" d="M339.94,334.44c-0.26,0-0.52-0.02-0.78-0.08l-48.21-9.39l0.14-0.74l48.21,9.39
                c1.16,0.23,2.39-0.18,3.28-1.09c0.88-0.9,1.25-2.11,1.01-3.25l-16.66-76.12c-0.3-1.39-1.49-2.32-2.95-2.32v-0.75
                c1.82,0,3.31,1.17,3.69,2.92l16.66,76.12c0.3,1.38-0.15,2.85-1.2,3.94C342.25,333.96,341.1,334.44,339.94,334.44z"/>
            </g>
            <g>
              <path className="st6" d="M396.53,334.45h-36.51c-2.45,0-4.49-1.56-5.07-3.89l-20-79.99l0.73-0.18l20,79.99
                c0.5,1.98,2.24,3.31,4.34,3.31h36.51V334.45z"/>
            </g>
            <path className="st1" d="M327.05,335.17h6.01c0.88,0,1.67-0.72,1.75-1.6l2.04-21.52c0.08-0.88-0.57-1.6-1.45-1.6h-6.01
              c-0.88,0-1.67,0.72-1.75,1.6l-2.04,21.52C325.52,334.46,326.17,335.17,327.05,335.17z"/>
            <g>
              <rect x="370.43" y="355.95" className="st6" width="32.64" height="0.75"/>
            </g>
          </g>
          <g>
            <path className="st6" d="M318.18,291.95c-1.51-0.84-6.28-2.56-7.9-2.91c-3.23-0.7-6.96-1.11-10.26-0.91
              c-1.51,0.09-4.01,0.26-5.16,1.42c-0.95,0.97-1.66,2.66-2.09,4.01c-1.12,3.5-0.9,12.37-1.02,15.55c-0.12,3.05-0.59,4.88-1.09,7.17
              c-0.5,2.32-0.86,3.83-1.39,6.06c-0.2,0.84-0.18,1.44,0.12,1.86h0c0.52,0.17,1.06,0.31,1.62,0.42l2.95,0.57l0.01,0
              c5.29,0.51,16.35,1.55,18.11,1.47c0.53-0.02,1.41-0.33,1.64-1.16c0.33-1.22-0.16-7.19,0.4-10.61c0.56-3.45,4.48-14.41,5.18-18.62
              C319.66,294.15,319.71,292.81,318.18,291.95z"/>
            <path className="st18" d="M298.59,318.36c-1.22-1.39-2.37-5.63-1.51-10.14c0.64-3.3-2.17-5.73-5.24-4.65
              c-0.04,2.27-0.04,4.32-0.08,5.54c-0.12,3.05-0.59,4.88-1.09,7.17c-0.5,2.32-0.86,3.83-1.39,6.06c-0.2,0.84-0.18,1.44,0.12,1.86
              c0.52,0.17,1.07,0.31,1.62,0.42l2.95,0.57c0.98,0.09,2.15,0.21,3.42,0.33c0.48-0.35,0.87-0.8,1.21-1.34
              C300.46,321.18,299.86,319.8,298.59,318.36z"/>
            <path className="st19" d="M314.01,273.6c-0.52-2.21-2.83-3.58-5.99-3.77c-2.51-0.15-4.8,1.26-5.67,3.16
              c-0.83,1.81-1.07,4.8,1.08,9.32c0.51,1.08-0.51,4.19-0.69,4.67c-0.42,1.12-0.63,1.13-0.84,1.72c-0.82,2.2,3.26,5.78,6.71,5.93
              c3.21,0.14,3.11-3.74,2.92-4.63c-0.15-0.71-0.76-0.82-1.09-1.04c-0.55-0.37-0.61-0.69-0.6-1.38c0.01-0.14,0.02-0.29,0.03-0.46
              c0.08-0.75,0.17-2.66,0.64-3.07c0.4-0.34,0.6-0.47,1.04-0.82c0.4-0.32,1.46-1.35,1.63-2.1c0.25-1.08,0.19-2.99,0.28-4.08
              C313.56,275.7,314.33,274.98,314.01,273.6z"/>
            <path className="st20" d="M306.49,281.46c-0.16-0.2-1.01-1.48-1.14-1.32c-0.24,0.31,0.22,2.14,0.64,2.92
              c0.53,0.99,1.43,1.9,2.75,2.26c0.47,0.13,1.16,0.08,1.32,0.04c0.08-0.51,0.2-1.09,0.45-1.3c0.4-0.34,0.6-0.47,1.04-0.82
              c0.13-0.1,0.31-0.27,0.41-0.41C310.44,283.63,308.06,283.43,306.49,281.46z"/>
            <path className="st11" d="M308.34,268.27c-2.98-0.19-6.24,1.03-6.7,3.02c-0.17,0.75-0.09,1.49-0.55,1.98
              c-1.27,1.39-0.2,4.28,0.22,5.37c0.57,1.5,1.96,4.62,2.47,4.41c0.31-0.13,0.76-1.18,0.73-1.64c-0.07-0.98-0.1-2-0.26-2.98
              c-0.11-0.71-0.1-0.71,0.2-1.3c0.11,0.26,0.37,1.01,0.37,1.01l0.95-0.46c0.79-1.25,1.21-3.15,1.05-4.99
              c0.15-0.24,0.54-0.5,0.75-0.59C309.11,271.44,310.43,268.4,308.34,268.27z"/>
            <path className="st19" d="M304.8,277.67c0.25,0.99-0.04,1.93-0.64,2.09c-0.6,0.17-1.28-0.5-1.53-1.49c-0.25-0.99,0.04-1.93,0.64-2.09
              C303.86,276.01,304.55,276.68,304.8,277.67z"/>
            <path className="st21" d="M300.51,276.86c0.09,0.4,0.42,1.14,0.85,0.62c1.22-1.48,2.24-2.4,3.99-3.51c1.13-0.71,2.18-1.29,3.46-1.55
              c1.75-0.35,3.44-0.23,4.87,0.46c0.24,0.11,0.46-0.16,0.36-0.56c-0.43-1.6-1.2-2.84-3.19-4.12c-1.46-0.94-4.61-0.61-6.44-0.04
              C299.77,269.62,299.76,273.44,300.51,276.86z"/>
            <path className="st22" d="M305.96,273.46c0.37-0.22,1.6-0.76,2.31-1.13c0.73-0.38,1.33-0.79,2.01-1.32c0.59-0.46,1.08-1.31,2.07-1.87
              c0.59-0.33,0.93-0.34,1.1-0.37c0.08-0.01-0.03-0.12-0.53,0.05c-1.04,0.35-2.64,1.25-3.62,1.57c-2.55,0.84-3.45,2.43-3.59,3.11
              C305.7,273.62,305.79,273.57,305.96,273.46z"/>
            <path className="st23" d="M305.8,273.52c1.45-0.85,3.67-1.5,5.29-3.42c1.55-1.84,4.07-1.77,5.45-0.58c0.62,0.53,1.54,1.76,1.12,2.91
              c-0.37,1.03-2.05,1.61-2.97,1.74c-0.45,0.06-0.55-0.01-0.74-0.23c-0.29-0.34-0.77-0.91-1.65-1.26c-1.25-0.49-3.47-0.24-4.67,0.16
              c-0.37,0.13-1.27,0.57-1.66,0.79C305.75,273.77,305.6,273.64,305.8,273.52z"/>
            <path className="st6" d="M312.49,280.24c-0.02,0.17-0.63,0.42-1.37,0.31s-1.21-0.68-1.15-0.84c0.06-0.14,0.55,0.14,1.29,0.25
              C312.01,280.08,312.52,280.07,312.49,280.24z"/>
            <g>
              <path className="st9" d="M327.64,312.05c0.08-0.88,0.87-1.6,1.75-1.6h5.25c-2.47-3.16-7.43-10.15-10.59-13.54
                c-2.68-2.87-4.85-5.82-8.68-5.12c-1.66,0.3-2.23,3.13-2.09,4.94c0.12,1.53,0.87,4.16,1.93,5.42c2.05,2.44,7.95,8.01,12.24,11.83
                L327.64,312.05z"/>
              <path className="st9" d="M340.88,315.17c-1.34-0.53-2.86-1.45-4.09-2.46l-0.68,7.2c1.89,0.73,3.88,1.21,5.76,1.42
                c0.12,0.01,0.24,0.03,0.37,0.04L340.88,315.17z"/>
            </g>
            <path className="st21" d="M316.41,329.56l-25.39-4.94c-0.58-0.11-1.15-0.26-1.7-0.44c0.18-0.17,0.38-0.32,0.6-0.45
              c1.53-0.94,3.69-1.08,5.1-2.22c1.33-1.07,2.32-2.45,2.72-4.1c0.82-3.39,1.65-9.82,2-13.52c0.26-2.77,0.47-7.27-0.83-10.86
              c-0.64-1.77-2.17-3.62-2.66-4.15c-0.33-0.35-0.35-0.44-0.03-0.51c0.44-0.11,1.26-0.2,1.68-0.26c0.46-0.07,0.66,0.17,1,0.51
              c0.92,0.92,2.26,3.05,2.79,5.12c0.7,2.71,1.14,5.83,0.89,9.38c2.34,0.17,6.35,0.5,8.64,0.7c1.38-5.34,2.11-10.16,1.61-13.53
              c-0.08-0.53-0.13-0.76,0.16-0.65c0.37,0.14,1.18,0.39,1.69,0.57c0.43,0.15,0.55,0.82,0.6,1.02c0.68,2.55-0.92,10.53-1.54,13.78
              c-0.52,2.68-1.13,8.62-1.25,10.8c-0.1,1.96-0.36,3.93,0.21,5.87c0.25,0.85,1,2.21,1.54,2.79c0.96,1.04,1.94,1.3,2.05,2.49
              C316.31,327.37,316.37,328.4,316.41,329.56z"/>
            <path className="st9" d="M283.56,319.21c0.48-4.69,3.67-17.86,4.44-21.51c0.44-2.08,1.23-6.09,3.87-7.59
              c0.96-0.55,2.9-1.25,3.43-1.38c0.69-0.17,0.93,0.25,1,0.33c0.96,1.14,1.49,2.5,2.1,4.28c0.74,2.15,0.07,5.86-0.69,8.89
              c-1.1,4.41-3.1,10.23-3.88,13.01c-1.04,3.71,2.17,4.09,3.2,4.3c3.38,0.67,17.88,2.89,20,3.31c2.33,0.47,2.49,1.72,2.39,2.79
              c-0.06,0.6-0.91,1.25-2.14,1.25c-3.5,0.02-20.22,0.13-25.58-0.02C287.39,326.74,282.81,326.46,283.56,319.21z"/>
            <path className="st19" d="M326.56,323.46c-0.67-0.54-1.29-1.03-1.65-1.3c-0.39-0.29-1.04-0.68-1.58-0.68
              c-1.11,0.01-2.19,0.09-2.89,0.15c-0.77,0.06-1.74,1-2.59,1.54c-0.86,0.55-1.54,1.71-0.87,2.81c0.32,0.54,1.16,0.78,1.87,0.64
              c0.97-0.18,2.85-0.66,3.91-0.97c0.97-0.28,1.65-0.21,2.55,0.19c0.36,0.16,0.68,0.29,0.97,0.39L326.56,323.46z"/>
          </g>
          <g>
            <ellipse className="st11" cx="632.15" cy="281.29" rx="32.1" ry="3.5"/>
            
              <linearGradient id="SVGID_00000039823990052671590910000017357505983248024714_" gradientUnits="userSpaceOnUse" x1="612.4205" y1="269.8982" x2="683.3575" y2="147.0318">
              <stop  offset="0" className='stopc1'/>
              <stop  offset="1" className="stopc2"/>
            </linearGradient>
            <path 
              // style="fill:url(#SVGID_00000039823990052671590910000017357505983248024714_);" 
              d="M611.14,253.83
              c-4.19-4.86-6.74-11.17-6.74-18.09c0-15.33,12.42-27.75,27.75-27.75c15.33,0,27.75,12.42,27.75,27.75
              c0,6.92-2.55,13.23-6.74,18.09l-21.01,27.47L611.14,253.83z"/>
            
              <ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 172.4477 729.5581)" className="st6" cx="632.15" cy="235.74" rx="15.98" ry="15.98"/>
          </g>
        </g>
        
          <rect x="48.53" y="250.88" 
            // style="clipPath:url(#SVGID_00000126323761351070573460000012166237451382120121_);fill:#9BA0D1;" 
            width="52.62" height="6.43"/>
        
          <rect x="68.7" y="266.28" 
            // style="clipPath:url(#SVGID_00000126323761351070573460000012166237451382120121_);fill:#9BA0D1;" 
            width="29.98" height="6.43"/>
        
          <rect x="79.72" y="283.15" 
            // style="clipPath:url(#SVGID_00000126323761351070573460000012166237451382120121_);fill:#9BA0D1;" 
            width="16.6" height="6.43"/>
        <g 
          // style="clipPath:url(#SVGID_00000126323761351070573460000012166237451382120121_);"
          >
          <g>
            <path className="st9" d="M183.68,303.2l0.15-1.14h2.85l-0.05,0.39c-0.02,0.15,0,0.28,0.07,0.4c0.06,0.11,0.14,0.17,0.24,0.17h5.35
              l-1.59-0.71l0.15-1.1c0.02-0.15,0-0.28-0.07-0.4c-0.06-0.11-0.14-0.17-0.24-0.17h-4.4c-0.27,0-0.53-0.09-0.76-0.26
              c-0.24-0.18-0.43-0.41-0.58-0.69c-0.15-0.28-0.26-0.62-0.33-0.99c-0.07-0.38-0.07-0.77-0.02-1.18l0.43-3.31
              c0.08-0.61,0.28-1.11,0.6-1.5c0.32-0.39,0.69-0.59,1.11-0.59h6.28c0.27,0,0.53,0.09,0.76,0.26c0.24,0.18,0.43,0.41,0.58,0.69
              c0.15,0.28,0.27,0.61,0.34,0.98c0.07,0.37,0.08,0.76,0.03,1.17l-0.16,1.16h-2.85l0.05-0.39c0.02-0.15,0-0.28-0.07-0.4
              c-0.06-0.11-0.14-0.17-0.24-0.17h-5.41l1.58,0.71l-0.11,0.85c-0.02,0.15,0,0.28,0.07,0.4c0.06,0.11,0.14,0.17,0.24,0.17h3.6
              c0.27,0,0.49,0,0.67,0l0.26,0.02c0.26,0.03,0.5,0.13,0.71,0.31c0.21,0.18,0.39,0.42,0.54,0.7c0.14,0.28,0.25,0.61,0.31,0.96
              c0.06,0.36,0.07,0.73,0.01,1.13l-0.48,3.55c-0.08,0.61-0.28,1.11-0.6,1.5c-0.32,0.39-0.69,0.59-1.11,0.59h-6.21
              c-0.27,0-0.53-0.09-0.76-0.26c-0.24-0.18-0.43-0.41-0.58-0.69c-0.15-0.28-0.26-0.62-0.33-0.99
              C183.64,304,183.63,303.61,183.68,303.2 M173.82,295.43l1.59,0.71l-0.84,6.31c-0.02,0.15,0,0.28,0.07,0.4
              c0.06,0.11,0.14,0.17,0.24,0.17h5.72l-1.58-0.71l0.84-6.31c0.02-0.15,0-0.28-0.07-0.4c-0.06-0.11-0.14-0.17-0.24-0.17H173.82z
              M181.31,292.12c0.24,0,0.47,0.08,0.69,0.23c0.21,0.16,0.39,0.37,0.54,0.63c0.14,0.26,0.25,0.56,0.31,0.9
              c0.06,0.34,0.07,0.69,0.01,1.05l-1.26,9.47c-0.07,0.57-0.26,1.03-0.55,1.38c-0.29,0.35-0.63,0.53-1.01,0.53h-6.9
              c-0.25,0-0.49-0.08-0.7-0.23c-0.21-0.16-0.39-0.37-0.53-0.63c-0.14-0.26-0.24-0.57-0.31-0.91s-0.07-0.7-0.03-1.07l1.28-9.45
              c0.07-0.57,0.25-1.03,0.54-1.38c0.29-0.35,0.62-0.53,1.01-0.53H181.31z M166.24,306.33l1.9-14.2H171l-0.45,3.31h-1.67l1.58,0.71
              l-1.36,10.19H166.24z M166.18,292.12l-4.92,14.2h-4.75l-1.74-14.2h3.02l1.33,10.18l-1.59,0.71h1.68l3.64-10.9H166.18z
              M150.88,292.12h2.85l-1.9,14.2h-4.9l-1.51-11.24h-1.68l1.59,0.71l-1.41,10.53h-2.85l1.9-14.2h4.92l1.51,11.24L150.88,292.12z
              M132.71,295.43l1.59,0.71l-0.2,1.54h5.08l-0.41,3.08h-5.09l-0.22,1.68c-0.02,0.15,0,0.28,0.07,0.4
              c0.06,0.11,0.14,0.17,0.24,0.17h5.62l-0.43,3.31h-6.94c-0.25,0-0.49-0.08-0.7-0.23c-0.21-0.16-0.39-0.37-0.53-0.63
              c-0.14-0.26-0.24-0.57-0.31-0.91c-0.06-0.34-0.07-0.7-0.03-1.07l1.52-11.36h8.88l-0.45,3.31H132.71z"/>
            <path className="st26" d="M184.7,299.48h9.05c0.01,0.03,0.01,0.05,0.02,0.08c0.06,0.36,0.07,0.73,0.01,1.13l-0.48,3.55
              c-0.08,0.61-0.28,1.11-0.6,1.5c-0.32,0.39-0.69,0.59-1.11,0.59h-6.21c-0.27,0-0.53-0.09-0.76-0.26
              c-0.24-0.18-0.43-0.41-0.58-0.69c-0.15-0.28-0.26-0.62-0.33-0.99c-0.07-0.38-0.07-0.77-0.02-1.18l0.15-1.14h2.85l-0.05,0.39
              c-0.02,0.15,0,0.28,0.07,0.4c0.06,0.11,0.14,0.17,0.24,0.17h5.35l-1.59-0.71l0.15-1.1c0.02-0.15,0-0.28-0.07-0.4
              c-0.06-0.11-0.14-0.17-0.24-0.17h-4.4c-0.27,0-0.53-0.09-0.76-0.26c-0.24-0.18-0.43-0.41-0.58-0.69
              C184.76,299.62,184.73,299.55,184.7,299.48 M172.11,299.48h2.86l-0.4,2.97c-0.02,0.15,0,0.28,0.07,0.4
              c0.06,0.11,0.14,0.17,0.24,0.17h5.72l-1.58-0.71l0.38-2.83h2.85l-0.66,4.94c-0.07,0.57-0.26,1.03-0.55,1.38
              c-0.29,0.35-0.63,0.53-1.01,0.53h-6.9c-0.25,0-0.49-0.08-0.7-0.23c-0.21-0.16-0.39-0.37-0.53-0.63s-0.24-0.57-0.31-0.91
              s-0.07-0.7-0.03-1.07L172.11,299.48z M167.16,299.48h2.85l-0.91,6.85h-2.85L167.16,299.48z M155.67,299.48h3.08l0.37,2.83
              l-1.59,0.71h1.68l1.18-3.54h3.24l-2.37,6.85h-4.75L155.67,299.48z M146.01,299.48h2.86l0.52,3.89l0.52-3.89h2.85l-0.92,6.85h-4.9
              L146.01,299.48z M141.98,299.48h2.85l-0.92,6.85h-2.85L141.98,299.48z M130.99,299.48h7.95l-0.17,1.29h-5.09l-0.22,1.68
              c-0.02,0.15,0,0.28,0.07,0.4c0.06,0.11,0.14,0.17,0.24,0.17h5.62l-0.43,3.31h-6.94c-0.25,0-0.49-0.08-0.7-0.23
              c-0.21-0.16-0.39-0.37-0.53-0.63c-0.14-0.26-0.24-0.57-0.31-0.91s-0.07-0.7-0.03-1.07L130.99,299.48z"/>
            <g>
              <path className="st9" d="M204.42,300.89h-6.81l1.69,0.71l-0.61,4.73h-3.02l1.82-14.2h3.02l-0.7,5.44h5.03l0.7-5.44h3.02l-1.82,14.2
                h-3.02L204.42,300.89z"/>
            </g>
            <g>
              <path className="st9" d="M212.31,302.31l-0.52,4.02h-3.02l1.57-12.3c0.07-0.57,0.26-1.03,0.56-1.38c0.31-0.35,0.66-0.53,1.06-0.53
                h6.97c0.27,0,0.52,0.08,0.74,0.23c0.23,0.16,0.42,0.37,0.57,0.63c0.15,0.26,0.26,0.56,0.34,0.9c0.07,0.34,0.08,0.69,0.03,1.06
                l-1.46,11.38h-3.02l0.52-4.02H212.31z M217.09,298.98l0.38-2.98c0.02-0.15-0.01-0.28-0.08-0.4c-0.07-0.11-0.16-0.17-0.26-0.17
                h-5.72l1.68,0.71l-0.36,2.84H217.09z"/>
            </g>
            <g>
              <path className="st9" d="M231.87,302.31h-7.34l-0.51,4.02h-3.19l1.82-14.2h10.1c0.23,0,0.44,0.07,0.64,0.2
                c0.2,0.14,0.37,0.32,0.5,0.56c0.14,0.24,0.24,0.5,0.31,0.8c0.07,0.3,0.08,0.61,0.03,0.93l-0.74,5.78
                c-0.07,0.55-0.26,1.01-0.56,1.37C232.63,302.13,232.27,302.31,231.87,302.31z M224.96,298.98h5.43c0.08,0,0.14-0.03,0.2-0.1
                c0.06-0.07,0.09-0.16,0.11-0.26l0.34-2.62c0.02-0.15-0.01-0.28-0.08-0.4c-0.07-0.11-0.16-0.17-0.26-0.17h-7.07l1.69,0.71
                L224.96,298.98z"/>
            </g>
            <g>
              <path className="st9" d="M245.49,302.31h-7.34l-0.51,4.02h-3.19l1.82-14.2h10.1c0.23,0,0.44,0.07,0.64,0.2
                c0.2,0.14,0.37,0.32,0.5,0.56c0.14,0.24,0.24,0.5,0.31,0.8c0.07,0.3,0.08,0.61,0.03,0.93l-0.74,5.78
                c-0.07,0.55-0.26,1.01-0.56,1.37C246.24,302.13,245.89,302.31,245.49,302.31z M238.57,298.98H244c0.08,0,0.14-0.03,0.2-0.1
                c0.06-0.07,0.09-0.16,0.11-0.26l0.34-2.62c0.02-0.15-0.01-0.28-0.08-0.4c-0.07-0.11-0.16-0.17-0.26-0.17h-7.07l1.69,0.71
                L238.57,298.98z"/>
            </g>
            <g>
              <path className="st9" d="M259.77,292.12l-4.83,8.52l-0.67,5.68h-3.28l0.59-4.97h1.78l-1.69-0.71l-3.3-8.52h3.19l1.91,5.21l2.78-5.21
                H259.77z"/>
            </g>
            <path className="st26" d="M251.22,299.48h4.38l-0.66,1.17l-0.67,5.68h-3.28l0.59-4.97h1.78l-1.69-0.71L251.22,299.48z M235.32,299.48
              h11.91l-0.12,0.92c-0.07,0.55-0.26,1.01-0.56,1.37c-0.31,0.36-0.66,0.54-1.06,0.54h-7.34l-0.51,4.02h-3.19L235.32,299.48z
              M221.7,299.48h11.91l-0.12,0.92c-0.07,0.55-0.26,1.01-0.56,1.37s-0.66,0.54-1.06,0.54h-7.34l-0.51,4.02h-3.19L221.7,299.48z
              M209.65,299.48h10.37l-0.86,6.85h-3.02l0.52-4.02h-4.35l-0.52,4.02h-3.02L209.65,299.48z M196.55,299.48h11.06l-0.88,6.85h-3.02
              l0.7-5.44h-6.81l1.69,0.71l-0.61,4.73h-3.02L196.55,299.48z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LoginVector