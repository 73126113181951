import React, {FC, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { User, UserBalanceLog } from '../../../types/authTypes';
import { adjustmentBalanceTypes, balanceType } from '../../../features/referenceData/appLists';
import { setDialog, setDialogType, setLoading} from '../../../store/actions/genericActions';
import { updateAnotherUser, updateUserBalanceLog} from '../../../store/actions/storeActions';

import {RootState} from '../../../store';
import {validateLengthInput, validatePositiveNumber } from '../../../features/functions/validatorField';
import {formatCurrencyMxn } from '../../../features/functions/genericFunctions';
import AvatarCustomed from '../../UI/AvatarCustomed'

import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

interface UpdateUserBalanceProps {
  userToUpdate: User,
  adminUser:User
}

const UpdateBalanceForm: FC<UpdateUserBalanceProps> = (props) => {

  const dispatch = useDispatch()
  const {userToUpdate, adminUser} = props
  const { loading } = useSelector((state: RootState) => state.generic);

  const [monto, setMonto] = useState<number| null>() //add money to account
  const [tipoTxnMonto, setTipoTxnMonto] = useState<string>(adjustmentBalanceTypes[0])
  const [deposit, setDeposit] = useState<number| null>() //abono
  const [tipoTxnDeposit, setTipoTxnDeposit] = useState<string>(adjustmentBalanceTypes[0])
  const [comment, setComment] = useState<string>('')
  const [editBalance, setEditBalance] = useState<boolean>(false)
  const [currentBalance, setCurrentBalance] = useState<number>(userToUpdate.balance?userToUpdate.balance:0)
  const [newBalance, setNewBalance] = useState<number>(userToUpdate.balance?userToUpdate.balance:0)
  const [editDeposit, setEditDeposit] = useState<boolean>(false)
  const [currentDeposit, setCurrentDeposit] = useState<number>(userToUpdate.abono?userToUpdate.abono:0)
  const [newDeposit, setNewDeposit] = useState<number>(userToUpdate.abono?userToUpdate.abono:0)
  const [invalidMonto, setInvalidMonto] = useState(false)
  const [invalidDeposit, setInvalidDeposit] = useState(false)
  const [invalidComment, setInvalidComment] = useState(false)
  const [formInvalid, setFormInvalid] = useState<boolean>(true); 

  useEffect(() => {
    if(!formInvalid && monto && !invalidMonto) {
      if(tipoTxnMonto===adjustmentBalanceTypes[0]){
        let newAmount = Number(currentBalance) + Number(monto)
        if(currentBalance !== newAmount){
          setNewBalance(newAmount)
        }
      }
      if(tipoTxnMonto===adjustmentBalanceTypes[1]){
        let newAmount = Number(currentBalance) - Number(monto)
        if(currentBalance !== newAmount){
          setNewBalance(newAmount)
        }
      }
    }
    if(!formInvalid && deposit && !invalidDeposit) {
      if(tipoTxnDeposit===adjustmentBalanceTypes[0]){
        let newAmount = Number(currentDeposit) + Number(deposit)
        if(currentDeposit !== newAmount){
          setNewDeposit(newAmount)
        }
      }
      if(tipoTxnDeposit===adjustmentBalanceTypes[1]){
        let newAmount = Number(currentDeposit) - Number(deposit)
        if(currentDeposit !== newAmount){
          setNewDeposit(newAmount)
        }
      }
    }
    if((monto!== null&&tipoTxnMonto!== null&&!invalidMonto&&comment&&!invalidComment)
    ||(deposit!== null&&tipoTxnDeposit!== null&&!invalidDeposit&&comment&&!invalidComment)){
      setFormInvalid(false)
    } else{
      setFormInvalid(true)
    }

  }, [
      monto,
      tipoTxnMonto,
      invalidMonto,
      deposit,
      tipoTxnDeposit,
      invalidDeposit,
      comment,
      invalidComment,
      formInvalid, 
      currentBalance, 
      currentDeposit,
      newBalance
  ])

  const editBalanceHandler = (e:any) =>{
    setEditBalance(e)
  }
  const editDepositHandler = (e:any) =>{
    setEditDeposit(e)
  }

  const montoHandler = (e:any) => {
    setMonto(e)
    setInvalidMonto(validatePositiveNumber(e))
  }
  const depositHandler = (e:any) => {
    setDeposit(e)
    setInvalidDeposit(validatePositiveNumber(e))
  }
  const commentHandler = (e:any) => {
    setComment(e)
    setInvalidComment(validateLengthInput(110,e))
  }
  
  const txnTypeMontoHandler = (e:SelectChangeEvent) => {
    setTipoTxnMonto(e.target.value as string);
  }

  const txnTypeDepositHandler = (e:SelectChangeEvent) => {
    setTipoTxnDeposit(e.target.value as string);
  }

  const clearFields = () => {
    setMonto(null)
    setTipoTxnMonto(adjustmentBalanceTypes[0])
}

  const closeDialogHandler = () => {
    dispatch<any>(setDialog(false))
    dispatch<any>(setDialogType(''))
    clearFields();
    // navigate('/dashboard') 
    return;
}

  const updateUserBalanceHandler = () => {
    dispatch<any>(setLoading(true))

    if(!formInvalid && ((editBalance && newBalance)||(editDeposit && newDeposit))) {
      const userUpdated: User = {
        ...userToUpdate,
        balance: editBalance?newBalance:currentBalance,
        abono: editDeposit?newDeposit:currentDeposit
      }

      let userBalanceArrayForLog: Array<UserBalanceLog> = []

      if(editBalance && monto) {
        const editBalanceLog:UserBalanceLog = {
          type: balanceType[0],
          amount: monto,
          action: tipoTxnMonto,
          timestamp: new Date().toString(),
          updatedById: adminUser.uid,
          updatedbyName: adminUser.fullName,
          comment: comment
        }
        userBalanceArrayForLog = [...userBalanceArrayForLog, editBalanceLog ]
      }
      
      if(editDeposit && deposit) {
        const editDepositLog: UserBalanceLog = {
          type: balanceType[1],
          amount: deposit,
          action: tipoTxnDeposit,
          timestamp: new Date().toString(),
          updatedById: adminUser.uid,
          updatedbyName: adminUser.fullName,
          comment: comment
        }
        userBalanceArrayForLog = [...userBalanceArrayForLog, editDepositLog ]
      }

      dispatch<any>(updateAnotherUser(userUpdated, adminUser.uid))
      dispatch<any>(updateUserBalanceLog(adminUser.uid, userToUpdate.uid, userBalanceArrayForLog, userToUpdate.userBalanceLogId ? userToUpdate.userBalanceLogId: undefined))
      dispatch<any>(setDialog(false))
      dispatch<any>(setDialogType(''))
      dispatch<any>(setLoading(false))
    }
    return
  }

  return (
    <Card elevation={0}>
      <CardContent>
          <Box 
              component="form"
              sx={{
                  width:'100%',
              }}
              // onSubmit={submitHandler}
              noValidate
              autoComplete="off"
              >
              <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box className="d-flex-row-center-between">
                      <Box className="d-flex-row-start-centered">
                          <AvatarCustomed 
                              userName={userToUpdate.fullName} 
                              size={48} 
                              userPhoto={userToUpdate.userAvatar}  
                              fontWeight={400}
                              fontSize={16}
                          />
                          <Box>
                            <Typography variant="h6" sx={{fontWeight:500, ml:1}}>
                                {userToUpdate.fullName}
                            </Typography>
                            <Typography variant="subtitle2" sx={{fontWeight:500, ml:1}}>
                                {userToUpdate.email}
                            </Typography>
                          </Box>
                      </Box>
                      <Box>
                        <Typography align='right' variant="subtitle1" sx={{fontWeight:600}}>Actual</Typography>
                        <Typography variant="subtitle1" align='right' sx={{fontWeight:500}}>
                            Saldo: {formatCurrencyMxn(currentBalance)}
                        </Typography>
                        <Typography variant="subtitle1" align='right' sx={{fontWeight:500}}>
                            Abono: {formatCurrencyMxn(currentDeposit)}
                        </Typography>
                        <Typography variant="subtitle1" align='right' sx={{fontWeight:500}}>
                          {currentDeposit>currentBalance?
                            `A favor ${formatCurrencyMxn(currentBalance&&currentDeposit?(-1*(currentBalance-currentDeposit)):0)}`
                            :
                            `Por pagar: ${formatCurrencyMxn(currentBalance&&currentDeposit?currentBalance-currentDeposit:0)}`
                          }
                        </Typography>
                      </Box>
                    </Box>
                    <Divider sx={{my:1}}/>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Box className="d-flex-col-center-centered">
                      <FormControlLabel
                        value="Editar"
                        control={
                          <Checkbox 
                          size="small"
                          color="secondary" 
                          value={editBalance} 
                          checked={editBalance}  
                          onChange={(e)=>editBalanceHandler(e.currentTarget.checked)}/>
                        }
                        label="Editar"
                        labelPlacement="top"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth sx={{my:1}} color="secondary">
                        <InputLabel id="package-type-label">Acción (saldo)</InputLabel>
                        <Select
                            labelId="package-type-label"
                            id="package-type"
                            disabled={!editBalance}
                            value={tipoTxnMonto}
                            label="Acción (saldo)"
                            onChange={txnTypeMontoHandler}
                        >
                            { adjustmentBalanceTypes.map((type: string, index:number) => {
                                    return(
                                        <MenuItem key={index}  sx={{boxShadow:0}} value={type}>{type}</MenuItem>
                                    )
                                }
                            )}
                        </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={5}>
                      <TextField
                          required
                          color="secondary"
                          value={monto}
                          fullWidth
                          sx={{my:1}}
                          onChange={(e:any) => montoHandler(e.currentTarget.value)}
                          label="Saldo ($ MXN)"
                          type="number"
                          disabled={!editBalance}
                          error={invalidMonto}
                          helperText={ invalidMonto ? 'Ingresa un número mayor a 0':null}
                      />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography align='right' variant="subtitle1" sx={{fontWeight:600}}>Nuevo</Typography>
                    <Typography sx={{fontWeight:500, color:newBalance<0?'error.main':'inherit'}} align='right' variant="subtitle1">
                      {currentBalance !== newBalance ?
                        `Saldo: ${formatCurrencyMxn(newBalance?newBalance:0)}`
                        :
                        'Sin cambios en saldo'
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Box sx={{height:'100%'}} className="d-flex-col-center-centered">
                      <Checkbox 
                      size="small"
                      color="secondary" 
                      value={editDeposit} 
                      checked={editDeposit}  
                      onChange={(e)=>editDepositHandler(e.currentTarget.checked)}/>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2}>
                      <FormControl sx={{my:1}} color="secondary" fullWidth>
                          <InputLabel id="package-type-label">Acción (abono)</InputLabel>
                          <Select
                              labelId="package-type-label"
                              id="package-type"
                              value={tipoTxnDeposit}
                              disabled={!editDeposit}
                              label="Acción (abono)"
                              onChange={txnTypeDepositHandler}
                              >
                              { adjustmentBalanceTypes.map((type: string, index:number) => {
                                return(
                                  <MenuItem key={index}  sx={{boxShadow:0}} value={type}>{type}</MenuItem>
                                  )
                                }
                                )}
                          </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={12}md={5}>
                      <TextField
                          required
                          color="secondary"
                          value={deposit}
                          fullWidth
                          sx={{my:1}}
                          disabled={!editDeposit}
                          onChange={(e:any) => depositHandler(e.currentTarget.value)}
                          label="Abono ($ MXN)"
                          type="number"
                          error={invalidDeposit}
                          helperText={ invalidDeposit ? 'Ingresa un número mayor a 0':null}
                          />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography sx={{fontWeight:500, color:newDeposit<0?'error.main':'inherit'}} align='right' variant="subtitle1">
                      {currentDeposit !== newDeposit ?
                        `Abono: ${formatCurrencyMxn(newDeposit?newDeposit:0)}`
                        :
                        'Sin cambios en abono'
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <TextField
                          required
                          color="secondary"
                          value={comment}
                          fullWidth
                          multiline
                          rows={2}
                          sx={{my:1}}
                          onChange={(e:any) => commentHandler(e.currentTarget.value)}
                          label="Comentario (Obligatorio)"
                          type="text"
                          error={invalidComment}
                          helperText={ invalidComment ? 'Has llegado al límite de caracteres':null}
                          />
                  </Grid>
              </Grid>
          </Box>
      </CardContent>
      <CardActions className="d-flex-row-end-centered">
              <LoadingButton
              loading={loading}
              color="secondary"
              sx={{my:1, bgcolor:'grey.50'}}
              variant="text"
              onClick={closeDialogHandler}
          >
              Cancelar
          </LoadingButton>
          <LoadingButton
              loading={loading}
              color="secondary"
              disableElevation
              sx={{my:1}}
              variant="contained"
              disabled={formInvalid}
              onClick={updateUserBalanceHandler}
          >
              Guardar
          </LoadingButton>
      </CardActions>
    </Card>
  )
}

export default UpdateBalanceForm