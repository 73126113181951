import React, {FC, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { DateRange, Range } from 'react-date-range';
import es from 'date-fns/locale/es';

import { RootState} from '../../../store';
import { getBulkShipmentData, getShipmentData, getShipmentsByDate, setShipments } from '../../../store/actions/apiActions'
import { User } from '../../../types/authTypes';
import { translateDate } from '../../../features/functions/handleDates';
import { roleTypeArray } from '../../../features/referenceData/userLists';

import SearchUserByName from '../../UI/forms/SearchUserByName';

import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import DateRangeIcon from '@mui/icons-material/DateRange';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';

interface FilterShipmentsProps {
    user: User
}

const FilterShipments:FC<FilterShipmentsProps> = (props) => {

    const dispatch=useDispatch()
    const {user} = props

    const isUserAdmin: boolean = user && user.role === roleTypeArray[1] ? true : false
    const extendedAccessByRole: boolean = user && (user.role === roleTypeArray[1] || user.role === roleTypeArray[2]) ? true: false
    const users: Array<User> | null = useSelector((state: RootState) => state.generic.usersAdmin)
    const [showSearchByEmail, setShowSearchByEmail] = useState<boolean>(false)
    const date = new Date();
    const getDayOfMonth: any = (year:any, month:any, day:number) => new Date(year, month, day);
    const currentDate = date.getDate()
    const firstDayCurrentMonth = getDayOfMonth(date.getFullYear(), date.getMonth(), 1);
    const midDayCurrentMonth = getDayOfMonth(date.getFullYear(), date.getMonth(), 16);
    const lastDayCurrentMonth = new Date(getDayOfMonth(date.getFullYear(), date.getMonth()+1, 0).setHours(23,59,59,59));
    // const [submittedByUser, setSubmittedByUser] = useState<User | undefined>(!isUserAdmin ? user:undefined)
    const { shipments } = useSelector((state: RootState) => state.apis)
    const [startDateQuery, setStartDateQuery] = useState<Date>(currentDate >= 16 ? midDayCurrentMonth:firstDayCurrentMonth)
    const [endDateQuery, setEndDateQuery] = useState<Date>(currentDate < 16 ? midDayCurrentMonth:lastDayCurrentMonth)
    const [dateRange, setDateRange] = useState<Array<Range>>([{
        startDate: currentDate >= 16 ? midDayCurrentMonth:firstDayCurrentMonth,
        endDate: currentDate < 16 ? midDayCurrentMonth:lastDayCurrentMonth,
        key: 'selection'
    }])

    const startDateToDisplay: string = translateDate(startDateQuery)
    const endDateToDisplay: string = translateDate(endDateQuery)

    const [anchorDatesEl, setAnchorDatesEl] = useState<null | HTMLElement>(null);
    const openCalendarEl = Boolean(anchorDatesEl);

    const handleCloseCalendar = () => {
        setAnchorDatesEl(null);
    };
    const clickChangeCalendar = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorDatesEl(event.currentTarget);
    };

    const {stores} = useSelector((state: RootState) => state.stores)
    const dateRangeHandler = (range:Array<Range>) => {
        const newStartDate: Date | undefined = range[0].startDate ? new Date(range[0].startDate.setHours(0,0,0,0)) : undefined;
        const newEndDate: Date | undefined = range[0].endDate ?  new Date(range[0].endDate.setHours(23,59,59,59)) : undefined;
        setDateRange(range)
        if(newStartDate && newEndDate) {
            setStartDateQuery(newStartDate)
            setEndDateQuery(newEndDate)
        }
    }

    useEffect(() => {    
        
        // if(users) {
        //     const members = users.filter((user:User) => {
        //         const isUserInStore = user.storesIds?.includes(storeId)
        //         if(isUserInStore) {return user}
        //         return null
        //     })
        //     setStoreMembers(members)
        // }

        dispatch<any>(getShipmentsByDate(startDateQuery, endDateQuery, isUserAdmin, !isUserAdmin&&user?user.uid:''))
    }, [
        dispatch,
        user,
        isUserAdmin,
        startDateQuery,
        endDateQuery
    ])

  return (
    <Box>
        <Tooltip placement="top" title="Filtra por fecha o rango de fechas. Muestra la quincena actual">
            <span>
                <LoadingButton 
                    aria-label="calendar"
                    disableElevation
                    color="secondary"
                    variant="text"
                    onClick={clickChangeCalendar}
                    sx={{textTransform:'capitalize', mt:1, bgcolor:'white'}}
                    startIcon={
                        <DateRangeIcon fontSize="small" />
                    }
                    >
                    {`${startDateToDisplay} - ${endDateToDisplay}`}
                </LoadingButton>
            </span>
        </Tooltip>
        <LoadingButton 
            color={shipments.isBulkData==true?"error":"secondary"}
            disableElevation
            variant="contained"
            loading={shipments.isLoading}
            onClick={() => {
                if( shipments && shipments.data && shipments.page != undefined && shipments.count && shipments.isBulkData==false)
                    dispatch<any>(getBulkShipmentData(user, stores, dateRange[0]));
                if(shipments.isBulkData==true){
                    dispatch<any>(getShipmentData(0, user, null, stores));
                }
        }}>
            {shipments.isBulkData==true?"Reportes paginados": "Generar Reporte Masivo"}
        </LoadingButton>
        {/*<LoadingButton 
            aria-label="show-search"
            disableElevation
            color={!showSearchByEmail?"secondary":'error'}
            variant="text"
            onClick={()=>setShowSearchByEmail(!showSearchByEmail)}
            sx={{ml:1,mt:1, bgcolor:'white'}}
            startIcon={!showSearchByEmail ?
                <SearchIcon fontSize="small" />
                :
                <CloseIcon fontSize="small"/>
            }
            >
            {!showSearchByEmail ? 'Filtrar usuario por email':'Cerrar búsqueda de usuario'}
        </LoadingButton>*/}
        <Menu
            anchorEl={anchorDatesEl}
            open={openCalendarEl}
            onClose={handleCloseCalendar}
            PaperProps={{
            elevation: 2,
            sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 4px 4px rgba(55, 61, 135,0.25))',
                border:1,
                borderRadius:3,
                borderColor:'grey.500',
                display:'flex',
                flexDirection:'row',
                mt: .75,
            }
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
            <DateRange 
                editableDateInputs={true}
                color='#6421F8'
                onChange={(item:any) => dateRangeHandler([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                locale={es}
                rangeColors={['#6421F8']}
            />
            <IconButton 
                size="small"
                color="error"
                onClick={handleCloseCalendar}
                sx={{mx:1}}
                >
                <CloseIcon />
            </IconButton>
        </Menu>
        {extendedAccessByRole && showSearchByEmail ? <SearchUserByName dbCall={extendedAccessByRole}/>:null}
    </Box>
  )
}

export default FilterShipments