import React, {FC, useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserToUpdate } from '../../../store/actions/genericActions';

import { firestore } from '../../../firebase/config';
import { collection, onSnapshot, query, where} from 'firebase/firestore';

import { RootState } from '../../../store';
import { roleTypeArray } from '../../../features/referenceData/userLists';
import { User } from '../../../types/authTypes';

import AvatarCustomed from '../../UI/AvatarCustomed';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'

interface SearchScope {
    dbCall: boolean
}

const SearchUserByName: FC<SearchScope> = (props) => {

    const {dbCall} = props
    const dispatch = useDispatch()

    
    const users: Array<User> | null = useSelector((state: RootState) => state.generic.usersAdmin)
    const [searchUserByName, setSearchUserByName] = useState<string|null>(null)
    const [usersFound, setUsersFound] = useState<Array<User>|null>(null)

    useEffect(() => {
        if(!usersFound||usersFound.length===0||!searchUserByName||searchUserByName===''){
            const saveUserInStore: boolean = false
            dispatch<any>(setUserToUpdate(saveUserInStore))
        }

    },[usersFound, searchUserByName, dispatch])

    const handleSearchUserByName = (e:any) => {
        const text: string = e.target.value as string
        setSearchUserByName(text)
        const value = text.trim()

        if(dbCall){
            const usersRef = collection(firestore, 'users')
            const userQueryName = query((usersRef), where('email','==', value))
                onSnapshot(userQueryName, (querySnapshot) => {
                const usersArray: Array<User> = querySnapshot.docs.map((doc) => {
                const userDoc:User = {...doc.data() as User, uid:doc.id}
                return userDoc
                })
                setUsersFound(usersArray)
            })
        }
        if(!dbCall) {
            const usersFound: Array<User> | null = users && users.filter((user:User) => 
                user.email === value && user.role === roleTypeArray[3]
            )
            setUsersFound(usersFound)
        }


    }
    
    const handleSearchSelected = (userSelected:User) =>{
        if(userSelected&& userSelected.uid){
            const saveUserInStore: boolean = true
            dispatch<any>(setUserToUpdate(saveUserInStore, userSelected))
        }
    }
    
    return (
    <Grid container spacing={2}>
        <Grid item sm={12}>
            <Typography variant='body1' sx={{my:1.75}}>Buscar usuario por correo electrónico</Typography>
            <TextField
                required
                color="secondary"
                value={searchUserByName}
                fullWidth
                id="outlined-required"
                onChange={(e:any) => handleSearchUserByName(e)}
                placeholder='Ingresa el correo del usuario'
                label="Correo del usuario"
                type="text"
            />
        </Grid>
        <Grid item sm={12}>
            {usersFound?.length === 0 ? 
                <Typography align='center' variant='body1' sx={{my:3, width:'100%'}}>Usuario no encontrado</Typography>
                :null
            }
            {usersFound && usersFound?.map((userExt:User, index:number) => {
                return(
                    <Box key={index} sx={{width:'100%'}}>
                        <ListItem
                        alignItems="center"
                        sx={{
                            borderBottom:  1.25, 
                            borderBottomColor:'grey.100',
                            borderTopLeftRadius:5,
                            bgcolor: 'grey.50'
                        }} 
                        secondaryAction={
                            <Button
                                onClick={()=>handleSearchSelected(userExt)}
                            >
                                Seleccionar
                            </Button>
                        }
                        >
                            <ListItemAvatar>
                                <AvatarCustomed 
                                userName={userExt.fullName} 
                                size={56} 
                                userPhoto={userExt.userAvatar}  
                                fontWeight={500}
                                fontSize={18}
                                />
                            </ListItemAvatar>
                            <ListItemText
                            sx={{ml:1.5}}
                            primary={
                                <Typography sx={{fontWeight:500}} variant="body1">
                                    {userExt.fullName}
                                </Typography>
                            }
                            secondary={
                                <span>
                                <Typography sx={{fontWeight:400, color:'grey.700'}} variant="body2">
                                    {userExt.email}
                                </Typography>
                                <Typography sx={{fontWeight:400, color:'grey.700'}} variant="caption">
                                    Rol: {userExt.role}
                                </Typography>
                                </span>
                            }
                            />
                        </ListItem>
                    </Box>
                )
            })}
        </Grid>
    </Grid>
    )
}

export default SearchUserByName