
import { GridColDef, GridLocaleText } from '@mui/x-data-grid';



export const reqColDef2: GridColDef[] = [
    { field: 'id', headerName: 'ID', sortable:true, width:150, align:'center'},
    { field: 'status', headerName: 'Estado', width:150, sortable: true},
    { field: 'reqName', headerName: 'Nombre del requerimiento', sortable:true, width:350},
    { field: 'priority', headerName: 'Prioridad', sortable:true, width:150, editable: true },
    { field: 'dueDate', headerName: 'Fecha de vencimiento', sortable:true, width:200, editable: true },
    { field: 'goal', headerName: 'Objetivo', sortable:true, width:400 },
    { field: 'providers', headerName: 'Asignados', sortable:true, width: 100 },
    { field: 'acceptanceCriteria', headerName: 'Criterio de acceptación', sortable:true, width:500 },
    { field: 'documents', headerName: 'Documentos', sortable:true, width:200 },
    { field: 'createdAt', headerName: 'Creado el', sortable:true, width:200 },
    { field: 'createdBy', headerName: 'Creado por', sortable:true, width:200 },
    { field: 'lastModifiedAt', headerName: 'Modificado el', sortable:true, width:200 },
    { field: 'lastModifiedBy', headerName: 'Modificado por', sortable:true, width:200 },
]


export const grid_esES_Text: GridLocaleText = {
  // Root
  noRowsLabel: 'No hay información para mostrar',
  noResultsOverlayLabel: 'No hay resultados.',
  errorOverlayDefaultLabel: 'Oh no, ocurrió un error.',

  // Density selector toolbar button text
  toolbarDensity: 'Densidad',
  toolbarDensityLabel: 'Densidad',
  toolbarDensityCompact: 'Compacta',
  toolbarDensityStandard: 'Estándar',
  toolbarDensityComfortable: 'Amplia',
  toolbarQuickFilterPlaceholder:'',
  toolbarQuickFilterLabel:'',
  toolbarQuickFilterDeleteIconLabel:'',
  toolbarExportPrint:'',
  toolbarExportExcel:'',
  filterPanelLinkOperator:'',
  filterOperatorIsAnyOf:'',
  checkboxSelectionSelectAllRows:'',
  checkboxSelectionUnselectAllRows:'',
  checkboxSelectionSelectRow:'',
  checkboxSelectionUnselectRow:'',
  pinToLeft:'',
  pinToRight:'',
  unpin:'',
  treeDataGroupingHeaderName:'',
  treeDataExpand:'',
  treeDataCollapse:'',
  groupingColumnHeaderName:'',
  groupColumn: (string)=>'string',
  unGroupColumn:(string)=>'string',
  detailPanelToggle:'',
  expandDetailPanel:'',
  collapseDetailPanel:'',
  rowReorderingHeaderName:'',
  aggregationMenuItemHeader:'',
  aggregationFunctionLabelSum:'',
  aggregationFunctionLabelAvg:'',
  aggregationFunctionLabelMin:'',
  aggregationFunctionLabelMax:'',
  aggregationFunctionLabelSize:'',
  // Columns selector toolbar button text
  toolbarColumns: 'Columnas',
  toolbarColumnsLabel: 'Seleccionar columnas',
  

  // Filters toolbar button text
  toolbarFilters: 'Fitros',
  toolbarFiltersLabel: 'Mostrar filtros',
  toolbarFiltersTooltipHide: 'Ocultar filtros',
  toolbarFiltersTooltipShow: 'Mostrar filtros',
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros activos` : `${count} filtros activos`,

  // Export selector toolbar button text
  toolbarExport: 'Exportar',
  toolbarExportLabel: 'Exportar',
  toolbarExportCSV: 'Descargar CSV',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Encontrar columna',
  columnsPanelTextFieldPlaceholder: 'Titulo de la columna',
  columnsPanelDragIconLabel: 'Reordenar columna',
  columnsPanelShowAllButton: 'Mostrar todo',
  columnsPanelHideAllButton: 'Ocultar todo',

  // Filter panel text
  filterPanelAddFilter: 'Agregar filtro',
  filterPanelDeleteIconLabel: 'Borrar',
  filterPanelOperators: 'Operadores',
  filterPanelOperatorAnd: 'Y',
  filterPanelOperatorOr: 'O',
  filterPanelColumns: 'Columnas',
  filterPanelInputLabel: 'Valor',
  filterPanelInputPlaceholder: 'Filtrar valor',

  // Filter operators text
  filterOperatorContains: 'contiene',
  filterOperatorEquals: 'igual a',
  filterOperatorStartsWith: 'empieza con',
  filterOperatorEndsWith: 'termina con',
  filterOperatorIs: 'es',
  filterOperatorNot: 'no es',
  filterOperatorAfter: 'es después',
  filterOperatorOnOrAfter: 'is on or after',
  filterOperatorBefore: 'is before',
  filterOperatorOnOrBefore: 'is on or before',
  filterOperatorIsEmpty: 'is empty',
  filterOperatorIsNotEmpty: 'is not empty',

  // Filter values text
  filterValueAny: 'cualquiera',
  filterValueTrue: 'verdadero',
  filterValueFalse: 'falso',

  // Column menu text
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Mostrar columnas',
  columnMenuFilter: 'Filtrar',
  columnMenuHideColumn: 'Esconder',
  columnMenuUnsort: 'Reordenar',
  columnMenuSortAsc: 'Ordenar ascendente',
  columnMenuSortDesc: 'Ordenar descendente',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,
  columnHeaderFiltersLabel: 'Mostrar filtros',
  columnHeaderSortIconLabel: 'Ordenar',

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} lineas seleccionadas`
      : `${count.toLocaleString()} línea seleccionada`,

  // Total rows footer text
  footerTotalRows: 'Total de lineas:',

  // Total visible rows footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Checkbox selection',

  // Boolean cell text
  booleanCellTrueLabel: 'true',
  booleanCellFalseLabel: 'false',

  // Actions cell more text
  actionsCellMore: 'more',

  // Used core components translation keys
  MuiTablePagination: {
  },
};
