import React, {FC, useState, useMemo} from 'react'
import { DataGrid,
  GridOverlay,
  GridColDef,
  GridRenderCellParams
} 
from '@mui/x-data-grid';

import {setDialog, setDialogType, setUserToUpdate}from'../../../store/actions/genericActions';
import {useDispatch } from 'react-redux';
import { User } from '../../../types/authTypes';
import { grid_esES_Text }from '../../../features/referenceData/reqTableDef';
import { dialogTypes } from '../../../features/referenceData/appLists';
import {formatCurrencyMxn } from '../../../features/functions/genericFunctions';

import AvatarCustomed from '../../UI/AvatarCustomed'

import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import PaidIcon from '@mui/icons-material/Paid';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

interface UserListProps {
  userList?: Array<User> | null
}

const UserListTable: FC<UserListProps> = (props) => {

    const {userList} = props

    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState<number>(10);
    const [columns, setColumns] = useState<any>()

    const rows = userList;
    
    const openDialogHandler = (userToEdit:User) => {
        dispatch<any>(setDialog(true))
        dispatch<any>(setDialogType(dialogTypes[3]))
        const saveUserInStore: boolean = true
        dispatch<any>(setUserToUpdate(saveUserInStore, userToEdit))
    }

  useMemo(() => {
    let reqColDef: GridColDef[] = [
        {field:'actions', headerName: 'Acciones', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Box className="d-flex-row-start-centered">
                        <Tooltip placement="top" title={`Generar reporte de ${params.row.fullName} (pendiente)`}>
                            <span>
                                <IconButton color="default">
                                    <SummarizeOutlinedIcon fontSize='small' />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip placement="top" title={`Administrar saldo de ${params.row.fullName}`}>
                            <span>
                                <IconButton color="secondary" onClick={()=>openDialogHandler(params.row)}>
                                    <PaidIcon fontSize='small' />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                )
            })
        },
        {field:'fullName', headerName: 'Nombre', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Box className="d-flex-row-start-centered" sx={{width:'100%'}}>
                        <AvatarCustomed 
                            userName={params.row.fullName} 
                            size={28} 
                            userPhoto={params.row.userAvatar}  
                            fontWeight={400}
                            fontSize={14}
                        />
                        <Typography variant="body2" sx={{fontWeight:500, ml:1}}>
                            {params.row.fullName}
                        </Typography>
                    </Box>
                )
            })
        },
        {field:'balance', headerName: 'Saldo', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
                const userBalance:string = params.row.balance ? formatCurrencyMxn(params.row.balance) : '$0 MXN'
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {userBalance}
                    </Typography>
                )
            })
        },
        {field:'abono', headerName: 'Abono', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
                const userDeposit:string = params.row.abono ? formatCurrencyMxn(params.row.abono) : '$0 MXN'
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {userDeposit}
                    </Typography>
                )
            })
        },
        {field:'pendingToPay', headerName: 'Pendiente por pagar', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
                const pendingToPay:string = params.row.balance && params.row.abono? formatCurrencyMxn(params.row.balance-params.row.abono) : '$0 MXN'
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {pendingToPay}
                    </Typography>
                )
            })
        },
        {field:'email', headerName: 'Email', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.email}
                    </Typography>
                )
            })
        },
        {field:'phone', headerName: 'Teléfono', align:'left', sortable:true, width:100, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.phone}
                    </Typography>
                )
            })
        }
      ]
      const columns: GridColDef[] = reqColDef;
      setColumns(columns)
  },[])    


  return (
      <div style={{ height: '85vh', width: '100%', backgroundColor:'white' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            { rows ? 
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        localeText={grid_esES_Text}
                        rows={rows}
                        columns={columns}
                        getRowId={(row)=>row.uid}
                        // editMode="row"
                        // checkboxSelection
                        density='standard'
                        disableSelectionOnClick
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 40]}
                        pagination
                        components={{
                            // Toolbar: CustomToolbar,
                            LoadingOverlay: GridOverlay
                        }}
                    />
                </div>
                :
                <Box className="d-flex-row-center-centered">
                    <Typography variant="h6" sx={{fontWeight:500}}>
                        No hay usuarios asignados
                    </Typography>
                </Box>
            }
          </div>
      </div>
  )
}

export default UserListTable