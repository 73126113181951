import React, {FC, useState, useMemo} from 'react'
import { DataGrid,
  GridOverlay,
  GridColDef, 
  GridToolbarContainer, 
  GridToolbarExport, 
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridRenderCellParams,
  GridValueFormatterParams
} 
from '@mui/x-data-grid';

import {getShipmentData, setShipments } from '../../../store/actions/apiActions';
import {RootState}from'../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { UserDirectoryFav } from '../../../types/storeTypes';
import { User } from '../../../types/authTypes';
import { ApiConnection, ShipmentInterface, LabelData } from '../../../types/apiTypes'
import { refreshRabeeWaybill } from '../../../store/actions/apiActions';
import { setLoading } from '../../../store/actions/genericActions';
import { grid_esES_Text }from '../../../features/referenceData/reqTableDef';
import { formatCurrencyMxn } from '../../../features/functions/genericFunctions';
import { translateDateFromDb, getLocalDateFromDb } from '../../../features/functions/handleDates';

import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import { stateToID } from "../../../features/referenceData/appLists";

interface ShipmentTableTable {
    shipments: any
    hideMenuActions?: boolean,
    directory?: Array<UserDirectoryFav>
}

const ShipmentTable: FC<ShipmentTableTable> = (props) => {

    const {shipments, hideMenuActions} = props

    const dispatch = useDispatch();
    const { loading } = useSelector((state: RootState) => state.generic);
    const {activeApis} = useSelector((state: RootState) => state.apis)
    const {stores} = useSelector((state: RootState) => state.stores)
    const  user:User|null = useSelector((state: RootState) => state.auth.user);
    const [pageSize, setPageSize] = useState<number>(100);
    const [columns, setColumns] = useState<any>()
    const [open, setOpen] = useState<boolean>(false)
    const userInStore: User | null = useSelector((state: RootState) => state.auth.user)
    const itemForcedValue = 1
    const rows = shipments.data;
    /*const openLabelHandler = (labelUrl: string) =>{
        window.open(labelUrl,'_blank')
    }*/

    const openLabelHandler = (labelInfo: LabelData) =>{
        if(labelInfo.type=="link"){
            window.open(labelInfo.data,'_blank')
        }
        if(labelInfo.type=="base64"){
            let pdfWindow = window.open("");
            if(pdfWindow)
                pdfWindow.document.write(
                    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(labelInfo.data||"") + "'></iframe>"
                )
        }

       
    }

    useMemo(() => {
        let reqColDef: GridColDef[] = user?.role == "Admin"?
        [
            {field:'actions', headerName: 'Acciones', align:'center', sortable:true, width:100, disableExport: true, renderCell: ((params: GridRenderCellParams) => {
                    const refreshStatus = (shipmentToTrack: ShipmentInterface) => {
                        dispatch<any>(setLoading(true))
                        const rabeeApi:Array<ApiConnection> | null = activeApis && activeApis.filter((api:ApiConnection)=> api.provider === 'rabee')
                        if(shipmentToTrack&&user&&rabeeApi!==null&&rabeeApi?.length>0){
                            const rabeeApiCredentials:ApiConnection = rabeeApi[0]
                            dispatch<any>(refreshRabeeWaybill(shipmentToTrack, user, rabeeApiCredentials))
                        }
                    }
                    return(
                        <Box sx={{width:'100%'}} className="d-flex-row-center-centered">
                            <Tooltip title="Refrescar status" placement='top'>
                                <span>
                                    <IconButton onClick={()=> refreshStatus(params.row)}>
                                        <RefreshIcon fontSize="small" className={loading ? 'rotate-anim' : ''} />
                                    </IconButton>

                                </span>
                            </Tooltip>
                            <Tooltip title="Ver guía" placement='right'>
                                <span>
                                    <IconButton onClick={()=> openLabelHandler(params.row.labelInfo)} color="secondary">
                                        <PictureAsPdfIcon fontSize="small" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    )
                })
            },
            {field:'provider', headerName: 'Carrier', align:'left', sortable:true, width:150, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.selectedCarrier&&params.row.selectedCarrier.carrier?params.row.selectedCarrier.carrier:'sin carrier'}
                    </Typography>
                )
                })
                
            },
            {field:'apiTrackNumber', headerName: 'No. Guía', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.apiTrackNumber}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${"#"+row.apiTrackNumber}`
            },
            {field:'Waybill', headerName: 'No. de trackeo', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.apiCreatedWaybill.waybill.waybillNo}
                        </Typography>
                    )
                })
            },
            {field:'destino', headerName: 'Destino', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {stateToID(params.row.apiCreatedWaybill.waybill.toState, "satid")}
                        </Typography>
                    )
                })
            },
            {field:'pesoCapturado', headerName: 'Peso Capturado', align:'left', sortable:true, width:130, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.packageToShip.peso}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${row.packageToShip.peso}`
            },
            {field:'pesoVolumentrico', headerName: 'Peso Volumetrico', align:'left', sortable:true, width:130, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.packageToShip.pesoVol}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${row.packageToShip.pesoVol}`
            },
            {field:'pesoFinal', headerName: 'Peso Final', align:'left', sortable:true, width:130, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.packageToShip.pesoFinal}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${row.packageToShip.pesoFinal}`
            },
            {field:'contenido', headerName: 'Contenido', align:'left', sortable:true, width:80, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.packageToShip.contenido}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${row.packageToShip.qty}`
            },
            {field:'createdAt', headerName: 'Creado el', align:'left', sortable:true, width:130, renderCell: ((params: GridRenderCellParams) => {
                    const translatedDate:Date= translateDateFromDb(params.row.createdAt)
                    const dateToDisplay: string = getLocalDateFromDb(translatedDate)
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {dateToDisplay}
                        </Typography>
                    )
                }),
                valueFormatter: ({ value }) => `${getLocalDateFromDb(translateDateFromDb(value)) }`,
            },
            {field:'frName', headerName: 'Remitente', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.apiCreatedWaybill.waybill.frName}
                    </Typography>
                )}),
                valueGetter: ({row}) => `${row.apiCreatedWaybill.waybill.frName}`,
            },
            {field:'createdByName', headerName: 'Cliente', align:'left', sortable:true, width:150, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.createdByName?params.row.createdByName:params.row.createdBy}
                    </Typography>
                )
                })
            },
            {field:'toName', headerName: 'Destinatario', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.apiCreatedWaybill.waybill.toName}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${row.apiCreatedWaybill.waybill.toName}`,
            },
            {field:'ServiceType', headerName: 'Servicio', align:'left', sortable:true, width:150, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.selectedCarrier&&params.row.selectedCarrier.serviceType
                            ?params.row.selectedCarrier.serviceType.name
                            :'No Serice Type'}
                    </Typography>
                )
                }),
                valueGetter: ({row}) => `${row.selectedCarrier.serviceType.name}`
            },
            {field:'qty', headerName: 'No. Guias', align:'left', sortable:true, width:80, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {1}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${1}`
            },
            /*{field:'status', headerName: 'Status', align:'left', sortable:true, width:160, disableExport: true, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.status?params.row.status:'En progreso'}
                        </Typography>
                    )
                })
            },*/
            {field:'totalPrice', headerName: 'Total Price $', align:'left', sortable:true, width:130, renderCell: ((params: GridRenderCellParams) => {
                    const amount= params.row.selectedCarrier && params.row.selectedCarrier.amount ? formatCurrencyMxn(params.row.selectedCarrier.amount) : '0MXN'
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {amount}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${row.selectedCarrier.amount}`
            },
            {field:'cost', headerName: 'costo', align:'left', sortable:true, width:130, renderCell: ((params: GridRenderCellParams) => {
                    const amount= params.row.selectedCarrier && params.row.selectedCarrier.cost ? formatCurrencyMxn(params.row.selectedCarrier.cost) : '0MXN'
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {amount}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${row.selectedCarrier.cost}`
            },
            /*{field:'createdAt', headerName: 'Creado el', align:'left', sortable:true, width:130, renderCell: ((params: GridRenderCellParams) => {
                    const translatedDate:Date= translateDateFromDb(params.row.createdAt)
                    const dateToDisplay: string = getLocalDateFromDb(translatedDate)
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {dateToDisplay}
                        </Typography>
                    )
                }),
                valueFormatter: ({ value }) => `${getLocalDateFromDb(translateDateFromDb(value)) }`,
            },
            
            
            {field:'frStreet', headerName: 'Dirección Remitente', align:'left', sortable:true, width:250, disableExport: true, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.apiCreatedWaybill.waybill.frStreet}
                        </Typography>
                    )
                })
            },
            {field:'toStreet', headerName: 'Dirección Destinatario', align:'left', sortable:true, width:250, disableExport: true, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.apiCreatedWaybill.waybill.toStreet}
                        </Typography>
                    )
                })
            },*/
            
            {field:'happyTrackNumber', headerName: 'No. Guía Happy', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.happyTrackNumber}
                        </Typography>
                    )
                })
            },
            {field:'groupName', headerName: 'Grupo', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.groupName}
                        </Typography>
                    )
                })
            },
            /*{field:'groupName', headerName: 'Grupo', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.groupName}
                        </Typography>
                    )
                })
            }*/
        ]
        :
        [
            {field:'actions', headerName: 'Acciones', align:'center', sortable:true, width:100, disableExport: true, renderCell: ((params: GridRenderCellParams) => {
                    const refreshStatus = (shipmentToTrack: ShipmentInterface) => {
                        dispatch<any>(setLoading(true))
                        const rabeeApi:Array<ApiConnection> | null = activeApis && activeApis.filter((api:ApiConnection)=> api.provider === 'rabee')
                        if(shipmentToTrack&&user&&rabeeApi!==null&&rabeeApi?.length>0){
                            const rabeeApiCredentials:ApiConnection = rabeeApi[0]
                            dispatch<any>(refreshRabeeWaybill(shipmentToTrack, user, rabeeApiCredentials))
                        }
                    }
                    return(
                        <Box sx={{width:'100%'}} className="d-flex-row-center-centered">
                            <Tooltip title="Refrescar status" placement='top'>
                                <span>
                                    <IconButton onClick={()=> refreshStatus(params.row)}>
                                        <RefreshIcon fontSize="small" className={loading ? 'rotate-anim' : ''} />
                                    </IconButton>

                                </span>
                            </Tooltip>
                            <Tooltip title="Ver guía" placement='right'>
                                <span>
                                    <IconButton onClick={()=> openLabelHandler(params.row.labelInfo)} color="secondary">
                                        <PictureAsPdfIcon fontSize="small" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    )
                })
            },
            {field:'provider', headerName: 'Carrier', align:'left', sortable:true, width:150, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.selectedCarrier&&params.row.selectedCarrier.carrier?params.row.selectedCarrier.carrier:'sin carrier'}
                    </Typography>
                )
                })
                
            },
            {field:'apiTrackNumber', headerName: 'No. Guía', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.apiTrackNumber}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${"#"+row.apiTrackNumber}`
            },
            {field:'Waybill', headerName: 'No. de trackeo', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.apiCreatedWaybill.waybill.waybillNo}
                        </Typography>
                    )
                })
            },
            {field:'destino', headerName: 'Destino', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {stateToID(params.row.apiCreatedWaybill.waybill.toState, "satid")}
                        </Typography>
                    )
                })
            },
            {field:'pesoCapturado', headerName: 'Peso Capturado', align:'left', sortable:true, width:130, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.packageToShip.peso}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${row.packageToShip.peso}`
            },
            {field:'pesoVolumentrico', headerName: 'Peso Volumetrico', align:'left', sortable:true, width:130, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.packageToShip.pesoVol}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${row.packageToShip.pesoVol}`
            },
            {field:'pesoFinal', headerName: 'Peso Final', align:'left', sortable:true, width:130, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.packageToShip.pesoFinal}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${row.packageToShip.pesoFinal}`
            },
            {field:'contenido', headerName: 'Contenido', align:'left', sortable:true, width:80, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.packageToShip.contenido}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${row.packageToShip.qty}`
            },
            {field:'createdAt', headerName: 'Creado el', align:'left', sortable:true, width:130, renderCell: ((params: GridRenderCellParams) => {
                    const translatedDate:Date= translateDateFromDb(params.row.createdAt)
                    const dateToDisplay: string = getLocalDateFromDb(translatedDate)
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {dateToDisplay}
                        </Typography>
                    )
                }),
                valueFormatter: ({ value }) => `${getLocalDateFromDb(translateDateFromDb(value)) }`,
            },
            {field:'frName', headerName: 'Remitente', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.apiCreatedWaybill.waybill.frName}
                    </Typography>
                )}),
                valueGetter: ({row}) => `${row.apiCreatedWaybill.waybill.frName}`,
            },
            {field:'createdByName', headerName: 'Cliente', align:'left', sortable:true, width:150, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.createdByName?params.row.createdByName:params.row.createdBy}
                    </Typography>
                )
                })
            },
            {field:'toName', headerName: 'Destinatario', align:'left', sortable:true, width:200, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.apiCreatedWaybill.waybill.toName}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${row.apiCreatedWaybill.waybill.toName}`,
            },
            {field:'ServiceType', headerName: 'Servicio', align:'left', sortable:true, width:150, renderCell: ((params: GridRenderCellParams) => {
                return(
                    <Typography variant="body2" sx={{fontWeight:500}}>
                        {params.row.selectedCarrier&&params.row.selectedCarrier.serviceType
                            ?params.row.selectedCarrier.serviceType.name
                            :'No Serice Type'}
                    </Typography>
                )
                }),
                valueGetter: ({row}) => `${row.selectedCarrier.serviceType.name}`
            },
            {field:'qty', headerName: 'No. Guias', align:'left', sortable:true, width:80, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {1}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${1}`
            },
            /*{field:'status', headerName: 'Status', align:'left', sortable:true, width:160, disableExport: true, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.status?params.row.status:'En progreso'}
                        </Typography>
                    )
                })
            },*/
            {field:'totalPrice', headerName: 'Total Price $', align:'left', sortable:true, width:130, renderCell: ((params: GridRenderCellParams) => {
                    const amount= params.row.selectedCarrier && params.row.selectedCarrier.amount ? formatCurrencyMxn(params.row.selectedCarrier.amount) : '0MXN'
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {amount}
                        </Typography>
                    )
                }),
                valueGetter: ({row}) => `${row.selectedCarrier.amount}`
            },
            /*{field:'createdAt', headerName: 'Creado el', align:'left', sortable:true, width:130, renderCell: ((params: GridRenderCellParams) => {
                    const translatedDate:Date= translateDateFromDb(params.row.createdAt)
                    const dateToDisplay: string = getLocalDateFromDb(translatedDate)
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {dateToDisplay}
                        </Typography>
                    )
                }),
                valueFormatter: ({ value }) => `${getLocalDateFromDb(translateDateFromDb(value)) }`,
            },
            
            
            {field:'frStreet', headerName: 'Dirección Remitente', align:'left', sortable:true, width:250, disableExport: true, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.apiCreatedWaybill.waybill.frStreet}
                        </Typography>
                    )
                })
            },
            {field:'toStreet', headerName: 'Dirección Destinatario', align:'left', sortable:true, width:250, disableExport: true, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.apiCreatedWaybill.waybill.toStreet}
                        </Typography>
                    )
                })
            },*/
            
            {field:'happyTrackNumber', headerName: 'No. Guía Happy', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.happyTrackNumber}
                        </Typography>
                    )
                })
            },
            {field:'groupName', headerName: 'Grupo', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.groupName}
                        </Typography>
                    )
                })
            },
            /*{field:'groupName', headerName: 'Grupo', align:'left', sortable:true, width:250, renderCell: ((params: GridRenderCellParams) => {
                    return(
                        <Typography variant="body2" sx={{fontWeight:500}}>
                            {params.row.groupName}
                        </Typography>
                    )
                })
            }*/
        ]
        const columns: GridColDef[] = reqColDef;
        setColumns(columns)
    },[
        activeApis,
        dispatch,
        user,
        shipments.isLoading
    ])    

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{padding:16, color:'grey.800'}}>
            <GridToolbarColumnsButton size="small" style={{marginLeft: 8, color:'grey.800'}} />
            <GridToolbarFilterButton style={{marginLeft: 8, color:'grey.800'}} />
            <GridToolbarDensitySelector size="small" style={{marginLeft: 8, color:'grey.800'}}/>
            <GridToolbarExport size="small" style={{marginLeft: 8, color:'grey.800'}} />
            </GridToolbarContainer>
        );
    }

    function ExpandedRow() {
        // const { row } = props;
        const [open, setOpen] = React.useState(false);
      
        return (
          <React.Fragment>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      History
                    </Typography>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      }

  return (
      <div style={{ height: '85vh', width: '100%', backgroundColor:'white' }}>
          <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1, overflowY: 'scroll' }}>
              {shipments.isBulkData?
                    <DataGrid 
                        localeText={grid_esES_Text}
                        rows={rows}
                        rowCount={shipments.count}
                        columns={columns}
                        getRowId={(row)=>row._id}
                        density='compact'
                        disableSelectionOnClick
                        //page={shipments.page}
                        //pageSize={shipments.data.length}
                        //onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[100]}
                        pagination
                        //paginationMode="server"
                        loading = {shipments.isLoading}
                        
                        components={{
                            Toolbar: !hideMenuActions ? CustomToolbar:null,
                            LoadingOverlay: GridOverlay
                        }}
                        componentsProps={{ toolbar: { csvOptions: { utf8WithBom: false } } }}
                    />
                    :
                    <DataGrid 
                      localeText={grid_esES_Text}
                      rows={rows}
                      rowCount={shipments.count}
                      columns={columns}
                      getRowId={(row)=>row._id}
                      density='compact'
                      disableSelectionOnClick
                      page={shipments.page}
                      pageSize={shipments.data.length}
                      //onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[100]}
                      pagination
                      paginationMode="server"
                      loading = {shipments.isLoading}
                      onPageChange={(newPage)=>{
                        dispatch<any>(setShipments(shipments.data, shipments.page, shipments.count, true, false));
                        dispatch<any>(getShipmentData(newPage, userInStore, shipments, stores));
                      }}
                      components={{
                          Toolbar: !hideMenuActions ? CustomToolbar:null,
                          LoadingOverlay: GridOverlay
                      }}
                      componentsProps={{ toolbar: { csvOptions: { utf8WithBom: false } } }}
                  />
                }
                  
              </div>
          </div>
      </div>
  )
}

export default ShipmentTable