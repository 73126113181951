import React, {FC, useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { RootState } from '../../store';

import { User, UserActivityState } from '../../types/authTypes';
import { signout } from '../../store/actions/authActions';
import { setRightDrawer, setShowForm} from '../../store/actions/genericActions';

import { roleTypeArray } from '../../features/referenceData/userLists';

import AvatarCustomed from '../UI/AvatarCustomed';

import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Box from '@mui/system/Box';
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import SellIcon from '@mui/icons-material/Sell';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import PaidIcon from '@mui/icons-material/Paid';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

const DrawerRight: FC = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location:any = useLocation();

    const user: User | null = useSelector((state: RootState) => state.auth.user);
    const userActivity: UserActivityState | null = useSelector((state: RootState) => state.auth.userActivityState);
    const {showForm, rightDrawer, loading} = useSelector((state: RootState) => state.generic);

    const [disableBoardlLink, setDisableBoardLink] = useState<boolean>(false)
    const [disableNewShipmentlLink, setNewShipmentLink] = useState<boolean>(false)
    const [disableQuoterLink, setDisableQuoterLink] = useState<boolean>(false)
    const [disableBalanceLink, setDisableBalanceLink] = useState<boolean>(false)
    const [disableMyShipmentLink, setDisableMyShipmentLink] = useState<boolean>(false)
    const [disableDirectoryLink, setDisableDirectoryLink] = useState<boolean>(false)
    const [disableAdminLink, setDisableAdminLink] = useState<boolean>(false)

    useEffect(()=>{

        if(location.pathname === '/dashboard'){
            setDisableBoardLink(true)
        } else{
            setDisableBoardLink(false)
        }
        if(location.pathname === '/newShipment'){
            setNewShipmentLink(true)
        } else{
            setNewShipmentLink(false)
        }
        if(location.pathname === '/quoter'){
            setDisableQuoterLink(true)
        } else{
            setDisableQuoterLink(false)
        }
        if(location.pathname === '/balance'){
            setDisableBalanceLink(true)
        } else{
            setDisableBalanceLink(false)
        }
        if(location.pathname === '/myShipments'){
            setDisableMyShipmentLink(true)
        } else{
            setDisableMyShipmentLink(false)
        }
        if(location.pathname === '/directory'){
            setDisableDirectoryLink(true)
        } else{
            setDisableDirectoryLink(false)
        }
        if(location.pathname === '/admin'){
            setDisableAdminLink(true)
        } else{
            setDisableAdminLink(false)
        }


    },[location, showForm])


    const closeRightDrawerHandler = () => {
        const statusFlag: boolean = rightDrawer
        dispatch<any>(setRightDrawer(!statusFlag))
    }
    const closeForms = () => {
        if(showForm){
            dispatch<any>(setShowForm(!showForm))
        }
        return
    }

    const navigateDashboardHandler = () =>{
        navigate('/dashboard') 
        closeForms()
        closeRightDrawerHandler()
    }
    const navigateNewShipmentHandler = () =>{
        navigate('/newShipment') 
        closeForms()
        closeRightDrawerHandler()
    }
    const navigateQuoterHandler = () =>{
        closeRightDrawerHandler()
        navigate('/quoter') 
        // dispatch<any>(setDialog(true))
        // dispatch<any>(setDialogType(dialogTypes[1]));
        closeForms()
    }
    const navigateBalanceHandler = () =>{
        navigate('/balance') 
        closeForms()
        closeRightDrawerHandler()
    }
    const navigateMyShipmentsHandler = () =>{
        navigate('/myShipments') 
        closeForms()
        closeRightDrawerHandler()
    }
    
    const navigateDirectoryHandler = () =>{
        navigate('/directory') 
        closeForms()
        closeRightDrawerHandler()
    }
    
    const navigateAdminHandler = () =>{
        navigate('/admin') 
        closeForms()
        closeRightDrawerHandler()
    }
    
    const navigateUserProfileHandler = (userId:string) =>{
        navigate("/userProfile", {state:{ id: userId}})
        closeForms()
        closeRightDrawerHandler()
    }
    
    const signOutHandler = () => {
        closeRightDrawerHandler()
        dispatch<any>(signout())
    }

    return (
        <Drawer
            variant="temporary"
            anchor="right"
            onClose={closeRightDrawerHandler}
            open={rightDrawer}
            SlideProps={{
                direction:'right',
                easing: 'in',
                in:true
            }}
            sx={{
                width: {xs:'70%', md:220},
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: {xs:'70%', md:'20%'},
                    boxSizing: 'border-box',
                },
              }}
            >
            <Toolbar sx={{mb:2}} />
            <Box sx={{ overflow: 'auto' }}>
                {user?
                    <Box sx={{width:'100%', mt:2}} className="d-flex-col-center-centered">
                        <AvatarCustomed 
                            userName={user.fullName} 
                            size={80} 
                            userPhoto={user.userAvatar}  
                            fontWeight={500}
                            fontSize={28}
                        />
                        <Typography variant="h6" sx={{fontWeight:600, mt:1}}>
                            ¡Hola {user.givenName}!
                        </Typography>
                        <Typography variant="body1" sx={{mb:3}}>
                            {user.email}
                        </Typography>
                        <LoadingButton
                            loading={loading}
                            size="small"
                            variant="contained"
                            sx={{mb:2}}
                            color="secondary"
                            disableElevation
                            onClick={() => navigateUserProfileHandler(user.uid)}
                        >
                            Ver perfil
                        </LoadingButton>
                    </Box>
                    :null
                }
                <List sx={{mt:1}}>
                    <ListItem disableGutters disablePadding>
                        <ListItemButton
                            dense
                            disableGutters
                            onClick={navigateDashboardHandler}
                            disabled={!userActivity?.active || disableBoardlLink ? true : false}
                            sx={{
                                textOverflow: 'ellipsis', p:0, px:2,
                                ":hover":{
                                color:'secondary.main'
                            }}}
                            >
                            <ListItemIcon>
                                { !disableBoardlLink ? 
                                    <LeaderboardOutlinedIcon sx={{color:'secondary.main', fontSize:24}}/>
                                    :
                                    <LeaderboardIcon sx={{color:'secondary.main', fontSize:24}}/>
                                }
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1" sx={{fontWeight:400}} component="h6" noWrap>
                                        Métricas
                                    </Typography>       
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                    {/* { user && user.currentStore ? */}
                        <ListItem disableGutters disablePadding>
                            <ListItemButton
                                dense
                                disableGutters
                                onClick={navigateNewShipmentHandler}
                                disabled={!userActivity?.active || disableNewShipmentlLink ? true : false}
                                sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                    color:'secondary.main'
                                }}}
                                >
                                <ListItemIcon>
                                    {!disableNewShipmentlLink ? 
                                        <Inventory2OutlinedIcon sx={{color:'secondary.main', fontSize:24}}/>
                                        :
                                        <Inventory2Icon sx={{color:'secondary.main', fontSize:24}}/>
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" sx={{fontWeight:400}} component="h6" noWrap>
                                            Crear envíos
                                        </Typography>       
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                        {/* :null
                     } */}
                    <ListItem disableGutters disablePadding>
                        <ListItemButton
                            dense
                            disableGutters
                            onClick={navigateQuoterHandler}
                            disabled={!userActivity?.active || disableQuoterLink ? true : false}
                            sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                color:'secondary.main'
                            }}}
                            >
                            <ListItemIcon>
                                {!disableQuoterLink ? 
                                    <SellOutlinedIcon sx={{color:'secondary.main', fontSize:24}}/>
                                    :
                                    <SellIcon sx={{color:'secondary.main', fontSize:24}}/>
                                }
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1" sx={{fontWeight:400}} component="h6" noWrap>
                                        Cotizador
                                    </Typography>       
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disableGutters disablePadding>
                        <ListItemButton
                            dense
                            disableGutters
                            onClick={navigateBalanceHandler}
                            disabled={!userActivity?.active || disableBalanceLink ? true : false}
                            sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                color:'secondary.main'
                            }}}
                            >
                            <ListItemIcon>
                                {!disableBalanceLink ? 
                                    <PaidOutlinedIcon sx={{color:'secondary.main', fontSize:24}}/>
                                    :
                                    <PaidIcon sx={{color:'secondary.main', fontSize:24}}/>
                                }
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1" sx={{fontWeight:400}} component="h6" noWrap>
                                        Agregar saldo
                                    </Typography>       
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disableGutters disablePadding>
                        <ListItemButton
                            dense
                            disableGutters
                            onClick={navigateMyShipmentsHandler}
                            disabled={!userActivity?.active || disableMyShipmentLink ? true : false}
                            sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                color:'secondary.main'
                            }}}
                            >
                            <ListItemIcon>
                                {!disableMyShipmentLink ? 
                                    <LocalShippingOutlinedIcon sx={{color:'secondary.main', fontSize:24}}/>
                                    :
                                    <LocalShippingIcon sx={{color:'secondary.main', fontSize:24}}/>
                                }
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1" sx={{fontWeight:400}} component="h6" noWrap>
                                        Mis envíos
                                    </Typography>       
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disableGutters disablePadding>
                        <ListItemButton
                            dense
                            disableGutters
                            onClick={navigateDirectoryHandler}
                            disabled={!userActivity?.active || disableDirectoryLink ? true : false}
                            sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                color:'secondary.main'
                            }}}
                            >
                            <ListItemIcon>
                                {!disableDirectoryLink ? 
                                    <AssignmentOutlinedIcon sx={{color:'secondary.main', fontSize:24}}/>
                                    :
                                    <AssignmentIcon sx={{color:'secondary.main', fontSize:24}}/>
                                }
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1" sx={{fontWeight:400}} component="h6" noWrap>
                                        Directorio
                                    </Typography>       
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                    { user && user.role && (user.role === roleTypeArray[1]||user.role === roleTypeArray[2]) ?
                        <ListItem disableGutters disablePadding>
                            <ListItemButton
                                dense
                                disableGutters
                                onClick={navigateAdminHandler}
                                disabled={!userActivity?.active || disableAdminLink ? true : false}
                                sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                    color:'secondary.main'
                                }}}
                                >
                                <ListItemIcon>
                                    {!disableAdminLink ?
                                        <AdminPanelSettingsOutlinedIcon sx={{color:'secondary.main', fontSize:24}}/>
                                        :
                                        <AdminPanelSettingsIcon sx={{color:'secondary.main', fontSize:24}}/>
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" sx={{fontWeight:400}} component="h6" noWrap>
                                            Administración
                                        </Typography>       
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                        :null
                    }
                    <ListItem disableGutters disablePadding>
                        <ListItemButton
                            onClick={signOutHandler}
                            dense
                            disableGutters
                            sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                color:'secondary.main'
                            }}}
                            >
                            <ListItemIcon>
                                <LogoutIcon sx={{color:'secondary.main', fontSize:24, transform:'rotate(180deg)'}}/>
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1" sx={{fontWeight:400}} component="h6" noWrap>
                                        Cerrar sesión
                                    </Typography>       
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    )
}

export default DrawerRight