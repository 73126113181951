import React, {FC} from 'react'
import { useSelector } from 'react-redux'
import { User } from '../../../types/authTypes';
import {RootState}from'../../../store';

import MemoizedCreateQuoteForm from '../../UI/forms/CreateQuoteForm';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const QuoterFormCard = () => {

  const user:User|null = useSelector((state:RootState)=> state.auth.user)
  return (
    <Container maxWidth='xl' sx={{bgcolor:'#F4F7F9', minHeight:'100vh'}}>
      <Toolbar sx={{mb:3}}/>
      <Typography variant='h6' component="h6" sx={{fontWeight:500, mb:2}}>Crear una cotización</Typography>
      {user?
         <MemoizedCreateQuoteForm user={user} />
        :null
      }
        
    </Container>
  )
}

export default QuoterFormCard