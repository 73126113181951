import React, { FC, useState, useEffect, useRef, ChangeEvent  }from 'react'
import { useSelector, useDispatch} from 'react-redux';
import es from 'date-fns/locale/es';

import { User} from '../../../types/authTypes';
import { RootState } from '../../../store';
import { updateUserInfo, updateUserAvatar } from '../../../store/actions/authActions';
import { setLoading, setShowForm} from '../../../store/actions/genericActions';
import { validateLengthInput } from '../../../features/functions/validatorField';
import { translateDate, translateDateFromDb } from '../../../features/functions/handleDates';

import AvatarCustomed from '../../UI/AvatarCustomed';

import Box from '@mui/system/Box'
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import LoadingButton from '@mui/lab/LoadingButton';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import CakeIcon from '@mui/icons-material/Cake';

interface UserType {
    user: User
}

const EditProfile: FC<UserType> = (props) => {

    const {user} = props
    const dispatch= useDispatch();
  
    let userUpdated: User | null = user;
    const { loading, showForm } = useSelector((state: RootState) => state.generic);
    const avatarInput = useRef<HTMLInputElement | null>(null)
  
    const [userName, setUserName] = useState<any>(user && user.fullName ? user.fullName : '');
    const [userBirthday, setUserBirthday] = useState<Date | undefined>(user && user.birthday ? translateDateFromDb(user.birthday) :undefined);
    const [userPhoneNmbr, setUserPhoneNmbr] = useState<string>(user && user.phoneNumber ? user.phoneNumber :'');
    const [userAbout, setUserAbout] = useState<string>(user && user.about ? user.about : '');
    const [formValid, setFormValid] = useState<boolean>(false);
    const [showCalendar, setShowCalendar] = useState<boolean>(false)
    const [dateForm, setDateForm] = useState<Date>(userBirthday ? new Date(userBirthday) : new Date())
    
    const [invalidUserName, setInvalidUserName] = useState<boolean>(false);
    const [invalidUserPhoneNumber, setInvalidUserPhoneNumber] = useState<boolean>(false);
    const [invalidUserAbout, setInvalidUserAbout] = useState<boolean>(false);

    useEffect(() => {
      if(
          (
            (userName !== '' && userName !== user.fullName) ||
            (userPhoneNmbr !== '' && userPhoneNmbr !== user.phoneNumber) ||
            (userBirthday && userBirthday !== user.birthday) ||
            (userAbout !== '' && userAbout !== user.about)
          ) 
          &&
          userName !== ''
      ){
        setFormValid(true)
      }
      if(
        (!userName || userName==='') ||
        ( invalidUserName ||
          invalidUserPhoneNumber ||
          invalidUserAbout
        )
        ){
          setFormValid(false)
        }
  
    }, [
        userName,
        userPhoneNmbr,
        userBirthday,
        userAbout,
        user.fullName,
        user.phoneNumber,
        user.birthday,
        user.about,
        invalidUserName,
        invalidUserPhoneNumber,
        invalidUserAbout,
        formValid
    ])
  
    const userNameHandler = (e:any) => {
      setUserName(e)
      setInvalidUserName(validateLengthInput(30, userName))
    }
    const userPhoneNmbrHandler = (e:any) => {
      setUserPhoneNmbr(e)
      setInvalidUserPhoneNumber(validateLengthInput(11, userPhoneNmbr))
    }
    // const userBirthdayHandler = (e:any) => {
    //   setDateForm(e)
    //   setUserBirthday(e)
    // }
    const userAboutHandler = (e:any) => {
      setUserAbout(e)
      setInvalidUserAbout(validateLengthInput(100, userAbout))
    }

    const toggleFormHandler = () => {
        const currentState = showForm
        dispatch<any>(setShowForm(!currentState))
    }
  
    const saveUserInfoHandler = () => {
      if(userUpdated && user && formValid){
          userUpdated = {
              ...user,
              fullName: userName,
              birthday: userBirthday,
              phoneNumber: userPhoneNmbr,
              about: userAbout
          }
          dispatch<any>(updateUserInfo(userUpdated, () => setLoading(true)));
          setFormValid(false)
          dispatch<any>(setShowForm(!showForm))
          
      }
  }
  
    const updateUserPhoto = () =>{
        if(avatarInput.current) {
            avatarInput.current.click()
        }   
    }
    
    const uploadPicture = (event:ChangeEvent<HTMLInputElement>|null) =>{
        dispatch<any>(setLoading(true))
        if(event?.target.files && user) {
            const avatarToSave: File|null = event && event.target ? event?.target.files[0] : null;
            if(avatarToSave && user) {
                dispatch<any>(updateUserAvatar(avatarToSave, user))
            }
        }
    }


    return (
        <Box sx={{ width:'100%'}}>
            <Card elevation={2}>
                <CardContent>
                    <Typography variant="subtitle1" component="h6" sx={{fontWeight:600, mb:3}}>
                        Editar información personal
                    </Typography>
                    <Box 
                        component="form"
                        sx={{
                            width:'100%',
                        }}
                        // onSubmit={submitHandler}
                        noValidate
                        className='d-flex-col-center-centered'
                        autoComplete="off"
                    >
                        <ButtonBase
                            onClick={updateUserPhoto}
                            >
                            <input
                                hidden
                                type='file' 
                                id='file' 
                                ref={avatarInput}
                                onChange={(e)=> uploadPicture(e)}
                                accept="image/png, image/gif, image/jpeg"
                            />
                            { loading ?
                                <CircularProgress color="secondary" />
                                :
                                <Box className='d-flex-col-center-centered'>
                                    <AvatarCustomed 
                                        userName={user?.fullName} 
                                        size={120} 
                                        userPhoto={user?.userAvatar}  
                                        fontWeight={600}
                                        fontSize={28}
                                    />
                                    <Typography sx={{my:2, fontWeight:400}} align="center" variant="body2">
                                        Cambiar foto
                                    </Typography>
                                </Box>
                            }
                        </ButtonBase>
                        <TextField
                            required
                            color="secondary"
                            value={userName}
                            fullWidth
                            size='small'
                            sx={{my:1}}
                            id="outlined-required"
                            onChange={(e:any) => userNameHandler(e.currentTarget.value)}
                            placeholder='Pedro Pascal'
                            label="Nombre completo"
                            type="text"
                            error={invalidUserName}
                            helperText={
                                invalidUserName?
                                // <Typography variant="body2" color="error">
                                    `Intenta un nombre más corto. ${userName.length}/25`
                                // </Typography>
                                :null
                            }
                        />
                        <TextField
                            required
                            color="secondary"
                            value={user.email}
                            disabled
                            fullWidth
                            size='small'
                            sx={{my:1}}
                            label="Correo electrónico"
                            type="email"
                        />
                        <TextField
                            required
                            color="secondary"
                            value={userPhoneNmbr}
                            fullWidth
                            size='small'
                            sx={{my:1}}
                            id="outlined-required"
                            onChange={(e:any) => userPhoneNmbrHandler(e.currentTarget.value)}
                            placeholder='ej. 3301827471'
                            label="Teléfono"
                            type="text"
                            error={invalidUserPhoneNumber}
                            helperText={
                                invalidUserPhoneNumber?
                                // <Typography variant="body2" color="error">
                                    `Ingresa 10 digitos sin guiones. ej: 3310394512`
                                // </Typography>
                                :null
                            }
                        />
                        <TextField
                            required
                            color="secondary"
                            value={userAbout}
                            fullWidth
                            size='small'
                            sx={{mt:1}}
                            multiline
                            rows={3}
                            id="outlined-required"
                            onChange={(e:any) => userAboutHandler(e.currentTarget.value)}
                            placeholder='Información extra'
                            label="Acerca de mi"
                            type="text"
                            error={invalidUserAbout}
                            helperText={
                                invalidUserAbout?
                                // <Typography variant="body2" color="error">
                                    `Intenta una nota más corta. ${userAbout.length}/50`
                                // </Typography>
                                :null
                            }
                        />
                        {/* <LoadingButton
                            loading={loading}
                            variant="outlined"
                            fullWidth
                            startIcon={<CakeIcon />}
                            color="secondary"
                            sx={{mt:2, textTransform:'capitalize'}}
                            onClick={() => setShowCalendar(!showCalendar)}
                        >
                            Cumpleaños  {userBirthday && user.birthday ? translateDate(userBirthday):''}
                        </LoadingButton>
                        { showCalendar?
                            <Calendar
                                color='#6421F8'
                                date={dateForm}
                                onChange={userBirthdayHandler}
                                locale={es}
                            />
                            :null
                        } */}
                    </Box>
                </CardContent>
                <CardActions>
                    <LoadingButton
                        loading={loading}
                        fullWidth
                        size="small"
                        color="secondary"
                        sx={{my:1, bgcolor:'grey.50'}}
                        variant="text"
                        onClick={toggleFormHandler}
                    >
                        Cancelar
                    </LoadingButton>
                    <LoadingButton
                        loading={loading}
                        fullWidth
                        size="small"
                        color="secondary"
                        sx={{my:1}}
                        variant="contained"
                        disabled={!formValid}
                        onClick={saveUserInfoHandler}
                    >
                        Guardar cambios
                    </LoadingButton>
                </CardActions>
            </Card>
        </Box>
    )
}

export default EditProfile