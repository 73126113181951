import dayjs from 'dayjs'
import 'dayjs/locale/es-mx'

dayjs.locale('es-mx')

function getToday(): Date {
    const today: Date = new Date();
    return today;
}

export function getMonthYear(): string {
    let monthYear: string
    monthYear = dayjs(getToday()).format('MMMM, YYYY')
    return monthYear
}
export function getTodayDate(): string {
    let todayDate: string
    todayDate = dayjs(getToday()).format('dddd DD MMM, YYYY')
    return todayDate
}

export function translateDate(date:Date): string {
    let translatedDate: string
    translatedDate = dayjs(date).format('MMMM DD')
    return translatedDate;
}

export function getLocalDateFromDb(date:Date): string {
    let translatedDate: string;
    translatedDate = dayjs(date).format('DD MMMM YYYY');
    return translatedDate;
}

export function translateDateFromDb(dbDate:any): Date {
    let translatedDate: Date = new Date(dbDate.seconds*1000);
    return translatedDate;
}