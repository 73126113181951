import React, { FC, useState, useEffect, useRef, ChangeEvent  }from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { roleTypeArray } from '../../../features/referenceData/userLists';

import { User, UserBasicData} from '../../../types/authTypes';
import { Store } from '../../../types/storeTypes';

import { RootState } from '../../../store';
import { createStore, setEditStore, updateStore, updateStoreAvatar } from '../../../store/actions/storeActions';
import { setLoading, setShowForm } from '../../../store/actions/genericActions';
import { validateLengthInput } from '../../../features/functions/validatorField';

import AvatarCustomed from '../../UI/AvatarCustomed';

import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';

interface UserType {
    user: User
}

const NewStoreForm: FC<UserType> = (props) => {

    const {user} = props
    const dispatch= useDispatch();  
  
    const { loading,showForm, usersAdmin } = useSelector((state: RootState) => state.generic);
    const { editStore } = useSelector((state: RootState) => state.stores);
    const avatarInput = useRef<HTMLInputElement | null>(null)

    const managerUsers: Array<User> | null = usersAdmin ? usersAdmin.filter((user:User) =>{
        return (user.role === roleTypeArray[1] || user.role === roleTypeArray[2]);
    }): null

    const [storeName, setStoreName] = useState<any>(editStore?editStore.storeName:'');
    const [storeAddress, setStoreAddress] = useState<any>(editStore?editStore.storeAddress:'');
    const [storeNeighborhood, setStoreNeighborhood] = useState<string>(editStore?editStore.storeNeighborhood:'');
    const [storeCity, setStoreCity] = useState<string>(editStore?editStore.storeCity:'');
    const [storePhone, setStorePhone] = useState<string>(editStore?editStore.storePhone:'');
    const [storeSupervisor, setStoreSupervisor] = useState<UserBasicData>(
        editStore && editStore.storeSupervisor ? editStore.storeSupervisor:
        {uid:user.uid, userName: user.fullName}
    );
    const [formValid, setFormValid] = useState<boolean>(false);
    
    const [invalidStoreName, setInvalidStoreName] = useState<boolean>(false);
    const [invalidStoreAddress, setInvalidStoreAddress] = useState<boolean>(false);
    const [invalidStoreNeighborhood, setInvalidStoreNeighborhood] = useState<boolean>(false);
    const [invalidStoreCity, setInvalidStoreCity] = useState<boolean>(false);
    const [invalidStorePhone, setInvalidStorePhone] = useState<boolean>(false);
    
    useEffect(() => {
      if(   (storeName !== '' &&
                storeAddress !== '' &&
                storeNeighborhood !== '' &&
                storeCity !== '' &&
                storePhone !== '') 
        && 
            (!invalidStoreName &&
                !invalidStoreAddress &&
                !invalidStoreNeighborhood &&
                !invalidStoreCity &&
                !invalidStorePhone)
        ){
        setFormValid(true)
      } else{
          setFormValid(false)
    }
  
    }, [
        storeName,
        storeAddress,
        storeNeighborhood,
        storeCity,
        storePhone,
        storeSupervisor,
        invalidStoreName,
        invalidStoreAddress,
        invalidStoreNeighborhood,
        invalidStoreCity,
        invalidStorePhone,
        formValid
    ])
  
    const storeNameHandler = (e:any) => {
      setStoreName(e)
      setInvalidStoreName(validateLengthInput(30, storeName))
    }
    const storeAddressHandler = (e:any) => {
      setStoreAddress(e)
      setInvalidStoreAddress(validateLengthInput(50, storeAddress))
    }
    const storeNeighborhoodHandler = (e:any) => {
      setStoreNeighborhood(e)
      setInvalidStoreNeighborhood(validateLengthInput(30, storeNeighborhood))
    }
    const storeCityHandler = (e:any) => {
      setStoreCity(e)
      setInvalidStoreCity(validateLengthInput(30, storeCity))
    }
    const storePhoneHandler = (e:any) => {
      setStorePhone(e)
      setInvalidStorePhone(validateLengthInput(10, storePhone))
    }
    const storeSupervisorHandler = (event: SelectChangeEvent) => {
        const selectedId: string = event.target.value as string
        const selectedSupervisor: any = managerUsers?.find((user:User) => user.uid === selectedId)

        const supervisor: UserBasicData = {
            uid:selectedSupervisor.uid,
            userName: selectedSupervisor.fullName
        }
        setStoreSupervisor(supervisor)
    }

    const toggleFormHandler = () => {
        const currentState = showForm
        if(showForm){
            dispatch<any>(setEditStore(undefined))
        }
        dispatch<any>(setShowForm(!currentState))
    }
  
    const saveStoreInfoHandler = () => {
      dispatch<any>(setLoading(true))
      if( user && formValid){

        const memberIdsToSave: Array<string> | null = usersAdmin ? usersAdmin
            .filter((user:User) => user.role === roleTypeArray[1] || user.uid === storeSupervisor.uid)
            .map((user:User) => user.uid)
        : null

        let storeData: Store = {
            _id: editStore ? editStore._id:'...',
            storeName,
            storeAddress,
            storeNeighborhood,
            storeCity,
            storePhone,
            storeSupervisor: storeSupervisor,
            members:memberIdsToSave ? memberIdsToSave : []
        }
        if(user) {
            editStore ?
            dispatch<any>(updateStore(storeData, user.uid)) :
            dispatch<any>(createStore(storeData, user))
            if(showForm){
            dispatch<any>(setEditStore(undefined))
        }
            clearFields()
            setFormValid(false)
            dispatch<any>(setShowForm(!showForm))
        }
      }
    }

    const clearFields = () => {
        setStoreName('')
        setStoreAddress('')
        setStoreNeighborhood('')
        setStoreCity('')
        setStorePhone('')
    }
  
    const updateStorePhoto = () =>{
        if(avatarInput.current) {
            avatarInput.current.click()
        }   
    }
    
    const uploadPicture = (event:ChangeEvent<HTMLInputElement>|null) =>{
        if(event?.target.files) {
            const avatarToSave: File|null = event && event.target ? event?.target.files[0] : null;
            if(avatarToSave && editStore && user) {
                dispatch<any>(setLoading(true))
                dispatch<any>(updateStoreAvatar(avatarToSave, editStore, user.uid))
                dispatch<any>(setShowForm(!showForm))
                if(showForm){
                    dispatch<any>(setEditStore(undefined))
                }
            }
        }
    }


    return (
        <Box sx={{ width:'100%'}}>
            <Card elevation={1} sx={{borderRadius:'30px'}}>
                <CardContent>
                    <Typography variant="subtitle1" component="h6" sx={{fontWeight:600, mb:3}}>
                        {editStore ? 'Editar':'Crear nuevo'} grupo
                    </Typography>
                    <Box 
                        component="form"
                        sx={{
                            width:'100%',
                        }}
                        // onSubmit={submitHandler}
                        noValidate
                        className='d-flex-col-center-centered'
                        autoComplete="off"
                        >
                        {editStore ? 
                            <ButtonBase
                                onClick={updateStorePhoto}
                                >
                                <input
                                    hidden
                                    type='file' 
                                    id='file' 
                                    ref={avatarInput}
                                    onChange={(e)=> uploadPicture(e)}
                                    accept="image/png, image/gif, image/jpeg"
                                />
                                { loading ?
                                    <CircularProgress color="secondary" />
                                    :
                                    <Box className='d-flex-col-center-centered'>
                                        <AvatarCustomed 
                                            userName={editStore?.storeName} 
                                            size={120} 
                                            userPhoto={editStore?.storeAvatar}  
                                            fontWeight={600}
                                            fontSize={28}
                                            variant="rounded"
                                        />
                                        <Typography sx={{my:2, fontWeight:400}} align="center" variant="body2">
                                            Cambiar foto
                                        </Typography>
                                    </Box>
                                }
                            </ButtonBase>
                            :
                            null
                        }
                        <TextField
                            required
                            color="secondary"
                            value={storeName}
                            fullWidth
                            size='small'
                            sx={{my:1}}
                            id="outlined-required"
                            onChange={(e:any) => storeNameHandler(e.currentTarget.value)}
                            autoFocus
                            placeholder='El Pitillal'
                            label="Nombre de la Franquicia"
                            type="text"
                            error={invalidStoreName}
                            helperText={
                                invalidStoreName?
                                // <Typography variant="body2" color="error">
                                    `Intenta un nombre más corto. ${storeName.length}/30`
                                // </Typography>
                                :null
                            }
                        />
                        <TextField
                            required
                            color="secondary"
                            value={storeAddress}
                            fullWidth
                            size='small'
                            sx={{my:1}}
                            id="outlined-required"
                            onChange={(e:any) => storeAddressHandler(e.currentTarget.value)}
                            placeholder='Calle Jose Lancaster #1233'
                            label="Dirección"
                            type="text"
                            error={invalidStoreAddress}
                            helperText={
                                invalidStoreAddress?
                                // <Typography variant="body2" color="error">
                                    `Intenta una dirección más corta. ${storeAddress.length}/50`
                                // </Typography>
                                :null
                            }
                        />
                        <TextField
                            required
                            color="secondary"
                            value={storeNeighborhood}
                            fullWidth
                            size='small'
                            sx={{my:1}}
                            id="outlined-required"
                            onChange={(e:any) => storeNeighborhoodHandler(e.currentTarget.value)}
                            placeholder='El Pitillal'
                            label="Colonia"
                            type="text"
                            error={invalidStoreNeighborhood}
                            helperText={
                                invalidStoreNeighborhood?
                                // <Typography variant="body2" color="error">
                                    `Intenta un nombre más corto. ${storeNeighborhood.length}/30`
                                // </Typography>
                                :null
                            }
                        />
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    color="secondary"
                                    value={storeCity}
                                    fullWidth
                                    size='small'
                                    sx={{my:1}}
                                    id="outlined-required"
                                    onChange={(e:any) => storeCityHandler(e.currentTarget.value)}
                                    placeholder='Guadalajara'
                                    label="Ciudad"
                                    type="text"
                                    error={invalidStoreCity}
                                    helperText={
                                        invalidStoreCity?
                                        // <Typography variant="body2" color="error">
                                        `Intenta un nombre más corto. ${storeCity.length}/30`
                                        // </Typography>
                                        :null
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    color="secondary"
                                    value={storePhone}
                                    fullWidth
                                    size='small'
                                    sx={{my:1}}
                                    id="outlined-required"
                                    onChange={(e:any) => storePhoneHandler(e.currentTarget.value)}
                                    placeholder='Ej. 3302191233'
                                    label="Teléfono"
                                    type="text"
                                    error={invalidStorePhone}
                                    helperText={
                                        invalidStorePhone?
                                        // <Typography variant="body2" color="error">
                                            `Intenta una nota más corta. ${storePhone.length}/10`
                                        // </Typography>
                                        :null
                                    }
                                />
                            </Grid>
                        </Grid>
                        <FormControl sx={{mt:1}} size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">Supervisor</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={storeSupervisor.uid}
                                label="Supervisor"
                                onChange={storeSupervisorHandler}
                            >
                                { managerUsers && managerUsers.map((userSelected:User, index:number) => {
                                        const userId:string = userSelected.uid;
                                        const userName:string = userSelected.fullName
                                        return(
                                            <MenuItem
                                                key={index} 
                                                value={userId}>
                                                    {userName}
                                            </MenuItem> 
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </CardContent>
                <CardActions>
                     <LoadingButton
                        loading={loading}
                        fullWidth
                        size="small"
                        color="secondary"
                        sx={{my:1, bgcolor:'grey.50'}}
                        variant="text"
                        onClick={toggleFormHandler}
                    >
                        Cancelar
                    </LoadingButton>
                    <LoadingButton
                        loading={loading}
                        color="secondary"
                        disableElevation
                        fullWidth
                        size="small"
                        sx={{my:1}}
                        variant="contained"
                        disabled={!formValid}
                        onClick={saveStoreInfoHandler}
                    >
                        {editStore ? 'Editar' : 'Agregar'} Franquicia
                    </LoadingButton>
                </CardActions>
            </Card>
        </Box>
    )
}

const MemoizedNewStoreForm = React.memo(NewStoreForm)

export default MemoizedNewStoreForm