import { StoreState, StoreAction, SET_STORES, SET_COMISSIONS, SET_EDIT_STORE, SET_CARRIER_PRICES, SET_CARRIER_PRICE_TO_EDIT } from '../../types/storeTypes'

const initialState: StoreState = {
    stores: null,
    comissions: null,
    carrierPrices:null,
    carrierPriceToEdit: null,
    editStore: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: StoreAction) => {
    switch(action.type) {
        case SET_STORES:
            return {
                ...state,
                stores: action.payload
            }
        case SET_COMISSIONS:
            return {
                ...state,
                comissions: action.payload
            }
        case SET_CARRIER_PRICES:
            return {
                ...state,
                carrierPrices: action.payload
            }
        case SET_CARRIER_PRICE_TO_EDIT:
            return {
                ...state,
                carrierPriceToEdit: action.payload
            }
        case SET_EDIT_STORE:
            return {
                ...state,
                editStore: action.payload
            }
        default:
            return state;
    }
}