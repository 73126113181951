import React, {FC, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { RootState } from '../../store';
import { User, UserActivityState } from '../../types/authTypes';
import { signout } from '../../store/actions/authActions';
import { setShowForm } from '../../store/actions/genericActions';

import { roleTypeArray } from '../../features/referenceData/userLists';

import GeneralPurposeDialog from '../UI/dialogs/GeneralPurposeDialog'

import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import SellIcon from '@mui/icons-material/Sell';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import PaidIcon from '@mui/icons-material/Paid';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip'

const LeftDrawer: FC = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location:any = useLocation();

    const user: User | null = useSelector((state: RootState) => state.auth.user);
    const userActivity: UserActivityState | null = useSelector((state: RootState) => state.auth.userActivityState);
    const {showForm} = useSelector((state: RootState) => state.generic);

    const [disableBoardlLink, setDisableBoardLink] = useState<boolean>(false)
    const [disableNewShipmentlLink, setNewShipmentLink] = useState<boolean>(false)
    const [disableQuoterLink, setDisableQuoterLink] = useState<boolean>(false)
    const [disableBalanceLink, setDisableBalanceLink] = useState<boolean>(false)
    const [disableMyShipmentLink, setDisableMyShipmentLink] = useState<boolean>(false)
    const [disableDirectoryLink, setDisableDirectoryLink] = useState<boolean>(false)
    const [disableAdminLink, setDisableAdminLink] = useState<boolean>(false)
    const [collapsedDrawer, setCollapsedDrawer] = useState(true)


    useEffect(()=>{

        if(location.pathname === '/dashboard'){
            setDisableBoardLink(true)
        } else{
            setDisableBoardLink(false)
        }
        if(location.pathname === '/newShipment'){
            setNewShipmentLink(true)
        } else{
            setNewShipmentLink(false)
        }
        if(location.pathname === '/quoter'){
            setDisableQuoterLink(true)
        } else{
            setDisableQuoterLink(false)
        }
        if(location.pathname === '/balance'){
            setDisableBalanceLink(true)
        } else{
            setDisableBalanceLink(false)
        }
        if(location.pathname === '/myShipments'){
            setDisableMyShipmentLink(true)
        } else{
            setDisableMyShipmentLink(false)
        }
        if(location.pathname === '/directory'){
            setDisableDirectoryLink(true)
        } else{
            setDisableDirectoryLink(false)
        }
        if(location.pathname === '/admin'){
            setDisableAdminLink(true)
        } else{
            setDisableAdminLink(false)
        }


    },[location, showForm])

    const closeForms = () => {
        if(showForm){
            dispatch<any>(setShowForm(!showForm))
        }
        return
    }


    const navigateDashboardHandler = () =>{
        navigate('/dashboard') 
        closeForms()
    }
    const navigateNewShipmentHandler = () =>{
        navigate('/newShipment')
        closeForms()
    }
    const navigateQuoterHandler = () =>{
        navigate('/quoter')        
        // dispatch<any>(setDialog(true))
        // dispatch<any>(setDialogType(dialogTypes[1]));
        closeForms()
    }
    const navigateBalanceHandler = () =>{
        navigate('/balance') 
        closeForms()
    }
    const navigateMyShipmentsHandler = () =>{
        navigate('/myShipments') 
        closeForms()
    }
    const navigateDirectoryHandler = () =>{
        navigate('/directory') 
        closeForms()
    }
    const navigateAdminHandler = () =>{
        navigate('/admin') 
        closeForms()
    }

    const signOutHandler = () => {
        dispatch<any>(signout())
    }

    const handleCollapseDrawer = () => {
        setCollapsedDrawer(!collapsedDrawer)
    }

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            sx={{
                width: collapsedDrawer? 50: 220,
                flexShrink: 0,
                display: {
                    xs:'none',
                    md:'block'
                },
                '& .MuiDrawer-paper': {
                    width: collapsedDrawer? 50: 220,
                bgcolor:'secondary.main',
                color:'#fff',
                boxSizing: 'border-box',
                },
            }}
            >
            <Toolbar sx={{mb:2}} />
            {user ? 
                <GeneralPurposeDialog user={user} />
                :null
            }
            <Box sx={{ overflow: 'auto' }}>
                <List>
                    <ListItem disableGutters>
                        <ListItemButton
                            dense
                            disableGutters
                            onClick={handleCollapseDrawer}
                            sx={{p:0, px:2, ":hover":{
                                color:'#fff'
                            }}}
                            >
                            <ListItemIcon>
                                {collapsedDrawer ?
                                    <ChevronRightIcon sx={{color:'#fff', fontSize:24}}/>
                                    :
                                    <ChevronLeftIcon sx={{color:'#fff', fontSize:24}}/>
                                }
                            </ListItemIcon>    
                        </ListItemButton>
                    </ListItem>
                    <ListItem disableGutters>
                        {collapsedDrawer ? 
                            <Tooltip
                                title="Métricas"
                                placement="right-end"
                                arrow
                                >
                                <span>
                                    <ListItemButton
                                        dense
                                        disableGutters
                                        onClick={navigateDashboardHandler}
                                        disabled={!userActivity?.active || disableBoardlLink ? true : false}
                                        sx={{
                                            textOverflow: 'ellipsis', p:0, px:2,
                                            ":hover":{
                                            color:'#fff'
                                        }}}
                                    >
                                        { !disableBoardlLink ? 
                                            <LeaderboardOutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                            :
                                            <LeaderboardIcon sx={{color:'#fff', fontSize:24}}/>
                                        }
                                    </ListItemButton>
                                </span>
                            </Tooltip>
                            :
                            <ListItemButton
                                dense
                                disableGutters
                                onClick={navigateDashboardHandler}
                                disabled={!userActivity?.active || disableBoardlLink ? true : false}
                                sx={{
                                    textOverflow: 'ellipsis', p:0, px:2,
                                    ":hover":{
                                    color:'#fff'
                                }}}
                                >
                                <ListItemIcon>
                                    { !disableBoardlLink ? 
                                        <LeaderboardOutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                        :
                                        <LeaderboardIcon sx={{color:'#fff', fontSize:24}}/>
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" sx={{fontWeight:600}} component="h6" noWrap>
                                            Métricas
                                        </Typography>       
                                    }
                                />
                            </ListItemButton>
                        }
                    </ListItem>
                    <ListItem disableGutters>
                        {collapsedDrawer ? 
                            <Tooltip
                                title="Crear envío"
                                placement="right-end"
                                arrow
                                >
                                <span>
                                    <ListItemButton
                                        dense
                                        disableGutters
                                        onClick={navigateNewShipmentHandler}
                                        disabled={!userActivity?.active || disableNewShipmentlLink ? true : false}
                                        sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                            color:'#fff'
                                        }}}
                                    >
                                        {!disableNewShipmentlLink ? 
                                            <Inventory2OutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                            :
                                            <Inventory2Icon sx={{color:'#fff', fontSize:24}}/>
                                        }
                                    </ListItemButton>
                                </span>
                            </Tooltip>
                            :
                            <ListItemButton
                                dense
                                disableGutters
                                onClick={navigateNewShipmentHandler}
                                disabled={!userActivity?.active || disableNewShipmentlLink ? true : false}
                                sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                    color:'#fff'
                                }}}
                                >
                                <ListItemIcon>
                                    {!disableNewShipmentlLink ? 
                                        <Inventory2OutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                        :
                                        <Inventory2Icon sx={{color:'#fff', fontSize:24}}/>
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" sx={{fontWeight:600}} component="h6" noWrap>
                                            Crear envío
                                        </Typography>       
                                    }
                                />
                            </ListItemButton>
                        }
                    </ListItem>
                    <ListItem disableGutters>
                        {collapsedDrawer ? 
                            <Tooltip
                                title="Cotizador"
                                placement="right-end"
                                arrow
                                >
                                <span>
                                    <ListItemButton
                                        dense
                                        disableGutters
                                        onClick={navigateQuoterHandler}
                                        disabled={!userActivity?.active || disableQuoterLink ? true : false}
                                        sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                            color:'#fff'
                                        }}}
                                    >
                                        {!disableQuoterLink ? 
                                            <SellOutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                            :
                                            <SellIcon sx={{color:'#fff', fontSize:24}}/>
                                        }
                                    </ListItemButton>
                                </span>
                            </Tooltip>
                            :
                            <ListItemButton
                                dense
                                disableGutters
                                onClick={navigateQuoterHandler}
                                disabled={!userActivity?.active || disableQuoterLink ? true : false}
                                sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                    color:'#fff'
                                }}}
                                >
                                <ListItemIcon>
                                    {!disableQuoterLink ? 
                                        <SellOutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                        :
                                        <SellIcon sx={{color:'#fff', fontSize:24}}/>
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" sx={{fontWeight:600}} component="h6" noWrap>
                                            Cotizador
                                        </Typography>       
                                    }
                                />
                            </ListItemButton>
                        }
                    </ListItem>
                    <ListItem disableGutters>
                        {collapsedDrawer ? 
                            <Tooltip
                                title="Agregar saldo"
                                placement="right-end"
                                arrow
                                >
                                <span>
                                    <ListItemButton
                                        dense
                                        disableGutters
                                        onClick={navigateBalanceHandler}
                                        disabled={!userActivity?.active || disableBalanceLink ? true : false}
                                        sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                            color:'#fff'
                                        }}}
                                    >
                                        {!disableBalanceLink ? 
                                            <PaidOutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                            :
                                            <PaidIcon sx={{color:'#fff', fontSize:24}}/>
                                        }
                                    </ListItemButton>
                                </span>
                            </Tooltip>
                            :
                            <ListItemButton
                                dense
                                disableGutters
                                onClick={navigateBalanceHandler}
                                disabled={!userActivity?.active || disableBalanceLink ? true : false}
                                sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                    color:'#fff'
                                }}}
                                >
                                <ListItemIcon>
                                    {!disableBalanceLink ? 
                                        <PaidOutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                        :
                                        <PaidIcon sx={{color:'#fff', fontSize:24}}/>
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" sx={{fontWeight:600}} component="h6" noWrap>
                                            Agregar saldo
                                        </Typography>       
                                    }
                                />
                            </ListItemButton>
                        }
                    </ListItem>
                    <ListItem disableGutters>
                        {collapsedDrawer ? 
                            <Tooltip
                                title="Mis envíos"
                                placement="right-end"
                                arrow
                                >
                                <span>
                                    <ListItemButton
                                        dense
                                        disableGutters
                                        onClick={navigateMyShipmentsHandler}
                                        disabled={!userActivity?.active || disableMyShipmentLink ? true : false}
                                        sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                            color:'#fff'
                                        }}}
                                    >
                                        {!disableMyShipmentLink ? 
                                            <LocalShippingOutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                            :
                                            <LocalShippingIcon sx={{color:'#fff', fontSize:24}}/>
                                        }
                                    </ListItemButton>
                                </span>
                            </Tooltip>
                            :
                            <ListItemButton
                                dense
                                disableGutters
                                onClick={navigateMyShipmentsHandler}
                                disabled={!userActivity?.active || disableMyShipmentLink ? true : false}
                                sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                    color:'#fff'
                                }}}
                                >
                                <ListItemIcon>
                                    {!disableMyShipmentLink ? 
                                        <LocalShippingOutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                        :
                                        <LocalShippingIcon sx={{color:'#fff', fontSize:24}}/>
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" sx={{fontWeight:600}} component="h6" noWrap>
                                            Mis envíos
                                        </Typography>       
                                    }
                                />
                            </ListItemButton>
                        } 
                    </ListItem>
                    <ListItem disableGutters>
                        {collapsedDrawer ? 
                            <Tooltip
                                title="Directorio"
                                placement="right-end"
                                arrow
                                >
                                <span>
                                    <ListItemButton
                                        dense
                                        disableGutters
                                        onClick={navigateDirectoryHandler}
                                        disabled={!userActivity?.active || disableDirectoryLink ? true : false}
                                        sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                            color:'#fff'
                                        }}}
                                    >
                                        {!disableDirectoryLink ? 
                                            <AssignmentOutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                            :
                                            <AssignmentIcon sx={{color:'#fff', fontSize:24}}/>
                                        }
                                    </ListItemButton>
                                </span>
                            </Tooltip>
                            :
                            <ListItemButton
                                dense
                                disableGutters
                                onClick={navigateDirectoryHandler}
                                disabled={!userActivity?.active || disableDirectoryLink ? true : false}
                                sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                    color:'#fff'
                                }}}
                                >
                                <ListItemIcon>
                                    {!disableDirectoryLink ? 
                                        <AssignmentOutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                        :
                                        <AssignmentIcon sx={{color:'#fff', fontSize:24}}/>
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" sx={{fontWeight:600}} component="h6" noWrap>
                                            Directorio
                                        </Typography>       
                                    }
                                />
                            </ListItemButton>
                        }
                    </ListItem>
                    { user && user.role && (user.role === roleTypeArray[1]||user.role === roleTypeArray[2]) ?
                        <ListItem disableGutters>
                            {collapsedDrawer ? 
                                <Tooltip
                                    title="Administración"
                                    placement="right-end"
                                    arrow
                                    >
                                    <span>
                                        <ListItemButton
                                            dense
                                            disableGutters
                                            onClick={navigateAdminHandler}
                                            disabled={!userActivity?.active || disableAdminLink ? true : false}
                                            sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                                color:'#fff'
                                            }}}
                                        >
                                            {!disableAdminLink ?
                                                <AdminPanelSettingsOutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                                :
                                                <AdminPanelSettingsIcon sx={{color:'#fff', fontSize:24}}/>
                                            }
                                        </ListItemButton>
                                    </span>
                                </Tooltip>
                                :
                                <ListItemButton
                                    dense
                                    disableGutters
                                    onClick={navigateAdminHandler}
                                    disabled={!userActivity?.active || disableAdminLink ? true : false}
                                    sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                        color:'#fff'
                                    }}}
                                    >
                                    <ListItemIcon>
                                        {!disableAdminLink ?
                                            <AdminPanelSettingsOutlinedIcon sx={{color:'#fff', fontSize:24}}/>
                                            :
                                            <AdminPanelSettingsIcon sx={{color:'#fff', fontSize:24}}/>
                                        }
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1" sx={{fontWeight:600}} component="h6" noWrap>
                                                Administración
                                            </Typography>       
                                        }
                                    />
                                </ListItemButton>
                            }
                        </ListItem>
                        :null
                    }
                    <ListItem disableGutters>
                        {collapsedDrawer ? 
                            <Tooltip
                                title="Cerrar sesión"
                                placement="right-end"
                                arrow
                                >
                                <span>
                                    <ListItemButton
                                        onClick={signOutHandler}
                                        dense
                                        disableGutters
                                        sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                            color:'#fff'
                                        }}}
                                    >
                                        <LogoutIcon sx={{color:'#fff', fontSize:24, transform:'rotate(180deg)'}}/>
                                    </ListItemButton>
                                </span>
                            </Tooltip>
                            :
                            <ListItemButton
                                onClick={signOutHandler}
                                dense
                                disableGutters
                                sx={{textOverflow: 'ellipsis',p:0, px:2, ":hover":{
                                    color:'#fff'
                                }}}
                                >
                                <ListItemIcon>
                                    <LogoutIcon sx={{color:'#fff', fontSize:24, transform:'rotate(180deg)'}}/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" sx={{fontWeight:600}} component="h6" noWrap>
                                            Cerrar sesión
                                        </Typography>       
                                    }
                                />
                            </ListItemButton>
                        }
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    )
}

export default LeftDrawer