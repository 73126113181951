import { configureStore, combineReducers } from '@reduxjs/toolkit';

import thunk from 'redux-thunk';
// import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

import authReducer from "./reducers/authReducer";
import genericReducer from "./reducers/genericReducer";
import storeReducer from "./reducers/storeReducer";
import apiReducer from './reducers/apiReducer';

import firebase from "../firebase/config";
import { firebaseReducer, getFirebase } from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore';

const rrfConfig = { // react redux firebase config
    userProfile: 'users',
    useFirestoreForProfile: true
}

const persistConfig = {
    key:'root',
    storage,
    whitelist:['auth', 'stores', 'firestore','firebase']
}

const rootReducer = combineReducers({
    auth: authReducer,
    generic: genericReducer,
    stores: storeReducer,
    apis: apiReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)
const thunkFirebase = thunk.withExtraArgument({getFirebase})

let store = configureStore({
    reducer: persistedReducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(thunkFirebase)
    middleware: [thunkFirebase]
});

let persistor = persistStore(store)

let exportedStore = {store, persistor}

export const rrfProps = { // react redux firebase props
    firebase,
    config:rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
}
        
export type RootState = ReturnType<typeof rootReducer>;

export default exportedStore;