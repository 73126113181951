
import { type } from 'os';
import { ShipmentInterface } from './apiTypes';
import { User } from './authTypes'
import { UserDirectoryFav, PackageBasicData, OptionsForShipment, CarrierPrice, carrierExtendedZone} from './storeTypes'

export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_SUCCESS = 'SET_SUCCESS';
export const SET_DIALOG = 'SET_DIALOG';
export const SET_DIALOG_TYPE = 'SET_DIALOG_TYPE';
export const SET_DIALOG_BODY = 'SET_DIALOG_BODY';
export const SET_SHOW_FORM = 'SET_SHOW_FORM';
export const SET_USER_DIRECTORY = 'SET_USER_DIRECTORY';
export const SET_ORIGIN_DIR_SHIPMENT = 'SET_ORIGIN_DIR_SHIPMENT';
export const SET_DESTINY_DIR_SHIPMENT = 'SET_DESTINY_DIR_SHIPMENT';
export const SET_PACKAGE_TO_SHIP = 'SET_PACKAGE_TO_SHIP';
export const SET_OPTIONS_FOR_SHIPMENT = 'SET_OPTIONS_FOR_SHIPMENT';
export const SET_SELECTED_PRICE_CARRIER = 'SET_SELECTED_PRICE_CARRIER';
export const SET_USERS_ADMIN = 'SET_USERS_ADMIN';
export const SET_COMISSION_TYPES = 'SET_COMISSION_TYPES';
export const SET_NEW_DOC_ID = 'SET_NEW_DOC_ID';
export const SET_RIGHT_DRAWER = 'SET_RIGHT_DRAWER';
export const SET_LEFT_DRAWER = 'SET_LEFT_DRAWER';
export const SET_UPDATE_USER = 'SET_UPDATE_USER';
export const SET_ORIGIN_EXTZONE = 'SET_ORIGIN_EXTZONE';
export const SET_DESTINATION_EXTZONE = 'SET_DESTINATION_EXTZONE';
export const GRAL_TEXT = 'GRAL_TEXT';
export const RATING_UPDATE = 'RATING_UPDATE';
export const SET_PUBLIC_PRICE = 'SET_PUBLIC_PRICE'
export const SET_WAYBILL_AVAILABLE = 'SET_WAYBILL_AVAILABLE'

export interface GenericState {
    loading: boolean;
    error: string;
    success: string;
    dialog: boolean;
    dialogType: string;
    dialogBody:ShipmentInterface | null;
    showForm: boolean;
    publicPrice: boolean;
    userDirectory: Array<UserDirectoryFav> | null;
    usersAdmin: Array<User> | null;
    userToUpdate: User | null;
    originDirectionShipment:[UserDirectoryFav, boolean, string ] | null;
    destinyDirectionShipment:[UserDirectoryFav, boolean, string ] | null;
    packageToShip: PackageBasicData | null;
    optionsForShipment: OptionsForShipment | null;
    selectedPriceCarrier: CarrierPrice| null;
    newDocId: string;
    rightDrawer: boolean;
    leftDrawer: boolean;
    gralText :string;
    originExtendedZone: {isAvailable : boolean, carriers: Array<carrierExtendedZone>};
    destinationExtendedZone: {isAvailable : boolean, carriers: Array<carrierExtendedZone>};
    carrierRatings : Array<any>;
    //waybillAvailable: boolean;
}

//actions

interface SetLoadingAction {
    type: typeof SET_LOADING;
    payload: boolean;
}

interface SetErrorAction {
    type: typeof SET_ERROR;
    payload: string;
}

interface SetSuccessAction {
    type: typeof SET_SUCCESS;
    payload: string;
}

interface SetDialogAction {
    type: typeof SET_DIALOG;
    payload: boolean;
}

interface SetUserToUpdateAction {
    type: typeof SET_UPDATE_USER;
    payload: User | null
}

interface SetDialogTypeAction {
    type: typeof SET_DIALOG_TYPE;
    payload: string
}

interface SetDialogBodyAction {
    type: typeof SET_DIALOG_BODY;
    payload: ShipmentInterface | null
}

interface SetShowFormAction {
    type: typeof SET_SHOW_FORM;
    payload: boolean;
}

interface SetPublicPrice {
    type: typeof SET_PUBLIC_PRICE;
    payload: boolean;
}

interface SetNewDocId {
    type: typeof SET_NEW_DOC_ID
    payload: string;
}

interface SetRightDrawer {
    type: typeof SET_RIGHT_DRAWER
    payload: boolean;
}

interface SetLeftDrawer {
    type: typeof SET_LEFT_DRAWER
    payload: boolean;
}

interface SetUsersAdminAction {
    type: typeof SET_USERS_ADMIN;
    payload: Array<User>;
} 

interface SetUserDirectoryAction {
    type: typeof SET_USER_DIRECTORY;
    payload: Array<UserDirectoryFav>;
} 

interface SetOriginDirShipment {
    type: typeof SET_ORIGIN_DIR_SHIPMENT;
    payload: [UserDirectoryFav, boolean, string] | null
}

interface SetDestinyDirShipment {
    type: typeof SET_DESTINY_DIR_SHIPMENT;
    payload: [UserDirectoryFav, boolean, string] | null
}

interface SetSelectedPriceCarrier {
    type: typeof SET_SELECTED_PRICE_CARRIER;
    payload: CarrierPrice | null
}

interface SetPackageToShip {
    type: typeof SET_PACKAGE_TO_SHIP;
    payload: PackageBasicData | null
}

interface SetOptionsForShipment {
    type: typeof SET_OPTIONS_FOR_SHIPMENT;
    payload: OptionsForShipment | null
}

interface setTextMessage{
    type: typeof GRAL_TEXT;
    payload: string;
}

interface setOriginExtendedZone{
    type: typeof SET_ORIGIN_EXTZONE;
    payload: {isAvailable : boolean, carriers: Array<carrierExtendedZone>};
}

interface setDestinationExtendedZone{
    type: typeof SET_DESTINATION_EXTZONE;
    payload: {isAvailable : boolean, carriers: Array<carrierExtendedZone>};
}

interface updateCarrierRatings{
    type: typeof RATING_UPDATE;
    payload: any | null;
}

/*interface setWaybillAvailable {
    type: typeof SET_WAYBILL_AVAILABLE;
    payload: boolean;
}*/

export type GenericAction = SetLoadingAction | SetErrorAction | SetSuccessAction 
| SetDialogAction | SetDialogBodyAction | SetUserToUpdateAction | SetDialogTypeAction | SetShowFormAction 
| SetNewDocId | SetRightDrawer | SetLeftDrawer | SetUsersAdminAction | SetUserDirectoryAction
| SetOriginDirShipment | SetDestinyDirShipment | SetPackageToShip | SetOptionsForShipment | SetSelectedPriceCarrier 
| setTextMessage | setDestinationExtendedZone | setOriginExtendedZone | updateCarrierRatings | SetPublicPrice