import { 
    GenericAction, 
    GenericState, 
    SET_DIALOG, 
    SET_DIALOG_TYPE,
    SET_DIALOG_BODY,
    SET_SHOW_FORM,
    SET_LOADING, 
    SET_ERROR, 
    SET_SUCCESS, 
    SET_NEW_DOC_ID,
    SET_USERS_ADMIN,
    SET_RIGHT_DRAWER,
    SET_LEFT_DRAWER,
    SET_USER_DIRECTORY,
    SET_ORIGIN_DIR_SHIPMENT,
    SET_DESTINY_DIR_SHIPMENT,
    SET_PACKAGE_TO_SHIP,
    SET_OPTIONS_FOR_SHIPMENT,
    SET_SELECTED_PRICE_CARRIER,
    SET_UPDATE_USER,
    GRAL_TEXT,
    SET_ORIGIN_EXTZONE,
    SET_DESTINATION_EXTZONE,
    RATING_UPDATE,
    SET_PUBLIC_PRICE,
} from '../../types/genericTypes'

const initialState: GenericState = {
    loading: false,
    error: '',
    success: '',
    dialog: false,
    dialogType: '',
    dialogBody: null,
    showForm: false,
    publicPrice: true,
    userDirectory: null,
    originDirectionShipment:null,
    destinyDirectionShipment:null,
    packageToShip: null,
    optionsForShipment: null,
    selectedPriceCarrier: null,
    userToUpdate: null,
    newDocId: '',
    usersAdmin: null,
    rightDrawer: false,
    leftDrawer: false,
    gralText:"",
    originExtendedZone : {isAvailable: false, carriers:[]}, 
    destinationExtendedZone : {isAvailable: false, carriers:[]},
    carrierRatings : new Array<any>(),
    //waybillAvailable: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: GenericAction) => {
    switch(action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case SET_SUCCESS: 
            return {
                ...state,
                success: action.payload
            }
        case SET_DIALOG: 
            return {
                ...state,
                dialog: action.payload
            }
        case SET_UPDATE_USER: 
            return {
                ...state,
                userToUpdate: action.payload
            }
        case SET_DIALOG_TYPE: 
            return {
                ...state,
                dialogType: action.payload
            }
        case SET_DIALOG_BODY: 
            return {
                ...state,
                dialogBody: action.payload
            }
        case SET_SHOW_FORM: 
            return {
                ...state,
                showForm: action.payload
            }
        case SET_PUBLIC_PRICE: 
            return {
                ...state,
                publicPrice: action.payload
            }
        case SET_NEW_DOC_ID: 
            return {
                ...state,
                newDocId: action.payload
            }
        case SET_RIGHT_DRAWER: 
            return {
                ...state,
                rightDrawer: action.payload
            }
        case SET_LEFT_DRAWER: 
            return {
                ...state,
                leftDrawer: action.payload
            }
        case SET_USERS_ADMIN: 
            return {
                ...state,
                usersAdmin: action.payload
            }
        case SET_USER_DIRECTORY: 
            return {
                ...state,
                userDirectory: action.payload
            }
        case SET_ORIGIN_DIR_SHIPMENT:
            return{
                ...state,
                originDirectionShipment: action.payload
            }
        case SET_DESTINY_DIR_SHIPMENT:
            return{
                ...state,
                destinyDirectionShipment: action.payload
            }
        case SET_PACKAGE_TO_SHIP:
            return{
                ...state,
                packageToShip: action.payload
            }
        case SET_OPTIONS_FOR_SHIPMENT:
            return{
                ...state,
                optionsForShipment: action.payload
            }
        case SET_SELECTED_PRICE_CARRIER:
            return{
                ...state,
                selectedPriceCarrier: action.payload
            }
        case GRAL_TEXT:
            return{
                ...state,
                gralText: action.payload
            }
        case SET_DESTINATION_EXTZONE:
            return{
                ...state,
                destinationExtendedZone: action.payload
            }
        case SET_ORIGIN_EXTZONE:
            return{
                ...state,
                originExtendedZone: action.payload
            }
        case RATING_UPDATE:
            //console.log("PAYLOAD",action.payload)
            if(action.payload){
                //console.log("not empty params", state.carrierRatings.length);
                const a = state.carrierRatings;
                a.push(action.payload);
                return{
                    ... state,
                    carrierRatings : a
                }
            }
            else
                //console.log("empty params")    
                return{
                    ... state,
                    carrierRatings : new Array<any>()
                }
        default:
            return state;
    }
}