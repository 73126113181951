import React, { FC, useState, useEffect  }from 'react';
import { useSelector, useDispatch } from 'react-redux';


import { User } from '../../../types/authTypes';
import { UserDirectoryFav } from '../../../types/storeTypes';
import {availableCountries} from'../../../features/referenceData/appLists';
import {mexStates} from'../../../features/referenceData/appLists';

import { RootState } from '../../../store';
import { setLoading, setDialog, setDialogType } from '../../../store/actions/genericActions';
import { createDirectory } from '../../../store/actions/storeActions';
import { validateLengthInput, validateEmail } from '../../../features/functions/validatorField';

import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface createShipmentProps {
    user:User
}

const CreateFavoriteDirForm: FC<createShipmentProps> = (props) => {

    const {user} = props

    const dispatch= useDispatch();  
  
    const { loading } = useSelector((state: RootState) => state.generic);

    const [alias, setAlias] = useState<string| null>('')
    const [fullName, setFullName] = useState<string| null>('')
    const [email, setEmail] = useState<string| null>('')
    const [phone, setPhone] = useState<string| null>('')
    const [company, setCompany] = useState<string| null>('')
    const [address, setAddress] = useState<string| null>('')
    const [address2, setAddress2] = useState<string| null>('')
    const [zipCode, setZipCode] = useState<string| null>('')
    const [neighborhood, setNeighborhood] = useState<string| null>('')
    const [country, setCountry] = useState<string| undefined>('')
    const [state, setState] = useState<string| undefined>('')
    const [city, setCity] = useState<string| null>('')
    const [reference, setReference] = useState<string| null>('')

    
    
    const [formValid, setFormValid] = useState<boolean>(false);

    const [invalidAlias, setInvalidAlias] = useState<boolean>(false);
    const [invalidFullName, setInvalidFullName] = useState<boolean>(false);
    const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
    const [invalidPhone, setInvalidPhone] = useState<boolean>(false);
    const [invalidCompany, setInvalidCompany] = useState<boolean>(false);
    const [invalidAddress, setInvalidAddress] = useState<boolean>(false);
    const [invalidAddress2, setInvalidAddress2] = useState<boolean>(false);
    const [invalidZipCode, setInvalidZipCode] = useState<boolean>(false);
    const [invalidNeighborhood, setInvalidNeighborhood] = useState<boolean>(false);
    const [invalidCountry, setInvalidCountry] = useState<boolean>(false);
    const [invalidState, setInvalidState] = useState<boolean>(false);
    const [invalidCity, setInvalidCity] = useState<boolean>(false);
    const [invalidReference, setInvalidReference] = useState<boolean>(false);

    let sendStates = [{label : "", satId :""}];
    
    if(country == "MEX"){
        sendStates = mexStates; 
    }
    
    useEffect(() => {
        if((
            alias!== null&&
            fullName!== null&&
            email!==null&&
            phone!== null&&
            company!== null&&
            address!== null&&
            address2!== null&&
            zipCode!== null&&
            neighborhood!== null&&
            country!== undefined&&
            state!== undefined&&
            city!== null&&
            reference!== null
        ) 
        && 
        (
            !invalidAlias&&
            !invalidFullName&&
            !invalidEmail&&
            !invalidPhone&&
            !invalidCompany&&
            !invalidAddress&&
            !invalidAddress2&&
            !invalidZipCode&&
            !invalidNeighborhood&&
            /*!invalidCountry&&
            !invalidState&&*/
            !invalidCity&&
            !invalidReference
        )
        ){
            setFormValid(true)
        } else{
            setFormValid(false)
        }
  
    }, [
        alias,
        fullName,
        email,
        phone,
        company,
        address,
        address2,
        zipCode,
        neighborhood,
        country,
        state,
        city,
        reference,
        invalidAlias,
        invalidFullName,
        invalidEmail,
        invalidPhone,
        invalidCompany,
        invalidAddress,
        invalidZipCode,
        invalidNeighborhood,
        invalidCountry,
        //invalidState,
        //invalidCity,
        invalidReference,
        formValid
    ])

    const aliasHandler = (e:any) => {
        e = setNormalize(e)
        setAlias(e)
        setInvalidAlias(validateLengthInput(50,e))
    }
    const fullNameHandler = (e:any) => {
        e = setNormalize(e)
        setFullName(e)
        setInvalidFullName(validateLengthInput(50,e))
    }
    const emailHandler = (e:any) => {
        setEmail(e)
        setInvalidEmail(validateEmail(e))
    }
    const phoneHandler = (e:any) => {
        setPhone(e)
        setInvalidPhone(validateLengthInput(11,e))
    }
    const companyHandler = (e:any) => {
        e = setNormalize(e)
        setCompany(e)
        setInvalidCompany(validateLengthInput(50,e))
    }
    const addressHandler = (e:any) => {
        e = setNormalize(e)
        setAddress(e)
        setInvalidAddress(validateLengthInput(35,e))
    }
    const addressHandler2 = (e:any) => {
        e = setNormalize(e)
        setAddress2(e)
        setInvalidAddress2(validateLengthInput(35,e))
    }
    const zipCodeHandler = (e:any) => {
        setZipCode(e)
        setInvalidZipCode(validateLengthInput(6,e))
    }
    const neighborhoodHandler = (e:any) => {
        e = setNormalize(e)
        setNeighborhood(e)
        setInvalidNeighborhood(validateLengthInput(50,e))
    }
    /*const countryHandler = (e:any) => {
        setCountry(e)
        setInvalidCountry(validateLengthInput(30,e))
    }
    const stateHandler = (e:any) => {
        setState(e)
        setInvalidState(validateLengthInput(30,e))
    }*/
    const countryHandler = (event: SelectChangeEvent) => {
        setCountry(event.target.value as string);
    }
    const stateHandler = (event: SelectChangeEvent) => {
        setState(event.target.value as string);
    }
    const cityHandler = (e:any) => {
        e = setNormalize(e)
        setCity(e)
        setInvalidCity(validateLengthInput(30,e))
    }
    const referenceHandler = (e:any) => {
        e = setNormalize(e)
        setReference(e)
        setInvalidReference(validateLengthInput(35,e))
    }
    const setNormalize = (e:any)=>{
        return e.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
  
    const closeDialogHandler = () => {
        dispatch<any>(setDialog(false))
        dispatch<any>(setDialogType(''))
        clearFields();
        // navigate('/dashboard') 
        return;
    }
  
    const saveDirectoryHandler = () => {
      dispatch<any>(setLoading(true))
      if( user && formValid){
          let newDirectoryData: UserDirectoryFav = {
            _id: '',
            createdBy: user?user.uid:'',
            createdAt:'',
            alias: alias?alias:'',
            fullName:fullName?fullName:'',
            phone:phone?phone:'',
            zipCode:zipCode?zipCode:'',
            address:address?address:'',
            address2 : address2? address2:'',
            email:email?email:'',
            neighborhood:neighborhood?neighborhood:'',
            city:city?city:'',
            state:state?state:'',
            country:country?country:'',
            company:company?company:'',
            reference:reference?reference:''
          }
          if(user && newDirectoryData) {
            dispatch<any>(createDirectory(newDirectoryData, user))
            clearFields()
            setFormValid(false)
            dispatch<any>(setDialog(false))
            dispatch<any>(setDialogType(''))
            
            //   editStore ?
            //   dispatch<any>(createStore(storeData, user))
            //   if(showForm){
            //     dispatch<any>(setEditStore(undefined))
            // }
          }
      }
    }

    const clearFields = () => {
        setAlias(null)
        setFullName(null)
        setEmail(null)
        setPhone(null)
        setCompany(null)
        setAddress(null)
        setZipCode(null)
        setNeighborhood(null)
        setCountry(undefined)
        setState(undefined)
        setCity(null)
        setReference(null)
    }



    return (
        <Card elevation={0}>
            <CardContent>
                <Box 
                    component="form"
                    sx={{
                        width:'100%',
                    }}
                    // onSubmit={submitHandler}
                    noValidate
                    autoComplete="off"
                    >
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={alias}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => aliasHandler(e.currentTarget.value)}
                                label="Alias"
                                type="text"
                                error={invalidAlias}
                                helperText={ alias&&invalidAlias ? `Intenta un texto más corto. ${alias.length}/50`:null}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={fullName}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => fullNameHandler(e.currentTarget.value)}
                                label="Nombre completo"
                                type="text"
                                error={invalidFullName}
                                helperText={fullName&&invalidFullName ? `Intenta un texto más corto. ${fullName.length}/50`:null}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={email}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => emailHandler(e.currentTarget.value)}
                                label="Email"
                                placeholder='jperez@mail.com'
                                type="email"
                                error={invalidEmail}
                                helperText={ invalidEmail ? 'Ingresa un correo válido':null}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={phone}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => phoneHandler(e.currentTarget.value)}
                                label="Teléfono"
                                placeholder='3310987655'
                                type="text"
                                error={invalidPhone}
                                helperText={ invalidPhone ? `Ingresa 10 dígitos y no utilices caracteres especiales`:null}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={company}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => companyHandler(e.currentTarget.value)}
                                label="Compañia"
                                type="text"
                                error={invalidCompany}
                                helperText={ company&&invalidCompany ? `Intenta un texto más corto. ${company.length}/50`:null}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={address}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => addressHandler(e.currentTarget.value)}
                                label="Dirección y Número"
                                type="text"
                                error={invalidAddress}
                                helperText={ address&&invalidAddress ? `Intenta un texto más corto. ${address.length}/50`:null}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={address2}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => addressHandler2(e.currentTarget.value)}
                                label="Direccion 2"
                                type="text"
                                error={invalidAddress2}
                                helperText={ address2&&invalidAddress2 ? `Intenta un texto más corto. ${address2.length}/35`:null}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={neighborhood}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => neighborhoodHandler(e.currentTarget.value)}
                                label="Colonia"
                                type="text"
                                error={invalidZipCode}
                                helperText={ neighborhood&&invalidZipCode ? `Intenta un texto más corto. ${neighborhood.length}/30`:null}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={zipCode}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => zipCodeHandler(e.currentTarget.value)}
                                label="Código Postal"
                                type="text"
                                error={invalidZipCode}
                                helperText={ invalidZipCode ? 'Ingresa 5 digitos y no utilices caracteres especiales':null}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={reference}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => referenceHandler(e.currentTarget.value)}
                                label="Referencia"
                                type="text"
                                error={invalidReference}
                                helperText={ reference&&invalidReference ? `Intenta un texto más corto. ${reference.length}/35`:null}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl 
                                variant='standard'
                                required
                                fullWidth
                                color="secondary"
                                sx={{my:1}}
                                >
                                <InputLabel id="demo-simple-select-label">Pais</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={country}
                                    //onChange={(e:any) => stateHandler(e.currentTarget.value)}
                                    onChange={countryHandler}
                                    >
                                    {availableCountries.map((country) => (
                                        <MenuItem
                                        key={country}
                                        value={country}
                                        >
                                        {country}
                                        </MenuItem>
                                    ))}
                                    
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl 
                                variant='standard'
                                required
                                fullWidth
                                color="secondary"
                                sx={{my:1}}
                                >
                                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state}
                                    //onChange={(e:any) => stateHandler(e.currentTarget.value)}
                                    onChange={stateHandler}
                                    >
                                    {sendStates.map((states) => (
                                        <MenuItem 
                                        key={states.satId}
                                        value={states.label}
                                        >
                                        {states.label}
                                        </MenuItem>
                                    ))}
                                    
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant='standard'
                                required
                                color="secondary"
                                value={city}
                                fullWidth
                                sx={{my:1}}
                                onChange={(e:any) => cityHandler(e.currentTarget.value)}
                                label="Ciudad"
                                type="text"
                                error={invalidCity}
                                helperText={ city&&invalidCity ? `Intenta un texto más corto. ${city.length}/30`:null}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
            <CardActions className="d-flex-row-end-centered">
                    <LoadingButton
                    loading={loading}
                    color="secondary"
                    sx={{my:1, bgcolor:'grey.50'}}
                    variant="text"
                    onClick={closeDialogHandler}
                >
                    Cancelar
                </LoadingButton>
                <LoadingButton
                    loading={loading}
                    color="secondary"
                    disableElevation
                    sx={{my:1}}
                    variant="contained"
                    disabled={!formValid}
                    onClick={saveDirectoryHandler}
                >
                    Guardar dirección
                </LoadingButton>
            </CardActions>
        </Card>
    )
}

const MemoizedCreateFavoriteDirForm = React.memo(CreateFavoriteDirForm)

export default MemoizedCreateFavoriteDirForm