export function validateLengthInput(maxLength: number, value:string): boolean {
    let invalid: boolean = false;
    const valueLength = value.length;

    valueLength > (maxLength - 1) ? invalid = true : invalid = false;
    
    return invalid;
}

export function validateExactLengthInput(length: number, value:string): boolean {
    let invalid: boolean = false;
    const valueLength = value.length;
    valueLength != length ? invalid = true : invalid = false;
    return invalid;
}

export function validatePassword(password:string): boolean {
    let invalid:boolean = false;
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const specialCharacters = /[$&+,:;=?@#|'<>.^*()%!-]/g;

    invalid = password.length >= 8 && password.match(lowerCaseLetters) && 
    password.match(upperCaseLetters) &&
    password.match(numbers) &&
    password.match(specialCharacters) ? false : true

    return invalid;
}

export function validateEmail(email:string): boolean {
    let invalid:boolean=false;
    const validEmailRegex = /^[a-zA-Z0-9.!#$%_&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    invalid = email.length > 4 && email.match(validEmailRegex) ? false: true;
    
    return invalid
}

export function validatePhone(phone:string): boolean {
    let invalid:boolean=false;
    // const validPhoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    invalid = phone.length === 11 ?  true:false;

    return invalid
}

export function validatePositiveNumber(val:number): boolean {
    let invalid:boolean=false;
    invalid = val<=0||val===0 ? true:false
    return invalid
}

export function validatePercentageNumber(val:number): boolean {
    let invalid:boolean=false;
    invalid = val<=0||val===0||val>=101 ? true:false
    return invalid
}