import React, {FC, useEffect, useState} from 'react';
import { useSelector, useDispatch} from 'react-redux';

import { RootState } from '../../store';
import { updateStore } from '../../store/actions/storeActions';
import { updateUserInfo } from '../../store/actions/authActions';
import { setDialog, setDialogType, setLoading } from '../../store/actions/genericActions';
import { User, UserActivityState, UserBasicData, StoreBasicData } from '../../types/authTypes';
import { Store } from '../../types/storeTypes';
import { shipmentStatus } from '../../features/referenceData/storeLists';
import { defaultAssets, dialogTypes } from '../../features/referenceData/appLists';
import { roleTypeArray } from '../../features/referenceData/userLists';

import { firestore } from '../../firebase/config';
import { collection, onSnapshot, query, where} from 'firebase/firestore';

import ShipmentTableCard from '../pages/shipments/ShipmentTableCard';
import SimpleSummaryCard from '../pages/shipments/SimpleSummaryCard';
import FilterShipments from '../pages/shipments/FilterShipments';


import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import LoadingButton from '@mui/lab/LoadingButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers';


const Dashboard: FC = () => {

    const dispatch = useDispatch()
    
    const user: User | null = useSelector((state: RootState) => state.auth.user);
    const userActivity: UserActivityState | null = useSelector((state: RootState) => state.auth.userActivityState);
    const { loading } = useSelector((state: RootState) => state.generic);
    const { shipments } = useSelector((state: RootState) => state.apis);
    const [franchises, setFranchises] = useState<Array<Store>|undefined>()
    const [franchiseToDisplay, setFranchiseToDisplay] = useState<Store|undefined>()

    const key = "status";
    const startedValue= shipmentStatus[0]; //iniciado
    const infoGeneratedValue = shipmentStatus[1]; // iniciado
    const notSentValue = shipmentStatus[2];
    const inProgressValue = shipmentStatus[3];
    // const sentValue = shipmentStatus[4];

    useEffect(()=>{
        
        if(userActivity&&!userActivity.active) {
            const franchisesRef = collection(firestore, 'franchises')
            const franchiseQuery = query((franchisesRef), 
                where("_id", '!=', 'all'));
             onSnapshot(franchiseQuery, (querySnapshot) => {
                const franchisesDb: Array<Store> = querySnapshot.docs.map((doc) => {
                    const franchiseDoc: Store = {...doc.data() as Store, _id: doc.id}
                    return franchiseDoc
                })
                setFranchises(franchisesDb)
            })
        } else {
            setFranchises(undefined)
        }
    }, [setFranchises, userActivity])
    let countOfInProgressValue;
    let countOfNotSent;
    let countOfGeneratedValue;
    let countOfStarted;
    if(shipments.data){
        countOfStarted = shipments && shipments.data?.reduce((acc, obj) => {
            return obj[key] === startedValue ? acc + 1 : acc;
           }, 0);
       
           countOfGeneratedValue = shipments && shipments.data?.reduce((acc, obj) => {
            return obj[key] === infoGeneratedValue ? acc + 1 : acc;
           }, 0);
           
           countOfNotSent = shipments && shipments.data?.reduce((acc, obj) => {
            return obj[key] === notSentValue ? acc + 1 : acc;
           }, 0);
           
           countOfInProgressValue = shipments && shipments.data.reduce((acc, obj) => {
           return obj[key] === inProgressValue ? acc + 1 : acc;
           }, 0);
    }
    
    // let countOfSentValue = shipments && shipments.reduce((acc, obj) => {
    //  return obj[key] === sentValue ? acc + 1 : acc;
    // }, 0);

    const startedSumToSent: number = countOfStarted&&countOfGeneratedValue?
        countOfStarted+countOfGeneratedValue:
        countOfStarted?countOfStarted:
        countOfGeneratedValue?countOfGeneratedValue:0

    const selectedFranchiseHandler = (e:any, value?:any) => {
        if(value){
            setFranchiseToDisplay(value)
        }
        return
    }

    const addSelectedStoreToUserHandler = () =>{
        dispatch<any>(setLoading(true))
        if(user&&user.uid&&franchiseToDisplay){
            const hasStoreAlready: boolean = user.storesIds ? user.storesIds.includes(franchiseToDisplay._id) : false

            if(hasStoreAlready) {
                return;
            }
    
            const storeSupervisor: UserBasicData = {
                uid: franchiseToDisplay.storeSupervisor?.uid ? franchiseToDisplay.storeSupervisor.uid :'N/A',
                userName: franchiseToDisplay.storeSupervisor?.userName ? franchiseToDisplay.storeSupervisor.userName :'N/A',
            }
            const storeToAdd: StoreBasicData = {
                _id:franchiseToDisplay._id,
                storeName: franchiseToDisplay.storeName,
                supervisor: storeSupervisor
            }
    
            const updatedListOfStores: Array<StoreBasicData> = user.stores ? [...user.stores, storeToAdd] : [storeToAdd]
            const updatedStoreIds: Array<string> = user.storesIds ? [...user.storesIds, franchiseToDisplay._id] : [franchiseToDisplay._id]
            const updatedSuperIds: Array<string> = user.supervisorIds && franchiseToDisplay.storeSupervisor ? [...user.supervisorIds, storeSupervisor.uid] : [storeSupervisor.uid]
            const updatedStoreMemberIds: Array<string> =  franchiseToDisplay.members ? [...franchiseToDisplay.members, user.uid] : [user.uid]
            let uniqueStoreIds= updatedStoreIds.filter((id, index) => {
                return updatedStoreIds.indexOf(id) === index;
            });
            let uniqueSuperIds= updatedSuperIds.filter((id, index) => {
                return updatedSuperIds.indexOf(id) === index;
            });
            let uniqueStoreMemberIds= updatedStoreMemberIds.filter((id, index) => {
                return updatedStoreMemberIds.indexOf(id) === index;
            });
    
            const userToUpdate: User = {
                ...user,
                stores: updatedListOfStores,
                storesIds: uniqueStoreIds,
                supervisorIds: uniqueSuperIds
            }
            const storeToUpdate: Store = {
                ...franchiseToDisplay,
                members: uniqueStoreMemberIds
            }
            dispatch<any>(updateUserInfo(userToUpdate, () => setLoading(true)))
            dispatch<any>(updateStore(storeToUpdate))
        }
    }

    return (
        <Container maxWidth='xl' sx={{bgcolor:'#F4F7F9', minHeight:'100vh'}}>
            <Toolbar sx={{mb:3}}/>
            { user && userActivity? 
                <Grid sx={{mb:1}} container direction="row" justify-content="space-between" alignItems="flex-start" spacing={1}>
                    <Grid item xs={12} className="d-flex-col-center-centered">
                        { !userActivity.active && franchises && (!user.stores|| user.stores.length===0) ? 
                            <Box sx={{width:'60%', height:'80vh'}} className='d-flex-col-center-centered'>
                                <Typography variant="h6" align="center">
                                    ¡Hola, {user?.givenName}! Te damos la bienvenida a Envios Happy.
                                </Typography>
                                <Typography variant="h6" align="center">
                                    Para continuar, selecciona la franquicia a la que te quieres a unir.
                                </Typography>
                                <Autocomplete
                                    id="select-franchise-inactive-user"
                                    sx={{width:{xs:'100%', md:'50%'},mx:1, mt:3}}
                                    options={franchises} // list here the franchises
                                    autoComplete
                                    clearOnBlur
                                    clearOnEscape
                                    autoHighlight
                                    clearText='Clear'
                                    onChange={(e, value) => selectedFranchiseHandler(e, value)}
                                    getOptionLabel={(franchise:Store) => {
                                        if(franchise.storeName) 
                                            return franchise.storeName
                                        else 
                                            return 'N/A'
                                    }}
                                    renderOption={(props, franchise: Store) => (
                                        <Box component="li" sx={{p:1}} {...props}>
                                            <Typography color='secondary'>
                                                <StorefrontOutlinedIcon />
                                            </Typography>
                                            <Typography variant="body2" sx={{fontWeight:500, ml:1}}>
                                                { franchise.storeName ?
                                                franchise.storeName : 'N/A'
                                                }
                                            </Typography>
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        size="small"
                                        color="primary"
                                        placeholder="Buscar franquicia"
                                        inputProps={{
                                            ...params.inputProps,
                                            startadornment:<SearchOutlinedIcon fontSize="small" sx={{color:'#000'}}/>,
                                            autoComplete: 'Franquicia', // disable autocomplete and autofill
                                        }}
                                        />
                                    )}
                                />
                                {franchiseToDisplay && (!user.stores|| user.stores.length===0)  ? 
                                    <Box sx={{width:'60%', mt:5}}>
                                        <Card>
                                            <CardMedia
                                                sx={{ height: 180 }}
                                                image={franchiseToDisplay.storeAvatar ? 
                                                    franchiseToDisplay.storeAvatar :
                                                    defaultAssets.defaultImageForStores}
                                                title={franchiseToDisplay.storeName}
                                            />
                                            <CardContent>
                                                <Typography variant='h6'>Tienda: {franchiseToDisplay.storeName}</Typography>
                                                <Typography variant='body2' color='text.secondary'>
                                                    {
                                                        franchiseToDisplay.storeAddress+' '+
                                                        franchiseToDisplay.storeNeighborhood+ ' '+
                                                        franchiseToDisplay.storeCity
                                                    } 
                                                </Typography>
                                                <Typography variant='body2' color='text.secondary'>
                                                    Supervisor: {franchiseToDisplay.storeSupervisor?.userName } 
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <LoadingButton 
                                                    onClick={addSelectedStoreToUserHandler}
                                                    disableElevation 
                                                    loading={loading}
                                                    variant="contained" 
                                                    color="secondary">
                                                        Seleccionar
                                                </LoadingButton>
                                            </CardActions>
                                            <CardContent>
                                                <Typography variant="caption">
                                                    Una vez seleccionada, solo el admin o el supervisor te puede asignar otra franquicia
                                                </Typography>
                                            </CardContent>

                                        </Card>
                                    </Box>
                                    : null
                                }
                            </Box>
                            :
                            null
                        }
                        {user.stores && user.stores.length > 0 && !userActivity.active ?
                            <Typography 
                                component="h6" 
                                variant="body1" 
                                align='center' 
                                sx={{fontWeight:600, bgcolor:'warning.light', p:2, borderRadius:3}}>
                                Gracias por seleccionar tu tienda. Por el momento tu cuenta se encuentra en revisión. El proceso de activación puede tardar hasta 48 horas.
                            </Typography>
                            :null
                        }
                        { userActivity && userActivity.active ?
                            <Box sx={{width:'100%'}}>
                                <Typography 
                                    component="h6" 
                                    variant="h6" 
                                    sx={{fontWeight:500, mb:.5}}>
                                    Panel
                                </Typography>
                                <FilterShipments user={user} /> 
                                <Grid container spacing={2} sx={{mt:1}}>
                                    <Grid item xs={12} md={4}>
                                        <SimpleSummaryCard type={startedValue} total={startedSumToSent}/>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SimpleSummaryCard type={notSentValue} total={countOfNotSent?countOfNotSent:0}/>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SimpleSummaryCard type={inProgressValue} total={countOfInProgressValue?countOfInProgressValue:0}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{mt:1}}>
                                    {/* <Grid item xs={12} md={6}> // Do we keep the graphs or not?
                                        <GraphPackagesShippedCard range={0}/>
                                    </Grid> */}
                                    <Grid item xs={12} md={12}>
                                        <ShipmentTableCard hideMenuActions={false} user={user} />
                                    </Grid>
                                </Grid>
                                {/* <CustomTabs/> */}
                            </Box>
                            :null
                        }
                    </Grid>
                </Grid>
                :null
            }
        </Container>
    )
}

export default Dashboard